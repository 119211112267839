import axios from 'axios/index';
import history from 'history.js';
import BoardModel from 'main/content/apps/scrumboard/model/BoardModel';

export const GET_BOARD_TAGS = '[SCRUMBOARD APP] GET BOARD TAGS';
export const ADD_BOARD_TAG = '[SCRUMBOARD APP] ADD BOARD TAG';
export const UPDATE_BOARD_TAG = '[SCRUMBOARD APP] UPDATE BOARD TAG';
export const REMOVE_BOARD_TAG = '[SCRUMBOARD APP] REMOVE BOARD TAG';
export const GET_BOARDS = '[SCRUMBOARD APP] GET BOARDS';
export const RESET_BOARDS = '[SCRUMBOARD APP] RESET BOARDS';
export const NEW_BOARD = '[SCRUMBOARD APP] NEW BOARD';
export const SET_SEARCH_TEXT = '[SCRUMBOARD APP] SET SEARCH TEXT';
export const SET_FILTERS = '[SCRUMBOARD APP] SET FILTERS';
export const SET_SORTING= '[SCRUMBOARD APP] SET SORTING';

export function getBoards(Co)
{
    const request = axios.get(`${window["apiLocation"]}/api/ScrumBoard?Co=${Co}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_BOARDS,
                payload: response.data
            })
        );
}

export function setSearchText(e) {
    return {
        type: SET_SEARCH_TEXT,
        payload: e.target.value
    }
}

export function setFilters(filters) {
    return {
        type: SET_FILTERS,
        payload: filters
    }
}

export function setSorting(sortBy) {
    return {
        type: SET_SORTING,
        payload: sortBy
    }
}

export function getBoardTags(Co)
{
    const request = axios.get(`${window["apiLocation"]}/api/ScrumBoardTag?Co=${Co}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_BOARD_TAGS,
                payload: response.data
            })
        );
}

export function newBoardTag(newTag)
{
    const request = axios.post(`${window["apiLocation"]}m/api/ScrumBoardTag`,
        newTag
    );

    return (dispatch) =>
        request.then((response) => {
                const tag = response.data;
                return dispatch({
                    type: ADD_BOARD_TAG,
                    tag
                })
            }
        );
}

export function updateBoardTag(boardTag)
{
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardTag?Co=${boardTag.Co}&Tag=${boardTag.ID}`,
        boardTag
    );

    return (dispatch) =>
        request.then((response) => {
                const tag = response.data;
                return dispatch({
                    type: UPDATE_BOARD_TAG,
                    tag
                })
            }
        );
}

export function removeBoardTag(tag)
{
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardTag?Co=${tag.Co}&Tag=${tag.ID}`);

    return (dispatch) =>
        request.then((response) => {
                return dispatch({
                    type: REMOVE_BOARD_TAG,
                    tag
                })
            }
        );
}

export function resetBoards()
{
    return {
        type: RESET_BOARDS
    }
}

export function newBoard(board)
{
    const request = axios.post(`${window["apiLocation"]}/api/ScrumBoard`,
        board
    );

    return (dispatch) =>
        request.then((response) => {
                const board = response.data;
                history.push({
                    pathname: '/apps/project-tracking/boards/' + board.id
                });
                return dispatch({
                    type: NEW_BOARD,
                    board
                })
            }
        );
}
