import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from 'lodash';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class RateTemplateLaborsList extends Component {

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    render() {
        const { classes, openEditRateTemplateLaborDialog, laborTypes } = this.props;
        const data = this.props.rateTemplateLabors ? this.props.rateTemplateLabors : [];

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">build</Icon>Rate Template Labor</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditRateTemplateLaborDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Rate Template",
                                accessor: "RateTemplate",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Labor Type",
                                accessor: "LaborType",
                                className: "font-bold",
                                Cell: row => `${row.original.LaborType} - ${laborTypes && _.find(laborTypes, {BusinessUnit: row.original.BusinessUnit, Division: row.original.Division, LaborType: row.original.LaborType}).Name}`
                            },
                            {
                                Header: "Rate",
                                accessor: "Rate",
                                className: "justify-center",
                                Cell: row => {
                                    return `$${parseFloat(row.original.Rate).toFixed(2)}`
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Labor Rates found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditRateTemplateLaborDialog: Actions.openEditRateTemplateLaborDialog,
    }, dispatch);
}

function mapStateToProps({ rateTemplatesApp, spReducers }) {
    return {
        user: rateTemplatesApp.user,
        laborTypes: spReducers.laborTypes,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(RateTemplateLaborsList)));
