import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DepartmentDialog from 'main/content/apps/departments/DepartmentDialog';
import TextGatherPhrasesDialog from 'main/content/apps/text-gather-phrases/TextGatherPhrasesDialog';
import TextGatherPhraseHeader from 'main/content/apps/text-gather-phrases/TextGatherPhrasesHeader';
import TextGatherPhrasesLeftSidebarHeader from 'main/content/apps/text-gather-phrases/TextGatherPhrasesLeftSidebarHeader';
import TextGatherPhraseList from 'main/content/apps/text-gather-phrases/TextGatherPhrasesList';
import TextGatherPhraseSidebarContent from 'main/content/apps/text-gather-phrases/TextGatherPhrasesSidebarContent';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right: 12,
        bottom: 12,
        zIndex: 99
    }
});

class textGatherPhrasesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getTextGatherPhrases(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewTextGatherPhrasesDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <TextGatherPhraseHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <TextGatherPhrasesLeftSidebarHeader />
                            }
                            content={
                                <TextGatherPhraseList />
                            }
                            leftSidebarContent={
                                <TextGatherPhraseSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={classNames(classes.addButton, !matches ? "fixed" : "")}
                                onClick={openNewTextGatherPhrasesDialog}
                            >
                                <Icon>add</Icon>
                            </Fab>
                        </FuseAnimate>
                        <TextGatherPhrasesDialog />
                        <DepartmentDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTextGatherPhrases: Actions.getTextGatherPhrases,
        getUserData: Actions.getUserData,
        openNewTextGatherPhrasesDialog: Actions.openNewTextGatherPhrasesDialog
    }, dispatch);
}

function mapStateToProps({ textGatherPhrasesApp }) {
    return {
        TextGatherPhrases: textGatherPhrasesApp.TextGatherPhrases.entities,
        selectedTextGatherPhraseIds: textGatherPhrasesApp.TextGatherPhrases.selectedTextGatherPhraseIds,
        searchText: textGatherPhrasesApp.TextGatherPhrases.searchText,
        user: textGatherPhrasesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(textGatherPhrasesApp)));
