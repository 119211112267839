import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { openEditGpsInventoryTransferListDialog } from '../gps-transfer-list/store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class InventoryLocationMaterialsList extends Component {

    state = {
        selectedInventoryLocationMaterialsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedInventoryLocationMaterialMenu = (event) => {
        this.setState({ selectedInventoryLocationMaterialsMenu: event.currentTarget });
    };

    closeSelectedInventoryLocationMaterialsMenu = () => {
        this.setState({ selectedInventoryLocationMaterialsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, securables, selectedMaterial, inventoryLocations, user, searchText, employees, costTypes, selectedInventoryLocationMaterialIds, selectAllInventoryLocationMaterials, deSelectAllInventoryLocationMaterials, technicians, toggleInSelectedInventoryLocationMaterials, openEditInventoryLocationMaterialDialog, removeInventoryLocationMaterials, removeInventoryLocationMaterial, toggleStarredInventoryLocationMaterial, setInventoryLocationMaterialsUnstarred, setInventoryLocationMaterialsStarred, openNewInventoryLocationMaterialDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedInventoryLocationMaterialData ? !this.props.inventoryLocationMaterialData ? [] : this.props.inventoryLocationMaterialData : this.props.selectedInventoryLocationMaterialData), searchText);
        const { selectedInventoryLocationMaterialsMenu } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const methods = { 'M': 'Markup %', 'F': 'Fixed Price' };
        const accessLevel = _.find(securables, { Securable: "inventory-locations" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" && !selectedMaterial &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewInventoryLocationMaterialDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">{!selectedMaterial ? "crop_free" : "store"}</Icon>Inventory</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditInventoryLocationMaterialDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Location",
                                accessor: "Location",
                                minWidth: 256,
                                className: "justify-center font-bold",
                                show: Boolean(selectedMaterial),
                                Cell: row => (
                                    inventoryLocations ? `${_.find(inventoryLocations, { Location: row.original.Location }).Description}` : row.original.Location
                                )
                            },
                            {
                                Header: "Material",
                                accessor: "Material",
                                minWidth: 128,
                                show: !Boolean(selectedMaterial),
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Stock Qty",
                                accessor: "StockQty",
                                className: "justify-center",
                                minWidth: 96,
                            },
                            {
                                Header: "UOM",
                                id: "UOM",
                                minWidth: 64,
                                className: "justify-center",
                                accessor: d => d.Data ? d.Data.Material.UOM : selectedMaterial && selectedMaterial.UOM

                            },
                            {
                                Header: "Description",
                                id: "description",
                                minWidth: 256,
                                accessor: d => d.Data ? d.Data.Material.Description : selectedMaterial && selectedMaterial.Description

                            },
                            {
                                Header: "",
                                accessor: "Transfer",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            const { Co, openEditGpsInventoryTransferListDialog, workOrder } = this.props;
                                            let Description = row.original.Data ? row.original.Data.Material.Description : selectedMaterial && selectedMaterial.Description
                                            const { Material, Location } = row.original;
                                            let Page = 1;
                                            let PageSize = 10;
                                            

                                            let Type = "I"
                                            let WorkOrder = null;
                                            let Scopes = [];
                                            if(workOrder){
                                                Type = "W"
                                                WorkOrder = workOrder.WorkOrder;
                                                Scopes = workOrder.Data.Scopes;
                                            }

                                            openEditGpsInventoryTransferListDialog({Co, Material, Description, Location, Page, PageSize, Type, WorkOrder, workOrder, Scopes})
                                        }}
                                    >
                                        Transfer
                                    </Button>
                                )
                            },
                            {
                                Header: "Bin Location",
                                accessor: "BinLocation",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Cost",
                                accessor: "Cost",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => (
                                    row.original.Cost && `$${row.original.Cost.toFixed(2)}`
                                )
                            },
                            {
                                Header: "Price",
                                accessor: "Price",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => (row.original.Price && row.original.PriceMethod === 'F') ? `$${row.original.Price.toFixed(2)}` : ((row.original.Cost && row.original.MarkupPercent) ? `$${(row.original.Cost + (row.original.Cost * (row.original.MarkupPercent / 100))).toFixed(2)}` : row.original.Cost && `$${row.original.Cost.toFixed(2)}`)
                            },
                            {
                                Header: "Low Stock Qty",
                                accessor: "LowStockQty",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Reorder Qty",
                                accessor: "ReOrderQty",
                                className: "justify-center",
                                minWidth: 96,
                            },
                            {
                                Header: "Count Date",
                                accessor: "CountDate",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    row.original.CountDate &&
                                    moment(moment(row.original.CountDate)).local().toDate().toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Active?",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                minWidth: 64
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Inventory Location Materials found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getInventoryLocationMaterials: Actions.getInventoryLocationMaterials,
        getUserData: Actions.getUserData,
        toggleInSelectedInventoryLocationMaterials: Actions.toggleInSelectedInventoryLocationMaterials,
        selectAllInventoryLocationMaterials: Actions.selectAllInventoryLocationMaterials,
        deSelectAllInventoryLocationMaterials: Actions.deSelectAllInventoryLocationMaterials,
        openEditInventoryLocationMaterialDialog: Actions.openEditInventoryLocationMaterialDialog,
        removeInventoryLocationMaterials: Actions.removeInventoryLocationMaterials,
        removeInventoryLocationMaterial: Actions.removeInventoryLocationMaterial,
        toggleStarredInventoryLocationMaterial: Actions.toggleStarredInventoryLocationMaterial,
        toggleStarredInventoryLocationMaterials: Actions.toggleStarredInventoryLocationMaterials,
        setInventoryLocationMaterialsStarred: Actions.setInventoryLocationMaterialsStarred,
        setInventoryLocationMaterialsUnstarred: Actions.setInventoryLocationMaterialsUnstarred,
        openNewInventoryLocationMaterialDialog: Actions.openNewInventoryLocationMaterialDialog,
        openEditGpsInventoryTransferListDialog,
    }, dispatch);
}

function mapStateToProps({ inventoryLocationMaterialsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        inventoryLocationMaterialData: spReducers.inventoryLocationMaterials,
        searchText: inventoryLocationMaterialsApp.inventoryLocationMaterials.searchText,
        user: spReducers.userProfiles.User.UserName,
        inventoryLocations: spReducers.inventoryLocations,
        employees: spReducers.employees,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryLocationMaterialsList)));
