import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    ID: null,
    ClientID: null,
    UserName: null,
    Title: null,
    Description: null,
    DueBy: null,
    Data: {
        Attachments: [],
        Comments: [],
        Assignees: [],
        Tags: [],
        Checklists: [],
    },
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    AttachmentID: null,
    open: false
}

const cardReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.OPEN_REQUEST_DIALOG:
            {
                return {
                    ...action.payload
                };
            }
        case Actions.REMOVE_REQUEST:
            {
                return initialState;
            }
        case Actions.CLOSE_REQUEST_DIALOG:
            {
                return initialState;
            }
        case Actions.CHANGE_REQUEST:
            {
                window["warn"](action.target);
                return _.setIn(state, action.target.name, action.target.type === 'checkbox' ? action.target.checked : action.target.value);
            }
        case Actions.CHIP_CHANGE_REQUEST:
            {
                return _.setIn(state, action.name, action.value.map(item => item.value));
            }
        case Actions.NEW_CHIP_REQUEST:
            {
                return _.setIn(state, action.name, [...state[action.name], action.value]);
            }
        case Actions.MAKE_COVER_REQUEST:
            {
                return {
                    ...state,
                    CoverImage: action.attachmentId
                }
            }
        case Actions.REMOVE_COVER_REQUEST:
            {
                return _.setIn(state, 'idAttachmentCover', '');
            }
        case Actions.ADD_ATTACHMENT_REQUEST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Attachments: [...state.Data.Attachments, ...action.attachments],
                    }
                }
            }
        case Actions.REMOVE_ATTACHMENT_REQUEST:
            {
                return {
                    ...state,
                    CoverImage: state.CoverImage === action.attachment.Attachment ? null : state.CoverImage,
                    Data: {
                        ...state.Data,
                        Attachments: _.reject(state.Data.Attachments, { Attachment: action.attachment.Attachment }),
                    }
                }
            }
        case Actions.REMOVE_DUE_REQUEST:
            {
                return _.setIn(state, 'DueBy', null);
            }
        case Actions.TOGGLE_LABEL_REQUEST:
            {
                const tag = _.find(state.Data.Tags, { Tag: action.label.Tag })
                return {
                    ...action.card,
                    Data: {
                        ...action.card.Data,
                        Tags: tag ? _.reject(action.card.Data.Tags, { Tag: action.label.Tag }) : [...action.card.Data.Tags, action.label],
                    }
                }
                // return _.setIn(state, 'Data.Tags', tag ? _.reject(state.Data.Tags, {TagID: action.label.TagID}) : [...state.Data.Tags, action.label]);
            }
        case Actions.TOGGLE_ASSIGNEE_REQUEST:
            {
                const { Assignees } = _.cloneDeepWith(state.Data);
                switch (action.method) {
                    case 'ADD':
                        {
                            Assignees.push(action.assignee);
                        }
                        break;
                    case 'DELETE':
                        {
                            for (var i = 0; i < Assignees.length; i++) {
                                if (Assignees[i].UserName == action.assignee.UserName) {
                                    Assignees.splice(i, 1);
                                }
                            }
                        }
                        break;
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Assignees,
                    }
                }
                //return _.setIn(state, 'idAssignees', _.xor(state.IDAssignees, [action.assigneeId]));
            }
        case Actions.ADD_CHECKLIST_REQUEST:
            {
                const { Checklists } = _.cloneDeepWith(state.Data);
                Checklists.push(action.list);
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
                //return _.setIn(state, 'Data.Checklists', [...state.Data.Checklists, action.newList]);
            }
        case Actions.UPDATE_CHECKLIST_REQUEST:
            {
                const { Checklists } = _.cloneDeepWith(state.Data);
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.list.ID) {
                        Checklists[i] = action.list;
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.REMOVE_CHECKLIST_REQUEST:
            {
                const { Checklists } = _.cloneDeepWith(state.Data);
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.list.ID) {
                        Checklists.splice(i, 1);
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.ADD_CHECKLIST_REQUEST_ITEM:
            {
                const { Checklists } = _.cloneDeepWith(state.Data);
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.item.ChecklistID) {
                        Checklists[i].Data.Items.push(action.item);
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.UPDATE_CHECKLIST_REQUEST_ITEM:
            {
                const { Checklists } = _.cloneDeepWith(state.Data);
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.item.ChecklistID) {
                        for (var t = 0; t < Checklists[i].Data.Items.length; t++) {
                            if (Checklists[i].Data.Items[t].ID == action.item.ID) {
                                Checklists[i].Data.Items[t] = action.item;
                            }
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.REMOVE_CHECKLIST_REQUEST_ITEM:
            {
                const { Checklists } = _.cloneDeepWith(state.Data);
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.item.ChecklistID) {
                        for (var t = 0; t < Checklists[i].Data.Items.length; t++) {
                            if (Checklists[i].Data.Items[t].ID == action.item.ID) {
                                Checklists[i].Data.Items.splice(t, 1);
                            }
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.CHECKLIST_CHANGE_REQUEST:
            {
                const index = state.Data.Checklists.findIndex((x) => x.ID === action.item.ID);
                return _.setIn(state, `Data.Checklists[${index}]`, action.item);
            }
        case Actions.CHECKLIST_REMOVE_REQUEST:
            {
                return _.setIn(state, 'Data.Checklists', _.reject(state.Data.Checklists, { id: action.id }));
            }
        case Actions.COMMENT_ADD_REQUEST:
            {
                return _.setIn(state, 'Data.Comments', [action.comment, ...state.Data.Comments]);
            }
        default:
            return state;
    }
};

export default cardReducer;
