import axios from 'axios';
import { getUserData } from 'main/content/apps/labor-types/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_LABOR_TYPES = '[LABOR TYPES APP] GET LABOR TYPES';
export const SET_SEARCH_TEXT = '[LABOR TYPES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_LABOR_TYPES = '[LABOR TYPES APP] TOGGLE IN SELECTED LABOR TYPES';
export const SELECT_ALL_LABOR_TYPES = '[LABOR TYPES APP] SELECT ALL LABOR TYPES';
export const DESELECT_ALL_LABOR_TYPES = '[LABOR TYPES APP] DESELECT ALL LABOR TYPES';
export const OPEN_NEW_LABOR_TYPE_DIALOG = '[LABOR TYPES APP] OPEN NEW LABOR TYPE DIALOG';
export const CLOSE_NEW_LABOR_TYPE_DIALOG = '[LABOR TYPES APP] CLOSE NEW LABOR TYPE DIALOG';
export const OPEN_EDIT_LABOR_TYPE_DIALOG = '[LABOR TYPES APP] OPEN EDIT LABOR TYPE DIALOG';
export const CLOSE_EDIT_LABOR_TYPE_DIALOG = '[LABOR TYPES APP] CLOSE EDIT LABOR TYPE DIALOG';
export const ADD_LABOR_TYPE = '[LABOR TYPES APP] ADD LABOR TYPE';
export const UPDATE_LABOR_TYPE = '[LABOR TYPES APP] UPDATE LABOR TYPE';
export const REMOVE_LABOR_TYPE = '[LABOR TYPES APP] REMOVE LABOR TYPE';
export const REMOVE_LABOR_TYPES = '[LABOR TYPES APP] REMOVE LABOR TYPES';
export const TOGGLE_STARRED_LABOR_TYPE = '[LABOR TYPES APP] TOGGLE STARRED LABOR TYPE';
export const TOGGLE_STARRED_LABOR_TYPES = '[LABOR TYPES APP] TOGGLE STARRED LABOR TYPES';
export const SET_LABOR_TYPES_STARRED = '[LABOR TYPES APP] SET LABOR TYPES STARRED ';

export function getLaborTypes(routeParams) {
    const request = axios.get('/api/laborTypes-app/laborTypes', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_LABOR_TYPES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedLaborTypes(laborTypeId) {
    return {
        type: TOGGLE_IN_SELECTED_LABOR_TYPES,
        laborTypeId
    }
}


export function selectAllLaborTypes() {
    return {
        type: SELECT_ALL_LABOR_TYPES
    }
}

export function deSelectAllLaborTypes() {
    return {
        type: DESELECT_ALL_LABOR_TYPES
    }
}


export function openNewLaborTypeDialog() {
    return {
        type: OPEN_NEW_LABOR_TYPE_DIALOG
    }
}

export function closeNewLaborTypeDialog() {
    return {
        type: CLOSE_NEW_LABOR_TYPE_DIALOG
    }
}

export function openEditLaborTypeDialog(data) {
    return {
        type: OPEN_EDIT_LABOR_TYPE_DIALOG,
        data
    }
}

export function closeEditLaborTypeDialog() {
    return {
        type: CLOSE_EDIT_LABOR_TYPE_DIALOG
    }
}

export function addLaborType(newLaborType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post(`${window["apiLocation"]}/api/LaborType`,
            newLaborType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_LABOR_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Labor Type ${response.data.LaborType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateLaborType(laborType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.put(`${window["apiLocation"]}/api/LaborType?Co=${encodeURIComponent(laborType.Co)}&BusinessUnit=${encodeURIComponent(laborType.BusinessUnit)}&Division=${encodeURIComponent(laborType.Division)}&Type=${encodeURIComponent(laborType.LaborType)}`,
            laborType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_LABOR_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Labor Type ${laborType.LaborType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeLaborType(laborTypeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post('/api/laborTypes-app/remove-laborType', {
            laborTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_LABOR_TYPE
                })
            ]).then(() => dispatch(getLaborTypes(routeParams)))
        );
    };
}


export function removeLaborTypes(laborTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post('/api/laborTypes-app/remove-laborTypes', {
            laborTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_LABOR_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_LABOR_TYPES
                })
            ]).then(() => dispatch(getLaborTypes(routeParams)))
        );
    };
}

export function toggleStarredLaborType(laborTypeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post('/api/laborTypes-app/toggle-starred-laborType', {
            laborTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_LABOR_TYPE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLaborTypes(routeParams)))
        );
    };
}

export function toggleStarredLaborTypes(laborTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post('/api/laborTypes-app/toggle-starred-laborTypes', {
            laborTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_LABOR_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_LABOR_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLaborTypes(routeParams)))
        );
    };
}

export function setLaborTypesStarred(laborTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post('/api/laborTypes-app/set-laborTypes-starred', {
            laborTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_LABOR_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_LABOR_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLaborTypes(routeParams)))
        );
    };
}

export function setLaborTypesUnstarred(laborTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().laborTypesApp.laborTypes;

        const request = axios.post('/api/laborTypes-app/set-laborTypes-unstarred', {
            laborTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_LABOR_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_LABOR_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLaborTypes(routeParams)))
        );
    };
}
