import React, {Component} from 'react';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles/index';
import {Hidden, Icon, IconButton, Input, Paper, Typography} from '@material-ui/core';
import * as Actions from './store/actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {FuseAnimate, FuseSelectedTheme} from '@fuse';

const styles = theme => ({
    root: {}
});

class WorkOrderScopesRightSidebarHeader extends Component {

    render()
    {
        const {classes, setSearchText, searchText, pageLayout} = this.props;
        return (
            <div className={classNames(classes.root, "flex flex-1 items-center justify-between p-8 sm:p-24")}>

                <div className="flex flex-shrink items-center w-full">

                </div>
            </div>
        )
            ;
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({customersApp})
{
    return {
        searchText: customersApp.customers.searchText
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(WorkOrderScopesRightSidebarHeader));
