import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedCostTypeIds: [],
    routeParams: {},
    costTypeDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            Co: null,
            CostType: null,
            Name: null,
            CostTypeCategory: null,
            OverrideCostGLAccount: null,
            OverrideRevenueGLAccount: null,
            TaxableYN: 'Y',
            Notes: '',
            Data: {
                Overrides: [],
            },
            value: 0
        }
    }
};

const costTypesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COST_TYPES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_COST_TYPES:
            {

                const costTypeId = action.costTypeId;

                let selectedCostTypeIds = [...state.selectedCostTypeIds];

                if (selectedCostTypeIds.find(id => id === costTypeId) !== undefined) {
                    selectedCostTypeIds = selectedCostTypeIds.filter(id => id !== costTypeId);
                }
                else {
                    selectedCostTypeIds = [...selectedCostTypeIds, costTypeId];
                }

                return {
                    ...state,
                    selectedCostTypeIds: selectedCostTypeIds
                };
            }
        case Actions.SELECT_ALL_COST_TYPES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedCostTypeIds = arr.map(costTypes => costTypes.id);

                return {
                    ...state,
                    selectedCostTypeIds: selectedCostTypeIds
                };
            }
        case Actions.DESELECT_ALL_COST_TYPES:
            {
                return {
                    ...state,
                    selectedCostTypeIds: []
                };
            }
        case Actions.OPEN_NEW_COST_TYPE_DIALOG:
            {
                return {
                    ...state,
                    costTypeDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_COST_TYPE_DIALOG:
            {
                return {
                    ...state,
                    costTypeDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_COST_TYPE_DIALOG:
            {
                return {
                    ...state,
                    costTypeDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_COST_TYPE_DIALOG:
            {
                return {
                    ...state,
                    costTypeDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_COST_TYPE_DIALOG:
            {
                return {
                    ...state,
                    costTypeDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default costTypesReducer;
