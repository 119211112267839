import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedDepartmentIds: [],
    routeParams       : {},
    departmentDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const departmentsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_DEPARTMENTS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_DEPARTMENTS:
        {

            const departmentId = action.departmentId;

            let selectedDepartmentIds = [...state.selectedDepartmentIds];

            if ( selectedDepartmentIds.find(id => id === departmentId) !== undefined )
            {
                selectedDepartmentIds = selectedDepartmentIds.filter(id => id !== departmentId);
            }
            else
            {
                selectedDepartmentIds = [...selectedDepartmentIds, departmentId];
            }

            return {
                ...state,
                selectedDepartmentIds: selectedDepartmentIds
            };
        }
        case Actions.SELECT_ALL_DEPARTMENTS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedDepartmentIds = arr.map(departments => departments.id);

            return {
                ...state,
                selectedDepartmentIds: selectedDepartmentIds
            };
        }
        case Actions.DESELECT_ALL_DEPARTMENTS:
        {
            return {
                ...state,
                selectedDepartmentIds: []
            };
        }
        case Actions.OPEN_NEW_DEPARTMENT_DIALOG:
        {
            return {
                ...state,
                departmentDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_DEPARTMENT_DIALOG:
        {
            return {
                ...state,
                departmentDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_DEPARTMENT_DIALOG:
        {
            return {
                ...state,
                departmentDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_DEPARTMENT_DIALOG:
        {
            return {
                ...state,
                departmentDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default departmentsReducer;
