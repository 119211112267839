import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { Button, Card, CardContent, CardHeader, Icon, IconButton, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAllMinimizedDialogs, updateAllMinimizedDialogs2 } from '../minimized-dialogs/store/actions/minimized-dialogs.actions';
import * as Actions from './store/actions';
import _ from '@lodash';
import Media from 'react-media';

const styles = theme => ({
    splitDialogsDiv: { 
        position: 'absolute', 
        top: '30px', 
        display: 'flex',
        flexWrap: 'wrap',
        //maxWidth: 800
    },
    splitDialog: {
        minWidth: 200,
        cursor: 'pointer'
    },
    cardHeader: {
        backgroundColor: '#4dbce9',
        borderBottom: '1px solid lightgrey',
        boxShadow: '0px 0px 4px 0px #8f8f8f',
        color: 'white',
    },
    cropDiv: { 
        //height: 200,
        maxWidth: 350,
        overflow: "hidden",
        padding: 0,
        margin: 0,
    },
    rightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        right: 0,
        top: 0,
        position: 'fixed',
    },
    leftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: 0,
        top: 0,
        position: 'fixed',
    },
    lowerRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        right: -5,
        //top: 0,
        bottom: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    lowerLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: -5,
        //top: 0,
        bottom: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    upperRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        right: -5,
        top: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    upperLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: -5,
        top: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    skipBtn: {
        height: 36,
        width: 36,
        minWidth: 12,
    }
});

class SplitscreenDialogs extends Component {
    state = {
        show: null,
        skip: true,
        rightSplitOverlay: null,
        leftSplitOverlay: null,
        lowerRightSplitOverlay: null,
        lowerLeftSplitOverlay: null,
        upperRightSplitOverlay: null,
        upperLeftSplitOverlay: null,
    };

    componentDidMount() {
        const { splitscreenDialog } = this.props;
        this.setState({ show: Boolean(splitscreenDialog.show) });
    }

    componentDidUpdate(prevProps, prevState) {
        const { splitscreenDialog, splitscreenDialogs, cornered } = this.props;
        const { show } = this.state;
        if(Boolean(splitscreenDialog.show) !== Boolean(prevProps.splitscreenDialog.show)){
            this.setState({ show: Boolean(splitscreenDialog.show) });
        }
    }

    formatPhone = (phone) => {
        const short = phone.substr(phone.length - 10, 10);
        return `(${short.substr(0, 3)}) ${short.substr(3, 3)}-${short.substr(6, 4)}`;
    }

    toggleOpen = async (dialog) => {
        const { splitscreenDialogs, splitscreenDialog, setSplitscreenDialog, updateAllMinimizedDialogs, cornered } = this.props;

        if(splitscreenDialog.splitPosition === 'left' || splitscreenDialog.splitPosition === 'right'){
            let ogDialogList = [...splitscreenDialogs];
            let dialogList = [...splitscreenDialogs];

            // Hide Overlays
            const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
            if(splitDialogIndex > -1){
                //await dialogList[splitDialogIndex].hideOverlays();
                dialogList[splitDialogIndex].split = false
            }

            const filteredDialog = _.filter(dialogList, (o) => o.header === dialog.header)

            const currentDialogIndex = _.findIndex(dialogList, (o) => o.header === dialog.header)

            let dockedPos = null;
            if(splitscreenDialog.splitPosition === 'left'){
                dockedPos = 'right'
            } else if(splitscreenDialog.splitPosition === 'right'){
                dockedPos = 'left'
            }

            dialogList[currentDialogIndex].docked = dockedPos;

            setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition)

            dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);

            // window["warn"]("newDialogState splitPosition",dialogList, splitDialogIndex, filteredDialog)

            const splitHeaders = [dialogList[splitDialogIndex].header, filteredDialog[0].header]
      

            if(ogDialogList.length > 2){
                updateAllMinimizedDialogs(splitHeaders)
            }

        } else if(splitscreenDialog.splitPosition === 'upper-left' || splitscreenDialog.splitPosition === 'upper-right' || splitscreenDialog.splitPosition === 'lower-left' || splitscreenDialog.splitPosition === 'lower-right'){
            //let ogDialogList = [...splitscreenDialogs];
            let dialogList = [...splitscreenDialogs];
            let dialogList2 = [...splitscreenDialogs];

            let corners = {
                'upper-right': 'lower-right',
                'upper-left': 'upper-right',
                'lower-right': 'lower-left',
                'lower-left': 'upper-left',
            }

            let corner;
            corner = cornered + 1
            if(corner < splitscreenDialogs.length){

                if(corner === 4 && splitscreenDialogs.length > 4){
                    const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
                    if(splitDialogIndex > -1){
                        //await dialogList[splitDialogIndex].hideOverlays();
                        dialogList[splitDialogIndex].split = false
                    }

                    
                    
                    setSplitscreenDialog(dialogList, false, corners[splitscreenDialog.splitPosition], splitscreenDialog.height, corner)
                    dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);

                    const dockedDialogs = _.filter(dialogList2, (o) => o.docked === undefined || o.docked === null);
                    const dockedDialogs2  = _.filter(dockedDialogs, (o) => o.header !== dialog.header);
                    let dialogsToUpdate = []
                    dockedDialogs2.map((dialog) =>{
                        if(dialog.split === false){
                            dialogsToUpdate.push(dialog.header)
                        }
                    })

                    // window["warn"]("67899", dialogList2, dockedDialogs, dialogsToUpdate, dialog.header)

                    this.props.updateAllMinimizedDialogs2(dialogsToUpdate, this.props.minimizedDialogs)

                } else{
                    const currentDialogIndex = _.findIndex(dialogList, (o) => o.header === dialog.header)
                    dialogList[currentDialogIndex].split = true;
                    dialogList[currentDialogIndex].docked = corners[splitscreenDialog.splitPosition];
                    //await dialogList[currentDialogIndex].hideOverlays();

                    setSplitscreenDialog(dialogList, true, corners[splitscreenDialog.splitPosition], splitscreenDialog.height, corner)
                    dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);
                }
                // corner = cornered + 1
            } else if(splitscreenDialogs.length >= corner){
                const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
                if(splitDialogIndex > -1){
                    //await dialogList[splitDialogIndex].hideOverlays();
                    dialogList[splitDialogIndex].split = false
                }

                setSplitscreenDialog(dialogList, false, corners[splitscreenDialog.splitPosition], splitscreenDialog.height, corner)
                dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);
            } else {
                // Hide Overlays
                const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
                if(splitDialogIndex > -1){
                    //await dialogList[splitDialogIndex].hideOverlays();
                    dialogList[splitDialogIndex].split = false
                }

                const currentDialogIndex = _.findIndex(dialogList, (o) => o.header === dialog.header)

                dialogList[currentDialogIndex].docked = corners[splitscreenDialog.splitPosition];

                // dialogList.map(async (dialog) => {
                //     await dialog.hideOverlays();
                // })

                setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition)

                dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);
            } 

        } else{ //position 2
            let dialogList = [...splitscreenDialogs];
            let dialogList2 = [...splitscreenDialogs];

            let corners = {
                'left-2': 'upper-right-2',
                'upper-right-2': 'lower-right-2',
                'lower-right-2': 'left-2',
                'right-2': 'lower-left-2',
                'lower-left-2': 'upper-left-2',
                'upper-left-2': 'right-2',
            }

            // window["warn"]("position 2 corners", corners[splitscreenDialog.splitPosition])

            let corner;
            corner = cornered + 1
            // window["warn"]("extra dialog 000", splitscreenDialogs, corner)
            if(corner < splitscreenDialogs.length){
                // corner = cornered + 1

                if(corner === 3 && splitscreenDialogs.length > 3){
                    const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
                    if(splitDialogIndex > -1){
                        //await dialogList[splitDialogIndex].hideOverlays();
                        dialogList[splitDialogIndex].split = false
                    }
                    
                    setSplitscreenDialog(dialogList, false, corners[splitscreenDialog.splitPosition], splitscreenDialog.height, corner)
                    dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);

                    const dockedDialogs = _.filter(dialogList2, (o) => o.docked === undefined || o.docked === null);
                    const dockedDialogs2  = _.filter(dockedDialogs, (o) => o.header !== dialog.header);
                    let dialogsToUpdate = []
                    dockedDialogs2.map((dialog) =>{
                        if(dialog.split === false){
                            dialogsToUpdate.push(dialog.header)
                        }     
                    })

                    // window["warn"]("67899", dialogList2, dockedDialogs, dialogsToUpdate, dialog.header, this.props.minimizedDialogs)

                    this.props.updateAllMinimizedDialogs2(dialogsToUpdate, this.props.minimizedDialogs)

                } else{
                    const currentDialogIndex = _.findIndex(dialogList, (o) => o.header === dialog.header)
                    dialogList[currentDialogIndex].split = true;
                    dialogList[currentDialogIndex].docked = corners[splitscreenDialog.splitPosition];
                    //await dialogList[currentDialogIndex].hideOverlays();

                    setSplitscreenDialog(dialogList, true, corners[splitscreenDialog.splitPosition], splitscreenDialog.height, corner)
                    dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);
                }
                
            } else {
                // Hide Overlays
                const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
                if(splitDialogIndex > -1){
                    //await dialogList[splitDialogIndex].hideOverlays();
                    dialogList[splitDialogIndex].split = false
                }

                const currentDialogIndex = _.findIndex(dialogList, (o) => o.header === dialog.header)

                dialogList[currentDialogIndex].docked = corners[splitscreenDialog.splitPosition];

                // dialogList.map(async (dialog) => {
                //     await dialog.hideOverlays();
                // })

                setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition)

                dialog.reopenFxn(splitscreenDialog.splitPosition, splitscreenDialog.height);
            }
        }
    }

    close = (e, dialog) => {
        e.stopPropagation();

        const { splitscreenDialogs } = this.props;
        let dialogList = [...splitscreenDialogs];

        // Hide Overlays
        if(dialogList.length < 3){
            //const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true)
            //dialogList[splitDialogIndex].hideOverlays();
            this.setState({ ...this.state, rightSplitOverlay: null, leftSplitOverlay: null, upperRightSplitOverlay: null, upperLeftSplitOverlay: null, lowerRightSplitOverlay: null, lowerLeftSplitOverlay: null}, () => dialog.closeFxn(e))
        } else{
            dialog.closeFxn(e);
        }

        //dialog.closeFxn();
    }

    noSplitscreenSelection = async () => {
        const { updateAllMinimizedDialogs, splitscreenDialogs, splitscreenDialog, setSplitscreenDialog, header } = this.props;
        let dialogList = [...splitscreenDialogs];
        const splitDialogIndex = _.findIndex(dialogList, (o) => o.split === true);
        dialogList[splitDialogIndex].split = false;

        await setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition);


        if(dialogList.length > 1){
            const filteredDialog = _.filter(dialogList, (o) => o.docked !== null && o.docked !== undefined)
            //const newDialogList = _.pull(dialogList, filteredDialog[0])

            // window["warn"]("noSplitscreenSelection sss", dialogList, filteredDialog)

            await updateAllMinimizedDialogs([filteredDialog[0].header])
            this.setState({ ...this.state, rightSplitOverlay: null, leftSplitOverlay: null, upperRightSplitOverlay: null, upperLeftSplitOverlay: null, lowerRightSplitOverlay: null, lowerLeftSplitOverlay: null})
        } else {
            this.setState({ ...this.state, rightSplitOverlay: null, leftSplitOverlay: null, upperRightSplitOverlay: null, upperLeftSplitOverlay: null, lowerRightSplitOverlay: null, lowerLeftSplitOverlay: null})
        }
    }

    skipSelection = async () => {
        const { splitscreenDialogs, splitscreenDialog, setSplitscreenDialog, updateAllMinimizedDialogs } = this.props;
        let dialogList = [...splitscreenDialogs];

        let corners = {
            'upper-right': 'lower-right',
            'upper-left': 'upper-right',
            'lower-right': 'lower-left',
            'lower-left': 'upper-left',
            'left-2': 'upper-right-2',
            'upper-right-2': 'lower-right-2',
            'lower-right-2': 'left-2',
            'right-2': 'lower-left-2',
            'lower-left-2': 'upper-left-2',
            'upper-left-2': 'right-2',
        }

        let index = _.findIndex(dialogList, (o) => o.docked === corners[corners[splitscreenDialog.splitPosition]])
        // window["warn"]("skipSelection yyy", index, dialogList, splitscreenDialog.splitPosition, corners[corners[splitscreenDialog.splitPosition]])

        if(index < 0){
            setSplitscreenDialog(dialogList, true, corners[splitscreenDialog.splitPosition], splitscreenDialog.height)
        } else {
            await setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition)

            const filteredDialog = _.filter(dialogList, (o) => o.docked !== null && o.docked !== undefined)

            await updateAllMinimizedDialogs([filteredDialog[0].header])
            this.setState({ ...this.state, rightSplitOverlay: null, leftSplitOverlay: null, upperRightSplitOverlay: null, upperLeftSplitOverlay: null, lowerRightSplitOverlay: null, lowerLeftSplitOverlay: null}) 
        }
        

    }

    render() {
        const { Co, classes, order, fullScreen, dialog, data, splitscreenDialogs, splitscreenDialog } = this.props; //dialog
        const { show, rightSplitOverlay,
            leftSplitOverlay,
            lowerRightSplitOverlay,
            lowerLeftSplitOverlay,
            upperRightSplitOverlay,
            upperLeftSplitOverlay, } = this.state;

        let top = null;
        let skipTop = null;
        if(splitscreenDialog.splitPosition === 'upper-right' || splitscreenDialog.splitPosition === 'lower-right' || splitscreenDialog.splitPosition === 'upper-right-2' ||
            splitscreenDialog.splitPosition === 'right-2'
        ){
            //top = splitscreenDialog.height*0.6;
            top = '55vh'
            skipTop = '52vh'
        } else if(splitscreenDialog.splitPosition === 'upper-left' || splitscreenDialog.splitPosition === 'lower-left' || splitscreenDialog.splitPosition === 'upper-left-2' || splitscreenDialog.splitPosition === 'lower-left-2' ||
            splitscreenDialog.splitPosition === 'left-2'
        ){
            top = 15;
            skipTop = 10
        } else{
            top = null;
        }

        let left = null;
        let right = null;
        let skipLeft = null;
        let skipRight = null;
        if(splitscreenDialog.splitPosition === 'right' || splitscreenDialog.splitPosition === 'lower-right' || splitscreenDialog.splitPosition === 'lower-left' ||
            splitscreenDialog.splitPosition === 'right-2' || splitscreenDialog.splitPosition === 'lower-right-2' || splitscreenDialog.splitPosition === 'lower-left-2' 
        ){
            left = 30;
            right = null;
            skipLeft = '46vw';
            skipRight = null;
        } else if(splitscreenDialog.splitPosition === 'left' || splitscreenDialog.splitPosition === 'upper-left'  || splitscreenDialog.splitPosition === 'upper-right' ||
            splitscreenDialog.splitPosition === 'left-2' || splitscreenDialog.splitPosition === 'upper-left-2'  || splitscreenDialog.splitPosition === 'upper-right-2'
        ){
            left = null;
            right = 0;
            skipLeft = null;
            skipRight = '43vw';
        }

        let arrowIcon = null;
        if(splitscreenDialog.splitPosition === 'lower-right' || splitscreenDialog.splitPosition === 'right-2'){
            arrowIcon = 'arrow_upward'
        } else if(splitscreenDialog.splitPosition === 'lower-left' || splitscreenDialog.splitPosition === 'lower-left-2' || splitscreenDialog.splitPosition === 'lower-right-2'){
            arrowIcon = 'arrow_forward'
        } else if(splitscreenDialog.splitPosition === 'upper-left' || splitscreenDialog.splitPosition === 'left-2'){
            arrowIcon = 'arrow_downward'
        } else if(splitscreenDialog.splitPosition === 'upper-right' || splitscreenDialog.splitPosition === 'upper-right-2' || splitscreenDialog.splitPosition === 'upper-left-2'){
            arrowIcon = 'arrow_back'
        }

        if(splitscreenDialog.splitPosition === 'upper-left-2'){
            skipTop = '90vh'
        } else if(splitscreenDialog.splitPosition === 'upper-left' || splitscreenDialog.splitPosition === 'left-2'){
            skipTop = '43vh'
        }


        // window["warn"]("splitscreenDialogs aaa", splitscreenDialogs, show, splitscreenDialog, top, left, right)


        return (
            <Media queries={{ medium: "(max-width: 1550px)"/**/ }}>
            {matches =>
                <>
                <div className={classNames(classes.rightSplitOverlay, "h-full w-1/2")} style={{ display: show && splitscreenDialogs.length > 1 && (splitscreenDialog.splitPosition === 'left' || splitscreenDialog.splitPosition === 'upper-left-2') ? 'flex' : 'none', backgroundColor: rightSplitOverlay }} onClick={this.noSplitscreenSelection}></div>
                <div className={classNames(classes.leftSplitOverlay, "h-full w-1/2")} style={{ display: show && splitscreenDialogs.length > 1 && (splitscreenDialog.splitPosition === 'right' || splitscreenDialog.splitPosition === 'lower-right-2') ? 'flex' : 'none', backgroundColor: leftSplitOverlay }} onClick={this.noSplitscreenSelection}></div>


                <div className={classNames(classes.upperRightSplitOverlay, "h-1/2 w-1/2")} style={{ display: show && splitscreenDialogs.length > 1 && (splitscreenDialog.splitPosition === 'upper-left' || splitscreenDialog.splitPosition === 'left-2') ? 'flex' : 'none', backgroundColor: upperRightSplitOverlay }} onClick={this.noSplitscreenSelection}></div>
                <div className={classNames(classes.upperLeftSplitOverlay, "h-1/2 w-1/2")} style={{ display: show && splitscreenDialogs.length > 1 && (splitscreenDialog.splitPosition === 'lower-left' || splitscreenDialog.splitPosition === 'lower-left-2') ? 'flex' : 'none', backgroundColor: upperLeftSplitOverlay }} onClick={this.noSplitscreenSelection}></div>
                <div className={classNames(classes.lowerRightSplitOverlay, "h-1/2 w-1/2")} style={{ display: show && splitscreenDialogs.length > 1 && (splitscreenDialog.splitPosition === 'upper-right' || splitscreenDialog.splitPosition === 'upper-right-2') ? 'flex' : 'none', backgroundColor: lowerRightSplitOverlay }} onClick={this.noSplitscreenSelection}></div>
                <div className={classNames(classes.lowerLeftSplitOverlay, "h-1/2 w-1/2")} style={{ display: show && splitscreenDialogs.length > 1 && (splitscreenDialog.splitPosition === 'lower-right' || splitscreenDialog.splitPosition === 'right-2') ? 'flex' : 'none', backgroundColor: lowerLeftSplitOverlay }} onClick={this.noSplitscreenSelection}></div>

                <Button
                    className={classNames(classes.skipBtn)} 
                    variant='contained' 
                    color='primary'
                    size='small'
                    style={{ 
                        //marginTop: -100, 
                        //height: 50,
                        display: show && splitscreenDialogs.length > 1 ? this.state.skip ? splitscreenDialog.splitPosition !== 'left' && splitscreenDialog.splitPosition !== 'right' ? 'flex' : 'none' : 'none' : 'none',
                        left: skipLeft,
                        right: skipRight,
                        top: skipTop,
                        zIndex: 21,
                        position: 'absolute',
                    }}
                    //sx={ width: 20, padding: 1, margin: 2 }
                    onClick={(e) => {
                        e.stopPropagation()
                        this.skipSelection()
                    }}p
                >
                    <Icon>{arrowIcon}</Icon>
                </Button>  

                <div 
                    className={classNames(classes.splitDialogsDiv)} 
                    style={{ 
                        display: show ? 'flex' : 'none',
                        left: left,
                        right: right,
                        top: top,
                        zIndex: 20,
                        overflowY: 'auto',
                        maxWidth: '45vw',
                        height: splitscreenDialog.splitPosition === 'left' || splitscreenDialog.splitPosition === 'right' || splitscreenDialog.splitPosition === 'left-2' || splitscreenDialog.splitPosition === 'lower-right-2'  ? 'auto' : 400,
                    }}
                >
                    {
                        splitscreenDialogs.map((dialog, index) => {
                            return (
                                <Card 
                                    key={index} 
                                    style={{ 
                                        display: dialog.split || dialog.docked ? 'none' : 'flex',
                                        flexDirection: 'column',
                                        width: '20vw',
                                        //height: '35vh',
                                        height: splitscreenDialog.splitPosition === 'left' || splitscreenDialog.splitPosition === 'right' || splitscreenDialog.splitPosition === 'upper-left-2' || splitscreenDialog.splitPosition === 'lower-right-2'  ? '35vh' : '25vh',
                                        //maxWidth: matches.medium ? 300 : 350,
                                        //maxHeight: 300,                      
                                    }} 
                                    className={classNames(classes.splitDialog, 'mr-20 mb-20')} 
                                    onClick={() => this.toggleOpen(dialog)}
                                >
                                    <CardHeader
                                        className={classNames(classes.cardHeader)}
                                        title={<Typography className="flex" style={{ alignItems: 'center' }}>{dialog.header}</Typography>}
                                        avatar={
                                            <Icon className="mt-8">{dialog.icon}</Icon>
                                        }
                                        action={
                                            <React.Fragment>
                                                <IconButton onClick={(e) => this.close(e, dialog)} style={{ color: 'white' }} classes={{ root: !show ? "px-6 py-0 mt-0" : "p-8" }} className={show ? "mt-8 mr-4" : "mt-8"}>
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            </React.Fragment>
                                        }
                                        style={{ padding: 8 }}
                                    />
                                    <CardContent style={{ padding: 0}}>
                                        { dialog.image ?
                                            <div className={classNames(classes.cropDiv)} >
                                                <img src={dialog.image} 
                                                    style={{ 
                                                    //height: splitscreenDialog.splitPosition === 'left' || splitscreenDialog.splitPosition === 'right' || splitscreenDialog.splitPosition === 'upper-left-2' || splitscreenDialog.splitPosition === 'lower-right-2'  ? '45vh' : '25vh', 
                                                    width: '100%'
                                                }} />
                                            </div>
                                            :
                                            <div>
                                                <p style={{ textAlign: 'center' }}>{dialog.description}</p>
                                            </div>
                                        }
                                    </CardContent>
                                </Card>
                            );
                        })
                    }
                    {/* <div class="break" style={{flexBasis: '100%', height: 0}}></div>
                    <Button 
                        variant='contained' 
                        color='primary' 
                        style={{ 
                            marginTop: -100, 
                            height: 50,
                            display: this.state.skip ? 'flex' : 'none',
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            this.skipSelection()
                        }}p
                    >
                        Skip
                    </Button>     */}
                </div>
                </>
            }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addSplitscreenDialog: Actions.addSplitscreenDialog,
        setSplitscreenDialog: Actions.setSplitscreenDialog,
        updateAllMinimizedDialogs,
        updateAllMinimizedDialogs2,
    }, dispatch);
}


function mapStateToProps({ spReducers, splitscreenDialogApp, minimizedDialogApp }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        splitscreenDialogs: splitscreenDialogApp.splitscreenDialog.data,
        splitscreenDialog: splitscreenDialogApp.splitscreenDialog,
        cornered: splitscreenDialogApp.splitscreenDialog.cornered,
        minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(SplitscreenDialogs));