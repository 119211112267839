import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTimecardIds: [],
    routeParams: {},
    timecardDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        date: new Date(),
    }
};

const timecardsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TIMECARDS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.TOGGLE_IN_SELECTED_TIMECARDS:
            {

                const timecardId = action.timecardId;

                let selectedTimecardIds = [...state.selectedTimecardIds];

                if (selectedTimecardIds.find(id => id === timecardId) !== undefined) {
                    selectedTimecardIds = selectedTimecardIds.filter(id => id !== timecardId);
                }
                else {
                    selectedTimecardIds = [...selectedTimecardIds, timecardId];
                }

                return {
                    ...state,
                    selectedTimecardIds: selectedTimecardIds
                };
            }
        case Actions.SELECT_ALL_TIMECARDS:
            {
                const { selectedTimecardIds } = action;
                return {
                    ...state,
                    selectedTimecardIds,
                };
            }
        case Actions.DESELECT_ALL_TIMECARDS:
            {
                return {
                    ...state,
                    selectedTimecardIds: []
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.OPEN_NEW_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_NEW_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: { ...action.data },
                        supervisor: action.supervisor
                    }
                };
            }
        case Actions.DATA_EDIT_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'data',
                        props: {
                            ...state.timecardDialog.props
                        },
                        data: { ...action.data },
                    }
                };
            }
        case Actions.ADD_EDIT_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: { ...action.data },
                    }
                };
            }
        case Actions.UPDATE_EDIT_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: { ...action.data },
                    }
                };
            }
        case Actions.CLOSE_EDIT_TIMECARD_DIALOG:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.SET_TIMECARD:
            {
                return {
                    ...state,
                    timecardDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data,
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default timecardsReducer;
