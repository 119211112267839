import React from 'react';
import { withStyles, Avatar, ListItem, Typography } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import classNames from 'classnames';
import moment from 'moment';
import _ from '@lodash';
import getProfileImage from '../../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    commentBubble: {
        borderRadius: '5px 20px 20px 5px',
        border: '1px solid ' + theme.palette.divider
    }
});

const CardActivity = ({ item, users, classes }) => {

    const avatar = 'assets/images/avatars/profile.jpg';
    const user = _.find(users, { UserName: item.UserName });

    return (
        <ListItem dense className="px-0">
            <Avatar alt={user.FirstName} src={user && user.Data && user.Data.Avatar ? getProfileImage(`Co=${item.Co}&ID=${user.Data.Avatar}`) : avatar} className="w-32 h-32" />
            <div className={classNames(classes.commentBubble, "flex flex-col ml-16 p-12")}>
                <div className="flex items-center">
                    <Typography className="font-bold">{`${user.FirstName} ${user.LastName}`}</Typography>
                    <Typography className="ml-8 text-12" color="textSecondary">{moment(moment(item.AddedDate)).local().toDate().toLocaleString('en-US')}</Typography>
                </div>
                <Typography>{item.Comment}</Typography>
            </div>
        </ListItem>
    )
};

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(CardActivity));
