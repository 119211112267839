import { Button, Icon, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import _ from '@lodash';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    root: {

    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 24,
        marginTop: 24,
    },
    customerSelect: {
        padding: 2,
        minHeight: 512,
        minWidth: '25%',
        border: '1px solid lightgrey',
        marginRight: 12,
        marginBottom: 24,
        borderRadius: 5,
        background: 'rgba(0, 0, 0, .05)',
    },
    customerCard: {
        borderRadius: 5,
        boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
    },
    customerAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    chip: {
        color: '#fff',
        backgroundColor: '#3f3f3f',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        marginBottom: 12,
        marginLeft: 8,
        height: 24,
    },
    chipLabel: {
        paddingLeft: 8,
        fontWeight: 'bold',
        color: '#fff',
        paddingBottom: 2,
    },
    chipAvatar: {
        marginLeft: -12,
        color: '#3f3f3f',
        backgroundColor: '#3f3f3f',
        border: '3px solid #3f3f3f',
    },
    chipAvatarIcon: {
        color: '#3f3f3f',
        backgroundColor: '#fff',
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    postedMatch: {
        color: theme.palette.secondary.dark
    },
    postedNoMatch: {
        color: theme.palette.error[500]
    },
    notPosted: {
        color: '#5f5f5f'
    },
    postedBatch: {
        color: theme.palette.primary.main
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
});
const initialState = {
    taxability: [],
    tax: null,
};

class EstimateFeatures extends Component {
    state = { ...initialState };

    componentDidMount() {
        const { taxability } = this.props;
        this.setState({ ...this.state, taxability });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { taxability } = this.props;
        if (!_.isEqual(prevProps.taxability, taxability)) {
            this.setState({ ...this.state, taxability });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    render() {
        const { customer, editable, costTypeCategories, onChange } = this.props;
        const { taxability } = this.state;
        return (
            <React.Fragment>
                <ul>
                    {[...taxability].map((value, index) => {
                        const { Co, CostTypeCategory, DeleteYN } = value;
                        const cat = _.find(costTypeCategories, { CostTypeCategory: value.CostTypeCategory });
                        if (DeleteYN !== 'Y')
                            return (
                                <li key={value.CostTypeCategory} className="mb-6">
                                    <div className={editable ? "text-14 flex" : "text-12"}>
                                        <div className={editable ? "float-left truncate w-full" : "w-full"}>
                                            {cat.Name}
                                        </div>
                                        {editable &&
                                            <div className="float-right text-right w-full sm:w-128">
                                                <Icon onClick={() => {
                                                    const index = _.findIndex(taxability, { CostTypeCategory });
                                                    const tax = _.find(taxability, { CostTypeCategory });
                                                    if (tax) {
                                                        if (!tax.AddedBy) {
                                                            taxability.splice(index, 1);
                                                        } else {
                                                            tax.DeleteYN = 'Y';
                                                        }
                                                    }
                                                    this.setState({ ...this.state, taxability }, () => { if (onChange) { onChange(this.state.taxability) } });
                                                }} className="align-middle ml-12 text-16 cursor-pointer"
                                                    color="error">delete</Icon>
                                            </div>
                                        }
                                    </div>
                                </li>
                            );
                    })
                    }
                </ul>
                {
                    this.state.tax &&
                    <ul>
                        {this.state.tax &&
                            <li key={this.state.tax.CostTypeCategory}>
                                <div className="flex clear">
                                    <TextField
                                        className={classNames("mr-8")}
                                        label="Category"
                                        select
                                        id="costTypeCategory"
                                        name="tax.CostTypeCategory"
                                        value={this.state.tax.CostTypeCategory || ''}
                                        onChange={this.handleChange}
                                        autoFocus
                                        margin="dense"
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >
                                        {(costTypeCategories && costTypeCategories.length > 0 ? costTypeCategories.map((value, index) => (
                                            <MenuItem key={index} value={value.CostTypeCategory}>
                                                {value.Name}
                                            </MenuItem>
                                        )) : '')}
                                    </TextField>
                                    <Button
                                        variant="contained"
                                        className="w-72 mt-8 mb-4"
                                        color="primary"
                                        onClick={() => {
                                            const { tax } = this.state;
                                            taxability.push(tax);
                                            // const newTax = {
                                            //     Co,
                                            //     Customer: customer,
                                            //     CostTypeCategory: null,
                                            //     TaxableYN: 'N',
                                            // };
                                            this.setState({ ...this.state, tax: null, taxability }, () => { if (onChange) { onChange(this.state.taxability) } });

                                        }}
                                        disabled={!this.state.tax.CostTypeCategory}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </li>
                        }
                    </ul>
                }
                {!this.state.tax &&
                    <Typography
                        variant="subtitle2"
                        style={{ alignItems: 'center', borderTop: this.state.Taxability && this.state.Taxability.length > 0 ? '1px solid #f1f1f1' : "none" }}
                        className={classNames("text-12 flex cursor-pointer justify-start", this.state.Taxability && this.state.Taxability.length > 0 ? "pt-8" : "")}
                        onClick={
                            () => {
                                const { Co, Customer } = customer;
                                const tax = {
                                    Co,
                                    Customer,
                                    CostTypeCategory: null,
                                    TaxableYN: 'N',
                                };
                                // Taxability.push(tax);
                                this.setState({ ...this.state, tax, /*Data: { ...this.state.Data, Taxability }*/ });
                            }
                        }
                    >
                        <Icon color="primary" className="text-18 mr-4" style={{ marginBottom: 2 }}>add</Icon>
                        New Category
                    </Typography>
                }
            </React.Fragment>

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ estimatesApp, spReducers, emailTemplateApp }) {
    return {
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User,
        costTypeCategories: spReducers.costTypeCategories,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(EstimateFeatures));
