import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardContent, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Stepper, Step, StepButton, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { NavLink, withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import CallHandlerDraftCard from './CallHandlerDraftCard';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    card: {
        marginTop: 12,
        cursor: 'pointer',
    },
    stepper: {
        padding: 0 + '!important',
    },
    workflow: {
        padding: 12,
        paddingTop: 0,
    },
    badge: {
        top: 6,
        right: 4,
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    }
});

function getSteps() {
    return [{ Name: 'Customer', Icon: 'account_circle' }, { Name: 'Service Site', Icon: 'location_on' }, { Name: 'Work Order', Icon: 'assignment' }];
}

class CallHandlerLeftSidebarContent extends Component {
    state = {
        drafts: {}
    };

    componentDidMount() {
        const { drafts } = this.props.callHandler;
        if (!_.isEqual(drafts, this.state.drafts)) {
            this.setState({ drafts });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { drafts } = this.props.callHandler;
        if (!_.isEqual(drafts, this.state.drafts)) {
            this.setState({ drafts });
        }
    }
    render() {
        const { classes, user, openCallDraft, callID } = this.props;
        const { drafts } = this.state;
        const steps = getSteps();
        return (
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <div className="p-12 pl-16">

                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex">{Object.keys(drafts).length > 0 ? <Badge color="error" classes={{ badge: classes.badge, }} className="mr-12" badgeContent={Object.keys(drafts).length}><Icon color="primary" className="text-32">assignment_ind</Icon></Badge> : <Icon color="primary" className="text-32 mr-12">assignment_ind</Icon>}Drafts</Typography>

                    <Divider />
                    <FuseAnimateGroup enter={{ animation: "transition.slideUpBigIn" }} leave={{ animation: "transition.slideUpBigOut" }} delay={300}>
                        {
                            Object.keys(drafts).map((key) => (
                                <CallHandlerDraftCard draft={drafts[key]} id={key} />
                            )
                            )
                        }
                    </FuseAnimateGroup>
                </div>
            </FuseAnimate>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCallDraft: Actions.openCallDraft,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, contactsApp }) {
    return {
        user: contactsApp.user,
        callHandler: callHandlerApp.callHandler,
        callID: callHandlerApp.callHandler.id,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallHandlerLeftSidebarContent)));
