import _ from '@lodash';
import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { renderToStaticMarkup } from "react-dom/server";

export const ReportHeader = (props) => {

    const { master } = props;
    const Report = _.cloneDeep(props.Report);

    const getReportParameter = (name) => {
        let param = _.find((Report.Parameters || []), { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    const division = _.find(master['divisions'], { Co: getReportParameter('@Co') });

    const dateParam = _.find((Report.Parameters || []), { Parameter: "@StartDate" });
    if (dateParam) {
        Report.Data.Parameters = _.filter(Report.Data.Parameters, (o) => o.Parameter !== "@EndDate")
        dateParam.Value += ` - ${getReportParameter("@EndDate") || moment().format("MM/DD/YYYY")}`;
    }

    const yearParam = _.find((Report.Parameters || []), { Parameter: "@StartYear" });
    if (yearParam) {
        Report.Data.Parameters = _.filter(Report.Data.Parameters, (o) => o.Parameter !== "@EndYear")
        yearParam.Value += ` - ${getReportParameter("@EndYear") || moment().format("YYYY")}`;
    }

    return (
        <div id="report-template" className="bg-white flex justify-between" style={{ alignItems: 'center', ...(props.style || {}) }}>
            {division && division.InvoiceHeaderImg ?
                <img
                    src={division.InvoiceHeaderImg || ""}
                    style={{ height: '1in', maxWidth: '40%' }}
                />
                :
                <div></div>
            }

            <div id="report-template-params" className="text-right">
                <h2>{Report.Name}</h2>
                {Report && Report.Data && Report.Data.Parameters && Report.Data.Parameters.map((value) => {
                    const paramVal = getReportParameter(value.Parameter);
                    value.text = value.ListValueText ? JSON.parse(value.ListValueText) : [];

                    let displayText;
                    if (value.List) {
                        const option = _.find((_.get(master, value.List) || []), { [value.ListValue]: paramVal })
                        if (value.text && value.text.length > 0) {
                            value.text.map((name) => {
                                const text = _.get(option, name);
                                if (text) {
                                    if (!displayText) {
                                        displayText = '';
                                    }
                                    displayText += `${text} `;
                                }
                            })
                        }
                    }
                    return (
                        <div className="py-0 mt-4 mb-0 pt-0 text-16">{displayText || paramVal}</div>
                    )
                })}
            </div>
        </div>
    )

}

function ReportTemplate(props) {

    const { master, Report, pageNum, totalPages, horizontalMargin } = props;

    return renderToStaticMarkup(
        <div className="w-full bg-white">
            <ReportHeader
                style={{ position: 'absolute', inset: `0.15in ${horizontalMargin}in 0 ${horizontalMargin}in`, height: '1.5in' }}
                master={master}
                Report={Report}
            />

            <div style={{ position: 'absolute', lineHeight: '0.2in', bottom: '0.2in', left: '50%', transform: 'translateX(-50%)', fontWeight: 700 }}>
                Page {pageNum} of {totalPages}
            </div>

        </div>
    )

}

export default ReportTemplate;