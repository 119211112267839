import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class EmployeesList extends Component {

    state = {
        selectedEmployeesMenu: null
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {

            return arr;
        }

        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedEmployeeMenu = (event) => {
        this.setState({ selectedEmployeesMenu: event.currentTarget });
    };

    closeSelectedEmployeesMenu = () => {
        this.setState({ selectedEmployeesMenu: null });
    };

    formatDate(date) {

        const dt = new Date(date);
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return (date ? formatted : '');
    }

    render() {
        const { classes, securables, employees, user, searchText, selectedEmployeeIds, openNewEmployeeDialog, selectAllEmployees, deSelectAllEmployees, toggleInSelectedEmployees, openEditEmployeeDialog, removeEmployees, removeEmployee, toggleStarredEmployee, setEmployeesUnstarred, setEmployeesStarred, openNewWorkOrderDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedEmployeeData ? (!this.props.employeeData ? [] : this.props.employeeData) : this.props.selectedEmployeeData), searchText);
        const { selectedEmployeesMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "employees" });

        const avatar = 'assets/images/avatars/profile.jpg';
        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewEmployeeDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">people</Icon>Employee List</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditEmployeeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "",
                                    fixed: "left",
                                    accessor: "",
                                    Cell: row => (
                                        <Avatar className="mr-8" alt={row.original.name} src={(row.original.Data && row.original.Data.Avatar ? getProfileImage(`Co=${row.original.Co}&ID=${row.original.Data.Avatar}&Thumb=true` ): avatar)} />
                                    ),
                                    className: "justify-center",
                                    width: 64,
                                    sortable: false
                                },
                                {
                                    Header: "Employee",
                                    accessor: "Employee",
                                    //filterable: true,
                                    className: "font-bold justify-center",
                                },
                                {
                                    Header: "First Name",
                                    accessor: "FirstName",
                                    //filterable: true,
                                },
                                {
                                    Header: "Last Name",
                                    accessor: "LastName",
                                    //filterable: true,
                                },
                                {
                                    Header: "MI",
                                    accessor: "MiddleInitial",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Suffix",
                                    accessor: "Suffix",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Gender",
                                    accessor: "Gender",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "DOB",
                                    id: "dob",
                                    accessor: d => this.formatDate(d.DOB),
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Phone",
                                    accessor: "Phone",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Cell",
                                    accessor: "CellPhone",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Email",
                                    accessor: "Email",
                                    width: 256,
                                    //filterable: true,
                                },
                                {
                                    Header: "Business Unit",
                                    accessor: "DefaultBusinessUnit",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Division",
                                    accessor: "DefaultDivision",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Department",
                                    accessor: "DefaultDepartment",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Active",
                                    accessor: "ActiveYN",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                            ]
                        }
                        defaultPageSize={10}
                        noDataText="No Employees found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEmployees: Actions.getEmployees,
        getUserData: Actions.getUserData,
        toggleInSelectedEmployees: Actions.toggleInSelectedEmployees,
        selectAllEmployees: Actions.selectAllEmployees,
        deSelectAllEmployees: Actions.deSelectAllEmployees,
        openEditEmployeeDialog: Actions.openEditEmployeeDialog,
        removeEmployees: Actions.removeEmployees,
        removeEmployee: Actions.removeEmployee,
        toggleStarredEmployee: Actions.toggleStarredEmployee,
        toggleStarredEmployees: Actions.toggleStarredEmployees,
        setEmployeesStarred: Actions.setEmployeesStarred,
        setEmployeesUnstarred: Actions.setEmployeesUnstarred,
        openNewEmployeeDialog: Actions.openNewEmployeeDialog
    }, dispatch);
}

function mapStateToProps({ spReducers, employeesApp }) {
    window["log"](spReducers, employeesApp);
    return {
        employees: employeesApp.employees.entities,
        selectedEmployeeIds: employeesApp.employees.selectedEmployeeIds,
        searchText: employeesApp.employees.searchText,
        user: employeesApp.user,
        employeeData: spReducers.employees,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeesList)));
