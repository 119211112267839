import axios from 'axios/index';

export const SET_NOTIFICATION_DATA = '[SPCONNECTION] SET NOTIFICATION DATA';
export const GET_NOTIFICATION_DATA = '[SPCONNECTION] GET NOTIFICATION DATA';
export const SUBSCRIBE_NOTIFICATION_LIST = '[SPCONNECTION] SUBSCRIBE NOTIFICATION LIST';

export function getInitialNotificationData() {
    const request = axios.get(`${window["apiLocation"]}/api/Notification`);
    request.then((response) => {
        return response.data;
    });
}

export function getNotificationData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Notification`);

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_NOTIFICATION_DATA,
                payload: response.data
            });
        });
    }
}

export function setNotificationData(data) {
    return {
        type: SET_NOTIFICATION_DATA,
        payload: data
    };
}