import {combineReducers} from 'redux';
import payTerms from './pay-terms.reducer';
import user from './user.reducer';

const payTermsAppReducers = combineReducers({
    payTerms,
    user
});

export default payTermsAppReducers;
