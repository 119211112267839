import ServiceUnitsApp from 'main/content/apps/service-units/ServiceUnitsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const ServiceUnitsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/service-units/:id',
            component: ServiceUnitsApp
        },
        {
            path     : '/apps/service-units',
            component: () => <Redirect to="/apps/service-units/all"/>
        }
    ]
};
