import axios from 'axios';
import { getUserData } from 'main/content/apps/material-categories/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_MATERIAL_CATEGORIES = '[MATERIAL CATEGORIES APP] GET MATERIAL CATEGORIES';
export const SET_SEARCH_TEXT = '[MATERIAL CATEGORIES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_MATERIAL_CATEGORIES = '[MATERIAL CATEGORIES APP] TOGGLE IN SELECTED MATERIAL CATEGORIES';
export const SELECT_ALL_MATERIAL_CATEGORIES = '[MATERIAL CATEGORIES APP] SELECT ALL MATERIAL CATEGORIES';
export const DESELECT_ALL_MATERIAL_CATEGORIES = '[MATERIAL CATEGORIES APP] DESELECT ALL MATERIAL CATEGORIES';
export const OPEN_NEW_MATERIAL_CATEGORY_DIALOG = '[MATERIAL CATEGORIES APP] OPEN NEW MATERIAL CATEGORY DIALOG';
export const CLOSE_NEW_MATERIAL_CATEGORY_DIALOG = '[MATERIAL CATEGORIES APP] CLOSE NEW MATERIAL CATEGORY DIALOG';
export const OPEN_EDIT_MATERIAL_CATEGORY_DIALOG = '[MATERIAL CATEGORIES APP] OPEN EDIT MATERIAL CATEGORY DIALOG';
export const CLOSE_EDIT_MATERIAL_CATEGORY_DIALOG = '[MATERIAL CATEGORIES APP] CLOSE EDIT MATERIAL CATEGORY DIALOG';
export const ADD_MATERIAL_CATEGORY = '[MATERIAL CATEGORIES APP] ADD MATERIAL CATEGORY';
export const UPDATE_MATERIAL_CATEGORY = '[MATERIAL CATEGORIES APP] UPDATE MATERIAL CATEGORY';
export const REMOVE_MATERIAL_CATEGORY = '[MATERIAL CATEGORIES APP] REMOVE MATERIAL CATEGORY';
export const REMOVE_MATERIAL_CATEGORIES = '[MATERIAL CATEGORIES APP] REMOVE MATERIAL CATEGORIES';
export const TOGGLE_STARRED_MATERIAL_CATEGORY = '[MATERIAL CATEGORIES APP] TOGGLE STARRED MATERIAL CATEGORY';
export const TOGGLE_STARRED_MATERIAL_CATEGORIES = '[MATERIAL CATEGORIES APP] TOGGLE STARRED MATERIAL CATEGORIES';
export const SET_MATERIAL_CATEGORIES_STARRED = '[MATERIAL CATEGORIES APP] SET MATERIAL CATEGORIES STARRED ';

export function getMaterialCategories(routeParams) {
    const request = axios.get('/api/materialCategories-app/materialCategories', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_MATERIAL_CATEGORIES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedMaterialCategories(materialCategoryId) {
    return {
        type: TOGGLE_IN_SELECTED_MATERIAL_CATEGORIES,
        materialCategoryId
    }
}


export function selectAllMaterialCategories() {
    return {
        type: SELECT_ALL_MATERIAL_CATEGORIES
    }
}

export function deSelectAllMaterialCategories() {
    return {
        type: DESELECT_ALL_MATERIAL_CATEGORIES
    }
}


export function openNewMaterialCategoryDialog(data) {
    return {
        type: OPEN_NEW_MATERIAL_CATEGORY_DIALOG,
        data
    }
}

export function closeNewMaterialCategoryDialog() {
    return {
        type: CLOSE_NEW_MATERIAL_CATEGORY_DIALOG
    }
}

export function openEditMaterialCategoryDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/MaterialCategory?Co=${data.Co}&Category=${encodeURIComponent(data.Category)}`);

        return request.then((response) =>
            dispatch({
                type: OPEN_EDIT_MATERIAL_CATEGORY_DIALOG,
                data: response.data
            }));
    }
}

export function closeEditMaterialCategoryDialog() {
    return {
        type: CLOSE_EDIT_MATERIAL_CATEGORY_DIALOG
    }
}

export function addMaterialCategory(newMaterialCategory) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/MaterialCategory`,
            newMaterialCategory
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_MATERIAL_CATEGORY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Material Category #${newMaterialCategory.Category} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateMaterialCategory(materialCategory) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/MaterialCategory?Co=${encodeURIComponent(materialCategory.Co)}&Category=${encodeURIComponent(materialCategory.Category)}`,
            materialCategory
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_MATERIAL_CATEGORY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Material Category #${materialCategory.Category} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeMaterialCategory(materialCategoryId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().materialCategoriesApp.materialCategories;

        const request = axios.post('/api/materialCategories-app/remove-materialCategory', {
            materialCategoryId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_MATERIAL_CATEGORY
                })
            ]).then(() => dispatch(getMaterialCategories(routeParams)))
        );
    };
}


export function removeMaterialCategories(materialCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().materialCategoriesApp.materialCategories;

        const request = axios.post('/api/materialCategories-app/remove-materialCategories', {
            materialCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_MATERIAL_CATEGORIES
                }),
                dispatch({
                    type: DESELECT_ALL_MATERIAL_CATEGORIES
                })
            ]).then(() => dispatch(getMaterialCategories(routeParams)))
        );
    };
}

export function toggleStarredMaterialCategory(materialCategoryId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().materialCategoriesApp.materialCategories;

        const request = axios.post('/api/materialCategories-app/toggle-starred-materialCategory', {
            materialCategoryId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_MATERIAL_CATEGORY
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getMaterialCategories(routeParams)))
        );
    };
}

export function toggleStarredMaterialCategories(materialCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().materialCategoriesApp.materialCategories;

        const request = axios.post('/api/materialCategories-app/toggle-starred-materialCategories', {
            materialCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_MATERIAL_CATEGORIES
                }),
                dispatch({
                    type: DESELECT_ALL_MATERIAL_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getMaterialCategories(routeParams)))
        );
    };
}

export function setMaterialCategoriesStarred(materialCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().materialCategoriesApp.materialCategories;

        const request = axios.post('/api/materialCategories-app/set-materialCategories-starred', {
            materialCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_MATERIAL_CATEGORIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_MATERIAL_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getMaterialCategories(routeParams)))
        );
    };
}

export function setMaterialCategoriesUnstarred(materialCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().materialCategoriesApp.materialCategories;

        const request = axios.post('/api/materialCategories-app/set-materialCategories-unstarred', {
            materialCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_MATERIAL_CATEGORIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_MATERIAL_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getMaterialCategories(routeParams)))
        );
    };
}
