import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Parser } from 'json2csv';
import { DatePicker } from "@material-ui/pickers";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as DispatchActions from '../dispatch-board/store/actions';
import { updateTableSettings } from '../users/store/actions';
import * as WorkOrderScopeActions from '../work-order-scopes/store/actions';
import { getWorkOrderHistory } from '../work-order-history/store/actions/work-order-history.actions';
import WorkOrderHistory from '../work-order-history/WorkOrderHistoryApp';
import * as Actions from './store/actions';
import axios from 'axios';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Work Orders Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class WorkOrdersList extends Component {

    state = {
        selectedWorkOrdersMenu: null,
        date: new Date(),
        loading: false,
        isDatepickerOpen: false,
        hist: null
    };

    componentDidMount() {
        let { date, loading, hist } = this.props;
        if (date) {
            this.setState({ ...this.state, date, loading, hist });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { date, loading, hist } = this.props;
        if (!_.isEqual(date, prevProps.date) || !_.isEqual(loading, prevProps.loading) || !_.isEqual(hist, prevProps.hist)) {
            this.setState({ ...this.state, date, loading, hist });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedWorkOrderMenu = (event) => {
        this.setState({ selectedWorkOrdersMenu: event.currentTarget });
    };

    closeSelectedWorkOrdersMenu = () => {
        this.setState({ selectedWorkOrdersMenu: null });
    };

    handleDateChange = (event) => {
        window["warn"](event);
        const date = event._d;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        const newDate = this.formatDateInput(date);
        if (oldDate !== newDate) {
            this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
                this.props.setBoardDate(this.props.Co, date, oldDate);
                this.updateDate(this.props.Co, newDate, oldDate);
            });
        }
    }

    handleToday = (event) => {
        const date = new Date();
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, oldDate);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    handleNextDate = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        date.setDate(date.getDate() + 1);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    handlePrevDate = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        date.setDate(date.getDate() - 1);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    updateDate = _.debounce((Co, date, oldDate) => { this.props.getTripData(Co, date, oldDate); this.props.getWOData(Co, date, oldDate); }, 1000);

    formatDateInput(dt) {
        return dt.toLocaleDateString('en-US').replace(/\//g, '-');
    }

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    openDatePicker = (e) => {
        this.setDatepickerOpen(true);
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    handleRefresh = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        this.props.setBoardDate(this.props.Co, date, old);
        this.updateDate(this.props.Co, oldDate, oldDate);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    handleRowCount = (count) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'work-orders' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'work-orders',
                Preferences: {
                    RowCount: count
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.RowCount = count;
        }
        this.props.updateTableSettings(prefs);
    }

    handleSortOrder = (sort) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'work-orders' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'work-orders',
                Preferences: {
                    SortOrder: sort
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.SortOrder = sort;
        }
        this.props.updateTableSettings(prefs);
    }

    export = () => {
        const data = this.props.selectedWOData;
        const csvData = new Parser();
        const Data = csvData.parse(data);
        var encoded = encodeURIComponent(Data);
        var csv = `data:text/csv;charset=utf-8, ${encoded}`;
        var link = document.createElement("a");
        link.setAttribute("href", csv);
        link.setAttribute("download", "Work Orders.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    setDatepickerOpen = (bool) => {
        this.setState({ isDatepickerOpen: bool });
    }

    fetchData = (Co, Page) => {
        const { customer, site, hist } = this.props;
        if (hist) {
            this.setState({ loading: true }, () => {
                const request = axios.get(`${window["apiLocation"]}/api/WorkOrder?Co=${Co}&Customer=${customer}${site ? `&Site=${site}` : ''}&Page=${Page}`);
                request.then((response) => {
                    this.setState({ hist: response.data, loading: false });
                }).catch(() => {
                    this.setState({ loading: false });
                })
            });
        }
    }

    render() {
        const { classes, securables, audit, loading2, workOrders, user, searchText, openNewWorkOrderDialog, openEditWorkOrderDialog, history, getWorkOrderHistory, woDialog, Co } = this.props;
        const { TableSettings } = user.Data;
        const { selectedWorkOrdersMenu, date, loading, hist } = this.state;
        const data = this.props.selectedWOData ? this.props.selectedWOData : loading ? [] : this.getFilteredArray(this.props.workOrderData || [], searchText);
        const statuses = ['Open', 'In Progress', 'On Hold', 'Closed', 'Canceled'];
        const accessLevel = _.find(securables, { Securable: "work-orders" });
        const prefs = _.find(TableSettings, { Table: 'work-orders' });
        const manualOptions = (hist ? {
            manual: true,
            data: hist.Results,
            pages: hist.PageCount,
            onFetchData: (e) => { this.fetchData(Co, e.page + 1); },
            defaultPageSize: 10,
            pageSizeOptions: [10],
            sortable: false
        } : {
            data,
            defaultSorted: prefs ? (prefs.Preferences.SortOrder || []) : [],
            defaultPageSize: prefs ? (prefs.Preferences.RowCount || 100) : 10,
            onPageSizeChange: this.handleRowCount,
            onSortedChange: this.handleSortOrder,
        });

        return (
            <FuseAnimate animation="transition.slideUpIn" className="h-full" delay={300}>
                <div className="h-full">
                    {audit &&
                        <Fab
                            color="primary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={this.export}
                        // onClick={openNewUserDialog}
                        >
                            <Icon className="text-16">save_alt</Icon>
                        </Fab>
                    }
                    {woDialog &&
                        <WorkOrderHistory />
                    }
                    <div className="sm:flex p-12 sm:p-0">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><Icon color="primary" className="text-32 mr-12">assignment</Icon>
                            <span>Work Orders</span>
                            {(!this.props.selectedWOData || this.props.technician) && !audit && <Icon color="action" onClick={this.handleRefresh} className={!loading ? "text-24 ml-8 mt-4 cursor-pointer" : "text-24 ml-8 mt-4 cursor-pointer spin"}>refresh</Icon>}
                        </Typography>
                        {(!this.props.selectedWOData || this.props.technician) && !audit &&
                            <div className="flex sm:absolute sm:pin-r pt-4">
                                <Tooltip title="View Today's Work Orders" placement="top">
                                    <div className="flex mr-12">
                                        <Icon onClick={this.handleToday} className="text-24 cursor-pointer" color="action">today</Icon>
                                    </div>
                                </Tooltip>
                                <Typography variant="caption" style={{ fontWeight: 'bold', alignItems: 'center', }} className="flex">
                                    <Tooltip title="Select Date" placement="top">
                                        <div className="picker">
                                            <DatePicker
                                                inputVariant="outlined"
                                                onOpen={() => this.setDatepickerOpen(true)}
                                                onClose={() => this.setDatepickerOpen(false)}
                                                open={this.state.isDatepickerOpen}
                                                label="Date"
                                                inputProps={
                                                    {
                                                        style: {
                                                            padding: 12,
                                                        }
                                                    }
                                                }
                                                TextFieldComponent={this.renderDateLabel}
                                                value={date}
                                                onChange={this.handleDateChange}
                                                showTodayButton
                                                autoOk
                                                animateYearScrolling
                                            />
                                        </div>
                                    </Tooltip>
                                    <Icon onClick={this.handlePrevDate} color="action" className="text-24 mr-4 cursor-pointer">keyboard_arrow_left</Icon>
                                    <Icon onClick={this.handleNextDate} color="action" className={`text-24 ml-4 cursor-pointer ${!this.props.technician ? 'mr-12' : ''}`}>keyboard_arrow_right</Icon>
                                    {accessLevel && accessLevel.AccessLevel !== "R" && !this.props.technician &&
                                        <Fab
                                            color="secondary"
                                            aria-label="add"
                                            className={classes.addButton}
                                            style={{ zIndex: 9, }}
                                            onClick={openNewWorkOrderDialog}
                                        >
                                            <Icon>add</Icon>
                                        </Fab>
                                    }
                                </Typography>
                            </div>
                        }
                    </div>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        if (woDialog) {
                                            getWorkOrderHistory(rowInfo.original, true);
                                        } else {
                                            openEditWorkOrderDialog(rowInfo.original);
                                        }


                                    }
                                }
                            }
                        }}
                        getTfootTrProps={() => {
                            return {
                                className: "font-bold text-center",
                            }
                        }}
                        {...manualOptions}
                        loading={loading || this.state.loading} // Display the loading overlay when we need it
                        LoadingComponent={loading ? LoadingDiv : undefined}
                        NoDataComponent={this.props.selectedWOData ? NoData : loading2 ? LoadingDiv : !audit && loading ? LoadingDiv : NoData}
                        columns={[
                            {
                                Header: "Work Order",
                                accessor: "WorkOrder",
                                className: "font-bold justify-center",
                                width: 128,
                                Footer: audit ? "Total" : null
                            },
                            {
                                Header: "Customer",
                                accessor: "Customer",
                                minWidth: 256,
                                className: "justify-center",
                                show: Boolean(audit),
                                Cell: row => {
                                    return `${row.original.Customer} - ${row.original.CustomerName}`
                                }
                            },
                            {
                                Header: "Site",
                                accessor: "Site",
                                className: "justify-center",
                                width: 128
                            },
                            {
                                Header: "Address",
                                accessor: "SiteAddress",
                                minWidth: 320,
                                className: "justify-center",
                                show: Boolean(audit),
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 400
                            },
                            {
                                Header: <Icon color="action">voicemail</Icon>,
                                accessor: "CallSid",
                                className: "justify-center",
                                width: 64,
                                Cell: row => {
                                    if (row.original.CallSid) {
                                        return (
                                            <Icon color="primary">voicemail</Icon>
                                        )
                                    }
                                },
                                show: Boolean(audit)
                            },
                            {
                                Header: "Scopes",
                                accessor: "ScopeCount",
                                className: "justify-center",
                                width: 128,
                                show: Boolean(audit),
                                Footer: _.sumBy(data, 'ScopeCount')
                            },
                            {
                                Header: "Trips",
                                accessor: "TripCount",
                                className: "justify-center",
                                width: 128,
                                show: Boolean(audit),
                                Footer: _.sumBy(data, 'TripCount')
                            },
                            {
                                Header: "Invoices",
                                accessor: "InvoiceCount",
                                className: "justify-center",
                                width: 128,
                                show: Boolean(audit),
                                Footer: _.sumBy(data, 'InvoiceCount')
                            },
                            {
                                Header: "Billed",
                                accessor: "InvoicedAmount",
                                className: "justify-center",
                                width: 96,
                                Cell: row => {
                                    return this.formatDollars(row.original.InvoicedAmount);
                                },
                                show: Boolean(audit),
                                Footer: this.formatDollars(_.sumBy(data, 'InvoicedAmount'))
                            },
                            {
                                Header: "Status",
                                accessor: "Status",
                                className: "justify-center",
                                Cell: row => {
                                    return statuses[row.original.Status];
                                },
                                width: 128
                            },
                            {
                                Header: "Entered By",
                                accessor: "EnteredBy",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Entered Date",
                                accessor: "EnteredDate",
                                className: "justify-center",
                                width: 250,
                            },
                        ]}
                        // defaultSorted={prefs ? (prefs.Preferences.SortOrder || []) : []}
                        // defaultPageSize={prefs ? (prefs.Preferences.RowCount || 100) : 100}
                        // onPageSizeChange={this.handleRowCount}
                        // onSortedChange={this.handleSortOrder}
                        noDataText="No Work Orders found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWorkOrders: Actions.getWorkOrders,
        getUserData: Actions.getUserData,
        toggleInSelectedWorkOrders: Actions.toggleInSelectedWorkOrders,
        selectAllWorkOrders: Actions.selectAllWorkOrders,
        deSelectAllWorkOrders: Actions.deSelectAllWorkOrders,
        openEditWorkOrderDialog: Actions.openEditWorkOrderDialog,
        removeWorkOrders: Actions.removeWorkOrders,
        removeWorkOrder: Actions.removeWorkOrder,
        toggleStarredWorkOrder: Actions.toggleStarredWorkOrder,
        toggleStarredWorkOrders: Actions.toggleStarredWorkOrders,
        setWorkOrdersStarred: Actions.setWorkOrdersStarred,
        setWorkOrdersUnstarred: Actions.setWorkOrdersUnstarred,
        openNewWorkOrderScopeDialog: WorkOrderScopeActions.openNewWorkOrderScopeDialog,
        getWorkOrderHistory,
        getWOData: DispatchActions.getWOData,
        getTripData: DispatchActions.getTripData,
        setTrips: DispatchActions.setTrips,
        setBoardDate: DispatchActions.setBoardDate,
        loadingTrips: DispatchActions.loadingTrips,
        updateTrips: DispatchActions.updateTrips,
        setSchedule: DispatchActions.setSchedule,
        openNewWorkOrderDialog: Actions.openNewWorkOrderDialog,
        updateTableSettings,
    }, dispatch);
}

function mapStateToProps({ spReducers, workOrdersApp, dispatchBoardApp }) {
    return {
        workOrders: workOrdersApp.workOrders.entities,
        selectedWorkOrderIds: workOrdersApp.workOrders.selectedWorkOrderIds,
        searchText: workOrdersApp.workOrders.searchText,
        user: spReducers.userProfiles.User,
        workOrderData: spReducers.workOrders,
        date: dispatchBoardApp.dispatchBoard.date,
        loading: dispatchBoardApp.dispatchBoard.loading,
        technicians: spReducers.technicians,
        trips: dispatchBoardApp.dispatchBoard.trips,
        schedule: dispatchBoardApp.dispatchBoard.schedule,
        Co: spReducers.companies.Co,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersList)));
