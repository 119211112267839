import _ from '@lodash'
import {
  Avatar,
  Button,
  Icon,
  MenuItem,
  Tooltip,
  Typography,
  Chip,
  Dialog,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  DialogContent,
  AppBar,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import SignatureCanvas from 'react-signature-canvas'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import InvoicePayment from './InvoicePayment'
import InvoicePaymentRecord from './InvoicePaymentRecord'
import * as Actions from './store/actions'
import Media from 'react-media'
import { formatPhone } from 'main/functions'

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 256px)',
    paddingBottom: 0,
  },
  avatar: {
    backgroundColor: theme.palette.primary[500],
  },
  techAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
  dividerPlain: {
    color: '#fff',
  },
  pageBreak: {},
})

const initialState = {
  selectedInvoiceIds: [],
  ranFetch: false,
  bulkInvoices: null,
  margin: null,
  printPDF: null,
  pdfOpen: false,
  pdfLoading: false,
}

class InvoiceBulkDialog extends Component {
  state = {
    ...initialState,
  }

  componentDidMount() {
    const { selectedInvoiceIds } = this.props
    this.setState({ ...this.state, selectedInvoiceIds })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedInvoiceIds, runFetch } = this.props
    const { ranFetch } = this.state

    if (runFetch !== prevProps.runFetch && ranFetch === false) {
      this.setState({ ...this.state, selectedInvoiceIds }, () =>
        this.fetchInvoices(),
      )
    }
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  formatPhone = str => {
    return formatPhone(str).formatted
  }

  fetchInvoices = async () => {
    const { Co, selectedInvoiceIds, fetchInvoices } = this.props

    this.setState({
      ...this.state,
      pdfOpen: true,
      pdfLoading: true,
      anchorEl: null,
    })

    await fetchInvoices(Co, selectedInvoiceIds).then(async res => {
      let margin

      let gridElement = res.data.map((invoice, i) => {
        const {
          classes,
          invoiceDialog,
          updateInvoiceDialog,
          materials,
          laborTypes,
          openEditWorkOrderUsageDialog,
          openEditWorkOrderDialog,
          openEditCustomerDialog,
          companies,
          technicians,
          divisions,
        } = this.props
        //const { trip } = invoiceDialog.props;
        const { Co } = invoice
        const readOnly = true
        const inv = invoice.Data
        const { Contacts } = inv.Data
        var contact = _.find(Contacts, o => {
          return o.Data.Contact.Name === inv.BillContact
        })
        const { showWarrantyDetail } = invoice
        const assignments = inv.Data.Assignments
        const billableDetails = inv.ID
          ? inv.Data.InvoiceDetails
          : _.filter(
              inv.Data.InvoiceDetails,
              l =>
                (l.Data.Usage && l.Data.Usage.NonBillableYN !== 'Y') ||
                (l.Data.Scope && l.Data.Scope.NonBillableYN !== 'Y'),
            )
        const lines = _.flatten(
          _.map(billableDetails, _.property('Data.InvoiceLines')),
        )
        let sub = _.sumBy(lines, o => {
          return Number(o.Amount)
        })
        let tax = _.sumBy(lines, o => {
          return Number(o.TaxAmount)
        })
        const co = _.find(companies, { Co }).Data.Co
        let total = sub + tax
        let payments =
          inv.Data && inv.Data.Payments
            ? _.sumBy(inv.Data.Payments, pmt =>
                _.sumBy(
                  _.filter(
                    pmt.Data.Allocations || pmt.Data.Payment.Data.Allocations,
                    o =>
                      !o.VoidDate &&
                      o.Co === inv.Co &&
                      o.Invoice === (inv.Invoice || o.Invoice) &&
                      o.InvoiceNumber === inv.InvoiceNumber,
                  ),
                  'Amount',
                ),
              )
            : 0
        let due = total - payments
        window['warn'](
          'Invoice Data: ',
          this.state,
          inv,
          lines,
          payments,
          total,
          due,
        )
        const paymentTypes = {
          visa: 'Visa',
          mastercard: 'MasterCard',
          master: 'MasterCard',
          discover: 'Discover',
          american_express: 'American Express',
          amex: 'American Express',
          am_ex: 'American Express',
        }
        const scope =
          inv.Data && inv.Data.Scopes && inv.Data.Scopes.length > 0
            ? inv.Data.Scopes[0]
            : null
        const billCust = inv.Data.Customer
          ? inv.Data.Customer
          : scope
            ? scope.Data.Customer || {}
            : {}
        const agreement =
          inv.Data &&
          billableDetails &&
          billableDetails.length > 0 &&
          billableDetails[0].Data &&
          billableDetails[0].Data.Agreement
            ? billableDetails[0].Data.Agreement
            : null
        const division = scope
          ? _.find(divisions, {
              Co,
              BusinessUnit: scope.BusinessUnit,
              Division: scope.Division,
            })
          : agreement
            ? _.find(divisions, {
                Co,
                BusinessUnit: agreement.BusinessUnit,
                Division: agreement.Division,
              })
            : null
        let pmts = [...(inv.Data && inv.Data.Payments ? inv.Data.Payments : [])]

        const { Scopes, InvoiceDetails } = invoice.Data.Data
        const { BusinessUnit, Division } =
          Scopes && Scopes.length > 0
            ? Scopes[0]
            : InvoiceDetails &&
                InvoiceDetails.length > 0 &&
                InvoiceDetails[0].Data &&
                InvoiceDetails[0].Data.Agreement
              ? InvoiceDetails[0].Data.Agreement
              : null
        const div = _.find(divisions, { Co, BusinessUnit, Division })
        const {
          InvoiceMarginTop,
          InvoiceMarginBottom,
          InvoiceMarginLeft,
          InvoiceMarginRight,
        } = div
        margin = {
          top: `${((InvoiceMarginTop / 330) * 3).toFixed(2)} in`,
          bottom: `${((InvoiceMarginBottom / 330) * 11).toFixed(2)} in`,
          left: `${((InvoiceMarginLeft / 255) * 8.5).toFixed(2)} in`,
          right: `${((InvoiceMarginRight / 255) * 8.5).toFixed(2)} in`,
        }

        return (
          <div className={classNames('inv-print sm:mx-24')} key={i}>
            <div className='w-full flex justify-between my-24'>
              {division && division.InvoiceHeaderImg && (
                <img
                  className='h-48 sm:h-96'
                  style={{ maxWidth: 600 }}
                  src={division.InvoiceHeaderImg}
                />
              )}
              <span className='sm:mt-12'>
                <label
                  className={classNames(
                    classes.invHeader,
                    'text-20 sm:text-36',
                  )}
                >
                  INVOICE
                </label>
                <div
                  className={classNames(
                    classes.invSubHeader,
                    'text-10 sm:text-16',
                  )}
                >
                  #{invoice.InvoiceNumber}
                </div>
              </span>
            </div>

            <div className='flex mb-24 justify-between px-16'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  openEditCustomerDialog({
                    Co: this.state.Co,
                    Customer: this.state.Customer,
                    Name: this.state.BillName,
                  })
                }}
              >
                <div className='w-full text-16 font-bold text-left'>
                  {invoice.BillName}
                  <Icon
                    onClick={e => {
                      e.stopPropagation()
                      this.setState({ ...this.state, EditBillContact: true })
                    }}
                    className='cursor-pointer text-16 ml-4 no-print align-middle'
                  >
                    contacts
                  </Icon>
                </div>
                {inv.BillContact && (
                  <div className='w-full text-12 text-left'>
                    C/O: {inv.BillContact}
                  </div>
                )}
                {invoice.EditBillContact && Contacts && (
                  <div onClick={e => e.stopPropagation()}>
                    <Autocomplete
                      className='my-12 no-print min-w-256'
                      title='Billing Contact'
                      options={[
                        { Data: { Contact: { Name: 'None' } } },
                        ..._.filter(Contacts, { BillingYN: 'Y' }),
                      ]}
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Contact}>
                            <div className='flex'>
                              {`${value.Data.Contact.Name}`}
                            </div>
                          </MenuItem>
                        )
                      }}
                      noflip={true}
                      portal={true}
                      value={contact ? contact.Contact : ''}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          BillContact: option.Contact
                            ? option.Data.Contact.Name
                            : null,
                          Data: {
                            ...this.state.Data,
                            BillContact: option.Contact
                              ? option.Data.Contact.Name
                              : null,
                          },
                          EditBillContact: false,
                        })
                      }
                      required
                      onBlur={() =>
                        this.setState({ ...this.state, EditBillContact: false })
                      }
                    />
                    <br />
                  </div>
                )}
                <div className='w-full text-12 text-left'>
                  {invoice.BillAddress1}
                </div>
                {invoice.BillAddress2 && (
                  <div className='w-full text-12 text-left'>
                    {invoice.BillAddress2}
                  </div>
                )}
                <div className='w-full text-12 text-left'>
                  {`${invoice.BillCity}, ${invoice.BillState} ${invoice.BillZip}`}
                </div>
                {inv.BillPhone && (
                  <div className='w-full text-12 text-left no-print'>
                    {this.formatPhone(inv.BillPhone)}
                  </div>
                )}
                {inv.BillEmail && (
                  <div className='w-full text-12 text-left no-print'>
                    {inv.BillEmail}
                  </div>
                )}
                {billCust.ApprovedAccountYN === 'Y' && (
                  <div className='capitalize font-bold no-print'>
                    <Icon
                      color='primary'
                      className='mr-4 text-14 align-middle mb-2'
                    >
                      security
                    </Icon>
                    Approved Account
                  </div>
                )}
                {billCust.InvoiceReviewYN === 'Y' && (
                  <div className='capitalize font-bold no-print'>
                    <Icon
                      color='error'
                      className='mr-4 text-18 align-middle mb-4'
                    >
                      visibility_on
                    </Icon>
                    Invoice Review Required
                  </div>
                )}
                {billCust.MailInvoiceYN === 'Y' && (
                  <div className='capitalize font-bold no-print'>
                    <Icon
                      color='error'
                      className='mr-4 text-18 align-middle mb-4'
                    >
                      mail
                    </Icon>
                    Send Invoice Via Mail
                  </div>
                )}
              </div>

              <div>
                <DatePicker
                  inputVariant='outlined'
                  onOpen={() => this.setDatepickerOpen(true)}
                  onClose={() => this.setDatepickerOpen(false)}
                  open={this.state.isDatepickerOpen}
                  label='Date'
                  inputProps={{
                    style: {
                      padding: 12,
                    },
                  }}
                  TextFieldComponent={() => ''}
                  value={invoice.InvoiceDate}
                  onChange={this.handleDateChange}
                  showTodayButton
                  autoOk
                />
                <div
                  className='flex cursor-pointer'
                  onClick={this.openDatePicker}
                >
                  <img
                    src='assets/icons/calendar_icon.png'
                    className='w-32 h-32 mr-8'
                  />
                  <div className='w-full'>
                    <div className='text-14 font-bold'>
                      {moment(invoice.InvoiceDate).format('dddd')}
                    </div>
                    <div className='text-10'>
                      {moment(invoice.InvoiceDate).format('MMM DD, YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex mt-24'>
              <div className='w-full'>
                <div
                  className='w-full flex justify-between rounded-full mb-12'
                  style={{ backgroundColor: '#3f3f3f' }}
                >
                  <div
                    onClick={() =>
                      openEditWorkOrderDialog({
                        Co: this.state.Co,
                        WorkOrder: this.state.WorkOrder,
                      })
                    }
                    className={classNames(
                      'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                      classes.dividerHighlight,
                    )}
                  >
                    {invoice.WorkOrder
                      ? `Work Order #${invoice.WorkOrder}`
                      : 'Agreement Billing'}
                  </div>
                  <div
                    className={classNames(
                      'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                      classes.dividerPlain,
                    )}
                  >
                    {invoice.Location}
                  </div>
                </div>
              </div>
            </div>

            <div className='mx-24 mt-12'>
              {!inv.Data.Scopes ||
                (inv.Data.Scopes.length < 1 &&
                  billableDetails.map((detail, index) => {
                    const {
                      Co,
                      Invoice,
                      InvoiceDetail,
                      WorkOrder,
                      Scope,
                      UsageLine,
                      Data,
                      Agreement,
                      AgreementCycle,
                    } = detail
                    const lines = Data ? Data.InvoiceLines : []
                    const postedTotal = _.sumBy(lines, o => {
                      return o.Data && o.Data.VPARTL
                        ? o.Data.VPARTL.Amount - o.Data.VPARTL.TaxAmount
                        : 0.0
                    })
                    const batchTotal = _.sumBy(lines, o => {
                      return o.Data && o.Data.VPARBL
                        ? o.Data.VPARBL.Amount - o.Data.VPARBL.TaxAmount || 0
                        : 0.0
                    })
                    console.warn(lines, postedTotal)
                    let line = {
                      Qty: 1,
                      UOM: 'EA',
                      Description: `${lines[0].Description}`,
                      Price: _.sumBy(lines, 'Amount'),
                    }
                    if (line) {
                      return (
                        <React.Fragment>
                          <Chip
                            classes={{
                              avatar: classes.chipAvatar,
                              label: classes.chipLabel,
                            }}
                            avatar={
                              <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                            }
                            label={`Agreement #${Agreement} Cycle #${AgreementCycle}`}
                            // onClick={handleClick}
                            // color="primary"
                            className={classes.chip}
                          />
                          <Table className={classNames(classes.table, 'mb-24')}>
                            <TableHead>
                              <TableRow className='h-24'>
                                <TableCell className='w-1/6 p-0 table-cell font-bold'>
                                  Qty
                                </TableCell>
                                <TableCell className='w-1/6 p-0 table-cell font-bold'>
                                  UOM
                                </TableCell>
                                <TableCell className='w-1/2 table-cell p-0 font-bold'>
                                  Description
                                </TableCell>
                                <TableCell className='w-1/6 text-right p-0 table-cell font-bold'>
                                  Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                key={index}
                                hover
                                className='cursor-pointer h-24'
                              >
                                <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {Number(line.Qty).toFixed(2)}
                                </TableCell>
                                <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {line.UOM}
                                </TableCell>
                                <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {line.Description}
                                </TableCell>
                                <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {this.formatDollars(line.Price)}
                                  {(inv.Data.VPARBH || inv.Data.VPARTH) && (
                                    <Tooltip
                                      title={
                                        <div>
                                          Batched:{' '}
                                          {this.formatDollars(batchTotal)}
                                          <br />
                                          Posted:{' '}
                                          {this.formatDollars(postedTotal)}
                                        </div>
                                      }
                                      placement='top'
                                    >
                                      <Icon
                                        color={
                                          this.formatDollars(postedTotal) ===
                                          this.formatDollars(line.Price)
                                            ? 'secondary'
                                            : this.formatDollars(batchTotal) ===
                                                this.formatDollars(line.Price)
                                              ? 'action'
                                              : 'error'
                                        }
                                        className={classNames(
                                          'mb-4 mt-4 ml-36 text-20 no-print',
                                        )}
                                        style={{
                                          verticalAlign: 'middle',
                                          marginRight: -56,
                                        }}
                                      >
                                        {this.formatDollars(postedTotal) ===
                                        this.formatDollars(line.Price)
                                          ? 'cloud_done'
                                          : this.formatDollars(batchTotal) ===
                                              this.formatDollars(line.Price)
                                            ? 'cloud_upload'
                                            : 'error'}
                                      </Icon>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </React.Fragment>
                      )
                    }
                  }))}
              {inv.Data.Scopes.map((scope, index) => {
                const assigned = assignments
                  ? _.find(assignments, { Scope: scope.Scope })
                  : true
                const est =
                  scope &&
                  scope.Data &&
                  (!scope.Invoice || scope.Invoice === inv.Invoice) &&
                  scope.Data.Estimate
                const estPayments =
                  est && est.Data && est.Data.Payments
                    ? _.sumBy(
                        _.filter(
                          est.Data.Payments,
                          p =>
                            (!p.InvoiceNumber ||
                              p.InvoiceNumber === inv.InvoiceNumber) &&
                            _.findIndex(inv.Data.Payments, {
                              PaymentID: p.PaymentID,
                            }) < 0,
                        ),
                        o =>
                          o.Data && o.Data.Payment && o.Data.Payment.VoidDate
                            ? 0
                            : o.Total || o.Amount,
                      )
                    : 0
                pmts = [
                  ...(est && est.Data && est.Data.Payments
                    ? _.filter(
                        est.Data.Payments,
                        p =>
                          (!p.InvoiceNumber ||
                            p.InvoiceNumber === inv.InvoiceNumber) &&
                          _.findIndex(inv.Data.Payments, {
                            PaymentID: p.PaymentID,
                          }) < 0,
                      )
                    : []),
                  ...pmts,
                ]
                // payments += estPayments;
                due = due - estPayments
                let scopeTotal = 0
                const billableAmt = _.sumBy(
                  _.filter(
                    billableDetails,
                    o =>
                      o.Scope === scope.Scope ||
                      (o.Data.Usage && o.Data.Usage.Scope === scope.Scope),
                  ),
                  o => _.sumBy(o.Data.InvoiceLines, 'Amount'),
                )
                const leadTech = _.find(technicians, {
                  Technician: scope.LeadTechnician,
                })
                window['warn']('Scope Estimate 334455: ', leadTech, scope)
                // window["warn"](assignments, assigned);
                if (
                  _.filter(
                    billableDetails,
                    o =>
                      o.Scope === scope.Scope ||
                      (o.Data.Usage && o.Data.Usage.Scope === scope.Scope),
                  ).length > 0
                ) {
                  return (
                    <div className='w-full mb-16'>
                      <div className='w-full flex justify-between'>
                        <Chip
                          classes={{
                            avatar: classes.chipAvatar,
                            label: classes.chipLabel,
                          }}
                          avatar={
                            <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                          }
                          label={`${
                            _.findLast(this.props.services, n => {
                              return n.Service === scope.Service
                            }).Description
                          }${
                            scope.CustomerPO ? ` - PO #${scope.CustomerPO}` : ''
                          }`}
                          className={classes.chip}
                        />
                        {leadTech && (
                          <div className='flex'>
                            <img
                              src='assets/icons/person_icon.png'
                              className='w-24 h-24 mr-4'
                            />
                            <div className='w-full'>
                              <div className='text-12 font-bold mt-4'>
                                {`${leadTech.FirstName} ${leadTech.LastName}`}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {scope.NotToExceed && billableAmt > scope.NotToExceed && (
                        <div className='w-full mb-12 no-print'>
                          <div
                            className='p-8 rounded py-12 w-full cursor-pointer'
                            style={{
                              backgroundColor: '#f9f9f9',
                              border: '1px dashed red',
                            }}
                          >
                            <Typography className='text-14 font-bold text-red w-full text-center'>
                              <Icon
                                color='error'
                                className='mr-6 text-20 mb-6 align-middle'
                              >
                                error
                              </Icon>
                              PRICE LIMIT EXCEEDED
                            </Typography>
                            <div className='w-full'>
                              <div className='italic mt-4 text-center text-12'>
                                This scope's total price exceeds the specified
                                limit of {this.formatDollars(scope.NotToExceed)}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {scope.IsWarrantyYN === 'Y' && (
                        <FormControlLabel
                          className='float-right mr-0 no-print'
                          control={
                            <Switch
                              checked={showWarrantyDetail}
                              name='showWarrantyDetail'
                              onChange={() =>
                                this.setState({
                                  ...this.state,
                                  showWarrantyDetail: !showWarrantyDetail,
                                })
                              }
                              color='primary'
                            />
                          }
                          label='Show Detail'
                        />
                      )}
                      <Table
                        className={classNames(classes.table, 'clear mb-12')}
                      >
                        <TableHead>
                          <TableRow className='h-24'>
                            <TableCell className='w-1/6 p-0 table-cell font-bold'>
                              Qty
                            </TableCell>
                            <TableCell className='w-1/6 p-0 table-cell font-bold'>
                              UOM
                            </TableCell>
                            <TableCell className='w-1/2 table-cell p-0 font-bold'>
                              Description
                            </TableCell>
                            <TableCell className='w-1/6 text-right p-0 table-cell font-bold'>
                              Price
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scope.IsWarrantyYN === 'Y' &&
                            !showWarrantyDetail && (
                              <TableRow
                                key={index}
                                hover
                                className='cursor-pointer h-24'
                              >
                                <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {(1).toFixed(2)}
                                </TableCell>
                                <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {'EA'}
                                </TableCell>
                                <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {'Warranty Service - No Charge'}
                                </TableCell>
                                <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  $0.00
                                </TableCell>
                              </TableRow>
                            )}
                          {billableDetails.map((detail, index) => {
                            const {
                              Co,
                              Invoice,
                              InvoiceDetail,
                              WorkOrder,
                              Scope,
                              UsageLine,
                              Data,
                              WarrantyYN,
                            } = detail
                            const lines = Data ? Data.InvoiceLines : []
                            const postedTotal = _.sumBy(lines, o => {
                              return o.Data && o.Data.VPARTL
                                ? o.Data.VPARTL.Amount - o.Data.VPARTL.TaxAmount
                                : 0.0
                            })
                            const batchTotal = _.sumBy(lines, o => {
                              return o.Data && o.Data.VPARBL
                                ? o.Data.VPARBL.Amount -
                                    o.Data.VPARBL.TaxAmount || 0
                                : 0.0
                            })
                            const svc = Data.Scope
                              ? _.find(this.props.services, {
                                  Service: Data.Scope.Service,
                                })
                              : {} //problem line
                            console.warn(lines, postedTotal)
                            let line = null
                            if (
                              Scope &&
                              scope.Scope === Scope &&
                              WarrantyYN !== 'Y'
                            ) {
                              line = {
                                Qty: 1,
                                UOM: 'FP',
                                Description:
                                  detail.Description ||
                                  (est
                                    ? ` Per Quote #${scope.Data.Estimate.EstimateNumber}`
                                    : scope.Summary
                                      ? 'Flat Price - Summary Below'
                                      : svc
                                        ? `${Data.Scope.Service} - ${svc.Description}`
                                        : `${Data.Scope.Description}`),
                                Price: Data.Scope.Price,
                                Tax: _.sumBy(lines, 'TaxAmount'),
                                TaxAmount: _.sumBy(lines, 'TaxAmount'),
                                TaxCode: lines[0] ? lines[0].TaxCode : null,
                              }
                            }
                            if (
                              Scope &&
                              scope.Scope === Scope &&
                              WarrantyYN === 'Y'
                            ) {
                              if (lines.length > 0) {
                                const { Amount, TaxAmount, Description } =
                                  lines[0]
                                line = {
                                  Qty: 1,
                                  UOM: 'EA',
                                  Description:
                                    detail.Description || Description,
                                  Price: Amount,
                                  Tax: TaxAmount,
                                  TaxAmount,
                                  TaxCode: scope.TaxCode,
                                }
                              }
                            }
                            if (
                              Data.Usage &&
                              Data.Usage.Scope === scope.Scope &&
                              Data.Usage.Type !== 6 &&
                              Data.Usage.Type !== 7 &&
                              Data.Usage.Type !== 8
                            ) {
                              line = {
                                Qty: Data.Usage.Qty,
                                UOM: Data.Usage.UOM,
                                Description:
                                  detail.Description ||
                                  `${Data.Usage.Description}${
                                    Data.Usage.Type === 2 && Data.Usage.Material
                                      ? ` (Part #${Data.Usage.Material})`
                                      : ''
                                  }`,
                                Price: Data.Usage.PriceTotal,
                                Tax: _.sumBy(lines, 'TaxAmount'),
                                TaxAmount: _.sumBy(lines, 'TaxAmount'),
                                TaxCode: lines[0].TaxCode,
                              }
                            }
                            if (line) {
                              if (scope.IsWarrantyYN === 'Y' && !inv.ID) {
                                if (!Data.Usage || Data.Usage.AddOnYN !== 'Y') {
                                  scopeTotal -= line.Price
                                  sub -= line.Price
                                  tax -= line.Tax || 0
                                  total = sub + tax
                                }
                              }
                              if (
                                scope.IsWarrantyYN !== 'Y' ||
                                showWarrantyDetail ||
                                (Data.Usage && Data.Usage.AddOnYN === 'Y')
                              ) {
                                return (
                                  <TableRow
                                    key={index}
                                    hover
                                    onClick={e => {
                                      if (!inv.ID) {
                                        if (
                                          Data.Usage &&
                                          Data.Usage.Status !== 'INVOICED' &&
                                          Data.Usage.Status !==
                                            'INVOICE PENDING' &&
                                          assigned
                                        ) {
                                          openEditWorkOrderUsageDialog(
                                            {
                                              ...Data.Usage,
                                              Data: { Scope: scope },
                                            },
                                            inv.Data.Customer.Data.Taxability,
                                          )
                                        }
                                      } else {
                                        this.setState({
                                          editLine: { detail, line, scope },
                                        })
                                      }
                                    }}
                                    className='cursor-pointer h-24'
                                  >
                                    <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                      {Number(line.Qty).toFixed(2)}
                                    </TableCell>
                                    <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                      {line.UOM}
                                    </TableCell>
                                    <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                      {line.Description}
                                      {(scope.PriceMethod !== 'T' ||
                                        scope.IsWarrantyYN === 'Y') &&
                                      Data.Usage &&
                                      Data.Usage.AddOnYN === 'Y'
                                        ? ' - Add-On'
                                        : ''}
                                    </TableCell>
                                    <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                      {this.formatDollars(line.Price)}
                                      {(inv.Data.VPARBH || inv.Data.VPARTH) && (
                                        <Tooltip
                                          title={
                                            <div>
                                              Batched:{' '}
                                              {this.formatDollars(batchTotal)}
                                              <br />
                                              Posted:{' '}
                                              {this.formatDollars(postedTotal)}
                                            </div>
                                          }
                                          placement='top'
                                        >
                                          <Icon
                                            color={
                                              this.formatDollars(
                                                postedTotal,
                                              ) ===
                                              this.formatDollars(line.Price)
                                                ? 'secondary'
                                                : this.formatDollars(
                                                      batchTotal,
                                                    ) ===
                                                    this.formatDollars(
                                                      line.Price,
                                                    )
                                                  ? 'action'
                                                  : 'error'
                                            }
                                            className={classNames(
                                              'mb-4 mt-4 ml-36 text-20 no-print',
                                            )}
                                            style={{
                                              verticalAlign: 'middle',
                                              marginRight: -56,
                                            }}
                                          >
                                            {this.formatDollars(postedTotal) ===
                                            this.formatDollars(line.Price)
                                              ? 'cloud_done'
                                              : this.formatDollars(
                                                    batchTotal,
                                                  ) ===
                                                  this.formatDollars(line.Price)
                                                ? 'cloud_upload'
                                                : 'error'}
                                          </Icon>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              }
                            }
                          })}
                          {scope.IsWarrantyYN === 'Y' &&
                            !inv.ID &&
                            showWarrantyDetail && (
                              <TableRow
                                key={index}
                                hover
                                className='cursor-pointer h-24'
                              >
                                <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {(1).toFixed(2)}
                                </TableCell>
                                <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {'EA'}
                                </TableCell>
                                <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {'Warranty Service - Credit'}
                                </TableCell>
                                <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                  {this.formatDollars(scopeTotal)}
                                </TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>

                      {est &&
                        est.Data &&
                        est.Data.Components &&
                        _.findIndex(billableDetails, { Scope: scope.Scope }) >
                          -1 && (
                          <div
                            className='w-full p-8 rounded-12 mb-12 cursor-pointer'
                            style={{ border: '1px solid #f1f1f1' }}
                            onClick={
                              !readOnly
                                ? () => this.props.openEditEstimateDialog(est)
                                : undefined
                            }
                          >
                            {
                              //Basic Detail
                              est.ComponentDetail === 'B' && (
                                <ul>
                                  {_.filter(
                                    est.Data.Components,
                                    o => o.DeleteYN !== 'Y',
                                  ).map((value, index) => {
                                    const {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      Assembly,
                                      Material,
                                      LaborType,
                                      Qty,
                                      UOM,
                                    } = value
                                    const assembly = value.Data.Assembly
                                    const material = _.find(materials, {
                                      Co,
                                      Material,
                                    })
                                    const laborType = _.find(laborTypes, {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      LaborType,
                                    })
                                    // window["warn"](value, assembly, assemblies);
                                    if (assembly) {
                                      return (
                                        <li>{`${Qty} - ${
                                          value.Name || assembly.Name
                                        }`}</li>
                                      )
                                    }
                                    if (!assembly) {
                                      var item = value
                                      const { ImageID, CustomImgURL } = item
                                      return (
                                        <li className='cursor-pointer'>{`${
                                          value.Qty
                                        } ${value.UOM} - ${
                                          value.Name || item.Description || ''
                                        }`}</li>
                                      )
                                    }
                                  })}
                                </ul>
                              )
                            }
                            {
                              //Basic Detail
                              est.ComponentDetail === 'S' && (
                                <div>
                                  {_.filter(
                                    est.Data.Components,
                                    o => o.DeleteYN !== 'Y',
                                  ).map((value, index) => {
                                    const {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      Assembly,
                                      Material,
                                      LaborType,
                                      Qty,
                                      UOM,
                                    } = value
                                    const assembly = value.Data.Assembly
                                    const material = _.find(materials, {
                                      Co,
                                      Material,
                                    })
                                    const laborType = _.find(laborTypes, {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      LaborType,
                                    })
                                    // window["warn"](value, assembly, assemblies);
                                    if (assembly) {
                                      if (
                                        assembly.Data &&
                                        assembly.Data.Images
                                      ) {
                                        assembly.Data.Image = _.find(
                                          assembly.Data.Images,
                                          { PrimaryYN: 'Y' },
                                        )
                                        if (
                                          !assembly.Data.Image &&
                                          assembly.Data.Images.length > 0
                                        ) {
                                          assembly.Data.Image =
                                            assembly.Data.Images[0]
                                        }
                                      }
                                      return (
                                        <div
                                          className='prevent-split flex mb-8 pt-6'
                                          style={{
                                            borderTop:
                                              index > 0 && '1px solid #f1f1f1',
                                          }}
                                        >
                                          <div className='w-1/6 pt-6 pr-6'>
                                            <img
                                              alt={assembly.Code}
                                              crossOrigin='Anonymous'
                                              className='w-full component-img'
                                              src={
                                                assembly.Data.Image
                                                  ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                  : 'assets/images/logos/SP_Cog_Gradient.png'
                                              }
                                            />
                                          </div>
                                          <div className='w-full'>
                                            <Typography className='w-full font-bold text-16'>
                                              <div
                                                className={classNames(
                                                  classes.qty,
                                                )}
                                              >
                                                {value.Qty}
                                              </div>
                                              {value.Name || assembly.Name}
                                            </Typography>
                                            <Typography
                                              className='clear'
                                              variant='caption'
                                            >
                                              {parse(
                                                value.Description ||
                                                  assembly.Description,
                                              )}
                                            </Typography>
                                          </div>
                                        </div>
                                      )
                                    }
                                    if (!assembly) {
                                      var item = value
                                      const { ImageID, CustomImgURL } = item
                                      return (
                                        <div
                                          className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                          style={{
                                            borderTop: '1px solid #f1f1f1',
                                          }}
                                        >
                                          <div className='w-1/6 pt-6 pr-6'>
                                            <img
                                              alt={Material}
                                              crossOrigin='Anonymous'
                                              className='w-full component-img'
                                              src={
                                                CustomImgURL ||
                                                'assets/images/logos/SP_Cog_Gradient.png'
                                              }
                                            />
                                          </div>
                                          <div className='w-full'>
                                            <Typography className='w-full font-bold text-16'>
                                              <div
                                                className={classNames(
                                                  classes.qty,
                                                )}
                                              >{`${value.Qty} ${value.UOM}`}</div>
                                              {value.Name || item.Description}
                                            </Typography>
                                            <Typography
                                              className='clear'
                                              variant='caption'
                                            >
                                              {parse(
                                                value.Description ||
                                                  item.Description ||
                                                  '',
                                              )}
                                            </Typography>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              )
                            }
                            {
                              //Basic Detail
                              est.ComponentDetail === 'F' && (
                                <div>
                                  {_.filter(
                                    est.Data.Components,
                                    o => o.DeleteYN !== 'Y',
                                  ).map((value, index) => {
                                    const {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      Assembly,
                                      Material,
                                      LaborType,
                                      Qty,
                                      UOM,
                                    } = value
                                    const assembly = value.Data.Assembly
                                    const material = _.find(materials, {
                                      Co,
                                      Material,
                                    })
                                    const laborType = _.find(laborTypes, {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      LaborType,
                                    })
                                    // window["warn"](value, assembly, assemblies);
                                    if (assembly) {
                                      if (
                                        assembly.Data &&
                                        assembly.Data.Images
                                      ) {
                                        assembly.Data.Image = _.find(
                                          assembly.Data.Images,
                                          { PrimaryYN: 'Y' },
                                        )
                                        if (
                                          !assembly.Data.Image &&
                                          assembly.Data.Images.length > 0
                                        ) {
                                          assembly.Data.Image =
                                            assembly.Data.Images[0]
                                        }
                                      }
                                      return (
                                        <div
                                          className='prevent-split flex mb-8 pt-6'
                                          style={{
                                            borderTop:
                                              index > 0 && '1px solid #f1f1f1',
                                          }}
                                        >
                                          <div className='w-1/6 pt-6 pr-6'>
                                            <img
                                              alt={assembly.Code}
                                              crossOrigin='Anonymous'
                                              className='w-full component-img'
                                              src={
                                                assembly.Data.Image
                                                  ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                  : 'assets/images/logos/SP_Cog_Gradient.png'
                                              }
                                            />
                                          </div>
                                          <div className='w-full'>
                                            <Typography className='w-full font-bold text-16'>
                                              <div
                                                className={classNames(
                                                  classes.qty,
                                                )}
                                              >
                                                {value.Qty}
                                              </div>
                                              {value.Name || assembly.Name}
                                              {assembly.Code && (
                                                <Typography
                                                  color='secondary'
                                                  className='font-bold text-12 mt-4 float-right'
                                                >
                                                  {assembly.Code}
                                                </Typography>
                                              )}
                                            </Typography>
                                            <Typography
                                              className='clear'
                                              variant='caption'
                                            >
                                              {parse(
                                                value.Description ||
                                                  assembly.Description,
                                              )}
                                            </Typography>
                                            <Typography className='font-bold text-12 my-12 relative'>
                                              Qty: {value.Qty}
                                              <Typography className='font-bold text-12 float-right'>
                                                {this.formatDollars(
                                                  value.Price,
                                                )}
                                              </Typography>
                                            </Typography>
                                          </div>
                                        </div>
                                      )
                                    }
                                    if (!assembly) {
                                      var item = value
                                      const { ImageID, CustomImgURL } = item
                                      return (
                                        <div
                                          className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                          style={{
                                            borderTop: '1px solid #f1f1f1',
                                          }}
                                        >
                                          <div className='w-1/6 pt-6 pr-6'>
                                            <img
                                              alt={Material}
                                              crossOrigin='Anonymous'
                                              className='w-full component-img'
                                              src={
                                                CustomImgURL ||
                                                'assets/images/logos/SP_Cog_Gradient.png'
                                              }
                                            />
                                          </div>
                                          <div className='w-full'>
                                            <Typography className='w-full font-bold text-16'>
                                              <div
                                                className={classNames(
                                                  classes.qty,
                                                )}
                                              >{`${value.Qty} ${value.UOM}`}</div>
                                              {value.Name || item.Description}
                                              <Typography
                                                color='secondary'
                                                className='font-bold text-12 mt-4 float-right'
                                              >
                                                {Material}
                                              </Typography>
                                            </Typography>
                                            <Typography
                                              className='clear'
                                              variant='caption'
                                            >
                                              {parse(
                                                value.Description ||
                                                  item.Description ||
                                                  '',
                                              )}
                                            </Typography>
                                            <Typography className='font-bold text-12 my-12 relative'>
                                              Qty: {value.Qty}
                                              <Typography className='font-bold text-12 float-right'>
                                                {this.formatDollars(
                                                  value.Price,
                                                )}
                                              </Typography>
                                            </Typography>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              )
                            }
                          </div>
                        )}
                      <React.Fragment>
                        <div
                          className={classNames(
                            'w-full justify-between',
                            !scope.Summary && 'no-print',
                          )}
                        >
                          <span className='text-12 cursor-pointer'>
                            <b className='text-12'>
                              <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                                speaker_notes
                              </Icon>
                              Technician Summary:
                            </b>
                          </span>
                          <MenuItem
                            className='mb-8 relative py-8'
                            style={{
                              border: '1px dotted lightgrey',
                              borderRadius: 5,
                            }}
                            key={index}
                            onClick={() => {
                              if (!this.state.editSummary[scope.Scope]) {
                                this.state.editSummary[scope.Scope] =
                                  _.cloneDeepWith(scope)
                                this.setState({
                                  ...this.state,
                                  ...this.state.editSummary,
                                })
                              }
                            }}
                          >
                            <div
                              className='text-12 italic'
                              style={{ whiteSpace: 'normal' }}
                            >
                              "{scope.Summary}"
                            </div>
                          </MenuItem>
                        </div>
                        <div className='w-full mt-6 pr-4'>
                          {scope.Summary && (
                            <TextField
                              className='mb-8 no-print'
                              id='summary'
                              name={`scope${scope.Scope}Summary`}
                              value={scope.Summary}
                              //onChange={(e) => { this.state.editSummary[scope.Scope].Summary = e.target.value; this.setState({ ...this.state, ...this.state.editSummary }) }}
                              variant='outlined'
                              multiline
                              rows={5}
                              fullWidth
                              //disabled={!this.state.editSummary[scope.Scope]}
                            />
                          )}
                        </div>
                      </React.Fragment>
                    </div>
                  )
                }
              })}
              {Boolean(inv.ID) &&
                (_.filter(
                  inv.Data.Scopes,
                  o =>
                    o.ReadyToBillYN === 'Y' &&
                    o.PriceMethod === 'F' &&
                    !o.Invoice,
                ).length > 0 ||
                  _.filter(
                    inv.Data.Usage,
                    o =>
                      o.Status === 'NEW' &&
                      _.find(
                        inv.Data.Scopes,
                        s =>
                          s.WorkOrder === o.WorkOrder &&
                          s.Scope === o.Scope &&
                          s.ReadyToBillYN === 'Y',
                      ),
                  ).length > 0) && (
                  <Button
                    onClick={() => {
                      this.setState({ syncing: true }, () => {
                        Promise.all([
                          this.props.syncInvoice({ ...this.state }),
                        ]).then(() => {
                          this.setState({ syncing: false })
                        })
                      })
                    }}
                    variant='contained'
                    color='primary'
                    className='w-full text-center mb-12 no-print'
                  >
                    <Icon
                      className={classNames(
                        'mr-6 mb-0 align-middle',
                        this.state.syncing && 'spin',
                      )}
                    >
                      refresh
                    </Icon>
                    SYNC{this.state.syncing ? 'ING' : ''} BILLABLE ITEMS
                  </Button>
                )}
            </div>
            <div className='w-full'>
              <div className='w-full flex justify-end rounded-full'>
                <div
                  className={classNames(
                    'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                  )}
                >
                  <div className='text-12 mt-4 mr-24 font-bold'>Subtotal:</div>
                  <div className='text-16 min-w-96 font-bold'>
                    {this.formatDollars(sub)}
                  </div>
                </div>
              </div>
              <div className='w-full flex justify-end rounded-full'>
                <div
                  className={classNames(
                    'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                  )}
                >
                  <div className='text-12 mt-4 mr-24 font-bold'>Tax:</div>
                  <div className='text-16 min-w-96 font-bold'>
                    {this.formatDollars(tax)}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex my-24'>
              <div className='w-full'>
                <div
                  className='w-full flex justify-between rounded-full'
                  style={{ backgroundColor: '#3f3f3f' }}
                >
                  <DatePicker
                    inputVariant='outlined'
                    onOpen={() => this.setDueDatepickerOpen(true)}
                    onClose={() => this.setDueDatepickerOpen(false)}
                    open={this.state.isDueDatepickerOpen}
                    label='Due Date'
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    TextFieldComponent={() => ''}
                    minDate={this.state.InvoiceDate}
                    value={invoice.DueDate}
                    onChange={this.handleDueDateChange}
                    showTodayButton
                    autoOk
                  />
                  <div
                    className={classNames(
                      'w-2/3 py-8 pl-24 text-12 font-bold cursor-pointer truncate',
                      classes.dividerPlain,
                    )}
                    onClick={this.openDueDatePicker}
                  >
                    DUE BY {moment(invoice.DueDate).format('MMM DD, YYYY')}
                  </div>
                  <div
                    className={classNames(
                      'w-auto rounded-full px-24 text-12 font-bold text-right flex items-center justify-between',
                      classes.dividerHighlight,
                    )}
                  >
                    <div className='text-12 mt-8 mr-24 font-bold'>Total:</div>
                    <div className='text-20 min-w-96 font-bold'>
                      {this.formatDollars(total)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='no-break'>
              <div className='m-24 pt-0 sm:flex'>
                <div className='w-full mr-16'>
                  <strong style={{ marginRight: 4 }}>Notes:</strong>
                  <Icon
                    onClick={() => {
                      this.setState({ ...this.state, EditNotes: true })
                    }}
                    className='cursor-pointer text-16 ml-4 no-print align-middle'
                    style={{ marginTop: -6 }}
                  >
                    edit
                  </Icon>
                  <br />
                  {invoice.Notes}
                  {invoice.EditNotes && (
                    <TextField
                      className='mt-6'
                      label='Notes'
                      id='notes'
                      name='Notes'
                      value={invoice.Notes}
                      onChange={this.handleChange}
                      onBlur={() => {
                        this.setState(
                          { ...this.state, EditNotes: false },
                          () => {
                            const { InvoiceNumber, Notes } = this.state
                            this.setNotes(InvoiceNumber, Notes)
                          },
                        )
                      }}
                      variant='outlined'
                      multiline
                      rows={5}
                      autoFocus
                      fullWidth
                    />
                  )}
                </div>
                <div className='w-full sm:w-2/3 mt-24 sm:mt-0'>
                  <div className='w-full mb-6'>
                    <span>
                      <strong style={{ marginRight: 4 }}>
                        Customer Signature:
                      </strong>
                      {invoice.SignedBy}
                      <Icon
                        onClick={() => {
                          this.setState({ ...this.state, EditSignedBy: true })
                        }}
                        className='cursor-pointer text-16 ml-4 no-print align-middle'
                        style={{ marginTop: -6 }}
                      >
                        edit
                      </Icon>
                    </span>
                    <br />
                    {!invoice.isSigning && invoice.EditSignedBy && (
                      <TextField
                        className={classes.formControl}
                        label='Signed By'
                        id='signedBy'
                        name='SignedBy'
                        value={invoice.SignedBy}
                        onChange={this.handleChange}
                        onBlur={() => {
                          this.setState({ ...this.state, EditSignedBy: false })
                        }}
                        variant='outlined'
                        autoFocus
                        fullWidth
                      />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ ...this.state, isSigning: true })
                    }}
                    style={{
                      border: '1px dotted #3f3f3f',
                      width: 'calc(100% - 2px)',
                      height: 96,
                      borderRadius: 5,
                    }}
                  >
                    {invoice.Signature && (
                      <img style={{ height: 96 }} src={invoice.Signature} />
                    )}
                  </div>
                </div>
              </div>
              <div className='m-24 mt-0'>
                <div className={classNames('w-full mb-12')}>
                  <div className='w-full flex justify-between'>
                    <strong style={{ marginRight: 4 }}>Payments:</strong>
                    {!readOnly &&
                      Boolean(due) &&
                      !isNaN(due) &&
                      due.toFixed(2) > 0 && (
                        <div
                          className='w-full no-print'
                          onClick={() => {
                            this.setState({ ...this.state, addPayment: true })
                          }}
                        >
                          <strong className='ml-4 text-12 float-right cursor-pointer'>
                            Add Payment
                          </strong>
                          <Icon
                            className='cursor-pointer text-14 ml-4 align-middle float-right no-print'
                            color='primary'
                          >
                            add_circle_outline
                          </Icon>
                        </div>
                      )}
                  </div>
                  {pmts.map(pmt => {
                    const voided =
                      Boolean(pmt.VoidDate) ||
                      (pmt.Data &&
                        pmt.Data.Payment &&
                        Boolean(pmt.Data.Payment.VoidDate))
                    return (
                      <div
                        onClick={() =>
                          this.setState({ viewPayment: !readOnly ? pmt : null })
                        }
                        className='w-full flex pt-8 pb-8 text-11 font-bold relative cursor-pointer'
                      >
                        <div className='mr-16'>
                          {new Date(pmt.PaymentDateTime).toLocaleDateString(
                            'en-US',
                          )}
                        </div>
                        {pmt.Check ? (
                          <React.Fragment>
                            <div
                              className={classNames(
                                voided ? 'mr-6 line-through' : 'mr-16',
                              )}
                            >{`Check #${pmt.Check}`}</div>
                          </React.Fragment>
                        ) : pmt.CardLastFour ? (
                          <React.Fragment>
                            <img
                              src={`assets/images/cards/${pmt.PaymentMethodType}.png`}
                              className='w-20 mr-4'
                              style={{ height: 14 }}
                            />
                            <div
                              className={classNames(
                                voided ? 'mr-6 line-through' : 'mr-16',
                              )}
                            >{`${
                              paymentTypes[pmt.PaymentMethodType]
                            } ending in ${pmt.CardLastFour}`}</div>
                          </React.Fragment>
                        ) : pmt.Data &&
                          pmt.Data.PaymentMethod &&
                          pmt.Data.PaymentMethod.CardLastFour ? (
                          <React.Fragment>
                            <img
                              src={`assets/images/cards/${pmt.Data.PaymentMethod.PaymentMethodType}.png`}
                              className='w-20 mr-4'
                              style={{ height: 14 }}
                            />
                            <div
                              className={classNames(
                                voided ? 'mr-6 line-through' : 'mr-16',
                              )}
                            >{`${
                              paymentTypes[
                                pmt.Data.PaymentMethod.PaymentMethodType
                              ]
                            } ending in ${
                              pmt.Data.PaymentMethod.CardLastFour
                            }`}</div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className={classNames(
                                voided ? 'mr-6 line-through' : 'mr-16',
                              )}
                            >{`OTHER${pmt.Memo ? ` - ${pmt.Memo}` : ''}`}</div>
                          </React.Fragment>
                        )}
                        {voided ? (
                          <div className={classNames('mr-16')}> - VOIDED</div>
                        ) : (
                          ''
                        )}
                        <div
                          className={classNames(
                            'pin-r absolute',
                            voided ? 'line-through' : '',
                          )}
                        >{`${this.formatDollars(
                          _.sumBy(
                            _.filter(
                              pmt.Data.Allocations ||
                                pmt.Data.Payment.Data.Allocations,
                              o =>
                                !o.VoidDate &&
                                o.Co === inv.Co &&
                                o.Invoice === (inv.Invoice || o.Invoice) &&
                                o.InvoiceNumber === inv.InvoiceNumber,
                            ),
                            'Amount',
                          ),
                        )}`}</div>
                      </div>
                    )
                  })}

                  <div className='w-full' style={{ marginBottom: -20 }}>
                    <div className='w-full flex justify-end rounded-full'>
                      <div
                        className={classNames(
                          'w-auto rounded-full text-12 font-bold text-right flex justify-between',
                        )}
                      >
                        <div className='text-12 mt-4 mr-24 font-bold'>
                          Total Paid:
                        </div>
                        <div className='text-12 mt-4 min-w-96 font-bold'>
                          {this.formatDollars(total - due)}
                        </div>
                      </div>
                    </div>
                    <div className='w-full flex justify-end rounded-full'>
                      <div
                        className={classNames(
                          'w-auto rounded-full text-12 px-12 font-bold text-right flex justify-between text-white py-4',
                        )}
                        style={{ background: '#3f3f3f', marginRight: -12 }}
                      >
                        <div className='text-12 mr-24 font-bold'>
                          Balance Due:
                        </div>
                        <div
                          className={classNames('text-12 min-w-96 font-bold')}
                        >
                          {due > 0 ? this.formatDollars(due) : '$0.00'}
                        </div>
                      </div>
                    </div>
                  </div>
                  {invoice.addPayment && (
                    <InvoicePayment
                      open={this.state.addPayment}
                      data={{ ...this.state, total: due }}
                      paymentMethods={inv.Data.PaymentMethods}
                      onPayment={this.handlePayment}
                      onClose={() => {
                        this.setState({ ...this.state, addPayment: false })
                      }}
                    />
                  )}
                  {this.state.viewPayment && (
                    <InvoicePaymentRecord
                      open={this.state.viewPayment}
                      data={{ ...this.state }}
                      payment={{ ...this.state.viewPayment }}
                      onVoid={this.handleVoidPayment}
                      onClose={() => {
                        this.setState({ ...this.state, viewPayment: false })
                      }}
                    />
                  )}
                </div>
              </div>
              <div className='w-full h-auto'>
                {division && division.InvoiceDisclaimer && (
                  <div className='mx-24 mb-12'>
                    <strong style={{ marginRight: 4 }}>
                      Terms and Conditions:
                    </strong>
                    <div
                      className='font-bold text-red w-full mt-6'
                      style={{ fontSize: 9 }}
                    >
                      {division.InvoiceDisclaimer}
                    </div>
                  </div>
                )}
                <div className='mx-24'>
                  <div className='w-full sm:flex sm:justify-between'>
                    <div className='mt-24'>
                      <div className='w-full flex'>
                        <img
                          src='assets/icons/location_icon.png'
                          className='w-32 h-32 mr-8'
                        />
                        <div className='w-full'>
                          <div className='text-14 font-bold'>{co.Name}</div>
                          <div className='text-10'>{co.Address}</div>
                          <div className='text-10'>
                            {`${co.City}, ${co.State} ${co.Zip}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-24'>
                      <div className='w-full flex'>
                        <img
                          src='assets/icons/email_icon.png'
                          className='w-32 h-32 mr-8'
                        />
                        <div className='w-full'>
                          <div className='text-14 font-bold'>Email</div>
                          <div className='text-10'>{co.Email}</div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-24'>
                      <div className='w-full flex'>
                        <img
                          src='assets/icons/phone_icon.png'
                          className='w-32 h-32 mr-8'
                        />
                        <div className='w-full'>
                          <div className='text-14 font-bold'>Phone</div>
                          <div className='text-10'>
                            {this.formatPhone(co.Phone)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              open={this.state.isSigning}
              classes={{ paper: classes.signaturePaper }}
              fullWidth
              maxWidth='sm'
              onClose={() => {
                if (this.sigPad.isEmpty()) {
                  this.setState({ ...this.state, isSigning: false })
                }
              }}
            >
              <div className='w-full mb-12'>
                <span>
                  <strong style={{ marginRight: 4 }}>
                    Customer Signature:
                  </strong>
                  {this.state.SignedBy}
                  <Icon
                    onClick={() => {
                      this.setState({ ...this.state, EditSignedBy: true })
                    }}
                    className='cursor-pointer text-16 ml-4 no-print align-middle'
                    style={{ marginTop: -6 }}
                  >
                    edit
                  </Icon>
                </span>
                <br />
                {this.state.isSigning && this.state.EditSignedBy && (
                  <TextField
                    className={classes.formControl}
                    label='Signed By'
                    id='signedBy'
                    name='SignedBy'
                    value={this.state.SignedBy}
                    onChange={this.handleChange}
                    onBlur={() => {
                      this.setState({ ...this.state, EditSignedBy: false })
                    }}
                    variant='outlined'
                    autoFocus
                    fullWidth
                  />
                )}
              </div>
              <div
                className='w-full'
                style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
              >
                <SignatureCanvas
                  ref={ref => {
                    this.sigPad = ref
                  }}
                  penColor='#66aef9'
                  canvasProps={{
                    className: 'sigCanvas',
                    style: { width: '100%', height: 150 },
                  }}
                />
              </div>
              <div className='flex w-full mt-16'>
                <Button
                  variant='contained'
                  className={classes.clearBtn}
                  fullWidth
                  onClick={this.clear}
                >
                  Clear
                </Button>
                <div className='min-w-12'></div>
                <Button
                  variant='contained'
                  className={classes.okBtn}
                  fullWidth
                  onClick={this.trim}
                >
                  OK
                </Button>
              </div>
            </Dialog>
            <div
              className={classNames(
                i < res.data.length - 1 ? 'page-break' : '',
              )}
            ></div>
          </div>
        )
      })
      this.setState(
        {
          ...this.state,
          bulkInvoices: gridElement,
          margin: margin,
          ranFetch: true,
        },
        () => this.props.resetRunFetch(),
      )
    })

    this.printPDF()
  }

  printPDF = () => {
    const { margin } = this.state
    let gridElement = document.getElementById('invoice-print-bulk')

    let gridItems = gridElement.querySelectorAll('*')
    gridItems.forEach(item => {
      item.style.marginTop = '0'
    })
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      forcePageBreak: '.page-break',
      paperSize: 'letter',
      margin,
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group, {
          title: `Multi-Invoice PDF`,
          creator: 'Service Point Pro',
        })
      })
      .then(dataUri => {
        this.setState({
          ...this.state,
          printPDF: dataUri,
          pdfLoading: false,
          ranFetch: false,
        })
      })
  }

  render() {
    const { bulkInvoices, pdfOpen, pdfLoading, printPDF, selectedInvoiceIds } =
      this.state

    return (
      <Media query='(min-width: 1200px)'>
        {matches => (
          <div>
            <Dialog
              open={bulkInvoices}
              style={{ transform: 'translate(5000px, 5000px' }}
              fullWidth
              maxWidth='md'
              fullScreen={!matches}
            >
              <DialogContent>
                <div id='invoice-print-bulk'>{bulkInvoices}</div>
              </DialogContent>
            </Dialog>

            {
              // PDF Dialog
              pdfOpen && (
                <Dialog
                  classes={{
                    paper: classNames(
                      'mt-0 mb-0 w-full h-full',
                      !matches && 'full-screen-dialog',
                    ),
                  }}
                  open={pdfOpen}
                  onClose={() =>
                    this.setState({
                      printPDF: null,
                      anchorEl: null,
                      pdfOpen: false,
                    })
                  }
                  fullWidth
                  maxWidth='md'
                  fullScreen={!matches}
                >
                  <AppBar position='static' className='dialog-header'>
                    <Toolbar className='flex w-full'>
                      <Typography variant='subtitle1' color='inherit'>
                        <Icon className='mr-6 align-middle mb-2'>print</Icon>
                        Invoice Preview
                      </Typography>
                    </Toolbar>
                    {pdfLoading && (
                      <IconButton
                        style={{
                          position: 'absolute',
                          right: 56,
                          top: matches.medium ? 8 : 4,
                          color: 'white',
                        }}
                        className='dialog-header-icon'
                      >
                        <Icon className='spin'>refresh</Icon>
                      </IconButton>
                    )}
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: matches.medium ? 8 : 4,
                        color: 'white',
                      }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          printPDF: null,
                          pdfOpen: null,
                        })
                      }}
                      className='dialog-header-icon'
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </AppBar>
                  <DialogContent
                    classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}
                  >
                    {printPDF && (
                      <iframe
                        src={printPDF}
                        className='w-full overflow-auto'
                        style={{ height: 'calc(100% - 24px)' }}
                      />
                    )}
                  </DialogContent>
                </Dialog>
              )
            }
          </div>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoices: Actions.fetchInvoices,
    },
    dispatch,
  )
}

function mapStateToProps({ invoicesApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    companies: spReducers.companies.List,
    divisions: spReducers.divisions,
    services: spReducers.services,
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    selectedInvoiceIds: invoicesApp.invoices.selectedInvoiceIds,
    fetchedInvoices: invoicesApp.invoices.fetchInvoices,
    technicians: spReducers.technicians,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceBulkDialog)),
)
