import axios from 'axios/index';

export const SET_DEPARTMENT_DATA = '[SPCONNECTION] SET DEPARTMENT DATA';
export const GET_DEPARTMENT_DATA = '[SPCONNECTION] GET DEPARTMENT DATA';
export const SUBSCRIBE_DEPARTMENT_LIST = '[SPCONNECTION] SUBSCRIBE DEPARTMENT LIST';

export function getInitialDepartmentData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Department`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getDepartmentData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Department`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_DEPARTMENT_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_DEPARTMENT_LIST,
            Co
        }));
    }
}

export function setDepartmentData(data) {
    return {
        type: SET_DEPARTMENT_DATA,
        payload: data
    };
}