import axios from 'axios/index';

export const SET_AGREEMENT_TYPE_DATA = '[SPCONNECTION] SET AGREEMENT TYPE DATA';
export const GET_AGREEMENT_TYPE_DATA = '[SPCONNECTION] GET AGREEMENT TYPE DATA';
export const SUBSCRIBE_AGREEMENT_TYPE_LIST = '[SPCONNECTION] SUBSCRIBE AGREEMENT TYPE LIST';

export function getInitialAgreementTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/AgreementType`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getAgreementTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/AgreementType`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_AGREEMENT_TYPE_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_AGREEMENT_TYPE_LIST,
            Co
        }));
    }
}

export function setAgreementTypeData(data) {
    return {
        type: SET_AGREEMENT_TYPE_DATA,
        payload: data
    };
}