import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedInventoryLocationIds: [],
    routeParams: {},
    inventoryLocationDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            LocationGroup: null,
            Location: null,
            Description: null,
            MobileYN: 'N',
            FleetVehicle: null,
            Address1: null,
            Address2: null,
            City: null,
            State: null,
            Zip: null,
            Country: 'US',
            CostGLAccount: null,
            SalesGLAccount: null,
            AdjustmentGLAccount: null,
            ActiveYN: 'Y',
            Notes: null,
            AttachmentID: null,
            Data: {
                Managers: [],
                Materials: [],
                ErrMsg: null,
            }
        }
    }
};

const inventoryLocationsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_INVENTORY_LOCATIONS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_INVENTORY_LOCATIONS:
            {

                const inventoryLocationId = action.inventoryLocationId;

                let selectedInventoryLocationIds = [...state.selectedInventoryLocationIds];

                if (selectedInventoryLocationIds.find(id => id === inventoryLocationId) !== undefined) {
                    selectedInventoryLocationIds = selectedInventoryLocationIds.filter(id => id !== inventoryLocationId);
                }
                else {
                    selectedInventoryLocationIds = [...selectedInventoryLocationIds, inventoryLocationId];
                }

                return {
                    ...state,
                    selectedInventoryLocationIds: selectedInventoryLocationIds
                };
            }
        case Actions.SELECT_ALL_INVENTORY_LOCATIONS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedInventoryLocationIds = arr.map(inventoryLocations => inventoryLocations.id);

                return {
                    ...state,
                    selectedInventoryLocationIds: selectedInventoryLocationIds
                };
            }
        case Actions.DESELECT_ALL_INVENTORY_LOCATIONS:
            {
                return {
                    ...state,
                    selectedInventoryLocationIds: []
                };
            }
        case Actions.OPEN_NEW_INVENTORY_LOCATION_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    inventoryLocationDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.inventoryLocationDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_INVENTORY_LOCATION_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_INVENTORY_LOCATION_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    inventoryLocationDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.inventoryLocationDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_INVENTORY_LOCATION_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    inventoryLocationDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.inventoryLocationDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_INVENTORY_LOCATION_DIALOG:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
};

export default inventoryLocationsReducer;
