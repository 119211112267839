import _ from '@lodash';
import axios from 'axios/index';
import { getUserData } from 'main/content/apps/supervisor/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_SUPERVISORS = '[SUPERVISORS APP] GET SUPERVISORS';
export const SET_SEARCH_TEXT = '[SUPERVISORS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SUPERVISORS = '[SUPERVISORS APP] TOGGLE IN SELECTED SUPERVISORS';
export const SELECT_ALL_SUPERVISORS = '[SUPERVISORS APP] SELECT ALL SUPERVISORS';
export const DESELECT_ALL_SUPERVISORS = '[SUPERVISORS APP] DESELECT ALL SUPERVISORS';
export const OPEN_NEW_SUPERVISOR_DIALOG = '[SUPERVISORS APP] OPEN NEW SUPERVISOR DIALOG';
export const CLOSE_NEW_SUPERVISOR_DIALOG = '[SUPERVISORS APP] CLOSE NEW SUPERVISOR DIALOG';
export const OPEN_EDIT_SUPERVISOR_DIALOG = '[SUPERVISORS APP] OPEN EDIT SUPERVISOR DIALOG';
export const ADD_EDIT_SUPERVISOR_DIALOG = '[SUPERVISORS APP] ADD EDIT SUPERVISOR DIALOG';
export const UPDATE_EDIT_SUPERVISOR_DIALOG = '[SUPERVISORS APP] UPDATE EDIT SUPERVISOR DIALOG';
export const DATA_EDIT_SUPERVISOR_DIALOG = '[SUPERVISORS APP] DATA EDIT SUPERVISOR DIALOG';
export const CLOSE_EDIT_SUPERVISOR_DIALOG = '[SUPERVISORS APP] CLOSE EDIT SUPERVISOR DIALOG';
export const ADD_SUPERVISOR = '[SUPERVISORS APP] ADD SUPERVISOR';
export const UPDATE_SUPERVISOR = '[SUPERVISORS APP] UPDATE SUPERVISOR';
export const REMOVE_SUPERVISOR = '[SUPERVISORS APP] REMOVE SUPERVISOR';
export const REMOVE_SUPERVISORS = '[SUPERVISORS APP] REMOVE SUPERVISORS';
export const TOGGLE_STARRED_SUPERVISOR = '[SUPERVISORS APP] TOGGLE STARRED SUPERVISOR';
export const TOGGLE_STARRED_SUPERVISORS = '[SUPERVISORS APP] TOGGLE STARRED SUPERVISORS';
export const SET_SUPERVISORS_STARRED = '[SUPERVISORS APP] SET SUPERVISORS STARRED';

// export const SET_SUPERVISOR_SCHEDULE = '[SUPERVISORS APP] SET SUPERVISOR SCHEDULE';
// export const SET_SUPERVISOR_TIMECARD = '[SUPERVISORS APP] SET SUPERVISOR TIMECARD';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}