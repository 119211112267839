import _ from '@lodash';
import { Avatar, Button, Card, CardContent, CardHeader, Chip, Icon, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff'
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
        color: '#000'
    },
    cardSubheader: {
        fontSize: 11,
        color: '#3f3f3f'
    },
    avatar: {
        background: '#4f4f4f',
        color: '#fff',
        width: 24,
        height: 24
    },
    selectedAvatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 12
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    blue: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    }
});

const initialState = {
    ID: null,
    Co: null,
    Name: null,
    Description: null,
    Category: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
    editing: false,
};

class ReportCategoryCard extends Component {

    state = {
        ...initialState,
    }

    componentDidMount() {
        const { value } = this.props;
        this.setState({ ...initialState, ...value });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value)) {
            this.setState({ ...this.state, ...value });
        }
    }

    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ ...this.state, open: !open });
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        const { editing } = this.state;
        this.setState({ ...this.state, editing: !editing, open: true });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    toggleCategory = (value) => {
        const { category } = this.props;
        if (!_.isEqual(category, value)) {
            this.props.setCategory(value);
        } else {
            this.props.setCategory(null);
        }
    }

    canBeSubmitted = () => {
        const { Name, Description, Category } = this.state;
        return Name && Name.length > 0 && Description && Description.Length > 0 && Category && !isNaN(Category);
    }

    render() {
        const { classes, value, user, categories, category, securables, fullWidth, reportCategory } = this.props;
        const { open, editing } = this.state;
        // const count = _.filter(reportList, { Category: value.Category }).length;
        const accessLevel = _.find(securables, { Securable: "learning" });
        const catNum = category ? category.Category : false;
        if (catNum && catNum !== value.Category) {
            return '';
        }
        return (
            <div className={classNames("mt-16 px-8 w-full", fullWidth ? "" : "sm:w-1/3 md:w-1/4 lg:w-1/5")}>
                <Card onClick={(e) => { e.stopPropagation(); this.props.onClick({ ...value }); }} className="cursor-pointer">
                    <CardHeader
                        classes={{ root: classes.card, title: classes.cardTitle, subheader: classes.cardSubheader, avatar: "mr-8", action: "pt-8 pr-4" }}
                        avatar={<Avatar className={classNames(classes.avatar, (reportCategory || {}).Category === value.Category && classes.selectedAvatar)}><Icon className="text-16">apps</Icon></Avatar>}
                        // action={this.state.ID ? <IconButton onClick={() => this.toggleCategory(value)}><Icon color={catNum === value.Category ? "secondary" : "action"}>filter_list</Icon></IconButton> : false}
                        title={<React.Fragment>{value.Name}{((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) && Boolean(value.Co) && <Icon onClick={this.toggleEdit} className="ml-4 text-14" style={{ paddingTop: 2 }}>edit</Icon>}</React.Fragment>}
                    // subheader={`${count} ${count !== 1 ? "Categories" : "Category"}`}

                    />
                    <CardContent className="relative" style={{ minHeight: 128, padding: editing ? 0 : 16 }} classes={{ root: classes.cardContent }}>
                        {!value.Co &&
                            <div className={classNames("pin-b pin-r mb-6 mr-6 absolute text-12 font-bold text-10 rounded pt-4 px-4 pb-0", classes.blue)}><Icon className="mr-4 text-16 align-middle mb-4">cloud_done</Icon>Standard</div>
                        }
                        {!editing || !value.Co ?
                            <div className="absolute pin-r pin-l pin-t pin-b m-auto w-full text-center" style={{ height: 56, paddingTop: !value.Co ? 18 : undefined }}>
                                <strong className="text-12 p-8">
                                    {value.Description}
                                </strong>
                                {Boolean(value.Co) &&
                                    <React.Fragment>
                                        <br />
                                        <label className="text-10 p-8">
                                            {value.AddedBy}
                                        </label>
                                        <br />
                                        <label className="text-10 p-8">
                                            {new Date(value.AddedDate).toLocaleDateString('en-US')}
                                        </label>
                                    </React.Fragment>
                                }
                            </div>
                            :
                            <div style={{ backgroundColor: '#fff' }} className="p-16">
                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Name"

                                        id="name"
                                        name="Name"
                                        value={this.state.Name}
                                        onChange={this.handleChange}
                                        inputProps={
                                            {
                                                style: {
                                                    padding: 12,
                                                }
                                            }
                                        }
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description}
                                        inputProps={
                                            {
                                                style: {
                                                    padding: 12,
                                                }
                                            }
                                        }
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className="w-full flex">
                                    <Button
                                        className={classNames(classes.saveButton, "w-full")}
                                        variant="contained"
                                        color="primary"
                                        disabled={!this.canBeSubmitted}
                                        onClick={() => {
                                            this.setState({ ...this.state, editing: false, });
                                            if (!this.state.ID) {
                                                this.props.addCategory(this.state);
                                            } else {
                                                this.props.updateCategory(this.state);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className={classNames(classes.deleteButton, "w-32")}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.setState({ ...this.state, editing: false, });
                                            if (!this.state.ID) {
                                                this.props.onDelete();
                                            } else {
                                                this.props.deleteCategory(this.state);
                                            }
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </div>
                            </div>
                        }
                    </CardContent>
                </Card >
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addCategory: Actions.addCategory,
        updateCategory: Actions.updateCategory,
        deleteCategory: Actions.removeCategory,
        setCategory: Actions.setCategory,
        setCategories: Actions.setCategories,
    }, dispatch);
}

function mapStateToProps({ spReducers, reportingApp }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User.UserName,
        categories: spReducers.classes.categories,
        category: reportingApp.reporting.category,
        securables: spReducers.userProfiles.User.Data.Securables,
        reportCategory: reportingApp.reporting.reportCategory
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportCategoryCard)));
