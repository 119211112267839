import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../store/actions';
import { withStyles, Typography, Icon } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
// import EmailTemplateBuilder from '../EmailTemplateBuilder';
import { FuseAnimateGroup, FuseAnimate } from '@fuse';
import { SPRoundIcon } from 'main/icons';

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: '#333',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100vh',
        paddingBottom: 80,
        overflow: 'auto',
    },
    template: {
        cursor: 'pointer',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        background: '#fff',
        color: '#333',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    newTemplate: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    }
});


class Templates extends Component {
    state = {
        Co: null,
        User: null,
    }
    componentDidMount() {
        //this.props.getTemplates(this.props.Co);
    }

    componentWillUnmount() {
        //this.props.resetTemplates();
    }

    newTemplate = () => {
        const { Co, User } = this.props;
        const template = {
            Co: Co,
            Name: 'New Email Template',
            Type: 'Email',
            AddedBy: User,
            AddedDate: new Date(),
            Markup: '',
            Notes: null,
            Data: {
                ErrMsg: null,
            }
        }
        this.props.newTemplate(template);
    }

    render() {
        const { classes, templates, Co, User } = this.props;

        return (
            <div className={classNames(classes.root, "flex flex-grow flex-no-shrink flex-col items-center w-full")}>
                {/* <EmailTemplateBuilder components="<div></div>" style="" /> */}
                <FuseAnimate>
                    <Typography className="mt-24 sm:mt-64 sm:py-24 text-24 font-400" color="inherit"><SPRoundIcon className="align-middle text-32 mr-6 mb-4" />Template Studio</Typography>
                </FuseAnimate>

                <div>
                    <FuseAnimateGroup
                        className="flex flex-wrap w-full justify-center py-32 px-16"
                        enter={{
                            animation: "transition.slideUpBigIn",
                            duration: 300
                        }}
                    >
                        {templates.map(template => (
                            <div className="w-224 h-224 p-16" key={template.ID}>
                                <Link
                                    to={'/apps/email-templates/templates/' + Co + '/' + template.ID}
                                    className={classNames(classes.template, "flex flex-col items-center justify-center w-full h-full rounded py-24")}
                                    role="button"
                                >
                                    <Icon className="text-56">dashboard</Icon>
                                    <Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">{template.Name}</Typography>
                                </Link>
                            </div>
                        ))}
                        <div className="w-224 h-224 p-16">
                            <div
                                className={classNames(classes.template, classes.newTemplate, "flex flex-col items-center justify-center w-full h-full rounded py-24")}
                                onClick={this.newTemplate}
                            >
                                <Icon className="text-56">add_circle</Icon>
                                <Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">Add new template</Typography>
                            </div>
                        </div>
                    </FuseAnimateGroup>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTemplates: Actions.getTemplates,
        resetTemplates: Actions.resetTemplates,
        newTemplate: Actions.newTemplate
    }, dispatch);
}

function mapStateToProps({ emailTemplateApp, spReducers }) {
    return {
        templates: emailTemplateApp.templates.Templates,
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Templates)));
