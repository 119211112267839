import axios from "axios/index";
import moment from "moment";
import { SUBSCRIBE_LIST, UNSUBSCRIBE_LIST } from "store/actions";
import { LocalNotifications } from "@capacitor/local-notifications";
import {
  createNotification,
  SET_SCHEDULED_LOCAL_NOTIFICATION,
} from "./local-notifications.actions";
import CacheManager from "store/middlewares/cache";
import _ from "@lodash";

export const SET_TECHNICIAN_SCHEDULE = "[SPCONNECTION] SET TECHNICIAN SCHEDULE";
export const GET_TECHNICIAN_SCHEDULE = "[SPCONNECTION] GET TECHNICIAN SCHEDULE";
export const SET_TECHNICIAN_TIMECARD = "[SPCONNECTION] SET TECHNICIAN TIMECARD";
export const GET_TECHNICIAN_TIMECARD = "[SPCONNECTION] GET TECHNICIAN TIMECARD";
export const SET_TECHNICIAN_TIMECARDS =
  "[SPCONNECTION] SET TECHNICIAN TIMECARDS";
export const GET_TECHNICIAN_TIMECARDS =
  "[SPCONNECTION] GET TECHNICIAN TIMECARDS";
export const SET_TECHNICIAN_ESTIMATES =
  "[SPCONNECTION] SET TECHNICIAN ESTIMATES";
export const GET_TECHNICIAN_ESTIMATES =
  "[SPCONNECTION] GET TECHNICIAN ESTIMATES";
export const SET_TECHNICIAN_PERFORMANCE =
  "[SPCONNECTION] SET TECHNICIAN PERFORMANCE";
export const GET_TECHNICIAN_PERFORMANCE =
  "[SPCONNECTION] GET TECHNICIAN PERFORMANCE";
export const SET_TECHNICIAN_DATE = "[SPCONNECTION] SET TECHNICIAN DATE";
export const SET_TECHNICIAN = "[SPCONNECTION] SET TECHNICIAN";
export const SET_TECHNICIAN_LOADING = "[SPCONNECTION] SET TECHNICIAN LOADING";

moment.updateLocale("en", {
  week: {
    dow: 0, // First day of week is Monday
  },
});

const offline = new CacheManager();

export function setTripReminders(allTrips) {
  return (dispatch, getState) => {
    const state = getState();
    const today = moment()
      .local()
      .format("MM/DD/YYYY");
    const completed = _.filter(
      allTrips,
      (o) =>
        moment(o.BoardTime)
          .local()
          .format("MM/DD/YYYY") === today && o.Status === 8
    );
    const notified = _.filter(
      allTrips,
      (o) =>
        moment(o.BoardTime).format("MM/DD/YYYY") === today && o.Status === 1
    );
    const inProgress = _.filter(
      allTrips,
      (o) =>
        moment(o.BoardTime)
          .local()
          .format("MM/DD/YYYY") === today && o.Status === 6
    );
    LocalNotifications.getPending().then((pending) => {
      const { notifications } = pending;
      dispatch({
        type: SET_SCHEDULED_LOCAL_NOTIFICATION,
        payload: notifications,
      });
      completed.map((trip) => {
        const oldNotif = _.find(notifications, { id: trip.ID });
        if (oldNotif) {
          LocalNotifications.cancel({ notifications: [oldNotif] }).then(() =>{
              // window["log"]("Canceled notification: ", oldNotif)
          }
          
          );
        }
      });
      notified.map((trip) => {
        if (trip.ScheduledTime) {
          const time = moment(trip.ScheduledTime)
            .subtract(30, "minutes")
            .toDate();
          const now = moment()
            .local()
            .toDate();
          if (time >= now) {
            const oldNotif = _.find(notifications, { id: trip.ID });
            if (oldNotif) {
              LocalNotifications.cancel({ notifications: [oldNotif] }).then(
                () => {
                  dispatch(
                    createNotification(
                      trip.ID,
                      "Trip Reminder",
                      `Reminder - You are scheduled to arrive at Work Order #${
                        trip.WorkOrder
                      } at ${moment(trip.ScheduledTime).format(
                        "h:mm A"
                      )}. Tap here to view, or Long-Press for more options.`,
                      { ...trip },
                      "TripReminder",
                      "SPTripActions",
                      { at: time }
                    )
                  );
                }
              );
            } else {
              dispatch(
                createNotification(
                  trip.ID,
                  "Trip Reminder",
                  `Reminder - You are scheduled to arrive at Work Order #${
                    trip.WorkOrder
                  } at ${moment(trip.ScheduledTime).format(
                    "h:mm A"
                  )}. Tap here to view, or Long-Press for more options.`,
                  { ...trip },
                  "TripReminder",
                  "SPTripActions",
                  { at: time }
                )
              );
            }
          }
        }
      });
      inProgress.map((trip) => {
        if (trip.Duration && trip.BoardTime) {
          const completionTime = moment(trip.BoardTime)
            .local()
            .add(trip.Duration * 60, "minutes");
          const time = moment(trip.BoardTime)
            .local()
            .add(trip.Duration * 60, "minutes")
            .subtract(15, "minutes")
            .toDate();
          const now = moment()
            .local()
            .toDate();
          if (time >= now) {
            const oldNotif = _.find(notifications, { id: trip.ID });
            if (oldNotif) {
              LocalNotifications.cancel({ notifications: [oldNotif] }).then(
                () => {
                  dispatch(
                    createNotification(
                      trip.ID,
                      "Trip Completion",
                      `Reminder - Trip #${trip.Trip} for Work Order #${
                        trip.WorkOrder
                      } is scheduled to be completed by ${completionTime.format(
                        "h:mm A"
                      )}. Tap here to extend the Trip Duration, or Long-Press for more options.`,
                      { ...trip },
                      "TripCompletion",
                      "SPTripActions",
                      { at: time }
                    )
                  );
                }
              );
            } else {
              dispatch(
                createNotification(
                  trip.ID,
                  "Trip Completion",
                  `Reminder - Trip #${trip.Trip} for Work Order #${
                    trip.WorkOrder
                  } is scheduled to be completed by ${completionTime.format(
                    "h:mm A"
                  )}. Tap here to extend the Trip Duration, or Long-Press for more options.`,
                  { ...trip },
                  "TripCompletion",
                  "SPTripActions",
                  { at: time }
                )
              );
            }
          }
        }
      });
    });
  };
}

export function getTechnicianSchedule(data, newDate, view, bool) {
  const { Co, Technician, Employee } = data;
  // const date = moment(dt);
  const dt = newDate
    ? moment(newDate).format("M/D/YYYY")
    : moment().format("M/D/YYYY");
  const start = moment(new Date(dt))
    .locale("us")
    .startOf("isoweek")
    .format("M/D/YYYY");
  return (dispatch, getState) => {
    const state = getState();
    const { date, technician } = state.spReducers.technician;
    const oldStart = moment(state.spReducers.technician.start)
      .locale("us")
      .startOf("isoweek")
      .format("M/D/YYYY");
    if (start !== oldStart || bool) {
      const request = axios.get(
        `${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(
          Co
        )}&Date=${encodeURIComponent(dt)}&Tech=${encodeURIComponent(
          Technician
        )}&View=W`
      );
      // console.warn('Tech Sched Param Dates: ', newDate, dt, start, oldStart);
      dispatch({
        type: SET_TECHNICIAN_DATE,
        date: new Date(dt),
      });
      if (technician) {
        dispatch({
          type: UNSUBSCRIBE_LIST,
          List: `${Co}_${technician.Technician}_${moment(oldStart).format(
            "M-D-YYYY"
          )}_Trips_${view || "W"}`,
        });
      }
      dispatch({
        type: SET_TECHNICIAN,
        payload: data,
      });
      dispatch({
        type: SET_TECHNICIAN_LOADING,
        loading: true,
      });
      dispatch({
        type: SET_TECHNICIAN_SCHEDULE,
        payload: [],
        raw: [],
      });
      request
        .then((response) => {
          const schedules = [];
          for (var i = 0; i < 7; i++) {
            let ndt = new Date(start);
            // console.warn(start, ndt);
            ndt.setDate(ndt.getDate() + i);
            // console.warn(start, ndt);
            ndt = moment(ndt).format("M/D/YYYY");
            const trips = _.filter(response.data, (o) => {
              return (
                moment(
                  o.InProgressDate ||
                    o.ArrivedDate ||
                    o.EnRouteDate ||
                    o.ScheduledDate
                ).format("M/D/YYYY") === ndt
              );
            });
            schedules.push({ Date: ndt, trips });
          }
          // console.warn('Tech Schedules: ', schedules, response.data);
          Promise.all([
            dispatch({
              type: GET_TECHNICIAN_SCHEDULE,
              payload: schedules,
              raw: response.data,
              start,
            }),
            dispatch({
              type: SUBSCRIBE_LIST,
              List: `${Co}_${Technician}_${moment(start).format(
                "M-D-YYYY"
              )}_Trips_${view || "W"}`,
            }),
            dispatch({
              type: UNSUBSCRIBE_LIST,
              List: `${Co}_${
                technician ? technician.Employee : Employee
              }_${moment(oldStart).format("M-D-YYYY")}_Timecard_${view || "W"}`,
            }),
            dispatch(getTechnicianTimecard(data, dt, view || "W")),
            // dispatch(getTechnicianPerformance(data, dt))
          ]);
        })
        .catch((e) => {
          const { schedules } = state.spReducers.offline;
          if (schedules) {
            const sched = _.find(schedules, { start });
            if (sched) {
              Promise.all([
                dispatch({
                  type: GET_TECHNICIAN_SCHEDULE,
                  ...sched,
                }),
                dispatch({
                  type: SUBSCRIBE_LIST,
                  List: `${Co}_${Technician}_${moment(start).format(
                    "M-D-YYYY"
                  )}_Trips_${view || "W"}`,
                }),
                dispatch({
                  type: UNSUBSCRIBE_LIST,
                  List: `${Co}_${
                    technician ? technician.Employee : Employee
                  }_${moment(oldStart).format("M-D-YYYY")}_Timecard_${view ||
                    "W"}`,
                }),
                dispatch(getTechnicianTimecard(data, dt, view || "W")),
                // dispatch(getTechnicianPerformance(data, dt))
              ]);
            }
          }
        });
    } else {
      dispatch({
        type: SET_TECHNICIAN_DATE,
        date: new Date(dt),
      });
    }
  };
}

export function getTechnicianEstimates(data, page) {
  return (dispatch, getState) => {
    const { estimates } = getState().spReducers.technician;
    Promise.all([
      dispatch({
        type: SET_TECHNICIAN_ESTIMATES,
        payload: {
          ...estimates,
          Page: page,
          Results: [],
          loading: true,
        },
      }),
    ]).then(() => {
      const { Co, Technician } = data;
      const request = axios.get(
        `${
          window["apiLocation"]
        }/api/Estimate?Co=${Co}&Technician=${encodeURIComponent(
          Technician
        )}&Page=${page || 1}`
      );
      return request.then((response) => {
        dispatch({
          type: SET_TECHNICIAN_ESTIMATES,
          payload: response.data,
        });
      });
    });
  };
}

export function setTechnicianEstimates(data) {
  return {
    type: SET_TECHNICIAN_ESTIMATES,
    payload: data,
  };
}

export function setTechnicianSchedule(data) {
  return {
    type: SET_TECHNICIAN_SCHEDULE,
    payload: data,
  };
}

export function getTechnicianTimecard(data, dt, view) {
  const { Co, Employee } = data;
  const date = moment(dt);
  const start = date
    .locale("us")
    .startOf("isoweek")
    .format("M/D/YYYY");
  // console.warn("Start of Week: ", start, dt);

  const request = axios.get(
    `${window["apiLocation"]}/api/Timecard?Co=${encodeURIComponent(
      Co
    )}&Date=${encodeURIComponent(dt)}&Employee=${encodeURIComponent(
      Employee
    )}&View=${encodeURIComponent(view)}`
  );

  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: SET_TECHNICIAN,
      payload: data,
    });
    dispatch({
      type: SET_TECHNICIAN_TIMECARD,
      payload: null,
    });
    dispatch({
      type: SET_TECHNICIAN_TIMECARDS,
      payload: [],
    });
    request.then((response) => {
      const timecards = [];
      for (var i = 0; i < 7; i++) {
        let ndt = new Date(start);
        ndt.setDate(ndt.getDate() + i);
        ndt = moment(ndt).format("M/D/YYYY");
        timecards.push({
          Date: ndt,
          timecard:
            _.find(response.data, (o) => {
              return moment(o.Date).format("M/D/YYYY") === ndt;
            }) || {},
        });
      }
      Promise.all([
        dispatch({
          type: GET_TECHNICIAN_TIMECARDS,
          payload: timecards,
        }),
        dispatch({
          type: SUBSCRIBE_LIST,
          List: `${Co}_${Employee}_${moment(start).format(
            "M-D-YYYY"
          )}_Timecard_${view || "W"}`,
        }),
      ]);
    });
  };
}

export function setTechnicianTimecards(data) {
  return {
    type: SET_TECHNICIAN_TIMECARDS,
    payload: data,
  };
}

export function setTechnicianTimecard(data) {
  return {
    type: SET_TECHNICIAN_TIMECARD,
    payload: data,
  };
}

export function getTechnicianPerformance(data, dt) {
  const { Co, Technician } = data;

  const request = axios.get(
    `${window["apiLocation"]}/api/TeamPerformance?Co=${encodeURIComponent(
      Co
    )}&End=${encodeURIComponent(dt)}&TeamLead=${encodeURIComponent(Technician)}`
  );

  return (dispatch, getState) => {
    // const state = getState();
    dispatch({
      type: SET_TECHNICIAN_PERFORMANCE,
      payload: [],
    });
    request.then((response) => {
      response.data.map((value, index) => {
        value.Employee = Number(value.Employee);
        value.Team = Number(value.Team);
        value.Qty = Number(value.Qty);
        value.Cost = Number(value.Cost);
        value.Revenue = Number(value.Revenue);
        value.Date = new Date(value.Date);
      });
      Promise.all([
        dispatch({
          type: GET_TECHNICIAN_PERFORMANCE,
          payload: response.data,
        }),
      ]);
    });
  };
}

export function setTechnicianPerformance(data) {
  return {
    type: SET_TECHNICIAN_PERFORMANCE,
    payload: data,
  };
}
