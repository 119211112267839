import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    loading: false,
    category: null,
    reportCategory: null,
    // reports: [],
    report: null,
    selectedReport: null,
    dataSources: [],
};

function firstDayInPreviousMonth() {
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);
    return d;
}

const reportingReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.SET_REPORTING_CATEGORY:
            {
                return {
                    ...state,
                    reportCategory: action.category,
                    report: null,
                };
            }
        case Actions.SET_DATA_SOURCES:
            {
                const { category, dataSources } = action;
                return {
                    ...state,
                    category,
                    dataSources
                }
            }
        case Actions.SET_SELECTED_REPORT: 
        {
            const { category, report, data, loading } = action;
            return {
                ...state,
                selectedReport: report,
                entities: data,
            }
        }
        break;
        case Actions.SET_REPORT_DATA:
            {
                const { category, report, data, loading } = action;
                return {
                    ...state,
                    category,
                    report,
                    entities: data,
                    loading: loading,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default reportingReducer;
