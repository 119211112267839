import axios from 'axios';
import { getUserData } from 'main/content/apps/text-gather-phrases/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_TEXT_GATHER_PHRASES = '[TEXT GATHER PHRASES APP] GET TEXT GATHER PHRASES';
export const SET_SEARCH_TEXT = '[TEXT GATHER PHRASES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TEXT_GATHER_PHRASES = '[TEXT GATHER PHRASES APP] TOGGLE IN SELECTED TEXT GATHER PHRASES';
export const SELECT_ALL_TEXT_GATHER_PHRASES = '[TEXT GATHER PHRASES APP] SELECT ALL TEXT GATHER PHRASES';
export const DESELECT_ALL_TEXT_GATHER_PHRASES = '[TEXT GATHER PHRASES APP] DESELECT ALL TEXT GATHER PHRASES';
export const OPEN_NEW_TEXT_GATHER_PHRASE_DIALOG = '[TEXT GATHER PHRASES APP] OPEN NEW TEXT GATHER PHRASE DIALOG';
export const CLOSE_NEW_TEXT_GATHER_PHRASE_DIALOG = '[TEXT GATHER PHRASES APP] CLOSE NEW TEXT GATHER PHRASE DIALOG';
export const OPEN_EDIT_TEXT_GATHER_PHRASE_DIALOG = '[TEXT GATHER PHRASES APP] OPEN EDIT TEXT GATHER PHRASE DIALOG';
export const UPDATE_EDIT_TEXT_GATHER_PHRASE_DIALOG = '[TEXT GATHER PHRASES APP] UPDATE EDIT TEXT GATHER PHRASE DIALOG';
export const CLOSE_EDIT_TEXT_GATHER_PHRASE_DIALOG = '[TEXT GATHER PHRASES APP] CLOSE EDIT TEXT GATHER PHRASE DIALOG';
export const ADD_TEXT_GATHER_PHRASE = '[TEXT GATHER PHRASES APP] ADD TEXT GATHER PHRASE';
export const UPDATE_TEXT_GATHER_PHRASE = '[TEXT GATHER PHRASES APP] UPDATE TEXT GATHER PHRASE';
export const REMOVE_TEXT_GATHER_PHRASE = '[TEXT GATHER PHRASES APP] REMOVE TEXT GATHER PHRASE';
export const REMOVE_TEXT_GATHER_PHRASES = '[TEXT GATHER PHRASES APP] REMOVE TEXT GATHER PHRASES';
export const TOGGLE_STARRED_TEXT_GATHER_PHRASE = '[TEXT GATHER PHRASES APP] TOGGLE STARRED TEXT GATHER PHRASE';
export const TOGGLE_STARRED_TEXT_GATHER_PHRASES = '[TEXT GATHER PHRASES APP] TOGGLE STARRED TEXT GATHER PHRASES';
export const SET_TEXT_GATHER_PHRASES_STARRED = '[TEXT GATHER PHRASES APP] SET TEXT GATHER PHRASES STARRED ';

export function getTextGatherPhrases(routeParams) {
    const request = axios.get('/api/text-gather-phrases-app/text-gather-phrases', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TEXT_GATHER_PHRASES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTextGatherPhrases(TextGatherPhraseId) {
    return {
        type: TOGGLE_IN_SELECTED_TEXT_GATHER_PHRASES,
        TextGatherPhraseId
    }
}


export function selectAllTextGatherPhrases() {
    return {
        type: SELECT_ALL_TEXT_GATHER_PHRASES
    }
}

export function deSelectAllTextGatherPhrases() {
    return {
        type: DESELECT_ALL_TEXT_GATHER_PHRASES
    }
}


export function openNewTextGatherPhrasesDialog(phone) {
    return {
        type: OPEN_NEW_TEXT_GATHER_PHRASE_DIALOG,
        phone,
    }
}

export function closeNewTextGatherPhrasesDialog() {
    return {
        type: CLOSE_NEW_TEXT_GATHER_PHRASE_DIALOG
    }
}

export function openEditTextGatherPhrasesDialog(data) {
    return {
        type: OPEN_EDIT_TEXT_GATHER_PHRASE_DIALOG,
        data
    }
}

export function closeEditTextGatherPhrasesDialog() {
    return {
        type: CLOSE_EDIT_TEXT_GATHER_PHRASE_DIALOG
    }
}

export function addTextGatherPhrase(newTextGatherPhrase) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/TextGatherPhrase`,
            newTextGatherPhrase
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_TEXT_GATHER_PHRASE,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Phrase Press #${newTextGatherPhrase.Phrase} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateTextGatherPhrase(TextGatherPhrase) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/TextGatherPhrase?Co=${encodeURIComponent(TextGatherPhrase.Co)}&Gather=${encodeURIComponent(TextGatherPhrase.Gather)}&Phrase=${encodeURIComponent(TextGatherPhrase.Phrase)}`,
            TextGatherPhrase
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_TEXT_GATHER_PHRASE,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Phrase Press #${TextGatherPhrase.Phrase} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeTextGatherPhrase(TextGatherPhrase) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/TextGatherPhrase?Co=${encodeURIComponent(TextGatherPhrase.Co)}&Gather=${encodeURIComponent(TextGatherPhrase.Gather)}&Phrase=${encodeURIComponent(TextGatherPhrase.Phrase)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TEXT_GATHER_PHRASE,
                    data: TextGatherPhrase
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Phrase Press #${TextGatherPhrase.Phrase} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeTextGatherPhrases(TextGatherPhraseIds) {
    return (dispatch, getState) => {

        const request = axios.post('/api/text-gather-phrases-app/remove-TextGatherPhrases', {
            TextGatherPhraseIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TEXT_GATHER_PHRASES
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHER_PHRASES
                })
            ])
        );
    };
}

export function toggleStarredTextGatherPhrase(TextGatherPhraseId) {
    return (dispatch, getState) => {

        const request = axios.post('/api/text-gather-phrases-app/toggle-starred-TextGatherPhrase', {
            TextGatherPhraseId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TEXT_GATHER_PHRASE
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function toggleStarredTextGatherPhrases(TextGatherPhraseIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGatherPhrasesApp.TextGatherPhrases;

        const request = axios.post('/api/text-gather-phrases-app/toggle-starred-TextGatherPhrases', {
            TextGatherPhraseIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TEXT_GATHER_PHRASES
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHER_PHRASES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTextGatherPhrases(routeParams)))
        );
    };
}

export function setTextGatherPhrasesStarred(TextGatherPhraseIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGatherPhrasesApp.TextGatherPhrases;

        const request = axios.post('/api/text-gather-phrases-app/set-TextGatherPhrases-starred', {
            TextGatherPhraseIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEXT_GATHER_PHRASES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHER_PHRASES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTextGatherPhrases(routeParams)))
        );
    };
}

export function setTextGatherPhrasesUnstarred(TextGatherPhraseIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGatherPhrasesApp.TextGatherPhrases;

        const request = axios.post('/api/text-gather-phrases-app/set-TextGatherPhrases-unstarred', {
            TextGatherPhraseIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEXT_GATHER_PHRASES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHER_PHRASES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTextGatherPhrases(routeParams)))
        );
    };
}
