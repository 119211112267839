import _ from '@lodash';
import { Card, CircularProgress, Icon, IconButton, Button, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import clsx from 'clsx';
import FileList from 'main/content/apps/file-manager/FileList';
import InvoicesList from 'main/content/apps/invoices/InvoicesList';
import TripsList from 'main/content/apps/trips/TripsList';
import WorkOrderUsagesList from 'main/content/apps/work-order-usages/WorkOrderUsagesList';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseOrdersList from '../purchase-orders/PurchaseOrdersList';
import ScopeDetails from '../work-orders/WorkOrderScope';
import * as Actions from './store/actions/work-order-history.actions';
import { openPreloadWorkOrderDialog, openEditWorkOrderDialog, closeEditWorkOrderDialog, closeNewWorkOrderDialog } from '../work-orders/store/actions/work-orders.actions';
import { openMinimizedDialog } from '../minimized-dialogs/store/actions/minimized-dialogs.actions';

const styles = theme => ({
    root: {},
    histTable: {
        marginLeft: theme.spacing(7) + 1,
        padding: 8,
    },
    histDrawer: {
        width: 64,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%',
        marginTop: '60px'
    },
    histShelf: {
        position: 'relative'
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        zIndex: 99999,
    },
    drawerClose: {
        width: 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    dividerPlain: {
        color: '#fff',
    },
    dividerHighlight: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
    invHeader: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        fontSize: 36,
        fontWeight: 'bold',
        color: '#333'
    },
    invSubHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right',
        marginTop: 4,
        color: '#333'
    },
})

// Want WorkerOrders to copy OPEN_EDIT_WORK_ORDER_DIALOG structure
let newWorkOrderHistoryState = {
    Co: null,
    loading: false,
    open: false,
    data: null,
    expanded: null,
    scopeExpanded: {}
}


class WorkOrderHistory extends Component {
    state = { ..._.cloneDeepWith(newWorkOrderHistoryState) };

    componentDidMount() {
        const { data } = this.props;
        this.setState({ data, open: Boolean(data) });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data } = this.props;
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ data, open: Boolean(data) });
        }
    }

    toggleSlide = (item) => {
        let expanded;
        if (this.state.expanded !== item) {
            expanded = item;
        }
        this.setState({ ...this.state, expanded });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    openWorkOrder = async () => {
	
        const { workOrderDialog, openMinimizedDialog, openPreloadWorkOrderDialog, openEditWorkOrderDialog, setWorkOrderHistory, closeEditWorkOrderDialog, closeNewWorkOrderDialog } = this.props;
        
        let dialogList = [...this.props.minimizedDialogs];
        dialogList.push({
            header: `Work Order #${workOrderDialog.data.WorkOrder}`, 
            open: false, icon: 'assignment',
            dialogState: workOrderDialog.data,
            reopenFxn: openPreloadWorkOrderDialog.bind(this)
        })
        openMinimizedDialog(dialogList)

        const wo = _.cloneDeep(this.state.data)
        await workOrderDialog.type === 'edit' ? closeEditWorkOrderDialog() : closeNewWorkOrderDialog();
		
        openEditWorkOrderDialog(wo, wo.Trip, true)
        setWorkOrderHistory(null);
    }

    render() {
        const { classes, divisions, invoiceDialog, back } = this.props;
        const { data, expanded } = this.state;
        const { trip } = invoiceDialog.props;
        let woNum;

        // Init Graphs data
        let revenue = 0, cost = 0, costPercent = 0, laborPercent = 0, matPercent = 0, hours = 0, cph = 0, rph = 0, matCost = 0, laborCost = 0, poCost = 0;

        if (data) {
            if (data.WorkOrder) {
                woNum = data.WorkOrder;
                // Graphs data
                revenue = _.sumBy(data.Data.Invoices, 'BilledTotal');
                laborCost = _.sumBy(data.Data.Labor, (o) => o.ActualCost || o.Total);
                poCost = _.sumBy(data.Data.POs, (o) => _.sumBy(o.Data.Lines, (l) => l.ActualCost || l.CostTotal));
                matCost = _.sumBy(data.Data.Inventory, 'Amount');
                matCost += poCost;
                cost = laborCost + matCost;
                costPercent = revenue > 0 ? Math.round((cost / revenue) * 100) : 100;
                laborPercent = cost > 0 ? Math.round((laborCost / cost) * 100) : 0;
                matPercent = cost > 0 ? Math.round((matCost / cost) * 100) : 0;
                hours = _.round(_.sumBy(data.Data.Labor, 'Qty'), 2);
                cph = hours > 0 ? _.round(cost / hours, 2) : Infinity;
                rph = hours > 0 ? _.round(revenue / hours, 2) : Infinity;

            }
        }
        const scope = data && data.Data && data.Data.Scopes && data.Data.Scopes.length > 0 ? data.Data.Scopes[0] : null;
        const division = scope ? _.find(divisions, { Co: scope.Co, BusinessUnit: scope.BusinessUnit, Division: scope.Division }) : null;

        const priorities = [{ text: 'Critical', color: 'red' }, { text: 'High', color: 'red' }, { text: 'Medium', color: 'gold' }, { text: 'Low', color: 'inherit' }];
        const techAvatar = 'assets/images/avatars/profile.jpg';


        return (
            <div className="w-full px-8">
                {back &&
                    <div className='w-full flex justify-between border-b-1 border-grey-light'>
                        <div className="flex pb-8">
                            <IconButton
                                className="p-0 mr-8"
                                onClick={this.props.back}
                            >
                                <Icon className="text-32" color="primary">keyboard_arrow_left</Icon>
                            </IconButton>

                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-18 font-600">
                                <span>Work Order #{woNum}</span>
                            </Typography>
                        </div>

                        {/* <Button variant="contained" color="primary" onClick={this.openWorkOrder} className="max-h-32">
                            Open Work Order
                            <Icon className='ml-8 text-16'>launch</Icon>
                        </Button> */}
                    </div>
                }
                {data &&
                    <div className="w-full flex justify-between mt-12 pr-12">
                        {division && division.InvoiceHeaderImg &&
                            <img className="h-48 sm:h-96" src={division.InvoiceHeaderImg} />
                        }
                        <span className="sm:mt-4">
                            <label className={classNames(classes.invHeader, "text-20 sm:text-36")}>SUMMARY</label>
                            <div className={classNames(classes.invSubHeader, "text-10 sm:text-14 text-center")}>{`${moment().format("M/D/YYYY")} at ${moment().format("h:mm A")}`}</div>
                        </span>
                    </div>
                }

                {data &&
                    <div className="flex w-full py-16 justify-between pl-24">
                        <div className={classNames(classes.link)}>
                            <Typography style={{ alignItems: 'center', }} className="flex relative">
                                <span>
                                    <strong>Site #{data.Site}</strong>
                                </span>
                            </Typography>
                            <div className="text-xs w-full truncate">
                                {data.siteContact && data.siteContact.Name ? data.siteContact.Name : data.Data.Customer.Name || `${data.Data.Customer.FirstName} ${data.Data.Customer.LastName}`}<br />
                                {`${data.Data.Site.Address1}`}<br />
                                {data.Data.Site.Address2 && data.Data.Site.Address2.length > 0 ? data.Data.Site.Address2 : ''}
                                {data.Data.Site.Address2 && data.Data.Site.Address2.length > 0 ? <br /> : ''}
                                {`${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`}<br />
                                {/* {`${data.Data.Site.Phone}`}<br /> */}
                                {data.Data.Site.CrossStreets &&
                                    <div className="capitalize font-bold"><Icon color="primary" className="mr-4 text-14 align-middle mb-2">location_searching</Icon>{data.Data.Site.CrossStreets}</div>
                                }
                                <div className="cursor-pointer truncate">
                                    <Icon className="text-14 mr-4 cursor-pointer align-middle" color="primary">email</Icon>
                                    {`${data.siteContact && data.siteContact.Email ? data.siteContact.Email : data.reqBy && data.reqBy.Email ? data.reqBy.Email : data.Data.Site.Email || data.Data.Customer.Email || 'Send Email'}`}
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {
                    data &&
                    <div className="flex mt-8 mb-12">
                        <div className="w-full">
                            <div className="w-full flex justify-between rounded-full mb-12" style={{ backgroundColor: '#3f3f3f' }}>
                                <div className={classNames("w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer", classes.dividerHighlight)}>
                                    Work Order #{data.WorkOrder}
                                </div>
                                <div className={classNames("w-2/3 py-8 px-24 text-12 font-bold text-right truncate", classes.dividerPlain)}>
                                    {data.Description}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div>
                    <div className="sm:flex justify-between">
                        <Card className="mb-12 rounded-lg flex-1" style={{ border: '1px solid #e1e1e1' }}>
                            <div className="w-full sm:flex">
                                <div className="w-full">
                                    <div className="w-full min-h-48 relative border-grey-light border-b-1 clickable">
                                        <div className="w-full justify-center absolute pin-t pin-b m-auto py-8">
                                            <Typography variant="h6" className="flex text-14 mt-4 ml-4 align-middle"><Icon color="secondary" className="text-24">insert_chart</Icon>
                                                <span className="font-bold ml-4">Revenue</span>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="w-full min-h-256 relative clickable">
                                        <div className="w-full justify-center absolute pin-t pin-b m-auto h-80">
                                            <Typography className="w-full text-center text-40 font-600 text-grey-darker mt-4" style={{ height: 48 }}>{this.formatDollars(revenue)}</Typography>
                                            <Typography className="w-full text-center text-30 sm:text-30 md:text-30 lg:text-30">Revenue</Typography>
                                        </div>
                                    </div>

                                    <div className="w-full min-h-128 flex border-grey-light border-t-1 clickable">
                                        <div className="w-full sm:w-1/2 border-grey-light border-r-1 flex-1 flex">
                                            <div className="w-full justify-center relative pin-t pin-b m-auto h-56">
                                                <Typography className="w-full text-center text-20 font-600 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(cost)}</Typography>
                                                <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Cost</Typography>
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 flex-1 flex">
                                            <div className="w-full justify-center relative pin-t pin-b m-auto h-56" style={{ marginLeft: 'auto' }}>
                                                <Typography className="w-full text-center text-20 font-600 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(revenue - cost)}</Typography>
                                                <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Gross Margin</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <Card className="mb-12 rounded-lg flex-1 sm:mx-16" style={{ border: '1px solid #e1e1e1' }}>
                            <div className="w-full sm:flex">
                                <div className="w-full">
                                    <div className="w-full min-h-48 relative border-grey-light border-b-1 clickable">
                                        <div className="w-full justify-center absolute pin-t pin-b m-auto py-8">
                                            <Typography variant="h6" className="flex text-14 mt-4 ml-4 align-middle"><Icon color="secondary" className="text-24">insert_chart</Icon>
                                                <span className="font-bold ml-4">Costs</span>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="w-full min-h-256 relative clickable">
                                        <div className="w-full absolute pin-t pin-b pin-l pin-r m-auto max-w-256 max-h-256 sm:max-w-192 sm:max-h-192 md:max-h-160 md:max-w-160 lg:max-w-256 lg:max-h-256">
                                            <div className="w-full relative" style={{ paddingTop: '100%', transform: 'translate(0, 0%)' }}>
                                                <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset', color: '#f1f1f1' }} className="pin-l pin-r pin-t pin-b absolute" value={100} />
                                                <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset' }} className="pin-l pin-r pin-t pin-b absolute" value={costPercent > 100 ? 100 : costPercent} />
                                            </div>
                                            <div className={`pin-l pin-r pin-t pin-b absolute m-auto mb-20`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography variant="caption" component="div" color="textSecondary" className="text-40 md:text-40 lg:text-40">{costPercent}%</Typography>
                                            </div>
                                            <div className={`pin-l pin-r pin-t pin-b absolute m-auto mt-36`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography variant="caption" component="div" color="textSecondary" className="text-30">Cost</Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full min-h-128 flex border-grey-light border-t-1 clickable">
                                        <div className="w-full sm:w-1/2 border-grey-light border-r-1 flex-1 flex">
                                            <div className="w-full relative pin-t pin-b pin-l pin-r m-auto max-w-256 max-h-256 sm:max-w-192 sm:max-h-192 md:max-h-160 md:max-w-160 lg:max-w-256 lg:max-h-256">
                                                <div className="w-full relative" style={{ paddingTop: '100%', transform: 'translate(0, 0%)' }}>
                                                    <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset', color: '#f1f1f1' }} className="pin-l pin-r pin-t pin-b absolute" value={100} />
                                                    <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset' }} className="pin-l pin-r pin-t pin-b absolute" value={laborPercent} />
                                                </div>
                                                <div className={`pin-l pin-r pin-t pin-b absolute m-auto mb-20`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography variant="caption" component="div" color="textSecondary" className="text-20 md:text-20 lg:text-20">{laborPercent}%</Typography>
                                                </div>
                                                <div className={`pin-l pin-r pin-t pin-b absolute m-auto mt-16`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography variant="caption" component="div" color="textSecondary" className="text-10">Labor</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 flex-1 flex">
                                            <div className="w-full relative pin-t pin-b pin-l pin-r m-auto max-w-256 max-h-256 sm:max-w-192 sm:max-h-192 md:max-h-160 md:max-w-160 lg:max-w-256 lg:max-h-256">
                                                <div className="w-full relative" style={{ paddingTop: '100%', transform: 'translate(0, 0%)' }}>
                                                    <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset', color: '#f1f1f1' }} className="pin-l pin-r pin-t pin-b absolute" value={100} />
                                                    <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset' }} className="pin-l pin-r pin-t pin-b absolute" value={matPercent} />
                                                </div>
                                                <div className={`pin-l pin-r pin-t pin-b absolute m-auto mb-20`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography variant="caption" component="div" color="textSecondary" className="text-20 md:text-20 lg:text-20">{matPercent}%</Typography>
                                                </div>
                                                <div className={`pin-l pin-r pin-t pin-b absolute m-auto mt-16`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography variant="caption" component="div" color="textSecondary" className="text-10">Material</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <Card className="mb-12 rounded-lg flex-1" style={{ border: '1px solid #e1e1e1' }}>
                            <div className="w-full sm:flex">
                                <div className="w-full">
                                    <div className="w-full min-h-48 relative border-grey-light border-b-1 clickable">
                                        <div className="w-full justify-center absolute pin-t pin-b m-auto py-8">
                                            <Typography variant="h6" className="flex text-14 mt-4 ml-4 align-middle"><Icon color="secondary" className="text-24">insert_chart</Icon>
                                                <span className="font-bold ml-4">KPI</span>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="w-full min-h-256 relative clickable">
                                        <div className="w-full justify-center absolute pin-t pin-b m-auto h-80">
                                            <Typography className="w-full text-center text-40 font-600 text-grey-darker mt-4" style={{ height: 48 }}>{hours}</Typography>
                                            <Typography className="w-full text-center text-30 sm:text-30 md:text-30 lg:text-30">Hours Worked</Typography>
                                        </div>
                                    </div>

                                    <div className="w-full min-h-128 flex border-grey-light border-t-1 clickable">
                                        <div className="w-full sm:w-1/2 border-grey-light border-r-1 flex-1 flex">
                                            <div className="w-full justify-center relative pin-t pin-b m-auto h-56">
                                                <Typography className="w-full text-center text-20 font-600 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(cph)}</Typography>
                                                <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Cost Per Hour</Typography>
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 flex-1 flex">
                                            <div className="w-full justify-center relative pin-t pin-b m-auto h-56" style={{ marginLeft: 'auto' }}>
                                                <Typography className="w-full text-center text-20 font-600 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(rph)}</Typography>
                                                <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Rev Per Hour</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>

                    </div>

                    <div style={{ width: '100%' }}>
                        {data ? (
                            <>
                                <ListItem className="cursor-pointer flex justify-between py-12" onClick={(e) => { e.stopPropagation(); this.toggleSlide("scopes"); }} style={{ borderBottom: expanded === 'scopes' ? 0 : '1px solid lightgrey' }} >
                                    <Typography variant="h6" className="flex text-16 align-middle"><Icon color="primary" className="text-24 mr-6">build</Icon>
                                        <span>Scopes</span>
                                    </Typography>
                                    <div className="flex">
                                        {(data.Data.Scopes || []).length > 0 &&
                                            <div className="rounded-full px-4 bg-red text-white mr-12 align-middle min-w-24 text-center">{(data.Data.Scopes || []).length}</div>
                                        }
                                        <Icon className="align-middle">{expanded === 'scopes' ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    </div>
                                </ListItem>

                                {expanded === 'scopes' &&
                                    <div className="px-12">
                                        {(data.Data && data.Data.Scopes) && data.Data.Scopes.map((scope, index) => {

                                            const assignedIndex = 1;
                                            const assignedTrip = false;

                                            const genFxn = () => {
                                                console.log("Nothing!")
                                            }


                                            return (
                                                <div key={index}>
                                                    <ScopeDetails
                                                        expanded={this.state.scopeExpanded}
                                                        scopeData={data.Data.Scopes}
                                                        scopeNum={scope}
                                                        indexNum={index}
                                                        Trips={data.Data.Trips}
                                                        trip={trip}
                                                        assignedIndex={assignedIndex}
                                                        assignedTrip={assignedTrip}
                                                        editTechs={{}}
                                                        editScopes={{}}
                                                        editQuotes={{}}
                                                        editWarranties={{}}
                                                        editSummary={{}}
                                                        woOpt={null}
                                                        techAvatar={techAvatar}
                                                        tripAccessLevel={null}
                                                        WorkOrder={data.WorkOrder}
                                                        priorities={priorities}
                                                        classes={classes}
                                                        launchEstimate={genFxn}
                                                        launchInvoice={genFxn}
                                                        assignUnit={genFxn}
                                                        unassignUnit={genFxn}
                                                        thisState={this.state}
                                                        setStateFxn={this.setState.bind(this)}
                                                        pdf={true}
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }

                                <ListItem className="cursor-pointer flex justify-between py-12" onClick={(e) => { e.stopPropagation(); this.toggleSlide("trips"); }} style={{ borderBottom: expanded === 'trips' ? 0 : '1px solid lightgrey' }} >
                                    <Typography variant="h6" className="flex text-16 align-middle"><Icon color="primary" className="text-24 mr-6">local_shipping</Icon>
                                        <span>Trips</span>
                                    </Typography>
                                    <div className="flex">
                                        {(data.Data.Trips || []).length > 0 &&
                                            <div className="rounded-full px-4 bg-red text-white mr-12 align-middle min-w-24 text-center">{(data.Data.Trips || []).length}</div>
                                        }
                                        <Icon className="align-middle">{expanded === 'trips' ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    </div>
                                </ListItem>

                                {expanded === 'trips' &&
                                    <div className="px-12">
                                        <TripsList animation="transition.slideDownIn" selectedTripData={data.Data.Trips} readOnly={true} noTitle={true} />
                                    </div>
                                }

                                <ListItem className="cursor-pointer flex justify-between py-12" onClick={(e) => { e.stopPropagation(); this.toggleSlide("usage"); }} style={{ borderBottom: expanded === 'usage' ? 0 : '1px solid lightgrey' }} >
                                    <Typography variant="h6" className="flex text-16 align-middle"><Icon color="primary" className="text-24 mr-6">format_list_bulleted</Icon>
                                        <span>Usage</span>
                                    </Typography>
                                    <div className="flex">
                                        {(data.Data.Usage || []).length > 0 &&
                                            <div className="rounded-full px-4 bg-red text-white mr-12 align-middle min-w-24 text-center">{(data.Data.Usage || []).length}</div>
                                        }
                                        <Icon className="align-middle">{expanded === 'usage' ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    </div>
                                </ListItem>

                                {expanded === 'usage' &&
                                    <div className="px-12">
                                        <WorkOrderUsagesList animation="transition.slideDownIn" readOnly={true} noTitle={true} workOrder={data} selectedWorkOrderUsageData={data.Data.Usage} />
                                    </div>
                                }

                                <ListItem className="cursor-pointer flex justify-between py-12" onClick={(e) => { e.stopPropagation(); this.toggleSlide("po"); }} style={{ borderBottom: expanded === 'po' ? 0 : '1px solid lightgrey' }} >
                                    <Typography variant="h6" className="flex text-16 align-middle"><Icon color="primary" className="text-24 mr-6">list_alt</Icon>
                                        <span>Purchase Orders</span>
                                    </Typography>
                                    <div className="flex">
                                        {(data.Data.POs || []).length > 0 &&
                                            <div className="rounded-full px-4 bg-red text-white mr-12 align-middle min-w-24 text-center">{(data.Data.POs || []).length}</div>
                                        }
                                        <Icon className="align-middle">{expanded === 'po' ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    </div>
                                </ListItem>

                                {expanded === 'po' &&
                                    <div className="px-12">
                                        <PurchaseOrdersList animation="transition.slideDownIn" workOrder={data} selectedPurchaseOrderData={data.Data.POs} readOnly={true} noTitle={true} />
                                    </div>
                                }

                                <ListItem className="cursor-pointer flex justify-between py-12" onClick={(e) => { e.stopPropagation(); this.toggleSlide("invoices"); }} style={{ borderBottom: expanded === 'invoices' ? 0 : '1px solid lightgrey' }}  >
                                    <Typography variant="h6" className="flex text-16 align-middle"><Icon color="primary" className="text-24 mr-6">receipt</Icon>
                                        <span>Invoices</span>
                                    </Typography>
                                    <div className="flex">
                                        {(data.Data.Invoices || []).length > 0 &&
                                            <div className="rounded-full px-4 bg-red text-white mr-12 align-middle min-w-24 text-center">{(data.Data.Invoices || []).length}</div>
                                        }
                                        <Icon className="align-middle">{expanded === 'invoices' ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    </div>
                                </ListItem>

                                {expanded === 'invoices' &&
                                    <div className="px-12">
                                        <InvoicesList animation="transition.slideDownIn" invoiceData={data.Data.Invoices} readOnly={true} noTitle={true} />
                                    </div>
                                }

                                <ListItem className="cursor-pointer flex justify-between py-12" onClick={(e) => { e.stopPropagation(); this.toggleSlide("attachments"); }} >
                                    <Typography variant="h6" className="flex text-16 align-middle"><Icon color="primary" className="text-24 mr-6" style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>
                                        <span>Attachments</span>
                                    </Typography>
                                    <div className="flex">
                                        {(data.Data.Attachments || []).length > 0 &&
                                            <div className="rounded-full px-4 bg-red text-white mr-12 align-middle min-w-24 text-center">{(data.Data.Attachments || []).length}</div>
                                        }
                                        <Icon className="align-middle">{expanded === 'attachments' ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    </div>
                                </ListItem>

                                {expanded === 'attachments' &&
                                    <div className="px-12">
                                        <FileList attachments={data.Data.Attachments} readOnly={true} noTitle={true} accept=".jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt" />
                                    </div>
                                }
                            </>
                        ) : null
                        }
                    </div>
                </div>
            </div >
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setWorkOrderHistory: Actions.setWorkOrderHistory,
        openMinimizedDialog,
        openPreloadWorkOrderDialog,
        openEditWorkOrderDialog,
        closeEditWorkOrderDialog,
        closeNewWorkOrderDialog,
    }, dispatch);
}

function mapStateToProps({ workOrdersApp, minimizedDialogApp, spReducers, invoicesApp }) {
    return {
        divisions: spReducers.divisions,
        invoiceDialog: invoicesApp.invoices.invoiceDialog,
        workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
        minimizedDialogs: minimizedDialogApp.minimizedDialog.data
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WorkOrderHistory));