import * as Actions from 'store/actions/spConnection';

const initialState = {
    qboCompany: null,
    preferences: null,
    departments: [],
    classes: [],
    employees: [],
    materials: [],
    nonInventory: [],
    categories: [],
    vendors: [],
    earnCodes: [],
    glAccounts: [],
    payTerms: [],
    taxCodes: [],
    taxRates: [],
    payrollItemWages: []
}

const qboSettings = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_QBO_COMPANY:
            {
                return {
                    ...state,
                    qboCompany: action.payload
                };
            }
        case Actions.GET_QBO_PREFERENCES_DATA:
            {
                return {
                    ...state,
                    preferences: action.payload
                };
            }
        case Actions.SET_QBO_PREFERENCES_DATA:
            {
                return {
                    ...state,
                    preferences: action.payload
                };
            }
        case Actions.GET_QBO_DEPARTMENT_DATA:
            {
                return {
                    ...state,
                    departments: action.payload
                };
            }
        case Actions.SET_QBO_DEPARTMENT_DATA:
            {
                return {
                    ...state,
                    departments: action.payload
                };
            }
        case Actions.GET_QBO_CLASS_DATA:
            {
                return {
                    ...state,
                    classes: action.payload
                };
            }
        case Actions.SET_QBO_CLASS_DATA:
            {
                return {
                    ...state,
                    classes: action.payload
                };
            }
        case Actions.GET_QBO_VENDOR_DATA:
            {
                return {
                    ...state,
                    vendors: action.payload
                };
            }
        case Actions.SET_QBO_VENDOR_DATA:
            {
                return {
                    ...state,
                    vendors: action.payload
                };
            }
        case Actions.GET_QBO_EMPLOYEE_DATA:
            {
                return {
                    ...state,
                    employees: action.payload
                };
            }
            case Actions.GET_QBO_PAYROLL_ITEM_WAGE_DATA:
                {
                    return {
                        ...state,
                        payrollItemWages: action.payload
                    };
                }
        case Actions.SET_QBO_EMPLOYEE_DATA:
            {
                return {
                    ...state,
                    employees: action.payload
                };
            }
            case Actions.GET_QBO_NON_INVENTORY_DATA:
                {
                    return {
                        ...state,
                        nonInventory: action.payload
                    };
                }
            case Actions.SET_QBO_NON_INVENTORY_DATA:
                {
                    return {
                        ...state,
                        nonInventory: action.payload
                    };
                }
        case Actions.GET_QBO_MATERIAL_DATA:
            {
                return {
                    ...state,
                    materials: action.payload
                };
            }
        case Actions.SET_QBO_MATERIAL_DATA:
            {
                return {
                    ...state,
                    materials: action.payload
                };
            }
        case Actions.GET_QBO_CATEGORY_DATA:
            {
                return {
                    ...state,
                    categories: action.payload
                };
            }
        case Actions.SET_QBO_CATEGORY_DATA:
            {
                return {
                    ...state,
                    categories: action.payload
                };
            }
        case Actions.GET_QBO_SERVICE_DATA:
            {
                return {
                    ...state,
                    services: action.payload
                };
            }
        case Actions.SET_QBO_SERVICE_DATA:
            {
                return {
                    ...state,
                    services: action.payload
                };
            }
        case Actions.GET_QBO_EARN_CODE_DATA:
            {
                return {
                    ...state,
                    earnCodes: action.payload
                };
            }
        case Actions.SET_QBO_EARN_CODE_DATA:
            {
                return {
                    ...state,
                    earnCodes: action.payload
                };
            }
        case Actions.GET_QBO_GL_ACCOUNT_DATA:
            {
                return {
                    ...state,
                    glAccounts: action.payload
                };
            }
        case Actions.SET_QBO_GL_ACCOUNT_DATA:
            {
                return {
                    ...state,
                    glAccounts: action.payload
                };
            }
        case Actions.GET_QBO_PAY_TERM_DATA:
            {
                return {
                    ...state,
                    payTerms: action.payload
                };
            }
        case Actions.SET_QBO_PAY_TERM_DATA:
            {
                return {
                    ...state,
                    payTerms: action.payload
                };
            }
        case Actions.GET_QBO_TAX_CODE_DATA:
            {
                return {
                    ...state,
                    taxCodes: action.payload
                };
            }
        case Actions.SET_QBO_TAX_CODE_DATA:
            {
                return {
                    ...state,
                    taxCodes: action.payload
                };
            }
        case Actions.GET_QBO_TAX_RATE_DATA:
            {
                return {
                    ...state,
                    taxRates: action.payload
                };
            }
        case Actions.SET_QBO_TAX_RATE_DATA:
            {
                return {
                    ...state,
                    taxRates: action.payload
                };
            }
    }

    return state;
};

export default qboSettings;