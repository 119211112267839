import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedFormOfPaymentIds: [],
    routeParams       : {},
    formOfPaymentDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const formOfPaymentsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_FORM_OF_PAYMENTS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_FORM_OF_PAYMENTS:
        {

            const formOfPaymentId = action.formOfPaymentId;

            let selectedFormOfPaymentIds = [...state.selectedFormOfPaymentIds];

            if ( selectedFormOfPaymentIds.find(id => id === formOfPaymentId) !== undefined )
            {
                selectedFormOfPaymentIds = selectedFormOfPaymentIds.filter(id => id !== formOfPaymentId);
            }
            else
            {
                selectedFormOfPaymentIds = [...selectedFormOfPaymentIds, formOfPaymentId];
            }

            return {
                ...state,
                selectedFormOfPaymentIds: selectedFormOfPaymentIds
            };
        }
        case Actions.SELECT_ALL_FORM_OF_PAYMENTS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedFormOfPaymentIds = arr.map(formOfPayments => formOfPayments.id);

            return {
                ...state,
                selectedFormOfPaymentIds: selectedFormOfPaymentIds
            };
        }
        case Actions.DESELECT_ALL_FORM_OF_PAYMENTS:
        {
            return {
                ...state,
                selectedFormOfPaymentIds: []
            };
        }
        case Actions.OPEN_NEW_FORM_OF_PAYMENT_DIALOG:
        {
            return {
                ...state,
                formOfPaymentDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_FORM_OF_PAYMENT_DIALOG:
        {
            return {
                ...state,
                formOfPaymentDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_FORM_OF_PAYMENT_DIALOG:
        {
            return {
                ...state,
                formOfPaymentDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_FORM_OF_PAYMENT_DIALOG:
        {
            return {
                ...state,
                formOfPaymentDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default formOfPaymentsReducer;
