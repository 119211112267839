import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedMaterialCategoryIds: [],
    routeParams: {},
    materialCategoryDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const materialCategoriesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MATERIAL_CATEGORIES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_MATERIAL_CATEGORIES:
            {

                const materialCategoryId = action.materialCategoryId;

                let selectedMaterialCategoryIds = [...state.selectedMaterialCategoryIds];

                if (selectedMaterialCategoryIds.find(id => id === materialCategoryId) !== undefined) {
                    selectedMaterialCategoryIds = selectedMaterialCategoryIds.filter(id => id !== materialCategoryId);
                }
                else {
                    selectedMaterialCategoryIds = [...selectedMaterialCategoryIds, materialCategoryId];
                }

                return {
                    ...state,
                    selectedMaterialCategoryIds: selectedMaterialCategoryIds
                };
            }
        case Actions.SELECT_ALL_MATERIAL_CATEGORIES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedMaterialCategoryIds = arr.map(materialCategories => materialCategories.id);

                return {
                    ...state,
                    selectedMaterialCategoryIds: selectedMaterialCategoryIds
                };
            }
        case Actions.DESELECT_ALL_MATERIAL_CATEGORIES:
            {
                return {
                    ...state,
                    selectedMaterialCategoryIds: []
                };
            }
        case Actions.OPEN_NEW_MATERIAL_CATEGORY_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    materialCategoryDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data
                    }
                };
            }
        case Actions.CLOSE_NEW_MATERIAL_CATEGORY_DIALOG:
            {
                return {
                    ...state,
                    materialCategoryDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_MATERIAL_CATEGORY_DIALOG:
            {
                return {
                    ...state,
                    materialCategoryDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_MATERIAL_CATEGORY_DIALOG:
            {
                return {
                    ...state,
                    materialCategoryDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default materialCategoriesReducer;
