import DepartmentsApp from 'main/content/apps/departments/DepartmentsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const DepartmentsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/departments/:id',
            component: DepartmentsApp
        },
        {
            path     : '/apps/departments',
            component: () => <Redirect to="/apps/departments/all"/>
        }
    ]
};
