import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTextGatherPhraseIds: [],
    routeParams: {},
    TextGatherPhrasesDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const TextGatherPhrasesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TEXT_GATHER_PHRASES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_TEXT_GATHER_PHRASES:
            {

                const TextGatherPhraseId = action.TextGatherPhraseId;

                let selectedTextGatherPhraseIds = [...state.selectedTextGatherPhraseIds];

                if (selectedTextGatherPhraseIds.find(id => id === TextGatherPhraseId) !== undefined) {
                    selectedTextGatherPhraseIds = selectedTextGatherPhraseIds.filter(id => id !== TextGatherPhraseId);
                }
                else {
                    selectedTextGatherPhraseIds = [...selectedTextGatherPhraseIds, TextGatherPhraseId];
                }

                return {
                    ...state,
                    selectedTextGatherPhraseIds: selectedTextGatherPhraseIds
                };
            }
        case Actions.SELECT_ALL_TEXT_GATHER_PHRASES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedTextGatherPhraseIds = arr.map(TextGatherPhrases => TextGatherPhrases.id);

                return {
                    ...state,
                    selectedTextGatherPhraseIds: selectedTextGatherPhraseIds
                };
            }
        case Actions.DESELECT_ALL_TEXT_GATHER_PHRASES:
            {
                return {
                    ...state,
                    selectedTextGatherPhraseIds: []
                };
            }
        case Actions.OPEN_NEW_TEXT_GATHER_PHRASE_DIALOG:
            {
                const { Co, ID, Data } = action.phone;
                const { Phrases } = Data;
                return {
                    ...state,
                    TextGatherPhrasesDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            Co,
                            Gather: ID,
                            Phrases,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_TEXT_GATHER_PHRASE_DIALOG:
            {
                return {
                    ...state,
                    TextGatherPhrasesDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_TEXT_GATHER_PHRASE_DIALOG:
            {
                return {
                    ...state,
                    TextGatherPhrasesDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_TEXT_GATHER_PHRASE_DIALOG:
                {
                    return {
                        ...state,
                        TextGatherPhrasesDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_TEXT_GATHER_PHRASE_DIALOG:
            {
                return {
                    ...state,
                    TextGatherPhrasesDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default TextGatherPhrasesReducer;
