
import { Storage } from '@capacitor/storage';
import JSONL from 'json-literal';

export class CapacitorStorage {

  async getItem(key) {

    const { value } = await Storage.get({ key });
    if (value === null) {
      return null;
    }
    return JSONL.parse(value);

  }

  async setItem(key, value) {

    await Storage.set({
      key,
      value: JSONL.stringify(value)
    });

  }

  async removeItem(key) {

    await Storage.remove({ key });

  }

  async getAllKeys() {

    const keys = await Storage.keys();

    return keys;

  }

  async clear() {
    Storage.clear();

  }

}

export default new CapacitorStorage();