import { FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Dialog, Icon, IconButton, List, ListItem, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Inspector } from 'react-inspector';
import { processOfflineData, storeOfflineData } from 'store/actions';
import axios from 'axios';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ minHeight: 384, overflow: 'auto', position: 'relative' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    itmList: {
        padding: 0
    },
    itmItem: {
        borderBottom: '1px solid  ' + theme.palette.divider,

        '&.unread': {
            background: 'rgba(0,0,0,0.03)'
        },
        '&.selected': {
            '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                display: 'block',
                height: '100%',
                width: 3,
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    attachment: {
        fontSize: 13,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.16)',
        paddingLeft: 16,
        marginBottom: 8,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    attachmentFilename: {
        fontWeight: 600
    },
    attachmentSize: {
        marginLeft: 8,
        fontWeight: 300
    },
});
const newOfflineItemsState = {
    item: null,
    loading: false
};

class OfflineItemsDialog extends Component {
    state = { ..._.cloneDeepWith(newOfflineItemsState) };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        const { item } = this.state;
        if (item) {
            this.setState({ item: null });
        } else {
            this.props.onClose();
        }
    };

    render() {
        const { theme, classes, Co, open, title, items } = this.props;
        const { item, loading } = this.state;
        let itemData = null;
        if (item && item.obj) {
            itemData = {};
            Object.keys(item.obj).map((key) => {
                if (/^[A-Z]/.test(key[0]) && item.obj[key]) {
                    itemData[key] = item.obj[key];
                }
            })
        }


        return (
            <Media query={"(min-width: 1200px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: classNames(classes.paper, matches ? "m-24 max-w-lg" : "full-screen-dialog")
                        }}
                        fullScreen={!matches}
                        open={open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={"md"}
                    >
                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit" className="w-1/2 truncate">
                                    <Icon onClick={() => this.setState({ item: null })} className="mr-8 align-middle mb-2 cursor-pointer truncate">{item ? "keyboard_arrow_left" : "cloud_off"}</Icon>{item ? `ID: ${item.obj.OfflineID}` : title}
                                </Typography>
                            </Toolbar>
                            {item &&
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: 104,
                                        top: matches ? 8 : 6,
                                        color: 'white'
                                    }}
                                    onClick={() => {
                                        if (!loading) {
                                            this.setState({ loading: true }, () => {
                                                Promise.all([
                                                    this.props.processOfflineData(item, null, true)
                                                ]).then(() => {
                                                    this.setState({ loading: false });
                                                })
                                            })
                                        }
                                    }}
                                    className={classNames("dialog-header-icon", loading && "spin")}
                                >
                                    <Icon>{!loading ? "cloud_upload" : "refresh"}</Icon>
                                </IconButton>
                            }
                            {item &&
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: 56,
                                        top: matches ? 8 : 6,
                                        color: 'white'
                                    }}
                                    onClick={() => {
                                        const old = _.cloneDeepWith(this.props.items);
                                        const index = _.findIndex(old, (o) => o.obj.OfflineID === item.obj.OfflineID);
                                        window["warn"]('Old Items: ', old, index);
                                        if (index > -1) {
                                            old.splice(index, 1);
                                            this.setState({ item: null }, () => this.props.storeOfflineData(old));
                                        }
                                    }}
                                    className="dialog-header-icon"
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            }
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: matches ? 8 : 6,
                                    color: 'white'
                                }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <TabContainer>
                            {Boolean(items) &&
                                <List className={classes.itmList}>
                                    <FuseAnimateGroup
                                        enter={{
                                            animation: "transition.slideUpBigIn"
                                        }}
                                    >
                                        {
                                            items && items.map((itm) => {
                                                if (!item || (item && item.obj && item.obj.OfflineID === itm.obj.OfflineID)) {
                                                    return (
                                                        <div className="w-full">
                                                            <ListItem
                                                                dense
                                                                button
                                                                onClick={() => this.setState({ item: itm })}
                                                                className={classNames(classes.itmItem, "px-8 sm:px-16")}>
                                                                <div className="w-full flex">
                                                                    <Avatar className={classNames(classes.avatar, "my-8 w-32 h-32")} style={{ backgroundColor: '#4f4f4f' }}>
                                                                        <Icon className="text-20 mb-2" style={{ color: '#fff' }}>cloud_upload</Icon>
                                                                    </Avatar>
                                                                    <div className="flex flex-1 flex-col relative overflow-hidden">
                                                                        <div className="flex items-center justify-between px-12">
                                                                            <div className="flex items-center">
                                                                                <Typography variant="subtitle1" className="font-bold capitalize">{itm.module.replace(/-/g, ' ')} - {itm.method}</Typography>
                                                                            </div>
                                                                            <Typography variant="subtitle1" className="text-12 mt-2 font-bold" style={{ whiteSpace: 'nowrap' }}>{moment(itm.timestamp).format("M/D/YYYY")}</Typography>
                                                                        </div>

                                                                        <div className="flex px-12 py-0 justify-between">
                                                                            <Typography className="truncate text-11">{itm.obj.OfflineID}</Typography>
                                                                            <Typography variant="subtitle1" className="text-10 mt-6" style={{ whiteSpace: 'nowrap' }}>{moment(itm.timestamp).format("h:mm A")}</Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </ListItem>
                                                            {item && item.obj &&
                                                                <div className="p-12 px-16 w-full">
                                                                    {Boolean(item.syncError) &&
                                                                        <div className="w-full mb-12">
                                                                            <div className="p-8 rounded py-12 w-full cursor-pointer" style={{ backgroundColor: '#f9f9f9', border: '1px dashed red' }}>
                                                                                <Typography className="text-14 font-bold text-red w-full text-center"><Icon color="error" className="mr-6 text-20 mb-6 align-middle">cloud_off</Icon>Data Sync Error</Typography>
                                                                                <div className="w-full">
                                                                                    <div className="mt-4 text-center text-12">{item.syncError}</div>
                                                                                    {Boolean(item.syncAttempts) &&
                                                                                        <div className="mt-4 text-center text-10">Upload Attempts: {item.syncAttempts}</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <Inspector data={itemData} name={item.obj.OfflineID} />
                                                                </div>
                                                            }
                                                            {/* {item && item.obj && item.obj.OfflineID && item.obj.OfflineID === itm.obj.OfflineID && 
                                                                <div className="w-full eitm-preview" style={{ maxWidth: '100%', minHeight: 512, overflow: 'auto', borderBottom: '1px solid lightgrey' }}>
                                                                    {parse(item.Body)}
                                                                    {itm.Data.Events && itm.Data.Events.length > 0 &&
                                                                        <div className="full my-24 p-24 pt-12 bt-dotted">
                                                                            <Typography className="text-12 font-bold mb-8">Recipient Events</Typography>
                                                                            {_.orderBy(itm.Data.Events, ['ReceivedDateTime'], ['desc']).map((evt, index) => {
                                                                                return (
                                                                                    <div className={classNames("w-full flex py-6 justify-between", index > 0 && "bt-dotted")}>
                                                                                        <div className="w-full flex">
                                                                                            <Tooltip placement="right" open={evt.Link ? undefined : false} title={`${evt.Link} - [${evt.LinkIndex}]`}>
                                                                                                <Icon className="text-32 mr-12" style={{ color: statuses[evt.EventType] || "#5f5f5f" }}>{events[evt.EventType] || "itm"}</Icon>
                                                                                            </Tooltip>
                                                                                            <div className="w-full">
                                                                                                <Typography className="text-11 font-bold">{evt.To}</Typography>
                                                                                                <Typography className="text-10 capitalize">{evt.EventType}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full text-right">
                                                                                            <Typography className="text-11 font-bold text-right">{moment.utc(evt.ReceivedDateTime).local().format("M/D/YYYY")}</Typography>
                                                                                            <Typography className="text-10 capitalize text-right">{moment.utc(evt.ReceivedDateTime).local().format("h:mm A")}</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            } */}
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </FuseAnimateGroup>
                                </List>
                            }
                        </TabContainer>
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        processOfflineData,
        storeOfflineData
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        users: spReducers.userProfiles.Users
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(OfflineItemsDialog));
