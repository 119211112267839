import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardMedia, CardContent, CardActions, CircularProgress, Divider, Icon, IconButton, LinearProgress, List, ListItem, ListItemText, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import * as Actions from './store/actions';
import _ from '@lodash';
import classNames from 'classnames';
import { SET_CLASS_STEP } from './store/actions';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff'
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
        color: '#333'
    },
    cardSubheader: {
        fontSize: 11,
    },
    avatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 12
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    progress: {
        width: 72,
        height: 72,
        margin: 'auto',
        marginBottom: -48,
        top: -48,
    },
    roundProgress: {
        width: '72px !important',
        height: '72px !important',
    },
    avatar: {
        backgroundColor: '#fff !important',
        width: 72,
        height: 72,
    },
    labels: {},
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
});

const initialState = {
    ID: null,
    Co: null,
    Name: null,
    Description: null,
    Category: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
    editing: false,
};

class AcademyClassCard extends Component {

    state = {
        ...initialState,
    }

    componentDidMount() {
        const { value } = this.props;
        this.setState({ ...initialState, ...value });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value)) {
            this.setState({ ...this.state, ...value });
        }
    }

    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ ...this.state, open: !open });
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        const { editing } = this.state;
        this.setState({ ...this.state, editing: !editing, open: true });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    toggleCategory = (value) => {
        const { category } = this.props;
        if (!category || category.Category !== value.Category) {
            this.props.setCategory(value);
        } else {
            this.props.setCategory(null);
        }
    }

    canBeSubmitted = () => {
        const { Name, Description, Category } = this.state;
        return Name && Name.length > 0 && Description && Description.Length > 0 && Category && !isNaN(Category);
    }

    render() {
        const { classes, value, user, categories, category, classList, securables } = this.props;
        const { open, editing } = this.state;
        const count = _.filter(classList, { Category: value.Category }).length;
        const accessLevel = _.find(securables, { Securable: "learning" });
        const catNum = category ? category.Category : false;
        if (catNum && catNum !== value.Category) {
            return '';
        }
        return (
            <Card onClick={(e) => e.stopPropagation()} className="mt-8 relative has-hover">
                {/* {(value.ID && step) ?
                    <Typography className="font-bold text-white pin-t pin-r absolute mt-4 mr-8" style={{ textShadow: '2px 2px 4px rgba(0,0,0,.35)' }} variant="caption">{step} of {value.Steps}</Typography> : ''
                } */}
                <CardMedia
                    component="image"
                    className="h-128 w-full"
                    image="assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg"
                    alt="SP Pro"
                />
                <CardContent className={editing ? "pb-56" : "pb-36"}>
                    <div className={classNames(classes.progress, "relative cursor-pointer")} onClick={() => this.toggleCategory(value)}>
                        <Avatar className={classNames(classes.avatar, "absolute pin-t")}><Icon className="text-36" color="secondary" style={{ color: catNum === value.Category ? undefined : '#5f5f5f' }}>{catNum === value.Category ? "filter_list" : "apps"}</Icon></Avatar>
                        {/*
                            catNum === value.Category ?
                                <CircularProgress className={classNames("m-0", classes.roundProgress)} color="secondary" variant="determinate" value={100} />
                                : ''
                        */}
                    </div>
                    {!editing ?
                        <React.Fragment>
                            <Typography gutterBottom variant="h6" style={{ fontSize: 18 }} component="div" className="flex">
                                <div className="truncate">{value.Name}</div>{this.props.editable && ((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ? <Icon onClick={this.toggleEdit} className="ml-6 text-18 mt-4 on-hover cursor-pointer" style={{ marginBottom: 2 }}>edit</Icon> : ''}
                            </Typography>
                            <Typography variant="body2" onClick={() => this.setState({ open: !open })} className="w-full font-12 flex justify-between cursor-pointer" color="text.secondary">
                                <div className={!open ? "truncate" : ""}>{value.Description}</div>
                            </Typography>
                        </React.Fragment>
                        :
                        <div style={{ backgroundColor: '#fff' }} className="relative">
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: -12,
                                    top: -38,
                                }}
                                onClick={() => this.setState({ ...this.state, editing: false, })}
                            >
                                <Icon color="action">close</Icon>
                            </IconButton>

                            <TextField
                                className={classes.formControl}
                                label="Name"

                                id="name"
                                name="Name"
                                value={this.state.Name || ''}
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                required
                                fullWidth
                            />

                            <TextField
                                className={classes.formControl}
                                label="Description"
                                id="description"
                                name="Description"
                                value={this.state.Description || ''}
                                onChange={this.handleChange}
                                multiline
                                rows={3}
                                margin="dense"
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                    }
                </CardContent>
                <CardActions className="w-full pin-b pin-l pin-r absolute justify-between p-12">
                    {editing ?
                        <div className="w-full flex">
                            <Button
                                className={classNames(classes.saveButton, "w-full p-8 text-14")}
                                variant="contained"
                                color="primary"
                                disabled={!this.canBeSubmitted}
                                onClick={() => {
                                    this.setState({ ...this.state, editing: false, });
                                    if (!this.state.ID) {
                                        this.props.addCategory(this.state);
                                    } else {
                                        this.props.updateCategory(this.state);
                                    }
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                className={classNames(classes.deleteButton, "min-w-32 p-8 text-14")}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.setState({ ...this.state, editing: false, });
                                    if (!this.state.ID) {
                                        this.props.onDelete();
                                    } else {
                                        this.props.deleteCategory(this.state);
                                    }
                                }}
                            >
                                <Icon>delete</Icon>
                            </Button>
                        </div> : ''
                    }
                </CardActions>
            </Card>
            // <Card onClick={(e) => e.stopPropagation()} className="mt-8">
            //     <CardHeader
            //         classes={{ root: classes.card, title: classes.cardTitle, subheader: classes.cardSubheader, avatar: "mr-8", action: "pt-8 pr-4" }}
            //         avatar={<Avatar className={classes.avatar}><Icon>apps</Icon></Avatar>}
            //         action={this.state.ID ? <IconButton onClick={() => this.toggleCategory(value)}><Icon color={catNum === value.Category ? "secondary" : "action"}>filter_list</Icon></IconButton> : false}
            //         title={<React.Fragment>{value.Name}{this.props.editable && ((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ? <Icon onClick={this.toggleEdit} className="ml-4 text-14" style={{ paddingTop: 2 }}>edit</Icon> : ''}</React.Fragment>}
            //         subheader={`${count} ${count !== 1 ? "Classes" : "Class"}`}

            //     />
            //     <CardContent className="relative" style={{ minHeight: 128, padding: editing ? 0 : 16 }} classes={{ root: classes.cardContent }}>
            //         {!editing ?
            //             <div className="absolute pin-r pin-l pin-t pin-b m-auto w-full text-center" style={{ height: 56 }}>
            //                 <strong className={classes.dark} className="text-12 p-8">
            //                     {value.Description}
            //                 </strong>
            //                 <br />
            //                 <label className={classes.dark} className="text-10 p-8">
            //                     {value.AddedBy}
            //                 </label>
            //                 <br />
            //                 <label className={classes.dark} className="text-10 p-8">
            //                     {new Date(value.AddedDate).toLocaleDateString('en-US')}
            //                 </label>
            //             </div>
            //             :
            //             <div style={{ backgroundColor: '#fff' }} className="p-16">
            //                 <div className="flex">

            //                     <TextField
            //                         className={classes.formControl}
            //                         label="Name"

            //                         id="name"
            //                         name="Name"
            //                         value={this.state.Name}
            //                         onChange={this.handleChange}
            //                         inputProps={
            //                             {
            //                                 style: {
            //                                     padding: 12,
            //                                 }
            //                             }
            //                         }
            //                         variant="outlined"
            //                         required
            //                         fullWidth
            //                     />
            //                 </div>

            //                 <div className="flex">
            //                     <TextField
            //                         className={classes.formControl}
            //                         label="Description"
            //                         id="description"
            //                         name="Description"
            //                         value={this.state.Description}
            //                         inputProps={
            //                             {
            //                                 style: {
            //                                     padding: 12,
            //                                 }
            //                             }
            //                         }
            //                         onChange={this.handleChange}
            //                         variant="outlined"
            //                         required
            //                         fullWidth
            //                     />
            //                 </div>
            //                 <div className="w-full flex">
            //                     <Button
            //                         className={classNames(classes.saveButton, "w-full")}
            //                         variant="contained"
            //                         color="primary"
            //                         disabled={!this.canBeSubmitted}
            //                         onClick={() => {
            //                             this.setState({ ...this.state, editing: false, });
            //                             if (!this.state.ID) {
            //                                 this.props.addCategory(this.state);
            //                             } else {
            //                                 this.props.updateCategory(this.state);
            //                             }
            //                         }}
            //                     >
            //                         Save
            //                             </Button>
            //                     <Button
            //                         className={classNames(classes.deleteButton, "w-32")}
            //                         variant="contained"
            //                         color="primary"
            //                         onClick={() => {
            //                             this.setState({ ...this.state, editing: false, });
            //                             if (!this.state.ID) {
            //                                 this.props.onDelete();
            //                             } else {
            //                                 this.props.deleteCategory(this.state);
            //                             }
            //                         }}
            //                     >
            //                         <Icon>delete</Icon>
            //                     </Button>
            //                 </div>
            //             </div>
            //         }
            //     </CardContent>
            // </Card >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addCategory: Actions.addClassCategory,
        updateCategory: Actions.updateClassCategory,
        deleteCategory: Actions.removeClassCategory,
        setCategory: Actions.setCategory,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, academyApp }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        categories: spReducers.classes.categories,
        category: academyApp.academy.category,
        classList: spReducers.classes.classes,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademyClassCard)));
