import * as Actions from '../actions';

const initialState = null;

const chat = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CHAT:
            {
                return {
                    ...action.chat
                };
            }
        case Actions.REMOVE_CHAT:
            {
                return null;
            }
        case Actions.GET_CONVERSATIONS:
            {
                if (state && state.UserName) {
                    const payload = action.payload.find((_chat) => _chat.UserName === state.UserName);
                    return { ...payload }
                } else {
                    return null;
                }
            }
        case Actions.SET_CONVERSATION:
            {
                return {
                    ...action.payload,
                }
            }
        // case Actions.SEND_MESSAGE:
        //     {
        //         return {
        //             ...state,
        //             Messages: state.Messages ? [
        //                 ...state.Messages,
        //                 action.message
        //             ] : [action.message]
        //         };
        //     }
        default:
            {
                return state;
            }
    }
};

export default chat;