import { FuseUtils } from '@fuse';
import _ from '@lodash';
import { Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CustomerRateTemplatesList extends Component {

    state = {
        selectedCustomerRateTemplatesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCustomerRateTemplateMenu = (event) => {
        this.setState({ selectedCustomerRateTemplatesMenu: event.currentTarget });
    };

    closeSelectedCustomerRateTemplatesMenu = () => {
        this.setState({ selectedCustomerRateTemplatesMenu: null });
    };

    render() {
        const { classes, securables, businessUnits, divisions, rateTemplates, data } = this.props;
        const accessLevel = _.find(securables, { Securable: "cost-types" });

        return (
            <div>
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">ballot</Icon>Default Rate Templates</Typography>
                <ReactTableFixedColumns
                    className={classNames(classes.root, "-striped -highlight border-0")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className: "cursor-pointer",
                            onClick: (e, handleOriginal) => {
                                if (rowInfo) {
                                    this.props.onSelected({ ...rowInfo.original });
                                }
                            }
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header: "Business Unit",
                            id: "businessUnit",
                            accessor: r => _.find(businessUnits, { BusinessUnit: r.BusinessUnit }).Description,
                            className: "font-bold justify-center",
                            width: 256
                        },
                        {
                            Header: "Division",
                            id: "division",
                            accessor: r => _.find(divisions, { BusinessUnit: r.BusinessUnit, Division: r.Division }).Description,
                            className: "font-bold justify-center",
                            width: 256
                        },
                        {
                            Header: "Rate Template",
                            id: "rateTemplate",
                            accessor: r => _.find(rateTemplates, {BusinessUnit: r.BusinessUnit, Division: r.Division, RateTemplate: r.RateTemplate}).Description,
                            className: "justify-center",
                            width: 192
                        },

                    ]}
                    defaultPageSize={10}
                    noDataText="No Rate Templates found"
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ customersApp, spReducers }) {
    return {
        rateTemplates: spReducers.rateTemplates,
        user: customersApp.user,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerRateTemplatesList)));
