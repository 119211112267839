import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse';
import { Avatar, Button, Card, CardActionArea, CardMedia, CardActions, CardContent, Checkbox, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    img: {
        width: '100%'
    },
    card: {
        width: '100%'
    },
    media: {
        height: 140,
    },
});

const VideoListItem = ({ classes, video, onVideoSelect }) => {
    window["log"](video);
    const imageUrl = video.snippet.thumbnails.default.url;
    const videoId = video.id.videoId;
    const url = `https://www.youtube.com/embed/${videoId}`;
    return (
        <Card onClick={() => onVideoSelect(video)} className={classes.card}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={imageUrl}
                    title={video.snippet.title}
                >
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom component="h2">
                        {video.snippet.title}
                    </Typography>
                    <Typography component="p">
                        {video.snippet.channelTitle}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

VideoListItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoListItem);