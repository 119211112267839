import _ from '@lodash'
import {
  AppBar,
  Badge,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  Drawer,
  Fab,
  FormControlLabel,
  FormGroup,
  Grow,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  CardHeader,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import {
  AccountBalance,
  AccountCircle,
  Assignment,
  AttachFile,
  Build,
  BusinessCenter,
  Dashboard,
  History,
  List as ListIcon,
  LocationOn,
  Payment,
  Receipt,
} from '@material-ui/icons'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'
import ContactList from 'main/content/apps/call-handler/ContactList'
import CustomerSitesList from 'main/content/apps/customer-sites/CustomerSitesList'
import EstimatesList from 'main/content/apps/estimates/EstimatesList'
import FileList from 'main/content/apps/file-manager/FileList'
import InvoicesList from 'main/content/apps/invoices/InvoicesList'
import PaymentsList from 'main/content/apps/invoices/PaymentsList'
import PaymentMethodList from 'main/content/apps/invoices/PaymentMethodList'
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList'
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList'
import Comments from 'main/content/components/comments/Comments'
import { SPRoundIcon } from 'main/icons'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Geocode from 'react-geocode'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import { impress } from 'main/content/compression/impress'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import AgreementsList from '../agreements/AgreementsList'
import * as SiteActions from '../customer-sites/store/actions'
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector'
import * as FileActions from '../file-manager/store/actions'
import AttachmentDialog from '../file-manager/AttachmentDialog'
import * as WorkOrderActions from '../work-orders/store/actions'
import CustomerRateTemplatesList from './CustomerRateTemplatesList'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import ChangeDialog from '../../components/change-log/ChangeDialog'
import CustomerTaxability from './CustomerTaxability'
import * as Actions from './store/actions'
import * as WOActions from '../work-orders/store/actions'
import axios from 'axios'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import {
  openMinimizedDialog,
  updateAllMinimizedDialogs,
} from '../minimized-dialogs/store/actions/minimized-dialogs.actions'
import {
  addSplitscreenDialog,
  showSplitscreenDialog,
  setSplitscreenDialog,
  hideAllSplitscreenDialogs,
  captureSplitscreenDialogs,
} from '../splitscreen-dialogs/store/actions/splitscreen-dialogs.actions'
import { openPreloadCustomerDialog } from './store/actions/customers.actions'
import PhoneInput from '../../components/inputs/PhoneInput'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import DuplicateCustomerWarningDialog from './DuplicateCustomerWarningDialog'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Marker({ text, icon, style, badge, heading, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <div onClick={onClick}>
        <img
          onMouseDown={e => {
            e.stopPropagation()
          }}
          style={style}
          src={icon}
        />
      </div>
    </Tooltip>
  )
}

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  error: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  ok: {
    background: '#333',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
  },
  updateRateTemplate: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteRateTemplate: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})

const newCustomerState = {
  ID: '',
  Co: null,
  Customer: '',
  Name: '',
  FirstName: '',
  LastName: '',
  Contact: '',
  Phone: '',
  Email: '',
  Address1: '',
  Address2: '',
  City: 'Albuquerque',
  State: 'NM',
  Zip: '',
  Country: 'US',
  Fax: '',
  BillNotes: '',
  BillContact: '',
  BillPhone: '',
  BillEmail: '',
  BillAddress1: '',
  BillAddress2: '',
  BillCity: 'Albuquerque',
  BillState: 'NM',
  BillZip: '',
  BillCountry: 'US',
  BillFax: '',
  TaxCode: null,
  PayTerms: null,
  VPCustGroup: null,
  VPCustomer: null,
  QBOCompany: null,
  QBOCustomer: null,
  PORequiredYN: null,
  InvoiceReviewYN: null,
  MailInvoiceYN: null,
  PaymentsCustomer: null,
  RefuseServiceYN: null,
  RefusalReason: null,
  RefusalDate: null,
  RefusalBy: null,
  BillSame: null,
  ApprovedAccountYN: null,
  Data: {
    Sites: [],
    Tags: [],
    Comments: [],
    Contacts: [],
    Attachments: [],
    Redirect: null,
    Redirects: [],
    RateTemplates: [],
    Taxability: [],
    VPARCM: null,
  },
  ActiveYN: 'Y',
  Notes: '',
  Status: 'A',
  value: 0,
  histTab: 0,
  Address: false,
  rateTemplate: {
    ID: null,
    Co: null,
    Customer: null,
    BusinessUnit: null,
    Division: null,
    RateTemplate: null,
    Data: {
      ErrMsg: null,
    },
  },
  showEmailLogs: false,
  isSearching: false,
  searchDescription: null,
  vpCustomers: [],
  qboCustomers: [],
  tax: null,
  viewChanges: false,
  showConfirmDuplicateCustomerCreationDialog: false,
  duplicatedEntitiesList: [],
  showDeleteConfirmation: false,
}

export const getBillingInformation = Contacts => {
  const BillPhone = _.filter(Contacts, o => o.BillingYN === 'Y')[0]
    ? _.filter(Contacts, o => o.BillingYN === 'Y')[0].Data.Contact.Phone
    : _.filter(Contacts, o => o.PrimaryYN === 'Y')[0].Data.Contact.Phone
  const BillEmail = _.filter(Contacts, o => o.BillingYN === 'Y')[0]
    ? _.filter(Contacts, o => o.BillingYN === 'Y')[0].Data.Contact.Email
    : _.filter(Contacts, o => o.PrimaryYN === 'Y')[0].Data.Contact.Email

  return { billPhone: BillPhone, billEmail: BillEmail }
}

class CustomersDialog extends Component {
  state = { ..._.cloneDeepWith(newCustomerState) }

  ref = React.createRef()

  componentDidMount() {
    const { data, props } = this.props.customerDialog
    const { open } = props

    if (data && open) {
      data.Address = this.buildAddress(
        data.Address1,
        data.City,
        data.State,
        data.Zip,
      )
      if ((!data.Latitude || !data.Longitude) && window.google) {
        Geocode.fromAddress(
          `${data.Address1} ${data.City}, ${data.State} ${data.Zip}`,
        ).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location
            this.setState({
              ...newCustomerState,
              ...data,
              Latitude: lat,
              Longitude: lng,
            })
          },
          error => {
            this.setState({ ...newCustomerState, ...data })
          },
        )
      } else {
        this.setState({ ...newCustomerState, ...data })
      }
      this.props.updateEditCustomerDialog(data)
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { vpCompany } = this.props

    /**
     * After Dialog Open
     */

    newCustomerState.Co = this.props.Co
    const initialState = _.cloneDeepWith(newCustomerState)
    if (
      !prevProps.customerDialog.props.open &&
      this.props.customerDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.customerDialog.type === 'edit' &&
        this.props.customerDialog.data &&
        !_.isEqual(
          this.props.customerDialog.data,
          prevProps.customerDialog.Data,
        )
      ) {
        const data = this.props.customerDialog.data
        data.Address = this.buildAddress(
          data.Address1,
          data.City,
          data.State,
          data.Zip,
        )
        if ((!data.Latitude || !data.Longitude) && window.google) {
          Geocode.fromAddress(
            `${data.Address1} ${data.City}, ${data.State} ${data.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState(
                { ...initialState, ...data, Latitude: lat, Longitude: lng },
                () => {
                  if (vpCompany && this.state.VPCustomer) {
                    this.handleCustomerSearch()
                  }
                  this.createPaymentsCustomer()
                },
              )
            },
            error => {
              this.setState({ ...initialState, ...data }, () => {
                if (vpCompany && this.state.VPCustomer) {
                  this.handleCustomerSearch()
                }
                this.createPaymentsCustomer()
              })
            },
          )
        } else {
          this.setState({ ...initialState, ...data }, () => {
            if (vpCompany && this.state.VPCustomer) {
              this.handleCustomerSearch()
            }
            this.createPaymentsCustomer()
          })
        }
      }
      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.customerDialog.type === 'new' &&
        !_.isEqual(initialState, prevState)
      ) {
        this.setState({ ...initialState })
      }
    }
    if (
      this.props.customerDialog.type === 'data' &&
      this.props.customerDialog.data &&
      !_.isEqual(this.props.customerDialog.data, prevState)
    ) {
      const data = this.props.customerDialog.data
      this.props.customerDialog.data.Address = this.buildAddress(
        data.Address1,
        data.City,
        data.State,
        data.Zip,
      )
      this.props.customerDialog.type = 'edit'
      this.setState({ ...this.props.customerDialog.data })
    }
    if (
      this.props.customerDialog.type === 'add' &&
      this.props.customerDialog.data &&
      !_.isEqual(this.props.customerDialog.data, prevState)
    ) {
      const data = this.props.customerDialog.data
      this.props.customerDialog.data.Address = this.buildAddress(
        data.Address1,
        data.City,
        data.State,
        data.Zip,
      )
      this.props.customerDialog.type = 'edit'
      this.setState({ ...this.props.customerDialog.data })
    }
    if (
      this.props.customerDialog.type === 'update' &&
      this.props.customerDialog.data &&
      !_.isEqual(this.props.customerDialog.data, prevState)
    ) {
      window['warn']('UPDATE!!!')
      const data = this.props.customerDialog.data
      this.props.customerDialog.data.Address = this.buildAddress(
        data.Address1,
        data.City,
        data.State,
        data.Zip,
      )
      this.props.customerDialog.type = 'edit'
      this.setState({ ...this.props.customerDialog.data })
    }
  }

  initGoogleAutocomplete = inputElement => {
    var options = { types: ['address'] }
    this.autocomplete = new window.google.maps.places.Autocomplete(
      inputElement,
      options,
    )
    this.autocomplete.setFields([
      'name',
      'formatted_address',
      'address_components',
      'geometry',
    ])
    this.autocomplete.addListener('place_changed', () =>
      this.handleAddressSelect(inputElement),
    )
  }

  handleAddressSelect = inputElement => {
    const componentForm = {
      street_number: 'short_name',
      route: 'short_name',
      locality: 'short_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
    }

    const place = this.autocomplete.getPlace()
    if (place && place.address_components) {
      const address = place.address_components
      const location = place.geometry ? place.geometry.location : ''
      let addressObject = {}
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]]
          addressObject[addressType] = val
        }
      }

      if (address) {
        const address1 = addressObject.street_number
          ? `${addressObject.street_number} ${addressObject.route}`
          : addressObject.route
        this.setState(
          {
            Address1: address1
              ? address1.normalize('NFD').replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            City: addressObject.locality
              ? addressObject.locality
                  .normalize('NFD')
                  .replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            State: addressObject.administrative_area_level_1,
            Country: addressObject.country,
            Zip: addressObject.postal_code,
            Latitude: location && location.lat ? location.lat() : null,
            Longitude: location && location.lng ? location.lng() : null,
          },
          this.getAddress,
          window['warn']('CUSTOMER TAB', place, addressObject),
        )
      }
    } else {
      inputElement.focus()
    }
  }

  getAddress = () => {
    const oldAddress = this.state.Address
    const { Address1, Zip } = this.state
    const Address =
      Address1 && Zip && Address1.length > 3 && Zip.length >= 3
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
    if (!_.isEqual(oldAddress, Address)) {
      this.setState({ ...this.state, Address })
    }
  }
  // getAddress = () => {
  //   const { Address1, City, State, Zip } = this.state
  //   if (window.google) {
  //     Geocode.fromAddress(`${Address1} ${City}, ${State} ${Zip}`).then(
  //       response => {
  //         const { lat, lng } = response.results[0].geometry.location
  //         const Address =
  //           Address1.length > 3 && City.length > 2 && State.length > 1
  //             ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
  //             : false
  //         this.setState({
  //           ...this.state,
  //           Address,
  //           Latitude: lat,
  //           Longitude: lng,
  //         })
  //       },
  //       error => {
  //         const Address =
  //           Address1.length > 3 && City.length > 2 && State.length > 1
  //             ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
  //             : false
  //         this.setState({ ...this.state, Address })
  //       },
  //     )
  //     window['log'](this.state)
  //   } else {
  //     const Address =
  //       Address1.length > 3 && City.length > 2 && State.length > 1
  //         ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
  //         : false
  //     this.setState({ ...this.state, Address })
  //   }
  // }

  handleBlur = event => {
    if (event.target.populate) {
      this.setState(
        _.set(
          { ...this.state },
          event.target.populate,
          event.target.type === 'checkbox'
            ? event.target.checked
              ? 'Y'
              : 'N'
            : event.target.value,
        ),
      )
    }
  }

  handleTabChange = (event, value) => {
    window['warn'](value)

    this.setState({ value })
  }

  closeComposeDialog = () => {
    this.props.customerDialog.type === 'edit'
      ? this.props.closeEditCustomerDialog()
      : this.props.closeNewCustomerDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.customerDialog.type === 'edit'
      ? this.props.closeEditCustomerDialog()
      : this.props.closeNewCustomerDialog()
  }

  canBeSubmitted() {
    const { Name, FirstName, LastName, Address1, City, State, Zip, Data } =
      this.state

    return (
      ((FirstName && LastName) || Name) &&
      Address1 &&
      City &&
      State &&
      Zip &&
      Data &&
      Data.Contacts &&
      Data.Contacts.length > 0 &&
      Address1.length > 0 &&
      City.length > 0 &&
      State.length > 0 &&
      Zip.length > 0
    )
  }
  billingCanBeSubmitted() {
    const { BillAddress1, BillZip } = this.state
    return BillAddress1 && BillZip
  }
  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  hasAddress() {
    const { Address } = this.state
    return Address
  }

  createPaymentsCustomer = () => {
    const {
      Co,
      Customer,
      FirstName,
      LastName,
      Name,
      BillPhone,
      BillEmail,
      BillAddress1,
      BillAddress2,
      BillCity,
      BillState,
      BillCountry,
      BillZip,
      PaymentsCustomer,
    } = this.state
    if (!PaymentsCustomer) {
      const customer = {
        Co,
        Customer,
        firstname: FirstName || '',
        lastname: LastName || '',
        company: Name || '',
        phone: BillPhone.replace(/[^a-zA-Z0-9]/g, ''),
        email: BillEmail || '',
        address_1: BillAddress1,
        address_2: BillAddress2 || '',
        address_city: BillCity,
        address_state: BillState,
        address_zip: BillZip,
        reference: Customer.toString(),
      }
      const request = axios.post(
        `${window['apiLocation']}/api/Omni/CreateCustomer`,
        customer,
      )
      request.then(response => {
        window['warn']('Payments Customer: ', response.data)
        if (response.data.id) {
          this.setState({ ...this.state, PaymentsCustomer: response.data.id })
        }
      })
    }
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  handleAddTag = tag => {
    let { Co } = this.state
    if (this.state.Data.Tags) {
      let { Tags } = this.state.Data
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
    } else {
      let Tags = []
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
    }
  }

  handleDeleteTag = tag => {
    let { Tags } = this.state.Data
    if (Tags == null) {
      Tags = []
    }
    for (var i = 0; i < Tags.length; i++) {
      if (Tags[i].ID == tag.ID) {
        if (isNaN(Tags[i].ID)) {
          Tags.splice(i, 1)
        } else {
          Tags[i].ActiveYN = 'N'
          Tags[i].Status = 'Update'
        }
      }
    }
    this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  closeMenu = () => {
    this.setState({ ...this.state, anchorEl: null })
  }

  handleCustomerSearch = _.debounce(() => {
    const { vpCompany } = this.props
    const { CustGroup } = vpCompany || {}
    const { searchDescription, VPCustomer } = this.state
    const data = this.props.customerDialog.data
    if (VPCustomer || (searchDescription && searchDescription.length > 3)) {
      const inputValue = (searchDescription || VPCustomer.toString())
        .trim()
        .toLowerCase()
      this.setState({ isSearching: true }, () => {
        axios
          .get(
            `${
              window['apiLocation']
            }/api/VPSettings/Customers?Group=${CustGroup}&search=${encodeURIComponent(
              inputValue,
            )}`,
          )
          .then(response => {
            this.setState({ vpCustomers: response.data, isSearching: false })
          })
          .catch(() => this.setState({ isSearching: false, vpCustomers: [] }))
      })
    } else {
      this.setState({ ...newCustomerState, ...data })
    }
    this.props.updateEditCustomerDialog(data)
  })

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
      this.setBillingInfo,
    )
  }

  setBillingInfo = () => {
    const {
      BillSame,
      Address1,
      Address2,
      City,
      State,
      Zip,
      Country,
      Phone,
      Email,
    } = this.state
    if (BillSame === 'Y') {
      this.setState({
        BillAddress1: Address1,
        BillAddress2: Address2,
        BillCity: City,
        BillState: State,
        BillZip: Zip,
        BillCountry: Country,
        BillPhone: Phone,
        BillEmail: Email,
      })
    }
  }

  handleQBOCustomerSearch = _.debounce(() => {
    const { qboCompany } = this.props
    const { searchDescription, QBOCustomer } = this.state
    if (QBOCustomer || (searchDescription && searchDescription.length > 3)) {
      const inputValue = (searchDescription || QBOCustomer.toString())
        .trim()
        .toLowerCase()
      this.setState({ isSearching: true }, () => {
        axios
          .get(
            `${window['apiLocation']}/api/QBOCustomer?Term=${encodeURIComponent(
              inputValue,
            )}`,
          )
          .then(response => {
            this.setState({ qboCustomers: response.data, isSearching: false })
          })
          .catch(() => this.setState({ isSearching: false, qboCustomers: [] }))
      })
    } else {
      this.setState({ qboCustomers: [] })
    }
  }, 750)

  addNonTax = CostTypeCategory => {
    const { Co, Customer } = this.state
    const { Taxability } = this.state.Data
    if (!Taxability) {
      Taxability = []
    }
    Taxability.push({
      Co,
      Customer,
      CostTypeCategory,
      TaxableYN: 'N',
    })
    this.setState({ ...this.state, Data: { ...this.state.Data, Taxability } })
  }

  removeNonTax = CostTypeCategory => {
    const { Taxability } = this.state.Data
    const tax = _.find(Taxability, { CostTypeCategory })
    if (tax) {
      tax.DeleteYN = 'Y'
    }
    this.setState({ ...this.state, Data: { ...this.state.Data, Taxability } })
  }

  attachmentSelectedHandler = event => {
    let { ID, Co, Data } = this.state
    const file = event.target.files[0]
    if (file.type.split('/')[0] === 'image') {
      this.fileSelectedHandler(event)
      // impress(file, 1024, 50, false, (img) => {
      //     window["log"](img);
      //     const nameArr = img.fileName.split('.');
      //     const { Attachments } = this.state.Data;
      //     const newFileName = `Co-${Co}-Unit-${ID}-Img-${Attachments.length + 1}.${nameArr[nameArr.length - 1]}`;
      //     const fd = new FormData();
      //     fd.append('image', img.file, newFileName);
      //     axios.post(`${window["apiLocation"]}/api/Attachment/AddAttachment?Co=${Co}&ID=${ID}&type=WO`, fd).then((res) => {
      //         window["warn"](res);
      //         if (res.data.Attachment) {
      //             const { Attachments } = this.state.Data;
      //             Attachments.push(res.data.Attachment);
      //             this.setState({ ...this.state, Data: { ...Data, Attachments: [...Attachments] } });
      //         }
      //     });
      // });
    } else {
      const fd = new FormData()
      fd.append('file', file, file.name)
      axios
        .post(
          `${
            window['apiLocation']
          }/api/attachment/AddAttachment?Co=${Co}&ID=${ID}&type=${encodeURIComponent(
            'Customer',
          )}`,
          fd,
        )
        .then(res => {
          // window["warn"](res);
          if (res.data.Attachment) {
            const { Attachments } = this.state.Data
            Attachments.push(res.data.Attachment)
            this.setState({
              ...this.state,
              Data: { ...Data, Attachments: [...Attachments] },
            })
          }
        })
    }
  }

  fileSelectedHandler = event => {
    let { ID, Data, Co, Customer } = this.state
    const file = event.target.files[0]
    var reader = new FileReader()
    reader.onload = e => {
      const nameArr = file.name.split('.')
      const fname = `Customer${Co}-${Customer}Img${Data.Attachments.length + 1}`
      this.setState({
        newPhoto: {
          data: e.target.result,
          fileName: fname,
          type: 'image/jpeg',
        },
      })
      if (this.fileInput) {
        this.fileInput.value = ''
      }
    }
    reader.readAsDataURL(file)
  }

  uploadImg = (file, notes) => {
    impress(file, 1024, 50, false, img => {
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      if (notes) {
        fd.append('notes', notes)
      }
      // saveAs(img.file, img.fileName);

      axios
        .post(
          `${
            window['apiLocation']
          }/api/attachment/AddAttachment?Co=${encodeURIComponent(
            this.state.Co,
          )}&ID=${encodeURIComponent(this.state.ID)}&type=${encodeURIComponent(
            'Customer',
          )}`,
          fd,
        )
        .then(res => {
          //axios.post(`${window["apiLocation"]}/api/Attachment/PostWorkOrderImage?Co=${encodeURIComponent(this.state.Co)}&WorkOrder=${encodeURIComponent(this.state.WorkOrder)}&User=${encodeURIComponent(this.props.user.UserName)}`, fd).then((res) => {
          // window["warn"](res);
          if (res.data.Attachment) {
            const { Attachments } = this.state.Data
            Attachments.push(res.data.Attachment)
            this.setState({
              ...this.state,
              Data: { ...this.state.Data, Attachments: [...Attachments] },
              newPhoto: null,
            })
          }
        })
    })
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { securables, customerDialog } = this.props

    const refusalOfService = _.find(securables, {
      Securable: 'customer-refusal',
    })

    let refusal =
      refusalOfService && customerDialog.data
        ? !this.state.RefuseServiceYN || this.state.RefuseServiceYN === 'N'
          ? refusalOfService.AccessLevel === 'E' ||
            refusalOfService.AccessLevel === 'F'
            ? true
            : false
          : refusalOfService.AccessLevel === 'E' &&
              this.props.User.UserName === customerDialog.data.RefusalBy
            ? true
            : refusalOfService.AccessLevel === 'F'
              ? true
              : refusalOfService.AccessLevel === 'R'
                ? false
                : false
        : false

    return (
      <>
        {this.state.anchorEl && (
          <Menu
            id='customer-menu'
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.closeMenu}
          >
            <MenuItem
              onClick={() => {
                this.props.updateEditCustomerDialog(this.state)
                this.closeMenu()
              }}
            >
              <Icon className='mr-8'>refresh</Icon>Refresh
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (this.state.Data.Redirect) {
                  this.props.openEditCustomerDNUDialog(this.state.Data.Redirect)
                } else {
                  this.props.openNewCustomerDNUDialog(this.state)
                }
                this.closeMenu()
              }}
            >
              <Icon className='mr-8'>directions</Icon>
              {this.state.Data.Redirect ? 'Un-' : ''}Merge Customer
            </MenuItem>
            {refusal && (
              <MenuItem
                onClick={() =>
                  this.setState({ promptRefusal: true }, this.closeMenu)
                }
              >
                <Icon className='mr-8'>explore_off</Icon>Refuse Service
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                this.setState({ anchorEl: null, viewChanges: true })
              }}
            >
              <Icon className='mr-8'>history</Icon>View Changes
            </MenuItem>
          </Menu>
        )}
        <ChangeDialog
          type='CUSTOMER'
          rec={this.state.ID}
          restore={data => this.setState({ ...data, viewChanges: false })}
          accessLevel={this.state.accessLevel}
          data={this.state}
          open={this.state.viewChanges}
          onClose={() => this.setState({ viewChanges: false })}
        />
        <IconButton
          style={{
            position: 'absolute',
            right: 112,
            //top: !matches ? 8 : 4,
            top: 8,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 64,
            top: matches ? 10 : 4,
            color: 'white',
          }}
          onClick={e => {
            this.setState({ ...this.state, anchorEl: e.target })
          }}
          className='dialog-header-icon'
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 10 : 4,
            color: 'white',
          }}
          onClick={e => {
            //this.closeComposeDialog();
            closeFxn(e)
          }}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
        <Tabs
          value={this.state.value}
          onChange={this.handleTabChange}
          variant='scrollable'
          scrollButtons='on'
        >
          <Tab disableRipple icon={<AccountCircle />} label='Info' />
          <Tab disableRipple icon={<AccountBalance />} label='Billing Info' />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Sites
                    ? this.state.Data.Sites.length
                    : 0
                }
                color='error'
              >
                <LocationOn />
              </Badge>
            }
            label='Service Sites'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.WorkOrders +
                  this.state.Scopes +
                  this.state.Invoices +
                  this.state.Payments +
                  this.state.Estimates
                }
                max={999}
                color='error'
              >
                <History />
              </Badge>
            }
            label='History'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.PaymentMethods
                    ? this.state.Data.PaymentMethods.length
                    : 0
                }
                color='error'
              >
                <Icon>payment</Icon>
              </Badge>
            }
            label='Payment Methods'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Agreements
                    ? this.state.Data.Agreements.length
                    : 0
                }
                color='error'
              >
                <BusinessCenter />
              </Badge>
            }
            label='Agreements'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.RateTemplates
                    ? this.state.Data.RateTemplates.length
                    : 0
                }
                color='error'
              >
                <Icon>ballot</Icon>
              </Badge>
            }
            label='Rate Templates'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Attachments
                    ? this.state.Data.Attachments.length
                    : 0
                }
                color='error'
              >
                <AttachFile />
              </Badge>
            }
            label='Attachments'
          />
          <Tab disableRipple icon={<Dashboard />} label='Dashboard' />
        </Tabs>
      </>
    )
  }

  ToolbarContents = () => {
    return (
      <Typography variant='subtitle1' color='inherit'>
        <SPRoundIcon className='cursor-pointer text-32 align-middle mr-8' />
        {this.props.customerDialog.type === 'new'
          ? 'New Customer'
          : `Customer #${this.state.Customer} Overview`}
      </Typography>
    )
  }

  render() {
    const {
      classes,
      securables,
      theme,
      customerDialog,
      addCustomer,
      updateCustomer,
      taxCodes,
      openNewCustomerSiteDialog,
      openNewWorkOrderDialog,
      googleMapsApiKey,
      payTerms,
      qboCompany,
      vpCompany,
      businessUnits,
      rateTemplates,
      divisions,
      splitscreenDialogs,
      setSplitscreenDialog,
      deleteCustomer,
      isAllowedToDelete,
    } = this.props
    const {
      Co,
      Customer,
      Name,
      FirstName,
      LastName,
      Address1,
      Address2,
      City,
      State,
      Zip,
      Country,
      Email,
      Contact,
      Phone,
      value,
      newPhoto,
      histTab,
      Address,
      Latitude,
      Longitude,
      anchorEl,
      isSearching,
      searchDescription,
      vpCustomers,
      qboCustomers,
      disableBackdropClick,
      screenOverlay,
      position,
    } = this.state
    const histTabs = ['Work Order', 'Invoice', 'Payment', 'Estimate']
    const histIcons = [
      <Assignment className='text-24 ml-12 mr-12' />,
      <Receipt className='text-24 ml-12 mr-12' />,
      <Payment className='text-24 ml-12 mr-12' />,
      <ListIcon className='text-24 ml-12 mr-12' />,
    ]
    const accessLevel = _.find(securables, { Securable: 'customer-dialog' })

    let paid = this.state.PaymentsTotal
    let invoiced = this.state.InvoiceTotal
    let due = invoiced - paid

    if (
      this.state.Data.VPARCM &&
      this.state.Data.VPARCM.Data &&
      this.state.Data.VPARCM.Data.VPARMT
    ) {
      due =
        this.state.Data &&
        this.state.Data.VPARCM &&
        this.state.Data.VPARCM.Data &&
        this.state.Data.VPARCM.Data.VPARMTCustAmtDue
          ? this.state.Data.VPARCM.Data.VPARMTCustAmtDue.CustAmtDue
          : 0
      paid =
        this.state.Data &&
        this.state.Data.VPARCM &&
        this.state.Data.VPARCM.Data &&
        this.state.Data.VPARCM.Data.VPARMT
          ? _.sumBy(this.state.Data.VPARCM.Data.VPARMT, o => {
              return o.Paid
            })
          : 0
      invoiced =
        this.state.Data &&
        this.state.Data.VPARCM &&
        this.state.Data.VPARCM.Data &&
        this.state.Data.VPARCM.Data.VPARMT
          ? _.sumBy(this.state.Data.VPARCM.Data.VPARMT, o => {
              return o.Invoiced
            })
          : 0
    }

    const refusalOfService = _.find(securables, {
      Securable: 'customer-refusal',
    })

    let refusal =
      refusalOfService && customerDialog.data
        ? !this.state.RefuseServiceYN || this.state.RefuseServiceYN === 'N'
          ? refusalOfService.AccessLevel === 'E' ||
            refusalOfService.AccessLevel === 'F'
            ? true
            : false
          : refusalOfService.AccessLevel === 'E' &&
              this.props.User.UserName === customerDialog.data.RefusalBy
            ? true
            : refusalOfService.AccessLevel === 'F'
              ? true
              : refusalOfService.AccessLevel === 'R'
                ? false
                : false
        : false

    const dialogProps = {
      ...customerDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      // hideBackdrop: true,
      disableEnforceFocus: true,
    }

    // window['warn'](
    //   'customer dialog billing eee',
    //   this.state.Data.Contacts,
    //   this.state,
    // )

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <DraggableDialog
            SlideUp={SlideUp}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this,
            )}
            header={`Customer #${this.state.Customer}: ${this.state.FirstName} ${this.state.LastName}`}
            type='Customer'
            picRef={this.ref.current}
            icon='account_box'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadCustomerDialog}
            description={`${FirstName} ${LastName}`}
            storeDialog={customerDialog}
            TypeState={this.state.Customer}
            screenOverlay={this.state.screenOverlay}
          >
            <EmailLogDialog
              title={`Customer #${this.state.Customer} Email Log`}
              type='CUSTOMER'
              rec={this.state.ID}
              open={this.state.showEmailLogs}
              onClose={() => this.setState({ showEmailLogs: false })}
            />

            <ConfirmationDialog
              open={this.state.showDeleteConfirmation}
              title={
                <div>
                  <Icon
                    className='mr-6 align-middle mb-4 text-orange'
                    color='error'
                  >
                    warning
                  </Icon>
                  Delete Confirmation
                </div>
              }
              content={
                <div className='w-full'>
                  Are you sure you want to delete this customer?
                </div>
              }
              confirmText={'Delete'}
              cancelText='Cancel'
              onCancel={() =>
                this.setState({ ...this.state, showDeleteConfirmation: false })
              }
              onConfirm={async () => {
                const response = await deleteCustomer({ ...this.state })

                if (response == '') {
                  this.setState({
                    ...this.state,
                    showDeleteConfirmation: false,
                  })
                }
              }}
            />
            {newPhoto && (
              <AttachmentDialog
                file={newPhoto}
                open={true}
                edit={true}
                onClose={() => this.setState({ newPhoto: null })}
                onSave={this.uploadImg}
              />
            )}
            {this.state.promptRefusal && this.state.RefuseServiceYN !== 'Y' && (
              <ConfirmationDialog
                open={this.state.promptRefusal}
                title={
                  <div>
                    <Icon className='mr-6 align-middle mb-4' color='error'>
                      explore_off
                    </Icon>
                    Do Not Service
                  </div>
                }
                content={
                  <div className='w-full'>
                    Please enter a reason for refusing service to this customer:
                    <br />
                    <TextField
                      className={'mt-12'}
                      label='Reason for Refusal'
                      id='refusalReason'
                      name='RefusalReason'
                      value={this.state.RefusalReason}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </div>
                }
                disableConfirm={
                  !this.state.RefusalReason ||
                  this.state.RefusalReason.length < 6
                }
                confirmText={'Save'}
                cancelText='Cancel'
                onCancel={() =>
                  this.setState({ ...this.state, promptRefusal: false })
                }
                onConfirm={() => {
                  this.setState({ ...this.state, promptRefusal: false }, () =>
                    updateCustomer({ ...this.state, RefuseServiceYN: 'Y' }),
                  )
                }}
              />
            )}
            {this.state.promptRefusal && this.state.RefuseServiceYN === 'Y' && (
              <ConfirmationDialog
                open={this.state.promptRefusal}
                title={
                  <div>
                    <Icon className='mr-6 align-middle mb-4' color='error'>
                      explore_off
                    </Icon>
                    Do Not Service
                  </div>
                }
                content={
                  <div className='w-full'>
                    <div className='italic mt-12'>
                      "{this.state.RefusalReason}"
                    </div>
                    <br />
                    {this.state.RefusalBy && this.props.users && (
                      <div className='w-full text-right font-bold'>
                        {[
                          _.find(this.props.users, {
                            UserName: this.state.RefusalBy,
                          }),
                        ].map(u => ` - ${u.FirstName} ${u.LastName}`)}
                      </div>
                    )}
                  </div>
                }
                confirmText={'Allow Service'}
                cancelText='Cancel'
                onCancel={() =>
                  this.setState({ ...this.state, promptRefusal: false })
                }
                onConfirm={() => {
                  this.setState({ ...this.state, promptRefusal: false }, () =>
                    updateCustomer({ ...this.state, RefuseServiceYN: 'N' }),
                  )
                }}
              />
            )}
            {this.state.showConfirmDuplicateCustomerCreationDialog && (
              <DuplicateCustomerWarningDialog
                open={this.state.showConfirmDuplicateCustomerCreationDialog}
                isCreatingCustomer={this.props.customerDialog.type === 'new'}
                duplicatedEntitiesList={this.state.duplicatedEntitiesList}
                onConfirm={async () => {
                  this.setState(
                    {
                      ...this.state,
                      Data: {
                        ...this.state.Data,
                        IgnoreDuplicatedEntitiesCheck: true,
                      },
                      showConfirmDuplicateCustomerCreationDialog: false,
                    },
                    async () => await upsertCustomer(this),
                  )
                }}
                onCancel={() =>
                  this.setState({
                    ...this.state,
                    showConfirmDuplicateCustomerCreationDialog: false,
                  })
                }
                onViewExistingCustomer={() => {
                  this.closeComposeDialog()
                  this.props.openEditCustomerDialog(
                    this.state.duplicatedEntitiesList[0],
                  )
                }}
              />
            )}
            {this.state.showConfirmDuplicateCustomerCreationDialog && (
              <DuplicateCustomerWarningDialog
                open={this.state.showConfirmDuplicateCustomerCreationDialog}
                isCreatingCustomer={this.props.customerDialog.type === 'new'}
                duplicatedEntitiesList={this.state.duplicatedEntitiesList}
                onConfirm={async () => {
                  this.setState(
                    {
                      ...this.state,
                      Data: {
                        ...this.state.Data,
                        IgnoreDuplicatedEntitiesCheck: true,
                      },
                      showConfirmDuplicateCustomerCreationDialog: false,
                    },
                    async () => await upsertCustomer(this),
                  )
                }}
                onCancel={() =>
                  this.setState({
                    ...this.state,
                    showConfirmDuplicateCustomerCreationDialog: false,
                  })
                }
              />
            )}
            {value === 0 && (
              <TabContainer refProp={this.ref}>
                {this.state.Data.Redirect && (
                  <div
                    onClick={() =>
                      this.props.openEditCustomerDNUDialog(
                        this.state.Data.Redirect,
                      )
                    }
                    className='p-8 rounded mb-24 w-full cursor-pointer'
                    style={{ backgroundColor: '#333' }}
                  >
                    <Typography className='text-16 font-bold text-white w-full text-center'>
                      <Icon color='secondary' className='mr-6 align-middle'>
                        directions
                      </Icon>
                      {`Redirected from Customer #${this.state.Data.Redirect.Customer}`}
                    </Typography>
                  </div>
                )}
                {this.state.RefuseServiceYN === 'Y' && (
                  <div
                    onClick={() =>
                      refusal ? this.setState({ promptRefusal: true }) : null
                    }
                    className={
                      refusal
                        ? 'p-8 rounded mb-24 py-24 w-full cursor-pointer'
                        : 'p-8 rounded mb-24 py-24 w-full'
                    }
                    style={{
                      backgroundColor: '#f9f9f9',
                      border: '1px dashed red',
                    }}
                  >
                    <Typography className='text-16 font-bold text-red w-full text-center'>
                      <Icon color='error' className='mr-6 align-middle'>
                        explore_off
                      </Icon>
                      DO NOT SERVICE
                    </Typography>
                    <div className='w-full'>
                      <div className='italic mt-4 text-center'>
                        "{this.state.RefusalReason}"
                      </div>
                    </div>
                  </div>
                )}
                {this.state.ApprovedAccountYN === 'Y' && (
                  <div
                    className='p-8 rounded mb-24 w-full cursor-pointer'
                    style={{ backgroundColor: '#333' }}
                  >
                    <Typography className='text-16 font-bold text-white w-full text-center'>
                      <Icon color='secondary' className='mr-6 align-middle'>
                        security
                      </Icon>
                      Approved Account
                    </Typography>
                  </div>
                )}
                {Boolean(invoiced || paid || due) && (
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                      <Icon color='action'>account_balance</Icon>
                    </div>
                    <Button
                      onClick={() =>
                        this.setState({ ...this.state, value: 3, histTab: 2 })
                      }
                      variant='contained'
                      className='w-full text-12 p-12 mb-24'
                      color='primary'
                    >
                      <Icon className='mr-6'>receipt</Icon>Invoiced:{' '}
                      {this.formatDollars(invoiced)}
                    </Button>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Button
                      onClick={() =>
                        this.setState({ ...this.state, value: 3, histTab: 3 })
                      }
                      variant='contained'
                      className='w-full text-12 p-12 mb-24'
                      color='secondary'
                    >
                      <Icon className='mr-6'>payment</Icon>Paid:{' '}
                      {this.formatDollars(paid)}
                    </Button>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Button
                      onClick={() =>
                        this.setState({ ...this.state, value: 3, histTab: 2 })
                      }
                      variant='contained'
                      color='primary'
                      className={classNames(
                        due && due > 0 ? classes.error : classes.ok,
                        'w-full text-12 p-12 mb-24',
                      )}
                    >
                      <Icon className='mr-6'>today</Icon>Due:{' '}
                      {this.formatDollars(due)}
                    </Button>
                  </div>
                )}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>account_circle</Icon>
                  </div>

                  <TextField
                    className={classes.formControl}
                    label='First Name'
                    id='firstName'
                    name='FirstName'
                    value={this.state.FirstName}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Last Name'
                    id='lastName'
                    name='LastName'
                    value={this.state.LastName}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Company'
                    id='company'
                    name='Name'
                    value={this.state.Name}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>home</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Address'
                    id='address1'
                    inputRef={this.initGoogleAutocomplete}
                    name='Address1'
                    value={this.state.Address1 || ''}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label="Address (cont'd)"
                    id='address2'
                    name='Address2'
                    value={this.state.Address2}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='City'
                    id='city'
                    name='City'
                    value={this.state.City}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='State'
                    id='state'
                    name='State'
                    value={this.state.State}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Zip Code'
                    id='zip'
                    name='Zip'
                    value={this.state.Zip}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  {vpCompany && (
                    <React.Fragment>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <div className='w-full flex relative'>
                        <Autocomplete
                          className={classes.formControl}
                          title='VP Customer'
                          options={[
                            { Customer: -1, Name: 'Create New' },
                            ...(this.state.Data.VPARCM
                              ? [this.state.Data.VPARCM]
                              : []),
                            ...vpCustomers,
                          ]}
                          menuItemComponent={value => (
                            <MenuItem value={value.Customer}>
                              {value.Customer < 0
                                ? 'Create New'
                                : `${value.Customer} - ${value.Name}`}
                            </MenuItem>
                          )}
                          portal={true}
                          debounce={750}
                          onChange={text =>
                            this.setState(
                              { ...this.state, searchDescription: text },
                              this.handleCustomerSearch,
                            )
                          }
                          value={this.state.VPCustomer || ''}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              VPCustgroup: option.CustGroup,
                              VPCustomer: option.Customer,
                            })
                          }
                          required
                        />
                        {isSearching && (
                          <CircularProgress
                            color='primary'
                            className='absolute pin-r pin-t m-auto'
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 9,
                              marginTop: 18,
                            }}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  )}
                  {qboCompany && (
                    <React.Fragment>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {/* <div className='flex relative'> */}
                      <Autocomplete
                        className={classes.formControl}
                        title='QBO Customer'
                        options={[
                          { QBOCustomer: -1, Name: 'Create New' },
                          ...qboCustomers,
                          ...(this.state.Data.QBOCustomer
                            ? [
                                {
                                  QBOCustomer: this.state.Data.QBOCustomer.Id,
                                  Name: this.state.Data.QBOCustomer.DisplayName,
                                },
                              ]
                            : []),
                        ]}
                        menuItemComponent={value => (
                          <MenuItem value={value.QBOCustomer}>
                            {value.QBOCustomer < 0
                              ? 'Create New'
                              : `${value.QBOCustomer} - ${value.Name}`}
                          </MenuItem>
                        )}
                        portal={true}
                        debounce={750}
                        onChange={text =>
                          this.setState(
                            { ...this.state, searchDescription: text },
                            this.handleQBOCustomerSearch,
                          )
                        }
                        value={this.state.QBOCustomer || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            QBOCompany: qboCompany.Co,
                            QBOCustomer: option.QBOCustomer,
                          })
                        }
                        required
                      />
                      {isSearching && (
                        <CircularProgress
                          color='primary'
                          className='absolute pin-r pin-t m-auto'
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 9,
                            marginTop: 18,
                          }}
                        />
                      )}
                      {/* </div> */}
                    </React.Fragment>
                  )}
                </div>
                <div className='w-full'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.BillSame === 'Y'}
                        name='BillSame'
                        onChange={this.handleChange}
                        color='primary'
                      />
                    }
                    label='Save as billing'
                    className='float-right mr-0 mb-24'
                  />
                </div>
                <div className='w-full sm:flex' style={{ display: 'none' }}>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>phone</Icon>
                  </div>
                  <PhoneInput
                    {...this.props}
                    className={'mb-12'}
                    label='Phone'
                    id='phone'
                    name='Phone'
                    value={this.state.Phone || ''}
                    onChange={value => {
                      this.setState({ ...this.state, Phone: value })
                    }}
                    variant='outlined'
                    required={true}
                    fullWidth={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={'mb-12'}
                    label='Email'
                    id='email'
                    name='Email'
                    value={this.state.Email || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                {/* <div className="w-full sm:flex">
                                <div className="hidden sm:block min-w-48 pt-20">
                                    <Icon color="action">phone</Icon>
                                </div>
                                <PhoneInput
                                    {...this.props}
                                    className={"mb-12"}
                                    label="Phone"
                                    id="phone"
                                    name="Phone"
                                    value={this.state.Phone || ''}
                                    onChange={(value) => {
                                        this.setState({ ...this.state, Phone: value });
                                    }}
                                    variant="outlined"
                                    required={true}
                                    fullWidth={true}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={"mb-12"}
                                    label="Email"
                                    id="email"
                                    name="Email"
                                    value={this.state.Email || ''}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        autoComplete: "off"
                                    }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                {/* {Address ?
                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20">
                                        <Icon color="action">map</Icon>
                                    </div>
                                    <div style={{
                                        width: '100%',
                                        height: '250px',
                                        backgroundColor: '#eeeeee',
                                        marginBottom: 24,
                                        borderRadius: '5px',
                                        overflow: 'hidden'
                                    }}>
                                        <GoogleMap
                                            options={this.getMapOptions}
                                            gestureHandling="cooperative"
                                            bootstrapURLKeys={{
                                                key: googleMapsApiKey
                                            }}
                                            defaultZoom={12}
                                            defaultCenter={[Number(Latitude), Number(Longitude)]}
                                            center={[Number(Latitude), Number(Longitude)]}
                                        >
                                            <Marker
                                                key={1}
                                                style={{ margin: 0, padding: 0, width: 32, height: 42, marginTop: -42, marginLeft: -16, cursor: 'pointer' }}
                                                icon="assets/images/logos/SPMarker_Gradient.png"
                                                text={this.state.Name ? this.state.Name : Address}
                                                lat={Number(Latitude)}
                                                lng={Number(Longitude)}
                                            />
                                        </GoogleMap>
                                    </div>
                                </div>
                                : ''
                            } */}

                <div className='w-full sm:flex mb-12' style={{ marginTop: -8 }}>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>contacts</Icon>
                  </div>
                  <ContactList
                    onUpdate={Contacts => {
                      const billPhone = _.filter(
                        Contacts,
                        o => o.BillingYN === 'Y',
                      )[0]
                        ? _.filter(Contacts, o => o.BillingYN === 'Y')[0].Data
                            .Contact.Phone
                        : _.filter(Contacts, o => o.PrimaryYN === 'Y')[0].Data
                            .Contact.Phone
                      const billEmail = _.filter(
                        Contacts,
                        o => o.BillingYN === 'Y',
                      )[0]
                        ? _.filter(Contacts, o => o.BillingYN === 'Y')[0].Data
                            .Contact.Email
                        : _.filter(Contacts, o => o.PrimaryYN === 'Y')[0].Data
                            .Contact.Email
                      this.setState(
                        {
                          ...this.state,
                          billPhone,
                          billEmail,
                          Data: { ...this.state.Data, Contacts },
                        },
                        () =>
                          updateCustomer({
                            ...this.state,
                            billPhone,
                            billEmail,
                          }),
                      )
                    }}
                    editable={true}
                    customer={{ ...this.state }}
                    details={this.state.Data.Contacts || []}
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>assistant</Icon>
                  </div>
                  <ChipInput
                    className='mb-12'
                    variant='outlined'
                    label='Tags'
                    id='tags'
                    name='Tags'
                    blurBehavior='add'
                    value={this.state.Data.Tags}
                    onAdd={this.handleAddTag}
                    onDelete={this.handleDeleteTag}
                    chipRenderer={({ value, handleDelete }, key) =>
                      value.ActiveYN == 'Y' && (
                        <Tooltip
                          title={`Added By ${value.AddedBy} on ${new Date(
                            value.AddedDate,
                          ).toLocaleDateString('en-US')}`}
                          placement='top'
                        >
                          <Chip
                            className='mb-8 mr-4'
                            color='primary'
                            icon={<Icon>account_circle</Icon>}
                            onDelete={handleDelete}
                            label={value.Title}
                          />
                        </Tooltip>
                      )
                    }
                    fullWidth
                    fullWidthInput
                  />
                </div>
                <div className='w-full flex mb-12 justify-between'>
                  <div className='flex'>
                    <div className='hidden sm:block min-w-48'></div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.ApprovedAccountYN === 'Y'}
                        name='ApprovedAccountYN'
                        onChange={this.handleChange}
                        color='primary'
                      />
                    }
                    label='Approved Account'
                  />
                </div>
                {this.state.ID && (
                  <div
                    className='pt-12 mt-12'
                    style={{ borderTop: '1px solid lightgrey' }}
                  >
                    <Comments
                      comments={[...this.state.Data.Comments]}
                      recordID={this.state.ID}
                      type='Customer'
                      commentAdd={comment => {
                        const { Data } = this.state
                        const { Comments } = Data
                        Comments.splice(0, 0, comment)
                        this.setState({
                          ...this.state,
                          Data: { ...Data, Comments },
                        })
                      }}
                    />
                  </div>
                )}
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                {(this.state.PaymentsTotal || this.state.InvoiceTotal) && (
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                      <Icon color='action'>account_balance</Icon>
                    </div>
                    <Button
                      variant='contained'
                      className='w-full text-12 p-12 mb-24'
                      color='primary'
                    >
                      <Icon className='mr-6'>receipt</Icon>Invoiced:{' '}
                      {this.formatDollars(invoiced)}
                    </Button>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Button
                      variant='contained'
                      className='w-full text-12 p-12 mb-24'
                      color='secondary'
                    >
                      <Icon className='mr-6'>payment</Icon>Paid:{' '}
                      {this.formatDollars(paid)}
                    </Button>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classNames(
                        due ? classes.error : classes.ok,
                        'w-full text-12 p-12 mb-24',
                      )}
                    >
                      <Icon className='mr-6'>today</Icon>Due:{' '}
                      {this.formatDollars(due)}
                    </Button>
                  </div>
                )}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>home</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Billing Address'
                    id='billAddress1'
                    name='BillAddress1'
                    value={this.state.BillAddress1}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    disabled={this.state.BillSame === 'Y'}
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label="Billing Address (cont'd)"
                    id='billAddress2'
                    name='BillAddress2'
                    value={this.state.BillAddress2}
                    onChange={this.handleChange}
                    variant='outlined'
                    disabled={this.state.BillSame === 'Y'}
                    fullWidth
                  />
                </div>

                <div className='w-full sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Billing City'
                    id='billCity'
                    name='BillCity'
                    value={this.state.BillCity}
                    onChange={this.handleChange}
                    disabled={this.state.BillSame === 'Y'}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Billing State'
                    id='billState'
                    name='BillState'
                    value={this.state.BillState}
                    onChange={this.handleChange}
                    disabled={this.state.BillSame === 'Y'}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Billing Zip Code'
                    id='billZip'
                    name='BillZip'
                    value={this.state.BillZip}
                    onChange={this.handleChange}
                    disabled={this.state.BillSame === 'Y'}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                {/* TODO: billing here */}
                <div className='w-full sm:flex' style={{ display: 'None' }}>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>phone</Icon>
                  </div>
                  {/* <TextField
                                    className={classes.formControl}
                                    label="Billing Phone"
                                    id="phone"
                                    name="BillPhone"
                                    value={this.state.BillPhone || ''}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        autoComplete: "off"
                                    }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                /> */}
                  <PhoneInput
                    className={'mb-12'}
                    label='Billing Phone'
                    id='phone'
                    name='BillPhone'
                    //value={_.filter(this.state.Data.Contacts, (o) => o.BillingYN === "Y")[0] ? _.filter(this.state.Data.Contacts, (o) => o.BillingYN === "Y")[0].Data.Contact.Phone : ''}
                    value={this.state.BillPhone || ''}
                    onChange={value => {
                      this.setState({ ...this.state, BillPhone: value })
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    disabled={this.state.BillSame === 'Y'}
                    required={true}
                    fullWidth={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={'mb-12'}
                    label='Billing Email'
                    id='email'
                    name='BillEmail'
                    //value={_.filter(this.state.Data.Contacts, (o) => o.BillingYN === "Y")[0] ? _.filter(this.state.Data.Contacts, (o) => o.BillingYN === "Y")[0].Data.Contact.Email : ''}
                    value={this.state.BillEmail || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    disabled={this.state.BillSame === 'Y'}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                {/* <div className="w-full sm:flex mb-12" style={{ marginTop: -8 }}>
                                <div className="hidden sm:block min-w-48 pt-20">
                                    <Icon color="action">contacts</Icon>
                                </div>
                                <ContactList billing={true} onUpdate={(Contacts) => this.setState({ ...this.state, Data: { ...this.state.Data, Contacts } })} editable={true} customer={{ ...this.state }} details={this.state.Data.Contacts || []} />
                            </div> */}

                <div className='w-full sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>format_list_numbered_rtl</Icon>
                  </div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Default Tax Code'
                    options={[
                      { Description: 'None', TaxCode: null },
                      ...taxCodes,
                    ]}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.TaxCode}>
                          {value.TaxCode
                            ? `${value.Description} - ${value.TaxCode}`
                            : value.Description}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.TaxCode || ''}
                    onSelect={option =>
                      this.setState({ ...this.state, TaxCode: option.TaxCode })
                    }
                    // required
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Pay Terms'
                    options={payTerms}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.PayTerms}>
                          {`${value.PayTerms} - ${value.Description}`}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.PayTerms}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        PayTerms: option.PayTerms,
                      })
                    }
                    required
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                    <Icon color='action'>settings</Icon>
                  </div>
                  <div className='w-full'>
                    <label
                      className='text-12 p-4 px-6 ml-8'
                      style={{ background: '#fff', color: 'grey' }}
                    >
                      Non-Taxable Items:
                    </label>
                    <div
                      className='rounded-4 p-12 mb-12'
                      style={{
                        border: '1px solid rgba(0, 0, 0, .25)',
                        marginTop: -11,
                      }}
                    >
                      <CustomerTaxability
                        editable={true}
                        customer={{ Co, Customer }}
                        taxability={this.state.Data.Taxability || []}
                        onChange={Taxability =>
                          this.setState({
                            ...this.state,
                            Data: { ...this.state.Data, Taxability },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'></div>
                  <div className='w-full sm:flex sm:justify-between'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.InvoiceReviewYN === 'Y'}
                          onChange={() =>
                            this.setState({
                              ...this.state,
                              InvoiceReviewYN:
                                this.state.InvoiceReviewYN === 'Y' ? 'N' : 'Y',
                            })
                          }
                        />
                      }
                      label='Invoice Review'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.MailInvoiceYN === 'Y'}
                          onChange={() =>
                            this.setState({
                              ...this.state,
                              MailInvoiceYN:
                                this.state.MailInvoiceYN === 'Y' ? 'N' : 'Y',
                            })
                          }
                        />
                      }
                      label='Mail Invoices'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.PORequiredYN === 'Y'}
                          onChange={() => {
                            this.setState({
                              ...this.state,
                              PORequiredYN:
                                this.state.PORequiredYN === 'Y' ? 'N' : 'Y',
                            })
                          }}
                        />
                      }
                      label='PO Required'
                    />
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 2 && customerDialog.type !== 'new' && (
              <TabContainer>
                <Fab
                  color='secondary'
                  aria-label='add'
                  className={classes.addButton}
                  onClick={event => {
                    openNewCustomerSiteDialog({
                      ID: '',
                      Co: Co,
                      Customer: Customer,
                      Description: `${Address1} ${
                        Name.length > 0 ? Name : `${FirstName} ${LastName}`
                      }`,
                      Site: '',
                      Contact: Contact,
                      Phone: Phone,
                      Email: Email,
                      Address1: Address1,
                      Address2: Address2,
                      City: City,
                      State: State,
                      Zip: Zip,
                      Country: Country,
                      Latitude: null,
                      Longitude: null,
                      Notes: '',
                      Status: 'A',
                      value: 0,
                      histTab: 0,
                      Data: {
                        Customer: {},
                        WorkOrders: [],
                        Tags: [],
                        Comments: [],
                      },
                    })
                  }}
                >
                  <Tooltip title='New Service Site' placement='left'>
                    <Icon>add</Icon>
                  </Tooltip>
                </Fab>
                <CustomerSitesList
                  selectedCustomerData={{ ...this.state }}
                  selectedCustomerSiteData={[...this.state.Data.Sites]}
                />
              </TabContainer>
            )}
            {value === 4 && customerDialog.type !== 'new' && (
              <TabContainer>
                <PaymentMethodList
                  data={this.state.Data.PaymentMethods || []}
                  payments={this.state.Data.Payments || []}
                  customer={this.state}
                />
              </TabContainer>
            )}
            {value === 3 && customerDialog.type !== 'new' && (
              <TabContainer>
                <div className={classes.hist}>
                  <Drawer
                    variant='permanent'
                    className={classNames(classes.drawer, {
                      [classes.drawerOpen]: this.state.open,
                      [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                      paper: classNames(classes.shelf, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                      }),
                    }}
                    open={this.state.open}
                  >
                    <List>
                      <ListItem
                        onClick={() => this.setState({ histTab: 0 })}
                        button
                        key='Work Orders'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Orders' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.WorkOrders || 0}
                              color='error'
                            >
                              <Assignment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 1 })}
                        button
                        key='Work Order Scopes'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Order Scopes' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Scopes || 0}
                              color='error'
                            >
                              <Build />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 2 })}
                        button
                        key='Invoices'
                      >
                        <ListItemIcon>
                          <Tooltip title='Invoices' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Invoices || 0}
                              color='error'
                            >
                              <Receipt />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 3 })}
                        button
                        key='Payments'
                      >
                        <ListItemIcon>
                          <Tooltip title='Payments' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Payments || 0}
                              color='error'
                            >
                              <Payment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 4 })}
                        button
                        key='Estimates'
                      >
                        <ListItemIcon>
                          <Tooltip title='Estimates' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Estimates || 0}
                              color='error'
                            >
                              <ListIcon />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ showEmailLogs: true })}
                        button
                        key='Emails'
                      >
                        <ListItemIcon>
                          <Tooltip title='Email Log' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={
                                this.state.Data && this.state.Data.EmailLogs
                                  ? this.state.Data.EmailLogs.length
                                  : 0
                              }
                              color='error'
                            >
                              <Icon>email</Icon>
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Drawer>
                  <div className={classes.content}>
                    <div className={classes.histTable}>
                      {histTab === 0 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrdersList
                            hist={{
                              PageCount: Math.ceil(this.state.WorkOrders / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.WorkOrders,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrders || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 1 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrderScopesList
                            hist={{
                              PageCount: Math.ceil(this.state.Scopes / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Scopes,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrderScopes || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 2 && (
                        <TabContainer padding={'0px'}>
                          <InvoicesList
                            hist={{
                              PageCount: Math.ceil(this.state.Invoices / 10),
                              Results: [...(this.state.Data.Invoices || [])],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Invoices,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            invoiceData={[...(this.state.Data.Invoices || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 3 && (
                        <TabContainer padding={'0px'}>
                          <PaymentsList
                            hist={{
                              PageCount: Math.ceil(this.state.Payments / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Payments,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            payments={[...(this.state.Data.Payments || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 4 && (
                        <TabContainer padding={'0px'}>
                          {/* <EstimateDialog /> */}
                          <EstimatesList
                            hist={{
                              PageCount: Math.ceil(this.state.Estimates / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Estimates,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            estimateData={[
                              ...(this.state.Data.Estimates || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                    </div>
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 5 && customerDialog.type !== 'new' && (
              <TabContainer>
                <AgreementsList
                  customer={{ ...this.state }}
                  selectedAgreementData={[...this.state.Data.Agreements]}
                />
              </TabContainer>
            )}
            {value === 6 && (
              <TabContainer>
                {accessLevel && accessLevel.AccessLevel !== 'R' && (
                  <React.Fragment>
                    <Typography
                      variant='h6'
                      style={{ alignItems: 'center' }}
                      className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                    >
                      <Icon color='primary' className='text-32 mr-12'>
                        add
                      </Icon>
                      {`${
                        !this.state.rateTemplate.ID ? 'Add' : 'Update'
                      } Rate Template`}
                    </Typography>
                    <div className='sm:flex'>
                      <Autocomplete
                        className={classes.formControl}
                        title='Business Unit'
                        options={businessUnits}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.BusinessUnit}>
                              {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={true}
                        value={this.state.rateTemplate.BusinessUnit || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            rateTemplate: {
                              ...this.state.rateTemplate,
                              BusinessUnit: option.BusinessUnit,
                            },
                          })
                        }
                        disabled={this.state.rateTemplate.ID}
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Division'
                        options={
                          divisions
                            ? _.filter(divisions, {
                                BusinessUnit:
                                  this.state.rateTemplate.BusinessUnit,
                              })
                            : []
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Division}>
                              {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={true}
                        value={this.state.rateTemplate.Division || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            rateTemplate: {
                              ...this.state.rateTemplate,
                              Division: option.Division,
                            },
                          })
                        }
                        disabled={this.state.rateTemplate.ID}
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Rate Template'
                        options={_.filter(rateTemplates, {
                          Co,
                          BusinessUnit: this.state.rateTemplate.BusinessUnit,
                          Division: this.state.rateTemplate.Division,
                        })}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.RateTemplate}>
                              {value.RateTemplate} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.rateTemplate.RateTemplate || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            rateTemplate: {
                              ...this.state.rateTemplate,
                              RateTemplate: option.RateTemplate,
                            },
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {!this.state.rateTemplate.ID ? (
                        <Button
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            const { Co, Customer } = this.state
                            this.props.addRateTemplate({
                              ...this.state.rateTemplate,
                              Co,
                              Customer,
                              Data: { ErrMsg: null },
                            })
                            this.setState({
                              ...this.state,
                              rateTemplate: newCustomerState.rateTemplate,
                            })
                          }}
                          disabled={
                            this.state.rateTemplate.BusinessUnit &&
                            this.state.rateTemplate.Division &&
                            this.state.rateTemplate.RateTemplate
                              ? false
                              : true
                          }
                          // disabled={!this.canMemberBeSubmitted()}
                        >
                          Add
                        </Button>
                      ) : (
                        <div className='w-full flex'>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.updateRateTemplate,
                              'w-full',
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              this.props.updateRateTemplate({
                                ...this.state.rateTemplate,
                                Data: { ErrMsg: null },
                              })
                              this.setState({
                                ...this.state,
                                rateTemplate: newCustomerState.rateTemplate,
                              })
                            }}
                            // disabled={!this.canMemberBeSubmitted()}
                          >
                            Update
                          </Button>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.deleteRateTemplate,
                            )}
                            variant='contained'
                            onClick={() => {
                              this.props.removeRateTemplate({
                                ...this.state.rateTemplate,
                                Data: { ErrMsg: null },
                              })
                              this.setState({
                                ...this.state,
                                rateTemplate: newCustomerState.rateTemplate,
                              })
                            }}
                            // disabled={!this.canMemberBeSubmitted()}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
                <CustomerRateTemplatesList
                  onSelected={rateTemplate =>
                    this.setState({ ...this.state, rateTemplate })
                  }
                  data={[...(this.state.Data.RateTemplates || [])]}
                />
              </TabContainer>
            )}
            {value === 7 && customerDialog.type !== 'new' && (
              <TabContainer>
                <FileList
                  attachments={this.state.Data.Attachments}
                  onAdded={this.attachmentSelectedHandler}
                  accept='.jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt'
                />
              </TabContainer>
            )}
            {value === 8 && customerDialog.type !== 'new' && (
              <TabContainer>
                <DashboardSelector data={this.state} form='customer' />
              </TabContainer>
            )}

            {accessLevel &&
              accessLevel.AccessLevel !== 'R' &&
              (value === 0 || value === 1) && (
                <DialogActions className='dialog-actions justify-between pl-16'>
                  {customerDialog.type === 'new' ? (
                    <DebounceButton
                      buttonText={'Add'}
                      processingText={'Adding'}
                      isDisabled={
                        !this.canBeSubmitted() || !this.billingCanBeSubmitted()
                          ? true
                          : false
                      }
                      variant={'contained'}
                      color={'primary'}
                      clickFxn={async () => {
                        this.setState({
                          ...this.state,
                          Data: {
                            ...this.state.Data,
                            IgnoreDuplicatedEntitiesCheck: false,
                          },
                        })

                        await upsertCustomer(this)
                      }}
                      debounceInterval={1000}
                    />
                  ) : (
                    <>
                      <DebounceButton
                        buttonText={'Save'}
                        processingText={'Saving'}
                        isDisabled={!this.canBeSubmitted()}
                        variant={'contained'}
                        color={'primary'}
                        clickFxn={async () => {
                          window['warn']('state being saved:', this.state)
                          this.setState({
                            ...this.state,
                            Data: {
                              ...this.state.Data,
                              IgnoreDuplicatedEntitiesCheck: false,
                            },
                          })

                          await upsertCustomer(this)
                        }}
                        debounceInterval={1000}
                      />
                      {isAllowedToDelete && (
                        <DebounceButton
                          buttonText={'Delete'}
                          processingText={'Deleting'}
                          variant={'contained'}
                          className={'backgroundErrorColor'}
                          clickFxn={async () => {
                            this.setState({
                              ...this.state,
                              showDeleteConfirmation: true,
                            })
                          }}
                          debounceInterval={1000}
                        />
                      )}
                    </>
                  )}
                  {customerDialog.type === 'new' &&
                  this.canBeSubmitted() &&
                  !this.billingCanBeSubmitted() ? (
                    <Button
                      variant={'contained'}
                      className={'backgroundErrorColor'}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          value: 1,
                        })
                      }
                    >
                      Billing Info Required before adding
                    </Button>
                  ) : null}
                </DialogActions>
              )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}
CustomersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEditWorkOrderDialog: WOActions.updateEditWorkOrderDialog,
      updateEditCustomerDialog: Actions.updateEditCustomerDialog,
      closeEditCustomerDialog: Actions.closeEditCustomerDialog,
      closeNewCustomerDialog: Actions.closeNewCustomerDialog,
      addCustomer: Actions.addCustomer,
      updateCustomer: Actions.updateCustomer,
      deleteCustomer: Actions.deleteCustomer,
      openNewCustomerSiteDialog: SiteActions.openNewCustomerSiteDialog,
      openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
      getFiles: FileActions.getFiles,
      openNewCustomerDNUDialog: Actions.openNewCustomerDNUDialog,
      openEditCustomerDNUDialog: Actions.openEditCustomerDNUDialog,
      addRateTemplate: Actions.addRateTemplate,
      updateRateTemplate: Actions.updateRateTemplate,
      removeRateTemplate: Actions.removeRateTemplate,
      openEditCustomerDialog: Actions.openEditCustomerDialog,
      openMinimizedDialog,
      updateAllMinimizedDialogs,
      addSplitscreenDialog,
      showSplitscreenDialog,
      setSplitscreenDialog,
      hideAllSplitscreenDialogs,
      captureSplitscreenDialogs,
      showMessage,
      openPreloadCustomerDialog,
    },
    dispatch,
  )
}

function mapStateToProps({
  customersApp,
  fileManagerApp,
  spReducers,
  minimizedDialogApp,
  splitscreenDialogApp,
  workOrdersApp,
}) {
  window['warn'](
    customersApp.customers.customerDialog,
    workOrdersApp.workOrders.workOrderDialog,
  )
  return {
    workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
    customerDialog: customersApp.customers.customerDialog,
    files: fileManagerApp.files,
    selectedItem: fileManagerApp.selectedItem,
    googleMapsApiKey: googleMapsApiKey,
    taxCodes: spReducers.taxCodes,
    user: customersApp.user,
    Co: spReducers.companies.Co,
    payTerms: spReducers.payTerms,
    securables: spReducers.userProfiles.User.Data.Securables,
    vpCompany: spReducers.vpSettings.vpCompany,
    qboCompany: spReducers.qboSettings.qboCompany,
    rateTemplates: spReducers.rateTemplates,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    users: spReducers.userProfiles.Users,
    User: spReducers.userProfiles.User,
    minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    splitscreenDialogs: splitscreenDialogApp.splitscreenDialog.data,
    splitscreenDialog: splitscreenDialogApp.splitscreenDialog,
    isAllowedToDelete: _.some(spReducers.userProfiles.User.Data.Securables, {
      Securable: 'deletion-manager',
    }),
  }
}

async function upsertCustomer(component) {
  const { addCustomer, updateCustomer } = component.props
  const { data, props } = component.props.workOrderDialog

  const response =
    component.props.customerDialog.type === 'new'
      ? await addCustomer(component.state)
      : await updateCustomer(component.state)

  window['warn'](
    'UPSERT CUSTOMER!!!',
    response,
    component.props,
    component.state,
  )
  if (
    !response.Data.DuplicatedEntities &&
    response.Data.DuplicatedEntities.length === 0
  ) {
    if (props.open === true) {
      await component.props.updateEditWorkOrderDialog(data, data.Trip)
    }
  }

  if (
    response.Data.DuplicatedEntities &&
    response.Data.DuplicatedEntities.length > 0
  ) {
    component.setState({
      ...component.state,
      duplicatedEntitiesList: response.Data.DuplicatedEntities,
      showConfirmDuplicateCustomerCreationDialog: true,
    })
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CustomersDialog),
)
