import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTeamIds: [],
    routeParams: {},
    teamDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            avatar: 'assets/images/avatars/profile.jpg',
            Co: null,
            Team: null,
            Employee: null,
            FirstName: null,
            LastName: null,
            MiddleInitial: null,
            TeamCategory: null,
            TeamRole: null,
            Phone: null,
            Email: null,
            Bio: null,
            ExemptYN: 'N',
            Notes: null,
            ActiveYN: 'Y',
            DefaultBusinessUnit: null,
            DefaultDivision: null,
            DefaultDepartment: null,
            value: 1,
            histTab: 0,
            Data: {
                WorkOrders: [],
                Trips: [],
                Timecard: null,
            },
            showTabs: false,
        },
        loading: false,
        date: new Date(),
    }
};

const teamsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TEAMS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        //case Actions.LOAD_WORK_ORDERS
        //    {
        //        return {
        //            ...state,
        //            workOrders: action.workOrders
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_TEAMS:
            {

                const teamId = action.teamId;

                let selectedTeamIds = [...state.selectedTeamIds];

                if (selectedTeamIds.find(id => id === teamId) !== undefined) {
                    selectedTeamIds = selectedTeamIds.filter(id => id !== teamId);
                }
                else {
                    selectedTeamIds = [...selectedTeamIds, teamId];
                }

                return {
                    ...state,
                    selectedTeamIds: selectedTeamIds
                };
            }
        case Actions.SELECT_ALL_TEAMS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedTeamIds = arr.map(team => team.id);

                return {
                    ...state,
                    selectedTeamIds: selectedTeamIds
                };
            }
        case Actions.DESELECT_ALL_TEAMS:
            {
                return {
                    ...state,
                    selectedTeamIds: []
                };
            }
        case Actions.OPEN_NEW_TEAM_DIALOG:
            {
                window["log"]('CUSTOMER DATA FOR NEW SITE: ', action.data, (action.data.Customer ? true : false));
                return {
                    ...state,
                    teamDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_TEAM_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_TEAM_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_TEAM_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type: 'data',
                        props: {
                            ...state.teamDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_TEAM_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_TEAM_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_TEAM_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.SET_TEAM_SCHEDULE:
            {
                const { date, data, loading } = action;
                return {
                    ...state,
                    teamDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        date,
                        data,
                        loading,
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default teamsReducer;
