import { FusePageCarded } from '@fuse';
import _ from '@lodash';
import { withStyles } from '@material-ui/core/styles';
import ServiceUnitHeader from 'main/content/apps/service-units/ServiceUnitsHeader';
import ServiceUnitsLeftSidebarHeader from 'main/content/apps/service-units/ServiceUnitsLeftSidebarHeader';
import ServiceUnitList from 'main/content/apps/service-units/ServiceUnitsList';
import ServiceUnitSidebarContent from 'main/content/apps/service-units/ServiceUnitsSidebarContent';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class ServiceUnitsApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getServiceUnits(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewServiceUnitDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <ServiceUnitHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <ServiceUnitList />
                            }
                            leftSidebarHeader={
                                <ServiceUnitsLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <ServiceUnitSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServiceUnits: Actions.getServiceUnits,
        getUserData: Actions.getUserData,
        openNewServiceUnitDialog: Actions.openNewServiceUnitDialog
    }, dispatch);
}

function mapStateToProps({ serviceUnitsApp }) {
    return {
        serviceUnits: serviceUnitsApp.serviceUnits.entities,
        selectedServiceUnitIds: serviceUnitsApp.serviceUnits.selectedServiceUnitIds,
        searchText: serviceUnitsApp.serviceUnits.searchText,
        user: serviceUnitsApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitsApp)));
