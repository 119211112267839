import { withStyles, Icon, List, ListItem, ListItemText, ListSubheader, Button } from '@material-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FuseAnimate } from '@fuse';
import * as Actions from './store/actions';
import TagBuilderDialog from './TagBuilderDialog';
import classNames from 'classnames';
import _ from '@lodash';

const styles = theme => ({
    listWrapper: {},
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        },
        '& .list-item-icon': {
            fontSize: 20,
            width: 20,
            height: 20,
            marginRight: 6,
            marginBottom: 4,
            verticalAlign: 'middle',
        },
    },
    listItemContainer: {
        width: '100%',
        '& .edit-icon': {
            visibility: 'hidden',
            opacity: 0
        },
        '&:hover .edit-icon': {
            visibility: 'visible',
            opacity: 1,
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
            marginRight: 24,
            cursor: 'pointer',
        }
    },
    listSubheader: {
        paddingRight: 24,
        paddingLeft: 24
    }
});

const initialState = {
    newTag: false,
    editTag: null,
}
class TodoSidebarContent extends Component {
    state = {
        ...initialState
    }
    render() {
        const { classes, labels, securables } = this.props;
        const accessLevel = _.find(securables, { Securable: "to-do-labels" });
        const filters = [{
            'id': 0,
            'handle': 'starred',
            'title': 'Starred',
            'icon': 'star'
        },
        {
            'id': 1,
            'handle': 'important',
            'title': 'Priority',
            'icon': 'error'
        },
        {
            'id': 2,
            'handle': 'late',
            'title': 'Late',
            'icon': 'assignment_late'
        },
        {
            'id': 3,
            'handle': 'pending',
            'title': 'Pending',
            'icon': 'schedule'
        },
        {
            'id': 4,
            'handle': 'completed',
            'title': 'Done',
            'icon': 'check'
        }];
        const folders = [
            {
                'id': 0,
                'handle': 'all',
                'title': 'All',
                'icon': 'view_headline'
            }
        ];

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={400}>
                <div className="pt-16">

                    <div className={classes.listWrapper}>

                        <List>
                            {folders.length > 0 && folders.map((folder) => (
                                <ListItem
                                    button
                                    component={NavLink}
                                    to={'/apps/to-do/' + folder.handle} key={folder.id}
                                    activeClassName="active"
                                    className={classes.listItem}
                                >
                                    <Icon className="list-item-icon" color="action">{folder.icon}</Icon>
                                    <ListItemText primary={folder.title} disableTypography={true} />
                                </ListItem>
                            ))}
                        </List>

                        <List>
                            <ListSubheader className={classes.listSubheader} disableSticky>FILTERS</ListSubheader>

                            {filters.length > 0 && filters.map((filter) => (
                                <ListItem
                                    button
                                    component={NavLink}
                                    to={'/apps/to-do/filter/' + filter.handle}
                                    activeClassName="active"
                                    className={classes.listItem}
                                    key={filter.id}
                                >
                                    <Icon className="list-item-icon" color="action">{filter.icon}</Icon>
                                    <ListItemText primary={filter.title} disableTypography={true} />
                                </ListItem>
                            ))}
                        </List>

                        <List>

                            <ListSubheader className={classes.listSubheader} disableSticky>TAGS</ListSubheader>

                            <div className="max-h-256 overflow-y-auto">
                                {labels.length > 0 && labels.map((label) => (
                                    <div className={classNames(classes.listItemContainer, "flex relative")}>
                                        <ListItem
                                            button
                                            component={NavLink}
                                            to={'/apps/to-do/label/' + label.ID}
                                            key={label.ID}
                                            className={classes.listItem}
                                        >
                                            <Icon className="list-item-icon" style={{ color: label.Background }}
                                                color="action">label</Icon>
                                            <ListItemText primary={label.Name} disableTypography={true} />
                                        </ListItem>
                                        {accessLevel && accessLevel.AccessLevel === "F" &&
                                            <Icon onClick={(e) => { e.stopPropagation(); this.setState({ editTag: label }) }} className="edit-icon" color="action">edit</Icon>
                                        }
                                    </div>
                                ))}
                            </div>
                            {accessLevel && accessLevel.AccessLevel === "F" &&
                                <ListItem
                                    button
                                    onClick={() => this.setState({ newTag: true })}
                                    className={classes.listItem}
                                >
                                    <Icon className="list-item-icon"
                                        color="secondary">add_circle_outline</Icon>
                                    <ListItemText primary={"New Tag"} disableTypography={true} />
                                </ListItem>
                            }
                        </List>
                    </div>
                    <TagBuilderDialog open={this.state.newTag || this.state.editTag} data={this.state.editTag || {}} onAdd={() => this.setState({ newTag: false, editTag: null })} onUpdate={() => this.setState({ newTag: false, editTag: null })} onClose={() => this.setState({ newTag: false, editTag: null })} />
                </div>
            </FuseAnimate>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openNewTodoDialog: Actions.openNewTodoDialog
    }, dispatch);
}

function mapStateToProps({ todoApp, spReducers }) {
    return {
        labels: spReducers.calendar.tags,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TodoSidebarContent)));
