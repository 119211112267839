import {combineReducers} from 'redux';
import gpsInventoryTransferList from './gps-transfer-list.reducer';
import user from './user.reducer';

const gpsInventoryTransferListAppReducers = combineReducers({
    gpsInventoryTransferList,
    user
});

export default gpsInventoryTransferListAppReducers;
