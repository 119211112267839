import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import deburr from 'lodash/deburr';
import * as Actions from './store/actions';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Avatar, Button, Card, CardHeader, Icon, Input, Paper, Popper, MenuItem, TextField, Typography, InputAdornment, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { FuseAnimateGroup } from '@fuse';
import accents from 'remove-accents';
import axios from 'axios/index';
import _ from '@lodash';
import moment from 'moment';

const styles = theme => ({
    root: {
        flexGrow: 1,
        left: 0,
        right: 0,
        position: 'absolute',
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        margin: 'auto',
        zIndex: 3,
        maxHeight: 360,
        overflow: 'auto',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: 8,
    },
    input: {
        paddingLeft: '8.8rem',
    },
    popper: {
        opacity: 1,
        transform: 'none !important',
        zIndex: 999999,
        marginTop: 4,
        marginBottom: 4,
        '&[x-placement*="bottom-start"]': {
            top: '56px !important',
        },
        '&[x-placement*="top-start"]': {
            top: 'unset !important',
            bottom: 56,
        },
    },
    portalPopper: {
        opacity: 1,
        zIndex: 999999,
        marginTop: 4,
        marginBottom: 4,
    },
});

class CustomerSearch extends React.Component {
    state = {
        suggestions: [],
        searchText: null,
        loading: false
    };


    handleSearchInput = (event) => {
        this.setState({ ...this.state, searchText: event.target.value, loading: true }, () => {
            this.getSuggestions();
        });

    }

    handleFocus = (event) => {
        this.getSuggestions();
    }

    handleBlur = (event) => {
        // this.setState({ suggestions: [] });
    }

    getSuggestions = _.debounce(() => {
        const { searchText } = this.state;
        if (searchText && searchText.length > 0) {
            const { Co, customers, sites, drafts } = this.props;
            const inputValue = deburr(accents.remove(searchText.trim())).toLowerCase();
            axios.get(`${window["apiLocation"]}/api/CustomerSearch?Co=${Co}&Term=${encodeURIComponent(inputValue)}`).then((response) => {
                const results = [];
                const Customers = response.data;
                for (var i = 0; i < Customers.length; i++) {
                    const suggestion = Customers[i];
                    results.push({ type: 'Customer', data: suggestion });
                }
                this.setState({ suggestions: results, loading: false }, () => window["warn"](this.state.suggestions));
            });
        } else {
            this.setState({ suggestions: [], loading: false });
        }
    }, 750);

    handleSelectCustomer(data) {
        const { Co, Customer } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Customer?Co=${encodeURIComponent(Co)}&Customer=${encodeURIComponent(Customer)}`).then((response) => {
            this.props.onSelected(response.data);
            this.setState({ suggestions: [], searchText: null });
        });
    }

    render() {
        const { classes, autoFocus } = this.props;
        const { suggestions, searchText, loading } = this.state;

        return (
            <div onFocus={this.handleFocus} onBlur={this.handleBlur} className="w-full mb-24">
                <TextField
                    className={classNames(classes.formControl, "")}
                    label="Search Customers"
                    value={searchText}
                    inputRef={ref => this.anchorEl = ref}
                    onChange={this.handleSearchInput}
                    InputLabelProps={{ shrink: this.state.searchText ? true : false }}
                    InputProps={{
                        endAdornment: loading ? <InputAdornment position="end"><CircularProgress color="primary" style={{ width: 16, height: 16 }} /></InputAdornment> : undefined
                    }}
                    fullWidth
                    variant="outlined"
                    autoFocus={autoFocus}
                />
                {
                    suggestions.length > 0 &&
                    <Popper className={classes.portalPopper} anchorEl={this.anchorEl} open={true} placement="bottom-start" modifiers={{ flip: { enabled: false, } }}>
                        <div className={classes.container} onMouseDown={(event) => { event.preventDefault(); }}>
                            <Paper className={classNames(classes.suggestionsContainerOpen, "w-full sm:w-auto px-4")} style={{ width: this.anchorEl ? this.anchorEl.clientWidth : undefined }} square>
                                {suggestions.map((suggestion) => {
                                    switch (suggestion.type) {
                                        case 'Customer':
                                            {
                                                return (
                                                    <MenuItem onClick={() => { this.handleSelectCustomer(suggestion.data); }} style={{ border: '1px dotted lightgrey', borderRadius: 4 }} component="div" className="px-16 my-4 w-full">
                                                        <div className="flex flex-1 w-full">
                                                            <div className="w-64">
                                                                <Icon color="primary" className="text-28 mb-0 w-full text-center">account_circle</Icon>
                                                                <Typography fullWidth style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold' }} className="w-full text-center text-12">{`#${suggestion.data.Customer}`}</Typography>
                                                            </div>
                                                            <div>
                                                                <Typography fullWidth style={{ alignItems: 'center', margin: 2, lineHeight: 1 }} className="w-full text-11 font-bold">{`${suggestion.data.FirstName} ${suggestion.data.LastName}`}</Typography>
                                                                <Typography fullWidth style={{ alignItems: 'center', margin: 2, lineHeight: 1 }} className="w-full text-11">{`${suggestion.data.Address1} ${suggestion.data.City}, ${suggestion.data.State} ${suggestion.data.Zip}`}</Typography>
                                                                <Typography fullWidth style={{ alignItems: 'center', margin: 2, lineHeight: 1 }} className="w-full text-11">{`${suggestion.data.Phone}`}</Typography>
                                                                {
                                                                    suggestion.data.UpdatedDate ?
                                                                        <Typography fullWidth style={{ alignItems: 'center', margin: 2, lineHeight: 1, fontSize: 'xx-small', fontWeight: 'bold' }} className="w-full">{`Updated: ${moment(moment(suggestion.data.UpdatedDate)).local().toDate().toLocaleTimeString('en-US')} on ${moment(moment(suggestion.data.UpdatedDate)).local().toDate().toLocaleDateString('en-US')}`}</Typography>
                                                                        :
                                                                        suggestion.data.AddedDate &&
                                                                        <Typography fullWidth style={{ alignItems: 'center', margin: 2, lineHeight: 1, fontSize: 'xx-small', fontWeight: 'bold' }} className="w-full">{`Added: ${moment(moment(suggestion.data.AddedDate)).local().toDate().toLocaleTimeString('en-US')} on ${moment(moment(suggestion.data.AddedDate)).local().toDate().toLocaleDateString('en-US')}`}</Typography>
                                                                }
                                                            </div>
                                                        </div>
                                                    </MenuItem >
                                                );
                                            }
                                    }
                                })
                                }
                            </Paper>
                        </div>
                    </Popper>
                }
            </div>
        );
    }
}

CustomerSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ customersApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CustomerSearch));