import * as Actions from 'store/actions/spConnection';

const initialState = {
    timecards: [],
    loading: false,
}

const timecards = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TIMECARD_DATA:
            {
                return {
                    timecards: action.payload,
                    loading: false,
                };
            }
        case Actions.FETCH_TIMECARD_DATA:
            {
                return {
                    ...state,
                    loading: true,
                }
            }
        case Actions.SET_TIMECARD_DATA:
            {
                return {
                    timecards: action.payload,
                    loading: false,
                };
            }
    }

    return state;
};

export default timecards;