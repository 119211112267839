import * as Actions from 'store/actions/spConnection';

const initialState = {
    Calls: [],
    Queues: [],
    Log: [],
    loading: false,
    start: new Date(),
    end: new Date(),
}
const callHandler = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CALL_HANDLER_DATA:
            {
                return {
                    ...state,
                    Calls: action.payload.length > 0 ? action.payload[0].Calls : state.Calls,
                    Queues: action.payload.length > 0 ? action.payload[0].Queues : state.Queues,
                }
            }
        case Actions.SET_CALL_HANDLER_DATA:
            {
                return {
                    ...state,
                    Calls: action.payload.length > 0 ? action.payload[0].Calls : state.Calls,
                    Queues: action.payload.length > 0 ? action.payload[0].Queues : state.Queues,
                }
            }
        case Actions.SET_CALL_LOG:
            {
                const { loading, data, start, end } = action;
                return {
                    ...state,
                    start,
                    end,
                    Log: data,
                    loading
                }
            }
    }

    return state;
};

export default callHandler;