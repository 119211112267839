import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, FormGroup, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import classNames from 'classnames';
import _ from '@lodash';
import Media from 'react-media';
import BarcodeScanner from 'main/content/components/barcode-scanner/BarcodeScanner';
import axios from 'axios';
import { impress } from 'main/content/compression/impress';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { BrowserQRCodeSvgWriter, BarcodeFormat } from '@zxing/library';
//import { openNewGpsInventoryTransferDialog } from '../gps-inventory-transfers/store/actions';
import { openEditGpsInventoryTransferListDialog } from '../gps-transfer-list/store/actions';
import WorkOrdersApp from '../work-orders/WorkOrdersApp';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {
        zIndex: '16 !important'
    },
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    }
});
const newInventoryLocationMaterialState = {
    ID: null,
    Co: null,
    Location: null,
    Material: null,
    Description: null,
    UOM: null,
    MfgBarcode: null,
    Cost: null,
    Price: null,
    StockQty: 0,
    LowStockQty: 0,
    ReOrderQty: 0,
    BinLocation: null,
    CountDate: null,
    PriceMethod: 'M',
    MarkupPercent: null,
    ActiveYN: 'Y',
    Notes: null,
    AttachmentID: null,
    StockedYN: 'N',
    Data: {
        ErrMsg: null,
    },
    scanner: null,
    isScanning: false,
    QBOCompany: null,
    QBOMaterial: null,
    partDescription: null,
    searching: false,
    dialogHeight: null,
    minHeight: null,
    location: null
};

class InventoryLocationMaterialDialog extends Component {
    state = { ..._.cloneDeepWith(newInventoryLocationMaterialState) };

    ref = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newInventoryLocationMaterialState.Co = this.props.Co;
        if (!prevProps.inventoryLocationMaterialDialog.props.open && this.props.inventoryLocationMaterialDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.inventoryLocationMaterialDialog.type === 'edit' &&
                this.props.inventoryLocationMaterialDialog.data &&
                !_.isEqual({ ...newInventoryLocationMaterialState, ...this.props.inventoryLocationMaterialDialog.data }, prevState)) {
                this.setState({ ..._.cloneDeepWith(newInventoryLocationMaterialState), ...this.props.inventoryLocationMaterialDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.inventoryLocationMaterialDialog.type === 'new' &&
                !_.isEqual(newInventoryLocationMaterialState, prevState)) {
                this.setState({ ..._.cloneDeepWith(newInventoryLocationMaterialState) });
            }
        } else {
            if (this.props.inventoryLocationMaterialDialog.type === 'update' &&
                this.props.inventoryLocationMaterialDialog.data &&
                !_.isEqual(this.props.inventoryLocationMaterialDialog.data, prevState)) {
                this.props.inventoryLocationMaterialDialog.type = 'edit';
                this.setState({ ...this.props.inventoryLocationMaterialDialog.data });
            }
        }
        if (this.state.Data && this.state.Data.Material) {
            const { Description, UOM, Data } = this.state.Data.Material;
            if (this.state.Description !== this.state.Data.Material.Description) {
                this.setState({ ...this.state, Description, UOM, Data: { ...this.state.Data, Avatar: Data && Data.Avatar ? Data.Avatar : null } })
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), this.handleTechLabor);
    };

    handleDateChange = (event) => {
        let OdometerDate = this.formatDate(event._d);
        this.setState({ ...this.state, OdometerDate });
    };

    closeComposeDialog = () => {
        this.props.inventoryLocationMaterialDialog.type === 'edit' ? this.props.closeEditInventoryLocationMaterialDialog() : this.props.closeNewInventoryLocationMaterialDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true})
    };

    closeDialogForSplitscreen = () => {
        this.props.inventoryLocationMaterialDialog.type === 'edit' ? this.props.closeEditInventoryLocationMaterialDialog() : this.props.closeNewInventoryLocationMaterialDialog();
    };

    canBeSubmitted() {
        const { Location, Material } = this.state;
        return Location && Material && Material.length > 0 && Location.length > 0;
    }

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    fileSelectedHandler = (event) => {
        let { ID, Data } = this.state;
        const { materialDialog } = this.props;
        impress(event.target.files[0], 1024, 50, true, (img) => {
            window["log"](img);
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Attachment/PostMaterialImage?Co=${encodeURIComponent(this.state.Co)}&ID=${encodeURIComponent(this.state.Material)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
                if (Data) {
                    Data.Avatar = img.base64;
                } else {
                    Data = { ...Data, Avatar: img.base64 };
                }
                this.setState({ ...this.state, Data });
            });
        });
    }

    showQR = () => {
        const { Material } = this.state;
        const el = document.getElementById('qr-code');
        if (el) {
            el.innerHTML = '';
            if (this.props.inventoryLocationMaterialDialog.type !== 'new' && Material) {
                const codeWriter = new BrowserQRCodeSvgWriter();
                // const hints = new Map();
                // const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_39, BarcodeFormat.EAN_13/*, ...*/];
                // hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
                // codeWriter.setHints(hints);
                codeWriter.writeToDom('#qr-code', Material, 30, 30);
                codeWriter.writeToDom('#scannable', Material, 300, 300);
                window["warn"]('QR Code generated');
            }
        }
    }

    handleQR = () => {
        this.setState({ ...this.state, viewingQR: true }, () => setTimeout(this.showQR, 250));
    }

    closeQR = () => {
        this.setState({ ...this.state, viewingQR: false });
    }

    handlePart = (part) => {
        const { Cost, Material, Description, UOM, Data } = part;
        this.setState({ ...this.state, Material, Description, UOM, Cost, Data: { ...this.state.Data, Material: part, Avatar: Data && Data.Avatar ? Data.Avatar : null }, isScanning: false })
    }

    getPart = (barcode) => {
        const { Co, Material } = this.state;
        const request = axios.get(`${window["apiLocation"]}/api/Material?Co=${encodeURIComponent(Co)}&Material=${encodeURIComponent(barcode ? barcode : Material)}`).then((response) => {
            if (response.data) {
                this.handlePart(response.data);
            }
        });
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e);
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>close</Icon>
                </IconButton>
            </>
        )
    }

    ToolbarContents = () => {
        const { classes, inventoryLocationMaterialDialog } = this.props;
        let { viewingQR, Material } = this.state;

        return (
            <>
                {(this.props.inventoryLocationMaterialDialog.type !== 'new' && Material) &&
                    <React.Fragment>
                        <div id="qr-code" onClick={this.handleQR} className={classes.qrPreview}></div>
                        <Dialog
                            open={viewingQR}
                            onClose={this.closeQR}
                        >
                            <div id="scannable" className={classes.qr}></div>
                        </Dialog >
                    </React.Fragment>
                }
                <Typography variant="subtitle1" color="inherit">
                    {inventoryLocationMaterialDialog.type === 'new' ? 'New Inventory Material' : `Inventory Material #${this.state.Material}`}
                </Typography>
            </>
        )
    }

    render() {
        const { classes, securables, uoms, inventoryLocationMaterialDialog, addInventoryLocationMaterial, materials, updateInventoryLocationMaterial, removeInventoryLocationMaterial, costTypes, inventoryLocations, employees, qboCompany, qboMaterials } = this.props;
        let { InventoryLocationMaterial, ID, isScanning, viewingQR, Material } = this.state;
        const accessLevel = _.find(securables, { Securable: "inventory-locations" });

        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        window["warn"]("inventoryLocationDialog", Material, this.state.Description, inventoryLocationMaterialDialog.data)

        const dialogProps = {
            ...inventoryLocationMaterialDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "md",
            hideBackdrop: true,
            disableEnforceFocus: true
        }

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={null}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={`Inventory Material #${this.state.Material}`}
                        type="Inventory Location Material"
                        picRef={this.ref.current}
                        icon="store"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openPreloadInventoryLocationMaterialDialog}
                        description={this.state.Description}
                        storeDialog={inventoryLocationMaterialDialog}
                        TypeState={this.state.Material}
                        screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >
                        <BarcodeScanner open={isScanning} onScan={this.getPart} onClose={() => { this.setState({ ...this.state, isScanning: false, }) }} />

                        <DialogContent ref={this.ref} classes={{ root: "p-24" }}>
                            {(this.state.Data.Material) &&
                                <div className={classNames(classes.profile, "flex flex-col items-center justify-center")}>
                                    <Avatar style={{ cursor: 'pointer', boxShadow: '2px 2px 10px 2px #3f3f3f' }} className="w-1/4 h-auto" alt="Inventory Location Material Image" src={(this.state.Data && this.state.Data.Avatar ? (!isNaN(this.state.Data.Avatar) ? getProfileImage(`Co=${this.state.Co}&ID=${this.state.Data.Avatar}` ): this.state.Data.Avatar) : this.state.avatar ? this.state.avatar : avatar)} />
                                    <Typography variant="h6" color="inherit" className="p-16">
                                        {`${this.state.Description}`}
                                    </Typography>
                                </div>
                            }
                            <div className="sm:flex">
                                <div onClick={() => { this.setState({ ...this.state, isScanning: true }); }} className="min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20 cursor-pointer">
                                    <Icon color="error">crop_free</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Location"
                                    select
                                    id="location"
                                    name="Location"
                                    value={this.state.Location}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.Location ? true : false }}
                                    required
                                    disabled={Boolean(this.state.ID)}
                                    fullWidth
                                >
                                    {inventoryLocations && inventoryLocations.map((value, index) => (
                                        <MenuItem key={index} value={value.Location}>
                                            <div className="flex">
                                                {`${value.Description.substr(0, 30)}`}
                                            </div>
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <React.Fragment>
                                    {this.state.searching || this.state.Material ?
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Material"
                                            options={materials}
                                            searchButton={true}
                                            menuItemComponent={(value) => {
                                                return value.ActiveYN === "Y" &&
                                                    <MenuItem value={value.Material}>
                                                        <div className="flex">
                                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Material} src={(value.ImageID ? getProfileImage(`ID=${value.ImageID}&Thumb=true` ): avatar)} />
                                                            {`${value.Material} - ${(value.Description || '').substr(0, 50)}`}
                                                        </div>
                                                    </MenuItem>
                                            }}
                                            selectedItemComponent={(value) => (
                                                <MenuItem value={value.Material}>
                                                    <div className="flex">
                                                        {`${value.Material}`}
                                                    </div>
                                                </MenuItem>
                                            )}
                                            debounce={750}
                                            onChange={(text) => this.setState({ ...this.state, partDescription: text })}
                                            onBlur={() => { if (!this.state.Material) { this.setState({ ...this.state, searching: false }) } }}
                                            search={this.state.partDescription}
                                            value={this.state.Material}
                                            portal={true}
                                            noBlur={true}
                                            fullWidth
                                            noflip={true}
                                            onSelect={(option) => this.setState({ ...this.state, Material: option.Material, Description: option.Description, UOM: option.UOM, UnitCost: option.Cost, searching: false }, this.getPart)}
                                            required
                                        /> :
                                        <form className="w-full" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); setTimeout(() => this.setState({ searching: true }), 300) }}>
                                            <TextField
                                                className={classes.formControl}
                                                label="Material"
                                                id="partDescription"
                                                name="partDescription"
                                                value={this.state.partDescription || ''}
                                                onChange={this.handleChange}
                                                InputProps={{
                                                    endAdornment: !this.state.Material ?
                                                        <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: true, })} style={{ cursor: 'pointer' }}>search</Icon></InputAdornment>
                                                        :
                                                        <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: false, partDescription: null, Description: null, Material: null })} style={{ cursor: 'pointer' }}>close</Icon></InputAdornment>,
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                            />
                                        </form>
                                    }
                                </React.Fragment>
                                {/* <TextField
                                    className={classes.formControl}
                                    label="Material #"
                                    id="material"
                                    name="Material"
                                    value={this.state.Material}
                                    onChange={this.handleChange}
                                    onBlur={() => this.getPart()}
                                    InputLabelProps={{ shrink: this.state.Material ? true : false }}
                                    fullWidth
                                    variant="outlined"
                                    disabled={Boolean(this.state.ID)}
                                /> */}
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Bin Location"
                                    id="binLocation"
                                    name="BinLocation"
                                    value={this.state.BinLocation}
                                    onChange={this.handleChange}
                                    onBlur={() => this.getPart()}
                                    InputLabelProps={{ shrink: this.state.BinLocation ? true : false }}
                                    fullWidth
                                    variant="outlined"
                                />
                                {qboCompany &&
                                    <React.Fragment>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="QBO Material"
                                            options={qboMaterials}
                                            menuItemComponent={(value) =>
                                                <MenuItem value={value.Id}>
                                                    {`${value.Name}`}
                                                </MenuItem>
                                            }
                                            portal={true}
                                            value={this.state.QBOMaterial || ''}
                                            onSelect={(option) => { if (inventoryLocationMaterialDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOMaterial: option.Id }) } else { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOMaterial: option.Id }) } }}
                                        // required
                                        />
                                    </React.Fragment>
                                }
                            </div>

                            <div className="sm:flex">
                                <div className="min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20 cursor-pointer">
                                    <Icon color="action">chat_bubble_outline</Icon>
                                </div>
                                <div className="sm:flex w-full">
                                    <div className="sm:flex sm:w-1/4">
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Unit of Measure"
                                            options={uoms}
                                            menuItemComponent={(value) => {
                                                return value.ActiveYN === "Y" &&
                                                    <MenuItem value={value.UOM}>
                                                        <div className="flex">
                                                            {`${value.UOM} - ${value.Description}`}
                                                        </div>
                                                    </MenuItem>
                                            }}
                                            value={this.state.UOM}
                                            noflip={true}
                                            onSelect={(option) => this.setState({ ...this.state, UOM: option.UOM })}
                                            required
                                            portal={true}
                                            disabled={true}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="sm:flex sm:w-3/4">
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.Description ? true : false }}
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">attach_money</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Cost"
                                    id="cost"
                                    name="Cost"
                                    value={this.state.Cost}
                                    onChange={this.handleChange}
                                    onBlur={this.formatCost}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        type: "number"
                                    }}
                                    InputLabelProps={{ shrink: this.state.Cost ? true : false }}
                                    variant="outlined"
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Price Method"
                                    select
                                    id="priceMethod"
                                    name="PriceMethod"
                                    value={this.state.PriceMethod}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.PriceMethod ? true : false }}
                                    fullWidth
                                    required
                                >
                                    <MenuItem value="M">
                                        Markup %
                                    </MenuItem>
                                    <MenuItem value="F">
                                        Fixed Price
                                    </MenuItem>
                                </TextField>
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>

                                {this.state.PriceMethod === 'M' &&
                                    <TextField
                                        className={classes.formControl}
                                        label="Markup %"
                                        id="markupPercent"
                                        name="MarkupPercent"
                                        value={this.state.MarkupPercent}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        required
                                        fullWidth
                                    />
                                }

                                {this.state.PriceMethod === 'F' &&
                                    <TextField
                                        className={classes.formControl}
                                        label="Price"
                                        id="price"
                                        name="Price"
                                        value={this.state.Price}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                }

                            </div>


                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">format_list_numbered</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Stock Qty"
                                    id="stockQty"
                                    name="StockQty"
                                    value={this.state.StockQty}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        type: "number"
                                    }}
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Low Stock Qty"
                                    id="lowStockQty"
                                    name="LowStockQty"
                                    value={this.state.LowStockQty}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        type: "number"
                                    }}
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Reorder Qty"
                                    id="reOrderQty"
                                    name="ReOrderQty"
                                    value={this.state.ReOrderQty}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        type: "number"
                                    }}
                                    required
                                    fullWidth
                                />

                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">note</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        style={{ float: 'left', }}
                                        control={
                                            <Switch
                                                checked={this.state.StockedYN === 'Y'}
                                                name="StockedYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Stocked?"
                                    />
                                </div>
                                <div className="w-1/2">
                                    <FormControlLabel
                                        style={{ float: 'right', }}
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {inventoryLocationMaterialDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addInventoryLocationMaterial(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateInventoryLocationMaterial(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const { Co, openEditGpsInventoryTransferListDialog } = this.props;
                                            const { Material, Description, Location } = this.state;
                                            openEditGpsInventoryTransferListDialog({Co, Material, Description, Location})
                                        }}
                                        //disabled={!this.canBeSubmitted()}
                                    >
                                        Add to Inventory Transfer
                                    </Button>
                                    </>
                                )}
                            </DialogActions>
                        }
                    </DraggableDialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditInventoryLocationMaterialDialog: Actions.closeEditInventoryLocationMaterialDialog,
        closeNewInventoryLocationMaterialDialog: Actions.closeNewInventoryLocationMaterialDialog,
        addInventoryLocationMaterial: Actions.addInventoryLocationMaterial,
        updateInventoryLocationMaterial: Actions.updateInventoryLocationMaterial,
        removeInventoryLocationMaterial: Actions.removeInventoryLocationMaterial,
        openPreloadInventoryLocationMaterialDialog: Actions.openPreloadInventoryLocationMaterialDialog,
        //openNewGpsInventoryTransferDialog,
        openEditGpsInventoryTransferListDialog,
    }, dispatch);
}

function mapStateToProps({ inventoryLocationMaterialsApp, workOrdersApp, workOrderScopesApp, spReducers }) {
    return {
        inventoryLocationMaterialDialog: inventoryLocationMaterialsApp.inventoryLocationMaterials.inventoryLocationMaterialDialog,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User.UserName,
        costTypes: spReducers.costTypes,
        rateTemplates: spReducers.rateTemplates,
        employees: spReducers.employees,
        inventoryLocations: spReducers.inventoryLocations,
        securables: spReducers.userProfiles.User.Data.Securables,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboMaterials: spReducers.qboSettings.materials,
        uoms: spReducers.uoms,
        materials: spReducers.materials.materials
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(InventoryLocationMaterialDialog));
