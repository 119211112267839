import React, { Component } from 'react';
import { TextField, Button, Avatar, Icon, Typography, List } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import TagCategory from './categories/TagCategory';
import _ from '@lodash';

class Tags extends Component {
    state = {
        message: ''
    };

    render() {
        const { tags } = this.props;
        window["warn"](tags);
        return (
            <div className="mb-24">
                <div className="flex items-center">
                    <Icon className="text-24 mr-8" color="primary">label</Icon>
                    <Typography className="font-600 text-16">Tags</Typography>
                </div>
                {tags && tags.length > 0 && (
                    <div className="mb-24">
                        <List className="">
                            {
                                tags.map((item, index) => {
                                    return (
                                        <TagCategory
                                            tags={item.Tags}
                                            category={item.Title}
                                            icon={item.Icon}
                                            key={item.Title}
                                        />
                                    );
                                })
                            }
                        </List>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}

export default connect(mapStateToProps)(Tags);
