import {combineReducers} from 'redux';
import checklists from './checklists.reducer';
import user from './user.reducer';

const checklistsAppReducers = combineReducers({
    checklists,
    user
});

export default checklistsAppReducers;
