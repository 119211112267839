import axios from 'axios/index';
import { getUserData } from 'main/content/apps/form-of-payments/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_FORM_OF_PAYMENTS = '[FORM OF PAYMENTS APP] GET FORM OF PAYMENTS';
export const SET_SEARCH_TEXT = '[FORM OF PAYMENTS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_FORM_OF_PAYMENTS = '[FORM OF PAYMENTS APP] TOGGLE IN SELECTED FORM OF PAYMENTS';
export const SELECT_ALL_FORM_OF_PAYMENTS = '[FORM OF PAYMENTS APP] SELECT ALL FORM OF PAYMENTS';
export const DESELECT_ALL_FORM_OF_PAYMENTS = '[FORM OF PAYMENTS APP] DESELECT ALL FORM OF PAYMENTS';
export const OPEN_NEW_FORM_OF_PAYMENT_DIALOG = '[FORM OF PAYMENTS APP] OPEN NEW FORM OF PAYMENT DIALOG';
export const CLOSE_NEW_FORM_OF_PAYMENT_DIALOG = '[FORM OF PAYMENTS APP] CLOSE NEW FORM OF PAYMENT DIALOG';
export const OPEN_EDIT_FORM_OF_PAYMENT_DIALOG = '[FORM OF PAYMENTS APP] OPEN EDIT FORM OF PAYMENT DIALOG';
export const CLOSE_EDIT_FORM_OF_PAYMENT_DIALOG = '[FORM OF PAYMENTS APP] CLOSE EDIT FORM OF PAYMENT DIALOG';
export const ADD_FORM_OF_PAYMENT = '[FORM OF PAYMENTS APP] ADD FORM OF PAYMENT';
export const UPDATE_FORM_OF_PAYMENT = '[FORM OF PAYMENTS APP] UPDATE FORM OF PAYMENT';
export const REMOVE_FORM_OF_PAYMENT = '[FORM OF PAYMENTS APP] REMOVE FORM OF PAYMENT';
export const REMOVE_FORM_OF_PAYMENTS = '[FORM OF PAYMENTS APP] REMOVE FORM OF PAYMENTS';
export const TOGGLE_STARRED_FORM_OF_PAYMENT = '[FORM OF PAYMENTS APP] TOGGLE STARRED FORM OF PAYMENT';
export const TOGGLE_STARRED_FORM_OF_PAYMENTS = '[FORM OF PAYMENTS APP] TOGGLE STARRED FORM OF PAYMENTS';
export const SET_FORM_OF_PAYMENTS_STARRED = '[FORM OF PAYMENTS APP] SET FORM OF PAYMENTS STARRED ';

export function getFormOfPayments(routeParams) {
    const request = axios.get('/api/formOfPayments-app/formOfPayments', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_FORM_OF_PAYMENTS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedFormOfPayments(formOfPaymentId) {
    return {
        type: TOGGLE_IN_SELECTED_FORM_OF_PAYMENTS,
        formOfPaymentId
    }
}


export function selectAllFormOfPayments() {
    return {
        type: SELECT_ALL_FORM_OF_PAYMENTS
    }
}

export function deSelectAllFormOfPayments() {
    return {
        type: DESELECT_ALL_FORM_OF_PAYMENTS
    }
}


export function openNewFormOfPaymentDialog() {
    return {
        type: OPEN_NEW_FORM_OF_PAYMENT_DIALOG
    }
}

export function closeNewFormOfPaymentDialog() {
    return {
        type: CLOSE_NEW_FORM_OF_PAYMENT_DIALOG
    }
}

export function openEditFormOfPaymentDialog(data) {
    return {
        type: OPEN_EDIT_FORM_OF_PAYMENT_DIALOG,
        data
    }
}

export function closeEditFormOfPaymentDialog() {
    return {
        type: CLOSE_EDIT_FORM_OF_PAYMENT_DIALOG
    }
}

export function addFormOfPayment(newFormOfPayment) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post(`${window["apiLocation"]}/api/FormOfPayment`,
            newFormOfPayment
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_FORM_OF_PAYMENT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form of Payment ${newFormOfPayment.FormOfPayment} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateFormOfPayment(formOfPayment) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.put(`${window["apiLocation"]}/api/FormOfPayment?Co=${encodeURIComponent(formOfPayment.Co)}&BusinessUnit=${encodeURIComponent(formOfPayment.BusinessUnit)}&FormOfPayment=${encodeURIComponent(formOfPayment.FormOfPayment)}`,
            formOfPayment
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_FORM_OF_PAYMENT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form of Payment ${formOfPayment.FormOfPayment} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeFormOfPayment(formOfPaymentId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post('/api/formOfPayments-app/remove-formOfPayment', {
            formOfPaymentId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FORM_OF_PAYMENT
                })
            ]).then(() => dispatch(getFormOfPayments(routeParams)))
        );
    };
}


export function removeFormOfPayments(formOfPaymentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post('/api/formOfPayments-app/remove-formOfPayments', {
            formOfPaymentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FORM_OF_PAYMENTS
                }),
                dispatch({
                    type: DESELECT_ALL_FORM_OF_PAYMENTS
                })
            ]).then(() => dispatch(getFormOfPayments(routeParams)))
        );
    };
}

export function toggleStarredFormOfPayment(formOfPaymentId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post('/api/formOfPayments-app/toggle-starred-formOfPayment', {
            formOfPaymentId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_FORM_OF_PAYMENT
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFormOfPayments(routeParams)))
        );
    };
}

export function toggleStarredFormOfPayments(formOfPaymentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post('/api/formOfPayments-app/toggle-starred-formOfPayments', {
            formOfPaymentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_FORM_OF_PAYMENTS
                }),
                dispatch({
                    type: DESELECT_ALL_FORM_OF_PAYMENTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFormOfPayments(routeParams)))
        );
    };
}

export function setFormOfPaymentsStarred(formOfPaymentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post('/api/formOfPayments-app/set-formOfPayments-starred', {
            formOfPaymentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_FORM_OF_PAYMENTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_FORM_OF_PAYMENTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFormOfPayments(routeParams)))
        );
    };
}

export function setFormOfPaymentsUnstarred(formOfPaymentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formOfPaymentsApp.formOfPayments;

        const request = axios.post('/api/formOfPayments-app/set-formOfPayments-unstarred', {
            formOfPaymentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_FORM_OF_PAYMENTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_FORM_OF_PAYMENTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFormOfPayments(routeParams)))
        );
    };
}
