import _ from '@lodash';
import { Avatar, Button, Chip, Divider, DialogActions, FormControlLabel, Icon, InputAdornment, MenuItem, Slide, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import Autocomplete from 'main/content/components/autocomplete/Autocomplete';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { openEditInvoiceDialog } from 'main/content/apps/invoices/store/actions';
import { openEditCustomerDialog } from 'main/content/apps/customers/store/actions';
import { openEditCustomerSiteDialog } from 'main/content/apps/customer-sites/store/actions';
import { openEditTechnicianDialog } from 'main/content/apps/technicians/store/actions';
import { openEditPurchaseOrderDialog } from 'main/content/apps/purchase-orders/store/actions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { guid, showMessage } from 'store/actions';
import format from 'string-template';
import moment from 'moment';
import getProfileImage from '../../../../functions/getProfileImageUrl';

const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}
const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
    },
    dialogRoot: {

    },
    profileAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    columnButton: {
        '& .edit': {
            display: 'none',
        },
        '&:hover .edit': {
            display: 'block',
        }
    }
});

const newPivotTableState = {
    results: [],
    columns: [],
    selectedColumns: [],
    pivotBy: [],
    calculations: [],
    calculation: {
        ID: null,
        Title: null,
        Expression: null,
        format: null
    },
    selectedPivot: null,
    selectedColumn: null,
}

class PivotTable extends Component {

    state = {
        ..._.cloneDeepWith(newPivotTableState)
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //     const { results, options } = this.props;
    //     return !_.isEqual(results, nextProps.results) || !_.isEqual(options, nextProps.options)
    // }

    componentDidMount() {
        const { results, options } = this.props;
        if (results && !options) {
            const columns = this.getColumns(results);
            this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, columns });
        } else {
            if (options && results) {
                const { Options } = options;
                const pivot = this.buildPivot(results, Options);
                this.setState({ ...this.state, results, ...pivot });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { results, options } = this.props;
        if (!_.isEqual(results, prevProps.results) || !_.isEqual(options, prevProps.options)) {
            if (results && !options) {
                const columns = this.getColumns(results);
                this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, columns });
            } else {
                if (options && results) {
                    const { Options } = options;
                    const pivot = this.buildPivot(results, Options);
                    this.setState({ ...this.state, results, ...pivot });
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({ ..._.cloneDeepWith(newPivotTableState) });
    }

    buildPivot = (data, options) => {
        const Options = JSON.parse(options);
        const { pivotBy, calculations, columnOptions } = Options;
        const columns = this.getColumns(data, columnOptions, pivotBy, calculations);
        const selectedColumns = _.orderBy(_.filter(columns, (o) => { return _.findIndex(columnOptions, { id: o.id }) > -1 }), ['displayIndex'], ['asc']);
        return {
            pivotBy,
            columns,
            selectedColumns,
            calculations,
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    savePivotTable = () => {
        const { selectedColumns, pivotBy, calculations } = _.cloneDeepWith(this.state);
        const columnOptions = [];
        selectedColumns.map((col) => {
            const { id, Header, format, dataType, aggregation, show, width, isCalculation, displayIndex } = col;
            columnOptions.push({
                id,
                Header,
                dataType,
                format,
                aggregation,
                show,
                width,
                isCalculation,
                displayIndex
            });
        });
        const options = {
            columnOptions,
            pivotBy,
            calculations,
        };

        if (this.props.onAdded) {
            if (!this.props.options || !this.props.options.ID || !this.props.options.Component) {
                this.props.onAdded(JSON.stringify(options));
            }
        }

        if (this.props.onUpdated) {
            if (this.props.options && this.props.options.ID && this.props.options.Component) {
                this.props.onUpdated(JSON.stringify(options));
            }
        }
        // window["warn"](options);
        // return options;
    }

    getColumns(data, columnOptions, pivotBy, calculations) {
        const columns = Object.keys(data[0]).map(key => {
            return {
                Header: key,
                headerClassName: 'font-bold text-12',
                accessor: key,
                id: key,
                dataType: !isNaN(data[0][key]) ? 'number' : moment(data[0][key]).isValid() ? 'date' : 'text'
            };
        });
        if (calculations) {
            calculations.map((calculation) => {
                const col = {
                    Header: calculation.Title,
                    id: calculation.ID,
                    Cell: rowInfo => {
                        if (rowInfo.original) {
                            if (!calculation.applyTo || calculation.applyTo === 'O') {
                                const { row, original } = rowInfo;
                                const exp = `${format(calculation.Expression, original)}`;
                                const val = loose(exp);
                                row[calculation.id] = val;
                                return <div className={`w-full text-12 ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        } else {
                            if (!calculation.applyTo || calculation.applyTo === 'A') {
                                const { row } = rowInfo;
                                const exp = `${format(calculation.Expression, row)}`;
                                const val = loose(exp);
                                row[calculation.id] = val;
                                return <div className={`w-full text-12 font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        }
                    },
                    accessor: row => {
                        return row[calculation.id];
                    },
                    aggregate: vals => 0,
                    headerClassName: 'font-bold text-12',
                    isCalculation: true,
                };
                columns.push(col);
            })
        }
        if (pivotBy) {
            pivotBy.map((piv) => {
                const column = _.find(columns, { id: piv });
                if (column) {
                    column.aggregate = (vals) => '';
                    if (['Technician', 'TeamLead', 'TeamMember'].indexOf(column.id) > -1) {
                        // column.disableExpander = true;
                        // column.accessor = row => this.getTechnicianName(row[column.id]);
                        column.Pivot = ({ value }) => <div className="font-bold text-12">{this.getTechnician(value)}</div>;
                    } else if (['WorkOrder', 'WO'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: this.props.Co, WorkOrder: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['Invoice', 'InvoiceNumber'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditInvoiceDialog({ Co: this.props.Co, InvoiceNumber: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['Customer', 'Cust', 'CustNum'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditCustomerDialog({ Co: this.props.Co, Customer: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['PO', 'PONumber', 'PurchaseOrder'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditPurchaseOrderDialog({ Co: this.props.Co, PO: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else {
                        column.Pivot = ({ value }) => {
                            return <div className={`w-full font-bold text-12 ${column.dataType !== 'text' ? 'text-center ' : ''}`}>{column.dataType === 'date' ? this.getFormattedDate(value, column.format) : value}</div>
                        };
                    }
                }
            });
        };
        if (columnOptions) {
            columnOptions.map((col) => {
                const { id, Header, format, dataType, aggregation, show, width, isCalculation, displayIndex } = col;
                const column = _.find(columns, { id });
                if (column) {
                    column.Header = Header;
                    if (!isCalculation && !column.Pivot) {
                        column.show = show;
                        column.aggregation = aggregation;
                        column.format = format;
                        column.aggregate = (vals) => { return aggregation ? _.round(_[aggregation](vals), 2) : '' };
                        column.Aggregated = cellInfo => {
                            return <div className={`w-full font-bold text-12 ${dataType === 'number' ? 'text-center ' : ''}`}>{column.format === 'D' ? this.formatDollars(cellInfo.value) : column.format === 'P' ? this.formatPercentage(cellInfo.value) : cellInfo.value}</div>
                        }
                        column.Cell = row => {
                            return (
                                <div className={`w-full text-12 ${dataType === 'number' ? 'text-center ' : ''}`}>{column.format === 'D' ? this.formatDollars(row.value) : column.format === 'P' ? this.formatPercentage(row.value) : row.value}</div>
                            )
                        }
                    }
                    column.displayIndex = displayIndex;
                }
            });
        }
        return columns;
    }

    getFormattedDate = (date, format) => {
        let formatted = date;
        const dt = moment(date);
        switch (format) {
            case 'D': {
                formatted = moment(date).format("MM/DD/YYYY")
            }
                break;
            case 'Y': {
                formatted = date
            }
                break;
            case 'Q': {
                formatted = `Q${dt.quarter()} ${dt.format('YYYY')}`
            }
                break;
            case 'M': {
                formatted = `${dt.format("MMMM")} ${dt.format("YYYY")}`
            }
                break;
            case 'W': {
                formatted = `Week #${dt.isoWeek()} ${dt.format("YYYY")}`
            }
                break;
            case 'DW': {
                formatted = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date]
            }
                break;
            case 'T': {
                formatted = dt.format("h:mm A")
            }
                break;
            case 'H': {
                formatted = dt.startOf("hour").format("h:mm A")
            }
                break;
        }
        return formatted
    }

    getDateAccessor = (date, format) => {
        let formatted = date;
        const dt = moment(date);
        switch (format) {
            case 'D': {
                formatted = new Date(moment(date).format("MM/DD/YYYY")).toISOString()
            }
                break;
            case 'Y': {
                formatted = dt.format("YYYY")
            }
                break;
            case 'Q': {
                formatted = new Date(`${dt.startOf('quarter').format("MM/DD/YYYY")}`).toISOString()
            }
                break;
            case 'M': {
                formatted = new Date(`${dt.startOf('month').format("MM/DD/YYYY")}`).toISOString()
            }
                break;
            case 'W': {
                formatted = new Date(dt.startOf('week').format("MM/DD/YYYY")).toISOString()
            }
                break;
            case 'DW': {
                formatted = dt.day()
            }
                break;
            case 'T': {
                formatted = new Date(`1/1/1900 ${dt.local().format("h:mm A")}`).toISOString()
            }
                break;
            case 'H': {
                formatted = new Date(`1/1/1900 ${dt.local().startOf("hour").format("h:mm A")}`).toISOString()
            }
                break;
        }
        return formatted
    }

    addUpdatePivot = (column) => {
        const { columns, pivotBy, selectedColumns } = _.cloneDeepWith(this.state);
        const pivot = _.findIndex(pivotBy, (o) => { return o === column.id });
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected < 0) {
            column.aggregate = (vals) => '';
            if (['Technician', 'TeamLead', 'TeamMember'].indexOf(column.id) > -1) {
                // column.disableExpander = true;
                // column.accessor = row => this.getTechnicianName(row[column.id]);
                column.Pivot = ({ value }) => <div className="font-bold text-12">{this.getTechnician(value)}</div>;
            } else if (['WorkOrder', 'WO'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: this.props.Co, WorkOrder: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else if (['Invoice', 'InvoiceNumber'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditInvoiceDialog({ Co: this.props.Co, InvoiceNumber: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else if (['Customer', 'Cust', 'CustNum', 'Cust #', 'Customer #'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditCustomerDialog({ Co: this.props.Co, Customer: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else if (['PO', 'PONumber', 'PurchaseOrder'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditPurchaseOrderDialog({ Co: this.props.Co, PO: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else {
                column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 ${column.dataType !== 'text' ? 'text-center ' : ''}`}>{column.dataType === 'date' ? this.getFormattedDate(value, column.format) : value}</div>;
            }
            selectedColumns.push(column);
        } else {
            selectedColumns.splice(selected, 1, column);
        }
        if (pivot < 0) {
            pivotBy.push(column.id);
        }
        this.setState({ ...this.state, pivotBy, selectedColumns, selectedPivot: null });
    }

    removePivot = (column) => {
        const { columns, pivotBy, selectedColumns } = _.cloneDeepWith(this.state);
        const pivot = _.findIndex(pivotBy, (o) => { return o === column.id });
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected > -1) {
            selectedColumns.splice(selected, 1);
        }
        if (pivot > -1) {
            pivotBy.splice(pivot, 1);
        }
        this.setState({ ...this.state, pivotBy, selectedColumns, selectedPivot: null });
    }

    addUpdateColumn = (column) => {
        const { columns, selectedColumns } = _.cloneDeepWith(this.state);
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected < 0) {
            if (!column.isCalculation) {
                column.show = true;
                column.aggregation = column.dataType === 'number' ? 'sum' : null;
                column.aggregate = (vals) => { return column.aggregation ? _.round(_[column.aggregation](vals), 2) : '' };
                column.Aggregated = cellInfo => {
                    return <div className={`w-full font-bold text-12 ${column.dataType === 'number' ? 'text-center ' : ''}`}>{column.format === 'D' ? this.formatDollars(cellInfo.value) : column.format === 'P' ? this.formatPercentage(cellInfo.value) : cellInfo.value}</div>
                }
                column.Cell = row => {
                    return (
                        <div className={`w-full text-12 ${column.dataType === 'number' ? 'text-center ' : ''}`}>{column.format === 'D' ? this.formatDollars(row.value) : column.format === 'P' ? this.formatPercentage(row.value) : row.value}</div>
                    )
                }
            }
            column.displayIndex = selectedColumns.length;
            selectedColumns.push(column);
        } else {
            selectedColumns.splice(selected, 1, column);
        }
        this.setState({ ...this.state, selectedColumns, selectedColumn: null });
    }



    removeColumn = (column) => {
        const { columns, selectedColumns, calculations } = _.cloneDeepWith(this.state);
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected > -1) {
            let hasCalculation = false;
            calculations.map((calc) => {
                if (calc.Expression.indexOf(column.id) > -1) {
                    hasCalculation = true;
                }
            });
            if (!hasCalculation) {
                selectedColumns.splice(selected, 1);
            } else {
                this.props.showMessage({
                    message: `Unable to remove column - Column is being used by one or more calculations.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            }
        }
        this.setState({ ...this.state, selectedColumns, selectedColumn: null });
    }

    addCalculation = (calculation) => {
        const { calculations, columns, selectedColumns } = _.cloneDeepWith(this.state);
        calculation.ID = guid();
        calculations.push(calculation);
        const col = {
            Header: calculation.Title,
            id: calculation.ID,
            Cell: rowInfo => {
                if (rowInfo.original) {
                    if (!calculation.applyTo || calculation.applyTo === 'O') {
                        const { row, original } = rowInfo;
                        const exp = `${format(calculation.Expression, original)}`;
                        const val = loose(exp);
                        
                        row[calculation.id] = val;
                        return <div className={`w-full text-12 ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                    } else {
                        return <div></div>
                    }
                } else {
                    if (!calculation.applyTo || calculation.applyTo === 'A') {
                        const { row } = rowInfo;
                        const exp = `${format(calculation.Expression, row)}`;
                        const val = loose(exp);
                        row[calculation.id] = val;
                        return <div className={`w-full text-12 font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                    } else {
                        return <div></div>
                    }
                }
            },
            accessor: row => {
                return row[calculation.id];
            },
            aggregate: vals => 0,
            headerClassName: 'font-bold text-12',
            isCalculation: true,
        };
        columns.push(col);
        this.setState({ ...this.state, calculation: { ID: null, Title: null, Expression: null, format: null, applyTo: null }, calculations, selectedColumns });
    }

    updateCalculation = (calculation) => {
        const { calculations, columns, selectedColumns } = _.cloneDeepWith(this.state);
        const index = _.findIndex(calculations, { ID: calculation.ID });
        if (index > -1) {
            calculations.splice(index, 1, calculation);
            const colIndex = _.findIndex(columns, { id: calculation.ID });
            if (colIndex > -1) {
                const col = columns[colIndex];
                col.Header = calculation.Title;
                col.Cell = rowInfo => {
                    if (rowInfo.original) {
                        if (!calculation.applyTo || calculation.applyTo === 'O') {
                            const { row, original } = rowInfo;
                            const exp = `${format(calculation.Expression, original)}`;
                            const val = loose(exp);
                            row[calculation.id] = val;
                            return <div className={`w-full text-12 ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                        } else {
                            return <div></div>
                        }
                    } else {
                        if (!calculation.applyTo || calculation.applyTo === 'A') {
                            const { row } = rowInfo;
                            const exp = `${format(calculation.Expression, row)}`;
                            const val = loose(exp);
                            row[calculation.id] = val;
                            return <div className={`w-full text-12 font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                        } else {
                            return <div></div>
                        }
                    }
                };
                col.accessor = row => {
                    return col.id;
                };
                col.aggregate = vals => 0;
                const selIndex = _.findIndex(selectedColumns, { id: calculation.ID });
                if (selIndex > -1) {
                    const sel = selectedColumns[selIndex];
                    sel.Header = calculation.Title;
                    sel.Cell = rowInfo => {
                        if (rowInfo.original) {
                            if (!calculation.applyTo || calculation.applyTo === 'O') {
                                const { row, original } = rowInfo;
                                const exp = `${format(calculation.Expression, original)}`;
                                const val = loose(exp);
                                row[calculation.id] = val;
                                return <div className={`w-full text-12 ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        } else {
                            if (!calculation.applyTo || calculation.applyTo === 'A') {
                                const { row } = rowInfo;
                                const exp = `${format(calculation.Expression, row)}`;
                                const val = loose(exp);
                                row[calculation.id] = val;
                                return <div className={`w-full text-12 font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        }
                    };
                    sel.accessor = row => {
                        return sel.id;
                    };
                    sel.aggregate = vals => 0;
                }
            }
        }
        this.setState({ ...this.state, calculation: {}, calculations, columns, selectedColumns });
    }

    removeCalculation = (calculation) => {
        const { calculations, columns, selectedColumns } = _.cloneDeepWith(this.state);
        const index = _.findIndex(calculations, { ID: calculation.ID });
        if (index > -1) {
            const colIndex = _.findIndex(columns, { id: calculation.ID });
            if (colIndex > -1) {
                const selIndex = _.findIndex(selectedColumns, { id: calculation.ID });
                if (selIndex > -1) {
                    selectedColumns.splice(selIndex, 1);
                }
                columns.splice(colIndex, 1);
            }
            calculations.splice(index, 1);
        }
        this.setState({ ...this.state, calculation: {}, calculations, columns, selectedColumns });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (!isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    getTechnician = (Technician) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return (
                <span className="flex">
                    <Avatar style={{ marginRight: 8, marginLeft: 8, width: 24, height: 24, }} classes={{ root: classes.avatarRoot }} className={classes.profileAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                    <div className="mt-6">{`${value.FirstName} ${value.LastName}`}</div>
                    <Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditTechnicianDialog({ Co: this.props.Co, Technician }) }} className="ml-4 text-12 font-bold align-middle mt-8">open_in_new</Icon></Tooltip>
                </span>
            )
        } else {
            return Technician;
        }
    }

    reorderColumns = (result) => {
        const { source, destination } = result;
        if (source && destination) {
            if (source.index !== destination.index) {

                const { selectedColumns } = _.cloneDeepWith(this.state);

                const ordered = this.reorder(
                    selectedColumns,
                    result.source.index,
                    result.destination.index
                );

                for (var i = 0; i < ordered.length; i++) {
                    ordered[i].displayIndex = i;
                    // this.addUpdateColumn(ordered[i]);
                }
                this.setState({ ...this.state, selectedColumns: ordered });
            }
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    expandAll = () => {
        let { selectedColumns } = _.cloneDeepWith(this.state);
        const expanded = selectedColumns.map(x => true);
        this.setState({ ...this.state, expanded });
    }

    collapseAll = () => {
        let { selectedColumns } = _.cloneDeepWith(this.state);
        const expanded = selectedColumns.map(x => false);
        this.setState({ ...this.state, expanded });
    }

    render() {
        const { classes, edit, dialog, filters } = this.props;
        const { results, columns, selectedColumns, pivotBy, calculations, calculation, selectedColumn, selectedPivot, expanded } = this.state;
        return (
            <React.Fragment>
                <div className="w-full">
                    {edit &&
                        <div className="w-full sm:flex">
                            <div className="w-full mb-12">
                                <Typography className="mb-4 w-full font-bold text-12">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">format_list_bulleted</Icon>Rows
                                </Typography>
                                <Autocomplete
                                    className="w-full mb-12"
                                    title="Pivot Rows"
                                    options={_.filter(columns, (o) => { return !o.isCalculation })}
                                    menuItemComponent={(option) => {
                                        return (
                                            <MenuItem value={option.id}>
                                                <div className="flex">
                                                    {option.Header}
                                                </div>
                                            </MenuItem>
                                        );
                                    }}
                                    portal={true}
                                    value={selectedPivot ? selectedPivot.id : null}
                                    onSelect={(option) => this.addUpdatePivot(option)}
                                />
                                {selectedPivot &&
                                    <React.Fragment>
                                        <TextField
                                            className={classes.formControl}
                                            label="Title"
                                            id="title"
                                            name="selectedPivot.Header"
                                            value={selectedPivot.Header || ''}
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <Button
                                            variant="contained"
                                            className={classNames(classes.black, "mb-12")}
                                            color="primary"
                                            onClick={() => this.addUpdatePivot(selectedPivot)}
                                            fullWidth
                                        >
                                            Update
                                        </Button>
                                    </React.Fragment>
                                }
                                {pivotBy.map((value) => {
                                    const column = _.find(columns, { id: value });
                                    if (column) {
                                        return (
                                            <Chip key={value} onClick={() => this.setState({ ...this.state, selectedPivot: column })} deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removePivot(column)} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classes.colorSecondary}>arrow_right</Icon>} label={<span>{column.Header}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>} />
                                        );
                                    }
                                })}
                            </div>
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <div className="w-full mb-12">
                                <Typography className="mb-4 w-full font-bold text-12">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">format_list_numbered</Icon>Columns
                                </Typography>
                                <Autocomplete
                                    className="w-full mb-12"
                                    title="Column"
                                    options={_.filter(columns, (o) => { return _.findIndex(pivotBy, (p) => { return o.id === p }) < 0 })}
                                    menuItemComponent={(option) => {
                                        return (
                                            <MenuItem value={option.id}>
                                                <div className="flex">
                                                    {option.Header || option.id}
                                                </div>
                                            </MenuItem>
                                        );
                                    }}
                                    portal={true}
                                    value={selectedColumn ? selectedColumn.id : null}
                                    onSelect={(option) => this.addUpdateColumn(option)}
                                />
                                {
                                    selectedColumn &&
                                    <React.Fragment>
                                        <TextField
                                            className={classes.formControl}
                                            label="Title"
                                            id="title"
                                            name="selectedColumn.Header"
                                            value={selectedColumn.Header || ''}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <TextField
                                            className={classes.formControl}
                                            label="Format"
                                            id="format"
                                            name="selectedColumn.format"
                                            value={selectedColumn.format || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value={null}>
                                                None
                                            </MenuItem>
                                            <MenuItem value="T">
                                                Text
                                            </MenuItem>
                                            <MenuItem value="P">
                                                Percentage
                                            </MenuItem>
                                            <MenuItem value="D">
                                                Dollars
                                            </MenuItem>
                                        </TextField>
                                        <TextField
                                            className={classes.formControl}
                                            label="Aggregation Type"
                                            id="aggregation"
                                            name="selectedColumn.aggregation"
                                            value={selectedColumn.aggregation || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="sum">
                                                Sum
                                            </MenuItem>
                                            <MenuItem value="mean">
                                                Average
                                            </MenuItem>
                                            <MenuItem value="min">
                                                Minimum
                                            </MenuItem>
                                            <MenuItem value="max">
                                                Maximum
                                            </MenuItem>
                                        </TextField>
                                        <FormControlLabel
                                            // style={{ float: 'right', }}
                                            control={
                                                <Switch
                                                    checked={!selectedColumn.show}
                                                    name="selectedColumn.show"
                                                    onChange={() => { this.setState({ ...this.state, selectedColumn: { ...selectedColumn, show: !selectedColumn.show } }) }}
                                                    color="primary"
                                                />
                                            }
                                            label="Hidden"
                                        />
                                        <Button
                                            variant="contained"
                                            className={classNames(classes.black, "mb-12")}
                                            color="primary"
                                            onClick={() => this.addUpdateColumn(selectedColumn)}
                                            fullWidth
                                        >
                                            Update
                                        </Button>
                                    </React.Fragment>
                                }
                                <DragDropContext onDragEnd={this.reorderColumns}>
                                    <Droppable
                                        droppableId="columns"
                                        type="column"
                                        direction="vertical"
                                    // ignoreContainerClipping={800}
                                    >
                                        {(provided) => (
                                            <div ref={provided.innerRef}>
                                                {selectedColumns.map((value, index) => {
                                                    if (_.findIndex(pivotBy, (p) => { return value.id === p }) < 0) {
                                                        return (
                                                            <Draggable key={value.id} draggableId={value.id} index={value.displayIndex} type="column">
                                                                {(provided2, snapshot) => (
                                                                    <div
                                                                        ref={provided2.innerRef}
                                                                        {...provided2.draggableProps}
                                                                        {...provided2.dragHandleProps}
                                                                    >
                                                                        <Chip
                                                                            onClick={() => {
                                                                                if (!value.isCalculation) {
                                                                                    if (calculation && calculation.Title && calculation.Title.length > 0) {
                                                                                        let { Expression } = calculation;
                                                                                        window["warn"](calculation, Expression);
                                                                                        if (Expression) {
                                                                                            Expression += `{${value.id}}`;
                                                                                            window["warn"](calculation, Expression);
                                                                                        } else {
                                                                                            Expression = `{${value.id}}`;
                                                                                            window["warn"](calculation, 'No Expression: ', Expression);
                                                                                        }
                                                                                        this.setState({ ...this.state, calculation: { ...calculation, Expression } });
                                                                                    } else {
                                                                                        this.setState({ ...this.state, selectedColumn: value })
                                                                                    }
                                                                                } else {
                                                                                    this.setState({ ...this.state, calculation: _.find(calculations, { ID: value.id }) })
                                                                                }
                                                                            }}
                                                                            deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>}
                                                                            onDelete={() => this.removeColumn(value)}
                                                                            className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")}
                                                                            icon={<Icon className={classNames(!value.show && !value.isCalculation ? classes.colorError : classes.colorSecondary, "text-16")}>{value.isCalculation ? 'functions' : !value.show ? 'visibility_off' : 'format_list_numbered'}</Icon>}
                                                                            label={<span>{value.Header}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        )
                                        }
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <div className="w-full mb-12">
                                <Typography className="mb-4 w-full font-bold text-12">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">functions</Icon>Calculations
                                </Typography>
                                <TextField
                                    className={classes.formControl}
                                    label="Title"
                                    id="title"
                                    name="calculation.Title"
                                    value={calculation.Title || ''}
                                    inputProps={{
                                        autoComplete: "off"
                                    }}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                                {calculation.Title && calculation.Title.length > 0 &&
                                    <React.Fragment>
                                        <TextField
                                            className={classes.formControl}
                                            label="Expression"
                                            id="expression"
                                            name="calculation.Expression"
                                            value={calculation.Expression || ''}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">=</InputAdornment>,
                                            }}
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                        <TextField
                                            className={classes.formControl}
                                            label="Format"
                                            id="format"
                                            name="calculation.format"
                                            value={calculation.format || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value={null}>
                                                None
                                            </MenuItem>
                                            <MenuItem value="T">
                                                Text
                                            </MenuItem>
                                            <MenuItem value="P">
                                                Percentage
                                            </MenuItem>
                                            <MenuItem value="D">
                                                Dollars
                                            </MenuItem>
                                        </TextField>
                                        <TextField
                                            className={classes.formControl}
                                            label="Apply To"
                                            id="applyTo"
                                            name="calculation.applyTo"
                                            value={calculation.applyTo || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value={null}>
                                                All Rows
                                            </MenuItem>
                                            <MenuItem value="A">
                                                Aggregated Rows
                                            </MenuItem>
                                            <MenuItem value="O">
                                                Original Rows
                                            </MenuItem>
                                        </TextField>
                                        {!calculation.ID ?
                                            <Button
                                                variant="contained"
                                                className={classNames(classes.black, "mb-12")}
                                                color="primary"
                                                onClick={() => this.addCalculation(calculation)}
                                                fullWidth
                                                disabled={!calculation.Expression}
                                            >
                                                <Icon className={classNames("mr-4 text-20", calculation.Expression ? classes.colorSecondary : '')}>add_circle_outline</Icon>Add Calculation
                                            </Button> :
                                            <Button
                                                variant="contained"
                                                className={classNames(classes.black, "mb-12")}
                                                color="primary"
                                                onClick={() => this.updateCalculation(calculation)}
                                                fullWidth
                                                disabled={!calculation.Expression}
                                            >
                                                Update
                                            </Button>
                                        }

                                    </React.Fragment>
                                }
                                {calculations.map((value) => {
                                    return (
                                        <Tooltip key={value.ID} placement="top" title={`=${value.Expression}`}>
                                            <Chip deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removeCalculation(value)} onClick={() => this.setState({ ...this.state, calculation: { ...value } })} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>functions</Icon>} label={<span>{value.Title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>} />
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    <ReactTable
                        data={_.filter(results, filters)}
                        columns={[...selectedColumns]}
                        expanded={expanded ? [...selectedColumns] : undefined}
                        defaultPageSize={10}
                        // showPagination={false}
                        className={`-highlight -striped rounded ${!edit && !dialog ? 'pin-t pin-b pin-r pin-l absolute mt-32' : ''}`}
                        pivotBy={[...pivotBy]}
                        getTbodyProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    overflow: 'unset'
                                    // minHeight: 288
                                }
                            }
                        }}
                    />
                </div>
                {edit &&
                    <DialogActions className="dialog-actions justify-start p-0 m-0 mt-12">
                        {!this.props.options || !this.props.options.ID || !this.props.options.Component ?
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.savePivotTable}
                            >
                                Add
                            </Button> :
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.savePivotTable}
                            >
                                Update
                            </Button>
                        }
                    </DialogActions>
                }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditWorkOrderDialog,
        openEditInvoiceDialog,
        openEditCustomerDialog,
        openEditCustomerSiteDialog,
        openEditTechnicianDialog,
        openEditPurchaseOrderDialog,
        showMessage
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        employees: spReducers.employees,
        filters: dashboardBuilderApp.dashboard.filters
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PivotTable)));
