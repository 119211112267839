import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import RGL, { WidthProvider } from "react-grid-layout";
import _ from 'lodash';
import FormElementDialog from './FormElementDialog';
import FormElement from './FormElement';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import moment from 'moment';

const GridLayout = WidthProvider(RGL);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    formSection: {
        border: '1px dotted lightgrey',
        // padding: 8,
    },
    scrollEnable: {
        touchAction: 'unset !important',
    }

});

class FormSectionBuilder extends Component {

    state = {
        grabbing: false,
        needsUpdate: [],
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    handleLayout = (layout) => {
        const { form, onNeedsUpdate, data } = this.props;
        const needsUpdate = [];
        layout.map((item, i) => {
            let el = data[i];
            if (item.x !== el.PositionX || item.y !== el.PositionY || item.w !== el.Columns || item.h !== el.Rows) {
                needsUpdate.push({
                    ...el,
                    PositionX: item.x,
                    PositionY: item.y,
                    Columns: item.w,
                    Rows: item.h,
                });
            }
        });
        this.setState({ ...this.state, needsUpdate }, () => onNeedsUpdate(needsUpdate));
    }

    render() {
        const { Co, classes, data, scrollLock, editable, section } = this.props;
        const max = _.maxBy(data, (o) => { return o.PositionY + o.Rows });
        const height = max ? (max.PositionY + max.Rows) * 19 : 19;
        const rendered = [];
        return (
            <div className="w-full">
                {(scrollLock || !editable) &&
                    <div
                        className={classNames("layout pt-6 pb-6 w-full relative overflow-hidden")}
                        style={{ height }}
                    >
                        {
                            data.map((el, index) => {
                                el.PositionY = 0;
                                var clashX = _.filter(rendered, (o) => { return o.PositionX + o.Columns > el.PositionX });
                                var clashes = _.filter(clashX, (o) => { return o.PositionY + o.Rows > el.PositionY });
                                if (clashes.length > 0) {
                                    clashes.map((o) => {
                                        if (o.PositionY + o.Rows > el.PositionY) {
                                            el.PositionY = o.PositionY + o.Rows;
                                        }
                                    });
                                }
                                rendered.push(el);
                                return (
                                    <div
                                        key={index}
                                        className={classNames(`absolute ${/*el.PositionX > 0 ? "pl-6" : ""} ${el.PositionX + el.Columns < 11 ? "pr-6" : ""*/''}`, classes.scrollEnable)}
                                        style={{ height: el.Rows * 19, width: `calc(${((el.Columns / 12) * 100).toFixed(4)}% - ${(el.PositionX > 0 ? el.PositionX + el.Columns < 12 ? 6 : 8 : 0) + (el.PositionX + el.Columns < 11 ? 6 : 0)}px`, top: (el.PositionY * 19) + 6, left: `calc(${(el.PositionX / 12) * 100}% + ${(el.PositionX > 0 ? 6 : 0)}px)` }}
                                    >
                                        <FormElement scrollLock={scrollLock} onChange={this.props.onChange} el={{ ...el }} editable={editable} />
                                    </div>
                                );
                            })
                        }
                    </div>
                }
                {!scrollLock && editable &&
                    <GridLayout
                        className={classNames("layout pt-6 pb-6 w-full overflow-hidden")}
                        rowHeight={19}
                        cols={12}
                        compactType={"vertical"}
                        margin={[0, 0]}
                        isDraggable={!scrollLock && editable}
                        isResizable={!scrollLock && editable}
                        onDragStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                        onResizeStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                    >
                        {
                            data.map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={classNames("w-full pr-8", !scrollLock && classes.formSection, scrollLock && classes.scrollEnable)}
                                        data-grid={{ el, x: el.PositionX, y: el.PositionY, w: el.Columns, h: el.Rows, /*minH: ['DIV', 'IMG'].indexOf(el.Type) > -1 ? undefined : el.Rows, maxH: ['DIV', 'IMG'].indexOf(el.Type) > -1 ? undefined : el.Rows, */static: el.StaticYN === "Y" }}
                                    >
                                        <FormElement scrollLock={scrollLock} onChange={this.props.onChange} el={{ ...el }} editable={editable} />
                                    </div>
                                );
                            })
                        }
                    </GridLayout>
                }
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewFormElementDialog: Actions.openNewFormElementDialog,
        openEditFormElementDialog: Actions.openEditFormElementDialog,
    }, dispatch);
}

function mapStateToProps({ formsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        formData: spReducers.forms,
        searchText: formsApp.forms.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSectionBuilder)));
