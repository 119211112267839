import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import WorkOrderScopesDialog from 'main/content/apps/work-order-scopes/WorkOrderScopesDialog';
import WorkOrderScopesHeader from 'main/content/apps/work-order-scopes/WorkOrderScopesHeader';
import WorkOrderScopesLeftSidebarHeader from 'main/content/apps/work-order-scopes/WorkOrderScopesLeftSidebarHeader';
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList';
import WorkOrderScopesRightSidebarHeader from 'main/content/apps/work-order-scopes/WorkOrderScopesRightSidebarHeader';
import WorkOrderScopesSidebarContent from 'main/content/apps/work-order-scopes/WorkOrderScopesSidebarContent';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class WorkOrderScopesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getWorkOrderScopes(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewWorkOrderScopeDialog } = this.props;

        return (
            <React.Fragment>
                <FusePageCarded
                    classes={{
                        root: "w-full",
                        content: "flex flex-col",
                        header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <WorkOrderScopesHeader pageLayout={() => this.pageLayout} />
                    }
                    leftSidebarHeader={
                        <WorkOrderScopesLeftSidebarHeader />
                    }
                    rightSidebarHeader={
                        <WorkOrderScopesRightSidebarHeader />
                    }
                    content={
                        <WorkOrderScopesList />

                    }
                    leftSidebarContent={
                        <WorkOrderScopesSidebarContent />
                    }
                    sidebarInner
                    onRef={instance => {
                        this.pageLayout = instance;
                    }}
                    innerScroll
                />
                <FuseAnimate animation="transition.expandIn" delay={300}>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewWorkOrderScopeDialog}
                    >
                        <Icon>assignment</Icon>
                    </Fab>
                </FuseAnimate>
                <WorkOrderScopesDialog />
            </React.Fragment>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWorkOrderScopes: Actions.getWorkOrderScopes,
        getUserData: Actions.getUserData,
        openNewWorkOrderScopeDialog: Actions.openNewWorkOrderScopeDialog
    }, dispatch);
}

function mapStateToProps({ workOrderScopesApp }) {
    return {
        workOrderScopes: workOrderScopesApp.workOrderScopes.entities,
        selectedWorkOrderScopeIds: workOrderScopesApp.workOrderScopes.selectedWorkOrderScopeIds,
        searchText: workOrderScopesApp.workOrderScopes.searchText,
        user: workOrderScopesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderScopesApp)));
