import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import RGL, { WidthProvider } from "react-grid-layout";
import _ from 'lodash';
import ChecklistItemDialog from './ChecklistItemDialog';
import ChecklistPDFItem from './ChecklistPDFItem';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import moment from 'moment';

const GridLayout = WidthProvider(RGL);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    checklistSection: {
        border: '1px dotted lightgrey',
        // padding: 8,
    },
    scrollEnable: {
        touchAction: 'unset !important',
    }

});

class ChecklistSectionBuilder extends Component {

    state = {
        grabbing: false,
        needsUpdate: [],
        Notes: null,
    };

    // componentDidMount() {
    //     const { section } = this.props;
    //     this.setState({ ...this.state, Notes: section.Notes })
    // }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    handleLayout = (layout) => {
        const { checklist, onNeedsUpdate, data } = this.props;
        const needsUpdate = [];
        layout.map((item, i) => {
            let el = data[i];
            if (item.x !== el.PositionX || item.y !== el.PositionY || item.w !== el.Columns || item.h !== el.Rows) {
                needsUpdate.push({
                    ...el,
                    PositionX: item.x,
                    PositionY: item.y,
                    Columns: item.w,
                    Rows: item.h,
                });
            }
        });
        this.setState({ ...this.state, needsUpdate }, () => onNeedsUpdate(needsUpdate));
    }

    handleChange = (event) => {
        const { section, readOnly } = this.props;
        if (!readOnly) {
            this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), () => { if (this.props.onNotesChange) { this.props.onNotesChange(section, this.state.Notes) } });
        }
    };

    render() {
        const { Co, classes, data, scrollLock, editable, section, readOnly } = this.props;
        const { Attachments } = section.Data;
        return (
            <div className="w-full">
                <Typography variant="h6" className="h-24 w-auto font-bold mb-12 cursor-pointer truncate" style={{ maxWidth: '50%' }}>{section.Name}</Typography>
                <div className={classNames("layout pt-6 pb-6 w-full relative overflow-hidden checklist-section")}>
                    {
                        data.map((el, index) => {
                            const photoNum = (el.Attachment ? _.findIndex(Attachments, { Attachment: el.Attachment }) + 1 : null);
                            return (
                                <div
                                    className="w-full"
                                    key={index}
                                >
                                    <ChecklistPDFItem photoNum={photoNum} readOnly={readOnly} el={{ ...el }} />
                                </div>
                            );
                        })
                    }
                    <div className="w-full mb-4" style={{ marginTop: 0 }}>
                        <Typography className="font-bold text-16 mb-4">Attachments</Typography>
                        <div className="w-full flex flex-wrap">
                            {_.filter(Attachments, (o) => o.IsDeleted !== 'Y' && Boolean(o.Data) && Boolean(o.Data.Attachment)).map((attch, index) => {
                                return (
                                    <div className="w-1/4 p-8 cursor-pointer relative"><div className="rounded-full absolute pin-t pin-r mt-16 mr-16 z-10 text-white text-center font-bold px-4 min-w-24" style={{ backgroundColor: '#f44336', boxShadow: '1px 1px 4px 0px rgb(0 0 0 / 75%)' }}>{index + 1}</div><img src={`${window["apiLocation"]}/api/Attachment/GetPDFImage?Co=${attch.Co}&ID=${attch.Attachment}&Timestamp=${new Date(attch.Data.Attachment.UpdatedDate || attch.Data.Attachment.AddedDate).getTime()}`} className="w-full h-auto rounded-lg" />{attch.Notes && <div className="w-full text-10 italic text-center">"{attch.Notes}"</div>}</div>
                                );
                            })}
                        </div>
                    </div>
                    <TextField
                        className={"my-16"}
                        label="Notes"
                        id={`section${section.Section}-notes`}
                        name="Notes"
                        value={section.Notes || ''}
                        variant="outlined"
                        disabled={!scrollLock}
                        multiline
                        rows={4}
                        margin="dense"
                        fullWidth
                    />
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewChecklistItemDialog: Actions.openNewChecklistItemDialog,
        openEditChecklistItemDialog: Actions.openEditChecklistItemDialog,
    }, dispatch);
}

function mapStateToProps({ checklistsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        checklistData: spReducers.checklists,
        searchText: checklistsApp.checklists.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistSectionBuilder)));
