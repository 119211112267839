import Constants from "../../Constants";

const getProfileImage = (queryString) => {
    var toReturn = `${window["apiLocation"]}/api/Attachment/GetProfileImage?${queryString}`;

    if (process.env.NODE_ENV === 'development') {
        toReturn += `&${Constants.SUBDOMAIN_HEADER_KEY}=${window[Constants.SUBDOMAIN_HEADER_KEY]}`;
    }

    return toReturn;
}

export default getProfileImage;