import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedLaborTypeIds: [],
    routeParams       : {},
    laborTypeDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const laborTypesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_LABOR_TYPES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_LABOR_TYPES:
        {

            const laborTypeId = action.laborTypeId;

            let selectedLaborTypeIds = [...state.selectedLaborTypeIds];

            if ( selectedLaborTypeIds.find(id => id === laborTypeId) !== undefined )
            {
                selectedLaborTypeIds = selectedLaborTypeIds.filter(id => id !== laborTypeId);
            }
            else
            {
                selectedLaborTypeIds = [...selectedLaborTypeIds, laborTypeId];
            }

            return {
                ...state,
                selectedLaborTypeIds: selectedLaborTypeIds
            };
        }
        case Actions.SELECT_ALL_LABOR_TYPES:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedLaborTypeIds = arr.map(laborTypes => laborTypes.id);

            return {
                ...state,
                selectedLaborTypeIds: selectedLaborTypeIds
            };
        }
        case Actions.DESELECT_ALL_LABOR_TYPES:
        {
            return {
                ...state,
                selectedLaborTypeIds: []
            };
        }
        case Actions.OPEN_NEW_LABOR_TYPE_DIALOG:
        {
            return {
                ...state,
                laborTypeDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_LABOR_TYPE_DIALOG:
        {
            return {
                ...state,
                laborTypeDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_LABOR_TYPE_DIALOG:
        {
            return {
                ...state,
                laborTypeDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_LABOR_TYPE_DIALOG:
        {
            return {
                ...state,
                laborTypeDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default laborTypesReducer;
