import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newServiceState = {
    Co: null,
    Service: '',
    Description: '',
    Detail: '',
    DefaultDuration: 1.0,
    DefaultPriority: 'Low',
    DefaultBusinessUnit: '',
    DefaultDivision: '',
    DefaultDepartment: '',
    DefaultServiceType: null,
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    },
    QBOCompany: null,
    QBOService: null
};

class ServiceDialog extends Component {
    state = { ...newServiceState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newServiceState.Co = this.props.Co;
        if (!prevProps.serviceDialog.props.open && this.props.serviceDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.serviceDialog.type === 'edit' &&
                this.props.serviceDialog.data &&
                !_.isEqual(this.props.serviceDialog.data, prevState)) {
                this.setState({ ...this.props.serviceDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.serviceDialog.type === 'new' &&
                !_.isEqual(newServiceState, prevState)) {
                this.setState({ ...newServiceState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.serviceDialog.type === 'edit' ? this.props.closeEditServiceDialog() : this.props.closeNewServiceDialog();
    };

    canBeSubmitted() {
        const { Co, Service, Description } = this.state;
        return (
            !isNaN(Co) && Service.length > 0 && Description.length > 0
        );
    }

    render() {
        const { classes, serviceDialog, addService, updateService, removeService, businessUnits, divisions, departments, serviceTypes, qboCompany, qboServices } = this.props;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...serviceDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {serviceDialog.type === 'new' ? 'New Service' : 'Edit Service'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>

                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Service"

                                    id="service"
                                    name="Service"
                                    value={this.state.Service}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(serviceDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Default Service Type"
                                    options={_.filter(serviceTypes, { ActiveYN: 'Y' })}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.ServiceType}>
                                            {value.Description}
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.DefaultServiceType}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultServiceType: option.ServiceType })}
                                    required
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Default BusinessUnit"
                                    id="defaultBusinessUnit"
                                    name="DefaultBusinessUnit"
                                    value={this.state.DefaultBusinessUnit}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                        <MenuItem value={value.BusinessUnit}>
                                            {`${value.BusinessUnit} - ${value.Description}`}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Default Division"
                                    id="defaultDivision"
                                    name="DefaultDivision"
                                    value={this.state.DefaultDivision}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >

                                    {
                                        this.state.DefaultBusinessUnit ?
                                            (divisions && divisions.length > 0 ? divisions.map((value) => (
                                                value.BusinessUnit == this.state.DefaultBusinessUnit ?
                                                    <MenuItem value={value.Division}>
                                                        {`${value.Division} - ${value.Description}`}
                                                    </MenuItem> : ''
                                            )) : '')
                                            :
                                            (<MenuItem>
                                                Please Select a Business Unit
                                            </MenuItem>)
                                    }
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Default Department"
                                    id="defaultDepartment"
                                    name="DefaultDepartment"
                                    value={this.state.DefaultDepartment}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    {
                                        this.state.DefaultDivision && this.state.DefaultBusinessUnit ?
                                            (departments && departments.length > 0 ? departments.map((value) => (
                                                value.BusinessUnit == this.state.DefaultBusinessUnit && value.Division == this.state.DefaultDivision ?
                                                    <MenuItem value={value.Department}>
                                                        {`${value.Department} - ${value.Description}`}
                                                    </MenuItem> : ''
                                            )) : '')
                                            :
                                            (<MenuItem>
                                                Please Select a Division
                                            </MenuItem>)
                                    }
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Detail"
                                    id="detail"
                                    name="Detail"
                                    value={this.state.Detail}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Default Duration"
                                    id="defaultDuration"
                                    name="DefaultDuration"
                                    value={this.state.DefaultDuration}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Default Priority"
                                    id="defaultPriority"
                                    name="DefaultPriority"
                                    value={this.state.DefaultPriority}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                >
                                    <MenuItem value={3}>
                                        Low
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Medium
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        High
                                    </MenuItem>
                                </TextField>
                            </div>

                            {qboCompany &&
                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="QBO Service"
                                        options={qboServices}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Id}>
                                                {`${value.Name}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.QBOService || ''}
                                        onSelect={(option) => { if (serviceDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOService: option.Id }) } else { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOService: option.Id }) } }}
                                    // required
                                    />
                                </div>
                            }
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {serviceDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addService(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateService(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditServiceDialog: Actions.closeEditServiceDialog,
        closeNewServiceDialog: Actions.closeNewServiceDialog,
        addService: Actions.addService,
        updateService: Actions.updateService,
        removeService: Actions.removeService
    }, dispatch);
}

function mapStateToProps({ servicesApp, spReducers }) {
    return {
        serviceDialog: servicesApp.services.serviceDialog,
        businessUnits: spReducers.businessUnits,
        serviceTypes: spReducers.serviceTypes,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        Co: spReducers.companies.Co,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboServices: spReducers.qboSettings.services,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ServiceDialog));
