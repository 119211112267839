import * as Actions from '../../actions/alerts/index';

const alerts = function (state = [], action) {
    switch (action.type) {
        case Actions.SET_ALERTS:
            {
                return action.alerts
            }
        default:
            {
                return state;
            }
    }
};

export default alerts;