import {combineReducers} from 'redux';
import inventoryLocationGroups from './inventory-location-groups.reducer';
import user from './user.reducer';

const inventoryLocationGroupsAppReducers = combineReducers({
    inventoryLocationGroups,
    user
});

export default inventoryLocationGroupsAppReducers;
