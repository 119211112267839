import axios from 'axios/index';

export const SET_FORM_DATA = '[SPCONNECTION] SET FORM DATA';
export const GET_FORM_DATA = '[SPCONNECTION] GET FORM DATA';
export const SUBSCRIBE_FORM_LIST = '[SPCONNECTION] SUBSCRIBE FORM LIST';

export function getInitialFormData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Form`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getFormData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Form`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_FORM_DATA,
                payload: response.data
            });
        });
    }
}

export function setFormData(data) {
    return {
        type: SET_FORM_DATA,
        payload: data
    };
}