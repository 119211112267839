import * as Actions from 'store/actions/spConnection';

const initialState = {
    status: null,
    notifications: null,
    scheduledNotifications: null,
}
const localNotifications = function (state = initialState, action) {
    switch (action.type) {
        case Actions.INIT_LOCAL_NOTIFICATIONS:
            {
                return {
                    ...state,
                    status: action.payload
                }
            }
        case Actions.SET_LOCAL_NOTIFICATION:
            {
                return {
                    ...state,
                    notifications: action.payload
                }
            }
        case Actions.SET_SCHEDULED_LOCAL_NOTIFICATION:
            {
                return {
                    ...state,
                    scheduledNotifications: action.payload
                }
            }
        case Actions.CLEAR_LOCAL_NOTIFICATIONS:
            {
                return {
                    ...state,
                    notifications: null
                }
            }
    }

    return state;
};

export default localNotifications;