import React, { Component } from 'react';
import {
    TextField, Badge, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, ListItem, ListItemText, Typography, Toolbar, AppBar, Avatar, MenuItem, Switch, FormControlLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import classNames from 'classnames';
import moment from 'moment';
import Media from 'react-media';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
        fontSize: 16,
    },
    status: {
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -10,
    },
    liRoot: {
        padding: 0
    },
    assigned: {
        backgroundColor: theme.palette.primary.main,
    },
    techUABadge: {
        top: 20,
        right: 39,
        background: '#3f3f3f',
    },
    techUABadgeIcon: {
        fontSize: 12,
    },
    techUACountBadge: {
        top: 14,
        right: -6,
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        border: '2px solid #3f3f3f',
    },
});
const newTechUAState = {
    ID: null,
    Co: null,
    Technician: null,
    StartDate: null,
    StartTime: null,
    EndDate: null,
    Duration: null,
    Description: null,
    AllDayYN: null,
    Notes: null,
    CreatedBy: null,
    CreatedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Data: {
        ErrMsg: null,
    }
};

class TechUADialog extends Component {
    state = { ..._.cloneDeepWith(newTechUAState) };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newTechUAState.Co = this.props.Co;
        const initialState = _.cloneDeepWith(newTechUAState);
        if (!prevProps.techUADialog.props.open && this.props.techUADialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.techUADialog.type === 'edit' &&
                this.props.techUADialog.data &&
                !_.isEqual(this.props.techUADialog.data, prevState)) {
                this.setState({ ...initialState, ...this.props.techUADialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.techUADialog.type === 'new' &&
                !_.isEqual({ ...initialState, ...this.props.techUADialog.data }, prevState)) {
                this.setState({ ...initialState, ...this.props.techUADialog.data });
            }
        } else {
            if (this.props.techUADialog.type === 'update' &&
                this.props.techUADialog.data &&
                !_.isEqual(this.props.techUADialog.data, prevState)) {
                this.props.techUADialog.type = 'edit';
                this.setState({ ...this.props.techUADialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.techUADialog.type === 'edit' ? this.props.closeEditTechUADialog() : this.props.closeNewTechUADialog();
    };

    canBeSubmitted() {
        const { Co, Technician, StartDate, EndDate, AllDayYN, Duration, Description } = this.state;
        if (AllDayYN == 'Y' &&  Date.parse(EndDate) >=  Date.parse(StartDate)) return Co && Technician && StartDate && EndDate && Description;
        else if (AllDayYN == 'N') return Co && Technician && StartDate && Duration && Description;
        else return false;  
    }   

    handleTechUAStartTimeChange = (event) => {
        let { techUA, techUAValue } = this.state;
        window["warn"](event);
        let StartDate = this.formatDate(event.toDate());
        let StartTime = event._d.toLocaleString('en-US');
        this.setState({ ...this.state, StartDate, StartTime });
    };

    handleTechUAStartDateChange = (event) => {
        let { techUA, techUAValue } = this.state;
        window["warn"](event);
        let StartDate = this.formatDate(event.toDate());
        let EndDate = (this.state.EndDate && moment(this.state.EndDate).toDate() > event.toDate() ? this.state.EndDate : this.formatDate(event.toDate()));
        this.setState({ ...this.state, StartDate, StartTime: null, EndDate });
    };

    handleTechUAEndDateChange = (event) => {
        let { techUA, techUAValue } = this.state;
        window["warn"](event);
        let EndDate = this.formatDate(event.toDate());
        let StartDate = (this.state.StartDate && moment(this.state.StartDate).toDate() < event.toDate() ? this.state.StartDate : this.formatDate(event.toDate()));
        this.setState({ ...this.state, StartDate, EndDate, });
    };

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    handleAllDayChange = (e) => {
        const { checked } = e.target;
        const { Duration, StartTime, StartDate, EndDate } = this.state;
        console.log("checking...", "Is Checked: " + checked)
        console.log("checking...", this.state)
        
        this.setState({ 
            ...this.state, 
            AllDayYN: checked ? 'Y' : 'N' ,
            StartDate: checked ? this.formatDate(StartDate) : null,
            EndDate: checked ? EndDate || this.formatDate(StartDate) : null,
            StartTime: !checked ? StartTime : null,  
            Duration: !checked ? Duration : 1,
        });

    }

    render() {
        const { classes, securables, techUADialog, addTechUATime, updateTechUATime, removeTechUATime, date, technicians, services } = this.props;
        const { Status, TechUA, Data } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "dispatch-board" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"
                        }}
                        className={classes.root}
                        {...techUADialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                        fullScreen={ !matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {techUADialog.type === 'new' ? 'New Unavailable Time' : `Edit Unavailable Time`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <Autocomplete
                                    className={classes.formControl}
                                    title="Technician"
                                    options={technicians}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.Technician}>
                                                <div className="flex">
                                                    <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                                    {`${value.FirstName} ${value.LastName}`}
                                                </div>
                                            </MenuItem>
                                    }}
                                    value={this.state.Technician || ''}
                                    noflip={true}
                                    onSelect={(option) => this.setState({ ...this.state, Technician: option.Technician })}
                                    required
                                />
                            </div>

                            {this.state.AllDayYN !== "Y" ?

                                <div className="flex">
                                    <DateTimePicker variant="inline" inputVariant="outlined"
                                        className={classes.formControl}
                                        label="Start Date/Time"
                                        id="startDateTime"
                                        format="MM/DD/YYYY h:mm A"
                                        // ref="datePicker"
                                        value={this.state.StartTime || this.state.StartDate}
                                        onChange={this.handleTechUAStartTimeChange}
                                        // TextFieldComponent={this.renderDateLabel}
                                        animateYearScrolling
                                        fullWidth
                                    />
                                </div> :

                                <DatePicker
                                    inputVariant="outlined"
                                    className={classes.formControl}
                                    label="Start Date"
                                    id="startDate"
                                    format="MM/DD/YYYY"
                                    // ref="datePicker"
                                    value={this.state.StartDate}
                                    onChange={this.handleTechUAStartDateChange}
                                    // TextFieldComponent={this.renderDateLabel}
                                    animateYearScrolling
                                    fullWidth
                                    autoOk
                                />
                            }

                            {this.state.AllDayYN === "Y" &&
                                <div className="flex">
                                    <DatePicker 
                                        inputVariant="outlined"
                                        className={classes.formControl}
                                        label="End Date"
                                        id="endDate"
                                        format="MM/DD/YYYY"
                                        // ref="datePicker"
                                        value={this.state.EndDate}
                                        onChange={this.handleTechUAEndDateChange}
                                        // TextFieldComponent={this.renderDateLabel}
                                        animateYearScrolling
                                        fullWidth
                                        autoOk
                                    />
                                </div>
                            }

                            {this.state.AllDayYN !== "Y" &&
                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Duration"
                                        id="duration"
                                        name="Duration"
                                        value={this.state.Duration || ''}
                                        inputProps={{
                                            type: 'number',
                                        }}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            }

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.AllDayYN === 'Y'}
                                            name="AllDayYN"
                                            onChange={this.handleAllDayChange}
                                            color="primary"
                                        />
                                    }
                                    label="All Day?"
                                />
                            </div>

                        </DialogContent>

                        {((accessLevel && accessLevel.AccessLevel !== "R")) &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {techUADialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addTechUATime(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    updateTechUATime(this.state);
                                                    this.closeComposeDialog();
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                Save
                            </Button>
                                            <IconButton
                                                onClick={() => {
                                                    removeTechUATime(this.state);
                                                    this.closeComposeDialog();
                                                }}
                                                disabled={!this.state.ID}
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTechUADialog: Actions.closeEditTechUADialog,
        closeNewTechUADialog: Actions.closeNewTechUADialog,
        addTechUATime: Actions.addTechUATime,
        updateTechUATime: Actions.updateTechUATime,
        removeTechUATime: Actions.removeTechUATime,
    }, dispatch);
}

function mapStateToProps({ techUAApp, spReducers }) {
    return {
        techUADialog: techUAApp.techUA.techUADialog,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
        services: spReducers.services,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TechUADialog));
