import { FuseAnimate, FuseUtils } from '@fuse'
import _ from '@lodash'
import { Fab, Icon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import { bindActionCreators } from 'redux'
import * as SiteActions from '../customer-sites/store/actions'
import * as Actions from './store/actions'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const styles = theme => ({
  root: {
    maxHeight: '70vh',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailList: {
    padding: 0,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
  },
  labels: {},
})

class CustomersList extends Component {
  state = {
    selectedCustomersMenu: null,
  }

  componentDidMount() {
    // window["log"]('CustomersList component initial customerData prop: ', this.props.customerData);
  }

  componentDidUpdate(prevProps, prevState) {
    // window["log"]('CustomersList component customerData prop updated: ', this.props.customerData);
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      window['log']('Filtered Array: ', arr)
      return arr
    }
    // window["log"]('Filtered Array: ', arr, searchText);
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedCustomerMenu = event => {
    this.setState({ selectedCustomersMenu: event.currentTarget })
  }

  closeSelectedCustomersMenu = () => {
    this.setState({ selectedCustomersMenu: null })
  }

  render() {
    const {
      classes,
      securables,
      customers,
      user,
      searchText,
      selectedCustomerIds,
      selectAllCustomers,
      openNewCustomerDialog,
      deSelectAllCustomers,
      toggleInSelectedCustomers,
      openEditCustomerDialog,
      toggleStarredCustomer,
      setCustomersUnstarred,
      setCustomersStarred,
      openNewCustomerSiteDialog,
    } = this.props
    const data = this.getFilteredArray(
      !this.props.customerData ? [] : this.props.customerData,
      searchText,
    )
    const { selectedCustomersMenu } = this.state
    const accessLevel = _.find(securables, { Securable: 'customers' })

    return (
      <FuseAnimate animation='transition.slideUpIn' delay={300}>
        <div>
          {accessLevel && accessLevel.AccessLevel !== 'R' && (
            <Fab
              color='secondary'
              aria-label='add'
              className={classes.addButton}
              onClick={openNewCustomerDialog}
            >
              <Icon>add</Icon>
            </Fab>
          )}
          <Typography
            variant='h6'
            style={{ alignItems: 'center' }}
            className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
          >
            <Icon color='primary' className='text-32 mr-12'>
              account_box
            </Icon>
            Customer List
          </Typography>
          <ReactTableFixedColumns
            className={classNames(
              classes.root,
              '-striped -highlight border-0 rounded',
            )}
            getTrProps={(state, rowInfo, column) => {
              return {
                className:
                  this.props.lineEnabled === true ? 'cursor-pointer' : 'auto',
                onClick: (e, handleOriginal) => {
                  if (rowInfo && this.props.lineEnabled) {
                    openEditCustomerDialog(rowInfo.original)
                  }
                },
              }
            }}
            data={data}
            columns={[
              {
                Header: 'Customer',
                accessor: 'Customer',
                //filterable: true,
                className: 'font-bold justify-center',
                width: 100,
              },
              {
                Header: 'First Name',
                accessor: 'FirstName',
                //filterable: true,
                width: 200,
              },
              {
                Header: 'Last Name',
                accessor: 'LastName',
                //filterable: true,
                width: 200,
              },
              {
                Header: 'Name',
                accessor: 'Name',
                //filterable: true,
                width: 200,
              },
              {
                Header: 'Address',
                accessor: 'Address1',
                //filterable: true,
                width: 300,
              },
              {
                Header: 'City',
                accessor: 'City',
                //filterable: true,
                className: 'justify-center',
                width: 150,
              },
              {
                Header: 'State',
                accessor: 'State',
                //filterable: true,
                className: 'justify-center',
                width: 100,
              },
              {
                Header: 'Zip',
                accessor: 'Zip',
                //filterable: true,
                className: 'justify-center',
                width: 100,
              },
              {
                Header: 'Billing Address',
                accessor: 'BillAddress1',
                //filterable: true,
                width: 300,
              },
              {
                Header: 'Billing City',
                accessor: 'BillCity',
                //filterable: true,
                className: 'justify-center',
                width: 150,
              },
              {
                Header: 'Billing State',
                accessor: 'BillState',
                //filterable: true,
                className: 'justify-center',
                width: 100,
              },
              {
                Header: 'Billing Zip',
                accessor: 'BillZip',
                //filterable: true,
                className: 'justify-center',
                width: 100,
              },
            ]}
            defaultPageSize={100}
            noDataText='No Customers found'
          />
        </div>
      </FuseAnimate>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomers: Actions.getCustomers,
      getUserData: Actions.getUserData,
      toggleInSelectedCustomers: Actions.toggleInSelectedCustomers,
      selectAllCustomers: Actions.selectAllCustomers,
      deSelectAllCustomers: Actions.deSelectAllCustomers,
      openEditCustomerDialog: Actions.openEditCustomerDialog,
      toggleStarredCustomer: Actions.toggleStarredCustomer,
      toggleStarredCustomers: Actions.toggleStarredCustomers,
      setCustomersStarred: Actions.setCustomersStarred,
      setCustomersUnstarred: Actions.setCustomersUnstarred,
      openNewCustomerSiteDialog: SiteActions.openNewCustomerSiteDialog,
      openNewCustomerDialog: Actions.openNewCustomerDialog,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, customersApp }) {
  window['log'](spReducers, customersApp)
  return {
    customers: customersApp.customers.entities,
    selectedCustomerIds: customersApp.customers.selectedCustomerIds,
    lineEnabled: customersApp.customers.lineEnabled,
    searchText: customersApp.customers.searchText,
    user: customersApp.user,
    customerData: spReducers.customers,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersList)),
)
