import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon, IconButton, MuiThemeProvider, Tooltip, Typography } from '@material-ui/core';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import classNames from 'classnames';
import { FuseThemes } from '@fuse';
import moment from 'moment';
import { FuseAnimate } from '@fuse';

const headerHeight = 200;

const styles = theme => ({
    root: {
        height: headerHeight,
        minHeight: headerHeight,
        display: 'flex',
        color: '#FFFFFF',
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.contrastBg,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        // '&:before': {
        //     content: "''",
        //     position: 'absolute',
        //     top: 0,
        //     right: 0,
        //     bottom: 0,
        //     left: 0,
        //     zIndex: 1,
        //     background: 'rgba(0, 0, 0, 0.45)'
        // },
        // '&.Jan'           : {
        //     backgroundImage   : "url('/assets/images/calendar/winter.jpg')",
        //     backgroundPosition: '0 85%'
        // },
        // '&.Feb'           : {
        //     backgroundImage   : "url('/assets/images/calendar/winter.jpg')",
        //     backgroundPosition: '0 85%'
        // },
        // '&.Mar'           : {
        //     backgroundImage   : "url('/assets/images/calendar/spring.jpg')",
        //     backgroundPosition: '0 40%'
        // },
        // '&.Apr'           : {
        //     backgroundImage   : "url('/assets/images/calendar/spring.jpg')",
        //     backgroundPosition: '0 40%'
        // },
        // '&.May'           : {
        //     backgroundImage   : "url('/assets/images/calendar/spring.jpg')",
        //     backgroundPosition: '0 40%'
        // },
        // '&.Jun'           : {
        //     backgroundImage   : "url('/assets/images/calendar/summer.jpg')",
        //     backgroundPosition: '0 80%'
        // },
        // '&.Jul'           : {
        //     backgroundImage   : "url('/assets/images/calendar/summer.jpg')",
        //     backgroundPosition: '0 80%'
        // },
        // '&.Aug'           : {
        //     backgroundImage   : "url('/assets/images/calendar/summer.jpg')",
        //     backgroundPosition: '0 80%'
        // },
        // '&.Sep'           : {
        //     backgroundImage   : "url('/assets/images/calendar/autumn.jpg')",
        //     backgroundPosition: '0 40%'
        // },
        // '&.Oct'           : {
        //     backgroundImage   : "url('/assets/images/calendar/autumn.jpg')",
        //     backgroundPosition: '0 40%'
        // },
        // '&.Nov'           : {
        //     backgroundImage   : "url('/assets/images/calendar/autumn.jpg')",
        //     backgroundPosition: '0 40%'
        // },
        // '&.Dec'           : {
        //     backgroundImage   : "url('/assets/images/calendar/winter.jpg')",
        //     backgroundPosition: '0 85%'
        // }
    }
});

class CalendarHeader extends Toolbar {

    render() {
        const { classes, label, date, view, views } = this.props;
        window["warn"]('Calendar View Names: ', views);

        return (
            <MuiThemeProvider theme={FuseThemes['mainThemeDark']}>

                <div className={classNames(classes.root, moment(date).format('MMM'))}>

                    <div className="flex flex-1 flex-col p-12 z-10">

                        <div className="flex justify-between">
                            <div className="flex items-center my-16">
                                <FuseAnimate animation="transition.expandIn" delay={300}>
                                    <Icon className="text-32 mx-12">today</Icon>
                                </FuseAnimate>
                                <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                    <Typography variant="h6">Calendar</Typography>
                                </FuseAnimate>
                            </div>
                            <div className="flex items-center">
                                <Tooltip title="Today">
                                    <div>
                                        <FuseAnimate animation="transition.expandIn" delay={500}>
                                            <IconButton aria-label="today" onClick={this.navigate.bind(null, navigate.TODAY)}>
                                                <Icon>today</Icon>
                                            </IconButton>
                                        </FuseAnimate>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Month">
                                    <div>
                                        <FuseAnimate animation="transition.expandIn" delay={500}>
                                            <IconButton
                                                aria-label="month"
                                                onClick={() => this.props.onView("month")}
                                                disabled={view === "month"}
                                            >
                                                <Icon>view_module</Icon>
                                            </IconButton>
                                        </FuseAnimate>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Week">
                                    <div>
                                        <FuseAnimate animation="transition.expandIn" delay={500}>
                                            <IconButton
                                                aria-label="week"
                                                onClick={() => this.props.onView("week")}
                                                disabled={view === "week"}
                                            >
                                                <Icon>view_week</Icon>
                                            </IconButton>
                                        </FuseAnimate>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Day">
                                    <div>
                                        <FuseAnimate animation="transition.expandIn" delay={500}>
                                            <IconButton
                                                aria-label="day"
                                                onClick={() => this.props.onView("day")}
                                                disabled={view === "day"}
                                            >
                                                <Icon>view_day</Icon>
                                            </IconButton>
                                        </FuseAnimate>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                        <FuseAnimate delay={500}>
                            <div className="flex items-center justify-center">
                                <Tooltip title="Previous">
                                    <IconButton aria-label="Previous" onClick={this.navigate.bind(null, navigate.PREVIOUS)}>
                                        <Icon>chevron_left</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="h6" className="sm:text-24 px-4 w-192 sm:w-224 text-center">{label}</Typography>
                                <Tooltip title="Next">
                                    <IconButton aria-label="Next" onClick={this.navigate.bind(null, navigate.NEXT)}>
                                        <Icon>chevron_right</Icon>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </FuseAnimate>
                    </div>
                </div>
            </MuiThemeProvider>
        )
    };
}

export default withStyles(styles, { withTheme: true })(CalendarHeader);
