import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, FormGroup, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import classNames from 'classnames';
import _ from '@lodash';
import Media from 'react-media';
import BarcodeScanner from 'main/content/components/barcode-scanner/BarcodeScanner';
import axios from 'axios';
import { impress } from 'main/content/compression/impress';
import WorkOrdersApp from '../work-orders/WorkOrdersApp';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    }
});
const newFleetVehicleState = {
    ID: null,
    Co: null,
    Category: null,
    FleetVehicle: null,
    LicenseNum: null,
    Description: null,
    Manufacturer: null,
    Model: null,
    ModelYear: null,
    VIN: null,
    Odometer: null,
    OdometerDate: null,
    Operator: null,
    Status: 0,
    ActiveYN: 'Y',
    Notes: null,
    AttachmentID: null,
    Data: {
        ErrMsg: null,
    },
    scanner: null,
    isScanning: false,
};

class FleetVehicleDialog extends Component {
    state = { ...newFleetVehicleState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newFleetVehicleState.Co = this.props.Co;
        if (!prevProps.fleetVehicleDialog.props.open && this.props.fleetVehicleDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.fleetVehicleDialog.type === 'edit' &&
                this.props.fleetVehicleDialog.data &&
                !_.isEqual(this.props.fleetVehicleDialog.data, prevState)) {
                this.setState({ ...this.props.fleetVehicleDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.fleetVehicleDialog.type === 'new' &&
                !_.isEqual(newFleetVehicleState, prevState)) {
                this.setState({ ...newFleetVehicleState });
            }
        } else {
            if (this.props.fleetVehicleDialog.type === 'update' &&
                this.props.fleetVehicleDialog.data &&
                !_.isEqual(this.props.fleetVehicleDialog.data, prevState)) {
                this.props.fleetVehicleDialog.type = 'edit';
                this.setState({ ...this.props.fleetVehicleDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), this.handleTechLabor);
    };

    handleDateChange = (event) => {
        let OdometerDate = this.formatDate(event._d);
        this.setState({ ...this.state, OdometerDate });
    };

    handleYearChange = (event) => {
        let ModelYear = event._d.getFullYear();
        this.setState({ ...this.state, ModelYear });
    };

    closeComposeDialog = () => {
        this.props.fleetVehicleDialog.type === 'edit' ? this.props.closeEditFleetVehicleDialog() : this.props.closeNewFleetVehicleDialog();
    };

    canBeSubmitted() {
        const { Description, Category, FleetVehicle } = this.state;
        return Category && Description && FleetVehicle && FleetVehicle.length > 0 && Description.length > 0 && Category.length > 0;
    }

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }
    handleVIN = (info, vin) => {
        const vInfo = {
            VIN: vin ? vin : this.state.VIN,
            Manufacturer: _.find(info.Results, { VariableId: 26 }).Value,
            Model: _.find(info.Results, { VariableId: 28 }).Value,
            ModelYear: _.find(info.Results, { VariableId: 29 }).Value,
            Description: `${_.find(info.Results, { VariableId: 26 }).Value} ${_.find(info.Results, { VariableId: 28 }).Value} ${_.find(info.Results, { VariableId: 34 }).Value} ${_.find(info.Results, { VariableId: 9 }).Value}Cyl ${_.find(info.Results, { VariableId: 24 }).Value} ${_.find(info.Results, { VariableId: 5 }).Value}`
        }
        this.setState({ ...this.state, ...vInfo, isScanning: false });
    }

    fileSelectedHandler = (event) => {
        let { ID, Data } = this.state;
        const { fleetVehicleDialog } = this.props;
        impress(event.target.files[0], 1024, 50, true, (img) => {
            window["log"](img);
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Attachment/PostFleetVehicleImage?Co=${encodeURIComponent(this.state.Co)}&ID=${encodeURIComponent(this.state.FleetVehicle)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
                if (Data) {
                    Data.Avatar = img.base64;
                } else {
                    Data = { ...Data, Avatar: img.base64 };
                }
                this.setState({ ...this.state, Data });
            });
        });
    }

    getVIN = (barcode) => {
        const { VIN } = this.state;
        if (VIN && VIN.length > 10) {
            const request = axios.get(`${window["apiLocation"]}/api/VIN?VIN=${encodeURIComponent(barcode ? barcode : VIN)}`).then((response) => {
                if (response.data) {
                    this.handleVIN(response.data);
                }
            });
        }
    }

    render() {
        const { classes, securables, fleetVehicleDialog, addFleetVehicle, updateFleetVehicle, removeFleetVehicle, costTypes, fleetVehicleCategories, employees } = this.props;
        let { FleetVehicle, ID, isScanning } = this.state;
        const accessLevel = _.find(securables, { Securable: "fleet-vehicles" });

        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        {...fleetVehicleDialog.props}
                        open={Boolean(accessLevel) && fleetVehicleDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={ !matches}
                    >
                        <BarcodeScanner open={isScanning} onScan={this.getVIN} onClose={() => { this.setState({ ...this.state, isScanning: false, }) }} />

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="sm:flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {fleetVehicleDialog.type === 'new' ? 'New Fleet Vehicle' : `Fleet Vehicle #${this.state.FleetVehicle}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            {(this.props.fleetVehicleDialog.type === 'edit' && this.state.FleetVehicle && this.state.FleetVehicle.length > 0) &&
                                <div className={classNames(classes.profile, "flex flex-col items-center justify-center")}>
                                    <Avatar style={{ cursor: 'pointer', boxShadow: '2px 2px 10px 2px #3f3f3f' }} onClick={() => this.fileInput.click()} className="w-1/4 h-auto" alt="Fleet Vehicle Image" src={(this.state.Data && this.state.Data.Avatar ? (!isNaN(this.state.Data.Avatar) ? getProfileImage(`Co=${this.state.Co}&ID=${this.state.Data.Avatar}` ): this.state.Data.Avatar) : this.state.avatar ? this.state.avatar : avatar)} />
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={this.fileSelectedHandler}
                                        ref={fileInput => this.fileInput = fileInput}
                                    />
                                    {fleetVehicleDialog.type === 'edit' && (
                                        <Typography variant="h6" color="inherit" className="p-16">
                                            {`${this.state.FleetVehicle}`}
                                        </Typography>
                                    )}
                                </div>
                            }
                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">local_shipping</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Category"
                                    select
                                    id="category"
                                    name="Category"
                                    value={this.state.Category}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.Category ? true : false }}
                                    required
                                    fullWidth
                                >
                                    {fleetVehicleCategories && fleetVehicleCategories.map((value, index) => (
                                        <MenuItem key={index} value={value.Category}>
                                            <div className="flex">
                                                {`${value.Category} - ${value.Description.substr(0, 30)}`}
                                            </div>
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Vehicle"
                                    id="fleetVehicle"
                                    name="FleetVehicle"
                                    value={this.state.FleetVehicle}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.FleetVehicle ? true : false }}
                                    fullWidth
                                    variant="outlined"
                                    disabled={Boolean(this.state.ID)}
                                    required
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="License #"
                                    id="licenseNum"
                                    name="LicenseNum"
                                    value={this.state.LicenseNum}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.LicenseNum ? true : false }}
                                    fullWidth
                                    variant="outlined"
                                    required
                                />
                            </div>

                            <div className="sm:flex">
                                <div onClick={() => { this.setState({ ...this.state, isScanning: true }); }} className="min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20 cursor-pointer">
                                    <Icon color="error">crop_free</Icon>
                                </div>
                                <div className="sm:flex w-full">
                                    <div className="sm:flex sm:w-1/3">
                                        <TextField
                                            className={classes.formControl}
                                            label="VIN #"
                                            id="vin"
                                            name="VIN"
                                            value={this.state.VIN}
                                            onChange={this.handleChange}
                                            onBlur={() => this.getVIN()}
                                            InputLabelProps={{ shrink: this.state.VIN ? true : false }}
                                            fullWidth
                                            variant="outlined"
                                            required
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="sm:flex sm:w-2/3">
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.Description ? true : false }}
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">info</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Make"
                                    id="manufacturer"
                                    name="Manufacturer"
                                    value={this.state.Manufacturer}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.Manufacturer ? true : false }}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Model"
                                    id="model"
                                    name="Model"
                                    value={this.state.Model}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.Model ? true : false }}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <DatePicker variant="inline" inputVariant="outlined"
                                    views={["year"]}
                                    className={classes.formControl}
                                    label="Year"
                                    id="modelYear"
                                    format="YYYY"
                                    ref="yearPicker"
                                    value={this.state.ModelYear && new Date(`1/1/${this.state.ModelYear}`)}
                                    onChange={this.handleYearChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Odometer"
                                    id="odometer"
                                    name="Odometer"
                                    value={this.state.Odometer}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.Odometer ? true : false }}
                                    variant="outlined"
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <DatePicker variant="inline" inputVariant="outlined"
                                    className={classes.formControl}
                                    label="Odometer Date"
                                    id="odometerDate"
                                    format="MM/DD/YYYY"
                                    ref="datePicker"
                                    value={this.state.OdometerDate}
                                    onChange={this.handleDateChange}
                                    // TextFieldComponent={this.renderDateLabel}
                                    variant="outlined"
                                    animateYearScrolling
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Operator"
                                    select
                                    id="operator"
                                    name="Operator"
                                    value={this.state.Operator}
                                    onChange={this.handleChange}
                                    // onBlur={this.handleTechLabor}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.Operator ? true : false }}
                                    fullWidth
                                >
                                    <MenuItem value={null}>
                                        None
                                    </MenuItem>
                                    {(employees && employees.length > 0 ? employees.map((value, index) => (
                                        <MenuItem key={index} value={value.Employee}>
                                            <div className="flex">
                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Employee} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                                {`${value.FirstName} ${value.LastName}`}
                                            </div>
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">note</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {fleetVehicleDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addFleetVehicle(this.state);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateFleetVehicle(this.state);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditFleetVehicleDialog: Actions.closeEditFleetVehicleDialog,
        closeNewFleetVehicleDialog: Actions.closeNewFleetVehicleDialog,
        addFleetVehicle: Actions.addFleetVehicle,
        updateFleetVehicle: Actions.updateFleetVehicle,
        removeFleetVehicle: Actions.removeFleetVehicle
    }, dispatch);
}

function mapStateToProps({ fleetVehiclesApp, workOrdersApp, workOrderScopesApp, spReducers }) {
    return {
        fleetVehicleDialog: fleetVehiclesApp.fleetVehicles.fleetVehicleDialog,
        workOrder: workOrdersApp.workOrders.workOrderDialog.data,
        scope: workOrderScopesApp.workOrderScopes.workOrderScopeDialog.data,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User.UserName,
        types: spReducers.fleetVehicleTypes,
        technicians: spReducers.technicians,
        businessUnits: spReducers.businessUnits,
        laborTypes: spReducers.laborTypes,
        taxCodes: spReducers.taxCodes,
        costTypes: spReducers.costTypes,
        rateTemplates: spReducers.rateTemplates,
        employees: spReducers.employees,
        fleetVehicleCategories: spReducers.fleetVehicleCategories,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(FleetVehicleDialog));
