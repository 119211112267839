import * as Actions from 'store/actions/spConnection';

const divisions = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_DIVISION_DATA:
            {
                return action.payload;
            }
        case Actions.SET_DIVISION_DATA:
            {
                return action.payload;
            }
    }

    return state;
};

export default divisions;