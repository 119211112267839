import {combineReducers} from 'redux';
import trips from './trips.reducer';
import user from './user.reducer';

const tripsAppReducers = combineReducers({
    trips,
    user
});

export default tripsAppReducers;
