import * as Actions from 'store/actions/spConnection';

const initialState = {
    data: {
        Events: [],
        Trips: [],
        ToDos: []
    },
    tags: [],
    month: new Date()
};

const calendar = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_CALENDAR_DATA:
            {
                return {
                    ...state,
                    data: action.payload,
                    month: action.month
                };
            }
            break;
        case Actions.SET_TAG_DATA:
            {
                return {
                    ...state,
                    tags: action.payload
                };
            }
            break;
    }

    return state;
};

export default calendar;