import axios from 'axios/index';
import CacheManager from 'store/middlewares/cache';

const offline = new CacheManager();

export const SET_INVOICE_NOTES = '[SPCONNECTION] SET INVOICE NOTES ';

export function updateInvoiceNotes(notes) {
    offline.get('invoiceNotes').then((notes) => {
        offline.remove('invoiceNotes');
    })
    return {
        type: SET_INVOICE_NOTES,
        notes
    }
}