import _ from '@lodash'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Hidden,
  Icon,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { saveAs } from '@progress/kendo-file-saver'
import axios from 'axios'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import Media from 'react-media'
import { connect } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import ChecklistSection from './ChecklistSection'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        paddingTop: props.paddingTop
          ? props.paddingTop
          : props.padding
            ? props.padding
            : 8 * 3,
        maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  paper: {
    minHeight: 'calc(100% - 66px)',
    paddingTop: 16,
    paddingBottom: 64,
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
})
const newChecklistState = {
  scrollLock: true,
  isTechnicianSigning: false,
  TechnicianSignature: null,
  isCustomerSigning: false,
  CustomerSignature: null,
}

const ChecklistMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class Checklist extends Component {
  state = { ...newChecklistState }

  componentDidMount() {
    const { checklist } = this.props
    this.setState({ ...newChecklistState, ..._.omit(checklist, 'assignment') })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { checklist } = this.props
    if (!_.isEqual(checklist, prevProps.checklist)) {
      this.setState({ ...this.state, ..._.omit(checklist, 'assignment') })
    }
  }

  canBeSubmitted() {
    return true
  }

  handleChange = (el, value, notes, attachment) => {
    const { readOnly } = this.props
    if (!readOnly) {
      const { Data } = this.state
      const { Sections } = Data
      const section = _.find(Sections, { Section: el.Section })
      const item = _.find(section.Data.Items, { Item: el.Item })
      if (item) {
        item.Value = value
        item.Notes = notes ? notes : null
        if (attachment) {
          item.Attachment = attachment
        }
      }
      this.setState({ ...this.state, Data: { ...Data } })
    }
  }

  handleNotesChange = (el, notes) => {
    const { readOnly } = this.props
    if (!readOnly) {
      const { Data } = this.state
      const { Sections } = Data
      const section = _.find(Sections, { Section: el.Section })
      if (section) {
        section.Notes = notes
      }
      this.setState({ ...this.state, Data: { ...Data } })
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  exportToPDF = () => {
    const { checklist } = this.props
    const data = checklist.Data.Sections
    const headers = _.filter(data, { HeaderYN: 'Y' })
    const sections = _.filter(data, o => {
      return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y'
    })
    const footers = _.filter(data, { FooterYN: 'Y' })
    let gridItem = document.getElementById('checklist-print')
    drawDOM(gridItem, {
      paperSize: 'Letter',
      keepTogether: '.checklist-section',
      margin: { top: 20, left: 40, right: 40, bottom: 20 },
      scale: 0.65,
      // pageTemplate: () => <PageTemplate checklist={{ ...checklist }} />
    })
      .then(group => {
        return exportPDF(group)
      })
      .then(dataUri => {
        var data = dataUri.split(';base64,')[1]
        const blob = this.b64toBlob(data, 'application/pdf')
        // console.warn(dataUri, data, blob);
        saveAs(dataUri, checklist.Name)
      })
  }

  handleSave = () => {
    const { assignment, checklist } = this.props
    if (!checklist.EnteredBy) {
      var rec = _.omit(this.state, 'scrollLock')
      if (assignment) {
        var data = {
          ...assignment,
          Checklist: checklist.Checklist,
          Data: {
            Result: {
              ...rec,
            },
          },
        }
        axios
          .post(`${window['apiLocation']}/api/ChecklistAssignmentResult`, data)
          .then(res => {
            if (
              res.data.Data &&
              res.data.Data.ErrMsg &&
              res.data.Data.ErrMsg.length > 0
            ) {
              console.warn(res.data.Data.ErrMsg)
              this.props.showMessage({
                message: `Error: ${res.data.Data.ErrMsg}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              })
            } else {
              this.props.onAdd(res.data)
              this.props.showMessage({
                message: `Checklist Input has been successfully saved.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              })
              this.props.onClose()
            }
          })
      }
    } else {
      this.handleUpdate()
    }
  }

  handleUpdate = () => {
    var data = _.omit(this.state, 'scrollLock')
    const { Co, Checklist, ID } = data
    axios
      .put(
        `${window['apiLocation']}/api/ChecklistResult?Co=${Co}&Checklist=${Checklist}&ID=${ID}`,
        data,
      )
      .then(res => {
        if (
          res.data.Data &&
          res.data.Data.ErrMsg &&
          res.data.Data.ErrMsg.length > 0
        ) {
          console.warn(res.data.Data.ErrMsg)
          this.props.showMessage({
            message: `Error: ${res.data.Data.ErrMsg}`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          })
        } else {
          this.props.onUpdate(res.data)
          this.props.showMessage({
            message: `Checklist Input has been successfully saved.`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          })
          this.props.onClose()
        }
      })
  }

  handleDelete = () => {
    const { Co, Checklist, ID, Data } = this.state
    Data.Sections.map(section => {
      section.Notes = null
      section.Data.Items.map(item => {
        item.Value = null
        item.Notes = null
      })
    })
    axios
      .delete(
        `${window['apiLocation']}/api/ChecklistAssignmentResult?Co=${Co}&Checklist=${Checklist}&ID=${ID}`,
      )
      .then(res => {
        if (res.data && res.data.length > 0) {
          console.warn(res.data)
          this.props.showMessage({
            message: `Error: ${res.data}`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          })
        } else {
          this.setState(
            {
              ...this.state,
              TechnicianSignature: null,
              isTechnicianSigning: false,
              CustomerSignature: null,
              isCustomerSigning: false,
              Data: { ...Data },
            },
            () => this.props.onDelete(this.state),
          )
          this.props.showMessage({
            message: `Checklist Input has been successfully removed.`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          })
          this.props.onClose()
        }
      })
    // this.setState({ ...newChecklistState, ..._.omit(checklist, 'assignment') });
  }

  sigTechnicianPad = {}

  clearTechnician = () => {
    this.sigTechnicianPad.clear()
  }

  trimTechnician = () => {
    this.setState({
      ...this.state,
      TechnicianSignature: this.sigTechnicianPad
        .getCanvas()
        .toDataURL('image/png'),
      isTechnicianSigning: false,
    })
  }

  sigCustomerPad = {}

  clearCustomer = () => {
    this.sigCustomerPad.clear()
  }

  trimCustomer = () => {
    this.setState({
      ...this.state,
      CustomerSignature: this.sigCustomerPad.getCanvas().toDataURL('image/png'),
      isCustomerSigning: false,
    })
  }

  render() {
    const {
      classes,
      checklist,
      open,
      onClose,
      info,
      footerInfo,
      header,
      footer,
      businessUnits,
      divisions,
      readOnly,
      onAttachment,
    } = this.props
    const data = checklist.Data.Sections
    const sections = _.filter(data, o => {
      return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y'
    })

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <Dialog
            TransitionComponent={!matches ? SlideUp : Grow}
            classes={{
              root: classNames(classes.root, ''),
              paper: matches ? classes.paper : 'full-screen-dialog',
            }}
            className={classNames(classes.root)}
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='md'
            fullScreen={!matches}
          >
            <Icon
              onClick={onClose}
              className='pin-r pin-t mr-12 mt-12 absolute cursor-pointer z-10 dialog-header-icon'
            >
              close
            </Icon>
            {matches && (
              <Icon
                onClick={this.exportToPDF}
                className='pin-l pin-t ml-12 mt-12 absolute cursor-pointer z-10 dialog-header-icon'
              >
                save_alt
              </Icon>
            )}
            <DialogContent className='pb-64 dialog-header'>
              <div id='checklist-print' className='sm:p-16 pt-0'>
                {/* <Typography variant="h6" style={{ alignItems: 'center' }} className="text-20 my-12 font-bold w-full text-center">{checklist.Name}</Typography> */}

                {info && <div className='w-full min-h-24'>{info}</div>}
                {sections &&
                  sections.map((section, index) => {
                    return (
                      <div key={index} className={`min-h-24 relative px-16`}>
                        <ChecklistSection
                          onAttachment={onAttachment}
                          readOnly={readOnly}
                          onNotesChange={this.handleNotesChange}
                          scrollLock={true}
                          section={section}
                          editable={false}
                          onChange={this.handleChange}
                          data={[...section.Data.Items]}
                        />
                      </div>
                    )
                  })}
                <div className='sm:flex px-16'>
                  <div className='w-full'>
                    <div className='w-full'>
                      <strong style={{ marginRight: 4 }}>
                        Technician Signature:
                      </strong>
                    </div>
                    <div
                      className='w-full relative mb-12'
                      onClick={() => {
                        if (!readOnly) {
                          this.setState({
                            ...this.state,
                            isTechnicianSigning: true,
                          })
                        }
                      }}
                      style={{
                        border: '1px dotted #3f3f3f',
                        width: '100%',
                        height: 128,
                        minHeight: 128,
                        borderRadius: 5,
                      }}
                    >
                      {this.state.TechnicianSignature && (
                        <img
                          className='pin-t pin-b pin-l pin-r m-auto absolute'
                          style={{ width: 'auto', height: '100%' }}
                          src={this.state.TechnicianSignature}
                        />
                      )}
                    </div>
                    <Dialog
                      open={this.state.isTechnicianSigning}
                      classes={{ paper: classes.signaturePaper }}
                      fullWidth
                      maxWidth='sm'
                      onClose={() => {
                        if (this.sigTechnicianPad.isEmpty()) {
                          this.setState({
                            ...this.state,
                            isTechnicianSigning: false,
                          })
                        }
                      }}
                    >
                      <div className='w-full mb-12'>
                        <strong style={{ marginRight: 4 }}>
                          Technician Signature:
                        </strong>
                      </div>
                      <div
                        className='w-full'
                        style={{
                          border: '1px dotted #3f3f3f',
                          borderRadius: 5,
                        }}
                      >
                        <SignatureCanvas
                          ref={ref => {
                            this.sigTechnicianPad = ref
                          }}
                          penColor='#66aef9'
                          canvasProps={{
                            className: 'sigCanvas',
                            style: { width: '100%', height: 150 },
                          }}
                        />
                      </div>
                      <div className='flex w-full mt-16'>
                        <Button
                          variant='contained'
                          className={classes.clearBtn}
                          fullWidth
                          onClick={this.clearTechnician}
                        >
                          Clear
                        </Button>
                        <div className='min-w-12'></div>
                        <Button
                          variant='contained'
                          className={classes.okBtn}
                          fullWidth
                          onClick={this.trimTechnician}
                        >
                          OK
                        </Button>
                      </div>
                    </Dialog>
                  </div>
                  <div className='sm:w-24 sm:h-12'></div>
                  <div className='w-full'>
                    <div className='w-full'>
                      <strong style={{ marginRight: 4 }}>
                        Customer Signature:
                      </strong>
                    </div>
                    <div
                      className='w-full relative mb-12'
                      onClick={() => {
                        if (!readOnly) {
                          this.setState({
                            ...this.state,
                            isCustomerSigning: true,
                          })
                        }
                      }}
                      style={{
                        border: '1px dotted #3f3f3f',
                        width: '100%',
                        height: 128,
                        minHeight: 128,
                        borderRadius: 5,
                      }}
                    >
                      {this.state.CustomerSignature && (
                        <img
                          className='pin-t pin-b pin-l pin-r m-auto absolute'
                          style={{ width: 'auto', height: '100%' }}
                          src={this.state.CustomerSignature}
                        />
                      )}
                    </div>
                    <Dialog
                      open={this.state.isCustomerSigning}
                      classes={{ paper: classes.signaturePaper }}
                      fullWidth
                      maxWidth='sm'
                      onClose={() => {
                        if (this.sigCustomerPad.isEmpty()) {
                          this.setState({
                            ...this.state,
                            isCustomerSigning: false,
                          })
                        }
                      }}
                    >
                      <div className='w-full mb-12'>
                        <strong style={{ marginRight: 4 }}>
                          Customer Signature:
                        </strong>
                      </div>
                      <div
                        className='w-full'
                        style={{
                          border: '1px dotted #3f3f3f',
                          borderRadius: 5,
                        }}
                      >
                        <SignatureCanvas
                          ref={ref => {
                            this.sigCustomerPad = ref
                          }}
                          penColor='#66aef9'
                          canvasProps={{
                            className: 'sigCanvas',
                            style: { width: '100%', height: 150 },
                          }}
                        />
                      </div>
                      <div className='flex w-full mt-16'>
                        <Button
                          variant='contained'
                          className={classes.clearBtn}
                          fullWidth
                          onClick={this.clearCustomer}
                        >
                          Clear
                        </Button>
                        <div className='min-w-12'></div>
                        <Button
                          variant='contained'
                          className={classes.okBtn}
                          fullWidth
                          onClick={this.trimCustomer}
                        >
                          OK
                        </Button>
                      </div>
                    </Dialog>
                  </div>
                </div>
                {checklist.Disclaimer && (
                  <div className='sm:flex'>
                    <div className='w-full px-16'>
                      <div
                        className='mb-12 rounded-6 p-8'
                        style={{ background: '#f1f1f1' }}
                      >
                        {checklist.Disclaimer && (
                          <div>
                            <div className='font-bold text-red text-10 text-center w-full'>
                              * {checklist.Disclaimer} *
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {footerInfo && (
                  <div className='w-full min-h-24'>{footerInfo}</div>
                )}
              </div>
            </DialogContent>
            {!readOnly && (
              <DialogActions className='dialog-actions justify-between m-0 p-8 pl-16 pin-b bg-white w-full absolute'>
                <React.Fragment>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.handleSave}
                    disabled={!this.canBeSubmitted()}
                  >
                    Save
                  </Button>
                  <IconButton
                    onClick={() => this.setState({ confirmDelete: true })}
                    disabled={!this.canBeSubmitted()}
                  >
                    <Icon>delete_sweep</Icon>
                  </IconButton>
                  <ConfirmationDialog
                    open={this.state.confirmDelete}
                    title={
                      <div>
                        <Icon className='mr-6 align-middle mb-4' color='error'>
                          delete_sweep
                        </Icon>
                        Are You Sure?
                      </div>
                    }
                    content={
                      <div className='w-full pt-8 pb-8'>{`Are you sure you wish to delete this checklist? This action cannot be undone.`}</div>
                    }
                    confirmText='Delete'
                    cancelText='Cancel'
                    onCancel={() =>
                      this.setState({ ...this.state, confirmDelete: false })
                    }
                    onConfirm={() =>
                      this.setState(
                        { ...this.state, confirmDelete: false },
                        this.handleDelete,
                      )
                    }
                  />
                </React.Fragment>
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}
Checklist.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({
  auth,
  checklistsApp,
  spReducers,
  dispatchBoardApp,
}) {
  return {
    user: spReducers.userProfiles.User,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Checklist),
)
