import * as Actions from '../actions';
import _ from '@lodash';

const initialState = null;

const boardReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_BOARD:
            {
                return {
                    ...action.payload
                };
            }
        case Actions.RESET_BOARD:
            {
                return initialState;
            }
        case Actions.ORDER_LIST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: action.payload,
                    }
                };
            }
        case Actions.ORDER_REQUEST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: action.payload,
                    }
                };
            }
        case Actions.ADD_LIST:
            {
                const { Lists } = state.Data;
                const List = action.payload;
                List.Data = {
                    SupportRequests: [],
                }
                if (Lists) {
                    Lists.push(List);
                };
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: Lists ? Lists : [List],
                    }
                };
            }
        case Actions.ADD_REQUEST:
            {
                const card = action.payload;
                card.Data = {
                    Attachments: [],
                    Comments: [],
                    Members: [],
                    Attachments: [],
                    Checklists: [],
                    Tags: [],
                };
                const { Lists } = state.Data;
                const List = _.find(Lists, { ID: card.ListID });
                if (List.Data.SupportRequests) {
                    List.Data.SupportRequests.push(card);
                } else {
                    List.Data.SupportRequests = [card];
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists
                    }
                }
            }
        case Actions.ADD_LABEL:
            {
                return {
                    ...state,
                    labels: [
                        ...state.labels,
                        action.payload
                    ]
                };
            }
        case Actions.UPDATE_REQUEST:
            {
                const { Lists } = state.Data;
                const List = _.find(Lists, { ID: action.payload.ListID });
                if (List && List.Data.SupportRequests) {
                    const { SupportRequests } = List.Data;
                    for (var i = 0; i < SupportRequests.length; i++) {
                        var card = SupportRequests[i];
                        if (card.ID == action.payload.ID) {
                            SupportRequests.splice(i, 1, action.payload);
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists,
                    }
                };
            }
        case Actions.REMOVE_REQUEST:
            {
                const { Lists } = state.Data;
                const List = _.find(Lists, { ID: action.payload.ListID });
                if (List && List.Data.SupportRequests) {
                    const { SupportRequests } = List.Data;
                    for (var i = 0; i < SupportRequests.length; i++) {
                        var card = SupportRequests[i];
                        if (card.ID == action.payload.ID) {
                            SupportRequests.splice(i, 1);
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists,
                    }
                };
            }
        case Actions.RENAME_LIST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: state.Data.Lists.map(list => {
                            if (list.ID === action.listId) {
                                list.Name = action.listTitle
                            }
                            return list;
                        })
                    }
                };
            }
        case Actions.REMOVE_LIST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: _.reject(state.Data.Lists, { ID: action.payload.ID })
                    }
                };
            }
        case Actions.CHANGE_BOARD_SETTINGS:
            {
                return {
                    ...state,
                    settings: action.payload
                };
            }
        case Actions.DELETE_BOARD:
            {
                return initialState;
            }
        case Actions.RENAME_BOARD:
            {
                return {
                    ...state,
                    Name: action.payload.Name,
                };
            }
        default:
            return state;
    }
};

export default boardReducer;
