import axios from 'axios';
import { getUserData } from 'main/content/apps/cost-types/store/actions/user.actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const GET_COST_TYPES = '[COST TYPES APP] GET COST TYPES';
export const SET_SEARCH_TEXT = '[COST TYPES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_COST_TYPES = '[COST TYPES APP] TOGGLE IN SELECTED COST TYPES';
export const SELECT_ALL_COST_TYPES = '[COST TYPES APP] SELECT ALL COST TYPES';
export const DESELECT_ALL_COST_TYPES = '[COST TYPES APP] DESELECT ALL COST TYPES';
export const OPEN_NEW_COST_TYPE_DIALOG = '[COST TYPES APP] OPEN NEW COST TYPE DIALOG';
export const CLOSE_NEW_COST_TYPE_DIALOG = '[COST TYPES APP] CLOSE NEW COST TYPE DIALOG';
export const OPEN_EDIT_COST_TYPE_DIALOG = '[COST TYPES APP] OPEN EDIT COST TYPE DIALOG';
export const UPDATE_EDIT_COST_TYPE_DIALOG = '[COST TYPES APP] UPDATE EDIT COST TYPE DIALOG';
export const CLOSE_EDIT_COST_TYPE_DIALOG = '[COST TYPES APP] CLOSE EDIT COST TYPE DIALOG';
export const ADD_COST_TYPE = '[COST TYPES APP] ADD COST TYPE';
export const UPDATE_COST_TYPE = '[COST TYPES APP] UPDATE COST TYPE';
export const REMOVE_COST_TYPE = '[COST TYPES APP] REMOVE COST TYPE';
export const REMOVE_COST_TYPES = '[COST TYPES APP] REMOVE COST TYPES';
export const TOGGLE_STARRED_COST_TYPE = '[COST TYPES APP] TOGGLE STARRED COST TYPE';
export const TOGGLE_STARRED_COST_TYPES = '[COST TYPES APP] TOGGLE STARRED COST TYPES';
export const SET_COST_TYPES_STARRED = '[COST TYPES APP] SET COST TYPES STARRED ';
export const ADD_COST_TYPE_GL_OVERRIDE = '[COST TYPES APP] ADD COST TYPE GL OVERRIDE';
export const UPDATE_COST_TYPE_GL_OVERRIDE = '[COST TYPES APP] UPDATE COST TYPE GL OVERRIDE';
export const REMOVE_COST_TYPE_GL_OVERRIDE = '[COST TYPES APP] REMOVE COST TYPE GL OVERRIDE';

export function getCostTypes(routeParams) {
    const request = axios.get('/api/costTypes-app/costTypes', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_COST_TYPES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedCostTypes(costTypeId) {
    return {
        type: TOGGLE_IN_SELECTED_COST_TYPES,
        costTypeId
    }
}


export function selectAllCostTypes() {
    return {
        type: SELECT_ALL_COST_TYPES
    }
}

export function deSelectAllCostTypes() {
    return {
        type: DESELECT_ALL_COST_TYPES
    }
}


export function openNewCostTypeDialog() {
    return {
        type: OPEN_NEW_COST_TYPE_DIALOG
    }
}

export function closeNewCostTypeDialog() {
    return {
        type: CLOSE_NEW_COST_TYPE_DIALOG
    }
}

export function openEditCostTypeDialog(data) {
    return {
        type: OPEN_EDIT_COST_TYPE_DIALOG,
        data
    }
}

export function closeEditCostTypeDialog() {
    return {
        type: CLOSE_EDIT_COST_TYPE_DIALOG
    }
}

export function addCostType(newCostType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post(`${window["apiLocation"]}/api/CostType`,
            newCostType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_COST_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `New Cost Type has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(getCostTypes(routeParams))
            })
        );
    };
}

export function updateCostType(costType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.put(`${window["apiLocation"]}/api/CostType?Co=${encodeURIComponent(costType.Co)}&CostType=${encodeURIComponent(costType.CostType)}`,
            costType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_COST_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Cost Type #${costType.CostType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(getCostTypes(routeParams))
            })
        );
    };
}

export function removeCostType(costTypeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post('/api/costTypes-app/remove-costType', {
            costTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_COST_TYPE
                })
            ]).then(() => dispatch(getCostTypes(routeParams)))
        );
    };
}


export function removeCostTypes(costTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post('/api/costTypes-app/remove-costTypes', {
            costTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_COST_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_COST_TYPES
                })
            ]).then(() => dispatch(getCostTypes(routeParams)))
        );
    };
}

export function toggleStarredCostType(costTypeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post('/api/costTypes-app/toggle-starred-costType', {
            costTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_COST_TYPE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCostTypes(routeParams)))
        );
    };
}

export function toggleStarredCostTypes(costTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post('/api/costTypes-app/toggle-starred-costTypes', {
            costTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_COST_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_COST_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCostTypes(routeParams)))
        );
    };
}

export function setCostTypesStarred(costTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post('/api/costTypes-app/set-costTypes-starred', {
            costTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_COST_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_COST_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCostTypes(routeParams)))
        );
    };
}

export function setCostTypesUnstarred(costTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().costTypesApp.costTypes;

        const request = axios.post('/api/costTypes-app/set-costTypes-unstarred', {
            costTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_COST_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_COST_TYPES
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function addCostTypeGLOverride(override) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.costTypesApp.costTypes.costTypeDialog;

        const request = axios.post(`${window["apiLocation"]}/api/CostTypeGLOverride`,
            override
        );

        return request.then((response) => {
            data.Data.Overrides.push(response.data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_COST_TYPE_DIALOG,
                    data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Override has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function updateCostTypeGLOverride(override) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.costTypesApp.costTypes.costTypeDialog;

        const request = axios.put(`${window["apiLocation"]}/api/CostTypeGLOverride?Co=${encodeURIComponent(override.Co)}&CostType=${encodeURIComponent(override.CostType)}&BusinessUnit=${encodeURIComponent(override.BusinessUnit)}&Division=${encodeURIComponent(override.Division)}`,
            override
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Overrides, { ID: response.data.ID });
            if (index > -1) {
                data.Data.Overrides.splice(index, 1, response.data);
            };
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_COST_TYPE_DIALOG,
                    data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Override has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeCostTypeGLOverride(override) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.costTypesApp.costTypes.costTypeDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/CostTypeGLOverride?Co=${encodeURIComponent(override.Co)}&CostType=${encodeURIComponent(override.CostType)}&BusinessUnit=${encodeURIComponent(override.BusinessUnit)}&Division=${encodeURIComponent(override.Division)}`);

        return request.then((response) => {
            const index = _.findIndex(data.Data.Overrides, { ID: override.ID });
            if (index > -1) {
                data.Data.Overrides.splice(index, 1);
            };
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_COST_TYPE_DIALOG,
                    data
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Override has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}
