import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Dialog, DialogActions, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { AccountCircle, Assignment, AttachFile, List as ListIcon, LocalShipping, Payment, Receipt } from '@material-ui/icons';
import axios from 'axios';
import classNames from 'classnames';
import FileList from 'main/content/apps/file-manager/FileList';
import TechUAList from 'main/content/apps/tech-ua/TechUAList';
import { impress } from 'main/content/compression/impress';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector';
import * as WorkOrderActions from '../work-orders/store/actions';
import * as Actions from './store/actions';
import TechnicianProfile from './TechnicianProfile';
import TechnicianProjectionList from './TechnicianProjectionList';
import TechnicianSkillsList from './TechnicianSkillsList';
import TechnicianShiftsList from './TechnicianShiftsList';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { openPreloadTechnicianDialog } from './store/actions/technicians.actions'
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography ref={props.refProp} component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateProjection: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteProjection: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newTechnicianState = {
    ID: null,
    avatar: 'assets/images/avatars/profile.jpg',
    Co: null,
    Technician: null,
    Employee: null,
    FirstName: null,
    LastName: null,
    MiddleInitial: null,
    TeamCategory: null,
    TeamRole: null,
    Phone: null,
    Email: null,
    Bio: null,
    ExemptYN: 'N',
    Notes: null,
    ActiveYN: 'Y',
    DefaultBusinessUnit: null,
    DefaultDivision: null,
    DefaultDepartment: null,
    DefaultCommission: null,
    value: 1,
    histTab: 0,
    Data: {
        WorkOrders: [],
        Trips: [],
        Timecard: null,
    },
    showTabs: false,
    projection: {
        Co: null,
        Technician: null,
        Mth: null,
        Projected: null,
        CostTarget: null,
    },
    dialogHeight: null,
    minHeight: null,
    location: null
};

const TechnicianMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

class TechniciansDialog extends Component {
    state = { ..._.cloneDeepWith(newTechnicianState) };

    ref = React.createRef();

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // window["warn"]("tech dialog componentDidUpdate")
        /**
         * After Dialog Open
         */
        newTechnicianState.Co = this.props.Co;
        if (!prevProps.technicianDialog.props.open && this.props.technicianDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.technicianDialog.type === 'edit' &&
                this.props.technicianDialog.data &&
                !_.isEqual(this.props.technicianDialog.data, prevProps.technicianDialog.data)) {
                const data = this.props.technicianDialog.data;
                this.setState({ ..._.cloneDeepWith(newTechnicianState), ...data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.technicianDialog.type === 'new' &&
                !_.isEqual(_.cloneDeepWith(newTechnicianState), prevState)) {
                this.setState({ ..._.cloneDeepWith(newTechnicianState), value: 1 });
            }
        }
        //window["log"]('STATE COMPARISON', this.props.technicianDialog, prevProps, prevState);
        if (prevProps.technicianDialog.props.open && this.props.technicianDialog.props.open) {
            if (this.props.technicianDialog.type === 'data' &&
                this.props.technicianDialog.data &&
                !_.isEqual(this.props.technicianDialog.data, prevProps.technicianDialog.data)) {
                const data = this.props.technicianDialog.data;
                this.props.technicianDialog.type = 'edit';
                this.setState({ ..._.cloneDeepWith(newTechnicianState), ...this.props.technicianDialog.data, value: this.state.value });
            }
        }
        if (prevProps.technicianDialog.props.open && this.props.technicianDialog.props.open) {
            if (this.props.technicianDialog.type === 'add' &&
                this.props.technicianDialog.data &&
                !_.isEqual(this.props.technicianDialog.data, prevProps.technicianDialog.data)) {
                const data = this.props.technicianDialog.data;
                this.props.technicianDialog.type = 'edit';
                this.setState({ ..._.cloneDeepWith(newTechnicianState), ...this.props.technicianDialog.data, value: this.state.value });
            }
        }
        if (prevProps.technicianDialog.props.open && this.props.technicianDialog.props.open) {
            if (this.props.technicianDialog.type === 'update' &&
                this.props.technicianDialog.data &&
                !_.isEqual(this.props.technicianDialog.data, prevProps.technicianDialog.data)) {
                const data = this.props.technicianDialog.data;
                this.props.technicianDialog.type = 'edit';
                this.setState({ ..._.cloneDeepWith(newTechnicianState), ...this.props.technicianDialog.data, value: this.state.value });
            }
        }
    }


    handleEmployeeChange = (emp) => {
        const { employees, technicians } = this.props;
        let tech = {};
        for (var i = 0; i < technicians.length; i++) {
            if (technicians[i].Employee == emp) {
                tech = technicians[i];
                tech.avatar = (!tech.avatar ? newTechnicianState.avatar : tech.avatar);
                this.props.technicianDialog.type = 'edit';
                this.setState(tech);
            }
        };
        if (!tech.Technician) {
            for (var i = 0; i < employees.length; i++) {
                if (employees[i].Employee == emp) {
                    tech = employees[i];
                }
            }
            if (tech.Employee) {
                tech.Technician = (`${tech.LastName}${tech.FirstName}`).replace(/ |\.|,|\(|\)|-/g, '').toUpperCase();
                tech.histTab = 0;
                // tech.value = 0;
                tech.avatar = 'assets/images/avatars/profile.jpg';
                tech.Data = {
                    WorkOrders: []
                };
                tech.Bio = '';
                this.props.technicianDialog.type = 'new';
                this.setState(tech);
            } else {
                this.props.technicianDialog.type = 'new';
                this.setState(newTechnicianState);
            }
        }
    };

    closeComposeDialog = () => {
        this.props.technicianDialog.type === 'edit' ? this.props.closeEditTechnicianDialog() : this.props.closeNewTechnicianDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true})
    };

    closeDialogForSplitscreen = () => {
        this.props.technicianDialog.type === 'edit' ? this.props.closeEditTechnicianDialog() : this.props.closeNewTechnicianDialog();
    };

    canBeSubmitted() {
        const { Co, Employee, Technician, FirstName, LastName } = this.state;
        return (
            Co && Employee && Technician && FirstName && LastName && Co > 0 && FirstName.length > 0 && Technician.length > 0 && LastName.length > 0
        );
    }

    handleMthChange = (event) => {
        let { Mth } = this.state.projection;
        if (event) {
            Mth = event.startOf("month").toDate();
        } else {
            Mth = null;
        }
        this.setState({ ...this.state, projection: { ...this.state.projection, Mth } });
    }

    fileSelectedHandler = (event) => {
        let { ID, Data } = this.state;
        const { technicianDialog } = this.props;
        impress(event.target.files[0], 1024, 50, true, (img) => {
            // window["log"](img);
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(this.state.Co)}&Type=Technician&ID=${encodeURIComponent(this.state.Technician)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
                if (Data) {
                    Data.Avatar = img.base64;
                } else {
                    Data = { Avatar: img.base64 };
                }
                this.setState({ Data });
            });
        });
    }

    toggleTabs = () => {
        const { showTabs } = this.state;
        this.setState({ ...this.state, showTabs: !showTabs });
    }

    updateProjection = () => {

        const { Co, Technician, projection } = this.state;

        const { Mth } = projection;

        const mth = moment(Mth).format("MM-DD-YYYY");

        const request = axios.put(`${window["apiLocation"]}/api/TechnicianProjection?Co=${Co}&Tech=${encodeURIComponent(Technician)}&Mth=${encodeURIComponent(mth)}`,
            {
                ...projection,
                Co,
                Technician,
                CostTarget: this.state.projection.CostTarget ? this.state.projection.CostTarget : this.state.Data.CostTarget ? this.state.Data.CostTarget.Target : null,
                MetYN: 'N',
                Data: {
                    ErrMsg: null
                }
            }
        );

        request.then((response) => {
            if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                const { Projections } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Projections, { Co, Technician, Mth });
                if (index > -1) {
                    Projections.splice(index, 1, response.data);
                } else {
                    Projections.push(response.data);
                }
                this.setState({
                    ...this.state,
                    Data: { ...this.state.Data, Projections },
                    projection: {
                        Co: null,
                        Technician: null,
                        Mth: null,
                        Projected: null,
                        CostTarget: null,
                    }
                }, () => {
                    this.props.showMessage({
                        message: `Technician Projection was updated successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        });
    }

    removeProjection = () => {

        const { Co, Technician, projection } = this.state;

        const { Mth } = projection;

        const mth = moment(Mth).format("MM-DD-YYYY");

        const request = axios.delete(`${window["apiLocation"]}/api/TechnicianProjection?Co=${Co}&Tech=${encodeURIComponent(Technician)}&Mth=${encodeURIComponent(mth)}`);

        request.then((response) => {
            if (response.data && response.data.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                const { Projections } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Projections, { Co, Technician, Mth });
                if (index > -1) {
                    Projections.splice(index, 1);
                }
                this.setState({
                    ...this.state,
                    Data: { ...this.state.Data, Projections },
                    projection: {
                        Co: null,
                        Technician: null,
                        Mth: null,
                        Projected: null,
                        CostTarget: null,
                    }
                }, () => {
                    this.props.showMessage({
                        message: `Technician Projection was deleted successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        });
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        const { technicianDialog } = this.props;
        const { value, showTabs } = this.state;

        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 56,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e)
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>close</Icon>
                </IconButton>
                {showTabs &&
                    <Tabs
                        value={value}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Icon>assignment_ind</Icon>} label="Profile" />
                        <Tab disableRipple icon={<AccountCircle />} label="Info" />
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Badge badgeContent={this.state.Data && this.state.Data.Skills ? this.state.Data.Skills.length : 0} color="error"><Icon>build</Icon></Badge>} label="Skills" />
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Badge badgeContent={this.state.Data && this.state.Data.Shifts ? this.state.Data.Shifts.length : 0} color="error"><Icon>date_range</Icon></Badge>} label="Shifts" />
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Badge badgeContent={this.state.Data && this.state.Data.UATime ? this.state.Data.UATime.length : 0} color="error"><Icon>event_busy</Icon></Badge>} label="Unavailable Time" />
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Badge badgeContent={this.state.Data && this.state.Data.Projections ? this.state.Data.Projections.length : 0} color="error"><Icon>today</Icon></Badge>} label="Projections" />
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Badge badgeContent={this.state.Data && this.state.Data.Attachments ? this.state.Data.Attachments.length : 0} color="error"><AttachFile /></Badge>} label="Attachments" />
                        <Tab disableRipple disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1} icon={<Icon>insert_chart</Icon>} label="Performance" />
                    </Tabs>
                }
            </>
        )
    }

    ToolbarContents = () => {
        const { classes, technicianDialog } = this.props
        const { Co,showTabs } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';

        return (
            <>
                <Typography variant="subtitle1" color="inherit">
                    {['new', 'customer'].indexOf(technicianDialog.type) > -1 ? 'New Technician' :
                        <div className="flex">
                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classNames(classes.avatar, "mt-0")} alt={this.state.Technician} src={(this.state.Data && this.state.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${this.state.Data.Avatar}&Thumb=true`) : avatar)} />
                            {`${this.state.FirstName} ${this.state.LastName}`}
                        </div>
                    }
                </Typography>
                <IconButton style={{
                    color: 'white'
                }}
                    onClick={() => {
                        this.toggleTabs();
                    }}
                >
                    {showTabs ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                </IconButton>
            </>
        )
    }

    render() {
        const { classes, theme, technicianDialog, addTechnician, updateTechnician, workOrders, employees, trips, securables, removeTechnician, openNewWorkOrderDialog, googleMapsApiKey, businessUnits, divisions, departments, technicians } = this.props
        const { Co, Technician, Phone, value, histTab, showTabs } = this.state;
        const histTabs = ['Work Order', 'Visit', 'Invoice', 'Payment', 'Estimate'];
        const avatar = 'assets/images/avatars/profile.jpg';
        const histIcons = [<Assignment className="text-24 ml-12 mr-12" />, <LocalShipping className="text-24 ml-12 mr-12" />, <Receipt className="text-24 ml-12 mr-12" />, <Payment className="text-24 ml-12 mr-12" />, <ListIcon className="text-24 ml-12 mr-12" />];
        const techAccessLevel = _.find(securables, { Securable: "technicians" });

        // window["warn"]("technicianDialog", technicianDialog)

        const dialogProps = {
            ...technicianDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: value === 0 ? "sm" : "md",
            //hideBackdrop: true,
            disableEnforceFocus: true
        }

        let header = this.state.FirstName ? `Technician ${this.state.FirstName} ${this.state.LastName}` : null;

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={null}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={header}
                        type="Technician"
                        picRef={this.ref.current}
                        icon="people"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openPreloadTechnicianDialog}
                        description={this.state.Description}
                        storeDialog={technicianDialog}
                        TypeState={this.state.Technician}
                        screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >

                        {value === 0 && technicianDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}><TechnicianProfile trips={[...this.state.Data.Trips]} timecard={{ ...this.state.Data.Timecard }} technician={{ ...this.state }} /></TabContainer>}
                        {value === 1 && <TabContainer refProp={this.ref} maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                            {(this.state.FirstName && this.state.LastName && this.state.FirstName.length > 0 && this.state.LastName.length > 0) &&
                                <div className={classNames(classes.profile, "flex flex-col items-center justify-center")}>
                                    <Avatar style={{ cursor: 'pointer', boxShadow: '2px 2px 10px 2px #3f3f3f' }} onClick={() => this.fileInput.click()} className="w-1/4 h-auto" alt="contact avatar" src={(this.state.Data && this.state.Data.Avatar ? (!isNaN(this.state.Data.Avatar) ? getProfileImage(`Co=${this.state.Co}&ID=${this.state.Data.Avatar}`) : this.state.Data.Avatar) : this.state.avatar)} />
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={this.fileSelectedHandler}
                                        ref={fileInput => this.fileInput = fileInput}
                                        disabled={['new', 'customer'].indexOf(technicianDialog.type) > -1}
                                    />
                                    <Typography variant="h6" color="inherit" className="p-16">
                                        {`${this.state.FirstName} ${this.state.MiddleInitial ? this.state.MiddleInitial : ''} ${this.state.LastName}`}
                                    </Typography>
                                </div>
                            }
                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">account_circle</Icon>
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Employee"
                                    options={_.filter(employees, (o) => o.Employee === this.state.Employee || _.findIndex(technicians, { Employee: o.Employee }) < 0)}
                                    menuItemComponent={(value) =>
                                        <MenuItem key={value.Employee} value={value.Employee}>
                                            <div className="flex">
                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Employee} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                                {`${value.FirstName} ${value.LastName}`}
                                            </div>
                                        </MenuItem>
                                    }
                                    portal={true}
                                    value={this.state.Employee || ''}
                                    onSelect={(option) => this.handleEmployeeChange(option.Employee)}
                                    disabled={technicianDialog.type !== 'new' && this.state.Employee}
                                />
                                {/* <TextField
                                    className={classes.formControl}
                                    label="Employee"
                                    id="employee"
                                    name="Employee"
                                    value={this.state.Employee || ''}
                                    onChange={this.handleEmployeeChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    select
                                >
                                    {employees && employees.map((value, index) => (
                                        <MenuItem key={index} value={value.Employee}>
                                            <div className="flex">
                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Employee} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true` : avatar)} />
                                                {`${value.FirstName} ${value.LastName}`}
                                            </div>
                                        </MenuItem>
                                    ))
                                    }
                                </TextField> */}
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Technician"
                                    id="technician"
                                    name="Technician"
                                    value={this.state.Technician || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    disabled={(technicianDialog.type !== 'new' || (this.state.Technician && this.state.Technician.length < 1))}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="First Name"
                                    id="firstName"
                                    name="FirstName"
                                    value={this.state.FirstName || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classNames(classes.formControl, "sm:w-1/2")}
                                    label="M.I."
                                    id="middleInitial"
                                    name="MiddleInitial"
                                    value={this.state.MiddleInitial || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Last Name"
                                    id="lastName"
                                    name="LastName"
                                    value={this.state.LastName || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">star</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Bio"
                                    id="bio"
                                    name="Bio"
                                    value={this.state.Bio || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    multiline
                                    rows={5}
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                />
                            </div>
                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">business</Icon>
                                </div>

                                <TextField
                                    className={classes.formControl}
                                    label="Default Business Unit"

                                    select
                                    id="defaultBusinessUnit"
                                    name="DefaultBusinessUnit"
                                    value={this.state.DefaultBusinessUnit || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value, index) => (
                                        <MenuItem key={index} value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>

                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Default Division"
                                    id="defaultDivision"
                                    select
                                    name="DefaultDivision"
                                    value={this.state.DefaultDivision || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                >
                                    {(this.state.DefaultBusinessUnit && this.state.DefaultBusinessUnit.length > 0 ?
                                        (divisions && divisions.length > 0 ? divisions.map((value) => (
                                            (this.state.DefaultBusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Division}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Business Unit
                                        </MenuItem>
                                    )
                                    }
                                </TextField>

                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Default Department"
                                    id="defaultDepartment"
                                    select
                                    name="DefaultDepartment"
                                    value={this.state.DefaultDepartment || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                >
                                    {(this.state.DefaultBusinessUnit && this.state.DefaultDivision && this.state.DefaultDivision.length > 0 && this.state.DefaultBusinessUnit.length > 0 ?
                                        (departments && departments.length > 0 ? departments.map((value) => (
                                            (this.state.DefaultDivision == value.Division && this.state.DefaultBusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Department}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Division
                                        </MenuItem>
                                    )
                                    }
                                </TextField>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">phone</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Phone"
                                    id="phone"
                                    name="Phone"
                                    value={this.state.Phone || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Email"
                                    id="email"
                                    name="Email"
                                    value={this.state.Email || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Default Commission Percent"
                                    id="defaultCommission"
                                    name="DefaultCommission"
                                    value={this.state.DefaultCommission || ''}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    inputProps={{
                                        type: "number",
                                        min: 0,
                                        max: 100
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">note</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                    disabled={(!this.state.Technician || this.state.Technician.length < 1)}
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </TabContainer>}
                        {/* {value === 2 && technicianDialog.type == 'edit' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                            <div className={classes.hist}>
                                <Drawer
                                    variant="permanent"
                                    className={classNames(classes.drawer, {
                                        [classes.drawerOpen]: this.state.open,
                                        [classes.drawerClose]: !this.state.open,
                                    })}
                                    classes={{
                                        paper: classNames(classes.shelf, {
                                            [classes.drawerOpen]: this.state.open,
                                            [classes.drawerClose]: !this.state.open,
                                        }),
                                    }}
                                    open={this.state.open}
                                >
                                    <List>
                                        <ListItem onClick={() => this.setState({ histTab: 0 })} button key="Visits">
                                            <ListItemIcon><Tooltip title="Visits" placement="right"><LocalShipping color={histTab === 0 ? "primary" : "action"} /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 1 })} button key="Work Orders">
                                            <ListItemIcon><Tooltip title="Work Orders" placement="right"><Assignment color={histTab === 1 ? "primary" : "action"} /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 2 })} button key="Invoices">
                                            <ListItemIcon><Tooltip title="Invoices" placement="right"><Receipt color={histTab === 2 ? "primary" : "action"} /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 3 })} button key="Payments">
                                            <ListItemIcon><Tooltip title="Payments" placement="right"><Payment color={histTab === 3 ? "primary" : "action"} /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 4 })} button key="Estimates">
                                            <ListItemIcon><Tooltip title="Estimates" placement="right"><ListIcon color={histTab === 4 ? "primary" : "action"} /></Tooltip></ListItemIcon>
                                        </ListItem>
                                    </List>
                                </Drawer>
                                <div className={classes.content}>
                                    <div className={classes.histTable}>
                                        {histTab === 0 && <TabContainer padding={8}>
                                            <TripsList technician={this.state} selectedTripData={[..._.filter(trips, { Technician: this.state.Technician })]} />
                                        </TabContainer>
                                        }
                                        {histTab === 1 &&
                                            <TabContainer padding={8}>
                                                <WorkOrdersList technician={this.state} selectedWOData={[..._.filter(workOrders, (o) => { return _.filter(o.Data.Trips, { Technician: this.state.Technician }).length > 0 })]} />
                                            </TabContainer>
                                        }
                                        {histTab === 2 && <TabContainer>Invoices</TabContainer>}
                                        {histTab === 3 && <TabContainer>Payments</TabContainer>}
                                        {histTab === 4 && <TabContainer>Estimates</TabContainer>}
                                    </div>
                                </div>
                            </div>
                        </TabContainer>} */}

                        {value === 2 && technicianDialog.type !== 'new' &&
                            <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                                <TechnicianSkillsList parentState={this.state} setStateFxn={this.setState.bind(this)} />
                            </TabContainer>
                        }

                        {value === 3 && technicianDialog.type !== 'new' &&
                            <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                                <TechnicianShiftsList parentState={this.state} setStateFxn={this.setState.bind(this)} />
                            </TabContainer>
                        }
                        {value === 4 && technicianDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}><TechUAList selectedTechUAData={[...this.state.Data.UATime]} /></TabContainer>}
                        {value === 5 && technicianDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                            {/* {accessLevel && accessLevel.AccessLevel !== "R" && */}
                            <React.Fragment>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">add</Icon>{`${!this.state.projection.MetYN ? "Add" : "Update"} Projection`}</Typography>
                                <div className="sm:flex">
                                    <DatePicker
                                        className={classes.formControl}
                                        views={["year", "month"]}
                                        label="Month"
                                        id="month"
                                        openTo="month"
                                        format="MMMM YYYY"
                                        value={this.state.projection.Mth}
                                        onChange={this.handleMthChange}
                                        inputVariant="outlined"
                                        required
                                        fullWidth
                                        autoOk={true}
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Projected Revenue"
                                        id="projection"
                                        name="projection.Projected"
                                        value={this.state.projection.Projected || ''}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: this.state.projection.Projected ? <InputAdornment position="start">$</InputAdornment> : undefined,
                                            type: "number"
                                        }}
                                        required
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Cost Target"
                                        id="costTarget"
                                        name="projection.CostTarget"
                                        value={this.state.projection.CostTarget ? this.state.projection.CostTarget : this.state.Data.CostTarget ? this.state.Data.CostTarget.Target : ''}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {!this.state.projection.Co ?
                                        <Button
                                            className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                            variant="contained"
                                            color="primary"
                                            onClick={this.updateProjection}
                                        >
                                            Add
                                        </Button> :
                                        <div className="w-full flex">
                                            <Button
                                                className={classNames(classes.formControl, classes.updateProjection, "w-full")}
                                                variant="contained"
                                                color="primary"
                                                onClick={this.updateProjection}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                className={classNames(classes.formControl, classes.deleteProjection)}
                                                variant="contained"
                                                onClick={this.removeProjection}
                                            >
                                                <Icon>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </React.Fragment>
                            {/* } */}
                            <TechnicianProjectionList data={this.state.Data.Projections} onSelected={(projection) => { this.setState({ ...this.state, projection }) }} />
                        </TabContainer>
                        }
                        {value === 6 && technicianDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}><FileList attachments={this.state.Data.Attachments} /></TabContainer>}
                        {value === 7 && technicianDialog.type !== 'new' &&
                            <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                                <DashboardSelector data={this.state} form="technician" />
                            </TabContainer>
                        }

                        {techAccessLevel && ['E', 'F'].indexOf(techAccessLevel.AccessLevel) > -1 && (value === 1 && ['new', 'customer'].indexOf(technicianDialog.type) > -1) &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        var rec = this.state;
                                        addTechnician(rec);
                                        //this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        }
                        {(techAccessLevel && ['E', 'F'].indexOf(techAccessLevel.AccessLevel) > -1 && value === 1 && ['new', 'customer'].indexOf(technicianDialog.type) < 0) &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        var rec = this.state;
                                        updateTechnician(rec);
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                                </Button>
                                {/* <IconButton
                                    onClick={() => {
                                        removeTechnician(this.state.id);
                                        this.closeComposeDialog();
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </IconButton> */}
                            </DialogActions>
                        }
                    </DraggableDialog>
                }
            </Media>
        );
    }
}
TechniciansDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTechnicianDialog: Actions.closeEditTechnicianDialog,
        closeNewTechnicianDialog: Actions.closeNewTechnicianDialog,
        addTechnician: Actions.addTechnician,
        updateTechnician: Actions.updateTechnician,
        removeTechnician: Actions.removeTechnician,
        openEditTechnicianDialog: Actions.openEditTechnicianDialog,
        openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
        openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
        showMessage: showMessage,
        impress: impress,
        openPreloadTechnicianDialog,
    }, dispatch);
}

function mapStateToProps({ auth, techniciansApp, spReducers, dispatchBoardApp }) {
    return {
        technicianDialog: techniciansApp.technicians.technicianDialog,
        googleMapsApiKey: googleMapsApiKey,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        user: spReducers.userProfiles.User.UserName,
        workOrders: spReducers.workOrders,
        trips: dispatchBoardApp.dispatchBoard.trips,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TechniciansDialog));
