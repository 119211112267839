import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FuseAnimate } from '@fuse'
import {
  openNewTechUADialog,
  openEditTechUADialog,
} from 'main/content/apps/tech-ua/store/actions'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import { updateWorkOrderScope } from '../work-order-scopes/store/actions'
import { setWorkOrderData } from '../../../../store/actions/spConnection/work-orders.actions'
import classNames from 'classnames'
import moment from 'moment'
import DispatchBoardTrip from './DispatchBoardTrip'
import _ from '@lodash'

const styles = theme => ({
  root: {},
  droppable: {
    background: 'rgba(0,0,0,.25)',
  },
  unavailable: {
    background: 'rgba(0, 0, 0, .25)',
    width: 'calc(100% + 1px)',
  },
  dropCell: {
    '& .action-item': {
      display: 'none',
      height: 48,
    },
    '&:hover .action-item': {
      display: 'block',
      height: 48,
      width: 48,
    },
  },
})

class DispatchDropCell extends Component {
  state = {
    time: '',
    technician: {},
    trips: [],
    dragover: false,
    exactTime: null,
    selectedTripID: null,
  }

  componentDidMount() {
    const { trips, technician, time, row, occupied, rows, date, techUA } =
      this.props

    const dt = new Date(
      `${date.toLocaleDateString('en-US')} ${time ? time : ''}`,
    )
    const hasShift =
      technician.Data.Shifts.length > 0
        ? _.find(
            technician.Data.Shifts,
            o =>
              o.Day === dt.getDay() &&
              (!time ||
                (new Date(
                  `${date.toLocaleDateString('en-US')} ${o.StartTime.slice(
                    0,
                    5,
                  )}`,
                ) <= dt &&
                  new Date(
                    `${date.toLocaleDateString('en-US')} ${o.EndTime.slice(
                      0,
                      5,
                    )}`,
                  ) > dt)),
          )
        : true
    let unavailable = !hasShift
    if (!unavailable) {
      unavailable = _.find(techUA, o => {
        var start = new Date(o.StartTime || o.StartDate)
        var end = o.EndDate
          ? new Date(o.EndDate).setHours(24)
          : o.StartTime && o.Duration
            ? moment(start).add(o.Duration * 60, 'm')
            : o.AllDayYN
              ? new Date(start).setHours(24)
              : start
        return o.AllDayYN !== 'Y' && !time
          ? false
          : o.Technician === technician.Technician && start <= dt && end > dt
      })
    }

    let exactTime = null
    let scopeNum = null
    if (trips.length > 0) {
      const WorkOrder = trips[0]
      if (WorkOrder.Trip < 1) {
        if (WorkOrder.Data) {
          const { Assignments } = WorkOrder.Data
          if (Assignments) {
            scopeNum = Assignments[0].Scope
          }
          if (
            Assignments &&
            Assignments.length > 0 &&
            WorkOrder.Data.WorkOrder &&
            WorkOrder.Data.WorkOrder.Data &&
            WorkOrder.Data.WorkOrder.Data.Scopes
          ) {
            const scope = _.find(WorkOrder.Data.WorkOrder.Data.Scopes, {
              Scope: Assignments[0].Scope,
            })
            if (scope) {
              exactTime = scope.ExactTime
            }
          }
        }
      }
    }

    this.setState({
      ...this.state,
      technician,
      time,
      trips,
      row,
      occupied,
      rows,
      dt,
      unavailable,
      exactTime,
      scopeNum,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { trips, technician, row, occupied, rows, time, date, techUA } =
      this.props
    if (
      !_.isEqual(trips, prevProps.trips) ||
      !_.isEqual(technician, prevProps.technician) ||
      !_.isEqual(row, prevProps.row) ||
      !_.isEqual(occupied, prevProps.occupied) ||
      !_.isEqual(rows, prevProps.rows) ||
      !_.isEqual(date, prevProps.date) ||
      !_.isEqual(
        techUA,
        prevProps.techUA,
      ) /* || !_.isEqual(time, prevProps.time)*/
    ) {
      const dt = new Date(
        `${date.toLocaleDateString('en-US')} ${time ? time : ''}`,
      )
      const hasShift =
        technician.Data.Shifts.length > 0
          ? _.find(
              technician.Data.Shifts,
              o =>
                o.Day === dt.getDay() &&
                (!time ||
                  (new Date(
                    `${date.toLocaleDateString('en-US')} ${o.StartTime.slice(
                      0,
                      5,
                    )}`,
                  ) <= dt &&
                    new Date(
                      `${date.toLocaleDateString('en-US')} ${o.EndTime.slice(
                        0,
                        5,
                      )}`,
                    ) > dt)),
            )
          : true
      let unavailable = !hasShift
      if (!unavailable) {
        unavailable = _.find(techUA, o => {
          var start = new Date(o.StartTime || o.StartDate)
          var end = o.EndDate
            ? new Date(o.EndDate).setHours(24)
            : o.StartTime && o.Duration
              ? moment(start).add(o.Duration * 60, 'm')
              : o.AllDayYN
                ? new Date(start).setHours(24)
                : start
          return o.AllDayYN !== 'Y' && !time
            ? false
            : o.Technician === technician.Technician && start <= dt && end > dt
        })
      }

      let exactTime = null
      let scopeNum = null
      if (trips.length > 0) {
        const WorkOrder = trips[0]
        if (WorkOrder.Trip < 1) {
          if (WorkOrder.Data) {
            const { Assignments } = WorkOrder.Data
            window['warn']('dispatch drop cell assignments', Assignments)
            if (Assignments) {
              scopeNum = Assignments[0].Scope
            }
            if (
              Assignments &&
              Assignments.length > 0 &&
              WorkOrder.Data.WorkOrder &&
              WorkOrder.Data.WorkOrder.Data &&
              WorkOrder.Data.WorkOrder.Data.Scopes
            ) {
              const scope = _.find(WorkOrder.Data.WorkOrder.Data.Scopes, {
                Scope: Assignments[0].Scope,
              })
              if (scope) {
                exactTime = scope.ExactTime
              }
            }
          }
        }
      }

      this.setState({
        ...this.state,
        technician,
        trips,
        row,
        occupied,
        rows,
        dt,
        unavailable,
        exactTime,
        scopeNum,
      })
    }
  }

  dragEnter = (event, el) => {
    if (!this.props.disableDrop) {
      event.preventDefault()
      this.setState({ dragover: true })
    }
  }

  dragOver = (event, el) => {
    if (!this.props.disableDrop) {
      event.preventDefault()
    }
  }

  dragLeave = (event, el) => {
    if (!this.props.disableDrop) {
      event.preventDefault()
      this.setState({ dragover: false })
    }
  }

  drop = event => {
    if (!this.props.disableDrop) {
      const { trips, technician, time, occupied } = this.state
      const { date, user, services } = this.props
      const WorkOrder = JSON.parse(event.dataTransfer.getData('text'))
      const { Co } = WorkOrder
      event.preventDefault()
      this.props.setDragging(false)
      let row = 0
      while (occupied.indexOf(row) > -1) {
        row += 1
      }
      const ScheduledDate = this.formatDateInput(date)
      const ScheduledTime = `${ScheduledDate} ${time}`
      occupied.push(row)

      if (this.props.isCmdCtrlPressed && WorkOrder.Trip === 0) {
        this.setState({ dragover: false, trips }, () => {
          const { workOrders } = this.props
          const index = _.findIndex(workOrders, {
            WorkOrder: WorkOrder.WorkOrder,
          })
          const wo = index > -1 ? _.cloneDeepWith(workOrders[index]) : null
          const scopes = []
          WorkOrder.Data.Assignments.map(assn => {
            const scope = _.find(WorkOrder.Data.WorkOrder.Data.Scopes, {
              Scope: assn.Scope,
            })
            if (scope && scope.LeadTechnician !== technician.Technician) {
              scopes.push({ ...scope, LeadTechnician: technician.Technician })
              if (wo) {
                const scp = _.find(wo.Data.Scopes, { Scope: scope.Scope })
                if (scp) {
                  scp.LeadTechnician = technician.Technician
                }
              }
            }
          })
          if (wo) {
            workOrders.splice(index, 1, wo)
            Promise.all([this.props.setWorkOrderData([...workOrders])]).then(
              () => {
                this.props.setBoardTrips(this.props.allTrips)
              },
            )
          }
          scopes.map(scp => {
            this.props.updateWorkOrderScope(scp)
          })
        })
      } else {
        if (WorkOrder.Trip === undefined) {
          if (this.props.isCmdCtrlPressed) {
            if (WorkOrder.Data) {
              const { Scopes } = WorkOrder.Data
              const scopes = _.filter(
                Scopes,
                s => !s.Data.Assignments || s.Data.Assignments.length < 1,
              )
              scopes.map(scope => {
                scope.ExactTime = moment(ScheduledTime).format('HH:mm:ss')
                scope.LeadTechnician = technician.Technician
                let Duration = 1.0
                if (scope.EstDuration && scope.EstDuration > 0) {
                  Duration = scope.EstDuration
                } else {
                  const srv = _.find(services, { Service: scope.Service })
                  if (srv) {
                    Duration = srv.DefaultDuration || 1.0
                  }
                }
                // scope.EstDuration = Duration;
                trips.push({
                  ...WorkOrder,
                  Trip: 0,
                  scopeNum: scope.Scope,
                  Duration,
                })
                this.setState(
                  {
                    dragover: false,
                    trips,
                    time: moment(ScheduledTime).format('HH:mm:ss'),
                    exactTime: moment(ScheduledTime).format('HH:mm:ss'),
                  },
                  () => this.props.updateWorkOrderScope(scope),
                )
              })
            }
          } else {
            let Duration = 1.0
            const scope = WorkOrder.Data.Scopes[0]
            // console.log("scope", WorkOrder);
            if (scope.EstDuration && scope.EstDuration > 0) {
              Duration = scope.EstDuration
            } else {
              const srv = _.find(services, { Service: scope.Service })
              if (srv) {
                Duration = srv.DefaultDuration || 1.0
              }
            }
            trips.push({
              ID: WorkOrder.ID,
              Co,
              WorkOrder: WorkOrder.WorkOrder,
              Technician: technician.Technician,
              Status: 0,
              ScheduledDate,
              ScheduledTime,
              Duration,
              CreatedBy: user.username,
              CreatedDate: new Date(),
              Data: {
                WorkOrder,
                Assignments: [
                  {
                    Co: WorkOrder.Co,
                    WorkOrder: WorkOrder.WorkOrder,
                    Scope: WorkOrder.Data.Scopes[0].Scope,
                  },
                ],
                Tags: [],
              },
              row,
            })
            this.setState({ dragover: false, trips }, () => {
              this.props.createTrip({
                Co,
                WorkOrder: WorkOrder.WorkOrder,
                Technician: technician.Technician,
                Status: 0,
                ScheduledDate,
                ScheduledTime,
                Duration,
                CreatedBy: user.username,
                CreatedDate: new Date(),
                Data: {
                  Assignments: [
                    {
                      Co: WorkOrder.Co,
                      WorkOrder: WorkOrder.WorkOrder,
                      Scope: WorkOrder.Data.Scopes[0].Scope,
                    },
                  ],
                  Scopes: WorkOrder.Data.Scopes,
                  Tags: [],
                },
              })
            })
          }
        } else {
          if (WorkOrder.Trip < 1) {
            if (WorkOrder.Data) {
              const { Assignments } = WorkOrder.Data
              if (
                Assignments &&
                Assignments.length > 0 &&
                WorkOrder.Data.WorkOrder &&
                WorkOrder.Data.WorkOrder.Data &&
                WorkOrder.Data.WorkOrder.Data.Scopes
              ) {
                const scope = _.find(WorkOrder.Data.WorkOrder.Data.Scopes, {
                  Scope: Assignments[0].Scope,
                })
                if (scope) {
                  scope.ExactTime = moment(ScheduledTime).format('HH:mm:ss')
                  scope.LeadTechnician = technician.Technician
                  trips.push(WorkOrder)
                  this.setState(
                    {
                      dragover: false,
                      trips,
                      time: moment(ScheduledTime).format('HH:mm:ss'),
                      exactTime: moment(ScheduledTime).format('HH:mm:ss'),
                    },
                    () => this.props.updateWorkOrderScope(scope),
                  )
                }
              }
            }
          } else {
            if (this.props.isCmdCtrlPressed) {
              if (
                !_.find(trips, { WorkOrder: WorkOrder.WorkOrder }) &&
                WorkOrder.Status < 4
              ) {
                // trips.push({ ...WorkOrder, ScheduledTime, row });
                this.setState({ dragover: false, trips }, () => {
                  this.props.updateTechnicianTrip(
                    {
                      ...WorkOrder,
                      Technician: technician.Technician,
                      BoardTime: ScheduledTime,
                      ScheduledTime,
                      Status: 0,
                    },
                    'UPDATE',
                  )
                  this.props.updateTrip({
                    ...WorkOrder,
                    Technician: technician.Technician,
                    ScheduledTime,
                    Status: 0,
                  })
                })
              }
            } else {
              if (
                WorkOrder.Technician &&
                WorkOrder.Technician !== technician.Technician
              ) {
                trips.push({
                  ID: WorkOrder.Data ? WorkOrder.Data.WorkOrder.ID : null,
                  Co,
                  WorkOrder: WorkOrder.WorkOrder,
                  Technician: technician.Technician,
                  Status: 0,
                  ScheduledDate,
                  ScheduledTime,
                  Duration: WorkOrder.Duration,
                  CreatedBy: user.username,
                  CreatedDate: new Date(),
                  Data: {
                    WorkOrder: WorkOrder.Data.WorkOrder,
                    Assignments: [
                      {
                        Co: WorkOrder.Co,
                        WorkOrder: WorkOrder.WorkOrder,
                        Scope: WorkOrder.Data.Assignments[0].Scope,
                      },
                    ],
                    Tags: [],
                  },
                  row,
                })
                this.setState({ dragover: false, trips }, () => {
                  this.props.createTrip({
                    Co,
                    WorkOrder: WorkOrder.WorkOrder,
                    Technician: technician.Technician,
                    Status: 0,
                    ScheduledDate,
                    ScheduledTime,
                    Duration: WorkOrder.Duration,
                    CreatedBy: user.username,
                    CreatedDate: new Date(),
                    Data: {
                      Assignments: [
                        {
                          Co: WorkOrder.Co,
                          WorkOrder: WorkOrder.WorkOrder,
                          Scope: WorkOrder.Data.Assignments[0].Scope,
                        },
                      ],
                      Scopes: WorkOrder.Data.Assignments,
                      Tags: [],
                    },
                  })
                })
              }
            }
            if (
              WorkOrder.Technician &&
              WorkOrder.Technician === technician.Technician
            ) {
              if (
                !_.find(trips, { WorkOrder: WorkOrder.WorkOrder }) &&
                WorkOrder.Status < 4
              ) {
                // trips.push({ ...WorkOrder, ScheduledTime, row });
                this.setState({ dragover: false, trips }, () => {
                  this.props.updateTechnicianTrip(
                    { ...WorkOrder, BoardTime: ScheduledTime, ScheduledTime },
                    'UPDATE',
                  )
                  this.props.updateTrip({ ...WorkOrder, ScheduledTime })
                })
              }
            }
          }
        }
      }
    }
  }

  formatDateInput(dt) {
    return dt.toLocaleDateString('en-US').replace(/\//g, '-')
  }

  deleteTrip = trip => {
    const { trips } = this.state
    for (var i = 0; i < trips.length; i++) {
      const trp = trips[i]
      let { Co, WorkOrder, Trip } = trp
      if (Co == trip.Co && WorkOrder == trip.WorkOrder && Trip == trip.Trip) {
        trips.splice(i, 1)
        i -= 1
      }
    }
    this.setState({ dragover: false, trips }, () => {
      this.props.deleteTrip(trip)
    })
  }

  getRow = row => {
    let { occupied } = this.state
    while (occupied.indexOf(row) > -1) {
      row += 1
    }
    occupied.push(row)
    return row
  }

  getHeight = () => {
    const { technician, trips, occupied, time } = this.state
    let rows = 0
    if (occupied) {
      for (var i = 0; i < occupied.length; i++) {
        if (rows < occupied[i]) {
          rows = occupied[i]
        }
      }
    }
    return rows + 1
  }

  hover = hover => {
    this.setState({ ...this.state, hover })
  }
  render() {
    const { classes, Co, techUA, date, occupied, border, dragging, now } =
      this.props
    const {
      trips,
      technician,
      time,
      row,
      rows,
      dragover,
      hover,
      unavailable,
      dt,
    } = this.state
    if (!dt) {
      return ''
    }
    const uaObj = {
      Co,
      Technician: technician.Technician,
      StartDate: dt.toLocaleString('en-US'),
      StartTime: !time ? null : dt.toLocaleString('en-US'),
      Duration: !time ? 24 : 1,
      AllDayYN: !time ? 'Y' : 'N',
    }

    let height = rows * 46 + 6
    if (height < 96) {
      height = 96
    }

    return (
      <div
        onMouseEnter={() => this.hover(true)}
        onMouseLeave={() => this.hover(false)}
        className={
          'dispatch-dropcell ' +
          classNames(
            classes.dropCell,
            unavailable
              ? classes.unavailable
              : dragover
                ? classes.droppable
                : '',
          )
        }
        onDragEnter={!unavailable ? this.dragEnter : undefined}
        onDragOver={!unavailable ? this.dragOver : undefined}
        onDrop={!unavailable ? this.drop : undefined}
        onDragLeave={!unavailable ? this.dragLeave : undefined}
        style={{
          position: 'relative',
          minHeight: height > 96 ? height : 96,
          width: !time ? '100%' : 96,
          minWidth: !time ? '100%' : 96,
          maxWidth: !time ? '100%' : 96,
          borderRight: border
            ? unavailable
              ? '1px solid #c9c9c9'
              : '1px solid #e9e9e9'
            : undefined,
        }}
      >
        {!dragging && occupied.length < 1 && hover && (
          <FuseAnimate animation='transition.expandIn' delay={300}>
            <Tooltip
              enterDelay={500}
              title={
                unavailable
                  ? unavailable.ID
                    ? unavailable.Description
                    : 'Unscheduled'
                  : 'Block Schedule'
              }
              placement='top'
            >
              <IconButton
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                className='pin-l pin-r pin-t pin-b absolute m-auto action-item'
                onClick={
                  !unavailable
                    ? e => {
                        this.props.openNewTechUADialog({ ...uaObj })
                        e.stopPropagation()
                      }
                    : unavailable.Technician
                      ? e => {
                          this.props.openEditTechUADialog(unavailable)
                          e.stopPropagation()
                        }
                      : undefined
                }
                aria-owns={undefined}
              >
                <Icon className='text-24'>
                  {unavailable && unavailable.ID ? 'edit' : 'event_busy'}
                </Icon>
              </IconButton>
            </Tooltip>
          </FuseAnimate>
        )}
        {trips.map((value, index) => {
          return (
            <DispatchBoardTrip
              onDelete={trip => {
                this.deleteTrip(trip)
              }}
              isCmdCtrlPressed={this.props.isCmdCtrlPressed}
              row={row}
              key={value.ID}
              data={value}
              index={value.row || index}
              time={time}
              onDrag={() => {
                this.hover(false)
                this.setState({ selectedTripID: value.ID }, () => {
                  window['warn'](this.state.selectedTripID)
                })
              }}
              dragFinish={() => {
                this.setState({ selectedTripID: null }, () => {
                  window['warn'](this.state.selectedTripID)
                })
              }}
              currentTripID={value.ID}
              selectedTripID={this.state.selectedTripID}
              now={now}
              exactTime={this.state.exactTime}
              scopeNum={this.state.scopeNum}
            />
          )
        })}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTrips: Actions.setTrips,
      createTrip: Actions.createTrip,
      updateTrip: Actions.updateTrip,
      deleteTrip: Actions.deleteTrip,
      openInfoWindow: Actions.openInfoWindow,
      openNewTechUADialog: openNewTechUADialog,
      openEditTechUADialog: openEditTechUADialog,
      updateTechnicianTrip: Actions.updateTechnicianTrip,
      updateWorkOrderScope,
      setWorkOrderData,
      setBoardTrips: Actions.setBoardTrips,
      setDragging: Actions.setDragging,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, dispatchBoardApp }) {
  return {
    Co: spReducers.companies.Co,
    user: dispatchBoardApp.user,
    date: dispatchBoardApp.dispatchBoard.date,
    workOrders: spReducers.workOrders,
    allTrips: dispatchBoardApp.dispatchBoard.trips,
    services: spReducers.services,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchDropCell)),
)
