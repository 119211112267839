import { FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  Badge,
  Button,
  Dialog,
  Icon,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TimePicker } from '@material-ui/pickers'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { bindActionCreators } from 'redux'
import * as DispatchActions from '../dispatch-board/store/actions'
import * as WorkOrderActions from '../work-orders/store/actions'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import * as Actions from './store/actions'
import TimecardBreak from './TimecardBreak'
import TimecardLabor from './TimecardLabor'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'

const styles = theme => ({
  root: {
    // maxHeight: '70vh',
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  calendar: {
    width: '100%',
    background: theme.palette.primary.main,
    borderRadius: 5,
  },
  calendarHeader: {
    width: '100%',
  },
  calendarPicker: {
    height: 72,
    width: '100%',
    background: '#3f3f3f',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  calendarPickerDates: {
    height: 72,
    width: '100%',
  },
  mailItem: {},
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 30,
    height: 30,
    marginTop: -6,
    marginBottom: -6,
  },
  labels: {},
  badge: {
    top: 5,
    right: 15,
  },
  liRoot: {
    padding: 0,
  },
  clockInBtn: {
    background: '#333',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
  },
  clockOutBtn: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:active': {
      background: '#5f5f5f',
    },
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  withdraw: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  inlineBadge: {
    top: '25%',
    left: '110%',
    right: 'unset',
    fontSize: 11,
    padding: 4,
    height: 16,
    transform: 'none',
  },
  invHeader: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
  },
  invSubHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 4,
    color: '#333',
  },
  dividerPlain: {
    color: '#fff',
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
})

const initialState = {
  ID: null,
  Co: null,
  Employee: null,
  Date: null,
  Data: {
    ErrMsg: null,
    Punch: [],
    Labor: [],
  },
  ApprovedYN: null,
  ApprovedBy: null,
  ApprovedDate: null,
  SubmittedYN: null,
  SubmittedDate: null,
  Notes: null,
  sigImg: null,
  isStartpickerOpen: false,
  isEndpickerOpen: false,
  hasOpenSuggestion: false,
  suggestedLabor: [],
}

class Timecard extends Component {
  state = {
    ..._.cloneDeepWith(initialState),
  }

  componentDidMount() {
    let { timecard } = this.props
    if (timecard) {
      this.setState({ ..._.cloneDeepWith(initialState), ...timecard }, () =>
        window['warn'](this.state, timecard),
      )
    }

    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    let { loading, trips, timecard } = this.props
    if (!_.isEqual(timecard, prevProps.timecard)) {
      window['warn']('Updating Timecard state')
      this.setState({ ..._.cloneDeepWith(initialState), ...timecard }, () =>
        window['warn'](this.state, timecard),
      )
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  formatDateInput(dt) {
    return dt.toLocaleDateString('en-US').replace(/\//g, '-')
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  handleRefresh = () => {
    let { date } = this.state
    this.setState(
      { ...this.state, date, sigImg: null, loading: true, trips: [] },
      () => {
        this.updateDate(date)
      },
    )
  }

  openMenu = e => {
    this.setState({ ...this.state, anchorEl: e.currentTarget })
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({
      ...this.state,
      sigImg: this.sigPad.getCanvas().toDataURL('image/png'),
      isSigning: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
    }
  }

  renderStartLabel = () => {
    const timecard = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    return (
      <span className='text-12'>
        {timeIn &&
          moment(moment(timeIn)).local().toDate().toLocaleTimeString('en-US')}
      </span>
    )
  }

  renderEndLabel = () => {
    const timecard = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    return (
      <span className='text-12'>
        {timeOut &&
          moment(moment(timeOut)).local().toDate().toLocaleTimeString('en-US')}
      </span>
    )
  }

  handleTimeOutChange = event => {
    const timecard = this.state
    const date = timecard.Date
    const tcData = timecard ? timecard.Data : null
    if (event) {
      const time = event._d
      const dt = new Date(date)
      dt.setTime(time.getTime())
      if (tcData) {
        const punch = tcData.Punch[tcData.Punch.length - 1]
        punch.TimeOut = dt.toLocaleString('en-US')
        this.props.editPunch(punch, this.props.source)
      }
    } else {
      if (tcData) {
        const punch = tcData.Punch[tcData.Punch.length - 1]
        punch.TimeOut = null
        this.props.editPunch(punch, this.props.source)
      }
    }
  }

  handleTimeInChange = event => {
    const timecard = this.state
    const date = timecard.Date
    const { Co, Employee } = timecard
    const tcData = timecard ? timecard.Data : null
    if (event) {
      const time = event._d
      const dt = new Date(date)
      dt.setTime(time.getTime())
      const punch =
        tcData && tcData.Punch && tcData.Punch.length > 0
          ? tcData.Punch[0]
          : {
              Co,
              Employee,
              Date: dt.toLocaleDateString('en-US'),
              TimeIn: dt.toLocaleString('en-US'),
            }
      punch.TimeIn = dt.toLocaleString('en-US')
      if (tcData && tcData.Punch && tcData.Punch.length > 0) {
        this.props.editPunch(punch, this.props.source)
      } else {
        this.props.clockIn(punch, this.props.source)
      }
    } else {
      const punch =
        tcData && tcData.Punch && tcData.Punch.length === 1
          ? tcData.Punch[0]
          : null
      if (punch) {
        this.props.removePunch(punch, this.props.source)
      }
    }
  }

  openStartPicker = e => {
    const { submitted } = this.props
    if (submitted !== 'Y') {
      this.setStartpickerOpen(true)
    }
  }

  openEndPicker = e => {
    const { submitted } = this.props
    if (submitted !== 'Y') {
      this.setEndpickerOpen(true)
    }
  }

  setStartpickerOpen = bool => {
    this.setState({ isStartpickerOpen: bool })
  }

  setEndpickerOpen = bool => {
    this.setState({ isEndpickerOpen: bool })
  }

  render() {
    const {
      classes,
      employees,
      approve,
      source,
      timecard,
      laborTypes,
      supervisor,
      divisions,
      securables,
    } = this.props
    // const timecard = { ...this.state };
    const date = moment(moment(timecard.Date)).local().toDate()
    const { Co, Employee } = timecard
    const trips = this.props.trips || []
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    const employee = _.find(employees, { Co, Employee })
    const avatar = 'assets/images/avatars/profile.jpg'
    const suggestedTrips =
      tcData && tcData.Labor
        ? _.filter(
            trips,
            o =>
              (tcData && tcData.Labor
                ? _.findIndex(tcData.Labor, { WorkOrder: o.WorkOrder })
                : -1) < 0 &&
              o.CompletedTime &&
              (o.InProgressTime || o.ArrivedTime || o.EnRouteTime),
          )
        : []
    const suggested =
      tcData && tcData.Labor
        ? _.sumBy(
            suggestedTrips,
            trip =>
              (new Date(trip.CompletedTime) -
                new Date(
                  trip.InProgressTime || trip.ArrivedTime || trip.EnRouteTime,
                ).getTime()) /
              36e5,
          ).toFixed(2)
        : null
    const { DefaultBusinessUnit, DefaultDivision } = employee || {}
    const division =
      DefaultBusinessUnit && DefaultDivision
        ? _.find(divisions, {
            Co,
            BusinessUnit: DefaultBusinessUnit,
            Division: DefaultDivision,
          })
        : null
    const accessLevel = _.find(securables, { Securable: 'timecards' })
    const fullAccess = accessLevel && accessLevel.AccessLevel === 'F'
    if (!employee) {
      return <div></div>
    }

    return (
      <div className='w-full relative'>
        <div className='inv-print sm:mx-8'>
          <div
            className='w-full flex justify-between mb-24'
            ref={this.props.refProp}
          >
            {division && division.InvoiceHeaderImg && (
              <img
                className='h-36 sm:h-64 md:h-80'
                src={division.InvoiceHeaderImg}
              />
            )}
            <span className='md:mt-12'>
              <label
                className={classNames(classes.invHeader, 'text-20 sm:text-36')}
              >
                TIMECARD
              </label>
              <Typography
                variant='caption'
                className={classNames(
                  classes.invSubHeader,
                  'text-10 sm:text-12 text-right',
                )}
              >
                <Icon
                  color={
                    !this.state.ID
                      ? 'action'
                      : this.state.ApprovedYN === 'Y'
                        ? 'secondary'
                        : this.state.ApprovedYN === 'N'
                          ? 'error'
                          : 'primary'
                  }
                  className='text-14 mr-4'
                  style={{ verticalAlign: 'middle' }}
                >
                  {!this.state.ID
                    ? 'add'
                    : this.state.ApprovedYN === 'Y'
                      ? 'cloud_done'
                      : this.state.ApprovedYN === 'N'
                        ? 'cloud_offline'
                        : this.state.SubmittedYN === 'Y'
                          ? 'cloud_upload'
                          : 'edit'}
                </Icon>
                {!this.state.ID
                  ? 'New'
                  : this.state.ApprovedYN === 'Y'
                    ? 'Approved'
                    : this.state.ApprovedYN === 'N'
                      ? 'Rejected'
                      : this.state.SubmittedYN === 'Y'
                        ? 'Submitted'
                        : 'Open'}
              </Typography>
              {/* <div className={classNames(classes.invSubHeader, "text-10 sm:text-16")}>#{this.state.InvoiceNumber}</div> */}
            </span>
          </div>

          <div className='flex mb-24 justify-between px-16'>
            <div className='cursor-pointer'>
              <div className='flex cursor-pointer'>
                <img
                  src={
                    employee.Data && employee.Data.Avatar
                      ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${Co}&ID=${employee.Data.Avatar}&Thumb=true`
                      : avatar
                  }
                  className='w-32 h-32 mr-8 rounded-full'
                />
                <div className='w-full'>
                  <div className='text-14 font-bold'>
                    {`${employee.FirstName} ${employee.LastName}`}
                  </div>
                  <div className='text-10'>{`Emp #${employee.Employee}`}</div>
                </div>
              </div>
            </div>

            <div>
              <div className='flex cursor-pointer'>
                <img
                  src='assets/icons/calendar_icon.png'
                  className='w-32 h-32 mr-8'
                />
                <div className='w-full'>
                  <div className='text-14 font-bold'>
                    {moment(this.state.Date).format('dddd')}
                  </div>
                  <div className='text-10'>
                    {moment(this.state.Date).format('MMM DD, YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.ApprovedYN === 'N' && (
            <div
              className='p-8 rounded m-24 py-24 w-full cursor-pointer'
              style={{ backgroundColor: '#f9f9f9', border: '1px dashed red' }}
            >
              <Typography className='text-16 font-bold text-red w-full text-center'>
                <Icon color='error' className='mr-6 align-middle mb-2'>
                  cloud_off
                </Icon>
                TIMECARD REJECTED
              </Typography>
              <div className='w-full'>
                <div className='italic mt-4 text-center'>
                  {this.state.Notes
                    ? `"${this.state.Notes}"`
                    : 'No Reason Given'}
                </div>
              </div>
            </div>
          )}

          <TimePicker
            clearable
            inputVariant='outlined'
            views={['hours', 'minutes']}
            onOpen={() => this.setStartpickerOpen(true)}
            onClose={() => this.setStartpickerOpen(false)}
            open={this.state.isStartpickerOpen}
            className={classes.formControl}
            label='Time In'
            id='timeIn'
            format='h:mm A'
            openTo='hours'
            value={timeIn ? moment(timeIn).local().toDate() : date}
            onChange={this.handleTimeInChange}
            TextFieldComponent={() => ''}
          />

          <div
            className='flex'
            onClick={() => {
              if (
                date.toLocaleDateString('en-US') ===
                  new Date().toLocaleDateString('en-US') &&
                !timeIn
              ) {
                const { Co, Employee } = timecard

                const punch = {
                  Co,
                  Employee,
                  Date: date.toLocaleDateString('en-US'),
                  TimeIn: new Date().toLocaleString('en-US'),
                }
                this.props.clockIn(punch, source)
              } else {
                this.openStartPicker()
              }
            }}
          >
            <div className='w-full'>
              <div
                className='w-full flex justify-between rounded-full cursor-pointer h-32'
                style={{ backgroundColor: '#3f3f3f' }}
              >
                <div
                  className={classNames(
                    'w-auto rounded-full py-4 px-24 text-14 font-bold truncate cursor-pointer',
                    classes.dividerHighlight,
                  )}
                >
                  {timeIn ? (
                    <React.Fragment>
                      <Icon className='mr-4 text-20 text-white align-middle mb-4'>
                        access_time
                      </Icon>
                      Time In
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Icon className='mr-4 text-20 text-white align-middle mb-2'>
                        timer
                      </Icon>
                      Clock In
                    </React.Fragment>
                  )}
                </div>
                <div
                  className={classNames(
                    'w-1/2 pt-6 px-24 text-12 font-bold text-right truncate cursor-pointer',
                    classes.dividerPlain,
                  )}
                >
                  {timeIn ? (
                    <React.Fragment>
                      <Icon className='mr-4 text-20 text-white align-middle mb-2'>
                        timer
                      </Icon>
                      {moment(timeIn).local().format('h:mm A')}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='w-full px-12 clearfix'>
            {tcData && timeIn && suggested && suggested > 0 && (
              <div
                className='bg-grey-lighter p-12 py-4 rounded-lg mt-12'
                style={{ border: '1px dotted lightgrey' }}
              >
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center', clear: 'both' }}
                  className='flex mb-8'
                >
                  <Badge
                    badgeContent={suggested}
                    color='primary'
                    classes={{ badge: classes.inlineBadge }}
                  >
                    <span className='text-16'>Suggested</span>
                  </Badge>
                </Typography>
                {suggestedTrips.map(trip => {
                  const { WorkOrder } = trip
                  const assignment =
                    trip.Data.Assignments && trip.Data.Assignments.length > 0
                      ? _.orderBy(trip.Data.Assignments, ['Scope'], ['asc'])[0]
                      : {}
                  const { Scope } = assignment
                  const scope = Scope
                    ? _.find(trip.Data.WorkOrder.Data.Scopes, { Scope })
                    : {}
                  const { BusinessUnit, Division } = scope
                  const exists =
                    (tcData && tcData.Labor
                      ? _.findIndex(tcData.Labor, { WorkOrder })
                      : -1) > -1
                  if (Scope && !exists) {
                    const laborType =
                      laborTypes &&
                      _.filter(laborTypes, {
                        Type: 'W',
                        BusinessUnit,
                        Division,
                      }).length > 0
                        ? _.filter(laborTypes, {
                            Type: 'W',
                            BusinessUnit,
                            Division,
                          })[0]
                        : null
                    const Qty = (
                      (new Date(trip.CompletedTime) -
                        new Date(
                          trip.InProgressTime ||
                            trip.ArrivedTime ||
                            trip.EnRouteTime,
                        ).getTime()) /
                      36e5
                    ).toFixed(2)
                    const suggested = {
                      Qty,
                      WorkOrder,
                      Scope,
                      Type: 'W',
                      Description: `Direct Labor - WO #${WorkOrder}`,
                      LaborType: laborType ? laborType.LaborType : null,
                      HourlyRate: laborType ? laborType.StandardUnitCost : null,
                      Total: Qty * (laborType ? laborType.StandardUnitCost : 0),
                    }
                    return (
                      <TimecardLabor
                        key={`${WorkOrder}-${Scope}`}
                        /*onOpen={(hasOpenSuggestion) => this.setState({ hasOpenSuggestion })}*/ source={
                          source
                        }
                        date={this.state.Date}
                        employee={employee}
                        trips={[trip]}
                        data={suggested}
                        suggested={true}
                      />
                    )
                  }
                })}
                {/* {!this.state.hasOpenSuggestion &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.props.addTripLabor()
                                        }}
                                        className="my-8 w-full"
                                        disabled={!suggested || suggested <= 0}
                                    >
                                        Add{suggestedTrips.length > 1 ? " All" : ""}
                                    </Button>
                                } */}
              </div>
            )}
            {tcData && timeIn && (
              <div className='w-full mb-12'>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center', marginTop: 4, clear: 'both' }}
                  className='flex'
                >
                  <Badge
                    badgeContent={
                      tcData.Labor && _.sumBy(tcData.Labor, 'Qty').toFixed(2)
                    }
                    color='primary'
                    classes={{ badge: classes.inlineBadge }}
                  >
                    <span className='text-16'>Hours</span>
                  </Badge>
                </Typography>
                {tcData &&
                  tcData.Labor &&
                  tcData.Labor.map((value, index) => (
                    <TimecardLabor
                      source={source}
                      approve={Boolean(approve)}
                      date={this.state.Date}
                      employee={employee}
                      submitted={timecard.SubmittedYN}
                      approved={timecard.ApprovedYN}
                      key={value.ID}
                      trips={[...trips]}
                      data={{ ...value }}
                    />
                  ))}
                {(fullAccess || timecard.SubmittedYN !== 'Y') && (
                  <TimecardLabor
                    source={source}
                    date={this.state.Date}
                    employee={employee}
                    trips={[...trips]}
                    data={null}
                  />
                )}

                {tcData && tcData.Punch && tcData.Punch.length > 1 && (
                  <Typography
                    variant='h6'
                    style={{
                      alignItems: 'center',
                      marginTop: -8,
                      clear: 'both',
                    }}
                    className='flex'
                  >
                    <span className='text-16'>Breaks</span>
                  </Typography>
                )}

                {tcData &&
                  tcData.Punch &&
                  tcData.Punch.map((value, index) => {
                    if (index > 0) {
                      return (
                        <TimecardBreak
                          source={source}
                          key={value.ID}
                          submitted={timecard.SubmittedYN}
                          data={{
                            StartRec: tcData.Punch[index - 1],
                            EndRec: value,
                          }}
                        />
                      )
                    }
                  })}
              </div>
            )}
          </div>
          <TimePicker
            clearable
            inputVariant='outlined'
            views={['hours', 'minutes']}
            onOpen={() => this.setEndpickerOpen(true)}
            onClose={() => this.setEndpickerOpen(false)}
            open={this.state.isEndpickerOpen}
            //className="no-print"
            format='h:mm A'
            openTo='hours'
            value={timeOut ? moment(timeOut).local().toDate() : date}
            onChange={this.handleTimeOutChange}
            TextFieldComponent={() => ''}
          />
          {timeIn && (
            <div
              className='flex mb-24'
              onClick={() => {
                if (
                  date.toLocaleDateString('en-US') ===
                    new Date().toLocaleDateString('en-US') &&
                  timeIn &&
                  !timeOut
                ) {
                  var rec = this.state
                  window['log'](rec)
                  const punch = tcData.Punch[tcData.Punch.length - 1]
                  punch.TimeOut = new Date().toLocaleString('en-US')
                  this.props.editPunch(punch, source)
                } else {
                  this.openEndPicker()
                }
              }}
            >
              <div className='w-full'>
                <div
                  className='w-full flex justify-between rounded-full cursor-pointer h-32'
                  style={{ backgroundColor: '#3f3f3f' }}
                >
                  <div
                    className={classNames(
                      'w-2/3 py-4 pt-6 pl-24 text-12 font-bold cursor-pointer',
                      classes.dividerPlain,
                    )}
                  >
                    {Boolean(timeOut) && (
                      <React.Fragment>
                        <Icon className='mr-4 text-20 text-white align-middle mb-2'>
                          access_time
                        </Icon>
                        Time Out
                      </React.Fragment>
                    )}
                  </div>
                  <div
                    className={classNames(
                      'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between cursor-pointer',
                      classes.dividerHighlight,
                    )}
                  >
                    <div className='text-12 pt-4 font-bold mt-2 truncate'>
                      <Icon className='mr-4 text-20 text-white align-middle mb-2'>
                        timer
                      </Icon>
                      {timeOut
                        ? moment(timeOut).local().format('h:mm A')
                        : 'Clock Out'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {timeIn && timeOut && (
            <div className='w-full flex justify-end items-center font-bold mb-24'>
              <div className='text-14 px-8' style={{ color: '#3f3f3f' }}>
                Duration:{' '}
              </div>
              <div
                className='rounded-full text-12 px-16 py-6'
                style={{ backgroundColor: '#3f3f3f', color: '#ffffff' }}
              >
                {moment
                  .duration(moment(timeOut).diff(moment(timeIn)))
                  .asHours()
                  .toFixed(2)}{' '}
                Hours
              </div>
            </div>
          )}
          {date.toLocaleDateString('en-US') ===
            new Date().toLocaleDateString('en-US') &&
            timeIn &&
            timeOut &&
            timecard.SubmittedYN !== 'Y' && (
              <React.Fragment>
                {!this.state.sigImg && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      const { Co, Employee } = timecard

                      const punch = {
                        Co,
                        Employee,
                        Date: date.toLocaleDateString('en-US'),
                        TimeIn: new Date().toLocaleString('en-US'),
                      }
                      this.props.clockIn(punch, source)
                    }}
                    className={classNames('w-full mb-24 rounded-full')}
                  >
                    <Icon className='mr-8'>timer</Icon>
                    Clock In
                  </Button>
                )}
              </React.Fragment>
            )}
          {/* {date.toLocaleDateString('en-US') === new Date().toLocaleDateString('en-US') && (timeIn && !timeOut) &&
                        <React.Fragment>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    var rec = this.state;
                                    window["log"](rec);
                                    const punch = tcData.Punch[tcData.Punch.length - 1];
                                    punch.TimeOut = new Date().toLocaleString('en-US');
                                    this.props.editPunch(punch, source);
                                }}
                                className={classNames(classes.clockOutBtn, "w-full mb-24 rounded-full")}
                            >
                                <Icon className="mr-8">timer</Icon>
                                Clock Out
                            </Button>
                        </React.Fragment>
                    } */}
        </div>
        <div className={classNames(classes.root, 'border-0')}>
          {/* <div className="w-full mb-12">
                        <div className="float-left text-left">
                            <div>
                                <span className="font-bold mr-6">Time In:</span>
                                <TimePicker
                                    clearable
                                    inputVariant="outlined"
                                    onOpen={() => this.setStartpickerOpen(true)}
                                    onClose={() => this.setStartpickerOpen(false)}
                                    open={this.state.isStartpickerOpen}
                                    className={classes.formControl}
                                    label="Time In"
                                    id="timeIn"
                                    format="h:mm A"
                                    openTo="minutes"
                                    value={timeIn ? moment(timeIn).local().toDate() : date}
                                    onChange={this.handleTimeInChange}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel
                                    }}
                                    TextFieldComponent={this.renderStartLabel}
                                    auto
                                    fullWidth
                                />
                                {timecard.SubmittedYN !== 'Y' &&
                                    <Icon onClick={this.openStartPicker} className="text-16 ml-4 cursor-pointer" style={{ paddingTop: 2 }}>edit</Icon>
                                }
                            </div>
                            <div>
                                <span className="font-bold mr-6">Time Out:</span>
                                {(tcData && timeIn) &&
                                    <React.Fragment>
                                        <TimePicker
                                            clearable
                                            inputVariant="outlined"
                                            onOpen={() => this.setEndpickerOpen(true)}
                                            onClose={() => this.setEndpickerOpen(false)}
                                            open={this.state.isEndpickerOpen}
                                            className={classes.formControl}
                                            label="Time Out"
                                            id="timeOut"
                                            format="h:mm A"
                                            openTo="minutes"
                                            value={timeOut ? moment(timeOut).local().toDate() : date}
                                            onChange={this.handleTimeOutChange}
                                            InputProps={{
                                                className: classes.input,
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputLabel
                                            }}
                                            TextFieldComponent={this.renderEndLabel}
                                            auto
                                            fullWidth
                                        />
                                        {timecard.SubmittedYN !== 'Y' &&
                                            <Icon onClick={this.openEndPicker} className="text-16 ml-4 cursor-pointer" style={{ paddingTop: 2 }}>edit</Icon>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="float-right text-right">
                            <div>
                                <span className="font-bold mr-6">{`${employee.Employee} - ${employee.FirstName} ${employee.LastName}`}</span>
                            </div>
                            <div>
                                <span className="font-bold mr-6">{date.toLocaleDateString('en-US')}</span>
                            </div>
                        </div>
                    </div> */}
          {timeIn && timeOut && (
            <React.Fragment>
              {!supervisor && (
                <React.Fragment>
                  <Dialog
                    open={this.state.isSigning}
                    classes={{ paper: classes.signaturePaper }}
                    fullWidth
                    maxWidth='sm'
                    onClose={() => {
                      if (this.sigPad.isEmpty()) {
                        this.setState({ ...this.state, isSigning: false })
                      }
                    }}
                    onTouchMove={e => e.stopPropagation()}
                  >
                    <div className='w-full mb-12'>
                      <span>
                        <strong style={{ marginRight: 4 }}>Signature:</strong>
                      </span>
                      <br />
                    </div>
                    <div
                      className='w-full'
                      style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
                    >
                      <SignatureCanvas
                        ref={ref => {
                          this.sigPad = ref
                        }}
                        penColor='#66aef9'
                        canvasProps={{
                          className: 'sigCanvas',
                          style: { width: '100%', height: 150 },
                        }}
                      />
                    </div>
                    <div className='flex w-full mt-16'>
                      <Button
                        variant='contained'
                        className={classes.clearBtn}
                        fullWidth
                        onClick={this.clear}
                      >
                        Clear
                      </Button>
                      <div className='min-w-12'></div>
                      <Button
                        variant='contained'
                        className={classes.okBtn}
                        fullWidth
                        onClick={this.trim}
                      >
                        OK
                      </Button>
                    </div>
                  </Dialog>
                  <span>
                    <strong style={{ marginRight: 4 }}>Signature:</strong>
                  </span>
                  <br />
                  <div
                    className='w-full mb-12 rounded-lg'
                    onClick={() => {
                      if (timecard.SubmittedYN !== 'Y') {
                        this.setState({
                          ...this.state,
                          isSigning: true,
                          sigImg: null,
                        })
                      }
                    }}
                    style={{
                      border: '1px dotted #3f3f3f',
                      width: '100%',
                      height: 150,
                      borderRadius: 5,
                    }}
                  >
                    {(this.state.sigImg || timecard.Signature) && (
                      <img
                        style={{ width: '100%', height: 150 }}
                        src={this.state.sigImg || timecard.Signature}
                      />
                    )}
                  </div>
                </React.Fragment>
              )}
              <div className='flex'>
                {this.state.sigImg && timecard.SubmittedYN !== 'Y' && (
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      timecard.SubmittedYN = 'Y'
                      timecard.ApprovedYN = null
                      timecard.SubmittedDate = new Date().toLocaleString(
                        'en-US',
                      )
                      timecard.Signature = this.state.sigImg
                      this.props.editTimecard(timecard, source)
                    }}
                    className={classNames('w-full mb-24 rounded-full')}
                  >
                    <Icon className='mr-8'>cloud_upload</Icon>
                    Submit Timecard
                  </Button>
                )}
                {timecard.SubmittedYN === 'Y' &&
                  timecard.ApprovedYN !== 'Y' &&
                  !approve &&
                  !supervisor && (
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        timecard.SubmittedYN = null
                        timecard.Signature = null
                        timecard.ApprovedYN = null
                        timecard.ApprovedDate = null
                        this.props.editTimecard(timecard, source)
                      }}
                      className={classNames(
                        classes.withdraw,
                        'w-full mb-24 rounded-full',
                      )}
                    >
                      <Icon className='mr-8'>history</Icon>
                      Withdraw
                    </Button>
                  )}
              </div>
              {this.state.promptRejection && (
                <ConfirmationDialog
                  open={this.state.promptRejection}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4' color='error'>
                        cancel
                      </Icon>
                      Reject Timecard
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      Please enter a reason for rejecting this timecard:
                      <br />
                      <TextField
                        className={'mt-12'}
                        label='Reason for Rejection'
                        id='rejectionReason'
                        name='Notes'
                        value={this.state.Notes || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        multiline
                        rows={5}
                        fullWidth
                      />
                    </div>
                  }
                  disableConfirm={
                    !this.state.Notes || this.state.Notes.length < 6
                  }
                  confirmText={'Save'}
                  cancelText='Cancel'
                  onCancel={() =>
                    this.setState({ ...this.state, promptRejection: false })
                  }
                  onConfirm={() => {
                    timecard.SubmittedYN = 'N'
                    timecard.Signature = null
                    timecard.ApprovedYN = 'N'
                    timecard.ApprovedDate = null
                    timecard.Notes = this.state.Notes
                    this.props.editTimecard(timecard, source)
                    this.setState(
                      { ...this.state, promptRejection: false },
                      () => this.props.editTimecard(timecard, source),
                    )
                  }}
                />
              )}
              <div className='sm:px-6 flex w-full'>
                {timecard.ApprovedYN !== 'Y' && (supervisor || approve) && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      timecard.SubmittedYN = 'Y'
                      timecard.ApprovedYN = 'Y'
                      timecard.ApprovedDate = new Date().toLocaleString('en-US')
                      this.props.editTimecard(timecard, source)
                    }}
                    className={classNames(
                      'w-full mb-24 rounded-full h-32',
                      timecard.SubmittedYN === 'Y' && 'rounded-r-none',
                    )}
                  >
                    <Icon className='mr-8'>check_circle_outline</Icon>
                    Approve
                  </Button>
                )}
                {timecard.SubmittedYN === 'Y' &&
                  timecard.ApprovedYN !== 'Y' &&
                  (supervisor || approve) && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        this.setState({ promptRejection: true })
                      }}
                      className={classNames('w-full mb-24 rounded-r-full h-32')}
                      style={{ backgroundColor: '#3f3f3f', color: '#fff' }}
                    >
                      <Icon className='mr-8' color='error'>
                        cancel
                      </Icon>
                      Reject
                    </Button>
                  )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
      openEditTimecardDialog: Actions.openEditTimecardDialog,
      removeTimecard: Actions.removeTimecard,
      openNewTimecardDialog: Actions.openNewTimecardDialog,
      getWOData: DispatchActions.getWOData,
      getTripData: DispatchActions.getTripData,
      setTrips: DispatchActions.setTrips,
      setBoardDate: DispatchActions.setBoardDate,
      loadingTrips: DispatchActions.loadingTrips,
      updateTrips: DispatchActions.updateTrips,
      setSchedule: DispatchActions.setSchedule,
      openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
      updateTrip: DispatchActions.updateTrip,
      clockIn: Actions.clockIn,
      editPunch: Actions.editPunch,
      editTimecard: Actions.editTimecard,
      removePunch: Actions.removePunch,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, timecardsApp, dispatchBoardApp }) {
  window['log'](spReducers, timecardsApp)
  return {
    loading: timecardsApp.timecards.timecardDialog.loading,
    selectedTimecardIds: timecardsApp.timecards.selectedTimecardIds,
    searchText: timecardsApp.timecards.searchText,
    user: spReducers.userProfiles.User,
    workOrderData: spReducers.workOrders,
    date:
      timecardsApp.timecards.timecardDialog.date ||
      dispatchBoardApp.dispatchBoard.date,
    timecards: spReducers.timecards,
    schedule: dispatchBoardApp.dispatchBoard.schedule,
    Co: spReducers.companies.Co,
    employees: spReducers.employees,
    laborTypes: spReducers.laborTypes,
    securables: spReducers.userProfiles.User.Data.Securables,
    divisions: spReducers.divisions,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Timecard)),
)
