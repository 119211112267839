import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newArrivalWindowState = {
    Co: null,
    BusinessUnit: '',
    ArrivalWindow: '',
    Description: '',
    StartTime: new Date().toString(),
    EndTime: new Date().toString(),
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    }
};

class ArrivalWindowDialog extends Component {
    state = { ...newArrivalWindowState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newArrivalWindowState.Co = this.props.Co;
        if (!prevProps.arrivalWindowDialog.props.open && this.props.arrivalWindowDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.arrivalWindowDialog.type === 'edit' &&
                this.props.arrivalWindowDialog.data &&
                !_.isEqual(this.props.arrivalWindowDialog.data, prevState)) {
                this.setState({ ...this.props.arrivalWindowDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.arrivalWindowDialog.type === 'new' &&
                !_.isEqual(newArrivalWindowState, prevState)) {
                this.setState({ ...newArrivalWindowState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.arrivalWindowDialog.type === 'edit' ? this.props.closeEditArrivalWindowDialog() : this.props.closeNewArrivalWindowDialog();
    };

    canBeSubmitted() {
        const { ArrivalWindow, Description } = this.state;
        return (
            ArrivalWindow.length > 0 && Description.length > 0
        );
    }

    formatTime(time) {
        const dt = new Date(time);
        let HH = dt.getHours();
        let MM = dt.getMinutes();
        const AP = (HH < 12 ? 'AM' : 'PM');
        if (HH < 10) {
            HH = '0' + HH;
        }
        if (HH > 12) {
            HH -= 12;
        }
        if (MM < 10) {
            MM = '0' + MM;
        }
        const formatted = (HH + ':' + MM + ' ' + AP);
        window["log"](formatted);
        return formatted;
    }

    render() {
        const { classes, securables, arrivalWindowDialog, addArrivalWindow, updateArrivalWindow, removeArrivalWindow, businessUnits } = this.props;
        const accessLevel = _.find(securables, { Securable: "arrival-windows" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...arrivalWindowDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {arrivalWindowDialog.type === 'new' ? 'New Arrival Window' : 'Edit Arrival Window'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField

                                    className={classes.formControl}
                                    label="Business Unit"
                                    id="businessUnit"
                                    select
                                    name="BusinessUnit"
                                    value={this.state.BusinessUnit}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Select Business Unit"
                                    disabled={(arrivalWindowDialog.type === 'new' ? false : true)}
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value, index) => (
                                        <MenuItem key={index} value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Arrival Window"
                                    id="arrivalWindow"
                                    name="ArrivalWindow"
                                    value={this.state.ArrivalWindow}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(arrivalWindowDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Start Time"
                                    id="startTime"
                                    type="time"
                                    name="StartTime"
                                    value={this.state.StartTime}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{
                                        step: 1800, // 30 min
                                    }}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="End Time"
                                    id="endTime"
                                    type="time"
                                    name="EndTime"
                                    value={this.state.EndTime}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{
                                        step: 1800, // 30 min
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {arrivalWindowDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addArrivalWindow(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateArrivalWindow(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditArrivalWindowDialog: Actions.closeEditArrivalWindowDialog,
        closeNewArrivalWindowDialog: Actions.closeNewArrivalWindowDialog,
        addArrivalWindow: Actions.addArrivalWindow,
        updateArrivalWindow: Actions.updateArrivalWindow,
        removeArrivalWindow: Actions.removeArrivalWindow
    }, dispatch);
}

function mapStateToProps({ arrivalWindowsApp, spReducers }) {
    return {
        arrivalWindowDialog: arrivalWindowsApp.arrivalWindows.arrivalWindowDialog,
        businessUnits: spReducers.businessUnits,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ArrivalWindowDialog));
