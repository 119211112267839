import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class DialGroupsList extends Component {

    state = {
        selectedDialGroupsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedDialGroupMenu = (event) => {
        this.setState({ selectedDialGroupsMenu: event.currentTarget });
    };

    closeSelectedDialGroupsMenu = () => {
        this.setState({ selectedDialGroupsMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, dialGroups, user, searchText, selectedDialGroupIds, selectAllDialGroups, deSelectAllDialGroups, toggleInSelectedDialGroups, openEditDialGroupDialog, removeDialGroups, removeDialGroup, toggleStarredDialGroup, setDialGroupsUnstarred, setDialGroupsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedDialGroupsData ? (!this.props.dialGroupData ? [] : this.props.dialGroupData) : this.props.selectedDialGroupsData), searchText);
        const { selectedDialGroupsMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">dialpad</Icon>Dial Groups</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditDialGroupDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Hunt Group",
                                accessor: "GroupID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Dial Group",
                                accessor: "ID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Seq",
                                accessor: "Seq",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 256,
                            },
                            {
                                Header: "Members",
                                accessor: "Data",
                                className: "justify-center",
                                Cell: row => {
                                    let endpoints = [];
                                        const value = row.original;
                                        for (var m = 0; m < value.Data.Members.length; m++) {
                                            let member = value.Data.Members[m];
                                            if (endpoints.indexOf(member.PhoneNumber) < 0) {
                                                endpoints.push(member.PhoneNumber);
                                            }
                                        }
                                    window["warn"](endpoints);
                                    return endpoints.length;
                                },
                                width: 156,
                            },
                            {
                                Header: "Created By",
                                accessor: "AddedBy",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Created Date",
                                accessor: "AddedDate",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Dial Groups found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDialGroups: Actions.getDialGroups,
        getUserData: Actions.getUserData,
        toggleInSelectedDialGroups: Actions.toggleInSelectedDialGroups,
        selectAllDialGroups: Actions.selectAllDialGroups,
        deSelectAllDialGroups: Actions.deSelectAllDialGroups,
        openEditDialGroupDialog: Actions.openEditDialGroupDialog,
        removeDialGroups: Actions.removeDialGroups,
        removeDialGroup: Actions.removeDialGroup,
        toggleStarredDialGroup: Actions.toggleStarredDialGroup,
        toggleStarredDialGroups: Actions.toggleStarredDialGroups,
        setDialGroupsStarred: Actions.setDialGroupsStarred,
        setDialGroupsUnstarred: Actions.setDialGroupsUnstarred
    }, dispatch);
}

function mapStateToProps({ dialGroupsApp, spReducers }) {
    return {
        dialGroups: dialGroupsApp.dialGroups.entities,
        selectedDialGroupIds: dialGroupsApp.dialGroups.selectedDialGroupIds,
        searchText: dialGroupsApp.dialGroups.searchText,
        user: dialGroupsApp.user,
        dialGroupData: spReducers.dialGroups,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DialGroupsList)));
