import { FuseAnimate } from '@fuse'
import _ from '@lodash'
import { Icon, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import userProfiles from '../../../../store/reducers/spConnection/user-profile.reducer'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import * as Actions from './store/actions'

const styles = theme => ({
  clear: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[400],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
  },
})

const initialState = {
  filters: {
    Type: 'charge',
    StartDate: null,
    EndDate: null,
    Success: null,
    // TopLevel: '1',
    IsManual: null,
  },
}
class PaymentsSidebarContent extends Component {
  state = {
    ...initialState,
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  componentDidMount() {
    const { filters, loading } = this.props
    if (filters) {
      this.setState(
        filters
          ? {
              ...initialState,
              filters: {
                Type: filters.Type,
                StartDate: filters.StartDate,
                EndDate: filters.EndDate,
              },
              loading,
            }
          : { ...initialState, loading },
      )
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { filters, loading } = this.props
    console.warn(filters, prevProps.filters, this.state)
    if (
      !_.isEqual(filters, prevProps.filters) ||
      !_.isEqual(loading, prevProps.loading)
    ) {
      this.setState(
        filters
          ? {
              filters: {
                Type: filters.Type,
                StartDate: filters.StartDate,
                EndDate: filters.EndDate,
              },
              loading,
            }
          : { ...initialState, loading },
      )
    }
  }

  setFilters = () => {
    const filters = this.buildFilters(this.state.filters)
    this.props.setPaymentFilters(filters)
  }

  handleStartDateChange = date => {
    const { filters } = this.state
    const endDate = date
      ? moment(date).add(7, 'days').format('YYYY-MM-DD')
      : null

    this.setState(
      {
        filters: {
          ...filters,
          StartDate: date ? moment(date).format('YYYY-MM-DD') : null,
          EndDate: endDate,
        },
      },
      this.setFilters,
    )
  }

  handleEndDateChange = date => {
    const { filters } = this.state
    this.setState(
      {
        filters: {
          ...filters,
          EndDate: date ? moment(date).format('YYYY-MM-DD') : null,
        },
      },
      this.setFilters,
    )
  }
  buildFilters = data => {
    const { filters } = this.props
    const { SortBy, SortOrder } = filters
    const newFilters = { SortBy, SortOrder }
    Object.keys(data).map(key => {
      const value = data[key]
      if (value) {
        newFilters[key] = value
      }
    })
    return newFilters
  }

  clearFilters = () => {
    this.setState({ ...initialState }, () => this.setFilters())
  }

  render() {
    const { Co, classes, filters } = this.props
    const { loading } = this.state
    const { Type, StartDate, EndDate } = this.state.filters
    const depositsAccessLevel = _.find(this.props.securables, {
      Securable: 'deposits',
    })

    const maxEndDate = filters.StartDate
      ? moment(filters.StartDate).add(7, 'days').toDate()
      : null

    // Calculate min date based on selected End Date - 7 days
    const minStartDate = filters.EndDate
      ? moment(filters.EndDate).subtract(7, 'days').toDate()
      : null
    return (
      <div onClick={e => e.stopPropagation()} className='p-12 lg:pl-20'>
        <FuseAnimate animation='transition.slideLeftIn' delay={200}>
          <Paper elevation={1} className='rounded-8 p-12 relative'>
            <Typography
              variant='h6'
              style={{ alignItems: 'center', marginBottom: 8 }}
              className='flex'
            >
              <Icon color='primary' className='text-32 mr-12'>
                search
              </Icon>
              Search
            </Typography>
            {filters && (
              <Icon
                onClick={this.clearFilters}
                className='pin-t pin-r absolute mt-16 mr-12 cursor-pointer'
                color='action'
              >
                close
              </Icon>
            )}
            <TextField
              className='mb-12'
              label='Type'
              id='type'
              name='filters.Type'
              value={this.state.filters.Type || ''}
              onChange={e =>
                this.setState(
                  {
                    ...this.state,
                    filters: { ...this.state.filters, Type: e.target.value },
                  },
                  this.setFilters,
                )
              }
              variant='outlined'
              select
              fullWidth
            >
              <MenuItem value='charge'>Payments</MenuItem>
              <MenuItem value='refund'>Refunds</MenuItem>
              <MenuItem value='void'>Voids</MenuItem>
              {depositsAccessLevel && (
                <MenuItem value='deposit'>Deposits</MenuItem>
              )}
            </TextField>
            <DatePicker
              className='mb-12'
              variant='dialog'
              inputVariant='outlined'
              label='Start Date'
              id='start'
              format='MM/DD/YYYY'
              value={filters.StartDate || null}
              onChange={this.handleStartDateChange}
              fullWidth
              clearable={true}
              autoOk={true}
            />
            <DatePicker
              className='mb-12'
              variant='dialog'
              inputVariant='outlined'
              label='End Date'
              id='end'
              format='MM/DD/YYYY'
              value={filters.EndDate || null}
              onChange={this.handleEndDateChange}
              fullWidth
              clearable={true}
              autoOk={true}
              minDate={filters.StartDate}
              maxDate={
                filters.StartDate
                  ? moment(filters.StartDate).add(7, 'days').toDate()
                  : null
              }
            />
            <DebounceButton
              buttonText='Search'
              className='w-full'
              processingText='Searching'
              clickFxn={() => this.props.getPayments(Co)}
              color='primary'
              variant='contained'
              debounceInterval={1000}
              isDisabled={Type === 'deposit' && (!StartDate || !EndDate)}
            />

            {/* <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={() => this.props.getPayments(Co, { ...this.state })}
                            disabled={loading}
                        >
                            {this.state.loading &&
                                <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                            }
                            {this.state.loading ? 'Searching' : 'Search'}
                        </Button> */}
          </Paper>
        </FuseAnimate>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPaymentFilters: Actions.setPaymentFilters,
      getPayments: Actions.getPayments,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, paymentsApp }) {
  return {
    Co: spReducers.companies.Co,
    filters: paymentsApp.payments.filters,
    loading: paymentsApp.payments.loading,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PaymentsSidebarContent),
  ),
)
