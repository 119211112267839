import _ from '@lodash';
import { Card, CardHeader, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SPRoundIcon } from 'main/icons';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {
        maxHeight: '75vh',
        overflow: 'auto'
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    calendar: {
        width: '100%',
        background: theme.palette.primary.main,
        borderRadius: 5,
    },
    calendarHeader: {
        width: '100%',
    },
    calendarPicker: {
        height: 72,
        width: '100%',
        background: '#3f3f3f',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    calendarPickerDates: {
        height: 72,
        width: '100%',
    },
    mailItem: {},
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    labels: {},
    badge: {
        top: 5,
        right: 15,
    },
    liRoot: {
        padding: 0
    },
    clockInBtn: {
        background: '#333',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        }
    },
    clockOutBtn: {
        background: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        '&:active': {
            background: '#5f5f5f',
        }
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    withdraw: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    inlineBadge: {
        top: '25%',
        left: '110%',
        right: 'unset',
        fontSize: 11,
        padding: 4,
        height: 16,
        transform: 'none',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
});

const initialState = {
    loading: false,
    expanded: {},
    data: {
        Date: null,
        timecard: {},
    }
}

class TechnicianEstimate extends Component {

    state = {
        ...initialState
    };

    componentDidMount() {
        let { data } = this.props;
        this.setState({ ...this.state, data })
    }

    componentDidUpdate(prevProps, prevState) {
        let { data } = this.props;
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ ...this.state, data })
        }
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        const { classes } = this.props;
        const { data } = this.state;
        let total = data.PriceTotal;
        if (!total) {
            if (data.Data) {
                total = _.sumBy(data.Data.Components, 'Price');
            } else {
                total = 0;
            }
        }
        const statuses = [{ text: "Open", icon: "timelapse", color: 'action' }, { text: "Accepted", icon: "assignment_turned_in", color: 'primary', iconColor: '#4dbce9', weight: 'bold' }, { text: "Scheduled", icon: "today", color: 'primary' }, { text: "Completed", icon: "check_circle_outline", color: 'secondary' }, { text: "Expired", icon: "event_busy", color: 'error' }]
        const status = statuses[data.Status || 0];

        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }

        return (
            <Card className="mt-4 mb-8 rounded-lg" style={{ border: '1px solid lightgrey', touchAction: 'pan-y' }}>
                <CardHeader onClick={this.props.onClick} className={classNames("p-12 relative", "highlight")}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classNames(classes.subheader, "truncate"), }
                    }
                    avatar={
                        <SPRoundIcon color={status.color} className={`${increaseFontSize ? "text-28" : "text-24"} mt-4`} />
                    }
                    action={
                        <div className="absolute w-96 pin-r mr-16 pin-t text-right mt-12">
                            <Typography variant="caption" className={`${increaseFontSize ? "text-16" : "text-12"} font-bold`}>{`${data.OptionCount && !data.SelectedOption ? `${data.OptionCount} Options` : this.formatDollars(total)}`}</Typography>
                            <Typography variant="caption" className={`${increaseFontSize ? "text-14" : "text-10"}  text-grey-darker`}><Icon color={status.color} className={`${increaseFontSize ? "text-16" : "text-14"}  mr-4 mb-2`} style={{ verticalAlign: 'middle' }}>{status.icon}</Icon>{status.text}</Typography>
                        </div>
                    }
                    title={
                        <Typography className={`flex ${increaseFontSize ? "text-16" : "text-12"} font-bold truncate pt-2`}>
                            <span>{data.EstimateNumber}</span>
                            <span style={{ background: '#4f4f4f', paddingTop: 2, marginTop: -1 }} className={`ml-6 rounded text-white ${increaseFontSize ? "text-12" : "text-10"} px-4 pb-0`}>{moment(data.IssuedDate).format('M/D/YYYY')}</span>
                        </Typography>
                    }
                    subheader={<div className={`${increaseFontSize ? "text-14" : "text-11"}`}>{data.SiteName}<Icon className={`${increaseFontSize ? "text-16" : "text-14"} mb-2 align-middle`} color="action">location_on</Icon></div>}
                >
                </CardHeader>
                {/* {supervisor && open &&
                    <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                        <Estimate source="supervisor" trips={[...trips]} supervisor={supervisor} timecard={{ Co, Employee, Date: data.Date, Data: { Punch: [], Labor: [], Attachments: [] }, ...timecard, }} />
                    </div>
                } */}
            </Card>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers, technicianApp }) {
    return {
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        userPreferences: spReducers.userProfiles.User.Preferences,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianEstimate)));
