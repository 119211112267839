import VendorsApp from 'main/content/apps/vendors/VendorsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const VendorsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/vendors/:id',
            component: VendorsApp
        },
        {
            path     : '/apps/vendors',
            component: () => <Redirect to="/apps/vendors/all"/>
        }
    ]
};
