import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Fab, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import AgreementTypeTaskDialog from './AgreementTypeTaskDialog';
import _ from '@lodash';
import { FeatureFlagsContext } from '../../../../providers/featureFlagsProvider';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class AgreementTypeTasksList extends Component {
    static contextType = FeatureFlagsContext

    state = {
        selectedAgreementTypeTasksMenu: null,
        featureFlags: this.context,
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedAgreementTypeTaskMenu = (event) => {
        this.setState({ selectedAgreementTypeTasksMenu: event.currentTarget });
    };

    closeSelectedAgreementTypeTasksMenu = () => {
        this.setState({ selectedAgreementTypeTasksMenu: null });
    };

    render() {
        const { classes, securables, openNewAgreementTypeTaskDialog, serviceUnitCategories, serviceUnitTypes, openEditAgreementTypeTaskDialog, } = this.props;
        const data = this.props.agreementTypeTaskData;
        const accessLevel = _.find(securables, { Securable: "agreement-types" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={() => {
                                const { Co, BusinessUnit, Division, AgreementType } = this.props.agreementType;
                                openNewAgreementTypeTaskDialog({
                                    Co,
                                    BusinessUnit,
                                    Division,
                                    AgreementType,
                                })
                            }
                            }
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <AgreementTypeTaskDialog />
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">library_books</Icon>Agreement Type Tasks</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditAgreementTypeTaskDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center",
                                width: 140,
                                show: false,
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "font-bold justify-center",
                                width: 140,
                                show: false,
                            },
                            {
                                Header: "Agreement Type",
                                accessor: "AgreementType",
                                className: "font-bold justify-center",
                                width: 140,
                                show: false,
                            },
                            {
                                Header: "Task",
                                accessor: "TaskType",
                                className: "font-bold justify-center",
                                width: 140
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 256
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 320
                            },
                            {
                                Header: "Service Unit Category",
                                id: "unitCat",
                                className: "justify-center",
                                width: 256,
                                accessor: r => (serviceUnitCategories && r.UnitCat) && _.find(serviceUnitCategories, { Co: r.Co, UnitCat: r.UnitCat }).Name
                            },
                            this.state.featureFlags['spp-507-multi-unit-type-select'] === true ? {
                                Header: "Service Unit Types",
                                id: "unitTypes",
                                className: "justify-center text-center",
                                width: 256,
                                getProps: (state, rowInfo,column)=>{
                                    return {
                                        style:{
                                            textWrap:'pretty'
                                        }
                                    }
                                },
                                accessor: r => {
                                    if(!r.UnitTypes) return '';

                                    return r.UnitTypes.map(ut=> ut.UnitType.Name).join(", ");
                                }
                            } : {
                                Header: "Service Unit Type",
                                id: "unitType",
                                className: "justify-center",
                                styles: 'text:wrap:pretty',
                                width: 256,
                                accessor: r => {
                                    if ((serviceUnitTypes && r.UnitType)) {
                                        const type = _.find(serviceUnitTypes, { Co: r.Co, UnitCat: r.UnitCat, UnitType: r.UnitType });
                                        if (type) {
                                            return type.Name;
                                        } else {
                                            return 'Not Found';
                                        }
                                    } else {
                                        return '';
                                    }
                                }
                            },
                            {
                                Header: "Price",
                                id: "price",
                                className: "justify-center",
                                width: 128,
                                accessor: r => r.Revenue != null && `$${r.Revenue.toFixed(2)}`
                            },
                            {
                                Header: "Add'l Price",
                                id: "additional",
                                className: "justify-center",
                                width: 128,
                                accessor: r => r.Additional != null && `$${r.Additional.toFixed(2)}`
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Agreement Type Tasks found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditAgreementTypeTaskDialog: Actions.openEditAgreementTypeTaskDialog,
        removeAgreementTypeTask: Actions.removeAgreementTypeTask,
        openNewAgreementTypeTaskDialog: Actions.openNewAgreementTypeTaskDialog
    }, dispatch);
}

function mapStateToProps({ agreementTypeTasksApp, spReducers }) {
    return {
        user: agreementTypeTasksApp.user,
        serviceUnitCategories: spReducers.serviceUnitCategories,
        serviceUnitTypes: spReducers.serviceUnitTypes,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementTypeTasksList)));
