import React, { Component } from 'react';
import { Avatar, Dialog, Paper, Typography, withStyles, TextField, IconButton, Icon, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { FuseScrollbars } from '@fuse';
import moment from 'moment/moment';
// import * as Actions from './store/actions';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from 'history.js';
import _ from '@lodash';
import { InsertCommentTwoTone } from '@material-ui/icons';
import getProfileImage from '../functions/getProfileImageUrl';

const styles = theme => ({
    messageRow: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: '0 16px 4px 16px',
        flex: '0 0 auto',
        // maxWidth: 274,
        '&.contact': {
            paddingRight: 56,
            '& $bubble': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                '& $time': {
                    marginLeft: 12
                }
            },
            '&.first-of-group': {
                '& $bubble': {
                    borderTopLeftRadius: 20
                }
            },
            '&.last-of-group': {
                '& $bubble': {
                    borderBottomLeftRadius: 20
                }
            }
        },
        '&.me': {
            paddingLeft: 56,

            '& $avatar': {
                order: 2,
                margin: '0 0 0 16px'
            },

            '& $bubble': {
                marginLeft: 'auto',
                marginRight: 32,
                backgroundColor: theme.palette.grey[800],
                color: theme.palette.getContrastText(theme.palette.grey[800]),
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                '& $time': {
                    justifyContent: 'flex-end',
                    right: 0,
                    marginRight: 12
                }
            },
            '&.first-of-group': {
                '& $bubble': {
                    borderTopRightRadius: 20
                }
            },

            '&.last-of-group': {
                '& $bubble': {
                    borderBottomRightRadius: 20
                }
            }
        },
        '&.contact + .me, &.me + .contact': {
            paddingTop: 20,
            marginTop: 20
        },
        '&.first-of-group': {
            '& $bubble': {
                borderTopLeftRadius: 20,
                paddingTop: 13
            }
        },
        '&.last-of-group': {
            '& $bubble': {
                borderBottomLeftRadius: 20,
                paddingBottom: 13,
                '& $time': {
                    display: 'flex'
                }
            }
        }
    },
    avatar: {
        position: 'absolute',
        right: 0,
        margin: 0
    },
    bubble: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        maxWidth: '100%'
    },
    message: {
        whiteSpace: 'pre-wrap',
        lineHeight: 1.2,
        overflow: 'hidden',
    },
    time: {
        position: 'absolute',
        display: 'none',
        width: '100%',
        fontSize: 11,
        marginTop: 8,
        top: '100%',
        left: 0,
        whiteSpace: 'nowrap'
    },
    bottom: {
        background: theme.palette.background.default,
        borderTop: '1px solid rgba(0, 0, 0, 0.13)'
    },
    inputWrapper: {
        borderRadius: 24
    },
    chat: {
        background: theme.palette.background.default,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header_Dark.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 264,
        maxHeight: 264
    },
    fullScreenChat: {
        background: theme.palette.background.default,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header_Dark.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 'calc(100vh - 118px)',
        maxHeight: 'calc(100vh - 118px)',
    },
    messageLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        cursor: 'pointer',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    endConversation: {
        '&:hover': {
            color: theme.palette.error[500]
        },
    }

});
class URLify extends React.Component {
    render() {
        let text = this.renderText()
        return (
            <div>{text}</div>
        )
    }
}

class Chat extends Component {
    state = {
        messageText: '',
        hasFocus: false,
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps) {
        const { chat, user } = this.props;
        const { hasFocus } = this.state;
        if (chat && !_.isEqual(prevProps.chat, chat)) {
            this.scrollToBottom();
        }
    }

    handleFocus = () => {
        this.setState({ ...this.state, hasFocus: true }, () => {
            const { chat, user } = this.props;

        });
    }

    handleBlur = () => {
        this.setState({ ...this.state, hasFocus: false });
    }

    isFirstMessageOfGroup = (item, i) => {
        return (i === 0 || (this.props.chat.Messages[i - 1] && this.props.chat.Messages[i - 1].From !== item.From));
    };

    isLastMessageOfGroup = (item, i) => {
        return (i === this.props.chat.Messages.length - 1 || (this.props.chat.Messages[i + 1] && this.props.chat.Messages[i + 1].From !== item.From));
    };

    onInputChange = (ev) => {
        this.setState({ messageText: ev.target.value });
    };

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    onMessageSubmit = (ev) => {
        const { Co, chat } = this.props;
        ev.preventDefault();
        if (this.state.messageText === '') {
            return;
        }
        const message = {
            Co,
            MessageSid: this.guid(),
            Type: 'text',
            From: this.props.chat.To,
            To: this.props.chat.From,
            Body: this.state.messageText,
            Queue: chat.Messages[chat.Messages.length - 1].Queue,
            ConversationID: chat.Conversation,
            CreatedDate: new Date(),
            Data: {
                ErrMsg: null,
            }
        };
        chat.Messages.push(message);
        this.props.addText(message)
            .then(() => {
                this.setState({ messageText: '' });
                this.scrollToBottom();
            });
    };

    scrollToBottom = () => {
        this.chatScroll.scrollTop = this.chatScroll.scrollHeight;
    };

    getHostName = (url) => {
        var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
        if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
            return match[2];
        }
        else {
            return null;
        }
    }

    getDomain = (url) => {
        var hostName = this.getHostName(url);
        var domain = hostName;

        if (hostName != null) {
            var parts = hostName.split('.').reverse();

            if (parts != null && parts.length > 1) {
                domain = parts[1] + '.' + parts[0];

                if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
                    domain = parts[2] + '.' + domain;
                }
            }
        }

        return domain;
    }

    isExternal = (url) => {
        var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
        if (match != null && typeof match[1] === 'string' &&
            match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol)
            return true;
        if (match != null && typeof match[2] === 'string' &&
            match[2].length > 0 &&
            match[2].replace(new RegExp(':(' + { 'http:': 80, 'https:': 443 }[window.location.protocol] + ')?$'), '')
            !== window.location.host) {
            return true;
        }
        else {
            return false;
        }
    }

    urlify = (text) => {
        const { classes } = this.props;
        var getLocation = function (href) {
            var l = document.createElement("a");
            l.href = href;
            return l;
        };
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        let parts = text.split(urlRegex) // re is a matching regular expression
        for (let i = 1; i < parts.length; i += 2) {
            let url = parts[i];
            let loc = getLocation(url);
            let path = loc.pathname;
            parts[i] = this.isExternal(url) ?
                <Tooltip title={url} placement="top"><div className={classes.messageLink} onClick={() => { window.open(url); }}>{url}</div></Tooltip> :
                <Tooltip title={url} placement="top"><div className={classes.messageLink} onClick={() => { history.push(path); }}>{url}</div></Tooltip>;
        }
        return parts
    }

    endConversation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { Messages } = this.props.chat;
        this.props.toggleConversation({ ...Messages[Messages.length - 1], EndConversationYN: 'Y', Data: { ErrMsg: null } });
    }

    shouldShowContactAvatar = (item, i) => {
        const { chat } = this.props;
        return (
            item.From === chat.To &&
            ((chat.Messages[i + 1] && (chat.Messages[i + 1].From !== chat.To) || (chat.Messages[i + 1] && chat.Messages[i + 1].From === chat.To && item.HandledBy !== chat.Messages[i + 1].HandledBy)) || !chat.Messages[i + 1])
        );
    };

    render() {
        const { Co, classes, chat, contacts, user, users, className, history, fullScreen } = this.props;
        const { messageText } = this.state;
        return (
            <Paper elevation={0} className={classNames("flex flex-col", className)}>
                <FuseScrollbars
                    containerRef={(ref) => {
                        this.chatScroll = ref
                    }}
                    className={`flex flex-1 flex-col overflow-y-auto ${fullScreen ? classes.fullScreenChat : classes.chat}`}
                >
                    {

                        (chat && chat.Messages && chat.Messages.length > 0) &&
                        (
                            <div className="flex flex-col p-12 pb-40">
                                {chat.Messages.map((item, i) => {
                                    const contact = !item.Direction || item.Direction === 'outgoing' ? _.find(users, { UserName: item.HandledBy }) || user : item.From;
                                    console.warn(contact, item);
                                    return (
                                        <div
                                            key={i}
                                            className={classNames(
                                                classes.messageRow,
                                                { 'me': item.From === chat.To },
                                                { 'contact': item.From !== chat.To },
                                                { 'first-of-group': this.isFirstMessageOfGroup(item, i) },
                                                { 'last-of-group': this.isLastMessageOfGroup(item, i) }
                                            )}
                                        >
                                            {this.shouldShowContactAvatar(item, i) && (
                                                <Avatar className={classes.avatar} src={(contact.Data && contact.Data.Avatar) && getProfileImage(`Co=${Co}&ID=${contact.Data.Avatar}`)}
                                                    alt={`${contact.FirstName} ${contact.LastName}`}
                                                >
                                                    {!contact.Data || !contact.Data.Avatar || contact.Data.Avatar === '' ? contact.FirstName[0] : ''}
                                                </Avatar>
                                            )}
                                            <Tooltip enterDelay={1000} title={<div><div>{contact.UserName || contact}</div><br /><div style={{ marginTop: -12 }}>{moment(moment(item.CreatedDate)).local().format('MMMM Do YYYY, h:mm:ss a')}</div></div>} placement="top">
                                                <div className={classes.bubble}>
                                                    <div className={classes.message}>
                                                        <React.Fragment>
                                                            {this.urlify(item.Body)}
                                                            {item.MediaJSON &&
                                                                item.MediaJSON.map((media, index) => {
                                                                    if (media.Type.split("/")[0] === "image") {
                                                                        return (
                                                                            <img onClick={() => this.setState({ ...this.state, attachment: media.Uri })} key={index} src={media.Uri} style={{ width: '100%', marginTop: 8, borderRadius: 5 }} />
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    </div>
                                                    <Typography className={classes.time} color="textSecondary"><React.Fragment>{item.EndConversationYN === "Y" && <Icon className="text-16 mr-4" color={i === chat.Messages.length - 1 ? "error" : "action"}>snooze</Icon>}{moment(moment(item.CreatedDate)).local().format('MMMM Do YYYY, h:mm:ss a')}</React.Fragment></Typography>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )
                                })}
                                {this.state.attachment &&
                                    <Dialog
                                        open={this.state.attachment}
                                        onClose={() => this.setState({ attachment: null })}
                                        maxWidth="md"
                                    >
                                        <img className="w-full" src={this.state.attachment} />
                                    </Dialog>
                                }
                            </div>
                        )
                    }

                </FuseScrollbars>
                <form onSubmit={this.onMessageSubmit} className={classNames(classes.bottom, "conversation-footer p-8 pin-b pin-l pin-r absolute")}>
                    <Paper className={classNames(classes.inputWrapper, "flex items-center relative")}>
                        <TextField
                            autoFocus={true}
                            id="message-input"
                            className="flex-1"
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: "flex flex-grow flex-no-shrink ml-16 mr-48 my-8",
                                    input: ""
                                },
                                placeholder: "Type your message"
                            }}
                            inputProps={{
                                autoComplete: "off",
                            }}
                            InputLabelProps={{
                                shrink: false,
                                className: classes.bootstrapFormLabel
                            }}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onChange={this.onInputChange}
                            value={messageText}
                        />
                        <IconButton className="absolute pin-r pin-t" type="submit">
                            <Icon className="text-24" color="action">send</Icon>
                        </IconButton>
                    </Paper>
                </form>
            </Paper>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addText: Actions.addTextMessage,
        toggleConversation: Actions.endConversation,
    }, dispatch);
}

function mapStateToProps({ chatPanel, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Chat));
