import axios from 'axios/index';
import { showMessage, setRequests } from 'store/actions';
import _ from '@lodash';

export const OPEN_REQUEST_DIALOG = '[SUPPORT REQUEST APP] OPEN REQUEST DIALOG';
export const CLOSE_REQUEST_DIALOG = '[SUPPORT REQUEST APP] CLOSE REQUEST DIALOG';
export const UPDATE_REQUEST = '[SUPPORT REQUEST APP] UPDATE REQUEST';
export const CHANGE_REQUEST = '[SUPPORT REQUEST APP] CHANGE REQUEST';
export const REMOVE_REQUEST = '[SUPPORT REQUEST APP] REMOVE REQUEST';
export const CHIP_CHANGE_REQUEST = '[SUPPORT REQUEST APP] CHIP CHANGED REQUEST';
export const NEW_CHIP_REQUEST = '[SUPPORT REQUEST APP] NEW CHIP REQUEST';
export const MAKE_COVER_REQUEST = '[SUPPORT REQUEST APP] MAKE COVER REQUEST';
export const REMOVE_COVER_REQUEST = '[SUPPORT REQUEST APP] REMOVE COVER REQUEST';
export const ADD_ATTACHMENT_REQUEST = '[SUPPORT REQUEST APP] ADD ATTACHMENT REQUEST';
export const REMOVE_ATTACHMENT_REQUEST = '[SUPPORT REQUEST APP] REMOVE ATTACHMENT REQUEST';
export const REMOVE_DUE_REQUEST = '[SUPPORT REQUEST APP] REMOVE DUE REQUEST';
export const TOGGLE_LABEL_REQUEST = '[SUPPORT REQUEST APP] TOGGLE LABEL REQUEST';
export const TOGGLE_ASSIGNEE_REQUEST = '[SUPPORT REQUEST APP] TOGGLE ASSIGNEE REQUEST';
export const ADD_CHECKLIST_REQUEST = '[SUPPORT REQUEST APP] ADD CHECKLIST REQUEST';
export const UPDATE_CHECKLIST_REQUEST = '[SUPPORT REQUEST APP] UPDATE CHECKLIST REQUEST';
export const REMOVE_CHECKLIST_REQUEST = '[SUPPORT REQUEST APP] REMOVE CHECKLIST REQUEST';
export const ADD_CHECKLIST_REQUEST_ITEM = '[SUPPORT REQUEST APP] ADD CHECKLIST REQUEST ITEM';
export const UPDATE_CHECKLIST_REQUEST_ITEM = '[SUPPORT REQUEST APP] UPDATE CHECKLIST REQUEST ITEM';
export const REMOVE_CHECKLIST_REQUEST_ITEM = '[SUPPORT REQUEST APP] REMOVE CHECKLIST REQUEST ITEM';
export const CHECKLIST_CHANGE_REQUEST = '[SUPPORT REQUEST APP] CHECKLIST CHANGE REQUEST';
export const CHECKLIST_REMOVE_REQUEST = '[SUPPORT REQUEST APP] CHECKLIST REMOVE REQUEST';
export const COMMENT_ADD_REQUEST = '[SUPPORT REQUEST APP] COMMENT ADD REQUEST';

export function openSupportRequestDialog(data, fetch) {
    if (!fetch || !data.ID) {
        return {
            type: OPEN_REQUEST_DIALOG,
            payload: { ...data, open: true }
        }
    } else {
        return (dispatch) => {
            const request = axios.get(`${window["apiLocation"]}/api/SupportRequest?ID=${data.ID}`);

            return request.then((response) => {
                dispatch({
                    type: OPEN_REQUEST_DIALOG,
                    payload: { ...response.data, open: true }
                });
            }).catch(() => {

            })
        }
    }
}

export function closeSupportRequestDialog() {
    return {
        type: CLOSE_REQUEST_DIALOG
    }
}

export function updateSupportRequest(card, bool, stealth) {
    return (dispatch, getState) => {
        const data = _.cloneDeepWith(card);
        delete data.errorData;
        delete data.Event;
        const request = axios.put(`${window["apiLocation"]}/api/SupportRequest?ID=${card.ID}`,
            data
        );

        return request.then((response) => {
            if (!bool) {
                dispatch(showMessage({
                    message: 'Support Request Saved',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            }
            dispatch(updateSupportRequestList(card));

        });
    }
}

export function updateSupportRequestList(card) {
    return (dispatch, getState) => {
        const state = getState();
        const { requests } = _.cloneDeepWith(state.support);
        const req= _.find(requests, { ID: card.ID });
        if (req) {
            req.Status = card.Status;
            req.Title = card.Title;
            req.Description = card.Description;
            req.Priority = card.Priority;
            req.Comments = card.Data.Comments.length;
            req.Attachments = card.Data.Attachments.length;
            req.Assignees = '';
            card.Data.Assignees.map((value, index) => {
                if (index === 0) {
                    req.Assignees = `${req.Assignees}${value.UserName}`
                } else {
                    req.Assignees = `${req.Assignees}, ${value.UserName}`
                }
            });
            req.Tags = '';
            card.Data.Tags.map((value, index) => {
                if (index === 0) {
                    req.Tags = `${req.Tags}${value.Tag}`
                } else {
                    req.Tags = `${req.Tags}, ${value.Tag}`
                }
            });
            dispatch(setRequests(requests));
        }
    }
}

export function removeSupportRequest(card) {
    return (dispatch) => {
        const request = axios.delete(`${window["apiLocation"]}/api/SupportRequest?ID=${card.ID}`);

        return request.then((response) => {
            dispatch(showMessage({
                message: 'SupportRequest Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
            dispatch({
                type: REMOVE_REQUEST,
                payload: card
            });
        }
        );
    };
}

export function handleChange(event) {
    return {
        type: CHANGE_REQUEST,
        target: event.target,
    };
}

export function chipChange(name, value) {
    return {
        type: CHIP_CHANGE_REQUEST,
        name,
        value
    };
}

export function addNewChip(name, value) {
    return {
        type: NEW_CHIP_REQUEST,
        name,
        value
    };
}

export function makeCover(attachmentId) {
    return {
        type: MAKE_COVER_REQUEST,
        attachmentId
    };
}

export function removeCover() {
    return {
        type: REMOVE_COVER_REQUEST
    };
}

export function addSupportRequestAttachment(attachments) {
    return {
        type: ADD_ATTACHMENT_REQUEST,
        attachments
    }
}

export function removeAttachment(attachment) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/Attachment/DeleteAttachment?Co=${encodeURIComponent(attachment.Co)}&Attachment=${encodeURIComponent(attachment.Attachment)}`);
        return request.then((response) => {
            return dispatch({
                type: REMOVE_ATTACHMENT_REQUEST,
                attachment
            })
        })
    }
}

export function removeDue() {
    return {
        type: REMOVE_DUE_REQUEST
    };
}

export function toggleLabel(label, card) {
    return (dispatch, getState) => {
        const state = getState();
        const AddedBy = state.spReducers.userProfiles.User.UserName;
        const tag = _.find(card.Data.Tags, { Tag: label.Tag });
        if (label.SupportRequestID) {
            const request = !tag ? axios.post(`${window["apiLocation"]}/api/SupportRequestTag`, { ...label, AddedBy, AddedDate: new Date(), Data: { ErrMsg: null } }) : axios.delete(`${window["apiLocation"]}/api/SupportRequestTag?ID=${label.SupportRequestID}&Tag=${label.Tag}`);
            return request.then((response) => {
                return dispatch({
                    type: TOGGLE_LABEL_REQUEST,
                    card,
                    label
                })
            })
        } else {
            return dispatch({
                type: TOGGLE_LABEL_REQUEST,
                card,
                label
            })
        }
    }
}

export function toggleAssignee(assignee, method) {
    return (dispatch, getState) => {
        const request = method == 'ADD' ?
            axios.post(`${window["apiLocation"]}/api/SupportRequestAssignee`, assignee) :
            axios.delete(`${window["apiLocation"]}/api/SupportRequestAssignee?ID=${assignee.SupportRequestID}&UserName=${encodeURIComponent(assignee.UserName)}`);
        return request.then((response) => {
            return dispatch({
                type: TOGGLE_ASSIGNEE_REQUEST,
                assignee,
                method,
            });
        });
    };
}

export function addChecklist(list) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/SupportRequestChecklist`, list);
        return request.then((response) => {
            return dispatch({
                type: ADD_CHECKLIST_REQUEST,
                list: response.data
            });
        });
    };
}

export function updateChecklist(list) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/SupportRequestChecklist?ID=${list.SupportRequestID}&Checklist=${list.ID}`, list);
        return request.then((response) => {
            return dispatch({
                type: UPDATE_CHECKLIST_REQUEST,
                list: response.data
            });
        });
    };
}

export function removeChecklist(list) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/SupportRequestChecklist?ID=${list.SupportRequestID}&Checklist=${list.ID}`);
        return request.then((response) => {
            return dispatch({
                type: REMOVE_CHECKLIST_REQUEST,
                list
            });
        });
    };
}
export function addChecklistItem(item) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/SupportRequestChecklistItem`, item);
        return request.then((response) => {
            return dispatch({
                type: ADD_CHECKLIST_REQUEST_ITEM,
                item: response.data
            });
        });
    };
}

export function updateChecklistItem(item) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/SupportRequestChecklistItem?ID=${item.SupportRequestID}&Checklist=${item.ChecklistID}&Item=${item.ID}`, item);
        return request.then((response) => {
            return dispatch({
                type: UPDATE_CHECKLIST_REQUEST_ITEM,
                item: response.data
            });
        });
    };
}

export function removeChecklistItem(item) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/SupportRequestChecklistItem?ID=${item.SupportRequestID}&Checklist=${item.ChecklistID}&Item=${item.ID}`);
        return request.then((response) => {
            return dispatch({
                type: REMOVE_CHECKLIST_REQUEST_ITEM,
                item
            });
        });
    };
}


export function commentAdd(comment) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/SupportRequestComment`, comment);
        return request.then((response) => {
            return dispatch({
                type: COMMENT_ADD_REQUEST,
                comment
            });
        });
    };
}
