import { Plugins } from '@capacitor/core';
import { Button, Dialog, DialogContent, DialogTitle, Grow, Icon, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../customers/store/actions';
// import 'capacitor-plugin-omni-sdk';
const { OmniPlugin } = Plugins;
const { initOmni, getReaders, connectReader, disconnectReader, takePayment } = OmniPlugin || {};



const styles = theme => ({
    root: {
        margin: 24,
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/images/backgrounds/SP_Header_Dark.png)',
        backgroundPosition: 'center'
    },
    checkbox: {
        paddingRight: 4,
        paddingTop: 10,
    },
    approved: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#333',
            color: '#fff',
        },
        '&:active': {
            backgroundColor: '#333',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#9f9f9f',
            color: '#5f5f5f',
        }
    },
    voidButton: {
        backgroundColor: `${theme.palette.error[400]} !important`,
        color: '#fff',
        '&:hover': {
            backgroundColor: `${theme.palette.error[500]} !important`,
            color: '#fff',
        },
        '&:active': {
            backgroundColor: `${theme.palette.error[500]} !important`,
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#9f9f9f',
            color: '#5f5f5f',
        }
    }
});

const initialState = {
    transaction: null,
    voidTransaction: null,
    data: null
};

class PaymentMethod extends Component {
    fattJs = null;
    state = { ..._.cloneDeepWith(initialState) };

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ data });
        }
    }

    componentDidMount() {
        const { data, payment } = this.props;
        this.setState({ ..._.cloneDeepWith(initialState), data });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        const { completedTransaction, isPayButtonDisabled, loading, formValues, total, editAmount, reader, readers, transaction } = this.state;
        const { classes, open, payments, customer } = this.props;
        const { data } = this.state;

        window["warn"]('Payment info: ', this.props.payment);
        // var pmt = (this.props.payment && this.props.payment.Data && this.props.payment.Data.Payment) ? this.props.payment.Data.Payment : this.props.payment;
        const card = data;
        const cardType = card ? card.PaymentMethodType : null;
        const type = card ? (card.PaymentMethodType === 'checking' || card.PaymentMethodType === 'savings' ? "ACH" : "CREDIT") : null;


        return (
            <Media queries={{ small: "(max-width: 600px)", medium: "(min-width: 768px) and (min-height: 768px)" }}>
                {matches =>
                    <Dialog
                        classes={{ paper: classes.root }}
                        // disableBackdropClick
                        // disableEscapeKeyDown
                        maxWidth="xs"
                        fullWidth
                        TransitionComponent={Grow}
                        onClose={() => this.props.onClose()}
                        aria-labelledby="payment-dialog-title"
                        open={open}
                    >
                        <DialogTitle classes={{ root: "p-16 px-24 pb-0 text-20" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">payment</Icon>Payment Method Details</DialogTitle>
                        <IconButton style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            color: '#333'
                        }}
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                        <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
                            <div className="w-full payment">
                                <TextField
                                    className="w-full bg-white mb-12"
                                    label="Form of Payment"
                                    id="type"
                                    name="PaymentType"
                                    variant="outlined"
                                    value={type || ''}
                                    margin="dense"
                                    InputLabelProps={{
                                        style: {
                                            textShadow: '0px 0px 4px white'
                                        }
                                    }}
                                    select
                                    disabled={true}
                                >
                                    <MenuItem value="CREDIT">
                                        Credit / Debit
                                    </MenuItem>
                                    <MenuItem value="ACH">
                                        Bank Account
                                    </MenuItem>
                                </TextField>
                                {card &&
                                    <React.Fragment>
                                        <div className="w-full flex">
                                            <div className="w-full flex">
                                                <TextField
                                                    className="w-full bg-white mr-4"
                                                    label="Cardholder"
                                                    id="paymentBy"
                                                    name="PaymentBy"
                                                    value={card.CardholderName || customer ? (customer.FirstName && customer.LastName ? `${customer.FirstName} ${customer.LastName}` : customer.Name) : ''}
                                                    variant="outlined"
                                                    InputProps={{
                                                        className: "h-48 p-8 pl-12",
                                                        startAdornment: <InputAdornment position="start"><Icon>account_box</Icon></InputAdornment>,
                                                    }}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className={`w-full`}>
                                            <div id="card-element" className="w-full">
                                                <div className="w-full flex">
                                                    <TextField
                                                        className="w-full mt-12 bg-white"
                                                        label="Card Number"
                                                        id="card-number"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            }
                                                        }}
                                                        value={card.CardLastFour ? `**** **** **** ${card.CardLastFour}` : '**** **** **** ****'}
                                                        disabled={true}
                                                        InputProps={{
                                                            inputProps: {
                                                                className: "h-32 p-8"
                                                            },
                                                            startAdornment: <InputAdornment position="start">{card && card.PaymentMethodType && ['visa', 'mastercard', 'american_express', 'discover'].indexOf(card.PaymentMethodType) > -1 ? <img className="w-32" src={`assets/images/cards/${cardType}.png`} /> : <Icon>payment</Icon>}</InputAdornment>,
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-full flex mb-12">
                                                    <TextField
                                                        className="w-3/5 mt-12 mr-8 bg-white"
                                                        label="Card Expiration"
                                                        id="card-expire"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            }
                                                        }}
                                                        disabled={true}
                                                        InputProps={{
                                                            className: "h-48 p-8 pl-12",
                                                            inputProps: {
                                                                className: "h-32 p-8",
                                                            },
                                                            startAdornment: <InputAdornment position="start"><Icon>today</Icon></InputAdornment>,
                                                        }}
                                                        value={moment(card.CardExpirationDate).format("MM/YYYY")}
                                                    />
                                                    <TextField
                                                        className="w-2/5 mt-12 bg-white"
                                                        label="Security Code"
                                                        variant="outlined"
                                                        value="***"
                                                        InputLabelProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            }
                                                        }}
                                                        disabled={true}
                                                        InputProps={{
                                                            className: "pl-12",
                                                            inputProps: {
                                                                className: "h-32 p-8"
                                                            },
                                                            startAdornment: <InputAdornment position="start" className="mr-0"><Icon>lock</Icon></InputAdornment>,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {/* {pmt.PaymentType === "CREDIT" && */}
                                {/* <div className="flex mb-16">
                                    <Button
                                        variant="contained"
                                        className={classNames("w-full", ((transaction && transaction.is_voidable) || !payment.VoidDate) ? "rounded-r-none" : "", classes.approved)}
                                        color="secondary"
                                        disabled={((transaction && transaction.is_voided) || payment.VoidDate)}
                                    >
                                        {loading ? <CircularProgress color="secondary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8" color={((transaction && transaction.is_voided) || payment.VoidDate) ? undefined : "secondary"}>{((transaction && transaction.is_voided) || payment.VoidDate) ? "payment" : "check_circle_outline"}</Icon>}
                                        {loading ? 'Voiding Payment' : `${((transaction && transaction.is_voided) || payment.VoidDate) ? "Transaction Voided" : pmt.PaymentType === "CREDIT" ? "Approved Payment" : `${pmt.PaymentType} Payment`} - ${this.formatDollars(pmt.PaymentTotal)}`}
                                    </Button>
                                    {((transaction && transaction.is_voidable) || (pmt.PaymentType !== "CREDIT" && !transaction && !payment.VoidDate)) ?
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                className={classNames("w-72 rounded-l-none", classes.voidButton)}
                                                color={"primary"}
                                                onClick={() => this.setState({ voidTransaction: true })}
                                                disabled={pmt.PaymentType === "CREDIT" && !transaction || (this.state.transaction && !this.state.transaction.is_voidable) || payment.VoidDate}
                                            >
                                                Void
                                            </Button>
                                            <ConfirmationDialog
                                                open={this.state.voidTransaction}
                                                title={<div><Icon className="mr-6 align-middle mb-4">payment</Icon>Are You Sure?</div>}
                                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to void this transaction? This action cannot be undone.`}</div>}
                                                confirmText="Void Payment"
                                                cancelText="Cancel"
                                                onCancel={() => this.setState({ ...this.state, voidTransaction: false })}
                                                onConfirm={() => this.setState({ ...this.state, voidTransaction: false, loading: true }, () => this.voidTransaction())}
                                            />
                                        </React.Fragment> : ''
                                    }
                                </div> */}
                                {
                                    card && card.ReusableYN !== "N" ?
                                        <Button
                                            variant="contained"
                                            className={classNames(classes.approved, "w-full mb-12")}
                                            onClick={() => this.props.deactivatePaymentMethod(card)}
                                        >
                                            <Icon className="align-middle mr-4" color="error">delete</Icon>De-Activate
                                        </Button> :
                                        <Button
                                            variant="contained"
                                            className={classNames("w-full mb-12")}
                                            disabled={true}
                                        >
                                            <Icon className="align-middle mr-4">delete</Icon>De-Activated
                                        </Button>
                                }

                                {payments &&
                                    <div className="w-full px-4 pb-12">
                                        <Typography variant="h6" className="font-bold text-14 mb-0">Payments</Typography>
                                        <div className="w-full max-h-128 overflow-auto">
                                            {
                                                payments.map((pmt) => (
                                                    <div className="w-full flex pb-8 text-11 font-bold relative cursor-pointer">
                                                        <Icon className="mr-4 text-16" color={pmt.VoidDate ? "error" : "primary"}>receipt</Icon>
                                                        <div className={classNames(pmt.VoidDate ? "mr-6 mt-2 line-through text-grey-dark" : "mt-2 mr-16")}>{new Date(pmt.PaymentDateTime).toLocaleDateString('en-US')}</div>
                                                        <div className={classNames(pmt.VoidDate ? "mr-6 mt-2 line-through text-grey-dark" : "mt-2 mr-16")}>{pmt.Memo}</div>
                                                        <div className={classNames("pin-r absolute mt-2", pmt.VoidDate ? "line-through text-grey-dark" : "")}>{`${this.formatDollars(pmt.PaymentTotal)}`}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deactivatePaymentMethod: Actions.deactivatePaymentMethod
    }, dispatch);
}



function mapStateToProps({ invoicesApp, spReducers }) {
    return {
        companies: spReducers.companies,
        user: spReducers.userProfiles.User,
    }
}



export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod));


