import axios from 'axios';

export const SET_VERSION = '[VERSION] SET VERSION';
export const SET_ABOUT = '[VERSION] SET ABOUT';
export const SET_VERSION_NOTES = '[VERSION] SET VERSION NOTES';
export const RESET_VERSION_NOTES = '[VERSION] RESET VERSION NOTES';
export const RESET_ALLOW_VERSION_LIST = '[VERSION] RESET ALLOW VERSION LIST';
export const WARN_LIVE_VERSION = '[VERSION] WARN LIVE VERSION';

export function setVersion(version) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_VERSION,
            version,
        });
    }
}

export function resetRelease(release) {
    return (dispatch) => {
        dispatch({
            type: RESET_VERSION_NOTES,
            releaseNotes:release,
        });
    }
}

export function resetAllowVersionList(allowVersionList) {
    return (dispatch) => {
        dispatch({
            type: RESET_ALLOW_VERSION_LIST,
            allowVersionList,
        });
    }
}

export function getAbout(open,allowVersionList) {
    return (dispatch, getState) => {
        const state = getState();

        const request = axios.get(`${window["apiLocation"]}/api/About`);
        request.then((response) => {
            Promise.all([
                dispatch({
                    type: SET_ABOUT,
                    about: response.data,
                    open,
                    allowVersionList
                })
            ]).then(() => {
                let version = localStorage.getItem('SPVersion');
                if (version !== state.version.version) {
                    localStorage.setItem('SPVersion', state.version.version);
                    dispatch(getReleaseNotes(state.version.version, state.version.version));
                }
                if (response.data.InstanceType === 'P' && !window.cordova && window.location.hostname !== 'live.servicepointpro.com') {
                    dispatch({
                        type: WARN_LIVE_VERSION,
                        open: true
                    });
                }
            })
        }).catch(() => {
            const { about } = state.version;
            dispatch({
                type: SET_ABOUT,
                about,
                open
            })
        })
    }
}
export function setAbout(open, release) {
    return (dispatch, getState) => {
        const { about } = getState().version;
        dispatch({
            type: SET_ABOUT,
            about,
            open,
            release
        });
    }
}

export function warnLive(open) {
    return {
        type: WARN_LIVE_VERSION,
        open
    }
}

export function getReleaseNotes(version, release) {
    return (dispatch, getState) => {
        // window["warn"]('Release Notes Request: ', version, release)
        const state = getState();
        if (version) {
            if (state.version.releaseNotes && state.version.releaseNotes.Version === version) {
                dispatch({
                    type: SET_VERSION_NOTES,
                    data: state.version.releaseNotes,
                    open: true,
                    release
                });
            } else {
                const req = axios.get(`${window["apiLocation"]}/api/ReleaseNotes?v=${encodeURIComponent(version)}`);
                req.then((response) => {
                    const { data } = response;
                    dispatch({
                        type: SET_VERSION_NOTES,
                        data,
                        open: true,
                        release
                    });
                })
            }
        } /*else {
            dispatch({
                type: SET_VERSION_NOTES,
                data: state.version.releaseNotes,
                open: false
            });
        }*/
    }
}