import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Avatar, Badge, Button, Card, CardHeader, Chip, DialogActions, DialogContent, Drawer, FormControlLabel, Icon, IconButton, InputAdornment, List, ListItem, ListItemIcon, MenuItem, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles/index';
import { Assignment, Build, List as ListIcon, LocalShipping, Payment, Receipt } from '@material-ui/icons';
import { DatePicker, TimePicker } from "@material-ui/pickers";
import ChipInput from 'material-ui-chip-input';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import TechnicianTrip from '../technician/TechnicianTrip';
import EstimatesList from 'main/content/apps/estimates/EstimatesList';
import InvoicesList from 'main/content/apps/invoices/InvoicesList';
import PaymentsList from 'main/content/apps/invoices/PaymentsList';
import TripsList from 'main/content/apps/trips/TripsList';
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList';
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList';
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog';
import ServiceUnitsList from './ServiceUnitsList';
import ContactList from './ContactList';
import * as Actions from './store/actions';
import * as DispatchBoardActions from './../dispatch-board/store/actions';
import classNames from 'classnames';
import moment from 'moment';
import { Block } from '@material-ui/icons';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import { formatPhone } from 'main/functions';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const drawerWidth = 240;

const styles = theme => ({
    root: {},
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    formControl: {
        marginBottom: 24
    },
    status: {
        marginBottom: 24,
        maxWidth: 100
    },
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    popper: {
        opacity: 1,
        marginBottom: 15,
        marginTop: 5,
        zIndex: 5,
        // opacity: 0,
    },
    tooltip: {
        opacity: 1,
        padding: 0,
        margin: 0,
        boxShadow: '2px 2px 6px #3333',
    },
    content: {
        overflow: 'hidden',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    histTable: {
        marginLeft: 56,
        '& .ReactTable': {
            borderTop: '1px solid #f1f1f1'
        },
        '& h6': {
            fontSize: '1.8rem',
            padding: 8,
            marginBottom: '0px !important',
            '& .material-icons': {
                marginRight: '6px !important',
                fontSize: '2.4rem !important'
            }
        }
    },
    hist: {
        display: 'flex',
        position: 'relative',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 56,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        [theme.breakpoints.up('sm')]: {
            width: 56,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    scheduleBadge: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 13,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
    openBadge: {
        top: 4,
        backgroundColor: '#4dbce9',
        color: '#fff',
    },
    busyBadge: {
        top: 4,
        backgroundColor: '#6c4de9',
        color: '#fff',
    },
    capacityBadge: {
        top: 4,
        backgroundColor: '#f75151',
        color: '#fff',
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    greenText: {
        color: '#d1e751',
    },
});

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

class WorkOrdersTab extends Component {
    state = { ..._.cloneDeepWith(this.props.callHandlerData.data.WorkOrder), contacts: [], Tags: [], ServiceUnits: [], histTab: 0, showHistory: false, date:new Date() };

    componentDidMount() {
        const { Co } = this.props;
        const Data = this.props.callHandlerData.data;
        const CustomerData = Data.Customer;
        const SiteData = Data.Site;
        let { WorkOrder } = Data;

        const { Customer, Site, Contact, Phone } = SiteData;
        const { companies } = this.props;
        const coInfo = _.find(companies, { Co }).Data.Co;
        const woOpt = coInfo && coInfo.Data ? coInfo.Data.WorkOrderOptions : null;
        if (woOpt && woOpt.DisableTMBillingYN === "Y") {
            WorkOrder.PriceMethod = 'F';
        }

        if (WorkOrder.type === 'new') {
            WorkOrder.type = 'draft';
            WorkOrder = { ...WorkOrder, Co, Customer, Site, Contact, ContactPhone: Phone, Requestor: Contact, TaxCode: SiteData.TaxCode || CustomerData.TaxCode };
            this.setState({ ...WorkOrder }, () => { this.props.setCallHandlerData({ ...this.state, Data: { ...this.state.Data, Tags: [] } }); });
        }

        if (WorkOrder.type === 'saved') {
            WorkOrder.type = 'draft';
            this.setState({ ...WorkOrder }, () => { this.props.setCallHandlerData({ ...this.state }); });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { Co } = this.props;
        const Data = this.props.callHandlerData.data;
        const CustomerData = Data.Customer;
        const SiteData = Data.Site;
        let { WorkOrder } = Data;

        const { Customer, Site, Contact, Phone } = SiteData;
        const { companies } = this.props;
        const coInfo = _.find(companies, { Co }).Data.Co;
        const woOpt = coInfo && coInfo.Data ? coInfo.Data.WorkOrderOptions : null;
        if (woOpt && woOpt.DisableTMBillingYN === "Y") {
            WorkOrder.PriceMethod = 'F';
        }

        //let SiteContact = SiteData && SiteData.Data && SiteData.Data.Contacts && SiteData.Data.Contacts.length > 0 ? _.filter(SiteData.Data.Contacts, (o) => o.PrimaryYN === "Y")[0].Data.Contact : null;

        if (WorkOrder.type === 'new') {
            WorkOrder.type = 'draft';
            WorkOrder = { ...WorkOrder, Co, Customer, Site, Contact, ContactPhone: Phone, TaxCode: SiteData.TaxCode || CustomerData.TaxCode };
            this.setState({ ...WorkOrder, Data: { Tags: [], ServiceUnits: [] } }, () => { this.props.setCallHandlerData({ ...this.state }); });
        }

        if (WorkOrder.type === 'saved') {
            WorkOrder.type = 'draft';
            this.setState({ ...WorkOrder }, () => { this.props.setCallHandlerData({ ...this.state }); });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleDueByChange = (event) => {
        let { DueBy } = this.state;
        DueBy = event._d.toLocaleDateString('en-US');
        window["warn"](event, DueBy);
        this.setState({ ...this.state, DueBy }, () => { this.props.setCallHandlerData(this.state); this.checkSchedule(); });
    };

    checkSchedule = () => {
        const { Co } = this.props;
        let { DueBy } = this.state;
        const dt = new Date(DueBy).toLocaleDateString('en-US').replace(/\//g, '-')
        this.props.getScheduledTrips(Co, dt);
    }

    handleBlur = (event) => {
        this.props.setCallHandlerData(this.state);
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    canBeSubmitted() {
        const { Co, Customer, Site, Description, BusinessUnit, Division, DueBy, ServiceType, Department, Service, ArrivalWindow, ExactTime, FormOfPayment, PriceMethod, Price, RateTemplate, trySubmit, LeadTechnician, SiteContact, RequestedByContact, CustomerPO } = this.state;
        const { PORequiredYN } = this.props.callHandlerData.data.Customer;
        const can = (Co && Customer && Site && Description && BusinessUnit && Division && DueBy && ServiceType && SiteContact && Department && Service && (PORequiredYN !== 'Y' || CustomerPO) && (ArrivalWindow || ExactTime)/* && FormOfPayment && LeadTechnician*/ && PriceMethod && (PriceMethod === "F" ? (Price || Price === 0) : RateTemplate) && Site.length > 0);
        if (can && trySubmit) {
            this.endTrySubmit();
        }
        return this.props.callHandlerData.data.Customer.RefuseService !== "Y" && can;
    }

    submitWorkOrder = () => {
        return this.props.addWorkOrder({ ...this.state, EnteredDate: new Date() }).then(()=>{
            this.handleRefresh();
        })
    }

    formatPhone = (str) => {
        return formatPhone(str).formatted;
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    handleAddTag = (tag) => {
        let { Co, } = this.state;
        if (this.state.Data.Tags) {
            let { Tags } = this.state.Data;
            Tags.push({ ID: this.guid(), Co, Title: tag.toString(), AddedBy: this.props.user.username, AddedDate: new Date().toLocaleDateString('en-US'), ActiveYN: 'Y', Status: 'New' });
            this.setState({ ...this.state, Data: { ...this.state.Data, Tags } }, () => {
                this.props.setCallHandlerData({ ...this.state });
            });
        } else {
            let Tags = [];
            Tags.push({ ID: this.guid(), Co, Title: tag.toString(), AddedBy: this.props.user.username, AddedDate: new Date().toLocaleDateString('en-US'), ActiveYN: 'Y', Status: 'New' });
            this.setState({ ...this.state, Data: { ...this.state.Data, Tags } }, () => {
                this.props.setCallHandlerData({ ...this.state });
            });
        }
    }

    handleDeleteTag = (tag) => {
        let { Tags } = this.state.Data;
        if (Tags === null) {
            Tags = [];
        }
        for (var i = 0; i < Tags.length; i++) {
            if (Tags[i].ID === tag.ID) {
                if (isNaN(Tags[i].ID)) {
                    Tags.splice(i, 1);
                } else {
                    Tags[i].ActiveYN = 'N';
                    Tags[i].Status = 'Update';
                }
            }
        }
        this.setState({ ...this.state, Data: { ...this.state.Data, Tags } }, () => {
            this.props.setCallHandlerData({ ...this.state });
        });
    }

    trySubmit = () => {
        this.setState({ ...this.state, trySubmit: true });
    }

    endTrySubmit = () => {
        this.setState({ ...this.state, trySubmit: false });
    }

    toggleHistory = () => {
        const { showHistory } = this.state;
        this.setState({ ...this.state, showHistory: !showHistory });
    }

    handleArrivalWindow = (option) => {
        if (option.ArrivalWindow === "ExactTime") {
            this.setState({ ...this.state, ExactTime: "08:00:00", ArrivalWindow: null }, () => this.props.setCallHandlerData(this.state))
        } else {
            this.setState({ ...this.state, ArrivalWindow: option.ArrivalWindow }, () => this.props.setCallHandlerData(this.state))
        }
    }

    handleExactTime = (time) => {
        this.setState({
            ExactTime: time ? time.format("HH:mm:00") : null
        }, () => this.props.setCallHandlerData(this.state));
    }

    handleRefresh = () => {
        let { date } = this.state
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(new Date());
        this.props.setBoardDate(this.props.Co, date, old);
        this.updateDate(this.props.Co, oldDate, oldDate);
      }
    
      updateDate = _.debounce((Co, date, oldDate) => {
        this.props.getTripData(Co, date, oldDate)
        this.props.getWOData(Co, date, oldDate)
      }, 1000)
    
      formatDateInput(dt) {
        return dt.toLocaleDateString('en-US').replace(/\//g, '-')
      }


    render() {
        const { classes, securables, Co, addWorkOrder, updateWorkOrder, removeWorkOrder, googleMapsApiKey, companies, openWorkOrder, businessUnits, divisions, departments, services, serviceTypes, rateTemplates, taxCodes, arrivalWindows, formOfPayments, technicians } = this.props;
        const { Customer, Site, WorkOrder, addContact, histTab, showHistory } = this.state;
        const coInfo = _.find(companies, { Co }).Data.Co;
        const woOpt = coInfo && coInfo.Data ? coInfo.Data.WorkOrderOptions : null;
        const disabled = (this.props.callHandlerData.data.Customer.RefuseServiceYN === "Y" || Boolean(WorkOrder));
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "call-handler" });
        const reqBy = _.find(this.props.callHandlerData.data.Site.Data.Contacts, { Contact: this.state.RequestedByContact });
        const openQuotes = this.props.callHandlerData.data.Site.Data && _.filter(this.props.callHandlerData.data.Site.Data.Estimates, (o) => { return (o.Status < 1 && new Date(o.ExpirationDate) >= new Date()) });
        const { schedule } = this.props.callHandlerData;

        const exactTime = this.state.ExactTime ? moment(`1/1/1970 ${this.state.ExactTime}`).toDate() : null;

        const CustomerData = this.props.callHandlerData.data.Customer;
        const SiteData = this.props.callHandlerData.data.Site;
        let contacts = [...(SiteData.Data.Contacts || []), ...(CustomerData.Data.Contacts || [])];
        contacts = _.uniqBy(contacts, 'Contact');

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <DialogContent classes={{ root: "p-12 sm:p-24" }}>
                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 20px)' }} className="w-full sm:flex">
                        <Icon color="primary" className="text-32 mr-8 align-middle">assignment</Icon>
                        {
                            this.state.WorkOrder ?
                                <span>Work Order #{this.state.WorkOrder} Info
                                    <Tooltip placement="top" title="Edit Work Order">
                                        <Icon onClick={() => { openWorkOrder(this.state); }} className="text-20 align-middle mb-4 ml-8">open_in_new</Icon>
                                    </Tooltip>
                                </span>
                                :
                                <span>Work Order Info</span>
                        }
                        {this.state.Site && this.props.callHandlerData.data.Site && this.props.callHandlerData.data.Site.Data &&
                            <Button onClick={this.toggleHistory} color="primary" variant="contained" className={classNames(classes.black, "text-12 pin-r absolute mr-48 sm:mr-64 md:mr-24 capitalize mt-4 h-24")}>
                                <Icon className={classNames(classes.greenText, "mr-4 text-16")}>history</Icon>Site History
                            </Button>
                        }
                    </Typography>
                    {showHistory &&
                        <div className="w-full sm:flex">
                            <div className="hidden sm:block min-w-48 pt-20">
                                <Icon color="action">history</Icon>
                            </div>
                            <Card classes={{ root: "w-full mb-24" }}>
                                <div className={classNames(classes.hist)}>
                                    <Drawer
                                        variant="permanent"
                                        className={classNames(classes.drawer, {
                                            [classes.drawerOpen]: this.state.open,
                                            [classes.drawerClose]: !this.state.open,
                                        })}
                                        classes={{
                                            paper: classNames(classes.shelf, {
                                                [classes.drawerOpen]: this.state.open,
                                                [classes.drawerClose]: !this.state.open,
                                            }),
                                        }}
                                        open={this.state.open}
                                    >
                                        <List>
                                            <ListItem onClick={() => this.setState({ histTab: 0 })} button key="Work Orders">
                                                <ListItemIcon><Tooltip title="Work Orders" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.WorkOrders || 0} color="error"><Assignment /></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                            <ListItem onClick={() => this.setState({ histTab: 1 })} button key="Work Order Scopes">
                                                <ListItemIcon><Tooltip title="Work Order Scopes" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.Scopes || 0} color="error"><Build /></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                            <ListItem onClick={() => this.setState({ histTab: 2 })} button key="Visits">
                                                <ListItemIcon><Tooltip title="Trips" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.Trips || 0} color="error"><LocalShipping /></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                            <ListItem onClick={() => this.setState({ histTab: 3 })} button key="Invoices">
                                                <ListItemIcon><Tooltip title="Invoices" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.Invoices || 0} color="error"><Receipt /></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                            <ListItem onClick={() => this.setState({ histTab: 4 })} button key="Payments">
                                                <ListItemIcon><Tooltip title="Payments" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.Payments || 0} color="error"><Payment /></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                            <ListItem onClick={() => this.setState({ histTab: 5 })} button key="Estimates">
                                                <ListItemIcon><Tooltip title="Estimates" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.Estimates || 0} color="error"><ListIcon /></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                            <ListItem onClick={() => this.setState({ showEmailLogs: true })} button key="Emails">
                                                <ListItemIcon><Tooltip title="Email Log" placement="right"><Badge max={999} badgeContent={this.props.callHandlerData.data.Site.Data && this.props.callHandlerData.data.Site.Data.EmailLogs ? this.props.callHandlerData.data.Site.Data.EmailLogs.length : 0} color="error"><Icon>email</Icon></Badge></Tooltip></ListItemIcon>
                                            </ListItem>
                                        </List>
                                    </Drawer>
                                    <EmailLogDialog title={`Site #${this.state.Site} Email Log`} type="SITE" rec={this.props.callHandlerData.data.Site.ID} open={this.state.showEmailLogs} onClose={() => this.setState({ showEmailLogs: false })} />
                                    <div className={classes.content}>
                                        <div className={classes.histTable}>
                                            {histTab === 0 &&
                                                <TabContainer padding={"0px"}>
                                                    <WorkOrdersList hist={{ PageCount: Math.ceil(this.props.callHandlerData.data.Site.WorkOrders / 10), Results: [], Page: 1, PageSize: 10, TotalCount: this.props.callHandlerData.data.Site.WorkOrders }} customer={this.state.Customer} site={this.state.Site} selectedWOData={[...(this.props.callHandlerData.data.Site.Data.WorkOrders || [])]} />
                                                </TabContainer>
                                            }
                                            {histTab === 1 &&
                                                <TabContainer padding={'0px'}>
                                                    <WorkOrderScopesList hist={{ PageCount: Math.ceil(this.props.callHandlerData.data.Site.Scopes / 10), Results: [], Page: 1, PageSize: 10, TotalCount: this.props.callHandlerData.data.Site.Scopes }} customer={this.state.Customer} site={this.state.Site} selectedWOData={[...(this.props.callHandlerData.data.Site.Data.WorkOrderScopes || [])]} />
                                                </TabContainer>
                                            }
                                            {histTab === 2 &&
                                                <TabContainer padding={"0px"}>
                                                    <TripsList hist={{ PageCount: Math.ceil(this.props.callHandlerData.data.Site.Trips / 10), Results: [], Page: 1, PageSize: 10, TotalCount: this.props.callHandlerData.data.Site.Trips }} customer={this.state.Customer} site={this.state.Site} selectedTripData={[...(this.props.callHandlerData.data.Site.Data.Trips || [])]} />
                                                </TabContainer>
                                            }
                                            {histTab === 3 &&
                                                <TabContainer padding={"0px"}>
                                                    <InvoicesList hist={{ PageCount: Math.ceil(this.props.callHandlerData.data.Site.Invoices / 10), Results: [], Page: 1, PageSize: 10, TotalCount: this.props.callHandlerData.data.Site.Invoices }} customer={this.state.Customer} site={this.state.Site} invoiceData={[...(this.props.callHandlerData.data.Site.Data.Invoices || [])]} />
                                                </TabContainer>
                                            }
                                            {histTab === 4 &&
                                                <TabContainer padding={"0px"}>
                                                    <PaymentsList hist={{ PageCount: Math.ceil(this.props.callHandlerData.data.Site.Payments / 10), Results: [], Page: 1, PageSize: 10, TotalCount: this.props.callHandlerData.data.Site.Payments }} customer={this.state.Customer} site={this.state.Site} payments={[...(this.props.callHandlerData.data.Site.Data.Payments || [])]} />
                                                </TabContainer>
                                            }
                                            {histTab === 5 &&
                                                <TabContainer padding={"0px"}>
                                                    {/* <EstimateDialog /> */}
                                                    <EstimatesList hist={{ PageCount: Math.ceil(this.props.callHandlerData.data.Site.Estimates / 10), Results: [], Page: 1, PageSize: 10, TotalCount: this.props.callHandlerData.data.Site.Estimates }} customer={this.state.Customer} site={this.state.Site} estimateData={[...(this.props.callHandlerData.data.Site.Data.Estimates || [])]} />
                                                </TabContainer>
                                            }
                                        </div>
                                    </div>
                                    <IconButton className="pin-r absolute w-36 h-36 mt-4 mr-4" onClick={this.toggleHistory}>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Card>
                        </div>
                    }
                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">settings</Icon>
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Service"
                            options={services}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Service}>
                                    {`${value.Service} - ${value.Description}`}
                                </MenuItem>
                            }}
                            noflip={true}
                            value={this.state.Service || ''}
                            onSelect={(option) => {
                                const BusinessUnit = option.DefaultBusinessUnit;
                                const Division = option.DefaultDivision;
                                const { Customer, Site } = this.props.callHandlerData.data;
                                const siteRT = _.find(Site.Data.RateTemplates, { Co, BusinessUnit, Division });
                                const custRT = _.find(Customer.Data.RateTemplates, { Co, BusinessUnit, Division });
                                const division = _.find(divisions, { Co, BusinessUnit, Division });
                                const rt = siteRT ? siteRT.RateTemplate : custRT ? custRT.RateTemplate : division ? division.DefaultRateTemplate : this.state.RateTemplate;
                                this.setState({ ...this.state, Service: option.Service, BusinessUnit: option.DefaultBusinessUnit || this.state.BusinessUnit, Division: option.DefaultDivision || this.state.Division, Department: option.DefaultDepartment || this.state.Department, ServiceType: option.DefaultServiceType || this.state.ServiceType, RateTemplate: rt, Priority: (this.state.Priority || !isNaN(option.DefaultPriority) && option.DefaultPriority <= 3 && option.DefaultPriority >= 1 ? option.DefaultPriority : 3) }, () => this.props.setCallHandlerData(this.state))
                            }}
                            required
                            activeOnly={true}
                            filter={(this.state.BusinessUnit && this.state.Division && this.state.Department) && { DefaultBusinessUnit: this.state.BusinessUnit, DefaultDivision: this.state.Division, DefaultDepartment: this.state.Department }}
                            error={this.state.trySubmit && !this.state.Service}
                            disabled={disabled}
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Service Type"
                            options={serviceTypes}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.ServiceType}>
                                    {`${value.Description}`}
                                </MenuItem>
                            }}
                            noflip={true}
                            value={this.state.ServiceType || ''}
                            onSelect={(option) => this.setState({ ...this.state, ServiceType: option.ServiceType }, () => this.props.setCallHandlerData(this.state))}
                            required
                            activeOnly={true}
                            error={this.state.trySubmit && !this.state.ServiceType}
                            disabled={disabled}
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <DatePicker variant="inline" inputVariant="outlined"
                            className={classes.formControl}
                            label="Due By"
                            id="dueBy"
                            format="MM/DD/YYYY"
                            value={this.state.DueBy}
                            onChange={this.handleDueByChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><Icon>today</Icon></InputAdornment>,
                            }}
                            disabled={disabled}
                            autoOk
                            animateYearScrolling
                            required
                            error={this.state.trySubmit && !this.state.DueBy}
                            fullWidth
                        />
                    </div>

                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">business</Icon>
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Business Unit"
                            options={businessUnits}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.BusinessUnit}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.BusinessUnit || ''}
                            onSelect={(option) => this.setState({ ...this.state, BusinessUnit: option.BusinessUnit }, () => this.props.setCallHandlerData(this.state))}
                            required
                            activeOnly={true}
                            error={this.state.trySubmit && !this.state.BusinessUnit}
                            disabled={disabled}
                        />

                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Division"
                            options={divisions ? _.filter(divisions, { BusinessUnit: this.state.BusinessUnit }) : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Division}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Division || ''}
                            onSelect={(option) => {
                                const { Co, BusinessUnit, Division } = option;
                                const { Customer, Site } = this.props.callHandlerData.data;
                                const siteRT = _.find(Site.Data.RateTemplates, { Co, BusinessUnit, Division });
                                const custRT = _.find(Customer.Data.RateTemplates, { Co, BusinessUnit, Division });
                                const rt = siteRT ? siteRT.RateTemplate : custRT ? custRT.RateTemplate : option.DefaultRateTemplate;
                                this.setState({ ...this.state, Division: option.Division, RateTemplate: this.state.RateTemplate || rt }, () => this.props.setCallHandlerData(this.state))
                            }}
                            required
                            activeOnly={true}
                            error={this.state.trySubmit && !this.state.Division}
                            disabled={disabled}
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Department"
                            options={departments ? _.filter(departments, { BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }) : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Department}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Department || ''}
                            onSelect={(option) => this.setState({ ...this.state, Department: option.Department }, () => this.props.setCallHandlerData(this.state))}
                            required
                            activeOnly={true}
                            error={this.state.trySubmit && !this.state.Department}
                            disabled={disabled}
                        />
                    </div>
                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">chat_bubble_outline</Icon>
                        </div>
                        <TextField
                            className={classes.formControl}
                            label="Description"
                            id="description"
                            name="Description"
                            value={this.state.Description || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            variant="outlined"
                            multiline
                            required
                            error={this.state.trySubmit && !this.state.Description}
                            rows={5}
                            disabled={disabled}
                            fullWidth
                        />
                    </div>

                    <div className="w-full sm:flex mb-12" style={{ marginTop: -8 }}>
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">build</Icon>
                        </div>
                        <ServiceUnitsList
                            onAdd={(unit) => {
                                const { ServiceUnits } = _.cloneDeepWith(this.state.Data);
                                const index = _.findIndex(ServiceUnits, { UnitID: unit.UnitID });
                                if (index < 0) {
                                    ServiceUnits.push(unit);
                                    this.setState({ ...this.state, Data: { ...this.state.Data, ServiceUnits } }, () => this.props.setCallHandlerData({ ...this.state }))
                                }
                            }}
                            onRemove={(unit) => {
                                const { ServiceUnits } = _.cloneDeepWith(this.state.Data);
                                const index = _.findIndex(ServiceUnits, { UnitID: unit.UnitID });
                                if (index > -1) {
                                    ServiceUnits.splice(index, 1);
                                    this.setState({ ...this.state, Data: { ...this.state.Data, ServiceUnits } }, () => this.props.setCallHandlerData({ ...this.state }))
                                }
                            }}
                            site={this.props.callHandlerData.data.Site}
                            scope={this.state}
                            editable={true}
                            units={this.state.Data.ServiceUnits || []}
                        />
                    </div>

                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">settings</Icon>
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Lead Technician"
                            options={[{ Technician: null, FirstName: 'None', LastName: '' }, ...technicians]}
                            menuItemComponent={(value) => {
                                const techSched = _.filter(schedule, { Technician: value.Technician });
                                const duration = _.sumBy(techSched, "Duration");
                                return <MenuItem value={value.Technician}>
                                    <div className={classNames("w-full flex mr-64")}>
                                        {value.Technician && <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />}
                                        {`${value.FirstName} ${value.LastName}`}
                                        {techSched && techSched.length > 0 &&
                                            <Tooltip /*placement="right"*/ TransitionComponent={Fade} enterDelay={1000} leaveDelay={300} TransitionProps={{ timeout: 300 }} classes={{ root: "relative", popper: classes.popper, tooltipPlacementTop: "with-after", tooltipPlacementBottom: "with-before", tooltip: classes.tooltip }} interactive={true} title={(
                                                <div className="w-256 sm:w-512" onMouseDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
                                                    <Card className="mb-16" style={{ border: '1px solid lightgrey' }}>
                                                        <CardHeader className={classNames("p-12 relative", "highlight")}
                                                            classes={
                                                                { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classes.subheader, }
                                                            }
                                                            avatar={
                                                                <Avatar classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                                            }
                                                            action={
                                                                <div className="absolute w-96 pin-r mr-16 pin-t text-right mt-12">
                                                                    <Typography variant="caption" className="font-bold">{`${techSched.length} Trips`}</Typography>
                                                                    <Typography variant="caption" className="text-10 text-grey-darker">{`${(_.sumBy(techSched, (t) => { return t.Duration }) || 0).toFixed(2)} Hours`}</Typography>
                                                                </div>
                                                            }
                                                            title={
                                                                <Typography className="flex text-12 font-bold">
                                                                    {`${value.FirstName} ${value.LastName}`}
                                                                </Typography>
                                                            }
                                                            subheader={moment(this.state.DueBy).format('M/D/YYYY')}
                                                        >
                                                        </CardHeader>
                                                        {techSched.map((trip) => (
                                                            <div className="w-full p-8 pt-0 pb-4" style={{ borderTop: '1px solid lightgrey', backgroundColor: '#f8f8f8' }}>
                                                                <TechnicianTrip capacity={true} readOnly={true} key={trip.ID} workOrder={{ ...trip.Data.WorkOrder, value: 2 }} technician={{ ...value }} trip={trip} />
                                                            </div>
                                                        ))}
                                                    </Card>
                                                </div>
                                            )} placement="top">
                                                {/* <Avatar className={classNames(classes.scheduleBadge, "pin-r pin-t mt-12 absolute show-on-focus", duration < 4 ? classes.openBadge : duration < 8 ? classes.busyBadge : classes.capacityBadge)}>{Math.round(duration)}</Avatar> */}
                                                <Badge classes={{ root: "pin-r pin-t mt-8 mr-16 absolute show-on-focus", badge: classNames(classes.techBadge, duration < 4 ? classes.openBadge : duration < 8 ? classes.busyBadge : classes.capacityBadge) }} color="primary" badgeContent={Math.round(duration)}>
                                                    <Icon color="action" className="text-24 align-middle">today</Icon>
                                                </Badge>
                                            </Tooltip>
                                        }
                                    </div>
                                </MenuItem>
                            }}
                            activeOnly={true}
                            value={this.state.LeadTechnician || ''}
                            onSelect={(option) => this.setState({ ...this.state, LeadTechnician: option.Technician }, () => this.props.setCallHandlerData(this.state))}
                            // error={this.state.trySubmit && !this.state.LeadTechnician}
                            disabled={disabled}
                        // required
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        {this.state.ExactTime ?
                            <TimePicker
                                className={classes.formControl}
                                id="exactTime"
                                label="Arrival Time"
                                format="h:mm A"
                                value={exactTime || ''}
                                onChange={this.handleExactTime}
                                required
                                disabled={disabled}
                                clearable={true}
                                inputVariant="outlined"
                                fullWidth
                            />
                            :
                            <Autocomplete
                                className={classes.formControl}
                                title="Arrival Window"
                                options={arrivalWindows ? _.filter(arrivalWindows, { BusinessUnit: this.state.BusinessUnit }).concat({ ArrivalWindow: "ExactTime", Description: "Exact Time", BusinessUnit: this.state.BusinessUnit }) : []}
                                menuItemComponent={(value) => {
                                    return <MenuItem value={value.ArrivalWindow}>
                                        {`${value.Description}`}
                                    </MenuItem>
                                }}
                                noflip={true}
                                value={this.state.ArrivalWindow || ''}
                                //onSelect={(option) => this.setState({ ...this.state, ArrivalWindow: option.ArrivalWindow }, () => this.props.setCallHandlerData(this.state))}
                                onSelect={(option) => this.handleArrivalWindow(option)}
                                required
                                activeOnly={true}
                                error={this.state.trySubmit && !this.state.ArrivalWindow}
                                disabled={disabled}
                            />
                        }
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Form of Payment"
                            options={formOfPayments ? _.filter(formOfPayments, { BusinessUnit: this.state.BusinessUnit }) : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.FormOfPayment}>
                                    {`${value.Description}`}
                                </MenuItem>
                            }}
                            noflip={true}
                            value={this.state.FormOfPayment || ''}
                            onSelect={(option) => this.setState({ ...this.state, FormOfPayment: option.FormOfPayment }, () => this.props.setCallHandlerData(this.state))}
                            required
                            activeOnly={true}
                            error={this.state.trySubmit && !this.state.FormOfPayment}
                            disabled={disabled}
                        />
                    </div>

                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">person_pin_circle</Icon>
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Site Contact"
                            options={[...contacts, { Contact: null }]}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Data ? value.Data.Contact.Contact : null}>
                                    {value.Data ? `${value.Data.Contact.Name} - ${value.Data.Contact.Phone ? this.formatPhone(value.Data.Contact.Phone) : value.Data.Contact.Email}` : <React.Fragment><Icon className="mr-8 align-middle">add</Icon>New Contact</React.Fragment>}
                                </MenuItem>
                            }}
                            noflip={true}
                            value={this.state.SiteContact || ''}
                            onSelect={(option) => { if (option.Contact) { this.setState({ ...this.state, SiteContact: option.Contact }, () => this.props.setCallHandlerData(this.state)) } else { this.setState({ addContact: true }) } }}
                            activeOnly={true}
                            // error={this.state.trySubmit && !this.state.SiteContact}
                            disabled={disabled}
                            required
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <Autocomplete
                            className={classes.formControl}
                            title="Requested By"
                            options={[...contacts, { Contact: null }]}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Data ? value.Data.Contact.Contact : null}>
                                    {value.Data ? `${value.Data.Contact.Name} - ${value.Data.Contact.Phone ? this.formatPhone(value.Data.Contact.Phone) : value.Data.Contact.Email}` : <React.Fragment><Icon className="mr-8 align-middle">add</Icon>New Contact</React.Fragment>}
                                </MenuItem>
                            }}
                            noflip={true}
                            value={this.state.RequestedByContact || ''}
                            onSelect={(option) => { if (option.Contact) { this.setState({ ...this.state, RequestedByContact: option.Contact }, () => this.props.setCallHandlerData(this.state)) } else { this.setState({ addContact: true }) } }}
                            activeOnly={true}
                            // error={(this.state.trySubmit && !this.state.SiteContact) || (reqBy && reqBy.RequiresApprovalYN === 'Y')}
                            helperText={reqBy && reqBy.RequiresApprovalYN === 'Y' ? "Approval Required" : undefined}
                            disabled={disabled}
                        />
                    </div>
                    {addContact &&
                        <div className="w-full sm:flex mb-12" style={{ marginTop: -8 }}>
                            <div className="hidden sm:block min-w-48 pt-20">
                                <Icon color="action">contacts</Icon>
                            </div>
                            <ContactList
                                label="Site Contacts"
                                contact={{
                                    ID: null,
                                    Name: null,
                                    Phone: null,
                                    Email: null,
                                    Data: {
                                        ErrMsg: null
                                    }
                                }}
                                onUpdate={(Contacts) => this.setState({ addContact: false }, () => this.props.setCallHandlerSiteData({ ...this.props.callHandlerData.data.Site, Data: { ...this.props.callHandlerData.data.Site.Data, Contacts } }))}
                                onCancel={() => this.setState({ addContact: false })}
                                editable={true} site={{ ...this.state }}
                                details={contacts || []}
                            />
                        </div>
                    }

                    {this.state.advanced === "Y" &&
                        <React.Fragment>

                            <div className="w-full sm:flex">
                                <div className="hidden sm:block min-w-48 pt-20">
                                    <Icon color="action">payment</Icon>
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Estimate #"
                                    options={[{ Estimate: null, EstimateNumber: 'None' }, ...openQuotes]}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.Estimate}>
                                            {value.icon ?
                                                <React.Fragment><Icon className="tex-24 mr-6" color="primary">{value.icon}</Icon>{value.EstimateNumber}</React.Fragment>
                                                :
                                                value.Description ?
                                                    <div className="w-256 truncate">{`${value.EstimateNumber} - ${value.Greeting || value.Description}`}</div>
                                                    :
                                                    value.EstimateNumber
                                            }
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.Estimate || ''}
                                    onSelect={(option) => {
                                        this.setState({ ...this.state, Estimate: option.Estimate, PriceMethod: option.Estimate ? 'F' : 'T', Price: option.PriceTotal ? option.PriceTotal.toFixed(2) : null });
                                    }}
                                    disabled={disabled}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Price Method"
                                    id="priceMethod"
                                    name="PriceMethod"
                                    value={this.state.PriceMethod || ''}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    variant="outlined"
                                    required
                                    select
                                    fullWidth
                                    disabled={!disabled ? this.state.NonBillableYN === 'N' ? false : true : disabled}
                                    error={this.state.trySubmit && !this.state.PriceMethod}
                                >
                                    <MenuItem value="T" disabled={woOpt && woOpt.DisableTMBillingYN === "Y"}>
                                        Rate Template
                                    </MenuItem>
                                    <MenuItem value="F">
                                        Flat Price
                                    </MenuItem>
                                </TextField>
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                {
                                    (this.state.PriceMethod === 'F' ?
                                        <TextField
                                            className={classes.formControl}
                                            label="Price"
                                            id="price"
                                            name="Price"
                                            value={this.state.Price || this.state.Price === 0.00 ? this.state.Price : ''}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={disabled}
                                            error={this.state.trySubmit && (!this.state.Price && this.state.Price !== 0.00)}
                                        /> :
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Rate Template"
                                            options={rateTemplates ? _.filter(rateTemplates, { BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }) : []}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.RateTemplate}>
                                                    {`${value.Description}`}
                                                </MenuItem>
                                            }}
                                            noflip={true}
                                            value={this.state.RateTemplate || ''}
                                            onSelect={(option) => this.setState({ ...this.state, RateTemplate: option.RateTemplate }, () => this.props.setCallHandlerData(this.state))}
                                            required
                                            activeOnly={true}
                                            error={this.state.trySubmit && !this.state.RateTemplate}
                                            disabled={disabled}
                                        />
                                    )
                                }
                            </div>


                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">receipt</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Customer PO"
                                    id="customerPO"
                                    name="CustomerPO"
                                    value={this.state.CustomerPO}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: Boolean(this.state.CustomerPO) && <InputAdornment position="start">#</InputAdornment>
                                    }}
                                    required={this.props.callHandlerData.data.Customer.PORequiredYN === 'Y'}
                                    error={this.props.callHandlerData.data.Customer.PORequiredYN === 'Y' && this.state.trySubmit && !this.state.CustomerPO}
                                    disabled={disabled}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Not To Exceed"
                                    id="notToExceed"
                                    name="NotToExceed"
                                    value={this.state.NotToExceed}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: Boolean(this.state.NotToExceed) && <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    type="number"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </div>


                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">format_list_numbered_rtl</Icon>
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Tax Code"
                                    options={taxCodes}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.TaxCode}>
                                                {`${value.Description} - ${value.TaxCode}`}
                                            </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.TaxCode}
                                    onSelect={(option) => this.setState({ ...this.state, TaxCode: option.TaxCode })}
                                    required
                                    disabled={disabled}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Taxable?"
                                    id="taxableYN"
                                    name="TaxableYN"
                                    value={this.state.TaxableYN}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    select
                                    disabled={disabled}
                                >
                                    <MenuItem value={"N"}>
                                        No
                                    </MenuItem>
                                    <MenuItem value={"Y"}>
                                        Yes
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">person</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Warranty Work"
                                    id="isWarrantyYN"
                                    name="IsWarrantyYN"
                                    value={this.state.IsWarrantyYN || "N"}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    disabled={disabled}
                                >
                                    <MenuItem value="N">
                                        No
                                    </MenuItem>
                                    <MenuItem value="Y">
                                        Yes
                                    </MenuItem>
                                </TextField>
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                {this.state.IsWarrantyYN === "Y" &&
                                    <React.Fragment>
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Warranty Technician"
                                            options={technicians}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.Technician}>
                                                    <div className="flex">
                                                        <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                                        {`${value.FirstName} ${value.LastName}`}
                                                    </div>
                                                </MenuItem>
                                            }}
                                            value={this.state.WarrantyTechnician}
                                            onSelect={(option) => this.setState({ ...this.state, WarrantyTechnician: option.Technician })}
                                            disabled={disabled}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                    </React.Fragment>
                                }
                                <TextField
                                    className={classes.formControl}
                                    label="Priority"
                                    id="priority"
                                    name="Priority"
                                    value={this.state.Priority || ''}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={disabled}
                                    select
                                >
                                    <MenuItem value={3}>
                                        Low
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Medium
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        High
                                    </MenuItem>
                                </TextField>
                            </div>
                        </React.Fragment>
                    }

                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">note</Icon>
                        </div>
                        <TextField
                            className={classes.formControl}
                            label="Notes"
                            id="notes"
                            name="Notes"
                            value={this.state.Notes || ''}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            variant="outlined"
                            multiline
                            rows={5}
                            disabled={disabled}
                            fullWidth
                        />
                    </div>

                    <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">assistant</Icon>
                        </div>
                        <ChipInput  blurBehavior="add"
                            className={classes.formControl}
                            variant="outlined"
                            label="Tags"
                            id="tags"
                            name="Tags"
                            blurBehavior="add"
                            value={this.state.Data.Tags}
                            onAdd={this.handleAddTag}
                            onDelete={this.handleDeleteTag}
                            chipRenderer={({ value, handleDelete }, key) =>
                                value.ActiveYN === 'Y' && (
                                    disabled ? (
                                        <Tooltip title={`Added By ${value.AddedBy} on ${new Date(value.AddedDate).toLocaleDateString('en-US')}`} placement="top">
                                            <Chip className="mb-8 mr-4" color="primary" icon={<Icon>assignment</Icon>} label={value.Title} />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={`Added By ${value.AddedBy} on ${new Date(value.AddedDate).toLocaleDateString('en-US')}`} placement="top">
                                            <Chip className="mb-8 mr-4" color="primary" icon={<Icon>assignment</Icon>} onDelete={handleDelete} label={value.Title} />
                                        </Tooltip>
                                    )
                                )
                            }
                            fullWidth
                            fullWidthInput
                            disabled={disabled}
                        />
                    </div>
                    <DialogActions
                        onMouseEnter={this.trySubmit}
                        // onMouseLeave={this.endTrySubmit}
                        className="justify-between m-0">
                        <FormControlLabel
                            className="sm:ml-24"
                            // style={{ float: 'right', }}
                            control={
                                <Switch
                                    checked={this.state.advanced === 'Y'}
                                    name="advanced"
                                    onChange={this.handleChange}
                                    color="primary"
                                />
                            }
                            label="Advanced"
                        />
                        {(accessLevel && accessLevel.AccessLevel !== "R") && Customer && Site && !WorkOrder &&
                            <DebounceButton
                                buttonText={"Create Work Order"}
                                processingText={"Creating Work Order"}
                                isDisabled={!this.canBeSubmitted()}
                                variant={"contained"}
                                color={"primary"}
                                clickFxn={this.submitWorkOrder}
                                debounceInterval={2000}
                            />
                        }
                    </DialogActions>
                </DialogContent>
            </FuseAnimate >
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        setCallHandlerData: Actions.setWorkOrderData,
        setCallHandlerSiteData: Actions.setSiteData,
        addWorkOrder: Actions.addWorkOrder,
        openWorkOrder: openEditWorkOrderDialog,
        getScheduledTrips: Actions.getScheduledTrips,
        getScheduledWOs: Actions.getScheduledWOs,
        setBoardDate: DispatchBoardActions.setBoardDate,
        getWOData: DispatchBoardActions.getWOData,
        getTripData: DispatchBoardActions.getTripData,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, spReducers, fuse }) {
    return {
        googleMapsApiKey: googleMapsApiKey,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        rateTemplates: spReducers.rateTemplates,
        arrivalWindows: spReducers.arrivalWindows,
        taxCodes: spReducers.taxCodes,
        formOfPayments: spReducers.formOfPayments,
        departments: spReducers.departments,
        technicians: spReducers.technicians,
        user: callHandlerApp.user,
        callHandlerData: callHandlerApp.callHandler,
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersTab));
