import axios from 'axios';
import { getUserData } from 'main/content/apps/catalog/store/actions/user.actions';
import { showMessage, SET_ASSEMBLY_PROGRESS, SET_CATALOG_DATA, SET_SAVED_CATALOGS, } from 'store/actions';
import { UPDATE_EDIT_ESTIMATE_DIALOG } from 'main/content/apps/estimates/store/actions';
import _ from '@lodash';

export const GET_ASSEMBLIES = '[CATALOG APP] GET ASSEMBLIES';
export const GET_ALL_OPTION_BOARDS = '[CATALOG APP] GET ALL OPTION_BOARDS';
export const SET_SEARCH_TEXT = '[CATALOG APP] SET SEARCH TEXT';
export const ADD_ASSEMBLY = '[CATALOG APP] ADD ASSEMBLY';
export const UPDATE_ASSEMBLY = '[CATALOG APP] UPDATE ASSEMBLY';
export const REMOVE_ASSEMBLY = '[CATALOG APP] REMOVE ASSEMBLY';
export const ADD_ASSEMBLY_CATEGORY = '[CATALOG APP] ADD ASSEMBLY CATEGORY';
export const UPDATE_ASSEMBLY_CATEGORY = '[CATALOG APP] UPDATE  CATEGORY';
export const REMOVE_ASSEMBLY_CATEGORY = '[CATALOG APP] REMOVE ASSEMBLY CATEGORY';
export const ADD_CATALOG_ASSEMBLY = '[CATALOG APP] ADD CATALOG ASSEMBLY';
export const UPDATE_CATALOG_ASSEMBLY = '[CATALOG APP] CATALOG ASSEMBLY';
export const REMOVE_CATALOG_ASSEMBLY = '[CATALOG APP] REMOVE CATALOG ASSEMBLY';
export const SET_ASSEMBLY = '[CATALOG APP] SET ASSEMBLY';
export const SET_ASSEMBLY_CATEGORY = '[CATALOG APP] SET ASSEMBLY CATEGORY';
export const SET_ASSEMBLIES = '[CATALOG APP] SET ASSEMBLIES';
export const SET_ASSEMBLY_CATEGORIES = '[CATALOG APP] SET ASSEMBLY CATEGORIES';
export const SET_ASSEMBLY_STEP = '[CATALOG APP] SET ASSEMBLY STEP';
export const OPEN_NEW_CATALOG_ITEM_DIALOG = '[CATALOG APP] OPEN NEW CATALOG ITEM DIALOG';
export const CLOSE_NEW_CATALOG_ITEM_DIALOG = '[CATALOG APP] CLOSE NEW CATALOG ITEM DIALOG';
export const OPEN_EDIT_CATALOG_ITEM_DIALOG = '[CATALOG APP] OPEN EDIT CATALOG ITEM DIALOG';
export const UPDATE_EDIT_CATALOG_ITEM_DIALOG = '[CATALOG APP] UPDATE EDIT CATALOG ITEM DIALOG';
export const CLOSE_EDIT_CATALOG_ITEM_DIALOG = '[CATALOG APP] CLOSE EDIT CATALOG ITEM DIALOG';
export const OPEN_NEW_CATALOG_ASSEMBLY_DIALOG = '[CATALOG APP] OPEN NEW CATALOG ASSEMBLY DIALOG';
export const CLOSE_NEW_CATALOG_ASSEMBLY_DIALOG = '[CATALOG APP] CLOSE NEW CATALOG ASSEMBLY DIALOG';
export const OPEN_EDIT_CATALOG_ASSEMBLY_DIALOG = '[CATALOG APP] OPEN EDIT CATALOG ASSEMBLY DIALOG';
export const UPDATE_EDIT_CATALOG_ASSEMBLY_DIALOG = '[CATALOG APP] UPDATE EDIT CATALOG ASSEMBLY DIALOG';
export const CLOSE_EDIT_CATALOG_ASSEMBLY_DIALOG = '[CATALOG APP] CLOSE EDIT CATALOG ASSEMBLY DIALOG';
export const OPEN_NEW_CATALOG_ASSEMBLY_COMPONENT_DIALOG = '[CATALOG APP] OPEN NEW CATALOG ASSEMBLY COMPONENT DIALOG';
export const CLOSE_NEW_CATALOG_ASSEMBLY_COMPONENT_DIALOG = '[CATALOG APP] CLOSE NEW CATALOG ASSEMBLY COMPONENT DIALOG';
export const OPEN_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG = '[CATALOG APP] OPEN EDIT CATALOG ASSEMBLY COMPONENT DIALOG';
export const UPDATE_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG = '[CATALOG APP] UPDATE EDIT CATALOG ASSEMBLY COMPONENT DIALOG';
export const CLOSE_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG = '[CATALOG APP] CLOSE EDIT CATALOG ASSEMBLY COMPONENT DIALOG';
export const OPEN_NEW_CATALOG_DIALOG = '[CATALOG APP] OPEN NEW CATALOG DIALOG';
export const CLOSE_NEW_CATALOG_DIALOG = '[CATALOG APP] CLOSE NEW CATALOG DIALOG';
export const OPEN_EDIT_CATALOG_DIALOG = '[CATALOG APP] OPEN EDIT CATALOG DIALOG';
export const UPDATE_EDIT_CATALOG_DIALOG = '[CATALOG APP] UPDATE EDIT CATALOG DIALOG';
export const CLOSE_EDIT_CATALOG_DIALOG = '[CATALOG APP] CLOSE EDIT CATALOG DIALOG';

export const ADD_CATALOG_OPTION_BOARD = '[CATALOG APP] ADD CATALOG OPTION BOARD';
export const UPDATE_CATALOG_OPTION_BOARD = '[CATALOG APP] CATALOG OPTION BOARD';
export const REMOVE_CATALOG_OPTION_BOARD = '[CATALOG APP] REMOVE CATALOG OPTION BOARD';
export const SET_OPTION_BOARD = '[CATALOG APP] SET OPTION BOARD';
export const SET_OPTION_BOARD_CATEGORY = '[CATALOG APP] SET OPTION BOARD CATEGORY';
export const SET_OPTION_BOARDS = '[CATALOG APP] SET OPTION BOARDS';
export const SET_OPTION_BOARD_CATEGORIES = '[CATALOG APP] SET OPTION BOARD CATEGORIES';
export const ADD_OPTION_BOARD_CATEGORY = '[CATALOG APP] ADD OPTION BOARD CATEGORY';
export const UPDATE_OPTION_BOARD_CATEGORY = '[CATALOG APP] UPDATE OPTION BOARD CATEGORY';
export const REMOVE_OPTION_BOARD_CATEGORY = '[CATALOG APP] REMOVE OPTION BOARD CATEGORY';
export const ADD_OPTION_BOARD = '[CATALOG APP] ADD OPTION BOARD';
export const UPDATE_OPTION_BOARD = '[CATALOG APP] UPDATE OPTION BOARD';
export const REMOVE_OPTION_BOARD = '[CATALOG APP] REMOVE OPTION BOARD';
export const OPEN_NEW_OPTION_BOARD_DIALOG = '[CATALOG APP] OPEN NEW OPTION BOARD DIALOG';
export const CLOSE_NEW_OPTION_BOARD_DIALOG = '[CATALOG APP] CLOSE NEW OPTION BOARD DIALOG';
export const OPEN_EDIT_OPTION_BOARD_DIALOG = '[CATALOG APP] OPEN EDIT OPTION BOARD DIALOG';
export const UPDATE_EDIT_OPTION_BOARD_DIALOG = '[CATALOG APP] UPDATE EDIT OPTION BOARD DIALOG';
export const CLOSE_EDIT_OPTION_BOARD_DIALOG = '[CATALOG APP] CLOSE EDIT OPTION BOARD DIALOG';
export const SET_RATE_UPDATES_LIST = '[CATALOG APP] SET RATE UPDATES LIST';
export const SET_SELECTED_ASSEMBLIES = '[CATALOG APP] SET SELECTED ASSEMBLIES';
export const SET_ASSEMBLY_SEARCH_LOADING = '[CATALOG APP] SET ASSEMBLY SEARCH LOADING';
export const SET_ASSEMBLIES_UPDATING = '[CATALOG APP] SET ASSEMBLIES UPDATING';
export const UPDATE_ASSEMBLY_PRICING = '[CATALOG APP] UPDATE ASSEMBLY PRICING';
export const UPDATE_SELECTED_ASSEMBLIES_PRICING = '[CATALOG APP] UPDATE SELECTED ASSEMBLIES PRICING';

export function getAssemblies(routeParams) {
    const request = axios.get('/api/catalog-app/catalog', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_ASSEMBLIES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function openNewCatalogItemDialog(data) {
    return {
        type: OPEN_NEW_CATALOG_ITEM_DIALOG,
        data
    }
}

export function openEditCatalogItemDialog(data, estimate, option) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Assembly?Co=${data.Co}&BusinessUnit=${encodeURIComponent(data.BusinessUnit)}&Division=${encodeURIComponent(data.Division)}&Assembly=${data.Assembly}`);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_CATALOG_ITEM_DIALOG,
                    data: { ...response.data, estimate, option }
                }),
            ]);
        }).catch((e) => {
            dispatch({
                type: OPEN_EDIT_CATALOG_ITEM_DIALOG,
                data: { ...data, estimate, option }
            });
        });
    }
}

export function closeNewCatalogItemDialog() {
    return {
        type: CLOSE_NEW_CATALOG_ITEM_DIALOG
    }
}

export function closeEditCatalogItemDialog() {
    return {
        type: CLOSE_EDIT_CATALOG_ITEM_DIALOG
    }
}

export function openNewCatalogAssemblyDialog(data) {
    return {
        type: OPEN_NEW_CATALOG_ASSEMBLY_DIALOG,
        data
    }
}

export function openEditCatalogAssemblyDialog(data, estimate) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Assembly?Co=${data.Co}&BusinessUnit=${encodeURIComponent(data.BusinessUnit)}&Division=${encodeURIComponent(data.Division)}&Assembly=${data.Assembly}`);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_CATALOG_ASSEMBLY_DIALOG,
                    data: response.data
                }),
            ]);
        }).catch((e) => {
            dispatch({
                type: OPEN_EDIT_CATALOG_ASSEMBLY_DIALOG,
                data
            });
        });
    }
}

export function closeNewCatalogAssemblyDialog() {
    return {
        type: CLOSE_NEW_CATALOG_ASSEMBLY_DIALOG
    }
}

export function closeEditCatalogAssemblyDialog() {
    return {
        type: CLOSE_EDIT_CATALOG_ASSEMBLY_DIALOG
    }
}

export function openNewCatalogAssemblyComponentDialog(data) {
    return {
        type: OPEN_NEW_CATALOG_ASSEMBLY_COMPONENT_DIALOG,
        data
    }
}

export function openEditCatalogAssemblyComponentDialog(data, estimate) {
    return {
        type: OPEN_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG,
        data
    }
}

export function closeNewCatalogAssemblyComponentDialog() {
    return {
        type: CLOSE_NEW_CATALOG_ASSEMBLY_COMPONENT_DIALOG
    }
}

export function closeEditCatalogAssemblyComponentDialog() {
    return {
        type: CLOSE_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG
    }
}

export function openNewCatalogDialog() {
    return {
        type: OPEN_NEW_CATALOG_DIALOG
    }
}

export function downloadCatalog(data, full) {
    window["warn"]('Fetch Catalog data: ', data);
    if (data) {
        const { Co, BusinessUnit, Division } = data;
        return (dispatch, getState) => {
            const { savedCatalogs } = getState().spReducers.catalog;
            const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
            const LastUpdate = full || !catalog ? '1/1/1900' : catalog ? catalog.LastUpdate : '1/1/1900';
            if (full) {
                dispatch(showMessage({
                    message: `Downloading ${data.Name}`,
                    autoHideDuration: 60000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'refresh'
                }));
            }


            const request = axios.get(`${window["apiLocation"]}/api/Catalog?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&LastUpdate=${encodeURIComponent(LastUpdate)}`);

            return request.then((response) => {
                if (full) {
                    dispatch(showMessage({
                        message: `${data.Name} Downloaded Successfully`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                }
                if (catalog && !full) {
                    if (!catalog.Data.Assemblies) {
                        catalog.Data.Assemblies = [];
                    }
                    if (!catalog.Data.Categories) {
                        catalog.Data.Categories = [];
                    }
                    if (!catalog.Data.OptionBoards) {
                        catalog.Data.OptionBoards = [];
                    }
                    // if (!catalog.Data.OptionBoardCategories) {
                    //     catalog.Data.OptionBoardCategories = [];
                    // }
                    response.data.Data.Categories.map((value) => {
                        const { BusinessUnit, Division, Category } = value;
                        const index = _.findIndex(catalog.Data.Categories, { Co, BusinessUnit, Division, Category });
                        if (index < 0) {
                            catalog.Data.Categories.push(value);
                        } else {
                            catalog.Data.Categories.splice(index, 1, value);
                        }
                    });
                    response.data.Data.Assemblies.map((value) => {
                        const { BusinessUnit, Division, Assembly } = value;
                        const index = _.findIndex(catalog.Data.Assemblies, { Co, BusinessUnit, Division, Assembly });
                        if (index < 0) {
                            catalog.Data.Assemblies.push(value);
                        } else {
                            catalog.Data.Assemblies.splice(index, 1, value);
                        }
                    });
                    // response.data.Data.OptionBoardCategories.map((value) => {
                    //     const { BusinessUnit, Division, Category } = value;
                    //     const index = _.findIndex(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, Category });
                    //     if (index < 0) {
                    //         catalog.Data.OptionBoardCategories.push(value);
                    //     } else {
                    //         catalog.Data.OptionBoardCategories.splice(index, 1, value);
                    //     }
                    // });
                    if (response.data.Data.OptionBoards) {
                        response.data.Data.OptionBoards.map((value) => {
                            const { BusinessUnit, Division, OptionBoard } = value;
                            const index = _.findIndex(catalog.Data.OptionBoards, { Co, BusinessUnit, Division, OptionBoard });
                            if (index < 0) {
                                catalog.Data.OptionBoards.push(value);
                            } else {
                                catalog.Data.OptionBoards.splice(index, 1, value);
                            }
                        });
                    }
                    response.data.Data.Assemblies = catalog.Data.Assemblies;
                    response.data.Data.Categories = catalog.Data.Categories;
                    response.data.Data.OptionBoards = catalog.Data.OptionBoards;
                    // response.data.Data.OptionBoardCategories = catalog.Data.OptionBoardCategories;
                }
                const saved = _.cloneDeep(savedCatalogs);
                const index = _.findIndex(saved, { Co, BusinessUnit, Division });
                if (index > -1) {
                    saved.splice(index, 1, response.data);
                } else {
                    saved.push(response.data);
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: saved
                    })
                ])
            })
        }
    }
}

export function openEditCatalogDialog(data) {
    if (data) {
        const { Co, BusinessUnit, Division } = data;
        return (dispatch, getState) => {
            const { savedCatalogs } = getState().spReducers.catalog;
            const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
            const LastUpdate = catalog ? catalog.LastUpdate : '1/1/1900';


            const request = axios.get(`${window["apiLocation"]}/api/Catalog?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&LastUpdate=${encodeURIComponent(LastUpdate)}`);

            return request.then((response) => {
                if (catalog) {
                    if (!catalog.Data.Assemblies) {
                        catalog.Data.Assemblies = [];
                    }
                    if (!catalog.Data.Categories) {
                        catalog.Data.Categories = [];
                    }
                    if (!catalog.Data.OptionBoards) {
                        catalog.Data.OptionBoards = [];
                    }
                    // if (!catalog.Data.OptionBoardCategories) {
                    //     catalog.Data.OptionBoardCategories = [];
                    // }
                    response.data.Data.Categories.map((value) => {
                        const { BusinessUnit, Division, Category } = value;
                        const index = _.findIndex(catalog.Data.Categories, { Co, BusinessUnit, Division, Category });
                        if (index < 0) {
                            catalog.Data.Categories.push(value);
                        } else {
                            catalog.Data.Categories.splice(index, 1, value);
                        }
                    });
                    response.data.Data.Assemblies.map((value) => {
                        const { BusinessUnit, Division, Assembly } = value;
                        const index = _.findIndex(catalog.Data.Assemblies, { Co, BusinessUnit, Division, Assembly });
                        if (index < 0) {
                            catalog.Data.Assemblies.push(value);
                        } else {
                            catalog.Data.Assemblies.splice(index, 1, value);
                        }
                    });
                    // response.data.Data.OptionBoardCategories.map((value) => {
                    //     const { BusinessUnit, Division, Category } = value;
                    //     const index = _.findIndex(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, Category });
                    //     if (index < 0) {
                    //         catalog.Data.OptionBoardCategories.push(value);
                    //     } else {
                    //         catalog.Data.OptionBoardCategories.splice(index, 1, value);
                    //     }
                    // });
                    if (response.data.Data.OptionBoards) {
                        response.data.Data.OptionBoards.map((value) => {
                            const { BusinessUnit, Division, OptionBoard } = value;
                            const index = _.findIndex(catalog.Data.OptionBoards, { Co, BusinessUnit, Division, OptionBoard });
                            if (index < 0) {
                                catalog.Data.OptionBoards.push(value);
                            } else {
                                catalog.Data.OptionBoards.splice(index, 1, value);
                            }
                        });
                    }
                    response.data.Data.Assemblies = catalog.Data.Assemblies;
                    response.data.Data.Categories = catalog.Data.Categories;
                    response.data.Data.OptionBoards = catalog.Data.OptionBoards;
                    // response.data.Data.OptionBoardCategories = catalog.Data.OptionBoardCategories;
                }
                const saved = _.cloneDeep(savedCatalogs);
                const index = _.findIndex(saved, { Co, BusinessUnit, Division });
                if (index > -1) {
                    saved.splice(index, 1, response.data);
                } else {
                    saved.push(response.data);
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: saved
                    })
                ]).then(() => {
                    setTimeout(() => dispatch({
                        type: OPEN_EDIT_CATALOG_DIALOG,
                        data: response.data,
                    }), 750);
                })
            }).catch((e) => {
                window["warn"]('Error fetching catalog', e, data)
                dispatch({
                    type: OPEN_EDIT_CATALOG_DIALOG,
                    data,
                })
            })
        }
    } else {
        return {
            type: OPEN_EDIT_CATALOG_DIALOG,
            data,
        };
    }
}

export function closeNewCatalogDialog() {
    return {
        type: CLOSE_NEW_CATALOG_DIALOG
    }
}

export function closeEditCatalogDialog() {
    return {
        type: CLOSE_EDIT_CATALOG_DIALOG
    }
}

export function addCatalogAssembly() {
    return {
        type: ADD_CATALOG_ASSEMBLY,
    }
}

export function updateCatalogAssembly() {
    return {
        type: UPDATE_CATALOG_ASSEMBLY,
    }
}

export function removeCatalogAssembly() {
    return {
        type: REMOVE_CATALOG_ASSEMBLY,
    }
}

export function getAssembly(selectedAssembly) {
    if (selectedAssembly) {
        return (dispatch, getState) => {

            const request = axios.get(`${window["apiLocation"]}/api/Assembly?Co=${selectedAssembly.Co}&BusinessUnit=${encodeURIComponent(selectedAssembly.BusinessUnit)}&Division=${encodeURIComponent(selectedAssembly.Division)}&Assembly=${selectedAssembly.Assembly}`);

            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: SET_ASSEMBLY,
                        assembly: response.data,
                    }),
                ]))
        }
    } else {
        return {
            type: SET_ASSEMBLY,
            assembly: null,
        };
    }
}

export function setCategory(category) {
    return {
        type: SET_ASSEMBLY_CATEGORY,
        category,
    }
}

export function setCategories(categories) {
    return {
        type: SET_ASSEMBLY_CATEGORIES,
        categories,
    }
}

export function setAssembly(selectedAssembly) {
    return {
        type: SET_ASSEMBLY,
        assembly: selectedAssembly,
    }
}

export function setAssemblies(assemblies) {
    return {
        type: SET_ASSEMBLIES,
        assemblies,
    }
}

export function addAssembly(newAssembly, openNew) {
    return (dispatch, getState) => {

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.post(`${window["apiLocation"]}/api/Assembly`,
            newAssembly
        );

        //window["warn"]("AddAssembly res state", newAssembly, ComponentsList)

        return request.then((response) => {
            window["warn"]("AddAssembly res", response)
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const { Co, BusinessUnit, Division, Assembly, Data } = response.data;
                const { Assignments } = Data;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    catalog.Data.Assemblies.push(response.data);
                    Assignments.map((value) => {
                        const { Category } = value;
                        const category = _.find(catalog.Data.Categories, { Co, BusinessUnit, Division, Category });
                        if (category) {
                            category.Data.Assemblies.push(value);
                        }
                        catalog.Data.Assignments.push(value);
                    })
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        data: catalog
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                        data: response.data
                    }),
                    //addCopiedAssemblyComponents(ComponentsList, response.data.Assembly),
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Assembly #${response.data.Assembly} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                    if (openNew) {
                        Promise.all([
                            dispatch(closeEditCatalogItemDialog())
                        ]).then(() => {
                            dispatch(openEditCatalogItemDialog(response.data));
                        })
                    }
                });
            }
        })
    };
}

export function updateAssembly(selectedAssembly) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, Assembly } = selectedAssembly;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.put(`${window["apiLocation"]}/api/Assembly?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Assembly=${encodeURIComponent(Assembly)}`,
            selectedAssembly
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    const index = _.findIndex(catalog.Data.Assemblies, { Co, BusinessUnit, Division, Assembly });
                    if (index > -1) {
                        catalog.Data.Assemblies.splice(index, 1, response.data);
                    }
                }
                Promise.all([
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                        payload: response.data
                    }),
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    }),
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Assembly #${response.data.Assembly} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                });
            }
        })
    };
}

export function removeAssembly(selectedAssembly) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, Assembly } = selectedAssembly;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.delete(`${window["apiLocation"]}/api/Assembly?Co=${encodeURIComponent(selectedAssembly.Co)}&Assembly=${encodeURIComponent(selectedAssembly.Assembly)}`,
            selectedAssembly
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    const index = _.findIndex(catalog.Data.Categories, { Co, BusinessUnit, Division, Assembly });
                    if (index > -1) {
                        catalog.Data.Assemblies.splice(index, 1);
                    }
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Assembly #${selectedAssembly.Assembly} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                });
            }
        })
    };
}

export function addAssemblyCategory(newAssemblyCategory) {
    return (dispatch, getState) => {

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.post(`${window["apiLocation"]}/api/AssemblyCategory`,
            newAssemblyCategory
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const { Co, BusinessUnit, Division } = response.data;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    catalog.Data.Categories.push(response.data);
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                });
            }
        });
    };
}

export function updateAssemblyCategory(category) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, Category } = category;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.put(`${window["apiLocation"]}/api/AssemblyCategory?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Category=${encodeURIComponent(Category)}`,
            category
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                category.editing = false;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    // catalog.Data.Categories.push(response.data)
                    const index = _.findIndex(catalog.Data.Categories, { Co, BusinessUnit, Division, Category });
                    if (index > -1) {
                        catalog.Data.Categories.splice(index, 1, category);
                    }
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                })
            }
        })
    };
}

export function removeAssemblyCategory(category) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, Category } = category;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.delete(`${window["apiLocation"]}/api/AssemblyCategory?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Category=${encodeURIComponent(Category)}`,
            category
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                category.editing = false;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    // catalog.Data.Categories.push(response.data)
                    const index = _.findIndex(catalog.Data.Categories, { Co, BusinessUnit, Division, Category });
                    if (index > -1) {
                        catalog.Data.Categories.splice(index, 1);
                    }
                }
                // const { data } = catalogDialog;
                // if (data && data.Data && data.Data.Categories) {
                //     const index = _.findIndex(catalog.Data.Categories, { Co, BusinessUnit, Division, Category });
                //     if (index > -1) {
                //         data.Data.Categories.splice(index, 1);
                //     }
                // }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Category #${category.Category} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                })
            }
        })
    };
}

export function addCopiedAssemblyComponents(ComponentsList, assemblyId) {
    if (ComponentsList) {
        if (ComponentsList.length > 0) {
            ComponentsList.map(async (Component) => {
                Component.AssemblyID = assemblyId;

                const request = axios.post(`${window["apiLocation"]}/api/AssemblyComponent`,
                    Component
                );
                await request.then((response) => {
                    window["warn"]("addAssemblyComponent res", response)
                })
            })
        }
    }
}



export function addAssemblyComponent(newAssemblyComponent) {
    return (dispatch, getState) => {

        const state = getState();
        const { catalogItemDialog } = state.catalogApp;

        const request = axios.post(`${window["apiLocation"]}/api/AssemblyComponent`,
            newAssemblyComponent
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                catalogItemDialog.data.Data.Components.push(response.data);
                // dispatch({
                //     type: UPDATE_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG,
                //     data: response.data
                // });
                dispatch({
                    type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                    data: catalogItemDialog.data
                });
                dispatch(showMessage({
                    message: `Assembly Component #${response.data.Component} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((e) => {

        })
    };
}

export function updateAssemblyComponent(selectedAssemblyComponent) {
    return (dispatch, getState) => {

        const { Co, BusinessUnit, Division, AssemblyID, Component } = selectedAssemblyComponent;
        const state = getState();
        const { catalogItemDialog } = state.catalogApp;
        const { Components } = catalogItemDialog.data.Data;
        const index = _.findIndex(Components, { Co, BusinessUnit, Division, AssemblyID, Component });

        const request = axios.put(`${window["apiLocation"]}/api/AssemblyComponent?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&AssemblyID=${AssemblyID}&Component=${Component}`,
            selectedAssemblyComponent
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Components.splice(index, 1, response.data);
                dispatch({
                    type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                    data: catalogItemDialog.data
                });
                dispatch(showMessage({
                    message: `Assembly Component #${response.data.Component} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    };
}


export function removeAssemblyComponent(selectedAssemblyComponent) {
    return (dispatch, getState) => {

        const { Co, BusinessUnit, Division, AssemblyID, Component } = selectedAssemblyComponent;
        const state = getState();
        const { catalogItemDialog } = state.catalogApp;
        const { Components } = catalogItemDialog.data.Data;
        const index = _.findIndex(Components, { Co, BusinessUnit, Division, AssemblyID, Component });

        const request = axios.delete(`${window["apiLocation"]}/api/AssemblyComponent?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&AssemblyID=${AssemblyID}&Component=${Component}`);

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Components.splice(index, 1);
                dispatch({
                    type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                    data: catalogItemDialog.data
                });
                dispatch(showMessage({
                    message: `Assembly Component #${Component} has been successfully removed.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    };
}

export function addCatalog(newCatalog) {
    return (dispatch, getState) => {

        const state = getState();
        // const { catalogDialog } = state.catalogApp;
        const { catalogs } = state.spReducers.catalog;

        const request = axios.post(`${window["apiLocation"]}/api/Catalog`,
            newCatalog
        );

        return request.then((response) => {
            catalogs.push(response.data)
            Promise.all([
                dispatch({
                    type: SET_CATALOG_DATA,
                    payload: catalogs
                }),
                dispatch({
                    type: UPDATE_EDIT_CATALOG_DIALOG,
                    data: response.data
                })
                // dispatch(getCatalog(response.data))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Catalog has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateCatalog(selectedCatalog) {
    return (dispatch, getState) => {

        const { Co, BusinessUnit, Division } = selectedCatalog;

        const state = getState();
        const { catalogs } = state.spReducers.catalog;
        const index = _.findIndex(catalogs, { Co, BusinessUnit, Division });

        const clone = _.cloneDeepWith(selectedCatalog);

        const request = axios.put(`${window["apiLocation"]}/api/Catalog?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}`,
            {
                ...clone,
                Data: {
                    ErrMsg: null,
                    Exclusions: clone.Data.Exclusions
                }
            }
        );

        return request.then((response) => {
            catalogs.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_CATALOG_DATA,
                    payload: catalogs
                }),
                dispatch({
                    type: UPDATE_EDIT_CATALOG_DIALOG,
                    data: {
                        ...selectedCatalog,
                        ...response.data,
                        Data: {
                            ...selectedCatalog.Data,
                            ...response.data.Data
                        }
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Catalog has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}


export function openNewOptionBoardDialog(data, catalog, estimate) {
    return {
        type: OPEN_NEW_OPTION_BOARD_DIALOG,
        data,
        catalog,
        estimate
    }
}

export function openEditOptionBoardDialog(data, catalog, estimate) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/OptionBoard?Co=${data.Co}&BusinessUnit=${encodeURIComponent(data.BusinessUnit)}&Division=${encodeURIComponent(data.Division)}&OptionBoard=${data.OptionBoard}`);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_OPTION_BOARD_DIALOG,
                    data: response.data,
                    catalog,
                    estimate
                }),
            ]);
        }).catch((e) => {
            dispatch({
                type: OPEN_EDIT_OPTION_BOARD_DIALOG,
                data,
                catalog,
                estimate
            });
        });
    }
}

export function closeNewOptionBoardDialog() {
    return {
        type: CLOSE_NEW_OPTION_BOARD_DIALOG
    }
}

export function closeEditOptionBoardDialog() {
    return {
        type: CLOSE_EDIT_OPTION_BOARD_DIALOG
    }
}

// export function addOptionBoard() {
//     return {
//         type: ADD_CATALOG_OPTION_BOARD,
//     }
// }

// export function updateOptionBoard() {
//     return {
//         type: UPDATE_CATALOG_OPTION_BOARD,
//     }
// }

// export function removeOptionBoard() {
//     return {
//         type: REMOVE_CATALOG_OPTION_BOARD,
//     }
// }

export function getOptionBoard(selectedOptionBoard) {
    if (selectedOptionBoard) {
        return (dispatch, getState) => {

            const request = axios.get(`${window["apiLocation"]}/api/OptionBoard?Co=${selectedOptionBoard.Co}&BusinessUnit=${encodeURIComponent(selectedOptionBoard.BusinessUnit)}&Division=${encodeURIComponent(selectedOptionBoard.Division)}&OptionBoard=${selectedOptionBoard.OptionBoard}`);

            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: SET_OPTION_BOARD,
                        assembly: response.data,
                    }),
                ]))
        }
    } else {
        return {
            type: SET_OPTION_BOARD,
            assembly: null,
        };
    }
}

export function getAllOptionBoards({ Co, BusinessUnit, Division }) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/OptionBoard?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Full=true`);

        return request.then((response) => {
            return Promise.all([
                dispatch({
                    type: GET_ALL_OPTION_BOARDS,
                    payload: response.data,
                }),
            ])
        }).catch(error => {

        })
    }

}

export function setOptionBoardCategory(category) {
    return {
        type: SET_OPTION_BOARD_CATEGORY,
        category,
    }
}

export function setOptionBoardCategories(categories) {
    return {
        type: SET_OPTION_BOARD_CATEGORIES,
        categories,
    }
}

export function setOptionBoard(selectedOptionBoard) {
    return {
        type: SET_OPTION_BOARD,
        assembly: selectedOptionBoard,
    }
}

export function setOptionBoards(assemblies) {
    return {
        type: SET_OPTION_BOARDS,
        assemblies,
    }
}

export function addOptionBoard(newOptionBoard) {
    window["warn"]('Trying to post new option board: ', newOptionBoard);
    const ob = _.cloneDeepWith(newOptionBoard);
    ob.Data.Options.map((opt) => {
        delete opt.catalog;
        delete opt.optionBoard;
        opt.Data.Components.map((comp) => {
            delete comp.Data;
        });
    })
    delete ob.Data.Images;
    delete ob.Data.Components;
    delete ob.category;
    // delete ob.Data.Assignments;
    window["warn"]('Trying to post new cloned option board: ', ob);
    try {
        return (dispatch, getState) => {

            const state = getState();
            const { savedCatalogs } = state.spReducers.catalog;
            const est = state.estimatesApp.estimates.estimateDialog.data;

            const request = axios.post(`${window["apiLocation"]}/api/OptionBoard`,
                ob
            );

            return request.then((response) => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const { Co, BusinessUnit, Division, OptionBoard, Data } = response.data;
                    const { Assignments } = Data;
                    const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                    if (catalog) {
                        catalog.Data.OptionBoards.push(response.data);
                        Assignments.map((value) => {
                            const { Category } = value;
                            const category = _.find(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, Category });
                            if (category) {
                                category.Data.OptionBoards.push(value);
                            }
                            catalog.Data.OptionBoardAssignments.push(value);
                        })
                    }
                    Promise.all([
                        dispatch({
                            type: SET_SAVED_CATALOGS,
                            payload: savedCatalogs
                        }),
                        dispatch({
                            type: UPDATE_EDIT_CATALOG_DIALOG,
                            data: catalog
                        }),
                        dispatch({
                            type: UPDATE_EDIT_OPTION_BOARD_DIALOG,
                            data: response.data
                        }),
                    ]).then(() => {
                        dispatch(showMessage({
                            message: `Option Board #${response.data.OptionBoard} has been successfully created.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                    });
                }
            })
        };
    } catch (ex) {
        window["warn"]('Error: ', ex);
    }
}

export function updateOptionBoard(selectedOptionBoard) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, OptionBoard } = selectedOptionBoard;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;
        const est = state.estimatesApp.estimates.estimateDialog.data;

        const request = axios.put(`${window["apiLocation"]}/api/OptionBoard?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&OptionBoard=${encodeURIComponent(OptionBoard)}`,
            selectedOptionBoard
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    const index = _.findIndex(catalog.Data.OptionBoards, { Co, BusinessUnit, Division, OptionBoard });
                    if (index > -1) {
                        catalog.Data.OptionBoards.splice(index, 1, response.data);
                    }
                }
                Promise.all([
                    dispatch({
                        type: UPDATE_EDIT_OPTION_BOARD_DIALOG,
                        data: response.data
                    }),
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        data: catalog
                    }),
                ]).then(() => {
                    dispatch(showMessage({
                        message: `OptionBoard #${response.data.OptionBoard} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                });
            }
        })
    };
}

export function removeOptionBoard(selectedOptionBoard) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, OptionBoard } = selectedOptionBoard;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;
        const est = state.estimatesApp.estimates.estimateDialog.data;

        const request = axios.delete(`${window["apiLocation"]}/api/OptionBoard?Co=${encodeURIComponent(selectedOptionBoard.Co)}&OptionBoard=${encodeURIComponent(selectedOptionBoard.OptionBoard)}`,
            selectedOptionBoard
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    const index = _.findIndex(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, OptionBoard });
                    if (index > -1) {
                        catalog.Data.OptionBoards.splice(index, 1);
                    }
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        data: catalog
                    }),
                ]).then(() => {
                    dispatch(showMessage({
                        message: `OptionBoard #${selectedOptionBoard.OptionBoard} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                });
            }
        })
    };
}

export function addOptionBoardCategory(newOptionBoardCategory) {
    return (dispatch, getState) => {

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.post(`${window["apiLocation"]}/api/OptionBoardCategory`,
            newOptionBoardCategory
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const { Co, BusinessUnit, Division } = response.data;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    catalog.Data.OptionBoardCategories.push(response.data);
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                });
            }
        });
    };
}

export function updateOptionBoardCategory(category) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, Category } = category;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.put(`${window["apiLocation"]}/api/OptionBoardCategory?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Category=${encodeURIComponent(Category)}`,
            category
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                category.editing = false;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    // catalog.Data.OptionBoardCategories.push(response.data)
                    const index = _.findIndex(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, Category });
                    if (index > -1) {
                        catalog.Data.OptionBoardCategories.splice(index, 1, category);
                    }
                }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                })
            }
        })
    };
}

export function removeOptionBoardCategory(category) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division, Category } = category;

        const state = getState();
        const { savedCatalogs } = state.spReducers.catalog;
        const { catalogDialog } = state.catalogApp;

        const request = axios.delete(`${window["apiLocation"]}/api/OptionBoardCategory?Co=${encodeURIComponent(Co)}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Category=${encodeURIComponent(Category)}`,
            category
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                category.editing = false;
                const catalog = _.find(savedCatalogs, { Co, BusinessUnit, Division });
                if (catalog) {
                    // catalog.Data.OptionBoardCategories.push(response.data)
                    const index = _.findIndex(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, Category });
                    if (index > -1) {
                        catalog.Data.OptionBoardCategories.splice(index, 1);
                    }
                }
                // const { data } = catalogDialog;
                // if (data && data.Data && data.Data.OptionBoardCategories) {
                //     const index = _.findIndex(catalog.Data.OptionBoardCategories, { Co, BusinessUnit, Division, Category });
                //     if (index > -1) {
                //         data.Data.OptionBoardCategories.splice(index, 1);
                //     }
                // }
                Promise.all([
                    dispatch({
                        type: SET_SAVED_CATALOGS,
                        payload: savedCatalogs
                    }),
                    dispatch({
                        type: UPDATE_EDIT_CATALOG_DIALOG,
                        payload: catalog
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Category #${category.Category} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                })
            }
        })
    };
}



export function addOptionBoardComponent(newOptionBoardComponent) {
    return (dispatch, getState) => {

        const state = getState();
        const { catalogItemDialog } = state.catalogApp;

        const request = axios.post(`${window["apiLocation"]}/api/OptionBoardComponent`,
            newOptionBoardComponent
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                catalogItemDialog.data.Data.Components.push(response.data);
                // dispatch({
                //     type: UPDATE_EDIT_CATALOG_OPTION_BOARD_COMPONENT_DIALOG,
                //     data: response.data
                // });
                dispatch({
                    type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                    data: catalogItemDialog.data
                });
                dispatch(showMessage({
                    message: `OptionBoard Component #${response.data.Component} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((e) => {

        })
    };
}

export function updateOptionBoardComponent(selectedOptionBoardComponent) {
    return (dispatch, getState) => {

        const { Co, BusinessUnit, Division, OptionBoardID, Component } = selectedOptionBoardComponent;
        const state = getState();
        const { catalogItemDialog } = state.catalogApp;
        const { Components } = catalogItemDialog.data.Data;
        const index = _.findIndex(Components, { Co, BusinessUnit, Division, OptionBoardID, Component });

        const request = axios.put(`${window["apiLocation"]}/api/OptionBoardComponent?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&OptionBoardID=${OptionBoardID}&Component=${Component}`,
            selectedOptionBoardComponent
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Components.splice(index, 1, response.data);
                dispatch({
                    type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                    data: catalogItemDialog.data
                });
                dispatch(showMessage({
                    message: `OptionBoard Component #${response.data.Component} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    };
}


export function removeOptionBoardComponent(selectedOptionBoardComponent) {
    return (dispatch, getState) => {

        const { Co, BusinessUnit, Division, OptionBoardID, Component } = selectedOptionBoardComponent;
        const state = getState();
        const { catalogItemDialog } = state.catalogApp;
        const { Components } = catalogItemDialog.data.Data;
        const index = _.findIndex(Components, { Co, BusinessUnit, Division, OptionBoardID, Component });

        const request = axios.delete(`${window["apiLocation"]}/api/OptionBoardComponent?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&OptionBoardID=${OptionBoardID}&Component=${Component}`);

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Components.splice(index, 1);
                dispatch({
                    type: UPDATE_EDIT_CATALOG_ITEM_DIALOG,
                    data: catalogItemDialog.data
                });
                dispatch(showMessage({
                    message: `OptionBoard Component #${Component} has been successfully removed.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    };
}


export function selectAllAssemblies(data) {
    return (dispatch, getState) => {
        const state = getState().catalogApp.assemblySearch;
        const { data } = state;
        dispatch({
            type: SET_SELECTED_ASSEMBLIES,
            selectedAssemblies: _.cloneDeepWith(data)
        });
    }
}

export function deSelectAllAssemblies() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_SELECTED_ASSEMBLIES,
            selectedAssemblies: []
        });
    }
}

export function toggleInSelectedAssemblies(row) {
    return (dispatch, getState) => {
        const state = getState().catalogApp.assemblySearch;
        const { selectedAssemblies } = state;
        const index = _.findIndex(selectedAssemblies, { Assembly: row.Assembly });
        if (index > -1) {
            selectedAssemblies.splice(index, 1);
        } else {
            selectedAssemblies.push(row)
        }
        dispatch({
            type: SET_SELECTED_ASSEMBLIES,
            selectedAssemblies
        });
    }
}

export function getSearchAssemblies(table, catalog) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division } = catalog;
        const { pageSize, page, sorted, category, variance } = table;
        dispatch({
            type: SET_ASSEMBLY_SEARCH_LOADING,
            loading: true,
        })
        const request = axios.put(`${window["apiLocation"]}/api/AssemblyRates?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Page=${page + 1}&Count=${pageSize}&Category=${category}&Variance=${variance}`, sorted);
        // const request = axios.put(`${window["apiLocation"]}/api/Assembly?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Page=${page + 1}&Count=${pageSize}&Category=${category}&Variance=${variance}`, sorted);
        return request.then((response) => {
            const { Assemblies, Page, PageCount } = response.data;
            dispatch({
                type: SET_RATE_UPDATES_LIST,
                data: Assemblies,
                pages: PageCount,
                page: Page,
                loading: false,
                category,
                variance,
                sorted
            });
        })
    }
}



export function updateAllAssemblies(table, catalog) {
    return (dispatch, getState) => {
        const { Co, BusinessUnit, Division } = catalog;
        const { pageSize, page, sorted, category, variance } = table;
        dispatch({
            type: SET_ASSEMBLIES_UPDATING,
            updating: true,
        })
        const request = axios.put(`${window["apiLocation"]}/api/AssemblyRates?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Page=${page + 1}&Count=${pageSize}&Category=${category}&Variance=${variance}&Update=true`, sorted);
        // const request = axios.put(`${window["apiLocation"]}/api/Assembly?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Page=${page + 1}&Count=${pageSize}&Category=${category}&Variance=${variance}`, sorted);
        return request.then((response) => {
            const { Assemblies, Page, PageCount } = response.data;
            dispatch({
                type: SET_RATE_UPDATES_LIST,
                data: Assemblies,
                pages: PageCount,
                page: Page,
                loading: false,
                category,
                variance,
                sorted
            });
            dispatch(deSelectAllAssemblies());
            dispatch(showMessage({
                message: `Assemblies successfully updated`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));
        }).catch((error) => {
            dispatch({
                type: SET_ASSEMBLIES_UPDATING,
                updating: false,
            })
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}

export function updateSelectedAssembliesPricing(assemblies, selectAll) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_ASSEMBLIES_UPDATING,
            updating: true,
        })
        const request = axios.post(`${window["apiLocation"]}/api/AssemblyRates`, assemblies);
        return request.then((response) => {
            const state = getState().catalogApp.assemblySearch;
            const data = _.cloneDeepWith(state.data);
            response.data.map((assembly) => {
                const index = _.findIndex(data, { Assembly: assembly.Assembly });
                if (index > -1) {
                    data.splice(index, 1, { ...assembly, savedChanges: true, hasChanges: false, hasError: (assembly.Data.ErrMsg && assembly.Data.ErrMsg.length > 0) });
                }
            })
            dispatch({
                type: UPDATE_ASSEMBLY_PRICING,
                data
            });
            dispatch(showMessage({
                message: `Assemblies successfully updated`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));
            dispatch(deSelectAllAssemblies());
        }).catch((error) => {
            dispatch({
                type: SET_ASSEMBLIES_UPDATING,
                updating: false,
            })
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}

export function updateAssemblyPricing(assembly) {
    const { Co, BusinessUnit, Division, Assembly } = assembly;
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/AssemblyRates?Co=${Co}&BusinessUnit=${encodeURIComponent(BusinessUnit)}&Division=${encodeURIComponent(Division)}&Assembly=${Assembly}`, assembly);
        return request.then((response) => {
            const state = getState().catalogApp.assemblySearch;
            const data = _.cloneDeepWith(state.data);
            const index = _.findIndex(data, { Assembly: response.data.Assembly });
            if (index > -1) {
                data.splice(index, 1, { ...response.data, savedChanges: true, hasChanges: false, hasError: (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) });
            }
            dispatch({
                type: UPDATE_ASSEMBLY_PRICING,
                data
            });
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Pricing successfully updated for Assembly #${assembly.PRCode || assembly.Assembly}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            dispatch(showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}