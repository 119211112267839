import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localSporage from 'store/storage/CacheManager';
import api from './api.reducer';
import authentication from './authenticate.reducer';
import vault from './vault.reducer';
// import { createBlacklistFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const vaultConfig = {
    key: "sp-vault-2",
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['vault'],
    serialize: false,
    deserialize: false,
}

const authConfig = {
    key: "sp-auth-2",
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['access_token'],
    serialize: false,
    deserialize: false,

}

const apiConfig = {
    key: "sp-api-2",
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    serialize: false,
    deserialize: false,

}

const spAuth = combineReducers({
    api: persistReducer(apiConfig, api),
    authentication: persistReducer(authConfig, authentication),
    vault: persistReducer(vaultConfig, vault)
});

export default spAuth;

