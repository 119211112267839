import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from '@lodash';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CostTypesList extends Component {

    state = {
        selectedCostTypesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCostTypeMenu = (event) => {
        this.setState({ selectedCostTypesMenu: event.currentTarget });
    };

    closeSelectedCostTypesMenu = () => {
        this.setState({ selectedCostTypesMenu: null });
    };

    render() {
        const { classes, securables, businessUnits, divisions, } = this.props;
        const data = [...this.props.costTypeOverrideData];
        const accessLevel = _.find(securables, { Securable: "cost-types" });

        return (
            <div>
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Cost Type GL Overrides</Typography>
                <ReactTableFixedColumns
                    className={classNames(classes.root, "-striped -highlight border-0")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className: "cursor-pointer",
                            onClick: (e, handleOriginal) => {
                                if (rowInfo) {
                                    this.props.onSelectedOverride({ ...rowInfo.original });
                                }
                            }
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header: "Cost Type",
                            accessor: "CostType",
                            className: "font-bold justify-center",
                            width: 96
                        },
                        {
                            Header: "Business Unit",
                            id: "businessUnit",
                            accessor: r => _.find(businessUnits, { BusinessUnit: r.BusinessUnit }).Description,
                            className: "justify-center",
                            width: 256
                        },
                        {
                            Header: "Division",
                            id: "division",
                            accessor: r => _.find(divisions, { BusinessUnit: r.BusinessUnit, Division: r.Division }).Description,
                            className: "justify-center",
                            width: 256
                        },
                        {
                            Header: "Cost GL",
                            accessor: "OverrideCostGLAccount",
                            className: "justify-center",
                            width: 192
                        },
                        {
                            Header: "Revenue GL",
                            accessor: "OverrideRevenueGLAccount",
                            className: "justify-center",
                            width: 192
                        },

                    ]}
                    defaultPageSize={10}
                    noDataText="No GL Overrides found"
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCostTypes: Actions.getCostTypes,
        getUserData: Actions.getUserData,
        toggleInSelectedCostTypes: Actions.toggleInSelectedCostTypes,
        selectAllCostTypes: Actions.selectAllCostTypes,
        deSelectAllCostTypes: Actions.deSelectAllCostTypes,
        openEditCostTypeDialog: Actions.openEditCostTypeDialog,
        removeCostTypes: Actions.removeCostTypes,
        removeCostType: Actions.removeCostType,
        toggleStarredCostType: Actions.toggleStarredCostType,
        toggleStarredCostTypes: Actions.toggleStarredCostTypes,
        setCostTypesStarred: Actions.setCostTypesStarred,
        setCostTypesUnstarred: Actions.setCostTypesUnstarred,
        openNewCostTypeDialog: Actions.openNewCostTypeDialog
    }, dispatch);
}

function mapStateToProps({ costTypesApp, spReducers }) {
    return {
        costTypes: costTypesApp.costTypes.entities,
        selectedCostTypeIds: costTypesApp.costTypes.selectedCostTypeIds,
        searchText: costTypesApp.costTypes.searchText,
        user: costTypesApp.user,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CostTypesList)));
