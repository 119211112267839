import * as Actions from '../actions';

const initialState = {
    state: false,
    sipPhone: null,
    sipSession: null,
    sipSessionActive: false,
    awaitingCallFrom: null,
    devices: {
        audioinput: {},
        audiooutput: {},
        videoinput: {},
    },
    videoOrientation: 'user',
    audioIn: '',
    audioOut: '',
    videoIn: '',
    remoteStream: null,
    localStream: null,
    favorites: [],
    recents: [],
    contacts: [],
    jwt: {
        token: null,
        refresh: null,
        project: null,
    },
};

const dialer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_DIALER_TOKENS:
            {
                return {
                    ...state,
                    jwt: {
                        token: action.payload.Token,
                        refresh: action.payload.Refresh,
                        project: action.payload.Project,
                    }
                }
            }
        case Actions.GET_MEDIA_DEVICES:
            {
                return {
                    ...state,
                    devices: action.payload,
                }
            }
        case Actions.SET_VIDEO_ORIENTATION:
            {
                return {
                    ...state,
                    videoOrientation: action.payload,
                }
            }
        case Actions.SET_AUDIO_IN:
            {
                return {
                    ...state,
                    audioIn: action.payload,
                }
            }
        case Actions.SET_AUDIO_OUT:
            {
                return {
                    ...state,
                    audioOut: action.payload,
                }
            }
        case Actions.SET_VIDEO_IN:
            {
                return {
                    ...state,
                    videoIn: action.payload,
                }
            }
        case Actions.SET_CONTACTS:
            {
                return {
                    ...state,
                    contacts: action.payload,
                }
            }
        case Actions.SET_FAVORITES:
            {
                return {
                    ...state,
                    favorites: action.payload,
                }
            }
        case Actions.SET_RECENTS:
            {
                return {
                    ...state,
                    recents: action.payload,
                }
            }
        case Actions.INITIATE_SIP_CONNECTION:
            {
                return {
                    ...state,
                    sipPhone: action.payload,
                };
            }
        case Actions.UPDATE_SIP_CONNECTION:
            {
                return {
                    ...state,
                    sipPhone: action.payload,
                };
            }
        case Actions.END_SIP_CONNECTION:
            {
                return {
                    ...state,
                    sipPhone: null,
                };
            }
        case Actions.INITIATE_SIP_SESSION:
            {
                return {
                    ...state,
                    sipSession: action.payload,
                    sipSessionActive: action.active,
                    remoteStream: action.remote,
                    localStream: action.local,

                };
            }
        case Actions.UPDATE_SIP_SESSION:
            {
                return {
                    ...state,
                    sipSessionActive: action.payload,
                    remoteStream: action.remote,
                    localStream: action.local,
                };
            }
        case Actions.UPDATE_LOCAL_STREAM: 
        {
            return {
                ...state,
                localStream: action.local,
            }
        }
        case Actions.END_SIP_SESSION:
            {
                return {
                    ...state,
                    sipSession: null,
                    sipSessionActive: false,
                    remoteStream: null,
                    localStream: null,
                };
            }
        case Actions.TOGGLE_DIALER:
            {
                return {
                    ...state,
                    state: action.payload
                };
            }
        case Actions.AWAITING_CALL_FROM:
            {
                return {
                    ...state,
                    awaitingCallFrom: action.payload,
                }
            }
        default:
            {
                return state;
            }
    }
};

export default dialer;