import * as Actions from "../../actions/version/index";
import { deviceDetect } from "react-device-detect";
import * as v from "version";

const device = deviceDetect();

// console.log(device);

const initialState = {
  version: v.version.version,
  device,
  releaseNotes: null,
  about: null,
  allowVersionList: false,
  aboutDialog: {
    release: null,
    props: {
      open: true
    }
  },
  warningDialog: {
    props: {
      open: false,
    },
  },
};

const version = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_VERSION:
      {
        const { version } = action;
        return {
          ...state,
          version
        }
      }
    case Actions.SET_ABOUT:
      {
        const { about, open, allowVersionList } = action;
        return {
          ...state,
          about,
          aboutDialog: {
            props: {
              open,
              allowVersionList
            }
          }
        }
      }
    case Actions.WARN_LIVE_VERSION:
      {
        const { open } = action;
        return {
          ...state,
          warningDialog: {
            props: {
              open
            }
          }
        }
      }
    case Actions.SET_VERSION_NOTES:
      {
        const { data, open, release } = action;
        return {
          ...state,
          releaseNotes: data,
          aboutDialog: {
            release,
            props: {
              open
            }
          }
        }
      }
    case Actions.RESET_VERSION_NOTES:
      {
        return {
          ...state,
          releaseNotes: null,
        }
      }
    case Actions.RESET_ALLOW_VERSION_LIST:
      {
        const { allowVersionList } = action;
        return {
          ...state,
          aboutDialog: {
            props: {
              open: true,
              allowVersionList: allowVersionList
            }
          }
        }
      }
    default:
      {
        return state;
      }
  }
};

export default version;
