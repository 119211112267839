import {combineReducers} from 'redux';
import materialCategories from './material-categories.reducer';
import user from './user.reducer';

const materialCategoriesAppReducers = combineReducers({
    materialCategories,
    user
});

export default materialCategoriesAppReducers;
