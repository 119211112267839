import _ from '@lodash';
import { AppBar, Badge, Button, CircularProgress, Dialog, DialogActions, FormControlLabel, GridList, GridListTile, GridListTileBar, Icon, IconButton, Menu, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ServiceUnitsList from '../service-units/ServiceUnitsList';
import PartTypesList from './PartTypesList';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {

    },
    formControl: {
        marginBottom: 24
    },
    addButton: {
        float: 'right',
        marginTop: 8,
        zIndex: 3,
    },
    andMore: {
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/images/backgrounds/SP_Header.png)',
        backgroundPosition: 'center',
        backgroundColor: '#3f3f3f',
        color: '#fff',
        paddingBottom: '100%',
        borderRadius: 5,
        border: '1px dotted #3f3f3f',
    },
    searchResult: {
        paddingBottom: '100%',
        backgroundPosition: 'center !important',
        backgroundSize: 'cover !important',
        borderRadius: 5,
        cursor: 'pointer',
    },
    searchContainer: {
        minHeight: 512,
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'calc(100% + 12px)',
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    footerBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
            'rgba(0,0,0,0.15) 30%, rgba(0,0,0,0.3) 100%)',
    },
    icon: {
        color: 'white',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteImages: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
    }
});
const newServiceUnitTypeState = {
    Co: null,
    UnitType: null,
    UnitCat: null,
    Prefix: null,
    Name: null,
    Description: null,
    Notes: '',
    Data: {
        ErrMsg: null,
        Units: [],
        TrainingImages: [],
        PartTypes: [],
    },
    value: 0,
    images: [],
    deleteImages: [],
    searchResults: [],
    selectedSearchImages: [],
    selectedAttachmentImages: [],
    selectedSearchImage: null,
    selectedAttachmentImage: null,
    selectedTrainingImage: null,
    uploadedTrainingImage: null,
    imageSearch: null,
    displayLimit: 36,
    anchorEl: null,
    searchAttachments: false,
    searchImages: false,
    trainingModel: false,
    modelTrained: false,
    publishingModel: false,
    modelPublished: true,
    uploadingImages: false,
    uploadingAttachments: false,
    uploadingURLs: false,
    deletingImages: false,
};

class ServiceUnitTypeDialog extends Component {
    state = { ...newServiceUnitTypeState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newServiceUnitTypeState.Co = this.props.Co;
        if (!prevProps.serviceUnitTypeDialog.props.open && this.props.serviceUnitTypeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.serviceUnitTypeDialog.type === 'edit' &&
                this.props.serviceUnitTypeDialog.data &&
                !_.isEqual({
                    ...newServiceUnitTypeState,
                    ...this.props.serviceUnitTypeDialog.data,
                    images: [],
                    deleteImages: [],
                    searchResults: [],
                }, prevState)) {
                this.setState({
                    ...newServiceUnitTypeState,
                    ...this.props.serviceUnitTypeDialog.data,
                    images: [],
                    deleteImages: [],
                    searchResults: [],
                });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.serviceUnitTypeDialog.type === 'new' &&
                !_.isEqual({
                    ...newServiceUnitTypeState,
                    images: [],
                    deleteImages: [],
                    searchResults: [],
                }, prevState)) {
                this.setState({
                    ...newServiceUnitTypeState,
                    images: [],
                    deleteImages: [],
                    searchResults: [],
                });
            }
        }
        if (this.props.serviceUnitTypeDialog.type === 'update' &&
            this.props.serviceUnitTypeDialog.data &&
            !_.isEqual(this.props.serviceUnitTypeDialog.data, prevState)) {
            this.props.serviceUnitTypeDialog.type = 'edit';
            this.setState({ ...this.props.serviceUnitTypeDialog.data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.serviceUnitTypeDialog.type === 'edit' ? this.props.closeEditServiceUnitTypeDialog() : this.props.closeNewServiceUnitTypeDialog();
    };

    canBeSubmitted() {
        const { UnitCat, UnitType, Description } = this.state;
        return (
            UnitCat && UnitType && Description && UnitCat.length > 0 && UnitType.length > 0 && Description.length > 0
        );
    }

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    fileSelectedHandler = (event) => {
        let images = [...this.state.images];
        for (var i = 0; i < event.target.files.length; i++) {
            impress(event.target.files[i], 1024, 50, true, (img) => {
                images.push(img);
                this.setState({ ...this.state, images });
            });
        }
    }

    uploadTrainingImages = () => {
        let { Co, UnitCat, UnitType, images, Data } = this.state;
        const fd = new FormData();
        const uploads = [];
        const URLs = [];
        const Attachments = [];
        for (var i = 0; i < images.length; i++) {
            const img = images[i];
            if (img.base64) {
                fd.append('image', img.file, img.fileName);
                uploads.push(img);
            };
            if (img.contentUrl) {
                URLs.push(img.contentUrl);
            };
            if (img.Attachment) {
                Attachments.push(img);
            };
        };
        let { TrainingImages } = Data;
        if (!TrainingImages) {
            TrainingImages = [];
        }
        if (uploads.length > 0) {
            this.setState({ ...this.state, uploadingImages: true });
            axios.post(`${window["apiLocation"]}/api/MLImages/UploadUnitTypeImages?Co=${encodeURIComponent(Co)}&UnitCat=${encodeURIComponent(UnitCat)}&UnitType=${encodeURIComponent(UnitType)}`, fd).then((res) => {
                if (res.data) {
                    for (var i = 1; i <= uploads.length; i++) {
                        if (res.data[`Image${i}Status`]) {
                            const img = res.data[`Image${i}Results`][0].image;
                            if (img) {
                                TrainingImages.push({
                                    Id: img.id,
                                    Thumbnail: img.thumbnailUri,
                                    Original: img.originalImageUri,
                                    Resized: img.resizedImageUri,
                                    Width: img.width,
                                    Height: img.Height
                                });
                            }
                        }
                    };
                    this.setState({ ...this.state, Data: { ...Data, TrainingImages }, images: [], uploadingImages: false, });
                }
            });
        }
        if (Attachments.length > 0) {
            this.setState({ ...this.state, uploadingAttachments: true });
            axios.post(`${window["apiLocation"]}/api/MLImages/UploadUnitTypeAttachments?Co=${encodeURIComponent(Co)}&UnitCat=${encodeURIComponent(UnitCat)}&UnitType=${encodeURIComponent(UnitType)}`, Attachments).then((res) => {
                if (res.data) {
                    for (var i = 1; i <= Attachments.length; i++) {
                        if (res.data[`Image${i}Status`]) {
                            const img = res.data[`Image${i}Results`][0].image;
                            if (img) {
                                TrainingImages.push({
                                    Id: img.id,
                                    Thumbnail: img.thumbnailUri,
                                    Original: img.originalImageUri,
                                    Resized: img.resizedImageUri,
                                    Width: img.width,
                                    Height: img.Height
                                });
                            }
                        }
                    };
                    this.setState({ ...this.state, Data: { ...Data, TrainingImages }, images: [], uploadingAttachments: false });
                }
            });
        }
        if (URLs.length > 0) {
            this.setState({ ...this.state, uploadingURLs: true });
            axios.post(`${window["apiLocation"]}/api/MLImages/UploadUnitTypeURLImages?Co=${encodeURIComponent(Co)}&UnitCat=${encodeURIComponent(UnitCat)}&UnitType=${encodeURIComponent(UnitType)}`, URLs).then((res) => {
                if (res.data) {
                    for (var i = 0; i < res.data.BatchResults.length; i++) {
                        const img = res.data.BatchResults[i].image;
                        if (img) {
                            TrainingImages.push({
                                Id: img.id,
                                Thumbnail: img.thumbnailUri,
                                Original: img.originalImageUri,
                                Resized: img.resizedImageUri,
                                Width: img.width,
                                Height: img.Height
                            });
                        }
                    };
                    this.setState({ ...this.state, Data: { ...Data, TrainingImages }, images: [], uploadingURLs: false });
                }
            });
        }

    }

    searchImages = () => {
        const { imageSearch, Description } = this.state;
        axios.post(`${window["apiLocation"]}/api/MLImages/ImageSearch?Term=${imageSearch ? imageSearch : Description}`).then((res) => {
            this.setState({ ...this.state, imageSearch: imageSearch ? imageSearch : Description, displayLimit: 36, searchResults: res.data.value });
        });

    }

    increaseDisplayLimit = () => {
        let { displayLimit } = this.state;
        displayLimit += 36;
        this.setState({ ...this.state, displayLimit });
    }

    selectSearchResult = (img) => {
        let { images } = this.state;
        const index = _.findIndex(images, { thumbnailUrl: img.thumbnailUrl });
        if (index > -1) {
            images.splice(index, 1);
        } else {
            images.push(img);
        }
        this.setState({ ...this.state, images });
    }

    viewSearchResult = (img) => {
        this.setState({ ...this.state, selectedSearchImage: img });
    }

    selectAttachment = (img) => {
        let { images } = this.state;
        const index = _.findIndex(images, { Attachment: img.Attachment });
        if (index > -1) {
            images.splice(index, 1);
        } else {
            images.push(img);
        }
        this.setState({ ...this.state, images });
    }

    viewAttachment = (img) => {
        this.setState({ ...this.state, selectedAttachmentImage: img });
    }

    selectTrainingImage = (img) => {
        let { images } = this.state;
        const index = _.findIndex(images, img.thumbnailUrl ? { thumbnailUrl: img.thumbnailUrl } : img.Attachment ? { Attachment: img.Attachment } : img.fileName && { fileName: img.fileName });
        if (index > -1) {
            images.splice(index, 1);
        } else {
            images.push(img);
        }
        this.setState({ ...this.state, images });
    }

    deleteTrainingImage = (img) => {
        let { deleteImages } = this.state;
        const index = _.findIndex(deleteImages, { Id: img.Id });
        if (index > -1) {
            deleteImages.splice(index, 1);
        } else {
            deleteImages.push(img);
        }
        this.setState({ ...this.state, deleteImages });
    }

    viewTrainingImage = (img) => {
        this.setState({ ...this.state, selectedTrainingImage: img });
    }

    viewUploadedTrainingImage = (img) => {
        this.setState({ ...this.state, uploadedTrainingImage: img });
    }

    deleteTrainingImages = () => {
        let { Co, Data, deleteImages } = this.state;
        let { TrainingImages } = Data;
        const IDs = [];
        for (var i = 0; i < deleteImages.length; i++) {
            const img = deleteImages[i];
            IDs.push(img.Id);
            const index = _.findIndex(TrainingImages, { Id: img.Id });
            if (index > -1) {
                TrainingImages.splice(index, 1);
            }
        }
        if (IDs.length > 0) {
            this.setState({ ...this.state, deletingImages: true });
            axios.post(`${window["apiLocation"]}/api/MLImages/RemoveUnitTypeImages?Co=${encodeURIComponent(Co)}`, IDs).then((res) => {
                if (res.data) {
                    for (var i = 0; i < deleteImages.length; i++) {
                        const img = deleteImages[i];
                        const index = _.findIndex(TrainingImages, { Id: img.Id });
                        if (index > -1) {
                            TrainingImages.splice(index, 1);
                        }
                    }
                    this.setState({ ...this.state, Data: { ...Data, TrainingImages }, deleteImages: [], deletingImages: false });
                }
            });
        }
    }

    trainModel = () => {
        const { Co } = this.state;
        this.setState({ ...this.state, trainingModel: true, modelTrained: false, })
        axios.post(`${window["apiLocation"]}/api/MLImages/TrainUnitTypeModel?Co=${encodeURIComponent(Co)}`, {}).then((res) => {
            if (res.data) {
                window["warn"](res.data);
                this.setState({ ...this.state, trainingModel: false, modelTrained: true, modelPublished: false });
            }
        }).catch((err) => {
            this.setState({ ...this.state, trainingModel: false, modelTrained: false, });
        });
    }

    publishModel = () => {
        const { Co } = this.state;
        this.setState({ ...this.state, publishingModel: true, modelPublished: false, })
        axios.post(`${window["apiLocation"]}/api/MLImages/PublishUnitTypeModel?Co=${encodeURIComponent(Co)}`, {}).then((res) => {
            if (res.data) {
                window["warn"](res.data);
                this.setState({ ...this.state, publishingModel: false, modelPublished: true, });
            }
        }).catch((err) => {
            this.setState({ ...this.state, publishingModel: false, modelPublished: false, });
        });
    }

    render() {
        const { classes, serviceUnitTypeDialog, addServiceUnitType, updateServiceUnitType, removeServiceUnitType, businessUnits, serviceUnitCategories } = this.props;
        const { value, images, Data, searchResults, selectedSearchImage, uploadingImages, uploadingAttachments, uploadingURLs, deletingImages, uploadedTrainingImage, trainingModel, modelTrained, publishingModel, modelPublished, selectedAttachmentImage, selectedTrainingImage, displayLimit, anchorEl, searchImages, searchAttachments, deleteImages } = this.state;
        const { Units } = Data ? Data : { Units: [] };

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...serviceUnitTypeDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={value === 0 ? "sm" : "md"}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {serviceUnitTypeDialog.type === 'new' ? 'New Service Unit Type' : 'Edit Service Unit Type'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>build</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Units ? this.state.Data.Units.length : 0} color="error"><Icon>list</Icon></Badge>} label="Units" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.PartTypes ? this.state.Data.PartTypes.length : 0} color="error"><Icon>settings</Icon></Badge>} label="Part Types" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.TrainingImages ? this.state.Data.TrainingImages.length : 0} color="error"><Icon>image</Icon></Badge>} label="Recognition" />
                            </Tabs>
                        </AppBar>

                        {value === 0 &&
                            <TabContainer>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">build</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Unit Type"
                                        id="UnitType"
                                        name="UnitType"
                                        value={this.state.UnitType}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled={(serviceUnitTypeDialog.type === 'new' ? false : true)}
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Unit Category"
                                        id="UnitCat"
                                        select
                                        name="UnitCat"
                                        value={this.state.UnitCat}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >{
                                            (serviceUnitCategories && serviceUnitCategories.length > 0) && serviceUnitCategories.map((value) => (
                                                <MenuItem value={value.UnitCat}>
                                                    {value.Name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">notes</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Name"
                                        id="name"
                                        name="Name"
                                        value={this.state.Name}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                        label="Prefix"
                                        id="prefix"
                                        name="Prefix"
                                        value={this.state.Prefix}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">chat_bubble_outline</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">note</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <div className="w-1/2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                </div>
                            </TabContainer>
                        }

                        {value === 1 &&
                            <TabContainer>
                                <ServiceUnitsList selectedServiceUnitData={[...this.state.Data.Units]} />
                            </TabContainer>
                        }

                        {value === 2 &&
                            <TabContainer>
                                <PartTypesList unitType={{ ...this.state }} partTypeData={[...this.state.Data.PartTypes]} />
                            </TabContainer>
                        }

                        {value === 3 &&
                            <TabContainer>
                                <Tooltip placement="left" title="Upload Training Images">
                                    <Icon onClick={(e) => this.setState({ anchorEl: (anchorEl || searchAttachments || searchImages) ? null : e.currentTarget, searchAttachments: false, searchImages: false })} className={classNames(classes.addButton, "text-18 cursor-pointer")}>{(!searchAttachments && !searchImages) ? "more_vert" : "close"}</Icon>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={(e) => this.setState({ anchorEl: null })}
                                >
                                    <MenuItem onClick={() => { this.fileInput.click(); this.setState({ ...this.state, anchorEl: null, searchAttachments: false }); }}><Icon className="mr-8">cloud_upload</Icon>Upload</MenuItem>
                                    <MenuItem onClick={() => this.setState({ ...this.state, anchorEl: null, searchAttachments: false, searchImages: true })}><Icon className="mr-8">search</Icon>Search</MenuItem>
                                    <MenuItem onClick={() => this.setState({ ...this.state, anchorEl: null, searchImages: false, searchAttachments: true })}><Icon className="mr-8">attach_file</Icon>Attachments</MenuItem>
                                </Menu>
                                <input
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={this.fileSelectedHandler}
                                    ref={fileInput => this.fileInput = fileInput}
                                />
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">image</Icon>Image Recognition</Typography>
                                {searchImages &&
                                    <div className="flex">
                                        <TextField
                                            className="mb-16"
                                            label="Search"
                                            id="imageSearch"
                                            name="imageSearch"
                                            value={this.state.imageSearch}
                                            InputLabelProps={{ shrink: this.state.imageSearch ? true : false }}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <div className="min-w-12 pt-20">
                                        </div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="mb-16"
                                            onClick={this.searchImages}
                                        >
                                            Search
                                    </Button>
                                    </div>
                                }
                                {searchResults && searchResults[selectedSearchImage] &&
                                    <Dialog
                                        open={searchResults && searchResults[selectedSearchImage]}
                                        onClose={() => this.setState({ ...this.state, selectedSearchImage: null })}
                                        fullWidth
                                        maxWidth="sm"
                                    >
                                        <IconButton style={{
                                            position: 'absolute',
                                            right: 10,
                                            top: 10,
                                            zIndex: 3,
                                            color: 'white'
                                        }}
                                            className={classes.closeIcon}
                                            onClick={() => this.setState({ ...this.state, selectedSearchImage: null })}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                        {(searchImages && selectedSearchImage > 0) &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, selectedSearchImage: selectedSearchImage - 1 })}
                                            >
                                                <Icon className="text-32">chevron_left</Icon>
                                            </IconButton>
                                        }
                                        {(searchImages && (selectedSearchImage < (searchResults.length - 1)) && selectedSearchImage < displayLimit - 1) &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, selectedSearchImage: selectedSearchImage + 1 })}
                                            >
                                                <Icon className="text-32">chevron_right</Icon>
                                            </IconButton>
                                        }
                                        <img className="w-full" src={searchResults[selectedSearchImage].contentUrl} />
                                        <GridListTileBar
                                            title={searchResults[selectedSearchImage].contentUrl}
                                            titlePosition="top"
                                            actionIcon={
                                                <IconButton onClick={() => this.selectSearchResult(searchResults[selectedSearchImage])} className={_.findIndex(images, { thumbnailUrl: searchResults[selectedSearchImage].thumbnailUrl }) > -1 ? classes.selectedIcon : classes.icon}>
                                                    <Icon>{_.findIndex(images, { thumbnailUrl: searchResults[selectedSearchImage].thumbnailUrl }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                            className={classNames(classes.titleBar, "pt-12")}
                                        />
                                    </Dialog>
                                }
                                {images && images[selectedTrainingImage] &&
                                    <Dialog
                                        open={images && images[selectedTrainingImage]}
                                        onClose={() => this.setState({ ...this.state, selectedTrainingImage: null })}
                                        fullWidth
                                        maxWidth="sm"
                                    >
                                        <IconButton style={{
                                            position: 'absolute',
                                            right: 10,
                                            top: 10,
                                            zIndex: 3,
                                            color: 'white'
                                        }}
                                            className={classes.closeIcon}
                                            onClick={() => this.setState({ ...this.state, selectedTrainingImage: null })}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                        {selectedTrainingImage > 0 &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, selectedTrainingImage: selectedTrainingImage - 1 })}
                                            >
                                                <Icon className="text-32">chevron_left</Icon>
                                            </IconButton>
                                        }
                                        {(selectedTrainingImage < (images.length - 1) && selectedTrainingImage < displayLimit - 1) &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, selectedTrainingImage: selectedTrainingImage + 1 })}
                                            >
                                                <Icon className="text-32">chevron_right</Icon>
                                            </IconButton>
                                        }
                                        <img className="w-full" src={images[selectedTrainingImage].contentUrl ? images[selectedTrainingImage].contentUrl : images[selectedTrainingImage].Attachment ? getProfileImage(`Co=${this.state.Co}&ID=${images[selectedTrainingImage].Attachment}` ): images[selectedTrainingImage].fileName && images[selectedTrainingImage].base64} />
                                        <GridListTileBar
                                            title={images[selectedTrainingImage].thumbnailUrl ? images[selectedTrainingImage].thumbnailUrl : images[selectedTrainingImage].Attachment ? images[selectedTrainingImage].Description : images[selectedTrainingImage].fileName && images[selectedTrainingImage].fileName}
                                            titlePosition="top"
                                            actionIcon={
                                                <IconButton onClick={() => this.selectTrainingImage(images[selectedTrainingImage])} className={_.findIndex(images, images[selectedTrainingImage].thumbnailUrl ? { thumbnailUrl: images[selectedTrainingImage].thumbnailUrl } : images[selectedTrainingImage].Attachment ? { Attachment: images[selectedTrainingImage].Attachment } : images[selectedTrainingImage].fileName && { fileName: images[selectedTrainingImage].fileName }) > -1 ? classes.selectedIcon : classes.icon}>
                                                    <Icon>{_.findIndex(images, images[selectedTrainingImage].thumbnailUrl ? { thumbnailUrl: images[selectedTrainingImage].thumbnailUrl } : images[selectedTrainingImage].Attachment ? { Attachment: images[selectedTrainingImage].Attachment } : images[selectedTrainingImage].fileName && { fileName: images[selectedTrainingImage].fileName }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                            className={classNames(classes.titleBar, "pt-12")}
                                        />
                                    </Dialog>
                                }
                                {(Data && Data.TrainingImages && Data.TrainingImages[uploadedTrainingImage]) &&
                                    <Dialog
                                        open={Data && Data.TrainingImages && Data.TrainingImages[uploadedTrainingImage]}
                                        onClose={() => this.setState({ ...this.state, uploadedTrainingImage: null })}
                                        fullWidth
                                        maxWidth="sm"
                                    >
                                        <IconButton style={{
                                            position: 'absolute',
                                            right: 10,
                                            top: 10,
                                            zIndex: 3,
                                            color: 'white'
                                        }}
                                            className={classes.closeIcon}
                                            onClick={() => this.setState({ ...this.state, uploadedTrainingImage: null })}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                        {uploadedTrainingImage > 0 &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, uploadedTrainingImage: uploadedTrainingImage - 1 })}
                                            >
                                                <Icon className="text-32">chevron_left</Icon>
                                            </IconButton>
                                        }
                                        {(uploadedTrainingImage < (Data.TrainingImages.length - 1) && uploadedTrainingImage < displayLimit - 1) &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, uploadedTrainingImage: uploadedTrainingImage + 1 })}
                                            >
                                                <Icon className="text-32">chevron_right</Icon>
                                            </IconButton>
                                        }
                                        <img className="w-full" src={Data.TrainingImages[uploadedTrainingImage].Original} />
                                        <GridListTileBar
                                            title={Data.TrainingImages[uploadedTrainingImage].Id}
                                            titlePosition="top"
                                            actionIcon={
                                                <IconButton className={classes.doneIcon}>
                                                    <Icon>cloud_done</Icon>
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                            className={classNames(classes.titleBar, "pt-12")}
                                        />
                                        <GridListTileBar
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton onClick={() => this.deleteTrainingImage(Data.TrainingImages[uploadedTrainingImage])} className={_.findIndex(deleteImages, { Id: Data.TrainingImages[uploadedTrainingImage].Id }) > -1 ? classes.deletingIcon : classes.deleteIcon}>
                                                    <Icon>{_.findIndex(deleteImages, { Id: Data.TrainingImages[uploadedTrainingImage].Id }) > -1 ? "delete_forever" : "delete"}</Icon>
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                            className={classes.footerBar}
                                        />
                                    </Dialog>
                                }
                                {searchImages &&
                                    <div className={classes.searchContainer}>
                                        <GridList cols={matches ? 4 : 3} spacing={12} cellHeight="auto" className={classes.gridList}>
                                            {searchResults.map((img, index) => (
                                                index < displayLimit &&
                                                <GridListTile key={index} cols={1} rows={1}>
                                                    <div onClick={() => this.viewSearchResult(index)} className={classes.searchResult} style={{ background: `url(${img.thumbnailUrl})` }} />
                                                    <GridListTileBar
                                                        title={img.contentUrl}
                                                        titlePosition="top"
                                                        actionIcon={
                                                            <IconButton onClick={() => this.selectSearchResult(img)} className={_.findIndex(images, { thumbnailUrl: img.thumbnailUrl }) > -1 ? classes.selectedIcon : classes.icon}>
                                                                <Icon>{_.findIndex(images, { thumbnailUrl: img.thumbnailUrl }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                            </IconButton>
                                                        }
                                                        actionPosition="left"
                                                        className={classes.titleBar}
                                                    />
                                                </GridListTile>
                                            ))}
                                        </GridList>
                                        {searchResults.length > displayLimit &&
                                            <Button onClick={this.increaseDisplayLimit} color="primary" variant="contained" className="w-full mt-12">Show More</Button>
                                        }
                                    </div>
                                }
                                {searchAttachments &&
                                    <div className={classes.searchContainer}>
                                        <GridList cols={matches ? 4 : 3} spacing={12} cellHeight="auto" className={classes.gridList}>
                                            {Units.map((unit, index) => {
                                                if (unit.Data && unit.Data.Attachments) {
                                                    return unit.Data.Attachments.map((attch, key) => {
                                                        return (
                                                            <GridListTile key={index} cols={1} rows={1}>
                                                                <div className={classes.searchResult} style={{ background: `url(${window["apiLocation"]}/api/Attachment/GetProfileImage?Co=${unit.Co}&ID=${attch.Attachment}` }} />
                                                                <GridListTileBar
                                                                    title={unit.Name}
                                                                    titlePosition="top"
                                                                    actionIcon={
                                                                        <IconButton onClick={() => this.selectAttachment(attch)} className={_.findIndex(images, { Attachment: attch.Attachment }) > -1 ? classes.selectedIcon : classes.icon}>
                                                                            <Icon>{_.findIndex(images, { Attachment: attch.Attachment }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                                        </IconButton>
                                                                    }
                                                                    actionPosition="left"
                                                                    className={classes.titleBar}
                                                                />
                                                            </GridListTile>
                                                        );
                                                    })
                                                }
                                            })}
                                        </GridList>
                                    </div>
                                }
                                {(!searchImages && !searchAttachments) &&
                                    <div className={classes.searchContainer}>
                                        <div className="w-full">
                                            <GridList cols={matches ? 4 : 3} spacing={12} cellHeight="auto" className={classes.gridList}>
                                                {images.map((img, index) => (
                                                    img.fileName ?
                                                        <GridListTile key={index} cols={1} rows={1}>
                                                            <div onClick={() => this.viewTrainingImage(index)} className={classes.searchResult} style={{ background: `url(${img.base64})` }} />
                                                            <GridListTileBar
                                                                title={img.fileName}
                                                                titlePosition="top"
                                                                actionIcon={
                                                                    <IconButton onClick={() => this.selectTrainingImage(img)} className={classes.selectedIcon}>
                                                                        <Icon>cloud_upload</Icon>
                                                                    </IconButton>
                                                                }
                                                                actionPosition="left"
                                                                className={classes.titleBar}
                                                            />
                                                        </GridListTile>
                                                        : img.Attachment ?
                                                            <GridListTile key={index} cols={1} rows={1}>
                                                                <div onClick={() => this.viewTrainingImage(index)} className={classes.searchResult} style={{ background: `url(${window["apiLocation"]}/api/Attachment/GetProfileImage?Co=${this.state.Co}&ID=${img.Attachment}` }} />
                                                                <GridListTileBar
                                                                    title={img.Description}
                                                                    titlePosition="top"
                                                                    actionIcon={
                                                                        <IconButton onClick={() => this.selectTrainingImage(img)} className={_.findIndex(images, { Attachment: img.Attachment }) > -1 ? classes.selectedIcon : classes.icon}>
                                                                            <Icon>{_.findIndex(images, { Attachment: img.Attachment }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                                        </IconButton>
                                                                    }
                                                                    actionPosition="left"
                                                                    className={classes.titleBar}
                                                                />
                                                            </GridListTile> : img.thumbnailUrl &&
                                                            <GridListTile key={index} cols={1} rows={1}>
                                                                <div onClick={() => this.viewTrainingImage(index)} className={classes.searchResult} style={{ background: `url(${img.contentUrl})` }} />
                                                                <GridListTileBar
                                                                    title={img.contentUrl}
                                                                    titlePosition="top"
                                                                    actionIcon={
                                                                        <IconButton onClick={() => this.selectTrainingImage(img)} className={_.findIndex(images, { thumbnailUrl: img.thumbnailUrl }) > -1 ? classes.selectedIcon : classes.icon}>
                                                                            <Icon>{_.findIndex(images, { thumbnailUrl: img.thumbnailUrl }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                                        </IconButton>
                                                                    }
                                                                    actionPosition="left"
                                                                    className={classes.titleBar}
                                                                />
                                                            </GridListTile>
                                                ))}
                                                {(Data && Data.TrainingImages) && Data.TrainingImages.map((image, index) => (
                                                    <GridListTile key={index} cols={1} rows={1}>
                                                        <div onClick={() => this.viewUploadedTrainingImage(index)} className={classes.searchResult} style={{ background: `url(${image.Thumbnail})` }} />
                                                        <GridListTileBar
                                                            title={image.Id}
                                                            titlePosition="top"
                                                            actionIcon={
                                                                <IconButton className={classes.doneIcon}>
                                                                    <Icon>cloud_done</Icon>
                                                                </IconButton>
                                                            }
                                                            actionPosition="left"
                                                            className={classes.titleBar}
                                                        />
                                                        <GridListTileBar
                                                            titlePosition="bottom"
                                                            actionIcon={
                                                                <IconButton onClick={() => this.deleteTrainingImage(image)} className={_.findIndex(deleteImages, { Id: image.Id }) > -1 ? classes.deletingIcon : classes.deleteIcon}>
                                                                    <Icon>{_.findIndex(deleteImages, { Id: image.Id }) > -1 ? "delete_forever" : "delete"}</Icon>
                                                                </IconButton>
                                                            }
                                                            actionPosition="right"
                                                            className={classes.footerBar}
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                        </div>
                                    </div>
                                }
                            </TabContainer>
                        }
                        {
                            value === 0 && (
                                serviceUnitTypeDialog.type === 'new' ? (
                                    <DialogActions className="dialog-actions justify-between pl-16">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                addServiceUnitType(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Add
                                    </Button>
                                    </DialogActions>
                                ) : (
                                        <DialogActions className="dialog-actions justify-between pl-16">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    updateServiceUnitType(this.state);
                                                    this.closeComposeDialog();
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                Save
                                        </Button>
                                        </DialogActions>
                                    )
                            )
                        }
                        {
                            value === 3 &&
                            <DialogActions className="dialog-actions justify-between pl-16 pr-16">
                                {(modelPublished && !trainingModel && images.length > 0) &&
                                    <Button
                                        variant="contained"
                                        className="text-12"
                                        color="primary"
                                        onClick={this.uploadTrainingImages}
                                        disabled={images.length < 1 || (uploadingImages || uploadingAttachments || uploadingURLs)}
                                    >
                                        <Icon className="mr-12">cloud_upload</Icon> Upload Images {(uploadingImages || uploadingAttachments || uploadingURLs) && <CircularProgress className="ml-12" size={24} />}
                                    </Button>
                                }
                                {(images.length < 1 && !modelTrained) &&
                                    <Button
                                        variant="contained"
                                        className="text-12"
                                        color="primary"
                                        onClick={this.trainModel}
                                        disabled={trainingModel}
                                    >
                                        <Icon className="mr-12">settings</Icon> Train Model {trainingModel && <CircularProgress className="ml-12" size={24} />}
                                    </Button>
                                }
                                {(images.length < 1 && modelTrained && !modelPublished) &&
                                    <Button
                                        variant="contained"
                                        className="text-12"
                                        color="primary"
                                        onClick={this.publishModel}
                                        disabled={publishingModel}
                                    >
                                        <Icon className="mr-12">cloud_upload</Icon> Publish Model {publishingModel && <CircularProgress className="ml-12" size={24} />}
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    className={classNames(classes.deleteImages, "text-12")}
                                    onClick={this.deleteTrainingImages}
                                    disabled={deleteImages.length < 1 || deletingImages}
                                >
                                    <Icon className="mr-12">delete</Icon> Delete Selected {deletingImages && <CircularProgress className="ml-12" size={24} />}
                                </Button>
                            </DialogActions>
                        }
                    </Dialog >
                }
            </Media >
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditServiceUnitTypeDialog: Actions.closeEditServiceUnitTypeDialog,
        closeNewServiceUnitTypeDialog: Actions.closeNewServiceUnitTypeDialog,
        addServiceUnitType: Actions.addServiceUnitType,
        updateServiceUnitType: Actions.updateServiceUnitType,
        removeServiceUnitType: Actions.removeServiceUnitType
    }, dispatch);
}

function mapStateToProps({ serviceUnitTypesApp, spReducers }) {
    return {
        serviceUnitTypeDialog: serviceUnitTypesApp.serviceUnitTypes.serviceUnitTypeDialog,
        businessUnits: spReducers.businessUnits,
        serviceUnitCategories: spReducers.serviceUnitCategories,
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitTypeDialog));
