import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import RGL, { WidthProvider } from "react-grid-layout";
import _ from 'lodash';
import FormSectionDialog from './FormSectionDialog';
import FormSection from './FormSection';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import moment from 'moment';

const GridLayout = WidthProvider(RGL);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    formSection: {
        border: '1px dotted lightgrey',
        // padding: 8,
    },
    scrollEnable: {
        touchAction: 'unset !important',
    },
    fullWidth: {
        width: '100% !important',
    }

});

class FormBuilder extends Component {

    state = {
        grabbing: false,
        needsUpdate: [],
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedFormSectionMenu = (event) => {
        this.setState({ selectedFormSectionsMenu: event.currentTarget });
    };

    closeSelectedFormSectionsMenu = () => {
        this.setState({ selectedFormSectionsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    setGrabbing = (grabbing) => {
        this.setState({ ...this.state, grabbing })
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    onDragEnd = (result) => {
        const { source, destination } = result;
        console.warn(result);

        if (!destination) {
            return;
        }

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        if (result.type === 'section') {
            const { form, onNeedsUpdate } = this.props;

            const data = this.reorder(
                form.Data.Sections,
                result.source.index,
                result.destination.index
            );

            const headers = _.filter(data, { HeaderYN: 'Y' });
            const content = _.filter(data, (o) => { return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y' });
            const footers = _.filter(data, { FooterYN: 'Y' });

            const sections = [...headers, ...content, ...footers];

            const needsUpdate = [];

            window["warn"](sections);

            sections.map((section, index) => {
                var old = _.find(form.Data.Sections, { Section: section.Section });
                if (old && old.RowIndex !== index) {
                    needsUpdate.push({ ...section, RowIndex: index });
                }
            });

            form.Data.Sections = [...sections];
            this.setState({ ...this.state, sections, needsUpdate }, () => onNeedsUpdate(needsUpdate));
        }
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    render() {
        const { classes, form, scrollLock, securables, openNewFormSectionDialog, openEditFormSectionDialog } = this.props;
        const { Co, Form } = form;
        console.warn(Co, Form, form);
        const { grabbing, sections } = this.state;
        const data = [...(sections || [...form.Data.Sections])];

        const headers = _.filter(data, { HeaderYN: 'Y' });
        const content = _.filter(data, (o) => { return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y' });
        const footers = _.filter(data, { FooterYN: 'Y' });

        const avatar = 'assets/images/avatars/profile.jpg';
        const types = { 'I': 'Inventory', 'W': 'Work Order' };
        const accessLevel = _.find(securables, { Securable: "forms" });

        return (
            <div className="w-full pb-64">
                <FormSectionDialog />
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">grid_on</Icon>{`Form Sections`}</Typography>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable
                        droppableId="section"
                        type="section"
                        direction="vertical"
                    >
                        {(provided) => (
                            <div ref={provided.innerRef} className="w-full">
                                {headers && headers.map((section, index) => (
                                    <Draggable
                                        isDragDisabled={scrollLock}
                                        draggableId={section.Section}
                                        key={index}
                                        index={index}
                                        type="section">
                                        {(provided, snapshot) => (
                                            <div className={classNames("w-full", !scrollLock && classes.formSection, scrollLock && classes.scrollEnable)}>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        onMouseDown={(e) => this.setGrabbing(true)}
                                                        onMouseUp={(e) => this.setGrabbing(false)}
                                                        className={`w-full min-h-24 form-section relative${grabbing ? ' cursor-grabbing' : ' cursor-grab'}`}
                                                    >
                                                        {scrollLock &&
                                                            <Icon color="action" onClick={() => openEditFormSectionDialog(section)} className="absolute pin-t pin-r text-20 cursor-pointer edit-icon z-10">edit</Icon>
                                                        }
                                                        {!scrollLock &&
                                                            <div className="pin-l pin-r pin-t pin-b m-auto absolute text-center h-24 w-auto text-grey-light">{section.Name}</div>
                                                        }
                                                        <FormSection scrollLock={true} section={section} data={[...section.Data.Elements]} />

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                                }
                                {content && content.map((section, index) => (
                                    <Draggable
                                        isDragDisabled={scrollLock}
                                        draggableId={section.Section}
                                        key={index}
                                        index={index + headers.length}
                                        type="section">
                                        {(provided, snapshot) => (
                                            <div className={classNames("w-full", !scrollLock && classes.formSection, scrollLock && classes.scrollEnable)}>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        onMouseDown={(e) => this.setGrabbing(true)}
                                                        onMouseUp={(e) => this.setGrabbing(false)}
                                                        className={`w-full min-h-24 form-section relative${grabbing ? ' cursor-grabbing' : ' cursor-grab'}`}
                                                    >
                                                        {scrollLock &&
                                                            <Icon color="action" onClick={() => openEditFormSectionDialog(section)} className="absolute pin-t pin-r text-20 cursor-pointer edit-icon z-10">edit</Icon>
                                                        }
                                                        {!scrollLock &&
                                                            <div className="pin-l pin-r pin-t pin-b m-auto absolute text-center h-24 w-auto text-grey-light">{section.Name}</div>
                                                        }
                                                        <FormSection scrollLock={true} section={section} data={[...section.Data.Elements]} />

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                                }
                                {footers && footers.map((section, index) => (
                                    <Draggable
                                        isDragDisabled={scrollLock}
                                        draggableId={section.Section}
                                        key={index}
                                        index={index + headers.length + content.length}
                                        type="section">
                                        {(provided, snapshot) => (
                                            <div className={classNames("w-full", !scrollLock && classes.formSection, scrollLock && classes.scrollEnable)}>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        onMouseDown={(e) => this.setGrabbing(true)}
                                                        onMouseUp={(e) => this.setGrabbing(false)}
                                                        className={`w-full min-h-24 form-section relative${grabbing ? ' cursor-grabbing' : ' cursor-grab'}`}
                                                    >
                                                        {scrollLock &&
                                                            <Icon color="action" onClick={() => openEditFormSectionDialog(section)} className="absolute pin-t pin-r text-20 cursor-pointer edit-icon z-10">edit</Icon>
                                                        }
                                                        {!scrollLock &&
                                                            <div className="pin-l pin-r pin-t pin-b m-auto absolute text-center h-24 w-auto text-grey-light">{section.Name}</div>
                                                        }
                                                        <FormSection scrollLock={true} section={section} data={[...section.Data.Elements]} />

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {accessLevel && accessLevel.AccessLevel !== "R" &&
                    <div className="w-full pt-16">
                        <Button
                            className="w-full"
                            variant="contained"
                            color="primary"
                            onClick={() => openNewFormSectionDialog({ Co, Form, })}
                        >
                            <Icon className="mr-6">add_circle_outline</Icon>New Section
                    </Button>
                    </div>
                }
                {/* <GridLayout
                    className="layout"
                    rowHeight={20}
                    cols={12}
                    compactType={"vertical"}
                    margin={[0, 0]}
                    isDraggable={!scrollLock}
                    isResizable={!scrollLock}
                    onDragStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                    onResizeStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                >
                    {
                        data.map((section, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classNames(!scrollLock && classes.formSection, scrollLock && classes.scrollEnable)}
                                    data-grid={{ section, x: section.PositionX, y: section.PositionY, w: section.Columns, h: section.Rows, static: section.StaticYN === "Y" }}
                                >
                                    <div
                                        onMouseDown={() => this.setGrabbing(true)}
                                        onMouseUp={() => this.setGrabbing(false)}
                                        className={`w-full h-full form-section relative${grabbing ? ' cursor-grabbing' : ' cursor-grab'}`}
                                    >
                                        {scrollLock &&
                                            <Icon color="action" onClick={() => openEditFormSectionDialog(section)} className="absolute pin-t pin-r text-20 cursor-pointer edit-icon z-10">edit</Icon>
                                        }
                                        {!scrollLock &&
                                            <div className="pin-l pin-r pin-t pin-b m-auto absolute text-center h-24 w-auto text-grey-light">{section.Name}</div>
                                        }
                                        <GridLayout
                                            className="layout"
                                            rowHeight={20}
                                            cols={12}
                                            compactType={"vertical"}
                                            margin={[0, 0]}
                                            isDraggable={false}
                                            isResizable={false}
                                            onDragStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                                            onResizeStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                                        >
                                            {
                                                section.Data.Elements && section.Data.Elements.map((el, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={classNames("pl-6 pr-6")}
                                                            data-grid={{ el, x: el.PositionX, y: el.PositionY, w: el.Columns, h: el.Rows, minH: ['NTS'].indexOf(el.Type) > -1 ? 2 : undefined, maxH: ['NTS'].indexOf(el.Type) > -1 ? 2 : ['IMG'].indexOf(el.Type) > -1 ? undefined : 1, static: el.StaticYN === "Y" }}
                                                        >
                                                            <div
                                                                onMouseDown={() => this.setGrabbing(true)}
                                                                onMouseUp={() => this.setGrabbing(false)}
                                                                className={`w-full h-full form-section relative${grabbing ? ' cursor-grabbing' : ' cursor-grab'}`}
                                                            >
                                                                {!scrollLock &&
                                                                    <div className="pin-l pin-r pin-t pin-b m-auto absolute text-center h-24 w-auto text-grey-light"></div>
                                                                }
                                                                {el.Type === "IMG" &&
                                                                    <img className="w-full h-auto p-8" onClick={this.break} src="assets/images/invoices/220SMInvoiceHeader.png" />
                                                                }
                                                                {el.Type === "IN" &&
                                                                    <TextField
                                                                        className={classes.formControl}
                                                                        label={el.Title}
                                                                        id={`formElement${el.Element}`}
                                                                        name={`FormElement${el.Element}`}
                                                                        value={this.state[`FormElement${el.Element}`] || ''}
                                                                        onChange={this.handleChange}
                                                                        variant="outlined"
                                                                        required={el.RequiredYN === "Y"}
                                                                        disabled={!scrollLock}
                                                                        fullWidth
                                                                    />
                                                                }
                                                                {el.Type === "NTS" &&
                                                                    <TextField
                                                                        className={classes.formControl}
                                                                        label={el.Title}
                                                                        id={`formElement${el.Element}`}
                                                                        name={`FormElement${el.Element}`}
                                                                        value={this.state[`FormElement${el.Element}`] || ''}
                                                                        onChange={this.handleChange}
                                                                        variant="outlined"
                                                                        required={el.RequiredYN === "Y"}
                                                                        disabled={!scrollLock}
                                                                        multiline
                                                                        rows={5}
                                                                        fullWidth
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </GridLayout>
                                    </div>
                                </div>
                            );
                        })
                    }
                </GridLayout> */}
            </div>
        );

        // return (
        //     <div>
        //         <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>{`Form Sections`}</Typography>
        //         <ReactTableFixedColumns
        //             className={classNames(classes.root, "-striped -highlight border-0 rounded")}
        //             getTrProps={(state, rowInfo, column) => {
        //                 return {
        //                     className: "cursor-pointer",
        //                     onClick: (e, handleOriginal) => {
        //                         if (rowInfo) {
        //                             this.props.onSelectedSection({ ...rowInfo.original });
        //                             // openEditFormSectionDialog({ ...rowInfo.original });
        //                         }
        //                     }
        //                 }
        //             }}
        //             data={data}
        //             columns={[
        //                 {
        //                     Header: "Form",
        //                     accessor: "Form",
        //                     width: 64,
        //                     className: "font-bold justify-center",
        //                 },
        //                 {
        //                     Header: "Section",
        //                     accessor: "Section",
        //                     width: 96,
        //                     className: "font-bold justify-center",
        //                 },
        //                 {
        //                     Header: "Name",
        //                     accessor: "Name",
        //                     className: "justify-center",
        //                     width: 192,
        //                 },
        //             ]}
        //             defaultPageSize={10}
        //             noDataText="No Form Sections found"
        //         />
        //     </div>
        // );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewFormSectionDialog: Actions.openNewFormSectionDialog,
        openEditFormSectionDialog: Actions.openEditFormSectionDialog,
    }, dispatch);
}

function mapStateToProps({ formsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        formData: spReducers.forms,
        searchText: formsApp.forms.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FormBuilder)));
