import _ from '@lodash';
import { Avatar, Button, Card, CardActions, CardContent, Dialog, Divider, Fab, Hidden, Icon, IconButton, InputAdornment, MenuItem, Slide, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import React, { Component } from 'react';
import Media from 'react-media';
import { SPRoundIcon } from 'main/icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import EstimateTMImageDialog from './EstimateTMImageDialog';
import axios from 'axios';
import * as Actions from './store/actions';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import getProfileImage from '../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 108px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const drawerWidth = 240;

const styles = theme => ({
    root: {
        // overflow: 'hidden',
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 24,
        height: 24,
        marginTop: -2,
        marginBottom: -4,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    backdrop: {
        zIndex: 9998,
        backgroundColor: 'rgba(0,0,0,.5)'
    },
    recommendations: {
        zIndex: 9999
    }
});

const newTMItemState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Name: null,
    Description: null,
    Assembly: null,
    Material: null,
    LaborType: null,
    Type: '0',
    PriceMethod: 'F',
    RateTemplate: null,
    Price: 0,
    TaxableYN: 'Y',
    DisplayYN: null,
    Notes: null,
    UnitCost: null,
    TotalCost: null,
    CustomPrice: null,
    CustomMemberPrice: null,
    CustomImgURL: null,
    EnteredBy: null,
    UpdatedBy: null,
    EnteredDate: null,
    UpdatedDate: null,
    Category: null,
    Qty: 1,
    UOM: 'EA',
    imageDialog: false,
    selectedImage: null,
    value: 0,
    saving: false,
    showRecommendations: false,
    estimate: null,
    option: null,
    partDescription: null,
    searching: false,
    confirmDelete: false
};

var newOption = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Option: null,
    Name: null,
    Description: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    PriceTotal: 0.00,
    AttachmentID: null,
    Data: {
        Components: [],
        ErrMsg: null,
    }

}

class EstimateTMItem extends Component {

    state = { ..._.cloneDeepWith(newTMItemState) };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidMount() {
        let { data, component, estimate } = this.props;

        // if (!component && estimate) {
        //     if (estimate.Data && estimate.Data.Components) {
        //         const { Co, BusinessUnit, Division, Assembly } = data || this.state;
        //         component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
        //     }
        // }
        this.setState({ ..._.cloneDeepWith(newTMItemState), ..._.cloneDeepWith(data), estimate });
    }

    componentDidUpdate = (prevProps, prevState) => {
        let { data, component, estimate } = this.props;

        // if (!component && estimate) {
        //     if (estimate.Data && estimate.Data.Components) {
        //         const { Co, BusinessUnit, Division, Assembly } = data || this.state;
        //         component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
        //     }
        // }
        if (data && (!_.isEqual(data, prevProps.data))) {
            this.setState({ ...this.state, ..._.cloneDeepWith(data), estimate });
        }
    }

    handleChange = (event) => {
        let { name, value } = event.target;

        if (name === 'Qty') {
            value = value.replace(/^1/, 0)
        }

        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };
    handleBlur = (event) => {
        let { name, value } = event.target; 

        if (name === 'Qty' && value.trim() === '' || !value) { 
            this.setState({...this.state, Qty: 1}) 

        }
    } 

    handleTypeChange = (event) => {
        let Type = event.target.value;
        this.setState({ ...this.state, Type, UOM: Type === 1 ? 'HR' : '' });
    }

    canBeSubmitted() {
        const { Co, Name, EditFromFieldYN, CustomPrice, CustomMemberPrice, estimate } = this.state;
        return (
            Co && Name && Co > 0 && Name.length > 0 && (!estimate || EditFromFieldYN !== "Y" || (CustomPrice !== undefined && CustomPrice !== null && CustomMemberPrice !== null && CustomMemberPrice !== undefined))
        );
    }

    fileSelectedHandler = (event) => {
        impress(event.target.files[0], 1024, 50, true, () => {
            // window["log"](img);
            // const fd = new FormData();
            // fd.append('image', img.file, img.fileName);
            // axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(this.state.Co)}&Type=Catalog&ID=${encodeURIComponent(this.state.Catalog)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
            //     if (Data) {
            //         Data.Avatar = img.base64;
            //     } else {
            //         Data = { Avatar: img.base64 };
            //     }
            //     this.setState({ Data });
            // });
        });
    }

    toggleTabs = () => {
        const { showTabs } = this.state;
        this.setState({ ...this.state, showTabs: !showTabs });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    increaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate && estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        Qty = Number(Qty || (component ? component.Qty : 1)) + 1;
        this.setState({ ...this.state, Qty });
    }

    decreaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate && estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }

        Qty = Math.max(Number(Qty || (component ? component.Qty : 0)) - 1, 0); 

        Qty = Qty === 0 ? 1 : Qty;

        this.setState({ ...this.state, Qty });
    }

    handleLaborChange = (event) => {
        const { laborTypes, catalog } = this.props;
        const { Co, BusinessUnit, Division, Qty } = this.state;
        let LaborType = event.target.value;
        const laborType = _.find(laborTypes, { Co, BusinessUnit, Division, LaborType });
        this.setState({ LaborType, Name: laborType.Description, Description: laborType.Description, CustomImgURL: null }, this.handleMarkup);
    }

    handleMarkup = () => {
        const { materials, laborTypes, catalog, rateTemplates } = this.props;
        let { Co, BusinessUnit, Division, Type, LaborType, Material, Qty, UnitCost, TotalCost, CustomPrice, CustomMemberPrice, Data } = this.state;
        let RateTemplate = _.find(rateTemplates, { Co, BusinessUnit, Division, RateTemplate: catalog.DefaultRateTemplate });
        if (RateTemplate) {
            switch (Type) {
                // case 0: {
                //     if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                //         UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MiscMarkup / 100))).toFixed(2);
                //         CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                //         PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                //     } else {
                //         if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                //             UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MiscMarkup / 100))).toFixed(2);
                //             CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                //             PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                //         }
                //     }
                // }
                //     break;
                case 1: {
                    CustomPrice = RateTemplate.LaborRate;
                    if (LaborType) {
                        for (var i = 0; i < RateTemplate.Data.Labor.length; i++) {
                            if (LaborType == RateTemplate.Data.Labor[i].LaborType) {
                                CustomPrice = parseFloat(RateTemplate.Data.Labor[i].Rate).toFixed(2);
                            }
                        }
                    }
                }
                    break;
                case 2: {
                    if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                        CustomPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                        for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                            var bp = RateTemplate.Data.Materials[i];
                            if (UnitCost >= bp.Breakpoint) {
                                CustomPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                            }
                        }
                    } else {
                        // if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                        //     UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                        //     CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                        //     PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                        //     for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                        //         var bp = RateTemplate.Data.Materials[i];
                        //         if (UnitCost >= bp.Breakpoint) {
                        //             UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                        //             CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                        //             PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                        //         }
                        //     }
                        // }
                    }
                }
                    break;
            }
        }
        window["warn"]('RateTemplate: ', RateTemplate, catalog, Co, BusinessUnit, Division, LaborType, CustomPrice, CustomMemberPrice)
        this.setState({
            CustomPrice,
            CustomMemberPrice: catalog.MemberLaborDiscount ? (CustomPrice * ((100 - catalog.MemberLaborDiscount) / 100)).toFixed(2) : this.state.CustomMemberPrice
        });
    }

    toggleAssemblies = (open, showRecommendations) => {
        this.setState({
            ...this.state,
            assembliesList: open,
            showRecommendations
        });
    };

    toggleImageDialog = (open) => {
        this.setState({ ...this.state, imageDialog: open });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    handleEditorChange = (Description) => {
        this.setState({ ...this.state, Description })
    }

    handleCustomMemberPriceChange = (e) => {
        const { catalog } = this.props;
        const { estimate } = this.state;
        this.setState({ CustomMemberPrice: e.target.value });
    }

    handleCustomPriceChange = (e) => {
        const { catalog } = this.props;
        const { estimate } = this.state;
        this.setState({ CustomPrice: e.target.value, CustomMemberPrice: catalog.MemberLaborDiscount ? e.target.value * ((100 - catalog.MemberLaborDiscount) / 100).toFixed(2) : this.state.CustomMemberPrice });
    }

    getPart = (barcode) => {
        const { Co, Material, Qty } = this.state;
        const request = axios.get(`${window["apiLocation"]}/api/Material?Co=${encodeURIComponent(Co)}&Material=${encodeURIComponent(barcode ? barcode : Material)}`).then((response) => {
            if (response.data) {
                this.handlePart(response.data, Qty);
            } else {
                this.setState({ ...this.state, scanMessage: 'Part not found' });
            }
        });
    }

    handlePart = (part, qty) => {
        const { Material, UOM, Description, Cost, CostType } = part;
        this.setState({ ...this.state, Qty: qty ? qty : 1, Material, UOM, Name: Description, UnitCost: Cost, CostType, TotalCost: Cost, isScanning: false, scanMessage: null, }, () => {
            this.handleMarkup();
        })
    }

    deleteTMItem = () => {
        let { estimate, component } = this.state;
        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Component } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Component });
            }
        }

        const { Components } = _.cloneDeepWith(estimate.Data);
        const index = _.findIndex(Components, { Component: component.Component });
        const comp = _.find(Components, { Component: component.Component });

        if (comp.ID) {
            comp.DeleteYN = 'Y';
            Components.splice(index, 1, comp);
        } else {
            Components.splice(index, 1);
        }
        if (this.props.onUpdated) {
            this.props.onUpdated(Components);
        }

        this.setState({ component: null, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
    }

    render() {
        window["warn"](this.state);
        const { classes, theme, addAssembly, rateTemplates, updateAssembly, departments, serviceTypes, services, catalog, materials, laborTypes, uoms, types } = this.props;
        let { estimate, component, Co, BusinessUnit, Division, Material, LaborType, CustomPrice, CustomMemberPrice, Price, CustomImgURL } = this.state;

        const selectedCatalog = _.find(catalog.savedCatalogs, { Co, BusinessUnit, Division });
        const tasks = selectedCatalog ? selectedCatalog.Data.Assemblies : [];
        const categories = selectedCatalog ? selectedCatalog.Data.Categories : [];

        const activeAgreements = estimate && estimate.Data && estimate.Data.Site && estimate.Data.Site.Data && estimate.Data.Site.Data.Agreements ? _.filter(estimate.Data.Site.Data.Agreements, (o) => o.ActiveYN !== 'N' && new Date(o.StartDate) <= new Date() && new Date(o.EndDate) >= new Date()) : null;
        const isMember = activeAgreements && activeAgreements.length > 0;
        const matAvatar = 'assets/images/logos/SP_Cog_Gradient.png';

        const toolbarOptions = [
            [{ size: ['small', false, 'large', 'huge'] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],

            ['link'],

            ['clean']                                       // remove formatting button
        ];

        const formats = [
            'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
            'list', 'bullet', 'indent', 'align',
            'link'
        ]

        const history = {
            delay: 2000,
            maxStack: 500,
            userOnly: true
        }

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <>
                        <Card className="w-full">
                            <CardContent className={!estimate ? "p-24 pb-96 sm:pb-48" : "p-12 px-24 pb-96 sm:pb-48"}>
                                <div>
                                    <div className="sm:flex">
                                        <div className="sm:flex w-full">
                                            <TextField
                                                className={classNames("m-0 w-full mb-16", !this.state.Type && "sm:w-1/2")}
                                                label="Type"
                                                select
                                                id="type"
                                                name="Type"
                                                value={this.state.Type || '0'}
                                                margin="dense"
                                                onChange={this.handleTypeChange}
                                                variant="outlined"
                                                required
                                            >
                                                {(types && types.length > 0 ? _.filter(types, (o) => o.UsageType < 3).map((value, index) => (
                                                    <MenuItem key={index} value={value.UsageType || '0'}>
                                                        {`${value.UsageType} - ${value.Name}`}
                                                    </MenuItem>
                                                )) : '')}
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            {
                                                this.state.Type == 2 &&
                                                <React.Fragment>
                                                    {this.state.searching || this.state.Material ?
                                                        <Autocomplete
                                                            className="w-full mb-16 m-0"
                                                            title="Material"
                                                            options={materials}
                                                            searchButton={true}
                                                            menuItemComponent={(value) => {
                                                                return value.ActiveYN === "Y" &&
                                                                    <MenuItem value={value.Material}>
                                                                        <div className="flex">
                                                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Material} src={(value.ImageID ? getProfileImage(`ID=${value.ImageID}&Thumb=true` ): matAvatar)} />
                                                                            {`${value.Material} - ${(value.Description || '').substr(0, 50)}`}
                                                                        </div>
                                                                    </MenuItem>
                                                            }}
                                                            debounce={750}
                                                            onChange={(text) => this.setState({ ...this.state, partDescription: text })}
                                                            onBlur={() => { if (!this.state.Material) { this.setState({ ...this.state, searching: false }) } }}
                                                            search={this.state.partDescription}
                                                            value={this.state.Material}
                                                            focusStyle={{ marginTop: 0 }}
                                                            margin="dense"
                                                            portal={true}
                                                            noBlur={true}
                                                            fullWidth
                                                            noflip={true}
                                                            onSelect={(option) => this.setState({ ...this.state, Material: option.Material, Name: option.Description, Description: option.Description, Qty: this.state.Qty ? this.state.Qty : 1, UOM: option.UOM, UnitCost: option.Cost, CustomImgURL: option.ImageID ? getProfileImage(`ID=${option.ImageID}` ): null, searching: false }, () => this.handleMarkup())}
                                                            required
                                                        /> :
                                                        <form className="w-full" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); setTimeout(() => this.setState({ searching: true }), 300) }}>
                                                            <TextField
                                                                className="w-full m-0 mb-16"
                                                                label="Material"
                                                                id="partDescription"
                                                                name="partDescription"
                                                                value={this.state.partDescription || ''}
                                                                margin="dense"
                                                                onChange={this.handleChange}
                                                                InputProps={this.state.Type === 2 ? {
                                                                    endAdornment: !this.state.Material ?
                                                                        <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: true, })} style={{ cursor: 'pointer' }}>search</Icon></InputAdornment>
                                                                        :
                                                                        <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: false, partDescription: null, Name: null, Description: null, Material: null, UnitCost: 0, CostTotal: 0, CustomPrice: null, CustomMemberPrice: null })} style={{ cursor: 'pointer' }}>close</Icon></InputAdornment>,
                                                                } : undefined}
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                            />
                                                        </form>
                                                    }
                                                </React.Fragment>
                                            }
                                            {
                                                this.state.Type == 1 &&
                                                <TextField
                                                    className="w-full m-0 mb-16"
                                                    label="Labor Type"
                                                    id="laborType"
                                                    name="LaborType"
                                                    value={this.state.LaborType || ''}
                                                    margin="dense"
                                                    onChange={this.handleLaborChange}
                                                    onBlur={this.formatCostAndPrice}
                                                    fullWidth
                                                    variant="outlined"
                                                    select
                                                >
                                                    {(this.state.BusinessUnit && this.state.Division && laborTypes && laborTypes.length > 0 ? _.filter(laborTypes, { Type: 'W', BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }).map((value, index) => (
                                                        (value.BusinessUnit === this.state.BusinessUnit && value.Division === this.state.Division) &&
                                                        <MenuItem key={index} value={value.LaborType}>
                                                            {`${value.LaborType} - ${value.Description}`}
                                                        </MenuItem>
                                                    )) : '')}
                                                </TextField>
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full sm:flex">
                                        <TextField
                                            className="w-full m-0"
                                            label="Title"
                                            id="name"
                                            name="Name"
                                            value={this.state.Name || ''}
                                            margin="dense"
                                            onChange={this.handleChange}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="sm:flex my-16 mb-0">
                                        <EstimateTMImageDialog onClose={() => this.toggleImageDialog(false)} onChange={(CustomImgURL) => this.setState({ ...this.state, CustomImgURL })} open={this.state.imageDialog} item={this.state} />
                                        <div className="w-full sm:max-w-1/2-12">
                                            {this.state.CustomImgURL ?
                                                <div className="w-full overflow-hidden mb-0 relative min-h-320 rounded max-h-512" style={{ border: '1px solid #f1f1f1', minHeight: 343 }}>
                                                    <div className={classNames(classes.blurBG, "pin-l pin-r pin-t pin-b m-auto absolute")} style={{ backgroundImage: `url(${this.state.CustomImgURL})` }}></div>

                                                    <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b m-auto absolute cursor-pointer")} style={{ backgroundImage: `url(${this.state.CustomImgURL})` }} onClick={() => { this.setState({ ...this.state, selectedImage: this.state.CustomImgURL }) }}></div>

                                                    {this.state.selectedImage &&
                                                        <Dialog
                                                            open={this.state.selectedImage}
                                                            onClose={() => this.setState({ ...this.state, selectedImage: null })}
                                                            fullWidth
                                                            maxWidth="sm"
                                                        >
                                                            <IconButton style={{
                                                                position: 'absolute',
                                                                right: 10,
                                                                top: 10,
                                                                zIndex: 3,
                                                                color: 'white'
                                                            }}
                                                                className={classes.closeIcon}
                                                                onClick={() => this.setState({ ...this.state, selectedImage: null })}
                                                            >
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                            <img className="w-full" src={this.state.selectedImage} />
                                                        </Dialog>
                                                    }
                                                </div> :
                                                <div className="w-full sm:mr-16 mb-0 cursor-pointer max-h-512">
                                                    <div onClick={() => this.toggleImageDialog(true)} className="w-full h-full rounded min-h-256 relative" style={{ border: '1px solid lightgrey', minHeight: 343 }}>
                                                        <Icon color="action" className="text-64 absolute pin-l pin-r pin-t pin-b m-auto">add_photo_alternate</Icon>
                                                    </div>
                                                </div>
                                            }
                                            <div className="mb-12 sm:mb-24">
                                                <div className="w-full mt-8 overflow-auto">
                                                    <div className="flex pb-8">
                                                        {this.state.CustomImgURL &&
                                                            <div className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 cursor-pointer" style={{ backgroundImage: `url(${CustomImgURL})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                                        }
                                                        <div className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 relative rounded" >
                                                            <Fab onClick={() => this.toggleImageDialog(true)} className="w-36 h-36 pin-l pin-r pin-t pin-b m-auto absolute" color="primary"><Icon className="text-20">add_photo_alternate</Icon></Fab>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <div className="w-full sm:pt-0">
                                            <React.Fragment>
                                                <div className="">
                                                    <Typography className="text-16 font-bold mb-4">Quantity</Typography>
                                                    <div className="flex mb-16">
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={this.decreaseQty}
                                                            className={classNames(classes.error, "p-0")}
                                                            style={{ minWidth: 38, minHeight: 'unset', height: 38 }}
                                                        >
                                                            <Icon>remove</Icon>
                                                        </Button>
                                                        <TextField
                                                            className="w-96 m-0 ml-8 mr-8"
                                                            id="qty"
                                                            name="Qty"
                                                            value={this.state.Qty || (component ? component.Qty : 1)}
                                                            margin="dense"
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleBlur}
                                                            variant="outlined"
                                                            InputProps={{ type: 'number' }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.increaseQty}
                                                            className="p-0"
                                                            style={{ minWidth: 38, minHeight: 'unset', height: 38 }}
                                                        >
                                                            <Icon>add</Icon>
                                                        </Button>
                                                        <div className="w-full">
                                                            <div className="ml-12">
                                                                <Autocomplete
                                                                    className={classNames("m-0")}
                                                                    title="UOM"
                                                                    options={uoms}
                                                                    menuItemComponent={(value) => {
                                                                        return value.ActiveYN === "Y" &&
                                                                            <MenuItem value={value.UOM}>
                                                                                <div className="flex">
                                                                                    {`${value.UOM} - ${value.Description}`}
                                                                                </div>
                                                                            </MenuItem>
                                                                    }}
                                                                    value={this.state.UOM}
                                                                    margin="dense"
                                                                    focusStyle={{ marginTop: 0 }}
                                                                    noflip={true}
                                                                    onSelect={(option) => this.setState({ ...this.state, UOM: option.UOM })}
                                                                    required
                                                                    portal={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Divider className="mb-8" />
                                            </React.Fragment>
                                            <div className="flex">
                                                <div className="mr-6">
                                                    <div>
                                                        <Typography className="text-16 font-bold mb-4" style={{ color: theme.palette.primary.main }}>Member Price</Typography>
                                                        <TextField className="text-20 mb-16" onBlur={() => this.setState({ CustomMemberPrice: Number(this.state.CustomMemberPrice).toFixed(2) })} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} margin="dense" variant="outlined" value={this.state.CustomMemberPrice || ''} name="CustomMemberPrice" onChange={this.handleCustomMemberPriceChange} />
                                                    </div>
                                                </div>
                                                <div className="ml-6">
                                                    <div>
                                                        <Typography className="text-16 font-bold mb-4">Price</Typography>
                                                        <TextField className="text-20 mb-16" onBlur={() => this.setState({ CustomPrice: Number(this.state.CustomPrice).toFixed(2) })} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} margin="dense" variant="outlined" value={this.state.CustomPrice || ''} name="CustomPrice" onChange={this.handleCustomPriceChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider className="mb-8" />
                                            <div>
                                                <Typography className={classNames("text-16 font-bold", estimate ? "mb-16" : "mb-0")}>Description</Typography>

                                                <ReactQuill
                                                    ref={(el) => { this.quillRef = el; }}
                                                    theme="snow"
                                                    className="w-full mb-24"
                                                    value={this.state.Description || ''}
                                                    name="Description"
                                                    modules={
                                                        {
                                                            toolbar: toolbarOptions,
                                                            history: history,
                                                            imageDrop: true,
                                                            imageResize: {
                                                                parchment: Quill.import('parchment'),
                                                                modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                            }
                                                        }
                                                    }
                                                    formats={formats}
                                                    onChange={this.handleEditorChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                            {(estimate && estimate.Data && estimate.Data.Components) &&
                                <CardActions className="justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-48 m-0 bg-white z-10 mb-44 py-12 catalog-item-footer" style={{ boxShadow: '2px 0px 12px 4px rgba(0,0,0,.5)' }}>
                                    <div className="flex">
                                        <Typography color="secondary" className="text-12 sm:text-16 font-bold mr-8">Item Subtotal:</Typography>
                                        <Typography className="text-12 sm:text-16 font-bold">{this.formatDollars(Number(this.state.Qty || 1) * Number(isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) || 0)}</Typography>
                                    </div>
                                    {!this.state.Component ?
                                        <Button
                                            className={classNames(classes.black, "text-12 sm:text-14 font-bold")}
                                            style={{ borderRadius: 24, paddingLeft: 8 }}
                                            variant="contained"
                                            onClick={() => {
                                                var rec = _.cloneDeepWith(this.state);
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                const last = _.maxBy(Components, 'Component');
                                                const {
                                                    Co,
                                                    BusinessUnit,
                                                    Division,
                                                    Name,
                                                    Description,
                                                    Assembly,
                                                    Material,
                                                    LaborType,
                                                    Type,
                                                    PriceMethod,
                                                    RateTemplate,
                                                    TaxableYN,
                                                    DisplayYN,
                                                    Notes,
                                                    UnitCost,
                                                    TotalCost,
                                                    CustomPrice,
                                                    CustomMemberPrice,
                                                    CustomImgURL,
                                                    EnteredBy,
                                                    UpdatedBy,
                                                    EnteredDate,
                                                    UpdatedDate,
                                                    Category,
                                                    Qty,
                                                    UOM
                                                } = rec;
                                                const {
                                                    Estimate,
                                                    OptionBoard,
                                                    Option
                                                } = estimate;
                                                const component = {
                                                    Co,
                                                    BusinessUnit,
                                                    Division,
                                                    Estimate,
                                                    OptionBoard,
                                                    Option,
                                                    Component: (last ? last.Component : 0) + 1,
                                                    Name,
                                                    Description,
                                                    Assembly,
                                                    Material,
                                                    LaborType,
                                                    Type,
                                                    PriceMethod,
                                                    RateTemplate,
                                                    Price: Number(this.state.Qty || 1) * Number(isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) || 0,
                                                    TaxableYN,
                                                    DisplayYN,
                                                    Notes,
                                                    UnitCost,
                                                    TotalCost,
                                                    CustomPrice,
                                                    CustomMemberPrice,
                                                    CustomImgURL,
                                                    Category,
                                                    Qty,
                                                    UOM,
                                                    Data: {

                                                    }
                                                };
                                                Components.push(component);
                                                if (this.props.onUpdated) {
                                                    this.props.onUpdated(Components);
                                                }
                                                this.setState({ component, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                window["warn"](estimate);
                                                // updateCatalogItem(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            <SPRoundIcon className={classNames(classes.colorSecondary, "text-24 mr-8")} /> Add <Hidden xsDown>{estimate.Option ? 'Component' : 'to Estimate'}</Hidden>
                                        </Button> :
                                        <div className="flex">
                                            <Button
                                                className={classNames(classes.black, "text-12 sm:text-14 font-bold")}
                                                style={{ borderRadius: 24, paddingLeft: 8, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                variant="contained"
                                                onClick={() => {
                                                    var rec = _.cloneDeepWith(this.state);
                                                    const { Components } = _.cloneDeepWith(estimate.Data);
                                                    const {
                                                        Co,
                                                        BusinessUnit,
                                                        Division,
                                                        Name,
                                                        Description,
                                                        Assembly,
                                                        Material,
                                                        LaborType,
                                                        Type,
                                                        PriceMethod,
                                                        RateTemplate,
                                                        TaxableYN,
                                                        DisplayYN,
                                                        Notes,
                                                        CustomPrice,
                                                        CustomMemberPrice,
                                                        CustomImgURL,
                                                        Category,
                                                        Qty,
                                                        UOM
                                                    } = rec;
                                                    let index = _.findIndex(Components, { Component: this.state.Component });
                                                    if (index > -1) {
                                                        const comp = {
                                                            ...Components[index],
                                                            Name,
                                                            Description,
                                                            Material,
                                                            LaborType,
                                                            Type,
                                                            PriceMethod,
                                                            RateTemplate,
                                                            Price: Number(this.state.Qty || 1) * Number(isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) || 0,
                                                            TaxableYN,
                                                            DisplayYN,
                                                            Notes,
                                                            CustomPrice,
                                                            CustomMemberPrice,
                                                            CustomImgURL,
                                                            Category,
                                                            Qty,
                                                            UOM,
                                                        };
                                                        Components.splice(index, 1, comp)
                                                    }
                                                    if (this.props.onUpdated) {
                                                        this.props.onUpdated(Components);
                                                    }
                                                    this.setState({ component, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                    window["warn"](estimate);
                                                    // updateCatalogItem(rec);
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                <SPRoundIcon className={classNames(classes.colorSecondary, "text-24 mr-8")} /> Update <Hidden xsDown>{estimate.Option ? 'Component' : 'Estimate'}</Hidden>
                                            </Button>
                                            <Button
                                                className={classNames(classes.error, "text-12 sm:text-14 font-bold m-0")}
                                                style={{ minWidth: 36, width: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                variant="contained"
                                                // color="primary"
                                                onClick={() => {
                                                    const { Components } = _.cloneDeepWith(estimate.Data);
                                                    const index = _.findIndex(Components, { Component: this.state.Component });
                                                    if (index > -1) {
                                                        const comp = _.find(Components, { Component: this.state.Component });
                                                        if (this.props.multipleOfSameComponent) {
                                                            if (comp.ID) {
                                                                comp.DeleteYN = 'Y';
                                                                Components.splice(index, 1, comp);
                                                            } else {
                                                                Components.splice(index, 1);
                                                            }
                                                            if (this.props.onUpdated) {
                                                                this.props.onUpdated(Components);
                                                            }
                                                            this.setState({ component: null, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                        } else {
                                                            this.setState({ ...this.state, confirmDelete: true })
                                                        }
                                                    }

                                                    // this.closeComposeDialog();
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                <Icon className={classNames("text-24")}>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </CardActions>
                            }
                        </Card>
                        <ConfirmationDialog
                            open={this.state.confirmDelete}
                            title={<div><Icon className="mr-6 align-middle mb-4 text-orange">warning</Icon>Are You Sure?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to remove "${this.state.Name}" from this estimate?`}</div>}
                            confirmText="Remove item"
                            cancelText="Cancel"
                            onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                            onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => this.deleteTMItem())}
                        />
                    </>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        impress,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        rateTemplates: spReducers.rateTemplates,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        employees: spReducers.employees,
        // catalog: spReducers.catalog,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        uoms: spReducers.uoms,
        materials: spReducers.materials.materials,
        laborTypes: spReducers.laborTypes,
        types: spReducers.workOrderUsageTypes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(EstimateTMItem));
