import axios from 'axios';
import { getUserData } from 'main/content/apps/inventory-location-groups/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_INVENTORY_LOCATION_GROUPS = '[FLEET VEHICLE CATEGORIES APP] GET FLEET VEHICLE CATEGORIES';
export const SET_SEARCH_TEXT = '[FLEET VEHICLE CATEGORIES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_INVENTORY_LOCATION_GROUPS = '[FLEET VEHICLE CATEGORIES APP] TOGGLE IN SELECTED FLEET VEHICLE CATEGORIES';
export const SELECT_ALL_INVENTORY_LOCATION_GROUPS = '[FLEET VEHICLE CATEGORIES APP] SELECT ALL FLEET VEHICLE CATEGORIES';
export const DESELECT_ALL_INVENTORY_LOCATION_GROUPS = '[FLEET VEHICLE CATEGORIES APP] DESELECT ALL FLEET VEHICLE CATEGORIES';
export const OPEN_NEW_INVENTORY_LOCATION_GROUP_DIALOG = '[FLEET VEHICLE CATEGORIES APP] OPEN NEW INVENTORY LOCATION GROUP DIALOG';
export const CLOSE_NEW_INVENTORY_LOCATION_GROUP_DIALOG = '[FLEET VEHICLE CATEGORIES APP] CLOSE NEW INVENTORY LOCATION GROUP DIALOG';
export const OPEN_EDIT_INVENTORY_LOCATION_GROUP_DIALOG = '[FLEET VEHICLE CATEGORIES APP] OPEN EDIT INVENTORY LOCATION GROUP DIALOG';
export const CLOSE_EDIT_INVENTORY_LOCATION_GROUP_DIALOG = '[FLEET VEHICLE CATEGORIES APP] CLOSE EDIT INVENTORY LOCATION GROUP DIALOG';
export const ADD_INVENTORY_LOCATION_GROUP = '[FLEET VEHICLE CATEGORIES APP] ADD INVENTORY LOCATION GROUP';
export const UPDATE_INVENTORY_LOCATION_GROUP = '[FLEET VEHICLE CATEGORIES APP] UPDATE INVENTORY LOCATION GROUP';
export const REMOVE_INVENTORY_LOCATION_GROUP = '[FLEET VEHICLE CATEGORIES APP] REMOVE INVENTORY LOCATION GROUP';
export const REMOVE_INVENTORY_LOCATION_GROUPS = '[FLEET VEHICLE CATEGORIES APP] REMOVE FLEET VEHICLE CATEGORIES';
export const TOGGLE_STARRED_INVENTORY_LOCATION_GROUP = '[FLEET VEHICLE CATEGORIES APP] TOGGLE STARRED INVENTORY LOCATION GROUP';
export const TOGGLE_STARRED_INVENTORY_LOCATION_GROUPS = '[FLEET VEHICLE CATEGORIES APP] TOGGLE STARRED FLEET VEHICLE CATEGORIES';
export const SET_INVENTORY_LOCATION_GROUPS_STARRED = '[FLEET VEHICLE CATEGORIES APP] SET FLEET VEHICLE CATEGORIES STARRED ';

export function getInventoryLocationGroups(routeParams) {
    const request = axios.get('/api/inventoryLocationGroups-app/inventoryLocationGroups', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_INVENTORY_LOCATION_GROUPS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedInventoryLocationGroups(inventoryLocationGroupId) {
    return {
        type: TOGGLE_IN_SELECTED_INVENTORY_LOCATION_GROUPS,
        inventoryLocationGroupId
    }
}


export function selectAllInventoryLocationGroups() {
    return {
        type: SELECT_ALL_INVENTORY_LOCATION_GROUPS
    }
}

export function deSelectAllInventoryLocationGroups() {
    return {
        type: DESELECT_ALL_INVENTORY_LOCATION_GROUPS
    }
}


export function openNewInventoryLocationGroupDialog() {
    return {
        type: OPEN_NEW_INVENTORY_LOCATION_GROUP_DIALOG
    }
}

export function closeNewInventoryLocationGroupDialog() {
    return {
        type: CLOSE_NEW_INVENTORY_LOCATION_GROUP_DIALOG
    }
}

export function openEditInventoryLocationGroupDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/InventoryLocationGroup?Co=${data.Co}&Group=${encodeURIComponent(data.LocationGroup)}`);

        return request.then((response) =>
            dispatch({
                type: OPEN_EDIT_INVENTORY_LOCATION_GROUP_DIALOG,
                data: response.data
            }));
    }
}

export function closeEditInventoryLocationGroupDialog() {
    return {
        type: CLOSE_EDIT_INVENTORY_LOCATION_GROUP_DIALOG
    }
}

export function addInventoryLocationGroup(newInventoryLocationGroup) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/InventoryLocationGroup`,
            newInventoryLocationGroup
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_INVENTORY_LOCATION_GROUP
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Location Group #${newInventoryLocationGroup.LocationGroup} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateInventoryLocationGroup(inventoryLocationGroup) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/InventoryLocationGroup?Co=${encodeURIComponent(inventoryLocationGroup.Co)}&Group=${encodeURIComponent(inventoryLocationGroup.LocationGroup)}`,
            inventoryLocationGroup
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_INVENTORY_LOCATION_GROUP
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Location Group #${inventoryLocationGroup.LocationGroup} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeInventoryLocationGroup(inventoryLocationGroupId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationGroupsApp.inventoryLocationGroups;

        const request = axios.post('/api/inventoryLocationGroups-app/remove-inventoryLocationGroup', {
            inventoryLocationGroupId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_INVENTORY_LOCATION_GROUP
                })
            ]).then(() => dispatch(getInventoryLocationGroups(routeParams)))
        );
    };
}


export function removeInventoryLocationGroups(inventoryLocationGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationGroupsApp.inventoryLocationGroups;

        const request = axios.post('/api/inventoryLocationGroups-app/remove-inventoryLocationGroups', {
            inventoryLocationGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_INVENTORY_LOCATION_GROUPS
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATION_GROUPS
                })
            ]).then(() => dispatch(getInventoryLocationGroups(routeParams)))
        );
    };
}

export function toggleStarredInventoryLocationGroup(inventoryLocationGroupId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().inventoryLocationGroupsApp.inventoryLocationGroups;

        const request = axios.post('/api/inventoryLocationGroups-app/toggle-starred-inventoryLocationGroup', {
            inventoryLocationGroupId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_INVENTORY_LOCATION_GROUP
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocationGroups(routeParams)))
        );
    };
}

export function toggleStarredInventoryLocationGroups(inventoryLocationGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationGroupsApp.inventoryLocationGroups;

        const request = axios.post('/api/inventoryLocationGroups-app/toggle-starred-inventoryLocationGroups', {
            inventoryLocationGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_INVENTORY_LOCATION_GROUPS
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATION_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocationGroups(routeParams)))
        );
    };
}

export function setInventoryLocationGroupsStarred(inventoryLocationGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationGroupsApp.inventoryLocationGroups;

        const request = axios.post('/api/inventoryLocationGroups-app/set-inventoryLocationGroups-starred', {
            inventoryLocationGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_INVENTORY_LOCATION_GROUPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATION_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocationGroups(routeParams)))
        );
    };
}

export function setInventoryLocationGroupsUnstarred(inventoryLocationGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationGroupsApp.inventoryLocationGroups;

        const request = axios.post('/api/inventoryLocationGroups-app/set-inventoryLocationGroups-unstarred', {
            inventoryLocationGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_INVENTORY_LOCATION_GROUPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATION_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocationGroups(routeParams)))
        );
    };
}
