import { Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import moment from 'moment';
import _ from '@lodash';

const styles = theme => ({
    blue: {
        backgroundColor: '#4dbce9',
        color: '#fff',
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
    },
});
const initialState = {
    frequency: null,
    annual: null,
    semiAnnual: {},
    quarterly: {},
    biMonthly: {},
    monthly: {},
};

class Recurrence extends Component {
    state = { ...initialState };

    componentDidMount() {
        const { frequency, start, data, value, disabled } = this.props;
        window["warn"]("recurrence 123 CDM:", frequency, this.props.recID, start)
        let { annual, semiAnnual, quarterly, biMonthly, monthly } = initialState;
        if (start) {
            switch (frequency) {
                case 'A': {
                    annual = moment(data && data[0] ? data[0][value] : start).month() + 1;
                    if (!disabled){
                        this.props.onChange(annual);
                    }
                }
                    break;
                case 'S': {
                    // Need to sort
                    if (data && data.length >= 2 && value) {
                        const arr = _.map(_.take(data, 2), (o) => { return new Date(o[value]).getMonth() + 1 }).sort((a, b) => a - b);
                        arr.map((num, index) => {
                            semiAnnual[`S${index + 1}`] = num;
                        })
                        if (!disabled){
                            this.props.onChange(semiAnnual);
                        }
                    } else {
                        for (var i = 0; i < 2; i++) {
                            let dt = new Date(start);
                            dt.setMonth(dt.getMonth() + (i * 6));
                            const mth = dt.getMonth() + 1;
                            semiAnnual[`S${mth > 6 ? 2 : 1}`] = mth;
                        }
                        if (!disabled){
                            this.props.onChange(semiAnnual);
                        }
                    }
                }
                    break;
                case 'Q': {
                    if (data && data.length >= 4 && value) {
                        const arr = _.map(_.take(data, 4), (o) => { return new Date(o[value]).getMonth() + 1 }).sort((a, b) => a - b);
                        arr.map((num, index) => {
                            quarterly[`Q${index + 1}`] = num;
                        })
                        if (!disabled){
                            this.props.onChange(quarterly);
                        }
                    } else {
                        for (var i = 0; i < 4; i++) {
                            let dt = new Date(start);
                            dt.setMonth(dt.getMonth() + (i * 3));
                            const mth = dt.getMonth() + 1;
                            quarterly[`Q${mth < 4 ? 1 : mth < 7 ? 2 : mth < 10 ? 3 : 4}`] = mth;
                        }
                        if (!disabled){
                            this.props.onChange(quarterly);
                        }
                    }
                }
                    break;
                case 'B': {
                    if (data && data.length >= 6 && value) {
                        const arr = _.map(_.take(data, 6), (o) => { return new Date(o[value]).getMonth() + 1 }).sort((a, b) => a - b);
                        arr.map((num, index) => {
                            biMonthly[`B${index + 1}`] = num;
                        })
                        if (!disabled){
                            this.props.onChange(biMonthly);
                        }
                    } else {
                        for (var i = 0; i < 6; i++) {
                            let dt = new Date(start);
                            dt.setMonth(dt.getMonth() + (i * 2));
                            const mth = dt.getMonth() + 1;
                            biMonthly[`B${mth < 3 ? 1 : mth < 5 ? 2 : mth < 7 ? 3 : mth < 9 ? 4 : mth < 11 ? 5 : 6}`] = mth;
                        }
                        if (!disabled){
                            this.props.onChange(biMonthly);
                        }
                    }
                }
                    break;
                case 'M': {
                    for (var i = 1; i < 13; i++) {
                        monthly[`M${i}`] = i;
                    }
                    if (!disabled){
                        this.props.onChange(monthly);
                    }
                }
                    break;
                default:
                    {
                        annual = moment(data && data[0] ? data[0][value] : start).month() + 1;
                        if (!disabled){
                            this.props.onChange(annual);
                        }
                    }
            }
        }
        this.setState({ frequency, start, annual, semiAnnual, quarterly, biMonthly, monthly });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { frequency, units, start, disabled } = this.props;
        if (!_.isEqual(frequency, prevProps.frequency) || !_.isEqual(start, prevProps.start) || !_.isEqual(units, prevProps.units)) {
            let { annual, semiAnnual, quarterly, biMonthly, monthly } = !_.isEqual(frequency, prevProps.frequency) ? _.cloneDeepWith(initialState) : this.state;
            if (start && (!_.isEqual(frequency, prevProps.frequency) || !_.isEqual(start, prevProps.start))) {
                window["warn"]('Recurrence Component Did Update: ', start, frequency);
                switch (frequency) {
                    case 'A': {
                        annual = moment(start).month() + 1;
                        if (!disabled){
                            this.props.onChange(annual);
                        }
                    }
                        break;
                    case 'S': {
                        for (var i = 0; i < 2; i++) {
                            let dt = new Date(start);
                            dt.setMonth(dt.getMonth() + (i * 6));
                            const mth = dt.getMonth() + 1;
                            semiAnnual[`S${mth > 6 ? 2 : 1}`] = mth;
                        }
                        if (!disabled){
                            this.props.onChange(semiAnnual);
                        }
                    }
                        break;
                    case 'Q': {
                        for (var i = 0; i < 4; i++) {
                            let dt = new Date(start);
                            dt.setMonth(dt.getMonth() + (i * 3));
                            const mth = dt.getMonth() + 1;
                            quarterly[`Q${mth < 4 ? 1 : mth < 7 ? 2 : mth < 10 ? 3 : 4}`] = mth;
                        }
                        if (!disabled){
                            this.props.onChange(quarterly);
                        }
                    }
                        break;
                    case 'B': {
                        for (var i = 0; i < 6; i++) {
                            let dt = new Date(start);
                            dt.setMonth(dt.getMonth() + (i * 2));
                            const mth = dt.getMonth() + 1;
                            biMonthly[`B${mth < 3 ? 1 : mth < 5 ? 2 : mth < 7 ? 3 : mth < 9 ? 4 : mth < 11 ? 5 : 6}`] = mth;
                        }
                        if (!disabled){
                            this.props.onChange(biMonthly);
                        }
                    }
                        break;
                    case 'M': {
                        for (var i = 1; i < 13; i++) {
                            monthly[`M${i}`] = i;
                        }
                        if (!disabled){
                            this.props.onChange(monthly);
                        }
                    }
                        break;
                    default:
                        {
                            annual = moment(start).month() + 1;
                            if (!disabled){
                                this.props.onChange(annual);
                            }
                        }
                }
            }
            this.setState({ frequency, start, annual, semiAnnual, quarterly, biMonthly, monthly });
        }
    }

    render() {
        const { classes, } = this.props;
        let { frequency, annual, semiAnnual, quarterly, biMonthly } = this.state;
        const schedule = { 'A': 'annual', 'S': 'semiAnnual', 'Q': 'quarterly', 'B': 'biMonthly' };

        if (frequency === 'A') {
            return (
                <div className="w-full flex">
                    <div className="w-full flex min-w-512">
                        <div className="w-full flex min-w-512">
                            <div onClick={() => { annual = 1; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 1 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                                {annual === 1 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Jan
                            </div>
                            <div onClick={() => { annual = 2; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 2 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 2 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Feb
                            </div>
                            <div onClick={() => { annual = 3; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 3 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 3 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Mar
                            </div>
                            <div onClick={() => { annual = 4; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 4 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 4 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Apr
                            </div>
                            <div onClick={() => { annual = 5; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 5 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 5 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                May
                            </div>
                            <div onClick={() => { annual = 6; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 6 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 6 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Jun
                            </div>
                            <div onClick={() => { annual = 7; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 7 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 7 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Jul
                            </div>
                            <div onClick={() => { annual = 8; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 8 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 8 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Aug
                            </div>
                            <div onClick={() => { annual = 9; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 9 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 9 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Sep
                            </div>
                            <div onClick={() => { annual = 10; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 10 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 10 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Oct
                            </div>
                            <div onClick={() => { annual = 11; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(annual === 11 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                                {annual === 11 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Nov
                            </div>
                            <div onClick={() => { annual = 12; this.setState({ ...this.state, annual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(annual === 12 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                                {annual === 12 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                                Dec
                            </div>
                        </div>
                    </div>
                </div>
            )
        };

        if (frequency === 'S') {
            return (
                <div className="w-full flex">
                    <div className="w-full flex min-w-248" style={{ marginRight: 2 }}>
                        <div onClick={() => { semiAnnual['S1'] = 1; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S1'] === 1 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {semiAnnual['S1'] === 1 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jan
                        </div>
                        <div onClick={() => { semiAnnual['S1'] = 2; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S1'] === 2 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S1'] === 2 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Feb
                        </div>
                        <div onClick={() => { semiAnnual['S1'] = 3; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S1'] === 3 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S1'] === 3 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Mar
                        </div>
                        <div onClick={() => { semiAnnual['S1'] = 4; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S1'] === 4 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S1'] === 4 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Apr
                        </div>
                        <div onClick={() => { semiAnnual['S1'] = 5; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S1'] === 5 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S1'] === 5 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            May
                        </div>
                        <div onClick={() => { semiAnnual['S1'] = 6; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(semiAnnual['S1'] === 6 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {semiAnnual['S1'] === 6 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jun
                        </div>
                    </div>
                    <div className="w-full flex min-w-248">
                        <div onClick={() => { semiAnnual['S2'] = 7; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S2'] === 7 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {semiAnnual['S2'] === 7 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jul
                        </div>
                        <div onClick={() => { semiAnnual['S2'] = 8; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S2'] === 8 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S2'] === 8 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Aug
                        </div>
                        <div onClick={() => { semiAnnual['S2'] = 9; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S2'] === 9 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S2'] === 9 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Sep
                        </div>
                        <div onClick={() => { semiAnnual['S2'] = 10; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S2'] === 10 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S2'] === 10 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Oct
                        </div>
                        <div onClick={() => { semiAnnual['S2'] = 11; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(semiAnnual['S2'] === 11 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {semiAnnual['S2'] === 11 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Nov
                        </div>
                        <div onClick={() => { semiAnnual['S2'] = 12; this.setState({ ...this.state, semiAnnual }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(semiAnnual['S2'] === 12 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {semiAnnual['S2'] === 12 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Dec
                        </div>
                    </div>
                </div>
            );
        }
        if (frequency === 'Q') {
            return (
                <div className="w-full flex">
                    <div className="w-full flex min-w-124" style={{ marginRight: 2 }}>
                        <div onClick={() => { quarterly['Q1'] = 1; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(quarterly['Q1'] === 1 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {quarterly['Q1'] === 1 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jan
                        </div>
                        <div onClick={() => { quarterly['Q1'] = 2; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(quarterly['Q1'] === 2 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {quarterly['Q1'] === 2 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Feb
                        </div>
                        <div onClick={() => { quarterly['Q1'] = 3; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderLeft: '1px solid #fff' }} className={classNames(quarterly['Q1'] === 3 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {quarterly['Q1'] === 3 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Mar
                        </div>
                    </div>
                    <div className="w-full flex min-w-124" style={{ marginRight: 2 }}>
                        <div onClick={() => { quarterly['Q2'] = 4; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(quarterly['Q2'] === 4 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {quarterly['Q2'] === 4 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Apr
                        </div>
                        <div onClick={() => { quarterly['Q2'] = 5; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(quarterly['Q2'] === 5 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {quarterly['Q2'] === 5 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            May
                        </div>
                        <div onClick={() => { quarterly['Q2'] = 6; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderLeft: '1px solid #fff' }} className={classNames(quarterly['Q2'] === 6 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {quarterly['Q2'] === 6 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jun
                        </div>
                    </div>
                    <div className="w-full flex min-w-124" style={{ marginRight: 2 }}>
                        <div onClick={() => { quarterly['Q3'] = 7; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(quarterly['Q3'] === 7 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {quarterly['Q3'] === 7 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jul
                        </div>
                        <div onClick={() => { quarterly['Q3'] = 8; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(quarterly['Q3'] === 8 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {quarterly['Q3'] === 8 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Aug
                        </div>
                        <div onClick={() => { quarterly['Q3'] = 9; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderLeft: '1px solid #fff' }} className={classNames(quarterly['Q3'] === 9 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {quarterly['Q3'] === 9 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Sep
                        </div>
                    </div>
                    <div className="w-full flex min-w-124">
                        <div onClick={() => { quarterly['Q4'] = 10; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(quarterly['Q4'] === 10 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {quarterly['Q4'] === 10 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Oct
                        </div>
                        <div onClick={() => { quarterly['Q4'] = 11; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(quarterly['Q4'] === 11 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center cursor-pointer font-bold")}>
                            {quarterly['Q4'] === 11 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Nov
                        </div>
                        <div onClick={() => { quarterly['Q4'] = 12; this.setState({ ...this.state, quarterly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderLeft: '1px solid #fff' }} className={classNames(quarterly['Q4'] === 12 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {quarterly['Q4'] === 12 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Dec
                        </div>
                    </div>
                </div>
            );
        }
        if (frequency === 'B') {
            return (
                <div className="w-full flex">
                    <div className="w-full flex min-w-84" style={{ marginRight: 2 }}>
                        <div onClick={() => { biMonthly['B1'] = 1; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(biMonthly['B1'] === 1 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {biMonthly['B1'] === 1 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jan
                        </div>
                        <div onClick={() => { biMonthly['B1'] = 2; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(biMonthly['B1'] === 2 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {biMonthly['B1'] === 2 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Feb
                        </div>
                    </div>
                    <div className="w-full flex min-w-84" style={{ marginRight: 2 }}>
                        <div onClick={() => { biMonthly['B2'] = 3; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(biMonthly['B2'] === 3 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {biMonthly['B2'] === 3 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Mar
                        </div>
                        <div onClick={() => { biMonthly['B2'] = 4; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(biMonthly['B2'] === 4 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full rounder-l-full cursor-pointer font-bold")}>
                            {biMonthly['B2'] === 4 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Apr
                        </div>
                    </div>
                    <div className="w-full flex min-w-84" style={{ marginRight: 2 }}>
                        <div onClick={() => { biMonthly['B3'] = 5; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(biMonthly['B3'] === 5 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {biMonthly['B3'] === 5 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            May
                        </div>
                        <div onClick={() => { biMonthly['B3'] = 6; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(biMonthly['B3'] === 6 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {biMonthly['B3'] === 6 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jun
                        </div>
                    </div>
                    <div className="w-full flex min-w-84" style={{ marginRight: 2 }}>
                        <div onClick={() => { biMonthly['B4'] = 7; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(biMonthly['B4'] === 7 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {biMonthly['B4'] === 7 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Jul
                        </div>
                        <div onClick={() => { biMonthly['B4'] = 8; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(biMonthly['B4'] === 8 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {biMonthly['B4'] === 8 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Aug
                        </div>
                    </div>
                    <div className="w-full flex min-w-84" style={{ marginRight: 2 }}>
                        <div onClick={() => { biMonthly['B5'] = 9; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(biMonthly['B5'] === 9 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {biMonthly['B5'] === 9 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Sep
                        </div>
                        <div onClick={() => { biMonthly['B5'] = 10; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(biMonthly['B5'] === 10 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {biMonthly['B5'] === 10 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Oct
                        </div>
                    </div>
                    <div className="w-full flex min-w-84" style={{ marginRight: 2 }}>
                        <div onClick={() => { biMonthly['B6'] = 11; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} style={{ borderRight: '1px solid #fff' }} className={classNames(biMonthly['B6'] === 11 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-l-full cursor-pointer font-bold")}>
                            {biMonthly['B6'] === 11 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Nov
                        </div>
                        <div onClick={() => { biMonthly['B6'] = 12; this.setState({ ...this.state, biMonthly }, () => this.props.onChange(this.state[schedule[this.state.frequency]], true)); }} className={classNames(biMonthly['B6'] === 12 ? classes.blue : classes.black, "w-full h-24 p-4 text-10 text-center rounded-r-full cursor-pointer font-bold")}>
                            {biMonthly['B6'] === 12 && <Icon className="text-12 align-middle" style={{ marginLeft: -2, marginRight: 2, marginBottom: 2 }}>check</Icon>}
                            Dec
                        </div>
                    </div>
                </div>
            );
        }
        return '';
    }
}


export default withStyles(styles, { withTheme: true })(Recurrence);
