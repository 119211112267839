import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import moment from 'moment';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    disabled: {
        color: 'lightgrey',
    }

});

class WorkOrderUsagesList extends Component {

    state = {
        selectedWorkOrderUsagesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedWorkOrderUsageMenu = (event) => {
        this.setState({ selectedWorkOrderUsagesMenu: event.currentTarget });
    };

    closeSelectedWorkOrderUsagesMenu = () => {
        this.setState({ selectedWorkOrderUsagesMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    render() {
        const { classes, assignments, workOrderUsageTypes, workOrder, scope, user, searchText, costTypes, selectedWorkOrderUsageIds, selectAllWorkOrderUsages, deSelectAllWorkOrderUsages, technicians, toggleInSelectedWorkOrderUsages, openEditWorkOrderUsageDialog, removeWorkOrderUsages, removeWorkOrderUsage, toggleStarredWorkOrderUsage, setWorkOrderUsagesUnstarred, setWorkOrderUsagesStarred, readOnly } = this.props;
        const data = this.getFilteredArray((!this.props.selectedWorkOrderUsageData ? !this.props.workOrderUsageData ? [] : this.props.workOrderUsageData : this.props.selectedWorkOrderUsageData), searchText);
        const { selectedWorkOrderUsagesMenu } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';

        return (
            <FuseAnimate animation={this.props.animation || "transition.slideUpIn"} delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center', display: readOnly ? 'none' : 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_bulleted</Icon>Usage</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            const assigned = assignments && rowInfo ? _.find(assignments, {Scope: rowInfo.original.Scope}) : null;
                            return {
                                className: classNames("cursor-pointer", (assignments && !assigned) && classes.disabled),
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        if (!assignments || assigned) {
                                            openEditWorkOrderUsageDialog({ ...rowInfo.original, Data: { WorkOrder: workOrder, Scope: scope, Assignments: assignments} }, undefined, readOnly);
                                        }
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Work Order",
                                accessor: "WorkOrder",
                                className: "font-bold justify-center",
                                show: false,
                            },
                            {
                                Header: "Line",
                                accessor: "Line",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Scope",
                                accessor: "Scope",
                                className: "justify-center"
                            },
                            {
                                Header: "Type",
                                accessor: "Type",
                                minWidth: 192,
                                className: "justify-center",
                                Cell: row => {
                                    return workOrderUsageTypes.map((value, index) => (
                                        value.UsageType === row.original.Type &&
                                        `${value.UsageType} - ${value.Name}`
                                    ));
                                }
                            },
                            {
                                Header: "Status",
                                accessor: "Status",
                                minWidth: 128,
                                className: "justify-center",
                            },
                            {
                                Header: "Qty",
                                accessor: "Qty",
                                className: "justify-center",
                                minWidth: 64,
                            },
                            {
                                Header: "UOM",
                                accessor: "UOM",
                                className: "justify-center",
                                minWidth: 64,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 256,
                            },
                            {
                                Header: "Technician",
                                accessor: "Technician",
                                minWidth: 256,
                                Cell: row => {
                                    return technicians.map((value, index) => (
                                        value.Technician === row.original.Technician &&
                                        <React.Fragment key={index}>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                            {
                                Header: "Date",
                                accessor: "UsageDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.CostDate &&
                                    moment(moment(row.original.UsageDate)).local().toDate().toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Cost Type",
                                accessor: "CostType",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => {
                                    return costTypes.map((value, index) => (
                                        value.CostType === row.original.CostType &&
                                        `${value.CostType} - ${value.Name}`
                                    ));
                                }
                            },
                            {
                                Header: "Unit Cost",
                                accessor: "UnitCost",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => {
                                    return `$${row.original.UnitCost ? parseFloat(row.original.UnitCost).toFixed(2) : '0.00'}`;
                                }
                            },
                            {
                                Header: "Total Cost",
                                accessor: "CostTotal",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => {
                                    return `$${row.original.CostTotal ? parseFloat(row.original.CostTotal).toFixed(2) : '0.00'}`;
                                }
                            },
                            {
                                Header: "Unit Price",
                                accessor: "UnitPrice",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => {
                                    return `$${row.original.UnitPrice ? parseFloat(row.original.UnitPrice).toFixed(2) : '0.00'}`;
                                }
                            },
                            {
                                Header: "Total Price",
                                accessor: "PriceTotal",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => {
                                    return `$${row.original.PriceTotal ? parseFloat(row.original.PriceTotal).toFixed(2) : '0.00'}`;
                                }
                            },
                            {
                                Header: "Tax Code",
                                accessor: "TaxCode",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Tax Rate",
                                accessor: "TaxRate",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => {
                                    return `${row.original.TaxRate ? parseFloat(row.original.TaxRate).toFixed(4) : '0.00'}%`;
                                }
                            },
                            {
                                Header: "Tax Total",
                                accessor: "TaxTotal",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => {
                                    return `$${row.original.TaxTotal ? parseFloat(row.original.TaxTotal).toFixed(2) : '0.00'}`;
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Usage Lines found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWorkOrderUsages: Actions.getWorkOrderUsages,
        getUserData: Actions.getUserData,
        toggleInSelectedWorkOrderUsages: Actions.toggleInSelectedWorkOrderUsages,
        selectAllWorkOrderUsages: Actions.selectAllWorkOrderUsages,
        deSelectAllWorkOrderUsages: Actions.deSelectAllWorkOrderUsages,
        openEditWorkOrderUsageDialog: Actions.openEditWorkOrderUsageDialog,
        removeWorkOrderUsages: Actions.removeWorkOrderUsages,
        removeWorkOrderUsage: Actions.removeWorkOrderUsage,
        toggleStarredWorkOrderUsage: Actions.toggleStarredWorkOrderUsage,
        toggleStarredWorkOrderUsages: Actions.toggleStarredWorkOrderUsages,
        setWorkOrderUsagesStarred: Actions.setWorkOrderUsagesStarred,
        setWorkOrderUsagesUnstarred: Actions.setWorkOrderUsagesUnstarred
    }, dispatch);
}

function mapStateToProps({ workOrderUsagesApp, spReducers }) {
    return {
        searchText: workOrderUsagesApp.workOrderUsages.searchText,
        user: spReducers.userProfiles.User.UserName,
        workOrderUsageTypes: spReducers.workOrderUsageTypes,
        technicians: spReducers.technicians,
        costTypes: spReducers.costTypes,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderUsagesList)));
