import axios from 'axios/index'
import { logIn } from 'store/actions'
import { useLocal } from 'version'
import Constants from '../../../Constants'
import {endpoint} from "../../../version";

export const GET_API_LOCATION = '[SP AUTHENTICATE] GET USER API LOCATION'

export function userLogIn(client, unm, pass, version, os) {
  if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common[Constants.SUBDOMAIN_HEADER_KEY] = client
  }

  return dispatch => {
    Promise.all([
      dispatch({
        type: GET_API_LOCATION,
        client,
        location: endpoint(client),
        error: null,
      }),
    ]).then(() => {
      dispatch(logIn(unm, pass, version, os))
    })
  }
}
