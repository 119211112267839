import React from 'react';
import {Redirect} from 'react-router-dom';
import TodoApp from './TodoApp';

export const TodoAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/to-do/label/:labelHandle',
            component: TodoApp
        },
        {
            path     : '/apps/to-do/filter/:filterHandle',
            component: TodoApp
        },
        {
            path     : '/apps/to-do/:folderHandle',
            component: TodoApp
        },
        {
            path     : '/apps/to-do',
            component: () => <Redirect to="/apps/to-do/all"/>
        }
    ]
};
