import React, { Component } from 'react';
import {
    Slide, Grow, TextField, Badge, Button, FormControlLabel, CircularProgress, Switch, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Tabs, Tab, Toolbar, Tooltip, AppBar, Avatar, GridList, GridListTile, GridListTileBar, Menu, MenuItem
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from '../service-unit-types/store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { impress } from 'main/content/compression/impress';
import { guid } from 'store/actions';
import axios from 'axios';
import _ from '@lodash';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {

    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 24
    },
    addButton: {
        float: 'right',
        marginTop: 8,
        zIndex: 3,
    },
    andMore: {
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/images/backgrounds/SP_Header.png)',
        backgroundPosition: 'center',
        backgroundColor: '#3f3f3f',
        color: '#fff',
        paddingBottom: '100%',
        borderRadius: 5,
        border: '1px dotted #3f3f3f',
    },
    searchResult: {
        paddingBottom: '100%',
        backgroundPosition: 'center !important',
        backgroundSize: 'cover !important',
        borderRadius: 5,
        cursor: 'pointer',
    },
    searchContainer: {
        minHeight: 512,
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'calc(100% + 12px)',
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    footerBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
            'rgba(0,0,0,0.15) 30%, rgba(0,0,0,0.3) 100%)',
    },
    icon: {
        color: 'white',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteImages: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
    }
});
const newCatalogCategoryImageState = {
    value: 0,
    images: [],
    videos: [],
    searchResults: [],
    selectedSearchImages: [],
    selectedAttachmentImages: [],
    selectedSearchImage: null,
    imageSearch: null,
    displayLimit: 36,
    anchorEl: null,
    searchImages: true,
};

class CatalogCategoryImageDialog extends Component {
    state = { ...newCatalogCategoryImageState };

    componentDidMount() {
        const { category } = this.props;
        if (category) {
            const req = axios.get(`${window["apiLocation"]}/api/Blob/GetFolder?filepath=${encodeURIComponent(category)}`);
            req.then((res) => {
                const images = [];
                res.data.map((value) => {
                    images.push(value);
                })
                this.setState({ ...this.state, images });
            })
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    closeComposeDialog = () => {
        // this.props.catalogCategoryImageDialog.type === 'edit' ? this.props.closeEditCatalogCategoryImageDialog() : this.props.closeNewCatalogCategoryImageDialog();
        this.props.onClose();
    };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    fileSelectedHandler = (event) => {
        const { category, onChange } = this.props;
        impress(event.target.files[0], 1024, 50, false, (img) => {
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Blob/PostBlob?filepath=${encodeURIComponent(category)}`, fd).then((res) => {
                Object.keys(res.data).map((value, index) => {
                    Promise.all([
                        onChange(res.data[value].src)
                    ]).then(() => this.closeComposeDialog());
                });
            });
        })
    }

    selectUploadedImage = (img) => {
        let { onChange } = this.props;
        Promise.all([
            onChange(img)
        ]).then(() => this.closeComposeDialog());
    }

    searchImages = () => {
        const { imageSearch, Description } = this.state;
        axios.post(`${window["apiLocation"]}/api/MLImages/ImageSearch?Term=${imageSearch ? imageSearch : Description}`).then((res) => {
            this.setState({ ...this.state, imageSearch: imageSearch ? imageSearch : Description, displayLimit: 36, searchResults: res.data.value });
        });

    }

    increaseDisplayLimit = () => {
        let { displayLimit } = this.state;
        displayLimit += 36;
        this.setState({ ...this.state, displayLimit });
    }

    selectSearchResult = (img) => {
        let { onChange, category } = this.props;
        Promise.all([
            onChange(img.thumbnailUrl)
        ]).then(() => this.closeComposeDialog());
    }

    viewSearchResult = (img) => {
        this.setState({ ...this.state, selectedSearchImage: img });
    }

    viewAttachment = (img) => {
        this.setState({ ...this.state, selectedAttachmentImage: img });
    }

    viewCategoryImage = (img) => {
        this.setState({ ...this.state, selectedCategoryImage: img });
    }

    viewUploadedCategoryImage = (img) => {
        this.setState({ ...this.state, uploadedCategoryImage: img });
    }

    selectCategoryImage = (img) => {
        let { images } = this.state;
        const index = _.findIndex(images, img.thumbnailUrl ? { thumbnailUrl: img.thumbnailUrl } : img.Attachment ? { Attachment: img.Attachment } : img.fileName && { fileName: img.fileName });
        if (index > -1) {
            images.splice(index, 1);
        } else {
            images.push(img);
        }
        this.setState({ ...this.state, images });
    }

    render() {
        const { classes } = this.props;
        const { value, images, searchResults, selectedSearchImage, displayLimit, searchImages } = this.state;

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        open={this.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="sm"
                        fullScreen={ !matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    Select Image
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="fullWidth"
                            >
                                <Tab disableRipple icon={<Icon>cloud_upload</Icon>} label="Upload" />
                                <Tab disableRipple icon={<Icon>search</Icon>} label="Search" />
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                            <DialogContent className="py-12 px-24">
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">cloud_upload</Icon>Uploaded Images</Typography>
                                <div className={classes.searchContainer}>
                                    <div className="w-full">
                                        <div className="w-full sm:flex">
                                            <div className="w-full mr-12">
                                                {images.map((image) => {
                                                    return <img className="w-full mb-4 p-12 rounded-6 cursor-pointer" onClick={() => this.selectUploadedImage(image.src)} style={{ border: '1px dotted lightgrey' }} src={image.src} />
                                                })}
                                            </div>
                                            <div onClick={() => { this.fileInput.click(); }} className="w-full h-auto min-h-128 mb-24 relative cursor-pointer rounded-6" style={{ border: '2px dotted lightgrey' }}><Icon className="pin-l pin-r pin-t pin-b absolute m-auto" color="primary">cloud_upload</Icon></div>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                onChange={this.fileSelectedHandler}
                                                ref={fileInput => this.fileInput = fileInput}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                        }
                        {value === 1 &&
                            <DialogContent className="py-12 px-24">
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">search</Icon>Image Search</Typography>
                                <input
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={this.fileSelectedHandler}
                                    ref={fileInput => this.fileInput = fileInput}
                                />
                                {searchImages &&
                                    <div className="flex">
                                        <TextField
                                            className="mb-16"
                                            label="Search"
                                            id="imageSearch"
                                            name="imageSearch"
                                            value={this.state.imageSearch}
                                            InputLabelProps={{ shrink: this.state.imageSearch ? true : false }}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <div className="min-w-12 pt-20">
                                        </div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="mb-16"
                                            onClick={this.searchImages}
                                        >
                                            Search
                                    </Button>
                                    </div>
                                }
                                {searchResults && searchResults[selectedSearchImage] &&
                                    <Dialog
                                        open={searchResults && searchResults[selectedSearchImage]}
                                        onClose={() => this.setState({ ...this.state, selectedSearchImage: null })}
                                        fullWidth
                                        maxWidth="sm"
                                    >
                                        <IconButton style={{
                                            position: 'absolute',
                                            right: 10,
                                            top: 10,
                                            zIndex: 3,
                                            color: 'white'
                                        }}
                                            className={classes.closeIcon}
                                            onClick={() => this.setState({ ...this.state, selectedSearchImage: null })}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                        {(searchImages && selectedSearchImage > 0) &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, selectedSearchImage: selectedSearchImage - 1 })}
                                            >
                                                <Icon className="text-32">chevron_left</Icon>
                                            </IconButton>
                                        }
                                        {(searchImages && (selectedSearchImage < (searchResults.length - 1)) && selectedSearchImage < displayLimit - 1) &&
                                            <IconButton style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 'calc(50% - 12px)',
                                                zIndex: 3,
                                                color: 'white'
                                            }}
                                                className={classes.closeIcon}
                                                onClick={() => this.setState({ ...this.state, selectedSearchImage: selectedSearchImage + 1 })}
                                            >
                                                <Icon className="text-32">chevron_right</Icon>
                                            </IconButton>
                                        }
                                        <img className="w-full" src={searchResults[selectedSearchImage].contentUrl} />
                                        <GridListTileBar
                                            title={searchResults[selectedSearchImage].contentUrl}
                                            titlePosition="top"
                                            actionIcon={
                                                <IconButton onClick={() => this.selectSearchResult(searchResults[selectedSearchImage])} className={_.findIndex(images, { thumbnailUrl: searchResults[selectedSearchImage].thumbnailUrl }) > -1 ? classes.selectedIcon : classes.icon}>
                                                    <Icon>{_.findIndex(images, { thumbnailUrl: searchResults[selectedSearchImage].thumbnailUrl }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                            className={classNames(classes.titleBar, "pt-12")}
                                        />
                                    </Dialog>
                                }
                                {searchImages &&
                                    <div className={classes.searchContainer}>
                                        <GridList cols={matches ? 4 : 3} spacing={12} cellHeight="auto" className={classes.gridList}>
                                            {searchResults.map((img, index) => (
                                                index < displayLimit &&
                                                <GridListTile key={index} cols={1} rows={1}>
                                                    <div onClick={() => this.viewSearchResult(index)} className={classes.searchResult} style={{ background: `url(${img.thumbnailUrl})` }} />
                                                    <GridListTileBar
                                                        title={img.contentUrl}
                                                        titlePosition="top"
                                                        actionIcon={
                                                            <IconButton onClick={() => this.selectSearchResult(img)} className={_.findIndex(this.props.images, { ImgUrl: img.thumbnailUrl }) > -1 ? classes.selectedIcon : classes.icon}>
                                                                <Icon>{_.findIndex(this.props.images, { ImgUrl: img.thumbnailUrl }) > -1 ? "cloud_upload" : "cloud"}</Icon>
                                                            </IconButton>
                                                        }
                                                        actionPosition="left"
                                                        className={classes.titleBar}
                                                    />
                                                </GridListTile>
                                            ))}
                                        </GridList>
                                        {searchResults.length > displayLimit &&
                                            <Button onClick={this.increaseDisplayLimit} color="primary" variant="contained" className="w-full mt-12">Show More</Button>
                                        }
                                    </div>
                                }
                            </DialogContent>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogCategoryImageDialog));
