import axios from 'axios/index';

export const SET_REPORT_CATEGORY_DATA = '[SPCONNECTION] SET REPORT CATEGORY DATA';
export const GET_REPORT_CATEGORY_DATA = '[SPCONNECTION] GET REPORT CATEGORY DATA';
export const SET_DASHBOARD_SECURABLE_DATA = '[SPCONNECTION] SET DASHBOARD SECURABLE DATA';
export const GET_DASHBOARD_SECURABLE_DATA = '[SPCONNECTION] GET DASHBOARD SECURABLE DATA';
export const SET_REPORT_SECURABLE_DATA = '[SPCONNECTION] SET REPORT SECURABLE DATA';
export const GET_REPORT_SECURABLE_DATA = '[SPCONNECTION] GET REPORT SECURABLE DATA';
export const SUBSCRIBE_REPORT_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE REPORT CATEGORY LIST';

export function getInitialReportCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ReportCategory`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getReportCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ReportCategory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_REPORT_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function getReportSecurableData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/UserRoleReportSecurable`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_REPORT_SECURABLE_DATA,
                payload: response.data
            })
        });
    }
}

export function getDashboardSecurableData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/UserRoleDashboardSecurable`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_DASHBOARD_SECURABLE_DATA,
                payload: response.data
            })
        });
    }
}

export function setReportCategoryData(data) {
    return {
        type: SET_REPORT_CATEGORY_DATA,
        payload: data
    };
}