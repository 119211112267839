import axios from 'axios/index';

export const SET_SERVICE_DATA = '[SPCONNECTION] SET SERVICE DATA';
export const GET_SERVICE_DATA = '[SPCONNECTION] GET SERVICE DATA';
export const SUBSCRIBE_SERVICE_LIST = '[SPCONNECTION] SUBSCRIBE SERVICE LIST';

export function getInitialServiceData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Service`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getServiceData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Service`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_SERVICE_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_SERVICE_LIST,
            Co
        }));
    }
}

export function setServiceData(data) {
    return {
        type: SET_SERVICE_DATA,
        payload: data
    };
}