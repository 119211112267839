import _ from '@lodash';
import { Grow, Slide, Button, Divider, Dialog, DialogContent, Icon, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Media from 'react-media';
import Autocomplete from '../../../components/autocomplete/Autocomplete';
import PivotTable from '../../reporting/PivotTable';
import Tile from './Tile';
import Chart from './Chart';
import { DatePicker } from '@material-ui/pickers';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
    },
    component: {
        cursor: 'pointer',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        background: '#fff',
        color: '#333',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    newComponent: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    },
    dialogRoot: {

    },
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    }
});

const newComponentState = {
    ID: null,
    Co: null,
    Component: null,
    Name: null,
    Type: null,
    Report: null,
    Options: null,
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    edit: true,
}

class DashboardComponentDialog extends Component {

    state = {
        ...newComponentState
    };

    componentDidMount() {
        const { component } = this.props;
        this.setState({ ...newComponentState, ...(component || {}) });
    }

    componentDidUpdate(prevProps, prevState) {
        const { component } = this.props;
        if (!_.isEqual(component, prevProps.component)) {
            this.setState({ ...newComponentState, ...(component || {}) });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped nowhere
        if (!destination) {
            return;
        }

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // // reordering list
        // if (result.type === 'list') {
        //     this.props.reorderList(result);
        // }

        // // reordering card
        // if (result.type === 'card') {
        //     this.props.reorderCard(result);
        // }
    };

    toggleSettingsDrawer = (state) => {
        state = (state === undefined) ? !this.state.settingsDrawerOpen : state;

        this.setState({
            settingsDrawerOpen: state
        });
    };

    setEditor = (editor) => {
        if (editor) {
            this.setState({ ...this.state, editor });
        }
    }

    updateDashboard = () => {
        const { updateDashboard } = this.props;
        updateDashboard(this.state);
        this.toggleMenu();
    }

    toggleMenu = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({ ...this.state, menuOpen: !this.state.menuOpen });
    }

    closeDialog = () => {
        const { onClose } = this.props;
        this.setState({ ...newComponentState }, onClose);
    }

    toggleEdit = () => {
        this.setState({ ...this.state, edit: !this.state.edit })
    }

    addComponent = (Options) => {
        const { Co } = this.props;
        window["warn"](Options);
        if (this.props.onAdded) {
            this.setState({ ...this.state, Co, Options, Data: { ErrMsg: null } }, () => this.props.onAdded(this.state));
        }
    }

    updateComponent = (Options) => {
        window["warn"](Options);
        if (this.props.onUpdated) {
            this.setState({ ...this.state, Options }, () => this.props.onUpdated(this.state));
        }
    }

    render() {
        const { classes, securables, securablesList, dataSources, reports } = this.props;
        const { Co, Component, Name, Report, Type, Options, Notes, edit } = this.state;
        const rpt = Report ? _.find(dataSources, { Report }) : null;
        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.dialogRoot,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.dialogRoot}
                        open={this.props.open}
                        onClose={this.closeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                    >
                        <DialogContent className="relative dialog-header">
                            <Typography className="w-full px-4 font-bold text-14 cursor-pointer mb-16 relative">
                                <Icon color="primary" className="mr-4 align-middle mb-4">insert_chart</Icon>
                                {Name ? Name : 'New Component'}
                                <IconButton
                                    className="m-0 p-0 ml-12"
                                    onClick={() => {
                                        this.toggleEdit();
                                    }}
                                >
                                    <Icon className="align-middle">{edit ? "expand_less" : "expand_more"}</Icon>
                                </IconButton>
                            </Typography>
                            <IconButton style={{
                                position: 'absolute',
                                right: 6,
                                top: matches ? 0 : -12,
                            }}
                                onClick={() => {
                                    this.closeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Divider className="mb-24" />
                            {edit &&
                                <div className="w-full sm:flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Name"
                                        id="name"
                                        name="Name"
                                        value={Name || ''}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            autoComplete: "off"
                                        }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Component Type"
                                        id="type"
                                        name="Type"
                                        value={Type || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value="P">
                                            Pivot Table
                                        </MenuItem>
                                        <MenuItem value="C">
                                            Chart
                                        </MenuItem>
                                        <MenuItem value="T">
                                            Tile
                                        </MenuItem>
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Data Source"
                                        options={[...dataSources]}
                                        menuItemComponent={(value) => {
                                            const report = _.find(reports, { Report: value.Report });
                                            return (
                                                <MenuItem value={value.Report}>
                                                    <div className="flex">
                                                        {`${report.Name}`}
                                                    </div>
                                                </MenuItem>
                                            );
                                        }}
                                        value={Report}
                                        onSelect={(option) => this.setState({ ...this.state, Report: option.Report })}
                                        required
                                    />
                                </div>
                            }
                            {Type === 'P' && rpt &&
                                <PivotTable edit={true} dialog={true} results={[...rpt.Results]} options={this.props.component} onAdded={(opt) => this.addComponent(opt)} onUpdated={(opt) => this.updateComponent(opt)} />
                            }
                            {Type === 'C' && rpt &&
                                <Chart title={this.state.Name} edit={true} dialog={true} results={[...rpt.Results]} options={this.props.component} onAdded={(opt) => this.addComponent(opt)} onUpdated={(opt) => this.updateComponent(opt)} />
                            }
                            {Type === 'T' && rpt &&
                                <Tile title={this.state.Name} edit={true} dialog={true} results={[...rpt.Results]} options={this.props.component} onAdded={(opt) => this.addComponent(opt)} onUpdated={(opt) => this.updateComponent(opt)} />
                            }
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        dashboard: dashboardBuilderApp.dashboard,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        employees: spReducers.employees,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardComponentDialog)));
