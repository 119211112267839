import {combineReducers} from 'redux';
import serviceUnitCategories from './service-unit-categories.reducer';
import user from './user.reducer';

const serviceUnitCategoriesAppReducers = combineReducers({
    serviceUnitCategories,
    user
});

export default serviceUnitCategoriesAppReducers;
