import {combineReducers} from 'redux';
import arrivalWindows from './arrival-windows.reducer';
import user from './user.reducer';

const arrivalWindowsAppReducers = combineReducers({
    arrivalWindows,
    user
});

export default arrivalWindowsAppReducers;
