import axios from 'axios/index';
import { getUserData } from 'main/content/apps/service-types/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_SERVICE_TYPES = '[SERVICE TYPES APP] GET SERVICE TYPES';
export const SET_SEARCH_TEXT = '[SERVICE TYPES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SERVICE_TYPES = '[SERVICE TYPES APP] TOGGLE IN SELECTED SERVICE TYPES';
export const SELECT_ALL_SERVICE_TYPES = '[SERVICE TYPES APP] SELECT ALL SERVICE TYPES';
export const DESELECT_ALL_SERVICE_TYPES = '[SERVICE TYPES APP] DESELECT ALL SERVICE TYPES';
export const OPEN_NEW_SERVICE_TYPE_DIALOG = '[SERVICE TYPES APP] OPEN NEW SERVICE TYPE DIALOG';
export const CLOSE_NEW_SERVICE_TYPE_DIALOG = '[SERVICE TYPES APP] CLOSE NEW SERVICE TYPE DIALOG';
export const OPEN_EDIT_SERVICE_TYPE_DIALOG = '[SERVICE TYPES APP] OPEN EDIT SERVICE TYPE DIALOG';
export const CLOSE_EDIT_SERVICE_TYPE_DIALOG = '[SERVICE TYPES APP] CLOSE EDIT SERVICE TYPE DIALOG';
export const ADD_SERVICE_TYPE = '[SERVICE TYPES APP] ADD SERVICE TYPE';
export const UPDATE_SERVICE_TYPE = '[SERVICE TYPES APP] UPDATE SERVICE TYPE';
export const REMOVE_SERVICE_TYPE = '[SERVICE TYPES APP] REMOVE SERVICE TYPE';
export const REMOVE_SERVICE_TYPES = '[SERVICE TYPES APP] REMOVE SERVICE TYPES';
export const TOGGLE_STARRED_SERVICE_TYPE = '[SERVICE TYPES APP] TOGGLE STARRED SERVICE TYPE';
export const TOGGLE_STARRED_SERVICE_TYPES = '[SERVICE TYPES APP] TOGGLE STARRED SERVICE TYPES';
export const SET_SERVICE_TYPES_STARRED = '[SERVICE TYPES APP] SET SERVICE TYPES STARRED ';

export function getServiceTypes(routeParams) {
    const request = axios.get('/api/serviceTypes-app/serviceTypes', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_SERVICE_TYPES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedServiceTypes(serviceTypeId) {
    return {
        type: TOGGLE_IN_SELECTED_SERVICE_TYPES,
        serviceTypeId
    }
}


export function selectAllServiceTypes() {
    return {
        type: SELECT_ALL_SERVICE_TYPES
    }
}

export function deSelectAllServiceTypes() {
    return {
        type: DESELECT_ALL_SERVICE_TYPES
    }
}


export function openNewServiceTypeDialog() {
    return {
        type: OPEN_NEW_SERVICE_TYPE_DIALOG
    }
}

export function closeNewServiceTypeDialog() {
    return {
        type: CLOSE_NEW_SERVICE_TYPE_DIALOG
    }
}

export function openEditServiceTypeDialog(data) {
    return {
        type: OPEN_EDIT_SERVICE_TYPE_DIALOG,
        data
    }
}

export function closeEditServiceTypeDialog() {
    return {
        type: CLOSE_EDIT_SERVICE_TYPE_DIALOG
    }
}

export function addServiceType(newServiceType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post(`${window["apiLocation"]}/api/ServiceType`,
            newServiceType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_SERVICE_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service Type ${newServiceType.ServiceType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateServiceType(serviceType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.put(`${window["apiLocation"]}/api/ServiceType?Co=${encodeURIComponent(serviceType.Co)}&ServiceType=${encodeURIComponent(serviceType.ServiceType)}`,
            serviceType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_SERVICE_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service Type ${serviceType.ServiceType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeServiceType(serviceTypeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post('/api/serviceTypes-app/remove-serviceType', {
            serviceTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_TYPE
                })
            ]).then(() => dispatch(getServiceTypes(routeParams)))
        );
    };
}


export function removeServiceTypes(serviceTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post('/api/serviceTypes-app/remove-serviceTypes', {
            serviceTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_TYPES
                })
            ]).then(() => dispatch(getServiceTypes(routeParams)))
        );
    };
}

export function toggleStarredServiceType(serviceTypeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post('/api/serviceTypes-app/toggle-starred-serviceType', {
            serviceTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_TYPE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceTypes(routeParams)))
        );
    };
}

export function toggleStarredServiceTypes(serviceTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post('/api/serviceTypes-app/toggle-starred-serviceTypes', {
            serviceTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceTypes(routeParams)))
        );
    };
}

export function setServiceTypesStarred(serviceTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post('/api/serviceTypes-app/set-serviceTypes-starred', {
            serviceTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceTypes(routeParams)))
        );
    };
}

export function setServiceTypesUnstarred(serviceTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceTypesApp.serviceTypes;

        const request = axios.post('/api/serviceTypes-app/set-serviceTypes-unstarred', {
            serviceTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceTypes(routeParams)))
        );
    };
}
