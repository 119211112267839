import {combineReducers} from 'redux';
import workOrderUsages from './work-order-usage.reducer';
import user from './user.reducer';

const workOrderUsagesAppReducers = combineReducers({
    workOrderUsages,
    user
});

export default workOrderUsagesAppReducers;
