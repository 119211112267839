import { FuseAnimate } from '@fuse'
import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  Fade,
  FormControlLabel,
  Grow,
  Hidden,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MobileStepper,
  Slide,
  Switch,
  Tab,
  Table,
  TableCell,
  TableFooter,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  DialogTitle,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { saveAs } from '@progress/kendo-file-saver'
import classNames from 'classnames'
import clsx from 'clsx'
import parse from 'html-react-parser'
import format from 'string-template'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import MailCompose from 'main/content/apps/mail/MailCompose'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import { SPRoundIcon } from 'main/icons'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import CacheManager from 'store/middlewares/cache'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import CatalogAssemblyList from '../catalog/CatalogAssemblyList'
import CatalogItem from '../catalog/CatalogItem'
import CatalogOptionBoardList from '../catalog/CatalogOptionBoardList'
import {
  downloadCatalog,
  openEditCatalogItemDialog,
  openNewCatalogItemDialog,
} from '../catalog/store/actions'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import TechnicianTrip from '../technician/TechnicianTrip'
import { updateTrip } from '../trips/store/actions'
import { openEditWorkOrderUsageDialog } from '../work-order-usages/store/actions'
import CatalogOptionBoardOption from './CatalogOptionBoardOption'
import CatalogOptionBoardOptionCard from './CatalogOptionBoardOptionCard'
import EstimateComponents from './EstimateComponents'
import EstimateExclusions from './EstimateExclusions'
import EstimateFeatures from './EstimateFeatures'
import EstimateOptionCard from './EstimateOptionCard'
import EstimateTMItem from './EstimateTMItem'
import * as Actions from './store/actions'
import InvoicePayment from '../invoices/InvoicePayment'
import InvoicePaymentRecord from '../invoices/InvoicePaymentRecord'
import { openMinimizedDialog } from '../minimized-dialogs/store/actions/minimized-dialogs.actions'
import { openPreloadEstimateDialog } from './store/actions/estimates.actions'
import CatalogRecommendationPopup from '../catalog/CatalogRecommendationPopup'
import { formatPhone } from 'main/functions'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import InvoiceAddPaymentComponent from '../invoices/InvoiceAddPaymentComponent'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const offline = new CacheManager()

const styles = theme => ({
  root: {},
  discountDialog: {
    margin: 24,
    backgroundSize: 'cover',
    backgroundImage: 'url(assets/images/backgrounds/SP_Header_Dark.png)',
    backgroundPosition: 'center',
  },
  paper: {
    margin: 12,
    height: '100%',
    minHeight: 'calc(100% - 64px)',
    overflowX: 'hidden',
  },
  formControl: {
    marginBottom: 24,
    marginTop: 24,
  },
  customerSelect: {
    padding: 2,
    minHeight: 512,
    minWidth: '25%',
    border: '1px solid lightgrey',
    marginRight: 12,
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
  },
  customerCard: {
    borderRadius: 5,
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
  },
  customerAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 12,
    // marginLeft: 8,
    height: 24,
  },
  chipLabel: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: '#fff',
    paddingBottom: 2,
  },
  chipAvatar: {
    marginLeft: '0px !important',
    color: '#3f3f3f',
    backgroundColor: '#3f3f3f',
    border: '3px solid #3f3f3f',
    width: '32px !important',
    height: '32px !important',
  },
  chipAvatarIcon: {
    color: '#3f3f3f',
    backgroundColor: '#fff',
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  postedMatch: {
    color: theme.palette.secondary.dark,
  },
  postedNoMatch: {
    color: theme.palette.error[500],
  },
  notPosted: {
    color: '#5f5f5f',
  },
  postedBatch: {
    color: theme.palette.primary.main,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  drawer: {
    width: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    zIndex: 99999,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerBadge: {
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    top: 4,
    right: -2,
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  qty: {
    backgroundColor: '#3f3f3f',
    height: 18,
    minWidth: 24,
    fontSize: 13,
    paddingLeft: 6,
    paddingRight: 6,
    marginRight: 6,
    marginTop: 3,
    float: 'left',
    borderRadius: 12,
    color: '#fff',
    textAlign: 'center',
  },
  invHeader: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
    textTransform: 'uppercase',
  },
  invSubHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 4,
    color: '#333',
  },
  dividerPlain: {
    color: '#fff',
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
  progress: {
    width: 48,
    height: 48,
    margin: 'auto',
    marginBottom: -36,
    top: -30,
  },
  roundProgress: {
    width: '72px !important',
    height: '72px !important',
  },
  avatar: {
    backgroundColor: '#fff !important',
    width: 48,
    height: 48,
  },
  labels: {},
  blackButton: {
    background: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#333',
    },
    '&:focus': {
      background: '#4f4f4f',
    },
  },
  topShadow: {
    boxShadow: '0px 0px 12px 0px rgba(0,0,0,.15)',
  },
  estimateSummary: {
    boxShadow: '0px 0px 12px 4px rgba(0,0,0,.25)',
    zIndex: 999,
  },
  estimateSummaryBottom: {
    zIndex: 999,
  },
  countBadge: {
    top: 12,
    right: -18,
  },
  techAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  popper: {
    opacity: 1,
    marginBottom: 15,
    marginTop: 5,
    zIndex: 9999999,
    // opacity: 0,
  },
  tooltip: {
    opacity: 1,
    padding: 0,
    margin: 0,
    boxShadow: '2px 2px 6px #3333',
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 12,
    maxWidth: '65%',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: 11,
    maxWidth: '65%',
  },
  action: {
    marginTop: 0,
    marginRight: -8,
  },
  scheduleBadge: {
    backgroundColor: theme.palette.error[500],
    height: 18,
    minWidth: 18,
    fontSize: 13,
    borderRadius: 9,
    padding: 4,
    marginRight: 8,
    width: 'auto',
  },
  openBadge: {
    top: 4,
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  busyBadge: {
    top: 4,
    backgroundColor: '#6c4de9',
    color: '#fff',
  },
  capacityBadge: {
    top: 4,
    backgroundColor: '#f75151',
    color: '#fff',
  },
  recommendedItemsDiv: {
    right: 0,
  },
  recommendedItemsBtn: {
    color: '#4dbce9',
  },
  badge: {
    maxHeight: '20px',
    fontSize: '12px',
    backgroundColor: '#4dbce9',
    '@media screen and (max-width: 480px)': {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
  deleteButton: {
    background: theme.palette.error[500],
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    '&:hover': {
      background: theme.palette.error[600],
    },
    '&:active': {
      background: theme.palette.error[600],
    },
  },
})
const newEstimateState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  Estimate: null,
  EstimateNumber: null,
  QuoteYN: null,
  IssuedBy: null,
  IssuedDate: new Date(),
  IssuedFromWorkOrder: null,
  AssignedToWorkOrder: null,
  AssignedToWorkOrderScope: null,
  Department: null,
  ServiceType: null,
  Service: null,
  DueBy: null,
  ScopeDescription: null,
  ArrivalWindow: null,
  LeadTechnician: null,
  InvoiceNumber: null,
  ExpirationDate: null,
  Customer: null,
  Site: null,
  Greeting: null,
  Description: null,
  Status: 0,
  OptionBoard: null,
  SelectedOption: null,
  Requestor: null,
  Originator: null,
  Contact: null,
  ContactPhone: null,
  TaxType: 'S',
  TaxCode: null,
  TaxRate: 0.0,
  TotalPrice: null,
  Notes: null,
  ContractVerbiage: null,
  AcceptedYN: null,
  AcceptedBy: null,
  AcceptedDate: null,
  Signature: null,
  EnteredBy: null,
  EnteredDate: null,
  UpdatedBy: null,
  UpdatedDate: null,
  CallSid: null,
  ComponentDetail: 'B',
  Disclaimer: null,
  PaymentDue: 'C',
  IncludeTaxYN: null,
  Data: {
    ErrMsg: null,
    Components: [],
    OptionBoard: null,
    Features: [],
    Exclusions: [],
    Customer: {},
    Site: {},
    EstimateHeaderImg: null,
    EstimateFooterImg: null,
    Payments: [],
  },
  featureDescription: null,
  addDescription: false,
  category: null,
  // feature: {
  //     ID: null,
  //     Co: null,
  //     BusinessUnit: null,
  //     Division: null,
  //     Estimate: null,
  //     Feature: null,
  //     Description: null,
  //     ParentFeature: null,
  //     EnteredBy: null,
  //     EnteredDate: null,
  //     UpdatedBy: null,
  //     UpdatedDate: null,
  // },
  tab: 0,
  simpleStep: 0,
  prevStep: null,
  downloading: false,
  downloadError: false,
  estimateType: null,
  optionBoardType: null,
  newOrExisting: '+',
  isDatepickerOpen: false,
  isExpirationDatepickerOpen: false,
  selectedComponent: null,
  selectedTMItem: null,
  hideSummary: true,
  workOrder: null,
  scheduleWork: false,
  autoInvoice: false,
  showEmailLogs: false,
  printPDF: null,
  showRecommendations: false,
  addDiscount: false,
  opt: null,
  DiscountType: null,
  DiscountAmt: null,
  DiscountTotal: null,
  discountAmt: null,
  SelectedPricing: 'S',
  isMember: null,
  components: null,
  sub: null,
  tax: null,
  total: null,
  existingComponents: null,
  dialogHeight: null,
  minHeight: null,
  location: null,
  posSet: null,
  position: { x: 480, y: 0 },
  IsFinanced: false,
  IsApprovedAccount: false,
}

const newOptionBoard = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  OptionBoard: null,
  Name: null,
  Description: null,
  OptionsType: null,
  SharedYN: null,
  Notes: null,
  EnteredBy: null,
  EnteredDate: null,
  UpdatedBy: null,
  UpdatedDate: null,
  AttachmentID: null,
  Data: {
    ErrMsg: null,
    Options: [],
  },
  IsNew: 'Y',
  featureDescription: null,
  optionsType: null,
  category: null,
  RecommendedOption: null,
  DefaultSavingsType: 'M',
  editOption: null,
}

const newOption = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  OptionBoard: null,
  Option: null,
  Name: null,
  Description: null,
  Notes: null,
  EnteredBy: null,
  EnteredDate: null,
  UpdatedBy: null,
  UpdatedDate: null,
  PriceTotal: 0.0,
  AttachmentID: null,
  Data: {
    Components: [],
    ErrMsg: null,
  },
  hidePricing: false,
}
const newTMItemState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  Name: null,
  Description: null,
  Assembly: null,
  Material: null,
  LaborType: null,
  Type: 2,
  PriceMethod: 'F',
  RateTemplate: null,
  Price: 0,
  TaxableYN: 'Y',
  DisplayYN: null,
  Notes: null,
  UnitCost: null,
  TotalCost: null,
  CustomPrice: null,
  CustomMemberPrice: null,
  CustomImgURL: null,
  EnteredBy: null,
  UpdatedBy: null,
  EnteredDate: null,
  UpdatedDate: null,
  Category: null,
  Qty: 1,
  UOM: 'EA',
  imageDialog: false,
  selectedImage: null,
  value: 0,
  saving: false,
  showRecommendations: false,
  estimate: null,
  option: null,
  partDescription: null,
  searching: false,
}

const terms = {
  Q: 'Quote',
  P: 'Proposal',
  E: 'Estimate',
}

class EstimateDialog extends Component {
  state = { ...newEstimateState }

  ref = React.createRef()

  picRef = React.createRef()

  componentDidMount() {
    // document.addEventListener('click', this.closeRecommendations, true);
  }

  componentWillUnmount() {
    // document.removeEventListener('click', this.closeRecommendations, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const estimateNotes = this.props.notes
    /**
     * After Dialog Open
     */
    newEstimateState.Co = this.props.Co
    const newState = _.cloneDeepWith(newEstimateState)
    const { tech } = this.props

    // window["warn"]("estimateDialog", this.props.estimateDialog, this.props.estimates)

    // if (prevState.SelectedPricing !== this.state.SelectedPricing){
    //     window["warn"]("calculateEstimatePrice ran")
    //     this.calculateEstimatePrice(true)
    // }

    if (
      this.props.estimateDialog.type === 'new' &&
      this.state.isMember === null
    ) {
      const activeAgreements =
        this.state &&
        this.state.Data &&
        this.state.Data.Site &&
        this.state.Data.Site.Data &&
        this.state.Data.Site.Data.Agreements
          ? _.filter(
              this.state.Data.Site.Data.Agreements,
              o =>
                o.ActiveYN !== 'N' &&
                new Date(o.StartDate) <=
                  new Date().setDate(new Date().getDate() + 1) &&
                new Date(o.EndDate) >= new Date(),
            )
          : null
      const isMember = Boolean(activeAgreements && activeAgreements.length > 0)
      let SelectedPricing = 'S'
      if (isMember) {
        SelectedPricing = 'M'
      }

      this.setState({ ...this.state, isMember, SelectedPricing })
    }

    if (
      !prevProps.estimateDialog.props.open &&
      this.props.estimateDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      const { data } = this.props.estimateDialog
      const estimateType = this.props.estimateDialog.data.OptionBoard
        ? 'O'
        : 'S'
      const newOrExisting =
        data.Data.OptionBoard && data.Data.OptionBoard.Estimate ? '+' : '-'
      const { Data } = data
      let steps = ['Select Price Book', 'Select Proposal Type']
      if (estimateType) {
        if (estimateType === 'S') {
          steps = [
            'Select Price Book',
            'Select Proposal Type',
            'Tasks & Assemblies',
            'Proposal Detail',
            'Review and Submit',
          ]
        }
        if (estimateType === 'O') {
          steps = [
            'Select Price Book',
            'Select Proposal Type' /*, "New or Existing"*/,
          ]
          /*if (newOrExisting === "-") {
                        steps.push("Select Option Board");
                    }*/
          //if (newOrExisting === "+") {
          steps.push('Option Board Type')
          //}
          if (
            Data.OptionBoard &&
            Data.OptionBoard.Data &&
            Data.OptionBoard.Data.Options
          ) {
            Data.OptionBoard.Data.Options.map((opt, index) => {
              steps.push(
                `Option #${index + 1} of ${
                  Data.OptionBoard.Data.Options.length
                } - ${opt.Name}`,
              )
            })
          }
          steps = [
            ...steps,
            'Proposal Detail',
            'Presentation Mode',
            'Review and Submit',
          ]
        }
      }
      if (
        this.props.estimateDialog.type === 'edit' &&
        this.props.estimateDialog.data &&
        !_.isEqual(
          this.props.estimateDialog.data,
          prevProps.estimateDialog.data,
        )
      ) {
        if (data.scheduleWork) {
          const {
            Co,
            Customer,
            Site,
            BusinessUnit,
            Division,
            Description,
            Estimate,
          } = data
          const division =
            _.find(this.props.divisions, { Co, BusinessUnit, Division }) || {}
          const { DefaultRateTemplate, DefaultTaxCode } = division
          const { Components } = data.Data
          const comp = _.find(Components, o =>
            Boolean(o.DeleteYN !== 'Y' && o.Data && o.Data.Assembly),
          )
          const assembly = comp ? comp.Data.Assembly : {}
          const { Department, ServiceType, Service } = assembly

          const sub = _.sumBy(
            _.filter(Components, o => o.DeleteYN !== 'Y'),
            o => {
              return Number(o.Price)
            },
          )

          this.setState({
            ..._.cloneDeepWith(newState),
            ...data,
            tab: 0,
            optionBoardType: Data.OptionBoard
              ? Data.OptionBoard.OptionsType
              : null,
            newOrExisting,
            simpleStep: steps.length - 1,
            estimateType,
            scheduleWork: true,
            sub,
            existingComponents: Components,
            workOrder: {
              Co,
              Customer,
              Site,
              BusinessUnit,
              Division,
              EstimateBusinessUnit: BusinessUnit,
              EstimateDivision: Division,
              Department,
              ServiceType,
              Service,
              Description,
              Priority: 3,
              PriceMethod: 'F',
              TaxableYN: 'Y',
              RateTemplate: DefaultRateTemplate,
              TaxCode: DefaultTaxCode,
              Price: sub,
              Estimate,
              Data: {
                ErrMsg: null,
                Trips: [],
                Tags: [],
              },
            },
            posSet: this.state.posSet,
          })
        } else {
          const { Components } = data.Data
          const sub = _.sumBy(
            _.filter(Components, o => o.DeleteYN !== 'Y'),
            o => {
              return Number(o.Price)
            },
          )

          this.setState({
            ..._.cloneDeepWith(newState),
            ...data,
            tab: 0,
            optionBoardType: Data.OptionBoard
              ? Data.OptionBoard.OptionsType
              : null,
            newOrExisting,
            simpleStep: steps.length - 1,
            estimateType,
            sub,
            existingComponents: Components,
          })
        }
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.estimateDialog.type === 'new' &&
        !_.isEqual(
          { ...newState, ...this.props.estimateDialog.data },
          prevState,
        )
      ) {
        // let { EstimateNumber, Notes } = this.props.estimateDialog.data;
        let { Co, BusinessUnit, Division, EstimateNumber } =
          this.props.estimateDialog.data
        if (!Co) {
          Co = tech.Co
        }
        if (!BusinessUnit) {
          BusinessUnit = tech.DefaultBusinessUnit
        }
        if (!Division) {
          Division = tech.DefaultDivision
        }

        const { catalogs, allCatalogs } = this.props
        let catalog = this.getCatalog(Co, BusinessUnit, Division)
        let hasCatalog = _.find([...allCatalogs], {
          Co,
          BusinessUnit,
          Division,
        })
        if (catalog && hasCatalog) {
          const {
            DefaultEstimateType,
            DefaultEstimatePrefix,
            DefaultComponentDetail,
            DefaultPaymentDue,
            DefaultExpirationDays,
            DefaultContractVerbiage,
            DefaultDisclaimer,
            Data,
            IncludeTaxYN,
          } = catalog
          this.setState(
            {
              ...newState,
              ...this.props.estimateDialog.data,
              Co,
              BusinessUnit,
              Division,
              tab: 0,
              simpleStep: this.props.estimateDialog.data.simpleStep
                ? this.props.estimateDialog.data.simpleStep
                : 1,
              EstimateNumber,
              ComponentDetail: DefaultComponentDetail,
              Disclaimer: DefaultDisclaimer,
              ContractVerbiage: DefaultContractVerbiage,
              PaymentDue: DefaultPaymentDue,
              IncludeTaxYN,
              ExpirationDate: DefaultExpirationDays
                ? moment().add(DefaultExpirationDays, 'days').local().toDate()
                : null,
              Data: {
                ...newState.Data,
                ...this.props.estimateDialog.data.Data,
                Exclusions: Data.Exclusions,
              },
              //SelectedPricing: SelectedPricing
            },
            this.resetPricing,
          )
        } else {
          if (hasCatalog) {
            this.setState(
              {
                ...newState,
                ...this.props.estimateDialog.data,
                simpleStep: 0,
                downloading: true,
              },
              () => {
                Promise.all([
                  this.props.downloadCatalog({
                    Co,
                    BusinessUnit: this.state.BusinessUnit,
                    Division: this.state.Division,
                  }),
                ])
                  .then(() =>
                    this.setState(
                      {
                        downloading: false,
                        downloadError: false,
                        simpleStep: 1,
                      },
                      this.setCatalog,
                    ),
                  )
                  .catch(() =>
                    this.setState(
                      { downloading: false, downloadError: true },
                      () => {
                        this.props.showMessage({
                          message: `Error downloading Price Book`,
                          autoHideDuration: 30000,
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                          variant: 'error',
                        })
                      },
                    ),
                  )
              },
            )
          } else {
            this.setState({
              ...newState,
              ...this.props.estimateDialog.data,
              Co,
              BusinessUnit,
              Division,
              simpleStep: 0,
              //SelectedPricing: SelectedPricing
            })
            // catalog = this.getCatalog(Co, BusinessUnit, "*");
            // hasCatalog = _.find([...allCatalogs], { Co, BusinessUnit, Division: "*" });
            // if (catalog && hasCatalog) {
            //     const { DefaultEstimateType, DefaultEstimatePrefix, DefaultComponentDetail, DefaultPaymentDue, DefaultExpirationDays, DefaultContractVerbiage, DefaultDisclaimer, Data, IncludeTaxYN } = catalog;
            //     this.setState({
            //         ...newState,
            //         ...this.props.estimateDialog.data,
            //         Division: "*",
            //         tab: 0,
            //         simpleStep: this.props.estimateDialog.data.simpleStep ? this.props.estimateDialog.data.simpleStep : 1,
            //         EstimateNumber,
            //         ComponentDetail: DefaultComponentDetail,
            //         Disclaimer: DefaultDisclaimer,
            //         ContractVerbiage: DefaultContractVerbiage,
            //         PaymentDue: DefaultPaymentDue,
            //         IncludeTaxYN,
            //         ExpirationDate: DefaultExpirationDays ? moment().add(DefaultExpirationDays, 'days').local().toDate() : null,
            //         Data: {
            //             ...newState.Data,
            //             ...this.props.estimateDialog.data.Data,
            //             Exclusions: Data.Exclusions
            //         }
            //     });
            // } else {
            //     if (hasCatalog) {
            //         this.setState({ ...newState, ...this.props.estimateDialog.data, Division: "*", simpleStep: 0, downloading: true }, () => {
            //             Promise.all([
            //                 this.props.downloadCatalog({ Co, BusinessUnit: this.state.BusinessUnit, Division: "*" })
            //             ]).then(() => this.setState({ downloading: false, downloadError: false, simpleStep: 1 }, this.setCatalog)).catch(() => this.setState({ downloading: false, downloadError: true }, () => {
            //                 this.props.showMessage({
            //                     message: `Error downloading Price Book`,
            //                     autoHideDuration: 30000,
            //                     anchorOrigin: {
            //                         vertical: 'top',
            //                         horizontal: 'right'
            //                     },
            //                     variant: 'error'
            //                 })
            //             }));
            //         });
            //     } else {
            //         this.setState({ ...newState, ...this.props.estimateDialog.data, simpleStep: 0, });
            //     }
            // }
          }
        }
      }
    }
    if (
      this.props.estimateDialog.type === 'update' &&
      this.props.estimateDialog.data &&
      !_.isEqual(this.props.estimateDialog.data, prevProps.estimateDialog.data)
    ) {
      this.props.estimateDialog.type = !this.state.Estimate ? 'new' : 'edit'
      this.setState({
        ...this.props.estimateDialog.data,
        workOrder: this.props.estimateDialog.data.AssignedToWorkOrder
          ? null
          : this.state.workOrder,
      })
    }
  }

  resetPricing = () => {
    const { catalogs, Co } = this.props
    const {
      simpleStep,
      BusinessUnit,
      Division,
      OptionBoard,
      estimateType,
      newOrExisting,
      Data,
      SelectedPricing,
    } = this.state
    const catalog = _.find([...catalogs], { Co, BusinessUnit, Division })
    const allAssemblies = catalog ? catalog.Data.Assemblies : []
    allAssemblies.forEach(c => (c.AddonYN = null))
  }

  calculateEstimatePrice = updateExistingComponents => {
    const { catalogs, Co } = this.props
    const {
      simpleStep,
      BusinessUnit,
      Division,
      OptionBoard,
      estimateType,
      newOrExisting,
      Data,
      SelectedPricing,
      existingComponents,
    } = this.state

    const est = this.state

    let steps = ['Select Price Book', 'Select Proposal Type']
    if (estimateType) {
      if (estimateType === 'S') {
        steps = [
          'Select Price Book',
          'Select Proposal Type',
          'Tasks & Assemblies',
          'Proposal Detail',
          'Review and Submit',
        ]
      }
      if (estimateType === 'O') {
        steps = [
          'Select Price Book',
          'Select Proposal Type' /*, "New or Existing"*/,
        ]
        if (newOrExisting !== '+') {
          steps.push('Select Option Board')
        }
        if (newOrExisting === '+') {
          steps.push('Option Board Type')
        }
        if (
          Data.OptionBoard &&
          Data.OptionBoard.Data &&
          Data.OptionBoard.Data.Options
        ) {
          Data.OptionBoard.Data.Options.map((opt, index) => {
            steps.push(
              `Option #${index + 1} of ${
                Data.OptionBoard.Data.Options.length
              } - ${opt.Name}`,
            )
          })
        }
        steps = [
          ...steps,
          'Proposal Detail',
          'Presentation Mode',
          'Review and Submit',
        ]
      }
    }

    const catalog = _.find([...catalogs], { Co, BusinessUnit, Division })

    const currentOption =
      estimateType === 'O' &&
      Data.OptionBoard &&
      simpleStep > 2 &&
      simpleStep < steps.length - 3
        ? Data.OptionBoard.Data.Options[simpleStep - 3]
        : null

    let components = currentOption
      ? currentOption.Data.Components
      : this.state.Data.Components

    components = components ? _.filter(components, o => o.DeleteYN !== 'Y') : []

    // window["warn"]("calculateEstimatePrice", updateExistingComponents, this.state.existingComponents, currentOption, _.cloneDeepWith(components))

    if (catalog && catalog.AutoAddOnYN === 'Y') {
      const allAssemblies = catalog ? catalog.Data.Assemblies : []
      const maxPriceComponent = _.maxBy(
        _.filter(components, o => Boolean(o.Data && o.Data.Assembly)),
        o => o.Data.Assembly.Price,
      )
      if (maxPriceComponent) {
        const maxObj = _.find(allAssemblies, {
          Assembly: maxPriceComponent.Assembly,
        })
        if (maxObj) {
          allAssemblies.forEach(c => {
            c.AddonYN = c.Price <= maxObj.Price ? 'Y' : 'N'
          })
        }
        components.forEach(component => {
          const assembly = _.find(
            allAssemblies,
            o => o.Assembly === component.Assembly,
          )
          // window["warn"]("component loop123", component, assembly, updateExistingComponents, this.state.existingComponents);
          if (!existingComponents || updateExistingComponents) {
            if (
              assembly &&
              component.Component === maxPriceComponent.Component
            ) {
              component.AddonYN = null
            }
            if (
              assembly &&
              component.Component !== maxPriceComponent.Component
            ) {
              if (!component.AddonYN) {
                component.AddonYN = assembly.AddonYN
              }
            }
            const assemblyNormalPrice =
              component.Qty *
              (!assembly
                ? component.CustomPrice
                : component.CustomPrice ||
                  (component.AddonYN === 'Y'
                    ? assembly.AddOnPrice
                    : assembly.Price))
            let assemblyMemberPrice =
              component.Qty *
              (!assembly
                ? component.CustomMemberPrice || component.CustomPrice
                : component.CustomMemberPrice ||
                  (component.AddonYN === 'Y'
                    ? assembly.AddOnMemberPrice
                    : assembly.MemberPrice))
            let assemblyPremiumPrice =
              component.Qty *
              (!assembly
                ? component.CustomPrice
                : component.AddonYN === 'Y'
                  ? assembly.PremiumAddOnPrice ||
                    assembly.AddOnPrice ||
                    assembly.CustomPrice ||
                    assembly.Price
                  : assembly.PremiumPrice ||
                    assembly.CustomPrice ||
                    assembly.Price)
            component.Price =
              !SelectedPricing ||
              SelectedPricing === 'R' ||
              SelectedPricing === 'S'
                ? assemblyNormalPrice
                : SelectedPricing === 'M'
                  ? assemblyMemberPrice
                  : SelectedPricing === 'P'
                    ? assemblyPremiumPrice
                    : assemblyNormalPrice
          }
        })
      } else {
        allAssemblies.forEach(a => (a.AddonYN = null))
      }
    }
    // window["warn"]("calculateEstimatePrice AFTER", updateExistingComponents, this.state.existingComponents, currentOption, _.cloneDeepWith(components))

    if (
      Data.OptionBoard &&
      Data.OptionBoard.Data &&
      Data.OptionBoard.Data.Options
    ) {
      Data.OptionBoard.Data.Options.forEach(opt => {
        if (opt.Data && opt.Data.Components) {
          const maxPriceComponent = _.maxBy(
            _.filter(opt.Data.Components, o =>
              Boolean(o.Data && o.Data.Assembly),
            ),
            o => o.Data.Assembly.Price,
          )
          opt.Data.Components.forEach(component => {
            const assembly = component.Data.Assembly
            if (assembly) {
              if (assembly.Data && assembly.Data.Images) {
                assembly.Data.Image = _.find(assembly.Data.Images, {
                  PrimaryYN: 'Y',
                })
                if (!assembly.Data.Image && assembly.Data.Images.length > 0) {
                  assembly.Data.Image = assembly.Data.Images[0]
                }
              }

              if (
                assembly &&
                component.Component === maxPriceComponent.Component
              ) {
                //&& estimateType !== "O"
                component.AddonYN = null
              }
              if (
                assembly &&
                component.Component !== maxPriceComponent.Component
              ) {
                //&& estimateType !== "O"
                if (!component.AddonYN) {
                  component.AddonYN = assembly.AddonYN
                }
              }
            }
            const assemblyNormalPrice =
              component.Qty *
              (!assembly
                ? component.CustomPrice
                : component.CustomPrice ||
                  (component.AddonYN === 'Y'
                    ? assembly.AddOnPrice
                    : assembly.Price))
            let assemblyMemberPrice =
              component.Qty *
              (!assembly
                ? component.CustomMemberPrice || component.CustomPrice
                : component.CustomMemberPrice ||
                  (component.AddonYN === 'Y'
                    ? assembly.AddOnMemberPrice
                    : assembly.MemberPrice))
            let assemblyPremiumPrice =
              component.Qty *
              (!assembly
                ? component.CustomPrice
                : component.AddonYN === 'Y'
                  ? assembly.PremiumAddOnPrice ||
                    assembly.AddOnPrice ||
                    assembly.CustomPrice ||
                    assembly.Price
                  : assembly.PremiumPrice ||
                    assembly.CustomPrice ||
                    assembly.Price)
            component.Price =
              !SelectedPricing ||
              SelectedPricing === 'R' ||
              SelectedPricing === 'S'
                ? assemblyNormalPrice
                : SelectedPricing === 'M'
                  ? assemblyMemberPrice
                  : SelectedPricing === 'P'
                    ? assemblyPremiumPrice
                    : assemblyNormalPrice
          })
        }
      })
    }

    let sub = _.sumBy(components, o => {
      return Number(o.Price)
    })
    // window["log"]("Calculate Estimate Price With: ", components, sub);
    this.setState({
      ...this.state,
      components: components,
      sub,
      existingComponents: updateExistingComponents
        ? null
        : this.state.existingComponents,
    })
  } // end of CEP fxn

  calculateOptionsEstimatePrice = simpleStep => {
    const { Data, SelectedPricing } = this.state

    const optionBoard = this.state.Data.OptionBoard
    if (optionBoard) {
      const currentOption = Data.OptionBoard.Data.Options[simpleStep - 3]

      let components = currentOption
        ? currentOption.Data.Components
        : this.state.Data.Components
      components = components
        ? _.filter(components, o => o.DeleteYN !== 'Y')
        : []

      const normalPrice = _.sumBy(
        components,
        component =>
          component.Qty *
          (!component.Data.Assembly
            ? component.CustomPrice
            : component.CustomPrice ||
              (component.AddonYN === 'Y'
                ? component.Data.Assembly.AddOnPrice
                : component.Data.Assembly.Price)),
      )
      let memberPrice = _.sumBy(
        components,
        component =>
          component.Qty *
          (!component.Data.Assembly
            ? component.CustomMemberPrice || component.CustomPrice
            : component.CustomMemberPrice ||
              (component.AddonYN === 'Y'
                ? component.Data.Assembly.AddOnMemberPrice
                : component.Data.Assembly.MemberPrice)),
      )
      let premiumPrice = _.sumBy(
        components,
        component =>
          component.Qty *
          (!component.Data.Assembly
            ? component.CustomPrice
            : component.AddonYN === 'Y'
              ? component.Data.Assembly.PremiumAddOnPrice ||
                component.Data.Assembly.AddOnPrice ||
                component.Data.Assembly.CustomPrice ||
                component.Data.Assembly.Price
              : component.Data.Assembly.PremiumPrice ||
                component.Data.Assembly.CustomPrice ||
                component.Data.Assembly.Price),
      )

      let sub =
        SelectedPricing === 'M'
          ? memberPrice
          : SelectedPricing === 'P'
            ? premiumPrice
              ? premiumPrice
              : normalPrice
            : normalPrice

      this.setState({ ...this.state, sub })
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  closeComposeDialog = () => {
    this.props.estimateDialog.type === 'edit'
      ? this.props.closeEditEstimateDialog()
      : this.props.closeNewEstimateDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.customerDialog.type === 'edit'
      ? this.props.closeEditCustomerDialog()
      : this.props.closeNewCustomerDialog()
  }

  canBeSubmitted() {
    const { EstimateNumber, Data, InvoiceNumber } = this.state
    return (
      EstimateNumber &&
      EstimateNumber.length > 0 &&
      (Data.OptionBoard || Data.Components.length > 0) &&
      !InvoiceNumber
    )
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }
  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({
      ...this.state,
      Signature: this.sigPad.getCanvas().toDataURL('image/png'),
      AcceptedDate: new Date(),
      isSigning: false,
    })
  }

  // getPDF = () => {
  //     const input = document.getElementById('estimate-print');
  //     html2canvas(input)
  //         .then((canvas) => {
  //             const imgData = canvas.toDataURL('image/png');
  //             const pdf = new jsPDF();
  //             pdf.addImage(imgData, 'PNG', 0, 0);
  //             pdf.save(`${this.state.EstimateNumber}.pdf`);
  //         });
  // }
  printPDF = () => {
    const { EstimateNumber } = this.state
    let gridElement = document.getElementById('estimate-print')
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      paperSize: 'letter',
      margin: { top: 16, bottom: 24, left: 0, right: 0 },
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group, {
          paperSize: 'letter',
          margin: { top: 16, bottom: 24, left: 0, right: 0 },
          multiPage: true,
        })
      })
      .then(dataUri => {
        this.setState({ printPDF: dataUri, anchorEl: null })
      })
  }
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
  convertImgToBase64(img) {
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)
    var dataURL = canvas.toDataURL('image/png')
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
  }

  canWorkOrderBeSubmitted = () => {
    const {
      Co,
      Customer,
      Site,
      BusinessUnit,
      Division,
      Department,
      ServiceType,
      Service,
      Description,
      Priority,
      PriceMethod,
      // RateTemplate,
      TaxCode,
      Price,
      Estimate,
      DueBy,
      ArrivalWindow,
    } = this.state.workOrder
    window['warn']('Estimate Work Order Details: ', this.state.workOrder)
    return (
      Co &&
      Customer &&
      Site &&
      BusinessUnit &&
      Division &&
      Department &&
      ServiceType &&
      Service &&
      Description &&
      Priority &&
      PriceMethod &&
      // RateTemplate &&
      TaxCode &&
      Price &&
      Estimate &&
      DueBy &&
      ArrivalWindow
    )
  }

  exportToPDF = () => {
    const { EstimateNumber } = this.state
    let gridElement = document.getElementById('estimate-print')
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      paperSize: 'letter',
      margin: { top: 16, bottom: 24, left: 0, right: 0 },
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group, {
          paperSize: 'letter',
          margin: { top: 16, bottom: 24, left: 0, right: 0 },
          multiPage: true,
        })
      })
      .then(dataUri => {
        var data = dataUri.split(';base64,')[1]
        const blob = this.b64toBlob(data, 'application/pdf')
        saveAs(dataUri, EstimateNumber)
      })
  }

  emailEstimate = () => {
    const { EstimateNumber, Co, Data } = this.state
    let gridElement = document.getElementById('estimate-print')
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      paperSize: 'letter',
      margin: { top: 16, bottom: 24, left: 0, right: 0 },
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group, {
          paperSize: 'letter',
          margin: { top: 16, bottom: 24, left: 0, right: 0 },
          multiPage: true,
        })
      })
      .then(dataUri => {
        var data = dataUri.split(';base64,')[1]
        const blob = this.b64toBlob(data, 'application/pdf')
        blob.lastModifiedDate = new Date()
        blob.name = `${EstimateNumber}.pdf`
        let html = ''
        // const file = this.dataURLtoFile(dataUri, `${EstimateNumber}.pdf`);
        const { businessUnits, divisions, templates, companies } = this.props
        // const { Scopes } = this.state.Data.Data;
        const { Co, BusinessUnit, Division, ID } = this.state
        let bu = null
        let co = _.find(companies, { Co })
        if (BusinessUnit && businessUnits && templates) {
          bu = _.find(businessUnits, { Co, BusinessUnit })
          const div = _.find(divisions, { Co, BusinessUnit, Division })
          if (bu && div) {
            const template =
              div.Data.EstimateEmailTemplate || bu.Data.EstimateEmailTemplate
            html = template ? template.Markup : ''
            // const templateData = {Co: Co, WorkOrder: Data.FromWorkOrder.WorkOrder, Estimate: EstimateNumber}
            // window["warn"]("estimate email templateData", templateData, this.state);
            // html = template ? format(template.Markup, templateData) : '';
          }
        }
        const edata = {
          composeDialog: true,
          Title: 'Email Estimate',
          Icon: 'email',
          To: `${this.state.Data.Customer.BillEmail};`,
          Subject: `Your Quote from ${co.Data.Co.Name}`,
          Head: '',
          Message: '',
          Body: html,
          Attachments: [blob],
          Type: 'ESTIMATE',
          RecordID: ID,
        }
        this.setState({ ...this.state, estimateEmail: edata })
      })
  }

  handleDateChange = event => {
    // window["warn"](event);
    const IssuedDate = event._d
    // this.setDatepickerOpen(false, IssuedDate);
    this.setState({ ...this.state, IssuedDate })
  }

  handleExpireDateChange = event => {
    // window["warn"](event);
    const ExpirationDate = event._d
    this.setState({ ...this.state, ExpirationDate })
  }

  openDatePicker = e => {
    this.setDatepickerOpen(true)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer'>
      {new Date(this.state.IssuedDate).toLocaleDateString('en-US')}
    </span>
  )

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  formatPhone(str) {
    return formatPhone(str).formatted
  }

  handleNext = () => {
    const { optionTypes } = this.props
    let { simpleStep, estimateType, newOrExisting, optionBoardType, Data } =
      this.state
    let steps = ['Select Price Book', 'Select Proposal Type']
    if (estimateType) {
      if (estimateType === 'S') {
        steps = [
          'Select Price Book',
          'Select Proposal Type',
          'Tasks & Assemblies',
          'Proposal Detail',
          'Review and Submit',
        ]
      }
      if (estimateType === 'O') {
        steps = [
          'Select Price Book',
          'Select Proposal Type' /*, "New or Existing"*/,
        ]
        /*if (newOrExisting === "-") {
                    steps.push("Select Option Board");
                }*/
        //if (newOrExisting === "+") {
        steps.push('Option Board Type')
        //}
        if (
          Data.OptionBoard &&
          Data.OptionBoard.Data &&
          Data.OptionBoard.Data.Options
        ) {
          Data.OptionBoard.Data.Options.map((opt, index) => {
            steps.push(
              `Option #${index + 1} of ${
                Data.OptionBoard.Data.Options.length
              } - ${opt.Name}`,
            )
          })
        }
        steps = [
          ...steps,
          'Proposal Detail',
          'Presentation Mode',
          'Review and Submit',
        ]
      }
    }
    if (simpleStep < steps.length - 1) {
      simpleStep += 1
      const prevStep = simpleStep - 1
      this.setState({ ...this.state, simpleStep, prevStep }, () =>
        this.calculateOptionsEstimatePrice(simpleStep),
      )
    }
  }

  handleBack = () => {
    let { simpleStep } = this.state
    if (simpleStep > 0) {
      simpleStep -= 1
      const prevStep = simpleStep + 1
      this.setState({ ...this.state, simpleStep, prevStep }, () =>
        this.calculateOptionsEstimatePrice(simpleStep),
      )
    } else {
      this.setState({ ...this.state, simpleStep }, () =>
        this.calculateOptionsEstimatePrice(simpleStep),
      )
    }
  }

  toggleAssemblies = open => {
    this.setState({
      assembliesList: open,
    })
  }

  setCatalog = () => {
    const { catalogs } = this.props
    const { Co, BusinessUnit, Division } = this.state
    const catalog = _.find(catalogs, { Co, BusinessUnit, Division })
    if (catalog) {
      const {
        DefaultEstimateType,
        DefaultEstimatePrefix,
        DefaultComponentDetail,
        DefaultPaymentDue,
        DefaultExpirationDays,
        DefaultContractVerbiage,
        DefaultDisclaimer,
        Data,
      } = catalog
      this.setState({
        ...this.state,
        ComponentDetail: DefaultComponentDetail,
        Disclaimer: DefaultDisclaimer,
        ContractVerbiage: DefaultContractVerbiage,
        PaymentDue: DefaultPaymentDue,
        ExpirationDate: DefaultExpirationDays
          ? moment().add(DefaultExpirationDays, 'days').local().toDate()
          : null,
        Data: {
          ...this.state.Data,
          Exclusions: Data.Exclusions,
        },
      })
    }
  }

  getCatalog = (Co, BusinessUnit, Division) => {
    const { catalogs } = this.props
    const catalog = _.find(catalogs, { Co, BusinessUnit, Division })
    if (catalog) {
      return catalog
    }
  }

  selectOptionBoard = opt => {
    const { catalogs } = this.props
    const { simpleStep, Co, BusinessUnit, Division } = this.state
    const catalog = _.find(catalogs, { Co, BusinessUnit, Division })
    const ob = _.cloneDeepWith(opt)
    ob.ID = null
    ob.OptionBoard = null
    ob.IsNew = 'Y'
    ob.SharedYN = 'N'
    ob.StandardYN = 'N'
    ob.Data.Options.map(option => {
      option.ID = null
      option.OptionBoard = null
      option.Estimate = null
      option.Data.Components.map(comp => {
        const Assembly = _.find(catalog.Data.Assemblies, {
          Co,
          BusinessUnit,
          Division,
          Assembly: comp.Assembly,
        })
        comp.ID = null
        comp.OptionBoard = null
        comp.Data = {
          Assembly,
        }
      })
    })
    this.setState(
      {
        ...this.state,
        optionBoardType: ob.OptionsType,
        OptionBoard: null,
        SelectedOption: null,
        Description: ob.Description,
        simpleStep: simpleStep + 1,
        Data: { ...this.state.Data, OptionBoard: ob },
      },
      this.calculateEstimatePrice,
    )
  }

  selectOption = opt => {
    const { catalogs } = this.props
    const {
      Co,
      BusinessUnit,
      Division,
      OptionBoard,
      estimateType,
      simpleStep,
      Data,
      SelectedPricing,
    } = this.state
    const catalog = _.find([...catalogs], { Co, BusinessUnit, Division })
    const optionBoard = this.state.Data.OptionBoard
    const { Description } = opt || optionBoard || this.state
    let steps = ['Select Price Book', 'Select Proposal Type']
    if (estimateType) {
      if (estimateType === 'S') {
        steps = [
          'Select Price Book',
          'Select Proposal Type',
          'Tasks & Assemblies',
          'Proposal Detail',
          'Review and Submit',
        ]
      }
      if (estimateType === 'O') {
        steps = [
          'Select Price Book',
          'Select Proposal Type' /*, "New or Existing"*/,
        ]
        /*if (newOrExisting === "-") {
                    steps.push("Select Option Board");
                }*/
        //if (newOrExisting === "+") {
        steps.push('Option Board Type')
        //}
        if (
          Data.OptionBoard &&
          Data.OptionBoard.Data &&
          Data.OptionBoard.Data.Options
        ) {
          Data.OptionBoard.Data.Options.map((opt, index) => {
            steps.push(
              `Option #${index + 1} of ${
                Data.OptionBoard.Data.Options.length
              } - ${opt.Name}`,
            )
          })
        }
        steps = [
          ...steps,
          'Proposal Detail',
          'Presentation Mode',
          'Review and Submit',
        ]
      }
    }
    let { Components } = this.state.Data
    Components = _.filter(Components, o => Boolean(o.ID))
    Components.map(old => {
      old.DeleteYN = 'Y'
    })
    const activeAgreements =
      this.state &&
      this.state.Data &&
      this.state.Data.Site &&
      this.state.Data.Site.Data &&
      this.state.Data.Site.Data.Agreements
        ? _.filter(
            this.state.Data.Site.Data.Agreements,
            o =>
              o.ActiveYN !== 'N' &&
              new Date(o.StartDate) <=
                new Date().setDate(new Date().getDate() + 1) &&
              new Date(o.EndDate) >= new Date(),
          )
        : null
    const isMember =
      Boolean(activeAgreements && activeAgreements.length > 0) ||
      (opt &&
        opt.Data &&
        _.findIndex(
          opt.Data.Components,
          o => o.Type === 4 && o.DeleteYN !== 'Y',
        ) > -1)
    const option = opt
      ? _.find(optionBoard.Data.Options, { Option: opt.Option })
      : null
    const components = option
      ? _.cloneDeepWith(option.Data.Components)
      : opt
        ? _.cloneDeepWith(opt.Data.Components)
        : []
    var savingsType =
      (opt ? opt.SavingsType : 'M') || optionBoard.DefaultSavingsType
    const fullPrice = _.sumBy(
      components,
      o => o.Qty * (o.CustomPrice || o.Data.Assembly.Price),
    )
    const ogPrice = _.sumBy(components, o => o.Price)
    const normalPrice = _.sumBy(components, o =>
      !o.Data.Assembly
        ? o.CustomPrice
          ? o.Qty * o.CustomPrice
          : o.Price
        : o.Qty *
          (o.CustomPrice ||
            (o.AddonYN === 'Y'
              ? o.Data.Assembly.AddOnPrice
              : o.Data.Assembly.Price)),
    )
    let memberPrice = _.sumBy(components, o =>
      !o.Data.Assembly
        ? o.CustomMemberPrice
          ? o.Qty * o.CustomMemberPrice
          : o.CustomPrice
            ? o.Qty * o.CustomPrice
            : o.Price
        : o.Qty *
          (o.CustomMemberPrice ||
            (o.AddonYN === 'Y'
              ? o.Data.Assembly.AddOnMemberPrice
              : o.Data.Assembly.MemberPrice)),
    )
    let premiumPrice = _.sumBy(components, o =>
      !o.Data.Assembly
        ? o.CustomPrice
          ? o.Qty * o.CustomPrice
          : o.Price
        : o.Qty *
          (o.AddonYN === 'Y'
            ? o.Data.Assembly.PremiumAddOnPrice || o.Data.Assembly.AddOnPrice
            : o.Data.Assembly.PremiumPrice || o.Data.Assembly.Price),
    )

    let price = this.state.existingComponents
      ? ogPrice
      : SelectedPricing === 'M'
        ? memberPrice
        : SelectedPricing === 'P'
          ? premiumPrice
            ? premiumPrice
            : 0
          : normalPrice

    if (opt) {
      let discountTotal = 0
      if (option.DiscountType === 'P') {
        discountTotal = price * (option.DiscountAmt / 100)
      } else {
        discountTotal = option.DiscountAmt
      }
      option.DiscountTotal = discountTotal
    }

    const maxPriceComponent = _.maxBy(
      _.filter(components, o => Boolean(o.Data && o.Data.Assembly)),
      o => o.Data.Assembly.Price,
    )

    components.map(component => {
      const {
        Qty,
        CustomPrice,
        CustomMemberPrice,
        Price,
        Data,
        Component,
        AddonYN,
      } = component
      const { Assembly } = component.Data

      let addOnAdjusted = AddonYN

      if (Assembly && component.Component === maxPriceComponent.Component) {
        addOnAdjusted = null
      }
      if (Assembly && component.Component !== maxPriceComponent.Component) {
        if (!component.AddonYN) {
          addOnAdjusted = Assembly.AddonYN
        }
      }

      const ogPriceComp = Price
      const normalPriceComp = !Assembly
        ? CustomPrice
          ? Qty * CustomPrice
          : Price
        : Qty *
          (CustomPrice ||
            (addOnAdjusted === 'Y' ? Assembly.AddOnPrice : Assembly.Price))
      let memberPriceComp = !Assembly
        ? CustomMemberPrice
          ? Qty * CustomMemberPrice
          : CustomPrice
            ? Qty * CustomPrice
            : Price
        : Qty *
          (CustomMemberPrice ||
            (addOnAdjusted === 'Y'
              ? Assembly.AddOnMemberPrice
              : Assembly.MemberPrice))
      let premiumPriceComp = !Assembly
        ? CustomPrice
          ? Qty * CustomPrice
          : Price
        : Qty *
          (addOnAdjusted === 'Y'
            ? Assembly.PremiumAddOnPrice || Assembly.AddOnPrice
            : Assembly.PremiumPrice || Assembly.Price)

      // const ogPriceComp = component.Price;
      // const normalPriceComp = !Qty * (component.CustomPrice || !component.Data.Assembly ? component.Price : (component.AddonYN === 'Y' ? component.Data.Assembly.AddOnPrice : component.Data.Assembly.Price));
      // let memberPriceComp = Qty * (component.CustomMemberPrice || !component.Data.Assembly ? component.CustomPrice || component.Price : (component.AddonYN === 'Y' ? component.Data.Assembly.AddOnMemberPrice : component.Data.Assembly.MemberPrice));
      // let premiumPriceComp = Qty * !component.Data.Assembly ? component.CustomPrice || component.Price : (component.AddonYN === 'Y' ? component.Data.Assembly.PremiumAddOnPrice || component.Data.Assembly.AddOnPrice : component.Data.Assembly.PremiumPrice || component.Data.Assembly.Price);
      let priceComp =
        SelectedPricing === 'M'
          ? memberPriceComp
          : SelectedPricing === 'P'
            ? premiumPriceComp
              ? premiumPriceComp
              : normalPriceComp
            : normalPriceComp

      component.ID = null
      component.EnteredBy = null
      component.EnteredDate = null
      component.UpdatedBy = null
      component.UpdatedDate = null
      component.PriceMethod = 'F'
      component.Price = priceComp
    })
    // window["warn"]('Selected Option: ', opt, option, components);
    this.setState(
      {
        ...this.state,
        Description,
        Signature: null,
        AcceptedDate: null,
        AcceptedBy: null,
        AcceptedYN: null,
        simpleStep: opt ? steps.length - 1 : simpleStep,
        SelectedOption:
          !option || !opt
            ? null
            : option
              ? option.Option
              : opt
                ? opt.Option
                : null,
        Data: {
          ...this.state.Data,
          Components: [...Components, ...components],
        },
        opt: opt ? opt : null,
        DiscountAmt: opt ? option.DiscountAmt : null,
        DiscountType: opt ? option.DiscountType : null,
        DiscountTotal: opt ? option.DiscountTotal : null,
      },
      this.calculateEstimatePrice,
    )
  }

  openMenu = e => {
    this.setState({ ...this.state, menuEl: e.target })
  }

  closeMenu = () => {
    this.setState({ ...this.state, menuEl: null })
  }

  confirmCloseDialog = () => {
    const { selectedComponent, selectedTMItem, tab } = this.state
    if (tab === 1) {
      this.setState({ tab: 0 })
    } else {
      if (selectedComponent || selectedTMItem) {
        this.setState({ selectedComponent: null, selectedTMItem: null })
      } else {
        const est = this.state
        if (
          (est.Data &&
            est.Data.Components &&
            est.Data.Components.length > 0 &&
            !est.ID) ||
          (!est.ID &&
            est.Data &&
            est.Data.OptionBoard &&
            est.Data.OptionBoard.Data &&
            est.Data.OptionBoard.Data.Options &&
            est.Data.OptionBoard.Data.Options.length > 0)
        ) {
          this.setState({ ...this.state, confirmExit: true })
        } else {
          this.closeComposeDialog()
        }
      }
    }
  }

  setDatepickerOpen = bool => {
    this.setState({ isDatepickerOpen: bool })
  }

  setExpirationDatepickerOpen = bool => {
    this.setState({ isExpirationDatepickerOpen: bool })
  }

  calculateTax = amt => {
    let total = 0
    const { Co, BusinessUnit, Division, IncludeTaxYN } = this.state
    const { Site } = this.state.Data
    if (Site && IncludeTaxYN === 'Y') {
      let { TaxCode } = Site
      if (!TaxCode) {
        const div = _.find(this.props.divisions, {
          Co,
          BusinessUnit,
          Division,
        })
        if (div && div.DefaultTaxCode) {
          TaxCode = div.DefaultTaxCode
        } else {
          const bu = _.find(this.props.businessUnits, { Co, BusinessUnit })
          if (bu && bu.DefaultTaxCode) {
            TaxCode = bu.DefaultTaxCode
          }
        }
      }
      if (TaxCode) {
        const { taxCodes } = this.props
        const taxCode = _.find(taxCodes, { TaxCode })
        total =
          amt *
          (taxCode
            ? new Date(taxCode.EffectiveDate) <= new Date()
              ? taxCode.NewRate / 100
              : taxCode.OldRate / 100
            : 0)
      }
    }
    return total
  }

  setOptionsType = optionType => {
    const { simpleStep, optionBoardType, estimateType } = this.state
    const Options = []
    const { Co, BusinessUnit, Division, OptionBoard, Data } =
      this.state.Data.OptionBoard
    const oldOptions = Data.Options
    optionType.Data.Options.map((value, index) => {
      const { Name, Option } = value
      const oldOption = oldOptions ? oldOptions[index] : null
      const option = {
        ...(oldOption || _.cloneDeepWith(newOption)),
        Co,
        BusinessUnit,
        Division,
        OptionBoard,
        Option,
        Name,
      }
      Options.push(option)
    })
    this.setState({
      ...this.state,
      optionBoardType: optionType.OptionsType,
      Data: {
        ...this.state.Data,
        OptionBoard: {
          ...this.state.Data.OptionBoard,
          OptionsType: optionType.OptionsType,
          Data: { ...Data, Options },
        },
      },
      simpleStep: simpleStep + 1,
    })
  }

  setOptionBoard = () => {
    const {
      simpleStep,
      OptionBoard,
      SelectedOption,
      Data,
      estimateType,
      Co,
      BusinessUnit,
      Division,
    } = this.state
    if (estimateType !== 'O') {
      this.setState({
        estimateType: 'O',
        simpleStep: simpleStep + 1,
        OptionBoard: Data.OptionBoard ? OptionBoard : null,
        SelectedOption: Data.OptionBoard ? SelectedOption : null,
        Data: {
          ...Data,
          OptionBoard: Data.OptionBoard || {
            ..._.cloneDeepWith(newOptionBoard),
            Co,
            BusinessUnit,
            Division,
          },
        },
      })
    } else {
      this.setState({ simpleStep: simpleStep + 1 })
    }
  }

  setOptionComponents = (option, Components, existing) => {
    const { OptionBoard } = this.state.Data
    const { Option } = option
    const opt = _.find(OptionBoard.Data.Options, { Option })
    const { selectedComponent } = this.state
    const shouldClose =
      existing ||
      !Boolean(
        selectedComponent &&
          selectedComponent.Data &&
          selectedComponent.Data.Recommendations &&
          selectedComponent.Data.Recommendations.length > 0,
      )
    if (opt) {
      opt.Data.Components = Components
      this.setState(
        {
          Data: { ...this.state.Data, OptionBoard },
          selectedComponent: shouldClose ? null : selectedComponent,
        },
        this.calculateEstimatePrice,
      )
    }
  }

  handleDueByChange = event => {
    let { DueBy } = this.state
    DueBy = event._d.toLocaleDateString('en-US')
    // window["warn"](event, DueBy);
    this.setState(
      { ...this.state, workOrder: { ...this.state.workOrder, DueBy } },
      () => {
        this.checkSchedule()
      },
    )
  }

  checkSchedule = () => {
    const { Co } = this.props
    let { DueBy } = this.state.workOrder
    const dt = new Date(DueBy).toLocaleDateString('en-US').replace(/\//g, '-')
    this.props.getScheduledTrips(Co, dt)
  }

  togglePricing = () => {
    const { hidePricing } = this.state
    this.setState({ hidePricing: !hidePricing })
  }

  handlePayment = payment => {
    const { Co, Data } = this.state
    if (payment) {
      // if (Data && Data.PaymentDetail) {
      //     Data.PaymentDetail.push(payment);
      // }
      if (Data && Data.Payments) {
        Data.Payments.push(
          !payment.ID
            ? {
                Co,
                PaymentID: payment.ID,
                PaymentDateTime: payment.PaymentDateTime,
                PaymentMethodType: payment.Data.PaymentMethod
                  ? payment.Data.PaymentMethod.PaymentMethodType
                  : payment.PaymentType,
                Nickname: payment.Data.PaymentMethod
                  ? payment.Data.PaymentMethod.Nickname
                  : '',
                CardLastFour: payment.Data.PaymentMethod
                  ? payment.Data.PaymentMethod.CardLastFour
                  : null,
                Amount: payment.Data.Allocations[0].Amount,
                Check: payment.Check,
                Memo: payment.Memo,
                Data: payment.Data,
              }
            : payment,
        )
      }
      this.setState({
        ...this.state,
        PaymentsInvoice: payment.PaymentsInvoice,
        Data,
      })
    }
  }

  handleVoidPayment = payment => {
    const { User } = this.props
    const { Data } = this.state
    if (payment) {
      payment.VoidDate = payment.VoideDate || new Date()
      payment.VoidedBy = payment.VoidedBy || User.UserName
      if (Data && Data.Payments) {
        const index = _.findIndex(Data.Payments, {
          PaymentID: payment.PaymentID,
        })
        // window["warn"]('Voided Payment: ', payment, index, Data);
        if (index > -1) {
          Data.Payments.splice(index, 1, payment)
        }
      }
      // if (Data && Data.PaymentDetail) {
      //     const index = _.findIndex(Data.PaymentDetail, { PaymentID: payment.PaymentID });
      //     window["warn"]('Voided Payment: ', payment, index, Data);
      //     if (index > -1) {
      //         Data.PaymentDetail.splice(index, 1, payment);
      //     }
      // }
      this.setState({ ...this.state, Data })
    }
  }

  closeRecommendations = event => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({ ...this.state, showRecommendations: false })
    }
  }

  recommendationsOnUpdated = (Components, currentOption) => {
    const { Data, selectedComponent } = this.state
    if (Data.OptionBoard) {
      // window["warn"]("recommendationsOnUpdated", currentOption, Components)
      this.setOptionComponents(currentOption, Components)
    } else {
      this.setState({
        ...this.state,
        Data: { ...this.state.Data, Components },
        selectedComponent: !Boolean(
          selectedComponent &&
            selectedComponent.Data &&
            selectedComponent.Data.Recommendations &&
            selectedComponent.Data.Recommendations.length > 0,
        )
          ? null
          : selectedComponent,
      })
    }
  }

  onUpdate = (currentOption, Components, existing) => {
    const { Data, hideSummary, selectedComponent } = this.state
    if (!currentOption) {
      this.setState(
        {
          ...this.state,
          Data: { ...Data, Components },
          hideSummary: Components.length > 0 ? hideSummary : true,
          selectedComponent:
            !Boolean(
              selectedComponent &&
                selectedComponent.Data &&
                selectedComponent.Data.Recommendations &&
                selectedComponent.Data.Recommendations.length > 0,
            ) || existing
              ? null
              : selectedComponent,
        },
        this.calculateEstimatePrice,
      )
    } else {
      this.setOptionComponents(currentOption, Components, existing)
    }
  }

  addDiscount = (e, subtotal, optionBoard, SelectedOption) => {
    e.stopPropagation()
    const { DiscountType, discountAmt, opt } = this.state
    let discountTotal = 0
    if (DiscountType === 'P') {
      discountTotal = subtotal * (discountAmt / 100)
    } else {
      discountTotal = discountAmt
    }

    if (optionBoard && SelectedOption) {
      const optionIndex = opt
        ? _.findIndex(optionBoard.Data.Options, { Option: opt.Option })
        : SelectedOption - 1
      optionBoard.Data.Options[optionIndex].DiscountAmt = discountAmt
      optionBoard.Data.Options[optionIndex].DiscountType = DiscountType
      optionBoard.Data.Options[optionIndex].DiscountTotal = discountTotal

      // window["warn"]("discount subtotal", subtotal, optionBoard, this.state.opt)
    }

    this.setState(
      {
        ...this.state,
        DiscountAmt: discountAmt,
        DiscountTotal: discountTotal,
        addDiscount: null,
        Data: {
          ...this.state.Data,
          OptionBoard: optionBoard ? { ...optionBoard } : null,
        },
      },
      this.calculateEstimatePrice,
    )
  }

  deleteDiscount = (e, optionBoard, SelectedOption) => {
    e.stopPropagation()
    const { opt } = this.state

    if (optionBoard && SelectedOption) {
      const optionIndex = opt
        ? _.findIndex(optionBoard.Data.Options, { Option: opt.Option })
        : null
      optionBoard.Data.Options[optionIndex].DiscountAmt = null
      optionBoard.Data.Options[optionIndex].DiscountType = null
      optionBoard.Data.Options[optionIndex].DiscountTotal = null
    }

    this.setState(
      {
        ...this.state,
        DiscountType: null,
        DiscountAmt: null,
        DiscountTotal: null,
        discountAmt: null,
        addDiscount: null,
        Data: {
          ...this.state.Data,
          OptionBoard: optionBoard ? { ...optionBoard } : null,
        },
      },
      this.calculateEstimatePrice,
    )
  }

  toggleTaxability = () => {
    const { IncludeTaxYN } = this.state

    this.setState(
      { ...this.state, IncludeTaxYN: IncludeTaxYN === 'Y' ? 'N' : 'Y' },
      this.calculateEstimatePrice,
    )
  }

  addUpdateSelectedPricing = (e, value) => {
    e.stopPropagation()
    this.setState({ ...this.state, SelectedPricing: value })
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const {
      theme,
      classes,
      addEstimate,
      securables,
      users,
      catalogs,
      materials,
      allCatalogs,
      updateEstimate,
      removeEstimate,
      departments,
      arrivalWindows,
      serviceTypes,
      laborTypes,
      services,
      schedule,
      formOfPayments,
      openEditWorkOrderUsageDialog,
      Co,
      companies,
      technicians,
      tech,
      assemblies,
      businessUnits,
      divisions,
      downloadCatalog,
      optionTypes,
    } = this.props
    const {
      estimateEmail,
      simpleStep,
      BusinessUnit,
      Division,
      downloading,
      downloadError,
      OptionBoard,
      SelectedOption,
      menuEl,
      estimateType,
      optionBoardType,
      newOrExisting,
      selectedComponent,
      selectedTMItem,
      hideSummary,
      Data,
      prevStep,
      tab,
      hidePricing,
      showRecommendations,
      SelectedPricing,
      Status,
    } = this.state
    const est = this.state
    let steps = ['Select Price Book', 'Select Proposal Type']
    if (estimateType) {
      if (estimateType === 'S') {
        steps = [
          'Select Price Book',
          'Select Proposal Type',
          'Tasks & Assemblies',
          'Proposal Detail',
          'Review and Submit',
        ]
      }
      if (estimateType === 'O') {
        steps = [
          'Select Price Book',
          'Select Proposal Type' /*, "New or Existing"*/,
        ]
        if (newOrExisting !== '+') {
          steps.push('Select Option Board')
        }
        if (newOrExisting === '+') {
          steps.push('Option Board Type')
        }
        if (
          Data.OptionBoard &&
          Data.OptionBoard.Data &&
          Data.OptionBoard.Data.Options
        ) {
          Data.OptionBoard.Data.Options.map((opt, index) => {
            steps.push(
              `Option #${index + 1} of ${
                Data.OptionBoard.Data.Options.length
              } - ${opt.Name}`,
            )
          })
        }
        steps = [
          ...steps,
          'Proposal Detail',
          'Presentation Mode',
          'Review and Submit',
        ]
      }
    }
    const EstimateType = catalog ? catalog.DefaultEstimateType : 'Q'
    const terminology = terms[EstimateType]
    const optionBoard = this.state.Data.OptionBoard
      ? this.state.Data.OptionBoard
      : catalog
        ? _.find(catalog.Data.OptionBoards, {
            Co,
            BusinessUnit,
            Division,
            OptionBoard,
          })
        : null
    const currentOption =
      estimateType === 'O' &&
      Data.OptionBoard &&
      simpleStep > 2 &&
      simpleStep < steps.length - 3
        ? Data.OptionBoard.Data.Options[simpleStep - 3]
        : null
    const catalog = _.find([...catalogs], { Co, BusinessUnit, Division })
    const woAccessLevel = _.find(securables, {
      Securable: 'estimate-work-orders',
    })
    const division = _.find(divisions, { Co, BusinessUnit, Division })

    return (
      <>
        {tab === 0 &&
          estimateType &&
          estimateType === 'O' &&
          optionBoard &&
          optionBoard.Data.Options &&
          simpleStep === steps.length - 2 && (
            <Tooltip title='Toggle Pricing'>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 104,
                  top: matches ? 6 : 4,
                  color: 'white',
                }}
                onClick={() => {
                  this.togglePricing()
                }}
                className='dialog-header-icon'
              >
                <Icon>{hidePricing ? 'visibility' : 'visibility_off'}</Icon>
              </IconButton>
            </Tooltip>
          )}
        <IconButton
          style={{
            position: 'absolute',
            right:
              tab === 0 &&
              estimateType &&
              estimateType === 'O' &&
              optionBoard &&
              optionBoard.Data.Options &&
              simpleStep === steps.length - 2
                ? 152
                : 104,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 56,
            top: matches ? 6 : 4,
            color: 'white',
          }}
          onClick={e => {
            this.setState({ anchorEl: e.target })
          }}
          className='dialog-header-icon'
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          id='estimate-menu'
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          <MenuItem onClick={() => this.setState({ tab: 1, anchorEl: null })}>
            <Icon className='mr-8'>settings</Icon>Terms &amp; Conditions
          </MenuItem>
          {est.ID && est.AssignedToWorkOrder && (
            <MenuItem
              onClick={() => {
                this.setState({
                  scheduleWork: true,
                  anchorEl: null,
                })
              }}
            >
              <Icon className='mr-8'>today</Icon>
              Scheduled Work
            </MenuItem>
          )}
          {est.ID &&
            !est.AssignedToWorkOrder &&
            woAccessLevel &&
            woAccessLevel.AccessLevel !== 'R' && (
              <MenuItem
                onClick={() => {
                  const {
                    Co,
                    Customer,
                    Site,
                    BusinessUnit,
                    Division,
                    Description,
                    PriceTotal,
                    Estimate,
                  } = this.state
                  const { DefaultRateTemplate, DefaultTaxCode } = division
                  const { Components } = this.state.Data
                  const comp = _.find(Components, o =>
                    Boolean(o.DeleteYN !== 'Y' && o.Data && o.Data.Assembly),
                  )
                  const assembly = comp ? comp.Data.Assembly : {}
                  const { Department, ServiceType, Service } = assembly
                  this.setState({
                    scheduleWork: true,
                    workOrder:
                      this.state.workOrder && this.state.workOrder.WorkOrder
                        ? this.state.workOrder
                        : {
                            Co,
                            Customer,
                            Site,
                            BusinessUnit,
                            Division,
                            Department,
                            ServiceType,
                            Service,
                            Description,
                            Priority: 3,
                            PriceMethod: 'F',
                            TaxableYN: 'Y',
                            RateTemplate: DefaultRateTemplate,
                            TaxCode: DefaultTaxCode,
                            Price: PriceTotal,
                            Estimate,
                            Data: {
                              ErrMsg: null,
                              Trips: [],
                              Tags: [],
                            },
                          },
                    anchorEl: null,
                  })
                }}
              >
                <Icon className='mr-8'>today</Icon>
                Schedule Work Order
              </MenuItem>
            )}
          {est.ID && (
            <MenuItem
              onClick={() => {
                this.setState({ anchorEl: null }, () => this.emailEstimate())
                window['warn'](this.state, this.props.customers)
              }}
            >
              <Icon className='mr-8'>email</Icon>Email Estimate
            </MenuItem>
          )}
          {est.ID && (
            <MenuItem
              onClick={() =>
                this.setState({ showEmailLogs: true, anchorEl: null })
              }
            >
              <Icon className='mr-8'>send</Icon>View Email Log
            </MenuItem>
          )}
          {est.ID && simpleStep === steps.length - 1 && (
            <MenuItem onClick={this.printPDF}>
              <Icon className='mr-8'>print</Icon>View PDF
            </MenuItem>
          )}
        </Menu>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 8 : 4,
            color: 'white',
          }}
          onClick={closeFxn}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
      </>
    )
  }

  ToolbarContents = () => {
    const {
      theme,
      classes,
      addEstimate,
      securables,
      users,
      catalogs,
      materials,
      allCatalogs,
      updateEstimate,
      removeEstimate,
      departments,
      arrivalWindows,
      serviceTypes,
      laborTypes,
      services,
      schedule,
      formOfPayments,
      openEditWorkOrderUsageDialog,
      Co,
      companies,
      technicians,
      tech,
      assemblies,
      businessUnits,
      divisions,
      downloadCatalog,
      optionTypes,
    } = this.props
    const {
      estimateEmail,
      simpleStep,
      BusinessUnit,
      Division,
      downloading,
      downloadError,
      OptionBoard,
      SelectedOption,
      menuEl,
      estimateType,
      optionBoardType,
      newOrExisting,
      selectedComponent,
      selectedTMItem,
      hideSummary,
      Data,
      prevStep,
      tab,
      hidePricing,
      showRecommendations,
      SelectedPricing,
      Status,
    } = this.state
    const est = this.state

    const catalog = _.find([...catalogs], { Co, BusinessUnit, Division })

    let steps = ['Select Price Book', 'Select Proposal Type']
    if (estimateType) {
      if (estimateType === 'S') {
        steps = [
          'Select Price Book',
          'Select Proposal Type',
          'Tasks & Assemblies',
          'Proposal Detail',
          'Review and Submit',
        ]
      }
      if (estimateType === 'O') {
        steps = [
          'Select Price Book',
          'Select Proposal Type' /*, "New or Existing"*/,
        ]
        if (newOrExisting !== '+') {
          steps.push('Select Option Board')
        }
        if (newOrExisting === '+') {
          steps.push('Option Board Type')
        }
        if (
          Data.OptionBoard &&
          Data.OptionBoard.Data &&
          Data.OptionBoard.Data.Options
        ) {
          Data.OptionBoard.Data.Options.map((opt, index) => {
            steps.push(
              `Option #${index + 1} of ${
                Data.OptionBoard.Data.Options.length
              } - ${opt.Name}`,
            )
          })
        }
        steps = [
          ...steps,
          'Proposal Detail',
          'Presentation Mode',
          'Review and Submit',
        ]
      }
    }

    const EstimateType = catalog ? catalog.DefaultEstimateType : 'Q'
    const terminology = terms[EstimateType]
    const optionBoard = this.state.Data.OptionBoard
      ? this.state.Data.OptionBoard
      : catalog
        ? _.find(catalog.Data.OptionBoards, {
            Co,
            BusinessUnit,
            Division,
            OptionBoard,
          })
        : null
    const currentOption =
      estimateType === 'O' &&
      Data.OptionBoard &&
      simpleStep > 2 &&
      simpleStep < steps.length - 3
        ? Data.OptionBoard.Data.Options[simpleStep - 3]
        : null

    return (
      <Typography
        variant='subtitle1'
        className='truncate w-3/4'
        color='inherit'
      >
        {!selectedComponent && this.state.tab === 0 ? (
          <SPRoundIcon className='text-32 align-middle mr-8 mb-4' />
        ) : (
          <Icon
            onClick={() => this.setState({ selectedComponent: null, tab: 0 })}
            className='text-32 align-middle mr-8 mb-4 cursor-pointer'
          >
            keyboard_arrow_left
          </Icon>
        )}
        {tab === 1
          ? 'Terms & Conditions'
          : !this.state.ID ||
              simpleStep < steps.length - (estimateType === 'O' ? 2 : 1)
            ? `${simpleStep + 1}. ${steps[simpleStep]}`
            : `Quote #${est.EstimateNumber}`}
      </Typography>
    )
  }

  heightUpdateFxn = (position, finalPosition, dialogHeight) => {
    // window["warn"]("height update fxn Esitmate ran!", position, finalPosition, dialogHeight)
    this.setState({
      ...this.state,
      location: position,
      posSet: finalPosition,
      dialogHeight: dialogHeight,
    })
  }

  render() {
    const { estimateDialog } = this.props
    if (!estimateDialog.props.open) {
      return ''
    } else {
      const {
        theme,
        classes,
        addEstimate,
        securables,
        users,
        catalogs,
        materials,
        allCatalogs,
        updateEstimate,
        removeEstimate,
        departments,
        arrivalWindows,
        serviceTypes,
        laborTypes,
        services,
        schedule,
        formOfPayments,
        openEditWorkOrderUsageDialog,
        Co,
        companies,
        technicians,
        tech,
        assemblies,
        businessUnits,
        divisions,
        downloadCatalog,
        optionTypes,
      } = this.props
      const { trip } = estimateDialog.props
      const est = this.state
      const issuedByTech = est.IssuedBy
        ? _.find(technicians, { Technician: est.IssuedBy })
        : tech
      const {
        estimateEmail,
        simpleStep,
        BusinessUnit,
        Division,
        downloading,
        downloadError,
        OptionBoard,
        SelectedOption,
        menuEl,
        estimateType,
        optionBoardType,
        newOrExisting,
        selectedComponent,
        selectedTMItem,
        hideSummary,
        Data,
        prevStep,
        tab,
        hidePricing,
        showRecommendations,
        SelectedPricing,
        Status,
      } = this.state
      const co = _.find(companies, { Co }).Data.Co
      const paymentTypes = {
        visa: 'Visa',
        mastercard: 'MasterCard',
        master: 'MasterCard',
        discover: 'Discover',
        american_express: 'American Express',
        amex: 'American Express',
        am_ex: 'American Express',
      }
      // const User = !this.state.EnteredBy ? this.props.User : _.findLast(this.props.Users, (n) => { return n.UserName === this.state.EnteredBy });
      const accessLevel = _.find(securables, { Securable: 'estimates' })
      const woAccessLevel = _.find(securables, {
        Securable: 'estimate-work-orders',
      })
      const userAvatar = '../../assets/images/avatars/profile.jpg'
      const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
      const disabled = false
      let steps = ['Select Price Book', 'Select Proposal Type']
      if (estimateType) {
        if (estimateType === 'S') {
          steps = [
            'Select Price Book',
            'Select Proposal Type',
            'Tasks & Assemblies',
            'Proposal Detail',
            'Review and Submit',
          ]
        }
        if (estimateType === 'O') {
          steps = [
            'Select Price Book',
            'Select Proposal Type' /*, "New or Existing"*/,
          ]
          if (newOrExisting !== '+') {
            steps.push('Select Option Board')
          }
          if (newOrExisting === '+') {
            steps.push('Option Board Type')
          }
          if (
            Data.OptionBoard &&
            Data.OptionBoard.Data &&
            Data.OptionBoard.Data.Options
          ) {
            Data.OptionBoard.Data.Options.map((opt, index) => {
              steps.push(
                `Option #${index + 1} of ${
                  Data.OptionBoard.Data.Options.length
                } - ${opt.Name}`,
              )
            })
          }
          steps = [
            ...steps,
            'Proposal Detail',
            'Presentation Mode',
            'Review and Submit',
          ]
        }
      }
      const paymentDue = { P: 'Prior to Start', C: 'Upon Completion' }

      //calculateEstimatePrice

      const catalog = _.find([...catalogs], { Co, BusinessUnit, Division })

      const activeAgreements =
        this.state &&
        this.state.Data &&
        this.state.Data.Site &&
        this.state.Data.Site.Data &&
        this.state.Data.Site.Data.Agreements
          ? _.filter(
              this.state.Data.Site.Data.Agreements,
              o =>
                o.ActiveYN !== 'N' &&
                new Date(o.StartDate) <=
                  new Date().setDate(new Date().getDate() + 1) &&
                new Date(o.EndDate) >= new Date(),
            )
          : null
      const isMember = Boolean(activeAgreements && activeAgreements.length > 0)
      //const isMember = this.state.isMember === null ? Boolean(activeAgreements && activeAgreements.length > 0) : this.state.isMember;

      //const isMember = Boolean(activeAgreements && activeAgreements.length > 0)
      // window["warn"]('Active Agreements: ', activeAgreements, isMember);

      // window["warn"]('Estimate Catalog: ', this.state, catalog, steps, simpleStep);
      const EstimateType = catalog ? catalog.DefaultEstimateType : 'Q'
      const terminology = terms[EstimateType]
      const optionBoard = this.state.Data.OptionBoard
        ? this.state.Data.OptionBoard
        : catalog
          ? _.find(catalog.Data.OptionBoards, {
              Co,
              BusinessUnit,
              Division,
              OptionBoard,
            })
          : null
      const currentOption =
        estimateType === 'O' &&
        Data.OptionBoard &&
        simpleStep > 2 &&
        simpleStep < steps.length - 3
          ? Data.OptionBoard.Data.Options[simpleStep - 3]
          : null

      // if (isMember && this.state.isMember === null && Status === 0) {
      //     this.setState({ ...this.state, SelectedPricing: "M", isMember: true });
      // } else if(!isMember && this.state.isMember === null && Status === 0){
      //     this.setState({ ...this.state, isMember: false });
      // }

      //const sub = this.state.sub ? this.state.sub : this.state.PriceTotal

      const sub = this.state.sub
      let tax = 0.0
      let total = 0.0

      if (this.state.DiscountTotal) {
        tax = this.calculateTax(sub - this.state.DiscountTotal)
        // window["warn"]("SelectedPricing", SelectedPricing, sub, this.state.DiscountTotal, tax, (sub - this.state.DiscountTotal))
        total = sub - this.state.DiscountTotal + tax
      } else {
        tax = this.calculateTax(sub)
        total = sub + tax
      }

      let multipleOfSameComponent = false
      let components = currentOption
        ? currentOption.Data.Components
        : this.state.Data.Components

      components = components
        ? _.filter(components, o => o.DeleteYN !== 'Y')
        : []

      // window["warn"]("Selected Pricing & Components", SelectedPricing, components, sub, this.state.DiscountTotal)

      const payments =
        est.Data && est.Data.Payments
          ? _.sumBy(est.Data.Payments, o =>
              o.Data && o.Data.Payment && o.Data.Payment.VoidDate
                ? 0
                : o.Total || o.Amount,
            )
          : 0
      const due = total - payments
      const division = _.find(divisions, { Co, BusinessUnit, Division })

      if (components && components.length > 0 && selectedComponent) {
        let componentList = [...components]
        let multiples = _.remove(
          componentList,
          o => o.Assembly === selectedComponent.Assembly,
        )
        if (multiples.length > 1) {
          multipleOfSameComponent = true
        }
      }

      window['warn']('est selectedTMItem', selectedTMItem)

      const comps = currentOption
        ? currentOption.Data.Components
        : this.state.Data.Components

      let Recommendations = []
      let tasks = []
      let recs

      if (catalog) {
        // Add all recommendations to an array and upgrades to another array
        _.filter(comps, o => Boolean(o.Assembly)).forEach(comp => {
          const assy = _.find(catalog.Data.Assemblies, o => {
            return o.ID === comp.Assembly
          })
          if (assy) {
            Recommendations = _.uniqBy(
              Recommendations.concat(assy.Data.Recommendations),
              'RecommendedAssembly',
            )
          }
        })

        // Reverse order of Recommmendations so that the most recently added item's recommendations show first
        Recommendations.reverse()

        // If a comp is in the recommendation array, remove it from the array
        _.filter(comps, o => Boolean(o.Assembly)).forEach(comp => {
          recs = _.remove(Recommendations, o => {
            return o.RecommendedAssembly === comp.Data.Assembly.Assembly
          })
        })

        tasks = catalog.Data.Assemblies
      }

      let estimate = !currentOption ? this.state : currentOption

      const dialogProps = {
        ...estimateDialog.props,
        className: classes.root,
        onClose: this.closeComposeDialog,
        fullWidth: true,
        maxWidth: 'md',
        //hideBackdrop: true,
        disableEnforceFocus: true,
      }

      // window["warn"]("estimate state 333", this.props.estimateDialog)

      let initialWidthSet =
        this.props.estimateDialog &&
        this.props.estimateDialog.data &&
        this.props.estimateDialog.data.initialDialogWidth === null
          ? this.props.estimateDialog.data.initialDialogWidth
          : 960

      // window["warn"]('Trip Info: ', trip);
      return (
        <Media query='(min-width: 1200px)'>
          {matches => (
            <React.Fragment>
              {estimateEmail && (
                <MailCompose
                  contacts={
                    this.state.Data.Customer && this.state.Data.Customer
                  }
                  dbAttachments={[
                    ...(this.state.Data.FromWorkOrder
                      ? this.state.Data.FromWorkOrder.Data.Attachments || []
                      : []),
                  ]}
                  onClose={() =>
                    this.setState({ ...this.state, estimateEmail: null })
                  }
                  hideButton={true}
                  data={estimateEmail}
                />
              )}
              <DraggableDialog
                SlideUp={SlideUp}
                matches={matches}
                dialogProps={{ ...dialogProps }}
                AppBarChildren={this.AppBarContents}
                ToolbarChildren={this.ToolbarContents}
                closeComposeDialog={this.closeComposeDialog.bind(this)}
                closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
                  this,
                )}
                header={`Quote #${this.state.EstimateNumber}`}
                type='Estimate'
                picRef={this.picRef.current}
                icon='receipt'
                dialogState={this.state}
                reopenDialogFxn={this.props.openPreloadEstimateDialog}
                description={this.state.Description}
                storeDialog={estimateDialog}
                TypeState={this.state.EstimateNumber}
                screenOverlay={this.state.screenOverlay}
                posSet={this.state.posSet}
                initialWidthSet={initialWidthSet}
                minHeight={this.state.minHeight}
                location={this.state.location}
                heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                dialogHeight={this.state.dialogHeight}
              >
                {this.state.printPDF && (
                  <Dialog
                    classes={{
                      paper: classNames(
                        'mt-0 mb-0 w-full h-full',
                        !matches && 'full-screen-dialog',
                      ),
                    }}
                    open={this.state.printPDF}
                    onClose={() =>
                      this.setState({ printPDF: null, anchorEl: null })
                    }
                    maxWidth='sm'
                    fullScreen={!matches}
                  >
                    <AppBar position='static' className='dialog-header'>
                      <Toolbar className='flex w-full'>
                        <Typography variant='subtitle1' color='inherit'>
                          <Icon className='mr-6 align-middle mb-2'>print</Icon>
                          {est.EstimateNumber}.pdf
                        </Typography>
                      </Toolbar>
                      <IconButton
                        style={{
                          position: 'absolute',
                          right: 10,
                          top: matches.medium ? 8 : 4,
                          color: 'white',
                        }}
                        onClick={() => {
                          this.setState({ printPDF: null, anchorEl: null })
                        }}
                        className='dialog-header-icon'
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </AppBar>
                    <DialogContent
                      classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}
                    >
                      <iframe
                        src={this.state.printPDF}
                        className='w-full overflow-auto'
                        style={{ height: 'calc(100% - 24px)' }}
                      />
                    </DialogContent>
                  </Dialog>
                )}
                <EmailLogDialog
                  title={`Estimate #${est.EstimateNumber} Email Log`}
                  type='ESTIMATE'
                  rec={est.ID}
                  open={this.state.showEmailLogs}
                  onClose={() => this.setState({ showEmailLogs: false })}
                />
                <ConfirmationDialog
                  open={this.state.confirmExit}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4'>error</Icon>Are
                      You Sure?
                    </div>
                  }
                  content={
                    <div className='w-full pt-8 pb-8'>{`This estimate has not yet been saved. Are you sure you wish to exit without saving?`}</div>
                  }
                  confirmText='Confirm'
                  cancelText='Cancel'
                  onCancel={() =>
                    this.setState({ ...this.state, confirmExit: false })
                  }
                  onConfirm={() =>
                    this.setState({ ...this.state, confirmExit: false }, () => {
                      this.closeComposeDialog()
                    })
                  }
                />

                <ConfirmationDialog
                  open={this.state.confirmUpdate}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4 text-orange'>
                        warning
                      </Icon>
                      Warning
                    </div>
                  }
                  content={
                    <div className='w-full pt-8 pb-8'>{`This estimate has already been signed and accepted. Adding or removing items will cause the Customer's Signature to be cleared. Are you sure you wish to proceed?`}</div>
                  }
                  confirmText='Confirm'
                  cancelText='Cancel'
                  onCancel={() =>
                    this.setState({ ...this.state, confirmUpdate: false })
                  }
                  onConfirm={() => {
                    const components = this.state.confirmUpdate
                    this.setState(
                      {
                        ...this.state,
                        confirmUpdate: false,
                        Signature: null,
                        AcceptedYN: null,
                        AcceptedBy: null,
                        AcceptedDate: null,
                      },
                      () => {
                        if (components) this.onUpdate(currentOption, components)
                      },
                    )
                  }}
                />

                <DialogContent
                  classes={{ root: 'p-12 pb-84' }}
                  onClick={() => this.setState({ scheduleWork: false })}
                  ref={this.picRef}
                >
                  {(this.state.workOrder || this.state.AssignedToWorkOrder) && (
                    <Drawer
                      anchor='right'
                      variant='permanent'
                      className={clsx(
                        classNames(
                          classes.drawer,
                          'h-full pin-r pin-t absolute',
                        ),
                        {
                          [classes.drawerOpen]: this.state.scheduleWork,
                          [classes.drawerClose]: !this.state.scheduleWork,
                        },
                      )}
                      classes={{
                        paper: clsx('relative border-none h-full', {
                          [classes.drawerOpen]: this.state.scheduleWork,
                          [classes.drawerClose]: !this.state.scheduleWork,
                        }),
                      }}
                      open={this.state.scheduleWork}
                      onClose={() => this.setState({ scheduleWork: false })}
                      onClick={e => e.stopPropagation()}
                    >
                      <div className='w-full'>
                        <div className='w-full p-12 pt-16 sm:pt-20 relative'>
                          <Typography className='font-bold w-full truncate'>
                            <SPRoundIcon className='mr-6 align-middle mb-4' />
                            {this.state.AssignedToWorkOrder
                              ? `Work Order #${this.state.AssignedToWorkOrder} Scope #${this.state.AssignedToWorkOrderScope}`
                              : 'Schedule Work Order'}
                          </Typography>
                          <IconButton
                            className='pin-r pin-t absolute mt-8'
                            onClick={() =>
                              this.setState({ scheduleWork: false })
                            }
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        </div>
                        <div className='w-full px-12 py-6'>
                          {/* {this.state.Division === "*" && */}
                          <Autocomplete
                            className={'w-full mb-12'}
                            title='Division'
                            options={divisions}
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.Division}>
                                  {value.Description}
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            value={
                              (this.state.workOrder || {}).Division ||
                              this.state.Division ||
                              ''
                            }
                            onSelect={option => {
                              const { Co, BusinessUnit, Division } = option
                              const division =
                                _.find(this.props.divisions, {
                                  Co,
                                  BusinessUnit,
                                  Division,
                                }) || {}
                              const { DefaultRateTemplate, DefaultTaxCode } =
                                division
                              this.setState({
                                ...this.state,
                                workOrder: {
                                  ...this.state.workOrder,
                                  BusinessUnit: option.BusinessUnit,
                                  Division: option.Division,
                                  TaxCode: DefaultTaxCode,
                                  RateTemplate: DefaultRateTemplate,
                                },
                              })
                            }}
                            required
                            activeOnly={true}
                            filter={{
                              BusinessUnit:
                                (this.state.workOrder || {}).BusinessUnit ||
                                this.state.BusinessUnit,
                            }}
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          {/* } */}
                          <Autocomplete
                            className={'w-full mb-12'}
                            title='Department'
                            options={departments}
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.Department}>
                                  {value.Description}
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            value={
                              (this.state.workOrder || {}).Department ||
                              this.state.Department ||
                              ''
                            }
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                workOrder: {
                                  ...this.state.workOrder,
                                  BusinessUnit: option.BusinessUnit,
                                  Division: option.Division,
                                  Department: option.Department,
                                },
                              })
                            }
                            required
                            activeOnly={true}
                            filter={{
                              BusinessUnit: (this.state.workOrder || {})
                                .BusinessUnit,
                              Division: (this.state.workOrder || {}).Division,
                            }}
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          <Autocomplete
                            className={'w-full mb-12'}
                            title='Service Type'
                            options={serviceTypes}
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.ServiceType}>
                                  {`${value.Description}`}
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            value={
                              (this.state.workOrder || {}).ServiceType ||
                              this.state.ServiceType ||
                              ''
                            }
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                workOrder: {
                                  ...this.state.workOrder,
                                  ServiceType: option.ServiceType,
                                },
                              })
                            }
                            required
                            activeOnly={true}
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          <Autocomplete
                            className={'w-full mb-12'}
                            title='Service'
                            options={services}
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.Service}>
                                  {`${value.Service} - ${value.Description}`}
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            value={
                              (this.state.workOrder || {}).Service ||
                              this.state.Service ||
                              ''
                            }
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                workOrder: {
                                  ...this.state.workOrder,
                                  Service: option.Service,
                                  Priority:
                                    this.state.Priority ||
                                    (!isNaN(option.DefaultPriority) &&
                                      option.DefaultPriority <= 3 &&
                                      option.DefaultPriority >= 1)
                                      ? option.DefaultPriority
                                      : 3,
                                },
                              })
                            }
                            required
                            activeOnly={true}
                            filter={{
                              DefaultBusinessUnit:
                                (this.state.workOrder || {}).BusinessUnit ||
                                this.state.BusinessUnit,
                              DefaultDivision:
                                (this.state.workOrder || {}).Division ||
                                this.state.Division,
                              DefaultDepartment:
                                (this.state.workOrder || {}).Department ||
                                this.state.Department,
                            }}
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          <TextField
                            className={'w-full mb-12 block'}
                            label='Description'
                            id='description'
                            name='workOrder.Description'
                            value={
                              (this.state.workOrder || {}).Description ||
                              this.state.ScopeDescription ||
                              ''
                            }
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            variant='outlined'
                            multiline
                            required
                            rows={5}
                            fullWidth
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          <DatePicker
                            variant='inline'
                            inputVariant='outlined'
                            className={'w-full mb-12 block'}
                            label='Due By'
                            id='dueBy'
                            format='MM/DD/YYYY'
                            value={
                              (this.state.workOrder || {}).DueBy ||
                              this.state.DueBy
                            }
                            onChange={this.handleDueByChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <Icon>today</Icon>
                                </InputAdornment>
                              ),
                            }}
                            autoOk
                            required
                            fullWidth
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          <Autocomplete
                            className={'w-full mb-12'}
                            title='Lead Technician'
                            options={technicians}
                            menuItemComponent={value => {
                              const techSched = (this.state.workOrder || {})
                                .DueBy
                                ? _.filter(schedule, {
                                    Technician: value.Technician,
                                  })
                                : null
                              const duration = _.sumBy(techSched, 'Duration')
                              return (
                                <MenuItem value={value.Technician}>
                                  <div
                                    className={classNames('w-full flex mr-64')}
                                  >
                                    <Avatar
                                      style={{ marginRight: 8 }}
                                      classes={{ root: classes.avatarRoot }}
                                      className={classes.techAvatar}
                                      alt={value.Technician}
                                      src={
                                        value.Data && value.Data.Avatar
                                          ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                          : avatar
                                      }
                                    />
                                    {`${value.FirstName} ${value.LastName}`}
                                    {techSched && techSched.length > 0 && (
                                      <Tooltip
                                        /*placement="right"*/ TransitionComponent={
                                          Fade
                                        }
                                        enterDelay={1000}
                                        leaveDelay={300}
                                        TransitionProps={{ timeout: 300 }}
                                        classes={{
                                          root: 'relative',
                                          popper: classes.popper,
                                          tooltipPlacementTop: 'with-after',
                                          tooltipPlacementBottom: 'with-before',
                                          tooltip: classes.tooltip,
                                        }}
                                        interactive={true}
                                        title={
                                          <div
                                            className='w-256 sm:w-512'
                                            onMouseDown={e =>
                                              e.stopPropagation()
                                            }
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <Card
                                              className='mb-16'
                                              style={{
                                                border: '1px solid lightgrey',
                                              }}
                                            >
                                              <CardHeader
                                                className={classNames(
                                                  'p-12 relative',
                                                  'highlight',
                                                )}
                                                classes={{
                                                  content: classes.content,
                                                  action: classes.action,
                                                  avatar: 'mr-8',
                                                  title: classes.title,
                                                  subheader: classes.subheader,
                                                }}
                                                avatar={
                                                  <Avatar
                                                    classes={{
                                                      root: classes.avatarRoot,
                                                    }}
                                                    className={classes.avatar}
                                                    alt={value.Technician}
                                                    src={
                                                      value.Data &&
                                                      value.Data.Avatar
                                                        ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                                        : avatar
                                                    }
                                                  />
                                                }
                                                action={
                                                  <div className='absolute w-96 pin-r mr-16 pin-t text-right mt-12'>
                                                    <Typography
                                                      variant='caption'
                                                      className='font-bold'
                                                    >{`${techSched.length} Trips`}</Typography>
                                                    <Typography
                                                      variant='caption'
                                                      className='text-10 text-grey-darker'
                                                    >{`${_.sumBy(
                                                      techSched,
                                                      t => {
                                                        return t.Duration
                                                      },
                                                    ).toFixed(
                                                      2,
                                                    )} Hours`}</Typography>
                                                  </div>
                                                }
                                                title={
                                                  <Typography className='flex text-12 font-bold'>
                                                    {`${value.FirstName} ${value.LastName}`}
                                                  </Typography>
                                                }
                                                subheader={moment(
                                                  this.state.DueBy,
                                                ).format('M/D/YYYY')}
                                              ></CardHeader>
                                              {techSched.map(trip => (
                                                <div
                                                  className='w-full p-8 pt-0 pb-4'
                                                  style={{
                                                    borderTop:
                                                      '1px solid lightgrey',
                                                    backgroundColor: '#f8f8f8',
                                                  }}
                                                >
                                                  <TechnicianTrip
                                                    capacity={true}
                                                    readOnly={true}
                                                    key={trip.ID}
                                                    workOrder={{
                                                      ...trip.Data.WorkOrder,
                                                      value: 2,
                                                    }}
                                                    technician={{ ...value }}
                                                    trip={trip}
                                                  />
                                                </div>
                                              ))}
                                            </Card>
                                          </div>
                                        }
                                        placement='top'
                                      >
                                        {/* <Avatar className={classNames(classes.scheduleBadge, "pin-r pin-t mt-12 absolute show-on-focus", duration < 4 ? classes.openBadge : duration < 8 ? classes.busyBadge : classes.capacityBadge)}>{Math.round(duration)}</Avatar> */}
                                        <Badge
                                          classes={{
                                            root: 'pin-r pin-t mt-8 mr-16 absolute show-on-focus',
                                            badge: classNames(
                                              classes.techBadge,
                                              duration < 4
                                                ? classes.openBadge
                                                : duration < 8
                                                  ? classes.busyBadge
                                                  : classes.capacityBadge,
                                            ),
                                          }}
                                          color='primary'
                                          badgeContent={Math.round(duration)}
                                        >
                                          <Icon
                                            color='action'
                                            className='text-24 align-middle'
                                          >
                                            today
                                          </Icon>
                                        </Badge>
                                      </Tooltip>
                                    )}
                                  </div>
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            activeOnly={true}
                            value={
                              (this.state.workOrder || {}).LeadTechnician ||
                              this.state.LeadTechnician ||
                              ''
                            }
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                workOrder: {
                                  ...this.state.workOrder,
                                  LeadTechnician: option.Technician,
                                },
                              })
                            }
                            required
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          <Autocomplete
                            className={'w-full mb-12 block'}
                            title='Arrival Window'
                            options={
                              arrivalWindows
                                ? _.filter(arrivalWindows, {
                                    BusinessUnit: this.state.BusinessUnit,
                                  })
                                : []
                            }
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.ArrivalWindow}>
                                  {`${value.Description}`}
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            value={
                              (this.state.workOrder || {}).ArrivalWindow ||
                              this.state.ArrivalWindow ||
                              ''
                            }
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                workOrder: {
                                  ...this.state.workOrder,
                                  ArrivalWindow: option.ArrivalWindow,
                                },
                              })
                            }
                            required
                            activeOnly={true}
                            disabled={
                              this.state.AssignedToWorkOrder ||
                              !woAccessLevel ||
                              woAccessLevel.AccessLevel === 'R'
                            }
                          />
                          {!this.state.AssignedToWorkOrder &&
                            this.state.workOrder.Service &&
                            this.state.workOrder.DueBy &&
                            this.state.workOrder.ArrivalWindow && (
                              <React.Fragment>
                                <Autocomplete
                                  className={'w-full mb-12'}
                                  title='Assign To'
                                  options={technicians}
                                  menuItemComponent={value => {
                                    const techSched = (
                                      this.state.workOrder || {}
                                    ).DueBy
                                      ? _.filter(schedule, {
                                          Technician: value.Technician,
                                        })
                                      : null
                                    const duration = _.sumBy(
                                      techSched,
                                      'Duration',
                                    )
                                    const { LeadTechnician, Data } =
                                      this.state.workOrder
                                    const { Trips } = Data
                                    const isDisabled =
                                      value.Technician === LeadTechnician ||
                                      _.findIndex(Trips, {
                                        Technician: value.Technician,
                                      }) > -1
                                    return (
                                      <MenuItem
                                        value={value.Technician}
                                        disabled={isDisabled}
                                      >
                                        <div
                                          className={classNames(
                                            'w-full flex mr-64',
                                          )}
                                        >
                                          <Avatar
                                            style={{ marginRight: 8 }}
                                            classes={{
                                              root: classes.avatarRoot,
                                            }}
                                            className={classes.techAvatar}
                                            alt={value.Technician}
                                            src={
                                              value.Data && value.Data.Avatar
                                                ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                                : avatar
                                            }
                                          />
                                          {`${value.FirstName} ${value.LastName}`}
                                          {techSched &&
                                            techSched.length > 0 && (
                                              <Tooltip
                                                /*placement="right"*/ TransitionComponent={
                                                  Fade
                                                }
                                                enterDelay={1000}
                                                leaveDelay={300}
                                                TransitionProps={{
                                                  timeout: 300,
                                                }}
                                                classes={{
                                                  root: 'relative',
                                                  popper: classes.popper,
                                                  tooltipPlacementTop:
                                                    'with-after',
                                                  tooltipPlacementBottom:
                                                    'with-before',
                                                  tooltip: classes.tooltip,
                                                }}
                                                interactive={true}
                                                title={
                                                  <div
                                                    className='w-256 sm:w-512'
                                                    onMouseDown={e =>
                                                      e.stopPropagation()
                                                    }
                                                    onClick={e =>
                                                      e.stopPropagation()
                                                    }
                                                  >
                                                    <Card
                                                      className='mb-16'
                                                      style={{
                                                        border:
                                                          '1px solid lightgrey',
                                                      }}
                                                    >
                                                      <CardHeader
                                                        className={classNames(
                                                          'p-12 relative',
                                                          'highlight',
                                                        )}
                                                        classes={{
                                                          content:
                                                            classes.content,
                                                          action:
                                                            classes.action,
                                                          avatar: 'mr-8',
                                                          title: classes.title,
                                                          subheader:
                                                            classes.subheader,
                                                        }}
                                                        avatar={
                                                          <Avatar
                                                            classes={{
                                                              root: classes.avatarRoot,
                                                            }}
                                                            className={
                                                              classes.avatar
                                                            }
                                                            alt={
                                                              value.Technician
                                                            }
                                                            src={
                                                              value.Data &&
                                                              value.Data.Avatar
                                                                ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                                                : avatar
                                                            }
                                                          />
                                                        }
                                                        action={
                                                          <div className='absolute w-96 pin-r mr-16 pin-t text-right mt-12'>
                                                            <Typography
                                                              variant='caption'
                                                              className='font-bold'
                                                            >{`${techSched.length} Trips`}</Typography>
                                                            <Typography
                                                              variant='caption'
                                                              className='text-10 text-grey-darker'
                                                            >{`${_.sumBy(
                                                              techSched,
                                                              t => {
                                                                return t.Duration
                                                              },
                                                            ).toFixed(
                                                              2,
                                                            )} Hours`}</Typography>
                                                          </div>
                                                        }
                                                        title={
                                                          <Typography className='flex text-12 font-bold'>
                                                            {`${value.FirstName} ${value.LastName}`}
                                                          </Typography>
                                                        }
                                                        subheader={moment(
                                                          this.state.DueBy,
                                                        ).format('M/D/YYYY')}
                                                      ></CardHeader>
                                                      {techSched.map(trip => (
                                                        <div
                                                          className='w-full p-8 pt-0 pb-4'
                                                          style={{
                                                            borderTop:
                                                              '1px solid lightgrey',
                                                            backgroundColor:
                                                              '#f8f8f8',
                                                          }}
                                                        >
                                                          <TechnicianTrip
                                                            capacity={true}
                                                            readOnly={true}
                                                            key={trip.ID}
                                                            workOrder={{
                                                              ...trip.Data
                                                                .WorkOrder,
                                                              value: 2,
                                                            }}
                                                            technician={{
                                                              ...value,
                                                            }}
                                                            trip={trip}
                                                          />
                                                        </div>
                                                      ))}
                                                    </Card>
                                                  </div>
                                                }
                                                placement='top'
                                              >
                                                {/* <Avatar className={classNames(classes.scheduleBadge, "pin-r pin-t mt-12 absolute show-on-focus", duration < 4 ? classes.openBadge : duration < 8 ? classes.busyBadge : classes.capacityBadge)}>{Math.round(duration)}</Avatar> */}
                                                <Badge
                                                  classes={{
                                                    root: 'pin-r pin-t mt-8 mr-16 absolute show-on-focus',
                                                    badge: classNames(
                                                      classes.techBadge,
                                                      duration < 4
                                                        ? classes.openBadge
                                                        : duration < 8
                                                          ? classes.busyBadge
                                                          : classes.capacityBadge,
                                                    ),
                                                  }}
                                                  color='primary'
                                                  badgeContent={Math.round(
                                                    duration,
                                                  )}
                                                >
                                                  <Icon
                                                    color='action'
                                                    className='text-24 align-middle'
                                                  >
                                                    today
                                                  </Icon>
                                                </Badge>
                                              </Tooltip>
                                            )}
                                        </div>
                                      </MenuItem>
                                    )
                                  }}
                                  portal={true}
                                  activeOnly={true}
                                  value={''}
                                  onSelect={option => {
                                    const {
                                      Co,
                                      DueBy,
                                      BusinessUnit,
                                      ArrivalWindow,
                                      Service,
                                      LeadTechnician,
                                      Data,
                                    } = this.state.workOrder
                                    const { Trips } = Data
                                    if (
                                      option.Technician !== LeadTechnician &&
                                      _.findIndex(Trips, {
                                        Technician: option.Technician,
                                      }) < 0
                                    ) {
                                      const svc =
                                        _.find(services, { Co, Service }) || {}
                                      const start =
                                        _.find(arrivalWindows, {
                                          BusinessUnit,
                                          ArrivalWindow,
                                        }) || {}
                                      const ScheduledDate = new Date(DueBy)
                                      const ScheduledTime = start
                                        ? `${ScheduledDate.toLocaleDateString(
                                            'en-US',
                                          )} ${start.StartTime}`
                                        : null
                                      const trip = {
                                        Co,
                                        ScheduledDate,
                                        ScheduledTime,
                                        Technician: option.Technician,
                                        Status: 0,
                                        Duration: svc.Duration || 1,
                                      }
                                      Trips.push(trip)
                                      // window["warn"]('Assigned Trips: ', Trips, trip);
                                      this.setState({
                                        ...this.state,
                                        workOrder: {
                                          ...this.state.workOrder,
                                          Data: { ...Data, Trips },
                                        },
                                      })
                                    }
                                  }}
                                  required
                                  disabled={this.state.AssignedToWorkOrder}
                                />
                                {(this.state.workOrder.LeadTechnician ||
                                  (this.state.workOrder.Data &&
                                    this.state.workOrder.Data.Trips &&
                                    this.state.workOrder.Data.Trips.length >
                                      0)) && (
                                  <div className='w-full p-8 pb-12'>
                                    {this.state.workOrder.LeadTechnician &&
                                      [this.state.workOrder.LeadTechnician].map(
                                        tech => {
                                          const value = _.find(technicians, {
                                            Technician: tech,
                                          })
                                          return (
                                            <div
                                              key={value.Technician}
                                              className={classNames(
                                                'w-full font-bold flex relative',
                                                this.state.workOrder.Data &&
                                                  this.state.workOrder.Data
                                                    .Trips &&
                                                  this.state.workOrder.Data
                                                    .Trips.length > 0 &&
                                                  'mb-16',
                                              )}
                                            >
                                              <Avatar
                                                style={{ marginRight: 8 }}
                                                classes={{
                                                  root: classes.avatarRoot,
                                                }}
                                                className={classes.techAvatar}
                                                alt={value.Technician}
                                                src={
                                                  value.Data &&
                                                  value.Data.Avatar
                                                    ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                                    : avatar
                                                }
                                              />
                                              {`${value.FirstName} ${value.LastName}`}
                                              <Icon
                                                color='action'
                                                onClick={() => {
                                                  this.setState({
                                                    ...this.state,
                                                    workOrder: {
                                                      ...this.state.workOrder,
                                                      LeadTechnician: null,
                                                    },
                                                  })
                                                }}
                                                className='pin-r absolute text-20 cursor-pointer'
                                              >
                                                close
                                              </Icon>
                                            </div>
                                          )
                                        },
                                      )}
                                    {this.state.workOrder.Data &&
                                      this.state.workOrder.Data.Trips &&
                                      this.state.workOrder.Data.Trips.map(
                                        (trip, index) => {
                                          const value = _.find(technicians, {
                                            Technician: trip.Technician,
                                          })
                                          return (
                                            <div
                                              key={value.Technician}
                                              className={classNames(
                                                'w-full font-bold flex relative',
                                                this.state.workOrder.Data.Trips
                                                  .length >
                                                  index + 1 && 'mb-16',
                                              )}
                                            >
                                              <Avatar
                                                style={{ marginRight: 8 }}
                                                classes={{
                                                  root: classes.avatarRoot,
                                                }}
                                                className={classes.techAvatar}
                                                alt={value.Technician}
                                                src={
                                                  value.Data &&
                                                  value.Data.Avatar
                                                    ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                                    : avatar
                                                }
                                              />
                                              {`${value.FirstName} ${value.LastName}`}
                                              <Icon
                                                color='action'
                                                onClick={() => {
                                                  const { Data } =
                                                    this.state.workOrder
                                                  const { Trips } = Data
                                                  Trips.splice(index, 1)
                                                  this.setState({
                                                    ...this.state,
                                                    workOrder: {
                                                      ...this.state.workOrder,
                                                      Data: { ...Data, Trips },
                                                    },
                                                  })
                                                }}
                                                className='pin-r absolute text-20 cursor-pointer'
                                              >
                                                close
                                              </Icon>
                                            </div>
                                          )
                                        },
                                      )}
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                        </div>
                        {!this.state.AssignedToWorkOrder &&
                          woAccessLevel &&
                          woAccessLevel.AccessLevel !== 'R' && (
                            <div className='w-full p-12 pt-0'>
                              <Button
                                variant='contained'
                                className={classNames(classes.black, 'w-full')}
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation()
                                  const {
                                    Co,
                                    DueBy,
                                    BusinessUnit,
                                    Division,
                                    ArrivalWindow,
                                    Service,
                                    LeadTechnician,
                                    Data,
                                  } = this.state.workOrder
                                  const { Trips } = Data
                                  if (
                                    LeadTechnician &&
                                    _.findIndex(Trips, {
                                      Technician: LeadTechnician,
                                    }) < 0
                                  ) {
                                    const svc =
                                      _.find(services, { Co, Service }) || {}
                                    const start =
                                      _.find(arrivalWindows, {
                                        BusinessUnit,
                                        ArrivalWindow,
                                      }) || {}
                                    const ScheduledDate = new Date(DueBy)
                                    const ScheduledTime = start
                                      ? `${ScheduledDate.toLocaleDateString(
                                          'en-US',
                                        )} ${start.StartTime}`
                                      : null
                                    const trip = {
                                      Co,
                                      ScheduledDate,
                                      ScheduledTime,
                                      Technician: LeadTechnician,
                                      Status: 0,
                                      Duration: svc.Duration || 1,
                                    }
                                    Trips.splice(0, 0, trip)
                                    // window["warn"]('Assigned Trips: ', Trips, trip);
                                  }
                                  this.props.scheduleEstimate({
                                    ...this.state.workOrder,
                                    Data: { ...Data, Trips },
                                  })
                                }}
                                disabled={!this.canWorkOrderBeSubmitted()}
                              >
                                Schedule
                              </Button>
                            </div>
                          )}
                      </div>
                    </Drawer>
                  )}
                  {tab === 0 && simpleStep === 0 && (
                    <FuseAnimate
                      animation={
                        prevStep < simpleStep
                          ? 'transition.slideRightIn'
                          : 'transition.slideLeftIn'
                      }
                      delay={300}
                    >
                      <div className='w-full p-12 pb-12'>
                        <div className='flex flex-wrap xs:pb-48'>
                          {allCatalogs.map(cat => {
                            const selectedCatalog =
                              this.state.BusinessUnit === cat.BusinessUnit &&
                              this.state.Division == cat.Division
                            return (
                              <div
                                className={
                                  'w-1/2 sm:w-1/3 md:w-1/4 p-4 pt-8 sm:p-12'
                                }
                                style={{
                                  transform: selectedCatalog
                                    ? 'scale(1.05)'
                                    : 'scale(.95)',
                                  opacity:
                                    !selectedCatalog &&
                                    cat.BusinessUnit !== this.state.BusinessUnit
                                      ? 0.25
                                      : 1,
                                }}
                              >
                                <Card
                                  onClick={() => {
                                    if (
                                      selectedCatalog ||
                                      cat.BusinessUnit ===
                                        this.state.BusinessUnit
                                    ) {
                                      if (!this.state.downloading) {
                                        this.setState(
                                          {
                                            ...this.state,
                                            BusinessUnit: cat.BusinessUnit,
                                            Division: cat.Division,
                                            downloading: true,
                                          },
                                          () => {
                                            Promise.all([
                                              downloadCatalog({
                                                Co,
                                                BusinessUnit:
                                                  this.state.BusinessUnit,
                                                Division: this.state.Division,
                                              }),
                                            ])
                                              .then(() =>
                                                this.setState(
                                                  {
                                                    ...this.state,
                                                    downloading: false,
                                                    downloadError: false,
                                                    simpleStep: simpleStep + 1,
                                                  },
                                                  this.setCatalog,
                                                ),
                                              )
                                              .catch(() =>
                                                this.setState(
                                                  {
                                                    downloading: false,
                                                    downloadError: true,
                                                  },
                                                  () => {
                                                    this.props.showMessage({
                                                      message: `Error downloading Price Book`,
                                                      autoHideDuration: 30000,
                                                      anchorOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                      },
                                                      variant: 'error',
                                                    })
                                                  },
                                                ),
                                              )
                                          },
                                        )
                                      }
                                    }
                                  }}
                                  className='w-full relative cursor-pointer rounded-lg'
                                  style={{
                                    boxShadow: selectedCatalog
                                      ? '0px 0px 12px 4px rgba(0,0,0,.35)'
                                      : undefined,
                                  }}
                                >
                                  <CardMedia
                                    component='image'
                                    className={'h-160 sm:h-192 w-full relative'}
                                    image={
                                      'assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg'
                                    }
                                    style={{
                                      background: cat.EstimateHeaderImg
                                        ? 'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,0)), url(assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg)'
                                        : '#fff',
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                    }}
                                    alt={cat.Name}
                                  >
                                    <img
                                      src={
                                        cat.EstimateHeaderImg ||
                                        co.ImgUrl ||
                                        'assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg'
                                      }
                                      className={
                                        cat.EstimateHeaderImg
                                          ? 'pin-t pin-b pin-l pin-r m-auto absolute w-5/6'
                                          : 'pin-t pin-b pin-l pin-r m-auto absolute'
                                      }
                                    />
                                  </CardMedia>
                                  {/* {cat.BusinessUnit === this.state.BusinessUnit && cat.Division === "*" &&
                                                                        <div className="pin-t pin-r absolute px-12 text-white rounded-bl-lg py-4 font-bold text-12" style={{ backgroundColor: '#3f3f3f' }}>
                                                                            All Divisions
                                                                        </div>
                                                                    } */}
                                  <CardContent
                                    className={''}
                                    classes={{
                                      root: 'px-6 py-12 pb-0 has-hover',
                                    }}
                                  >
                                    <div
                                      className={classNames(
                                        classes.progress,
                                        'relative',
                                      )}
                                    >
                                      <Avatar
                                        className={classNames(
                                          classes.avatar,
                                          'absolute pin-t cursor-pointer',
                                        )}
                                      >
                                        {selectedCatalog ? (
                                          downloading ? (
                                            <Icon
                                              style={{ color: '#5f5f5f' }}
                                              className='text-36'
                                            >
                                              swap_vertical_circle
                                            </Icon>
                                          ) : downloadError ? (
                                            <Icon
                                              style={{ color: '#5f5f5f' }}
                                              className='text-36'
                                            >
                                              cancel
                                            </Icon>
                                          ) : (
                                            <Icon
                                              style={{ color: '#5f5f5f' }}
                                              className='text-36'
                                            >
                                              check_circle_outline
                                            </Icon>
                                          )
                                        ) : (
                                          <SPRoundIcon
                                            className='text-36'
                                            style={{ color: '#5f5f5f' }}
                                          />
                                        )}
                                      </Avatar>
                                      {selectedCatalog && (
                                        <CircularProgress
                                          className={classNames(
                                            'pin-t pin-l pin-r absolute m-auto',
                                          )}
                                          variant={
                                            !downloading
                                              ? 'determinate'
                                              : undefined
                                          }
                                          value={!downloading ? 100 : undefined}
                                          style={{
                                            marginTop: 9,
                                            width: 30,
                                            height: 30,
                                          }}
                                          color='primary'
                                        />
                                      )}
                                    </div>
                                    <Typography
                                      gutterBottom
                                      variant='h6'
                                      style={{ fontSize: 16 }}
                                      component='div'
                                      className='flex'
                                    >
                                      <div
                                        className={
                                          'w-full text-center truncate mt-6'
                                        }
                                      >
                                        {cat.Name}
                                      </div>
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </FuseAnimate>
                  )}
                  {tab === 0 && simpleStep === 1 && (
                    <FuseAnimate
                      animation={
                        prevStep < simpleStep
                          ? 'transition.slideRightIn'
                          : 'transition.slideLeftIn'
                      }
                      delay={300}
                    >
                      <div className='w-full p-12 pb-12'>
                        <div className='mb-12 w-full flex'>
                          <Button
                            className={classNames(
                              'w-full rounded-lg rounded-r-none',
                              !SelectedPricing || SelectedPricing === 'S'
                                ? ''
                                : classes.blackButton,
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() =>
                              this.setState(
                                { SelectedPricing: 'S' },
                                this.calculateEstimatePrice,
                              )
                            }
                          >
                            {(!SelectedPricing || SelectedPricing === 'S') && (
                              <Icon className='mr-6'>check_circle_outline</Icon>
                            )}
                            Standard
                          </Button>
                          <Button
                            className={classNames(
                              'w-full rounded-none border-solid border-r-1 border-l-1 border-grey-dark',
                              SelectedPricing !== 'M' && classes.blackButton,
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() =>
                              this.setState(
                                { SelectedPricing: 'M' },
                                this.calculateEstimatePrice,
                              )
                            }
                          >
                            {SelectedPricing === 'M' && (
                              <Icon className='mr-6'>check_circle_outline</Icon>
                            )}
                            Member
                          </Button>
                          <Button
                            className={classNames(
                              'w-full rounded-lg rounded-l-none',
                              SelectedPricing !== 'P' && classes.blackButton,
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() =>
                              this.setState(
                                { SelectedPricing: 'P' },
                                this.calculateEstimatePrice,
                              )
                            }
                          >
                            {SelectedPricing === 'P' && (
                              <Icon className='mr-6'>check_circle_outline</Icon>
                            )}
                            Premium
                          </Button>
                        </div>
                        <div className='flex flex-wrap xs:pb-48 justify-center'>
                          <div
                            className={'w-1/2 p-4 pt-8 sm:p-12'}
                            style={{
                              transform:
                                this.state.estimateType === 'S'
                                  ? 'scale(1.0)'
                                  : 'scale(.95)',
                            }}
                          >
                            <Card
                              onClick={() =>
                                this.setState({
                                  estimateType: 'S',
                                  OptionBoard: null,
                                  SelectedOption: null,
                                  Data: { ...Data, OptionBoard: null },
                                  simpleStep: simpleStep + 1,
                                })
                              }
                              className='w-full relative cursor-pointer rounded-lg'
                              style={{
                                boxShadow:
                                  this.state.estimateType === 'S'
                                    ? '0px 0px 12px 4px rgba(0,0,0,.35)'
                                    : undefined,
                              }}
                            >
                              <CardMedia
                                component='image'
                                className={
                                  'h-160 sm:h-320 lg:h-448 w-full relative'
                                }
                                image={
                                  this.state.estimateType === 'S'
                                    ? 'assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg'
                                    : 'assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg'
                                }
                                alt='SP Pro'
                              >
                                <img
                                  src={'assets/images/icons/estimate.png'}
                                  className={
                                    'pin-t pin-b pin-l pin-r m-auto absolute'
                                  }
                                />
                              </CardMedia>
                              <CardContent
                                className={''}
                                classes={{ root: 'px-6 py-12 pb-0 has-hover' }}
                              >
                                <div
                                  className={classNames(
                                    classes.progress,
                                    'relative',
                                  )}
                                >
                                  <Avatar
                                    className={classNames(
                                      classes.avatar,
                                      'absolute pin-t cursor-pointer',
                                    )}
                                  >
                                    {this.state.estimateType === 'S' ? (
                                      <Icon
                                        style={{ color: '#5f5f5f' }}
                                        className='text-36'
                                      >
                                        check_circle_outline
                                      </Icon>
                                    ) : (
                                      <SPRoundIcon
                                        className='text-36'
                                        style={{ color: '#5f5f5f' }}
                                      />
                                    )}
                                  </Avatar>
                                  {this.state.estimateType === 'S' && (
                                    <CircularProgress
                                      className={classNames(
                                        'pin-t pin-l pin-r absolute m-auto',
                                      )}
                                      variant={
                                        !downloading ? 'determinate' : undefined
                                      }
                                      value={!downloading ? 100 : undefined}
                                      style={{
                                        marginTop: 9,
                                        width: 30,
                                        height: 30,
                                      }}
                                      color='primary'
                                    />
                                  )}
                                </div>
                                <Typography
                                  gutterBottom
                                  variant='h6'
                                  style={{ fontSize: 16 }}
                                  component='div'
                                  className='flex'
                                >
                                  <div className={'w-full text-center mt-6'}>
                                    Single Estimate
                                  </div>
                                </Typography>
                              </CardContent>
                            </Card>
                          </div>
                          <div
                            className={'w-1/2  p-4 pt-8 sm:p-12'}
                            style={{
                              transform:
                                this.state.estimateType === 'O'
                                  ? 'scale(1.0)'
                                  : 'scale(.95)',
                            }}
                          >
                            <Card
                              onClick={this.setOptionBoard}
                              className='w-full relative cursor-pointer rounded-lg'
                              style={{
                                boxShadow:
                                  this.state.estimateType === 'O'
                                    ? '0px 0px 12px 4px rgba(0,0,0,.35)'
                                    : undefined,
                              }}
                            >
                              <CardMedia
                                component='image'
                                className={
                                  'h-160 sm:h-320 lg:h-448 w-full relative'
                                }
                                image={
                                  this.state.estimateType === 'O'
                                    ? 'assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg'
                                    : 'assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg'
                                }
                                alt='SP Pro'
                              >
                                <img
                                  src={'assets/images/icons/boards.png'}
                                  className={
                                    'pin-t pin-b pin-l pin-r m-auto absolute'
                                  }
                                />
                              </CardMedia>
                              <CardContent
                                className={''}
                                classes={{ root: 'px-6 py-12 pb-0 has-hover' }}
                              >
                                <div
                                  className={classNames(
                                    classes.progress,
                                    'relative',
                                  )}
                                >
                                  <Avatar
                                    className={classNames(
                                      classes.avatar,
                                      'absolute pin-t cursor-pointer',
                                    )}
                                  >
                                    {this.state.estimateType === 'O' ? (
                                      <Icon
                                        style={{ color: '#5f5f5f' }}
                                        className='text-36'
                                      >
                                        check_circle_outline
                                      </Icon>
                                    ) : (
                                      <SPRoundIcon
                                        className='text-36'
                                        style={{ color: '#5f5f5f' }}
                                      />
                                    )}
                                  </Avatar>
                                  {this.state.estimateType === 'O' && (
                                    <CircularProgress
                                      className={classNames(
                                        'pin-t pin-l pin-r absolute m-auto',
                                      )}
                                      variant={
                                        !downloading ? 'determinate' : undefined
                                      }
                                      value={!downloading ? 100 : undefined}
                                      style={{
                                        marginTop: 9,
                                        width: 30,
                                        height: 30,
                                      }}
                                      color='primary'
                                    />
                                  )}
                                </div>
                                <Typography
                                  gutterBottom
                                  variant='h6'
                                  style={{ fontSize: 16 }}
                                  component='div'
                                  className='flex'
                                >
                                  <div className={'w-full text-center mt-6'}>
                                    Option Board
                                  </div>
                                </Typography>
                              </CardContent>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </FuseAnimate>
                  )}
                  {catalog &&
                    tab === 0 &&
                    simpleStep === 2 &&
                    estimateType === 'O' && (
                      <FuseAnimate
                        animation={
                          prevStep < simpleStep
                            ? 'transition.slideRightIn'
                            : 'transition.slideLeftIn'
                        }
                        delay={300}
                      >
                        <div className='w-full'>
                          {catalog.EnableSavedOptionBoardsYN === 'Y' &&
                          newOrExisting === '-' ? (
                            <CatalogOptionBoardList
                              enterFrom={
                                prevStep < simpleStep ? 'right' : 'left'
                              }
                              isMember={isMember}
                              dialog={true}
                              catalog={catalog}
                              estimate={this.state}
                              onSelectedOptionBoard={this.selectOptionBoard}
                            />
                          ) : (
                            <div className='w-full'>
                              <Typography
                                variant='h6'
                                style={{ alignItems: 'center' }}
                                className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                              >
                                <Icon
                                  color='primary'
                                  className='text-32 mr-8 cursor-pointer'
                                >
                                  apps
                                </Icon>
                                Option Board Types
                              </Typography>
                              <Divider />
                              <div className='flex flex-wrap xs:pb-48 pt-6'>
                                {_.filter(
                                  optionTypes,
                                  o =>
                                    o.ActiveYN != 'N' ||
                                    o.OptionsType === optionBoardType,
                                ).map(opt => {
                                  return (
                                    <div
                                      className={
                                        'w-1/2 sm:w-1/3 md:w-1/4 p-4 pt-8'
                                      }
                                      style={{
                                        transform:
                                          optionBoardType === opt.OptionsType
                                            ? 'scale(1)'
                                            : 'scale(.95)',
                                      }}
                                    >
                                      <Card
                                        onClick={() => this.setOptionsType(opt)}
                                        className='w-full relative cursor-pointer rounded-lg relative'
                                        style={{
                                          boxShadow:
                                            optionBoardType === opt.OptionsType
                                              ? '0px 0px 12px 4px rgba(0,0,0,.35)'
                                              : undefined,
                                        }}
                                      >
                                        <CardMedia
                                          component='image'
                                          className={
                                            'h-160 sm:h-192 w-full relative'
                                          }
                                          image={
                                            'assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg'
                                          }
                                          style={{
                                            background:
                                              catalog &&
                                              catalog.EstimateHeaderImg
                                                ? 'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,0)), url(assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg)'
                                                : '#fff',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                          }}
                                          alt={opt.Name}
                                        >
                                          <img
                                            src={
                                              catalog &&
                                              catalog.EstimateHeaderImg
                                                ? catalog.EstimateHeaderImg ||
                                                  co.ImgUrl
                                                : co.ImgUrl ||
                                                  'assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg'
                                            }
                                            className={
                                              catalog &&
                                              catalog.EstimateHeaderImg
                                                ? 'pin-t pin-b pin-l pin-r m-auto absolute w-5/6'
                                                : 'pin-t pin-b pin-l pin-r m-auto absolute'
                                            }
                                          />
                                        </CardMedia>
                                        <div
                                          className='pin-t pin-r absolute px-12 text-white rounded-bl-lg py-4 font-bold text-12'
                                          style={{ backgroundColor: '#3f3f3f' }}
                                        >
                                          {`${opt.Data.Options.length} Options`}
                                        </div>
                                        <CardContent
                                          className={''}
                                          classes={{
                                            root: 'px-6 py-12 pb-0 has-hover',
                                          }}
                                        >
                                          <div
                                            className={classNames(
                                              classes.progress,
                                              'relative',
                                            )}
                                          >
                                            <Avatar
                                              className={classNames(
                                                classes.avatar,
                                                'absolute pin-t cursor-pointer',
                                              )}
                                            >
                                              {optionBoardType ===
                                              opt.OptionsType ? (
                                                <Icon
                                                  style={{ color: '#5f5f5f' }}
                                                  className='text-36'
                                                >
                                                  check_circle_outline
                                                </Icon>
                                              ) : (
                                                <SPRoundIcon
                                                  className='text-36'
                                                  style={{ color: '#5f5f5f' }}
                                                />
                                              )}
                                            </Avatar>
                                            {optionBoardType ===
                                              opt.OptionsType && (
                                              <CircularProgress
                                                className={classNames(
                                                  'pin-t pin-l pin-r absolute m-auto',
                                                )}
                                                variant={
                                                  !downloading
                                                    ? 'determinate'
                                                    : undefined
                                                }
                                                value={
                                                  !downloading ? 100 : undefined
                                                }
                                                style={{
                                                  marginTop: 9,
                                                  width: 30,
                                                  height: 30,
                                                }}
                                                color='primary'
                                              />
                                            )}
                                          </div>
                                          <Typography
                                            gutterBottom
                                            variant='h6'
                                            style={{ fontSize: 16 }}
                                            component='div'
                                            className='flex'
                                          >
                                            <div
                                              className={
                                                'w-full text-center truncate mt-6'
                                              }
                                            >
                                              {opt.Name}
                                            </div>
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </FuseAnimate>
                    )}
                  {tab === 0 &&
                    simpleStep === 2 &&
                    this.state.estimateType === 'S' && (
                      <React.Fragment>
                        {catalog ? (
                          <div className='w-full h-full'>
                            <div
                              className='w-full h-full'
                              style={{
                                display:
                                  selectedComponent || selectedTMItem
                                    ? 'none'
                                    : undefined,
                              }}
                            >
                              <CatalogAssemblyList
                                enterFrom={
                                  prevStep < simpleStep ? 'right' : 'left'
                                }
                                isMember={
                                  isMember ||
                                  _.findIndex(
                                    this.state.Data.Components,
                                    o => o.Type === 4 && o.DeleteYN !== 'Y',
                                  ) > -1
                                }
                                onCategoryChange={category =>
                                  this.setState({ ...this.state, category })
                                }
                                category={this.state.category}
                                dialog={true}
                                catalog={catalog}
                                estimate={this.state}
                                info={this.state}
                                SelectedPricing={SelectedPricing}
                                onSelected={selectedComponent =>
                                  this.setState({
                                    selectedComponent: {
                                      ...selectedComponent,
                                      isNew: true,
                                    },
                                  })
                                }
                                onSelectedItem={() =>
                                  this.setState({
                                    selectedTMItem: {
                                      ..._.cloneDeepWith(newTMItemState),
                                      Co,
                                      BusinessUnit,
                                      Division,
                                    },
                                  })
                                }
                                onAddComponent={Components => {
                                  this.state.Signature
                                    ? this.setState(
                                        {
                                          ...this.state,
                                          confirmUpdate: Components,
                                        },
                                        this.calculateEstimatePrice,
                                      )
                                    : this.setState(
                                        {
                                          ...this.state,
                                          Data: {
                                            ...this.state.Data,
                                            Components,
                                          },
                                        },
                                        this.calculateEstimatePrice,
                                      )
                                }}
                                onUpdated={Components => {
                                  this.state.Signature
                                    ? this.setState({
                                        ...this.state,
                                        confirmUpdate: Components,
                                      })
                                    : this.setState(
                                        {
                                          ...this.state,
                                          Data: {
                                            ...this.state.Data,
                                            Components,
                                          },
                                          selectedComponent: !Boolean(
                                            selectedComponent &&
                                              selectedComponent.Data &&
                                              selectedComponent.Data
                                                .Recommendations &&
                                              selectedComponent.Data
                                                .Recommendations.length > 0,
                                          )
                                            ? null
                                            : selectedComponent,
                                        },
                                        this.calculateEstimatePrice,
                                      )
                                }}
                              />
                            </div>
                            {this.state.selectedComponent && (
                              <CatalogItem
                                onSelected={selectedComponent =>
                                  this.setState({
                                    selectedComponent: {
                                      ...selectedComponent,
                                      isNew: true,
                                    },
                                  })
                                }
                                isMember={
                                  isMember ||
                                  _.findIndex(
                                    this.state.Data.Components,
                                    o => o.Type === 4 && o.DeleteYN !== 'Y',
                                  ) > -1
                                }
                                data={selectedComponent}
                                estimate={this.state}
                                catalog={catalog}
                                onUpdated={(Components, existing) => {
                                  this.state.Signature
                                    ? this.setState(
                                        {
                                          ...this.state,
                                          confirmUpdate: Components,
                                        },
                                        this.calculateEstimatePrice,
                                      )
                                    : this.onUpdate(
                                        currentOption,
                                        Components,
                                        existing,
                                      )
                                }}
                                multipleOfSameComponent={
                                  multipleOfSameComponent
                                }
                              />
                            )}
                            {selectedTMItem && (
                              <EstimateTMItem
                                isMember={
                                  isMember ||
                                  _.findIndex(
                                    this.state.Data.Components,
                                    o => o.Type === 4 && o.DeleteYN !== 'Y',
                                  ) > -1
                                }
                                estimate={this.state}
                                catalog={catalog}
                                data={selectedTMItem}
                                onUpdated={Components =>
                                  this.setState(
                                    {
                                      ...this.state,
                                      Data: { ...this.state.Data, Components },
                                      selectedTMItem: null,
                                    },
                                    this.calculateEstimatePrice,
                                  )
                                }
                                multipleOfSameComponent={
                                  multipleOfSameComponent
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <Button
                            variant='contained'
                            color='primary'
                            className={classNames(
                              'w-256 h-36 m-auto absolute pin-l pin-r pin-t pin-b text-12',
                              downloading && classes.black,
                            )}
                            onClick={() => {
                              if (!this.state.downloading) {
                                this.setState(
                                  { ...this.state, downloading: true },
                                  () => {
                                    Promise.all([
                                      downloadCatalog({
                                        Co,
                                        BusinessUnit: this.state.BusinessUnit,
                                        Division: this.state.Division,
                                      }),
                                    ])
                                      .then(() =>
                                        this.setState(
                                          {
                                            ...this.state,
                                            downloading: false,
                                            downloadError: false,
                                          },
                                          this.setCatalog,
                                        ),
                                      )
                                      .catch(() =>
                                        this.setState(
                                          {
                                            downloading: false,
                                            downloadError: true,
                                          },
                                          () => {
                                            this.props.showMessage({
                                              message: `Error downloading Price Book`,
                                              autoHideDuration: 30000,
                                              anchorOrigin: {
                                                vertical: 'top',
                                                horizontal: 'right',
                                              },
                                              variant: 'error',
                                            })
                                          },
                                        ),
                                      )
                                  },
                                )
                              }
                            }}
                          >
                            <Icon
                              className={classNames(
                                'mr-6',
                                downloading && 'spin',
                              )}
                              color={downloading ? 'secondary' : undefined}
                            >
                              {downloading ? 'refresh' : 'cloud_download'}
                            </Icon>
                            Download{downloading ? 'ing' : ''} Price Book
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  {tab === 0 && currentOption && (
                    <React.Fragment>
                      {catalog ? (
                        <div
                          key={currentOption.Option}
                          className='w-full h-full'
                        >
                          <div
                            className='w-full h-full'
                            style={{
                              display:
                                selectedComponent || selectedTMItem
                                  ? 'none'
                                  : undefined,
                            }}
                          >
                            <CatalogAssemblyList
                              enterFrom={
                                prevStep < simpleStep ? 'right' : 'left'
                              }
                              isMember={
                                isMember ||
                                _.findIndex(
                                  currentOption.Data.Components,
                                  o => o.Type === 4 && o.DeleteYN !== 'Y',
                                ) > -1
                              }
                              onCategoryChange={category =>
                                this.setState({ ...this.state, category })
                              }
                              category={this.state.category}
                              dialog={true}
                              catalog={catalog}
                              estimate={currentOption}
                              info={this.state}
                              SelectedPricing={SelectedPricing}
                              onSelected={selectedComponent =>
                                this.setState({
                                  selectedComponent: {
                                    ...selectedComponent,
                                    isNew: true,
                                  },
                                })
                              }
                              onSelectedItem={() =>
                                this.setState({
                                  selectedTMItem: {
                                    ..._.cloneDeepWith(newTMItemState),
                                    Co,
                                    BusinessUnit,
                                    Division,
                                  },
                                })
                              }
                              onAddComponent={Components =>
                                this.setOptionComponents(
                                  currentOption,
                                  Components,
                                )
                              }
                              onUpdated={Components =>
                                this.recommendationsOnUpdated(
                                  Components,
                                  currentOption,
                                )
                              }
                            />
                          </div>
                          {this.state.selectedComponent && (
                            <CatalogItem
                              SelectedPricing={this.state.SelectedPricing}
                              onSelected={selectedComponent =>
                                this.setState({
                                  selectedComponent: {
                                    ...selectedComponent,
                                    isNew: true,
                                  },
                                })
                              }
                              isMember={
                                isMember ||
                                _.findIndex(
                                  currentOption.Data.Components,
                                  o => o.Type === 4 && o.DeleteYN !== 'Y',
                                ) > -1
                              }
                              data={selectedComponent}
                              estimate={currentOption}
                              catalog={catalog}
                              onUpdated={Components =>
                                this.setOptionComponents(
                                  currentOption,
                                  Components,
                                )
                              }
                              multipleOfSameComponent={multipleOfSameComponent}
                            />
                          )}
                          {selectedTMItem && (
                            <EstimateTMItem
                              isMember={
                                isMember ||
                                _.findIndex(
                                  currentOption.Data.Components,
                                  o => o.Type === 4 && o.DeleteYN !== 'Y',
                                ) > -1
                              }
                              estimate={currentOption}
                              catalog={catalog}
                              data={selectedTMItem}
                              onUpdated={Components =>
                                this.setState({ selectedTMItem: null }, () =>
                                  this.setOptionComponents(
                                    currentOption,
                                    Components,
                                  ),
                                )
                              }
                              multipleOfSameComponent={multipleOfSameComponent}
                            />
                          )}
                        </div>
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          className={classNames(
                            'w-256 h-36 m-auto absolute pin-l pin-r pin-t pin-b text-12',
                            downloading && classes.black,
                          )}
                          onClick={() => {
                            if (!this.state.downloading) {
                              this.setState(
                                { ...this.state, downloading: true },
                                () => {
                                  Promise.all([
                                    downloadCatalog({
                                      Co,
                                      BusinessUnit: this.state.BusinessUnit,
                                      Division: this.state.Division,
                                    }),
                                  ])
                                    .then(() =>
                                      this.setState(
                                        {
                                          ...this.state,
                                          downloading: false,
                                          downloadError: false,
                                        },
                                        this.setCatalog,
                                      ),
                                    )
                                    .catch(() =>
                                      this.setState(
                                        {
                                          downloading: false,
                                          downloadError: true,
                                        },
                                        () => {
                                          this.props.showMessage({
                                            message: `Error downloading Price Book`,
                                            autoHideDuration: 30000,
                                            anchorOrigin: {
                                              vertical: 'top',
                                              horizontal: 'right',
                                            },
                                            variant: 'error',
                                          })
                                        },
                                      ),
                                    )
                                },
                              )
                            }
                          }}
                        >
                          <Icon
                            className={classNames(
                              'mr-6',
                              downloading && 'spin',
                            )}
                            color={downloading ? 'secondary' : undefined}
                          >
                            {downloading ? 'refresh' : 'cloud_download'}
                          </Icon>
                          Download{downloading ? 'ing' : ''} Price Book
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                  {/*(this.state.tab === 0 && simpleStep === 2) && this.state.estimateType === "O" &&
                                        <FuseAnimate animation={prevStep < simpleStep ? "transition.slideRightIn" : "transition.slideLeftIn"} delay={300}>
                                            <div className="w-full p-12 pb-12">
                                                <div className="flex flex-wrap xs:pb-48 justify-center">
                                                    <div className={"w-1/2 p-4 pt-8 sm:p-12"} style={{ transform: this.state.newOrExisting === "+" ? 'scale(1.05)' : 'scale(.95)' }}>
                                                        <Card onClick={() => this.setState({ newOrExisting: "+", OptionBoard: null, SelectedOption: null, simpleStep: simpleStep + 1 })} className="w-full relative cursor-pointer rounded-lg" style={{ boxShadow: this.state.newOrExisting === "+" ? '0px 0px 12px 4px rgba(0,0,0,.35)' : undefined }}>
                                                            <CardMedia
                                                                component="image"
                                                                className={"h-160 sm:h-320 lg:h-448 w-full relative"}
                                                                image={this.state.newOrExisting === "+" ? "assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg" : "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"}
                                                                alt="SP Pro"
                                                            >
                                                                <img src={"assets/images/icons/boards_new.png"} className={"pin-t pin-b pin-l pin-r m-auto absolute"} />
                                                            </CardMedia>
                                                            <CardContent className={""} classes={{ root: "px-6 py-12 pb-0 has-hover" }}>
                                                                <div className={classNames(classes.progress, "relative")}>
                                                                    <Avatar className={classNames(classes.avatar, "absolute pin-t cursor-pointer")}>
                                                                        {this.state.newOrExisting === "+" ?
                                                                            <Icon style={{ color: '#5f5f5f' }} className="text-36">check_circle_outline</Icon>
                                                                            :
                                                                            <Icon style={{ color: '#5f5f5f' }} className="text-36">add_circle_outline</Icon>
                                                                        }
                                                                    </Avatar>
                                                                    {this.state.newOrExisting === "+" &&
                                                                        <CircularProgress className={classNames("pin-t pin-l pin-r absolute m-auto")} variant={!downloading ? "determinate" : undefined} value={!downloading ? 100 : undefined} style={{ marginTop: 9, width: 30, height: 30 }} color="primary" />
                                                                    }
                                                                </div>
                                                                <Typography gutterBottom variant="h6" style={{ fontSize: 16 }} component="div" className="flex">
                                                                    <div className={"w-full text-center mt-6 truncate"}>New Option Board</div>
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </div>
                                                    <div className={"w-1/2 p-4 pt-8 sm:p-12"} style={{ transform: this.state.newOrExisting === "-" ? 'scale(1.05)' : 'scale(.95)' }}>
                                                        <Card onClick={() => this.setState({ newOrExisting: "-", simpleStep: simpleStep + 1 })} className="w-full relative cursor-pointer rounded-lg" style={{ boxShadow: this.state.newOrExisting === "-" ? '0px 0px 12px 4px rgba(0,0,0,.35)' : undefined }}>
                                                            <CardMedia
                                                                component="image"
                                                                className={"h-160 sm:h-320 lg:h-448 w-full relative"}
                                                                image={this.state.newOrExisting === "-" ? "assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg" : "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"}
                                                                alt="SP Pro"
                                                            >
                                                                <img src={"assets/images/icons/boards_existing.png"} className={"pin-t pin-b pin-l pin-r m-auto absolute"} />
                                                            </CardMedia>
                                                            <CardContent className={""} classes={{ root: "px-6 py-12 pb-0 has-hover" }}>
                                                                <div className={classNames(classes.progress, "relative")}>
                                                                    <Avatar className={classNames(classes.avatar, "absolute pin-t cursor-pointer")}>
                                                                        {this.state.newOrExisting === "-" ?
                                                                            <Icon style={{ color: '#5f5f5f' }} className="text-36">check_circle_outline</Icon>
                                                                            :
                                                                            <Icon style={{ color: '#5f5f5f' }} className="text-36">cloud_download</Icon>
                                                                        }
                                                                    </Avatar>
                                                                    {this.state.newOrExisting === "-" &&
                                                                        <CircularProgress className={classNames("pin-t pin-l pin-r absolute m-auto")} variant={!downloading ? "determinate" : undefined} value={!downloading ? 100 : undefined} style={{ marginTop: 9, width: 30, height: 30 }} color="primary" />
                                                                    }
                                                                </div>
                                                                <Typography gutterBottom variant="h6" style={{ fontSize: 16 }} component="div" className="flex">
                                                                    <div className={"w-full text-center mt-6 truncate"}>Existing Option Board</div>
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                        </FuseAnimate>
                                                                */}
                  {tab === 0 &&
                    estimateType &&
                    simpleStep ===
                      steps.length - (estimateType === 'O' ? 3 : 2) && (
                      <FuseAnimate
                        animation={
                          prevStep < simpleStep
                            ? 'transition.slideRightIn'
                            : 'transition.slideLeftIn'
                        }
                        delay={300}
                      >
                        <div className='w-full p-12 pb-48'>
                          <TextField
                            className='w-full bg-white mb-12'
                            label='Selected Pricing'
                            id='type'
                            name='SelectedPricing'
                            variant='outlined'
                            value={SelectedPricing || ''}
                            onChange={e =>
                              this.setState(
                                {
                                  ...this.state,
                                  SelectedPricing: e.target.value,
                                },
                                this.calculateEstimatePrice,
                              )
                            }
                            //onChange={(e) => this.addUpdateSelectedPricing(e, e.target.value)}
                            // margin="dense"
                            InputLabelProps={{
                              style: {
                                textShadow: '0px 0px 4px white',
                              },
                            }}
                            select
                          >
                            <MenuItem value='S'>Standard</MenuItem>
                            <MenuItem value='M'>Member</MenuItem>
                            <MenuItem value='P'>Premium</MenuItem>
                          </TextField>
                          {!Data.OptionBoard ? (
                            <TextField
                              className={classNames('mb-12')}
                              label='Description of Work'
                              id='description'
                              name='Description'
                              value={this.state.Description}
                              onChange={this.handleChange}
                              variant='outlined'
                              multiline
                              rows={5}
                              fullWidth
                            />
                          ) : (
                            <TextField
                              className={classNames('mb-12')}
                              label='Option Board Name'
                              id='optionBoardName'
                              name='Data.OptionBoard.Name'
                              value={Data.OptionBoard.Name}
                              onChange={this.handleChange}
                              variant='outlined'
                              fullWidth
                            />
                          )}
                          {Data.OptionBoard && (
                            <TextField
                              className={classNames('mb-12')}
                              label='Summary'
                              id='summary'
                              name='Greeting'
                              value={this.state.Greeting}
                              onChange={this.handleChange}
                              variant='outlined'
                              multiline
                              rows={5}
                              fullWidth
                            />
                          )}
                          <TextField
                            className={classNames('mb-12')}
                            label='Task/Assembly Detail'
                            id='componentDetail'
                            name='ComponentDetail'
                            value={this.state.ComponentDetail}
                            onChange={this.handleChange}
                            variant='outlined'
                            select
                            fullWidth
                          >
                            <MenuItem value='B'>Basic</MenuItem>
                            <MenuItem value='S'>Showcase</MenuItem>
                            <MenuItem value='F'>Full Details</MenuItem>
                            {!optionBoard && (
                              <MenuItem value='C'>Custom</MenuItem>
                            )}
                          </TextField>
                          {catalog &&
                            catalog.EnableSavedOptionBoardsYN === 'Y' &&
                            Data.OptionBoard && (
                              <div className='w-full mb-12'>
                                <FormControlLabel
                                  className='justify-end w-full mx-0'
                                  // style={{ float: 'right', }}
                                  control={
                                    <Switch
                                      checked={
                                        Data.OptionBoard.StandardYN === 'Y'
                                      }
                                      onChange={() =>
                                        this.setState({
                                          ...this.state,
                                          Data: {
                                            ...this.state.Data,
                                            OptionBoard: {
                                              ...this.state.Data.OptionBoard,
                                              StandardYN:
                                                this.state.Data.OptionBoard
                                                  .StandardYN === 'Y'
                                                  ? 'N'
                                                  : 'Y',
                                            },
                                          },
                                        })
                                      }
                                      color='primary'
                                    />
                                  }
                                  label='Save as Standard'
                                />
                              </div>
                            )}
                          {optionBoard && !SelectedOption && (
                            <div className='w-full'>
                              {optionBoard.Data.Options &&
                                optionBoard.Data.Options.map((value, index) => {
                                  return (
                                    <CatalogOptionBoardOption
                                      matches={matches}
                                      key={index}
                                      optionBoard={{ ...optionBoard }}
                                      isMember={
                                        isMember ||
                                        _.findIndex(
                                          value.Data.Components,
                                          o =>
                                            o.Type === 4 && o.DeleteYN !== 'Y',
                                        ) > -1
                                      }
                                      selected={
                                        optionBoard.RecommendedOption ===
                                        value.Option
                                      }
                                      data={{
                                        ...value,
                                        ComponentDetail:
                                          this.state.ComponentDetail,
                                      }}
                                      onSelectedComponent={selectedComponent =>
                                        this.setState({
                                          simpleStep: value.Option + 2,
                                          selectedComponent,
                                        })
                                      }
                                      onSelected={option =>
                                        this.selectOption(option)
                                      }
                                      onDeSelected={() => this.selectOption()}
                                      IncludeTaxYN={this.state.IncludeTaxYN}
                                      Site={this.state.Data.Site}
                                      SelectedOption={SelectedOption}
                                      setStateFxn={this.setState.bind(this)}
                                      parentState={this.state}
                                      canAddDiscount={true}
                                      SelectedPricing={SelectedPricing}
                                      existingComponents={
                                        this.state.existingComponents
                                      }
                                    />
                                  )
                                })}
                            </div>
                          )}
                          {(!optionBoard || SelectedOption) &&
                            this.state.ComponentDetail === 'C' && (
                              <div className=''>
                                <label
                                  className='text-12 p-4 px-6 ml-8'
                                  style={{ background: '#fff', color: 'grey' }}
                                >
                                  Custom Details:
                                </label>
                                <div
                                  className='rounded-4 p-12 px-16 mb-12'
                                  style={{
                                    border: '1px solid rgba(0, 0, 0, .25)',
                                    marginTop: -11,
                                  }}
                                >
                                  <ul
                                    className={
                                      this.state.Data.Features.length > 0
                                        ? 'mb-8'
                                        : ''
                                    }
                                  >
                                    <EstimateFeatures
                                      editable={true}
                                      estimate={{ ...this.state }}
                                      details={this.state.Data.Features}
                                    />
                                    {this.state.feature && (
                                      <li key={this.state.feature.Feature}>
                                        <div className='flex clear'>
                                          <TextField
                                            className={classNames('mr-8')}
                                            label='New Estimate Detail'
                                            id='featureDescription'
                                            name='feature.Description'
                                            value={
                                              this.state.feature.Description ||
                                              ''
                                            }
                                            onChange={this.handleChange}
                                            autoFocus
                                            margin='dense'
                                            onBlur={() => {
                                              if (
                                                !this.state.feature.Description
                                              ) {
                                                this.setState({
                                                  ...this.state,
                                                  feature: null,
                                                })
                                              }
                                            }}
                                            // onChange={(e) => this.setState({ ...this.state, feature: { ...this.state.feature, Description: e.target.value } })}
                                            variant='outlined'
                                            fullWidth
                                          />
                                          <Button
                                            variant='contained'
                                            className='w-72 mt-8 mb-4'
                                            color='primary'
                                            onClick={() => {
                                              // this.closeComposeDialog();
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Estimate,
                                                Data,
                                                feature,
                                              } = this.state
                                              // const { Description } = this.state.feature;
                                              const { Features } = Data
                                              Features.push(feature)
                                              const newFeature = {
                                                ID: null,
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Estimate,
                                                Feature:
                                                  (Features.length > 0
                                                    ? _.maxBy(
                                                        Features,
                                                        'Feature',
                                                      ).Feature
                                                    : 0) + 1,
                                                Description: null,
                                                Data: {
                                                  Features: [],
                                                },
                                              }
                                              this.setState(
                                                {
                                                  ...this.state,
                                                  feature: newFeature,
                                                  Data: {
                                                    ...this.state.Data,
                                                    Features,
                                                  },
                                                },
                                                window['warn'](
                                                  newFeature,
                                                  Features,
                                                ),
                                              )
                                            }}
                                            disabled={
                                              !this.state.feature.Description
                                            }
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                  {!this.state.feature && (
                                    <Typography
                                      variant='subtitle2'
                                      style={{
                                        alignItems: 'center',
                                        borderTop:
                                          this.state.Data.Features.length > 0
                                            ? '1px solid #f1f1f1'
                                            : 'none',
                                      }}
                                      className={classNames(
                                        'text-12 flex cursor-pointer justify-end',
                                        this.state.Data.Features.length > 0
                                          ? 'pt-8'
                                          : '',
                                      )}
                                      onClick={() => {
                                        const {
                                          Co,
                                          BusinessUnit,
                                          Division,
                                          Estimate,
                                          Data,
                                        } = this.state
                                        const { Features } = Data
                                        const feature = {
                                          ID: null,
                                          Co,
                                          BusinessUnit,
                                          Division,
                                          Estimate,
                                          Feature:
                                            (Features.length > 0
                                              ? _.maxBy(Features, 'Feature')
                                                  .Feature
                                              : 0) + 1,
                                          Description: null,
                                          Data: {
                                            Features: [],
                                          },
                                        }
                                        this.setState({
                                          ...this.state,
                                          feature,
                                        })
                                      }}
                                    >
                                      <Icon
                                        color='primary'
                                        className='text-18 mr-4'
                                        style={{ marginBottom: 2 }}
                                      >
                                        add
                                      </Icon>
                                      New Detail
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            )}
                          {tab === 0 && (!optionBoard || SelectedOption) && (
                            <div className=''>
                              <Typography className='font-bold text-14 mb-4'>
                                Preview:
                              </Typography>
                              <div
                                className='rounded-6 p-12 px-16'
                                style={{ border: '1px solid #f1f1f1' }}
                              >
                                <Chip
                                  classes={{
                                    root: 'mt-8',
                                    avatar: classes.chipAvatar,
                                    label: classes.chipLabel,
                                  }}
                                  avatar={
                                    <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                                  }
                                  label={'Proposal Details'}
                                  // color="primary"
                                  className={classes.chip}
                                />
                                <div>
                                  <strong className='mr-4 mb-4'>
                                    Description of Work:
                                  </strong>
                                  <br />
                                  {this.state.Description}
                                  {est.Data && est.Data.Components && (
                                    <div className='w-full'>
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'B' && (
                                          <ul>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                return (
                                                  <li
                                                    className='cursor-pointer'
                                                    onClick={() =>
                                                      this.setState({
                                                        ...this.state,
                                                        simpleStep:
                                                          optionBoard &&
                                                          SelectedOption
                                                            ? SelectedOption + 2
                                                            : 2,
                                                        selectedComponent:
                                                          value.Data.Assembly,
                                                      })
                                                    }
                                                  >{`${Qty} - ${
                                                    value.Name || assembly.Name
                                                  }`}</li>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <li className='cursor-pointer'>{`${
                                                    value.Qty
                                                  } ${value.UOM} - ${
                                                    value.Name ||
                                                    item.Description
                                                  }`}</li>
                                                )
                                              }
                                            })}
                                          </ul>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'S' && (
                                          <div>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                if (
                                                  assembly.Data &&
                                                  assembly.Data.Images
                                                ) {
                                                  assembly.Data.Image = _.find(
                                                    assembly.Data.Images,
                                                    { PrimaryYN: 'Y' },
                                                  )
                                                  if (
                                                    !assembly.Data.Image &&
                                                    assembly.Data.Images
                                                      .length > 0
                                                  ) {
                                                    assembly.Data.Image =
                                                      assembly.Data.Images[0]
                                                  }
                                                }
                                                return (
                                                  <div
                                                    onClick={() =>
                                                      this.setState({
                                                        ...this.state,
                                                        simpleStep:
                                                          optionBoard &&
                                                          SelectedOption
                                                            ? SelectedOption + 2
                                                            : 2,
                                                        selectedComponent:
                                                          value.Data.Assembly,
                                                      })
                                                    }
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={assembly.Code}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          assembly.Data.Image
                                                            ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                            : 'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        {/* <div className={classNames(classes.qty)}>{value.Qty}</div> */}
                                                        {value.Name ||
                                                          assembly.Name}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            assembly.Description,
                                                        )}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <div
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={Material}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          CustomImgURL ||
                                                          'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        {/* <div className={classNames(classes.qty)}>{`${value.Qty} ${value.UOM}`}</div> */}
                                                        {value.Name ||
                                                          item.Description}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            item.Description ||
                                                            '',
                                                        )}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'F' && (
                                          <div>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                if (
                                                  assembly.Data &&
                                                  assembly.Data.Images
                                                ) {
                                                  assembly.Data.Image = _.find(
                                                    assembly.Data.Images,
                                                    { PrimaryYN: 'Y' },
                                                  )
                                                  if (
                                                    !assembly.Data.Image &&
                                                    assembly.Data.Images
                                                      .length > 0
                                                  ) {
                                                    assembly.Data.Image =
                                                      assembly.Data.Images[0]
                                                  }
                                                }
                                                return (
                                                  <div
                                                    onClick={() =>
                                                      this.setState({
                                                        ...this.state,
                                                        simpleStep:
                                                          optionBoard &&
                                                          SelectedOption
                                                            ? SelectedOption + 2
                                                            : 2,
                                                        selectedComponent:
                                                          value.Data.Assembly,
                                                      })
                                                    }
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={assembly.Code}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          assembly.Data.Image
                                                            ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                            : 'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        <div
                                                          className={classNames(
                                                            classes.qty,
                                                          )}
                                                        >
                                                          {value.Qty} EA
                                                        </div>
                                                        {value.Name ||
                                                          assembly.Name}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                        {assembly.Code && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-12 mt-4 float-right'
                                                          >
                                                            {assembly.Code}
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            assembly.Description,
                                                        )}
                                                      </Typography>
                                                      {(value.Detail ||
                                                        assembly.Detail) && (
                                                        <Divider className='my-8' />
                                                      )}
                                                      {(value.Detail ||
                                                        assembly.Detail) && (
                                                        <Typography
                                                          className='clear'
                                                          variant='caption'
                                                        >
                                                          {value.Detail ||
                                                            assembly.Detail}
                                                        </Typography>
                                                      )}
                                                      <Typography className='font-bold text-12 my-12 relative'>
                                                        <Typography className='font-bold text-12 float-right'>
                                                          {this.formatDollars(
                                                            value.Price,
                                                          )}
                                                        </Typography>
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <div
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={Material}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          CustomImgURL ||
                                                          'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        <div
                                                          className={classNames(
                                                            classes.qty,
                                                          )}
                                                        >{`${value.Qty} ${value.UOM}`}</div>
                                                        {value.Name ||
                                                          item.Description}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                        <Typography
                                                          color='secondary'
                                                          className='font-bold text-12 mt-4 float-right'
                                                        >
                                                          {Material}
                                                        </Typography>
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            item.Description ||
                                                            '',
                                                        )}
                                                      </Typography>
                                                      <Typography className='font-bold text-12 my-12 relative'>
                                                        <Typography className='font-bold text-12 float-right'>
                                                          {this.formatDollars(
                                                            value.Price,
                                                          )}
                                                        </Typography>
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'C' && (
                                          <ul>
                                            <EstimateFeatures
                                              estimate={{ ...this.state }}
                                              details={this.state.Data.Features}
                                            />
                                          </ul>
                                        )
                                      }
                                    </div>
                                  )}
                                </div>
                                {SelectedOption && (
                                  <Button
                                    variant='contained'
                                    className='w-full no-print'
                                    color='primary'
                                    onClick={() => this.selectOption()}
                                  >
                                    Deselect Option
                                  </Button>
                                )}
                                <Divider className='mb-8 mt-8' />
                                <Table className='mt-8'>
                                  <TableFooter className='mt-24'>
                                    <TableRow className='h-24'>
                                      <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'>
                                        Subtotal
                                      </TableCell>
                                      <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                      <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                      <TableCell
                                        className='w-1/6 text-right p-0 table-cell font-bold text-red border-b-0'
                                        style={{ paddingRight: 0 }}
                                      >
                                        {this.formatDollars(sub)}
                                      </TableCell>
                                    </TableRow>
                                    {this.state.DiscountAmt &&
                                      this.state.DiscountType &&
                                      this.state.DiscountTotal && (
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'>
                                            Discount
                                            {this.state.DiscountType === 'P'
                                              ? ` (${this.state.DiscountAmt}%)`
                                              : ''}
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell
                                            className='w-1/6 text-right p-0 table-cell border-b-0 cursor-pointer'
                                            style={{ paddingRight: 0 }}
                                            onClick={() => {
                                              this.setState({
                                                ...this.state,
                                                addDiscount: true,
                                              })
                                            }}
                                          >
                                            <div className='w-full'>
                                              <Icon
                                                className='cursor-pointer text-14 mt-4 mr-4 align-middle float-right no-print'
                                                style={{ marginRight: -16 }}
                                                color='action'
                                              >
                                                edit
                                              </Icon>
                                              <span className='font-bold border-b-0 float-right'>{`-${this.formatDollars(
                                                this.state.DiscountTotal,
                                              )}`}</span>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    {this.state.IncludeTaxYN === 'Y' && (
                                      <React.Fragment>
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'>
                                            Tax
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/6 text-right p-0 table-cell font-bold text-red border-b-0'>
                                            {this.formatDollars(tax)}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'>
                                            Total
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/6 text-right p-0 table-cell font-bold text-red border-b-0'>
                                            {this.formatDollars(total)}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )}
                                    {!this.state.DiscountTotal &&
                                      !optionBoard &&
                                      !SelectedOption && (
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'></TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell
                                            className='w-1/6 text-right p-0 table-cell border-b-0 cursor-pointer'
                                            style={{ paddingRight: 0 }}
                                            onClick={() => {
                                              this.setState({
                                                ...this.state,
                                                addDiscount: true,
                                              })
                                            }}
                                          >
                                            <div className='w-full'>
                                              <strong
                                                className='ml-4 text-12 float-right'
                                                style={{ color: 'black' }}
                                              >
                                                Add Discount
                                              </strong>
                                              <Icon
                                                className='cursor-pointer text-14 ml-4 mt-4 align-middle float-right'
                                                color='primary'
                                              >
                                                add_circle_outline
                                              </Icon>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                  </TableFooter>
                                </Table>
                              </div>
                            </div>
                          )}
                          <div className='sm:flex justify-end no-print'>
                            <FormControlLabel
                              classes={{ label: 'font-bold' }}
                              control={
                                <Checkbox
                                  checked={this.state.IncludeTaxYN === 'Y'}
                                  onClick={this.toggleTaxability}
                                />
                              }
                              label='Include Tax?'
                            />
                          </div>
                        </div>
                      </FuseAnimate>
                    )}
                  {this.state.addDiscount && (
                    <Dialog
                      //classes={{ paper: classNames("mt-0 mb-0 w-full h-full", !matches && "full-screen-dialog") }}
                      classes={{
                        paper: classNames(
                          classes.discountDialog,
                          'w-full rounded-lg p-24',
                          !matches ? 'm-0 rounded-none full-screen-dialog' : '',
                        ),
                      }}
                      open={this.state.addDiscount}
                      onClose={() =>
                        this.setState({ addDiscount: null, anchorEl: null })
                      }
                      maxWidth='sm'
                      fullScreen={!matches}
                    >
                      <div className='dialog-header'>
                        <DialogTitle
                          classes={{ root: 'text-18 p-0' }}
                          id='confirmation-dialog-title'
                        >
                          <Icon
                            className='mr-8 align-middle'
                            style={{ marginBottom: 2 }}
                            color='primary'
                          >
                            add_circle_outline
                          </Icon>
                          {!this.state.DiscountTotal ? 'Add' : 'Update'}{' '}
                          Discount
                        </DialogTitle>
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: 10,
                            top: matches.medium ? 8 : 4,
                            color: '#333',
                          }}
                          onClick={() => {
                            this.setState({
                              addDiscount: null,
                              anchorEl: null,
                            })
                          }}
                          className='dialog-header-icon'
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </div>
                      <DialogContent classes={{ root: 'p-0 py-8' }}>
                        <div className={classNames('w-full')}>
                          <TextField
                            className='w-full bg-white mb-12'
                            label='Discount Type'
                            id='type'
                            name='DiscountType'
                            variant='outlined'
                            value={this.state.DiscountType || ''}
                            onChange={e =>
                              this.setState({
                                ...this.state,
                                DiscountType: e.target.value,
                                DiscountAmt: null,
                              })
                            }
                            margin='dense'
                            InputLabelProps={{
                              style: {
                                textShadow: '0px 0px 4px white',
                              },
                            }}
                            select
                          >
                            <MenuItem value='P'>Percentage</MenuItem>
                            <MenuItem value='F'>Fixed</MenuItem>
                          </TextField>
                          {this.state.DiscountType && (
                            <TextField
                              className='w-full bg-white mb-12'
                              label='Discount Amount'
                              id='discount-amt'
                              name='DiscountAmt'
                              value={
                                this.state.discountAmt ||
                                this.state.DiscountAmt ||
                                ''
                              }
                              onChange={e =>
                                this.setState({
                                  ...this.state,
                                  discountAmt: e.target.value,
                                })
                              }
                              //onChange={(e) => this.updateDiscountAmt(e, sub)}
                              InputProps={{
                                style: {
                                  textShadow: '0px 0px 4px white',
                                },
                                startAdornment:
                                  this.state.DiscountType === 'F' ? (
                                    <InputAdornment position='start'>
                                      $
                                    </InputAdornment>
                                  ) : null,
                                endAdornment:
                                  this.state.DiscountType === 'P' ? (
                                    <InputAdornment position='end'>
                                      %
                                    </InputAdornment>
                                  ) : null,
                                type: 'number',
                                inputProps: {
                                  min: 0,
                                  max:
                                    this.state.DiscountType === 'P'
                                      ? 100
                                      : sub - 1,
                                },
                              }}
                              variant='outlined'
                              margin='dense'
                              required
                              fullWidth
                            />
                          )}
                          {this.state.DiscountType &&
                            (this.state.discountAmt ||
                              this.state.DiscountAmt) && (
                              <div className='w-full flex'>
                                <Button
                                  variant='contained'
                                  className={classNames(
                                    'w-full',
                                    this.state.DiscountTotal
                                      ? 'rounded-r-none'
                                      : '',
                                  )}
                                  color={'primary'}
                                  onClick={e =>
                                    this.addDiscount(
                                      e,
                                      sub,
                                      optionBoard,
                                      SelectedOption,
                                    )
                                  }
                                  //disabled={(this.state.PaymentType === "CHECK" && !this.state.Check) || (this.state.PaymentType !== "CHECK" && !this.state.Memo)}
                                >
                                  {!this.state.DiscountTotal ? 'Add' : 'Update'}{' '}
                                  Discount
                                </Button>
                                {Boolean(this.state.DiscountTotal) && (
                                  <Button
                                    variant='contained'
                                    className={classNames(
                                      classes.deleteButton,
                                      'w-64 rounded-l-none',
                                    )}
                                    color={'primary'}
                                    onClick={e =>
                                      this.deleteDiscount(
                                        e,
                                        optionBoard,
                                        SelectedOption,
                                      )
                                    }
                                    //disabled={(this.state.PaymentType === "CHECK" && !this.state.Check) || (this.state.PaymentType !== "CHECK" && !this.state.Memo)}
                                  >
                                    <Icon>delete</Icon>
                                  </Button>
                                )}
                              </div>
                            )}
                        </div>
                      </DialogContent>
                    </Dialog>
                  )}
                  {tab === 1 && (
                    <FuseAnimate
                      animation={'transition.slideRightIn'}
                      delay={300}
                    >
                      <div className='w-full p-12 pb-48'>
                        <div className=''>
                          <label
                            className='text-12 p-4 px-6 ml-8'
                            style={{ background: '#fff', color: 'lightgrey' }}
                          >
                            Exclusions:
                          </label>
                          <div
                            className='rounded-4 p-12 mb-12'
                            style={{
                              border: '1px solid rgba(0, 0, 0, .25)',
                              marginTop: -11,
                            }}
                          >
                            <ul
                              className={
                                this.state.Data.Exclusions.length > 0
                                  ? 'mb-8'
                                  : ''
                              }
                            >
                              <EstimateExclusions
                                editable={true}
                                estimate={{ ...this.state }}
                                details={this.state.Data.Exclusions}
                              />
                              {this.state.exclusion && (
                                <li key={this.state.exclusion.Exclusion}>
                                  <div className='flex clear'>
                                    <TextField
                                      className={classNames('mr-8')}
                                      label='New Exclusion'
                                      id='exclusionDescription'
                                      name='exclusion.Description'
                                      value={
                                        this.state.exclusion.Description || ''
                                      }
                                      onChange={this.handleChange}
                                      autoFocus
                                      margin='dense'
                                      onBlur={() => {
                                        if (!this.state.exclusion.Description) {
                                          this.setState({
                                            ...this.state,
                                            exclusion: null,
                                          })
                                        }
                                      }}
                                      // onChange={(e) => this.setState({ ...this.state, exclusion: { ...this.state.exclusion, Description: e.target.value } })}
                                      variant='outlined'
                                      fullWidth
                                    />
                                    <Button
                                      variant='contained'
                                      className='w-72 mt-8 mb-4'
                                      color='primary'
                                      onClick={() => {
                                        // this.closeComposeDialog();
                                        const {
                                          Co,
                                          BusinessUnit,
                                          Division,
                                          Estimate,
                                          Data,
                                          exclusion,
                                        } = this.state
                                        // const { Description } = this.state.exclusion;
                                        const { Exclusions } = Data
                                        Exclusions.push(exclusion)
                                        const newExclusion = {
                                          ID: null,
                                          Co,
                                          BusinessUnit,
                                          Division,
                                          Estimate,
                                          Exclusion:
                                            (Exclusions.length > 0
                                              ? _.maxBy(Exclusions, 'Exclusion')
                                                  .Exclusion
                                              : 0) + 1,
                                          Description: null,
                                          Data: {
                                            Exclusions: [],
                                          },
                                        }
                                        this.setState(
                                          {
                                            ...this.state,
                                            exclusion: newExclusion,
                                            Data: {
                                              ...this.state.Data,
                                              Exclusions,
                                            },
                                          },
                                          // window["warn"](newExclusion, Exclusions)
                                        )
                                      }}
                                      disabled={
                                        !this.state.exclusion.Description
                                      }
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </li>
                              )}
                            </ul>
                            {!this.state.exclusion && (
                              <Typography
                                variant='subtitle2'
                                style={{
                                  alignItems: 'center',
                                  borderTop:
                                    this.state.Data.Exclusions.length > 0
                                      ? '1px solid #f1f1f1'
                                      : 'none',
                                }}
                                className={classNames(
                                  'text-12 flex cursor-pointer justify-end',
                                  this.state.Data.Exclusions.length > 0
                                    ? 'pt-8'
                                    : '',
                                )}
                                onClick={() => {
                                  const {
                                    Co,
                                    BusinessUnit,
                                    Division,
                                    Estimate,
                                    Data,
                                  } = this.state
                                  const { Exclusions } = Data
                                  const exclusion = {
                                    ID: null,
                                    Co,
                                    BusinessUnit,
                                    Division,
                                    Estimate,
                                    Exclusion:
                                      (Exclusions.length > 0
                                        ? _.maxBy(Exclusions, 'Exclusion')
                                            .Exclusion
                                        : 0) + 1,
                                    Description: null,
                                    Data: {
                                      Exclusions: [],
                                    },
                                  }
                                  this.setState({ ...this.state, exclusion })
                                }}
                              >
                                <Icon
                                  color='primary'
                                  className='text-18 mr-4'
                                  style={{ marginBottom: 2 }}
                                >
                                  add
                                </Icon>
                                New Exclusion
                              </Typography>
                            )}
                          </div>
                        </div>
                        <TextField
                          className={classNames('mb-12')}
                          label='Payment Due'
                          select
                          id='paymentDue'
                          name='PaymentDue'
                          value={this.state.PaymentDue}
                          onChange={this.handleChange}
                          variant='outlined'
                          required
                          fullWidth
                        >
                          <MenuItem value='C'>On Completion</MenuItem>
                          <MenuItem value='P'>Prior to Start</MenuItem>
                        </TextField>
                        <TextField
                          className={classNames('mb-12')}
                          label='Terms of Acceptance'
                          id='contractVerbiage'
                          name='ContractVerbiage'
                          value={this.state.ContractVerbiage}
                          onChange={this.handleChange}
                          variant='outlined'
                          multiline
                          rows={5}
                          // autoFocus
                          fullWidth
                        />

                        <TextField
                          className={classNames('mb-12')}
                          label='Disclaimer'
                          id='disclaimer'
                          name='Disclaimer'
                          value={this.state.Disclaimer}
                          onChange={this.handleChange}
                          variant='outlined'
                          multiline
                          rows={5}
                          // autoFocus
                          fullWidth
                        />

                        {(this.state.PaymentDue ||
                          this.state.Disclaimer ||
                          this.state.ContractVerbiage ||
                          this.state.Data.Exclusions.length > 0) && (
                          <div className=''>
                            <Typography className='font-bold text-14 mb-4'>
                              Preview:
                            </Typography>
                            {this.state.PaymentDue && (
                              <div
                                className='mb-12 rounded-6 p-8'
                                style={{ background: '#f1f1f1' }}
                              >
                                <div className='text-10 w-full'>
                                  <b>Payment Due:</b>
                                  <br />
                                  {paymentDue[this.state.PaymentDue]}
                                </div>
                              </div>
                            )}
                            {this.state.Data.Exclusions.length > 0 && (
                              <div
                                className='mb-12 rounded-6 p-8'
                                style={{ background: '#f1f1f1' }}
                              >
                                <div className='text-10 w-full'>
                                  <b>Exclusions:</b>
                                </div>
                                <ul>
                                  <EstimateExclusions
                                    estimate={{ ...this.state }}
                                    details={this.state.Data.Exclusions}
                                  />
                                </ul>
                              </div>
                            )}
                            {this.state.ContractVerbiage && (
                              <div
                                className='mb-12 rounded-6 p-8'
                                style={{ background: '#f1f1f1' }}
                              >
                                <div className='text-10 w-full'>
                                  <b>Terms of Acceptance:</b>
                                  <br />
                                  {this.state.ContractVerbiage}
                                </div>
                              </div>
                            )}
                            {this.state.Disclaimer && (
                              <div
                                className={
                                  this.state.ContractVerbiage ? 'pt-12' : ''
                                }
                                style={{
                                  borderTop: this.state.ContractVerbiage
                                    ? '1px solid #f1f1f1'
                                    : 'none',
                                }}
                              >
                                <div className='font-bold text-red text-10 text-center w-full'>
                                  * {this.state.Disclaimer} *
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </FuseAnimate>
                  )}
                  {tab === 0 &&
                    estimateType &&
                    estimateType === 'O' &&
                    optionBoard &&
                    optionBoard.Data.Options &&
                    simpleStep === steps.length - 2 && (
                      <div className='w-full'>
                        {optionBoard.Name && (
                          <Typography className='w-full text-center font-bold text-20'>
                            {optionBoard.Name}
                          </Typography>
                        )}
                        <div
                          className={classNames(
                            'w-full max-w-full overflow-x-auto flex pt-16 pb-36',
                          )}
                        >
                          {optionBoard.Data.Options &&
                            optionBoard.Data.Options.map(value => {
                              return (
                                <CatalogOptionBoardOptionCard
                                  hidePricing={hidePricing}
                                  includeTaxYN={this.state.IncludeTaxYN}
                                  data={{ ...value }}
                                  isMember={
                                    isMember ||
                                    _.findIndex(
                                      value.Data.Components,
                                      o => o.Type === 4 && o.DeleteYN !== 'Y',
                                    ) > -1
                                  }
                                  estimate={this.state}
                                  onRecommend={opt =>
                                    this.setState({
                                      Data: {
                                        ...Data,
                                        OptionBoard: {
                                          ...Data.OptionBoard,
                                          RecommendedOption: opt.Option,
                                        },
                                      },
                                    })
                                  }
                                  selected={
                                    this.state.SelectedOption === value.Option
                                  }
                                  recommended={
                                    !this.state.SelectedOption &&
                                    optionBoard.RecommendedOption ===
                                      value.Option
                                  }
                                  onChange={() => {}}
                                  optionBoard={{ ...optionBoard }}
                                  options={[...optionBoard.Data.Options]}
                                  catalog={catalog}
                                  onSelected={option =>
                                    this.selectOption(option)
                                  }
                                  onDeSelected={() => this.selectOption()}
                                  SelectedPricing={SelectedPricing}
                                  existingComponents={
                                    this.state.existingComponents
                                  } /*onView={(option) => this.viewOption(option)}*/
                                />
                              )
                            })}
                        </div>
                      </div>
                    )}
                  {tab === 0 &&
                    estimateType &&
                    simpleStep === steps.length - 1 && (
                      <FuseAnimate
                        animation={
                          prevStep < simpleStep
                            ? 'transition.slideRightIn'
                            : 'transition.slideLeftIn'
                        }
                        delay={300}
                      >
                        <div
                          id='estimate-print'
                          className='inv-print sm:mx-24 sm:px-24 mb-64'
                        >
                          <div className='w-full flex justify-between my-24'>
                            {catalog && catalog.EstimateHeaderImg ? (
                              <img
                                className='h-48 sm:h-96'
                                src={catalog.EstimateHeaderImg}
                              />
                            ) : this.state.Data &&
                              this.state.Data.EstimateHeaderImg ? (
                              <img
                                className='h-48 sm:h-96'
                                src={this.state.Data.EstimateHeaderImg}
                              />
                            ) : division && division.InvoiceHeaderImg ? (
                              <img
                                className='h-48 sm:h-96'
                                src={division.InvoiceHeaderImg}
                              />
                            ) : (
                              ''
                            )}
                            <span className='sm:mt-12'>
                              <label
                                className={classNames(
                                  classes.invHeader,
                                  'text-20 sm:text-36',
                                )}
                              >
                                {terminology}
                              </label>
                              <div
                                className={classNames(
                                  classes.invSubHeader,
                                  'text-10 sm:text-16',
                                )}
                              >
                                #{this.state.EstimateNumber}
                              </div>
                            </span>
                          </div>

                          <div className='flex mb-24 justify-between px-16'>
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                this.props.openEditCustomerDialog({
                                  Co: this.state.Co,
                                  Customer: this.state.Customer,
                                })
                              }}
                            >
                              <div className='w-full text-16 font-bold text-left'>
                                {this.state.Data.Customer.Name &&
                                this.state.Data.Customer.Name.length > 0
                                  ? this.state.Data.Customer.Name
                                  : `${this.state.Data.Customer.FirstName} ${this.state.Data.Customer.LastName}`}
                              </div>
                              <div className='w-full text-12 text-left'>
                                {this.state.Data.Customer.BillAddress1}
                              </div>
                              {this.state.Data.Customer.BillAddress2 && (
                                <div className='w-full text-12 text-left'>
                                  {this.state.Data.Customer.BillAddress2}
                                </div>
                              )}
                              <div className='w-full text-12 text-left'>
                                {`${this.state.Data.Customer.BillCity}, ${this.state.Data.Customer.BillState} ${this.state.Data.Customer.BillZip}`}
                              </div>
                            </div>

                            <div>
                              <div className='flex cursor-pointer'>
                                <DatePicker
                                  inputVariant='outlined'
                                  onOpen={() => this.setDatepickerOpen(true)}
                                  onClose={() => this.setDatepickerOpen(false)}
                                  open={this.state.isDatepickerOpen}
                                  label='Date'
                                  inputProps={{
                                    style: {
                                      padding: 12,
                                    },
                                  }}
                                  TextFieldComponent={() => ''}
                                  value={this.state.IssuedDate}
                                  onChange={this.handleDateChange}
                                  showTodayButton
                                  autoOk
                                />
                                <img
                                  src='assets/icons/calendar_icon.png'
                                  onClick={this.openDatePicker}
                                  className='w-32 h-32 mr-8'
                                />
                                <div
                                  className='w-full'
                                  onClick={this.openDatePicker}
                                >
                                  <div className='text-14 font-bold'>
                                    {moment(this.state.IssuedDate).format(
                                      'dddd',
                                    )}
                                  </div>
                                  <div className='text-10'>
                                    {moment(this.state.IssuedDate).format(
                                      'MMM DD, YYYY',
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='flex mt-24'>
                            <div className='w-full'>
                              <div
                                className='w-full flex justify-between rounded-full mb-12'
                                style={{ backgroundColor: '#3f3f3f' }}
                              >
                                <div
                                  className={classNames(
                                    'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                    classes.dividerHighlight,
                                  )}
                                >
                                  {terminology} Details
                                </div>
                                <div
                                  className={classNames(
                                    'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                    classes.dividerPlain,
                                  )}
                                >
                                  {`${this.state.Data.Site.Address1}${
                                    this.state.Data.Site.Address2 &&
                                    this.state.Data.Site.Address2.length > 0
                                      ? ` ${this.state.Data.Site.Address2}`
                                      : ''
                                  } ${this.state.Data.Site.City}, ${
                                    this.state.Data.Site.State
                                  } ${this.state.Data.Site.Zip}`}
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.Greeting && (
                            <div className='flex mx-24 mb-12'>
                              <div className='w-full'>
                                <div>
                                  <strong className='mr-4 mb-4'>
                                    Summary:
                                  </strong>
                                  <Icon
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        EditGreeting: true,
                                      })
                                    }}
                                    className='cursor-pointer text-16 ml-4 no-print align-middle mb-4'
                                  >
                                    edit
                                  </Icon>
                                  <br />
                                  {this.state.Greeting}
                                </div>
                                {this.state.EditGreeting && (
                                  <TextField
                                    className={classNames(
                                      classes.formControl,
                                      'no-print',
                                    )}
                                    label='Summary of Findings'
                                    id='greeting'
                                    name='Greeting'
                                    value={this.state.Greeting}
                                    onChange={this.handleChange}
                                    onBlur={() => {
                                      this.setState({
                                        ...this.state,
                                        EditGreeting: false,
                                      })
                                    }}
                                    variant='outlined'
                                    multiline
                                    rows={5}
                                    autoFocus
                                    fullWidth
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {optionBoard && !SelectedOption ? (
                            <div className='w-full px-20'>
                              {optionBoard.Data.Options &&
                                optionBoard.Data.Options.map((value, index) => {
                                  return (
                                    <CatalogOptionBoardOption
                                      key={index}
                                      matches={matches}
                                      optionBoard={{ ...optionBoard }}
                                      isMember={
                                        isMember ||
                                        _.findIndex(
                                          value.Data.Components,
                                          o =>
                                            o.Type === 4 && o.DeleteYN !== 'Y',
                                        ) > -1
                                      }
                                      selected={
                                        optionBoard.RecommendedOption ===
                                        value.Option
                                      }
                                      data={{
                                        ...value,
                                        ComponentDetail:
                                          this.state.ComponentDetail,
                                      }}
                                      onSelectedComponent={selectedComponent =>
                                        this.setState({
                                          simpleStep: value.Option + 2,
                                          selectedComponent,
                                        })
                                      }
                                      onSelected={option =>
                                        this.selectOption(option)
                                      }
                                      onDeSelected={() => this.selectOption()}
                                      IncludeTaxYN={this.state.IncludeTaxYN}
                                      Site={this.state.Data.Site}
                                      SelectedOption={SelectedOption}
                                      setStateFxn={this.setState.bind(this)}
                                      parentState={this.state}
                                      canAddDiscount={false}
                                      SelectedPricing={SelectedPricing}
                                      existingComponents={
                                        this.state.existingComponents
                                      }
                                    />
                                  )
                                })}
                            </div>
                          ) : (
                            <div className='px-20'>
                              <div
                                className='rounded-6 p-12'
                                style={{ border: '1px solid #f1f1f1' }}
                              >
                                <Chip
                                  classes={{
                                    root: 'mt-8',
                                    avatar: classes.chipAvatar,
                                    label: classes.chipLabel,
                                  }}
                                  avatar={
                                    <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                                  }
                                  label={`${terminology} Details`}
                                  // color="primary"
                                  className={classes.chip}
                                />

                                <div>
                                  {/*(!this.state.Description || this.state.Description.length < 1) && <Icon color="error" className="text-20 mr-4 mb-4 no-print align-middle">warning</Icon>*/}
                                  <strong className='mr-4 mb-4'>
                                    Description of Work:
                                  </strong>
                                  <Icon
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        EditDescription: true,
                                      })
                                    }}
                                    className='cursor-pointer text-16 ml-4 no-print align-middle mb-4'
                                  >
                                    edit
                                  </Icon>
                                  <br />
                                  {this.state.Description}
                                  {this.state.EditDescription && (
                                    <TextField
                                      className={classNames(
                                        classes.formControl,
                                        'no-print',
                                      )}
                                      label='Description'
                                      id='description'
                                      name='Description'
                                      value={this.state.Description}
                                      onChange={this.handleChange}
                                      onBlur={() => {
                                        this.setState({
                                          ...this.state,
                                          EditDescription: false,
                                        })
                                        updateEstimate(this.state)
                                      }}
                                      variant='outlined'
                                      multiline
                                      rows={5}
                                      autoFocus
                                      fullWidth
                                    />
                                  )}
                                  {est.Data && est.Data.Components && (
                                    <div className='w-full my-8'>
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'B' && (
                                          <ul>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                return (
                                                  <li
                                                    className='cursor-pointer'
                                                    onClick={() =>
                                                      this.setState({
                                                        ...this.state,
                                                        simpleStep:
                                                          optionBoard &&
                                                          SelectedOption
                                                            ? SelectedOption + 2
                                                            : 2,
                                                        selectedComponent:
                                                          value.Data.Assembly,
                                                      })
                                                    }
                                                  >{`${Qty} - ${
                                                    value.Name || assembly.Name
                                                  }`}</li>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <li className='cursor-pointer'>{`${
                                                    value.Qty
                                                  } ${value.UOM} - ${
                                                    value.Name ||
                                                    item.Description
                                                  }`}</li>
                                                )
                                              }
                                            })}
                                          </ul>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'S' && (
                                          <div>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                if (
                                                  assembly.Data &&
                                                  assembly.Data.Images
                                                ) {
                                                  assembly.Data.Image = _.find(
                                                    assembly.Data.Images,
                                                    { PrimaryYN: 'Y' },
                                                  )
                                                  if (
                                                    !assembly.Data.Image &&
                                                    assembly.Data.Images
                                                      .length > 0
                                                  ) {
                                                    assembly.Data.Image =
                                                      assembly.Data.Images[0]
                                                  }
                                                }
                                                return (
                                                  <div
                                                    onClick={() =>
                                                      this.setState({
                                                        ...this.state,
                                                        simpleStep:
                                                          optionBoard &&
                                                          SelectedOption
                                                            ? SelectedOption + 2
                                                            : 2,
                                                        selectedComponent:
                                                          value.Data.Assembly,
                                                      })
                                                    }
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={assembly.Code}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          assembly.Data.Image
                                                            ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                            : 'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        {/* <div className={classNames(classes.qty)}>{value.Qty}</div> */}
                                                        {value.Name ||
                                                          assembly.Name}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            assembly.Description,
                                                        )}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <div
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={Material}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          CustomImgURL ||
                                                          'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        {/* <div className={classNames(classes.qty)}>{`${value.Qty} ${value.UOM}`}</div> */}
                                                        {value.Name ||
                                                          item.Description}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            item.Description ||
                                                            '',
                                                        )}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        this.state.ComponentDetail === 'F' && (
                                          <div>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                if (
                                                  assembly.Data &&
                                                  assembly.Data.Images
                                                ) {
                                                  assembly.Data.Image = _.find(
                                                    assembly.Data.Images,
                                                    { PrimaryYN: 'Y' },
                                                  )
                                                  if (
                                                    !assembly.Data.Image &&
                                                    assembly.Data.Images
                                                      .length > 0
                                                  ) {
                                                    assembly.Data.Image =
                                                      assembly.Data.Images[0]
                                                  }
                                                }
                                                return (
                                                  <div
                                                    onClick={() =>
                                                      this.setState({
                                                        ...this.state,
                                                        simpleStep:
                                                          optionBoard &&
                                                          SelectedOption
                                                            ? SelectedOption + 2
                                                            : 2,
                                                        selectedComponent:
                                                          value.Data.Assembly,
                                                      })
                                                    }
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={assembly.Code}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          assembly.Data.Image
                                                            ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                            : 'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        <div
                                                          className={classNames(
                                                            classes.qty,
                                                          )}
                                                        >
                                                          {value.Qty} EA
                                                        </div>
                                                        {value.Name ||
                                                          assembly.Name}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                        {assembly.Code && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-12 mt-4 float-right'
                                                          >
                                                            {assembly.Code}
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            assembly.Description,
                                                        )}
                                                      </Typography>
                                                      {(value.Detail ||
                                                        assembly.Detail) && (
                                                        <Divider className='my-8' />
                                                      )}
                                                      {(value.Detail ||
                                                        assembly.Detail) && (
                                                        <Typography
                                                          className='clear'
                                                          variant='caption'
                                                        >
                                                          {value.Detail ||
                                                            assembly.Detail}
                                                        </Typography>
                                                      )}
                                                      <Typography className='font-bold text-12 my-12 relative'>
                                                        <Typography className='font-bold text-12 float-right'>
                                                          {this.formatDollars(
                                                            value.Price,
                                                          )}
                                                        </Typography>
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <div
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={Material}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          CustomImgURL ||
                                                          'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        <div
                                                          className={classNames(
                                                            classes.qty,
                                                          )}
                                                        >{`${value.Qty} ${value.UOM}`}</div>
                                                        {value.Name ||
                                                          item.Description}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                        <Typography
                                                          color='secondary'
                                                          className='font-bold text-12 mt-4 float-right'
                                                        >
                                                          {Material}
                                                        </Typography>
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            item.Description ||
                                                            '',
                                                        )}
                                                      </Typography>
                                                      <Typography className='font-bold text-12 my-12 relative'>
                                                        <Typography className='font-bold text-12 float-right'>
                                                          {this.formatDollars(
                                                            value.Price,
                                                          )}
                                                        </Typography>
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                        )
                                      }
                                    </div>
                                  )}
                                  {
                                    //Basic Detail
                                    this.state.ComponentDetail === 'C' && (
                                      <ul className='prevent-split'>
                                        <EstimateFeatures
                                          estimate={{ ...this.state }}
                                          details={this.state.Data.Features}
                                        />
                                      </ul>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          )}
                          {optionBoard && SelectedOption && (
                            <div className='mx-24 mt-12'>
                              <Button
                                variant='contained'
                                className='w-full no-print'
                                color='primary'
                                onClick={() => this.selectOption()}
                              >
                                Deselect Option
                              </Button>
                            </div>
                          )}
                          <div className='prevent-split'>
                            {(!optionBoard || SelectedOption) && (
                              <div className='mx-24 mt-8'>
                                <Table>
                                  <TableFooter className='mt-24'>
                                    <TableRow className='h-24'>
                                      <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'>
                                        Subtotal
                                      </TableCell>
                                      <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                      <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                      <TableCell
                                        className='w-1/6 text-right p-0 table-cell font-bold text-red border-b-0'
                                        style={{ paddingRight: 0 }}
                                      >
                                        {this.formatDollars(sub)}
                                      </TableCell>
                                    </TableRow>
                                    {this.state.DiscountAmt &&
                                      this.state.DiscountType &&
                                      this.state.DiscountTotal && (
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'>
                                            Discount
                                            {this.state.DiscountType === 'P'
                                              ? ` (${this.state.DiscountAmt}%)`
                                              : ''}
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell
                                            className='w-1/6 text-right p-0 table-cell border-b-0 cursor-pointer'
                                            style={{ paddingRight: 0 }}
                                            onClick={() => {
                                              this.setState({
                                                ...this.state,
                                                addDiscount: true,
                                              })
                                            }}
                                          >
                                            <div className='w-full'>
                                              <Icon
                                                className='cursor-pointer text-14 mt-4 mr-4 align-middle float-right no-print'
                                                style={{ marginRight: -16 }}
                                                color='action'
                                              >
                                                edit
                                              </Icon>
                                              <span className='font-bold border-b-0 float-right'>{`-${this.formatDollars(
                                                this.state.DiscountTotal,
                                              )}`}</span>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    {this.state.IncludeTaxYN === 'Y' && (
                                      <React.Fragment>
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'>
                                            Tax
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/6 text-right p-0 table-cell font-bold text-red border-b-0'>
                                            {this.formatDollars(tax)}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold text-red border-b-0'>
                                            Total
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold border-b-0'></TableCell>
                                          <TableCell className='w-1/6 text-right p-0 table-cell font-bold text-red border-b-0'>
                                            {this.formatDollars(total)}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )}
                                    {/* {!this.state.DiscountTotal && (!optionBoard && !SelectedOption) &&
                                                                        <TableRow className="h-24">
                                                                            <TableCell className="w-1/6 p-0 table-cell font-bold text-red border-b-0"></TableCell>
                                                                            <TableCell className="w-1/6 p-0 table-cell font-bold border-b-0"></TableCell>
                                                                            <TableCell className="w-1/2 table-cell p-0 font-bold border-b-0"></TableCell>
                                                                            <TableCell className="w-1/6 text-right p-0 table-cell border-b-0 cursor-pointer" style={{ paddingRight: 0 }} onClick={() => { this.setState({ ...this.state, addDiscount: true }) }}>

                                                                                <div className="w-full">
                                                                                    <strong className="ml-4 text-12 float-right" style={{ color: 'black' }}>Add Discount</strong>
                                                                                    <Icon className="cursor-pointer text-14 ml-4 mt-4 align-middle float-right" color="primary">add_circle_outline</Icon>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    } */}
                                  </TableFooter>
                                </Table>
                              </div>
                            )}
                            <div className='flex my-24'>
                              <div className='w-full'>
                                <div
                                  className='w-full flex justify-between rounded-full'
                                  style={{ backgroundColor: '#3f3f3f' }}
                                >
                                  <DatePicker
                                    inputVariant='outlined'
                                    onOpen={() =>
                                      this.setExpirationDatepickerOpen(true)
                                    }
                                    onClose={() =>
                                      this.setExpirationDatepickerOpen(false)
                                    }
                                    open={this.state.isExpirationDatepickerOpen}
                                    label='Date'
                                    inputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    TextFieldComponent={() => ''}
                                    value={this.state.ExpirationDate}
                                    onChange={this.handleExpireDateChange}
                                    showTodayButton
                                    autoOk
                                  />
                                  <div
                                    className={classNames(
                                      'w-2/3 py-8 pl-24 text-12 font-bold cursor-pointer',
                                      classes.dividerPlain,
                                    )}
                                    onClick={() =>
                                      this.setExpirationDatepickerOpen(true)
                                    }
                                  >
                                    EXPIRES ON{' '}
                                    {moment(this.state.ExpirationDate).format(
                                      'MMM DD, YYYY',
                                    )}
                                  </div>
                                  {issuedByTech && (
                                    <div
                                      className={classNames(
                                        'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                                        classes.dividerHighlight,
                                      )}
                                    >
                                      <div className='text-12 mt-8 mr-24 font-bold'>
                                        Issued By:
                                      </div>
                                      <div className='font-bold'>
                                        <div className='flex mt-4'>
                                          <img
                                            src='assets/icons/person_icon.png'
                                            className='w-24 h-24 mr-4'
                                          />
                                          <div className='w-full'>
                                            <div className='text-12 font-bold mt-4 flex'>
                                              <div>{`${issuedByTech.FirstName}`}</div>
                                              <div className='ml-4 flex'>
                                                {`${issuedByTech.LastName.substr(
                                                  0,
                                                  1,
                                                )}`}
                                                <div className='no-print'>{`${issuedByTech.LastName.substr(
                                                  1,
                                                  issuedByTech.LastName.length -
                                                    1,
                                                )}`}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='mx-20'>
                              <div className='w-full'>
                                <strong style={{ marginRight: 4 }}>
                                  Terms & Conditions:
                                </strong>
                              </div>
                            </div>
                            {this.state.PaymentDue && (
                              <div
                                className='m-12 mx-20 mt-4 rounded-6 p-8'
                                style={{ background: '#f1f1f1' }}
                              >
                                <div className='text-10 w-full'>
                                  <b>Payment Due:</b>
                                  <br />
                                  {paymentDue[this.state.PaymentDue]}
                                </div>
                              </div>
                            )}
                            {this.state.Data.Exclusions.length > 0 && (
                              <div
                                className='m-12 mx-20 rounded-6 p-8'
                                style={{ background: '#f1f1f1' }}
                              >
                                <div className='text-10 w-full'>
                                  <b>Exclusions:</b>
                                </div>
                                <ul>
                                  <EstimateExclusions
                                    estimate={{ ...this.state }}
                                    details={this.state.Data.Exclusions}
                                  />
                                </ul>
                              </div>
                            )}
                            {this.state.Disclaimer && (
                              <div className='mx-24 mt-8'>
                                <div className='font-bold text-red text-10 text-center w-full'>
                                  * {this.state.Disclaimer} *
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='no-print'>
                            <Dialog
                              open={this.state.isSigning}
                              classes={{ paper: classes.signaturePaper }}
                              fullWidth
                              maxWidth='sm'
                              onClose={() => {
                                if (this.sigPad.isEmpty()) {
                                  this.setState({
                                    ...this.state,
                                    isSigning: false,
                                  })
                                }
                              }}
                            >
                              <div className='w-full mb-4 px-4'>
                                <div className='w-full h-20'>
                                  <div
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        EditAcceptedBy: true,
                                      })
                                    }}
                                    className='float-left w-1/2 truncate'
                                  >
                                    <strong className='mr-4'>
                                      Accepted By:
                                    </strong>
                                    {this.state.AcceptedBy}
                                    <Icon className='cursor-pointer text-16 ml-4 no-print align-middle mb-4'>
                                      edit
                                    </Icon>
                                  </div>
                                  {this.state.AcceptedDate && (
                                    <div className='float-right'>
                                      <strong className='mr-4'>Date:</strong>
                                      {new Date(
                                        this.state.AcceptedDate,
                                      ).toLocaleDateString('en-US')}
                                    </div>
                                  )}
                                </div>
                                {this.state.isSigning &&
                                  this.state.EditAcceptedBy && (
                                    <TextField
                                      className={classes.formControl}
                                      label='Accepted By'
                                      id='acceptedBy'
                                      name='AcceptedBy'
                                      value={this.state.AcceptedBy}
                                      onChange={this.handleChange}
                                      onBlur={() => {
                                        this.setState({
                                          ...this.state,
                                          EditAcceptedBy: false,
                                        })
                                      }}
                                      variant='outlined'
                                      autoFocus
                                      fullWidth
                                    />
                                  )}
                              </div>
                              {this.state.ContractVerbiage && (
                                <div
                                  className='mb-12 rounded-6 p-8'
                                  style={{ background: '#f1f1f1' }}
                                >
                                  <div className='text-10 w-full'>
                                    <b>Terms of Acceptance:</b>
                                    <br />
                                    {this.state.ContractVerbiage}
                                  </div>
                                </div>
                              )}
                              <div
                                className='w-full'
                                style={{
                                  border: '1px dotted #3f3f3f',
                                  borderRadius: 5,
                                }}
                              >
                                <SignatureCanvas
                                  ref={ref => {
                                    this.sigPad = ref
                                  }}
                                  penColor='#66aef9'
                                  canvasProps={{
                                    className: 'sigCanvas',
                                    style: { width: '100%', height: 150 },
                                  }}
                                />
                              </div>
                              <div className='flex w-full mt-16'>
                                <Button
                                  variant='contained'
                                  className={classes.clearBtn}
                                  fullWidth
                                  onClick={this.clear}
                                >
                                  Clear
                                </Button>
                                <div className='min-w-12'></div>
                                <Button
                                  variant='contained'
                                  className={classes.okBtn}
                                  fullWidth
                                  onClick={this.trim}
                                >
                                  OK
                                </Button>
                              </div>
                            </Dialog>
                          </div>
                          <div className='prevent-split'>
                            {(!optionBoard || SelectedOption) && (
                              <div className='m-20 mb-0'>
                                <div className='w-full mb-4'>
                                  <div className='w-full h-20 px-4'>
                                    <div
                                      onClick={() => {
                                        this.setState({
                                          ...this.state,
                                          EditAcceptedBy: true,
                                        })
                                      }}
                                      className='float-left w-1/2 truncate'
                                    >
                                      <strong className='mr-4'>
                                        Accepted By:
                                      </strong>
                                      {this.state.AcceptedBy}
                                      <Icon className='cursor-pointer text-16 ml-4 no-print align-middle mb-4'>
                                        edit
                                      </Icon>
                                    </div>
                                    {this.state.AcceptedDate && (
                                      <div className='float-right'>
                                        <strong className='mr-4'>Date:</strong>
                                        {new Date(
                                          this.state.AcceptedDate,
                                        ).toLocaleDateString('en-US')}
                                      </div>
                                    )}
                                  </div>
                                  {!this.state.isSigning &&
                                    this.state.EditAcceptedBy && (
                                      <TextField
                                        className={classes.formControl}
                                        label='Accepted By'
                                        id='acceptedBy'
                                        name='AcceptedBy'
                                        value={this.state.AcceptedBy}
                                        onChange={this.handleChange}
                                        onBlur={() => {
                                          this.setState({
                                            ...this.state,
                                            EditAcceptedBy: false,
                                          })
                                        }}
                                        variant='outlined'
                                        autoFocus
                                        fullWidth
                                      />
                                    )}
                                </div>
                                {this.state.ContractVerbiage && (
                                  <div
                                    className='mb-12 rounded-6 p-8'
                                    style={{ background: '#f1f1f1' }}
                                  >
                                    <div className='text-10 w-full'>
                                      <b>Terms of Acceptance:</b>
                                      <br />
                                      {this.state.ContractVerbiage}
                                    </div>
                                  </div>
                                )}
                                <div
                                  className='w-full mb-4'
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      isSigning: true,
                                    })
                                  }}
                                  style={{
                                    border: '1px dotted #3f3f3f',
                                    width: '100%',
                                    height: 150,
                                    borderRadius: 5,
                                  }}
                                >
                                  {this.state.Signature && (
                                    <img
                                      style={{ width: '100%', height: 150 }}
                                      src={this.state.Signature}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            <div className='m-24 mt-0 pt-24'>
                              <div className={classNames('w-full mb-12')}>
                                <InvoiceAddPaymentComponent
                                  due={due}
                                  readOnly={false}
                                  isFinanced={this.state.IsFinanced}
                                  isApprovedAccount={
                                    this.state.IsApprovedAccount
                                  }
                                  onIsFinancedChange={() =>
                                    this.setState(prevState => ({
                                      ...prevState,
                                      IsFinanced: !prevState.IsFinanced,
                                      IsApprovedAccount: false,
                                    }))
                                  }
                                  onIsApprovedAccountChange={() =>
                                    this.setState(prevState => ({
                                      ...prevState,
                                      IsApprovedAccount:
                                        !prevState.IsApprovedAccount,
                                      IsFinanced: false,
                                    }))
                                  }
                                  onAddPaymentClick={() => {
                                    if (
                                      !this.state.IsFinanced &&
                                      !this.state.IsApprovedAccount
                                    ) {
                                      this.setState({
                                        ...this.state,
                                        addPayment: true,
                                      })
                                    }
                                  }}
                                />
                                {est.Data &&
                                  est.Data.Payments &&
                                  est.Data.Payments.map(pmt => {
                                    const voided =
                                      Boolean(pmt.VoidDate) ||
                                      (pmt.Data &&
                                        pmt.Data.Payment &&
                                        Boolean(pmt.Data.Payment.VoidDate))
                                    return (
                                      <div
                                        onClick={() =>
                                          this.setState({ viewPayment: pmt })
                                        }
                                        className='w-full flex pt-8 pb-8 text-11 font-bold relative cursor-pointer'
                                      >
                                        <div className='mr-16'>
                                          {new Date(
                                            pmt.PaymentDateTime,
                                          ).toLocaleDateString('en-US')}
                                        </div>
                                        {pmt.Check ? (
                                          <React.Fragment>
                                            <div
                                              className={classNames(
                                                voided
                                                  ? 'mr-6 line-through'
                                                  : 'mr-16',
                                              )}
                                            >{`Check #${pmt.Check}`}</div>
                                          </React.Fragment>
                                        ) : pmt.CardLastFour ? (
                                          <React.Fragment>
                                            <img
                                              src={`assets/images/cards/${pmt.PaymentMethodType}.png`}
                                              className='w-20 mr-4'
                                              style={{ height: 14 }}
                                            />
                                            <div
                                              className={classNames(
                                                voided
                                                  ? 'mr-6 line-through'
                                                  : 'mr-16',
                                              )}
                                            >{`${
                                              paymentTypes[
                                                pmt.PaymentMethodType
                                              ]
                                            } ending in ${
                                              pmt.CardLastFour
                                            }`}</div>
                                          </React.Fragment>
                                        ) : pmt.Data &&
                                          pmt.Data.PaymentMethod &&
                                          pmt.Data.PaymentMethod
                                            .CardLastFour ? (
                                          <React.Fragment>
                                            <img
                                              src={`assets/images/cards/${pmt.Data.PaymentMethod.PaymentMethodType}.png`}
                                              className='w-20 mr-4'
                                              style={{ height: 14 }}
                                            />
                                            <div
                                              className={classNames(
                                                voided
                                                  ? 'mr-6 line-through'
                                                  : 'mr-16',
                                              )}
                                            >{`${
                                              paymentTypes[
                                                pmt.Data.PaymentMethod
                                                  .PaymentMethodType
                                              ]
                                            } ending in ${
                                              pmt.Data.PaymentMethod
                                                .CardLastFour
                                            }`}</div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className={classNames(
                                                voided
                                                  ? 'mr-6 line-through'
                                                  : 'mr-16',
                                              )}
                                            >{`OTHER${
                                              pmt.Memo ? ` - ${pmt.Memo}` : ''
                                            }`}</div>
                                          </React.Fragment>
                                        )}
                                        {voided ? (
                                          <div className={classNames('mr-16')}>
                                            {' '}
                                            - VOIDED
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <div
                                          className={classNames(
                                            'pin-r absolute',
                                            voided ? 'line-through' : '',
                                          )}
                                        >{`${this.formatDollars(
                                          pmt.Amount || pmt.PaymentTotal,
                                        )}`}</div>
                                      </div>
                                    )
                                  })}

                                <div
                                  className='w-full'
                                  style={{ marginBottom: -20 }}
                                >
                                  <div className='w-full flex justify-end rounded-full'>
                                    <div
                                      className={classNames(
                                        'w-auto rounded-full text-12 font-bold text-right flex justify-between',
                                      )}
                                    >
                                      <div className='text-12 mt-4 mr-24 font-bold'>
                                        Total Paid:
                                      </div>
                                      <div className='text-12 mt-4 min-w-96 font-bold'>
                                        {this.formatDollars(total - due)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='w-full flex justify-end rounded-full'>
                                    <div
                                      className={classNames(
                                        'w-auto rounded-full text-12 px-12 font-bold text-right flex justify-between text-white py-4',
                                      )}
                                      style={{
                                        background: '#3f3f3f',
                                        marginRight: -12,
                                      }}
                                    >
                                      <div className='text-12 mr-24 font-bold'>
                                        Balance Due:
                                      </div>
                                      <div
                                        className={classNames(
                                          'text-12 min-w-96 font-bold',
                                        )}
                                      >
                                        {due > 0
                                          ? this.formatDollars(due)
                                          : '$0.00'}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.addPayment && (
                                  <InvoicePayment
                                    open={this.state.addPayment}
                                    data={{
                                      ...est.Data.Customer,
                                      ...this.state,
                                      total: due,
                                    }}
                                    paymentMethods={est.Data.PaymentMethods}
                                    onPayment={this.handlePayment}
                                    onClose={() => {
                                      this.setState({ addPayment: false })
                                    }}
                                  />
                                )}
                                {this.state.viewPayment && (
                                  <InvoicePaymentRecord
                                    open={this.state.viewPayment}
                                    data={{
                                      ...est.Data.Customer,
                                      ...this.state,
                                    }}
                                    payment={{ ...this.state.viewPayment }}
                                    onVoid={this.handleVoidPayment}
                                    onClose={() => {
                                      this.setState({ viewPayment: false })
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='mx-24'>
                            <div className='w-full sm:flex sm:justify-between'>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/location_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      {co.Name}
                                    </div>
                                    <div className='text-10'>{co.Address}</div>
                                    <div className='text-10'>
                                      {`${co.City}, ${co.State} ${co.Zip}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/email_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Email
                                    </div>
                                    <div className='text-10'>{co.Email}</div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/phone_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Phone
                                    </div>
                                    <div className='text-10'>
                                      {this.formatPhone(co.Phone)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </FuseAnimate>
                    )}
                </DialogContent>

                {tab === 0 &&
                  catalog &&
                  catalog.EnableSavedOptionBoardsYN === 'Y' &&
                  simpleStep === 2 &&
                  estimateType === 'O' && (
                    <DialogActions
                      className={classNames(
                        'pin-b pin-l pin-r p-0 absolute bg-white py-8 mb-48 flex justify-between estimate-stepper',
                        classes.estimateSummaryBottom,
                      )}
                    >
                      <Button
                        color={newOrExisting !== '+' ? 'action' : 'primary'}
                        onClick={() => this.setState({ newOrExisting: '+' })}
                        className='w-full'
                      >
                        <Icon
                          color={newOrExisting !== '+' ? 'action' : 'primary'}
                          className='mr-6'
                        >
                          add_circle_outline
                        </Icon>
                        Custom Board
                      </Button>
                      <Button
                        color={newOrExisting === '+' ? 'action' : 'primary'}
                        onClick={() => this.setState({ newOrExisting: '-' })}
                        className='w-full'
                      >
                        <Icon
                          color={newOrExisting === '+' ? 'action' : 'primary'}
                          className='mr-6'
                        >
                          file_copy
                        </Icon>
                        Copy Existing
                      </Button>
                    </DialogActions>
                  )}
                {tab === 0 &&
                  (estimateType === 'S' ||
                    (estimateType === 'O' &&
                      simpleStep < steps.length - 3 &&
                      simpleStep > 2)) && (
                    <DialogActions
                      className={classNames(
                        'pin-b pin-l pin-r p-0 absolute bg-white',
                        !hideSummary
                          ? classes.estimateSummary
                          : classes.estimateSummaryBottom,
                        !selectedComponent && !selectedTMItem ? 'mb-48' : '',
                      )}
                    >
                      <div className={classNames('relative w-full')}>
                        <div
                          onClick={() =>
                            this.setState({
                              hideSummary:
                                currentOption ||
                                (this.state.Data.Components || []).length > 0
                                  ? !hideSummary
                                  : true,
                            })
                          }
                          className={classNames(
                            'w-full flex justify-between p-12 py-8 cursor-pointer',
                            hideSummary && 'estimate-stepper',
                          )}
                          style={{
                            backgroundColor: '#3f3f3f',
                            borderTop: '2px solid #3f3f3f',
                          }}
                        >
                          <Badge
                            classes={{ badge: classes.countBadge }}
                            color='error'
                            badgeContent={
                              currentOption
                                ? _.filter(
                                    currentOption.Data.Components,
                                    o => o.DeleteYN !== 'Y',
                                  ).length
                                : (
                                    _.filter(
                                      this.state.Data.Components,
                                      o => o.DeleteYN !== 'Y',
                                    ) || []
                                  ).length
                            }
                          >
                            <Typography
                              onClick={e => {
                                if (selectedComponent || selectedTMItem) {
                                  e.stopPropagation()
                                  this.setState({
                                    selectedComponent: null,
                                    selectedTMItem: null,
                                    hideSummary: true,
                                  })
                                }
                              }}
                              variant='h6'
                              className='text-16 text-white flex'
                            >
                              {(selectedComponent || selectedTMItem) && (
                                <Icon className='mr-4'>
                                  keyboard_arrow_left
                                </Icon>
                              )}
                              {currentOption ? (
                                <React.Fragment>
                                  {currentOption.Name}
                                  <Hidden
                                    xsDown
                                  >{` - ${currentOption.Option} of ${Data.OptionBoard.Data.Options.length}`}</Hidden>
                                </React.Fragment>
                              ) : (
                                `${terminology} #${this.state.EstimateNumber}`
                              )}
                            </Typography>
                          </Badge>
                          {/* <Typography variant="h6" className="text-16 flex" color="primary">{this.state.Data.Components.length} Items</Typography> */}
                          <Typography
                            color='secondary'
                            variant='h6'
                            className={classNames('font-bold text-16 mr-4')}
                          >
                            Subtotal:{' '}
                            <span className='text-white'>
                              {this.formatDollars(sub)}
                            </span>
                            {((this.state.Data.Components &&
                              this.state.Data.Components.length > 0) ||
                              (optionBoard &&
                                _.findIndex(
                                  optionBoard.Data.Options,
                                  o => o.Data.Components.length > 0,
                                ) > -1)) && (
                              <Icon className='text-white ml-12 align-middle'>
                                {hideSummary
                                  ? 'keyboard_arrow_up'
                                  : 'keyboard_arrow_down'}
                              </Icon>
                            )}
                          </Typography>
                        </div>
                        {!hideSummary && currentOption && (
                          <div className='w-full flex'>
                            <div className='w-full text-left'>
                              <FormControlLabel
                                className='ml-12 truncate'
                                // style={{ float: 'right', }}
                                control={
                                  <Switch
                                    checked={this.state.addDescription}
                                    onClick={() =>
                                      this.setState({
                                        addDescription:
                                          !this.state.addDescription,
                                      })
                                    }
                                    color='primary'
                                  />
                                }
                                label='Edit Description'
                              />
                            </div>
                            <div className='w-full text-right'>
                              <FormControlLabel
                                className='mr-24'
                                // style={{ float: 'right', }}
                                control={
                                  <Switch
                                    checked={
                                      optionBoard.RecommendedOption ===
                                      currentOption.Option
                                    }
                                    onClick={() =>
                                      this.setState({
                                        Data: {
                                          ...Data,
                                          OptionBoard: {
                                            ...Data.OptionBoard,
                                            RecommendedOption:
                                              optionBoard.RecommendedOption ===
                                              currentOption.Option
                                                ? null
                                                : currentOption.Option,
                                          },
                                        },
                                      })
                                    }
                                    color='primary'
                                  />
                                }
                                label='Recommended'
                              />
                            </div>
                          </div>
                        )}
                        {!hideSummary &&
                          optionBoard &&
                          !this.state.addDescription &&
                          currentOption.Description && (
                            <div className='w-full px-24 mb-12'>
                              <Typography className={'text-14 font-bold'}>
                                Description of Work:
                              </Typography>
                              <Typography className={'text-12'}>
                                {currentOption.Description}
                              </Typography>
                            </div>
                          )}
                        {!hideSummary &&
                          optionBoard &&
                          this.state.addDescription && (
                            <div className='w-full sm:flex px-24 my-8'>
                              <TextField
                                className={classNames('mb-12')}
                                label='Description of Work'
                                id='descriptionOfWork'
                                value={currentOption.Description || ''}
                                onChange={e => {
                                  const { Options } =
                                    this.state.Data.OptionBoard.Data
                                  const index = _.findIndex(Options, {
                                    Option: currentOption.Option,
                                  })
                                  if (index > -1) {
                                    const opt = Options[index]
                                    opt.Description = e.target.value
                                    Options.splice(index, 1, opt)
                                    this.setState({
                                      ...this.state,
                                      Data: {
                                        ...this.state.Data,
                                        OptionBoard: {
                                          ...optionBoard,
                                          Data: {
                                            ...optionBoard.Data,
                                            Options,
                                          },
                                        },
                                      },
                                    })
                                  }
                                }}
                                variant='outlined'
                                multiline
                                rows={3}
                                fullWidth
                                // required
                              />
                            </div>
                          )}
                        {!hideSummary &&
                          currentOption &&
                          currentOption.Data.Components.length < 1 && (
                            <FuseAnimate
                              animation='transition.slideUpIn'
                              delay={300}
                            >
                              <div className='dialog-actions'>
                                <div
                                  className='w-full px-12'
                                  style={{ backgroundColor: '#f1f1f1' }}
                                >
                                  <div className='w-full flex max-w-full overflow-x-auto'>
                                    {optionBoard.Data.Options.map(
                                      (opt, index) => {
                                        if (
                                          opt.Option !== currentOption.Option &&
                                          opt.Data.Components.length > 0
                                        ) {
                                          return (
                                            <EstimateOptionCard
                                              option={opt}
                                              key={opt.Option}
                                              optionBoard={optionBoard}
                                              co={co}
                                              catalog={catalog}
                                              onCopy={Components =>
                                                this.setOptionComponents(
                                                  currentOption,
                                                  Components,
                                                )
                                              }
                                            />
                                          )
                                        }
                                      },
                                    )}
                                  </div>
                                </div>
                              </div>
                            </FuseAnimate>
                          )}
                        {!hideSummary && (
                          <FuseAnimate
                            animation='transition.slideUpIn'
                            delay={300}
                          >
                            <div className='dialog-actions'>
                              <div
                                className={classNames(
                                  'w-full px-12 relative',
                                  Recommendations &&
                                    Recommendations.length > 0 &&
                                    'pb-40',
                                )}
                                style={{ backgroundColor: '#f1f1f1' }}
                              >
                                <EstimateComponents
                                  topBar={true}
                                  catalog={catalog}
                                  estimate={
                                    !currentOption ? this.state : currentOption
                                  }
                                  optionBoard={{ ...optionBoard }}
                                  SelectedPricing={this.state.SelectedPricing}
                                  onSelected={selectedComponent =>
                                    this.setState({
                                      ...this.state,
                                      simpleStep: optionBoard ? simpleStep : 2,
                                      selectedComponent,
                                      selectedTMItem,
                                      hideSummary: true,
                                    })
                                  }
                                  onSelectedItem={selectedTMItem =>
                                    this.setState({
                                      ...this.state,
                                      simpleStep: optionBoard ? simpleStep : 2,
                                      selectedTMItem,
                                      hideSummary: true,
                                    })
                                  }
                                  components={components}
                                  onUpdate={Components => {
                                    if (!currentOption) {
                                      this.state.Signature
                                        ? this.setState(
                                            {
                                              ...this.state,
                                              confirmUpdate: Components,
                                            },
                                            this.calculateEstimatePrice,
                                          )
                                        : this.setState(
                                            {
                                              ...this.state,
                                              Data: {
                                                ...this.state.Data,
                                                Components,
                                              },
                                              hideSummary:
                                                Components.length > 0
                                                  ? hideSummary
                                                  : true,
                                            },
                                            this.calculateEstimatePrice,
                                          )
                                    } else {
                                      this.state.Signature
                                        ? this.setState(
                                            {
                                              ...this.state,
                                              confirmUpdate: Components,
                                            },
                                            this.calculateEstimatePrice,
                                          )
                                        : this.setOptionComponents(
                                            currentOption,
                                            Components,
                                          )
                                    }
                                  }}
                                />
                                {Recommendations &&
                                  Recommendations.length > 0 && (
                                    <div
                                      className={classNames(
                                        classes.recommendedItemsDiv,
                                        'absolute',
                                      )}
                                    >
                                      <Button
                                        className={classNames(
                                          classes.recommendedItemsBtn,
                                        )}
                                        onClick={() =>
                                          this.setState({
                                            ...this.state,
                                            showRecommendations: true,
                                          })
                                        }
                                      >
                                        RECOMMENDED ITEMS
                                        <div
                                          className={classNames(
                                            classes.badge,
                                            'rounded-full px-2 text-white mx-6 align-middle min-w-20 text-center',
                                          )}
                                        >
                                          {Recommendations.length}
                                        </div>
                                      </Button>
                                    </div>
                                  )}

                                {/* <div className="w-full px-12" style={{ backgroundColor: '#f1f1f1' }}>
                                                            <EstimateComponents 
                                                                topBar={true} 
                                                                catalog={catalog} 
                                                                estimate={!currentOption ? this.state : currentOption} optionBoard={{ ...optionBoard }} 
                                                                onSelected={(selectedComponent) => this.setState({ ...this.state, simpleStep: optionBoard ? simpleStep : 2, selectedComponent, selectedTMItem, hideSummary: true })} 
                                                                onSelectedItem={(selectedTMItem) => this.setState({ ...this.state, simpleStep: optionBoard ? simpleStep : 2, selectedTMItem, hideSummary: true })} 
                                                                components={currentOption ? currentOption.Data.Components : this.state.Data.Components} 
                                                                onUpdate={(Components) => { this.state.Signature ? this.setState({ ...this.state, confirmUpdate: Components }) : this.onUpdate(currentOption, Components);  }} 
                                                            />
                                                            
                                                        </div> */}
                              </div>
                            </div>
                          </FuseAnimate>
                        )}
                        <div ref={this.ref}>
                          {showRecommendations &&
                            estimate &&
                            Recommendations.length > 0 &&
                            _.filter(Recommendations, o => {
                              return o.DeleteYN !== 'Y'
                            }).length > 0 && (
                              <CatalogRecommendationPopup
                                source='drawer'
                                Recommendations={Recommendations}
                                isMember={isMember}
                                tasks={tasks}
                                estimate={estimate}
                                closeRecommendations={() =>
                                  this.setState({
                                    ...this.state,
                                    showRecommendations: false,
                                  })
                                }
                                onUpdated={Components =>
                                  this.recommendationsOnUpdated(
                                    Components,
                                    currentOption,
                                  )
                                }
                                ogMb={true}
                                SelectedPricing={SelectedPricing}
                                onSelected={selectedComponent =>
                                  this.setState({
                                    ...this.state,
                                    simpleStep: optionBoard ? simpleStep : 2,
                                    selectedComponent,
                                    selectedTMItem,
                                    hideSummary: true,
                                    showRecommendations: false,
                                  })
                                }
                              />
                            )}
                        </div>
                      </div>
                    </DialogActions>
                  )}
                {tab === 0 && !selectedComponent && !selectedTMItem && (
                  <DialogActions
                    className={classNames(
                      'pin-b pin-l pin-r p-0 absolute bg-white',
                    )}
                    style={{ zIndex: 9999 }}
                  >
                    <div className='w-full justify-between'>
                      <MobileStepper
                        variant='dots'
                        steps={steps.length}
                        position='static'
                        activeStep={simpleStep}
                        className={classNames(
                          classes.topShadow,
                          'pin-b pin-l pin-r absolute m-0 estimate-stepper',
                        )}
                        nextButton={
                          simpleStep < steps.length - 1 ? (
                            <Button
                              size='small'
                              onClick={this.handleNext}
                              disabled={simpleStep === steps.length}
                            >
                              Next
                              <Icon>keyboard_arrow_right</Icon>
                            </Button>
                          ) : !this.state.ID ? (
                            <DebounceButton
                              buttonText={'Finish'}
                              processingText={'Finishing'}
                              isDisabled={!this.canBeSubmitted()}
                              variant={'contained'}
                              color={'primary'}
                              clickFxn={() => {
                                return Promise.all([
                                  addEstimate({
                                    ...this.state,
                                    Data: { ...this.state.Data },
                                  }),
                                  // this.emailEstimate(),
                                ]).then(() => {
                                  this.closeComposeDialog()
                                })
                              }}
                              debounceInterval={1500}
                            />
                          ) : (
                            <DebounceButton
                              buttonText={'Update'}
                              processingText={'Updating'}
                              isDisabled={!this.canBeSubmitted()}
                              variant={'contained'}
                              color={'primary'}
                              clickFxn={() => {
                                return Promise.all([
                                  updateEstimate(this.state),
                                ]).then(() => {
                                  this.closeComposeDialog()
                                })
                              }}
                              debounceInterval={1500}
                            />
                          )
                        }
                        backButton={
                          <Button
                            size='small'
                            onClick={this.handleBack}
                            disabled={simpleStep === 0}
                          >
                            <Icon>keyboard_arrow_left</Icon>
                            Back
                          </Button>
                        }
                      />
                    </div>
                  </DialogActions>
                )}
              </DraggableDialog>
            </React.Fragment>
          )}
        </Media>
      )
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditEstimateDialog: Actions.closeEditEstimateDialog,
      closeNewEstimateDialog: Actions.closeNewEstimateDialog,
      addEstimate: Actions.addEstimate,
      updateEstimate: Actions.updateEstimate,
      removeEstimate: Actions.removeEstimate,
      updateEditEstimateDialog: Actions.updateEditEstimateDialog,
      openEditEstimateDialog: Actions.openEditEstimateDialog,
      openEditWorkOrderUsageDialog,
      sendEstimate: Actions.sendEstimate,
      updateTrip,
      openEditCustomerDialog,
      openEditCustomerSiteDialog,
      openNewCatalogItemDialog,
      openEditCatalogItemDialog,
      downloadCatalog,
      openMinimizedDialog,
      getScheduledTrips: Actions.getScheduledTrips,
      getScheduledWOs: Actions.getScheduledWOs,
      scheduleEstimate: Actions.scheduleEstimate,
      showMessage,
      openPreloadEstimateDialog,
    },
    dispatch,
  )
}

function mapStateToProps({
  estimatesApp,
  spReducers,
  emailTemplateApp,
  minimizedDialogs,
  minimizedDialogApp,
  customersApp,
}) {
  return {
    customers: customersApp.customers.entities,
    estimateDialog: estimatesApp.estimates.estimateDialog,
    companies: spReducers.companies.List,
    allCatalogs: spReducers.catalog.catalogs,
    catalogs: spReducers.catalog.savedCatalogs,
    Co: spReducers.companies.Co,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    rateTemplates: spReducers.rateTemplates,
    arrivalWindows: spReducers.arrivalWindows,
    departments: spReducers.departments,
    templates: emailTemplateApp.templates.Templates,
    formOfPayments: spReducers.formOfPayments,
    User: spReducers.userProfiles.User,
    Users: spReducers.userProfiles.Users,
    services: spReducers.services,
    serviceTypes: spReducers.serviceTypes,
    securables: spReducers.userProfiles.User.Data.Securables,
    users: spReducers.userProfiles.Users,
    technicians: spReducers.technicians,
    tech: spReducers.technician.technician,
    assemblies: spReducers.catalog.assemblies,
    optionTypes: spReducers.optionTypes,
    taxCodes: spReducers.taxCodes,
    optionTypes: spReducers.optionTypes,
    schedule: estimatesApp.estimates.schedule,
    materials: spReducers.materials.materials,
    laborTypes: spReducers.laborTypes,
    minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    estimates: estimatesApp.estimates,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(EstimateDialog),
)
