import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
    Card, CardHeader, TextField, Badge, Button, Dialog, DialogTitle, DialogActions, DialogContent, Icon, IconButton, ListItem, ListItemText, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse';
import { bindActionCreators } from 'redux';
import TechnicianTrip from '../technician/TechnicianTrip';
import { connect } from 'react-redux';
import moment from 'moment';
import * as Actions from './store/actions';
import _ from 'lodash';
import axios from 'axios/index';
import Media from 'react-media';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        padding: 12,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    formControl: {
        marginBottom: 12,
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
});

const initialState = {
    Co: null,
    WorkOrder: null,
    Trip: null,
    Technician: null,
    ScheduledDate: null,
    ScheduledTime: null,
    NotifiedDate: null,
    NotifiedTime: null,
    AcceptedDate: null,
    AcceptedTime: null,
    RejectedDate: null,
    RejectedTime: null,
    EnRouteDate: null,
    EnRouteTime: null,
    InProgressDate: null,
    InProgressTime: null,
    OnHoldDate: null,
    OnHoldTime: null,
    CompletedDate: null,
    CompletedTime: null,
    Status: 0,
    Duration: null,
    Notes: null,
    CreatedDate: new Date(),
    CreatedBy: null,
    UpdatedDate: null,
    UpdatedBy: null,
    Data: {
        Assignments: [],
        ErrMsg: null,
        Tags: [],
    },
    anytime: false,
    trips: [],
};

class TripHoldDialog extends React.Component {
    state = { ...initialState };

    componentDidMount() {
        const { initialState } = this.props;
        this.setState(_.cloneDeepWith(initialState));
    }

    componentDidUpdate = () => {

    }

    onConfirm = () => {
        const { trip } = this.props;
        const { ScheduledDate, ScheduledTime, Duration, Notes } = this.state;
        this.props.addTrip({
            ...trip,
            ID: null,
            Trip: null,
            Status: 1,
            ScheduledDate,
            ScheduledTime,
            NotifiedDate: null,
            NotifiedTime: null,
            AcceptedDate: null,
            AcceptedTime: null,
            RejectedDate: null,
            RejectedTime: null,
            EnRouteDate: null,
            EnRouteTime: null,
            ArrivedDate: null,
            ArrivedTime: null,
            InProgressDate: null,
            InProgressTime: null,
            OnHoldDate: null,
            OnHoldTime: null,
            CompletedDate: null,
            CompletedTime: null,
            Duration: (Duration || trip.Duration),
            SourceTrip: trip.Trip,
            Notes,
            Data: {
                Assignments: trip.Data.Assignments,
                ErrMsg: null
            }
        });
        this.props.onConfirm();
    }

    canBeSubmitted() {
        const { trip } = this.props;
        const { ScheduledDate, Duration } = this.state;
        return trip && trip.WorkOrder && trip.Technician && ScheduledDate && (Duration || trip.Duration);
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleAnytimeChange = (event) => {
        const { ScheduledDate } = this.state;
        if (event.target.checked) {
            this.setState({ ...this.state, ScheduledTime: null, anytime: true })
        } else {
            this.setState({ ...this.state, ScheduledTime: ScheduledDate, anytime: false })
        }
    }

    handleAdminTripDateChange = (event) => {
        let { anytime, trips } = this.state;
        window["warn"](event);
        const oldDate = this.state.ScheduledDate;
        let ScheduledDate = this.formatDate(event._d);
        let ScheduledTime = (anytime ? null : event._d.toLocaleString('en-US'));
        this.setState({ ...this.state, ScheduledDate, ScheduledTime, loading: (oldDate !== ScheduledDate), trips: (oldDate !== ScheduledDate) ? [] : trips }, () => { if (this.state.loading) { this.checkSchedule(); } });
    };

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    checkSchedule = () => {
        const { trip } = this.props;
        const { ScheduledDate } = this.state;
        const { Co, Technician, } = trip;
        const start = moment(ScheduledDate).format('M-D-YYYY');

        const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(Co)}&Date=${encodeURIComponent(start)}&Tech=${encodeURIComponent(Technician)}&View=D`);

        request.then((response) => {
            this.setState({ ...this.state, loading: false, trips: response.data });
        });
    }

    render() {
        const { classes, title, message, confirmText, cancelText, onCancel, open, trip, securables, technician } = this.props;
        const { trips, ScheduledDate, loading } = this.state

        return (
            <Media queries={{ small: "(max-width: 600px)", medium: "(min-width: 768px) and (min-height: 768px)" }}>
                {matches =>
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="sm"
                        onEntering={this.handleEntering}
                        aria-labelledby="confirmation-dialog-title"
                        open={open}
                        fullScreen={!matches.medium}
                        classes={{ paper: !matches.medium ? "full-screen-dialog" : "" }}
                    >
                        <DialogTitle classes={{ root: "p-16 pl-24 pr-24 dialog-header" }} id="confirmation-dialog-title">{title}</DialogTitle>
                        <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
                            {message}
                            <div className="flex">
                                {this.state.anytime ?
                                    <DatePicker variant="inline" inputVariant="outlined"
                                        className={classes.formControl}
                                        label="Scheduled Date"
                                        id="scheduledDate"
                                        format="MM/DD/YYYY"
                                        ref="datePicker"
                                        value={this.state.ScheduledDate}
                                        onChange={this.handleAdminTripDateChange}
                                        // variant="outlined"
                                        animateYearScrolling
                                        // disabled={(!accessLevel || accessLevel.AccessLevel === "R") && (!tripAccessLevel || tripAccessLevel !== "F")}
                                        fullWidth
                                    />
                                    :
                                    <DateTimePicker variant="inline" inputVariant="outlined"
                                        className={classes.formControl}
                                        label="Scheduled Date/Time"
                                        id="scheduledDate"
                                        format="MM/DD/YYYY h:mm A"
                                        ref="datePicker"
                                        value={this.state.ScheduledTime}
                                        onChange={this.handleAdminTripDateChange}
                                        // variant="outlined"
                                        animateYearScrolling
                                        // disabled={(!accessLevel || accessLevel.AccessLevel === "R") && (!tripAccessLevel || tripAccessLevel !== "F")}
                                        fullWidth
                                    />
                                }
                            </div>

                            {ScheduledDate &&
                                <Card className="mb-16" style={{ border: '1px solid lightgrey' }}>
                                    <CardHeader className={classNames("p-12 relative", "highlight")}
                                        classes={
                                            { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classes.subheader, }
                                        }
                                        avatar={
                                            <Icon color="primary" className="text-24 mt-4">today</Icon>
                                        }
                                        action={
                                            <div className="absolute w-96 pin-r mr-16 pin-t text-right mt-12">
                                                <Typography variant="caption" className="font-bold">{`${trips.length} Trips`}</Typography>
                                                <Typography variant="caption" className="text-10 text-grey-darker">{`${_.sumBy(trips, (t) => { return t.Duration }).toFixed(2)} Hours`}</Typography>
                                            </div>
                                        }
                                        title={
                                            <Typography className="flex text-12 font-bold">
                                                <span>{moment(ScheduledDate).format('dddd')}</span>
                                            </Typography>
                                        }
                                        subheader={moment(ScheduledDate).format('M/D/YYYY')}
                                    >
                                    </CardHeader>
                                    <div className="w-full p-12 pt-4 pb-8" style={{ borderTop: '1px solid lightgrey', backgroundColor: '#f8f8f8' }}>
                                        <FuseAnimateGroup
                                            enter={{
                                                animation: "transition.slideRightBigIn"
                                            }}
                                        >
                                            {(!trips || trips.length < 1) &&
                                                <div className="w-full p-24 text-center mt-8 font-bold text-12">
                                                    {loading ?
                                                        <div className="loader stretchBar mb-8 w-full m-auto pin-t pin-b pin-l pin-r">
                                                            <div className="rect1"></div>
                                                            <div className="rect2"></div>
                                                            <div className="rect3"></div>
                                                            <div className="rect4"></div>
                                                            <div className="rect5"></div>
                                                        </div> :
                                                        'No Assignments Found'
                                                    }
                                                </div>
                                            }
                                            {trips && trips.length > 0 && _.sortBy([...trips], (trip) => { return (trip.Status > 3 ? moment(moment(trip.BoardTime)).local() : moment(trip.BoardTime).local()) }).map((trip) => {
                                                window["warn"](trip);
                                                const { Data } = trip;
                                                if (Data) {
                                                    const { WorkOrder } = Data;
                                                    if (WorkOrder && WorkOrder.Data) {
                                                        const { Site } = WorkOrder.Data;
                                                        return (
                                                            <div className="w-full">
                                                                <TechnicianTrip readOnly={true} key={trip.ID} workOrder={{ ...trip.Data.WorkOrder, value: 2 }} technician={{ ...technician }} trip={trip} />
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })
                                            }
                                        </FuseAnimateGroup>
                                    </div>
                                </Card>
                            }

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Duration"
                                    id="duration"
                                    name="Duration"
                                    value={(this.state.Duration || trip.Duration)}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classNames(classes.formControl, "mb-0")}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>

                            <div className="flex w-full justify-end">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.anytime || (this.state.ID && !this.state.ScheduledTime)}
                                            name="anytime"
                                            onChange={this.handleAnytimeChange}
                                            color="primary"
                                        />
                                    }
                                    label="Anytime"
                                />
                            </div>
                        </DialogContent>
                        <DialogActions className="dialog-actions">
                            <Button onClick={onCancel}>
                                {cancelText || 'No Thanks'}
                            </Button>
                            <Button disabled={!this.canBeSubmitted()} onClick={this.onConfirm} color="primary">
                                {confirmText || 'Add'}
                            </Button>
                        </DialogActions>
                    </Dialog >
                }
            </Media>
        );
    }
}

TripHoldDialog.propTypes = {
    onClose: PropTypes.func,
    value: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addTrip: Actions.addTrip,
        updateTrip: Actions.updateTrip,
        removeTrip: Actions.removeTrip,
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, timecardsApp }) {
    return {
        user: dispatchBoardApp.user,
        laborTypes: spReducers.laborTypes,
        dialog: timecardsApp.timecards.timecardDialog,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TripHoldDialog));