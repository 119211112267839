import * as Actions from 'store/actions/utilities';

const initialState = {
    isScanning: false,
}
const utilities = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SCANNING:
            {
                const { isScanning } = action;
                return {
                    isScanning
                };
            }
    }

    return state;
};

export default utilities;