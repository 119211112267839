import CustomersApp from 'main/content/apps/supervisor/SupervisorApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const SupervisorAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/supervisor',
            component: CustomersApp
        },
    ]
};
