import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedCatalogIds: [],
    routeParams: {},
    assemblySearch: {
        page: 1,
        pages: null,
        loading: true,
        data: [],
        selectedAssemblies: [],
        category: null,
        variance: null,
        sorted: [],
    },
    catalogAssemblyComponentDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    catalogAssemblyDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    catalogOptionBoardDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        catalog: null,
    },
    catalogItemDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    catalogDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    categories: [],
    category: null,
    assemblies: [],
    assembly: null,
    optionBoardCategories: [],
    optionBoardCategory: null,
    optionBoards: [],
    allOptionBoards: [],
    optionBoard: null,
    step: null,
};

const catalogReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ASSEMBLIES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_ALL_OPTION_BOARDS:
            {
                return {
                    ...state,
                    allOptionBoards: action.payload
                };
            }
        case Actions.SET_ASSEMBLY_SEARCH_LOADING:
            {
                const { loading } = action;
                return {
                    ...state,
                    assemblySearch: {
                        ...state.assemblySearch,
                        loading
                    }
                }
            }
            break;
        case Actions.UPDATE_ASSEMBLY_PRICING:
            {
                const { data, } = action;
                return {
                    ...state,
                    assemblySearch: {
                        ...state.assemblySearch,
                        data,
                        updating: false
                    }
                }
            }
            break;
        case Actions.SET_ASSEMBLIES_UPDATING:
            {
                return {
                    ...state,
                    assemblySearch: {
                        ...state.assemblySearch,
                        updating: action.updating
                    }
                }

            }
            break;
        case Actions.SET_RATE_UPDATES_LIST:
            {
                const { data, pages, page, loading, category, variance, sorted } = action;
                return {
                    ...state,
                    assemblySearch: {
                        ...state.assemblySearch,
                        data,
                        pages,
                        page,
                        loading,
                        category,
                        variance,
                        sorted,
                        updating: false
                    }
                }
            }
            break;
        case Actions.SET_SELECTED_ASSEMBLIES:
            {
                const { selectedAssemblies } = action;
                return {
                    ...state,
                    assemblySearch: {
                        ...state.assemblySearch,
                        selectedAssemblies
                    }
                }
            }
            break;
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.SET_ASSEMBLY:
            {
                return {
                    ...state,
                    assembly: action.assembly,
                }
            }
        case Actions.OPEN_NEW_CATALOG_ITEM_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogItemDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.OPEN_EDIT_CATALOG_ITEM_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogItemDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.UPDATE_EDIT_CATALOG_ITEM_DIALOG:
            {
                const { data, payload } = action;
                return {
                    ...state,
                    catalogItemDialog: {
                        type: 'update',
                        props: {
                            open: true,
                        },
                        data: data || payload,
                    }
                }
            }
        case Actions.CLOSE_NEW_CATALOG_ITEM_DIALOG:
            {
                return {
                    ...state,
                    catalogItemDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.CLOSE_EDIT_CATALOG_ITEM_DIALOG:
            {
                return {
                    ...state,
                    catalogItemDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.OPEN_NEW_CATALOG_ASSEMBLY_COMPONENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogAssemblyComponentDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.OPEN_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogAssemblyComponentDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.UPDATE_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogAssemblyComponentDialog: {
                        type: 'update',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.CLOSE_NEW_CATALOG_ASSEMBLY_COMPONENT_DIALOG:
            {
                return {
                    ...state,
                    catalogAssemblyComponentDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.CLOSE_EDIT_CATALOG_ASSEMBLY_COMPONENT_DIALOG:
            {
                return {
                    ...state,
                    catalogAssemblyComponentDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.OPEN_NEW_CATALOG_ASSEMBLY_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogAssemblyDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.OPEN_EDIT_CATALOG_ASSEMBLY_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogAssemblyDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.UPDATE_EDIT_CATALOG_ASSEMBLY_DIALOG:
            {
                const { data, payload } = action;
                return {
                    ...state,
                    catalogAssemblyDialog: {
                        type: 'update',
                        props: {
                            open: true,
                        },
                        data: data || payload,
                    }
                }
            }
        case Actions.CLOSE_NEW_CATALOG_ASSEMBLY_DIALOG:
            {
                return {
                    ...state,
                    catalogAssemblyDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.CLOSE_EDIT_CATALOG_ASSEMBLY_DIALOG:
            {
                return {
                    ...state,
                    catalogAssemblyDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.OPEN_NEW_CATALOG_DIALOG:
            {
                return {
                    ...state,
                    catalogDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data: null,
                    },
                    category: null,
                }
            }
        case Actions.OPEN_EDIT_CATALOG_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data,
                    },
                    category: null,
                }
            }
        case Actions.UPDATE_EDIT_CATALOG_DIALOG:
            {
                const { payload, data } = action;
                return {
                    ...state,
                    catalogDialog: {
                        type: 'update',
                        props: {
                            open: state.catalogDialog.props.open,
                        },
                        data: data || payload,
                    }
                }
            }
        case Actions.CLOSE_NEW_CATALOG_DIALOG:
            {
                return {
                    ...state,
                    catalogDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    },
                    category: null,
                }
            }
        case Actions.CLOSE_EDIT_CATALOG_DIALOG:
            {
                return {
                    ...state,
                    catalogDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    },
                    category: null,
                }
            }
        case Actions.SET_ASSEMBLY_STEP:
            {
                return {
                    ...state,
                    step: action.step,
                }
            }
        case Actions.SET_ASSEMBLIES:
            {
                return {
                    ...state,
                    assemblies: action.assemblies,
                }
            }
        case Actions.SET_ASSEMBLY_CATEGORY:
            {
                return {
                    ...state,
                    category: action.category,
                }
            }
        case Actions.SET_ASSEMBLY_CATEGORIES:
            {
                return {
                    ...state,
                    categories: action.categories,
                }
            }
        // case Actions.GET_OPTION_BOARDS:
        //     {
        //         return {
        //             ...state,
        //             entities: _.keyBy(action.payload, 'id'),
        //             routeParams: action.routeParams
        //         };
        //     }
        // case Actions.SET_SEARCH_TEXT:
        //     {
        //         return {
        //             ...state,
        //             searchText: action.searchText
        //         };
        //     }
        case Actions.SET_OPTION_BOARD:
            {
                return {
                    ...state,
                    assembly: action.assembly,
                }
            }
        case Actions.OPEN_NEW_OPTION_BOARD_DIALOG:
            {
                const { data, catalog, estimate } = action;
                return {
                    ...state,
                    catalogOptionBoardDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data,
                        catalog,
                        estimate
                    }
                }
            }
        case Actions.OPEN_EDIT_OPTION_BOARD_DIALOG:
            {
                const { data, catalog, estimate } = action;
                return {
                    ...state,
                    catalogOptionBoardDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data,
                        catalog,
                        estimate
                    }
                }
            }
        case Actions.UPDATE_EDIT_OPTION_BOARD_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    catalogOptionBoardDialog: {
                        type: 'update',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.CLOSE_NEW_OPTION_BOARD_DIALOG:
            {
                return {
                    ...state,
                    catalogOptionBoardDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.CLOSE_EDIT_OPTION_BOARD_DIALOG:
            {
                return {
                    ...state,
                    catalogOptionBoardDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        // case Actions.SET_OPTION_BOARD_STEP:
        //     {
        //         return {
        //             ...state,
        //             step: action.step,
        //         }
        //     }
        case Actions.SET_OPTION_BOARDS:
            {
                return {
                    ...state,
                    assemblies: action.assemblies,
                }
            }
        case Actions.SET_OPTION_BOARD_CATEGORY:
            {
                return {
                    ...state,
                    category: action.category,
                }
            }
        case Actions.SET_OPTION_BOARD_CATEGORIES:
            {
                return {
                    ...state,
                    categories: action.categories,
                }
            }
        default:
            {
                return state;
            }
    }
};

export default catalogReducer;
