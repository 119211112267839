import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class TextGathersList extends Component {

    state = {
        selectedTextGathersMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTextGatherMenu = (event) => {
        this.setState({ selectedTextGathersMenu: event.currentTarget });
    };

    closeSelectedTextGathersMenu = () => {
        this.setState({ selectedTextGathersMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, securables, textGathers, user, searchText, openNewTextGatherDialog, selectedTextGatherIds, selectAllTextGathers, deSelectAllTextGathers, toggleInSelectedTextGathers, openEditTextGatherDialog, removeTextGathers, removeTextGather, toggleStarredTextGather, setTextGathersUnstarred, setTextGathersStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedTextGathersData ? (!this.props.textGatherData ? [] : this.props.textGatherData) : this.props.selectedTextGathersData), searchText);
        const { selectedTextGathersMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "text-gathers" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewTextGatherDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">textsms</Icon>Text Gathers</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTextGatherDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Gather",
                                accessor: "ID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            // {
                            //     Header: "Description",
                            //     accessor: "Description",
                            //     minWidth: 256,
                            // },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Gathers found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTextGathers: Actions.getTextGathers,
        getUserData: Actions.getUserData,
        toggleInSelectedTextGathers: Actions.toggleInSelectedTextGathers,
        selectAllTextGathers: Actions.selectAllTextGathers,
        deSelectAllTextGathers: Actions.deSelectAllTextGathers,
        openEditTextGatherDialog: Actions.openEditTextGatherDialog,
        removeTextGathers: Actions.removeTextGathers,
        removeTextGather: Actions.removeTextGather,
        toggleStarredTextGather: Actions.toggleStarredTextGather,
        toggleStarredTextGathers: Actions.toggleStarredTextGathers,
        setTextGathersStarred: Actions.setTextGathersStarred,
        setTextGathersUnstarred: Actions.setTextGathersUnstarred,
        openNewTextGatherDialog: Actions.openNewTextGatherDialog
    }, dispatch);
}

function mapStateToProps({ textGathersApp, spReducers }) {
    return {
        textGathers: textGathersApp.textGathers.entities,
        selectedTextGatherIds: textGathersApp.textGathers.selectedTextGatherIds,
        searchText: textGathersApp.textGathers.searchText,
        user: textGathersApp.user,
        textGatherData: spReducers.textGathers,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TextGathersList)));
