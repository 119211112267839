import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from './store/actions';
import CallHandlerHeader from 'main/content/apps/call-handler/CallHandlerHeader';
import CallHandlerLeftSidebarHeader from 'main/content/apps/call-handler/CallHandlerLeftSidebarHeader';
import CallHandlerLeftSidebarContent from 'main/content/apps/call-handler/CallHandlerLeftSidebarContent';
import CallHandlerRightSidebarHeader from 'main/content/apps/call-handler/CallHandlerRightSidebarHeader';
import CallHandlerRightSidebarContent from 'main/content/apps/call-handler/CallHandlerRightSidebarContent';
import _ from '@lodash';
import { Badge, Button, Fab, Icon, Tooltip } from '@material-ui/core';
import CallHandler from 'main/content/apps/call-handler/CallHandler';
import Draggable from 'react-draggable';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right: 16,
        bottom: 12,
        zIndex: 99,
        '&:hover': {
            zIndex: 998
        }
    },
    padButton: {
        position: 'absolute',
        right: 16,
        bottom: 68,
        zIndex: 99,
        '&:hover': {
            zIndex: 998
        }
    },
    badge: {
        top: -8,
        right: -8,
    }
});

class CallHandlerApp extends Component {

    state = {
        pos1: 0,
        pos2: 0,
        pos3: 0,
        pos4: 0,
        down: true,
    }
    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        //if (!_.isEqual(this.props.location, prevProps.location)) {
        //    this.props.getCustomers(this.props.match.params);
        //}
    }

    // mousedown = (e) => {
    //     let { pos1, pos2, pos3, pos4 } = this.state;
    //     e = e || window.event;
    //     e.preventDefault();
    //     if (this.fab) {
    //         // get the mouse cursor position at startup:
    //         pos3 = e.clientX;
    //         pos4 = e.clientY;
    //         this.setState({ ...this.state, pos1, pos2, pos3, pos4, down: true });
    //     }
    // }

    // mouseup = (e) => {
    //     let { down } = this.state;
    //     if (down) {
    //         this.setState({ ...this.state, down: false });
    //     }
    // }

    // mousemove = (e) => {
    //     let { pos1, pos2, pos3, pos4, down } = this.state;
    //     e = e || window.event;
    //     e.preventDefault();
    //     if (down && this.fab) {
    //         // calculate the new cursor position:
    //         pos1 = pos3 - e.clientX;
    //         pos2 = pos4 - e.clientY;
    //         pos3 = e.clientX;
    //         pos4 = e.clientY;
    //         // set the element's new position:
    //         this.fab.style.top = (this.page.offsetTop - pos2) + "px";
    //         this.fab.style.left = (this.page.offsetLeft - pos1) + "px";
    //         this.setState({ ...this.state, pos1, pos2, pos3, pos4 });
    //     }
    // }

    render() {
        const { classes, openNewCustomerDialog, openNewCustomerSiteDialog, history, workOrders, date } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            ref={(page) => { this.page = page; }}
                            // onMouseUp={this.mouseup}
                            // onMouseMove={this.mousemove}
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <CallHandlerHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <CallHandlerLeftSidebarHeader />
                            }
                            rightSidebarHeader={
                                <CallHandlerRightSidebarHeader />
                            }
                            leftSidebarContent={
                                <CallHandlerLeftSidebarContent />
                            }
                            content={
                                <CallHandler />
                            }
                            rightSidebarContent={
                                <CallHandlerRightSidebarContent pageLayout={() => this.pageLayout} />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {matches &&
                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                <Draggable disabled={matches}>
                                    <Tooltip title="View Dispatch Board" placement="top">
                                        <NavLink button to="/apps/dispatch-board" className={classNames(classes.addButton, !matches ? classes.padButton : "", !matches ? "fixed" : "")}>
                                            <Fab
                                                id="chButton"
                                                color="secondary"
                                                aria-label="add"
                                            >
                                                <Badge color="error" badgeContent={workOrders ? _.filter(workOrders, (o) => { return _.filter(o.Data.Scopes, (s) => { return s.Status !== 4 && new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') && s.Data.Assignments.length < 1 }).length > 0 }).length : 0} classes={{ badge: classes.badge }}>
                                                    <Icon>dvr</Icon>
                                                </Badge>
                                            </Fab>
                                        </NavLink>
                                    </Tooltip>
                                </Draggable>
                            </FuseAnimate>
                        }
                    </React.Fragment>
                }
            </Media>
        )
    };
}

CallHandlerApp.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ customersApp, spReducers, dispatchBoardApp }) {
    return {
        customers: customersApp.customers.entities,
        date: dispatchBoardApp.dispatchBoard.date,
        selectedCustomerIds: customersApp.customers.selectedCustomerIds,
        searchText: customersApp.customers.searchText,
        user: customersApp.user,
        workOrders: spReducers.workOrders,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallHandlerApp)));
