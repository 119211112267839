import moment from 'moment';
import _ from 'lodash';
import * as Actions from 'store/actions';
import * as Login from 'auth/store/actions';
import { persistor } from 'store';

const OfflineMonitor = store => next => action => {
    const state = store.getState();
    const { spReducers, auth, spAuth } = state;
    switch (action.type) {
        case Actions.SET_OFFLINE_STATUS:
            {
                const { offline } = action;
                const { status } = spReducers.offline;
                const loggedIn = auth.login.success;
                if (status.offline && !offline && loggedIn) {
                    // console.warn('Syncing offline data');
                    store.dispatch(Actions.syncOfflineData());
                }
            }
            break;
        case Login.PURGE_STATE:
            {
                persistor.pause();
                const { vault } = spAuth;
                Promise.all([
                    window.localStorage.setItem('SP_VLT', spAuth.vault.lockType)
                ]).then(() => {
                    persistor.flush().then(() => {
                        return persistor.purge().then(() => {
                            window.location.reload();
                        })
                    });
                });

            }
            break;
    }
    next(action);
}

export default OfflineMonitor;