import axios from 'axios';
import { getUserData } from 'main/content/apps/teams/store/actions/user.actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const GET_TEAMS = '[TEAMS APP] GET TEAMS';
export const SET_SEARCH_TEXT = '[TEAMS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TEAMS = '[TEAMS APP] TOGGLE IN SELECTED TEAMS';
export const SELECT_ALL_TEAMS = '[TEAMS APP] SELECT ALL TEAMS';
export const DESELECT_ALL_TEAMS = '[TEAMS APP] DESELECT ALL TEAMS';
export const OPEN_NEW_TEAM_DIALOG = '[TEAMS APP] OPEN NEW TEAM DIALOG';
export const CLOSE_NEW_TEAM_DIALOG = '[TEAMS APP] CLOSE NEW TEAM DIALOG';
export const OPEN_EDIT_TEAM_DIALOG = '[TEAMS APP] OPEN EDIT TEAM DIALOG';
export const ADD_EDIT_TEAM_DIALOG = '[TEAMS APP] ADD EDIT TEAM DIALOG';
export const UPDATE_EDIT_TEAM_DIALOG = '[TEAMS APP] UPDATE EDIT TEAM DIALOG';
export const DATA_EDIT_TEAM_DIALOG = '[TEAMS APP] DATA EDIT TEAM DIALOG';
export const CLOSE_EDIT_TEAM_DIALOG = '[TEAMS APP] CLOSE EDIT TEAM DIALOG';
export const ADD_TEAM = '[TEAMS APP] ADD TEAM';
export const UPDATE_TEAM = '[TEAMS APP] UPDATE TEAM';
export const REMOVE_TEAM = '[TEAMS APP] REMOVE TEAM';
export const REMOVE_TEAMS = '[TEAMS APP] REMOVE TEAMS';
export const TOGGLE_STARRED_TEAM = '[TEAMS APP] TOGGLE STARRED TEAM';
export const TOGGLE_STARRED_TEAMS = '[TEAMS APP] TOGGLE STARRED TEAMS';
export const SET_TEAMS_STARRED = '[TEAMS APP] SET TEAMS STARRED ';
export const SET_TEAM_SCHEDULE = '[TEAMS APP] SET TEAM SCHEDULE';
export const SET_TEAM_TIMECARD = '[TEAMS APP] SET TEAM TIMECARD';

export function getTeams(routeParams) {
    const request = axios.get('/api/teams-app/teams', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TEAMS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTeams(teamId) {
    return {
        type: TOGGLE_IN_SELECTED_TEAMS,
        teamId
    }
}


export function selectAllTeams() {
    return {
        type: SELECT_ALL_TEAMS
    }
}

export function deSelectAllTeams() {
    return {
        type: DESELECT_ALL_TEAMS
    }
}


export function openNewTeamDialog(data) {
    return {
        type: OPEN_NEW_TEAM_DIALOG,
        data
    }
}

export function closeNewTeamDialog() {
    return {
        type: CLOSE_NEW_TEAM_DIALOG
    }
}

export function openPreloadTeamDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_TEAM_DIALOG,
            data: data,
        })
    }
}

export function openEditTeamDialog(data) {
    return (dispatch, getState) => {

        const state = getState();
        window["warn"](state);

        const { Co, Team } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Team?Co=${encodeURIComponent(Co)}&Team=${encodeURIComponent(Team)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_TEAM_DIALOG,
                    data: response.data
                }),
                // dispatch(getTeamSchedule(response.data, state.dispatchBoardApp.dispatchBoard.date))
            ])
        );
    };
}


export function getTeamSchedule(data, date) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_TEAM_SCHEDULE,
            date,
            loading: true,
            data: {
                ...data,
                Data: {
                    ...data.Data,
                    Trips: []
                },
            }
        });
        const { Co, Team } = data;

        const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(Co)}&Date=${encodeURIComponent(date.toLocaleDateString('en-US'))}&Tech=${encodeURIComponent(Team)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEAM_SCHEDULE,
                    date,
                    loading: false,
                    data: {
                        ...data,
                        Data: {
                            ...data.Data,
                            Trips: response.data
                        },
                    }
                }),
            ])
        );
    };
}

export function updateEditTeamDialog(data) {
    return (dispatch, getState) => {

        const { Co, Team } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Team?Co=${encodeURIComponent(Co)}&Team=${encodeURIComponent(Team)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DATA_EDIT_TEAM_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditTeamDialog() {
    return {
        type: CLOSE_EDIT_TEAM_DIALOG
    }
}

export function addTeam(newTeam) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Team`,
            newTeam
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_EDIT_TEAM_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team #${response.data.Team} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditTeamDialog(response.data))
            })
        );
    };
}

export function updateTeam(team) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/Team?Co=${encodeURIComponent(team.Co)}&Team=${encodeURIComponent(team.Team)}`,
            team
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_TEAM_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team #${response.data.Team} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditTeamDialog(team))
            })
        );
    };
}

export function addTeamMember(newTeamMember) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.teamsApp.teams.teamDialog;


        const request = axios.post(`${window["apiLocation"]}/api/TeamMember`,
            newTeamMember
        );

        return request.then((response) =>
            Promise.all([
                dispatch(updateEditTeamDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Members: [...data.Data.Members, response.data],
                    }
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team Member has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateTeamMember(member) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.teamsApp.teams.teamDialog;


        const request = axios.put(`${window["apiLocation"]}/api/TeamMember?Co=${encodeURIComponent(member.Co)}&Team=${encodeURIComponent(member.Team)}&Member=${encodeURIComponent(member.TeamMember)}&ID=${member.ID}`,
            member
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Members, { ID: response.data.ID });
            data.Data.Members.splice(index, 1, response.data);
            Promise.all([
                dispatch(updateEditTeamDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Members: [...data.Data.Members],
                    }
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team Member has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeTeamMember(member) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.teamsApp.teams.teamDialog;


        const request = axios.delete(`${window["apiLocation"]}/api/TeamMember?Co=${encodeURIComponent(member.Co)}&Team=${encodeURIComponent(member.Team)}&Member=${encodeURIComponent(member.TeamMember)}&ID=${member.ID}`,
            member
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Members, { ID: response.data.ID });
            data.Data.Members.splice(index, 1);
            Promise.all([
                dispatch(updateEditTeamDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Members: [...data.Data.Members],
                    }
                }))
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team Member has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function updateTeamLead(lead) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.teamsApp.teams.teamDialog;


        const request = axios.put(`${window["apiLocation"]}/api/TeamLead?Co=${encodeURIComponent(lead.Co)}&Team=${encodeURIComponent(lead.Team)}&Lead=${encodeURIComponent(lead.TeamLead)}&ID=${lead.ID}`,
            lead
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Leads, { ID: response.data.ID });
            data.Data.Leads.splice(index, 1, response.data);
            Promise.all([
                dispatch(updateEditTeamDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Leads: [...data.Data.Leads],
                    }
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team Lead has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeTeamLead(lead) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.teamsApp.teams.teamDialog;


        const request = axios.delete(`${window["apiLocation"]}/api/TeamLead?Co=${encodeURIComponent(lead.Co)}&Team=${encodeURIComponent(lead.Team)}&Lead=${encodeURIComponent(lead.TeamLead)}&ID=${lead.ID}`,
            lead
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Leads, { ID: response.data.ID });
            data.Data.Leads.splice(index, 1);
            Promise.all([
                dispatch(updateEditTeamDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Leads: [...data.Data.Leads],
                    }
                }))
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Team Lead has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeTeam(teamId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().teamsApp.teams;

        const request = axios.post('/api/teams-app/remove-team', {
            teamId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TEAM
                })
            ]).then(() => dispatch(getTeams(routeParams)))
        );
    };
}


export function removeTeams(teamIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().teamsApp.teams;

        const request = axios.post('/api/teams-app/remove-teams', {
            teamIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TEAMS
                }),
                dispatch({
                    type: DESELECT_ALL_TEAMS
                })
            ]).then(() => dispatch(getTeams(routeParams)))
        );
    };
}

export function toggleStarredTeam(teamId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().teamsApp.teams;

        const request = axios.post('/api/teams-app/toggle-starred-team', {
            teamId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TEAM
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTeams(routeParams)))
        );
    };
}

export function toggleStarredTeams(teamIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().teamsApp.teams;

        const request = axios.post('/api/teams-app/toggle-starred-teams', {
            teamIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TEAMS
                }),
                dispatch({
                    type: DESELECT_ALL_TEAMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTeams(routeParams)))
        );
    };
}

export function setTeamsStarred(teamIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().teamsApp.teams;

        const request = axios.post('/api/teams-app/set-teams-starred', {
            teamIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEAMS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TEAMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTeams(routeParams)))
        );
    };
}

export function setTeamsUnstarred(teamIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().teamsApp.teams;

        const request = axios.post('/api/teams-app/set-teams-unstarred', {
            teamIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEAMS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TEAMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTeams(routeParams)))
        );
    };
}
