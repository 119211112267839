import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../store/actions';
import { withStyles, Typography, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { FuseAnimateGroup, FuseAnimate } from '@fuse';
import RecycleBinDrawer from '../drawers/RecycleBinDrawer';
import _ from '@lodash';

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.contrastBg,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingBottom: 80,
        overflow: 'auto',
    },
    board: {
        cursor: 'pointer',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        background: '#fff',
        color: '#333',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    newBoard: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    }
});


class Boards extends Component {
    state = {
        Co: null,
        User: null,
        recycleBinOpen: false,
        loading: false
    }
    componentDidMount() {
        //this.props.getBoards(this.props.Co);
        this.props.resetBoard();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading && !_.isEqual(this.props.boards, prevProps.boards)) {
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        //this.props.resetBoards();
    }

    newBoard = () => {
        const { Co, User } = this.props;
        const board = {
            Co: Co,
            Name: 'New Board',
            AddedBy: User,
            AddedDate: new Date(),
            Status: 0,
            Notes: null,
            Data: {
                ErrMsg: null,
            },
            RestrictedYN: 'Y',
        }
        this.props.newBoard(board);
    }

    refreshBoards = () => {
        const { Co, resetBoards, getBoards } = this.props;
        Promise.all([
            resetBoards()
        ]).then(() => {
            this.setState({ loading: true }, () => setTimeout(() => getBoards(Co), 1000));
        });
    }

    render() {
        const { classes, boards, Co, User } = this.props;

        return (
            <div className={classNames(classes.root, "flex flex-grow flex-no-shrink flex-col items-center")}>

                <div className="w-full flex justify-end p-8">
                    <Tooltip placement="left" title="Recycle Bin">
                        <IconButton className="float-right" color="inherit" onClick={(e) => this.setState({ recycleBinOpen: true })}>
                            <Icon>restore_from_trash</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip placement="left" title="Refresh List">
                        <IconButton className={classNames("float-right", this.state.loading ? "spin" : "")} color="inherit" onClick={this.refreshBoards}>
                            <Icon>refresh</Icon>
                        </IconButton>
                    </Tooltip>
                </div>

                {/* Recycle Bin Drawer */}
                <RecycleBinDrawer
                    type="boards"
                    drawerOpen={this.state.recycleBinOpen}
                    closeDrawer={() => this.setState({ recycleBinOpen: false })}
                />

                <FuseAnimate>
                    <Typography className="mt-44 sm:mt-88 sm:py-24 text-24 sm:text-40 font-400" color="inherit">Project Tracking</Typography>
                </FuseAnimate>

                <div>
                    <FuseAnimateGroup
                        className="flex flex-wrap w-full justify-center py-32 px-16"
                        enter={{
                            animation: "transition.slideUpBigIn",
                            duration: 300
                        }}
                    >
                        {boards.map(board => (
                            <div className="w-224 h-224 p-16" key={board.ID}>
                                <Link
                                    to={'/apps/project-tracking/boards/' + Co + '/' + board.ID}
                                    className={classNames(classes.board, "flex flex-col items-center justify-center w-full h-full rounded-lg py-24")}
                                    role="button"
                                >
                                    <Icon className="text-56">assessment</Icon>
                                    <Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">{board.Name}</Typography>
                                </Link>
                            </div>
                        ))}
                        <div className="w-224 h-224 p-16">
                            <div
                                className={classNames(classes.board, classes.newBoard, "flex flex-col items-center justify-center w-full h-full rounded-lg py-24")}
                                onClick={this.newBoard}
                            >
                                <Icon className="text-56">add_circle</Icon>
                                <Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">Add Project</Typography>
                            </div>
                        </div>
                    </FuseAnimateGroup>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBoards: Actions.getBoards,
        resetBoards: Actions.resetBoards,
        newBoard: Actions.newBoard,
        resetBoard: Actions.resetBoard,
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        boards: scrumboardApp.boards.Boards,
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Boards)));
