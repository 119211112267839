import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import axios from 'axios'
import classNames from 'classnames'
import { impress } from 'main/content/compression/impress'
import AboutTab from 'main/content/pages/profile/tabs/AboutTab'
import UserRolesList from '../user-roles/UserRolesList'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import * as WorkOrderActions from '../work-orders/store/actions'
import {
  addUserRoleAssignment,
  removeUserRoleAssignment,
} from '../user-roles/store/actions'
import * as Actions from './store/actions'
import PhoneInput from '../../components/inputs/PhoneInput'

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  header: {},
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 24,
  },
  deleteAssignmentRecord: {
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})

const newUserState = {
  ID: null,
  // avatar: 'assets/images/avatars/profile.jpg',
  UserName: null,
  userRoleExists: false,
  Password: null,
  FirstName: null,
  LastName: null,
  DOB: null,
  Gender: null,
  Title: null,
  Phone: null,
  Email: null,
  Bio: null,
  Location: null,
  Notes: null,
  EnteredBy: null,
  EnteredDate: null,
  AttachmentID: null,
  ActiveYN: 'Y',
  Data: {
    Companies: [
      {
        Co: null,
        Username: null,
        PrimaryRole: null,
        ActiveYN: 'Y',
      },
    ],
    Roles: [],
  },
  value: 0,
  Role: null,
  assignment: {
    ID: null,
    Role: null,
  },
  passFocus: false,
}

// function FormatDollars(props) {
//     const { inputRef, onChange, name, type, ...other } = props;
//     return (
//         <NumberFormat
//             {...other}
//             getInputRef={inputRef}
//             onValueChange={values => {
//                 onChange({
//                     target: {
//                         name: name,
//                         type: type,
//                         value: values.value,
//                     },
//                 });
//             }}
//             type={type}
//             thousandSeparator
//             decimalScale={2}
//             fixedDecimalScale
//             prefix="$"
//         />
//     );
// }

// FormatDollars.propTypes = {
//     inputRef: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
// };

// function FormatPhone(props) {
//     const { inputRef, ...other } = props;

//     return (
//         <MaskedInput
//             {...other}
//             ref={ref => {
//                 inputRef(ref ? ref.inputElement : null);
//             }}
//             mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//             placeholderChar={'\u2000'}
//             showMask
//         />
//     );
// }

// FormatPhone.propTypes = {
//     inputRef: PropTypes.func.isRequired,
// };

// const UserMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

const strong = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
)
const hasUpper = new RegExp('(?=.*[A-Z])')
const hasLower = new RegExp('(?=.*[a-z])')
const hasNumber = new RegExp('(?=.*[0-9])')
const hasSpecial = new RegExp('(?=.*[^A-Za-z0-9])')

class UsersDialog extends Component {
  state = { ..._.cloneDeepWith(newUserState) }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    if (!prevProps.userDialog.props.open && this.props.userDialog.props.open) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.userDialog.type === 'edit' ||
        (this.props.userDialog.type === 'add' &&
          this.props.userDialog.data &&
          !_.isEqual(this.props.userDialog.data, prevState))
      ) {
        this.props.userDialog.data.showPassword = false
        this.setState({
          ..._.cloneDeepWith(newUserState),
          ...this.props.userDialog.data,
        })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.userDialog.type === 'new' &&
        !_.isEqual(newUserState, prevState)
      ) {
        this.setState({ ..._.cloneDeepWith(newUserState) })
      }
    } else {
      if (
        this.props.userDialog.type === 'update' ||
        (this.props.userDialog.type === 'add' &&
          this.props.userDialog.data &&
          !_.isEqual(this.props.userDialog.data, prevState))
      ) {
        this.props.userDialog.type = 'edit'
        this.props.userDialog.data.showPassword = false
        this.setState({
          ..._.cloneDeepWith(newUserState),
          ...this.props.userDialog.data,
        })
      }
    }
  }

  validatePassword() {
    const { Password } = this.state
    const validation = {
      length: Password && Password.length <= 15 && Password.length >= 8,
      tooLong: Password && Password.length > 15,
      upper: Password && hasUpper.test(Password),
      lower: Password && hasLower.test(Password),
      number: Password && hasNumber.test(Password),
      special: Password && hasSpecial.test(Password),
      isValid: !(!Password || !strong.test(Password) || Password.length > 15),
    }

    return validation
  }

  canBeSubmitted() {
    const { userDialog } = this.props
    const { UserName, FirstName, LastName, Data, Email } = this.state
    const { Companies } = Data
    const co = Companies[0]
    const isPWValid = this.validatePassword()
    return (
      (userDialog.type !== 'new' || isPWValid.isValid) &&
      UserName &&
      FirstName &&
      LastName &&
      Email &&
      Email.length >= 6 &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email) &&
      UserName.length >= 4 &&
      FirstName.length > 0 &&
      LastName.length > 0 &&
      co &&
      co.Co &&
      co.PrimaryRole
    )
  }

  closeComposeDialog = () => {
    this.props.userDialog.type === 'edit'
      ? this.props.closeEditUserDialog()
      : this.props.closeNewUserDialog()
  }

  fileSelectedHandler = event => {
    const { Data } = this.state
    impress(event.target.files[0], 1024, 50, true, img => {
      // window["log"](img);
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      axios
        .post(
          `${
            window['apiLocation']
          }/api/Attachment/PostProfileImage?Co=${encodeURIComponent(
            200,
          )}&Type=User&ID=${encodeURIComponent(
            this.state.UserName,
          )}&User=${encodeURIComponent(this.props.user)}`,
          fd,
        )
        .then(() => {
          if (Data) {
            Data.Avatar = img.base64
          } else {
            Data = { Avatar: img.base64 }
          }
          this.setState({ Data })
        })
    })
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  setAssignment = assignment => {
    this.setState({ ...this.state, assignment })
  }

  formatDate(date) {
    const dt = new Date(date)
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return date ? formatted : ''
  }

  formatPhone(phone) {
    return phone.length == 10
      ? `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`
      : phone
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleDOBChange = event => {
    let { general } = this.state
    let { DOB } = general
    DOB = event._d.toLocaleDateString('en-US')
    // window["warn"](event, DOB);
    this.setState({ ...this.state, general: { ...general, DOB } })
  }

  handleUserDOBChange = event => {
    let { DOB } = this.state
    DOB = event._d.toLocaleDateString('en-US')
    // window["warn"](event, DOB);
    this.setState({ ...this.state, DOB })
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  render() {
    const {
      classes,
      userDialog,
      addUser,
      updateUser,
      companies,
      settings,
      userRoles,
      Co,
    } = this.props
    const { value, passFocus } = this.state
    const general = this.state
    const userAvatar =
      general.Gender !== 'F'
        ? '../../assets/images/avatars/profile.jpg'
        : '../../assets/images/avatars/profile_f.jpg'
    const isPWValid = this.validatePassword()
    // window["log"]('Password Validation: ', isPWValid.special);
    // console.log(isPWValid.special)
    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <Dialog
            classes={{
              root: classes.root,
              paper: matches ? classes.paper : 'full-screen-dialog',
            }}
            className={classes.root}
            {...userDialog.props}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth={'md'}
            fullScreen={!matches}
          >
            <AppBar position='static' elevation={1} className='dialog-header'>
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  {['new'].indexOf(userDialog.type) > -1
                    ? 'New User'
                    : this.state.UserName}
                </Typography>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches ? 10 : 4,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon'
              >
                <Icon>close</Icon>
              </IconButton>
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                // variant="scrollable"
                // scrollButtons="on"
              >
                <Tab
                  disableRipple
                  icon={<Icon>account_circle</Icon>}
                  label='Info'
                />
                <Tab
                  disableRipple
                  disabled={userDialog.type === 'new'}
                  icon={<Icon>contacts</Icon>}
                  label='Roles'
                />
              </Tabs>
            </AppBar>

            {value === 0 && (
              <TabContainer>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>account_circle</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Username'
                    id='userName'
                    name='UserName'
                    value={this.state.UserName || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <div className='w-full relative'>
                    <TextField
                      className={classes.formControl}
                      label='Password'
                      id='password'
                      name='Password'
                      value={this.state.Password || ''}
                      onChange={this.handleChange}
                      onFocus={() => this.setState({ passFocus: true })}
                      onBlur={() => this.setState({ passFocus: false })}
                      type={this.state.showPassword ? 'text' : 'password'}
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='Toggle Sensitive Info visibility'
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <Icon>visibility_off</Icon>
                              ) : (
                                <Icon>visibility</Icon>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant='outlined'
                      required
                      fullWidth
                      disabled={userDialog.type !== 'new'}
                    />
                    {userDialog.type === 'new' && passFocus && (
                      <div
                        className='w-full px-8 rounded rounded-b-lg absolute'
                        style={{
                          top: 56,
                          backgroundColor: '#f9f9f9',
                          zIndex: 999,
                          border: '1px solid lightgrey',
                          boxShadow: '2px 2px 4px 0px rgba(0,0,0,.25)',
                        }}
                      >
                        <Typography
                          variant='caption'
                          className='w-full mt-8 mb-8 text-11'
                          style={{
                            color: isPWValid.isValid ? 'green' : 'black',
                          }}
                        >
                          {isPWValid.isValid
                            ? 'User password meets all requirements!'
                            : 'User password must satisfy each of the following criteria:'}
                        </Typography>
                        <ul className='mb-8 text-11'>
                          <li
                            style={{
                              color: isPWValid.length ? 'green' : 'black',
                            }}
                          >
                            {isPWValid.tooLong
                              ? 'Password is longer than 15 characters!'
                              : 'Contains 8 - 15 characters'}
                            {isPWValid.tooLong ||
                              (isPWValid.length && (
                                <Icon
                                  style={{
                                    color: 'green',
                                    fontSize: '18px',
                                    position: 'absolute',
                                  }}
                                >
                                  check
                                </Icon>
                              ))}
                          </li>
                          <li
                            style={{
                              color: isPWValid.upper ? 'green' : 'black',
                            }}
                          >
                            Contains at least 1 Uppercase Letter{' '}
                            {isPWValid.upper && (
                              <Icon
                                style={{
                                  color: 'green',
                                  fontSize: '18px',
                                  position: 'absolute',
                                }}
                              >
                                check
                              </Icon>
                            )}
                          </li>
                          <li
                            style={{
                              color: isPWValid.lower ? 'green' : 'black',
                            }}
                          >
                            Contains at least 1 Lowercase Letter{' '}
                            {isPWValid.lower && (
                              <Icon
                                style={{
                                  color: 'green',
                                  fontSize: '18px',
                                  position: 'absolute',
                                }}
                              >
                                check
                              </Icon>
                            )}
                          </li>
                          <li
                            style={{
                              color: isPWValid.number ? 'green' : 'black',
                            }}
                          >
                            Contains at least 1 Number{' '}
                            {isPWValid.number && (
                              <Icon
                                style={{
                                  color: 'green',
                                  fontSize: '18px',
                                  position: 'absolute',
                                }}
                              >
                                check
                              </Icon>
                            )}
                          </li>
                          <li
                            style={{
                              color: isPWValid.special ? 'green' : 'black',
                            }}
                          >
                            Contains at least 1 special Character{' '}
                            {isPWValid.special && (
                              <Icon
                                style={{
                                  color: 'green',
                                  fontSize: '18px',
                                  position: 'absolute',
                                }}
                              >
                                check
                              </Icon>
                            )}
                          </li>
                        </ul>
                        {/* )} */}
                      </div>
                    )}
                  </div>
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='First Name'
                    id='firstName'
                    name='FirstName'
                    value={this.state.FirstName || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Last Name'
                    id='lastName'
                    name='LastName'
                    value={this.state.LastName || ''}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Gender'
                    id='gender'
                    name='Gender'
                    value={this.state.Gender || ''}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: this.state.Gender ? true : false,
                    }}
                    variant='outlined'
                    fullWidth
                    select
                  >
                    <MenuItem value={null}>Unsepecified</MenuItem>
                    <MenuItem value='M'>Male</MenuItem>
                    <MenuItem value='F'>Female</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Title'
                    id='title'
                    name='Title'
                    value={this.state.Title || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>
                {userDialog.type === 'new' && (
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Default Company'
                      options={companies.List}
                      menuItemComponent={(value, index) => {
                        return (
                          <MenuItem key={index} value={value.Data.Co.Co}>
                            {`${value.Data.Co.Co} - ${value.Data.Co.Name}`}
                          </MenuItem>
                        )
                      }}
                      value={this.state.Data.Companies[0].Co || ''}
                      noflip={true}
                      onSelect={option => {
                        const { Companies } = this.state.Data
                        this.setState({
                          ...this.state,
                          Data: {
                            ...this.state.Data,
                            Companies: [
                              {
                                Co: option.Co,
                                Username: this.state.UserName,
                                PrimaryRole: Companies[0].PrimaryRole,
                                ActiveYN: 'Y',
                                SelectedTheme: settings.theme.main,
                              },
                            ],
                          },
                        })
                      }}
                      required
                      portal={true}
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Primary Role'
                      options={userRoles}
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Role}>
                            {`${value.Role} - ${value.Name}`}
                          </MenuItem>
                        )
                      }}
                      value={this.state.Data.Companies[0].PrimaryRole || ''}
                      noflip={true}
                      onSelect={option => {
                        const { Companies } = this.state.Data
                        this.setState({
                          ...this.state,
                          Data: {
                            ...this.state.Data,
                            Companies: [
                              {
                                ...Companies[0],
                                PrimaryRole: option.Role,
                              },
                            ],
                          },
                        })
                      }}
                      required
                      portal={true}
                    />
                  </div>
                )}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>message</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Bio'
                    id='bio'
                    name='Bio'
                    value={this.state.Bio || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                {/* <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">location_on</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Location"
                                    id="location"
                                    name="Location"
                                    value={this.state.Location}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                            </div> */}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>phone</Icon>
                  </div>
                  {/* <TextField
                                        className={classes.formControl}
                                        label="Phone"
                                        id="phone"
                                        name="Phone"
                                        value={this.state.Phone || ''} 
                                        onChange={this.handlePhoneChange} 
                                        variant="outlined"
                                        required
                                        fullWidth
                                    /> */}
                  <PhoneInput
                    {...this.props}
                    className={classes.formControl}
                    label='Phone'
                    id='phone'
                    name='Phone'
                    value={this.state.Phone || ''}
                    onChange={value => {
                      this.setState({ ...this.state, Phone: value })
                    }}
                    variant='outlined'
                    required={true}
                    fullWidth={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Email'
                    id='email'
                    name='Email'
                    value={this.state.Email || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>
                <div className='flex'>
                  <div className='w-1/2'>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                >
                  <Icon color='primary' className='text-32 mr-12'>
                    group
                  </Icon>
                  {`${!this.state.assignment.ID ? 'Add' : 'Update'} Access`}
                </Typography>
                <div className='sm:flex'>
                  <Autocomplete
                    className={classes.formControl}
                    title='User Role'
                    options={userRoles}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.Role}>
                          <div className='flex'>
                            {`${value.Role} - ${value.Name}`}
                          </div>
                        </MenuItem>
                      )
                    }}
                    noflip={true}
                    portal={!matches}
                    value={this.state.assignment.Role}
                    onSelect={option => {
                      const roleExists = !!_.find(this.state.Data.Roles, {
                        Role: option.Role,
                      })
                      this.setState({
                        ...this.state,
                        assignment: {
                          ...this.state.assignment,
                          Role: option.Role,
                        },
                        userRoleExists: roleExists ? true : false,
                      })
                    }}
                    required
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  {!this.state.assignment.ID ? (
                    <Button
                      className={classNames(
                        classes.formControl,
                        'w-full sm:w-1/2',
                      )}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        window['warn'](
                          this.state,
                          this.state.Data.Roles,
                          this.state.assignment.Role,
                        )
                        const { UserName, assignment } = this.state
                        const assn = {
                          ...assignment,
                          Co,
                          UserName,
                          Data: { ErrMsg: null },
                        }
                        this.props.addUserRoleAssignment(assn)
                        const Roles = _.cloneDeepWith(this.state.Data.Roles)
                        Roles.push({ ...assn, ID: `Role${assn.Role}` })
                        this.setState({
                          ...this.state,
                          assignment: newUserState.assignment,
                          Data: { ...this.state.Data, Roles },
                        })
                        window['warn'](this.state.assignment)
                      }}
                      disabled={
                        !this.state.assignment.Role || this.state.userRoleExists
                      }
                    >
                      {this.state.userRoleExists
                        ? 'User already has role!'
                        : 'Add'}
                    </Button>
                  ) : (
                    <Button
                      className={classNames(
                        classes.formControl,
                        classes.deleteAssignmentRecord,
                      )}
                      variant='contained'
                      onClick={() => {
                        this.props.removeUserRoleAssignment({
                          ...this.state.assignment,
                          Data: { ErrMsg: null },
                        })
                        const Roles = _.cloneDeepWith(this.state.Data.Roles)
                        const index = _.findIndex(Roles, {
                          ID: this.state.assignment.ID,
                        })
                        if (index > -1) {
                          Roles.splice(index, 1)
                        }
                        this.setState({
                          ...this.state,
                          assignment: newUserState.assignment,
                          Data: { ...this.state.Data, Roles },
                        })
                      }}
                    >
                      <Icon>delete</Icon>
                    </Button>
                  )}
                </div>
                <UserRolesList
                  onSelected={this.setAssignment}
                  selectedRoles={this.state.Data.Roles || []}
                />
              </TabContainer>
            )}
            {userDialog.type === 'new' ? (
              <DialogActions className='dialog-actions justify-between pl-16'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    const { Co } = this.props
                    var rec = this.state
                    addUser({ ...rec })
                    this.closeComposeDialog()
                  }}
                  disabled={!this.canBeSubmitted()}
                >
                  Add
                </Button>
              </DialogActions>
            ) : (
              <DialogActions className='dialog-actions justify-between pl-16'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    var rec = this.state
                    updateUser(rec)
                    this.closeComposeDialog()
                  }}
                  disabled={!this.canBeSubmitted()}
                >
                  Save
                </Button>
                <IconButton
                  onClick={() => {
                    // removeEmployee(this.state.id);
                    this.closeComposeDialog()
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}
UsersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditUserDialog: Actions.closeEditUserDialog,
      closeNewUserDialog: Actions.closeNewUserDialog,
      addUser: Actions.addUser,
      updateUser: Actions.updateUser,
      removeUser: Actions.removeUser,
      openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
      addUserRoleAssignment,
      removeUserRoleAssignment,
      showMessage: showMessage,
      impress: impress,
    },
    dispatch,
  )
}

function mapStateToProps({ usersApp, spReducers, fuse }) {
  return {
    companies: spReducers.companies,
    Co: spReducers.companies.Co,
    userDialog: usersApp.users.userDialog,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    user: spReducers.userProfiles.User.UserName,
    Co: spReducers.companies.Co,
    settings: fuse.settings.current,
    userRoles: spReducers.userRoles,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(UsersDialog),
)
