import {combineReducers} from 'redux';
import uoms from './units-of-measure.reducer';
import user from './user.reducer';

const uomsAppReducers = combineReducers({
    uoms,
    user
});

export default uomsAppReducers;
