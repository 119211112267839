import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import InventoryLocationList from 'main/content/apps/inventory-locations/InventoryLocationsList';
import InventoryLocationHeader from 'main/content/apps/inventory-locations/InventoryLocationsHeader';
import InventoryLocationSidebarContent from 'main/content/apps/inventory-locations/InventoryLocationsSidebarContent';
import InventoryLocationsLeftSidebarHeader from 'main/content/apps/inventory-locations/InventoryLocationsLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import InventoryLocationDialog from 'main/content/apps/inventory-locations/InventoryLocationDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class InventoryLocationsApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getInventoryLocations(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewInventoryLocationDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <InventoryLocationHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <InventoryLocationList />
                            }
                            leftSidebarHeader={
                                <InventoryLocationsLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <InventoryLocationSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {/* <InventoryLocationDialog /> */}
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getInventoryLocations: Actions.getInventoryLocations,
        getUserData: Actions.getUserData,
        openNewInventoryLocationDialog: Actions.openNewInventoryLocationDialog
    }, dispatch);
}

function mapStateToProps({ inventoryLocationsApp }) {
    return {
        inventoryLocations: inventoryLocationsApp.inventoryLocations.entities,
        selectedInventoryLocationIds: inventoryLocationsApp.inventoryLocations.selectedInventoryLocationIds,
        searchText: inventoryLocationsApp.inventoryLocations.searchText,
        user: inventoryLocationsApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryLocationsApp)));
