import React, { useState, useEffect, Component } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import _ from '@lodash';

export default function DebounceButton({ buttonText, processingText, isDisabled, variant, color, size, clickFxn = () => { }, afterFxn = () => { }, debounceInterval, className, id }) {
    // Define 2 state variables:
    // One to determine if the button is disabled from the parent component
    // And one for determining if the clickFxn is processing
    const [disabled, setDisabled] = useState(isDisabled)
    const [processing, setProcessing] = useState(false);

    // Create a promise that delays running the onClick function from the props 
    // for the debounceInterval time and resolves with a false response
    // to end the processing phase
    const handleClick = (e) => {
        e.stopPropagation();
        Promise.all([
            setProcessing(true),
            setDisabled(true)
        ]).then(() => {
            debounceFxn();
        });
    }

    const debounceFxn = _.debounce(() => {
        Promise.all([
            clickFxn()
        ]).then(() => {
            setProcessing(false);
            setDisabled(false);
            afterFxn();
        });
    }, debounceInterval, { leading: true, trailing: false });

    // This determines if the button is disabled or enabled from the parent component 
    // and updates the "disabled" state accordingly
    useEffect(() => {
        setDisabled(isDisabled)
    }, [isDisabled]);


    // Create a button that sets the disabled and processing states to true onClick
    // to trigger the handleClick promise function
    return (
        <Button
            id={id}
            className={className}
            variant={variant}
            color={color}
            size={size}
            onClick={handleClick}
            disabled={disabled}
        >
            <React.Fragment>
                {processing ? <CircularProgress className="mr-8" size={16} /> : null}
                {processing ? processingText : buttonText}
            </React.Fragment>
        </Button>
    )
}
