import { PushNotifications } from '@capacitor/push-notifications';
import { openEditEventDialog } from 'main/content/apps/calendar/store/actions';
import { openCardDialog } from 'main/content/apps/scrumboard/store/actions';
import { openEditTodoDialog } from 'main/content/apps/todo/store/actions';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { openEditEstimateDialog } from 'main/content/apps/estimates/store/actions';
import { openEditInvoiceDialog } from 'main/content/apps/invoices/store/actions';
import { setRecording } from 'main/content/apps/voice-mailboxes/store/actions';
import { getChat, openChatPanel } from 'main/chatPanel/store/actions';
import { markNotificationRead, markNotificationDeleted } from './local-notifications.actions';
import { Capacitor } from '@capacitor/core';
import _ from '@lodash';
import axios from 'axios';
import moment from 'moment';

export const SET_PUSH_NOTIFICATION = '[PUSH NOTIFICATION] SET PUSH NOTIFICATION';
export const SET_PUSH_NOTIFICATION_TOKEN = '[PUSH NOTIFICATION] SET PUSH NOTIFICATION TOKEN';
export const SET_PUSH_NOTIFICATION_ERROR = '[PUSH NOTIFICATION] SET PUSH NOTIFICATION ERROR';
export const INIT_PUSH_NOTIFICATIONS = '[PUSH NOTIFICATION] INIT PUSH NOTIFICATIONS';
export const REMOVE_PUSH_NOTIFICATION = '[PUSH NOTIFICATION] REMOVE PUSH NOTIFICATION';
export const CLEAR_PUSH_NOTIFICATIONS = '[PUSH NOTIFICATION] CLEAR PUSH NOTIFICATIONS';

export function initPushNotifications() {
    return (dispatch, getState) => {
        if (Capacitor.getPlatform() !== 'web') {
            dispatch(registerPushNotifications());
        }
    }
}

export function registerPushNotifications() {
    return (dispatch, getState) => {
        PushNotifications.requestPermissions().then((permission) => {
            window["log"](permission);
            dispatch({
                type: INIT_PUSH_NOTIFICATIONS,
                payload: permission
            });
            if (permission.receive === 'granted') {
                PushNotifications.register();
            } else {

            }

            PushNotifications.addListener(
                'registration',
                (token) => {
                    window["log"]('My token: ' + JSON.stringify(token));
                    if (token.value) {
                        const request = axios.post(`${window["apiLocation"]}/api/Notification`, { token: token.value });
                        request.then((response) => {
                            if (!response.data || response.data === '') {
                                dispatch({
                                    type: SET_PUSH_NOTIFICATION_TOKEN,
                                    payload: token
                                });
                            } else {
                                dispatch({
                                    type: SET_PUSH_NOTIFICATION_ERROR,
                                    payload: response.data
                                });
                            }
                        });
                    }

                }
            );

            PushNotifications.addListener('registrationError', (error) => {
                window["log"]('Error: ' + JSON.stringify(error));
                dispatch({
                    type: SET_PUSH_NOTIFICATION_ERROR,
                    payload: error
                });
            });

            dispatch(registerPushNotificationListeners());

        })
    }
}

export function registerPushNotificationListeners() {
    return (dispatch, getState) => {
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification) => {
                window["warn"]('Received Push Notification: ', notification);
                switch (notification.data.NotificationType) {
                    case 'ChatMessage': {
                        setTimeout(() =>
                            PushNotifications.removeDeliveredNotifications({ notifications: [notification] }).then(() => {

                            }), 1000);
                    }
                        break;
                }
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification) => {
                const state = getState();
                const { voicemail, calendar } = state.spReducers;
                const { Events, ToDos } = calendar.data;
                const { actionId } = notification;
                const data = notification.notification.data;
                window["log"]('Action performed: ', notification, data);
                if (actionId !== 'remove') {
                    if (data.NotificationID) {
                        dispatch(markNotificationRead(Number(data.NotificationID)));
                    }
                    if (data.Payload && data.Payload.length > 0) {
                        const Data = data.Payload;
                        switch (data.NotificationType) {
                            case 'Trip': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const { Co, WorkOrder, Trip } = data;
                                    dispatch(openEditWorkOrderDialog({ Co, WorkOrder }, Trip));
                                }
                            }
                            case 'Email': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const { Co, WorkOrder, BusinessUnit, Division, Estimate, Invoice } = data;
                                    if (WorkOrder) {
                                        dispatch(openEditWorkOrderDialog({ Co, WorkOrder }));
                                    }
                                    if (BusinessUnit && Division && Estimate) {
                                        dispatch(openEditEstimateDialog({ Co, BusinessUnit, Division, Estimate }));
                                    }
                                    if (Invoice) {
                                        dispatch(openEditInvoiceDialog({ Co, Invoice }));
                                    }
                                }
                            }
                                break;
                            case 'ToDoItem': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const event = _.find(ToDos, { Type: 'T', ID: data.ID });
                                    if (event) {
                                        dispatch(openEditTodoDialog(event));
                                    }
                                }
                            }
                                break;
                            case 'ChatMessage': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    if (data.UserName) {
                                        dispatch(openChatPanel());
                                        dispatch(getChat(data.UserName, data.ID));
                                    }
                                }
                            }
                                break;
                            case 'ProjectTask': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const event = _.find(ToDos, { Type: 'P', ID: data.ID });
                                    if (event) {
                                        dispatch(openCardDialog(event, true));
                                    }
                                }
                            }
                                break;
                            case 'CalendarEvent': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const event = _.find(Events, { ID: data.ID });
                                    if (event) {
                                        const { StartDate, EndDate, Title, Description, AllDayYN, AddedBy, PublicYN } = event;
                                        dispatch(openEditEventDialog({
                                            id: event.ID,
                                            start: moment.utc(StartDate).local().toDate(),
                                            end: moment.utc(EndDate).local().toDate(),
                                            title: Title,
                                            desc: Description,
                                            allDay: AllDayYN,
                                            publicYN: PublicYN,
                                            AddedBy,
                                            Data: {
                                                ...event.Data,
                                                type: "E",
                                            }
                                        }));
                                    }
                                }
                            }
                                break;
                            case 'Voicemail': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const recording = _.find(voicemail, { CallSid: data.CallSid });
                                    if (recording) {
                                        dispatch(setRecording(recording));
                                    } else {
                                        dispatch(setRecording(data.CallSid, true));
                                    }
                                }
                            }
                                break;
                        }
                    }
                } else {
                    if (data.NotificationID) {
                        dispatch(markNotificationDeleted(Number(data.NotificationID)));
                    }
                }
            }
        );
    }
}

export function clearPushNotifications() {
    return (dispatch, getState) => {
        PushNotifications.removeAllDeliveredNotifications().then((d) => {
            window["log"](d);
            dispatch({
                type: CLEAR_PUSH_NOTIFICATIONS
            })
        })
    }
}