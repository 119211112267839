import {combineReducers} from 'redux';
import CallQueues from './call-queues.reducer';
import user from './user.reducer';

const callQueuesAppReducers = combineReducers({
    CallQueues,
    user
});

export default callQueuesAppReducers;
