import axios from 'axios/index';
import _ from '@lodash';

export const SET_TIMECARD_DATA = '[SPCONNECTION] SET TIMECARD DATA';
export const GET_TIMECARD_DATA = '[SPCONNECTION] GET TIMECARD DATA';
export const FETCH_TIMECARD_DATA = '[SPCONNECTION] FETCH TIMECARD DATA';
export const SUBSCRIBE_TIMECARD_LIST = '[SPCONNECTION] SUBSCRIBE TIMECARD LIST';

export function getInitialTimecardData(Co) {

    return (dispatch) => {
        dispatch({
            type: FETCH_TIMECARD_DATA,
        });

        const request = axios.get(`${window["apiLocation"]}/api/Timecard?Co=${Co}`);

        request.then((response) => {
            dispatch({
                type: SET_TIMECARD_DATA,
                payload: response.data
            });
        });
    }
}

export function getTimecardData(Co) {

    return (dispatch) => {
        dispatch({
            type: FETCH_TIMECARD_DATA,
        });

        const request = axios.get(`${window["apiLocation"]}/api/Timecard?Co=${Co}`);

        request.then((response) => {
            dispatch({
                type: SET_TIMECARD_DATA,
                payload: response.data
            });
        });
    }
}

export function updateTimecardData(obj, method) {

    return (dispatch, getState) => {
        const state = getState();
        const { timecards } = state.spReducers.timecards;
        const index = _.findIndex(timecards, { ID: obj.ID });
        switch (method) {
            case 'ADD':
                {
                    if (index < 0) {
                        timecards.splice(0, 0, obj);
                    }
                }
                break;
            case 'UPDATE':
                {
                    if (index > -1) {
                        if (obj.SubmittedYN && obj.ApprovedYN !== 'Y') {
                            timecards.splice(index, 1, obj);
                        } else {
                            timecards.splice(index, 1);
                        }
                    } else {
                        timecards.splice(0, 0, obj);
                    }
                }
                break;
            case 'REMOVE':
                {
                    if (index > -1) {
                        timecards.splice(index, 1);
                    }
                }
                break;
        }
        dispatch(setTimecardData(timecards));
    };

}

export function setTimecardData(data) {
    return {
        type: SET_TIMECARD_DATA,
        payload: data
    };
}