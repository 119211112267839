import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import deburr from 'lodash/deburr';
import * as Actions from './store/actions';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Avatar, Button, Chip, Card, CardHeader, Icon, Input, Paper, MenuItem, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { FuseAnimateGroup } from '@fuse';
import ChipInput from 'material-ui-chip-input';
import accents from 'remove-accents';
import axios from 'axios/index';
import _ from '@lodash';
import moment from 'moment';

const styles = theme => ({
    root: {
        flexGrow: 1,
        left: 0,
        right: 0,
        position: 'absolute',
    },
    container: {
        position: 'relative',
    },
    chipContainer: {
        paddingTop: 3,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        margin: 'auto',
        zIndex: 1,
        marginTop: 10,
        left: 24,
        right: 24,
        maxHeight: 500,
        overflow: 'auto',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 10
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: 8,
    },
    input: {
        paddingLeft: '8.8rem',
    }
});

class PaymentsSearch extends React.Component {
    state = {

    };


    handleSearchInput = (event) => {
        this.setState({ suggestions: [], open: false, searching: false });
        this.props.setSearchText(event);
        // this.getSuggestions();

    }

    handleAddTerm = (term) => {
        let { Co, } = this.state;
        const { searchText, filters } = this.props;
        if (!searchText) {
            this.props.setSearchText({ target: { value: term } });
        } else {
            this.props.setSearchText({ target: { value: `${searchText}|${term}` } });
        }
        if (filters.Type !== 'deposit') {
            this.searchPayments();
        }
    }

    searchPayments = _.debounce(() => {
        this.props.getPayments(this.props.Co, 1);
    }, 750)

    handleDeleteTerm = (term) => {
        let { searchText, filters } = this.props;
        let newText = searchText.replace(`|${term}`, '').replace(`${term}|`, '').replace(term, '');
        this.props.setSearchText({ target: { value: newText } });
        if (filters.Type !== 'deposit') {
            this.searchPayments();
        }
    }

    render() {
        const { classes, searchText, filters } = this.props;
        const { suggestions, searching, open } = this.state;

        return (
            <div className={classes.root}>
                <ChipInput  blurBehavior="add"
                    className={classNames(classes.input, "xl:pl-56")}
                    classes={{
                        chipContainer: classes.chipContainer,
                    }}
                    // variant="outlined"
                    // label="Terms"
                    id="terms"
                    placeholder={`Search ${filters.Type === 'deposit' ? 'Deposits' : 'Transactions'}`}
                    blurBehavior="add"
                    value={searchText && searchText.length > 0 ? searchText.split('|') : []}
                    disableUnderline={true}
                    onAdd={this.handleAddTerm}
                    onDelete={this.handleDeleteTerm}
                    chipRenderer={({ value, handleDelete }, key) =>
                        <Chip key={key} className="mb-8 mr-4" color="primary" onDelete={handleDelete} label={value} />
                    }
                    fullWidth
                />
            </div>
        );
    }
}

PaymentsSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText,
        getPayments: Actions.getPayments
    }, dispatch);
}

function mapStateToProps({ paymentsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        searchText: paymentsApp.payments.searchText,
        filters: paymentsApp.payments.filters,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PaymentsSearch));