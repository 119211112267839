import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import { Badge, Hidden, Icon, IconButton, Input, Paper, Typography, Button } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FuseAnimate, FuseSelectedTheme } from '@fuse';
import CallHandlerSearch from './CallHandlerSearchBar';
import _ from '@lodash';

const styles = theme => ({
    root: {},
});

class CallHandlerHeader extends Component {
    state = {
        drafts: {},
        calls: []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calls, callHandler } = this.props;
        const { drafts } = callHandler;
        if (!_.isEqual(drafts, this.state.drafts) || !_.isEqual(calls, this.state.calls)) {
            this.setState({ ...this.state, drafts, calls });
        }
    };

    render() {
        const { classes, setSearchText, searchText, pageLayout } = this.props;
        const { drafts, calls } = this.state;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex flex-1">
                    <Paper className="flex items-center w-full h-48 sm:h-56 p-16 pl-4 md:pl-16 rounded-8 relative" elevation={1}>
                        <Hidden xlUp>
                            <IconButton
                                onClick={(ev) => pageLayout().toggleLeftSidebar()}
                                aria-label="open left sidebar"
                                className="z-10"
                            >
                                <Badge color="error" badgeContent={Object.keys(drafts).length}>
                                    <Icon>assignment_ind</Icon>
                                </Badge>
                            </IconButton>
                        </Hidden>

                        <Icon color="action">search</Icon>
                        <CallHandlerSearch className="w-full" />
                        <Hidden xlUp>
                            <IconButton
                                onClick={(ev) => pageLayout().toggleRightSidebar()}
                                aria-label="open right sidebar"
                                className="z-10 pin-r mr-8 absolute"
                            >
                                <Badge color="error" badgeContent={calls.length}>
                                    <Icon>ring_volume</Icon>
                                </Badge>
                            </IconButton>
                        </Hidden>
                        <div className="flex flex-shrink items-center w-1/5">

                        </div>
                        
                    </Paper>
                </div>
            </MuiThemeProvider>
        )
            ;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, customersApp, spReducers }) {
    return {
        searchText: customersApp.customers.searchText,
        callHandler: callHandlerApp.callHandler,
        calls: spReducers.callHandler.Calls,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CallHandlerHeader));
