import React, { Component } from "react";
import {
  Grow,
  Slide,
  TextField,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  Typography,
  Toolbar,
  AppBar,
  Avatar,
  MenuItem,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import * as Actions from "./store/actions";
import { connect } from "react-redux";
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import classNames from "classnames";
import moment from "moment";
import Media from "react-media";
import Autocomplete from "../../components/autocomplete/Autocomplete";
import ChangeDialog from "../../components/change-log/ChangeDialog";
import _ from "@lodash";
import DebounceButton from "../../components/debounce-button/DebounceButton";
import DraggableDialog from "../draggable-dialog/DraggableDialog";
import { openPreloadTripDialog } from "./store/actions/trips.actions";
import { createTrip } from "../dispatch-board/store/actions";
import getProfileImage from "../../../functions/getProfileImageUrl";

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {},
  formControl: {
    marginBottom: 24,
  },
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
    fontSize: 16,
  },
  status: {
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -10,
  },
  liRoot: {
    padding: 0,
  },
  assigned: {
    backgroundColor: theme.palette.primary.main,
  },
  tripBadge: {
    top: 20,
    right: 39,
    background: "#3f3f3f",
  },
  tripBadgeIcon: {
    fontSize: 12,
  },
  tripCountBadge: {
    top: 14,
    right: -6,
    height: 16,
    minWidth: 16,
    fontSize: "1rem",
    border: "2px solid #3f3f3f",
    padding: 2,
  },
  avatarTech: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  switch: {},
});
const newTripState = {
  ID: null,
  Co: null,
  WorkOrder: null,
  Trip: null,
  Technician: null,
  Technicians: [],
  Bulk: [],
  ScheduledDate: null,
  ScheduledTime: null,
  NotifiedDate: null,
  NotifiedTime: null,
  AcceptedDate: null,
  AcceptedTime: null,
  RejectedDate: null,
  RejectedTime: null,
  EnRouteDate: null,
  EnRouteTime: null,
  ArrivedDate: null,
  ArrivedTime: null,
  InProgressDate: null,
  InProgressTime: null,
  OnHoldDate: null,
  OnHoldTime: null,
  CompletedDate: null,
  CompletedTime: null,
  Status: 0,
  Duration: 1,
  Notes: null,
  CreatedDate: new Date(),
  CreatedBy: null,
  UpdatedDate: null,
  UpdatedBy: null,
  Data: {
    Assignments: [],
    ErrMsg: null,
    Tags: [],
  },
  anytime: false,
  processing: [],
  viewChanges: false,
  value: 0,
  dialogHeight: null,
  minHeight: null,
  location: null,
  multiVisit: false,
  editVisit: null,
};

class TripDialog extends Component {
  state = { ...newTripState };

  ref = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    newTripState.Co = this.props.Co;
    if (!prevProps.tripDialog.props.open && this.props.tripDialog.props.open) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.tripDialog.type === "edit" &&
        this.props.tripDialog.data &&
        !_.isEqual(this.props.tripDialog.data, prevProps.tripDialog.data)
      ) {
        this.setState({
          ...newTripState,
          ...this.props.tripDialog.data,
          Technicians: [],
          Bulk: [],
          anytime: !this.state.ScheduledTime,
        });
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.tripDialog.type === "new" &&
        !_.isEqual(
          { ...newTripState, ...this.props.tripDialog.data },
          prevState
        )
      ) {
        this.setState({
          ...newTripState,
          ...this.props.tripDialog.data,
          Technicians: [],
          Bulk: [],
        });
      }
    } else {
      if (
        this.props.tripDialog.type === "update" &&
        this.props.tripDialog.data &&
        !_.isEqual(this.props.tripDialog.data, prevState)
      ) {
        this.props.tripDialog.type = "edit";
        this.setState({ ...this.props.tripDialog.data });
      }
    }
  }

  handleChange = (event) => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === "checkbox"
          ? event.target.checked
            ? "Y"
            : "N"
          : event.target.value
      )
    );
  };

  closeComposeDialog = () => {
    this.props.tripDialog.type === "edit"
      ? this.props.closeEditTripDialog()
      : this.props.closeNewTripDialog();
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    });
  };

  closeDialogForSplitscreen = () => {
    this.props.tripDialog.type === "edit"
      ? this.props.closeEditTripDialog()
      : this.props.closeNewTripDialog();
  };

  canBeSubmitted() {
    const {
      Co,
      WorkOrder,
      ScheduledDate,
      Technician,
      Data,
      Technicians,
      Bulk,
      multiVisit,
    } = this.state;
    return (
      Boolean(WorkOrder) &&
      Co &&
      ((multiVisit && Bulk && Bulk.length > 0) ||
        (!multiVisit &&
          ScheduledDate &&
          (Technician || Technicians.length > 0))) &&
      Data.Assignments &&
      Data.Assignments.length > 0
    );
  }

  handleAnytimeChange = (event) => {
    const { ScheduledDate, anytime } = this.state;
    if (event.target.checked) {
      this.setState({ ...this.state, ScheduledTime: null, anytime: true });
    } else {
      this.setState({
        ...this.state,
        ScheduledTime: ScheduledDate,
        anytime: false,
      });
    }
  };

  handleAdminTripDateChange = (event) => {
    let { trip, tripValue, anytime } = this.state;
    // window["warn"](event);
    let ScheduledDate = this.formatDate(event._d);
    let ScheduledTime = anytime ? null : event._d.toLocaleString("en-US");
    this.setState({ ...this.state, ScheduledDate, ScheduledTime });
  };

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString("en-US"));
    const mm = dt.getMonth() + 1;
    const dd = dt.getDate();
    const yyyy = dt.getFullYear();
    const formatted =
      yyyy + "-" + (mm <= 9 ? "0" + mm : mm) + "-" + (dd <= 9 ? "0" + dd : dd);
    return formatted;
  }

  closeMenu = () => {
    this.setState({ menuEl: null });
  };

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { securables } = this.props;
    const { menuEl } = this.state;
    const accessLevel = _.find(securables, { Securable: "dispatch-board" });

    return (
      <>
        <IconButton
          style={{
            position: "absolute",
            right: Boolean(this.state.Trip) ? 104 : 56,
            top: !matches.small ? 8 : 4,
            color: "white",
          }}
          className="dialog-header-icon"
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        {Boolean(this.state.Trip) && (
          <IconButton
            style={{
              position: "absolute",
              right: 56,
              top: 10,
              color: "white",
            }}
            className="dialog-header-icon"
            onClick={(e) => this.setState({ menuEl: e.target })}
          >
            <Icon>more_vert</Icon>
          </IconButton>
        )}
        {Boolean(this.state.Trip) && (
          <Menu
            id="dialog-menu"
            classes={
              {
                /*paper: "min-w-256"*/
              }
            }
            anchorEl={menuEl}
            open={Boolean(menuEl)}
            onClose={this.closeMenu}
          >
            <MenuItem
              onClick={() => {
                this.setState({ menuEl: null, viewChanges: true });
              }}
            >
              <Icon className="mr-8">history</Icon>View Changes
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({
                  menuEl: null,
                  Trip: null,
                  ID: null,
                  Technicians: [this.state.Technician],
                  Technician: null,
                  Status: 0,
                  NotifiedDate: null,
                  NotifiedTime: null,
                  AcceptedDate: null,
                  AcceptedTime: null,
                  RejectedDate: null,
                  RejectedTime: null,
                  EnRouteDate: null,
                  EnRouteTime: null,
                  ArrivedDate: null,
                  ArrivedTime: null,
                  InProgressDate: null,
                  InProgressTime: null,
                  OnHoldDate: null,
                  OnHoldTime: null,
                  CompletedDate: null,
                  CompletedTime: null,
                  Notes: null,
                  CreatedDate: new Date(),
                  CreatedBy: null,
                  anytime: !this.state.ScheduledTime,
                });
              }}
            >
              <Icon className="mr-8">file_copy</Icon>Copy Trip
            </MenuItem>
          </Menu>
        )}
        <ChangeDialog
          type="TRIP"
          rec={this.state.ID}
          restore={(data) => this.setState({ ...data, viewChanges: false })}
          accessLevel={accessLevel}
          data={this.state}
          open={this.state.viewChanges}
          onClose={() => this.setState({ viewChanges: false })}
        />
        <IconButton
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "white",
          }}
          onClick={(e) => {
            closeFxn(e);
          }}
          className="dialog-header-icon"
        >
          <Icon>close</Icon>
        </IconButton>
      </>
    );
  };

  ToolbarContents = () => {
    const { tripDialog } = this.props;

    return (
      <>
        <Toolbar className="flex w-full" style={{ paddingLeft: 0 }}>
          <Typography variant="subtitle1" color="inherit">
            {this.state.WorkOrder ? `Work Order #${this.state.WorkOrder} ` : ""}
            {tripDialog.type === "new" || !this.state.Trip
              ? "New Trip"
              : `Trip #${this.state.Trip}`}
          </Typography>
        </Toolbar>
      </>
    );
  };

  canBeAdded = () => {
    const {
      Technicians,
      ScheduledDate,
      ScheduledTime,
      Duration,
      Status,
      Notes,
      Bulk,
    } = this.state;
    const visit = {
      Technicians,
      ScheduledDate,
      ScheduledTime,
      Duration,
      Status,
      Notes,
    };
    let exists = false;
    Bulk.forEach((v) => {
      if (_.isEqual(v, visit)) {
        exists = true;
      }
    });
    return !exists;
  };

  render() {
    const {
      classes,
      securables,
      tripDialog,
      addTrip,
      updateTrip,
      removeTrip,
      date,
      technicians,
      services,
    } = this.props;
    const { readOnly } = tripDialog.props;
    const {
      Status,
      Trip,
      Data,
      processing,
      menuEl,
      Technicians,
      Bulk,
    } = this.state;
    const avatar = "assets/images/avatars/profile.jpg";
    const accessLevel = _.find(securables, { Securable: "dispatch-board" });
    const tripAccessLevel = _.find(securables, {
      Securable: "work-order-trips",
    });
    const statusProps = [
      {
        text: "Scheduled",
        icon: "access_time",
        color: "rgb(100, 100, 200)",
        dateProp: "ScheduledDate",
        timeProp: "ScheduledTime",
      },
      {
        text: "Notified",
        icon: "offline_bolt",
        color: "rgb(0, 150, 250)",
        dateProp: "NotifiedDate",
        timeProp: "NotifiedTime",
      },
      {
        text: "Accepted",
        icon: "offline_pin",
        color: "rgb(0, 50, 250)",
        dateProp: "AcceptedDate",
        timeProp: "AcceptedTime",
      },
      {
        text: "Rejected",
        icon: "cancel",
        color: "rgb(210, 0, 0)",
        dateProp: "RejectedDate",
        timeProp: "RejectedTime",
      },
      {
        text: "En-Route",
        icon: "explore",
        color: "rgb(50, 170, 200)",
        dateProp: "EnRouteDate",
        timeProp: "EnRouteTime",
      },
      {
        text: "Arrived",
        icon: "my_location",
        color: "rgb(50, 150, 100)",
        dateProp: "ArrivedDate",
        timeProp: "ArrivedTime",
      },
      {
        text: "In Progress",
        icon: "play_circle_outline",
        color: "rgb(150, 200, 50)",
        dateProp: "InProgressDate",
        timeProp: "InProgressTime",
      },
      {
        text: "On Hold",
        icon: "pause_circle_outline",
        color: "rgb(210, 100, 100)",
        dateProp: "OnHoldDate",
        timeProp: "OnHoldTime",
      },
      {
        text: "Completed",
        icon: "check_circle",
        color: "rgb(100, 100, 100)",
        dateProp: "CompletedDate",
        timeProp: "CompletedTime",
      },
    ];
    const status = statusProps[Status];
    // window["warn"](status);

    const dialogProps = {
      ...tripDialog.props,
      className: classes.root,
      id: `trip-dialog-${this.state.Trip}`,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: "xs",
      //hideBackdrop: true,
      disableEnforceFocus: true,
    };

    const draggableSelectImg =
      "assets/images/draggable-selections/trip-dialog-img.png";

    //let header = tripDialog.type === 'new' ? 'New Trip' : `Trip #${this.state.Trip}`;
    let header1 = this.state.WorkOrder
      ? `Work Order #${this.state.WorkOrder} Trip`
      : "";
    let header2 =
      tripDialog.type === "new" || !this.state.Trip
        ? "New Trip"
        : `Trip #${this.state.Trip}`;
    //let header = header1 + header2

    let header = header1;

    return (
      <Media query="(min-width: 512px) and (min-height: 512px)">
        {(matches) => (
          <DraggableDialog
            SlideUp={null}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this
            )}
            header={header}
            type="Trip"
            selector={document.querySelector(`#trip-dialog-${this.state.Trip}`)}
            picRef={draggableSelectImg}
            icon="local_shipping"
            dialogState={this.state}
            reopenDialogFxn={
              tripDialog.type === "new"
                ? this.props.openNewTripDialog
                : this.props.openPreloadTripDialog
            }
            description={"Trip"}
            storeDialog={tripDialog}
            TypeState={
              tripDialog.type === "new" && tripDialog.props.open === true
                ? true
                : this.state.Trip
            }
            screenOverlay={this.state.screenOverlay}
            //posSet={this.state.posSet}
            //initialWidthSet={initialWidthSet}
            //minHeight={this.state.minHeight}
            //location={this.state.location}
            //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
            //dialogHeight={this.state.dialogHeight}
          >
            <DialogContent classes={{ root: "p-24" }} ref={this.ref}>
              <div className="w-full">
                <Autocomplete
                  className={
                    (!Trip || Trip < 1) && Technicians && Technicians.length > 0
                      ? "mb-8"
                      : classes.formControl
                  }
                  title="Technician"
                  options={technicians}
                  menuItemComponent={(value) => {
                    return (
                      (value.Technician === this.state.Technician ||
                        value.ActiveYN === "Y") && (
                        <MenuItem
                          disabled={
                            _.findIndex(
                              Technicians,
                              (o) => o === value.Technician
                            ) > -1
                          }
                          value={value.Technician}
                        >
                          <div className="flex">
                            <Avatar
                              style={{ marginRight: 8 }}
                              classes={{ root: classes.avatarRoot }}
                              className={classes.avatar}
                              alt={value.Technician}
                              src={
                                value.Data && value.Data.Avatar
                                  ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`)
                                  : avatar
                              }
                            />
                            {`${value.FirstName} ${value.LastName}`}
                          </div>
                        </MenuItem>
                      )
                    );
                  }}
                  value={this.state.Technician}
                  noflip={true}
                  onSelect={(option) => {
                    if (Trip > 0) {
                      this.setState({
                        Technician: option.Technician,
                        Technicians: [],
                      });
                    } else {
                      if (
                        _.findIndex(
                          Technicians,
                          (o) => o === option.Technician
                        ) < 0
                      ) {
                        Technicians.push(option.Technician);
                        this.setState({ Technician: null, Technicians });
                      }
                    }
                  }}
                  disabled={
                    (!accessLevel || accessLevel.AccessLevel === "R") &&
                    (!tripAccessLevel || tripAccessLevel !== "F")
                  }
                  required
                />
                {(!Trip || Trip < 1) && Technicians && Technicians.length > 0 && (
                  <div className="max-h-160 overflow-auto mb-8">
                    {Technicians.map((tech, index) => {
                      const tk = _.find(technicians, { Technician: tech });
                      if (tk) {
                        return (
                          <div className="w-full flex my-8 justify-center">
                            <div className="w-full flex">
                              <Avatar
                                style={{ marginRight: 8 }}
                                classes={{ root: classes.avatarRoot }}
                                className={classes.avatarTech}
                                alt={tk.Technician}
                                src={
                                  tk.Data && tk.Data.Avatar
                                    ? getProfileImage(`Co=${tk.Co}&ID=${tk.Data.Avatar}&Thumb=true`)
                                    : avatar
                                }
                              />
                              {`${tk.FirstName} ${tk.LastName}`}
                            </div>
                            <Icon
                              color="action"
                              onClick={() => {
                                Technicians.splice(index, 1);
                                this.setState({ Technicians });
                              }}
                              className="mt-4 cursor-pointer"
                            >
                              close
                            </Icon>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>

              <div className="flex">
                {this.state.anytime ? (
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    // className={classes.formControl}
                    label="Scheduled Date"
                    id="scheduledDate"
                    format="MM/DD/YYYY"
                    ref={(e) => (this.datePicker = e)}
                    value={this.state.ScheduledDate}
                    onChange={this.handleAdminTripDateChange}
                    animateYearScrolling
                    disabled={
                      (!accessLevel || accessLevel.AccessLevel === "R") &&
                      (!tripAccessLevel || tripAccessLevel !== "F")
                    }
                    autoOk
                    fullWidth
                  />
                ) : (
                  <DateTimePicker
                    variant="inline"
                    inputVariant="outlined"
                    // className={classes.formControl}
                    label="Scheduled Date"
                    id="scheduledDate"
                    format="MM/DD/YYYY h:mm A"
                    ref={(e) => (this.datePicker = e)}
                    value={this.state.ScheduledTime}
                    onChange={this.handleAdminTripDateChange}
                    animateYearScrolling
                    disabled={
                      (!accessLevel || accessLevel.AccessLevel === "R") &&
                      (!tripAccessLevel || tripAccessLevel !== "F")
                    }
                    fullWidth
                  />
                )}
              </div>

              <div className="flex w-full justify-end">
                <FormControlLabel
                  classes={{ root: "mr-0" }}
                  control={
                    <Switch
                      checked={
                        this.state.anytime ||
                        (this.state.ID && !this.state.ScheduledTime)
                      }
                      name="anytime"
                      onChange={this.handleAnytimeChange}
                      color="primary"
                      disabled={
                        (!accessLevel || accessLevel.AccessLevel === "R") &&
                        (!tripAccessLevel || tripAccessLevel !== "F")
                      }
                    />
                  }
                  label="Anytime"
                />
              </div>

              <div className="w-full">
                <TextField
                  className={classes.formControl}
                  label="Duration"
                  id="duration"
                  name="Duration"
                  value={this.state.Duration}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div className="flex">
                <TextField
                  className={
                    (!Trip || !this.state[status.timeProp] || Status < 1) &&
                    classes.formControl
                  }
                  style={{
                    marginBottom:
                      Trip && this.state[status.timeProp] && Status > 0 && 4,
                  }}
                  label="Status"
                  id="Status"
                  name="Status"
                  value={this.state.Status}
                  onChange={this.handleChange}
                  variant="outlined"
                  select
                  fullWidth
                >
                  {statusProps.map((value, index) => (
                    <MenuItem key={index} value={index}>
                      <Icon
                        style={{ marginRight: 8 }}
                        className={classNames(classes.status, "text-32")}
                      >{`${value.icon}`}</Icon>
                      {`${value.text}`}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              {Trip && this.state[status.timeProp] && Status > 0 && (
                <div className="flex" style={{ marginBottom: 4 }}>
                  <span
                    style={{
                      fontSize: "x-small",
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    {Status > 0
                      ? moment(moment(this.state[status.timeProp]))
                          .local()
                          .toDate()
                          .toLocaleTimeString("en-US")
                      : moment(this.state[status.timeProp])
                          .toDate()
                          .toLocaleTimeString("en-US")}{" "}
                    on{" "}
                    {Status > 0
                      ? moment(moment(this.state[status.timeProp]))
                          .local()
                          .toDate()
                          .toLocaleDateString("en-US")
                      : moment(this.state[status.timeProp])
                          .toDate()
                          .toLocaleDateString("en-US")}
                    <br />
                  </span>
                </div>
              )}

              <div className="flex">
                <TextField
                  className={classNames(classes.formControl)}
                  label="Notes"
                  id="notes"
                  name="Notes"
                  value={this.state.Notes}
                  onChange={this.handleChange}
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                />
              </div>
              {this.state.multiVisit && (
                <div className="flex">
                  <Button
                    className={classNames("w-full", classes.formControl)}
                    onClick={() => {
                      const {
                        Technicians,
                        ScheduledDate,
                        ScheduledTime,
                        Duration,
                        Status,
                        Notes,
                      } = _.cloneDeepWith(this.state);
                      Bulk.push({
                        Technicians,
                        ScheduledDate,
                        ScheduledTime,
                        Duration,
                        Status,
                        Notes,
                      });
                      this.setState({
                        Bulk: _.sortBy(
                          _.sortBy(Bulk, (r) =>
                            moment(
                              r.ScheduledTime || "1/1/1900 00:00:00"
                            ).toDate()
                          ),
                          (e) => moment(e.ScheduledDate)
                        ),
                      });
                    }}
                    color="primary"
                    variant="contained"
                    disabled={
                      !this.state.Technicians ||
                      this.state.Technicians.length < 1 ||
                      !this.state.ScheduledDate ||
                      !this.state.Duration ||
                      !this.canBeAdded()
                    }
                  >
                    Add Visit
                  </Button>
                </div>
              )}
              {this.state.multiVisit &&
                (!Trip || Trip < 1) &&
                Bulk &&
                Bulk.length > 0 && (
                  <div className="mt-6">
                    {Bulk.map((visit, index) => {
                      const { Duration, Technicians } = visit;
                      return (
                        <div
                          className="w-full flex mb-16 justify-between cursor-pointer"
                          onClick={() => {
                            const { Bulk } = _.cloneDeepWith(this.state);
                            const {
                              Technicians,
                              ScheduledDate,
                              ScheduledTime,
                              Duration,
                              Status,
                              Notes,
                            } = _.cloneDeepWith(visit);
                            Bulk.splice(index, 1);
                            this.setState({
                              Technicians,
                              ScheduledDate,
                              ScheduledTime,
                              Duration,
                              Status,
                              Notes,
                              Bulk,
                              anytime: !ScheduledTime,
                            });
                          }}
                        >
                          <div className="w-full flex justify-start">
                            <div className="flex mt-4 font-bold text-12">
                              <Icon
                                className="align-middle mr-4 text-16"
                                color="primary"
                              >
                                today
                              </Icon>
                              {visit.ScheduledTime
                                ? `${moment(visit.ScheduledTime).format(
                                    "M/D/YYYY h:mm A"
                                  )}`
                                : `${moment(visit.ScheduledDate).format(
                                    "M/D/YYYY"
                                  )} ANYTIME`}{" "}
                              - {Number(Duration).toFixed(2)} HR
                              {Number(Duration) > 1 ? "S" : ""}
                            </div>
                            <div className=" ml-2 flex">
                              {Technicians.map((member, index) => {
                                const tech = _.find(technicians, {
                                  Technician: member,
                                });
                                if (
                                  (tech && index < 4) ||
                                  Technicians.length < 6
                                ) {
                                  return (
                                    <Tooltip
                                      title={`${tech.FirstName} ${tech.LastName}`}
                                      placement="top"
                                    >
                                      <Avatar
                                        className="ml-2 align-middle w-24 h-24 cursor-pointer"
                                        src={
                                          tech.Data.Avatar
                                            ? getProfileImage(`Co=${tech.Co}&ID=${tech.Data.Avatar}&Thumb=true`) ||
                                              "assets/images/avatars/profile.jpg"
                                            : avatar
                                        }
                                      />
                                    </Tooltip>
                                  );
                                }
                              })}
                              {Technicians.length > 5 && (
                                <Avatar className="ml-2 align-middle w-24 h-24 text-11 bg-grey-light text-grey-darker cursor-pointer">
                                  +{Technicians.length - 4}
                                </Avatar>
                              )}
                            </div>
                          </div>
                          <Icon
                            color="action"
                            onClick={(e) => {
                              e.stopPropagation();
                              Bulk.splice(index, 1);
                              this.setState({ Bulk });
                            }}
                            className="cursor-pointer"
                          >
                            close
                          </Icon>
                        </div>
                      );
                    })}
                  </div>
                )}
              <div className="w-full">
                <Typography
                  variant="caption"
                  style={{ alignItems: "center", marginBottom: 4 }}
                  className="flex"
                >
                  <span className="font-bold">Assigned Scopes</span>
                </Typography>
                <div className="w-full mb-12">
                  {Data &&
                    Data.Scopes &&
                    Data.Scopes.map((scope, index) => {
                      const service = _.find(services, {
                        Service: scope.Service,
                      });
                      const assigned = _.find(Data.Assignments, {
                        Scope: scope.Scope,
                      });
                      const isProcessing =
                        _.findIndex(processing, (o) => o === scope.Scope) > -1;
                      return (
                        <div
                          onClick={() => {
                            if (
                              !readOnly &&
                              ((accessLevel &&
                                accessLevel.AccessLevel !== "R") ||
                                (tripAccessLevel &&
                                  tripAccessLevel.AccessLevel === "F"))
                            ) {
                              if (this.state.ID) {
                                if (!isProcessing) {
                                  processing.push(scope.Scope);
                                  if (assigned) {
                                    this.setState({ processing }, () => {
                                      Promise.all([
                                        this.props.removeTripAssignment(
                                          this.state,
                                          scope.Scope,
                                          true
                                        ),
                                      ]).then(() => {
                                        const ind = _.findIndex(
                                          processing,
                                          (o) => o === scope.Scope
                                        );
                                        if (ind > -1) {
                                          processing.splice(ind, 1);
                                          this.setState({ processing });
                                        }
                                      });
                                    });
                                  } else {
                                    this.setState({ processing }, () => {
                                      Promise.all([
                                        this.props.addTripAssignment(
                                          this.state,
                                          scope.Scope,
                                          true
                                        ),
                                      ]).then(() => {
                                        const ind = _.findIndex(
                                          processing,
                                          (o) => o === scope.Scope
                                        );
                                        if (ind > -1) {
                                          processing.splice(ind, 1);
                                          this.setState({ processing });
                                        }
                                      });
                                    });
                                  }
                                }
                              } else {
                                const { Co, WorkOrder, Scope } = scope;
                                const { Trip } = this.state;
                                const { Assignments } = this.state.Data;
                                if (!assigned) {
                                  Assignments.push({
                                    Co,
                                    WorkOrder,
                                    Trip,
                                    Scope,
                                  });
                                  this.setState({
                                    Data: { ...this.state.Data, Assignments },
                                  });
                                } else {
                                  const index = _.findIndex(Assignments, {
                                    Scope,
                                  });
                                  if (index > -1) {
                                    Assignments.splice(index, 1);
                                    this.setState({
                                      Data: { ...this.state.Data, Assignments },
                                    });
                                  }
                                }
                              }
                            }
                          }}
                          className="w-full cursor-pointer"
                        >
                          <ListItem
                            classes={{ root: classes.liRoot }}
                            className="relative mb-8 cursor-pointer"
                          >
                            <Badge
                              color="error"
                              classes={{ badge: classes.tripBadge }}
                              invisible={
                                !scope.Data.Assignments ||
                                scope.Data.Assignments.length < 1
                                  ? true
                                  : false
                              }
                              badgeContent={
                                <Badge
                                  color="secondary"
                                  classes={{ badge: classes.tripCountBadge }}
                                  badgeContent={
                                    scope.Data.Assignments
                                      ? scope.Data.Assignments.length
                                      : 0
                                  }
                                >
                                  <Icon className="text-16">
                                    local_shipping
                                  </Icon>
                                </Badge>
                              }
                            >
                              <Avatar
                                style={{ marginRight: 8 }}
                                classes={{ root: classes.avatarRoot }}
                                className={classNames(
                                  classes.avatar,
                                  assigned && classes.assigned
                                )}
                              >
                                {isProcessing ? (
                                  <Icon className="spin">refresh</Icon>
                                ) : (
                                  scope.Scope
                                )}
                              </Avatar>
                            </Badge>
                            <ListItemText
                              classes={{ root: classes.liRoot }}
                              primary={
                                <div className="w-3/4 overflow-hidden">
                                  <div
                                    className="font-bold text-14"
                                    style={{
                                      overflow: "hidden",
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {service.Description}
                                  </div>
                                </div>
                              }
                              secondary={
                                <span className="font-bold text-12">
                                  {
                                    <Typography variant="caption">
                                      {scope.Description}
                                    </Typography>
                                  }
                                </span>
                              }
                            />
                            <Typography
                              variant="caption"
                              className="font-bold absolute pin-r pin-t mr-4"
                              style={{ marginTop: 2 }}
                            >
                              {scope.DueBy &&
                                new Date(scope.DueBy).toLocaleDateString(
                                  "en-US"
                                )}
                            </Typography>
                          </ListItem>
                        </div>
                      );
                    })}
                </div>
              </div>
            </DialogContent>

            {!readOnly &&
              ((accessLevel && accessLevel.AccessLevel !== "R") ||
                (tripAccessLevel && tripAccessLevel.AccessLevel !== "R")) && (
                <DialogActions className="dialog-actions justify-between pl-16">
                  {tripDialog.type === "new" || !this.state.Trip ? (
                    <DebounceButton
                      buttonText={`Create Trip${
                        Technicians.length > 1 ? "s" : ""
                      }`}
                      processingText={`Creating Trip${
                        Technicians.length > 1 ? "s" : ""
                      }`}
                      isDisabled={!this.canBeSubmitted()}
                      variant={"contained"}
                      color={"primary"}
                      clickFxn={() => {
                        addTrip(this.state, true);
                        this.closeComposeDialog();
                      }}
                      debounceInterval={1000}
                    />
                  ) : (
                    <React.Fragment>
                      <DebounceButton
                        buttonText={"Save"}
                        processingText={"Saving"}
                        isDisabled={!this.canBeSubmitted()}
                        variant={"contained"}
                        color={"primary"}
                        clickFxn={() => {
                          updateTrip(this.state);
                          this.closeComposeDialog();
                        }}
                        debounceInterval={1000}
                      />
                      <IconButton
                        onClick={() => {
                          removeTrip(this.state);
                          this.closeComposeDialog();
                        }}
                        disabled={!this.state.ID}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </React.Fragment>
                  )}
                  {(tripDialog.type === "new" || !this.state.Trip) && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.multiVisit}
                          name="multiVisit"
                          onChange={(e) =>
                            this.setState({
                              multiVisit: e.target.checked,
                              Bulk: [],
                            })
                          }
                          color="primary"
                          disabled={
                            (!accessLevel || accessLevel.AccessLevel === "R") &&
                            (!tripAccessLevel || tripAccessLevel !== "F")
                          }
                        />
                      }
                      label="Multi-Visit"
                    />
                  )}
                </DialogActions>
              )}
          </DraggableDialog>
        )}
      </Media>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditTripDialog: Actions.closeEditTripDialog,
      closeNewTripDialog: Actions.closeNewTripDialog,
      openNewTripDialog: Actions.openNewTripDialog,
      addTrip: createTrip,
      updateTrip: Actions.updateTrip,
      removeTrip: Actions.removeTrip,
      addTripAssignment: Actions.addTripAssignment,
      removeTripAssignment: Actions.removeTripAssignment,
      openPreloadTripDialog,
    },
    dispatch
  );
}

function mapStateToProps({ tripsApp, spReducers }) {
  return {
    tripDialog: tripsApp.trips.tripDialog,
    technicians: spReducers.technicians,
    Co: spReducers.companies.Co,
    user: spReducers.userProfiles.User.UserName,
    securables: spReducers.userProfiles.User.Data.Securables,
    services: spReducers.services,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(TripDialog)
);
