import WorkOrderUsagesApp from 'main/content/apps/work-order-usages/WorkOrderUsagesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const WorkOrderUsagesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/work-order-usages/:id',
            component: WorkOrderUsagesApp
        },
        {
            path     : '/apps/work-order-usages',
            component: () => <Redirect to="/apps/work-order-usages/all"/>
        }
    ]
};
