import React from 'react';
import { Badge, Icon, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import FuseNavBadge from './../FuseNavBadge';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import _ from '@lodash';

const propTypes = {
    item: PropTypes.shape(
        {
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            icon: PropTypes.string,
            url: PropTypes.string
        })
};

const defaultProps = {};

const styles = theme => ({
    item: {
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + ' !important',
            pointerEvents: 'none',
            transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: theme.palette.secondary.contrastText + ' !important',
            },
            '& .list-item-icon': {
                color: theme.palette.secondary.contrastText + ' !important',
            }
        },
        '&.square, &.active.square': {
            width: '100%',
            borderRadius: '0'
        },
        '& .list-item-icon': {
            color: '#fff'
        },
        '& .list-item-text': {
            color: '#fff'
        },
        color: 'inherit !important',
        textDecoration: 'none !important',
        // '&.active .nav-badge': {
        //     top: -4,
        //     right: -6
        // },
    },
    badge: {
        top: 0,
        right: 0,
        fontSize: 9,
        minWidth: 14,
        minHeight: 14,
        height: 14,
    }
});

function getSecurable(Co, id, user, userRoles) {
    const roles = _.filter(userRoles, (o) => { return _.findIndex(user.Data.Roles, { Co, Role: o.Role }) > -1 });
    const securables = [];
    roles.map((value) => {
        value.Data.Securables.map((sec) => {
            securables.push(sec.Securable);
        });
    });
    return securables.indexOf(id) > -1;
}

function FuseNavVerticalItem({ item, classes, nestedLevel, userRole, navbarCloseMobile, active, user, userRoles, Co }) {
    // if (item.auth && (!item.auth.includes(userRole) || (userRole !== 'guest' && item.auth.length === 1 && item.auth.includes('guest')))) {
    if (item.securables && !getSecurable(Co, item.id, user, userRoles)) {
        return null;
    }

    let paddingValue = 40 + (nestedLevel * 16);
    const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';

    return (
        <ListItem
            button
            component={NavLink}
            to={item.url}
            activeClassName="active"
            className={classNames(classes.item, listItemPadding, 'list-item', active)}
            onClick={navbarCloseMobile}
            exact={item.exact}
        >
            {item.icon && (
                (item.badge && item.badge.title > 0) ? (
                    <Badge color="error" classes={{ badge: classNames(classes.badge, "nav-badge") }} badgeContent={item.badge.title}>
                        <Icon className="list-item-icon text-16 flex-no-shrink" color="action">{item.icon}</Icon>
                    </Badge>
                    // <FuseNavBadge badge={item.badge} />
                ) :
                    <Icon className="list-item-icon text-16 flex-no-shrink" color="action">{item.icon}</Icon>
            )}
            <ListItemText className="list-item-text" primary={item.title} classes={{ root: "pl-16", primary: 'text-14 list-item-text-primary' }} />
        </ListItem>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        navbarCloseMobile: Actions.navbarCloseMobile
    }, dispatch);
}

function mapStateToProps({ auth, fuse, spReducers, }) {
    return {
        Co: spReducers.companies.Co,
        // userRole: auth.user.role,
        user: spReducers.userProfiles.User,
        userRoles: spReducers.userRoles,
    }
}

FuseNavVerticalItem.propTypes = propTypes;
FuseNavVerticalItem.defaultProps = defaultProps;

const NavVerticalItem = withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseNavVerticalItem)));

export default NavVerticalItem;
