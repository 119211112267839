import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class ServicesList extends Component {

    state = {
        selectedServicesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedServiceMenu = (event) => {
        this.setState({ selectedServicesMenu: event.currentTarget });
    };

    closeSelectedServicesMenu = () => {
        this.setState({ selectedServicesMenu: null });
    };

    render() {
        const { classes, services, user, searchText, openNewServiceDialog, selectedServiceIds, selectAllServices, deSelectAllServices, toggleInSelectedServices, openEditServiceDialog, removeServices, removeService, toggleStarredService, setServicesUnstarred, setServicesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.serviceData ? [] : this.props.serviceData), searchText);
        const { selectedServicesMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewServiceDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">build</Icon>Services</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditServiceDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "DefaultBusinessUnit",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Division",
                                accessor: "DefaultDivision",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Department",
                                accessor: "DefaultDepartment",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Service",
                                accessor: "Service",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Description",
                                accessor: "Description"
                            },
                            {
                                Header: "Detail",
                                accessor: "Detail",
                            },
                            {
                                Header: "Duration",
                                accessor: "DefaultDuration",
                                className: "justify-center"
                            },
                            {
                                Header: "Priority",
                                accessor: "DefaultPriority",
                                className: "justify-center"
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center"
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Services found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServices: Actions.getServices,
        getUserData: Actions.getUserData,
        toggleInSelectedServices: Actions.toggleInSelectedServices,
        selectAllServices: Actions.selectAllServices,
        deSelectAllServices: Actions.deSelectAllServices,
        openEditServiceDialog: Actions.openEditServiceDialog,
        removeServices: Actions.removeServices,
        removeService: Actions.removeService,
        toggleStarredService: Actions.toggleStarredService,
        toggleStarredServices: Actions.toggleStarredServices,
        setServicesStarred: Actions.setServicesStarred,
        setServicesUnstarred: Actions.setServicesUnstarred,
        openNewServiceDialog: Actions.openNewServiceDialog
    }, dispatch);
}

function mapStateToProps({ servicesApp, spReducers }) {
    return {
        services: servicesApp.services.entities,
        selectedServiceIds: servicesApp.services.selectedServiceIds,
        searchText: servicesApp.services.searchText,
        user: servicesApp.user,
        serviceData: spReducers.services
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesList)));
