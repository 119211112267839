import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class HuntGroupsList extends Component {

    state = {
        selectedHuntGroupsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedHuntGroupMenu = (event) => {
        this.setState({ selectedHuntGroupsMenu: event.currentTarget });
    };

    closeSelectedHuntGroupsMenu = () => {
        this.setState({ selectedHuntGroupsMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, securables, huntGroups, user, searchText, openNewHuntGroupDialog, selectedHuntGroupIds, selectAllHuntGroups, deSelectAllHuntGroups, toggleInSelectedHuntGroups, openEditHuntGroupDialog, removeHuntGroups, removeHuntGroup, toggleStarredHuntGroup, setHuntGroupsUnstarred, setHuntGroupsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedHuntGroupsData ? (!this.props.huntGroupData ? [] : this.props.huntGroupData) : this.props.selectedHuntGroupsData), searchText);
        const { selectedHuntGroupsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "hunt-groups" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewHuntGroupDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">ring_volume</Icon>Hunt Groups</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditHuntGroupDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Hunt Group",
                                accessor: "ID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 256,
                            },
                            {
                                Header: "Dial Groups",
                                accessor: "Data",
                                className: "justify-center",
                                Cell: row => (
                                    row.original.Data.Members ? row.original.Data.Members.length : 0
                                ),
                                width: 128,
                            },
                            {
                                Header: "Dial Group Members",
                                accessor: "Data",
                                className: "justify-center",
                                Cell: row => {
                                    let endpoints = [];
                                    for (var i = 0; i < row.original.Data.Members.length; i++) {
                                        const value = row.original.Data.Members[i];
                                        for (var m = 0; m < value.Data.Members.length; m++) {
                                            let member = value.Data.Members[m];
                                            if (endpoints.indexOf(member.PhoneNumber) < 0) {
                                                endpoints.push(member.PhoneNumber);
                                            }
                                        }
                                    }
                                    window["warn"](endpoints);
                                    return endpoints.length;
                                },
                                width: 156,
                            },
                            {
                                Header: "Created By",
                                accessor: "AddedBy",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Created Date",
                                accessor: "AddedDate",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Hunt Groups found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getHuntGroups: Actions.getHuntGroups,
        getUserData: Actions.getUserData,
        toggleInSelectedHuntGroups: Actions.toggleInSelectedHuntGroups,
        selectAllHuntGroups: Actions.selectAllHuntGroups,
        deSelectAllHuntGroups: Actions.deSelectAllHuntGroups,
        openEditHuntGroupDialog: Actions.openEditHuntGroupDialog,
        removeHuntGroups: Actions.removeHuntGroups,
        removeHuntGroup: Actions.removeHuntGroup,
        toggleStarredHuntGroup: Actions.toggleStarredHuntGroup,
        toggleStarredHuntGroups: Actions.toggleStarredHuntGroups,
        setHuntGroupsStarred: Actions.setHuntGroupsStarred,
        setHuntGroupsUnstarred: Actions.setHuntGroupsUnstarred,
        openNewHuntGroupDialog: Actions.openNewHuntGroupDialog
    }, dispatch);
}

function mapStateToProps({ huntGroupsApp, spReducers }) {
    return {
        huntGroups: huntGroupsApp.huntGroups.entities,
        selectedHuntGroupIds: huntGroupsApp.huntGroups.selectedHuntGroupIds,
        searchText: huntGroupsApp.huntGroups.searchText,
        user: huntGroupsApp.user,
        huntGroupData: spReducers.huntGroups,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(HuntGroupsList)));
