import * as Actions from 'store/actions/spAuthentication';

const authentication = function (state = null, action) {
    switch (action.type) {
        case Actions.AUTHENTICATE_SP:
            {
                return action.data;
            }
        case Actions.REFRESH_SP_TOKEN:
            {
                return action.data;
            }
        case Actions.REMOVE_SP_TOKEN:
            {
                return null;
            }
    }

    return state;
};

export default authentication;