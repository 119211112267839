import {combineReducers} from 'redux';
import users from './users.reducer';
import user from './user.reducer';

const userReducers = combineReducers({
    users,
    user
});

export default userReducers;
