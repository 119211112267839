import axios from 'axios';
import { getUserData } from 'main/content/apps/service-units/store/actions/user.actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const GET_SERVICE_UNITS = '[SERVICE UNITS APP] GET SERVICE UNITS';
export const SET_SEARCH_TEXT = '[SERVICE UNITS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SERVICE_UNITS = '[SERVICE UNITS APP] TOGGLE IN SELECTED SERVICE UNITS';
export const SELECT_ALL_SERVICE_UNITS = '[SERVICE UNITS APP] SELECT ALL SERVICE UNITS';
export const DESELECT_ALL_SERVICE_UNITS = '[SERVICE UNITS APP] DESELECT ALL SERVICE UNITS';
export const OPEN_NEW_SERVICE_UNIT_DIALOG = '[SERVICE UNITS APP] OPEN NEW SERVICE UNIT DIALOG';
export const CLOSE_NEW_SERVICE_UNIT_DIALOG = '[SERVICE UNITS APP] CLOSE NEW SERVICE UNIT DIALOG';
export const OPEN_EDIT_SERVICE_UNIT_DIALOG = '[SERVICE UNITS APP] OPEN EDIT SERVICE UNIT DIALOG';
export const UPDATE_EDIT_SERVICE_UNIT_DIALOG = '[SERVICE UNITS APP] UPDATE EDIT SERVICE UNIT DIALOG';
export const CLOSE_EDIT_SERVICE_UNIT_DIALOG = '[SERVICE UNITS APP] CLOSE EDIT SERVICE UNIT DIALOG';
export const ADD_SERVICE_UNIT = '[SERVICE UNITS APP] ADD SERVICE UNIT';
export const UPDATE_SERVICE_UNIT = '[SERVICE UNITS APP] UPDATE SERVICE UNIT';
export const REMOVE_SERVICE_UNIT = '[SERVICE UNITS APP] REMOVE SERVICE UNIT';
export const REMOVE_SERVICE_UNITS = '[SERVICE UNITS APP] REMOVE SERVICE UNITS';
export const TOGGLE_STARRED_SERVICE_UNIT = '[SERVICE UNITS APP] TOGGLE STARRED SERVICE UNIT';
export const TOGGLE_STARRED_SERVICE_UNITS = '[SERVICE UNITS APP] TOGGLE STARRED SERVICE UNITS';
export const SET_SERVICE_UNITS_STARRED = '[SERVICE UNITS APP] SET SERVICE UNITS STARRED ';

export const OPEN_NEW_SERVICE_UNIT_PART_DIALOG = '[SERVICE UNIT PARTS APP] OPEN NEW SERVICE UNIT PART DIALOG';
export const CLOSE_NEW_SERVICE_UNIT_PART_DIALOG = '[SERVICE UNIT PARTS APP] CLOSE NEW SERVICE UNIT PART DIALOG';
export const OPEN_EDIT_SERVICE_UNIT_PART_DIALOG = '[SERVICE UNIT PARTS APP] OPEN EDIT SERVICE UNIT PART DIALOG';
export const CLOSE_EDIT_SERVICE_UNIT_PART_DIALOG = '[SERVICE UNIT PARTS APP] CLOSE EDIT SERVICE UNIT PART DIALOG';
export const ADD_SERVICE_UNIT_PART = '[SERVICE UNIT PARTS APP] ADD SERVICE UNIT PART';
export const UPDATE_SERVICE_UNIT_PART = '[SERVICE UNIT PARTS APP] UPDATE SERVICE UNIT PART';

export function getServiceUnits(routeParams) {
    const request = axios.get('/api/service-units-app/service-units', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_SERVICE_UNITS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedServiceUnits(serviceUnitId) {
    return {
        type: TOGGLE_IN_SELECTED_SERVICE_UNITS,
        serviceUnitId
    }
}


export function selectAllServiceUnits() {
    return {
        type: SELECT_ALL_SERVICE_UNITS
    }
}

export function deSelectAllServiceUnits() {
    return {
        type: DESELECT_ALL_SERVICE_UNITS
    }
}


export function openNewServiceUnitDialog(data) {
    return {
        type: OPEN_NEW_SERVICE_UNIT_DIALOG,
        data
    }
}

export function closeNewServiceUnitDialog() {
    return {
        type: CLOSE_NEW_SERVICE_UNIT_DIALOG
    }
}

export function openEditServiceUnitDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/ServiceUnit?Co=${data.Co}&Customer=${encodeURIComponent(data.Customer)}&Site=${encodeURIComponent(data.Site)}&Unit=${encodeURIComponent(data.UnitID)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_SERVICE_UNIT_DIALOG,
                data: response.data
            })
        });
    }
}

export function updateEditServiceUnitDialog(data) {
    return {
        type: UPDATE_EDIT_SERVICE_UNIT_DIALOG,
        data
    }
}

export function closeEditServiceUnitDialog() {
    return {
        type: CLOSE_EDIT_SERVICE_UNIT_DIALOG
    }
}

export function addServiceUnit(newServiceUnit) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.post(`${window["apiLocation"]}/api/ServiceUnit`,
            newServiceUnit
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Service Unit #${response.data.UnitID} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                Promise.all([
                    dispatch({
                        type: ADD_SERVICE_UNIT,
                        data: response.data
                    })
                ]).then(() => {
                    dispatch(updateEditServiceUnitDialog(response.data));
                });
            }
        }
        );
    };
}

export function updateServiceUnit(serviceUnit) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.put(`${window["apiLocation"]}/api/ServiceUnit?Co=${encodeURIComponent(serviceUnit.Co)}&Customer=${encodeURIComponent(serviceUnit.Customer)}&Site=${encodeURIComponent(serviceUnit.Site)}&Unit=${encodeURIComponent(serviceUnit.UnitID)}`,
            serviceUnit
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Service Unit #${serviceUnit.UnitID} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                Promise.all([
                    dispatch({
                        type: UPDATE_SERVICE_UNIT,
                        data: response.data
                    })
                ]).then(() => {
                    dispatch(updateEditServiceUnitDialog(response.data));
                })
            }
        }
        );
    };
}

export function removeServiceUnit(serviceUnit) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/ServiceUnit?Co=${encodeURIComponent(serviceUnit.Co)}&Customer=${encodeURIComponent(serviceUnit.Customer)}&Site=${encodeURIComponent(serviceUnit.Site)}&Unit=${encodeURIComponent(serviceUnit.UnitID)}`,
            serviceUnit
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Service Unit #${serviceUnit.UnitID} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                Promise.all([
                    dispatch({
                        type: REMOVE_SERVICE_UNIT,
                        data: serviceUnit
                    })
                ]).then(() => {
                });
            }
        }
        );
    };
}


export function removeServiceUnits(serviceUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.post('/api/service-units-app/remove-serviceUnits', {
            serviceUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_UNITS
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNITS
                })
            ]).then(() => dispatch(getServiceUnits(routeParams)))
        );
    };
}

export function toggleStarredServiceUnit(serviceUnitId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.post('/api/service-units-app/toggle-starred-serviceUnit', {
            serviceUnitId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_UNIT
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnits(routeParams)))
        );
    };
}

export function toggleStarredServiceUnits(serviceUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.post('/api/service-units-app/toggle-starred-serviceUnits', {
            serviceUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_UNITS
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNITS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnits(routeParams)))
        );
    };
}

export function setServiceUnitsStarred(serviceUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.post('/api/service-units-app/set-serviceUnits-starred', {
            serviceUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_UNITS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNITS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnits(routeParams)))
        );
    };
}

export function setServiceUnitsUnstarred(serviceUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitsApp.serviceUnits;

        const request = axios.post('/api/service-units-app/set-serviceUnits-unstarred', {
            serviceUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_UNITS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNITS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnits(routeParams)))
        );
    };
}



export function openNewServiceUnitPartDialog() {
    return {
        type: OPEN_NEW_SERVICE_UNIT_PART_DIALOG
    }
}

export function closeNewServiceUnitPartDialog() {
    return {
        type: CLOSE_NEW_SERVICE_UNIT_PART_DIALOG
    }
}

export function openEditServiceUnitPartDialog(data) {
    return {
        type: OPEN_EDIT_SERVICE_UNIT_PART_DIALOG,
        data
    }
}

export function closeEditServiceUnitPartDialog() {
    return {
        type: CLOSE_EDIT_SERVICE_UNIT_PART_DIALOG
    }
}

export function addServiceUnitPart(newServiceUnitPart) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/ServiceUnitPart`,
            newServiceUnitPart
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_SERVICE_UNIT_PART,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const state = getState();
                    const { serviceUnitDialog } = state.serviceUnitsApp.serviceUnits;
                    const { Data } = serviceUnitDialog.data;
                    const { Parts } = Data;
                    Parts.push(response.data);
                    dispatch({
                        type: UPDATE_EDIT_SERVICE_UNIT_DIALOG,
                        data: serviceUnitDialog.data
                    });
                    dispatch(showMessage({
                        message: `Part #${response.data.Part} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateServiceUnitPart(serviceUnitPart) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/ServiceUnitPart?Co=${encodeURIComponent(serviceUnitPart.Co)}&Customer=${encodeURIComponent(serviceUnitPart.Customer)}&Site=${encodeURIComponent(serviceUnitPart.Site)}&Unit=${encodeURIComponent(serviceUnitPart.UnitID)}&Part=${encodeURIComponent(serviceUnitPart.Part)}`,
            serviceUnitPart
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_SERVICE_UNIT_PART
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const state = getState();
                    const { serviceUnitDialog } = state.serviceUnitsApp.serviceUnits;
                    const { Data } = serviceUnitDialog.data;
                    const { Parts } = Data;
                    const index = _.findIndex(Parts, { Part: serviceUnitPart.Part });
                    if (index > -1) {
                        Parts.splice(index, 1, response.data);
                    }
                    dispatch({
                        type: UPDATE_EDIT_SERVICE_UNIT_DIALOG,
                        data: serviceUnitDialog.data
                    });
                    dispatch(showMessage({
                        message: `Part Type #${serviceUnitPart.Part} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeServiceUnitPart(serviceUnitPart) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/ServiceUnitPart?Co=${encodeURIComponent(serviceUnitPart.Co)}&Customer=${encodeURIComponent(serviceUnitPart.Customer)}&Site=${encodeURIComponent(serviceUnitPart.Site)}&Unit=${encodeURIComponent(serviceUnitPart.UnitID)}&Part=${encodeURIComponent(serviceUnitPart.Part)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_SERVICE_UNIT_PART
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const state = getState();
                    const { serviceUnitDialog } = state.serviceUnitsApp.serviceUnits;
                    const { Data } = serviceUnitDialog.data;
                    const { Parts } = Data;
                    const index = _.findIndex(Parts, { Part: serviceUnitPart.Part });
                    if (index > -1) {
                        Parts.splice(index, 1);
                    }
                    dispatch({
                        type: UPDATE_EDIT_SERVICE_UNIT_DIALOG,
                        data: serviceUnitDialog.data
                    });
                    dispatch(showMessage({
                        message: `Part Type #${serviceUnitPart.Part} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}