import axios from 'axios/index';
import { UPDATE_EDIT_WORK_ORDER_DIALOG } from '../../../work-orders/store/actions';
import { getUserData } from 'main/content/apps/work-order-scopes/store/actions/user.actions';
import { updateEditCustomerSiteDialog } from 'main/content/apps/customer-sites/store/actions/customer-sites.actions';
import { ADD_AGREEMENT } from 'main/content/apps/agreements/store/actions/agreements.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';
import _ from '@lodash';

export const GET_WORK_ORDER_SCOPES = '[WORK ORDER SCOPES APP] GET WORK ORDER SCOPES';
export const SET_SEARCH_TEXT = '[WORK ORDER SCOPES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_WORK_ORDER_SCOPES = '[WORK ORDER SCOPES APP] TOGGLE IN SELECTED WORK ORDER SCOPES';
export const SELECT_ALL_WORK_ORDER_SCOPES = '[WORK ORDER SCOPES APP] SELECT ALL WORK ORDER SCOPES';
export const DESELECT_ALL_WORK_ORDER_SCOPES = '[WORK ORDER SCOPES APP] DESELECT ALL WORK ORDER SCOPES';
export const OPEN_NEW_WORK_ORDER_SCOPE_DIALOG = '[WORK ORDER SCOPES APP] OPEN NEW WORK ORDER SCOPE DIALOG';
export const CLOSE_NEW_WORK_ORDER_SCOPE_DIALOG = '[WORK ORDER SCOPES APP] CLOSE NEW WORK ORDER SCOPE DIALOG';
export const OPEN_EDIT_WORK_ORDER_SCOPE_DIALOG = '[WORK ORDER SCOPES APP] OPEN EDIT WORK ORDER SCOPE DIALOG';
export const CLOSE_EDIT_WORK_ORDER_SCOPE_DIALOG = '[WORK ORDER SCOPES APP] CLOSE EDIT WORK ORDER SCOPE DIALOG';
export const OPEN_NEW_REVENUE_SPLIT_DIALOG = '[WORK ORDER SCOPES APP] OPEN NEW REVENUE SPLIT DIALOG';
export const CLOSE_NEW_REVENUE_SPLIT_DIALOG = '[WORK ORDER SCOPES APP] CLOSE NEW REVENUE SPLIT DIALOG';
export const OPEN_EDIT_REVENUE_SPLIT_DIALOG = '[WORK ORDER SCOPES APP] OPEN EDIT REVENUE SPLIT DIALOG';
export const CLOSE_EDIT_REVENUE_SPLIT_DIALOG = '[WORK ORDER SCOPES APP] CLOSE EDIT REVENUE SPLIT DIALOG';
export const ADD_REVENUE_SPLIT = '[WORK ORDER SCOPES APP] ADD REVENUE SPLIT';
export const UPDATE_REVENUE_SPLIT = '[WORK ORDER SCOPES APP] UPDATE REVENUE SPLIT';
export const REMOVE_REVENUE_SPLIT = '[WORK ORDER SCOPES APP] REMOVE REVENUE SPLIT';
export const ADD_WORK_ORDER_SCOPE = '[WORK ORDER SCOPES APP] ADD WORK ORDER SCOPE';
export const UPDATE_WORK_ORDER_SCOPE = '[WORK ORDER SCOPES APP] UPDATE WORK ORDER SCOPE';
export const ADD_EDIT_WORK_ORDER_SCOPE_DIALOG = '[WORK ORDER SCOPES APP] ADD EDIT WORK ORDER SCOPE DIALOG';
export const UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG = '[WORK ORDER SCOPES APP] UPDATE EDIT WORK ORDER SCOPE DIALOG';
export const REMOVE_WORK_ORDER_SCOPE = '[WORK ORDER SCOPES APP] REMOVE WORK ORDER SCOPE';
export const REMOVE_WORK_ORDER_SCOPES = '[WORK ORDER SCOPES APP] REMOVE WORK ORDER SCOPES';
export const TOGGLE_STARRED_WORK_ORDER_SCOPE = '[WORK ORDER SCOPES APP] TOGGLE STARRED WORK ORDER SCOPE';
export const TOGGLE_STARRED_WORK_ORDER_SCOPES = '[WORK ORDER SCOPES APP] TOGGLE STARRED WORK ORDER SCOPES';
export const SET_WORK_ORDER_SCOPES_STARRED = '[WORK ORDER SCOPES APP] SET WORK ORDER SCOPES STARRED ';

export function getWorkOrderScopes(routeParams) {
    const request = axios.get('/api/workOrderScopes-app/workOrderScopes', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_WORK_ORDER_SCOPES,
                payload: response.data,
                routeParams
            })
        );
}


export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedWorkOrderScopes(workOrderScopeId) {
    return {
        type: TOGGLE_IN_SELECTED_WORK_ORDER_SCOPES,
        workOrderScopeId
    }
}


export function selectAllWorkOrderScopes() {
    return {
        type: SELECT_ALL_WORK_ORDER_SCOPES
    }
}

export function deSelectAllWorkOrderScopes() {
    return {
        type: DESELECT_ALL_WORK_ORDER_SCOPES
    }
}


export function openNewWorkOrderScopeDialog(data) {
    return {
        type: OPEN_NEW_WORK_ORDER_SCOPE_DIALOG,
        data
    }
}

export function closeNewWorkOrderScopeDialog() {
    return {
        type: CLOSE_NEW_WORK_ORDER_SCOPE_DIALOG
    }
}

export function openPreloadWorkOrderScopeDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data: data,
        })
    }
}

export function openEditWorkOrderScopeDialog(data) {
    return (dispatch, getState) => {

        const { Co, WorkOrder, Scope } = data;

        const request = axios.get(`${window["apiLocation"]}/api/WorkOrderScope?Co=${encodeURIComponent(Co)}&WorkOrder=${encodeURIComponent(WorkOrder)}&Scope=${encodeURIComponent(Scope)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_WORK_ORDER_SCOPE_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditWorkOrderScopeDialog() {
    return {
        type: CLOSE_EDIT_WORK_ORDER_SCOPE_DIALOG
    }
}

export function addWorkOrderScope(newWorkOrderScope) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/WorkOrderScope`,
            newWorkOrderScope
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
                    data: response.data
                }),
                dispatch({
                    type: ADD_WORK_ORDER_SCOPE,
                    data: response.data
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Scope #${response.data.Scope} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                }
            })
        );
    };
}

export function updateWorkOrderScope(workOrderScope, autoInv) {
    return (dispatch, getState) => {

        const state = getState();
        const url = `${window["apiLocation"]}/api/WorkOrderScope?Co=${encodeURIComponent(workOrderScope.Co)}&WorkOrder=${encodeURIComponent(workOrderScope.WorkOrder)}&Scope=${encodeURIComponent(workOrderScope.Scope)}`;
        const request = axios.put(url,
            workOrderScope
        );

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: UPDATE_WORK_ORDER_SCOPE,
                    data: { ...response.data, autoInv }
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Scope #${response.data.Scope} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                    if (response.data.Data.Agreements && response.data.Data.Agreements.length > 0) {
                        response.data.Data.Agreements.map((agr) => {
                            dispatch({
                                type: ADD_AGREEMENT,
                                data: agr
                            });
                        })
                    }
                }
            })
            if (state.workOrderScopesApp.workOrderScopes.workOrderScopeDialog.props.open) {
                dispatch({
                    type: UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
                    data: response.data
                })
            }
        }).catch((error) => {
            console.warn('Error updating Scope: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('UPDATE', 'put', url, { ...workOrderScope }, 'work-order-scopes')),
                ]).then(() => {
                    dispatch({
                        type: UPDATE_WORK_ORDER_SCOPE,
                        data: { ...workOrderScope }
                    })
                })
            }
        });
    };
}

export function removeWorkOrderScope(workOrderScopeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderScopesApp.workOrderScopes;

        const request = axios.post('/api/workOrderScopes-app/remove-workOrderScope', {
            workOrderScopeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_WORK_ORDER_SCOPE,
                })
            ]).then(() => dispatch(getWorkOrderScopes(routeParams)))
        );
    };
}


export function removeWorkOrderScopes(workOrderScopeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderScopesApp.workOrderScopes;

        const request = axios.post('/api/workOrderScopes-app/remove-workOrderScopes', {
            workOrderScopeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_WORK_ORDER_SCOPES
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_SCOPES
                })
            ]).then(() => dispatch(getWorkOrderScopes(routeParams)))
        );
    };
}

export function toggleStarredWorkOrderScope(workOrderScopeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().workOrderScopesApp.workOrderScopes;

        const request = axios.post('/api/workOrderScopes-app/toggle-starred-workOrderScope', {
            workOrderScopeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_WORK_ORDER_SCOPE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderScopes(routeParams)))
        );
    };
}

export function toggleStarredWorkOrderScopes(workOrderScopeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderScopesApp.workOrderScopes;

        const request = axios.post('/api/workOrderScopes-app/toggle-starred-workOrderScopes', {
            workOrderScopeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_WORK_ORDER_SCOPES
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_SCOPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderScopes(routeParams)))
        );
    };
}

export function setWorkOrderScopesStarred(workOrderScopeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderScopesApp.workOrderScopes;

        const request = axios.post('/api/workOrderScopes-app/set-workOrderScopes-starred', {
            workOrderScopeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDER_SCOPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_SCOPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderScopes(routeParams)))
        );
    };
}

export function setWorkOrderScopesUnstarred(workOrderScopeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderScopesApp.workOrderScopes;

        const request = axios.post('/api/workOrderScopes-app/set-workOrderScopes-unstarred', {
            workOrderScopeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDER_SCOPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_SCOPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderScopes(routeParams)))
        );
    };
}


export function openNewRevenueSplitDialog(data) {
    return {
        type: OPEN_NEW_REVENUE_SPLIT_DIALOG,
        data
    }
}

export function closeNewRevenueSplitDialog() {
    return {
        type: CLOSE_NEW_REVENUE_SPLIT_DIALOG
    }
}

export function openEditRevenueSplitDialog(data) {
    return {
        type: OPEN_EDIT_REVENUE_SPLIT_DIALOG,
        data
    }
}

export function closeEditRevenueSplitDialog() {
    return {
        type: CLOSE_EDIT_REVENUE_SPLIT_DIALOG
    }
}


export function addRevenueSplit(newRevenueSplit) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/RevenueSplit`,
            newRevenueSplit
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error creating Revenue Split.`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const state = getState();
                const scope = _.cloneDeepWith(state.workOrderScopesApp.workOrderScopes.workOrderScopeDialog.data);
                scope.Data.Splits.push(response.data);
                const woDialog = state.workOrdersApp.workOrders.workOrderDialog;
                const wo = woDialog.props.open && woDialog.data.WorkOrder === scope.WorkOrder ? woDialog.data : null;
                if (wo) {
                    const index = _.findIndex(wo.Data.Scopes, { Scope: scope.Scope });
                    if (index > -1) {
                        wo.Data.Scopes.splice(index, 1, scope);
                        if (wo.Data.Billing && wo.Data.Billing[0] && wo.Data.Billing[0].Scopes) {
                            const ind = _.find(wo.Data.Billing[0].Scopes, { Scope: scope.Scope });
                            if (ind > -1) {
                                wo.Data.Billing[0].Scopes.splice(ind, 1, scope);
                            }
                        }
                        dispatch({
                            type: UPDATE_EDIT_WORK_ORDER_DIALOG,
                            data: wo//updateEditWorkOrderDialog(wo));
                        });
                    }
                }
                Promise.all([
                    dispatch({
                        type: ADD_REVENUE_SPLIT,
                        data: response.data
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Revenue Split #${response.data.Seq} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                })
            };
        });
    };
}

export function updateRevenueSplit(revenueSplit) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/RevenueSplit?Co=${encodeURIComponent(revenueSplit.Co)}&WorkOrder=${encodeURIComponent(revenueSplit.WorkOrder)}&Scope=${encodeURIComponent(revenueSplit.Scope)}&Seq=${encodeURIComponent(revenueSplit.Seq)}`,
            revenueSplit
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_REVENUE_SPLIT,
                    data: response.data
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Revenue Split #${revenueSplit.Seq} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function removeRevenueSplit(revenueSplit) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/RevenueSplit?Co=${encodeURIComponent(revenueSplit.Co)}&WorkOrder=${encodeURIComponent(revenueSplit.WorkOrder)}&Scope=${encodeURIComponent(revenueSplit.Scope)}&Seq=${encodeURIComponent(revenueSplit.Seq)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_REVENUE_SPLIT,
                    data: revenueSplit
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Revenue Split #${revenueSplit.Seq} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

