import axios from 'axios';
import { getUserData } from 'main/content/apps/inventory-locations/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_INVENTORY_LOCATIONS = '[INVENTORY LOCATIONS APP] GET INVENTORY LOCATIONS';
export const SET_SEARCH_TEXT = '[INVENTORY LOCATIONS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_INVENTORY_LOCATIONS = '[INVENTORY LOCATIONS APP] TOGGLE IN SELECTED INVENTORY LOCATIONS';
export const SELECT_ALL_INVENTORY_LOCATIONS = '[INVENTORY LOCATIONS APP] SELECT ALL INVENTORY LOCATIONS';
export const DESELECT_ALL_INVENTORY_LOCATIONS = '[INVENTORY LOCATIONS APP] DESELECT ALL INVENTORY LOCATIONS';
export const OPEN_NEW_INVENTORY_LOCATION_DIALOG = '[INVENTORY LOCATIONS APP] OPEN NEW INVENTORY LOCATION DIALOG';
export const CLOSE_NEW_INVENTORY_LOCATION_DIALOG = '[INVENTORY LOCATIONS APP] CLOSE NEW INVENTORY LOCATION DIALOG';
export const OPEN_EDIT_INVENTORY_LOCATION_DIALOG = '[INVENTORY LOCATIONS APP] OPEN EDIT INVENTORY LOCATION DIALOG';
export const UPDATE_EDIT_INVENTORY_LOCATION_DIALOG = '[INVENTORY LOCATIONS APP] UPDATE EDIT INVENTORY LOCATION DIALOG';
export const CLOSE_EDIT_INVENTORY_LOCATION_DIALOG = '[INVENTORY LOCATIONS APP] CLOSE EDIT INVENTORY LOCATION DIALOG';
export const ADD_INVENTORY_LOCATION = '[INVENTORY LOCATIONS APP] ADD INVENTORY LOCATION';
export const UPDATE_INVENTORY_LOCATION = '[INVENTORY LOCATIONS APP] UPDATE INVENTORY LOCATION';
export const REMOVE_INVENTORY_LOCATION = '[INVENTORY LOCATIONS APP] REMOVE INVENTORY LOCATION';
export const REMOVE_INVENTORY_LOCATIONS = '[INVENTORY LOCATIONS APP] REMOVE INVENTORY LOCATIONS';
export const TOGGLE_STARRED_INVENTORY_LOCATION = '[INVENTORY LOCATIONS APP] TOGGLE STARRED INVENTORY LOCATION';
export const TOGGLE_STARRED_INVENTORY_LOCATIONS = '[INVENTORY LOCATIONS APP] TOGGLE STARRED INVENTORY LOCATIONS';
export const SET_INVENTORY_LOCATIONS_STARRED = '[INVENTORY LOCATIONS APP] SET INVENTORY LOCATIONS STARRED ';

export const ADD_INVENTORY_LOCATION_MANAGER = '[INVENTORY LOCATIONS APP] ADD INVENTORY LOCATION MANAGER';
export const DELETE_INVENTORY_LOCATION_MANAGER = '[INVENTORY LOCATIONS APP] DELETE INVENTORY LOCATION MANAGER'

export function getInventoryLocations(routeParams) {
    const request = axios.get('/api/inventory-locations-app/inventory-locations', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_INVENTORY_LOCATIONS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedInventoryLocations(inventoryLocationId) {
    return {
        type: TOGGLE_IN_SELECTED_INVENTORY_LOCATIONS,
        inventoryLocationId
    }
}


export function selectAllInventoryLocations() {
    return {
        type: SELECT_ALL_INVENTORY_LOCATIONS
    }
}

export function deSelectAllInventoryLocations() {
    return {
        type: DESELECT_ALL_INVENTORY_LOCATIONS
    }
}


export function openNewInventoryLocationDialog(data) {
    return {
        type: OPEN_NEW_INVENTORY_LOCATION_DIALOG,
        data
    }
}

export function closeNewInventoryLocationDialog() {
    return {
        type: CLOSE_NEW_INVENTORY_LOCATION_DIALOG
    }
}

export function openPreloadInventoryLocationDialog(data){
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_INVENTORY_LOCATION_DIALOG,
            data: data,
        })
    }
}

export function openEditInventoryLocationDialog(data) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.get(`${window["apiLocation"]}/api/InventoryLocation?Co=${data.Co}&Location=${encodeURIComponent(data.Location)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_INVENTORY_LOCATION_DIALOG,
                data: response.data
            })
        });
    }
}

export function updateEditInventoryLocationDialog(data) {
    return {
        type: UPDATE_EDIT_INVENTORY_LOCATION_DIALOG,
        data
    }
}

export function closeEditInventoryLocationDialog() {
    return {
        type: CLOSE_EDIT_INVENTORY_LOCATION_DIALOG
    }
}

export function addInventoryLocation(newInventoryLocation) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.post(`${window["apiLocation"]}/api/InventoryLocation`,
            newInventoryLocation
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_INVENTORY_LOCATION,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Location #${response.data.Location} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditInventoryLocationDialog(response.data));
            })
        );
    };
}

export function updateInventoryLocation(inventoryLocation) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.put(`${window["apiLocation"]}/api/InventoryLocation?Co=${encodeURIComponent(inventoryLocation.Co)}&Location=${encodeURIComponent(inventoryLocation.Location)}`,
            inventoryLocation
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_INVENTORY_LOCATION,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Location #${inventoryLocation.Location} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditInventoryLocationDialog(response.data));
            })
        );
    };
}

export function removeInventoryLocation(inventoryLocation) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/InventoryLocation?Co=${encodeURIComponent(inventoryLocation.Co)}&Location=${encodeURIComponent(inventoryLocation.Location)}`,
            inventoryLocation
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_INVENTORY_LOCATION,
                    data: inventoryLocation
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Location #${inventoryLocation.Location} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeInventoryLocations(inventoryLocationIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.post('/api/inventory-locations-app/remove-inventoryLocations', {
            inventoryLocationIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_INVENTORY_LOCATIONS
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATIONS
                })
            ]).then(() => dispatch(getInventoryLocations(routeParams)))
        );
    };
}

export function toggleStarredInventoryLocation(inventoryLocationId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.post('/api/inventory-locations-app/toggle-starred-inventoryLocation', {
            inventoryLocationId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_INVENTORY_LOCATION
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocations(routeParams)))
        );
    };
}

export function toggleStarredInventoryLocations(inventoryLocationIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.post('/api/inventory-locations-app/toggle-starred-inventoryLocations', {
            inventoryLocationIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_INVENTORY_LOCATIONS
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATIONS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocations(routeParams)))
        );
    };
}

export function setInventoryLocationsStarred(inventoryLocationIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.post('/api/inventory-locations-app/set-inventoryLocations-starred', {
            inventoryLocationIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_INVENTORY_LOCATIONS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATIONS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocations(routeParams)))
        );
    };
}

export function setInventoryLocationsUnstarred(inventoryLocationIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().inventoryLocationsApp.inventoryLocations;

        const request = axios.post('/api/inventory-locations-app/set-inventoryLocations-unstarred', {
            inventoryLocationIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_INVENTORY_LOCATIONS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_INVENTORY_LOCATIONS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInventoryLocations(routeParams)))
        );
    };
}

export function addInventoryLocationManager(newManager) {
    return (dispatch, getState) => {

        const { Co, Location } = newManager;

        const state = getState();
        const { data } = state.inventoryLocationsApp.inventoryLocations.inventoryLocationDialog;

        const request = axios.post(`${window["apiLocation"]}/api/InventoryLocationManager`,
            newManager
        );

        return request.then((response) => {
            const { Data } = data;
            Data.Managers.push(response.data);

            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_INVENTORY_LOCATION_DIALOG,
                    data: { ...data, Data}
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Manager has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function deleteInventoryLocationManager(manager) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/InventoryLocationManager`,
            manager
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DELETE_INVENTORY_LOCATION_MANAGER,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Inventory Manager has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}
