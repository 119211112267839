import React, { Component } from 'react';
import {
    Grow, Slide, TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, FormGroup, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from '@lodash';
import Media from 'react-media';
import BarcodeScanner from 'main/content/components/barcode-scanner/BarcodeScanner';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import MaterialDialog from '../materials/MaterialDialog';
import { openEditMaterialDialog } from '../materials/store/actions';
import axios from 'axios';
import accents from 'remove-accents';
import deburr from 'lodash/deburr';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import TransferMaterialsList from './TransferMaterialsList';
import { addMaterialToTransfer, updateMaterialToTransfer, removeMaterialToTransfer, addInventoryTransfer, updateInventoryTransfer } from './store/actions/gps-inventory-transfers.actions'
import getProfileImage from '../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newGpsInventoryTransferState = {
    ID: null,
    Co: null,
    Transfer: null,
    WorkOrder: null,
    Scope: 1,
    Scopes: [],
    Line: null,
    Type: "I",
    typeOptions: [
        {
            type: "Inventory",
            value: "I"
        },
        {
            type: "Work Order",
            value: "W"
        }
    ],
    material: {
        ID: null,
        Co: null,
        Type: null,
        SourceLocation: null,
        NewLocation: null,
        WorkOrder: null,
        workOrder: null,
        Scope: null,
        ApprovedQty: null,
        AcceptedQty: null,
        Employee: null,
        Qty: null,
        Material: null,
        Status: null,
        Description: '',
        StockQty: '',
    },
    Data: {
        Lines: [],
    },
    editMaterial: false,
    Status: null,
    SourceLocation: null,
    NewLocation: null,
    Qty: null,
    Description: '',
    Material: null,
    InventoryLocation: null,
    Employee: null,
    ItemID: null,
    CreatedBy: null,
    CreatedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Notes: null,
    AttachmentID: null,
    UsageDate: new Date(),
    AddOnYN: 'N',
    scanner: null,
    isScanning: false,
    searching: false,
    searchingWo: false,
    scanMessage: null,
    dialogHeight: null,
    minHeight: null,
    location: null,
    woDescription: null,
    woList: [],
    StockQty: null,
};

class GpsInventoryTransferDialog extends Component {
    state = { ...newGpsInventoryTransferState };

    ref = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */

        const { Co, employees, user, gpsInventoryTransferDialog } = this.props;

        const emp = _.find(employees, { UserName: user.UserName });

        window["warn"]("gpsInventoryTransferDialog", this.state, this.props.gpsInventoryTransferDialog, emp)
        newGpsInventoryTransferState.Co = this.props.Co;
        if (!prevProps.gpsInventoryTransferDialog.props.open && this.props.gpsInventoryTransferDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.gpsInventoryTransferDialog.type === 'edit' &&
                this.props.gpsInventoryTransferDialog.data &&
                !_.isEqual(this.props.gpsInventoryTransferDialog.data, prevState)) {
                    let newData = gpsInventoryTransferDialog.data.Data.Lines[0]
                    if(newData.Type === "I"){
                        this.setState({ ...this.props.gpsInventoryTransferDialog.data, Co, Employee: emp.Employee, woDescription: null, woList: [], Type: newData.Type, NewLocation: newData.NewLocation});
                    } else {
                        this.setState({ ...this.props.gpsInventoryTransferDialog.data, Co, Employee: emp.Employee, woDescription: null, woList: [], Type: newData.Type, WorkOrder: newData.WorkOrder, Scope: newData.Scope}, () => this.getWOData(newData.WorkOrder));
                    }
                
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.gpsInventoryTransferDialog.type === 'new') {
                let transfer = { ...newGpsInventoryTransferState, ...this.props.gpsInventoryTransferDialog.data };
                if (!_.isEqual({ ...transfer }, prevState)) {
                    if(transfer.material.Material && !transfer.material.StockQty){
                        this.setState({ ...transfer, Co, Employee: emp.Employee, woDescription: null, woList: [] }, this.searchMaterialQty);
                    } else {
                        this.setState({ ...transfer, Co, Employee: emp.Employee, woDescription: null, woList: [] });
                    }
                    
                }
            }
        } else {
            if (this.props.gpsInventoryTransferDialog.type === 'update' &&
                this.props.gpsInventoryTransferDialog.data &&
                !_.isEqual(this.props.gpsInventoryTransferDialog.data, prevState)) {
                this.props.gpsInventoryTransferDialog.type = 'edit';
                this.setState({ ...this.props.gpsInventoryTransferDialog.data, Co, Employee: emp.Employee });
            }
        }
    }

    handleMaterialChange = (event) => {
        window["warn"]("handleMaterialChange ran!", event.target.name, event.target.value)
        this.setState({ ...this.state, material: {...this.state.material, [event.target.name]: event.target.value} })
    }

    closeComposeDialog = () => {
        this.props.gpsInventoryTransferDialog.type === 'edit' ? this.props.closeEditGpsInventoryTransferDialog() : this.props.closeNewGpsInventoryTransferDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true, Data: {Lines: []}})
    };

    closeDialogForSplitscreen = () => {
        this.props.gpsInventoryTransferDialog.type === 'edit' ? this.props.closeEditGpsInventoryTransferDialog() : this.props.closeNewGpsInventoryTransferDialog();
    };

    canBeSubmitted = () => {
        const { Co, SourceLocation, NewLocation, Data, WorkOrder, Scope, Type } = this.state;
        if(Type === "I"){
            return Co && SourceLocation && NewLocation && Data.Lines.length > 0;
        } else {
            return Co && SourceLocation && WorkOrder && Scope && Data.Lines.length > 0;
        }
        
    }

    setMaterial = (material) => {
        if(this.state.Status === 0){
            const mat = _.find(this.props.materials, { Material: material.Material });
            if (mat) {
                material.Description = mat.Description;
            }
            this.setState({ ...this.state, material, editMaterial: true }, this.searchMaterialQty);
        }
    }

    canMaterialBeSubmitted = () => {
        const { Co, material, NewLocation, WorkOrder, Scope, Type } = this.state;
        if(Type === "I"){
            return Co && material.Description && material.Qty && material.Material && NewLocation;
        } else {
            return Co && material.Description && material.Qty && material.Material && WorkOrder && Scope;
        }
        
    }

    handlePart = (part, qty) => {
        const { Material, UOM, Description, Cost, CostType } = part;
        this.setState({ ...this.state, material: { Material: Material, Qty: qty, Description: Description}, isScanning: false, scanMessage: null, })
    }

    getPart = (barcode) => {
        window["warn"]("getPart ran")
        const { Co, material } = this.state;
        const request = axios.get(`${window["apiLocation"]}/api/Material?Co=${encodeURIComponent(Co)}&Material=${encodeURIComponent(barcode ? barcode : material.Material)}`).then((response) => {
            if (response.data) {
                this.handlePart(response.data, material.Qty);
            } else {
                this.setState({ ...this.state, scanMessage: 'Part not found' });
            }
        });
    }

    // handleMaterialSearch = () => {
    //     const { Co, Material } = this.state;
    //     this.props.openEditMaterialDialog({ Co, Material });
    // }

    handleSourceLocationChange = (e) => {
        const SourceLocation = e.target.value;
        this.setState({ ...this.state, SourceLocation });
    }

    handleNewLocationChange = (e) => {
        const NewLocation = e.target.value;

        const { Data } = this.state;
        let newData = {...Data}
        if(Data.Lines.length > 0){    
            newData.Lines.map((Line) => {
                Line.NewLocation = NewLocation;
            })
        }
        
        this.setState({ ...this.state, NewLocation, Data: newData });
    }

    handleTypeChange = (e) => {
        const Type = e.target.value;

        const { Data } = this.state;
        let newData = {...Data}
        if(Data.Lines.length > 0){    
            newData.Lines.map((Line) => {
                Line.Type = Type;
            })
        }

        this.setState({ ...this.state, Type, Data: newData });
    }

    handleWOSearch = _.debounce(() => {
        const { woDescription } = this.state;
        if (woDescription && woDescription.length > 0) {
            const { Co, technician } = this.props;
            const Tech = technician ? technician.Technician : null;
            const inputValue = deburr(accents.remove(woDescription.trim())).toLowerCase();
            axios.get(`${window["apiLocation"]}/api/TripSearch?Co=${Co}&Tech=${encodeURIComponent(Tech)}&Term=${encodeURIComponent(inputValue)}`).then((response) => {
                this.setState({ woList: response.data }, () => window["warn"](this.state.woList));
            });
        } else {
            this.setState({ ...this.state, woList: [] });
        }
    }, 750);

    getWOData = (WorkOrder) => {
        const { Co } = this.props;
        axios.get(`${window["apiLocation"]}/api/WorkOrder?Co=${Co}&WorkOrder=${encodeURIComponent(WorkOrder)}`).then((response) => {
            window["warn"]("990077", WorkOrder, response.data)
            this.setState({ woList: [response.data], Scopes: response.data.Data.Scopes }, () => window["warn"](this.state.woList));
        });
    }

    searchMaterialQty = () => {
        const { SourceLocation, material } = this.state;
        const { Co } = this.props;

        axios.get(`${window["apiLocation"]}/api/InventoryLocationMaterial?Co=${Co}&Location=${encodeURIComponent(SourceLocation)}&Material=${encodeURIComponent(material.Material)}`).then((response) => {
            window["warn"]("stockQty aaa", response.data)
            this.setState({ ...this.state, material: {...this.state.material, StockQty: response.data.StockQty} })
            //this.setState({ StockQty: response.data.StockQty });
        });

    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e);
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>close</Icon>
                </IconButton>
            </>
        )
    }

    ToolbarContents = () => {
        const { gpsInventoryTransferDialog } = this.props;

        return (
            <Typography variant="subtitle1" color="inherit">
                {gpsInventoryTransferDialog.type === 'new' ? 'New GPS Inventory Transfer' : `GPS Inventory Transfer #${this.state.Transfer}`}
            </Typography>
        )
    }

    render() {
        const { classes, gpsInventoryTransferDialog, materials, inventoryLocations, inventoryManagerLocations, user, employees  } = this.props;
        let { Scope, isScanning, Data, searching, searchingWo, Scopes, SourceLocation, RequestedBy, woDescription, workOrder } = this.state;
        //let workOrder = Data.WorkOrder, scope = Data.Scope;

        const matAvatar = 'assets/images/logos/SP_Cog_Gradient.png';

        const draggableSelectImg = 'assets/images/draggable-selections/work-order-usage-select.png';
        const dialogProps = {
            ...gpsInventoryTransferDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "md",
            hideBackdrop: true,
            disableEnforceFocus: true
        }

        let header = gpsInventoryTransferDialog.type === 'new' ? 'New GPS Inventory Transfer' : `GPS Inventory Transfer ${this.state.Transfer}`;

        window["warn"]("wo usage dialog type", gpsInventoryTransferDialog.type, gpsInventoryTransferDialog.props.open, this.ref.current)

        

        //this.state.Status = 2

        // window["warn"]("gpsInventoryTransferDialog", this.state.Materials, this.props.gpsInventoryTransferDialog)

        let typeCheck = null;
        if(this.state.Type){
            typeCheck = this.state.Type;
        } else{
            if(this.state.Data.Lines.length > 0){
                typeCheck = this.state.Data.Lines[0].Type;
            } 
        }

        const approver = _.findIndex(inventoryManagerLocations, { Location: SourceLocation });

        let accepter = false;
        if(RequestedBy && user.UserName === RequestedBy){
            accepter = true
        }

        window["warn"]("gpsInventoryTransferDialog2", this.state.woList, this.state, inventoryManagerLocations, approver)

        //let woDescription = this.state.WorkOrder

        //this.state.Status = 0;

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={SlideUp}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={header}
                        type="GPS Inventory Transfer"
                        picRef={draggableSelectImg}
                        icon="send"
                        dialogState={this.state}
                        reopenDialogFxn={gpsInventoryTransferDialog.type === 'new' ? this.props.openNewGpsInventoryTransferDialog : this.props.openEditGpsInventoryTransferDialog}
                        description={this.state.Description}
                        storeDialog={gpsInventoryTransferDialog}
                        TypeState={gpsInventoryTransferDialog.type === 'new' && gpsInventoryTransferDialog.props.open === true ? true : this.state.Transfer}
                        screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >
                        <BarcodeScanner open={isScanning} onScan={this.getPart} onClose={() => { this.setState({ ...this.state, isScanning: false, }) }} message={this.state.scanMessage} />

                        <DialogContent ref={this.ref} classes={{ root: "p-24" }}>
                            <div className="md:flex">
                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">send</Icon>
                                </div>
                                <div className="md:flex w-full">
                                    <div className="md:flex md:w-1/2">
                                        <TextField
                                            className={classes.formControl}
                                            label="Source Location"
                                            id="SourceLocation"
                                            name="SourceLocation"
                                            value={this.state.SourceLocation}
                                            onChange={this.handleSourceLocationChange}
                                            InputLabelProps={{ shrink: this.state.SourceLocation ? true : false }}
                                            fullWidth
                                            variant="outlined"
                                            disabled={this.state.Status >= 1}
                                            select
                                            required
                                        >
                                            {inventoryLocations && inventoryLocations.map((value, index) => (
                                                <MenuItem key={index} value={value.Location}>
                                                    <div className="flex">
                                                        {`${value.Description}`}
                                                    </div>
                                                </MenuItem>
                                            ))
                                            }
                                        </TextField>
                                        
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="md:flex md:w-1/2">
                                        <TextField
                                            className={classes.formControl}
                                            label="Request Type"
                                            id="ype"
                                            name="Type"
                                            value={this.state.Type}
                                            onChange={this.handleTypeChange}
                                            InputLabelProps={{ shrink: this.state.Type ? true : false }}
                                            fullWidth
                                            variant="outlined"
                                            disabled={this.state.Status >= 1}
                                            select
                                            required
                                        >
                                            {this.state.typeOptions.map((value, index) => (
                                                <MenuItem key={index} value={value.value}>
                                                    <div className="flex">
                                                        {`${value.type}`}
                                                    </div>
                                                </MenuItem>
                                            ))
                                            }
                                        </TextField>
                                        
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    { typeCheck === "I" &&
                                    <div className="md:flex md:w-1/2">
                                        <TextField
                                            className={classes.formControl}
                                            label="New Location"
                                            id="NewLocation"
                                            name="NewLocation"
                                            value={this.state.NewLocation}
                                            onChange={this.handleNewLocationChange}
                                            InputLabelProps={{ shrink: this.state.NewLocation ? true : false }}
                                            fullWidth
                                            variant="outlined"
                                            disabled={this.state.Status >= 1}
                                            select
                                            required
                                        >
                                            {inventoryManagerLocations && inventoryManagerLocations.map((value, index) => (
                                                <MenuItem key={index} value={value.Location} disabled={value.Disabled}>
                                                    <div className="flex">
                                                        {`${value.Description}`}
                                                    </div>
                                                </MenuItem>
                                            ))
                                            }
                                        </TextField>
                                    </div>
                                    }
                                    { typeCheck === "W" &&
                                        <>
                                            <div className="md:flex md:w-1/2">
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Work Order"
                                                    options={this.state.woList.length > 0 ? this.state.woList : workOrder ? [{...workOrder}] : []}
                                                    //options={this.state.woList.length > 0 ? this.state.woList : []}
                                                    searchButton={true}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.WorkOrder}>
                                                            {`${value.WorkOrder} - ${(value.Data.WorkOrder ? value.Data.WorkOrder.Description : value.Description || '').substr(0, 50)}`}
                                                        </MenuItem>
                                                    }}
                                                    debounce={750}
                                                    onChange={(text) => this.setState({ ...this.state, woDescription: text }, this.handleWOSearch)}
                                                    onBlur={() => this.setState({ ...this.state, searchingWo: false })}
                                                    search={woDescription || ''}
                                                    //value={this.state.WorkOrder || ''}
                                                    value={this.state.WorkOrder}
                                                    portal={true}
                                                    noflip={true}
                                                    autoFocus={true}
                                                    onSelect={(option) => this.setState({ ...this.state, WorkOrder: option.WorkOrder, Scopes: option.Data.WorkOrder.Data.Scopes})}
                                                    required
                                                    disabled={this.state.Status >= 1}
                                                />
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                            </div>
                                            <div className="md:flex md:w-1/2">
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Scope"
                                                    id="Scope"
                                                    name="Scope"
                                                    value={this.state.Scope}
                                                    //onChange={this.handleNewLocationChange}
                                                    InputLabelProps={{ shrink: this.state.Scope ? true : false }}
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={this.state.Status >= 1}
                                                    select
                                                    required
                                                >
                                                    {Scopes && Scopes.map((value, index) => (
                                                        <MenuItem key={index} value={value.Scope}>
                                                            <div className="flex">
                                                                {`${value.Scope} - ${value.Description}`}
                                                            </div>
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </TextField>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="md:flex" style={{ display: this.state.Status >= 1 ? 'none' : 'flex' }}>
                                {/* {(this.state.Type !== 2 || (this.state.Type === 2 && !this.state.Material || this.state.Material === '')) &&
                                    <div onClick={() => { if (this.state.Type === 2) { this.setState({ ...this.state, isScanning: true }); } }} className="min-w-48 pb-12 md:pb-4  p-0 md:pt-20 cursor-pointer">
                                        <Icon color={this.state.Type === 2 ? "error" : "action"}>crop_free</Icon>
                                    </div>
                                }
                                {
                                    (this.state.Type === 2 && this.state.Material && this.state.Material !== '') &&
                                    <div onClick={this.handleMaterialSearch} className="min-w-48 pb-12 md:pb-4  p-0 md:pt-20 cursor-pointer">
                                        <Icon color={this.state.Type === 2 ? "error" : "action"}>search</Icon>
                                    </div>
                                } */}

                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">store</Icon>
                                </div>
                                <div className="md:flex w-full">
                                    <div className="md:flex md:w-1/4">
                                        <TextField
                                            className={classes.formControl}
                                            label="Part #"
                                            id="material"
                                            name="Material"
                                            value={this.state.material.Material || ''}
                                            onChange={this.handleMaterialChange}
                                            onBlur={() => this.getPart()}
                                            InputLabelProps={{ shrink: this.state.material.Material ? true : false }}
                                            fullWidth
                                            variant="outlined"
                                            //disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="md:flex md:w-1/6">
                                        <TextField
                                            className={classes.formControl}
                                            label="Qty"
                                            id="qty"
                                            name="Qty"
                                            value={this.state.material.Qty || ''}
                                            onChange={this.handleMaterialChange}
                                            InputLabelProps={{ shrink: this.state.material.Qty ? true : false }}
                                            InputProps={{
                                                endAdornment: this.state.material && this.state.material.StockQty ?
                                                    <InputAdornment position="end">{`/${this.state.material.StockQty}`}</InputAdornment>
                                                    :
                                                    null
                                            }}
                                            inputProps={{ max: this.state.material.StockQty ? this.state.material.StockQty : null }}
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            //disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                            required
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="md:flex md:w-1/2">
                                        {searching && this.state.material.Description && this.state.material.Description.length > 1 ?
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Description"
                                                options={materials}
                                                searchButton={true}
                                                menuItemComponent={(value) => {
                                                    return value.ActiveYN === "Y" &&
                                                        <MenuItem value={value.Material}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Material} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): matAvatar)} />
                                                                {`${(value.Description || '').substr(0, 50)}`}
                                                            </div>
                                                        </MenuItem>
                                                }}
                                                debounce={750}
                                                onChange={(text) => this.setState({ ...this.state, Description: text, material: {...this.state.material, Description: text} })}
                                                onBlur={() => { if (!this.state.Material) { this.setState({ ...this.state, searching: false }) } }}
                                                search={this.state.material.Description}
                                                value={this.state.material.Material}
                                                portal={true}
                                                noflip={true}
                                                onSelect={(option) => this.setState({ ...this.state, material: { Material: option.Material, Description: option.Description, Qty: this.state.material.Qty ? this.state.material.Qty : 1 }, Material: option.Material, Description: option.Description, Qty: this.state.Qty ? this.state.Qty : 1, searching: false }, this.searchMaterialQty)}
                                                required
                                            /> :
                                            <form className="w-full" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); setTimeout(() => this.setState({ searching: true }), 300) }}>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Description"
                                                    id="description"
                                                    name="Description"
                                                    value={this.state.material.Description || ''}
                                                    onChange={this.handleMaterialChange}
                                                    InputProps={{
                                                        endAdornment: !this.state.Material ?
                                                            <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: true, })} style={{ cursor: 'pointer' }}>search</Icon></InputAdornment>
                                                            :
                                                            <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: false, material: newGpsInventoryTransferState.material })} style={{ cursor: 'pointer' }}>close</Icon></InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: this.state.material.Description ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    //disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                                />
                                            </form>
                                        }
                                    </div>
                                    <div className="hidden md:block min-w-12 pt-20">
                                    </div>
                                    {!this.state.material.ID && !this.state.material.tempID ?
                                        <div className="md:flex md:w-1/4">
                                        <Button
                                            className={classNames(classes.formControl, "w-full")}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                let { Co, material, SourceLocation, NewLocation, Employee, Status, Type, WorkOrder, Scope } = this.state;
                                                material.Co = Co;
                                                material.SourceLocation = SourceLocation;
                                                material.NewLocation = NewLocation;
                                                material.WorkOrder = WorkOrder;
                                                material.Scope = Scope;
                                                material.Employee = Employee;
                                                material.Status = Status;
                                                material.Type = Type;
                                                material.AcceptedQty = null;
                                                material.ApprovedQty = null;
                                                window["warn"]("material state 111", material)
                                                this.props.addMaterialToTransfer(material);
                                                this.setState({ ...this.state, material: newGpsInventoryTransferState.material })
                                            }}
                                            disabled={!this.canMaterialBeSubmitted()}
                                        >
                                            Add
                                        </Button></div> :
                                        <div className="md:flex md:w-1/4">
                                            <Button
                                                className={classNames(classes.formControl, classes.updateMember, "w-full")}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    const { Co, material } = this.state;
                                                    this.props.updateMaterialToTransfer(material);
                                                    this.setState({ ...this.state, material: newGpsInventoryTransferState.material })
                                                }}
                                            // disabled={!this.canMemberBeSubmitted()}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                className={classNames(classes.formControl, classes.deleteMember)}
                                                variant="contained"
                                                onClick={() => {
                                                    let { Co, material } = this.state;
                                                    if(this.state.Status === 0 && !this.state.Transfer ){
                                                        this.props.removeMaterialToTransfer(material);
                                                    } else{
                                                        material.DeleteYN = "Y";
                                                        this.props.updateMaterialToTransfer(material);
                                                    }
                                                    
                                                    this.setState({ ...this.state, material: newGpsInventoryTransferState.material })
                                                }}
                                            // disabled={!this.canMemberBeSubmitted()}
                                            >
                                                <Icon>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>

                            <TransferMaterialsList onSelectedMaterial={this.setMaterial} material={{ ...this.state }} handleMaterialChange={this.handleMaterialChange.bind(this)} />
                        </DialogContent>

                        <DialogActions className="dialog-actions justify-between pl-16">
                            {this.state.Status === 0 && !this.state.Transfer ? (
                                <React.Fragment>
                                    <DebounceButton
                                        buttonText={"Save"}
                                        processingText={"Saving"}
                                        isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            const { Co, Status, SourceLocation, NewLocation, Type, Data} = this.state;
                                            //let Data = { Lines: Lines }
                                            this.props.addInventoryTransfer({Co, Status, SourceLocation, NewLocation, Data});
                                            this.closeComposeDialog();
                                        }}
                                        debounceInterval={1000}
                                    />
                                </React.Fragment>
                            ) : this.state.Status === 0 && this.state.Transfer ? (
                                <React.Fragment>
                                    <DebounceButton
                                        buttonText={"Update"}
                                        processingText={"Updating"}
                                        //isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            const { Co, Status, SourceLocation, NewLocation, Transfer, Data, ID} = this.state;
                                            //let Data = { Lines: Lines }
                                            this.props.updateInventoryTransfer({Co, Status, SourceLocation, NewLocation, Data, ID, Transfer}, "update");
                                            this.closeComposeDialog();
                                        }}
                                        debounceInterval={1000}
                                    />
                                    { accepter &&
                                    <DebounceButton
                                        buttonText={"Submit"}
                                        processingText={"Submitting"}
                                        //isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            const { Co, SourceLocation, NewLocation, Transfer, ID} = this.state;
                                            let Status = 1;
                                            let { Data } = this.state;
                                            let newLines = [...Data.Lines];
                                            newLines.map((Line) => {
                                                Line.Status = 1;
                                            })
                                            Data.Lines = newLines;
                                            this.props.updateInventoryTransfer({Co, Status, SourceLocation, NewLocation, Data, Transfer, ID}, "submit");
                                            this.closeComposeDialog();
                                        }}
                                        debounceInterval={1000}
                                    />}
                                </React.Fragment>
                            ) : this.state.Status === 1 && approver > -1 ? (
                                <React.Fragment>
                                    <div></div>
                                    <DebounceButton
                                        buttonText={"Approve"}
                                        processingText={"Approving"}
                                        //isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            const { Co, SourceLocation, NewLocation, Transfer, ID} = this.state;
                                            let Status = 2;
                                            let { Data } = this.state;
                                            let newLines = [...Data.Lines];
                                            newLines.map((Line) => {
                                                Line.Status = 2;
                                                if(!Line.ApprovedQty){
                                                    Line.ApprovedQty = Line.Qty
                                                }
                                            })
                                            Data.Lines = newLines;
                                            this.props.updateInventoryTransfer({Co, Status, SourceLocation, NewLocation, Data, Transfer, ID}, "approve");
                                            this.closeComposeDialog();
                                        }}
                                        debounceInterval={1000}
                                    />
                                </React.Fragment>
                            ) : this.state.Status === 2 && accepter ? (
                                <React.Fragment>
                                    <div></div>
                                    <DebounceButton
                                        buttonText={"Accept"}
                                        processingText={"Accepting"}
                                        //isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            const { Co, SourceLocation, NewLocation, Transfer, ID} = this.state;
                                            let Status = 3;
                                            let { Data } = this.state;
                                            let newLines = [...Data.Lines];
                                            newLines.map((Line) => {
                                                Line.Status = 3;
                                                if(!Line.AcceptedQty){
                                                    Line.AcceptedQty = Line.ApprovedQty
                                                }
                                            })
                                            Data.Lines = newLines;
                                            window["warn"]("AcceptedQty ttt", newLines)
                                            this.props.updateInventoryTransfer({Co, Status, SourceLocation, NewLocation, Data, Transfer, ID}, "accept");
                                            this.closeComposeDialog();
                                        }}
                                        debounceInterval={1000}
                                    />
                                </React.Fragment>
                            ) : (
                                <></>
                            )
                            // : (
                            //     <React.Fragment>
                            //         <IconButton
                            //             onClick={() => {
                            //                 //removeWorkOrderUsage(this.state);
                            //                 this.closeComposeDialog();
                            //             }}
                            //             //disabled={!this.state.ID || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                            //         >
                            //             <Icon>delete</Icon>
                            //         </IconButton>
                            //     </React.Fragment>
                            // )
                            }
                        </DialogActions>
                    </DraggableDialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditGpsInventoryTransferDialog: Actions.closeEditGpsInventoryTransferDialog,
        closeNewGpsInventoryTransferDialog: Actions.closeNewGpsInventoryTransferDialog,
        openEditGpsInventoryTransferDialog: Actions.openEditGpsInventoryTransferDialog,
        openEditMaterialDialog,
        openNewGpsInventoryTransferDialog: Actions.openNewGpsInventoryTransferDialog,
        addMaterialToTransfer,
        updateMaterialToTransfer,
        removeMaterialToTransfer,
        addInventoryTransfer,
        updateInventoryTransfer,
    }, dispatch);
}

function mapStateToProps({ gpsInventoryTransfersApp, workOrdersApp, workOrderScopesApp, spReducers }) {
    return {
        gpsInventoryTransferDialog: gpsInventoryTransfersApp.gpsInventoryTransfers.gpsInventoryTransferDialog,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        inventoryLocations: spReducers.inventoryLocations,
        employees: spReducers.employees,
        materials: spReducers.materials.materials,
        inventoryManagerLocations: spReducers.inventoryManagerLocations,
        technician: spReducers.technician.technician,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(GpsInventoryTransferDialog));
