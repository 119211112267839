import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    avatarRoot: {
        height: 24,
        width: 24,
    }

});

class InventoryManagerList extends Component {

    state = {
        selectedUserRolesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedUserRoleMenu = (event) => {
        this.setState({ selectedUserRolesMenu: event.currentTarget });
    };

    closeSelectedUserRolesMenu = () => {
        this.setState({ selectedUserRolesMenu: null });
    };

    render() {
        const { classes, securables, userData } = this.props;
        // const data = this.getFilteredArray((!this.props.selectedUserRolesData ? (!this.props.userRoleData ? [] : this.props.userRoleData) : this.props.selectedUserRolesData), searchText);
        const data = securables;
        const avatar = 'assets/images/avatars/profile.jpg';

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Inventory Managers</Typography>
                    <ReactTable
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    this.props.onSelected(rowInfo.original);
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Name",
                                accessor: "UserName",
                                Cell: row => {
                                    const user = _.find(userData, { UserName: row.original.UserName });
                                    if (user) {
                                        return (
                                            <React.Fragment>
                                                <Avatar className="mr-8 ml-8" classes={{ root: classes.avatarRoot }} alt={user.FirstName} src={(user.Data.Avatar ? getProfileImage(`ID=${user.Data.Avatar}&Thumb=true` ): avatar)} />
                                                {`${user.FirstName} ${user.LastName}`}
                                            </React.Fragment>
                                        )
                                    }
                                },
                                className: "font-bold justify-center",
                                width: 256,
                            },
                            {
                                Header: "UserName",
                                accessor: "UserName",
                                //filterable: true,
                                className: "font-bold justify-center",
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Users found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ userRolesApp, spReducers }) {
    return {
        userRoles: userRolesApp.userRoles.entities,
        selectedUserRoleIds: userRolesApp.userRoles.selectedUserRoleIds,
        searchText: userRolesApp.userRoles.searchText,
        user: userRolesApp.user,
        userRoleData: spReducers.userRoles,
        userData: spReducers.userProfiles.Users
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryManagerList)));
