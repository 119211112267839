import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedChecklistIds: [],
    routeParams: {},
    checklistDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    checklistSectionDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    checklistItemDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    checklistAssignmentDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
};

const checklistsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.OPEN_NEW_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'data',
                        props: {
                            ...state.checklistDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_CHECKLIST_DIALOG:
            {
                return {
                    ...state,
                    checklistDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: action.data,
                    }
                };
            }
        case Actions.CLOSE_NEW_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: 'data',
                        props: {
                            ...state.checklistSectionDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_CHECKLIST_SECTION_DIALOG:
            {
                return {
                    ...state,
                    checklistSectionDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                    }
                };
            }
        case Actions.CLOSE_NEW_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'data',
                        props: {
                            ...state.checklistItemDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_CHECKLIST_ITEM_DIALOG:
            {
                return {
                    ...state,
                    checklistItemDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
            case Actions.OPEN_NEW_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'new',
                            props: {
                                open: true
                            },
                            data: action.data,
                        }
                    };
                }
            case Actions.CLOSE_NEW_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'new',
                            props: {
                                open: false
                            },
                            data: null
                        }
                    };
                }
            case Actions.OPEN_EDIT_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'edit',
                            props: {
                                open: true
                            },
                            data: action.data,
                            loading: true,
                        }
                    };
                }
            case Actions.DATA_EDIT_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'data',
                            props: {
                                ...state.checklistAssignmentDialog.props
                            },
                            data: action.data
                        }
                    };
                }
            case Actions.ADD_EDIT_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'add',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
            case Actions.UPDATE_EDIT_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
            case Actions.CLOSE_EDIT_CHECKLIST_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        checklistAssignmentDialog: {
                            type: 'edit',
                            props: {
                                open: false
                            },
                            data: null
                        }
                    };
                }
        default:
            {
                return state;
            }
    }
};

export default checklistsReducer;
