import axios from 'axios'
import { getUserData } from 'main/content/apps/work-order-usages/store/actions/user.actions'
import { showMessage, setOfflineData, guid } from 'store/actions'

export const OPEN_NEW_GPS_INVENTORY_TRANSFER_DIALOG =
  '[GPS INVENTORY TRANSFER APP] OPEN NEW GPS INVENTORY TRANSFER DIALOG'
export const CLOSE_NEW_GPS_INVENTORY_TRANSFER_DIALOG =
  '[GPS INVENTORY TRANSFER APP] CLOSE NEW GPS INVENTORY TRANSFER DIALOG'
export const OPEN_EDIT_GPS_INVENTORY_TRANSFER_DIALOG =
  '[GPS INVENTORY TRANSFER APP] OPEN EDIT GPS INVENTORY TRANSFER DIALOG'
export const UPDATE_EDIT_GPS_INVENTORY_TRANSFER_DIALOG =
  '[GPS INVENTORY TRANSFER APP] UPDATE EDIT GPS INVENTORY TRANSFER DIALOG'
export const CLOSE_EDIT_GPS_INVENTORY_TRANSFER_DIALOG =
  '[GPS INVENTORY TRANSFER APP] CLOSE EDIT GPS INVENTORY TRANSFER DIALOG'
export const ADD_MATERIAL_TO_TRANSFER =
  '[GPS INVENTORY TRANSFER APP] ADD MATERIAL TO TRANSFER'
export const UPDATE_MATERIAL_TO_TRANSFER =
  '[GPS INVENTORY TRANSFER APP] UPDATE MATERIAL TO TRANSFER'
export const REMOVE_MATERIAL_TO_TRANSFER =
  '[GPS INVENTORY TRANSFER APP] REMOVE MATERIAL TO TRANSFER'
export const ADD_INVENTORY_TRANSFER =
  '[GPS INVENTORY TRANSFER APP] ADD INVENTORY TRANSFER'
export const UPDATE_INVENTORY_TRANSFER =
  '[GPS INVENTORY TRANSFER APP] UPDATE INVENTORY TRANSFER'

export function openNewGpsInventoryTransferDialog(data) {
  window['warn']('openNewGpsInventoryTransferDialog ran!', data)
  return {
    type: OPEN_NEW_GPS_INVENTORY_TRANSFER_DIALOG,
    data,
  }
}

export function closeNewGpsInventoryTransferDialog() {
  return {
    type: CLOSE_NEW_GPS_INVENTORY_TRANSFER_DIALOG,
  }
}

export function openEditGpsInventoryTransferDialog1(data) {
  return {
    type: OPEN_EDIT_GPS_INVENTORY_TRANSFER_DIALOG,
    data,
  }
}

export function openEditGpsInventoryTransferDialog(data) {
  return (dispatch, getState) => {
    window['warn']('openEditGpsInventoryTransferDialog ran!', data)
    let request
    if (data.Transfer) {
      const { Co, Transfer } = data
      request = axios.get(
        `${window['apiLocation']}/api/InventoryTransfer?Co=${encodeURIComponent(
          Co,
        )}&Transfer=${Transfer}`,
      )
    } else if (data.ID) {
      const { ID } = data
      request = axios.get(
        `${window['apiLocation']}/api/InventoryTransfer?ID=${ID}`,
      )
    }

    if (!request) {
      Promise.all([
        dispatch(
          showMessage({
            message: `Error: Invalid data provided`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        ),
      ])
    }

    return request.then(response => {
      let NewLocation = response.data.Data.Lines[0].NewLocation

      Promise.all([
        dispatch({
          type: OPEN_EDIT_GPS_INVENTORY_TRANSFER_DIALOG,
          data: { ...response.data, NewLocation },
        }),
      ])
    })
  }
}

export function updateEditGpsInventoryTransferDialog(
  data,
  taxability,
  readOnly,
) {
  return {
    type: UPDATE_EDIT_GPS_INVENTORY_TRANSFER_DIALOG,
    data,
    taxability,
    readOnly,
  }
}

export function closeEditGpsInventoryTransferDialog() {
  return {
    type: CLOSE_EDIT_GPS_INVENTORY_TRANSFER_DIALOG,
  }
}

export function addMaterialToTransfer(material) {
  let data = material
  material.tempID = Date.now()
  window['warn']('addMaterialToTransfer', material)
  return {
    type: ADD_MATERIAL_TO_TRANSFER,
    data,
  }
}

export function updateMaterialToTransfer(material) {
  let data = material
  return {
    type: UPDATE_MATERIAL_TO_TRANSFER,
    data,
  }
}

export function removeMaterialToTransfer(material) {
  let data = material
  return {
    type: REMOVE_MATERIAL_TO_TRANSFER,
    data,
  }
}

export function addInventoryTransfer(newInventoryTransfer) {
  return (dispatch, getState) => {
    let data = { ...newInventoryTransfer }
    data.Data.Lines.map(Line => {
      if (Line.tempID) {
        delete Line.tempID
      }
    })

    const url = `${window['apiLocation']}/api/InventoryTransfer`
    // const request = axios.post(url,
    //     { ...newInventoryTransfer }
    // );

    const request = axios.post(url, data)

    window['warn']('addInventoryTransfer', data)

    return request
      .then(response => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch({
            type: ADD_INVENTORY_TRANSFER,
            data: response.data,
          })
          dispatch(
            showMessage({
              message: `Inventory Transfer #${response.data.Transfer} has been successfully created.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
      .catch(error => {
        console.warn('Error adding transfer: ', error, request)
        if (error.message) {
          dispatch(
            showMessage({
              message: `${error.message}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'offline',
            }),
          )
        }
        if (error.request) {
          const offlineData = {
            ...newInventoryTransfer,
            OfflineID: guid(),
            Data: { ErrMsg: null },
          }
          Promise.all([
            dispatch(
              setOfflineData(
                'ADD',
                'post',
                url,
                offlineData,
                'inventory-transfers',
              ),
            ),
          ]).then(() => {
            dispatch({
              type: ADD_INVENTORY_TRANSFER,
              data: offlineData,
            })
          })
        }
      })
  }
}

export function updateInventoryTransfer(data, Type) {
  return (dispatch, getState) => {
    const { Co, Transfer } = data

    let newData = { ...data }
    newData.Data.Lines.map(Line => {
      if (Line.tempID) {
        delete Line.tempID
      }
    })

    const url = `${window['apiLocation']}/api/InventoryTransfer?Co=${Co}&Transfer=${Transfer}`
    const request = axios.put(url, newData)

    window['warn']('updateInventoryTransfer', newData)

    return request.then(response => {
      Promise.all([
        dispatch({
          type: UPDATE_INVENTORY_TRANSFER,
          data: response.data,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Inventory Transfer #${Transfer} has been successfully ${Type}ed.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
    })
  }
}
