import _ from '@lodash';
import { Capacitor } from '@capacitor/core';
import { Button, Card, CardContent, CardHeader, CircularProgress, Hidden, Icon, Link, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import * as DialerActions from 'main/dialer/store/actions';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditTripDialog, openNewTripDialog } from '../trips/store/actions';
import { openEditWorkOrderScopeDialog } from '../work-order-scopes/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import { addAlert } from 'store/actions';
import { formatPhone } from 'main/functions';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {

    },
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    avatarTech: {
        backgroundColor: theme.palette.primary[500],
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    card: {
        marginTop: 8,
        marginBottom: 4,
        cursor: 'pointer',
        width: '100%',
        border: '1px solid lightgrey',
        borderBottom: 'none'
    },
    placeholder: {
        marginTop: 8,
        marginBottom: 4,
        cursor: 'pointer',
        width: '100%',
        border: '2px dashed lightgrey',
        // background: 'rgba(0,0,0,.015)',
        boxShadow: 'none'
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    tabs: {
        width: '100%',
    },
    tab: {
        minWidth: '25%',
        maxWidth: '25%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    campaign: {
        width: 'auto',
        top: 0,
        right: -5,
        marginTop: 5,
        borderRadius: 4,
        padding: 10,
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    tripAdminTab: {
        minWidth: '20%',
        maxWidth: '20%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    container: {
        padding: 0,
    },
    trip: {
        minWidth: 300,
    },
    tripTabContent: {
        minHeight: 175,
        // maxHeight: 175,
        overflow: 'auto',
    },
    formControl: {
        marginBottom: 12
    },
    footer: {
        top: 'auto',
        bottom: 0,
    },
    scopeBadge: {
        top: 5,
        right: 2,
    },
    tripBadge: {
        top: 35,
        right: 35,
        background: '#3f3f3f',
    },
    tripBadgeIcon: {
        fontSize: 14,
        marginTop: 1,
    },
    tripCountBadge: {
        top: 15,
        right: -7,
        height: 17,
        minWidth: 17,
        fontSize: '1rem',
        border: '2px solid #3f3f3f',
        paddingTop: 2,
    },
    scopeAvatar: {
        width: 20,
        height: 20,
        fontSize: 11,
        marginRight: 4,
    },
    assignmentAvatar: {
        width: 20,
        height: 20,
        fontSize: 11,
        marginRight: 4,
        backgroundColor: theme.palette.primary.main,
    }
});

const statusProps =
    [
        { text: 'Scheduled', icon: 'access_time', color: 'rgb(100, 100, 200)', dateProp: 'ScheduledDate', timeProp: 'ScheduledTime' },
        { text: 'Notified', icon: 'offline_bolt', color: 'rgb(0, 150, 250)', dateProp: 'NotifiedDate', timeProp: 'NotifiedTime' },
        { text: 'Accepted', icon: 'offline_pin', color: 'rgb(0, 50, 250)', dateProp: 'AcceptedDate', timeProp: 'AcceptedTime' },
        { text: 'Rejected', icon: 'cancel', color: 'rgb(210, 0, 0)', dateProp: 'RejectedDate', timeProp: 'RejectedTime' },
        { text: 'En-Route', icon: 'explore', color: 'rgb(50, 170, 200)', dateProp: 'EnRouteDate', timeProp: 'EnRouteTime' },
        { text: 'Arrived', icon: 'my_location', color: 'rgb(50, 150, 100)', dateProp: 'ArrivedDate', timeProp: 'ArrivedTime' },
        { text: 'In Progress', icon: 'play_circle_outline', color: 'rgb(150, 200, 50)', dateProp: 'InProgressDate', timeProp: 'InProgressTime' },
        { text: 'On Hold', icon: 'pause_circle_outline', color: 'rgb(210, 100, 100)', dateProp: 'OnHoldDate', timeProp: 'OnHoldTime' },
        { text: 'Completed', icon: 'check_circle', color: 'rgb(100, 100, 100)', dateProp: 'CompletedDate', timeProp: 'CompletedTime' }
    ];

class TechnicianTrip extends Component {
    state = {
        open: false,
        value: 2,
        tripValue: 2,
        tcPrompt: false,
        fetching: false,
        updating: false,
    }
    //componentDidMount = () => {
    //    const { workOrder } = this.props;
    //    if (workOrder && !_.isEqual(workOrder, this.state)) {
    //        this.setState(workOrder);
    //    }
    //}

    componentDidMount() {
        let { tripValue, tcPrompt } = this.state;
        let { workOrder, technician, trip, date, user } = this.props;
        const { ID, Co, WorkOrder, open } = workOrder;
        const ScheduledDate = this.formatDateInput(date);
        let ScheduledTime = `${ScheduledDate} 00:00:00`;
        if (!technician) {
            tripValue = 0;
        }
        let tech = null;
        if (!trip) {
            let Duration = 0;
            for (var i = 0; i < workOrder.Data.Scopes.length; i++) {
                const service = _.find(this.props.services, { Service: workOrder.Data.Scopes[i].Service });
                const arrival = _.find(this.props.arrivalWindows, { ArrivalWindow: workOrder.Data.Scopes[i].ArrivalWindow });
                tech = workOrder.Data.Scopes[i].LeadTechnician;
                Duration += (service && service.DefaultDuration ? service.DefaultDuration : 1);
                if (arrival) {
                    if (i == 0) {
                        ScheduledTime = `${ScheduledDate} ${arrival.StartTime}`;
                    } else {
                        if (moment(`${ScheduledDate} ${arrival.StartTime}`).toDate() < moment(ScheduledTime).toDate())
                            ScheduledTime = `${ScheduledDate} ${arrival.StartTime}`;
                    }
                }
            }
            trip = { ID, Co, WorkOrder, Technician: tech, Status: 0, ScheduledDate, ScheduledTime, Duration, CreatedBy: user.username, CreatedDate: new Date(), Data: { WorkOrder: workOrder, Tags: [], }, row: 0 }
        }
        if (workOrder && !_.isEqual(workOrder, this.state)) {
            window["log"]('Setting Work Order List Item');
            this.setState({ ...workOrder, technician, trip, date, open, tripValue, tcPrompt });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { tripValue, tcPrompt } = this.state;
        let { workOrder, technician, date, trip, user } = this.props;
        const { ID, Co, WorkOrder, open } = workOrder;
        const ScheduledDate = !trip ? this.formatDateInput(date) : this.formatDateInput(trip.ScheduledDate);
        const ScheduledTime = !trip ? `${ScheduledDate} 00:00:00` : trip.ScheduledTime;
        if (!technician) {
            tripValue = 0;
        }
        let trp = trip ? trip : { ID, Co, WorkOrder, Technician: null, Status: 0, ScheduledDate, ScheduledTime, Duration: trip ? trip.Duration : 1.0, CreatedBy: user.username, CreatedDate: new Date(), Data: { WorkOrder: workOrder, Tags: [], }, row: 0 };
        if (workOrder && (!_.isEqual(workOrder, prevProps.workOrder) || !_.isEqual(technician, prevProps.technician) || !_.isEqual(trip, prevProps.trip))) {
            window["log"]('Updating Work Order List Item', workOrder, prevProps.workOrder);
            this.setState({ ...workOrder, technician, trip: trp, date, open, tcPrompt, value: workOrder.value > 2 ? prevProps.workOrder.value : workOrder.value, tripValue });
        }
    }

    handleChange = (event, value) => {
        const { workOrder } = this.props;
        if (this.state.value !== value) {
            this.setState({ value });
        }
    };

    handleOpen = () => {
        let { open } = this.state;
        open = !open;
        this.setState({ open }, () => { if (this.props.clickHandler) { this.props.clickHandler(open ? this.props.trip : null) } });
    }

    handleAdminChange = (event, tripValue) => {
        const { workOrder, technician, } = this.props;
        const { trip } = this.state;
        const { ID, Co, WorkOrder } = workOrder;
        if (tripValue < 4) {
            this.setState({ tripValue });
        } else {
            if (!technician && trip.Technician) {
                this.props.createTrip(trip);
                const newTrip = { ...trip, Technician: null, };
                this.setState({ trip: newTrip });
            } else {
                if (technician)
                    this.props.onUpdate(trip);
            }
        }
    };

    handleAdminTripChange = (event) => {
        let { trip, tripValue } = this.state;
        _.set(trip, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value);
        tripValue += tripValue < 3 ? 1 : 0;
        this.setState({ ...this.state, trip, tripValue });
    };

    handleAdminTripDateChange = (event) => {
        let { trip, tripValue } = this.state;
        window["warn"](event);
        trip.ScheduledDate = this.formatDate(event._d);
        trip.ScheduledTime = event._d.toLocaleString('en-US');
        tripValue += tripValue < 3 ? 1 : 0;
        this.setState({ ...this.state, trip, tripValue }, () => { if (this.props.onUpdate) { this.props.onUpdate(this.state.trip) } });
    };

    renderDateLabel = (e) => {
        window["log"](e);
        return (
            <TextField
                style={{ marginBottom: 12 }}
                label="Scheduled Date/Time"
                id="scheduledDate"
                name="ScheduledDate"
                onClick={(e) => { this.refs.datePicker.open(e); }}
                inputProps={
                    {
                        style: {
                            padding: 12
                        }
                    }
                }
                value={e.value}
                variant="outlined"
                fullWidth
                disabled={true}
            />
        )
    };

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate().toString();
        const yyyy = dt.getFullYear().toString();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatDateInput(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    dragStart = (event) => {
        event.dataTransfer.setData('text/plain', JSON.stringify(this.state));
        this.props.setDragging(true);
    }

    dragEnd = () => {
        this.props.setDragging(false);
    }

    getDateTime(date, time) {
        const dt = new Date(date);
        const tm = new Date(time);
        return dt.setTime(tm);
    }

    stop = (event) => {
        event.stopPropagation();
    }

    handleCall = (phone) => {
        const { toggleDialer, initCall, dialer } = this.props;
        if (phone && phone.length === 7 || phone.length === 10 || phone.length === 12) {
            toggleDialer(true);
            initCall(dialer, {
                destinationNumber: phone,
                audio: true,
                video: false,
            });
        }
    }

    formatPhoneLink(str) {
        const phone = str ? str.replace(/\(|\)|,|\.|-/g, '') : '';
        return `+1-${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    formatPhone(str) {
        return formatPhone(str).formatted;
    }

    updateStatus = (trip) => {
        const nextStatus = trip ? _.find(this.props.company.Data.TripStatusTerminologies, (o) => o.Status > trip.Status && o.QuickChangeYN === 'Y') : null;
        if (nextStatus) {
            this.setState({ ...this.state, updating: true }, () => {
                const Status = nextStatus.Status;
                trip.Status = Status;
                if (Status > 4 && Status < 7) {
                    trip.BoardTime = new Date().toLocaleString('en-US');
                }
                Promise.all([
                    this.props.onUpdate({ ...trip })
                ]).then(() => {
                    this.setState({ ...this.state, updating: false, tcPrompt: trip.Status === 8 });
                    if (trip.Status === 4) {
                        const { Address1, City, State, Zip } = trip.Data.WorkOrder.Data.Site;
                        const { WorkOrder } = trip;
                        this.props.addAlert({
                            open: true,
                            title: 'En-Route to Work Order',
                            type: 'TripDirections',
                            data: { Address1, City, State, Zip, WorkOrder, platform: Capacitor.getPlatform() },
                            icon: "directions",
                            cancelText: "Dismiss",
                            hideConfirm: true
                        });
                    }
                })
            });
        }
    }

    openDirections = (trip) => {
        const { Data } = trip.Data.WorkOrder;
        if (Data.Site) {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`)}`);
        }
    }

    handleCall = (phone) => {
        const { toggleDialer, initCall, dialer } = this.props;
        if (phone && phone.length === 7 || phone.length === 10 || phone.length === 12)
            toggleDialer(true);
        initCall(dialer, {
            destinationNumber: phone,
            audio: true,
            video: false,
        });
    }

    addHours = (dt, h) => {
        const tm = new Date(dt);
        tm.setTime(tm.getTime() + (h * 60 * 60 * 1000));
        return tm;
    }

    render() {
        const { classes, openWorkOrder, dialer, workOrders, technician, readOnly, hold, capacity, search, supervisor } = this.props;
        const workOrder = this.state;
        const wo = _.find(workOrders, { WorkOrder: workOrder.WorkOrder });
        if (wo && wo.Data && !workOrder.Data) {
            workOrder.Data = wo.Data;
        }
        const { trip, open, fetching, updating } = this.state;
        const canceled = trip && trip.Data && trip.Data.Assignments && trip.Data.WorkOrder && trip.Data.WorkOrder.Data && trip.Data.WorkOrder.Data.Scopes && _.filter(trip.Data.Assignments, (o) => _.filter(trip.Data.WorkOrder.Data.Scopes, (s) => { return s.Scope === o.Scope && s.Status < 4 }).length > 0).length < 1;

        const override = trip ? _.find(this.props.company.Data.TripStatusTerminologies, { Status: trip.Status }) : null;
        const nextStatus = trip ? _.find(this.props.company.Data.TripStatusTerminologies, (o) => o.Status > trip.Status && o.QuickChangeYN === 'Y') : null;
        if (!workOrder.ID) {
            return (<div></div>);
        }

        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }

        const contact = {
            Name: null,
            Phone: null
        };
        if (workOrder.Data.Contact) {
            contact.Name = workOrder.Data.Contact.Name;
            if (workOrder.Data.Contact.Phone) {
                contact.Phone = workOrder.Data.Contact.Phone;
            }
        } else {
            if (workOrder.Contact) {
                contact.Name = workOrder.Contact;
            } else {
                if (workOrder.Data.Site && workOrder.Data.Site.Contact) {
                    contact.Name = workOrder.Data.Site.Contact;
                } else {
                    if (workOrder.Data.Customer && workOrder.Data.Customer.FirstName) {
                        contact.Name = `${workOrder.Data.Customer.FirstName} ${workOrder.Data.Customer.LastName}`;
                    } else {
                        contact.Name = workOrder.Data.Customer.Name
                    }
                }
            }
            if (workOrder.ContactPhone) {
                contact.Phone = workOrder.ContactPhone;
            } else {
                if (workOrder.Data.Site && workOrder.Data.Site.Phone) {
                    contact.Phone = workOrder.Data.Site.Phone;
                } else {
                    if (workOrder.Data.Customer && workOrder.Data.Customer.Phone) {
                        contact.Phone = workOrder.Data.Customer.Phone;
                    } else {
                        contact.Phone = '';
                    }
                }
            }
        }
        if (trip.Status === 0) {
            return (
                <Card onClick={this.stop} onMouseDown={this.stop} onMouseMove={this.stop} className={classNames(classes.placeholder, "dispatch-work-order rounded-lg")}>
                    <CardHeader className={classNames("p-12 relative", "highlight", technician ? classes.trip : '')}
                        classes={
                            { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classes.subheader, }
                        }
                        avatar={
                            <Icon className="text-24 mt-4">{statusProps[trip.Status].icon}</Icon>
                        }
                        action={
                            <div className="absolute pin-r mr-16 pin-t text-right mt-12 pt-8">
                                <Typography variant="caption" className={`font-bold ${increaseFontSize ? "text-16" : "text-12"}`}>{!trip.ScheduledTime ? `Anytime - ${trip.Duration} Hours` : `${moment(trip.ScheduledTime).local().format("h:mm A")} - ${moment(this.addHours(trip.ScheduledTime, trip.Duration)).local().format("h:mm A")}`}</Typography>
                            </div>
                        }
                        title={
                            <Typography className={`flex ${increaseFontSize ? "text-16" : "text-12"} font-bold`}>
                                <span>Busy</span>
                            </Typography>
                        }
                    />
                </Card>
            );
        }
        return (
            <Card onClick={this.stop} onMouseDown={this.stop} onMouseMove={this.stop} className={classNames(classes.card, "dispatch-work-order rounded-lg")}>
                {/* {technician &&
                    <TimecardLaborDialog
                        date={moment(trip.InProgressDate || trip.EnRouteDate || trip.ScheduledDate).format("M/D/YYYY")}
                        trips={[trip]}
                        employee={technician}
                        open={this.state.tcPrompt}
                        source={"technician"}
                        title={<div><Icon className="mr-6 align-middle mb-4">access_time</Icon>Add to Timecard?</div>}
                        message={<div className="w-full pt-8 pb-8">{`Would you like to add labor for Work Order #${trip.WorkOrder} to your timecard?`}</div>}
                        data={{ Co: trip.Co, Employee: technician.Employee, Date: moment(trip.InProgressDate || trip.EnRouteDate || trip.ScheduledDate).format("M/D/YYYY"), Type: 'W', WorkOrder: trip.WorkOrder, Scope: trip.Data.Assignments[0].Scope, Qty: (trip.ActualDuration || 1).toFixed(2) }}
                        onClose={() => this.setState({ ...this.state, tcPrompt: false, })}
                    />
                } */}
                <CardHeader onClick={this.handleOpen} draggable={technician ? false : true} onDragStart={this.dragStart} onDragEnd={this.dragEnd} className={classNames("p-12 relative", "highlight", technician ? classes.trip : '')}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classes.subheader, }
                    }
                    avatar={
                        <Icon className="text-24 mt-4" color={canceled ? 'error' : undefined}>{canceled ? 'cancel' : trip.Status === 1 && trip.SourceTrip ? 'pause_circle_outline' : statusProps[trip.Status].icon}</Icon>
                    }
                    action={
                        capacity ?
                            <div className="absolute pin-r mr-16 pin-t text-right mt-12 pt-8">
                                <Typography variant="caption" className={`font-bold ${increaseFontSize ? "text-16" : "text-12"}`}>{!trip.ScheduledTime ? `Anytime - ${trip.Duration} Hours` : `${moment(trip.ScheduledTime).local().format("h:mm A")} - ${moment(this.addHours(trip.ScheduledTime, trip.Duration)).local().format("h:mm A")}`}</Typography>
                            </div> :
                            <div className="absolute w-96 pin-r mr-16 pin-t text-right mt-12">
                                {!search ?
                                    <Typography component="div" variant="caption" className={`font-bold ${increaseFontSize ? "text-16" : "text-12"}`}>{canceled ? 'Canceled' : override ? override.TechnicianTerminology : statusProps[trip.Status].text}</Typography>
                                    :
                                    <Typography component="div" variant="caption" className={`font-bold ${increaseFontSize ? "text-16" : "text-12"}`}>{moment(trip.ScheduledDate).format('M/D/YYYY')}</Typography>
                                }
                                <Typography component="div" variant="caption" className={`${increaseFontSize ? "text-14" : "text-10"} text-grey-darker`}>{hold ? moment(trip.ScheduledDate).format('M/D/YYYY') : trip.BoardTime ? (trip.Status > 3 ? moment(moment(trip.CompletedTime || trip.BoardTime)).local().format("h:mm A") : moment(trip.CompletedTime || trip.BoardTime).local().format("h:mm A")) : "Anytime"}</Typography>
                                {/* <Icon className="pin-r pin-t mt-20 absolute">{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon> */}
                            </div>
                    }
                    title={
                        <Typography className={`flex font-bold ${increaseFontSize ? "text-16" : "text-12"}`}>
                            <span>{`Work Order #${workOrder.WorkOrder} Trip #${trip.Trip}`}</span>
                        </Typography>
                    }
                    subheader={<span className={`${increaseFontSize ? "text-14" : "text-11"}`}>{workOrder.Description}</span>}
                />
                {workOrder.Data.Site.CrossStreets &&
                    <div className="w-full p-4 px-12 truncate text-white text-center text-11 capitalize" style={{ backgroundColor: '#3f3f3f' }}><Icon color="secondary" className="mr-4 text-16 align-middle mb-2">location_searching</Icon>{workOrder.Data.Site.CrossStreets}</div>
                }
                {
                    ((!supervisor && trip.Status > 3 && trip.Status < 8) || open) && !capacity &&
                    <CardContent className="p-16" style={{ borderTop: '1px solid lightgrey', paddingBottom: 16 }}>
                        <div className="">
                            <div className="w-full flex justify-between mb-12">
                                <div className="mr-12" onClick={() => this.openDirections(trip)}>
                                    <Typography component="div" fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                        <Icon className={`${increaseFontSize ? "text-18" : "text-16"} mr-12`}>location_on</Icon>
                                        <strong>Address</strong>
                                    </Typography>
                                    <div className={`${increaseFontSize ? "text-16" : "text-11"} ml-28`}>
                                        {`${workOrder.Data.Site.Address1}`}<br />
                                        {workOrder.Data.Site.Address2 && <React.Fragment>{`${workOrder.Data.Site.Address2}`}</React.Fragment>}
                                        {`${workOrder.Data.Site.City}, ${workOrder.Data.Site.State} ${workOrder.Data.Site.Zip}`}
                                    </div>
                                </div>
                                <Typography component="div" fullWidth style={{ marginBottom: 0 }} className={`flex ${increaseFontSize ? "text-16" : "text-13"}`}>
                                    <Icon className={`${increaseFontSize ? "text-20" : "text-16"} mr-6 align-middle`} style={{ marginTop: 2 }}>timer</Icon>
                                    <strong>{`${trip.Duration.toFixed(2)} Hours`}</strong>
                                </Typography>
                            </div>
                            {trip.Notes && trip.Notes.length > 0 &&
                                <div className="w-full flex justify-between mb-12">
                                    <div className="mr-12">
                                        <Typography component="div" fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                            <Icon className={`${increaseFontSize ? "text-18" : "text-16"} mr-12`}>speaker_notes</Icon>
                                            <strong className={`${increaseFontSize ? "text-16" : "text-13"}`}>Notes</strong>
                                        </Typography>
                                        <div className={`${increaseFontSize ? "text-16" : "text-11"} ml-28`}>
                                            {trip.Notes}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="w-full flex justify-between">
                                {
                                    (isMobile || !dialer) ?
                                        <Link href={`tel:${this.formatPhoneLink(workOrder.Data.Contact ? workOrder.Data.Contact.Phone || workOrder.ContactPhone : workOrder.ContactPhone)}`} color="primary" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <div className="mr-12">
                                                <Typography component="div" fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                                    <Icon className={`${increaseFontSize ? "text-18" : "text-16"} mr-12`}>phone</Icon>
                                                    <strong>Contact</strong>
                                                </Typography>
                                                <div className={`${increaseFontSize ? "text-16" : "text-11"} ml-28`}>
                                                    {`${workOrder.Data.Contact ? workOrder.Data.Contact.Name || workOrder.Contact : workOrder.Contact}`}<br />
                                                    {`${this.formatPhone(workOrder.Data.Contact ? workOrder.Data.Contact.Phone || workOrder.ContactPhone : workOrder.ContactPhone)}`}
                                                </div>
                                            </div>
                                        </Link>
                                        :
                                        <div class="mr-12" onClick={() => this.handleCall(contact.Phone)}>
                                            <Typography component="div" fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                                <Icon className={`${increaseFontSize ? "text-18" : "text-16"} mr-12`}>phone</Icon>
                                                <strong>Contact</strong>
                                            </Typography>
                                            <div className={`${increaseFontSize ? "text-16" : "text-11"} ml-28`}>
                                                {contact.Name}<br />
                                                {this.formatPhone(contact.Phone)}
                                            </div>
                                        </div>
                                }
                                <div className="flex max-w-1/2">
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({ ...this.state, fetching: true }, () => {
                                                Promise.all([
                                                    openWorkOrder(workOrder, trip.Trip, true)
                                                ]).then(() => {
                                                    this.setState({ ...this.state, fetching: false })
                                                });
                                            })
                                        }}
                                        variant="contained"
                                        style={nextStatus && !supervisor ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
                                        className="text-12 font-bold xs:px-32 rounded-lg"
                                        color="primary"
                                        disabled={fetching}
                                    >
                                        {fetching && <CircularProgress color="primary" className="mr-12" style={{ width: 20, height: 20 }} />}
                                        {fetching ? <React.Fragment>Loading<Hidden xsDown> Work Order</Hidden></React.Fragment> : <React.Fragment>View<Hidden xsDown> Work Order</Hidden></React.Fragment>}
                                    </Button>
                                    {!supervisor && nextStatus && !canceled && !readOnly &&

                                        <Button
                                            onClick={() => {
                                                const shouldOpen = Boolean(trip.Status < 8);
                                                Promise.all([
                                                    this.updateStatus(trip)
                                                ]).then(() => {
                                                    if (shouldOpen) {
                                                        openWorkOrder(workOrder, trip.Trip, true)
                                                    }
                                                });
                                            }}
                                            variant="contained"
                                            className="text-12 font-bold text-white px-12 rounded-lg sm:pr-20"
                                            disabled={updating}
                                            style={{ minWidth: 48, backgroundColor: '#3f3f3f', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                        >
                                            {updating ?
                                                <CircularProgress color="secondary" style={{ width: 24, height: 24 }} /> :
                                                <React.Fragment>
                                                    <Icon className="text-24 text-white mr-4">
                                                        {statusProps[nextStatus.Status].icon}
                                                    </Icon>
                                                    <Hidden xsDown>
                                                        {nextStatus && nextStatus.ButtonType !== 'I' ? nextStatus.ButtonText : ''}
                                                    </Hidden>
                                                </React.Fragment>
                                            }
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </CardContent>
                }
            </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCustomer: openEditCustomerDialog,
        openSite: openEditCustomerSiteDialog,
        openWorkOrder: openEditWorkOrderDialog,
        openScope: openEditWorkOrderScopeDialog,
        openEditTripDialog,
        openNewTripDialog,
        toggleDialer: DialerActions.toggleDialer,
        initCall: DialerActions.initCall,
        addAlert
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, dialer }) {
    return {
        user: dispatchBoardApp.user,
        workOrders: spReducers.workOrders,
        technicians: spReducers.technicians,
        arrivalWindows: spReducers.arrivalWindows,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        date: dispatchBoardApp.dispatchBoard.date,
        dragging: dispatchBoardApp.dispatchBoard.dragging,
        dialer: dialer.sipPhone,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        company: _.find(spReducers.companies.List, { Co: spReducers.companies.Co }).Data.Co,
        userPreferences: spReducers.userProfiles.User.Preferences,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianTrip)));
