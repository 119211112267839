import React, { Component } from 'react';
import { Checkbox, Chip, Icon, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import ToolbarMenu from './ToolbarMenu';
import _ from '@lodash';
import { CallMissedSharp } from '@material-ui/icons';

class StatusMenu extends Component {
    state = {
        anchorEl: null
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { onChange, theme } = this.props;
        const colors = ['#4dbce9', '#f44336', 'yellow', '#fff'];
        const statuses = {
            'new': {
                id: 0,
                text: 'New',
                icon: 'add'
            },
            'assigned': {
                id: 1,
                text: 'Assigned',
                icon: 'people'
            },
            'in-progress': {
                id: 2,
                text: 'In Progress',
                icon: 'build'
            },
            'on-hold': {
                id: 3,
                text: 'On Hold',
                icon: 'pause',
            },
            'qa': {
                id: 4,
                text: 'Q/A',
                icon: 'assignment_done'
            },
            'completed': {
                id: 5,
                text: 'Completed',
                icon: 'check'
            },
        };
        const status = (this.props.status === 'new' && this.props.assignees > 0) ? 'assigned' : this.props.status;

        return (
            <div>
                <Chip size="small" style={{ backgroundColor: '#fff', color: theme.palette.primary.main, marginRight: 12, marginTop: 11 }} onClick={this.handleMenuClick} label={statuses[status].text} icon={<Icon className="text-20" style={{ color: theme.palette.primary.main }}>{statuses[status].icon}</Icon>} />
                <ToolbarMenu state={this.state.anchorEl} onClose={this.handleMenuClose}>
                    <div className="max-h-256 overflow-y-auto">
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange("new");
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                <Icon className="mr-8 align-middle">add</Icon>
                                New
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange("in-progress");
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                <Icon className="mr-8 align-middle">build</Icon>
                                In Progress
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange("on-hold");
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                <Icon className="mr-8 align-middle">pause</Icon>
                                On Hold
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange("qa");
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                <Icon className="mr-8 align-middle">assignment_done</Icon>
                                Q/A
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange("completed");
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                <Icon className="mr-8 align-middle">check</Icon>
                                Completed
                            </ListItemText>
                        </MenuItem>
                    </div>
                </ToolbarMenu>
            </div>
        );
    };
}

export default StatusMenu;
