import { Badge, Fade, Tooltip, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DispatchBoardWorkOrder from '../content/apps/dispatch-board/DispatchBoardWorkOrder';
import { setSelectedWO } from '../content/apps/dispatch-board/store/actions';

const styles = theme => ({
    popper: {
        opacity: 1,
        marginBottom: 15,
        marginTop: 5,
        zIndex: 5,
        // opacity: 0,
    },
    tooltip: {
        opacity: 1,
        padding: 0,
        margin: 0,
        boxShadow: '2px 2px 6px #3333',
    },
});

class WOMarker extends Component {
    state = {
        tooltipOpen: false,
    }

    componentDidMount() {
        const { tooltipOpen } = this.props;
        this.setState({ tooltipOpen });
    }

    componentDidUpdate(prevProps, prevState) {
        const { tooltipOpen } = this.props;
        if (tooltipOpen !== prevProps.tooltipOpen) {
            this.setState({ tooltipOpen });
        }
    }

    render() {
        const { workOrder, icon, style, badge, onClick, classes, onClose, mapDragging } = this.props;
        const { tooltipOpen } = this.state;
        return (
            <Tooltip
                // leaveDelay={1000}
                // enterDelay={1000}
                TransitionComponent={Fade}
                open={tooltipOpen && !mapDragging}
                onClose={(e) => { }}
                TransitionProps={{ timeout: 300 }}
                // disableFocusListener
                classes={{
                    popper: classes.popper,
                    tooltipPlacementTop: "with-after",
                    tooltipPlacementBottom: "with-before",
                    tooltip: classes.tooltip
                }}
                PopperProps={{
                    // disablePortal: true,
                    anchorEl: this.anchorEl,
                    popperOptions: {
                        modifiers: {
                            flip: { enabled: false },
                            preventOverflow:
                            {
                                enabled: true,
                                escapeWithReference: true,
                                boundariesElement: 'viewport'
                            }
                        },
                        // strategy: 'fixed'
                    }
                }}
                interactive
                title={(
                    <div style={{ maxWidth: 289, minWidth: 289 }} /*onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} onMouseDown={this.props.onMouseEnter} onMouseUp={this.props.onMouseLeave} onTouchStart={this.props.onTouchStart} onTouchEnd={this.props.onTouchEnd} onScrollStart={this.props.onTouchStart} onScrollEnd={this.props.onTouchEnd}*/>
                        <DispatchBoardWorkOrder canClose={true} clickHandler={(e) => { if (this.props.tooltipOpen) { onClose(e) } else { this.setState({ tooltipOpen: false }) } }} fullScreen={true} open={true} key={workOrder.ID} workOrder={{ ...workOrder, value: 2 }} />
                    </div>
                )
                }
                placement="top"
            >
                <div>
                    {
                        (badge && badge > 0) ?
                            <Badge badgeContent={badge} classes={{ badge: "site-map-badge" }} color="error">
                                <img ref={el => this.anchorEl = el} onClick={(e) => { e.stopPropagation(); if (onClick) { onClick(workOrder) } else { this.setState({ tooltipOpen: true }) } }} style={style} src={icon} />
                            </Badge>
                            :
                            <img ref={el => this.anchorEl = el} onClick={(e) => { e.stopPropagation(); if (onClick) { onClick(workOrder) } else { this.setState({ tooltipOpen: true }) } }} style={style} src={icon} />
                    }
                </div>
            </Tooltip >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedWO
    }, dispatch);
}

function mapStateToProps({ }) {
    return {

    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WOMarker));
