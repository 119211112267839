import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { AppBar, Avatar, Button, Card, CardHeader, Dialog, DialogActions, Grow, Hidden, Icon, IconButton, Paper, Slide, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { SPRoundIcon } from 'main/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions';
import { openEditEstimateDialog } from '../estimates/store/actions';
import { openEditInvoiceDialog } from '../invoices/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import CustomerRedirectSearchBar from './CustomerRedirectSearchBar';
import * as Actions from './store/actions';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ maxHeight: 'calc(100% - 112px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;


const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 13,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
    paper: {
        margin: 12,
        // minHeight: 'calc(100% - 64px)',
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    ok: {
        background: '#333',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        },
        '&:focus': {
            backgroundColor: '#5f5f5f',
        },
    },
    itemCard: {
        marginBottom: 12,
    },
    itemCardHeader: {
        borderBottom: '1px solid lightgrey'
    },
    itemCardUnit: {
        borderBottom: '1px dotted lightgrey',
        paddingLeft: 48,
    },
    headerContent: {
        maxWidth: '100%',
        overflow: 'hidden',
    }
});

const newCustomerDNUState = {
    ID: null,
    Co: null,
    Customer: null,
    Redirect: null,
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    Data: {
        Customer: null,
        Redirect: null,
        ErrMsg: null
    },
    value: 0,
    histTab: 0,
    expanded: []
};

class CustomerDNUDialog extends Component {
    state = { ..._.cloneDeepWith(newCustomerDNUState) };

    componentDidMount() {
        const { data, props } = this.props.customerDNUDialog;
        if (data) {
            this.setState({ ...newCustomerDNUState, ...data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
        if (event.target.populate) {
            this.setState(_.set({ ...this.state }, event.target.populate, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
        }
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newCustomerDNUState.Co = this.props.Co;
        const data = this.props.customerDNUDialog.data;
        if (!prevProps.customerDNUDialog.props.open && this.props.customerDNUDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.customerDNUDialog.type === 'edit' &&
                this.props.customerDNUDialog.data &&
                !_.isEqual(this.props.customerDNUDialog.data, prevProps.customerDNUDialog.Data)) {
                this.setState({ ...newCustomerDNUState, ...data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.customerDNUDialog.type === 'new' &&
                !_.isEqual({ ...newCustomerDNUState, ...data }, prevState)) {
                this.setState({ ...newCustomerDNUState, ...data });
            }
        }
        if (this.props.customerDNUDialog.type === 'update' &&
            this.props.customerDNUDialog.data &&
            !_.isEqual(this.props.customerDNUDialog.data, prevState)) {
            this.props.customerDNUDialog.type = 'edit';
            this.setState({ ...data });
        }
    }

    closeComposeDialog = () => {
        this.props.customerDNUDialog.type === 'edit' ? this.props.closeEditCustomerDNUDialog() : this.props.closeNewCustomerDNUDialog();
    };

    canBeSubmitted() {
        const { Co, Customer, Redirect } = this.state;
        return Boolean(Co) && Boolean(Customer) && Boolean(Redirect);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    toggleList = (e, name) => {
        e.stopPropagation();
        const { expanded } = this.state;
        const index = expanded.indexOf(name);
        if (index > -1) {
            expanded.splice(index, 1);
        } else {
            expanded.push(name);
        }
        this.setState({ ...this.state, expanded });
    }

    render() {
        const { classes, securables, customerDNUDialog } = this.props
        const { value, Data, histTab, expanded } = this.state;
        const accessLevel = _.find(securables, { Securable: "customer-dialog" });

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        {...customerDNUDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    <SPRoundIcon className="cursor-pointer text-32 align-middle mr-8" />
                                    {customerDNUDialog.type === 'new' ? 'Merge Customers' : `Merged Customers`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            {/* <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                            // variant="scrollable"
                            // scrollButtons="on"
                            >
                                <Tab disableRipple icon={<AccountCircle />} label="Info" />
                            </Tabs> */}
                        </AppBar>
                        {value === 0 &&
                            <TabContainer>
                                <div className="w-full sm:flex p-24 pb-64">
                                    <div className="w-full sm:min-w-1/2 sm:max-w-1/2 min-h-256 md:min-h-512 sm:rounded-l relative border-solid border-1 border-grey-lighter">
                                        <Hidden smUp>
                                            <Icon color="action" className="text-32 pin-r pin-l pin-b m-auto absolute" style={{ bottom: -18 }}>directions</Icon>
                                        </Hidden>
                                        <Hidden xsDown>
                                            <Icon color="action" className="text-32 pin-r pin-t pin-b m-auto absolute" style={{ right: -18 }}>directions</Icon>
                                        </Hidden>
                                        {Data.Customer &&
                                            <div className="w-full p-12 pb-24 sm:pb-12 sm:pr-24">
                                                <Typography style={{ alignItems: 'center', }} className="flex relative">
                                                    <Icon color={"primary"} className="text-24 mr-4">account_circle</Icon>
                                                    <span>
                                                        <strong>Customer #{this.state.Customer}</strong>
                                                    </span>
                                                </Typography>
                                                <div className="text-xs w-full pl-28 truncate mb-12">
                                                    {Data.Customer.Name || `${Data.Customer.FirstName} ${Data.Customer.LastName}`}<br />
                                                    {`${Data.Customer.Address1}`}<br />
                                                    {Data.Customer.Address2 && Data.Customer.Address2.length > 0 ? Data.Customer.Address2 : ''}
                                                    {Data.Customer.Address2 && Data.Customer.Address2.length > 0 ? <br /> : ''}
                                                    {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                                </div>
                                                <div className="w-full pl-12 max-h-256 md:max-h-512 overflow-auto">
                                                    <div className="w-full bg-grey-lightest rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'Sites')} variant="h6" style={{ alignItems: 'center', }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">location_on</Icon>
                                                            <span>
                                                                Sites
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Customer.Data.Sites.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Customer.Data.Sites.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('Sites') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('Sites') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Customer.Data.Sites.map(value =>
                                                                        <Card className={classes.itemCard} key={value.Site}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">location_on</Icon>
                                                                                }
                                                                                title={`Site #${value.Site}`}
                                                                                subheader={`${value.Address1}${value.Address2 ? `${value.Address2} ` : ''} ${value.City}, ${value.State} ${value.Zip}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditCustomerSiteDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-full bg-grey-lightest rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'WO')} variant="h6" style={{ alignItems: 'center' }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">assignment</Icon>
                                                            <span>
                                                                Work Orders
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Customer.Data.WorkOrders.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Customer.Data.WorkOrders.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('WO') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('WO') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Customer.Data.WorkOrders.map(value =>
                                                                        <Card className={classes.itemCard} key={value.WorkOrder}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">assignment</Icon>
                                                                                }
                                                                                title={`Work Order #${value.WorkOrder}`}
                                                                                subheader={`${value.Description}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditWorkOrderDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-full bg-grey-lightest rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'Invoice')} variant="h6" style={{ alignItems: 'center' }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">receipt</Icon>
                                                            <span>
                                                                Invoices
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Customer.Data.Invoices.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Customer.Data.Invoices.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('Invoice') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('Invoice') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Customer.Data.Invoices.map(value =>
                                                                        <Card className={classes.itemCard} key={value.Invoice}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">receipt</Icon>
                                                                                }
                                                                                title={`Invoice #${value.InvoiceNumber}`}
                                                                                subheader={`${value.Description}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditInvoiceDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-full bg-grey-lightest rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'Estimate')} variant="h6" style={{ alignItems: 'center' }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">list</Icon>
                                                            <span>
                                                                Estimates
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Customer.Data.Estimates.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Customer.Data.Estimates.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('Estimate') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('Estimate') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Customer.Data.Estimates.map(value =>
                                                                        <Card className={classes.itemCard} key={value.EstimateNumber}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">list</Icon>
                                                                                }
                                                                                title={`Estimate #${value.EstimateNumber}`}
                                                                                subheader={`${value.Description}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditEstimateDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full sm:min-w-1/2 sm:max-w-1/2 bg-grey-lightest min-h-256 md:min-h-512 sm:rounded-r border-solid border-1 border-grey-lighter">
                                        {!Data.Redirect &&
                                            <div className="flex flex-1 p-12 mt-12">
                                                <Paper className="flex items-center w-full h-48 sm:h-56 p-16 pl-4 md:pl-16 rounded-8 relative" elevation={1}>
                                                    <Icon color="action">search</Icon>
                                                    <CustomerRedirectSearchBar handleCustomer={(customer) => { this.setState({ ...this.state, Redirect: customer.Customer, Data: { ...this.state.Data, Redirect: customer } }) }} />
                                                </Paper>
                                            </div>
                                        }
                                        {Data.Redirect &&
                                            <div className="w-full p-12 pt-24 sm:pt-12 sm:pr-24">
                                                <Typography style={{ alignItems: 'center', }} className="flex relative w-full">
                                                    <Icon color={"primary"} className="text-24 mr-4">account_circle</Icon>
                                                    <span>
                                                        <strong>Customer #{this.state.Redirect}</strong>
                                                    </span>
                                                    {customerDNUDialog.type === 'new' &&
                                                        <IconButton
                                                            className="pin-r pin-t absolute"
                                                            style={{
                                                                top: -8,
                                                                right: -20
                                                            }}
                                                            onClick={() => {
                                                                this.setState({ ...this.state, Redirect: null, Data: { ...this.state.Data, Redirect: null } })
                                                            }}
                                                        >
                                                            <Icon className="text-16">close</Icon>
                                                        </IconButton>
                                                    }
                                                </Typography>
                                                <div className="text-xs w-full pl-28 truncate mb-12">
                                                    {Data.Redirect.Name || `${Data.Redirect.FirstName} ${Data.Redirect.LastName}`}<br />
                                                    {`${Data.Redirect.Address1}`}<br />
                                                    {Data.Redirect.Address2 && Data.Redirect.Address2.length > 0 ? Data.Redirect.Address2 : ''}
                                                    {Data.Redirect.Address2 && Data.Redirect.Address2.length > 0 ? <br /> : ''}
                                                    {`${Data.Redirect.City}, ${Data.Redirect.State} ${Data.Redirect.Zip}`}
                                                </div>
                                                <div className="w-full pl-12 max-h-256 md:max-h-512 overflow-auto">
                                                    <div className="w-full bg-white rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'RedirSites')} variant="h6" style={{ alignItems: 'center', }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">location_on</Icon>
                                                            <span>
                                                                Sites
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Redirect.Data.Sites.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Redirect.Data.Sites.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('RedirSites') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('RedirSites') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Redirect.Data.Sites.map(value =>
                                                                        <Card className={classes.itemCard} key={value.Site}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">location_on</Icon>
                                                                                }
                                                                                title={`Site #${value.Site}`}
                                                                                subheader={`${value.Address1}${value.Address2 ? `${value.Address2} ` : ''} ${value.City}, ${value.State} ${value.Zip}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditCustomerSiteDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-full bg-white rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'RedirWO')} variant="h6" style={{ alignItems: 'center' }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">assignment</Icon>
                                                            <span>
                                                                Work Orders
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Redirect.Data.WorkOrders.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Redirect.Data.WorkOrders.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('RedirWO') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('RedirWO') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Redirect.Data.WorkOrders.map(value =>
                                                                        <Card className={classes.itemCard} key={value.WorkOrder}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">assignment</Icon>
                                                                                }
                                                                                title={`Work Order #${value.WorkOrder}`}
                                                                                subheader={`${value.Description}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditWorkOrderDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-full bg-white rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'RedirInvoice')} variant="h6" style={{ alignItems: 'center' }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">receipt</Icon>
                                                            <span>
                                                                Invoices
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Redirect.Data.Invoices.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Redirect.Data.Invoices.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('RedirInvoice') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('RedirInvoice') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Redirect.Data.Invoices.map(value =>
                                                                        <Card className={classes.itemCard} key={value.Invoice}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">receipt</Icon>
                                                                                }
                                                                                title={`Invoice #${value.InvoiceNumber}`}
                                                                                subheader={`${value.Description}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditInvoiceDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-full bg-white rounded mb-8" style={{ border: '1px dotted lightgrey' }}>
                                                        <Typography onClick={(e) => this.toggleList(e, 'RedirEstimate')} variant="h6" style={{ alignItems: 'center' }} className="flex text-14 font-bold ml-8 cursor-pointer relative h-36">
                                                            <Icon color={"primary"} className="text-24 mr-4">list</Icon>
                                                            <span>
                                                                Estimates
                                                            </span>
                                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                                <Avatar className={classNames(classes.avatar, "pin-r pin-t mt-8 absolute")}>{Data.Redirect.Data.Estimates.length}</Avatar>
                                                            </FuseAnimate>
                                                            {Data.Redirect.Data.Estimates.length > 0 &&
                                                                <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf('RedirEstimate') > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                                            }
                                                        </Typography>
                                                        {expanded.indexOf('RedirEstimate') > -1 &&
                                                            <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <FuseAnimateGroup
                                                                    enter={{
                                                                        animation: "transition.slideUpBigIn"
                                                                    }}
                                                                    leave={{
                                                                        animation: "transition.slideLeftBigOut"
                                                                    }}
                                                                >
                                                                    {Data.Redirect.Data.Estimates.map(value =>
                                                                        <Card className={classes.itemCard} key={value.EstimateNumber}>
                                                                            <CardHeader
                                                                                className={classes.itemCardUnit}
                                                                                classes={{ action: "sm:mt-0", root: "p-8", avatar: "mr-8", content: classes.headerContent, title: "leading-none truncate text-12 font-bold", subheader: "leading-none text-10 w-full truncate" }}
                                                                                avatar={
                                                                                    <Icon color={"primary"} className="text-24">list</Icon>
                                                                                }
                                                                                title={`Estimate #${value.EstimateNumber}`}
                                                                                subheader={`${value.Description}`}
                                                                                action={
                                                                                    <IconButton onClick={() => this.props.openEditEstimateDialog(value)} aria-label="edit">
                                                                                        <Icon className="text-16">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    )}
                                                                </FuseAnimateGroup>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* <div className="w-full p-24">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div> */}
                            </TabContainer>
                        }

                        {accessLevel && accessLevel.AccessLevel !== "R" && (value === 0 || value === 1) &&
                            <DialogActions className={`justify-${customerDNUDialog.type === 'new' ? "start" : "end"} pl-16 pin-b absolute bg-white w-full`}>
                                {customerDNUDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const { Co, Customer, Redirect, Notes } = this.state;
                                            const rec = {
                                                Co,
                                                Customer,
                                                Redirect,
                                                Notes,
                                                Data: {
                                                    ErrMsg: null
                                                }
                                            };
                                            this.props.addCustomerDNU(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Merge Customers
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const { Co, Customer, Redirect, Notes } = this.state;
                                            const rec = {
                                                Co,
                                                Customer,
                                                Redirect,
                                                Notes,
                                                Data: {
                                                    ErrMsg: null
                                                }
                                            };
                                            this.props.removeCustomerDNU(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Un-Merge Customers
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
CustomerDNUDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateEditCustomerDNUDialog: Actions.updateEditCustomerDNUDialog,
        closeEditCustomerDNUDialog: Actions.closeEditCustomerDNUDialog,
        closeNewCustomerDNUDialog: Actions.closeNewCustomerDNUDialog,
        openEditCustomerSiteDialog,
        openEditWorkOrderDialog,
        openEditInvoiceDialog,
        openEditEstimateDialog,
        addCustomerDNU: Actions.addCustomerDNU,
        removeCustomerDNU: Actions.removeCustomerDNU,
        showMessage
    }, dispatch);
}

function mapStateToProps({ customersApp, spReducers }) {
    return {
        customerDNUDialog: customersApp.customers.customerDNUDialog,
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CustomerDNUDialog));

