import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import { Button, Hidden, Icon, IconButton, Input, Paper, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FuseAnimate, FuseSelectedTheme } from '@fuse';

const styles = theme => ({
    root: {},
    logo: {},
    logoIcon: {
        fontSize: '32px!important'
    },
    logoText: {
        fontSize: 24
    },
});

class SupervisorsHeader extends Component {

    render() {
        const { classes, setSearchText, searchText, pageLayout, weekly } = this.props;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex w-full">
                    <div className={classNames(classes.logo, "flex items-center flex-1 w-full justify-between")}>
                        <div className="mt-8 w-1/2 truncate">
                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                <Icon className={classNames(classes.logoIcon, "mr-16 align-middle mb-10")}>supervised_user_circle</Icon>
                            </FuseAnimate>
                            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                <span className={classes.logoText}>Supervisor Dashboard</span>
                            </FuseAnimate>
                        </div>
                        <div className="float-right">
                            <Button onClick={this.props.handleDaily} variant={!weekly ? "outlined" : undefined} className="mr-8 rounded-full" style={{ color: '#fff', border: !weekly ? '1px solid #fff' : undefined }}>Daily</Button>
                            <Button onClick={this.props.handleWeekly} variant={weekly ? "outlined" : undefined} className="rounded-full" style={{ color: '#fff', border: weekly ? '1px solid #fff' : undefined }}>Weekly</Button>
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        )
            ;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({ supervisorApp }) {
    return {
        searchText: supervisorApp.supervisor.searchText
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(SupervisorsHeader));
