import * as Actions from '../actions';
import _ from '@lodash';
import { UPDATE_ASSEMBLY_PRICING, SET_ASSEMBLIES_UPDATING } from '../actions/materials.actions'

const initialState = {
    entities: [],
    searchText: '',
    selectedMaterialIds: [],
    routeParams: {},
    materialDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            Category: null,
            Material: null,
            Description: null,
            UOM: null,
            Cost: null,
            CostType: null,
            CostTotal: null,
            StockedYN: 'N',
            TaxableYN: 'Y',
            CreatedBy: null,
            CreatedDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            Notes: null,
            AttachmentID: null,
            MfgBarcode: null,
            value: 0,
            workOrder: null,
            Data: {
                ErrMsg: null,
            }
        }
    },
    assemblies: {
        data: [],
        selectedAssemblies: [],
        loading: true,
        updating: false,
    },
};

const materialsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MATERIALS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_MATERIALS:
            {

                const materialId = action.materialId;

                let selectedMaterialIds = [...state.selectedMaterialIds];

                if (selectedMaterialIds.find(id => id === materialId) !== undefined) {
                    selectedMaterialIds = selectedMaterialIds.filter(id => id !== materialId);
                }
                else {
                    selectedMaterialIds = [...selectedMaterialIds, materialId];
                }

                return {
                    ...state,
                    selectedMaterialIds: selectedMaterialIds
                };
            }
        case Actions.SELECT_ALL_MATERIALS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedMaterialIds = arr.map(materials => materials.id);

                return {
                    ...state,
                    selectedMaterialIds: selectedMaterialIds
                };
            }
        case Actions.DESELECT_ALL_MATERIALS:
            {
                return {
                    ...state,
                    selectedMaterialIds: []
                };
            }
        case Actions.OPEN_NEW_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    materialDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.materialDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_MATERIAL_DIALOG:
            {
                return {
                    ...state,
                    materialDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: {
                            ...initialState.materialDialog.data,
                        }
                    }
                };
            }
        case Actions.OPEN_EDIT_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    materialDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.materialDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    materialDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.materialDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    materialDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: {
                            ...initialState.materialDialog.data,
                        }
                    }
                };
            }
        case Actions.GET_ASSEMBLY_RATES:
            return {
                ...state, 
                assemblies: {
                    ...state.assemblies,
                    data: action.assemblies.data,
                    selectedAssemblies: action.assemblies.selectedAssemblies,
                    loading: action.assemblies.loading
                }
            }
        case Actions.SET_SELECTED_ASSEMBLIES:
            {
                const { selectedAssemblies } = action;
                return {
                    ...state,
                    assemblies: {
                        ...state.assemblies,
                        selectedAssemblies
                    }
                }
            }
        case UPDATE_ASSEMBLY_PRICING:
            {
                return {
                    ...state,
                    assemblies: {
                        ...state.assemblies,
                        data: action.assemblies,
                        updating: false,
                    }
                }
            }
        case SET_ASSEMBLIES_UPDATING:
            {
                return {
                    ...state,
                    assemblies: {
                        ...state.assemblies,
                        data: action.data,
                        updating: action.updating
                    }
                }

            }
        default:
            {
                return state;
            }
    }
};

export default materialsReducer;
