import axios from 'axios/index';
import { showMessage } from 'store/actions/fuse';
import history from 'history.js';
import _ from '@lodash';
import * as Actions from './index';
import { FuseUtils } from '@fuse';

export const GET_TEMPLATE = '[EMAIL TEMPLATE APP] GET TEMPLATE';
export const DELETE_TEMPLATE = '[EMAIL TEMPLATE APP] DELETE TEMPLATE';
export const COPY_TEMPLATE = '[EMAIL TEMPLATE APP] COPY TEMPLATE';
export const RENAME_TEMPLATE = '[EMAIL TEMPLATE APP] RENAME TEMPLATE';
export const CHANGE_TEMPLATE_SETTINGS = '[EMAIL TEMPLATE APP] CHANGE TEMPLATE SETTINGS';
export const RESET_TEMPLATE = '[EMAIL TEMPLATE APP] RESET TEMPLATE';

export function getTemplate(Co, templateId) {
    const request = axios.get(`${window["apiLocation"]}/api/HTMLTemplate?Co=${Co}&ID=${templateId}`);

    return (dispatch) =>
        request.then(
            (response) =>
                dispatch({
                    type: GET_TEMPLATE,
                    payload: response.data
                }),
            (error) => {
                dispatch(showMessage({
                    message: error.response.data,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
                history.push({
                    pathname: '/apps/email-templates/templates'
                });
            });
}

export function resetTemplate() {
    return {
        type: RESET_TEMPLATE
    };
}

export function changeTemplateSettings(newSettings) {
    return (dispatch, getState) => {
        const { template } = getState().emailTemplateApp;
        const settings = _.merge(template.settings, newSettings);
        const request = axios.post('/api/email-template-app/template/settings/update',
            {
                templateId: template.id,
                settings
            }
        );

        return request.then((response) =>
            dispatch({
                type: CHANGE_TEMPLATE_SETTINGS,
                payload: response.data
            })
        );
    }
}

export function deleteTemplate(template) {
    const request = axios.delete(`${window["apiLocation"]}/api/HTMLTemplate?Co=${template.Co}&Template=${template.ID}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch(showMessage({
                message: 'Template Removed',
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));

            history.push({
                pathname: '/apps/email-templates/templates'
            });

            dispatch({
                type: DELETE_TEMPLATE,
                payload: template
            });
        }
        );
}
// export function deleteTemplate(templateId) {
//     const request = axios.post('/api/email-template-app/template/delete',
//         {
//             templateId
//         }
//     );

//     return (dispatch) =>
//         request.then((response) => {

//             history.push({
//                 pathname: '/apps/email-templates/templates'
//             });

//             return dispatch({
//                 type: DELETE_TEMPLATE
//             });
//         })
// }

export function copyTemplate(template) {
    const newTemplate = _.merge(template, {
        id: FuseUtils.generateGUID(),
        name: template.Name + ' (Copied)',
        uri: template.uri + '-copied'
    });
    return (dispatch) => {
        dispatch(Actions.newTemplate(newTemplate));
        return { type: COPY_TEMPLATE };
    }
}

export function updateTemplate(template) {
    const request = axios.put(`${window["apiLocation"]}/api/HTMLTemplate?Co=${template.Co}&ID=${template.ID}`,
        template
    );

    return (dispatch) =>
        request.then((response) => {

            dispatch({
                type: RENAME_TEMPLATE,
                payload: template
            });
            dispatch(showMessage({
                message: 'Template updated successfully',
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));
        });
}
