import axios from 'axios/index';

export const SET_VOICEMAIL_DATA = '[SPCONNECTION] SET VOICEMAIL DATA';
export const GET_VOICEMAIL_DATA = '[SPCONNECTION] GET VOICEMAIL DATA';
export const SUBSCRIBE_VOICEMAIL_LIST = '[SPCONNECTION] SUBSCRIBE VOICEMAIL LIST';

export function getInitialVoicemailData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Voicemail`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getVoicemailData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Voicemail`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VOICEMAIL_DATA,
                payload: response.data
            })
        });
    }
}

export function setVoicemailData(data) {
    return {
        type: SET_VOICEMAIL_DATA,
        payload: data
    };
}