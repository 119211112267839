import CostTypesApp from 'main/content/apps/cost-types/CostTypesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const CostTypesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/cost-types/:id',
            component: CostTypesApp
        },
        {
            path     : '/apps/cost-types',
            component: () => <Redirect to="/apps/cost-types/all"/>
        }
    ]
};
