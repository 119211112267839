import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import axios from 'axios/index';
import { Avatar, AppBar, Button, Card, CardContent, Chip, FormControl, FormControlLabel, Icon, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, InputAdornment, OutlinedInput, Select, Switch, Toolbar, Typography, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { FuseAnimateGroup, FuseThemes } from '@fuse';
import { bindActionCreators } from 'redux';
import { impress } from 'main/content/compression/impress';
import { showMessage } from 'store/actions';
import { DatePicker } from "@material-ui/pickers";
import ChipInput from 'material-ui-chip-input';
import _ from '@lodash';
import { connect } from 'react-redux';

const styles = theme => ({
    root: {},
    updateButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});

class AboutTab extends Component {
    state = {
        general: {
            Data: {
                Companies: [],
                Skills: [],
                Queues: [],
                Stations: [],
            }
        },
        editing: false,
        stations: {
            editing: false,
        },
        skills: {
            editing: false,
        },
        queues: {
            editing: false,
        },
        companies: {
            editing: false,
        },
        IncreaseFontSize: 'N'
    }

    componentDidMount() {
        const { general } = this.props;
        this.setState({ ...this.state, general });
    }

    componentDidUpdate(prevProps, prevState) {
        const { general } = this.props;
        window["warn"](general, this.state.general);
        if (!this.state.editing && !prevState.editing && !_.isEqual(general, this.state.general)) {
            this.setState({ ...this.state, general });
        }
    }

    formatDate(date) {

        const dt = new Date(date);
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return (date ? formatted : '');
    }

    formatPhone(phone) {
        return phone && phone.length == 10 ? `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}` : phone;
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    handleDOBChange = (event) => {
        let { general } = this.state;
        let { DOB } = general;
        DOB = event.format("MM/DD/YYYY");
        window["warn"](event, DOB);
        this.setState({ ...this.state, general: { ...general, DOB } })
    };

    toggleEdit = () => {
        const { general, editing } = this.state;
        this.setState({ ...this.state, general, editing: !editing });
    }

    saveChanges = () => {
        const { general } = this.state;
        const data = _.cloneDeepWith(general);
        delete data.Data;
        axios.put(`${window["apiLocation"]}/api/UserProfile?Co=${this.props.Co}&UserName=${encodeURIComponent(general.UserName)}`, data).then((response) => {
            if (this.state.editing) {
                this.toggleEdit();
            }
        });
    }

    toggleEditSkills = (skill) => {
        const { skills } = this.state;
        const { editing } = skills;
        this.setState({ ...this.state, skills: { editing: skill ? skill : editing ? false : {} } });
    }

    createSkill = () => {
        const { general, skills } = this.state
        const { Co } = this.props;
        const { UserName } = general;
        let { editing } = skills;
        editing = {
            ...editing,
            Co,
            UserName,
        }
        axios.post(`${window["apiLocation"]}/api/UserSettings/PostUserSkill`, editing).then((response) => {
            general.Data.Skills.push(response.data);
            this.setState({ ...this.state, general, skills: { editing: false } });
        });
        this.setState({ ...this.state, skills: { editing: false } });
    }

    updateSkill = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { skills } = this.state;
        const { editing } = skills;
        axios.post(`${window["apiLocation"]}/api/UserSettings/UpdateUserSkill?Co=${Co}&UserName=${encodeURIComponent(editing.UserName)}&Skill=${editing.Skill}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Skills, { Skill: editing.Skill });
            general.Data.Skills.splice(index, 1, response.data);
            this.setState({ ...this.state, general, skills: { editing: false } });
        });
    }

    removeSkill = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { skills } = this.state;
        const { editing } = skills;
        axios.delete(`${window["apiLocation"]}/api/UserSettings/DeleteUserSkill?Co=${Co}&UserName=${encodeURIComponent(editing.UserName)}&Skill=${editing.Skill}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Skills, { Skill: editing.Skill });
            general.Data.Skills.splice(index, 1);
            this.setState({ ...this.state, general, skills: { editing: false } });
        });
    }

    toggleEditCompanies = (company) => {
        const { companies } = this.state;
        const { editing } = companies;
        this.setState({ ...this.state, companies: { editing: company ? company : editing ? false : {} } });
    }

    createCompanyUser = () => {
        const { general, companies } = this.state
        const { UserName } = general;
        let { editing } = companies;
        editing = {
            ...editing,
            ActiveYN: "Y",
            UserName,
        }
        axios.post(`${window["apiLocation"]}/api/UserSettings/PostCompanyUser`, editing).then((response) => {
            general.Data.Companies.push(response.data);
            this.setState({ ...this.state, general, companies: { editing: false } });
        });
        this.setState({ ...this.state, companies: { editing: false } });
    }

    updateCompanyUser = () => {
        let { general } = this.state;
        const { companies } = this.state;
        const { UserName } = general;
        const { editing } = companies;
        axios.post(`${window["apiLocation"]}/api/UserSettings/UpdateCompanyUser?UserName=${encodeURIComponent(UserName)}&Co=${editing.Co}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Companies, { Co: editing.Co });
            general.Data.Companies.splice(index, 1, response.data);
            this.setState({ ...this.state, general, companies: { editing: false } });
        });
    }

    removeCompanyUser = () => {
        let { general } = this.state;
        const { companies } = this.state;
        const { UserName } = general;
        const { editing } = companies;
        axios.delete(`${window["apiLocation"]}/api/UserSettings/DeleteCompanyUser?UserName=${encodeURIComponent(UserName)}&Co=${editing.Co}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Companies, { Co: editing.Co });
            general.Data.Companies.splice(index, 1);
            this.setState({ ...this.state, general, companies: { editing: false } });
        });
    }

    toggleEditStations = (station) => {
        const { stations } = this.state;
        const { editing } = stations;
        this.setState({ ...this.state, stations: { editing: station ? station : editing ? false : {} } });
    }

    createStation = () => {
        const { general, stations } = this.state
        const { Co } = this.props;
        const { UserName } = general;
        let { editing } = stations;
        editing = {
            ...editing,
            Co,
            UserName,
        }
        axios.post(`${window["apiLocation"]}/api/UserSettings/PostUserStation`, editing).then((response) => {
            general.Data.Stations.push(response.data);
            this.setState({ ...this.state, general, stations: { editing: false } });
        });
        this.setState({ ...this.state, stations: { editing: false } });
    }

    updateStation = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { stations } = this.state;
        const { editing } = stations;
        axios.post(`${window["apiLocation"]}/api/UserSettings/UpdateUserStation?Co=${Co}&UserName=${encodeURIComponent(editing.UserName)}&Phone=${editing.Phone}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Stations, { Phone: editing.Phone });
            general.Data.Stations.splice(index, 1, response.data);
            this.setState({ ...this.state, general, stations: { editing: false } });
        });
    }

    removeStation = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { stations } = this.state;
        const { editing } = stations;
        axios.delete(`${window["apiLocation"]}/api/UserSettings/DeleteUserStation?Co=${Co}&UserName=${encodeURIComponent(editing.UserName)}&Phone=${editing.Phone}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Stations, { Phone: editing.Phone });
            general.Data.Stations.splice(index, 1);
            this.setState({ ...this.state, general, stations: { editing: false } });
        });
    }

    toggleEditQueues = (queue) => {
        const { queues } = this.state;
        const { editing } = queues;
        this.setState({ ...this.state, queues: { editing: queue ? queue : editing ? false : {} } });
    }

    createQueue = () => {
        const { general, queues } = this.state
        const { Co } = this.props;
        const { UserName } = general;
        let { editing } = queues;
        editing = {
            ...editing,
            Co,
            UserName,
        }
        axios.post(`${window["apiLocation"]}/api/UserSettings/PostCallQueueAgent`, editing).then((response) => {
            general.Data.Queues.push(response.data);
            this.setState({ ...this.state, general, queues: { editing: false } });
        });
        this.setState({ ...this.state, queues: { editing: false } });
    }

    updateQueue = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { queues } = this.state;
        const { editing } = queues;
        axios.post(`${window["apiLocation"]}/api/UserSettings/UpdateCallQueueAgent?Co=${Co}&UserName=${encodeURIComponent(editing.UserName)}&Queue=${editing.Queue}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Queues, { Queue: editing.Queue });
            general.Data.Queues.splice(index, 1, response.data);
            this.setState({ ...this.state, general, queues: { editing: false } });
        });
    }

    removeQueue = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { queues } = this.state;
        const { editing } = queues;
        axios.delete(`${window["apiLocation"]}/api/UserSettings/DeleteCallQueueAgent?Co=${Co}&UserName=${encodeURIComponent(editing.UserName)}&Queue=${editing.Queue}`, editing).then((response) => {
            let index = _.findIndex(general.Data.Queues, { Queue: editing.Queue });
            general.Data.Queues.splice(index, 1);
            this.setState({ ...this.state, general, queues: { editing: false } });
        });
    }

    render() {
        const { classes, groups, friends, callQueues, Companies, userRoles, settings, securables } = this.props;
        const { editing, skills, stations, queues, general, companies } = this.state;
        const genders = { "M": "Male", "F": "Female" }
        const userAccessLevel = _.find(securables, { Securable: "users" });
        const queueAccessLevel = _.find(securables, { Securable: "call-queues" });

        

        // let pref = general.Preferences;
        // let pref2 = JSON.parse(pref)
        let IncreaseFontSize = this.state.IncreaseFontSize;
        if(general.Preferences){
            let pref = JSON.parse(general.Preferences);
            if(pref.IncreaseFontSize){
                IncreaseFontSize = pref.IncreaseFontSize;
                window["warn"]("about increaseFontSize ss", IncreaseFontSize)
            }
        }

        return (
            !general.FirstName ? '' :
                <div className={classNames(classes.root, "md:flex px-12")}>

                    <div className="flex flex-col flex-1 md:pr-32">
                        <FuseAnimateGroup
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        >
                            {general && (
                                <React.Fragment>
                                    <Card className="w-full mb-24 rounded-lg">
                                        <AppBar position="static" elevation={0}>
                                            <Toolbar className="pl-16 pr-8">
                                                <Typography variant="subtitle1" color="inherit" className="flex-1">
                                                    General Information
                                                </Typography>
                                                {!editing ?
                                                    <Icon onClick={this.toggleEdit} className="mr-12 cursor-pointer">edit</Icon>
                                                    :
                                                    <Icon onClick={this.saveChanges} className="mr-12 cursor-pointer">save</Icon>
                                                }
                                            </Toolbar>
                                        </AppBar>

                                        <CardContent>
                                            {!editing ?
                                                <React.Fragment>
                                                    <div className="mb-24">
                                                        <Typography className="font-bold mb-4 text-15">Gender</Typography>
                                                        <Typography>{general.Gender ? genders[general.Gender] : "Unspecified"}</Typography>
                                                    </div>

                                                    <div className="mb-24">
                                                        <Typography className="font-bold mb-4 text-15">Birthday</Typography>
                                                        <Typography>{this.formatDate(general.DOB)}</Typography>
                                                    </div>

                                                    <div className="mb-24">
                                                        <Typography className="font-bold mb-4 text-15">Location</Typography>

                                                        <div className="flex items-center">
                                                            <Typography>{general.Location}</Typography>
                                                            <Icon className="text-16 ml-4" color="action">location_on</Icon>
                                                        </div>
                                                    </div>

                                                    <div className="mb-24">
                                                        <Typography className="font-bold mb-4 text-15">About Me</Typography>
                                                        <Typography>{general.Bio}</Typography>
                                                    </div>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <div className="mb-24">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Gender"
                                                            id="gender"
                                                            name="general.Gender"
                                                            value={general.Gender}
                                                            onChange={this.handleChange}
                                                            InputLabelProps={{ shrink: general.Gender ? true : false }}
                                                            variant="outlined"
                                                            fullWidth
                                                            select
                                                        >
                                                            <MenuItem value={null}>
                                                                Unsepecified
                                                            </MenuItem>
                                                            <MenuItem value="M">
                                                                Male
                                                            </MenuItem>
                                                            <MenuItem value="F">
                                                                Female
                                                            </MenuItem>
                                                        </TextField>
                                                    </div>

                                                    <div className="mb-24">
                                                        <DatePicker variant="inline" inputVariant="outlined"
                                                            className={classes.formControl}
                                                            label="Date of Birth"
                                                            id="dob"
                                                            format="MM/DD/YYYY"
                                                            value={general.DOB}
                                                            onChange={this.handleDOBChange}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end"><Icon>today</Icon></InputAdornment>,
                                                            }}
                                                            autoOk
                                                            animateYearScrolling
                                                            required
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className="mb-24">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Location"
                                                            id="location"
                                                            name="general.Location"
                                                            value={general.Location}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className="mb-24">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Bio"
                                                            id="bio"
                                                            name="general.Bio"
                                                            value={general.Bio}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            multiline
                                                            rows={5}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }

                                        </CardContent>
                                    </Card>
                                    <Card className="w-full mb-24 rounded-lg">
                                        <AppBar position="static" elevation={0}>
                                            <Toolbar className="pl-16 pr-8">
                                                <Typography variant="subtitle1" color="inherit" className="flex-1">
                                                    Work
                                                </Typography>
                                                {!editing ?
                                                    <Icon onClick={this.toggleEdit} className="mr-12 cursor-pointer">edit</Icon>
                                                    :
                                                    <Icon onClick={this.saveChanges} className="mr-12 cursor-pointer">save</Icon>
                                                }
                                            </Toolbar>
                                        </AppBar>

                                        <CardContent>
                                            {!editing ?
                                                <div className="mb-24">
                                                    <Typography className="font-bold mb-4 text-15">Title</Typography>
                                                    <Typography>{general.Title}</Typography>
                                                </div>
                                                :
                                                <div className="mb-24">
                                                    <TextField
                                                        className={classes.formControl}
                                                        label="Title"
                                                        id="title"
                                                        name="general.Title"
                                                        value={general.Title}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                    />
                                                </div>
                                            }

                                            <div className="mb-24">
                                                <Typography className="font-bold mb-4 text-15">Skills</Typography>

                                                {general.Data.Skills.map((skill, index) => (
                                                    <Chip
                                                        onClick={() => this.toggleEditSkills(skill)}
                                                        key={index}
                                                        style={{ marginRight: 5, marginBottom: 5, }}
                                                        color="secondary"
                                                        avatar={<Avatar alt={skill.Rating}>{skill.Rating}</Avatar>}
                                                        label={skill.Skill}
                                                    />
                                                ))}
                                                {skills.editing !== true ?
                                                    <Chip
                                                        onClick={() => this.toggleEditSkills()}
                                                        key={9999}
                                                        style={{ marginRight: 5, marginBottom: 5, }}
                                                        color="primary"
                                                        avatar={<Avatar><Icon>add</Icon></Avatar>}
                                                        label="Add"
                                                    /> :
                                                    <Chip
                                                        onClick={this.createSkill}
                                                        key={9999}
                                                        style={{ marginRight: 5, marginBottom: 5, }}
                                                        color="primary"
                                                        avatar={<Avatar><Icon>save</Icon></Avatar>}
                                                        label="Save"
                                                    />
                                                }
                                                {(skills.editing) &&
                                                    <div className="mb-24 sm:flex mt-24">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Skill Name"
                                                            id="skill"
                                                            name="skills.editing.Skill"
                                                            value={skills.editing.Skill || ''}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            disabled={skills.editing.EnteredBy}
                                                        />
                                                        <div className="hidden sm:block min-w-12 pt-20">
                                                        </div>
                                                        <TextField
                                                            className={classNames(classes.formControl, "w-1/2")}
                                                            label="Rating"
                                                            id="rating"
                                                            name="skills.editing.Rating"
                                                            value={skills.editing.Rating || 0}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment placement="end">/10</InputAdornment>,
                                                                type: "number",
                                                                inputProps: { min: 0, max: 10 }
                                                            }}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                        <div className="hidden sm:block min-w-12 pt-20">
                                                        </div>
                                                        {skills.editing.EnteredBy ?
                                                            <div className="w-1/2 flex">
                                                                <Button
                                                                    className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.updateSkill}
                                                                >
                                                                    Update
                                                                </Button>
                                                                <Button
                                                                    className={classNames(classes.formControl, classes.deleteButton)}
                                                                    variant="contained"
                                                                    onClick={this.removeSkill}
                                                                >
                                                                    <Icon>delete</Icon>
                                                                </Button>
                                                            </div> :
                                                            <div className="w-1/2 flex">
                                                                <Button
                                                                    className={classNames(classes.formControl, "w-full")}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.createSkill}
                                                                >
                                                                    Add Skill
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </CardContent>
                                    </Card>

                                    <Card className="w-full mb-24 rounded-lg">
                                        <AppBar position="static" elevation={0}>
                                            <Toolbar className="pl-16 pr-8">
                                                <Typography variant="subtitle1" color="inherit" className="flex-1">
                                                    Contact
                                                </Typography>
                                                {!editing ?
                                                    <Icon onClick={this.toggleEdit} className="mr-12 cursor-pointer">edit</Icon>
                                                    :
                                                    <Icon onClick={this.saveChanges} className="mr-12 cursor-pointer">save</Icon>
                                                }
                                            </Toolbar>
                                        </AppBar>

                                        <CardContent>
                                            {!editing ?
                                                <React.Fragment>
                                                    <div className="mb-24">
                                                        <Typography className="font-bold mb-4 text-15">Phone</Typography>
                                                        <div className="flex items-center">
                                                            <Typography>{this.formatPhone(general.Phone)}</Typography>
                                                        </div>
                                                    </div>

                                                    <div className="mb-24">
                                                        <Typography className="font-bold mb-4 text-15">Email</Typography>
                                                        <div className="flex items-center">
                                                            <Typography>{general.Email}</Typography>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="mb-24">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Phone"
                                                            id="phone"
                                                            name="general.Phone"
                                                            value={general.Phone}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className="mb-24">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Email"
                                                            id="email"
                                                            name="general.Email"
                                                            value={general.Email}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }

                                        </CardContent>
                                    </Card>
                                    <FormControlLabel
                                        classes={{ root: "mb-24 sm:mb-0" }}
                                        control={
                                            <Switch
                                                checked={general.GPSEnabledYN !== 'N'}
                                                name="general.GPSEnabledYN"
                                                onChange={() => this.setState({ general: { ...general, GPSEnabledYN: general.GPSEnabledYN !== 'N' ? 'N' : 'Y' } }, this.saveChanges)}
                                                color="secondary"
                                                disabled={(!userAccessLevel || !userAccessLevel.AccessLevel || userAccessLevel.AccessLevel !== 'F') && this.props.user !== general.UserName}
                                            />
                                        }
                                        label="Enable GPS"
                                    />
                                    {/* TODO: Update how Increase Font Size is edited*/}

                                    <FormControlLabel
                                        classes={{ root: "mb-24 sm:mb-0" }}
                                        control={
                                            <Switch
                                                checked={IncreaseFontSize !== 'N'}
                                                name="IncreaseFontSize"
                                                // onChange={() => this.setState({ general: { ...general, GPSEnabledYN: general.GPSEnabledYN !== 'N' ? 'N' : 'Y' } }, this.saveChanges)}
                                                onChange={() => {
                                                    let IncreaseFontSizeNew = null;
                                                    if(general.Preferences){
                                                        let pref = JSON.parse(general.Preferences);
                                                        if(pref.IncreaseFontSize){
                                                            //IncreaseFontSize = pref.IncreaseFontSize;
                                                            pref.IncreaseFontSize = pref.IncreaseFontSize !== 'N' ? 'N' : 'Y'
                                                            IncreaseFontSizeNew = IncreaseFontSize !== 'N' ? 'N' : 'Y'
                                                        } else{
                                                            pref.IncreaseFontSize = 'Y'
                                                            IncreaseFontSizeNew = 'Y'
                                                        }

                                                        let newPref = JSON.stringify(pref);

                                                        this.setState({ general: { ...general, Preferences: newPref } }, this.saveChanges)
                                                    } else {
                                                        IncreaseFontSizeNew = IncreaseFontSize !== 'N' ? 'N' : 'Y';
                                                        let pref = {IncreaseFontSize: IncreaseFontSizeNew};
                                                        let newPref = JSON.stringify(pref)

                                                        window["warn"]("new pref ggg", pref)

                                                        this.setState({...this.state, IncreaseFontSize: IncreaseFontSizeNew, general: { ...general, Preferences: newPref }}, this.saveChanges)
                                                    }

                                                    
                                                    // this.setState({ general: { ...general, IncreaseFontSize: general.IncreaseFontSize !== 'N' ? 'N' : 'Y' } }, this.saveChanges)
                                                }}
                                                color="secondary"
                                                disabled={(!userAccessLevel || !userAccessLevel.AccessLevel || userAccessLevel.AccessLevel !== 'F') && this.props.user !== general.UserName}
                                            />
                                        }
                                        label="Increase Font Size"
                                    />
                                </React.Fragment>
                            )}

                        </FuseAnimateGroup>
                    </div>

                    <div className="flex flex-col md:w-320">
                        <FuseAnimateGroup
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        >
                            {/* <Card className="w-full mb-16">
                            <AppBar position="static" elevation={0}>
                                <Toolbar className="pl-16 pr-8">
                                    <Typography variant="subtitle1" color="inherit" className="flex-1">
                                        Connections
                                    </Typography>
                                    <Button className="normal-case" color="inherit" size="small">View All ({general.Data.Followers.length})</Button>
                                </Toolbar>
                            </AppBar>
                            <CardContent className="p-0">
                                <List className="p-8">
                                    {friends && friends.map((friend) => (
                                        <img key={friend.id} className="w-64 m-4" src={friend.avatar} alt={friend.name}/>
                                    ))}
                                </List>
                            </CardContent>
                        </Card> */}

                            <Card className="w-full mb-24 rounded-lg">
                                <AppBar position="static" elevation={0}>
                                    <Toolbar className="pl-16 pr-8">
                                        <Typography variant="subtitle1" color="inherit" className="flex-1">
                                            Companies
                                        </Typography>
                                        {/* <Button className="normal-case" color="inherit" size="small">View All ({general.Data.Companies.length})</Button> */}
                                    </Toolbar>
                                </AppBar>
                                <CardContent className="p-0">
                                    <List className="p-0">
                                        {general.Data.Companies && general.Data.Companies.map((Company, index) => {
                                            const role = Company.PrimaryRole && userRoles ? _.find(userRoles, { Role: Company.PrimaryRole }) : null;
                                            return (
                                                <ListItem key={index}>
                                                    <Avatar alt={Company.Co} className="mr-4">{role ? role.Name[0] : 'U'}</Avatar>
                                                    <ListItemText
                                                        primary={(
                                                            <div className="">
                                                                <Typography className="inline font-medium ml-4" color="primary" paragraph={false}>
                                                                    {Company.Co}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        secondary={role ? role.Name : false}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        {userAccessLevel && userAccessLevel.AccessLevel === 'F' &&
                                                            <IconButton onClick={() => this.toggleEditCompanies(Company)}>
                                                                <Icon>edit</Icon>
                                                            </IconButton>
                                                        }
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                        )}
                                        {userAccessLevel && userAccessLevel.AccessLevel === 'F' &&
                                            <ListItem key={9999} className="mt-24">
                                                {companies.editing &&
                                                    <ListItemText
                                                        primary={(
                                                            <div className="mt-12 flex">
                                                                <div className="w-full">
                                                                    <div className="mb-12">
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Company"
                                                                            id="company"
                                                                            name="companies.editing.Co"
                                                                            value={companies.editing.Co || ''}
                                                                            onChange={this.handleChange}
                                                                            variant="outlined"
                                                                            required
                                                                            select
                                                                            fullWidth
                                                                            disabled={_.find(general.Data.Companies, { Co: companies.editing.Co })}
                                                                        >
                                                                            {Companies && Companies.map((value, index) => (
                                                                                <MenuItem key={index} value={value.Data.Co.Co}>
                                                                                    {`${value.Data.Co.Co} - ${value.Data.Co.Name}`}
                                                                                </MenuItem>

                                                                            ))
                                                                            }
                                                                        </TextField>
                                                                    </div>

                                                                    <div className="mb-12">
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Primary Role"
                                                                            id="primaryRole"
                                                                            name="companies.editing.PrimaryRole"
                                                                            value={companies.editing.PrimaryRole}
                                                                            onChange={this.handleChange}
                                                                            variant="outlined"
                                                                            select
                                                                            required
                                                                            fullWidth
                                                                        >
                                                                            {userRoles && userRoles.map((value, index) =>
                                                                                <MenuItem value={value.Role}>
                                                                                    {`${value.Role} - ${value.Name}`}
                                                                                </MenuItem>
                                                                            )}
                                                                        </TextField>
                                                                        <FormControlLabel
                                                                            classes={{ root: "mb-12 mt-12" }}
                                                                            control={
                                                                                <Switch
                                                                                    checked={companies.editing.PaymentsEnabledYN === 'Y'}
                                                                                    name="companies.editing.PaymentsEnabledYN"
                                                                                    onChange={this.handleChange}
                                                                                    color="secondary"
                                                                                />
                                                                            }
                                                                            label="Payments Enabled"
                                                                        />
                                                                        {
                                                                            companies.editing.PaymentsEnabledYN === 'Y' && <TextField
                                                                                className={classes.formControl}
                                                                                label="Payment Role"
                                                                                id="paymentsRole"
                                                                                name="companies.editing.PaymentsRole"
                                                                                value={companies.editing.PaymentsRole}
                                                                                onChange={this.handleChange}
                                                                                variant="outlined"
                                                                                select
                                                                                required
                                                                                fullWidth
                                                                            >

                                                                                <MenuItem value="Default">
                                                                                    Default
                                                                                </MenuItem>

                                                                            </TextField>
                                                                        }
                                                                    </div>
                                                                    {/* <div className="mb-12">
                                                                    <FormControl variant="outlined" className={classNames("w-full", classes.formControl)}>
                                                                        <InputLabel ref={el => { this.inputLabel = el; }} htmlFor="theme-select">Theme</InputLabel>
                                                                        <Select
                                                                            value={companies.editing.SelectedTheme || settings.theme.main}
                                                                            onChange={this.handleChange}
                                                                            name="companies.editing.SelectedTheme"
                                                                            input={
                                                                                <OutlinedInput
                                                                                    labelWidth={56}
                                                                                    name="companies.editing.SelectedTheme"
                                                                                    id="theme-select"
                                                                                />
                                                                            }
                                                                        >
                                                                            {Object.entries(FuseThemes).map(([key, val]) => (
                                                                                <MenuItem
                                                                                    key={key} value={key}
                                                                                    className="m-8 mt-0 rounded-lg"
                                                                                    style={{
                                                                                        backgroundColor: val.palette.background.default,
                                                                                        color: val.palette.text.primary,
                                                                                        border: '1px solid ' + val.palette.divider
                                                                                    }}
                                                                                >
                                                                                    {_.startCase(key)}
                                                                                    <div
                                                                                        className="flex w-full h-8 block absolute pin-b pin-l pin-r"
                                                                                        style={{
                                                                                            borderTop: '1px solid ' + val.palette.divider
                                                                                        }}
                                                                                    >
                                                                                        <div className="w-1/4 h-8" style={{ backgroundColor: val.palette.primary.main }} />
                                                                                        <div className="w-1/4 h-8" style={{ backgroundColor: val.palette.secondary.main }} />
                                                                                        <div className="w-1/4 h-8" style={{ backgroundColor: val.palette.error.main }} />
                                                                                        <div className="w-1/4 h-8" style={{ backgroundColor: val.palette.background.paper }} />
                                                                                    </div>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div> */}

                                                                    <div className="flex mb-12">
                                                                        {!_.find(general.Data.Companies, { Co: companies.editing.Co }) ?
                                                                            <Button
                                                                                className={classNames(classes.formControl, "w-full")}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={this.createCompanyUser}
                                                                            >
                                                                                Add Company
                                                                            </Button> :
                                                                            <React.Fragment>
                                                                                <Button
                                                                                    className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={this.updateCompanyUser}
                                                                                >
                                                                                    Update
                                                                                </Button>
                                                                                <Button
                                                                                    className={classNames(classes.formControl, classes.deleteButton)}
                                                                                    variant="contained"
                                                                                    onClick={this.removeCompanyUser}
                                                                                >
                                                                                    <Icon>delete</Icon>
                                                                                </Button>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                }
                                                {userAccessLevel && userAccessLevel.AccessLevel === 'F' && !companies.editing &&
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => this.toggleEditCompanies()}>
                                                            <Icon>add</Icon>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        }
                                    </List>
                                </CardContent>
                            </Card>

                            <Card className="w-full mb-24 rounded-lg">
                                <AppBar position="static" elevation={0}>
                                    <Toolbar className="pl-16 pr-8">
                                        <Typography variant="subtitle1" color="inherit" className="flex-1">
                                            Stations
                                        </Typography>
                                        {/* <Button className="normal-case" color="inherit" size="small">View All ({general.Data.Stations.length})</Button> */}
                                    </Toolbar>
                                </AppBar>
                                <CardContent className="p-0">
                                    <List className="p-0">
                                        {general.Data.Stations && general.Data.Stations.map((Station, index) => (
                                            <ListItem key={index}>
                                                <Avatar alt={Station.Name} className="mr-4">{Station.Name[0]}</Avatar>
                                                <ListItemText
                                                    primary={(
                                                        <div className="">
                                                            <Typography className="inline font-medium ml-4" color="primary" paragraph={false}>
                                                                {Station.Name}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    secondary={`${this.formatPhone(Station.Phone)}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => this.toggleEditStations(Station)}>
                                                        <Icon>edit</Icon>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                        <ListItem key={9999} className="mt-24">
                                            {stations.editing &&
                                                <ListItemText
                                                    primary={(
                                                        <div className="mt-12 flex">
                                                            <div className="w-full">
                                                                <div className="mb-12">
                                                                    <TextField
                                                                        className={classes.formControl}
                                                                        label="Phone #"
                                                                        id="phone"
                                                                        name="stations.editing.Phone"
                                                                        value={stations.editing.Phone || ''}
                                                                        type="number"
                                                                        onChange={this.handleChange}
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        disabled={stations.editing.EnteredBy}
                                                                    />
                                                                </div>

                                                                <div className="mb-12">
                                                                    <TextField
                                                                        className={classes.formControl}
                                                                        label="Name"
                                                                        id="name"
                                                                        name="stations.editing.Name"
                                                                        value={stations.editing.Name || ''}
                                                                        onChange={this.handleChange}
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                    />
                                                                </div>
                                                                <div className="flex mb-12">
                                                                    {!stations.editing.EnteredBy ?
                                                                        <Button
                                                                            className={classNames(classes.formControl, "w-full")}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={this.createStation}
                                                                            disabled={!stations.editing.Name || !stations.editing.Phone || isNaN(stations.editing.Phone) || stations.editing.Phone.length !== 10}
                                                                        >
                                                                            Add Station
                                                                        </Button> :
                                                                        <React.Fragment>
                                                                            <Button
                                                                                className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={this.updateStation}
                                                                                disabled={!stations.editing.Name || !stations.editing.Phone || isNaN(stations.editing.Phone) || stations.editing.Phone.length !== 10}
                                                                            >
                                                                                Update
                                                                            </Button>
                                                                            <Button
                                                                                className={classNames(classes.formControl, classes.deleteButton)}
                                                                                variant="contained"
                                                                                onClick={this.removeStation}
                                                                            >
                                                                                <Icon>delete</Icon>
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            }
                                            {!stations.editing &&
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => this.toggleEditStations()}>
                                                        <Icon>add</Icon>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            }
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>

                            <Card className="w-full mb-24 rounded-lg">
                                <AppBar position="static" elevation={0}>
                                    <Toolbar className="pl-16 pr-8">
                                        <Typography variant="subtitle1" color="inherit" className="flex-1">
                                            Queues
                                        </Typography>
                                        {/* <Button className="normal-case" color="inherit" size="small">View All ({general.Data.Queues.length})</Button> */}
                                    </Toolbar>
                                </AppBar>
                                <CardContent className="p-0">
                                    <List className="p-0">
                                        {general.Data.Queues && general.Data.Queues.map((queue, index) => (
                                            <ListItem key={index}>
                                                <Avatar alt={queue.Queue} className="mr-4">{queue.Queue[0]}</Avatar>
                                                <ListItemText
                                                    primary={(
                                                        <div className="">

                                                            <Typography className="inline font-medium" color="secondary" paragraph={false}>
                                                                Co #{queue.Co}
                                                            </Typography>

                                                            <Typography className="inline font-medium ml-4" color="primary" paragraph={false}>
                                                                {queue.Queue}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    secondary={`${queue.Rating}/10`}
                                                />
                                                <ListItemSecondaryAction>
                                                    {queueAccessLevel && queueAccessLevel.AccessLevel === 'F' &&
                                                        <IconButton onClick={() => this.toggleEditQueues(queue)}>
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    }
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                        {queueAccessLevel && queueAccessLevel.AccessLevel === 'F' &&
                                            <ListItem key={9999} className="mt-24">
                                                {queues.editing &&
                                                    <ListItemText
                                                        primary={(
                                                            <div className="mt-12 flex">
                                                                <div className="w-full">
                                                                    <div className="mb-12">
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Queue"
                                                                            id="queue"
                                                                            name="queues.editing.Queue"
                                                                            value={queues.editing.Queue || ''}
                                                                            onChange={this.handleChange}
                                                                            variant="outlined"
                                                                            required
                                                                            fullWidth
                                                                            select
                                                                            disabled={queues.editing.EnteredBy}
                                                                        >
                                                                            {callQueues && _.filter(callQueues, (o) => _.findIndex(general.Data.Queues, (q) => o.Co === q.Co && o.Queue === q.Queue) < 0).map((queue, index) => (
                                                                                <MenuItem key={index} value={queue.Queue}>
                                                                                    {queue.Description}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </div>

                                                                    <div className="mb-12">
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Rating"
                                                                            id="rating"
                                                                            name="queues.editing.Rating"
                                                                            value={queues.editing.Rating || 0}
                                                                            onChange={this.handleChange}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment placement="end">/10</InputAdornment>,
                                                                                type: "number",
                                                                                inputProps: { min: 0, max: 10 }
                                                                            }}
                                                                            variant="outlined"
                                                                            required
                                                                            fullWidth
                                                                        />
                                                                    </div>

                                                                    <div className="mb-12">
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Text Enabled"
                                                                            id="queueText"
                                                                            name="queues.editing.TextEnabledYN"
                                                                            value={queues.editing.TextEnabledYN || 'N'}
                                                                            onChange={this.handleChange}
                                                                            variant="outlined"
                                                                            required
                                                                            fullWidth
                                                                            select
                                                                        >
                                                                            <MenuItem value="N">
                                                                                No
                                                                            </MenuItem>
                                                                            <MenuItem value="Y">
                                                                                Yes
                                                                            </MenuItem>
                                                                        </TextField>

                                                                    </div>

                                                                    {queueAccessLevel && queueAccessLevel.AccessLevel === 'F' &&
                                                                        <div className="flex mb-12">
                                                                            {!queues.editing.EnteredBy ?
                                                                                <Button
                                                                                    className={classNames(classes.formControl, "w-full")}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={this.createQueue}
                                                                                >
                                                                                    Add Queue
                                                                                </Button> :
                                                                                <React.Fragment>
                                                                                    <Button
                                                                                        className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={this.updateQueue}
                                                                                    >
                                                                                        Update
                                                                                    </Button>
                                                                                    <Button
                                                                                        className={classNames(classes.formControl, classes.deleteButton)}
                                                                                        variant="contained"
                                                                                        onClick={this.removeQueue}
                                                                                    >
                                                                                        <Icon>delete</Icon>
                                                                                    </Button>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                }
                                                {!queues.editing &&
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => this.toggleEditQueues()}>
                                                            <Icon>add</Icon>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </FuseAnimateGroup>
                    </div>
                </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        impress: impress,
    }, dispatch);
}

function mapStateToProps({ fuse, auth, spReducers }) {
    return {
        settings: fuse.settings.current,
        user: spReducers.userProfiles.User.UserName,
        Companies: spReducers.companies.List,
        Co: spReducers.companies.Co,
        callQueues: spReducers.callQueues,
        userRoles: spReducers.userRoles,
        securables: spReducers.userProfiles.User.Data.Securables,

    }
}
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AboutTab));
