import {combineReducers} from 'redux';
import academy from './academy.reducer';
import user from './user.reducer';

const academyAppReducers = combineReducers({
    academy,
    user
});

export default academyAppReducers;
