import axios from 'axios';
import { getUserData } from 'main/content/apps/units-of-measure/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_UOMS = '[UOMS APP] GET UOMS';
export const SET_SEARCH_TEXT = '[UOMS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_UOMS = '[UOMS APP] TOGGLE IN SELECTED UOMS';
export const SELECT_ALL_UOMS = '[UOMS APP] SELECT ALL UOMS';
export const DESELECT_ALL_UOMS = '[UOMS APP] DESELECT ALL UOMS';
export const OPEN_NEW_UOM_DIALOG = '[UOMS APP] OPEN NEW UOM DIALOG';
export const CLOSE_NEW_UOM_DIALOG = '[UOMS APP] CLOSE NEW UOM DIALOG';
export const OPEN_EDIT_UOM_DIALOG = '[UOMS APP] OPEN EDIT UOM DIALOG';
export const CLOSE_EDIT_UOM_DIALOG = '[UOMS APP] CLOSE EDIT UOM DIALOG';
export const ADD_UOM = '[UOMS APP] ADD UOM';
export const UPDATE_UOM = '[UOMS APP] UPDATE UOM';
export const REMOVE_UOM = '[UOMS APP] REMOVE UOM';
export const REMOVE_UOMS = '[UOMS APP] REMOVE UOMS';
export const TOGGLE_STARRED_UOM = '[UOMS APP] TOGGLE STARRED UOM';
export const TOGGLE_STARRED_UOMS = '[UOMS APP] TOGGLE STARRED UOMS';
export const SET_UOMS_STARRED = '[UOMS APP] SET UOMS STARRED ';

export function getUOMs(routeParams) {
    const request = axios.get('/api/uoms-app/uoms', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_UOMS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedUOMs(uomId) {
    return {
        type: TOGGLE_IN_SELECTED_UOMS,
        uomId
    }
}


export function selectAllUOMs() {
    return {
        type: SELECT_ALL_UOMS
    }
}

export function deSelectAllUOMs() {
    return {
        type: DESELECT_ALL_UOMS
    }
}


export function openNewUOMDialog() {
    return {
        type: OPEN_NEW_UOM_DIALOG
    }
}

export function closeNewUOMDialog() {
    return {
        type: CLOSE_NEW_UOM_DIALOG
    }
}

export function openEditUOMDialog(data) {
    return {
        type: OPEN_EDIT_UOM_DIALOG,
        data
    }
}

export function closeEditUOMDialog() {
    return {
        type: CLOSE_EDIT_UOM_DIALOG
    }
}

export function addUOM(newUOM) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post(`${window["apiLocation"]}/api/UOM`,
            newUOM
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_UOM
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Unit of Measure ${newUOM.UOM} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateUOM(uom) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.put(`${window["apiLocation"]}/api/UOM?Co=${encodeURIComponent(uom.Co)}&UOM=${encodeURIComponent(uom.UOM)}`,
            uom
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_UOM
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Unit of Measure ${uom.UOM} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeUOM(uomId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post('/api/uoms-app/remove-uom', {
            uomId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_UOM
                })
            ]).then(() => dispatch(getUOMs(routeParams)))
        );
    };
}


export function removeUOMs(uomIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post('/api/uoms-app/remove-uoms', {
            uomIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_UOMS
                }),
                dispatch({
                    type: DESELECT_ALL_UOMS
                })
            ]).then(() => dispatch(getUOMs(routeParams)))
        );
    };
}

export function toggleStarredUOM(uomId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post('/api/uoms-app/toggle-starred-uom', {
            uomId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_UOM
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUOMs(routeParams)))
        );
    };
}

export function toggleStarredUOMs(uomIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post('/api/uoms-app/toggle-starred-uoms', {
            uomIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_UOMS
                }),
                dispatch({
                    type: DESELECT_ALL_UOMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUOMs(routeParams)))
        );
    };
}

export function setUOMsStarred(uomIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post('/api/uoms-app/set-uoms-starred', {
            uomIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_UOMS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_UOMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUOMs(routeParams)))
        );
    };
}

export function setUOMsUnstarred(uomIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().uomsApp.uoms;

        const request = axios.post('/api/uoms-app/set-uoms-unstarred', {
            uomIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_UOMS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_UOMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUOMs(routeParams)))
        );
    };
}
