import axios from 'axios/index';

export const SET_CUSTOMER_SITE_DATA = '[SPCONNECTION] SET CUSTOMER SITE DATA';
export const GET_CUSTOMER_SITE_DATA = '[SPCONNECTION] GET CUSTOMER SITE DATA';
export const SUBSCRIBE_CUSTOMER_SITE_LIST = '[SPCONNECTION] SUBSCRIBE CUSTOMER SITE LIST';

export function getCustomerSiteData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CustomerSite`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CUSTOMER_SITE_DATA,
                payload: response.data
            })
        }).then()
    }
}

export function setCustomerSiteData(data) {
    return {
        type: SET_CUSTOMER_SITE_DATA,
        payload: data
    };
}