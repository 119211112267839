import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, Card, CardHeader, CardContent, CardMedia, CardActions, Divider, Icon, List, ListItem, ListItemText, Paper, Typography, TextField, MenuItem } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import classNames from 'classnames';
import axios from 'axios';
import { showMessage } from 'store/actions';


const styles = theme => ({
    root: {

    },
    updateOverride: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteOverride: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    borderTop: {
        borderTop: '1px solid lightgrey',
        paddingTop: 4,
        paddingBottom: 4,
    },
    liRoot: {
        padding: 0
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '70%',
    },
    wrap: {
        whiteSpace: 'normal !important',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '70%',
    },
});

const initialState = {
    override: {
        ID: null,
        Co: null,
        Status: null,
        Description: null,
        TechnicianTerminology: null,
        DispatchTerminology: null,
        ButtonStyle: null,
        ButtonText: null,
        QuickChangeYN: null,
        AddedBy: null,
        AddedDate: null,
        UpdatedBy: null,
        UpdatedDate: null,
        Notes: null,
        Data: {
            ErrMsg: null
        }
    },
}

class TripsStatusTerminology extends Component {

    state = {
        ..._.cloneDeepWith(initialState)
    }

    componentDidMount() {

    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    canBeSubmitted = () => {
        const { Status, TechnicianTerminology } = this.state.override;
        return Status !== null && TechnicianTerminology && TechnicianTerminology.length > 0;
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    addOverride = () => {
        const { Co } = this.props.company;
        const { override } = this.state;
        const request = axios.post(`${window["apiLocation"]}/api/TripStatusTerminology`, { ...override, Co });

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.setState({ override: _.cloneDeepWith(initialState.override) }, () => {
                    this.props.showMessage({
                        message: `Status override successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        }).catch((error) => {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        });
    }

    updateOverride = () => {
        const { override } = this.state;
        const { Co, Status } = override;
        const request = axios.put(`${window["apiLocation"]}/api/TripStatusTerminology?Co=${Co}&Status=${Status}`, { ...override, Co });

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.setState({ override: _.cloneDeepWith(initialState.override) }, () => {
                    this.props.showMessage({
                        message: `Status override successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        }).catch((error) => {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        });
    }

    removeOverride = () => {
        const { override } = this.state;
        const { Co, Status } = override;
        const request = axios.delete(`${window["apiLocation"]}/api/TripStatusTerminology?Co=${Co}&Status=${Status}`);

        request.then((response) => {
            if (response.data && response.data.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.setState({ override: _.cloneDeepWith(initialState.override) }, () => {
                    this.props.showMessage({
                        message: `Status override successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        }).catch((error) => {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        });
    }

    render() {
        const { classes, user, data, securables, company } = this.props;
        const statusProps =
            [
                { text: 'Scheduled', icon: 'access_time', color: 'rgb(100, 100, 200)', dateProp: 'ScheduledDate', timeProp: 'ScheduledTime' },
                { text: 'Notified', icon: 'offline_bolt', color: 'rgb(0, 150, 250)', dateProp: 'NotifiedDate', timeProp: 'NotifiedTime' },
                { text: 'Accepted', icon: 'offline_pin', color: 'rgb(0, 50, 250)', dateProp: 'AcceptedDate', timeProp: 'AcceptedTime' },
                { text: 'Rejected', icon: 'cancel', color: 'rgb(210, 0, 0)', dateProp: 'RejectedDate', timeProp: 'RejectedTime' },
                { text: 'En-Route', icon: 'explore', color: 'rgb(50, 170, 200)', dateProp: 'EnRouteDate', timeProp: 'EnRouteTime', QuickChangeYN: 'Y' },
                { text: 'Arrived', icon: 'my_location', color: 'rgb(50, 150, 100)', dateProp: 'ArrivedDate', timeProp: 'ArrivedTime' },
                { text: 'In Progress', icon: 'play_circle_outline', color: 'rgb(150, 200, 50)', dateProp: 'InProgressDate', timeProp: 'InProgressTime', QuickChangeYN: 'Y' },
                { text: 'On Hold', icon: 'pause_circle_outline', color: 'rgb(210, 100, 100)', dateProp: 'OnHoldDate', timeProp: 'OnHoldTime' },
                { text: 'Completed', icon: 'check_circle', color: 'rgb(100, 100, 100)', dateProp: 'CompletedDate', timeProp: 'CompletedTime', QuickChangeYN: 'Y' }
            ];
        const stat = statusProps[this.state.override.Status];
        const accessLevel = _.find(securables, { Securable: "companies" });

        return (
            <div className={classNames("w-full")}>
                {accessLevel && accessLevel.AccessLevel === "F" &&
                    <React.Fragment>
                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">{!stat ? "add" : stat.icon}</Icon>{`${!this.state.override.ID ? "Add" : "Update"} Terminology Override`}</Typography>
                        <div className="sm:flex">
                            <TextField
                                className={classes.formControl}
                                label="Status"
                                id="Status"
                                name="override.Status"
                                value={this.state.override.Status !== null ? this.state.override.Status : ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                select
                                fullWidth
                            >
                                {
                                    statusProps.map((value, index) => (
                                        <MenuItem key={index} value={index}>
                                            {value.text}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <TextField
                                className={classes.formControl}
                                label="Preferred Term"
                                id="technicianTerminology"
                                name="override.TechnicianTerminology"
                                value={this.state.override.TechnicianTerminology || ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                            />
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <TextField
                                className={classes.formControl}
                                label="Button Text"
                                id="buttonText"
                                name="override.ButtonText"
                                value={this.state.override.ButtonText || ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                            />
                            {/* <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <TextField
                                className={classes.formControl}
                                label="Button Style"
                                id="buttonStyle"
                                name="override.ButtonStyle"
                                value={this.state.override.ButtonStyle || ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                select
                                fullWidth
                            >
                                <MenuItem value="I">
                                    Icon Only
                                </MenuItem>
                                <MenuItem value="T">
                                    Text Only
                                </MenuItem>
                                <MenuItem value="B">
                                    Icon + Text
                                </MenuItem>
                            </TextField> */}
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <TextField
                                className={classes.formControl}
                                label="Quick Option?"
                                id="quickChangeYN"
                                name="override.QuickChangeYN"
                                value={this.state.override.QuickChangeYN || ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                select
                                fullWidth
                            >
                                <MenuItem value="Y">
                                    Yes
                                </MenuItem>
                                <MenuItem value="N">
                                    No
                                </MenuItem>
                            </TextField>
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            {!this.state.override.ID ?
                                <Button
                                    className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addOverride}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button> :
                                <div className="w-full flex">
                                    <Button
                                        className={classNames(classes.formControl, classes.updateOverride, "w-full")}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.updateOverride}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        className={classNames(classes.formControl, classes.deleteOverride)}
                                        variant="contained"
                                        onClick={this.removeOverride}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                }
                {statusProps.map((value, index) => {
                    let override = _.find(company.Data.TripStatusTerminologies, { Status: index });
                    if (!override) {
                        override = {
                            ..._.cloneDeepWith(initialState.override),
                            Status: index,
                            TechnicianTerminology: value.text,
                            DispatchTerminology: value.text,
                            ButtonText: value.text,
                            ButtonStyle: 'B',
                        };
                    }
                    return (
                        <div key={index} onClick={() => this.setState({ override })} className={classNames("w-full p-12", index > 0 ? classes.borderTop : '')}>
                            <ListItem classes={{ root: classes.liRoot }} className="relative cursor-pointer min-h-36">
                                {value.icon &&
                                    <Icon className="mr-6" style={{ color: value.color }}>{value.icon}</Icon>
                                }
                                <ListItemText classes={{ root: classes.liRoot, primary: classes.title }} primary={<span className={classNames("font-bold text-14")}>{override.TechnicianTerminology || value.text}
                                </span>} />
                                <Icon className="pin-r pin-b pin-t absolute m-auto text-16">{override && override.ID ? "edit" : "add_circle_outline"}</Icon>
                            </ListItem>
                        </div>
                    );
                })}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage
    }, dispatch);
}

function mapStateToProps({ companiesApp, spReducers }) {
    return {
        user: spReducers.userProfiles.User,
        company: _.find(spReducers.companies.List, { Co: companiesApp.companies.companyDialog.data.Co }).Data.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TripsStatusTerminology)));
