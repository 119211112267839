import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class PurchaseOrderLinesList extends Component {

    state = {
        selectedPurchaseOrderLinesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedPurchaseOrderLineMenu = (event) => {
        this.setState({ selectedPurchaseOrderLinesMenu: event.currentTarget });
    };

    closeSelectedPurchaseOrderLinesMenu = () => {
        this.setState({ selectedPurchaseOrderLinesMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        const { Co, securables, classes, purchaseOrder, searchText, openEditPurchaseOrderLineDialog, openNewPurchaseOrderLineDialog, readOnly } = this.props;
        const data = this.getFilteredArray((!this.props.selectedPurchaseOrderLineData ? !this.props.purchaseOrderLineData ? [] : this.props.purchaseOrderLineData : this.props.selectedPurchaseOrderLineData), searchText);
        const { selectedPurchaseOrderLinesMenu } = this.state;
        const types = { 'I': 'Inventory', 'W': 'Work Order' };
        let poData = { Co };
        const accessLevel = _.find(securables, { Securable: "purchase-orders" });
        if (purchaseOrder) {
            const { Co, PO, POType, WorkOrder, Scope, InventoryLocation, TaxCode, Data } = purchaseOrder;
            poData = { Co, PO, POLineType: POType, WorkOrder, Scope, InventoryLocation, POLine: Data.Lines ? Data.Lines.length + 1 : 1, TaxCode, Data: { WorkOrder: purchaseOrder && purchaseOrder.Data ? purchaseOrder.Data.WorkOrder : null } };
        }

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {!readOnly && accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={() => openNewPurchaseOrderLineDialog({ ...poData })}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Purchase Order Lines</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        openEditPurchaseOrderLineDialog({ ...rowInfo.original }, readOnly);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "",
                                accessor: "Data",
                                minWidth: 48,
                                className: "justify-center",
                                Cell: row => {
                                    const { CostTotal, Data } = row.original;
                                    if (Data.VPPOIT || Data.VPPOIB) {
                                        return (
                                            <Tooltip placement="top" title={Data.VPPOIB && Data.VPPOIB.OrigCost ? this.formatDollars(Data.VPPOIB.OrigCost) : Data.VPPOIT && Data.VPPOIT.OrigCost ? this.formatDollars(Data.VPPOIT.OrigCost) : ''}>
                                                <Icon color={(Data.VPPOIB && Data.VPPOIB.OrigCost === CostTotal) ? "primary" : (Data.VPPOIT && Data.VPPOIT.OrigCost === CostTotal) ? "secondary" : "error"}>{(Data.VPPOIB && Data.VPPOIB.OrigCost === CostTotal) ? "cloud_upload" : (Data.VPPOIT && Data.VPPOIT.OrigCost === CostTotal) ? "cloud_done" : "error_outline"}</Icon>
                                            </Tooltip>
                                        )
                                    }
                                }
                            },
                            {
                                Header: "Line",
                                accessor: "POLine",
                                minWidth: 64,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Line Type",
                                id: "POLineType",
                                minWidth: 128,
                                className: "justify-center",
                                Cell: row => (
                                    types[row.original.POLineType]
                                ),
                            },
                            {
                                Header: "Qty",
                                accessor: "Qty",
                                className: "justify-center",
                                minWidth: 96,
                            },
                            {
                                Header: "UOM",
                                accessor: "UOM",
                                minWidth: 64,
                                className: "justify-center",

                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 256,

                            },
                            {
                                Header: "Date Needed",
                                accessor: "DateNeeded",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    row.original.DateNeeded &&
                                    moment(row.original.DateNeeded).toDate().toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Received Qty",
                                id: "received",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    (row.original.Data && row.original.Data.Receiving) &&
                                    _.sumBy(row.original.Data.Receiving, (o) => { return Number(o.ReceivedQty) })
                                )
                            },
                            {
                                Header: "Cost",
                                accessor: "CostTotal",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => (
                                    row.original.CostTotal && `$${Number(row.original.CostTotal).toFixed(2)}`
                                )
                            },
                            {
                                Header: "Tax",
                                accessor: "TaxTotal",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => (row.original.TaxTotal && row.original.TaxableYN === 'Y') ? `$${Number(row.original.TaxTotal).toFixed(2)}` : '$0.00'
                            },
                        ]}
                        defaultPageSize={100}
                        noDataText="No Purchase Order Lines found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPurchaseOrderLines: Actions.getPurchaseOrderLines,
        getUserData: Actions.getUserData,
        toggleInSelectedPurchaseOrderLines: Actions.toggleInSelectedPurchaseOrderLines,
        selectAllPurchaseOrderLines: Actions.selectAllPurchaseOrderLines,
        deSelectAllPurchaseOrderLines: Actions.deSelectAllPurchaseOrderLines,
        openEditPurchaseOrderLineDialog: Actions.openEditPurchaseOrderLineDialog,
        removePurchaseOrderLines: Actions.removePurchaseOrderLines,
        removePurchaseOrderLine: Actions.removePurchaseOrderLine,
        toggleStarredPurchaseOrderLine: Actions.toggleStarredPurchaseOrderLine,
        toggleStarredPurchaseOrderLines: Actions.toggleStarredPurchaseOrderLines,
        setPurchaseOrderLinesStarred: Actions.setPurchaseOrderLinesStarred,
        setPurchaseOrderLinesUnstarred: Actions.setPurchaseOrderLinesUnstarred,
        openNewPurchaseOrderLineDialog: Actions.openNewPurchaseOrderLineDialog
    }, dispatch);
}

function mapStateToProps({ purchaseOrderLinesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        purchaseOrderLineData: spReducers.purchaseOrderLines,
        searchText: purchaseOrderLinesApp.purchaseOrderLines.searchText,
        user: spReducers.userProfiles.User.UserName,
        inventoryPurchaseOrders: spReducers.inventoryPurchaseOrders,
        employees: spReducers.employees,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderLinesList)));
