import { FuseUtils } from "@fuse";
import _ from "@lodash";
import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as ChatActions from "main/chatPanel/store/actions";
import * as DialerActions from "main/dialer/store/actions";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { openEditTechnicianDialog } from "../technicians/store/actions";
import * as Actions from "./store/actions";
import getProfileImage from "../../../functions/getProfileImageUrl";

const styles = () => ({
  root: {
    maxHeight: "calc(100vh - 306px)",
  },
  mailItem: {},
  avatarRoot: {
    marginRight: 0,
  },
  avatar: {
    borderRadius: "50%",
    boxShadow: "2px 2px 6px #333",
    width: 48,
    height: 48,
  },
  action: {
    margin: 8,
    marginRight: -4,
    marginTop: 8,
  },
  icon: {
    marginLeft: 12,
    marginRight: 12,
  },
  title: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  subheader: {},
  tripAvatar: {
    borderRadius: "50%",
    boxShadow: "1px 1px 2px rgba(0,0,0,.25)",
    width: 26,
    height: 26,
    backgroundColor: "rgb(100,100,100)",
  },
  tripAction: {
    margin: 0,
    marginRight: -4,
    marginTop: 0,
  },
  tripIcon: {
    marginLeft: 4,
    marginRight: 12,
  },
  labels: {},
  technician: {
    width: "100%",
    border: "none",
    boxShadow: "none",
    background: "transparent",
    alignSelf: "start",
    position: "absolute",
    top: "calc(50% - 32px)",
  },
  mobileTechnician: {
    width: "100%",
    border: "none",
    boxShadow: "none",
    background: "transparent",
    alignSelf: "start",
    position: "absolute",
    top: "calc(50% - 40px)",
  },
  trip: {
    width: "auto",
    border: "none",
    zIndex: 1,
    overflow: "visible",
    alignSelf: "start",
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    background: "rgb(250, 250, 250)",
    boxShadow: "1px 1px 4px #333",
  },
  content: {
    overflow: "hidden",
  },
  header: {
    width: "100%",
  },
  popper: {
    opacity: 1,
    background: "transparent",
    margin: 10,
  },
  tooltip: {
    opacity: 1,
    padding: 0,
    margin: 0,
    boxShadow: "2px 2px 6px #3333",
  },
  popper: {
    opacity: 1,
    marginTop: 8,
    zIndex: 3,
    background: "#fff",
  },
  tooltip: {
    opacity: 1,
    padding: 8,
    margin: 0,
    borderRadius: 4,
    background: "#fff",
    boxShadow: "2px 2px 6px #3333",
  },
  badge: {
    top: 5,
    right: 15,
  },
  techBadge: {
    top: 5,
  },
  techNameBadge: {
    top: "90%",
    right: "90%",
    backgroundColor: "rgba(0,0,0,.5)",
    color: "#fff",
    fontSize: "x-small",
  },
  openBadge: {
    backgroundColor: "#4dbce9",
    color: "#fff",
  },
  busyBadge: {
    backgroundColor: "#6c4de9",
    color: "#fff",
  },
  capacityBadge: {
    backgroundColor: "#f75151",
    color: "#fff",
  },
  header: {
    zIndex: 3,
  },
});

class DispatchBoardTechnician extends Component {
  state = {
    anchorEl: null,
    anchorTech: null,
    matches: false,
  };

  mediaQuery = null;
  componentDidMount() {
    this.mediaQuery = window.matchMedia("(min-width: 1024px)");

    this.mediaQuery.addListener(this.handleMediaQueryChange);

    this.handleMediaQueryChange(this.mediaQuery);
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.handleMediaQueryChange);
  }

  componentDidUpdate(prevState, prevProps) {

    // if(this.state.technicians !== prevState.technicians){
    //   console.log('+++state not changed')
    // }
    // console.log('+++this.state', this.state)
    // console.log('+++prevState', prevState)
    // console.log('+++this.props', this.props)
    // console.log('+++prevProps', prevProps)
  }

  handleMediaQueryChange = (mediaQuery) => {
    this.setState({ matches: mediaQuery.matches });
  };

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map((id) => entities[id]);
    if (searchText.length === 0) {
      return arr;
    }
    const data = FuseUtils.filterArrayByString(arr, searchText);
    return data;
  };

  openSelectedDispatchBoardMenu = (event) => {
    this.setState({ selectedDispatchBoardMenu: event.currentTarget });
  };

  closeSelectedDispatchBoardMenu = () => {
    this.setState({ selectedDispatchBoardMenu: null });
  };

  handleChange = (event) => {
    // window["log"](this, event.target);
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === "checkbox"
          ? event.target.checked
            ? "Y"
            : "N"
          : event.target.value
      )
    );
  };

  openMenu = (event, tech) => {
    // window["warn"](tech);
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      anchorTech: tech,
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, anchorEl: null, anchorTech: null });
  };

  handleContactClick = (tech) => {
    this.props.openChatPanel();
    const { employees, user } = this.props;
    const { Co } = tech;
    const employee = _.find(employees, { Co, Employee: tech.Employee });
    if (employee && employee.UserName && user.UserName !== employee.UserName) {
      this.props.getChat(employee.UserName);
    }
    // this.scrollToTop();
  };

  handleNotify = (tech) => {
    const { Co, date, notifyAll, openTechnician } = this.props;
    const dt = new Date(date).toLocaleDateString("en-US").replace(/\//g, "-");
    // window["warn"](Co, tech.Technician, dt);
    notifyAll(Co, tech.Technician, dt);
  };

  render() {
    const { classes, technician, openTechnician, Co, user, rows } = this.props;
    const { anchorTech, anchorEl } = this.state;
    const avatar = "assets/images/avatars/profile.jpg";
    const scheduled = [];
    if (anchorTech && anchorTech.tripObj) {
      Object.keys(anchorTech.tripObj).map((key) => {
        const trip = anchorTech.tripObj[key];
        if (trip.Status === 0) {
          scheduled.push(trip);
        }
      });
    }
    const co = _.find(user.Data.Companies, { Co });
    const tech = co.Data.Technician;
    let minHeight = rows * 46 + 6;
    if (minHeight < 96) {
      minHeight = 96;
    }
    return (
      <div
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onDragLeave={(e) => e.stopPropagation()}
        className="w-full py-16 relative"
        style={{ minHeight }}
      >
        {anchorEl && (
          <Menu
            id={`tech-Menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.closeMenu}
          >
            <MenuItem
              onClick={() => {
                openTechnician(anchorTech);
                this.closeMenu();
              }}
            >
              <Icon className="mr-8">account_box</Icon>View Profile
            </MenuItem>
            {anchorTech && scheduled.length > 0 && (
              <MenuItem
                onClick={() => {
                  this.handleNotify(anchorTech);
                  this.closeMenu();
                }}
              >
                <Icon className="mr-8">textsms</Icon>Notify All
              </MenuItem>
            )}
            {anchorTech &&
              (!tech || tech.Technician !== anchorTech.Technician) && (
                <React.Fragment>
                  <MenuItem
                    onClick={() => {
                      this.handleContactClick(anchorTech);
                      this.closeMenu();
                    }}
                  >
                    <Icon className="mr-8">message</Icon>Message
                  </MenuItem>
                  {/* <MenuItem onClick={() => { this.handleCall(anchorTech); this.closeMenu(); }}><Icon className="mr-8">phone</Icon>Call</MenuItem> */}
                </React.Fragment>
              )}
          </Menu>
        )}
        {this.state.matches ? (
          <Card className={classes.technician}>
            <CardHeader
              className="p-0 pr-4"
              classes={{
                action: classes.action,
                avatar: classes.icon,
                title: classes.title,
                subheader: classes.subheader,
              }}
              avatar={
                <Tooltip
                  placement="right"
                  title={`Scheduled for ${technician.duration.toFixed(2)} Hr${
                    technician.duration > 1 ? "s" : ""
                  }`}
                >
                  <Badge
                    classes={{
                      badge: classNames(
                        classes.techBadge,
                        technician.duration < 4
                          ? classes.openBadge
                          : technician.duration < 8
                          ? classes.busyBadge
                          : classes.capacityBadge
                      ),
                    }}
                    color="primary"
                    badgeContent={technician.trips.length}
                  >
                    <Avatar
                      classes={{ root: classes.avatarRoot }}
                      className={classes.avatar}
                      alt={technician.name}
                      src={
                        technician.Data && technician.Data.Avatar
                          ? getProfileImage(`Co=${technician.Co}&ID=${technician.Data.Avatar}&Thumb=true`
                          ): avatar
                      }
                    />
                  </Badge>
                </Tooltip>
              }
              action={
                <React.Fragment>
                  <IconButton
                    onClick={(e) => {
                      this.openMenu(e, technician);
                    }}
                    aria-owns={undefined}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                </React.Fragment>
              }
              title={`${technician.FirstName} ${technician.LastName}`}
              subheader={
                technician.DefaultBusinessUnit &&
                `${technician.DefaultBusinessUnit} ${
                  technician.DefaultDepartment
                    ? technician.DefaultDepartment
                    : ""
                }`
              }
            />
          </Card>
        ) : (
          <Card className={classes.mobileTechnician}>
            <CardHeader
              className="p-0 pt-16 pb-16"
              classes={{ avatar: classes.icon }}
              avatar={
                <Tooltip
                  placement="right-start"
                  title={`${technician.FirstName} ${technician.LastName}`}
                >
                  <Badge
                    classes={{
                      badge: classNames(
                        classes.techBadge,
                        technician.duration < 4
                          ? classes.openBadge
                          : technician.duration < 8
                          ? classes.busyBadge
                          : classes.capacityBadge
                      ),
                    }}
                    color="primary"
                    badgeContent={Object.keys(technician.tripObj).length}
                  >
                    <Tooltip
                      placement="right-end"
                      title={`Scheduled for ${technician.duration} Hr${
                        technician.duration > 1 ? "s" : ""
                      }`}
                    >
                      <Badge
                        classes={{
                          badge: classNames(classes.techNameBadge),
                        }}
                        badgeContent={`${technician.FirstName.substr(
                          0,
                          1
                        )}${technician.LastName.substr(0, 1)}`}
                      >
                        <Avatar
                          onClick={(e) => {
                            this.openMenu(e, technician);
                          }}
                          classes={{ root: classes.avatarRoot }}
                          className={classes.avatar}
                          alt={technician.name}
                          src={
                            technician.Data && technician.Data.Avatar
                              ? getProfileImage(`Co=${technician.Co}&ID=${technician.Data.Avatar}&Thumb=true`
                              ): avatar
                          }
                        />
                      </Badge>
                    </Tooltip>
                  </Badge>
                </Tooltip>
              }
            />
          </Card>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openTechnician: openEditTechnicianDialog,
      getChat: ChatActions.getChat,
      openChatPanel: ChatActions.openChatPanel,
      toggleDialer: DialerActions.toggleDialer,
      notifyAll: Actions.notifyAll,
    },
    dispatch
  );
}

function mapStateToProps({ spReducers, dispatchBoardApp }) {
  return {
    date: dispatchBoardApp.dispatchBoard.date,
    user: spReducers.userProfiles.User,
    Co: spReducers.companies.Co,
    technicians: spReducers.technicians,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DispatchBoardTechnician)
  )
);
