import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedSupervisorIds: [],
    routeParams: {},
    supervisorDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            avatar: 'assets/images/avatars/profile.jpg',
            Co: null,
            Supervisor: null,
            Employee: null,
            FirstName: null,
            LastName: null,
            MiddleInitial: null,
            TeamCategory: null,
            TeamRole: null,
            Phone: null,
            Email: null,
            Bio: null,
            ExemptYN: 'N',
            Notes: null,
            ActiveYN: 'Y',
            DefaultBusinessUnit: null,
            DefaultDivision: null,
            DefaultDepartment: null,
            value: 1,
            histTab: 0,
            Data: {
                WorkOrders: [],
                Trips: [],
                Timecard: null,
                Timecards: [],
                searchResults: [],
            },
            showTabs: false,
        },
        loading: false,
        date: new Date(),
    }
};

const supervisorsReducer = function (state = initialState, action) {
    switch (action.type) {
        default:
            {
                return state;
            }
    }
};

export default supervisorsReducer;
