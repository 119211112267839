import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, Tabs, Tab, Badge, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import UserRoleSecurablesList from './UserRoleSecurablesList';
import UserRoleReportsList from './UserRoleReportsList';
import UserRoleDashboardsList from './UserRoleDashboardsList';
import UserRoleAssignmentsList from './UserRoleAssignmentsList';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import classNames from 'classnames';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 0px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    updateRecord: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteRecord: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    deleteAssignmentRecord: {
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
});

const newUserRoleState = {
    ID: null,
    Co: null,
    Role: null,
    Name: '',
    DefaultHomepage: null,
    Notes: '',
    Data: {
        Securables: [{
            Securable: 'learning',
            AccessLevel: 'E',
        }],
        Users: [],
    },
    value: 0,
    securable: {
        ID: null,
        Securable: null,
        AccessLevel: null,
    },
    report: {
        ID: null,
        Report: null,
        AccessLevel: null,
    },
    dashboard: {
        ID: null,
        Dashboard: null,
        AccessLevel: null,
    },
    assignment: {
        ID: null,
        UserName: null,
    }
};

class UserRoleDialog extends Component {
    state = { ...newUserRoleState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newUserRoleState.Co = this.props.Co;
        if (!prevProps.userRoleDialog.props.open && this.props.userRoleDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.userRoleDialog.type === 'edit' &&
                this.props.userRoleDialog.data &&
                !_.isEqual(this.props.userRoleDialog.data, prevState)) {
                this.setState({ ...this.props.userRoleDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.userRoleDialog.type === 'new' &&
                !_.isEqual(newUserRoleState, prevState)) {
                this.setState({ ...newUserRoleState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    closeComposeDialog = () => {
        this.props.userRoleDialog.type === 'edit' ? this.props.closeEditUserRoleDialog() : this.props.closeNewUserRoleDialog();
    };

    canBeSubmitted() {
        const { Name, DefaultHomepage } = this.state;
        return (
            Name && Name.length > 0 && DefaultHomepage && DefaultHomepage.length > 0
        );
    }

    setSecurable = (securable) => {
        this.setState({ ...this.state, securable });
    }

    setReport = (report) => {
        this.setState({ ...this.state, report });
    }

    setDashboard = (dashboard) => {
        this.setState({ ...this.state, dashboard });
    }

    setAssignment = (assignment) => {
        window["warn"](assignment);
        this.setState({ ...this.state, assignment });
    }

    render() {
        const { classes, userRoleDialog, addUserRole, updateUserRole, securables, pages, Co, users, reports, dashboards } = this.props;
        const { value } = this.state;
        const userAvatar = '../../assets/images/avatars/profile.jpg';

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...userRoleDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={value === 0 ? "sm" : "md"}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {userRoleDialog.type === 'new' ? 'New User Role' : `Edit ${this.state.Name} User Role`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            // variant="fullWidth"
                            >
                                <Tab disableRipple icon={<Icon>contacts</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Securables ? this.state.Data.Securables.length : 0} color="error"><Icon>lock_open</Icon></Badge>} label="Access" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Reports ? _.filter(this.state.Data.Reports, { Co }).length : 0} color="error"><Icon>format_list_numbered</Icon></Badge>} label="Reports" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Dashboards ? _.filter(this.state.Data.Dashboards, { Co }).length : 0} color="error"><Icon>insert_chart</Icon></Badge>} label="Dashboards" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Users ? this.state.Data.Users.length : 0} color="error"><Icon>group</Icon></Badge>} label="Users" />
                            </Tabs>
                        </AppBar>

                        <DialogContent classes={{ root: "p-0" }}>
                            {value === 0 && <TabContainer>
                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Role"
                                        id="role"
                                        name="Role"
                                        value={this.state.Role}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Name"
                                        id="name"
                                        name="Name"
                                        value={this.state.Name}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Homepage"
                                        options={_.filter(pages, (o) => { return _.findIndex(this.state.Data.Securables, { Securable: o.id }) > -1 || !o.securables })}
                                        menuItemComponent={(value) => {
                                            return (
                                                <MenuItem value={value.url}>
                                                    <div className="flex">
                                                        <Icon className="mr-8">{value.icon}</Icon>
                                                        <label style={{ paddingTop: 2 }}>{`${value.title}`}</label>
                                                    </div>
                                                </MenuItem>
                                            );
                                        }}
                                        value={this.state.DefaultHomepage}
                                        noflip={true}
                                        onSelect={(option) => this.setState({ ...this.state, DefaultHomepage: option.url })}
                                        required
                                    />
                                </div>

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                            </TabContainer>}
                            {value === 1 && <TabContainer>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">lock_open</Icon>{`${!this.state.securable.ID ? "Add" : "Update"} Access`}</Typography>
                                <div className="sm:flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Securable"
                                        options={securables}
                                        menuItemComponent={(value) => {
                                            const index = _.findIndex(this.state.Data.Securables, { Securable: value.id });
                                            if (index < 0 || (this.state.securable.ID && this.state.securable.Securable === value.id)) {
                                                return (
                                                    <MenuItem value={value.id}>
                                                        <div className="flex">
                                                            {/* <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.title}>
                                                            <Icon>{value.icon}</Icon>
                                                        </Avatar> */}
                                                            {`${value.title}`}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            }
                                        }}
                                        disabled={this.state.securable.ID}
                                        value={this.state.securable.Securable}
                                        noflip={true}
                                        onSelect={(option) => this.setState({ ...this.state, securable: { ...this.state.securable, Securable: option.id } })}
                                        required
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Access Level"
                                        id="accessLevel"
                                        select
                                        name="securable.AccessLevel"
                                        value={this.state.securable.AccessLevel || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value="R">
                                            Read-Only
                                        </MenuItem>
                                        <MenuItem value="E">
                                            Read / Write
                                        </MenuItem>
                                        <MenuItem value="F">
                                            Full Access
                                        </MenuItem>
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {!this.state.securable.ID ?
                                        <Button
                                            className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const { Role, securable } = this.state;
                                                this.props.addUserRoleSecurable({ ...securable, Role, Data: { ErrMsg: null } });
                                                this.setState({ ...this.state, securable: newUserRoleState.securable })
                                            }}
                                            disabled={!this.state.ID || !this.state.securable.AccessLevel || !this.state.securable.Securable}
                                        >
                                            Add
                                        </Button> :
                                        <div className="w-full flex">
                                            <Button
                                                className={classNames(classes.formControl, classes.updateRecord, "w-full")}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.props.updateUserRoleSecurable({ ...this.state.securable, Data: { ErrMsg: null } });
                                                    this.setState({ ...this.state, securable: newUserRoleState.securable })
                                                }}
                                            // disabled={!this.canMemberBeSubmitted()}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                className={classNames(classes.formControl, classes.deleteRecord)}
                                                variant="contained"
                                                onClick={() => {
                                                    this.props.removeUserRoleSecurable({ ...this.state.securable, Data: { ErrMsg: null } });
                                                    this.setState({ ...this.state, securable: newUserRoleState.securable })
                                                }}
                                                disabled={_.find(pages, { id: this.state.securable.Securable }) && _.find(pages, { id: this.state.securable.Securable }).url === this.state.DefaultHomepage}
                                            >
                                                <Icon>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <UserRoleSecurablesList onSelected={this.setSecurable} securables={[...this.state.Data.Securables || []]} />
                            </TabContainer>}
                            {value === 2 && <TabContainer>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>{`${!this.state.report.ID ? "Add" : "Update"} Access`}</Typography>
                                <div className="sm:flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Report"
                                        options={reports}
                                        menuItemComponent={(value) => {
                                            if (_.findIndex(this.state.Data.Reports, { Report: value.Report }) < 0 || (this.state.report.Report && this.state.report.Report === value.Report))
                                                return (
                                                    <MenuItem value={value.Report}>
                                                        <div className="flex">
                                                            {/* <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.title}>
                                                                <Icon>{value.icon}</Icon>
                                                            </Avatar> */}
                                                            {`${value.Name}`}
                                                        </div>
                                                    </MenuItem>
                                                );
                                        }}
                                        disabled={this.state.report.ID}
                                        value={this.state.report.Report}
                                        noflip={true}
                                        onSelect={(option) => this.setState({ ...this.state, report: { ...this.state.report, Co, Report: option.Report } })}
                                        required
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Access Level"
                                        id="accessLevel"
                                        select
                                        name="report.AccessLevel"
                                        value={this.state.report.AccessLevel || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value="R">
                                            Read-Only
                                        </MenuItem>
                                        <MenuItem value="E">
                                            Read / Write
                                        </MenuItem>
                                        <MenuItem value="F">
                                            Full Access
                                        </MenuItem>
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {!this.state.report.ID ?
                                        <Button
                                            className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const { Role, report } = this.state;
                                                this.props.addUserRoleReport({ ...report, Role, Data: { ErrMsg: null } });
                                                this.setState({ ...this.state, report: newUserRoleState.report })
                                            }}
                                            disabled={!this.state.ID || !this.state.report.AccessLevel || !this.state.report.Report}
                                        >
                                            Add
                                        </Button> :
                                        <div className="w-full flex">
                                            <Button
                                                className={classNames(classes.formControl, classes.updateRecord, "w-full")}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.props.updateUserRoleReport({ ...this.state.report, Data: { ErrMsg: null } });
                                                    this.setState({ ...this.state, report: newUserRoleState.report })
                                                }}
                                            // disabled={!this.canMemberBeSubmitted()}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                className={classNames(classes.formControl, classes.deleteRecord)}
                                                variant="contained"
                                                onClick={() => {
                                                    this.props.removeUserRoleReport({ ...this.state.report, Data: { ErrMsg: null } });
                                                    this.setState({ ...this.state, report: newUserRoleState.report })
                                                }}
                                                disabled={_.find(pages, { id: this.state.report.Report }) && _.find(pages, { id: this.state.report.Report }).url === this.state.DefaultHomepage}
                                            >
                                                <Icon>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <UserRoleReportsList onSelected={this.setReport} reports={[..._.filter(this.state.Data.Reports || [], { Co })]} />
                            </TabContainer>}
                            {value === 3 && <TabContainer>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">insert_chart</Icon>{`${!this.state.dashboard.ID ? "Add" : "Update"} Access`}</Typography>
                                <div className="sm:flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Dashboard"
                                        options={dashboards}
                                        menuItemComponent={(value) => {
                                            if (_.findIndex(this.state.Data.Dashboards, { Dashboard: value.Dashboard }) < 0 || (this.state.dashboard.Dashboard && this.state.dashboard.Dashboard === value.Dashboard))
                                                return (
                                                    <MenuItem value={value.Dashboard}>
                                                        <div className="flex">
                                                            {/* <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.title}>
                                                                <Icon>{value.icon}</Icon>
                                                            </Avatar> */}
                                                            {`${value.Name}`}
                                                        </div>
                                                    </MenuItem>
                                                );
                                        }}
                                        disabled={this.state.dashboard.ID}
                                        value={this.state.dashboard.Dashboard}
                                        noflip={true}
                                        onSelect={(option) => this.setState({ ...this.state, dashboard: { ...this.state.dashboard, Co, Dashboard: option.Dashboard } })}
                                        required
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Access Level"
                                        id="accessLevel"
                                        select
                                        name="dashboard.AccessLevel"
                                        value={this.state.dashboard.AccessLevel || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value="R">
                                            Read-Only
                                        </MenuItem>
                                        <MenuItem value="E">
                                            Read / Write
                                        </MenuItem>
                                        <MenuItem value="F">
                                            Full Access
                                        </MenuItem>
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {!this.state.dashboard.ID ?
                                        <Button
                                            className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const { Role, dashboard } = this.state;
                                                this.props.addUserRoleDashboard({ ...dashboard, Role, Data: { ErrMsg: null } });
                                                this.setState({ ...this.state, dashboard: newUserRoleState.dashboard })
                                            }}
                                            disabled={!this.state.ID || !this.state.dashboard.AccessLevel || !this.state.dashboard.Dashboard}
                                        >
                                            Add
                                        </Button> :
                                        <div className="w-full flex">
                                            <Button
                                                className={classNames(classes.formControl, classes.updateRecord, "w-full")}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.props.updateUserRoleDashboard({ ...this.state.dashboard, Data: { ErrMsg: null } });
                                                    this.setState({ ...this.state, dashboard: newUserRoleState.dashboard })
                                                }}
                                            // disabled={!this.canMemberBeSubmitted()}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                className={classNames(classes.formControl, classes.deleteRecord)}
                                                variant="contained"
                                                onClick={() => {
                                                    this.props.removeUserRoleDashboard({ ...this.state.dashboard, Data: { ErrMsg: null } });
                                                    this.setState({ ...this.state, dashboard: newUserRoleState.dashboard })
                                                }}
                                                disabled={_.find(pages, { id: this.state.dashboard.Dashboard }) && _.find(pages, { id: this.state.dashboard.Dashboard }).url === this.state.DefaultHomepage}
                                            >
                                                <Icon>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <UserRoleDashboardsList onSelected={this.setDashboard} dashboards={[..._.filter(this.state.Data.Dashboards || [], { Co })]} />
                            </TabContainer>}
                            {value === 4 && <TabContainer>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">group</Icon>{`${!this.state.assignment.ID ? "Add" : "Update"} Access`}</Typography>
                                <div className="sm:flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="User Account"
                                        options={users}
                                        menuItemComponent={(value) => {
                                            if (_.findIndex(this.state.Data.Users, { UserName: value.UserName }) < 0 || (this.state.assignment.UserName && this.state.assignment.UserName === value.UserName))
                                                return <MenuItem value={value.UserName}>
                                                    <div className="flex">
                                                        <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                        {`${value.FirstName} ${value.LastName}`}
                                                    </div>
                                                </MenuItem>
                                        }}
                                        noflip={true}
                                        portal={!matches}
                                        value={this.state.assignment.UserName}
                                        onSelect={(option) => this.setState({ ...this.state, assignment: { ...this.state.assignment, UserName: option.UserName } })}
                                        required
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {!this.state.assignment.ID ?
                                        <Button
                                            className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const { Role, assignment } = this.state;
                                                this.props.addUserRoleAssignment({ ...assignment, Co, Role, Data: { ErrMsg: null } });
                                                this.setState({ ...this.state, assignment: newUserRoleState.assignment })
                                            }}
                                            disabled={!this.state.ID || !this.state.assignment.UserName}
                                        >
                                            Add
                                        </Button> :
                                        <Button
                                            className={classNames(classes.formControl, classes.deleteAssignmentRecord)}
                                            variant="contained"
                                            onClick={() => {
                                                this.props.removeUserRoleAssignment({ ...this.state.assignment, Data: { ErrMsg: null } });
                                                this.setState({ ...this.state, assignment: newUserRoleState.assignment })
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </Button>
                                    }
                                </div>
                                <UserRoleAssignmentsList onSelected={this.setAssignment} securables={[...(this.state.Data.Users || [])]} />
                            </TabContainer>}
                        </DialogContent>

                        {userRoleDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addUserRole(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateUserRole(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditUserRoleDialog: Actions.closeEditUserRoleDialog,
        closeNewUserRoleDialog: Actions.closeNewUserRoleDialog,
        addUserRole: Actions.addUserRole,
        updateUserRole: Actions.updateUserRole,
        removeUserRole: Actions.removeUserRole,
        addUserRoleSecurable: Actions.addUserRoleSecurable,
        updateUserRoleSecurable: Actions.updateUserRoleSecurable,
        removeUserRoleSecurable: Actions.removeUserRoleSecurable,
        addUserRoleReport: Actions.addUserRoleReport,
        updateUserRoleReport: Actions.updateUserRoleReport,
        removeUserRoleReport: Actions.removeUserRoleReport,
        addUserRoleDashboard: Actions.addUserRoleDashboard,
        updateUserRoleDashboard: Actions.updateUserRoleDashboard,
        removeUserRoleDashboard: Actions.removeUserRoleDashboard,
        addUserRoleAssignment: Actions.addUserRoleAssignment,
        removeUserRoleAssignment: Actions.removeUserRoleAssignment,
    }, dispatch);
}

function mapStateToProps({ userRolesApp, spReducers }) {
    return {
        userRoleDialog: userRolesApp.userRoles.userRoleDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.Securables,
        users: spReducers.userProfiles.Users,
        pages: spReducers.userProfiles.Pages,
        reports: spReducers.reporting.reports,
        dashboards: spReducers.reporting.dashboards,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UserRoleDialog));
