import axios from 'axios/index';

export const SET_COST_TYPE_DATA = '[SPCONNECTION] SET COST TYPE DATA';
export const GET_COST_TYPE_DATA = '[SPCONNECTION] GET COST TYPE DATA';
export const SUBSCRIBE_COST_TYPE_LIST = '[SPCONNECTION] SUBSCRIBE COST TYPE LIST';

export function getInitialCostTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CostType`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getCostTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CostType`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_COST_TYPE_DATA,
                payload: response.data
            })
        });
    }
}

export function setCostTypeData(data) {
    return {
        type: SET_COST_TYPE_DATA,
        payload: data
    };
}