import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newRateTemplateState = {
    Co: null,
    BusinessUnit: '',
    Division: '',
    RateTemplate: '',
    LaborType: null,
    Rate: 0.00,
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    }
};

class RateTemplateLaborDialog extends Component {
    state = { ...newRateTemplateState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newRateTemplateState.Co = this.props.Co;
        if (!prevProps.rateTemplateLaborDialog.props.open && this.props.rateTemplateLaborDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.rateTemplateLaborDialog.type === 'edit' &&
                this.props.rateTemplateLaborDialog.data &&
                !_.isEqual(this.props.rateTemplateLaborDialog.data, prevState)) {
                this.setState({ ...this.props.rateTemplateLaborDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.rateTemplateLaborDialog.type === 'new' &&
                this.props.rateTemplateLaborDialog.data &&
                !_.isEqual(this.props.rateTemplateLaborDialog.data, prevState)) {
                this.setState({ ...this.props.rateTemplateLaborDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.rateTemplateLaborDialog.type === 'edit' ? this.props.closeEditRateTemplateLaborDialog() : this.props.closeNewRateTemplateLaborDialog();
    };

    canBeSubmitted() {
        const { RateTemplate, BusinessUnit, Division, LaborType } = this.state;
        return (
            RateTemplate.length > 0 && BusinessUnit.length > 0 && Division.length > 0 && !isNaN(LaborType)
        );
    }

    render() {
        const { classes, rateTemplateLaborDialog, addRateTemplateLabor, updateRateTemplateLabor, removeRateTemplateLabor, businessUnits, divisions, rateTemplates, laborTypes } = this.props;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...rateTemplateLaborDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {rateTemplateLaborDialog.type === 'new' ? 'New Labor Rate' : 'Edit Labor Rate'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Business Unit"

                                    select
                                    id="businessUnit"
                                    name="BusinessUnit"
                                    value={this.state.BusinessUnit}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.BusinessUnit ? true : false }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={true}
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                        <MenuItem value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Division"
                                    id="division"
                                    select
                                    name="Division"
                                    value={this.state.Division}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.Division ? true : false }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={true}
                                >
                                    {(this.state.BusinessUnit && this.state.BusinessUnit.length > 0 ?
                                        (divisions && divisions.length > 0 ? _.filter(divisions, { BusinessUnit: this.state.BusinessUnit }).map((value) =>
                                            <MenuItem value={value.Division}>
                                                {value.Description}
                                            </MenuItem>
                                        ) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Business Unit
                                        </MenuItem>
                                    )}
                                </TextField>
                            </div>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Rate Template"

                                    id="rateTemplate"
                                    name="RateTemplate"
                                    value={this.state.RateTemplate}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.RateTemplate ? true : false }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={true}
                                >
                                    {(rateTemplates && rateTemplates.length > 0 ? rateTemplates.map((value) => (
                                        <MenuItem value={value.RateTemplate}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Labor Type"

                                    id="laborType"
                                    name="LaborType"
                                    value={this.state.LaborType}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.LaborType ? true : false }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    select
                                    disabled={(rateTemplateLaborDialog.type === 'new' ? false : true)}
                                >
                                    {(laborTypes && laborTypes.length > 0 ? _.filter(laborTypes, { BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }).map((value) => (
                                        <MenuItem value={value.LaborType}>
                                            {value.LaborType} - {value.Name}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Rate"
                                    id="rate"
                                    name="Rate"
                                    value={this.state.Rate}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        type: "number"
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </DialogContent>

                        {rateTemplateLaborDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addRateTemplateLabor(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                        </Button>
                            </DialogActions>
                        ) : (
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateRateTemplateLabor(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                        </Button>
                                    <IconButton
                                        onClick={() => {
                                            removeRateTemplateLabor(this.state);
                                            this.closeComposeDialog();
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </DialogActions>
                            )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditRateTemplateLaborDialog: Actions.closeEditRateTemplateLaborDialog,
        closeNewRateTemplateLaborDialog: Actions.closeNewRateTemplateLaborDialog,
        addRateTemplateLabor: Actions.addRateTemplateLabor,
        updateRateTemplateLabor: Actions.updateRateTemplateLabor,
        removeRateTemplateLabor: Actions.removeRateTemplateLabor
    }, dispatch);
}

function mapStateToProps({ rateTemplatesApp, spReducers }) {
    return {
        rateTemplateLaborDialog: rateTemplatesApp.rateTemplates.rateTemplateLaborDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        rateTemplates: spReducers.rateTemplates,
        Co: spReducers.companies.Co,
        laborTypes: spReducers.laborTypes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RateTemplateLaborDialog));
