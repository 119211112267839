import {combineReducers} from 'redux';
import agreementTypeTasks from './agreement-type-tasks.reducer';
import user from './user.reducer';

const agreementTypeTasksAppReducers = combineReducers({
    agreementTypeTasks,
    user
});

export default agreementTypeTasksAppReducers;
