import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 21,
        borderRadius: 2,
        padding: '0 6px',
        fontSize: 11,
        backgroundColor: 'rgba(0,0,0,.08);'
    },
    color: {
        width: 8,
        height: 8,
        marginRight: 4,
        borderRadius: '50%'
    }
});

function TodoChip({ classes, title, background, color, className }) {
    return (
        <div className={classNames(classes.root, className)}>
            <div className={classNames(classes.color)} style={{backgroundColor: background || '#3f3f3f'}} />
            <div>{title}</div>
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(TodoChip);
