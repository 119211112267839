import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import _ from '@lodash';
import axios from 'axios';
import { getChat, openChatPanel } from 'main/chatPanel/store/actions';
import { openEditEventDialog } from 'main/content/apps/calendar/store/actions';
import { openEditEstimateDialog } from 'main/content/apps/estimates/store/actions';
import { openEditInvoiceDialog } from 'main/content/apps/invoices/store/actions';
import { openCardDialog } from 'main/content/apps/scrumboard/store/actions';
import { openEditTodoDialog } from 'main/content/apps/todo/store/actions';
import { updateTrip } from 'main/content/apps/trips/store/actions';
import { setRecording } from 'main/content/apps/voice-mailboxes/store/actions';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import moment from 'moment';
import { addAlert, setNotificationData, openAppSettings, registerPushNotificationListeners } from 'store/actions';
import trips from '../../reducers/spConnection/trips.reducer';
import { initPushNotifications } from './push-notifications.actions';

export const SET_LOCAL_NOTIFICATION = '[LOCAL NOTIFICATION] SET LOCAL NOTIFICATION';
export const SET_SCHEDULED_LOCAL_NOTIFICATION = '[LOCAL NOTIFICATION] SET SCHEDULED LOCAL NOTIFICATION';
export const INIT_LOCAL_NOTIFICATIONS = '[LOCAL NOTIFICATION] INIT LOCAL NOTIFICATIONS';
export const REMOVE_LOCAL_NOTIFICATION = '[LOCAL NOTIFICATION] REMOVE LOCAL NOTIFICATION';
export const CLEAR_LOCAL_NOTIFICATIONS = '[LOCAL NOTIFICATION] CLEAR LOCAL NOTIFICATIONS';

export function registerLocalNotificationListeners() {
    return (dispatch, getState) => {
        if (Capacitor.getPlatform() !== 'web') {
            LocalNotifications.registerActionTypes({
                types: [
                    {
                        id: 'SiteArrival',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'update',
                                title: 'In Progress',
                                foreground: true
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            }
                        ]
                    },
                    {
                        id: 'SiteDeparture',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'parts-run',
                                title: 'Parts Run',
                            },
                            {
                                id: 'on-hold',
                                title: 'On Hold',
                                input: true,
                                inputButtonTitle: 'Update',
                                inputPlaceholder: 'Enter Hold Reason'
                            },
                            {
                                id: 'update',
                                title: 'Complete',
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            }
                        ]
                    },
                    {
                        id: 'ToDoItem',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    },
                    {
                        id: 'ProjectTask',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    },
                    {
                        id: 'CalendarEvent',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    },
                    {
                        id: 'Voicemail',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    },
                    {
                        id: 'Trip',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    },
                    {
                        id: 'TripReminder',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'en-route',
                                title: 'On My Way',
                                foreground: true
                            },
                            {
                                id: 'snooze',
                                title: 'Snooze',
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    },
                    {
                        id: 'TripCompletion',
                        actions: [
                            {
                                id: 'view',
                                title: 'View',
                                foreground: true
                            },
                            {
                                id: 'extend',
                                title: 'Extend',
                                foreground: true
                            },
                            {
                                id: 'on-hold',
                                title: 'On Hold',
                                input: true,
                                inputButtonTitle: 'Update',
                                inputPlaceholder: 'Enter Hold Reason'
                            },
                            {
                                id: 'update',
                                title: 'Complete',
                            },
                            {
                                id: 'snooze',
                                title: 'Snooze',
                            },
                            {
                                id: 'remove',
                                title: 'Dismiss',
                                destructive: true
                            },
                        ]
                    }
                ]
            })

            LocalNotifications.addListener("localNotificationReceived", (resp) => {
                LocalNotifications.getDeliveredNotifications().then((notifications) => {
                    dispatch({
                        type: SET_LOCAL_NOTIFICATION,
                        payload: notifications
                    });
                });
            });

            LocalNotifications.addListener("localNotificationActionPerformed", (resp) => {
                const state = getState();
                const { voicemail, calendar } = state.spReducers;
                const { Events, ToDos } = calendar.data;
                console.log('Local Notification Action Performed:', resp);
                const { notification, actionId, inputValue } = resp;
                const { extra } = notification;
                let Data = null;
                if (extra.data) {
                    Data = extra.data;
                }
                if (actionId !== 'remove') {
                    dispatch(markNotificationRead(notification.id));
                    switch (notification.actionTypeId) {
                        case 'TripReminder': {
                            switch (actionId) {
                                case 'en-route':
                                    {
                                        if (Data) {
                                            Promise.all([
                                                dispatch(updateTrip({ ...Data, Status: 4 }))
                                            ]).then(() => {
                                                dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true));
                                                const { Address1, City, State, Zip } = Data.Data.WorkOrder.Data.Site;
                                                dispatch(addAlert({
                                                    open: true,
                                                    title: 'En-Route to Work Order',
                                                    type: 'TripDirections',
                                                    data: { ID: Data.ID, Address1, City, State, Zip, WorkOrder: Data.WorkOrder, platform: Capacitor.getPlatform() },
                                                    icon: "directions",
                                                    cancelText: "Dismiss",
                                                    hideConfirm: true
                                                }));

                                            });
                                        }
                                    }
                                    break;
                                case 'snooze':
                                    {
                                        const time = moment().add(15, 'minutes').toDate();
                                        dispatch(createNotification(notification.id, notification.title, notification.body, Data, 'TripReminder', 'SPTripActions', { at: time }));
                                    }
                                    break;
                                default:
                                    {
                                        if (Data) {
                                            dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true));
                                        }
                                    }
                                    break;
                            }
                        }
                            break;
                        case 'TripCompletion': {
                            switch (actionId) {
                                case 'view': {
                                    if (Data) {
                                        dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true));
                                    }
                                }
                                    break;
                                case 'on-hold': {
                                    if (Data) {
                                        dispatch(updateTrip({ ...Data, Status: 7, Notes: inputValue }));
                                    }
                                }
                                    break;
                                case 'update': {
                                    if (Data) {
                                        dispatch(updateTrip({ ...Data, Status: 8 }));
                                    }
                                }
                                    break;
                                case 'snooze':
                                    {
                                        const time = moment().add(15, 'minutes').toDate();
                                        dispatch(createNotification(notification.id, notification.title, notification.body, Data, 'TripCompletion', 'SPTripActions', { at: time }));
                                    }
                                    break;
                                default:
                                    {
                                        if (Data) {
                                            dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true));
                                            dispatch(addAlert({
                                                open: true,
                                                title: 'Extend Trip Duration',
                                                type: 'TripExtension',
                                                data: { ...Data, platform: Capacitor.getPlatform() },
                                                icon: "timer",
                                                cancelText: "Cancel",
                                                hideConfirm: true,
                                                onAction: (trip) => dispatch(updateTrip(trip))
                                            }));
                                        }
                                    }
                                    break;
                            }
                        }
                            break;
                        case 'SiteArrival': {
                            switch (actionId) {
                                case 'view': {
                                    if (Data) {
                                        dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true));
                                    }
                                }
                                    break;
                                default: {
                                    if (Data) {
                                        Promise.all([
                                            dispatch(updateTrip(Data))
                                        ]).then(() => {
                                            dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true))
                                        });
                                    }
                                }
                                    break;
                            }
                        }
                            break;
                        case 'SiteDeparture': {
                            switch (actionId) {
                                case 'view': {
                                    if (Data) {
                                        dispatch(openEditWorkOrderDialog(Data.Data.WorkOrder, Data.Trip, true));
                                    }
                                }
                                    break;
                                case 'parts-run': {
                                    if (Data) {
                                        dispatch(updateTrip({ ...Data, Status: 7, Notes: 'Parts Run' }));
                                    }
                                }
                                    break;
                                case 'on-hold': {
                                    if (Data) {
                                        dispatch(updateTrip({ ...Data, Status: 7, Notes: inputValue }));
                                    }
                                }
                                    break;
                                default: {
                                    if (Data) {
                                        dispatch(updateTrip(Data))
                                    }
                                }
                                    break;
                            }
                        }
                            break;
                        case 'Trip': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                const { Co, WorkOrder, Trip } = data;
                                dispatch(openEditWorkOrderDialog({ Co, WorkOrder }, Trip));
                            }
                        }
                            break;
                        case 'Email': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                const { Co, WorkOrder, BusinessUnit, Division, Estimate, Invoice } = data;
                                if (WorkOrder) {
                                    dispatch(openEditWorkOrderDialog({ Co, WorkOrder }));
                                }
                                if (BusinessUnit && Division && Estimate) {
                                    dispatch(openEditEstimateDialog({ Co, BusinessUnit, Division, Estimate }));
                                }
                                if (Invoice) {
                                    dispatch(openEditInvoiceDialog({ Co, Invoice }));
                                }
                            }
                        }
                            break;
                        case 'ToDoItem': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                const event = _.find(ToDos, { Type: 'T', ID: data.ID });
                                if (event) {
                                    dispatch(openEditTodoDialog(event));
                                }
                            }
                        }
                            break;
                        case 'ChatMessage': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                if (data.UserName) {
                                    dispatch(openChatPanel());
                                    dispatch(getChat(data.UserName, data.ID));
                                }
                            }
                        }
                            break;
                        case 'ProjectTask': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                const event = _.find(ToDos, { Type: 'P', ID: data.ID });
                                if (event) {
                                    dispatch(openCardDialog(event, true));
                                }
                            }
                        }
                            break;
                        case 'CalendarEvent': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                const event = _.find(Events, { ID: data.ID });
                                if (event) {
                                    const { StartDate, EndDate, Title, Description, AllDayYN, AddedBy, PublicYN } = event;
                                    dispatch(openEditEventDialog({
                                        id: event.ID,
                                        start: moment.utc(StartDate).local().toDate(),
                                        end: moment.utc(EndDate).local().toDate(),
                                        title: Title,
                                        desc: Description,
                                        allDay: AllDayYN,
                                        publicYN: PublicYN,
                                        AddedBy,
                                        Data: {
                                            ...event.Data,
                                            type: "E",
                                        }
                                    }));
                                }
                            }
                        }
                            break;
                        case 'Voicemail': {
                            if (Data) {
                                const data = JSON.parse(Data);
                                const recording = _.find(voicemail, { CallSid: data.CallSid });
                                if (recording) {
                                    dispatch(setRecording(recording));
                                } else {
                                    dispatch(setRecording(data.CallSid, true));
                                }
                            }
                        }
                            break;
                    }
                } else {
                    dispatch(markNotificationDeleted(notification.id));
                }
                LocalNotifications.getDeliveredNotifications().then((notifications) => {
                    dispatch({
                        type: SET_LOCAL_NOTIFICATION,
                        payload: notifications
                    });
                });
            });
        }
    }
}

export function initNotifications() {
    return (dispatch, getState) => {
        if (Capacitor.getPlatform() !== 'web') {
            LocalNotifications.requestPermissions().then((d) => {
                console.log(d);
                dispatch({
                    type: INIT_LOCAL_NOTIFICATIONS,
                    payload: d.display
                });
                if (d.display === 'granted') {
                    dispatch(registerLocalNotificationListeners());
                    dispatch(initPushNotifications());
                } else {
                    if (Capacitor.getPlatform() !== 'web') {
                        dispatch(registerLocalNotificationListeners());
                        dispatch(registerPushNotificationListeners());
                        dispatch(addAlert({
                            open: true,
                            title: 'Permission Required',
                            content: 'Service Point Pro needs to display Notifications periodically, but has not received permission to do so - Please open Application Settings and enable Notifications.',
                            icon: "notifications",
                            cancelText: "Dismiss",
                            confirmText: "Settings",
                            onConfirm: () => openAppSettings()
                            // hideConfirm: true
                        }));
                    }
                }

            })
        }
    }
}

export function createNotification(id, title, body, data, actionTypeId, group, schedule) {
    return (dispatch, getState) => {
        if (Capacitor.getPlatform() !== 'web') {
            LocalNotifications.schedule({
                notifications: [
                    {
                        title,
                        body,
                        id,
                        extra: {
                            data
                        },
                        schedule,
                        actionTypeId,
                        threadIdentifier: group || 'SPProNotifications',
                        group: group || 'SPProNotifications',
                        sound: 'sp-pro-alert.wav'
                    }
                ]
            }).then((d) => {
                console.log(d);
                LocalNotifications.getDeliveredNotifications().then((notifications) => {
                    dispatch({
                        type: SET_LOCAL_NOTIFICATION,
                        payload: notifications
                    });
                })
            })
        }
    }
}

export function markNotificationRead(ID) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/Notification?ID=${ID}`);
        request.then(() => {
            const { notifications } = getState().spReducers;
            const rec = _.find(notifications, { ID });
            rec.DateReceived = new Date();
            dispatch(setNotificationData([...notifications]));
        }).catch(() => {
            const { notifications } = getState().spReducers;
            const rec = _.find(notifications, { ID });
            rec.DateReceived = new Date();
            dispatch(setNotificationData([...notifications]));
        })
    }
}

export function markNotificationDeleted(ID) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/Notification?ID=${ID}`);
        request.then(() => {
            const { notifications } = getState().spReducers;
            const index = _.findIndex(notifications, { ID });
            if (index > -1) {
                notifications.splice(index, 1);
            }
            dispatch(setNotificationData([...notifications]));
        }).catch(() => {
            const { notifications } = getState().spReducers;
            const index = _.findIndex(notifications, { ID });
            if (index > -1) {
                notifications.splice(index, 1);
            }
            dispatch(setNotificationData([...notifications]));
        })
    }
}

export function clearLocalNotifications() {
    return (dispatch, getState) => {
        if (Capacitor.getPlatform() !== 'web') {
            LocalNotifications.removeAllDeliveredNotifications().then((d) => {
                console.log(d);
                dispatch({
                    type: CLEAR_LOCAL_NOTIFICATIONS
                });
                LocalNotifications.getDeliveredNotifications().then((notifications) => {
                    dispatch({
                        type: SET_LOCAL_NOTIFICATION,
                        payload: notifications
                    });
                });
            })
        }
    }
}