import axios from 'axios/index';

export const GET_FILES = '[FILE MANAGER APP] GET FILES';

export function getFiles() {
    return {
        type: GET_FILES,
        payload: []
    }
}
