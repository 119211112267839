import { FuseAnimate, FuseUtils } from '@fuse'
import { Icon, Typography, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import * as Actions from './store/actions'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { FeatureFlagsContext } from '../../../../providers/featureFlagsProvider'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

function LoadingDiv() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .65)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        className='loader stretchBar'
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
        }}
      >
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
        <br />
        <img
          style={{ width: 80, marginTop: 15 }}
          src='assets/images/splash/SPLoading.png'
        />
      </div>
    </div>
  )
}

function NoData() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .25)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
          paddingTop: 75,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        No Records Found
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 288px)',
    paddingBottom: 0,
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    width: 32,
    height: 32,
  },
  labels: {},
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  borderTop: {
    borderTop: '1px solid lightgrey',
  },
  error: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  ok: {
    background: '#333',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  greenText: {
    color: '#d1e751',
  },
  listContainer: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: '#f6f6f6',
  },
  type_charge: {
    color: '#fff',
    backgroundColor: '#d1e751',
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
  type_refund: {
    color: '#fff',
    backgroundColor: 'orange',
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
  type_void: {
    color: '#fff',
    backgroundColor: '#6f6f6f',
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
})

class DepositsList extends Component {
  static contextType = FeatureFlagsContext;

  state = {
    selectedPaymentsMenu: null,
    anchorEl: null,
    featureFlags: this.context
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText.replace(/\|/g, ' '))
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  render() {
    const { classes, loading, searchText, Co, filters } = this.props
    const data = this.getFilteredArray(
      !this.props.selectedDepositData
        ? !this.props.depositData || !this.props.depositData.data
          ? []
          : this.props.depositData.data
        : this.props.selectedDepositData,
      searchText,
    )

    return (
      <div className='w-full'>
        <Media query='(min-width: 768px)'>
          {() => (
            <FuseAnimate animation='transition.slideUpIn' delay={300}>
              <div>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                >
                  <Icon color='primary' className='text-32 mr-12'>
                    payment
                  </Icon>
                  Payment Deposits
                </Typography>
                <ReactTableFixedColumns
                  className={classNames(
                    classes.root,
                    '-striped -highlight border-0 rounded',
                  )}
                  getTrProps={(state, rowInfo) => {
                    const trn = rowInfo ? rowInfo.original : null
                    return {
                      className: `cursor-pointer`,
                      onClick: e => {
                        e.stopPropagation()
                        if (rowInfo) {
                          if (
                            this.state.featureFlags['spp-238-new-deposit-deatils'] === true
                          ) {
                            this.props.getDepositTransactions(
                              Co,
                              rowInfo.original,
                            );
                          } else {
                            this.props.getDeposit(Co, rowInfo.original);
                          }
                        }
                      },
                    }
                  }}
                  // manual
                  data={data}
                  loading={loading} // Display the loading overlay when we need it
                  NoDataComponent={loading ? LoadingDiv : NoData}
                  LoadingComponent={loading ? LoadingDiv : undefined}
                  columns={[
                    {
                      Header: 'Date',
                      accessor: 'batched_at',
                      width: 96,
                      Cell: row =>
                        moment.utc(row.value).local().format('M/D/YYYY'),
                      className: 'justify-center font-bold text-12',
                    },
                    {
                      Header: 'Batch',
                      accessor: 'batch_id',
                      width: 224,
                      Cell: row => (
                        <div className='w-full text-center text-12 truncate'>
                          {row.value}
                        </div>
                      ),
                      className: 'justify-center font-bold',
                    },
                    {
                      Header: 'Transactions',
                      accessor: 'count',
                      width: 128,
                      // Cell: row => row.original.is_voided ? <Icon className="text-20 text-grey">cancel</Icon> : row.original.success ? <Icon color="secondary" className="text-20">check_circle_outline</Icon> : <Icon color="error" className="text-20">error</Icon>,
                      className: 'justify-center',
                    },
                    {
                      Header: 'Amount',
                      accessor: 'sum',
                      width: 128,
                      Cell: row => (
                        <div className='flex'>
                          {this.formatDollars(row.value)}
                        </div>
                      ),
                      className: 'justify-center',
                    },
                    {
                      Header: 'Fees',
                      accessor: 'fees',
                      width: 128,
                      Cell: row => (
                        <div className='flex'>
                          {this.formatDollars(row.value)}
                        </div>
                      ),
                      className: 'justify-center',
                    },
                  ]}
                  defaultPageSize={20}
                  noDataText='No Deposits found'
                />
              </div>
            </FuseAnimate>
          )}
        </Media>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPayments: Actions.getPayments,
      setPaymentFilters: Actions.setPaymentFilters,
      setTransaction: Actions.setTransaction,
      getDeposit: Actions.getDeposit,
      getDepositTransactions: Actions.getDepositTransactions,
    },
    dispatch,
  )
}

function mapStateToProps({ paymentsApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    depositData: paymentsApp.payments.deposits,
    deposit: paymentsApp.payments.deposit,
    searchText: paymentsApp.payments.searchText,
    user: spReducers.userProfiles.User.UserName,
    securables: spReducers.userProfiles.User.Data.Securables,
    filters: paymentsApp.payments.filters,
    loading: paymentsApp.payments.loading,
    transaction: paymentsApp.payments.transaction,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositsList)),
)
