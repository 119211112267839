import _ from '@lodash';
import { guid } from '../spConnection/offline.actions';

export const SET_ALERTS = '[ALERTS] SET ALERTS';

export function setAlerts(alerts) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_ALERTS,
            alerts,
        });
    }
}

export function addAlert(alert) {
    return (dispatch, getState) => {
        const state = getState();
        const { type, data } = alert;
        const alerts = _.cloneDeepWith(state.alerts);
        alert.id = data && data.ID ? `${type}-${data.ID}` : guid();
        const index = _.findIndex(alerts, { id: alert.id });
        if (index < 0) {
            alerts.push(alert);
            dispatch({
                type: SET_ALERTS,
                alerts
            });
        }
    }
}

export function removeAlert(alert) {
    return (dispatch, getState) => {
        const state = getState();
        const alerts = _.cloneDeepWith(state.alerts);
        const index = _.findIndex(alerts, { id: alert.id });
        if (index > -1) {
            alerts.splice(index, 1);
            dispatch({
                type: SET_ALERTS,
                alerts
            });
        }
    }
}