import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar, Checkbox, Icon, IconButton, ListItemText, MenuItem, withStyles } from '@material-ui/core';
import ToolbarMenu from './ToolbarMenu';
import _ from '@lodash';

class MembersMenu extends Component {
    state = {
        anchorEl: null
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleToggleMember = (UserName) => {
        const { Co, members, User, card } = this.props;
        const { BoardID, ListID, ID, } = card;
        const AddedBy = User.UserName;
        const memb = _.find(this.props.idMembers, { UserName });
        const method = memb ? 'DELETE' : 'ADD';
        const member = {
            Co,
            SupportRequestID: ID,
            UserName,
            AddedBy,
            AddedDate: new Date(),
            Data: {
                ErrMsg: null,
            }
        }
        window["warn"](member);
        this.props.onToggleMember(member, method);
    }

    render() {
        const { Co, members, idMembers, onToggleMember } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';

        return (
            <div>
                <IconButton color="inherit" onClick={this.handleMenuClick}>
                    <Icon>account_circle</Icon>
                </IconButton>
                <ToolbarMenu state={this.state.anchorEl} onClose={this.handleMenuClose}>
                    <div className="max-h-256 overflow-y-auto">
                        {members && _.filter(members, { ActiveYN: 'Y' }).map(member => {
                            const checked = Boolean(_.find([...this.props.idMembers], { UserName: member.UserName }));
                            return (
                                <MenuItem
                                    className="px-8"
                                    key={member.id}
                                    onClick={(ev) => {
                                        this.handleToggleMember(member.UserName);
                                    }}
                                >
                                    <Checkbox checked={checked} />
                                    <Avatar className="w-32 h-32" src={avatar} />
                                    <ListItemText>
                                        {`${member.FirstName} ${member.LastName}`}
                                    </ListItemText>
                                </MenuItem>
                            );
                        })}
                    </div>
                </ToolbarMenu>
            </div>
        );
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ supportApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User,
        card: supportApp.request,
    }
}

export default withStyles({}, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersMenu)));
