import {combineReducers} from 'redux';
import serviceUnitTypes from './service-unit-types.reducer';
import user from './user.reducer';

const serviceUnitTypesAppReducers = combineReducers({
    serviceUnitTypes,
    user
});

export default serviceUnitTypesAppReducers;
