import _ from '@lodash';
import { AppBar, Avatar, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, MenuItem, Switch, TextField, Toolbar, Typography, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const initialState = {
    Co: null,
    Customer: null,
    Site: null,
    UnitID: null,
    Part: null,
    Qty: null,
    UOM: null,
    Description: null,
    PartType: null,
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    Material: null,
    Data: {
        ErrMsg: null
    },
    searching: false,
};

class ServiceUnitPartDialog extends Component {
    state = { ...initialState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        initialState.Co = this.props.Co;
        if (!prevProps.serviceUnitPartDialog.props.open && this.props.serviceUnitPartDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.serviceUnitPartDialog.type === 'edit' &&
                this.props.serviceUnitPartDialog.data &&
                !_.isEqual(this.props.serviceUnitPartDialog.data, prevState)) {
                this.setState({ ...initialState, ...this.props.serviceUnitPartDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.serviceUnitPartDialog.type === 'new' &&
                !_.isEqual(this.props.serviceUnitPartDialog.data, prevProps.serviceUnitPartDialog.data)) {
                const { serviceUnit } = this.props;
                const { Co, Customer, Site, UnitID, UnitType, UnitCat } = serviceUnit;
                this.setState({ ...initialState, Co, Customer, Site, UnitID });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.serviceUnitPartDialog.type === 'edit' ? this.props.closeEditServiceUnitPartDialog() : this.props.closeNewServiceUnitPartDialog();
    };

    canBeSubmitted() {
        const {  Co, Customer, Site, UnitID, PartType, Qty, UOM, Description } = this.state;
        window["warn"]("pma canBeSubmitted 122", Co, Customer, Site, UnitID, Qty, UOM, Description )
        return (
            Co && Customer && Site && UnitID && Qty && UOM && Description && Description.length > 0
        );
    }

    handlePart = (part) => {
        const { Material, UOM, Description } = part;
        this.setState({ ...this.state, Material, UOM, Description })
    }

    render() {
        const { classes, serviceUnitPartDialog, addServiceUnitPart, updateServiceUnitPart, removeServiceUnitPart, serviceUnitTypes, uoms, materials } = this.props;
        const { searching, Description } = this.state;
        const matAvatar = 'assets/images/logos/SP_Cog_Gradient.png';
        const { serviceUnit } = this.props;
        const { Co, UnitType, UnitCat } = serviceUnit;
        const unitType = _.find(serviceUnitTypes, { Co, UnitCat, UnitType });
        const partTypes = unitType ? unitType.Data.PartTypes : [];

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? "m-24" : "full-screen-dialog"
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...serviceUnitPartDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {serviceUnitPartDialog.type === 'new' ? 'New Part' : 'Edit Part'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Part Type"
                                    select
                                    id="partType"
                                    name="PartType"
                                    value={this.state.PartType || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    {partTypes && partTypes.map((value, index) => (
                                        <MenuItem key={index} value={value.PartType}>
                                            <div className="flex">
                                                {`${value.Name}`}
                                            </div>
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>

                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Qty"
                                    id="qty"
                                    name="Qty"
                                    value={this.state.Qty}
                                    onChange={this.handleChange}
                                    onBlur={this.formatCostAndPrice}
                                    InputLabelProps={{ shrink: this.state.Qty ? true : false }}
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    required
                                />
                            </div>
                            <div className="flex">
                                <Autocomplete
                                    className={classes.formControl}
                                    title="UOM"
                                    options={uoms}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.UOM}>
                                                <div className="flex">
                                                    {`${value.UOM} - ${value.Description}`}
                                                </div>
                                            </MenuItem>
                                    }}
                                    value={this.state.UOM}
                                    noflip={true}
                                    onSelect={(option) => this.setState({ ...this.state, UOM: option.UOM })}
                                    required
                                    portal={true}
                                />
                            </div>
                            <div className="flex">
                                {Description && Description.length > 1 && searching ?
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Description"
                                        options={materials}
                                        searchButton={true}
                                        menuItemComponent={(value) => {
                                            return value.ActiveYN === "Y" &&
                                                <MenuItem value={value.Material}>
                                                    <div className="flex">
                                                        <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Material} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): matAvatar)} />
                                                        {`${(value.Description || '').substr(0, 50)}`}
                                                    </div>
                                                </MenuItem>
                                        }}
                                        debounce={750}
                                        onChange={(text) => this.setState({ ...this.state, Description: text })}
                                        search={this.state.Description}
                                        value={this.state.Material}
                                        portal={true}
                                        noflip={true}
                                        onSelect={(option) => this.setState({ ...this.state, Material: option.Material, Description: option.Description, UOM: option.UOM, searching: false })}
                                        required
                                    />
                                    :
                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description || ''}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            endAdornment: !this.state.Material ?
                                                <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: true, })} style={{ cursor: 'pointer' }}>search</Icon></InputAdornment>
                                                :
                                                <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: false, Description: null, Material: null, UnitCost: 0, CostTotal: 0, UnitPrice: 0, PriceTotal: 0 })} style={{ cursor: 'pointer' }}>close</Icon></InputAdornment>,
                                        }}
                                        InputLabelProps={{ shrink: this.state.Description ? true : false }}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                }
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Part #"
                                    id="material"
                                    name="Material"
                                    value={this.state.Material || ''}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.Material ? true : false }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                        </DialogContent>

                        {serviceUnitPartDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addServiceUnitPart(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                        </Button>
                            </DialogActions>
                        ) : (
                                <DialogActions className="dialog-actions justify-between px-16">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateServiceUnitPart(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                        </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            removeServiceUnitPart(this.state);
                                            this.closeComposeDialog();
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </DialogActions>
                            )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditServiceUnitPartDialog: Actions.closeEditServiceUnitPartDialog,
        closeNewServiceUnitPartDialog: Actions.closeNewServiceUnitPartDialog,
        addServiceUnitPart: Actions.addServiceUnitPart,
        updateServiceUnitPart: Actions.updateServiceUnitPart,
        removeServiceUnitPart: Actions.removeServiceUnitPart,
    }, dispatch);
}

function mapStateToProps({ serviceUnitsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        serviceUnitPartDialog: serviceUnitsApp.serviceUnits.serviceUnitPartDialog,
        serviceUnitCategories: spReducers.serviceUnitCategories,
        serviceUnitTypes: spReducers.serviceUnitTypes,
        materials: spReducers.materials.materials,
        uoms: spReducers.uoms,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitPartDialog));
