import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Badge, Card, Chip, Typography, Avatar, Icon, IconButton, Tooltip, LinearProgress, withStyles, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as Actions from 'main/content/apps/scrumboard/store/actions';
import moment from 'moment';
import _ from '@lodash';
import getProfileImage from '../../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    card: {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider
    },
    cardContent: {},
    cardFooter: {
        height: 48,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider
    },
    memberBadge: {
        top: 16,
        right: 20,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 34,
        height: 34,
        cursor: 'pointer',
        borderRadius: '50%'
    },
    addedByBadge: {
        top: 12,
        right: 12,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 26,
        height: 26,
        cursor: 'pointer',
        borderRadius: '50%',
        padding: 0
    },
    taskIdBadge: {
        // float: 'right',
        // display:"block",
        backgroundColor: 'grey',
        color: '#fff',
        // fontSize: '1.0rem',
        // height: '20px',
        // padding: 'auto 6px',
        // borderRadius: '6px'
    }
});

class BoardCard extends Component {

    state = {
        anchorEl: null,
        statusAnchorEl: null,
    }

    handleMenuClick = (ev) => {
        ev.stopPropagation();
        this.setState({ anchorEl: ev.currentTarget })
    }
    handleStatusMenuClick = (ev) => {
        ev.stopPropagation();
        this.setState({ statusAnchorEl: ev.currentTarget })
    }
    handleStatusMenuClose = (ev) => {
        ev.stopPropagation()
        this.setState({ statusAnchorEl: null });
    };

    handleCardClick = (ev, card) => {
        window["warn"]('Card Click:', card);
        ev.preventDefault();
        const { board } = this.props;

        const list = card && board ? _.find(board.Data.Lists, { ID: card.ListID }) : null;

        if (board) card.BoardName = board.Name;
        if (list) card.ListName = list.Name;

        this.props.openCardDialog(card, true);
    };

    handleMenuClose = (ev) => {
        ev.stopPropagation()
        this.setState({ anchorEl: null });
    };
    updateBoard = () => {
        this.props.getBoard(this.props.board.Co, this.props.board.ID);
    }

    updateCardCompleted = (ev, card) => {
        ev.stopPropagation()
        this.props.updateCard(card)
        this.setState({ anchorEl: null });
    }
    updateCardStatus = (event, ev, card) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ statusAnchorEl: null });
        const updatedCard = { ...card, ListID: ev.ID }
        this.props.updateCard(updatedCard, true, true)
            .then(() => {
                this.updateBoard();
            });
    }

    checkItemsChecked = (card) => {
        return card.Data.Checklists ? _.sum(card.Data.Checklists.map(list => _.sum(list.Data.Items.map(x => (x.CheckedYN == 'Y' ? 1 : 0))))) : 0;
    };

    checkItems = (card) => {
        return card.Data.Checklists ? _.sum(card.Data.Checklists.map(x => x.Data.Items.length)) : 0;
    };

    getAllAssignees = (card, assignees = []) => {
        let list = [...assignees, ...(card.Data ? card.Data.Members || [] : [])];
        if (card.children) {
            card.children.map((task) => {
                list = this.getAllAssignees(task, list);
            })
        }
        return _.uniqBy(list, 'UserName');
    }

    render() {
        const { anchorEl, statusAnchorEl } = this.state;
        const { classes, card, index, board, users, tags, securables, view, list, boardData } = this.props;
        const checkItemsChecked = this.checkItemsChecked(card);
        const checkItems = this.checkItems(card);
        const avatar = 'assets/images/avatars/profile.jpg';
        const addedBy = _.find(users, { UserName: card.AddedBy });
        const accessLevel = _.find(securables, { Securable: "project-tracking" });
        const colors = ['#4dbce9', '#f44336', 'orange', '#4dbce9'];
        const subtasks = card ? _.filter(board.Data.Cards, { ParentID: card.ID }) : [];
        const allAssignees = this.getAllAssignees(card);

        return (
            <React.Fragment>
                <Draggable draggableId={String(card.ID)} index={index} type="card">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Card
                                className={classNames(classes.card, "w-full rounded-lg cursor-pointer  ", `${view === "col" && "tasklist-wrapper" || view !== "col" && "mb-16 taskcard-wrapper"}`)}
                                elevation={snapshot.isDragging ? 3 : 0}
                                onClick={(ev) => this.handleCardClick(ev, card)}
                            >

                                {card.CoverImage && (
                                    <img className="block" src={getProfileImage(`Co=${card.Co}&ID=${card.CoverImage}`)} alt="card cover" />
                                )}

                                <div className={classNames(classes.cardContent, "p-12 flex justify-between items-center taskcard-row", `${view === "col" && "tasklist-row"}`)}>
                                    {view === "col" && <div className="relative dropdown-btn ml-4">
                                        <Menu
                                            anchorEl={statusAnchorEl}
                                            open={Boolean(statusAnchorEl)}
                                            onClose={this.handleStatusMenuClose}
                                        >   {boardData ?
                                            boardData.Lists.map((n) => {
                                                return (
                                                    <MenuItem onClick={(ev) => { this.updateCardStatus(ev, n, card) }}>
                                                        <ListItemText primary={n.Name} />
                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            <MenuItem >
                                                <ListItemText primary="No Title Found" />
                                            </MenuItem>
                                            }

                                        </Menu>
                                        <IconButton
                                            onClick={this.handleStatusMenuClick}
                                            variant="outlined"
                                            size="small"
                                            // className="ml-4 text-base"
                                            disableElevation={true}
                                        >
                                            {/* <Icon className="text-22">keyboard_arrow_down_icon</Icon> */}
                                            {list.Name ? list.Name : ""}
                                        </IconButton>
                                    </div>}

                                    <Typography title={card.Name} className={classNames("font-600 task-title ", card.CompletedYN === "Y" && "line-through text-grey-dark")}>{card.CompletedYN === "Y" ? <Icon color="secondary" className="mr-4 align-middle text-20 mb-4 line-through-none">check_circle_outline</Icon> : (card.Priority && card.Priority !== 3) ? <Icon style={{ color: colors[card.Priority] }} className="mr-4 align-middle text-20 mb-4">flag</Icon> : ''}{card.Name}</Typography>
                                    {view === "col" && <div className={classNames(classes.cardFooter, "px-12 flex justify-between tasklist-footer")} style={{ width: "40%" }}>
                                        <div className="flex items-center justify-end">
                                            {card && (
                                                <span className="flex items-center">
                                                    <Icon className="text-18 mr-8" color="action">attachment</Icon>
                                                    <Typography color="textSecondary">{card.AttachmentsCount || 0}</Typography>
                                                </span>
                                            )}
                                            {subtasks.length > 0 && (
                                                <span className="flex items-center ml-12">
                                                    <Icon className="text-18" color="action">call_split</Icon>
                                                    <Typography color="textSecondary">{subtasks.length}</Typography>
                                                </span>
                                            )}
                                            {subtasks.length === 0 && view === "col" && (
                                                <span className="flex items-center ml-12">
                                                    <Icon className="text-18" color="action">call_split</Icon>
                                                    <Typography color="textSecondary">0</Typography>
                                                </span>
                                            )}
                                            {card && card.CommentsCount > 0 && (
                                                <span className="flex items-center ml-12">
                                                    <Icon className="text-18" color="action">comment</Icon>
                                                    <Typography color="textSecondary">{card.CommentsCount || 0}</Typography>
                                                </span>
                                            )}
                                            {card && card.CommentsCount === 0 && view === "col" && (
                                                <span className="flex items-center ml-12">
                                                    <Icon className="text-18" color="action">comment</Icon>
                                                    <Typography color="textSecondary">0</Typography>
                                                </span>
                                            )}
                                        </div>


                                        <div className="flex items-center">
                                            {addedBy &&
                                                <Tooltip title={`Added By ${addedBy.FirstName} ${addedBy.LastName}`}>
                                                    <Badge classes={{ badge: classNames(classes.addedByBadge) }} badgeContent={`${addedBy.FirstName.substr(0, 1)}${addedBy.LastName.substr(0, 1)}`}>
                                                        <Avatar className="w-24 h-24" src={addedBy.Data && addedBy.Data.Avatar ? getProfileImage(`Co=${this.props.Co}&ID=${addedBy.Data.Avatar}` ): avatar} />
                                                    </Badge>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>}

                                    <div className='ct-btn relative'>
                                        <Chip label={card.TaskID} className={classNames(classes.taskIdBadge, "tl-btn h-24 text-10")} />
                                        {view === 'col' &&
                                            <Typography className="text-10 text-grey pin-t pin-l pl-4 pr-4 absolute">{moment(card.AddedDate).format("MMM Do")}</Typography>
                                        }
                                        {view === 'col' &&
                                            <Typography className="text-10 text-grey pin-b pin-r pl-4 pr-4 absolute">{moment(card.AddedDate).format("YYYY")}</Typography>
                                        }
                                    </div>



                                    {card.Data.Tags && card.Data.Tags.length > 0 && (
                                        <div className={classNames("task-point", `${view !== "col" && "mt-6"}`)}>
                                            <div className="flex">
                                                {card.Data.Tags.map(tag => {
                                                    const label = _.find(tags, { ID: tag.TagID });

                                                    return label && (
                                                        <Tooltip title={label.Name} key={tag.ID}>
                                                            <div className={classNames("w-32  h-8 rounded-6 mr-6  p-2", `${view === "col" && "h-16"}`)} style={{ backgroundColor: label.Background }} >
                                                                {view === "col" ? label.Name : ""}
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {card.Data.Tags && card.Data.Tags.length === 0 && view === "col" && (
                                        <div className="task-point">
                                            <div className="flex">
                                                <div>-</div>
                                            </div>
                                        </div>
                                    )}

                                    {(card.DueBy || checkItems > 0) && (
                                        <div className={classNames("flex items-center  mb-12 due-date", `${view !== "col" && "mt-6"}`)} >
                                            {card.DueBy && (
                                                <div
                                                    className={classNames("flex items-center px-8 py-4 mr-8 rounded-sm", moment() > moment(card.DueBy) ? checkItemsChecked === checkItems ? "bg-green text-white" : "bg-red text-white" : "bg-green text-white")}>
                                                    <Icon className="text-16 mr-4">access_time</Icon>
                                                    <span>{moment(card.DueBy).format("MMM Do YY")}</span>
                                                </div>
                                            )}

                                            {card.Data.Checklists && checkItems > 0 && (
                                                <div
                                                    className={classNames("flex items-center px-8 py-4 mr-8 rounded-sm", checkItemsChecked === checkItems ? "bg-green text-white" : "bg-grey-dark text-white")}
                                                >
                                                    <Icon className="text-16 mr-4">check_circle</Icon>
                                                    <span>{checkItemsChecked}</span>
                                                    <span>/</span>
                                                    <span>{checkItems}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {(!card.DueBy && checkItems === 0 && view === "col") && (
                                        <div className="flex items-center  mb-12 due-date">
                                            <div>
                                                -
                                            </div>
                                        </div>
                                    )}

                                    {allAssignees && allAssignees.length > 0 && (
                                        <div className="flex flex-wrap cta-btn">
                                            <div className="flex">
                                                {allAssignees.map(memb => {
                                                    const member = _.find(users, { UserName: memb.UserName });
                                                    if (member) {
                                                        return (
                                                            <Tooltip title={`Assigned to ${member.FirstName} ${member.LastName}`} key={memb.ID}>
                                                                <Badge classes={{ badge: classNames(classes.memberBadge) }} badgeContent={`${member.FirstName.substr(0, 1)}${member.LastName.substr(0, 1)}`}>
                                                                    <Avatar className="mr-4 w-32 h-32" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${memb.Co}&ID=${member.Data.Avatar}` ): avatar} />
                                                                </Badge>
                                                            </Tooltip>
                                                        )
                                                    }
                                                })}
                                                <div className="">
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {allAssignees.length === 0 && view === "col" && (
                                        <div className="flex flex-wrap  task-lable cta-btn">

                                            <Tooltip title={`Un-Assigned`}>
                                                <div className="mr-4 w-32 h-32 border-solid border-gray border-3 text-center rounded-full pt-4 shadow-sm shadow-gray-500/50">
                                                    -
                                                </div>
                                            </Tooltip>

                                        </div>
                                    )}
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleMenuClose}
                                    >
                                        {card.CompletedYN === "Y" ?
                                            <MenuItem onClick={(ev) => { this.updateCardCompleted(ev, { ...card, CompletedYN: "N" }) }}>
                                                <ListItemIcon style={{ minWidth: '36px' }}>
                                                    <Icon>remove_circle_outline</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary="Reopen Task" />
                                            </MenuItem>
                                            :
                                            <MenuItem onClick={(ev) => { this.updateCardCompleted(ev, { ...card, CompletedYN: "Y" }) }}>
                                                <ListItemIcon style={{ minWidth: '36px' }}>
                                                    <Icon>check_circle_outline</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary="Complete Task" />
                                            </MenuItem>
                                        }
                                    </Menu>
                                    <div className='complete-tesk-btn'>
                                        <IconButton
                                            onClick={this.handleMenuClick}
                                            variant="outlined"
                                            size="small"
                                            className="ml-4 "
                                        >
                                            <Icon className="text-16">more_vert</Icon>
                                        </IconButton>
                                    </div>

                                </div>
                                {subtasks.length > 0 &&
                                    <Tooltip placement="top" title={`${(subtasks.length > 0 ? (_.filter(subtasks, { CompletedYN: 'Y' }).length / subtasks.length) * 100 : 0).toFixed(0)}% Complete`}>
                                        <LinearProgress className="w-full m-0 cursor-pointer" color="secondary" classes={{ determinate: 'bg-grey-lighter' }} variant="determinate" value={subtasks.length > 0 ? (_.filter(subtasks, { CompletedYN: 'Y' }).length / subtasks.length) * 100 : 0} />
                                    </Tooltip>
                                }

                                {view === "row" && <div className={classNames(classes.cardFooter, "px-12 flex justify-between")}>
                                    <div className="flex items-center justify-end">
                                        {card && (
                                            <span className="flex items-center">
                                                <Icon className="text-18 mr-8" color="action">attachment</Icon>
                                                <Typography color="textSecondary">{card.AttachmentsCount || 0}</Typography>
                                            </span>
                                        )}
                                        {subtasks.length > 0 && (
                                            <span className="flex items-center ml-12">
                                                <Icon className="text-18" color="action">call_split</Icon>
                                                <Typography color="textSecondary">{subtasks.length}</Typography>
                                            </span>
                                        )}
                                        {card && card.CommentsCount > 0 && (
                                            <span className="flex items-center ml-12">
                                                <Icon className="text-18" color="action">comment</Icon>
                                                <Typography color="textSecondary">{card.CommentsCount || 0}</Typography>
                                            </span>
                                        )}
                                    </div>


                                    <div className="flex items-center">
                                        {addedBy &&
                                            <Tooltip title={`Added By ${addedBy.FirstName} ${addedBy.LastName}`}>
                                                <Badge classes={{ badge: classNames(classes.addedByBadge) }} badgeContent={`${addedBy.FirstName.substr(0, 1)}${addedBy.LastName.substr(0, 1)}`}>
                                                    <Avatar className="w-24 h-24" src={addedBy.Data && addedBy.Data.Avatar ? getProfileImage(`Co=${this.props.Co}&ID=${addedBy.Data.Avatar}` ): avatar} />
                                                </Badge>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>}
                            </Card>
                        </div>
                    )}
                </Draggable>
            </React.Fragment>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCardDialog: Actions.openCardDialog,
        updateCard: Actions.updateCard,
        getBoard: Actions.getBoard,
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        board: scrumboardApp.board,
        users: spReducers.userProfiles.Users,
        tags: spReducers.calendar.tags,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardCard)));
