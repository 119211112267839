import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageSimple, FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import UsersList from 'main/content/apps/users/UsersList';
import UsersHeader from 'main/content/apps/users/UsersHeader';
import UsersLeftSidebarHeader from 'main/content/apps/users/UsersLeftSidebarHeader';
import UsersRightSidebarHeader from 'main/content/apps/users/UsersRightSidebarHeader';
import UsersSidebarContent from 'main/content/apps/users/UsersSidebarContent';
import _ from '@lodash';
import { Button, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import UserProfile from 'main/content/pages/profile/ProfilePage';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
});

const initialState = {
    user: null
}

class CustomersApp extends Component {
    state = {
        ...initialState
    }

    componentDidMount() {
        window["warn"]('Users App Params: ', this.props.match);
        const { users } = this.props;
        const user = _.find(users, { UserName: decodeURIComponent(this.props.match.params.id) });
        this.props.getUserData(user);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            const { users } = this.props;
            const user = _.find(users, { UserName: decodeURIComponent(this.props.match.params.id) });
            window["warn"]('Users App Params: ', this.props.match);
            this.props.getUserData(user);
        }
    }

    render() {
        const { classes, openNewUserDialog, match, user } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        {user ?
                            <React.Fragment>
                                <Typography onClick={() => this.props.getUserData(null)} variant="h6" className="text-20 mt-16 text-white float-left cursor-pointer" style={{ marginBottom: -52, zIndex: 9 }}><IconButton className="align-middle mb-4 ml-16 p-0"><Icon className="text-32" color="secondary">keyboard_arrow_left</Icon></IconButton>
                                    Back
                                </Typography>
                                <UserProfile general={user} />
                            </React.Fragment>
                            :
                            <FusePageCarded
                                classes={{
                                    root: "w-full",
                                    content: "flex flex-col p-12",
                                    header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                                }}
                                header={
                                    <UsersHeader pageLayout={() => this.pageLayout} />
                                }
                                leftSidebarHeader={
                                    <UsersLeftSidebarHeader />
                                }
                                content={
                                    <UsersList />

                                }
                                leftSidebarContent={
                                    <UsersSidebarContent />
                                }
                                sidebarInner
                                onRef={instance => {
                                    this.pageLayout = instance;
                                }}
                                innerScroll={true}
                            />
                        }
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUsers: Actions.getUsers,
        getUserData: Actions.getUserData,
        openNewUserDialog: Actions.openNewUserDialog
    }, dispatch);
}

function mapStateToProps({ usersApp, spReducers }) {
    return {
        users: usersApp.users.entities,
        selectedUserIds: usersApp.users.selectedUserIds,
        searchText: usersApp.users.searchText,
        user: usersApp.user,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
