import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedUserIds: [],
    routeParams: {},
    userDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const usersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USERS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        //case Actions.LOAD_WORK_ORDERS
        //    {
        //        return {
        //            ...state,
        //            workOrders: action.workOrders
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_USERS:
            {

                const userId = action.userId;

                let selectedUserIds = [...state.selectedUserIds];

                if (selectedUserIds.find(id => id === userId) !== undefined) {
                    selectedUserIds = selectedUserIds.filter(id => id !== userId);
                }
                else {
                    selectedUserIds = [...selectedUserIds, userId];
                }

                return {
                    ...state,
                    selectedUserIds: selectedUserIds
                };
            }
        case Actions.SELECT_ALL_USERS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedUserIds = arr.map(user => user.id);

                return {
                    ...state,
                    selectedUserIds: selectedUserIds
                };
            }
        case Actions.DESELECT_ALL_USERS:
            {
                return {
                    ...state,
                    selectedUserIds: []
                };
            }
        case Actions.OPEN_NEW_USER_DIALOG:
            {
                window["log"]('CUSTOMER DATA FOR NEW SITE: ', action.data, (action.data.Customer ? true : false));
                return {
                    ...state,
                    userDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.DATA_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type: 'data',
                        props: {
                            ...state.userDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: {
                            ...action.data,
                            value: 1
                        },
                    }
                };
            }
        case Actions.UPDATE_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default usersReducer;
