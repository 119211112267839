import { FuseAnimateGroup } from '@fuse';
import { Backdrop, Badge, Card, CardHeader, Divider, Drawer, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clsx from 'clsx';
import * as Actions from 'main/content/apps/scrumboard/store/actions';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100vh - 64px)',
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    backdrop: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1
    },
    badge: {
        right: 6,
        top: 4
    }
});

class RecycleBinDrawer extends Component {

    state = {
        boardConfirm: false,
        deleteBoard: null,
        boardsOpen: false,
        cardConfirm: false,
        deleteCard: null,
        cardsOpen: false,
        listConfirm: false,
        deleteList: null,
        listsOpen: false
    };

    componentDidUpdate(prevProps) {
        const { Co, drawerOpen, getDeletedBoards, getDeletedListsCards, board } = this.props;
        if (prevProps.drawerOpen !== drawerOpen && drawerOpen) {
            if (!board) {
                getDeletedBoards(Co);
            } else {
                getDeletedListsCards(board.Co, board.ID);
            }
        }
    }

    render() {
        const { classes, recycleBin, drawerOpen, closeDrawer, permanentlyRemoveBoard, restoreBoard, permanentlyRemoveList, restoreList, permanentlyRemoveCard, restoreCard, board } = this.props;
        const { boardsOpen, listsOpen, cardsOpen } = this.state;

        return (
            <Backdrop className={classes.backdrop} open={drawerOpen} onClick={closeDrawer}>
                <Drawer
                    anchor="right"
                    variant="permanent"
                    className={clsx(classNames(classes.drawer, "pin-r absolute"), {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen
                    })}
                    classes={{
                        paper: clsx("relative border-none h-full", {
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen
                        })
                    }}
                    open={drawerOpen}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        tabIndex={0}
                        className="w-full h-full relative"
                    >
                        <div className="p-12 relative">
                            <Typography variant="h6" className="w-full font-bold text-16 mt-4 mb-12">
                                <Icon className="align-middle mr-4 mb-4" color="primary">restore_from_trash</Icon>
                                Recycle Bin
                            </Typography>

                            <IconButton onClick={closeDrawer} color="inherit" className="pin-t pin-r absolute mt-4 mr-4 text-black">
                                <Icon>close</Icon>
                            </IconButton>
                            <Divider />

                            {!board &&
                                <div className="mt-12 w-full">
                                    <Typography onClick={() => this.setState({ boardsOpen: !boardsOpen })} variant="h6" className="w-full font-bold text-14 mb-8 justify-between flex cursor-pointer">
                                        <div className="flex">
                                            <Badge classes={{ root: "mr-6", badge: classes.badge }} badgeContent={recycleBin.Boards.length} color="primary">
                                                <Icon className="align-middle mr-4 mb-4">assessment</Icon>
                                            </Badge>
                                            Deleted Projects
                                        </div>
                                        {recycleBin.Boards.length > 0 &&
                                            <Icon className="align-middle">{`keyboard_arrow_${boardsOpen ? "up" : "down"}`}</Icon>
                                        }
                                    </Typography>
                                    {boardsOpen &&
                                        <FuseAnimateGroup
                                            enter={{
                                                animation: "transition.slideUpBigIn",
                                                duration: 300
                                            }}
                                        >
                                            {recycleBin.Boards.map(board => (
                                                <Card className='w-full p-4 mb-8'>
                                                    <CardHeader
                                                        classes={{ root: "p-8", content: "overflow-hidden", title: "font-bold truncate", avatar: "mr-4", action: "mt-0" }}
                                                        avatar={<Icon className="align-middle" color="error">assessment</Icon>}
                                                        title={board.Name}
                                                        action={
                                                            <div className="flex justify-end">
                                                                <Tooltip placement="left" title="Restore">
                                                                    <IconButton className="p-8" color="action" onClick={() => { restoreBoard(board) }}>
                                                                        <Icon className="text-20">restore</Icon>
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip placement="left" title="Delete Permanently">
                                                                    <IconButton className="p-8" color="action" onClick={() => { this.setState({ boardConfirm: true, deleteBoard: board }) }}>
                                                                        <Icon className="text-20">delete_forever</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    />
                                                </Card>
                                            ))}
                                        </FuseAnimateGroup>
                                    }
                                    <Divider />
                                    <ConfirmationDialog
                                        open={this.state.boardConfirm}
                                        title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                                        content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to permanently delete this board? This action cannot be undone.`}</div>}
                                        confirmText="Confirm"
                                        cancelText="Never Mind"
                                        onCancel={() => this.setState({ ...this.state, boardConfirm: false })}
                                        onConfirm={() => this.setState({ ...this.state, boardConfirm: false }, () => { permanentlyRemoveBoard(this.state.deleteBoard) })}
                                    />
                                </div>
                            }

                            {board &&
                                <React.Fragment>
                                    <div className="mt-12 w-full">
                                        <Typography onClick={() => this.setState({ listsOpen: !listsOpen })} variant="h6" className="w-full font-bold text-14 mb-8 justify-between flex cursor-pointer">
                                            <div className="flex">
                                                <Badge classes={{ root: "mr-6", badge: classes.badge }} badgeContent={recycleBin.Lists.length} color="primary">
                                                    <Icon className="align-middle mr-4 mb-4">list_alt</Icon>
                                                </Badge>
                                                Deleted Lists
                                            </div>
                                            {recycleBin.Lists.length > 0 &&
                                                <Icon className="align-middle">{`keyboard_arrow_${listsOpen ? "up" : "down"}`}</Icon>
                                            }
                                        </Typography>
                                        {listsOpen &&
                                            <FuseAnimateGroup
                                                enter={{
                                                    animation: "transition.slideUpBigIn",
                                                    duration: 300
                                                }}
                                            >
                                                {recycleBin.Lists.map(list => (
                                                    <Card className='w-full p-4 mb-8'>
                                                        <CardHeader
                                                            classes={{ root: "p-8", content: "overflow-hidden", title: "font-bold truncate", avatar: "mr-4", action: "mt-0" }}
                                                            avatar={<Icon className="align-middle" color="error">list_alt</Icon>}
                                                            title={list.Name}
                                                            action={
                                                                <div className="flex justify-end">
                                                                    <Tooltip placement="left" title="Restore">
                                                                        <IconButton className="p-8" color="action" onClick={() => { restoreList(list) }}>
                                                                            <Icon className="text-20">restore</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip placement="left" title="Delete Permanently">
                                                                        <IconButton className="p-8" color="action" onClick={() => { this.setState({ listConfirm: true, deleteList: list }) }}>
                                                                            <Icon className="text-20">delete_forever</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                ))}
                                            </FuseAnimateGroup>
                                        }
                                        <Divider />
                                        <ConfirmationDialog
                                            open={this.state.listConfirm}
                                            title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to permanently delete this list? This action cannot be undone.`}</div>}
                                            confirmText="Confirm"
                                            cancelText="Never Mind"
                                            onCancel={() => this.setState({ ...this.state, listConfirm: false })}
                                            onConfirm={() => this.setState({ ...this.state, listConfirm: false }, () => { permanentlyRemoveList(this.state.deleteList) })}
                                        />
                                    </div>
                                    <div className="mt-12 w-full">
                                        <Typography onClick={() => this.setState({ cardsOpen: !cardsOpen })} variant="h6" className="w-full font-bold text-14 mb-8 justify-between flex cursor-pointer">
                                            <div className="flex">
                                                <Badge classes={{ root: "mr-6", badge: classes.badge }} badgeContent={recycleBin.Cards.length} color="primary">
                                                    <Icon className="align-middle mr-4 mb-4">assignment</Icon>
                                                </Badge>
                                                Deleted Tasks
                                            </div>
                                            {recycleBin.Cards.length > 0 &&
                                                <Icon className="align-middle">{`keyboard_arrow_${cardsOpen ? "up" : "down"}`}</Icon>
                                            }
                                        </Typography>
                                        {cardsOpen &&
                                            <FuseAnimateGroup
                                                enter={{
                                                    animation: "transition.slideUpBigIn",
                                                    duration: 300
                                                }}
                                            >
                                                {recycleBin.Cards.map(card => (
                                                    <Card className='w-full p-4 mb-8'>
                                                        <CardHeader
                                                            classes={{ root: "p-8", content: "overflow-hidden", title: "font-bold truncate", avatar: "mr-4", action: "mt-0" }}
                                                            avatar={<Icon className="align-middle" color="error">assignment</Icon>}
                                                            title={card.Name}
                                                            action={
                                                                <div className="flex justify-end">
                                                                    <Tooltip placement="left" title="Restore">
                                                                        <IconButton className="p-8" color="action" onClick={() => { restoreCard(card) }}>
                                                                            <Icon className="text-20">restore</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip placement="left" title="Delete Permanently">
                                                                        <IconButton className="p-8" color="action" onClick={() => { this.setState({ cardConfirm: true, deleteCard: card }) }}>
                                                                            <Icon className="text-20">delete_forever</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                ))}
                                            </FuseAnimateGroup>
                                        }
                                        <Divider />
                                        <ConfirmationDialog
                                            open={this.state.cardConfirm}
                                            title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to permanently delete this card? This action cannot be undone.`}</div>}
                                            confirmText="Confirm"
                                            cancelText="Never Mind"
                                            onCancel={() => this.setState({ ...this.state, cardConfirm: false })}
                                            onConfirm={() => this.setState({ ...this.state, cardConfirm: false }, () => { permanentlyRemoveCard(this.state.deleteCard) })}
                                        />
                                    </div>
                                </React.Fragment>
                            }

                        </div>
                    </div>
                </Drawer>
            </Backdrop>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDeletedBoards: Actions.getDeletedBoards,
        permanentlyRemoveBoard: Actions.permanentlyRemoveBoard,
        restoreBoard: Actions.restoreBoard,
        getDeletedListsCards: Actions.getDeletedListsCards,
        permanentlyRemoveCard: Actions.permanentlyRemoveCard,
        permanentlyRemoveList: Actions.permanentlyRemoveList,
        restoreCard: Actions.restoreCard,
        restoreList: Actions.restoreList,
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        // board: scrumboardApp.board,
        recycleBin: scrumboardApp.recycleBin,
        Co: spReducers.companies.Co,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(RecycleBinDrawer)));
