import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTechUAIds: [],
    routeParams: {},
    techUADialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const techUAReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.OPEN_NEW_TECH_UA_DIALOG:
            {
                const {data} = action;
                return {
                    ...state,
                    techUADialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_TECH_UA_DIALOG:
            {
                return {
                    ...state,
                    techUADialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_TECH_UA_DIALOG:
            {
                const {data} = action;
                return {
                    ...state,
                    techUADialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...data,
                        }
                    }
                };
            }
            case Actions.UPDATE_EDIT_TECH_UA_DIALOG:
                {
                    const {data} = action;
                    return {
                        ...state,
                        techUADialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: {
                                ...data,
                            }
                        }
                    };
                }
        case Actions.CLOSE_EDIT_TECH_UA_DIALOG:
            {
                return {
                    ...state,
                    techUADialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default techUAReducer;
