import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from 'lodash';
import moment from 'moment';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class PurchaseOrderReceiving extends Component {

    state = {
        selectedPurchaseOrderReceivingsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedPurchaseOrderReceivingMenu = (event) => {
        this.setState({ selectedPurchaseOrderReceivingsMenu: event.currentTarget });
    };

    closeSelectedPurchaseOrderReceivingsMenu = () => {
        this.setState({ selectedPurchaseOrderReceivingsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { Co, classes, purchaseOrder, purchaseOrderLine } = this.props;
        const data = [...purchaseOrderLine.Data.Receiving];
        const avatar = 'assets/images/avatars/profile.jpg';
        const types = { 'I': 'Inventory', 'W': 'Work Order' };
        let poData = { Co };
        if (purchaseOrder) {
            const { Co, PO, POType, WorkOrder, Scope, InventoryLocation, TaxCode, Data } = purchaseOrder;
            poData = { Co, PO, POLineType: POType, WorkOrder, Scope, InventoryLocation, POLine: Data.Lines ? Data.Lines.length + 1 : 1, TaxCode };
        }

        return (
            <div>
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">playlist_add_check</Icon>{`Receiving ${purchaseOrderLine && `(${purchaseOrderLine && _.sumBy(purchaseOrderLine.Data.Receiving, (o) => { return o.ReceivedQty })} of ${purchaseOrderLine.Qty})`}`}</Typography>
                <ReactTableFixedColumns
                    className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className: "cursor-pointer",
                            onClick: (e, handleOriginal) => {
                                if (rowInfo) {
                                    this.props.onSelectedReceiving({ ...rowInfo.original });
                                    // openEditPurchaseOrderReceivingDialog({ ...rowInfo.original });
                                }
                            }
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header: "PO #",
                            accessor: "PO",
                            minWidth: 128,
                            show: false,
                            className: "justify-center font-bold",
                        },
                        {
                            Header: "Line",
                            accessor: "POLine",
                            minWidth: 128,
                            show: false,
                            className: "justify-center font-bold",
                        },
                        {
                            Header: "Seq",
                            accessor: "Seq",
                            width: 64,
                            className: "justify-center font-bold",
                        },
                        {
                            Header: "Qty",
                            accessor: "ReceivedQty",
                            className: "justify-center",
                            width: 64,
                        },
                        {
                            Header: "Date",
                            accessor: "ReceivedDate",
                            className: "justify-center",
                            width: 128,
                            Cell: row => (
                                row.original.ReceivedDate &&
                                moment(row.original.ReceivedDate).toDate().toLocaleDateString('en-US')
                            )
                        },
                        {
                            Header: "Cost",
                            accessor: "ReceivedCostTotal",
                            className: "justify-center",
                            width: 128,
                            Cell: row => (
                                row.original.ReceivedCostTotal && `$${row.original.ReceivedCostTotal.toFixed(2)}`
                            )
                        },
                        {
                            Header: "Tax",
                            accessor: "ReceivedTaxTotal",
                            className: "justify-center",
                            width: 128,
                            Cell: row => (row.original.ReceivedTaxTotal && purchaseOrderLine && purchaseOrderLine.TaxableYN === 'Y') ? `$${row.original.ReceivedTaxTotal.toFixed(2)}` : '$0.00'
                        },
                        {
                            Header: "Entered By",
                            accessor: "AddedBy",
                            className: "justify-center",
                        },
                        {
                            Header: "Entered Date",
                            accessor: "AddedDate",
                            className: "justify-center",
                            Cell: row => (
                                row.original.AddedDate &&
                                moment(row.original.AddedDate).toDate().toLocaleDateString('en-US')
                            )
                        },
                    ]}
                    defaultPageSize={10}
                    noDataText="No Receiving Lines found"
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ purchaseOrderLinesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        purchaseOrderLineData: spReducers.purchaseOrderLines,
        searchText: purchaseOrderLinesApp.purchaseOrderLines.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderReceiving)));
