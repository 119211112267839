import _ from '@lodash';
import { Button, Checkbox, FormControlLabel, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';


const styles = theme => ({
    root: {

    },
    updateOverride: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteOverride: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    borderTop: {
        borderTop: '1px solid lightgrey',
        paddingTop: 4,
        paddingBottom: 4,
    },
    liRoot: {
        padding: 0
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '70%',
    },
    wrap: {
        whiteSpace: 'normal !important',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '70%',
    },
});

const initialState = {
    ID: null,
    Co: null,
    HideLaborButtonYN: null,
    HideMaterialsButtonYN: null,
    HideMiscButtonYN: null,
    DisableTMBillingYN: null,
    RequireDueDateYN: null,
    RequireFormOfPaymentYN: null,
    Data: {
        ErrMsg: null
    }
}

class TripsStatusTerminology extends Component {

    state = {
        ..._.cloneDeepWith(initialState)
    }

    componentDidMount() {
        const { WorkOrderOptions } = this.props.company.Data;
        this.setState({ ..._.cloneDeepWith(initialState), ...WorkOrderOptions });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { WorkOrderOptions } = this.props.company.Data;
        if (!_.isEqual(WorkOrderOptions, prevProps.company.Data.WorkOrderOptions)) {
            this.setState({ ..._.cloneDeepWith(initialState), ...WorkOrderOptions });
        }
    }

    canBeSubmitted = () => {
        const { Status, TechnicianTerminology } = this.state.override;
        return Status !== null && TechnicianTerminology && TechnicianTerminology.length > 0;
    }

    // handleChange = (event) => {
    //     this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    // };

    saveOptions = () => {
        const { Co } = this.props;
        const request = axios.put(`${window["apiLocation"]}/api/WorkOrderOptions?Co=${Co}`, { ...this.state, Co });

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.setState({ override: _.cloneDeepWith(initialState.override) }, () => {
                    this.props.showMessage({
                        message: `Work Order Options successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        }).catch((error) => {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        });
    }

    handleChange = (opt) => {
        let value = this.state[opt.key];
        if (value === "Y") {
            value = "N";
        } else {
            value = "Y";
        }
        this.setState(_.set({ ...this.state }, opt.key, value));
    }

    render() {
        const { classes, user, data, securables, company } = this.props;
        const accessLevel = _.find(securables, { Securable: "companies" });

        const options = [
            {
                key: "DisableTMBillingYN",
                label: 'Disable T&M Billing'
            },
            // {
            //     key: "RequireDueDateYN",
            //     label: 'Require Due By Date'
            // },
            // {
            //     key: "RequireFormOfPaymentYN",
            //     label: 'Require Form of Payment'
            // },
            {
                key: "HideLaborButtonYN",
                label: 'Hide Labor Button'
            },
            {
                key: "HideMaterialsButtonYN",
                label: 'Hide Materials Button'
            },
            {
                key: "HideMiscButtonYN",
                label: 'Hide Miscellaneous Button'
            },
        ]

        window["warn"]('Work Order Options: ', this.state, this.props.company);

        return (
            <React.Fragment>
                <div className={classNames("w-full")}>
                    {accessLevel && accessLevel.AccessLevel === "F" &&
                        <React.Fragment>
                            {/* <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">settings</Icon>Work Order Options</Typography> */}
                            <div className="flex flex-wrap px-12 sm:px-24">
                                {options.map((opt) => {
                                    return (
                                        <div className="w-full sm:w-1/2 md:w-1/3 justify-center mb-160116">
                                            <FormControlLabel onChange={() => this.handleChange(opt)} checked={this.state[opt.key] === "Y"} control={<Checkbox />} label={opt.label} />
                                        </div>
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    }
                    {/* {statusProps.map((value, index) => {
                    let override = _.find(company.Data.TripStatusTerminologies, { Status: index });
                    if (!override) {
                        override = {
                            ..._.cloneDeepWith(initialState.override),
                            Status: index,
                            TechnicianTerminology: value.text,
                            DispatchTerminology: value.text,
                            ButtonText: value.text,
                            ButtonStyle: 'B',
                        };
                    }
                    return (
                        <div key={index} onClick={() => this.setState({ override })} className={classNames("w-full p-12", index > 0 ? classes.borderTop : '')}>
                            <ListItem classes={{ root: classes.liRoot }} className="relative cursor-pointer min-h-36">
                                {value.icon &&
                                    <Icon className="mr-6" style={{ color: value.color }}>{value.icon}</Icon>
                                }
                                <ListItemText classes={{ root: classes.liRoot, primary: classes.title }} primary={<span className={classNames("font-bold text-14")}>{override.TechnicianTerminology || value.text}
                                </span>} />
                                <Icon className="pin-r pin-b pin-t absolute m-auto text-16">{override && override.ID ? "edit" : "add_circle_outline"}</Icon>
                            </ListItem>
                        </div>
                    );
                })} */}
                </div>
                <div className="w-full justify-between pin-l pin-r pin-b absolute p-12">
                    <Button variant="contained" color="primary" onClick={this.saveOptions}>Save</Button>
                </div>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage
    }, dispatch);
}

function mapStateToProps({ companiesApp, spReducers }) {
    return {
        Co: companiesApp.companies.companyDialog.data.Co,
        user: spReducers.userProfiles.User,
        company: _.find(spReducers.companies.List, { Co: companiesApp.companies.companyDialog.data.Co }).Data.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TripsStatusTerminology)));
