import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTripIds: [],
    routeParams: {},
    tripDialog: {
        type: 'new',
        props: {
            open: false,
            readOnly: false
        },
        data: {
            Co: null,
            WorkOrder: null,
            Trip: null,
            Technician: null,
            Status: 0,
            Duration: .5,
            Notes: null,
            Data: {
                ErrMsg: null,
                Tags: [],
            }
        }
    }
};

const tripsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TRIPS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_TRIPS:
            {

                const tripId = action.tripId;

                let selectedTripIds = [...state.selectedTripIds];

                if (selectedTripIds.find(id => id === tripId) !== undefined) {
                    selectedTripIds = selectedTripIds.filter(id => id !== tripId);
                }
                else {
                    selectedTripIds = [...selectedTripIds, tripId];
                }

                return {
                    ...state,
                    selectedTripIds: selectedTripIds
                };
            }
        case Actions.SELECT_ALL_TRIPS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedTripIds = arr.map(trips => trips.id);

                return {
                    ...state,
                    selectedTripIds: selectedTripIds
                };
            }
        case Actions.DESELECT_ALL_TRIPS:
            {
                return {
                    ...state,
                    selectedTripIds: []
                };
            }
        case Actions.OPEN_NEW_TRIP_DIALOG:
            {
                const {data} = action;
                return {
                    ...state,
                    tripDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            readOnly: false,
                        },
                        data: {
                            ...initialState.tripDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_TRIP_DIALOG:
            {
                return {
                    ...state,
                    tripDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_TRIP_DIALOG:
            {
                const {data, readOnly} = action;
                return {
                    ...state,
                    tripDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.tripDialog.data,
                            ...data,
                        }
                    }
                };
            }
            case Actions.UPDATE_EDIT_TRIP_DIALOG:
                {
                    const {data, readOnly} = action;
                    return {
                        ...state,
                        tripDialog: {
                            type: 'update',
                            props: {
                                open: true,
                                readOnly
                            },
                            data: {
                                ...initialState.tripDialog.data,
                                ...data,
                            }
                        }
                    };
                }
        case Actions.CLOSE_EDIT_TRIP_DIALOG:
            {
                return {
                    ...state,
                    tripDialog: {
                        type: 'edit',
                        props: {
                            open: false,
                            readOnly: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default tripsReducer;
