import _ from '@lodash'
import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { ResizableBox } from 'react-resizable'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { updateWorkOrderScope } from '../work-order-scopes/store/actions'
import DispatchBoardWorkOrder from './DispatchBoardWorkOrder'
import * as Actions from './store/actions'
import { formatPhone } from 'main/functions'

const styles = theme => ({
  avatarRoot: {
    marginRight: 0,
  },
  avatar: {
    borderRadius: '50%',
    boxShadow: '2px 2px 6px #333',
    width: 48,
    height: 48,
  },
  action: {
    margin: 8,
    marginRight: -4,
    marginTop: 8,
  },
  icon: {
    marginLeft: 12,
    marginRight: 12,
  },
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    color: '#333',
    fontSize: 10,
    lineHeight: '10px',
  },
  subheader: {},
  tripAvatar: {
    borderRadius: '50%',
    boxShadow: '1px 1px 2px rgba(0,0,0,.25)',
    width: 32,
    height: 32,
    backgroundColor: 'rgb(100,100,100)',
  },
  tripAction: {
    margin: 0,
    marginTop: 0,
  },
  tripIcon: {
    marginLeft: 4,
    marginRight: 6,
    marginTop: -2,
  },
  labels: {},
  technician: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    alignSelf: 'start',
  },
  trip: {
    width: 'auto',
    border: 'none',
    zIndex: 1,
    overflow: 'visible',
    alignSelf: 'start',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    background: 'rgb(250, 250, 250)',
    boxShadow: '1px 1px 4px #333',
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 1,
    marginRight: 1,
    cursor: 'pointer',
  },
  hold: {
    position: 'absolute',
    top: -1,
    bottom: -1,
    background: '#6f6f6f',
    zIndex: -1,
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
  },
  draggingTrip: {
    width: 'auto',
    border: 'none',
    //zIndex: 1,
    overflow: 'visible',
    alignSelf: 'start',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    background: 'rgb(250, 250, 250)',
    boxShadow: '1px 1px 4px #333',
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 1,
    marginRight: 1,
  },
  content: {
    overflow: 'hidden',
    minWidth: 60,
  },
  header: {
    width: '100%',
  },
  popper: {
    opacity: 1,
    marginBottom: 15,
    marginTop: 5,
    zIndex: 5,
    // opacity: 0,
  },
  // open: {
  //     opacity: 1,
  //     transition: 'all .35s ease-out',
  // },
  tooltip: {
    opacity: 1,
    padding: 0,
    margin: 0,
    boxShadow: '2px 2px 6px #3333',
  },
  dottedLine: {
    border: 'none',
    borderTop: '2px dotted rgba(0,0,0,0.3)',
    top: '50%',
    width: '100%',
    position: 'absolute',
    margin: 0,
  },
  dot: {
    height: '6px',
    width: '6px',
    backgroundColor: 'rgba(0,0,0,0.6)',
    borderRadius: '50%',
    display: 'inline-block',
    position: 'absolute',
    top: 'calc(50% - 2px)',
  },
  timelineStatus: {
    zIndex: 2,
    display: 'inline-block',
    position: 'absolute',
    top: 'calc(50% - 7px)',
    cursor: 'pointer',
    backgroundColor: '#fff',
    width: 16,
    height: 16,
  },
  timelineIcon: {
    fontSize: '16px !important',
    color: '#5f5f5f',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  overlayDiv: {
    height: 'calc(100% + 2px)',
    zIndex: 2,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-1px',
    marginLeft: '-1px',
    pointerEvents: 'none',
  },
  overlayDiv1: {
    borderTop: '1px solid orange',
    borderBottom: '1px solid orange',
    borderLeft: '1px solid orange',
    height: '100%',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    overflow: 'hidden',
  },
  overlayDiv2: {
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    borderRight: '1px solid transparent',
    height: '100%',
  },
})

const statusProps = [
  {
    text: 'Scheduled',
    icon: 'access_time',
    color: 'rgb(180 70 255)',
    name: 'ScheduledTime',
    status: 0,
    prep: 'for',
  },
  {
    text: 'Notified',
    icon: 'offline_bolt',
    color: 'rgb(0, 150, 250)',
    name: 'NotifiedTime',
    status: 1,
    prep: 'at',
  },
  {
    text: 'Accepted',
    icon: 'offline_pin',
    color: 'rgb(0, 50, 250)',
    name: 'AcceptedTime',
    status: 2,
    prep: 'at',
  },
  {
    text: 'Rejected',
    icon: 'cancel',
    color: 'rgb(210, 0, 0)',
    name: 'RejectedTime',
    status: 3,
    prep: 'at',
  },
  {
    text: 'En-Route',
    icon: 'explore',
    color: 'rgb(50, 170, 200)',
    name: 'EnRouteTime',
    showTimeline: true,
    status: 4,
    prep: 'at',
  },
  {
    text: 'Arrived',
    icon: 'my_location',
    color: 'rgb(50, 150, 100)',
    name: 'ArrivedTime',
    showTimeline: true,
    status: 5,
    prep: 'at',
  },
  {
    text: 'In Progress',
    icon: 'play_circle_outline',
    color: 'rgb(150, 200, 50)',
    name: 'InProgressTime',
    status: 6,
    prep: 'at',
  },
  {
    text: 'On Hold',
    icon: 'pause_circle_outline',
    color: 'rgb(210, 100, 100)',
    name: 'OnHoldTime',
    status: 7,
    prep: 'at',
  },
  {
    text: 'Completed',
    icon: 'check_circle',
    color: 'rgb(100, 100, 100)',
    name: 'CompletedTime',
    status: 8,
    prep: 'at',
  },
]

class DispatchBoardTrip extends Component {
  state = {
    width: 0,
    Duration: 0,
    tooltip: false,
    tooltipX: null,
    open: false,
    exactTime: null,
  }

  ref = React.createRef()

  componentDidMount() {
    const { time, data, now, exactTime } = this.props
    const { ActualDuration, Duration } = data
    const timeline = time ? this.getTimeline(data, now) : {}

    this.setState({
      time,
      data,
      exactTime,
      resizing: false,
      Duration: ActualDuration || Duration,
      ...timeline,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { time, data, now, exactTime } = this.props
    if (
      !_.isEqual(data, prevProps.data) ||
      (data.Status < 4 && !_.isEqual(now, prevProps.now))
    ) {
      const { ActualDuration, Duration } = data
      const timeline = time ? this.getTimeline(data, now) : {}
      this.setState({
        time,
        data,
        exactTime,
        Duration: ActualDuration || Duration,
        ...timeline,
      })
    }
  }

  onResizeStart = (event, { element, size }) => {
    // window["log"]('Resize Started', event, this.props.data, size);
    this.setState({ resizing: true })
    this.props.setDragging(true)
  }

  onResizeStop = (event, { element, size }) => {
    // window["log"]('Resize Ended', event, this.props.data, size);
    const { width, Duration, data } = this.state
    const { username } = this.props.user
    this.setState({ resizing: false })

    // Tentative Trip Resize
    const { Data } = data
    const { date, updateWorkOrderScope } = this.props

    let scope = _.filter(Data.WorkOrder.Data.Scopes, s => {
      return (
        Boolean(s.LeadTechnician) &&
        s.Scope === this.props.scopeNum &&
        s.Type !== 'A' &&
        s.Status !== 4 &&
        new Date(s.DueBy).toLocaleDateString('en-US') ===
          new Date(date).toLocaleDateString('en-US') &&
        s.Data.Assignments.length < 1
      )
    })

    if (scope && scope.length > 0) {
      updateWorkOrderScope({ ...scope[0], EstDuration: Duration })
    } else {
      const UpdatedDate = new Date()
      this.props.updateTrip({
        ...this.props.data,
        Duration,
        UpdatedBy: username,
        UpdatedDate,
      })
    }

    this.props.setDragging(false)
  }

  onResize = (event, { element, size }) => {
    // window["log"]('Resizing', event, this.props.data, size);
    this.setState({ width: size.width, Duration: (size.width + 2) / 192 })
  }

  toggleTooltip = event => {
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    const { data } = this.state
    const { infoWindow } = this.props
    if (infoWindow === data.ID) {
      this.props.openInfoWindow(null)
    } else {
      this.props.openInfoWindow(data.ID)
    }
  }

  openTooltip = () => {
    const { data } = this.state
    this.props.openInfoWindow(data.ID)
  }

  closeTooltip = () => {
    const { data } = this.state
    const { infoWindow } = this.props
    if (infoWindow === data.ID) {
      this.props.openInfoWindow(null)
    }
  }

  dragStart = event => {
    const { Technician, Trip, Duration } = this.props.data
    event.dataTransfer.setData('text/plain', JSON.stringify(this.props.data))
    this.props.setDragging(true)
    if (this.props.onDrag) {
      this.props.onDrag()
    }
  }

  dragEnd = event => {
    this.props.setDragging(false)
    if (this.props.dragFinish) {
      this.props.dragFinish()
    }
  }

  updateTrip = trip => {
    const { data } = this.state
    const { username } = this.props.user
    const { Technician, ScheduledDate, Status, Duration } = trip
    trip.UpdatedBy = username
    trip.UpdatedDate = new Date()
    // window["warn"](trip);
    this.setState({
      ...this.state,
      width: trip.Duration * 192 - 2,
      Duration: trip.Duration,
      data: { ...data, Technician, ScheduledDate, Status, Duration },
    })
    this.update(trip)
  }

  update = _.debounce(trip => {
    if (!isNaN(trip.Duration) && trip.Duration >= 0.5) {
      this.props.updateTrip(trip)
    }
  }, 1000)

  deleteTrip = trip => {
    this.props.onDelete(trip)
  }

  trackMouseX = e => {
    this.setState({ ...this.state, tooltipX: e.pageX })
    // this.setMouseX(e.pageX);
    // window["warn"]('Track Mouse: ', e.screenX, e.pageX);
  }

  setMouseX = _.debounce(x => {
    this.setState({ ...this.state, tooltipX: x })
  }, 150)

  // getTimespan = (data) => {
  //     let StartTime = data.ScheduledTime ? new Date(data.ScheduledTime) : null;
  //     let EndTime = data.ScheduledTime ? new Date(data.ScheduledTime) : null;
  //     if (EndTime) {
  //         EndTime.setHours(EndTime.getHours() + (data.Duration || 1));
  //     }
  //     if (data.Status >= 4) {
  //         const activeTime = data.EnRouteTime || data.ArrivedTime || data.InProgressTime || data.BoardTime;
  //         const st = new Date(activeTime);
  //         const min = new Date(st.toLocaleDateString('en-us'));
  //         const max = new Date(min);
  //         max.setDate(max.getDate() + 1);
  //         let end = new Date(activeTime);
  //         end.setHours(end.getHours() + (data.Duration || .5));
  //         if (data.CompletedTime) {
  //             end = new Date(data.CompletedTime);
  //         }
  //         if (!StartTime) {
  //             StartTime = st;
  //             EndTime = end;
  //         }
  //         if (StartTime < min) {
  //             StartTime = min;
  //         }
  //         if (EndTime > max) {
  //             EndTime = max;
  //         }
  //         if (StartTime > st) {
  //             StartTime = st;
  //         } else {
  //             EndTime = end;
  //         }
  //     }
  //     return { StartTime, EndTime }
  // }

  getTimeline = (data, now) => {
    // window["warn"]("dispatch board trip exact time", this.props.exactTime);
    //data.BoardTime = this.state.exactTime ? this.state.exactTime : data.BoardTime;
    let offset =
      data.BoardTime && data.BoardTime.split(':').length > 1
        ? Number(data.BoardTime.split(':')[1])
        : false
    let realOffset = offset
    if (offset && offset >= 30) {
      offset -= 30
    }

    let ellapsed = 0
    let early = 0
    let oe = data.ScheduledTime ? new Date(data.ScheduledTime) : null
    if (oe) {
      oe.setHours(oe.getHours() + (data.Duration || 0.5))
    }
    if (data.Status >= 4) {
      const activeTime =
        data.EnRouteTime ||
        data.ArrivedTime ||
        data.InProgressTime ||
        data.BoardTime
      const st = new Date(activeTime)
      const min = new Date(st.toLocaleDateString('en-US'))
      const max = new Date(min)
      max.setDate(max.getDate() + 1)
      if (oe && oe > max) {
        oe = max
      }
      if ((data.ScheduledTime || data.StartTime) && activeTime) {
        let og = new Date(data.ScheduledTime || data.StartTime)
        const ogMin = new Date(og.toLocaleDateString('en-US'))
        if (ogMin < min) {
          og = min
        }
        const bt = data.BoardTime ? new Date(data.BoardTime) : null
        if (og < bt) {
          ellapsed = (bt.getTime() - og.getTime()) / 60000
        } else {
          if (bt && bt > st) {
            ellapsed = (bt.getTime() - st.getTime()) / 60000
          }
        }
      }
      const et = new Date(data.BoardTime)
      et.setHours(et.getHours() + (data.ActualDuration || data.Duration || 0.5))
      if (et < oe) {
        early = (oe.getTime() - et.getTime()) / 60000
      }
    }
    const leadOffset = Math.round(offset * 3.2)
    const lead = Math.round(ellapsed ? ellapsed * 3.2 : 0)
    const { date, time } = this.props
    const { ActualDuration, Duration } = data
    let duration = ActualDuration || Duration || 1.0

    if (data.Trip < 1) {
      const { date, workOrders } = this.props
      const woData = _.filter(workOrders, o => o.WorkOrder === data.WorkOrder)

      let scope = _.filter(woData[0].Data.Scopes, s => {
        return (
          Boolean(s.LeadTechnician) &&
          s.Scope === this.props.scopeNum &&
          s.Type !== 'A' &&
          s.Status !== 4 &&
          new Date(s.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') &&
          s.Data.Assignments.length < 1
        )
      })[0]
      if (scope && scope.EstDuration) {
        duration = scope.EstDuration
      }
    } else {
      duration = ActualDuration || Duration
    }

    const dt = new Date(`${new Date(date).toLocaleDateString('en-US')} ${time}`)
    let hours = dt.getHours()
    const minutes = dt.getMinutes()
    hours += minutes / 60
    hours += offset / 60
    hours += duration
    const width =
      Math.round((hours > 24 ? duration - (hours - 24) : duration) * 192) - 2
    let tripWidth = width + 2
    if (tripWidth < 144) {
      tripWidth = 144
    }
    let trail = Math.round(early ? early * 3.2 : 0)
    if (data.ActualDuration) {
      trail -= tripWidth
    }

    const st =
      data.Status < 4 && data.ScheduledTime
        ? new Date(data.ScheduledTime)
        : null
    const diffPx = st
      ? (now.toDate().getTime() / 60000 - st.getTime() / 60000) * 3.2
      : 0
    const remPx = tripWidth - diffPx
    const latePx = tripWidth - remPx

    return {
      leadOffset,
      tripWidth,
      oe,
      ellapsed,
      early,
      lead,
      trail,
      width,
      latePx,
      remPx,
    }
  }

  formatPhone = str => {
    return formatPhone(str).formatted
  }

  render() {
    const {
      classes,
      securables,
      index,
      row,
      infoWindow,
      workOrders,
      dragging,
      services,
      date,
    } = this.props
    const {
      data,
      resizing,
      time,
      Duration,
      leadOffset,
      tripWidth,
      ellapsed,
      early,
      lead,
      trail,
      latePx,
      remPx,
    } = this.state
    const TECHNICIAN = row.original ? row.original.Technician : row.Technician

    if (!data) {
      return <div></div>
    }
    const late = data.ScheduledTime
      ? new Date(data.ScheduledTime) < new Date()
      : false
    const { WorkOrder, Data } = data
    const workOrder = _.find(workOrders, { WorkOrder })
    Data.WorkOrder = workOrder
    const avatar = 'assets/images/avatars/profile.jpg'
    const accessLevel = _.find(securables, { Securable: 'dispatch-board' })
    const tentative = {
      text: 'Tentative',
      icon: 'add_circle_outline',
      color: 'rgb(100, 150, 200)',
    }
    const variableProps = {
      open: !dragging ? data.ID === infoWindow : false,
      leaveDelay: 1000,
    }

    // window["log"]("WorkOrder: ", workOrder);
    let svc = 'N/A'
    let scopeDescription = 'N/A'
    if (data && data.Data) {
      if (data.Data.Assignments) {
        if (data.Data.Assignments.length > 1) {
          svc = 'Multiple Scopes'
        } else {
          if (data.Data.Assignments.length < 1) {
            svc = 'No Scopes Assigned'
          } else {
            if (workOrder && workOrder.Data) {
              const scp = _.find(workOrder.Data.Scopes, {
                Scope: data.Data.Assignments[0].Scope,
              })
              if (scp) {
                scopeDescription = scp.Description
                const srvc = _.find(services, { Service: scp.Service })
                if (srvc) {
                  svc = `${srvc.Service} - ${srvc.Description}`
                }
              } else {
                svc = 'N/A'
              }
            }
          }
        }
      }
    }

    let tentativeData = null
    if (workOrder && workOrder.Data.Scopes) {
      let woData = [workOrder]

      woData.map(value => {
        let scopes = _.filter(value.Data.Scopes, s => {
          return (
            s.LeadTechnician === TECHNICIAN.Technician &&
            s.Scope === this.props.scopeNum &&
            s.Type !== 'A' &&
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        })
        tentativeData = scopes[0]
      })
    }

    return time && time !== '' ? (
      <React.Fragment>
        {data.Status >= 4 && (
          <div
            onMouseDown={e => e.stopPropagation()}
            onMouseUp={e => e.stopPropagation()}
            style={{
              margin: '5px 0',
              height: 41,
              position: 'absolute',
              top: index * 46,
              left: leadOffset,
              width: tripWidth,
            }}
          >
            {
              Boolean(ellapsed) && lead > 10 && (
                <Tooltip
                  title={
                    <div>
                      <div className='w-full text-center font-bold'>
                        {`Work Order #${data.WorkOrder} Trip #${data.Trip}`}
                      </div>
                      <div className='w-full text-center'>
                        {`${statusProps[0].text} for ${moment(
                          data.ScheduledTime,
                        ).format('h:mm A')} on ${moment(
                          data.ScheduledTime,
                        ).format('MMM D')}`}
                      </div>
                    </div>
                  }
                  placement='top'
                >
                  <div
                    className={classNames(
                      classes.timelineStatus,
                      'rounded-full',
                    )}
                    style={{ left: -(lead + 7) }}
                  >
                    <Icon className={classes.timelineIcon}>
                      {statusProps[0].icon}
                    </Icon>
                  </div>
                </Tooltip>
              )
              // <span className={classNames(classes.dot)} style={{ zIndex: 1, left: -lead }}></span>
            }
            {_.filter(statusProps, o => o.showTimeline).map((info, i) => {
              if (info.status < data.Status && data[info.name]) {
                let sTime = 0
                const bt = new Date(data.BoardTime)
                const st = new Date(data[info.name])
                if (st < bt) {
                  sTime = (bt.getTime() - st.getTime()) / 60000
                }
                const left = Math.round(sTime ? sTime * 3.2 + 7 : 0)
                if (left > 10) {
                  return (
                    <Tooltip
                      key={i}
                      title={
                        <div>
                          <div className='w-full text-center font-bold'>
                            {`Work Order #${data.WorkOrder} Trip #${data.Trip}`}
                          </div>
                          <div className='w-full text-center'>
                            {`${info.text} ${info.prep} ${moment(
                              data[info.name],
                            ).format('h:mm A')} on ${moment(
                              data[info.name],
                            ).format('MMM D')}`}
                          </div>
                        </div>
                      }
                      placement='top'
                    >
                      <div
                        className={classNames(
                          classes.timelineStatus,
                          'rounded-full',
                        )}
                        style={{ left: -left }}
                      >
                        <Icon className={classes.timelineIcon}>
                          {info.icon}
                        </Icon>
                      </div>
                    </Tooltip>
                  )
                }
              }
            })}
            {Boolean(ellapsed) && lead > 10 && (
              <hr
                className={classNames(classes.dottedLine)}
                style={{ zIndex: 1, width: lead + 5, left: -lead }}
              />
            )}
            {Boolean(early) && trail > 10 && (
              <hr
                className={classNames(classes.dottedLine)}
                style={{ zIndex: 1, width: trail + 5, right: -trail }}
              />
            )}
            {Boolean(early) && trail > 10 && (
              <Tooltip
                title={
                  <div>
                    <div className='w-full text-center font-bold'>
                      {`Work Order #${data.WorkOrder} Trip #${data.Trip}`}
                    </div>
                    <div className='w-full text-center'>
                      {`Completion expected by ${moment(data.EndTime).format(
                        'h:mm A',
                      )} on ${moment(data.EndTime).format('MMM D')}`}
                    </div>
                  </div>
                }
                placement='top'
              >
                <div
                  className={classNames(classes.timelineStatus, 'rounded-full')}
                  style={{ right: -(trail + 7) }}
                >
                  <Icon className={classes.timelineIcon}>
                    {statusProps[8].icon}
                  </Icon>
                </div>
                {/* <span className={classNames(classes.dot)} style={{ zIndex: 1, right: -(trail + 5) }}></span> */}
              </Tooltip>
            )}
          </div>
        )}
        {workOrder ? (
          <Tooltip
            {..._.cloneDeepWith(variableProps)}
            /*onOpen={!isMobile && this.openTooltip}*/ onClose={
              !isMobile && this.closeTooltip
            }
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
            disableFocusListener
            classes={{
              popper: classes.popper,
              tooltipPlacementTop: 'with-after',
              tooltipPlacementBottom: 'with-before',
              tooltip: classes.tooltip,
            }}
            interactive
            title={
              // {workOrder ? <Tooltip open={!dragging ? (data.ID === infoWindow) : false} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }} disableFocusListener classes={{ popper: classes.popper, tooltipPlacementTop: "with-after", tooltipPlacementBottom: "with-before", tooltip: classes.tooltip }} interactive title={(
              tentativeData ? (
                <div
                  onMouseDown={e => e.stopPropagation()}
                  onMouseUp={e => e.stopPropagation()}
                >
                  <DispatchBoardWorkOrder
                    fullScreen={true}
                    open={variableProps.open}
                    onUpdate={trip => {
                      this.updateTrip(trip)
                    }}
                    onDelete={trip => {
                      this.deleteTrip(trip)
                    }}
                    clickHandler={this.toggleTooltip}
                    key={data.ID}
                    workOrder={{ ...workOrder, value: 2 }}
                    scopes={[tentativeData]}
                    scopeNum={this.props.scopeNum}
                  />
                </div>
              ) : (
                <div
                  onMouseDown={e => e.stopPropagation()}
                  onMouseUp={e => e.stopPropagation()}
                >
                  <DispatchBoardWorkOrder
                    fullScreen={true}
                    open={variableProps.open}
                    onUpdate={trip => {
                      this.updateTrip(trip)
                    }}
                    onDelete={trip => {
                      this.deleteTrip(trip)
                    }}
                    clickHandler={this.toggleTooltip}
                    key={data.ID}
                    workOrder={{ ...workOrder, value: 2 }}
                    technician={TECHNICIAN}
                    trip={data}
                  />
                </div>
              )
            }
            placement='top'
            /*PopperProps={tooltipX && {
                          anchorEl: {
                              clientHeight: 0,
                              clientWidth: 0,
                              getBoundingClientRect: () => {
                                  // window["warn"]('anchorEl: ', this.ref, this.ref.current);
                                  const el = this.ref.current.getBoundingClientRect();
                                  // window["warn"]('BoundingRect: ', el);
                                  return ({
                                      top: el.top,
                                      left: tooltipX,
                                      right: tooltipX,
                                      bottom: el.bottom,
                                      width: 0,
                                      height: 0,
                                  })
                              }
                          }
                      }}*/
          >
            <Card
              /*onMouseEnter={this.trackMouseX}*/ onMouseDown={e => {
                e.stopPropagation()
              }}
              onMouseUp={e => {
                e.stopPropagation()
              }}
              onMouseMove={e => {
                e.stopPropagation()
              }}
              className={classNames(
                'dispatch-trip',
                resizing
                  ? classes.trip
                  : dragging
                    ? classes.draggingTrip
                    : classes.trip,
              )}
              style={{
                opacity: data.Trip < 1 ? 0.8 : undefined,
                border: `1.5px solid ${
                  data.Trip < 1
                    ? tentative.color
                    : statusProps[data.Status].color
                }`,
                width: this.state.width,
                minWidth: 142,
                top: index * 46,
                left: leadOffset,
              }}
            >
              {latePx > 0 && (
                <div
                  className={classNames(classes.overlayDiv)}
                  style={{ display: 'flex', width: 'calc(100% + 3px)' }}
                >
                  <div
                    className={classNames(classes.overlayDiv1)}
                    style={{ width: latePx }}
                  >
                    <CardHeader
                      /*onMouseMove={this.trackMouseX}*/ onClick={e => {
                        e.stopPropagation()
                        this.toggleTooltip()
                      }}
                      className={classNames(classes.header, 'p-0 pr-8')}
                      style={{
                        padding: '3px 0px 2px 0px',
                        width: tripWidth,
                        background: 'rgb(250, 250, 250)',
                      }}
                      classes={{
                        action: classes.tripAction,
                        avatar: classes.tripIcon,
                        content: classes.content,
                        title: classes.title,
                        subheader: classes.subheader,
                      }}
                      avatar={
                        <Avatar
                          classes={{ root: classes.avatarRoot }}
                          style={{ background: 'orange' }}
                          className={classes.tripAvatar}
                        >
                          <Icon style={{ color: '#fff', fontSize: 32 }}>
                            {data.Trip < 1
                              ? tentative.icon
                              : data.Status === 1 && data.SourceTrip
                                ? statusProps[7].icon
                                : statusProps[data.Status].icon}
                          </Icon>
                        </Avatar>
                      }
                      action={
                        accessLevel &&
                        accessLevel.AccessLevel !== 'R' &&
                        data.Status < 7 && (
                          <div>
                            <IconButton
                              style={{
                                padding: 4,
                                cursor: 'grab',
                                marginRight: 6,
                              }}
                              aria-owns={undefined}
                            >
                              <Icon style={{ color: '#333' }}>code</Icon>
                            </IconButton>
                          </div>
                        )
                      }
                      title={
                        <React.Fragment>
                          <div className='truncate'>
                            {`WO #${workOrder.WorkOrder}`}
                            {dragging &&
                            this.props.selectedTripID ===
                              this.props.currentTripID ? (
                              <span
                                className='font-bold ml-4'
                                style={{
                                  color: 'orange',
                                }}
                              >
                                {this.props.isCmdCtrlPressed
                                  ? 'Moving trip'
                                  : 'Copying trip'}
                              </span>
                            ) : null}
                          </div>
                          <div className='flex truncate'>
                            <div
                              className='truncate mt-2 mr-4'
                              style={{ color: 'orange' }}
                            >
                              {`${
                                data.Trip < 1
                                  ? 'Tentative'
                                  : statusProps[data.Status].text
                              }`}
                            </div>
                            <div
                              style={{
                                backgroundColor: 'orange',
                                color: '#fff',
                                borderRadius: 3,
                                width: 38,
                                padding: '2px 4px 0px 4px',
                                textAlign: 'center',
                              }}
                            >
                              {`${
                                workOrder &&
                                workOrder.Data &&
				workOrder.Data.Site &&
                                workOrder.Data.Site.Zip
                                  ? workOrder.Data.Site.Zip
                                  : '-----'
                              }`}
                            </div>
                          </div>
                          <div className='truncate mt-2'>{svc}</div>
                        </React.Fragment>
                      }
                    />
                  </div>
                  <div
                    className={classNames(classes.overlayDiv2)}
                    style={{
                      width: remPx,
                      borderColor: remPx > 0 ? 'transparent' : 'orange',
                    }}
                  ></div>
                </div>
              )}
              <ResizableBox
                className={
                  accessLevel &&
                  accessLevel.AccessLevel !== 'R' &&
                  data.Status < 7
                    ? 'trip'
                    : 'completed-trip'
                }
                width={this.state.width}
                onResize={this.onResize}
                onResizeStart={this.onResizeStart}
                onResizeStop={this.onResizeStop}
                onTouchStart={e => {
                  e.preventDefault()
                }}
                draggable={
                  accessLevel &&
                  accessLevel.AccessLevel !== 'R' &&
                  !resizing &&
                  data.Status < 7
                }
                onDragStart={this.dragStart}
                onDragEnd={this.dragEnd}
                height={38}
                axis={
                  accessLevel &&
                  accessLevel.AccessLevel !== 'R' &&
                  data.Status < 7
                    ? 'x'
                    : 'none'
                }
                handleSize={
                  accessLevel &&
                  accessLevel.AccessLevel !== 'R' &&
                  data.Status < 7
                    ? [38, 38]
                    : [0, 0]
                }
                minConstraints={[134, 38]}
                draggableOpts={{ grid: [48, 38] }}
              >
                <div ref={this.ref}>
                  <CardHeader
                    /*onMouseMove={this.trackMouseX}*/ onClick={e => {
                      e.stopPropagation()
                      this.toggleTooltip()
                    }}
                    className={classNames(classes.header, 'p-0 pr-8')}
                    style={{ padding: '3px 0px 2px 0px' }}
                    classes={{
                      action: classes.tripAction,
                      avatar: classes.tripIcon,
                      content: classes.content,
                      title: classes.title,
                      subheader: classes.subheader,
                    }}
                    avatar={
                      <Tooltip
                        title={
                          <div className='max-w-192'>
                            <div className='w-full text-center font-bold'>
                              {`Work Order #${data.WorkOrder}${
                                data.Trip
                                  ? ` Trip #${data.Trip}`
                                  : ' - Tentative'
                              }`}
                            </div>
                            {workOrder &&
                              workOrder.Data &&
                              workOrder.Data.Customer && (
                                <div className='w-full text-center'>
                                  {workOrder.Data.Customer.Name &&
                                  workOrder.Data.Customer.Name.length > 0
                                    ? workOrder.Data.Customer.Name
                                    : `${workOrder.Data.Customer.FirstName} ${workOrder.Data.Customer.LastName}`}
                                </div>
                              )}
                            {workOrder &&
                              (workOrder.ContactPhone ||
                                workOrder.Data.Site.Phone ||
                                workOrder.Data.Customer.Phone) && (
                                <div className='w-full text-center'>
                                  {this.formatPhone(
                                    workOrder.ContactPhone ||
                                      workOrder.Data.Site.Phone ||
                                      workOrder.Data.Customer.Phone,
                                  )}
                                </div>
                              )}
                            {workOrder &&
                              workOrder.Data &&
                              workOrder.Data.Site && (
                                <div className='w-full text-center'>
                                  <Icon className='mr-4 text-16 align-middle'>
                                    location_searching
                                  </Icon>
                                  {workOrder.Data.Site.CrossStreets ||
                                    workOrder.Data.Site.Zip}
                                </div>
                              )}
                            {workOrder && (
                              <div className='w-full'>
                                <Divider className='bg-white my-4' />
                                {workOrder.Data.Scopes.map(scopes => {
                                  return (
                                    <div className='w-full text-left'>
                                      Scope {scopes.Scope}: {scopes.Description}
                                    </div>
                                  )
                                })}
                                <Divider className='bg-white my-4' />
                              </div>
                            )}
                            <div className='w-full text-left'>
                              Scheduled Duration: {data.Duration}{' '}
                              {data.Duration !== 1 ? 'Hrs' : 'Hr'}
                            </div>
                            {statusProps.map((info, i) => {
                              if (data[info.name]) {
                                return (
                                  <div key={i} className='w-full text-left'>
                                    {`${info.text} ${info.prep} ${moment(
                                      data[info.name],
                                    ).format('h:mm A')} on ${moment(
                                      data[info.name],
                                    ).format('MMM D')}`}
                                  </div>
                                )
                              }
                            })}
                            {/* <div className="w-full text-center">
                                                            Start Time: {moment(data.StartTime).format("h:mm A on M/D/YYYY")}
                                                        </div>
                                                        <div className="w-full text-center">
                                                            End Time: {moment(data.EndTime).format("h:mm A on M/D/YYYY")}
                                                        </div>
                                                        <div className="w-full text-center">
                                                            Actual Duration: {data.ActualDuration} Hrs
                                                        </div>
                                                        <div className="w-full text-center">
                                                            Offset Index: {index}
                                                        </div> */}
                          </div>
                        }
                        placement='top'
                      >
                        <Avatar
                          classes={{ root: classes.avatarRoot }}
                          style={{
                            background:
                              data.Trip < 1
                                ? tentative.color
                                : statusProps[data.Status].color,
                          }}
                          className={classes.tripAvatar}
                        >
                          <Icon style={{ color: '#fff', fontSize: 32 }}>
                            {data.Trip < 1
                              ? tentative.icon
                              : data.Status === 1 && data.SourceTrip
                                ? statusProps[7].icon
                                : statusProps[data.Status].icon}
                          </Icon>
                        </Avatar>
                      </Tooltip>
                    }
                    action={
                      accessLevel &&
                      accessLevel.AccessLevel !== 'R' &&
                      data.Status < 7 && (
                        <div>
                          <Tooltip
                            open={resizing}
                            placement='top'
                            title={`${Duration} Hr${Duration > 1 ? 's' : ''}`}
                          >
                            <IconButton
                              style={{
                                padding: 4,
                                cursor: 'grab',
                                marginRight: 6,
                              }}
                              aria-owns={undefined}
                            >
                              <Icon style={{ color: '#333' }}>code</Icon>
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    }
                    title={
                      <React.Fragment>
                        <div className='truncate'>
                          {`WO #${workOrder.WorkOrder}`}
                          {dragging &&
                          this.props.selectedTripID ===
                            this.props.currentTripID ? (
                            <span
                              className='font-bold ml-4'
                              style={{
                                color:
                                  data.Trip > 0
                                    ? statusProps[data.Status].color
                                    : tentative.color,
                              }}
                            >
                              {this.props.isCmdCtrlPressed
                                ? 'Moving trip'
                                : 'Copying trip'}
                            </span>
                          ) : null}
                        </div>

                        <div className='flex truncate'>
                          <div
                            className='truncate mt-2 mr-4'
                            style={{
                              color:
                                data.Trip > 0
                                  ? statusProps[data.Status].color
                                  : tentative.color,
                            }}
                          >
                            {`${
                              data.Trip < 1
                                ? 'Tentative'
                                : statusProps[data.Status].text
                            }`}
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                data.Trip > 0
                                  ? statusProps[data.Status].color
                                  : tentative.color,
                              color: '#fff',
                              borderRadius: 3,
                              width: 38,
                              padding: '2px 4px 0px 4px',
                              textAlign: 'center',
                            }}
                          >
                            {`${
                              workOrder &&
                              workOrder.Data &&
                              workOrder.Data.Site &&
                              workOrder.Data.Site.Zip
                                ? workOrder.Data.Site.Zip
                                : '-----'
                            }`}
                          </div>
                        </div>
                        <div className='truncate mt-2'>{svc}</div>
                      </React.Fragment>
                    }
                  />
                </div>
              </ResizableBox>
              {data.Data &&
                data.Data.Holds &&
                data.Data.Holds.map((hold, i) => {
                  const tripWidth =
                    this.state.width < 86 ? 86 : this.state.width
                  const ip = moment(data.InProgressTime)
                  const start = moment(hold.OnHoldTime)
                  const delay = start.diff(ip, 'seconds')
                  const left = (delay / 60 / 30) * 48
                  const end = moment(hold.InProgressTime)
                  const sec = end.diff(start, 'seconds')
                  const width = (sec / 60 / 30) * 48
                  let maxWidth = tripWidth - left
                  maxWidth = maxWidth < 0 ? 0 : maxWidth
                  const borderRadii =
                    left > 10
                      ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                      : {}
                  const borders =
                    maxWidth > 2
                      ? {
                          borderLeft: `1.5px solid ${
                            statusProps[data.Status].color
                          }`,
                          borderRight: `1.5px solid ${
                            statusProps[data.Status].color
                          }`,
                        }
                      : {}
                  return (
                    // <Tooltip placement="bottom" title={`On Hold from ${start.format('h:mm a')} to ${end.format('h:mm a')}`}>
                    <div
                      key={i}
                      className={classes.hold}
                      style={{
                        width,
                        maxWidth,
                        left,
                        ...borderRadii,
                        ...borders,
                      }}
                    ></div>
                    // </Tooltip>
                  )
                })}
            </Card>
          </Tooltip>
        ) : (
          <Card
            onMouseDown={e => {
              e.stopPropagation()
            }}
            onMouseUp={e => {
              e.stopPropagation()
            }}
            onMouseMove={e => {
              e.stopPropagation()
            }}
            className={classNames(
              'dispatch-trip',
              resizing
                ? classes.trip
                : dragging
                  ? classes.draggingTrip
                  : classes.trip,
            )}
            style={{
              border: `1.5px solid ${statusProps[data.Status].color}`,
              width: this.state.width,
              top: index * 46,
              left: leadOffset,
            }}
          >
            <ResizableBox
              className={data.Status < 7 ? 'trip' : 'completed-trip'}
              width={this.state.width}
              onResize={this.onResize}
              onResizeStart={this.onResizeStart}
              onResizeStop={this.onResizeStop}
              onTouchStart={e => {
                e.preventDefault()
              }}
              draggable={
                accessLevel &&
                accessLevel.AccessLevel !== 'R' &&
                !resizing &&
                data.Status < 7
              }
              onDragStart={this.dragStart}
              onDragEnd={this.dragEnd}
              height={38}
              axis={
                accessLevel &&
                accessLevel.AccessLevel !== 'R' &&
                data.Status < 7
                  ? 'x'
                  : 'none'
              }
              handleSize={
                accessLevel &&
                accessLevel.AccessLevel !== 'R' &&
                data.Status < 7
                  ? [38, 38]
                  : [0, 0]
              }
              minConstraints={[134, 38]}
              draggableOpts={{ grid: [48, 38] }}
            >
              <CardHeader
                onClick={e => {
                  e.stopPropagation()
                  this.toggleTooltip()
                }}
                className={classNames(classes.header, 'p-0 pr-8')}
                style={{ padding: '3px 0px 2px 0px' }}
                classes={{
                  action: classes.tripAction,
                  avatar: classes.tripIcon,
                  content: classes.content,
                  title: classes.title,
                  subheader: classes.subheader,
                }}
                avatar={
                  <Tooltip
                    title={
                      <div className='max-w-192'>
                        <div className='w-full text-center font-bold'>
                          {`Work Order #${data.WorkOrder}${
                            data.Trip ? ` Trip #${data.Trip}` : ' - Tentative'
                          }`}
                        </div>
                        {workOrder &&
                          workOrder.Data &&
                          workOrder.Data.Customer && (
                            <div className='w-full text-center'>
                              {workOrder.Data.Customer.Name &&
                              workOrder.Data.Customer.Name.length > 0
                                ? workOrder.Data.Customer.Name
                                : `${workOrder.Data.Customer.FirstName} ${workOrder.Data.Customer.LastName}`}
                            </div>
                          )}
                        {workOrder &&
                          (workOrder.ContactPhone ||
                            workOrder.Data.Site.Phone ||
                            workOrder.Data.Customer.Phone) && (
                            <div className='w-full text-center'>
                              {this.formatPhone(
                                workOrder.ContactPhone ||
                                  workOrder.Data.Site.Phone ||
                                  workOrder.Data.Customer.Phone,
                              )}
                            </div>
                          )}
                        {workOrder &&
                          workOrder.Data &&
                          workOrder.Data.Site && (
                            <div className='w-full text-center'>
                              <Icon className='mr-4 text-16 align-middle'>
                                location_searching
                              </Icon>
                              {workOrder.Data.Site.CrossStreets ||
                                workOrder.Data.Site.Zip}
                            </div>
                          )}
                        {workOrder && (
                          <div className='w-full text-center'>
                            <Divider className='bg-white my-4' />
                            {scopeDescription}
                            <Divider className='bg-white my-4' />
                          </div>
                        )}
                        <div className='w-full text-center'>
                          Scheduled Duration: {data.Duration}{' '}
                          {data.Duration !== 1 ? 'Hrs' : 'Hr'}
                        </div>
                        {statusProps.map((info, i) => {
                          if (data[info.name]) {
                            return (
                              <div key={i} className='w-full text-left'>
                                {`${info.text} ${info.prep} ${moment(
                                  data[info.name],
                                ).format('h:mm A')} on ${moment(
                                  data[info.name],
                                ).format('MMM D')}`}
                              </div>
                            )
                          }
                        })}
                        {/* <div className="w-full text-center">
                                                        Start Time: {moment(data.StartTime).format("h:mm A on M/D/YYYY")}
                                                    </div>
                                                    <div className="w-full text-center">
                                                        End Time: {moment(data.EndTime).format("h:mm A on M/D/YYYY")}
                                                    </div>
                                                    <div className="w-full text-center">
                                                        Actual Duration: {data.ActualDuration} Hrs
                                                    </div>
                                                    <div className="w-full text-center">
                                                        Offset Index: {index}
                                                    </div> */}
                      </div>
                    }
                    placement='top'
                  >
                    <Avatar
                      classes={{ root: classes.avatarRoot }}
                      style={{
                        background:
                          data.Trip < 1
                            ? tentative.color
                            : statusProps[data.Status].color,
                      }}
                      className={classes.tripAvatar}
                    >
                      <Icon style={{ color: '#fff', fontSize: 32 }}>
                        {data.Trip < 1
                          ? tentative.icon
                          : data.Status === 1 && data.SourceTrip
                            ? statusProps[7].icon
                            : statusProps[data.Status].icon}
                      </Icon>
                    </Avatar>
                  </Tooltip>
                }
                action={
                  accessLevel &&
                  accessLevel.AccessLevel !== 'R' &&
                  data.Status < 7 && (
                    <div>
                      <Tooltip
                        open={resizing}
                        placement='top'
                        title={`${Duration} Hr${Duration > 1 ? 's' : ''}`}
                      >
                        <IconButton
                          style={{ padding: 4, cursor: 'grab', marginRight: 6 }}
                          aria-owns={undefined}
                        >
                          <Icon style={{ color: '#333' }}>code</Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  )
                }
                title={
                  <React.Fragment>
                    <div className='truncate'>{`WO #${data.WorkOrder}`}</div>
                    <div className='flex truncate'>
                      <div
                        className='truncate mt-2 mr-4'
                        style={{
                          color:
                            data.Trip > 0
                              ? statusProps[data.Status].color
                              : tentative.color,
                        }}
                      >
                        {`${
                          data.Trip < 1
                            ? 'Tentative'
                            : statusProps[data.Status].text
                        }`}
                      </div>
                      <div
                        style={{
                          backgroundColor:
                            data.Trip > 0
                              ? statusProps[data.Status].color
                              : tentative.color,
                          color: '#fff',
                          borderRadius: 3,
                          width: 38,
                          padding: '2px 4px 0px 4px',
                          textAlign: 'center',
                        }}
                      >
                        {`${
                          workOrder &&
                          workOrder.Data &&
                          workOrder.Data.Site &&
                          workOrder.Data.Site.Zip
                            ? workOrder.Data.Site.Zip
                            : '-----'
                        }`}
                      </div>
                    </div>
                    <div className='truncate mt-2'>{svc}</div>
                  </React.Fragment>
                }
              />
            </ResizableBox>
          </Card>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {workOrder ? (
          <Tooltip
            {..._.cloneDeepWith(variableProps)}
            /*onOpen={!isMobile && this.openTooltip}*/ onClose={
              !isMobile && this.closeTooltip
            }
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
            disableFocusListener
            classes={{
              popper: classes.popper,
              tooltipPlacementTop: 'with-after',
              tooltipPlacementBottom: 'with-before',
              tooltip: classes.tooltip,
            }}
            interactive
            title={
              // <Tooltip open={!dragging ? (data.ID === infoWindow) : false} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }} disableFocusListener classes={{ popper: classes.popper, tooltipPlacementTop: "with-after", tooltipPlacementBottom: "with-before", tooltip: classes.tooltip }} interactive title={(

              <div
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
              >
                <DispatchBoardWorkOrder
                  fullScreen={true}
                  open={variableProps.open}
                  onUpdate={trip => {
                    this.updateTrip(trip)
                  }}
                  onDelete={trip => {
                    this.deleteTrip(trip)
                  }}
                  clickHandler={this.toggleTooltip}
                  key={data.ID}
                  workOrder={{ ...workOrder, value: 2 }}
                  technician={TECHNICIAN}
                  trip={data}
                />
              </div>
            }
            placement='top'
          >
            <Card
              /*onMouseEnter={this.trackMouseX}*/ onMouseDown={e => {
                e.stopPropagation()
              }}
              onMouseUp={e => {
                e.stopPropagation()
              }}
              onMouseMove={e => {
                e.stopPropagation()
              }}
              onTouchStart={e => e.stopPropagation()}
              draggable={
                accessLevel && accessLevel.AccessLevel !== 'R' && !resizing
              }
              onDragStart={this.dragStart}
              onDragEnd={this.dragEnd}
              className={
                resizing
                  ? classes.trip
                  : dragging
                    ? classes.draggingTrip
                    : classes.trip
              }
              style={{
                opacity: data.Trip < 1 ? 0.5 : undefined,
                border: `1.5px solid ${
                  data.Trip < 1
                    ? tentative.color
                    : statusProps[data.Status].color
                }`,
                width: 215,
                position: 'relative',
                top: 5,
                marginTop: 0,
              }}
            >
              <div width='100%'>
                <CardHeader
                  onMouseDown={e => e.stopPropagation()}
                  onMouseUp={e => e.stopPropagation()}
                  onClick={e => {
                    e.stopPropagation()
                    this.toggleTooltip()
                  }}
                  className={classNames(classes.header, 'p-0 pr-4')}
                  style={{ padding: '3px 0px 2px 0px' }}
                  classes={{
                    action: classes.tripAction,
                    avatar: classes.tripIcon,
                    content: classes.content,
                    title: classes.title,
                    subheader: classes.subheader,
                  }}
                  avatar={
                    <Tooltip
                      title={
                        <div className='max-w-192'>
                          <div className='w-full text-center font-bold'>
                            {`Work Order #${data.WorkOrder}${
                              data.Trip ? ` Trip #${data.Trip}` : ' - Tentative'
                            }`}
                          </div>
                          {workOrder &&
                            workOrder.Data &&
                            workOrder.Data.Customer && (
                              <div className='w-full text-center'>
                                {workOrder.Data.Customer.Name &&
                                workOrder.Data.Customer.Name.length > 0
                                  ? workOrder.Data.Customer.Name
                                  : `${workOrder.Data.Customer.FirstName} ${workOrder.Data.Customer.LastName}`}
                              </div>
                            )}
                          {workOrder &&
                            (workOrder.ContactPhone ||
                              workOrder.Data.Site.Phone ||
                              workOrder.Data.Customer.Phone) && (
                              <div className='w-full text-center'>
                                {this.formatPhone(
                                  workOrder.ContactPhone ||
                                    workOrder.Data.Site.Phone ||
                                    workOrder.Data.Customer.Phone,
                                )}
                              </div>
                            )}
                          {workOrder &&
                            workOrder.Data &&
                            workOrder.Data.Site && (
                              <div className='w-full text-center'>
                                <Icon className='mr-4 text-16 align-middle'>
                                  location_searching
                                </Icon>
                                {workOrder.Data.Site.CrossStreets ||
                                  workOrder.Data.Site.Zip}
                              </div>
                            )}
                          {workOrder && (
                            <div className='w-full'>
                              <Divider className='bg-white my-4' />
                              {workOrder.Data.Scopes.map(scopes => {
                                return (
                                  <div className='w-full text-left'>
                                    Scope {scopes.Scope}: {scopes.Description}
                                  </div>
                                )
                              })}
                              <Divider className='bg-white my-4' />
                            </div>
                          )}
                          <div className='w-full text-left'>
                            Scheduled Duration: {data.Duration}{' '}
                            {data.Duration !== 1 ? 'Hrs' : 'Hr'}
                          </div>
                          {statusProps.map((info, i) => {
                            if (data[info.name]) {
                              return (
                                <div key={i} className='w-full text-left'>
                                  {`${info.text} ${info.prep} ${moment(
                                    data[info.name],
                                  ).format('h:mm A')} on ${moment(
                                    data[info.name],
                                  ).format('MMM D')}`}
                                </div>
                              )
                            }
                          })}
                        </div>
                      }
                      placement='top'
                    >
                      <Avatar
                        classes={{ root: classes.avatarRoot }}
                        style={{
                          background:
                            data.Trip < 1
                              ? tentative.color
                              : statusProps[data.Status].color,
                        }}
                        className={classes.tripAvatar}
                      >
                        <Icon style={{ color: '#fff', fontSize: 32 }}>
                          {data.Trip < 1
                            ? tentative.icon
                            : data.Status === 1 && data.SourceTrip
                              ? statusProps[7].icon
                              : statusProps[data.Status].icon}
                        </Icon>
                      </Avatar>
                    </Tooltip>
                  }
                  title={
                    <React.Fragment>
                      <div className='truncate'>
                        {`WO #${workOrder.WorkOrder}`}
                        {dragging &&
                        this.props.selectedTripID ===
                          this.props.currentTripID ? (
                          <span
                            className='font-bold ml-4'
                            style={{
                              color:
                                data.Trip > 0
                                  ? statusProps[data.Status].color
                                  : tentative.color,
                            }}
                          >
                            {this.props.isCmdCtrlPressed
                              ? 'Moving trip'
                              : 'Copying trip'}
                          </span>
                        ) : null}
                      </div>
                      <div className='flex truncate'>
                        <div
                          className='truncate mt-2 mr-4'
                          style={{
                            color:
                              data.Trip > 0
                                ? statusProps[data.Status].color
                                : tentative.color,
                          }}
                        >
                          {`${
                            data.Trip < 1
                              ? 'Tentative'
                              : statusProps[data.Status].text
                          }`}
                        </div>
                        <div
                          style={{
                            backgroundColor:
                              data.Trip > 0
                                ? statusProps[data.Status].color
                                : tentative.color,
                            color: '#fff',
                            borderRadius: 3,
                            width: 38,
                            padding: '2px 4px 0px 4px',
                            textAlign: 'center',
                          }}
                        >
                          {`${
                            workOrder.Data.Site.Zip
                              ? workOrder.Data.Site.Zip
                              : ''
                          }`}
                        </div>
                      </div>
                      <div className='truncate mt-2'>{svc}</div>
                    </React.Fragment>
                  }
                />
              </div>
            </Card>
          </Tooltip>
        ) : (
          <Card
            onMouseDown={e => {
              e.stopPropagation()
            }}
            onMouseUp={e => {
              e.stopPropagation()
            }}
            onMouseMove={e => {
              e.stopPropagation()
            }}
            onTouchStart={e => e.stopPropagation()}
            draggable={
              accessLevel && accessLevel.AccessLevel !== 'R' && !resizing
            }
            onDragStart={this.dragStart}
            onDragEnd={this.dragEnd}
            className={
              resizing
                ? classes.trip
                : dragging
                  ? classes.draggingTrip
                  : classes.trip
            }
            style={{
              border: `1.5px solid ${statusProps[data.Status].color}`,
              width: 215,
              position: 'relative',
              top: 5,
              marginTop: 0,
            }}
          >
            <div width='100%'>
              <CardHeader
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                onMouseEnter={this.trackMouseX}
                onClick={e => {
                  e.stopPropagation()
                  this.toggleTooltip()
                }}
                className={classNames(classes.header, 'p-0 pr-4')}
                style={{ padding: '3px 0px 2px 0px' }}
                classes={{
                  action: classes.tripAction,
                  avatar: classes.tripIcon,
                  content: classes.content,
                  title: classes.title,
                  subheader: classes.subheader,
                }}
                avatar={
                  <Avatar
                    classes={{ root: classes.avatarRoot }}
                    style={{
                      background:
                        data.Trip < 1
                          ? tentative.color
                          : statusProps[data.Status].color,
                    }}
                    className={classes.tripAvatar}
                  >
                    <Icon style={{ color: '#fff', fontSize: 32 }}>
                      {data.Trip < 1
                        ? tentative.icon
                        : data.Status === 1 && data.SourceTrip
                          ? statusProps[7].icon
                          : statusProps[data.Status].icon}
                    </Icon>
                  </Avatar>
                }
                title={
                  <React.Fragment>
                    <div className='truncate'>{`WO #${data.WorkOrder}`}</div>
                    <div className='flex truncate'>
                      <div
                        className='truncate mt-2 mr-4'
                        style={{
                          color:
                            data.Trip > 0
                              ? statusProps[data.Status].color
                              : tentative.color,
                        }}
                      >
                        {`${
                          data.Trip < 1
                            ? 'Tentative'
                            : statusProps[data.Status].text
                        }`}
                      </div>
                      <div
                        style={{
                          backgroundColor:
                            data.Trip > 0
                              ? statusProps[data.Status].color
                              : tentative.color,
                          color: '#fff',
                          borderRadius: 3,
                          width: 38,
                          padding: '2px 4px 0px 4px',
                          textAlign: 'center',
                        }}
                      >
                        {`${
                          workOrder && workOrder.Data && workOrder.Data.Site.Zip
                            ? workOrder.Data.Site.Zip
                            : '-----'
                        }`}
                      </div>
                    </div>
                    <div className='truncate mt-2'>{svc}</div>
                  </React.Fragment>
                }
              />
            </div>
          </Card>
        )}
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
      setTripInfo: Actions.setTripInfo,
      updateTrip: Actions.updateTrip,
      setDragging: Actions.setDragging,
      openInfoWindow: Actions.openInfoWindow,
      updateWorkOrderScope,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, dispatchBoardApp }) {
  return {
    user: dispatchBoardApp.user,
    technicianData: spReducers.technicians,
    dragging: dispatchBoardApp.dispatchBoard.dragging,
    infoWindow: dispatchBoardApp.dispatchBoard.infoWindow,
    workOrders: spReducers.workOrders,
    securables: spReducers.userProfiles.User.Data.Securables,
    date: dispatchBoardApp.dispatchBoard.date,
    services: spReducers.services,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchBoardTrip)),
)
