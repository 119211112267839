import axios from 'axios/index';
import { getUserData } from 'main/content/apps/departments/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_DEPARTMENTS = '[DEPARTMENTS APP] GET DEPARTMENTS';
export const SET_SEARCH_TEXT = '[DEPARTMENTS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_DEPARTMENTS = '[DEPARTMENTS APP] TOGGLE IN SELECTED DEPARTMENTS';
export const SELECT_ALL_DEPARTMENTS = '[DEPARTMENTS APP] SELECT ALL DEPARTMENTS';
export const DESELECT_ALL_DEPARTMENTS = '[DEPARTMENTS APP] DESELECT ALL DEPARTMENTS';
export const OPEN_NEW_DEPARTMENT_DIALOG = '[DEPARTMENTS APP] OPEN NEW DEPARTMENT DIALOG';
export const CLOSE_NEW_DEPARTMENT_DIALOG = '[DEPARTMENTS APP] CLOSE NEW DEPARTMENT DIALOG';
export const OPEN_EDIT_DEPARTMENT_DIALOG = '[DEPARTMENTS APP] OPEN EDIT DEPARTMENT DIALOG';
export const CLOSE_EDIT_DEPARTMENT_DIALOG = '[DEPARTMENTS APP] CLOSE EDIT DEPARTMENT DIALOG';
export const ADD_DEPARTMENT = '[DEPARTMENTS APP] ADD DEPARTMENT';
export const UPDATE_DEPARTMENT = '[DEPARTMENTS APP] UPDATE DEPARTMENT';
export const REMOVE_DEPARTMENT = '[DEPARTMENTS APP] REMOVE DEPARTMENT';
export const REMOVE_DEPARTMENTS = '[DEPARTMENTS APP] REMOVE DEPARTMENTS';
export const TOGGLE_STARRED_DEPARTMENT = '[DEPARTMENTS APP] TOGGLE STARRED DEPARTMENT';
export const TOGGLE_STARRED_DEPARTMENTS = '[DEPARTMENTS APP] TOGGLE STARRED DEPARTMENTS';
export const SET_DEPARTMENTS_STARRED = '[DEPARTMENTS APP] SET DEPARTMENTS STARRED ';

export function getDepartments(routeParams) {
    const request = axios.get('/api/departments-app/departments', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_DEPARTMENTS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedDepartments(departmentId) {
    return {
        type: TOGGLE_IN_SELECTED_DEPARTMENTS,
        departmentId
    }
}


export function selectAllDepartments() {
    return {
        type: SELECT_ALL_DEPARTMENTS
    }
}

export function deSelectAllDepartments() {
    return {
        type: DESELECT_ALL_DEPARTMENTS
    }
}


export function openNewDepartmentDialog() {
    return {
        type: OPEN_NEW_DEPARTMENT_DIALOG
    }
}

export function closeNewDepartmentDialog() {
    return {
        type: CLOSE_NEW_DEPARTMENT_DIALOG
    }
}

export function openEditDepartmentDialog(data) {
    return {
        type: OPEN_EDIT_DEPARTMENT_DIALOG,
        data
    }
}

export function closeEditDepartmentDialog() {
    return {
        type: CLOSE_EDIT_DEPARTMENT_DIALOG
    }
}

export function addDepartment(newDepartment) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post(`${window["apiLocation"]}/api/Department`,
            newDepartment
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_DEPARTMENT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Department ${newDepartment.Department} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateDepartment(department) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.put(`${window["apiLocation"]}/api/Department?Co=${encodeURIComponent(department.Co)}&Department=${encodeURIComponent(department.Department)}`,
            department
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_DEPARTMENT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Department ${department.Department} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeDepartment(departmentId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post('/api/departments-app/remove-department', {
            departmentId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DEPARTMENT
                })
            ]).then(() => dispatch(getDepartments(routeParams)))
        );
    };
}


export function removeDepartments(departmentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post('/api/departments-app/remove-departments', {
            departmentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DEPARTMENTS
                }),
                dispatch({
                    type: DESELECT_ALL_DEPARTMENTS
                })
            ]).then(() => dispatch(getDepartments(routeParams)))
        );
    };
}

export function toggleStarredDepartment(departmentId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post('/api/departments-app/toggle-starred-department', {
            departmentId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DEPARTMENT
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDepartments(routeParams)))
        );
    };
}

export function toggleStarredDepartments(departmentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post('/api/departments-app/toggle-starred-departments', {
            departmentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DEPARTMENTS
                }),
                dispatch({
                    type: DESELECT_ALL_DEPARTMENTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDepartments(routeParams)))
        );
    };
}

export function setDepartmentsStarred(departmentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post('/api/departments-app/set-departments-starred', {
            departmentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DEPARTMENTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DEPARTMENTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDepartments(routeParams)))
        );
    };
}

export function setDepartmentsUnstarred(departmentIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().departmentsApp.departments;

        const request = axios.post('/api/departments-app/set-departments-unstarred', {
            departmentIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DEPARTMENTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DEPARTMENTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDepartments(routeParams)))
        );
    };
}
