import { combineReducers } from 'redux';
import dispatchBoard from './dispatch-board.reducer';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
// import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import dateTransform from 'store/transforms/dateTransform';
import CapacitorStorage from 'store/storage/CapacitorStorage';
import localSporage from 'store/storage/CacheManager';
import user from './user.reducer';


const dispatchFilter = createFilter(
    'dispatchBoard',
    ['selectedBoard']
);

const dbPersistConfig = {
    key: 'sp-dispatch-2',
    // storage: window.Cordova ? CapacitorStorage : localSporage,
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    transforms: [dispatchFilter/*, dateTransform*/],
    serialize: false,
    deserialize: false,
};

const dispatchBoardAppReducers = combineReducers({
    dispatchBoard: dispatchBoard,
    user
});

export default persistReducer(dbPersistConfig, dispatchBoardAppReducers);
