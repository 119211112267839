import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate, FuseUtils } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import PurchaseOrderList from 'main/content/apps/purchase-orders/PurchaseOrdersList';
import InvoiceList from 'main/content/apps/invoices/InvoicesList';
import TimecardList from 'main/content/apps/timecards/TimecardsList';
import WorkOrderList from 'main/content/apps/work-orders/WorkOrdersList';
import CallList from 'main/content/apps/calls/CallsList';
import WorkOrderScopeList from 'main/content/apps/work-order-scopes/WorkOrderScopesList';
import AuditPostingHeader from 'main/content/apps/audit-posting/AuditPostingHeader';
import AuditPostingSidebarContent from 'main/content/apps/audit-posting/AuditPostingSidebarContent';
import AuditPostingLeftSidebarHeader from 'main/content/apps/audit-posting/AuditPostingLeftSidebarHeader';
import PurchaseOrderDialog from 'main/content/apps/purchase-orders/PurchaseOrderDialog';
import TimecardDialog from 'main/content/apps/timecards/TimecardDialog';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class AuditPostingApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            // this.props.getAuditPosting(this.props.match.params);
        }
    }

    getContent = () => {
        const { auditPosting, searchText, loading, status, glAccount } = this.props;

        const data = this.getFilteredArray(_.cloneDeep(auditPosting), searchText);

        if (this.props.type === "C") {
            return <CallList audit={true} loading2={loading} selectedCallData={[...data]} />
        }
        if (this.props.type === "I") {
            return <InvoiceList status={true} loading={loading} invoiceData={[...data]} />
        }
        if (this.props.type === "P") {
            return <PurchaseOrderList status={true} loading={loading} selectedPurchaseOrderData={[...data]} />
        }
        if (this.props.type === "T") {
            return <TimecardList status={status} loading2={loading} lines={true} selectedTimecardData={[...data]} />
        }
        if (this.props.type === "W") {
            return <WorkOrderList audit={true} loading2={loading} selectedWOData={[...data]} />
        }
        if (this.props.type === "S") {
            return <WorkOrderScopeList audit={true} loading2={loading} selectedWOData={[...data]} />
        }
    }

    getFilteredArray = (entities, searchText) => {
        const { status, glAccount, type, vendor, paymentStatus, paymentType, deliveryStatus, reviewStatus, employees } = this.props;
        const filters = { "T": ['FirstName', 'LastName', 'Employee', 'Date', 'WorkOrder'] };
        if (type === 'T') {
            entities.forEach((rec) => {
                const { Co, Employee } = rec;
                rec.meta = {
                    employee: _.find(employees, { Co, Employee })
                }
            })
        }
        let filtered = entities;

        if (type === "I") {
            filtered = _.filter(entities, (i) => {
                var comparisonValue = i.PaymentTypes || '';

                if (i.PaymentStatus !== (paymentStatus || i.PaymentStatus)) return false;

                if (paymentType === "MULTI") {
                    return comparisonValue.indexOf(',') > -1;
                }

                if(paymentType === 'Financed'){
                    return i.IsFinanced === true;
                }

                if(paymentType === 'ApprovedAccount'){
                    return i.IsApprovedAccount === true;
                }

                return comparisonValue === (paymentType || i.PaymentTypes || '');
            });

            if (deliveryStatus !== null) {
                filtered = _.filter(filtered, (o) => {
                    if (deliveryStatus === "sent") {
                        return o.EmailCount > 0;
                    } else {
                        return o.EmailCount < 1;
                    }
                })
            }
            if (reviewStatus !== null) {
                filtered = _.filter(filtered, (o) => {
                    if (reviewStatus === "Y") {
                        return o.InvoiceReviewYN === 'Y'
                    } else {
                        return o.InvoiceReview !== 'Y'
                    }
                })
            }
            if (status !== null) {
                filtered = _.filter(filtered, (i) => {
                    var Status = i.Data && i.Data.Data ? (i.Data.Data.VPARBH ? 1 : i.Data.Data.VPARTH ? 2 : 0) : 0;
                    return Status === status;
                });
                if (status > 0 && glAccount) {
                    filtered = _.filter(filtered, (i) => {
                        if (i.Data.Data && i.Data.Data.InvoiceDetails) {
                            i.Data.Data.InvoiceDetails = _.filter(i.Data.Data.InvoiceDetails, (d) => {
                                if (d.Data && d.Data && d.Data.InvoiceLines) {
                                    d.Data.InvoiceLines = _.filter(d.Data.InvoiceLines, (l) => {
                                        return l.Data && ((l.Data.VPARBL && l.Data.VPARBL.GLAcct === glAccount) || (l.Data.VPARTL && l.Data.VPARTL.GLAcct === glAccount));
                                    });
                                    // window["warn"](d.Data.InvoiceLines);
                                }
                                return d.Data && d.Data.InvoiceLines && d.Data.InvoiceLines.length > 0;
                            });
                            // window["warn"](i.Data.Data.InvoiceDetails);
                        };
                        return i.Data && i.Data.Data && i.Data.Data.InvoiceDetails && i.Data.Data.InvoiceDetails.length > 0;
                    })
                    // window["warn"](filtered);
                }
            }
        }
        if (type === "P") {
            if (status !== null) {
                filtered = _.filter(entities, (i) => {
                    var Status = i.Data ? (i.Data.VPPOHB ? 1 : i.Data.VPPOHD ? 2 : 0) : 0;
                    return Status === status;
                });
            }
            if (vendor) {
                filtered = _.filter(filtered, { Vendor: vendor });
            }
        }
        const arr = Object.keys(filtered).map((id) => filtered[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText, filters[type]);
    };

    render() {
        const { classes, openNewAuditPostingDialog, auditPosting, searchText } = this.props;
        const body = this.getContent();

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <AuditPostingHeader pageLayout={() => this.pageLayout} />
                            }
                            content={body}
                            leftSidebarHeader={
                                <AuditPostingLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <AuditPostingSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {/* <PurchaseOrderDialog /> */}
                        <TimecardDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ auditPostingApp, spReducers }) {
    return {
        auditPosting: auditPostingApp.auditPosting.entities,
        searchText: auditPostingApp.auditPosting.searchText,
        user: auditPostingApp.user,
        type: auditPostingApp.auditPosting.type,
        loading: auditPostingApp.auditPosting.loading,
        paymentStatus: auditPostingApp.auditPosting.paymentStatus,
        paymentType: auditPostingApp.auditPosting.paymentType,
        status: auditPostingApp.auditPosting.status,
        deliveryStatus: auditPostingApp.auditPosting.deliveryStatus,
        reviewStatus: auditPostingApp.auditPosting.reviewStatus,
        glAccount: auditPostingApp.auditPosting.glAccount,
        vendor: auditPostingApp.auditPosting.vendor,
        employees: spReducers.employees
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditPostingApp)));
