import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Fab, Icon, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as DispatchActions from '../dispatch-board/store/actions';
import * as WorkOrderActions from '../work-orders/store/actions';
import * as Actions from './store/actions';
import axios from 'axios';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Records Found
            </div>
        </div>
    )
}

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class TripsList extends Component {

    state = {
        selectedTripsMenu: null,
        date: new Date(),
        loading: false,
        hist: null
    };

    componentDidMount() {
        let { date, loading, hist, customer, Co } = this.props;
        this.setState({ ...this.state, date, loading, hist }, () => {
            if (customer && hist) {
                this.fetchData(Co, hist.Page);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let { date, loading, hist } = this.props;
        if (!_.isEqual(date, prevProps.date) || !_.isEqual(loading, prevProps.loading) || !_.isEqual(hist, prevProps.hist)) {
            this.setState({ ...this.state, date, loading, hist });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTripMenu = (event) => {
        this.setState({ selectedTripsMenu: event.currentTarget });
    };

    closeSelectedTripsMenu = () => {
        this.setState({ selectedTripsMenu: null });
    };

    handleDateChange = (event) => {
        window["warn"](event);
        const date = event._d;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        const newDate = this.formatDateInput(date);
        if (oldDate !== newDate) {
            this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
                this.props.setBoardDate(this.props.Co, date, old);
                this.updateDate(this.props.Co, newDate, oldDate);
            });
        }
    }

    handleToday = (event) => {
        const date = new Date();
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    handleNextDate = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        date.setDate(date.getDate() + 1);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    handlePrevDate = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        date.setDate(date.getDate() - 1);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    updateDate = _.debounce((Co, date, oldDate) => { this.props.getTripData(Co, date, oldDate); this.props.getWOData(Co, date, oldDate); }, 1000);

    formatDateInput(dt) {
        return dt.toLocaleDateString('en-US').replace(/\//g, '-');
    }

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    openDatePicker = (e) => {
        this.refs.datePicker.open(e);
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    handleRefresh = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        this.props.setBoardDate(this.props.Co, date, old);
        this.updateDate(this.props.Co, oldDate, oldDate);
    }

    fetchData = (Co, Page) => {
        const { customer, site, hist } = this.props;

        if (hist) {
            this.setState({ loading: true }, () => {
                const request = axios.get(`${window["apiLocation"]}/api/Trip?Co=${Co}&Customer=${customer}${site ? `&Site=${site}` : ''}&Page=${Page}`);
                request.then((response) => {
                    this.setState({ hist: response.data, loading: false });
                }).catch(() => {
                    this.setState({ loading: false });
                })
            });
        }
    }

    render() {
        const { classes, securables, trips, user, searchText, selectedTripIds, selectAllTrips, deSelectAllTrips, technicians, technician, openEditWorkOrderDialog, Co, openNewTripDialog, toggleInSelectedTrips, openEditTripDialog, removeTrips, removeTrip, toggleStarredTrip, setTripsUnstarred, setTripsStarred, readOnly } = this.props;
        const data = this.getFilteredArray((!this.props.selectedTripData ? !this.props.tripData ? [] : this.props.tripData : this.props.selectedTripData), searchText);
        const { selectedTripsMenu, loading, date, hist } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "dispatch-board" });
        const statusProps =
            [
                { text: 'Scheduled', icon: 'access_time', color: 'rgb(100, 100, 200)', dateProp: 'ScheduledDate', timeProp: 'ScheduledTime' },
                { text: 'Notified', icon: 'offline_bolt', color: 'rgb(0, 150, 250)', dateProp: 'NotifiedDate', timeProp: 'NotifiedTime' },
                { text: 'Accepted', icon: 'offline_pin', color: 'rgb(0, 50, 250)', dateProp: 'AcceptedDate', timeProp: 'AcceptedTime' },
                { text: 'Rejected', icon: 'cancel', color: 'rgb(210, 0, 0)', dateProp: 'RejectedDate', timeProp: 'RejectedTime' },
                { text: 'En-Route', icon: 'explore', color: 'rgb(50, 170, 200)', dateProp: 'EnRouteDate', timeProp: 'EnRouteTime' },
                { text: 'Arrived', icon: 'my_location', color: 'rgb(50, 150, 100)', dateProp: 'ArrivedDate', timeProp: 'ArrivedTime' },
                { text: 'In Progress', icon: 'play_circle_outline', color: 'rgb(150, 200, 50)', dateProp: 'InProgressDate', timeProp: 'InProgressTime' },
                { text: 'On Hold', icon: 'pause_circle_outline', color: 'rgb(210, 100, 100)', dateProp: 'OnHoldDate', timeProp: 'OnHoldTime' },
                { text: 'Completed', icon: 'check_circle', color: 'rgb(100, 100, 100)', dateProp: 'CompletedDate', timeProp: 'CompletedTime' }
            ];
        const manualOptions = (hist ? {
            manual: true,
            data: hist.Results,
            pages: hist.PageCount,
            onFetchData: (e) => { this.fetchData(Co, e.page + 1); },
            defaultPageSize: 10,
            pageSizeOptions: [10],
            sortable: false
        } : {
            data,
            defaultPageSize: 10,
        });
        window["log"]('Trip List History: ', manualOptions, hist, this.props.hist);
        return (
            <FuseAnimate animation={this.props.animation || "transition.slideUpIn"} delay={300}>
                <div>
                    <div className="sm:flex p-12 sm:p-0">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12, display: readOnly ? 'none' : 'flex' }} className="flex"><Icon color="primary" className="text-32 mr-12">local_shipping</Icon>
                            <span>Trips</span>
                            {(!this.props.selectedTripData || technician) && <Icon color="action" onClick={this.handleRefresh} className={!loading ? "text-24 ml-8 mt-4 cursor-pointer" : "text-24 ml-8 mt-4 cursor-pointer spin"}>refresh</Icon>}
                        </Typography>
                        {(!this.props.selectedTripData || technician) &&
                            <div className="flex sm:absolute sm:pin-r pt-4">
                                <Tooltip title="View Today's Work Orders" placement="top">
                                    <div className="flex mr-12">
                                        <Icon onClick={this.handleToday} className="text-24 cursor-pointer" color="action">today</Icon>
                                    </div>
                                </Tooltip>
                                <Typography variant="caption" style={{ fontWeight: 'bold', alignItems: 'center', }} className="flex">
                                    <Tooltip title="Select Date" placement="top">
                                        <div className="picker">
                                            <DatePicker variant="inline"
                                                inputVariant="outlined"
                                                ref="datePicker"
                                                label="Date"
                                                inputProps={
                                                    {
                                                        style: {
                                                            padding: 12,
                                                        }
                                                    }
                                                }
                                                TextFieldComponent={this.renderDateLabel}
                                                value={date}
                                                onChange={this.handleDateChange}
                                                showTodayButton
                                                autoOk
                                                animateYearScrolling
                                            />
                                        </div>
                                    </Tooltip>
                                    <Icon onClick={this.handlePrevDate} color="action" className="text-24 mr-4 cursor-pointer">keyboard_arrow_left</Icon>
                                    <Icon onClick={this.handleNextDate} color="action" className={`text-24 ml-4 cursor-pointer ${!this.props.technician ? 'mr-12' : ''}`}>keyboard_arrow_right</Icon>
                                    {!this.props.technician &&
                                        <Fab
                                            color="secondary"
                                            aria-label="add"
                                            className={classes.addButton}
                                            onClick={openNewTripDialog}
                                        >
                                            <Icon>add</Icon>
                                        </Fab>
                                    }
                                </Typography>
                            </div>
                        }
                    </div>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTripDialog(rowInfo.original, readOnly);
                                    }
                                }
                            }
                        }}
                        {...manualOptions}
                        loading={loading || this.state.loading} // Display the loading overlay when we need it
                        LoadingComponent={loading ? LoadingDiv : undefined}
                        NoDataComponent={loading ? LoadingDiv : NoData}
                        columns={[
                            {
                                Header: "Work Order",
                                Accessor: "WorkOrder",
                                className: "font-bold justify-center",
                                // show: Boolean(this.props.technician),
                                Cell: row => <div onClick={(e) => { e.stopPropagation(); openEditWorkOrderDialog({ ...row.original }, row.original.Trip); }}>
                                    {row.original.WorkOrder}{!readOnly && <Icon className="text-14 font-bold ml-4" style={{ paddingTop: 2 }}>open_in_new</Icon>}
                                </div>
                            },
                            {
                                Header: "Trip",
                                accessor: "Trip",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Technician",
                                accessor: "Technician",
                                minWidth: 256,
                                className: "justify-center",
                                Cell: row => {
                                    return technicians.map((value, index) => (
                                        value.Technician === row.original.Technician &&
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                            {
                                Header: "Status",
                                accessor: "Status",
                                minWidth: 128,
                                Cell: row => (
                                    <React.Fragment>
                                        <Icon style={{ marginRight: 6, marginLeft: 8, }}>{`${statusProps[row.original.Status].icon}`}</Icon>
                                        {` ${statusProps[row.original.Status].text}`}
                                    </React.Fragment>
                                )
                            },
                            {
                                Header: "Duration",
                                accessor: "Duration",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    `${(row.original.Duration || 0).toFixed(2)} Hrs`
                                )
                            },
                            {
                                Header: "Scheduled Date",
                                accessor: "ScheduledDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.ScheduledDate &&
                                    new Date(row.original.ScheduledDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Scheduled Time",
                                accessor: "ScheduledTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.ScheduledTime &&
                                    moment(row.original.ScheduledTime).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "Notified Date",
                                accessor: "NotifiedDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.NotifiedDate &&
                                    new Date(row.original.NotifiedDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Notified Time",
                                accessor: "NotifiedTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.NotifiedTime &&
                                    moment(row.original.NotifiedTime).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "Accepted Date",
                                accessor: "AcceptedDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.AcceptedDate &&
                                    new Date(row.original.AcceptedDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Accepted Time",
                                accessor: "AcceptedTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.AcceptedTime &&
                                    moment(row.original.AcceptedTime).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "Rejected Date",
                                accessor: "RejectedDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.RejectedDate &&
                                    new Date(row.original.RejectedDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Rejected Time",
                                accessor: "RejectedTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.RejectedTime &&
                                    moment(row.original.RejectedTime).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "En-Route Date",
                                accessor: "EnRouteDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.EnRouteDate &&
                                    new Date(row.original.EnRouteDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "En-Route Time",
                                accessor: "EnRouteTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.EnRouteTime &&
                                    moment(moment(row.original.EnRouteTime)).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "Arrived Date",
                                accessor: "ArrivedDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.ArrivedDate &&
                                    new Date(row.original.ArrivedDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Arrived Time",
                                accessor: "ArrivedTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.ArrivedTime &&
                                    moment(moment(row.original.ArrivedTime)).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "In Progress Date",
                                accessor: "InProgressDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.InProgressDate &&
                                    new Date(row.original.InProgressDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "In Progress Time",
                                accessor: "InProgressTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.InProgressTime &&
                                    moment(moment(row.original.InProgressTime)).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "On Hold Date",
                                accessor: "OnHoldDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.OnHoldDate &&
                                    new Date(row.original.OnHoldDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "On Hold Time",
                                accessor: "OnHoldTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.OnHoldTime &&
                                    moment(moment(row.original.OnHoldTime)).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "Completed Date",
                                accessor: "CompletedDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.CompletedDate &&
                                    new Date(row.original.CompletedDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Completed Time",
                                accessor: "CompletedTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.CompletedTime &&
                                    moment(moment(row.original.CompletedTime)).local().toDate().toLocaleTimeString('en-US')
                                )
                            },
                        ]}
                    // noDataText="No Trips found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTrips: Actions.getTrips,
        getUserData: Actions.getUserData,
        toggleInSelectedTrips: Actions.toggleInSelectedTrips,
        selectAllTrips: Actions.selectAllTrips,
        deSelectAllTrips: Actions.deSelectAllTrips,
        openNewTripDialog: Actions.openNewTripDialog,
        openEditTripDialog: Actions.openEditTripDialog,
        removeTrips: Actions.removeTrips,
        removeTrip: Actions.removeTrip,
        toggleStarredTrip: Actions.toggleStarredTrip,
        toggleStarredTrips: Actions.toggleStarredTrips,
        setTripsStarred: Actions.setTripsStarred,
        getWOData: DispatchActions.getWOData,
        getTripData: DispatchActions.getTripData,
        setTrips: DispatchActions.setTrips,
        setBoardDate: DispatchActions.setBoardDate,
        loadingTrips: DispatchActions.loadingTrips,
        updateTrips: DispatchActions.updateTrips,
        setSchedule: DispatchActions.setSchedule,
        openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
        setTripsUnstarred: Actions.setTripsUnstarred
    }, dispatch);
}

function mapStateToProps({ tripsApp, spReducers, dispatchBoardApp }) {
    return {
        trips: tripsApp.trips.entities,
        selectedTripIds: tripsApp.trips.selectedTripIds,
        searchText: tripsApp.trips.searchText,
        user: tripsApp.user,
        workOrderData: spReducers.workOrders,
        date: dispatchBoardApp.dispatchBoard.date,
        loading: dispatchBoardApp.dispatchBoard.loading,
        technicians: spReducers.technicians,
        trips: dispatchBoardApp.dispatchBoard.trips,
        schedule: dispatchBoardApp.dispatchBoard.schedule,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TripsList)));
