import React from 'react';
import { withStyles, AppBar, Typography, Avatar, Hidden } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { FuseNavigation, FuseLayouts } from '@fuse';
import Media from 'react-media';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from '@lodash';
import getProfileImage from './functions/getProfileImageUrl';

const styles = theme => ({
    root: {
        '& .user': {
            '& .username, & .email': {
                transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                    easing: theme.transitions.easing.easeInOut
                })
            }
        },
        paddingBottom: 36,
        // paddingTop: 8,
    },
    avatar: {
        width: 60,
        height: 60,
        position: 'absolute',
        top: 100,
        padding: 6,
        background: theme.palette.background.default,
        boxSizing: 'content-box',
        left: '50%',
        transform: 'translateX(-50%)',
        '& > img': {
            borderRadius: '50%'
        }
    }
});


function MainNavbar({ classes, navigation, layoutStyle, user, Co, companies }) {
    function UserHeader() {
        const avatar = 'assets/images/avatars/profile.jpg';
        const co = _.find(companies, { Co });
        return (
            <Hidden lgUp>
                <AppBar
                    position="static"
                    color="primary"
                    elevation={0}
                    className="user relative flex flex-col items-center justify-center pt-32 pb-64 mb-40 z-0"
                >
                    <Typography className="username text-16 whitespace-no-wrap min-h-16" color="inherit">{`${user.FirstName} ${user.LastName}`}</Typography>
                    <Typography className="email text-13 mt-0 opacity-50 whitespace-no-wrap min-h-12" color="inherit">{user.UserName}</Typography>
                    <Avatar
                        className={classNames(classes.avatar, "avatar")}
                        alt="profile photo"
                        src={(co && co.Data && co.Data.Co && co.Data.Co.ImgUrl) ? co.Data.Co.ImgUrl : (user.Data && user.Data.Avatar && user.Data.Avatar.length > 0 ? getProfileImage(`ID=${user.Data.Avatar}`) : avatar)}
                    />
                </AppBar>
            </Hidden>
        );
    }

    const navigationLayout = FuseLayouts[layoutStyle].type;
    return (
        <div className={classes.root}>
            {navigationLayout === 'vertical' ? (
                <Media query="(max-height: 768px)">
                    {matches =>
                        <React.Fragment>
                            <UserHeader />
                            <FuseNavigation navigation={[...navigation]} layout={navigationLayout} />
                        </React.Fragment>
                    }
                </Media>
            ) : (
                <React.Fragment>
                    <Hidden lgUp>
                        <UserHeader />
                    </Hidden>
                    <FuseNavigation navigation={[...navigation]} layout={navigationLayout} />
                </React.Fragment>
            )
            }

        </div >
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ fuse, auth, spReducers }) {
    return {
        navigation: fuse.navigation,
        layoutStyle: fuse.settings.current.layout.style,
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar)));
