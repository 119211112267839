import axios from 'axios/index';

export const SET_FOLDER_DATA = '[SPCONNECTION] SET FOLDER DATA';
export const GET_FOLDER_DATA = '[SPCONNECTION] GET FOLDER DATA';
export const SUBSCRIBE_FOLDER_LIST = '[SPCONNECTION] SUBSCRIBE FOLDER LIST';

export function getInitialFolderData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CustomFolder`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getFolderData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CustomFolder`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_FOLDER_DATA,
                payload: response.data
            })
        });
    }
}

export function setFolderData(data) {
    return {
        type: SET_FOLDER_DATA,
        payload: data
    };
}