import ServicesApp from 'main/content/apps/services/ServicesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const ServicesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/services/:id',
            component: ServicesApp
        },
        {
            path     : '/apps/services',
            component: () => <Redirect to="/apps/services/all"/>
        }
    ]
};
