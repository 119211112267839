import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedCallGatherKeyIds: [],
    routeParams: {},
    CallGatherKeysDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const CallGatherKeysReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CALL_GATHER_KEYS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_CALL_GATHER_KEYS:
            {

                const CallGatherKeyId = action.CallGatherKeyId;

                let selectedCallGatherKeyIds = [...state.selectedCallGatherKeyIds];

                if (selectedCallGatherKeyIds.find(id => id === CallGatherKeyId) !== undefined) {
                    selectedCallGatherKeyIds = selectedCallGatherKeyIds.filter(id => id !== CallGatherKeyId);
                }
                else {
                    selectedCallGatherKeyIds = [...selectedCallGatherKeyIds, CallGatherKeyId];
                }

                return {
                    ...state,
                    selectedCallGatherKeyIds: selectedCallGatherKeyIds
                };
            }
        case Actions.SELECT_ALL_CALL_GATHER_KEYS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedCallGatherKeyIds = arr.map(CallGatherKeys => CallGatherKeys.id);

                return {
                    ...state,
                    selectedCallGatherKeyIds: selectedCallGatherKeyIds
                };
            }
        case Actions.DESELECT_ALL_CALL_GATHER_KEYS:
            {
                return {
                    ...state,
                    selectedCallGatherKeyIds: []
                };
            }
        case Actions.OPEN_NEW_CALL_GATHER_KEY_DIALOG:
            {
                const { Co, ID, Data } = action.phone;
                const { Keys } = Data;
                return {
                    ...state,
                    CallGatherKeysDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            Co,
                            Gather: ID,
                            Keys,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_CALL_GATHER_KEY_DIALOG:
            {
                return {
                    ...state,
                    CallGatherKeysDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CALL_GATHER_KEY_DIALOG:
            {
                return {
                    ...state,
                    CallGatherKeysDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_CALL_GATHER_KEY_DIALOG:
                {
                    return {
                        ...state,
                        CallGatherKeysDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_CALL_GATHER_KEY_DIALOG:
            {
                return {
                    ...state,
                    CallGatherKeysDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default CallGatherKeysReducer;
