import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Divider, Icon, Typography, Tab, Tabs } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseAnimate, FuseUtils } from '@fuse';
import CatalogDialogSearch from './CatalogDialogSearch';
import CatalogOptionBoardCard from './CatalogOptionBoardCard';
import CatalogOptionBoardOptionCard from './CatalogOptionBoardOptionCard';
import CatalogAssemblyCard from './CatalogAssemblyCard';
import { guid } from 'store/actions';
import _ from '@lodash';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ width: '100%', padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 108px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

class CatalogOptionBoardList extends Component {

    state = {
        Co: null,
        BusinessUnit: null,
        Division: null,
        Data: {
            OptionBoardCategories: [],
            OptionBoards: [],
        },
        category: null,
        optionBoard: null,
        selectedOption: null,
        value: 0,
    }

    componentDidMount() {
        const { catalog, category, optionBoard, selectedOption } = this.props;
        const { Co, BusinessUnit, Division } = this.props.catalog;
        this.props.getAllOptionBoards({ Co, BusinessUnit, Division });
        this.setState({ ...catalog, category, optionBoard, selectedOption, value: 0 });
    }

    componentDidUpdate = (prevProps) => {
        const { catalog, category, optionBoard, selectedOption } = this.props;
        if (!_.isEqual(catalog, prevProps.catalog) || !_.isEqual(category, prevProps.category) || !_.isEqual(optionBoard, prevProps.optionBoard) || !_.isEqual(selectedOption, prevProps.selectedOption)) {
            this.setState({ ...this.state, ...catalog, category, optionBoard, selectedOption });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    addOptionBoard = () => {
        const { Co, BusinessUnit, Division, category } = this.state;
        const { catalog, estimate } = this.props;
        const { Category } = category || {};
        const newOptionBoard = {
            ID: null,
            Co,
            BusinessUnit,
            Division,
            StandardYN: 'Y',
            Data: {
                ErrMsg: null,
                Options: [],
            },
        };
        this.props.openNewOptionBoardDialog(newOptionBoard, catalog, estimate);
    }

    selectOption = (opt, est) => {
        if (this.props.onSelectedOption) {
            this.props.onSelectedOption(opt, est);
        }
    }

    selectOptionBoard = (opt, est) => {
        if (this.props.onSelectedOptionBoard) {
            this.props.onSelectedOptionBoard(opt, est);
        }
    }

    render() {
        const { searchText, securables, dialog, onAddComponent, onCategoryChange, catalog, estimate, enterFrom, allOptionBoards, user } = this.props;
        const { category, Data, value } = this.state;
        const { OptionBoards } = Data;
        const optionBoardData = _.filter([...(value < 2 ? OptionBoards : allOptionBoards)], (o) => ((value === 0 ? (o.EnteredBy === user.UserName) : value === 1 ? (o.SharedYN === 'Y') : true)));
        const categories = _.filter(catalog.Data.Categories, (o) => (!o.ParentCategory && _.filter(optionBoardData, (d) => d.Category === o.Category).length > 0));
        const accessLevel = _.find(securables, { Securable: "catalog" });
        const { Co, BusinessUnit, Division } = estimate || {};

        const optionBoard = this.state.optionBoard ? _.find(optionBoardData, { Co, BusinessUnit, Division, OptionBoard: this.state.optionBoard }) : null;
        return (
            <div className="w-full">
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" onClick={category ? () => this.setState({ category: _.find(categories, (o) => o.Category === category.ParentCategory) }) : undefined} className="text-32 mr-8 cursor-pointer">{!category ? "apps" : "keyboard_arrow_left"}</Icon>{!category ? "Standard Option Boards" : category.Name}{dialog && <div className="pin-r absolute"><CatalogDialogSearch catalog={this.props.catalog} /></div>}</Typography>
                <Divider />
                <div className="w-full flex flex-wrap">

                    <Tabs
                        value={value}
                        className="w-full"
                        onChange={(e, value) => this.setState({ value })}
                        variant="fullWidth"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab disableRipple label="My Boards" />
                        <Tab disableRipple label="Shared Boards" />
                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <Tab disableRipple label="All Boards" />
                        }
                    </Tabs>

                    <TabContainer padding="24px 24px 64px 24px">
                        <div className="w-full flex flex-wrap">
                            {(!category || category.Type === 'C') &&
                                [..._.filter(categories, (o) => o.ParentCategory === (category ? category.Category : undefined))].map((value, index) => {
                                    return (
                                        <CatalogAssemblyCard optionBoards={optionBoardData} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} catalog={this.props.catalog} dialog={dialog} onClick={() => this.setState({ ...this.state, category: value })} onDelete={() => this.removeCategory(value)} value={value} key={value.ID} />
                                    );
                                })
                            }
                            {_.filter(optionBoardData, (o) => (!category ? !o.Category : o.Category === category.Category)).map((optionBoard) => {
                                if (Boolean(optionBoard) && (optionBoard.ActiveYN !== 'N')) {
                                    return (
                                        <CatalogOptionBoardCard catalog={this.props.catalog} dialog={dialog} estimate={estimate} onAddComponent={onAddComponent || undefined} altClick={estimate ? (opt) => this.selectOptionBoard(opt, estimate) : undefined} onDelete={() => this.removeOptionBoard(optionBoard)} value={optionBoard} key={optionBoard.ID} />
                                    );
                                }
                            })
                            }
                            {(accessLevel && accessLevel.AccessLevel !== "R") && catalog && !optionBoard && !estimate &&
                                <Typography onClick={this.addOptionBoard} variant="subtitle2" style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>}New Option Board</Typography>
                            }
                        </div>
                    </TabContainer>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setOptionBoards: Actions.setOptionBoards,
        // setCategory: Actions.setCategory,
        getAllOptionBoards: Actions.getAllOptionBoards,
        openNewOptionBoardDialog: Actions.openNewOptionBoardDialog,
        openEditOptionBoardDialog: Actions.openEditOptionBoardDialog,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, catalogApp }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        classList: spReducers.classes.classes,
        allOptionBoards: catalogApp.allOptionBoards,
        // categories: spReducers.catalog.categories,
        // optionBoards: spReducers.catalog.optionBoards,
        // category: catalogApp.category,
        searchText: catalogApp.searchText,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogOptionBoardList)));
