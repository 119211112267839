import axios from 'axios/index';
import history from 'history.js';
// import TemplateModel from 'main/content/apps/email-templates/model/TemplateModel';

export const GET_TEMPLATE_TAGS = '[EMAIL TEMPLATE APP] GET TEMPLATE TAGS';
export const ADD_TEMPLATE_TAG = '[EMAIL TEMPLATE APP] ADD TEMPLATE TAG';
export const UPDATE_TEMPLATE_TAG = '[EMAIL TEMPLATE APP] UPDATE TEMPLATE TAG';
export const REMOVE_TEMPLATE_TAG = '[EMAIL TEMPLATE APP] REMOVE TEMPLATE TAG';
export const GET_TEMPLATES = '[EMAIL TEMPLATE APP] GET TEMPLATES';
export const RESET_TEMPLATES = '[EMAIL TEMPLATE APP] RESET TEMPLATES';
export const NEW_TEMPLATE = '[EMAIL TEMPLATE APP] NEW TEMPLATE';

export function getTemplates(Co)
{
    const request = axios.get(`${window["apiLocation"]}/api/HTMLTemplate?Co=${Co}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_TEMPLATES,
                payload: response.data
            })
        );
}

export function getTemplateTags(Co)
{
    const request = axios.get(`${window["apiLocation"]}/api/HTMLTemplateTag?Co=${Co}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_TEMPLATE_TAGS,
                payload: response.data
            })
        );
}

export function newTemplateTag(newTag)
{
    const request = axios.post(`${window["apiLocation"]}/api/HTMLTemplateTag`,
        newTag
    );

    return (dispatch) =>
        request.then((response) => {
                const tag = response.data;
                return dispatch({
                    type: ADD_TEMPLATE_TAG,
                    tag
                })
            }
        );
}

export function updateTemplateTag(templateTag)
{
    const request = axios.put(`${window["apiLocation"]}/api/HTMLTemplateTag?Co=${templateTag.Co}&Tag=${templateTag.ID}`,
        templateTag
    );

    return (dispatch) =>
        request.then((response) => {
                const tag = response.data;
                return dispatch({
                    type: UPDATE_TEMPLATE_TAG,
                    tag
                })
            }
        );
}

export function removeTemplateTag(tag)
{
    const request = axios.put(`${window["apiLocation"]}/api/HTMLTemplateTag?Co=${tag.Co}&Tag=${tag.ID}`);

    return (dispatch) =>
        request.then((response) => {
                return dispatch({
                    type: REMOVE_TEMPLATE_TAG,
                    tag
                })
            }
        );
}

export function resetTemplates()
{
    return {
        type: RESET_TEMPLATES
    }
}

export function newTemplate(template)
{
    const request = axios.post(`${window["apiLocation"]}/api/HTMLTemplate`,
        template
    );

    return (dispatch) =>
        request.then((response) => {
                const template = response.data;
                history.push({
                    pathname: `/apps/email-templates/templates/${template.Co}/${template.ID}`
                });
                return dispatch({
                    type: NEW_TEMPLATE,
                    template
                })
            }
        );
}
