import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedRateTemplateIds: [],
    routeParams: {},
    rateTemplateDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    rateTemplateLaborDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    rateTemplateMaterialDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const rateTemplatesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_RATE_TEMPLATES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_RATE_TEMPLATES:
            {

                const rateTemplateId = action.rateTemplateId;

                let selectedRateTemplateIds = [...state.selectedRateTemplateIds];

                if (selectedRateTemplateIds.find(id => id === rateTemplateId) !== undefined) {
                    selectedRateTemplateIds = selectedRateTemplateIds.filter(id => id !== rateTemplateId);
                }
                else {
                    selectedRateTemplateIds = [...selectedRateTemplateIds, rateTemplateId];
                }

                return {
                    ...state,
                    selectedRateTemplateIds: selectedRateTemplateIds
                };
            }
        case Actions.SELECT_ALL_RATE_TEMPLATES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedRateTemplateIds = arr.map(rateTemplates => rateTemplates.id);

                return {
                    ...state,
                    selectedRateTemplateIds: selectedRateTemplateIds
                };
            }
        case Actions.DESELECT_ALL_RATE_TEMPLATES:
            {
                return {
                    ...state,
                    selectedRateTemplateIds: []
                };
            }
        case Actions.OPEN_NEW_RATE_TEMPLATE_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_RATE_TEMPLATE_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_RATE_TEMPLATE_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_RATE_TEMPLATE_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        /*----------------------------------------------------------------------------------------------------------------------*/
        case Actions.OPEN_NEW_RATE_TEMPLATE_LABOR_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateLaborDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_NEW_RATE_TEMPLATE_LABOR_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateLaborDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_RATE_TEMPLATE_LABOR_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateLaborDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_RATE_TEMPLATE_LABOR_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateLaborDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        /*----------------------------------------------------------------------------------------------------------------------*/
        case Actions.OPEN_NEW_RATE_TEMPLATE_MATERIAL_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateMaterialDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_NEW_RATE_TEMPLATE_MATERIAL_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateMaterialDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_RATE_TEMPLATE_MATERIAL_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateMaterialDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_RATE_TEMPLATE_MATERIAL_DIALOG:
            {
                return {
                    ...state,
                    rateTemplateMaterialDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default rateTemplatesReducer;
