import axios from 'axios/index';

export const SET_FLEET_VEHICLE_CATEGORY_DATA = '[SPCONNECTION] SET FLEET VEHICLE CATEGORY DATA';
export const GET_FLEET_VEHICLE_CATEGORY_DATA = '[SPCONNECTION] GET FLEET VEHICLE CATEGORY DATA';
export const SUBSCRIBE_FLEET_VEHICLE_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE FLEET VEHICLE CATEGORY LIST';

export function getInitialFleetVehicleCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/FleetVehicleCategory`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getFleetVehicleCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/FleetVehicleCategory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_FLEET_VEHICLE_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setFleetVehicleCategoryData(data) {
    return {
        type: SET_FLEET_VEHICLE_CATEGORY_DATA,
        payload: data
    };
}