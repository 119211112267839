import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Badge,
  Button,
  Slider,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Divider,
  Icon,
  IconButton,
  Typography,
  Tooltip,
  Toolbar,
  AppBar,
  Avatar,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Radio,
  TableRow,
  RadioGroup,
  Box,
  Collapse,
  Slide,
} from '@material-ui/core'
import {
  FormatLineSpacing,
  Assignment,
  AttachFile,
  Business,
  BusinessCenter,
  ChevronRight,
  ChevronLeft,
  Dashboard,
  History,
  LocationCity,
  List as ListIcon,
  LocalShipping,
  LocationOn,
  Menu as MenuIcon,
  Payment,
  Receipt,
  ViewList,
} from '@material-ui/icons'
import { withStyles, styled } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import { showMessage } from 'store/actions'
import * as FileActions from '../file-manager/store/actions'
import { connect } from 'react-redux'
import DepartmentsList from 'main/content/apps/departments/DepartmentsList'
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector'
import FileList from 'main/content/apps/file-manager/FileList'
import ImageDialog from '../catalog/ImageDialog'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import Media from 'react-media'
import _ from '@lodash'
import InvoicePlaceholder from './InvoicePlaceholder'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import { openPreloadDivisionDialog } from './store/actions/divisions.actions'

const StyledFormControlLabel = styled(props => <FormControlLabel {...props} />)(
  ({ theme }) => ({
    '& .Mui-checked': {
      color: theme.palette.primary.main,
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    },
  }),
)

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const styles = theme => ({
  root: {},
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: 73,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
})

const VerticalSlider = styled(Slider)({
  '& .MuiSlider-track': {
    border: 'none',
    color: '#4dbce9',
    width: 3,
  },
  '& .MuiSlider-rail': {
    color: 'gray',
    opacity: 0.3,
    width: 3,
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    marginTop: -8,
    marginLeft: -9,
    backgroundColor: '#4dbce9',
    maskImage: `url("assets/images/invoices/triangle-left.svg")`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
  },
})

const HorizontalSlider = styled(Slider)({
  height: 3,
  marginBottom: 0,
  '& .MuiSlider-track': {
    border: 'none',
    color: '#4dbce9',
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: 'gray',
    opacity: 0.3,
    height: 3,
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    marginTop: -4,
    marginLeft: -10,
    backgroundColor: '#4dbce9',
    maskImage: `url("assets/images/invoices/triangle-bottom.svg")`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
  },
})

const newDivisionState = {
  ID: '',
  Co: null,
  BusinessUnit: '',
  Division: '',
  Description: '',
  LaborCostGL: '',
  LaborRevenueGL: '',
  MaterialCostGL: '',
  MaterialRevenueGL: '',
  EquipmentCostGL: '',
  EquipmentRevenueGL: '',
  MiscCostGL: '',
  MiscRevenueGL: '',
  DefaultRevenueGL: '',
  DeferredRevenueGL: '',
  Notes: '',
  value: 0,
  Data: {
    Departments: [],
    Attachments: [],
  },
  imgDialog: false,
  InvoiceHeaderImg: null,
  InvoiceFooterImg: null,
  InvoiceDisclaimer: null,
  QBOCompany: null,
  DefaultQBOServiceItem: null,
  DefaultQBONonInventoryItem: null,
  DefaultQBODeferredRevenueItem: null,
  DefaultQBOEquipmentRentalItem: null,
  InvoicePrefix: null,
  POPrefix: null,
  ConfirmationEmailTemplate: null,
  InvoiceEmailTemplate: null,
  EstimateEmailTemplate: null,
  VPCompany: null,
  VPRecType: null,
  defaultSpacing: true,
  InvoiceMarginTop: 5,
  InvoiceMarginBottom: 5,
  InvoiceMarginLeft: 5,
  InvoiceMarginRight: 5,
  CommissionLaborType: null,
  ReferralBonusLaborType: null,
  dialogHeight: null,
  minHeight: null,
  location: null,
}

const DivisionMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class DivisionsDialog extends Component {
  state = { ..._.cloneDeepWith(newDivisionState) }

  ref = React.createRef()

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    newDivisionState.Co = this.props.Co
    if (
      !prevProps.divisionDialog.props.open &&
      this.props.divisionDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.divisionDialog.type === 'edit' &&
        this.props.divisionDialog.data &&
        !_.isEqual(this.props.divisionDialog.data, prevState)
      ) {
        const data = this.props.divisionDialog.data
        const {
          InvoiceMarginTop,
          InvoiceMarginBottom,
          InvoiceMarginLeft,
          InvoiceMarginRight,
        } = data
        this.setState({
          ..._.cloneDeepWith(newDivisionState),
          ...this.props.divisionDialog.data,
          defaultSpacing:
            (!InvoiceMarginTop &&
              !InvoiceMarginBottom &&
              !InvoiceMarginLeft &&
              !InvoiceMarginRight) ||
            (InvoiceMarginTop === newDivisionState.InvoiceMarginTop &&
              InvoiceMarginBottom === newDivisionState.InvoiceMarginBottom &&
              InvoiceMarginLeft === newDivisionState.InvoiceMarginLeft &&
              InvoiceMarginRight === newDivisionState.InvoiceMarginRight),
        })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.divisionDialog.type === 'new' &&
        !_.isEqual(newDivisionState, prevState)
      ) {
        this.setState({ ..._.cloneDeepWith(newDivisionState) })
      }

      // if (this.props.divisionDialog.type === 'customer' &&
      //     this.props.divisionDialog.data &&
      //     !_.isEqual(this.props.divisionDialog.data, prevState)) {
      //     const data = this.props.divisionDialog.data;
      //     this.props.divisionDialog.type = 'new';
      //     this.setState({ ...this.props.divisionDialog.data });
      // }
    }
    //window["log"]('STATE COMPARISON', this.props.divisionDialog, prevProps, prevState);
    if (
      prevProps.divisionDialog.props.open &&
      this.props.divisionDialog.props.open
    ) {
      if (
        this.props.divisionDialog.type === 'data' &&
        this.props.divisionDialog.data &&
        !_.isEqual(this.props.divisionDialog.data, prevState)
      ) {
        const data = this.props.divisionDialog.data
        this.props.divisionDialog.type = 'edit'
        this.setState({ ...this.props.divisionDialog.data })
      }
    }
    if (
      prevProps.divisionDialog.props.open &&
      this.props.divisionDialog.props.open
    ) {
      if (
        this.props.divisionDialog.type === 'add' &&
        this.props.divisionDialog.data &&
        !_.isEqual(this.props.divisionDialog.data, prevState)
      ) {
        const data = this.props.divisionDialog.data
        this.props.showMessage({
          message: `Division ${this.props.divisionDialog.data.Division} has been successfully created.`,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        })
        this.props.divisionDialog.type = 'edit'
        this.setState({ ...this.props.divisionDialog.data })
      }
    }
    if (
      prevProps.divisionDialog.props.open &&
      this.props.divisionDialog.props.open
    ) {
      if (
        this.props.divisionDialog.type === 'update' &&
        this.props.divisionDialog.data &&
        !_.isEqual(this.props.divisionDialog.data, prevState)
      ) {
        const data = this.props.divisionDialog.data
        this.props.showMessage({
          message: `Division ${this.props.divisionDialog.data.Division} has been successfully updated.`,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        })
        this.props.divisionDialog.type = 'edit'
        this.setState({ ...this.props.divisionDialog.data })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.divisionDialog.type === 'edit'
      ? this.props.closeEditDivisionDialog()
      : this.props.closeNewDivisionDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.divisionDialog.type === 'edit'
      ? this.props.closeEditDivisionDialog()
      : this.props.closeNewDivisionDialog()
  }

  canBeSubmitted() {
    const { Description } = this.state
    return Description.length > 0
  }

  openHeaderImgDialog = () => {
    this.setState({
      ...this.state,
      imgDialog: true,
      onImgChange: img =>
        this.setState({ ...this.state, InvoiceHeaderImg: img }),
    })
  }

  openFooterImgDialog = () => {
    this.setState({
      ...this.state,
      imgDialog: true,
      onImgChange: img =>
        this.setState({ ...this.state, InvoiceFooterImg: img }),
    })
  }

  openChecklistHeaderImgDialog = () => {
    this.setState({
      ...this.state,
      imgDialog: true,
      onImgChange: img =>
        this.setState({ ...this.state, ChecklistHeaderImg: img }),
    })
  }

  openChecklistFooterImgDialog = () => {
    this.setState({
      ...this.state,
      imgDialog: true,
      onImgChange: img =>
        this.setState({ ...this.state, ChecklistFooterImg: img }),
    })
  }

  handleSliderChange = (name, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  handledefaultSpacingChange = e => {
    this.setState({
      defaultSpacing: e.target.value === 'default',
      InvoiceMarginTop: 5,
      InvoiceMarginBottom: 5,
      InvoiceMarginLeft: 5,
      InvoiceMarginRight: 5,
    })
  }

  handleVerticalChange = (event, newValue, activeThumb) => {
    const height = 330
    const minDistance = 275

    if (
      Math.abs(newValue[1] - newValue[0]) < minDistance ||
      !Array.isArray(newValue)
    ) {
      return
    }

    this.setState(prevState => ({
      ...prevState,
      InvoiceMarginBottom: newValue[0],
      InvoiceMarginTop: height - newValue[1],
    }))
  }

  handleHorizontalChange = (event, newValue) => {
    const width = 255
    const minDistance = 180

    if (
      Math.abs(newValue[1] - newValue[0]) < minDistance ||
      !Array.isArray(newValue)
    )
      return

    this.setState(prevState => ({
      ...prevState,
      InvoiceMarginLeft: newValue[0],
      InvoiceMarginRight: width - newValue[1],
    }))
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { value } = this.state

    return (
      <>
        <IconButton
          style={{
            position: 'absolute',
            right: 54,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 10 : 4 /*if fullScreen*/,
            color: 'white',
          }}
          onClick={e => {
            closeFxn(e)
          }}
          className='dialog-header-icon' /*if fullScreen*/
        >
          <Icon>close</Icon>
        </IconButton>
        <Tabs
          value={value}
          onChange={this.handleTabChange}
          variant='scrollable'
          scrollButtons='on'
        >
          <Tab disableRipple icon={<LocationCity />} label='Info' />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Departments
                    ? this.state.Data.Departments.length
                    : 0
                }
                color='error'
              >
                <Business />
              </Badge>
            }
            label='Departments'
          />
          <Tab
            disableRipple
            icon={<Icon>tune</Icon>}
            label='Invoice Settings'
          />
          <Tab
            disableRipple
            icon={<Icon>assignment_turned_in</Icon>}
            label='Checklist Settings'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Attachments
                    ? this.state.Data.Attachments.length
                    : 0
                }
                color='error'
              >
                <AttachFile />
              </Badge>
            }
            label='Attachments'
          />
          <Tab disableRipple icon={<Dashboard />} label='Dashboard' />
        </Tabs>
      </>
    )
  }

  ToolbarContents = () => {
    const { divisionDialog } = this.props

    return (
      <Typography variant='subtitle1' color='inherit'>
        {['new', 'customer'].indexOf(divisionDialog.type) > -1
          ? 'New Division'
          : `Division ${this.state.Division} Overview`}
      </Typography>
    )
  }

  render() {
    const {
      classes,
      securables,
      theme,
      divisionDialog,
      addDivision,
      updateDivision,
      vpSettings,
      laborTypes,
      removeDivision,
      businessUnits,
      glAccounts,
      taxCodes,
      rateTemplates,
      payTerms,
      qboCompany,
      qboMaterials,
      qboServices,
      qboNonInventory,
      templates,
    } = this.props
    const { Co, BusinessUnit, Division, value, imgDialog, onImgChange } =
      this.state
    const accessLevel = _.find(securables, { Securable: 'divisions' })

    const dialogProps = {
      ...divisionDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    return (
      <Media query='(min-width: 512px) and (min-height: 512px)'>
        {matches => (
          <DraggableDialog
            SlideUp={SlideUp}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this,
            )}
            header={`Division ${this.state.Division}`}
            type='Division'
            picRef={this.ref.current}
            icon='location_city'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadDivisionDialog}
            description={this.state.Description}
            storeDialog={divisionDialog}
            TypeState={this.state.Division}
            screenOverlay={this.state.screenOverlay}
            //posSet={this.state.posSet}
            //initialWidthSet={initialWidthSet}
            //minHeight={this.state.minHeight}
            //location={this.state.location}
            //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
            //dialogHeight={this.state.dialogHeight}
          >
            {value === 0 && (
              <TabContainer refProp={this.ref}>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>location_city</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Business Unit'
                    id='businessUnit'
                    select
                    name='BusinessUnit'
                    value={this.state.BusinessUnit}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                    placeholder='Select Business Unit'
                    disabled={divisionDialog.type === 'new' ? false : true}
                  >
                    {businessUnits && businessUnits.length > 0
                      ? businessUnits.map((value, index) => (
                          <MenuItem key={index} value={value.BusinessUnit}>
                            {value.Description}
                          </MenuItem>
                        ))
                      : ''}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Division'
                    id='division'
                    name='Division'
                    value={this.state.Division}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                    disabled={divisionDialog.type === 'new' ? false : true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Description'
                    id='description'
                    name='Description'
                    value={this.state.Description}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                </div>
                {qboCompany && (
                  <React.Fragment>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>build</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Default QBO Service'
                        options={qboServices}
                        menuItemComponent={value => (
                          <MenuItem value={value.Id}>
                            {`${value.Name}`}
                          </MenuItem>
                        )}
                        portal={true}
                        value={this.state.DefaultQBOServiceItem || ''}
                        onSelect={option => {
                          if (divisionDialog.type !== 'new') {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBOServiceItem: option.Id,
                            })
                          } else {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBOServiceItem: option.Id,
                            })
                          }
                        }}
                        // required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Default QBO Purchase Item'
                        options={qboNonInventory}
                        menuItemComponent={value => (
                          <MenuItem value={value.Id}>
                            {`${value.Name}`}
                          </MenuItem>
                        )}
                        portal={true}
                        value={this.state.DefaultQBONonInventoryItem || ''}
                        onSelect={option => {
                          if (divisionDialog.type !== 'new') {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBONonInventoryItem: option.Id,
                            })
                          } else {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBONonInventoryItem: option.Id,
                            })
                          }
                        }}
                        // required
                      />
                    </div>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>settings</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Default QBO Equipment Rental Item'
                        options={qboNonInventory}
                        menuItemComponent={value => (
                          <MenuItem value={value.Id}>
                            {`${value.Name}`}
                          </MenuItem>
                        )}
                        portal={true}
                        value={this.state.DefaultQBOEquipmentRentalItem || ''}
                        onSelect={option => {
                          if (divisionDialog.type !== 'new') {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBOEquipmentRentalItem: option.Id,
                            })
                          } else {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBOEquipmentRentalItem: option.Id,
                            })
                          }
                        }}
                        // required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Default QBO Deferred Revenue Item'
                        options={qboNonInventory}
                        menuItemComponent={value => (
                          <MenuItem value={value.Id}>
                            {`${value.Name}`}
                          </MenuItem>
                        )}
                        portal={true}
                        value={this.state.DefaultQBODeferredRevenueItem || ''}
                        onSelect={option => {
                          if (divisionDialog.type !== 'new') {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBODeferredRevenueItem: option.Id,
                            })
                          } else {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              DefaultQBODeferredRevenueItem: option.Id,
                            })
                          }
                        }}
                        // required
                      />
                    </div>
                  </React.Fragment>
                )}
                {!qboCompany && (
                  <React.Fragment>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>build</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Labor Cost GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.LaborCostGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            LaborCostGL: option.GLAccount,
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Labor Revenue GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.LaborRevenueGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            LaborRevenueGL: option.GLAccount,
                          })
                        }
                        required
                      />
                    </div>

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>confirmation_number</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Material Cost GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.MaterialCostGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            MaterialCostGL: option.GLAccount,
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Material Revenue GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.MaterialRevenueGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            MaterialRevenueGL: option.GLAccount,
                          })
                        }
                        required
                      />
                    </div>

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>settings</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Equipment Cost GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.EquipmentCostGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            EquipmentCostGL: option.GLAccount,
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Equipment Revenue GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.EquipmentRevenueGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            EquipmentRevenueGL: option.GLAccount,
                          })
                        }
                        required
                      />
                    </div>

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>grain</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Misc Cost GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.MiscCostGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            MiscCostGL: option.GLAccount,
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Misc Revenue GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.MiscRevenueGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            MiscRevenueGL: option.GLAccount,
                          })
                        }
                        required
                      />
                    </div>

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pt-20'>
                        <Icon color='action'>update</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Deferred Revenue GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.DeferredRevenueGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            DeferredRevenueGL: option.GLAccount,
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Default Revenue GL'
                        options={
                          glAccounts
                            ? [
                                { GLAccount: null, Description: 'Default' },
                                ...glAccounts,
                              ]
                            : [{ GLAccount: null, Description: 'Default' }]
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.GLAccount}>
                              {value.GLAccount} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.DefaultRevenueGL}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            DefaultRevenueGL: option.GLAccount,
                          })
                        }
                        required
                      />
                    </div>
                  </React.Fragment>
                )}

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'>
                    <Icon color='action'>monetization_on</Icon>
                  </div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Default Tax Code'
                    options={taxCodes}
                    menuItemComponent={value => {
                      return (
                        value.ActiveYN === 'Y' && (
                          <MenuItem value={value.TaxCode}>
                            {`${value.Description} - ${value.TaxCode}`}
                          </MenuItem>
                        )
                      )
                    }}
                    portal={true}
                    value={this.state.DefaultTaxCode}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        DefaultTaxCode: option.TaxCode,
                      })
                    }
                    required
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Default Rate Template'
                    options={rateTemplates}
                    menuItemComponent={value => {
                      if (
                        value.BusinessUnit === this.state.BusinessUnit &&
                        value.Division === this.state.Division
                      ) {
                        return (
                          <MenuItem value={value.RateTemplate}>
                            {`${value.Description}`}
                          </MenuItem>
                        )
                      }
                    }}
                    portal={true}
                    value={this.state.DefaultRateTemplate}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        DefaultRateTemplate: option.RateTemplate,
                      })
                    }
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='DefaultPay Terms'
                    options={payTerms}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.PayTerms}>
                          {`${value.PayTerms} - ${value.Description}`}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.DefaultPayTerms}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        DefaultPayTerms: option.PayTerms,
                      })
                    }
                    required
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Commission Labor Type'
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: Boolean(this.state.CommissionLaborType),
                    }}
                    options={_.filter(laborTypes, {
                      BusinessUnit: this.state.BusinessUnit,
                      Division: this.state.Division,
                      Type: 'O',
                    })}
                    menuItemComponent={(value, index) => {
                      return (
                        <MenuItem key={index} value={value.LaborType}>
                          {value.Description}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.CommissionLaborType || ''}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        CommissionLaborType: option.LaborType,
                      })
                    }
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Referral Bonus Labor Type'
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: Boolean(this.state.ReferralBonusLaborType),
                    }}
                    options={_.filter(laborTypes, {
                      BusinessUnit: this.state.BusinessUnit,
                      Division: this.state.Division,
                      Type: 'O',
                    })}
                    menuItemComponent={(value, index) => {
                      return (
                        <MenuItem key={index} value={value.LaborType}>
                          {value.Description}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.ReferralBonusLaborType || ''}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        ReferralBonusLaborType: option.LaborType,
                      })
                    }
                  />
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>receipt</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='InvoicePrefix'
                    id='invoicePrefix'
                    name='InvoicePrefix'
                    value={this.state.InvoicePrefix}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='POPrefix'
                    id='poPrefix'
                    name='POPrefix'
                    value={this.state.POPrefix}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                  {vpSettings &&
                    vpSettings.vpCompany &&
                    vpSettings.recTypes && (
                      <React.Fragment>
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <Autocomplete
                          className={classes.formControl}
                          title='VP RecType'
                          options={vpSettings.recTypes}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.RecType}>
                                {`${value.RecType} - ${value.Description}`}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          value={this.state.VPRecType || ''}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              VPCompany: option.Co,
                              VPRecType: option.RecType,
                            })
                          }
                          required
                        />
                      </React.Fragment>
                    )}
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'>
                    <Icon color='action'>view_quilt</Icon>
                  </div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Confirmation Email Template'
                    options={
                      [
                        { ID: null, Type: 'Email', Name: 'None' },
                        ...templates,
                      ] || []
                    }
                    menuItemComponent={value => {
                      return (
                        value.Type === 'Email' && (
                          <MenuItem value={value.ID}>
                            {`${value.ID ? `${value.ID} - ` : ''}${value.Name}`}
                          </MenuItem>
                        )
                      )
                    }}
                    portal={true}
                    value={this.state.ConfirmationEmailTemplate}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        ConfirmationEmailTemplate: option.ID,
                      })
                    }
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Invoice Email Template'
                    options={
                      [
                        { ID: null, Type: 'Email', Name: 'None' },
                        ...templates,
                      ] || []
                    }
                    menuItemComponent={value => {
                      return (
                        value.Type === 'Email' && (
                          <MenuItem value={value.ID}>
                            {`${value.ID ? `${value.ID} - ` : ''}${value.Name}`}
                          </MenuItem>
                        )
                      )
                    }}
                    portal={true}
                    value={this.state.InvoiceEmailTemplate}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        InvoiceEmailTemplate: option.ID,
                      })
                    }
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Estimate Email Template'
                    options={
                      [
                        { ID: null, Type: 'Email', Name: 'None' },
                        ...templates,
                      ] || []
                    }
                    menuItemComponent={value => {
                      return (
                        value.Type === 'Email' && (
                          <MenuItem value={value.ID}>
                            {`${value.ID ? `${value.ID} - ` : ''}${value.Name}`}
                          </MenuItem>
                        )
                      )
                    }}
                    portal={true}
                    value={this.state.EstimateEmailTemplate}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        EstimateEmailTemplate: option.ID,
                      })
                    }
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div className='flex'>
                  <div className='w-1/2'>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                  </div>
                </div>
              </TabContainer>
            )}

            {value === 1 && divisionDialog.type == 'edit' && (
              <TabContainer>
                <DepartmentsList
                  selectedDepartmentsData={this.state.Data.Departments}
                />
              </TabContainer>
            )}

            {/* -------- Invoice Setting Tab -------- */}
            {value === 2 && (
              <TabContainer padding='24px 24px 64px 24px'>
                <ImageDialog
                  category='assets/images/forms/'
                  onChange={onImgChange}
                  open={imgDialog}
                  onClose={() =>
                    this.setState({ ...this.state, imgDialog: false })
                  }
                />

                <div className='w-full'>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>image</Icon>
                    </div>
                    {!this.state.InvoiceHeaderImg ? (
                      <div
                        onClick={this.openHeaderImgDialog}
                        className='h-96 mb-24 relative cursor-pointer rounded-6'
                        style={{ border: '2px dotted lightgrey' }}
                      >
                        <Icon
                          className='pin-l pin-r pin-t pin-b absolute m-auto'
                          color='primary'
                        >
                          cloud_upload
                        </Icon>
                      </div>
                    ) : (
                      <img
                        onClick={this.openHeaderImgDialog}
                        className='h-96 mb-24 cursor-pointer'
                        src={this.state.InvoiceHeaderImg}
                      />
                    )}
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-10'>
                      <FormatLineSpacing color='action' />
                    </div>

                    <div className='w-full mb-24 mx-4'>
                      <div className='sm:flex'>
                        <section className='w-full mr-24 pt-4'>
                          <Typography className='font-bold text-14 mb-16'>
                            Print Margins:
                          </Typography>

                          <div className='ml-16'>
                            <RadioGroup
                              name='use-radio-group'
                              value={
                                this.state.defaultSpacing ? 'default' : 'custom'
                              }
                              onChange={this.handledefaultSpacingChange}
                            >
                              <StyledFormControlLabel
                                value='default'
                                label='Default'
                                control={<Radio />}
                              />
                              <StyledFormControlLabel
                                value='custom'
                                label='Custom'
                                control={<Radio />}
                              />
                            </RadioGroup>

                            <Collapse in={!this.state.defaultSpacing}>
                              <section
                                style={{
                                  backgroundColor: '#ccc',
                                  padding: '10px',
                                }}
                              >
                                <Box className='flex justify-center w-full'>
                                  <HorizontalSlider
                                    aria-label='Horizontal Spacing'
                                    valueLabelDisplay='auto'
                                    min={0}
                                    max={255}
                                    style={{ width: 255 }}
                                    value={[
                                      this.state.InvoiceMarginLeft,
                                      255 - this.state.InvoiceMarginRight,
                                    ]}
                                    onChange={this.handleHorizontalChange}
                                  />
                                  <div style={{ width: '24px' }}>&nbsp;</div>
                                </Box>

                                <Box className='flex justify-center w-full'>
                                  <Box
                                    style={{
                                      backgroundColor: '#fff',
                                      width: 255,
                                      height: 330,
                                      border: '1px solid gray',
                                    }}
                                  >
                                    <Box
                                      className='w-full h-full'
                                      style={{
                                        borderColor: 'transparent',
                                        borderStyle: 'solid',
                                        borderRightWidth:
                                          this.state.InvoiceMarginRight,
                                        borderLeftWidth:
                                          this.state.InvoiceMarginLeft,
                                        borderTopWidth:
                                          this.state.InvoiceMarginTop,
                                        borderBottomWidth:
                                          this.state.InvoiceMarginBottom,
                                      }}
                                    >
                                      <Box
                                        className='w-full h-full'
                                        style={{ border: '1px dotted #4dbce9' }}
                                      >
                                        <img
                                          className='w-full h-auto'
                                          src='assets/images/invoices/invoice-placeholder.png'
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                  <VerticalSlider
                                    aria-label='Vertical Spacing'
                                    orientation='vertical'
                                    valueLabelDisplay='auto'
                                    min={0}
                                    max={330}
                                    style={{ height: 330 }}
                                    value={[
                                      this.state.InvoiceMarginBottom,
                                      330 - this.state.InvoiceMarginTop,
                                    ]}
                                    onChange={this.handleVerticalChange}
                                  />
                                </Box>
                              </section>
                            </Collapse>
                          </div>
                        </section>

                        <section className='min-w-192 max-w-192 mr-16'>
                          {/* -------- Invoice Placeholder -------- */}
                          <InvoicePlaceholder
                            InvoiceHeaderImg={this.state.InvoiceHeaderImg}
                            InvoiceDisclaimer={this.state.InvoiceDisclaimer}
                            InvoiceSpacing={{
                              top: this.state.InvoiceMarginTop,
                              bottom: this.state.InvoiceMarginBottom,
                              left: this.state.InvoiceMarginLeft,
                              right: this.state.InvoiceMarginRight,
                            }}
                          />
                        </section>
                      </div>
                    </div>
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>announcement</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Invoice Disclaimer'
                      id='disclaimer'
                      name='InvoiceDisclaimer'
                      value={this.state.InvoiceDisclaimer}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      rows={5}
                      // autoFocus
                      fullWidth
                    />
                  </div>

                  {this.state.InvoiceDisclaimer && (
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                      <div className='w-full mb-24'>
                        <Typography className='font-bold text-14 mb-4'>
                          Preview:
                        </Typography>
                        <div
                          className='mb-12 rounded-6 p-8'
                          style={{ background: '#f1f1f1' }}
                        >
                          {this.state.InvoiceDisclaimer && (
                            <div>
                              <div className='font-bold text-red text-10 text-center w-full'>
                                * {this.state.InvoiceDisclaimer} *
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>image</Icon>
                    </div>
                    {!this.state.InvoiceFooterImg ? (
                      <div
                        onClick={this.openFooterImgDialog}
                        className='w-full sm:w-full-48 h-128 mb-24 relative cursor-pointer rounded-6'
                        style={{ border: '2px dotted lightgrey' }}
                      >
                        <Icon
                          className='pin-l pin-r pin-t pin-b absolute m-auto'
                          color='primary'
                        >
                          cloud_upload
                        </Icon>
                      </div>
                    ) : (
                      <img
                        onClick={this.openFooterImgDialog}
                        className='w-full sm:w-full-48 h-auto cursor-pointer'
                        src={this.state.InvoiceFooterImg}
                      />
                    )}
                  </div>
                </div>
              </TabContainer>
            )}

            {value === 3 && (
              <TabContainer padding='24px 24px 64px 24px'>
                <ImageDialog
                  category='assets/images/forms/'
                  onChange={onImgChange}
                  open={imgDialog}
                  onClose={() =>
                    this.setState({ ...this.state, imgDialog: false })
                  }
                />
                <div className='w-full'>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>image</Icon>
                    </div>
                    {!this.state.ChecklistHeaderImg ? (
                      <div
                        onClick={this.openChecklistHeaderImgDialog}
                        className='w-full sm:w-full-48 h-128 mb-24 relative cursor-pointer rounded-6'
                        style={{ border: '2px dotted lightgrey' }}
                      >
                        <Icon
                          className='pin-l pin-r pin-t pin-b absolute m-auto'
                          color='primary'
                        >
                          cloud_upload
                        </Icon>
                      </div>
                    ) : (
                      <img
                        onClick={this.openChecklistHeaderImgDialog}
                        className='w-auto h-96 mb-24 cursor-pointer'
                        src={this.state.ChecklistHeaderImg}
                      />
                    )}
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>image</Icon>
                    </div>
                    {!this.state.ChecklistFooterImg ? (
                      <div
                        onClick={this.openChecklistFooterImgDialog}
                        className='w-full sm:w-full-48 h-128 mb-24 relative cursor-pointer rounded-6'
                        style={{ border: '2px dotted lightgrey' }}
                      >
                        <Icon
                          className='pin-l pin-r pin-t pin-b absolute m-auto'
                          color='primary'
                        >
                          cloud_upload
                        </Icon>
                      </div>
                    ) : (
                      <img
                        onClick={this.openChecklistFooterImgDialog}
                        className='w-full sm:w-full-48 h-auto cursor-pointer'
                        src={this.state.ChecklistFooterImg}
                      />
                    )}
                  </div>
                </div>
              </TabContainer>
            )}

            {value === 4 && divisionDialog.type == 'edit' && (
              <TabContainer>
                <FileList />
              </TabContainer>
            )}

            {value === 5 && divisionDialog.type == 'edit' && (
              <TabContainer>
                <DashboardSelector data={this.state} form='division' />
              </TabContainer>
            )}
            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {['new', 'customer'].indexOf(divisionDialog.type) > -1 ? (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      addDivision(rec)
                      //this.closeComposeDialog();
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      updateDivision(rec)
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Save
                  </Button>
                )}
              </DialogActions>
            )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}

DivisionsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditDivisionDialog: Actions.closeEditDivisionDialog,
      closeNewDivisionDialog: Actions.closeNewDivisionDialog,
      openPreloadDivisionDialog,
      addDivision: Actions.addDivision,
      updateDivision: Actions.updateDivision,
      removeDivision: Actions.removeDivision,
      getFiles: FileActions.getFiles,
      showMessage: showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({
  divisionsApp,
  fileManagerApp,
  spReducers,
  emailTemplateApp,
}) {
  return {
    divisionDialog: divisionsApp.divisions.divisionDialog,
    businessUnits: spReducers.businessUnits,
    files: fileManagerApp.files,
    selectedItem: fileManagerApp.selectedItem,
    Co: spReducers.companies.Co,
    glAccounts: spReducers.glAccounts,
    securables: spReducers.userProfiles.User.Data.Securables,
    laborTypes: spReducers.laborTypes,
    taxCodes: spReducers.taxCodes,
    rateTemplates: spReducers.rateTemplates,
    payTerms: spReducers.payTerms,
    qboCompany: spReducers.qboSettings.qboCompany,
    qboServices: spReducers.qboSettings.services,
    qboMaterials: spReducers.qboSettings.materials,
    qboNonInventory: spReducers.qboSettings.nonInventory,
    templates: emailTemplateApp.templates.Templates,
    vpSettings: spReducers.vpSettings,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(DivisionsDialog),
)