import { CapacitorScreenshot } from 'capacitor-screenshot-plugin';
import { guid } from 'store/actions/spConnection/offline.actions';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';

export const SET_ERROR = '[SUPPORT] SET ERROR';
export const RESET_REDUCERS = '[SUPPORT] RESET REDUCERS';
export const RESET_STATE = '[SUPPORT] RESET STATE';
export const SET_REQUESTS = '[SUPPORT] SET REQUESTS';
export const KEEP_INTACT = '[SUPPORT] KEEP INTACT';
export const TEST_APP_ERRORS = '[SUPPORT] TEST APP ERRORS';

export function testAppErrors() {
    return {
        type: TEST_APP_ERRORS
    }
}

export function resetReducers() {
    return (dispatch, getState) => {
        Promise.all([
            dispatch({
                type: SET_ERROR,
                error: null,
                img: null,
                keepIntact: true
            })
        ]).then(() => {
            dispatch({
                type: RESET_REDUCERS
            });
        });
    }
}

export function keepIntact(bool) {
    return {
        type: KEEP_INTACT,
        keepIntact: bool
    }
}

function snapToFile(dataurl, filename) {

    var bstr = atob(dataurl),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: "image/jpeg" });
}

export function resetState() {
    return {
        type: RESET_STATE
    }
}

export function getSupportRequests(filter) {
    return (dispatch, getState) => {
        const request = axios.get(`${window['apiLocation']}/api/SupportRequest?filter=${filter || 'S'}`);
        request.then((response) => {
            dispatch(setRequests(response.data, filter));
        })
    }
}

export function setRequests(requests, filter) {
    return {
        type: SET_REQUESTS,
        requests,
        filter
    }
}

export function setError(error) {
    return async (dispatch, getState) => {
        let img = null;
        const platform = Capacitor.getPlatform();
        if (platform !== 'web') {
            try {
                const snap = await CapacitorScreenshot.grab();
                if (snap && snap.img && snap.img.length > 10) {
                    img = snapToFile(snap.img, `${guid()}.jpg`);
                }
            } catch (ex) {
                window["warn"](ex);
            }
        }
        dispatch({
            type: SET_ERROR,
            error,
            img
        });
    }
}