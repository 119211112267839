import { FuseChipSelect } from '@fuse/index';
import { FuseUtils } from '@fuse';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grow, Icon, IconButton, InputAdornment, Slide, TextField, Toolbar, Tooltip, Typography, withStyles, CircularProgress } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import axios from 'axios';
import classNames from 'classnames';
import JSONL from 'json-literal';
import * as Actions from 'main/content/apps/support/requests/store/actions/index';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import { impress } from 'main/content/compression/impress';
import React, { Component } from 'react';
import { Inspector } from 'react-inspector';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupportRequestAttachment from './attachment/SupportRequestAttachment';
import SupportRequestChecklist from './checklist/SupportRequestChecklist';
import Comments from './comments/Comments';
import AssigneesMenu from './toolbar/AssigneesMenu';
import CheckListMenu from './toolbar/CheckListMenu';
import DueMenu from './toolbar/DueMenu';
import LabelsMenu from './toolbar/LabelsMenu';
import StatusMenu from './toolbar/StatusMenu';
import PriorityMenu from './toolbar/PriorityMenu';
import { showMessage } from 'store/actions';
import getProfileImage from '../../../../../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {},
    paper: {
        color: theme.palette.text.primary
    },
    formControl: {
        marginBottom: 24
    },
    assigneeBadge: {
        top: 16,
        right: 16,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: '50%',
        textShadow: '1px 1px 5px #333',
        fontSize: 14
    },
    attachmentList: {
        paddingTop: 8
    },
    attachment: {
        fontSize: 13,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.16)',
        paddingLeft: 16,
        marginBottom: 8,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    attachmentFilename: {
        fontWeight: 600
    },
    attachmentSize: {
        marginLeft: 8,
        fontWeight: 300
    },
});

const initialState = {
    ID: null,
    ClientID: null,
    UserName: null,
    Title: null,
    Description: '',
    DueBy: null,
    Data: {
        Attachments: [],
        Comments: [],
        Assignees: [],
        Tags: [],
        Checklists: [],
    },
    Event: null,
    Notes: null,
    AddedDate: null,
    AttachmentID: null,
    Status: 'new',
    Priority: 3,
    dueMenu: null,
    confirm: false,
    isDatepickerOpen: false,
    newTag: false,
    editTag: null,
    inspector: false,
    selectedAttachment: null,
    selectedAttachmentName: null,
    attachments: [],
    submitButtonDisable: false,
    submitButtonText: "Submit",
    processing: false,
}

class SupportRequestDialog extends Component {

    state = {
        ...initialState
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.card &&
            !_.isEqual(prevProps.card, this.props.card)
        ) {
            const { card, isSupportProvider } = this.props;
            window["warn"]('Card Data Updated: ', card, prevProps.card);
            let errorData = {};
            if (card.Event && isSupportProvider && card.ID && card.ID > 0) {
                const eData = JSONL.parse(card.Event);
                errorData = {
                    error: eData.error,
                    data: {}
                };
                if (eData.data) {
                    Object.keys(eData.data).sort().forEach(function (key) {
                        errorData.data[key] = eData.data[key];
                    });
                }
                errorData.openDialogs = FuseUtils.discoverOpenDialogs(errorData);
            }
            if (this.props.card.ID === this.state.ID) {
                this.setState({ ...this.state, ...this.props.card, errorData }, () => this.props.updateSupportRequestList(this.state));
            } else {
                this.setState({ ...initialState, ...this.props.card, errorData }, () => this.props.updateSupportRequestList(this.state));
            }
        }
    }

    saveAndCloseSupportRequest = () => {
        const card = this.state;
        // if (!_.isEqual({ ...initialState, ...this.props.card }, { ...card })) {
        this.props.updateSupportRequest({ ...card });
        // }
        this.props.closeSupportRequestDialog();
    }

    initialSubmit = () => {
        this.setState({ ...this.state, submitButtonDisable: true, submitButtonText: "Submitting", processing: true }, () => { this.submitAndCloseSupportRequest(); if (this.props.onSubmitted) { this.props.onSubmitted(); } })
    }

    submitAndCloseSupportRequest = () => {
        const card = _.cloneDeepWith(this.state);
        delete card.attachments;
        delete card.selectedAttachment;
        delete card.selectedAttachmentName;
        let fd = new FormData();
        fd.append('data', JSON.stringify(card));
        if (this.state.attachments) {
            this.state.attachments.map((attachment, index) => {
                fd.append(`attachment-${index}`, attachment, attachment.name);
            });
        }
        const request = axios.post(`${window["apiLocation"]}/api/SupportRequest`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        request.then((response) => {
            // this.props.closeSupportRequestDialog();
            this.setState({ ...this.state, submitButtonDisable: false, submitButtonText: "Submit", processing: false }, () => this.props.closeSupportRequestDialog())
            this.props.showMessage({
                message: `Support Request Submitted.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            });
        }).catch((err) => {
            this.props.showMessage({
                message: `Error: ${err.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        })
    }

    closeSupportRequest = () => {
        this.props.closeSupportRequestDialog();
    }

    handleAssigneeAction = (Assignees) => {
        const { users, User } = this.props;
        const card = this.state;
        const { Co, BoardID, ListID, ID, } = card;
        const AddedBy = User.UserName;
        for (var i = 0; i < Assignees.length; i++) {
            const UserName = Assignees[i].value;
            const isAssignee = _.find(card.Data.Assignees, { UserName });
            if (!isAssignee) {
                const assignee = {
                    SupportRequestID: ID,
                    UserName,
                    AddedBy,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                }
                window["warn"](assignee);
                this.props.toggleAssignee(assignee, "ADD");
            }
        };

        for (var i = 0; i < card.Data.Assignees.length; i++) {
            const { UserName } = card.Data.Assignees[i];
            const isAssignee = _.find(Assignees, { value: UserName });
            if (!isAssignee) {
                const assignee = {
                    SupportRequestID: ID,
                    UserName,
                    AddedBy,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                }
                window["warn"](assignee);
                this.props.toggleAssignee(assignee, "DELETE");
            }

        }


    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    updatePriority = (Priority) => {
        this.setState({ Priority });
    }

    handleTagAction = (tags) => {
        const { User } = this.props;
        const card = this.state;
        const { ClientID, ID, } = card;
        const AddedBy = User.UserName;
        for (var i = 0; i < tags.length; i++) {
            const Tag = tags[i].value;
            const isTag = _.find(card.Data.Tags, { Tag });
            if (!isTag) {
                const tag = {
                    ClientID,
                    SupportRequestID: ID,
                    Tag,
                    AddedBy,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                }
                window["warn"](tag);
                this.props.toggleLabel(tag, card);
            }
        };

        for (var i = 0; i < card.Data.Tags.length; i++) {
            const { Tag } = card.Data.Tags[i];
            const isTag = _.find(tags, { value: Tag });
            if (!isTag) {
                const tag = {
                    ClientID,
                    SupportRequestID: ID,
                    Tag,
                    AddedBy,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                }
                window["warn"](tag);
                this.props.toggleLabel(tag, card);
            }

        }
    }

    handleUpdateChecklist = (list) => {
        window["warn"](list);
        this.props.updateChecklist(list);
    }

    handleRemoveChecklist = (list) => {
        window["warn"](list);
        this.props.removeChecklist(list);
    }

    handleAddChecklistItem = (Name, ChecklistID) => {
        const { User } = this.props;
        const {
            Co,
            ID
        } = this.state;
        const AddedBy = User.UserName;
        const item = {
            SupportRequestID: ID,
            ChecklistID,
            Name,
            AddedBy,
            AddedDate: new Date(),
            Data: {
                ErrMsg: null,
            }
        }
        window["warn"](item);
        this.props.addChecklistItem(item);
    }

    handleUpdateChecklistItem = (item) => {
        window["warn"](item);
        this.props.updateChecklistItem(item);
    }

    handleRemoveChecklistItem = (item) => {
        window["warn"](item);
        this.props.removeChecklistItem(item);
    }

    fileSelectedHandler = (event) => {
        const { Data, ID } = this.state;
        impress(event.target.files[0], 1024, 50, false, (img) => {
            window["log"](img);
            if (ID) {
                const fd = new FormData();
                fd.append('image', img.file, img.fileName);
                axios.put(`${window["apiLocation"]}/api/SupportRequestAttachment?ID=${encodeURIComponent(this.state.ID)}`, fd).then((res) => {
                    if (res.data.Attachments) {
                        this.props.addSupportRequestAttachment(res.data.Attachments);
                    }
                });
            } else {
                let { attachments } = this.state;
                attachments.push(new File([img.file], img.fileName, { type: img.type }));
                this.setState({ attachments });
            }
        });
    }

    toggleCompleted = () => {
        this.setState({
            ...this.state,
            CompletedYN: this.state.CompletedYN !== "Y" ? "Y" : null
        })
    };

    renderDateLabel = (e) => {
        window["log"](e);
        return (
            <TextField
                label="Due By"
                id="dueBy"
                name="DueBy"
                onClick={(e) => { this.setDatepickerOpen(true); }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon color="action">today</Icon>
                        </InputAdornment>
                    )
                }}
                className="w-full sm:w-auto"
                value={e.value}
                variant="outlined"
            />
        )
    };

    confirmRemoveSupportRequest = () => {
        this.setState({ ...this.state, confirm: true });
    }

    setDatepickerOpen = (bool) => {
        this.setState({ isDatepickerOpen: bool });
    }

    getFileSize = (attachment) => {
        var _size = attachment.size;
        var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0; while (_size > 900) { _size /= 1024; i++; }
        var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
        return exactSize;
    }


    removeAttachment = (index) => {
        const { attachments } = this.state;
        attachments.splice(index, 1);
        this.setState({ ...this.state, attachments });
    }

    showAttachment = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => this.setState({ ...this.state, selectedAttachment: reader.result, selectedAttachmentName: file.name }, () => window["warn"](this.state));
        reader.onerror = error => reject(error);
    });

    render() {
        const { theme, classes, removeSupportRequest, closeSupportRequestDialog, board, addLabel, chipChange, removeAttachment, removeDue, toggleLabel, toggleAssignee, addChecklist, commentAdd, makeCover, removeCover, addNewChip, users, username, securables, isSupportProvider } = this.props;
        const card = this.state;
        const { Co } = card;
        const { handleChange } = this;
        const user = _.find(users, { UserName: this.state.UserName });
        const avatar = 'assets/images/avatars/profile.jpg';
        const { errorData } = this.state;
        const tags = [
            {
                Tag: "inquiry",
                Name: "Inquiry",
                Color: "#4f4f4f",
                Background: "yellow"
            },
            {
                Tag: "bug",
                Name: "Bug",
                Color: "#fff",
                Background: "orangered"
            },
            {
                Tag: "improvement",
                Name: "Improvement",
                Color: "#fff",
                Background: "orange"
            },
            {
                Tag: "feature",
                Name: "Feature",
                Color: "#fff",
                Background: "blue"
            },
            {
                Tag: "reporting",
                Name: "Reporting",
                Color: "#fff",
                Background: "#5f5f5f"
            }
        ];
        const statuses = {
            'new': {
                id: 0,
                text: 'New'
            },
            'assigned': {
                id: 1,
                text: 'Assigned'
            },
            'in-progress': {
                id: 2,
                text: 'In Progress'
            },
            'on-hold': {
                id: 3,
                text: 'On Hold'
            },
            'qa': {
                id: 4,
                text: 'Q/A'
            },
            'completed': {
                id: 5,
                text: 'Completed'
            },
        };


        const Attachment = ({ fileName, size, onRemove, file }) => {
            return (
                <div className="w-full">
                    <div onClick={() => this.showAttachment(file)} className={classes.attachment}>
                        <div className="flex">
                            <Typography variant="caption" className={classes.attachmentFilename}>{fileName}</Typography>
                            <Typography variant="caption" className={classes.attachmentSize}>({size})</Typography>
                        </div>
                        <IconButton onClick={(e) => { e.stopPropagation(); onRemove(); }}>
                            <Icon className="text-16">close</Icon>
                        </IconButton>
                    </div>
                </div>
            );
        }

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: classNames(classes.paper, matches ? "m-24 max-w-lg" : "full-screen-dialog")
                        }}
                        onClose={this.closeSupportRequest}
                        open={Boolean(card.ID) || card.open}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                    >
                        <ConfirmationDialog
                            open={this.state.confirm}
                            title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this card? This action cannot be undone.`}</div>}
                            confirmText="Confirm"
                            cancelText="Never Mind"
                            onCancel={() => this.setState({ ...this.state, confirm: false })}
                            onConfirm={() => this.setState({ ...this.state, confirm: false }, () => { removeSupportRequest(card); })}
                        />
                        {card && (
                            <DialogTitle component="div" className="p-0">
                                <AppBar position="static" elevation={1}>
                                    <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16 dialog-header">
                                        <div className="flex flex-1 max-w-3/4 overflow-x-auto">
                                            <StatusMenu status={card.Status} theme={theme} assignees={card.Data.Assignees.length} onChange={(Status) => this.setState({ Status }, () => this.props.updateSupportRequest(this.state))} />
                                            {isSupportProvider && card.ID ?
                                                <DueMenu
                                                    onDueChange={handleChange}
                                                    onRemoveDue={removeDue}
                                                    due={card.DueBy}
                                                /> : ''
                                            }
                                            {isSupportProvider && card.ID ?
                                                <PriorityMenu
                                                    onChange={this.updatePriority}
                                                    priority={card.Priority}
                                                /> : ''
                                            }

                                            <LabelsMenu
                                                onToggleLabel={toggleLabel}
                                                labels={tags}
                                                card={card}
                                                idLabels={card.Data.Tags}
                                            />
                                            {isSupportProvider && card.ID ?
                                                <AssigneesMenu
                                                    onToggleAssignee={toggleAssignee}
                                                    Co={Co}
                                                    Assignees={users}
                                                    idAssignees={card.Data.Assignees}
                                                /> : ''
                                            }
                                            <IconButton color="inherit" onClick={() => this.fileInput.click()}>
                                                <Icon style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>
                                            </IconButton>
                                            <input
                                                type="file"
                                                accept=".png,.jpg,.jpeg"
                                                style={{ display: 'none' }}
                                                onChange={this.fileSelectedHandler}
                                                ref={fileInput => this.fileInput = fileInput}
                                            />
                                            {isSupportProvider && card.ID ?
                                                <CheckListMenu
                                                    onAddChecklist={addChecklist}
                                                /> : ''
                                            }
                                        </div>
                                        <div className="flex">
                                            <IconButton color="inherit" onClick={this.closeSupportRequest}>
                                                <Icon>close</Icon>
                                            </IconButton>
                                        </div>
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>
                        )}

                        {card && (
                            <DialogContent className="p-16 sm:p-24 mb-48">
                                <div className="w-full justify-between flex">
                                    <div className="flex w-full items-center mb-12" style={{ marginTop: -8 }}>
                                        <Icon className="text-24 mr-8" color="primary">assignment_late</Icon>
                                        <Typography className="font-600 text-18">{!card.ID ? 'New ' : ''}Support Request{card.ID ? ` #${card.ID}` : ''}</Typography>
                                    </div>

                                    {user &&
                                        <div className="w-full flex justify-end">
                                            <div className={classNames(classes.commentBubble, "flex flex-col")}>
                                                <div className="flex items-center">
                                                    <Avatar alt={user.FirstName} src={user && user.Data && user.Data.Avatar ? getProfileImage(`Co=${this.props.Co}&ID=${user.Data.Avatar}`) : avatar} className="w-24 h-24 mr-8" />
                                                    <Typography className="font-bold">{`${user.FirstName} ${user.LastName}`}</Typography>
                                                </div>
                                                <Typography className="ml-8 text-12 text-right" color="textSecondary">{new Date(this.state.AddedDate).toLocaleDateString('en-US')}</Typography>
                                            </div>
                                        </div>
                                    }

                                    {!user &&
                                        <div className="w-full flex justify-end">
                                            <div className={classNames(classes.commentBubble, "flex flex-col")}>
                                                <div className="flex items-center">
                                                    <Avatar alt={card.UserName} src={avatar} className="w-24 h-24 mr-8" />
                                                    <Typography className="font-bold">{card.UserName}</Typography>
                                                </div>
                                                <Typography className="ml-8 text-12 text-right" color="textSecondary">{new Date(card.AddedDate).toLocaleDateString('en-US')}</Typography>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="flex items-center mb-24">
                                    <TextField
                                        label="Title"
                                        type="text"
                                        name="Title"
                                        value={card.Title || ''}
                                        onChange={(e) => this.setState({ Title: e.target.value })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled={!isSupportProvider && this.state.ID}

                                    />
                                </div>

                                <div className="w-full mb-24">
                                    <TextField
                                        label="Description"
                                        name="Description"
                                        multiline
                                        rows="4"
                                        value={card.Description || ''}
                                        onChange={(e) => this.setState({ Description: e.target.value })}
                                        variant="outlined"
                                        disabled={Boolean(this.state.ID) && username !== this.state.AddedBy && !isSupportProvider}
                                        fullWidth
                                    />
                                    {isSupportProvider && card.Event && card.ID && card.ID > 0 && errorData && errorData.error && errorData.error.message ?
                                        <div className="w-full">
                                            <div className="flex items-center mt-16">
                                                <Icon className="text-20 mr-8" color="error">error</Icon>
                                                <Typography className="font-600 text-16 text-grey-darker">{errorData.error.message}</Typography>
                                            </div>
                                        </div> : ''
                                    }
                                </div>
                                <div className="flex flex-col sm:flex-row sm:justify-between justify-center items-center mb-24">

                                    {isSupportProvider && card.DueBy && (
                                        <DatePicker
                                            inputVariant="outlined"
                                            onOpen={() => this.setDatepickerOpen(true)}
                                            onClose={() => this.setDatepickerOpen(false)}
                                            open={this.state.isDatepickerOpen}
                                            label="Due By"
                                            type="date"
                                            name="DueBy"
                                            value={card.DueBy}
                                            onChange={(ev) => {
                                                window["warn"](ev);
                                                if (ev) {
                                                    ev.target = {
                                                        type: 'input',
                                                        name: "DueBy",
                                                        value: ev._d ? ev._d : null,
                                                    }
                                                    handleChange(ev);
                                                } else {
                                                    removeDue();
                                                }
                                            }}
                                            placeholder="Choose a due date"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            autoOk={true}
                                            clearable={true}
                                            format="MM/DD/YYYY"
                                            // ref={el => { this.datePicker = el; }}
                                            variant="outlined"
                                            TextFieldComponent={this.renderDateLabel}
                                        />
                                    )}
                                </div>

                                <div className="flex flex-col sm:flex-row">
                                    {card.Data.Tags && (
                                        <div className="flex-1 mb-24">
                                            <div className="flex items-center mt-16 mb-12">
                                                <Icon className="text-20 mr-8" color="inherit">label</Icon>
                                                <Typography className="font-600 text-16">Tags</Typography>
                                            </div>
                                            <FuseChipSelect
                                                className={(card.Data.Assignees && card.Data.Assignees.length > 0) && 'sm:mr-8'}
                                                value={
                                                    card.Data.Tags.map(tag => ({
                                                        value: tag.Tag,
                                                        label: _.find(tags, { Tag: tag.Tag }).Name,
                                                        color: _.find(tags, { Tag: tag.Tag }).Color,
                                                        background: _.find(tags, { Tag: tag.Tag }).Background
                                                    }))
                                                }
                                                onChange={(value) => this.handleTagAction(value)}
                                                placeholder="Select Tags"
                                                isMulti
                                                textFieldProps={{
                                                    variant: "outlined"
                                                }}
                                                disabled={!isSupportProvider && card.ID > 0}
                                                variant="fixed"
                                                options={tags.map((label) => (
                                                    {
                                                        value: label.Tag,
                                                        label: label.Name,
                                                        color: label.Color,
                                                        background: label.Background
                                                    }
                                                ))}
                                                onCreateOption={(Name) => {

                                                }}
                                            />
                                        </div>
                                    )}

                                    {isSupportProvider && card.ID && card.Data.Assignees ?
                                        <div className="flex-1 mb-24">
                                            <div className="flex items-center mt-16 mb-12">
                                                <Icon className="text-20 mr-8" color="inherit">supervisor_account</Icon>
                                                <Typography className="font-600 text-16">Assignees</Typography>
                                            </div>
                                            <FuseChipSelect
                                                className={'sm:ml-8'}
                                                value={
                                                    card.Data.Assignees.map(memb => {
                                                        const assignee = _.find(users, { UserName: memb.UserName });
                                                        return {
                                                            value: assignee.UserName,
                                                            label: (
                                                                <Tooltip title={`${assignee.FirstName} ${assignee.LastName}`}>
                                                                    <Badge classes={{ badge: classNames(classes.assigneeBadge) }} badgeContent={`${assignee.FirstName.substr(0, 1)}${assignee.LastName.substr(0, 1)}`}>
                                                                        <Avatar className="-ml-12 w-32 h-32" src={avatar} />
                                                                    </Badge>
                                                                </Tooltip>)
                                                        }
                                                    })
                                                }
                                                onChange={(value) => this.handleAssigneeAction(value)}
                                                placeholder="Select Assignees"
                                                isMulti
                                                textFieldProps={{
                                                    variant: "outlined"
                                                }}
                                                options={_.filter(users, (o) => o.ActiveYN !== 'N').map((assignee) => (
                                                    {
                                                        value: assignee.UserName,
                                                        label: (<span className="flex items-center">{`${assignee.FirstName} ${assignee.LastName}`}</span>)
                                                    }
                                                ))}
                                                variant="fixed"
                                            />
                                        </div> : ''
                                    }
                                </div>
                                {isSupportProvider && card.Event && card.ID && card.ID > 0 ?
                                    <div className="w-full">
                                        <div className="flex items-center mt-16 mb-12">
                                            <Icon className="text-20 mr-8" color="inherit">code</Icon>
                                            <Typography className="font-600 text-16">Details ({(Buffer.byteLength(card.Event, 'utf8') / 1024 / 1024).toFixed(2)} MB)</Typography>
                                        </div>
                                        <div className="w-full mb-24 p-12 text-grey-lighter rounded-lg overflow-x-auto" style={{ backgroundColor: 'rgb(36, 36, 36)' }}>
                                            <Inspector data={errorData} theme="chromeDark" name={"Error Data"} />
                                        </div>
                                    </div> : ''
                                }

                                {(!card.ID && card.attachments && card.attachments.length > 0) ?
                                    <div className="mb-24">
                                        <div className="flex items-center mt-16">
                                            <Icon className="text-20 mr-8" color="inherit" style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>
                                            <Typography className="font-600 text-16">Attachments</Typography>
                                        </div>
                                        <div className={classes.attachmentList}>
                                            {card.attachments && card.attachments.map((attachment, index) => {
                                                const fileSize = this.getFileSize(attachment);
                                                return <Attachment key={index} file={attachment} onRemove={() => this.removeAttachment(index)} fileName={attachment.name} size={fileSize} />;
                                            })}
                                        </div>
                                        {
                                            this.state.selectedAttachment &&
                                            <Dialog
                                                classes={{ paper: classNames("mt-0 mb-0 w-full h-full", !matches && "full-screen-dialog") }}
                                                open={this.state.selectedAttachment}
                                                onClose={() => this.setState({ ...this.state, selectedAttachment: null })}
                                                maxWidth="sm"
                                                fullScreen={!matches}
                                            >
                                                <AppBar position="static" className="dialog-header">
                                                    <Toolbar className="flex w-full">
                                                        <Typography variant="subtitle1" color="inherit">
                                                            {this.state.selectedAttachmentName ? this.state.selectedAttachmentName : 'Attachment'}
                                                        </Typography>
                                                    </Toolbar>
                                                    <IconButton
                                                        style={{
                                                            position: 'absolute',
                                                            right: 10,
                                                            top: matches ? 8 : 4,
                                                            color: 'white'
                                                        }}
                                                        onClick={() => {
                                                            this.setState({ ...this.state, selectedAttachment: null });
                                                        }}
                                                        className="dialog-header-icon"
                                                    >
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                </AppBar>
                                                <DialogContent classes={{ root: "p-16 pb-0 sm:p-24 sm:pb-0" }}>
                                                    <iframe src={this.state.selectedAttachment} className="w-full overflow-auto" style={{ height: 'calc(100vh - 158px)' }} />
                                                </DialogContent>
                                            </Dialog>
                                        }
                                    </div> : ''
                                }

                                {(card.ID && card.Data.Attachments && card.Data.Attachments.length > 0) ?
                                    <div className="mb-24">
                                        <div className="flex items-center mt-16 mb-12">
                                            <Icon className="text-20 mr-8" color="inherit" style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>
                                            <Typography className="font-600 text-16">Attachments</Typography>
                                        </div>
                                        <div className="flex flex-col sm:flex-row flex-wrap">
                                            {_.filter(card.Data.Attachments, (o) => (isSupportProvider || o.InternalYN !== "Y")).map(item => (
                                                <SupportRequestAttachment
                                                    item={item}
                                                    card={card}
                                                    // makeCover={makeCover}
                                                    // removeCover={removeCover}
                                                    removeAttachment={removeAttachment}
                                                    key={item.ID}
                                                />
                                            )
                                            )}
                                        </div>
                                    </div> : ''
                                }

                                {(isSupportProvider && card.Data.Checklists) ? [...card.Data.Checklists].map(checklist => (
                                    <SupportRequestChecklist
                                        key={checklist.ID}
                                        checklist={checklist}
                                        onUpdateChecklist={this.handleUpdateChecklist}
                                        onRemoveChecklist={this.handleRemoveChecklist}
                                        onAddChecklistItem={this.handleAddChecklistItem}
                                        onUpdateChecklistItem={this.handleUpdateChecklistItem}
                                        onRemoveChecklistItem={this.handleRemoveChecklistItem}
                                    />
                                )) : ''}
                                {(card.ID && card.ID > 0) ?
                                    <Comments card={card} isSupportProvider={isSupportProvider} users={users} commentAdd={commentAdd} /> : ''
                                }

                                <DialogActions className="dialog-actions justify-between pin-b pin-l pin-r absolute bg-white">
                                    {!card.ID ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.initialSubmit}
                                            disabled={!card.Title || !card.UserName || this.state.submitButtonDisable}
                                        >
                                            <React.Fragment>
                                                {this.state.processing ? <CircularProgress className="mr-8" size={16} /> : null}
                                                {this.state.submitButtonText}
                                            </React.Fragment>
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.saveAndCloseSupportRequest}
                                            disabled={!isSupportProvider && username !== card.UserName}
                                        > Save
                                        </Button>
                                    }
                                </DialogActions>
                            </DialogContent>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeSupportRequestDialog: Actions.closeSupportRequestDialog,
        updateSupportRequest: Actions.updateSupportRequest,
        removeSupportRequest: Actions.removeSupportRequest,
        handleChange: Actions.handleChange,
        addLabel: Actions.addLabel,
        chipChange: Actions.chipChange,
        makeCover: Actions.makeCover,
        removeCover: Actions.removeCover,
        removeAttachment: Actions.removeAttachment,
        removeDue: Actions.removeDue,
        toggleLabel: Actions.toggleLabel,
        toggleAssignee: Actions.toggleAssignee,
        addChecklist: Actions.addChecklist,
        updateChecklist: Actions.updateChecklist,
        removeChecklist: Actions.removeChecklist,
        addChecklistItem: Actions.addChecklistItem,
        updateChecklistItem: Actions.updateChecklistItem,
        removeChecklistItem: Actions.removeChecklistItem,
        addNewChip: Actions.addNewChip,
        commentAdd: Actions.commentAdd,
        addSupportRequestAttachment: Actions.addSupportRequestAttachment,
        newSupportRequest: Actions.newSupportRequest,
        updateSupportRequestList: Actions.updateSupportRequestList,
        showMessage
    }, dispatch);
}

function mapStateToProps(store) {
    const { supportApp, spReducers } = store;
    return {
        stateData: store,
        Co: spReducers.companies.Co,
        card: supportApp.request,
        users: spReducers.userProfiles.Users,
        username: spReducers.userProfiles.User.UserName,
        User: spReducers.userProfiles.User,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(SupportRequestDialog));
