import axios from 'axios/index';
import reorder, { reorderQuoteMap } from './reorder';
import { showMessage } from 'store/actions/fuse';
import { updateSupportRequest } from './card.actions';
import history from 'history.js';
import _ from '@lodash';
import * as Actions from './index';
import { FuseUtils } from '@fuse';

export const GET_BOARD = '[SUPPORT REQUEST APP] GET BOARD';
export const DELETE_BOARD = '[SUPPORT REQUEST APP] DELETE BOARD';
export const COPY_BOARD = '[SUPPORT REQUEST APP] COPY BOARD';
export const RENAME_BOARD = '[SUPPORT REQUEST APP] RENAME BOARD';
export const CHANGE_BOARD_SETTINGS = '[SUPPORT REQUEST APP] CHANGE BOARD SETTINGS';
export const RESET_BOARD = '[SUPPORT REQUEST APP] RESET BOARD';
export const ORDER_LIST = '[SUPPORT REQUEST APP] ORDER LIST';
export const ORDER_REQUEST = '[SUPPORT REQUEST APP] ORDER REQUEST';
export const ADD_REQUEST = '[SUPPORT REQUEST APP] ADD REQUEST';
export const ADD_LIST = '[SUPPORT REQUEST APP] ADD LIST';
export const ADD_LABEL = '[SUPPORT REQUEST APP] ADD LABEL';
export const RENAME_LIST = '[SUPPORT REQUEST APP] RENAME LIST';
export const REMOVE_LIST = '[SUPPORT REQUEST APP] REMOVE LIST';

export function getBoard(Id) {
    const request = axios.get(`${window["apiLocation"]}/api/SupportRequest?ID=${Id}`);

    return (dispatch) =>
        request.then(
            (response) =>
                dispatch({
                    type: GET_BOARD,
                    payload: response.data
                }),
            (error) => {
                dispatch(showMessage({
                    message: error.response.data,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
                history.push({
                    pathname: '/apps/project-tracking/boards'
                });
            });
}

export function resetBoard() {
    return {
        type: RESET_BOARD
    };
}

export function reorderList(result) {
    return (dispatch, getState) => {

        const { board } = getState().supportApp;
        const { Lists } = board.Data;

        const ordered = reorder(
            Lists,
            result.source.index,
            result.destination.index
        );

        for (var i = 0; i < ordered.length; i++) {
            ordered[i].DisplayIndex = i;
            dispatch(renameList(ordered[i], true));
        }

        dispatch(showMessage({
            message: 'List Order Saved',
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        }));

        return dispatch({
            type: ORDER_LIST,
            payload: ordered
        });
    }
}

export function reorderSupportRequest(result) {
    return (dispatch, getState) => {

        const { board } = getState().supportApp;
        const { Lists } = board.Data;

        const ordered = reorderQuoteMap(
            Lists,
            result.source,
            result.destination
        );

        dispatch(showMessage({
            message: 'SupportRequest Order Saved',
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        }));

        for (var i = 0; i < ordered.length; i++) {
            if (ordered[i].ID === result.destination.droppableId) {
                for (var c = 0; c < ordered[i].Data.SupportRequests.length; c++) {
                    ordered[i].Data.SupportRequests[c].ListID = ordered[i].ID;
                    ordered[i].Data.SupportRequests[c].DisplayIndex = c;
                    dispatch(updateSupportRequest(ordered[i].Data.SupportRequests[c], true, true));
                }
            }
        }

        return dispatch({
            type: ORDER_REQUEST,
            payload: ordered
        });
    }
}

export function newSupportRequest(card) {

    const request = axios.post(`${window["apiLocation"]}/api/SupportRequest`,
        card
    );
    return (dispatch) =>
        new Promise((resolve, reject) => {
            request.then((response) => {
                resolve(response.data);
                return dispatch({
                    type: ADD_REQUEST,
                    payload: response.data
                });
            });
        });
}


export function newList(list) {

    const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardList`,
        list
    );

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: ADD_LIST,
                payload: response.data
            })
        );
}

export function renameList(list, bool) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardList?Co=${list.Co}&Board=${list.BoardID}&List=${list.ID}`,
        list
    );

    return (dispatch) =>
        request.then((response) => {
            if (!bool) {
                dispatch({
                    type: RENAME_LIST,
                    listId: list.ID,
                    listTitle: list.Name
                })
            }
        }
        );
}

export function removeList(list) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardList?Co=${list.Co}&Board=${list.BoardID}&List=${list.ID}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch(showMessage({
                message: 'List Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
            dispatch({
                type: REMOVE_LIST,
                payload: list
            });
        }
        );
}

export function addLabel(label) {
    return (dispatch) => {
        return dispatch({
            type: ADD_LABEL,
            payload: label
        })
    }
}

export function changeBoardSettings(newSettings) {
    return (dispatch, getState) => {
        const { board } = getState().supportApp;
        const settings = _.merge(board.settings, newSettings);
        const request = axios.post('/api/scrumboard-app/board/settings/update',
            {
                boardId: board.id,
                settings
            }
        );

        return request.then((response) =>
            dispatch({
                type: CHANGE_BOARD_SETTINGS,
                payload: response.data
            })
        );
    }
}

export function deleteBoard(board) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoard?Co=${board.Co}&Board=${board.ID}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch(showMessage({
                message: 'Board Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));

            history.push({
                pathname: '/apps/project-tracking/boards'
            });

            dispatch({
                type: DELETE_BOARD,
                payload: board
            });
        }
        );
}
// export function deleteBoard(boardId) {
//     const request = axios.post('/api/scrumboard-app/board/delete',
//         {
//             boardId
//         }
//     );

//     return (dispatch) =>
//         request.then((response) => {

//             history.push({
//                 pathname: '/apps/project-tracking/boards'
//             });

//             return dispatch({
//                 type: DELETE_BOARD
//             });
//         })
// }

export function copyBoard(board) {
    const newBoard = _.merge(board, {
        id: FuseUtils.generateGUID(),
        name: board.Name + ' (Copied)',
        uri: board.uri + '-copied'
    });
    return (dispatch) => {
        dispatch(Actions.newBoard(newBoard));
        return { type: COPY_BOARD };
    }
}

export function renameBoard(board) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoard?Co=${board.Co}&Board=${board.ID}`,
        board
    );

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: RENAME_BOARD,
                payload: board
            }));
}
