import {combineReducers} from 'redux';
import serviceUnits from './service-units.reducer';
import user from './user.reducer';

const serviceUnitsAppReducers = combineReducers({
    serviceUnits,
    user
});

export default serviceUnitsAppReducers;
