import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedEstimateIds: [],
    routeParams: {},
    loading: false,
    processing: false,
    filters: null,
    schedule: [],
    estimateDialog: {
        type: 'new',
        props: {
            open: false,
            trip: null,
        },
        data: {
            Co: null,
            BusinessUnit: null,
            Division: null,
            Estimate: null,
            EstimateNumber: null,
            QuoteYN: null,
            IssuedBy: null,
            IssuedDate: new Date(),
            IssuedFromWorkOrder: null,
            ExpirationDate: null,
            Customer: null,
            Site: null,
            Greeting: null,
            Description: null,
            Status: 0,
            OptionBoard: null,
            SelectedOption: null,
            Requestor: null,
            Originator: null,
            Contact: null,
            ContactPhone: null,
            TaxType: 'S',
            TaxCode: null,
            TaxRate: 0.00,
            Notes: null,
            ContractVerbiage: null,
            AcceptedYN: null,
            AcceptedBy: null,
            AcceptedDate: null,
            Signature: null,
            EntereBy: null,
            EnteredDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            CallSid: null,
            Data: {
                ErrMsg: null,
                Components: null,
                Customer: null,
                Site: null,
            }
        }
    }
};

const estimatesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_LOADING: {
            const { loading } = action;
            return {
                ...state,
                loading
            }
        }
        case Actions.GET_SCHEDULE:
            {
                const { schedule } = action;
                return {
                    ...state,
                    schedule
                }
            }
        case Actions.SCHEDULE_ESTIMATE:
            {
                const { data } = action;
                return {
                    ...state,
                    estimateDialog: {
                        ...state.estimateDialog,
                        type: 'update',
                        data: {
                            ...state.estimateDialog.data,
                            ...data
                        }
                    }
                }
            }
        case Actions.SET_PROCESSING: {
            const { processing } = action;
            return {
                ...state,
                processing
            }
        }
        case Actions.GET_ESTIMATES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_ESTIMATES:
            {

                const estimateId = action.estimateId;

                let selectedEstimateIds = [...state.selectedEstimateIds];

                if (selectedEstimateIds.find(id => id === estimateId) !== undefined) {
                    selectedEstimateIds = selectedEstimateIds.filter(id => id !== estimateId);
                }
                else {
                    selectedEstimateIds = [...selectedEstimateIds, estimateId];
                }

                return {
                    ...state,
                    selectedEstimateIds: selectedEstimateIds
                };
            }
        case Actions.SELECT_ALL_ESTIMATES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedEstimateIds = arr.map(estimates => estimates.id);

                return {
                    ...state,
                    selectedEstimateIds: selectedEstimateIds
                };
            }
        case Actions.DESELECT_ALL_ESTIMATES:
            {
                return {
                    ...state,
                    selectedEstimateIds: []
                };
            }
        case Actions.SET_ESTIMATES: {
            return {
                ...state,
                entities: action.estimates,
                selectedEstimateIds: [],
                filters: action.filters,
                loading: action.loading
            }
        }
        case Actions.SET_ESTIMATE_FILTERS:
            {
                const { filters } = action;
                return {
                    ...state,
                    filters
                }
            }
        case Actions.OPEN_NEW_ESTIMATE_DIALOG:
            {
                const { data, trip } = action;
                window["warn"]({
                    ...initialState,
                    ...data,
                });
                return {
                    ...state,
                    estimateDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            trip
                        },
                        data: {
                            ...initialState.estimateDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_ESTIMATE_DIALOG:
            {
                return {
                    ...state,
                    estimateDialog: {
                        type: 'new',
                        props: {
                            open: false,
                            trip: null,
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_ESTIMATE_DIALOG:
            {
                return {
                    ...state,
                    estimateDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                            trip: null,
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_ESTIMATE_DIALOG:
            {
                return {
                    ...state,
                    estimateDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_ESTIMATE_DIALOG:
            {
                return {
                    ...state,
                    estimateDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_ESTIMATE_DIALOG:
            {
                return {
                    ...state,
                    estimateDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default estimatesReducer;
