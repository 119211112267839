import * as Actions from 'store/actions/spConnection';

const initialState = {
    notes: {}
}
const invoices = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_INVOICE_NOTES:
            {
                return {
                    notes: action.notes
                };
            }
    }
    return state;
};

export default invoices;