import axios from 'axios';
import { getUserData } from 'main/content/apps/user-roles/store/actions/user.actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const GET_USER_ROLES = '[USER ROLES APP] GET USER ROLES';
export const SET_SEARCH_TEXT = '[USER ROLES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_USER_ROLES = '[USER ROLES APP] TOGGLE IN SELECTED USER ROLES';
export const SELECT_ALL_USER_ROLES = '[USER ROLES APP] SELECT ALL USER ROLES';
export const DESELECT_ALL_USER_ROLES = '[USER ROLES APP] DESELECT ALL USER ROLES';
export const OPEN_NEW_USER_ROLE_DIALOG = '[USER ROLES APP] OPEN NEW USER ROLE DIALOG';
export const CLOSE_NEW_USER_ROLE_DIALOG = '[USER ROLES APP] CLOSE NEW USER ROLE DIALOG';
export const OPEN_EDIT_USER_ROLE_DIALOG = '[USER ROLES APP] OPEN EDIT USER ROLE DIALOG';
export const UPDATE_EDIT_USER_ROLE_DIALOG = '[USER ROLES APP] UPDATE EDIT USER ROLE DIALOG';
export const CLOSE_EDIT_USER_ROLE_DIALOG = '[USER ROLES APP] CLOSE EDIT USER ROLE DIALOG';
export const ADD_USER_ROLE = '[USER ROLES APP] ADD USER ROLE';
export const UPDATE_USER_ROLE = '[USER ROLES APP] UPDATE USER ROLE';
export const REMOVE_USER_ROLE = '[USER ROLES APP] REMOVE USER ROLE';
export const REMOVE_USER_ROLES = '[USER ROLES APP] REMOVE USER ROLES';
export const TOGGLE_STARRED_USER_ROLE = '[USER ROLES APP] TOGGLE STARRED USER ROLE';
export const TOGGLE_STARRED_USER_ROLES = '[USER ROLES APP] TOGGLE STARRED USER ROLES';
export const SET_USER_ROLES_STARRED = '[USER ROLES APP] SET USER ROLES STARRED ';
export const SET_SEARCH_RESULTS = '[USER ROLES APP] SET SEARCH RESULTS';
export const ADD_USER_ROLE_SECURABLE = '[USER ROLES APP] ADD USER ROLE SECURABLE';
export const UPDATE_USER_ROLE_SECURABLE = '[USER ROLES APP] UPDATE USER ROLE SECURABLE';
export const REMOVE_USER_ROLE_SECURABLE = '[USER ROLES APP] REMOVE USER ROLE SECURABLE';
export const ADD_USER_ROLE_ASSIGNMENT = '[USER ROLES APP] ADD USER ROLE ASSIGNMENT';
export const UPDATE_USER_ROLE_ASSIGNMENT = '[USER ROLES APP] UPDATE USER ROLE ASSIGNMENT';
export const REMOVE_USER_ROLE_ASSIGNMENT = '[USER ROLES APP] REMOVE USER ROLE ASSIGNMENT';

export function getUserRoles(routeParams) {
    const request = axios.get('/api/user-roles-app/user-roles', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_USER_ROLES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedUserRoles(userRoleId) {
    return {
        type: TOGGLE_IN_SELECTED_USER_ROLES,
        userRoleId
    }
}


export function selectAllUserRoles() {
    return {
        type: SELECT_ALL_USER_ROLES
    }
}

export function deSelectAllUserRoles() {
    return {
        type: DESELECT_ALL_USER_ROLES
    }
}


export function openNewUserRoleDialog(data) {
    return {
        type: OPEN_NEW_USER_ROLE_DIALOG,
        data
    }
}

export function closeNewUserRoleDialog() {
    return {
        type: CLOSE_NEW_USER_ROLE_DIALOG
    }
}

export function openEditUserRoleDialog(data) {
    return (dispatch, getState) => {
        const state = getState();
        const { Co } = state.spReducers.companies;

        const request = axios.get(`${window["apiLocation"]}/api/UserRole?Co=${Co}&Role=${data.Role}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_USER_ROLE_DIALOG,
                    data: response.data,
                })
            ]))
    }
}

export function closeEditUserRoleDialog() {
    return {
        type: CLOSE_EDIT_USER_ROLE_DIALOG
    }
}

export function addUserRole(newUserRole) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/UserRole`,
            newUserRole
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_USER_ROLE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateUserRole(userRole) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/UserRole?Role=${encodeURIComponent(userRole.Role)}`,
            userRole
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_USER_ROLE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeUserRole(userRole) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/UserRole?Role=${encodeURIComponent(userRole.Role)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_USER_ROLE,
                    data: userRole
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeUserRoles(userRoleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().userRolesApp.userRoles;

        const request = axios.post('/api/user-roles-app/remove-userRoles', {
            userRoleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_USER_ROLES
                }),
                dispatch({
                    type: DESELECT_ALL_USER_ROLES
                })
            ]).then(() => dispatch(getUserRoles(routeParams)))
        );
    };
}

export function toggleStarredUserRole(userRoleId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().userRolesApp.userRoles;

        const request = axios.post('/api/user-roles-app/toggle-starred-userRole', {
            userRoleId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_USER_ROLE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUserRoles(routeParams)))
        );
    };
}

export function toggleStarredUserRoles(userRoleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().userRolesApp.userRoles;

        const request = axios.post('/api/user-roles-app/toggle-starred-userRoles', {
            userRoleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_USER_ROLES
                }),
                dispatch({
                    type: DESELECT_ALL_USER_ROLES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUserRoles(routeParams)))
        );
    };
}

export function setUserRolesStarred(userRoleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().userRolesApp.userRoles;

        const request = axios.post('/api/user-roles-app/set-userRoles-starred', {
            userRoleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_USER_ROLES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_USER_ROLES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUserRoles(routeParams)))
        );
    };
}

export function setUserRolesUnstarred(userRoleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().userRolesApp.userRoles;

        const request = axios.post('/api/user-roles-app/set-userRoles-unstarred', {
            userRoleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_USER_ROLES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_USER_ROLES
                }),
                dispatch(getUserData())
            ])
        );
    };
}


export function addUserRoleSecurable(newSecurable) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.post(`${window["apiLocation"]}/api/UserRoleSecurable`,
            newSecurable
        );

        return request.then((response) => {
            const { Data } = data;
            Data.Securables.push(response.data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                        Data,
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role Access has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            });
        });
    };
}

export function updateUserRoleSecurable(securable) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.put(`${window["apiLocation"]}/api/UserRoleSecurable?Role=${encodeURIComponent(securable.Role)}&Securable=${encodeURIComponent(securable.Securable)}`,
            securable
        );

        return request.then((response) => {
            // const { Data } = data;
            // const index = _.findIndex(Data.Securables, { ID: response.data.ID });
            // Data.Securables.splice(index, 1, response.Data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role Access has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeUserRoleSecurable(securable) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/UserRoleSecurable?Role=${encodeURIComponent(securable.Role)}&Securable=${encodeURIComponent(securable.Securable)}`);

        return request.then((response) => {
            const { Data } = data;
            const index = _.findIndex(Data.Securables, { ID: response.data.ID });
            Data.Securables.splice(index, 1);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                        Data
                    }
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role Access has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}


export function addUserRoleAssignment(newAssignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data, open } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.post(`${window["apiLocation"]}/api/UserRoleAssignment`,
            newAssignment
        );

        return request.then((response) => {
            if (open) {
                const { Data } = data;
                Data.Users.push(response.data);
                Promise.all([
                    dispatch({
                        type: UPDATE_EDIT_USER_ROLE_DIALOG,
                        data: {
                            ...data,
                            Data,
                        }
                    })
                ]).then(() => {
                    if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                        dispatch(showMessage({
                            message: `Error: ${response.data.Data.ErrMsg}`,
                            autoHideDuration: 30000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                    } else {
                        dispatch(showMessage({
                            message: `User Role Access has been successfully added.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                    }
                });
            } else {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role Access has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            }
        });
    };
}

export function removeUserRoleAssignment(assignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data, open } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/UserRoleAssignment?Co=${assignment.Co}&Role=${encodeURIComponent(assignment.Role)}&User=${encodeURIComponent(assignment.UserName)}`);

        return request.then((response) => {
            if (open) {
                const { Data } = data;
                const index = _.findIndex(Data.Users, { ID: assignment.ID });
                Data.Users.splice(index, 1);
                Promise.all([
                    dispatch({
                        type: UPDATE_EDIT_USER_ROLE_DIALOG,
                        data: {
                            ...data,
                            Data
                        }
                    })
                ]).then(() => {
                    if (response.data && response.data.length > 0) {
                        dispatch(showMessage({
                            message: `Error: ${response.data}`,
                            autoHideDuration: 30000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                    } else {
                        dispatch(showMessage({
                            message: `User Role Access has been successfully deleted.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                    }
                })
            } else {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `User Role Access has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            }
        });
    };
}




export function addUserRoleReport(newReport) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.post(`${window["apiLocation"]}/api/UserRoleReportSecurable`,
            newReport
        );

        return request.then((response) => {
            const { Data } = data;
            Data.Reports.push(response.data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                        Data,
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Report Access has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            });
        });
    };
}

export function updateUserRoleReport(report) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.put(`${window["apiLocation"]}/api/UserRoleReportSecurable?Role=${encodeURIComponent(report.Role)}&Report=${encodeURIComponent(report.Report)}`,
            report
        );

        return request.then((response) => {
            // const { Data } = data;
            // const index = _.findIndex(Data.Reports, { ID: response.data.ID });
            // Data.Reports.splice(index, 1, response.Data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Report Access has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeUserRoleReport(report) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/UserRoleReportSecurable?Role=${encodeURIComponent(report.Role)}&Report=${encodeURIComponent(report.Report)}`);

        return request.then((response) => {
            const { Data } = data;
            const index = _.findIndex(Data.Reports, { ID: response.data.ID });
            Data.Reports.splice(index, 1);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                        Data
                    }
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Report Access has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}


export function addUserRoleDashboard(newDashboard) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.post(`${window["apiLocation"]}/api/UserRoleDashboardSecurable`,
            newDashboard
        );

        return request.then((response) => {
            const { Data } = data;
            Data.Dashboards.push(response.data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                        Data,
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dashboard Access has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            });
        });
    };
}

export function updateUserRoleDashboard(dashboard) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.put(`${window["apiLocation"]}/api/UserRoleDashboardSecurable?Role=${encodeURIComponent(dashboard.Role)}&Dashboard=${encodeURIComponent(dashboard.Dashboard)}`,
            dashboard
        );

        return request.then((response) => {
            // const { Data } = data;
            // const index = _.findIndex(Data.Dashboards, { ID: response.data.ID });
            // Data.Dashboards.splice(index, 1, response.Data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                    }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dashboard Access has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeUserRoleDashboard(dashboard) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.userRolesApp.userRoles.userRoleDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/UserRoleDashboardSecurable?Role=${encodeURIComponent(dashboard.Role)}&Dashboard=${encodeURIComponent(dashboard.Dashboard)}`);

        return request.then((response) => {
            const { Data } = data;
            const index = _.findIndex(Data.Dashboards, { ID: response.data.ID });
            Data.Dashboards.splice(index, 1);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_USER_ROLE_DIALOG,
                    data: {
                        ...data,
                        Data
                    }
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dashboard Access has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}