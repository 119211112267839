import { FuseAnimate } from '@fuse';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MailCompose from 'main/content/apps/mail/MailCompose';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetReducers, setError } from 'store/actions';
import _ from '@lodash';
import JSONL from 'json-literal';
import { openSupportRequestDialog } from 'main/content/apps/support/requests/store/actions';
import SupportRequestDialog from 'main/content/apps/support/requests/board/dialogs/card/SupportRequestDialog';
import { guid, keepIntact, testAppErrors } from 'store/actions';
import DebounceButton from '../../../components/debounce-button/DebounceButton';

const styles = theme => ({
    root: {
        background: '#fff',
        color: '#3f3f3f',
    },
    searchWrapper: {
        width: '100%',
        height: 56,
        padding: 18,
        display: 'flex',
        alignItems: 'center'
    },
    search: {
        paddingLeft: 16
    },
});

class StackErrorPage extends Component {

    state = {
        sendEmail: null,
        submitted: false,
        reloadOnReset: false
    }

    componentDidMount() {
        const { exception, error, setError, keepIntact } = this.props;
        if (exception && !error) {
            setError(exception);
        }
        this.setState({ submitted: false, reloadOnReset: keepIntact });
    }

    launchEmail = () => {
        const { User, companies, error, img } = this.props;
        const { Co, List } = companies;
        const co = _.find(List, { Co });
        const attch = [];
        if (img) {
            attch.push(img);
        }
        const info = {
            composeDialog: true,
            Title: 'Bug Report',
            Icon: "email",
            To: `support@servicepointpro.com`,
            Subject: `New Bug Report from ${User.FirstName} ${User.LastName}${co && co.Data && co.Data.Co ? ` at ${co.Data.Co.Name}` : ''}`,
            Message: '',
            Body: null,
            Attachments: attch,
            disableTo: true,
            disableSubject: true,
        }
        this.setState({ ...this.state, sendEmail: info, });
    }

    startSupportRequest = () => {
        const { img, error, noReport } = this.props;
        const stateData = _.cloneDeepWith(this.props.storeData);
        stateData.spAuth.authentication.access_token = null;
        stateData.spAuth.authentication.refresh_token = null;
        stateData.spAuth.vault.vault = null;
        const ClientID = guid();
        const attachments = [];
        if (img) {
            attachments.push(img);
        }
        this.props.openSupportRequestDialog({
            ID: 0,
            ClientID,
            UserName: this.props.User.UserName,
            Title: `New Bug Report from ${this.props.User.FirstName} ${this.props.User.LastName} at ${this.props.about.Company}`,
            Description: null,
            AddedDate: new Date(),
            Event: JSONL.stringify({ error: { message: error.message, stack: error.stack, location: window.location.href, sourceURL: error.sourceURL, column: error.column, line: error.line }, data: !noReport ? stateData : null }),
            Data: { Attachments: [], Tags: [{ ClientID, SupportRequestID: 0, Tag: "bug", AddedBy: this.props.User.UserName, AddedDate: new Date(), Data: { ErrMsg: null } }], Assignees: [], Comments: [], Checklists: [], ErrMsg: null },
            attachments
        })
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { error, exception, onReset } = this.props;
    //     if (prevProps.exception && exception && prevProps.error && !error) {
    //         onReset();
    //     }
    // }


    render() {
        const { classes, error, exception, onReset, snip, resetReducers, noReport, about } = this.props;
        const { sendEmail, reloadOnReset } = this.state;
        const isSupportProvider = about.SupportProvider

        return (
            <div className={classNames(classes.root, "flex flex-col flex-1 items-center justify-center p-16")}>

                <div className="max-w-512 text-center">

                    <FuseAnimate animation="transition.expandIn" delay={100}>
                        <Typography onClick={isSupportProvider ? this.props.testAppErrors : undefined} variant="h2" color="inherit" className="font-medium mb-16">
                            OOPS!!!
                        </Typography>
                    </FuseAnimate>

                    <FuseAnimate delay={200}>
                        <Typography variant="h5" color="textSecondary" className="mb-16">
                            It looks like you broke the internet...
                        </Typography>
                    </FuseAnimate>

                    <FuseAnimate delay={300}>
                        <Typography component="div" variant="subtitle1" color="textSecondary" className="mb-24">
                            Just kidding, it seems we have an internal issue - rest assured we're looking into it!
                        </Typography>
                    </FuseAnimate>
                    <div className="w-full justify-center">
                        {!this.state.submitted &&
                            <Button
                                className="mb-12 w-full"
                                variant="contained"
                                color="primary"
                                // onClick={this.launchEmail}
                                onClick={this.startSupportRequest}
                            >
                                Submit Bug Report
                            </Button>
                        }
                        {noReport &&
                            <SupportRequestDialog onSubmitted={() => this.setState({ submitted: true })} isSupportProvider={this.props.about.SupportProvider} />
                        }
                        <Button
                            className="w-full"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (reloadOnReset) {
                                    window.location.reload();
                                } else {
                                    Promise.all([
                                        resetReducers(),
                                    ]).then(() => {
                                        onReset();
                                        if (!noReport) {
                                            this.props.setKeepIntact(false);
                                        }
                                    });
                                }
                            }}
                        >
                            {reloadOnReset ? 'Reload Application' : 'Reset Application State'}
                        </Button>
                    </div>
                    {/* {sendEmail &&
                        <MailCompose onClose={() => this.setState({ ...this.state, sendEmail: null })} hideButton={true} data={sendEmail} />
                    } */}
                    {exception &&
                        <Typography component="div" variant="caption" color="textSecondary" className="mt-24">
                            {exception.message}
                        </Typography>
                    }
                    {snip &&
                        <img className="w-full mt-8" src={snip} />
                    }
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        resetReducers,
        setError,
        openSupportRequestDialog,
        setKeepIntact: keepIntact,
        testAppErrors
    }, dispatch);
}

function mapStateToProps(state) {
    const { spReducers, support, version } = state;
    return {
        storeData: state,
        User: spReducers.userProfiles.User,
        companies: spReducers.companies,
        error: support.error,
        img: support.img,
        keepIntact: support.keepIntact,
        about: version.about || {},
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(StackErrorPage));
