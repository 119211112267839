import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { bindActionCreators } from 'redux';
import ServiceUnitPartDialog from './ServiceUnitPartDialog';
import * as Actions from './store/actions';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class ServiceUnitPartsList extends Component {

    state = {
        selectedServiceUnitPartsMenu: null
    };

    render() {
        const { classes, searchText, openNewServiceUnitPartDialog, openEditServiceUnitPartDialog, serviceUnit, parts, unitTypes } = this.props;
        const data = parts;
        const { UnitCat, UnitType } = serviceUnit;
        const unitType = _.find(unitTypes, { UnitType, UnitCat });
        const partTypes = unitType ? unitType.Data.PartTypes : [];

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewServiceUnitPartDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <ServiceUnitPartDialog serviceUnit={serviceUnit} />
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">settings</Icon>Part Types</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        openEditServiceUnitPartDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Type",
                                id: "part-type",
                                accessor: row => {
                                    window["warn"](row, partTypes);
                                    const type = _.find(partTypes, { PartType: row.PartType });
                                    if (type) {
                                        return type.Name;
                                    } else {
                                        return row.PartType
                                    }
                                },
                                className: "font-bold justify-center",
                                width: 96,
                            },
                            {
                                Header: "Qty",
                                accessor: "Qty",
                                className: "justify-center",
                                width: 64,
                            },
                            {
                                Header: "UOM",
                                accessor: "UOM",
                                className: "justify-center",
                                width: 64,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256
                            },
                            {
                                Header: "Material",
                                accessor: "Material",
                                className: "justify-center",
                                width: 96,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Parts found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditServiceUnitPartDialog: Actions.openEditServiceUnitPartDialog,
        openNewServiceUnitPartDialog: Actions.openNewServiceUnitPartDialog
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        unitTypes: spReducers.serviceUnitTypes
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitPartsList)));
