import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedVendorIds: [],
    routeParams: {},
    vendorDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            Vendor: null,
            Vendor: null,
            Description: null,
            MobileYN: 'N',
            FleetVehicle: null,
            Address1: null,
            Address2: null,
            City: null,
            State: null,
            Zip: null,
            Country: 'US',
            CostGLAccount: null,
            SalesGLAccount: null,
            AdjustmentGLAccount: null,
            ActiveYN: 'Y',
            Notes: null,
            AttachmentID: null,
            Data: {
                Materials: [],
                ErrMsg: null,
            },
            vendorMaterial: {
                ID: null,
                Co: null,
                Vendor: null, 
                Material: null,
                VendorMaterial: null,
                Cost: null,
                AddedBy: null,
                AddedDate: null,
                UpdatedBy: null,
                UpdatedDate: null,
                Notes: null,
                AttachmentID: null,
                Data: {
                    Material: null
                },
            },
        }
    }
};

const vendorsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_VENDORS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_VENDORS:
            {

                const vendorId = action.vendorId;

                let selectedVendorIds = [...state.selectedVendorIds];

                if (selectedVendorIds.find(id => id === vendorId) !== undefined) {
                    selectedVendorIds = selectedVendorIds.filter(id => id !== vendorId);
                }
                else {
                    selectedVendorIds = [...selectedVendorIds, vendorId];
                }

                return {
                    ...state,
                    selectedVendorIds: selectedVendorIds
                };
            }
        case Actions.SELECT_ALL_VENDORS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedVendorIds = arr.map(vendors => vendors.id);

                return {
                    ...state,
                    selectedVendorIds: selectedVendorIds
                };
            }
        case Actions.DESELECT_ALL_VENDORS:
            {
                return {
                    ...state,
                    selectedVendorIds: []
                };
            }
        case Actions.OPEN_NEW_VENDOR_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    vendorDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.vendorDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_VENDOR_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_VENDOR_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    vendorDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.vendorDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_VENDOR_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    vendorDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.vendorDialog.data,
                            ...data,
                        }
                    }
                };
            }
            case Actions.ADD_VENDOR_MATERIAL:
                {
                    return {
                        ...state,
                        vendorDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: {
                                ...state.vendorDialog.data,
                                vendorMaterial: {
                                    ID: null,
                                    Co: null,
                                    Vendor: null, 
                                    Material: null,
                                    VendorMaterial: null,
                                    Cost: null,
                                    AddedBy: null,
                                    AddedDate: null,
                                    UpdatedBy: null,
                                    UpdatedDate: null,
                                    Notes: null,
                                    AttachmentID: null,
                                    Data: {
                                        Material: null
                                    },
                                },
                            }
                        }
                    }
                }
            case Actions.UPDATE_VENDOR_MATERIAL:
                {
                    return {
                        ...state,
                        vendorDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: {
                                ...state.vendorDialog.data,
                                vendorMaterial: {
                                    ID: null,
                                    Co: null,
                                    Vendor: null, 
                                    Material: null,
                                    VendorMaterial: null,
                                    Cost: null,
                                    AddedBy: null,
                                    AddedDate: null,
                                    UpdatedBy: null,
                                    UpdatedDate: null,
                                    Notes: null,
                                    AttachmentID: null,
                                    Data: {
                                        Material: null
                                    },
                                },
                            }
                        }
                    }
                }
            case Actions.REMOVE_VENDOR_MATERIAL:
                {
                    return {
                        ...state,
                        vendorDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: {
                                ...state.vendorDialog.data,
                                vendorMaterial: {
                                    ID: null,
                                    Co: null,
                                    Vendor: null, 
                                    Material: null,
                                    VendorMaterial: null,
                                    Cost: null,
                                    AddedBy: null,
                                    AddedDate: null,
                                    UpdatedBy: null,
                                    UpdatedDate: null,
                                    Notes: null,
                                    AttachmentID: null,
                                    Data: {
                                        Material: null
                                    },
                                },
                            }
                        }
                    }
                }
        case Actions.CLOSE_EDIT_VENDOR_DIALOG:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
};

export default vendorsReducer;
