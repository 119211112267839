import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    Templates: [],
    Tags: [],
}

const templatesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TEMPLATES:
            {

                return {
                    ...state,
                    Templates: action.payload,
                };
            }
        case Actions.NEW_TEMPLATE:
            {
                return {
                    ...state,
                    Templates: [
                        ...state.Templates,
                        action.template
                    ],
                }
            }
        case Actions.RENAME_TEMPLATE:
            {
                const index = state.Templates.findIndex((x) => x.ID === action.payload.ID);
                state.Templates[index] = action.payload;
                return {
                    ...state,
                }
            }
        case Actions.RESET_TEMPLATES:
            {
                return {
                    ...state,
                    Templates: [],
                }
            }
        case Actions.DELETE_TEMPLATE:
            {
                return {
                    ...state,
                    Templates: _.reject(state.Templates, { ID: action.payload.ID }),
                }
            }
        default:
            return state;
    }
};

export default templatesReducer;
