import { fuseDark } from '@fuse/fuse-colors';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';

export const fuseThemesConfig = {
    pro: {
        palette: {
            type: 'light',
            primary: {
                light: '#87efff',
                main: '#4dbce9',
                dark: '#4dbce9',
                contrastText: '#fff',
                contrastBg: '#45ccff',
            },
            secondary: {
                light: '#ffff83',
                main: '#d1e751',
                dark: '#9db516',
                contrastText: '#333'
            },
            error: red
        },
        status: {
            danger: 'orange',
            alert: 'rgb(210, 100, 100)',
            ok: '#9db516',
        }
    },
    classic: {
        palette: {
            type: 'light',
            primary: {
                ...fuseDark,
                contrastText: '#fff',
                contrastBg: '#353b40',
            },
            secondary: {
                light: lightBlue[400],
                main: lightBlue[600],
                dark: lightBlue[700]
            },
            error: red
        },
        status: {
            danger: 'orange',
            alert: 'rgb(210, 100, 100)',
            ok: '#9db516',
        }
    },
    greeny: {
        palette: {
            type: 'light',
            primary: {
                light: '#6cabd4',
                main: '#387ca3',
                dark: '#005074',
                contrastText: '#fff',
                contrastBg: '#3f84b5',
            },
            secondary: {
                light: '#89f6cf',
                main: '#55c39e',
                dark: '#159270',
                contrastText: '#fff'
            },
            error: red
        },
        status: {
            danger: 'orange',
            alert: 'rgb(210, 100, 100)',
            ok: '#9db516',
        }
    },
    gulfShore: {
        palette: {
            type: 'light',
            primary: {
                light: '#94bdd4',
                main: '#80a6bb',
                dark: '#7293a5',
                contrastText: '#fff',
                contrastBg: '#78a0b7',
            },
            secondary: {
                light: '#fbecca',
                main: '#dec690',
                dark: '#bdac84',
                contrastText: '#333'
            },
            error: red
        },
        status: {
            danger: 'orange',
            alert: 'rgb(210, 100, 100)',
            ok: '#9db516',
        }
    },
    proDark: {
       palette: {
           type: 'dark',
           primary: {
               light: '#87efff',
               main: '#4dbce9',
               dark: '#4dbce9',
               contrastText: '#fff',
               contrastBg: '#45ccff',
           },
           secondary: {
               light: '#ffff83',
               main: '#d1e751',
               dark: '#9db516',
               contrastText: '#333'
           },
           error: red
       },
       status: {
           danger: 'orange',
           alert: 'rgb(210, 100, 100)',
           ok: '#9db516',
       },
       status: {
           danger: 'orange',
           alert: 'rgb(210, 100, 100)',
           ok: '#9db516',
       }
    },
    classicDark: {
       palette: {
           type: 'dark',
           primary: {
               ...fuseDark,
               contrastText: '#fff',
               contrastBg: '#353b40',
           },
           secondary: {
               light: lightBlue[400],
               main: lightBlue[600],
               dark: lightBlue[700]
           },
           error: red
       },
       status: {
           danger: 'orange',
           alert: 'rgb(210, 100, 100)',
           ok: '#9db516',
       }
    },
    // deepOcean: {
    //    palette: {
    //        type: 'dark',
    //        primary: {
    //            light: '#8f53e7',
    //            main: '#5a24b4',
    //            dark: '#1e0083',
    //            contrastText: '#fff',
    //        },
    //        secondary: {
    //            light: '#ff61ff',
    //            main: '#fe00e9',
    //            dark: '#c600b6',
    //            contrastText: '#fff'
    //        },
    //        error: red
    //    },
    //    status: {
    //        danger: 'orange',
    //        alert: 'rgb(210, 100, 100)',
    //        ok: '#9db516',
    //    }
    // },
    slate: {
       palette: {
           type: 'dark',
           primary: {
               light: '#86fff7',
               main: '#4ecdc4',
               dark: '#009b94',
               contrastText: '#fff',
           },
           secondary: {
               light: '#ff9d99',
               main: '#ff6b6b',
               dark: '#c73840',
               contrastText: '#fff'
           },
           error: red
       },
       status: {
           danger: 'orange',
           alert: 'rgb(210, 100, 100)',
           ok: '#9db516',
       }
    },
};
