import axios from 'axios';
import { getUserData } from 'main/content/apps/purchase-order-lines/store/actions/user.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';

export const GET_PURCHASE_ORDER_LINES = '[PURCHASE ORDER LINES APP] GET PURCHASE ORDER LINES';
export const SET_SEARCH_TEXT = '[PURCHASE ORDER LINES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_PURCHASE_ORDER_LINES = '[PURCHASE ORDER LINES APP] TOGGLE IN SELECTED PURCHASE ORDER LINES';
export const SELECT_ALL_PURCHASE_ORDER_LINES = '[PURCHASE ORDER LINES APP] SELECT ALL PURCHASE ORDER LINES';
export const DESELECT_ALL_PURCHASE_ORDER_LINES = '[PURCHASE ORDER LINES APP] DESELECT ALL PURCHASE ORDER LINES';
export const OPEN_NEW_PURCHASE_ORDER_LINE_DIALOG = '[PURCHASE ORDER LINES APP] OPEN NEW PURCHASE ORDER LINE DIALOG';
export const CLOSE_NEW_PURCHASE_ORDER_LINE_DIALOG = '[PURCHASE ORDER LINES APP] CLOSE NEW PURCHASE ORDER LINE DIALOG';
export const OPEN_EDIT_PURCHASE_ORDER_LINE_DIALOG = '[PURCHASE ORDER LINES APP] OPEN EDIT PURCHASE ORDER LINE DIALOG';
export const UPDATE_EDIT_PURCHASE_ORDER_LINE_DIALOG = '[PURCHASE ORDER LINES APP] UPDATE EDIT PURCHASE ORDER LINE DIALOG';
export const CLOSE_EDIT_PURCHASE_ORDER_LINE_DIALOG = '[PURCHASE ORDER LINES APP] CLOSE EDIT PURCHASE ORDER LINE DIALOG';
export const ADD_PURCHASE_ORDER_LINE = '[PURCHASE ORDER LINES APP] ADD PURCHASE ORDER LINE';
export const UPDATE_PURCHASE_ORDER_LINE = '[PURCHASE ORDER LINES APP] UPDATE PURCHASE ORDER LINE';
export const REMOVE_PURCHASE_ORDER_LINE = '[PURCHASE ORDER LINES APP] REMOVE PURCHASE ORDER LINE';
export const ADD_PURCHASE_ORDER_RECEIVING = '[PURCHASE ORDER LINES APP] ADD PURCHASE ORDER RECEIVING';
export const UPDATE_PURCHASE_ORDER_RECEIVING = '[PURCHASE ORDER LINES APP] UPDATE PURCHASE ORDER RECEIVING';
export const REMOVE_PURCHASE_ORDER_RECEIVING = '[PURCHASE ORDER LINES APP] REMOVE PURCHASE ORDER RECEIVING';
export const REMOVE_PURCHASE_ORDER_LINES = '[PURCHASE ORDER LINES APP] REMOVE PURCHASE ORDER LINES';
export const TOGGLE_STARRED_PURCHASE_ORDER_LINE = '[PURCHASE ORDER LINES APP] TOGGLE STARRED PURCHASE ORDER LINE';
export const TOGGLE_STARRED_PURCHASE_ORDER_LINES = '[PURCHASE ORDER LINES APP] TOGGLE STARRED PURCHASE ORDER LINES';
export const SET_PURCHASE_ORDER_LINES_STARRED = '[PURCHASE ORDER LINES APP] SET PURCHASE ORDER LINES STARRED ';

export function getPurchaseOrderLines(routeParams) {
    const request = axios.get('/api/purchase-order-lines-app/purchase-order-lines', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_PURCHASE_ORDER_LINES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedPurchaseOrderLines(purchaseOrderLineId) {
    return {
        type: TOGGLE_IN_SELECTED_PURCHASE_ORDER_LINES,
        purchaseOrderLineId
    }
}


export function selectAllPurchaseOrderLines() {
    return {
        type: SELECT_ALL_PURCHASE_ORDER_LINES
    }
}

export function deSelectAllPurchaseOrderLines() {
    return {
        type: DESELECT_ALL_PURCHASE_ORDER_LINES
    }
}


export function openNewPurchaseOrderLineDialog(data) {
    return {
        type: OPEN_NEW_PURCHASE_ORDER_LINE_DIALOG,
        data
    }
}

export function closeNewPurchaseOrderLineDialog() {
    return {
        type: CLOSE_NEW_PURCHASE_ORDER_LINE_DIALOG
    }
}

export function openPreloadPurchaseOrderLineDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_PURCHASE_ORDER_LINE_DIALOG,
            data: data,
        })
    }
}

export function openEditPurchaseOrderLineDialog(data, readOnly) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/PurchaseOrderLine?Co=${data.Co}&PO=${encodeURIComponent(data.PO)}&Line=${encodeURIComponent(data.POLine)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_PURCHASE_ORDER_LINE_DIALOG,
                data: response.data,
                readOnly
            })
        }).catch((e) => {
            dispatch({
                type: OPEN_EDIT_PURCHASE_ORDER_LINE_DIALOG,
                data: data,
                readOnly
            });
            dispatch(showMessage({
                message: `PO Line loaded from offline storage`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'offline'
            }));
        });
    }
}

export function updateEditPurchaseOrderLineDialog(data) {
    return {
        type: UPDATE_EDIT_PURCHASE_ORDER_LINE_DIALOG,
        data
    }
}

export function closeEditPurchaseOrderLineDialog() {
    return {
        type: CLOSE_EDIT_PURCHASE_ORDER_LINE_DIALOG
    }
}

export function addPurchaseOrderLine(newPurchaseOrderLine) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/PurchaseOrderLine`;
        const request = axios.post(url,
            newPurchaseOrderLine
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_PURCHASE_ORDER_LINE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Purchase Order Line #${response.data.POLine} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
            ).catch((error) => {
                console.warn('Error adding purchase order line: ', error, request);
                if (error.message) {
                    dispatch(showMessage({
                        message: `${error.message}`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                }
                if (error.request) {
                    const offlineData = { ...newPurchaseOrderLine, OfflineID: guid() };
                    Promise.all([
                        dispatch(setOfflineData('ADD', 'post', url, offlineData, 'purchase-order-lines')),
                    ]).then(() => {
                        dispatch({
                            type: ADD_PURCHASE_ORDER_LINE,
                            data: offlineData
                        })
                    })
                }
            });
    };
}

export function updatePurchaseOrderLine(purchaseOrderLine) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/PurchaseOrderLine?Co=${encodeURIComponent(purchaseOrderLine.Co)}&PO=${encodeURIComponent(purchaseOrderLine.PO)}&Line=${encodeURIComponent(purchaseOrderLine.POLine)}`,
            purchaseOrderLine
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_PURCHASE_ORDER_LINE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Purchase Order Line #${purchaseOrderLine.POLine} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removePurchaseOrderLine(purchaseOrderLine) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/PurchaseOrderLine?Co=${encodeURIComponent(purchaseOrderLine.Co)}&PO=${encodeURIComponent(purchaseOrderLine.PO)}&Line=${encodeURIComponent(purchaseOrderLine.POLine)}`,
            purchaseOrderLine
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_PURCHASE_ORDER_LINE,
                    data: purchaseOrderLine
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Purchase Order Line #${purchaseOrderLine.POLine} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function addPurchaseOrderReceiving(newPurchaseOrderReceiving) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/PurchaseOrderReceiving`,
            newPurchaseOrderReceiving
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_PURCHASE_ORDER_RECEIVING,
                    data: response.data
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Purchase Order Receiving Line has been successfully added.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                // dispatch(updateEditPurchaseOrderReceivingDialog(response.data));
            })
        );
    };
}

export function updatePurchaseOrderReceiving(purchaseOrderReceiving) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/PurchaseOrderReceiving?Co=${encodeURIComponent(purchaseOrderReceiving.Co)}&PO=${encodeURIComponent(purchaseOrderReceiving.PO)}&Line=${encodeURIComponent(purchaseOrderReceiving.POLine)}&Seq=${purchaseOrderReceiving.Seq}`,
            purchaseOrderReceiving
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_PURCHASE_ORDER_RECEIVING,
                    data: response.data
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Purchase Order Receiving Line has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function removePurchaseOrderReceiving(purchaseOrderReceiving) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/PurchaseOrderReceiving?Co=${encodeURIComponent(purchaseOrderReceiving.Co)}&PO=${encodeURIComponent(purchaseOrderReceiving.PO)}&Line=${encodeURIComponent(purchaseOrderReceiving.POLine)}&Seq=${purchaseOrderReceiving.Seq}`,
            purchaseOrderReceiving
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_PURCHASE_ORDER_RECEIVING,
                    data: purchaseOrderReceiving
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Purchase Order Receiving Line has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}


export function removePurchaseOrderLines(purchaseOrderLineIds) {

}

export function toggleStarredPurchaseOrderLine(purchaseOrderLineId) {

}

export function toggleStarredPurchaseOrderLines(purchaseOrderLineIds) {

}

export function setPurchaseOrderLinesStarred(purchaseOrderLineIds) {

}

export function setPurchaseOrderLinesUnstarred(purchaseOrderLineIds) {

}
