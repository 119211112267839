import { AnalyticsDashboardAppConfig } from './dashboards/analytics/AnalyticsDashboardAppConfig';
import { MailAppConfig } from './mail/MailAppConfig';
import { TodoAppConfig } from './todo/TodoAppConfig';
import { ContactsAppConfig } from './contacts/ContactsAppConfig';
import { CustomersAppConfig } from './customers/CustomersAppConfig';
import { CallHandlerAppConfig } from './call-handler/CallHandlerAppConfig';
import { DispatchBoardAppConfig } from './dispatch-board/DispatchBoardAppConfig';
import { CustomerSitesAppConfig } from './customer-sites/CustomerSitesAppConfig';
import { FileManagerAppConfig } from './file-manager/FileManagerAppConfig';
import { CalendarAppConfig } from './calendar/CalendarAppConfig';
import { ChatAppConfig } from "./chat/ChatAppConfig";
import { SupervisorAppConfig } from "./supervisor/SupervisorAppConfig";
import { EstimatesAppConfig } from "./estimates/EstimatesAppConfig";
import { ReportingAppConfig } from "./reporting/ReportingAppConfig";
import { EmailTemplateAppConfig } from './email-templates/EmailTemplateAppConfig';
import { DashboardBuilderAppConfig } from './dashboard-builder/DashboardBuilderAppConfig';
import { CompaniesAppConfig } from "./companies/CompaniesAppConfig";
import { FormsAppConfig } from "./forms/FormsAppConfig";
import { VoiceMailboxesAppConfig } from "./voice-mailboxes/VoiceMailboxesAppConfig";
import { SupportAppConfig } from "./support/SupportAppConfig";
import { ChecklistsAppConfig } from "./checklists/ChecklistsAppConfig";
import { PaymentsAppConfig } from './payments/PaymentsAppConfig';
import { ECommerceAppConfig } from './e-commerce/ECommerceAppConfig';
import { ScrumboardAppConfig } from './scrumboard/ScrumboardAppConfig';
import { ServicesAppConfig } from './services/ServicesAppConfig';
import { TechnicianAppConfig } from './technician/TechnicianAppConfig';
import { TechniciansAppConfig } from './technicians/TechniciansAppConfig';
import { TeamsAppConfig } from './teams/TeamsAppConfig';
import { TimecardsAppConfig } from './timecards/TimecardsAppConfig';
import { EmployeesAppConfig } from './employees/EmployeesAppConfig';
import { OptionBoardTypesAppConfig } from './option-board-types/OptionBoardTypesAppConfig';
import { InventoryLocationMaterialsAppConfig } from './inventory-location-materials/InventoryLocationMaterialsAppConfig';
import { AuditPostingAppConfig } from './audit-posting/AuditPostingAppConfig';
import { UsersAppConfig } from './users/UsersAppConfig';
import { UserRolesAppConfig } from './user-roles/UserRolesAppConfig';
import { ServiceUnitCategoriesAppConfig } from './service-unit-categories/ServiceUnitCategoriesAppConfig';
import { ServiceUnitTypesAppConfig } from './service-unit-types/ServiceUnitTypesAppConfig';
import { ServiceUnitsAppConfig } from './service-units/ServiceUnitsAppConfig';
import { VendorsAppConfig } from './vendors/VendorsAppConfig';
import { ServiceTypesAppConfig } from './service-types/ServiceTypesAppConfig';
import { ArrivalWindowsAppConfig } from './arrival-windows/ArrivalWindowsAppConfig';
import { RateTemplatesAppConfig } from './rate-templates/RateTemplatesAppConfig';
import { LaborTypesAppConfig } from './labor-types/LaborTypesAppConfig';
import { CostTypesAppConfig } from './cost-types/CostTypesAppConfig';
import { MaterialCategoriesAppConfig } from './material-categories/MaterialCategoriesAppConfig';
import { MaterialsAppConfig } from './materials/MaterialsAppConfig';
import { FleetVehicleCategoriesAppConfig } from './fleet-vehicle-categories/FleetVehicleCategoriesAppConfig';
import { FleetVehiclesAppConfig } from './fleet-vehicles/FleetVehiclesAppConfig';
import { InventoryLocationGroupsAppConfig } from './inventory-location-groups/InventoryLocationGroupsAppConfig';
import { InventoryLocationsAppConfig } from './inventory-locations/InventoryLocationsAppConfig';
import { GLAccountsAppConfig } from './gl-accounts/GLAccountsAppConfig';
import { TaxCodesAppConfig } from './tax-codes/TaxCodesAppConfig';
import { FormOfPaymentsAppConfig } from './form-of-payments/FormOfPaymentsAppConfig';
import { PayTermsAppConfig } from './pay-terms/PayTermsAppConfig';
import { AgreementsAppConfig } from './agreements/AgreementsAppConfig';
import { AgreementTypesAppConfig } from './agreement-types/AgreementTypesAppConfig';
import { AgreementTypeTasksAppConfig } from './agreement-type-tasks/AgreementTypeTasksAppConfig';
import { BusinessUnitsAppConfig } from './business-units/BusinessUnitsAppConfig';
import { DepartmentsAppConfig } from './departments/DepartmentsAppConfig';
import { DivisionsAppConfig } from './divisions/DivisionsAppConfig';
import { PhoneNumbersAppConfig } from './phone-numbers/PhoneNumbersAppConfig';
import { PurchaseOrdersAppConfig } from './purchase-orders/PurchaseOrdersAppConfig';
import { PurchaseOrderLinesAppConfig } from './purchase-order-lines/PurchaseOrderLinesAppConfig';
import { CallGathersAppConfig } from './call-gathers/CallGathersAppConfig';
import { HuntGroupsAppConfig } from './hunt-groups/HuntGroupsAppConfig';
import { DialGroupsAppConfig } from './dial-groups/DialGroupsAppConfig';
import { DialGroupMembersAppConfig } from './dial-group-members/DialGroupMembersAppConfig';
import { CallQueuesAppConfig } from './call-queues/CallQueuesAppConfig';
import { TextGathersAppConfig } from './text-gathers/TextGathersAppConfig';
import { TextGatherPhrasesAppConfig } from './text-gather-phrases/TextGatherPhrasesAppConfig';
import { CallGatherKeysAppConfig } from './call-gather-keys/CallGatherKeysAppConfig';
import { YouTubeAppConfig } from './youtube/YouTubeAppConfig';
import { WorkOrdersAppConfig } from './work-orders/WorkOrdersAppConfig';
import { TripsAppConfig } from './trips/TripsAppConfig';
import { AcademyAppConfig } from './academy/AcademyAppConfig';
import { CatalogAppConfig } from './catalog/CatalogAppConfig';
import { InvoicesAppConfig } from './invoices/InvoicesAppConfig';
import { WorkOrderScopesAppConfig } from './work-order-scopes/WorkOrderScopesAppConfig';
import { WorkOrderUsagesAppConfig } from './work-order-usages/WorkOrderUsagesAppConfig';
import { UOMsAppConfig } from './units-of-measure/UOMsAppConfig';

export const appsConfigs = [
    AnalyticsDashboardAppConfig,
    MailAppConfig,
    TodoAppConfig,
    AcademyAppConfig,
    CatalogAppConfig,
    FormsAppConfig,
    ChecklistsAppConfig,
    OptionBoardTypesAppConfig,
    CompaniesAppConfig,
    // FileManagerAppConfig,
    AuditPostingAppConfig,
    // ContactsAppConfig,
    PaymentsAppConfig,
    CustomersAppConfig,
    CallHandlerAppConfig,
    DispatchBoardAppConfig,
    CustomerSitesAppConfig,
    CalendarAppConfig,
    // ChatAppConfig,
    TextGathersAppConfig,
    TextGatherPhrasesAppConfig,
    DashboardBuilderAppConfig,
    TimecardsAppConfig,
    UOMsAppConfig,
    ServiceUnitsAppConfig,
    // ECommerceAppConfig,
    ScrumboardAppConfig,
    WorkOrdersAppConfig,
    EmailTemplateAppConfig,
    GLAccountsAppConfig,
    ReportingAppConfig,
    InventoryLocationMaterialsAppConfig,
    ServiceUnitCategoriesAppConfig,
    ServiceUnitTypesAppConfig,
    TripsAppConfig,
    EstimatesAppConfig,
    PurchaseOrdersAppConfig,
    PurchaseOrderLinesAppConfig,
    VendorsAppConfig,
    AgreementsAppConfig,
    AgreementTypesAppConfig,
    AgreementTypeTasksAppConfig,
    FleetVehicleCategoriesAppConfig,
    FleetVehiclesAppConfig,
    InventoryLocationGroupsAppConfig,
    InventoryLocationsAppConfig,
    MaterialCategoriesAppConfig,
    MaterialsAppConfig,
    VoiceMailboxesAppConfig,
    InvoicesAppConfig,
    WorkOrderScopesAppConfig,
    ServicesAppConfig,
    TechnicianAppConfig,
    TechniciansAppConfig,
    TeamsAppConfig,
    EmployeesAppConfig,
    UsersAppConfig,
    UserRolesAppConfig,
    ServiceTypesAppConfig,
    LaborTypesAppConfig,
    CostTypesAppConfig,
    SupervisorAppConfig,
    ArrivalWindowsAppConfig,
    RateTemplatesAppConfig,
    TaxCodesAppConfig,
    FormOfPaymentsAppConfig,
    PayTermsAppConfig,
    BusinessUnitsAppConfig,
    DepartmentsAppConfig,
    DivisionsAppConfig,
    PhoneNumbersAppConfig,
    CallGathersAppConfig,
    HuntGroupsAppConfig,
    DialGroupsAppConfig,
    DialGroupMembersAppConfig,
    CallQueuesAppConfig,
    CallGatherKeysAppConfig,
    SupportAppConfig,
    YouTubeAppConfig,
    WorkOrdersAppConfig,
    WorkOrderUsagesAppConfig,
];
