import React from 'react';

export default function Loading() {
    return (
        <div style={{
            paddingTop: 100,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
}
