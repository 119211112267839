import React from 'react';
import {Redirect} from 'react-router-dom';
import Templates from './templates/Templates';
import Template from './template/Template';

export const EmailTemplateAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/email-templates/templates/:Co/:templateId/:templateUri?',
            component: Template
        },
        {
            path     : '/apps/email-templates/templates',
            component: Templates
        },
        {
            path     : '/apps/email-templates',
            component: () => <Redirect to="/apps/email-templates/templates"/>
        }
    ]
};
