import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedServiceUnitCategoryIds: [],
    routeParams       : {},
    serviceUnitCategoryDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const serviceUnitCategoriesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_SERVICE_UNIT_CATEGORIES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_SERVICE_UNIT_CATEGORIES:
        {

            const serviceUnitCategoryId = action.serviceUnitCategoryId;

            let selectedServiceUnitCategoryIds = [...state.selectedServiceUnitCategoryIds];

            if ( selectedServiceUnitCategoryIds.find(id => id === serviceUnitCategoryId) !== undefined )
            {
                selectedServiceUnitCategoryIds = selectedServiceUnitCategoryIds.filter(id => id !== serviceUnitCategoryId);
            }
            else
            {
                selectedServiceUnitCategoryIds = [...selectedServiceUnitCategoryIds, serviceUnitCategoryId];
            }

            return {
                ...state,
                selectedServiceUnitCategoryIds: selectedServiceUnitCategoryIds
            };
        }
        case Actions.SELECT_ALL_SERVICE_UNIT_CATEGORIES:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedServiceUnitCategoryIds = arr.map(serviceUnitCategories => serviceUnitCategories.id);

            return {
                ...state,
                selectedServiceUnitCategoryIds: selectedServiceUnitCategoryIds
            };
        }
        case Actions.DESELECT_ALL_SERVICE_UNIT_CATEGORIES:
        {
            return {
                ...state,
                selectedServiceUnitCategoryIds: []
            };
        }
        case Actions.OPEN_NEW_SERVICE_UNIT_CATEGORY_DIALOG:
        {
            return {
                ...state,
                serviceUnitCategoryDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_SERVICE_UNIT_CATEGORY_DIALOG:
        {
            return {
                ...state,
                serviceUnitCategoryDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_SERVICE_UNIT_CATEGORY_DIALOG:
        {
            return {
                ...state,
                serviceUnitCategoryDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.UPDATE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG:
        {
            return {
                ...state,
                serviceUnitCategoryDialog: {
                    type : 'update',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG:
        {
            return {
                ...state,
                serviceUnitCategoryDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default serviceUnitCategoriesReducer;
