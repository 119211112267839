import _ from '@lodash';

export const SET_MINIMIZED_DIALOG = '[MINIMIZED DIALOG APP] SET MINIMIZED DIALOG';

export function openMinimizedDialog(data) {
    window["warn"]("openMinimizedDialog ran!!!", data)
    return (dispatch, getState) => {
        if (data) {

            return dispatch({
                type: SET_MINIMIZED_DIALOG,
                payload: data
            })
        }
    }
}

export function updateAllMinimizedDialogs(data) {
    return (dispatch, getState) => {
        let currentState = getState()
        if(currentState){
            let currentData = currentState["minimizedDialogApp"]["minimizedDialog"]["data"];

            // Reset showing minimized dialogs
            for(let m = 0; m < currentData.length; m++){
                currentData[m].show = false;
            }

            let resHeaders = [];
            for(let i = 0; i < currentData.length; i++){
                let dialogIndex = _.findIndex(data, (o) => o === currentData[i].header);

                if(dialogIndex < 0){
                    resHeaders.push(currentData[i].header);
                }
            }

            for(let j = 0; j < resHeaders.length; j++){
                let minimizedDialogIndex = _.findIndex(currentData, (o) => o.header === resHeaders[j]);
                currentData[minimizedDialogIndex].show = true;
            }


            window["warn"]("updateAllMinimizedDialogs", currentData, data, resHeaders)

            return dispatch(openMinimizedDialog(currentData));
        } else{
            return dispatch(openMinimizedDialog(null));
        }       
    }
}

export function updateAllMinimizedDialogs2(data, currentData) {
    return (dispatch, getState) => {
        let currentState = getState()
        if(currentState){
            //let currentData = currentState["minimizedDialogApp"]["minimizedDialog"]["data"];

            // Reset showing minimized dialogs
            for(let m = 0; m < currentData.length; m++){
                currentData[m].show = false;
            }

           

            // let resHeaders = [];
            // for(let i = 0; i < data.length; i++){
            //     //let dialogIndex = _.findIndex(data, (o) => o === currentData[i].header);
            //     let dialogIndex = _.findIndex(currentData, (o) => o === data[i].header);

            //     if(dialogIndex > 0){
            //         resHeaders.push(currentData[i].header);
            //     }
            // }

            window["warn"]("updateAllMinimizedDialogs2", currentData, data, currentState)

            for(let j = 0; j < data.length; j++){
                let minimizedDialogIndex = _.findIndex(currentData, (o) => o.header === data[j]);
                currentData[minimizedDialogIndex].show = true;
            }
            

            return dispatch(openMinimizedDialog(currentData));
        } else{
            return dispatch(openMinimizedDialog(null));
        }       
    }
}