import axios from 'axios/index';
import { getUserData } from 'main/content/apps/arrival-windows/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_ARRIVAL_WINDOWS = '[ARRIVAL WINDOWS APP] GET ARRIVAL WINDOWS';
export const SET_SEARCH_TEXT = '[ARRIVAL WINDOWS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_ARRIVAL_WINDOWS = '[ARRIVAL WINDOWS APP] TOGGLE IN SELECTED ARRIVAL WINDOWS';
export const SELECT_ALL_ARRIVAL_WINDOWS = '[ARRIVAL WINDOWS APP] SELECT ALL ARRIVAL WINDOWS';
export const DESELECT_ALL_ARRIVAL_WINDOWS = '[ARRIVAL WINDOWS APP] DESELECT ALL ARRIVAL WINDOWS';
export const OPEN_NEW_ARRIVAL_WINDOW_DIALOG = '[ARRIVAL WINDOWS APP] OPEN NEW ARRIVAL WINDOW DIALOG';
export const CLOSE_NEW_ARRIVAL_WINDOW_DIALOG = '[ARRIVAL WINDOWS APP] CLOSE NEW ARRIVAL WINDOW DIALOG';
export const OPEN_EDIT_ARRIVAL_WINDOW_DIALOG = '[ARRIVAL WINDOWS APP] OPEN EDIT ARRIVAL WINDOW DIALOG';
export const CLOSE_EDIT_ARRIVAL_WINDOW_DIALOG = '[ARRIVAL WINDOWS APP] CLOSE EDIT ARRIVAL WINDOW DIALOG';
export const ADD_ARRIVAL_WINDOW = '[ARRIVAL WINDOWS APP] ADD ARRIVAL WINDOW';
export const UPDATE_ARRIVAL_WINDOW = '[ARRIVAL WINDOWS APP] UPDATE ARRIVAL WINDOW';
export const REMOVE_ARRIVAL_WINDOW = '[ARRIVAL WINDOWS APP] REMOVE ARRIVAL WINDOW';
export const REMOVE_ARRIVAL_WINDOWS = '[ARRIVAL WINDOWS APP] REMOVE ARRIVAL WINDOWS';
export const TOGGLE_STARRED_ARRIVAL_WINDOW = '[ARRIVAL WINDOWS APP] TOGGLE STARRED ARRIVAL WINDOW';
export const TOGGLE_STARRED_ARRIVAL_WINDOWS = '[ARRIVAL WINDOWS APP] TOGGLE STARRED ARRIVAL WINDOWS';
export const SET_ARRIVAL_WINDOWS_STARRED = '[ARRIVAL WINDOWS APP] SET ARRIVAL WINDOWS STARRED ';

export function getArrivalWindows(routeParams) {
    const request = axios.get('/api/arrivalWindows-app/arrivalWindows', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_ARRIVAL_WINDOWS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedArrivalWindows(arrivalWindowId) {
    return {
        type: TOGGLE_IN_SELECTED_ARRIVAL_WINDOWS,
        arrivalWindowId
    }
}


export function selectAllArrivalWindows() {
    return {
        type: SELECT_ALL_ARRIVAL_WINDOWS
    }
}

export function deSelectAllArrivalWindows() {
    return {
        type: DESELECT_ALL_ARRIVAL_WINDOWS
    }
}


export function openNewArrivalWindowDialog() {
    return {
        type: OPEN_NEW_ARRIVAL_WINDOW_DIALOG
    }
}

export function closeNewArrivalWindowDialog() {
    return {
        type: CLOSE_NEW_ARRIVAL_WINDOW_DIALOG
    }
}

export function openEditArrivalWindowDialog(data) {
    return {
        type: OPEN_EDIT_ARRIVAL_WINDOW_DIALOG,
        data
    }
}

export function closeEditArrivalWindowDialog() {
    return {
        type: CLOSE_EDIT_ARRIVAL_WINDOW_DIALOG
    }
}

export function addArrivalWindow(newArrivalWindow) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post(`${window["apiLocation"]}/api/ArrivalWindow`,
            newArrivalWindow
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_ARRIVAL_WINDOW
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Arrival Window ${newArrivalWindow.ArrivalWindow} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(getArrivalWindows(routeParams))
            })
        );
    };
}

export function updateArrivalWindow(arrivalWindow) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.put(`${window["apiLocation"]}/api/ArrivalWindow?Co=${encodeURIComponent(arrivalWindow.Co)}&BusinessUnit=${encodeURIComponent(arrivalWindow.BusinessUnit)}&ArrivalWindow=${encodeURIComponent(arrivalWindow.ArrivalWindow)}`,
            arrivalWindow
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_ARRIVAL_WINDOW
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Arrival Window ${arrivalWindow.ArrivalWindow} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(getArrivalWindows(routeParams))
            })
        );
    };
}

export function removeArrivalWindow(arrivalWindowId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post('/api/arrivalWindows-app/remove-arrivalWindow', {
            arrivalWindowId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_ARRIVAL_WINDOW
                })
            ]).then(() => dispatch(getArrivalWindows(routeParams)))
        );
    };
}


export function removeArrivalWindows(arrivalWindowIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post('/api/arrivalWindows-app/remove-arrivalWindows', {
            arrivalWindowIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_ARRIVAL_WINDOWS
                }),
                dispatch({
                    type: DESELECT_ALL_ARRIVAL_WINDOWS
                })
            ]).then(() => dispatch(getArrivalWindows(routeParams)))
        );
    };
}

export function toggleStarredArrivalWindow(arrivalWindowId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post('/api/arrivalWindows-app/toggle-starred-arrivalWindow', {
            arrivalWindowId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_ARRIVAL_WINDOW
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getArrivalWindows(routeParams)))
        );
    };
}

export function toggleStarredArrivalWindows(arrivalWindowIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post('/api/arrivalWindows-app/toggle-starred-arrivalWindows', {
            arrivalWindowIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_ARRIVAL_WINDOWS
                }),
                dispatch({
                    type: DESELECT_ALL_ARRIVAL_WINDOWS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getArrivalWindows(routeParams)))
        );
    };
}

export function setArrivalWindowsStarred(arrivalWindowIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post('/api/arrivalWindows-app/set-arrivalWindows-starred', {
            arrivalWindowIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_ARRIVAL_WINDOWS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_ARRIVAL_WINDOWS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getArrivalWindows(routeParams)))
        );
    };
}

export function setArrivalWindowsUnstarred(arrivalWindowIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().arrivalWindowsApp.arrivalWindows;

        const request = axios.post('/api/arrivalWindows-app/set-arrivalWindows-unstarred', {
            arrivalWindowIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_ARRIVAL_WINDOWS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_ARRIVAL_WINDOWS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getArrivalWindows(routeParams)))
        );
    };
}
