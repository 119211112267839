import axios from 'axios';
import { getUserData } from 'main/content/apps/trips/store/actions/user.actions';
import { setTechnicianTimecards } from 'store/actions/spConnection/technician.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';
import { updateTechnicianTrip } from 'main/content/apps/dispatch-board/store/actions';
import _ from '@lodash';
import moment from 'moment';

export const GET_TRIPS = '[TRIPS APP] GET TRIPS';
export const SET_SEARCH_TEXT = '[TRIPS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TRIPS = '[TRIPS APP] TOGGLE IN SELECTED TRIPS';
export const SELECT_ALL_TRIPS = '[TRIPS APP] SELECT ALL TRIPS';
export const DESELECT_ALL_TRIPS = '[TRIPS APP] DESELECT ALL TRIPS';
export const OPEN_NEW_TRIP_DIALOG = '[TRIPS APP] OPEN NEW TRIP DIALOG';
export const CLOSE_NEW_TRIP_DIALOG = '[TRIPS APP] CLOSE NEW TRIP DIALOG';
export const OPEN_EDIT_TRIP_DIALOG = '[TRIPS APP] OPEN EDIT TRIP DIALOG';
export const UPDATE_EDIT_TRIP_DIALOG = '[TRIPS APP] UPDATE EDIT TRIP DIALOG';
export const CLOSE_EDIT_TRIP_DIALOG = '[TRIPS APP] CLOSE EDIT TRIP DIALOG';
export const ADD_TRIP = '[TRIPS APP] ADD TRIP';
export const UPDATE_TRIP = '[TRIPS APP] UPDATE TRIP';
export const REMOVE_TRIP = '[TRIPS APP] REMOVE TRIP';
export const REMOVE_TRIPS = '[TRIPS APP] REMOVE TRIPS';
export const TOGGLE_STARRED_TRIP = '[TRIPS APP] TOGGLE STARRED TRIP';
export const TOGGLE_STARRED_TRIPS = '[TRIPS APP] TOGGLE STARRED TRIPS';
export const ADD_TRIP_ASSIGNMENT = '[TRIPS APP] ADD TRIP ASSIGNMENT';
export const REMOVE_TRIP_ASSIGNMENT = '[TRIPS APP] REMOVE TRIP ASSIGNMENT';
export const SET_TRIPS_STARRED = '[TRIPS APP] SET TRIPS STARRED ';

export function getTrips(routeParams) {
    const request = axios.get('/api/trips-app/trips', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TRIPS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTrips(tripId) {
    return {
        type: TOGGLE_IN_SELECTED_TRIPS,
        tripId
    }
}


export function selectAllTrips() {
    return {
        type: SELECT_ALL_TRIPS
    }
}

export function deSelectAllTrips() {
    return {
        type: DESELECT_ALL_TRIPS
    }
}


export function openNewTripDialog(data) {
    return {
        type: OPEN_NEW_TRIP_DIALOG,
        data
    }
}

export function closeNewTripDialog() {
    return {
        type: CLOSE_NEW_TRIP_DIALOG
    }
}

export function openPreloadTripDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_TRIP_DIALOG,
            data: data,
        })
    }
}

export function openEditTripDialog(data, readOnly) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Trip?Co=${data.Co}&WorkOrder=${data.WorkOrder}&Trip=${data.Trip}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_TRIP_DIALOG,
                data: response.data,
                readOnly
            });
        }).catch((error) => {
            dispatch({
                type: OPEN_EDIT_TRIP_DIALOG,
                data,
                readOnly
            });
        });
    }
}

export function addTripAssignment(data, Scope, open) {
    const { Co, WorkOrder, Trip } = data;
    return (dispatch, getState) => {

        const assignment = {
            Co,
            WorkOrder,
            Trip,
            Scope,
            Data: {
                ErrMsg: null,
            }
        }

        const url = `${window["apiLocation"]}/api/TripAssignment`;
        const request = axios.post(url,
            assignment
        );

        return request.then((response) => {
            data.Data.Assignments.push(assignment);
            const scope = _.find(data.Data.Scopes, { Scope });
            if (scope) {
                if (!scope.Data) {
                    scope.Data = { Assignments: [], };
                };
                if (!scope.Data.Assignments) {
                    scope.Data.Assignments = [];
                }
                scope.Data.Assignments.push(assignment);
            }
            window["warn"]('New Trip w/ Assignment: ', data);
            dispatch(updateTechnicianTrip(data, "UPDATE"));
            if (open) {
                Promise.all([
                    dispatch({
                        type: ADD_TRIP_ASSIGNMENT,
                        data: response.data
                    }),
                    dispatch({
                        type: UPDATE_EDIT_TRIP_DIALOG,
                        data
                    }),
                    dispatch(showMessage({
                        message: `Scope #${Scope} has been added to Trip #${Trip}.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    })),
                ]);
            }
        }).catch((error) => {
            console.warn('Error adding trip assignment: ', error);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...assignment, OfflineID: guid() }
                dispatch(setOfflineData('ADD', 'post', url, offlineData, 'trips'));
                data.Data.Assignments.push(offlineData);
                const scope = _.find(data.Data.Scopes, { Scope });
                if (scope) {
                    if (!scope.Data) {
                        scope.Data = { Assignments: [], };
                    };
                    if (!scope.Data.Assignments) {
                        scope.Data.Assignments = [];
                    }
                    scope.Data.Assignments.push(offlineData);
                }
                if (open) {
                    Promise.all([
                        dispatch({
                            type: ADD_TRIP_ASSIGNMENT,
                            data: offlineData
                        }),
                        dispatch({
                            type: UPDATE_EDIT_TRIP_DIALOG,
                            data
                        }),
                    ]);
                }
            }
        });
    }
}

export function removeTripAssignment(data, Scope) {
    const { Co, WorkOrder, Trip } = data;
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/TripAssignment?Co=${Co}&WorkOrder=${WorkOrder}&Trip=${Trip}&Scope=${Scope}`;
        const request = axios.delete(url);

        return request.then((response) => {
            const index = _.findIndex(data.Data.Assignments, { Scope });
            let assignment = null;
            if (index > -1) {
                assignment = { ...data.Data.Assignments[index] };
                data.Data.Assignments.splice(index, 1);
            }
            const scope = _.find(data.Data.Scopes, { Scope });
            if (scope) {
                const assignmentIndex = _.findIndex(scope.Data.Assignments, { Trip: data.Trip });
                scope.Data.Assignments.splice(assignmentIndex, 1);
            }
            Promise.all([
                dispatch({
                    type: REMOVE_TRIP_ASSIGNMENT,
                    data: assignment
                }),
                dispatch({
                    type: UPDATE_EDIT_TRIP_DIALOG,
                    data
                }),
                dispatch(showMessage({
                    message: `Scope #${Scope} has been removed from Trip #${Trip}.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })),
            ]);
        }).catch((error) => {
            console.warn('Error deleting trip assignment: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const index = _.findIndex(data.Data.Assignments, { Scope });
                let assignment = null;
                if (index > -1) {
                    assignment = { ...data.Data.Assignments[index] };
                    data.Data.Assignments.splice(index, 1);
                }
                const scope = _.find(data.Data.Scopes, { Scope });
                if (scope) {
                    const assignmentIndex = _.findIndex(scope.Data.Assignments, { Trip: data.Trip });
                    scope.Data.Assignments.splice(assignmentIndex, 1);
                }
                dispatch(setOfflineData('REMOVE', 'delete', url, { ...assignment }, 'trips'));
                Promise.all([
                    dispatch({
                        type: REMOVE_TRIP_ASSIGNMENT,
                        data: assignment
                    }),
                    dispatch({
                        type: UPDATE_EDIT_TRIP_DIALOG,
                        data
                    }),
                ]);
            }
        });
    }
}

export function updateEditTripDialog(data) {
    return {
        type: UPDATE_EDIT_TRIP_DIALOG,
        data
    }
}

export function closeEditTripDialog() {
    return {
        type: CLOSE_EDIT_TRIP_DIALOG
    }
}

export function addTrip(newTrip, assigned) {
    return (dispatch, getState) => {

        const { routeParams } = getState().tripsApp.trips;

        const url = `${window["apiLocation"]}/api/DispatchBoard`;
        const request = axios.post(url,
            { ...newTrip }
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Promise.all([
                    dispatch({
                        type: ADD_TRIP,
                        data: response.data,
                    })
                ]).then(() => {
                    dispatch(showMessage({
                        message: `Trip #${response.data.Trip} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                    if (!assigned) {
                        if (newTrip.Data && newTrip.Data.Assignments && newTrip.Data.Scopes) {
                            newTrip.Data.Assignments.map((scp, value) => {
                                dispatch(addTripAssignment(response.data, scp.Scope));
                            });
                        } else {
                            if (response.data.Data && response.data.Data.Scopes) {
                                if (response.data.Data.Scopes.length < 2) {
                                    dispatch(addTripAssignment(response.data, response.data.Data.Scopes[0].Scope));
                                } else {
                                    dispatch(openEditTripDialog(response.data));
                                }
                            }
                        }
                    }
                    // dispatch(updateEditTripDialog(response.data));
                })
            }
        }).catch((error) => {
            console.warn('Error adding trip: ', error);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...newTrip, OfflineID: guid() }
                dispatch(setOfflineData('ADD', 'post', url, offlineData, 'trips'));
                dispatch({
                    type: ADD_TRIP,
                    data: offlineData,
                });
                if (offlineData.Data && offlineData.Data.Assignments && offlineData.Data.Scopes) {
                    offlineData.Data.Assignments.map((scp, value) => {
                        dispatch(addTripAssignment(offlineData, scp.Scope));
                    });
                } else {
                    if (offlineData.Data && offlineData.Data.Scopes) {
                        if (offlineData.Data.Scopes.length < 2) {
                            dispatch(addTripAssignment(offlineData, offlineData.Data.Scopes[0].Scope));
                        } else {
                            dispatch(openEditTripDialog(offlineData));
                        }
                    }
                }
            }
        });
    };
}

export function updateTrip(trip) {
    return (dispatch, getState) => {

        const state = getState();
        const { technician } = state.spReducers;

        const url = `${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(trip.Co)}&WorkOrder=${encodeURIComponent(trip.WorkOrder)}&Trip=${encodeURIComponent(trip.Trip)}`;
        const request = axios.put(url,
            { ...trip }
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Trip #${trip.Trip} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                dispatch({
                    type: UPDATE_TRIP,
                    data: response.data,
                });
                if (response.data.Data.Timecard && technician && technician.technician && response.data.Data.Timecard.Employee === technician.technician.Employee) {
                    const { timecards } = technician;
                    const index = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                    if (index > -1) {
                        timecards[index].timecard = response.data.Data.Timecard;
                    };
                    Promise.all([
                        dispatch(setTechnicianTimecards([...timecards])),
                    ])
                }
            }
        }).catch((error) => {
            console.warn('Error updating trip: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                dispatch(setOfflineData('UPDATE', 'put', url, { ...trip }, 'trips'));
                dispatch({
                    type: UPDATE_TRIP,
                    data: { ...trip },
                })
            }
        });
    };
}

export function removeTrip(trip) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(trip.Co)}&WorkOrder=${encodeURIComponent(trip.WorkOrder)}&Trip=${encodeURIComponent(trip.Trip)}`;
        const request = axios.delete(url);

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Trip #${trip.Trip} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                dispatch({
                    type: REMOVE_TRIP,
                    data: trip
                })
            }
            dispatch(closeEditTripDialog());
        }).catch((error) => {
            console.warn('Error deleting trip: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                dispatch(setOfflineData('REMOVE', 'delete', url, { ...trip }, 'trips'));
                dispatch({
                    type: REMOVE_TRIP,
                    data: trip
                });
                dispatch(closeEditTripDialog());
            }
        });
    };
}


export function removeTrips(tripIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().tripsApp.trips;

        const request = axios.post('/api/trips-app/remove-trips', {
            tripIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TRIPS
                }),
                dispatch({
                    type: DESELECT_ALL_TRIPS
                })
            ]).then(() => dispatch(getTrips(routeParams)))
        );
    };
}

export function toggleStarredTrip(tripId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().tripsApp.trips;

        const request = axios.post('/api/trips-app/toggle-starred-trip', {
            tripId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TRIP
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTrips(routeParams)))
        );
    };
}

export function toggleStarredTrips(tripIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().tripsApp.trips;

        const request = axios.post('/api/trips-app/toggle-starred-trips', {
            tripIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TRIPS
                }),
                dispatch({
                    type: DESELECT_ALL_TRIPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTrips(routeParams)))
        );
    };
}

export function setTripsStarred(tripIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().tripsApp.trips;

        const request = axios.post('/api/trips-app/set-trips-starred', {
            tripIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TRIPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TRIPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTrips(routeParams)))
        );
    };
}

export function setTripsUnstarred(tripIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().tripsApp.trips;

        const request = axios.post('/api/trips-app/set-trips-unstarred', {
            tripIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TRIPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TRIPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTrips(routeParams)))
        );
    };
}
