import * as Actions from 'store/actions/spConnection';

const initialState = {
    classes: [],
    categories: [],
    progress: [],
}

const classes = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CLASS_DATA:
            {
                return {
                    ...state,
                    classes: action.payload
                };
            }
        case Actions.SET_CLASS_DATA:
            {
                return {
                    ...state,
                    classes: action.payload
                };
            }
        case Actions.GET_CLASS_PROGRESS:
            {
                return {
                    ...state,
                    progress: action.payload
                };
            }
        case Actions.SET_CLASS_PROGRESS:
            {
                return {
                    ...state,
                    progress: action.payload
                };
            }
        case Actions.GET_CLASS_CATEGORY_DATA:
            {
                return {
                    ...state,
                    categories: action.payload
                };
            }
        case Actions.SET_CLASS_CATEGORY_DATA:
            {
                return {
                    ...state,
                    categories: action.payload
                };
            }
    }

    return state;
};

export default classes;