import React, { Component } from 'react';
import { Checkbox, Icon, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import ToolbarMenu from './ToolbarMenu';
import _ from '@lodash';

class LabelsMenu extends Component {
    state = {
        anchorEl: null
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { onChange, priority } = this.props;
        const colors = ['#4dbce9', '#f44336', 'yellow', '#fff']

        return (
            <div>
                <IconButton color="inherit" onClick={this.handleMenuClick}>
                    <Icon style={{ color: colors[priority] || '#fff' }}>flag</Icon>
                </IconButton>
                <ToolbarMenu state={this.state.anchorEl} onClose={this.handleMenuClose}>
                    <div className="max-h-256 overflow-y-auto">
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange(3);
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                Low Priority
                            </ListItemText>
                            <ListItemIcon>
                                <Icon style={{ color: '#4dbce9' }}>flag</Icon>
                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange(2);
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                Medium Priority
                            </ListItemText>
                            <ListItemIcon>
                                <Icon style={{ color: 'yellow' }}>flag</Icon>
                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem
                            className="px-8"
                            onClick={(ev) => {
                                onChange(1);
                                this.handleMenuClose();
                            }}
                        >
                            <ListItemText>
                                High Priority
                            </ListItemText>
                            <ListItemIcon>
                                <Icon style={{ color: '#f44336' }}>flag</Icon>
                            </ListItemIcon>
                        </MenuItem>
                    </div>
                </ToolbarMenu>
            </div>
        );
    };
}

export default LabelsMenu;
