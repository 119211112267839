import axios from 'axios/index';

export const SET_BUSINESS_UNIT_DATA = '[SPCONNECTION] SET BUSINESS UNIT DATA';
export const GET_BUSINESS_UNIT_DATA = '[SPCONNECTION] GET BUSINESS UNIT DATA';
export const SUBSCRIBE_BUSINESS_UNIT_LIST = '[SPCONNECTION] SUBSCRIBE BUSINESS UNIT LIST';

export function getInitialBusinessUnitData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/BusinessUnit`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getBusinessUnitData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/BusinessUnit`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_BUSINESS_UNIT_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_BUSINESS_UNIT_LIST,
            Co
        }));
    }
}

export function setBusinessUnitData(data) {
    return {
        type: SET_BUSINESS_UNIT_DATA,
        payload: data
    };
}