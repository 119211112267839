import React, { Component } from 'react';
import { Button, withStyles, Icon, IconButton, MenuItem, FormControl, Select } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    }
});

class TodoToolbar extends Component {

    handleOrderChange = (ev) => {
        this.props.changeOrder(ev.target.value);
    };

    render() {
        const { classes, orderBy, orderDescending, toggleOrderDescending, openNewTodoDialog } = this.props;

        return (
            <div className={classNames(classes.root, "flex justify-between w-full")}>
                <Button
                    className={classNames(classes.blackButton, "m-12")}
                    onClick={() => {
                        openNewTodoDialog();
                    }}
                    variant="contained"
                    color="primary"
                >
                    <Icon className="mr-6 mb-4 text-20" color="secondary">add_circle_outline</Icon>ADD TASK
                </Button>
                <div className="flex items-center">
                    <FormControl className="">
                        <Select
                            value={orderBy}
                            onChange={this.handleOrderChange}
                            displayEmpty
                            name="filter"
                            className=""
                        >
                            <MenuItem value="">
                                <em>Order by</em>
                            </MenuItem>
                            <MenuItem value="StartDate">Start Date</MenuItem>
                            <MenuItem value="DueDate">Due Date</MenuItem>
                            <MenuItem value="Title">Title</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton onClick={toggleOrderDescending}>
                        <Icon style={{ transform: orderDescending ? 'scaleY(-1)' : 'scaleY(1)' }}>
                            sort
                        </Icon>
                    </IconButton>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeOrder: Actions.changeOrder,
        toggleOrderDescending: Actions.toggleOrderDescending,
        openNewTodoDialog: Actions.openNewTodoDialog
    }, dispatch);
}

function mapStateToProps({ todoApp }) {
    return {
        orderBy: todoApp.todos.orderBy,
        orderDescending: todoApp.todos.orderDescending
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TodoToolbar)));
