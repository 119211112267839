import axios from 'axios';
import { getUserData } from 'main/content/apps/estimates/store/actions/user.actions';
import { updateWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { showMessage, setOfflineData, guid, addDialogHistory } from 'store/actions';
import moment from 'moment';
import _ from 'lodash';

export const GET_ESTIMATES = '[ESTIMATES APP] GET ESTIMATES';
export const SET_ESTIMATES = '[ESTIMATES APP] SET ESTIMATES';
export const SET_LOADING = '[ESTIMATES APP] SET LOADING';
export const SET_PROCESSING = '[ESTIMATES APP] SET PROCESSING';
export const SET_SEARCH_TEXT = '[ESTIMATES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_ESTIMATES = '[ESTIMATES APP] TOGGLE IN SELECTED ESTIMATES';
export const SELECT_ALL_ESTIMATES = '[ESTIMATES APP] SELECT ALL ESTIMATES';
export const DESELECT_ALL_ESTIMATES = '[ESTIMATES APP] DESELECT ALL ESTIMATES';
export const OPEN_NEW_ESTIMATE_DIALOG = '[ESTIMATES APP] OPEN NEW ESTIMATE DIALOG';
export const CLOSE_NEW_ESTIMATE_DIALOG = '[ESTIMATES APP] CLOSE NEW ESTIMATE DIALOG';
export const OPEN_EDIT_ESTIMATE_DIALOG = '[ESTIMATES APP] OPEN EDIT ESTIMATE DIALOG';
export const UPDATE_EDIT_ESTIMATE_DIALOG = '[ESTIMATES APP] UPDATE EDIT ESTIMATE DIALOG';
export const CLOSE_EDIT_ESTIMATE_DIALOG = '[ESTIMATES APP] CLOSE EDIT ESTIMATE DIALOG';
export const ADD_ESTIMATE = '[ESTIMATES APP] ADD ESTIMATE';
export const UPDATE_ESTIMATE = '[ESTIMATES APP] UPDATE ESTIMATE';
export const REMOVE_ESTIMATE = '[ESTIMATES APP] REMOVE ESTIMATE';
export const REMOVE_ESTIMATES = '[ESTIMATES APP] REMOVE ESTIMATES';
export const TOGGLE_STARRED_ESTIMATE = '[ESTIMATES APP] TOGGLE STARRED ESTIMATE';
export const TOGGLE_STARRED_ESTIMATES = '[ESTIMATES APP] TOGGLE STARRED ESTIMATES';
export const SET_ESTIMATES_STARRED = '[ESTIMATES APP] SET ESTIMATES STARRED ';
export const SET_ESTIMATE_FILTERS = '[ESTIMATES APP] SET ESTIMATES FILTERS';
export const GET_SCHEDULE = '[CALL HANDLER APP] GET SCHEDULE';
export const SCHEDULE_ESTIMATE = '[CALL HANDLER APP] SCHEDULE ESTIMATE';

export function scheduleEstimate(newWorkOrder) {
    return (dispatch, getState) => {

        const state = getState();

        const request = axios.post(`${window["apiLocation"]}/api/CallHandler`,
            {
                WorkOrder: newWorkOrder,
                Scope: { ...newWorkOrder, Data: { ...newWorkOrder.Data, Tags: [] } }
            }
        );

        return request.then((response) => {
            if (response.data.WorkOrder.Data.ErrMsg && response.data.WorkOrder.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.WorkOrder.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                if (response.data.Scope.Data.ErrMsg && response.data.Scope.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Scope.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const wo = response.data.WorkOrder;
                    const scope = response.data.Scope;
                    const { WorkOrder, Scope, Department, ServiceType, Service, Description, DueBy, LeadTechnician, ArrivalWindow, RateTemplate, Priority, PriceMethod, Price, Estimate } = scope;
                    Promise.all([
                        dispatch({
                            type: SCHEDULE_ESTIMATE,
                            data: {
                                AssignedToWorkOrder: WorkOrder,
                                AssignedToWorkOrderScope: Scope,
                                Department,
                                ServiceType,
                                Service,
                                ScopeDescription: Description,
                                DueBy,
                                LeadTechnician,
                                ArrivalWindow,
                                RateTemplate,
                                Priority,
                                PriceMethod,
                                Price,
                                Estimate
                            }
                        }),
                    ]).then(() => {
                        dispatch(showMessage({
                            message: `Work Order #${response.data.Scope.WorkOrder} has been successfully created.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                        const { Co, WorkOrder } = response.data.WorkOrder;
                        dispatch(
                            addDialogHistory({
                                timestamp: new Date(),
                                dialog: 'WorkOrder',
                                params: {
                                    Co,
                                    WorkOrder,
                                }
                            })
                        );
                    })
                }
            }
        });
    };
}

export function getScheduledTrips(Co, dt) {
    window["warn"]('New Dispatch Date: ', dt);
    if (dt) {
        const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard`, {
            params: {
                Co,
                Date: dt
            }
        });

        return (dispatch, getState) => {
            dispatch({
                type: GET_SCHEDULE,
                trips: [],
            });
            // dispatch();
            request.then((response) => {
                Promise.all([
                    dispatch(getScheduledWOs(Co, dt, response.data))
                ]);
            });
        }
    }
}

export function getScheduledWOs(Co, dt, schedule) {
    if (dt) {
        const request = axios.get(`${window["apiLocation"]}/api/WorkOrder`, {
            params: {
                Co,
                DueBy: dt
            }
        });

        return (dispatch) => {
            dispatch({
                type: GET_SCHEDULE,
                schedule: [],
            });
            request.then((response) => {
                schedule.map((trip) => {
                    const { Co, WorkOrder } = trip;
                    const wo = _.find(response.data, { Co, WorkOrder });
                    if (wo) {
                        trip.Data.WorkOrder = wo;
                    }
                })
                Promise.all([
                    dispatch({
                        type: GET_SCHEDULE,
                        schedule,
                    }),
                ]);
            });
        }
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedEstimates(estimateId) {
    return {
        type: TOGGLE_IN_SELECTED_ESTIMATES,
        estimateId
    }
}


export function selectAllEstimates() {
    return {
        type: SELECT_ALL_ESTIMATES
    }
}

export function deSelectAllEstimates() {
    return {
        type: DESELECT_ALL_ESTIMATES
    }
}


export function openNewEstimateDialog(data) {

    return (dispatch, getState) => {
        const { taxCodes, businessUnits } = getState().spReducers;
        window["warn"](data);
        dispatch({
            type: OPEN_NEW_ESTIMATE_DIALOG,
            data
        });
        // dispatch(buildNewEstimate(data, taxCodes, businessUnits));
    }
}

export function buildNewEstimate(data, taxCodes, businessUnits) {
    const { Customer, Site, Estimates, Scopes, EstimateNumber, WorkOrder, Scope, Assignments } = data;
    const {
        Co,
        PayTerms,
        FirstName,
        LastName,
        Name,
        Email,
        Phone,
        BillContact,
        BillAddress1,
        BillAddress2,
        BillCity,
        BillState,
        BillZip,
        BillCountry,
        BillPhone,
        BillEmail,
    } = Customer;
    const {
        Address1,
        Address2,
        City,
        State,
        Zip
    } = Site;
    const EstimateDetails = [];
    let EstimateDetail = 0;
    for (var i = 0; i < Scopes.length; i++) {
        const scope = { ...Scopes[i] };
        if (scope.ReadyToBillYN === 'Y') {
            if (scope.PriceMethod === 'F' && !scope.Estimate) {
                const bu = _.find(businessUnits, (u) => { return u.Co === scope.Co && u.BusinessUnit === scope.BusinessUnit });
                const tc = _.find(taxCodes, (c) => { return c.Co === scope.Co && c.TaxCode === (scope.TaxCode ? scope.TaxCode : Site.TaxCode ? Site.TaxCode : Customer.TaxCode ? Customer.TaxCode : bu.TaxCode) });
                const TaxRate = (!tc ? 0.00 : new Date() >= new Date(tc.EffectiveDate) ? tc.NewRate : tc.OldRate) / 100;
                window["warn"](bu, tc, TaxRate);
                EstimateDetail += 1;
                EstimateDetails.push({
                    Co: scope.Co,
                    Estimate: null,
                    EstimateDetail,
                    WorkOrder,
                    Scope: scope.Scope,
                    Data: {
                        Scope: scope,
                        EstimateLines: [{
                            Co: scope.Co,
                            Estimate: null,
                            EstimateDetail,
                            EstimateDetailLine: 1,
                            Amount: scope.Price,
                            TaxAmount: (scope.TaxableYN !== "N" ? scope.Price * TaxRate : 0.00)
                        }]
                    }
                });
            }
            for (var s = 0; s < scope.Data.Usage.length; s++) {
                const usage = scope.Data.Usage[s];
                if (usage.Status === 'NEW') {
                    EstimateDetail += 1;
                    EstimateDetails.push({
                        Co: scope.Co,
                        Estimate: null,
                        EstimateDetail,
                        WorkOrder,
                        UsageLine: usage.Line,
                        Data: {
                            Usage: usage,
                            EstimateLines: [{
                                Co: scope.Co,
                                Estimate: null,
                                EstimateDetail,
                                EstimateDetailLine: 1,
                                Amount: usage.PriceTotal,
                                TaxAmount: (scope.TaxableYN !== "N" && usage.TaxableYN !== "N" ? usage.TaxTotal : 0)
                            }]
                        }
                    });
                }
            }
        }
    }
    const scope = Scopes[0];
    delete scope.BillingData;
    // delete scope.Data;
    const estimate = {
        Co,
        EstimateNumber,
        EstimateDate: moment(new Date().toLocaleDateString('en-US')).toDate(),
        Customer: Customer.Customer,
        Description: `Estimate for Work Order #${WorkOrder}`,
        PayTerms,
        DueDate: new Date(),
        WorkOrder,
        Scope,
        Site: Site.Site,
        Location: `${Address1} ${City}, ${State} ${Zip}`,
        FirstName,
        LastName,
        BillName: Name && Name.trim().length > 0 ? Name : `${FirstName} ${LastName}`,
        BillAddress1,
        BillAddress2,
        BillCity,
        BillState,
        BillZip,
        BillCountry,
        BillPhone: BillPhone || Phone,
        BillEmail: BillEmail || Email,
        Data: {
            Co,
            EstimateNumber,
            EstimateDate: moment(new Date().toLocaleDateString('en-US')).toDate(),
            Customer: Customer.Customer,
            Description: `Estimate for Work Order #${WorkOrder}`,
            PayTerms,
            DueDate: new Date(),
            WorkOrder,
            Scope,
            BillName: Name && Name.trim().length > 0 ? Name : `${FirstName} ${LastName}`,
            BillAddress1,
            BillAddress2,
            BillCity,
            BillState,
            BillZip,
            BillCountry,
            BillPhone: BillPhone || Phone,
            BillEmail: BillEmail || Email,
            Data: {
                Assignments,
                Scopes: Scope ? [{ ...scope }] : _.filter([...Scopes], { ReadyToBillYN: 'Y' }),
                Usage: [],
                Payments: [],
                EstimateDetails,
                ErrMsg: null,
            }
        }
    }
    const lines = _.flatten(_.map(estimate.Data.Data.EstimateDetails, _.property('Data.EstimateLines')));
    estimate.BilledTotal = _.sumBy(lines, (o) => { return Number(o.Amount) });
    estimate.TaxTotal = _.sumBy(lines, (o) => { return Number(o.TaxAmount) });
    window["warn"](estimate);
    return {
        type: OPEN_NEW_ESTIMATE_DIALOG,
        data: estimate,
        // trip
    }
}

export function closeNewEstimateDialog() {
    return {
        type: CLOSE_NEW_ESTIMATE_DIALOG
    }
}

export function openPreloadEstimateDialog(data, scheduleWork){
    return (dispatch, getState) => {
            dispatch({
                type: OPEN_EDIT_ESTIMATE_DIALOG,
                data: { ...data, scheduleWork }
            })
    }
}

export function openEditEstimateDialog(data, scheduleWork) {
    {
        return (dispatch, getState) => {

            const request = axios.get(`${window["apiLocation"]}/api/Estimate?Co=${data.Co}&BusinessUnit=${encodeURIComponent(data.BusinessUnit)}&Division=${encodeURIComponent(data.Division)}&Estimate=${data.Estimate}`);

            return request.then((response) => {
                let res = _.cloneDeepWith(response)
                window["warn"]("estimate api call", res)
                Promise.all([
                    dispatch({
                        type: OPEN_EDIT_ESTIMATE_DIALOG,
                        data: { ...response.data, scheduleWork }
                    })
                ])
            }).catch((e) => {
                Promise.all([
                    dispatch({
                        type: OPEN_EDIT_ESTIMATE_DIALOG,
                        data: { ...data, scheduleWork }
                    })
                ])
            });
        };
    }
}

export function updateEditEstimateDialog(data) {
    return {
        type: UPDATE_EDIT_ESTIMATE_DIALOG,
        data
    }
}

export function closeEditEstimateDialog() {
    return {
        type: CLOSE_EDIT_ESTIMATE_DIALOG
    }
}


export function addEstimate(newEstimate) {
    return (dispatch, getState) => {

        const state = getState();
        const { workOrdersApp } = state;
        const { workOrderDialog } = workOrdersApp.workOrders;
        const { data } = _.cloneDeepWith(workOrderDialog);
        const { routeParams } = getState().estimatesApp.estimates;
        window["warn"]("newEstimate", newEstimate);
        const estimate = _.cloneDeepWith(newEstimate);
        if (estimate.Data.Customer && estimate.Data.Customer.Data) {
            delete estimate.Data.Customer.Data;
        }
        if (estimate.Data.Site && estimate.Data.Site.Data) {
            delete estimate.Data.Site.Data;
        }
        const url = `${window["apiLocation"]}/api/Estimate`;
        const request = axios.post(url,
            estimate
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_ESTIMATE,
                    data: { ...newEstimate, Estimate: response.data.Estimate }
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const est = { ...response.data, autoInvoice: newEstimate.autoInvoice, PriceTotal: _.sumBy(_.filter(response.data.Data.Components, (o) => o.DeleteYN !== 'Y'), 'Price') }
                    dispatch(updateEditEstimateDialog(est));
                    if (data && data.WorkOrder === newEstimate.IssuedFromWorkOrder) {
                        const { Estimates, Site } = data.Data;
                        Estimates.push(est);
                        if (Site.Data && Site.Data.Estimates) {
                            Site.Data.Estimates.push(est);
                        }
                        data.newEstimate = est;
                        dispatch(updateWorkOrderDialog(data));
                    }
                    dispatch(showMessage({
                        message: `Estimate #${newEstimate.EstimateNumber} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        ).catch((error) => {
            console.warn('Error adding estimate: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...newEstimate, OfflineID: guid() };
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'estimates')),
                ]).then(() => {
                    dispatch({
                        type: ADD_ESTIMATE,
                        data: { ...offlineData, Estimate: -1 }
                    })
                })
            }
        });
    };
}

export function updateEstimate(estimate, open) {
    return (dispatch, getState) => {

        const state = getState();
        const { workOrdersApp } = state;
        const { workOrderDialog } = workOrdersApp.workOrders;
        const { data } = _.cloneDeepWith(workOrderDialog);
        const url = `${window["apiLocation"]}/api/Estimate?Co=${encodeURIComponent(estimate.Co)}&BusinessUnit=${encodeURIComponent(estimate.BusinessUnit)}&Division=${encodeURIComponent(estimate.Division)}&Estimate=${encodeURIComponent(estimate.Estimate)}`;
        const request = axios.put(url,
            estimate
        );

        estimate.open = open;

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_ESTIMATE,
                    data: estimate
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    if (data && data.Data && data.Data.Site && data.Data.Site.Site === response.data.Site && data.Data.Site.Customer === response.data.Customer) {
                        const est = { ...estimate, ...response.data, PriceTotal: _.sumBy(_.filter(response.data.Data.Components, (o) => o.DeleteYN !== 'Y'), 'Price') };
                        const { Estimates, Site } = data.Data;
                        const index = _.findIndex(Estimates, { Estimate: response.data.Estimate });
                        if (index > -1) {
                            Estimates.splice(index, 1, est);
                        }
                        if (Site.Data && Site.Data.Estimates) {
                            const siteIndex = _.findIndex(Site.Data.Estimates, { Estimate: response.data.Estimate });
                            if (siteIndex > -1) {
                                Site.Data.Estimates.splice(siteIndex, 1, est);
                            }
                        }
                        data.updatedEstimate = est;
                        dispatch(updateWorkOrderDialog(data));
                    }
                    // dispatch(updateEditEstimateDialog(response.data));
                    dispatch(showMessage({
                        message: `Estimate ${estimate.EstimateNumber} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        ).catch((error) => {
            console.warn('Error updating estimate: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('UPDATE', 'put', url, estimate, 'estimates')),
                ]).then(() => {
                    dispatch({
                        type: UPDATE_ESTIMATE,
                        data: estimate
                    })
                })
            }
        });
    };
}

export function removeEstimate(estimate) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Estimate?Co=${encodeURIComponent(estimate.Co)}&BusinessUnit=${encodeURIComponent(estimate.BusinessUnit)}&Division=${encodeURIComponent(estimate.Division)}&Estimate=${encodeURIComponent(estimate.Estimate)}`;
        const request = axios.delete(url);

        return request.then((response) => {

            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Promise.all([
                    dispatch({
                        type: REMOVE_ESTIMATE,
                        data: estimate,
                    }),
                    dispatch(showMessage({
                        message: `Estimate ${estimate.EstimateNumber} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                ])
            }
        }).catch((error) => {
            console.warn('Error deleting estimate: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('REMOVE', 'delete', url, estimate, 'estimates')),
                ]).then(() => {
                    dispatch({
                        type: REMOVE_ESTIMATE,
                        data: estimate
                    })
                })
            }
        });
    };
}

export function sendEstimate(fd) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/Email`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        return request.then((response) => {
            console.warn(response);
        });
    };
}

export function setEstimateFilters(filters) {
    return {
        type: SET_ESTIMATE_FILTERS,
        filters,
    }
}
export function getEstimates(Co, filters) {

    return (dispatch) => {
        // dispatch({
        //     type: SET_LOADING,
        //     loading: true,
        // });
        dispatch({
            type: SET_ESTIMATES,
            estimates: [],
            filters,
            loading: true,
        });
        const { Estimate, Status, DueWithin } = filters;
        const { BusinessUnit, Division } = Estimate;
        const request = axios.get(`${window["apiLocation"]}/api/Estimate`, {
            params: {
                Co,
                BusinessUnit,
                Division,
                Status,
                DueWithin
            }
        });
        request.then((response) => {
            dispatch({
                type: SET_ESTIMATES,
                estimates: response.data,
                filters,
                loading: false
            });
        });
    }
}