import _ from "@lodash";
import { Icon, List, Typography } from "@material-ui/core";
import React, { Component } from "react";
import DispatchBoardWOWrapper from "./DispatchBoardWOWrapper";

class DispatchBoardFolderContents extends Component {
  state = {
    visible: {},
    hasRef: false,
    observer: null,
    entries: [],
  };

  handleIntersect = (entries, observer) => {
    const allEntries = [...this.state.entries, ...entries];
    this.setState({ entries: allEntries }, () =>
      this.setVisible(this.state.entries)
    );
  };

  setVisible = _.debounce((entries) => {
    const visible = _.cloneDeepWith(this.state.visible);
    entries.forEach((entry) => {
      const { id } = entry.target;
      const { top, bottom } = entry.boundingClientRect;
      if (entry.isIntersecting) {
        visible[id] = true;
      } else {
        delete visible[id];
      }
    });
    this.setState({ visible, entries: [] });
  }, 350);

  handleRef = (ref) => {
    if (ref) {
      this.ref = ref;
      this.setState({ hasRef: true }, () => {
        if (this.ref) {
          const observer = new IntersectionObserver(this.handleIntersect, {
            root: ref,
            threshold: 0.01,
          });

          this.setState({ observer });
        }
      });
    }
  };

  render() {
    const {
      loading,
      canEdit,
      folder,
      data,
      byScope,
      openFolder,
      showHold,
      unassigned,
    } = this.props;
    const { visible } = this.state;

    return (
      <List
        ref={this.handleRef}
        className="p-0 pb-8 overflow-y-auto"
        style={{ maxHeight: "calc(100vh - 386px)" }}
      >
        {!loading &&
          data.map((value, index) => {
            const { scopes, hold } = value;
            return (
              <div key={value.WorkOrder} className="w-full">
                {!byScope && (
                  <DispatchBoardWOWrapper
                    showHold={showHold}
                    unassigned={unassigned}
                    observer={this.state.observer}
                    placeholder={!visible[`WO-${value.WorkOrder}`]}
                    id={`WO-${value.WorkOrder}`}
                    workOrder={value}
                    scopes={scopes}
                  />
                )}
                {byScope &&
                  scopes.map((scope) => (
                    <DispatchBoardWOWrapper
                      unassigned={unassigned}
                      showHold={showHold}
                      observer={this.state.observer}
                      placeholder={
                        !visible[`WO-${value.WorkOrder}-${scope.Scope}`]
                      }
                      id={`WO-${value.WorkOrder}-${scope.Scope}`}
                      key={`${value.WorkOrder}-${scope.Scope}`}
                      workOrder={{ ...value, Description: scope.Description }}
                      scopes={[scope]}
                    />
                  ))}
              </div>
            );
          })}
        {folder && openFolder && canEdit && (
          <Typography
            onClick={() => openFolder(folder)}
            variant="h6"
            className="text-12 w-full text-right mt-8 pr-12 cursor-pointer"
            style={{ marginBottom: -8 }}
          >
            <Icon className="align-middle text-16 mr-6 mb-4" color="primary">
              edit
            </Icon>
            Edit Folder
          </Typography>
        )}
      </List>
    );
  }
}

export default DispatchBoardFolderContents;
