import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CallGathersList extends Component {

    state = {
        selectedCallGathersMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCallGatherMenu = (event) => {
        this.setState({ selectedCallGathersMenu: event.currentTarget });
    };

    closeSelectedCallGathersMenu = () => {
        this.setState({ selectedCallGathersMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, securables, callGathers, user, searchText, openNewCallGatherDialog, selectedCallGatherIds, selectAllCallGathers, deSelectAllCallGathers, toggleInSelectedCallGathers, openEditCallGatherDialog, removeCallGathers, removeCallGather, toggleStarredCallGather, setCallGathersUnstarred, setCallGathersStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedCallGathersData ? (!this.props.callGatherData ? [] : this.props.callGatherData) : this.props.selectedCallGathersData), searchText);
        const { selectedCallGathersMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "call-gathers" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewCallGatherDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">dialpad</Icon>Call Gathers</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditCallGatherDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Gather",
                                accessor: "ID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            // {
                            //     Header: "Description",
                            //     accessor: "Description",
                            //     minWidth: 256,
                            // },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Gathers found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCallGathers: Actions.getCallGathers,
        getUserData: Actions.getUserData,
        toggleInSelectedCallGathers: Actions.toggleInSelectedCallGathers,
        selectAllCallGathers: Actions.selectAllCallGathers,
        deSelectAllCallGathers: Actions.deSelectAllCallGathers,
        openEditCallGatherDialog: Actions.openEditCallGatherDialog,
        removeCallGathers: Actions.removeCallGathers,
        removeCallGather: Actions.removeCallGather,
        toggleStarredCallGather: Actions.toggleStarredCallGather,
        toggleStarredCallGathers: Actions.toggleStarredCallGathers,
        setCallGathersStarred: Actions.setCallGathersStarred,
        setCallGathersUnstarred: Actions.setCallGathersUnstarred,
        openNewCallGatherDialog: Actions.openNewCallGatherDialog
    }, dispatch);
}

function mapStateToProps({ callGathersApp, spReducers }) {
    return {
        callGathers: callGathersApp.callGathers.entities,
        selectedCallGatherIds: callGathersApp.callGathers.selectedCallGatherIds,
        searchText: callGathersApp.callGathers.searchText,
        user: callGathersApp.user,
        callGatherData: spReducers.callGathers,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallGathersList)));
