import React, { Component } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import { withStyles } from '@material-ui/core/styles/index';
// import { Avatar, Divider, Icon, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import MailCompose from 'main/content/apps/mail/MailCompose';
import { connect } from 'react-redux';

require('grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.min.js');

const styles = theme => ({
    editor: {
        height: 'calc(100vh - 128px) !important',
        minHeight: 'calc(100vh - 128px) !important',
        '& .gjs-cv-canvas': {
            top: 0,
            height: 'calc(100% - 48px)',
            backgroundColor: '#2f2f2f',
            width: 'calc(100% - 256px)',
        },
        '& .gjs-pn-panel': {
            minHeight: 48,
            padding: 8,
        },
        '& .gjs-pn-views': {
            bottom: 0,
            width: 256,
            top: 'unset',
            borderBottom: 'none',
            borderTop: '2px solid rgba(0,0,0,.2)'
        },
        '& .gjs-pn-options': {
            bottom: 0,
            top: 'unset',
            borderRight: '2px solid rgba(0,0,0,.2)',
            right: 256,
        },
        '& .gjs-pn-devices': {
            bottom: 0,
            top: 'unset',
            left: 'unset',
        },
        '& .gjs-pn-devices-c': {
            bottom: 0,
            top: 'unset',
            left: 'unset',
        },
        '& .gjs-pn-btn': {
            padding: 4,
            minHeight: 32,
            minWidth: 32,
            lineHeight: '24px',
        },
        '& .gjs-pn-btn:hover': {
            color: theme.palette.primary.main
        },
        '& .gjs-four-color': {
            color: theme.palette.secondary.main
        },
        '& .gjs-four-color-h:hover': {
            color: theme.palette.primary.main
        },
        '& .gjs-pn-views-container': {
            top: 0,
            height: 'calc(100% - 48px)',
            width: 256,
        },
        '& .gjs-pn-views-container *': {
            fontSize: '1rem'
        },
        '& .gjs-rte-toolbar': {
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            border: '1px solid #3b97e3',
        },
        '& .gjs-rte-action': {
            fontSize: '1.5rem',
            minWidth: 32,
            borderRight: '1px solid #9f9f9f'
        },
        '& .gjs-rte-active': {
            backgroundColor: '#333',
        },
        '& .gjs-toolbar-item': {
            fontSize: '1.5rem',
            width: 32,
            padding: '8px 10px',
        },
        '& .gjs-off-prv': {
            fontSize: '1.5rem'
        }

    }
});

const initialState = {
    editor: null,
    testEmail: null,
}
class EmailTemplateBuilder extends Component {
    state = {
        ...initialState
    }
    componentDidMount() {
        // window["warn"](CKEDITOR);
        const { template, components, style, user, onLoaded, token } = this.props;
        const { Co, ID } = template;
        let editor = grapesjs.init({
            container: '#editor',
            components,
            storageManager: false,
            noticeOnUnload: false,
            colorPicker: { appendTo: 'parent', offset: { top: 26, left: -188 } },
            plugins: ['gjs-preset-newsletter'],
            style: `
            body {background: transparent;width: 100%; -webkit-font-smoothing: antialiased;}
            table {border-collapse: collapse;}`,
            assetManager: {
                upload: `${window["apiLocation"]}/api/Blob/PostBlob?filepath=${encodeURIComponent(`assets/images/templates/`)}`,
                headers: {
                    'Authorization': token
                },
                assets: template.Data && template.Data.Assets ? template.Data.Assets : []
            },
            pluginsOpts: {
                'gjs-preset-newsletter': {
                    cellStyle: {
                        'font-size': '12px',
                        'font-weight': 300,
                        'vertical-align': 'top',
                        color: '#333',
                        margin: 0,
                        padding: 0,
                    },
                    tableStyle: {
                        height: 'auto',
                        width: '100%',
                        'max-width': '512px',
                        'min-height': '128px',
                        margin: '0 auto 12px auto',
                        padding: '5px 5px 5px 5px',
                    }
                },
            }
        });
        editor.on('asset:upload:response', (response) => {
            const am = editor.AssetManager;
            const uploaded = [];
            Object.keys(response).map((value, index) => {
                uploaded.push(response[value]);
            });
            am.add(uploaded);
        });
        // var mdlClass = 'gjs-mdl-dialog-sm';
        var pnm = editor.Panels;
        // var cmdm = editor.Commands;
        // var md = editor.Modal;
        var bm = editor.BlockManager;
        bm.add('spacer', {
            label: `Spacer`,
            content: '<div style="height:12px;">&nbsp;</div>',
            attributes: {
                title: 'Insert empty spacer',
                class:'fa fa-window-minimize',
            }
        });
        const rte = editor.RichTextEditor;
        rte.remove('link');
        // Add info command
        pnm.removePanel('commands');
        pnm.removeButton('options', 'gjs-toggle-images')
        pnm.addButton('options', [{
            id: 'preview',
            className: 'fa fa-eye',
            attributes: { title: 'Preview' },
            command: function () { editor.runCommand('core:preview') }
        }, {
            id: 'undo',
            className: 'fa fa-undo',
            attributes: { title: 'Undo' },
            command: function () { editor.runCommand('core:undo') }
        }, {
            id: 'redo',
            className: 'fa fa-repeat',
            attributes: { title: 'Redo' },
            command: function () { editor.runCommand('core:redo') }
        }, {
            id: 'clear-all',
            className: 'fa fa-trash icon-blank',
            attributes: { title: 'Clear canvas' },
            command: {
                run: function (editor, sender) {
                    sender && sender.set('active', false);
                    // if (confirm('Are you sure to clean the canvas?')) {
                    editor.DomComponents.clear();
                    //     setTimeout(function () {
                    //         localStorage.clear()
                    //     }, 0)
                    // }
                }
            }
        }, {
            id: 'send-test',
            className: 'fa fa-paper-plane icon-blank',
            attributes: { title: 'Send test email' },
            command: {
                run: () => {
                    const Body = `${editor.runCommand('gjs-get-inlined-html').toString()}`;
                    const Head = `
                        <head>
                            <meta http-equiv="Content-Type" content="text/html; charset=us-ascii" />

                            <title>Email</title>
                                                                                                                                                                                                                                                                                                                                                                                                                                
                            <style type="text/css">
                                body {width: 100%; -webkit-font-smoothing: antialiased;}
                                table {border-collapse: collapse;}
                            </style>
                        </head>`;
                    // const Css = editor.getCss().toString();
                    // console.log(Body, Css);
                    const data = {
                        composeDialog: true,
                        Title: 'Send Test Email',
                        Icon: "email",
                        To: `${user.Email};`,
                        Subject: `${template.Name} Test`,
                        Head,
                        Message: '',
                        Body,
                        Attachments: [],
                    }
                    this.setState({ ...this.state, testEmail: { ...data } });
                }
            }
        }]);

        this.setState({ ...this.state, editor }, () => {
            if (onLoaded) {
                onLoaded(editor);
            }
        });
    }

    render() {
        const { classes, user, height } = this.props;
        const { editor, testEmail } = this.state;
        return (
            <div>
                <div id="editor" className={classes.editor} style={{ height }}></div>
                {
                    testEmail &&
                    <MailCompose onClose={() => this.setState({ ...this.state, testEmail: null })} hideButton={true} data={{ ...testEmail }} />
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers, spAuth }) {
    return {
        user: spReducers.userProfiles.User,
        token: spAuth.authentication.access_token
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(EmailTemplateBuilder));
