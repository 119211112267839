import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class ServiceUnitsList extends Component {

    state = {
        selectedServiceUnitsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedServiceUnitMenu = (event) => {
        this.setState({ selectedServiceUnitsMenu: event.currentTarget });
    };

    closeSelectedServiceUnitsMenu = () => {
        this.setState({ selectedServiceUnitsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, serviceUnitTypes, serviceUnitCategories, user, customerSite, searchText, costTypes, selectedServiceUnitIds, selectAllServiceUnits, deSelectAllServiceUnits, technicians, toggleInSelectedServiceUnits, openEditServiceUnitDialog, removeServiceUnits, removeServiceUnit, toggleStarredServiceUnit, setServiceUnitsUnstarred, setServiceUnitsStarred, openNewServiceUnitDialog, edit } = this.props;
        const data = this.getFilteredArray((!this.props.selectedServiceUnitData ? !this.props.serviceUnitData ? [] : this.props.serviceUnitData : this.props.selectedServiceUnitData), searchText);
        const { selectedServiceUnitsMenu } = this.state;
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        return (
            <FuseAnimate animation={this.props.animation || "transition.slideUpIn"} delay={300}>
                <div>
                    {customerSite && edit !== false &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={() => {
                                const { Co, Customer, Site } = customerSite;
                                openNewServiceUnitDialog({
                                    Co,
                                    Customer,
                                    Site,
                                });
                            }}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center', display: edit === false ? 'none' : 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">{customerSite ? "build" : "list"}</Icon>Service Units</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo && edit !== false) {
                                        openEditServiceUnitDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "",
                                id: "avatar",
                                className: "justify-center",
                                minWidth: 64,
                                fixed: "left",
                                accessor: r => <Avatar style={{ cursor: 'pointer', boxShadow: '2px 2px 4px 0px #3f3f3f' }} className="w-1/2 h-auto" alt="Service Unit Image" src={(r.Data && r.Data.Avatar ? (!isNaN(r.Data.Avatar) ? getProfileImage(`Co=${r.Co}&ID=${r.Data.Avatar}` ): r.Data.Avatar) : r.avatar ? r.avatar : avatar)} />
                            },
                            {
                                Header: "UnitID",
                                accessor: "UnitID",
                                minWidth: 128,
                                fixed: "left",
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Active?",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                minWidth: 64,
                            },
                            {
                                Header: "Unit Category",
                                id: "unitCat",
                                minWidth: 128,
                                className: "justify-center",
                                accessor: d => (d.UnitCat && serviceUnitCategories) && `${_.find(serviceUnitCategories, { UnitCat: d.UnitCat }).Name}`
                            },
                            {
                                Header: "Unit Type",
                                id: "unitType",
                                minWidth: 192,
                                className: "justify-center",
                                accessor: d => (d.UnitType && serviceUnitTypes) && `${_.find(serviceUnitTypes, { UnitType: d.UnitType }).Name}`
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                minWidth: 128,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 256,
                            },
                            {
                                Header: "Manufacturer",
                                accessor: "Manufacturer",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Model",
                                accessor: "Model",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Serial #",
                                accessor: "SerialNumber",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Manufacture Date",
                                accessor: "ManufactureDate",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            // {
                            //     Header: "",
                            //     accessor: "UnitID",
                            //     className: "justify-center",
                            //     minWidth: 64,
                            //     fixed: "right"
                            // },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Service Units found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServiceUnits: Actions.getServiceUnits,
        getUserData: Actions.getUserData,
        toggleInSelectedServiceUnits: Actions.toggleInSelectedServiceUnits,
        selectAllServiceUnits: Actions.selectAllServiceUnits,
        deSelectAllServiceUnits: Actions.deSelectAllServiceUnits,
        openEditServiceUnitDialog: Actions.openEditServiceUnitDialog,
        removeServiceUnits: Actions.removeServiceUnits,
        removeServiceUnit: Actions.removeServiceUnit,
        toggleStarredServiceUnit: Actions.toggleStarredServiceUnit,
        toggleStarredServiceUnits: Actions.toggleStarredServiceUnits,
        setServiceUnitsStarred: Actions.setServiceUnitsStarred,
        setServiceUnitsUnstarred: Actions.setServiceUnitsUnstarred,
        openNewServiceUnitDialog: Actions.openNewServiceUnitDialog
    }, dispatch);
}

function mapStateToProps({ serviceUnitsApp, spReducers }) {
    return {
        serviceUnitData: spReducers.serviceUnits,
        searchText: serviceUnitsApp.serviceUnits.searchText,
        user: spReducers.userProfiles.User.UserName,
        serviceUnitTypes: spReducers.serviceUnitTypes,
        serviceUnitCategories: spReducers.serviceUnitCategories,
        technicians: spReducers.technicians,
        costTypes: spReducers.costTypes,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitsList)));
