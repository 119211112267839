import axios from 'axios';
import { getUserData } from 'main/content/apps/checklists/store/actions/user.actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const SET_SEARCH_TEXT = '[CHECKLISTS APP] SET SEARCH TEXT';
export const OPEN_NEW_CHECKLIST_DIALOG = '[CHECKLISTS APP] OPEN NEW CHECKLIST DIALOG';
export const CLOSE_NEW_CHECKLIST_DIALOG = '[CHECKLISTS APP] CLOSE NEW CHECKLIST DIALOG';
export const OPEN_EDIT_CHECKLIST_DIALOG = '[CHECKLISTS APP] OPEN EDIT CHECKLIST DIALOG';
export const ADD_EDIT_CHECKLIST_DIALOG = '[CHECKLISTS APP] ADD EDIT CHECKLIST DIALOG';
export const UPDATE_EDIT_CHECKLIST_DIALOG = '[CHECKLISTS APP] UPDATE EDIT CHECKLIST DIALOG';
export const DATA_EDIT_CHECKLIST_DIALOG = '[CHECKLISTS APP] DATA EDIT CHECKLIST DIALOG';
export const CLOSE_EDIT_CHECKLIST_DIALOG = '[CHECKLISTS APP] CLOSE EDIT CHECKLIST DIALOG';
export const OPEN_NEW_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] OPEN NEW CHECKLIST SECTION DIALOG';
export const CLOSE_NEW_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] CLOSE NEW CHECKLIST SECTION DIALOG';
export const OPEN_EDIT_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] OPEN EDIT CHECKLIST SECTION DIALOG';
export const ADD_EDIT_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] ADD EDIT CHECKLIST SECTION DIALOG';
export const UPDATE_EDIT_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] UPDATE EDIT CHECKLIST SECTION DIALOG';
export const DATA_EDIT_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] DATA EDIT CHECKLIST SECTION DIALOG';
export const CLOSE_EDIT_CHECKLIST_SECTION_DIALOG = '[CHECKLISTS APP] CLOSE EDIT CHECKLIST SECTION DIALOG';
export const OPEN_NEW_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] OPEN NEW CHECKLIST ITEM DIALOG';
export const CLOSE_NEW_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] CLOSE NEW CHECKLIST ITEM DIALOG';
export const OPEN_EDIT_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] OPEN EDIT CHECKLIST ITEM DIALOG';
export const ADD_EDIT_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] ADD EDIT CHECKLIST ITEM DIALOG';
export const UPDATE_EDIT_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] UPDATE EDIT CHECKLIST ITEM DIALOG';
export const DATA_EDIT_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] DATA EDIT CHECKLIST ITEM DIALOG';
export const CLOSE_EDIT_CHECKLIST_ITEM_DIALOG = '[CHECKLISTS APP] CLOSE EDIT CHECKLIST ITEM DIALOG';
export const OPEN_NEW_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] OPEN NEW CHECKLIST ASSIGNMENT DIALOG';
export const CLOSE_NEW_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] CLOSE NEW CHECKLIST ASSIGNMENT DIALOG';
export const OPEN_EDIT_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] OPEN EDIT CHECKLIST ASSIGNMENT DIALOG';
export const ADD_EDIT_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] ADD EDIT CHECKLIST ASSIGNMENT DIALOG';
export const UPDATE_EDIT_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] UPDATE EDIT CHECKLIST ASSIGNMENT DIALOG';
export const DATA_EDIT_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] DATA EDIT CHECKLIST ASSIGNMENT DIALOG';
export const CLOSE_EDIT_CHECKLIST_ASSIGNMENT_DIALOG = '[CHECKLISTS APP] CLOSE EDIT CHECKLIST ASSIGNMENT DIALOG';
export const ADD_CHECKLIST = '[CHECKLISTS APP] ADD CHECKLIST';
export const UPDATE_CHECKLIST = '[CHECKLISTS APP] UPDATE CHECKLIST';
export const REMOVE_CHECKLIST = '[CHECKLISTS APP] REMOVE CHECKLIST';
export const ADD_CHECKLIST_SECTION = '[CHECKLISTS APP] ADD CHECKLIST SECTION';
export const UPDATE_CHECKLIST_SECTION = '[CHECKLISTS APP] UPDATE CHECKLIST SECTION';
export const REMOVE_CHECKLIST_SECTION = '[CHECKLISTS APP] REMOVE CHECKLIST SECTION';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function openNewChecklistDialog(data) {
    return {
        type: OPEN_NEW_CHECKLIST_DIALOG,
        data
    }
}

export function closeNewChecklistDialog() {
    return {
        type: CLOSE_NEW_CHECKLIST_DIALOG
    }
}

export function openEditChecklistDialog(data) {
    return (dispatch, getState) => {

        const state = getState();
        window["warn"](state);

        const { Co, Checklist } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Checklist?Co=${encodeURIComponent(Co)}&Checklist=${encodeURIComponent(Checklist)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_CHECKLIST_DIALOG,
                    data: response.data
                }),
            ])
        );
    };
}

export function updateEditChecklistDialog(data) {
    return {
        type: DATA_EDIT_CHECKLIST_DIALOG,
        data
    }
}

export function closeEditChecklistDialog() {
    return {
        type: CLOSE_EDIT_CHECKLIST_DIALOG
    }
}

export function addChecklist(newChecklist) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Checklist`,
            newChecklist
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_EDIT_CHECKLIST_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist #${response.data.Checklist} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditChecklistDialog(response.data))
            })
        );
    };
}

export function updateChecklist(checklist) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/Checklist?Co=${encodeURIComponent(checklist.Co)}&Checklist=${encodeURIComponent(checklist.Checklist)}`,
            checklist
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_CHECKLIST_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist #${response.data.Checklist} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditChecklistDialog(checklist))
            })
        );
    };
}

export function openNewChecklistSectionDialog(data) {
    return {
        type: OPEN_NEW_CHECKLIST_SECTION_DIALOG,
        data
    }
}

export function closeNewChecklistSectionDialog() {
    return {
        type: CLOSE_NEW_CHECKLIST_SECTION_DIALOG
    }
}

export function openEditChecklistSectionDialog(data) {
    return {
        type: OPEN_EDIT_CHECKLIST_SECTION_DIALOG,
        data: data
    }
}

export function updateEditChecklistSectionDialog(data) {
    return {
        type: DATA_EDIT_CHECKLIST_SECTION_DIALOG,
        data
    }
}

export function closeEditChecklistSectionDialog() {
    return {
        type: CLOSE_EDIT_CHECKLIST_SECTION_DIALOG
    }
}

export function addChecklistSection(newChecklistSection) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;


        const request = axios.post(`${window["apiLocation"]}/api/ChecklistSection`,
            newChecklistSection
        );

        return request.then((response) =>
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Sections: [...data.Data.Sections, response.data],
                    }
                })),
                dispatch(updateEditChecklistSectionDialog({
                    ...response.data
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Section has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateChecklistSection(section) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;
        console.warn(section.Data.Items);


        const request = axios.put(`${window["apiLocation"]}/api/ChecklistSection?Co=${encodeURIComponent(section.Co)}&Checklist=${encodeURIComponent(section.Checklist)}&Section=${encodeURIComponent(section.Section)}`,
            section
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Sections, { ID: response.data.ID });
            data.Data.Sections.splice(index, 1, { ...response.data, Data: { ...response.data.Data, Items: section.Data.Items } });
            console.warn(data);
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Sections: [...data.Data.Sections],
                    }
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Section has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeChecklistSection(section) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;


        const request = axios.delete(`${window["apiLocation"]}/api/ChecklistSection?Co=${encodeURIComponent(section.Co)}&Checklist=${encodeURIComponent(section.Checklist)}&Section=${encodeURIComponent(section.Section)}`,
            section
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Sections, { ID: response.data.ID });
            data.Data.Sections.splice(index, 1);
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Sections: [...data.Data.Sections],
                    }
                }))
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Section has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function openNewChecklistItemDialog(data) {
    return {
        type: OPEN_NEW_CHECKLIST_ITEM_DIALOG,
        data
    }
}

export function closeNewChecklistItemDialog() {
    return {
        type: CLOSE_NEW_CHECKLIST_ITEM_DIALOG
    }
}

export function openEditChecklistItemDialog(data) {
    return {
        type: OPEN_EDIT_CHECKLIST_ITEM_DIALOG,
        data: data
    }
}

export function updateEditChecklistItemDialog(data) {
    return {
        type: DATA_EDIT_CHECKLIST_ITEM_DIALOG,
        data
    }
}

export function closeEditChecklistItemDialog() {
    return {
        type: CLOSE_EDIT_CHECKLIST_ITEM_DIALOG
    }
}

export function addChecklistItem(newChecklistItem) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;
        const sectionData = _.find(data.Data.Sections, { Section: newChecklistItem.Section });


        const request = axios.post(`${window["apiLocation"]}/api/ChecklistItem`,
            newChecklistItem
        );

        return request.then((response) => {
            const section = _.find(data.Data.Sections, { Section: response.data.Section });
            section.Data.Items.push(response.data);
            // sectionData.Data.Items.push(response.data);
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                })),
                dispatch(updateEditChecklistSectionDialog({
                    ...sectionData,
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Item has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateChecklistItem(item) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;
        const sectionData = _.find(data.Data.Sections, { Section: item.Section });


        const request = axios.put(`${window["apiLocation"]}/api/ChecklistItem?Co=${encodeURIComponent(item.Co)}&Checklist=${encodeURIComponent(item.Checklist)}&Section=${item.Section}&Item=${encodeURIComponent(item.Item)}`,
            item
        );

        return request.then((response) => {
            const sectionIndex = _.findIndex(sectionData.Data.Items, { Item: item.Item });
            if (sectionIndex > -1) {
                sectionData.Data.Items.splice(sectionIndex, 1, response.data);
            }
            const section = _.find(data.Data.Sections, { Section: item.Section });
            const index = _.findIndex(section.Data.Items, { Item: item.Item });
            if (index > -1) {
                section.Data.Items.splice(index, 1, response.data);
            }
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                })),
                dispatch(updateEditChecklistSectionDialog({
                    ...sectionData,
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Item has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeChecklistItem(item) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;
        const sectionData = _.find(data.Data.Sections, { Section: item.Section });


        const request = axios.delete(`${window["apiLocation"]}/api/ChecklistItem?Co=${encodeURIComponent(item.Co)}&Checklist=${encodeURIComponent(item.Checklist)}&Section=${item.Section}&Item=${encodeURIComponent(item.Item)}`,
            item
        );

        return request.then((response) => {
            const sectionIndex = _.findIndex(sectionData.Data.Items, { Item: item.Item });
            if (sectionIndex > -1) {
                sectionData.Data.Items.splice(sectionIndex, 1);
            }
            const section = _.find(data.Data.Sections, { Section: item.Section });
            const index = _.findIndex(section.Data.Items, { Item: item.Item });
            if (index > -1) {
                section.Data.Items.splice(index, 1);
            }
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                })),
                dispatch(updateEditChecklistSectionDialog({
                    ...sectionData,
                }))
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Item has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeChecklist(checklistId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().checklistsApp.checklists;

        const request = axios.post('/api/checklists-app/remove-checklist', {
            checklistId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CHECKLIST
                })
            ])
        );
    };
}


export function openNewChecklistAssignmentDialog(data) {
    return {
        type: OPEN_NEW_CHECKLIST_ASSIGNMENT_DIALOG,
        data
    }
}

export function closeNewChecklistAssignmentDialog() {
    return {
        type: CLOSE_NEW_CHECKLIST_ASSIGNMENT_DIALOG
    }
}

export function openEditChecklistAssignmentDialog(data) {
    return {
        type: OPEN_EDIT_CHECKLIST_ASSIGNMENT_DIALOG,
        data: data
    }
}

export function updateEditChecklistAssignmentDialog(data) {
    return {
        type: DATA_EDIT_CHECKLIST_ASSIGNMENT_DIALOG,
        data
    }
}

export function closeEditChecklistAssignmentDialog() {
    return {
        type: CLOSE_EDIT_CHECKLIST_ASSIGNMENT_DIALOG
    }
}

export function addChecklistAssignment(newChecklistAssignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;


        const request = axios.post(`${window["apiLocation"]}/api/ChecklistAssignment`,
            newChecklistAssignment
        );

        return request.then((response) => {
            data.Data.Assignments.push(response.data);
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                })),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Assignment has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateChecklistAssignment(assignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;


        const request = axios.put(`${window["apiLocation"]}/api/ChecklistAssignment?Co=${encodeURIComponent(assignment.Co)}&Checklist=${encodeURIComponent(assignment.Checklist)}&ID=${encodeURIComponent(assignment.ID)}`,
            assignment
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Assignments, { ID: assignment.ID });
            if (index > -1) {
                data.Data.Assignments.splice(index, 1, response.data);
            }
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                })),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Assignment has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeChecklistAssignment(assignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.checklistsApp.checklists.checklistDialog;


        const request = axios.delete(`${window["apiLocation"]}/api/ChecklistAssignment?Co=${encodeURIComponent(assignment.Co)}&Checklist=${encodeURIComponent(assignment.Checklist)}&ID=${encodeURIComponent(assignment.ID)}`,
            assignment
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Assignments, { ID: assignment.ID });
            if (index > -1) {
                data.Data.Assignments.splice(index, 1);
            }
            Promise.all([
                dispatch(updateEditChecklistDialog({
                    ...data,
                })),
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Checklist Assignment has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}