import axios from 'axios/index';

export const SET_CALL_GATHER_DATA = '[SPCONNECTION] SET CALL GATHER DATA';
export const GET_CALL_GATHER_DATA = '[SPCONNECTION] GET CALL GATHER DATA';
export const SUBSCRIBE_CALL_GATHER_LIST = '[SPCONNECTION] SUBSCRIBE CALL GATHER LIST';

export function getInitialCallGatherData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CallGather`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getCallGatherData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CallGather`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CALL_GATHER_DATA,
                payload: response.data
            })
        });
    }
}

export function setCallGatherData(data) {
    return {
        type: SET_CALL_GATHER_DATA,
        payload: data
    };
}