import { FuseAnimate, FuseUtils } from '@fuse';
import { Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import _ from '@lodash';
import InvoicePaymentRecord from './InvoicePaymentRecord';
import * as Actions from './store/actions';
import axios from 'axios';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Records Found
            </div>
        </div>
    )
}

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

const initialState = {
    viewPayment: null,
    payments: [],
    hist: null,
    loading: false
}

class PaymentsList extends Component {

    state = {
        ..._.cloneDeepWith(initialState)
    };

    componentDidMount() {
        const { payments, hist, customer, Co } = this.props;
        this.setState({ ..._.cloneDeepWith(initialState), payments, hist }, () => {
            if (customer && hist) {
                this.fetchData(Co, hist.Page);
            }
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { payments, hist } = this.props;
        if (!_.isEqual(payments, prevProps.payments) || !_.isEqual(hist, prevProps.hist)) {
            this.setState({ ..._.cloneDeepWith(initialState), payments, hist });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    handleVoidPayment = (payment) => {
        const { User } = this.props;
        const { payments } = this.state;
        if (payment) {
            payment.VoidDate = payment.VoidDate || new Date();
            payment.VoidedBy = payment.VoidedBy || User.UserName;
            if (payments) {
                const index = _.findIndex(payments, { PaymentID: payment.PaymentID });
                window["warn"]('Voided Payment: ', payment, index, payments);
                if (index > -1) {
                    payments.splice(index, 1, payment);
                }
            }
            this.setState({ ...this.state, payments }, () => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(payments);
                }
            });
        }
    }

    fetchData = (Co, Page) => {
        const { customer, site, hist } = this.props;

        if (hist) {
            this.setState({ loading: true }, () => {
                const request = axios.get(`${window["apiLocation"]}/api/PaymentHistory?Co=${Co}&Customer=${customer}${site ? `&Site=${site}` : ''}&Page=${Page}`);
                request.then((response) => {
                    this.setState({ hist: response.data, loading: false });
                }).catch(() => {
                    this.setState({ loading: false });
                })
            });
        }
    }

    render() {
        const { classes, searchText, openEditPaymentDialog, edit, Co } = this.props;
        const { payments, viewPayment, hist, loading } = this.state;
        const data = payments;
        const cardTypes = { visa: "Visa", mastercard: "MasterCard", discover: "Discover", american_express: "American Express" };
        const paymentTypes = { "CREDIT": "Credit / Debit", "CASH": "Cash", "CHECK": "Check", "OTHER": "Other" };
        const manualOptions = (hist ? {
            manual: true,
            data: hist.Results,
            pages: hist.PageCount,
            onFetchData: (e) => { this.fetchData(Co, e.page + 1); },
            defaultPageSize: 10,
            pageSizeOptions: [10],
            sortable: false
        } : {
            data,
            defaultPageSize: 10,
        });

        return (
            <FuseAnimate animation={this.props.animation || "transition.slideUpIn"} delay={300}>
                <div>
                    {/* <Button
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewPaymentDialog}
                        >
                            <Icon>add</Icon>
                        </Button> */}
                    <Typography variant="h6" style={{ alignItems: 'center', display: edit === false ? 'none' : 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">payment</Icon>Payments</Typography>
                    {this.state.viewPayment &&
                        <InvoicePaymentRecord open={Boolean(this.state.viewPayment)} payment={{ ...this.state.viewPayment }} onVoid={this.handleVoidPayment} onClose={() => { this.setState({ ...this.state, viewPayment: false }) }} />
                    }
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: classNames("cursor-pointer", rowInfo && rowInfo.original.VoidDate ? "line-through text-grey" : ""),
                                onClick: () => {
                                    if (rowInfo && edit !== false) {
                                        this.setState({ viewPayment: rowInfo.original });
                                    }
                                }
                            }
                        }}
                        {...manualOptions}
                        loading={loading || this.state.loading} // Display the loading overlay when we need it
                        LoadingComponent={loading ? LoadingDiv : undefined}
                        NoDataComponent={loading ? LoadingDiv : NoData}
                        columns={[
                            {
                                Header: "Date",
                                accessor: "PaymentDateTime",
                                className: "font-bold justify-center",
                                Cell: row => new Date(row.value).toLocaleDateString('en-US'),
                                width: 128
                            },
                            {
                                Header: "Type",
                                accessor: "PaymentType",
                                className: "justify-center",
                                width: 128,
                                Cell: row => paymentTypes[row.value]
                            },
                            {
                                Header: "Amount",
                                accessor: "PaymentTotal",
                                Cell: row => this.formatDollars(row.value),
                                className: "justify-center",
                                width: 96
                            },
                            {
                                Header: "Memo",
                                accessor: "Memo",
                                width: 256
                            },
                            {
                                Header: "Payment ID",
                                accessor: "PaymentID",
                                className: "justify-center",
                                width: 312
                            },
                        ]}
                        // defaultPageSize={10}
                        noDataText="No Payments found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // openEditPaymentDialog: Actions.openEditPaymentDialog,
        // openNewPaymentDialog: Actions.openNewPaymentDialog
    }, dispatch);
}

function mapStateToProps({ paymentsApp, spReducers }) {
    return {
        User: spReducers.userProfiles.User,
        Co: spReducers.companies.Co
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsList)));
