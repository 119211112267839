import CustomersApp from 'main/content/apps/checklists/ChecklistsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const ChecklistsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/checklists/:id',
            component: CustomersApp
        },
        {
            path     : '/apps/checklists',
            component: () => <Redirect to="/apps/checklists/all"/>
        }
    ]
};
