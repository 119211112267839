import CustomerSitesApp from 'main/content/apps/customer-sites/CustomerSitesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const CustomerSitesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/customer-sites/:id',
            component: CustomerSitesApp
        },
        {
            path     : '/apps/customer-sites',
            component: () => <Redirect to="/apps/customer-sites/all"/>
        }
    ]
};
