import {combineReducers} from 'redux';
import fleetVehicleCategories from './fleet-vehicle-categories.reducer';
import user from './user.reducer';

const fleetVehicleCategoriesAppReducers = combineReducers({
    fleetVehicleCategories,
    user
});

export default fleetVehicleCategoriesAppReducers;
