import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, List, Typography } from '@material-ui/core';
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse';
import TodoListItem from './TodoListItem';
import _ from '@lodash';
import { RecentActorsSharp } from '@material-ui/icons';

const styles = theme => ({
    todoList: {
        padding: 0
    },
    todoItem: {},
    labels: {}
});

const initialState = {
    filtered: []
}

class TodoList extends Component {
    state = {
        ...initialState
    }

    componentDidMount() {
        const { todos, routeParams } = this.props;
        const filtered = this.filterToDos(todos, routeParams);
        this.setState({ filtered });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { todos, routeParams } = this.props;
        if (!_.isEqual(todos, prevProps.todos) || !_.isEqual(routeParams, prevProps.routeParams)) {
            const filtered = this.filterToDos(todos, routeParams);
            this.setState({ filtered });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    filterToDos = (todos, routeParams) => {
        const { folderHandle, filterHandle, labelHandle } = routeParams;
        window["warn"]('To-Do List Params', routeParams);
        let recs = _.cloneDeepWith(todos);
        if (labelHandle) {
            recs = _.filter(recs, (o) => _.findIndex(o.Data.Tags, { TagID: Number(labelHandle) }) > -1 || _.findIndex(o.Data.ProjectTags, { TagID: Number(labelHandle) }) > -1);
        }
        if (filterHandle) {
            switch (filterHandle) {
                case 'starred': {
                    recs = _.filter(recs, { StarredYN: 'Y' });
                }
                    break;
                case 'important': {
                    recs = _.filter(recs, { ImportantYN: 'Y' });
                }
                    break;
                case 'late': {
                    recs = _.filter(recs, (o) => o.CompletedYN !== 'Y' && (o.DueDate && new Date(o.DueDate) < new Date()));
                }
                    break;
                case 'pending': {
                    recs = _.filter(recs, (o) => o.CompletedYN !== 'Y');
                }
                    break;
                case 'completed': {
                    recs = _.filter(todos, { CompletedYN: 'Y' });
                }
                    break;
            }
        }
        window["warn"]('To-Do List Recs', todos, recs);
        return recs;
    }

    render() {
        const { classes, searchText, orderBy, orderDescending } = this.props;
        const { filtered } = this.state;

        const arr = !orderDescending ? _.sortBy(this.getFilteredArray(filtered, searchText), (o) => orderBy === "StartDate" ? new Date(o.StartDate) : orderBy === "DueDate" ? new Date(o.DueDate || '12/31/2099') : o[orderBy]) :
            _.sortBy(this.getFilteredArray(filtered, searchText), (o) => orderBy === "StartDate" ? new Date(o.StartDate) : orderBy === "DueDate" ? new Date(o.DueDate || '12/31/2099') : o[orderBy]).reverse();

        if (arr.length === 0) {
            return (
                <FuseAnimate delay={100}>
                    <div className="flex flex-1 items-center justify-center h-full">
                        <Typography color="textSecondary" variant="h6" className="text-16">
                            Nothing to do? Try adding a task!
                        </Typography>
                    </div>
                </FuseAnimate>
            );
        }

        return (
            <FuseAnimate animation="transition.slideUpIn">
                <List className={classes.todoList}>
                    {
                        arr.map((todo) => (
                            <TodoListItem todo={todo} key={`${todo.Type}${todo.ID}`} />
                        )
                        )
                    }
                </List>
            </FuseAnimate>
        );
    }
}

function mapStateToProps({ todoApp, spReducers }) {
    return {
        todos: spReducers.calendar.data.ToDos,
        searchText: todoApp.todos.searchText,
        orderBy: todoApp.todos.orderBy,
        orderDescending: todoApp.todos.orderDescending,
        routeParams: todoApp.todos.routeParams
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(TodoList)));
