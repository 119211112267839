import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, Badge, FormControlLabel, Checkbox, Dialog, Card, CardHeader, CardContent, Divider, Fab, Icon, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import moment from 'moment';
import classNames from 'classnames';
import axios from 'axios';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    doneAvatar: {
        backgroundColor: '#5f5f5f',
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    card: {
        marginBottom: 12,
        cursor: 'pointer',
        width: '100%',
    },
    cardHeader: {
        padding: 8,
    },
    cardContent: {
        borderTop: '1px solid lightgrey',
        padding: 8,
        paddingBottom: '8px !important',
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    action: {
        marginTop: 0,
        alignSelf: 'auto',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: '1rem'
    },
    badge: {
        top: 6,
        right: 4,
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
    folderBadge: {
        top: 12,
        right: 'unset',
        left: 'calc(108% - 12px)',
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    },
    mailboxAvatar: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 13,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
});

class VoicemailDialog extends Component {

    state = {
        Co: null,
        recording: null,
        user: null,
    }

    componentDidMount() {
        const { Co, recording, user } = this.props;
        this.setState({ ...this.state, Co, recording, user });
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co, recording, user } = this.props;
        if (!_.isEqual(Co, prevProps.Co) || !_.isEqual(recording, prevProps.recording) || !_.isEqual(user, prevProps.user)) {
            this.setState({ ...this.state, Co, recording, user });
        }
    }
    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    stop = (event) => {
        event.stopPropagation();
    }

    formatPhone(phone) {
        return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    markHandled = () => {
        const { recording } = this.state;
        recording.Status = 1;
        recording.HandledBy = this.props.user;
        recording.Data = { ErrMsg: null };
        const request = axios.put(`${window["apiLocation"]}/api/Voicemail?Co=${encodeURIComponent(recording.Co)}&Message=${encodeURIComponent(recording.CallSid)}`,
            recording
        );
        request.then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg === "") {
                this.props.setRecording(null);
            }
        })
    }

    markUnHandled = () => {
        const { recording } = this.state;
        recording.Status = 0;
        recording.HandledBy = null;
        recording.Data = { ErrMsg: null };
        const request = axios.put(`${window["apiLocation"]}/api/Voicemail?Co=${encodeURIComponent(recording.Co)}&Message=${encodeURIComponent(recording.CallSid)}`,
            recording
        );
        request.then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg === "") {
                this.props.setRecording(response.data)
            }
        })
    }

    render() {
        const { classes, voiceMailboxes, voicemail, data, users, about } = this.props;
        const { Co, recording, user, expanded } = this.state;
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const mailboxes = _.filter(voiceMailboxes, (o) => _.findIndex(o.Data.Handlers, { UserName: user }) > -1);
        const box = recording ? _.find(mailboxes, { VoiceMailbox: recording.VoiceMailbox }) : null;
        return (
            <Dialog
                classes={{
                    root: "w-full",
                    paper: "w-full",
                }}
                open={Boolean(recording)}
                onClose={() => this.props.setRecording(null)}
                maxWidth="sm"
            >
                {recording &&
                    <Card key={recording.CallSid} className="w-full relative">
                        <div className="pin-t pin-r mr-8 absolute rounded rounded-t-none px-16" style={{ backgroundColor: '#3f3f3f', color: '#fff' }}>{box.Name}</div>
                        <CardHeader className="p-12 highlight"
                            classes={{ subheader: "text-11", title: "font-bold" }}
                            avatar={
                                <Avatar onClick={(e) => { e.stopPropagation(); }} className={recording.HandledBy && recording.Status > 0 ? classes.doneAvatar : classes.avatar}>
                                    <Icon>voicemail</Icon>
                                </Avatar>
                            }
                            title={`${this.formatPhone(recording.From.replace('+1', ''))}`}
                            subheader={`${moment(recording.ReceivedDate).format("h:mm A")} on ${moment(recording.ReceivedDate).format("MM/DD/YYYY")}`
                            }
                            action={
                                <IconButton style={{ marginTop: 8, }} onClick={(e) => this.props.setRecording(null)} aria-owns={undefined}>
                                    <Icon color="action">close</Icon>
                                </IconButton>
                            }
                        />
                        <CardContent className="w-full p-8">
                            {recording.Transcription ?
                                <div className="p-12 pt-0">
                                    <Typography className="w-full font-bold text-16 mb-8">Transcription:</Typography>
                                    <Typography className="w-full italic text-14 mb-8 max-h-256 overflow-y-auto">"{recording.Transcription}"</Typography>
                                </div> :
                                <div className="p-12 pt-0">
                                    <Typography className="w-full font-bold text-16 mb-8">Transcription Unavailable</Typography>
                                </div>
                            }
                            <audio controls style={{ width: '100%' }} >
                                <source src={recording.Recording} type="audio/x-wav" />
                            </audio>
                            <div className="p-12 w-full">
                                <TextField
                                    className={classNames("mb-12")}
                                    label="Handler Notes"
                                    id="handlerNotes"
                                    name="recording.HandlerNotes"
                                    value={this.state.recording.HandlerNotes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    disabled={recording.HandledBy && recording.HandledBy !== this.props.user}
                                />
                                <TextField
                                    className={classNames("mb-12")}
                                    label="Resolution"
                                    id="resolution"
                                    name="recording.Resolution"
                                    value={this.state.recording.Resolution || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    disabled={recording.HandledBy && recording.HandledBy !== this.props.user}
                                >
                                    <MenuItem value="callback">
                                        Call Back
                                    </MenuItem>
                                    {(about && about.SupportProvider) ?
                                        <MenuItem value="ticket">
                                            Ticket Created
                                        </MenuItem> :
                                        <MenuItem value="scheduled">
                                            Scheduled
                                        </MenuItem>
                                    }
                                    <MenuItem value="resolved">
                                        Resolved
                                    </MenuItem>
                                    <MenuItem value="info">
                                        Information Only
                                    </MenuItem>
                                    <MenuItem value="spam">
                                        Spam Call
                                    </MenuItem>
                                </TextField>
                                {recording.HandledBy &&
                                    <TextField
                                        className={classNames("mb-12")}
                                        label="Handled By"
                                        id="handledBy"
                                        name="recording.HandledBy"
                                        value={this.state.recording.HandledBy}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        select
                                        disabled={true}
                                    >
                                        {users && users.map((value, index) => (
                                            <MenuItem key={index} value={value.UserName}>
                                                <div className="flex">
                                                    <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                    {`${value.FirstName} ${value.LastName}`}
                                                </div>
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                }
                                {!recording.HandledBy ?
                                    <Button variant="contained" className={classNames(classes.blackButton, "w-full")} onClick={this.markHandled}><Icon color="primary" className="mr-8">headset_mic</Icon>Mark as Handled</Button>
                                    :
                                    <Button variant="contained" className={classNames(classes.blackButton, "w-full")} onClick={this.markUnHandled}><Icon color="secondary" className="mr-8">headset_mic</Icon>Mark as Unhandled</Button>
                                }
                            </div>
                        </CardContent>
                    </Card>
                }
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRecording: Actions.setRecording
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp, usersApp, spReducers, version }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        users: spReducers.userProfiles.Users,
        Co: spReducers.companies.Co,
        recording: voiceMailboxesApp.voiceMailboxes.recording,
        voiceMailboxes: spReducers.voiceMailboxes,
        voicemail: spReducers.voicemail,
        about: version.about
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VoicemailDialog)));
