import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DialGroupDialog from 'main/content/apps/dial-groups/DialGroupDialog';
import DialGroupHeader from 'main/content/apps/dial-groups/DialGroupsHeader';
import DialGroupsLeftSidebarHeader from 'main/content/apps/dial-groups/DialGroupsLeftSidebarHeader';
import DialGroupList from 'main/content/apps/dial-groups/DialGroupsList';
import DialGroupSidebarContent from 'main/content/apps/dial-groups/DialGroupsSidebarContent';
import React, { Component } from 'react';
// import DialGroupKeysDialog from 'main/content/apps/dial-group-keys/DialGroupKeysDialog';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right: 12,
        bottom: 12,
        zIndex: 99
    }
});

class DialGroupsApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getDialGroups(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewDialGroupDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <DialGroupHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <DialGroupsLeftSidebarHeader />
                            }
                            content={
                                <DialGroupList />
                            }
                            leftSidebarContent={
                                <DialGroupSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={classNames(classes.addButton, !matches ? "fixed" : "")}
                                onClick={openNewDialGroupDialog}
                            >
                                <Icon>add</Icon>
                            </Fab>
                        </FuseAnimate>
                        <DialGroupDialog />
                        {/* <DialGroupKeysDialog /> */}
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDialGroups: Actions.getDialGroups,
        getUserData: Actions.getUserData,
        openNewDialGroupDialog: Actions.openNewDialGroupDialog
    }, dispatch);
}

function mapStateToProps({ dialGroupsApp }) {
    return {
        dialGroups: dialGroupsApp.dialGroups.entities,
        selectedDialGroupIds: dialGroupsApp.dialGroups.selectedDialGroupIds,
        searchText: dialGroupsApp.dialGroups.searchText,
        user: dialGroupsApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DialGroupsApp)));
