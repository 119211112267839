import {combineReducers} from 'redux';
import TextGatherPhrases from './text-gather-phrases.reducer';
import user from './user.reducer';

const textGatherPhrasesAppReducers = combineReducers({
    TextGatherPhrases,
    user
});

export default textGatherPhrasesAppReducers;
