import { FuseAnimate, FuseUtils } from '@fuse'
import { Icon, Button, Fab, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import { bindActionCreators } from 'redux'
import _ from '@lodash'
import moment from 'moment'
import PaymentMethod from './PaymentMethod'
import TokenizePaymentMethod from './TokenizePaymentMethod'
import * as Actions from './store/actions'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const styles = theme => ({
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
  },
  labels: {},
})

const initialState = {
  viewPaymentMethod: null,
  data: [],
  customer: null,
}

class PaymentMethodList extends Component {
  state = {
    ..._.cloneDeepWith(initialState),
  }

  componentDidMount() {
    const { data, customer } = this.props
    this.setState({ ..._.cloneDeepWith(initialState), data, customer })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { data, customer } = this.props
    if (
      !_.isEqual(data, prevProps.data) ||
      !_.isEqual(customer, prevProps.customer)
    ) {
      this.setState({ ..._.cloneDeepWith(initialState), data, customer })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  handleVoidPayment = payment => {
    const { User } = this.props
    const { payments } = this.state
    if (payment) {
      payment.VoidDate = payment.VoidDate || new Date()
      payment.VoidedBy = payment.VoidedBy || User.UserName
      if (payments) {
        const index = _.findIndex(payments, { PaymentID: payment.PaymentID })
        window['warn']('Voided Payment: ', payment, index, payments)
        if (index > -1) {
          payments.splice(index, 1, payment)
        }
      }
      this.setState({ ...this.state, payments }, () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(payments)
        }
      })
    }
  }

  handlePaymentMethod = paymentMethod => {
    const { customer } = this.state
    // data.push(paymentMethod);
    customer.Data.PaymentMethods.push(paymentMethod)
    this.setState({ customer, addPaymentMethod: false })
  }

  render() {
    const { classes, searchText, openEditPaymentDialog, payments } = this.props
    const { viewPayment, data, customer, viewPaymentMethod } = this.state
    const paymentTypes = {
      visa: 'Visa',
      mastercard: 'MasterCard',
      master: 'MasterCard',
      discover: 'Discover',
      american_express: 'American Express',
      amex: 'American Express',
      am_ex: 'American Express',
    }

    return (
      <div>
        <Fab
          variant='fab'
          color='secondary'
          aria-label='add'
          className={classes.addButton}
          onClick={() => this.setState({ addPaymentMethod: true })}
        >
          <Icon>add</Icon>
        </Fab>
        <Typography
          variant='h6'
          style={{ alignItems: 'center' }}
          className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
        >
          <Icon color='primary' className='text-32 mr-12'>
            payment
          </Icon>
          Payment Methods
        </Typography>
        {this.state.addPaymentMethod && (
          <TokenizePaymentMethod
            open={this.state.addPaymentMethod}
            data={{ ...customer }}
            paymentMethods={customer.Data.PaymentMethods}
            onAdded={this.handlePaymentMethod}
            onClose={() => {
              this.setState({ ...this.state, addPaymentMethod: false })
            }}
          />
        )}
        {this.state.viewPaymentMethod && (
          <PaymentMethod
            open={Boolean(viewPaymentMethod)}
            data={{ ...viewPaymentMethod }}
            customer={{ ...customer }}
            payments={_.filter(customer.Data.Payments, {
              PaymentMethodID: viewPaymentMethod.PaymentMethodID,
            })}
            onClose={() => {
              this.setState({ ...this.state, viewPaymentMethod: null })
            }}
          />
        )}
        <ReactTableFixedColumns
          className={classNames(classes.root, '-striped -highlight border-0')}
          getTrProps={(state, rowInfo) => {
            return {
              className: classNames(
                'cursor-pointer',
                rowInfo && rowInfo.original.VoidDate
                  ? 'line-through text-grey'
                  : '',
              ),
              onClick: () => {
                if (rowInfo) {
                  this.setState({ viewPaymentMethod: rowInfo.original })
                }
              },
            }
          }}
          data={data}
          columns={[
            {
              Header: '',
              accessor: 'ReusableYN',
              className: 'justify-center',
              width: 48,
              Cell: row => (
                <Icon
                  color={row.value === 'N' ? 'error' : 'secondary'}
                  className='text-20'
                >
                  {row.value === 'N' ? 'cancel' : 'check_circle_outline'}
                </Icon>
              ),
            },
            {
              Header: 'Type',
              accessor: 'PaymentMethodType',
              className: 'justify-center',
              width: 64,
              Cell: row => (
                <img
                  className='w-32'
                  src={`assets/images/cards/${row.value}.png`}
                />
              ),
            },
            {
              Header: 'Description',
              id: 'description',
              accessor: row =>
                `${paymentTypes[row.PaymentMethodType]} ending in ${
                  row.CardLastFour
                }`,
              width: 256,
            },
            {
              Header: 'Name',
              id: 'card-holder-name',
              accessor: row => {
                const cardHolderName = _.get(row, 'CardholderName')
                return (
                  <span>{cardHolderName ? cardHolderName : customer.Name}</span>
                )
              },
              className: 'justify-center',
              width: 224,
            },
            {
              Header: 'Expires',
              accessor: 'CardExpirationDate',
              className: 'justify-center',
              width: 96,
              Cell: row =>
                row.value ? moment(row.value).format('M/YYYY') : '',
            },
            {
              Header: 'Paid Total',
              accessor: row => {
                const paid = _.sumBy(
                  _.filter(
                    payments,
                    o =>
                      o.PaymentMethodID === row.PaymentMethodID && !o.VoidDate,
                  ),
                  'PaymentTotal',
                )
                return paid
              },
              id: 'paid-total',
              className: 'justify-center',
              width: 128,
              Cell: row => this.formatDollars(row.value),
            },
            {
              Header: 'Last Used',
              accessor: row => {
                const last = _.maxBy(
                  _.filter(payments, { PaymentMethodID: row.PaymentMethodID }),
                  'PaymentDateTime',
                )
                return last && last.PaymentDateTime
                  ? moment(last.PaymentDateTime).toDate()
                  : null
              },
              id: 'last-used',
              className: 'justify-center',
              width: 96,
              Cell: row =>
                row.value ? moment(row.value).format('M/D/YYYY') : '',
            },
            {
              Header: 'ID',
              accessor: 'PaymentMethodID',
              className: 'justify-center',
              minWidth: 320,
            },
          ]}
          defaultPageSize={10}
          noDataText='No Payment Methods found'
        />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // openEditPaymentDialog: Actions.openEditPaymentDialog,
      // openNewPaymentDialog: Actions.openNewPaymentDialog
    },
    dispatch,
  )
}

function mapStateToProps({ paymentsApp, spReducers }) {
  return {
    User: spReducers.userProfiles.User,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList)),
)
