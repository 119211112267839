import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedAgreementTypeIds: [],
    routeParams       : {},
    agreementTypeDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const agreementTypesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_AGREEMENT_TYPES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.OPEN_NEW_AGREEMENT_TYPE_DIALOG:
        {
            return {
                ...state,
                agreementTypeDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_AGREEMENT_TYPE_DIALOG:
        {
            return {
                ...state,
                agreementTypeDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_AGREEMENT_TYPE_DIALOG:
        {
            return {
                ...state,
                agreementTypeDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.UPDATE_EDIT_AGREEMENT_TYPE_DIALOG:
        {
            return {
                ...state,
                agreementTypeDialog: {
                    type : 'update',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_AGREEMENT_TYPE_DIALOG:
        {
            return {
                ...state,
                agreementTypeDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default agreementTypesReducer;
