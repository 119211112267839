import axios from 'axios/index';

export const GET_USER_DATA = '[CHAT PANEL] GET USER DATA';
export const GET_CONVERSATIONS = '[CHAT PANEL] GET CONVERSATIONS';
export const SET_CONVERSATION = '[CHAT PANEL] SET CONVERSATION';



export function getUserChats(user) {
    return (dispatch, getState) => {
        const request = axios.get(`${window["apiLocation"]}/api/Chat`);

        return request.then((response) => {

            return dispatch({
                type: GET_CONVERSATIONS,
                payload: response.data,
            });
        });
    }
}

export function setConversation(convo){
    return {
        type: SET_CONVERSATION,
        payload: convo,
    }
}

export function getUserData()
{
    const request = axios.get('/api/chat/user');

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_USER_DATA,
                payload: []
            })
        );
}
