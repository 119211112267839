import PaymentsApp from 'main/content/apps/payments/PaymentsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const PaymentsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/payments/:id',
            component: PaymentsApp
        },
        {
            path     : '/apps/payments',
            component: () => <Redirect to="/apps/payments/all"/>
        }
    ]
};
