import axios from 'axios/index';
import * as DispatchActions from 'main/content/apps/dispatch-board/store/actions/dispatch-board.actions';
import { SUBSCRIBE_LIST, UNSUBSCRIBE_LIST } from 'store/actions';

export const SET_WORK_ORDER_DATA = '[SPCONNECTION] SET WORK ORDER DATA';
export const GET_WORK_ORDER_DATA = '[SPCONNECTION] GET WORK ORDER DATA';
export const UPDATE_WORK_ORDER_DATA = '[SPCONNECTION] UPDATE WORK ORDER DATA';

export function getInitialWorkOrderData(Co, DueBy) {
    const request = axios.get(`${window["apiLocation"]}/api/WorkOrder`, {
        params: {
            Co,
            DueBy
        }
    });
    return (dispatch, getState) => {
        request.then((response) => {
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDER_DATA,
                    payload: response.data
                }),
                dispatch({
                    type: DispatchActions.SET_WORK_ORDERS,
                    WorkOrders: response.data
                }),
            ]).then(() => {
                const state = getState();
                const { trips } = state.dispatchBoardApp.dispatchBoard;
                dispatch(DispatchActions.setBoardTrips(trips));
            });
        });
    }
}

export function getWorkOrderData(Co, Date, oldDate) {
    if (Date) {
        const request = axios.get(`${window["apiLocation"]}/api/WorkOrder`, {
            params: {
                Co,
                DueBy: Date
            }
        });

        return (dispatch, getState) => {
            request.then((response) => {
                Promise.all([
                    dispatch({
                        type: SET_WORK_ORDER_DATA,
                        payload: response.data
                    }),
                    dispatch({
                        type: DispatchActions.SET_WORK_ORDERS,
                        WorkOrders: response.data
                    }),
                ]).then(() => {
                    const state = getState();
                    const { trips } = state.dispatchBoardApp.dispatchBoard;
                    dispatch(DispatchActions.setBoardTrips(trips));
                });
            });
        }
    }
}

export function setWorkOrderData(data) {
    return {
        type: SET_WORK_ORDER_DATA,
        payload: data
    };
}