import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardContent, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Stepper, Step, StepButton, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { NavLink, withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import classNames from 'classnames';
import _ from '@lodash';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    card: {
        marginTop: 12,
        cursor: 'pointer',
    },
    stepper: {
        padding: 0 + '!important',
    },
    workflow: {
        padding: 12,
        paddingTop: 0,
    },
    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    content: {
        overflow: 'hidden',
    }
});

function getSteps() {
    return [{ Name: 'Customer', Icon: 'account_circle' }, { Name: 'Service Site', Icon: 'location_on' }, { Name: 'Work Order', Icon: 'assignment' }];
}

class CallHandlerDraftCard extends Component {
    state = {
        draft: {
            Customer: {},
            Site: {},
            WorkOrder: {
                EnteredDate: new Date(),
            },
        },
    };

    componentDidMount() {
        window["warn"](this.props);
        const { draft } = this.props;
        this.setState({ ...this.state, draft });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { draft } = this.props;
        window["warn"](draft);
        if (!_.isEqual(this.props.draft, this.state.draft)) {
            this.setState({ ...this.state, draft });
        }
    };

    render() {
        const { classes, user, openCallDraft, callID, id } = this.props;
        const { draft } = this.state;
        const steps = getSteps();
        return (
            <Card className={classNames(classes.card, "highlight")} onClick={() => { openCallDraft(id, draft); }}>
                <CardHeader className="p-12" classes={{ title: classes.title, content: classes.content, }}
                    avatar={
                        <Avatar className={callID == id ? classes.selectedCallAvatar : classes.avatar}>
                            <Icon>assignment_ind</Icon>
                        </Avatar>
                    }
                    title={draft.Site && draft.Site.Description && draft.Site.Description.length > 0 ? draft.Site.Description : draft.Customer.Name && draft.Customer.Name.length > 0 ? draft.Customer.Name : `${draft.Customer.FirstName} ${draft.Customer.LastName}`}
                    subheader={`${new Date(draft.WorkOrder.EnteredDate).toLocaleDateString('en-US')} at ${new Date(draft.WorkOrder.EnteredDate).toLocaleTimeString('en-US')}`}
                />
                <CardContent className={classes.stepper}>
                    <Stepper fullWidth linear className={classes.workflow} activeStep={draft.activeStep}>
                        {steps.map((label, index) => (
                            <Step id={label.Name}>
                                <StepButton icon={<Icon color={(draft.activeStep > index ? 'secondary' : (draft.activeStep == index ? 'primary' : 'disabled'))}>{label.Icon}</Icon>} completed={false}>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </CardContent>
            </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCallDraft: Actions.openCallDraft,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, contactsApp }) {
    return {
        user: contactsApp.user,
        callHandler: callHandlerApp.callHandler,
        callID: callHandlerApp.callHandler.id,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallHandlerDraftCard)));
