import _ from '@lodash'
import axios from 'axios/index'
import { getDashboards } from 'main/content/apps/dashboard-builder/store/actions'
import { getTemplates } from 'main/content/apps/email-templates/store/actions'
import {
  getBoards,
  getBoardTags,
} from 'main/content/apps/scrumboard/store/actions'
import { showMessage } from 'store/actions'
import { updateInList } from 'main/functions'
import moment from 'moment'
import {
  getAudioClips,
  getBoardData,
  getCallGatherData,
  getCallQueueData,
  getCatalogData,
  getClassCategoryData,
  getClassData,
  getClassProgress,
  getCustomerData,
  getCustomerSiteData,
  getFolderData,
  getGPSData,
  getHoldMusic,
  getHuntGroupData,
  getInitialCallHandlerData,
  getInitialTripData,
  getInitialWorkOrderData,
  getPhoneNumberData,
  getPurchaseOrderData,
  getReportCategoryData,
  getSupervisorData,
  getTeamData,
  getTechnicianUATimeData,
  getTextGatherData,
  getTimecardData,
  getUserRoleListData,
  getVoiceMailboxData,
  getVoicemailData,
  initGPS,
  startSignalR,
  SUBSCRIBE_LIST,
} from 'store/actions'
import * as Login from 'auth/store/actions'

export const SET_USER_PROFILE_DATA = '[SPCONNECTION] SET USER PROFILE DATA'
export const GET_USER_PROFILE_DATA = '[SPCONNECTION] GET USER PROFILE DATA'
export const SET_USER_PROFILE_LIST_DATA =
  '[SPCONNECTION] SET USER PROFILE LIST DATA'
export const GET_USER_PROFILE_LIST_DATA =
  '[SPCONNECTION] GET USER PROFILE LIST DATA'
export const SUBSCRIBE_USER_PROFILE_LIST =
  '[SPCONNECTION] SUBSCRIBE USER PROFILE LIST'
export const SET_USER_PRIVILEGES = '[SP CONNECTION] SET USER PRIVILEGES'
export const SET_GLOBAL_SECURABLES = '[SP CONNECTION] SET GLOBAL SECURABLES'

export function getUserProfileListData() {
  return (dispatch, getState) => {
    const state = getState()
    const request = axios.get(`${window['apiLocation']}/api/UserProfile`)
    request.then(response => {
      dispatch({
        type: GET_USER_PROFILE_LIST_DATA,
        payload: response.data,
        timestamp: new Date(),
      })
      // if (!interval) {
      //     interval = setInterval(() => {
      //         dispatch(getUserProfileListChanges());
      //     }, 30000);
      // }
    })
  }
}

export function getUserProfileListChanges() {
  return (dispatch, getState) => {
    const state = getState()
    const { timestamp, Users } = _.cloneDeepWith(state.spReducers.userProfiles)
    const request = axios.get(
      `${window['apiLocation']}/api/UserStatus?Since=${moment
        .utc(timestamp || '1/1/1900')
        .format('M-D-YYYY HH:mm:ss')}`,
    )
    request.then(response => {
      response.data.map(user => {
        const index = _.findIndex(Users, { UserName: user.UserName })
        if (index > -1) {
          Users.splice(index, 1, user)
        }
      })
      dispatch({
        type: GET_USER_PROFILE_LIST_DATA,
        payload: Users,
        timestamp: new Date(),
      })
      // if (!interval) {
      //     interval = setInterval(() => {
      //         dispatch(getUserProfileListChanges());
      //     }, 30000);
      // }
    })
  }
}

export function updateUserPresence(user) {
  return (dispatch, getState) => {
    const { Users } = getState().spReducers.userProfiles
    const { UserName } = user
    const obj = {
      UserName,
      Data: {
        ...user,
      },
    }
    let payload = updateInList(Users, obj, 'MERGE', { UserName })
    dispatch({
      type: GET_USER_PROFILE_LIST_DATA,
      payload,
      timestamp: new Date(),
    })
    dispatch(
      showMessage({
        message: `Session resumed`,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        variant: 'success',
      }),
    )
  }
}

export function updateUserLocation(loc) {
  return (dispatch, getState) => {
    const { Users } = getState().spReducers.userProfiles
    const { UserName } = loc
    const obj = {
      UserName,
      Data: {
        Location: loc,
      },
    }
    let payload = updateInList(Users, obj, 'MERGE', { UserName })
    dispatch({
      type: GET_USER_PROFILE_LIST_DATA,
      payload,
      timestamp: new Date(),
    })
  }
}

export function setGlobalSecurables() {
  return {
    type: SET_GLOBAL_SECURABLES,
  }
}

// function getSecurable(url) {
//     const state = store.getState();
//     const { userRoles, userProfiles, companies } = state.spReducers;
//     const { User, Pages } = userProfiles;
//     const { Co } = companies;
//     let id = null;
//     let isSecurable = false;
//     Pages.map((page) => {
//         if (url.indexOf(page.url) > -1) {
//             id = page.id;
//             isSecurable = page.securables;
//         }
//     });
//     const roles = _.filter(userRoles, (o) => { return _.findIndex(User.Data.Roles, { Co, Role: o.Role }) > -1 });
//     const securables = [];
//     roles.map((value) => {
//         value.Data.Securables.map((sec) => {
//             securables.push(sec.Securable);
//         });
//     });
//     return !isSecurable ? true : (securables.indexOf(id) > -1);
// }

export function getUserProfileData(unm, bool) {
  const request = axios.get(`${window['apiLocation']}/api/UserProfile`, {
    params: {
      UserName: unm,
    },
  })

  return (dispatch, getState) => {
    request.then(response => {
      window['warn'](response)
      if (!response.data || response.data === null) {
        // if we can't find the user profile
        // we log the user out
        dispatch({
          type: Login.LOGOUT,
        })
        return
      }

      if (
        response.data &&
        response.data.Data &&
        response.data.Data.TableSettings
      ) {
        response.data.Data.TableSettings.map(settings => {
          try {
            settings.Preferences = JSON.parse(settings.Preferences)
          } catch (e) {}
        })
      }
      if (
        response.data.Data &&
        response.data.Data.Companies &&
        response.data.Data.Companies.length > 0
      ) {
        response.data.Data.DefaultHomepage =
          response.data.Data.Companies[0].Data.Role.DefaultHomepage
      }
      Promise.all([
        dispatch(setGlobalSecurables()),
        dispatch({
          type: GET_USER_PROFILE_DATA,
          payload: response.data,
        }),
        dispatch(initGPS(response.data)),
      ]).then(() => {
        if (bool) {
          Promise.all([
            dispatch(getUserRoleListData(unm)),
            dispatch(getUserProfileListData()),
          ]).then(() => {
            dispatch(startSignalR(unm))
          })
        }
      })
    })
  }
}

export function setUserPrivileges(Co, userData, roleData, load) {
  // window["warn"](Co, userData, roleData);
  return (dispatch, getState) => {
    const state = getState()
    const { spReducers } = state
    let { companies, userRoles, userProfiles } = spReducers
    if (roleData) {
      userRoles = roleData
    }
    let { User, Pages } = userProfiles
    if (userData) {
      User = userData
    }
    const { Roles } = User.Data
    const roles = _.filter(Roles, { Co })
    const co = _.find(companies, { Co })
    const primaryRole = co ? co.Data.Role : null
    User.Data.Securables = []
    User.Data.Reports = []
    User.Data.Dashboards = []
    User.Data.Pages = []
    User.Data.DefaultHomepage = primaryRole
      ? primaryRole.Data.Role.DefaultHomepage
      : null
    if (Co && roles.length > 0 && userRoles.length > 0) {
      roles.map((role, index) => {
        const Role = _.find(userRoles, { Role: role.Role })
        if (Role) {
          const accessLevels = ['R', 'E', 'F']
          if (
            Role.Data &&
            Role.Data.Role &&
            Role.Data.Role.Data &&
            Role.Data.Role.Data.Securables
          ) {
            Role.Data.Role.Data.Securables.map(sec => {
              const accessLevel = accessLevels.indexOf(sec.AccessLevel)
              const index = _.findIndex(
                User.Data.Securables,
                o =>
                  o.Securable === sec.Securable &&
                  accessLevels.indexOf(o.AccessLevel) <= accessLevel,
              )
              if (index > -1) {
                User.Data.Securables.splice(index, 1, sec)
              } else {
                User.Data.Securables.push(sec)
              }
            })
            Role.Data.Role.Data.Reports.map(sec => {
              User.Data.Reports.push(sec)
            })
            Role.Data.Role.Data.Dashboards.map(sec => {
              User.Data.Dashboards.push(sec)
            })
          } else {
            Role.Data.Securables.map(sec => {
              const accessLevel = accessLevels.indexOf(sec.AccessLevel)
              const index = _.findIndex(
                User.Data.Securables,
                o =>
                  o.Securable === sec.Securable &&
                  accessLevels.indexOf(o.AccessLevel) <= accessLevel,
              )
              if (index > -1) {
                User.Data.Securables.splice(index, 1, sec)
              } else {
                User.Data.Securables.push(sec)
              }
            })
            Role.Data.Reports.map(sec => {
              User.Data.Reports.push(sec)
            })
            Role.Data.Dashboards.map(sec => {
              User.Data.Dashboards.push(sec)
            })
          }
        }
      })
      Pages.map(page => {
        const sec = _.find(User.Data.Securables, o => {
          return o.Securable === page.id
        })
        if (sec || !page.securables) {
          User.Data.Pages.push(page)
          // if (page.url === homepage) {
          //     User.Data.DefaultHomepage = page.url;
          // }
        }
      })
      dispatch({
        type: SET_USER_PRIVILEGES,
        payload: User,
      })
      if (load) {
        const date = new Date(state.dispatchBoardApp.dispatchBoard.date)
          .toLocaleDateString('en-US')
          .replace(/\//g, '-')
        const { Pages } = User.Data
        // window["warn"]('Allowed Pages: ', Pages);
        //Fetch secured

        //Call Handler
        if (_.find(Pages, { id: 'call-handler' })) {
          Promise.all([dispatch(getInitialCallHandlerData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Calls` })
            // dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_${callDate}_Call_Logs`, });
          })
          // dispatch(getCallLog(Co, startDate, endDate));
        }

        //Trips
        if (_.find(Pages, { id: 'dispatch-board' })) {
          Promise.all([
            dispatch(getInitialTripData(Co, date)),
            dispatch(getGPSData()),
            dispatch(getTechnicianUATimeData(Co)),
            dispatch(getBoardData(Co, date)),
            dispatch(getFolderData(Co, date)),
          ]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_${date}_Trips` })
            dispatch({ type: SUBSCRIBE_LIST, List: `UserGPS` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_TechnicianUATimes` })
            dispatch({
              type: SUBSCRIBE_LIST,
              List: `${Co}_CustomDispatchBoards`,
            })
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_CustomFolders` })
          })
        }

        //Work Orders
        if (
          _.find(Pages, { id: 'dispatch-board' }) ||
          _.find(Pages, { id: 'work-orders' })
        ) {
          Promise.all([dispatch(getInitialWorkOrderData(Co, date))]).then(
            () => {
              dispatch({
                type: SUBSCRIBE_LIST,
                List: `${Co}_${date}_WorkOrders`,
              })
              dispatch({
                type: SUBSCRIBE_LIST,
                List: `${Co}_AgreementWorkOrders`,
              })
            },
          )
        }

        if (_.find(Pages, { id: 'reporting' })) {
          Promise.all([dispatch(getReportCategoryData(Co, date))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ReportCategories` })
          })
        }
        //Customers
        if (_.find(Pages, { id: 'customers' })) {
          Promise.all([dispatch(getCustomerData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Customers` })
          })
        }
        //Supervisor
        if (_.find(Pages, { id: 'supervisor' })) {
          const superDate = state.spReducers.supervisor.date
          Promise.all([dispatch(getSupervisorData(Co, superDate, true))]).then(
            () => {
              // dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Customers`, });
            },
          )
        }

        //Customer Sites
        if (_.find(Pages, { id: 'sites' })) {
          Promise.all([dispatch(getCustomerSiteData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_CustomerSites` })
          })
        }

        //Agreements
        // if (_.find(Pages, { id: 'agreements' })) {
        //     Promise.all([
        //         dispatch(getAgreementData(Co)),
        //     ]).then(() => {
        //         dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Agreements`, });
        //     })
        // }

        //Timecards
        if (_.find(Pages, { id: 'timecards' })) {
          Promise.all([dispatch(getTimecardData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Timecards` })
          })
        }

        //Voicemail
        if (_.find(Pages, { id: 'voicemail' })) {
          Promise.all([
            dispatch(getVoiceMailboxData(Co)),
            dispatch(getVoicemailData(Co)),
          ]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_VoiceMailboxes` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Voicemail` })
          })
        }

        //Classes
        if (_.find(Pages, { id: 'learning' })) {
          Promise.all([
            dispatch(getClassData(Co)),
            dispatch(getClassProgress(Co)),
            dispatch(getClassCategoryData(Co)),
          ]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Classes` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ClassCategories` })
          })
        }

        //Teams
        if (_.find(Pages, { id: 'teams' })) {
          Promise.all([dispatch(getTeamData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Teams` })
          })
        }

        //POs
        if (_.find(Pages, { id: 'purchase-orders' })) {
          Promise.all([dispatch(getPurchaseOrderData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_PurchaseOrders` })
          })
        }

        //POs
        // if (_.find(Pages,{id: 'purchase-orders'})) {
        //     Promise.all([
        //         dispatch(getPurchaseOrderData(Co)),
        //     ]).then(() => {
        //         dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_PurchaseOrders`, });
        //     })
        // }

        //Scrumboard
        if (_.find(Pages, { id: 'project-tracking' })) {
          Promise.all([
            dispatch(getBoardTags(Co)),
            dispatch(getBoards(Co)),
          ]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ScrumBoardTags` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ScrumBoards` })
          })
        }

        //Dashboard Studio
        if (_.find(Pages, { id: 'dashboard-builder' })) {
          Promise.all([dispatch(getDashboards(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Dashboards` })
          })
        }
        //Price Book
        if (_.find(Pages, { id: 'catalog' })) {
          Promise.all([
            dispatch(getCatalogData(Co)),
            // dispatch(getAssemblyData(Co)),
            // dispatch(getAssemblyCategoryData(Co)),
            // getAssemblyCategoryAssignmentData(Co),
          ]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Catalogs` })
            // dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Assemblies`, });
            // dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_AssemblyCategories`, });
            // dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_AssemblyCategoryAssignments`, });
          })
        }
        //Template Studio
        if (_.find(Pages, { id: 'email-templates' })) {
          Promise.all([dispatch(getTemplates(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_HTMLTemplates` })
          })
        }
        //Call Gathers
        if (_.find(Pages, { id: 'call-gathers' })) {
          Promise.all([dispatch(getCallGatherData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Gathers` })
          })
        }

        //Text Gathers
        if (_.find(Pages, { id: 'text-gathers' })) {
          Promise.all([dispatch(getTextGatherData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_TextGathers` })
          })
        }

        //Hunt Groups
        if (_.find(Pages, { id: 'hunt-groups' })) {
          Promise.all([dispatch(getHuntGroupData(Co))]).then(() => {
            dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_HuntGroups` })
          })
        }

        dispatch(getAudioClips(Co))
        dispatch(getHoldMusic(Co))
        dispatch(getPhoneNumberData(Co))
        dispatch(getCallQueueData(Co))

        //Subscribe secured
        dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_AudioClips` })
        dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_HoldMusic` })
        dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_PhoneNumbers` })
        dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_CallQueues` })
      }
    }
  }
}

export function setUserProfileData(data) {
  return {
    type: SET_USER_PROFILE_DATA,
    payload: data,
  }
}
