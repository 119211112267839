import axios from 'axios/index';
import { getUserData } from 'main/content/apps/customer-sites/store/actions/user.actions';
import { setSiteData, setCustomerData } from 'main/content/apps/call-handler/store/actions';
import { showMessage, addDialogHistory } from 'store/actions';
import _ from '@lodash';
import { DATA_EDIT_CUSTOMER_DIALOG } from '../../../customers/store/actions';
import { responsiveFontSizes } from '@material-ui/core';

export const GET_CUSTOMER_SITES = '[CUSTOMER SITES APP] GET CUSTOMER SITES';
export const SET_SEARCH_TEXT = '[CUSTOMER SITES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CUSTOMER_SITES = '[CUSTOMER SITES APP] TOGGLE IN SELECTED CUSTOMER SITES';
export const SELECT_ALL_CUSTOMER_SITES = '[CUSTOMER SITES APP] SELECT ALL CUSTOMER SITES';
export const DESELECT_ALL_CUSTOMER_SITES = '[CUSTOMER SITES APP] DESELECT ALL CUSTOMER SITES';
export const OPEN_NEW_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] OPEN NEW CUSTOMER SITE DIALOG';
export const CLOSE_NEW_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] CLOSE NEW CUSTOMER SITE DIALOG';
export const OPEN_EDIT_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] OPEN EDIT CUSTOMER SITE DIALOG';
export const ADD_EDIT_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] ADD EDIT CUSTOMER SITE DIALOG';
export const UPDATE_EDIT_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] UPDATE EDIT CUSTOMER SITE DIALOG';
export const DATA_EDIT_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] DATA EDIT CUSTOMER SITE DIALOG';
export const CLOSE_EDIT_CUSTOMER_SITE_DIALOG = '[CUSTOMER SITES APP] CLOSE EDIT CUSTOMER SITE DIALOG';
export const ADD_CUSTOMER_SITE = '[CUSTOMER SITES APP] ADD CUSTOMER SITE';
export const UPDATE_CUSTOMER_SITE = '[CUSTOMER SITES APP] UPDATE CUSTOMER SITE';
export const REMOVE_CUSTOMER_SITE = '[CUSTOMER SITES APP] REMOVE CUSTOMER SITE';
export const REMOVE_CUSTOMER_SITES = '[CUSTOMER SITES APP] REMOVE CUSTOMER SITES';
export const TOGGLE_STARRED_CUSTOMER_SITE = '[CUSTOMER SITES APP] TOGGLE STARRED CUSTOMER SITE';
export const TOGGLE_STARRED_CUSTOMER_SITES = '[CUSTOMER SITES APP] TOGGLE STARRED CUSTOMER SITES';
export const SET_CUSTOMER_SITES_STARRED = '[CUSTOMER SITES APP] SET CUSTOMER SITES STARRED ';

export function getCustomerSites(routeParams) {
    const request = axios.get('/api/customerSites-app/customerSites', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_CUSTOMER_SITES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedCustomerSites(customerSiteId) {
    return {
        type: TOGGLE_IN_SELECTED_CUSTOMER_SITES,
        customerSiteId
    }
}


export function selectAllCustomerSites() {
    return {
        type: SELECT_ALL_CUSTOMER_SITES
    }
}

export function deSelectAllCustomerSites() {
    return {
        type: DESELECT_ALL_CUSTOMER_SITES
    }
}


export function openNewCustomerSiteDialog(data) {
    return {
        type: OPEN_NEW_CUSTOMER_SITE_DIALOG,
        data
    }
}

export function closeNewCustomerSiteDialog() {
    return {
        type: CLOSE_NEW_CUSTOMER_SITE_DIALOG
    }
}

export function openPreloadCustomerSiteDialog(data){
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_CUSTOMER_SITE_DIALOG,
            data: data
        })
    }
}

export function openEditCustomerSiteDialog(data) {
    return (dispatch, getState) => {

        const { Co, Customer, Site, Description } = data;
        dispatch(
            addDialogHistory({
                timestamp: new Date(),
                dialog: 'Site',
                params: {
                    Co,
                    Customer,
                    Site,
                    Description
                }
            })
        );

        const request = axios.get(`${window["apiLocation"]}/api/CustomerSite?Co=${encodeURIComponent(Co)}&Customer=${encodeURIComponent(Customer)}&Site=${encodeURIComponent(Site)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_CUSTOMER_SITE_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function updateEditCustomerSiteDialog(data) {
    return (dispatch, getState) => {
        dispatch(showMessage({
            message: `Refreshing Site Data`,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'refresh'
        }))

        const { Co, Customer, Site } = data;

        const request = axios.get(`${window["apiLocation"]}/api/CustomerSite?Co=${encodeURIComponent(Co)}&Customer=${encodeURIComponent(Customer)}&Site=${encodeURIComponent(Site)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DATA_EDIT_CUSTOMER_SITE_DIALOG,
                    data: response.data
                }),
                dispatch(showMessage({
                    message: `Site Data Refreshed`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }))
            ])
        );
    };
}

export function closeEditCustomerSiteDialog() {
    return {
        type: CLOSE_EDIT_CUSTOMER_SITE_DIALOG
    }
}

export function addCustomerSite(newCustomerSite) {
    return (dispatch, getState) => {

        const state = getState();
        const { data, props } = state.customersApp.customers.customerDialog;
        const { open } = props;


        const request = axios.post(`${window["apiLocation"]}/api/CustomerSite`,
            newCustomerSite
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Service Site #${response.data.Site} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                dispatch({
                    type: ADD_EDIT_CUSTOMER_SITE_DIALOG,
                    data: response.data
                });
                if (open && data && data.Customer === response.data.Customer) {
                    const { Sites } = data ? _.cloneDeepWith(data.Data) : {};
                    if (Sites) {
                        Sites.push(response.data);
                        dispatch({
                            type: DATA_EDIT_CUSTOMER_DIALOG,
                            data: {
                                ...data,
                                Data: {
                                    ...data.Data,
                                    Sites
                                }
                            }
                        })
                    }
                }
                const { Co, Customer, Site, Description } = response.data;
                dispatch(
                    addDialogHistory({
                        timestamp: new Date(),
                        dialog: 'Site',
                        params: {
                            Co,
                            Customer,
                            Site,
                            Description
                        }
                    })
                );
            }
        });
    };
}

export function updateCustomerSite(customerSite) {
    return (dispatch, getState) => {

        const state = getState();
        const request = axios.put(`${window["apiLocation"]}/api/CustomerSite?Co=${encodeURIComponent(customerSite.Co)}&Site=${encodeURIComponent(customerSite.Site)}`,
            customerSite
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Service Site #${response.data.Site} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const { callHandler } = state.callHandlerApp;
                if (response.data.Customer === callHandler.data.Site.Customer && response.data.Site === callHandler.data.Site.Site) {
                    dispatch(setSiteData({ ...callHandler.data.Site, ...response.data }));
                }
                dispatch({
                    type: UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
                    data: response.data
                });
            }
        });
    };
}

export function toggleStarredCustomerSite(customerSiteId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().customerSitesApp.customerSites;

        const request = axios.post('/api/customerSites-app/toggle-starred-customerSite', {
            customerSiteId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CUSTOMER_SITE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCustomerSites(routeParams)))
        );
    };
}

export function toggleStarredCustomerSites(customerSiteIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().customerSitesApp.customerSites;

        const request = axios.post('/api/customerSites-app/toggle-starred-customerSites', {
            customerSiteIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CUSTOMER_SITES
                }),
                dispatch({
                    type: DESELECT_ALL_CUSTOMER_SITES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCustomerSites(routeParams)))
        );
    };
}

export function setCustomerSitesStarred(customerSiteIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().customerSitesApp.customerSites;

        const request = axios.post('/api/customerSites-app/set-customerSites-starred', {
            customerSiteIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CUSTOMER_SITES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CUSTOMER_SITES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCustomerSites(routeParams)))
        );
    };
}

export function setCustomerSitesUnstarred(customerSiteIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().customerSitesApp.customerSites;

        const request = axios.post('/api/customerSites-app/set-customerSites-unstarred', {
            customerSiteIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CUSTOMER_SITES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CUSTOMER_SITES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCustomerSites(routeParams)))
        );
    };
}


export function addRateTemplate(rateTemplate) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.customerSitesApp.customerSites.customerSiteDialog;

        const request = axios.post(`${window["apiLocation"]}/api/CustomerSiteRateTemplate`,
            rateTemplate
        );

        return request.then((response) => {
            data.Data.RateTemplates.push(response.data);
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
                    data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Rate Template has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function updateRateTemplate(rateTemplate) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.customerSitesApp.customerSites.customerSiteDialog;

        const request = axios.put(`${window["apiLocation"]}/api/CustomerSiteRateTemplate?Co=${encodeURIComponent(rateTemplate.Co)}&Customer=${rateTemplate.Customer}&Site=${rateTemplate.Site}&RateTemplate=${encodeURIComponent(rateTemplate.RateTemplate)}&BusinessUnit=${encodeURIComponent(rateTemplate.BusinessUnit)}&Division=${encodeURIComponent(rateTemplate.Division)}`,
            rateTemplate
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.RateTemplates, { ID: response.data.ID });
            if (index > -1) {
                data.Data.RateTemplates.splice(index, 1, response.data);
            };
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
                    data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Rate Template has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeRateTemplate(rateTemplate) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.customerSitesApp.customerSites.customerSiteDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/CustomerSiteRateTemplate?Co=${encodeURIComponent(rateTemplate.Co)}&Customer=${rateTemplate.Customer}&Site=${rateTemplate.Site}&RateTemplate=${encodeURIComponent(rateTemplate.RateTemplate)}&BusinessUnit=${encodeURIComponent(rateTemplate.BusinessUnit)}&Division=${encodeURIComponent(rateTemplate.Division)}`);

        return request.then((response) => {
            const index = _.findIndex(data.Data.RateTemplates, { ID: rateTemplate.ID });
            if (index > -1) {
                data.Data.RateTemplates.splice(index, 1);
            };
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
                    data
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Rate Template has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function deleteCustomerSite(customerSite) {
    return async (dispatch) => {
        const request = axios.delete(`${window["apiLocation"]}/api/CustomerSite?company=${encodeURIComponent(customerSite.Co)}&customer=${encodeURIComponent(customerSite.Customer)}&site=${encodeURIComponent(customerSite.Site)}`);

        const response = await request;

        if (response.data === "") {
            Promise.all([
                dispatch({
                    type: REMOVE_CUSTOMER_SITE
                })
            ])
                .then(() => {
                    if (response.data === "") {
                        dispatch(showMessage({
                            message: `Site ${customerSite.Site} has been successfully deleted.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                    }
                });
        }
        else {
            dispatch(showMessage({
                message: `Site ${customerSite.Site} could not be deleted. ${response.data}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        }

        return response.data;
    };
}