import { FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Badge, Button, ClickAwayListener, Icon, IconButton, InputAdornment, Menu, MenuItem, Tooltip, Typography, withStyles } from '@material-ui/core';
import axios from 'axios';
import classNames from 'classnames';
import * as Actions from 'main/content/apps/scrumboard/store/actions/index';
import Autocomplete from 'main/content/components/autocomplete/Autocomplete';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import getProfileImage from '../../../../../../../functions/getProfileImageUrl';

const styles = theme => ({
    memberBadge: {
        top: 12,
        right: 16,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 24,
        height: 24,
        cursor: 'pointer',
        borderRadius: '50%'
    },
});
const initialState = {
    addSubtaskActive: false,
    newSubtask: "",
    options: [],
    card: {
        Co: null,
        BoardID: null,
        ListID: null,
        ParentID: null,
        ID: null,
        Name: '',
        Description: '',
        DueBy: null,
        Data: {
            Attachments: [],
            Comments: [],
            Members: [],
            Tags: [],
            Checklists: [],
            Cards: []
        },
        BreadcrumbItems: [],
        Notes: '',
        AddedBy: null,
        AddedDate: null,
        AttachmentID: null,
        CoverImage: null,
        DisplayIndex: null,
        CompletedYN: null,
        StarredYN: null,
        ImportantYN: null,
        BoardName: null,
        ListName: null,
        Priority: 3,
        dueMenu: null,
        confirm: false,
        isDatepickerOpen: false,
        newTag: false,
        editTag: null,
        contextMenuEl: null,
        selectedSubtask: null
    }
};

class Subtasks extends Component {

    state = {
        ...initialState
    };

    searchTasks = (newValue) => {
        const { Co, board } = this.props;
        this.setState({ newSubtask: newValue });

        axios.get(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${Co}&Board=${board.ID}&Term=${newValue && newValue.replace(" ", "|")}`)
            .then(response => {
                this.setState({ options: response.data || [] })
            })
    }

    handleClick = async () => {
        const { newSubtask } = this.state;
        if (newSubtask && newSubtask !== "") {
            if (typeof newSubtask === "string") {
                this.addSubtask({ Name: newSubtask }, false);

            } else {
                const { Cards } = this.props.board.Data;
                const card = _.find(Cards, { ID: newSubtask.ID })
                this.addSubtask(_.cloneDeep(card), true);
            }

            this.setState({ ...initialState })
        }
    }

    addSubtask = (data, existing) => {

        const { Co, board, card, username, subtaskAdd } = this.props;
        const { ID: BoardID } = board;

        const subtask = {
            ...initialState.card,
            AddedBy: username,
            AddedDate: new Date(),
            ...data,
            Co,
            BoardID,
            ParentID: card.ID
        }

        subtaskAdd(subtask, existing);

    }

    disassociateSubtask = (e, subTask) => {
        e.stopPropagation();
        this.props.disassociateSubtask(subTask);
    }

    closeMenu = (e) => {
        e.stopPropagation();
        this.setState({ contextMenuEl: null, selectedSubtask: null });
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (!searchText || searchText.length === 0) {
            return arr;
        }
        var matches = FuseUtils.filterArrayByString(arr, searchText, ['Name', 'Description', 'TaskID']);
        function addParent(ID) {
            var parent = _.find(entities, { ID });
            window["warn"]('Match Parent Object: ', parent);
            if (parent) {
                matches.push(parent);
                if (parent.ParentID) {
                    addParent(parent.ParentID);
                }
            }
        }
        matches.map((match) => {
            if (match.ParentID) {
                window["warn"]('Match Parent: ', match.ParentID);
                addParent(match.ParentID);
            }
        })
        return matches
    };

    findChildren = (tasks, allTasks) => {
        tasks.map((task) => {
            if (task.ID) {
                task.children = this.findChildren(_.filter(allTasks, { ParentID: task.ID }), allTasks);
            }
        })
        return tasks;
    }

    findAssignedChildren = (task, matches) => {
        const { filters } = this.props;
        if (_.filter(task.Data.Members, (m) => filters.assignedTo.indexOf(m.UserName) > -1).length > 0) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findAssignedChildren(t, matches);
            });
        }
        return matches;
    }

    findAddedByChildren = (task, matches) => {
        const { filters } = this.props;
        if (filters.addedBy.indexOf(task.AddedBy) > -1) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findAddedByChildren(t, matches);
            });
        }
        return matches;
    }

    findTaggedChildren = (task, matches) => {
        const { filters } = this.props;
        if (_.filter(task.Data.Tags, (t) => filters.tags.indexOf(t.TagID) > -1).length > 0) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findTaggedChildren(t, matches);
            });
        }
        return matches;
    }

    filterSubtasks = (tasks) => {
        const { filters, board, searchText } = this.props;
        let filtered = this.getFilteredArray(this.findChildren(tasks, board.Data.Cards) || [], searchText);
        let matches = [];
        if (filters.assignedTo && filters.assignedTo.length > 0) {
            matches = _.filter(filtered, (o) => this.findAssignedChildren(o, []).length > 0);
        }
        if (filters.addedBy && filters.addedBy.length > 0) {
            matches = _.filter(matches, (o) => this.findAddedByChildren(o, []).length > 0);
        }
        if (filters.tags && filters.tags.length > 0) {
            matches = _.filter(matches, (o) => this.findTaggedChildren(o, []).length > 0);
        }
        return matches;
    }

    matchTask = (task) => {
        const { filters, searchText } = this.props;
        let isMatch = FuseUtils.filterArrayByString([task], searchText, ['Name', 'Description', 'TaskID']).length > 0;
        if (isMatch && filters.assignedTo && filters.assignedTo.length > 0) {
            isMatch = _.filter(task.Data.Members, (m) => filters.assignedTo.indexOf(m.UserName) > -1).length > 0
        }
        if (isMatch && filters.addedBy && filters.addedBy.length > 0) {
            isMatch = filters.addedBy.indexOf(task.AddedBy) > -1;
        }
        if (isMatch && filters.tags && filters.tags.length > 0) {
            isMatch = _.filter(task.Data.Tags, (t) => filters.tags.indexOf(t.TagID) > -1).length > 0
        }
        return isMatch;
    }

    render() {
        const { classes, subTasks, openSubtask, tags, board, users, parent, filters } = this.props;
        const { addSubtaskActive, newSubtask, contextMenuEl, selectedSubtask } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const matches = (board && board.Data && board.Data.Cards) ? this.filterSubtasks(_.filter(board.Data.Cards, (o) => _.findIndex(subTasks, (t) => t.ID === o.ID) > -1)) : subTasks;
        const matchedTask = this.matchTask(parent);
        return (
            <div className="w-full mb-24">

                {((subTasks && subTasks.length > 0) || addSubtaskActive) &&
                    <div className="flex items-center mt-12 pb-12 border-b-1 border-grey-light">
                        <Icon className="text-20 mr-4" color="inherit">list</Icon>
                        <Typography className="font-600 text-16">Subtasks</Typography>
                    </div>
                }
                <div className='mb-12'>
                    {subTasks && subTasks.map((subTask, index) => {
                        const card = (board && board.Data && board.Data.Cards) ? _.find(board.Data.Cards, { ID: subTask.ID }) : null;
                        return (
                            <div className="w-full py-6 border-b-1 border-grey-light clickable hover:bg-grey-lightest" style={{ opacity: !matchedTask && _.findIndex(matches, (o) => o.ID === subTask.ID) < 0 ? '.25' : undefined }} onClick={() => openSubtask(subTask, true)} key={index}>
                                <div className='w-full flex justify-between items-center'>
                                    {subTask.CompletedYN === "Y" ?
                                        <Icon color="secondary" className="mr-8 align-middle text-28">check_circle_outline</Icon> :
                                        <SPRoundIcon color="action" className="mr-8 align-middle text-28" />
                                    }
                                    <div className="w-full overflow-hidden">
                                        <Typography className={classNames("font-600 mb-4 truncate capitalize", subTask.CompletedYN === "Y" && "line-through text-grey-dark")}>
                                            {subTask.Name}
                                        </Typography>
                                        <Typography style={{ marginTop: -8 }} className={classNames("mb-4 truncate text-grey-darker text-11", subTask.CompletedYN === "Y" && "line-through text-grey-dark")}>
                                            {subTask.Description}
                                        </Typography>
                                    </div>

                                    <div className="flex items-center">
                                        {subTask.AttachmentsCount > 0 && (
                                            <span className="flex items-center">
                                                <Icon className="text-20 mr-4" color="action">attachment</Icon>
                                                <Typography color="textSecondary">{subTask.AttachmentsCount}</Typography>
                                            </span>
                                        )}

                                        {subTask.SubTaskCount > 0 && (
                                            <span className="flex items-center ml-12">
                                                <Icon className="text-20" color="action">call_split</Icon>
                                                <Typography color="textSecondary">{subTask.SubTaskCount}</Typography>
                                            </span>
                                        )}
                                        {subTask.CommentsCount > 0 && (
                                            <span className="flex items-center ml-12">
                                                <Icon className="text-20" color="action">comment</Icon>
                                                <Typography color="textSecondary">{subTask.CommentsCount}</Typography>
                                            </span>
                                        )}

                                        <span className="flex items-center ml-12">
                                            <Tooltip placement="left" title="Subtask Menu">
                                                <IconButton onClick={(e) => { e.stopPropagation(); this.setState({ contextMenuEl: e.target, selectedSubtask: subTask.ID }); }}>
                                                    <Icon className="text-22">more_vert</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                        {contextMenuEl && selectedSubtask === subTask.ID &&
                                            <Menu onClick={(e) => e.stopPropagation()} id={`subtask-${subTask.ID}-menu`} classes={{ paper: "min-w-256" }} anchorEl={contextMenuEl} open={Boolean(contextMenuEl)} onClose={this.closeMenu}>
                                                <MenuItem onClick={(e) => this.setState({ contextMenuEl: null, selectedSubtask: null }, () => openSubtask(subTask, true))}><Icon className="mr-8">open_in_new</Icon>View Task</MenuItem>
                                                {subTask.CompletedYN !== "Y" ?
                                                    <MenuItem onClick={(e) => { subTask.CompletedYN = 'Y'; this.setState({ contextMenuEl: null, selectedSubtask: null }, () => this.props.updateSubtask({ ...subTask, CompletedYN: 'Y' }, false)) }}><Icon className="mr-8">check_circle_outline</Icon>Complete Task</MenuItem> :
                                                    <MenuItem onClick={(e) => { subTask.CompletedYN = null; this.setState({ contextMenuEl: null, selectedSubtask: null }, () => this.props.updateSubtask({ ...subTask, CompletedYN: null }, false)) }}><Icon className="mr-8">undo</Icon>Re-Open Task</MenuItem>
                                                }
                                                <MenuItem onClick={(e) => this.setState({ contextMenuEl: null, selectedSubtask: null }, () => this.disassociateSubtask(e, subTask))}><Icon className="mr-8">cancel</Icon>Dissociate Task</MenuItem>
                                            </Menu>
                                        }
                                    </div>
                                </div>
                                <div className="w-full flex justify-between" style={{ marginTop: -8 }}>
                                    <div className="flex flex-wrap ml-36 pt-10">
                                        {card && card.Data && card.Data.Tags && card.Data.Tags.length > 0 &&
                                            card.Data.Tags.map(tag => {
                                                const label = _.find(tags, { ID: tag.TagID });

                                                return label && (
                                                    <Tooltip title={label.Name} key={tag.ID}>
                                                        <div className={classNames("w-32  h-6 rounded-6 mr-6 mb-6")} style={{ backgroundColor: label.Background }} />
                                                    </Tooltip>
                                                );
                                            }
                                            )}
                                    </div>

                                    <div className="flex flex-wrap mr-8">
                                        {card && card.Data && card.Data.Members && card.Data.Members.length > 0 && card.Data.Members.map(memb => {
                                            const member = _.find(users, { UserName: memb.UserName });
                                            if (member) {
                                                return (
                                                    <Tooltip title={`Assigned to ${member.FirstName} ${member.LastName}`} key={memb.ID}>
                                                        <Badge classes={{ badge: classNames(classes.memberBadge) }} badgeContent={`${member.FirstName.substr(0, 1)}${member.LastName.substr(0, 1)}`}>
                                                            <Avatar className="mr-4 w-24 h-24" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${memb.Co}&ID=${member.Data.Avatar}` ): avatar} />
                                                        </Badge>
                                                    </Tooltip>
                                                )
                                            }
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    )}
                </div>

                {
                    addSubtaskActive &&
                    <ClickAwayListener onClickAway={() => newSubtask === "" && this.setState({ addSubtaskActive: false })}>
                        <div className="w-full mb-12">
                            <Autocomplete
                                title="Subtask Title"
                                options={this.state.options}
                                menuItemComponent={(value) => {
                                    return <MenuItem value={value}>
                                        {value.Name} {value.ParentTaskName && `- [${value.ParentTaskName}]`}
                                    </MenuItem>
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <Icon className="text-30" color="primary" onClick={this.handleClick}>add_rounded</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                noBlur
                                required
                                portal={true}
                                debounce={500}
                                onChange={this.searchTasks}
                                value={newSubtask}
                                search={typeof newSubtask === "object" ? newSubtask.Name : newSubtask}
                                onSelect={(option) => this.setState({ newSubtask: option })}
                            />

                        </div>
                    </ClickAwayListener>
                }

                <Button
                    variant="outlined"
                    onClick={() => this.setState({ addSubtaskActive: true })}
                    classes={{
                        root: "normal-case font-600 h-40",
                        label: "justify-start"
                    }}
                >
                    <Icon className="text-20 mr-8">add</Icon>
                    Add subtask
                </Button>

            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openSubtask: Actions.openSubtask,
        subtaskAdd: Actions.subtaskAdd,
        disassociateSubtask: Actions.disassociateSubtask,
        updateSubtask: Actions.updateSubtask,
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        board: scrumboardApp.board,
        card: scrumboardApp.card,
        username: spReducers.userProfiles.User.UserName,
        users: spReducers.userProfiles.Users,
        tags: spReducers.calendar.tags,
        searchText: scrumboardApp.boards.searchText,
        filters: scrumboardApp.boards.filters,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Subtasks));
