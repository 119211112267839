import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Icon, MenuItem, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCompany } from 'store/actions';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    logo: {},
    logoIcon: {
        fontSize: '32px!important'
    },
    logoText: {
        fontSize: 24
    },
    accountSelect: {},
    accountSelectMenu: {}
});

class BusinessUnitLeftSidebarHeader extends Component {

    state = {
        Co: '',
        List: [],
    };

    componentDidMount = () => {
        const { companies } = this.props;
        if (companies) {
            const { Co, List } = this.props.companies;
            this.setState({ Co, List });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { Co, List } = this.props.companies;
        if (!_.isEqual(prevProps.companies, this.props.companies)) {
            this.setState({ Co, List });
        }
    }

    setCompany = (ev) => {
        const { Co } = this.state;
        this.props.setCompany(ev.target.value, Co);
    }

    render() {
        const { classes } = this.props;
        const { Co, List } = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-col justify-center h-full p-24")}>

                <div className={classNames(classes.logo, "flex items-center flex-1")}>
                    <FuseAnimate animation="transition.expandIn" delay={300}>
                        <Icon className={classNames(classes.logoIcon, "mr-16")}>business_center</Icon>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                        <span className={classes.logoText}>Business Units</span>
                    </FuseAnimate>
                </div>
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <TextField
                        id="account-selection"
                        select
                        label={"Company Name"}
                        className={classes.accountSelect}
                        value={Co}
                        onChange={this.setCompany}
                        SelectProps={{
                            MenuProps: {
                                className: classes.accountSelectMenu
                            }
                        }}
                        placeholder="Select Company"
                        margin="normal"
                    >
                        {List.map((value, index) => (
                            <MenuItem key={index} value={value.Co}>
                                {value.Data.Co.Name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FuseAnimate>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCompany: setCompany,
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        companies: spReducers.companies
    }
}
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(BusinessUnitLeftSidebarHeader));
