import auth from 'auth/store/reducers'
import localSporage from 'store/storage/CacheManager'
import chatPanel from 'main/chatPanel/store/reducers'
import academyApp from 'main/content/apps/academy/store/reducers'
import agreementTypeTasksApp from 'main/content/apps/agreement-type-tasks/store/reducers'
import agreementTypesApp from 'main/content/apps/agreement-types/store/reducers'
import companiesApp from 'main/content/apps/companies/store/reducers'
import agreementsApp from 'main/content/apps/agreements/store/reducers'
import arrivalWindowsApp from 'main/content/apps/arrival-windows/store/reducers'
import auditPostingApp from 'main/content/apps/audit-posting/store/reducers'
import businessUnitsApp from 'main/content/apps/business-units/store/reducers'
import calendarApp from 'main/content/apps/calendar/store/reducers'
import callGatherKeysApp from 'main/content/apps/call-gather-keys/store/reducers'
import callGathersApp from 'main/content/apps/call-gathers/store/reducers'
import callHandlerApp from 'main/content/apps/call-handler/store/reducers'
import callQueuesApp from 'main/content/apps/call-queues/store/reducers'
import incomingCallApp from 'main/content/apps/incoming-call/store/reducers'
import catalogApp from 'main/content/apps/catalog/store/reducers'
import chatApp from 'main/content/apps/chat/store/reducers'
import contactsApp from 'main/content/apps/contacts/store/reducers'
import voiceMailboxesApp from 'main/content/apps/voice-mailboxes/store/reducers'
import costTypesApp from 'main/content/apps/cost-types/store/reducers'
import customerSitesApp from 'main/content/apps/customer-sites/store/reducers'
import customersApp from 'main/content/apps/customers/store/reducers'
import dashboardBuilderApp from 'main/content/apps/dashboard-builder/store/reducers'
import paymentsApp from 'main/content/apps/payments/store/reducers'
import analyticsDashboardApp from 'main/content/apps/dashboards/analytics/store/reducers'
import departmentsApp from 'main/content/apps/departments/store/reducers'
import dialGroupMembersApp from 'main/content/apps/dial-group-members/store/reducers'
import dialGroupsApp from 'main/content/apps/dial-groups/store/reducers'
import dispatchBoardApp from 'main/content/apps/dispatch-board/store/reducers'
import supervisorApp from 'main/content/apps/supervisor/store/reducers'
import divisionsApp from 'main/content/apps/divisions/store/reducers'
import eCommerceApp from 'main/content/apps/e-commerce/store/reducers'
import emailTemplateApp from 'main/content/apps/email-templates/store/reducers'
import employeesApp from 'main/content/apps/employees/store/reducers'
import estimatesApp from 'main/content/apps/estimates/store/reducers'
import fileManagerApp from 'main/content/apps/file-manager/store/reducers'
import fleetVehicleCategoriesApp from 'main/content/apps/fleet-vehicle-categories/store/reducers'
import fleetVehiclesApp from 'main/content/apps/fleet-vehicles/store/reducers'
import formOfPaymentsApp from 'main/content/apps/form-of-payments/store/reducers'
import formsApp from 'main/content/apps/forms/store/reducers'
import checklistsApp from 'main/content/apps/checklists/store/reducers'
import glAccountsApp from 'main/content/apps/gl-accounts/store/reducers'
import huntGroupsApp from 'main/content/apps/hunt-groups/store/reducers'
import inventoryLocationGroupsApp from 'main/content/apps/inventory-location-groups/store/reducers'
import inventoryLocationMaterialsApp from 'main/content/apps/inventory-location-materials/store/reducers'
import inventoryLocationsApp from 'main/content/apps/inventory-locations/store/reducers'
import invoicesApp from 'main/content/apps/invoices/store/reducers'
import laborTypesApp from 'main/content/apps/labor-types/store/reducers'
import mailApp from 'main/content/apps/mail/store/reducers'
import materialCategoriesApp from 'main/content/apps/material-categories/store/reducers'
import materialsApp from 'main/content/apps/materials/store/reducers'
import optionBoardTypesApp from 'main/content/apps/option-board-types/store/reducers'
import payTermsApp from 'main/content/apps/pay-terms/store/reducers'
import phoneNumbersApp from 'main/content/apps/phone-numbers/store/reducers'
import purchaseOrderLinesApp from 'main/content/apps/purchase-order-lines/store/reducers'
import purchaseOrdersApp from 'main/content/apps/purchase-orders/store/reducers'
import rateTemplatesApp from 'main/content/apps/rate-templates/store/reducers'
import reportingApp from 'main/content/apps/reporting/store/reducers'
import scrumboardApp from 'main/content/apps/scrumboard/store/reducers'
import serviceTypesApp from 'main/content/apps/service-types/store/reducers'
import serviceUnitCategoriesApp from 'main/content/apps/service-unit-categories/store/reducers'
import serviceUnitTypesApp from 'main/content/apps/service-unit-types/store/reducers'
import serviceUnitsApp from 'main/content/apps/service-units/store/reducers'
import servicesApp from 'main/content/apps/services/store/reducers'
import supportApp from 'main/content/apps/support/store/reducers'
import supportRequestApp from 'main/content/apps/support/requests/store/reducers'
import taxCodesApp from 'main/content/apps/tax-codes/store/reducers'
import teamsApp from 'main/content/apps/teams/store/reducers'
import techUAApp from 'main/content/apps/tech-ua/store/reducers'
import technicianApp from 'main/content/apps/technician/store/reducers'
import techniciansApp from 'main/content/apps/technicians/store/reducers'
import textGatherPhrasesApp from 'main/content/apps/text-gather-phrases/store/reducers'
import textGathersApp from 'main/content/apps/text-gathers/store/reducers'
import timecardsApp from 'main/content/apps/timecards/store/reducers'
import todoApp from 'main/content/apps/todo/store/reducers'
import tripsApp from 'main/content/apps/trips/store/reducers'
import uomsApp from 'main/content/apps/units-of-measure/store/reducers'
import userRolesApp from 'main/content/apps/user-roles/store/reducers'
import usersApp from 'main/content/apps/users/store/reducers'
import vendorsApp from 'main/content/apps/vendors/store/reducers'
import workOrderScopesApp from 'main/content/apps/work-order-scopes/store/reducers'
import workOrderUsagesApp from 'main/content/apps/work-order-usages/store/reducers'
import gpsInventoryTransfersApp from 'main/content/apps/gps-inventory-transfers/store/reducers'
import gpsInventoryTransferListApp from 'main/content/apps/gps-transfer-list/store/reducers'
import workOrdersApp from 'main/content/apps/work-orders/store/reducers'
import workOrderHistoryApp from 'main/content/apps/work-order-history/store/reducers'
import minimizedDialogApp from 'main/content/apps/minimized-dialogs/store/reducers'
import splitscreenDialogApp from 'main/content/apps/splitscreen-dialogs/store/reducers'
import dialer from 'main/dialer/store/reducers'
import messagingApp from 'main/messaging/store/reducers'
import quickPanel from 'main/quickPanel/store/reducers'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { persistor } from 'store'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
// import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { RESET_REDUCERS, RESET_STATE } from 'store/actions'
import CapacitorStorage from 'store/storage/CapacitorStorage'
import dateTransform from 'store/transforms/dateTransform'
import fuse from './fuse'
import spAuth from './spAuthentication'
import spReducers from './spConnection'
import support from './support'
import utilities from './utilities'
import version from './version'
import alerts from './alerts'

const catalogFilter = createBlacklistFilter('catalogApp', [
  'catalogAssemblyDialog',
  'catalogItemDialog',
  'catalogAssemblyComponentDialog',
  'catalogOptionBoardDialog',
  'catalogDialog',
])

// const dispatchFilter = createBlacklistFilter(
//     'dispatchBoardApp',
//     ['dispatchBoard.dispatchBoardDialog', 'dispatchBoard.dispatchFolderDialog']
// );

// const spAuthFilter = createBlacklistFilter(
//     'spAuth',
//     ['vault', 'authentication']
// )

const persistConfig = {
  key: 'root-2',
  // storage: window.Cordova ? CapacitorStorage : localSporage,
  storage: localSporage,
  stateReconciler: autoMergeLevel2,
  // transforms: [spAuthFilter],
  whitelist: ['auth', 'callHandlerApp'],
  serialize: false,
  deserialize: false,
}

const offlineFilter = createBlacklistFilter('offline', ['status'])

const callFilter = createBlacklistFilter('callHandler', ['Calls'])

const userFilter = createBlacklistFilter('userProfiles', [
  'Users' /*, 'Pages', 'Securables'*/,
])

const techFilter = createBlacklistFilter('technician', ['date'])

const spPersistConfig = {
  key: 'sp-reducers-2',
  // storage: window.Cordova ? CapacitorStorage : localSporage,
  storage: localSporage,
  stateReconciler: autoMergeLevel2,
  transforms: [
    offlineFilter,
    callFilter,
    userFilter,
    techFilter /*, dateTransform*/,
  ],
  blacklist: [
    'connectionStatus',
    'calendar',
    'notifications',
    'workOrders',
    'materials',
    'catalog',
    'userProfiles',
  ],
  serialize: false,
  deserialize: false,
}

const dispatchBoardData = {
  technicians: spReducers.technicians,
  trips: spReducers.trips,
}

const appReducer = combineReducers({
  alerts,
  auth,
  fuse,
  spReducers: persistReducer(spPersistConfig, spReducers),
  spAuth,
  analyticsDashboardApp,
  mailApp,
  todoApp,
  formsApp,
  checklistsApp,
  uomsApp,
  reportingApp,
  fileManagerApp,
  contactsApp,
  messagingApp,
  techUAApp,
  academyApp,
  catalogApp,
  companiesApp,
  callHandlerApp,
  incomingCallApp,
  auditPostingApp,
  dispatchBoardApp,
  dispatchBoardData,
  supervisorApp,
  dashboardBuilderApp,
  emailTemplateApp,
  technicianApp,
  customersApp,
  estimatesApp,
  voiceMailboxesApp,
  textGathersApp,
  optionBoardTypesApp,
  textGatherPhrasesApp,
  vendorsApp,
  timecardsApp,
  customerSitesApp,
  workOrdersApp,
  workOrderScopesApp,
  workOrderUsagesApp,
  gpsInventoryTransfersApp,
  gpsInventoryTransferListApp,
  workOrderHistoryApp,
  minimizedDialogApp,
  splitscreenDialogApp,
  paymentsApp,
  agreementsApp,
  agreementTypesApp,
  agreementTypeTasksApp,
  fleetVehicleCategoriesApp,
  serviceUnitCategoriesApp,
  serviceUnitTypesApp,
  serviceUnitsApp,
  fleetVehiclesApp,
  purchaseOrdersApp,
  purchaseOrderLinesApp,
  inventoryLocationGroupsApp,
  inventoryLocationsApp,
  materialCategoriesApp,
  materialsApp,
  servicesApp,
  techniciansApp,
  teamsApp,
  employeesApp,
  usersApp,
  userRolesApp,
  serviceTypesApp,
  laborTypesApp,
  inventoryLocationMaterialsApp,
  costTypesApp,
  glAccountsApp,
  formOfPaymentsApp,
  payTermsApp,
  arrivalWindowsApp,
  rateTemplatesApp,
  taxCodesApp,
  businessUnitsApp,
  departmentsApp,
  divisionsApp,
  tripsApp,
  invoicesApp,
  supportApp,
  supportRequestApp,
  phoneNumbersApp,
  callGathersApp,
  huntGroupsApp,
  dialGroupsApp,
  dialGroupMembersApp,
  callQueuesApp,
  callGatherKeysApp,
  calendarApp,
  dialer,
  quickPanel,
  chatPanel,
  chatApp,
  eCommerceApp,
  scrumboardApp,
  version,
  support,
  utilities,
})

const rootReducer = (state, action) => {
  switch (action.type) {
    case RESET_REDUCERS: {
      const {
        spReducers,
        spAuth,
        auth,
        callHandlerApp,
        dispatchBoardApp,
        support,
        version,
      } = state
      console.warn('Resetting Application State')
      // localSporage.removeItem('persist:root');
      return appReducer(
        {
          spReducers,
          spAuth,
          auth,
          callHandlerApp,
          dispatchBoardApp,
          support,
          version,
        },
        action,
      )
    }
    case RESET_STATE: {
      return appReducer(undefined, action)
    }
    default: {
      return appReducer(state, action)
    }
  }
}

export default persistReducer(persistConfig, rootReducer)
