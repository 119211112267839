import axios from 'axios/index';

export const SET_MATERIAL_CATEGORY_DATA = '[SPCONNECTION] SET MATERIAL CATEGORY DATA';
export const GET_MATERIAL_CATEGORY_DATA = '[SPCONNECTION] GET MATERIAL CATEGORY DATA';
export const SUBSCRIBE_MATERIAL_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE MATERIAL CATEGORY LIST';

export function getInitialMaterialCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/MaterialCategory`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getMaterialCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/MaterialCategory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_MATERIAL_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setMaterialCategoryData(data) {
    return {
        type: SET_MATERIAL_CATEGORY_DATA,
        payload: data
    };
}