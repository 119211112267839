import axios from 'axios';
import { getUserData } from 'main/content/apps/call-gathers/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_CALL_GATHERS = '[CALL GATHERS APP] GET CALL GATHERS';
export const SET_SEARCH_TEXT = '[CALL GATHERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CALL_GATHERS = '[CALL GATHERS APP] TOGGLE IN SELECTED CALL GATHERS';
export const SELECT_ALL_CALL_GATHERS = '[CALL GATHERS APP] SELECT ALL CALL GATHERS';
export const DESELECT_ALL_CALL_GATHERS = '[CALL GATHERS APP] DESELECT ALL CALL GATHERS';
export const OPEN_NEW_CALL_GATHER_DIALOG = '[CALL GATHERS APP] OPEN NEW CALL GATHER DIALOG';
export const CLOSE_NEW_CALL_GATHER_DIALOG = '[CALL GATHERS APP] CLOSE NEW CALL GATHER DIALOG';
export const OPEN_EDIT_CALL_GATHER_DIALOG = '[CALL GATHERS APP] OPEN EDIT CALL GATHER DIALOG';
export const UPDATE_EDIT_CALL_GATHER_DIALOG = '[CALL GATHERS APP] UPDATE EDIT CALL GATHER DIALOG';
export const CLOSE_EDIT_CALL_GATHER_DIALOG = '[CALL GATHERS APP] CLOSE EDIT CALL GATHER DIALOG';
export const ADD_CALL_GATHER = '[CALL GATHERS APP] ADD CALL GATHER';
export const UPDATE_CALL_GATHER = '[CALL GATHERS APP] UPDATE CALL GATHER';
export const REMOVE_CALL_GATHER = '[CALL GATHERS APP] REMOVE CALL GATHER';
export const REMOVE_CALL_GATHERS = '[CALL GATHERS APP] REMOVE CALL GATHERS';
export const TOGGLE_STARRED_CALL_GATHER = '[CALL GATHERS APP] TOGGLE STARRED CALL GATHER';
export const TOGGLE_STARRED_CALL_GATHERS = '[CALL GATHERS APP] TOGGLE STARRED CALL GATHERS';
export const SET_CALL_GATHERS_STARRED = '[CALL GATHERS APP] SET CALL GATHERS STARRED ';
export const SET_SEARCH_RESULTS = '[CALL GATHERS APP] SET SEARCH RESULTS';

export function getCallGathers(routeParams) {
    const request = axios.get('/api/call-gathers-app/call-gathers', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_CALL_GATHERS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedCallGathers(callGatherId) {
    return {
        type: TOGGLE_IN_SELECTED_CALL_GATHERS,
        callGatherId
    }
}


export function selectAllCallGathers() {
    return {
        type: SELECT_ALL_CALL_GATHERS
    }
}

export function deSelectAllCallGathers() {
    return {
        type: DESELECT_ALL_CALL_GATHERS
    }
}


export function openNewCallGatherDialog() {
    return {
        type: OPEN_NEW_CALL_GATHER_DIALOG
    }
}

export function closeNewCallGatherDialog() {
    return {
        type: CLOSE_NEW_CALL_GATHER_DIALOG
    }
}

export function openEditCallGatherDialog(data) {
    return {
        type: OPEN_EDIT_CALL_GATHER_DIALOG,
        data
    }
}

export function closeEditCallGatherDialog() {
    return {
        type: CLOSE_EDIT_CALL_GATHER_DIALOG
    }
}

export function addCallGather(newCallGather) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/CallGather`,
            newCallGather
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_CALL_GATHER,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Gather #${response.data.ID} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateCallGather(callGather) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/CallGather?Co=${encodeURIComponent(callGather.Co)}&Gather=${encodeURIComponent(callGather.ID)}`,
            callGather
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_CALL_GATHER,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Gather #${callGather.ID} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeCallGather(callGather) {
    return (dispatch, getState) => {

        const request = axios.post('/api/call-gathers-app/remove-callGather', {
            callGather
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CALL_GATHER,
                    data: callGather
                })
            ])
        );
    };
}


export function removeCallGathers(callGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGathersApp.callGathers;

        const request = axios.post('/api/call-gathers-app/remove-callGathers', {
            callGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CALL_GATHERS
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHERS
                })
            ]).then(() => dispatch(getCallGathers(routeParams)))
        );
    };
}

export function toggleStarredCallGather(callGatherId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().callGathersApp.callGathers;

        const request = axios.post('/api/call-gathers-app/toggle-starred-callGather', {
            callGatherId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CALL_GATHER
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallGathers(routeParams)))
        );
    };
}

export function toggleStarredCallGathers(callGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGathersApp.callGathers;

        const request = axios.post('/api/call-gathers-app/toggle-starred-callGathers', {
            callGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CALL_GATHERS
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallGathers(routeParams)))
        );
    };
}

export function setCallGathersStarred(callGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGathersApp.callGathers;

        const request = axios.post('/api/call-gathers-app/set-callGathers-starred', {
            callGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_GATHERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallGathers(routeParams)))
        );
    };
}

export function setCallGathersUnstarred(callGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGathersApp.callGathers;

        const request = axios.post('/api/call-gathers-app/set-callGathers-unstarred', {
            callGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_GATHERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHERS
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function purchaseCallGather(num) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/IncomingCallGather`, num);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: ADD_CALL_GATHER
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Gather ${num.Number} has been successfully purchased.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        });
    }
}

export function searchAvailableNumbers(Co, area, search) {
    return (dispatch, getState) => {
        var request = axios.get(`${window["apiLocation"]}/api/IncomingCallGather?Co=${encodeURIComponent(Co)}&AreaCode=${encodeURIComponent(area)}&Pattern=${search}`);

        return request.then((response) => {
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: response.data,
            })
        });
    }
}
