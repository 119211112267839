import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CallQueuesList extends Component {

    state = {
        selectedCallQueuesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCallQueueMenu = (event) => {
        this.setState({ selectedCallQueuesMenu: event.currentTarget });
    };

    componentDidUpdate(prevProps, prevState) {
        window["warn"]('Call Queues List:', this.props);
    }

    closeSelectedCallQueuesMenu = () => {
        this.setState({ selectedCallQueuesMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, securables, CallQueues, user, searchText, openNewCallQueueDialog, selectedCallQueueIds, selectAllCallQueues, deSelectAllCallQueues, toggleInSelectedCallQueues, openEditCallQueueDialog, removeCallQueues, removeCallQueue, toggleStarredCallQueue, setCallQueuesUnstarred, setCallQueuesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedCallQueuesData ? (!this.props.callQueueData ? [] : this.props.callQueueData) : this.props.selectedCallQueuesData), searchText);
        const { selectedCallQueuesMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "call-queues" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewCallQueueDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">queue_music</Icon>Call Queues</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditCallQueueDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Queue",
                                accessor: "Queue",
                                className: "font-bold justify-center",
                                width: 175
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 300,
                            },
                            {
                                Header: "Default Business Unit",
                                accessor: "DefaultBusinessUnit",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Default Division",
                                accessor: "DefaultDivision",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Default Department",
                                accessor: "DefaultDepartment",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Call Queues found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCallQueues: Actions.getCallQueues,
        getUserData: Actions.getUserData,
        toggleInSelectedCallQueues: Actions.toggleInSelectedCallQueues,
        selectAllCallQueues: Actions.selectAllCallQueues,
        deSelectAllCallQueues: Actions.deSelectAllCallQueues,
        openEditCallQueueDialog: Actions.openEditCallQueueDialog,
        removeCallQueues: Actions.removeCallQueues,
        removeCallQueue: Actions.removeCallQueue,
        toggleStarredCallQueue: Actions.toggleStarredCallQueue,
        toggleStarredCallQueues: Actions.toggleStarredCallQueues,
        setCallQueuesStarred: Actions.setCallQueuesStarred,
        setCallQueuesUnstarred: Actions.setCallQueuesUnstarred,
        openNewCallQueueDialog: Actions.openNewCallQueueDialog
    }, dispatch);
}

function mapStateToProps({ callQueuesApp, spReducers }) {
    return {
        callQueues: callQueuesApp.CallQueues.entities,
        selectedCallQueueIds: callQueuesApp.CallQueues.selectedCallQueueIds,
        searchText: callQueuesApp.CallQueues.searchText,
        user: callQueuesApp.user,
        callQueueData: spReducers.callQueues,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallQueuesList)));
