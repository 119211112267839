import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newServiceTypeState = {
    Co: null,
    ServiceType: '',
    Description: '',
    Detail: '',
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    }
};

class ServiceTypeDialog extends Component {
    state = { ...newServiceTypeState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newServiceTypeState.Co = this.props.Co;
        if (!prevProps.serviceTypeDialog.props.open && this.props.serviceTypeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.serviceTypeDialog.type === 'edit' &&
                this.props.serviceTypeDialog.data &&
                !_.isEqual(this.props.serviceTypeDialog.data, prevState)) {
                this.setState({ ...this.props.serviceTypeDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.serviceTypeDialog.type === 'new' &&
                !_.isEqual(newServiceTypeState, prevState)) {
                this.setState({ ...newServiceTypeState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.serviceTypeDialog.type === 'edit' ? this.props.closeEditServiceTypeDialog() : this.props.closeNewServiceTypeDialog();
    };

    canBeSubmitted() {
        const { ServiceType, Description } = this.state;
        return (
            ServiceType.length > 0 && Description.length > 0
        );
    }

    render() {
        const { classes, serviceTypeDialog, addServiceType, updateServiceType, removeServiceType } = this.props;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...serviceTypeDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {serviceTypeDialog.type === 'new' ? 'New Service Type' : 'Edit Service Type'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Service Type"

                                    id="serviceType"
                                    name="ServiceType"
                                    value={this.state.ServiceType}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(serviceTypeDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {serviceTypeDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addServiceType(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                        </Button>
                            </DialogActions>
                        ) : (
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateServiceType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                        </Button>
                                </DialogActions>
                            )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditServiceTypeDialog: Actions.closeEditServiceTypeDialog,
        closeNewServiceTypeDialog: Actions.closeNewServiceTypeDialog,
        addServiceType: Actions.addServiceType,
        updateServiceType: Actions.updateServiceType,
        removeServiceType: Actions.removeServiceType
    }, dispatch);
}

function mapStateToProps({ serviceTypesApp, spReducers }) {
    return {
        serviceTypeDialog: serviceTypesApp.serviceTypes.serviceTypeDialog,
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ServiceTypeDialog));
