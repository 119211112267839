import React, { Component } from 'react';
import { Icon, Typography, Paper, Button, Menu, MenuItem, Dialog } from '@material-ui/core';
import moment from 'moment';
import getProfileImage from '../../../../../../../functions/getProfileImageUrl';

class CardAttachment extends Component {
    state = {
        anchorEl: null
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { card, item, makeCover, removeCover, removeAttachment, Co } = this.props;
        const { anchorEl } = this.state;
        const isImg = [".png", ".jpg", ".bmp", ".jpeg"].indexOf((item || {}).Extension) > -1;
        const ext = { "png": "image", "bmp": "image", "jpg": "image", "jpeg": "image", "pdf": "picture_as_pdf", "doc": "insert_drive_file", "docx": "insert_drive_file", "txt": "insert_drive_file", "xls": "insert_chart", "xlsx": "insert_chart", "csv": "insert_chart", "mov": "play_circle_outline", "mp4": "play_circle_outline" };

        // switch ( item.type )
        // {
        //     case 'image':
        //     {
        return (
            <div className="flex w-full sm:w-1/2 mb-16" key={item.Attachment}>
                <div className="flex items-center justify-center w-128 h-auto mr-16">
                    <Paper className="rounded-4 overflow-hidden" elevation={1}>
                        {isImg ?
                            <img onClick={() => this.props.onSelected(item)} className="block max-h-full max-h-full cursor-pointer" src={getProfileImage(`ID=${item.Attachment}`)} alt="attachment" /> :
                            <div onClick={() => this.props.onSelected(item)} className="block max-h-full max-h-full min-h-128 min-w-128 cursor-pointer relative">
                                <Icon color="primary" className="pin-t pin-b pin-l pin-r absolute m-auto">{ext[((item || {}).Extension || '').replace('.', '')]}</Icon>
                            </div>
                        }
                    </Paper>
                </div>
                <div className="flex flex-auto flex-col justify-center items-start min-w-0">
                    <div className="flex items-center w-full">
                        <Typography className="text-16 font-600 truncate flex-shrink">{`${item.FileName}`}</Typography>
                        {card.CoverImage === item.Attachment && (
                            <Icon className="text-orange-light text-20 ml-4">star</Icon>
                        )}
                    </div>
                    <Typography className="truncate w-full text-12" color="textSecondary">{moment(item.AddedDate).format('M/D/YYYY')}</Typography>
                    <Typography className="truncate w-full text-12 mb-12" color="textSecondary">{item.FileSize} MB</Typography>
                    <Button
                        aria-owns={anchorEl ? 'actions-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        variant="outlined"
                        size="small"
                    >
                        Actions
                        <Icon className="text-20">arrow_drop_down</Icon>
                    </Button>
                    <Menu
                        id="actions-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        {isImg && card.CoverImage !== item.Attachment ? (
                            <MenuItem
                                onClick={() => {
                                    this.handleClose();
                                    makeCover(item.Attachment);
                                }}
                            >
                                Make Cover
                            </MenuItem>
                        ) : isImg && (
                            <MenuItem
                                onClick={() => {
                                    this.handleClose();
                                    removeCover();
                                }}
                            >
                                Remove Cover
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                this.handleClose();
                                removeAttachment({ Co, ...item });
                            }}
                        >
                            Remove Attachment
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        )
        //     }
        //     case 'link':
        //     {
        //         return (
        //             <div className="flex w-full sm:w-1/2 mb-16" key={item.id}>
        //                 <Paper className="min-w-128 w-128 h-128 mr-16 flex items-center justify-center rounded-4 overflow-hidden" elevation={1}>
        //                     <Typography className="font-600">LINK</Typography>
        //                 </Paper>
        //                 <div className="flex flex-auto flex-col justify-center items-start min-w-0">
        //                     <Typography className="text-16 font-600 truncate w-full">{item.url}</Typography>
        //                     <Typography className="truncate w-full mb-12" color="textSecondary">{item.time}</Typography>
        //                     <Button
        //                         aria-owns={anchorEl ? 'actions-menu' : null}
        //                         aria-haspopup="true"
        //                         onClick={this.handleClick}
        //                         variant="outlined"
        //                         size="small"
        //                     >
        //                         Actions
        //                         <Icon className="text-20">arrow_drop_down</Icon>
        //                     </Button>
        //                     <Menu
        //                         id="simple-menu"
        //                         anchorEl={anchorEl}
        //                         open={Boolean(anchorEl)}
        //                         onClose={this.handleClose}
        //                     >
        //                         <MenuItem
        //                             onClick={() => {
        //                                 this.handleClose();
        //                                 removeAttachment(item.id);
        //                             }}
        //                         >
        //                             Remove Attachment
        //                         </MenuItem>
        //                     </Menu>
        //                 </div>
        //             </div>
        //         )
        //     }
        //     default:
        //     {
        //         return null;
        //     }
        // }
    }
}

export default CardAttachment;
