import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import UOMList from 'main/content/apps/units-of-measure/UOMsList';
import UOMHeader from 'main/content/apps/units-of-measure/UOMsHeader';
import UOMSidebarContent from 'main/content/apps/units-of-measure/UOMsSidebarContent';
import UOMsLeftSidebarHeader from 'main/content/apps/units-of-measure/UOMsLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import UOMDialog from 'main/content/apps/units-of-measure/UOMDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class UOMsApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getUOMs(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewUOMDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <UOMHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <UOMList />
                            }
                            leftSidebarHeader={
                                <UOMsLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <UOMSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <UOMDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUOMs: Actions.getUOMs,
        getUserData: Actions.getUserData,
        openNewUOMDialog: Actions.openNewUOMDialog
    }, dispatch);
}

function mapStateToProps({ uomsApp }) {
    return {
        uoms: uomsApp.uoms.entities,
        selectedUOMIds: uomsApp.uoms.selectedUOMIds,
        searchText: uomsApp.uoms.searchText,
        user: uomsApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UOMsApp)));
