import _ from '@lodash'
import {
  AppBar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  FormControlLabel,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import { DatePicker } from '@material-ui/pickers'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import AgreementTypeTaskOverridesList from './AgreementTypeTaskOverridesList'
import * as Actions from './store/actions'
import { FeatureFlagsContext } from '../../../../providers/featureFlagsProvider'
import SelectWrapper from '../../components/select/SelectWrapper'

function Marker({ text, icon, style, badge, heading, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <div onClick={onClick}>
        <img
          onMouseDown={e => {
            e.stopPropagation()
          }}
          style={style}
          src={icon}
        />
      </div>
    </Tooltip>
  )
}

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: 73,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  updateOverride: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteOverride: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})
const newAgreementTypeTaskState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  AgreementType: null,
  TaskType: null,
  Name: null,
  Description: null,
  UnitCat: null,
  Notes: null,
  AgreementID: null,
  UnitType: null,
  UnitTypes: [],
  AssociatedService: null,
  Department: null,
  Frequency: null,
  BME: null,
  DateMedian: null,
  LeadTime: null,
  RevenueType: null,
  Revenue: null,
  Additional: null,
  OptionalYN: 'N',
  ActiveYN: 'Y',
  Data: {
    Overrides: [],
  },
  taskOverride: {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    AgreementType: null,
    TaskType: null,
    UnitType: null,
    UnitTypes: [],
    Revenue: null,
    Additional: null,
    Data: {
      ErrMsg: null,
    },
  },
  clItem: {
    Title: null,
    Description: null,
    type: 'C',
  },
  value: 0,
}

class AgreementTypeTasksDialog extends Component {
  static contextType = FeatureFlagsContext

  state = {
    ..._.cloneDeepWith(newAgreementTypeTaskState),
    featureFlags: this.context,
  }

  componentDidMount() {}

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    newAgreementTypeTaskState.Co = this.props.Co
    const initialState = _.cloneDeepWith(newAgreementTypeTaskState)
    if (
      !prevProps.agreementTypeTaskDialog.props.open &&
      this.props.agreementTypeTaskDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.agreementTypeTaskDialog.type === 'edit' &&
        this.props.agreementTypeTaskDialog.data &&
        !_.isEqual(this.props.agreementTypeTaskDialog.data, prevState)
      ) {
        const { data } = this.props.agreementTypeTaskDialog
        this.setState({
          ...initialState,
          ...data,
          originalUnitTypes: data.UnitTypes,
          taskOverride: {
            ...data.taskOverride,
            originalUnitTypes: data.taskOverride ? data.taskOverride.UnitTypes : [],
          },
        })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.agreementTypeTaskDialog.type === 'new' &&
        !_.isEqual(
          { ...initialState, ...this.props.agreementTypeTaskDialog.data },
          prevState,
        )
      ) {
        const { data } = this.props.agreementTypeTaskDialog
        this.setState({
          ...initialState,
          ...data,
          originalUnitTypes: data.UnitTypes,
          taskOverride: {
            ...data.taskOverride,
            originalUnitTypes: data.taskOverride ? data.taskOverride.UnitTypes : [],
          },
        })
      }
    } else {
      if (
        this.props.agreementTypeTaskDialog.type === 'update' &&
        this.props.agreementTypeTaskDialog.data &&
        !_.isEqual(this.props.agreementTypeTaskDialog.data, prevState)
      ) {
        const { data } = this.props.agreementTypeTaskDialog
        this.props.agreementTypeTaskDialog.type = 'edit'
        this.setState({
          ...data,
          originalUnitTypes: data.UnitTypes,
          taskOverride: {
            ...data.taskOverride,
            originalUnitTypes: data.taskOverride ? data.taskOverride.UnitTypes : [],
          },
        })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.agreementTypeTaskDialog.type === 'edit'
      ? this.props.closeEditAgreementTypeTaskDialog()
      : this.props.closeNewAgreementTypeTaskDialog()
  }

  canBeSubmitted() {
    const {
      BusinessUnit,
      Division,
      AgreementType,
      TaskType,
      Name,
      Description,
    } = this.state
    return (
      BusinessUnit &&
      Division &&
      AgreementType &&
      TaskType &&
      Name &&
      Description &&
      BusinessUnit.length > 0 &&
      Division.length > 0 &&
      AgreementType.length > 0 &&
      TaskType.length > 0 &&
      Name.length > 0 &&
      Description.length > 0
    )
  }

  handleDateMedianChange = event => {
    let { DateMedian } = this.state
    DateMedian = event._d.toLocaleDateString('en-US')
    window['warn'](DateMedian)
    this.setState({ ...this.state, DateMedian })
  }

  setOverride = taskOverride => {
    this.setState({ ...this.state, taskOverride })
  }

  canOverrideBeSubmitted = () => {
    const { UnitType, Revenue, Additional } = this.state.taskOverride
    return UnitType && Revenue && Additional && UnitType.length > 0
  }

  render() {
    const useNewMultiUnitTypeSelect =
      this.state.featureFlags['spp-507-multi-unit-type-select']

    const getUnitTypeAutocompleteComponent = isTaskOverride => {
      return (
        <Autocomplete
          className='mb-12'
          title='Unit Type'
          options={
            isTaskOverride
              ? _.filter(serviceUnitTypes, {
                  UnitCat: this.state.UnitCat,
                })
              : [
                  { UnitType: null, Name: 'All Service Unit Types' },
                  ..._.filter(serviceUnitTypes, {
                    UnitCat: this.state.UnitCat,
                  }),
                ]
          }
          menuItemComponent={value => {
            return (
              <MenuItem key={value.UnitType || '999'} value={value.UnitType}>
                <div className='flex'>{`${value.Name}`}</div>
              </MenuItem>
            )
          }}
          value={
            (isTaskOverride
              ? this.state.taskOverride.UnitType
              : this.state.UnitType) || ''
          }
          portal={true}
          onSelect={option =>
            isTaskOverride
              ? this.setState({
                  taskOverride: {
                    ...this.state.taskOverride,
                    UnitType: option.UnitType,
                  },
                })
              : this.setState({ UnitType: option.UnitType })
          }
          required={isTaskOverride}
        />
      )
    }

    const getUnitTypeMultiSelectComponent = isTaskOverride => {
      var serviceUnitToSelectValueMapper = serviceUnit => ({
        UnitType: {
          UnitType: serviceUnit.UnitType,
          Name: serviceUnit.Name,
        },
        AgreementTypeTaskId: this.state.ID,
        UnitTypeId: serviceUnit.Id,
        Id: null,
      })

      const options = _.filter(serviceUnitTypes, {
        UnitCat: this.state.UnitCat,
      }).map(serviceUnitToSelectValueMapper)

      const originalValues =
        (isTaskOverride
          ? this.state.taskOverride.originalUnitTypes
          : this.state.originalUnitTypes) || []

      const currentValues =
        (isTaskOverride
          ? this.state.taskOverride.UnitTypes
          : this.state.UnitTypes) || []

      const valuesMap = _.keyBy(originalValues, 'UnitType.UnitType')

      const mergedOptions = options.map(option => {
        const existingValue = valuesMap[option.UnitType.UnitType]
        return existingValue ? existingValue : option
      })

      return (
        <SelectWrapper
          label={'Unit Type'}
          options={mergedOptions}
          values={currentValues}
          onChange={event => {
            const eventValues = event.target.value

            const newValue = eventValues[eventValues.length - 1]
            const existingOptionToRemove = currentValues.find(
              currentValue => currentValue.UnitType.UnitType === newValue,
            )

            // we remove unchecked option
            const effectiveValues = eventValues
              .slice(0, -1)
              .filter(
                eventValue =>
                  existingOptionToRemove === undefined ||
                  (existingOptionToRemove !== undefined &&
                    eventValue.UnitType.UnitType !==
                      existingOptionToRemove.UnitType.UnitType),
              )

            // we add newly checked option
            if (existingOptionToRemove === undefined) {
              effectiveValues.push(
                mergedOptions.find(
                  option => option.UnitType.UnitType === newValue,
                ),
              )
            }

            isTaskOverride
              ? this.setState({
                  taskOverride: {
                    ...this.state.taskOverride,
                    UnitTypes: [...effectiveValues],
                  },
                })
              : this.setState({ UnitTypes: [...effectiveValues] })
          }}
          required={isTaskOverride}
          multiple={true}
          nameProperty={'UnitType.Name'}
          valueProperty={'UnitType.UnitType'}
        />
      )
    }

    const {
      classes,
      securables,
      agreementTypeTaskDialog,
      addAgreementTypeTask,
      agreementTypes,
      updateAgreementTypeTask,
      removeAgreementTypeTask,
      businessUnits,
      divisions,
      departments,
      services,
      serviceUnitCategories,
      serviceUnitTypes,
      addOverride,
      updateOverride,
      removeOverride,
    } = this.props
    const {
      Co,
      BusinessUnit,
      Division,
      AgreementType,
      Data,
      value,
      Frequency,
      taskOverride,
    } = this.state
    const frequencies = { S: 'Half', Q: 'Quarter', M: 'Month', W: 'Week' }
    const accessLevel = _.find(securables, { Securable: 'agreement-types' })
    const agreementType =
      BusinessUnit && Division && AgreementType && agreementTypes
        ? _.find(agreementTypes, { BusinessUnit, Division, AgreementType })
        : null

    return (
      <Media query='(min-width: 512px) and (min-height: 512px)'>
        {matches => (
          <Dialog
            classes={{
              root: classes.root,
              paper: matches
                ? classes.paper
                : 'full-screen-dialog' /*if fullScreen*/,
            }}
            fullScreen={!matches}
            className={classes.root}
            {...agreementTypeTaskDialog.props}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth='md'
          >
            <AppBar
              position='static'
              elevation={1}
              className='dialog-header' /*if fullScreen*/
            >
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  {['new', 'customer'].indexOf(agreementTypeTaskDialog.type) >
                  -1
                    ? 'New Agreement Type Task'
                    : `${this.state.TaskType} Task Overview`}
                </Typography>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches ? 10 : 4 /*if fullScreen*/,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon' /*if fullScreen*/
              >
                <Icon>close</Icon>
              </IconButton>
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                variant='scrollable'
                scrollButtons='on'
              >
                <Tab disableRipple icon={<Icon>list_add</Icon>} label='Info' />
                {/* <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Overrides ? this.state.Data.Overrides.length : 0} color="error"><Icon>assignment_done</Icon></Badge>} label="Checklist" /> */}
                {agreementType &&
                  agreementType.PriceMethod === 'T' &&
                  this.state.RevenueType === 'U' && (
                    <Tab
                      disableRipple
                      icon={
                        <Badge
                          badgeContent={
                            this.state.Data && this.state.Data.Overrides
                              ? this.state.Data.Overrides.length
                              : 0
                          }
                          color='error'
                        >
                          <Icon>playlist_add</Icon>
                        </Badge>
                      }
                      label='Overrides'
                    />
                  )}
              </Tabs>
            </AppBar>

            {value === 0 && (
              <TabContainer>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'>
                    <Icon color='action'>list_add</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Business Unit'
                    id='BusinessUnit'
                    name='BusinessUnit'
                    value={this.state.BusinessUnit || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                    disabled={
                      agreementTypeTaskDialog.type === 'new' ? false : true
                    }
                  >
                    {businessUnits && businessUnits.length > 0
                      ? businessUnits.map(value => (
                          <MenuItem value={value.BusinessUnit}>
                            {`${value.BusinessUnit} - ${value.Description}`}
                          </MenuItem>
                        ))
                      : ''}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Division'
                    id='Division'
                    name='Division'
                    value={this.state.Division || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                    disabled={
                      agreementTypeTaskDialog.type === 'new' ? false : true
                    }
                  >
                    {this.state.BusinessUnit ? (
                      divisions && divisions.length > 0 ? (
                        divisions.map(value =>
                          value.BusinessUnit == this.state.BusinessUnit ? (
                            <MenuItem value={value.Division}>
                              {`${value.Division} - ${value.Description}`}
                            </MenuItem>
                          ) : (
                            ''
                          ),
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      <MenuItem>Please Select a Business Unit</MenuItem>
                    )}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Agreement Type'
                    id='agreementType'
                    name='AgreementType'
                    value={this.state.AgreementType || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                    select
                    required
                    disabled={
                      agreementTypeTaskDialog.type === 'new' ? false : true
                    }
                  >
                    {this.state.BusinessUnit &&
                    this.state.BusinessUnit.length > 0 ? (
                      this.state.Division && this.state.Division.length > 0 ? (
                        agreementTypes && agreementTypes.length > 0 ? (
                          agreementTypes.map((value, index) =>
                            this.state.BusinessUnit == value.BusinessUnit &&
                            this.state.Division == value.Division ? (
                              <MenuItem key={index} value={value.AgreementType}>
                                {`${value.Name}`}
                              </MenuItem>
                            ) : (
                              ''
                            ),
                          )
                        ) : (
                          ''
                        )
                      ) : (
                        <MenuItem value=''>Please Select a Division</MenuItem>
                      )
                    ) : (
                      <MenuItem value=''>
                        Please Select a Business Unit
                      </MenuItem>
                    )}
                  </TextField>
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>chat_bubble_outline</Icon>
                  </div>
                  <div className='sm:flex w-full'>
                    <TextField
                      className={classes.formControl}
                      label='Task'
                      id='taskType'
                      name='TaskType'
                      value={this.state.TaskType || ''}
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: this.state.TaskType ? true : false,
                      }}
                      variant='outlined'
                      fullWidth
                      required
                      disabled={
                        agreementTypeTaskDialog.type === 'new' ? false : true
                      }
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Name'
                      id='name'
                      name='Name'
                      value={this.state.Name || ''}
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: this.state.Name ? true : false,
                      }}
                      variant='outlined'
                      required
                      fullWidth
                    />
                  </div>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Description'
                    id='description'
                    name='Description'
                    value={this.state.Description || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>settings</Icon>
                  </div>
                  <Autocomplete
                    className='mb-12'
                    title='Unit Category'
                    options={serviceUnitCategories}
                    menuItemComponent={value => {
                      return (
                        <MenuItem key={value.UnitCat} value={value.UnitCat}>
                          <div className='flex'>{`${value.Name}`}</div>
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.UnitCat || ''}
                    onSelect={option =>
                      this.setState({ UnitCat: option.UnitCat, UnitType: null })
                    }
                    // required
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  {useNewMultiUnitTypeSelect
                    ? getUnitTypeMultiSelectComponent(false)
                    : getUnitTypeAutocompleteComponent(false)}
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Department'
                    id='department'
                    name='Department'
                    value={this.state.Department || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    required
                    fullWidth
                  >
                    {this.state.BusinessUnit &&
                    this.state.BusinessUnit.length > 0 ? (
                      this.state.Division && this.state.Division.length > 0 ? (
                        departments && departments.length > 0 ? (
                          departments.map((value, index) =>
                            this.state.BusinessUnit == value.BusinessUnit &&
                            this.state.Division == value.Division ? (
                              <MenuItem key={index} value={value.Department}>
                                {`${value.Description}`}
                              </MenuItem>
                            ) : (
                              ''
                            ),
                          )
                        ) : (
                          ''
                        )
                      ) : (
                        <MenuItem value=''>Please Select a Division</MenuItem>
                      )
                    ) : (
                      <MenuItem value=''>
                        Please Select a Business Unit
                      </MenuItem>
                    )}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Associated Service'
                    id='associatedService'
                    name='AssociatedService'
                    value={this.state.AssociatedService || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    required
                    fullWidth
                  >
                    {this.state.BusinessUnit &&
                    this.state.BusinessUnit.length > 0 ? (
                      this.state.Division && this.state.Division.length > 0 ? (
                        services && services.length > 0 ? (
                          services.map((value, index) =>
                            this.state.BusinessUnit ==
                              value.DefaultBusinessUnit &&
                            this.state.Division == value.DefaultDivision &&
                            this.state.Department == value.DefaultDepartment ? (
                              <MenuItem key={index} value={value.Service}>
                                {`${value.Description}`}
                              </MenuItem>
                            ) : (
                              ''
                            ),
                          )
                        ) : (
                          ''
                        )
                      ) : (
                        <MenuItem value=''>Please Select a Division</MenuItem>
                      )
                    ) : (
                      <MenuItem value=''>
                        Please Select a Business Unit
                      </MenuItem>
                    )}
                  </TextField>
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'>
                    <Icon color='action'>calendar_today</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Frequency'
                    id='Frequency'
                    name='Frequency'
                    value={this.state.Frequency || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    required
                    fullWidth
                  >
                    <MenuItem value='*'>Per Agreement</MenuItem>
                    <MenuItem value='A'>Annual</MenuItem>
                    <MenuItem value='S'>Semi-Annual</MenuItem>
                    <MenuItem value='Q'>Quarterly</MenuItem>
                    <MenuItem value='B'>Bi-Monthly</MenuItem>
                    <MenuItem value='M'>Monthly</MenuItem>
                    <MenuItem value='W'>Weekly</MenuItem>
                    <MenuItem value='O'>Once</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  {
                    Frequency === 'A' ? (
                      <React.Fragment>
                        <DatePicker
                          inputVariant='outlined'
                          className={classes.formControl}
                          openTo='month'
                          label='Due Around'
                          format='MMM DD'
                          id='dateMedian'
                          name='DateMedian'
                          value={
                            this.state.DateMedian ||
                            moment().format('MM/15/YYYY')
                          }
                          InputLabelProps={{ shrink: true }}
                          ToolbarComponent={() => ''}
                          onChange={this.handleDateMedianChange}
                          views={['month', 'date']}
                          fullWidth
                          required
                          autoOk={true}
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classes.formControl}
                          label='Schedule Within'
                          id='leadTime'
                          name='LeadTime'
                          value={this.state.LeadTime || ''}
                          onChange={this.handleChange}
                          InputProps={{
                            endAdornment: 'Days',
                            type: 'number',
                          }}
                          variant='outlined'
                          required
                          fullWidth
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TextField
                          className={classes.formControl}
                          label='Revenue Type'
                          id='revenueType'
                          name='RevenueType'
                          value={this.state.RevenueType || ''}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.RevenueType ? true : false,
                          }}
                          variant='outlined'
                          select
                          required
                          fullWidth
                        >
                          <MenuItem value='T'>Task</MenuItem>
                          <MenuItem value='U'>Unit</MenuItem>
                        </TextField>
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classes.formControl}
                          label={`${
                            this.state.RevenueType === 'T' ? 'Task' : 'Unit'
                          } Price`}
                          id='revenue'
                          name='Revenue'
                          value={this.state.Revenue || ''}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.Revenue !== null,
                          }}
                          InputProps={{
                            startAdornment: this.state.Revenue !== null && (
                              <InputAdornment position='start'>
                                $
                              </InputAdornment>
                            ),
                            type: 'number',
                          }}
                          variant='outlined'
                          fullWidth
                        />
                        {this.state.RevenueType !== 'T' && (
                          <React.Fragment>
                            <div className='hidden sm:block min-w-12 pt-20'></div>
                            <TextField
                              className={classes.formControl}
                              label={`Price per Additional Unit`}
                              id='additional'
                              name='Additional'
                              value={this.state.Additional || ''}
                              onChange={this.handleChange}
                              InputLabelProps={{
                                shrink: this.state.Additional !== null,
                              }}
                              InputProps={{
                                startAdornment: this.state.Additional !==
                                  null && (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                                type: 'number',
                              }}
                              variant='outlined'
                              fullWidth
                            />
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )
                    // :
                    // Frequency !== "O" &&
                    // <TextField
                    //     className={classes.formControl}
                    //     label="Due Around"
                    //     id="bme"
                    //     name="BME"
                    //     value={this.state.BME}
                    //     onChange={this.handleChange}
                    //     InputLabelProps={{ shrink: this.state.BME ? true : false }}
                    //     variant="outlined"
                    //     select
                    //     required
                    //     fullWidth
                    // >
                    //     <MenuItem value="B">
                    //         Beginning of {frequencies[Frequency]}
                    //     </MenuItem>
                    //     <MenuItem value="M">
                    //         Middle of {frequencies[Frequency]}
                    //     </MenuItem>
                    //     <MenuItem value="E">
                    //         End of {frequencies[Frequency]}
                    //     </MenuItem>
                    // </TextField>
                  }
                </div>
                {Frequency === 'A' && (
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'>
                      <Icon color='action'>monetization_on</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Revenue Type'
                      id='revenueType'
                      name='RevenueType'
                      value={this.state.RevenueType || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      select
                      required
                      fullWidth
                    >
                      <MenuItem value='T'>Task</MenuItem>
                      <MenuItem value='U'>Unit</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label={`${
                        this.state.RevenueType === 'T' ? 'Task' : 'Unit'
                      } Price`}
                      id='revenue'
                      name='Revenue'
                      value={this.state.Revenue || ''}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: this.state.Revenue !== null && (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        type: 'number',
                      }}
                      variant='outlined'
                      fullWidth
                    />
                    {this.state.RevenueType !== 'T' && (
                      <React.Fragment>
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classes.formControl}
                          label={`Additional Unit Price`}
                          id='additional'
                          name='Additional'
                          value={this.state.Additional || ''}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.Additional !== null,
                          }}
                          InputProps={{
                            startAdornment: this.state.Additional !== null && (
                              <InputAdornment position='start'>
                                $
                              </InputAdornment>
                            ),
                            type: 'number',
                          }}
                          variant='outlined'
                          fullWidth
                        />
                      </React.Fragment>
                    )}
                  </div>
                )}

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div className='flex'>
                  <div className='w-full'>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.OptionalYN === 'Y'}
                          name='OptionalYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      className='float-right'
                      label='Optional'
                    />
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 2 && agreementTypeTaskDialog.type == 'edit' && (
              <TabContainer>
                {accessLevel && accessLevel.AccessLevel !== 'R' && (
                  <React.Fragment>
                    <Typography
                      variant='h6'
                      style={{ alignItems: 'center' }}
                      className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                    >
                      <Icon color='primary' className='text-32 mr-12'>
                        playlist_add
                      </Icon>
                      {`${
                        !this.state.taskOverride.ID ? 'Add' : 'Update'
                      } Checklist Item`}
                    </Typography>
                    <div className='sm:flex'>
                      <TextField
                        className={classes.formControl}
                        label='Title'
                        id='clTitle'
                        name='clItem.Title'
                        value={this.state.clItem.Title}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.clItem.Title !== null,
                        }}
                        variant='outlined'
                        fullWidth
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Description'
                        id='clDesc'
                        name='clItem.Description'
                        value={this.state.clItem.Description}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.clItem.Description !== null,
                        }}
                        variant='outlined'
                        fullWidth
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Type'
                        select
                        id='clType'
                        name='clItem.Type'
                        value={this.state.clItem.Type}
                        onChange={this.handleChange}
                        variant='outlined'
                        InputLabelProps={{
                          shrink: this.state.clItem.Type ? true : false,
                        }}
                        fullWidth
                      >
                        <MenuItem value='CB'>Checkbox</MenuItem>
                        <MenuItem value='SEL'>Drop-Down List</MenuItem>
                        <MenuItem value='IMG'>Image</MenuItem>
                        <MenuItem value='BNG'>
                          Indeterminate (Bad / Neutral / Good)
                        </MenuItem>
                        <MenuItem value='NNY'>
                          Indeterminate (No / N/A / Yes)
                        </MenuItem>
                        <MenuItem value='IN'>Input</MenuItem>
                        <MenuItem value='NTS'>Notes</MenuItem>
                        <MenuItem value='N'>Numeric</MenuItem>
                        <MenuItem value='R'>Rating</MenuItem>
                        <MenuItem value='SIG'>Signature Box</MenuItem>
                        <MenuItem value='DIV'>Spacer</MenuItem>
                        <MenuItem value='TTL'>Title</MenuItem>
                        <MenuItem value='SUB'>Sub-Title</MenuItem>
                      </TextField>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {!this.state.taskOverride.ID ? (
                        <Button
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            const {
                              Co,
                              BusinessUnit,
                              Division,
                              AgreementType,
                              TaskType,
                              taskOverride,
                            } = this.state
                            addOverride({
                              ...taskOverride,
                              Co,
                              BusinessUnit,
                              Division,
                              AgreementType,
                              TaskType,
                            })
                          }}
                          disabled={!this.canOverrideBeSubmitted()}
                        >
                          Add
                        </Button>
                      ) : (
                        <div className='w-full flex'>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.updateOverride,
                              'w-full',
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              updateOverride({ ...this.state.taskOverride })
                            }}
                            disabled={!this.canOverrideBeSubmitted()}
                          >
                            Update
                          </Button>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.deleteOverride,
                            )}
                            variant='contained'
                            onClick={() => {
                              removeOverride({ ...this.state.taskOverride })
                            }}
                            disabled={!this.canOverrideBeSubmitted()}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </TabContainer>
            )}
            {value === 1 && agreementTypeTaskDialog.type == 'edit' && (
              <TabContainer>
                {accessLevel && accessLevel.AccessLevel !== 'R' && (
                  <React.Fragment>
                    <Typography
                      variant='h6'
                      style={{ alignItems: 'center' }}
                      className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                    >
                      <Icon color='primary' className='text-32 mr-12'>
                        playlist_add
                      </Icon>
                      {`${
                        !this.state.taskOverride.ID ? 'Add' : 'Update'
                      } Task Override`}
                    </Typography>
                    <div className='sm:flex'>
                      {useNewMultiUnitTypeSelect
                        ? getUnitTypeMultiSelectComponent(true)
                        : getUnitTypeAutocompleteComponent(true)}

                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Task Price'
                        id='orrevenue'
                        name='taskOverride.Revenue'
                        value={this.state.taskOverride.Revenue}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.taskOverride.Revenue !== null,
                        }}
                        InputProps={{
                          startAdornment: this.state.taskOverride.Revenue !==
                            null && (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                          type: 'number',
                        }}
                        variant='outlined'
                        fullWidth
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label="Add'l Unit Price"
                        id='oradditional'
                        name='taskOverride.Additional'
                        value={this.state.taskOverride.Additional}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.taskOverride.Additional !== null,
                        }}
                        InputProps={{
                          startAdornment: this.state.taskOverride.Additional !==
                            null && (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                          type: 'number',
                        }}
                        variant='outlined'
                        fullWidth
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {!this.state.taskOverride.ID ? (
                        <Button
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            const {
                              Co,
                              BusinessUnit,
                              Division,
                              AgreementType,
                              TaskType,
                              taskOverride,
                            } = this.state
                            addOverride({
                              ...taskOverride,
                              Co,
                              BusinessUnit,
                              Division,
                              AgreementType,
                              TaskType,
                            })
                          }}
                          disabled={!this.canOverrideBeSubmitted()}
                        >
                          Add
                        </Button>
                      ) : (
                        <div className='w-full flex'>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.updateOverride,
                              'w-full',
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              updateOverride({ ...this.state.taskOverride })
                            }}
                            disabled={!this.canOverrideBeSubmitted()}
                          >
                            Update
                          </Button>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.deleteOverride,
                            )}
                            variant='contained'
                            onClick={() => {
                              removeOverride({ ...this.state.taskOverride })
                            }}
                            disabled={!this.canOverrideBeSubmitted()}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
                <AgreementTypeTaskOverridesList
                  onSelected={this.setOverride}
                  task={{ ...this.state }}
                  agreementTypeTaskOverrideData={[...this.state.Data.Overrides]}
                />
              </TabContainer>
            )}

            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {['new', 'customer'].indexOf(agreementTypeTaskDialog.type) >
                -1 ? (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      addAgreementTypeTask(rec)
                      this.closeComposeDialog()
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      updateAgreementTypeTask(rec)
                      this.closeComposeDialog()
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Save
                  </Button>
                )}
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}
AgreementTypeTasksDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditAgreementTypeTaskDialog:
        Actions.closeEditAgreementTypeTaskDialog,
      closeNewAgreementTypeTaskDialog: Actions.closeNewAgreementTypeTaskDialog,
      addAgreementTypeTask: Actions.addAgreementTypeTask,
      updateAgreementTypeTask: Actions.updateAgreementTypeTask,
      removeAgreementTypeTask: Actions.removeAgreementTypeTask,
      addOverride: Actions.addAgreementTypeTaskOverride,
      updateOverride: Actions.updateAgreementTypeTaskOverride,
      removeOverride: Actions.removeAgreementTypeTaskOverride,
    },
    dispatch,
  )
}

function mapStateToProps({ agreementTypeTasksApp, spReducers }) {
  return {
    agreementTypeTaskDialog:
      agreementTypeTasksApp.agreementTypeTasks.agreementTypeTaskDialog,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    services: spReducers.services,
    Co: spReducers.companies.Co,
    rateTemplates: spReducers.rateTemplates,
    agreementTypes: spReducers.agreementTypes,
    serviceUnitCategories: spReducers.serviceUnitCategories,
    serviceUnitTypes: spReducers.serviceUnitTypes,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AgreementTypeTasksDialog),
)
