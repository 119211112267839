import { TextField } from '@material-ui/core';
import { formatPhone } from 'main/functions';
import React, { Component } from 'react';

class PhoneInput extends Component {

    state = {
        phone: this.props.value,
        noFormat: ''
    }

    handlePhoneChange = (raw) => {

        const data = formatPhone(raw);
        this.setState({ phone: data.formatted, noFormat: data.stripped }, () => this.props.onChange(data.stripped));
    };

    componentDidMount() {
        this.handlePhoneChange(this.props.value);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.handlePhoneChange(this.props.value);
        }
    }

    render() {
        return (
            <TextField
                className={this.props.className}
                label={this.props.label}
                id={this.props.id}
                name={this.props.name}
                value={this.state.phone}
                onChange={(e) => this.handlePhoneChange(e.target.value)}
                onBlur={this.props.onBlur}
                InputProps={this.props.InputProps}
                inputProps={this.props.inputProps}
                InputLabelProps={this.props.InputLabelProps}
                variant={this.props.variant}
                required={this.props.required}
                fullWidth={this.props.fullWidth}
                disabled={this.props.disabled}
                margin={this.props.margin}
            />
        )
    }
}

export default PhoneInput;