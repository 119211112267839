import React, { Component } from 'react';
import {
    TextField, Button, CircularProgress, Dialog, DialogActions, DialogContent, Icon, IconButton, MenuItem, Typography, Toolbar, AppBar, Avatar, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';
import axios from 'axios';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newDepartmentState = {
    Co: null,
    BusinessUnit: '',
    Department: '',
    Division: '',
    Description: '',
    ActiveYN: 'Y',
    Notes: '',
    DivisionOverride: null,
    VPCompany: null,
    VPPRDept: null,
    QBOCompany: null,
    QBOClass: null,
    Data: {

    },
    vpPRDepts: [],
    searchDescription: null,
    isSearching: false
};

class DepartmentDialog extends Component {
    state = { ...newDepartmentState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { vpCompany } = this.props;
        /**
         * After Dialog Open
         */
        const initialState = _.cloneDeepWith(newDepartmentState);
        initialState.Co = this.props.Co;
        if (!prevProps.departmentDialog.props.open && this.props.departmentDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.departmentDialog.type === 'edit' &&
                this.props.departmentDialog.data &&
                !_.isEqual(this.props.departmentDialog.data, prevState)) {
                this.setState({ ...initialState, ...this.props.departmentDialog.data }, () => {
                    if (vpCompany && this.state.VPPRDept) {
                        this.handleDeptSearch();
                    }
                });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.departmentDialog.type === 'new' &&
                !_.isEqual(newDepartmentState, prevState)) {
                this.setState({ ...initialState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.departmentDialog.type === 'edit' ? this.props.closeEditDepartmentDialog() : this.props.closeNewDepartmentDialog();
    };

    canBeSubmitted() {
        const { Department, Description } = this.state;
        return (
            Department.length > 0 && Description.length > 0
        );
    }

    handleDeptSearch = _.debounce(() => {
        const { vpCompany } = this.props;
        const { HQCo1 } = (vpCompany || {});
        const { searchDescription, VPPRDept } = this.state;
        if (VPPRDept || (searchDescription && searchDescription.length > 0)) {
            const inputValue = (searchDescription || VPPRDept).trim().toLowerCase();
            this.setState({ isSearching: true }, () => {
                axios.get(`${window["apiLocation"]}/api/VPSettings/Departments?Co=${HQCo1}&search=${encodeURIComponent(inputValue)}`).then((response) => {
                    this.setState({ vpPRDepts: response.data, isSearching: false });
                }).catch(() => this.setState({ isSearching: false, vpPRDepts: [] }));
            })
        } else {
            this.setState({ vpPRDepts: [] });
        }
    }, 750);

    render() {
        const { classes, securables, departmentDialog, addDepartment, updateDepartment, removeDepartment, businessUnits, divisions, vpCompany, departments, qboCompany, qboClasses } = this.props;
        const { searchDescription, vpPRDepts, isSearching } = this.state;
        const accessLevel = _.find(securables, { Securable: "departments" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...departmentDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {departmentDialog.type === 'new' ? 'New Department' : 'Edit Department'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <Autocomplete
                                    className={classes.formControl}
                                    title="Business Unit"
                                    options={businessUnits}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.BusinessUnit || ''}
                                    onSelect={(option) => this.setState({ ...this.state, BusinessUnit: option.BusinessUnit })}
                                    required
                                    disabled={(departmentDialog.type === 'new' ? false : true)}
                                />
                                {/* <TextField
                                    className={classes.formControl}
                                    label="Business Unit"

                                    select
                                    id="businessUnit"
                                    name="BusinessUnit"
                                    value={this.state.BusinessUnit}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(departmentDialog.type === 'new' ? false : true)}
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                        <MenuItem value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField> */}
                            </div>

                            <div className="flex">
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Division"
                                    options={divisions ? _.filter(divisions, { BusinessUnit: this.state.BusinessUnit }) : []}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.Division}>
                                            {value.Description}
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.Division || ''}
                                    onSelect={(option) => {
                                        this.setState({ ...this.state, Division: option.Division })
                                    }}
                                    required
                                    disabled={(departmentDialog.type === 'new' ? false : true)}
                                />

                                {/* <TextField
                                    className={classes.formControl}
                                    label="Division"
                                    id="division"
                                    select
                                    name="Division"
                                    value={this.state.Division}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(departmentDialog.type === 'new' ? false : true)}
                                >
                                    {(this.state.BusinessUnit.length > 0 ?
                                        (divisions && divisions.length > 0 ? divisions.map((value) => (
                                            (this.state.BusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Division}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Business Unit
                                </MenuItem>
                                    )
                                    }
                                </TextField> */}
                            </div>

                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Department"
                                    id="department"
                                    name="Department"
                                    value={this.state.Department || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(departmentDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Division Override"
                                    id="divisionOverride"
                                    select
                                    name="DivisionOverride"
                                    value={this.state.DivisionOverride || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    {(this.state.BusinessUnit.length > 0 ?
                                        (divisions && divisions.length > 0 ? divisions.map((value) => (
                                            (this.state.BusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Division}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Business Unit
                                        </MenuItem>
                                    )
                                    }
                                </TextField>
                            </div>

                            {vpCompany &&
                                <div className="flex relative">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="VP PR Department"
                                        options={vpPRDepts || []}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.PRDept}>
                                                {`${value.PRDept} - ${value.Description}`}
                                            </MenuItem>
                                        }
                                        debounce={750}
                                        onChange={(text) => this.setState({ ...this.state, searchDescription: text }, this.handleDeptSearch)}
                                        portal={true}
                                        value={this.state.VPPRDept || ''}
                                        onSelect={(option) => this.setState({ ...this.state, VPCompany: option.PRCo, VPPRDept: option.PRDept })}
                                        required
                                    />
                                    {isSearching &&
                                        <CircularProgress color="primary" className="absolute pin-r pin-t m-auto" style={{ width: 20, height: 20, marginRight: 9, marginTop: 18 }} />
                                    }
                                </div>
                            }
                            {qboCompany &&
                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="QBO Class"
                                        options={qboClasses}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Id}>
                                                {`${value.Name}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.QBOClass || ''}
                                        onSelect={(option) => { if (departmentDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOClass: option.Id }) } else { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOClass: option.Id }) } }}
                                    // required
                                    />
                                </div>
                            }

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {departmentDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addDepartment(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateDepartment(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditDepartmentDialog: Actions.closeEditDepartmentDialog,
        closeNewDepartmentDialog: Actions.closeNewDepartmentDialog,
        addDepartment: Actions.addDepartment,
        updateDepartment: Actions.updateDepartment,
        removeDepartment: Actions.removeDepartment
    }, dispatch);
}

function mapStateToProps({ departmentsApp, spReducers }) {
    return {
        departmentDialog: departmentsApp.departments.departmentDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        vpCompany: spReducers.vpSettings.vpCompany,
        departments: spReducers.vpSettings.departments,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboClasses: spReducers.qboSettings.classes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(DepartmentDialog));
