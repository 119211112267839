import * as Actions from 'store/actions/support';

const initialState = {
    error: null,
    img: null,
    requests: [],
    filter: 'S',
    keepIntact: false,
    testAppErrors: false
}
const support = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_ERROR:
            {
                const { error, img, keepIntact } = action;
                return {
                    ...state,
                    error,
                    img,
                    keepIntact
                };
            }
        case Actions.SET_REQUESTS:
            {
                const { requests, filter } = action;
                return {
                    ...state,
                    requests,
                    filter: filter || state.filter
                }

            }
        case Actions.KEEP_INTACT:
            {
                const { keepIntact } = action;
                return {
                    ...state,
                    keepIntact
                }
            }
        case Actions.TEST_APP_ERRORS:
            {
                return {
                    ...state,
                    testAppErrors: true
                }
            }
    }

    return state;
};

export default support;