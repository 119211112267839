import axios from 'axios';
import { getUserData } from 'main/content/apps/invoices/store/actions/user.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';
import moment from 'moment';
import _ from 'lodash';

export const GET_INVOICES = '[INVOICES APP] GET INVOICES';
export const FETCH_INVOICES = '[INVOICES APP] FETCH INVOICES';
export const SET_SEARCH_TEXT = '[INVOICES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_INVOICES = '[INVOICES APP] TOGGLE IN SELECTED INVOICES';
export const SELECT_ALL_INVOICES = '[INVOICES APP] SELECT ALL INVOICES';
export const DESELECT_ALL_INVOICES = '[INVOICES APP] DESELECT ALL INVOICES';
export const OPEN_NEW_INVOICE_DIALOG = '[INVOICES APP] OPEN NEW INVOICE DIALOG';
export const CLOSE_NEW_INVOICE_DIALOG = '[INVOICES APP] CLOSE NEW INVOICE DIALOG';
export const OPEN_EDIT_INVOICE_DIALOG = '[INVOICES APP] OPEN EDIT INVOICE DIALOG';
export const UPDATE_EDIT_INVOICE_DIALOG = '[INVOICES APP] UPDATE EDIT INVOICE DIALOG';
export const CLOSE_EDIT_INVOICE_DIALOG = '[INVOICES APP] CLOSE EDIT INVOICE DIALOG';
export const ADD_INVOICE = '[INVOICES APP] ADD INVOICE';
export const UPDATE_INVOICE = '[INVOICES APP] UPDATE INVOICE';
export const REMOVE_INVOICE = '[INVOICES APP] REMOVE INVOICE';
export const REMOVE_INVOICES = '[INVOICES APP] REMOVE INVOICES';
export const TOGGLE_STARRED_INVOICE = '[INVOICES APP] TOGGLE STARRED INVOICE';
export const TOGGLE_STARRED_INVOICES = '[INVOICES APP] TOGGLE STARRED INVOICES';
export const SET_INVOICES_STARRED = '[INVOICES APP] SET INVOICES STARRED ';

export function getInvoices(routeParams) {
    const request = axios.get('/api/invoices-app/invoices', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_INVOICES,
                payload: response.data,
                routeParams
            })
        );
}

export function fetchInvoices(Co, values) {
    window["warn"]('Fetching Invoices: ', values);
    const request = axios.put(`${window["apiLocation"]}/api/Invoice/Bulk?Co=${Co}`, values);

    return (dispatch) =>
        request.then((response) => {
            dispatch({
                type: FETCH_INVOICES,
                payload: response.data
            })
            return response;
        }
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedInvoices(invoiceId) {
    return {
        type: TOGGLE_IN_SELECTED_INVOICES,
        invoiceId
    }
}


export function selectAllInvoices(invoices) {
    return {
        type: SELECT_ALL_INVOICES,
        selectedInvoiceIds: invoices
    }
}

export function deSelectAllInvoices() {
    return {
        type: DESELECT_ALL_INVOICES,
        selectedInvoiceIds: []
    }
}

export function updateInvoices(invoices) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Invoice/Batch`,
            invoices
        );

        return request.then((response) => {
            var ErrMsg = '';
            response.data.map(value => {
                if (value.Data.ErrMsg && value.Data.ErrMsg.length > 0) {
                    ErrMsg += `${value.Data.ErrMsg} | `;
                }
            });
            if (ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Selected invoices have been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        });
    };
}


export function openNewInvoiceDialog(data, trip) {

    return (dispatch, getState) => {
        const { taxCodes, businessUnits } = getState().spReducers;
        window["warn"](data, trip);
        dispatch(buildNewInvoice(data, taxCodes, businessUnits, trip));
    }
}

export function buildNewInvoice(data, taxCodes, businessUnits, trip) {
    return (dispatch, getState) => {
        const state = getState();
        const { costTypes } = state.spReducers;
        const { Customer, Site, Invoices, Scopes, InvoiceNumber, WorkOrder, Scope, Assignments } = _.cloneDeepWith(data);
        window["warn"](Assignments);
        const {
            Co,
            PayTerms,
            FirstName,
            LastName,
            Name,
            Email,
            Phone,
            BillContact,
            BillAddress1,
            BillAddress2,
            BillCity,
            BillState,
            BillZip,
            BillCountry,
            BillPhone,
            BillEmail,
            PaymentsCustomer
        } = Customer;
        const { Taxability, Contacts, PaymentMethods } = Customer.Data;
        const {
            Address1,
            Address2,
            City,
            State,
            Zip
        } = Site;
        const Contact = Contacts ? _.find(Contacts, { BillingYN: 'Y' }) : null;
        const InvoiceDetails = [];
        let InvoiceDetail = 0;
        for (var i = 0; i < Scopes.length; i++) {
            const scope = { ...Scopes[i] };
            if (scope.ReadyToBillYN === 'Y') {
                if (scope.PriceMethod === 'F' && !scope.Invoice) {
                    const bu = _.find(businessUnits, (u) => { return u.Co === scope.Co && u.BusinessUnit === scope.BusinessUnit });
                    const tc = _.find(taxCodes, (c) => { return c.Co === scope.Co && c.TaxCode === (scope.TaxCode ? scope.TaxCode : Site.TaxCode ? Site.TaxCode : Customer.TaxCode ? Customer.TaxCode : bu.TaxCode) });
                    const TaxRate = (!tc ? 0.00 : new Date() >= new Date(tc.EffectiveDate) ? tc.NewRate : tc.OldRate) / 100;
                    window["warn"](bu, tc, TaxRate);
                    let TaxAmount = (scope.TaxableYN !== "N" ? scope.Price * TaxRate : 0.00);
                    if (scope.TaxableYN !== 'N' && scope.Data && scope.Data.Splits && scope.Data.Splits.length > 0) {
                        TaxAmount = 0;
                        let Amount = 0;
                        scope.Data.Splits.map((split) => {
                            const ct = _.find(costTypes, { CostType: split.CostType });
                            const type = ct ? ct.CostTypeCategory : 'O';
                            const nonTax = _.find((Taxability || []), { CostTypeCategory: type, TaxableYN: 'N' });
                            Amount += scope.Price * (split.Split / 100);
                            TaxAmount += (nonTax || split.TaxableYN === 'N' ? 0 : Amount * TaxRate)
                        });
                        const rem = scope.Price - Amount;
                        if (Amount > 0) {
                            TaxAmount += rem * TaxRate;
                        }
                    }
                    InvoiceDetail += 1;
                    InvoiceDetails.push({
                        Co: scope.Co,
                        Invoice: null,
                        InvoiceDetail,
                        WorkOrder,
                        Scope: scope.Scope,
                        Data: {
                            Scope: scope,
                            InvoiceLines: [{
                                Co: scope.Co,
                                Invoice: null,
                                InvoiceDetail,
                                InvoiceDetailLine: 1,
                                Amount: scope.Price,
                                TaxAmount
                            }]
                        }
                    });
                }
                scope.Data.Usage.map((usage) => {
                    // const type = usage.CostType ? 
                    const usageTypes = ['O', 'L', 'M', 'M', 'E', 'O', 'O', 'O', 'O'];
                    const ct = _.find(costTypes, { CostType: usage.CostType });
                    const type = ct ? ct.CostTypeCategory : usageTypes[usage.Type] || 'O';
                    const nonTax = _.find((Taxability || []), { CostTypeCategory: type, TaxableYN: 'N' });
                    if (usage.Status === 'NEW') {
                        InvoiceDetail += 1;
                        InvoiceDetails.push({
                            Co: scope.Co,
                            Invoice: null,
                            InvoiceDetail,
                            WorkOrder,
                            UsageLine: usage.Line,
                            Data: {
                                Usage: usage,
                                InvoiceLines: [{
                                    Co: scope.Co,
                                    Invoice: null,
                                    InvoiceDetail,
                                    InvoiceDetailLine: 1,
                                    Amount: usage.PriceTotal,
                                    TaxAmount: nonTax ? 0 : (scope.TaxableYN !== "N" && usage.TaxableYN !== "N" ? usage.TaxTotal : 0)
                                }]
                            }
                        });
                    }
                })
            }
        }
        const scope = Scopes[0];
        delete scope.BillingData;
        // delete scope.Data;
        const invoice = {
            Co,
            InvoiceNumber,
            InvoiceDate: moment(new Date().toLocaleDateString('en-US')).toDate(),
            Customer: Customer.Customer,
            Description: `Invoice for Work Order #${WorkOrder}`,
            PayTerms,
            DueDate: new Date(),
            WorkOrder,
            Scope,
            Site: Site.Site,
            Location: `${Address1}${Address2 && Address2.length > 0 ? ` ${Address2}` : ''} ${City}, ${State} ${Zip}`,
            FirstName,
            LastName,
            BillName: Name && Name.trim().length > 0 ? Name : `${FirstName} ${LastName}`,
            BillContact: Contact ? Contact.Data.Contact.Name : null,
            BillAddress1,
            BillAddress2,
            BillCity,
            BillState,
            BillZip,
            BillCountry,
            BillPhone: BillPhone || Phone,
            BillEmail: BillEmail || Email,
            PaymentsCustomer,
            Data: {
                Co,
                InvoiceNumber,
                InvoiceDate: moment(new Date().toLocaleDateString('en-US')).toDate(),
                Customer: Customer.Customer,
                Description: `Invoice for Work Order #${WorkOrder}`,
                PayTerms,
                DueDate: new Date(),
                WorkOrder,
                Scope,
                BillName: Name && Name.trim().length > 0 ? Name : `${FirstName} ${LastName}`,
                BillContact: Contact ? Contact.Data.Contact.Name : null,
                BillAddress1,
                BillAddress2,
                BillCity,
                BillState,
                BillZip,
                BillCountry,
                BillPhone: BillPhone || Phone,
                BillEmail: BillEmail || Email,
                PaymentsCustomer,
                Data: {
                    Customer,
                    Contacts,
                    Contact,
                    Assignments,
                    Scopes: Scope ? [{ ...scope }] : _.filter([...Scopes], { ReadyToBillYN: 'Y' }),
                    Usage: [],
                    Payments: [],
                    PaymentDetail: [],
                    PaymentMethods,
                    InvoiceDetails,
                    ErrMsg: null,
                }
            }
        }
        const lines = _.flatten(_.map(invoice.Data.Data.InvoiceDetails, _.property('Data.InvoiceLines')));
        invoice.BilledTotal = _.sumBy(lines, (o) => { return Number(o.Amount) });
        invoice.TaxTotal = _.sumBy(lines, (o) => { return Number(o.TaxAmount) });
        window["warn"](invoice);
        dispatch({
            type: OPEN_NEW_INVOICE_DIALOG,
            data: invoice,
            trip
        });
    }
}

export function buildNewAgreementInvoice(data) {
    return (dispatch, getState) => {
        const state = getState();
        const { spReducers } = state;
        const { taxCodes, businessUnits } = spReducers;
        const { Customer, Site, Agreement, Billing } = data;
        const { Cycle, Seq, Data, Amount, DueDate } = Billing;
        const { Co, BusinessUnit, Division } = Agreement;
        const InvoiceNumber = `${Agreement.Agreement}-${Cycle}-${Seq}`
        const {
            PayTerms,
            FirstName,
            LastName,
            Name,
            Email,
            Phone,
            BillContact,
            BillAddress1,
            BillAddress2,
            BillCity,
            BillState,
            BillZip,
            BillCountry,
            BillPhone,
            BillEmail,
        } = Customer;
        const {
            Address1,
            Address2,
            City,
            State,
            Zip
        } = Site;
        const { Contacts } = Customer.Data;
        const Contact = Contacts ? _.find(Contacts, { BillingYN: 'Y' }) : null;
        const InvoiceDetails = [];
        const bu = _.find(businessUnits, (u) => { return u.Co === Co && u.BusinessUnit === BusinessUnit });
        const tc = _.find(taxCodes, (c) => { return c.Co === Co && c.TaxCode === (Site.TaxCode ? Site.TaxCode : Customer.TaxCode ? Customer.TaxCode : bu.TaxCode) });
        const TaxRate = (!tc ? 0.00 : new Date() >= new Date(tc.EffectiveDate) ? tc.NewRate : tc.OldRate) / 100;
        window["warn"](bu, tc, TaxRate);
        InvoiceDetails.push({
            Co: data.Co,
            Invoice: null,
            InvoiceDetail: 1,
            Agreement: Agreement.Agreement,
            AgreementCycle: Cycle,
            AgreementBilling: Seq,
            AgreementCustomer: Customer.Customer,
            AgreementSite: Site.Site,
            Data: {
                Agreement,
                InvoiceLines: [{
                    Co,
                    Invoice: null,
                    InvoiceDetail: 1,
                    InvoiceDetailLine: 1,
                    Description: `Agreement Billing #${Seq} of ${_.filter(Agreement.Data.BillingSchedule, { Cycle }).length}`,
                    Amount,
                    TaxAmount: (Amount * TaxRate)
                }]
            }
        });
        const invoice = {
            Co,
            InvoiceNumber,
            InvoiceDate: DueDate,
            Customer: Customer.Customer,
            Description: `Agreement Billing #${Seq} of ${_.filter(Agreement.Data.BillingSchedule, { Cycle }).length}`,
            PayTerms,
            DueDate,
            Site: Site.Site,
            Location: `${Address1} ${City}, ${State} ${Zip}`,
            FirstName,
            LastName,
            BillName: Name && Name.trim().length > 0 ? Name : `${FirstName} ${LastName}`,
            BillContact: Contact ? Contact.Data.Contact.Name : null,
            BillAddress1,
            BillAddress2,
            BillCity,
            BillState,
            BillZip,
            BillCountry,
            BillPhone: BillPhone || Phone,
            BillEmail: BillEmail || Email,
            Data: {
                Co,
                InvoiceNumber,
                InvoiceDate: new Date(),
                Customer: Customer.Customer,
                Description: `Agreement Billing #${Seq} of ${_.filter(Agreement.Data.BillingSchedule, { Cycle }).length}`,
                PayTerms,
                DueDate: new Date(),
                BillName: Name && Name.trim().length > 0 ? Name : `${FirstName} ${LastName}`,
                BillContact: Contact ? Contact.Data.Contact.Name : null,
                BillAddress1,
                BillAddress2,
                BillCity,
                BillState,
                BillZip,
                BillCountry,
                BillPhone: BillPhone || Phone,
                BillEmail: BillEmail || Email,
                Data: {
                    Customer,
                    Contacts,
                    Contact,
                    Usage: [],
                    Payments: [],
                    PaymentDetail: [],
                    Scopes: [],
                    InvoiceDetails,
                    ErrMsg: null,
                }
            }
        }
        const lines = _.flatten(_.map(invoice.Data.Data.InvoiceDetails, _.property('Data.InvoiceLines')));
        invoice.BilledTotal = _.sumBy(lines, (o) => { return Number(o.Amount) });
        invoice.TaxTotal = _.sumBy(lines, (o) => { return Number(o.TaxAmount) });
        window["warn"](invoice);
        dispatch({
            type: OPEN_NEW_INVOICE_DIALOG,
            data: invoice
        });
    }
}

export function closeNewInvoiceDialog() {
    return {
        type: CLOSE_NEW_INVOICE_DIALOG
    }
}

export function openPreloadWorkInvoiceDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_INVOICE_DIALOG,
            data: data,
            readOnly: true
        })
    }
}

export function openEditInvoiceDialog(data, readOnly) {
    {
        return (dispatch, getState) => {

            const url = data.Invoice ? `${window["apiLocation"]}/api/Invoice?Co=${data.Co}&Invoice=${data.Invoice}` : `${window["apiLocation"]}/api/Invoice?Co=${data.Co}&InvNum=${window.encodeURIComponent(data.InvoiceNumber)}`;

            const request = axios.get(url);

            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: OPEN_EDIT_INVOICE_DIALOG,
                        data: response.data,
                        readOnly
                    })
                ])
            ).catch((e) => {
                dispatch(showMessage({
                    message: `Unable to load requested Invoice.`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            });
        };
    }
}

export function refreshEditInvoiceDialog(data, readOnly) {
    {
        return (dispatch, getState) => {

            const url = data.Invoice ? `${window["apiLocation"]}/api/Invoice?Co=${data.Co}&Invoice=${data.Invoice}` : `${window["apiLocation"]}/api/Invoice?Co=${data.Co}&InvNum=${window.encodeURIComponent(data.InvoiceNumber)}`;

            const request = axios.get(url);

            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: UPDATE_EDIT_INVOICE_DIALOG,
                        data: response.data,
                        readOnly
                    })
                ])
            ).catch((e) => {
                dispatch(showMessage({
                    message: `Unable to load requested Invoice.`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            });
        };
    }
}

export function updateEditInvoiceDialog(data, partial) {
    return {
        type: UPDATE_EDIT_INVOICE_DIALOG,
        data,
        partial
    }
}

export function closeEditInvoiceDialog() {
    return {
        type: CLOSE_EDIT_INVOICE_DIALOG
    }
}


export function waiveAgreementInvoice(waivedInvoice) {
    return (dispatch, getState) => {

        const invoice = _.cloneDeepWith(waivedInvoice);
        const { Co, Customer, Site, Agreement, Cycle, Seq, Revision } = invoice;
        const url = `${window["apiLocation"]}/api/AgreementBilling?Co=${Co}&Customer=${Customer}&Site=${encodeURIComponent(Site)}&Agreement=${encodeURIComponent(Agreement)}&Cycle=${Cycle}&Revision=${Revision}&Seq=${Seq}`;
        const request = axios.put(url,
            invoice
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Agreement Billing has been successfully waived.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }
        )/*.catch((error) => {
            console.warn('Error adding invoice: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...waivedInvoice, OfflineID: guid() };
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'invoices')),
                ]).then(() => {
                    dispatch({
                        type: ADD_INVOICE,
                        data: { ...offlineData, Invoice: -1 }
                    })
                })
            }
        });*/
    };
}


export function addAgreementInvoice(newInvoice) {
    return (dispatch, getState) => {

        const invoice = _.cloneDeepWith(newInvoice);
        delete invoice.Data.Data;
        const url = `${window["apiLocation"]}/api/AgreementBilling`;
        const request = axios.post(url,
            invoice
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(openEditInvoiceDialog(response.data.Data.Invoice))
                dispatch(showMessage({
                    message: `Invoice ${response.data.Data.Invoice.InvoiceNumber} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }
        )/*.catch((error) => {
            console.warn('Error adding invoice: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...newInvoice, OfflineID: guid() };
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'invoices')),
                ]).then(() => {
                    dispatch({
                        type: ADD_INVOICE,
                        data: { ...offlineData, Invoice: -1 }
                    })
                })
            }
        });*/
    };
}


export function addInvoice(newInvoice) {
    return (dispatch, getState) => {

        const { routeParams } = getState().invoicesApp.invoices;
        window["warn"](newInvoice);
        const invoice = _.cloneDeepWith(newInvoice);
        delete invoice.Data.Data;
        const url = `${window["apiLocation"]}/api/Invoice`;
        const request = axios.post(url,
            invoice
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(updateEditInvoiceDialog({ ...newInvoice, ID: response.data.ID, Invoice: response.data.Invoice }));
                dispatch({
                    type: ADD_INVOICE,
                    data: { ...newInvoice, ID: response.data.ID, Invoice: response.data.Invoice }
                })
                dispatch(showMessage({
                    message: `Invoice ${newInvoice.InvoiceNumber} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }
        ).catch((error) => {
            console.warn('Error adding invoice: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...newInvoice, OfflineID: guid() };
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'invoices')),
                ]).then(() => {
                    dispatch({
                        type: ADD_INVOICE,
                        data: { ...offlineData, Invoice: -1 }
                    })
                })
            }
        });
    };
}

export function updateInvoice(invoice) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Invoice?Co=${encodeURIComponent(invoice.Co)}&Invoice=${encodeURIComponent(invoice.Invoice)}`;
        const request = axios.put(url,
            invoice
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_INVOICE,
                    data: invoice
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    // dispatch(updateEditInvoiceDialog(response.data));
                    dispatch(showMessage({
                        message: `Invoice ${invoice.InvoiceNumber} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        ).catch((error) => {
            console.warn('Error updating invoice: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('UPDATE', 'put', url, invoice, 'invoices')),
                ]).then(() => {
                    dispatch({
                        type: UPDATE_INVOICE,
                        data: invoice
                    })
                })
            }
        });
    };
}

export function updateInvoiceDetail(invoice, invoiceDetail) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Invoice?Co=${invoiceDetail.Co}&Invoice=${invoiceDetail.Invoice}&Detail=${invoiceDetail.InvoiceDetail}`;
        const request = axios.put(url,
            invoiceDetail
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const index = _.findIndex(invoice.Data.Data.InvoiceDetails, { InvoiceDetail: invoiceDetail.InvoiceDetail });
                invoice.Data.Data.InvoiceDetails.splice(index, 1, response.data);
                dispatch({
                    type: UPDATE_INVOICE,
                    data: invoice
                })
                dispatch(updateEditInvoiceDialog(invoice));
                dispatch(showMessage({
                    message: `Invoice Detail successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        })
    };
}

export function removeInvoiceDetail(invoice, invoiceDetail) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Invoice?Co=${invoiceDetail.Co}&Invoice=${invoiceDetail.Invoice}&Detail=${invoiceDetail.InvoiceDetail}`;
        const request = axios.delete(url);

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const index = _.findIndex(invoice.Data.Data.InvoiceDetails, { InvoiceDetail: invoiceDetail.InvoiceDetail });
                invoice.Data.Data.InvoiceDetails.splice(index, 1);
                dispatch({
                    type: UPDATE_INVOICE,
                    data: invoice
                })
                dispatch(updateEditInvoiceDialog(invoice));
                dispatch(showMessage({
                    message: `Invoice Detail successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        })
    };
}

export function syncInvoice(invoice) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Invoice?Co=${encodeURIComponent(invoice.Co)}&Invoice=${encodeURIComponent(invoice.Invoice)}&InvoiceNumber=${encodeURIComponent(invoice.InvoiceNumber)}`;
        const request = axios.put(url);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_INVOICE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(updateEditInvoiceDialog(response.data));
                    dispatch(showMessage({
                        message: `Invoice ${invoice.InvoiceNumber} line items successfully synchronized.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeAgreementInvoice(invoice) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Invoice?Co=${encodeURIComponent(invoice.Co)}&Invoice=${encodeURIComponent(invoice.Invoice)}`;
        const request = axios.delete(url);

        return request.then((response) => {

            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Promise.all([
                    dispatch({
                        type: REMOVE_INVOICE,
                        data: invoice,
                    }),
                    dispatch(showMessage({
                        message: `Invoice ${invoice.InvoiceNumber} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                ])
            }
        }).catch((error) => {
            console.warn('Error deleting invoice: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('REMOVE', 'delete', url, invoice, 'invoices')),
                ]).then(() => {
                    dispatch({
                        type: REMOVE_INVOICE,
                        data: invoice
                    })
                })
            }
        });
    };
}

export function removeInvoice(invoice) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/Invoice?Co=${encodeURIComponent(invoice.Co)}&Invoice=${encodeURIComponent(invoice.Invoice)}`;
        const request = axios.delete(url);

        return request.then((response) => {

            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Promise.all([
                    dispatch({
                        type: REMOVE_INVOICE,
                        data: invoice,
                    }),
                    dispatch(showMessage({
                        message: `Invoice ${invoice.InvoiceNumber} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                ])
            }
        }).catch((error) => {
            console.warn('Error deleting invoice: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('REMOVE', 'delete', url, invoice, 'invoices')),
                ]).then(() => {
                    dispatch({
                        type: REMOVE_INVOICE,
                        data: invoice
                    })
                })
            }
        });
    };
}


export function removeInvoices(invoiceIds) {
    return (dispatch, getState) => {

    };
}

export function toggleStarredInvoice(invoiceId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().invoicesApp.invoices;

        const request = axios.post('/api/invoices-app/toggle-starred-invoice', {
            invoiceId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_INVOICE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInvoices(routeParams)))
        );
    };
}

export function toggleStarredInvoices(invoiceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().invoicesApp.invoices;

        const request = axios.post('/api/invoices-app/toggle-starred-invoices', {
            invoiceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_INVOICES
                }),
                dispatch({
                    type: DESELECT_ALL_INVOICES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInvoices(routeParams)))
        );
    };
}

export function setInvoicesStarred(invoiceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().invoicesApp.invoices;

        const request = axios.post('/api/invoices-app/set-invoices-starred', {
            invoiceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_INVOICES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_INVOICES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInvoices(routeParams)))
        );
    };
}

export function setInvoicesUnstarred(invoiceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().invoicesApp.invoices;

        const request = axios.post('/api/invoices-app/set-invoices-unstarred', {
            invoiceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_INVOICES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_INVOICES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getInvoices(routeParams)))
        );
    };
}


export function sendInvoice(fd) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/Email`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        return request.then((response) => {
            console.warn(response);
        });
    };
}