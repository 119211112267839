import axios from 'axios/index';
import { getUserData } from 'main/content/apps/agreement-type-tasks/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_AGREEMENT_TYPE_TASKS = '[AGREEMENT TYPE TASKS APP] GET AGREEMENT TYPE TASKS';
export const SET_SEARCH_TEXT = '[AGREEMENT TYPE TASKS APP] SET SEARCH TEXT';
export const OPEN_NEW_AGREEMENT_TYPE_TASK_DIALOG = '[AGREEMENT TYPE TASKS APP] OPEN NEW AGREEMENT TYPE TASK DIALOG';
export const CLOSE_NEW_AGREEMENT_TYPE_TASK_DIALOG = '[AGREEMENT TYPE TASKS APP] CLOSE NEW AGREEMENT TYPE TASK DIALOG';
export const OPEN_EDIT_AGREEMENT_TYPE_TASK_DIALOG = '[AGREEMENT TYPE TASKS APP] OPEN EDIT AGREEMENT TYPE TASK DIALOG';
export const UPDATE_EDIT_AGREEMENT_TYPE_TASK_DIALOG = '[AGREEMENT TYPE TASKS APP] UPDATE EDIT AGREEMENT TYPE TASK DIALOG';
export const CLOSE_EDIT_AGREEMENT_TYPE_TASK_DIALOG = '[AGREEMENT TYPE TASKS APP] CLOSE EDIT AGREEMENT TYPE TASK DIALOG';
export const ADD_AGREEMENT_TYPE_TASK = '[AGREEMENT TYPE TASKS APP] ADD AGREEMENT TYPE TASK';
export const UPDATE_AGREEMENT_TYPE_TASK = '[AGREEMENT TYPE TASKS APP] UPDATE AGREEMENT TYPE TASK';
export const REMOVE_AGREEMENT_TYPE_TASK = '[AGREEMENT TYPE TASKS APP] REMOVE AGREEMENT TYPE TASK';
export const ADD_AGREEMENT_TYPE_TASK_OVERRIDE = '[AGREEMENT TYPE TASKS APP] ADD AGREEMENT TYPE TASK OVERRIDE';
export const UPDATE_AGREEMENT_TYPE_TASK_OVERRIDE = '[AGREEMENT TYPE TASKS APP] UPDATE AGREEMENT TYPE TASK OVERRIDE';
export const REMOVE_AGREEMENT_TYPE_TASK_OVERRIDE = '[AGREEMENT TYPE TASKS APP] REMOVE AGREEMENT TYPE TASK OVERRIDE';


export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}


export function openNewAgreementTypeTaskDialog(data) {
    return {
        type: OPEN_NEW_AGREEMENT_TYPE_TASK_DIALOG,
        data
    }
}

export function closeNewAgreementTypeTaskDialog() {
    return {
        type: CLOSE_NEW_AGREEMENT_TYPE_TASK_DIALOG
    }
}

export function openEditAgreementTypeTaskDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/AgreementTypeTask?Co=${data.Co}&BusinessUnit=${encodeURIComponent(data.BusinessUnit)}&Division=${encodeURIComponent(data.Division)}&AgreementType=${encodeURIComponent(data.AgreementType)}&TaskType=${encodeURIComponent(data.TaskType)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_AGREEMENT_TYPE_TASK_DIALOG,
                data: response.data
            });
        });
    }
}

export function closeEditAgreementTypeTaskDialog() {
    return {
        type: CLOSE_EDIT_AGREEMENT_TYPE_TASK_DIALOG
    }
}

export function addAgreementTypeTask(newAgreementTypeTask) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/AgreementTypeTask`,
            newAgreementTypeTask
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_AGREEMENT_TYPE_TASK,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement Type Task ${newAgreementTypeTask.TaskType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateAgreementTypeTask(agreementTypeTask) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/AgreementTypeTask?Co=${encodeURIComponent(agreementTypeTask.Co)}&BusinessUnit=${encodeURIComponent(agreementTypeTask.BusinessUnit)}&Division=${encodeURIComponent(agreementTypeTask.Division)}&AgreementType=${encodeURIComponent(agreementTypeTask.AgreementType)}&TaskType=${encodeURIComponent(agreementTypeTask.TaskType)}`,
            agreementTypeTask
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_AGREEMENT_TYPE_TASK,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement Type Task ${agreementTypeTask.TaskType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeAgreementTypeTask(agreementTypeTask) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/AgreementTypeTask?Co=${encodeURIComponent(agreementTypeTask.Co)}&BusinessUnit=${encodeURIComponent(agreementTypeTask.BusinessUnit)}&Division=${encodeURIComponent(agreementTypeTask.Division)}&AgreementType=${encodeURIComponent(agreementTypeTask.AgreementType)}&TaskType=${encodeURIComponent(agreementTypeTask.TaskType)}`,
            agreementTypeTask
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_AGREEMENT_TYPE_TASK,
                    data: agreementTypeTask
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement Type Task ${agreementTypeTask.TaskType} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function addAgreementTypeTaskOverride(newOverride) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/AgreementTypeTaskRevenueOverride`,
            newOverride
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_AGREEMENT_TYPE_TASK_OVERRIDE,
                    data: response.data,
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Agreement Type Task Override ${newOverride.UnitType} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function updateAgreementTypeTaskOverride(override) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/AgreementTypeTaskRevenueOverride?Co=${encodeURIComponent(override.Co)}&BusinessUnit=${encodeURIComponent(override.BusinessUnit)}&Division=${encodeURIComponent(override.Division)}&AgreementType=${encodeURIComponent(override.AgreementType)}&TaskType=${encodeURIComponent(override.TaskType)}&UnitType=${encodeURIComponent(override.UnitType)}`,
            override
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_AGREEMENT_TYPE_TASK_OVERRIDE,
                    data: response.data,
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Agreement Type Task Override ${override.UnitType} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function removeAgreementTypeTaskOverride(override) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/AgreementTypeTaskRevenueOverride?Co=${encodeURIComponent(override.Co)}&BusinessUnit=${encodeURIComponent(override.BusinessUnit)}&Division=${encodeURIComponent(override.Division)}&AgreementType=${encodeURIComponent(override.AgreementType)}&TaskType=${encodeURIComponent(override.TaskType)}&UnitType=${encodeURIComponent(override.UnitType)}`,
            override
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_AGREEMENT_TYPE_TASK_OVERRIDE,
                    data: override,
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Agreement Type Task Override ${override.UnitType} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}
