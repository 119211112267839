import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardMedia, CardContent, CardActions, Chip, Divider, Hidden, Icon, IconButton, LinearProgress, List, ListItem, ListItemText, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import { isMobile } from 'react-device-detect';
import * as Actions from './store/actions';
import _ from '@lodash';
import classNames from 'classnames';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import ImageDialog from '../catalog/ImageDialog';
import CatalogOptionBoardCategoryImageDialog from './CatalogOptionBoardCategoryImageDialog';
import { SPRoundIcon, SPMarkerIcon } from 'main/icons';
import parse, { domToReact } from 'html-react-parser';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/logos/SP_Cog_Gradient.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        overflow: 'hidden',
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    cardSubheader: {
        fontSize: 11,
    },
    avatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    completedAvatar: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 12
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    hoverable: {
        "& .showOnHover": {
            display: 'none',
        },
        "&:hover .showOnHover":
        {
            display: 'block'
        },
        // "&:active": {
        //     transform: 'scale(.95)'
        // }
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    scaleButton: {
        "&:active": {
            transform: 'scale(.95)'
        }
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    progress: {
        width: 48,
        height: 48,
        margin: 'auto',
        marginBottom: -36,
        top: -30,
    },
    roundProgress: {
        width: '72px !important',
        height: '72px !important',
    },
    avatar: {
        backgroundColor: '#fff !important',
        width: 48,
        height: 48,
    },
    labels: {},
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
});

const initialState = {
    ID: null,
    Co: null,
    OptionBoard: null,
    Category: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
    editing: false,
    imgSearch: false,
};

class CatalogOptionBoardCard extends Component {

    state = {
        ID: null,
        Co: null,
        OptionBoard: null,
        Category: null,
        Data: {
            Steps: [],
            ErrMsg: null,
        },
        Steps: 0,
        open: false,
        editing: false,
    }

    componentDidMount() {
        const { value } = this.props;
        // if (value.Data && value.Data.Image) {

        // }
        this.setState({ ...initialState, ...value });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value)) {
            this.setState({ ...this.state, ...value });
        }
    }

    hasAlpha(context, canvas) {
        var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
            hasAlphaPixels = false;
        for (var i = 3, n = data.length; i < n; i += 4) {
            if (data[i] < 255) {
                hasAlphaPixels = true;
                break;
            }
        }
        return hasAlphaPixels;
    }

    // checkAlpha(src) {
    //     var canvas = document.createElement('canvas');

    //     var img = new Image();
    //     img.onload = function () {
    //         canvas.width = 100;
    //         canvas.height = 100;
    //         ctx.drawImage(img, 0, 0, 100, 100);
    //         document.getElementById("image").src = canvas.toDataURL(src);
    //     }
    //     img.src = src;

    //     var ctx = canvas.getContext('2d');
    //     reader.readAsDataURL(e.target.files[0]);
    // }

    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ ...this.state, open: !open });
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        const { editing } = this.state;
        this.setState({ ...this.state, editing: !editing, open: true });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    canBeSubmitted = () => {
        const { Name, Description, Category } = this.state;
        return Name && Name.length > 0 && Description && Description.Length > 0 && Category && !isNaN(Category);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    addComponent = (e) => {
        e.stopPropagation();
        const { estimate, onAddComponent } = this.props;
        if (estimate && estimate.Data && estimate.Data.Components) {
            var rec = this.state;
            const {
                Co,
                BusinessUnit,
                Division,
                Qty,
                OptionBoard,
                TaxableYN,
                AddonYN
            } = rec;
            const component = {
                Co,
                BusinessUnit,
                Division,
                Estimate: estimate.Estimate,
                Component: estimate.Data.Components.length + 1,
                Type: 0,
                Qty: Qty || 1,
                UOM: 'EA',
                OptionBoard,
                Price: Number(this.state.Qty || 1) * Number(this.state.AddonYN === 'Y' ? this.state.AddOnPrice : this.state.Price) || 0,
                PriceMethod: 'F',
                TaxableYN,
                AddonYN,
                Data: {
                    OptionBoard: rec,
                }
            };
            estimate.Data.Components.push(component);
            window["warn"](estimate);
            if (onAddComponent) {
                onAddComponent();
            }
        }
    }

    render() {
        const { classes, value, category, user, securables, sidebar, component, estimate, option, dialog, catalogs, onClickBefore, altClick, companies } = this.props;
        // window["warn"]('Estimate OptionBoard: ', catalog, value);
        const { Data } = catalog || {};
        const { OptionBoardCategories } = Data || {};
        const { open, editing, imgSearch } = this.state;
        const defaultImg = 'assets/images/logos/SP_Cog_Gradient.png';
        const accessLevel = _.find(securables, { Securable: "catalog" });
        if (value && value.Data && value.Data.Images && value.Data.Images.length > 0) {
            value.Data.Image = _.find(value.Data.Images, { PrimaryYN: 'Y' });
            if (!value.Data.Image) {
                value.Data.Image = value.Data.Images[0];
            }
        }
        const { Co, BusinessUnit, Division, Assembly, Category } = value;
        const co = _.find(companies, { Co }).Data.Co;
        const catalog = _.find([...catalogs], { Co, BusinessUnit, Division });
        return (
            <div onClick={(e) => {
                if (altClick) {
                    altClick(value);
                } else {
                    if (onClickBefore) {
                        Promise.all([
                            onClickBefore(e)
                        ]).then(() => {
                            if (value.OptionBoard) {
                                this.props.openEditOptionBoardDialog(value, catalog, estimate);
                            }
                        });
                    } else {
                        if (value.OptionBoard) {
                            this.props.openEditOptionBoardDialog(value, catalog, estimate);
                        } else {
                            if (this.props.onClick) {
                                this.props.onClick(e);
                            }
                        }
                    }
                }
                //}} className={classNames(sidebar ? "w-full p-6" : component ? "w-1/2 p-6 m-0" : dialog ? value.OptionBoard ? "w-1/2 sm:w-1/3 md:w-1/4 p-4 pt-8 sm:p-12" : "w-full sm:w-1/3 md:w-1/4 p-4 pt-8 sm:p-12" : "w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:1/6 p-4 pt-8 sm:p-12")}>
            }} className={classNames(sidebar ? "w-full p-6" : component ? "w-1/2 p-6 m-0" : "w-1/2 sm:w-1/3 md:w-1/4 p-4 pt-8 sm:p-12")}>
                <Card className="mt-8 w-full relative cursor-pointer h-full">
                    {component &&
                        <div className={classNames("pin-r pin-t m-8 absolute text-center text-24 p-8 pt-4 pb-4 font-bold")} style={{ backgroundColor: `#3f3f3f`, lineHeight: '16px', borderRadius: 24 }}>
                            <div class="w-full text-14 text-white">{component.Qty}</div>
                        </div>
                    }
                    <CardMedia
                        component="image"
                        className={!component ? "h-160 sm:h-192 w-full relative" : "h-128 w-full relative"}
                        image={(this.state.Data && this.state.Data.Image && this.state.Data.Image.ImgUrl) ? this.state.Data.Image.ImgUrl : "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"}
                        onClick={(e) => { if (editing && !value.OptionBoard) { e.stopPropagation(); this.setState({ ...this.state, imgSearch: !imgSearch }) } }}
                        style={(!this.state.Data || !this.state.Data.Image || !this.state.Data.Image.ImgUrl) ? {
                            background: catalog.EstimateHeaderImg ? 'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,0)), url(assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg)' : '#fff',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        } : undefined}
                        alt={value.Name}
                    >
                        {(!this.state.Data || !this.state.Data.Image || !this.state.Data.Image.ImgUrl) &&
                            <img src={catalog.EstimateHeaderImg || co.ImgUrl || "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"} className={catalog.EstimateHeaderImg ? "pin-t pin-b pin-l pin-r m-auto absolute w-5/6" : "pin-t pin-b pin-l pin-r m-auto absolute"} />
                        }
                    </CardMedia>
                    <div className="pin-t pin-r absolute px-12 text-white rounded-bl-lg py-4 font-bold text-12" style={{ backgroundColor: '#3f3f3f' }}>
                        {`${value.Data.Options.length} Options`}
                    </div>
                    <CardContent className={editing ? "pb-6" : ""} classes={{ root: "px-6 py-12 pb-0 has-hover" }}>
                        <div className={classNames(classes.progress, "relative")}>
                            <Avatar className={classNames(classes.avatar, "absolute pin-t cursor-pointer")}>
                                {
                                    value.OptionBoard ?
                                        estimate ?
                                            <Icon className="text-28" style={{ color: '#5f5f5f' }}>file_copy</Icon> :
                                            <SPRoundIcon className="text-36" style={{ color: '#5f5f5f' }} /> :
                                        (!accessLevel || accessLevel.AccessLevel === "R" || (accessLevel.AccessLevel === "E" || user === value.AddedBy)) ?
                                            <Icon className="text-28" style={{ color: '#5f5f5f' }}>apps</Icon> :
                                            (isMobile && open && !this.props.estimate) ?
                                                <Icon onClick={this.toggleEdit} className="text-28" style={{ color: '#5f5f5f' }}>edit</Icon> :
                                                editing ?
                                                    <Icon className="text-28" style={{ color: '#5f5f5f' }}>edit</Icon> :
                                                    !this.props.estimate ?
                                                        <React.Fragment>
                                                            <Icon onClick={this.toggleEdit} className="text-28 on-hover" style={{ color: '#5f5f5f' }}>edit</Icon>
                                                            <Icon className="text-28 hide-on-hover" style={{ color: '#5f5f5f' }}>apps</Icon>
                                                        </React.Fragment> :
                                                        <Icon className="text-28" style={{ color: '#5f5f5f' }}>apps</Icon>
                                }
                            </Avatar>
                            {/* <CircularProgress className={classNames("m-0", classes.roundProgress)} color={step > 0 && step >= value.Steps ? "secondary" : "primary"} variant="determinate" value={value.Steps && value.Steps > 0 && step > 0 ? (step / value.Steps) * 100 : 0} /> */}
                        </div>
                        {!editing ?
                            <React.Fragment>
                                {/* {!this.props.estimate && !value.OptionBoard && isMobile && open && ((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ?
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: -12,
                                            top: -24,
                                        }}
                                        onClick={this.toggleEdit}
                                    >
                                        <Icon color="action">edit</Icon>
                                    </IconButton>
                                    : ''}
                                {!this.props.estimate && !value.OptionBoard && !isMobile && ((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ?
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: -12,
                                            top: -24,
                                        }}
                                        onClick={this.toggleEdit}
                                    >
                                        <Icon color="action">edit</Icon>
                                    </IconButton>
                                    : ''} */}
                                <Typography onClick={(e) => { e.stopPropagation(); this.setState({ open: !open }) }} gutterBottom variant="h6" style={{ fontSize: 16 }} component="div" className="flex my-4">
                                    <div className={!open ? "truncate w-full text-center" : "w-full text-center"}>{value.Name}</div>
                                </Typography>
                            </React.Fragment>
                            :
                            <div style={{ backgroundColor: '#fff' }} className="relative pt-16" onClick={(e) => e.stopPropagation()}>
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: -12,
                                        top: -24,
                                    }}
                                    onClick={(e) => { e.stopPropagation(); this.setState({ ...this.state, editing: false, open: false }); }}
                                >
                                    <Icon color="action">close</Icon>
                                </IconButton>
                                {imgSearch &&
                                    <ImageDialog category="assets/images/assembly-categories/" onChange={(ImgUrl) => this.setState({ ...this.state, Data: { ...this.state.Data, Image: this.state.Data.Image ? { ...this.state.Data.Image, ImgUrl } : { Co, BusinessUnit, Division, Category, ImgUrl } } })} open={imgSearch} onClose={() => this.setState({ ...this.state, imgSearch: false })} />
                                    // <CatalogOptionBoardCategoryImageDialog open={imgSearch} onClose={() => this.setState({ ...this.state, imgSearch: !imgSearch })} category={this.state} onChange={(Image) => this.setState({ ...this.state, Data: { ...this.state.Data, Image } })} />
                                }

                                <TextField
                                    className={classes.formControl}
                                    label="Name"

                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    margin="dense"
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                {/* 
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    margin="dense"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                /> */}

                                <TextField
                                    className={classes.formControl}
                                    label="Contains"
                                    id="type"
                                    name="Type"
                                    value={this.state.Type}
                                    margin="dense"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    select
                                >
                                    <MenuItem value="C" disabled={_.findIndex(catalog.Data.Assignments, { Category: this.state.Category }) > -1}>
                                        Categories
                                    </MenuItem>
                                    <MenuItem value="T" disabled={_.findIndex(OptionBoardCategories, { ParentCategory: this.state.Category }) > -1}>
                                        Option Boards
                                    </MenuItem>
                                </TextField>
                                {this.state.ID &&
                                    <div className="flex">
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Parent Category"
                                            options={[{ Category: null, Name: 'None' }, ...(_.filter(OptionBoardCategories || [], (o) => { return o.Category !== this.state.Category && o.Type === 'C' }))]}
                                            menuItemComponent={(value, index) => {
                                                return (
                                                    <MenuItem key={value.Category || index} value={value.Category}>
                                                        {value.Name}
                                                    </MenuItem>
                                                )
                                            }}
                                            portal={true}
                                            moreProps={{ margin: 'dense' }}
                                            debounce={750}
                                            value={this.state.ParentCategory || ''}
                                            onSelect={(option) => this.setState({ ...this.state, ParentCategory: option.Category })}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </CardContent>
                    <CardActions className="w-full p-12 pt-0 px-6">
                        {/* {value.OptionBoard &&
                            <div className="w-full">
                                {value.ActiveYN !== 'Y' ?
                                    <Chip className={classNames(classes.black, "w-full font-bold")} icon={<Icon color="error" className={classNames("text-24")}>visibility_off</Icon>} label="INACTIVE" /> :
                                    (component && component.Price) ?
                                        <Chip className={classNames(classes.black, classes.scaleButton, "w-full font-bold")} icon={<Icon className={classNames(classes.colorSecondary, "text-24")}>edit</Icon>} label={this.formatDollars(component.Price)} />
                                        : (value.OptionBoard && value.Price) ?
                                            <Chip onClick={this.addComponent} className={classNames(classes.black, estimate || option ? classes.scaleButton : "", "w-full font-bold")} icon={!estimate && !option ? <SPRoundIcon className={classNames(classes.colorSecondary, "text-24")} /> : <Icon className={classNames(classes.colorSecondary, "text-24")}>add_circle_outline</Icon>} label={this.formatDollars(value.Price)} /> :
                                            <Chip onClick={this.addComponent} className={classNames(classes.black, estimate || option ? classes.scaleButton : "", "w-full font-bold")} icon={!estimate && !option ? <SPRoundIcon className={classNames(classes.colorSecondary, "text-24")} /> : <Icon className={classNames(classes.colorSecondary, "text-24")}>add_circle_outline</Icon>} label={this.formatDollars(0)} />
                                }
                            </div>
                        } */}
                        {editing &&
                            <div className="w-full flex">
                                <Button
                                    className={classNames(classes.saveButton, "w-full p-8 text-14")}
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.canBeSubmitted}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ ...this.state, editing: false, });
                                        if (!this.state.ID) {
                                            this.props.addOptionBoardCategory(this.state);
                                            this.props.onDelete();
                                        } else {
                                            this.props.updateOptionBoardCategory(this.state);
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={classNames(classes.deleteButton, "min-w-32 p-8 text-14")}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ ...this.state, editing: false, });
                                        if (!this.state.ID) {
                                            this.props.onDelete();
                                        } else {
                                            this.props.deleteOptionBoardCategory(this.state);
                                        }
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </Button>
                            </div>
                        }
                    </CardActions>
                </Card>
                {/* <Card classes={{ root: classes.hoverable }} className="w-full">
                    {value.OptionBoard &&
                        <React.Fragment>
                            <div className="w-full flex">
                                <Tooltip title={parse(value.Description)} placement="bottom-start">
                                    <div onClick={() => { if (value.OptionBoard) { this.props.openEditOptionBoardDialog(value, estimate); } }} className="p-6 pb-0 text-left w-full truncate cursor-pointer">
                                        <strong className={classes.dark} className="text-12">
                                            {!component ? value.OptionBoard ?
                                                <SPRoundIcon className="mr-6 align-middle mb-4" /> :
                                                <Icon className="mr-6 align-middle mb-4">apps</Icon> : ''
                                            }
                                            {`${value.Code || value.OptionBoard} - ${value.Name}`}
                                        </strong>
                                    </div>
                                </Tooltip>
                                {!component && !value.OptionBoard &&
                                    <div className="showOnHover">
                                        <Tooltip title={value.OptionBoard ? "Edit OptionBoard" : "Edit Category"} placement="top">
                                            <div onClick={this.toggleEdit} className="p-6 pb-0 cursor-pointer">
                                                <strong className={classes.dark} className="text-12">
                                                    <Icon className="align-middle mb-4">edit</Icon>
                                                </strong>
                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                            </div>
                            <CardContent className="relative cursor-pointer" style={{ paddingBottom: 'calc(100% - 16px)' }} classes={{ root: classes.cardContent }}>
                                {this.state.Data && this.state.Data.Image &&
                                    <React.Fragment>
                                        <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute bg-white")}></div>
                                        <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b absolute cursor-pointer m-8")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }}></div>
                                        {component &&
                                            <div className={classNames("pin-r pin-t m-8 absolute text-center text-24 p-8 pt-4 pb-4 font-bold")} style={{ backgroundColor: `#3f3f3f`, lineHeight: '16px', borderRadius: 24 }}>
                                                <div class="w-full text-14">{component.Qty}</div>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            </CardContent>
                        </React.Fragment>
                    }
                    {!sidebar && !value.OptionBoard &&
                        <Hidden xsDown>
                            <CardContent onClick={(e) => { if (value.OptionBoard && editing) { this.props.openEditOptionBoardDialog(value); }; if (editing && !value.OptionBoard) { e.stopPropagation(); this.setState({ ...this.state, imgSearch: !imgSearch }) } }} className="relative cursor-pointer" style={{ paddingBottom: 'calc(100% - 16px)' }} classes={{ root: classes.cardContent }}>
                                {this.state.Data && this.state.Data.Image ?
                                    <React.Fragment>
                                        <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute bg-white")}></div>
                                        <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b absolute cursor-pointer m-8")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }}></div>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute bg-white")}></div>
                                        <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b absolute cursor-pointer m-8")} style={{ backgroundImage: `url(${defaultImg})` }}></div>
                                    </React.Fragment>
                                }
                            </CardContent>
                        </Hidden>
                    }
                    {!value.OptionBoard &&
                        <React.Fragment>
                            <div className="w-full flex">
                                <Tooltip title={parse(value.Description)} placement="top-start">
                                    <div className="p-6 pb-0 text-left w-full truncate cursor-pointer flex">
                                        <Hidden smUp>
                                            {this.state.Data && this.state.Data.Image ?
                                                <div onClick={() => this.setState({ ...this.state, imgSearch: !imgSearch })} className="mr-6 align-middle mb-4 w-48 h-48 relative">
                                                    <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute bg-white")}></div>
                                                    <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b absolute cursor-pointer")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }}></div>
                                                </div> :
                                                <div onClick={() => this.setState({ ...this.state, imgSearch: !imgSearch })} className="mr-6 align-middle mb-4 w-48 h-48 relative">
                                                    <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute bg-white")}></div>
                                                    <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b absolute cursor-pointer")} style={{ backgroundImage: `url(${defaultImg})` }}></div>
                                                </div>
                                            }
                                        </Hidden>
                                        <strong className={classes.dark} className="text-12 mt-4 sm:mt-0 xs:pt-10">
                                            <Hidden xsDown>
                                                <Icon className="mr-6 align-middle mb-4">apps</Icon>
                                            </Hidden>
                                            {value.Name}
                                        </strong>
                                    </div>
                                </Tooltip>
                                <div className="showOnHover">
                                    <Tooltip title={value.OptionBoard ? "Edit OptionBoard" : "Edit Category"} placement="top">
                                        <div onClick={this.toggleEdit} className="p-6 pb-0 cursor-pointer">
                                            <strong className={classes.dark} className="text-12">
                                                <Icon className="align-middle mb-4">edit</Icon>
                                            </strong>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            {editing &&
                                <div onClick={(e) => e.stopPropagation()} style={{ backgroundColor: '#fff' }} className="p-16">
                                    {imgSearch &&
                                        <CatalogOptionBoardCategoryImageDialog open={imgSearch} onClose={() => this.setState({ ...this.state, imgSearch: !imgSearch })} category={this.state} onChange={(Image) => this.setState({ ...this.state, Data: { ...this.state.Data, Image } })} />
                                    }
                                    <div className="flex">

                                        <TextField
                                            className={classes.formControl}
                                            label="Name"

                                            id="name"
                                            name="Name"
                                            value={this.state.Name}
                                            onChange={this.handleChange}
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            fullWidth
                                        />
                                    </div>

                                    <div className="flex">
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            margin="dense"
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                        />
                                    </div>

                                    <div className="flex">
                                        <TextField
                                            className={classes.formControl}
                                            label="Contains"
                                            id="type"
                                            name="Type"
                                            value={this.state.Type}
                                            margin="dense"
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="C" disabled={_.findIndex(catalog.Data.Assignments, { Category: this.state.Category }) > -1}>
                                                Categories
                                            </MenuItem>
                                            <MenuItem value="T" disabled={_.findIndex(catalog.Data.OptionBoardCategories, { ParentCategory: this.state.Category }) > -1}>
                                                Option Boards
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                    {this.state.ID &&
                                        <div className="flex">
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Parent Category"
                                                options={[{ Category: null, Name: 'None' }, ...(_.filter(OptionBoardCategories || [], (o) => { return o.Category !== this.state.Category && o.Type === 'C' }))]}
                                                menuItemComponent={(value) => {
                                                    return (
                                                        <MenuItem key={value.Category} value={value.Category}>
                                                            {value.Name}
                                                        </MenuItem>
                                                    )
                                                }}
                                                portal={true}
                                                moreProps={{ margin: 'dense' }}
                                                debounce={750}
                                                value={this.state.ParentCategory || ''}
                                                onSelect={(option) => this.setState({ ...this.state, ParentCategory: option.Category })}
                                            />
                                        </div>
                                    }
                                    <div className="w-full flex">
                                        <Button
                                            className={classNames(classes.saveButton, "w-full")}
                                            variant="contained"
                                            color="primary"
                                            disabled={!this.canBeSubmitted}
                                            onClick={() => {
                                                this.setState({ ...this.state, editing: false, });
                                                if (!this.state.ID) {
                                                    this.props.addOptionBoardCategory(this.state);
                                                    this.props.onDelete();
                                                } else {
                                                    this.props.updateOptionBoardCategory(this.state);
                                                }
                                            }}
                                        >
                                            {this.state.ID ? 'Save' : 'Add'}
                                        </Button>
                                        {!this.state.ID &&
                                            <Button
                                                className={classNames(classes.deleteButton, "w-32")}
                                                variant="contained"
                                                color="primary"
                                                disabled={_.findIndex(catalog.Data.Assignments, { Category: this.state.Category }) > -1 || _.findIndex(catalog.Data.OptionBoardCategories, { ParentCategory: this.state.Category }) > -1}
                                                onClick={() => {
                                                    this.setState({ ...this.state, editing: false, });
                                                    if (!this.state.ID) {
                                                        this.props.onDelete();
                                                    } else {
                                                        this.props.deleteOptionBoardCategory(this.state);
                                                    }
                                                }}
                                            >
                                                <Icon>delete</Icon>
                                            </Button>
                                        }
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </Card > */}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCategory: Actions.setCategory,
        getOptionBoard: Actions.getOptionBoard,
        addOptionBoardCategory: Actions.addOptionBoardCategory,
        updateOptionBoardCategory: Actions.updateOptionBoardCategory,
        deleteOptionBoardCategory: Actions.removeOptionBoardCategory,
        openNewOptionBoardDialog: Actions.openNewOptionBoardDialog,
        openEditOptionBoardDialog: Actions.openEditOptionBoardDialog,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, catalogApp }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        // categories: spReducers.catalog.categories,
        securables: spReducers.userProfiles.User.Data.Securables,
        companies: spReducers.companies.List,
        catalogs: spReducers.catalog.savedCatalogs,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogOptionBoardCard)));
