import { FuseUtils } from '@fuse';
import { Icon, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import RGL, { WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import 'react-resizable/css/styles.css';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import AttachmentDialog from '../file-manager/AttachmentDialog';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import ChecklistItem from './ChecklistItem';
import * as Actions from './store/actions';
import axios from 'axios';

const GridLayout = WidthProvider(RGL);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    checklistSection: {
        border: '1px dotted lightgrey',
        // padding: 8,
    },
    scrollEnable: {
        touchAction: 'unset !important',
    }

});

class ChecklistSectionBuilder extends Component {

    state = {
        grabbing: false,
        needsUpdate: [],
        Notes: null,
        Attachments: [],
        newPhoto: null,
        attachment: null,
        confirmDelete: false,
        deleteAttachment: null
    };

    componentDidMount() {
        const { section } = this.props;
        this.setState({ ...this.state, Notes: section.Notes, Attachments: section.Data.Attachments || [] })
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    handleLayout = (layout) => {
        const { checklist, onNeedsUpdate, data } = this.props;
        const needsUpdate = [];
        layout.map((item, i) => {
            let el = data[i];
            if (item.x !== el.PositionX || item.y !== el.PositionY || item.w !== el.Columns || item.h !== el.Rows) {
                needsUpdate.push({
                    ...el,
                    PositionX: item.x,
                    PositionY: item.y,
                    Columns: item.w,
                    Rows: item.h,
                });
            }
        });
        this.setState({ ...this.state, needsUpdate }, () => onNeedsUpdate(needsUpdate));
    }

    handleChange = (event) => {
        const { section, readOnly } = this.props;
        if (!readOnly) {
            this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), () => { if (this.props.onNotesChange) { this.props.onNotesChange(section, this.state.Notes) } });
        }
    };

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadImg = (file, notes) => {
        const { onAttachment, section } = this.props;
        if (onAttachment) {
            onAttachment(file, notes, (attachment) => {
                this.onAttachmentAdded(attachment);
            });
        }
    }

    onAttachmentAdded = (attachment) => {
        const { section } = this.props;
        const { Attachments } = this.state;
        const { Co, Checklist, Section, ResultID } = section;
        const { Attachment, Notes, AddedBy, AddedDate } = attachment;
        const attch = {
            Co,
            Checklist,
            Section,
            ResultID,
            Attachment,
            Notes,
            IsNew: "Y",
            EnteredBy: AddedBy,
            EnteredDate: AddedDate,
            Data: {
                Attachment: attachment
            }
        };
        Attachments.push(attch);
        // section.Data.Attachments.push(attch);
        this.setState({ Attachments, newPhoto: null });
    }

    updateAttachment = (file, notes) => {
        const { section } = this.props;
        const { attachment } = this.state;
        if (attachment) {
            const fd = new FormData();
            fd.append('image', file, file.fileName);
            if (notes) {
                fd.append('notes', notes);
            }
            // saveAs(img.file, img.fileName);

            axios.post(`${window["apiLocation"]}/api/Attachment/UpdateAttachment?Co=${encodeURIComponent(section.Co)}&Attachment=${attachment.Attachment}`, fd).then((res) => {
                if (res.data.Attachment) {
                    const { Attachments } = this.state;
                    const index = _.findIndex(Attachments, { Attachment: attachment.Attachment });
                    if (index > -1) {
                        Attachments[index].Notes = notes;
                        Attachments[index].IsUpdated = "Y";
                        Attachments[index].Data.Attachment = res.data.Attachment;
                    }
                    this.setState({ Attachments, attachment: null });
                }
            });
        }
    }

    deleteAttachment = (attachment) => {
        const { section } = this.props;
        if (attachment) {
            // axios.delete(`${window["apiLocation"]}/api/Attachment/DeleteAttachment?Co=${encodeURIComponent(session.Co)}&Attachment=${attachment.Attachment}`).then((res) => {
            const { Attachments } = this.state;
            const index = _.findIndex(Attachments, { Attachment: attachment.Attachment });
            if (index > -1) {
                Attachments[index].IsDeleted = 'Y';
            }
            this.setState({ Attachments, attachment: null, deleteAttachment: null });
            // });
        }
    }

    fileSelectedHandler = (event, callback) => {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            const { section } = this.props;
            const fname = this.guid();
            this.setState({ newPhoto: { data: e.target.result, fileName: fname, type: "image/jpeg" } });
            if (this.fileInput) {
                this.fileInput.value = "";
            }
        }
        reader.readAsDataURL(file);
    }

    render() {
        const { Co, classes, data, scrollLock, editable, section, readOnly, builder } = this.props;
        const { Attachments, newPhoto, attachment } = this.state;
        return (
            <div className="w-full">
                <Typography onClick={this.props.onClick ? () => this.props.onClick(section) : undefined} variant="h6" className="h-24 w-auto font-bold mb-12 cursor-pointer truncate" style={{ maxWidth: '50%' }}>{section.Name}</Typography>
                <div className={classNames("layout pt-6 pb-6 w-full relative overflow-hidden checklist-section")}>
                    {
                        data.map((el, index) => {
                            const photoNum = (el.Attachment ? _.findIndex(Attachments, { Attachment: el.Attachment }) + 1 : null);
                            return (
                                <div
                                    className="w-full"
                                    key={index}
                                >
                                    <ChecklistItem builder={builder} photoNum={photoNum} onAttachment={this.props.onAttachment} onAttachmentAdded={(attachment) => this.onAttachmentAdded(attachment)} readOnly={readOnly} onClick={this.props.onItemClick ? () => this.props.onItemClick(el) : undefined} scrollLock={scrollLock} onChange={this.props.onChange} el={{ ...el }} editable={editable} />
                                </div>
                            );
                        })
                    }
                    <div className="w-full mb-4" style={{ marginTop: 0 }}>
                        <Typography className="font-bold text-16 mb-4">Attachments</Typography>
                        <div className="w-full flex flex-wrap">
                            {_.filter(Attachments, (o) => o.IsDeleted !== 'Y' && Boolean(o.Data) && Boolean(o.Data.Attachment)).map((attch, index) => {
                                return (
                                    <div className="w-1/4 p-8 cursor-pointer relative"><div className="rounded-full absolute pin-t pin-r mt-16 mr-16 z-10 text-white text-center font-bold px-4 min-w-24" style={{ backgroundColor: '#f44336', boxShadow: '1px 1px 4px 0px rgb(0 0 0 / 75%)' }}>{index + 1}</div><img onClick={() => this.setState({ attachment: attch.Data.Attachment })} src={`${window["apiLocation"]}/api/Attachment/GetPDFImage?Co=${attch.Co}&ID=${attch.Attachment}&Timestamp=${new Date(attch.Data.Attachment.UpdatedDate || attch.Data.Attachment.AddedDate).getTime()}`} className="w-full h-auto rounded-lg" />{attch.Notes && <div className="w-full text-10 italic text-center">"{attch.Notes}"</div>}</div>
                                );
                            })}
                        </div>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={this.fileSelectedHandler}
                            ref={fileInput => this.fileInput = fileInput}
                            accept=".jpg, png, .bmp"
                        />
                        <div className="w-full min-h-84 rounded-lg bg-grey-lightest relative cursor-pointer no-print" onClick={builder ? undefined : () => this.fileInput.click()} style={{ border: '1px dotted lightgrey' }}>
                            <Icon className="absolute m-auto pin-l pin-r pin-t pin-b" color="action">add_a_photo</Icon>
                        </div>
                        {newPhoto &&
                            <AttachmentDialog
                                file={newPhoto}
                                open={true}
                                edit={true}
                                onClose={() => this.setState({ newPhoto: null })}
                                onSave={this.uploadImg}
                            />
                        }
                        {attachment &&
                            <AttachmentDialog
                                attachments={_.map(Attachments, (o) => o.Data.Attachment)}
                                onChange={(attch) => this.setState({ attachment: attch })}
                                attachment={attachment}
                                open={true}
                                notes={attachment.Notes}
                                onClose={() => this.setState({ attachment: null })}
                                onDelete={this.deleteAttachment}
                                onSave={this.updateAttachment}
                            />
                        }
                        {Boolean(this.state.deleteAttachment) && this.state.confirmDelete &&
                            <ConfirmationDialog
                                open={this.state.confirmDelete}
                                title={<div><Icon className="mr-6 align-middle mb-4">check_circle</Icon>Are You Sure?</div>}
                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this photo? This action cannot be undone.`}</div>}
                                confirmText="Delete Photo"
                                cancelText="Cancel"
                                onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                                onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => this.deleteAttachment(this.state.deleteAttachment))}
                            />
                        }
                    </div>
                    <TextField
                        className={"my-16"}
                        label="Notes"
                        id={`section${section.Section}-notes`}
                        name="Notes"
                        value={this.state.Notes || ''}
                        onChange={this.handleChange}
                        variant="outlined"
                        disabled={!scrollLock}
                        multiline
                        rows={4}
                        margin="dense"
                        fullWidth
                    />
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewChecklistItemDialog: Actions.openNewChecklistItemDialog,
        openEditChecklistItemDialog: Actions.openEditChecklistItemDialog,
    }, dispatch);
}

function mapStateToProps({ checklistsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        checklistData: spReducers.checklists,
        searchText: checklistsApp.checklists.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistSectionBuilder)));
