import axios from 'axios/index';

export const SET_GL_ACCOUNT_DATA = '[SPCONNECTION] SET GL ACCOUNT DATA';
export const GET_GL_ACCOUNT_DATA = '[SPCONNECTION] GET GL ACCOUNT DATA';
export const SUBSCRIBE_GL_ACCOUNT_LIST = '[SPCONNECTION] SUBSCRIBE GL ACCOUNT LIST';

export function getInitialGLAccountData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/GLAccount`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getGLAccountData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/GLAccount`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_GL_ACCOUNT_DATA,
                payload: response.data
            })
        });
    }
}

export function setGLAccountData(data) {
    return {
        type: SET_GL_ACCOUNT_DATA,
        payload: data
    };
}