import * as Actions from '../actions/incoming-call.actions';
import CacheManager from 'store/middlewares/cache';


import _ from '@lodash';
import { data } from 'jquery';


const offline = new CacheManager();


const initialState = {
    type: 'new',
    props: {
        open: false
    },
    //data: null
};




const incomingCallReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.OPEN_EDIT_INCOMING_CALL_DIALOG:
            {
                return {
                    ...state,
                    type: 'edit',
                    props: {
                        open: true
                    },
                    //data: action.data
                };
            }
        case Actions.CLOSE_EDIT_INCOMING_CALL_DIALOG:
            {
                return {
                    ...state,
                    type: 'edit',
                    props: {
                        open: false
                    },
                    //data: null
                };
            }
        default:
            {
                return state;
            }
    }
};


export default incomingCallReducer;