import _ from '@lodash';
import axios from 'axios/index';
import { getUserData } from 'main/content/apps/technicians/store/actions/user.actions';
import { GET_TECHNICIAN_PERFORMANCE, SET_TECHNICIAN_PERFORMANCE, SET_TECHNICIAN_SCHEDULE, SET_TECHNICIAN_TIMECARD, SET_TECHNICIAN_ESTIMATES, showMessage } from 'store/actions';

export const GET_TECHNICIANS = '[TECHNICIANS APP] GET TECHNICIANS';
export const SET_SEARCH_TEXT = '[TECHNICIANS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TECHNICIANS = '[TECHNICIANS APP] TOGGLE IN SELECTED TECHNICIANS';
export const SELECT_ALL_TECHNICIANS = '[TECHNICIANS APP] SELECT ALL TECHNICIANS';
export const DESELECT_ALL_TECHNICIANS = '[TECHNICIANS APP] DESELECT ALL TECHNICIANS';
export const OPEN_NEW_TECHNICIAN_DIALOG = '[TECHNICIANS APP] OPEN NEW TECHNICIAN DIALOG';
export const CLOSE_NEW_TECHNICIAN_DIALOG = '[TECHNICIANS APP] CLOSE NEW TECHNICIAN DIALOG';
export const OPEN_EDIT_TECHNICIAN_DIALOG = '[TECHNICIANS APP] OPEN EDIT TECHNICIAN DIALOG';
export const ADD_EDIT_TECHNICIAN_DIALOG = '[TECHNICIANS APP] ADD EDIT TECHNICIAN DIALOG';
export const UPDATE_EDIT_TECHNICIAN_DIALOG = '[TECHNICIANS APP] UPDATE EDIT TECHNICIAN DIALOG';
export const DATA_EDIT_TECHNICIAN_DIALOG = '[TECHNICIANS APP] DATA EDIT TECHNICIAN DIALOG';
export const CLOSE_EDIT_TECHNICIAN_DIALOG = '[TECHNICIANS APP] CLOSE EDIT TECHNICIAN DIALOG';
export const ADD_TECHNICIAN = '[TECHNICIANS APP] ADD TECHNICIAN';
export const UPDATE_TECHNICIAN = '[TECHNICIANS APP] UPDATE TECHNICIAN';
export const REMOVE_TECHNICIAN = '[TECHNICIANS APP] REMOVE TECHNICIAN';
export const REMOVE_TECHNICIANS = '[TECHNICIANS APP] REMOVE TECHNICIANS';
export const TOGGLE_STARRED_TECHNICIAN = '[TECHNICIANS APP] TOGGLE STARRED TECHNICIAN';
export const TOGGLE_STARRED_TECHNICIANS = '[TECHNICIANS APP] TOGGLE STARRED TECHNICIANS';
export const SET_TECHNICIANS_STARRED = '[TECHNICIANS APP] SET TECHNICIANS STARRED';

// export const SET_TECHNICIAN_SCHEDULE = '[TECHNICIANS APP] SET TECHNICIAN SCHEDULE';
// export const SET_TECHNICIAN_TIMECARD = '[TECHNICIANS APP] SET TECHNICIAN TIMECARD';

export function getTechnicians(routeParams) {
    const request = axios.get('/api/technicians-app/technicians', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TECHNICIANS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTechnicians(technicianId) {
    return {
        type: TOGGLE_IN_SELECTED_TECHNICIANS,
        technicianId
    }
}


export function selectAllTechnicians() {
    return {
        type: SELECT_ALL_TECHNICIANS
    }
}

export function deSelectAllTechnicians() {
    return {
        type: DESELECT_ALL_TECHNICIANS
    }
}


export function openNewTechnicianDialog(data) {
    return {
        type: OPEN_NEW_TECHNICIAN_DIALOG,
        data
    }
}

export function closeNewTechnicianDialog() {
    return {
        type: CLOSE_NEW_TECHNICIAN_DIALOG
    }
}

export function openEditTechnicianDialog(data) {
    return (dispatch, getState) => {

        const state = getState();
        // window["warn"](state);

        const { Co, Customer, Technician } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Technician?Co=${encodeURIComponent(Co)}&Technician=${encodeURIComponent(Technician)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_TECHNICIAN_DIALOG,
                    data: {
                        ...response.data,
                        Data: {
                            ...response.data.Data,
                            Trips: [],
                        }
                    }
                }),
                dispatch(getTechnicianSchedule(response.data, state.dispatchBoardApp.dispatchBoard.date))
            ])
        );
    };
}


export function getTechnicianSchedule(data, date) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_TECHNICIAN_SCHEDULE,
            date,
            loading: true,
            data: {
                ...data,
                Data: {
                    ...data.Data,
                    Trips: []
                },
            }
        });
        const { Co, Technician } = data;

        const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(Co)}&Date=${encodeURIComponent(date.toLocaleDateString('en-US'))}&Tech=${encodeURIComponent(Technician)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIAN_SCHEDULE,
                    date,
                    loading: false,
                    data: {
                        ...data,
                        Data: {
                            ...data.Data,
                            Trips: response.data
                        },
                    }
                }),
                dispatch(getTechnicianTimecard({
                    ...data,
                    Data: {
                        ...data.Data,
                        Trips: response.data
                    },
                }, date))
            ])
        );
    };
}

export function clockIn(punch) {
    return (dispatch, getState) => {
        const state = getState();

        const request = axios.post(`${window["apiLocation"]}/api/Timecard`, punch);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIAN_TIMECARD,
                    payload: response.data
                }),
            ])
        );
    };
}

export function addLabor(data) {
    return (dispatch, getState) => {
        const state = getState();
        const { timecard, technician } = state.spReducers.technician;

        const request = axios.post(`${window["apiLocation"]}/api/TimecardLabor`, data);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIAN_TIMECARD,
                    payload: {
                        ...timecard,
                        Data: {
                            ...timecard.Data,
                            Labor: timecard.Data.Labor ? [...timecard.Data.Labor, response.data] : [response.data]
                        }
                    },
                }),
            ])
        );
    };
}

export function updateLabor(data) {
    return (dispatch, getState) => {
        const state = getState();
        const { timecard } = state.spReducers.technician;

        const request = axios.put(`${window["apiLocation"]}/api/TimecardLabor?Co=${data.Co}&Employee=${data.Employee}&Date=${encodeURIComponent(data.Date)}&ID=${data.ID}`, data);

        return request.then((response) => {
            const index = _.findIndex(timecard.Data.Labor, { ID: data.ID });
            timecard.Data.Labor.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_TECHNICIAN_TIMECARD,
                    payload: {
                        ...timecard,
                        Data: {
                            ...timecard.Data,
                            Labor: timecard.Data.Labor,
                        }
                    },
                }),
            ])
        }
        );
    };
}

export function removeLabor(data) {
    return (dispatch, getState) => {
        const state = getState();
        const { timecard } = state.spReducers.technician;

        const request = axios.delete(`${window["apiLocation"]}/api/TimecardLabor?Co=${data.Co}&Employee=${data.Employee}&Date=${encodeURIComponent(data.Date)}&ID=${data.ID}`, data);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIAN_TIMECARD,
                    payload: {
                        ...timecard,
                        Data: {
                            ...timecard.Data,
                            Labor: _.reject(timecard.Data.Labor, { ID: data.ID })
                        }
                    },
                }),
            ])
        );
    };
}


export function editPunch(punch) {
    return (dispatch, getState) => {

        const state = getState();
        const { timecard } = state.spReducers.technician;
        const { Punch } = timecard.Data;

        const { Co, Employee, Date } = punch;

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${Co}&Employee=${Employee}&Date=${encodeURIComponent(punch.Date)}&ID=${punch.ID}`, punch);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                const index = _.findIndex(Punch, { ID: punch.ID });
                Punch.splice(index, 1, response.data);
                Promise.all([
                    dispatch({
                        type: SET_TECHNICIAN_TIMECARD,
                        payload: {
                            ...timecard,
                            Data: {
                                ...timecard.Data,
                                Punch,
                            }
                        },
                    }),
                ])
            } else {

            }
        });
    };
}


export function removePunch(punch) {
    return (dispatch, getState) => {

        const state = getState();
        const { timecard } = state.spReducers.technician;
        const { Punch } = timecard.Data;

        const { Co, Employee, Date } = punch;

        const request = axios.delete(`${window["apiLocation"]}/api/Timecard?Co=${Co}&Employee=${Employee}&Date=${encodeURIComponent(punch.Date)}&ID=${punch.ID}`, punch);

        return request.then((response) => {
            if (!response.data || response.data.length === 0) {
                const index = _.findIndex(Punch, { ID: punch.ID });
                Punch.splice(index, 1);
                Promise.all([
                    dispatch({
                        type: SET_TECHNICIAN_TIMECARD,
                        payload: {
                            ...timecard,
                            Data: {
                                ...timecard.Data,
                                Punch,
                            }
                        },
                    }),
                ])
            } else {

            }
        });
    };
}

export function editBreak(punch) {
    return (dispatch, getState) => {

        const state = getState();
        const { timecard } = state.spReducers.technician;
        const { Punch } = timecard.Data;

        const { Co, Employee, Date } = punch;

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.StartRec.Co}&Employee=${punch.StartRec.Employee}&Date=${encodeURIComponent(punch.StartRec.Date)}&ID=${punch.StartRec.ID}`, punch.StartRec);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                Punch.splice(index, 1, response.data);
                const request2 = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                return request2.then((response2) => {
                    if (!response2.data.ErrMsg || response2.data.ErrMsg.length === 0) {
                        const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                        Punch.splice(index2, 1, response2.data);
                        Promise.all([
                            dispatch({
                                type: SET_TECHNICIAN_TIMECARD,
                                payload: {
                                    ...timecard,
                                    Data: {
                                        ...timecard.Data,
                                        Punch,
                                    }
                                },
                            }),
                        ])
                    } else {

                    }
                })
            } else {

            }
        }
        );
    };
}

export function editTimecard(tcData) {
    return (dispatch, getState) => {

        const state = getState();
        const { timecard } = state.spReducers.technician;

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${tcData.Co}&Employee=${tcData.Employee}&Date=${encodeURIComponent(tcData.Date)}`, tcData);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                Promise.all([
                    dispatch({
                        type: SET_TECHNICIAN_TIMECARD,
                        payload: {
                            ...response.data,
                            Data: {
                                ...timecard.Data,
                            }
                        },
                    }),
                ])
            } else {

            }
        }
        );
    };
}

export function removeBreak(punch) {
    return (dispatch, getState) => {

        const state = getState();
        const { timecard } = state.spReducers.technician;
        const { Punch } = timecard.Data;

        const startRec = {
            ...punch.StartRec,
            TimeOut: punch.EndRec.TimeOut,
        };

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.StartRec.Co}&Employee=${punch.StartRec.Employee}&Date=${encodeURIComponent(punch.StartRec.Date)}&ID=${punch.StartRec.ID}`, startRec);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                Punch.splice(index, 1, response.data);
                const request2 = axios.delete(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                return request2.then((response2) => {
                    if (!response2.data || response2.data.length === 0) {
                        const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                        Punch.splice(index2, 1);
                        Promise.all([
                            dispatch({
                                type: SET_TECHNICIAN_TIMECARD,
                                payload: {
                                    ...timecard,
                                    Data: {
                                        ...timecard.Data,
                                        Punch,
                                    }
                                },
                            }),
                        ])
                    } else {

                    }
                })
            } else {

            }
        }
        );
    };
}

export function getTechnicianTimecard(data, date) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_TECHNICIAN_TIMECARD,
            date,
            loading: true,
            data: {
                ...data,
                Data: {
                    ...data.Data,
                    Timecard: null,
                },
            }
        });
        const { Co, Employee } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Timecard?Co=${encodeURIComponent(Co)}&Date=${encodeURIComponent(date.toLocaleDateString('en-US'))}&Employee=${encodeURIComponent(Employee)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIAN_TIMECARD,
                    date,
                    loading: false,
                    data: {
                        ...data,
                        Data: {
                            ...data.Data,
                            Timecard: response.data,
                        },
                    }
                }),
            ])
        );
    };
}

export function updateEditTechnicianDialog(data) {
    return (dispatch, getState) => {

        const { Co, Customer, Technician } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Technician?Co=${encodeURIComponent(Co)}&Technician=${encodeURIComponent(Technician)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DATA_EDIT_TECHNICIAN_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditTechnicianDialog() {
    return {
        type: CLOSE_EDIT_TECHNICIAN_DIALOG
    }
}

export function addTechnician(newTechnician) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Technician`,
            newTechnician
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_EDIT_TECHNICIAN_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Technician ID ${response.data.Technician} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateTechnician(technician) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/Technician?Co=${encodeURIComponent(technician.Co)}&Technician=${encodeURIComponent(technician.Technician)}`,
            technician
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_TECHNICIAN_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Technician ID ${response.data.Technician} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeTechnician(technicianId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().techniciansApp.technicians;

        const request = axios.post('/api/technicians-app/remove-technician', {
            technicianId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TECHNICIAN
                })
            ]).then(() => dispatch(getTechnicians(routeParams)))
        );
    };
}


export function removeTechnicians(technicianIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().techniciansApp.technicians;

        const request = axios.post('/api/technicians-app/remove-technicians', {
            technicianIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TECHNICIANS
                }),
                dispatch({
                    type: DESELECT_ALL_TECHNICIANS
                })
            ]).then(() => dispatch(getTechnicians(routeParams)))
        );
    };
}

export function toggleStarredTechnician(technicianId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().techniciansApp.technicians;

        const request = axios.post('/api/technicians-app/toggle-starred-technician', {
            technicianId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TECHNICIAN
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTechnicians(routeParams)))
        );
    };
}

export function toggleStarredTechnicians(technicianIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().techniciansApp.technicians;

        const request = axios.post('/api/technicians-app/toggle-starred-technicians', {
            technicianIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TECHNICIANS
                }),
                dispatch({
                    type: DESELECT_ALL_TECHNICIANS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTechnicians(routeParams)))
        );
    };
}

export function setTechniciansStarred(technicianIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().techniciansApp.technicians;

        const request = axios.post('/api/technicians-app/set-technicians-starred', {
            technicianIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIANS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TECHNICIANS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTechnicians(routeParams)))
        );
    };
}

export function setTechniciansUnstarred(technicianIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().techniciansApp.technicians;

        const request = axios.post('/api/technicians-app/set-technicians-unstarred', {
            technicianIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TECHNICIANS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TECHNICIANS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTechnicians(routeParams)))
        );
    };
}


export function getTechnicianPerformance(data, end, start) {
    const { Co, Technician } = data;

    const request = axios.get(`${window["apiLocation"]}/api/TeamPerformance?Co=${encodeURIComponent(Co)}${start ? `&Start=${start.replace(/\//g, '-')}` : ''}&End=${encodeURIComponent(end.replace(/\//g, '-'))}&TeamLead=${encodeURIComponent(Technician)}`);


    return (dispatch, getState) => {
        // const state = getState();
        dispatch({
            type: SET_TECHNICIAN_PERFORMANCE,
            payload: []
        });
        request.then((response) => {
            response.data.map((value, index) => {
                value.Employee = Number(value.Employee);
                value.Team = Number(value.Team);
                value.Qty = Number(value.Qty);
                value.Cost = Number(value.Cost);
                value.Revenue = Number(value.Revenue);
                value.Date = new Date(value.Date);
            });
            Promise.all([
                dispatch({
                    type: GET_TECHNICIAN_PERFORMANCE,
                    payload: response.data
                }),
            ]);
        });
    }
}