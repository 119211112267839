import axios from 'axios/index';
import { startSignalR, set } from 'store/actions';
import { initiateSip } from 'main/dialer/store/actions/dialer.actions';

export const SET_USER_ROLE_DATA = '[SPCONNECTION] SET USER ROLE DATA';
export const GET_USER_ROLE_DATA = '[SPCONNECTION] GET USER ROLE DATA';
export const SET_USER_ROLE_LIST_DATA = '[SPCONNECTION] SET USER ROLE LIST DATA';
export const GET_USER_ROLE_LIST_DATA = '[SPCONNECTION] GET USER ROLE LIST DATA';
export const SUBSCRIBE_USER_ROLE_LIST = '[SPCONNECTION] SUBSCRIBE USER ROLE LIST';

export function getUserRoleListData(unm) {
    const request = axios.get(`${window["apiLocation"]}/api/UserRole`);

    return (dispatch) => {
        request.then((response) => {
            Promise.all([
                dispatch({
                    type: GET_USER_ROLE_LIST_DATA,
                    payload: response.data
                })
            ]).then(() => {
                // dispatch(startSignalR(unm));
                // dispatch(initiateSip(unm));
            });
        });
    }
}

export function getUserRoleData(UserName) {
    const request = axios.get(`${window["apiLocation"]}/api/UserRole`, {
        params: {
            UserName
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_USER_ROLE_DATA,
                payload: response.data
            })
        });
    }
}

export function setUserRoleData(data) {
    return {
        type: SET_USER_ROLE_DATA,
        payload: data
    };
}