import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class DialGroupMembersList extends Component {

    state = {
        selectedDialGroupMembersMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedDialGroupMemberMenu = (event) => {
        this.setState({ selectedDialGroupMembersMenu: event.currentTarget });
    };

    closeSelectedDialGroupMembersMenu = () => {
        this.setState({ selectedDialGroupMembersMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, dialGroupMembers, user, searchText, selectedDialGroupMemberIds, selectAllDialGroupMembers, deSelectAllDialGroupMembers, toggleInSelectedDialGroupMembers, openEditDialGroupMemberDialog, removeDialGroupMembers, removeDialGroupMember, toggleStarredDialGroupMember, setDialGroupMembersUnstarred, setDialGroupMembersStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedDialGroupMembersData ? (!this.props.dialGroupMemberData ? [] : this.props.dialGroupMemberData) : this.props.selectedDialGroupMembersData), searchText);
        const { selectedDialGroupMembersMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">account_circle</Icon>Dial Group Members</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditDialGroupMemberDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Hunt Group",
                                accessor: "GroupID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Dial Group",
                                accessor: "DialID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Member",
                                accessor: "ID",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Phone Number",
                                accessor: "PhoneNumber",
                                minWidth: 200,
                            },
                            {
                                Header: "Created By",
                                accessor: "AddedBy",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Created Date",
                                accessor: "AddedDate",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Dial Group Members found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDialGroupMembers: Actions.getDialGroupMembers,
        getUserData: Actions.getUserData,
        toggleInSelectedDialGroupMembers: Actions.toggleInSelectedDialGroupMembers,
        selectAllDialGroupMembers: Actions.selectAllDialGroupMembers,
        deSelectAllDialGroupMembers: Actions.deSelectAllDialGroupMembers,
        openEditDialGroupMemberDialog: Actions.openEditDialGroupMemberDialog,
        removeDialGroupMembers: Actions.removeDialGroupMembers,
        removeDialGroupMember: Actions.removeDialGroupMember,
        toggleStarredDialGroupMember: Actions.toggleStarredDialGroupMember,
        toggleStarredDialGroupMembers: Actions.toggleStarredDialGroupMembers,
        setDialGroupMembersStarred: Actions.setDialGroupMembersStarred,
        setDialGroupMembersUnstarred: Actions.setDialGroupMembersUnstarred
    }, dispatch);
}

function mapStateToProps({ dialGroupMembersApp, spReducers }) {
    return {
        dialGroupMembers: dialGroupMembersApp.dialGroupMembers.entities,
        selectedDialGroupMemberIds: dialGroupMembersApp.dialGroupMembers.selectedDialGroupMemberIds,
        searchText: dialGroupMembersApp.dialGroupMembers.searchText,
        user: dialGroupMembersApp.user,
        dialGroupMemberData: spReducers.dialGroupMembers
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DialGroupMembersList)));
