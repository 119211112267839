import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles/index';
import { AppBar, Avatar, Badge, Button, Card, CardHeader, CardActions, CardContent, Collapse, Chip, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Stepper, Step, StepButton, Tabs, Tab, TextField, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import moment from 'moment';
import _ from '@lodash';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {

    },
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    avatarTech: {
        backgroundColor: theme.palette.primary[500],
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    card: {
        marginTop: 0,
        marginBottom: 8,
        cursor: 'pointer',
        width: '100%',
        border: '1px solid lightgrey',
    },
    addButton: {
        marginTop: 0,
        marginBottom: 8,
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        boxShadow: 'none'
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    tabs: {
        width: '100%',
    },
    tab: {
        minWidth: '25%',
        maxWidth: '25%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    campaign: {
        width: 'auto',
        top: 0,
        right: -5,
        marginTop: 5,
        borderRadius: 4,
        padding: 10,
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
    },
    tripAdminTab: {
        minWidth: '20%',
        maxWidth: '20%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    container: {
        padding: 0,
    },
    trip: {
        minWidth: 300,
    },
    tripTabContent: {
        minHeight: 175,
        maxHeight: 175,
        overflow: 'auto',
    },
    formControl: {
        marginBottom: 12
    },
    footer: {
        top: 'auto',
        bottom: 0,
    },
    scopeBadge: {
        top: 5,
        right: 2,
    },
    tripBadge: {
        top: 35,
        right: 35,
        background: '#3f3f3f',
    },
    tripBadgeIcon: {
        fontSize: 14,
        marginTop: 1,
    },
    tripCountBadge: {
        top: 15,
        right: -7,
        height: 17,
        minWidth: 17,
        fontSize: '1rem',
        border: '2px solid #3f3f3f',
        paddingTop: 2,
    },
    input: {
        height: 36,
    },
    inputLabel: {
        fontSize: 12,
        '&[data-shrink="false"]': {
            marginTop: -8,
        },
        '&[data-shrink="true"]': {
            marginTop: 0
        }
    },
    cardContent: {
        padding: 12,
        borderTop: '1px solid lightgrey',
        paddingBottom: '4px !important',
    },
    suggestedContent: {
        padding: 12,
        paddingBottom: '4px !important',
    },
    addButtonContent: {
        padding: 12,
        border: '1px solid lightgrey',
        borderRadius: 4,
        paddingBottom: '4px !important',
    },
    updateButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});

const initialState = {
    ID: null,
    Co: null,
    Employee: null,
    Type: 'W',
    LaborType: null,
    WorkOrder: null,
    Scope: null,
    Description: null,
    Qty: null,
    HourlyRate: null,
    Total: null,
    Status: 0,
    Notes: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
};

class TimecardLabor extends Component {
    state = initialState;

    componentDidMount = () => {
        const { data, trips } = this.props;
        if (!trips || trips.length < 1) {
            initialState.Type = 'I';
        }
        if (data && !_.isEqual({ ...initialState, ...data }, this.state)) {
            this.setState({ ...initialState, ...data });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, trips } = this.props;
        if (!_.isEqual(trips, prevProps.trips)) {
            if (!trips || trips.length < 1) {
                initialState.Type = 'I';
            } else {
                initialState.Type = 'W';
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleOpen = () => {
        let { open } = this.state;
        open = !open;
        this.setState({ open }, () => {
            if (this.props.onOpen) {
                this.props.onOpen(this.state.open);
            }
        });
    }

    handleQtyChange = (event) => {
        const Qty = event.target.value;
        const { HourlyRate } = this.state;
        this.setState({ ...this.state, Qty, Total: (Qty || 0) * (HourlyRate || 0) });
    }

    canBeSubmitted = () => {
        const { Co, Employee, Type, Qty, Description, LaborType, WorkOrder, Scope } = this.state;

        return Type && Qty && !isNaN(Qty) && ((WorkOrder && Scope) || Description) && LaborType;
    }

    stop = (event) => {
        event.stopPropagation();
    }

    render() {
        const { classes, data, laborTypes, dialog, trips, submitted, approved, employee, date, approve, source, suggested, securables, hideIcons } = this.props;
        const { Co, Employee, open } = this.state;
        // filter to only WOs with started trips
        const started = _.filter(trips, (o) => { return o.Status > 3 });
        const workOrders = _.uniqBy(started, 'WorkOrder');
        const workOrder = started ? _.find(workOrders, { WorkOrder: this.state.WorkOrder }) : null;
        const scopes = workOrder && workOrder.Data && workOrder.Data.WorkOrder && workOrder.Data.WorkOrder.Data ? _.filter(workOrder.Data.WorkOrder.Data.Scopes, (o) => _.filter(trips, (t) => _.findIndex(t.Data.Assignments, { WorkOrder: o.WorkOrder, Scope: o.Scope }) > -1).length > 0) : null;
        const scope = scopes ? _.find(scopes, { Scope: this.state.Scope }) : null;
        const accessLevel = _.find(securables, { Securable: "timecards" });
        const fullAccess = (accessLevel && accessLevel.AccessLevel === "F");

        return (
            <Card /*onClick={this.stop} onMouseDown={this.stop} onMouseMove={this.stop}*/ className={classNames(data && (data.ID || data.OfflineID) ? classes.card : classes.addButton, "dispatch-work-order")}>
                <CardHeader onClick={this.handleOpen} className={classNames("p-0 pl-12 pr-12")}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-4", title: classes.title, subheader: classes.subheader, }
                    }
                    avatar={
                        hideIcons ? null : (suggested && data) ? <Icon className="text-20 mt-6" color="primary">timer</Icon> : (!data || (!data.ID && !data.OfflineID)) ? <Icon className="text-20 mt-6" color="primary">add_circle_outline</Icon> : data.Type === 'W' ? <Icon className="text-20 mt-6" color={data.Status === 2 ? "secondary" : "primary"}>{data.Status === 2 ? "cloud_done" : data.Status === 1 ? "cloud_upload" : "assignment"}</Icon> : data.Type !== 'W' && <Icon className="text-20 mt-6" color={data.Status === 2 ? "secondary" : "primary"}>{data.Status === 2 ? "cloud_done" : data.Status === 1 ? "cloud_upload" : "assignment_ind"}</Icon>
                    }
                    action={
                        (data && (data.ID || data.OfflineID || suggested)) &&
                        <Typography className="flex text-12 mt-4">
                            {Boolean(this.state.Qty) && <span className="mt-4 mr-4 font-bold">{this.state.Qty || data.Qty} Hours</span>}
                            {!hideIcons && <Icon color="action" style={{ marginTop: 2 }} className="text-24 mr-8 mt-4 ml-4">{!open ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>}
                        </Typography>

                    }
                    title={
                        (!data || (!suggested && (!data.ID && !data.OfflineID))) ?
                            <Typography className="flex text-12 font-bold">
                                <span style={{ marginTop: 2 }}>Add Labor</span>
                            </Typography> :
                            <Typography className="flex text-12 font-bold">
                                {data.Type === 'W' ? <span style={{ marginTop: 2 }}>{`Work Order #${data.WorkOrder} Scope #${data.Scope}`}</span> : <span style={{ marginTop: 2 }}>{data.Description}</span>}
                            </Typography>
                    }
                />
                {open &&
                    <FuseAnimate animation="transition.slideDownIn">
                        <CardContent classes={{ root: suggested ? classes.suggestedContent : data ? classes.cardContent : classes.addButtonContent }}>

                            <div className="sm:flex">
                                <TextField
                                    className={classNames(classes.formControl, "sm:w-1/3")}
                                    label="Type"
                                    id="type"
                                    name="Type"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel
                                    }}
                                    value={this.state.Type}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    disabled={this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                    fullWidth
                                >
                                    <MenuItem disabled={!started || started.length < 1} value="W">
                                        Work Order
                                    </MenuItem>
                                    <MenuItem value="I">
                                        Indirect
                                    </MenuItem>
                                    <MenuItem disabled={true} value="O">
                                        Other
                                    </MenuItem>
                                </TextField>
                                <div className="hidden sm:block min-w-8 pt-20">
                                </div>
                                {this.state.Type === 'W' || (this.state.Type === 'O' && this.state.WorkOrder) ?
                                    <div className="sm:flex w-full">
                                        <TextField
                                            className={classes.formControl}
                                            label="Work Order"
                                            id="workOrder"
                                            name="WorkOrder"
                                            InputProps={{
                                                className: classes.input,
                                                type: 'number',
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputLabel,
                                                shrink: Boolean(this.state.WorkOrder)
                                            }}
                                            value={this.state.WorkOrder}
                                            onChange={this.handleChange}
                                            disabled={this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            {workOrders && workOrders.length > 0 && (this.state.WorkOrder ? _.findIndex(workOrders, { WorkOrder: this.state.WorkOrder }) > -1 : 1 === 1) ? workOrders.map((value, index) =>
                                                <MenuItem key={index} value={value.WorkOrder}>
                                                    {`${value.WorkOrder} - ${value.Data.WorkOrder.Description.substr(0, 15)}...`}
                                                </MenuItem>
                                            ) :
                                                !this.state.WorkOrder ?
                                                    <MenuItem value={null}>
                                                        No Work Orders Available
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value={this.state.WorkOrder}>
                                                        {this.state.WorkOrder}
                                                    </MenuItem>
                                            }
                                        </TextField>
                                        <div className="hidden sm:block min-w-8 pt-20">
                                        </div>
                                        <TextField
                                            className={classNames(classes.formControl, "sm:w-1/2")}
                                            // className={classes.formControl}
                                            label="Scope"
                                            id="scope"
                                            name="Scope"
                                            InputProps={{
                                                className: classes.input,
                                                type: 'number',
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputLabel,
                                                shrink: Boolean(this.state.Scope)
                                            }}
                                            value={this.state.Scope}
                                            onChange={this.handleChange}
                                            disabled={this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            {scopes ? scopes.map((value, index) =>
                                                <MenuItem key={index} value={value.Scope}>
                                                    {`${value.Scope} - ${value.Description.substr(0, 8)}...`}
                                                </MenuItem>
                                            ) :
                                                !this.state.Scope ?
                                                    <MenuItem value={null}>
                                                        Please Select Work Order
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value={this.state.Scope}>
                                                        {this.state.Scope}
                                                    </MenuItem>
                                            }
                                        </TextField>
                                    </div> : this.state.Type === 'O' && this.state.Agreement ?
                                        <div className="sm:flex w-full">
                                            <TextField
                                                className={classes.formControl}
                                                label="Agreement"
                                                id="agreement"
                                                name="Agreement"
                                                InputProps={{
                                                    className: classes.input,
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel,
                                                    shrink: Boolean(this.state.Agreement)
                                                }}
                                                value={this.state.Agreement || ''}
                                                disabled={true}
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <div className="hidden sm:block min-w-8 pt-20">
                                            </div>
                                            <TextField
                                                className={classNames(classes.formControl, "sm:w-1/2")}
                                                // className={classes.formControl}
                                                label="Cycle"
                                                id="cycle"
                                                name="Cycle"
                                                InputProps={{
                                                    className: classes.input,
                                                    type: 'number',
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel,
                                                    shrink: Boolean(this.state.Cycle)
                                                }}
                                                value={this.state.Cycle || ''}
                                                disabled={true}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div> :
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            InputProps={{
                                                className: classes.input,
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputLabel,
                                                shrink: Boolean(this.state.Description)
                                            }}
                                            value={this.state.Description || ''}
                                            onChange={this.handleChange}
                                            disabled={this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                            variant="outlined"
                                            fullWidth
                                        />
                                }
                            </div>
                            <div className="sm:flex">
                                <TextField
                                    className={classNames(classes.formControl, "sm:w-1/3")}
                                    // className={classes.formControl}
                                    label="Qty"
                                    id="qty"
                                    name="Qty"
                                    InputProps={{
                                        className: classes.input,
                                        type: 'number',
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel,
                                        shrink: Boolean(this.state.Qty)
                                    }}
                                    value={this.state.Qty || ''}
                                    onChange={this.handleQtyChange}
                                    disabled={this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                    variant="outlined"
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-8 pt-20">
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Labor Type"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel,
                                        shrink: Boolean(this.state.LaborType)
                                    }}
                                    options={
                                        scope ?
                                            _.filter(laborTypes, { BusinessUnit: scope.BusinessUnit, Division: scope.Division, Type: this.state.Type })
                                            :
                                            _.filter(laborTypes, { BusinessUnit: employee.DefaultBusinessUnit, Division: employee.DefaultDivision, Type: this.state.Type })

                                    }
                                    menuItemComponent={(value, index) => {
                                        return (
                                            <MenuItem key={index} value={value.LaborType}>
                                                {value.Description}
                                            </MenuItem>
                                        );
                                    }}
                                    disabled={this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                    portal={true}
                                    value={this.state.LaborType}
                                    onSelect={(option) => this.setState({ ...this.state, LaborType: option.LaborType, HourlyRate: option.StandardUnitCost, Total: (this.state.Qty || 0) * option.StandardUnitCost })}
                                    required
                                />
                                {/* <TextField
                                    className={classes.formControl}
                                    label="Labor Type"
                                    id="laborType"
                                    name="LaborType"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel,
                                        shrink: Boolean(this.state.LaborType)
                                    }}
                                    value={this.state.LaborType}
                                    onChange={this.handleChange}
                                    disabled={this.state.Type === 'O' || approved === 'Y' || this.state.ApprovedYN === 'Y'}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    {laborTypes.map((value, index) => {
                                        if (scope) {
                                            if (scope.BusinessUnit === value.BusinessUnit && scope.Division === value.Division && value.Type === this.state.Type) {
                                                return (
                                                    <MenuItem key={index} value={value.LaborType}>
                                                        {value.Description}
                                                    </MenuItem>
                                                );
                                            }
                                        } else {
                                            if (employee && employee.DefaultBusinessUnit && employee.DefaultDivision && employee.DefaultBusinessUnit === value.BusinessUnit && employee.DefaultDivision === value.Division && value.Type === this.state.Type) {
                                                return (
                                                    <MenuItem key={index} value={value.LaborType}>
                                                        {value.Description}
                                                    </MenuItem>
                                                );
                                            }
                                        }
                                    }
                                    )}
                                </TextField> */}
                            </div>
                            {data && (data.ID || data.OfflineID) ?
                                <div className="w-full flex">
                                    <Button
                                        className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.props.updateLabor(this.state, source);
                                            this.setState({ ...this.state, open: false });
                                        }}
                                        disabled={!this.canBeSubmitted() || this.state.Type === 'O' || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        className={classNames(classes.formControl, classes.deleteButton)}
                                        variant="contained"
                                        onClick={() => {
                                            this.props.removeLabor(this.state, source);
                                        }}
                                        disabled={data.Status > 0 || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </div> :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        var rec = this.state;
                                        window["log"](rec);
                                        const dt = moment(moment(date)).local().toDate().toLocaleDateString('en-US');
                                        const { Co, Employee } = employee;
                                        this.props.addLabor({ ...rec, Co, Employee, Date: dt, open: false }, source);
                                        if (this.props.onSubmitted) {
                                            this.props.onSubmitted();
                                        }
                                        this.setState({ ...initialState });
                                    }}
                                    className="mb-8 w-full"
                                    disabled={!this.canBeSubmitted() || (!fullAccess && (approved === 'Y' || this.state.ApprovedYN === 'Y' || (!approve && submitted === 'Y')))}
                                >
                                    Add
                                </Button>
                            }
                        </CardContent>
                    </FuseAnimate>
                }
            </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addLabor: Actions.addLabor,
        updateLabor: Actions.updateLabor,
        removeLabor: Actions.removeLabor,
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, timecardsApp }) {
    return {
        user: dispatchBoardApp.user,
        laborTypes: spReducers.laborTypes,
        dialog: timecardsApp.timecards.timecardDialog,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TimecardLabor)));
