import _ from '@lodash';

export const OPEN_EDIT_INCOMING_CALL_DIALOG = '[CUSTOMERS APP] OPEN EDIT INCOMING CALL DIALOG';
export const CLOSE_EDIT_INCOMING_CALL_DIALOG = '[CUSTOMERS APP] CLOSE EDIT INCOMING CALL DIALOG';

export function openPreloadIncomingCallDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_INCOMING_CALL_DIALOG,
            data: data
        })
    }
}

export function closeEditIncomingCallDialog() {
    return {
        type: CLOSE_EDIT_INCOMING_CALL_DIALOG
    }
}