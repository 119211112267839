import HuntGroupsApp from 'main/content/apps/hunt-groups/HuntGroupsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const HuntGroupsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/hunt-groups/:id',
            component: HuntGroupsApp
        },
        {
            path     : '/apps/hunt-groups',
            component: () => <Redirect to="/apps/hunt-groups/all"/>
        }
    ]
};
