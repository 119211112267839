import axios from 'axios/index';
import { getUserData } from 'main/content/apps/rate-templates/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_RATE_TEMPLATES = '[RATE TEMPLATES APP] GET RATE TEMPLATES';
export const SET_SEARCH_TEXT = '[RATE TEMPLATES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_RATE_TEMPLATES = '[RATE TEMPLATES APP] TOGGLE IN SELECTED RATE TEMPLATES';
export const SELECT_ALL_RATE_TEMPLATES = '[RATE TEMPLATES APP] SELECT ALL RATE TEMPLATES';
export const DESELECT_ALL_RATE_TEMPLATES = '[RATE TEMPLATES APP] DESELECT ALL RATE TEMPLATES';
export const OPEN_NEW_RATE_TEMPLATE_DIALOG = '[RATE TEMPLATES APP] OPEN NEW RATE TEMPLATE DIALOG';
export const CLOSE_NEW_RATE_TEMPLATE_DIALOG = '[RATE TEMPLATES APP] CLOSE NEW RATE TEMPLATE DIALOG';
export const OPEN_EDIT_RATE_TEMPLATE_DIALOG = '[RATE TEMPLATES APP] OPEN EDIT RATE TEMPLATE DIALOG';
export const CLOSE_EDIT_RATE_TEMPLATE_DIALOG = '[RATE TEMPLATES APP] CLOSE EDIT RATE TEMPLATE DIALOG';
export const ADD_RATE_TEMPLATE = '[RATE TEMPLATES APP] ADD RATE TEMPLATE';
export const UPDATE_RATE_TEMPLATE = '[RATE TEMPLATES APP] UPDATE RATE TEMPLATE';
export const REMOVE_RATE_TEMPLATE = '[RATE TEMPLATES APP] REMOVE RATE TEMPLATE';
export const OPEN_NEW_RATE_TEMPLATE_LABOR_DIALOG = '[RATE TEMPLATE LABORS APP] OPEN NEW RATE TEMPLATE LABOR DIALOG';
export const CLOSE_NEW_RATE_TEMPLATE_LABOR_DIALOG = '[RATE TEMPLATE LABORS APP] CLOSE NEW RATE TEMPLATE LABOR DIALOG';
export const OPEN_EDIT_RATE_TEMPLATE_LABOR_DIALOG = '[RATE TEMPLATE LABORS APP] OPEN EDIT RATE TEMPLATE LABOR DIALOG';
export const CLOSE_EDIT_RATE_TEMPLATE_LABOR_DIALOG = '[RATE TEMPLATE LABORS APP] CLOSE EDIT RATE TEMPLATE LABOR DIALOG';
export const ADD_RATE_TEMPLATE_LABOR = '[RATE TEMPLATE LABORS APP] ADD RATE TEMPLATE LABOR';
export const UPDATE_RATE_TEMPLATE_LABOR = '[RATE TEMPLATE LABORS APP] UPDATE RATE TEMPLATE LABOR';
export const REMOVE_RATE_TEMPLATE_LABOR = '[RATE TEMPLATE LABORS APP] REMOVE RATE TEMPLATE LABOR';
export const OPEN_NEW_RATE_TEMPLATE_MATERIAL_DIALOG = '[RATE TEMPLATE MATERIALS APP] OPEN NEW RATE TEMPLATE MATERIAL DIALOG';
export const CLOSE_NEW_RATE_TEMPLATE_MATERIAL_DIALOG = '[RATE TEMPLATE MATERIALS APP] CLOSE NEW RATE TEMPLATE MATERIAL DIALOG';
export const OPEN_EDIT_RATE_TEMPLATE_MATERIAL_DIALOG = '[RATE TEMPLATE MATERIALS APP] OPEN EDIT RATE TEMPLATE MATERIAL DIALOG';
export const CLOSE_EDIT_RATE_TEMPLATE_MATERIAL_DIALOG = '[RATE TEMPLATE MATERIALS APP] CLOSE EDIT RATE TEMPLATE MATERIAL DIALOG';
export const ADD_RATE_TEMPLATE_MATERIAL = '[RATE TEMPLATE MATERIALS APP] ADD RATE TEMPLATE MATERIAL';
export const UPDATE_RATE_TEMPLATE_MATERIAL = '[RATE TEMPLATE MATERIALS APP] UPDATE RATE TEMPLATE MATERIAL';
export const REMOVE_RATE_TEMPLATE_MATERIAL = '[RATE TEMPLATE MATERIALS APP] REMOVE RATE TEMPLATE MATERIAL';
export const REMOVE_RATE_TEMPLATES = '[RATE TEMPLATES APP] REMOVE RATE TEMPLATES';
export const TOGGLE_STARRED_RATE_TEMPLATE = '[RATE TEMPLATES APP] TOGGLE STARRED RATE TEMPLATE';
export const TOGGLE_STARRED_RATE_TEMPLATES = '[RATE TEMPLATES APP] TOGGLE STARRED RATE TEMPLATES';
export const SET_RATE_TEMPLATES_STARRED = '[RATE TEMPLATES APP] SET RATE TEMPLATES STARRED ';

export function getRateTemplates(routeParams) {
    const request = axios.get('/api/rateTemplates-app/rateTemplates', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_RATE_TEMPLATES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedRateTemplates(rateTemplateId) {
    return {
        type: TOGGLE_IN_SELECTED_RATE_TEMPLATES,
        rateTemplateId
    }
}


export function selectAllRateTemplates() {
    return {
        type: SELECT_ALL_RATE_TEMPLATES
    }
}

export function deSelectAllRateTemplates() {
    return {
        type: DESELECT_ALL_RATE_TEMPLATES
    }
}


export function openNewRateTemplateDialog() {
    return {
        type: OPEN_NEW_RATE_TEMPLATE_DIALOG
    }
}

export function closeNewRateTemplateDialog() {
    return {
        type: CLOSE_NEW_RATE_TEMPLATE_DIALOG
    }
}

export function openEditRateTemplateDialog(data) {
    return {
        type: OPEN_EDIT_RATE_TEMPLATE_DIALOG,
        data
    }
}

export function closeEditRateTemplateDialog() {
    return {
        type: CLOSE_EDIT_RATE_TEMPLATE_DIALOG
    }
}

export function addRateTemplate(newRateTemplate) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/RateTemplate`,
            newRateTemplate
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_RATE_TEMPLATE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Rate Template ${newRateTemplate.RateTemplate} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateRateTemplate(rateTemplate) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/RateTemplate?Co=${encodeURIComponent(rateTemplate.Co)}&BusinessUnit=${encodeURIComponent(rateTemplate.BusinessUnit)}&Division=${encodeURIComponent(rateTemplate.Division)}&RateTemplate=${encodeURIComponent(rateTemplate.RateTemplate)}`,
            rateTemplate
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_RATE_TEMPLATE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Rate Template ${rateTemplate.RateTemplate} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeRateTemplate(rateTemplateId) {
    return (dispatch, getState) => {

        const request = axios.post('/api/rateTemplates-app/remove-rateTemplate', {
            rateTemplateId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_RATE_TEMPLATE
                })
            ])
        );
    };
}


export function removeRateTemplates(rateTemplateIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().rateTemplatesApp.rateTemplates;

        const request = axios.post('/api/rateTemplates-app/remove-rateTemplates', {
            rateTemplateIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_RATE_TEMPLATES
                }),
                dispatch({
                    type: DESELECT_ALL_RATE_TEMPLATES
                })
            ]).then(() => dispatch(getRateTemplates(routeParams)))
        );
    };
}

export function toggleStarredRateTemplate(rateTemplateId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().rateTemplatesApp.rateTemplates;

        const request = axios.post('/api/rateTemplates-app/toggle-starred-rateTemplate', {
            rateTemplateId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_RATE_TEMPLATE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getRateTemplates(routeParams)))
        );
    };
}

export function toggleStarredRateTemplates(rateTemplateIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().rateTemplatesApp.rateTemplates;

        const request = axios.post('/api/rateTemplates-app/toggle-starred-rateTemplates', {
            rateTemplateIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_RATE_TEMPLATES
                }),
                dispatch({
                    type: DESELECT_ALL_RATE_TEMPLATES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getRateTemplates(routeParams)))
        );
    };
}

export function setRateTemplatesStarred(rateTemplateIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().rateTemplatesApp.rateTemplates;

        const request = axios.post('/api/rateTemplates-app/set-rateTemplates-starred', {
            rateTemplateIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_RATE_TEMPLATES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_RATE_TEMPLATES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getRateTemplates(routeParams)))
        );
    };
}

export function setRateTemplatesUnstarred(rateTemplateIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().rateTemplatesApp.rateTemplates;

        const request = axios.post('/api/rateTemplates-app/set-rateTemplates-unstarred', {
            rateTemplateIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_RATE_TEMPLATES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_RATE_TEMPLATES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getRateTemplates(routeParams)))
        );
    };
}

/*---------------------------------------------------------------------------------------------------*/


export function openNewRateTemplateLaborDialog(data) {
    return {
        type: OPEN_NEW_RATE_TEMPLATE_LABOR_DIALOG,
        data
    }
}

export function closeNewRateTemplateLaborDialog() {
    return {
        type: CLOSE_NEW_RATE_TEMPLATE_LABOR_DIALOG
    }
}

export function openEditRateTemplateLaborDialog(data) {
    return {
        type: OPEN_EDIT_RATE_TEMPLATE_LABOR_DIALOG,
        data
    }
}

export function closeEditRateTemplateLaborDialog() {
    return {
        type: CLOSE_EDIT_RATE_TEMPLATE_LABOR_DIALOG
    }
}

export function addRateTemplateLabor(newRateTemplateLabor) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/RateTemplateLabor`,
            newRateTemplateLabor
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_RATE_TEMPLATE_LABOR
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Labor Type ${newRateTemplateLabor.LaborType} rate has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function updateRateTemplateLabor(rateTemplateLabor) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/RateTemplateLabor?Co=${encodeURIComponent(rateTemplateLabor.Co)}&BusinessUnit=${encodeURIComponent(rateTemplateLabor.BusinessUnit)}&Division=${encodeURIComponent(rateTemplateLabor.Division)}&RateTemplate=${encodeURIComponent(rateTemplateLabor.RateTemplate)}&LaborType=${encodeURIComponent(rateTemplateLabor.LaborType)}`,
            rateTemplateLabor
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_RATE_TEMPLATE_LABOR
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Labor Type ${rateTemplateLabor.LaborType} rate has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function removeRateTemplateLabor(rateTemplateLabor) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/RateTemplateLabor?Co=${encodeURIComponent(rateTemplateLabor.Co)}&BusinessUnit=${encodeURIComponent(rateTemplateLabor.BusinessUnit)}&Division=${encodeURIComponent(rateTemplateLabor.Division)}&RateTemplate=${encodeURIComponent(rateTemplateLabor.RateTemplate)}&LaborType=${encodeURIComponent(rateTemplateLabor.LaborType)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_RATE_TEMPLATE_LABOR
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Labor Type ${rateTemplateLabor.LaborType} rate has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

/*---------------------------------------------------------------------------------------------------*/


export function openNewRateTemplateMaterialDialog(data) {
    return {
        type: OPEN_NEW_RATE_TEMPLATE_MATERIAL_DIALOG,
        data
    }
}

export function closeNewRateTemplateMaterialDialog() {
    return {
        type: CLOSE_NEW_RATE_TEMPLATE_MATERIAL_DIALOG
    }
}

export function openEditRateTemplateMaterialDialog(data) {
    return {
        type: OPEN_EDIT_RATE_TEMPLATE_MATERIAL_DIALOG,
        data
    }
}

export function closeEditRateTemplateMaterialDialog() {
    return {
        type: CLOSE_EDIT_RATE_TEMPLATE_MATERIAL_DIALOG
    }
}

export function addRateTemplateMaterial(newRateTemplateMaterial) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/RateTemplateMaterial`,
            newRateTemplateMaterial
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_RATE_TEMPLATE_MATERIAL
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Material Breakpoint ${newRateTemplateMaterial.Breakpoint} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function updateRateTemplateMaterial(rateTemplateMaterial) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/RateTemplateMaterial?Co=${encodeURIComponent(rateTemplateMaterial.Co)}&BusinessUnit=${encodeURIComponent(rateTemplateMaterial.BusinessUnit)}&Division=${encodeURIComponent(rateTemplateMaterial.Division)}&RateTemplate=${encodeURIComponent(rateTemplateMaterial.RateTemplate)}&Breakpoint=${encodeURIComponent(rateTemplateMaterial.Breakpoint)}`,
            rateTemplateMaterial
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_RATE_TEMPLATE_MATERIAL
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Material Breakpoint ${rateTemplateMaterial.Breakpoint} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function removeRateTemplateMaterial(rateTemplateMaterial) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/RateTemplateMaterial?Co=${encodeURIComponent(rateTemplateMaterial.Co)}&BusinessUnit=${encodeURIComponent(rateTemplateMaterial.BusinessUnit)}&Division=${encodeURIComponent(rateTemplateMaterial.Division)}&RateTemplate=${encodeURIComponent(rateTemplateMaterial.RateTemplate)}&Breakpoint=${encodeURIComponent(rateTemplateMaterial.Breakpoint)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_RATE_TEMPLATE_MATERIAL
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Material Breakpoint ${rateTemplateMaterial.Breakpoint} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}