import axios from 'axios';
import { getUserData } from 'main/content/apps/academy/store/actions/user.actions';
import { showMessage, SET_CLASS_PROGRESS } from 'store/actions';
import _ from '@lodash';

export const GET_CLASSES = '[ACADEMY APP] GET CLASSES';
export const SET_SEARCH_TEXT = '[ACADEMY APP] SET SEARCH TEXT';
export const ADD_CLASS = '[ACADEMY APP] ADD CLASS';
export const UPDATE_CLASS = '[ACADEMY APP] UPDATE CLASS';
export const REMOVE_CLASS = '[ACADEMY APP] REMOVE CLASS';
export const ADD_CLASS_CATEGORY = '[ACADEMY APP] ADD CLASS CATEGORY';
export const UPDATE_CLASS_CATEGORY = '[ACADEMY APP] UPDATE  CATEGORY';
export const REMOVE_CLASS_CATEGORY = '[ACADEMY APP] REMOVE CLASS CATEGORY';
export const SET_CLASS = '[ACADEMY APP] SET CLASS';
export const SET_CLASS_CATEGORY = '[ACADEMY APP] SET CLASS CATEGORY';
export const SET_CLASSES = '[ACADEMY APP] SET CLASSES';
export const SET_CLASS_CATEGORIES = '[ACADEMY APP] SET CLASS CATEGORIES';
export const SET_CLASS_STEP = '[ACADEMY APP] SET CLASS STEP';

export function getClasses(routeParams) {
    const request = axios.get('/api/academy-app/academy', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_CLASSES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function setOrder(selectedClass, newOrder) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/ClassStep?Co=${selectedClass.Co}&Class=${selectedClass.Class}`, newOrder);
        return request.then((response) => {
            const updated = _.cloneDeepWith(selectedClass);
            updated.Data.Steps.map((step, index) => {
                const match = _.findIndex(response.data, { Step: step.Step });
                if (match > -1) {
                    updated.Data.Steps.splice(index, 1, response.data[match]);
                }
            })
            updated.Data.Steps = _.orderBy(updated.Data.Steps, ['DisplayIndex'], ['asc']);
            Promise.all([
                dispatch({
                    type: SET_CLASS,
                    class: updated,
                })
            ]).then(() => {
                dispatch(setStep(updated.Data.Steps[0]));
            })

        })
    }
}

export function getClass(selectedClass) {
    if (selectedClass) {
        return (dispatch, getState) => {

            const state = getState();
            const { progress } = state.spReducers.classes;
            const prog = _.find(progress, { Class: selectedClass.Class });

            const request = axios.get(`${window["apiLocation"]}/api/Class?Co=${selectedClass.Co}&Class=${selectedClass.Class}`);

            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: SET_CLASS,
                        class: response.data,
                    }),
                    dispatch(setStep(response.data.Data ? response.data.Data.Steps[(prog ? prog.Step - 1 : 0)] : null))
                ]))
        }
    } else {
        return {
            type: SET_CLASS,
            class: null,
        };
    }
}

export function setCategory(category) {
    return {
        type: SET_CLASS_CATEGORY,
        category,
    }
}

export function setCategories(categories) {
    return {
        type: SET_CLASS_CATEGORIES,
        categories,
    }
}

export function setClass(selectedClass) {
    return {
        type: SET_CLASS,
        class: selectedClass,
    }
}

export function setClasses(classes) {
    return {
        type: SET_CLASSES,
        classes,
    }
}

export function setStep(step) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_CLASS_STEP,
            step,
        });
        if (step) {
            dispatch(setProgress(step));
        }
    }
}

export function addClass(newClass) {
    return (dispatch, getState) => {

        const state = getState();
        const { classes } = state.academyApp.academy;

        const request = axios.post(`${window["apiLocation"]}/api/Class`,
            newClass
        );

        return request.then((response) => {
            classes.push(response.data)
            Promise.all([
                dispatch({
                    type: SET_CLASSES,
                    classes,
                }),
                dispatch(getClass(response.data))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Class #${response.data.Class} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateClass(selectedClass) {
    return (dispatch, getState) => {

        const state = getState();
        const { classes } = state.academyApp.academy;
        const index = _.findIndex(classes, { Class: selectedClass.Class });

        const request = axios.put(`${window["apiLocation"]}/api/Class?Co=${encodeURIComponent(selectedClass.Co)}&Class=${encodeURIComponent(selectedClass.Class)}`,
            selectedClass
        );

        return request.then((response) => {
            classes.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_CLASSES,
                    classes
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Class #${response.data.Class} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeClass(selectedClass) {
    return (dispatch, getState) => {

        const state = getState();
        const { classes } = state.academyApp.academy;
        const index = _.findIndex(classes, { Class: selectedClass.Class });
        window["warn"](classes, index);

        const request = axios.delete(`${window["apiLocation"]}/api/Class?Co=${encodeURIComponent(selectedClass.Co)}&Class=${encodeURIComponent(selectedClass.Class)}`,
            selectedClass
        );

        return request.then((response) => {
            classes.splice(index, 1);
            window["warn"](classes);
            Promise.all([
                dispatch({
                    type: SET_CLASSES,
                    classes
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Class #${selectedClass.Class} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function addClassCategory(newClassCategory) {
    return (dispatch, getState) => {

        const state = getState();
        const { categories } = state.academyApp.academy;

        const request = axios.post(`${window["apiLocation"]}/api/ClassCategory`,
            newClassCategory
        );

        return request.then((response) => {
            categories.push(response.data)
            Promise.all([
                dispatch({
                    type: SET_CLASS_CATEGORIES,
                    categories,
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateClassCategory(category) {
    return (dispatch, getState) => {

        const state = getState();
        const { categories } = state.academyApp.academy;
        const index = _.findIndex(categories, { Category: category.Category });

        const request = axios.put(`${window["apiLocation"]}/api/ClassCategory?Co=${encodeURIComponent(category.Co)}&Category=${encodeURIComponent(category.Category)}`,
            category
        );

        return request.then((response) => {
            categories.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_CLASS_CATEGORIES,
                    categories
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeClassCategory(category) {
    return (dispatch, getState) => {

        const state = getState();
        const { categories } = state.academyApp.academy;
        const index = _.findIndex(categories, { Category: category.Category });

        const request = axios.delete(`${window["apiLocation"]}/api/ClassCategory?Co=${encodeURIComponent(category.Co)}&Category=${encodeURIComponent(category.Category)}`,
            category
        );

        return request.then((response) => {
            categories.splice(index, 1);
            Promise.all([
                dispatch({
                    type: SET_CLASS_CATEGORIES,
                    categories
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Category #${category.Category} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function addStep(step) {
    return (dispatch, getState) => {

        const state = getState();
        const selectedClass = state.academyApp.academy.class;
        const index = _.findIndex(selectedClass.Data.Steps, { Step: step.Step });


        const request = axios.post(`${window["apiLocation"]}/api/ClassStep`,
            step
        );

        return request.then((response) => {
            selectedClass.Data.Steps.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_CLASS,
                    class: selectedClass,
                }),
                dispatch({
                    type: SET_CLASS_STEP,
                    step: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Step #${response.data.DisplayIndex + 1} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateStep(step) {
    return (dispatch, getState) => {

        const state = getState();
        const selectedClass = state.academyApp.academy.class;
        const index = _.findIndex(selectedClass.Data.Steps, { Step: step.Step });


        const request = axios.put(`${window["apiLocation"]}/api/ClassStep?Co=${encodeURIComponent(step.Co)}&Class=${encodeURIComponent(step.Class)}&Step=${step.Step}`,
            step
        );

        return request.then((response) => {
            selectedClass.Data.Steps.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_CLASS,
                    class: selectedClass,
                }),
                dispatch({
                    type: SET_CLASS_STEP,
                    step: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Step #${response.data.DisplayIndex + 1} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeStep(step) {
    return (dispatch, getState) => {

        const state = getState();
        const selectedClass = state.academyApp.academy.class;
        const index = _.findIndex(selectedClass.Data.Steps, { Step: step.Step });

        const request = axios.delete(`${window["apiLocation"]}/api/ClassStep?Co=${encodeURIComponent(step.Co)}&Class=${encodeURIComponent(step.Class)}&Step=${step.Step}`,
            step
        );

        return request.then((response) => {
            selectedClass.Data.Steps.splice(index, 1);
            Promise.all([
                dispatch({
                    type: SET_CLASS,
                    class: selectedClass,
                }),
                dispatch({
                    type: SET_CLASS_STEP,
                    step: null,
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Step #${step.DisplayIndex + 1} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function setProgress(step) {
    return (dispatch, getState) => {
        const { ID, Co, Class, Step } = step;
        if (ID) {
            const prog = {
                Co,
                Class,
                Step,
                Data: {
                    ErrMsg: null,
                }
            };
            const state = getState();
            const { progress } = state.spReducers.classes;
            const index = _.findIndex(progress, { Class });
            if (index > -1) {
                dispatch(updateProgress(prog));
            } else {
                dispatch(addProgress(prog));
            }
        }
    }
}

export function addProgress(step) {
    return (dispatch, getState) => {

        const state = getState();
        const { progress } = state.spReducers.classes;

        const request = axios.post(`${window["apiLocation"]}/api/ClassProgress`,
            step
        );

        return request.then((response) => {
            progress.push(response.data);
            Promise.all([
                dispatch({
                    type: SET_CLASS_PROGRESS,
                    payload: progress,
                }),
            ]);
        });
    };
}

export function updateProgress(step) {
    return (dispatch, getState) => {

        const state = getState();
        const { progress } = state.spReducers.classes;
        const index = _.findIndex(progress, { Class: step.Class });

        const request = axios.put(`${window["apiLocation"]}/api/ClassProgress?Co=${encodeURIComponent(step.Co)}&Class=${encodeURIComponent(step.Class)}`,
            step
        );

        return request.then((response) => {
            progress.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_CLASS_PROGRESS,
                    payload: progress,
                }),
            ]);
        });
    };
}
