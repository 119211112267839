import { combineReducers } from 'redux';
import support from './support.reducer';
import user from './user.reducer';
import request from '../../requests/store/reducers/card.reducer';

const supportAppReducers = combineReducers({
    support,
    user,
    request
});

export default supportAppReducers;