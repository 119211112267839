import * as Actions from '../actions';
import _ from '@lodash';

const initialState = null;

const templateReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TEMPLATE:
            {
                return {
                    ...action.payload
                };
            }
        case Actions.RESET_TEMPLATE:
            {
                return initialState;
            }
        case Actions.DELETE_TEMPLATE:
            {
                return initialState;
            }
        case Actions.RENAME_TEMPLATE:
            {
                return {
                    ...state,
                    Name: action.payload.Name,
                };
            }
        default:
            return state;
    }
};

export default templateReducer;
