import * as Actions from 'store/actions/spAuthentication';

const initialState = {
    client: null,
    location: null,
    error: null
};

const api = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_API_LOCATION:
            {
                const { error, location, client } = action;
                return {
                    client,
                    location,
                    error
                };
            }
    }

    return state;
};

export default api;