import {combineReducers} from 'redux';
import CallGatherKeys from './call-gather-keys.reducer';
import user from './user.reducer';

const callGatherKeysAppReducers = combineReducers({
    CallGatherKeys,
    user
});

export default callGatherKeysAppReducers;
