import axios from 'axios/index';
import * as DispatchActions from 'main/content/apps/dispatch-board/store/actions/dispatch-board.actions';
import { SUBSCRIBE_LIST, UNSUBSCRIBE_LIST } from 'store/actions';

export const SET_TRIP_DATA = '[SPCONNECTION] SET TRIP DATA';
export const GET_TRIP_DATA = '[SPCONNECTION] GET TRIP DATA';

export function getInitialTripData(Co, Date) {
    if (Date) {
        const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard`, {
            params: {
                Co,
                Date
            }
        });
        return (dispatch) => {
            request.then((response) => {
                dispatch(DispatchActions.setBoardTrips(response.data));
            });
        }
    }
}

export function getTripData(Co, Date, oldDate) {
    if (Date) {
        const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard`, {
            params: {
                Co,
                Date
            }
        });

        return (dispatch) => {
            request.then((response) => {
                Promise.all([
                    dispatch(DispatchActions.setBoardTrips(response.data))
                ]);
            });
        }
    } else {
        if (oldDate) {
            return {
                type: UNSUBSCRIBE_LIST,
                List: `${Co}_${oldDate}_Trips`,
            }
        }
    }
}

export function setTripData(data) {
    return {
        type: SET_TRIP_DATA,
        payload: data
    };
}