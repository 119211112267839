import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class ServiceUnitCategoriesList extends Component {

    state = {
        selectedServiceUnitCategoriesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedServiceUnitCategoryMenu = (event) => {
        this.setState({ selectedServiceUnitCategoriesMenu: event.currentTarget });
    };

    closeSelectedServiceUnitCategoriesMenu = () => {
        this.setState({ selectedServiceUnitCategoriesMenu: null });
    };

    render() {
        const { classes, serviceUnitCategories, user, searchText, openNewServiceUnitCategoryDialog, selectedServiceUnitCategoryIds, selectAllServiceUnitCategories, deSelectAllServiceUnitCategories, toggleInSelectedServiceUnitCategories, openEditServiceUnitCategoryDialog, removeServiceUnitCategories, removeServiceUnitCategory, toggleStarredServiceUnitCategory, setServiceUnitCategoriesUnstarred, setServiceUnitCategoriesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedServiceUnitCategoriesData ? (!this.props.serviceUnitCategoryData ? [] : this.props.serviceUnitCategoryData) : this.props.selectedServiceUnitCategoriesData), searchText);
        const { selectedServiceUnitCategoriesMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewServiceUnitCategoryDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">settings</Icon>Service Unit Categories</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditServiceUnitCategoryDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Unit Category",
                                accessor: "UnitCat",
                                className: "font-bold justify-center",
                                width: 192,
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256,
                            },
                            {
                                Header: "Unit Types",
                                id: "types",
                                className: "justify-center",
                                width: 96,
                                accessor: r => (r.Data && r.Data.Types) && r.Data.Types.length
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Service Unit Categories found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServiceUnitCategories: Actions.getServiceUnitCategories,
        getUserData: Actions.getUserData,
        toggleInSelectedServiceUnitCategories: Actions.toggleInSelectedServiceUnitCategories,
        selectAllServiceUnitCategories: Actions.selectAllServiceUnitCategories,
        deSelectAllServiceUnitCategories: Actions.deSelectAllServiceUnitCategories,
        openEditServiceUnitCategoryDialog: Actions.openEditServiceUnitCategoryDialog,
        removeServiceUnitCategories: Actions.removeServiceUnitCategories,
        removeServiceUnitCategory: Actions.removeServiceUnitCategory,
        toggleStarredServiceUnitCategory: Actions.toggleStarredServiceUnitCategory,
        toggleStarredServiceUnitCategories: Actions.toggleStarredServiceUnitCategories,
        setServiceUnitCategoriesStarred: Actions.setServiceUnitCategoriesStarred,
        setServiceUnitCategoriesUnstarred: Actions.setServiceUnitCategoriesUnstarred,
        openNewServiceUnitCategoryDialog: Actions.openNewServiceUnitCategoryDialog
    }, dispatch);
}

function mapStateToProps({ serviceUnitCategoriesApp, spReducers }) {
    return {
        serviceUnitCategories: serviceUnitCategoriesApp.serviceUnitCategories.entities,
        selectedServiceUnitCategoryIds: serviceUnitCategoriesApp.serviceUnitCategories.selectedServiceUnitCategoryIds,
        searchText: serviceUnitCategoriesApp.serviceUnitCategories.searchText,
        user: serviceUnitCategoriesApp.user,
        serviceUnitCategoryData: spReducers.serviceUnitCategories
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitCategoriesList)));
