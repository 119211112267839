import * as Actions from 'store/actions/spConnection';

const boards = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_BOARD_DATA:
            {
                return action.payload;
            }
        case Actions.SET_BOARD_DATA:
            {
                return action.payload;
            }
    }

    return state;
};

export default boards;