import {combineReducers} from 'redux';
import forms from './forms.reducer';
import user from './user.reducer';

const formsAppReducers = combineReducers({
    forms,
    user
});

export default formsAppReducers;
