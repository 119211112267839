import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Parser } from 'json2csv';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { updateTableSettings } from '../users/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import * as Actions from './store/actions';
import axios from 'axios';
import getProfileImage from '../../../functions/getProfileImageUrl';

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Work Order Scopes Found
            </div>
        </div>
    )
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    techAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class WorkOrderScopesList extends Component {

    state = {
        selectedWorkOrderScopesMenu: null,
        loading: false,
        hist: null
    };

    componentDidMount() {
        const { hist } = this.props;
        this.setState({ hist });
    }

    componentDidUpdate(prevProps, prevState) {
        const { hist } = this.props;
        if (!_.isEqual(hist, prevProps.hist)) {
            this.setState({ hist });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {

            return arr;
        }

        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedWorkOrderScopeMenu = (event) => {
        this.setState({ selectedWorkOrderScopesMenu: event.currentTarget });
    };

    closeSelectedWorkOrderScopesMenu = () => {
        this.setState({ selectedWorkOrderScopesMenu: null });
    };

    handleRowCount = (count) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'work-order-scopes' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'work-order-scopes',
                Preferences: {
                    RowCount: count
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.RowCount = count;
        }
        this.props.updateTableSettings(prefs);
    }

    handleSortOrder = (sort) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'work-order-scopes' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'work-order-scopes',
                Preferences: {
                    SortOrder: sort
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.SortOrder = sort;
        }
        this.props.updateTableSettings(prefs);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    export = () => {
        const data = this.props.selectedWOData;
        const csvData = new Parser();
        const Data = csvData.parse(data);
        var encoded = encodeURIComponent(Data);
        var csv = `data:text/csv;charset=utf-8, ${encoded}`;
        var link = document.createElement("a");
        link.setAttribute("href", csv);
        link.setAttribute("download", "Work Order Scopes.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    fetchData = (Co, Page) => {
        const { customer, site, hist } = this.props;
        if (hist) {
            this.setState({ loading: true }, () => {
                const request = axios.get(`${window["apiLocation"]}/api/WorkOrderScope?Co=${Co}&Customer=${customer}${site ? `&Site=${site}` : ''}&Page=${Page}`);
                request.then((response) => {
                    this.setState({ hist: response.data, loading: false });
                }).catch(() => {
                    this.setState({ loading: false });
                })
            });
        }
    }

    render() {
        const { classes, securables, audit, history, loading2, user, technicians, searchText, openEditWorkOrderScopeDialog, openEditWorkOrderDialog, edit, Co } = this.props;
        const { TableSettings } = user.Data;
        const { hist, loading } = this.state;
        const data = this.getFilteredArray((!this.props.selectedWOData ? (!this.props.workOrderScopeData ? [] : this.props.workOrderScopeData) : this.props.selectedWOData), searchText);
        const accessLevel = _.find(securables, { Securable: "work-orders" });
        const prefs = _.find(TableSettings, { Table: 'work-order-scopes' });
        const avatar = 'assets/images/avatars/profile.jpg';
        const manualOptions = (hist ? {
            manual: true,
            data: hist.Results,
            pages: hist.PageCount,
            onFetchData: (e) => { this.fetchData(Co, e.page + 1); },
            defaultPageSize: 10,
            pageSizeOptions: [10],
            sortable: false
        } : {
            data,
            defaultSorted: prefs ? (prefs.Preferences.SortOrder || []) : [],
            defaultPageSize: prefs ? (prefs.Preferences.RowCount || 100) : 10,
            onPageSizeChange: this.handleRowCount,
            onSortedChange: this.handleSortOrder,
        });

        return (
            <FuseAnimate animation={this.props.animation || "transition.slideUpIn"} delay={300}>
                <div>
                    {audit &&
                        <Fab
                            color="primary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={this.export}
                        // onClick={openNewUserDialog}
                        >
                            <Icon className="text-16">save_alt</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center', display: edit === false ? 'none' : 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">build</Icon>Scopes</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo && edit !== false) {
                                        openEditWorkOrderScopeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        getTfootTrProps={() => {
                            return {
                                className: "font-bold text-center",
                            }
                        }}
                        {...manualOptions}
                        loading={loading || this.state.loading} // Display the loading overlay when we need it
                        LoadingComponent={loading ? LoadingDiv : undefined}
                        NoDataComponent={loading2 || loading ? LoadingDiv : NoData}
                        columns={[
                            {
                                Header: "Work Order",
                                accessor: "WorkOrder",
                                className: "font-bold justify-center",
                                width: 128,
                                Cell: row => {
                                    if (row.original.WorkOrder) {
                                        return (
                                            <div onClick={(e) => { e.stopPropagation(); openEditWorkOrderDialog({ ...row.original }); }}>
                                                {row.original.WorkOrder}<Icon className="text-14 font-bold ml-4" style={{ paddingTop: 2 }}>open_in_new</Icon>
                                            </div>
                                        );
                                    }
                                },
                                show: Boolean(audit || history),
                                Footer: !hist &&  "Total",
                                footerClassName: "font-bold text-center"
                            },
                            {
                                Header: "Scope",
                                accessor: "Scope",
                                className: "font-bold justify-center",
                                width: 96
                            },
                            {
                                Header: "Due By",
                                accessor: "DueBy",
                                className: "justify-center",
                                width: 96,
                                Cell: row => {
                                    return row.original.DueBy ? new Date(row.original.DueBy).toLocaleDateString('en-US') : ''
                                }
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 384,
                            },
                            {
                                Header: "Lead Technician",
                                accessor: "LeadTechnician",
                                minWidth: 256,
                                className: "justify-center",
                                Cell: row => {
                                    return technicians.map((value, index) => (
                                        value.Technician === row.original.LeadTechnician &&
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.techAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                            {
                                Header: "Trips",
                                accessor: "TripCount",
                                className: "justify-center",
                                width: 128,
                                show: Boolean(audit || history),
                                Footer: !hist &&  _.sumBy(data, 'TripCount')
                            },
                            {
                                Header: "Cost",
                                accessor: "CostTotal",
                                className: "justify-center",
                                width: 128,
                                Cell: row => {
                                    return this.formatDollars(row.original.CostTotal);
                                },
                                show: Boolean(audit || history),
                                Footer: !hist &&  this.formatDollars(_.sumBy(data, 'CostTotal')),
                                footerClassName: "font-bold text-center"
                            },
                            {
                                Header: "Billable",
                                accessor: "PriceTotal",
                                className: "justify-center",
                                width: 128,
                                Cell: row => {
                                    return this.formatDollars(row.original.PriceTotal);
                                },
                                show: Boolean(audit || history),
                                Footer: !hist &&  this.formatDollars(_.sumBy(data, 'PriceTotal')),
                                footerClassName: "font-bold text-center"
                            },
                            {
                                Header: "Invoices",
                                accessor: "InvoiceCount",
                                className: "justify-center",
                                width: 128,
                                show: Boolean(audit || history),
                                Footer: !hist &&  _.sumBy(data, 'InvoiceCount'),
                                footerClassName: "font-bold text-center"
                            },
                            {
                                Header: "Billed",
                                accessor: "InvoicedAmount",
                                className: "justify-center",
                                width: 128,
                                Cell: row => {
                                    return this.formatDollars(row.original.InvoicedAmount);
                                },
                                show: Boolean(audit || history),
                                Footer: !hist &&  this.formatDollars(_.sumBy(data, 'InvoicedAmount')),
                                footerClassName: "font-bold text-center"
                            },
                            {
                                Header: "Unbilled",
                                id: 'unbilled',
                                accessor: (e) => { return e.PriceTotal - e.InvoicedAmount },
                                className: "justify-center",
                                width: 128,
                                Cell: row => {
                                    return this.formatDollars((row.original.PriceTotal - row.original.InvoicedAmount));
                                },
                                show: Boolean(audit || history),
                                Footer: !hist &&  this.formatDollars(_.sumBy(data, (o) => { return (o.PriceTotal - o.InvoicedAmount) })),
                                footerClassName: "font-bold text-center"
                            },
                            {
                                Header: "Customer",
                                accessor: "Customer",
                                minWidth: 256,
                                className: "justify-center",
                                Cell: row => {
                                    const customer = row.original.Data && row.original.Data.Customer ? row.original.Data.Customer : row.original;
                                    const name = customer.CustomerName || customer.Name || `${customer.FirstName} ${customer.LastName}`;
                                    return `${customer.Customer} - ${name}`
                                }
                            },
                            {
                                Header: "Address",
                                accessor: "SiteAddress",
                                minWidth: 320,
                                className: "justify-center",
                                show: Boolean(audit || history),
                            },
                            {
                                Header: "Service",
                                accessor: "Service",
                                className: "justify-center",
                                width: 96
                            },
                            {
                                Header: "Service Type",
                                accessor: "ServiceType",
                                className: "justify-center",
                                width: 128
                            },
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "justify-center",
                                width: 128,
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "justify-center",
                                width: 128
                            },
                            {
                                Header: "Department",
                                accessor: "Department",
                                className: "justify-center",
                                width: 128,
                            },
                            {
                                Header: "Requestor",
                                accessor: "Requestor",
                                width: 192,
                                className: "justify-center",
                            },
                            {
                                Header: "Originator",
                                accessor: "Originator",
                                width: 192,
                                className: "justify-center",
                                Cell: row => {
                                    return technicians.map((value, index) => (
                                        value.Technician === row.original.Originator &&
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.techAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                            {
                                Header: "Status",
                                accessor: "Status",
                                width: 96,
                                className: "justify-center",
                            },
                            {
                                Header: "Customer PO",
                                accessor: "CustomerPO",
                                width: 192,
                                className: "justify-center",
                            },
                            {
                                Header: "Entered By",
                                accessor: "EnteredBy",
                                width: 256
                            },
                            {
                                Header: "Entered Date",
                                accessor: "EnteredDate",
                                width: 256,
                                className: "justify-center",
                            }
                        ]
                        }
                        // defaultSorted={audit && prefs ? (prefs.Preferences.SortOrder || []) : []}
                        // defaultPageSize={audit && prefs ? (prefs.Preferences.RowCount || 10) : 10}
                        // onPageSizeChange={this.handleRowCount}
                        // onSortedChange={this.handleSortOrder}
                        noDataText="No Work Order Scopes found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWorkOrderScopes: Actions.getWorkOrderScopes,
        getUserData: Actions.getUserData,
        toggleInSelectedWorkOrderScopes: Actions.toggleInSelectedWorkOrderScopes,
        selectAllWorkOrderScopes: Actions.selectAllWorkOrderScopes,
        deSelectAllWorkOrderScopes: Actions.deSelectAllWorkOrderScopes,
        openEditWorkOrderScopeDialog: Actions.openEditWorkOrderScopeDialog,
        removeWorkOrderScopes: Actions.removeWorkOrderScopes,
        removeWorkOrderScope: Actions.removeWorkOrderScope,
        toggleStarredWorkOrderScope: Actions.toggleStarredWorkOrderScope,
        toggleStarredWorkOrderScopes: Actions.toggleStarredWorkOrderScopes,
        setWorkOrderScopesStarred: Actions.setWorkOrderScopesStarred,
        setWorkOrderScopesUnstarred: Actions.setWorkOrderScopesUnstarred,
        openEditWorkOrderDialog,
        updateTableSettings,
    }, dispatch);
}

function mapStateToProps({ spReducers, workOrderScopesApp }) {
    window["log"](spReducers, workOrderScopesApp);
    return {
        Co: spReducers.companies.Co,
        workOrderScopes: workOrderScopesApp.workOrderScopes.entities,
        selectedWorkOrderScopeIds: workOrderScopesApp.workOrderScopes.selectedWorkOrderScopeIds,
        searchText: workOrderScopesApp.workOrderScopes.searchText,
        user: spReducers.userProfiles.User,
        workOrderScopeData: spReducers.workOrderScopes,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderScopesList)));
