import {combineReducers} from 'redux';
import minimizedDialog from './minimized-dialogs.reducer';
import user from './user.reducer';

const minimizedDialogAppReducers = combineReducers({
    minimizedDialog,
    user
});

export default minimizedDialogAppReducers;
