import {combineReducers} from 'redux';
import auditPosting from './audit-posting.reducer';
import user from './user.reducer';

const auditPostingAppReducers = combineReducers({
    auditPosting,
    user
});

export default auditPostingAppReducers;
