import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Chip,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  MobileStepper,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import classNames from 'classnames'
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import DispatchBoardWorkOrder from './DispatchBoardWorkOrder'

const styles = theme => ({
  root: {},
  formControl: {
    marginBottom: 8,
  },
  listItem: {
    color: 'inherit!important',
    textDecoration: 'none!important',
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingLeft: 24,
    paddingRight: 12,
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
  },
  avatar: {
    backgroundColor: theme.palette.error[500],
    height: 18,
    minWidth: 18,
    fontSize: 13,
    borderRadius: 9,
    padding: 4,
    marginRight: 8,
    width: 'auto',
  },
  card: {
    marginTop: 12,
    cursor: 'pointer',
    width: '100%',
  },
  actions: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  info: {
    padding: 8,
    paddingTop: 0,
    paddingBottom: 0 + '!important',
  },
  campaign: {
    width: 'auto',
    top: 0,
    right: -5,
    marginTop: 5,
    borderRadius: 4,
    padding: 10,
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  badge: {
    top: 6,
    right: 4,
    minWidth: 16,
    minHeight: 16,
    height: 16,
    fontSize: 11,
  },
  folderBadge: {
    top: 12,
    right: 'unset',
    left: 'calc(108% - 12px)',
    minWidth: 16,
    minHeight: 16,
    height: 16,
    fontSize: 11,
  },
})
const newDispatchBoardState = {
  ID: null,
  Co: null,
  Board: null,
  Name: null,
  Description: null,
  Options: null,
  Notes: '',
  SharedYN: null,
  AddedBy: null,
  AddedDate: new Date(),
  UpdatedBy: null,
  UpdatedDate: null,
  criteriaType: null,
  Data: {
    ErrMsg: null,
  },
  dialogTab: 0,
  selectedFolder: null,
  selectedTechnician: null,
  selectedTeam: null,
  options: {
    folders: [],
    criteria: {
      businessUnits: [],
      divisions: [],
      departments: [],
      teams: [],
      technicians: [],
    },
    inAddition: false,
  },
  expanded: [],
  criteriaList: null,
  unassigned: [],
  selectedFolders: [],
}

class DispatchBoardDialog extends Component {
  state = { ..._.cloneDeepWith(newDispatchBoardState) }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    newDispatchBoardState.Co = this.props.Co
    if (
      !prevProps.dispatchBoardDialog.props.open &&
      this.props.dispatchBoardDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.dispatchBoardDialog.type === 'edit' &&
        this.props.dispatchBoardDialog.data &&
        !_.isEqual(this.props.dispatchBoardDialog.data, prevState)
      ) {
        const options = JSON.parse(this.props.dispatchBoardDialog.data.Options)
        this.setState(
          {
            ..._.cloneDeepWith(newDispatchBoardState),
            ...this.props.dispatchBoardDialog.data,
            options,
          },
          this.getWOData,
        )
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.dispatchBoardDialog.type === 'new' &&
        !_.isEqual(newDispatchBoardState, prevState)
      ) {
        this.setState(
          { ..._.cloneDeepWith(newDispatchBoardState) },
          this.getWOData,
        )
      }
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  closeComposeDialog = () => {
    this.props.dispatchBoardDialog.type === 'edit'
      ? this.props.closeEditDispatchBoardDialog()
      : this.props.closeNewDispatchBoardDialog()
  }

  canBeSubmitted() {
    const { Name, Description } = this.state
    return Name && Name.length > 0 && Description && Description.length > 0
  }

  handleNext = () => {
    const { dialogTab } = this.state
    this.setState({ ...this.state, dialogTab: dialogTab + 1 }, this.getWOData)
  }

  handleBack = () => {
    const { dialogTab } = this.state
    this.setState({ ...this.state, dialogTab: dialogTab - 1 })
  }

  toggleList = (e, name) => {
    // e.stopPropagation();
    const { expanded } = this.state
    const index = expanded.indexOf(name)
    if (index > -1) {
      expanded.splice(index, 1)
    } else {
      expanded.push(name)
    }
    this.setState({ ...this.state, expanded })
  }

  getWOData = () => {
    console.warn('Getting WO Data!!!')
    const {
      Co,
      date,
      trips,
      searchText,
      user,
      customBoards,
      customFolders,
      selectedBoard,
      workOrders,
    } = this.props

    const data = workOrders
    let unassigned = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            !s.LeadTechnician &&
            s.Type !== 'A' &&
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        }).length > 0
      )
    })

    const criteriaLists = {
      businessUnits: {
        key: 'BusinessUnit',
        format: ['Description'],
        label: 'Business Unit',
        listName: 'Business Units',
        icon: 'business_center',
      },
      divisions: {
        key: 'Division',
        format: ['Description'],
        label: 'Division',
        listName: 'Divisions',
        icon: 'location_city',
      },
      departments: {
        key: 'Department',
        format: ['Description'],
        label: 'Department',
        listName: 'Departments',
        icon: 'business',
      },
      serviceTypes: {
        key: 'ServiceType',
        format: ['Description'],
        label: 'Service Type',
        listName: 'Service Types',
        icon: 'settings',
      },
      services: {
        key: 'Service',
        format: ['Service', ' - ', 'Description'],
        label: 'Service',
        listName: 'Services',
        icon: 'build',
      },
      priorities: {
        key: 'Priority',
        format: ['Description'],
        label: 'Priority',
        listName: 'Priorities',
        icon: 'flag',
      },
      arrivalWindows: {
        key: 'ArrivalWindow',
        format: ['Description'],
        label: 'Arrival Window',
        listName: 'Arrival Windows',
        icon: 'access_time',
      },
    }

    let folders =
      user && user.Data && user.Data.DispatchFolders
        ? _.filter(user.Data.DispatchFolders, o => o.SharedYN !== 'Y')
        : []
    folders = [...customFolders, ...folders]

    let selectedFolders = []

    const { options } = this.state

    Object.keys(options.criteria).map(paramKey => {
      const params = options.criteria[paramKey]
      if (params.length > 0) {
        const list = criteriaLists[paramKey]
        if (list) {
          unassigned = _.filter(unassigned, o => {
            return _.filter(
              o.Data.Scopes,
              s =>
                params.indexOf(s[list.key]) > -1 &&
                s.Status !== 4 &&
                new Date(s.DueBy).toLocaleDateString('en-US') ===
                  new Date(date).toLocaleDateString('en-US') &&
                s.Data.Assignments.length < 1,
            ).length
          })
        }
      }
    })

    options.folders.map((Folder, index) => {
      const folder = _.find(folders, { Co, Folder })
      if (folder) {
        let filtered = unassigned
        const criteria = JSON.parse(folder.Options)
        Object.keys(criteria).map(paramKey => {
          const params = criteria[paramKey]
          if (params.length > 0) {
            const list = criteriaLists[paramKey]
            filtered = _.filter(filtered, o => {
              return _.filter(
                o.Data.Scopes,
                s =>
                  params.indexOf(s[list.key]) > -1 &&
                  s.Status !== 4 &&
                  new Date(s.DueBy).toLocaleDateString('en-US') ===
                    new Date(date).toLocaleDateString('en-US') &&
                  s.Data.Assignments.length < 1,
              ).length
            })
          }
        })
        folder.contents = filtered
        selectedFolders.push(folder)
      }
    })

    this.setState({
      unassigned,
      selectedFolders,
    })
  }

  render() {
    const {
      Co,
      classes,
      dispatchBoardDialog,
      addDispatchBoard,
      updateDispatchBoard,
      removeDispatchBoard,
      businessUnits,
      master,
      user,
      workOrders,
      date,
      technicians,
      loading,
      securables,
      customFolders,
    } = this.props
    const {
      criteriaType,
      dialogTab,
      options,
      expanded,
      unassigned,
      selectedFolders,
    } = this.state
    const { criteria } = options
    const accessLevel = _.find(securables, {
      Securable: 'custom-dispatch-boards',
    })
    const steps = [
      'Basic Info',
      'Select Board Criteria',
      'Select Custom Folders',
    ]
    const comments = [
      null,
      '* These criteria will dictate which Technicians are displayed on the Dispatch Board, as well as which Work Order Scopes are shown in the "Unassigned" folder, and any additional Custom Folders assigned to this board *',
      '* These folders will further filter the list of Work Orders shown in the "Unassigned" folder *',
    ]
    let folders =
      user && user.Data && user.Data.DispatchFolders
        ? _.filter(user.Data.DispatchFolders, o => o.SharedYN !== 'Y')
        : []
    folders = [...customFolders, ...folders]
    const criteriaLists = {
      businessUnits: {
        key: 'BusinessUnit',
        format: ['Description'],
        label: 'Business Unit',
        listName: 'Business Units',
        icon: 'business_center',
      },
      divisions: {
        key: 'Division',
        format: ['Description'],
        label: 'Division',
        listName: 'Divisions',
        icon: 'location_city',
      },
      departments: {
        key: 'Department',
        format: ['Description'],
        label: 'Department',
        listName: 'Departments',
        icon: 'business',
      },
      serviceTypes: {
        key: 'ServiceType',
        format: ['Description'],
        label: 'Service Type',
        listName: 'Service Types',
        icon: 'settings',
      },
      services: {
        key: 'Service',
        format: ['Service', ' - ', 'Description'],
        label: 'Service',
        listName: 'Services',
        icon: 'build',
      },
      priorities: {
        key: 'Priority',
        format: ['Description'],
        label: 'Priority',
        listName: 'Priorities',
        icon: 'flag',
      },
      arrivalWindows: {
        key: 'ArrivalWindow',
        format: ['Description'],
        label: 'Arrival Window',
        listName: 'Arrival Windows',
        icon: 'access_time',
      },
    }
    const techCriteriaLists = {
      businessUnits: {
        key: 'BusinessUnit',
        format: ['Description'],
        label: 'Business Unit',
        listName: 'Business Units',
        icon: 'business_center',
      },
      divisions: {
        key: 'Division',
        format: ['Description'],
        label: 'Division',
        listName: 'Divisions',
        icon: 'location_city',
      },
      departments: {
        key: 'Department',
        format: ['Description'],
        label: 'Department',
        listName: 'Departments',
        icon: 'business',
      },
      teams: {
        key: 'Team',
        format: ['Name'],
        label: 'Team',
        listName: 'Teams',
        icon: 'supervised_user_circle',
      },
      technicians: {
        key: 'Technician',
        format: ['FirstName', 'LastName'],
        label: 'Technician',
        listName: 'Technicians',
        icon: 'people',
      },
    }
    const criteriaList = techCriteriaLists[criteriaType]
    const data = { ...master }

    return (
      <Media query='(min-width: 512px) and (min-height: 512px)'>
        {matches => (
          <Dialog
            classes={{
              root: classes.root,
              paper: matches
                ? classes.paper
                : 'full-screen-dialog' /*if fullScreen*/,
            }}
            fullScreen={!matches}
            className={classes.root}
            {...dispatchBoardDialog.props}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth='xs'
          >
            <AppBar
              position='static'
              elevation={1}
              className='dialog-header' /*if fullScreen*/
            >
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  {!this.state.ID
                    ? 'New Dispatch Board'
                    : `Edit Dispatch Board #${this.state.Board}`}
                </Typography>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches ? 10 : 4 /*if fullScreen*/,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon' /*if fullScreen*/
              >
                <Icon>close</Icon>
              </IconButton>
            </AppBar>
            <DialogContent classes={{ root: 'p-24' }}>
              <Typography
                className={classNames(
                  'flex items-center flex-1 font-bold text-12',
                  !comments[dialogTab] ? 'mb-24' : 'mb-12',
                )}
              >{`${dialogTab + 1}. ${steps[dialogTab]}`}</Typography>
              {comments[dialogTab] && (
                <Typography className='flex items-center flex-1 mb-12 text-10 text-grey-dark italic text-center'>{`${comments[dialogTab]}`}</Typography>
              )}
              {dialogTab === 0 && (
                <React.Fragment>
                  <div className='flex'>
                    <TextField
                      className={classes.formControl}
                      label='Dispatch Board Name'
                      id='name'
                      name='Name'
                      value={this.state.Name}
                      onChange={this.handleChange}
                      variant='outlined'
                      fullWidth
                      required
                    />
                  </div>

                  <div className='flex'>
                    <TextField
                      className={classNames(classes.formControl, 'mb-4')}
                      label='Description'
                      id='description'
                      name='Description'
                      value={this.state.Description}
                      onChange={this.handleChange}
                      variant='outlined'
                      fullWidth
                      multiline
                      rows={3}
                      // required
                    />
                  </div>
                  <div className='flex'>
                    <div className='w-1/2 mb-12'>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.SharedYN === 'Y'}
                            name='SharedYN'
                            onChange={this.handleChange}
                            color='primary'
                          />
                        }
                        label='Shared?'
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {dialogTab === 1 && (
                <div className='mb-24'>
                  <div className='flex'>
                    <TextField
                      className={classes.formControl}
                      label='Criteria Type'
                      id='criteriaType'
                      name='criteriaType'
                      value={this.state.criteriaType || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      fullWidth
                      select
                      required
                    >
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value='businessUnits'>Business Units</MenuItem>
                      <MenuItem value='divisions'>Divisions</MenuItem>
                      <MenuItem value='departments'>Departments</MenuItem>
                      <MenuItem value='teams'>Teams</MenuItem>
                      <MenuItem value='technicians'>Technicians</MenuItem>
                    </TextField>
                  </div>
                  {criteriaList && (
                    <div className='w-full'>
                      <Autocomplete
                        className={'mb-8'}
                        title={criteriaList.label}
                        options={_.filter(
                          data[criteriaType],
                          o =>
                            criteria[criteriaType].indexOf(
                              o[criteriaList.key],
                            ) < 0,
                        )}
                        menuItemComponent={(value, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={value[criteriaList.key]}
                            >
                              {criteriaList.format.map(prop => {
                                return `${value[prop]} `
                              })}
                            </MenuItem>
                          )
                        }}
                        portal={true}
                        value={this.state[criteriaList.key] || ''}
                        onSelect={option =>
                          this.setState(
                            _.set(
                              { ...this.state },
                              criteriaList.key,
                              option[criteriaList.key],
                            ),
                          )
                        }
                      />
                      <Button
                        className={classNames(classes.formControl, 'w-full')}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          criteria[criteriaType].push(
                            this.state[criteriaList.key],
                          )
                          this.setState(
                            _.set(
                              { ...this.state, criteria },
                              criteriaList.key,
                              null,
                            ),
                          )
                        }}
                        disabled={!this.state[criteriaList.key]}
                      >
                        Add {criteriaList.label}
                      </Button>
                    </div>
                  )}
                  {criteria &&
                    _.filter(criteria, o => o.length > 0).length > 0 && (
                      <div className='w-full'>
                        <div className=''>
                          <label
                            className='text-12 p-4 px-6 ml-8'
                            style={{ background: '#fff', color: '#9f9f9f' }}
                          >
                            Criteria
                          </label>
                          <div
                            className='rounded-4 p-12 mb-12 min-h-256'
                            style={{
                              border: '1px solid rgba(0, 0, 0, .25)',
                              marginTop: -11,
                            }}
                          >
                            {Object.keys(criteria).map((key, index) => {
                              const value = criteria[key]
                              if (value.length > 0) {
                                const list = techCriteriaLists[key]
                                return (
                                  <div className='w-full'>
                                    <div className='flex justify-between border-b-1 border-solid border-grey-light mb-8'>
                                      <Typography
                                        variant='h6'
                                        className='text-12 font-bold'
                                      >
                                        <Icon
                                          className='mr-4 text-16 align-middle'
                                          style={{ marginBottom: 6 }}
                                        >
                                          {list.icon}
                                        </Icon>
                                        {list.listName}
                                      </Typography>
                                      {key === 'technicians' && (
                                        <FormControlLabel
                                          style={{ marginTop: -10 }}
                                          className={classNames('m-0')}
                                          classes={{ label: 'text-12' }}
                                          label='Additional'
                                          control={
                                            <Switch
                                              checked={options.inAddition}
                                              id='allDay'
                                              name='allDay'
                                              onChange={e =>
                                                this.setState({
                                                  options: {
                                                    ...options,
                                                    inAddition:
                                                      e.target.checked,
                                                  },
                                                })
                                              }
                                            />
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className='flex-wrap'>
                                      {value.map((entry, index) => {
                                        const obj = _.find(
                                          data[key],
                                          o => o[list.key] === entry,
                                        )
                                        if (obj) {
                                          return (
                                            <Chip
                                              classes={{ deleteIcon: 'h-20' }}
                                              className='mb-8 mr-4 h-24'
                                              color='primary'
                                              onDelete={() => {
                                                criteria[key].splice(
                                                  value.indexOf(entry),
                                                  1,
                                                )
                                                this.setState({
                                                  ...this.state,
                                                  criteria,
                                                })
                                              }}
                                              label={list.format.map(prop => {
                                                return `${obj[prop]} `
                                              })}
                                            />
                                          )
                                        } else {
                                          return (
                                            <Chip
                                              classes={{ deleteIcon: 'h-20' }}
                                              className='mb-8 mr-4 h-24'
                                              color='primary'
                                              onDelete={() => {
                                                criteria[key].splice(
                                                  value.indexOf(entry),
                                                  1,
                                                )
                                                this.setState({
                                                  ...this.state,
                                                  criteria,
                                                })
                                              }}
                                              label={'Not Found'}
                                            />
                                          )
                                        }
                                      })}
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
              {dialogTab === 2 && (
                <React.Fragment>
                  <div className='w-full'>
                    <Autocomplete
                      className={'mb-8'}
                      title='Dispatch Folder'
                      options={folders}
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Folder}>
                            {`${value.Name}`}
                          </MenuItem>
                        )
                      }}
                      portal={true}
                      value={this.state.selectedFolder}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          selectedFolder: option.Folder,
                        })
                      }
                      // required
                    />
                    {this.state.selectedFolder &&
                    options.folders.indexOf(this.state.selectedFolder) > -1 ? (
                      <Button
                        className={classNames(classes.formControl, 'w-full')}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          const index = _.findIndex(options.folders, {
                            Co,
                            Folder: this.state.selectedFolder,
                          })
                          options.folders.splice(index, 1)
                          this.setState(
                            { ...this.state, selectedFolder: null, options },
                            this.getWOData,
                          )
                        }}
                        disabled={!this.state.selectedFolder}
                      >
                        Remove Folder
                      </Button>
                    ) : (
                      <Button
                        className={classNames(classes.formControl, 'w-full')}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          options.folders.push(this.state.selectedFolder)
                          this.setState(
                            { ...this.state, selectedFolder: null, options },
                            this.getWOData,
                          )
                        }}
                        disabled={!this.state.selectedFolder}
                      >
                        Add Folder
                      </Button>
                    )}
                  </div>
                  <div className='w-full mb-24'>
                    {selectedFolders.map((folder, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selectedFolder: folder.Folder,
                            })
                          }}
                        >
                          <div className='w-full relative'>
                            <div
                              className={`pin-r pin-t absolute bg-grey-${
                                folder.SharedYN === 'Y' ? 'darkest' : 'dark'
                              } text-white text-10 rounded-b-4 pl-4 pr-4`}
                              style={{ marginTop: -12 }}
                            >
                              {folder.SharedYN === 'Y' ? 'Shared' : 'Private'}
                            </div>
                            <Typography
                              onClick={e =>
                                this.toggleList(e, `folder-${folder.Folder}`)
                              }
                              variant='h6'
                              style={{ alignItems: 'center' }}
                              className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                            >
                              <div className='truncate w-2/3'>
                                {folder.Name}
                              </div>
                              {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                              {(loading || folder.contents.length > 0) &&
                              loading ? (
                                <Icon className='text-20 spin pin-r absolute mr-8'>
                                  refresh
                                </Icon>
                              ) : (
                                <FuseAnimate
                                  animation='transition.expandIn'
                                  delay={300}
                                >
                                  {
                                    <Avatar
                                      className={classNames(
                                        classes.avatar,
                                        'pin-r pin-t mt-4 absolute',
                                      )}
                                    >
                                      {folder.contents.length}
                                    </Avatar>
                                  }
                                </FuseAnimate>
                              )}
                              {folder.contents.length > 0 && (
                                <Icon
                                  className='mr-40 pin-r absolute'
                                  style={{ marginTop: 2 }}
                                >
                                  {expanded.indexOf(`folder-${folder.Folder}`) >
                                  -1
                                    ? 'keyboard_arrow_up'
                                    : 'keyboard_arrow_down'}
                                </Icon>
                              )}
                            </Typography>
                          </div>
                          {expanded.indexOf(`folder-${folder.Folder}`) > -1 && (
                            <List className='p-0'>
                              <FuseAnimateGroup
                                enter={{
                                  animation: 'transition.slideUpBigIn',
                                }}
                                leave={{
                                  animation: 'transition.slideLeftBigOut',
                                }}
                              >
                                {!loading &&
                                  folder.contents.map((value, index) => {
                                    const scopes = _.filter(
                                      value.Data.Scopes,
                                      s => {
                                        return (
                                          new Date(s.DueBy).toLocaleDateString(
                                            'en-US',
                                          ) ===
                                            new Date(date).toLocaleDateString(
                                              'en-US',
                                            ) &&
                                          s.Data.Assignments.length < 1 &&
                                          s.Status < 4
                                        )
                                      },
                                    )
                                    return (
                                      <div className='w-full'>
                                        <DispatchBoardWorkOrder
                                          key={index}
                                          workOrder={value}
                                          scopes={scopes}
                                        />
                                      </div>
                                    )
                                  })}
                                <Typography
                                  onClick={() =>
                                    this.props.openEditDispatchFolderDialog(
                                      folder,
                                    )
                                  }
                                  variant='h6'
                                  className='text-12 w-full text-right mt-8 pr-12 cursor-pointer'
                                  style={{ marginBottom: -8 }}
                                >
                                  <Icon
                                    className='align-middle text-16 mr-6 mb-4'
                                    color='primary'
                                  >
                                    edit
                                  </Icon>
                                  Edit Folder
                                </Typography>
                              </FuseAnimateGroup>
                            </List>
                          )}
                          <Divider className='mt-12' />
                        </div>
                      )
                    })}
                  </div>
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions className='dialog-actions justify-between'>
              <MobileStepper
                variant='dots'
                steps={steps.length}
                position='static'
                activeStep={dialogTab}
                className={'pin-b pin-l pin-r absolute'}
                nextButton={
                  dialogTab < steps.length - 1 ? (
                    <Button
                      size='small'
                      className='min-w-76'
                      onClick={this.handleNext}
                      disabled={dialogTab === steps.length || !this.state.Name}
                    >
                      Next
                      <Icon>keyboard_arrow_right</Icon>
                    </Button>
                  ) : accessLevel && accessLevel.AccessLevel !== 'R' ? (
                    <div>
                      {dispatchBoardDialog.type === 'new' || !this.state.ID ? (
                        <Button
                          variant='contained'
                          color='primary'
                          className='leading-tight text-12 min-w-76'
                          onClick={() => {
                            this.setState(
                              { ...this.state, adding: true },
                              () => {
                                Promise.all([
                                  addDispatchBoard({
                                    ...this.state,
                                    Co,
                                    Options: JSON.stringify(options),
                                    options: null,
                                    criteria: null,
                                  }),
                                ]).then(() =>
                                  this.setState(
                                    { ...this.state, adding: false },
                                    this.closeComposeDialog,
                                  ),
                                )
                              },
                            )
                          }}
                          disabled={!this.canBeSubmitted() || this.state.adding}
                        >
                          Add
                        </Button>
                      ) : (
                        (accessLevel.AccessLevel === 'F' ||
                          this.state.AddedBy === user.UserName) && (
                          <Button
                            variant='contained'
                            color='primary'
                            className='leading-tight text-12 min-w-76'
                            onClick={() => {
                              this.setState(
                                { ...this.state, adding: true },
                                () => {
                                  Promise.all([
                                    updateDispatchBoard({
                                      ...this.state,
                                      Co,
                                      Options: JSON.stringify(options),
                                      options: null,
                                      criteria: null,
                                    }),
                                  ]).then(() =>
                                    this.setState(
                                      { ...this.state, adding: false },
                                      this.closeComposeDialog,
                                    ),
                                  )
                                },
                              )
                            }}
                            disabled={
                              !this.canBeSubmitted() || this.state.adding
                            }
                          >
                            Save
                          </Button>
                        )
                      )}
                    </div>
                  ) : (
                    <div className='min-w-76 min-h-12'></div>
                  )
                }
                backButton={
                  dialogTab > 0 ? (
                    <Button
                      size='small'
                      onClick={this.handleBack}
                      disabled={dialogTab === 0}
                    >
                      <Icon>keyboard_arrow_left</Icon>
                      Back
                    </Button>
                  ) : (
                    <div className='min-w-76 min-h-12'></div>
                  )
                }
              />
            </DialogActions>
          </Dialog>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openEditDispatchFolderDialog: Actions.openEditDispatchFolderDialog,
      closeEditDispatchBoardDialog: Actions.closeEditDispatchBoardDialog,
      closeNewDispatchBoardDialog: Actions.closeNewDispatchBoardDialog,
      addDispatchBoard: Actions.addDispatchBoard,
      updateDispatchBoard: Actions.updateDispatchBoard,
      // removeDispatchBoard: Actions.removeDispatchBoard
    },
    dispatch,
  )
}

function mapStateToProps({ dispatchBoardApp, spReducers }) {
  const {
    businessUnits,
    divisions,
    departments,
    serviceTypes,
    services,
    arrivalWindows,
    teams,
    technicians,
    workOrders,
    folders,
  } = spReducers
  return {
    user: spReducers.userProfiles.User,
    date: dispatchBoardApp.dispatchBoard.date,
    WorkOrders: workOrders,
    workOrderList: dispatchBoardApp.dispatchBoard.workOrderList,
    workOrders: dispatchBoardApp.dispatchBoard.workOrders,
    dispatchBoardDialog: dispatchBoardApp.dispatchBoard.dispatchBoardDialog,
    businessUnits,
    Co: spReducers.companies.Co,
    technicians,
    loading: dispatchBoardApp.dispatchBoard.loading,
    securables: spReducers.userProfiles.User.Data.Securables,
    master: {
      businessUnits,
      divisions,
      departments,
      serviceTypes,
      services,
      arrivalWindows,
      teams,
      technicians,
    },
    customFolders: folders,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(DispatchBoardDialog),
)
