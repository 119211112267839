import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newRateTemplateState = {
    Co: null,
    BusinessUnit: '',
    Division: '',
    RateTemplate: '',
    Breakpoint: null,
    Markup: 0.00,
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    }
};

class RateTemplateMaterialDialog extends Component {
    state = { ...newRateTemplateState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newRateTemplateState.Co = this.props.Co;
        if (!prevProps.rateTemplateMaterialDialog.props.open && this.props.rateTemplateMaterialDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.rateTemplateMaterialDialog.type === 'edit' &&
                this.props.rateTemplateMaterialDialog.data &&
                !_.isEqual(this.props.rateTemplateMaterialDialog.data, prevState)) {
                this.setState({ ...this.props.rateTemplateMaterialDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.rateTemplateMaterialDialog.type === 'new' &&
            this.props.rateTemplateMaterialDialog.data &&
            !_.isEqual(this.props.rateTemplateMaterialDialog.data, prevState)) {
            this.setState({ ...newRateTemplateState, ...this.props.rateTemplateMaterialDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.rateTemplateMaterialDialog.type === 'edit' ? this.props.closeEditRateTemplateMaterialDialog() : this.props.closeNewRateTemplateMaterialDialog();
    };

    canBeSubmitted() {
        const { RateTemplate, BusinessUnit, Division, Breakpoint, Markup} = this.state;
        return (
            RateTemplate.length > 0 && BusinessUnit.length > 0 && Division.length > 0 && !isNaN(Breakpoint) && !isNaN(Markup)
        );
    }

    render() {
        const { classes, rateTemplateMaterialDialog, addRateTemplateMaterial, updateRateTemplateMaterial, removeRateTemplateMaterial, businessUnits, divisions, rateTemplates, laborTypes } = this.props;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                className={classes.root}
                {...rateTemplateMaterialDialog.props}
                onClose={this.closeComposeDialog}
                fullWidth
                maxWidth="xs"
            >

                <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            {rateTemplateMaterialDialog.type === 'new' ? 'New Material Breakpoint' : 'Edit Material Breakpoint'}
                        </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                <DialogContent classes={{ root: "p-24" }}>
                    <div className="flex">

                        <TextField
                            className={classes.formControl}
                            label="Business Unit"
                            
                            select
                            id="businessUnit"
                            name="BusinessUnit"
                            value={this.state.BusinessUnit}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                            disabled={true}
                        >
                            {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                <MenuItem value={value.BusinessUnit}>
                                    {value.Description}
                                </MenuItem>
                            )) : '')}
                        </TextField>
                    </div>

                    <div className="flex">

                        <TextField
                            className={classes.formControl}
                            label="Division"
                            id="division"
                            select
                            name="Division"
                            value={this.state.Division}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                            disabled={true}
                        >
                            {(this.state.BusinessUnit && this.state.BusinessUnit.length > 0 ?
                                (divisions && divisions.length > 0 ? divisions.map((value) => (
                                    (this.state.BusinessUnit == value.BusinessUnit ?
                                        <MenuItem value={value.Division}>
                                            {value.Description}
                                        </MenuItem> : '')
                                )) : '')
                                :
                                <MenuItem value="">
                                    Please Select a Business Unit
                                </MenuItem>
                            )
                            }
                        </TextField>
                    </div>
                    <div className="flex">

                        <TextField
                            className={classes.formControl}
                            label="Rate Template"
                            
                            id="rateTemplate"
                            name="RateTemplate"
                            value={this.state.RateTemplate}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                            disabled={true}
                            >
                                {(rateTemplates && rateTemplates.length > 0 ? rateTemplates.map((value) => (
                                    <MenuItem value={value.RateTemplate}>
                                        {value.Description}
                                    </MenuItem>
                                )) : '')}
                            </TextField>
                    </div>
                    <div className="flex">

                        <TextField
                            className={classes.formControl}
                            label="Breakpoint"
                            
                            id="breakpoint"
                            name="Breakpoint"
                            value={this.state.Breakpoint}
                            onChange={this.handleChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                type: "number"
                            }}
                            variant="outlined"
                            required
                            fullWidth
                            disabled={(rateTemplateMaterialDialog.type === 'new' ? false : true)}
                            />
                    </div>

                    <div className="flex">
                        <TextField
                            className={classes.formControl}
                            label="Markup"
                            id="markup"
                            name="Markup"
                            value={this.state.Markup}
                            onChange={this.handleChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                type: "number"
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </DialogContent>

                {rateTemplateMaterialDialog.type === 'new' ? (
                    <DialogActions className="dialog-actions justify-between pl-16">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                addRateTemplateMaterial(this.state);
                                this.closeComposeDialog();
                            }}
                            disabled={!this.canBeSubmitted()}
                        >
                            Add
                        </Button>
                    </DialogActions>
                ) : (
                        <DialogActions className="dialog-actions justify-between pl-16">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    updateRateTemplateMaterial(this.state);
                                    this.closeComposeDialog();
                                }}
                                disabled={!this.canBeSubmitted()}
                            >
                                Save
                        </Button>
                            <IconButton
                                onClick={() => {
                                    removeRateTemplateMaterial(this.state);
                                    this.closeComposeDialog();
                                }}
                            >
                                <Icon>delete</Icon>
                                    </IconButton>
                                </DialogActions>
                            )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditRateTemplateMaterialDialog: Actions.closeEditRateTemplateMaterialDialog,
        closeNewRateTemplateMaterialDialog: Actions.closeNewRateTemplateMaterialDialog,
        addRateTemplateMaterial: Actions.addRateTemplateMaterial,
        updateRateTemplateMaterial: Actions.updateRateTemplateMaterial,
        removeRateTemplateMaterial: Actions.removeRateTemplateMaterial
    }, dispatch);
}

function mapStateToProps({ rateTemplatesApp, spReducers }) {
    return {
        rateTemplateMaterialDialog: rateTemplatesApp.rateTemplates.rateTemplateMaterialDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        rateTemplates: spReducers.rateTemplates,
        Co: spReducers.companies.Co,
        laborTypes: spReducers.laborTypes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RateTemplateMaterialDialog));
