import * as Actions from '../actions';
import CacheManager from 'store/middlewares/cache';

import _ from '@lodash';

const offline = new CacheManager();

const initialState = {
    entities: [],
    searchText: '',
    selectedWorkOrderIds: [],
    routeParams: {},
    workOrderDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        preload: false,
        splitOpen: false,
    }
};

const workOrdersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_WORK_ORDERS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        //case Actions.LOAD_WORK_ORDERS
        //    {
        //        return {
        //            ...state,
        //            workOrders: action.workOrders
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_WORK_ORDERS:
            {

                const workOrderId = action.workOrderId;

                let selectedWorkOrderIds = [...state.selectedWorkOrderIds];

                if (selectedWorkOrderIds.find(id => id === workOrderId) !== undefined) {
                    selectedWorkOrderIds = selectedWorkOrderIds.filter(id => id !== workOrderId);
                }
                else {
                    selectedWorkOrderIds = [...selectedWorkOrderIds, workOrderId];
                }

                return {
                    ...state,
                    selectedWorkOrderIds: selectedWorkOrderIds
                };
            }
        case Actions.SELECT_ALL_WORK_ORDERS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedWorkOrderIds = arr.map(workOrder => workOrder.id);

                return {
                    ...state,
                    selectedWorkOrderIds: selectedWorkOrderIds
                };
            }
        case Actions.DESELECT_ALL_WORK_ORDERS:
            {
                return {
                    ...state,
                    selectedWorkOrderIds: []
                };
            }
        case Actions.OPEN_NEW_WORK_ORDER_DIALOG:
            {
                return {
                    ...state,
                    workOrderDialog: {
                        type: (action.data.Customer ? 'site' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_WORK_ORDER_DIALOG:
            {
                return {
                    ...state,
                    workOrderDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_WORK_ORDER_DIALOG:
            {
                return {
                    ...state,
                    workOrderDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        preload: action.preload,
                        splitOpen: action.splitOpen,
                    }
                };
            }
        case Actions.ADD_EDIT_WORK_ORDER_DIALOG:
            {
                return {
                    ...state,
                    workOrderDialog: {
                        ...state.workOrderDialog,
                        type: 'update',
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_WORK_ORDER_DIALOG:
            {
                return {
                    ...state,
                    workOrderDialog: {
                        ...state.workOrderDialog,
                        type: 'update',
                        data: action.data,
                        preload: false,
                        splitOpen: action.splitOpen,
                    }
                };
            }
        case Actions.CLOSE_EDIT_WORK_ORDER_DIALOG:
            {
                return {
                    ...state,
                    workOrderDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        preload: false,
                        splitOpen: false,
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default workOrdersReducer;