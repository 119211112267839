import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedAgreementIds: [],
    routeParams: {},
    filters: null,
    loading: false,
    processing: false,
    amortizations: [],
    billings: [],
    tasks: [],
    waivedTasks: [],
    agreementDialog: {
        type: 'new',
        props: {
            open: false,
            onAdded: undefined,
            onUpdated: undefined,
        },
        data: {
            ID: null,
            Co: null,
            BusinessUnit: null,
            Division: null,
            AgreementType: null,
            Customer: null,
            Site: null,
            Agreement: null,
            Cycle: 1,
            Revision: 0,
            SoldBy: null,
            StartDate: null,
            EndDate: null,
            TotalPrice: null,
            ActiveYN: 'Y',
            Notes: null,
            WorkOrder: null,
            Scope: null,
            AttachmentID: null,
            MultiSiteYN: null,
            Data: {
                AgreementType: null,
                Customer: null,
                Site: null,
                Sites: [],
                Tasks: [],
                Variations: [],
                Attachments: [],
                Schedule: [],
                ErrMsg: null,
            },
            needsRevision: false,
            newCycle: false,
            sourceWO: null
        }
    }
};

const agreementsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_LOADING: {
            const { loading } = action;
            return {
                ...state,
                loading
            }
        }
        case Actions.SET_PROCESSING: {
            const { processing } = action;
            return {
                ...state,
                processing
            }
        }
        case Actions.SET_SELECTED_TASKS: {
            return {
                ...state,
                tasks: action.tasks
            }
        }
        case Actions.SET_WAIVED_TASKS: {
            return {
                ...state,
                waivedTasks: action.tasks
            }
        }
        case Actions.SET_SELECTED_BILLINGS: {
            return {
                ...state,
                billings: action.billings
            }
        }
        case Actions.SET_SELECTED_AMORTIZATIONS: {
            return {
                ...state,
                amortizations: action.amortizations
            }
        }
        case Actions.SET_AGREEMENTS: {
            return {
                ...state,
                entities: action.agreements,
                selectedAgreementIds: [],
                filters: action.filters,
                loading: action.loading
            }
        }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.SET_AGREEMENT_FILTERS:
            {
                const { filters } = action;
                return {
                    ...state,
                    filters
                }
            }
        case Actions.TOGGLE_IN_SELECTED_AGREEMENTS:
            {

                const agreementId = action.agreementId;

                let selectedAgreementIds = [...state.selectedAgreementIds];

                if (selectedAgreementIds.find(id => id === agreementId) !== undefined) {
                    selectedAgreementIds = selectedAgreementIds.filter(id => id !== agreementId);
                }
                else {
                    selectedAgreementIds = [...selectedAgreementIds, agreementId];
                }

                return {
                    ...state,
                    selectedAgreementIds: selectedAgreementIds
                };
            }
        case Actions.SELECT_ALL_AGREEMENTS:
            {
                const { selectedAgreementIds } = action;
                return {
                    ...state,
                    selectedAgreementIds,
                };
            }
        case Actions.DESELECT_ALL_AGREEMENTS:
            {
                return {
                    ...state,
                    selectedAgreementIds: []
                };
            }
        case Actions.OPEN_NEW_AGREEMENT_DIALOG:
            {
                const { data, onAdded, onUpdated } = action;
                return {
                    ...state,
                    agreementDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            onAdded,
                            onUpdated
                        },
                        data: {
                            ...initialState.agreementDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_AGREEMENT_DIALOG:
            {
                return {
                    ...state,
                    agreementDialog: {
                        ...initialState.agreementDialog,
                    }

                };
            }
        case Actions.OPEN_EDIT_AGREEMENT_DIALOG:
            {
                const { data, source } = action;
                return {
                    ...state,
                    agreementDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.agreementDialog.data,
                            ...data,
                            sourceWO: source
                        }
                    }
                };
            }
        case Actions.RENEW_EDIT_AGREEMENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    agreementDialog: {
                        type: 'renew',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.agreementDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_AGREEMENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    agreementDialog: {
                        ...state.agreementDialog,
                        type: 'update',
                        data: {
                            ...initialState.agreementDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.REVISE_AGREEMENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    agreementDialog: {
                        ...state.agreementDialog,
                        type: 'revise',
                        data: {
                            ...initialState.agreementDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.SCHEDULE_EDIT_AGREEMENT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    agreementDialog: {
                        ...state.agreementDialog,
                        type: 'schedule',
                        props: {
                            open: action.open || state.open
                        },
                        data: {
                            ...initialState.agreementDialog.data,
                            ...data,
                        }
                    }
                };
            }
            case Actions.INVOICE_EDIT_AGREEMENT_DIALOG:
                {
                    const { data } = action;
                    return {
                        ...state,
                        agreementDialog: {
                            ...state.agreementDialog,
                            type: 'invoice',
                            props: {
                                open: action.open || state.open
                            },
                            data: {
                                ...initialState.agreementDialog.data,
                                ...data,
                            }
                        }
                    };
                }
        case Actions.CLOSE_EDIT_AGREEMENT_DIALOG:
            {
                return {
                    ...state,
                    agreementDialog: {
                        ...initialState.agreementDialog,
                    }

                };
            }
        default:
            {
                return state;
            }
    }
};

export default agreementsReducer;
