import React, { Component } from 'react';
import { TextField, Button, Avatar, Icon, Typography, List } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import CardActivity from './activity/CardActivity';
import CardComment from './comment/CardComment';
import _ from '@lodash';

class Comments extends Component {
    state = {
        message: ''
    };

    render() {
        const { commentAdd, recordID, type, comments } = this.props;

        return (
            <div className="mb-24">
                <div className="flex items-center">
                    <Icon className="text-24 mr-8" color="primary">comment</Icon>
                    <Typography className="font-600 text-16">Comments</Typography>
                </div>
                <CardComment
                    recordID={recordID}
                    type={type}
                    onCommentAdd={commentAdd}
                />

                <div className="flex items-center mt-16 mb-12">
                    <Icon className="text-20 mr-8" color="inherit">list</Icon>
                    <Typography className="font-600 text-16">Activity</Typography>
                </div>
                {comments && comments.length > 0 && (
                    <div className="mb-24">
                        <List className="">
                            {comments.map(item => (
                                <CardActivity
                                    item={item}
                                    key={item.ID}
                                />
                            )
                            )}
                        </List>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}

export default connect(mapStateToProps)(Comments);
