import React, { Component } from 'react';
import { Checkbox, Icon, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import ToolbarMenu from './ToolbarMenu';
import _ from '@lodash';

class LabelsMenu extends Component {
    state = {
        anchorEl: null
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { labels, idLabels, onToggleLabel, card } = this.props;

        return (
            <div>
                <IconButton color="inherit" onClick={this.handleMenuClick}>
                    <Icon>label</Icon>
                </IconButton>
                <ToolbarMenu state={this.state.anchorEl} onClose={this.handleMenuClose}>
                    <div className="max-h-256 overflow-y-auto">
                        {labels && labels.map(label => {
                            const checked = Boolean(_.find([...this.props.idLabels], { TagID: label.ID }));
                            return (
                                <MenuItem
                                    className="px-8"
                                    key={label.ID}
                                    onClick={(ev) => {
                                        const { Co, BoardID, ListID, ID } = card;
                                        onToggleLabel({ Co, BoardID, ListID, CardID: ID, TagID: label.ID });
                                    }}
                                >
                                    <Checkbox checked={checked} />
                                    <ListItemText>
                                        {label.Name}
                                    </ListItemText>
                                    <ListItemIcon>
                                        <Icon style={{ color: label.Background }}>label</Icon>
                                    </ListItemIcon>
                                </MenuItem>
                            );
                        })}
                    </div>
                </ToolbarMenu>
            </div>
        );
    };
}

export default LabelsMenu;
