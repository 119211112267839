import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Button, Chip, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, MenuItem, Switch, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import Recorder from 'recorder-js';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;
const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newCallGatherKeyState = {
    ID: '',
    Co: null,
    Gather: null,
    Key: '0',
    Action: 'Enqueue',
    AssignedQueue: null,
    AssignedGather: null,
    AssignedDial: null,
    AssignedHuntGroup: null,
    AssignedVoiceMailbox: null,
    RecordAssignedDialYN: null,
    Message: '',
    MessageVoice: '',
    MessageType: 'text',
    Description: '',
    Notes: '',
    ActiveYN: 'Y',
    value: 0,
    MessageClip: '',
    VocalPitch: 'medium',
    RateOfSpeech: 'medium',
    Volume: 'medium',
    MessageFileID: null,
    hasRecordedAudio: false,
    isRecordingAudio: false,
    recordedAudioFileName: '',
    Data: {
        ErrMsg: '',
        AudioClips: [],
    },
};

const rates = { "x-slow": .35, "slow": .45, "medium": .55, "fast": .6, "x-fast": .65 };
const pitches = { "x-low": .43, "low": .47, "medium": .5, "high": 0.53, "x-high": 0.56 };
const volumes = { "x-soft": .25, "soft": .5, "medium": .75, "loud": .9, "'x-loud": 1 };

const API_KEY = process.env.REACT_APP_MAP_KEY;
const GOOGLE_TEXT_TO_SPEECH_URL = 'https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=' + API_KEY
const SIP_ENDPOINT = '';

class CallGatherKeysDialog extends Component {
    state = {
        ..._.cloneDeepWith(newCallGatherKeyState),
        AddedBy: '',
        AddedDate: new Date(),
    };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {

        const { UserName } = this.props.user;
        if (!_.isEqual(UserName, prevProps.user.UserName)) {
            this.setState({ ...this.state, AddedBy: UserName });
        }
        /**
         * After Dialog Open
         */
        newCallGatherKeyState.Co = this.props.Co;
        if (!prevProps.CallGatherKeysDialog.props.open && this.props.CallGatherKeysDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.CallGatherKeysDialog.type === 'edit' &&
                this.props.CallGatherKeysDialog.data &&
                !_.isEqual(this.props.CallGatherKeysDialog.data, prevState)) {
                const data = this.props.CallGatherKeysDialog.data;
                this.setState({ ..._.cloneDeepWith(newCallGatherKeyState), ...this.props.CallGatherKeysDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.CallGatherKeysDialog.type === 'new' &&
                !_.isEqual(newCallGatherKeyState, prevState)) {
                const { data } = this.props.CallGatherKeysDialog;
                window["warn"](data);
                const { Co, Keys, Gather } = data;
                for (var i = 0; i < Keys.length; i++) {
                    if (keys.indexOf(Keys[i].Key.toString()) > -1) {
                        keys.splice(keys.indexOf(Keys[i].Key.toString()), 1);
                    }
                }
                this.setState({ ..._.cloneDeepWith(newCallGatherKeyState), Co, Gather, }, () => window["warn"](this.state));
            }
        }
        //window["log"]('STATE COMPARISON', this.props.CallGatherKeysDialog, prevProps, prevState);
        if (prevProps.CallGatherKeysDialog.props.open && this.props.CallGatherKeysDialog.props.open) {
            if (this.props.CallGatherKeysDialog.type === 'data' &&
                this.props.CallGatherKeysDialog.data &&
                !_.isEqual(this.props.CallGatherKeysDialog.data, prevState)) {
                const data = this.props.CallGatherKeysDialog.data;
                this.props.CallGatherKeysDialog.type = 'edit';
                this.setState({ ...this.props.CallGatherKeysDialog.data });
            }
        }
        if (prevProps.CallGatherKeysDialog.props.open && this.props.CallGatherKeysDialog.props.open) {
            if (this.props.CallGatherKeysDialog.type === 'add' &&
                this.props.CallGatherKeysDialog.data &&
                !_.isEqual(this.props.CallGatherKeysDialog.data, prevState)) {
                const data = this.props.CallGatherKeysDialog.data;
                this.props.showMessage({
                    message: `CallGatherKey ${this.props.CallGatherKeysDialog.data.CallGatherKey} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.CallGatherKeysDialog.type = 'edit';
                this.setState({ ...this.props.CallGatherKeysDialog.data });
            }
        }
        if (prevProps.CallGatherKeysDialog.props.open && this.props.CallGatherKeysDialog.props.open) {
            if (this.props.CallGatherKeysDialog.type === 'update' &&
                this.props.CallGatherKeysDialog.data &&
                !_.isEqual(this.props.CallGatherKeysDialog.data, prevState)) {
                const data = this.props.CallGatherKeysDialog.data;
                this.props.showMessage({
                    message: `CallGatherKey ${this.props.CallGatherKeysDialog.data.CallGatherKey} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.CallGatherKeysDialog.type = 'edit';
                this.setState({ ...this.props.CallGatherKeysDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.CallGatherKeysDialog.type === 'edit' ? this.props.closeEditCallGatherKeysDialog() : this.props.closeNewCallGatherKeysDialog();
    };

    canBeSubmitted() {
        const { Gather, Key, Action, AssignedQueue, AssignedDial, AssignedGather, AssignedHuntGroup, Message } = this.state;
        return (
            Gather && Gather.toString().length > 0 &&
            Key && Key.toString().length > 0 &&
            Action && Action.length > 0 &&
            (
                (AssignedQueue && AssignedQueue.length > 0) ||
                (AssignedGather && AssignedGather.toString().length > 0) ||
                (AssignedDial && AssignedDial.length > 0) ||
                (AssignedHuntGroup && AssignedHuntGroup.toString().length > 0)
            )
        );
    }

    parseSSML = () => {
        const { Message } = this.state;
        const ssml = Message;
        var tags = [];
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        for (var i = 0; i < nodes.length; i++) {
            var tag = {};
            var node = nodes[i];
            tag.Say = node.childNodes[0].nodeValue;
            tag.Attributes = '';
            for (var a = 0; a < node.attributes.length; a++) {
                tag[node.attributes[a].name] = node.attributes[a].value;
                tag.Attributes += ` ${node.attributes[a].name}=${node.attributes[a].value} `;
            }
            tags.push(tag);
        }
        window["warn"](tags, ssml, nodes, SSML);
        return tags;
    }

    buildSSML = (tags) => {
        let Message = '';
        for (var i = 0; i < tags.length; i++) {
            const tag = tags[i];
            Message += `<prosody pitch="${tag.pitch ? tag.pitch : 'medium'}" rate="${tag.rate ? tag.rate : 'medium'}" volume="${tag.volume ? tag.volume : 'medium'}">${tag.Say}</prosody>`;
        }
        return Message;
    }

    speak = async () => {
        const { Message, Phone, } = this.state;
        const { user } = this.props;

        var value = {
            UserName: user.UserName,
            SSML: Message,
            Voice: 'man',
            Data: {
                ErrMsg: '',
            }
        };

        const request = axios.put(`${window["apiLocation"]}/api/Speech?Phone=${encodeURIComponent(Phone)}`, value);
        request.then((r) => { window["warn"](r); this.props.setAwaitingCallFrom(Phone); });


        // const request = axios.delete(`${window["apiLocation"]}/api/Speech?Sid=${encodeURIComponent('dfc63963-cf4b-4183-bf12-5577d001d295')}`);
        // request.then((r)=>{ window["warn"](r); });




        // const tags = this.parseSSML();
        // let Message = '';
        // for (var t = 0; t< tags.length; t++) {
        //     const tag = tags[t];
        //     Message += `<prosody pitch="${pitches[tag.pitch ? tag.pitch : 'medium']}" rate="${rates[tag.rate ? tag.rate : 'medium']}" volume="${volumes[tag.volume ? tag.volume : 'medium']}">${tag.Say}</prosody>`;
        // }
        // const ssml = `<speak>${Message}</speak>`;
        // window["warn"](ssml);
        // // Prepare the Google Text-to-Speech configuration
        // const body = JSON.stringify({
        //     input: { ssml },
        //     // Select the language code and voice name (A-F)
        //     voice: { languageCode: 'en-US', name: 'en-US-Wavenet-F' },
        //     // Use MP3 in order to play in browser
        //     audioConfig: { audioEncoding: 'MP3' }
        // })
        // // Send the SSML string to the API
        // const res = await fetch(GOOGLE_TEXT_TO_SPEECH_URL, {
        //     method: 'POST',
        //     body
        // }).then(res => res.json())
        // // Play the returned audio with the Browser’s Audo API
        // const audio = new Audio('data:audio/wav;base64,' + res.audioContent)
        // audio.play();
    }

    addClip = () => {
        let { Message, MessageClip, RateOfSpeech, VocalPitch, Volume } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: MessageClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.push(tag);
        const newMessage = this.buildSSML(tags);
        this.setState({ ...this.state, Message: newMessage, MessageClip: '', });
    }

    saveClip = () => {
        let { Message, MessageClip, RateOfSpeech, VocalPitch, Volume, EditClip } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: MessageClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.splice(EditClip, 1, tag);
        const newMessage = this.buildSSML(tags);
        EditClip = null;
        this.setState({ ...this.state, Message: newMessage, MessageClip: '', EditClip });
    }

    removeClip = (key) => {
        const tags = this.parseSSML();
        tags.splice(key, 1);
        const newMessage = this.buildSSML(tags);
        this.setState({ ...this.state, Message: newMessage });
    }

    editClip = (key) => {
        const tags = this.parseSSML();
        const tag = tags[key];
        this.setState({ ...this.state, MessageClip: tag.Say, RateOfSpeech: tag.rate ? tag.rate : 'medium', VocalPitch: tag.pitch ? tag.pitch : 'medium', Volume: tag.volume ? tag.volume : 'medium', EditClip: key })
    }

    clipFileSelectedHandler = (event) => {
        const { Data } = this.state;
        const fd = new FormData();
        const fn = event.target.files[0].name;
        const file = new Blob([event.target.files[0]], { type: event.target.files[0].type });
        fd.append('file', file, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipFileUploadProgress.innerHTML = percentCompleted === 100 ? 'Upload Clip' : `${percentCompleted}%`;
            }
        };
        axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                // this.addAudioClip(res.data.Attachment);
                this.setState({ ...this.state, MessageFileID: res.data.Attachment.Attachment });
            }
        });
    }

    addAudioClip = (att) => {
        const { Data } = this.state;
        let { AudioClips } = Data;
        AudioClips.push(att);
        this.setState({ ...this.state, Data: { ...Data, AudioClips } });
    }

    messageAudioPaused = () => {
        this.setState({ ...this.state, messageAudioPlaying: false });
    }

    messageAudioPlaying = () => {
        this.setState({ ...this.state, messageAudioPlaying: true });
    }

    startRecordingAudio = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            this.handleStream(stream);
        })
    }

    handleStream = (stream) => {

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const recorder = new Recorder(audioContext);
        recorder.init(stream);
        this.setState({ ...this.state, isRecordingAudio: recorder, audioStream: stream, hasRecordedAudio: false }, () => { recorder.start(); });
    }

    stopRecordingAudio = () => {
        const { isRecordingAudio, audioStream } = this.state;
        isRecordingAudio.stop().then(({ blob, buffer }) => {
            this.recordedAudioPlayback.src = URL.createObjectURL(blob);
            audioStream.getTracks()[0].stop();
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: blob, audioStream: null });
        });
    }

    deleteRecordedAudio = () => {
        this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false });
    }

    uploadRecordedAudio = () => {
        const { hasRecordedAudio, recordedAudioFileName } = this.state;
        const fd = new FormData();
        const fn = `${recordedAudioFileName}.wav`;
        fd.append('file', hasRecordedAudio, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipUploadProgress.innerHTML = percentCompleted === 100 ? '' : `${percentCompleted}%`;
            }
        };
        const request = axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            // if (res.data.Attachment) {
            //     this.addAudioClip(res.data.Attachment);
            // }
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false, VoicemailFileID: res.data.Attachment.Attachment });
        });
    }

    render() {
        const { classes, securables, theme, CallGatherKeysDialog, addCallGatherKey, updateCallGatherKey, voiceMailboxes, removeCallGatherKey, businessUnits, divisions, departments, queues, awaitingCallFrom, setAwaitingCallFrom, AudioClips, gathers, huntGroups } = this.props
        const { Co, BusinessUnit, Phone, value, Message, EditClip, Data, Action, ID } = this.state;
        const SSMLTags = this.parseSSML();
        const accessLevel = _.find(securables, { Securable: "call-gathers" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...CallGatherKeysDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(CallGatherKeysDialog.type) > -1 ? `Gather #${this.state.Gather} New Key Press` : `Gather #${this.state.Gather} Key #${this.state.Key} Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <FuseAnimate animation="transition.slideUpIn" delay={300}>
                            <DialogContent classes={{ root: "p-24" }}>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        <Icon color="action">dialpad</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Key Press"
                                        id="key"
                                        select
                                        name="Key"
                                        value={this.state.Key}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        style={{ width: 200 }}
                                        placeholder="Key Press"
                                        disabled={Boolean(this.state.ID)}
                                    >
                                        {!ID ? keys.map((value, index) =>
                                            <MenuItem key={index} value={value}>
                                                {value}
                                            </MenuItem>
                                        ) :
                                            <MenuItem value={this.state.Key}>
                                                {this.state.Key}
                                            </MenuItem>
                                        }
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Action"
                                        id="action"
                                        name="Action"
                                        value={this.state.Action}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: this.state.Action ? true : false }}
                                        variant="outlined"
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value="Enqueue">
                                            Enqueue
                                        </MenuItem>
                                        <MenuItem value="Gather">
                                            Gather
                                        </MenuItem>
                                        <MenuItem value="Dial">
                                            Transfer
                                        </MenuItem>
                                        <MenuItem value="Hunt">
                                            Hunt Group
                                        </MenuItem>
                                        <MenuItem value="Voicemail">
                                            Voicemail
                                        </MenuItem>
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {
                                        Action === "Enqueue" &&
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Queue"
                                            options={queues}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.Queue}>
                                                    {value.Queue}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.AssignedQueue}
                                            onSelect={(option) => this.setState({ ...this.state, AssignedQueue: option.Queue })}
                                            required
                                        />
                                    }
                                    {
                                        Action === "Gather" &&
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Gather"
                                            options={gathers}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.ID}>
                                                    {value.ID} - {value.Name}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.AssignedGather}
                                            onSelect={(option) => this.setState({ ...this.state, AssignedGather: option.ID })}
                                            required
                                        />
                                    }
                                    {
                                        Action === "Dial" &&
                                        <TextField
                                            className={classes.formControl}
                                            label="Transfer To"
                                            id="defaultDial"
                                            name="AssignedDial"
                                            value={this.state.AssignedDial}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.AssignedDial ? true : false }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                    {
                                        Action === "Hunt" &&
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Hunt Group"
                                            options={huntGroups}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.ID}>
                                                    {value.Name}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.AssignedHuntGroup}
                                            onSelect={(option) => this.setState({ ...this.state, AssignedHuntGroup: option.ID })}
                                            required
                                        />
                                    }
                                    {
                                        Action === "Voicemail" &&
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Voice Mailbox"
                                            options={voiceMailboxes}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.VoiceMailbox}>
                                                    {`${value.VoiceMailbox} - ${value.Name}`}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.AssignedVoiceMailbox}
                                            onSelect={(option) => this.setState({ ...this.state, AssignedVoiceMailbox: option.VoiceMailbox })}
                                            required
                                        />
                                    }
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        <Icon color="action">record_voice_over</Icon>
                                    </div>

                                    {
                                        this.state.MessageType == 'voice' &&
                                        <React.Fragment>
                                            <div className="w-full">
                                                <div className="sm:flex w-full">
                                                    <TextField
                                                        className={classNames(classes.formControl, "w-full")}
                                                        label="Message Type"
                                                        id="messageType"
                                                        select
                                                        name="MessageType"
                                                        value={this.state.MessageType}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        // style={{ minWidth: 200 }}
                                                        placeholder="Message Type"
                                                    >
                                                        <MenuItem value="voice">
                                                            Audio Recording
                                                        </MenuItem>
                                                        <MenuItem value="text">
                                                            Text-to-Speech
                                                        </MenuItem>
                                                    </TextField>
                                                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                                                        <div className="hidden sm:block min-w-12 pt-20">
                                                        </div>
                                                    }
                                                    {(accessLevel && accessLevel.AccessLevel !== "R") && (!this.state.isRecordingAudio && !this.state.hasRecordedAudio) ?
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={classNames(classes.formControl, "w-full text-12")}
                                                            onClick={this.startRecordingAudio}
                                                        >
                                                            <Icon className="mr-4">mic_none</Icon> Record Clip
                                                        </Button>
                                                        :
                                                        (accessLevel && accessLevel.AccessLevel !== "R") && !this.state.hasRecordedAudio ?
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                className={classNames(classes.formControl, "w-full text-12")}
                                                                onClick={this.stopRecordingAudio}
                                                            >
                                                                <Icon className="mr-4">stop</Icon> Stop Recording
                                                            </Button>
                                                            :
                                                            (accessLevel && accessLevel.AccessLevel !== "R") &&
                                                            <div className="flex w-full">
                                                                <Tooltip title="Upload Audio Clip">
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        className={classNames(classes.formControl, "w-full text-12")}
                                                                        // style={{ minWidth: 200 }}
                                                                        onClick={this.uploadRecordedAudio}
                                                                    >
                                                                        <Icon className="mr-4">cloud_upload</Icon> <span ref={clipUploadProgress => this.clipUploadProgress = clipUploadProgress}></span>
                                                                    </Button>
                                                                </Tooltip>
                                                                <div className="hidden sm:block min-w-12 pt-20">
                                                                </div>
                                                                <Tooltip title="Delete Audio Clip">
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classNames(classes.formControl, "w-full text-12")}
                                                                        // style={{ minWidth: 200 }}
                                                                        onClick={this.deleteRecordedAudio}
                                                                    >
                                                                        <Icon className="mr-4">delete</Icon>
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    (this.state.isRecordingAudio || this.state.hasRecordedAudio) &&
                                                    <React.Fragment>
                                                        <TextField
                                                            className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")}
                                                            label="Clip Name"
                                                            id="clipName"
                                                            name="recordedAudioFileName"
                                                            value={this.state.recordedAudioFileName}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={Boolean(this.state.isRecordingAudio)}
                                                        />
                                                        <audio className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")} controls style={{ width: '100%' }} ref={(ref) => { this.recordedAudioPlayback = ref }} />
                                                    </React.Fragment>
                                                }
                                            </div>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <div className="w-full">
                                                <div className="sm:flex w-full">
                                                    <div className="w-full">
                                                        <TextField
                                                            className={classNames(classes.formControl, "w-full")}
                                                            label="Audio"
                                                            id="messageFileID"
                                                            select
                                                            name="MessageFileID"
                                                            value={this.state.MessageFileID}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            fullWidth
                                                            placeholder="Select Message Audio"
                                                            InputLabelProps={{ shrink: this.state.MessageFileID ? true : false }}
                                                            disabled={this.state.messageAudioPlaying}
                                                        >
                                                            <MenuItem value={null}>
                                                                None
                                                            </MenuItem>
                                                            {(AudioClips && AudioClips.length > 0 ? AudioClips.map((value) => (
                                                                <MenuItem value={value.Attachment}>
                                                                    {value.FileName}
                                                                </MenuItem>
                                                            )) : '')}
                                                        </TextField>
                                                        {
                                                            this.state.MessageFileID &&
                                                            <audio className={classes.formControl} controls style={{ width: '100%' }} ref="acceptedAudioPlay" src={`${window["apiLocation"]}/api/AudioFile/${this.state.MessageFileID}.wav`} onPause={this.acceptedAudioPaused} onPlay={this.acceptedAudioPlaying} />
                                                        }
                                                    </div>
                                                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                                                        <React.Fragment>
                                                            <div className="hidden sm:block min-w-12 pt-20">
                                                            </div>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={classNames(classes.formControl, "w-full text-12")}
                                                                onClick={() => this.clipFileInput.click()}
                                                            >
                                                                <Icon className="mr-4">cloud_upload</Icon> <span ref={clipFileUploadProgress => this.clipFileUploadProgress = clipFileUploadProgress}>Upload Clip</span>
                                                            </Button>
                                                            <input
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={this.clipFileSelectedHandler}
                                                                ref={clipFileInput => this.clipFileInput = clipFileInput}
                                                                accept=".mp3,.wav"
                                                            />
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.MessageType == 'text' &&
                                        <React.Fragment>
                                            <TextField
                                                className={"mb-12"}
                                                label="Message Type"
                                                id="MessageType"
                                                select
                                                name="MessageType"
                                                value={this.state.MessageType}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Message Type"
                                            >
                                                <MenuItem value="voice">
                                                    Audio Recording
                                                </MenuItem>
                                                <MenuItem value="text">
                                                    Text-to-Speech
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={"mb-12"}
                                                label="Rate"
                                                id="rate"
                                                select
                                                name="RateOfSpeech"
                                                value={this.state.RateOfSpeech}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Rate of Speech"
                                            >
                                                <MenuItem value="x-slow">
                                                    X-Slow
                                                </MenuItem>
                                                <MenuItem value="slow">
                                                    Slow
                                                </MenuItem>
                                                <MenuItem value="medium">
                                                    Normal
                                                </MenuItem>
                                                <MenuItem value="fast">
                                                    Fast
                                                </MenuItem>
                                                <MenuItem value="x-fast">
                                                    X-Fast
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={"mb-12"}
                                                label="Pitch"
                                                id="vocalPitch"
                                                select
                                                name="VocalPitch"
                                                value={this.state.VocalPitch}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Vocal Pitch"
                                            >
                                                <MenuItem value="x-low">
                                                    X-Low
                                                </MenuItem>
                                                <MenuItem value="low">
                                                    Low
                                                </MenuItem>
                                                <MenuItem value="medium">
                                                    Normal
                                                </MenuItem>
                                                <MenuItem value="high">
                                                    High
                                                </MenuItem>
                                                <MenuItem value="x-high">
                                                    X-High
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={"mb-12"}
                                                label="Volume"
                                                id="volume"
                                                select
                                                name="Volume"
                                                value={this.state.Volume}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Volume"
                                            >
                                                <MenuItem value="x-soft">
                                                    X-Soft
                                                </MenuItem>
                                                <MenuItem value="soft">
                                                    Soft
                                                </MenuItem>
                                                <MenuItem value="medium">
                                                    Normal
                                                </MenuItem>
                                                <MenuItem value="loud">
                                                    Loud
                                                </MenuItem>
                                                <MenuItem value="x-loud">
                                                    X-Loud
                                                </MenuItem>
                                            </TextField>
                                        </React.Fragment>
                                    }
                                </div>
                                {
                                    this.state.MessageType == 'text' &&
                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        </div>
                                        <div className="w-full">
                                            <TextField
                                                className={"mb-12"}
                                                label="Message"
                                                id="Message"
                                                name="MessageClip"
                                                value={this.state.MessageClip}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                fullWidth
                                            />
                                            {(!EditClip && EditClip !== 0) ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={"mb-12"}
                                                    style={{ minWidth: 100 }}
                                                    onClick={this.addClip}
                                                    disabled={this.state.MessageClip.trim().length < 1}
                                                >
                                                    <Icon className="mr-4">add_circle_outline</Icon> Add
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={"mb-12"}
                                                    style={{ minWidth: 100 }}
                                                    onClick={this.saveClip}
                                                    disabled={this.state.MessageClip.trim().length < 1}
                                                >
                                                    <Icon className="mr-4">save</Icon> Save
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.MessageType == 'text' && SSMLTags.length > 0 &&
                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        </div>
                                        <div className="w-full max-w-full overflow-x-auto mb-12 mt-0">
                                            {
                                                SSMLTags.map((value, key) =>
                                                    <Tooltip title={value.Attributes} placement="top">
                                                        <Chip key={key} className="mb-8 mr-4 truncate" color="primary" icon={<Icon>record_voice_over</Icon>} label={value.Say} onClick={() => { this.editClip(key); }} onDelete={() => { this.removeClip(key); }} />
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                    </div>
                                }

                                <div className="sm:flex mt-12">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        <Icon color="action">note</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                {
                                    Action === "Dial" &&
                                    <div className="sm:flex justify-end">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.RecordAssignedDialYN === 'Y'}
                                                    name="RecordAssignedDialYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Record Transferred Calls"
                                        />
                                    </div>
                                }
                            </DialogContent>
                        </FuseAnimate>

                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {['new', 'customer'].indexOf(CallGatherKeysDialog.type) > -1 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addCallGatherKey(rec);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                var rec = this.state;
                                                updateCallGatherKey(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                                        </Button>
                                        <IconButton
                                            onClick={() => {
                                                var rec = this.state;
                                                removeCallGatherKey(rec);
                                                this.closeComposeDialog();
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
CallGatherKeysDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditCallGatherKeysDialog: Actions.closeEditCallGatherKeysDialog,
        closeNewCallGatherKeysDialog: Actions.closeNewCallGatherKeysDialog,
        addCallGatherKey: Actions.addCallGatherKey,
        updateCallGatherKey: Actions.updateCallGatherKey,
        removeCallGatherKey: Actions.removeCallGatherKey,
        getFiles: FileActions.getFiles,
        showMessage: showMessage,
        setAwaitingCallFrom: setAwaitingCallFrom,
    }, dispatch);
}

function mapStateToProps({ callGatherKeysApp, fileManagerApp, spReducers, dialer }) {
    return {
        CallGatherKeysDialog: callGatherKeysApp.CallGatherKeys.CallGatherKeysDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        queues: spReducers.callQueues,
        gathers: spReducers.callGathers,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        awaitingCallFrom: dialer.awaitingCallFrom,
        AudioClips: spReducers.audioFiles.audioClips,
        huntGroups: spReducers.huntGroups,
        securables: spReducers.userProfiles.User.Data.Securables,
        voiceMailboxes: spReducers.voiceMailboxes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CallGatherKeysDialog));
