import * as Actions from '../actions';

const userReducer = function (state = {}, action) {
    switch ( action.type )
    {
        case Actions.GET_USER_DATA:
            return action.data;
        default:
            return state;
    }
};

export default userReducer;