import { FuseAnimate, FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  Badge,
  Icon,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import classNames from 'classnames'
import * as ChatActions from 'main/chatPanel/store/actions'
import * as DialerActions from 'main/dialer/store/actions'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { openEditTechnicianDialog } from '../technicians/store/actions'
import { updateTableSettings } from '../users/store/actions'
import DispatchBoardDialog from './DispatchBoardDialog'
import DispatchDropCell from './DispatchDropCell'
import DispatchBoardTechnician from './DispatchBoardTechnician'
import * as Actions from './store/actions'
import moment from 'moment'
import { TableVirtuoso } from 'react-virtuoso'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { slots } from './constants'
const techCriteriaLists = {
  businessUnits: {
    key: 'DefaultBusinessUnit',
    format: ['Description'],
    label: 'Business Unit',
    listName: 'Business Units',
    icon: 'business_center',
  },
  divisions: {
    key: 'DefaultDivision',
    format: ['Description'],
    label: 'Division',
    listName: 'Divisions',
    icon: 'location_city',
  },
  departments: {
    key: 'DefaultDepartment',
    format: ['Description'],
    label: 'Department',
    listName: 'Departments',
    icon: 'business',
  },
  teams: {
    key: 'Team',
    format: ['Name'],
    label: 'Team',
    listName: 'Teams',
    icon: 'supervised_user_circle',
  },
  technicians: {
    key: 'Technician',
    format: ['FirstName', 'LastName'],
    label: 'Technician',
    listName: 'Technicians',
    icon: 'people',
  },
}

function LoadingDiv() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .65)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        className='loader stretchBar'
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
        }}
      >
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
        <br />
        <img
          style={{ width: 80, marginTop: 15 }}
          src='assets/images/splash/SPLoading.png'
        />
      </div>
    </div>
  )
}

function NoData() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .25)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
          paddingTop: 75,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        No Technicians Found
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 306px)',
  },
  mobileRoot: {
    maxHeight: 'calc(100vh - 360px)',
  },
  mailItem: {},
  avatarRoot: {
    marginRight: 0,
  },
  avatar: {
    borderRadius: '50%',
    boxShadow: '2px 2px 6px #333',
    width: 48,
    height: 48,
  },
  action: {
    margin: 8,
    marginRight: -4,
    marginTop: 8,
  },
  icon: {
    marginLeft: 12,
    marginRight: 12,
  },
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  subheader: {},
  tripAvatar: {
    borderRadius: '50%',
    boxShadow: '1px 1px 2px rgba(0,0,0,.25)',
    width: 26,
    height: 26,
    backgroundColor: 'rgb(100,100,100)',
  },
  tripAction: {
    margin: 0,
    marginRight: -4,
    marginTop: 0,
  },
  tripIcon: {
    marginLeft: 4,
    marginRight: 12,
  },
  labels: {},
  technician: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    alignSelf: 'start',
  },
  trip: {
    width: 'auto',
    border: 'none',
    zIndex: 1,
    overflow: 'visible',
    alignSelf: 'start',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    background: 'rgb(250, 250, 250)',
    boxShadow: '1px 1px 4px #333',
  },
  content: {
    overflow: 'hidden',
  },
  header: {
    width: '100%',
  },
  popper: {
    opacity: 1,
    background: 'transparent',
    margin: 10,
  },
  tooltip: {
    opacity: 1,
    padding: 0,
    margin: 0,
    boxShadow: '2px 2px 6px #3333',
  },
  popper: {
    opacity: 1,
    marginTop: 8,
    zIndex: 4,
    background: '#fff',
  },
  tooltip: {
    opacity: 1,
    padding: 8,
    margin: 0,
    borderRadius: 4,
    background: '#fff',
    boxShadow: '2px 2px 6px #3333',
  },
  badge: {
    top: 5,
    right: 15,
  },
  techBadge: {
    top: 5,
  },
  techNameBadge: {
    top: '90%',
    right: '90%',
    backgroundColor: 'rgba(0,0,0,.5)',
    color: '#fff',
    fontSize: 'x-small',
  },
  openBadge: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  busyBadge: {
    backgroundColor: '#6c4de9',
    color: '#fff',
  },
  capacityBadge: {
    backgroundColor: '#f75151',
    color: '#fff',
  },
  header: {
    zIndex: 4,
  },
  timeLine: {
    borderRight: '1px solid #333',
    height: 'calc(100vh - 306px)',
    position: 'absolute',
    left: 0,
    top: '25%',
  },
  timeLineFoot: {
    borderRight: '1px solid #333',
    position: 'absolute',
    left: 0,
  },
  timeLineLabel: {
    position: 'absolute',
    backgroundColor: '#333',
    color: 'white',
    borderRadius: '3px',
    padding: '2px',
    width: 56,
    fontSize: 10,
    left: -28,
  },
  dbSelect: {
    '& > .menu-icon': {
      display: 'none',
    },
  },
})

const initialReorderInfo = {
  tech: null,
  index: null,
  positionCount: 0,
  indicatorKey: null,
}

class VirtualizedDispatchBoard extends Component {
  state = {
    technicians: this.props.technicians,
    date: this.props.date,
    technicians: [],
    trips: [],
    loading: this.props.loading,
    selectedBoard: 'default',
    pinAnytime: true,
    schedule: {
      board: {},
      list: [],
    },
    down: false,
    x: null,
    left: null,
    isDatepickerOpen: false,
    filtered: false,
    now: moment(),
    dragOverTech: null,
    draggingTech: null,
    dragPosition: 'bottom',
    boardData: [],
    realTrips: [],
    technicianUA: [],
    isCmdCtrlPressed: false,
    reOrderInfo: initialReorderInfo,
    board: null,
  }
  componentDidMount() {
    let {
      Co,
      technicians,
      trips,
      date,
      loading,
      schedule,
      selectedBoard,
      sharedBoards,
      user,
      techUA,
    } = this.props

    const dt = moment(date).startOf('day')
    const technicianUA = _.filter(
      techUA,
      o => moment(o.StartDate) <= dt && moment(o.EndDate || o.StartDate) >= dt,
    )

    const now = moment()

    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)

    const sec = 60 - now.toDate().getSeconds()
    setTimeout(() => {
      this.setState({ now: moment() }, () => {
        this.interval = setInterval(
          () => this.setState({ now: moment() }),
          60000,
        )
      })
    }, sec * 1000)

    if (technicians && trips) {
      let boards =
        user && user.Data && user.Data.DispatchBoards
          ? _.filter(user.Data.DispatchBoards, o => o.SharedYN !== 'Y')
          : []
      boards = [...sharedBoards, ...boards]
      const board = _.find(boards, { Co, Board: selectedBoard })
      this.setState(
        {
          ...this.state,
          technicians,
          trips,
          date,
          loading,
          schedule,
          selectedBoard,
          now,
          board,
          technicianUA,
        },
        this.getData,
      )
    }

    if (
      moment().format('M/D/YYYY') === moment(date).format('M/D/YYYY') &&
      document.getElementsByClassName('rt-table')
    ) {
      document.getElementsByClassName('rt-table')[0].scrollLeft =
        new Date().getHours() * 192
    } else {
      document.getElementsByClassName('rt-table')[0].scrollLeft = 8 * 192
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      Co,
      technicians,
      trips,
      date,
      loading,
      schedule,
      selectedBoard,
      searchText,
      sharedBoards,
      user,
      techUA,
    } = this.props

    const dt = moment(date).startOf('day')
    const technicianUA = _.filter(
      techUA,
      o => moment(o.StartDate) <= dt && moment(o.EndDate || o.StartDate) >= dt,
    )

    let boards =
      user && user.Data && user.Data.DispatchBoards
        ? _.filter(user.Data.DispatchBoards, o => o.SharedYN !== 'Y')
        : []
    boards = [...sharedBoards, ...boards]
    const board = _.find(boards, { Co, Board: selectedBoard })
    if (
      !_.isEqual(board, this.state.board) ||
      !_.isEqual(schedule, prevProps.schedule) ||
      loading !== prevProps.loading ||
      !_.isEqual(selectedBoard, prevProps.selectedBoard) ||
      !_.isEqual(searchText, prevProps.searchText) ||
      !_.isEqual(techUA, prevProps.techUA)     
    ) {
      this.setState(
        {
          ...this.state,
          technicians,
          trips,
          date,
          loading,
          schedule,
          selectedBoard,
          board,
          technicianUA,
        },
        this.getData,
      )
    }
    if(
      !_.isEqual(technicians, prevProps.technicians)){
        this.setState({...this.state, technicians:technicians})
      }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  incrementPositionCount = () => {
    this.setState(
      prevState => ({
        reOrderInfo: {
          ...prevState.reOrderInfo,
          positionCount: prevState.reOrderInfo.positionCount + 1,
          indicatorKey: 'ArrowUp',
        },
      }),
      () => this.debouncedReOrderTechsByArrow(),
    )
  }

  decrementPositionCount = () => {
    this.setState(
      prevState => ({
        reOrderInfo: {
          ...prevState.reOrderInfo,
          positionCount: prevState.reOrderInfo.positionCount - 1,
          indicatorKey: 'ArrowDown',
        },
      }),
      () => this.debouncedReOrderTechsByArrow(),
    )
  }

  setSpecificPosition = position => {
    this.setState(
      prevState => ({
        reOrderInfo: {
          ...prevState.reOrderInfo,
          positionCount: position,
          indicatorKey: position,
        },
      }),
      () => this.reOrderTechsByIndex(),
    )
  }
  debouncedSetSpecificPosition = _.debounce(this.setSpecificPosition, 1500)
  handleKeyDown = event => {
    const key = event.key
    const { reOrderInfo } = this.state
    if (
      reOrderInfo.tech &&
      key !== 'ArrowUp' &&
      key !== 'ArrowDown' &&
      isNaN(parseInt(key, 10))
    ) {
      this.setState({
        reOrderInfo: initialReorderInfo,
      })
    } else if (reOrderInfo.tech && key === 'ArrowUp') {
      this.incrementPositionCount()
    } else if (reOrderInfo.tech && key === 'ArrowDown') {
      this.decrementPositionCount()
    } else if (reOrderInfo.tech && !isNaN(parseInt(key, 10))) {
      const newPositionCount =
        this.state.reOrderInfo.positionCount.toString() + key

      const updatedPositionCount =
        newPositionCount.length > 1
          ? parseInt(newPositionCount, 10)
          : newPositionCount

      this.setState(
        prevState => ({
          reOrderInfo: {
            ...prevState.reOrderInfo,
            positionCount: updatedPositionCount,
            indicatorKey: updatedPositionCount,
          },
        }),
        () =>
          this.debouncedSetSpecificPosition(
            this.state.reOrderInfo.positionCount - 1,
          ),
      )
    }

    if (event.metaKey || event.ctrlKey) {
      this.setState({ isCmdCtrlPressed: true })
    }
  }

  reOrderTechsByArrow = () => {
    const { reOrderInfo, data } = this.state
    if (reOrderInfo.index >= 0) {
      const diff = reOrderInfo.index - reOrderInfo.positionCount
      const newIndex = diff >= 0 ? diff : 0
      if (newIndex !== reOrderInfo.index) {
        const newData = [...data]
        const [removedItem] = newData.splice(reOrderInfo.index, 1)
        newData.splice(newIndex, 0, removedItem)
        this.updateBoardWithNewOrder(newData)
        this.setState({ reOrderInfo: initialReorderInfo })
      }
    }
  }

  debouncedReOrderTechsByArrow = _.debounce(this.reOrderTechsByArrow, 1500)

  reOrderTechsByIndex = () => {
    const { reOrderInfo, data } = this.state
    if (
      reOrderInfo.positionCount !== reOrderInfo.index &&
      reOrderInfo.positionCount <= data.length - 1 &&
      reOrderInfo.positionCount >= 0
    ) {
      const newData = [...data]
      const [removedItem] = newData.splice(reOrderInfo.index, 1)
      newData.splice(reOrderInfo.positionCount, 0, removedItem)
      this.updateBoardWithNewOrder(newData)
      this.setState({ reOrderInfo: initialReorderInfo })
    } else {
      this.setState({ reOrderInfo: initialReorderInfo })
    }
  }

  updateBoardWithNewOrder = newData => {
    const { board } = this.state
    if (!board) {
      console.error('Board data is not available.')
      return
    }
    const newOrder = newData.map(tech => tech.Technician.Technician)
    const newBoard = {
      ...board,
      Options: JSON.stringify({
        ...JSON.parse(board.Options),
        technicianOrder: newOrder,
      }),
    }
    this.setState({ data: newData, board: newBoard }, () => {
      this.props.updateDispatchBoard(newBoard)
    })
  }

  handleKeyUp = event => {
    if (this.state.isCmdCtrlPressed) {
      this.setState({ isCmdCtrlPressed: false })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    let filterData = FuseUtils.filterArrayByString(arr, searchText, [
      'FirstName',
      'LastName',
      'Name',
      'Address1',
      'Address2',
      'City',
      'State',
      'Zip',
      'Service',
      'Description',
    ])

    return filterData
  }

  openSelectedDispatchBoardMenu = event => {
    this.setState({ selectedDispatchBoardMenu: event.currentTarget })
  }

  closeSelectedDispatchBoardMenu = () => {
    this.setState({ selectedDispatchBoardMenu: null })
  }

  dragOver = (event, el) => {
    event.preventDefault()
  }

  drop = (event, technician, time) => {
    event.preventDefault()
  }

  handleRefresh = () => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    this.props.setBoardDate(this.props.Co, date, old)
    this.updateDate(this.props.Co, oldDate, oldDate)
  }

  handleDateChange = event => {
    const date = event._d
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    const newDate = this.formatDateInput(date)
    if (oldDate !== newDate) {
      this.setState({ ...this.state, date, loading: true, trips: [] }, () => {
        this.props.setBoardDate(this.props.Co, date, oldDate)
        this.updateDate(this.props.Co, newDate, oldDate)
      })
    }
  }

  handleToday = event => {
    const date = new Date()
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    const newDate = this.formatDateInput(date)
    this.setState({ ...this.state, date, loading: true, trips: [] }, () => {
      this.props.setBoardDate(this.props.Co, date, oldDate)
      this.updateDate(this.props.Co, newDate, oldDate)
    })
  }

  handleNextDate = () => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    date.setDate(date.getDate() + 1)
    const newDate = this.formatDateInput(date)
    this.setState({ ...this.state, date, loading: true, trips: [] }, () => {
      this.props.setBoardDate(this.props.Co, date, old)
      this.updateDate(this.props.Co, newDate, oldDate)
    })
  }

  handlePrevDate = () => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    date.setDate(date.getDate() - 1)
    const newDate = this.formatDateInput(date)
    this.setState({ ...this.state, date, loading: true, trips: [] }, () => {
      this.props.setBoardDate(this.props.Co, date, old)
      this.updateDate(this.props.Co, newDate, oldDate)
    })
  }

  updateDate = _.debounce((Co, date, oldDate) => {
    this.props.getTripData(Co, date, oldDate)
    this.props.getWOData(Co, date, oldDate)
    const rtTable = document.getElementsByClassName('rt-table')[0]
    if (rtTable) {
      if (
        moment(date).format('M/D/YYYY') !== moment(oldDate).format('M/D/YYYY')
      ) {
        if (
          moment().format('M/D/YYYY') === moment(date).format('M/D/YYYY') && rtTable ) {
          rtTable.scrollLeft = new Date().getHours() * 192
        } else {
          rtTable.scrollLeft = 8 * 192
        }
      }
    }
  }, 1000)

  formatDateInput(dt) {
    return dt.toLocaleDateString('en-US').replace(/\//g, '-')
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  getWindow(time) {
    let hour = time.getHours()
    let minutes = time.getMinutes()
    let ordinal = hour >= 12 ? 'P' : 'A'
    hour = hour > 12 ? (hour -= 12) : hour < 1 ? (hour = 12) : hour
    minutes = minutes >= 30 ? '30' : ''
    return `${hour}${minutes}${ordinal}`
  }

  openDatePicker = e => {
    this.setDatepickerOpen(true)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer mr-8'>
      {this.formatDateText(this.state.date)}
    </span>
  )

  handleAnytimePin = e => {
    const { pinAnytime } = this.state
    this.setState({ ...this.state, pinAnytime: !pinAnytime })
  }

  openMenu = (event, tech) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      anchorTech: tech,
    })
  }

  closeMenu = () => {
    this.setState({ ...this.state, anchorEl: null, anchorTech: null })
  }

  handleContactClick = tech => {
    this.props.openChatPanel()
    const { employees, user } = this.props
    const { Co, Employee } = tech
    const employee = _.find(employees, { Co, Employee: tech.Employee })
    if (employee && employee.UserName && user.UserName !== employee.UserName) {
      this.props.getChat(employee.UserName)
    }
    // this.scrollToTop();
  }

  handleCall = tech => {
    const { closeChatPanel, toggleDialer, initCall, dialer } = this.props
    /*toggleDialer(true);
        initCall(dialer, {
            destinationNumber: tech.Phone.replace(/\(|\)|\.|,|-| /g, ''),
            audio: true,
            video: false,
        });*/
  }

  handleNotify = tech => {
    const { Co, date, notifyAll } = this.props
    const dt = new Date(date).toLocaleDateString('en-US').replace(/\//g, '-')
    notifyAll(Co, tech.Technician, dt)
  }

  handleRowCount = count => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'dispatch-board' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'dispatch-board',
        Preferences: {
          RowCount: count,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.RowCount = count
    }
    this.props.updateTableSettings(prefs)
  }

  handleSortOrder = sort => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'dispatch-board' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'dispatch-board',
        Preferences: {
          SortOrder: sort,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.SortOrder = sort
    }
    this.props.updateTableSettings(prefs)
  }

  selectBoard = e => {
    const selectedBoard = e.target.value
    if (selectedBoard !== '+') {
      this.setState({ ...this.state, selectedBoard }, () =>
        this.props.selectDispatchBoard(selectedBoard),
      )
    } else {
      this.props.openNewDispatchBoardDialog()
    }
  }

  setDatepickerOpen = bool => {
    this.setState({ isDatepickerOpen: bool })
  }

  setFiltered = () => {
    const { filtered } = this.state
    this.setState({ filtered: !filtered }, this.getData)
  }

  getData = update => {
    const { Co, teams, searchText } = this.props
    const { loading, schedule, filtered, board } = this.state
    const activeTechniciansWithTrips = schedule.list
      ? _.filter(schedule.list, o => {
          return (
            o.Technician.ActiveYN === 'Y' &&
            (!filtered || o.Technician.trips.length > 0)
          )
        })
      : {}
    let data = loading
      ? []
      : this.getFilteredArray(activeTechniciansWithTrips, searchText)

    if (board) {
      const options = JSON.parse(board.Options)
      const technicianOrder = options.technicianOrder || []
      const { criteria } = options
      Object.keys(criteria).map(paramKey => {
        const params = criteria[paramKey]
        if (params.length > 0) {
          const list = techCriteriaLists[paramKey]
          if (paramKey !== 'teams') {
            if (paramKey !== 'technicians' || !options.inAddition) {
              data = _.filter(data, o => {
                return (
                  params.indexOf(o.Technician[list.key]) > -1 ||
                  (criteria.technicians &&
                    criteria.technicians.indexOf(o.Technician.Technician) > -1)
                )
              })
            }
          } else {
            data = _.filter(data, o => {
              let exists = false
              params.map(Team => {
                const team = _.find(teams, { Co, Team })
                if (
                  _.find(team.Data.Members, {
                    Co,
                    TeamMember: o.Technician.Technician,
                    ActiveYN: 'Y',
                  }) ||
                  _.find(team.Data.Leads, {
                    Co,
                    TeamLead: o.Technician.Technician,
                    ActiveYN: 'Y',
                  })
                ) {
                  exists = true
                }
              })
              return exists
            })
          }
        }
      })

      let newData = []

      technicianOrder.map(tech => {
        const techIndex = _.findIndex(
          data,
          o => o.Technician.Technician === tech,
        )
        if (techIndex > -1) {
          const techData = data[techIndex]
          if (techData) {
            newData.push(techData)
            data.splice(techIndex, 1)
          }
        }
      })

      data = [...newData, ...data]
    }
    const realTrips = _.flatten(
      _.map(data, o => _.filter(o.Technician.trips, t => t.Trip > 0)),
    )


   this.setState({ data, realTrips });

  }

  displayTimeLine() {
    const timeline = document.getElementById('timeline')
    const anytimeCol = document.getElementById('anytime')

    if (timeline && anytimeCol) {
      const timelineLeft = timeline.getBoundingClientRect().left
      const anytimeColLeft = anytimeCol.getBoundingClientRect().left
      const diff = timelineLeft - anytimeColLeft
      if (diff <= 252) {
        timeline.style.visibility = 'hidden'
      } else {
        timeline.style.visibility = 'visible'
      }
    }
  }

  handleTechClick = (tech, index) => {
    if (this.state.isCmdCtrlPressed) {
      this.setState({
        reOrderInfo: {
          tech,
          index,
          positionCount: 0,
          indicatorKey: null,
        },
      })
    }
  }

  render() {
    const { Co, theme, classes, user, teams, securables, sharedBoards } =
      this.props
    const { TableSettings } = user.Data
    const prefs = _.find(TableSettings, { Table: 'dispatch-board' })
    const accessLevel = _.find(securables, {
      Securable: 'custom-dispatch-boards',
    })
    const {
      trips,
      date,
      loading,
      pinAnytime,
      anchorTech,
      down,
      selectedBoard,
      filtered,
      now,
      draggingTech,
      dragOverTech,
      dragPosition,
      board,
      data,
      realTrips,
      technicianUA,
    } = this.state
    const nowHrs = now.hour() + now.minute() / 60
    const timeLine = Math.round((nowHrs / 24) * 4608)
    const scheduled = []
    if (anchorTech && anchorTech.tripObj) {
      Object.keys(anchorTech.tripObj).map(key => {
        const trip = anchorTech.tripObj[key]
        if (trip.Status === 0) {
          scheduled.push(trip)
        }
      })
    }
    let boards =
      user && user.Data && user.Data.DispatchBoards
        ? _.filter(user.Data.DispatchBoards, o => o.SharedYN !== 'Y')
        : []
    boards = [...sharedBoards, ...boards]

    const positionCount =
      this.state.reOrderInfo.positionCount < 0
        ? this.state.reOrderInfo.positionCount * -1
        : this.state.reOrderInfo.positionCount

    return (
      <Media query='(min-width: 1024px)'>
        {matches => (
          <div className='p-12 pb-0'>
            <FuseAnimate animation='transition.slideUpIn'>
              <div className='sm:flex p-12 sm:p-0'>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex mb-8 cursor-pointer'
                >
                  <Tooltip placement='top' title={`${realTrips.length} Trips`}>
                    <Badge
                      classes={{ badge: classes.badge }}
                      max={999}
                      badgeContent={realTrips.length}
                      color='primary'
                    >
                      <Icon
                        onClick={this.setFiltered}
                        color={filtered ? 'secondary' : 'primary'}
                        className='text-32 mr-12'
                      >
                        {filtered ? 'filter_list' : 'dvr'}
                      </Icon>
                    </Badge>
                  </Tooltip>
                  <TextField
                    className='m-0'
                    id='dispatchBoard'
                    name='CustomBoard'
                    value={selectedBoard || 'default'}
                    select
                    margin='dense'
                    InputProps={{
                      disableUnderline: true,
                      className: 'text-20',
                    }}
                    inputProps={{
                      className: classes.dbSelect,
                    }}
                    onChange={this.selectBoard}
                  >
                    <MenuItem value='default'>Dispatch Board</MenuItem>
                    {_.filter(boards, { Co }).map(value => (
                      <MenuItem value={value.Board}>
                        {value.Name}
                        {value.SharedYN === 'Y' && (
                          <Icon
                            className='ml-6 text-16 menu-icon'
                            color='action'
                          >
                            share
                          </Icon>
                        )}
                      </MenuItem>
                    ))}

                    {accessLevel && accessLevel.AccessLevel !== 'R' && (
                      <MenuItem value='+' className='text-12'>
                        <Icon className='mr-6 text-16' color='primary'>
                          add_circle_outline
                        </Icon>
                        New Dispatch Board
                      </MenuItem>
                    )}
                  </TextField>
                  {/* <span>Dispatch Board</span> */}
                  <Icon
                    color='action'
                    onClick={this.handleRefresh}
                    className={
                      !loading
                        ? 'text-24 ml-8 cursor-pointer'
                        : 'text-24 ml-8 mt-4 cursor-pointer spin'
                    }
                  >
                    refresh
                  </Icon>
                </Typography>
                <div className='flex sm:absolute sm:pin-r pt-4'>
                  <Tooltip title="View Today's Schedule" placement='top'>
                    <div className='flex mr-12'>
                      <Icon
                        onClick={this.handleToday}
                        className='text-24 cursor-pointer'
                        color='action'
                      >
                        today
                      </Icon>
                    </div>
                  </Tooltip>
                  <Typography
                    variant='caption'
                    style={{ fontWeight: 'bold', alignItems: 'center' }}
                    className='flex'
                  >
                    <Tooltip title='Select Date' placement='top'>
                      <div className='picker'>
                        <DatePicker
                          inputVariant='outlined'
                          onOpen={() => this.setDatepickerOpen(true)}
                          onClose={() => this.setDatepickerOpen(false)}
                          open={this.state.isDatepickerOpen}
                          label='Date'
                          inputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          TextFieldComponent={this.renderDateLabel}
                          value={date}
                          onChange={this.handleDateChange}
                          showTodayButton
                          autoOk
                        />
                      </div>
                    </Tooltip>
                    <Icon
                      onClick={this.handlePrevDate}
                      color='action'
                      className='text-24 mr-4 cursor-pointer'
                    >
                      keyboard_arrow_left
                    </Icon>
                    <Icon
                      onClick={this.handleNextDate}
                      color='action'
                      className='text-24 ml-4 cursor-pointer'
                    >
                      keyboard_arrow_right
                    </Icon>
                  </Typography>
                </div>
              </div>
            </FuseAnimate>

            <div style={{ position: 'relative' }}>
              {loading && <LoadingDiv />}
              {!loading && data && data.length === 0 && <NoData />}
              {this.state.reOrderInfo.tech &&
              this.state.reOrderInfo.indicatorKey ? (
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 7,
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                >
                  {this.state.reOrderInfo.indicatorKey === 'ArrowUp' && (
                    <div className='flex items-center justify-center text-3xl'>
                      <KeyboardArrowUpIcon style={{ height: 48, width: 48 }} />
                      <span>{positionCount}</span>
                    </div>
                  )}

                  {this.state.reOrderInfo.indicatorKey === 'ArrowDown' && (
                    <div className='flex items-center justify-center text-3xl'>
                      <KeyboardArrowDownIcon
                        style={{ height: 48, width: 48 }}
                      />
                      <span>{positionCount}</span>
                    </div>
                  )}

                  {typeof this.state.reOrderInfo.indicatorKey === 'number' && (
                    <div className='flex items-center justify-center text-3xl'>
                      <span>
                        {positionCount > data.length || positionCount <= 0
                          ? 'Invalid position'
                          : `${positionCount} / ${data.length}`}
                      </span>
                    </div>
                  )}
                </div>
              ) : null}

              <TableVirtuoso
                onKeyDown={e => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault()
                  }
                }}
                onScroll={() => this.displayTimeLine()}
                className='rt-table'
                style={{ height: 'calc(100vh - 306px)' }}
                data={data}
                totalCount={data ? data.length : 0}
                components={{
                  Table: ({ style, ...props }) => {
                    const techCellWidth = matches ? 300 : 86
                    const totalWidth = techCellWidth + 250 + slots.length * 96
                    return (
                      <table
                        className='rt-table'
                        {...props}
                        style={{
                          ...style,
                          width: totalWidth,
                          overflowX: 'auto',
                        }}
                        onMouseDown={e => {
                          e.preventDefault()
                          const left =
                            document.getElementsByClassName('rt-table')[0]
                              .scrollLeft
                          const { clientX } = e
                          this.setState(
                            { ...this.state, down: true, x: clientX, left },
                            () => {
                              if (this.props.infoWindow) {
                                this.props.openInfoWindow(null)
                              }
                            },
                          )
                        }}
                        onMouseUp={e => {
                          e.preventDefault()

                          this.setState({
                            ...this.state,
                            down: false,
                            x: null,
                            left: null,
                          })
                        }}
                        onMouseMove={e => {
                          e.preventDefault()
                          const { down, x, left } = this.state
                          const { clientX } = e
                          if (down && x && left >= 0) {
                            document.getElementsByClassName(
                              'rt-table',
                            )[0].scrollLeft = left - clientX + x
                          }
                        }}
                        onMouseEnter={e => {
                          this.displayTimeLine()
                        }}
                        onMouseLeave={e => {
                          this.setState({
                            ...this.state,
                            down: false,
                            x: null,
                            left: null,
                          })
                        }}
                      />
                    )
                  },

                  Footer: () => {
                    return (
                      <div className='w-full flex relative'>
                        <div
                          className={classNames(classes.timeLineFoot, 'pin-b')}
                          style={{ zIndex: 1, left: timeLine, height: 4000 }}
                        ></div>
                      </div>
                    )
                  },
                }}
                fixedHeaderContent={() => (
                  <tr>
                    <th
                      style={{
                        padding: 20,
                        fontSize: 12,
                        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        fontWeight: 'bold',
                        width: matches ? 300 : 76,
                        background: 'white',
                        position: 'sticky',
                        left: 0,
                        zIndex: 2,
                      }}
                    >
                      Technician
                    </th>
                    <th
                      id='anytime'
                      style={{
                        position: pinAnytime ? 'sticky' : 'static',
                        left: matches ? 300 : 86,
                        width: 250,
                        background: 'white',
                        fontSize: 12,
                        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        zIndex: 2,
                      }}
                      onMouseDown={e => e.stopPropagation()}
                      onMouseUp={e => e.stopPropagation()}
                      onClick={this.handleAnytimePin}
                    >
                      <Tooltip
                        interactive
                        title={`${
                          !pinAnytime ? 'Pin' : 'Un-pin'
                        } Anytime Column`}
                        placement='top'
                        className='w-full'
                      >
                        <div className='w-full h-full'>Anytime</div>
                      </Tooltip>

                      <Icon
                        className='text-24 pin-r pin-t absolute'
                        color={pinAnytime ? 'primary' : 'action'}
                      >
                        {pinAnytime ? 'bookmark' : 'bookmark_border'}
                      </Icon>
                    </th>

                    {slots.map((_, index) => {
                      const ndt = moment()
                      ndt.set('hour', 0)
                      ndt.set('minute', index * 30)
                      const time = ndt.format('h:mm A')
                      const showTimeline = now >= ndt && now < ndt.add(30, 'm')

                      const currentMinutes = now.minutes() % 30
                      const pixelsPerMinute = 96 / 30
                      const leftOffset = currentMinutes * pixelsPerMinute

                      return (
                        <th
                          key={index}
                          style={{
                            background: 'white',
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            position: 'relative',
                          }}
                        >
                          {showTimeline ? (
                            <div
                              className='flex flex-col absolute h-full w-full'
                              style={{
                                top: -1,
                                left: leftOffset,
                                zIndex: 1,
                              }}
                            >
                              <div
                                className={classNames(classes.timeLineLabel)}
                              >
                                {now.format('h:mm A')}
                              </div>

                              <div
                                id='timeline'
                                className={classNames(classes.timeLine)}
                              ></div>
                            </div>
                          ) : null}

                          {time}
                        </th>
                      )
                    })}
                  </tr>
                )}
                itemContent={(i, row) => {
                  const isSelectedTech =
                    this.state.reOrderInfo.tech &&
                    this.state.reOrderInfo.tech.Technician.Technician ===
                      row.Technician.Technician
                  return (
                    <React.Fragment key={i}>
                      <td
                        style={{
                          padding: 0,
                          margin: 0,
                          left: 0,
                          boxSizing: 'border-box',
                          position: 'sticky',
                          backgroundColor: i % 2 !== 0 ? 'white' : '#fafafa',
                          cursor: 'pointer',
                          border: isSelectedTech
                            ? '2px dotted #4DBCE9'
                            : 'none',
                          borderRight: isSelectedTech
                            ? '2px dotted #4DBCE9'
                            : '1px solid rgba(0, 0, 0, 0.1)',
                          borderBottom: isSelectedTech
                            ? '2px dotted #4DBCE9'
                            : '1px solid rgba(0, 0, 0, 0.1)',
                          zIndex: 6,
                        }}
                        onClick={e => this.handleTechClick(row, i)}
                      >
                        <DispatchBoardTechnician
                          rows={row.Rows}
                          technician={row.Technician}
                        />
                      </td>

                      <td
                        style={{
                          padding: 0,
                          margin: 0,
                          boxSizing: 'border-box',
                          position: pinAnytime ? 'sticky' : 'static',
                          left: matches ? 300 : 86,
                          backgroundColor: i % 2 !== 0 ? 'white' : '#fafafa',
                          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                          borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                          zIndex: 6,
                        }}
                      >
                        <React.Fragment>
                          {board &&
                            dragPosition === 'top' &&
                            draggingTech &&
                            dragOverTech === row.Technician && (
                              <div
                                className='w-full min-h-96 p-4'
                                style={{ backgroundColor: '#9f9f9f' }}
                              ></div>
                            )}
                          <DispatchDropCell
                            isCmdCtrlPressed={this.state.isCmdCtrlPressed}
                            disableDrop={Boolean(draggingTech)}
                            row={row}
                            rows={row.Rows}
                            technician={{ ...row.Technician }}
                            now={now}
                            time={null}
                            occupied={row.occupied ? row.occupied : []}
                            trips={
                              row.ANYTIME && Array.isArray(row.ANYTIME.trips)
                                ? row.ANYTIME.trips
                                : []
                            }
                          />
                          {board &&
                            dragPosition === 'bottom' &&
                            draggingTech &&
                            dragOverTech === row.Technician && (
                              <div
                                className='w-full min-h-96 p-4'
                                style={{ backgroundColor: '#9f9f9f' }}
                              ></div>
                            )}
                        </React.Fragment>
                      </td>
                      {slots.map((slot, index) => {
                        const trips =
                          row[slot] && Array.isArray(row[slot].trips)
                            ? row[slot].trips
                            : []
                        const occupied = row[slot] ? row[slot].occupied : []
                        const ndt = moment()
                        ndt.set('hour', 0)
                        ndt.set('minute', index * 30)
                        const time = ndt.format('H:mm')

                        return (
                          <td
                            key={index}
                            style={{
                              backgroundColor:
                                i % 2 !== 0 ? 'white' : '#fafafa',
                              padding: 0,
                              margin: 0,
                              boxSizing: 'border-box',
                              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            }}
                          >
                            <DispatchDropCell
                              isCmdCtrlPressed={this.state.isCmdCtrlPressed}
                              techUA={technicianUA}
                              disableDrop={Boolean(draggingTech)}
                              dragging={this.state.down}
                              border={Boolean(index < 48)}
                              row={row}
                              rows={row.Rows}
                              now={now}
                              technician={{ ...row.Technician }}
                              time={time}
                              occupied={occupied}
                              trips={trips}
                            />
                          </td>
                        )
                      })}
                    </React.Fragment>
                  )
                }}
              />
            </div>

            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <div>
                {board ? (
                  (accessLevel.AccessLevel === 'F' ||
                    board.AddedBy === user.UserName) && (
                    <Typography
                      onClick={() =>
                        this.props.openEditDispatchBoardDialog(board)
                      }
                      variant='h6'
                      className='text-12 w-full text-right mt-16 mb-12 pr-12 cursor-pointer pr-36'
                    >
                      <Icon
                        className='align-middle text-16 mr-6 mb-4'
                        color='primary'
                      >
                        edit
                      </Icon>
                      Edit Dispatch Board
                    </Typography>
                  )
                ) : (
                  <Typography
                    onClick={this.props.openNewDispatchBoardDialog}
                    variant='h6'
                    className='text-12 w-full text-right mt-16 mb-12 pr-12 cursor-pointer pr-36'
                  >
                    <Icon
                      className='align-middle text-16 mr-6 mb-4'
                      color='primary'
                    >
                      add_circle_outline
                    </Icon>
                    New Dispatch Board
                  </Typography>
                )}
              </div>
            )}
            <DispatchBoardDialog />
          </div>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWOData: Actions.getWOData,
      getTripData: Actions.getTripData,
      openTechnician: openEditTechnicianDialog,
      setTrips: Actions.setTrips,
      setBoardDate: Actions.setBoardDate,
      loadingTrips: Actions.loadingTrips,
      updateTrips: Actions.updateTrips,
      setSchedule: Actions.setSchedule,
      updateDispatchBoard: Actions.updateDispatchBoard,
      getChat: ChatActions.getChat,
      openChatPanel: ChatActions.openChatPanel,
      toggleDialer: DialerActions.toggleDialer,
      initCall: DialerActions.initCall,
      openInfoWindow: Actions.openInfoWindow,
      notifyAll: Actions.notifyAll,
      openNewDispatchBoardDialog: Actions.openNewDispatchBoardDialog,
      openEditDispatchBoardDialog: Actions.openEditDispatchBoardDialog,
      selectDispatchBoard: Actions.selectDispatchBoard,
      setDragging: Actions.setDragging,
      updateTableSettings,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, dispatchBoardApp, dialer }) {
  return {
    searchText: dispatchBoardApp.dispatchBoard.searchText,
    user: spReducers.userProfiles.User,
    dispatchBoardData: spReducers.dispatchBoards,
    technicians: spReducers.technicians,
    trips: dispatchBoardApp.dispatchBoard.trips,
    schedule: dispatchBoardApp.dispatchBoard.schedule,
    selectedBoard: dispatchBoardApp.dispatchBoard.selectedBoard,
    date: dispatchBoardApp.dispatchBoard.date,
    loading: dispatchBoardApp.dispatchBoard.loading,
    Co: spReducers.companies.Co,
    dialer: dialer.sipPhone,
    infoWindow: dispatchBoardApp.dispatchBoard.infoWindow,
    securables: spReducers.userProfiles.User.Data.Securables,
    employees: spReducers.employees,
    teams: spReducers.teams,
    techUA: spReducers.techUA,
    sharedBoards: spReducers.boards,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(VirtualizedDispatchBoard),
  ),
)