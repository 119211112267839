import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarHeader from 'main/content/apps/calendar/CalendarHeader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { getCalendarData } from 'store/actions';
import { Button, Fab, Icon } from '@material-ui/core';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { isMobile } from 'react-device-detect';
import _ from '@lodash';
import { FuseAnimate } from '@fuse';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import { openEditTodoDialog } from '../todo/store/actions';
import { openCardDialog } from '../scrumboard/store/actions';


const localizer = momentLocalizer(moment)


const DragAndDropCalendar = withDragAndDrop(Calendar);


let allViews = Object.keys(Views).map(k => Views[k]);


const styles = theme => ({
    root: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        flex: 1,
        '& .rbc-header': {
            padding: '12px 6px',
            fontWeight: 600,
            fontSize: 14,
            '& span': {
                color: '#5f5f5f'
            },
        },
        '& .rbc-label': {
            padding: '8px 6px'
        },
        '& .rbc-today': {
            backgroundColor: 'transparent'
        },
        '& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today': {
            borderBottom: '2px solid ' + theme.palette.secondary.main + '!important'
        },
        '& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view': {
            padding: 0,
            marginTop: -45,
            background: '#fff',
            borderRadius: 8,
            marginLeft: 24,
            width: 'calc(100% - 48px)',
            maxHeight: 'calc(100vh - 232px)',
            overflowY: 'auto',
            zIndex: 98,
            ...theme.mixins.border(0)
        },
        '& .rbc-row-content': {
            paddingTop: 5,
            paddingBottom: 5,
        },
        '& .rbc-overflowing': {
            margin: '0 !important',
        },
        '& .rbc-time-view .rbc-overflowing': {
            paddingRight: !isMobile ? 12 : undefined,
        },
        '& .rbc-agenda-view table': {
            ...theme.mixins.border(1),
            '& thead > tr > th': {
                ...theme.mixins.borderBottom(0)
            },
            '& tbody > tr > td': {
                padding: '12px 6px',
                '& + td': {
                    ...theme.mixins.borderLeft(1)
                }
            }
        },
        '& .rbc-time-view': {
            '& .rbc-time-header': {
                ...theme.mixins.border(1)
            },
            '& .rbc-time-content': {
                flex: '0 1 auto',
                ...theme.mixins.border(1)
            }
        },
        '& .rbc-month-view': {
            '& > .rbc-row': {
                ...theme.mixins.border(1)
            },
            '& .rbc-month-row': {
                ...theme.mixins.border(1),
                borderWidth: '0 1px 1px 1px!important',
                minHeight: 128
            },
            '& .rbc-header + .rbc-header': {
                ...theme.mixins.borderLeft(1)
            },
            '& .rbc-header': {
                ...theme.mixins.borderBottom(0)
            },
            '& .rbc-day-bg + .rbc-day-bg': {
                ...theme.mixins.borderLeft(1)
            }
        },
        '& .rbc-day-slot .rbc-time-slot': {
            ...theme.mixins.borderTop(1),
            opacity: 0.5
        },
        '& .rbc-time-header > .rbc-row > * + *': {
            ...theme.mixins.borderLeft(1)
        },
        '& .rbc-time-content > * + * > *': {
            ...theme.mixins.borderLeft(1)
        },
        '& .rbc-day-bg + .rbc-day-bg': {
            ...theme.mixins.borderLeft(1)
        },
        '& .rbc-time-header > .rbc-row:first-child': {
            ...theme.mixins.borderBottom(1)
        },
        '& .rbc-timeslot-group': {
            minHeight: 64,
            ...theme.mixins.borderBottom(1)
        },
        '& .rbc-date-cell': {
            padding: 8,
            fontSize: 16,
            fontWeight: 400,
            opacity: .5,
            '& > a': {
                color: 'inherit'
            }
        },
        '& .rbc-event': {
            borderRadius: 4,
            padding: '4px 8px',
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: theme.palette.secondary.contrastText,
            boxShadow: theme.shadows[2],
            transitionProperty: 'box-shadow',
            transitionDuration: theme.transitions.duration.short,
            transitionTimingFunction: theme.transitions.easing.easeInOut,
            position: 'relative',
            '&:hover': {
                boxShadow: theme.shadows[4]
            }
        },
        '& .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event': {
            minHeight: 52,
        },
        '& .bg-darkgrey': {
            backgroundColor: '#3f3f3f !important',
            color: '#fff !important'
        },
        '& .bg-primary': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: theme.palette.primary.contrastText
        },
        '& .bg-error': {
            backgroundColor: `${theme.palette.error[500]} !important`,
            color: '#fff'
        },
        '& .bg-purple': {
            backgroundColor: `#904de9 !important`,
            color: '#fff'
        },
        '& .rbc-row-segment': {
            padding: '0 4px 4px 4px'
        },
        '& .rbc-off-range-bg': {
            backgroundColor: theme.palette.type === 'light' ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0.16)'
        },
        '& .rbc-show-more': {
            color: '#5f5f5f !important',
            background: 'transparent'
        },
        '& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event': {
            position: 'static'
        },
        '& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child': {
            left: 0,
            top: 0,
            bottom: 0,
            height: 'auto'
        },
        '& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child': {
            right: 0,
            top: 0,
            bottom: 0,
            height: 'auto'
        },
        '& .rbc-addons-dnd-resize-ew-icon': {
            height: '21px !important'
        },
        '& .rbc-addons-dnd-resize-ew-anchor': {
            left: '-4px !important',
        },
        '& .rbc-addons-dnd-resize-ew-anchor:last-child': {
            left: 'unset !important',
            right: '-4px !important',
        }
    },
    addButton: {
        position: 'absolute',
        right: 28,
        top: 82,
        zIndex: 99,
        width: 36,
        height: 36
    }
});

const initialState = {
    events: [],
    trips: [],
}

class CalendarApp extends Component {

    state = {

    }

    componentDidMount() {
        const { events, trips } = this.props;
        this.setState({ ...initialState, events, trips: trips || [] });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { events, trips } = this.props;
        if (!_.isEqual(trips, prevProps.trips) || !_.isEqual(events, prevProps.events)) {
            this.setState({ ...this.state, trips: trips || [], events });
        }
    }


    moveEvent = ({ event, start, end }) => {
        if (!event.Data.trip) {
            const { id, title, desc, allDay, publicYN, Data } = event;
            const { type } = Data;
            const rec = {
                ID: id,
                Type: type,
                StartDate: start,
                EndDate: end,
                Title: title,
                Description: desc,
                AllDayYN: allDay ? "Y" : null,
                PublicYN: publicYN ? "Y" : null,
                Data: {
                    ...Data,
                }
            };
            this.props.updateEvent(rec);
        }
    };


    resizeEvent = ({ event, start, end }) => {
        if (!event.Data.trip) {
            const { id, title, desc, allDay, publicYN, Data } = event;
            const { type } = Data;
            const rec = {
                ID: id,
                Type: type,
                StartDate: start,
                EndDate: end,
                Title: title,
                Description: desc,
                AllDayYN: allDay ? "Y" : null,
                PublicYN: publicYN ? "Y" : null,
                Data: {
                    ...Data,
                }
            };
            this.props.updateEvent(rec);
        }
    };

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    getEvents = () => {
        const { trips, events, toDos } = this.props;
        const allEvents = [];
        events.map(((val) => {
            const { ID, Type, StartDate, EndDate, Title, Description, AllDayYN, AddedBy, PublicYN, Data } = val;
            allEvents.push({
                id: ID,
                start: moment.utc(StartDate).local().toDate(),
                end: moment.utc(EndDate).local().toDate(),
                title: Title,
                desc: Description,
                allDay: AllDayYN,
                publicYN: PublicYN,
                AddedBy,
                Data: {
                    ...Data,
                    type: "E",
                }
            });
        }));
        toDos.map(((val) => {
            const { ID, Type, DueDate, Title, Description, Data, AddedBy } = val;
            if (DueDate) {
                allEvents.push({
                    id: ID,
                    start: Type === "P" ? moment(DueDate).toDate() : moment.utc(DueDate).local().toDate(),
                    end: Type === "P" ? moment(DueDate).toDate() : moment.utc(DueDate).local().toDate(),
                    title: Title,
                    desc: Description,
                    allDay: 'Y',
                    AddedBy,
                    Data: {
                        ...Data,
                        type: "T",
                        toDo: val
                    }
                });
            }
        }));
        if (trips) {
            trips.map((trip) => {
                let start = new Date(trip.InProgressTime || trip.ArrivedTime || trip.EnRouteTime || trip.ScheduledTime || trip.ScheduledDate);
                let duration = trip.ActualDuration || trip.Duration;
                if (duration < .5) {
                    duration = .5;
                }
                let end = new Date(start).setHours(new Date(start).getHours() + duration);
                let allDay = false;
                if (start == end || (!trip.InProgressTime && !trip.ArrivedTime && !trip.EnRouteTime && !trip.ScheduledTime) || (!trip.Duration && !trip.ActualDuration)) {
                    end = start;
                    allDay = "Y";
                }
                allEvents.push({
                    id: trip.ID,
                    allDay,
                    title: `WO #${trip.WorkOrder}-${trip.Trip}`,
                    desc: trip.Description,
                    start,
                    end,
                    Data: {
                        type: 'W',
                        trip
                    }
                })
            })
        }
        return allEvents;
    }

    setMonth = _.debounce((date) => {
        const { month, getCalendarData, Co, tech } = this.props;
        const oldMonth = moment(month).format("MM/01/YYYY");
        const newMonth = moment(date).format("MM/01/YYYY");
        if (oldMonth !== newMonth) {
            getCalendarData(Co, date, tech ? tech.Technician : null);
        }
    }, 750);


    render() {
        const { classes, events, openNewEventDialog, openEditEventDialog, openEditWorkOrderDialog, openEditTodoDialog, openCardDialog, month } = this.props;
        const allEvents = this.getEvents();
        const statusProps =
            [
                { text: 'Scheduled', icon: 'access_time', color: '#fff' },
                { text: 'Notified', icon: 'offline_bolt', color: 'rgb(0, 150, 250)' },
                { text: 'Accepted', icon: 'offline_pin', color: 'rgb(0, 50, 250)' },
                { text: 'Rejected', icon: 'cancel', color: 'rgb(210, 0, 0)' },
                { text: 'En-Route', icon: 'explore', color: 'rgb(50, 170, 200)' },
                { text: 'Arrived', icon: 'my_location', color: 'rgb(50, 150, 100)' },
                { text: 'In Progress', icon: 'play_circle_outline', color: 'rgb(150, 200, 50)' },
                { text: 'On Hold', icon: 'pause_circle_outline', color: 'rgb(210, 100, 100)' },
                { text: 'Completed', icon: 'check_circle', color: '#d1e751' }
            ];


        return (
            <div className={classes.root}>
                <div className="w-full">
                    <DragAndDropCalendar
                        className="flex flex-1 bg-grey-lightest rounded-lg"
                        selectable
                        localizer={localizer}
                        events={allEvents}
                        onEventDrop={this.moveEvent}
                        resizable
                        onEventResize={this.resizeEvent}
                        defaultView="month"
                        defaultDate={new Date(month)}
                        draggableAccessor={event => event.Data.type === 'E'}
                        resizableAccessor={event => event.Data.type === 'E'}
                        onNavigate={this.setMonth}
                        startAccessor="start"
                        endAccessor="end"
                        views={allViews}
                        showMultiDayTimes
                        eventPropGetter={(event) => {
                            const { trip, toDo } = event.Data;
                            return {
                                className: trip ? "bg-darkgrey" : toDo ? (toDo.CompletedYN !== "Y" && new Date(toDo.DueDate) < new Date()) ? "bg-error" : toDo.Type === "P" ? "bg-purple" : "bg-primary" : undefined,
                            }
                        }}
                        components={{
                            toolbar: CalendarHeader,
                            event: props => {
                                // window["warn"]('Event Data: ', props);
                                const { event } = props;
                                const { trip, type, toDo } = event.Data;
                                return <div className="text-12 font-bold mt-4 truncate"><Icon className="text-20 mr-4 align-middle mb-4" style={{ color: trip ? statusProps[trip.Status].color : undefined }} color={toDo && toDo.CompletedYN === "Y" ? "secondary" : undefined}>{trip ? statusProps[trip.Status].icon : toDo ? toDo.Type === "P" ? toDo.CompletedYN === "Y" ? "check_circle_outline" : "table_chart" : "check_box" : "event"}</Icon>{trip && !trip.Status ? "Busy" : event.title}</div>
                            }
                        }}
                        // onNavigate={this.handleNavigate}
                        onSelectEvent={event => {
                            const { trip, toDo, type } = event.Data;
                            if (trip) {
                                if (trip.Status > 0) {
                                    let WorkOrder = { Co: trip.Co, WorkOrder: trip.WorkOrder };
                                    openEditWorkOrderDialog(WorkOrder, trip.Trip);
                                }
                            } else {
                                if (toDo) {
                                    if (toDo.Type === "P") {
                                        openCardDialog(toDo, true);
                                    } else {
                                        openEditTodoDialog(toDo);
                                    }
                                } else {
                                    openEditEventDialog(event);
                                }
                            }
                        }}
                        onSelectSlot={slotInfo => openNewEventDialog({
                            start: slotInfo.start,
                            end: slotInfo.start,
                            Data: {
                                type: 'C',
                                trip: null,
                                Attendees: [],
                                ErrMsg: null
                            }
                        })}
                    />
                </div>
                <FuseAnimate animation="transition.expandIn" delay={500}>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={() => openNewEventDialog({
                            start: new Date(),
                            end: new Date(),
                            Data: {
                                type: 'C',
                                trip: null,
                                Attendees: [],
                                ErrMsg: null
                            }
                        })}
                    >
                        <Icon>add</Icon>
                    </Fab>
                </FuseAnimate>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // getEvents: Actions.getEvents,
        openNewEventDialog: Actions.openNewEventDialog,
        openEditEventDialog: Actions.openEditEventDialog,
        updateEvent: Actions.updateEvent,
        getCalendarData,
        openEditWorkOrderDialog,
        openEditTodoDialog,
        openCardDialog
    }, dispatch);
}


function mapStateToProps({ calendarApp, spReducers }) {
    return {
        events: spReducers.calendar.data.Events,
        trips: spReducers.calendar.data.Trips,
        toDos: spReducers.calendar.data.ToDos,
        month: spReducers.calendar.month,
        Co: spReducers.companies.Co,
        tech: spReducers.technician.technician,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CalendarApp));

