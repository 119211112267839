import axios from 'axios';
import { getUserData } from 'main/content/apps/text-gathers/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_TEXT_GATHERS = '[TEXT GATHERS APP] GET TEXT GATHERS';
export const SET_SEARCH_TEXT = '[TEXT GATHERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TEXT_GATHERS = '[TEXT GATHERS APP] TOGGLE IN SELECTED TEXT GATHERS';
export const SELECT_ALL_TEXT_GATHERS = '[TEXT GATHERS APP] SELECT ALL TEXT GATHERS';
export const DESELECT_ALL_TEXT_GATHERS = '[TEXT GATHERS APP] DESELECT ALL TEXT GATHERS';
export const OPEN_NEW_TEXT_GATHER_DIALOG = '[TEXT GATHERS APP] OPEN NEW TEXT GATHER DIALOG';
export const CLOSE_NEW_TEXT_GATHER_DIALOG = '[TEXT GATHERS APP] CLOSE NEW TEXT GATHER DIALOG';
export const OPEN_EDIT_TEXT_GATHER_DIALOG = '[TEXT GATHERS APP] OPEN EDIT TEXT GATHER DIALOG';
export const UPDATE_EDIT_TEXT_GATHER_DIALOG = '[TEXT GATHERS APP] UPDATE EDIT TEXT GATHER DIALOG';
export const CLOSE_EDIT_TEXT_GATHER_DIALOG = '[TEXT GATHERS APP] CLOSE EDIT TEXT GATHER DIALOG';
export const ADD_TEXT_GATHER = '[TEXT GATHERS APP] ADD TEXT GATHER';
export const UPDATE_TEXT_GATHER = '[TEXT GATHERS APP] UPDATE TEXT GATHER';
export const REMOVE_TEXT_GATHER = '[TEXT GATHERS APP] REMOVE TEXT GATHER';
export const REMOVE_TEXT_GATHERS = '[TEXT GATHERS APP] REMOVE TEXT GATHERS';
export const TOGGLE_STARRED_TEXT_GATHER = '[TEXT GATHERS APP] TOGGLE STARRED TEXT GATHER';
export const TOGGLE_STARRED_TEXT_GATHERS = '[TEXT GATHERS APP] TOGGLE STARRED TEXT GATHERS';
export const SET_TEXT_GATHERS_STARRED = '[TEXT GATHERS APP] SET TEXT GATHERS STARRED ';
export const SET_SEARCH_RESULTS = '[TEXT GATHERS APP] SET SEARCH RESULTS';

export function getTextGathers(routeParams) {
    const request = axios.get('/api/text-gathers-app/text-gathers', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TEXT_GATHERS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTextGathers(textGatherId) {
    return {
        type: TOGGLE_IN_SELECTED_TEXT_GATHERS,
        textGatherId
    }
}


export function selectAllTextGathers() {
    return {
        type: SELECT_ALL_TEXT_GATHERS
    }
}

export function deSelectAllTextGathers() {
    return {
        type: DESELECT_ALL_TEXT_GATHERS
    }
}


export function openNewTextGatherDialog() {
    return {
        type: OPEN_NEW_TEXT_GATHER_DIALOG
    }
}

export function closeNewTextGatherDialog() {
    return {
        type: CLOSE_NEW_TEXT_GATHER_DIALOG
    }
}

export function openEditTextGatherDialog(data) {
    return {
        type: OPEN_EDIT_TEXT_GATHER_DIALOG,
        data
    }
}

export function closeEditTextGatherDialog() {
    return {
        type: CLOSE_EDIT_TEXT_GATHER_DIALOG
    }
}

export function addTextGather(newTextGather) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/TextGather`,
            newTextGather
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_TEXT_GATHER,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Gather ${response.data.ID} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateTextGather(textGather) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/TextGather?Co=${encodeURIComponent(textGather.Co)}&Gather=${encodeURIComponent(textGather.ID)}`,
            textGather
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_TEXT_GATHER,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Gather ${textGather.ID} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeTextGather(textGather) {
    return (dispatch, getState) => {

        const request = axios.post('/api/text-gathers-app/remove-textGather', {
            textGather
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TEXT_GATHER,
                    data: textGather
                })
            ])
        );
    };
}


export function removeTextGathers(textGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGathersApp.textGathers;

        const request = axios.post('/api/text-gathers-app/remove-textGathers', {
            textGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TEXT_GATHERS
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHERS
                })
            ]).then(() => dispatch(getTextGathers(routeParams)))
        );
    };
}

export function toggleStarredTextGather(textGatherId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().textGathersApp.textGathers;

        const request = axios.post('/api/text-gathers-app/toggle-starred-textGather', {
            textGatherId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TEXT_GATHER
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTextGathers(routeParams)))
        );
    };
}

export function toggleStarredTextGathers(textGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGathersApp.textGathers;

        const request = axios.post('/api/text-gathers-app/toggle-starred-textGathers', {
            textGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TEXT_GATHERS
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTextGathers(routeParams)))
        );
    };
}

export function setTextGathersStarred(textGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGathersApp.textGathers;

        const request = axios.post('/api/text-gathers-app/set-textGathers-starred', {
            textGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEXT_GATHERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTextGathers(routeParams)))
        );
    };
}

export function setTextGathersUnstarred(textGatherIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().textGathersApp.textGathers;

        const request = axios.post('/api/text-gathers-app/set-textGathers-unstarred', {
            textGatherIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TEXT_GATHERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TEXT_GATHERS
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function purchaseTextGather(num) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/IncomingTextGather`, num);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: ADD_TEXT_GATHER
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Gather ${num.Number} has been successfully purchased.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        });
    }
}

export function searchAvailableNumbers(Co, area, search) {
    return (dispatch, getState) => {
        var request = axios.get(`${window["apiLocation"]}/api/IncomingTextGather?Co=${encodeURIComponent(Co)}&AreaCode=${encodeURIComponent(area)}&Pattern=${search}`);

        return request.then((response) => {
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: response.data,
            })
        });
    }
}
