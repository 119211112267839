import * as Actions from 'store/actions/spConnection';

const formOfPayments = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_FORM_OF_PAYMENT_DATA:
            {
                return action.payload;
            }
        case Actions.SET_FORM_OF_PAYMENT_DATA:
            {
                return action.payload;
            }
    }

    return state;
};

export default formOfPayments;