import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import CustomersList from 'main/content/apps/customers/CustomersList';
import CustomersHeader from 'main/content/apps/customers/CustomersHeader';
import CustomersLeftSidebarHeader from 'main/content/apps/customers/CustomersLeftSidebarHeader';
import CustomersRightSidebarHeader from 'main/content/apps/customers/CustomersRightSidebarHeader';
import CustomersSidebarContent from 'main/content/apps/customers/CustomersSidebarContent';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
});

class CustomersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getCustomers(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewCustomerDialog, openNewCustomerSiteDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <CustomersHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <CustomersLeftSidebarHeader />
                            }
                            content={
                                <CustomersList />

                            }
                            leftSidebarContent={
                                <CustomersSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCustomers: Actions.getCustomers,
        getUserData: Actions.getUserData,
        openNewCustomerDialog: Actions.openNewCustomerDialog
    }, dispatch);
}

function mapStateToProps({ customersApp }) {
    return {
        customers: customersApp.customers.entities,
        selectedCustomerIds: customersApp.customers.selectedCustomerIds,
        searchText: customersApp.customers.searchText,
        user: customersApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
