import { FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import history from 'history.js';
import CompanyHeader from 'main/content/apps/companies/CompaniesHeader';
import CompanyList from 'main/content/apps/companies/CompaniesList';
import CompanySidebarContent from 'main/content/apps/companies/CompaniesSidebarContent';
import CompanyDialog from 'main/content/apps/companies/CompanyDialog';
import CompanyLeftSidebarHeader from 'main/content/apps/companies/CompanyLeftSidebarHeader';
import DepartmentDialog from 'main/content/apps/departments/DepartmentDialog';
import DivisionDialog from 'main/content/apps/divisions/DivisionDialog';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setQBOLink, getQBOCompanyData } from 'store/actions';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class CompaniesApp extends Component {

    state = {
        confirmQBOConnection: false,
        errorQBOConnection: false,
    }

    componentDidMount() {
        const { qboLink } = this.props;
        if (qboLink) {
            const prms = this.getParams(window.location);
            if (qboLink.VerificationToken && qboLink.VerificationToken === prms.state) {
                const data = _.cloneDeepWith({ ...prms, ...qboLink });
                Promise.all([
                    this.props.setQBOLink(null)
                ]).then(() => {
                    history.push(`/apps/companies`);
                    setTimeout(() => this.connectQBO(data), 1500);
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            const { qboLink } = this.props;
            if (qboLink) {
                const prms = this.getParams(window.location);
                if (qboLink.VerificationToken && qboLink.VerificationToken === prms.state) {
                    const data = _.cloneDeepWith({ ...prms, ...qboLink });
                    Promise.all([
                        this.props.setQBOLink(null)
                    ]).then(() => {
                        history.push(`/apps/companies`);
                        setTimeout(() => this.connectQBO(data), 1500);
                    });
                }
            }
        }
    }

    getParams = (url) => {
        const query = url.search.substring(1);
        const obj = {};
        const vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0].length > 0) {
                obj[decodeURIComponent(pair[0])] = pair[1];
            }
        }
        return obj;
    }

    connectQBO = (data) => {
        const { Co, realmId, code, InitToken } = data;
        const { companies } = this.props;
        const co = _.find(companies, { Co });
        if (co) {
            const request = axios.put(`${window["apiLocation"]}/api/QBOAuth?Co=${Co}`, {
                token: InitToken,
                code,
                id: realmId
            });
            request.then((response) => {
                if (response.data && response.data.length > 0) {
                    this.setState({ errorQBOConnection: response.data });
                } else {
                    const req = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Company`);
                    req.then((res) => {
                        this.setState({ confirmQBOConnection: `Successfully connected Company #${data.Co} to the ${res.data.Name} QBO Account! (ID#${data.realmId})` }, () =>  this.props.getQBOCompanyData(data.realmId));
                    }).catch((err) => {
                        this.setState({ confirmQBOConnection: `Successfully connected Company #${data.Co} to QBO Account #${data.realmId}!` }, () =>  this.props.getQBOCompanyData(data.realmId));
                    })
                    // Promise.all([
                    //     this.props.openEditCompanyDialog({ ...co.Data.Co, IntegrationType: 'Q', QBOCompany: realmId })
                    // ]).then(() => {
                    // window["log"](`Ready to connect Company #${data.Co} to QBO Account #${data.realmId}`);
                    // });
                }
            }).catch((err) => {
                this.setState({ errorQBOConnection: `Error linking QBO Account: ${err.message}` });
            })
        }
    }

    render() {
        const { classes, openNewCompanyDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <CompanyHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <CompanyLeftSidebarHeader />
                            }
                            content={
                                <CompanyList />
                            }
                            leftSidebarContent={
                                <CompanySidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {/* <CompanyDialog /> */}
                        {/* <DivisionDialog /> */}
                        <DepartmentDialog />
                        {this.state.confirmQBOConnection &&
                            <ConfirmationDialog
                                open={this.state.confirmQBOConnection}
                                title={<div><Icon className="mr-6 align-middle mb-4" color="secondary">check_circle_outline</Icon>Success</div>}
                                content={<div className="w-full pt-8 pb-8">{this.state.confirmQBOConnection}</div>}
                                confirmText="OK"
                                hideCancel={true}
                                onConfirm={() => this.setState({ ...this.state, confirmQBOConnection: false })}
                            />

                        }
                        {this.state.errorQBOConnection &&
                            <ConfirmationDialog
                                open={this.state.errorQBOConnection}
                                title={<div><Icon className="mr-6 align-middle mb-4" color="error">error</Icon>Error</div>}
                                content={<div className="w-full pt-8 pb-8">{this.state.errorQBOConnection}</div>}
                                confirmText="OK"
                                hideCancel={true}
                                onConfirm={() => this.setState({ ...this.state, errorQBOConnection: false })}
                            />

                        }
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCompanies: Actions.getCompanies,
        getUserData: Actions.getUserData,
        openNewCompanyDialog: Actions.openNewCompanyDialog,
        openEditCompanyDialog: Actions.openEditCompanyDialog,
        setQBOLink,
        getQBOCompanyData
    }, dispatch);
}

function mapStateToProps({ companiesApp, spReducers }) {
    return {
        companies: spReducers.companies.List,
        selectedCompanyIds: companiesApp.companies.selectedCompanyIds,
        searchText: companiesApp.companies.searchText,
        user: companiesApp.user,
        qboLink: spReducers.companies.QBOLink
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CompaniesApp)));
