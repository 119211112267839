import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class DivisionsList extends Component {

    state = {
        selectedDivisionsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedDivisionMenu = (event) => {
        this.setState({ selectedDivisionsMenu: event.currentTarget });
    };

    closeSelectedDivisionsMenu = () => {
        this.setState({ selectedDivisionsMenu: null });
    };

    render() {
        const { classes, securables, divisions, user, searchText, openNewDivisionDialog, selectedDivisionIds, selectAllDivisions, qboCompany, qboServices, qboMaterials, qboNonInventory, deSelectAllDivisions, toggleInSelectedDivisions, openEditDivisionDialog, removeDivisions, removeDivision, toggleStarredDivision, setDivisionsUnstarred, setDivisionsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedDivisionsData ? (!this.props.divisionData ? [] : this.props.divisionData) : this.props.selectedDivisionsData), searchText);
        const { selectedDivisionsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "divisions" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewDivisionDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">location_city</Icon>Divisions</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditDivisionDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 300,
                            },
                            {
                                Header: "Default QBO Service",
                                id: "qboService",
                                accessor: row => {
                                    const item = _.find(qboServices, { Id: row["DefaultQBOServiceItem"] });
                                    if (item) {
                                        return item.Name
                                    }
                                },
                                className: "justify-center",
                                width: 300,
                                show: Boolean(qboCompany)
                            },
                            {
                                Header: "Default QBO Purchase Item",
                                id: "qboPOItem",
                                accessor: row => {
                                    const item = _.find(qboNonInventory, { Id: row["DefaultQBONonInventoryItem"] });
                                    if (item) {
                                        return item.Name
                                    }
                                },
                                className: "justify-center",
                                width: 300,
                                show: Boolean(qboCompany)
                            },
                            {
                                Header: "Default QBO Deferred Revenue Item",
                                id: "qboDeferredItem",
                                accessor: row => {
                                    const item = _.find(qboNonInventory, { Id: row["DefaultQBODeferredRevenueItem"] });
                                    if (item) {
                                        return item.Name
                                    }
                                },
                                className: "justify-center",
                                width: 300,
                                show: Boolean(qboCompany)
                            },
                            {
                                Header: "Default QBO Equipment Rental Item",
                                id: "qboEqpItem",
                                accessor: row => {
                                    const item = _.find(qboNonInventory, { Id: row["DefaultQBOEquipmentRentalItem"] });
                                    if (item) {
                                        return item.Name
                                    }
                                },
                                className: "justify-center",
                                width: 300,
                                show: Boolean(qboCompany)
                            },
                            {
                                Header: "Labor Cost GL",
                                accessor: "LaborCostGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Labor Revenue GL",
                                accessor: "LaborRevenueGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Material Cost GL",
                                accessor: "MaterialCostGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Material Revenue GL",
                                accessor: "MaterialRevenueGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Equipment Cost GL",
                                accessor: "EquipmentCostGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Equipment Revenue GL",
                                accessor: "EquipmentRevenueGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Misc Cost GL",
                                accessor: "MiscCostGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Misc Revenue GL",
                                accessor: "MiscRevenueGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Deferred Revenue GL",
                                accessor: "DeferredRevenueGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                            {
                                Header: "Default Revenue GL",
                                accessor: "DefaultRevenueGL",
                                className: "justify-center",
                                width: 200,
                                show: Boolean(!qboCompany)
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Divisions found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDivisions: Actions.getDivisions,
        getUserData: Actions.getUserData,
        toggleInSelectedDivisions: Actions.toggleInSelectedDivisions,
        selectAllDivisions: Actions.selectAllDivisions,
        deSelectAllDivisions: Actions.deSelectAllDivisions,
        openEditDivisionDialog: Actions.openEditDivisionDialog,
        removeDivisions: Actions.removeDivisions,
        removeDivision: Actions.removeDivision,
        toggleStarredDivision: Actions.toggleStarredDivision,
        toggleStarredDivisions: Actions.toggleStarredDivisions,
        setDivisionsStarred: Actions.setDivisionsStarred,
        setDivisionsUnstarred: Actions.setDivisionsUnstarred,
        openNewDivisionDialog: Actions.openNewDivisionDialog
    }, dispatch);
}

function mapStateToProps({ divisionsApp, spReducers }) {
    return {
        divisions: divisionsApp.divisions.entities,
        selectedDivisionIds: divisionsApp.divisions.selectedDivisionIds,
        searchText: divisionsApp.divisions.searchText,
        user: divisionsApp.user,
        divisionData: spReducers.divisions,
        securables: spReducers.userProfiles.User.Data.Securables,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboServices: spReducers.qboSettings.services,
        qboMaterials: spReducers.qboSettings.materials,
        qboNonInventory: spReducers.qboSettings.nonInventory,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DivisionsList)));
