import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Avatar, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class TechProjectionList extends Component {

    state = {

    };

    componentDidMount() {
        let { date, loading, } = this.props;
        if (date) {
            this.setState({ ...this.state, date, loading, });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { date, loading, } = this.props;
        if (!_.isEqual(date, prevProps.date) || !_.isEqual(loading, prevProps.loading)) {
            this.setState({ ...this.state, date, loading });
        }
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (num && !isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    render() {
        const { classes, securables, user, searchText, technicians, openEditTechProjectionDialog, removeTechProjection, onSelected, data } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <div className="sm:flex p-12 sm:p-0">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><Icon color="primary" className="text-32 mr-12">today</Icon>
                            <span>Projections</span>
                        </Typography>
                    </div>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo && onSelected) {
                                        onSelected(_.cloneDeepWith(rowInfo.original));
                                    }
                                }
                            }
                        }}
                        data={_.orderBy(data, ["Mth"], ["desc"])}
                        columns={[
                            {
                                Header: "Technician",
                                accessor: "Technician",
                                minWidth: 192,
                                className: "justify-center",
                                Cell: row => {
                                    const { Co, Technician } = row.original;
                                    const value = _.find(technicians, { Co, Technician });
                                    return <React.Fragment>
                                        <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                        {`${value.FirstName} ${value.LastName}`}
                                    </React.Fragment>;
                                }
                            },
                            {
                                Header: "Month",
                                accessor: "Month",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => (
                                    row.original.Mth &&
                                    new Date(row.original.Mth).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Projected Revenue",
                                accessor: "Projected",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => this.formatDollars(row.original.Projected)
                            },
                            {
                                Header: "Projected Cost",
                                accessor: "CostTarget",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => this.formatDollars(row.original.Projected * (row.original.CostTarget / 100))
                            },
                            {
                                Header: "Cost Target",
                                accessor: "CostTarget",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => `< ${this.formatPercentage(row.original.CostTarget)}`
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Records found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ techProjectionApp, spReducers, dispatchBoardApp }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechProjectionList)));
