import {combineReducers} from 'redux';
import materials from './materials.reducer';
import user from './user.reducer';

const materialsAppReducers = combineReducers({
    materials,
    user
});

export default materialsAppReducers;
