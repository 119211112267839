import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import { Hidden, Badge, Icon, IconButton, Input, Paper, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FuseAnimate, FuseSelectedTheme } from '@fuse';
import _ from '@lodash';

const styles = theme => ({
    root: {}
});

class VoiceMailboxesHeader extends Component {

    render() {
        const { classes, setSearchText, searchText, pageLayout, voicemail, voiceMailboxes, user } = this.props;
        const mailboxes = _.filter(voiceMailboxes, (o) => _.findIndex(o.Data.Handlers, { UserName: user }) > -1);
        const messages = _.filter(voicemail, (o) => _.findIndex(mailboxes, { VoiceMailbox: o.VoiceMailbox }) > -1);
        // window["warn"]('Voicemail Count: ', messages.length, messages, mailboxes);
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex flex-1">
                    <Paper className="flex items-center w-full h-48 sm:h-56 p-16 pl-4 md:pl-16 rounded-8 " elevation={1}>
                        <Hidden xlUp>
                            <IconButton
                                onClick={(ev) => pageLayout().toggleLeftSidebar()}
                                aria-label="open left sidebar"
                            >
                                <Badge badgeContent={messages.length} color="error">
                                    <Icon>voicemail</Icon>
                                </Badge>
                            </IconButton>
                        </Hidden>

                        <Icon color="action">search</Icon>

                        <Input
                            placeholder="Search Voice Mailboxes"
                            className="pl-16"
                            disableUnderline
                            fullWidth
                            value={searchText}
                            inputProps={{
                                'aria-label': 'Search'
                            }}
                            onChange={setSearchText}
                        />
                        <div className="flex flex-shrink items-center w-1/5">

                        </div>
                    </Paper>
                </div>
            </MuiThemeProvider>
        )
            ;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp, spReducers }) {
    return {
        searchText: voiceMailboxesApp.voiceMailboxes.searchText,
        voicemail: spReducers.voicemail,
        voiceMailboxes: spReducers.voiceMailboxes,
        user: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(VoiceMailboxesHeader));
