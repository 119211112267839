import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedInventoryLocationMaterialIds: [],
    routeParams: {},
    inventoryLocationMaterialDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            Location: null,
            Material: null,
            Cost: null,
            Price: null,
            StockQty: 0,
            LowStockQty: 0,
            ReOrderQty: 0,
            BinLocation: null,
            CountDate: null,
            PriceMethod: 'M',
            MarkupPercent: null,
            ActiveYN: 'Y',
            Notes: null,
            AttachmentID: null,
            StockedYN: 'N',
            Data: {
                ErrMsg: null,
            }
        }
    }
};

const inventoryLocationMaterialsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_INVENTORY_LOCATION_MATERIALS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_INVENTORY_LOCATION_MATERIALS:
            {

                const inventoryLocationMaterialId = action.inventoryLocationMaterialId;

                let selectedInventoryLocationMaterialIds = [...state.selectedInventoryLocationMaterialIds];

                if (selectedInventoryLocationMaterialIds.find(id => id === inventoryLocationMaterialId) !== undefined) {
                    selectedInventoryLocationMaterialIds = selectedInventoryLocationMaterialIds.filter(id => id !== inventoryLocationMaterialId);
                }
                else {
                    selectedInventoryLocationMaterialIds = [...selectedInventoryLocationMaterialIds, inventoryLocationMaterialId];
                }

                return {
                    ...state,
                    selectedInventoryLocationMaterialIds: selectedInventoryLocationMaterialIds
                };
            }
        case Actions.SELECT_ALL_INVENTORY_LOCATION_MATERIALS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedInventoryLocationMaterialIds = arr.map(inventoryLocationMaterials => inventoryLocationMaterials.id);

                return {
                    ...state,
                    selectedInventoryLocationMaterialIds: selectedInventoryLocationMaterialIds
                };
            }
        case Actions.DESELECT_ALL_INVENTORY_LOCATION_MATERIALS:
            {
                return {
                    ...state,
                    selectedInventoryLocationMaterialIds: []
                };
            }
        case Actions.OPEN_NEW_INVENTORY_LOCATION_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    inventoryLocationMaterialDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.inventoryLocationMaterialDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_INVENTORY_LOCATION_MATERIAL_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    inventoryLocationMaterialDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.inventoryLocationMaterialDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    inventoryLocationMaterialDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.inventoryLocationMaterialDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
};

export default inventoryLocationMaterialsReducer;
