import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { AppBar, Avatar, Badge, Button, Card, CardHeader, CardContent, CardActions, Chip, Collapse, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../call-handler/store/actions';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import { getConversation } from 'main/messaging/store/actions';
import { NavLink, withRouter } from 'react-router-dom';
import _ from '@lodash';
import moment from 'moment';
import Timer from 'react-compound-timer';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    completed: {
        backgroundColor: theme.palette.error,
    },
    ringing: {
        backgroundColor: '#3f3f3f',
    },
    card: {
        marginTop: 12,
        //cursor: 'pointer',
        width: '100%',
        boxShadow: 'none'
    },
    actions: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    campaign: {
        width: 'auto',
        top: 5,
        right: 5,
        padding: 10,
        paddingLeft: 0,
        paddingRight: 0,
        transform: 'none',
        background: 'transparent'
    },
    callInfo: {
        color: '#fff',
        height: 'auto',
        borderRadius: 3,
        marginLeft: 5,
        fontSize: 11,
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    tabs: {
        width: '100%',
    },
    tab: {
        minWidth: '33.3%',
        maxWidth: '33.3%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    recStatBadge: {
        padding: 14,
        //backgroundColor: '#3f3f3f !important'
    },
});

class IncomingCallHandler extends Component {
    state = {
        call: {
            To: '',
            From: '',
            Queue: '',
            CallSid: '',
            ConversationID: '',
            Data: {
                Customers: [],
                Sites: [],
                WorkOrders: [],
                Phone: {},
                QueueID: '',
                Queue: {},
                QueueName: '',
            }
        },
        status: 'queued',
        handler: null,
        value: 1,
        id: null,
        callID: null,
        hoverRecStatus: false
    };

    componentDidMount() {
        window["warn"](this.props);
        const { call, handler } = this.props;
        this.setState({ ...this.state, call, handler, tab: 0 });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { call, handler } = this.props;
        window["warn"](this.props, this.state);
        if (!_.isEqual(this.props.call, this.state.call) || !_.isEqual(this.props.handler, this.state.handler)) {
            this.setState({ ...this.state, call, handler, tab: 0 });
        }
    };

    handleTabChange = (event, value) => {
        if (this.state.value == value) {
            this.setState({ value: -1 });
        } else {
            this.setState({ value });
        }
    }
    handleExpandClick(id) {
        //const { expanded } = this.state;
        //const call = (id !== expanded ? id : null);
        //this.setState({ ...this.state, expanded: call }, () => { window["warn"](this.state) });
    };

    stop = (event) => {
        event.stopPropagation();
    }

    formatPhone(phone) {
        return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    handleCall = () => {
        const { call, handler } = this.state;
        const { username, pageLayout } = this.props;
        if (call.Type === 'text') {
            this.props.getConversation(call.Co, call.ConversationID.toLowerCase());
            pageLayout().toggleRightSidebar();
        }
        if (!handler) {
            this.setState({ ...this.state, status: 'answered', handler: username }, () => { this.props.acceptCall(call, username); });
        } else {
            if (handler === username) {
                this.setState({ ...this.state, status: 'completed' }, () => { this.props.endCall(call, username); });
            }
        }
    }

    endCall = () => {
        const { call, handler } = this.state;
        const { username } = this.props;
        if (!handler || handler === username) {
            this.setState({ ...this.state, status: 'completed' }, () => { this.props.endCall(call, username); });
        }
    }

    divertCall = () => {
        const { call, handler } = this.state;
        const { username, callQueues } = this.props;
        const queue = _.find(callQueues, { Co: call.Co, Queue: call.Queue });
        if (!handler && queue && queue.FallbackAction === "Dial" && queue.FallbackDial) {
            this.setState({ ...this.state, status: 'forwarded' }, () => { this.props.divertCall(call, queue.FallbackDial); });
        }
    }

    enterRecStatus = () => {
        this.setState({ ...this.state, hoverRecStatus: true });
    }

    leaveRecStatus = () => {
        this.setState({ ...this.state, hoverRecStatus: false });
    }

    updateRecStatus = (e) => {
        e.stopPropagation();
        const { call } = this.state;
        let status = call.RecordingStatus === 'in-progress' ? 'paused' : call.RecordingStatus === 'paused' ? 'in-progress' : call.RecordingStatus;
        this.props.updateRecordingStatus(call, status);
    }

    render() {
        const { classes, securables, user, openCustomer, openSite, openWorkOrder, newCustomerCall, id, callID, getCustomerData, getSiteData, handler, agent, callQueues, phoneNumbers, username } = this.props;
        const { call, value, status, hoverRecStatus, anchorEl } = this.state;
        const queue = _.find(callQueues, { Co: call.Co, Queue: call.Queue });
        const phone = _.find(phoneNumbers, { Co: call.Co, Phone: call.To });
        const { DefaultBusinessUnit, DefaultDivision, DefaultDepartment } = queue ? queue : {};
        const accessLevel = _.find(securables, { Securable: "call-handler" });
        const dbu = DefaultBusinessUnit;
        const ddv = DefaultDivision;
        const ddp = DefaultDepartment;
        const startDate = moment(moment.utc(call.Status === "queued" ? call.CreatedDate : call.HandledDate)).local().toDate();
        const wait = (new Date() - startDate);


        window["warn"]("call handler name", handler, username, call.RecordingStatus, queue)

        return (
            <Card onClick={this.stop} className={classes.card}>
                <CardContent>
                    <div style={{margin: 'auto', width: 95 }}>
                        {!handler ?
                            <Tooltip title={"Accept Conversation"} placement="top">
                                <Avatar style={{ height: '90px', width: '90px', cursor: 'pointer'}} onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.handleCall() } }} className={(agent && !agent.SessionID && this.state.status === "answered") || status === 'forwarded' ? classes.ringing : status === 'completed' ? classes.completed : handler || status === 'answered' ? classes.selectedCallAvatar : classes.avatar}>
                                    <Icon className='text-48'>{call.Type === 'text' ? 'phone_iphone' : agent && !agent.SessionID && this.state.status === "answered" ? 'phone_callback' : (handler || status == 'answered') && status !== 'completed' ? 'phone_in_talk' : status === 'forwarded' ? 'phone_forwarded' : 'ring_volume'}</Icon>
                                </Avatar>
                            </Tooltip> :
                            <Tooltip title={"End Conversation"} placement="top">
                                <Avatar style={{ height: '90px', width: '90px', cursor: 'pointer'}} onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.handleCall() } }} className={(agent && !agent.SessionID && this.state.status === "answered") || status === 'forwarded' ? classes.ringing : status === 'completed' ? classes.completed : handler || status === 'answered' ? classes.selectedCallAvatar : classes.avatar}>
                                    <Icon>{call.Type === 'text' ? 'phone_iphone' : agent && !agent.SessionID && this.state.status === "answered" ? 'phone_callback' : (handler || status == 'answered') && status !== 'completed' ? 'phone_in_talk' : status === 'forwarded' ? 'phone_forwarded' : 'ring_volume'}</Icon>
                                </Avatar>
                            </Tooltip>
                        }
                    </div>

                    <Typography className='mt-12' style={{ width: 150, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                        {`${this.formatPhone(call.From.replace('+1', ''))}`}
                    </Typography>

                    {call.Status === 'queued' ?
                        <Typography className='mt-12 text-14' style={{ width: 150, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            {`${startDate.toLocaleTimeString('en-US')}`}
                        </Typography>
                        :
                        call.CreatedDate && wait && wait > 0 ?
                            <Timer initialTime={wait} lastUnit="m">
                                {(timer) => {
                                    return (
                                        <div style={{margin: "10px auto", width: 150}}>
                                            <label className="text-14">
                                                <Timer.Minutes />
                                            </label>
                                            <label className="text-14 ml-4">
                                                Minutes
                                            </label>
                                            <label className="text-14 ml-4">
                                                <Timer.Seconds />
                                            </label>
                                            <label className="text-14 ml-4">
                                                Seconds
                                            </label>
                                        </div>
                                    )
                                }}
                            </Timer> : ''
                    }

                    <div className='mt-12 flex' style={{marginLeft: 'auto', marginRight: 'auto', width: 150}}>
                        <div style={{ flex: 1 }}>
                            <Tooltip title={"End Call"} placement="top">
                                <IconButton 
                                    onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.endCall(); this.setState({ anchorEl: null }) } }}
                                    disabled={call.Status === 'answered' && handler && handler === username ? false : true}
                                >
                                    <Icon color={call.Status === 'answered' && handler && handler === username ? 'error' : 'disabled'}>call_end</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{ flex: 1 }}>
                            <Tooltip title={call.RecordingStatus === 'in-progress' ? "Pause Recording" : "Resume Recording"} placement="top">
                                <IconButton 
                                    disabled={call.RecordingStatus && call.RecordingStatus !== 'completed' ? false : true} 
                                    onClick={this.updateRecStatus} 
                                    onMouseEnter={this.enterRecStatus} 
                                    onMouseLeave={this.leaveRecStatus} 
                                    className={classes.recStatBadge}
                                    style={{ backgroundColor: call.RecordingStatus && call.RecordingStatus !== 'completed' ? '#3f3f3f' : null }}
                                >
                                    <Icon 
                                        style={{ color: call.RecordingStatus && call.RecordingStatus !== 'completed' ? call.RecordingStatus === 'in-progress' && !hoverRecStatus ? 'white' : hoverRecStatus ? 'white' : '#f44336' : 'rgba(0, 0, 0, 0.26)'}} 
                                        className='text-20'>{call.RecordingStatus === 'in-progress' && !hoverRecStatus ? 'mic' : hoverRecStatus ? 'mic' : 'mic_off'}
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{ flex: 1 }}>
                            <Tooltip title={ queue && queue.FallbackAction === 'Dial' && queue.FallbackDial ? `Divert to ${queue.FallbackDial}` : null} placement="top">
                                <IconButton 
                                    disabled={!handler && queue && queue.FallbackAction === 'Dial' && queue.FallbackDial && status !== 'forwarded' ? false : true} 
                                    onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.divertCall() } }} 
                                    aria-owns={undefined}
                                >
                                    <Icon 
                                        color={!handler && queue && queue.FallbackAction === 'Dial' && queue.FallbackDial && status !== 'forwarded' ? 'primary' : 'disabled'}
                                    >
                                        phone_forwarded
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //openCall: Actions.openCall,
        openCustomer: openEditCustomerDialog,
        openSite: openEditCustomerSiteDialog,
        openWorkOrder: openEditWorkOrderDialog,
        getCustomerData: Actions.getCustomerData,
        getSiteData: Actions.getSiteData,
        acceptCall: Actions.acceptCall,
        divertCall: Actions.divertCall,
        endCall: Actions.endCall,
        newCustomerCall: Actions.newCustomerCall,
        updateRecordingStatus: Actions.updateRecordingStatus,
        getConversation,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, contactsApp, spReducers }) {
    return {
        user: contactsApp.user,
        callHandler: callHandlerApp.callHandler,
        callID: callHandlerApp.callHandler.id,
        username: spReducers.userProfiles.User.UserName,
        agent: spReducers.agentStatus.Agent,
        securables: spReducers.userProfiles.User.Data.Securables,
        callQueues: spReducers.callQueues,
        phoneNumbers: spReducers.phoneNumbers,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(IncomingCallHandler)));