import axios from 'axios/index'
import { getUserData } from 'main/content/apps/customers/store/actions/user.actions'
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions/work-orders.actions'
import { showMessage, addDialogHistory } from 'store/actions'
import _ from '@lodash'

export const SET_SEARCH_TEXT = '[CALL HANDLER APP] SET SEARCH TEXT'
export const SET_CUSTOMER_DATA = '[CALL HANDLER APP] SET CUSTOMER DATA'
export const GET_CUSTOMER_DATA = '[CALL HANDLER APP] GET CUSTOMER DATA'
export const CREATE_CUSTOMER = '[CALL HANDLER APP] CREATE CUSTOMER'
export const SET_SITE_DATA = '[CALL HANDLER APP] SET SITE DATA'
export const GET_SITE_DATA = '[CALL HANDLER APP] GET SITE DATA'
export const CREATE_CUSTOMER_SITE = '[CALL HANDLER APP] CREATE CUSTOMER SITE'
export const SET_WORK_ORDER_DATA = '[CALL HANDLER APP] SET WORK ORDER DATA'
export const GET_WORK_ORDER_DATA = '[CALL HANDLER APP] GET WORK ORDER DATA'
export const SET_CALL_HANDLER_STEP = '[CALL HANDLER APP] SET CALL HANDLER STEP'
export const CREATE_CALL_HANDLER_MAP =
  '[CALL HANDLER APP] CREATE CALL HANDLER MAP'
export const SAVE_CALL_HANDLER_DRAFT =
  'CALL HANDLER APP] SAVE CALL HANDLER DRAFT'
export const OPEN_CALL_HANDLER_DRAFT =
  'CALL HANDLER APP] OPEN CALL HANDLER DRAFT'
export const DELETE_CURRENT_CALL_HANDLER_DRAFT =
  '[CALL HANDLER APP] DELETE CURRENT CALL HANDLER DRAFT'
export const CLEAR_CALL_HANDLER_DATA =
  '[CALL HANDLER APP] CLEAR CALL HANDLER DATA'
export const CLEAR_WORK_ORDER_DATA = '[CALL HANDLER APP] CLEAR WORK ORDER DATA'
export const SET_CALL = '[CALL HANDLER APP] SET CALL'
export const START_SESSION = '[CALL HANDLER APP] START SESSION'
export const END_SESSION = '[CALL HANDLER APP] END SESSION'
export const ACCEPT_CALL = '[CALL HANDLER APP] ACCEPT CALL'
export const DIVERT_CALL = '[CALL HANDLER APP] ACCEPT CALL'
export const END_CALL = '[CALL HANDLER APP] END CALL'
export const UPDATE_RECORDING_STATUS =
  '[CALL HANDLER APP] UPDATE RECORDING STATUS'
export const NEW_CUSTOMER_CALL = '[CALL HANDLER APP] NEW CUSTOMER CALL'
export const GET_SCHEDULE = '[CALL HANDLER APP] GET SCHEDULE'
// export const GET_UNAVAILABLE = '[CALL HANDLER APP] GET UNAVAILABLE';
// export const GET_SCHEDULED_WOS = '[CALL HANDLER APP] GET SCHEDULED WOS';

export function getScheduledTrips(Co, dt) {
  window['warn']('New Dispatch Date: ', dt)
  if (dt) {
    const request = axios.get(`${window['apiLocation']}/api/DispatchBoard`, {
      params: {
        Co,
        Date: dt,
      },
    })

    return (dispatch, getState) => {
      dispatch({
        type: GET_SCHEDULE,
        trips: [],
      })
      // dispatch();
      request.then(response => {
        Promise.all([dispatch(getScheduledWOs(Co, dt, response.data))])
      })
    }
  }
}

export function getScheduledWOs(Co, dt, schedule) {
  window['warn']('SCHEDULEEEEEE:', schedule)
  if (dt) {
    const request = axios.get(`${window['apiLocation']}/api/WorkOrder`, {
      params: {
        Co,
        DueBy: dt,
      },
    })

    return dispatch => {
      dispatch({
        type: GET_SCHEDULE,
        schedule: [],
      })
      request.then(response => {
        if (_.isArray(schedule)) {
          schedule.map(trip => {
            const { Co, WorkOrder } = trip
            const wo = _.find(response.data, { Co, WorkOrder })
            if (wo) {
              trip.Data.WorkOrder = wo
            }
          })
          Promise.all([
            dispatch({
              type: GET_SCHEDULE,
              schedule,
            }),
          ])
        } else {
          console.error('Schedule is not array')
        }
      })
    }
  }
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function setCustomerData(data) {
  return {
    type: SET_CUSTOMER_DATA,
    data,
  }
}

export function newCustomerCall(call) {
  return {
    type: NEW_CUSTOMER_CALL,
    call,
  }
}

export function setCall(call) {
  return {
    type: SET_CALL,
    call,
  }
}

export function getCustomerData(data, clear, options) {
  return (dispatch, getState) => {
    const state = getState()

    const { callHandler } = state.callHandlerApp

    const { WorkOrder } = callHandler.data

    const { ID, CallSid } = WorkOrder

    const { Co, Customer, Site } = data

    const request = axios.get(
      `${window['apiLocation']}/api/Customer?Co=${encodeURIComponent(
        Co,
      )}&Customer=${encodeURIComponent(
        Customer,
      )}&Full=false&IncludeThirdPartyPaymentData=true`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: GET_CUSTOMER_DATA,
          data: response.data,
          clear: clear || Boolean(ID),
          options: !ID
            ? {
                ...(options || {}),
                CallSid: options && options.CallSid ? options.CallSid : CallSid,
              }
            : options || {},
        }),
      ]),
    )
  }
}

export function createCustomer(newCustomer) {
  return (dispatch, getState) => {
    const state = getState()

    const { CallSid } = state.callHandlerApp.callHandler.data.WorkOrder

    const request = axios.post(`${window['apiLocation']}/api/Customer`, {
      ...newCustomer,
      CallSid,
      Tags: JSON.stringify(newCustomer.Tags),
    })

    return request.then(response => {
      if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
        dispatch(
          showMessage({
            message: `Error: ${response.data.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      } else if (
        response.data.Data.DuplicatedEntities &&
        response.data.Data.DuplicatedEntities.length === 0
      ) {
        Promise.all([
          dispatch({
            type: CREATE_CUSTOMER,
            data: { ...newCustomer, ...response.data },
          }),
        ]).then(() => {
          dispatch(
            showMessage({
              message: `Customer #${response.data.Customer} has been successfully created.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
          const { Co, Customer, Name, FirstName, LastName } = response.data
          dispatch(
            addDialogHistory({
              timestamp: new Date(),
              dialog: 'Customer',
              params: {
                Co,
                Customer,
                Name,
                FirstName,
                LastName,
              },
            }),
          )
        })
      }

      return response.data
    })
  }
}

export function setSiteData(data) {
  return {
    type: SET_SITE_DATA,
    data,
  }
}

export function getSiteData(data, clear, options) {
  {
    return (dispatch, getState) => {
      const state = getState()

      const { callHandler } = state.callHandlerApp

      const { WorkOrder } = callHandler.data

      const { ID, CallSid } = WorkOrder

      const { Co, Customer, Site } = data

      const request = axios.get(
        `${window['apiLocation']}/api/CustomerSite?Co=${encodeURIComponent(
          Co,
        )}&Customer=${encodeURIComponent(Customer)}&Site=${encodeURIComponent(
          Site,
        )}&Full=false&IncludeThirdPartyPaymentData=true`,
      )

      return request.then(response =>
        Promise.all([
          dispatch(clearCallHandlerData()),
          dispatch({
            type: GET_SITE_DATA,
            data: response.data,
            clear: clear || Boolean(ID),
            options: !ID
              ? {
                  ...(options || {}),
                  CallSid:
                    options && options.CallSid ? options.CallSid : CallSid,
                }
              : options || {},
          }),
        ]),
      )
    }
  }
}

export function createCustomerSite(newCustomerSite) {
  return (dispatch, getState) => {
    const state = getState()

    const { CallSid } = state.callHandlerApp.callHandler.data.WorkOrder

    const request = axios.post(`${window['apiLocation']}/api/CustomerSite`, {
      ...newCustomerSite,
      CallSid,
      Tags: JSON.stringify(newCustomerSite.Tags),
    })

    return request.then(response => {
      if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
        dispatch(
          showMessage({
            message: `Error: ${response.data.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      } else {
        Promise.all([
          dispatch({
            type: CREATE_CUSTOMER_SITE,
            data: response.data,
          }),
        ]).then(() => {
          const cust = _.cloneDeepWith(
            state.callHandlerApp.callHandler.data.Customer,
          )
          cust.Data.Sites.push(response.data)
          dispatch(setCustomerData(cust))
          dispatch(
            showMessage({
              message: `Service Site #${response.data.Site} has been successfully created.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
          const { Co, Customer, Site, Description } = response.data
          dispatch(
            addDialogHistory({
              timestamp: new Date(),
              dialog: 'Site',
              params: {
                Co,
                Customer,
                Site,
                Description,
              },
            }),
          )
        })
      }
    })
  }
}

export function setWorkOrderData(data) {
  return {
    type: SET_WORK_ORDER_DATA,
    data,
  }
}

export function setCallHandlerStep(activeStep) {
  return {
    type: SET_CALL_HANDLER_STEP,
    activeStep,
  }
}

export function createMap(map) {
  return {
    type: CREATE_CALL_HANDLER_MAP,
    map,
  }
}

export function saveCallDraft(data) {
  return {
    type: SAVE_CALL_HANDLER_DRAFT,
    data,
  }
}

export function openCallDraft(id, data) {
  return dispatch => {
    dispatch({
      type: OPEN_CALL_HANDLER_DRAFT,
      id,
      data,
    })
    dispatch({
      type: SET_CALL_HANDLER_STEP,
      activeStep: data.activeStep,
    })
  }
}

export function clearCallHandlerData() {
  return {
    type: CLEAR_CALL_HANDLER_DATA,
  }
}

export function clearWorkOrderData() {
  return {
    type: CLEAR_WORK_ORDER_DATA,
  }
}

export function addWorkOrder(newWorkOrder) {
  return (dispatch, getState) => {
    const state = getState()
    const { CallSid } = state.callHandlerApp.callHandler.call

    const { routeParams } = getState().workOrdersApp.workOrders

    const request = axios.post(`${window['apiLocation']}/api/CallHandler`, {
      WorkOrder: newWorkOrder,
      Scope: {
        ...newWorkOrder,
        CallSid,
        Tags: JSON.stringify(newWorkOrder.Tags),
      },
    })

    return request.then(response => {
      if (
        response.data.WorkOrder.Data.ErrMsg &&
        response.data.WorkOrder.Data.ErrMsg.length > 0
      ) {
        dispatch(
          showMessage({
            message: `Error: ${response.data.WorkOrder.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      } else {
        if (
          response.data.Scope.Data.ErrMsg &&
          response.data.Scope.Data.ErrMsg.length > 0
        ) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Scope.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          Promise.all([
            dispatch({
              type: GET_WORK_ORDER_DATA,
              data: {
                ...response.data.WorkOrder,
                ...response.data.Scope,
                ID: response.data.WorkOrder.ID,
                Data: {
                  ...response.data.WorkOrder.Data,
                  ...response.data.Scope.Data,
                  ID: response.data.WorkOrder.ID,
                },
              },
            }),
            dispatch({
              type: DELETE_CURRENT_CALL_HANDLER_DRAFT,
            }),
          ]).then(() => {
            dispatch(
              showMessage({
                message: `Work Order #${response.data.WorkOrder.WorkOrder} has been successfully created.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
            const { Co, WorkOrder } = response.data.WorkOrder
            dispatch(
              addDialogHistory({
                timestamp: new Date(),
                dialog: 'WorkOrder',
                params: {
                  Co,
                  WorkOrder,
                },
              }),
            )
          })
        }
      }
    })
  }
}

export function acceptCall(call, user) {
  return (dispatch, getState) => {
    dispatch({
      type: NEW_CUSTOMER_CALL,
      call,
    })

    const request = axios.get(
      `${window['apiLocation']}/api/Queue?Co=${encodeURIComponent(
        call.Co,
      )}&QueueID=${encodeURIComponent(call.Queue)}&ID=${encodeURIComponent(
        call.Sid,
      )}&UserName=${encodeURIComponent(user)}`,
    )

    return request
      .then(response =>
        Promise.all([
          dispatch({
            type: ACCEPT_CALL,
          }),
        ]),
      )
      .catch(error => {
        dispatch({
          type: ACCEPT_CALL,
        })
        dispatch(
          showMessage({
            message: `Error: ${error.message}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export function divertCall(call, to) {
  return (dispatch, getState) => {
    const request = axios.get(
      `${window['apiLocation']}/api/Queue?Co=${encodeURIComponent(
        call.Co,
      )}&CallID=${encodeURIComponent(call.Sid)}&To=${encodeURIComponent(to)}`,
    )

    return request
      .then(response =>
        Promise.all([
          dispatch({
            type: DIVERT_CALL,
          }),
        ]),
      )
      .catch(error => {})
  }
}

export function updateRecordingStatus(call, status) {
  return (dispatch, getState) => {
    const request = axios.get(
      `${
        window['apiLocation']
      }/api/CallRecordings/UpdateRecording?Co=${encodeURIComponent(
        call.Co,
      )}&CallSid=${encodeURIComponent(call.Sid)}&Status=${encodeURIComponent(
        status,
      )}`,
    )

    return request
      .then(response =>
        Promise.all([
          dispatch({
            type: UPDATE_RECORDING_STATUS,
          }),
        ]),
      )
      .catch(error => {})
  }
}

export function endCall(call, user) {
  return (dispatch, getState) => {
    const request = axios.get(
      `${window['apiLocation']}/api/Queue?Co=${encodeURIComponent(
        call.Co,
      )}&CallID=${encodeURIComponent(call.Sid)}&UserName=${encodeURIComponent(
        user,
      )}`,
    )

    return request
      .then(response =>
        Promise.all([
          dispatch({
            type: END_CALL,
          }),
        ]),
      )
      .catch(error => {})
  }
}

export function startAgentSession(Co, agent) {
  return (dispatch, getState) => {
    const request = axios.get(
      `${window['apiLocation']}/api/Queue?Co=${encodeURIComponent(
        Co,
      )}&UserName=${encodeURIComponent(agent.UserName)}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: START_SESSION,
        }),
      ]),
    )
  }
}

export function endAgentSession(agent) {
  return (dispatch, getState) => {
    const request = axios.get(
      `${window['apiLocation']}/api/Queue?UserName=${encodeURIComponent(
        agent.UserName,
      )}&SessionID=${encodeURIComponent(agent.SessionID)}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: END_SESSION,
        }),
      ]),
    )
  }
}

export function setAgentStatus(Co, agent) {
  return (dispatch, getState) => {
    const request = axios.put(
      `${window['apiLocation']}/api/AgentStatus?UserName=${encodeURIComponent(
        agent.UserName,
      )}`,
      {
        ...agent,
      },
    )

    return request.then(response => {
      Promise.all([
        dispatch(
          showMessage({
            message: `Agent Status has been updated.`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        ),
      ])
    })
  }
}

export function setAgentStation(Co, station) {
  return (dispatch, getState) => {
    const request = axios.put(
      `${window['apiLocation']}/api/UserStation?UserName=${encodeURIComponent(
        station.UserName,
      )}&Phone=${encodeURIComponent(station.Phone)}`,
      {
        ...station,
      },
    )

    return request.then(response =>
      Promise.all([
        dispatch(
          showMessage({
            message: `Agent Station has been updated.`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        ),
      ]),
    )
  }
}
