import {combineReducers} from 'redux';
import workOrderScopes from './work-order-scopes.reducer';
import user from './user.reducer';

const workOrderScopesAppReducers = combineReducers({
    workOrderScopes,
    user
});

export default workOrderScopesAppReducers;
