import {combineReducers} from 'redux';
import inventoryLocations from './inventory-locations.reducer';
import user from './user.reducer';

const inventoryLocationsAppReducers = combineReducers({
    inventoryLocations,
    user
});

export default inventoryLocationsAppReducers;
