import React, { Component } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grow, Slide, Button, Dialog, DialogActions, DialogContent, Icon, Typography, Tooltip, IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import { connect } from 'react-redux';
import FormSection from './FormSection';
import _ from '@lodash';
import Media from 'react-media';
import axios from 'axios';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, paddingTop: props.paddingTop ? props.paddingTop : props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

function PageTemplate(props) {
    const { form } = props;
    const data = form.Data.Sections;
    const headers = _.filter(data, { HeaderYN: 'Y' });
    const footers = _.filter(data, { FooterYN: 'Y' });
    return (
        <React.Fragment>
            {headers && headers.map((section, index) => {
                return (
                    <div key={index} className={`w-full min-h-24 form-section relative`}>
                        <FormSection scrollLock={true} section={section} editable={false} data={[...section.Data.Elements]} />
                    </div>
                );
            })}
            {footers && footers.map((section, index) => {
                return (
                    <div key={index} className={`w-full min-h-24 form-section relative`}>
                        <FormSection scrollLock={true} section={section} editable={false} data={[...section.Data.Elements]} />
                    </div>
                );
            })}
        </React.Fragment>
    )

}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    paper: {
        minHeight: 'calc(100% - 66px)',
        paddingTop: 16,
        paddingBottom: 64,
    },
});
const newFormState = {
    scrollLock: true,
};

const FormMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

class Form extends Component {
    state = { ...newFormState };

    componentDidMount() {
        const { form } = this.props;
        this.setState({ ...newFormState, ..._.omit(form, 'assignment') });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { form } = this.props;
        if (!_.isEqual(form, prevProps.form)) {
            this.setState({ ...this.state, ..._.omit(form, 'assignment') });
        }

    }

    canBeSubmitted() {
        return true;
    }

    handleChange = (el, value, notes) => {
        const { readOnly } = this.props;
        if (!readOnly) {
            const { Data } = this.state;
            const { Sections } = Data;
            const section = _.find(Sections, { Section: el.Section });
            const element = _.find(section.Data.Elements, { Element: el.Element });
            if (element) {
                element.Value = value;
                element.Notes = notes ? notes : null;
            }
            this.setState({ ...this.state, Data: { ...Data } });
        }
    };

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    exportToPDF = () => {
        const { form } = this.props;
        const data = form.Data.Sections;
        const headers = _.filter(data, { HeaderYN: 'Y' });
        const sections = _.filter(data, (o) => { return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y' });
        const footers = _.filter(data, { FooterYN: 'Y' });
        let gridElement = document.getElementById('form-print');
        drawDOM(gridElement, {
            paperSize: "Letter",
            keepTogether: ".form-section",
            margin: { top: 20, left: 40, right: 40, bottom: 20 },
            scale: .65,
            // pageTemplate: () => <PageTemplate form={{ ...form }} />
        }).then((group) => {
            return exportPDF(group);
        }).then((dataUri) => {
            var data = dataUri.split(';base64,')[1];
            const blob = this.b64toBlob(data, 'application/pdf');
            // console.warn(dataUri, data, blob);
            saveAs(dataUri, form.Name);
        });
    }

    handleSave = () => {
        const { assignment, form } = this.props;
        if (!form.EnteredBy) {
            var rec = _.omit(this.state, 'scrollLock');
            if (assignment) {
                var data = {
                    ...assignment,
                    Form: form.Form,
                    Data: {
                        Result: {
                            ...rec
                        }
                    }
                }
                axios.post(`${window["apiLocation"]}/api/FormAssignmentResult`, data).then((res) => {
                    if (res.data.Data && res.data.Data.ErrMsg && res.data.Data.ErrMsg.length > 0) {
                        console.warn(res.data.Data.ErrMsg);
                        this.props.showMessage({
                            message: `Error: ${res.data.Data.ErrMsg}`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        });
                    } else {
                        this.props.onAdd(res.data);
                        this.props.showMessage({
                            message: `Form Input has been successfully saved.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        });
                        // this.exportToPDF();
                    }
                });
            }
        } else {
            this.handleUpdate();
        }

    }

    handleUpdate = () => {
        var data = _.omit(this.state, 'scrollLock');
        const { Co, Form, ID } = data;
        axios.put(`${window["apiLocation"]}/api/FormResult?Co=${Co}&Form=${Form}&ID=${ID}`, data).then((res) => {
            if (res.data.Data && res.data.Data.ErrMsg && res.data.Data.ErrMsg.length > 0) {
                console.warn(res.data.Data.ErrMsg);
                this.props.showMessage({
                    message: `Error: ${res.data.Data.ErrMsg}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.props.onUpdate(res.data);
                this.props.showMessage({
                    message: `Form Input has been successfully saved.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                });
                // this.exportToPDF();
            }
        });
    }

    handleDelete = () => {
        const { Co, Form, ID, Data } = this.state;
        Data.Sections.map((section) => {
            section.Data.Elements.map((element) => {
                element.Value = null;
                element.Notes = null;
            })
        })
        axios.delete(`${window["apiLocation"]}/api/FormAssignmentResult?Co=${Co}&Form=${Form}&ID=${ID}`).then((res) => {
            if (res.data && res.data.length > 0) {
                console.warn(res.data);
                this.props.showMessage({
                    message: `Error: ${res.data}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.setState({ ...this.state, Data: { ...Data } }, () => this.props.onDelete(this.state));
                this.props.showMessage({
                    message: `Form Input has been successfully removed.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                });
                this.props.onClose();
            }
        });
        // this.setState({ ...newFormState, ..._.omit(form, 'assignment') });
    }

    render() {
        const { classes, form, open, onClose, info, user, readOnly } = this.props
        const data = form.Data.Sections;
        const headers = _.filter(data, { HeaderYN: 'Y' });
        const sections = _.filter(data, (o) => { return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y' });
        const footers = _.filter(data, { FooterYN: 'Y' });

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classNames(classes.root, ""),
                            paper: matches ? classes.paper : "pt-36"
                        }}
                        className={classNames(classes.root)}
                        open={open}
                        onClose={onClose}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                    >
                        <Icon onClick={onClose} className="pin-r pin-t mr-12 mt-12 absolute cursor-pointer z-10">close</Icon>
                        {matches &&
                            <Icon onClick={this.exportToPDF} className="pin-l pin-t ml-12 mt-12 absolute cursor-pointer z-10">save_alt</Icon>
                        }
                        <DialogContent className="p-48 pt-0">
                            <div id="form-print">
                                {headers && headers.map((section, index) => {
                                    return (
                                        <div key={index} className={`w-full min-h-24 form-section relative`}>
                                            <FormSection scrollLock={true} section={section} editable={false} onChange={this.handleChange} data={[...section.Data.Elements]} />
                                        </div>
                                    );
                                })}
                                {info &&
                                    <div className="w-full min-h-24 form-section relative">
                                        {info}
                                    </div>
                                }
                                {sections && sections.map((section, index) => {
                                    return (
                                        <div key={index} className={`w-full min-h-24 form-section relative`}>
                                            <FormSection scrollLock={true} section={section} editable={false} onChange={this.handleChange} data={[...section.Data.Elements]} />
                                        </div>
                                    );
                                })
                                }
                                {footers && footers.map((section, index) => {
                                    return (
                                        <div key={index} className={`w-full min-h-24 form-section relative`}>
                                            <FormSection scrollLock={true} section={section} editable={false} onChange={this.handleChange} data={[...section.Data.Elements]} />
                                        </div>
                                    );
                                })}
                            </div>
                        </DialogContent>

                        {!readOnly &&
                            <DialogActions className="dialog-actions justify-between m-0 p-8 pl-16 pin-b bg-white w-full absolute">
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSave}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                    <IconButton
                                        onClick={this.handleDelete}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        <Icon>delete_sweep</Icon>
                                    </IconButton>
                                </React.Fragment>
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
Form.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage,
    }, dispatch);
}

function mapStateToProps({ auth, formsApp, spReducers, dispatchBoardApp }) {
    return {
        user: spReducers.userProfiles.User,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Form));
