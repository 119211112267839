import React, { Component } from 'react';
import {
    TextField, Badge, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch, Tabs, Tab
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import CostTypeOverridesList from './CostTypeOverridesList';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import _ from '@lodash';
import { SignalCellularNull } from '@material-ui/icons';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 0px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    updateOverride: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteOverride: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newCostTypeState = {
    Co: null,
    CostType: null,
    Name: null,
    CostTypeCategory: null,
    OverrideCostGLAccount: null,
    OverrideRevenueGLAccount: null,
    TaxableYN: 'Y',
    Notes: '',
    Data: {
        Overrides: [],
    },
    override: {
        ID: null,
        BusinessUnit: null,
        Division: null,
        OverrideCostGLAccount: null,
        OverrideRevenueGLAccount: null
    },
    value: 0
};

class CostTypeDialog extends Component {
    state = { ...newCostTypeState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newCostTypeState.Co = this.props.Co;
        if (!prevProps.costTypeDialog.props.open && this.props.costTypeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.costTypeDialog.type === 'edit' &&
                this.props.costTypeDialog.data &&
                !_.isEqual(this.props.costTypeDialog.data, prevState)) {
                this.setState({ ...this.props.costTypeDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.costTypeDialog.type === 'new' &&
                !_.isEqual(newCostTypeState, prevState)) {
                this.setState({ ...newCostTypeState });
            }
        }
        if (prevProps.costTypeDialog.props.open && this.props.costTypeDialog.props.open) {
            if (this.props.costTypeDialog.type === 'update' &&
                this.props.costTypeDialog.data &&
                !_.isEqual(this.props.costTypeDialog.data, prevState)) {
                this.props.costTypeDialog.type = 'edit';
                this.setState({ ...this.props.costTypeDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.costTypeDialog.type === 'edit' ? this.props.closeEditCostTypeDialog() : this.props.closeNewCostTypeDialog();
    };

    canBeSubmitted() {
        const { Name, CostType, CostTypeCategory } = this.state;
        return (
            Name && CostTypeCategory &&
            Name.length > 0 && CostTypeCategory.length > 0
        );
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    setOverride = (override) => {
        this.setState({ ...this.state, override });
    }

    render() {
        const { classes, securables, costTypeDialog, addCostType, updateCostType, removeCostType, businessUnits, divisions, costTypeCategories, glAccounts } = this.props;
        const { value } = this.state;
        const accessLevel = _.find(securables, { Securable: "cost-types" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...costTypeDialog.props}
                        open={Boolean(accessLevel) && costTypeDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={value === 0 ? "xs" : "md"}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {costTypeDialog.type === 'new' ? 'New Cost Type' : `Cost Type #${this.state.CostType}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                            >
                                <Tab disableRipple icon={<Icon>list</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Overrides ? this.state.Data.Overrides.length : 0} color="error"><Icon>format_list_numbered</Icon></Badge>} label="Overrides" />
                            </Tabs>
                        </AppBar>

                        <DialogContent classes={{ root: "p-0" }}>
                            {value === 0 && <TabContainer>
                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="CostType"

                                        id="costType"
                                        name="CostType"
                                        type="string"
                                        value={this.state.CostType || "TBD"}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                        disabled={true}
                                    />
                                </div>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Name"
                                        id="name"
                                        name="Name"
                                        value={this.state.Name}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Category"
                                        select
                                        id="costTypeCategory"
                                        name="CostTypeCategory"
                                        value={this.state.CostTypeCategory}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{ shrink: this.state.CostTypeCategory ? true : false }}
                                    >
                                        {(costTypeCategories && costTypeCategories.length > 0 ? costTypeCategories.map((value, index) => (
                                            <MenuItem key={index} value={value.CostTypeCategory}>
                                                {value.Name}
                                            </MenuItem>
                                        )) : '')}
                                    </TextField>
                                </div>

                                {/* <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Cost GL Override"
                                        options={glAccounts ? [{ GLAccount: null, Description: 'Default', }, ...glAccounts] : [{ GLAccount: null, Description: 'Default', }]}
                                        menuItemComponent={(value) => {
                                            return <MenuItem value={value.GLAccount}>
                                                {value.GLAccount} - {value.Description}
                                            </MenuItem>
                                        }}
                                        portal={matches}
                                        noflip={matches}
                                        value={this.state.OverrideCostGLAccount}
                                        onSelect={(option) => this.setState({ ...this.state, OverrideCostGLAccount: option.GLAccount })}
                                        required
                                    />
                                </div>

                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Revenue GL Override"
                                        options={glAccounts ? [{ GLAccount: null, Description: 'Default', }, ...glAccounts] : [{ GLAccount: null, Description: 'Default', }]}
                                        menuItemComponent={(value) => {
                                            return <MenuItem value={value.GLAccount}>
                                                {value.GLAccount} - {value.Description}
                                            </MenuItem>
                                        }}
                                        portal={matches}
                                        noflip={matches}
                                        value={this.state.OverrideRevenueGLAccount}
                                        onSelect={(option) => this.setState({ ...this.state, OverrideRevenueGLAccount: option.GLAccount })}
                                        required
                                    />
                                </div> */}

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Taxable?"
                                        id="taxableYN"
                                        name="TaxableYN"
                                        value={this.state.TaxableYN}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: this.state.TaxableYN ? true : false }}
                                        variant="outlined"
                                        select
                                        fullWidth
                                    >
                                        <MenuItem value="Y">
                                            Yes
                                </MenuItem>
                                        <MenuItem value="N">
                                            No
                                </MenuItem>
                                    </TextField>
                                </div>

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <div className="w-1/2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                </div>
                            </TabContainer>
                            }

                            {value === 1 &&
                                <TabContainer>
                                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                                        <React.Fragment>
                                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">add</Icon>{`${!this.state.override.ID ? "Add" : "Update"} Override`}</Typography>
                                            <div className="sm:flex">
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Business Unit"
                                                    options={businessUnits}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.BusinessUnit}>
                                                            {value.Description}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.override.BusinessUnit || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, override: { ...this.state.override, BusinessUnit: option.BusinessUnit } })}
                                                    required
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Division"
                                                    options={divisions ? _.filter(divisions, { BusinessUnit: this.state.override.BusinessUnit }) : []}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.Division}>
                                                            {value.Description}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.override.Division || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, override: { ...this.state.override, Division: option.Division } })}
                                                    required
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Cost GL Override"
                                                    options={glAccounts ? [{ GLAccount: null, Description: 'Default', }, ...glAccounts] : [{ GLAccount: null, Description: 'Default', }]}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.GLAccount}>
                                                            {value.GLAccount} - {value.Description}
                                                        </MenuItem>
                                                    }}
                                                    portal={matches}
                                                    noflip={matches}
                                                    value={this.state.override.OverrideCostGLAccount || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, override: { ...this.state.override, OverrideCostGLAccount: option.GLAccount } })}
                                                    required
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Revenue GL Override"
                                                    options={glAccounts ? [{ GLAccount: null, Description: 'Default', }, ...glAccounts] : [{ GLAccount: null, Description: 'Default', }]}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.GLAccount}>
                                                            {value.GLAccount} - {value.Description}
                                                        </MenuItem>
                                                    }}
                                                    portal={matches}
                                                    noflip={matches}
                                                    value={this.state.override.OverrideRevenueGLAccount || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, override: { ...this.state.override, OverrideRevenueGLAccount: option.GLAccount } })}
                                                    required
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {!this.state.override.ID ?
                                                    <Button
                                                        className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            const { Co, CostType } = this.state;
                                                            this.props.addCostTypeGLOverride({ ...this.state.override, Co, CostType, Data: { ErrMsg: null } });
                                                            this.setState({ ...this.state, override: newCostTypeState.override })
                                                        }}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        Add
                        </Button> :
                                                    <div className="w-full flex">
                                                        <Button
                                                            className={classNames(classes.formControl, classes.updateOverride, "w-full")}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                this.props.updateCostTypeGLOverride({ ...this.state.override, Data: { ErrMsg: null } });
                                                                this.setState({ ...this.state, override: newCostTypeState.override })
                                                            }}
                                                        // disabled={!this.canMemberBeSubmitted()}
                                                        >
                                                            Update
                            </Button>
                                                        <Button
                                                            className={classNames(classes.formControl, classes.deleteOverride)}
                                                            variant="contained"
                                                            onClick={() => {
                                                                this.props.removeCostTypeGLOverride({ ...this.state.override, Data: { ErrMsg: null } });
                                                                this.setState({ ...this.state, override: newCostTypeState.override })
                                                            }}
                                                        // disabled={!this.canMemberBeSubmitted()}
                                                        >
                                                            <Icon>delete</Icon>
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                    <CostTypeOverridesList onSelectedOverride={this.setOverride} costTypeOverrideData={[...this.state.Data.Overrides]} />
                                </TabContainer>
                            }
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {costTypeDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addCostType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateCostType(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditCostTypeDialog: Actions.closeEditCostTypeDialog,
        closeNewCostTypeDialog: Actions.closeNewCostTypeDialog,
        addCostType: Actions.addCostType,
        updateCostType: Actions.updateCostType,
        removeCostType: Actions.removeCostType,
        addCostTypeGLOverride: Actions.addCostTypeGLOverride,
        updateCostTypeGLOverride: Actions.updateCostTypeGLOverride,
        removeCostTypeGLOverride: Actions.removeCostTypeGLOverride
    }, dispatch);
}

function mapStateToProps({ costTypesApp, spReducers }) {
    return {
        costTypeDialog: costTypesApp.costTypes.costTypeDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        Co: spReducers.companies.Co,
        costTypeCategories: spReducers.costTypeCategories,
        glAccounts: spReducers.glAccounts,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CostTypeDialog));
