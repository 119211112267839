import { FuseChipSelect } from '@fuse/index';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grow, Icon, IconButton, InputAdornment, ListItem, ListItemText, Slide, TextField, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import axios from 'axios';
import classNames from 'classnames';
import * as Actions from 'main/content/apps/scrumboard/store/actions/index';
import TagBuilderDialog from 'main/content/apps/todo/TagBuilderDialog';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import { impress } from 'main/content/compression/impress';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AttachmentDialog from 'main/content/apps/file-manager/AttachmentDialog';
import CardAttachment from './attachment/CardAttachment';
import CardChecklist from './checklist/CardChecklist';
import Comments from './comments/Comments';
import Subtasks from './subtasks/Subtasks';
import CheckListMenu from './toolbar/CheckListMenu';
import DueMenu from './toolbar/DueMenu';
import LabelsMenu from './toolbar/LabelsMenu';
import MembersMenu from './toolbar/MembersMenu';
import PriorityMenu from './toolbar/PriorityMenu';
import getProfileImage from '../../../../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {},
    paper: {
        color: theme.palette.text.primary
    },
    formControl: {
        marginBottom: 24
    },
    memberBadge: {
        top: 16,
        right: 16,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: '50%'
    },
});

const initialState = {
    Co: null,
    BoardID: null,
    ListID: null,
    ParentID: null,
    ID: null,
    Name: '',
    Description: '',
    DueBy: null,
    Data: {
        Attachments: [],
        Comments: [],
        Members: [],
        Tags: [],
        Checklists: [],
        Cards: []
    },
    BreadcrumbItems: [],
    Notes: '',
    AddedBy: null,
    AddedDate: null,
    AttachmentID: null,
    CoverImage: null,
    DisplayIndex: null,
    CompletedYN: null,
    StarredYN: null,
    ImportantYN: null,
    BoardName: null,
    ListName: null,
    ParentTaskName: null,
    Priority: 3,
    dueMenu: null,
    confirm: false,
    isDatepickerOpen: false,
    newTag: false,
    editTag: null
}

class BoardCardDialog extends Component {

    state = {
        ...initialState
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.card &&
            !_.isEqual(prevProps.card, this.props.card)
        ) {
            this.setState({ ...initialState, ...this.props.card });
        }
    }

    saveAndCloseCard = () => {
        const card = this.state;
        this.props.updateCard({ ...card });
        this.props.closeCardDialog();
    }

    closeCard = () => {
        const { Co, BoardID } = this.state;
        // this.props.getBoard(Co, BoardID);
        this.props.closeCardDialog();
    }

    handleMemberAction = (members) => {
        const { users, User } = this.props;
        const card = this.state;
        const { Co, BoardID, ListID, ID, } = card;
        const AddedBy = User.UserName;
        for (var i = 0; i < members.length; i++) {
            const UserName = members[i].value;
            const isMember = _.find(card.Data.Members, { UserName });
            if (!isMember) {
                const member = {
                    Co,
                    BoardID,
                    ListID,
                    CardID: ID,
                    UserName,
                    AddedBy,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                }
                window["warn"](member);
                this.props.toggleMember(member, "ADD");
            }
        };

        for (var i = 0; i < card.Data.Members.length; i++) {
            const { UserName } = card.Data.Members[i];
            const isMember = _.find(members, { value: UserName });
            if (!isMember) {
                const member = {
                    Co,
                    BoardID,
                    ListID,
                    CardID: ID,
                    UserName,
                    AddedBy,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                }
                window["warn"](member);
                this.props.toggleMember(member, "DELETE");
            }

        }


    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    updatePriority = (Priority) => {
        this.setState({ Priority });
    }

    handleTagAction = (tags) => {
        const { User } = this.props;
        const card = this.state;
        const { Co, BoardID, ListID, ID, } = card;
        const AddedBy = User.UserName;
        for (var i = 0; i < tags.length; i++) {
            const TagID = tags[i].value;
            if (!TagID) {
                this.setState({ newTag: true });
            } else {
                const isTag = _.find(card.Data.Tags, { TagID });
                if (!isTag) {
                    const tag = {
                        Co,
                        BoardID,
                        ListID,
                        CardID: ID,
                        TagID,
                        AddedBy,
                        AddedDate: new Date(),
                        Data: {
                            ErrMsg: null,
                        }
                    }
                    window["warn"](tag);
                    this.props.toggleLabel(tag);
                }
            }
        };

        for (var i = 0; i < card.Data.Tags.length; i++) {
            const { TagID } = card.Data.Tags[i];
            if (TagID) {
                const isTag = _.find(tags, { value: TagID });
                if (!isTag) {
                    const tag = {
                        Co,
                        BoardID,
                        ListID,
                        CardID: ID,
                        TagID,
                        AddedBy,
                        AddedDate: new Date(),
                        Data: {
                            ErrMsg: null,
                        }
                    }
                    window["warn"](tag);
                    this.props.toggleLabel(tag);
                }
            }

        }
    }

    handleUpdateChecklist = (list) => {
        window["warn"](list);
        this.props.updateChecklist(list);
    }

    handleRemoveChecklist = (list) => {
        window["warn"](list);
        this.props.removeChecklist(list);
    }

    handleAddChecklistItem = (Name, ChecklistID) => {
        const { User } = this.props;
        const {
            Co,
            BoardID,
            ListID,
            ID
        } = this.state;
        const AddedBy = User.UserName;
        const item = {
            Co,
            BoardID,
            ListID,
            CardID: ID,
            ChecklistID,
            Name,
            AddedBy,
            AddedDate: new Date(),
            Data: {
                ErrMsg: null,
            }
        }
        window["warn"](item);
        this.props.addChecklistItem(item);
    }

    handleUpdateChecklistItem = (item) => {
        window["warn"](item);
        this.props.updateChecklistItem(item);
    }

    handleRemoveChecklistItem = (item) => {
        window["warn"](item);
        this.props.removeChecklistItem(item);
    }

    // fileSelectedHandler = (event) => {
    //     const { Data } = this.state;
    //     impress(event.target.files[0], 1024, 50, false, (img) => {
    //         window["log"](img);
    //         const fd = new FormData();
    //         fd.append('image', img.file, img.fileName);
    //         axios.post(`${window["apiLocation"]}/api/Attachment/PostScrumBoardCardAttachment?Co=${encodeURIComponent(this.state.Co)}&Card=${encodeURIComponent(this.state.ID)}&User=${encodeURIComponent(this.props.username)}`, fd).then((res) => {
    //             if (res.data.Attachment) {
    //                 this.props.addCardAttachment(res.data.Attachment);
    //             }
    //         });
    //     });
    // }

    toggleCompleted = () => {
        this.setState({
            ...this.state,
            CompletedYN: this.state.CompletedYN !== "Y" ? "Y" : null
        })
    };

    renderDateLabel = (e) => {
        window["log"](e);
        return (
            <TextField
                label="Due By"
                id="dueBy"
                name="DueBy"
                onClick={(e) => { this.setDatepickerOpen(true); }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon color="action">today</Icon>
                        </InputAdornment>
                    )
                }}
                className="w-full sm:w-auto"
                value={e.value}
                variant="outlined"
            />
        )
    };

    confirmRemoveCard = () => {
        this.setState({ ...this.state, confirm: true });
    }

    setDatepickerOpen = (bool) => {
        this.setState({ isDatepickerOpen: bool });
    }

    fileSelectedHandler = (event) => {
        let { Data, Co, ID } = this.state;
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            const nameArr = file.name.split('.');
            const fname = `Task${Co}-${ID}Img${Data.Attachments.length + 1}`;
            this.setState({ newPhoto: { data: e.target.result, fileName: fname, type: "image/jpeg" } });
            if (this.fileInput) {
                this.fileInput.value = "";
            }
        }
        reader.readAsDataURL(file);
    }

    attachmentSelectedHandler = (event) => {
        let { ID, Co, Data } = this.state;
        const file = event.target.files[0];
        if (file.type.split('/')[0] === "image") {
            this.fileSelectedHandler(event);
            // impress(file, 1024, 50, false, (img) => {
            //     window["log"](img);
            //     const nameArr = img.fileName.split('.');
            //     const { Attachments } = this.state.Data;
            //     const newFileName = `Co-${Co}-Unit-${ID}-Img-${Attachments.length + 1}.${nameArr[nameArr.length - 1]}`;
            //     const fd = new FormData();
            //     fd.append('image', img.file, newFileName);
            //     axios.post(`${window["apiLocation"]}/api/Attachment/AddAttachment?Co=${Co}&ID=${ID}&type=WO`, fd).then((res) => {
            //         window["warn"](res);
            //         if (res.data.Attachment) {
            //             const { Attachments } = this.state.Data;
            //             Attachments.push(res.data.Attachment);
            //             this.setState({ ...this.state, Data: { ...Data, Attachments: [...Attachments] } });
            //         }
            //     });
            // });
        } else {
            const fd = new FormData();
            fd.append('file', file, file.name);
            axios.post(`${window["apiLocation"]}/api/Attachment/AddAttachment?Co=${Co}&ID=${ID}&type=ProjectTask&ID=${ID}`, fd).then((res) => {
                window["warn"](res);
                if (res.data.Attachment) {
                    const { Attachments } = this.state.Data;
                    Attachments.push(res.data.Attachment);
                    this.setState({ ...this.state, Data: { ...Data, Attachments: [...Attachments] } });
                }
            });
        }
    }

    uploadImg = (file, notes) => {
        const { ID } = this.state;
        impress(file, 1024, 50, false, (img) => {
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            if (notes) {
                fd.append('notes', notes);
            }
            // saveAs(img.file, img.fileName);

            axios.post(`${window["apiLocation"]}/api/Attachment/AddAttachment?Co=${encodeURIComponent(this.state.Co)}&type=ProjectTask&ID=${ID}`, fd).then((res) => {
                window["warn"](res);
                if (res.data.Attachment) {
                    const { Attachments } = this.state.Data;
                    Attachments.push(res.data.Attachment);
                    this.setState({ ...this.state, Data: { ...this.state.Data, Attachments: [...Attachments] }, newPhoto: null });
                }
            });
        });
    }

    updateAttachment = (file, notes) => {
        const { Attachment } = this.state;
        if (Attachment) {
            const fd = new FormData();
            if (typeof file === "string") {
                fd.append('fileName', file);
            } else {
                fd.append('image', file, file.fileName);
            }

            if (notes) {
                fd.append('notes', notes);
            }
            // saveAs(img.file, img.fileName);

            return axios.post(`${window["apiLocation"]}/api/Attachment/UpdateAttachment?Co=${encodeURIComponent(this.props.Co)}&Attachment=${Attachment.Attachment}`, fd).then((res) => {
                if (res.data.Attachment) {
                    const { attachments } = this.props;
                    const index = _.findIndex(attachments, { Attachment: Attachment.Attachment });
                    if (index > -1) {
                        attachments.splice(index, 1, res.data.Attachment);
                    }
                    this.setState({ Attachment: res.data.Attachment });
                }
            });
        }
    }

    render() {
        const { classes, removeCard, board, addLabel, chipChange, removeAttachment, removeDue, toggleLabel, toggleMember, addChecklist, commentAdd, makeCover, removeCover, addNewChip, users, tags, username, securables } = this.props;
        const card = this.state;
        const { Co, newPhoto, Attachment } = card;
        const { handleChange } = this;
        const user = _.find(users, { UserName: this.state.AddedBy });
        const avatar = 'assets/images/avatars/profile.jpg';
        const list = card && board ? _.find(board.Data.Lists, { ID: card.ListID }) : null;
        const accessLevel = _.find(securables, { Securable: "project-tracking" });
        const tagAccessLevel = _.find(securables, { Securable: "to-do-labels" });

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: classNames(classes.paper, matches ? "m-24 max-w-lg" : "full-screen-dialog")
                        }}
                        onClose={this.closeCard}
                        open={Boolean(card.ID)}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                    >
                        <ConfirmationDialog
                            open={this.state.confirm}
                            title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this task?`}</div>}
                            confirmText="Confirm"
                            cancelText="Never Mind"
                            onCancel={() => this.setState({ ...this.state, confirm: false })}
                            onConfirm={() => this.setState({ ...this.state, confirm: false }, async () => { await removeCard(card); this.closeCard() })}
                        />
                        {card && (
                            <DialogTitle component="div" className="p-0">
                                <AppBar position="static" elevation={1}>
                                    <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16 dialog-header">
                                        <div className="flex flex-1">

                                            <DueMenu
                                                onDueChange={handleChange}
                                                onRemoveDue={removeDue}
                                                due={card.DueBy}
                                            />

                                            <PriorityMenu
                                                onChange={this.updatePriority}
                                                priority={card.Priority}
                                            />

                                            <LabelsMenu
                                                onToggleLabel={toggleLabel}
                                                labels={tags}
                                                card={card}
                                                idLabels={card.Data.Tags}
                                            />

                                            <MembersMenu
                                                onToggleMember={toggleMember}
                                                Co={Co}
                                                members={users}
                                                idMembers={card.Data.Members}
                                            />

                                            <IconButton color="inherit" onClick={() => this.fileInput.click()}>
                                                <Icon>attachment</Icon>
                                            </IconButton>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                onChange={(e) => Promise.all([
                                                    this.attachmentSelectedHandler(e)
                                                ]).then(() => {
                                                    this.fileInput.value = "";
                                                })}
                                                ref={fileInput => this.fileInput = fileInput}
                                                accept=".jpg, .jpeg, .mp4, .mov, .png, .bmp, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt"
                                            />
                                            {newPhoto &&
                                                <AttachmentDialog
                                                    file={newPhoto}
                                                    open={true}
                                                    edit={true}
                                                    onClose={() => this.setState({ newPhoto: null })}
                                                    onSave={this.uploadImg}
                                                />
                                            }
                                            {(Boolean(Attachment) && !this.state.confirmDelete) &&
                                                <AttachmentDialog attachments={this.state.Data.Attachments} onChange={(attch) => this.setState({ Attachment: attch })} onDelete={(attch) => this.setState({ Attachment: null }, () => removeAttachment({ Co: card.Co, ...attch }))} onSave={this.updateAttachment} notes={Attachment.Notes} onClose={() => this.setState({ Attachment: null })} attachment={Attachment} open={true} />
                                            }
                                            <CheckListMenu
                                                onAddChecklist={addChecklist}
                                            />

                                        </div>
                                        <IconButton color="inherit" onClick={this.closeCard}>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>
                        )}

                        {card && (
                            <DialogContent className="p-16 sm:p-24 mb-64">

                                {user &&
                                    <div className="w-full mb-24 flex justify-between">
                                        <div className={classNames(classes.commentBubble, "flex flex-col")}>
                                            <div className="flex items-center">
                                                <Avatar alt={user.FirstName} src={user && user.Data && user.Data.Avatar ? getProfileImage(`Co=${this.state.Co}&ID=${user.Data.Avatar}` ): avatar} className="w-32 h-32 mr-8" />
                                                <Typography className="font-bold">{`${user.FirstName} ${user.LastName}`}</Typography>
                                                <Typography className="ml-8 text-12" color="textSecondary">{new Date(this.state.AddedDate).toLocaleDateString('en-US')}</Typography>
                                            </div>
                                        </div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    checked={card.CompletedYN === "Y"}
                                                    onChange={this.toggleCompleted}
                                                    onClick={(ev) => ev.stopPropagation()}
                                                />
                                            }
                                            margin="dense"
                                            label="Completed"
                                            className="mr-0"
                                        />
                                    </div>
                                }
                                <div className="flex flex-col sm:flex-row sm:justify-between justify-center items-center mb-24">

                                    <div className="mb-16 sm:mb-0 flex items-center flex-wrap">
                                        {card.BreadcrumbItems && card.BreadcrumbItems.map((item, index) =>
                                            <>
                                                {index > 0 && <Icon className="text-20" color="inherit">chevron_right</Icon>}
                                                {item.clickable ?
                                                    <Typography onClick={() => this.props.openBreadcrumbSubtask(item.card, index)} className="cursor-pointer hover:underline">{item.text}</Typography>
                                                    :
                                                    <Typography>{item.text}</Typography>
                                                }
                                            </>
                                        )}
                                    </div>


                                    {card.DueBy && (
                                        <DatePicker
                                            inputVariant="outlined"
                                            onOpen={() => this.setDatepickerOpen(true)}
                                            onClose={() => this.setDatepickerOpen(false)}
                                            open={this.state.isDatepickerOpen}
                                            label="Due By"
                                            type="date"
                                            name="DueBy"
                                            value={card.DueBy}
                                            onChange={(ev) => {
                                                window["warn"](ev);
                                                if (ev) {
                                                    ev.target = {
                                                        type: 'input',
                                                        name: "DueBy",
                                                        value: ev._d ? ev._d : null,
                                                    }
                                                    handleChange(ev);
                                                } else {
                                                    removeDue();
                                                }
                                            }}
                                            placeholder="Choose a due date"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            autoOk={true}
                                            clearable={true}
                                            format="MM/DD/YYYY"
                                            // ref={el => { this.datePicker = el; }}
                                            variant="outlined"
                                            TextFieldComponent={this.renderDateLabel}
                                        />
                                    )}
                                </div>

                                <div className="flex items-center mb-24">
                                    <TextField
                                        label="Title"
                                        type="text"
                                        name="Name"
                                        value={card.Name}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {card.subscribed && (
                                                        <Icon className="text-20" color="action">remove_red_eye</Icon>
                                                    )}
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled={username !== this.state.AddedBy && (!accessLevel || accessLevel.AccessLevel !== 'F')}

                                    />
                                </div>

                                <div className="w-full mb-24">
                                    <TextField
                                        label="Description"
                                        name="Description"
                                        multiline
                                        rows="4"
                                        value={card.Description}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={username !== this.state.AddedBy && (!accessLevel || accessLevel.AccessLevel !== 'F')}
                                        fullWidth
                                    />
                                </div>

                                <Subtasks subTasks={card.Data.Cards} parent={card} />

                                <div className="flex flex-col sm:flex-row" style={{ gap: 20 }}>
                                    {card.Data.Tags && (
                                        <div className="flex-1 mb-24">
                                            <div className="flex items-center mt-16 mb-12">
                                                <Icon className="text-20 mr-8" color="inherit">label</Icon>
                                                <Typography className="font-600 text-16">Tags</Typography>
                                            </div>
                                            <FuseChipSelect
                                                className={(card.Data.Members && card.Data.Members.length > 0) && 'sm:mr-8'}
                                                value={
                                                    card.Data.Tags.map(tag => ({
                                                        value: tag.TagID,
                                                        label: _.find(tags, { ID: tag.TagID }).Name,
                                                        color: _.find(tags, { ID: tag.TagID }).Color,
                                                        background: _.find(tags, { ID: tag.TagID }).Background
                                                    }))
                                                }
                                                onChange={(value) => this.handleTagAction(value)}
                                                placeholder="Select Tags"
                                                isMulti
                                                textFieldProps={{
                                                    variant: "outlined"
                                                }}
                                                variant="fixed"
                                                noOptionsComponent={
                                                    <div>
                                                        {(tagAccessLevel && tagAccessLevel.AccessLevel !== "R") ?
                                                            <ListItem
                                                                button
                                                                onClick={() => this.setState({ newTag: true })}
                                                            >
                                                                <Icon className="list-item-icon mr-6"
                                                                    color="secondary">add_circle_outline</Icon>
                                                                <ListItemText primary={"New Tag"} />
                                                            </ListItem> : "No Options"
                                                        }
                                                    </div>
                                                }
                                                options={[{ ID: null, Name: "New Tag" }, ...tags].map((label) => (
                                                    {
                                                        value: label.ID,
                                                        label: label.Name,
                                                        color: label.Color,
                                                        background: label.Background
                                                    }
                                                ))}
                                                onCreateOption={(Name) => {

                                                }}
                                            />
                                        </div>
                                    )}
                                    <TagBuilderDialog open={this.state.newTag || this.state.editTag} data={this.state.editTag || {}} onAdd={() => this.setState({ newTag: false, editTag: null })} onUpdate={() => this.setState({ newTag: false, editTag: null })} onClose={() => this.setState({ newTag: false, editTag: null })} />

                                    {card.Data.Members && (
                                        <div className="flex-1 mb-24">
                                            <div className="flex items-center mt-16 mb-12">
                                                <Icon className="text-20 mr-8" color="inherit">supervisor_account</Icon>
                                                <Typography className="font-600 text-16">Assignees</Typography>
                                            </div>
                                            <FuseChipSelect
                                                className={(card.Data.Tags && card.Data.Tags.length > 0) && 'sm:ml-8'}
                                                value={
                                                    card.Data.Members.map(memb => {
                                                        const member = _.find(users, { UserName: memb.UserName });
                                                        return {
                                                            value: member.UserName,
                                                            label: (
                                                                <Tooltip title={`${member.FirstName} ${member.LastName}`}>
                                                                    <Badge classes={{ badge: classNames(classes.memberBadge) }} badgeContent={`${member.FirstName.substr(0, 1)}${member.LastName.substr(0, 1)}`}>
                                                                        <Avatar className="-ml-12 w-32 h-32" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${memb.Co}&ID=${member.Data.Avatar}` ): avatar} />
                                                                    </Badge>
                                                                </Tooltip>)
                                                        }
                                                    })
                                                }
                                                onChange={(value) => this.handleMemberAction(value)}
                                                placeholder="Select Assignees"
                                                isMulti
                                                textFieldProps={{
                                                    variant: "outlined"
                                                }}
                                                options={users.map((member) => (
                                                    {
                                                        value: member.UserName,
                                                        label: (<span className="flex items-center"><Avatar className="w-32 h-32 mr-8" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${member.Data.Avatar}` ): avatar} />{`${member.FirstName} ${member.LastName}`}</span>)
                                                    }
                                                ))}
                                                variant="fixed"
                                            />
                                        </div>
                                    )}
                                </div>

                                {card.Data.Attachments && card.Data.Attachments.length > 0 && (
                                    <div className="mb-24">
                                        <div className="flex items-center mt-16 mb-12">
                                            <Icon className="text-20 mr-8" color="inherit">attachment</Icon>
                                            <Typography className="font-600 text-16">Attachments</Typography>
                                        </div>
                                        <div className="flex flex-col sm:flex-row flex-wrap">
                                            {card.Data.Attachments.map(item => (
                                                <CardAttachment
                                                    item={item}
                                                    card={card}
                                                    makeCover={makeCover}
                                                    removeCover={removeCover}
                                                    removeAttachment={removeAttachment}
                                                    key={item.ID}
                                                    onSelected={(Attachment) => this.setState({ Attachment })}
                                                    Co={Co}
                                                />
                                            )
                                            )}
                                        </div>
                                    </div>
                                )}

                                {card.Data.Checklists && card.Data.Checklists.map(checklist => (
                                    <CardChecklist
                                        key={checklist.ID}
                                        checklist={checklist}
                                        onUpdateChecklist={this.handleUpdateChecklist}
                                        onRemoveChecklist={this.handleRemoveChecklist}
                                        onAddChecklistItem={this.handleAddChecklistItem}
                                        onUpdateChecklistItem={this.handleUpdateChecklistItem}
                                        onRemoveChecklistItem={this.handleRemoveChecklistItem}
                                    />
                                ))}

                                <Comments card={card} users={users} commentAdd={commentAdd} />

                                <DialogActions className="dialog-actions justify-between pin-b pin-l pin-r absolute bg-white">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.saveAndCloseCard}
                                    > Save
                                    </Button>

                                    {(username === card.AddedBy || accessLevel && accessLevel.AccessLevel === 'F') &&
                                        <IconButton
                                            onClick={() => {
                                                this.confirmRemoveCard(card)
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    }
                                </DialogActions>
                            </DialogContent>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBoard: Actions.getBoard,
        openBreadcrumbSubtask: Actions.openBreadcrumbSubtask,
        closeCardDialog: Actions.closeCardDialog,
        subtaskAdd: Actions.subtaskAdd,
        updateCard: Actions.updateCard,
        removeCard: Actions.removeCard,
        handleChange: Actions.handleChange,
        addLabel: Actions.addLabel,
        chipChange: Actions.chipChange,
        makeCover: Actions.makeCover,
        removeCover: Actions.removeCover,
        removeAttachment: Actions.removeAttachment,
        removeDue: Actions.removeDue,
        toggleLabel: Actions.toggleLabel,
        toggleMember: Actions.toggleMember,
        addChecklist: Actions.addChecklist,
        updateChecklist: Actions.updateChecklist,
        removeChecklist: Actions.removeChecklist,
        addChecklistItem: Actions.addChecklistItem,
        updateChecklistItem: Actions.updateChecklistItem,
        removeChecklistItem: Actions.removeChecklistItem,
        addNewChip: Actions.addNewChip,
        commentAdd: Actions.commentAdd,
        addCardAttachment: Actions.addCardAttachment,
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        card: scrumboardApp.card,
        board: scrumboardApp.board,
        users: spReducers.userProfiles.Users,
        username: spReducers.userProfiles.User.UserName,
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User,
        tags: spReducers.calendar.tags,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(BoardCardDialog));
