import { FuseAnimate, FuseUtils } from '@fuse';
import { Icon, Typography, Tooltip, Button, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import format from 'string-template';
import { Parser } from 'json2csv';
import axios from 'axios';
import { showMessage } from 'store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}


function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Records Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 288px)',
        paddingBottom: 0,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    exportLoadingIcon: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        marginTop: 2,
        zIndex: 3,
    },
    csvButton: {
        float: 'right',
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        width: 32,
        height: 32,
    },
    labels: {},
    green: {
        backgroundColor: '#d1e751',
        color: '#3f3f3f'
    },
    blue: {
        backgroundColor: '#4dbce9',
        color: '#fff'
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    borderTop: {
        borderTop: '1px solid lightgrey'
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    ok: {
        background: '#333',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        },
        '&:focus': {
            backgroundColor: '#5f5f5f',
        },
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    greenText: {
        color: '#d1e751',
    },
    listContainer: {
        borderTop: '1px solid lightgrey',
        borderBottom: '1px solid lightgrey',
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: '#f6f6f6'
    },
    type_charge: {
        color: '#fff',
        backgroundColor: '#d1e751',
        borderRadius: 5,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11
    },
    type_refund: {
        color: '#fff',
        backgroundColor: 'orange',
        borderRadius: 5,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11
    },
    type_void: {
        color: '#fff',
        backgroundColor: '#6f6f6f',
        borderRadius: 5,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11
    }
});

class PaymentsList extends Component {

    state = {
        selectedPaymentsMenu: null,
        anchorEl: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText.replace(/\|/g, ' '));
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    searchPayments = _.debounce((Co, Page) => {
        const { customer } = this.props;
        this.props.getPayments(Co, Page, customer);
    }, 350);

    getExportData = () => {
        const { Co, filters, paymentData } = this.props;
        const { SortBy, SortOrder, StartDate, EndDate, Type } = filters;
        const currentPage = paymentData ? paymentData.current_page : 1;

        // Fetch all data
        const request = axios.get(`${window["apiLocation"]}/api/Omni/Transactions`,
            {
                params: {
                    Co,
                    Page: currentPage,
                    SortOrder,
                    SortBy,
                    Type,
                    StartDate,
                    EndDate,
                    TopLevel: 1,
                    Full: true
                }
            });

        request.then((response) => {
            window["warn"]("yyggttss", response)
            let data = response.data.data;

            this.exportData(data);
        }).catch((error) => {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        }).finally(() => {
                this.setState({ ...this.state, exportLoading: false });
            });
    }

    exportData = (data) => {
        try {
            //const { selectedPaymentData } = this.props;
            //const data = this.getFilteredArray((!this.props.selectedPaymentData ? (!this.props.paymentData || !this.props.paymentData.data ? [] : this.props.paymentData.data) : this.props.selectedPaymentData), searchText);
            let newData = []
            data.map((value) => {
                if (value.sp_data) {
                    let obj = {}
                    obj["Payment Date"] = moment.utc(value.created_at).local().format("h:mm A on M/D/YYYY");
                    obj["Customer Name"] = value.sp_customer.FirstName && value.sp_customer.LastName ? `${value.sp_customer.FirstName} ${value.sp_customer.LastName}` : value.sp_customer.Name;
                    //obj["Customer Name"] = `${value.sp_data.Data.payment_method.CardholderName} ${value.sp_customer.LastName}`
                    obj["Payment Method"] = value.payment_method.card_type;
                    //obj["Payment Method"] = `${value.sp_data.Data.payment_method.PaymentMethodType} ${value.sp_data.Data.payment_method.CardLastFour}`
                    obj["Taken By"] = value.sp_data.Data.Allocations[0].EnteredBy;
                    obj["Invoice Number"] = value.sp_data.Data.Allocations[0].InvoiceNumber;
                    obj["Last 4"] = value.payment_method.card_last_four
                    obj["Amount"] = this.formatDollars(value.total);
                    obj["Customer"] = value.sp_customer && value.sp_customer.VPCustomer ? value.sp_customer.VPCustomer : value.sp_data.Customer;

                    newData.push(obj)
                }
            });
            const csvData = new Parser();
            const Data = csvData.parse(newData);
            var encoded = encodeURIComponent(Data);
            var csv = `data:text/csv;charset=utf-8, ${encoded}`;
            var link = document.createElement("a");
            link.setAttribute("href", csv);
            //link.setAttribute("download", "Purchase Orders.csv");
            link.setAttribute("download", `Payments-${moment().format('M-D-YYYY')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        }
    }

    render() {
        const { classes, loading, searchText, Co, filters, paymentData, customer, securables } = this.props;
        const { anchorEl, exportLoading } = this.state;
        const data = this.getFilteredArray((!this.props.selectedPaymentData ? (!this.props.paymentData || !this.props.paymentData.data ? [] : this.props.paymentData.data) : this.props.selectedPaymentData), searchText);
        const currentPage = this.props.paymentData ? this.props.paymentData.current_page : 1;
        const lastPage = this.props.paymentData ? this.props.paymentData.last_page || -1 : -1;
        const co = _.find(this.props.companies, { Co: this.props.Co });
        const isVP = co ? co.Data.Co.VPCompany : false;

        const exportPayments = _.find(securables, { Securable: "export-payments" });

        window['warn']("payments filters ###", filters)

        return (
            <div className="w-full">
                <Media query="(min-width: 768px)">
                    {() =>
                        <FuseAnimate animation="transition.slideUpIn" delay={300}>
                            <div>
                                {exportPayments &&
                                    <Tooltip title={exportLoading ? "Downloading CSV" : "Export to CSV"} placement='top'>
                                        {exportLoading ? (
                                            <Icon color="primary" className={classNames(classes.exportLoadingIcon, 'spin')}>refresh</Icon>
                                        )
                                            :
                                            (<Fab
                                                color="primary"
                                                aria-label="add"
                                                className={classes.addButton}
                                                onClick={() => {
                                                    this.setState({ ...this.state, exportLoading: true }, this.getExportData())
                                                }}
                                            >
                                                <Icon className="text-16">save_alt</Icon>

                                            </Fab>)
                                        }
                                    </Tooltip>
                                }
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">payment</Icon>{customer ? 'Previous' : 'Payment'} Transactions
                                </Typography>

                                <ReactTableFixedColumns
                                    className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                                    getTrProps={(state, rowInfo) => {
                                        const trn = rowInfo ? rowInfo.original : null;
                                        let allocated = 0;
                                        if (trn && trn.sp_data) {
                                            allocated = _.sumBy(_.filter(trn.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount');
                                        };
                                        let ttl = 0;
                                        if (trn && !trn.is_voided) {
                                            ttl = trn.total - trn.total_refunded;
                                        }
                                        const available = ttl - allocated;
                                        return {
                                            className: `cursor-pointer ${(trn && trn.is_voided) || (customer && !(available > 0)) ? 'text-grey' : (trn && !trn.success) ? 'text-red' : ''}`,
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                if (rowInfo) {
                                                    if (this.props.onSelected) {
                                                        this.props.onSelected(rowInfo.original, available);
                                                    } else {
                                                        this.props.setTransaction(rowInfo.original);
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    manual
                                    data={customer ? (this.props.paymentData ? this.props.paymentData.data || [] : []) : data}
                                    pages={lastPage} // Display the total number of pages
                                    loading={loading} // Display the loading overlay when we need it
                                    onFetchData={(e) => { this.searchPayments(Co, e.page + 1); }}
                                    NoDataComponent={loading ? LoadingDiv : NoData}
                                    LoadingComponent={loading ? LoadingDiv : undefined}
                                    onSortedChange={(e) => { if (e[0] && e[0].id) { this.props.setPaymentFilters({ ...filters, SortBy: e[0].id, SortOrder: e[0].desc ? "DESC" : "ASC" }); } }}
                                    onPageSizeChange={(e) => { window["warn"]('Page Size Change: ', e); this.props.setPaymentFilters({ ...filters, PerPage: e }) }}
                                    multiSort={false}
                                    defaultSorted={filters.SortBy ? [{ id: filters.SortBy, desc: filters.SortOrder === 'DESC' }] : []}
                                    columns={[
                                        {
                                            Header: "Status",
                                            accessor: 'success',
                                            width: 64,
                                            Cell: row => row.original.is_voided ? <Icon className="text-20 text-grey">cancel</Icon> : row.original.success ? <Icon color="secondary" className="text-20">check_circle_outline</Icon> : <Icon color="error" className="text-20">error</Icon>,
                                            className: "justify-center",
                                            sortable: false,
                                        },
                                        {
                                            Header: "Date",
                                            accessor: 'created_at',
                                            width: 192,
                                            Cell: row => moment.utc(row.value).local().format("h:mm A on M/D/YYYY"),
                                            className: "justify-center font-bold text-12",
                                            show: Boolean(customer)
                                        },
                                        {
                                            Header: "Payment Info",
                                            accessor: 'payment_method.card_type',
                                            width: 250,
                                            Cell: row => row.value ? <div className="flex"><img src={`assets/images/cards/${row.value}.png`} className="mr-4" style={{ height: 20 }} />{row.original.payment_method.card_last_four ? <div className="ml-6 align-middle">{`Ends in ${row.original.payment_method.card_last_four} - ${row.original.payment_method.person_name}`}</div> : ''}</div> : <Icon className="text-20">help</Icon>,
                                            className: "justify-start",
                                            sortable: false,
                                            show: Boolean(customer)
                                        },
                                        {
                                            Header: "Amount",
                                            accessor: 'total',
                                            width: 160,
                                            Cell: row => <div className="flex"><div className={`${row.original.is_voided || row.original.total_refunded > 0 || !row.original.success ? 'line-through text-grey' : ''}`}>{this.formatDollars(row.value)}</div>{row.original.total_refunded > 0 && <div className={'ml-4'}>{this.formatDollars(row.value - row.original.total_refunded)}</div>}</div>,
                                            className: "justify-center font-bold",
                                            show: Boolean(customer)
                                        },
                                        {
                                            Header: "Allocations",
                                            id: "allocations",
                                            accessor: r => {
                                                if (r.sp_data) {
                                                    return _.sumBy(_.filter(r.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount');
                                                } else {
                                                    return 0.00;
                                                }
                                            },
                                            width: 160,
                                            Cell: row => {
                                                const transaction = row.original;
                                                const allocated = Math.round(((!transaction || !transaction.success || transaction.type !== 'charge') ? 0 : !transaction.sp_data ? 0 : _.sumBy(_.filter(transaction.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount') + Number.EPSILON) * 100) / 100;
                                                const total = Math.round(((!transaction || !transaction.success) ? 0 : transaction.is_voided ? 0 : (transaction.total - transaction.total_refunded) + Number.EPSILON) * 100) / 100;
                                                return <div className="flex">{transaction.type === "charge" && total !== allocated && <Tooltip placement="top" title={!transaction.sp_data ? "Unallocated Payment Balance" : total < allocated ? "Over-Allocated Payment Balance" : "Under-Allocated Payment Balance"}><Icon className="text-18 mr-4 text-orange">warning</Icon></Tooltip>}<div className="flex">{this.formatDollars(row.value)}</div></div>;
                                            },
                                            className: "justify-center font-bold",
                                            show: Boolean(customer)
                                        },
                                        {
                                            Header: "Customer Name",
                                            accessor: r => {
                                                let name = r.sp_customer ? `${r.sp_customer.FirstName} ${r.sp_customer.LastName}` : `${r.customer.firstname || ''} ${r.customer.lastname || ''}`;
                                                if (name.trim().length < 1) {
                                                    name = r.sp_customer ? r.sp_customer.Name : r.payment_method.person_name
                                                }
                                                return name;
                                            },
                                            id: 'customer_name',
                                            width: 256,
                                            className: "justify-center font-bold capitalize",
                                            sortable: false,
                                        },
                                        {
                                            Header: "Payment Info",
                                            accessor: 'payment_method.card_type',
                                            width: 250,
                                            Cell: row => row.value ? <div className="flex"><img src={`assets/images/cards/${row.value}.png`} className="mr-4" style={{ height: 20 }} />{row.original.payment_method.card_last_four ? <div className="ml-6 align-middle">{`Ends in ${row.original.payment_method.card_last_four} - ${row.original.payment_method.person_name}`}</div> : ''}</div> : <Icon className="text-20">help</Icon>,
                                            className: "justify-start",
                                            sortable: false,
                                            show: !Boolean(customer)
                                        },
                                        {
                                            Header: "Type",
                                            accessor: r => `${!r.success ? 'Failed ' : r.is_voided ? 'Voided ' : ''}${r.type}`,
                                            id: 'type',
                                            width: 128,
                                            className: "justify-center capitalize",
                                            Cell: row => <div className={classNames(classes[`type_${row.original.type}`], !row.original.success ? 'bg-red' : row.original.is_voided ? 'bg-grey' : '')}>{`${!row.original.success ? 'Failed ' : row.original.is_voided ? 'Voided ' : ''}${row.original.type}`}</div>,
                                            sortable: false,
                                        },
                                        {
                                            Header: "Date",
                                            accessor: 'created_at',
                                            width: 192,
                                            Cell: row => moment.utc(row.value).local().format("h:mm A on M/D/YYYY"),
                                            className: "justify-center font-bold text-12",
                                            show: !Boolean(customer)
                                        },
                                        {
                                            Header: "Amount",
                                            accessor: 'total',
                                            width: 160,
                                            Cell: row => <div className="flex"><div className={`${row.original.is_voided || row.original.total_refunded > 0 || !row.original.success ? 'line-through text-grey' : ''}`}>{this.formatDollars(row.value)}</div>{row.original.total_refunded > 0 && <div className={'ml-4'}>{this.formatDollars(row.value - row.original.total_refunded)}</div>}</div>,
                                            className: "justify-center font-bold",
                                            show: !Boolean(customer)
                                        },
                                        {
                                            Header: "Allocations",
                                            id: "allocations",
                                            accessor: r => {
                                                if (r.sp_data) {
                                                    return _.sumBy(_.filter(r.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount');
                                                } else {
                                                    return 0.00;
                                                }
                                            },
                                            width: 160,
                                            Cell: row => {
                                                const transaction = row.original;
                                                const allocated = Math.round(((!transaction || !transaction.success || transaction.type !== 'charge') ? 0 : !transaction.sp_data ? 0 : _.sumBy(_.filter(transaction.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount') + Number.EPSILON) * 100) / 100;
                                                const total = Math.round(((!transaction || !transaction.success) ? 0 : transaction.is_voided ? 0 : (transaction.total - transaction.total_refunded) + Number.EPSILON) * 100) / 100;
                                                return <div className="flex">{transaction.type === "charge" && total !== allocated && <Tooltip placement="top" title={!transaction.sp_data ? "Unallocated Payment Balance" : total < allocated ? "Over-Allocated Payment Balance" : "Under-Allocated Payment Balance"}><Icon className="text-18 mr-4 text-orange">warning</Icon></Tooltip>}<div className="flex">{this.formatDollars(row.value)}</div></div>;
                                            },
                                            className: "justify-center font-bold",
                                            show: !Boolean(customer)
                                        },
                                        {
                                            Header: "VP Customer",
                                            id: "sp-customer",
                                            accessor: r => r.sp_customer ? r.sp_customer.VPCustomer : null,
                                            width: 96,
                                            className: "justify-center font-bold",
                                            show: !Boolean(customer) && isVP
                                        },
                                        {
                                            Header: "ID",
                                            accessor: 'id',
                                            width: 224,
                                            Cell: row => <div className="w-full flex hoverable"><div className="w-full text-center text-12 truncate">{row.value}</div><Icon onClick={(e) => { e.stopPropagation(); navigator.clipboard.writeText(row.value); }} color="action" className="show-on-hover text-16 align-middle ml-4">file_copy</Icon></div>,
                                            className: "justify-center font-bold",
                                            sortable: false,
                                        },
                                    ]}
                                    //TODO: Change this back to original
                                    //defaultPageSize={customer ? 10 : (paymentData.per_page || filters.PerPage || 20)}
                                    defaultPageSize={customer ? 10 : paymentData ? paymentData.per_page : filters ? filters.PerPage : 20}
                                    //defaultPageSize={100}
                                    noDataText="No Payments found"
                                    pageSizeOptions={!customer ? [10, 20, 50, 100] : [10]}
                                />
                            </div>
                        </FuseAnimate>
                    }
                </Media>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPayments: Actions.getPayments,
        setPaymentFilters: Actions.setPaymentFilters,
        setTransaction: Actions.setTransaction,
        showMessage,
    }, dispatch);
}

function mapStateToProps({ paymentsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        paymentData: paymentsApp.payments.data,
        searchText: paymentsApp.payments.searchText,
        user: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
        filters: paymentsApp.payments.filters,
        loading: paymentsApp.payments.loading,
        transaction: paymentsApp.payments.transaction,
        workOrderData: spReducers.workOrders,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsList)));
