import React, { Component } from 'react';
import {
    TextField, Button, CircularProgress, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import Media from 'react-media';
import axios from 'axios';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newGLAccountState = {
    Co: null,
    GLAccount: null,
    Description: null,
    BalanceType: null,
    ActiveYN: 'Y',
    Notes: null,
    Data: {

    },
    VPGLCo: null,
    VPGLAccount: null,
    QBOCompany: null,
    QBOAccount: null,
    searchDescription: null,
    vpGLAccounts: [],
    isSearching: false
};

class GLAccountDialog extends Component {
    state = { ...newGLAccountState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { vpCompany } = this.props;
        /**
         * After Dialog Open
         */
        const initialState = _.cloneDeepWith(newGLAccountState);
        initialState.Co = this.props.Co;
        if (!prevProps.glAccountDialog.props.open && this.props.glAccountDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.glAccountDialog.type === 'edit' &&
                this.props.glAccountDialog.data &&
                !_.isEqual(this.props.glAccountDialog.data, prevState)) {
                this.setState({ ...initialState, ...this.props.glAccountDialog.data }, () => {
                    if (vpCompany && this.state.VPGLAccount) {
                        this.handleGLSearch();
                    }
                });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.glAccountDialog.type === 'new' &&
                !_.isEqual(initialState, prevState)) {
                this.setState({ ...initialState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.glAccountDialog.type === 'edit' ? this.props.closeEditGLAccountDialog() : this.props.closeNewGLAccountDialog();
    };

    canBeSubmitted() {
        const { vpCompany } = this.props;
        const { GLAccount, Description, BalanceType, VPGLAccount } = this.state;
        const cbs = (
            GLAccount &&
            GLAccount.length > 0 &&
            Description &&
            Description.length > 0 &&
            BalanceType &&
            BalanceType.length > 0 &&
            (vpCompany ? VPGLAccount : true) &&
            (vpCompany ? VPGLAccount.length > 0 : true)
        );
        return cbs;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    trySubmit = () => {
        this.setState({ ...this.state, trySubmit: true });
    }

    endTrySubmit = () => {
        this.setState({ ...this.state, trySubmit: false });
    }

    handleGLSearch = _.debounce(() => {
        const { vpCompany } = this.props;
        const { HQCo1 } = (vpCompany || {});
        const { searchDescription, VPGLAccount } = this.state;
        if (VPGLAccount || (searchDescription && searchDescription.length > 0)) {
            const inputValue = (searchDescription || VPGLAccount).trim().toLowerCase();
            this.setState({ isSearching: true }, () => {
                axios.get(`${window["apiLocation"]}/api/VPSettings/GLAccounts?Co=${HQCo1}&search=${encodeURIComponent(inputValue)}`).then((response) => {
                    this.setState({ vpGLAccounts: response.data, isSearching: false });
                }).catch(() => this.setState({ isSearching: false, vpGLAccounts: [] }));
            })
        } else {
            this.setState({ vpGLAccounts: [] });
        }
    }, 750);

    render() {
        const { classes, securables, glAccountDialog, addGLAccount, updateGLAccount, removeGLAccount, vpCompany, qboCompany, qboAccounts, qboDepartments, qboClasses, qboPreferences } = this.props;
        const { searchDescription, vpGLAccounts, isSearching } = this.state;
        const accessLevel = _.find(securables, { Securable: "gl-accounts" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...glAccountDialog.props}
                        open={Boolean(accessLevel) && glAccountDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {glAccountDialog.type === 'new' ? 'New GL Account' : 'Edit GL Account'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="GL Account"

                                    id="glAccount"
                                    name="GLAccount"
                                    value={this.state.GLAccount || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(glAccountDialog.type === 'new' ? false : true)}
                                    required
                                    error={this.state.trySubmit && !this.state.GLAccount}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={this.state.trySubmit && !this.state.Description}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="BalanceType"
                                    id="balanceType"
                                    name="BalanceType"
                                    value={this.state.BalanceType || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={this.state.trySubmit && !this.state.BalanceType}
                                    select
                                >
                                    <MenuItem value="D">
                                        Debit
                                    </MenuItem>
                                    <MenuItem value="C">
                                        Credit
                                    </MenuItem>
                                </TextField>
                            </div>

                            {vpCompany &&
                                <div className="flex relative">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="VP GL Account"
                                        options={vpGLAccounts}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.GLAcct}>
                                                {`${value.GLAcct} - ${value.Description}`}
                                            </MenuItem>
                                        }
                                        debounce={750}
                                        onChange={(text) => this.setState({ ...this.state, searchDescription: text }, this.handleGLSearch)}
                                        portal={true}
                                        value={this.state.VPGLAccount || ''}
                                        onSelect={(option) => { if (glAccountDialog.type !== 'new') { this.setState({ ...this.state, VPGLCo: option.GLCo, VPGLAccount: option.GLAcct }) } else { this.setState({ ...this.state, GLAccount: option.GLAcct, Description: option.Description, BalanceType: option.NormBal, VPGLCo: option.GLCo, VPGLAccount: option.GLAcct }) } }}
                                        required
                                        error={this.state.trySubmit && !this.state.VPGLAccount}
                                    />
                                    {isSearching &&
                                        <CircularProgress color="primary" className="absolute pin-r pin-t m-auto" style={{ width: 20, height: 20, marginRight: 9, marginTop: 18 }} />
                                    }
                                </div>
                            }

                            {qboCompany &&
                                <div className="flex relative">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="QBO Account"
                                        options={qboAccounts}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Id}>
                                                {`${value.Name}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.QBOAccount || ''}
                                        onSelect={(option) => { if (glAccountDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOAccount: option.Id }) } else { this.setState({ ...this.state, GLAccount: option.GLAcct, Description: option.Description, BalanceType: option.NormBal, QBOCompany: qboCompany.Co, QBOAccount: option.Id }) } }}
                                        required
                                    />
                                </div>
                            }
                            {/* <div className="flex">
                                <Autocomplete
                                    className={classes.formControl}
                                    title="QBO Department"
                                    options={qboDepartments}
                                    menuItemComponent={(value) =>
                                        <MenuItem value={value.Id}>
                                            {`${value.Name}`}
                                        </MenuItem>
                                    }
                                    portal={true}
                                    value={this.state.QBODepartment || ''}
                                    onSelect={(option) => { if (glAccountDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBODepartment: option.Id }) } else { this.setState({ ...this.state, GLAccount: option.GLAcct, Description: option.Description, BalanceType: option.NormBal, QBOCompany: qboCompany.Co, QBODepartment: option.Id }) } }}
                                    // required
                                    error={this.state.trySubmit && !this.state.QBODepartment}
                                />
                            </div>
                            <div className="flex">
                                <Autocomplete
                                    className={classes.formControl}
                                    title="QBO Class"
                                    options={qboClasses}
                                    menuItemComponent={(value) =>
                                        <MenuItem value={value.Id}>
                                            {`${value.Name}`}
                                        </MenuItem>
                                    }
                                    portal={true}
                                    value={this.state.QBOClass || ''}
                                    onSelect={(option) => { if (glAccountDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOClass: option.Id }) } else { this.setState({ ...this.state, GLAccount: option.GLAcct, Description: option.Description, BalanceType: option.NormBal, QBOCompany: qboCompany.Co, QBOClass: option.Id }) } }}
                                    // required
                                    error={this.state.trySubmit && !this.state.QBOClass}
                                />
                            </div> */}


                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions
                                onMouseEnter={this.trySubmit}
                                onMouseLeave={this.endTrySubmit}
                                className="justify-between pl-16">
                                {glAccountDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addGLAccount(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateGLAccount(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditGLAccountDialog: Actions.closeEditGLAccountDialog,
        closeNewGLAccountDialog: Actions.closeNewGLAccountDialog,
        addGLAccount: Actions.addGLAccount,
        updateGLAccount: Actions.updateGLAccount,
        removeGLAccount: Actions.removeGLAccount
    }, dispatch);
}

function mapStateToProps({ glAccountsApp, spReducers }) {
    return {
        glAccountDialog: glAccountsApp.glAccounts.glAccountDialog,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        vpCompany: spReducers.vpSettings.vpCompany,
        vpGLAccounts: spReducers.vpSettings.glAccounts,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboPreferences: spReducers.qboSettings.preferences,
        qboAccounts: spReducers.qboSettings.glAccounts,
        qboDepartments: spReducers.qboSettings.departments,
        qboClasses: spReducers.qboSettings.classes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(GLAccountDialog));
