import React, { Component } from 'react';
import { Snackbar, SnackbarContent, IconButton, withStyles, Icon } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { openEditEventDialog } from 'main/content/apps/calendar/store/actions';
import { openCardDialog } from 'main/content/apps/scrumboard/store/actions';
import { openEditTodoDialog } from 'main/content/apps/todo/store/actions';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { setRecording } from 'main/content/apps/voice-mailboxes/store/actions';
import { setNotificationData } from 'store/actions';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from '@lodash';
import moment from 'moment';
import axios from 'axios';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    offline: CloudOffIcon,
    uploading: CloudUploadIcon,
    uploaded: CloudDoneIcon,
    refresh: RefreshIcon,
    notification: NotificationsIcon
};

const styles1 = theme => ({
    success: {
        backgroundColor: theme.palette.secondary.dark,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    refresh: {
        backgroundColor: '#5f5f5f',
    },
    notification: {
        backgroundColor: '#3f3f3f'
    },
    uploading: {
        backgroundColor: theme.palette.primary.main,
    },
    uploaded: {
        backgroundColor: theme.palette.secondary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    offline: {
        backgroundColor: '#5f5f5f',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    // bigRound: {
    //     borderRadius: 16
    // }
});

function ConnectionNotification(props) {
    const { classes, className, message, subtitle, notification, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className,)}
            aria-describedby="client-snackbar"
            message={
                <span
                    id="client-snackbar"
                    onClick={variant === 'notification' && notification ? () => {
                        const { ID, Type, Data, DateReceived } = notification;
                        switch (Type) {
                            case 'Trip': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const { Co, WorkOrder, Trip } = data;
                                    other.openEditWorkOrderDialog({ Co, WorkOrder }, Trip);
                                }
                            }
                                break;
                            case 'ToDoItem': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const event = _.find(other.toDos, { Type: 'T', ID: data.ID });
                                    if (event) {
                                        other.openEditTodoDialog(event);
                                    }
                                }
                            }
                                break;
                            case 'ProjectTask': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const event = _.find(other.toDos, { Type: 'P', ID: data.ID });
                                    if (event) {
                                        other.openCardDialog(event, true);
                                    }
                                }
                            }
                                break;
                            case 'CalendarEvent': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const event = _.find(other.events, { ID: data.ID });
                                    if (event) {
                                        const { StartDate, EndDate, Title, Description, AllDayYN, AddedBy, PublicYN } = event;
                                        other.openEditEventDialog({
                                            id: event.ID,
                                            start: moment.utc(StartDate).local().toDate(),
                                            end: moment.utc(EndDate).local().toDate(),
                                            title: Title,
                                            desc: Description,
                                            allDay: AllDayYN,
                                            publicYN: PublicYN,
                                            AddedBy,
                                            Data: {
                                                ...event.Data,
                                                type: "E",
                                            }
                                        })
                                    }
                                }
                            }
                                break;
                            case 'Voicemail': {
                                if (Data) {
                                    const data = JSON.parse(Data);
                                    const recording = _.find(other.voicemail, { CallSid: data.CallSid });
                                    if (recording) {
                                        other.setRecording(recording);
                                    } else {
                                        other.setRecording(data.CallSid, true);
                                    }
                                }
                            }
                                break;
                        }
                        if (!DateReceived) {
                            const request = axios.put(`${window["apiLocation"]}/api/Notification?ID=${ID}`);
                            request.then(() => {
                                const { notifications } = other;
                                const rec = _.find(notifications, { ID });
                                rec.DateReceived = new Date();
                                other.setNotificationData([...notifications]);
                            }).catch(() => {
                                const { notifications } = other;
                                const rec = _.find(notifications, { ID });
                                rec.DateReceived = new Date();
                                other.setNotificationData([...notifications]);
                            })
                        }
                        onClose();
                    } : undefined}
                    className={classes.message}>
                    <Icon color={variant === "notification" ? "secondary" : undefined} className={classNames(classes.icon, classes.iconVariant, variant === "refresh" ? 'spin' : '')} />
                    <div className="w-full">
                        <div className="w-full">{message}</div>
                        {subtitle &&
                            <div className="w-full text-10">{subtitle}</div>
                        }
                    </div>
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={(e) => { e.stopPropagation(); onClose(e); }}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
}

ConnectionNotification.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    // onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'refresh', 'offline', 'uploading', 'uploaded', 'notification']).isRequired,
};

const ConnectionNotificationWrapper = withStyles(styles1)(ConnectionNotification);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
});

class FuseNotification extends Component {

    handleClose = () => {
        this.setState({ open: false, message: '' });
    };

    render() {
        const { classes, options, state, hideNotification, ...other } = this.props;
        return (
            <Snackbar
                anchorOrigin={{ vertical: options.anchorOrigin.vertical, horizontal: options.anchorOrigin.horizontal }}
                classes={{ root: options.anchorOrigin.vertical === "top" ? "toast-top" : options.anchorOrigin.vertical === "bottom" ? "toast-bottom" : "" }}
                open={state}
                onClose={hideNotification}
                autoHideDuration={options.autoHideDuration}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <ConnectionNotificationWrapper
                    {...other}
                    onClose={hideNotification}
                    variant={options.variant}
                    message={options.message}
                    subtitle={options.subtitle}
                    notification={options.data}
                />
            </Snackbar>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        hideNotification: Actions.hideNotification,
        openEditEventDialog,
        openEditTodoDialog,
        openCardDialog,
        openEditWorkOrderDialog,
        setRecording,
        setNotificationData,
    }, dispatch);
}

function mapStateToProps({ fuse, spReducers }) {
    return {
        state: fuse.notification.state,
        options: fuse.notification.options,
        events: spReducers.calendar.data.Events,
        trips: spReducers.calendar.data.Trips,
        toDos: spReducers.calendar.data.ToDos,
        notifications: spReducers.notifications,
        voicemail: spReducers.voicemail
    }
}


export default withStyles(styles2)(connect(mapStateToProps, mapDispatchToProps)(FuseNotification));
