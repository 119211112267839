import { FuseAnimate, FuseUtils } from '@fuse';
import { Button, Checkbox, CircularProgress, Icon, InputAdornment, Tooltip, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
//import RateUpdatesSearch from './RateUpdatesSearch';
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import _ from '@lodash';
import axios from 'axios';
import { showMessage } from 'store/actions';
import { openEditCatalogItemDialog, closeEditCatalogItemDialog, closeEditCatalogAssemblyComponentDialog } from '../catalog/store/actions';
import { updateSelectedAssembliesPricing, deSelectAllAssemblies, selectAllAssemblies, updateAssemblyPricing } from './store/actions/materials.actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Associated Tasks Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: '70vh'
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class AssociatedTasksList extends Component {

    state = {
        category: null,
        variance: null,
        page: 1,
        pages: null,
        loading: true,
        data: [],
        selectedAssemblies: [],
        sorted: [],
        updating: false,
    };

    componentDidMount() {
        const { assemblies, Co, materialDialog } = this.props;
        let pages = Math.ceil(assemblies.data.length / 100)

        this.setState({ ...this.state, data: assemblies.data, loading: assemblies.loading, updating: assemblies.updating, selectedAssemblies: assemblies.selectedAssemblies, pages: pages });
    }
    componentDidUpdate = (prevProps, prevState) => {
        const { assemblies, Co, materialDialog, assemblySearch } = this.props;

        const { selectedAssemblies } = this.state;

        window["warn"]("tasks selectedAssemblies", assemblies, prevProps.assemblies)

        if (!_.isEqual(assemblies, prevProps.assemblies)) {

            let pages = Math.ceil(assemblies.data.length / 100)

            this.setState({ ...this.state, data: assemblies.data, loading: assemblies.loading, updating: assemblies.updating, selectedAssemblies: assemblies.selectedAssemblies, pages: pages });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (!isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    mergeCriteria = (category, variance) => {
        this.setState({ ...this.state, category, variance });
    }

    openAssembly = (assembly) => {
        Promise.all([
            this.props.closeAssemblyComponentDialog(),
            this.props.closeAssembly()
        ]).then(() => {
            this.props.openAssembly(assembly);
        });
    }

    render() {
        const { classes, assemblies } = this.props;
        const { loading, pages, data, category, variance } = this.state;
        const haveEdits = _.filter(data, { hasChanges: true });

        let updating = assemblies.updating;
        let selectedAssemblies = assemblies.selectedAssemblies;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="p-12">
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">assignment</Icon>Associated Tasks
                        {
                            selectedAssemblies && selectedAssemblies.length > 0 &&
                            <Button
                                variant="contained"
                                className="ml-12 pt-0 pb-0"
                                color="primary"
                                onClick={() => this.props.updateSelectedAssembliesPricing(selectedAssemblies)}
                                disabled={updating}
                            >
                                {updating ?
                                    <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} /> :
                                    <Icon className="mr-4">cloud_upload</Icon>
                                }
                                {updating ? 'Updating' : 'Update'}
                            </Button>
                        }
                        {/* <div className="pin-r absolute"><RateUpdatesSearch catalog={this.props.catalog} category={category} variance={variance} onChange={this.mergeCriteria} onSearch={() => this.props.searchAssemblies({ ...this.state, page: 0 }, catalog)} /></div> */}
                    </Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                            }
                        }}
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        columns={[
                            {
                                Header: () => (
                                    <Checkbox
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                        className="p-0"
                                        onChange={(event) => {
                                            event.target.checked ? this.props.selectAllAssemblies(data) : this.props.deSelectAllAssemblies();
                                        }}
                                        checked={selectedAssemblies.length === data.length && selectedAssemblies.length > 0}
                                        indeterminate={selectedAssemblies.length !== data.length && selectedAssemblies.length > 0}
                                    />
                                ),
                                fixed: "left",
                                accessor: "",
                                Cell: row => {
                                    return (<Checkbox
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                        className="p-0"
                                        checked={_.findIndex(selectedAssemblies, { Co: row.original.Co, BusinessUnit: row.original.BusinessUnit, Division: row.original.Division, Assembly: row.original.Assembly }) > -1}
                                        onChange={() => this.props.toggleInSelectedAssemblies(row.original)}
                                    />
                                    )
                                },
                                className: "justify-center p-0",
                                sortable: false,
                                width: 48,
                                fixed: 'left'
                            },
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center",
                                width: 128,
                                show: false,
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "font-bold justify-center",
                                width: 96,
                                show: false,
                            },
                            {
                                Header: "Task",
                                id: 'Assembly',
                                accessor: row => row.PRCode || row.Assembly,
                                className: "font-bold justify-center",
                                Cell: r => (
                                    <Tooltip placement="top" title={`View Task #${r.value}`}>
                                        <div className="flex" onClick={() => this.openAssembly(r.original)}>
                                            {r.value}<Icon className="text-16 ml-4 mt-4 cursor-pointer align-middle">open_in_new</Icon>
                                        </div>
                                    </Tooltip>
                                ),
                                width: 96,
                                fixed: 'left'
                            },
                            {
                                Header: "Name",
                                accessor: 'Description',
                                className: "",
                                Cell: row => <div className="w-full truncate">{row.original.Description}</div>,
                                width: 256,
                                fixed: 'left'
                            },
                            {
                                Header: <Icon onClick={(e) => { e.stopPropagation(); if (haveEdits.length > 0) { this.props.updateSelectedAssembliesPricing(haveEdits) } }} color={haveEdits.length > 0 ? "primary" : "action"} className={classNames("cursor-pointer", updating && "spin")}>{updating ? 'refresh' : 'cloud_upload'}</Icon>,
                                id: 'update',
                                accessor: '',
                                Cell: row => row.original.hasChanges ? <Icon onClick={(e) => {
                                    e.stopPropagation();
                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                    const recs = _.cloneDeepWith(data);
                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                    if (rec) {
                                        rec.hasChanges = false;
                                        rec.isUpdating = true;
                                    }
                                    window["warn"]("upload cloud", recs)
                                    this.setState({ ...this.state, data: recs }, () => this.props.updateAssemblyPricing(row.original));
                                }} color="primary">cloud_upload</Icon> : row.original.isUpdating ? <Icon color="action" className="spin">refresh</Icon> : row.original.hasError ? <Icon color="error">cloud_off</Icon> : row.original.savedChanges ? <Icon color="secondary">cloud_done</Icon> : '', //
                                className: "justify-center",
                                width: 48,
                                fixed: 'left',
                                sortable: false
                            },
                            {
                                Header: "Price",
                                accessor: 'Price',
                                className: "justify-center text-grey",
                                Cell: row => this.formatDollars(row.original.Price),
                                width: 128
                            },
                            {
                                Header: "New Price",
                                accessor: 'NewPrice',
                                className: "justify-center font-bold",
                                Cell: row => (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        if (!row.original.editNewPrice) {
                                            const { Assembly, Co, BusinessUnit, Division } = row.original;
                                            const recs = _.cloneDeepWith(data);
                                            const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                            if (rec) {
                                                rec.editNewPrice = true;
                                            }
                                            this.setState({ data: recs });
                                        }
                                    }}>
                                        {!row.original.editNewPrice ? this.formatDollars(row.original.NewPrice) :
                                            <TextField
                                                className="w-full m-0 mr-12"
                                                id="newPrice"
                                                value={row.original.NewPrice || 0.00}
                                                margin="dense"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.NewPrice = value;
                                                        rec.hasChanges = true;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                required
                                                autoFocus={true}
                                                InputProps={{
                                                    type: 'number',
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disableUnderline: true
                                                }}
                                                onBlur={() => {
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.editNewPrice = false;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                inputProps={{
                                                    className: "text-center font-bold"
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                                width: 128
                            },
                            {
                                Header: "Difference",
                                accessor: 'Difference',
                                className: "justify-center",
                                Cell: row => this.formatDollars(row.original.Difference),
                                width: 128
                            },
                            {
                                Header: "Variance",
                                accessor: 'Variance',
                                className: "justify-center",
                                Cell: row => this.formatPercentage(row.original.Variance),
                                width: 128,
                            },
                            {
                                Header: "Add-On Price",
                                accessor: 'AddOnPrice',
                                className: "justify-center text-grey",
                                Cell: row => this.formatDollars(row.original.AddOnPrice || 0),
                                width: 128
                            },
                            {
                                Header: "New Add-On Price",
                                accessor: 'NewAddOnPrice',
                                className: "justify-center font-bold",
                                Cell: row => (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        if (!row.original.editNewAddOnPrice) {
                                            const { Assembly, Co, BusinessUnit, Division } = row.original;
                                            const recs = _.cloneDeepWith(data);
                                            const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                            if (rec) {
                                                rec.editNewAddOnPrice = true;
                                            }
                                            this.setState({ data: recs });
                                        }
                                    }}>
                                        {!row.original.editNewAddOnPrice ? this.formatDollars(row.original.NewAddOnPrice) :
                                            <TextField
                                                className="w-full m-0 mr-12"
                                                id="newAddOnPrice"
                                                value={row.original.NewAddOnPrice || 0.00}
                                                margin="dense"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.NewAddOnPrice = value;
                                                        rec.hasChanges = true;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                required
                                                autoFocus={true}
                                                InputProps={{
                                                    type: 'number',
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disableUnderline: true
                                                }}
                                                onBlur={() => {
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.editNewAddOnPrice = false;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                inputProps={{
                                                    className: "text-center font-bold"
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                                width: 160
                            },
                            {
                                Header: "Member Price",
                                accessor: 'MemberPrice',
                                className: "justify-center text-grey",
                                Cell: row => this.formatDollars(row.original.MemberPrice || 0),
                                width: 128
                            },
                            {
                                Header: "New Member Price",
                                accessor: 'NewMemberPrice',
                                className: "justify-center font-bold",
                                Cell: row => (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        if (!row.original.editNewMemberPrice) {
                                            const { Assembly, Co, BusinessUnit, Division } = row.original;
                                            const recs = _.cloneDeepWith(data);
                                            const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                            if (rec) {
                                                rec.editNewMemberPrice = true;
                                            }
                                            this.setState({ data: recs });
                                        }
                                    }}>
                                        {!row.original.editNewMemberPrice ? this.formatDollars(row.original.NewMemberPrice) :
                                            <TextField
                                                className="w-full m-0 mr-12"
                                                id="newMemberPrice"
                                                value={row.original.NewMemberPrice || 0.00}
                                                margin="dense"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.NewMemberPrice = value;
                                                        rec.hasChanges = true;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                required
                                                autoFocus={true}
                                                InputProps={{
                                                    type: 'number',
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disableUnderline: true
                                                }}
                                                onBlur={() => {
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.editNewMemberPrice = false;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                inputProps={{
                                                    className: "text-center font-bold"
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                                width: 160
                            },
                            {
                                Header: "Member Add-On Price",
                                accessor: 'MemberAddOnPrice',
                                className: "justify-center text-grey",
                                Cell: row => this.formatDollars(row.original.MemberAddOnPrice || 0),
                                width: 192
                            },
                            {
                                Header: "New Member Add-On Price",
                                accessor: 'NewMemberAddOnPrice',
                                className: "justify-center font-bold",
                                Cell: row => (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        if (!row.original.editNewMemberAddOnPrice) {
                                            const { Assembly, Co, BusinessUnit, Division } = row.original;
                                            const recs = _.cloneDeepWith(data);
                                            const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                            if (rec) {
                                                rec.editNewMemberAddOnPrice = true;
                                            }
                                            this.setState({ data: recs });
                                        }
                                    }}>
                                        {!row.original.editNewMemberAddOnPrice ? this.formatDollars(row.original.NewMemberAddOnPrice) :
                                            <TextField
                                                className="w-full m-0 mr-12"
                                                id="newMemberAddOnPrice"
                                                value={row.original.NewMemberAddOnPrice || 0.00}
                                                margin="dense"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.NewMemberAddOnPrice = value;
                                                        rec.hasChanges = true;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                required
                                                autoFocus={true}
                                                InputProps={{
                                                    type: 'number',
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disableUnderline: true
                                                }}
                                                onBlur={() => {
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.editNewMemberAddOnPrice = false;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                inputProps={{
                                                    className: "text-center font-bold"
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                                width: 256
                            },
                            {
                                Header: "Premium Price",
                                accessor: 'PremiumPrice',
                                className: "justify-center text-grey",
                                Cell: row => this.formatDollars(row.original.PremiumPrice || 0),
                                width: 192
                            },
                            {
                                Header: "New Premium Price",
                                accessor: 'NewPremiumPrice',
                                className: "justify-center font-bold",
                                Cell: row => (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        if (!row.original.editNewPremiumPrice) {
                                            const { Assembly, Co, BusinessUnit, Division } = row.original;
                                            const recs = _.cloneDeepWith(data);
                                            const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                            if (rec) {
                                                rec.editNewPremiumPrice = true;
                                            }
                                            this.setState({ data: recs });
                                        }
                                    }}>
                                        {!row.original.editNewPremiumPrice ? this.formatDollars(row.original.NewPremiumPrice) :
                                            <TextField
                                                className="w-full m-0 mr-12"
                                                id="newPremiumPrice"
                                                value={row.original.NewPremiumPrice || 0.00}
                                                margin="dense"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.NewPremiumPrice = value;
                                                        rec.hasChanges = true;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                required
                                                autoFocus={true}
                                                InputProps={{
                                                    type: 'number',
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disableUnderline: true
                                                }}
                                                onBlur={() => {
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.editNewPremiumPrice = false;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                inputProps={{
                                                    className: "text-center font-bold"
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                                width: 256
                            },
                            {
                                Header: "Premium Add-On Price",
                                accessor: 'PremiumAddOnPrice',
                                className: "justify-center text-grey",
                                Cell: row => this.formatDollars(row.original.PremiumAddOnPrice || 0),
                                width: 192
                            },
                            {
                                Header: "New Premium Add-On Price",
                                accessor: 'NewPremiumAddOnPrice',
                                className: "justify-center font-bold",
                                Cell: row => (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        if (!row.original.editNewPremiumAddOnPrice) {
                                            const { Assembly, Co, BusinessUnit, Division } = row.original;
                                            const recs = _.cloneDeepWith(data);
                                            const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                            if (rec) {
                                                rec.editNewPremiumAddOnPrice = true;
                                            }
                                            this.setState({ data: recs });
                                        }
                                    }}>
                                        {!row.original.editNewPremiumAddOnPrice ? this.formatDollars(row.original.NewPremiumAddOnPrice) :
                                            <TextField
                                                className="w-full m-0 mr-12"
                                                id="newPremiumAddOnPrice"
                                                value={row.original.NewPremiumAddOnPrice || 0.00}
                                                margin="dense"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.NewPremiumAddOnPrice = value;
                                                        rec.hasChanges = true;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                required
                                                autoFocus={true}
                                                InputProps={{
                                                    type: 'number',
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    disableUnderline: true
                                                }}
                                                onBlur={() => {
                                                    const { Assembly, Co, BusinessUnit, Division } = row.original;
                                                    const recs = _.cloneDeepWith(data);
                                                    const rec = _.find(recs, { Assembly, Co, BusinessUnit, Division });
                                                    if (rec) {
                                                        rec.editNewPremiumAddOnPrice = false;
                                                    }
                                                    this.setState({ data: recs });
                                                }}
                                                inputProps={{
                                                    className: "text-center font-bold"
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                                width: 256
                            },
                        ]}
                        defaultPageSize={10}
                        NoDataComponent={!loading ? NoData : LoadingDiv}
                        LoadingComponent={loading ? LoadingDiv : undefined}
                        noDataText="No Tasks found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectAllAssemblies,
        deSelectAllAssemblies,
        toggleInSelectedAssemblies: Actions.toggleInSelectedAssemblies,
        updateAssemblyPricing,
        updateSelectedAssembliesPricing,
        openAssembly: openEditCatalogItemDialog,
        closeAssembly: closeEditCatalogItemDialog,
        closeAssemblyComponentDialog: closeEditCatalogAssemblyComponentDialog
    }, dispatch);
}

function mapStateToProps({ materialsApp, spReducers, catalogApp }) {
    return {
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        materialDialog: materialsApp.materials.materialDialog,
        assemblySearch: catalogApp.assemblySearch,
        assemblies: materialsApp.materials.assemblies,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AssociatedTasksList)));
