import TextGathersApp from 'main/content/apps/text-gathers/TextGathersApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const TextGathersAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/text-gathers/:id',
            component: TextGathersApp
        },
        {
            path     : '/apps/text-gathers',
            component: () => <Redirect to="/apps/text-gathers/all"/>
        }
    ]
};
