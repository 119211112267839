import {combineReducers} from 'redux';
import reporting from './reporting.reducer';
import user from './user.reducer';

const reportingAppReducers = combineReducers({
    reporting,
    user
});

export default reportingAppReducers;
