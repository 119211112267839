import _ from '@lodash';
import { Icon, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios/index';
import classNames from 'classnames';
import deburr from 'lodash/deburr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import accents from 'remove-accents';

const styles = theme => ({
    root: {
        flexGrow: 1,
        left: 0,
        right: 0,
        position: 'absolute',
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        margin: 'auto',
        zIndex: 3,
        maxHeight: 500,
        overflow: 'auto',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: 8,
    },
    input: {
        paddingLeft: '8.8rem',
    }
});

class CustomerSiteSearch extends React.Component {
    state = {
        suggestions: [],
        searchText: null,
    };


    handleSearchInput = (event) => {
        this.setState({ ...this.state, searchText: event.target.value });
        this.getSuggestions();

    }

    handleFocus = (event) => {
        this.getSuggestions();
    }

    handleBlur = (event) => {
        // this.setState({ suggestions: [] });
    }

    getSuggestions = _.debounce(() => {
        const { searchText } = this.state;
        if (searchText && searchText.length > 0) {
            const { Co } = this.props;
            const inputValue = deburr(accents.remove(searchText.trim())).toLowerCase();
            axios.get(`${window["apiLocation"]}/api/CustomerSiteSearch?Co=${Co}&Term=${encodeURIComponent(inputValue)}`).then((response) => {
                const results = [];
                const Sites = response.data;
                for (var i = 0; i < Sites.length; i++) {
                    const suggestion = Sites[i];
                    results.push({ type: 'Site', data: suggestion });
                }
                this.setState({ suggestions: results }, () => window["warn"](this.state.suggestions));
            });
        } else {
            this.setState({ suggestions: [] });
        }
    }, 750);

    handleSelectSite(data) {
        const { Co, Customer, Site } = data;

        const request = axios.get(`${window["apiLocation"]}/api/CustomerSite?Co=${encodeURIComponent(Co)}&Customer=${encodeURIComponent(Customer)}&Site=${encodeURIComponent(Site)}`).then((response) => {
            this.props.onSelected(response.data);
            this.setState({ suggestions: [], searchText: null });
        });
    }

    render() {
        const { classes } = this.props;
        const { suggestions, searchText } = this.state;

        return (
            <div onFocus={this.handleFocus} onBlur={this.handleBlur} className="w-full mb-24">
                <TextField
                    className={classNames(classes.formControl, "")}
                    label="Site"
                    value={searchText}
                    onChange={this.handleSearchInput}
                    InputLabelProps={{ shrink: this.state.searchText ? true : false }}
                    fullWidth
                    variant="outlined"
                    required
                    disabled={this.props.disabled}
                />
                {
                    suggestions.length > 0 &&
                    <div className={classes.container} onMouseDown={(event) => { event.preventDefault(); }}>
                        <Paper className={classNames(classes.suggestionsContainerOpen, "w-full sm:w-auto")} square>
                            {suggestions.map((suggestion) => {
                                switch (suggestion.type) {
                                    case 'Site':
                                        {
                                            return (
                                                <MenuItem onClick={() => { this.handleSelectSite(suggestion.data); }} style={{ height: 60, padding: 16, border: '1px dotted lightgrey', borderRadius: 4, margin: 4 }} component="div">
                                                    <div className="flex flex-1">
                                                        <div className="w-64">
                                                            <Icon color="primary" className="text-36 mt-16 mb-0 w-full text-center">location_on</Icon>
                                                            <Typography fullWidth style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold' }} className="w-full text-center">{`#${suggestion.data.Site}`}</Typography>
                                                        </div>
                                                        <div className="ml-16">
                                                            <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1 }} className="w-full">{`${suggestion.data.Description}`}</Typography>
                                                            <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1 }} className="w-full">{`${suggestion.data.Address1} ${suggestion.data.City}, ${suggestion.data.State} ${suggestion.data.Zip}`}</Typography>
                                                            <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1 }} className="w-full">{`${suggestion.data.Phone}`}</Typography>
                                                            {
                                                                suggestion.data.UpdatedDate ?
                                                                    <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1, fontSize: 'xx-small', fontWeight: 'bold' }} className="w-full">{`Updated: ${moment(moment(suggestion.data.UpdatedDate)).local().toDate().toLocaleTimeString('en-US')} on ${moment(moment(suggestion.data.UpdatedDate)).local().toDate().toLocaleDateString('en-US')}`}</Typography>
                                                                    :
                                                                    suggestion.data.AddedDate &&
                                                                    <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1, fontSize: 'xx-small', fontWeight: 'bold' }} className="w-full">{`Added: ${moment(moment(suggestion.data.AddedDate)).local().toDate().toLocaleTimeString('en-US')} on ${moment(moment(suggestion.data.AddedDate)).local().toDate().toLocaleDateString('en-US')}`}</Typography>
                                                            }
                                                        </div>
                                                    </div>
                                                </MenuItem >
                                            );
                                        }
                                }
                            })
                            }
                        </Paper>
                    </div>
                }
            </div>
        );
    }
}

CustomerSiteSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ customerSitesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CustomerSiteSearch));