import axios from 'axios/index';
import { getUserData } from 'main/content/apps/support/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const SET_SEARCH_TEXT = '[SUPPORT APP] SET SEARCH TEXT';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

