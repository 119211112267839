import axios from 'axios/index';
import CacheManager from 'store/middlewares/cache';

const offline = new CacheManager();

export const SET_DIALOG_HISTORY = '[SPCONNECTION] SET DIALOG HISTORY';

export function getInitialDialogHistory(Co) {
    return (dispatch) => {
        // offline.get('dialogs').then((history) => {
        //     dispatch(setDialogHistory(history || []));
        //     offline.remove('dialogs');
        // });
    }
}

export function setDialogHistory(history) {
    return {
        type: SET_DIALOG_HISTORY,
        history
    };
}

export function addDialogHistory(dialog) {
    return (dispatch, getState) => {
        const state = getState();
        const { dialogHistory } = state.spReducers;
        let { history } = dialogHistory;
        if (history) {
            if (history.length > 100) {
                history.splice(100, 1);
            }
        }
        else {
            history = [];
        }
        history.splice(0, 0, dialog);
        dispatch(setDialogHistory(history));
    };
}