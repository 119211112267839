import axios from 'axios/index';
import { getUserData } from 'main/content/apps/agreement-types/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_AGREEMENT_TYPES = '[AGREEMENT TYPES APP] GET AGREEMENT TYPES';
export const SET_SEARCH_TEXT = '[AGREEMENT TYPES APP] SET SEARCH TEXT';
export const OPEN_NEW_AGREEMENT_TYPE_DIALOG = '[AGREEMENT TYPES APP] OPEN NEW AGREEMENT TYPE DIALOG';
export const CLOSE_NEW_AGREEMENT_TYPE_DIALOG = '[AGREEMENT TYPES APP] CLOSE NEW AGREEMENT TYPE DIALOG';
export const OPEN_EDIT_AGREEMENT_TYPE_DIALOG = '[AGREEMENT TYPES APP] OPEN EDIT AGREEMENT TYPE DIALOG';
export const UPDATE_EDIT_AGREEMENT_TYPE_DIALOG = '[AGREEMENT TYPES APP] UPDATE EDIT AGREEMENT TYPE DIALOG';
export const CLOSE_EDIT_AGREEMENT_TYPE_DIALOG = '[AGREEMENT TYPES APP] CLOSE EDIT AGREEMENT TYPE DIALOG';
export const ADD_AGREEMENT_TYPE = '[AGREEMENT TYPES APP] ADD AGREEMENT TYPE';
export const UPDATE_AGREEMENT_TYPE = '[AGREEMENT TYPES APP] UPDATE AGREEMENT TYPE';
export const REMOVE_AGREEMENT_TYPE = '[AGREEMENT TYPES APP] REMOVE AGREEMENT TYPE';


export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}


export function openNewAgreementTypeDialog() {
    return {
        type: OPEN_NEW_AGREEMENT_TYPE_DIALOG
    }
}

export function closeNewAgreementTypeDialog() {
    return {
        type: CLOSE_NEW_AGREEMENT_TYPE_DIALOG
    }
}

export function openEditAgreementTypeDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/AgreementType?Co=${data.Co}&BusinessUnit=${encodeURIComponent(data.BusinessUnit)}&Division=${encodeURIComponent(data.Division)}&AgreementType=${encodeURIComponent(data.AgreementType)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_AGREEMENT_TYPE_DIALOG,
                data: response.data
            });
        });
    }
}

export function closeEditAgreementTypeDialog() {
    return {
        type: CLOSE_EDIT_AGREEMENT_TYPE_DIALOG
    }
}

export function addAgreementType(newAgreementType) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/AgreementType`,
            newAgreementType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_AGREEMENT_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement Type ${newAgreementType.AgreementType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateAgreementType(agreementType) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/AgreementType?Co=${encodeURIComponent(agreementType.Co)}&BusinessUnit=${encodeURIComponent(agreementType.BusinessUnit)}&Division=${encodeURIComponent(agreementType.Division)}&AgreementType=${encodeURIComponent(agreementType.AgreementType)}`,
            agreementType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_AGREEMENT_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement Type ${agreementType.AgreementType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeAgreementType(agreementTypeId) {
    return (dispatch, getState) => {

        const request = axios.post('/api/agreementTypes-app/remove-agreementType', {
            agreementTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_AGREEMENT_TYPE
                })
            ])
        );
    };
}
