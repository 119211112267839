import { Icon, Typography, withStyles } from '@material-ui/core';
import { data } from 'jquery';
import moment from 'moment';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import LogEntry from './LogEntry';
import _ from '@lodash';

const styles = theme => ({
    root: {},
});

function revertState(item, logs) {
    const newer = _.filter(logs, (o) => moment(o.ActionDate).toDate() >= moment(item.ActionDate).toDate());
    let data = {};
    newer.map((change) => {
        const changes = JSON.parse(change.Data);
        changes.map((d) => {
            data[d.Prop] = d.OldVal;
        })
    })
    const changes = JSON.parse(item.Data);
    changes.map((d) => {
        data[d.Prop] = d.OldVal;
    })
    data.timestamp = item.ActionDate;
    data.logID = item.ID;
    return data;
}

const DataLogs = ({ theme, logs, onSelected, selected }) => {

    return (
        <div className="w-full">
            <div className="w-full rounded-lg bg-grey-lightest" style={{ border: '1px dotted lightgrey' }}>
                {_.filter(logs, (o) => o.Action === 'UPDATE' && o.Data.substr(0,1) !== '{').map((log, index) => {
                    return (
                        <LogEntry selected={selected} onSelected={(item) => onSelected(revertState(item, logs))} item={log} key={log.ActionDate} />
                    );
                })}
            </div>
        </div>
    )
};

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(DataLogs));
