import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, Card, CardHeader, CardContent, Divider, Fab, Icon, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import axios from 'axios';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    card: {
        marginBottom: 12,
        cursor: 'pointer',
        width: '100%',
    },
    cardHeader: {
        padding: 8,
    },
    cardContent: {
        borderTop: '1px solid lightgrey',
        padding: 8,
        paddingBottom: '8px !important',
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    action: {
        marginTop: 0,
        alignSelf: 'auto',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: '1rem'
    },
});

class CallGathersSidebarContent extends Component {

    state = {
        Co: null,
        search: '',
        results: [],
        user: null,
    }

    componentDidMount() {
        const { Co, results, user } = this.props;
        this.setState({ ...this.state, Co, results, user });
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co, results, user } = this.props;
        if (!_.isEqual(Co, prevProps.Co) || !_.isEqual(results, prevProps.results) || !_.isEqual(user, prevProps.user)) {
            this.setState({ ...this.state, Co, results, user });
        }
    }

    render() {
        const { classes, purchaseCallGather } = this.props;
        const { search, user, results, Co } = this.state;
        return (
            <div className="p-16 lg:pl-24">
                {/* <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                    <Paper elevation={1} className="rounded-8">
                        <List>
                            <ListItem
                                button
                                component={NavLink}
                                to={'/apps/call-gathers/all'}
                                activeClassName="active"
                                className={classes.listItem}
                            >
                                <Icon className="list-item-icon text-16" color="action">people</Icon>
                                <ListItemText className="truncate pr-0" primary="All Gathers" disableTypography={true} />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to={'/apps/call-gathers/frequent'}
                                activeClassName="active"
                                className={classes.listItem}
                            >
                                <Icon className="list-item-icon text-16" color="action">restore</Icon>
                                <ListItemText className="truncate pr-0" primary="Recent Gathers" disableTypography={true} />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to={'/apps/call-gathers/starred'}
                                activeClassName="active"
                                className={classes.listItem}
                            >
                                <Icon className="list-item-icon text-16" color="action">star</Icon>
                                <ListItemText className="truncate pr-0" primary="Starred Gathers" disableTypography={true} />
                            </ListItem>
                        </List>
                    </Paper>
                </FuseAnimate> */}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        purchaseCallGather: Actions.purchaseCallGather,
        searchAvailableNumbers: Actions.searchAvailableNumbers,
    }, dispatch);
}

function mapStateToProps({ callGathersApp, usersApp, spReducers }) {
    return {
        user: callGathersApp.user,
        Co: spReducers.companies.Co,
        results: callGathersApp.callGathers.searchResults,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallGathersSidebarContent)));
