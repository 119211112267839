import { applyMiddleware, compose, createStore, Store } from 'redux'
import { persistStore } from 'redux-persist'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import OfflineMonitor from './store/middlewares/Offline'
import SPMonitor from './store/middlewares/SPSignalR'
import reducers from './store/reducers'
import { RootAppState } from './types/store'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(
    thunk as ThunkMiddleware<RootAppState>,
    SPMonitor,
    OfflineMonitor,
  ),
)

export const store: Store<RootAppState> = createStore(reducers, enhancer)

export default store

export const persistor = persistStore(store)
