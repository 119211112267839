import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Badge, Button, Dialog, DialogActions, DialogContent, Fab, Icon, IconButton, Tab, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Dashboard } from '@material-ui/icons';
import classNames from 'classnames';
import AnalyticsDashboardApp from 'main/content/apps/dashboards/analytics/AnalyticsDashboardApp';
import DialGroupMembersList from 'main/content/apps/dial-group-members/DialGroupMembersList';
import * as GroupActions from 'main/content/apps/dial-group-members/store/actions';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newDialGroupState = {
    ID: null,
    Co: null,
    GroupID: null,
    Name: '',
    Description: '',
    Timeout: 10,
    Seq: null,
    Notes: '',
    ActiveYN: 'Y',
    AddedBy: '',
    AddedDate: new Date(),
    value: 0,
    Data: {
        ErrMsg: '',
        Members: [],
    },
};

class DialGroupsDialog extends Component {
    state = { ...newDialGroupState };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newDialGroupState.Co = this.props.Co;
        if (!prevProps.dialGroupDialog.props.open && this.props.dialGroupDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.dialGroupDialog.type === 'edit' &&
                this.props.dialGroupDialog.data &&
                !_.isEqual(this.props.dialGroupDialog.data, prevState)) {
                const data = this.props.dialGroupDialog.data;
                this.setState({ ...this.props.dialGroupDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.dialGroupDialog.type === 'new' &&
                !_.isEqual(this.props.dialGroupDialog.data, prevState)) {
                this.setState(this.props.dialGroupDialog.data);
            }
        }
        if (prevProps.dialGroupDialog.props.open && this.props.dialGroupDialog.props.open) {
            if (this.props.dialGroupDialog.type === 'update' &&
                this.props.dialGroupDialog.data &&
                !_.isEqual(this.props.dialGroupDialog.data, prevState)) {
                this.props.dialGroupDialog.type = 'edit';
                this.setState({ ...this.props.dialGroupDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.dialGroupDialog.type === 'edit' ? this.props.closeEditDialGroupDialog() : this.props.closeNewDialGroupDialog();
    };

    canBeSubmitted() {
        const { Name, DefaultAction } = this.state;
        return (
            Name.length > 0
        );
    }

    render() {
        const { classes, securables, theme, dialGroupDialog, addDialGroup, updateDialGroup, removeDialGroup, businessUnits, divisions, departments, queues, awaitingCallFrom, setAwaitingCallFrom, AudioClips, gathers } = this.props
        const { Co, BusinessUnit, Phone, value, Greeting, EditClip, Data, DefaultAction } = this.state;
        const accessLevel = _.find(securables, { Securable: "hunt-groups" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...dialGroupDialog.props}
                        open={Boolean(accessLevel) && dialGroupDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(dialGroupDialog.type) > -1 ? `Hunt Group #${this.state.GroupID} > New Dial Group` : `Hunt Group #${this.state.GroupID} > Dial Group #${this.state.ID} Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>dialpad</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Members ? this.state.Data.Members.length : 0} color="error"><Icon>account_circle</Icon></Badge>} label="Members" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && <TabContainer>
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <DialogContent classes={{ root: "p-0 sm:p-24" }}>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">dialpad</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Dial Group"
                                            id="id"
                                            name="ID"
                                            value={this.state.ID}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Name"
                                            id="name"
                                            name="Name"
                                            value={this.state.Name}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Timeout"
                                            id="timeout"
                                            name="Timeout"
                                            value={this.state.Timeout}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            style={{ width: 384 }}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Seq"
                                            id="seq"
                                            name="Seq"
                                            value={this.state.Seq}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            style={{ width: 256 }}
                                            disabled={!this.state.ID}
                                        />
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">chat_bubble_outline</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">note</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>
                                </DialogContent>
                            </FuseAnimate>
                        </TabContainer>}
                        {value === 1 && dialGroupDialog.type == 'edit' &&
                            <TabContainer>
                                {accessLevel && accessLevel.AccessLevel !== "R" &&
                                    <Fab
                                        color="secondary"
                                        aria-label="add"
                                        className={classNames(classes.addButton)}
                                        onClick={() => this.props.openNewDialGroupMemberDialog(this.state)}
                                    >
                                        <Icon>add</Icon>
                                    </Fab>
                                }
                                <DialGroupMembersList selectedDialGroupMembersData={[...this.state.Data.Members]} />
                            </TabContainer>}

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {['new', 'customer'].indexOf(dialGroupDialog.type) > -1 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addDialGroup(rec);
                                            //this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                var rec = this.state;
                                                updateDialGroup(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                                        </Button>
                                        <IconButton
                                            onClick={() => {
                                                removeDialGroup(this.state);
                                                this.closeComposeDialog();
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
DialGroupsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditDialGroupDialog: Actions.closeEditDialGroupDialog,
        closeNewDialGroupDialog: Actions.closeNewDialGroupDialog,
        addDialGroup: Actions.addDialGroup,
        updateDialGroup: Actions.updateDialGroup,
        removeDialGroup: Actions.removeDialGroup,
        getFiles: FileActions.getFiles,
        showMessage: showMessage,
        setAwaitingCallFrom: setAwaitingCallFrom,
        openNewDialGroupMemberDialog: GroupActions.openNewDialGroupMemberDialog
    }, dispatch);
}

function mapStateToProps({ callQueuesApp, dialGroupsApp, fileManagerApp, spReducers, dialer }) {
    return {
        dialGroupDialog: dialGroupsApp.dialGroups.dialGroupDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        queues: spReducers.callQueues,
        gathers: spReducers.dialGroups,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        awaitingCallFrom: dialer.awaitingCallFrom,
        AudioClips: spReducers.audioFiles.audioClips,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(DialGroupsDialog));
