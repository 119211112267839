import { FusePageCarded } from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import OptionBoardTypeDialog from 'main/content/apps/option-board-types/OptionBoardTypeDialog';
import OptionBoardTypeHeader from 'main/content/apps/option-board-types/OptionBoardTypesHeader';
import OptionBoardTypesLeftSidebarHeader from 'main/content/apps/option-board-types/OptionBoardTypesLeftSidebarHeader';
import OptionBoardTypeList from 'main/content/apps/option-board-types/OptionBoardTypesList';
import OptionBoardTypeSidebarContent from 'main/content/apps/option-board-types/OptionBoardTypesSidebarContent';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class OptionBoardTypesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    render() {
        const { classes, openNewOptionBoardTypeDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <OptionBoardTypeHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <OptionBoardTypeList />
                            }
                            leftSidebarHeader={
                                <OptionBoardTypesLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <OptionBoardTypeSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <OptionBoardTypeDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewOptionBoardTypeDialog: Actions.openNewOptionBoardTypeDialog
    }, dispatch);
}

function mapStateToProps({ optionBoardTypesApp }) {
    return {
        optionBoardTypes: optionBoardTypesApp.optionBoardTypes.entities,
        selectedOptionBoardTypeIds: optionBoardTypesApp.optionBoardTypes.selectedOptionBoardTypeIds,
        searchText: optionBoardTypesApp.optionBoardTypes.searchText,
        user: optionBoardTypesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(OptionBoardTypesApp)));
