import {combineReducers} from 'redux';
import glAccounts from './gl-accounts.reducer';
import user from './user.reducer';

const glAccountsAppReducers = combineReducers({
    glAccounts,
    user
});

export default glAccountsAppReducers;
