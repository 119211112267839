import CustomersApp from 'main/content/apps/technician/TechnicianApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const TechnicianAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/technician',
            component: CustomersApp
        },
    ]
};
