import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Button, CircularProgress, Icon, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as Actions from './store/actions';

const styles = theme => ({
    clear: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[400],
        },
        '&:active': {
            backgroundColor: theme.palette.error[700],
        }
    }
});

const initialState = {
    Agreement: {
        BusinessUnit: null,
        Division: null,
        AgreementType: null,
        Status: 'Current',
    },
    EventType: null,
    Task: null,
    DueWithin: 30,
}
class AgreementsSidebarContent extends Component {

    state = {
        ...initialState
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    componentDidMount() {
        const { filters, loading } = this.props;
        if (filters) {
            this.setState(filters ? { ...filters, loading } : { ...initialState, loading });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { filters, loading } = this.props;
        console.warn(filters, prevProps.filters, this.state);
        if (!_.isEqual(filters, prevProps.filters) || !_.isEqual(loading, prevProps.loading)) {
            this.setState(filters ? { ...filters, loading } : { ...initialState, loading });
        }
    }

    setFilters = () => {
        const filters = this.buildFilters(this.state);
        this.props.setAgreementFilters(filters);
    }

    buildFilters = (data) => {

        const filters = {
            Agreement: {},
        };
        Object.keys(data.Agreement).map((key) => {
            const value = data.Agreement[key];
            if (value) {
                filters.Agreement[key] = value;
            }
        });
        Object.keys(data).map((key) => {
            const value = data[key];
            if (key !== 'Agreement') {
                if (value) {
                    filters[key] = value;
                }
            }
        });
        return filters;
    }

    clearFilters = () => {
        this.setState({ ...initialState }, () => this.props.setAgreementFilters(null));
    }

    render() {
        const { Co, classes, businessUnits, divisions, agreementTypes, filters } = this.props;
        const { loading } = this.state;
        const currentFilters = this.buildFilters(this.state);
        const agreementType = agreementTypes ? _.find(agreementTypes, { BusinessUnit: this.state.Agreement.BusinessUnit, Division: this.state.Agreement.Division, AgreementType: this.state.Agreement.AgreementType }) : null;
        const tasks = agreementType ? agreementType.Data.Tasks : [];
        return (
            <div onClick={(e) => e.stopPropagation()} className="p-12 lg:pl-20">
                <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                    <Paper elevation={1} className="rounded-8 p-12 relative">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 8, }} className="flex"><Icon color="primary" className="text-32 mr-12">search</Icon>Search</Typography>
                        {filters &&
                            <Icon onClick={this.clearFilters} className="pin-t pin-r absolute mt-16 mr-12 cursor-pointer" color="action">close</Icon>
                        }
                        <Autocomplete
                            className="mb-12"
                            title="Business Unit"
                            options={businessUnits ? businessUnits : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.BusinessUnit}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Agreement.BusinessUnit || ''}
                            onSelect={(option) => this.setState({ ...this.state, Agreement: { ...this.state.Agreement, BusinessUnit: option.BusinessUnit, Division: null, AgreementType: null, }, Task: null })}
                        />
                        <Autocomplete
                            className="mb-12"
                            title="Division"
                            options={divisions ? _.filter(divisions, { BusinessUnit: this.state.Agreement.BusinessUnit }) : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Division}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Agreement.Division || ''}
                            onSelect={(option) => this.setState({ ...this.state, Agreement: { ...this.state.Agreement, Division: option.Division, AgreementType: null, }, Task: null })}
                        />
                        <Autocomplete
                            className="mb-12"
                            title="Agreement Type"
                            options={businessUnits && divisions && agreementTypes ? _.filter(agreementTypes, { BusinessUnit: this.state.Agreement.BusinessUnit, Division: this.state.Agreement.Division }) : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.AgreementType}>
                                    {value.Name}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Agreement.AgreementType || ''}
                            onSelect={(option) => this.setState({ ...this.state, Agreement: { ...this.state.Agreement, AgreementType: option.AgreementType, }, Task: null })}
                        />
                        <TextField
                            className="mb-12"
                            label="Agreement Status"
                            id="status"
                            name="Agreement.Status"
                            value={this.state.Agreement.Status || ''}
                            onChange={(e) => this.setState({ ...this.state, Agreement: { ...this.state.Agreement, Status: e.target.value } })}
                            variant="outlined"
                            select
                            fullWidth
                        >
                            <MenuItem value="Inactive">
                                Inactive
                            </MenuItem>
                            <MenuItem value="Pending">
                                Pending
                            </MenuItem>
                            <MenuItem value="Current">
                                Current
                            </MenuItem>
                            <MenuItem value="Expired">
                                Expired
                            </MenuItem>
                        </TextField>
                        <TextField
                            className="mb-12"
                            label="Upcoming Event"
                            id="eventType"
                            name="EventType"
                            value={this.state.EventType || ''}
                            onChange={(e) => this.setState({ ...this.state, EventType: e.target.value })}
                            variant="outlined"
                            select
                            fullWidth
                        >
                            <MenuItem value={null}>
                                None
                            </MenuItem>
                            <MenuItem value="T">
                                Task
                            </MenuItem>
                            <MenuItem value="B">
                                Billing
                            </MenuItem>
                            <MenuItem value="A">
                                Amortization
                            </MenuItem>
                        </TextField>
                        {this.state.EventType &&
                            <TextField
                                className="mb-12"
                                label="Due Within"
                                id="dueWithin"
                                name="DueWithin"
                                type="number"
                                value={this.state.DueWithin || 30}
                                onChange={(e) => this.setState({ ...this.state, DueWithin: e.target.value })}
                                variant="outlined"
                                fullWidth
                            />
                        }
                        {
                            this.state.EventType === "T" &&
                            <Autocomplete
                                className="mb-12"
                                title="Task"
                                options={[{ TaskType: null, Name: 'Any' }, ...tasks]}
                                menuItemComponent={(value) => {
                                    return <MenuItem value={value.TaskType}>
                                        {value.Name}
                                    </MenuItem>
                                }}
                                portal={true}
                                value={this.state.Task || ''}
                                onSelect={(option) => this.setState({ ...this.state, Task: option.TaskType })}
                            />
                        }
                        {
                            filters && _.isEqual(filters, currentFilters) ?
                                <Button
                                    variant="contained"
                                    fullWidth
                                    className={classes.clear}
                                    onClick={this.clearFilters}
                                >
                                    Clear Filters
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => this.props.getAgreements(Co, { ...this.state })}
                                    disabled={loading}
                                >
                                    {this.state.loading &&
                                        <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                                    }
                                    {this.state.loading ? 'Searching' : 'Search'}
                                </Button>
                        }
                    </Paper>
                </FuseAnimate>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAgreementFilters: Actions.setAgreementFilters,
        getAgreements: Actions.getAgreements
    }, dispatch);
}

function mapStateToProps({ spReducers, agreementsApp }) {
    return {
        Co: spReducers.companies.Co,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        agreementTypes: spReducers.agreementTypes,
        filters: agreementsApp.agreements.filters,
        loading: agreementsApp.agreements.loading,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementsSidebarContent)));
