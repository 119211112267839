import AcademyApp from 'main/content/apps/academy/AcademyApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const AcademyAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/academy/:Category',
            component: AcademyApp
        },
        {
            path     : '/apps/academy',
            component: () => <Redirect to="/apps/academy/all"/>
        }
    ]
};
