import { Geolocation } from '@capacitor/geolocation';
import { FuseAnimate, FuseAnimateGroup, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Badge, Card, CardHeader, Chip, CircularProgress, Hidden, Icon, IconButton, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import GoogleMap from 'google-map-react';
import { SPRoundIcon } from 'main/icons';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swipeable from "react-swipeable";
import { bindActionCreators } from 'redux';
import { getSupervisorData } from 'store/actions';
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector';
import TechnicianSchedule from '../technician/TechnicianSchedule';
import TechnicianTimecard from '../technician/TechnicianTimecard';
import * as Actions from './store/actions';
import { openEditInvoiceDialog } from '../invoices/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import * as rounded from 'highcharts-rounded-corners/rounded-corners';
import getProfileImage from '../../../functions/getProfileImageUrl';



const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

function Marker({ text, icon, style, badge, heading, type, status, onClick }) {
    return (
        <Tooltip title={text} placement="top" classes={{ popper: "tech-schedule-tooltip" }}>
            <Badge onClick={onClick} badgeContent={badge ? badge : 0} classes={{ root: type === "tech" ? "person-marker" : "", badge: type === "tech" ? "tech-badge" : status < 4 ? "tech-scheduled-badge cursor-pointer" : status < 8 ? "tech-in-progress-badge cursor-pointer" : "tech-completed-badge cursor-pointer" }} color={status < 4 ? "error" : "primary"}>
                <div>
                    <img style={style} src={icon} />
                </div>
            </Badge>
        </Tooltip>
    );
}

const styles = theme => ({
    tabs: {
        '&:hover': {
            backgroundColor: "rgba(200, 200, 200, 0.3)"
        }
    },
    root: {
        // maxHeight: '75vh',
        overflow: 'auto'
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    calendar: {
        width: '100%',
        background: theme.palette.primary.main,
        // borderRadius: 5,
    },
    calendarHeader: {
        width: '100%',
    },
    calendarPicker: {
        height: 72,
        width: '100%',
        background: '#3f3f3f',
        // borderBottomLeftRadius: 5,
        // borderBottomRightRadius: 5
    },
    calendarPickerDates: {
        height: 72,
        width: '100%',
    },
    mailItem: {},
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    labels: {},
    badge: {
        top: 5,
        right: 12,
    },
    liRoot: {
        padding: 0
    },
    clockInBtn: {
        background: '#333',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        }
    },
    clockOutBtn: {
        background: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        '&:active': {
            background: '#5f5f5f',
        }
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    withdraw: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    inlineBadge: {
        top: '25%',
        left: '110%',
        right: 'unset',
        fontSize: 11,
        padding: 4,
        height: 16,
        transform: 'none',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    tab: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    viewTab: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 4px 0px #3f3f3f',
        color: theme.palette.primary.contrastText,
        paddingTop: '8px !important',
    },
    viewTabIcon: {
        color: theme.palette.primary.contrastText,
    },
    searchBadge: {
        top: 2,
        right: -8,
        position: 'relative',
        transform: 'none'
    },
    action: {
        marginTop: -4,
        marginBottom: -4
    },
    selectedView: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: 5
    }
});

const initialState = {
    loading: false,
    date: new Date(),
    expanded: {},
    trips: [],
    timecards: [],
    timecard: null,
    selectedTrip: null,
    viewing: 'overview',
    anchorEl: null,
    tcView: null,
    schedView: null,
    hold: false,
    animation: 'transition.slideLeftIn',
    isDatepickerOpen: false,
    selectedTeam: 0,
    selectedLeadTeam: 0,
    selectedTech: null,
    selDate: null,
    techView: null
}

class SupervisorProfile extends Component {

    state = {
        ...initialState
    };

    componentDidMount() {
        // HighchartsMore(Highcharts);
        rounded(Highcharts);
        let { date, data, loading } = this.props;
        this.setState({ ...this.state, date, data, loading, tcView: moment(date).format("M/D/YYYY"), schedView: moment(date).format("M/D/YYYY") }, () => this.getGPS());
    }

    componentDidUpdate(prevProps, prevState) {
        let { loading, data, date, start, weekly } = this.props;
        if (!_.isEqual(date, prevProps.date) || !_.isEqual(loading, prevProps.loading) || !_.isEqual(data, prevProps.data)) {
            this.setState({ ...this.state, date, loading, data });
        }
        if (weekly !== prevProps.weekly) {
            this.setState({ animation: weekly !== prevProps.weekly ? !weekly ? 'transition.slideUpIn' : 'transition.slideDownIn' : this.state.animation });
        }
    }

    getGPS = () => {
        const { gps } = this.props;
        if (!gps || !gps.location || !gps.location.coords || !gps.location.coords.latitude || !gps.location.coords.longitude) {

            var geoOptions = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            console.log('Fetching GPS Location - Supervisor');
            Geolocation.getCurrentPosition(geoOptions).then((pos) => {
                var crd = pos.coords;

                var Latitude = crd.latitude;
                var Longitude = crd.longitude;
                this.setState({ ...this.state, userGPS: { Latitude, Longitude } });
            }).catch((err) => {
                window["warn"](`ERROR(${err.code}): ${err.message}`);
            });
        } else {
            var Latitude = gps.location.coords.latitude;
            var Longitude = gps.location.coords.longitude;
            this.setState({ ...this.state, userGPS: { Latitude, Longitude } });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    handleDateChange = (event) => {
        window["warn"](event);
        const date = event._d;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        const newDate = this.formatDateInput(date);
        if (oldDate !== newDate) {
            this.setState({ ...this.state, date, schedView: moment(date).format("M/D/YYYY"), hold: false, sigImg: null, loading: true, trips: [], }, () => {
                this.updateDate(date);
            });
        }
    }

    handleDateClick = (date) => {
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.state.date);
        const newDate = this.formatDateInput(date);
        if (oldDate !== newDate) {
            this.setState({ ...this.state, date, selDate: moment(date).format('M/D/YYYY'), hold: false, tcView: moment(date).format("M/D/YYYY"), schedView: moment(date).format("M/D/YYYY"), animation: new Date(this.state.date) > new Date(date) ? 'transition.slideLeftIn' : 'transition.slideRightIn' }, () => {
                this.updateDate(date);
            });
        }
    }

    handleSwipeDateLeft = () => {
        let { date } = this.state;
        if (date) {
            const dt = new Date(date);
            const start = moment(new Date(dt)).locale('us').startOf('isoweek').toDate();
            const end = moment(new Date(dt)).locale('us').endOf('isoweek').toDate();
            const next = dt.setDate(dt.getDate() + 1);
            // if (next >= start && next <= end) {
            this.setState({ ...this.state, date: next, hold: false, loading: (next > end || next < start), tcView: moment(next).format("M/D/YYYY"), schedView: moment(next).format("M/D/YYYY"), animation: 'transition.slideRightIn' }, () => {
                this.updateDate(next);
            });
            // }
        }
    }

    handleSwipeDateRight = () => {
        let { date } = this.state;
        if (date) {
            const dt = new Date(date);
            const start = moment(new Date(dt)).locale('us').startOf('isoweek').toDate();
            const end = moment(new Date(dt)).locale('us').endOf('isoweek').toDate();
            const next = dt.setDate(dt.getDate() - 1);
            // if (next >= start && next <= end) {
            this.setState({ ...this.state, date: next, hold: false, loading: (next > end || next < start), tcView: moment(next).format("M/D/YYYY"), schedView: moment(next).format("M/D/YYYY"), animation: 'transition.slideLeftIn' }, () => {
                this.updateDate(next);
            });
            // }
        }
    }

    handleToday = (event) => {
        const date = new Date();
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.state.date);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, sigImg: null, loading: true, trips: [], hold: false, tcView: moment(date).format("M/D/YYYY"), schedView: moment(date).format("M/D/YYYY"), }, () => {
            this.updateDate(date, true);
        });
    }

    handleNextDate = (bool) => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.state.date);
        date.setDate(date.getDate() + 7);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, sigImg: null, loading: true, trips: [], timecards: [], hold: false, tcView: bool ? null : moment(date).format("M/D/YYYY"), schedView: bool ? null : moment(date).format("M/D/YYYY"), animation: 'transition.slideRightIn', }, () => {
            this.updateDate(date, true);
        });
    }

    handlePrevDate = (bool) => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.state.date);
        date.setDate(date.getDate() - 7);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, sigImg: null, loading: true, trips: [], timecards: [], hold: false, tcView: bool ? null : moment(date).format("M/D/YYYY"), schedView: bool ? null : moment(date).format("M/D/YYYY"), animation: 'transition.slideLeftIn', }, () => {
            this.updateDate(date, true);
        });
    }

    updateDate = _.debounce((date, bool) => { this.props.getSupervisorData(this.props.Co, date, bool); }, 500);

    formatDateInput(dt) {
        return dt.toLocaleDateString('en-US').replace(/\//g, '-');
    }

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    openDatePicker = (e) => {
        this.refs.datePicker.open(e);
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    handleRefresh = () => {
        let { date } = this.state;
        this.setState({ ...this.state, date, sigImg: null, loading: true, trips: [], }, () => {
            this.updateDate(date, true);
        });
    }

    toggleTrip = (trip) => {
        const { expanded } = this.state;
        if (expanded[trip]) {
            expanded[trip] = false;
        } else {
            expanded[trip] = true;
        }
        this.setState({ ...this.state, expanded });
    }

    getMapOptions = (maps) => {
        return {
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.DROPDOWN_MENU
            },
            gestureHandling: "cooperative",
            // mapTypeId: maps.MapTypeId.SATELLITE,
            styles: [
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#d1e751"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#292929"
                        },
                        {
                            "lightness": 10
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 60
                        },
                        {
                            "visibility": "on"
                        },
                        {
                            "weight": 2
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": "17"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#4dbce9"
                        },
                        {
                            "saturation": -60
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#292929"
                        },
                        {
                            "lightness": 10
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "lightness": "20"
                        },
                        {
                            "weight": 3
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#878787"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "lightness": "2"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "lightness": "30"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#5a5c57"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#292929"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#212121"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#949494"
                        },
                        {
                            "weight": 1
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#b0b0b0"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#a8a8a8"
                        },
                        {
                            "lightness": 15
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 30
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#0f252e"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#4dbce9"
                        },
                        {
                            "saturation": -55
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#4a4a4a"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#333333"
                        }
                    ]
                }
            ]
        }
    }

    selectTrip = (trip) => {
        let { selectedTrip } = this.state;
        this.setState({ ...this.state, selectedTrip: (trip && selectedTrip && selectedTrip.ID === trip.ID) ? null : trip });
    }

    openMenu = (e) => {
        this.setState({ ...this.state, anchorEl: e.currentTarget });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    openStartPicker = (e) => {
        this.startPicker.open(e);
    }

    openEndPicker = (e) => {
        this.endPicker.open(e);
    }

    setDatepickerOpen = (bool) => {
        const { isDatepickerOpen } = this.state;
        if (isDatepickerOpen !== bool) {
            this.setState({ isDatepickerOpen: bool });
        }
    }

    // handleDateChange = (event) => {
    //     window["warn"](event);
    //     const InvoiceDate = event._d;
    //     this.setState({ ...this.state, InvoiceDate, DueDate: InvoiceDate });
    // }

    openDatePicker = (e) => {
        this.setDatepickerOpen(true);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        const { classes, Co, user, searchText, openEditWorkOrderDialog, supervisor, onBack, searchResults, technicians, technician, employees, weekly } = this.props;
        const { date, animation, loading, expanded, allTrips, timecard, userGPS, selectedTrip, viewing, anchorEl, tcView, schedView, selectedTeam, selectedLeadTeam, selectedTech, selDate, techView } = this.state;
        const start = moment(date).locale('us').startOf('isoweek');
        const tcData = timecard ? timecard.Data : null;
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const statusProps =
            [
                { text: 'Scheduled', icon: 'access_time', color: 'rgb(100, 100, 200)', dateProp: 'ScheduledDate', timeProp: 'ScheduledTime' },
                { text: 'Notified', icon: 'offline_bolt', color: 'rgb(0, 150, 250)', dateProp: 'NotifiedDate', timeProp: 'NotifiedTime' },
                { text: 'Accepted', icon: 'offline_pin', color: 'rgb(0, 50, 250)', dateProp: 'AcceptedDate', timeProp: 'AcceptedTime' },
                { text: 'Rejected', icon: 'cancel', color: 'rgb(210, 0, 0)', dateProp: 'RejectedDate', timeProp: 'RejectedTime' },
                { text: 'En-Route', icon: 'explore', color: 'rgb(50, 170, 200)', dateProp: 'EnRouteDate', timeProp: 'EnRouteTime' },
                { text: 'Arrived', icon: 'my_location', color: 'rgb(50, 150, 100)', dateProp: 'ArrivedDate', timeProp: 'ArrivedTime' },
                { text: 'In Progress', icon: 'play_circle_outline', color: 'rgb(150, 200, 50)', dateProp: 'InProgressDate', timeProp: 'InProgressTime' },
                { text: 'On Hold', icon: 'pause_circle_outline', color: 'rgb(210, 100, 100)', dateProp: 'OnHoldDate', timeProp: 'OnHoldTime' },
                { text: 'Completed', icon: 'check_circle', color: 'rgb(100, 100, 100)', dateProp: 'CompletedDate', timeProp: 'CompletedTime' }
            ];
        const calWindow = [];
        for (var i = 0; i < 7; i++) {
            let dt = new Date(start);
            dt.setDate(dt.getDate() + i);
            calWindow.push(dt);
        }
        const avatar = 'assets/images/avatars/profile.jpg';
        const teamAvatar = 'assets/images/logos/SP_Cog_Gradient.png';

        const teams = supervisor.data.Teams;

        const memb = technician ? [{ Co, TeamMember: technician.Technician }] : [];
        const team = selectedTeam ? _.find(teams, { Team: selectedTeam }) : {
            Co,
            Name: "All Teams",
            TeamLead: technician ? technician.Technician : null,
            Data: {
                Members: [...memb, ..._.flatten(_.map(teams, _.property('Data.Members')))]
            }
        };
        team.Data.Members.map((memb) => {
            const tk = _.find(technicians, { Co, Technician: memb.TeamMember });
            if (tk) {
                const emp = _.find(employees, { Co, Employee: tk.Employee });
                memb.Data = {
                    Technician: tk,
                    Employee: emp
                }
            }
        })

        const membLead = selectedTech ? [{ Co, TeamMember: selectedTech }] : [];
        let leadTeam = _.cloneDeep(team);
        if (selectedTech) {
            leadTeam = selectedLeadTeam ? _.find(teams, { Team: selectedLeadTeam }) : {
                Co,
                Name: "All Teams",
                TeamLead: selectedTech,
                Data: {
                    Members: [...membLead, ..._.flatten(_.map(_.filter(teams, (o) => (techView === 'team' && o.TeamLead === selectedTech)), _.property('Data.Members')))]
                }
            };

            leadTeam.Data.Members.map((memb) => {
                const tk = _.find(technicians, { Co, Technician: memb.TeamMember });
                if (tk) {
                    const emp = _.find(employees, { Co, Employee: tk.Employee });
                    memb.Data = {
                        Technician: tk,
                        Employee: emp
                    }
                }
            })
        }
        const selTech = selectedTech ? _.find(technicians, { Technician: selectedTech }) : null;
        const selEmp = selTech ? _.find(employees, { Co, Employee: selTech.Employee }) : null;
        const lead = selectedTeam && team ? _.find(technicians, { Technician: team.TeamMember }) : null;
        const soldEstimates = _.filter(supervisor.data.Estimates, (o) => o.Status > 0 && (!selEmp || techView === 'team' || selEmp.UserName === o.EnteredBy) && _.findIndex(leadTeam.Data.Members, (m) => m.Data && m.Data.Employee && m.Data.Employee.UserName === o.EnteredBy) > -1 && (weekly || moment.utc(o.IssuedDate).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const sold = _.sumBy(soldEstimates, 'PriceTotal');
        const sales = soldEstimates.length;
        const invoiceLines = _.filter(supervisor.data.InvoiceLines, (o) => (!selTech || techView === 'team' || selTech.Technician === o.LeadTechnician) && _.findIndex(leadTeam.Data.Members, (m) => m.TeamMember === o.LeadTechnician) > -1 && (weekly || moment(o.InvoiceDate).format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const invoices = _.uniqBy(invoiceLines, 'Invoice');
        const billed = _.sumBy(invoiceLines, 'Amount');
        const estimates = _.uniqBy(_.filter(supervisor.data.Estimates, (o) => (!selEmp || techView === 'team' || selEmp.UserName === o.EnteredBy) && _.findIndex(leadTeam.Data.Members, (m) => m.Data && m.Data.Employee && m.Data.Employee.UserName === o.EnteredBy) > -1 && (weekly || moment.utc(o.IssuedDate).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY"))), 'EstimateNumber');
        const trips = _.filter(supervisor.data.Trips, (o) => (!selTech || techView === 'team' || selTech.Technician === o.Technician) && _.findIndex(leadTeam.Data.Members, (m) => m.TeamMember === o.Technician) > -1 && (weekly || moment.utc(o.BoardTime).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const techTeams = _.filter(teams, (o) => (!selTech || selTech.Technician === o.TeamLead));
        const wos = _.uniqBy(trips, 'WorkOrder').length;
        const pos = _.filter(supervisor.data.POLines, (o) => (!selTech || techView === 'team' || selTech.Technician === o.LeadTechnician) && _.findIndex(leadTeam.Data.Members, (m) => m.TeamMember === o.LeadTechnician) > -1 && (weekly || moment.utc(o.AddedDate).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const poCount = _.uniqBy(pos, 'PO').length;
        const poCost = _.sumBy(pos, 'CostTotal');
        const labor = _.filter(supervisor.data.Labor, (o) => ((!selEmp || techView === 'team' || selEmp.Employee === o.Employee) || (!selTech || selTech.Technician === o.LeadTechnician)) && _.findIndex(leadTeam.Data.Members, (m) => (m.TeamMember === o.LeadTechnician || m.Data && m.Data.Employee && m.Data.Employee.Employee === o.Employee)) > -1 && (weekly || moment(o.Date).format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const punch = _.filter(supervisor.data.Punch, (o) => (!selEmp || techView === 'team' || selEmp.Employee === o.Employee) && _.findIndex(leadTeam.Data.Members, (m) => m.Data && m.Data.Employee && m.Data.Employee.Employee === o.Employee) > -1 && (weekly || moment(o.Date).format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const timecards = _.filter(supervisor.data.Timecards, (o) => (!selEmp || techView === 'team' || selEmp.Employee === o.Employee) && _.findIndex(leadTeam.Data.Members, (m) => m.Data && m.Data.Employee && m.Data.Employee.Employee === o.Employee) > -1 && (weekly || moment(o.Date).format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
        const hours = _.sumBy(_.flatten(_.map(timecards, _.property('Data.Labor'))), 'Qty');
        const laborCost = _.sumBy(labor, 'Total');
        const materialCost = _.sumBy(_.filter(supervisor.data.InventoryTransactions, (o) => (!selEmp || techView === 'team' || selEmp.Employee === o.Employee) && _.findIndex(leadTeam.Data.Members, (m) => m.Data && m.Data.Employee && m.Data.Employee.Employee === o.Employee) > -1 && (weekly || moment.utc(o.TransactionDate).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY"))), 'Amount');
        const cost = poCost + laborCost + materialCost;
        const profit = (billed - cost);
        const margin = (billed !== 0 && profit !== 0 ? (profit / billed) : 0) * 100;

        return (
            <Media query="(min-width: 768px) and (min-height: 768px)" >
                {matches =>
                    <FuseAnimate animation="transition.slideUpIn" delay={300}>
                        <div className="w-full pb-6">
                            <Typography variant="h6" style={{ alignItems: 'center', marginBottom: -4, maxWidth: viewing === "overview" ? 'calc(100% - 40px)' : undefined, }} className="flex pl-0 pt-4 sm:max-w-none overflow-x-auto">
                                <div onClick={() => this.setState({ ...this.state, viewing: 'overview', })} className={classNames(viewing === 'overview' ? classes.viewTab : classes.tab, "w-auto p-6 pr-12 cursor-pointer")} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, whiteSpace: 'nowrap' }}>
                                    <Icon color="primary" className={classNames(viewing === 'overview' ? classes.viewTabIcon : "", "text-32 mr-8 mb-4 align-middle")}>supervised_user_circle</Icon>
                                    <span>Team Overview</span>
                                </div>
                                <div onClick={() => this.setState({ ...this.state, viewing: 'dashboard', })} className={classNames(viewing === 'dashboard' ? classes.viewTab : classes.tab, "w-auto p-6 pr-12 cursor-pointer")} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, whiteSpace: 'nowrap' }}>
                                    <Icon color="primary" className={classNames(viewing === 'dashboard' ? classes.viewTabIcon : "", "text-32 mr-8 mb-4 align-middle")}>insert_chart</Icon>
                                    <span>Performance</span>
                                </div>
                            </Typography>
                            {["overview", "timecard"].indexOf(viewing) > -1 &&
                                <IconButton className="pin-r pin-t absolute" style={{ marginTop: 3, marginRight: -4 }} onClick={this.handleRefresh}>
                                    <Icon color="action" className={!loading ? "text-24 cursor-pointer" : "text-24 cursor-pointer spin"}>refresh</Icon>
                                </IconButton>
                            }
                            <div className="md:flex pb-48 md:pb-0 rounded-b" style={["overview", "timecard"].indexOf(viewing) > -1 ? { boxShadow: '2px 2px 8px 2px rgb(0 0 0 / 15%)', borderBottomLeftRadius: 5, borderBottomRightRadius: 5 } : undefined}>
                                <div className={classNames(`w-full relative min-w-1/2${["overview", "timecard"].indexOf(viewing) > -1 ? " md:max-w-1/2" : ""}`, matches ? classes.root : '')}>
                                    {viewing !== "dashboard" &&
                                        <div className={classNames("border-0")}>
                                            <div className={classNames(classes.calendar, "rounded-tr md:rounded-tr-none")}>
                                                <div className={classNames(classes.calendarHeader, "flex justify-between")}>
                                                    <Icon onClick={() => this.handlePrevDate(false)} className="text-24 text-white mt-6 cursor-pointer ml-4">skip_previous</Icon>
                                                    <Typography onClick={this.openDatePicker} variant="subheading" style={{ alignItems: 'center', color: '#fff', }} className="flex justify-center font-bold m-4 my-8 cursor-pointer">
                                                        <Icon onClick={(e) => { e.stopPropagation(); this.handleToday(); }} className="text-white text-20 mr-4">today</Icon>
                                                        {weekly ? `Week of ${moment(start).format("MMMM Do 'YY")}` : moment(date).format("MMMM Do 'YY")}
                                                    </Typography>
                                                    <Icon onClick={() => this.handleNextDate(false)} className="text-24 text-white mt-6 cursor-pointer mr-4">skip_next</Icon>
                                                </div>
                                                <DatePicker
                                                    onOpen={() => this.setDatepickerOpen(true)}
                                                    onClose={() => this.setDatepickerOpen(false)}
                                                    open={this.state.isDatepickerOpen}
                                                    label="Date"
                                                    inputProps={
                                                        {
                                                            style: {
                                                                padding: 12,
                                                            }
                                                        }
                                                    }
                                                    TextFieldComponent={() => ''}
                                                    value={date || null}
                                                    onChange={this.handleDateChange}
                                                    showTodayButton
                                                    autoOk
                                                />
                                            </div>
                                        </div>
                                    }
                                    {viewing === "overview" && teams.length > 0 && team &&
                                        <React.Fragment>
                                            <div className="w-full">
                                                <div className="w-full">
                                                    <div className="w-full flex justify-between px-12" style={{ borderBottom: '1px solid lightgrey' }}>
                                                        <div className="w-full flex mb-4 pt-8 truncate">
                                                            {(selTech || selectedTeam) ?
                                                                <Icon onClick={() => this.setState({ selectedTech: null, selectedLeadTeam: null, selectedTeam: (selectedTech ? selectedTeam : null) })} style={{ marginLeft: -6 }} color="primary" className="mr-6 align-middle text-32 font-200 clickable rounded-full">keyboard_arrow_left</Icon> :
                                                                ''
                                                            }
                                                            {!selTech ?
                                                                <Avatar className="mr-8 align-middle w-24 h-24 mt-4" src={team.Data.Avatar ? getProfileImage(`Co=${team.Co}&ID=${team.Data.Avatar}&Thumb=true` || teamAvatar) : teamAvatar} /> :
                                                                <Avatar className="mr-8 align-middle w-24 h-24 mt-4" src={selTech.Data.Avatar ? getProfileImage(`Co=${selTech.Co}&ID=${selTech.Data.Avatar}&Thumb=true` || avatar) : avatar} />}
                                                            {!selTech ?
                                                                <TextField
                                                                    className="mt-2"
                                                                    name="selectedTeam"
                                                                    id="selectedTeam"
                                                                    value={selectedTeam || 0}
                                                                    margin="dense"
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        className: "text-20 font-600"
                                                                    }}
                                                                    onChange={this.handleChange}
                                                                    select
                                                                >
                                                                    <MenuItem value={0}>
                                                                        All Teams
                                                                    </MenuItem>
                                                                    {teams.map((tm) =>
                                                                        <MenuItem key={tm.Team} value={tm.Team}>
                                                                            {tm.Name}
                                                                        </MenuItem>
                                                                    )}
                                                                </TextField> :
                                                                <TextField
                                                                    className="mt-2"
                                                                    name="selectedTech"
                                                                    id="selectedTech"
                                                                    value={selectedTech || ''}
                                                                    margin="dense"
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        className: "text-20 font-600"
                                                                    }}
                                                                    onChange={this.handleChange}
                                                                    select
                                                                >
                                                                    {team.Data.Members.map((tm) => {
                                                                        const tech = _.find(technicians, { Technician: tm.TeamMember });
                                                                        if (tech) {
                                                                            return (
                                                                                <MenuItem key={tech.Technician} value={tech.Technician}>
                                                                                    {`${tech.FirstName} ${tech.LastName}`}
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    }
                                                                    )}
                                                                </TextField>
                                                            }
                                                        </div>
                                                        {selTech &&
                                                            <div className="flex md:pr-6">
                                                                {this.state.techView !== "team" &&
                                                                    <>
                                                                        <div onClick={() => this.setState({ techView: null })} className={classNames("w-56 h-48 mt-2 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                            <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{trips.length}</Typography>
                                                                            <Typography className={classNames("w-full text-center text-10 font-bold", !techView && classes.selectedView)}>Trip{trips.length !== 1 ? 's' : ''}</Typography>
                                                                        </div>
                                                                        <div onClick={() => this.setState({ techView: "timecard" })} className={classNames("w-56 h-48 mt-2 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                            <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{Math.round((hours + Number.EPSILON) * 100) / 100}</Typography>
                                                                            <Typography className={classNames("w-full text-center text-10 font-bold", techView === 'timecard' && classes.selectedView)}>Hour{hours !== 1 ? 's' : ''}</Typography>
                                                                        </div>
                                                                        <div onClick={() => this.setState({ techView: "invoice" })} className={classNames("w-56 h-48 mt-2 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2 }}>
                                                                            <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{invoices.length}</Typography>
                                                                            <Typography className={classNames("w-full text-center text-10 font-bold", techView === 'invoice' && classes.selectedView)}>Invoice{invoices.length !== 1 ? 's' : ''}</Typography>
                                                                        </div>
                                                                        {techTeams.length > 0 &&
                                                                            <div onClick={(e) => { techTeams.length > 0 && this.setState({ techView: "team", selectedLeadTeam: null }); e.stopPropagation() }} className={classNames(`w-56 h-48 mt-2${techTeams.length > 0 ? " clickable" : ""}`, classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderLeft: '1px dotted lightgrey' }}>
                                                                                <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{techTeams.length}</Typography>
                                                                                <Typography className="w-full text-center text-10 font-bold">Team{techTeams.length !== 1 ? 's' : ''}</Typography>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                        {lead && !selTech &&
                                                            <div className="flex sm:w-full justify-end mt-12">
                                                                <Avatar src={lead.Data.Avatar ? getProfileImage(`Co=${lead.Co}&ID=${lead.Data.Avatar}&Thumb=true` || 'assets/images/avatars/profile.jpg') : avatar} className="mr-6 align-middle w-24 h-24 mt-2 " />
                                                                <Hidden xsDown><Typography className="text-12 mt-6 mr-6">{lead.FirstName} {lead.LastName}</Typography></Hidden>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="md:max-h-486 lg:max-h-570 overflow-y-auto p-12 pb-40 md:pb-24">
                                                        <Swipeable onSwipedLeft={weekly ? this.handleNextDate : this.handleSwipeDateLeft} onSwipedRight={weekly ? this.handlePrevDate : this.handleSwipeDateRight}>
                                                            {loading ?
                                                                <div className="w-full min-h-512 relative">
                                                                    <div style={{
                                                                        padding: 20,
                                                                        color: 'rgba(0, 0, 0, 0.5)',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        left: 0,
                                                                        right: 0,
                                                                        top: 0,
                                                                        bottom: 0,
                                                                        background: 'rgba(255, 255, 255, .65)',
                                                                        transition: 'all .3s ease',
                                                                        zIndex: 10,
                                                                        opacity: 1,
                                                                        pointerEvents: 'none',
                                                                    }}>
                                                                        <div className="loader stretchBar" style={{
                                                                            margin: 'auto',
                                                                            left: 0,
                                                                            right: 0,
                                                                            top: 0,
                                                                            bottom: 30,
                                                                            width: '100%',
                                                                            position: 'absolute',
                                                                        }}>
                                                                            <div className="rect1"></div>
                                                                            <div className="rect2"></div>
                                                                            <div className="rect3"></div>
                                                                            <div className="rect4"></div>
                                                                            <div className="rect5"></div><br />
                                                                            <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                <FuseAnimateGroup enter={{ animation }}>
                                                                    <div style={{ touchAction: 'pan-y' }} key={moment(weekly ? start : date).format('M-D-YYYY')}>
                                                                        {selTech && !techView ?
                                                                            <div className="w-full mb-12">
                                                                                <Typography className="mb-8 w-full"><Icon className="mr-6 align-middle text-20 mb-6" color="primary">today</Icon>Schedule</Typography>
                                                                                {
                                                                                    weekly && calWindow.map((value, index) => {
                                                                                        const dt = moment(value).format("M/D/YYYY")
                                                                                        const selTrips = _.filter(trips, (o) => (o.BoardTime ? moment.utc(o.BoardTime).local().format("M/D/YYYY") : moment.utc(o.ScheduledDate).format("M/D/YYYY")) === dt);
                                                                                        return <TechnicianSchedule date={moment(date).format('M/D/YYYY')} supervisor={true} technician={{ ...selTech }} noBack={true} selectTrip={this.selectTrip} onClick={() => this.setState({ ...this.state, selDate: selDate === dt ? null : dt, date: selDate === dt ? this.state.date : new Date(dt) })} key={value} data={{ Date: dt, trips: selTrips }} open={selDate === dt} />
                                                                                    })
                                                                                }
                                                                                {/* {weekly && selDate &&
                                                                                    <TechnicianSchedule date={moment(date).format('M/D/YYYY')} supervisor={true} technician={{ ...selTech }} selectTrip={this.selectTrip} onClick={() => this.setState({ ...this.state, selDate: null })} key={selDate} data={{ Date: selDate, trips: _.filter(trips, (o) => moment.utc(o.BoardTime).local().format("M/D/YYYY") === selDate) }} open={true} />
                                                                                } */}
                                                                                {!weekly &&
                                                                                    <TechnicianSchedule noBack={true} date={moment(date).format('M/D/YYYY')} supervisor={true} selectTrip={this.selectTrip} animation={animation} key={moment(date).format('M-D-YYYY')} data={{ Date: moment(date).format('M/D/YYYY'), trips }} open={true} />
                                                                                }
                                                                            </div>
                                                                            :
                                                                            (selTech && techView === 'timecard') ?
                                                                                <div className="w-full mb-12">
                                                                                    <Typography className="mb-8 w-full"><Icon className="mr-6 align-middle text-20 mb-6" color="primary">access_time</Icon>Timecard{weekly && !selDate ? 's' : ''}</Typography>
                                                                                    {
                                                                                        weekly && calWindow.map((value, index) => {
                                                                                            const dt = moment(value).format("M/D/YYYY")
                                                                                            const selTrips = _.filter(trips, (o) => moment.utc(o.BoardTime).local().format("M/D/YYYY") === dt);
                                                                                            const selTime = _.find(timecards, (o) => moment(o.Date).format("M/D/YYYY") === dt);
                                                                                            return <TechnicianTimecard supervisor={true} date={moment(date).format('M/D/YYYY')} technician={{ ...selTech }} noBack={true} onClick={() => this.setState({ ...this.state, selDate: selDate === dt ? null : dt, date: selDate === dt ? this.state.date : new Date(dt) })} key={value} trips={selTrips} data={{ Date: dt, timecard: { ...(selTime || { Date: moment(dt).format('M/D/YYYY'), Data: { Labor: [], Punch: [] } }) } }} open={selDate === dt} />
                                                                                        })
                                                                                    }
                                                                                    {/* {weekly && selDate &&
                                                                                        <TechnicianTimecard supervisor={true} onClick={() => this.setState({ ...this.state, selDate: null })} animation={animation} technician={{ ...selTech }} key={selDate} trips={_.filter(trips, (o) => moment.utc(o.BoardTime).local().format("M/D/YYYY") === selDate)} data={{ Date: selDate, timecard: { ...(_.find(timecards, (o) => moment(o.Date).format("M/D/YYYY") === selDate) || { Date: moment(selDate).format('M/D/YYYY'), Data: { Labor: [], Punch: [] } }) } }} open={true} />
                                                                                    } */}
                                                                                    {!weekly &&
                                                                                        <TechnicianTimecard supervisor={true} noBack={true} onClick={() => this.setState({ ...this.state, tcView: null, schedView: null })} animation={animation} technician={{ ...selTech }} key={moment(date).format('M-D-YYYY')} trips={trips} data={{ Date: moment(date).format('M/D/YYYY'), timecard: { ...(_.find(timecards, (o) => moment(o.Date).format("M/D/YYYY") === moment(date).format('M/D/YYYY')) || { Date: moment(date).format('M/D/YYYY'), Data: { Labor: [], Punch: [] } }), } }} open={true} />
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                (selTech && techView === 'invoice') ?
                                                                                    <div className="w-full mb-12">
                                                                                        <Typography className="mb-8 w-full"><Icon className="mr-6 align-middle text-20 mb-6" color="primary">receipt</Icon>Invoices</Typography>
                                                                                        {invoices.map((inv) => {
                                                                                            const total = _.sumBy(_.filter(supervisor.data.InvoiceLines, (o) => o.Invoice === inv.Invoice), 'Amount');
                                                                                            return (
                                                                                                <Card onClick={() => this.props.openEditInvoiceDialog(inv)} className="mb-12 rounded-lg clickable" style={{ border: '1px solid #e1e1e1' }}>
                                                                                                    <CardHeader
                                                                                                        classes={{ action: "mt-12 mr-12 mb-0", root: "p-4 py-12", title: "max-w-96 sm:max-w-256 truncate", subheader: "text-grey text-10", avatar: "mr-6" }}
                                                                                                        title={inv.InvoiceNumber}
                                                                                                        subheader={moment(inv.InvoiceDate).format('M/D/YYYY')}
                                                                                                        avatar={
                                                                                                            <Icon color="action" className="ml-8 text-32">receipt</Icon>
                                                                                                        }
                                                                                                        action={
                                                                                                            <div className="text-12 font-bold">
                                                                                                                {this.formatDollars(total)}
                                                                                                            </div>
                                                                                                        }
                                                                                                    />
                                                                                                </Card>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                    :
                                                                                    (selTech && techView === 'team') ?
                                                                                        <div className="w-full mb-12">
                                                                                            {techTeams.length > 0 && (
                                                                                                <div className="w-full">
                                                                                                    <div className="w-full flex justify-between px-12" style={{ borderBottom: '1px solid lightgrey' }}>
                                                                                                        <div className="w-full flex mb-4 pt-8 truncate justify-end">
                                                                                                            <Avatar className="mr-8 align-middle w-24 h-24 mt-4" src={leadTeam.Data.Avatar ? getProfileImage(`Co=${leadTeam.Co}&ID=${leadTeam.Data.Avatar}&Thumb=true` || teamAvatar ): teamAvatar} /> :
                                                                                                            <TextField
                                                                                                                className="mt-2"
                                                                                                                name="selectedLeadTeam"
                                                                                                                id="selectedLeadTeam"
                                                                                                                value={selectedLeadTeam || 0}
                                                                                                                margin="dense"
                                                                                                                InputProps={{
                                                                                                                    disableUnderline: true,
                                                                                                                    className: "text-20 font-600"
                                                                                                                }}
                                                                                                                onChange={this.handleChange}
                                                                                                                select
                                                                                                            >
                                                                                                                <MenuItem value={0}>
                                                                                                                    All Teams
                                                                                                                </MenuItem>
                                                                                                                {techTeams.map((tm) =>
                                                                                                                    <MenuItem key={tm.Team} value={tm.Team}>
                                                                                                                        {tm.Name}
                                                                                                                    </MenuItem>
                                                                                                                )}
                                                                                                            </TextField>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {leadTeam.Data.Members.length > 0 &&
                                                                                                        <Typography className="mb-8 w-full"><Icon className="mr-6 align-middle text-20 mb-6" color="primary">people</Icon>Team Members</Typography>
                                                                                                    }

                                                                                                    {leadTeam.Data.Members.map((member, index) => {
                                                                                                        const tech = _.find(technicians, { Technician: member.TeamMember });
                                                                                                        const techTeams = _.filter(teams, { TeamLead: member.TeamMember });
                                                                                                        const labor = _.filter(supervisor.data.Timecards, (o) => o.Employee === tech.Employee && (weekly || moment(o.Date).format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
                                                                                                        const hours = _.sumBy(_.flatten(_.map(labor, _.property('Data.Labor'))), 'Qty');
                                                                                                        const trips = _.filter(supervisor.data.Trips, (o) => o.Technician === tech.Technician && (weekly || moment.utc(o.BoardTime).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
                                                                                                        const invoices = _.uniqBy(_.filter(supervisor.data.InvoiceLines, (o) => o.LeadTechnician === tech.Technician && (weekly || moment(o.InvoiceDate).format("M-D-YYYY") === moment(date).format("M-D-YYYY"))), 'Invoice');

                                                                                                        return (
                                                                                                            <Card onClick={() => this.setState({ selectedTech: tech.Technician, techView: null })} className="mb-12 rounded-lg clickable" style={{ border: '1px solid #e1e1e1' }}>
                                                                                                                <CardHeader
                                                                                                                    classes={{ action: classes.action, root: "p-4 py-12", avatar: "mr-6", title: "max-w-96 sm:max-w-256 truncate", subheader: "max-w-96 sm:max-w-256 md:max-w-384 overflow-x-auto" }}
                                                                                                                    title={`${tech.FirstName} ${tech.LastName}`}
                                                                                                                    subheader={
                                                                                                                        <div className="flex" onTouchMove={(e) => { e.stopPropagation(); e.preventDefault() }}>
                                                                                                                            {(techTeams.length > 0 && tech.Technician !== selectedTech) ?
                                                                                                                                techTeams.map((tm) => <Chip size="small" color="primary" className="h-16 text-10 mr-4 rounded" label={tm.Name} />)
                                                                                                                                :
                                                                                                                                <Chip size="small" className="h-16 text-10 mr-4 rounded" style={{ backgroundColor: "#5F5F5F", color: "#FFFFFF" }} label="Individual" />
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    avatar={
                                                                                                                        <Avatar className="ml-4 align-middle w-32 h-32" src={tech.Data.Avatar ? getProfileImage(`Co=${tech.Co}&ID=${tech.Data.Avatar}&Thumb=true` || 'assets/images/avatars/profile.jpg' ): avatar} />
                                                                                                                    }
                                                                                                                    action={
                                                                                                                        <div className="flex pr-4">
                                                                                                                            <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: null }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                                                                                <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{trips.length}</Typography>
                                                                                                                                <Typography className="w-full text-center text-10 font-bold">Trip{trips.length !== 1 ? 's' : ''}</Typography>
                                                                                                                            </div>
                                                                                                                            <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: "timecard" }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                                                                                <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{Math.round((hours + Number.EPSILON) * 100) / 100}</Typography>
                                                                                                                                <Typography className="w-full text-center text-10 font-bold">Hour{hours !== 1 ? 's' : ''}</Typography>
                                                                                                                            </div>
                                                                                                                            <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: "invoice" }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                                                                                <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{invoices.length}</Typography>
                                                                                                                                <Typography className="w-full text-center text-10 font-bold">Invoice{invoices.length !== 1 ? 's' : ''}</Typography>
                                                                                                                            </div>

                                                                                                                            {techTeams.length > 0 && tech.Technician !== selectedTech ?
                                                                                                                                <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: "team", selectedLeadTeam: null }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2 }}>
                                                                                                                                    <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{techTeams.length}</Typography>
                                                                                                                                    <Typography className="w-full text-center text-10 font-bold">Team{techTeams.length !== 1 ? 's' : ''}</Typography>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <div onClick={(e) => e.stopPropagation()} className="w-56 h-48 cursor-default" style={{ color: '#3f3f3f', padding: 2 }}>
                                                                                                                                    <div className="flex w-full h-full justify-center items-center">
                                                                                                                                        <Icon>account_circle</Icon>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                    }
                                                                                                                />
                                                                                                            </Card>
                                                                                                        )
                                                                                                    })}

                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        :
                                                                                        team.Data.Members.length > 0 &&
                                                                                        <div className="w-full">
                                                                                            <Typography className="mb-8 w-full"><Icon className="mr-6 align-middle text-20 mb-6" color="primary">people</Icon>Team Members</Typography>
                                                                                            {team.Data.Members.map((member, index) => {
                                                                                                const tech = _.find(technicians, { Technician: member.TeamMember });
                                                                                                const techTeams = _.filter(teams, { TeamLead: member.TeamMember });
                                                                                                const labor = _.filter(supervisor.data.Timecards, (o) => o.Employee === tech.Employee && (weekly || moment(o.Date).format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
                                                                                                const hours = _.sumBy(_.flatten(_.map(labor, _.property('Data.Labor'))), 'Qty');
                                                                                                const trips = _.filter(supervisor.data.Trips, (o) => o.Technician === tech.Technician && (weekly || moment.utc(o.BoardTime).local().format("M-D-YYYY") === moment(date).format("M-D-YYYY")));
                                                                                                const invoices = _.uniqBy(_.filter(supervisor.data.InvoiceLines, (o) => o.LeadTechnician === tech.Technician && (weekly || moment(o.InvoiceDate).format("M-D-YYYY") === moment(date).format("M-D-YYYY"))), 'Invoice');
                                                                                                if (tech) {
                                                                                                    window["warn"]('Team Member:', tech);
                                                                                                    return (
                                                                                                        <Card onClick={() => this.setState({ selectedTech: tech.Technician, techView: null })} className="mb-12 rounded-lg clickable" style={{ border: '1px solid #e1e1e1' }}>
                                                                                                            <CardHeader
                                                                                                                classes={{ action: classes.action, root: "p-4 py-12", avatar: "mr-6", title: "max-w-96 sm:max-w-256 truncate", subheader: "max-w-96 sm:max-w-256 md:max-w-384 overflow-x-auto" }}
                                                                                                                title={`${tech.FirstName} ${tech.LastName}`}
                                                                                                                subheader={
                                                                                                                    <div className="flex" onTouchMove={(e) => { e.stopPropagation(); e.preventDefault() }}>
                                                                                                                        {techTeams.length > 0 ?
                                                                                                                            techTeams.map((tm) => <Chip size="small" color="primary" className="h-16 text-10 mr-4 rounded" label={tm.Name} />)
                                                                                                                            :
                                                                                                                            <Chip size="small" className="h-16 text-10 mr-4 rounded" style={{ backgroundColor: "#5F5F5F", color: "#FFFFFF" }} label="Individual" />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                avatar={
                                                                                                                    <Avatar className="ml-4 align-middle w-32 h-32" src={tech.Data.Avatar ? getProfileImage(`Co=${tech.Co}&ID=${tech.Data.Avatar}&Thumb=true` || 'assets/images/avatars/profile.jpg' ): avatar} />
                                                                                                                }
                                                                                                                action={
                                                                                                                    <div className="flex pr-4">
                                                                                                                        <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: null }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                                                                            <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{trips.length}</Typography>
                                                                                                                            <Typography className="w-full text-center text-10 font-bold">Trip{trips.length !== 1 ? 's' : ''}</Typography>
                                                                                                                        </div>
                                                                                                                        <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: "timecard" }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                                                                            <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{Math.round((hours + Number.EPSILON) * 100) / 100}</Typography>
                                                                                                                            <Typography className="w-full text-center text-10 font-bold">Hour{hours !== 1 ? 's' : ''}</Typography>
                                                                                                                        </div>
                                                                                                                        <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: "invoice" }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2, borderRight: '1px dotted lightgrey' }}>
                                                                                                                            <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{invoices.length}</Typography>
                                                                                                                            <Typography className="w-full text-center text-10 font-bold">Invoice{invoices.length !== 1 ? 's' : ''}</Typography>
                                                                                                                        </div>

                                                                                                                        {techTeams.length > 0 ?
                                                                                                                            <div onClick={(e) => { this.setState({ selectedTech: tech.Technician, techView: "team", selectedLeadTeam: null }); e.stopPropagation() }} className={classNames("w-56 h-48 clickable", classes.tabs)} style={{ color: '#3f3f3f', padding: 2 }}>
                                                                                                                                <Typography className="w-full text-center text-16 font-600 mt-4" style={{ height: 20 }}>{techTeams.length}</Typography>
                                                                                                                                <Typography className="w-full text-center text-10 font-bold">Team{techTeams.length !== 1 ? 's' : ''}</Typography>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div onClick={(e) => e.stopPropagation()} className="w-56 h-48 cursor-default" style={{ color: '#3f3f3f', padding: 2 }}>
                                                                                                                                <div className="flex w-full h-full justify-center items-center">
                                                                                                                                    <Icon>account_circle</Icon>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        }

                                                                                                                    </div>
                                                                                                                }
                                                                                                            />
                                                                                                        </Card>
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                            )}
                                                                                        </div>
                                                                        }
                                                                        <div className="w-full mb-12">
                                                                            <Typography className="mb-8 w-full"><Icon className="mr-6 align-middle text-20 mb-6" color="primary">dashboard</Icon>{weekly ? 'Weekly' : 'Daily'} Numbers</Typography>
                                                                            <Card className="mb-12 rounded-lg" style={{ border: '1px solid #e1e1e1' }}>
                                                                                <div className="w-full sm:flex">
                                                                                    <div className="w-full sm:w-1/2 sm:border-r-1">
                                                                                        <div className="w-full min-h-128 relative border-b-1 clickable">
                                                                                            <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(billed)}</Typography>
                                                                                                <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Revenue</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full min-h-256 relative clickable">
                                                                                            <div className="w-full absolute pin-t pin-b pin-l pin-r m-auto max-w-256 max-h-256 sm:max-w-192 sm:max-h-192 md:max-h-160 md:max-w-160 lg:max-w-256 lg:max-h-256">
                                                                                                <div className="w-full relative" style={{ paddingTop: '100%', transform: 'translate(0, 0%)' }}>
                                                                                                    <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset', color: '#f1f1f1' }} className="pin-l pin-r pin-t pin-b absolute" value={100} />
                                                                                                    <CircularProgress variant="determinate" style={{ width: '80%', margin: '10%', height: 'unset' }} className="pin-l pin-r pin-t pin-b absolute" value={margin} />
                                                                                                </div>
                                                                                                <div className={`pin-l pin-r pin-t pin-b absolute m-auto mb-20`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                                    <Typography variant="caption" component="div" color="textSecondary" className="text-24 md:text-20 lg:text-24">{`${margin.toFixed(0)}%`}</Typography>
                                                                                                </div>
                                                                                                <div className={`pin-l pin-r pin-t pin-b absolute m-auto mt-16`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                                    <Typography variant="caption" component="div" color="textSecondary" className="text-10">Gross Margin</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full min-h-128 relative border-t-1 xs:border-b-1 clickable">
                                                                                            <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(cost)}</Typography>
                                                                                                <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Cost</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full">
                                                                                        <div className="w-full sm:flex">
                                                                                            <div className="w-full min-h-128 flex border-b-1">
                                                                                                <div className="w-full relative border-r-1 clickable">
                                                                                                    <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                        <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{wos}</Typography>
                                                                                                        <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Work Order{wos != 1 ? 's' : ''}</Typography>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="w-full relative clickable">
                                                                                                    <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                        <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{invoices.length}</Typography>
                                                                                                        <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Invoice{invoices.length != 1 ? 's' : ''}</Typography>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-full min-h-128 relative border-b-1 sm:border-l-1 clickable">
                                                                                                <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                    <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars((invoices.length > 0 ? (billed / invoices.length) : 0))}</Typography>
                                                                                                    <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Avg Invoice</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full min-h-256 relative clickable">
                                                                                            <HighchartsReact
                                                                                                highcharts={Highcharts}
                                                                                                options={{
                                                                                                    chart: {
                                                                                                        type: 'column'
                                                                                                    },
                                                                                                    colors: ['#4dbce9', '#d1e751', '#3f3f3f', '#87efff', '#ffff83', '#5f5f5f', '#4dbce9', '#9db516', '#7f7f7f'],
                                                                                                    title: '',
                                                                                                    subtitle: {
                                                                                                        text: 'Sales & Revenue'
                                                                                                    },
                                                                                                    xAxis: {
                                                                                                        categories: _.map(leadTeam.Data.Members, (o) => { var tech = _.find(technicians, { Technician: o.TeamMember }); return `${tech.FirstName} ${tech.LastName[0]}` })
                                                                                                    },
                                                                                                    yAxis: {
                                                                                                        min: 0,
                                                                                                        gridLineWidth: 0,
                                                                                                        title: {
                                                                                                            text: ''
                                                                                                        },
                                                                                                        labels: {
                                                                                                            enabled: false
                                                                                                        }
                                                                                                    },
                                                                                                    plotOptions: {
                                                                                                        series: {
                                                                                                            borderRadiusTopLeft: '50%',
                                                                                                            borderRadiusTopRight: '50%',
                                                                                                            borderRadiusBottomLeft: '50%',
                                                                                                            borderRadiusBottomRight: '50%',
                                                                                                            maxPointWidth: 24,
                                                                                                        }
                                                                                                    },
                                                                                                    series: [{
                                                                                                        name: 'Sales',
                                                                                                        data: _.map(leadTeam.Data.Members, (o) => { return _.sumBy(_.filter(estimates, { IssuedBy: o.TeamMember }), 'PriceTotal') || 0 })

                                                                                                    }, {
                                                                                                        name: 'Revenue',
                                                                                                        data: _.map(leadTeam.Data.Members, (o) => { return _.sumBy(_.filter(invoiceLines, { LeadTechnician: o.TeamMember }), 'Amount') || 0 })

                                                                                                    }],
                                                                                                    credits: {
                                                                                                        enabled: false
                                                                                                    },
                                                                                                }}
                                                                                                containerProps={{
                                                                                                    style: {
                                                                                                        height: '256px'
                                                                                                    }
                                                                                                }}
                                                                                            // callback={(chart) => this.setState({ ...this.state, chart })}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="w-full sm:flex">
                                                                                            <div className="w-full min-h-128 relative border-t-1 clickable">
                                                                                                <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                    <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{this.formatDollars(sold)}</Typography>
                                                                                                    <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Sold</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-full min-h-128 flex border-t-1 sm:border-l-1">
                                                                                                <div className="w-full relative border-r-1 clickable">
                                                                                                    <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                        <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{estimates.length}</Typography>
                                                                                                        <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Estimate{estimates.length != 1 ? 's' : ''}</Typography>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="w-full relative clickable">
                                                                                                    <div className="w-full justify-center absolute pin-t pin-b m-auto h-56">
                                                                                                        <Typography className="w-full text-center text-20 font-700 text-grey-darker mt-4" style={{ height: 32 }}>{sales}</Typography>
                                                                                                        <Typography className="w-full text-center text-10 sm:text-12 md:text-10 lg:text-12">Sale{sales != 1 ? 's' : ''}</Typography>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Card>
                                                                        </div>
                                                                    </div>
                                                                </FuseAnimateGroup>
                                                            }
                                                        </Swipeable>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-between absolute p-12 py-6 pin-b pin-l pin-r" style={{ borderTop: '1px solid lightgrey', backgroundColor: '#fafafa' }}>
                                                <div className="w-full flex">
                                                    <Typography className="text-12 text-grey-dark mt-4 font-600">{team.Data.Members.length} Members</Typography>
                                                </div>
                                                <div className="w-full flex justify-end">
                                                    {team.Data.Members.map((member, index) => {
                                                        const tech = _.find(technicians, { Technician: member.TeamMember });
                                                        if (tech && index < 4 || team.Data.Members.length < 6) {
                                                            return (
                                                                <Avatar className="ml-4 align-middle w-24 h-24" src={tech.Data.Avatar ? getProfileImage(`Co=${tech.Co}&ID=${tech.Data.Avatar}&Thumb=true` || 'assets/images/avatars/profile.jpg' ): avatar} />
                                                            );
                                                        }
                                                    }
                                                    )}
                                                    {team.Data.Members.length > 5 &&
                                                        <Avatar className="ml-4 align-middle w-24 h-24 text-11 bg-grey-light text-grey-darker">+{team.Data.Members.length - 4}</Avatar>
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {viewing === "dashboard" &&
                                        <div className={classNames(classes.root, "border-0 mt-4")} style={{ maxHeight: 'unset', borderTop: '1px solid lightgrey' }}>
                                            <DashboardSelector data={team} form="team" />
                                        </div>
                                    }
                                </div>
                                {userGPS && ["overview", "timecard"].indexOf(viewing) > -1 &&
                                    <div className="min-w-56 lg:min-w-72 min-h-56 lg:min-h-72 mb--12 md:mb-0 md:h-616 lg:h-700 rounded-t md:rounded-none" style={{ backgroundColor: '#3f3f3f', color: '#fff' }}>
                                        <div className="w-full h-56 md:h-72">
                                            {start &&
                                                <div className="flex md:flex-wrap border-b-1 md:border-b-none md:border-r-1">
                                                    {calWindow.map((dt, index) => {
                                                        const day = days[dt.getDay()];
                                                        const fmt = moment(dt).format('M/D/YYYY');
                                                        const sel = moment(date).format('M/D/YYYY');
                                                        return (
                                                            <div onClick={() => this.handleDateClick(dt)} className={classNames("w-full cursor-pointer relative h-56 md:h-88 lg:h-100 border-r-dotted md:border-r-none md:border-b-dotted")} style={{ background: 'transparent' }}>
                                                                <div className={`pin-t pin-b pin-l pin-r m-auto absolute w-auto ${fmt !== sel && "md:h-48 pt-8"}`}>
                                                                    {
                                                                        fmt !== sel &&
                                                                        <Typography style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold', color: 'grey' }} className="w-full text-center">{day ? day.substr(0, 3) : ''}</Typography>
                                                                    }
                                                                    <div className="w-full text-center text-grey relative">
                                                                        {
                                                                            fmt === sel ?
                                                                                <div className="absolute pin-l pin-r m-auto p-4 font-bold p-6 mt--4 md:mt-0 h-64 md:h-88 lg:h-100 w-64 md:w-88 lg:w-100 xs:ml--2 md:ml--17 lg:ml--14" style={{ zIndex: 999, background: '#3f3f3f', borderRadius: '50%' }}>
                                                                                    <div className="relative" style={{ borderRadius: '50%', width: '100%', height: '100%', backgroundColor: '#d1e751', color: '#333' }}>
                                                                                        {/* {dt.getDate()} */}
                                                                                        <SPRoundIcon onClick={this.handleRefresh} className={classNames("text-52 md:text-76 lg:text-88", loading && "spin-slow")} style={{ color: "#3f3f3f" }} />
                                                                                        {/* <div className="pin-t pin-b pin-l pin-r m-auto absolute w-48 h-48">
                                                                                            {dt.getDate()}
                                                                                        </div> */}
                                                                                    </div>
                                                                                </div> :
                                                                                <div className="absolute pin-l pin-r m-auto h-28 w-28 p-4 font-bold" style={{ background: 'transparent', color: 'grey' }}>
                                                                                    {dt.getDate()}
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {userGPS && ["overview", "timecard"].indexOf(viewing) > -1 &&
                                    <div className="w-full relative">
                                        <div className="mt-12 md:mt-0">
                                            <div
                                                // onScroll={(e) => e.preventDefault()}
                                                className="rounded rounded-t-none md:rounded-tr md:rounded-l-none h-560 md:h-616 lg:h-700"
                                                style={{
                                                    width: '100%',
                                                    backgroundColor: '#eeeeee',
                                                    // marginBottom: 8,
                                                    overflow: 'hidden'
                                                }}>
                                                <GoogleMap
                                                    options={this.getMapOptions}
                                                    gestureHandling="cooperative"
                                                    bootstrapURLKeys={{
                                                        key: googleMapsApiKey
                                                    }}
                                                    defaultZoom={12}
                                                    defaultCenter={[userGPS.Latitude, userGPS.Longitude]}
                                                    center={(selectedTrip && selectedTrip.Data.WorkOrder.Data.Site.Latitude && selectedTrip.Data.WorkOrder.Data.Site.Longitude) ? [Number(selectedTrip.Data.WorkOrder.Data.Site.Latitude), Number(selectedTrip.Data.WorkOrder.Data.Site.Longitude)] : [userGPS.Latitude, userGPS.Longitude]}
                                                >
                                                    <Marker
                                                        style={{ width: 32, height: 32, borderRadius: '50%', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', cursor: 'pointer', position: 'absolute', transform: 'rotate(-45deg)' }}
                                                        icon={user.Data && user.Data.Avatar ? getProfileImage(`ID=${user.Data.Avatar}&Thumb=true` ): avatar}
                                                        text={"Your Location"}
                                                        lat={userGPS.Latitude}
                                                        lng={userGPS.Longitude}
                                                        type="tech"
                                                    />
                                                    {trips.map((trip, index) => {
                                                        const { Data } = trip;
                                                        if (Data) {
                                                            const { WorkOrder } = Data;
                                                            if (WorkOrder && WorkOrder.Data) {
                                                                const { Site } = WorkOrder.Data;
                                                                if (Site && Site.Latitude && Site.Longitude) {
                                                                    return (
                                                                        <Marker
                                                                            key={trip.ID}
                                                                            heading={0}
                                                                            style={{ margin: 0, padding: 0, width: 32, height: 42, marginTop: -42, marginLeft: -16, cursor: 'pointer' }}
                                                                            icon="assets/images/logos/SPMarker_Gradient.png"
                                                                            text={<div className="text-center">Work Order #{trip.WorkOrder} Trip #{trip.Trip} - {moment.utc(trip.BoardTime).local().format('M/D/YYYY')}<br />{Site.Description}</div>}
                                                                            lat={Number(Site.Latitude)}
                                                                            lng={Number(Site.Longitude)}
                                                                            badge={selTech ? (index + 1) : undefined}
                                                                            type="site"
                                                                            status={trip.Status}
                                                                            onClick={() => { if (trip.Status > 0) { openEditWorkOrderDialog(trip, trip.Trip) } }}
                                                                        />
                                                                    )
                                                                }
                                                            }
                                                        }
                                                    })
                                                    }
                                                </GoogleMap>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </FuseAnimate >
                }
            </Media >
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        getSupervisorData,
        openEditInvoiceDialog,
        openEditWorkOrderDialog,
    }, dispatch);
}

function mapStateToProps({ spReducers, supervisorApp }) {
    return {
        loading: spReducers.supervisor.loading,
        timecards: spReducers.supervisor.timecards,
        searchText: supervisorApp.supervisor.searchText,
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        date: spReducers.supervisor.date,
        start: spReducers.supervisor.start,
        supervisor: spReducers.supervisor,
        gps: spReducers.gps,
        technicians: spReducers.technicians,
        technician: spReducers.technician.technician,
        employees: spReducers.employees
        // searchResults: supervisorApp.supervisor.supervisorDialog.data && supervisorApp.supervisor.supervisorDialog.data.Data && supervisorApp.supervisor.supervisorDialog.data.Data.searchResults ? supervisorApp.supervisor.supervisorDialog.data.Data.searchResults : null
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SupervisorProfile)));
