import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import _ from '@lodash';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class UserDashboardsList extends Component {

    state = {
        selectedUserRolesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedUserRoleMenu = (event) => {
        this.setState({ selectedUserRolesMenu: event.currentTarget });
    };

    closeSelectedUserRolesMenu = () => {
        this.setState({ selectedUserRolesMenu: null });
    };

    render() {
        const { classes, dashboards, dashboardsList } = this.props;
        const data = dashboards;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Dashboard Access</Typography>
                    <ReactTable
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    this.props.onSelected(rowInfo.original);
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Dashboard",
                                accessor: "Dashboard",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                Cell: row => _.find(dashboardsList, { Dashboard: row.original.Dashboard }).Name,
                                width: 256,
                            },
                            {
                                Header: "Access Level",
                                accessor: "AccessLevel",
                                Cell: row => {
                                    return row.original.AccessLevel === "R" ? "Read-Only" : row.original.AccessLevel  === "E" ? "Read / Write" : "Full Access";
                                },
                                className: "justify-center",
                                width: 192
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Dashboards found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        dashboardsList: spReducers.reporting.dashboards,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDashboardsList)));
