import * as Actions from '../../actions/fuse/index';

const initialState = {
    state  : null,
    options: {
        anchorOrigin    : {
            vertical  : 'top',
            horizontal: 'center'
        },
        autoHideDuration: 6000,
        message: '',
        subtitle: '',
        notification: null,
        variant: 'info',
    }
};

const notification = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SHOW_NOTIFICATION:
        {
            return {
                ...state,
                state  : true,
                options: {
                    ...state.options,
                    message: '',
                    subtitle: '',
                    notification: null,
                    variant: 'info',
                    ...action.options
                }
            };
        }
        case Actions.HIDE_NOTIFICATION:
        {
            return {
                ...state,
                state: null
            };
        }
        default:
        {
            return state;
        }
    }
};

export default notification;