import {combineReducers} from 'redux';
import purchaseOrderLines from './purchase-order-lines.reducer';
import user from './user.reducer';

const purchaseOrderLinesAppReducers = combineReducers({
    purchaseOrderLines,
    user
});

export default purchaseOrderLinesAppReducers;
