import _ from '@lodash';
import moment from 'moment';

export function updateInList(data, item, action, match, merge) {
    const list = _.cloneDeepWith(data);
    if (list && item) {
        const index = _.findIndex(list, match || { ID: item.ID });
        switch (action) {
            case 'ADD':
                {
                    if (index > -1) {
                        list.splice(index, 1, item);
                    } else {
                        list.push(item);
                    }
                }
                break;
            case 'UPDATE':
                {
                    if (index > -1) {
                        list.splice(index, 1, merge && list[index].Data && item.Data ? { ...item, Data: { ...list[index].Data, ...item.Data } } : item);
                    } else {
                        list.push(item);
                    }
                }
                break;
            case 'REMOVE':
                {
                    if (index > -1) {
                        list.splice(index, 1);
                    }
                }
                break;
            case 'MERGE':
                {
                    if (index > -1) {
                        list.splice(index, 1, { ...list[index], ...item, Data: list[index].Data && item.Data ? { ...list[index].Data, ...item.Data } : list[index].Data, });
                    }
                }
                break;
        }
    }
    return list;
}

export function omitDates(obj) {
    if (obj) {
        if (Array.isArray(obj)) {
            return _.map(obj, (o) => omitDates(o));
        } else {
            if (typeof (obj) === 'object') {
                let newObj = {};
                Object.keys(obj).map((key) => {
                    if (key.indexOf('Date') < 0 && key.indexOf('Time') < 0) {
                        newObj[key] = omitDates(obj[key]);
                    }
                });
                return newObj;
            } else {
                return obj;
            }
        }
    } else {
        return obj;
    }
}

export function formatPhone(raw) {

    const stripped = raw ? raw.replace(/[^0-9]/g, '') : '';
    let formatted = stripped.slice(0, 10);
    let ext = stripped.slice(10, 90);

    if (formatted.length > 6) {
        formatted = `(${formatted.slice(0, 3)}) ${formatted.slice(
            3,
            6
        )}-${formatted.slice(6)}`;
    } else if (formatted.length > 3) {
        formatted = `(${formatted.slice(0, 3)}) ${formatted.slice(3)}`;
    } else if (formatted.length >= 1) {
        formatted = `(${formatted}`;
    }

    formatted = `${formatted}${ext && ext.length > 0 ? ` Ext #${ext}` : ''}`;
    
    return {
        raw,
        formatted,
        stripped
    }
};