import axios from 'axios';
import { getUserData } from 'main/content/apps/vendors/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_VENDORS = '[VENDORS APP] GET VENDORS';
export const SET_SEARCH_TEXT = '[VENDORS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_VENDORS = '[VENDORS APP] TOGGLE IN SELECTED VENDORS';
export const SELECT_ALL_VENDORS = '[VENDORS APP] SELECT ALL VENDORS';
export const DESELECT_ALL_VENDORS = '[VENDORS APP] DESELECT ALL VENDORS';
export const OPEN_NEW_VENDOR_DIALOG = '[VENDORS APP] OPEN NEW VENDOR DIALOG';
export const CLOSE_NEW_VENDOR_DIALOG = '[VENDORS APP] CLOSE NEW VENDOR DIALOG';
export const OPEN_EDIT_VENDOR_DIALOG = '[VENDORS APP] OPEN EDIT VENDOR DIALOG';
export const UPDATE_EDIT_VENDOR_DIALOG = '[VENDORS APP] UPDATE EDIT VENDOR DIALOG';
export const CLOSE_EDIT_VENDOR_DIALOG = '[VENDORS APP] CLOSE EDIT VENDOR DIALOG';
export const ADD_VENDOR = '[VENDORS APP] ADD VENDOR';
export const UPDATE_VENDOR = '[VENDORS APP] UPDATE VENDOR';
export const REMOVE_VENDOR = '[VENDORS APP] REMOVE VENDOR';
export const ADD_VENDOR_MATERIAL = '[VENDORS APP] ADD VENDOR MATERIAL';
export const UPDATE_VENDOR_MATERIAL = '[VENDORS APP] UPDATE VENDOR MATERIAL';
export const REMOVE_VENDOR_MATERIAL = '[VENDORS APP] REMOVE VENDOR MATERIAL';
export const REMOVE_VENDORS = '[VENDORS APP] REMOVE VENDORS';
export const TOGGLE_STARRED_VENDOR = '[VENDORS APP] TOGGLE STARRED VENDOR';
export const TOGGLE_STARRED_VENDORS = '[VENDORS APP] TOGGLE STARRED VENDORS';
export const SET_VENDORS_STARRED = '[VENDORS APP] SET VENDORS STARRED ';

export function getVendors(routeParams) {
    const request = axios.get('/api/vendors-app/vendors', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_VENDORS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedVendors(vendorId) {
    return {
        type: TOGGLE_IN_SELECTED_VENDORS,
        vendorId
    }
}


export function selectAllVendors() {
    return {
        type: SELECT_ALL_VENDORS
    }
}

export function deSelectAllVendors() {
    return {
        type: DESELECT_ALL_VENDORS
    }
}


export function openNewVendorDialog(data) {
    return {
        type: OPEN_NEW_VENDOR_DIALOG,
        data
    }
}

export function closeNewVendorDialog() {
    return {
        type: CLOSE_NEW_VENDOR_DIALOG
    }
}

export function openEditVendorDialog(data) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.get(`${window["apiLocation"]}/api/Vendor?Co=${data.Co}&Vendor=${encodeURIComponent(data.Vendor)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_VENDOR_DIALOG,
                data: response.data
            })
        });
    }
}

export function updateEditVendorDialog(data) {
    return {
        type: UPDATE_EDIT_VENDOR_DIALOG,
        data
    }
}

export function closeEditVendorDialog() {
    return {
        type: CLOSE_EDIT_VENDOR_DIALOG
    }
}

export function addVendor(newVendor) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post(`${window["apiLocation"]}/api/Vendor`,
            newVendor
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_VENDOR,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Vendor #${response.data.Vendor} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditVendorDialog(response.data));
            })
        );
    };
}

export function updateVendor(vendor) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.put(`${window["apiLocation"]}/api/Vendor?Co=${encodeURIComponent(vendor.Co)}&Vendor=${encodeURIComponent(vendor.Vendor)}`,
            vendor
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_VENDOR,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Vendor #${vendor.Vendor} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditVendorDialog(response.data));
            })
        );
    };
}

export function removeVendor(vendor) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/Vendor?Co=${encodeURIComponent(vendor.Co)}&Vendor=${encodeURIComponent(vendor.Vendor)}`,
            vendor
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_VENDOR,
                    data: vendor
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Vendor #${vendor.Vendor} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function addVendorMaterial(newVendorMaterial) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post(`${window["apiLocation"]}/api/VendorMaterial`,
            newVendorMaterial
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_VENDOR_MATERIAL,
                    data: response.data
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Vendor Material #${response.data.Material} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function updateVendorMaterial(vendorMaterial) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.put(`${window["apiLocation"]}/api/VendorMaterial?Co=${encodeURIComponent(vendorMaterial.Co)}&Vendor=${vendorMaterial.Vendor}&Material=${encodeURIComponent(vendorMaterial.Material)}`,
            vendorMaterial
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_VENDOR_MATERIAL,
                    data: response.data
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Vendor Material #${vendorMaterial.Material} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}

export function removeVendorMaterial(vendorMaterial) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/VendorMaterial?Co=${encodeURIComponent(vendorMaterial.Co)}&Vendor=${vendorMaterial.Vendor}&Material=${encodeURIComponent(vendorMaterial.Material)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_VENDOR_MATERIAL,
                    data: vendorMaterial
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Vendor Material #${vendorMaterial.Material} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        );
    };
}


export function removeVendors(vendorIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post('/api/vendors-app/remove-vendors', {
            vendorIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_VENDORS
                }),
                dispatch({
                    type: DESELECT_ALL_VENDORS
                })
            ]).then(() => dispatch(getVendors(routeParams)))
        );
    };
}

export function toggleStarredVendor(vendorId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post('/api/vendors-app/toggle-starred-vendor', {
            vendorId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_VENDOR
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getVendors(routeParams)))
        );
    };
}

export function toggleStarredVendors(vendorIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post('/api/vendors-app/toggle-starred-vendors', {
            vendorIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_VENDORS
                }),
                dispatch({
                    type: DESELECT_ALL_VENDORS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getVendors(routeParams)))
        );
    };
}

export function setVendorsStarred(vendorIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post('/api/vendors-app/set-vendors-starred', {
            vendorIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_VENDORS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_VENDORS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getVendors(routeParams)))
        );
    };
}

export function setVendorsUnstarred(vendorIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().vendorsApp.vendors;

        const request = axios.post('/api/vendors-app/set-vendors-unstarred', {
            vendorIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_VENDORS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_VENDORS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getVendors(routeParams)))
        );
    };
}
