import axios from 'axios';
import { getUserData } from 'main/content/apps/fleet-vehicles/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_FLEET_VEHICLES = '[FLEET VEHICLES APP] GET FLEET VEHICLES';
export const SET_SEARCH_TEXT = '[FLEET VEHICLES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_FLEET_VEHICLES = '[FLEET VEHICLES APP] TOGGLE IN SELECTED FLEET VEHICLES';
export const SELECT_ALL_FLEET_VEHICLES = '[FLEET VEHICLES APP] SELECT ALL FLEET VEHICLES';
export const DESELECT_ALL_FLEET_VEHICLES = '[FLEET VEHICLES APP] DESELECT ALL FLEET VEHICLES';
export const OPEN_NEW_FLEET_VEHICLE_DIALOG = '[FLEET VEHICLES APP] OPEN NEW FLEET VEHICLE DIALOG';
export const CLOSE_NEW_FLEET_VEHICLE_DIALOG = '[FLEET VEHICLES APP] CLOSE NEW FLEET VEHICLE DIALOG';
export const OPEN_EDIT_FLEET_VEHICLE_DIALOG = '[FLEET VEHICLES APP] OPEN EDIT FLEET VEHICLE DIALOG';
export const UPDATE_EDIT_FLEET_VEHICLE_DIALOG = '[FLEET VEHICLES APP] UPDATE EDIT FLEET VEHICLE DIALOG';
export const CLOSE_EDIT_FLEET_VEHICLE_DIALOG = '[FLEET VEHICLES APP] CLOSE EDIT FLEET VEHICLE DIALOG';
export const ADD_FLEET_VEHICLE = '[FLEET VEHICLES APP] ADD FLEET VEHICLE';
export const UPDATE_FLEET_VEHICLE = '[FLEET VEHICLES APP] UPDATE FLEET VEHICLE';
export const REMOVE_FLEET_VEHICLE = '[FLEET VEHICLES APP] REMOVE FLEET VEHICLE';
export const REMOVE_FLEET_VEHICLES = '[FLEET VEHICLES APP] REMOVE FLEET VEHICLES';
export const TOGGLE_STARRED_FLEET_VEHICLE = '[FLEET VEHICLES APP] TOGGLE STARRED FLEET VEHICLE';
export const TOGGLE_STARRED_FLEET_VEHICLES = '[FLEET VEHICLES APP] TOGGLE STARRED FLEET VEHICLES';
export const SET_FLEET_VEHICLES_STARRED = '[FLEET VEHICLES APP] SET FLEET VEHICLES STARRED ';

export function getFleetVehicles(routeParams) {
    const request = axios.get('/api/fleet-vehicles-app/fleet-vehicles', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_FLEET_VEHICLES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedFleetVehicles(fleetVehicleId) {
    return {
        type: TOGGLE_IN_SELECTED_FLEET_VEHICLES,
        fleetVehicleId
    }
}


export function selectAllFleetVehicles() {
    return {
        type: SELECT_ALL_FLEET_VEHICLES
    }
}

export function deSelectAllFleetVehicles() {
    return {
        type: DESELECT_ALL_FLEET_VEHICLES
    }
}


export function openNewFleetVehicleDialog(data) {
    return {
        type: OPEN_NEW_FLEET_VEHICLE_DIALOG,
        data
    }
}

export function closeNewFleetVehicleDialog() {
    return {
        type: CLOSE_NEW_FLEET_VEHICLE_DIALOG
    }
}

export function openEditFleetVehicleDialog(data) {
    return {
        type: OPEN_EDIT_FLEET_VEHICLE_DIALOG,
        data
    }
}

export function updateEditFleetVehicleDialog(data) {
    return {
        type: UPDATE_EDIT_FLEET_VEHICLE_DIALOG,
        data
    }
}

export function closeEditFleetVehicleDialog() {
    return {
        type: CLOSE_EDIT_FLEET_VEHICLE_DIALOG
    }
}

export function addFleetVehicle(newFleetVehicle) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.post(`${window["apiLocation"]}/api/FleetVehicle`,
            newFleetVehicle
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_FLEET_VEHICLE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Fleet Vehicle #${response.data.FleetVehicle} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditFleetVehicleDialog(response.data));
            })
        );
    };
}

export function updateFleetVehicle(fleetVehicle) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.put(`${window["apiLocation"]}/api/FleetVehicle?Co=${encodeURIComponent(fleetVehicle.Co)}&FleetVehicle=${encodeURIComponent(fleetVehicle.FleetVehicle)}`,
            fleetVehicle
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_FLEET_VEHICLE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Fleet Vehicle #${fleetVehicle.FleetVehicle} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditFleetVehicleDialog(response.data));
            })
        );
    };
}

export function removeFleetVehicle(fleetVehicle) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/FleetVehicle?Co=${encodeURIComponent(fleetVehicle.Co)}&FleetVehicle=${encodeURIComponent(fleetVehicle.FleetVehicle)}`,
            fleetVehicle
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FLEET_VEHICLE,
                    data: fleetVehicle
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Fleet Vehicle #${fleetVehicle.FleetVehicle} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeFleetVehicles(fleetVehicleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.post('/api/fleet-vehicles-app/remove-fleetVehicles', {
            fleetVehicleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FLEET_VEHICLES
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLES
                })
            ]).then(() => dispatch(getFleetVehicles(routeParams)))
        );
    };
}

export function toggleStarredFleetVehicle(fleetVehicleId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.post('/api/fleet-vehicles-app/toggle-starred-fleetVehicle', {
            fleetVehicleId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_FLEET_VEHICLE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicles(routeParams)))
        );
    };
}

export function toggleStarredFleetVehicles(fleetVehicleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.post('/api/fleet-vehicles-app/toggle-starred-fleetVehicles', {
            fleetVehicleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_FLEET_VEHICLES
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicles(routeParams)))
        );
    };
}

export function setFleetVehiclesStarred(fleetVehicleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.post('/api/fleet-vehicles-app/set-fleetVehicles-starred', {
            fleetVehicleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_FLEET_VEHICLES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicles(routeParams)))
        );
    };
}

export function setFleetVehiclesUnstarred(fleetVehicleIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehiclesApp.fleetVehicles;

        const request = axios.post('/api/fleet-vehicles-app/set-fleetVehicles-unstarred', {
            fleetVehicleIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_FLEET_VEHICLES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicles(routeParams)))
        );
    };
}
