import axios from 'axios/index';

export const SET_RATE_TEMPLATE_DATA = '[SPCONNECTION] SET RATE TEMPLATE DATA';
export const GET_RATE_TEMPLATE_DATA = '[SPCONNECTION] GET RATE TEMPLATE DATA';
export const SUBSCRIBE_RATE_TEMPLATE_LIST = '[SPCONNECTION] SUBSCRIBE RATE TEMPLATE LIST';

export function getInitialRateTemplateData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/RateTemplate`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getRateTemplateData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/RateTemplate`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_RATE_TEMPLATE_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_RATE_TEMPLATE_LIST,
            Co
        }));
    }
}

export function setRateTemplateData(data) {
    return {
        type: SET_RATE_TEMPLATE_DATA,
        payload: data
    };
}