import { Camera, CameraResultType } from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import { FuseAnimate, FuseAnimateGroup } from '@fuse'
import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Drawer,
  Fab,
  Grow,
  Hidden,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import {
  Assignment,
  Build,
  List as ListIcon,
  LocalShipping,
  Payment,
  Receipt,
} from '@material-ui/icons'
import axios from 'axios'
import classNames from 'classnames'
import clsx from 'clsx'
import GoogleMap from 'google-map-react'
import history from 'history.js'
import parse from 'html-react-parser'
import * as ICS from 'ics-js'
import deburr from 'lodash/deburr'
import EstimatesList from 'main/content/apps/estimates/EstimatesList'
import FileList from 'main/content/apps/file-manager/FileList'
import InvoicesList from 'main/content/apps/invoices/InvoicesList'
import PaymentsList from 'main/content/apps/invoices/PaymentsList'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import MailCompose from 'main/content/apps/mail/MailCompose'
import TripsList from 'main/content/apps/trips/TripsList'
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList'
import WorkOrderUsagesList from 'main/content/apps/work-order-usages/WorkOrderUsagesList'
import WorkOrdersPDF from './WorkOrdersPDF'
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList'
import Comments from 'main/content/components/comments/Comments'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import InspectorDialog from 'main/content/components/inspector/Inspector'
import Recordings from 'main/content/components/recordings/Recordings'
import Tags from 'main/content/components/tags/Tags'
import { impress } from 'main/content/compression/impress'
import * as DialerActions from 'main/dialer/store/actions'
import { SPRoundIcon } from 'main/icons'
import moment from 'moment'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import Geocode from 'react-geocode'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import accents from 'remove-accents'
import { showMessage, addAlert } from 'store/actions'
import format from 'string-template'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import ChangeDialog from '../../components/change-log/ChangeDialog'
import {
  addAgreement,
  openEditAgreementDialog,
  openNewAgreementDialog,
  removeAgreementTask,
} from '../agreements/store/actions'
import { getCustomerData, getSiteData } from '../call-handler/store/actions'
import TaskChecklist from '../checklists/Checklist'
import ChecklistGenerator from '../checklists/ChecklistGenerator'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector'
import {
  openEditEstimateDialog,
  openNewEstimateDialog,
} from '../estimates/store/actions'
import AttachmentDialog from '../file-manager/AttachmentDialog'
import TaskForm from '../forms/Form'
import InvoiceCustomer from '../invoices/InvoiceCustomer'
import {
  openEditInvoiceDialog,
  openNewInvoiceDialog,
} from '../invoices/store/actions'
import PurchaseOrdersList from '../purchase-orders/PurchaseOrdersList'
import { openNewPurchaseOrderDialog } from '../purchase-orders/store/actions'
import ServiceUnitsList from '../service-units/ServiceUnitsList'
import {
  openEditServiceUnitDialog,
  openNewServiceUnitDialog,
} from '../service-units/store/actions'
import TimecardLaborDialog from '../timecards/TimecardLaborDialog'
import * as TripActions from '../trips/store/actions'
import TripHoldDialog from '../trips/TripHoldDialog'
import * as WorkOrderScopeActions from '../work-order-scopes/store/actions'
import { openNewWorkOrderUsageDialog } from '../work-order-usages/store/actions'
import * as Actions from './store/actions'
import { setWorkOrderHistory } from '../work-order-history/store/actions/work-order-history.actions'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import ScopeDetails from './WorkOrderScope'
import WorkOrdersContactList from './WorkOrdersContactList'
import {
  addSplitscreenDialog,
  showSplitscreenDialog,
  setSplitscreenDialog,
  hideAllSplitscreenDialogs,
  captureSplitscreenDialogs,
} from '../splitscreen-dialogs/store/actions/splitscreen-dialogs.actions'
import {
  openPreloadWorkOrderDialog,
  openPreloadWorkOrderDialogSplitScreen,
} from './store/actions/work-orders.actions'
import WorkOrderHistory from '../work-order-history/WorkOrderHistory'
import { formatPhone } from 'main/functions'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import { getBillingInformation } from '../customers/CustomersDialog'
import {
  openNewGpsInventoryTransferDialog,
  openEditGpsInventoryTransferDialog,
} from '../gps-inventory-transfers/store/actions'

function Marker({ text, icon, style, badge, heading, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <div onClick={onClick}>
        <img
          onMouseDown={e => {
            e.stopPropagation()
          }}
          style={style}
          src={icon}
        />
      </div>
    </Tooltip>
  )
}

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TabContainer(props) {
  return (
    <Typography
      className={props.className}
      component='div'
      style={{
        padding: props.inheritPadding
          ? undefined
          : props.padding
            ? props.padding
            : 8 * 3,
        maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}
function ActionPanel(props) {
  if (props.actionPanel) {
    return (
      <FuseAnimate animation='transition.slideRightIn'>
        {props.children}
      </FuseAnimate>
    )
  } else {
    return <React.Fragment>{props.children}</React.Fragment>
  }
}

const styles = theme => ({
  root: {
    padding: 0,
  },
  avatar: {
    // backgroundColor: '#555555',
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  canceledAvatar: {
    backgroundColor: theme.palette.error[500],
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  unassignedScopeAvatar: {
    backgroundColor: '#9f9f9f',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  techSelAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  streetView: {
    borderRadius: '5px',
  },
  formControl: {
    marginBottom: 24,
  },
  status: {
    marginBottom: 24,
    maxWidth: 100,
  },
  customerSelect: {
    padding: 2,
    width: '100%',
    border: '1px solid lightgrey',
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  customerScroll: {},
  actionButton: {
    background: '#3f3f3f',
  },
  liRoot: {
    padding: 0,
  },
  actionBadge: {
    marginRight: 8,
    marginTop: 8,
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  yellow: {
    backgroundColor: '#f4b636',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5f5f5f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
    '&:active': {
      backgroundColor: '#5f5f5f',
    },
  },
  grey: {
    backgroundColor: '#5f5f5f',
    color: '#fff',
  },
  redText: {
    color: '#f44336',
  },
  greenText: {
    color: '#d1e751',
  },
  blueText: {
    color: '#4dbce9',
  },
  yellowText: {
    color: '#f4b636',
  },
  blackText: {
    color: '#3f3f3f',
  },
  greyText: {
    color: '#5f5f5f',
  },
  drawer: {
    width: 58,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 164,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerBadge: {
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    top: 4,
    right: -2,
    padding: 2,
  },
  switchBase: {
    height: 'unset',
  },
  estBadge: {
    top: 0,
    right: 12,
    background: '#3f3f3f',
    padding: 2,
  },
  estBadgeIcon: {
    fontSize: '14px !important',
  },
  taskBadge: {
    top: 20,
    right: 39,
    background: '#3f3f3f',
    padding: 2,
  },
  taskBadgeIcon: {
    fontSize: '14px !important',
  },
  taskCountBadge: {
    top: 14,
    right: -6,
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    border: '2px solid #3f3f3f',
    padding: 2,
  },
  taskCard: {
    marginBottom: 12,
    border: '1px solid lightgrey',
  },
  taskCardHeader: {
    // borderTop: '1px dotted lightgrey',
  },
  taskCardUnit: {
    // paddingLeft: 48,
  },
  unitAvatar: {
    width: 32,
    height: 32,
  },
  unitTaskBadge: {
    marginRight: 18,
    marginTop: 18,
  },
  formBadge: {
    minWidth: 14,
    height: 14,
    fontSize: 10,
    top: 'unset',
    bottom: -8,
    right: 4,
    padding: 2,
  },
  taskForms: {
    paddingBottom: '0 !important',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 12,
    maxWidth: '70%',
  },
  wrap: {
    whiteSpace: 'normal !important',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: 11,
    maxWidth: '70%',
  },
  tag: {
    marginRight: 4,
    height: 28,
    borderRadius: 5,
    marginBottom: 4,
  },
  borderTop: {
    borderTop: '1px solid lightgrey',
    paddingTop: 4,
    paddingBottom: 4,
  },
  techNameBadge: {
    top: '90%',
    right: '90%',
    backgroundColor: 'rgba(0,0,0,.5)',
    color: '#fff',
    fontSize: 'x-small',
  },
  techAvatarRoot: {
    marginRight: 0,
  },
  techAvatar: {
    borderRadius: '50%',
    boxShadow: '2px 2px 6px #333',
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  newTechButton: {
    width: 32,
    height: 32,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  histDrawer: {
    width: 64,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  histShelf: {
    position: 'relative',
  },
  histDrawerClose: {
    overflowX: 'hidden',
    width: 56,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.up('sm')]: {
      width: 56,
    },
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
    padding: 8,
  },
  histContent: {
    width: '100%',
    minHeight: '300px',
  },
  qty: {
    backgroundColor: '#3f3f3f',
    height: 18,
    minWidth: 18,
    fontSize: 13,
    paddingLeft: 4,
    paddingRight: 4,
    marginRight: 6,
    marginTop: 3,
    // width: 24,
    float: 'left',
    borderRadius: 12,
    color: '#fff',
    textAlign: 'center',
  },
  chip: {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 12,
    height: 24,
  },
  chipLabel: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: '#fff',
    paddingBottom: 2,
    fontSize: 16,
  },
  chipAvatar: {
    marginLeft: '-12px !important',
    color: '#3f3f3f',
    backgroundColor: '#3f3f3f',
    border: '3px solid #3f3f3f',
    width: '32px !important',
    height: '32px !important',
  },
  chipAvatarIcon: {
    color: '#3f3f3f',
    backgroundColor: '#fff',
  },
  invHeader: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
  },
  invSubHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 4,
    color: '#333',
  },
  dividerPlain: {
    color: '#fff',
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
})
const newWorkOrderState = {
  ID: null,
  Co: null,
  WorkOrder: null,
  Customer: null,
  Site: null,
  Contact: null,
  ContactPhone: null,
  Description: null,
  Originator: null,
  Requestor: null,
  RequestedByContact: null,
  SiteContact: null,
  EnteredBy: null,
  EnteredDate: new Date(),
  Status: 0,
  Notes: null,
  value: 1,
  Data: {
    ErrMsg: null,
    Scopes: [],
    Site: null,
    Customer: null,
    Attachments: [],
    Billing: [],
    Comments: [],
    Tags: [],
    Trips: [],
    POs: [],
    Recordings: [],
    Invoices: [],
    Usage: [],
  },
  streetView: false,
  Trip: null,
  BillingData: null,
  Latitude: null,
  Longitude: null,
  showTabs: false,
  activityType: 'tags',
  taskAnchorEl: null,
  selectedForm: null,
  selectedForms: [],
  selectedChecklist: null,
  selectedChecklists: [],
  selectedDivision: null,
  selectedTask: null,
  selectedScope: null,
  expanded: {},
  editScopes: {},
  editSummary: {},
  editTechs: {},
  editQuotes: {},
  editWarranties: {},
  tcPrompt: false,
  holdPrompt: false,
  needsUpdate: false,
  loadingAgreement: false,
  histTab: 0,
  loading: false,
  siteList: [],
  siteDescription: null,
  searchingSite: false,
  newEstimate: null,
  newPhotoList: [],
  updatedEstimate: null,
  scheduleEstimate: false,
  scheduleReturnTrip: false,
  returnPrompt: false,
  confirmComplete: null,
  autoInv: false,
  tripNotes: null,
  holdInfo: null,
  checklistResults: '',
  checklistsPDF: null,
  anchorQuoteEl: null,
  anchorQuote: null,
  showEmailLogs: false,
  addUnitsScope: null,
  unitMenuEl: null,
  unitMenuData: null,
  unitMenuUnit: null,
  siteMenuEl: null,
  customerMenuEl: null,
  viewChanges: false,
  openWoPDF: null,
  openWoContactList: null,
  isSiteContact: null,
  viewSummary: false,
  viewChanges: false,
  minimizedDialog: false,
  disableBackdropClick: false,
  dragged: false,
  screenOverlay: null,
  posReset: true,
  position: { x: 480, y: 0 },
  startingPosition: null,
  initialDialogWidth: 960,
  dialogWidth: 960,
  removedFromSplitscreen: false,
  topDialog: false,
  splitImageCaptured: null,
  initialZindex: true,
  dialogHeight: null,
  minHeight: null,
  location: null,
}

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

class WorkOrdersDialog extends Component {
  state = { ..._.cloneDeepWith(newWorkOrderState), Co: this.props.Co }

  ref = React.createRef()

  componentDidMount() {
    const { Co, loggedIn } = this.props
    const { data, props, preload } = this.props.workOrderDialog
    const { open } = props
    newWorkOrderState.Co = this.props.Co
    const initialState = _.cloneDeepWith(newWorkOrderState)

    if (data && open) {
      data.Address = this.buildAddress(
        data.Data.Site.Address1,
        data.Data.Site.City,
        data.Data.Site.State,
        data.Data.Site.Zip,
      )
      if (
        (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
        window.google
      ) {
        Geocode.fromAddress(
          `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
        ).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location
            this.setState({
              ...initialState,
              ...data,
              Latitude: lat,
              Longitude: lng,
              value: 0,
              expanded: {},
              editScopes: {},
              editSummary: {},
              editTechs: {},
            })
          },
          error => {
            this.setState({
              ...initialState,
              ...data,
              value: 0,
              expanded: {},
              editScopes: {},
              editSummary: {},
              editTechs: {},
            })
          },
        )
      } else {
        const { Latitude, Longitude } = data.Data.Site
        this.setState({
          ...initialState,
          ...data,
          Latitude,
          Longitude,
          value: 0,
          expanded: {},
          editScopes: {},
          editSummary: {},
          editTechs: {},
        })
      }
      this.props.updateEditWorkOrderDialog(data, data.Trip)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    newWorkOrderState.Co = this.props.Co
    const initialState = _.cloneDeepWith(newWorkOrderState)
    if (
      !prevProps.workOrderDialog.props.open &&
      this.props.workOrderDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.workOrderDialog.type === 'edit' &&
        this.props.workOrderDialog.data &&
        !_.isEqual(
          this.props.workOrderDialog.data,
          prevProps.workOrderDialog.data,
        )
      ) {
        const { data, preload } = this.props.workOrderDialog
        data.newEstimate = null
        data.updatedEstimate = null
        data.Address = this.buildAddress(
          data.Data.Site.Address1,
          data.Data.Site.City,
          data.Data.Site.State,
          data.Data.Site.Zip,
        )
        const { Latitude, Longitude } = data.Data.Site
        if (!this.state.posSet) {
          this.setState(
            {
              ...initialState,
              ...data,
              Latitude,
              Longitude,
              value: 0,
              expanded: {},
              editScopes: {},
              editSummary: {},
              editTechs: {},
              loading: preload,
              minHeight: this.props.workOrderDialog.splitOpen,
              posSet: this.state.posSet,
              dialogHeight: null,
            },
            () => {
              if (
                (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
                window.google
              ) {
                this.geocodeSite(data.Data.Site)
              }
            },
          )
        } else {
          this.setState(
            {
              ...initialState,
              ...data,
              Latitude,
              Longitude,
              value: 0,
              expanded: {},
              editScopes: {},
              editSummary: {},
              editTechs: {},
              loading: preload,
              minHeight: this.props.workOrderDialog.splitOpen,
              posSet: this.state.posSet,
            },
            () => {
              if (
                (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
                window.google
              ) {
                this.geocodeSite(data.Data.Site)
              }
            },
          )
        }
      }

      if (
        this.props.workOrderDialog.type === 'site' &&
        this.props.workOrderDialog.data &&
        !_.isEqual(this.props.workOrderDialog.data, prevState)
      ) {
        this.setState({
          ...initialState,
          ...this.props.workOrderDialog.data,
          loading: false,
        })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.workOrderDialog.type === 'new' &&
        !_.isEqual(initialState, prevState)
      ) {
        this.setState({ ...initialState, loading: false, value: 1 })
      }
    }
    if (
      prevProps.workOrderDialog.props.open &&
      this.props.workOrderDialog.props.open
    ) {
      if (
        this.props.workOrderDialog.type === 'update' &&
        this.props.workOrderDialog.data &&
        !_.isEqual(
          this.props.workOrderDialog.data,
          prevProps.workOrderDialog.data,
        )
      ) {
        const { data, preload } = this.props.workOrderDialog
        const { Address1, City, State, Zip, Latitude, Longitude } =
          data.Data.Site
        const Address = this.buildAddress(Address1, City, State, Zip)
        this.props.workOrderDialog.type = 'edit'
        let newEstimate = null
        let updatedEstimate = null
        const canAssignEstimate = Boolean(
          data.newEstimate &&
            !data.newEstimate.AssignedToWorkOrder &&
            _.find(data.Data.Scopes, o => !o.Estimate),
        )
        if (
          canAssignEstimate &&
          data.newEstimate &&
          !_.isEqual(
            data.newEstimate,
            prevProps.workOrderDialog.data.newEstimate,
          ) &&
          (!data.newEstimate.OptionBoard || data.newEstimate.SelectedOption)
        ) {
          newEstimate = _.cloneDeepWith(data.newEstimate)
          data.newEstimate = null
        }
        const assignedScope =
          data && data.Data && data.Data.Scopes && data.updatedEstimate
            ? _.find(
                data.Data.Scopes,
                o => o.Estimate === data.updatedEstimate.Estimate,
              )
            : null
        const canUpdateEstimate = Boolean(
          data.updatedEstimate &&
            (!data.updatedEstimate.AssignedWorkOrder ||
              (assignedScope && !assignedScope.InvoiceNumber)),
        )
        if (
          canUpdateEstimate &&
          data.updatedEstimate &&
          !_.isEqual(
            data.updatedEstimate,
            prevProps.workOrderDialog.data.updatedEstimate,
          ) &&
          (!data.updatedEstimate.OptionBoard ||
            data.updatedEstimate.SelectedOption)
        ) {
          // window["warn"]('Updated Estimate: ', _.cloneDeepWith(data.updatedEstimate));
          if (!data.updatedEstimate.AssignedToWorkOrder) {
            newEstimate = _.cloneDeepWith(data.updatedEstimate)
          } else {
            if (assignedScope) {
              this.props.updateWorkOrderScope({
                ...assignedScope,
                RefreshEstimate: 'Y',
              })
            }
          }
          data.updatedEstimate = null
        }
        this.setState(
          {
            ...this.props.workOrderDialog.data,
            Address,
            Latitude,
            Longitude,
            siteDescription: null,
            siteList: [],
            value: this.state.value,
            newEstimate,
            updatedEstimate,
            loading: false,
          },
          () => {
            if (data.autoInv) {
              data.autoInv = null
              this.launchInvoice()
            }
          },
        )
      } else {
        if (
          this.props.workOrderDialog.type === 'update' &&
          this.state.loading
        ) {
          this.setState({ loading: false })
        }
      }
    }
  }

  geocodeSite = site => {
    Geocode.fromAddress(
      `${site.Address1} ${site.City}, ${site.State} ${site.Zip}`,
    ).then(response => {
      const { lat, lng } = response.results[0].geometry.location
      this.setState({ Latitude: lat, Longitude: lng })
    })
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  closeComposeDialog = () => {
    this.props.workOrderDialog.type === 'edit'
      ? this.props.closeEditWorkOrderDialog()
      : this.props.closeNewWorkOrderDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
      dialogHeight: null,
      posSet: null,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.workOrderDialog.type === 'edit'
      ? this.props.closeEditWorkOrderDialog()
      : this.props.closeNewWorkOrderDialog()
  }

  canBeSubmitted() {
    const { Co, Customer, Site, Description } = this.state
    return (
      Co &&
      Customer &&
      Site &&
      Description &&
      Site.length > 0 &&
      Description.length > 0
    )
  }

  toggleStreetview(streetView) {
    this.setState({ streetView })
  }

  hasAddress() {
    const { Address1, City, State, Zip } = this.state.Data.Site
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  getAddress = () => {
    // window["log"](this.state);
    const { Address1, City, State, Zip } = this.state.Data.Site
    if (window.google) {
      Geocode.fromAddress(`${Address1} ${City}, ${State} ${Zip}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          const Address =
            Address1.length > 3 && City.length > 2 && State.length > 1
              ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
              : false
          this.setState({
            ...this.state,
            Address,
            Latitude: lat,
            Longitude: lng,
          })
        },
        error => {
          const Address =
            Address1.length > 3 && City.length > 2 && State.length > 1
              ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
              : false
          this.setState({ ...this.state, Address })
        },
      )
    } else {
      const Address =
        Address1.length > 3 && City.length > 2 && State.length > 1
          ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
          : false
      this.setState({ ...this.state, Address })
    }
  }

  setBillingData = Data => {
    let { BillingData } = this.state
    if (
      !BillingData ||
      BillingData.Customer.Customer !== Data.Customer.Customer
    ) {
      this.setState({ ...this.state, BillingData: Data }, () => {
        // window["warn"](this.state.BillingData);
      })
    } else {
      this.setState({ ...this.state, BillingData: null }, () => {
        // window["warn"](this.state.BillingData);
      })
    }
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  toggleActions = e => {
    e.stopPropagation()
    const { actionPanel } = this.state
    this.setState({ ...this.state, actionPanel: !actionPanel, tripInfo: false })
  }

  hideActions = e => {
    e.stopPropagation()
    const { actionPanel, tripInfo } = this.state
    if (actionPanel || tripInfo) {
      this.setState({ ...this.state, actionPanel: false, tripInfo: false })
    }
  }

  toggleTabs = () => {
    const { showTabs } = this.state
    this.setState({ ...this.state, showTabs: !showTabs })
  }

  openDirections = () => {
    const { Data } = this.state
    if (Data.Site) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          `${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`,
        )}`,
      )
    }
  }

  launchInvoice = () => {
    const { divisions, departments } = this.props
    let { WorkOrder, Data, Trip, checklistsPDF } = this.state
    const { Scopes } = Data
    const first = _.find(Scopes, { ReadyToBillYN: 'Y' })
    const dept = first
      ? _.find(departments, {
          BusinessUnit: first.BusinessUnit,
          Division: first.Division,
          Department: first.Department,
        })
      : null
    const div = dept
      ? _.find(divisions, {
          BusinessUnit: dept.BusinessUnit,
          Division: dept.DivisionOverride || dept.Division,
        })
      : null
    const trip = Trip ? _.find(Data.Trips, { Trip }) : null
    const Assignments = trip ? trip.Data.Assignments : null
    // window["warn"]('Billing Data: ', Data.Billing);
    if (Data.Billing && Data.Billing.length > 0) {
      const BillingData = {
        ...Data.Billing[0],
      }
      // if (trip) {
      //     BillingData.Scopes = _.filter(BillingData.Scopes, (o) => { return _.findIndex(trip.Data.Assignments, { Scope: o.Scope }) > -1 });
      // }
      this.setState({ ...this.state, BillingData }, () => {
        this.props.openNewInvoiceDialog(
          {
            ...this.state.BillingData,
            WorkOrder,
            Scope: null,
            InvoiceNumber: `${
              div && div.InvoicePrefix ? div.InvoicePrefix : 'AR'
            }${WorkOrder}-${this.state.Data.Invoices.length + 1}`,
            Assignments,
          },
          { ...trip, Data: { ...(trip || {}).Data, WorkOrder: this.state } },
          checklistsPDF && [checklistsPDF],
        )
        this.setState({
          ...this.state,
          actionEl: null,
          actionAdd: null,
          actionManage: null,
        })
      })
    }
  }

  launchEstimate = bool => {
    const d2i = bool === true
    let { Data, WorkOrder, CallSid } = this.state
    const { technician, catalogs } = this.props
    const { Customer, Site, Scopes, Estimates } = Data
    const { Co, BusinessUnit, Division, LeadTechnician } = Scopes[0]
    const catalog = _.find(catalogs, { Co, BusinessUnit, Division })
    const next = _.maxBy(Estimates, o => {
      const seq = o.EstimateNumber.replace(
        `${
          catalog && catalog.DefaultEstimatePrefix
            ? catalog.DefaultEstimatePrefix
            : 'Q'
        }${WorkOrder}-`,
        '',
      )
      if (!isNaN(seq)) {
        return Number(seq)
      }
    })
    const seq = next
      ? Number(
          next.EstimateNumber.replace(
            `${
              catalog && catalog.DefaultEstimatePrefix
                ? catalog.DefaultEstimatePrefix
                : 'Q'
            }${WorkOrder}-`,
            '',
          ),
        )
      : 0
    const data = {
      Co,
      BusinessUnit,
      Division,
      Customer: Site.Customer,
      Site: Site.Site,
      Estimate: null,
      EstimateNumber: `${
        catalog ? catalog.DefaultEstimatePrefix : 'Q'
      }${WorkOrder}-${seq + 1}`,
      IssuedBy: technician ? technician.Technician : LeadTechnician,
      IssuedDate: new Date(),
      IssuedFromWorkOrder: WorkOrder,
      Status: 0,
      Data: {
        Customer,
        Site,
        Components: [],
        Features: [],
        Exclusions: [],
        Payments: [],
        ErrMsg: null,
      },
      CallSid,
      autoInvoice: d2i,
      estimateType: d2i ? 'S' : null,
      simpleStep: d2i ? 2 : undefined,
    }
    this.setState(
      { ...this.state, actionEl: null, actionAdd: null, actionManage: null },
      () => {
        this.props.openNewEstimateDialog(data)
      },
    )
  }

  fileSelectedHandler = (event, i) => {
    let { ID, Data, Co, WorkOrder, newPhotoList } = this.state
    const file = event
    var reader = new FileReader()
    var refindedPhoto
    reader.onload = e => {
      const nameArr = file.name.split('.')
      const fname = `WO${Co}-${WorkOrder}Img${Data.Attachments.length + i + 1}`
      if (this.fileInput) {
        this.fileInput.value = ''
      }
      refindedPhoto = {
        data: e.target.result,
        fileName: fname,
        type: 'image/jpeg',
      }
      this.setState(prevState => ({
        newPhotoList: [...prevState.newPhotoList, refindedPhoto],
      }))
    }
    reader.readAsDataURL(file)
  }

  imgSelectedHandler = event => {
    let { ID, Co, Data } = this.state
    const file = event.target.files[0]
    if (file.type.split('/')[0] === 'image') {
      this.fileSelectedHandler(event)
    }
  }
  attachmentSelectedHandler = event => {
    const { ID, Co, Data } = this.state
    const files = event.target.files
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.type.split('/')[0] === 'image') {
        this.fileSelectedHandler(file, i)
        // impress(file, 1024, 50, false, (img) => {
        //     window["log"](img);
        //     const nameArr = img.fileName.split('.');
        //     const { Attachments } = this.state.Data;
        //     const newFileName = `Co-${Co}-Unit-${ID}-Img-${Attachments.length + 1}.${nameArr[nameArr.length - 1]}`;
        //     const fd = new FormData();
        //     fd.append('image', img.file, newFileName);
        //     axios.post(`${window["apiLocation"]}/api/Attachment/AddAttachment?Co=${Co}&ID=${ID}&type=WO`, fd).then((res) => {
        //         window["warn"](res);
        //         if (res.data.Attachment) {
        //             const { Attachments } = this.state.Data;
        //             Attachments.push(res.data.Attachment);
        //             this.setState({ ...this.state, Data: { ...Data, Attachments: [...Attachments] } });
        //         }
        //     });
        // });
      } else {
        const fd = new FormData()
        fd.append('file', file, file.name)
        axios
          .post(
            `${window['apiLocation']}/api/Attachment/AddAttachment?Co=${Co}&ID=${ID}&type=WO`,
            fd,
          )
          .then(res => {
            if (res.data.Attachment) {
              const { Attachments } = this.state.Data
              Attachments.push(res.data.Attachment)
              this.setState({
                ...this.state,
                Data: { ...Data, Attachments: [...Attachments] },
              })
            }
          })
      }
    }
  }

  uploadImg = (file, notes) => {
    impress(file, 1024, 50, false, img => {
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      if (notes) {
        fd.append('notes', notes)
      }
      // saveAs(img.file, img.fileName);
      axios
        .post(
          `${
            window['apiLocation']
          }/api/Attachment/PostWorkOrderImage?Co=${encodeURIComponent(
            this.state.Co,
          )}&WorkOrder=${encodeURIComponent(
            this.state.WorkOrder,
          )}&User=${encodeURIComponent(this.props.user.UserName)}`,
          fd,
        )
        .then(res => {
          // window["warn"](res);
          if (res.data.Attachment) {
            const { Attachments } = this.state.Data
            Attachments.push(res.data.Attachment)
            // this.setState({ ...this.state, Data: { ...this.state.Data, Attachments: [...Attachments] }, newPhoto: null });
            this.setState(prevState => ({
              Data: { ...prevState.Data, Attachments: [...Attachments] },
              newPhotoList: prevState.newPhotoList.slice(0, -1),
            }))
          }
        })
    })
  }

  uploadChecklistImg = (file, notes, callback) => {
    impress(file, 1024, 50, false, img => {
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      if (notes) {
        fd.append('notes', notes)
      }

      axios
        .post(
          `${
            window['apiLocation']
          }/api/Attachment/PostWorkOrderImage?Co=${encodeURIComponent(
            this.state.Co,
          )}&WorkOrder=${encodeURIComponent(
            this.state.WorkOrder,
          )}&User=${encodeURIComponent(this.props.user.UserName)}`,
          fd,
        )
        .then(res => {
          // window["warn"](res);
          if (res.data.Attachment) {
            const { Attachments } = this.state.Data
            Attachments.push(res.data.Attachment)
            this.setState(prevState => ({
              Data: { ...prevState.Data, Attachments: [...Attachments] },
              newPhotoList: prevState.newPhotoList.slice(0, -1),
            }))
            callback(res.data.Attachment, notes)
          }
        })
    })
  }

  newAgreement = () => {
    const { Co, WorkOrder, Data, Trip } = this.state
    const trip = Trip ? _.find(Data.Trips, { Trip }) : null
    const { Scopes, Site, Customer, Assignments } = Data
    const { BusinessUnit, Division, LeadTechnician } =
      trip && trip.Data && trip.Data.Assignments
        ? _.find(
            Scopes,
            o =>
              o.Scope ===
              (trip.Data.Assignments && trip.Data.Assignments.length > 0
                ? trip.Data.Assignments[0].Scope
                : 1),
          )
        : this.state.Data.Scopes && this.state.Data.Scopes.length > 0
          ? this.state.Data.Scopes[0]
          : {}
    const StartDate = new Date()
    const data = {
      Co,
      BusinessUnit,
      Division,
      Customer: Site.Customer,
      Site: Site.Site,
      SoldBy: LeadTechnician,
      StartDate,
      WorkOrder,
      Scope: 1,
      Data: {
        AgreementType: null,
        Site,
        Customer,
        WorkOrder: this.state,
        Tasks: [],
        Schedule: [],
        Variations: Site.Data.Agreements,
        Attachments: [],
      },
    }
    this.props.openNewAgreementDialog(_.cloneDeepWith(data))
  }

  setAction = (actionEl, actionAdd, actionManage) => {
    this.setState({ ...this.state, actionEl, actionAdd, actionManage })
  }

  handleCall = phone => {
    const { toggleDialer, initCall, dialer } = this.props
    if (
      (phone && phone.length === 7) ||
      phone.length === 10 ||
      phone.length === 12
    )
      toggleDialer(true)
    initCall(dialer, {
      destinationNumber: phone,
      audio: true,
      video: false,
    })
  }

  toggleScope = scope => {
    const { expanded } = this.state
    if (expanded[scope]) {
      expanded[scope] = false
    } else {
      expanded[scope] = true
    }
    this.setState({ ...this.state, expanded })
  }

  handleSiteSelect = Site => {
    const { Data } = Site
    const { Customer, Units, Agreements } = Data
    const Address = this.buildAddress(
      Site.Address1,
      Site.City,
      Site.State,
      Site.Zip,
    )
    if ((!Site.Latitude || !Site.Longitude) && window.google) {
      Geocode.fromAddress(
        `${Site.Address1} ${Site.City}, ${Site.State} ${Site.Zip}`,
      ).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          this.setState({
            ...this.state,
            Customer: Site.Customer,
            Site: Site.Site,
            Data: { ...this.state.Data, Site, Customer, Agreements, Units },
            Address,
            Latitude: lat,
            Longitude: lng,
          })
        },
        error => {
          this.setState({
            ...this.state,
            Customer: Site.Customer,
            Site: Site.Site,
            Data: { ...this.state.Data, Site, Customer, Agreements, Units },
            Address,
          })
        },
      )
    } else {
      this.setState({
        ...this.state,
        Customer: Site.Customer,
        Site: Site.Site,
        Data: { ...this.state.Data, Site, Customer, Agreements, Units },
        Address,
        Latitude: Site.Latitude,
        Longitude: Site.Longitude,
      })
    }
  }

  updateStatus = (trip, Status) => {
    if (Status !== trip.Status) {
      const { Data } = this.state
      const { Invoices, Usage, Scopes } = Data
      if (Status === 8) {
        const scopes = _.filter(
          Scopes,
          o =>
            _.findIndex(trip.Data.Assignments, { Scope: o.Scope }) > -1 &&
            (o.LeadTechnician || trip.Technician) === trip.Technician,
        )
        const billableScopes = _.filter(
          scopes,
          o => !o.InvoiceNumber && o.PriceMethod === 'F',
        )
        const billable = _.filter(
          Usage,
          o =>
            o.Status === 'NEW' &&
            _.findIndex(trip.Data.Assignments, { Scope: o.Scope }) > -1 &&
            _.findIndex(scopes, { Scope: o.Scope }) > -1,
        )
        if (billableScopes.length > 0 || billable.length > 0) {
          this.setState({
            confirmComplete: {
              trip,
              count: billableScopes.length + billable.length,
            },
          })
        } else {
          trip.Status = Status
          this.props.updateTrip({ ...trip })
          this.setState({ ...this.state, tripInfo: false, tcPrompt: true })
        }
      } else {
        trip.Status = Status
        if (Status !== 7) {
          trip.BoardTime = new Date().toLocaleString('en-US')
        }
        this.props.updateTrip({ ...trip })
        if (Status === 4) {
          const { Address1, City, State, Zip } = this.state.Data.Site
          const { WorkOrder } = this.state
          this.props.addAlert({
            open: true,
            title: 'En-Route to Work Order',
            type: 'TripDirections',
            data: {
              Address1,
              City,
              State,
              Zip,
              WorkOrder,
              platform: Capacitor.getPlatform(),
            },
            icon: 'directions',
            cancelText: 'Dismiss',
            hideConfirm: true,
          })
        }
        if (trip.Status === 7) {
          this.setState({
            ...this.state,
            tripInfo: false,
            holdPrompt: true,
            holdInfo: null,
          })
        } else {
          this.setState({ ...this.state, tripInfo: false })
        }
      }
    }
  }

  emailCustomer = email => {
    const { Co } = this.state
    const { companies } = this.props
    const co = _.find(companies, { Co })
    const Attachments = []

    const data = {
      composeDialog: true,
      Title: 'Email Customer',
      Icon: 'email',
      To: `${email};`,
      Subject: `A Message from ${co.Data.Co.Name}`,
      Head: '',
      Message: '',
      Body: '',
      Attachments,
      Type: 'WO',
      RecordID: this.state.ID,
    }
    this.setState(
      { ...this.state, customerEmailData: { ...data } },
      this.closeMenu,
    )
    return data
  }

  emailConfirmation = () => {
    const { businessUnits, divisions, templates, arrivalWindows, companies } =
      this.props
    const { Co, WorkOrder, Address, EnteredDate, UpdatedDate, ID } = this.state
    const co = _.find(companies, { Co })
    const { Customer, Site, Scopes } = this.state.Data
    const Attachments = []
    const siteContacts =
      this.state.Data.Site && this.state.Data.Site.Data
        ? this.state.Data.Site.Data.Contacts
        : []
    const custContacts =
      this.state.Data.Customer && this.state.Data.Customer.Data
        ? this.state.Data.Customer.Data.Contacts
        : []
    const contactsList = this.state.isSiteContact ? siteContacts : custContacts
    const primaryRecord = contactsList.find(item => item.PrimaryYN === 'Y')
    let Event
    let html = ''
    const reqBy = this.state.Data.RequestedByContact
    const siteContact = this.state.Data.Contact
    if (Scopes) {
      const scope = Scopes[0]
      const { BusinessUnit, Division, Data } = Scopes[0]
      if (businessUnits && BusinessUnit) {
        const bu = _.find(businessUnits, { Co, BusinessUnit })
        const div = _.find(divisions, { Co, BusinessUnit, Division })
        const { Service } = Data
        const ArrivalWindow = _.find(arrivalWindows, {
          Co,
          BusinessUnit,
          ArrivalWindow: Scopes[0].ArrivalWindow,
        })
        if (div && bu && ArrivalWindow) {
          const start = new Date(
            `${new Date(scope.DueBy).toLocaleDateString('en-US')} ${
              ArrivalWindow.StartTime
            }`,
          ).toUTCString()
          const end = new Date(
            `${new Date(scope.DueBy).toLocaleDateString('en-US')} ${
              ArrivalWindow.EndTime
            }`,
          ).toUTCString()
          const entered = new Date(EnteredDate).toUTCString()
          const updated = new Date(UpdatedDate || EnteredDate).toUTCString()
          const template =
            div.Data.ConfirmationEmailTemplate ||
            bu.Data.ConfirmationEmailTemplate
          const templateData = {
            ...Customer,
            ...Site,
            ...Scopes[0],
            Name: Customer.Name || `${Customer.FirstName} ${Customer.LastName}`,
            DueBy: new Date(Scopes[0].DueBy).toLocaleDateString('en-US'),
            ArrivalWindow: ArrivalWindow.Description,
          }
          // window["warn"](templateData);
          html = template ? format(template.Markup, templateData) : ''
          const cal = new ICS.VCALENDAR()
          cal.addProp('PRODID', 'servicepointpro.com')
          cal.addProp('VERSION', 2)
          cal.addProp('METHOD', 'REQUEST')
          const event = new ICS.VEVENT()
          event.addProp('UID', `${Co}-${WorkOrder}@my.servicepointpro.com`)
          event.addProp('DTSTAMP', new Date(), { VALUE: 'DATE-TIME' })
          event.addProp('CREATED', new Date(entered), { VALUE: 'DATE-TIME' })
          event.addProp('SEQUENCE', 0)
          event.addProp('ORGANIZER', null, {
            CN: `"${bu.Description}":MAILTO:scheduling@servicepointpro.com`,
          })
          if (Customer.Email && Customer.Email.length > 0) {
            event.addProp('ATTENDEE', null, {
              ROLE: 'REQ-PARTICIPANT',
              PARTSTAT: 'NEEDS-ACTION',
              RSVP: 'TRUE',
              CN: `"${
                Customer.Name || `${Customer.FirstName} ${Customer.LastName}`
              }":MAILTO:${Customer.Email}`,
            })
          }
          event.addProp('DTSTART', new Date(start), { VALUE: 'DATE-TIME' })
          event.addProp('DTEND', new Date(end), { VALUE: 'DATE-TIME' })
          event.addProp('LOCATION', Address)
          event.addProp('SUMMARY', `Your ${bu.Description} Service Appointment`)
          event.addProp(
            'DESCRIPTION',
            `Service Appointment with ${bu.Description} for ${Service.Detail}`,
          )
          event.addProp('PRIORITY', '3')
          cal.addComponent(event)
          Event = cal.toString()
          // window["warn"](start, end, Event);
          const blob = cal.toBlob()
          // Attachments.push(new File([blob], `service-appointment.ics`, { type: "text/calendar" }));
        }
      }
    }

    const data = {
      composeDialog: true,
      Title: 'Send Confirmation',
      Icon: 'email',
      To: `${
        reqBy && reqBy.Email
          ? `${reqBy.Email};`
          : (primaryRecord && primaryRecord.Data.Contact.Email) || Site.Email
      }`,
      Subject: `Your Work Order Confirmation from ${co.Data.Co.Name}`,
      Head: '',
      Message: '',
      Body: html,
      Attachments,
      Event,
      Type: 'WO',
      RecordID: ID,
      role: 'confirmation',
    }
    this.setState(
      { ...this.state, confirmationData: { ...data } },
      this.closeMenu,
    )
    return data
  }

  formatPhone(str) {
    return formatPhone(str).formatted
  }

  formatPhoneLink(str) {
    const phone = str.replace(/\(|\)|,|\.|-/g, '')
    return phone.length === 10
      ? `+1-${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6, 4)}`
      : str
  }

  openMenu = e => {
    this.setState({ ...this.state, menuEl: e.target })
  }

  closeMenu = () => {
    this.setState({ ...this.state, menuEl: null })
  }

  inspectData = () => {
    this.setState({ ...this.state, inspector: true, menuEl: null })
  }

  closeInspector = () => {
    this.setState({ ...this.state, inspector: false })
  }

  generateChecklistsPDF = () => {
    const checklists = []
    const { classes, companies, users, divisions, serviceUnitTypes } =
      this.props
    const { Data } = this.state
    const { Scopes } = Data
    Scopes.map(scope => {
      if (scope.Data && scope.Data.ChecklistResults) {
        scope.Data.ChecklistResults.map(checklist => {
          const technician = _.find(users, { UserName: checklist.EnteredBy })
          checklist.info =
            scope &&
            [scope].map(scope => {
              const { Co, BusinessUnit, Division } = scope || {}
              const division = _.find(divisions, { Co, BusinessUnit, Division })
              return (
                <div className='w-full' key={scope.ID}>
                  <div className='w-full flex justify-between my-24'>
                    {division && division.InvoiceHeaderImg && (
                      <img
                        className='h-48 sm:h-96'
                        src={division.InvoiceHeaderImg}
                      />
                    )}
                    <span className='sm:mt-12'>
                      <label
                        className={classNames(
                          classes.invHeader,
                          'sm:pr-8 text-20 sm:text-36',
                        )}
                      >
                        CHECKLIST
                      </label>
                      <div
                        className={classNames(
                          classes.invSubHeader,
                          'sm:pr-8 text-10 sm:text-16',
                        )}
                      >
                        Work Order #{this.state.WorkOrder}-{scope.Scope}
                      </div>
                    </span>
                  </div>

                  <div className='flex mb-24 justify-between p-16 pr-4'>
                    <div className='cursor-pointer'>
                      <div className='w-full text-16 font-bold text-left'>
                        {Data.Customer.Name && Data.Customer.Name.length > 0
                          ? Data.Customer.Name
                          : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                      </div>
                      <div className='w-full text-12 text-left'>
                        {Data.Customer.Address1}
                      </div>
                      {Data.Customer.Address2 && (
                        <div className='w-full text-12 text-left'>
                          {Data.Customer.Address2}
                        </div>
                      )}
                      <div className='w-full text-12 text-left'>
                        {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                      </div>
                    </div>

                    <div>
                      <div
                        className='flex cursor-pointer'
                        onClick={this.openDatePicker}
                      >
                        <img
                          src='assets/icons/calendar_icon.png'
                          className='w-32 h-32 mr-8'
                        />
                        <div className='w-full'>
                          <div className='text-14 font-bold'>
                            {checklist.ResultID
                              ? moment(checklist.EnteredDate).format('dddd')
                              : moment(Date()).format('dddd')}
                          </div>
                          <div className='text-10'>
                            {checklist.ResultID
                              ? moment(checklist.EnteredDate).format(
                                  'MMM DD YYYY',
                                )
                              : moment(Date()).format('MMM DD YYYY')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex mt-24'>
                    <div className='w-full'>
                      <div
                        className='w-full flex justify-between rounded-full mb-12'
                        style={{ backgroundColor: '#3f3f3f' }}
                      >
                        <div
                          className={classNames(
                            'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                            classes.dividerHighlight,
                          )}
                        >
                          {checklist.Name}
                        </div>
                        <div
                          className={classNames(
                            'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                            classes.dividerPlain,
                          )}
                        >
                          {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                        </div>
                      </div>
                    </div>
                  </div>
                  {technician && (
                    <div className='w-full flex justify-end'>
                      <div
                        className='flex mr-24 mt-4'
                        style={{ marginBottom: -28 }}
                      >
                        <img
                          src='assets/icons/person_icon.png'
                          className='w-24 h-24 mr-4'
                        />
                        <div className='w-full'>
                          <div className='text-12 font-bold mt-4'>
                            {`${technician.FirstName} ${technician.LastName}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })
          checklist.footerInfo =
            scope &&
            [scope].map(scope => {
              const { Co } = scope || {}
              const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co
              if (co) {
                return (
                  <div className='mx-24' key={scope.ID}>
                    <div className='w-full sm:flex sm:justify-between'>
                      <div className='mt-24'>
                        <div className='w-full flex'>
                          <img
                            src='assets/icons/location_icon.png'
                            className='w-32 h-32 mr-8'
                          />
                          <div className='w-full'>
                            <div className='text-14 font-bold'>{co.Name}</div>
                            <div className='text-10'>{co.Address}</div>
                            <div className='text-10'>
                              {`${co.City}, ${co.State} ${co.Zip}`}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-24'>
                        <div className='w-full flex'>
                          <img
                            src='assets/icons/email_icon.png'
                            className='w-32 h-32 mr-8'
                          />
                          <div className='w-full'>
                            <div className='text-14 font-bold'>Email</div>
                            <div className='text-10'>{co.Email}</div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-24'>
                        <div className='w-full flex'>
                          <img
                            src='assets/icons/phone_icon.png'
                            className='w-32 h-32 mr-8'
                          />
                          <div className='w-full'>
                            <div className='text-14 font-bold'>Phone</div>
                            <div className='text-10'>
                              {this.formatPhone(co.Phone)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })
          checklists.push(checklist)
        })
      }
      if (scope.Data && scope.Data.AgreementTasks) {
        scope.Data.AgreementTasks.map(task => {
          if (task.Data.Task.Data.Units) {
            task.Data.Task.Data.Units.map(unit => {
              unit.Data.ChecklistResults.map(checklist => {
                checklist.info =
                  scope &&
                  [scope].map(scope => {
                    const { Co, BusinessUnit, Division } = scope || {}
                    const division = _.find(divisions, {
                      Co,
                      BusinessUnit,
                      Division,
                    })
                    const technician = _.find(users, {
                      UserName: checklist.EnteredBy,
                    })
                    return (
                      <div className='w-full' key={scope.ID}>
                        <div className='w-full flex justify-between my-24'>
                          {division && division.InvoiceHeaderImg && (
                            <img
                              className='h-48 sm:h-96'
                              src={division.InvoiceHeaderImg}
                            />
                          )}
                          <span className='sm:mt-12'>
                            <label
                              className={classNames(
                                classes.invHeader,
                                'sm:pr-8 text-20 sm:text-36',
                              )}
                            >
                              CHECKLIST
                            </label>
                            <div
                              className={classNames(
                                classes.invSubHeader,
                                'sm:pr-8 text-10 sm:text-16',
                              )}
                            >
                              Work Order #{this.state.WorkOrder}-{scope.Scope}
                            </div>
                          </span>
                        </div>

                        <div className='flex mb-24 justify-between p-16 pr-4'>
                          <div className='cursor-pointer'>
                            <div className='w-full text-16 font-bold text-left'>
                              {Data.Customer.Name &&
                              Data.Customer.Name.length > 0
                                ? Data.Customer.Name
                                : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                            </div>
                            <div className='w-full text-12 text-left'>
                              {Data.Customer.Address1}
                            </div>
                            {Data.Customer.Address2 && (
                              <div className='w-full text-12 text-left'>
                                {Data.Customer.Address2}
                              </div>
                            )}
                            <div className='w-full text-12 text-left'>
                              {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                            </div>
                          </div>

                          <div>
                            <div
                              className='flex cursor-pointer'
                              onClick={this.openDatePicker}
                            >
                              <img
                                src='assets/icons/calendar_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>
                                  {checklist.ResultID
                                    ? moment(checklist.EnteredDate).format(
                                        'dddd',
                                      )
                                    : moment(Date()).format('dddd')}
                                </div>
                                <div className='text-10'>
                                  {checklist.ResultID
                                    ? moment(checklist.EnteredDate).format(
                                        'MMM DD YYYY',
                                      )
                                    : moment(Date()).format('MMM DD YYYY')}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='flex mt-24'>
                          <div className='w-full'>
                            <div
                              className='w-full flex justify-between rounded-full mb-12'
                              style={{ backgroundColor: '#3f3f3f' }}
                            >
                              <div
                                className={classNames(
                                  'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                  classes.dividerHighlight,
                                )}
                              >
                                {checklist.Name}
                              </div>
                              <div
                                className={classNames(
                                  'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                  classes.dividerPlain,
                                )}
                              >
                                {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='w-full flex justify-between'>
                          <div className='text-12 text-left pl-16'>
                            <div className='w-full'>
                              <strong className='mr-8 font-bold'>Task:</strong>
                              <label>{task.Data.Task.Name}</label>
                            </div>
                            {unit.UnitType &&
                              _.find(serviceUnitTypes, {
                                UnitType: unit.UnitType,
                              }) && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Unit Type:
                                  </strong>
                                  <label>
                                    {
                                      _.find(serviceUnitTypes, {
                                        UnitType: unit.UnitType,
                                      }).Name
                                    }
                                  </label>
                                </div>
                              )}
                            <div className='w-full'>
                              <strong className='mr-8 font-bold'>
                                Unit ID:
                              </strong>
                              <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                            </div>
                            {unit.Manufacturer && (
                              <div className='w-full'>
                                <strong className='mr-8 font-bold'>
                                  Manufacturer:
                                </strong>
                                <label>{unit.Manufacturer}</label>
                              </div>
                            )}
                            {unit.Model && (
                              <div className='w-full'>
                                <strong className='mr-8 font-bold'>
                                  Model:
                                </strong>
                                <label>{unit.Model}</label>
                              </div>
                            )}
                            {unit.SerialNumber && (
                              <div className='w-full'>
                                <strong className='mr-8 font-bold'>
                                  Serial #:
                                </strong>
                                <label>{unit.SerialNumber}</label>
                              </div>
                            )}
                          </div>
                          {technician && (
                            <div
                              className='flex mr-24 mt-4'
                              style={{ marginBottom: -28 }}
                            >
                              <img
                                src='assets/icons/person_icon.png'
                                className='w-24 h-24 mr-4'
                              />
                              <div className='w-full'>
                                <div className='text-12 font-bold mt-4'>
                                  {`${technician.FirstName} ${technician.LastName}`}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })
                checklist.footerInfo =
                  scope &&
                  [scope].map(scope => {
                    const { Co } = scope || {}
                    const co = (_.find(companies, { Co }) || { Data: {} }).Data
                      .Co
                    if (co) {
                      return (
                        <div className='mx-24' key={scope.ID}>
                          <div className='w-full sm:flex sm:justify-between'>
                            <div className='mt-24'>
                              <div className='w-full flex'>
                                <img
                                  src='assets/icons/location_icon.png'
                                  className='w-32 h-32 mr-8'
                                />
                                <div className='w-full'>
                                  <div className='text-14 font-bold'>
                                    {co.Name}
                                  </div>
                                  <div className='text-10'>{co.Address}</div>
                                  <div className='text-10'>
                                    {`${co.City}, ${co.State} ${co.Zip}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='mt-24'>
                              <div className='w-full flex'>
                                <img
                                  src='assets/icons/email_icon.png'
                                  className='w-32 h-32 mr-8'
                                />
                                <div className='w-full'>
                                  <div className='text-14 font-bold'>Email</div>
                                  <div className='text-10'>{co.Email}</div>
                                </div>
                              </div>
                            </div>
                            <div className='mt-24'>
                              <div className='w-full flex'>
                                <img
                                  src='assets/icons/phone_icon.png'
                                  className='w-32 h-32 mr-8'
                                />
                                <div className='w-full'>
                                  <div className='text-14 font-bold'>Phone</div>
                                  <div className='text-10'>
                                    {this.formatPhone(co.Phone)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                checklists.push(checklist)
              })
            })
          }
        })
      }
    })
    if (checklists.length > 0) {
      this.setState({
        checklistResults: (
          <ChecklistGenerator
            readOnly={true}
            checklists={checklists}
            onGenerate={checklistsPDF =>
              this.setState({ checklistResults: '', checklistsPDF }, () =>
                this.launchInvoice(),
              )
            }
            fileName='Inspection Reports'
          />
        ),
      })
    } else {
      this.setState({ checklistResults: '', checklistsPDF: null }, () =>
        this.launchInvoice(),
      )
    }
  }

  getNextPO = (POs, prefix) => {
    const last = _.maxBy(POs || [], o => {
      if (!isNaN(o.PO.replace(prefix, ''))) {
        return Number(o.PO.replace(prefix, ''))
      }
    })
    const seq = last ? Number(last.PO.replace(prefix, '')) : 0
    const PO = `${prefix}${seq + 1}`
    return PO
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  takePicture = async () => {
    let { ID, Data, Co, WorkOrder } = this.state
    const image = await Camera.getPhoto({
      quality: 50,
      // allowEditing: true,
      resultType: CameraResultType.Uri,
    })
    if (image && image.webPath) {
      // console.warn('Image content: ', image);
      const response = await fetch(image.webPath)
      const blob = await response.blob()
      const file = new File(
        [blob],
        `WO${Co}-${WorkOrder}Img${Data.Attachments.length + 1}.jpg`,
        { type: `image/jpeg` },
      )
      // console.warn('Image file: ', file);
      if (file) {
        let { ID, Data, Co, WorkOrder } = this.state
        var reader = new FileReader()
        reader.onload = e => {
          const nameArr = file.name.split('.')
          const fname = `WO${Co}-${WorkOrder}Img${Data.Attachments.length + 1}`
          var refindedPhoto = {
            data: e.target.result,
            fileName: fname,
            type: 'image/jpeg',
          }
          this.setState(prevState => ({
            newPhotoList: [...prevState.newPhotoList, refindedPhoto],
          }))
          this.fileInput.value = ''
        }
        reader.readAsDataURL(file)
        // impress(file, 1024, 50, false, (img) => {
        //     window["log"](img);
        //     const fd = new FormData();
        //     fd.append('image', img.file, img.fileName);
        //     axios.post(`${window["apiLocation"]}/api/Attachment/PostWorkOrderImage?Co=${encodeURIComponent(this.state.Co)}&WorkOrder=${encodeURIComponent(this.state.WorkOrder)}&User=${encodeURIComponent(this.props.user.UserName)}`, fd).then((res) => {
        //         window["warn"](res);
        //         if (res.data.Attachment) {
        //             const { Attachments } = this.state.Data;
        //             Attachments.push(res.data.Attachment);
        //             this.setState({ ...this.state, Data: { ...Data, Attachments: [...Attachments] } });
        //         }
        //     });
        // });
      }
    }
  }

  handleSiteSearch = _.debounce(() => {
    const { siteDescription } = this.state
    if (siteDescription && siteDescription.length > 0) {
      const { Co } = this.props
      const inputValue = deburr(
        accents.remove(siteDescription.trim()),
      ).toLowerCase()
      axios
        .get(
          `${
            window['apiLocation']
          }/api/CustomerSiteSearch?Co=${Co}&Term=${encodeURIComponent(
            inputValue,
          )}`,
        )
        .then(response => {
          this.setState({ siteList: response.data })
        })
    } else {
      this.setState({ siteList: [] })
    }
  }, 750)

  handleSiteSelect = site => {
    axios
      .get(
        `${window['apiLocation']}/api/CustomerSite?Co=${site.Co}&Customer=${
          site.Customer
        }&Site=${encodeURIComponent(site.Site)}`,
      )
      .then(response => {
        const option = response.data
        const { Address1, City, State, Zip, Latitude, Longitude } = option
        this.setState({
          ...this.state,
          Customer: option.Customer,
          Site: option.Site,
          siteDescription: null,
          searchingSite: false,
          Address: `${Address1} ${City}, ${State} ${Zip}`,
          Latitude,
          Longitude,
          Data: {
            ...this.state.Data,
            Site: option,
            Customer: option.Data.Customer,
          },
        })
      })
  }

  // generateChecklists = () => {
  //     const { Scopes } = this.state.Data;
  //     const forms = [];
  //     const checklists = [];
  //     Scopes.map(scope => {
  //         scope.Data.FormResults.map(res => {
  //             forms.push(res);
  //         });
  //         scope.Data.ChecklistResults.map(res => {
  //             checklists.push(res);
  //         });
  //         scope.Data.AgreementTasks.map(task => {
  //             task.Data.Units.map(unit => {
  //                 unit.Data.FormResults.map(res => {
  //                     forms.push(res);
  //                 });
  //                 unit.Data.ChecklistResults.map(res => {
  //                     checklists.push(res);
  //                 });
  //             })
  //         })
  //     })
  // }

  closeUnitMenu = () => {
    this.setState({ unitMenuEl: null, unitMenuData: null, unitMenuUnit: null })
  }

  assignUnit = (rec, scope) => {
    const { Co, Customer, Site, WorkOrder } = this.state

    const { UnitID } = rec
    const Scope = scope

    if (WorkOrder && Scope) {
      const unit = {
        Co,
        Customer,
        Site,
        WorkOrder,
        Scope,
        UnitID,
        Data: {
          ErrMsg: null,
        },
      }
      const request = axios.post(
        `${window['apiLocation']}/api/ServiceUnitAssignment`,
        unit,
      )

      request.then(response => {
        if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
          this.props.showMessage({
            message: `Error: ${response.data.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          })
          // this.setState({ autoFocus: false });
        } else {
          const { Scopes } = this.state.Data
          const scp = _.find(this.state.Data.Scopes, { Scope: scope })
          const Units = scp.Data.ServiceUnits
          Units.push(response.data)
          this.setState(
            { ...this.state, Data: { ...this.state.Data, Scopes } },
            () => {
              // this.props.onAdd(response.data);
              this.props.showMessage({
                message: `Successfully added Unit #${response.data.UnitID} to Scope #${response.data.Scope}`,
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              })
            },
          )
        }
      })
    }
  }

  unassignUnit = unit => {
    const { Co, WorkOrder, Scope, Customer, Site, UnitID } = unit

    if (Co && Customer && Site && WorkOrder && Scope && UnitID) {
      const request = axios.delete(
        `${
          window['apiLocation']
        }/api/ServiceUnitAssignment?Co=${Co}&Customer=${Customer}&Site=${Site}&WorkOrder=${WorkOrder}&Scope=${Scope}&UnitID=${encodeURIComponent(
          UnitID,
        )}`,
      )

      request.then(response => {
        const { Scopes } = this.state.Data
        const scp = _.find(this.state.Data.Scopes, { Scope })
        const Units = scp.Data.ServiceUnits
        const index = _.findIndex(Units, o => o.UnitID === UnitID)
        if (index > -1) {
          Units.splice(index, 1)
        }
        this.setState(
          { ...this.state, Data: { ...this.state.Data, Scopes } },
          () => {
            // this.props.onAdd(response.data);
            this.props.showMessage({
              message: `Successfully removed Unit #${UnitID} from Scope #${Scope}`,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            })
          },
        )
      })
    }
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { securables } = this.props
    const { Trip, value, showTabs, Data } = this.state
    const handlerAccessLevel = _.find(securables, { Securable: 'call-handler' })
    const inspectorAccessLevel = _.find(securables, { Securable: 'inspector' })
    const accessLevel = _.find(securables, { Securable: 'work-order-dialog' })
    const trip = Trip ? _.find(Data.Trips, { Trip }) : null
    // const { props } = this.props.workOrderDialog
    // const { open } = props

    // let allContacts = []
    // if (open && Data && Data.Customer && Data.Site &&  Data.Site.Data && Data.Customer.Data &&  Data.Site.Data.Contacts && Data.Customer.Data.Contacts ) {
    //     window["warn"]("its open!: ", Data)
    //     allContacts = _.uniqBy([...this.state.Data.Site.Data.Contacts, ...this.state.Data.Customer.Data.Contacts], contact => contact.Data.Contact.Email)
    // }
    const allContacts =
      this.state.Data &&
      this.state.Data.Customer &&
      this.state.Data.Site &&
      this.state.Data.Site.Data &&
      this.state.Data.Customer.Data &&
      this.state.Data.Site.Data.Contacts &&
      Data.Customer.Data.Contacts
        ? _.uniqBy(
            [
              ...this.state.Data.Site.Data.Contacts,
              ...this.state.Data.Customer.Data.Contacts,
            ],
            contact => contact.Data.Contact.Email,
          )
        : []

    return (
      <>
        {this.state.confirmationData && (
          <MailCompose
            contacts={allContacts}
            dbAttachments={this.state.Data.Attachments}
            onClose={() =>
              this.setState({ ...this.state, confirmationData: null })
            }
            hideButton={true}
            data={{ ...this.state.confirmationData }}
          />
        )}
        {this.state.customerEmailData && (
          <MailCompose
            contacts={allContacts}
            dbAttachments={this.state.Data.Attachments}
            onClose={() =>
              this.setState({ ...this.state, customerEmailData: null })
            }
            hideButton={true}
            data={{ ...this.state.customerEmailData }}
          />
        )}
        {this.state.inspector && (
          <InspectorDialog
            theme='chromeDark'
            data={{ ...this.state }}
            name='Work Order'
            open={this.state.inspector}
            onClose={this.closeInspector}
          />
        )}
        {/* <EstimateDialog /> */}
        <IconButton
          style={{
            position: 'absolute',
            right: 96,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 56,
            top: !matches.small ? 8 : 4,
            color: 'white',
          }}
          className='dialog-header-icon'
          onClick={this.openMenu}
        >
          <Icon className={this.state.loading ? 'spin' : ''}>
            {this.state.loading ? 'refresh' : 'more_vert'}
          </Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: !matches.small ? 8 : 4,
            color: 'white',
          }}
          className='dialog-header-icon'
          onClick={e => {
            if (this.state.value !== 0 || this.state.viewSummary) {
              this.setState({ value: 0, viewSummary: false })
              this.props.setWorkOrderHistory(null)
            } else {
              //this.closeComposeDialog();
              closeFxn(e)
            }
          }}
        >
          <Icon>close</Icon>
        </IconButton>

        <Menu
          id='dialog-menu'
          classes={{ paper: 'min-w-256' }}
          anchorEl={this.state.menuEl}
          open={Boolean(this.state.menuEl)}
          onClose={this.closeMenu}
        >
          <MenuItem
            onClick={() => {
              this.props.updateEditWorkOrderDialog(
                this.state,
                trip ? trip.Trip : null,
              )
              this.closeMenu()
            }}
          >
            <Icon className='mr-8'>refresh</Icon>Refresh
          </MenuItem>
          {handlerAccessLevel && handlerAccessLevel.AccessLevel !== 'R' && (
            <MenuItem onClick={this.emailConfirmation}>
              <Icon className='mr-8'>email</Icon>Send Confirmation
            </MenuItem>
          )}
          <MenuItem
            onClick={() => this.setState({ showEmailLogs: true, menuEl: null })}
          >
            <Icon className='mr-8'>send</Icon>View Email Log
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ menuEl: null, viewChanges: true })
            }}
          >
            <Icon className='mr-8'>history</Icon>View Changes
          </MenuItem>
          <MenuItem
            onClick={() => this.setState({ openWoPDF: true, menuEl: null })}
          >
            <Icon className='mr-8'>print</Icon>View as PDF
          </MenuItem>
          {inspectorAccessLevel && (
            <MenuItem onClick={this.inspectData}>
              <Icon className='mr-8'>code</Icon>Inspect Data
            </MenuItem>
          )}
        </Menu>
        <ChangeDialog
          type='WO'
          rec={this.state.ID}
          restore={data => this.setState({ ...data, viewChanges: false })}
          accessLevel={accessLevel}
          data={this.state}
          open={this.state.viewChanges}
          onClose={() => this.setState({ viewChanges: false })}
        />
        {showTabs && (
          <Tabs
            value={value}
            onChange={this.handleTabChange}
            variant='scrollable'
            scrollButtons='on'
          >
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={<Icon>assignment_ind</Icon>}
              label='Overview'
            />
            <Tab disableRipple icon={<Icon>assignment</Icon>} label='Info' />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Scopes
                      ? this.state.Data.Scopes.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>build</Icon>
                </Badge>
              }
              label='Scopes'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Trips
                      ? this.state.Data.Trips.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>local_shipping</Icon>
                </Badge>
              }
              label='Trips'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Usage
                      ? this.state.Data.Usage.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>format_list_bulleted</Icon>
                </Badge>
              }
              label='Usage'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.POs
                      ? this.state.Data.POs.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>list_alt</Icon>
                </Badge>
              }
              label='Purchasing'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Estimates
                      ? this.state.Data.Estimates.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>format_list_numbered</Icon>
                </Badge>
              }
              label='Estimates'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Invoices
                      ? this.state.Data.Invoices.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>receipt</Icon>
                </Badge>
              }
              label='Billing'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data &&
                    this.state.Data.Site &&
                    this.state.Data.Site.Data &&
                    this.state.Data.Site.Data.Units
                      ? this.state.Data.Site.Data.Units.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>build</Icon>
                </Badge>
              }
              label='Service Units'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Attachments
                      ? this.state.Data.Attachments.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>attach_file</Icon>
                </Badge>
              }
              label='Attachments'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={<Icon>history</Icon>}
              label='History'
            />
            <Tab
              disableRipple
              disabled={
                ['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
              }
              icon={<Icon>insert_chart</Icon>}
              label='Dashboard'
            />
          </Tabs>
        )}
      </>
    )
  }

  ToolbarContents = () => {
    const { securables } = this.props
    const { Trip, value, showTabs, Data } = this.state
    const handlerAccessLevel = _.find(securables, { Securable: 'call-handler' })
    const inspectorAccessLevel = _.find(securables, { Securable: 'inspector' })
    const accessLevel = _.find(securables, { Securable: 'work-order-dialog' })
    const trip = Trip ? _.find(Data.Trips, { Trip }) : null
    return (
      <>
        {['new', 'site'].indexOf(this.props.workOrderDialog.type) < 0 && (
          <React.Fragment>
            <Hidden smUp>
              <IconButton
                style={{
                  color: 'white',
                  marginLeft: -16,
                }}
                onClick={e => {
                  e.stopPropagation()
                  if (value !== 0 || this.state.viewSummary) {
                    this.setState({
                      ...this.state,
                      value: 0,
                      viewSummary: false,
                    })
                  } else {
                    this.toggleActions(e)
                  }
                }}
              >
                {value !== 0 || this.state.viewSummary ? (
                  <Icon className='text-32'>keyboard_arrow_left</Icon>
                ) : (
                  <Icon className='text-24 mt-4'>menu</Icon>
                )}
              </IconButton>
            </Hidden>
            <Hidden xsDown>
              <IconButton
                style={{
                  color: 'white',
                  marginLeft: -16,
                }}
                onClick={() => {
                  this.setState({ ...this.state, value: 0, viewSummary: false })
                }}
              >
                {value !== 0 || this.state.viewSummary ? (
                  <Icon className='text-32'>keyboard_arrow_left</Icon>
                ) : (
                  <SPRoundIcon className='cursor-pointer text-32' />
                )}
              </IconButton>
            </Hidden>
          </React.Fragment>
        )}
        <Typography variant='subtitle1' color='inherit'>
          {['new', 'site'].indexOf(this.props.workOrderDialog.type) > -1
            ? 'New Work Order'
            : `Work Order #${this.state.WorkOrder}${
                trip ? ` Trip #${trip.Trip}` : ''
              }`}
        </Typography>
        <IconButton
          style={{
            color: 'white',
          }}
          onClick={() => {
            this.toggleTabs()
          }}
        >
          <Icon className='align-middle mt-4'>
            {showTabs ? 'expand_less' : 'expand_more'}
          </Icon>
        </IconButton>
      </>
    )
  }

  heightUpdateFxn = (position, finalPosition, dialogHeight) => {
    this.setState({
      ...this.state,
      location: position,
      posSet: finalPosition,
      dialogHeight: dialogHeight,
    })
  }

  render() {
    const {
      workOrderDialog,
      agreementOpen,
      estimateOpen,
      workOrderScopeOpen,
      workOrderUsageOpen,
      customerOpen,
      customerSiteOpen,
      invoiceOpen,
      serviceUnitOpen,
      purchaseOrderOpen,
    } = this.props
    // if (workOrderDialog.props.open && !agreementOpen && !estimateOpen && !workOrderScopeOpen && !workOrderUsageOpen && !customerOpen && !customerSiteOpen && !invoiceOpen && !serviceUnitOpen && !purchaseOrderOpen) {
    if (!workOrderDialog.props.open) {
      return ''
    } else {
      const {
        classes,
        companies,
        securables,
        user,
        technician,
        technicians,
        workOrderDialog,
        addWorkOrder,
        materials,
        laborTypes,
        serviceUnitTypes,
        serviceUnitCategories,
        dialer,
        updateWorkOrder,
        services,
        arrivalWindows,
        removeWorkOrder,
        googleMapsApiKey,
        openCustomer,
        openSite,
        openNewWorkOrderScopeDialog,
        openNewTripDialog,
        openNewWorkOrderUsageDialog,
        openEditWorkOrderScopeDialog,
        users,
        openNewInvoiceDialog,
        openNewPurchaseOrderDialog,
        openEditAgreementDialog,
        serviceTypes,
        businessUnits,
        divisions,
        departments,
        rateTemplates,
        formOfPayments,
        openEditTripDialog,
      } = this.props
      const {
        value,
        holdInfo,
        histTab,
        inspector,
        selectedTask,
        loadingAgreement,
        newEstimate,
        updatedEstimate,
        anchorQuote,
        anchorQuoteEl,
        unitMenuEl,
        unitMenuData,
        unitMenuUnit,
        scheduleEstimate,
        newPhoto,
        newPhotoList,
        promptReturnTrip,
        scheduleReturnTrip,
        confirmComplete,
        returnPrompt,
        searchingSite,
        siteList,
        siteDescription,
        confirmationData,
        loading,
        customerEmailData,
        selectedUnit,
        selectedForm,
        selectedScope,
        selectedForms,
        selectedChecklist,
        selectedChecklists,
        selectedDivision,
        tripInfo,
        expanded,
        streetView,
        WorkOrder,
        Co,
        ID,
        BillingData,
        Address,
        Latitude,
        Longitude,
        Data,
        actionPanel,
        showTabs,
        actionEl,
        actionManage,
        actionAdd,
        Trip,
        menuEl,
        siteMenuEl,
        customerMenuEl,
      } = this.state
      const trip = Trip ? _.find(Data.Trips, { Trip }) : null
      const priorities = [
        { text: 'Critical', color: 'red' },
        { text: 'High', color: 'red' },
        { text: 'Medium', color: 'gold' },
        { text: 'Low', color: 'inherit' },
      ]
      let sortAgreements = []
      if (Data.Site && Data.Site.Data && Data.Site.Data.Agreements) {
        const { Agreements } = Data.Site.Data
        const active = _.filter(Agreements, { ActiveYN: 'Y' })
        const inactive = _.filter(Agreements, o => {
          return (
            o.ActiveYN === 'N' && !_.find(active, { Agreement: o.Agreement })
          )
        })
        sortAgreements = _.orderBy(
          [...active, ...inactive],
          ['Cycle', 'Revision'],
          ['asc', 'desc'],
        )
      }

      // let currentAgreements = _.filter(sortAgreements, (o) => moment(a.StartDate).toDate() < moment(o.StartDate).toDate() && moment(a.StartDate).toDate() <= moment().startOf('day').toDate() && moment(a.EndDate).toDate() >= moment().startOf('day').toDate())
      // let pastAgreements = _.filter(sortedAgreements, (o) => moment(o.EndDate).toDate() < moment().startOf('day').toDate());
      // let futureAgreements = _.filter(sortedAgreements, (o) => moment(o.EndDate).toDate() > moment().startOf('day').toDate());

      // sortAgreements = _.filter(sortAgreements, (o) => _.findIndex(sortAgreements, (a) => o.Agreement === a.Agreement && ((moment(a.StartDate).toDate() < moment(o.StartDate).toDate() && moment(a.StartDate).toDate() <= new Date() && moment(a.EndDate).toDate() >= new Date()) || (moment(a.StartDate).toDate() > moment(o.StartDate).toDate() && moment(a.StartDate).toDate() <= new Date() && moment(a.EndDate).toDate() >= new Date()))) < 0)

      const userAvatar = '../../assets/images/avatars/profile.jpg'
      const chAccess = _.find(securables, { Securable: 'call-handler' })
      const accessLevel = _.find(securables, { Securable: 'work-order-dialog' })
      const inspectorAccessLevel = _.find(securables, {
        Securable: 'inspector',
      })
      const tripAccessLevel = _.find(securables, {
        Securable: 'work-order-trips',
      })
      const billingAccessLevel = _.find(securables, {
        Securable: 'work-order-billing',
      })
      const attachmentAccessLevel = _.find(securables, {
        Securable: 'work-order-attachments',
      })
      const agreementAccessLevel = _.find(securables, {
        Securable: 'work-order-agreements',
      })
      const purchaseAccessLevel = _.find(securables, {
        Securable: 'work-order-purchases',
      })
      const scopeAccessLevel = _.find(securables, {
        Securable: 'work-order-scopes',
      })
      const handlerAccessLevel = _.find(securables, {
        Securable: 'call-handler',
      })
      const catalogAccessLevel = _.find(securables, { Securable: 'catalog' })
      const assigned =
        trip && technician && trip.Technician === technician.Technician
      const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
      const techAvatar = 'assets/images/avatars/profile.jpg'
      const { Scopes } = Data
      const first = _.find(Scopes, { ReadyToBillYN: 'Y' })
      const dv = first
        ? _.find(divisions, {
            BusinessUnit: first.BusinessUnit,
            Division: first.Division,
          })
        : null
      const now = new Date()
      const statusProps = [
        {
          text: 'Scheduled',
          icon: 'access_time',
          color: 'rgb(100, 100, 200)',
          dateProp: 'ScheduledDate',
          timeProp: 'ScheduledTime',
        },
        {
          text: 'Notified',
          icon: 'offline_bolt',
          color: 'rgb(0, 150, 250)',
          dateProp: 'NotifiedDate',
          timeProp: 'NotifiedTime',
        },
        {
          text: 'Accepted',
          icon: 'offline_pin',
          color: 'rgb(0, 50, 250)',
          dateProp: 'AcceptedDate',
          timeProp: 'AcceptedTime',
        },
        {
          text: 'Rejected',
          icon: 'cancel',
          color: 'rgb(210, 0, 0)',
          dateProp: 'RejectedDate',
          timeProp: 'RejectedTime',
        },
        {
          text: 'En-Route',
          icon: 'explore',
          color: 'rgb(50, 170, 200)',
          dateProp: 'EnRouteDate',
          timeProp: 'EnRouteTime',
        },
        {
          text: 'Arrived',
          icon: 'my_location',
          color: 'rgb(50, 150, 100)',
          dateProp: 'ArrivedDate',
          timeProp: 'ArrivedTime',
        },
        {
          text: 'In Progress',
          icon: 'play_circle_outline',
          color: 'rgb(150, 200, 50)',
          dateProp: 'InProgressDate',
          timeProp: 'InProgressTime',
        },
        {
          text: 'On Hold',
          icon: 'pause_circle_outline',
          color: 'rgb(210, 100, 100)',
          dateProp: 'OnHoldDate',
          timeProp: 'OnHoldTime',
        },
        {
          text: 'Completed',
          icon: 'check_circle',
          color: 'rgb(100, 100, 100)',
          dateProp: 'CompletedDate',
          timeProp: 'CompletedTime',
        },
      ]
      if (trip) {
        const start = moment(trip.InProgressTime || trip.EnRouteTime)
        const end = moment(trip.CompletedTime)
        const sec = end.diff(start, 'seconds')
        trip.ActualDuration = sec / 60 / 60
      }

      const canceled =
        trip &&
        trip.Data &&
        trip.Data.Assignments &&
        Data &&
        Data.Scopes &&
        _.filter(
          trip.Data.Assignments,
          o =>
            _.filter(Data.Scopes, s => {
              return s.Scope === o.Scope && s.Status < 4
            }).length > 0,
        ).length < 1
      const reqBy = this.state.Data.RequestedByContact
      const siteContact = this.state.Data.Contact
      const scopes = _.filter(this.state.Data.Scopes, o =>
        !trip
          ? 1 === 1
          : _.findIndex(
              trip && trip.Data && trip.Data.Assignments
                ? trip.Data.Assignments
                : [],
              { Scope: o.Scope },
            ) > -1,
      )
      const coInfo = _.find(companies, { Co }).Data.Co
      const woOpt = coInfo && coInfo.Data ? coInfo.Data.WorkOrderOptions : null
      const siteContacts =
        this.state.Data.Site && this.state.Data.Site.Data
          ? this.state.Data.Site.Data.Contacts
          : []
      const custContacts =
        this.state.Data.Customer && this.state.Data.Customer.Data
          ? this.state.Data.Customer.Data.Contacts
          : []

      const contactsList = this.state.isSiteContact
        ? siteContacts
        : custContacts
      const primaryRecord = contactsList.find(item => item.PrimaryYN === 'Y')
      const dialogProps = {
        ...workOrderDialog.props,
        className: classes.root,
        onClose: this.closeComposeDialog,
        fullWidth: true,
        maxWidth: 'md',
        //hideBackdrop: true,
        disableEnforceFocus: true,
      }

      //Work Order #${this.state.WorkOrder}

      let increaseFontSize = false
      if (this.props.userPreferences) {
        let pref = JSON.parse(this.props.userPreferences)
        if (pref.IncreaseFontSize) {
          increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
        }
      }

      let classes2 = this.props.classes
      const bigFontSetting = true

      let initialWidthSet =
        this.props.workOrderDialog &&
        this.props.workOrderDialog.data &&
        this.props.workOrderDialog.data.initialDialogWidth === null
          ? this.props.workOrderDialog.data.initialDialogWidth
          : 960

      const Taxability =
        this.state &&
        this.state.Data &&
        this.state.Data.Customer &&
        this.state.Data.Customer.Data &&
        this.state.Data.Customer.Data.Taxability

      return (
        <Media
          queries={{
            small: '(max-width: 600px)',
            medium: '(min-width: 1200px)' /**/,
          }}
        >
          {matches => (
            <DraggableDialog
              SlideUp={SlideUp}
              matches={matches}
              dialogProps={{ ...dialogProps }}
              AppBarChildren={this.AppBarContents}
              ToolbarChildren={this.ToolbarContents}
              closeComposeDialog={this.closeComposeDialog.bind(this)}
              closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
                this,
              )}
              header={`Work Order #${this.state.WorkOrder}`}
              type='Work Order'
              picRef={this.ref.current}
              icon='assignment'
              dialogState={this.state}
              reopenDialogFxn={this.props.openPreloadWorkOrderDialogSplitScreen}
              //reopenDialogFxn={this.props.openPreloadWorkOrderDialog}
              description={this.state.Description}
              storeDialog={workOrderDialog}
              TypeState={this.state.WorkOrder}
              posSet={this.state.posSet}
              initialWidthSet={initialWidthSet}
              minHeight={this.state.minHeight}
              location={this.state.location}
              heightUpdateFxn={this.heightUpdateFxn.bind(this)}
              dialogHeight={this.state.dialogHeight}
            >
              <EmailLogDialog
                title={`Work Order #${this.state.WorkOrder} Email Log`}
                type='WO'
                rec={this.state.ID}
                open={this.state.showEmailLogs}
                onClose={() => this.setState({ showEmailLogs: false })}
              />

              <WorkOrdersPDF
                open={this.state.openWoPDF}
                setStateFxn={this.setState.bind(this)}
                classes={classes}
              />
              <WorkOrdersContactList
                label={
                  this.state.isSiteContact
                    ? 'Site Contacts'
                    : 'Customer Contacts'
                }
                contact={null}
                open={this.state.openWoContactList}
                onUpdate={Contacts => {
                  const { billPhone, billEmail } =
                    getBillingInformation(Contacts)

                  if (this.state.isSiteContact) {
                    this.setState({
                      addContact: false,
                      Data: {
                        ...this.state.Data,
                        Site: {
                          ...this.state.Data.Site,
                          Data: { ...this.state.Data.Site.Data, Contacts },
                        },
                      },
                    })
                  } else {
                    const customer = {
                      ...this.state.Data.Customer,
                      BillPhone: billPhone,
                      BillEmail: billEmail,
                      Data: { ...this.state.Data.Customer.Data, Contacts },
                    }

                    this.setState({
                      addContact: false,
                      Data: {
                        ...this.state.Data,
                        Customer: customer,
                        Billing: [
                          { ...this.state.Data.Billing[0], Customer: customer },
                        ],
                      },
                    })
                  }
                }}
                siteContact={
                  this.state.isSiteContact ? this.state.SiteContact : null
                }
                updateSiteContact={
                  this.state.isSiteContact
                    ? SiteContact => {
                        this.setState({ SiteContact }, () =>
                          updateWorkOrder(this.state),
                        )
                        console.log(this.state)
                        // return updateWorkOrder({...this.state, SiteContact: anchorData});
                      }
                    : null
                }
                onCancel={() => this.setState({ addContact: false })}
                details={contactsList || []}
                site={this.state.isSiteContact ? Data.Site : null}
                customer={Data.Customer}
                setStateFxn={this.setState.bind(this)}
              />
              {newPhotoList &&
                newPhotoList.map(item => (
                  <AttachmentDialog
                    file={item}
                    open={true}
                    edit={true}
                    onClose={() =>
                      this.setState(prevState => ({
                        newPhotoList: prevState.newPhotoList.slice(0, -1),
                      }))
                    }
                    onSave={this.uploadImg}
                  />
                ))}
              {trip && this.state.confirmComplete && (
                <ConfirmationDialog
                  open={this.state.confirmComplete}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4 text-orange'>
                        warning
                      </Icon>
                      Reminder - Unbilled Items
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      {`This Work Order contains ${
                        confirmComplete.count
                      } unbilled line item${
                        confirmComplete.count > 1 ? 's' : ''
                      }. Would you like to create an Invoice now?`}
                    </div>
                  }
                  confirmText={'Yes'}
                  cancelText='No'
                  onConfirm={() =>
                    this.setState(
                      { ...this.state, confirmComplete: null, tripInfo: false },
                      this.launchInvoice,
                    )
                  }
                  onCancel={() => {
                    confirmComplete.trip.Status = 8
                    this.props.updateTrip({
                      ...confirmComplete.trip,
                      Status: 8,
                    })
                    this.setState({
                      ...this.state,
                      confirmComplete: null,
                      tripInfo: false,
                      tcPrompt: true,
                    })
                  }}
                />
              )}
              {newEstimate &&
                !newEstimate.autoInvoice &&
                !promptReturnTrip &&
                !this.state.confirmEstimateAttachment && (
                  <ConfirmationDialog
                    open={newEstimate}
                    title={
                      <div>
                        <Icon
                          className='mr-6 align-middle mb-4'
                          style={{ transform: 'rotate(-90deg)' }}
                        >
                          attachment
                        </Icon>
                        Schedule Work for Estimate #{newEstimate.EstimateNumber}
                        ?
                      </div>
                    }
                    content={
                      !scheduleEstimate ? (
                        <div className='w-full'>
                          Has the proposed work been approved by the customer?
                        </div>
                      ) : (
                        <div>
                          <div className='w-full'>
                            Can the proposed work be performed on this visit?
                            {scopes.length > 1 &&
                              ' If so, please select which Scope to assign this estimate to:'}
                          </div>
                          {scopes.length > 1 && (
                            <React.Fragment>
                              <br />
                              <TextField
                                className={classes.formControl}
                                label='Assign to Scope'
                                select
                                id='estimate-scope'
                                name='estimateScope'
                                value={
                                  this.state.estimateScope ||
                                  (scopes[0] || {}).Scope
                                }
                                onChange={this.handleChange}
                                variant='outlined'
                                fullWidth
                              >
                                {scopes.map((value, index) => (
                                  <MenuItem key={index} value={value.Scope}>
                                    <div className='flex'>
                                      <Avatar
                                        style={{ marginRight: 8 }}
                                        classes={{ root: classes.avatarRoot }}
                                        className={classes.avatar}
                                      >
                                        {value.Scope}
                                      </Avatar>
                                      {`${
                                        value.Data.Service.Description ||
                                        ''.substr(0, 30)
                                      }`}
                                    </div>
                                  </MenuItem>
                                ))}
                              </TextField>
                            </React.Fragment>
                          )}
                        </div>
                      )
                    }
                    confirmText='Yes'
                    cancelText='No'
                    onCancel={() =>
                      this.setState({
                        ...this.state,
                        newEstimate: scheduleEstimate ? newEstimate : false,
                        promptReturnTrip: scheduleEstimate,
                        promptNewEstimate: !scheduleEstimate,
                      })
                    }
                    onConfirm={() =>
                      this.setState(
                        {
                          ...this.state,
                          scheduleEstimate: !scheduleEstimate,
                          confirmEstimateAttachment: scheduleEstimate,
                        },
                        () => {
                          if (scheduleEstimate) {
                            const scope =
                              this.state.estimateScope ||
                              (scopes[0] || {}).Scope
                            if (scope) {
                              const scopeData = _.find(scopes, { Scope: scope })
                              if (scopeData) {
                                this.props.updateWorkOrderScope({
                                  ...scopeData,
                                  Estimate: newEstimate.Estimate,
                                  EstimateBusinessUnit:
                                    newEstimate.BusinessUnit,
                                  EstimateDivision: newEstimate.Division,
                                })
                              }
                            }
                          }
                        },
                      )
                    }
                    // onConfirm={() => this.setState({ ...this.state, newEstimate: false }, () => { const scope = this.state.estimateScope || (scopes[0] || {}).Scope; if (scope) { this.props.updateWorkOrderScope({ ..._.find(scopes, { Scope: scope }), Estimate: newEstimate.Estimate, }) } })}
                  />
                )}
              {!newEstimate && this.state.promptNewEstimate && (
                <ConfirmationDialog
                  open={this.state.promptNewEstimate}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4' color='primary'>
                        format_list_numbered
                      </Icon>
                      Create Another Estimate?
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      Do you need to create another Estimate in order to invoice
                      for this visit?
                    </div>
                  }
                  confirmText={'Yes'}
                  cancelText='No'
                  onCancel={() =>
                    this.setState({ ...this.state, promptNewEstimate: false })
                  }
                  onConfirm={() =>
                    this.setState(
                      { ...this.state, promptNewEstimate: false },
                      () => this.launchEstimate(true),
                    )
                  }
                />
              )}
              {newEstimate && newEstimate.autoInvoice && (
                <ConfirmationDialog
                  open={newEstimate && newEstimate.autoInvoice}
                  title={
                    <div>
                      <Icon
                        className='mr-6 align-middle mb-4'
                        color='secondary'
                      >
                        receipt
                      </Icon>
                      Create Invoice
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      Would you like to create an Invoice from this estimate?
                    </div>
                  }
                  confirmText='Yes'
                  cancelText='No'
                  onCancel={() =>
                    this.setState(
                      {
                        ...this.state,
                        newEstimate: false,
                        scheduleEstimate: false,
                      },
                      () => {
                        const scope =
                          this.state.estimateScope || (scopes[0] || {}).Scope
                        if (scope) {
                          this.props.updateWorkOrderScope({
                            ..._.find(scopes, { Scope: scope }),
                            Estimate: newEstimate.Estimate,
                            EstimateBusinessUnit: newEstimate.BusinessUnit,
                            EstimateDivision: newEstimate.Division,
                          })
                        }
                      },
                    )
                  }
                  onConfirm={() =>
                    this.setState(
                      {
                        ...this.state,
                        newEstimate: false,
                        scheduleEstimate: false,
                      },
                      () => {
                        const scope =
                          this.state.estimateScope || (scopes[0] || {}).Scope
                        if (scope) {
                          this.props.updateWorkOrderScope(
                            {
                              ..._.find(scopes, { Scope: scope }),
                              Estimate: newEstimate.Estimate,
                              EstimateBusinessUnit: newEstimate.BusinessUnit,
                              EstimateDivision: newEstimate.Division,
                            },
                            true,
                          )
                        }
                      },
                    )
                  }
                />
              )}
              {newEstimate && this.state.confirmEstimateAttachment && (
                <ConfirmationDialog
                  open={this.state.confirmEstimateAttachment}
                  title={
                    <div>
                      <Icon
                        className='mr-6 align-middle mb-4'
                        color='secondary'
                      >
                        check_circle_outline
                      </Icon>
                      You're Good to Go!
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      Estimate #{newEstimate.EstimateNumber} is now attached to
                      the current Work Order.
                    </div>
                  }
                  confirmText={'OK'}
                  cancelText=''
                  onCancel={() =>
                    this.setState({
                      ...this.state,
                      confirmEstimateAttachment: false,
                      newEstimate: false,
                    })
                  }
                  onConfirm={() =>
                    this.setState({
                      ...this.state,
                      confirmEstimateAttachment: false,
                      newEstimate: false,
                    })
                  }
                />
              )}
              {newEstimate && promptReturnTrip && (
                <ConfirmationDialog
                  open={newEstimate}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4'>today</Icon>
                      Schedule Work for Estimate #{newEstimate.EstimateNumber}?
                    </div>
                  }
                  content={
                    !scheduleReturnTrip ? (
                      <div className='w-full'>
                        Who will be performing the proposed work?
                      </div>
                    ) : (
                      <div>
                        <div className='w-full'>
                          Please select which Scope to assign this estimate to:
                        </div>
                        {scopes.length > 1 && (
                          <React.Fragment>
                            <br />
                            <TextField
                              className={classes.formControl}
                              label='Assign to Scope'
                              select
                              id='estimate-scope'
                              name='estimateScope'
                              value={
                                this.state.estimateScope ||
                                (scopes[0] || {}).Scope
                              }
                              onChange={this.handleChange}
                              variant='outlined'
                              fullWidth
                            >
                              {scopes.map((value, index) => (
                                <MenuItem key={index} value={value.Scope}>
                                  <div className='flex'>
                                    <Avatar
                                      style={{ marginRight: 8 }}
                                      classes={{ root: classes.avatarRoot }}
                                      className={classes.avatar}
                                    >
                                      {value.Scope}
                                    </Avatar>
                                    {`${
                                      value.Data.Service.Description ||
                                      ''.substr(0, 30)
                                    }`}
                                  </div>
                                </MenuItem>
                              ))}
                            </TextField>
                          </React.Fragment>
                        )}
                      </div>
                    )
                  }
                  confirmText={!scheduleReturnTrip ? 'I Will' : 'Yes'}
                  cancelText={!scheduleReturnTrip ? 'Someone Else' : 'Cancel'}
                  onCancel={() =>
                    this.setState({
                      ...this.state,
                      newEstimate: false,
                      promptReturnTrip: false,
                      scheduleReturnTrip: false,
                      scheduleEstimate: false,
                      notifyOfficeOfEstimate: !scheduleReturnTrip,
                    })
                  }
                  onConfirm={() =>
                    this.setState(
                      {
                        ...this.state,
                        newEstimate:
                          scheduleReturnTrip || scopes.length < 2
                            ? false
                            : newEstimate,
                        scheduleReturnTrip: !(
                          scheduleReturnTrip || scopes.length < 2
                        ),
                        promptReturnTrip:
                          scheduleReturnTrip || scopes.length < 2
                            ? false
                            : promptReturnTrip,
                        scheduleEstimate:
                          scheduleReturnTrip || scopes.length < 2
                            ? false
                            : scheduleEstimate,
                        returnPrompt: scheduleReturnTrip || scopes.length < 2,
                      },
                      () => {
                        if (scheduleReturnTrip || scopes.length < 2) {
                          const scope =
                            this.state.estimateScope || (scopes[0] || {}).Scope
                          if (scope) {
                            this.props.updateWorkOrderScope({
                              ..._.find(scopes, { Scope: scope }),
                              Estimate: newEstimate.Estimate,
                              EstimateBusinessUnit: newEstimate.BusinessUnit,
                              EstimateDivision: newEstimate.Division,
                            })
                          }
                        }
                      },
                    )
                  }
                  // onConfirm={() => this.setState({ ...this.state, newEstimate: false }, () => { const scope = this.state.estimateScope || (scopes[0] || {}).Scope; if (scope) { this.props.updateWorkOrderScope({ ..._.find(scopes, { Scope: scope }), Estimate: newEstimate.Estimate, }) } })}
                />
              )}
              {this.state.notifyOfficeOfEstimate && (
                <ConfirmationDialog
                  open={this.state.notifyOfficeOfEstimate}
                  title={
                    <div>
                      <Icon
                        className='mr-6 align-middle mb-4'
                        color='secondary'
                      >
                        check_circle_outline
                      </Icon>
                      We'll Take it from Here
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      Looks like someone from the Main Office will need to
                      finish scheduling the proposed work - Please contact your
                      supervisor with any questions or concerns.
                    </div>
                  }
                  confirmText={'OK'}
                  cancelText=''
                  onCancel={() =>
                    this.setState({
                      ...this.state,
                      notifyOfficeOfEstimate: false,
                    })
                  }
                  onConfirm={() =>
                    this.setState({
                      ...this.state,
                      notifyOfficeOfEstimate: false,
                    })
                  }
                />
              )}
              {/* {updatedEstimate &&
                            <ConfirmationDialog
                                open={updatedEstimate}
                                title={<div><Icon className="mr-6 align-middle mb-4" style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>Update Scope #{updatedEstimate.AssignedToWorkOrderScope} Pricing?</div>}
                                content={
                                    <div className="w-full">{`Would you like to update Scope #${updatedEstimate.AssignedToWorkOrderScope} pricing?`}</div>
                                }
                                confirmText="Update Pricing"
                                cancelText="No Thanks"
                                onCancel={() => this.setState({ ...this.state, updatedEstimate: false })}
                                onConfirm={() => this.setState({ ...this.state, updatedEstimate: false }, () => this.props.updateWorkOrderScope({ ..._.find(scopes, { Scope: updatedEstimate.AssignedToWorkOrderScope }), Estimate: updatedEstimate.Estimate, RefreshEstimate: "Y" }))}
                            />
                        } */}
              {value === 0 && this.state.viewSummary && (
                <TabContainer
                  padding='12px'
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <WorkOrderHistory data={this.state} />
                </TabContainer>
              )}
              {value === 0 && !this.state.viewSummary && (
                <TabContainer
                  padding='0px'
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <React.Fragment>
                    {this.state.Data.Customer.RefuseServiceYN === 'Y' && (
                      <div className='w-full p-12'>
                        <div
                          onClick={() =>
                            this.props.openCustomer(this.state.Data.Customer)
                          }
                          className='p-8 rounded py-24 w-full cursor-pointer'
                          style={{
                            backgroundColor: '#f9f9f9',
                            border: '1px dashed red',
                          }}
                        >
                          <Typography className='text-16 font-bold text-red w-full text-center'>
                            <Icon color='error' className='mr-6 align-middle'>
                              explore_off
                            </Icon>
                            DO NOT SERVICE
                          </Typography>
                          <div className='w-full'>
                            <div className='italic mt-4 text-center'>
                              "{this.state.Data.Customer.RefusalReason || 'N/A'}
                              "
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='w-full flex' ref={this.ref}>
                      {(actionPanel || !matches.small) && (
                        <div
                          className={
                            !actionPanel
                              ? 'hidden sm:block'
                              : 'absolute pin-l sm:relative'
                          }
                          style={
                            !matches.small
                              ? { borderRight: '1px solid lightgrey' }
                              : {
                                  borderRight: '1px solid lightgrey',
                                  boxShadow: '0px 0px 8px -2px #3f3f3f',
                                  background: '#fff',
                                  zIndex: 2,
                                  maxHeight: '100%',
                                  bottom: 0,
                                  overflow: 'auto',
                                  top: 0,
                                  paddingTop: `calc(env(safe-area-inset-top) + ${
                                    showTabs ? 128 : 56
                                  }px`,
                                  paddingBottom: 'env(safe-area-inset-bottom)',
                                }
                          }
                        >
                          <Menu
                            id='usage-menu'
                            anchorEl={actionEl}
                            open={Boolean(actionEl)}
                            onClose={() =>
                              this.setState({
                                ...this.state,
                                actionEl: null,
                                actionAdd: null,
                                actionManage: null,
                              })
                            }
                          >
                            <MenuItem onClick={actionAdd}>Add New</MenuItem>
                            <MenuItem onClick={actionManage}>Manage</MenuItem>
                          </Menu>
                          <ActionPanel animate={actionPanel && matches.small}>
                            <FuseAnimateGroup
                              enter={{
                                animation: 'transition.slideUpBigIn',
                              }}
                              leave={{
                                animation: 'transition.slideUpBigOut',
                              }}
                            >
                              {billingAccessLevel &&
                                (!woOpt || woOpt.HideLaborButtonYN !== 'Y') && (
                                  <div className='w-full p-16 pb-4 justify-center'>
                                    <Badge
                                      classes={{ badge: classes.actionBadge }}
                                      badgeContent={Number(
                                        _.sumBy(Data.Usage, { Type: 1 }),
                                      )}
                                      color='error'
                                    >
                                      <Fab
                                        color='primary'
                                        aria-label='labor'
                                        className={classNames(
                                          increaseFontSize ? 'ml-12' : null,
                                          classes.actionButton,
                                        )}
                                        disabled={
                                          (!assigned &&
                                            billingAccessLevel &&
                                            billingAccessLevel.AccessLevel !==
                                              'F') ||
                                          (assigned && trip.Status < 4) ||
                                          canceled
                                        }
                                        onClick={event => {
                                          if (
                                            _.sumBy(Data.Usage, { Type: 1 }) > 0
                                          ) {
                                            this.setAction(
                                              event.currentTarget,
                                              () => {
                                                openNewWorkOrderUsageDialog(
                                                  {
                                                    Co: Co,
                                                    WorkOrder: WorkOrder,
                                                    Scope: trip
                                                      ? _.filter(
                                                          Data.Scopes,
                                                          o => {
                                                            return (
                                                              _.findIndex(
                                                                trip.Data
                                                                  .Assignments,
                                                                {
                                                                  Scope:
                                                                    o.Scope,
                                                                },
                                                              ) > -1
                                                            )
                                                          },
                                                        )[0].Scope
                                                      : this.state.Data
                                                          .Scopes[0].Scope,
                                                    Type: 1,
                                                    UOM: 'HR',
                                                    Data: {
                                                      WorkOrder: this.state,
                                                      Assignments: trip
                                                        ? trip.Data.Assignments
                                                        : null,
                                                    },
                                                    //workOrder: this.props.workOrder
                                                  },
                                                  Taxability
                                                    ? Taxability
                                                    : null,
                                                )
                                                this.setState({
                                                  ...this.state,
                                                  actionEl: null,
                                                  actionAdd: null,
                                                  actionManage: null,
                                                  laborHover: false,
                                                })
                                              },
                                              () => {
                                                this.setState({
                                                  ...this.state,
                                                  value: 4,
                                                  actionEl: null,
                                                  actionAdd: null,
                                                  actionManage: null,
                                                  laborHover: false,
                                                })
                                              },
                                            )
                                          } else {
                                            openNewWorkOrderUsageDialog(
                                              {
                                                Co: Co,
                                                WorkOrder: WorkOrder,
                                                Scope: trip
                                                  ? _.filter(Data.Scopes, o => {
                                                      return (
                                                        _.findIndex(
                                                          trip.Data.Assignments,
                                                          { Scope: o.Scope },
                                                        ) > -1
                                                      )
                                                    })[0].Scope
                                                  : this.state.Data.Scopes[0]
                                                      .Scope,
                                                Type: 1,
                                                UOM: 'HR',
                                                Data: {
                                                  WorkOrder: this.state,
                                                  Assignments: trip
                                                    ? trip.Data.Assignments
                                                    : null,
                                                },
                                                //workOrder: this.props.workOrder
                                              },
                                              Taxability ? Taxability : null,
                                            )
                                          }
                                        }}
                                        onMouseEnter={() =>
                                          this.setState({
                                            ...this.state,
                                            laborHover: true,
                                          })
                                        }
                                        onMouseLeave={() =>
                                          this.setState({
                                            ...this.state,
                                            laborHover: false,
                                          })
                                        }
                                      >
                                        {this.state.laborHover ? (
                                          <Icon>add</Icon>
                                        ) : (
                                          <Icon>person_pin</Icon>
                                        )}
                                      </Fab>
                                    </Badge>
                                    <Typography
                                      component='div'
                                      variant='caption'
                                      className={classNames(
                                        increaseFontSize
                                          ? 'text-16'
                                          : 'text-12',
                                        'w-full text-center font-bold mt-4',
                                      )}
                                    >
                                      Labor
                                    </Typography>
                                  </div>
                                )}
                              {billingAccessLevel &&
                                (!woOpt ||
                                  woOpt.HideMaterialsButtonYN !== 'Y') && (
                                  <div className='w-full p-16 pb-4 justify-center'>
                                    <Badge
                                      classes={{ badge: classes.actionBadge }}
                                      badgeContent={Number(
                                        _.sumBy(Data.Usage, { Type: 2 }),
                                      )}
                                      color='error'
                                    >
                                      <Fab
                                        color='primary'
                                        aria-label='scan'
                                        className={classNames(
                                          increaseFontSize ? 'ml-12' : null,
                                          classes.actionButton,
                                        )}
                                        disabled={
                                          (!assigned &&
                                            billingAccessLevel &&
                                            billingAccessLevel.AccessLevel !==
                                              'F') ||
                                          (assigned && trip.Status < 4) ||
                                          canceled
                                        }
                                        onClick={event => {
                                          if (
                                            _.sumBy(Data.Usage, { Type: 2 }) > 0
                                          ) {
                                            this.setAction(
                                              event.currentTarget,
                                              () => {
                                                openNewWorkOrderUsageDialog(
                                                  {
                                                    Co: Co,
                                                    WorkOrder: WorkOrder,
                                                    Scope: trip
                                                      ? _.filter(
                                                          Data.Scopes,
                                                          o => {
                                                            return (
                                                              _.findIndex(
                                                                trip.Data
                                                                  .Assignments,
                                                                {
                                                                  Scope:
                                                                    o.Scope,
                                                                },
                                                              ) > -1
                                                            )
                                                          },
                                                        )[0].Scope
                                                      : this.state.Data
                                                          .Scopes[0].Scope,
                                                    Type: 2,
                                                    UOM: 'EA',
                                                    Data: {
                                                      WorkOrder: this.state,
                                                      Assignments: trip
                                                        ? trip.Data.Assignments
                                                        : null,
                                                    },
                                                  },
                                                  Taxability
                                                    ? Taxability
                                                    : null,
                                                )
                                                this.setState({
                                                  ...this.state,
                                                  actionEl: null,
                                                  actionAdd: null,
                                                  actionManage: null,
                                                  materialHover: false,
                                                })
                                              },
                                              () => {
                                                this.setState({
                                                  ...this.state,
                                                  value: 4,
                                                  actionEl: null,
                                                  actionAdd: null,
                                                  actionManage: null,
                                                  materialHover: false,
                                                })
                                              },
                                            )
                                          } else {
                                            openNewWorkOrderUsageDialog(
                                              {
                                                Co: Co,
                                                WorkOrder: WorkOrder,
                                                Scope: trip
                                                  ? _.filter(Data.Scopes, o => {
                                                      return (
                                                        _.findIndex(
                                                          trip.Data.Assignments,
                                                          { Scope: o.Scope },
                                                        ) > -1
                                                      )
                                                    })[0].Scope
                                                  : this.state.Data.Scopes[0]
                                                      .Scope,
                                                Type: 2,
                                                UOM: 'EA',
                                                Data: {
                                                  WorkOrder: this.state,
                                                  Assignments: trip
                                                    ? trip.Data.Assignments
                                                    : null,
                                                },
                                              },
                                              Taxability ? Taxability : null,
                                            )
                                          }
                                          window['warn'](Taxability)
                                        }}
                                        onMouseEnter={() =>
                                          this.setState({
                                            ...this.state,
                                            materialHover: true,
                                          })
                                        }
                                        onMouseLeave={() =>
                                          this.setState({
                                            ...this.state,
                                            materialHover: false,
                                          })
                                        }
                                      >
                                        {this.state.materialHover ? (
                                          <Icon>add</Icon>
                                        ) : (
                                          <Icon>crop_free</Icon>
                                        )}
                                      </Fab>
                                    </Badge>
                                    <Typography
                                      component='div'
                                      variant='caption'
                                      className={classNames(
                                        increaseFontSize
                                          ? 'text-16'
                                          : 'text-12',
                                        'w-full text-center font-bold mt-4',
                                      )}
                                    >
                                      Materials
                                    </Typography>
                                  </div>
                                )}
                              {purchaseAccessLevel && (
                                <div className='w-full p-16 pb-4 justify-center'>
                                  <Badge
                                    classes={{ badge: classes.actionBadge }}
                                    badgeContent={
                                      Data.POs ? Data.POs.length : 0
                                    }
                                    color='error'
                                  >
                                    <Fab
                                      color='primary'
                                      aria-label='purchase'
                                      className={classNames(
                                        increaseFontSize ? 'ml-12' : null,
                                        classes.actionButton,
                                      )}
                                      disabled={
                                        (!assigned &&
                                          purchaseAccessLevel &&
                                          purchaseAccessLevel.AccessLevel !==
                                            'F') ||
                                        (assigned && trip.Status < 4) ||
                                        canceled
                                      }
                                      onClick={event => {
                                        if (
                                          (Data.POs ? Data.POs.length : 0) > 0
                                        ) {
                                          this.setAction(
                                            event.currentTarget,
                                            () => {
                                              openNewPurchaseOrderDialog({
                                                Co: Co,
                                                POType: 'W',
                                                WorkOrder,
                                                Scope: trip
                                                  ? _.filter(Data.Scopes, o => {
                                                      return (
                                                        _.findIndex(
                                                          trip.Data.Assignments,
                                                          { Scope: o.Scope },
                                                        ) > -1
                                                      )
                                                    })[0].Scope
                                                  : this.state.Data.Scopes[0]
                                                      .Scope,
                                                PO: this.getNextPO(
                                                  this.state.Data.POs,
                                                  `${
                                                    dv && dv.POPrefix
                                                      ? dv.POPrefix
                                                      : 'WP'
                                                  }${WorkOrder}-`,
                                                ),
                                                Description: `WO #${WorkOrder} Materials`,
                                                Data: {
                                                  Assignments: trip
                                                    ? trip.Data.Assignments
                                                    : null,
                                                  Scopes: trip
                                                    ? _.filter(
                                                        Data.Scopes,
                                                        o => {
                                                          return (
                                                            _.findIndex(
                                                              trip.Data
                                                                .Assignments,
                                                              {
                                                                Scope: o.Scope,
                                                              },
                                                            ) > -1
                                                          )
                                                        },
                                                      )
                                                    : this.state.Data.Scopes,
                                                },
                                              })
                                              this.setState({
                                                ...this.state,
                                                actionEl: null,
                                                actionAdd: null,
                                                actionManage: null,
                                                poHover: false,
                                              })
                                            },
                                            () => {
                                              this.setState({
                                                ...this.state,
                                                value: 5,
                                                actionEl: null,
                                                actionAdd: null,
                                                actionManage: null,
                                                poHover: false,
                                              })
                                            },
                                          )
                                        } else {
                                          openNewPurchaseOrderDialog({
                                            Co: Co,
                                            POType: 'W',
                                            WorkOrder,
                                            Scope: trip
                                              ? _.filter(Data.Scopes, o => {
                                                  return (
                                                    _.findIndex(
                                                      trip.Data.Assignments,
                                                      { Scope: o.Scope },
                                                    ) > -1
                                                  )
                                                })[0].Scope
                                              : this.state.Data.Scopes[0].Scope,
                                            PO: this.getNextPO(
                                              this.state.Data.POs,
                                              `${
                                                dv && dv.POPrefix
                                                  ? dv.POPrefix
                                                  : 'WP'
                                              }${WorkOrder}-`,
                                            ),
                                            Description: `WO #${WorkOrder} Materials`,
                                            Data: {
                                              Assignments: trip
                                                ? trip.Data.Assignments
                                                : null,
                                              Scopes: trip
                                                ? _.filter(Data.Scopes, o => {
                                                    return (
                                                      _.findIndex(
                                                        trip.Data.Assignments,
                                                        { Scope: o.Scope },
                                                      ) > -1
                                                    )
                                                  })
                                                : this.state.Data.Scopes,
                                            },
                                          })
                                        }
                                      }}
                                      onMouseEnter={() =>
                                        this.setState({
                                          ...this.state,
                                          poHover: true,
                                        })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({
                                          ...this.state,
                                          poHover: false,
                                        })
                                      }
                                    >
                                      {this.state.poHover ? (
                                        <Icon>add</Icon>
                                      ) : (
                                        <Icon>store</Icon>
                                      )}
                                    </Fab>
                                  </Badge>
                                  <Typography
                                    component='div'
                                    variant='caption'
                                    className={classNames(
                                      increaseFontSize ? 'text-16' : 'text-12',
                                      'w-full text-center font-bold mt-4',
                                    )}
                                  >
                                    Purchase
                                  </Typography>
                                </div>
                              )}
                              {billingAccessLevel &&
                                (!woOpt || woOpt.HideMiscButtonYN !== 'Y') && (
                                  <div className='w-full p-16 pb-4 justify-center'>
                                    <Badge
                                      classes={{ badge: classes.actionBadge }}
                                      badgeContent={Number(
                                        _.sumBy(Data.Usage, { Type: 0 }),
                                      )}
                                      color='error'
                                    >
                                      <Fab
                                        color='primary'
                                        aria-label='scan'
                                        className={classNames(
                                          increaseFontSize ? 'ml-12' : null,
                                          classes.actionButton,
                                        )}
                                        disabled={
                                          (!assigned &&
                                            billingAccessLevel &&
                                            billingAccessLevel.AccessLevel !==
                                              'F') ||
                                          (assigned && trip.Status < 4) ||
                                          canceled
                                        }
                                        onClick={event => {
                                          if (
                                            _.sumBy(Data.Usage, { Type: 0 }) > 0
                                          ) {
                                            this.setAction(
                                              event.currentTarget,
                                              () => {
                                                openNewWorkOrderUsageDialog(
                                                  {
                                                    Co: Co,
                                                    WorkOrder: WorkOrder,
                                                    Scope: trip
                                                      ? _.filter(
                                                          Data.Scopes,
                                                          o => {
                                                            return (
                                                              _.findIndex(
                                                                trip.Data
                                                                  .Assignments,
                                                                {
                                                                  Scope:
                                                                    o.Scope,
                                                                },
                                                              ) > -1
                                                            )
                                                          },
                                                        )[0].Scope
                                                      : this.state.Data
                                                          .Scopes[0].Scope,
                                                    Type: 0,
                                                    UOM: 'EA',
                                                    Data: {
                                                      WorkOrder: this.state,
                                                      Assignments: trip
                                                        ? trip.Data.Assignments
                                                        : null,
                                                    },
                                                  },
                                                  Taxability
                                                    ? Taxability
                                                    : null,
                                                )
                                                this.setState({
                                                  ...this.state,
                                                  actionEl: null,
                                                  actionAdd: null,
                                                  actionManage: null,
                                                  miscHover: false,
                                                })
                                              },
                                              () => {
                                                this.setState({
                                                  ...this.state,
                                                  value: 4,
                                                  actionEl: null,
                                                  actionAdd: null,
                                                  actionManage: null,
                                                  miscHover: false,
                                                })
                                              },
                                            )
                                          } else {
                                            openNewWorkOrderUsageDialog(
                                              {
                                                Co: Co,
                                                WorkOrder: WorkOrder,
                                                Scope: trip
                                                  ? _.filter(Data.Scopes, o => {
                                                      return (
                                                        _.findIndex(
                                                          trip.Data.Assignments,
                                                          { Scope: o.Scope },
                                                        ) > -1
                                                      )
                                                    })[0].Scope
                                                  : this.state.Data.Scopes[0]
                                                      .Scope,
                                                Type: 0,
                                                UOM: 'EA',
                                                Data: {
                                                  WorkOrder: this.state,
                                                  Assignments: trip
                                                    ? trip.Data.Assignments
                                                    : null,
                                                },
                                              },
                                              Taxability ? Taxability : null,
                                            )
                                          }
                                        }}
                                        onMouseEnter={() =>
                                          this.setState({
                                            ...this.state,
                                            miscHover: true,
                                          })
                                        }
                                        onMouseLeave={() =>
                                          this.setState({
                                            ...this.state,
                                            miscHover: false,
                                          })
                                        }
                                      >
                                        {this.state.iscHover ? (
                                          <Icon>add</Icon>
                                        ) : (
                                          <Icon>style</Icon>
                                        )}
                                      </Fab>
                                    </Badge>
                                    <Typography
                                      component='div'
                                      variant='caption'
                                      className={classNames(
                                        increaseFontSize
                                          ? 'text-16'
                                          : 'text-12',
                                        'w-full text-center font-bold mt-4',
                                      )}
                                    >
                                      Misc
                                    </Typography>
                                  </div>
                                )}
                              {attachmentAccessLevel && (
                                <div className='w-full p-16 pb-4 justify-center'>
                                  <Badge
                                    classes={{ badge: classes.actionBadge }}
                                    badgeContent={
                                      Data.Attachments
                                        ? _.filter(
                                            Data.Attachments || [],
                                            o =>
                                              [
                                                '.png',
                                                '.jpg',
                                                '.bmp',
                                                '.jpeg',
                                              ].indexOf(o.Extension) > -1,
                                          ).length
                                        : 0
                                    }
                                    color='error'
                                  >
                                    <Fab
                                      color='primary'
                                      aria-label='photo'
                                      className={classNames(
                                        increaseFontSize ? 'ml-12' : null,
                                        classes.actionButton,
                                      )}
                                      disabled={
                                        (!assigned &&
                                          attachmentAccessLevel &&
                                          attachmentAccessLevel.AccessLevel !==
                                            'F') ||
                                        (assigned && trip.Status < 4) ||
                                        canceled
                                      }
                                      onClick={event => {
                                        if (
                                          (Data.Attachments
                                            ? Data.Attachments.length
                                            : 0) > 0
                                        ) {
                                          this.setAction(
                                            event.currentTarget,
                                            () => {
                                              if (window.cordova) {
                                                this.takePicture()
                                              } else {
                                                this.fileInput.click()
                                              }
                                              this.setState({
                                                ...this.state,
                                                actionEl: null,
                                                actionAdd: null,
                                                actionManage: null,
                                                photoHover: false,
                                              })
                                            },
                                            () => {
                                              this.setState({
                                                ...this.state,
                                                value: 9,
                                                actionEl: null,
                                                actionAdd: null,
                                                actionManage: null,
                                                photoHover: false,
                                              })
                                            },
                                          )
                                        } else {
                                          if (window.cordova) {
                                            this.takePicture()
                                          } else {
                                            this.fileInput.click()
                                          }
                                        }
                                      }}
                                      onMouseEnter={() =>
                                        this.setState({
                                          ...this.state,
                                          photoHover: true,
                                        })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({
                                          ...this.state,
                                          photoHover: false,
                                        })
                                      }
                                    >
                                      {this.state.photoHover ? (
                                        <Icon>add</Icon>
                                      ) : (
                                        <Icon>photo_camera</Icon>
                                      )}
                                      {!window.cordova && (
                                        <input
                                          type='file'
                                          style={{ display: 'none' }}
                                          onChange={
                                            this.attachmentSelectedHandler
                                          }
                                          ref={fileInput =>
                                            (this.fileInput = fileInput)
                                          }
                                          multiple
                                          accept='.jpg, png, .bmp, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt'
                                        />
                                      )}
                                    </Fab>
                                  </Badge>
                                  <Typography
                                    component='div'
                                    variant='caption'
                                    className={classNames(
                                      increaseFontSize ? 'text-16' : 'text-12',
                                      'w-full text-center font-bold mt-4',
                                    )}
                                  >
                                    Photo
                                  </Typography>
                                </div>
                              )}
                              {catalogAccessLevel && (
                                <div className='w-full p-16 pb-4 justify-center'>
                                  <Badge
                                    classes={{ badge: classes.actionBadge }}
                                    badgeContent={
                                      Data.Estimates ? Data.Estimates.length : 0
                                    }
                                    color='error'
                                  >
                                    <Fab
                                      color='primary'
                                      aria-label='estimate'
                                      className={classNames(
                                        increaseFontSize ? 'ml-12' : null,
                                        classes.actionButton,
                                      )}
                                      disabled={
                                        (!assigned &&
                                          billingAccessLevel &&
                                          billingAccessLevel.AccessLevel !==
                                            'F') ||
                                        (assigned && trip.Status < 4) ||
                                        canceled
                                      }
                                      onClick={event => {
                                        if (
                                          (Data.Estimates
                                            ? Data.Estimates.length
                                            : 0) > 0
                                        ) {
                                          this.setAction(
                                            event.currentTarget,
                                            this.launchEstimate,
                                            () => {
                                              this.setState({
                                                ...this.state,
                                                value: 6,
                                                actionEl: null,
                                                actionAdd: null,
                                                actionManage: null,
                                                estimateHover: false,
                                              })
                                            },
                                          )
                                        } else {
                                          this.launchEstimate()
                                        }
                                      }}
                                      onMouseEnter={() =>
                                        this.setState({
                                          ...this.state,
                                          estimateHover: true,
                                        })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({
                                          ...this.state,
                                          estimateHover: false,
                                        })
                                      }
                                    >
                                      {this.state.estimateHover ? (
                                        <Icon>add</Icon>
                                      ) : (
                                        <Icon>format_list_numbered</Icon>
                                      )}
                                    </Fab>
                                  </Badge>
                                  <Typography
                                    component='div'
                                    variant='caption'
                                    className={classNames(
                                      increaseFontSize ? 'text-16' : 'text-12',
                                      'w-full text-center font-bold mt-4',
                                    )}
                                  >
                                    Estimate
                                  </Typography>
                                </div>
                              )}
                              {billingAccessLevel && (
                                <div className='w-full p-16 pb-4 justify-center'>
                                  <Badge
                                    classes={{ badge: classes.actionBadge }}
                                    badgeContent={
                                      Data.Invoices ? Data.Invoices.length : 0
                                    }
                                    color='error'
                                  >
                                    <Fab
                                      color='primary'
                                      aria-label='invoice'
                                      className={classNames(
                                        increaseFontSize ? 'ml-12' : null,
                                        classes.actionButton,
                                      )}
                                      disabled={
                                        (!assigned &&
                                          billingAccessLevel &&
                                          billingAccessLevel.AccessLevel !==
                                            'F') ||
                                        (assigned && trip.Status < 4) ||
                                        canceled
                                      }
                                      onClick={event => {
                                        if (
                                          (Data.Invoices
                                            ? Data.Invoices.length
                                            : 0) > 0
                                        ) {
                                          this.setAction(
                                            event.currentTarget,
                                            this.launchInvoice,
                                            () => {
                                              this.setState({
                                                ...this.state,
                                                value: 7,
                                                actionEl: null,
                                                actionAdd: null,
                                                actionManage: null,
                                                invoiceHover: false,
                                              })
                                            },
                                          )
                                        } else {
                                          this.launchInvoice()
                                        }
                                      }}
                                      onMouseEnter={() =>
                                        this.setState({
                                          ...this.state,
                                          invoiceHover: true,
                                        })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({
                                          ...this.state,
                                          invoiceHover: false,
                                        })
                                      }
                                    >
                                      {this.state.invoiceHover ? (
                                        <Icon>add</Icon>
                                      ) : (
                                        <Icon>receipt</Icon>
                                      )}
                                    </Fab>
                                  </Badge>
                                  <Typography
                                    component='div'
                                    variant='caption'
                                    className={classNames(
                                      increaseFontSize ? 'text-16' : 'text-12',
                                      'w-full text-center font-bold mt-4',
                                    )}
                                  >
                                    Invoice
                                  </Typography>
                                </div>
                              )}
                              <div className='w-full p-16 pb-4 justify-center'>
                                <Badge
                                  classes={{ badge: classes.actionBadge }}
                                  badgeContent={
                                    Data.Site &&
                                    Data.Site.Data &&
                                    Data.Site.Data.Units
                                      ? Data.Site.Data.Units.length
                                      : 0
                                  }
                                  color='error'
                                >
                                  <Fab
                                    color='primary'
                                    aria-label='equipment'
                                    className={classNames(
                                      increaseFontSize ? 'ml-12' : null,
                                      classes.actionButton,
                                    )}
                                    disabled={canceled}
                                    onClick={event => {
                                      this.setState({
                                        ...this.state,
                                        value: 8,
                                        actionEl: null,
                                        actionAdd: null,
                                        actionManage: null,
                                        invoiceHover: false,
                                      })
                                    }}
                                  >
                                    <Icon>build</Icon>
                                  </Fab>
                                </Badge>
                                <Typography
                                  component='div'
                                  variant='caption'
                                  className={classNames(
                                    increaseFontSize ? 'text-16' : 'text-12',
                                    'w-full text-center font-bold mt-4',
                                  )}
                                >
                                  Equipment
                                </Typography>
                              </div>
                              <div className='w-full p-16 pb-4 justify-center'>
                                <Fab
                                  color='primary'
                                  aria-label='history'
                                  className={classNames(
                                    increaseFontSize ? 'ml-12' : null,
                                    classes.actionButton,
                                  )}
                                  onClick={event =>
                                    this.setState({ ...this.state, value: 10 })
                                  }
                                >
                                  <Icon>history</Icon>
                                </Fab>
                                <Typography
                                  component='div'
                                  variant='caption'
                                  className={classNames(
                                    increaseFontSize ? 'text-16' : 'text-12',
                                    'w-full text-center font-bold mt-4',
                                  )}
                                >
                                  History
                                </Typography>
                              </div>
                              <div className='w-full p-16 pb-4 justify-center'>
                                <Fab
                                  color='primary'
                                  aria-label='directions'
                                  className={classNames(
                                    increaseFontSize ? 'ml-12' : null,
                                    classes.actionButton,
                                  )}
                                  onClick={this.openDirections}
                                >
                                  <Icon>map</Icon>
                                </Fab>
                                <Typography
                                  component='div'
                                  variant='caption'
                                  className={classNames(
                                    increaseFontSize ? 'text-16' : 'text-12',
                                    'w-full text-center font-bold mt-4',
                                  )}
                                >
                                  Directions
                                </Typography>
                              </div>
                              <div className='w-full p-16 pb-4 justify-center'>
                                <Fab
                                  color='primary'
                                  aria-label='directions'
                                  className={classNames(
                                    increaseFontSize ? 'ml-12' : null,
                                    classes.actionButton,
                                  )}
                                  onClick={() =>
                                    this.setState({ viewSummary: true })
                                  }
                                >
                                  <Icon>assessment</Icon>
                                </Fab>
                                <Typography
                                  component='div'
                                  variant='caption'
                                  className={classNames(
                                    increaseFontSize ? 'text-16' : 'text-12',
                                    'w-full text-center font-bold mt-4',
                                  )}
                                >
                                  Summary
                                </Typography>
                              </div>
                            </FuseAnimateGroup>
                          </ActionPanel>
                        </div>
                      )}
                      <div
                        onClick={this.hideActions}
                        className={'w-full p-12'}
                        style={{
                          width: !matches.small ? 'calc(100% - 92px)' : '100%',
                        }}
                      >
                        <div
                          className='w-full pb-8'
                          style={{ borderBottom: '1px solid lightgrey' }}
                        >
                          <div
                            className='relative'
                            style={{
                              marginLeft: -12,
                              marginRight: -12,
                              marginTop: -12,
                            }}
                          >
                            <img
                              style={{ width: '100%' }}
                              src={`https://maps.googleapis.com/maps/api/streetview?key=${googleMapsApiKey}&size=640x${
                                matches.medium ? 240 : 300
                              }&location=${encodeURIComponent(Address)}`}
                              draggable='false'
                            />
                            <div className='w-full pin-t absolute p-8'>
                              <Typography
                                style={{ alignItems: 'center' }}
                                className='flex relative text-white mt-4'
                              >
                                {/* <Icon onClick={this.toggleActions} color={"action"} className="text-24 mr-4 sm:hidden text-white" style={{ textShadow: '0 0 6px #3f3f3f' }}>menu</Icon> */}
                                <Icon
                                  color={'primary'}
                                  className='text-24 mr-4 text-white'
                                  style={{ textShadow: '0 0 6px #3f3f3f' }}
                                >
                                  assignment
                                </Icon>
                                <span
                                  style={{
                                    width: '55%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '55%',
                                  }}
                                >
                                  <strong
                                    className='text-white'
                                    style={{ textShadow: '0 0 6px #3f3f3f' }}
                                  >
                                    {this.state.Description}
                                  </strong>
                                </span>
                                {((siteContact && siteContact.Phone) ||
                                  Data.Customer.Phone ||
                                  Data.Site.Phone) && (
                                  <Tooltip
                                    title={`${this.formatPhone(
                                      siteContact && siteContact.Phone
                                        ? siteContact.Phone
                                        : Data.Site.Phone ||
                                            Data.Customer.Phone,
                                    )}`}
                                    placement='top'
                                  >
                                    {isMobile || !dialer ? (
                                      <Chip
                                        className={classNames(
                                          increaseFontSize
                                            ? 'text-16'
                                            : 'text-12',
                                          'pin-r absolute pin-r h-28 mr-24',
                                          classes.black,
                                        )}
                                        style={{ borderRadius: 5 }}
                                        color='primary'
                                        icon={
                                          <Icon
                                            className={classNames(
                                              classes.greenText,
                                              'text-16',
                                            )}
                                          >
                                            phone
                                          </Icon>
                                        }
                                        label={
                                          <Link
                                            href={`tel:${this.formatPhoneLink(
                                              siteContact && siteContact.Phone
                                                ? siteContact.Phone
                                                : Data.Site.Phone ||
                                                    Data.Customer.Phone,
                                            )}`}
                                            color='inherit'
                                          >
                                            {siteContact && siteContact.Name
                                              ? siteContact.Name
                                              : Data.Site.Name ||
                                                Data.Customer.Name}{' '}
                                            -{' '}
                                            {`${this.formatPhone(
                                              siteContact && siteContact.Phone
                                                ? siteContact.Phone
                                                : Data.Site.Phone
                                                  ? Data.Site.Phone
                                                  : Data.Customer.Phone,
                                            )}`}
                                          </Link>
                                        }
                                      />
                                    ) : (
                                      <Chip
                                        className={classNames(
                                          increaseFontSize
                                            ? 'text-16'
                                            : 'text-12',
                                          'pin-r absolute pin-r h-28 mr-24',
                                          classes.black,
                                        )}
                                        style={{ borderRadius: 5 }}
                                        color='primary'
                                        icon={
                                          <Icon
                                            className={classNames(
                                              classes.greenText,
                                              'text-16',
                                            )}
                                          >
                                            phone
                                          </Icon>
                                        }
                                        label={`${
                                          siteContact && siteContact.Name
                                            ? siteContact.Name
                                            : Data.Site.Name ||
                                              Data.Customer.Name
                                        } - ${this.formatPhone(
                                          siteContact && siteContact.Phone
                                            ? siteContact.Phone
                                            : Data.Site.Phone
                                              ? Data.Site.Phone
                                              : Data.Customer.Phone,
                                        )}`}
                                      />
                                    )}
                                  </Tooltip>
                                )}

                                {siteContacts && siteContacts.length > 1 && (
                                  <Tooltip
                                    className={classNames(
                                      'pin-r absolute pin-r',
                                      classes.black,
                                      classes.greenText,
                                    )}
                                    placement='top'
                                    title='More Contacts'
                                  >
                                    {!this.state.siteContactsMenuEl ? (
                                      <Icon
                                        style={{ borderRadius: '50%' }}
                                        onClick={e =>
                                          this.setState({
                                            siteContactsMenuEl: e.target,
                                          })
                                        }
                                        className='text-20 ml-4 cursor-pointer align-middle'
                                      >
                                        expand_more
                                      </Icon>
                                    ) : (
                                      <Icon
                                        style={{ borderRadius: '50%' }}
                                        onClick={e =>
                                          this.setState({
                                            siteContactsMenuEl: null,
                                          })
                                        }
                                        className='text-20 ml-4 cursor-pointer align-middle'
                                      >
                                        expand_less
                                      </Icon>
                                    )}
                                  </Tooltip>
                                )}
                              </Typography>
                              <Menu
                                classes={{ paper: classes.black }}
                                id='site-contacts-menu'
                                anchorEl={this.state.siteContactsMenuEl}
                                open={Boolean(this.state.siteContactsMenuEl)}
                                onClose={() =>
                                  this.setState({ siteContactsMenuEl: null })
                                }
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                style={{ marginTop: 50 }}
                              >
                                {siteContacts &&
                                  siteContacts.length > 0 &&
                                  [...siteContacts]
                                    .slice(1)
                                    .map(siteContact => {
                                      return (
                                        <MenuItem>
                                          <Icon
                                            className={classNames(
                                              classes.greenText,
                                              increaseFontSize
                                                ? 'text-16'
                                                : 'text-14',
                                              'mr-8',
                                            )}
                                          >
                                            phone
                                          </Icon>
                                          <Link
                                            className={classNames(
                                              increaseFontSize
                                                ? 'text-16'
                                                : 'text-14',
                                              'text-white',
                                            )}
                                            href={`tel:${this.formatPhoneLink(
                                              siteContact.Data.Contact.Phone,
                                            )}`}
                                            color='inherit'
                                          >{`${
                                            siteContact.Data.Contact.Name
                                          } - ${this.formatPhone(
                                            siteContact.Data.Contact.Phone,
                                          )}`}</Link>
                                        </MenuItem>
                                      )
                                    })}
                              </Menu>
                              {/* <Typography variant="caption" className="w-full pl-28 mt-4 text-white" style={{ textShadow: '0 0 6px #3f3f3f' }}>{this.state.Description}</Typography> */}
                            </div>
                          </div>
                          <div className='flex'>
                            <div
                              className={classNames(classes.link, 'min-w-1/2')}
                            >
                              <Typography
                                style={{ alignItems: 'center' }}
                                className='flex relative'
                              >
                                <Icon
                                  color={'primary'}
                                  className='text-24 mr-4'
                                >
                                  location_on
                                </Icon>
                                <span>
                                  <strong
                                    className={classNames(
                                      increaseFontSize ? 'text-18' : 'text-16',
                                    )}
                                  >
                                    Site #{this.state.Site}
                                  </strong>
                                  <Tooltip placement='top' title='Site Actions'>
                                    <Icon
                                      onClick={e =>
                                        this.setState(
                                          { siteMenuEl: e.target },
                                          // console.log(this.state)
                                        )
                                      }
                                      className={`${
                                        increaseFontSize
                                          ? 'text-18 mb-4'
                                          : 'text-16 mb-2'
                                      } ml-4 cursor-pointer align-middle`}
                                    >
                                      more_vert
                                    </Icon>
                                  </Tooltip>
                                  {siteMenuEl && (
                                    <Menu
                                      id='site-menu'
                                      anchorEl={siteMenuEl}
                                      open={Boolean(siteMenuEl)}
                                      onClose={() =>
                                        this.setState({ siteMenuEl: null })
                                      }
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          openSite(Data.Site)
                                          this.setState({ siteMenuEl: null })
                                        }}
                                      >
                                        <Icon className='mr-8'>
                                          open_in_new
                                        </Icon>
                                        View Site Details
                                      </MenuItem>
                                      {chAccess && chAccess.AccessLevel && (
                                        <MenuItem
                                          onClick={() => {
                                            history.push('/apps/call-handler')
                                            this.props.getSiteData(Data.Site)
                                            this.setState(
                                              { siteMenuEl: null },
                                              this.props
                                                .closeEditWorkOrderDialog,
                                            )
                                          }}
                                        >
                                          <Icon className='mr-8'>phone</Icon>
                                          Open in Call Handler
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={() =>
                                          this.setState({
                                            openWoContactList: true,
                                            isSiteContact: true,
                                            siteMenuEl: null,
                                          })
                                        }
                                      >
                                        <Icon className='mr-8'>person_pin</Icon>
                                        Site Contacts
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </span>
                              </Typography>
                              <div
                                className={classNames(
                                  increaseFontSize ? 'text-16' : 'text-xs',
                                  'w-full pl-28 truncate',
                                )}
                              >
                                {siteContact && siteContact.Name
                                  ? siteContact.Name
                                  : Data.Customer.Name ||
                                    `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                <br />
                                {`${Data.Site.Address1}`}
                                <br />
                                {Data.Site.Address2 &&
                                Data.Site.Address2.length > 0
                                  ? Data.Site.Address2
                                  : ''}
                                {Data.Site.Address2 &&
                                Data.Site.Address2.length > 0 ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {`${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`}
                                <br />
                                {/* {`${Data.Site.Phone}`}<br /> */}
                                {this.state.Data.Site.CrossStreets && (
                                  <div className='capitalize font-bold'>
                                    <Icon
                                      color='primary'
                                      className='mr-4 text-14 align-middle mb-2'
                                    >
                                      location_searching
                                    </Icon>
                                    {this.state.Data.Site.CrossStreets}
                                  </div>
                                )}
                                <div
                                  onClick={() => {
                                    this.emailCustomer(
                                      siteContact && siteContact.Email
                                        ? siteContact.Email
                                        : reqBy && reqBy.Email
                                          ? reqBy.Email
                                          : (primaryRecord &&
                                              primaryRecord.Data.Contact
                                                .Email) ||
                                            Data.Site.Email ||
                                            null,
                                    )
                                  }}
                                  className='cursor-pointer truncate'
                                >
                                  <Icon
                                    className='text-14 mr-4 cursor-pointer align-middle'
                                    color='primary'
                                  >
                                    email
                                  </Icon>
                                  {`${
                                    siteContact && siteContact.Email
                                      ? siteContact.Email
                                      : reqBy && reqBy.Email
                                        ? reqBy.Email
                                        : (primaryRecord &&
                                            primaryRecord.Data.Contact.Email) ||
                                          Data.Site.Email ||
                                          'Send Email'
                                  }`}
                                </div>
                              </div>
                            </div>
                            <div
                              className={classNames(classes.link, 'min-w-1/2')}
                            >
                              <Typography
                                style={{ alignItems: 'center' }}
                                className='flex relative'
                              >
                                <Icon
                                  color={'primary'}
                                  className='text-24 mr-4'
                                >
                                  account_circle
                                </Icon>
                                <span>
                                  <strong
                                    className={classNames(
                                      increaseFontSize ? 'text-18' : 'text-16',
                                    )}
                                  >
                                    Customer #{this.state.Customer}
                                  </strong>
                                  <Tooltip
                                    placement='top'
                                    title='Customer Actions'
                                  >
                                    <Icon
                                      onClick={e =>
                                        this.setState({
                                          customerMenuEl: e.target,
                                        })
                                      }
                                      className={`${
                                        increaseFontSize
                                          ? 'text-18 mb-4'
                                          : 'text-16 mb-2'
                                      } ml-4 cursor-pointer align-middle`}
                                    >
                                      more_vert
                                    </Icon>
                                  </Tooltip>
                                  {customerMenuEl && (
                                    <Menu
                                      id='customer-menu'
                                      anchorEl={customerMenuEl}
                                      open={Boolean(customerMenuEl)}
                                      onClose={() =>
                                        this.setState({ customerMenuEl: null })
                                      }
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          openCustomer(Data.Customer)
                                          this.setState({
                                            customerMenuEl: null,
                                          })
                                        }}
                                      >
                                        <Icon className='mr-8'>
                                          open_in_new
                                        </Icon>
                                        View Customer Details
                                      </MenuItem>
                                      {chAccess && chAccess.AccessLevel && (
                                        <MenuItem
                                          onClick={() => {
                                            history.push(`/apps/call-handler`)
                                            this.props.getCustomerData(
                                              Data.Site,
                                            )
                                            this.setState(
                                              { customerMenuEl: null },
                                              this.props
                                                .closeEditWorkOrderDialog,
                                            )
                                          }}
                                        >
                                          <Icon className='mr-8'>phone</Icon>
                                          Open in Call Handler
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={() =>
                                          this.setState({
                                            openWoContactList: true,
                                            isSiteContact: false,
                                            customerMenuEl: null,
                                          })
                                        }
                                      >
                                        <Icon className='mr-8'>
                                          assignment_ind
                                        </Icon>
                                        Customer Contacts
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </span>
                              </Typography>
                              <div
                                className={classNames(
                                  increaseFontSize ? 'text-16' : 'text-xs',
                                  'w-full pl-28 truncate',
                                )}
                              >
                                {Data.Customer.Name ||
                                  `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                <br />
                                {`${Data.Customer.Address1}`}
                                <br />
                                {Data.Customer.Address2 &&
                                Data.Customer.Address2.length > 0
                                  ? Data.Customer.Address2
                                  : ''}
                                {Data.Customer.Address2 &&
                                Data.Customer.Address2.length > 0 ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                <br />
                                {/* {`${Data.Customer.Phone}`}<br /> */}
                                {this.state.Data.Customer.ApprovedAccountYN ===
                                  'Y' && (
                                  <div className='capitalize font-bold'>
                                    <Icon
                                      color='primary'
                                      className='mr-4 text-14 align-middle mb-2'
                                    >
                                      security
                                    </Icon>
                                    Approved Account
                                  </div>
                                )}
                                <div
                                  onClick={() => {
                                    this.emailCustomer(
                                      reqBy && reqBy.Email
                                        ? reqBy.Email
                                        : (primaryRecord &&
                                            primaryRecord.Data.Contact.Email) ||
                                            Data.Site.Email ||
                                            null,
                                    )
                                  }}
                                  className='cursor-pointer truncate'
                                >
                                  <Icon
                                    className='text-14 mr-4 cursor-pointer align-middle'
                                    color='primary'
                                  >
                                    email
                                  </Icon>
                                  {`${
                                    reqBy && reqBy.Email
                                      ? reqBy.Email
                                      : (primaryRecord &&
                                          primaryRecord.Data.Contact.Email) ||
                                        'Send Email'
                                  }`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-full pl-24'>
                            {sortAgreements &&
                              sortAgreements.map((agr, index) => (
                                <Tooltip
                                  title={`${moment(agr.StartDate).format(
                                    'MM/DD/YYYY',
                                  )} - ${moment(agr.EndDate).format(
                                    'MM/DD/YYYY',
                                  )}`}
                                  placement='top'
                                >
                                  <Chip
                                    color='primary'
                                    disabled={loadingAgreement}
                                    onClick={() => {
                                      if (!loadingAgreement) {
                                        this.setState(
                                          {
                                            ...this.state,
                                            loadingAgreement: true,
                                          },
                                          () => {
                                            Promise.all([
                                              openEditAgreementDialog(
                                                {
                                                  ...agr,
                                                  renewalWorkOrder:
                                                    this.state.WorkOrder,
                                                  renewalScope: 1,
                                                },
                                                false,
                                                this.state.WorkOrder,
                                              ),
                                            ]).then(() => {
                                              this.setState({
                                                ...this.state,
                                                loadingAgreement: false,
                                              })
                                            })
                                          },
                                        )
                                      }
                                    }}
                                    classes={{
                                      label: increaseFontSize
                                        ? 'text-14'
                                        : 'text-11',
                                    }}
                                    className={classNames(
                                      'mr-8 mt-4 h-28 cursor-pointer',
                                      agr.ActiveYN !== 'Y'
                                        ? classes.black
                                        : new Date() >=
                                            new Date(agr.EndDate).setDate(
                                              new Date(agr.EndDate).getDate() -
                                                45,
                                            )
                                          ? new Date() > new Date(agr.EndDate)
                                            ? classes.red
                                            : classes.yellow
                                          : classes.blue,
                                    )}
                                    style={{ borderRadius: 5 }}
                                    icon={
                                      loadingAgreement ? (
                                        <CircularProgress
                                          color='secondary'
                                          style={{ width: 20, height: 20 }}
                                        />
                                      ) : (
                                        <SPRoundIcon
                                          className={classNames(
                                            'text-20',
                                            agr.ActiveYN === 'Y'
                                              ? classes.greenText
                                              : '',
                                          )}
                                        />
                                      )
                                    }
                                    label={`${agr.Agreement} Cycle #${agr.Cycle} Rev #${agr.Revision}`}
                                  />
                                </Tooltip>
                              ))}
                            {((!assigned &&
                              agreementAccessLevel &&
                              agreementAccessLevel.AccessLevel === 'F') ||
                              (assigned &&
                                agreementAccessLevel &&
                                trip.Status > 3)) && (
                              <Chip
                                color='primary'
                                onClick={this.newAgreement}
                                classes={{
                                  label: increaseFontSize
                                    ? 'text-14'
                                    : 'text-11',
                                }}
                                className={classNames(
                                  'mr-8 mt-4 h-28 cursor-pointer',
                                  classes.black,
                                )}
                                style={{ borderRadius: 5 }}
                                icon={
                                  <Icon
                                    className={classNames(
                                      classes.greenText,
                                      'text-20',
                                    )}
                                  >
                                    add
                                  </Icon>
                                }
                                label='Add Service Agreement'
                              />
                            )}
                          </div>
                        </div>
                        {trip && !canceled && (
                          <div
                            className={`flex ${
                              tripInfo
                                ? 'justify-between w-full'
                                : 'justify-end'
                            } pin-r pin-b p-12 absolute max-w-512 overflow-x-hidden`}
                            style={{ zIndex: 1201 }}
                          >
                            {trip.Data && trip.Data.Technician && (
                              <TimecardLaborDialog
                                date={moment(
                                  trip.InProgressDate ||
                                    trip.EnRouteDate ||
                                    trip.ScheduledDate,
                                ).format('M/D/YYYY')}
                                trips={[
                                  {
                                    ...trip,
                                    Data: {
                                      WorkOrder: this.state,
                                      Assignments: trip.Data.Assignments,
                                    },
                                  },
                                ]}
                                employee={{
                                  Co: trip.Data.Technician.Co,
                                  Employee: trip.Data.Technician.Employee,
                                }}
                                open={this.state.tcPrompt}
                                source={'technician'}
                                title={
                                  <div>
                                    <Icon className='mr-6 align-middle mb-4'>
                                      access_time
                                    </Icon>
                                    Add to Timecard?
                                  </div>
                                }
                                message={
                                  <div className='w-full pt-8 pb-8'>{`Would you like to add labor for Work Order #${trip.WorkOrder} to your timecard?`}</div>
                                }
                                data={{
                                  Co: trip.Co,
                                  Employee: trip.Data.Technician.Employee,
                                  Date: moment(
                                    trip.InProgressDate ||
                                      trip.EnRouteDate ||
                                      trip.ScheduledDate,
                                  ).format('M/D/YYYY'),
                                  Type: 'W',
                                  WorkOrder: trip.WorkOrder,
                                  Scope: trip.Data.Assignments[0].Scope,
                                  Qty: (trip.ActualDuration || 1).toFixed(2),
                                }}
                                onClose={() =>
                                  this.setState({
                                    ...this.state,
                                    tcPrompt: false,
                                  })
                                }
                              />
                            )}
                            {trip && (
                              <TripHoldDialog
                                trip={{ ...trip }}
                                open={this.state.holdPrompt}
                                title={
                                  <div>
                                    <Icon className='mr-6 align-middle mb-4'>
                                      pause_circle_outline
                                    </Icon>
                                    Schedule a Return Trip?
                                  </div>
                                }
                                message={
                                  <div className='w-full pt-8 pb-8 mb-12'>{`Would you like to schedule a return trip for Work Order #${trip.WorkOrder}?`}</div>
                                }
                                onCancel={() =>
                                  this.setState({
                                    ...this.state,
                                    holdPrompt: false,
                                  })
                                }
                                onConfirm={() =>
                                  this.setState({
                                    ...this.state,
                                    holdPrompt: false,
                                  })
                                }
                              />
                            )}
                            {tripInfo && (
                              <FuseAnimateGroup
                                enter={{
                                  animation: 'transition.slideRightBigIn',
                                }}
                                leave={{
                                  animation: 'transition.slideRightBigOut',
                                }}
                                className='w-full flex justify-between'
                              >
                                <Tooltip
                                  title={
                                    trip.Status === 4 ? 'En-Route' : 'En-Route'
                                  }
                                  placement='top'
                                >
                                  <Fab
                                    color='primary'
                                    aria-label='trip'
                                    className={classNames(classes.black, '')}
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.updateStatus(trip, 4)
                                    }}
                                  >
                                    <Icon
                                      color={
                                        trip.Status === 4 ? 'secondary' : ''
                                      }
                                    >
                                      explore
                                    </Icon>
                                  </Fab>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    trip.Status === 6
                                      ? 'Place on Hold'
                                      : 'In Progress'
                                  }
                                  placement='top'
                                >
                                  <Fab
                                    color='primary'
                                    aria-label='trip'
                                    className={classNames(classes.black, '')}
                                    onClick={e => {
                                      e.stopPropagation()
                                      if (trip.Status === 6) {
                                        this.setState({
                                          ...this.state,
                                          holdInfo: trip,
                                        })
                                      } else {
                                        this.updateStatus(trip, 6)
                                      }
                                    }}
                                  >
                                    <Icon
                                      color={
                                        trip.Status === 6 || trip.Status === 7
                                          ? 'secondary'
                                          : ''
                                      }
                                    >
                                      {trip.Status === 6
                                        ? 'pause_circle_outline'
                                        : 'play_circle_outline'}
                                    </Icon>
                                  </Fab>
                                </Tooltip>
                                <Tooltip title='Completed' placement='top'>
                                  <Fab
                                    color='primary'
                                    aria-label='trip'
                                    className={classNames(classes.black, '')}
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.updateStatus(trip, 8)
                                    }}
                                  >
                                    <Icon
                                      color={
                                        trip.Status === 8 ? 'secondary' : ''
                                      }
                                    >
                                      check_circle
                                    </Icon>
                                  </Fab>
                                </Tooltip>
                                <Tooltip title='More Options' placement='top'>
                                  <Fab
                                    color='primary'
                                    aria-label='trip'
                                    className={classNames(classes.black, '')}
                                    onClick={e => {
                                      openEditTripDialog(trip)
                                    }}
                                  >
                                    <Icon>more_vert</Icon>
                                  </Fab>
                                </Tooltip>
                              </FuseAnimateGroup>
                            )}
                            <Tooltip title='Trip Options' placement='top'>
                              <Fab
                                color='primary'
                                aria-label='trip'
                                className={classNames(
                                  classes.black,
                                  'ml-24 sm:ml-48 min-w-56',
                                )}
                                onClick={e => {
                                  e.stopPropagation()
                                  this.setState({
                                    ...this.state,
                                    tripInfo: !tripInfo,
                                    actionPanel: false,
                                  })
                                }}
                              >
                                <Icon>
                                  {tripInfo
                                    ? 'keyboard_arrow_right'
                                    : 'local_shipping'}
                                </Icon>
                              </Fab>
                            </Tooltip>
                          </div>
                        )}
                        <div
                          className='w-full'
                          style={{ borderBottom: '1px solid lightgrey' }}
                        >
                          {Data &&
                            Data.Scopes &&
                            Data.Scopes.map((scope, index) => {
                              const assignedIndex = _.findIndex(
                                trip && trip.Data && trip.Data.Assignments
                                  ? trip.Data.Assignments
                                  : [],
                                { Scope: scope.Scope },
                              )
                              const assignedTrip =
                                !trip || (trip && assignedIndex > -1)
                                  ? true
                                  : false

                              return (
                                <div key={index}>
                                  <ScopeDetails
                                    expanded={expanded}
                                    scopeData={Data}
                                    scopeNum={scope}
                                    indexNum={index}
                                    Trips={this.state.Data.Trips}
                                    trip={trip}
                                    assignedIndex={assignedIndex}
                                    assignedTrip={assignedTrip}
                                    editTechs={this.state.editTechs}
                                    editScopes={this.state.editScopes}
                                    editQuotes={this.state.editQuotes}
                                    editWarranties={this.state.editWarranties}
                                    editSummary={this.state.editSummary}
                                    woOpt={woOpt}
                                    techAvatar={techAvatar}
                                    tripAccessLevel={tripAccessLevel}
                                    WorkOrder={WorkOrder}
                                    priorities={priorities}
                                    classes={classes}
                                    launchEstimate={this.launchEstimate}
                                    launchInvoice={this.launchInvoice}
                                    assignUnit={this.assignUnit}
                                    unassignUnit={this.unassignUnit}
                                    thisState={this.state}
                                    setStateFxn={this.setState.bind(this)}
                                    pdf={false}
                                    estimatesData={this.state.Data.Estimates}
                                  />
                                </div>
                              )
                            })}
                          <Menu
                            id='forms-menu'
                            anchorEl={this.state.taskAnchorEl}
                            open={Boolean(this.state.taskAnchorEl)}
                            onClose={() =>
                              this.setState({
                                ...this.state,
                                taskAnchorEl: null,
                              })
                            }
                          >
                            <MenuItem
                              onClick={() => {
                                this.props.removeAgreementTask(
                                  this.state.selectedTask,
                                )
                                this.setState({
                                  ...this.state,
                                  taskAnchorEl: null,
                                })
                              }}
                            >
                              Remove Task
                            </MenuItem>
                          </Menu>
                          {selectedForm && selectedUnit && selectedScope && (
                            <TaskForm
                              assignment={{
                                Co: selectedUnit.Co,
                                Customer: selectedUnit.Customer,
                                Site: selectedUnit.Site,
                                Agreement: selectedTask
                                  ? selectedTask.Agreement
                                  : null,
                                Cycle: selectedTask ? selectedTask.Cycle : null,
                                TaskType: selectedTask
                                  ? selectedTask.TaskType
                                  : null,
                                UnitID: selectedUnit.UnitID,
                                WorkOrder: selectedScope.WorkOrder,
                                Scope: selectedScope.Scope,
                              }}
                              scope={{ ...selectedScope }}
                              form={{ ...selectedForm }}
                              open={Boolean(selectedForm)}
                              onAdd={formData => {
                                if (selectedTask) {
                                  const index = _.findIndex(
                                    selectedUnit.Data.Forms,
                                    { Form: formData.Form },
                                  )
                                  if (index > -1) {
                                    selectedUnit.Data.Forms.splice(index, 1)
                                  }
                                  selectedUnit.Data.FormResults.push(
                                    formData.Data.Result,
                                  )
                                } else {
                                  const assn = _.find(
                                    selectedScope.Data.ServiceUnits,
                                    { UnitID: selectedUnit.UnitID },
                                  )
                                  if (assn) {
                                    const index = _.findIndex(assn.Data.Forms, {
                                      Form: formData.Form,
                                    })
                                    if (index > -1) {
                                      assn.Data.Forms.splice(index, 1)
                                    }
                                    assn.Data.FormResults.push(
                                      formData.Data.Result,
                                    )
                                  }
                                }
                              }}
                              onUpdate={formData => {
                                if (selectedTask) {
                                  const index = _.findIndex(
                                    selectedUnit.Data.FormResults,
                                    { Form: formData.Form },
                                  )
                                  if (index > -1) {
                                    selectedUnit.Data.FormResults.splice(
                                      index,
                                      1,
                                      formData,
                                    )
                                  }
                                } else {
                                  const assn = _.find(
                                    selectedScope.Data.ServiceUnits,
                                    { UnitID: selectedUnit.UnitID },
                                  )
                                  if (assn) {
                                    const index = _.findIndex(assn.Data.Forms, {
                                      Form: formData.Form,
                                    })
                                    if (index > -1) {
                                      assn.Data.Forms.splice(index, 1, formData)
                                    }
                                  }
                                }
                              }}
                              onDelete={formData => {
                                if (selectedTask) {
                                  const index = _.findIndex(
                                    selectedUnit.Data.FormResults,
                                    { Form: formData.Form },
                                  )
                                  if (index > -1) {
                                    selectedUnit.Data.FormResults.splice(
                                      index,
                                      1,
                                    )
                                  }
                                } else {
                                  const assn = _.find(
                                    selectedScope.Data.ServiceUnits,
                                    { UnitID: selectedUnit.UnitID },
                                  )
                                  if (assn) {
                                    const index = _.findIndex(assn.Data.Forms, {
                                      Form: formData.Form,
                                    })
                                    if (index > -1) {
                                      assn.Data.Forms.splice(index, 1)
                                    }
                                  }
                                }
                              }}
                              onClose={() =>
                                this.setState({
                                  ...this.state,
                                  selectedForm: null,
                                  taskAnchorEl: null,
                                  selectedUnit: null,
                                  selectedTask: null,
                                  selectedForms: [],
                                  selectedScope: null,
                                })
                              }
                              info={
                                selectedTask &&
                                selectedUnit && (
                                  <div className='w-full'>
                                    <Typography className='font-bold text-20 text-center w-full mt-16 mb-16'>
                                      {selectedTask.Data.Task.Name}
                                    </Typography>
                                    <div className='flex'>
                                      <div className='w-full text-left'>
                                        {selectedTask && (
                                          <div className='w-full'>
                                            <strong className='mr-8 font-bold'>
                                              Task:
                                            </strong>
                                            <label>
                                              {selectedTask.Data.Task.TaskType}
                                            </label>
                                          </div>
                                        )}
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Unit:
                                          </strong>
                                          <label>{`${selectedUnit.UnitID} - ${selectedUnit.Name}`}</label>
                                        </div>
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Type:
                                          </strong>
                                          <label>{selectedUnit.UnitType}</label>
                                        </div>
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Manufacturer:
                                          </strong>
                                          <label>
                                            {selectedUnit.Manufacturer}
                                          </label>
                                        </div>
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Model:
                                          </strong>
                                          <label>{selectedUnit.Model}</label>
                                        </div>
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Serial #:
                                          </strong>
                                          <label>
                                            {selectedUnit.SerialNumber}
                                          </label>
                                        </div>
                                      </div>
                                      <div className='w-full text-right'>
                                        {technician && (
                                          <div className='w-full'>
                                            <strong className='mr-8 font-bold'>
                                              Technician:
                                            </strong>
                                            <label className='mr-6'>{`${technician.FirstName} ${technician.LastName}`}</label>
                                          </div>
                                        )}
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Date:
                                          </strong>
                                          <label>
                                            {selectedForm.ResultID
                                              ? new Date(
                                                  selectedForm.EnteredDate,
                                                ).toLocaleDateString('en-US')
                                              : new Date().toLocaleDateString(
                                                  'en-US',
                                                )}
                                          </label>
                                        </div>
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Site:
                                          </strong>
                                          <label>{Data.Site.Site}</label>
                                        </div>
                                        <div className='w-full'>
                                          <label>{Data.Site.Address1}</label>
                                        </div>
                                        <div className='w-full'>
                                          <label>{`${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`}</label>
                                        </div>
                                        <div className='w-full'>
                                          <strong className='mr-8 font-bold'>
                                            Work Order:
                                          </strong>
                                          <label>{this.state.WorkOrder}</label>
                                        </div>
                                        {/* {trip &&
                                                                        <div className="w-full">
                                                                            <strong className="mr-8 font-bold">Date:</strong>
                                                                            <label>{new Date(trip.InProgressDate).toLocaleDateString('en-US')}</label>
                                                                        </div>
                                                                    } */}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            />
                          )}
                          {selectedChecklist &&
                            selectedUnit &&
                            selectedScope && (
                              <TaskChecklist
                                header={
                                  selectedDivision &&
                                  selectedDivision.ChecklistHeaderImg
                                    ? selectedDivision.ChecklistHeaderImg
                                    : undefined
                                }
                                footer={
                                  selectedDivision &&
                                  selectedDivision.ChecklistFooterImg
                                    ? selectedDivision.ChecklistFooterImg
                                    : undefined
                                }
                                assignment={{
                                  Co: selectedUnit.Co,
                                  Customer: selectedUnit.Customer,
                                  Site: selectedUnit.Site,
                                  Agreement: selectedTask
                                    ? selectedTask.Agreement
                                    : null,
                                  Cycle: selectedTask
                                    ? selectedTask.Cycle
                                    : null,
                                  TaskType: selectedTask
                                    ? selectedTask.TaskType
                                    : null,
                                  UnitID: selectedUnit.UnitID,
                                  WorkOrder: selectedScope.WorkOrder,
                                  Scope: selectedScope.Scope,
                                }}
                                scope={{ ...selectedScope }}
                                checklist={{ ...selectedChecklist }}
                                open={Boolean(selectedChecklist)}
                                onAttachment={this.uploadChecklistImg}
                                onAdd={checklistData => {
                                  if (selectedTask) {
                                    const index = _.findIndex(
                                      selectedUnit.Data.Checklists,
                                      { Checklist: checklistData.Checklist },
                                    )
                                    if (index > -1) {
                                      selectedUnit.Data.Checklists.splice(
                                        index,
                                        1,
                                      )
                                    }
                                    selectedUnit.Data.ChecklistResults.push(
                                      checklistData.Data.Result,
                                    )
                                  } else {
                                    const assn = _.find(
                                      selectedScope.Data.ServiceUnits,
                                      { UnitID: selectedUnit.UnitID },
                                    )
                                    if (assn) {
                                      const index = _.findIndex(
                                        assn.Data.Checklists,
                                        { Checklist: checklistData.Checklist },
                                      )
                                      if (index > -1) {
                                        assn.Data.Checklists.splice(index, 1)
                                      }
                                      assn.Data.ChecklistResults.push(
                                        checklistData.Data.Result,
                                      )
                                    }
                                  }
                                }}
                                onUpdate={checklistData => {
                                  if (selectedTask) {
                                    const index = _.findIndex(
                                      selectedUnit.Data.ChecklistResults,
                                      { Checklist: checklistData.Checklist },
                                    )
                                    if (index > -1) {
                                      selectedUnit.Data.ChecklistResults.splice(
                                        index,
                                        1,
                                        checklistData,
                                      )
                                    }
                                  } else {
                                    const assn = _.find(
                                      selectedScope.Data.ServiceUnits,
                                      { UnitID: selectedUnit.UnitID },
                                    )
                                    if (assn) {
                                      const index = _.findIndex(
                                        assn.Data.Checklists,
                                        { Checklist: checklistData.Checklist },
                                      )
                                      if (index > -1) {
                                        assn.Data.Checklists.splice(
                                          index,
                                          1,
                                          checklistData,
                                        )
                                      }
                                      // assn.Data.ChecklistResults.push(checklistData.Data.Result);
                                    }
                                  }
                                }}
                                onDelete={checklistData => {
                                  if (selectedTask) {
                                    const index = _.findIndex(
                                      selectedUnit.Data.ChecklistResults,
                                      { Checklist: checklistData.Checklist },
                                    )
                                    if (index > -1) {
                                      selectedUnit.Data.ChecklistResults.splice(
                                        index,
                                        1,
                                      )
                                    }
                                  } else {
                                    const assn = _.find(
                                      selectedScope.Data.ServiceUnits,
                                      { UnitID: selectedUnit.UnitID },
                                    )
                                    if (assn) {
                                      const index = _.findIndex(
                                        assn.Data.Checklists,
                                        { Checklist: checklistData.Checklist },
                                      )
                                      if (index > -1) {
                                        assn.Data.Checklists.splice(index, 1)
                                      }
                                    }
                                  }
                                }}
                                onClose={() =>
                                  this.setState({
                                    ...this.state,
                                    selectedChecklist: null,
                                    taskAnchorEl: null,
                                    selectedUnit: null,
                                    selectedTask: null,
                                    selectedChecklists: [],
                                    selectedDivision: null,
                                    selectedScope: null,
                                  })
                                }
                                info={
                                  selectedScope &&
                                  [selectedScope].map(scope => {
                                    const { Co, BusinessUnit, Division } =
                                      scope || {}
                                    const division = _.find(divisions, {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                    })
                                    return (
                                      <div className='w-full'>
                                        <div className='w-full flex justify-between my-24'>
                                          {division &&
                                            division.InvoiceHeaderImg && (
                                              <img
                                                className='h-48 sm:h-96'
                                                src={division.InvoiceHeaderImg}
                                              />
                                            )}
                                          <span className='sm:mt-12'>
                                            <label
                                              className={classNames(
                                                classes.invHeader,
                                                'sm:pr-8 text-20 sm:text-36',
                                              )}
                                            >
                                              CHECKLIST
                                            </label>
                                            <div
                                              className={classNames(
                                                classes.invSubHeader,
                                                'sm:pr-8 text-10 sm:text-16',
                                              )}
                                            >
                                              Work Order #{this.state.WorkOrder}
                                              -{selectedScope.Scope}
                                            </div>
                                          </span>
                                        </div>

                                        <div className='flex mb-24 justify-between p-16 pr-4'>
                                          <div className='cursor-pointer'>
                                            <div className='w-full text-16 font-bold text-left'>
                                              {Data.Customer.Name &&
                                              Data.Customer.Name.length > 0
                                                ? Data.Customer.Name
                                                : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                            </div>
                                            <div className='w-full text-12 text-left'>
                                              {Data.Customer.Address1}
                                            </div>
                                            {Data.Customer.Address2 && (
                                              <div className='w-full text-12 text-left'>
                                                {Data.Customer.Address2}
                                              </div>
                                            )}
                                            <div className='w-full text-12 text-left'>
                                              {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                            </div>
                                          </div>

                                          <div>
                                            <div
                                              className='flex cursor-pointer'
                                              onClick={this.openDatePicker}
                                            >
                                              <img
                                                src='assets/icons/calendar_icon.png'
                                                className='w-32 h-32 mr-8'
                                              />
                                              <div className='w-full'>
                                                <div className='text-14 font-bold'>
                                                  {selectedChecklist.ResultID
                                                    ? moment(
                                                        selectedChecklist.EnteredDate,
                                                      ).format('dddd')
                                                    : moment(Date()).format(
                                                        'dddd',
                                                      )}
                                                </div>
                                                <div className='text-10'>
                                                  {selectedChecklist.ResultID
                                                    ? moment(
                                                        selectedChecklist.EnteredDate,
                                                      ).format('MMM DD YYYY')
                                                    : moment(Date()).format(
                                                        'MMM DD YYYY',
                                                      )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='flex mt-24'>
                                          <div className='w-full'>
                                            <div
                                              className='w-full flex justify-between rounded-full mb-12'
                                              style={{
                                                backgroundColor: '#3f3f3f',
                                              }}
                                            >
                                              <div
                                                className={classNames(
                                                  'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                                  classes.dividerHighlight,
                                                )}
                                              >
                                                {selectedChecklist.Name}
                                              </div>
                                              <div
                                                className={classNames(
                                                  'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                                  classes.dividerPlain,
                                                )}
                                              >
                                                {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='w-full flex justify-between'>
                                          <div className='text-12 text-left pl-16'>
                                            {selectedTask && (
                                              <div className='w-full'>
                                                <strong className='mr-8 font-bold'>
                                                  Task:
                                                </strong>
                                                <label>
                                                  {selectedTask.Data.Task.Name}
                                                </label>
                                              </div>
                                            )}
                                            {/* {selectedUnit.UnitCat && _.find(serviceUnitCategories, { UnitCat: selectedUnit.UnitCat }) &&
                                                                            <div className="w-full">
                                                                                <strong className="mr-8 font-bold">Category:</strong>
                                                                                <label>{_.find(serviceUnitCategories, { UnitCat: selectedUnit.UnitCat }).Name}</label>
                                                                            </div>
                                                                        } */}
                                            {selectedUnit.UnitType &&
                                              _.find(serviceUnitTypes, {
                                                UnitType: selectedUnit.UnitType,
                                              }) && (
                                                <div className='w-full'>
                                                  <strong className='mr-8 font-bold'>
                                                    Unit Type:
                                                  </strong>
                                                  <label>
                                                    {
                                                      _.find(serviceUnitTypes, {
                                                        UnitType:
                                                          selectedUnit.UnitType,
                                                      }).Name
                                                    }
                                                  </label>
                                                </div>
                                              )}
                                            <div className='w-full'>
                                              <strong className='mr-8 font-bold'>
                                                Unit ID:
                                              </strong>
                                              <label>{`${selectedUnit.UnitID} - ${selectedUnit.Name}`}</label>
                                            </div>
                                            {selectedUnit.Manufacturer && (
                                              <div className='w-full'>
                                                <strong className='mr-8 font-bold'>
                                                  Manufacturer:
                                                </strong>
                                                <label>
                                                  {selectedUnit.Manufacturer}
                                                </label>
                                              </div>
                                            )}
                                            {selectedUnit.Model && (
                                              <div className='w-full'>
                                                <strong className='mr-8 font-bold'>
                                                  Model:
                                                </strong>
                                                <label>
                                                  {selectedUnit.Model}
                                                </label>
                                              </div>
                                            )}
                                            {selectedUnit.SerialNumber && (
                                              <div className='w-full'>
                                                <strong className='mr-8 font-bold'>
                                                  Serial #:
                                                </strong>
                                                <label>
                                                  {selectedUnit.SerialNumber}
                                                </label>
                                              </div>
                                            )}
                                          </div>
                                          {technician && (
                                            <div
                                              className='flex mr-24 mt-4'
                                              style={{ marginBottom: -28 }}
                                            >
                                              <img
                                                src='assets/icons/person_icon.png'
                                                className='w-24 h-24 mr-4'
                                              />
                                              <div className='w-full'>
                                                <div className='text-12 font-bold mt-4'>
                                                  {`${technician.FirstName} ${technician.LastName}`}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                // info={selectedTask && selectedUnit &&
                                //     <div className="w-full">
                                //         <Typography className="font-bold text-20 text-center w-full mt-16 mb-16">
                                //             {selectedTask.Data.Task.Name}
                                //         </Typography>
                                //         <div className="flex">
                                //             <div className="w-full text-left">
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Task:</strong>
                                //                     <label>{selectedTask.Data.Task.TaskType}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Unit:</strong>
                                //                     <label>{`${selectedUnit.UnitID} - ${selectedUnit.Name}`}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Type:</strong>
                                //                     <label>{selectedUnit.UnitType}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Manufacturer:</strong>
                                //                     <label>{selectedUnit.Manufacturer}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Model:</strong>
                                //                     <label>{selectedUnit.Model}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Serial #:</strong>
                                //                     <label>{selectedUnit.SerialNumber}</label>
                                //                 </div>
                                //             </div>
                                //             <div className="w-full text-right">
                                //                 {technician &&
                                //                     <div className="w-full">
                                //                         <strong className="mr-8 font-bold">Technician:</strong>
                                //                         <label className="mr-6">{`${technician.FirstName} ${technician.LastName}`}</label>
                                //                     </div>
                                //                 }
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Date:</strong>
                                //                     <label>{selectedChecklist.ResultID ? new Date(selectedChecklist.EnteredDate).toLocaleDateString('en-US') : new Date().toLocaleDateString('en-US')}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Site:</strong>
                                //                     <label>{Data.Site.Site}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <label>{Data.Site.Address1}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <label>{`${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`}</label>
                                //                 </div>
                                //                 <div className="w-full">
                                //                     <strong className="mr-8 font-bold">Work Order:</strong>
                                //                     <label>{this.state.WorkOrder}</label>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </div>
                                // }
                                footerInfo={
                                  selectedScope &&
                                  [selectedScope].map(scope => {
                                    const { Co } = scope || {}
                                    const co = (
                                      _.find(companies, { Co }) || { Data: {} }
                                    ).Data.Co
                                    if (co) {
                                      return (
                                        <div className='mx-24'>
                                          <div className='w-full sm:flex sm:justify-between'>
                                            <div className='mt-24'>
                                              <div className='w-full flex'>
                                                <img
                                                  src='assets/icons/location_icon.png'
                                                  className='w-32 h-32 mr-8'
                                                />
                                                <div className='w-full'>
                                                  <div className='text-14 font-bold'>
                                                    {co.Name}
                                                  </div>
                                                  <div className='text-10'>
                                                    {co.Address}
                                                  </div>
                                                  <div className='text-10'>
                                                    {`${co.City}, ${co.State} ${co.Zip}`}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='mt-24'>
                                              <div className='w-full flex'>
                                                <img
                                                  src='assets/icons/email_icon.png'
                                                  className='w-32 h-32 mr-8'
                                                />
                                                <div className='w-full'>
                                                  <div className='text-14 font-bold'>
                                                    Email
                                                  </div>
                                                  <div className='text-10'>
                                                    {co.Email}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='mt-24'>
                                              <div className='w-full flex'>
                                                <img
                                                  src='assets/icons/phone_icon.png'
                                                  className='w-32 h-32 mr-8'
                                                />
                                                <div className='w-full'>
                                                  <div className='text-14 font-bold'>
                                                    Phone
                                                  </div>
                                                  <div className='text-10'>
                                                    {this.formatPhone(co.Phone)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              />
                            )}
                          {selectedForm &&
                            selectedScope &&
                            !selectedTask &&
                            !selectedUnit && (
                              <TaskForm
                                assignment={{
                                  Co: selectedScope.Co,
                                  WorkOrder: selectedScope.WorkOrder,
                                  Scope: selectedScope.Scope,
                                }}
                                form={{ ...selectedForm }}
                                open={Boolean(selectedForm)}
                                onAdd={formData => {
                                  const index = _.findIndex(
                                    selectedScope.Data.Forms,
                                    { Form: formData.Form },
                                  )
                                  if (index > -1) {
                                    selectedScope.Data.Forms.splice(index, 1)
                                  }
                                  selectedScope.Data.FormResults.push(
                                    formData.Data.Result,
                                  )
                                }}
                                scope={{ ...selectedScope }}
                                onUpdate={formData => {
                                  const index = _.findIndex(
                                    selectedScope.Data.FormResults,
                                    { Form: formData.Form },
                                  )
                                  if (index > -1) {
                                    selectedScope.Data.FormResults.splice(
                                      index,
                                      1,
                                      formData,
                                    )
                                  }
                                }}
                                onDelete={formData => {
                                  const index = _.findIndex(
                                    selectedScope.Data.FormResults,
                                    { Form: formData.Form },
                                  )
                                  if (index > -1) {
                                    selectedScope.Data.FormResults.splice(
                                      index,
                                      1,
                                    )
                                  }
                                }}
                                onClose={() =>
                                  this.setState({
                                    ...this.state,
                                    selectedForm: null,
                                    taskAnchorEl: null,
                                    selectedUnit: null,
                                    selectedTask: null,
                                    selectedForms: [],
                                    selectedScope: null,
                                  })
                                }
                                info={
                                  selectedScope && (
                                    <div className='w-full'>
                                      <Typography className='font-bold text-20 text-center w-full mt-16 mb-16'>
                                        {selectedScope.Description}
                                      </Typography>
                                      <div className='flex'>
                                        <div className='w-full text-right'>
                                          {technician && (
                                            <div className='w-full'>
                                              <strong className='mr-8 font-bold'>
                                                Technician:
                                              </strong>
                                              <label className='mr-6'>{`${technician.FirstName} ${technician.LastName}`}</label>
                                            </div>
                                          )}
                                          <div className='w-full'>
                                            <strong className='mr-8 font-bold'>
                                              Date:
                                            </strong>
                                            <label>
                                              {selectedForm.ResultID
                                                ? new Date(
                                                    selectedForm.EnteredDate,
                                                  ).toLocaleDateString('en-US')
                                                : new Date().toLocaleDateString(
                                                    'en-US',
                                                  )}
                                            </label>
                                          </div>
                                          <div className='w-full'>
                                            <strong className='mr-8 font-bold'>
                                              Site:
                                            </strong>
                                            <label>{Data.Site.Site}</label>
                                          </div>
                                          <div className='w-full'>
                                            <label>{Data.Site.Address1}</label>
                                          </div>
                                          <div className='w-full'>
                                            <label>{`${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`}</label>
                                          </div>
                                          <div className='w-full'>
                                            <strong className='mr-8 font-bold'>
                                              Work Order:
                                            </strong>
                                            <label>
                                              {this.state.WorkOrder}
                                            </label>
                                          </div>
                                          <div className='w-full'>
                                            <strong className='mr-8 font-bold'>
                                              Scope:
                                            </strong>
                                            <label>{selectedScope.Scope}</label>
                                          </div>
                                          {/* {trip &&
                                                                        <div className="w-full">
                                                                            <strong className="mr-8 font-bold">Date:</strong>
                                                                            <label>{new Date(trip.InProgressDate).toLocaleDateString('en-US')}</label>
                                                                        </div>
                                                                    } */}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              />
                            )}
                          {selectedChecklist &&
                            selectedScope &&
                            !selectedTask &&
                            !selectedUnit && (
                              <TaskChecklist
                                assignment={{
                                  Co: selectedScope.Co,
                                  WorkOrder: selectedScope.WorkOrder,
                                  Scope: selectedScope.Scope,
                                }}
                                checklist={{ ...selectedChecklist }}
                                open={Boolean(selectedChecklist)}
                                onAdd={checklistData => {
                                  const index = _.findIndex(
                                    selectedScope.Data.Checklists,
                                    { Checklist: checklistData.Checklist },
                                  )
                                  if (index > -1) {
                                    selectedScope.Data.Checklists.splice(
                                      index,
                                      1,
                                    )
                                  }
                                  selectedScope.Data.ChecklistResults.push(
                                    checklistData.Data.Result,
                                  )
                                }}
                                // scope={{ ...selectedScope }}
                                onUpdate={checklistData => {
                                  const index = _.findIndex(
                                    selectedScope.Data.ChecklistResults,
                                    { Checklist: checklistData.Checklist },
                                  )
                                  if (index > -1) {
                                    selectedScope.Data.ChecklistResults.splice(
                                      index,
                                      1,
                                      checklistData,
                                    )
                                  }
                                }}
                                onDelete={checklistData => {
                                  const index = _.findIndex(
                                    selectedScope.Data.ChecklistResults,
                                    { Checklist: checklistData.Checklist },
                                  )
                                  if (index > -1) {
                                    selectedScope.Data.ChecklistResults.splice(
                                      index,
                                      1,
                                    )
                                  }
                                }}
                                onAttachment={this.uploadChecklistImg}
                                onClose={() =>
                                  this.setState({
                                    ...this.state,
                                    selectedChecklist: null,
                                    taskAnchorEl: null,
                                    selectedUnit: null,
                                    selectedTask: null,
                                    selectedChecklists: [],
                                    selectedScope: null,
                                  })
                                }
                                info={
                                  selectedScope &&
                                  [selectedScope].map(scope => {
                                    const { Co, BusinessUnit, Division } =
                                      scope || {}
                                    const division = _.find(divisions, {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                    })
                                    return (
                                      <div className='w-full'>
                                        <div className='w-full flex justify-between my-24'>
                                          {division &&
                                            division.InvoiceHeaderImg && (
                                              <img
                                                className='h-48 sm:h-96'
                                                src={division.InvoiceHeaderImg}
                                              />
                                            )}
                                          <span className='sm:mt-12'>
                                            <label
                                              className={classNames(
                                                classes.invHeader,
                                                'sm:pr-8 text-20 sm:text-36',
                                              )}
                                            >
                                              CHECKLIST
                                            </label>
                                            <div
                                              className={classNames(
                                                classes.invSubHeader,
                                                'sm:pr-8 text-10 sm:text-16',
                                              )}
                                            >
                                              Work Order #{this.state.WorkOrder}
                                              -{selectedScope.Scope}
                                            </div>
                                          </span>
                                        </div>

                                        <div className='flex mb-24 justify-between p-16 pr-4'>
                                          <div className='cursor-pointer'>
                                            <div className='w-full text-16 font-bold text-left'>
                                              {Data.Customer.Name &&
                                              Data.Customer.Name.length > 0
                                                ? Data.Customer.Name
                                                : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                            </div>
                                            <div className='w-full text-12 text-left'>
                                              {Data.Customer.Address1}
                                            </div>
                                            {Data.Customer.Address2 && (
                                              <div className='w-full text-12 text-left'>
                                                {Data.Customer.Address2}
                                              </div>
                                            )}
                                            <div className='w-full text-12 text-left'>
                                              {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                            </div>
                                          </div>

                                          <div>
                                            <div
                                              className='flex cursor-pointer'
                                              onClick={this.openDatePicker}
                                            >
                                              <img
                                                src='assets/icons/calendar_icon.png'
                                                className='w-32 h-32 mr-8'
                                              />
                                              <div className='w-full'>
                                                <div className='text-14 font-bold'>
                                                  {selectedChecklist.ResultID
                                                    ? moment(
                                                        selectedChecklist.EnteredDate,
                                                      ).format('dddd')
                                                    : moment(Date()).format(
                                                        'dddd',
                                                      )}
                                                </div>
                                                <div className='text-10'>
                                                  {selectedChecklist.ResultID
                                                    ? moment(
                                                        selectedChecklist.EnteredDate,
                                                      ).format('MMM DD YYYY')
                                                    : moment(Date()).format(
                                                        'MMM DD YYYY',
                                                      )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='flex mt-24'>
                                          <div className='w-full'>
                                            <div
                                              className='w-full flex justify-between rounded-full mb-12'
                                              style={{
                                                backgroundColor: '#3f3f3f',
                                              }}
                                            >
                                              <div
                                                className={classNames(
                                                  'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                                  classes.dividerHighlight,
                                                )}
                                              >
                                                {selectedChecklist.Name}
                                              </div>
                                              <div
                                                className={classNames(
                                                  'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                                  classes.dividerPlain,
                                                )}
                                              >
                                                {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {technician && (
                                          <div className='w-full flex justify-end'>
                                            <div
                                              className='flex mr-24 mt-4'
                                              style={{ marginBottom: -28 }}
                                            >
                                              <img
                                                src='assets/icons/person_icon.png'
                                                className='w-24 h-24 mr-4'
                                              />
                                              <div className='w-full'>
                                                <div className='text-12 font-bold mt-4'>
                                                  {`${technician.FirstName} ${technician.LastName}`}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  })
                                }
                                footerInfo={
                                  selectedScope &&
                                  [selectedScope].map(scope => {
                                    const { Co } = scope || {}
                                    const co = (
                                      _.find(companies, { Co }) || { Data: {} }
                                    ).Data.Co
                                    if (co) {
                                      return (
                                        <div className='mx-24'>
                                          <div className='w-full sm:flex sm:justify-between'>
                                            <div className='mt-24'>
                                              <div className='w-full flex'>
                                                <img
                                                  src='assets/icons/location_icon.png'
                                                  className='w-32 h-32 mr-8'
                                                />
                                                <div className='w-full'>
                                                  <div className='text-14 font-bold'>
                                                    {co.Name}
                                                  </div>
                                                  <div className='text-10'>
                                                    {co.Address}
                                                  </div>
                                                  <div className='text-10'>
                                                    {`${co.City}, ${co.State} ${co.Zip}`}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='mt-24'>
                                              <div className='w-full flex'>
                                                <img
                                                  src='assets/icons/email_icon.png'
                                                  className='w-32 h-32 mr-8'
                                                />
                                                <div className='w-full'>
                                                  <div className='text-14 font-bold'>
                                                    Email
                                                  </div>
                                                  <div className='text-10'>
                                                    {co.Email}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='mt-24'>
                                              <div className='w-full flex'>
                                                <img
                                                  src='assets/icons/phone_icon.png'
                                                  className='w-32 h-32 mr-8'
                                                />
                                                <div className='w-full'>
                                                  <div className='text-14 font-bold'>
                                                    Phone
                                                  </div>
                                                  <div className='text-10'>
                                                    {this.formatPhone(co.Phone)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              />
                            )}
                        </div>
                        {/* <div className="w-full py-12" style={{ borderBottom: '1px solid lightgrey' }}>
                                            <ListItem onClick={this.launchEstimate} classes={{ root: classes.liRoot }} className="relative cursor-pointer min-h-36">
                                                <Icon className="text-24 mr-12 ml-4" color="primary">format_list_numbered</Icon>
                                                <ListItemText classes={{ root: classes.liRoot, primary: classes.title }} primary={<span className={classNames("font-bold text-14")}>Estimates</span>} />
                                                <Icon className="pin-r absolute">keyboard_arrow_down</Icon>
                                            </ListItem>
                                        </div>
                                        <div className="w-full py-12" style={{ borderBottom: '1px solid lightgrey' }}>
                                            <ListItem onClick={this.launchInvoice} classes={{ root: classes.liRoot }} className="relative cursor-pointer min-h-36">
                                                <Icon className="text-24 mr-12 ml-4" color="primary">receipt</Icon>
                                                <ListItemText classes={{ root: classes.liRoot, primary: classes.title }} primary={<span className={classNames("font-bold text-14")}>Invoices</span>} />
                                                <Icon className="pin-r absolute">keyboard_arrow_down</Icon>
                                            </ListItem>
                                        </div> */}
                        <div
                          className='w-full flex mb-8 pb-8'
                          style={{ minHeight: 256 }}
                        >
                          <div
                            className='p-12 w-full pr-72'
                            style={{
                              borderRight: '1px solid lightgrey',
                              paddingLeft: 2,
                              maxHeight: 512,
                              overflowY: 'auto',
                            }}
                          >
                            {this.state.activityType === 'tags' && (
                              <Tags
                                tags={[
                                  {
                                    Title: 'Customer',
                                    Icon: 'account_circle',
                                    Tags:
                                      this.state.Data.Customer.Data &&
                                      this.state.Data.Customer.Data.Tags
                                        ? this.state.Data.Customer.Data.Tags
                                        : [],
                                  },
                                  {
                                    Title: 'Site',
                                    Icon: 'location_on',
                                    Tags:
                                      this.state.Data.Site.Data &&
                                      this.state.Data.Site.Data.Tags
                                        ? this.state.Data.Site.Data.Tags
                                        : [],
                                  },
                                  {
                                    Title: 'Work Order',
                                    Icon: 'assignment',
                                    Tags: this.state.Data.Tags || [],
                                  },
                                ]}
                              />
                            )}

                            {this.state.activityType === 'recordings' && (
                              <Recordings
                                recordings={[...this.state.Data.Recordings]}
                              />
                            )}

                            {this.state.activityType === 'comments' && (
                              <Comments
                                comments={[
                                  ...(this.state.Data.Comments
                                    ? this.state.Data.Comments
                                    : []),
                                ]}
                                recordID={this.state.ID}
                                type='WorkOrder'
                                commentAdd={comment => {
                                  const { Data } = this.state
                                  const { Comments } = Data

                                  if (_.isArray(Comments)) {
                                    Comments.splice(0, 0, comment)
                                    this.setState({
                                      ...this.state,
                                      Data: { ...Data, Comments },
                                    })
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className='relative'>
                            <Drawer
                              variant='permanent'
                              className={clsx(
                                classNames(
                                  classes.drawer,
                                  'h-full pin-r pin-t absolute',
                                ),
                                {
                                  [classes.drawerOpen]: this.state.woDrawer,
                                  [classes.drawerClose]: !this.state.woDrawer,
                                },
                              )}
                              classes={{
                                paper: clsx('relative border-none h-full', {
                                  [classes.drawerOpen]: this.state.woDrawer,
                                  [classes.drawerClose]: !this.state.woDrawer,
                                }),
                              }}
                              style={{
                                borderLeft: '1px solid lightgrey',
                                borderRight: '1px solid lightgrey',
                                minHeight: 256,
                              }}
                              anchor='right'
                              open={this.state.woDrawer}
                            >
                              <div className={classes.toolbar}>
                                {!this.state.woDrawer && (
                                  <IconButton
                                    className='w-48 h-48 ml-4'
                                    onClick={() =>
                                      this.setState({
                                        ...this.state,
                                        woDrawer: true,
                                      })
                                    }
                                  >
                                    <Icon>menu</Icon>
                                  </IconButton>
                                )}
                                {this.state.woDrawer && (
                                  <IconButton
                                    className='w-48 h-48 ml-4'
                                    onClick={() =>
                                      this.setState({
                                        ...this.state,
                                        woDrawer: false,
                                      })
                                    }
                                  >
                                    <Icon>chevron_right</Icon>
                                  </IconButton>
                                )}
                              </div>
                              <Divider />
                              <List>
                                <ListItem
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      activityType: 'tags',
                                    })
                                  }
                                  button
                                >
                                  <ListItemIcon>
                                    <Badge
                                      classes={{ badge: classes.drawerBadge }}
                                      badgeContent={
                                        [
                                          ...(this.state.Data.Tags
                                            ? this.state.Data.Tags
                                            : []),
                                          ...(this.state.Data.Customer.Data &&
                                          this.state.Data.Customer.Data.Tags
                                            ? this.state.Data.Customer.Data.Tags
                                            : []),
                                          ...(this.state.Data.Site.Data &&
                                          this.state.Data.Site.Data.Tags
                                            ? this.state.Data.Site.Data.Tags
                                            : []),
                                        ].length
                                      }
                                      color='error'
                                    >
                                      <Icon
                                        color={
                                          this.state.activityType === 'tags'
                                            ? 'primary'
                                            : 'action'
                                        }
                                      >
                                        label
                                      </Icon>
                                    </Badge>
                                  </ListItemIcon>
                                  <ListItemText primary={'Tags'} />
                                </ListItem>
                                <ListItem
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      activityType: 'recordings',
                                    })
                                  }
                                  button
                                >
                                  <ListItemIcon>
                                    <Badge
                                      classes={{ badge: classes.drawerBadge }}
                                      badgeContent={
                                        (this.state.Data.Recordings
                                          ? this.state.Data.Recordings
                                          : []
                                        ).length
                                      }
                                      color='error'
                                    >
                                      <Icon
                                        color={
                                          this.state.activityType ===
                                          'recordings'
                                            ? 'primary'
                                            : 'action'
                                        }
                                      >
                                        voicemail
                                      </Icon>
                                    </Badge>
                                  </ListItemIcon>
                                  <ListItemText primary={'Recordings'} />
                                </ListItem>
                                <ListItem
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      activityType: 'comments',
                                    })
                                  }
                                  button
                                >
                                  <ListItemIcon>
                                    <Badge
                                      classes={{ badge: classes.drawerBadge }}
                                      badgeContent={
                                        (this.state.Data.Comments
                                          ? this.state.Data.Comments
                                          : []
                                        ).length
                                      }
                                      color='error'
                                    >
                                      <Icon
                                        color={
                                          this.state.activityType === 'comments'
                                            ? 'primary'
                                            : 'action'
                                        }
                                      >
                                        comment
                                      </Icon>
                                    </Badge>
                                  </ListItemIcon>
                                  <ListItemText primary={'Comments'} />
                                </ListItem>
                              </List>
                            </Drawer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </TabContainer>
              )}
              <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
                {this.state.checklistResults}
              </div>
              {value === 1 && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <React.Fragment>
                    {Address && Latitude && Longitude ? (
                      <div
                        className='sm:flex'
                        style={{
                          marginLeft: -24,
                          marginRight: -24,
                          marginTop: -24,
                        }}
                      >
                        {/* <div className="min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20" style={{ position: 'relative' }}>
                                            <Tooltip title="Map View" placement="top">
                                                <Icon
                                                    color="action"
                                                    onClick={(event) => {
                                                        this.toggleStreetview(false);
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >map</Icon>
                                            </Tooltip>
                                            <br />
                                            <Tooltip title="Street View" placement="top">
                                                <Icon
                                                    color="action"
                                                    className="pin-r pin-t mr-8 sm:pin-none absolute"
                                                    onClick={(event) => {
                                                        this.toggleStreetview(true);
                                                    }}
                                                    style={{ cursor: 'pointer', bottom: 40, }}
                                                >directions_car</Icon>
                                            </Tooltip>
                                        </div> */}
                        <div
                          style={{
                            width: '100%',
                            height: '300px',
                            backgroundColor: '#eeeeee',
                            marginBottom: 24,
                            // borderRadius: '5px',
                            overflow: 'hidden',
                          }}
                        >
                          <GoogleMap
                            options={this.getMapOptions}
                            gestureHandling='cooperative'
                            bootstrapURLKeys={{
                              key: googleMapsApiKey,
                            }}
                            defaultZoom={12}
                            defaultCenter={[
                              Number(Latitude),
                              Number(Longitude),
                            ]}
                            center={[Number(Latitude), Number(Longitude)]}
                          >
                            <Marker
                              key={1}
                              style={{
                                margin: 0,
                                padding: 0,
                                width: 32,
                                height: 42,
                                marginTop: -42,
                                marginLeft: -16,
                                cursor: 'pointer',
                              }}
                              icon='assets/images/logos/SPMarker_Gradient.png'
                              text={this.state.Description}
                              lat={Number(Latitude)}
                              lng={Number(Longitude)}
                            />
                          </GoogleMap>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>location_on</Icon>
                      </div>

                      <Autocomplete
                        className={classes.formControl}
                        title='Site'
                        options={
                          siteList.length > 0
                            ? siteList
                            : this.state.Data.Customer &&
                                this.state.Data.Customer.Data &&
                                this.state.Data.Customer.Data.Sites
                              ? this.state.Data.Site
                                ? [
                                    this.state.Data.Site,
                                    ...this.state.Data.Customer.Data.Sites,
                                  ]
                                : this.state.Data.Customer.Data.Sites
                              : this.state.Data.Site
                                ? [this.state.Data.Site]
                                : []
                        }
                        searchButton={true}
                        menuItemComponent={value => {
                          return (
                            <MenuItem
                              disabled={value.ActiveYN === 'N'}
                              value={value.Site}
                            >
                              {`${value.Site} - ${(
                                value.Description || ''
                              ).substr(0, 50)}`}
                            </MenuItem>
                          )
                        }}
                        debounce={750}
                        onChange={text =>
                          this.setState(
                            { ...this.state, siteDescription: text },
                            this.handleSiteSearch,
                          )
                        }
                        onBlur={() =>
                          this.setState({ ...this.state, searchingSite: false })
                        }
                        search={this.state.siteDescription || ''}
                        value={this.state.Site || ''}
                        portal={true}
                        noflip={true}
                        autoFocus={true}
                        onSelect={option =>
                          this.setState(
                            {
                              ...this.state,
                              Customer: option.Customer,
                              Site: option.Site,
                              SiteContact: null,
                              RequestedByContact: null,
                              Contact: null,
                              ContactPhone: null,
                              Latitude: option.Latitude,
                              Longitude: option.Longitude,
                              Data: { ...this.state.Data, Site: option },
                            },
                            () => this.handleSiteSelect(option),
                          )
                        }
                        required
                        disabled={
                          (this.state.Data &&
                            this.state.Data.Estimates &&
                            this.state.Data.Estimates.length > 0) ||
                          (this.state.Data &&
                            this.state.Data.Invoices &&
                            this.state.Data.Invoices.length > 0)
                        }
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Entered By'
                        id='enteredBy'
                        name='EnteredBy'
                        value={this.state.EnteredBy || user.UserName}
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        select
                        disabled={true}
                      >
                        {users &&
                          users.map((value, index) => (
                            <MenuItem key={index} value={value.UserName}>
                              <div className='flex'>
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  classes={{ root: classes.avatarRoot }}
                                  className={classes.userAvatar}
                                  alt={value.Technician}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`
                                      : userAvatar
                                  }
                                />
                                {`${value.FirstName} ${value.LastName}`}
                              </div>
                            </MenuItem>
                          ))}
                      </TextField>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.status}
                        label='Status'
                        id='status'
                        name='Status'
                        value={this.state.Status}
                        onChange={this.handleChange}
                        variant='outlined'
                        required
                        fullWidth
                        select
                      >
                        <MenuItem value={0}>Open</MenuItem>
                        <MenuItem value={1}>In Progress</MenuItem>
                        <MenuItem value={2}>On Hold</MenuItem>
                        <MenuItem value={3}>Closed</MenuItem>
                        {/* <MenuItem value={4}>
                                            Canceled
                                    </MenuItem> */}
                      </TextField>
                    </div>

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>chat_bubble_outline</Icon>
                      </div>
                      <TextField
                        className={classes.formControl}
                        label='Description'
                        id='description'
                        name='Description'
                        value={this.state.Description}
                        onChange={this.handleChange}
                        variant='outlined'
                        multiline
                        rows={5}
                        fullWidth
                      />
                    </div>

                    {!this.state.SiteContact &&
                      !this.state.RequestedByContact && (
                        <div className='sm:flex'>
                          <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                            <Icon color='action'>phone</Icon>
                          </div>
                          <TextField
                            className={classes.formControl}
                            label='Contact Name'
                            id='contact'
                            name='Contact'
                            value={this.state.Contact}
                            onChange={this.handleChange}
                            variant='outlined'
                            fullWidth
                          />
                          <div className='hidden sm:block min-w-12 pt-20'></div>
                          <TextField
                            className={classes.formControl}
                            label='Contact Phone'
                            id='contactPhone'
                            name='ContactPhone'
                            value={this.state.ContactPhone}
                            onChange={this.handleChange}
                            variant='outlined'
                            fullWidth
                          />
                        </div>
                      )}

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>person_pin</Icon>
                      </div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Site Contact'
                        options={[...siteContacts, ...custContacts]}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Data.Contact.Contact}>
                              {`${value.Data.Contact.Name} - ${
                                value.Data.Contact.Phone
                                  ? this.formatPhone(value.Data.Contact.Phone)
                                  : value.Data.Contact.Email
                              }`}
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        value={this.state.SiteContact || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            SiteContact: option.Contact,
                          })
                        }
                        activeOnly={true}
                        error={this.state.trySubmit && !this.state.SiteContact}
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Requested By'
                        options={[...siteContacts, ...custContacts]}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Data.Contact.Contact}>
                              {`${value.Data.Contact.Name} - ${
                                value.Data.Contact.Phone
                                  ? this.formatPhone(value.Data.Contact.Phone)
                                  : value.Data.Contact.Email
                              }`}
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        value={this.state.RequestedByContact || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            RequestedByContact: option.Contact,
                          })
                        }
                        activeOnly={true}
                        error={
                          (this.state.trySubmit && !this.state.SiteContact) ||
                          (reqBy && reqBy.RequiresApprovalYN === 'Y')
                        }
                        helperText={
                          reqBy && reqBy.RequiresApprovalYN === 'Y'
                            ? 'Approval Required'
                            : undefined
                        }
                      />
                    </div>

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>note</Icon>
                      </div>
                      <TextField
                        className={classes.formControl}
                        label='Notes'
                        id='notes'
                        name='Notes'
                        value={this.state.Notes}
                        onChange={this.handleChange}
                        variant='outlined'
                        multiline
                        rows={5}
                        fullWidth
                      />
                    </div>
                  </React.Fragment>
                </TabContainer>
              )}
              {value === 2 && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <React.Fragment>
                    {accessLevel && accessLevel.AccessLevel !== 'R' && (
                      <Fab
                        color='secondary'
                        aria-label='add'
                        className={classes.addButton}
                        onClick={event => {
                          openNewWorkOrderScopeDialog({
                            Co,
                            BusinessUnit: null,
                            Division: null,
                            Department: null,
                            WorkOrder: this.state.WorkOrder,
                            Scope: null,
                            ServiceType: null,
                            Service: null,
                            Description: null,
                            Detail: '',
                            Originator: null,
                            Requestor: this.state.Requestor,
                            DueBy: null,
                            Priority: 3,
                            CustomerPO: null,
                            NotToExceed: null,
                            LeadTechnician: null,
                            ArrivalWindow: null,
                            ExactTime: null,
                            PriceMethod: 'T',
                            Price: null,
                            FormOfPayment: null,
                            NonBillableYN: 'N',
                            TaxCode:
                              this.state.Data.Site.TaxCode ||
                              this.state.Data.Customer.TaxCode,
                            QuoteID: null,
                            EnteredBy: null,
                            EnteredDate: new Date(),
                            Status: 0,
                            Notes: null,
                            ReadyToBillYN: null,
                            value: 0,
                            Data: {
                              WorkOrder: {
                                ...this.state,
                                Data: { Scopes: this.state.Data.Scopes },
                              },
                              Customer: {
                                ...this.state.Data.Customer,
                                Data: null,
                              },
                              Site: { ...this.state.Data.Site, Data: null },
                              Tags: [],
                              ServiceUnits: [],
                            },
                            streetView: false,
                          })
                        }}
                      >
                        <Tooltip title='New Work Order Scope' placement='left'>
                          <Icon>add</Icon>
                        </Tooltip>
                      </Fab>
                    )}
                    <WorkOrderScopesList
                      selectedWOData={[...this.state.Data.Scopes]}
                    />
                  </React.Fragment>
                </TabContainer>
              )}
              {value === 3 && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  {tripAccessLevel && tripAccessLevel.AccessLevel !== 'R' && (
                    <Fab
                      color='secondary'
                      aria-label='add'
                      className={classes.addButton}
                      onClick={event => {
                        openNewTripDialog({
                          Co,
                          WorkOrder,
                          Trip: null,
                          Technician: null,
                          ScheduledDate: null,
                          ScheduledTime: null,
                          Status: 0,
                          Duration: 0.5,
                          CreatedBy: user.UserName,
                          CreatedDate: new Date(),
                          Data: {
                            ErrMsg: null,
                            Tags: [],
                            Scopes: this.state.Data.Scopes,
                            Assignments: [],
                          },
                        })
                      }}
                    >
                      <Tooltip title='New Trip' placement='left'>
                        <Icon>add</Icon>
                      </Tooltip>
                    </Fab>
                  )}
                  <TripsList selectedTripData={[...this.state.Data.Trips]} />
                </TabContainer>
              )}
              {value === 4 && workOrderDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  {billingAccessLevel &&
                    billingAccessLevel.AccessLevel !== 'R' && (
                      <Fab
                        color='secondary'
                        aria-label='add'
                        className={classes.addButton}
                        onClick={event => {
                          openNewWorkOrderUsageDialog(
                            {
                              Co,
                              WorkOrder,
                              Scope: trip
                                ? _.filter(Data.Scopes, o => {
                                    return (
                                      _.findIndex(trip.Data.Assignments, {
                                        Scope: o.Scope,
                                      }) > -1
                                    )
                                  })[0].Scope
                                : this.state.Data.Scopes[0].Scope,
                              Data: {
                                Assignments: trip
                                  ? trip.Data.Assignments
                                  : null,
                                WorkOrder: this.state,
                              },
                            },
                            Taxability ? Taxability : null,
                          )
                        }}
                      >
                        <Tooltip title='New Usage Line' placement='left'>
                          <Icon>add</Icon>
                        </Tooltip>
                      </Fab>
                    )}
                  <WorkOrderUsagesList
                    assignments={trip ? trip.Data.Assignments : null}
                    workOrder={{ ...this.state }}
                    selectedWorkOrderUsageData={[...this.state.Data.Usage]}
                  />
                </TabContainer>
              )}
              {value === 5 && workOrderDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <PurchaseOrdersList
                    workOrder={{ ...this.state }}
                    selectedPurchaseOrderData={[...this.state.Data.POs]}
                  />
                </TabContainer>
              )}
              {value === 6 && workOrderDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <EstimatesList
                    workOrder={{ ...this.state }}
                    estimateData={[...this.state.Data.Estimates]}
                  />
                </TabContainer>
              )}
              {value === 7 && workOrderDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <div>
                    <Typography
                      variant='h6'
                      style={{ alignItems: 'center' }}
                      className='flex text-16 sm:text-20 mb-6 sm:mb-12'
                    >
                      <Badge
                        color='error'
                        className='mr-12'
                        badgeContent={this.state.Data.Billing.length}
                      >
                        <Icon color='primary' className='text-32'>
                          account_box
                        </Icon>
                      </Badge>
                      Billable Customers
                    </Typography>
                    <div className={classes.customerSelect}>
                      <div
                        className={classes.customerScroll}
                        style={{ width: this.state.Data.Billing.length * 380 }}
                      >
                        {this.state.Data.Billing.map((value, index) => (
                          <InvoiceCustomer
                            selected={_.isEqual(value, BillingData)}
                            onClick={() => {
                              this.setBillingData(value)
                            }}
                            key={index}
                            customerData={{ ...value.Customer }}
                            invoiceData={[...value.Invoices]}
                            scopeData={[...value.Scopes]}
                          />
                        ))}
                      </div>
                      <hr
                        style={{
                          clear: 'both',
                          border: 'none',
                          width: '100%',
                          height: 0,
                        }}
                      />
                    </div>
                    {billingAccessLevel &&
                      billingAccessLevel.AccessLevel !== 'R' &&
                      BillingData && (
                        <Fab
                          color='secondary'
                          aria-label='add'
                          className={classes.addButton}
                          onClick={event => {
                            // window["warn"](event, this.state);
                            openNewInvoiceDialog({
                              ...BillingData,
                              WorkOrder,
                              Scope: null,
                              InvoiceNumber: `${
                                dv && dv.InvoicePrefix ? dv.InvoicePrefix : 'I'
                              }${WorkOrder}-${
                                this.state.Data.Invoices.length + 1
                              }`,
                            })
                          }}
                        >
                          <Tooltip title='New Invoice' placement='left'>
                            <Icon>add</Icon>
                          </Tooltip>
                        </Fab>
                      )}
                    <InvoicesList invoiceData={[...this.state.Data.Invoices]} />
                  </div>
                </TabContainer>
              )}
              {value === 8 && workOrderDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <ServiceUnitsList
                    customerSite={{ ...this.state.Data.Site }}
                    selectedServiceUnitData={
                      this.state.Data.Site.Data &&
                      this.state.Data.Site.Data.Units
                        ? [...this.state.Data.Site.Data.Units]
                        : []
                    }
                  />
                </TabContainer>
              )}
              {value === 9 && workOrderDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <FileList
                    attachments={this.state.Data.Attachments}
                    onAdded={this.attachmentSelectedHandler}
                    accept='.jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt'
                  />
                </TabContainer>
              )}

              {value === 10 && (
                <TabContainer
                  className='h-full sm:p-12 md:p-24'
                  maxHeight='unset'
                  inheritPadding={true}
                >
                  <div
                    className={classNames(classes.hist, 'h-full p-4 sm:p-0')}
                    style={{ maxHeight: 'calc(100% - 8px)' }}
                  >
                    <Drawer
                      variant='permanent'
                      className={classNames(classes.histDrawer, {
                        [classes.histDrawerOpen]: this.state.open,
                        [classes.histDrawerClose]: !this.state.open,
                      })}
                      classes={{
                        paper: classNames(classes.histShelf, {
                          [classes.histDrawerOpen]: this.state.open,
                          [classes.histDrawerClose]: !this.state.open,
                        }),
                      }}
                      open={this.state.open}
                    >
                      {this.state.Data.Site.Data && (
                        <List>
                          <ListItem
                            onClick={() => this.setState({ histTab: 0 })}
                            button
                            key='Work Orders'
                          >
                            <ListItemIcon>
                              <Tooltip title='Work Orders' placement='right'>
                                <Badge
                                  max={999}
                                  badgeContent={
                                    this.state.Data.Site.WorkOrders || 0
                                  }
                                  color='error'
                                >
                                  <Assignment />
                                </Badge>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                          <ListItem
                            onClick={() => this.setState({ histTab: 1 })}
                            button
                            key='Work Order Scopes'
                          >
                            <ListItemIcon>
                              <Tooltip
                                title='Work Order Scopes'
                                placement='right'
                              >
                                <Badge
                                  max={999}
                                  badgeContent={
                                    this.state.Data.Site.Scopes || 0
                                  }
                                  color='error'
                                >
                                  <Build />
                                </Badge>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                          <ListItem
                            onClick={() => this.setState({ histTab: 2 })}
                            button
                            key='Visits'
                          >
                            <ListItemIcon>
                              <Tooltip title='Trips' placement='right'>
                                <Badge
                                  max={999}
                                  badgeContent={this.state.Data.Site.Trips || 0}
                                  color='error'
                                >
                                  <LocalShipping />
                                </Badge>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                          <ListItem
                            onClick={() => this.setState({ histTab: 3 })}
                            button
                            key='Invoices'
                          >
                            <ListItemIcon>
                              <Tooltip title='Invoices' placement='right'>
                                <Badge
                                  max={999}
                                  badgeContent={
                                    this.state.Data.Site.Invoices || 0
                                  }
                                  color='error'
                                >
                                  <Receipt />
                                </Badge>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                          <ListItem
                            onClick={() => this.setState({ histTab: 4 })}
                            button
                            key='Payments'
                          >
                            <ListItemIcon>
                              <Tooltip title='Payments' placement='right'>
                                <Badge
                                  max={999}
                                  badgeContent={
                                    this.state.Data.Site.Payments || 0
                                  }
                                  color='error'
                                >
                                  <Payment />
                                </Badge>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                          <ListItem
                            onClick={() => this.setState({ histTab: 5 })}
                            button
                            key='Estimates'
                          >
                            <ListItemIcon>
                              <Tooltip title='Estimates' placement='right'>
                                <Badge
                                  max={999}
                                  badgeContent={
                                    this.state.Data.Site.Estimates || 0
                                  }
                                  color='error'
                                >
                                  <ListIcon />
                                </Badge>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                        </List>
                      )}
                    </Drawer>
                    <div className={classNames(classes.histContent, 'h-full')}>
                      {this.state.Data.Site.Data && (
                        <div
                          className={classNames(classes.histTable, 'h-full')}
                        >
                          {histTab === 0 && (
                            <TabContainer
                              className='h-full'
                              maxHeight={'auto'}
                              padding={'0px'}
                            >
                              <WorkOrdersList
                                woDialog={true}
                                hist={{
                                  PageCount: Math.ceil(
                                    this.state.Data.Site.WorkOrders / 10,
                                  ),
                                  Results: [],
                                  Page: 1,
                                  PageSize: 10,
                                  TotalCount: this.state.Data.Site.WorkOrders,
                                }}
                                customer={this.state.Data.Site.Customer}
                                site={this.state.Data.Site.Site}
                                selectedWOData={[
                                  ...(this.state.Data.Site.Data.WorkOrders ||
                                    []),
                                ]}
                              />
                            </TabContainer>
                          )}
                          {histTab === 1 && (
                            <TabContainer padding={'0px'}>
                              <WorkOrderScopesList
                                hist={{
                                  PageCount: Math.ceil(
                                    this.state.Data.Site.Scopes / 10,
                                  ),
                                  Results: [],
                                  Page: 1,
                                  PageSize: 10,
                                  TotalCount: this.state.Data.Site.Scopes,
                                }}
                                customer={this.state.Data.Site.Customer}
                                site={this.state.Data.Site.Site}
                                selectedWOData={[
                                  ...(this.state.Data.Site.Data
                                    .WorkOrderScopes || []),
                                ]}
                              />
                            </TabContainer>
                          )}
                          {histTab === 2 && (
                            <TabContainer padding={'0px'}>
                              <TripsList
                                hist={{
                                  PageCount: Math.ceil(
                                    this.state.Data.Site.Trips / 10,
                                  ),
                                  Results: [],
                                  Page: 1,
                                  PageSize: 10,
                                  TotalCount: this.state.Data.Site.Trips,
                                }}
                                customer={this.state.Data.Site.Customer}
                                site={this.state.Data.Site.Site}
                                selectedTripData={[
                                  ...(this.state.Data.Site.Data.Trips || []),
                                ]}
                              />
                            </TabContainer>
                          )}
                          {histTab === 3 && (
                            <TabContainer padding={'0px'}>
                              <InvoicesList
                                hist={{
                                  PageCount: Math.ceil(
                                    this.state.Data.Site.Invoices / 10,
                                  ),
                                  Results: [],
                                  Page: 1,
                                  PageSize: 10,
                                  TotalCount: this.state.Data.Site.Invoices,
                                }}
                                customer={this.state.Data.Site.Customer}
                                site={this.state.Data.Site.Site}
                                invoiceData={[
                                  ...(this.state.Data.Site.Data.Invoices || []),
                                ]}
                              />
                            </TabContainer>
                          )}
                          {histTab === 4 && (
                            <TabContainer padding={'0px'}>
                              <PaymentsList
                                hist={{
                                  PageCount: Math.ceil(
                                    this.state.Data.Site.Payments / 10,
                                  ),
                                  Results: [],
                                  Page: 1,
                                  PageSize: 10,
                                  TotalCount: this.state.Data.Site.Payments,
                                }}
                                customer={this.state.Data.Site.Customer}
                                site={this.state.Data.Site.Site}
                                payments={[
                                  ...(this.state.Data.Site.Data.Payments || []),
                                ]}
                              />
                            </TabContainer>
                          )}
                          {histTab === 5 && (
                            <TabContainer padding={'0px'}>
                              {/* <EstimateDialog /> */}
                              <EstimatesList
                                hist={{
                                  PageCount: Math.ceil(
                                    this.state.Data.Site.Estimates / 10,
                                  ),
                                  Results: [],
                                  Page: 1,
                                  PageSize: 10,
                                  TotalCount: this.state.Data.Site.Estimates,
                                }}
                                customer={this.state.Data.Site.Customer}
                                site={this.state.Data.Site.Site}
                                estimateData={[
                                  ...(this.state.Data.Site.Data.Estimates ||
                                    []),
                                ]}
                              />
                            </TabContainer>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </TabContainer>
              )}
              {value === 11 && workOrderDialog.type == 'edit' && (
                <TabContainer
                  padding={'16px'}
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 56px)'
                  }
                >
                  <DashboardSelector data={this.state} form='work-order' />
                </TabContainer>
              )}
              {trip && holdInfo && (
                <ConfirmationDialog
                  open={Boolean(this.state.holdInfo)}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4' color='error'>
                        pause_circle_outline
                      </Icon>
                      Additional Information Needed
                    </div>
                  }
                  content={
                    <div className='w-full'>
                      Please describe why this trip is being placed on hold.
                      <TextField
                        className='mt-12'
                        label='On-Hold Notes'
                        id='trip-notes'
                        name='tripNotes'
                        value={this.state.tripNotes || holdInfo.Notes}
                        onChange={this.handleChange}
                        variant='outlined'
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </div>
                  }
                  disableConfirm={
                    !this.state.tripNotes || this.state.tripNotes.length < 3
                  }
                  confirmText={'Continue'}
                  cancelText='Cancel'
                  onCancel={() =>
                    this.setState({ ...this.state, holdInfo: false })
                  }
                  onConfirm={() =>
                    this.updateStatus(
                      { ...holdInfo, Notes: this.state.tripNotes },
                      7,
                    )
                  }
                />
              )}
              {trip && returnPrompt && (
                <TripHoldDialog
                  trip={{ ...trip }}
                  open={this.state.returnPrompt}
                  title={
                    <div>
                      <Icon className='mr-6 align-middle mb-4'>today</Icon>
                      Schedule a Return Trip
                    </div>
                  }
                  message={
                    <div className='w-full pt-8 pb-8 mb-12'>{`Please select when you will return to perform the remaining work`}</div>
                  }
                  cancelText='Cancel'
                  confirmText='Add Return Trip'
                  onCancel={() =>
                    this.setState({ ...this.state, returnPrompt: false })
                  }
                  onConfirm={() =>
                    this.setState({ ...this.state, returnPrompt: false })
                  }
                />
              )}

              {accessLevel &&
                accessLevel.AccessLevel !== 'R' &&
                value === 1 && (
                  <DialogActions className='dialog-actions justify-between pl-16'>
                    {['new', 'site'].indexOf(workOrderDialog.type) > -1 ? (
                      <DebounceButton
                        buttonText={'Add'}
                        processingText={'Adding'}
                        isDisabled={!this.canBeSubmitted()}
                        variant={'contained'}
                        color={'primary'}
                        clickFxn={() => {
                          return addWorkOrder({
                            ...this.state,
                            Data: { ErrMsg: null },
                          })
                        }}
                        debounceInterval={1000}
                      />
                    ) : (
                      <React.Fragment>
                        <DebounceButton
                          buttonText={'Save'}
                          processingText={'Saving'}
                          isDisabled={!this.canBeSubmitted()}
                          variant={'contained'}
                          color={'primary'}
                          clickFxn={() => {
                            return updateWorkOrder({
                              ...this.state,
                              Data: { ErrMsg: null },
                            })
                          }}
                          debounceInterval={1000}
                        />
                        {/* <IconButton
                                                    onClick={() => {
                                                        removeWorkOrder(this.state.id);
                                                        this.closeComposeDialog();
                                                    }}
                                                >
                                                    <Icon>delete</Icon>
                                                </IconButton> */}
                      </React.Fragment>
                    )}
                  </DialogActions>
                )}

              {/* <InvoiceDialog /> */}
              {/* <PurchaseOrderDialog /> */}
            </DraggableDialog>
          )}
        </Media>
      )
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEditWorkOrderDialog: Actions.updateEditWorkOrderDialog,
      closeEditWorkOrderDialog: Actions.closeEditWorkOrderDialog,
      closeNewWorkOrderDialog: Actions.closeNewWorkOrderDialog,
      addWorkOrder: Actions.addWorkOrder,
      updateWorkOrder: Actions.updateWorkOrder,
      openEditWorkOrderDialog: Actions.openEditWorkOrderDialog,
      updateWorkOrderScope: WorkOrderScopeActions.updateWorkOrderScope,
      removeWorkOrder: Actions.removeWorkOrder,
      openNewWorkOrderScopeDialog:
        WorkOrderScopeActions.openNewWorkOrderScopeDialog,
      openEditWorkOrderScopeDialog:
        WorkOrderScopeActions.openEditWorkOrderScopeDialog,
      openNewTripDialog: TripActions.openNewTripDialog,
      openEditTripDialog: TripActions.openEditTripDialog,
      updateTrip: TripActions.updateTrip,
      openCustomer: openEditCustomerDialog,
      openSite: openEditCustomerSiteDialog,
      setWorkOrderHistory,
      openNewWorkOrderUsageDialog,
      openNewInvoiceDialog,
      openEditInvoiceDialog,
      openNewPurchaseOrderDialog,
      openNewAgreementDialog,
      openNewEstimateDialog,
      openEditEstimateDialog,
      openEditAgreementDialog,
      toggleDialer: DialerActions.toggleDialer,
      initCall: DialerActions.initCall,
      showMessage,
      removeAgreementTask,
      openEditServiceUnitDialog,
      openNewServiceUnitDialog,
      addAgreement,
      getCustomerData,
      getSiteData,
      addSplitscreenDialog,
      showSplitscreenDialog,
      setSplitscreenDialog,
      hideAllSplitscreenDialogs,
      captureSplitscreenDialogs,
      openPreloadWorkOrderDialog,
      openPreloadWorkOrderDialogSplitScreen,
      openNewGpsInventoryTransferDialog,
      openEditGpsInventoryTransferDialog,
      addAlert,
    },
    dispatch,
  )
}

function mapStateToProps({
  auth,
  workOrdersApp,
  emailTemplateApp,
  fuse,
  spReducers,
  dialer,
  agreementsApp,
  estimatesApp,
  workOrderScopesApp,
  workOrderHistoryApp,
  workOrderUsagesApp,
  invoicesApp,
  customersApp,
  customerSitesApp,
  tripsApp,
  purchaseOrdersApp,
  serviceUnitsApp,
  splitscreenDialogApp,
  gpsInventoryTransfersApp,
}) {
  return {
    Co: spReducers.companies.Co,
    workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
    googleMapsApiKey: googleMapsApiKey,
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    dialer: dialer.sipPhone,
    rateTemplates: spReducers.rateTemplates,
    formOfPayments: spReducers.formOfPayments,
    businessUnits: spReducers.businessUnits,
    templates: emailTemplateApp.templates.Templates,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    services: spReducers.services,
    arrivalWindows: spReducers.arrivalWindows,
    serviceTypes: spReducers.serviceTypes,
    serviceUnitTypes: spReducers.serviceUnitTypes,
    serviceUnitCategories: spReducers.serviceUnitCategories,
    securables: spReducers.userProfiles.User.Data.Securables,
    technicians: spReducers.technicians,
    technician: spReducers.technician.technician,
    loggedIn: auth.login.success,
    catalogs: spReducers.catalog.catalogs,
    companies: spReducers.companies.List,
    materials: spReducers.materials.materials,
    laborTypes: spReducers.laborTypes,
    agreementOpen: agreementsApp.agreements.agreementDialog.props.open,
    estimateOpen: estimatesApp.estimates.estimateDialog.props.open,
    workOrderScopeOpen:
      workOrderScopesApp.workOrderScopes.workOrderScopeDialog.props.open,
    workOrderUsageOpen:
      workOrderUsagesApp.workOrderUsages.workOrderUsageDialog.props.open,
    invoiceOpen: invoicesApp.invoices.invoiceDialog.props.open,
    customerOpen: customersApp.customers.customerDialog.props.open,
    customerSiteOpen:
      customerSitesApp.customerSites.customerSiteDialog.props.open,
    tripOpen: tripsApp.trips.tripDialog.props.open,
    purchaseOrderOpen:
      purchaseOrdersApp.purchaseOrders.purchaseOrderDialog.props.open,
    serviceUnitOpen: serviceUnitsApp.serviceUnits.serviceUnitDialog.props.open,
    workOrderHistoryData: workOrderHistoryApp.workOrderHistory.data,
    splitscreenDialogs: splitscreenDialogApp.splitscreenDialog.data,
    splitscreenDialog: splitscreenDialogApp.splitscreenDialog,
    userPreferences: spReducers.userProfiles.User.Preferences,
    gpsInventoryTransferDialog: gpsInventoryTransfersApp,
    workOrder: workOrdersApp.workOrders.workOrderDialog.data,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(WorkOrdersDialog),
)
