import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedInvoiceIds: [],
    routeParams: {},
    fetchedInvoices: [],
    invoiceDialog: {
        type: 'new',
        props: {
            open: false,
            trip: null,
            readOnly: false
        },
        data: {
            Co: null,
            Invoice: null,
            InvoiceNumber: null,
            InvoiceDate: null,
            Status: null,
            Customer: null,
            PayTerms: null,
            DueDate: null,
            DiscountDate: null,
            DiscountRate: null,
            Description: null,
            WorkOrder: null,
            BillName: null,
            BillAddress1: null,
            BillAddress2: null,
            BillCity: null,
            BillState: null,
            BillZip: null,
            BillCountry: null,
            BillPhone: null,
            BillEmail: null,
            Technician: null,
            Notes: null,
            DeliveredYN: null,
            DeliveredDate: null,
            DeliveredBy: null,
            SummaryLevel: 'D',
            FormOfPayment: null,
            PaymentConfirmation: null,
            VoidDate: null,
            VoidedBy: null,
            EnteredBy: null,
            EnteredDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            AttachmentID: null,
            Signature: null,
            Data: {
                Scopes: [],
                Usage: [],
                InvoiceDetails: [],
                ErrMsg: null,
            }
        }
    }
};

const invoicesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_INVOICES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.FETCH_INVOICES:
            {
                return {
                    ...state,
                    fetchedInvoices: action.payload
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_INVOICES:
            {

                const invoiceId = action.invoiceId;

                let selectedInvoiceIds = [...state.selectedInvoiceIds];

                if (selectedInvoiceIds.find(id => id === invoiceId) !== undefined) {
                    selectedInvoiceIds = selectedInvoiceIds.filter(id => id !== invoiceId);
                }
                else {
                    selectedInvoiceIds = [...selectedInvoiceIds, invoiceId];
                }

                return {
                    ...state,
                    selectedInvoiceIds: selectedInvoiceIds
                };
            }
        case Actions.SELECT_ALL_INVOICES:
            {
                const { selectedInvoiceIds } = action;
                return {
                    ...state,
                    selectedInvoiceIds,
                };
            }
        case Actions.DESELECT_ALL_INVOICES:
            {
                return {
                    ...state,
                    selectedInvoiceIds: []
                };
            }
        case Actions.OPEN_NEW_INVOICE_DIALOG:
            {
                const { data, trip } = action;
                window["warn"]({
                    ...initialState,
                    ...data,
                });
                return {
                    ...state,
                    invoiceDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            trip,
                            readOnly: false
                        },
                        data: {
                            ...initialState.invoiceDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_INVOICE_DIALOG:
            {
                return {
                    ...state,
                    invoiceDialog: {
                        type: 'new',
                        props: {
                            open: false,
                            trip: null,
                            readOnly: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_INVOICE_DIALOG:
            {
                return {
                    ...state,
                    invoiceDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                            trip: null,
                            readOnly: action.readOnly
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_INVOICE_DIALOG:
            {
                return {
                    ...state,
                    invoiceDialog: {
                        ...state.invoiceDialog,
                        type: 'update',
                        data: (action.partial ? {
                            ...state.invoiceDialog.data,
                            ...action.partial
                        } : action.data)
                    }
                }
            }
        // case Actions.UPDATE_EDIT_INVOICE_DIALOG:
        //     {
        //         return {
        //             ...state,
        //             invoiceDialog: {
        //                 type: 'update',
        //                 props: {
        //                     open: true
        //                 },
        //                 data: action.data
        //             }
        //         };
        //     }
        case Actions.CLOSE_EDIT_INVOICE_DIALOG:
            {
                return {
                    ...state,
                    invoiceDialog: {
                        type: 'edit',
                        props: {
                            open: false,
                            trip: null,
                            readOnly: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default invoicesReducer;
