import { FuseAnimate } from '@fuse'
import _ from '@lodash'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Icon,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import * as authActions from 'auth/store/actions'
import axios from 'axios'
import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import AuthVault from '../../../components/vault/AuthVault';
import * as Actions from 'store/actions'
import {
  initializeVault,
  setLockType,
  lockVault,
  clearVault,
} from 'store/actions/spAuthentication'
import vault from '../../../../../store/reducers/spAuthentication/vault.reducer'
import Constants from "../../../../../Constants";
import {endpoint} from "../../../../../version";

const styles = theme => ({
  root: {
    background:
      "url('assets/images/backgrounds/SPCog_Spinner_BG_Dark.png') no-repeat",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    //filter: 'invert(90%)'
  },
  card: {
    width: '100%',
    maxWidth: 380,
  },
  cardLoading: {
    width: '100%',
    maxWidth: 284,
  },
  greyButton: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4f4f4f',
      color: '#fff',
    },
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
})

const initialState = {
  client: null,
  clientExists: null,
  email: null,
  phone: null,
  password: null,
  confirmPassword: null,
  remember: true,
  loggedIn: false,
  Co: null,
  user: null,
  validating: false,
  forgotPassword: false,
  pinSent: false,
  pinValidated: false,
  pin: null,
  pin1: null,
  pin2: null,
  pin3: null,
  pin4: null,
  pin5: null,
  pin6: null,
  confirmPassword: null,
  message: null,
  confirmEmail: null,
  verificationType: 'E',
}

class LoginPage extends Component {
  state = _.cloneDeepWith(initialState)

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
      ),
    )
  }

  canBeSubmitted() {
    const { client, email, password } = this.state
    return (
      client &&
      client.length > 0 &&
      email &&
      email.length > 0 &&
      password &&
      password.length > 0
    )
  }

  componentWillMount() {
    this.props.initializeVault()
  }

  componentDidMount() {
    const { user, Co, loggedIn, client } = this.props
    this.setState({
      ...initialState,
      user,
      Co,
      loggedIn,
      email: user && user.UserName ? user.UserName : this.state.email,
      client,
      clientExists: Boolean(client),
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { user, Co, loggedIn, client, vault, version, device } = this.props
    if (
      !loggedIn &&
      prevProps.vault.lockType === vault.lockType &&
      vault.lockType === 'Biometrics' &&
      ((!prevProps.vault.vault && vault.vault) || prevProps.loggedIn)
    ) {
      const os =
        device && (device.osName || device.os) && device.osVersion
          ? `${device.osName || device.os} ${device.osVersion}`
          : 'N/A'
      this.props.biometricLogin(version, os)
    }
    if (
      !_.isEqual(client, prevProps.client) ||
      !_.isEqual(Co, prevProps.Co) ||
      !_.isEqual(user, prevProps.user) ||
      !_.isEqual(loggedIn, prevProps.loggedIn)
    ) {
      this.setState({
        ...initialState,
        ...this.state,
        user,
        Co,
        loggedIn,
        email: user && user.UserName ? user.UserName : this.state.email,
        client,
        clientExists: Boolean(this.state.client || client),
      })
    }
  }

  logIn = (client, email, password, version, os) => {
    if (client && email && password) {
      this.props.logIn(client, email, password, version, os)
    }
  }

  validateClient = e => {
    this.setState(
      {
        validating: true,
        clientExists: null,
        email: this.state.email,
        password: null,
      },
      () => this.pingClient(),
    )
  }

  // pingClient = _.debounce(() => {
  //     this.setState({ clientExists: true });
  // }, 1500);

  pingClient = _.debounce(() => {
    const { client } = this.state

    if (process.env.NODE_ENV === 'development') {
        window[Constants.SUBDOMAIN_HEADER_KEY] = client;
        axios.defaults.headers.common[Constants.SUBDOMAIN_HEADER_KEY] = client;
    }

    const request = axios.get(
      endpoint(client) + '/api/ping',
    )
    request
      .then(response => {
        if (response.data.includes('pong')) {
          this.setState({ validating: false, clientExists: true })
        } else {
          this.setState({ validating: false, clientExists: false })
        }
      })
      .catch(error => {
        this.setState({ validating: false, clientExists: false })
      })
  }, 1500)

  requestReset = () => {
    const { email, client, phone, verificationType, confirmEmail } = this.state
    const request = axios.post(
      (endpoint(client)) + '/api/PasswordReset/Request',
      {
        UserName: email,
        Phone: phone,
        Type: verificationType,
        Email: confirmEmail,
      },
    )
    request.then(response => {
      if (!response.data.ErrMsg || response.data.ErrMsg.length < 1) {
        this.setState({ ...this.state, pinSent: response.data, message: null })
      } else {
        this.setState({ ...this.state, message: response.data.ErrMsg })
      }
    })
  }

  validatePin = () => {
    const { pinSent, pin, client } = this.state
    const request = axios.post(
      (endpoint(client)) +
        '/api/PasswordReset/Validate',
      {
        ...pinSent,
        pin,
      },
    )
    request.then(response => {
      if (!response.data.ErrMsg || response.data.ErrMsg.length < 1) {
        this.setState({
          ...this.state,
          pinSent: response.data,
          pinValidated: true,
          message: null,
        })
      } else {
        this.setState({ ...this.state, message: response.data.ErrMsg })
      }
    })
  }

  resetPassword = () => {
    const { pinSent, password, client } = this.state
    const request = axios.post(
      (endpoint(client)) + '/api/PasswordReset/Reset',
      {
        ...pinSent,
        password,
      },
    )
    request.then(response => {
      if (!response.data.ErrMsg || response.data.ErrMsg.length < 1) {
        this.setState({
          ...this.state,
          password: null,
          forgotPassword: false,
          phone: null,
          pin1: null,
          pin2: null,
          pin3: null,
          pin4: null,
          pin5: null,
          pin6: null,
          pin: null,
          pinSent: false,
          pinValidated: false,
          confirmPassword: null,
          message: (
            <div className='w-full text-center font-bold'>
              <Icon
                color='secondary'
                className='text-16 mr-4 align-middle'
                style={{ marginBottom: 2 }}
              >
                check_circle_outline
              </Icon>
              Password has been successfully reset.
            </div>
          ),
        })
      } else {
        this.setState({ ...this.state, message: response.data.ErrMsg })
      }
    })
  }

  render() {
    const { classes, logIn, version, device, vault } = this.props
    const {
      email,
      password,
      client,
      clientExists,
      remember,
      Co,
      user,
      confirmEmail,
      loggedIn,
      validating,
      forgotPassword,
      verificationType,
      phone,
      pinSent,
      pin,
      pinValidated,
      pin1,
      pin2,
      pin3,
      pin4,
      pin5,
      pin6,
      confirmPassword,
      message,
    } = this.state
    const os =
      device && (device.osName || device.os) && device.osVersion
        ? `${device.osName || device.os} ${device.osVersion}`
        : 'N/A'
    const co =
      user && user.Data && user.Data.Companies
        ? _.find(user.Data.Companies, { Co })
        : null
    let strong = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
    )
    if (
      loggedIn &&
      co &&
      user &&
      user.UserName &&
      user.Data &&
      co.Data &&
      co.Data.Role &&
      co.Data.Role.Data &&
      co.Data.Role.DefaultHomepage
    ) {
      return ''
    }
    if (loggedIn /* && user && user.UserName && !Co*/) {
      if (password && password.length > 0) {
        this.setState({ password: '' })
      }
      return (
        <div
          className={classNames(
            classes.root,
            'flex flex-col flex-auto flex-no-shrink items-center justify-center',
          )}
        >
          <div className='cog'></div>
          <div className='flex flex-col items-center justify-center w-full'>
            <FuseAnimate animation='transition.expandIn'>
              <Card className={classes.cardLoading}>
                <CardContent className='flex flex-col items-center justify-center p-24'>
                  <Icon
                    className='absolute pin-t pin-l mt-6 ml-6 font-20 cursor-pointer'
                    onClick={this.props.logOut}
                    style={{ transform: 'rotate(180deg)' }}
                  >
                    exit_to_app
                  </Icon>
                  <img
                    className='m-12'
                    style={{ width: '22rem' }}
                    src='assets/images/logos/SP_Logo_SM.png'
                    alt='logo'
                  />

                  <div
                    className='loader stretchBar'
                    style={{
                      width: '100%',
                    }}
                  >
                    <div className='rect1'></div>
                    <div className='rect2'></div>
                    <div className='rect3'></div>
                    <div className='rect4'></div>
                    <div className='rect5'></div>
                    <br />
                  </div>
                  <img
                    style={{ width: 80, marginTop: 28 }}
                    src='assets/images/splash/SPLoading.png'
                  />
                </CardContent>
              </Card>
            </FuseAnimate>
          </div>
        </div>
      )
    }
    if (
      (!loggedIn ||
        !co ||
        !co.Data ||
        !co.Data.Role ||
        !co.Data.Role.Data ||
        !co.Data.Role.DefaultHomepage) &&
      user &&
      user.UserName &&
      vault.lockTypes.biometrics &&
      vault.lockType === 'Biometrics'
    ) {
      return (
        <div
          className={classNames(
            classes.root,
            'flex flex-col flex-auto flex-no-shrink items-center justify-center',
          )}
        >
          <div className='cog'></div>
        </div>
      )
    }
    return (
      <div
        className={classNames(
          classes.root,
          'flex flex-col flex-auto flex-no-shrink items-center justify-center',
        )}
      >
        <div className='cog'></div>

        <div
          className='flex flex-col items-center justify-center w-full'
          style={{ width: 'calc(100vw - 24px)' }}
        >
          <FuseAnimate animation='transition.expandIn'>
            <Card className={classes.card}>
              <CardContent className='flex flex-col items-center justify-center p-24'>
                {client && clientExists && forgotPassword && (
                  <Icon
                    className='absolute pin-t pin-l mt-24 ml-24 text-28 cursor-pointer'
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        password: null,
                        forgotPassword: false,
                        phone: null,
                        pin1: null,
                        pin2: null,
                        pin3: null,
                        pin4: null,
                        pin5: null,
                        pin6: null,
                        pin: null,
                        pinSent: false,
                        pinValidated: false,
                        confirmPassword: null,
                      })
                    }
                  >
                    keyboard_arrow_left
                  </Icon>
                )}
                <img
                  className='m-0 mb-8'
                  style={{ width: '20rem' }}
                  src='assets/images/logos/SP_Logo_SM.png'
                  alt='logo'
                />

                <Typography variant='caption' className='m-8 mt-0 font-bold'>
                  {forgotPassword
                    ? pinValidated
                      ? 'CHOOSE NEW PASSWORD'
                      : pinSent
                        ? 'ENTER VERIFICATION CODE'
                        : 'FORGOT PASSWORD'
                    : 'PLEASE LOG IN'}
                </Typography>

                {!forgotPassword && (
                  <div className='flex flex-col justify-center w-full'>
                    <form
                      name='instanceForm'
                      onSubmit={event => {
                        if (client) {
                          this.validateClient()
                        }
                        event.preventDefault()
                      }}
                      noValidate
                      className='flex flex-col justify-center w-full'
                    >
                      <div
                        className='w-full rounded mb-8'
                        style={{ border: '1px solid lightgrey' }}
                      >
                        <div
                          className='bg-white text-grey-dark text-12 ml-12 px-4'
                          style={{ marginTop: -8, marginBottom: -8, width: 72 }}
                        >
                          Instance *
                        </div>
                        <div className='w-full flex'>
                          <TextField
                            className={classNames(
                              'mb-12 w-full rounded-l pt-10 pl-12',
                            )}
                            // label="Space ID"
                            autoFocus
                            name='client'
                            value={client || ''}
                            onChange={e =>
                              this.setState({
                                client: e.target.value,
                                clientExists: false,
                              })
                            }
                            InputProps={{
                              startAdornment: validating ? (
                                <CircularProgress
                                  color='primary'
                                  style={{ width: 24, height: 24 }}
                                />
                              ) : (
                                <InputAdornment position='start'>
                                  <Icon
                                    color={
                                      !clientExists ? 'action' : 'secondary'
                                    }
                                  >
                                    language
                                  </Icon>
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                              placeholder: 'Company',
                            }}
                            inputProps={{
                              spellCheck: 'false',
                              className: 'text-center',
                              autoComplete: 'off',
                            }}
                            InputLabelProps={{
                              style: {
                                height: 24,
                                padding: 0,
                              },
                            }}
                            required
                          />
                          <div
                            className='w-full bg-grey-lightest font-bold text-center p-16 px-4 rounded-r xs:text-12 sm:text-14'
                            style={{
                              paddingTop: 17,
                              borderLeft: '1px solid lightgrey',
                            }}
                          >
                            .servicepointpro.com
                          </div>
                        </div>
                      </div>
                      {!clientExists && (
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className='w-full'
                          aria-label='CONTINUE'
                          disabled={!client}
                        >
                          CONTINUE
                        </Button>
                      )}
                    </form>
                  </div>
                )}
                {client && clientExists && !forgotPassword && (
                  <form
                    name='loginForm'
                    onSubmit={event => {
                      this.logIn(client, email, password, version, os)
                      event.preventDefault()
                    }}
                    noValidate
                    className='flex flex-col justify-center w-full'
                  >
                    <TextField
                      className='mb-16 mt-8'
                      label='Username'
                      type='email'
                      name='email'
                      value={email || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon color='action'>person</Icon>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                    />

                    <TextField
                      className='mb-8'
                      label='Password'
                      type='password'
                      name='password'
                      value={password || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon color='action'>lock</Icon>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                    />

                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className='w-full'
                      aria-label='LOG IN'
                      disabled={!this.canBeSubmitted()}
                    >
                      LOGIN
                    </Button>
                    {vault.lockTypes.biometrics && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={vault.lockType === 'Biometrics'}
                            onChange={() =>
                              this.props.setLockType(
                                vault.lockType !== 'Biometrics'
                                  ? 'Biometrics'
                                  : 'NoLocking',
                              )
                            }
                            color='secondary'
                          />
                        }
                        label={'Enable Biometric Authentication'}
                      />
                    )}
                    {/* {!vault.lockTypes.biometrics && vault.lockTypes.passcode &&
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={vault.lockType === 'SystemPasscode'}
                                                        onChange={() => this.props.setLockType(vault.lockType !== 'SystemPasscode' ? 'SystemPasscode' : 'NoLocking')}
                                                        color="secondary"
                                                    />
                                                }
                                                label={"Use System Passcode"}
                                            />
                                        } */}
                    {message ? (
                      <Typography
                        variant='caption'
                        className='w-full text-center mt-8 mb-8 text-11'
                      >
                        {message}
                      </Typography>
                    ) : (
                      <div className='flex items-center justify-between'>
                        <Button
                          onClick={() =>
                            this.setState({ forgotPassword: true })
                          }
                          className='h-24 mt-8 w-full'
                          classes={{
                            label:
                              'capitalize text-grey-dark underline text-11',
                          }}
                        >
                          Forgot Password?
                        </Button>
                      </div>
                    )}
                  </form>
                )}
                {/* {vault.lockTypes.biometrics && vault.lockType === 'Biometrics' &&
                                    <Button variant="contained" color="primary" className="w-full mt-12" aria-label="BIOMETRIC LOG IN"
                                        onClick={() => this.props.biometricLogin(version, os)}>
                                        BIOMETRIC LOG IN
                                    </Button>
                                } */}
                {client && clientExists && forgotPassword && !pinSent && (
                  <form
                    name='verifyForm'
                    onSubmit={event => {
                      this.requestReset()
                      event.preventDefault()
                    }}
                    noValidate
                    className='flex flex-col justify-center w-full'
                  >
                    <TextField
                      className='mb-16 mt-8'
                      label='Username'
                      type='email'
                      name='email'
                      value={email || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon color='action'>person</Icon>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      required
                      fullWidth
                    />

                    <div className='w-full flex mb-16'>
                      <Button
                        variant='contained'
                        className={classNames(
                          'w-full rounded-tr-none rounded-br-none rounded-tl-lg rounded-bl-lg',
                          verificationType === 'E' && classes.greyButton,
                        )}
                        color={verificationType === 'T' ? 'primary' : undefined}
                        onClick={() => this.setState({ verificationType: 'T' })}
                      >
                        <Icon className='mr-4'>smartphone</Icon>Text
                      </Button>
                      <Button
                        variant='contained'
                        className={classNames(
                          'w-full rounded-tl-none rounded-bl-none rounded-tr-lg rounded-br-lg',
                          verificationType === 'T' && classes.greyButton,
                        )}
                        color={verificationType === 'E' ? 'primary' : undefined}
                        onClick={() => this.setState({ verificationType: 'E' })}
                      >
                        <Icon className='mr-4'>mail</Icon>Email
                      </Button>
                    </div>

                    {verificationType === 'T' && (
                      <TextField
                        className='mb-8'
                        label='Verify Cell Phone #'
                        type='tel'
                        name='phone'
                        value={phone || ''}
                        onChange={e => {
                          if (!isNaN(e.target.value)) {
                            this.setState({
                              ...this.state,
                              phone: e.target.value,
                            })
                          }
                        }}
                        variant='outlined'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Icon color='action'>smartphone</Icon>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        required
                        fullWidth
                      />
                    )}

                    {verificationType === 'E' && (
                      <TextField
                        className='mb-8'
                        label='Verify Email Address'
                        type='email'
                        name='confirmEmail'
                        value={confirmEmail || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Icon color='action'>mail</Icon>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        required
                        fullWidth
                      />
                    )}

                    {verificationType === 'T' && (
                      <Typography
                        variant='caption'
                        className='w-full text-center mb-8 text-10 text-grey-dark'
                      >
                        * By clicking "Send Verification Code" below, User
                        hereby is agreeing to receive a one-time text message
                        from Service Point Pro containing your verification
                        code. Reply STOP to unsubscribe. Msg&Data rates may
                        apply. <br />
                        <a
                          href='https://servicepointpro.com/legal/privacy-policy/'
                          target='_blank'
                        >
                          Privacy Policy
                        </a>
                      </Typography>
                    )}
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className='w-full mb-8'
                      aria-label='SEND'
                      disabled={
                        !email ||
                        email.length < 5 ||
                        (verificationType === 'T' &&
                          (!phone || phone.length !== 10)) ||
                        (verificationType === 'E' &&
                          (!confirmEmail || confirmEmail.length < 7))
                      }
                    >
                      Send Verification Code
                    </Button>
                    {message && (
                      <Typography
                        variant='caption'
                        className='w-full text-center mt-8 mb-8 text-11 text-red'
                      >
                        {message}
                      </Typography>
                    )}
                  </form>
                )}
                {client &&
                  clientExists &&
                  forgotPassword &&
                  pinSent &&
                  !pinValidated && (
                    <form
                      name='pinForm'
                      onSubmit={event => {
                        this.setState(
                          {
                            ...this.state,
                            pin: `${pin1}${pin2}${pin3}${pin4}${pin5}${pin6}`,
                          },
                          this.validatePin,
                        )
                        event.preventDefault()
                      }}
                      noValidate
                      className='flex flex-col justify-center w-full'
                    >
                      <div className='w-full flex justify-between'>
                        <TextField
                          className='mb-16 mt-8 mr-4'
                          name='pin1'
                          value={pin1 || ''}
                          onChange={e => {
                            if (
                              !isNaN(e.target.value) &&
                              e.target.value.length <= 1
                            ) {
                              this.setState(
                                { ...this.state, pin1: e.target.value },
                                () => {
                                  if (
                                    this.state.pin1 &&
                                    this.state.pin1.length > 0
                                  ) {
                                    this.pin2.focus()
                                  }
                                },
                              )
                            }
                          }}
                          variant='outlined'
                          inputProps={{
                            ref: ref => (this.pin1 = ref),
                            className: 'text-center font-bold',
                          }}
                          autoFocus={!pin1}
                          required
                          fullWidth
                        />
                        <TextField
                          className='mb-16 mt-8 mr-4'
                          name='pin2'
                          value={pin2 || ''}
                          onChange={e => {
                            if (
                              !isNaN(e.target.value) &&
                              e.target.value.length <= 1
                            ) {
                              this.setState(
                                { ...this.state, pin2: e.target.value },
                                () => {
                                  if (
                                    this.state.pin2 &&
                                    this.state.pin2.length > 0
                                  ) {
                                    this.pin3.focus()
                                  }
                                },
                              )
                            }
                          }}
                          variant='outlined'
                          inputProps={{
                            ref: ref => (this.pin2 = ref),
                            className: 'text-center font-bold',
                          }}
                          autoFocus={pin1 && !pin2}
                          required
                          fullWidth
                        />
                        <TextField
                          className='mb-16 mt-8 mr-4'
                          name='pin3'
                          value={pin3 || ''}
                          onChange={e => {
                            if (
                              !isNaN(e.target.value) &&
                              e.target.value.length <= 1
                            ) {
                              this.setState(
                                { ...this.state, pin3: e.target.value },
                                () => {
                                  if (
                                    this.state.pin3 &&
                                    this.state.pin3.length > 0
                                  ) {
                                    this.pin4.focus()
                                  }
                                },
                              )
                            }
                          }}
                          variant='outlined'
                          inputProps={{
                            ref: ref => (this.pin3 = ref),
                            className: 'text-center font-bold',
                          }}
                          autoFocus={pin1 && pin2 && !pin3}
                          required
                          fullWidth
                        />
                        <TextField
                          className='mb-16 mt-8 mr-4'
                          name='pin4'
                          value={pin4 || ''}
                          onChange={e => {
                            if (
                              !isNaN(e.target.value) &&
                              e.target.value.length <= 1
                            ) {
                              this.setState(
                                { ...this.state, pin4: e.target.value },
                                () => {
                                  if (
                                    this.state.pin4 &&
                                    this.state.pin4.length > 0
                                  ) {
                                    this.pin5.focus()
                                  }
                                },
                              )
                            }
                          }}
                          variant='outlined'
                          inputProps={{
                            ref: ref => (this.pin4 = ref),
                            className: 'text-center font-bold',
                          }}
                          autoFocus={pin1 && pin2 && pin3 && !pin4}
                          required
                          fullWidth
                        />
                        <TextField
                          className='mb-16 mt-8 mr-4'
                          name='pin5'
                          value={pin5 || ''}
                          onChange={e => {
                            if (
                              !isNaN(e.target.value) &&
                              e.target.value.length <= 1
                            ) {
                              this.setState(
                                { ...this.state, pin5: e.target.value },
                                () => {
                                  if (
                                    this.state.pin5 &&
                                    this.state.pin5.length > 0
                                  ) {
                                    this.pin6.focus()
                                  }
                                },
                              )
                            }
                          }}
                          variant='outlined'
                          inputProps={{
                            ref: ref => (this.pin5 = ref),
                            className: 'text-center font-bold',
                          }}
                          autoFocus={pin1 && pin2 && pin3 && pin4 && !pin5}
                          required
                          fullWidth
                        />
                        <TextField
                          className='mb-16 mt-8 mr-4'
                          name='pin6'
                          value={pin6 || ''}
                          onChange={e => {
                            if (
                              !isNaN(e.target.value) &&
                              e.target.value.length <= 1
                            ) {
                              this.setState({
                                ...this.state,
                                pin6: e.target.value,
                              })
                            }
                          }}
                          variant='outlined'
                          inputProps={{
                            ref: ref => (this.pin6 = ref),
                            className: 'text-center font-bold',
                          }}
                          autoFocus={
                            pin1 && pin2 && pin3 && pin4 && pin5 && !pin6
                          }
                          required
                          fullWidth
                        />
                      </div>

                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className='w-full mb-8'
                        aria-label='VERIFY'
                        disabled={
                          !pin1 || !pin2 || !pin3 || !pin4 || !pin5 || !pin6
                        }
                      >
                        Verify
                      </Button>
                      {message && (
                        <Typography
                          variant='caption'
                          className='w-full text-center mt-8 mb-8 text-11 text-red'
                        >
                          {message}
                        </Typography>
                      )}
                    </form>
                  )}
                {client &&
                  clientExists &&
                  forgotPassword &&
                  pinSent &&
                  pin &&
                  pinValidated && (
                    <form
                      name='loginForm'
                      onSubmit={event => {
                        this.resetPassword()
                        event.preventDefault()
                      }}
                      noValidate
                      className='flex flex-col justify-center w-full'
                    >
                      <TextField
                        className='mb-16 mt-8'
                        label='Password'
                        type='password'
                        name='password'
                        value={password || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        required
                        fullWidth
                      />

                      <TextField
                        className='mb-8'
                        label='Confirm Password'
                        type='password'
                        name='confirmPassword'
                        value={confirmPassword || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        required
                        fullWidth
                      />

                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className='w-full mb-8'
                        aria-label='RESET'
                        disabled={
                          !password ||
                          !strong.test(password) ||
                          password.length > 15 ||
                          !confirmPassword ||
                          password !== confirmPassword
                        }
                      >
                        Reset Password
                      </Button>
                      {message && (
                        <Typography
                          variant='caption'
                          className='w-full text-center mt-8 mb-8 text-11 text-red'
                        >
                          {message}
                        </Typography>
                      )}

                      <Typography
                        variant='caption'
                        className='w-full text-center mt-8 mb-8 text-11'
                      >
                        New password must satisfy each of the following
                        criteria:
                      </Typography>
                      <ul className='mb-8 text-11'>
                        <li>Contains 8 - 15 characters</li>
                        <li>Contains at least 1 Uppercase Letter</li>
                        <li>Contains at least 1 Lowercase Letter</li>
                        <li>Contains at least 1 Number</li>
                        <li>Contains at least 1 special Character</li>
                      </ul>
                    </form>
                  )}
                <Typography
                  variant='caption'
                  className='w-full text-center mt-8 mb-0 text-10'
                >
                  &copy; Service Point Pro {new Date().getFullYear()} - v.{' '}
                  {version}
                </Typography>
              </CardContent>
            </Card>
          </FuseAnimate>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logIn: Actions.userLogIn,
      biometricLogin: Actions.biometricLogin,
      logOut: authActions.logoutUser,
      refreshToken: Actions.refreshToken,
      initializeVault,
      setLockType,
      lockVault,
      // getAPILocation: Actions.getAPILocation,
    },
    dispatch,
  )
}

function mapStateToProps({ auth, spReducers, spAuth, version }) {
  return {
    loggedIn: auth.login.success,
    Co: spReducers.companies.Co,
    version: version.version,
    device: version.device,
    user: spReducers.userProfiles.User,
    location: spAuth.api.location,
    client: spAuth.api.client,
    vault: spAuth.vault,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage),
)
