import * as Actions from 'store/actions/spAuthentication';

const lockType = window.localStorage.getItem('SP_VLT');

const initialState = {
    vault: null,
    vaultIsLocked: Boolean(lockType === 'Biometrics'),
    lockTypes: {},
    lockType: lockType || 'NoLocking',
}

const vault = function (state = initialState, action) {
    switch (action.type) {
        case Actions.INITIALIZE_VAULT:
            {
                return {
                    ...state,
                    vault: action.payload
                }
            }
        case Actions.SET_LOCK_TYPES:
            {
                return {
                    ...state,
                    lockTypes: action.payload
                }
            }
        case Actions.SET_LOCK_TYPE:
            {
                return {
                    ...state,
                    lockType: action.payload
                }
            }
        case Actions.SET_VAULT_IS_LOCKED:
            {
                return {
                    ...state,
                    vaultIsLocked: action.payload
                }
            }
    }
    return state;
}

export default vault;