import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  TextField,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Icon,
  IconButton,
  Typography,
  Tooltip,
  Toolbar,
  AppBar,
  Avatar,
  Tabs,
  Tab,
  MenuItem,
} from '@material-ui/core'
import {
  AccountCircle,
  AccountBalance,
  Assignment,
  AttachFile,
  Build,
  BusinessCenter,
  ChevronRight,
  ChevronLeft,
  Dashboard,
  History,
  List as ListIcon,
  LocalShipping,
  LocationOn,
  Menu as MenuIcon,
  Payment,
  Receipt,
  ViewList,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles/index'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import { showMessage } from 'store/actions'
import { connect } from 'react-redux'
import { impress } from 'main/content/compression/impress'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import _ from '@lodash'
import Media from 'react-media'
import axios from 'axios'

const styles = theme => ({
  root: {},
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
  },
  checklistControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  header: {},
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
  updateSection: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteSection: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})
const newChecklistAssignmentState = {
  ID: null,
  Co: null,
  Checklist: null,
  Type: 'TASK',
  BusinessUnit: null,
  Division: null,
  Department: null,
  Service: null,
  AgreementType: null,
  TaskType: null,
  UnitCat: null,
  UnitType: null,
  Data: {
    ErrMsg: null,
  },
}

class ChecklistAssignmentDialog extends Component {
  state = { ...newChecklistAssignmentState }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const data = this.props.checklistAssignmentDialog.data
    /**
     * After Dialog Open
     */
    newChecklistAssignmentState.Co = this.props.Co
    if (
      !prevProps.checklistAssignmentDialog.props.open &&
      this.props.checklistAssignmentDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.checklistAssignmentDialog.type === 'edit' &&
        data &&
        !_.isEqual(data, prevState)
      ) {
        this.setState({ ...data })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.checklistAssignmentDialog.type === 'new' &&
        !_.isEqual({ ...newChecklistAssignmentState, ...data }, prevState)
      ) {
        this.setState({ ...newChecklistAssignmentState, ...data })
      }
    }

    if (
      prevProps.checklistAssignmentDialog.props.open &&
      this.props.checklistAssignmentDialog.props.open
    ) {
      if (
        this.props.checklistAssignmentDialog.type === 'data' &&
        data &&
        !_.isEqual(data, prevState)
      ) {
        this.props.checklistAssignmentDialog.type = 'edit'
        this.setState({ ...data })
      }
    }
    if (
      prevProps.checklistAssignmentDialog.props.open &&
      this.props.checklistAssignmentDialog.props.open
    ) {
      if (
        this.props.checklistAssignmentDialog.type === 'update' &&
        data &&
        !_.isEqual(data, prevState)
      ) {
        this.props.checklistAssignmentDialog.type = 'edit'
        this.setState({ ...data })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.checklistAssignmentDialog.type === 'edit'
      ? this.props.closeEditChecklistAssignmentDialog()
      : this.props.closeNewChecklistAssignmentDialog()
  }

  canBeSubmitted() {
    const {
      Co,
      Type,
      BusinessUnit,
      Division,
      Department,
      Service,
      AgreementType,
      TaskType,
    } = this.state
    return (
      Co &&
      Type &&
      BusinessUnit &&
      Division &&
      Co > 0 &&
      Type.length > 0 &&
      (Type === 'TASK' ? AgreementType && TaskType : true)
    )
  }

  render() {
    const {
      classes,
      securables,
      businessUnits,
      divisions,
      departments,
      services,
      agreementTypes,
      serviceUnitCategories,
      serviceUnitTypes,
      checklistAssignmentDialog,
      addChecklistAssignment,
      updateChecklistAssignment,
      removeChecklistAssignment,
    } = this.props
    const accessLevel = _.find(securables, { Securable: 'checklists' })
    const agreementType = _.find(agreementTypes, {
      AgreementType: this.state.AgreementType,
      BusinessUnit: this.state.BusinessUnit,
      Division: this.state.Division,
    })
    return (
      <Media query='(min-width: 512px) and (min-height: 512px)'>
        {matches => (
          <Dialog
            classes={{
              root: classes.root,
              paper: matches
                ? classes.paper
                : 'full-screen-dialog' /*if fullScreen*/,
            }}
            className={classes.root}
            {...checklistAssignmentDialog.props}
            open={Boolean(accessLevel) && checklistAssignmentDialog.props.open}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth='xs'
            fullScreen={!matches}
          >
            <AppBar
              position='static'
              elevation={1}
              className='dialog-header' /*if fullScreen*/
            >
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  {`${
                    ['new', 'customer'].indexOf(
                      checklistAssignmentDialog.type,
                    ) > -1
                      ? 'New'
                      : 'Edit'
                  } Assignment`}
                </Typography>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches ? 10 : 4 /*if fullScreen*/,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon' /*if fullScreen*/
              >
                <Icon>close</Icon>
              </IconButton>
            </AppBar>
            <DialogContent classes={{ root: 'p-24' }}>
              <div className='flex'>
                <TextField
                  className={classes.checklistControl}
                  label='Type'
                  select
                  id='type'
                  name='Type'
                  value={this.state.Type}
                  onChange={this.handleChange}
                  variant='outlined'
                  InputLabelProps={{ shrink: this.state.Type ? true : false }}
                  fullWidth
                >
                  <MenuItem value='SCOPE'>Work Order Scope</MenuItem>
                  <MenuItem value='TASK'>Agreement Task</MenuItem>
                </TextField>
              </div>
              <div className='flex'>
                <Autocomplete
                  className={classes.checklistControl}
                  title='Business Unit'
                  options={businessUnits}
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.BusinessUnit}>
                        {value.Description}
                      </MenuItem>
                    )
                  }}
                  portal={true}
                  value={this.state.BusinessUnit}
                  onSelect={option =>
                    this.setState({
                      ...this.state,
                      BusinessUnit: option.BusinessUnit,
                      Division: null,
                      Department: null,
                      AgreementType: null,
                      TaskType: null,
                      UnitCat: null,
                      UnitType: null,
                    })
                  }
                  required
                />
              </div>

              <div className='flex'>
                <Autocomplete
                  className={classes.checklistControl}
                  title='Division'
                  options={
                    divisions
                      ? _.filter(divisions, {
                          BusinessUnit: this.state.BusinessUnit,
                        })
                      : []
                  }
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.Division}>
                        {value.Description}
                      </MenuItem>
                    )
                  }}
                  portal={true}
                  value={this.state.Division}
                  onSelect={option =>
                    this.setState({
                      ...this.state,
                      Division: option.Division,
                      Department: null,
                      AgreementType: null,
                      TaskType: null,
                      UnitCat: null,
                      UnitType: null,
                    })
                  }
                  required
                />
              </div>

              <div className='flex'>
                <Autocomplete
                  className={classes.checklistControl}
                  title='Department'
                  options={
                    departments
                      ? _.filter(departments, {
                          BusinessUnit: this.state.BusinessUnit,
                          Division: this.state.Division,
                        })
                      : []
                  }
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.Department}>
                        {value.Description}
                      </MenuItem>
                    )
                  }}
                  portal={true}
                  value={this.state.Department}
                  onSelect={option =>
                    this.setState({
                      ...this.state,
                      Department: option.Department,
                    })
                  }
                  required={this.state.Type === 'TASK'}
                />
              </div>
              {this.state.Type === 'SCOPE' && (
                <div className='flex'>
                  <Autocomplete
                    className={classes.checklistControl}
                    title='Service'
                    options={services}
                    menuItemComponent={value => {
                      return (
                        value.ActiveYN === 'Y' && (
                          <MenuItem value={value.Service}>
                            {`${value.Service} - ${value.Description}`}
                          </MenuItem>
                        )
                      )
                    }}
                    portal={true}
                    value={this.state.Service}
                    onSelect={option =>
                      this.setState({ ...this.state, Service: option.Service })
                    }
                    // required
                  />
                </div>
              )}
              {this.state.Type === 'TASK' && (
                <React.Fragment>
                  <div className='flex'>
                    <Autocomplete
                      className={classes.checklistControl}
                      title='Agreement Type'
                      options={
                        agreementTypes
                          ? _.filter(agreementTypes, {
                              BusinessUnit: this.state.BusinessUnit,
                              Division: this.state.Division,
                            })
                          : []
                      }
                      //   options={
                      //     agreementTypes
                      //       ? _.find(agreementTypes, {
                      //           BusinessUnit: this.state.BusinessUnit,
                      //           Division: this.state.Division,
                      //         })
                      //       : []
                      //   }
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.AgreementType}>
                            {value.Name}
                          </MenuItem>
                        )
                      }}
                      portal={true}
                      value={this.state.AgreementType}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          AgreementType: option.AgreementType,
                        })
                      }
                      required
                    />
                  </div>
                  <div className='flex'>
                    <Autocomplete
                      className={classes.checklistControl}
                      title='Agreement Task'
                      options={
                        agreementType
                          ? _.filter(agreementType.Data.Tasks, o => {
                              return o.Department && this.state.Department
                                ? o.Department === this.state.Department
                                : true
                            })
                          : []
                      }
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.TaskType}>
                            {value.Name}
                          </MenuItem>
                        )
                      }}
                      portal={true}
                      value={this.state.TaskType}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          TaskType: option.TaskType,
                          UnitCat: option.UnitCat,
                          UnitType: option.UnitType,
                        })
                      }
                      required
                    />
                  </div>
                </React.Fragment>
              )}
              <div className='flex'>
                <Autocomplete
                  className={classes.checklistControl}
                  title='Service Unit Category'
                  options={
                    serviceUnitCategories && agreementType
                      ? _.filter(serviceUnitCategories, o => {
                          return (
                            _.findIndex(agreementType.Data.Tasks, t => {
                              return t.UnitCat === o.UnitCat
                            }) > -1
                          )
                        })
                      : serviceUnitCategories
                  }
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.UnitCat}>
                        {value.Description}
                      </MenuItem>
                    )
                  }}
                  portal={true}
                  value={this.state.UnitCat}
                  onSelect={option =>
                    this.setState({ ...this.state, UnitCat: option.UnitCat })
                  }
                  // required
                />
              </div>
              <div className='flex'>
                <Autocomplete
                  className={classes.checklistControl}
                  title='Service Unit Type'
                  options={
                    serviceUnitTypes
                      ? _.filter(serviceUnitTypes, {
                          UnitCat: this.state.UnitCat,
                        })
                      : []
                  }
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.UnitType}>
                        {value.Description}
                      </MenuItem>
                    )
                  }}
                  portal={true}
                  value={this.state.UnitType}
                  onSelect={option =>
                    this.setState({ ...this.state, UnitType: option.UnitType })
                  }
                  // required
                />
              </div>
            </DialogContent>
            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {['new', 'customer'].indexOf(checklistAssignmentDialog.type) >
                  -1 && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      addChecklistAssignment(rec)
                      this.closeComposeDialog()
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Add
                  </Button>
                )}
                {['new', 'customer'].indexOf(checklistAssignmentDialog.type) <
                  0 && (
                  <React.Fragment>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        var rec = this.state
                        updateChecklistAssignment(rec)
                      }}
                      disabled={!this.canBeSubmitted()}
                    >
                      Save
                    </Button>
                    <IconButton
                      onClick={() => {
                        removeChecklistAssignment(this.state)
                        this.closeComposeDialog()
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </React.Fragment>
                )}
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}
ChecklistAssignmentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditChecklistAssignmentDialog:
        Actions.closeEditChecklistAssignmentDialog,
      closeNewChecklistAssignmentDialog:
        Actions.closeNewChecklistAssignmentDialog,
      showMessage: showMessage,
      addChecklistAssignment: Actions.addChecklistAssignment,
      updateChecklistAssignment: Actions.updateChecklistAssignment,
      removeChecklistAssignment: Actions.removeChecklistAssignment,
      addChecklistAssignment: Actions.addChecklistAssignment,
      updateChecklistAssignment: Actions.updateChecklistAssignment,
      removeChecklistAssignment: Actions.removeChecklistAssignment,
      impress: impress,
    },
    dispatch,
  )
}

function mapStateToProps({
  auth,
  checklistsApp,
  spReducers,
  dispatchBoardApp,
}) {
  return {
    checklistAssignmentDialog:
      checklistsApp.checklists.checklistAssignmentDialog,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    services: spReducers.services,
    agreementTypes: spReducers.agreementTypes,
    serviceUnitCategories: spReducers.serviceUnitCategories,
    serviceUnitTypes: spReducers.serviceUnitTypes,
    checklists: spReducers.checklists,
    user: spReducers.userProfiles.User.UserName,
    Co: spReducers.companies.Co,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(ChecklistAssignmentDialog),
)
