import React, { Component } from 'react';
import { Divider, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { FuseAnimate, FuseUtils } from '@fuse';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReportDialog from './ReportDialog';
import ReportCard from './ReportCard';
import classNames from 'classnames';
import * as Actions from './store/actions';
import _ from '@lodash';

const styles = theme => ({
    root: {

    },
});

const initialState = {
    reports: [],
    editReport: false,
    reportOpen: false,
    report: null,
}

class Reports extends Component {
    state = {
        ...initialState,
    }
    componentDidMount() {
        const { reports, categories } = this.props;
        this.setState({ ...this.state, reports, categories });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { reports, categories } = this.props;
        if (!_.isEqual(reports, prevProps.reports) || !_.isEqual(categories, prevProps.categories)) {
            this.setState({ ...this.state, reports, categories });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    addReport = () => {
        const { reportOpen } = this.state;
        this.setState({ ...this.state, reportOpen: !reportOpen })
    }

    render() {
        const { classes, setSearchText, searchText, pageLayout, reportCategory, sidebar } = this.props;
        const { reports, categories, editReport, reportOpen } = this.state;
        const category = reportCategory;
        const data = category && category.Data ? this.getFilteredArray(category.Data.Reports, searchText) : [];
        return (
            <FuseAnimate animation="transition.slideRightBigIn" delay={300}>
                <div>
                    <ReportDialog category={reportCategory} edit={editReport} component={this.state.report && { ...this.state.report }} open={reportOpen} onClose={() => this.setState({ ...this.state, reportOpen: false, report: null })} />
                    <Typography variant="h6" style={{ alignItems: 'center' }} className={classNames("flex truncate text-16 sm:text-20 mb-6 sm:mb-12", sidebar && "p-12 pb-0")}><Icon color="primary" className="text-32 mr-8 cursor-pointer" onClick={() => { if (!sidebar) { this.props.setCategory(null) } }}>{sidebar ? 'format_list_numbered' : 'keyboard_arrow_left'}</Icon>{category ? category.Name : ''} Reports</Typography>
                    <Divider />
                    <div className="flex flex-wrap">
                        {
                            [...data].map((value, index) => {
                                return (
                                    <ReportCard fullWidth={Boolean(sidebar)} onEdit={() => this.setState({ ...this.state, report: { ...value }, reportOpen: true })} onClick={() => this.props.setReport({ ...value })} onDelete={() => this.props.removeReport(value)} value={{ ...value }} key={index} category={{ ...reportCategory }} />
                                )
                            })
                        }
                        <Typography onClick={this.addReport} variant="subtitle2" style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer"><Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>New Report</Typography>
                    </div>
                </div>
            </FuseAnimate>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText,
        setCategories: Actions.setCategories,
        setCategory: Actions.setCategory,
        removeReport: Actions.removeReport,
        setReport: Actions.setReport,
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        searchText: reportingApp.reporting.searchText,
        reports: reportingApp.reporting.reports,
        categories: spReducers.reporting.categories,
        reportCategory: reportingApp.reporting.reportCategory
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Reports));
