import axios from 'axios/index';

export const SET_UOM_DATA = '[SPCONNECTION] SET UOM DATA';
export const GET_UOM_DATA = '[SPCONNECTION] GET UOM DATA';
export const SUBSCRIBE_UOM_LIST = '[SPCONNECTION] SUBSCRIBE UOM LIST';

export function getInitialUOMData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/UOM`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getUOMData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/UOM`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_UOM_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_UOM_LIST,
            Co
        }));
    }
}

export function setUOMData(data) {
    return {
        type: SET_UOM_DATA,
        payload: data
    };
}