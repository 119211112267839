import { Geolocation } from '@capacitor/geolocation'
import { FuseAnimate, FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MobileStepper,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'
import FileList from 'main/content/apps/file-manager/FileList'
import Comments from 'main/content/components/comments/Comments'
import { SPRoundIcon } from 'main/icons'
import moment from 'moment'
import React, { Component } from 'react'
import Geocode from 'react-geocode'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import ServiceUnitsList from '../service-units/ServiceUnitsList'
import {
  openEditServiceUnitDialog,
  openNewServiceUnitDialog,
} from '../service-units/store/actions'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import { openEditWorkOrderDialog } from '../work-orders/store/actions'
import { openPreloadAgreementDialog } from './store/actions/agreements.actions'
import AgreementAmortizationsList from './AgreementAmortizationsList'
import AgreementBillingsList from './AgreementBillingsList'
import AgreementFormDialog from './AgreementFormDialog'
import AgreementsList from './AgreementsList'
import CustomerSearchBar from './CustomerSearchBar'
import CustomerSiteSearchBar from './CustomerSiteSearchBar'
import Recurrence from './Recurrence'
import * as Actions from './store/actions'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import getProfileImage from '../../../functions/getProfileImageUrl'
import { FeatureFlagsContext } from '../../../../providers/featureFlagsProvider'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Marker({ text, icon, style, badge, heading, type }) {
  const rotate = heading
    ? heading <= 180
      ? `rotate(${heading - 90}deg)`
      : `rotate(${heading + 90}deg)`
    : 'none'
  return (
    <Tooltip title={text} placement='top'>
      <div className={type === 'person' ? 'person-marker' : ''}>
        <img style={{ ...style }} src={icon} />
      </div>
    </Tooltip>
  )
}

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        marginBottom: props.marginBottom,
        padding: props.padding ? props.padding : 8 * 3,
        paddingTop: props.paddingTop
          ? props.paddingTop
          : props.padding
            ? props.padding
            : 8 * 3,
        maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

const styles = theme => ({
  root: {},
  formControl: {
    marginBottom: 12,
    // zIndex: 9999,
  },
  scrollableList: {
    maxHeight: 100,
    overflow: 'auto',
  },
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
  updateMaterial: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteMaterial: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
  taskCard: {
    marginBottom: 12,
  },
  taskCardHeader: {
    borderBottom: '1px solid lightgrey',
  },
  taskCardUnit: {
    borderBottom: '1px dotted lightgrey',
    paddingLeft: 48,
  },
  unitAvatar: {
    width: 32,
    height: 32,
  },
  taskBadge: {
    marginRight: 16,
    marginTop: 16,
  },
  taskBadgeSimple: {
    top: 14,
    right: 14,
    fontSize: 11,
    height: 16,
    minWidth: 16,
    padding: 4,
    zIndex: 'unset',
  },
  expand: {
    marginLeft: 'auto',
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  yellow: {
    backgroundColor: '#f4b636',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
  },
  grey: {
    backgroundColor: '#5f5f5f',
    color: '#fff',
  },
  redText: {
    color: '#f44336',
    fontSize: 36,
  },
  greenText: {
    color: '#d1e751',
    fontSize: 36,
  },
  blueText: {
    color: '#4dbce9',
    fontSize: 36,
  },
  yellowText: {
    color: '#f4b636',
    fontSize: 36,
  },
  blackText: {
    color: '#3f3f3f',
    fontSize: 36,
  },
  greyText: {
    color: '#5f5f5f',
    fontSize: 36,
  },
  simpleBadge: {
    top: 9,
    right: -12,
    height: 16,
    fontSize: 10,
    minWidth: 16,
    padding: 0,
  },
  frequency: {
    borderBottom: '1px solid lightgrey',
    backgroundColor: '#f1f1f1',
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  taskSiteUnit: {
    backgroundColor: '#f9f9f9',
    borderLeft: '1px dotted lightgrey',
  },
  blackButton: {
    background: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#333',
    },
    '&:focus': {
      background: '#4f4f4f',
    },
  },
})
const newAgreementState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  AgreementType: null,
  Customer: null,
  Site: null,
  Agreement: null,
  Cycle: 1,
  Revision: 0,
  SoldBy: null,
  StartDate: null,
  EndDate: null,
  TotalPrice: 0,
  ActiveYN: 'Y',
  Notes: null,
  WorkOrder: null,
  Scope: null,
  AttachmentID: null,
  SignedBy: null,
  Signature: null,
  BillingFrequency: null,
  MultiSiteYN: null,
  AccountManager: null,
  Data: {
    AgreementType: null,
    Customer: null,
    Site: null,
    Sites: [],
    BillingSchedule: [],
    Billings: [],
    Tasks: [],
    Variations: [],
    Attachments: [],
    Schedule: [],
    Comments: [],
    ErrMsg: null,
  },
  renewalWorkOrder: null,
  renewalScope: null,
  Address: false,
  value: 0,
  needsRevision: false,
  newCycle: false,
  renewRan: false,
  taskMenu: null,
  taskScheduleMenu: null,
  taskEditMenu: null,
  taskInfo: null,
  taskIncluded: null,
  taskSite: null,
  taskWorkOrder: '+',
  taskScope: null,
  taskDueBy: new Date(),
  taskTechnician: null,
  taskArrivalWindow: null,
  taskSeq: null,
  advanced: false,
  showTabs: false,
  showTasks: false,
  showUnits: false,
  simpleAction: null,
  simpleStep: 0,
  annualBillings: null,
  semiAnnualBillings: {},
  quarterlyBillings: {},
  biMonthlyBillings: {},
  adding: false,
  TermLength: 1,
  EstimateNumber: null,
  Component: null,
  anchorUnit: null,
  anchorUnitEl: null,
  anchorUnitTask: null,
  allowScopeYN: 'N',
  FilteredScope: [],
  exclusions: {},
  currentTask: null,
  inclusions: {},
  workOrderTasks: [],
  sourceWO: null,
  dialogHeight: null,
  minHeight: null,
  location: null,
  reviseNoRecurrences: false,
  PONumber: null,
  scopeDialog: false,
  hasFocus: false,
}

class AgreementDialog extends Component {
  static contextType = FeatureFlagsContext

  state = { ..._.cloneDeepWith(newAgreementState) }

  ref = React.createRef()

  componentDidMount() {
    const { gps } = this.props
    if (
      !gps ||
      !gps.location ||
      !gps.location.coords ||
      !gps.location.coords.latitude ||
      !gps.location.coords.longitude
    ) {
      var geoOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      // console.log('Fetching GPS Location - Agreements');
      Geolocation.getCurrentPosition(geoOptions)
        .then(pos => {
          var crd = pos.coords

          var Latitude = crd.latitude
          var Longitude = crd.longitude
          this.setState({ ...this.state, userGPS: { Latitude, Longitude } })
        })
        .catch(err => {
          // window["warn"](`ERROR(${err.code}): ${err.message}`);
        })
    } else {
      var Latitude = gps.location.coords.latitude
      var Longitude = gps.location.coords.longitude
      this.setState({ ...this.state, userGPS: { Latitude, Longitude } })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */

    const { Co } = this.props
    newAgreementState.Co = this.props.Co
    const initialState = _.cloneDeepWith(newAgreementState)
    const { data } = this.props.agreementDialog
    const workOrderData = this.props.selectedWOData
      ? this.props.selectedWOData
      : this.getFilteredArray(
          this.props.workOrderData || [],
          this.props.searchText,
        )
    const foundRecord = workOrderData.find(
      item => item.WorkOrder === data.WorkOrder,
    )

    if (
      !prevProps.agreementDialog.props.open &&
      this.props.agreementDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.agreementDialog.type === 'edit' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        const { AgreementType } = data
        const type = _.find(this.props.agreementTypes, { AgreementType })
        data.taskWorkOrder = data.sourceWO || '+'
        data.Data.AgreementType = _.cloneDeep(type)
        data.Address = data.Data.Site
          ? this.buildAddress(
              data.Data.Site.Address1,
              data.Data.Site.City,
              data.Data.Site.State,
              data.Data.Site.Zip,
            )
          : null
        if (
          data.Data.Site &&
          (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
          window.google
        ) {
          Geocode.fromAddress(
            `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                ...initialState,
                ...data,
                Data: {
                  ...data.Data,
                  Site: { ...data.Data.Site, Latitude: lat, Longitude: lng },
                },
              })
            },
            error => {
              this.setState({ ...initialState, ...data })
            },
          )
        } else {
          this.setState({ ...initialState, ...data })
        }
      }
      if (
        this.props.agreementDialog.type === 'renew' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        const { AgreementType } = data
        const type = _.find(this.props.agreementTypes, { AgreementType })
        data.Data.AgreementType = _.cloneDeep(type)
        data.Address = data.Data.Site
          ? this.buildAddress(
              data.Data.Site.Address1,
              data.Data.Site.City,
              data.Data.Site.State,
              data.Data.Site.Zip,
            )
          : null
        this.props.agreementDialog.type = 'edit'
        if (
          data.Data.Site &&
          (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
          window.google
        ) {
          Geocode.fromAddress(
            `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState(
                {
                  ...initialState,
                  ...data,
                  Data: {
                    ...data.Data,
                    Site: { ...data.Data.Site, Latitude: lat, Longitude: lng },
                  },
                  simpleAction: 'renew',
                  simpleStep: 1,
                },
                this.newCycle,
              )
            },
            error => {
              this.setState(
                {
                  ...initialState,
                  ...data,
                  simpleAction: 'renew',
                  simpleStep: 1,
                },
                this.newCycle,
              )
            },
          )
        } else {
          this.setState(
            { ...initialState, ...data, simpleAction: 'renew', simpleStep: 1 },
            this.newCycle,
          )
        }
      }
      if (
        this.props.agreementDialog.type === 'schedule' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        const { AgreementType } = data
        const type = _.find(this.props.agreementTypes, { AgreementType })
        data.Data.AgreementType = _.cloneDeep(type)
        data.Address = data.Data.Site
          ? this.buildAddress(
              data.Data.Site.Address1,
              data.Data.Site.City,
              data.Data.Site.State,
              data.Data.Site.Zip,
            )
          : null
        this.props.agreementDialog.type = 'edit'
        if (
          data.Data.Site &&
          (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
          window.google
        ) {
          Geocode.fromAddress(
            `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                ...initialState,
                ...data,
                Data: {
                  ...data.Data,
                  Site: { ...data.Data.Site, Latitude: lat, Longitude: lng },
                },
                simpleAction: 'tasks',
                simpleStep: 3,
              })
            },
            error => {
              this.setState({
                ...initialState,
                ...data,
                simpleAction: 'tasks',
                simpleStep: 3,
              })
            },
          )
        } else {
          this.setState({
            ...initialState,
            ...data,
            simpleAction: 'tasks',
            simpleStep: 3,
          })
        }
      }
      if (
        this.props.agreementDialog.type === 'invoice' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        const { AgreementType } = data
        const type = _.find(this.props.agreementTypes, { AgreementType })
        data.Data.AgreementType = _.cloneDeep(type)
        data.Address = data.Data.Site
          ? this.buildAddress(
              data.Data.Site.Address1,
              data.Data.Site.City,
              data.Data.Site.State,
              data.Data.Site.Zip,
            )
          : null
        this.props.agreementDialog.type = 'edit'
        if (
          data.Data.Site &&
          (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
          window.google
        ) {
          Geocode.fromAddress(
            `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                ...initialState,
                ...data,
                Data: {
                  ...data.Data,
                  Site: { ...data.Data.Site, Latitude: lat, Longitude: lng },
                },
                value: 1,
              })
            },
            error => {
              this.setState({ ...initialState, ...data, value: 1 })
            },
          )
        } else {
          this.setState({ ...initialState, ...data, value: 1 })
        }
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.agreementDialog.type === 'new' &&
        !_.isEqual(initialState, prevState)
      ) {
        const { data } = this.props.agreementDialog
        if (data && data.Data && data.Data.Site && data.Data.Site.Address1) {
          data.Address = this.buildAddress(
            data.Data.Site.Address1,
            data.Data.Site.City,
            data.Data.Site.State,
            data.Data.Site.Zip,
          )
          if (
            (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
            window.google
          ) {
            Geocode.fromAddress(
              `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
            ).then(
              response => {
                const { lat, lng } =
                  response.results.length !== 0
                    ? response.results[0].geometry.location
                    : { lat: 0, lng: 0 }
                this.setState({
                  ...initialState,
                  ...data,
                  Data: {
                    ...data.Data,
                    Site: { ...data.Data.Site, Latitude: lat, Longitude: lng },
                  },
                  Co,
                  FilteredScope: foundRecord.Data.Scopes,
                  allowScopeYN: 'Y',
                })
              },
              error => {
                this.setState({ ...initialState, ...data, Co })
              },
            )
          } else {
            this.setState({ ...initialState, ...data, Co })
          }
        }
        this.setState({ ...initialState, ...data, Co })
      }
    } else {
      if (
        this.props.agreementDialog.props.open &&
        this.props.agreementDialog.type === 'update' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        if (data.Data && data.Data.Site) {
          data.Address = this.buildAddress(
            data.Data.Site.Address1,
            data.Data.Site.City,
            data.Data.Site.State,
            data.Data.Site.Zip,
          )
        }
        this.props.agreementDialog.type = 'edit'
        this.setState(
          {
            ...this.state,
            ...data,
            BusinessUnit: this.state.BusinessUnit,
            Division: this.state.Division,
            AgreementType: this.state.AgreementType,
            MultiSiteYN: this.state.MultiSiteYN,
            Data: { ...this.state.Data, ...data.Data },
          },
          () => this.handleTypeChange(),
        )
      }
      if (
        this.props.agreementDialog.props.open &&
        this.props.agreementDialog.type === 'revise' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        if (data.Data && data.Data.Site) {
          data.Address = this.buildAddress(
            data.Data.Site.Address1,
            data.Data.Site.City,
            data.Data.Site.State,
            data.Data.Site.Zip,
          )
        }
        // window["warn"]("updateEditAgreementDialog2 data 111 revise", data)
        this.props.agreementDialog.type = 'edit'
        this.setState({
          ...this.state,
          ...data,
          BusinessUnit: this.state.BusinessUnit,
          Division: this.state.Division,
          AgreementType: this.state.AgreementType,
          MultiSiteYN: this.state.MultiSiteYN,
          Data: { ...this.state.Data, ...data.Data },
        })
      }
      if (
        this.props.agreementDialog.props.open &&
        this.props.agreementDialog.type === 'schedule' &&
        this.props.agreementDialog.data &&
        !_.isEqual(this.props.agreementDialog.data, prevState)
      ) {
        const { data } = this.props.agreementDialog
        if (data.Data && data.Data.Site) {
          data.Address = this.buildAddress(
            data.Data.Site.Address1,
            data.Data.Site.City,
            data.Data.Site.State,
            data.Data.Site.Zip,
          )
        }
        this.props.agreementDialog.type = 'edit'
        this.setState({
          ...this.state,
          ...data,
          BusinessUnit: this.state.BusinessUnit,
          Division: this.state.Division,
          AgreementType: this.state.AgreementType,
          MultiSiteYN: this.state.MultiSiteYN,
          Data: { ...this.state.Data, ...data.Data },
        })
      }
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
      this.handleTechLabor,
    )
  }

  closeComposeDialog = () => {
    this.props.agreementDialog.type === 'edit'
      ? this.props.closeEditAgreementDialog()
      : this.props.closeNewAgreementDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  canBeSubmitted = () => {
    const {
      BusinessUnit,
      Division,
      AgreementType,
      Customer,
      Site,
      Agreement,
      Cycle,
      Revision,
      StartDate,
      WorkOrder,
      Scope,
      MultiSiteYN,
      Data,
    } = this.state
    const { Sites } = Data
    return (
      BusinessUnit &&
      Division &&
      AgreementType &&
      Customer &&
      (MultiSiteYN === 'Y' ? Sites && Sites.length > 0 : Site) &&
      Agreement &&
      Cycle !== null &&
      Revision !== null &&
      StartDate
    )
  }

  hasAddress() {
    const { Address } = this.state
    return Address
  }

  newCycle = () => {
    if (!this.state.newCycle) {
      let { renewalWorkOrder, renewalScope, StartDate, EndDate } = this.state
      const { Variations, AgreementType } = this.state.Data
      const all = [...Variations, ...[this.state]]
      let cycle = _.maxBy(all, 'Cycle')
      let active = _.find(all, { Cycle: cycle.Cycle, ActiveYN: 'Y' })
      // window["warn"](cycle, active, all)
      if (active && active.EndDate) {
        let startDate = new Date(active.EndDate)
        startDate.setDate(startDate.getDate() + 1)
        if (startDate < new Date()) {
          startDate = new Date()
        }
        StartDate = startDate.toLocaleDateString('en-US')
      }
      if (this.state.TermLength || AgreementType.TermLength) {
        let endDate = new Date(StartDate)
        endDate.setFullYear(
          endDate.getFullYear() +
            (this.state.TermLength || AgreementType.TermLength),
        )
        endDate.setDate(endDate.getDate() - 1)
        EndDate = endDate.toLocaleDateString('en-US')
      } else {
        let endDate = new Date(StartDate)
        endDate.setFullYear(endDate.getFullYear() + 1)
        endDate.setDate(endDate.getDate() - 1)
        EndDate = endDate.toLocaleDateString('en-US')
      }

      const agreementType = AgreementType
      let sched =
        agreementType && agreementType.BillingFrequency === '*'
          ? this.state.BillingFrequency || ''
          : (agreementType || {}).BillingFrequency || ''

      // window["warn"]("newcycle ran", typeof sched, sched, agreementType, this.state.Data.Tasks, this.state.BillingFrequency)

      this.setState(
        {
          ...this.state,
          Cycle: cycle.Cycle + 1,
          Revision: 0,
          StartDate,
          EndDate,
          WorkOrder: renewalWorkOrder,
          Scope: renewalScope,
          Signature: null,
          SignedBy: null,
          newCycle: true,
          renewRan: true,
          simpleStep: 0,
        },
        this.handleTypeChange,
      )
    }
  }

  handleTermLengthChange = event => {
    const TermLength = event.target.value
    this.setState({ ...this.state, TermLength }, () => {
      if (TermLength && !isNaN(TermLength) && TermLength > 0) {
        this.handleTypeChange()
      }
    })
  }

  filterUnits = (unitsToBeFiltered, unitCat, unitType, unitTypes) => {
    const useNewMultiUnitTypeSelect =
      this.context['spp-507-multi-unit-type-select']

    if (useNewMultiUnitTypeSelect) {
      return _.filter(unitsToBeFiltered, o => {
        return (
          o.UnitCat === unitCat &&
          _.findIndex(
            unitTypes,
            agreementTypeTaskUnitType =>
              agreementTypeTaskUnitType.UnitType.UnitType === o.UnitType,
          ) >= 0
        )
      })
    } else {
      return _.filter(unitsToBeFiltered, o => {
        return (
          o.UnitCat === unitCat &&
          o.UnitType === (unitType ? unitType : o.UnitType)
        )
      })
    }
  }

  updateTaskPrices = (
    event,
    Task,
    oldTaskTF,
    TaskType,
    frequency,
    Agreement,
  ) => {
    const { agreementTypes } = this.props
    let {
      Co,
      BusinessUnit,
      Division,
      Data,
      StartDate,
      Cycle,
      Revision,
      MultiSiteYN,
    } = this.state

    const AgreementType = event ? event.target.value : this.state.AgreementType
    let type = _.find(agreementTypes, { BusinessUnit, Division, AgreementType })
    if (type) {
      type = _.cloneDeep(type)
    }

    //const { TaskType } = Task;
    const task = {
      Co,
      Customer: this.state.Customer,
      Site: this.state.Site,
      Agreement,
      Cycle,
      Revision,
      TaskType: TaskType,
      Revenue: 0.0,
      MiscRevenue: 0.0,
      Data: {
        Units: [],
        Recurrences: [],
        Schedule: [],
      },
      Frequency: frequency,
    }

    const { Schedule, Site, Tasks } = Data
    const Included = _.find(Tasks, { TaskType: task.TaskType })

    const Scheduled = Included && Included.Data ? Included.Data.Schedule : []

    task.Data.Schedule = Scheduled

    let oldTask = null

    if (oldTaskTF) {
      oldTask = _.find(Data.Tasks, { TaskType })
      if (oldTask) {
        // window["warn"]("agreement tasks123 oldTask", oldTask, this.state.renewRan)
        task.MiscRevenue = Number(oldTask.MiscRevenue)
      }
    }

    let unitsOG = []

    let newExclusions = { ...this.state.exclusions }
    let newInclusions = { ...this.state.inclusions }

    if (Task.ActiveYN !== 'N') {
      //Task.OptionalYN !== 'Y' &&
      if (Task.UnitCat) {
        const { Customer, Site } = Data
        if (Site) {
          const { Units } = Site.Data
          const units = this.filterUnits(
            Units,
            Task.UnitCat,
            Task.UnitType,
            Task.UnitTypes,
          )
          units.forEach(unit => {
            // window["warn"]("Task.UnitCat", Task.UnitCat, unit)
            const { UnitID, UnitType, ActiveYN, ExcludeYN } = unit
            if (
              ActiveYN !== 'N' &&
              (!this.state.exclusions[unit.UnitID] ||
                (this.state.exclusions[unit.UnitID] !== '*' &&
                  !this.state.exclusions[unit.UnitID][Task.TaskType]))
            ) {
              task.Data.Units.push({
                Co,
                Customer: Site.Customer,
                Site: Site.Site,
                Agreement,
                Cycle,
                Revision,
                TaskType,
                UnitID,
                UnitType,
                Revenue: Task.Revenue,
              })
            }
            //if (ActiveYN !== 'N'){
            unitsOG.push({
              Co,
              Customer: Site.Customer,
              Site: Site.Site,
              Agreement,
              Cycle,
              Revision,
              TaskType,
              UnitID,
              UnitType,
              Revenue: Task.Revenue,
              ActiveYN: ActiveYN,
            })
            //}
          })
        }
      } else {
        if (this.state.renewRan === true) {
          Task.RevenueType = 'U'
          const units = Task.Data.Units
          units.forEach(unit => {
            // window["warn"]("Task.UnitCat 2", Task.UnitCat, unit)
            const { UnitID, UnitType, ActiveYN, ExcludeYN } = unit
            if (
              ActiveYN !== 'N' &&
              (!this.state.exclusions[unit.UnitID] ||
                (this.state.exclusions[unit.UnitID] !== '*' &&
                  !this.state.exclusions[unit.UnitID][Task.TaskType]))
            ) {
              task.Data.Units.push({
                Co,
                Customer: Site.Customer,
                Site: Site.Site,
                Agreement,
                Cycle,
                Revision,
                TaskType,
                UnitID,
                UnitType,
                Revenue: Task.Revenue,
              })
            }
            //if (ActiveYN !== 'N'){
            unitsOG.push({
              Co,
              Customer: Site.Customer,
              Site: Site.Site,
              Agreement,
              Cycle,
              Revision,
              TaskType,
              UnitID,
              UnitType,
              Revenue: Task.Revenue,
              ActiveYN: ActiveYN,
            })
            //}
          })
        }
      }
      if (
        (Task.RevenueType === 'T' || task.Data.Units.length < 1) &&
        Task.ActiveYN !== 'N'
      ) {
        //task.Revenue += Task.Revenue;
        //task.Revenue = Number(Included.Revenue);
        if (Included && Included.Revenue) {
          task.Revenue = Number(Included.Revenue)
        } else {
          task.Revenue += Task.Revenue
        }
      }
      // let newExclusions = {...this.state.exclusions}
      let subtractOuter = false
      let addOuter = false
      let subtractPrice = null
      let starExclusions = null
      if (
        Task.RevenueType === 'U' &&
        task.Data.Units.length > 0 &&
        Task.ActiveYN !== 'N'
      ) {
        for (var u = 0; u < unitsOG.length; u++) {
          // window["warn"]("units78963", task.TaskType, unitsOG[u], unitsOG.length, task.Revenue, Task.Frequency)
          let subtract = false
          let add = false
          if (this.state.exclusions[unitsOG[u].UnitID]) {
            if (
              this.state.exclusions[unitsOG[u].UnitID][task.TaskType] &&
              this.state.exclusions[unitsOG[u].UnitID][task.TaskType] === 'N'
            ) {
              //window["warn"]("agreement exclusions", this.state.exclusions, this.state.exclusions[unitsOG[u].UnitID], task.TaskType, a[task.TaskType])
              subtract = true
              subtractOuter = true
              newExclusions[unitsOG[u].UnitID][task.TaskType] = 'Y'
              unitsOG[u].excluded = true
            }
            // else if(this.state.exclusions[unitsOG[u].UnitID] === "*"){
            //     subtract = true
            //     subtractOuter = true;
            //     //newExclusions[unitsOG[u].UnitID] = "N"
            //     //newExclusions[unitsOG[u].UnitID][task.TaskType] = "Y";
            //     starExclusions = unitsOG[u].UnitID
            // }
          }

          // window["warn"]("newInclusions", newInclusions[unitsOG[u].UnitID], unitsOG[u].UnitID, this.state.inclusions)

          if (this.state.inclusions[unitsOG[u].UnitID]) {
            if (
              this.state.inclusions[unitsOG[u].UnitID][task.TaskType] &&
              this.state.inclusions[unitsOG[u].UnitID][task.TaskType] === 'Y'
            ) {
              //window["warn"]("agreement exclusions", this.state.exclusions, this.state.exclusions[unitsOG[u].UnitID], task.TaskType, a[task.TaskType])
              subtract = false
              subtractOuter = false
              add = true
              addOuter = true
              delete newInclusions[unitsOG[u].UnitID][task.TaskType]
            }
            // else if(this.state.inclusions[unitsOG[u].UnitID] === "*"){
            //     subtract = false
            //     subtractOuter = false;
            //     add = true;
            //     addOuter = true;
            //     //window["warn"]("newInclusions", newInclusions)
            //     delete newInclusions[unitsOG[u].UnitID];
            // }
          }

          // window["warn"]("newInclusions 2", newInclusions)

          if (unitsOG[u].ActiveYN === 'N') {
            subtract = true
            subtractOuter = true
          }

          const unit = unitsOG[u]

          const override = _.find(Task.Data.Overrides, {
            UnitType: unit.UnitType,
          })
          let overrideValue = null
          if (override) {
            let allOverides = []
            for (var n = 0; n < task.Data.Units.length; n++) {
              const unit = task.Data.Units[n]
              const override = _.find(Task.Data.Overrides, {
                UnitType: unit.UnitType,
              })
              if (override) {
                allOverides.push(override)
              }
            }

            let sortedOverrides = _.sortBy(allOverides, [
              function (o) {
                return o.Revenue
              },
            ])

            let highestRev = sortedOverrides[sortedOverrides.length - 1]

            // window["warn"]("sortedOverrides ABC", highestRev, unit, task)

            overrideValue = highestRev
              ? highestRev.UnitType === unit.UnitType
                ? override.Revenue
                : override.Additional
              : override.Revenue
            unitsOG[u].Revenue = overrideValue
          }

          // window["warn"]("override value", task.TaskType, overrideValue, Included, this.state.renewRan, Task.Frequency)

          const additionalPrice = Task.Additional
          let useAdditional = false
          if (additionalPrice) {
            let unitsCopy = [...unitsOG]
            let activeUnits = _.remove(unitsCopy, o => {
              return o.ActiveYN === 'N' || o.excluded === true
            })
            let sortedUnits = _.sortBy(unitsCopy, [
              function (o) {
                return o.Revenue
              },
            ])
            //let sortedUnits = _.sortBy(task.Data.Units, [function (o) { return o.Revenue; }])
            let highestRev = sortedUnits[sortedUnits.length - 1]
            // window["warn"]("useAdditional highestRev", highestRev, sortedUnits, unitsCopy)

            if (task.Data.Units.length < 2) {
              useAdditional = highestRev.UnitID === unit.UnitID ? false : true
            } else {
              useAdditional =
                highestRev.UnitID === unit.UnitID
                  ? add === true
                    ? true
                    : false
                  : true
            }
          }

          // window["warn"]("agreement exclusions", this.state.exclusions, this.state.inclusions, useAdditional)

          // let subtractPrice = null;

          switch (Task.Frequency) {
            case 'S':
              {
                if (Included) {
                  if (
                    this.state.currentTask &&
                    Included.TaskType === this.state.currentTask.TaskType
                  ) {
                    if (override) {
                      task.Revenue += 2 * overrideValue
                    } else {
                      task.Revenue += useAdditional
                        ? 2 * overrideValue
                        : 2 * Number(Included.Revenue)
                    }
                  } else {
                    if (this.state.renewRan === true) {
                      if (override) {
                        task.Revenue += 2 * overrideValue
                      } else {
                        task.Revenue += useAdditional
                          ? 2 * Task.Additional
                          : 2 * Task.Revenue
                      }
                    } else {
                      if (override) {
                        task.Revenue = overrideValue
                      } else {
                        task.Revenue = Included.Revenue
                      }
                    }
                  }

                  if (subtract === true) {
                    if (override) {
                      subtractPrice = 2 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 2 * Task.Additional
                        : 2 * Task.Revenue
                    }
                  }

                  if (add === true) {
                    if (override) {
                      subtractPrice = 2 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 2 * Task.Additional
                        : 2 * Task.Revenue
                    }
                  }
                } else {
                  if (override) {
                    task.Revenue += 2 * overrideValue
                  } else {
                    task.Revenue += useAdditional
                      ? 2 * Task.Additional
                      : 2 * Task.Revenue
                  }
                }
              }
              break
            case 'Q':
              {
                if (Included) {
                  if (
                    this.state.currentTask &&
                    Included.TaskType === this.state.currentTask.TaskType
                  ) {
                    if (override) {
                      task.Revenue += 4 * overrideValue
                    } else {
                      task.Revenue += useAdditional
                        ? 4 * overrideValue
                        : 4 * Number(Included.Revenue)
                    }
                  } else {
                    if (this.state.renewRan === true) {
                      if (override) {
                        task.Revenue += 4 * overrideValue
                      } else {
                        task.Revenue += useAdditional
                          ? 4 * Task.Additional
                          : 4 * Task.Revenue
                      }
                    } else {
                      if (override) {
                        task.Revenue = overrideValue
                      } else {
                        task.Revenue = Included.Revenue
                      }
                    }
                  }

                  if (subtract === true) {
                    if (override) {
                      subtractPrice = 4 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 4 * Task.Additional
                        : 4 * Task.Revenue
                    }
                  }

                  if (add === true) {
                    if (override) {
                      subtractPrice = 4 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 4 * Task.Additional
                        : 4 * Task.Revenue
                    }
                  }
                } else {
                  if (override) {
                    task.Revenue += 4 * overrideValue
                  } else {
                    task.Revenue += useAdditional
                      ? 4 * Task.Additional
                      : 4 * Task.Revenue
                  }
                }
              }
              break
            case 'B':
              {
                if (Included) {
                  if (
                    this.state.currentTask &&
                    Included.TaskType === this.state.currentTask.TaskType
                  ) {
                    if (override) {
                      task.Revenue += 6 * overrideValue
                    } else {
                      task.Revenue += useAdditional
                        ? 6 * overrideValue
                        : 6 * Number(Included.Revenue)
                    }
                  } else {
                    if (this.state.renewRan === true) {
                      if (override) {
                        task.Revenue += 6 * overrideValue
                      } else {
                        task.Revenue += useAdditional
                          ? 6 * Task.Additional
                          : 6 * Task.Revenue
                      }
                    } else {
                      if (override) {
                        task.Revenue = overrideValue
                      } else {
                        task.Revenue = Included.Revenue
                      }
                    }
                  }

                  if (subtract === true) {
                    if (override) {
                      subtractPrice = 6 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 6 * Task.Additional
                        : 6 * Task.Revenue
                    }
                  }

                  if (add === true) {
                    if (override) {
                      subtractPrice = 6 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 6 * Task.Additional
                        : 6 * Task.Revenue
                    }
                  }
                } else {
                  if (override) {
                    task.Revenue += 6 * overrideValue
                  } else {
                    task.Revenue += useAdditional
                      ? 6 * Task.Additional
                      : 6 * Task.Revenue
                  }
                }
              }
              break
            case 'M':
              {
                if (Included) {
                  if (
                    this.state.currentTask &&
                    Included.TaskType === this.state.currentTask.TaskType
                  ) {
                    if (override) {
                      task.Revenue += 12 * overrideValue
                    } else {
                      task.Revenue += useAdditional
                        ? 12 * overrideValue
                        : 12 * Number(Included.Revenue)
                    }
                  } else {
                    if (this.state.renewRan === true) {
                      if (override) {
                        task.Revenue += 12 * overrideValue
                      } else {
                        task.Revenue += useAdditional
                          ? 12 * Task.Additional
                          : 12 * Task.Revenue
                      }
                    } else {
                      if (override) {
                        task.Revenue = overrideValue
                      } else {
                        task.Revenue = Included.Revenue
                      }
                    }
                  }

                  if (subtract === true) {
                    if (override) {
                      subtractPrice = 12 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 12 * Task.Additional
                        : 12 * Task.Revenue
                    }
                  }

                  if (add === true) {
                    if (override) {
                      subtractPrice = 12 * overrideValue
                    } else {
                      subtractPrice = useAdditional
                        ? 12 * Task.Additional
                        : 12 * Task.Revenue
                    }
                  }
                } else {
                  if (override) {
                    task.Revenue += 12 * overrideValue
                  } else {
                    task.Revenue += useAdditional
                      ? 12 * Task.Additional
                      : 12 * Task.Revenue
                  }
                }
              }
              break
            default: {
              // window["warn"]("override value default", task.TaskType, unit, overrideValue, task.Revenue, addOuter, useAdditional)
              if (Included) {
                if (
                  this.state.currentTask &&
                  Included.TaskType === this.state.currentTask.TaskType
                ) {
                  if (override) {
                    task.Revenue += overrideValue
                  } else {
                    task.Revenue += useAdditional
                      ? Task.Additional
                      : Number(Included.Revenue)
                  }
                } else {
                  if (this.state.renewRan === true) {
                    if (override) {
                      task.Revenue += overrideValue
                    } else {
                      task.Revenue += useAdditional
                        ? Task.Additional
                        : Number(Task.Revenue)
                    }
                  } else {
                    if (override) {
                      task.Revenue = overrideValue
                    } else {
                      task.Revenue = Included.Revenue
                    }
                  }
                }

                if (subtract === true) {
                  if (override) {
                    subtractPrice = overrideValue
                  } else {
                    subtractPrice = useAdditional
                      ? Task.Additional
                      : Number(Task.Revenue)
                  }
                }

                if (add === true) {
                  if (override) {
                    subtractPrice = overrideValue
                  } else {
                    subtractPrice = useAdditional
                      ? Task.Additional
                      : Number(Task.Revenue)
                  }
                }
              } else {
                if (override) {
                  task.Revenue += overrideValue
                } else {
                  task.Revenue += useAdditional
                    ? Task.Additional
                    : Number(Task.Revenue)
                }
              }
            }
          }
          // if(subtract){
          //     task.Revenue -= subtractPrice
          // }
        }
        // window["warn"]("subtractOuter", subtractOuter, subtractPrice, task.Revenue)
        if (subtractOuter) {
          task.Revenue -= subtractPrice
        }

        if (addOuter) {
          task.Revenue += subtractPrice
        }
      }

      if (starExclusions) {
        newExclusions = { ...newExclusions, [starExclusions]: 'Y' }
        // window["warn"]("agreement exclusions 2", this.state.exclusions, newExclusions)
      }

      if (
        Included &&
        this.state.currentTask &&
        Included.TaskType === this.state.currentTask.TaskType
      ) {
        switch (Task.Frequency) {
          case 'S':
            {
              if (Included.OldMiscRevenue && Included.OldMiscRevenue !== 0) {
                task.Revenue =
                  Included.Revenue -
                  2 * Included.OldMiscRevenue +
                  2 * task.MiscRevenue
                // if (Included.OldMiscRevenue > 0) {
                //     task.Revenue = Included.Revenue - Included.OldMiscRevenue + task.MiscRevenue
                // } else if (Included.OldMiscRevenue < 0) {
                //     task.Revenue = Included.Revenue + Included.OldMiscRevenue + task.MiscRevenue
                // }
              } else {
                task.Revenue = Included.Revenue + 2 * (task.MiscRevenue || 0.0)
              }
            }
            break
          case 'Q':
            {
              window['warn'](
                'quarterly calcs',
                task.Revenue,
                Included.Revenue,
                task.MiscRevenue,
                Included.OldMiscRevenue,
                Included.OldMiscRevenue !== 0,
              )
              //task.Revenue = Included.Revenue - (4 * Included.OldMiscRevenue) + (4 * task.MiscRevenue)
              if (Included.OldMiscRevenue && Included.OldMiscRevenue !== 0) {
                task.Revenue =
                  Included.Revenue -
                  4 * Included.OldMiscRevenue +
                  4 * task.MiscRevenue
                // if (Included.OldMiscRevenue > 0) {
                //     task.Revenue = Included.Revenue - (4 * Included.OldMiscRevenue) + (4 * task.MiscRevenue)
                // } else if (Included.OldMiscRevenue < 0) {
                //     task.Revenue = Included.Revenue + (4 * Included.OldMiscRevenue) + (4 * task.MiscRevenue)
                // }
              } else {
                task.Revenue = Included.Revenue + 4 * (task.MiscRevenue || 0.0)
              }
            }
            break
          case 'B':
            {
              if (Included.OldMiscRevenue && Included.OldMiscRevenue !== 0) {
                task.Revenue =
                  Included.Revenue -
                  6 * Included.OldMiscRevenue +
                  6 * task.MiscRevenue
                // if (Included.OldMiscRevenue > 0) {
                //     task.Revenue = Included.Revenue - Included.OldMiscRevenue + task.MiscRevenue
                // } else if (Included.OldMiscRevenue < 0) {
                //     task.Revenue = Included.Revenue + Included.OldMiscRevenue + task.MiscRevenue
                // }
              } else {
                task.Revenue = Included.Revenue + 6 * (task.MiscRevenue || 0.0)
              }
            }
            break
          case 'M':
            {
              if (Included.OldMiscRevenue && Included.OldMiscRevenue !== 0) {
                task.Revenue =
                  Included.Revenue -
                  12 * Included.OldMiscRevenue +
                  12 * task.MiscRevenue
                // if (Included.OldMiscRevenue > 0) {
                //     task.Revenue = Included.Revenue - Included.OldMiscRevenue + task.MiscRevenue
                // } else if (Included.OldMiscRevenue < 0) {
                //     task.Revenue = Included.Revenue + Included.OldMiscRevenue + task.MiscRevenue
                // }
              } else {
                task.Revenue = Included.Revenue + 12 * (task.MiscRevenue || 0.0)
              }
            }
            break
          default: {
            // if (Included.OldMiscRevenue !== 0) {
            //     if (Included.OldMiscRevenue > 0) {
            //         window["warn"]("agreement override123 5", task.Revenue, Included.OldMiscRevenue, task.MiscRevenue)
            //         task.Revenue = Included.Revenue - Included.OldMiscRevenue + task.MiscRevenue
            //     } else if (Included.OldMiscRevenue < 0) {
            //         task.Revenue = Included.Revenue + Included.OldMiscRevenue + task.MiscRevenue
            //     }
            // } else {
            //     task.Revenue = Included.Revenue + task.MiscRevenue
            // }

            // window["warn"]("additional rev test 2", task.Revenue, Included.OldMiscRevenue, Included.Revenue, task.MiscRevenue)
            task.Revenue =
              Included.Revenue - Included.OldMiscRevenue + task.MiscRevenue
          }
        }
      }
      //tasks.push(task);
      //window["warn"]('Old Task: ', oldTask, task);
    }

    this.setState({
      ...this.state,
      exclusions: newExclusions,
      inclusions: newInclusions,
    })
    return task
  }

  handleTypeChange = event => {
    let agreementTasks = []
    if (this.state.Data.Tasks) {
      this.state.Data.Tasks.map(task => {
        if (task.Data && task.Data.Recurrences) {
          agreementTasks = [...agreementTasks, ...task.Data.Recurrences]
        }
        // if (task.Data && task.Data.Schedule) {
        //     scheduledTasks = [...scheduledTasks, ...task.Data.Schedule];
        // }
      })
    }

    // window["warn"]('Agreement State: ', this.state);
    const { agreementTypes } = this.props
    let {
      Co,
      BusinessUnit,
      Division,
      Data,
      StartDate,
      Cycle,
      Revision,
      MultiSiteYN,
      newCycle,
    } = this.state
    if (!StartDate) {
      StartDate = new Date().toLocaleDateString('en-US')
    }
    const AgreementType = event ? event.target.value : this.state.AgreementType
    let type = _.find(agreementTypes, { BusinessUnit, Division, AgreementType })

    // if (this.state.Data.AgreementType) {
    //     type = _.cloneDeep(this.state.Data.AgreementType);

    // } else {
    if (type) {
      type = _.cloneDeep(type)
    }
    // }

    let TermLength =
      type && type.TermLength ? type.TermLength : this.state.TermLength
    let EndDate
    EndDate = new Date(StartDate)
    EndDate.setDate(EndDate.getDate() + (TermLength || 1) * 365 - 1)

    if (MultiSiteYN !== 'Y') {
      if (BusinessUnit && Division) {
        let Agreement = this.state.Agreement

        const { Tasks } = type.Data

        // const Tasks2 = type.Data.Tasks;
        // const Tasks = this.state.Data.Tasks;

        // window["warn"]("agreement tasks123 Tasks", Tasks)

        const tasks = []

        if (newCycle) {
          Data.Tasks.map(incl => {
            const Task = _.find(Tasks, { TaskType: incl.TaskType })
            if (Task) {
              Task.Frequency =
                incl.Frequency ||
                (Task.Frequency === '*'
                  ? this.state.BillingFrequency
                  : Task.Frequency)
              let task = this.updateTaskPrices(
                event,
                Task,
                true,
                Task.TaskType,
                Task.Frequency,
                Agreement,
              )
              window['warn']('newcycle loop task', task)
              tasks.push(task)
            }
          })
        } else {
          //Check for revision or for new
          if (this.state.simpleAction !== 'revise') {
            Agreement = `${type.Prefix}${this.state.Site}`
          }
          Tasks.map((Task, index) => {
            // window["warn"]("Task.Frequency", Task.Frequency)
            Task.Frequency =
              Task.Frequency === '*'
                ? this.state.BillingFrequency
                : Task.Frequency

            const Included = _.find(Data.Tasks, { TaskType: Task.TaskType })
            //Task.RevenueType = Included.RevenueType
            //Task.Frequency = Included.Frequency === "*" ? this.state.BillingFrequency : Included.Frequency
            if (Included || Task.OptionalYN !== 'Y') {
              let task = this.updateTaskPrices(
                event,
                Task,
                true,
                Task.TaskType,
                Task.Frequency,
                Agreement,
              )
              tasks.push(task)
            }
          })
        }

        this.updateAgreementPrice({
          ...this.state,
          AgreementType,
          Agreement,
          TermLength,
          StartDate,
          EndDate,
          Signature: null,
          SignedBy: null,
          Data: { ...Data, AgreementType: type, Tasks: tasks },
          needsRevision: true,
        })
      }
    } else {
      const { Customer, Sites } = Data
      if (Sites) {
        if (BusinessUnit && Division) {
          let Agreement = this.state.Agreement

          const { Tasks } = type.Data
          //const Tasks = _.filter(this.state.Data.AgreementType.Data.Tasks, (o) => o.ActiveYN !== 'N' || _.findIndex(agreementTasks, { TaskType: o.TaskType }) > -1)
          const tasks = []

          if (newCycle) {
            Data.Tasks.map(incl => {
              const Task = _.find(Tasks, { TaskType: incl.TaskType })
              if (Task) {
                Task.Frequency =
                  incl.Frequency ||
                  (Task.Frequency === '*'
                    ? this.state.BillingFrequency
                    : Task.Frequency)
                let task = this.updateTaskPrices(
                  event,
                  Task,
                  true,
                  Task.TaskType,
                  Task.Frequency,
                  Agreement,
                )
                tasks.push(task)
              }
            })
          } else {
            //Check for revision or for new
            if (this.state.simpleAction !== 'revise') {
              Agreement = `${type.Prefix}${this.state.Site}`
            }
            Tasks.map((Task, index) => {
              // window["warn"]("Task.Frequency", Task.Frequency)
              Task.Frequency =
                Task.Frequency === '*'
                  ? this.state.BillingFrequency
                  : Task.Frequency

              const Included = _.find(Data.Tasks, { TaskType: Task.TaskType })
              //Task.RevenueType = Included.RevenueType
              //Task.Frequency = Included.Frequency === "*" ? this.state.BillingFrequency : Included.Frequency
              if (Included || Task.OptionalYN !== 'Y') {
                let task = this.updateTaskPrices(
                  event,
                  Task,
                  true,
                  Task.TaskType,
                  Task.Frequency,
                  Agreement,
                )
                tasks.push(task)
              }
            })
          }

          this.updateAgreementPrice({
            ...this.state,
            AgreementType,
            Agreement,
            StartDate,
            EndDate,
            Signature: null,
            SignedBy: null,
            Data: { ...Data, AgreementType: type, Tasks: tasks },
            needsRevision: true,
          })
        }
      }
    }
  }

  handleTypeChangeRenew = () => {
    // window["warn"]("handleTypeChangeRenew ran", this.state.Data, this.state.BillingFrequency)
    let agreementTasks = []
    if (this.state.Data.Tasks) {
      this.state.Data.Tasks.map(task => {
        if (task.Data && task.Data.Recurrences) {
          agreementTasks = [...agreementTasks, ...task.Data.Recurrences]
        }
        // if (task.Data && task.Data.Schedule) {
        //     scheduledTasks = [...scheduledTasks, ...task.Data.Schedule];
        // }
      })
    }

    // window["warn"]('Agreement State: ', this.state);
    const { agreementTypes } = this.props
    let {
      Co,
      BusinessUnit,
      Division,
      Data,
      StartDate,
      Cycle,
      Revision,
      MultiSiteYN,
    } = this.state
    // if (!StartDate) {
    //     StartDate = new Date().toLocaleDateString('en-US');
    // }
    const AgreementType = this.state.AgreementType
    let type = _.find(agreementTypes, { BusinessUnit, Division, AgreementType })

    // if (this.state.Data.AgreementType) {
    //     type = _.cloneDeep(this.state.Data.AgreementType);

    // } else {
    if (type) {
      type = _.cloneDeep(type)
    }
    // }

    let event = null

    let TermLength =
      type && type.TermLength ? type.TermLength : this.state.TermLength
    // let EndDate;
    // EndDate = new Date(StartDate);
    // EndDate.setDate(EndDate.getDate() + ((TermLength || 1) * 365) - 1);

    if (MultiSiteYN !== 'Y') {
      if (BusinessUnit && Division) {
        const Agreement = `${type.Prefix}${this.state.Site}`
        const Tasks2 = type.Data.Tasks
        const Tasks = this.state.Data.Tasks

        const tasks = []
        Tasks.map((Task, index) => {
          const Included = _.find(Tasks2, { TaskType: Task.TaskType })
          Task.RevenueType = Included.RevenueType
          Task.Frequency =
            Included.Frequency === '*'
              ? this.state.BillingFrequency
              : Included.Frequency
          // window["warn"]("agreement tasks456", Tasks, Tasks2, Included.Frequency, this.state.Data.AgreementType.Data)
          let task = this.updateTaskPrices(
            event,
            Task,
            true,
            Task.TaskType,
            Task.Frequency,
          )
          tasks.push(task)
        })

        let TotalPrice = 0.0
        for (var i = 0; i < tasks.length; i++) {
          TotalPrice += (tasks[i].Revenue || 0) * (TermLength || 1)
          // window["warn"]("updateAgreementPrice revenue", Tasks[i], Tasks[i].Revenue, TotalPrice)
        }

        this.setState({
          ...this.state,
          TotalPrice,
          Data: { ...Data, AgreementType: type, Tasks: tasks },
          renewRan: false,
        })
      }
    } else {
      const { Customer, Sites } = Data
      if (Sites) {
        if (BusinessUnit && Division) {
          const Agreement = `${type.Prefix}${this.state.Customer}`
          //const { Tasks } = type.Data;
          const Tasks = _.filter(
            this.state.Data.AgreementType.Data.Tasks,
            o =>
              o.ActiveYN !== 'N' ||
              _.findIndex(agreementTasks, { TaskType: o.TaskType }) > -1,
          )
          const tasks = []

          Tasks.map((Task, index) => {
            let task = this.updateTaskPrices(
              event,
              Task,
              true,
              Task.TaskType,
              Task.Frequency,
            )
            tasks.push(task)
          })

          let TotalPrice = 0.0
          for (var i = 0; i < tasks.length; i++) {
            TotalPrice += (tasks[i].Revenue || 0) * (TermLength || 1)
            // window["warn"]("updateAgreementPrice revenue", Tasks[i], Tasks[i].Revenue, TotalPrice)
          }

          this.setState({
            ...this.state,
            TotalPrice,
            Data: { ...Data, AgreementType: type, Tasks: tasks },
            renewRan: false,
          })
        }
      }
    }
  }

  handleClickTask = (TaskType, update) => {
    let {
      Co,
      BusinessUnit,
      Division,
      Agreement,
      Cycle,
      Revision,
      TotalPrice,
      MultiSiteYN,
      Data,
    } = this.state
    let { Customer, Tasks, AgreementType, Site, Sites } = Data
    const prevTasks = _.cloneDeep(Tasks)
    const Task = _.find(AgreementType.Data.Tasks, { TaskType })
    const index = _.findIndex(Tasks, { TaskType })
    if (index > -1) {
      if (Task.OptionalYN === 'Y') {
        TotalPrice -= Task.Revenue
        Tasks.splice(index, 1)
      }
    } else {
      let task = this.updateTaskPrices(
        null,
        Task,
        false,
        Task.TaskType,
        Task.Frequency,
        Agreement,
      )

      Tasks.push(task)
    }

    // window["warn"]('Updated Agreement Tasks: ', { ...this.state, TotalPrice, Signature: null, SignedBy: null, Data: { ...this.state.Data, Tasks }, needsRevision, });
    const needsRevision = !_.isEqual(Tasks, prevTasks)
    this.updateAgreementPrice({
      ...this.state,
      TotalPrice,
      Signature: null,
      SignedBy: null,
      Data: { ...this.state.Data, Tasks },
      needsRevision,
    })
  }

  /*updateTaskFrequency = (TaskType, Frequency) => {
        window["warn"]("updateTaskFrequency ran")
        let { Co, BusinessUnit, Division, Agreement, Cycle, Revision, TotalPrice, MultiSiteYN, Data, TermLength } = this.state;
        let { Customer, Tasks, AgreementType, Site, Sites } = Data;
        const prevTasks = _.cloneDeep(Tasks);
        const Task = _.find(AgreementType.Data.Tasks, { TaskType });
        let { IncentiveType, IncentiveRate } = AgreementType;
        Task.Frequency = Frequency;
        const index = _.findIndex(Tasks, { TaskType });
        if (index > -1) {
            TotalPrice -= Tasks[index].Revenue;
            const { RevenueType } = AgreementType;
            let task = this.updateTaskPrices(null, Task, true, TaskType, Frequency);
            Tasks.splice(index, 1, task);
        }
        const needsRevision = !_.isEqual(Tasks, prevTasks);
        this.updateAgreementPrice({ ...this.state, TotalPrice, Signature: null, SignedBy: null, Data: { ...this.state.Data, Tasks }, needsRevision, });

    }*/

  updateTaskFrequency = (TaskType, Frequency) => {
    let {
      Co,
      BusinessUnit,
      Division,
      Agreement,
      Cycle,
      Revision,
      TotalPrice,
      MultiSiteYN,
      Data,
      TermLength,
      exclusions,
    } = this.state
    let { Customer, Tasks, AgreementType, Site, Sites } = Data

    window['warn']('updateTaskFrequency Tasks', Tasks, exclusions, Site)
    const prevTasks = _.cloneDeep(Tasks)
    const Task = _.find(AgreementType.Data.Tasks, { TaskType })
    let { IncentiveType, IncentiveRate } = AgreementType
    Task.Frequency = Frequency
    const index = _.findIndex(Tasks, { TaskType })
    if (index > -1) {
      TotalPrice -= Tasks[index].Revenue
      const { RevenueType } = AgreementType
      const task = {
        Co,
        Customer: this.state.Customer,
        Site: this.state.Site,
        Agreement,
        Cycle,
        Revision,
        TaskType,
        Revenue: 0.0,
        MiscRevenue: 0.0,
        Data: {
          Units: [],
          Recurrences: [],
          Schedule: [],
        },
        Frequency,
      }
      const oldTask = _.find(Data.Tasks, { TaskType })
      if (oldTask) {
        // task.Revenue = Number(oldTask.MiscRevenue);
        task.MiscRevenue = Number(oldTask.MiscRevenue)
      }
      if (Task.UnitCat) {
        if (MultiSiteYN !== 'Y' && Site) {
          const { Units } = Site.Data
          const units = this.filterUnits(
            Units,
            Task.UnitCat,
            Task.UnitType,
            Task.UnitTypes,
          )

          units.forEach(unit => {
            const { UnitID, ActiveYN, ExcludeYN } = unit
            if (
              ActiveYN !== 'N' &&
              (!this.state.exclusions[unit.UnitID] ||
                (this.state.exclusions[unit.UnitID] !== '*' &&
                  !this.state.exclusions[unit.UnitID][Task.TaskType]))
            ) {
              task.Data.Units.push({
                Co,
                Customer: Site.Customer,
                Site: Site.Site,
                Agreement,
                Cycle,
                Revision,
                TaskType,
                UnitID,
              })
            }
          })
        }
        if (MultiSiteYN === 'Y' && Sites) {
          Sites.map(site => {
            const Site = _.find(Customer.Data.Sites, { Site: site.Site })
            if (Site) {
              const { Units } = Site.Data
              const units = this.filterUnits(
                Units,
                Task.UnitCat,
                Task.UnitType,
                Task.UnitTypes,
              )
              units.forEach(unit => {
                const { UnitID, ActiveYN, ExcludeYN } = unit
                if (
                  ActiveYN !== 'N' &&
                  (!this.state.exclusions[unit.UnitID] ||
                    (this.state.exclusions[unit.UnitID] !== '*' &&
                      !this.state.exclusions[unit.UnitID][Task.TaskType]))
                ) {
                  task.Data.Units.push({
                    Co,
                    Customer: Site.Customer,
                    Site: Site.Site,
                    Agreement,
                    Cycle,
                    Revision,
                    TaskType,
                    UnitID,
                  })
                }
              })
            }
          })
        }
      }
      if (Task.RevenueType === 'T' || task.Data.Units.length < 1) {
        task.Revenue += Task.Revenue
      }
      if (Task.RevenueType === 'U' && task.Data.Units.length > 0) {
        for (var u = 0; u < task.Data.Units.length; u++) {
          const unit = task.Data.Units[u]
          const override = _.find(Task.Data.Overrides, {
            UnitType: unit.UnitType,
          })
          switch (Task.Frequency) {
            case 'S':
              {
                if (override) {
                  task.Revenue += 2 * override.Revenue
                } else {
                  task.Revenue += 2 * Task.Revenue
                }
              }
              break
            case 'Q':
              {
                if (override) {
                  task.Revenue += 4 * override.Revenue
                } else {
                  task.Revenue += 4 * Task.Revenue
                }
              }
              break
            case 'B':
              {
                if (override) {
                  task.Revenue += 6 * override.Revenue
                } else {
                  task.Revenue += 6 * Task.Revenue
                }
              }
              break
            case 'M':
              {
                if (override) {
                  task.Revenue += 12 * override.Revenue
                } else {
                  task.Revenue += 12 * Task.Revenue
                }
              }
              break
            default: {
              if (override) {
                task.Revenue += override.Revenue
              } else {
                task.Revenue += Task.Revenue
              }
            }
          }
        }
      }
      if (MultiSiteYN === 'Y') {
        switch (Task.Frequency) {
          case 'S':
            {
              task.Revenue +=
                2 * (task.MiscRevenue || 0.0) * this.state.Data.Sites.length
            }
            break
          case 'Q':
            {
              task.Revenue +=
                4 * (task.MiscRevenue || 0.0) * this.state.Data.Sites.length
            }
            break
          case 'B':
            {
              task.Revenue +=
                6 * (task.MiscRevenue || 0.0) * this.state.Data.Sites.length
            }
            break
          case 'M':
            {
              task.Revenue +=
                12 * (task.MiscRevenue || 0.0) * this.state.Data.Sites.length
            }
            break
          default: {
            task.Revenue +=
              (task.MiscRevenue || 0.0) * this.state.Data.Sites.length
          }
        }
      } else {
        switch (Task.Frequency) {
          case 'S':
            {
              task.Revenue += 2 * (task.MiscRevenue || 0.0)
            }
            break
          case 'Q':
            {
              task.Revenue += 4 * (task.MiscRevenue || 0.0)
            }
            break
          case 'B':
            {
              task.Revenue += 6 * (task.MiscRevenue || 0.0)
            }
            break
          case 'M':
            {
              task.Revenue += 12 * (task.MiscRevenue || 0.0)
            }
            break
          default: {
            task.Revenue += task.MiscRevenue || 0.0
          }
        }
      }
      Tasks.splice(index, 1, task)
    }
    const needsRevision = !_.isEqual(Tasks, prevTasks)
    this.updateAgreementPrice({
      ...this.state,
      TotalPrice,
      Signature: null,
      SignedBy: null,
      Data: { ...this.state.Data, Tasks },
      needsRevision,
    })
  }

  updateAgreementPrice = data => {
    let { Cycle, Data, TermLength, value, reviseNoRecurrences } = data
    const { AgreementType, Tasks } = Data
    let {
      Price,
      PriceMethod,
      IncentiveType,
      IncentiveRate,
      RenewalIncentiveType,
      RenewalIncentiveRate,
    } = AgreementType
    const AgreementTasks = AgreementType.Data.Tasks

    let TotalPrice = 0.0
    for (var i = 0; i < Tasks.length; i++) {
      TotalPrice += (Tasks[i].Revenue || 0) * (TermLength || 1)
      window['warn'](
        'updateAgreementPrice revenue',
        Tasks[i],
        Tasks[i].Revenue,
        TotalPrice,
        AgreementType,
        data,
      )
    }

    if (PriceMethod === 'F') {
      TotalPrice = Price
    }
    if (Cycle === 1 && IncentiveType && IncentiveRate) {
      if (IncentiveType == 'F') {
        TotalPrice += IncentiveRate
      }

      if (IncentiveType == 'P') {
        TotalPrice += TotalPrice * (IncentiveRate / 100)
      }
    }
    if (Cycle > 1 && RenewalIncentiveType && RenewalIncentiveRate) {
      if (RenewalIncentiveType == 'F') {
        TotalPrice += RenewalIncentiveRate
      }

      if (RenewalIncentiveType == 'P') {
        TotalPrice += TotalPrice * (RenewalIncentiveRate / 100)
      }
    }

    const needsRevision = this.state.needsRevision
      ? true
      : data.needsRevision ||
        !this.state.ID ||
        Boolean(this.state.TotalPrice !== TotalPrice)
    window['warn']('updateAgreementPrice needsRevision', needsRevision, data)
    this.setState(
      {
        ...data,
        TotalPrice,
        needsRevision,
        renewRan: false,
        value,
        reviseNoRecurrences,
      },
      () => this.updateBillingSchedule(),
    )
  }

  handleStartDateChange = event => {
    let { StartDate, EndDate } = this.state
    const { AgreementType } = this.state.Data
    StartDate = event._d.toLocaleDateString('en-US')
    if (this.state.TermLength || (AgreementType && AgreementType.TermLength)) {
      let endDate = new Date(StartDate)
      endDate.setDate(
        endDate.getDate() +
          ((365 * this.state.TermLength || AgreementType.TermLength) - 1),
      )
      EndDate = endDate.toLocaleDateString('en-US')
    }
    // window["warn"](StartDate);
    this.setState(
      { ...this.state, StartDate, EndDate, needsRevision: true },
      this.handleTypeChange,
    )
  }

  handleEndDateChange = event => {
    let { EndDate } = this.state
    EndDate = event._d.toLocaleDateString('en-US')
    // window["warn"](EndDate);
    this.setState({ ...this.state, EndDate, needsRevision: true })
  }

  handleTaskDueByChange = event => {
    let { taskDueBy } = this.state
    taskDueBy = event._d.toLocaleDateString('en-US')
    this.setState({ ...this.state, taskDueBy })
  }
  handleWorkOrderChange = workOrder => {
    let { EndDate } = this.state
    this.setState({ ...this.state, WorkOrder: workOrder.WorkOrder })
    const workOrderData = this.props.selectedWOData
      ? this.props.selectedWOData
      : this.getFilteredArray(
          this.props.workOrderData || [],
          this.props.searchText,
        )
    const foundRecord = workOrderData.find(
      item => item.WorkOrder === workOrder.WorkOrder,
    )
    this.setState({
      ...this.state,
      WorkOrder: workOrder.WorkOrder,
      FilteredScope: foundRecord.Data.Scopes,
      allowScopeYN: 'Y',
    })
  }

  getAddress = () => {
    // window["log"](this.state);
    const { Address1, City, State, Zip } = this.state
    if (window.google) {
      Geocode.fromAddress(`${Address1} ${City}, ${State} ${Zip}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          const Address =
            Address1.length > 3 && City.length > 2 && State.length > 1
              ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
              : false
          this.setState({
            ...this.state,
            Address,
            Latitude: lat,
            Longitude: lng,
          })
        },
        error => {
          const Address =
            Address1.length > 3 && City.length > 2 && State.length > 1
              ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
              : false
          this.setState({ ...this.state, Address })
        },
      )
    } else {
      const Address =
        Address1.length > 3 && City.length > 2 && State.length > 1
          ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
          : false
      this.setState({ ...this.state, Address })
    }
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1 &&
      City &&
      State &&
      Address1.length > 3 &&
      City.length > 2 &&
      State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${
          Zip ? Zip.trim() : ''
        }`
      : false
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  handleSimpleTabChange = (event, value) => {
    this.setState({ simpleValue: value })
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  handlePart = barcode => {
    const { agreementMaterial } = this.state
    if (!agreementMaterial.ID) {
      this.setState({
        ...this.state,
        agreementMaterial: { ...agreementMaterial, Material: barcode },
        isScanning: false,
      })
    } else {
      this.setState({
        ...this.state,
        agreementMaterial: { ...agreementMaterial, AgreementMaterial: barcode },
        isScanning: false,
      })
    }
  }

  handleTaskMenuOpen = (taskMenu, taskInfo, taskIncluded, taskSite) => {
    // window["warn"]('Task Menu Open: ', { taskMenu, taskInfo, taskIncluded, taskSite });
    this.setState({ ...this.state, taskMenu, taskInfo, taskIncluded, taskSite })
  }

  handleTaskMenuClose = () => {
    this.setState({
      ...this.state,
      taskMenu: null,
      taskInfo: null,
      taskIncluded: null,
      taskSite: null,
    })
  }

  handleTaskWaiveMenuClose = () => {
    this.setState({
      ...this.state,
      taskWaiveMenu: null,
      taskInfo: null,
      taskIncluded: null,
      taskSite: null,
    })
  }

  handleTaskScheduleMenuClose = () => {
    this.setState({
      ...this.state,
      taskScheduleMenu: null,
      taskInfo: null,
      taskIncluded: null,
      taskSite: null,
    })
  }

  scheduleTask = e => {
    const { taskInfo, taskIncluded, taskSite } = this.state
    // window["warn"]({ taskScheduleMenu: e.target, taskInfo, taskIncluded, taskSite });
    this.setState({
      ...this.state,
      taskScheduleMenu: e.target,
      taskInfo,
      taskIncluded,
      taskSite,
      taskMenu: null,
    })
  }

  handleTaskEditMenuClose = () => {
    this.setState({
      ...this.state,
      taskEditMenu: null,
      taskInfo: null,
      taskIncluded: null,
      taskSite: null,
    })
  }

  handleTaskEditMenuOpen = (taskEditMenu, taskInfo, taskIncluded, taskSite) => {
    //taskInfo.MiscRevenue = Number(e.target.value);
    const { Data } = this.state
    const { Tasks } = Data
    const index = _.findIndex(Tasks, { TaskType: taskInfo.TaskType })
    if (index > -1) {
      const task = Tasks[index]
      task.OldMiscRevenue = task.MiscRevenue
      Tasks.splice(index, 1, task)
    }

    this.setState({
      ...this.state,
      taskEditMenu,
      taskInfo,
      taskIncluded,
      taskSite,
      Data: { ...Data, Tasks },
    })
  }

  waiveTask = e => {
    const { taskInfo, taskIncluded, taskSite } = this.state
    // window["warn"]({ taskWaiveMenu: e.target, taskInfo, taskIncluded, taskSite });
    this.setState({
      ...this.state,
      taskWaiveMenu: e.target,
      taskInfo,
      taskIncluded,
      taskSite,
      taskMenu: null,
    })
  }

  addWaivedTask = () => {
    const {
      Data,
      Co,
      Customer,
      Site,
      Agreement,
      Cycle,
      taskInfo,
      taskDueBy,
      taskWorkOrder,
      taskScope,
      taskSeq,
      taskArrivalWindow,
      taskTechnician,
      taskSite,
      MultiSiteYN,
    } = this.state
    var task = {
      ...taskInfo,
      Co,
      Customer,
      Site: MultiSiteYN === 'Y' ? taskSite.Site : Site,
      Agreement,
      Cycle,
      Seq: taskSeq,
      WorkOrder: 0,
      Scope: 0,
      DueBy: taskDueBy,
      ArrivalWindow: taskArrivalWindow,
      LeadTechnician: taskTechnician,
      Action: 'W',
      Data: {
        ErrMsg: null,
      },
    }
    this.setState(
      {
        ...this.state,
        taskWaiveMenu: null,
        taskInfo: null,
        taskSite: null,
        taskWorkOrder: null,
        taskIncluded: null,
        taskSite: null,
        taskScope: null,
        taskDueBy: new Date(),
        taskArrivalWindow: null,
        taskTechnician: null,
      },
      () => this.props.assignAgreementTask(task),
    )
  }

  addTaskToWorkOrder = () => {
    const {
      Data,
      Co,
      Customer,
      Site,
      Agreement,
      Cycle,
      taskInfo,
      taskDueBy,
      taskWorkOrder,
      taskScope,
      taskSeq,
      taskArrivalWindow,
      taskTechnician,
      taskSite,
      MultiSiteYN,
    } = this.state
    var task = {
      ...taskInfo,
      Co,
      Customer,
      Site: MultiSiteYN === 'Y' ? taskSite.Site : Site,
      Agreement,
      Cycle,
      Seq: taskSeq,
      WorkOrder: taskWorkOrder === '+' ? 0 : taskWorkOrder,
      Scope: taskScope === '+' ? 0 : taskScope,
      DueBy: taskDueBy,
      ArrivalWindow: taskArrivalWindow,
      LeadTechnician: taskTechnician,
      Data: {
        ErrMsg: null,
      },
    }
    this.setState(
      {
        ...this.state,
        taskScheduleMenu: null,
        taskInfo: null,
        taskSite: null,
        taskWorkOrder: null,
        taskIncluded: null,
        taskSite: null,
        taskScope: null,
        taskDueBy: new Date(),
        taskArrivalWindow: null,
        taskTechnician: null,
      },
      () => {
        this.props.assignAgreementTask(task)
      },
    )
  }

  handleCustomerSelect = Customer => {
    const { Data } = this.state
    this.setState({
      ...this.state,
      Customer: Customer.Customer,
      Data: { ...Data, Customer },
    })
  }

  handleSiteSelect = Site => {
    const { Data } = Site
    const { Customer, Agreements, Units } = Data
    const Address = this.buildAddress(
      Site.Address1,
      Site.City,
      Site.State,
      Site.Zip,
    )
    if ((!Site.Latitude || !Site.Longitude) && window.google) {
      Geocode.fromAddress(
        `${Site.Address1} ${Site.City}, ${Site.State} ${Site.Zip}`,
      ).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          this.setState({
            ...this.state,
            Customer: Site.Customer,
            Site: Site.Site,
            Data: {
              ...this.state.Data,
              Site,
              Customer,
              Variations: Agreements,
              Units,
            },
            Address,
            Latitude: lat,
            Longitude: lng,
          })
        },
        error => {
          this.setState({
            ...this.state,
            Customer: Site.Customer,
            Site: Site.Site,
            Data: {
              ...this.state.Data,
              Site,
              Customer,
              Variations: Agreements,
              Units,
            },
            Address,
          })
        },
      )
    } else {
      this.setState({
        ...this.state,
        Customer: Site.Customer,
        Site: Site.Site,
        Data: {
          ...this.state.Data,
          Site,
          Customer,
          Variations: Agreements,
          Units,
        },
        Address,
      })
    }
  }

  setSchedTaskMenu = (e, task) => {
    this.setState({
      ...this.state,
      schedTaskMenu: e.target,
      scheduledTask: task,
    })
  }

  handleSchedTaskMenuClose = () => {
    this.setState({ ...this.state, schedTaskMenu: null, scheduledTask: null })
  }

  handleSignature = Signature => {
    this.setState({ ...this.state, Signature })
  }

  handleSignedBy = SignedBy => {
    this.setState({ ...this.state, SignedBy })
  }

  toggleTabs = () => {
    const { showTabs } = this.state
    this.setState({ ...this.state, showTabs: !showTabs })
  }

  toggleTasks = () => {
    const { showTasks } = this.state
    this.setState({ ...this.state, showTasks: !showTasks })
  }

  toggleUnits = () => {
    const { showUnits } = this.state
    this.setState({ ...this.state, showUnits: !showUnits })
  }

  handleNext = () => {
    let { simpleStep } = this.state
    if (simpleStep < 4) {
      simpleStep += 1
      this.setState({ ...this.state, simpleStep })
    }
  }

  handleBack = () => {
    let { simpleStep, simpleAction } = this.state
    if (simpleStep > 0) {
      simpleStep -= 1
      this.setState({ ...this.state, simpleStep, simpleAction })
    } else {
      simpleAction = null
      this.setState({ ...this.state, simpleStep, simpleAction })
    }
  }

  setBillingSchedule = billings => {
    const {
      Co,
      Customer,
      Site,
      Agreement,
      Cycle,
      Revision,
      Data,
      BillingFrequency,
      TotalPrice,
      StartDate,
      TermLength,
    } = this.state
    const { AgreementType } = Data
    let BillingSchedule = []
    for (var i = 0; i < (TermLength || 1); i++) {
      switch (BillingFrequency || AgreementType.BillingFrequency) {
        case 'A':
          {
            let dt = moment(StartDate)
            dt.add(i, 'y')
            dt.set('month', billings - 1)
            dt = dt.format('MM/DD/YYYY')
            const billing = {
              Co,
              Customer,
              Site,
              Agreement,
              Cycle,
              Revision,
              Seq: 1,
              DueDate: dt,
              Amount: TotalPrice,
            }
            BillingSchedule.push(billing)
          }
          break
        case 'S':
          {
            let Seq = 0
            Object.keys(billings).map(key => {
              Seq += 1
              let dt = moment(StartDate)
              const mth = dt.month() + 1
              if (billings[key] < mth) {
                dt.add(1, 'y')
              }
              dt.set('month', billings[key] - 1)
              dt.add(i, 'y')

              dt = dt.startOf('month').format('MM/DD/YYYY')
              const occurrence = {
                Co,
                Customer,
                Site,
                Agreement,
                Cycle,
                Revision,
                Seq,
                DueDate: dt,
                Amount: TotalPrice / 2,
              }
              BillingSchedule.push(occurrence)
            })
          }
          break
        case 'Q':
          {
            let Seq = 0
            Object.keys(billings).map(key => {
              Seq += 1
              let dt = moment(StartDate)
              const mth = dt.month() + 1
              if (billings[key] < mth) {
                dt.add(1, 'y')
              }
              dt.set('month', billings[key] - 1)
              dt.add(i, 'y')

              dt = dt.startOf('month').format('MM/DD/YYYY')
              const occurrence = {
                Co,
                Customer,
                Site,
                Agreement,
                Cycle,
                Revision,
                Seq,
                DueDate: dt,
                Amount: TotalPrice / 4,
              }
              BillingSchedule.push(occurrence)
            })
          }
          break
        case 'B':
          {
            let Seq = 0
            Object.keys(billings).map(key => {
              Seq += 1
              let dt = moment(StartDate)
              const mth = dt.month() + 1
              if (billings[key] < mth) {
                dt.add(1, 'y')
              }
              dt.set('month', billings[key] - 1)
              dt.add(i, 'y')

              dt = dt.startOf('month').format('MM/DD/YYYY')
              const occurrence = {
                Co,
                Customer,
                Site,
                Agreement,
                Cycle,
                Revision,
                Seq,
                DueDate: dt,
                Amount: TotalPrice / 6,
              }
              BillingSchedule.push(occurrence)
            })
          }
          break
        case 'M':
          {
            let Seq = 0
            Object.keys(billings).map(key => {
              Seq += 1
              let dt = moment(StartDate)
              const mth = dt.month() + 1
              if (billings[key] < mth) {
                dt.add(1, 'y')
              }
              dt.set('month', billings[key] - 1)
              dt.add(i, 'y')

              dt = dt.startOf('month').format('MM/DD/YYYY')
              const occurrence = {
                Co,
                Customer,
                Site,
                Agreement,
                Cycle,
                Revision,
                Seq,
                DueDate: dt,
                Amount: TotalPrice / 12,
              }
              BillingSchedule.push(occurrence)
            })
          }
          break
      }
    }
    BillingSchedule = _.sortBy(BillingSchedule, o => {
      return new Date(o.DueDate)
    })
    BillingSchedule.map((billing, index) => {
      billing.Seq = index + 1
    })
    this.setState({ ...this.state, Data: { ...Data, BillingSchedule } }) //, this.state.renewRan ? this.handleTypeChangeRenew : null);
    // window["warn"]('Updated Agreement Billings: ', BillingSchedule, billings, this.state.Data.Tasks);
  }

  updateBillingSchedule = () => {
    const {
      Co,
      Customer,
      Site,
      Agreement,
      Cycle,
      Revision,
      StartDate,
      TotalPrice,
      Data,
    } = this.state
    let { BillingSchedule } = Data
    if (BillingSchedule && BillingSchedule.length > 0) {
      BillingSchedule.map(billing => {
        billing.Amount = TotalPrice / BillingSchedule.length
      })
    } else {
      BillingSchedule = []
      let dt = moment(StartDate)
      dt = dt.startOf('month').format('MM/DD/YYYY')
      const billing = {
        Co,
        Customer,
        Site,
        Agreement,
        Cycle,
        Revision,
        Seq: 1,
        DueDate: dt,
        Amount: TotalPrice,
      }
      BillingSchedule.push(billing)
    }
    this.setState({ ...this.state, Data: { ...Data, BillingSchedule } })
  }

  setTaskRecurrenceSchedule = (task, schedule, changed) => {
    const { TaskType } = task
    const {
      Co,
      Customer,
      Site,
      Agreement,
      Cycle,
      Revision,
      Data,
      StartDate,
      needsRevision,
      TermLength,
      newCycle,
    } = this.state
    const { Tasks, AgreementType } = !changed ? Data : _.cloneDeepWith(Data)
    const Task = _.find(Tasks, { TaskType: task.TaskType })
    window['warn'](
      'setTaskRecurrenceSchedule Task Info: ',
      schedule,
      task,
      Task,
      changed,
      Tasks,
    )
    const Frequency = Task.Frequency || task.Frequency

    if (Task && (needsRevision || newCycle || !this.state.ID || changed)) {
      Task.Data.Recurrences = []
      for (var i = 0; i < (TermLength || 1); i++) {
        switch (Frequency) {
          case 'A':
            {
              let dt = moment(StartDate)
              const mth = dt.month() + 1
              if (schedule < mth) {
                dt.add(1, 'y')
              }
              dt.set('month', schedule - 1)
              dt.add(i, 'y')

              dt = dt.startOf('month').format('MM/DD/YYYY')
              const occurrence = {
                Co,
                Customer,
                Site,
                Agreement,
                Cycle,
                Revision,
                TaskType,
                Seq: 1,
                Date: dt,
              }
              Task.Data.Recurrences.push(occurrence)
              // window["warn"]('Schedule Update: ', occurrence, Task.Data.Recurrences);
            }
            break
          case 'S':
            {
              let Seq = 0
              Object.keys(schedule).map(key => {
                Seq += 1
                let dt = moment(StartDate)
                const mth = dt.month() + 1
                if (schedule[key] < mth) {
                  dt.add(1, 'y')
                }
                dt.set('month', schedule[key] - 1)
                dt.add(i, 'y')

                dt = dt.startOf('month').format('MM/DD/YYYY')
                const occurrence = {
                  Co,
                  Customer,
                  Site,
                  Agreement,
                  Cycle,
                  Revision,
                  TaskType,
                  Seq,
                  Date: dt,
                }
                Task.Data.Recurrences.push(occurrence)
                // window["warn"]('Schedule Update: ', occurrence, Task.Data.Recurrences);
              })
            }
            break
          case 'Q':
            {
              let Seq = 0
              Object.keys(schedule).map(key => {
                Seq += 1
                let dt = moment(StartDate)
                const mth = dt.month() + 1
                if (schedule[key] < mth) {
                  dt.add(1, 'y')
                }
                dt.set('month', schedule[key] - 1)
                dt.add(i, 'y')

                dt = dt.startOf('month').format('MM/DD/YYYY')
                const occurrence = {
                  Co,
                  Customer,
                  Site,
                  Agreement,
                  Cycle,
                  Revision,
                  TaskType,
                  Seq,
                  Date: dt,
                }
                Task.Data.Recurrences.push(occurrence)
                // window["warn"]('Schedule Update: ', occurrence, Task.Data.Recurrences);
              })
            }
            break
          case 'B':
            {
              let Seq = 0
              Object.keys(schedule).map(key => {
                Seq += 1
                let dt = moment(StartDate)
                const mth = dt.month() + 1
                if (schedule[key] < mth) {
                  dt.add(1, 'y')
                }
                dt.set('month', schedule[key] - 1)
                dt.add(i, 'y')

                dt = dt.startOf('month').format('MM/DD/YYYY')
                const occurrence = {
                  Co,
                  Customer,
                  Site,
                  Agreement,
                  Cycle,
                  Revision,
                  TaskType,
                  Seq,
                  Date: dt,
                }
                Task.Data.Recurrences.push(occurrence)
                // window["warn"]('Schedule Update: ', occurrence, Task.Data.Recurrences);
              })
            }
            break
          case 'M':
            {
              let Seq = 0
              Object.keys(schedule).map(key => {
                Seq += 1
                let dt = moment(StartDate)
                const mth = dt.month() + 1
                if (schedule[key] < mth) {
                  dt.add(1, 'y')
                }
                dt.set('month', schedule[key] - 1)
                dt.add(i, 'y')

                dt = dt.startOf('month').format('MM/DD/YYYY')
                const occurrence = {
                  Co,
                  Customer,
                  Site,
                  Agreement,
                  Cycle,
                  Revision,
                  TaskType,
                  Seq,
                  Date: dt,
                }
                Task.Data.Recurrences.push(occurrence)
                // window["warn"]('Schedule Update: ', occurrence, Task.Data.Recurrences);
              })
            }
            break
        }
      }
    }
    Task.Data.Recurrences = _.sortBy(Task.Data.Recurrences, o => {
      return new Date(o.Date)
    })
    Task.Data.Recurrences.map((occurrence, index) => {
      occurrence.Seq = index + 1
    })

    let value = this.state.value
    let reviseNoRecurrences = this.state.reviseNoRecurrences
    const currentRecurrences = _.filter(Tasks, o =>
      o.Data && o.Data.Recurrences
        ? o.Data.Recurrences.length < 1 && o.TaskType !== 'PMA Camera'
        : null,
    )
    if (currentRecurrences && reviseNoRecurrences) {
      value = 0
      reviseNoRecurrences = false
    }
    window['warn'](
      'Task Schedule Change: ',
      Task,
      Tasks[Tasks.length - 1],
      Tasks,
      this.state.reviseNoRecurrences,
      currentRecurrences,
    )
    this.updateAgreementPrice({
      ...this.state,
      needsRevision: changed,
      Data: { ...Data, Tasks },
      value,
      reviseNoRecurrences,
    })
  }

  handleSiteChange = e => {
    const Site = e.target.value
    const { Data, MultiSiteYN } = this.state
    const { Customer } = Data
    const { Sites } = Customer.Data
    const site = _.find(Sites, { Site })
    this.setState(
      {
        ...this.state,
        Site,
        Data: { ...Data, Site: MultiSiteYN !== 'Y' ? site : null },
      },
      () => this.handleTypeChange(),
    )
  }
  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      // window["log"]('Filtered Array: ', arr);
      return arr
    }
    window['log']('Filtered Array: ', arr, searchText)
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    let { value, simpleAction, Data, taskInfo, showTabs } = this.state
    let scheduledTasks = []
    let agreementTasks = []
    if (this.state.Data.Tasks) {
      this.state.Data.Tasks.map(task => {
        if (task.Data && task.Data.Recurrences) {
          agreementTasks = [...agreementTasks, ...task.Data.Recurrences]
        }
        if (task.Data && task.Data.Schedule) {
          scheduledTasks = [...scheduledTasks, ...task.Data.Schedule]
        }
      })
    }

    // window["warn"]('Agreement Tasks Flattened: ', this.state.Data, agreementTasks);

    let currentTask = null
    let currentTaskMR = null
    const { Tasks } = Data
    if (taskInfo) {
      const index = _.findIndex(Tasks, { TaskType: taskInfo.TaskType })
      if (index > -1) {
        currentTask = { ...Tasks[index] }
        currentTaskMR = currentTask.MiscRevenue
      }
    }

    return (
      <>
        <IconButton
          style={{
            position: 'absolute',
            right: 56,
            //top: !matches ? 8 : 4,
            top: 8,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: 'white',
          }}
          onClick={e => {
            closeFxn(e)
          }}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
        {showTabs && (
          <Tabs
            value={value}
            onChange={this.handleTabChange}
            variant='scrollable'
            scrollButtons='on'
          >
            <Tab
              disableRipple
              icon={<Icon>business_center</Icon>}
              label='Info'
            />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.BillingSchedule
                      ? this.state.Data.BillingSchedule.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>receipt</Icon>
                </Badge>
              }
              label='Billing'
              disabled={!this.state.Data.BillingSchedule}
            />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.AmortizationSchedule
                      ? this.state.Data.AmortizationSchedule.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>today</Icon>
                </Badge>
              }
              label='Amortization'
              disabled={!this.state.Data.AmortizationSchedule}
            />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && agreementTasks
                      ? agreementTasks.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>list</Icon>
                </Badge>
              }
              label='Tasks'
              disabled={!agreementTasks}
            />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && scheduledTasks
                      ? scheduledTasks.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>date_range</Icon>
                </Badge>
              }
              label='Schedule'
              disabled={!scheduledTasks}
            />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data &&
                    this.state.Data.Site &&
                    this.state.Data.Site.Data &&
                    this.state.Data.Site.Data.Units
                      ? this.state.Data.Site.Data.Units.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>build</Icon>
                </Badge>
              }
              label='Service Units'
              disabled={
                !this.state.Data.Site ||
                !this.state.Data.Site.Data ||
                !this.state.Data.Site.Data.Units
              }
            />
            <Tab disableRipple icon={<Icon>assignment</Icon>} label='Form' />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Variations
                      ? this.state.Data.Variations.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>library_books</Icon>
                </Badge>
              }
              label='Variations'
              disabled={!this.state.Data.Variations}
            />
            <Tab
              disableRipple
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Attachments
                      ? this.state.Data.Attachments.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>attach_file</Icon>
                </Badge>
              }
              label='Attachments'
              disabled={!this.state.Data.Attachments}
            />
          </Tabs>
        )}
      </>
    )
  }

  ToolbarContents = () => {
    const { classes, agreementDialog } = this.props
    let { showTabs } = this.state

    return (
      <>
        <SPRoundIcon
          onClick={() => this.setState({ ...this.state, value: 0 })}
          className={classNames(
            'text-24 mr-8',
            this.state.ID
              ? this.state.ActiveYN === 'Y'
                ? classes.greenText
                : classes.redText
              : '',
          )}
        />
        <Typography variant='subtitle1' color='inherit'>
          {agreementDialog.type === 'new'
            ? 'New Agreement'
            : `${this.state.Agreement} - C${this.state.Cycle} - R${this.state.Revision}`}
        </Typography>
        <IconButton
          style={{
            color: 'white',
          }}
          className='pt-16'
          onClick={() => {
            this.toggleTabs()
          }}
        >
          {showTabs ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
        </IconButton>
      </>
    )
  }
  closeScopeDialog = () => {
    this.setState({ scopeDialog: false, adding: false })
  }
  render() {
    const {
      classes,
      securables,
      agreementDialog,
      addAgreement,
      searchText,
      updateAgreement,
      removeAgreement,
      businessUnits,
      divisions,
      arrivalWindows,
      agreementTypes,
      technicians,
      user,
      openEditServiceUnitDialog,
      openNewServiceUnitDialog,
      users,
    } = this.props
    let {
      Co,
      Customer,
      Agreement,
      Cycle,
      Revision,
      ID,
      Address,
      value,
      simpleAction,
      anchorUnit,
      anchorUnitEl,
      anchorUnitTask,
      exclusions,
      simpleStep,
      Data,
      userGPS,
      BusinessUnit,
      Division,
      AgreementType,
      taskMenu,
      taskEditMenu,
      taskScheduleMenu,
      taskWaiveMenu,
      schedTaskMenu,
      scheduledTask,
      taskInfo,
      taskWorkOrder,
      taskSite,
      taskScope,
      showTabs,
      showTasks,
      showUnits,
      advanced,
      quarterlyBillings,
      semiAnnualBillings,
      annualBillings,
      biMonthlyBillings,
      MultiSiteYN,
      workOrderTasks,
      allowScopeYN,
      FilteredScope,
    } = this.state

    const { GPS, Site } = Data
    const { Latitude, Longitude } = Site ? Site : {}
    const accessLevel = _.find(securables, { Securable: 'agreement-dialog' })
    const woAccessLevel = _.find(securables, {
      Securable: 'agreement-work-orders',
    })
    const workOrderData = this.props.selectedWOData
      ? this.props.selectedWOData
      : this.getFilteredArray(this.props.workOrderData || [], searchText)
    const FilteredScopeNew = workOrderData.find(
      item => item.WorkOrder === this.state.WorkOrder,
    )
      ? workOrderData.find(item => item.WorkOrder === this.state.WorkOrder).Data
          .Scopes
      : []
    // window["warn"]('Agreement Dialog Prop Data: ', this.props.agreementDialog.data);
    const agreementType = Data.AgreementType
    const annualSteps = [
      'Agreement Type',
      'Agreement Basics',
      'Customer Specifics',
      simpleAction === 'tasks' ? 'Assign Tasks' : 'Included Tasks',
      'Review and Submit',
    ]
    const otherSteps = [
      'Agreement Type',
      'Agreement Basics',
      'Customer Specifics',
      simpleAction === 'tasks' ? 'Assign Tasks' : 'Included Tasks',
      'Review and Submit',
    ]
    const billingFrequencies = {
      A: 'Annual ',
      S: 'Semi-Annual ',
      Q: 'Quarterly ',
      B: 'Bi-Monthly ',
      M: 'Monthly ',
    }
    const steps =
      agreementType && agreementType.BillingFrequency === 'A'
        ? annualSteps
        : otherSteps

    const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
    const techAvatar = 'assets/images/avatars/profile.jpg'
    const siteAvatar = 'assets/images/logos/SPMarker_Gradient.png'
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    let scheduledTasks = []
    let agreementTasks = []
    if (this.state.Data.Tasks) {
      this.state.Data.Tasks.map(task => {
        if (task.Data && task.Data.Recurrences) {
          agreementTasks = [...agreementTasks, ...task.Data.Recurrences]
        }
        if (task.Data && task.Data.Schedule) {
          scheduledTasks = [...scheduledTasks, ...task.Data.Schedule]
        }
      })
    }

    window['warn']('Agreement Tasks Flattened: ', agreementDialog)

    let currentTask = null
    let currentTaskMR = null
    const { Tasks } = Data
    if (taskInfo) {
      const index = _.findIndex(Tasks, { TaskType: taskInfo.TaskType })
      if (index > -1) {
        currentTask = { ...Tasks[index] }
        currentTaskMR = currentTask.MiscRevenue
      }
    }

    const dialogProps = {
      ...agreementDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    //let header = agreementDialog.type === 'new' ? 'New Agreement' : `Agreement #${this.state.Agreement}`;

    let header = !this.state.Agreement
      ? 'New Agreement'
      : `Agreement #${this.state.Agreement}`

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <DraggableDialog
            SlideUp={SlideUp}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            header={header}
            type='Agreement'
            picRef={this.ref.current}
            icon='business_center'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadAgreementDialog}
            description={this.state.Description}
            storeDialog={agreementDialog}
            //TypeState={this.state.Agreement}
            TypeState={
              agreementDialog.type === 'new' &&
              agreementDialog.props.open === true
                ? true
                : this.state.Agreement
            }
            screenOverlay={this.state.screenOverlay}
            //posSet={this.state.posSet}
            //initialWidthSet={initialWidthSet}
            //minHeight={this.state.minHeight}
            //location={this.state.location}
            //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
            //dialogHeight={this.state.dialogHeight}
          >
            <Dialog
              open={Boolean(this.state.scopeDialog)}
              maxWidth='sm'
              fullWidth={true}
              onClose={this.closeScopeDialog}
            >
              <AppBar position='static' color='primary'>
                <Toolbar className='flex w-full'>
                  <Typography color='inherit'>
                    Choose which scope to add agreement to
                  </Typography>
                  <IconButton
                    style={{
                      height: 30,
                      width: 30,
                      position: 'absolute',
                      right: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                    onClick={this.closeScopeDialog}
                  >
                    <Icon style={{ color: 'white', fontSize: 25 }}>close</Icon>
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent
                className='flex justify-center items-start'
                style={{
                  minHeight: this.state.hasFocus ? 250 : 120,
                  paddingTop: 30,
                }}
              >
                <Autocomplete
                  className={classes.formControl}
                  title='Scope'
                  options={[...FilteredScopeNew]}
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.Scope}>
                        <div className='flex'>{`${value.Scope}`}</div>
                      </MenuItem>
                    )
                  }}
                  noflip={true}
                  portal={!matches}
                  required
                  value={this.state.Scope || ''}
                  disabled={false}
                  onSelect={option => {
                    this.setState({
                      ...this.state,
                      Scope: option.Scope,
                      hasFocus: false,
                    })
                  }}
                  listClass={classes.scrollableList}
                  setParentContainerHeight={() => {
                    this.setState(prevState => ({
                      ...prevState,
                      hasFocus: true,
                    }))
                  }}
                />
              </DialogContent>
              <DialogActions className='dialog-actions justify-end pl-16'>
                <div className='flex'>
                  <Button
                    variant='contained'
                    color='primary'
                    className='leading-tight text-12 mr-4'
                    onClick={() => {
                      const val = _.cloneDeepWith(this.state)

                      delete val.Data.WorkOrder
                      Promise.all([addAgreement(val)]).then(() =>
                        this.setState(
                          { adding: false },
                          this.closeComposeDialog,
                        ),
                      )
                    }}
                  >
                    Add
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
            {value === 0 && (
              <TabContainer
                refProp={this.ref}
                marginBottom={0}
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                {Address && advanced === 'Y' && Latitude && Longitude && (
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'>
                      <Icon color='action'>map</Icon>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '250px',
                        backgroundColor: '#eeeeee',
                        marginBottom: 24,
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <GoogleMap
                        options={this.getMapOptions}
                        gestureHandling='cooperative'
                        bootstrapURLKeys={{
                          key: googleMapsApiKey,
                        }}
                        defaultZoom={12}
                        defaultCenter={[Number(Latitude), Number(Longitude)]}
                        center={[Number(Latitude), Number(Longitude)]}
                      >
                        <Marker
                          key={1}
                          style={{
                            margin: 0,
                            padding: 0,
                            width: 32,
                            height: 42,
                            marginTop: -42,
                            marginLeft: -16,
                            cursor: 'pointer',
                          }}
                          icon='assets/images/logos/SPMarker_Gradient.png'
                          text={Address}
                          lat={Number(Latitude)}
                          lng={Number(Longitude)}
                        />
                        {userGPS && (
                          <Marker
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: '50%',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              margin: 'auto',
                              cursor: 'pointer',
                              position: 'absolute',
                              transform: 'rotate(-45deg)',
                            }}
                            icon={
                              user.Data && user.Data.Avatar
                                ? getProfileImage(
                                    `ID=${user.Data.Avatar}&Thumb=true`,
                                  )
                                : avatar
                            }
                            text={'Your Location'}
                            lat={userGPS.Latitude}
                            lng={userGPS.Longitude}
                            type='person'
                          />
                        )}
                      </GoogleMap>
                    </div>
                  </div>
                )}
                {ID && advanced !== 'Y' && !simpleAction && (
                  <div className='w-full pb-24'>
                    <Typography className='flex items-center flex-1 mb-24 font-bold text-12'>{`${'Select an Action:'}`}</Typography>
                    <div className='w-full sm:flex'>
                      <Button
                        variant='contained'
                        color='primary'
                        className='w-full mb-20'
                        onClick={() => {
                          const { Data } = this.state
                          const { Tasks } = Data
                          //const currentRecurrences = _.filter(Tasks, (o) => o.Data && o.Data.Recurrences ? o.Data.Recurrences.length > 0 && o.TaskType !== "PMA Camera" : null)
                          const currentRecurrences = _.filter(Tasks, o =>
                            o.Data && o.Data.Recurrences
                              ? o.Data.Recurrences.length < 1 &&
                                o.Frequency !== '*'
                              : null,
                          )
                          // window['warn'](
                          //   'agr state yyyttt',
                          //   this.state,
                          //   currentRecurrences,
                          //   agreementTasks,
                          //   Tasks,
                          // )

                          if (currentRecurrences.length > 0) {
                            //if(agreementTasks.length < currentRecurrences2.length){
                            this.setState({
                              ...this.state,
                              simpleAction: 'revise',
                              simpleStep: 0,
                              needsRevision: true,
                              value: 3,
                              reviseNoRecurrences: true,
                            })
                          } else {
                            this.setState({
                              ...this.state,
                              simpleAction: 'revise',
                              simpleStep: 0,
                            })
                          }
                        }}
                        disabled={this.state.newCycle}
                      >
                        <Icon className='mr-8'>edit</Icon>Revise
                      </Button>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Button
                        variant='contained'
                        color='primary'
                        className='w-full mb-20'
                        onClick={() => {
                          this.setState(
                            {
                              ...this.state,
                              simpleAction: 'renew',
                              simpleStep: 1,
                            },
                            this.newCycle,
                          )
                        }}
                      >
                        <Icon className='mr-8'>refresh</Icon>Renew
                      </Button>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Button
                        variant='contained'
                        color='primary'
                        className='w-full mb-20'
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            simpleAction: 'tasks',
                            simpleStep: 3,
                          })
                        }}
                        disabled={this.state.newCycle}
                      >
                        <Icon className='mr-8'>list</Icon>Add Tasks
                      </Button>
                    </div>
                    <div className='w-full pin-b pin-l pin-r absolute p-8'>
                      <FormControlLabel
                        className='h-32'
                        control={
                          <Switch
                            checked={this.state.advanced === 'Y'}
                            name='advanced'
                            onChange={this.handleChange}
                            color='primary'
                          />
                        }
                        label='Advanced'
                      />
                    </div>
                  </div>
                )}
                {advanced !== 'Y' && (!ID || simpleAction) && (
                  <div className='w-full pb-24'>
                    <Typography className='flex items-center flex-1 mb-24 font-bold text-12'>{`${
                      simpleStep + 1
                    }. ${steps[simpleStep]}`}</Typography>
                    {simpleStep === 0 && (
                      <React.Fragment>
                        <Autocomplete
                          className={classes.formControl}
                          title='Business Unit'
                          options={businessUnits}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.BusinessUnit}>
                                {value.Description}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          value={this.state.BusinessUnit || ''}
                          onSelect={option => {
                            if (
                              option.BusinessUnit !== this.state.BusinessUnit
                            ) {
                              this.setState({
                                ...this.state,
                                BusinessUnit: option.BusinessUnit,
                                Division: null,
                                AgreementType: null,
                              })
                            }
                          }}
                          required
                          activeOnly={true}
                          error={
                            this.state.trySubmit && !this.state.BusinessUnit
                          }
                          disabled={
                            this.state.ID ||
                            (this.state.taskWorkOrder &&
                              this.state.BusinessUnit)
                          }
                        />
                        {/* <TextField
                                                    className={classes.formControl}
                                                    label="Business Unit"
                                                    id="BusinessUnit"
                                                    name="BusinessUnit"
                                                    value={this.state.BusinessUnit}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    select
                                                    fullWidth
                                                    required
                                                    disabled={this.state.ID || (this.state.taskWorkOrder && this.state.BusinessUnit)}
                                                >
                                                    {businessUnits && businessUnits.map((value, index) => (
                                                        <MenuItem key={index} value={value.BusinessUnit}>
                                                            {value.Description}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                        {/* <TextField
                                                    className={classes.formControl}
                                                    label="Division"
                                                    id="Division"
                                                    name="Division"
                                                    value={this.state.Division || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    select
                                                    fullWidth
                                                    required
                                                    disabled={this.state.ID || (this.state.taskWorkOrder && this.state.Division)}
                                                >
                                                    {
                                                        this.state.BusinessUnit ?
                                                            (divisions && divisions.length > 0 ? divisions.map((value, index) => (
                                                                value.BusinessUnit === this.state.BusinessUnit ?
                                                                    <MenuItem key={index} value={value.Division}>
                                                                        {`${value.Division} - ${value.Description}`}
                                                                    </MenuItem> : ''
                                                            )) : '')
                                                            :
                                                            (<MenuItem>
                                                                Please Select a Business Unit
                                                            </MenuItem>)
                                                    }
                                                </TextField> */}
                        <Autocomplete
                          className={classes.formControl}
                          title='Division'
                          options={
                            divisions
                              ? _.filter(divisions, {
                                  BusinessUnit: this.state.BusinessUnit,
                                })
                              : []
                          }
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.Division}>
                                {value.Description}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          value={this.state.Division || ''}
                          onSelect={option => {
                            if (option.Division !== this.state.Division) {
                              this.setState({
                                ...this.state,
                                Division: option.Division,
                                AgreementType: null,
                              })
                            }
                          }}
                          required
                          activeOnly={true}
                          error={this.state.trySubmit && !this.state.Division}
                          disabled={
                            this.state.ID ||
                            (this.state.taskWorkOrder && this.state.Division)
                          }
                        />
                        <TextField
                          className={classes.formControl}
                          label='Agreement Type'
                          id='agreementType'
                          name='AgreementType'
                          value={this.state.AgreementType || ''}
                          InputLabelProps={{
                            shrink: this.state.AgreementType ? true : false,
                          }}
                          onChange={this.handleTypeChange}
                          variant='outlined'
                          fullWidth
                          select
                          required
                          disabled={this.state.ID}
                        >
                          {this.state.BusinessUnit ? (
                            this.state.Division ? (
                              agreementTypes &&
                              agreementTypes.length > 0 &&
                              agreementTypes.map(
                                (value, index) =>
                                  value.BusinessUnit ===
                                    this.state.BusinessUnit &&
                                  value.Division === this.state.Division &&
                                  value.ActiveYN === 'Y' && (
                                    <MenuItem
                                      key={index}
                                      value={value.AgreementType}
                                    >
                                      {value.Name}
                                    </MenuItem>
                                  ),
                              )
                            ) : (
                              <MenuItem>Please Select a Division</MenuItem>
                            )
                          ) : (
                            <MenuItem>Please Select a Business Unit</MenuItem>
                          )}
                        </TextField>
                        <div className='hidden sm:block min-w-12'></div>
                        {this.props.agreementDialog.type === 'edit' ? (
                          <Autocomplete
                            className={classes.formControl}
                            title='Scope'
                            options={[...FilteredScopeNew]}
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.Scope}>
                                  <div className='flex'>{`${value.Scope}`}</div>
                                </MenuItem>
                              )
                            }}
                            noflip={true}
                            portal={!matches}
                            required
                            value={this.state.Scope || ''}
                            disabled={false}
                            onSelect={option => {
                              this.setState({
                                ...this.state,
                                Scope: option.Scope,
                              })
                              window['warn'](this.state, option)
                            }}
                          />
                        ) : null}
                      </React.Fragment>
                    )}
                    {simpleStep === 1 && (
                      <React.Fragment>
                        <TextField
                          className={classNames(classes.formControl, '')}
                          label='Length of Agreement'
                          id='termLength'
                          name='TermLength'
                          value={this.state.TermLength}
                          onChange={this.handleTermLengthChange}
                          variant='outlined'
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                Year
                                {!this.state.TermLength ||
                                this.state.TermLength > 1
                                  ? 's'
                                  : ''}
                              </InputAdornment>
                            ),
                            type: 'number',
                          }}
                          disabled={
                            agreementType &&
                            agreementType.TermLength &&
                            agreementType.TermLength > 0
                          }
                          required
                        />
                        <DatePicker
                          inputVariant='outlined'
                          className={classNames(classes.formControl)}
                          label='Start Date'
                          format='MM/DD/YYYY'
                          id='startDate'
                          name='StartDate'
                          value={this.state.StartDate}
                          InputLabelProps={{
                            shrink: this.state.StartDate ? true : false,
                          }}
                          onChange={this.handleStartDateChange}
                          fullWidth
                          autoOk
                          required
                        />
                        <DatePicker
                          inputVariant='outlined'
                          className={classNames(classes.formControl)}
                          label='End Date'
                          format='MM/DD/YYYY'
                          id='endDate'
                          name='EndDate'
                          value={this.state.EndDate}
                          InputLabelProps={{
                            shrink: this.state.EndDate ? true : false,
                          }}
                          onChange={this.handleEndDateChange}
                          variant='outlined'
                          fullWidth
                          autoOk
                        />
                        <TextField
                          className={classNames(classes.formControl, '')}
                          label='Billing Frequency'
                          id='billingFrequency'
                          name='BillingFrequency'
                          value={
                            agreementType &&
                            agreementType.BillingFrequency === '*'
                              ? this.state.BillingFrequency || ''
                              : (agreementType || {}).BillingFrequency || ''
                          }
                          onChange={this.handleChange}
                          variant='outlined'
                          select
                          fullWidth
                          disabled={
                            agreementType &&
                            agreementType.BillingFrequency !== '*'
                          }
                          required
                        >
                          <MenuItem value='O'>Once</MenuItem>
                          <MenuItem value='M'>Monthly</MenuItem>
                          <MenuItem value='B'>Bi-Monthly</MenuItem>
                          <MenuItem value='Q'>Quarterly</MenuItem>
                          <MenuItem value='S'>Semi-Annual</MenuItem>
                          <MenuItem value='A'>Annual</MenuItem>
                        </TextField>
                        {agreementType &&
                          ['A', 'S', 'Q', 'B', 'M'].indexOf(
                            this.state.BillingFrequency ||
                              agreementType.BillingFrequency,
                          ) > -1 && (
                            <div
                              className={classNames(
                                classes.formControl,
                                (this.state.BillingFrequency ||
                                  agreementType.BillingFrequency) === 'M'
                                  ? 'hidden'
                                  : '',
                              )}
                            >
                              <div className='w-full' style={{ marginTop: -8 }}>
                                <div className='mt-8 relative'>
                                  <label
                                    className='text-12 p-4 px-4 ml-8 absolute pin-t'
                                    style={{
                                      background: '#fff',
                                      color: '#9f9f9f',
                                      marginTop: -12,
                                    }}
                                  >
                                    {billingFrequencies[
                                      agreementType.BillingFrequency
                                    ] &&
                                      billingFrequencies[
                                        agreementType.BillingFrequency
                                      ]}
                                    Billing Schedule
                                  </label>
                                  <div
                                    className='rounded-4 p-16 px-8 mb-12 overflow-auto'
                                    style={{
                                      border: '1px solid rgba(0, 0, 0, .25)',
                                    }}
                                  >
                                    <Recurrence
                                      term={
                                        this.state.TermLength ||
                                        AgreementType.TermLength
                                      }
                                      onChange={this.setBillingSchedule}
                                      frequency={
                                        this.state.BillingFrequency ||
                                        agreementType.BillingFrequency
                                      }
                                      data={Data.BillingSchedule}
                                      value='DueDate'
                                      disabled={
                                        Boolean(this.state.ID) &&
                                        !this.state.needsRevision &&
                                        !this.state.newCycle
                                      }
                                      start={this.state.StartDate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    )}
                    {simpleStep === 2 && (
                      <React.Fragment>
                        <FormControlLabel
                          className='h-24 mr-0 float-right'
                          classes={{ label: 'text-12' }}
                          style={{ marginTop: -44 }}
                          control={
                            <Switch
                              checked={this.state.MultiSiteYN === 'Y'}
                              name='MultiSiteYN'
                              onChange={e => {
                                this.setState(
                                  {
                                    ...this.state,
                                    MultiSiteYN: e.target.checked ? 'Y' : 'N',
                                  },
                                  () => this.handleTypeChange(),
                                )
                              }}
                              color='primary'
                            />
                          }
                          label='Multi-Site'
                        />
                        {this.state.Data.Customer ? (
                          <TextField
                            className={classNames(classes.formControl, '')}
                            label='Customer'
                            id='Customer'
                            name='Customer'
                            value={this.state.Customer}
                            onChange={e => {
                              if (e.target.value) {
                                this.handleChange(e)
                              } else {
                                this.setState({
                                  ...this.state,
                                  Customer: null,
                                  Site: null,
                                  Data: {
                                    ...this.state.Data,
                                    Customer: null,
                                    Site: null,
                                  },
                                })
                              }
                            }}
                            InputLabelProps={{
                              shrink: this.state.Customer ? true : false,
                            }}
                            fullWidth
                            variant='outlined'
                            disabled={this.state.ID}
                            required
                            select
                          >
                            <MenuItem value={this.state.Customer}>
                              {`${this.state.Data.Customer.Customer} - ${
                                this.state.Data.Customer.Name ||
                                `${this.state.Data.Customer.FirstName} ${this.state.Data.Customer.LastName}`
                              }`}
                            </MenuItem>
                            <MenuItem value={null}>
                              <Icon className='mr-4'>search</Icon>Search
                            </MenuItem>
                          </TextField>
                        ) : (
                          <CustomerSearchBar
                            autoFocus={true}
                            onSelected={this.handleCustomerSelect}
                          />
                        )}
                        {this.state.MultiSiteYN === 'Y' && (
                          <Typography className='flex items-center flex-1 mb-8 font-bold text-12'>
                            <Icon
                              color='primary'
                              className='text-16 mr-4 align-middle mb-4'
                            >
                              location_on
                            </Icon>
                            Included Sites
                          </Typography>
                        )}
                        {this.state.Data.Customer && (
                          <div className='w-full'>
                            <TextField
                              className='mb-8'
                              label='Site'
                              id='Site'
                              name='Site'
                              value={this.state.Site || ''}
                              onChange={this.handleSiteChange}
                              // InputLabelProps={{ shrink: this.state.Site ? true : false }}
                              fullWidth
                              variant='outlined'
                              disabled={this.state.ID}
                              select
                              required
                            >
                              {this.state.Data.Site &&
                                (!this.state.Data.Customer.Data ||
                                  !this.state.Data.Customer.Data.Sites ||
                                  _.findIndex(
                                    this.state.Data.Customer.Data.Sites,
                                    {
                                      Co: this.state.Co,
                                      Customer: this.state.Customer,
                                      Site: this.state.Site,
                                    },
                                  ) < 0) && (
                                  <MenuItem value={this.state.Data.Site.Site}>
                                    {`${this.state.Data.Site.Site} - ${this.state.Data.Site.Description}`}
                                  </MenuItem>
                                )}
                              {this.state.Data.Customer &&
                                this.state.Data.Customer.Data &&
                                this.state.Data.Customer.Data.Sites &&
                                this.state.Data.Customer.Data.Sites.map(
                                  site => {
                                    return (
                                      <MenuItem value={site.Site}>
                                        {`${site.Site} - ${site.Description}`}
                                      </MenuItem>
                                    )
                                  },
                                )}
                            </TextField>
                            {this.state.MultiSiteYN === 'Y' && (
                              <Button
                                variant='contained'
                                color={
                                  this.state.Data.Sites &&
                                  _.findIndex(this.state.Data.Sites, {
                                    Site: this.state.Site,
                                  }) > -1
                                    ? 'error'
                                    : 'primary'
                                }
                                className={classNames(
                                  'w-full mb-8 ml-0',
                                  classes.blackButton,
                                )}
                                onClick={() => {
                                  let { Sites } = this.state.Data
                                  if (!Sites) {
                                    Sites = []
                                  }
                                  const index = _.findIndex(Sites, {
                                    Site: this.state.Site,
                                  })
                                  if (index > -1) {
                                    Sites.splice(index, 1)
                                    this.setState(
                                      {
                                        ...this.state,
                                        Site: null,
                                        Data: { ...this.state.Data, Sites },
                                      },
                                      () => this.handleTypeChange(),
                                    )
                                  } else {
                                    Sites.push({
                                      Co,
                                      Customer,
                                      BusinessUnit,
                                      Division,
                                      Agreement,
                                      Cycle,
                                      Revision,
                                      Site: this.state.Site,
                                    })
                                    this.setState(
                                      {
                                        ...this.state,
                                        Site: null,
                                        Data: { ...this.state.Data, Sites },
                                      },
                                      () => this.handleTypeChange(),
                                    )
                                  }
                                }}
                                disabled={!this.state.Site}
                              >
                                {this.state.Data.Sites &&
                                _.findIndex(this.state.Data.Sites, {
                                  Site: this.state.Site,
                                }) > -1 ? (
                                  <Icon color='error' className='mr-4'>
                                    remove_circle_outline
                                  </Icon>
                                ) : (
                                  <Icon
                                    color={
                                      this.state.Site ? 'secondary' : 'disabled'
                                    }
                                    className='mr-4'
                                  >
                                    add_circle_outline
                                  </Icon>
                                )}
                                {this.state.Data.Sites &&
                                _.findIndex(this.state.Data.Sites, {
                                  Site: this.state.Site,
                                }) > -1
                                  ? 'Remove Site'
                                  : 'Add Site'}
                              </Button>
                            )}
                          </div>
                        )}
                        {this.state.MultiSiteYN === 'Y' && (
                          <div className='w-full'>
                            {anchorUnit && anchorUnitEl && (
                              <Menu
                                id='unit-menu'
                                anchorEl={anchorUnitEl}
                                open={Boolean(anchorUnit)}
                                onClose={() =>
                                  this.setState({
                                    anchorUnit: null,
                                    anchorUnitEl: null,
                                    anchorUnitTask: null,
                                  })
                                }
                              >
                                <MenuItem
                                  onClick={() => {
                                    openEditServiceUnitDialog(anchorUnit)
                                    this.setState({
                                      anchorUnit: null,
                                      anchorUnitEl: null,
                                      anchorUnitTask: null,
                                    })
                                  }}
                                >
                                  <Icon className='mr-8'>edit</Icon>Edit Service
                                  Unit
                                </MenuItem>
                                {!exclusions[anchorUnit.UnitID] && (
                                  <MenuItem
                                    onClick={() => {
                                      exclusions[anchorUnit.UnitID] = '*'
                                      this.setState(
                                        {
                                          anchorUnit: null,
                                          anchorUnitEl: null,
                                          anchorUnitTask: null,
                                          exclusions,
                                        },
                                        () => this.handleTypeChange(),
                                      )
                                    }}
                                  >
                                    <Icon className='mr-8'>
                                      remove_circle_outline
                                    </Icon>
                                    Exclude Service Unit
                                  </MenuItem>
                                )}
                                {exclusions[anchorUnit.UnitID] && (
                                  <MenuItem
                                    onClick={() => {
                                      delete exclusions[anchorUnit.UnitID]
                                      this.setState(
                                        {
                                          anchorUnit: null,
                                          anchorUnitEl: null,
                                          anchorUnitTask: null,
                                          exclusions,
                                        },
                                        () => this.handleTypeChange(),
                                      )
                                    }}
                                  >
                                    <Icon className='mr-8'>
                                      add_circle_outline
                                    </Icon>
                                    Include Service Unit
                                  </MenuItem>
                                )}
                              </Menu>
                            )}
                            {this.state.Data.Sites &&
                              this.state.Data.Sites.map(st => {
                                const allSites =
                                  this.state.Data.Customer.Data.Sites
                                if (
                                  this.state.Data.Site &&
                                  _.findIndex(allSites, { Site: st.Site }) < 0
                                ) {
                                  allSites.push(this.state.Data.Site)
                                }
                                // window["warn"]('Agreement Sites: ', this.state.Data.Sites, this.state.Data.Customer.Data.Sites);
                                const site = _.find(allSites, { Site: st.Site })
                                if (site) {
                                  return (
                                    <div
                                      className='w-full mb-8 p-12 rounded'
                                      style={{
                                        backgroundColor: 'rgb(235, 235, 235)',
                                      }}
                                    >
                                      <Typography
                                        onClick={() =>
                                          this.setState({
                                            ...this.state,
                                            Site: site.Site,
                                          })
                                        }
                                        className='flex items-center flex-1 mb-8 font-bold text-12 cursor-pointer truncate'
                                      >
                                        <Icon
                                          color='primary'
                                          className='text-16 mr-4 align-middle mb-4'
                                        >
                                          location_on
                                        </Icon>
                                        {`${site.Site} - ${site.Description}`}
                                      </Typography>
                                      <React.Fragment>
                                        {site &&
                                          site.Data &&
                                          site.Data.Units.map((unit, index) => {
                                            const { Co } = unit
                                            const len = site.Data.Units.length
                                            return (
                                              <Card
                                                className={classes.taskCard}
                                                key={index}
                                              >
                                                <CardHeader
                                                  key={index}
                                                  className={
                                                    classes.taskCardUnit
                                                  }
                                                  classes={{
                                                    action: 'sm:mt-0 mr-0',
                                                    root: 'p-8',
                                                    avatar: 'mr-8',
                                                    title:
                                                      'leading-none truncate',
                                                    subheader: 'leading-none',
                                                  }}
                                                  avatar={
                                                    <img
                                                      className={classNames(
                                                        classes.unitAvatar,
                                                        'w-32 h-auto mt-4',
                                                      )}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      alt='Service Unit Image'
                                                      src={
                                                        unit.Data &&
                                                        unit.Data.Avatar
                                                          ? !isNaN(
                                                              unit.Data.Avatar,
                                                            )
                                                            ? getProfileImage(
                                                                `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                              )
                                                            : unit.Data.Avatar
                                                          : unit.avatar
                                                            ? unit.avatar
                                                            : avatar
                                                      }
                                                    />
                                                  }
                                                  title={
                                                    <label className='text-12 font-bold'>
                                                      {unit.Name}
                                                    </label>
                                                  }
                                                  subheader={
                                                    <label className='text-10'>
                                                      {unit.UnitID}
                                                      {unit.ActiveYN ===
                                                        'N' && (
                                                        <label className='text-10 rounded bg-red p-0 font-bold text-white px-6 ml-6'>
                                                          Inactive
                                                        </label>
                                                      )}
                                                      {exclusions[
                                                        unit.UnitID
                                                      ] && (
                                                        <label className='text-10 rounded bg-orange p-0 font-bold text-white px-6 ml-6'>
                                                          Excluded
                                                        </label>
                                                      )}
                                                    </label>
                                                  }
                                                  action={
                                                    accessLevel &&
                                                    accessLevel.AccessLevel !==
                                                      'R' && (
                                                      <IconButton
                                                        onClick={e =>
                                                          this.setState({
                                                            anchorUnit: unit,
                                                            anchorUnitEl:
                                                              e.target,
                                                          })
                                                        }
                                                        aria-label='edit'
                                                      >
                                                        <Icon className='text-16'>
                                                          more_vert
                                                        </Icon>
                                                      </IconButton>
                                                    )
                                                  }
                                                />
                                              </Card>
                                            )
                                          })}
                                        {accessLevel &&
                                          accessLevel.AccessLevel !== 'R' && (
                                            <CardHeader
                                              onClick={() =>
                                                openNewServiceUnitDialog({
                                                  Co,
                                                  Customer: site.Customer,
                                                  Site: site.Site,
                                                })
                                              }
                                              className='cursor-pointer p-0'
                                              classes={{
                                                avatar: 'pt-6 mx-8 mr-4',
                                                action: 'mt-0',
                                              }}
                                              avatar={
                                                <Icon
                                                  className='text-16'
                                                  color='primary'
                                                >
                                                  add_circle_outline
                                                </Icon>
                                              }
                                              title={
                                                <label className='text-12 font-bold cursor-pointer'>
                                                  Add Service Unit
                                                </label>
                                              }
                                            />
                                          )}
                                      </React.Fragment>
                                    </div>
                                  )
                                }
                              })}
                          </div>
                        )}
                        {this.state.MultiSiteYN !== 'Y' && Site && (
                          <div
                            className='w-full mb-8 p-12 rounded'
                            style={{ backgroundColor: 'rgb(235, 235, 235)' }}
                          >
                            {anchorUnit && anchorUnitEl && (
                              <Menu
                                id='unit-menu'
                                anchorEl={anchorUnitEl}
                                open={Boolean(anchorUnit)}
                                onClose={() =>
                                  this.setState({
                                    anchorUnit: null,
                                    anchorUnitEl: null,
                                    anchorUnitTask: null,
                                  })
                                }
                              >
                                <MenuItem
                                  onClick={() => {
                                    openEditServiceUnitDialog(anchorUnit)
                                    this.setState({
                                      anchorUnit: null,
                                      anchorUnitEl: null,
                                      anchorUnitTask: null,
                                    })
                                  }}
                                >
                                  <Icon className='mr-8'>edit</Icon>Edit Service
                                  Unit
                                </MenuItem>
                                {!exclusions[anchorUnit.UnitID] && (
                                  <MenuItem
                                    onClick={() => {
                                      exclusions[anchorUnit.UnitID] = '*'
                                      this.setState(
                                        {
                                          anchorUnit: null,
                                          anchorUnitEl: null,
                                          anchorUnitTask: null,
                                          exclusions,
                                        },
                                        () => this.handleTypeChange(),
                                      )
                                    }}
                                  >
                                    <Icon className='mr-8'>
                                      remove_circle_outline
                                    </Icon>
                                    Exclude Service Unit
                                  </MenuItem>
                                )}
                                {exclusions[anchorUnit.UnitID] && (
                                  <MenuItem
                                    onClick={() => {
                                      let inclusions = {}
                                      inclusions[anchorUnit.UnitID] =
                                        exclusions[anchorUnit.UnitID]
                                      delete exclusions[anchorUnit.UnitID]
                                      this.setState(
                                        {
                                          anchorUnit: null,
                                          anchorUnitEl: null,
                                          anchorUnitTask: null,
                                          exclusions,
                                          inclusions,
                                        },
                                        () => this.handleTypeChange(),
                                      )
                                    }}
                                  >
                                    <Icon className='mr-8'>
                                      add_circle_outline
                                    </Icon>
                                    Include Service Unit
                                  </MenuItem>
                                )}
                              </Menu>
                            )}
                            <React.Fragment>
                              {this.state.Data.Site &&
                                this.state.Data.Site.Data &&
                                this.state.Data.Site.Data.Units.map(
                                  (unit, index) => {
                                    const { Co } = unit
                                    const len =
                                      this.state.Data.Site.Data.Units.length
                                    return (
                                      <Card
                                        className={classes.taskCard}
                                        key={index}
                                      >
                                        <CardHeader
                                          key={index}
                                          className={classes.taskCardUnit}
                                          classes={{
                                            action: 'sm:mt-0 mr-0',
                                            root: 'p-8',
                                            avatar: 'mr-8',
                                            title: 'leading-none',
                                            subheader: 'leading-none',
                                          }}
                                          avatar={
                                            <img
                                              className={classNames(
                                                classes.unitAvatar,
                                                'w-32 h-auto mt-4',
                                              )}
                                              style={{ cursor: 'pointer' }}
                                              alt='Service Unit Image'
                                              src={
                                                unit.Data && unit.Data.Avatar
                                                  ? !isNaN(unit.Data.Avatar)
                                                    ? getProfileImage(
                                                        `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                      )
                                                    : unit.Data.Avatar
                                                  : unit.avatar
                                                    ? unit.avatar
                                                    : avatar
                                              }
                                            />
                                          }
                                          title={
                                            <label className='text-12 font-bold'>
                                              {unit.Name}
                                            </label>
                                          }
                                          subheader={
                                            <label className='text-10'>
                                              {unit.UnitID}
                                              {unit.ActiveYN === 'N' && (
                                                <label className='text-10 rounded bg-red p-0 font-bold text-white px-6 ml-6'>
                                                  Inactive
                                                </label>
                                              )}
                                              {exclusions[unit.UnitID] && (
                                                <label className='text-10 rounded bg-orange p-0 font-bold text-white px-6 ml-6'>
                                                  Excluded
                                                </label>
                                              )}
                                            </label>
                                          }
                                          action={
                                            accessLevel &&
                                            accessLevel.AccessLevel !== 'R' && (
                                              <IconButton
                                                onClick={e =>
                                                  this.setState({
                                                    anchorUnit: unit,
                                                    anchorUnitEl: e.target,
                                                  })
                                                }
                                                aria-label='edit'
                                              >
                                                <Icon className='text-16'>
                                                  more_vert
                                                </Icon>
                                              </IconButton>
                                            )
                                          }
                                        />
                                      </Card>
                                    )
                                  },
                                )}
                              {accessLevel &&
                                accessLevel.AccessLevel !== 'R' && (
                                  <CardHeader
                                    onClick={() =>
                                      openNewServiceUnitDialog({
                                        Co,
                                        Customer: Site.Customer,
                                        Site: Site.Site,
                                      })
                                    }
                                    className='cursor-pointer p-0'
                                    classes={{
                                      avatar: 'pt-6 mx-8 mr-4',
                                      action: 'mt-0',
                                    }}
                                    avatar={
                                      <Icon className='text-16' color='primary'>
                                        add_circle_outline
                                      </Icon>
                                    }
                                    title={
                                      <label className='text-12 font-bold cursor-pointer'>
                                        Add Service Unit
                                      </label>
                                    }
                                  />
                                )}
                            </React.Fragment>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {simpleStep === 3 && simpleAction === 'tasks' && (
                      <React.Fragment>
                        <TextField
                          className='w-full mb-12 rounded-lg'
                          label='Assign to Work Order'
                          id='taskWorkOrder'
                          name='taskWorkOrder'
                          value={this.state.taskWorkOrder}
                          onChange={e => {
                            const wo = e.target.value
                            workOrderTasks.forEach(tsk => {
                              tsk.WorkOrder = wo
                            })
                            this.setState({ taskWorkOrder: wo, workOrderTasks })
                          }}
                          InputLabelProps={{
                            shrink: this.state.taskWorkOrder ? true : false,
                          }}
                          InputProps={{ type: 'number' }}
                          fullWidth
                          variant='outlined'
                          select
                          required
                        >
                          {woAccessLevel &&
                            woAccessLevel.AccessLevel !== 'R' && (
                              <MenuItem value={'+'}>
                                <Typography className='flex items-center flex-1'>
                                  <Icon className='mr-8'>add</Icon>Create New
                                  Work Order
                                </Typography>
                              </MenuItem>
                            )}
                          {!taskSite &&
                            this.state.Data &&
                            this.state.Data.Site &&
                            this.state.Data.Site.Data &&
                            this.state.Data.Site.Data.WorkOrders &&
                            this.state.Data.Site.Data.WorkOrders.map(
                              (value, index) => {
                                return (
                                  <MenuItem key={index} value={value.WorkOrder}>
                                    <Typography className='truncate'>{`${
                                      value.WorkOrder
                                    } - ${
                                      value.Description || ''
                                    }`}</Typography>
                                  </MenuItem>
                                )
                              },
                            )}
                          {taskSite &&
                            taskSite.Data &&
                            taskSite.Data.WorkOrders &&
                            taskSite.Data.WorkOrders.map((value, index) => {
                              return (
                                <MenuItem key={index} value={value.WorkOrder}>
                                  <Typography className='truncate'>{`${
                                    value.WorkOrder
                                  } - ${value.Description || ''}`}</Typography>
                                </MenuItem>
                              )
                            })}
                        </TextField>
                        <DebounceButton
                          buttonText={`Assign to Work Order ${
                            workOrderTasks.length > 0
                              ? `(${workOrderTasks.length} Task${
                                  workOrderTasks.length > 1 ? 's' : ''
                                })`
                              : ''
                          }`}
                          processingText={`Assigning to Work Order ${
                            workOrderTasks.length > 0
                              ? `(${workOrderTasks.length} Task${
                                  workOrderTasks.length > 1 ? 's' : ''
                                })`
                              : ''
                          }`}
                          variant={'contained'}
                          color={'primary'}
                          className='w-full mb-12'
                          clickFxn={() => {
                            window['log']('Selected WO Tasks: ', workOrderTasks)
                            const { Co, Customer, Site, Agreement, Cycle } =
                              this.state
                            const val = {
                              Co,
                              Customer,
                              Site,
                              Agreement,
                              Cycle,
                              WorkOrder: taskWorkOrder,
                              CreateNew: Boolean(taskWorkOrder === '+'),
                              Tasks: workOrderTasks,
                            }
                            return Promise.all([
                              this.props.assignAgreementTasks(val),
                            ]).then(() => {
                              this.closeComposeDialog()
                            })
                          }}
                          debounceInterval={1500}
                          isDisabled={
                            !this.state.taskWorkOrder ||
                            workOrderTasks.length < 1 ||
                            _.filter(
                              workOrderTasks,
                              o =>
                                o.Scope === '+' &&
                                (!o.DueBy || !o.ArrivalWindow),
                            ).length > 0
                          }
                        />
                      </React.Fragment>
                    )}
                    {simpleStep === 3 && (
                      <div
                        className='w-full mb-24 p-12 rounded'
                        style={{ backgroundColor: 'rgb(235, 235, 235)' }}
                      >
                        {accessLevel && accessLevel.AccessLevel !== 'R' && (
                          <Menu
                            id='task-menu'
                            anchorEl={taskMenu}
                            keepMounted
                            open={Boolean(taskMenu)}
                            onClose={this.handleTaskMenuClose}
                          >
                            {/* <MenuItem onClick={this.scheduleTask}><Icon className="mr-8">assignment</Icon>Schedule Task</MenuItem> */}
                            <MenuItem onClick={this.waiveTask}>
                              <Icon className='mr-8'>event_busy</Icon>Waive Task
                              Sequence
                            </MenuItem>
                          </Menu>
                        )}
                        {anchorUnit && anchorUnitEl && anchorUnitTask && (
                          <Menu
                            id='unit-menu'
                            anchorEl={anchorUnitEl}
                            open={Boolean(anchorUnit)}
                            onClose={() =>
                              this.setState({
                                anchorUnit: null,
                                anchorUnitEl: null,
                                anchorUnitTask: null,
                              })
                            }
                          >
                            <MenuItem
                              onClick={() => {
                                openEditServiceUnitDialog(anchorUnit)
                                this.setState({
                                  anchorUnit: null,
                                  anchorUnitEl: null,
                                  anchorUnitTask: null,
                                })
                              }}
                            >
                              <Icon className='mr-8'>edit</Icon>Edit Service
                              Unit
                            </MenuItem>
                            {(!exclusions[anchorUnit.UnitID] ||
                              (exclusions[anchorUnit.UnitID] !== '*' &&
                                !exclusions[anchorUnit.UnitID][
                                  anchorUnitTask.TaskType
                                ])) &&
                              simpleAction !== 'tasks' && (
                                <MenuItem
                                  onClick={() => {
                                    if (!exclusions[anchorUnit.UnitID]) {
                                      exclusions[anchorUnit.UnitID] = {}
                                    }
                                    exclusions[anchorUnit.UnitID][
                                      anchorUnitTask.TaskType
                                    ] = 'N'
                                    this.setState(
                                      {
                                        anchorUnit: null,
                                        anchorUnitEl: null,
                                        anchorUnitTask: null,
                                        exclusions,
                                      },
                                      () => {
                                        // this.updateTaskFrequency(anchorUnitTask.TaskType, anchorUnitTask.Frequency);
                                        this.handleTypeChange()
                                      },
                                    )
                                  }}
                                >
                                  <Icon className='mr-8'>
                                    remove_circle_outline
                                  </Icon>
                                  Exclude From Task
                                </MenuItem>
                              )}
                          </Menu>
                        )}
                        {accessLevel &&
                          accessLevel.AccessLevel !== 'R' &&
                          Boolean(taskWaiveMenu) &&
                          taskInfo && (
                            <Menu
                              id='task-waive-menu'
                              anchorEl={taskWaiveMenu}
                              keepMounted
                              open={Boolean(taskWaiveMenu)}
                              onClose={this.handleTaskWaiveMenuClose}
                            >
                              <div className='p-12 min-w-320 max-w-320'>
                                <Typography className='flex items-center flex-1 font-bold mb-12'>
                                  <Icon className='mr-8' color='primary'>
                                    assignment
                                  </Icon>
                                  Waive Task
                                </Typography>
                                <TextField
                                  className='w-full mb-12'
                                  label='Task Sequence'
                                  id='taskSeq'
                                  name='taskSeq'
                                  value={this.state.taskSeq || ''}
                                  onChange={this.handleChange}
                                  InputProps={{ type: 'number' }}
                                  fullWidth
                                  variant='outlined'
                                  required
                                  select
                                >
                                  {this.state.taskIncluded &&
                                    this.state.taskIncluded.Data &&
                                    this.state.taskIncluded.Data.Recurrences &&
                                    this.state.taskIncluded.Data.Recurrences.map(
                                      rec => {
                                        // window["warn"]('Task Option: ', rec);
                                        const count =
                                          this.state.taskIncluded.Data
                                            .Recurrences.length
                                        return (
                                          <MenuItem
                                            key={rec.Seq}
                                            value={rec.Seq}
                                            disabled={Boolean(
                                              _.findIndex(
                                                this.state.taskIncluded.Data
                                                  .Waive,
                                                {
                                                  Site:
                                                    MultiSiteYN === 'Y'
                                                      ? taskSite.Site
                                                      : this.state.Site,
                                                  Seq: rec.Seq,
                                                },
                                              ) > -1,
                                            )}
                                          >
                                            {`Service #${rec.Seq} of ${count}`}
                                          </MenuItem>
                                        )
                                      },
                                    )}
                                </TextField>
                                <Button
                                  variant='contained'
                                  color='primary'
                                  className='w-full'
                                  onClick={this.addWaivedTask}
                                  disabled={!this.state.taskSeq}
                                >
                                  Waive
                                </Button>
                              </div>
                            </Menu>
                          )}
                        {accessLevel &&
                          accessLevel.AccessLevel !== 'R' &&
                          Boolean(taskScheduleMenu) &&
                          taskInfo && (
                            <Menu
                              id='task-schedule-menu'
                              anchorEl={taskScheduleMenu}
                              keepMounted
                              open={Boolean(taskScheduleMenu)}
                              onClose={this.handleTaskScheduleMenuClose}
                            >
                              <div className='p-12 min-w-320 max-w-320'>
                                <Typography className='flex items-center flex-1 font-bold mb-12'>
                                  <Icon className='mr-8' color='primary'>
                                    assignment
                                  </Icon>
                                  Assign to Work Order
                                </Typography>
                                <TextField
                                  className='w-full mb-12'
                                  label='Task Sequence'
                                  id='taskSeq'
                                  name='taskSeq'
                                  value={this.state.taskSeq || ''}
                                  onChange={this.handleChange}
                                  InputProps={{ type: 'number' }}
                                  fullWidth
                                  variant='outlined'
                                  required
                                  select
                                >
                                  {this.state.taskIncluded &&
                                    this.state.taskIncluded.Data &&
                                    this.state.taskIncluded.Data.Recurrences &&
                                    _.filter(
                                      this.state.taskIncluded.Data.Recurrences,
                                      o =>
                                        o.Site ===
                                        (taskSite ? taskSite.Site : o.Site),
                                    ).map(rec => {
                                      // window["warn"]('Task Option: ', rec);
                                      const count = _.filter(
                                        this.state.taskIncluded.Data
                                          .Recurrences,
                                        o =>
                                          o.Site ===
                                          (taskSite ? taskSite.Site : o.Site),
                                      ).length
                                      return (
                                        <MenuItem
                                          key={rec.Seq}
                                          value={rec.Seq}
                                          disabled={Boolean(
                                            _.findIndex(
                                              this.state.taskIncluded.Data
                                                .Schedule,
                                              {
                                                Site:
                                                  MultiSiteYN === 'Y'
                                                    ? taskSite.Site
                                                    : this.state.Site,
                                                Seq: rec.Seq,
                                              },
                                            ) > -1,
                                          )}
                                        >
                                          {`Service #${rec.Seq} of ${count}`}
                                        </MenuItem>
                                      )
                                    })}
                                </TextField>
                                {this.state.taskWorkOrder &&
                                  this.state.taskWorkOrder !== '+' && (
                                    <TextField
                                      className='w-full mb-12'
                                      label='Scope'
                                      id='taskScope'
                                      name='taskScope'
                                      value={this.state.taskScope}
                                      onChange={this.handleChange}
                                      InputLabelProps={{
                                        shrink: this.state.taskScope
                                          ? true
                                          : false,
                                      }}
                                      InputProps={{ type: 'number' }}
                                      fullWidth
                                      variant='outlined'
                                      required
                                      select
                                    >
                                      {woAccessLevel &&
                                        woAccessLevel.AccessLevel !== 'R' && (
                                          <MenuItem value={'+'}>
                                            <Typography className='flex items-center flex-1'>
                                              <Icon className='mr-8'>add</Icon>
                                              Create New Scope
                                            </Typography>
                                          </MenuItem>
                                        )}
                                      {!taskSite &&
                                        this.state.taskInfo &&
                                        this.state.taskWorkOrder &&
                                        this.state.Data.Site &&
                                        this.state.Data.Site.Data &&
                                        this.state.Data.Site.Data.WorkOrders &&
                                        _.filter(
                                          this.state.Data.Site.Data.WorkOrders,
                                          {
                                            WorkOrder: this.state.taskWorkOrder,
                                          },
                                        ).map(wo => {
                                          return wo.Data.Scopes.map(
                                            (value, index) => {
                                              if (
                                                value.BusinessUnit ===
                                                  this.state.taskInfo
                                                    .BusinessUnit &&
                                                value.Division ===
                                                  this.state.taskInfo
                                                    .Division &&
                                                value.Department ===
                                                  this.state.taskInfo.Department
                                              ) {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={value.Scope}
                                                  >
                                                    <Typography className='truncate'>{`${
                                                      value.Scope
                                                    } - ${
                                                      value.Description || ''
                                                    }`}</Typography>
                                                  </MenuItem>
                                                )
                                              }
                                            },
                                          )
                                        })}
                                      {taskSite &&
                                        taskSite.Data &&
                                        taskSite.Data.WorkOrders &&
                                        _.filter(taskSite.Data.WorkOrders, {
                                          WorkOrder: this.state.taskWorkOrder,
                                        }).map(wo => {
                                          return wo.Data.Scopes.map(
                                            (value, index) => {
                                              if (
                                                value.BusinessUnit ===
                                                  this.state.taskInfo
                                                    .BusinessUnit &&
                                                value.Division ===
                                                  this.state.taskInfo
                                                    .Division &&
                                                value.Department ===
                                                  this.state.taskInfo.Department
                                              ) {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={value.Scope}
                                                  >
                                                    <Typography className='truncate'>{`${
                                                      value.Scope
                                                    } - ${
                                                      value.Description || ''
                                                    }`}</Typography>
                                                  </MenuItem>
                                                )
                                              }
                                            },
                                          )
                                        })}
                                    </TextField>
                                  )}
                                {(this.state.taskWorkOrder === '+' ||
                                  this.state.taskScope === '+') && (
                                  <DatePicker
                                    inputVariant='outlined'
                                    className='w-full mb-12'
                                    label='Due By'
                                    format='MM/DD/YYYY'
                                    id='taskDueBy'
                                    name='taskDueBy'
                                    value={this.state.taskDueBy}
                                    InputLabelProps={{
                                      shrink: this.state.taskDueBy
                                        ? true
                                        : false,
                                    }}
                                    onChange={this.handleTaskDueByChange}
                                    fullWidth
                                    autoOk
                                  />
                                )}
                                {(this.state.taskWorkOrder === '+' ||
                                  this.state.taskScope === '+') && (
                                  <Autocomplete
                                    className='w-full mb-12'
                                    title='Arrival Window'
                                    options={
                                      taskInfo && arrivalWindows
                                        ? _.filter(arrivalWindows, {
                                            BusinessUnit: taskInfo.BusinessUnit,
                                          })
                                        : []
                                    }
                                    menuItemComponent={value => {
                                      return (
                                        <MenuItem value={value.ArrivalWindow}>
                                          {`${value.Description}`}
                                        </MenuItem>
                                      )
                                    }}
                                    portal={true}
                                    value={this.state.taskArrivalWindow}
                                    onSelect={option =>
                                      this.setState({
                                        ...this.state,
                                        taskArrivalWindow: option.ArrivalWindow,
                                      })
                                    }
                                    required
                                  />
                                )}
                                {(this.state.taskWorkOrder === '+' ||
                                  this.state.taskScope === '+') && (
                                  <Autocomplete
                                    className='w-full mb-12'
                                    title='Lead Technician'
                                    options={technicians}
                                    menuItemComponent={value => {
                                      return (
                                        value.ActiveYN === 'Y' && (
                                          <MenuItem value={value.Technician}>
                                            <div className='flex'>
                                              <Avatar
                                                style={{ marginRight: 8 }}
                                                classes={{
                                                  root: classes.avatarRoot,
                                                }}
                                                className={classes.avatar}
                                                alt={value.Technician}
                                                src={
                                                  value.Data &&
                                                  value.Data.Avatar
                                                    ? getProfileImage(
                                                        `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                                      )
                                                    : avatar
                                                }
                                              />
                                              {`${value.FirstName} ${value.LastName}`}
                                            </div>
                                          </MenuItem>
                                        )
                                      )
                                    }}
                                    value={this.state.taskTechnician}
                                    portal={true}
                                    onSelect={option =>
                                      this.setState({
                                        ...this.state,
                                        taskTechnician: option.Technician,
                                      })
                                    }
                                    required
                                  />
                                )}
                                <Button
                                  variant='contained'
                                  color='primary'
                                  className='w-full'
                                  onClick={this.addTaskToWorkOrder}
                                  disabled={
                                    !this.state.taskWorkOrder ||
                                    (this.state.taskWorkOrder !== '+' &&
                                      !this.state.taskScope) ||
                                    !this.state.taskSeq
                                  }
                                >
                                  Add
                                </Button>
                              </div>
                            </Menu>
                          )}
                        {accessLevel &&
                          accessLevel.AccessLevel !== 'R' &&
                          taskInfo &&
                          Boolean(taskEditMenu) && (
                            <Menu
                              id='task-edit-menu'
                              anchorEl={taskEditMenu}
                              keepMounted
                              open={Boolean(taskEditMenu)}
                              onClose={this.handleTaskEditMenuClose}
                            >
                              <div className='p-12 min-w-320 max-w-320'>
                                <Typography className='flex items-center flex-1 font-bold mb-12'>
                                  <Icon className='mr-8' color='primary'>
                                    assignment
                                  </Icon>
                                  Misc Fees (Per Recurrence)
                                </Typography>
                                <TextField
                                  className='w-full mb-12'
                                  label='Additional Charges'
                                  id='taskMisc'
                                  value={
                                    typeof taskInfo.MiscRevenue !== 'undefined'
                                      ? taskInfo.MiscRevenue
                                      : currentTask &&
                                          currentTask.MiscRevenue !== 0
                                        ? currentTask.MiscRevenue
                                        : ''
                                  }
                                  onChange={e => {
                                    taskInfo.MiscRevenue = Number(
                                      e.target.value,
                                    )
                                    const { Data } = this.state
                                    const { Tasks } = Data
                                    const index = _.findIndex(Tasks, {
                                      TaskType: taskInfo.TaskType,
                                    })
                                    if (index > -1) {
                                      const task = Tasks[index]
                                      task.MiscRevenue = taskInfo.MiscRevenue
                                      Tasks.splice(index, 1, task)
                                      this.setState({
                                        Data: { ...Data, Tasks },
                                        currentTask,
                                      })
                                    }
                                  }}
                                  InputProps={{
                                    type: 'number',
                                    startAdornment: Boolean(
                                      taskInfo.MiscRevenue,
                                    ) ? (
                                      <InputAdornment placement='start'>
                                        $
                                      </InputAdornment>
                                    ) : (
                                      ''
                                    ),
                                  }}
                                  fullWidth
                                  variant='outlined'
                                />

                                <Button
                                  variant='contained'
                                  color='primary'
                                  className='w-full'
                                  onClick={() => {
                                    this.setState(
                                      {
                                        taskEditMenu: null,
                                        taskInfo: null,
                                        taskIncluded: null,
                                      },
                                      () => this.handleTypeChange(),
                                    )
                                  }}
                                >
                                  Done
                                </Button>
                              </div>
                            </Menu>
                          )}
                        {this.state.Data.AgreementType &&
                          this.state.Data.AgreementType.Data &&
                          _.filter(
                            this.state.Data.AgreementType.Data.Tasks,
                            o =>
                              o.ActiveYN !== 'N' ||
                              _.findIndex(agreementTasks, {
                                TaskType: o.TaskType,
                              }) > -1,
                          ).map((task, index) => {
                            const {
                              ID,
                              ActiveYN,
                              Co,
                              BusinessUnit,
                              Division,
                              AgreementType,
                              Data,
                            } = this.state
                            const { Schedule, Site, Tasks } = Data
                            const Included = _.find(Tasks, {
                              TaskType: task.TaskType,
                            })

                            const Scheduled = _.find(Schedule, {
                              TaskType: task.TaskType,
                            })
                            const type = _.find(agreementTypes, {
                              BusinessUnit,
                              Division,
                              AgreementType,
                            })
                            const len = type.Data.Tasks.length
                            const original = type
                              ? _.find(type.Data.Tasks, {
                                  TaskType: task.TaskType,
                                })
                              : null
                            const assignToScope = _.find(workOrderTasks, {
                              TaskType: task.TaskType,
                            })
                            const remaining =
                              Included &&
                              Included.Data &&
                              Included.Data.Recurrences
                                ? _.filter(
                                    Included.Data.Recurrences,
                                    o =>
                                      o.Site ===
                                        (taskSite ? taskSite.Site : o.Site) &&
                                      !o.WaivedBy &&
                                      _.findIndex(Included.Data.Schedule, {
                                        Site: o.Site,
                                        Seq: o.Seq,
                                      }) < 0,
                                  )
                                : []

                            window['warn'](
                              'TaskFrequency Included',
                              Included,
                              taskSite,
                              assignToScope,
                              remaining,
                            )

                            if (
                              simpleAction === 'tasks' &&
                              (!Included || remaining.length < 1)
                            ) {
                              return ''
                            }
                            return (
                              <Card
                                className={classNames(
                                  classes.taskCard,
                                  index === len - 1 ? 'mb-0' : '',
                                )}
                                key={index}
                              >
                                <CardHeader
                                  className={classes.taskCardHeader}
                                  classes={{
                                    action: 'sm:mt-0',
                                    root: 'p-8',
                                    avatar: 'mr-12',
                                    title: 'leading-none',
                                    subheader: 'leading-none',
                                  }}
                                  avatar={
                                    <Badge
                                      onClick={() => {
                                        if (!Scheduled) {
                                          this.handleClickTask(task.TaskType)
                                        }
                                      }}
                                      classes={{
                                        badge: classNames(
                                          'cursor-pointer',
                                          classes.taskBadgeSimple,
                                          classes.grey,
                                        ),
                                      }}
                                      badgeContent={
                                        Included && Included.Data.Units
                                          ? Included.Data.Units.length
                                          : 0
                                      }
                                    >
                                      <Icon
                                        className={classNames(
                                          'cursor-pointer text-24',
                                          Scheduled
                                            ? classes.greenText
                                            : classes.greyText,
                                        )}
                                        style={{ fontSize: 28 }}
                                      >
                                        {Scheduled
                                          ? 'check_circle_outline'
                                          : Included
                                            ? 'radio_button_checked'
                                            : 'radio_button_unchecked'}
                                      </Icon>
                                    </Badge>
                                  }
                                  title={
                                    <label className='text-12 font-bold'>
                                      {task.Name}
                                    </label>
                                  }
                                  subheader={
                                    <TextField
                                      className={classNames('')}
                                      // label={task.Frequency === '*' || (!task.Frequency && !this.state.TaskFrequency) && "Task Frequency"}
                                      style={{ margin: 0 }}
                                      name='TaskFrequency'
                                      value={
                                        Included
                                          ? Included.Frequency || task.Frequency
                                          : task.Frequency || ''
                                      }
                                      onChange={e =>
                                        this.updateTaskFrequency(
                                          task.TaskType,
                                          e.target.value,
                                        )
                                      }
                                      margin='dense'
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          fontSize: 11,
                                          paddingTop: 4,
                                          color: '#9f9f9f',
                                        },
                                        classes: {
                                          input: 'p-0 pr-24',
                                        },
                                      }}
                                      select
                                      disabled={
                                        original &&
                                        original.Frequency &&
                                        original.Frequency !== '*'
                                      }
                                    >
                                      <MenuItem value='*'>
                                        Select Task Frequency
                                      </MenuItem>
                                      <MenuItem value='M'>Monthly</MenuItem>
                                      <MenuItem value='B'>Bi-Monthly</MenuItem>
                                      <MenuItem value='Q'>Quarterly</MenuItem>
                                      <MenuItem value='S'>Semi-Annual</MenuItem>
                                      <MenuItem value='A'>Annual</MenuItem>
                                    </TextField>
                                  }
                                  action={
                                    Included && (
                                      <React.Fragment>
                                        <label
                                          className={`text-12 font-bold mx-12`}
                                        >
                                          ${Included.Revenue}
                                        </label>
                                        {accessLevel &&
                                        accessLevel.AccessLevel !== 'R' &&
                                        !this.state.needsRevision &&
                                        !this.state.newCycle &&
                                        ID &&
                                        MultiSiteYN !== 'Y' &&
                                        ActiveYN === 'Y' ? (
                                          <IconButton
                                            className='mr-4'
                                            onClick={e =>
                                              this.handleTaskMenuOpen(
                                                e.currentTarget,
                                                task,
                                                Included,
                                              )
                                            }
                                            aria-label='settings'
                                          >
                                            <Icon className='text-16'>
                                              more_vert
                                            </Icon>
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            className='mr-4'
                                            onClick={e =>
                                              this.handleTaskEditMenuOpen(
                                                e.currentTarget,
                                                task,
                                                Included,
                                              )
                                            }
                                            aria-label='settings'
                                          >
                                            <Icon className='text-16'>
                                              more_vert
                                            </Icon>
                                          </IconButton>
                                        )}
                                      </React.Fragment>
                                    )
                                  }
                                />
                                {Included &&
                                  ['A', 'S', 'Q', 'B', 'M'].indexOf(
                                    Included.Frequency || task.Frequency,
                                  ) > -1 && (
                                    <CardContent
                                      classes={{
                                        root: classNames(
                                          'p-8 overflow-x-auto',
                                          classes.frequency,
                                          (Included.Frequency ||
                                            task.Frequency) === 'M'
                                            ? 'hidden'
                                            : '',
                                        ),
                                      }}
                                    >
                                      <Recurrence
                                        term={this.state.TermLength}
                                        onChange={(Schedule, manual) =>
                                          this.setTaskRecurrenceSchedule(
                                            task,
                                            Schedule,
                                            manual,
                                          )
                                        }
                                        data={Included.Data.Recurrences}
                                        units={agreementTasks}
                                        disabled={
                                          Boolean(this.state.ID) &&
                                          !this.state.needsRevision &&
                                          !this.state.newCycle
                                        }
                                        value='Date'
                                        start={
                                          (Included.Frequency ||
                                            task.Frequency) === 'A'
                                            ? Included &&
                                              Included.Data &&
                                              Included.Data.Recurrences &&
                                              Included.Data.Recurrences[0]
                                              ? Included.Data.Recurrences[0]
                                                  .Date ||
                                                task.DateMedian ||
                                                this.state.StartDate
                                              : task.DateMedian ||
                                                this.state.StartDate
                                            : this.state.StartDate
                                        }
                                        frequency={
                                          Included.Frequency || task.Frequency
                                        }
                                      />
                                    </CardContent>
                                  )}
                                {this.state.MultiSiteYN === 'Y' &&
                                  this.state.Data.Sites &&
                                  this.state.Data.Sites.map(st => {
                                    const site = _.find(
                                      this.state.Data.Customer.Data.Sites,
                                      { Site: st.Site },
                                    )
                                    if (site) {
                                      const { Units } = Included
                                        ? Included.Data
                                        : {}
                                      const units = Units
                                        ? _.filter(Units, { Site: site.Site })
                                        : null
                                      // window["warn"](`${site.Site} Units: `, units);
                                      return (
                                        <div className='w-full'>
                                          <CardHeader
                                            key={site.Site}
                                            className={classes.taskCardUnit}
                                            classes={{
                                              action: 'sm:mt-0',
                                              root: 'p-8 pl-16',
                                              avatar: 'mr-8',
                                              title: 'leading-none',
                                              subheader: 'leading-none',
                                            }}
                                            avatar={
                                              <img
                                                className={classNames(
                                                  classes.unitAvatar,
                                                  'w-20 h-auto',
                                                )}
                                                style={{ cursor: 'pointer' }}
                                                alt='Service Site Icon'
                                                src={siteAvatar}
                                              />
                                            }
                                            title={
                                              <label className='text-12 font-bold'>
                                                Site #{site.Site}
                                              </label>
                                            }
                                            subheader={
                                              <label className='text-10'>
                                                {site.Description}
                                              </label>
                                            }
                                            action={
                                              Included &&
                                              !this.state.needsRevision &&
                                              !this.state.newCycle &&
                                              accessLevel &&
                                              accessLevel.AccessLevel !== 'R' &&
                                              ID &&
                                              ActiveYN === 'Y' && (
                                                <IconButton
                                                  className='mr-4'
                                                  onClick={e =>
                                                    this.handleTaskMenuOpen(
                                                      e.currentTarget,
                                                      task,
                                                      Included,
                                                      site,
                                                    )
                                                  }
                                                  aria-label='settings'
                                                >
                                                  <Icon className='text-16'>
                                                    more_vert
                                                  </Icon>
                                                </IconButton>
                                              )
                                            }
                                          />
                                          {units &&
                                            units.map(Unit => {
                                              const unit = _.find(
                                                site.Data.Units,
                                                { UnitID: Unit.UnitID },
                                              )
                                              return (
                                                <CardHeader
                                                  key={unit.UnitID}
                                                  className={
                                                    classes.taskCardUnit
                                                  }
                                                  classes={{
                                                    action: 'sm:mt-0 mr-0',
                                                    root: classNames(
                                                      'p-8 ml-24 pl-12',
                                                      classes.taskSiteUnit,
                                                    ),
                                                    avatar: 'mr-12',
                                                    title:
                                                      'leading-none truncate',
                                                    subheader:
                                                      'leading-none truncate',
                                                  }}
                                                  avatar={
                                                    <Avatar
                                                      className={classNames(
                                                        classes.unitAvatar,
                                                        'w-20 h-20',
                                                      )}
                                                      style={{
                                                        cursor: 'pointer',
                                                        boxShadow:
                                                          '2px 2px 4px 0px #3f3f3f',
                                                      }}
                                                      alt='Service Unit Image'
                                                      src={
                                                        unit.Data &&
                                                        unit.Data.Avatar
                                                          ? !isNaN(
                                                              unit.Data.Avatar,
                                                            )
                                                            ? getProfileImage(
                                                                `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                              )
                                                            : unit.Data.Avatar
                                                          : unit.avatar
                                                            ? unit.avatar
                                                            : avatar
                                                      }
                                                    />
                                                  }
                                                  title={
                                                    <label className='text-11 font-bold'>
                                                      {unit.Name}
                                                    </label>
                                                  }
                                                  subheader={
                                                    <label className='text-10'>
                                                      {unit.UnitID}
                                                    </label>
                                                  }
                                                  action={
                                                    accessLevel &&
                                                    accessLevel.AccessLevel !==
                                                      'R' && (
                                                      <IconButton
                                                        onClick={e =>
                                                          this.setState({
                                                            anchorUnit: unit,
                                                            anchorUnitEl:
                                                              e.target,
                                                            anchorUnitTask:
                                                              Included,
                                                          })
                                                        }
                                                        aria-label='edit'
                                                      >
                                                        <Icon className='text-16'>
                                                          more_vert
                                                        </Icon>
                                                      </IconButton>
                                                    )
                                                  }
                                                />
                                              )
                                            })}
                                          {accessLevel &&
                                            accessLevel.AccessLevel !== 'R' &&
                                            Included &&
                                            task.UnitCat && (
                                              <CardHeader
                                                onClick={() =>
                                                  openNewServiceUnitDialog({
                                                    Co,
                                                    Customer: site.Customer,
                                                    Site: site.Site,
                                                    UnitCat: task.UnitCat,
                                                    UnitType: task.UnitType,
                                                  })
                                                }
                                                className='cursor-pointer'
                                                classes={{
                                                  root: classNames(
                                                    'p-8 ml-24 pl-12',
                                                    classes.taskSiteUnit,
                                                  ),
                                                  avatar: 'pl-4 pt-6 mr-16',
                                                  action: 'mt-0',
                                                }}
                                                action={
                                                  <div className='flex mr-8 mr-16'>
                                                    <Icon
                                                      className='text-14 mr-4'
                                                      color='primary'
                                                    >
                                                      add_circle_outline
                                                    </Icon>
                                                    <label className='text-10 font-bold cursor-pointer'>
                                                      Add Service Unit
                                                    </label>
                                                  </div>
                                                }
                                              />
                                            )}
                                        </div>
                                      )
                                    }
                                  })}
                                {this.state.MultiSiteYN !== 'Y' && (
                                  <React.Fragment>
                                    {Included &&
                                      Included.Data.Units.map(Unit => {
                                        const unit = _.find(Site.Data.Units, {
                                          UnitID: Unit.UnitID,
                                        })
                                        return (
                                          <CardHeader
                                            key={unit.UnitID}
                                            className={classes.taskCardUnit}
                                            classes={{
                                              action: 'sm:mt-0 mr-0',
                                              root: 'p-8 pl-16',
                                              avatar: 'mr-12',
                                              title: 'leading-none',
                                              subheader: 'leading-none',
                                            }}
                                            avatar={
                                              <Avatar
                                                className={classNames(
                                                  classes.unitAvatar,
                                                  'w-20 h-20',
                                                )}
                                                style={{
                                                  cursor: 'pointer',
                                                  boxShadow:
                                                    '2px 2px 4px 0px #3f3f3f',
                                                }}
                                                alt='Service Unit Image'
                                                src={
                                                  unit.Data && unit.Data.Avatar
                                                    ? !isNaN(unit.Data.Avatar)
                                                      ? getProfileImage(
                                                          `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                        )
                                                      : unit.Data.Avatar
                                                    : unit.avatar
                                                      ? unit.avatar
                                                      : avatar
                                                }
                                              />
                                            }
                                            title={
                                              <label className='text-12 font-bold'>
                                                {unit.Name}
                                              </label>
                                            }
                                            subheader={
                                              <label className='text-10'>
                                                {unit.UnitID}
                                              </label>
                                            }
                                            action={
                                              accessLevel &&
                                              accessLevel.AccessLevel !==
                                                'R' && (
                                                <IconButton
                                                  onClick={e =>
                                                    this.setState({
                                                      anchorUnit: unit,
                                                      anchorUnitEl: e.target,
                                                      anchorUnitTask: Included,
                                                    })
                                                  }
                                                  aria-label='edit'
                                                >
                                                  <Icon className='text-16'>
                                                    more_vert
                                                  </Icon>
                                                </IconButton>
                                              )
                                            }
                                          />
                                        )
                                      })}
                                    {accessLevel &&
                                      accessLevel.AccessLevel !== 'R' &&
                                      Included &&
                                      task.UnitCat &&
                                      Site &&
                                      simpleAction !== 'tasks' && (
                                        <CardHeader
                                          onClick={() =>
                                            openNewServiceUnitDialog({
                                              Co,
                                              Customer: Site.Customer,
                                              Site: Site.Site,
                                              UnitCat: task.UnitCat,
                                              UnitType: task.UnitType,
                                            })
                                          }
                                          className='cursor-pointer'
                                          classes={{
                                            root: 'p-8',
                                            avatar: 'pl-4 pt-6 mr-16',
                                            action: 'mt-0',
                                          }}
                                          action={
                                            <div className='flex mr-8 mr-16'>
                                              <Icon
                                                className='text-14 mr-4'
                                                color='primary'
                                              >
                                                add_circle_outline
                                              </Icon>
                                              <label className='text-10 font-bold cursor-pointer'>
                                                Add Service Unit
                                              </label>
                                            </div>
                                          }
                                        />
                                      )}
                                    {simpleAction === 'tasks' &&
                                      !assignToScope &&
                                      this.state.taskWorkOrder && (
                                        <CardHeader
                                          onClick={() => {
                                            const { workOrderTasks } =
                                              this.state
                                            const {
                                              Co,
                                              BusinessUnit,
                                              Division,
                                              Department,
                                            } = task
                                            const {
                                              Customer,
                                              Site,
                                              Agreement,
                                              Cycle,
                                            } = this.state
                                            const wo = _.find(
                                              this.state.Data.Site.Data
                                                .WorkOrders,
                                              {
                                                WorkOrder:
                                                  this.state.taskWorkOrder,
                                              },
                                            )
                                            const exists = Boolean(wo)
                                              ? _.find(wo.Data.Scopes, {
                                                  BusinessUnit,
                                                  Division,
                                                  Department,
                                                })
                                              : null
                                            const newExists = _.find(
                                              workOrderTasks,
                                              {
                                                BusinessUnit,
                                                Division,
                                                Department,
                                              },
                                            )
                                            const rec = {
                                              Co,
                                              BusinessUnit,
                                              Division,
                                              Customer,
                                              Site,
                                              Agreement,
                                              Cycle,
                                              TaskType: task.TaskType,
                                              Seq:
                                                remaining &&
                                                remaining.length > 0
                                                  ? remaining[0].Seq
                                                  : null,
                                              Scope: exists
                                                ? exists.Scope
                                                : '+',
                                              WorkOrder: taskWorkOrder,
                                              Department,
                                              ArrivalWindow: newExists
                                                ? newExists.ArrivalWindow
                                                : null,
                                              DueBy: newExists
                                                ? newExists.DueBy
                                                : null,
                                              LeadTechnician: newExists
                                                ? newExists.LeadTechnician
                                                : null,
                                            }
                                            workOrderTasks.push(rec)
                                            this.setState({ workOrderTasks })
                                          }}
                                          className='cursor-pointer'
                                          classes={{
                                            root: 'p-8',
                                            avatar: 'pl-4 pt-6 mr-16',
                                            action: 'mt-0',
                                          }}
                                          action={
                                            <div className='flex mr-8 mr-16'>
                                              <Icon
                                                className='text-14 mr-4'
                                                color='primary'
                                              >
                                                add_circle_outline
                                              </Icon>
                                              <label className='text-10 font-bold cursor-pointer'>
                                                Add Task
                                              </label>
                                            </div>
                                          }
                                        />
                                      )}
                                    {simpleAction === 'tasks' &&
                                      assignToScope &&
                                      this.state.taskWorkOrder && (
                                        <CardHeader
                                          onClick={() => {
                                            const { workOrderTasks } =
                                              this.state
                                            const index = _.findIndex(
                                              workOrderTasks,
                                              { TaskType: task.TaskType },
                                            )
                                            if (index > -1) {
                                              workOrderTasks.splice(index, 1)
                                            }
                                            this.setState({ workOrderTasks })
                                          }}
                                          className='cursor-pointer'
                                          classes={{
                                            root: 'p-8',
                                            avatar: 'pl-4 pt-6 mr-16',
                                            action: 'mt-0',
                                          }}
                                          action={
                                            <div className='flex mr-8 mr-16'>
                                              <Icon
                                                className='text-14 mr-4'
                                                color='error'
                                              >
                                                remove_circle_outline
                                              </Icon>
                                              <label className='text-10 font-bold cursor-pointer'>
                                                Remove Task
                                              </label>
                                            </div>
                                          }
                                        />
                                      )}
                                    {simpleAction === 'tasks' &&
                                      assignToScope &&
                                      this.state.taskWorkOrder && (
                                        <div className='p-12 pt-0 w-full'>
                                          {/* <Typography className="flex items-center flex-1 font-bold mb-12"><Icon className="mr-8" color="primary">assignment</Icon>Assign to Work Order</Typography> */}
                                          <TextField
                                            className='w-full mb-12'
                                            label='Task Sequence'
                                            id='taskSeq'
                                            name='taskSeq'
                                            value={assignToScope.Seq || ''}
                                            onChange={e => {
                                              const Seq = e.target.value
                                              assignToScope.Seq = Seq
                                              this.setState({ workOrderTasks })
                                            }}
                                            InputProps={{ type: 'number' }}
                                            fullWidth
                                            variant='outlined'
                                            required
                                            select
                                          >
                                            {Included &&
                                              Included.Data &&
                                              Included.Data.Recurrences &&
                                              _.filter(
                                                Included.Data.Recurrences,
                                                o =>
                                                  o.Site ===
                                                  (taskSite
                                                    ? taskSite.Site
                                                    : o.Site),
                                              ).map(rec => {
                                                // window["warn"]('Task Option: ', rec);
                                                const count = _.filter(
                                                  Included.Data.Recurrences,
                                                  o =>
                                                    o.Site ===
                                                    (taskSite
                                                      ? taskSite.Site
                                                      : o.Site),
                                                ).length
                                                return (
                                                  <MenuItem
                                                    key={rec.Seq}
                                                    value={rec.Seq}
                                                    disabled={Boolean(
                                                      _.findIndex(
                                                        Included.Data.Schedule,
                                                        {
                                                          Site:
                                                            MultiSiteYN === 'Y'
                                                              ? taskSite.Site
                                                              : this.state.Site,
                                                          Seq: rec.Seq,
                                                        },
                                                      ) > -1,
                                                    )}
                                                  >
                                                    {`Service #${rec.Seq} of ${count}`}
                                                  </MenuItem>
                                                )
                                              })}
                                          </TextField>
                                          {this.state.taskWorkOrder &&
                                            this.state.taskWorkOrder !==
                                              '+' && (
                                              <TextField
                                                className='w-full mb-12'
                                                label='Scope'
                                                id='taskScope'
                                                name='taskScope'
                                                value={
                                                  assignToScope.Scope || ''
                                                }
                                                onChange={e => {
                                                  const Scope = e.target.value
                                                  assignToScope.Scope = Scope
                                                  this.setState({
                                                    workOrderTasks,
                                                  })
                                                }}
                                                InputLabelProps={{
                                                  shrink: assignToScope.Scope
                                                    ? true
                                                    : false,
                                                }}
                                                InputProps={{ type: 'number' }}
                                                fullWidth
                                                variant='outlined'
                                                required
                                                select
                                              >
                                                {woAccessLevel &&
                                                  woAccessLevel.AccessLevel !==
                                                    'R' && (
                                                    <MenuItem value={'+'}>
                                                      <Typography className='flex items-center flex-1'>
                                                        <Icon className='mr-8'>
                                                          add
                                                        </Icon>
                                                        Create New Scope
                                                      </Typography>
                                                    </MenuItem>
                                                  )}
                                                {!taskSite &&
                                                  task &&
                                                  this.state.taskWorkOrder &&
                                                  this.state.Data.Site &&
                                                  this.state.Data.Site.Data &&
                                                  this.state.Data.Site.Data
                                                    .WorkOrders &&
                                                  _.filter(
                                                    this.state.Data.Site.Data
                                                      .WorkOrders,
                                                    {
                                                      WorkOrder:
                                                        this.state
                                                          .taskWorkOrder,
                                                    },
                                                  ).map(wo => {
                                                    return wo.Data.Scopes.map(
                                                      (value, index) => {
                                                        if (
                                                          value.BusinessUnit ===
                                                            task.BusinessUnit &&
                                                          value.Division ===
                                                            task.Division &&
                                                          value.Department ===
                                                            task.Department
                                                        ) {
                                                          return (
                                                            <MenuItem
                                                              key={index}
                                                              value={
                                                                value.Scope
                                                              }
                                                            >
                                                              <Typography className='truncate'>{`${
                                                                value.Scope
                                                              } - ${
                                                                value.Description ||
                                                                ''
                                                              }`}</Typography>
                                                            </MenuItem>
                                                          )
                                                        }
                                                      },
                                                    )
                                                  })}
                                                {taskSite &&
                                                  taskSite.Data &&
                                                  taskSite.Data.WorkOrders &&
                                                  _.filter(
                                                    taskSite.Data.WorkOrders,
                                                    {
                                                      WorkOrder:
                                                        this.state
                                                          .taskWorkOrder,
                                                    },
                                                  ).map(wo => {
                                                    return wo.Data.Scopes.map(
                                                      (value, index) => {
                                                        if (
                                                          value.BusinessUnit ===
                                                            task.BusinessUnit &&
                                                          value.Division ===
                                                            task.Division &&
                                                          value.Department ===
                                                            task.Department
                                                        ) {
                                                          return (
                                                            <MenuItem
                                                              key={index}
                                                              value={
                                                                value.Scope
                                                              }
                                                            >
                                                              <Typography className='truncate'>{`${
                                                                value.Scope
                                                              } - ${
                                                                value.Description ||
                                                                ''
                                                              }`}</Typography>
                                                            </MenuItem>
                                                          )
                                                        }
                                                      },
                                                    )
                                                  })}
                                              </TextField>
                                            )}
                                          {(this.state.taskWorkOrder === '+' ||
                                            assignToScope.Scope === '+') && (
                                            <DatePicker
                                              inputVariant='outlined'
                                              className='w-full mb-12'
                                              label='Due By'
                                              format='MM/DD/YYYY'
                                              id='taskDueBy'
                                              name='taskDueBy'
                                              required
                                              value={assignToScope.DueBy}
                                              InputLabelProps={{
                                                shrink: assignToScope.DueBy
                                                  ? true
                                                  : false,
                                              }}
                                              onChange={e => {
                                                assignToScope.DueBy =
                                                  e._d.toLocaleDateString(
                                                    'en-US',
                                                  )
                                                this.setState({
                                                  workOrderTasks,
                                                })
                                              }}
                                              fullWidth
                                              autoOk
                                            />
                                          )}
                                          {(this.state.taskWorkOrder === '+' ||
                                            assignToScope.Scope === '+') && (
                                            <Autocomplete
                                              className='w-full mb-12'
                                              title='Arrival Window'
                                              options={
                                                task && arrivalWindows
                                                  ? _.filter(arrivalWindows, {
                                                      BusinessUnit:
                                                        task.BusinessUnit,
                                                    })
                                                  : []
                                              }
                                              menuItemComponent={value => {
                                                return (
                                                  <MenuItem
                                                    value={value.ArrivalWindow}
                                                  >
                                                    {`${value.Description}`}
                                                  </MenuItem>
                                                )
                                              }}
                                              portal={true}
                                              value={
                                                assignToScope.ArrivalWindow ||
                                                ''
                                              }
                                              onSelect={option => {
                                                assignToScope.ArrivalWindow =
                                                  option.ArrivalWindow
                                                this.setState({
                                                  workOrderTasks,
                                                })
                                              }}
                                              required
                                            />
                                          )}
                                          {(this.state.taskWorkOrder === '+' ||
                                            assignToScope.Scope === '+') && (
                                            <Autocomplete
                                              className='w-full mb-12'
                                              title='Lead Technician'
                                              options={technicians}
                                              menuItemComponent={value => {
                                                return (
                                                  value.ActiveYN === 'Y' && (
                                                    <MenuItem
                                                      value={value.Technician}
                                                    >
                                                      <div className='flex'>
                                                        <Avatar
                                                          style={{
                                                            marginRight: 8,
                                                          }}
                                                          classes={{
                                                            root: classes.avatarRoot,
                                                          }}
                                                          className={
                                                            classes.avatar
                                                          }
                                                          alt={value.Technician}
                                                          src={
                                                            value.Data &&
                                                            value.Data.Avatar
                                                              ? getProfileImage(
                                                                  `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                                                )
                                                              : avatar
                                                          }
                                                        />
                                                        {`${value.FirstName} ${value.LastName}`}
                                                      </div>
                                                    </MenuItem>
                                                  )
                                                )
                                              }}
                                              value={
                                                assignToScope.LeadTechnician ||
                                                ''
                                              }
                                              portal={true}
                                              onSelect={option => {
                                                assignToScope.LeadTechnician =
                                                  option.Technician
                                                this.setState({
                                                  workOrderTasks,
                                                })
                                              }}
                                              // required
                                            />
                                          )}
                                        </div>
                                      )}
                                  </React.Fragment>
                                )}
                              </Card>
                            )
                          })}
                      </div>
                    )}
                    {simpleStep === 4 && (
                      <React.Fragment>
                        <Divider className='mb-20' />
                        <AgreementFormDialog
                          hidden={false}
                          onSignature={this.handleSignature}
                          onSignedBy={this.handleSignedBy}
                          data={this.state}
                          open={true}
                        />
                      </React.Fragment>
                    )}
                    {/* <TextField
                                            className={classNames(classes.formControl, "")}
                                            label="Price"
                                            id="totalPrice"
                                            name="TotalPrice"
                                            value={this.state.TotalPrice}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.TotalPrice !== null }}
                                            InputProps={{ startAdornment: this.state.TotalPrice !== null && <InputAdornment position="start">$</InputAdornment>, type: 'number' }}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            disabled={this.state.ID || (agreementType && agreementType.RevenueType === 'T')}
                                        />
                                        {(!this.state.Customer || !this.state.Site) &&
                                            <React.Fragment>
                                                {this.state.Data.Customer ?
                                                    <TextField
                                                        className={classNames(classes.formControl, "")}
                                                        label="Customer"
                                                        id="Customer"
                                                        name="Customer"
                                                        value={this.state.Customer}
                                                        onChange={this.handleChange}
                                                        InputLabelProps={{ shrink: this.state.Customer ? true : false }}
                                                        fullWidth
                                                        variant="outlined"
                                                        disabled={this.state.ID}
                                                        required
                                                    />
                                                    :
                                                    <CustomerSearchBar onSelected={this.handleCustomerSelect} />
                                                }
                                                {this.state.Data.Site ?
                                                    <TextField
                                                        className={classNames(classes.formControl, "")}
                                                        label="Site"
                                                        id="Site"
                                                        name="Site"
                                                        value={this.state.Site}
                                                        onChange={this.handleChange}
                                                        InputLabelProps={{ shrink: this.state.Site ? true : false }}
                                                        fullWidth
                                                        variant="outlined"
                                                        disabled={this.state.ID}
                                                        required
                                                    />
                                                    :
                                                    <CustomerSiteSearchBar onSelected={this.handleSiteSelect} />
                                                }
                                            </React.Fragment>
                                        }
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Sold By"
                                            options={technicians}
                                            menuItemComponent={(value) => {
                                                return (
                                                    <MenuItem value={value.Technician}>
                                                        <div className="flex">
                                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` : avatar)} />
                                                            {`${value.FirstName} ${value.LastName}`}
                                                        </div>
                                                    </MenuItem>
                                                )
                                            }}
                                            value={this.state.SoldBy}
                                            onSelect={(option) => this.setState({ ...this.state, SoldBy: option.Technician })}
                                            portal={true}
                                            required
                                        />
                                        <TextField
                                            className={classNames(classes.formControl, "mb-32")}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        /> */}
                    {/* {this.state.AgreementType &&
                                            <React.Fragment>
                                                <Divider />
                                                <Tabs
                                                    value={simpleValue}
                                                    onChange={this.handleSimpleTabChange}
                                                    classes={{ root: "min-h-36" }}
                                                >
                                                    <Tab
                                                        className="w-1/2 min-h-36 max-w-full"
                                                        label={
                                                            <Typography
                                                                variant="h6"
                                                                style={{ alignItems: 'center', }}
                                                                className="mb-0 text-12 font-bold"
                                                            >
                                                                <Badge
                                                                    badgeContent={this.state.Data && this.state.Data.Site && this.state.Data.Site.Data && this.state.Data.Site.Data.Units ? this.state.Data.Site.Data.Units.length : 0}
                                                                    color="error"
                                                                    classes={{ badge: classes.simpleBadge }}
                                                                >
                                                                    <Icon color="primary" className="text-16 mr-6">build</Icon>
                                                                Units
                                                            </Badge>
                                                            </Typography>
                                                        }
                                                    />
                                                    <Tab
                                                        className="w-1/2 min-h-36 max-w-full"
                                                        label={
                                                            <Typography
                                                                variant="h6"
                                                                style={{ alignItems: 'center', }}
                                                                className="mb-0 text-12 font-bold"
                                                            >
                                                                <Badge
                                                                    badgeContent={this.state.Data && this.state.Data.Tasks ? this.state.Data.Tasks.length : 0}
                                                                    color="error"
                                                                    classes={{ badge: classes.simpleBadge }}
                                                                >
                                                                    <Icon color="primary" className="text-16 mr-6">list</Icon>
                                                                    Tasks
                                                                </Badge>
                                                            </Typography>
                                                        }
                                                    />
                                                </Tabs>
                                            </React.Fragment>
                                        } */}
                    <MobileStepper
                      variant='dots'
                      steps={steps.length}
                      position='static'
                      style={{ zIndex: 999 }}
                      activeStep={simpleStep}
                      className={'pin-b pin-l pin-r absolute dialog-stepper'}
                      nextButton={
                        simpleStep < steps.length - 1 ? (
                          <Button
                            size='small'
                            onClick={this.handleNext}
                            disabled={
                              simpleStep === steps.length ||
                              (simpleStep === 0 && !agreementType) ||
                              (simpleStep === 1 &&
                                (!agreementType ||
                                  (!agreementType.TermLength &&
                                    !this.state.TermLength) ||
                                  !this.state.StartDate ||
                                  !this.state.EndDate ||
                                  ((agreementType.BillingFrequency || '*') ===
                                    '*' &&
                                    !this.state.BillingFrequency))) ||
                              // (simpleStep === 2 &&
                              //     (this.state.Data && this.state.Data.Site && this.state.Data.Site.Data && this.state.Data.Site.Data.Units ? this.state.Data.Site.Data.Units.length : 0) < 1
                              // ) ||
                              (simpleStep === 3 &&
                                (agreementTasks.length < 1 ||
                                  (this.state.MultiSiteYN === 'Y' &&
                                    this.state.Data.Sites.length < 1) ||
                                  (this.state.MultiSiteYN !== 'Y' &&
                                    (this.state.Data &&
                                    this.state.Data.Site &&
                                    this.state.Data.Site.Data &&
                                    this.state.Data.Site.Data.Units
                                      ? this.state.Data.Site.Data.Units.length
                                      : 0) < 1)))
                            }
                          >
                            Next
                            <Icon>keyboard_arrow_right</Icon>
                          </Button>
                        ) : agreementDialog.type === 'new' || !this.state.ID ? (
                          <Button
                            variant='contained'
                            color='primary'
                            className='leading-tight text-12 mr-4'
                            onClick={() => {
                              const val = _.cloneDeepWith(this.state)

                              delete val.Data.WorkOrder
                              if (!this.state.EstimateNumber) {
                                if (FilteredScopeNew.length > 1) {
                                  this.setState({
                                    scopeDialog: true,
                                    adding: true,
                                  })
                                } else {
                                  this.setState({ adding: true }, () => {
                                    Promise.all([addAgreement(val)]).then(() =>
                                      this.setState(
                                        { adding: false },
                                        this.closeComposeDialog,
                                      ),
                                    )
                                  })
                                }
                              } else {
                                if (agreementDialog.props.onAdded) {
                                  this.setState({ adding: false }, () => {
                                    Promise.all([
                                      agreementDialog.props.onAdded(val),
                                    ]).then(() =>
                                      this.setState(
                                        { adding: false },
                                        this.closeComposeDialog,
                                      ),
                                    )
                                  })
                                }
                                if (agreementDialog.props.onUpdated) {
                                  this.setState({ adding: true }, () => {
                                    Promise.all([
                                      agreementDialog.props.onUpdated(val),
                                    ]).then(() =>
                                      this.setState(
                                        { adding: false },
                                        this.closeComposeDialog,
                                      ),
                                    )
                                  })
                                }
                              }
                            }}
                            disabled={
                              !this.canBeSubmitted() || this.state.adding
                            }
                          >
                            {this.state.adding && (
                              <CircularProgress
                                color='primary'
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginRight: 4,
                                }}
                              />
                            )}
                            {!this.state.EstimateNumber
                              ? this.state.adding
                                ? 'Adding'
                                : 'Add'
                              : this.state.Component
                                ? this.state.adding
                                  ? 'Updating Estimate'
                                  : 'Update Estimate'
                                : this.state.adding
                                  ? 'Adding to Estimate'
                                  : 'Add to Estimate'}
                          </Button>
                        ) : agreementDialog.type !== 'new' &&
                          this.state.ID &&
                          this.state.newCycle ? (
                          <Button
                            variant='contained'
                            color='primary'
                            className='leading-tight text-12 mr-4'
                            onMouseEnter={() => {
                              const {
                                BusinessUnit,
                                Division,
                                AgreementType,
                                Customer,
                                Site,
                                Agreement,
                                Cycle,
                                Revision,
                                StartDate,
                                WorkOrder,
                                Scope,
                                MultiSiteYN,
                                Data,
                              } = this.state
                              // window["warn"]('Agreement Renewal CBS: ', BusinessUnit, Division, AgreementType, Customer, Site, Agreement, Cycle, Revision, StartDate, WorkOrder, Scope, MultiSiteYN, Data);
                            }}
                            onClick={() => {
                              const val = _.cloneDeepWith(this.state)

                              delete val.Data.WorkOrder
                              this.setState({ adding: true }, () => {
                                Promise.all([
                                  addAgreement({ ...val, ID: null }),
                                ]).then(() => this.setState({ adding: false }))
                              })
                            }}
                            style={{
                              marginLeft: -32,
                            }}
                            disabled={
                              !this.canBeSubmitted() || this.state.adding
                            }
                          >
                            {this.state.adding && (
                              <CircularProgress
                                color='primary'
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginRight: 4,
                                }}
                              />
                            )}
                            {this.state.adding ? 'Adding Cycle' : 'Add Cycle'}
                          </Button>
                        ) : agreementDialog.type !== 'new' &&
                          this.state.ID &&
                          this.state.needsRevision ? (
                          <Button
                            variant='contained'
                            color='primary'
                            className='leading-tight text-12 mr-4'
                            onClick={() => {
                              const val = _.cloneDeepWith(this.state)

                              delete val.Data.WorkOrder
                              //window["warn"]("yyttrree", this.state)
                              this.setState({ adding: true }, () => {
                                Promise.all([
                                  addAgreement({ ...val, ID: null }),
                                ]).then(() => this.setState({ adding: false }))
                              })
                            }}
                            style={{
                              marginLeft: -12,
                            }}
                            disabled={
                              !this.canBeSubmitted() || this.state.adding
                            }
                          >
                            {this.state.adding && (
                              <CircularProgress
                                color='primary'
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginRight: 4,
                                }}
                              />
                            )}
                            {this.state.adding ? 'Revising' : 'Revise'}
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            color='primary'
                            className='leading-tight text-12 mr-4'
                            onClick={() => {
                              const val = _.cloneDeepWith(this.state)

                              delete val.Data.WorkOrder
                              this.setState({ adding: true }, () => {
                                Promise.all([updateAgreement(val)]).then(() =>
                                  this.setState({ adding: false }),
                                )
                              })
                            }}
                            disabled={
                              !this.canBeSubmitted() || this.state.adding
                            }
                          >
                            {this.state.adding && (
                              <CircularProgress
                                color='primary'
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginRight: 4,
                                }}
                              />
                            )}
                            {this.state.adding ? 'Saving' : 'Save'}
                          </Button>
                        )
                      }
                      backButton={
                        simpleStep > 0 || simpleAction ? (
                          <Button
                            size='small'
                            onClick={this.handleBack}
                            disabled={simpleStep === 0 && !simpleAction}
                          >
                            <Icon>keyboard_arrow_left</Icon>
                            Back
                          </Button>
                        ) : (
                          <FormControlLabel
                            style={{ marginRight: -32 }}
                            className='h-32'
                            control={
                              <Switch
                                checked={this.state.advanced === 'Y'}
                                name='advanced'
                                onChange={this.handleChange}
                                color='primary'
                              />
                            }
                            label='Advanced'
                          />
                        )
                      }
                    />
                  </div>
                )}
                {advanced === 'Y' && (
                  <React.Fragment>
                    {' '}
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>business_center</Icon>
                      </div>
                      <TextField
                        className={classes.formControl}
                        label='Business Unit'
                        id='BusinessUnit'
                        name='BusinessUnit'
                        value={this.state.BusinessUnit}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.BusinessUnit ? true : false,
                        }}
                        variant='outlined'
                        select
                        fullWidth
                        required
                        disabled={this.state.ID}
                      >
                        {businessUnits &&
                          businessUnits.map((value, index) => (
                            <MenuItem key={index} value={value.BusinessUnit}>
                              {value.Description}
                            </MenuItem>
                          ))}
                      </TextField>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Division'
                        id='Division'
                        name='Division'
                        value={this.state.Division}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.Division ? true : false,
                        }}
                        variant='outlined'
                        select
                        fullWidth
                        required
                        disabled={this.state.ID}
                      >
                        {this.state.BusinessUnit ? (
                          divisions && divisions.length > 0 ? (
                            divisions.map((value, index) =>
                              value.BusinessUnit == this.state.BusinessUnit ? (
                                <MenuItem key={index} value={value.Division}>
                                  {`${value.Division} - ${value.Description}`}
                                </MenuItem>
                              ) : (
                                ''
                              ),
                            )
                          ) : (
                            ''
                          )
                        ) : (
                          <MenuItem>Please Select a Business Unit</MenuItem>
                        )}
                      </TextField>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Agreement Type'
                        id='agreementType'
                        name='AgreementType'
                        value={this.state.AgreementType}
                        InputLabelProps={{
                          shrink: this.state.AgreementType ? true : false,
                        }}
                        onChange={this.handleTypeChange}
                        variant='outlined'
                        fullWidth
                        select
                        required
                        disabled={this.state.ID}
                      >
                        {this.state.BusinessUnit ? (
                          this.state.Division ? (
                            agreementTypes &&
                            agreementTypes.length > 0 &&
                            agreementTypes.map(
                              (value, index) =>
                                value.BusinessUnit ===
                                  this.state.BusinessUnit &&
                                value.Division === this.state.Division &&
                                value.ActiveYN == 'Y' && (
                                  <MenuItem
                                    key={index}
                                    value={value.AgreementType}
                                  >
                                    {value.Name}
                                  </MenuItem>
                                ),
                            )
                          ) : (
                            <MenuItem>Please Select a Division</MenuItem>
                          )
                        ) : (
                          <MenuItem>Please Select a Business Unit</MenuItem>
                        )}
                      </TextField>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classNames(classes.formControl, '')}
                        label='Price'
                        id='totalPrice'
                        name='TotalPrice'
                        value={this.state.TotalPrice}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.TotalPrice !== null,
                        }}
                        InputProps={{
                          startAdornment: this.state.TotalPrice !== null && (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                          type: 'number',
                        }}
                        fullWidth
                        variant='outlined'
                        required
                        disabled={
                          this.state.ID ||
                          (agreementType && agreementType.RevenueType === 'T')
                        }
                      />
                    </div>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                      {this.state.Data.Customer ? (
                        <TextField
                          className={classNames(classes.formControl, '')}
                          label='Customer'
                          id='Customer'
                          name='Customer'
                          value={this.state.Customer}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.Customer ? true : false,
                          }}
                          fullWidth
                          variant='outlined'
                          disabled={this.state.ID}
                          required
                        />
                      ) : (
                        <CustomerSearchBar
                          onSelected={this.handleCustomerSelect}
                        />
                      )}
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {this.state.Data.Site ? (
                        <TextField
                          className={classNames(classes.formControl, '')}
                          label='Site'
                          id='Site'
                          name='Site'
                          value={this.state.Site}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.Site ? true : false,
                          }}
                          fullWidth
                          variant='outlined'
                          disabled={this.state.ID}
                          required
                        />
                      ) : (
                        <CustomerSiteSearchBar
                          onSelected={this.handleSiteSelect}
                        />
                      )}
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classNames(classes.formControl, '')}
                        label='Agreement'
                        id='Agreement'
                        name='Agreement'
                        value={this.state.Agreement}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.Agreement ? true : false,
                        }}
                        fullWidth
                        variant='outlined'
                        disabled={this.state.ID}
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <div className='sm:flex w-full'>
                        <TextField
                          className={classNames(classes.formControl, '')}
                          label='Cycle'
                          id='cycle'
                          name='Cycle'
                          value={this.state.Cycle}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.Cycle !== null,
                          }}
                          InputProps={{ type: 'number' }}
                          fullWidth
                          variant='outlined'
                          required
                          disabled={this.state.ID}
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classNames(classes.formControl, '')}
                          label='Revision'
                          id='revision'
                          name='Revision'
                          value={this.state.Revision}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.Revision !== null,
                          }}
                          InputProps={{ type: 'number' }}
                          fullWidth
                          variant='outlined'
                          required
                          disabled={this.state.ID}
                        />
                      </div>
                    </div>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>calendar_today</Icon>
                      </div>
                      <DatePicker
                        inputVariant='outlined'
                        className={classNames(classes.formControl, 'sm:w-1/2')}
                        label='Start Date'
                        format='MM/DD/YYYY'
                        id='startDate'
                        name='StartDate'
                        value={this.state.StartDate}
                        InputLabelProps={{
                          shrink: this.state.StartDate ? true : false,
                        }}
                        onChange={this.handleStartDateChange}
                        fullWidth
                        autoOk
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <DatePicker
                        inputVariant='outlined'
                        className={classNames(classes.formControl, 'sm:w-1/2')}
                        label='End Date'
                        format='MM/DD/YYYY'
                        id='endDate'
                        name='EndDate'
                        value={this.state.EndDate}
                        InputLabelProps={{
                          shrink: this.state.EndDate ? true : false,
                        }}
                        onChange={this.handleEndDateChange}
                        fullWidth
                        autoOk
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Sold By'
                        options={technicians}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Technician}>
                              <div className='flex'>
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  classes={{ root: classes.avatarRoot }}
                                  className={classes.avatar}
                                  alt={value.Technician}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? getProfileImage(
                                          `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                        )
                                      : avatar
                                  }
                                />
                                {`${value.FirstName} ${value.LastName}`}
                              </div>
                            </MenuItem>
                          )
                        }}
                        value={this.state.SoldBy}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            SoldBy: option.Technician,
                          })
                        }
                        portal={true}
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Work Order'
                        options={[...workOrderData]}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.WorkOrder}>
                              <div className='flex'>
                                {`${value.WorkOrder} `}
                              </div>
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        portal={!matches}
                        required
                        value={this.state.WorkOrder || ''}
                        onSelect={option => this.handleWorkOrderChange(option)}
                      />
                    </div>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20' />
                      <Autocomplete
                        className={classes.formControl}
                        title='Scope'
                        options={[...FilteredScopeNew]}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Scope}>
                              <div className='flex'>{`${value.Scope}`}</div>
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        portal={!matches}
                        required
                        value={this.state.Scope || ''}
                        disabled={false}
                        onSelect={option =>
                          this.setState({ ...this.state, Scope: option.Scope })
                        }
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Account Manager'
                        options={[
                          { UserName: null, FirstName: 'None', LastName: '' },
                          ...users,
                        ]}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.UserName}>
                              <div className='flex'>
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  classes={{ root: classes.avatarRoot }}
                                  className={classes.userAvatar}
                                  alt={value.Technician}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? getProfileImage(
                                          `Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                        )
                                      : userAvatar
                                  }
                                />
                                {`${value.FirstName} ${value.LastName}`}
                              </div>
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        portal={!matches}
                        value={this.state.AccountManager}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            AccountManager: option.UserName,
                          })
                        }
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classes.formControl}
                        label='Customer PO'
                        id='PONumber'
                        name='PONumber'
                        value={this.state.PONumber}
                        onChange={this.handleChange}
                        variant='outlined'
                        fullWidth
                        InputProps={{
                          startAdornment: Boolean(this.state.PONumber) && (
                            <InputAdornment position='start'>#</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>note</Icon>
                      </div>
                      <TextField
                        className={classes.formControl}
                        label='Notes'
                        id='notes'
                        name='Notes'
                        value={this.state.Notes || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        multiline
                        rows={5}
                        fullWidth
                      />
                    </div>
                    <div className='flex'>
                      <div className='w-1/2'>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.ActiveYN === 'Y'}
                              name='ActiveYN'
                              onChange={this.handleChange}
                              color='primary'
                            />
                          }
                          label='Active?'
                        />
                      </div>
                      {accessLevel &&
                        accessLevel.AccessLevel !== 'R' &&
                        this.state.ID && (
                          <div className='w-1/2'>
                            <Button
                              variant='contained'
                              color='primary'
                              className='mb-6 float-right'
                              onClick={this.newCycle}
                              disabled={this.state.newCycle}
                            >
                              <Icon className='mr-8'>add</Icon>New Cycle
                            </Button>
                          </div>
                        )}
                    </div>
                    {this.state.ID && (
                      <div
                        className='pt-12 mt-12'
                        style={{ borderTop: '1px solid lightgrey' }}
                      >
                        <Comments
                          comments={[...this.state.Data.Comments]}
                          recordID={this.state.ID}
                          type='Agreement'
                          commentAdd={comment => {
                            const { Data } = this.state
                            const { Comments } = Data
                            Comments.splice(0, 0, comment)
                            this.setState({
                              ...this.state,
                              Data: { ...Data, Comments },
                            })
                          }}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <AgreementBillingsList
                  agreementAlreadyExists={this.state.ID !== null}
                  customer={{ ...this.state.Data.Customer }}
                  site={{ ...this.state.Data.Site }}
                  agreement={{ ...this.state }}
                  data={[...this.state.Data.BillingSchedule]}
                />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <AgreementAmortizationsList
                  customer={{ ...this.state.Data.Customer }}
                  site={{ ...this.state.Data.Site }}
                  agreement={{ ...this.state }}
                  data={[...this.state.Data.AmortizationSchedule]}
                />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                >
                  <Icon color='primary' className='text-32 mr-12'>
                    format_list_bulleted
                  </Icon>
                  Agreement Tasks
                </Typography>
                <div
                  className='w-full mb-24 p-12 rounded'
                  style={{ backgroundColor: 'rgb(235, 235, 235)' }}
                >
                  {accessLevel && accessLevel.AccessLevel !== 'R' && (
                    <Menu
                      id='task-menu'
                      anchorEl={taskMenu}
                      keepMounted
                      open={Boolean(taskMenu)}
                      onClose={this.handleTaskMenuClose}
                    >
                      <MenuItem onClick={this.scheduleTask}>
                        <Icon className='mr-8'>assignment</Icon>Schedule Task
                      </MenuItem>
                      <MenuItem onClick={this.waiveTask}>
                        <Icon className='mr-8'>event_busy</Icon>Waive Task
                      </MenuItem>
                    </Menu>
                  )}
                  {accessLevel && accessLevel.AccessLevel !== 'R' && (
                    <Menu
                      id='task-waive-menu'
                      anchorEl={taskWaiveMenu}
                      keepMounted
                      open={Boolean(taskWaiveMenu)}
                      onClose={this.handleTaskWaiveMenuClose}
                    >
                      <div className='p-12 min-w-320 max-w-320'>
                        <Typography className='flex items-center flex-1 font-bold mb-12'>
                          <Icon className='mr-8' color='primary'>
                            assignment
                          </Icon>
                          Waive Task
                        </Typography>
                        <TextField
                          className='w-full mb-12'
                          label='Task Sequence'
                          id='taskSeq'
                          name='taskSeq'
                          value={this.state.taskSeq || ''}
                          onChange={this.handleChange}
                          InputProps={{ type: 'number' }}
                          fullWidth
                          variant='outlined'
                          required
                          select
                        >
                          {this.state.taskIncluded &&
                            this.state.taskIncluded.Data &&
                            this.state.taskIncluded.Data.Recurrences &&
                            this.state.taskIncluded.Data.Recurrences.map(
                              rec => {
                                // window["warn"]('Task Option: ', rec);
                                const count =
                                  this.state.taskIncluded.Data.Recurrences
                                    .length
                                return (
                                  <MenuItem
                                    key={rec.Seq}
                                    value={rec.Seq}
                                    disabled={Boolean(
                                      _.findIndex(
                                        this.state.taskIncluded.Data.Waive,
                                        {
                                          Site:
                                            MultiSiteYN === 'Y'
                                              ? taskSite.Site
                                              : this.state.Site,
                                          Seq: rec.Seq,
                                        },
                                      ) > -1,
                                    )}
                                  >
                                    {`Service #${rec.Seq} of ${count}`}
                                  </MenuItem>
                                )
                              },
                            )}
                        </TextField>
                        <Button
                          variant='contained'
                          color='primary'
                          className='w-full'
                          onClick={this.addWaivedTask}
                          disabled={!this.state.taskSeq}
                        >
                          Waive
                        </Button>
                      </div>
                    </Menu>
                  )}
                  {accessLevel && accessLevel.AccessLevel !== 'R' && (
                    <Menu
                      id='task-schedule-menu'
                      anchorEl={taskScheduleMenu}
                      keepMounted
                      open={Boolean(taskScheduleMenu)}
                      onClose={this.handleTaskScheduleMenuClose}
                    >
                      <div className='p-12 min-w-320 max-w-320'>
                        <Typography className='flex items-center flex-1 font-bold mb-12'>
                          <Icon className='mr-8' color='primary'>
                            assignment
                          </Icon>
                          Assign to Work Order
                        </Typography>
                        <TextField
                          className='w-full mb-12'
                          label='Task Sequence'
                          id='taskSeq'
                          name='taskSeq'
                          value={this.state.taskSeq || ''}
                          onChange={this.handleChange}
                          InputProps={{ type: 'number' }}
                          fullWidth
                          variant='outlined'
                          required
                          select
                        >
                          {this.state.taskIncluded &&
                            this.state.taskIncluded.Data &&
                            this.state.taskIncluded.Data.Recurrences &&
                            _.filter(
                              this.state.taskIncluded.Data.Recurrences,
                              o =>
                                o.Site === (taskSite ? taskSite.Site : o.Site),
                            ).map(rec => {
                              let selectedTaskSchedule = _.find(
                                this.state.taskIncluded.Data.Schedule,
                                {
                                  Site:
                                    MultiSiteYN === 'Y'
                                      ? taskSite.Site
                                      : this.state.Site,
                                  Seq: rec.Seq,
                                },
                              )
                              // window["warn"]('Task Option: ', rec);
                              const count = _.filter(
                                this.state.taskIncluded.Data.Recurrences,
                                o =>
                                  o.Site ===
                                  (taskSite ? taskSite.Site : o.Site),
                              ).length
                              return (
                                <MenuItem
                                  key={rec.Seq}
                                  value={rec.Seq}
                                  disabled={Boolean(selectedTaskSchedule)}
                                >
                                  {`Service #${rec.Seq} of ${count} - ${moment(
                                    rec.Date,
                                  ).format('M/D/YY')}`}
                                </MenuItem>
                              )
                            })}
                        </TextField>
                        <TextField
                          className='w-full mb-12'
                          label='Work Order'
                          id='taskWorkOrder'
                          name='taskWorkOrder'
                          value={this.state.taskWorkOrder}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: this.state.taskWorkOrder ? true : false,
                          }}
                          InputProps={{ type: 'number' }}
                          fullWidth
                          variant='outlined'
                          select
                          required
                        >
                          <MenuItem value={'+'}>
                            <Typography className='flex items-center flex-1'>
                              <Icon className='mr-8'>add</Icon>Create New Work
                              Order
                            </Typography>
                          </MenuItem>
                          {!taskSite &&
                            this.state.Data &&
                            this.state.Data.Site &&
                            this.state.Data.Site.Data &&
                            this.state.Data.Site.Data.WorkOrders &&
                            this.state.Data.Site.Data.WorkOrders.map(
                              (value, index) => {
                                return (
                                  <MenuItem
                                    className='max-w-256 truncate'
                                    key={index}
                                    value={value.WorkOrder}
                                  >
                                    {`${value.WorkOrder} - ${
                                      value.Description || ''.substr(0, 30)
                                    }`}
                                  </MenuItem>
                                )
                              },
                            )}
                          {taskSite &&
                            taskSite.Data &&
                            taskSite.Data.WorkOrders &&
                            taskSite.Data.WorkOrders.map((value, index) => {
                              return (
                                <MenuItem
                                  className='max-w-256 truncate'
                                  key={index}
                                  value={value.WorkOrder}
                                >
                                  {`${value.WorkOrder} - ${
                                    value.Description || ''.substr(0, 30)
                                  }`}
                                </MenuItem>
                              )
                            })}
                        </TextField>
                        {this.state.taskWorkOrder &&
                          this.state.taskWorkOrder !== '+' && (
                            <TextField
                              className='w-full mb-12'
                              label='Scope'
                              id='taskScope'
                              name='taskScope'
                              value={this.state.taskScope}
                              onChange={this.handleChange}
                              InputLabelProps={{
                                shrink: this.state.taskScope ? true : false,
                              }}
                              InputProps={{ type: 'number' }}
                              fullWidth
                              variant='outlined'
                              required
                              select
                            >
                              <MenuItem value={'+'}>
                                <Typography className='flex items-center flex-1'>
                                  <Icon className='mr-8'>add</Icon>Create New
                                  Scope
                                </Typography>
                              </MenuItem>
                              {!taskSite &&
                                this.state.taskInfo &&
                                this.state.taskWorkOrder &&
                                this.state.Data.Site &&
                                this.state.Data.Site.Data &&
                                this.state.Data.Site.Data.WorkOrders &&
                                _.filter(this.state.Data.Site.Data.WorkOrders, {
                                  WorkOrder: this.state.taskWorkOrder,
                                }).map(wo => {
                                  return wo.Data.Scopes.map((value, index) => {
                                    if (
                                      value.BusinessUnit ===
                                        this.state.taskInfo.BusinessUnit &&
                                      value.Division ===
                                        this.state.taskInfo.Division &&
                                      value.Department ===
                                        this.state.taskInfo.Department
                                    ) {
                                      return (
                                        <MenuItem
                                          className='max-w-256 truncate'
                                          key={index}
                                          value={value.Scope}
                                        >
                                          {`${value.Scope} - ${
                                            value.Description ||
                                            ''.substr(0, 30)
                                          }`}
                                        </MenuItem>
                                      )
                                    }
                                  })
                                })}
                              {taskSite &&
                                taskSite.Data &&
                                taskSite.Data.WorkOrders &&
                                _.filter(taskSite.Data.WorkOrders, {
                                  WorkOrder: this.state.taskWorkOrder,
                                }).map(wo => {
                                  return wo.Data.Scopes.map((value, index) => {
                                    if (
                                      value.BusinessUnit ===
                                        this.state.taskInfo.BusinessUnit &&
                                      value.Division ===
                                        this.state.taskInfo.Division &&
                                      value.Department ===
                                        this.state.taskInfo.Department
                                    ) {
                                      return (
                                        <MenuItem
                                          className='max-w-256 truncate'
                                          key={index}
                                          value={value.Scope}
                                        >
                                          {`${value.Scope} - ${
                                            value.Description ||
                                            ''.substr(0, 30)
                                          }`}
                                        </MenuItem>
                                      )
                                    }
                                  })
                                })}
                            </TextField>
                          )}
                        {(this.state.taskWorkOrder === '+' ||
                          this.state.taskScope === '+') && (
                          <DatePicker
                            inputVariant='outlined'
                            className='w-full mb-12'
                            label='Due By'
                            format='MM/DD/YYYY'
                            id='taskDueBy'
                            name='taskDueBy'
                            value={this.state.taskDueBy}
                            InputLabelProps={{
                              shrink: this.state.taskDueBy ? true : false,
                            }}
                            onChange={this.handleTaskDueByChange}
                            fullWidth
                            autoOk
                          />
                        )}
                        {(this.state.taskWorkOrder === '+' ||
                          this.state.taskScope === '+') && (
                          <Autocomplete
                            className='w-full mb-12'
                            title='Arrival Window'
                            options={
                              taskInfo && arrivalWindows
                                ? _.filter(arrivalWindows, {
                                    BusinessUnit: taskInfo.BusinessUnit,
                                  })
                                : []
                            }
                            menuItemComponent={value => {
                              return (
                                <MenuItem value={value.ArrivalWindow}>
                                  {`${value.Description}`}
                                </MenuItem>
                              )
                            }}
                            portal={true}
                            value={this.state.taskArrivalWindow}
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                taskArrivalWindow: option.ArrivalWindow,
                              })
                            }
                            required
                          />
                        )}
                        {(this.state.taskWorkOrder === '+' ||
                          this.state.taskScope === '+') && (
                          <Autocomplete
                            className='w-full mb-12'
                            title='Lead Technician'
                            options={technicians}
                            menuItemComponent={value => {
                              return (
                                value.ActiveYN === 'Y' && (
                                  <MenuItem value={value.Technician}>
                                    <div className='flex'>
                                      <Avatar
                                        style={{ marginRight: 8 }}
                                        classes={{ root: classes.avatarRoot }}
                                        className={classes.avatar}
                                        alt={value.Technician}
                                        src={
                                          value.Data && value.Data.Avatar
                                            ? getProfileImage(
                                                `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                              )
                                            : avatar
                                        }
                                      />
                                      {`${value.FirstName} ${value.LastName}`}
                                    </div>
                                  </MenuItem>
                                )
                              )
                            }}
                            value={this.state.taskTechnician}
                            portal={true}
                            onSelect={option =>
                              this.setState({
                                ...this.state,
                                taskTechnician: option.Technician,
                              })
                            }
                            required
                          />
                        )}
                        <Button
                          variant='contained'
                          color='primary'
                          className='w-full'
                          onClick={this.addTaskToWorkOrder}
                          disabled={
                            !this.state.taskWorkOrder || !this.state.taskSeq
                          }
                        >
                          Add
                        </Button>
                      </div>
                    </Menu>
                  )}
                  {this.state.Data.AgreementType &&
                    this.state.Data.AgreementType.Data &&
                    this.state.Data.AgreementType.Data.Tasks.map(
                      (task, index) => {
                        const {
                          ID,
                          ActiveYN,
                          Co,
                          BusinessUnit,
                          Division,
                          AgreementType,
                          Data,
                        } = this.state
                        const { Schedule, Site, Tasks } = Data
                        const Included = _.find(Tasks, {
                          TaskType: task.TaskType,
                        })
                        const Scheduled = _.find(Schedule, {
                          TaskType: task.TaskType,
                        })
                        const type = _.find(agreementTypes, {
                          BusinessUnit,
                          Division,
                          AgreementType,
                        })
                        const len = type.Data.Tasks.length
                        const original = type
                          ? _.find(type.Data.Tasks, { TaskType: task.TaskType })
                          : null
                        return (
                          <Card
                            className={classNames(
                              classes.taskCard,
                              index === len - 1 ? 'mb-0' : '',
                            )}
                            key={index}
                          >
                            <CardHeader
                              className={classes.taskCardHeader}
                              classes={{
                                action: 'sm:mt-0',
                                root: 'p-8',
                                avatar: 'mr-12',
                                title: 'leading-none',
                                subheader: 'leading-none',
                              }}
                              avatar={
                                <Badge
                                  onClick={() => {
                                    if (!Scheduled) {
                                      this.handleClickTask(task.TaskType)
                                    }
                                  }}
                                  classes={{
                                    badge: classNames(
                                      'cursor-pointer',
                                      classes.taskBadgeSimple,
                                      classes.grey,
                                    ),
                                  }}
                                  badgeContent={
                                    Included && Included.Data.Units
                                      ? Included.Data.Units.length
                                      : 0
                                  }
                                >
                                  <Icon
                                    className={classNames(
                                      'cursor-pointer text-24',
                                      Scheduled
                                        ? classes.greenText
                                        : classes.greyText,
                                    )}
                                    style={{ fontSize: 28 }}
                                  >
                                    {Scheduled
                                      ? 'check_circle_outline'
                                      : Included
                                        ? 'radio_button_checked'
                                        : 'radio_button_unchecked'}
                                  </Icon>
                                </Badge>
                              }
                              title={
                                <label className='text-12 font-bold'>
                                  {task.Name}
                                </label>
                              }
                              subheader={
                                <TextField
                                  className={classNames('')}
                                  // label={task.Frequency === '*' || (!task.Frequency && !this.state.TaskFrequency) && "Task Frequency"}
                                  style={{ margin: 0 }}
                                  name='TaskFrequency'
                                  value={
                                    Included
                                      ? Included.Frequency || task.Frequency
                                      : task.Frequency || ''
                                  }
                                  onChange={e =>
                                    this.updateTaskFrequency(
                                      task.TaskType,
                                      e.target.value,
                                    )
                                  }
                                  margin='dense'
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      fontSize: 11,
                                      paddingTop: 4,
                                      color: '#9f9f9f',
                                    },
                                    classes: {
                                      input: 'p-0 pr-24',
                                    },
                                  }}
                                  select
                                  disabled={
                                    original &&
                                    original.Frequency &&
                                    original.Frequency !== '*'
                                  }
                                >
                                  <MenuItem value='*'>
                                    Select Task Frequency
                                  </MenuItem>
                                  <MenuItem value='M'>Monthly</MenuItem>
                                  <MenuItem value='B'>Bi-Monthly</MenuItem>
                                  <MenuItem value='Q'>Quarterly</MenuItem>
                                  <MenuItem value='S'>Semi-Annual</MenuItem>
                                  <MenuItem value='A'>Annual</MenuItem>
                                </TextField>
                              }
                              action={
                                Included && (
                                  <React.Fragment>
                                    <label
                                      className={`text-12 font-bold mx-12`}
                                    >
                                      ${Included.Revenue}
                                    </label>
                                    {accessLevel &&
                                      accessLevel.AccessLevel !== 'R' &&
                                      !this.state.needsRevision &&
                                      !this.state.newCycle &&
                                      ID &&
                                      MultiSiteYN !== 'Y' &&
                                      ActiveYN === 'Y' && (
                                        <IconButton
                                          className='mr-4'
                                          onClick={e =>
                                            this.handleTaskMenuOpen(
                                              e.currentTarget,
                                              task,
                                              Included,
                                            )
                                          }
                                          aria-label='settings'
                                        >
                                          <Icon className='text-16'>
                                            more_vert
                                          </Icon>
                                        </IconButton>
                                      )}
                                  </React.Fragment>
                                )
                              }
                            />
                            {Included &&
                              ['A', 'S', 'Q', 'B', 'M'].indexOf(
                                Included.Frequency || task.Frequency,
                              ) > -1 && (
                                <CardContent
                                  classes={{
                                    root: classNames(
                                      'p-8 overflow-x-auto',
                                      classes.frequency,
                                      (Included.Frequency || task.Frequency) ===
                                        'M'
                                        ? 'hidden'
                                        : '',
                                    ),
                                  }}
                                >
                                  <Recurrence
                                    term={
                                      this.state.TermLength ||
                                      AgreementType.TermLength
                                    }
                                    onChange={(Schedule, manual) =>
                                      this.setTaskRecurrenceSchedule(
                                        task,
                                        Schedule,
                                        manual,
                                      )
                                    }
                                    data={Included.Data.Recurrences}
                                    value='Date'
                                    disabled={
                                      Boolean(this.state.ID) &&
                                      !this.state.needsRevision &&
                                      !this.state.newCycle
                                    }
                                    start={
                                      (Included.Frequency || task.Frequency) ===
                                      'A'
                                        ? Included &&
                                          Included.Data &&
                                          Included.Data.Recurrences &&
                                          Included.Data.Recurrences[0]
                                          ? Included.Data.Recurrences[0].Date ||
                                            task.DateMedian ||
                                            this.state.StartDate
                                          : task.DateMedian ||
                                            this.state.StartDate
                                        : this.state.StartDate
                                    }
                                    frequency={
                                      Included.Frequency || task.Frequency
                                    }
                                  />
                                </CardContent>
                              )}
                            {this.state.MultiSiteYN === 'Y' &&
                              this.state.Data.Sites &&
                              this.state.Data.Sites.map(st => {
                                const site = _.find(
                                  this.state.Data.Customer.Data.Sites,
                                  { Site: st.Site },
                                )
                                if (site) {
                                  const { Units } = Included
                                    ? Included.Data
                                    : {}
                                  const units = Units
                                    ? _.filter(Units, { Site: site.Site })
                                    : null
                                  // window["warn"](`${site.Site} Units: `, units);
                                  return (
                                    <div className='w-full'>
                                      <CardHeader
                                        key={site.Site}
                                        className={classes.taskCardUnit}
                                        classes={{
                                          action: 'sm:mt-0',
                                          root: 'p-8 pl-16',
                                          avatar: 'mr-8',
                                          title: 'leading-none',
                                          subheader: 'leading-none',
                                        }}
                                        avatar={
                                          <img
                                            className={classNames(
                                              classes.unitAvatar,
                                              'w-20 h-auto',
                                            )}
                                            style={{ cursor: 'pointer' }}
                                            alt='Service Site Icon'
                                            src={siteAvatar}
                                          />
                                        }
                                        title={
                                          <label className='text-12 font-bold'>
                                            Site #{site.Site}
                                          </label>
                                        }
                                        subheader={
                                          <label className='text-10'>
                                            {site.Description}
                                          </label>
                                        }
                                        action={
                                          Included &&
                                          !this.state.needsRevision &&
                                          !this.state.newCycle &&
                                          accessLevel &&
                                          accessLevel.AccessLevel !== 'R' &&
                                          ID &&
                                          ActiveYN === 'Y' && (
                                            <IconButton
                                              className='mr-4'
                                              onClick={e =>
                                                this.handleTaskMenuOpen(
                                                  e.currentTarget,
                                                  task,
                                                  Included,
                                                  site,
                                                )
                                              }
                                              aria-label='settings'
                                            >
                                              <Icon className='text-16'>
                                                more_vert
                                              </Icon>
                                            </IconButton>
                                          )
                                        }
                                      />
                                      {units &&
                                        units.map(Unit => {
                                          const unit = _.find(site.Data.Units, {
                                            UnitID: Unit.UnitID,
                                          })
                                          return (
                                            <CardHeader
                                              key={unit.UnitID}
                                              className={classes.taskCardUnit}
                                              classes={{
                                                action: 'sm:mt-0',
                                                root: classNames(
                                                  'p-8 ml-24 pl-12',
                                                  classes.taskSiteUnit,
                                                ),
                                                avatar: 'mr-12',
                                                title: 'leading-none truncate',
                                                subheader:
                                                  'leading-none truncate',
                                              }}
                                              avatar={
                                                <Avatar
                                                  className={classNames(
                                                    classes.unitAvatar,
                                                    'w-20 h-20',
                                                  )}
                                                  style={{
                                                    cursor: 'pointer',
                                                    boxShadow:
                                                      '2px 2px 4px 0px #3f3f3f',
                                                  }}
                                                  alt='Service Unit Image'
                                                  src={
                                                    unit.Data &&
                                                    unit.Data.Avatar
                                                      ? !isNaN(unit.Data.Avatar)
                                                        ? getProfileImage(
                                                            `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                          )
                                                        : unit.Data.Avatar
                                                      : unit.avatar
                                                        ? unit.avatar
                                                        : avatar
                                                  }
                                                />
                                              }
                                              title={
                                                <label className='text-11 font-bold'>
                                                  {unit.Name}
                                                </label>
                                              }
                                              subheader={
                                                <label className='text-10'>
                                                  {unit.UnitID}
                                                </label>
                                              }
                                              action={
                                                accessLevel &&
                                                accessLevel.AccessLevel !==
                                                  'R' && (
                                                  <IconButton
                                                    className='mr-4'
                                                    onClick={() =>
                                                      openEditServiceUnitDialog(
                                                        unit,
                                                      )
                                                    }
                                                    aria-label='edit'
                                                  >
                                                    <Icon className='text-16'>
                                                      edit
                                                    </Icon>
                                                  </IconButton>
                                                )
                                              }
                                            />
                                          )
                                        })}
                                      {accessLevel &&
                                        accessLevel.AccessLevel !== 'R' &&
                                        Included &&
                                        task.UnitCat && (
                                          <CardHeader
                                            onClick={() =>
                                              openNewServiceUnitDialog({
                                                Co,
                                                Customer: site.Customer,
                                                Site: site.Site,
                                                UnitCat: task.UnitCat,
                                                UnitType: task.UnitType,
                                              })
                                            }
                                            className='cursor-pointer'
                                            classes={{
                                              root: classNames(
                                                'p-8 ml-24 pl-12',
                                                classes.taskSiteUnit,
                                              ),
                                              avatar: 'pl-4 pt-6 mr-16',
                                              action: 'mt-0',
                                            }}
                                            action={
                                              <div className='flex mr-8 mr-16'>
                                                <Icon
                                                  className='text-14 mr-4'
                                                  color='primary'
                                                >
                                                  add_circle_outline
                                                </Icon>
                                                <label className='text-10 font-bold cursor-pointer'>
                                                  Add Service Unit
                                                </label>
                                              </div>
                                            }
                                          />
                                        )}
                                    </div>
                                  )
                                }
                              })}
                            {this.state.MultiSiteYN !== 'Y' && (
                              <React.Fragment>
                                {Included &&
                                  Included.Data.Units.map(Unit => {
                                    const unit = _.find(Site.Data.Units, {
                                      UnitID: Unit.UnitID,
                                    })
                                    return (
                                      <CardHeader
                                        key={unit.UnitID}
                                        className={classes.taskCardUnit}
                                        classes={{
                                          action: 'sm:mt-0',
                                          root: 'p-8 pl-16',
                                          avatar: 'mr-12',
                                          title: 'leading-none',
                                          subheader: 'leading-none',
                                        }}
                                        avatar={
                                          <Avatar
                                            className={classNames(
                                              classes.unitAvatar,
                                              'w-20 h-20',
                                            )}
                                            style={{
                                              cursor: 'pointer',
                                              boxShadow:
                                                '2px 2px 4px 0px #3f3f3f',
                                            }}
                                            alt='Service Unit Image'
                                            src={
                                              unit.Data && unit.Data.Avatar
                                                ? !isNaN(unit.Data.Avatar)
                                                  ? getProfileImage(
                                                      `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                    )
                                                  : unit.Data.Avatar
                                                : unit.avatar
                                                  ? unit.avatar
                                                  : avatar
                                            }
                                          />
                                        }
                                        title={
                                          <label className='text-12 font-bold'>
                                            {unit.Name}
                                          </label>
                                        }
                                        subheader={
                                          <label className='text-10'>
                                            {unit.UnitID}
                                          </label>
                                        }
                                        action={
                                          accessLevel &&
                                          accessLevel.AccessLevel !== 'R' && (
                                            <IconButton
                                              className='mr-4'
                                              onClick={() =>
                                                openEditServiceUnitDialog(unit)
                                              }
                                              aria-label='edit'
                                            >
                                              <Icon className='text-16'>
                                                edit
                                              </Icon>
                                            </IconButton>
                                          )
                                        }
                                      />
                                    )
                                  })}
                                {accessLevel &&
                                  accessLevel.AccessLevel !== 'R' &&
                                  Included &&
                                  task.UnitCat &&
                                  Site && (
                                    <CardHeader
                                      onClick={() =>
                                        openNewServiceUnitDialog({
                                          Co,
                                          Customer: Site.Customer,
                                          Site: Site.Site,
                                          UnitCat: task.UnitCat,
                                          UnitType: task.UnitType,
                                        })
                                      }
                                      className='cursor-pointer'
                                      classes={{
                                        root: 'p-8',
                                        avatar: 'pl-4 pt-6 mr-16',
                                        action: 'mt-0',
                                      }}
                                      action={
                                        <div className='flex mr-8 mr-16'>
                                          <Icon
                                            className='text-14 mr-4'
                                            color='primary'
                                          >
                                            add_circle_outline
                                          </Icon>
                                          <label className='text-10 font-bold cursor-pointer'>
                                            Add Service Unit
                                          </label>
                                        </div>
                                      }
                                    />
                                  )}
                              </React.Fragment>
                            )}
                          </Card>
                        )
                      },
                    )}
                </div>
              </TabContainer>
            )}
            {value === 4 && (
              <TabContainer>
                {accessLevel && accessLevel.AccessLevel !== 'R' && (
                  <Menu
                    id='scheduled-task-menu'
                    anchorEl={schedTaskMenu}
                    keepMounted
                    open={Boolean(schedTaskMenu)}
                    onClose={this.handleSchedTaskMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        this.props.removeAgreementTask(this.state.scheduledTask)
                        this.handleSchedTaskMenuClose()
                      }}
                    >
                      Remove Task
                    </MenuItem>
                  </Menu>
                )}
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                >
                  <Icon color='primary' className='text-32 mr-12'>
                    date_range
                  </Icon>
                  Scheduled Tasks
                </Typography>
                <div
                  className='w-full mb-24 p-12 rounded'
                  style={{ backgroundColor: 'rgb(235, 235, 235)' }}
                >
                  <div className='w-full'>
                    {this.state.Data.AgreementType &&
                      this.state.Data.AgreementType.Data &&
                      this.state.Data.AgreementType.Data.Tasks.map(
                        (task, index) => {
                          const {
                            ID,
                            ActiveYN,
                            Co,
                            BusinessUnit,
                            Division,
                            AgreementType,
                            Data,
                          } = this.state
                          const { Schedule, Site, Tasks } = Data
                          const Included = _.find(Tasks, {
                            TaskType: task.TaskType,
                          })
                          const recur =
                            Included && Included.Data
                              ? Included.Data.Recurrences
                              : []
                          const Scheduled =
                            Included && Included.Data
                              ? Included.Data.Schedule
                              : []
                          // window["warn"]("updatetaskprices scheduled 2", Included, this.state.Data.AgreementType, Scheduled)
                          const type = _.find(agreementTypes, {
                            BusinessUnit,
                            Division,
                            AgreementType,
                          })
                          const len = type.Data.Tasks.length
                          const original = type
                            ? _.find(type.Data.Tasks, {
                                TaskType: task.TaskType,
                              })
                            : null
                          return (
                            <Card
                              className={classNames(
                                classes.taskCard,
                                index === len - 1 ? 'mb-0' : '',
                              )}
                              key={index}
                            >
                              <CardHeader
                                className={classes.taskCardHeader}
                                classes={{
                                  action: 'sm:mt-0',
                                  root: 'p-8',
                                  avatar: 'mr-12',
                                  title: 'leading-none',
                                  subheader: 'leading-none',
                                }}
                                avatar={
                                  <Badge
                                    onClick={() => {
                                      if (!Scheduled) {
                                        this.handleClickTask(task.TaskType)
                                      }
                                    }}
                                    classes={{
                                      badge: classNames(
                                        'cursor-pointer',
                                        classes.taskBadgeSimple,
                                        classes.grey,
                                      ),
                                    }}
                                    badgeContent={
                                      Included && Included.Data.Units
                                        ? Included.Data.Units.length
                                        : 0
                                    }
                                  >
                                    <Icon
                                      className={classNames(
                                        'cursor-pointer text-24',
                                        Scheduled
                                          ? classes.greenText
                                          : classes.greyText,
                                      )}
                                      style={{ fontSize: 28 }}
                                    >
                                      {Scheduled
                                        ? 'check_circle_outline'
                                        : Included
                                          ? 'radio_button_checked'
                                          : 'radio_button_unchecked'}
                                    </Icon>
                                  </Badge>
                                }
                                title={
                                  <label className='text-12 font-bold'>
                                    {task.Name}
                                  </label>
                                }
                                subheader={
                                  <TextField
                                    className={classNames('')}
                                    // label={task.Frequency === '*' || (!task.Frequency && !this.state.TaskFrequency) && "Task Frequency"}
                                    style={{ margin: 0 }}
                                    name='TaskFrequency'
                                    value={
                                      Included
                                        ? Included.Frequency || task.Frequency
                                        : task.Frequency || ''
                                    }
                                    onChange={e =>
                                      this.updateTaskFrequency(
                                        task.TaskType,
                                        e.target.value,
                                      )
                                    }
                                    margin='dense'
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        fontSize: 11,
                                        paddingTop: 4,
                                        color: '#9f9f9f',
                                      },
                                      classes: {
                                        input: 'p-0 pr-24',
                                      },
                                    }}
                                    select
                                    disabled={
                                      original &&
                                      original.Frequency &&
                                      original.Frequency !== '*'
                                    }
                                  >
                                    <MenuItem value='*'>
                                      Select Task Frequency
                                    </MenuItem>
                                    <MenuItem value='M'>Monthly</MenuItem>
                                    <MenuItem value='B'>Bi-Monthly</MenuItem>
                                    <MenuItem value='Q'>Quarterly</MenuItem>
                                    <MenuItem value='S'>Semi-Annual</MenuItem>
                                    <MenuItem value='A'>Annual</MenuItem>
                                  </TextField>
                                }
                                action={
                                  Included && (
                                    <React.Fragment>
                                      {/* <Button disabled={val.Status === 4} onClick={() => this.props.openEditWorkOrderDialog({ Co: val.Co, WorkOrder: val.WorkOrder })} className="ml-12 h-24 text-12" color="primary" variant="contained">{val.Status === 4 ? "Waived" : `WO #${val.WorkOrder}-${val.Scope}`}</Button> */}
                                      <label
                                        className={`text-12 font-bold mx-12`}
                                      >
                                        {Scheduled.length} of {recur.length}
                                      </label>
                                    </React.Fragment>
                                  )
                                }
                              />
                              {this.state.MultiSiteYN === 'Y' &&
                                this.state.Data.Sites &&
                                this.state.Data.Sites.map(st => {
                                  const site = _.find(
                                    this.state.Data.Customer.Data.Sites,
                                    { Site: st.Site },
                                  )
                                  if (site) {
                                    const { Units } = Included
                                      ? Included.Data
                                      : {}
                                    const units = Units
                                      ? _.filter(Units, { Site: site.Site })
                                      : null
                                    // window["warn"](`${site.Site} Units: `, units);
                                    const siteSchedule = _.filter(Scheduled, {
                                      Site: site.Site,
                                    })
                                    if (siteSchedule.length) {
                                      return (
                                        <div className='w-full'>
                                          <CardHeader
                                            key={site.Site}
                                            className={classes.taskCardUnit}
                                            classes={{
                                              action: 'sm:mt-0',
                                              root: 'p-8 pl-16',
                                              avatar: 'mr-8',
                                              title: 'leading-none',
                                              subheader: 'leading-none',
                                            }}
                                            avatar={
                                              <img
                                                className={classNames(
                                                  classes.unitAvatar,
                                                  'w-20 h-auto',
                                                )}
                                                style={{ cursor: 'pointer' }}
                                                alt='Service Site Icon'
                                                src={siteAvatar}
                                              />
                                            }
                                            title={
                                              <label className='text-12 font-bold'>
                                                Site #{site.Site}
                                              </label>
                                            }
                                            subheader={
                                              <label className='text-10'>
                                                {site.Description}
                                              </label>
                                            }
                                          />
                                          {siteSchedule.map((val, index) => {
                                            return (
                                              <div className='w-full'>
                                                <CardHeader
                                                  key={site.Site}
                                                  className={
                                                    classes.taskCardUnit
                                                  }
                                                  classes={{
                                                    action: 'sm:mt-0',
                                                    root: 'p-0 px-8 pl-24',
                                                    avatar: 'mr-8',
                                                    title: 'leading-none',
                                                    subheader: 'leading-none',
                                                  }}
                                                  avatar={
                                                    <Icon color='action'>
                                                      today
                                                    </Icon>
                                                  }
                                                  title={
                                                    <label className='text-12 font-bold'>
                                                      Task Seq #{val.Seq}
                                                    </label>
                                                  }
                                                  subheader={
                                                    <label className='text-10'>
                                                      {moment(
                                                        val.EnteredDate,
                                                      ).format('MM/DD/YYYY')}
                                                    </label>
                                                  }
                                                  action={
                                                    <React.Fragment>
                                                      <Button
                                                        disabled={
                                                          val.Status === 4
                                                        }
                                                        onClick={() =>
                                                          this.props.openEditWorkOrderDialog(
                                                            {
                                                              Co: val.Co,
                                                              WorkOrder:
                                                                val.WorkOrder,
                                                            },
                                                          )
                                                        }
                                                        className='ml-12 h-24 text-12'
                                                        color='primary'
                                                        variant='contained'
                                                      >
                                                        {val.Status === 4
                                                          ? 'Waived'
                                                          : `WO #${val.WorkOrder}-${val.Scope}`}
                                                      </Button>
                                                      {accessLevel &&
                                                        accessLevel.AccessLevel !==
                                                          'R' && (
                                                          <IconButton
                                                            onClick={e =>
                                                              this.setSchedTaskMenu(
                                                                e,
                                                                val,
                                                              )
                                                            }
                                                            aria-label='settings'
                                                          >
                                                            <Icon>
                                                              more_vert
                                                            </Icon>
                                                          </IconButton>
                                                        )}
                                                    </React.Fragment>
                                                  }
                                                />
                                                {units &&
                                                  units.map(Unit => {
                                                    const unit = _.find(
                                                      site.Data.Units,
                                                      { UnitID: Unit.UnitID },
                                                    )
                                                    return (
                                                      <CardHeader
                                                        key={unit.UnitID}
                                                        className={
                                                          classes.taskCardUnit
                                                        }
                                                        classes={{
                                                          action: 'sm:mt-0',
                                                          root: classNames(
                                                            'p-8 ml-24 pl-12',
                                                            classes.taskSiteUnit,
                                                          ),
                                                          avatar: 'mr-12',
                                                          title:
                                                            'leading-none truncate',
                                                          subheader:
                                                            'leading-none truncate',
                                                        }}
                                                        avatar={
                                                          <Avatar
                                                            className={classNames(
                                                              classes.unitAvatar,
                                                              'w-20 h-20',
                                                            )}
                                                            style={{
                                                              cursor: 'pointer',
                                                              boxShadow:
                                                                '2px 2px 4px 0px #3f3f3f',
                                                            }}
                                                            alt='Service Unit Image'
                                                            src={
                                                              unit.Data &&
                                                              unit.Data.Avatar
                                                                ? !isNaN(
                                                                    unit.Data
                                                                      .Avatar,
                                                                  )
                                                                  ? getProfileImage(
                                                                      `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                                    )
                                                                  : unit.Data
                                                                      .Avatar
                                                                : unit.avatar
                                                                  ? unit.avatar
                                                                  : avatar
                                                            }
                                                          />
                                                        }
                                                        title={
                                                          <label className='text-11 font-bold'>
                                                            {unit.Name}
                                                          </label>
                                                        }
                                                        subheader={
                                                          <label className='text-10'>
                                                            {unit.UnitID}
                                                          </label>
                                                        }
                                                        action={
                                                          accessLevel &&
                                                          accessLevel.AccessLevel !==
                                                            'R' && (
                                                            <IconButton
                                                              className='mr-4'
                                                              onClick={() =>
                                                                openEditServiceUnitDialog(
                                                                  unit,
                                                                )
                                                              }
                                                              aria-label='edit'
                                                            >
                                                              <Icon className='text-16'>
                                                                edit
                                                              </Icon>
                                                            </IconButton>
                                                          )
                                                        }
                                                      />
                                                    )
                                                  })}
                                              </div>
                                            )
                                          })}
                                        </div>
                                      )
                                    }
                                  }
                                })}
                              {this.state.MultiSiteYN !== 'Y' &&
                                Scheduled &&
                                Scheduled.map(val => {
                                  return (
                                    <div className='w-full'>
                                      <CardHeader
                                        key={val.valID}
                                        className={classes.taskCardval}
                                        classes={{
                                          action: 'sm:mt-0',
                                          root: 'p-8 pl-16 py-0',
                                          avatar: 'mr-12',
                                          title: 'leading-none',
                                          subheader: 'leading-none',
                                        }}
                                        style={{
                                          borderBottom: '1px solid lightgrey',
                                        }}
                                        avatar={
                                          <Icon color='action'>build</Icon>
                                        }
                                        title={
                                          <label className='text-12 font-bold'>
                                            Seq #{val.Seq} of{' '}
                                            {Included.Data.Schedule.length}
                                          </label>
                                        }
                                        // subheader={new Date(val.Date).toLocaleDateString('en-US')}
                                        action={
                                          <React.Fragment>
                                            <Button
                                              disabled={val.Status === 4}
                                              onClick={() =>
                                                this.props.openEditWorkOrderDialog(
                                                  {
                                                    Co: val.Co,
                                                    WorkOrder: val.WorkOrder,
                                                  },
                                                )
                                              }
                                              className='ml-12 h-24 text-12'
                                              color='primary'
                                              variant='contained'
                                            >
                                              {val.Status === 4
                                                ? 'Waived'
                                                : `WO #${val.WorkOrder}-${val.Scope}`}
                                            </Button>
                                            {accessLevel &&
                                              accessLevel.AccessLevel !== 'R' &&
                                              !this.state.needsRevision &&
                                              !this.state.newCycle &&
                                              ID &&
                                              MultiSiteYN !== 'Y' &&
                                              ActiveYN === 'Y' && (
                                                <IconButton
                                                  onClick={e =>
                                                    this.setSchedTaskMenu(
                                                      e,
                                                      val,
                                                    )
                                                  }
                                                  aria-label='settings'
                                                >
                                                  <Icon>more_vert</Icon>
                                                </IconButton>
                                              )}
                                          </React.Fragment>
                                        }
                                      />
                                      {Included &&
                                        Included.Data.Units.map(Unit => {
                                          const unit = _.find(Site.Data.Units, {
                                            UnitID: Unit.UnitID,
                                          })
                                          return (
                                            <CardHeader
                                              key={unit.UnitID}
                                              className={classes.taskCardUnit}
                                              classes={{
                                                action: 'sm:mt-0',
                                                root: 'p-0 px-8 pl-24',
                                                avatar: 'mr-12',
                                                title: 'leading-none',
                                                subheader: 'leading-none',
                                              }}
                                              avatar={
                                                <Avatar
                                                  className={classNames(
                                                    classes.unitAvatar,
                                                    'w-20 h-20',
                                                  )}
                                                  style={{
                                                    cursor: 'pointer',
                                                    boxShadow:
                                                      '2px 2px 4px 0px #3f3f3f',
                                                  }}
                                                  alt='Service Unit Image'
                                                  src={
                                                    unit.Data &&
                                                    unit.Data.Avatar
                                                      ? !isNaN(unit.Data.Avatar)
                                                        ? getProfileImage(
                                                            `Co=${unit.Co}&ID=${unit.Data.Avatar}`,
                                                          )
                                                        : unit.Data.Avatar
                                                      : unit.avatar
                                                        ? unit.avatar
                                                        : avatar
                                                  }
                                                />
                                              }
                                              title={
                                                <label className='text-12 font-bold'>
                                                  {unit.Name}
                                                </label>
                                              }
                                              subheader={
                                                <label className='text-10'>
                                                  {unit.UnitID}
                                                </label>
                                              }
                                              action={
                                                accessLevel &&
                                                accessLevel.AccessLevel !==
                                                  'R' && (
                                                  <IconButton
                                                    className='mr-4'
                                                    onClick={() =>
                                                      openEditServiceUnitDialog(
                                                        unit,
                                                      )
                                                    }
                                                    aria-label='edit'
                                                  >
                                                    <Icon className='text-16'>
                                                      edit
                                                    </Icon>
                                                  </IconButton>
                                                )
                                              }
                                            />
                                          )
                                        })}
                                    </div>
                                  )
                                })}
                            </Card>
                          )
                        },
                      )}
                    {/*
                                        scheduledTasks && scheduledTasks.map((value, index) => {
                                            const { Co, Data } = this.state;
                                            const { AgreementType, Schedule, Site, Tasks } = Data;
                                            const task = _.find(AgreementType.Data.Tasks, { TaskType: value.TaskType });
                                            const Included = _.find(Tasks, { TaskType: value.TaskType });
                                            const Scheduled = value;
                                            return (
                                                <Card className={classes.taskCard} key={index}>
                                                    <CardHeader
                                                        className={classes.taskCardHeader}
                                                        classes={{ action: "sm:mt-0" }}
                                                        avatar={
                                                            <Badge classes={{ badge: classNames("cursor-pointer", classes.taskBadge, classes.grey) }} badgeContent={Included && Included.Data.Units ? Included.Data.Units.length : 0}>
                                                                <Icon className={classNames("cursor-pointer text-32", Scheduled ? classes.greenText : classes.greyText)}>{Scheduled ? "check_circle_outline" : Included ? "radio_button_checked" : "radio_button_unchecked"}</Icon>
                                                            </Badge>
                                                        }
                                                        title={<label className="text-16 font-bold">{task.Name}</label>}
                                                        subheader={<label className="text-12">{`${task.UnitCat ? task.UnitCat : ''}${task.UnitType ? ` - ${task.UnitType}` : ''}`}</label>}
                                                        action={
                                                            (accessLevel && accessLevel.AccessLevel !== "R") && Included &&
                                                            <React.Fragment>
                                                                <Button disabled={Scheduled.Status === 4} onClick={() => this.props.openEditWorkOrderDialog({ Co: Scheduled.Co, WorkOrder: Scheduled.WorkOrder })} className="ml-12 h-24 text-12" color="primary" variant="contained">{Scheduled.Status === 4 ? "Waived" : `WO #${Scheduled.WorkOrder}-${Scheduled.Scope}`}</Button>
                                                                <IconButton onClick={(e) => this.setSchedTaskMenu(e, Scheduled)} aria-label="settings">
                                                                    <Icon>more_vert</Icon>
                                                                </IconButton>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    {
                                                        Included && Included.Data.Units.map((Unit, uIndex) => {
                                                            const unit = _.find(Site.Data.Units, { UnitID: Unit.UnitID });
                                                            return (
                                                                <CardHeader
                                                                    key={uIndex}
                                                                    className={classes.taskCardUnit}
                                                                    classes={{ action: "sm:mt-0" }}
                                                                    avatar={
                                                                        <Avatar className={classes.unitAvatar} style={{ cursor: 'pointer', boxShadow: '2px 2px 4px 0px #3f3f3f' }} alt="Service Unit Image" src={(unit.Data && unit.Data.Avatar ? (!isNaN(unit.Data.Avatar) ? getProfileImage(`Co=${unit.Co}&ID=${unit.Data.Avatar}` ): unit.Data.Avatar) : unit.avatar ? unit.avatar : avatar)} />
                                                                    }
                                                                    title={<label className="text-16 font-bold">{unit.Name}</label>}
                                                                    subheader={<label className="text-12">{unit.UnitID}</label>}
                                                                    action={(accessLevel && accessLevel.AccessLevel !== "R") &&
                                                                        <IconButton onClick={() => openEditServiceUnitDialog(unit)} aria-label="edit">
                                                                            <Icon>edit</Icon>
                                                                        </IconButton>
                                                                    }
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Card>
                                            )
                                        })
                                    */}
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 5 && (
              <TabContainer>
                <ServiceUnitsList
                  selectedServiceUnitData={[...this.state.Data.Site.Data.Units]}
                />
              </TabContainer>
            )}

            {
              value === 6 && (
                <TabContainer>
                  <AgreementFormDialog
                    onSignature={this.handleSignature}
                    onSignedBy={this.handleSignedBy}
                    data={this.state}
                    open={true}
                  />
                </TabContainer>
              ) /* :
                            advanced === "Y" ?
                                <AgreementFormDialog hidden={value !== 4} onSignature={this.handleSignature} onSignedBy={this.handleSignedBy} data={this.state} open={true} /> :
                                ''*/
            }

            {value === 7 && (
              <TabContainer>
                <AgreementsList
                  agreement={this.state}
                  selectedAgreementData={[...this.state.Data.Variations]}
                />
              </TabContainer>
            )}

            {value === 8 && (
              <TabContainer>
                <FileList attachments={[...this.state.Data.Attachments]} />
              </TabContainer>
            )}
            {accessLevel &&
              accessLevel.AccessLevel !== 'R' &&
              value < 2 &&
              advanced === 'Y' && (
                <DialogActions className='dialog-actions justify-between pl-16 dialog-actions'>
                  {agreementDialog.type === 'new' || !this.state.ID ? (
                    <React.Fragment>
                      {this.state.EstimateNumber ? (
                        <Button
                          variant='contained'
                          color='primary'
                          className='mb-6'
                          onClick={() => {
                            if (agreementDialog.props.onAdded) {
                              this.setState(
                                { ...this.state, adding: true },
                                () => {
                                  Promise.all([
                                    agreementDialog.props.onAdded(
                                      _.cloneDeepWith(this.state),
                                    ),
                                  ]).then(() =>
                                    this.setState(
                                      { ...this.state, adding: false },
                                      this.closeComposeDialog,
                                    ),
                                  )
                                },
                              )
                            }
                            if (agreementDialog.props.onUpdated) {
                              this.setState(
                                { ...this.state, adding: true },
                                () => {
                                  Promise.all([
                                    agreementDialog.props.onUpdated(
                                      _.cloneDeepWith(this.state),
                                    ),
                                  ]).then(() =>
                                    this.setState(
                                      { ...this.state, adding: false },
                                      this.closeComposeDialog,
                                    ),
                                  )
                                },
                              )
                            }
                          }}
                          disabled={!this.canBeSubmitted()}
                        >
                          Add to Estimate
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          className='mb-6'
                          onClick={() => {
                            const val = _.cloneDeepWith(this.state)

                            delete val.Data.WorkOrder
                            addAgreement(val)
                            this.closeComposeDialog()
                          }}
                          disabled={!this.canBeSubmitted()}
                        >
                          Add
                        </Button>
                      )}
                      <FormControlLabel
                        style={{ float: 'right' }}
                        control={
                          <Switch
                            checked={this.state.advanced === 'Y'}
                            name='advanced'
                            onChange={this.handleChange}
                            color='primary'
                          />
                        }
                        label='Advanced'
                      />
                    </React.Fragment>
                  ) : agreementDialog.type !== 'new' &&
                    this.state.ID &&
                    this.state.newCycle ? (
                    <React.Fragment>
                      <Button
                        variant='contained'
                        color='primary'
                        className='mb-6'
                        onClick={() => {
                          const val = _.cloneDeepWith(this.state)

                          delete val.Data.WorkOrder
                          addAgreement({ ...val, ID: null })
                        }}
                        disabled={!this.canBeSubmitted()}
                      >
                        Add Cycle
                      </Button>
                      <FormControlLabel
                        style={{ float: 'right' }}
                        control={
                          <Switch
                            checked={this.state.advanced === 'Y'}
                            name='advanced'
                            onChange={this.handleChange}
                            color='primary'
                          />
                        }
                        label='Advanced'
                      />
                    </React.Fragment>
                  ) : agreementDialog.type !== 'new' &&
                    this.state.ID &&
                    this.state.needsRevision ? (
                    <React.Fragment>
                      <Button
                        variant='contained'
                        color='primary'
                        className='mb-6'
                        onClick={() => {
                          const val = _.cloneDeepWith(this.state)

                          delete val.Data.WorkOrder
                          addAgreement({ ...val, ID: null })
                        }}
                        disabled={!this.canBeSubmitted()}
                      >
                        Revise
                      </Button>
                      <FormControlLabel
                        style={{ float: 'right' }}
                        control={
                          <Switch
                            checked={this.state.advanced === 'Y'}
                            name='advanced'
                            onChange={this.handleChange}
                            color='primary'
                          />
                        }
                        label='Advanced'
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          updateAgreement(this.state)
                        }}
                        disabled={!this.canBeSubmitted()}
                      >
                        Save
                      </Button>
                      <FormControlLabel
                        style={{ float: 'right' }}
                        control={
                          <Switch
                            checked={this.state.advanced === 'Y'}
                            name='advanced'
                            onChange={this.handleChange}
                            color='primary'
                          />
                        }
                        label='Advanced'
                      />
                      <IconButton
                        onClick={() => {
                          this.setState({ confirmDelete: true })
                          // this.closeComposeDialog();
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                      <ConfirmationDialog
                        open={this.state.confirmDelete}
                        title={
                          <div>
                            <Icon
                              className='mr-6 align-middle mb-4'
                              color='error'
                            >
                              delete
                            </Icon>
                            Are You Sure?
                          </div>
                        }
                        content={
                          <div className='w-full pt-8 pb-8'>{`Are you sure you wish to delete this agreement? This action cannot be undone.`}</div>
                        }
                        confirmText='Delete'
                        cancelText='Cancel'
                        onCancel={() =>
                          this.setState({ ...this.state, confirmDelete: false })
                        }
                        onConfirm={() =>
                          this.setState(
                            { ...this.state, confirmDelete: false },
                            () => {
                              removeAgreement(this.state)
                              this.closeComposeDialog()
                            },
                          )
                        }
                      />
                    </React.Fragment>
                  )}
                </DialogActions>
              )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditAgreementDialog: Actions.closeEditAgreementDialog,
      closeNewAgreementDialog: Actions.closeNewAgreementDialog,
      addAgreement: Actions.addAgreement,
      updateAgreement: Actions.updateAgreement,
      removeAgreement: Actions.removeAgreement,
      openEditServiceUnitDialog,
      openNewServiceUnitDialog,
      assignAgreementTask: Actions.assignAgreementTask,
      assignAgreementTasks: Actions.assignAgreementTasks,
      removeAgreementTask: Actions.removeAgreementTask,
      openEditWorkOrderDialog,
      openPreloadAgreementDialog,
    },
    dispatch,
  )
}

function mapStateToProps({
  agreementsApp,
  workOrdersApp,
  workOrderScopesApp,
  spReducers,
}) {
  return {
    googleMapsApiKey: googleMapsApiKey,
    agreementDialog: agreementsApp.agreements.agreementDialog,
    technicians: spReducers.technicians,
    Co: spReducers.companies.Co,
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    businessUnits: spReducers.businessUnits,
    gps: spReducers.gps,
    divisions: spReducers.divisions,
    arrivalWindows: spReducers.arrivalWindows,
    agreementTypes: spReducers.agreementTypes,
    searchText: workOrdersApp.workOrders.searchText,
    workOrderData: spReducers.workOrders,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AgreementDialog),
)
