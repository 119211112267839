import axios from 'axios/index';

export const SET_PURCHASE_ORDER_DATA = '[SPCONNECTION] SET PURCHASE ORDER DATA';
export const GET_PURCHASE_ORDER_DATA = '[SPCONNECTION] GET PURCHASE ORDER DATA';
export const SUBSCRIBE_PURCHASE_ORDER_LIST = '[SPCONNECTION] SUBSCRIBE PURCHASE ORDER LIST';

export function getInitialPurchaseOrderData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/PurchaseOrder`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getPurchaseOrderData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/PurchaseOrder`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_PURCHASE_ORDER_DATA,
                payload: response.data
            })
        });
    }
}

export function setPurchaseOrderData(data) {
    return {
        type: SET_PURCHASE_ORDER_DATA,
        payload: data
    };
}