import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Button, Card, CardHeader, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grow, Icon, IconButton, Menu, MenuItem, Slide, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { openEditInvoiceDialog } from '../invoices/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Records Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 288px)',
        paddingBottom: 0,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        width: 32,
        height: 32,
    },
    labels: {},
    green: {
        backgroundColor: '#d1e751',
        color: '#3f3f3f'
    },
    blue: {
        backgroundColor: '#4dbce9',
        color: '#fff'
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    borderTop: {
        borderTop: '1px solid lightgrey'
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    ok: {
        background: '#333',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        },
        '&:focus': {
            backgroundColor: '#5f5f5f',
        },
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    greenText: {
        color: '#d1e751',
    },
    listContainer: {
        borderTop: '1px solid lightgrey',
        borderBottom: '1px solid lightgrey',
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: '#f6f6f6'
    }
});

class AgreementsList extends Component {

    state = {
        selectedAgreementsMenu: null,
        anchorEl: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedAgreementMenu = (event) => {
        this.setState({ selectedAgreementsMenu: event.currentTarget });
    };

    closeSelectedAgreementsMenu = () => {
        this.setState({ selectedAgreementsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    selectAllAgreements = (data) => {
        const selected = [];
        data.map((value) => {
            selected.push(value.ID);
        });
        this.props.selectAllAgreements(selected);
    }

    selectAmortizations = () => {
        const { agreementData, selectedAgreementIds } = this.props;
        const agreements = _.filter(agreementData, (o) => selectedAgreementIds.indexOf(o.ID) > -1);
        window["warn"]('Selected Amortizations: ', agreements);
        this.props.setSelectedAmortizations(agreements);
        this.closeMenu();
    }

    selectBillings = () => {
        const { agreementData, selectedAgreementIds } = this.props;
        const agreements = _.filter(agreementData, (o) => selectedAgreementIds.indexOf(o.ID) > -1);
        window["warn"]('Selected Billings: ', agreements);
        this.props.setSelectedBillings(agreements);
        this.closeMenu();
    }

    selectTasks = () => {
        const { agreementData, selectedAgreementIds } = this.props;
        const agreements = _.filter(agreementData, (o) => selectedAgreementIds.indexOf(o.ID) > -1);
        window["warn"]('Selected Tasks: ', agreements);
        this.props.setSelectedTasks(agreements);
        this.closeMenu();
    }

    selectWaivedTasks = () => {
        const { agreementData, selectedAgreementIds } = this.props;
        const agreements = _.filter(agreementData, (o) => selectedAgreementIds.indexOf(o.ID) > -1);
        window["warn"]('Selected Tasks: ', agreements);
        this.props.setWaivedTasks(agreements);
        this.closeMenu();
    }

    openMenu = event => {
        event.stopPropagation();
        this.setState({ ...this.state, anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null });
    };

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        const { Co, classes, securables, filters, selectedAgreementIds, billings, amortizations, tasks, waivedTasks, agreement, agreementData, loading, openEditAgreementDialog, renewEditAgreementDialog, scheduleEditAgreementDialog, invoiceEditAgreementDialog, openNewAgreementDialog, businessUnits, divisions, agreementTypes, technicians, customerSite, customer, searchText } = this.props;
        const { anchorEl } = this.state;
        const data = this.getFilteredArray((!this.props.selectedAgreementData ? (!this.props.agreementData ? [] : this.props.agreementData) : this.props.selectedAgreementData), searchText);
        const { Customer, Site } = customerSite || customer || {};
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "agreements" });

        window["warn"]("agreement list agreementData", agreementData)

        return (
            <Media query="(min-width: 768px)">
                {matches =>
                    <FuseAnimate animation="transition.slideUpIn" delay={300}>
                        <div>
                            {filters && filters.EventType === 'T' &&
                                <Menu id="agreements-menu" classes={{ paper: "min-w-256" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                                    {/* <MenuItem onClick={this.closeMenu}><Icon className="mr-8">textsms</Icon>Send Text Reminder</MenuItem>
                                    <MenuItem onClick={this.closeMenu}><Icon className="mr-8">email</Icon>Send Email Reminder</MenuItem> */}
                                    <MenuItem onClick={this.selectTasks}><Icon className="mr-8">assignment</Icon>Schedule Selected Tasks</MenuItem>
                                    <MenuItem onClick={this.selectWaivedTasks}><Icon className="mr-8">event_busy</Icon>Waive Selected Tasks</MenuItem>
                                </Menu>
                            }
                            {filters && filters.EventType === 'B' &&
                                <Menu id="agreements-menu" classes={{ paper: "min-w-256" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                                    <MenuItem onClick={this.selectBillings}><Icon className="mr-8">receipt</Icon>Generate Agreement Billings</MenuItem>
                                </Menu>
                            }
                            {filters && filters.EventType === 'A' &&
                                <Menu id="agreements-menu" classes={{ paper: "min-w-256" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                                    <MenuItem onClick={this.selectAmortizations}><Icon className="mr-8">receipt</Icon>Amortize Agreement Revenue</MenuItem>
                                </Menu>
                            }
                            <Dialog
                                TransitionComponent={!matches ? SlideUp : Grow}
                                classes={{
                                    paper: matches ? classes.paper : null
                                }}
                                open={tasks && tasks.length > 0}
                                onClose={() => this.props.setSelectedTasks([])}
                                fullWidth
                                maxWidth={"sm"}
                                fullScreen={!matches}
                            >
                                <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-16" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">assignment</Icon>Upcoming Agreement Tasks</DialogTitle>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    color: '#333'
                                }}
                                    onClick={() => {
                                        this.props.setSelectedTasks([]);
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                                <DialogContent className={classes.listContainer}>
                                    {tasks.map((value, index) => {
                                        const { Customer, Site, Agreement, Cycle } = value;
                                        const agreement = _.find(agreementData, { Co, Customer, Site, Agreement, Cycle });
                                        const { BusinessUnit, Division, AgreementType } = agreement || {};
                                        const type = _.find(agreementTypes, { BusinessUnit, Division, AgreementType });
                                        const task = type ? _.find(type.Data.Tasks, { TaskType: value.TaskType }) : null;
                                        return (
                                            <Card className="w-full p-4 mb-16 cursor-pointer">
                                                <CardHeader
                                                    key={value.ID}
                                                    classes={{ action: "text-11 mt-0 text-grey mt-6 mr-6", root: classNames("p-4 px-12"), avatar: "mr-8", title: "leading-none text-14 py-4 font-bold text-grey-darkest w-5/6 truncate", subheader: "leading-none text-10 py-4 text-grey" }}
                                                    avatar={
                                                        <Avatar color="primary" className={classNames(value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? classes.error : value.WorkOrder ? classes.black : classes.blue : classes.blue, classes.avatar)}>
                                                            <Icon className="text-20 mb-4" color={value.WorkOrder && (!value.Data || !value.Data.ErrMsg || value.Data.ErrMsg.length < 1) ? "secondary" : undefined}>{value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? "cloud_off" : value.WorkOrder ? "cloud_done" : "cloud_upload" : "cloud_upload"}</Icon>
                                                        </Avatar>
                                                    }
                                                    onClick={value.WorkOrder ? () => this.props.openEditWorkOrderDialog({ Co: value.Co, WorkOrder: value.WorkOrder }) : () => openEditAgreementDialog(value)}
                                                    title={`Agreement #${value.Agreement} Cycle #${value.Cycle}`}
                                                    subheader={value.WorkOrder ? `WO #${value.WorkOrder}${task ? ` - ${task.Name} Visit #${value.Seq}` : ` - ${value.TaskType} Visit #${value.Seq}`}` : task ? `${task.Name} Visit #${value.Seq}` : `${value.TaskType} Visit #${value.Seq}`}
                                                    action={new Date(value.DueBy || value.Date).toLocaleDateString('en-US')}
                                                />
                                                {value.Data && value.Data.ErrMsg && value.Data.ErrMsg.length > 0 &&
                                                    <CardContent className="w-full p-12 text-red text-center text-12 py-4 font-bold">
                                                        Error: {value.Data.ErrMsg}
                                                    </CardContent>
                                                }
                                            </Card>
                                        );
                                    })}
                                </DialogContent>
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <div className="flex">
                                        <Button
                                            className="text-12"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.props.createSelectedTasks(Co, tasks);
                                                // updateInvoice(this.state);
                                            }}
                                            disabled={this.props.processing}
                                        >
                                            {this.props.processing &&
                                                <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                                            }
                                            {this.props.processing ? 'Scheduling' : 'Schedule'} Tasks
                                        </Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                TransitionComponent={!matches ? SlideUp : Grow}
                                classes={{
                                    paper: matches ? classes.paper : null
                                }}
                                open={waivedTasks && waivedTasks.length > 0}
                                onClose={() => this.props.setWaivedTasks([])}
                                fullWidth
                                maxWidth={"sm"}
                                fullScreen={!matches}
                            >
                                <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-16" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">event_busy</Icon>Waive Tasks</DialogTitle>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    color: '#333'
                                }}
                                    onClick={() => {
                                        this.props.setWaivedTasks([]);
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                                <DialogContent className={classes.listContainer}>
                                    {waivedTasks.map((value, index) => {
                                        const { Customer, Site, Agreement, Cycle } = value;
                                        const agreement = _.find(agreementData, { Co, Customer, Site, Agreement, Cycle });
                                        const { BusinessUnit, Division, AgreementType } = agreement || {};
                                        const type = _.find(agreementTypes, { BusinessUnit, Division, AgreementType });
                                        const task = type ? _.find(type.Data.Tasks, { TaskType: value.TaskType }) : null;
                                        return (
                                            <Card className="w-full p-4 mb-16 cursor-pointer">
                                                <CardHeader
                                                    key={value.ID}
                                                    classes={{ action: "text-11 mt-0 text-grey mt-6 mr-6", root: classNames("p-4 px-12"), avatar: "mr-8", title: "leading-none text-14 py-4 font-bold text-grey-darkest w-5/6 truncate", subheader: "leading-none text-10 py-4 text-grey" }}
                                                    avatar={
                                                        <Avatar color="primary" className={classNames(value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? classes.error : value.Status === 4 ? classes.black : classes.blue : classes.blue, classes.avatar)}>
                                                            <Icon className="text-20 mb-4" color={value.Status === 4 && (!value.Data || !value.Data.ErrMsg || value.Data.ErrMsg.length < 1) ? "secondary" : undefined}>{value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? "cloud_off" : value.Status === 4 ? "cloud_done" : "cloud_upload" : "cloud_upload"}</Icon>
                                                        </Avatar>
                                                    }
                                                    onClick={() => openEditAgreementDialog(value)}
                                                    title={`Agreement #${value.Agreement} Cycle #${value.Cycle}`}
                                                    subheader={value.Status === 4 ? `Waived${task ? ` - ${task.Name} Visit #${value.Seq}` : ` - ${value.TaskType} Visit #${value.Seq}`}` : task ? `${task.Name} Visit #${value.Seq}` : `${value.TaskType} Visit #${value.Seq}`}
                                                    action={value.Status === 4 ? new Date().toLocaleDateString('en-US') : new Date(value.DueBy).toLocaleDateString('en-US')}
                                                />
                                                {value.Data && value.Data.ErrMsg && value.Data.ErrMsg.length > 0 &&
                                                    <CardContent className="w-full p-12 text-red text-center text-12 py-4 font-bold">
                                                        Error: {value.Data.ErrMsg}
                                                    </CardContent>
                                                }
                                            </Card>
                                        );
                                    })}
                                </DialogContent>
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <div className="flex">
                                        <Button
                                            className="text-12"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.props.waiveSelectedTasks(Co, waivedTasks);
                                                // updateInvoice(this.state);
                                            }}
                                            disabled={this.props.processing}
                                        >
                                            {this.props.processing &&
                                                <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                                            }
                                            {this.props.processing ? 'Waiving' : 'Waive'} Tasks
                                        </Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                TransitionComponent={!matches ? SlideUp : Grow}
                                classes={{
                                    paper: matches ? classes.paper : null
                                }}
                                open={billings && billings.length > 0}
                                onClose={() => this.props.setSelectedBillings([])}
                                fullWidth
                                maxWidth={"sm"}
                                fullScreen={!matches}
                            >
                                <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-16" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">receipt</Icon>Agreement Billings</DialogTitle>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    color: '#333'
                                }}
                                    onClick={() => {
                                        this.props.setSelectedBillings([]);
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                                <DialogContent className={classes.listContainer}>
                                    {billings.map((value, index) => {
                                        return (
                                            <Card className="w-full p-4 mb-16 cursor-pointer">
                                                <CardHeader
                                                    key={value.ID}
                                                    classes={{ action: "text-11 mt-0 text-grey mt-6 mr-6", root: classNames("p-4 px-12"), avatar: "mr-8", title: "leading-none text-14 py-4 font-bold text-grey-darkest w-5/6 truncate", subheader: "leading-none text-10 py-4 text-grey" }}
                                                    avatar={
                                                        <Avatar color="primary" className={classNames(value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? classes.error : value.Data.Invoice ? classes.black : classes.blue : classes.blue, classes.avatar)}>
                                                            <Icon className="text-20 mb-4" color={value.Data && value.Data.Invoice ? "secondary" : undefined}>{value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? "cloud_off" : value.Data.Invoice ? "cloud_done" : "cloud_upload" : "cloud_upload"}</Icon>
                                                        </Avatar>
                                                    }
                                                    onClick={value.Data && value.Data.Invoice ? () => this.props.openEditInvoiceDialog(value.Data.Invoice) : () => openEditAgreementDialog(value)}
                                                    title={`Agreement #${value.Agreement} Cycle #${value.Cycle} Billing #${value.Seq}`}
                                                    subheader={value.Data && value.Data.Invoice ? `Invoice #${value.Data.Invoice.InvoiceNumber} - ${this.formatDollars(value.Amount)}` : this.formatDollars(value.Amount)}
                                                    action={new Date(value.DueDate).toLocaleDateString('en-US')}
                                                />
                                                {value.Data && value.Data.ErrMsg && value.Data.ErrMsg.length > 0 &&
                                                    <CardContent className="w-full p-12 text-red text-center text-12 py-4 font-bold">
                                                        Error: {value.Data.ErrMsg}
                                                    </CardContent>
                                                }
                                            </Card>
                                        );
                                    })}
                                </DialogContent>
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <div className="flex">
                                        <Button
                                            className="text-12"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.props.createSelectedBillings(Co, billings);
                                                // updateInvoice(this.state);
                                            }}
                                            disabled={this.props.processing}
                                        >
                                            {this.props.processing &&
                                                <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                                            }
                                            {this.props.processing ? 'Generating' : 'Generate'} Billings
                                        </Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                TransitionComponent={!matches ? SlideUp : Grow}
                                classes={{
                                    paper: matches ? classes.paper : null
                                }}
                                open={amortizations && amortizations.length > 0}
                                onClose={() => this.props.setSelectedAmortizations([])}
                                fullWidth
                                maxWidth={"sm"}
                                fullScreen={!matches}
                            >
                                <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-16" }} id="confirmation-dialog-title"><div className="w-3/4 truncate"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">today</Icon>Agreement Revenue Amortization</div></DialogTitle>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    color: '#333'
                                }}
                                    onClick={() => {
                                        this.props.setSelectedAmortizations([]);
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                                <DialogContent className={classes.listContainer}>
                                    {amortizations.map((value, index) => {
                                        return (
                                            <Card className="w-full p-4 mb-16 cursor-pointer">
                                                <CardHeader
                                                    key={value.ID}
                                                    classes={{ action: "text-11 mt-0 text-grey mt-6 mr-6", root: classNames("p-4 px-12"), avatar: "mr-8", title: "leading-none text-14 py-4 font-bold text-grey-darkest w-5/6 truncate", subheader: "leading-none text-10 py-4 text-grey" }}
                                                    avatar={
                                                        <Avatar color="primary" className={classNames(value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? classes.error : value.Data.Transaction ? classes.black : classes.blue : classes.blue, classes.avatar)}>
                                                            <Icon className="text-20 mb-4" color={value.Data && value.Data.Transaction ? "secondary" : undefined}>{value.Data ? value.Data.ErrMsg && value.Data.ErrMsg.length > 0 ? "cloud_off" : value.Data.Transaction ? "cloud_done" : "cloud_upload" : "cloud_upload"}</Icon>
                                                        </Avatar>
                                                    }
                                                    onClick={() => openEditAgreementDialog(value)}
                                                    title={`Agreement #${value.Agreement} Cycle #${value.Cycle} Amortization #${value.Seq}`}
                                                    subheader={value.Data && value.Data.Transaction ? `Transaction #${value.Data.Transaction.Transaction} - ${this.formatDollars(value.Amount)}` : this.formatDollars(value.Amount)}
                                                    action={new Date(value.Date || value.TransactionDate).toLocaleDateString('en-US')}
                                                />
                                                {value.Data && value.Data.ErrMsg && value.Data.ErrMsg.length > 0 &&
                                                    <CardContent className="w-full p-12 text-red text-center text-12 py-4 font-bold">
                                                        Error: {value.Data.ErrMsg}
                                                    </CardContent>
                                                }
                                            </Card>
                                        );
                                    })}
                                </DialogContent>
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <div className="flex">
                                        <Button
                                            className="text-12"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.props.createSelectedAmortizations(Co, amortizations);
                                                // updateInvoice(this.state);
                                            }}
                                            disabled={this.props.processing}
                                        >
                                            {this.props.processing &&
                                                <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                                            }
                                            {this.props.processing ? 'Processing' : 'Process'} Amortizations
                                        </Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                            {(accessLevel && accessLevel.AccessLevel !== "R") && !agreement &&
                                <Fab
                                    color="secondary"
                                    aria-label="add"
                                    className={classes.addButton}
                                    onClick={() => openNewAgreementDialog(customerSite ? _.cloneDeepWith({ Co, Customer, Site, StartDate: new Date(), Data: { Customer: customerSite.Data.Customer, Site: customerSite, Sites: [], Tasks: [] } }) : customer ? _.cloneDeepWith({ Co, Customer, StartDate: new Date(), Data: { Customer: customer, Sites: [], Tasks: [] } }) : null)}
                                >
                                    <Icon>add</Icon>
                                </Fab>
                            }
                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">business_center</Icon>Agreements
                                {
                                    filters && filters.EventType && selectedAgreementIds.length > 0 &&
                                    <Button
                                        variant="contained"
                                        className="ml-12 pt-0 pb-0"
                                        color="primary"
                                        onClick={this.openMenu}
                                    >
                                        Actions
                                    </Button>
                                }
                            </Typography>
                            <ReactTableFixedColumns
                                className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                                getTrProps={(state, rowInfo, column) => {
                                    const agr = rowInfo ? rowInfo.original : null;
                                    return {
                                        className: `${agr && (agr.ActiveYN !== "Y" ? "text-grey-light" : new Date() >= new Date(agr.EndDate).setDate(new Date(agr.EndDate).getDate() - 45) ? new Date() > new Date(agr.EndDate) ? "text-red" : "text-orange" : "")} cursor-pointer`,
                                        onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                                if (!agreement) {
                                                    openEditAgreementDialog(rowInfo.original);
                                                } else {
                                                    openEditAgreementDialog(rowInfo.original, true);
                                                }
                                            }
                                        }
                                    }
                                }}
                                data={data}
                                NoDataComponent={loading ? LoadingDiv : NoData}
                                columns={[
                                    {
                                        Header: () => (
                                            <Checkbox
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                                className="p-0"
                                                onChange={(event) => {
                                                    event.target.checked ? this.selectAllAgreements(data) : this.props.deSelectAllAgreements();
                                                }}
                                                checked={selectedAgreementIds.length === data.length && selectedAgreementIds.length > 0}
                                                indeterminate={selectedAgreementIds.length !== data.length && selectedAgreementIds.length > 0}
                                            />
                                        ),
                                        fixed: "left",
                                        accessor: "",
                                        Cell: row => {
                                            return (<Checkbox
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                                className="p-0"
                                                checked={selectedAgreementIds.includes(row.value.ID)}
                                                onChange={() => this.props.toggleInSelectedAgreements(row.value.ID)}
                                            />
                                            )
                                        },
                                        className: "justify-center p-0",
                                        sortable: false,
                                        width: 48,
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "Customer",
                                        id: 'customer',
                                        accessor: r => r.Data && r.Data.Customer ? `${r.Data.Customer.Name || `${r.Data.Customer.FirstName} ${r.Data.Customer.LastName}`} ` : r.Customer,
                                        Cell: r => r.original.Data && r.original.Data.Customer ? `${r.original.Customer} - ${r.original.Data.Customer.Name || `${r.original.Data.Customer.FirstName} ${r.original.Data.Customer.LastName}`} ` : r.original.Customer,
                                        minWidth: 224,
                                        className: "justify-center font-bold",
                                        show: !customerSite && !agreement,
                                    },
                                    {
                                        Header: "Site",
                                        accessor: "Site",
                                        minWidth: 128,
                                        className: "justify-center font-bold",
                                        show: !customerSite && !agreement,
                                    },
                                    {
                                        Header: "Agreement",
                                        accessor: "Agreement",
                                        minWidth: 128,
                                        className: "justify-center font-bold",
                                    },
                                    {
                                        Header: "Cycle",
                                        accessor: "Cycle",
                                        minWidth: 96,
                                        className: "justify-center font-bold",
                                    },
                                    {
                                        Header: "Start Date",
                                        accessor: "StartDate",
                                        Cell: r => this.formatDate(r.value),
                                        minWidth: 128,
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "EndDate",
                                        accessor: "EndDate",
                                        Cell: r => this.formatDate(r.value),
                                        minWidth: 128,
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "",
                                        accessor: "NextCycle",
                                        Cell: r => {
                                            if (!r.original.NextCycle) {
                                                return (
                                                    <DebounceButton
                                                        buttonText={<div className="flex text-12"><Icon className="text-18 mr-4">refresh</Icon>Renew</div>}
                                                        processingText={"Loading"}
                                                        // variant={"contained"}
                                                        color={"primary"}
                                                        className="py-0"
                                                        clickFxn={() => {
                                                            return renewEditAgreementDialog(r.original);
                                                        }}
                                                        debounceInterval={1500}
                                                    />
                                                );
                                            }
                                        },
                                        className: "justify-center",
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "Next Visit",
                                        accessor: "NextTaskDueDate",
                                        //accessor: "StartDate",
                                        className: "justify-center",
                                        Cell: r => r.value ? this.formatDate(r.value) : '',
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "",
                                        accessor: "NextTaskDueDate",
                                        Cell: r => {
                                            if (r.original.ActiveYN === 'Y' && r.value) {
                                                return (
                                                    <DebounceButton
                                                        buttonText={<div className="flex text-12"><Icon className="text-18 mr-4">today</Icon>Schedule</div>}
                                                        processingText={"Loading"}
                                                        // variant={"contained"}
                                                        color={"primary"}
                                                        className="py-0"
                                                        clickFxn={() => {
                                                            return scheduleEditAgreementDialog(r.original);
                                                        }}
                                                        debounceInterval={1500}
                                                    />
                                                );
                                            }
                                        },
                                        className: "justify-center",
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "Next Task",
                                        accessor: "NextTask",
                                        className: "justify-center",
                                        Cell: r => {
                                            const { original } = r;
                                            let desc = r.value;
                                            const type = _.find(agreementTypes, (o) => o.Co == original.Co && o.BusinessUnit == original.BusinessUnit && o.Division == original.Division && o.AgreementType == original.AgreementType);
                                            if (type) {
                                                const task = _.find(type.Data.Tasks, (o) => o.TaskType == r.value);
                                                if (task) {
                                                    desc = task.Name;
                                                }
                                            }
                                            return desc;
                                        },
                                        minWidth: 160,
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "Next Billing",
                                        accessor: "NextBillingDueDate",
                                        //accessor: "EndDate",
                                        className: "justify-center",
                                        Cell: r => r.value ? this.formatDate(r.value) : '',
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "",
                                        accessor: "NextBillingDueDate",
                                        Cell: r => {
                                            if (r.original.ActiveYN === 'Y' && r.value) {
                                                return (
                                                    <DebounceButton
                                                        buttonText={<div className="flex text-12"><Icon className="text-18 mr-4">receipt</Icon>Invoice</div>}
                                                        processingText={"Loading"}
                                                        // variant={"contained"}
                                                        color={"primary"}
                                                        className="py-0"
                                                        clickFxn={() => {
                                                            return invoiceEditAgreementDialog(r.original);
                                                        }}
                                                        debounceInterval={1500}
                                                    />
                                                );
                                            }
                                        },
                                        className: "justify-center",
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "Agreement Type",
                                        id: "agreementType",
                                        accessor: r => agreementTypes ? _.find(agreementTypes, { BusinessUnit: r.BusinessUnit, Division: r.Division, AgreementType: r.AgreementType }).Description : r.AgreementType,
                                        minWidth: 320,
                                        className: "justify-center font-bold",
                                    },
                                    {
                                        Header: "Revision",
                                        accessor: "Revision",
                                        minWidth: 96,
                                        className: "justify-center font-bold",
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "Status",
                                        className: "justify-center",
                                        show: data.length > 0 && !Boolean(this.props.selectedAgreementData)
                                    },
                                    {
                                        Header: "Active?",
                                        accessor: "ActiveYN",
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "Tasks",
                                        id: "tasks",
                                        accessor: r => {
                                            if (r.ActiveYN === "Y" && r.Data && r.Data.Tasks) {
                                                return _.sumBy(r.Data.Tasks, (o) => o.Data && o.Data.Recurrences ? o.Data.Recurrences.length : 1)
                                            } else {
                                                return 0
                                            }
                                        },
                                        minWidth: 64,
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "Completed",
                                        id: "completed",
                                        accessor: r => r.ActiveYN === "Y" && r.Data && r.Data.Schedule && r.Data.Schedule.length,
                                        minWidth: 96,
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "Remaining",
                                        id: "remaining",
                                        accessor: r => {
                                            if (r.ActiveYN === "Y" && r.Data && r.Data && r.Data && r.Data.Tasks && r.Data && r.Data.Schedule) {
                                                return _.sumBy(r.Data.Tasks, (o) => o.Data && o.Data.Recurrences ? o.Data.Recurrences.length : 1) - r.Data.Schedule.length
                                            } else {
                                                return 0;
                                            }
                                        },
                                        minWidth: 96,
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "Price",
                                        id: "price",
                                        className: "justify-center",
                                        accessor: r => r.TotalPrice && `$${r.TotalPrice.toFixed(2)}`
                                    },
                                    {
                                        Header: "Business Unit",
                                        id: "businessUnit",
                                        accessor: r => businessUnits ? _.find(businessUnits, { BusinessUnit: r.BusinessUnit }).Description : r.BusinessUnit,
                                        minWidth: 256,
                                        className: "justify-center font-bold",
                                    },
                                    {
                                        Header: "Division",
                                        id: "division",
                                        accessor: r => divisions ? _.find(divisions, { BusinessUnit: r.BusinessUnit, Division: r.Division }).Description : r.Division,
                                        minWidth: 256,
                                        className: "justify-center font-bold",
                                    },
                                    // {
                                    //     Header: "Sold By",
                                    //     id: "soldBy",
                                    //     accessor: r => {
                                    //         if (technicians) {
                                    //             var value = _.find(technicians, { Technician: r.SoldBy });
                                    //             if (value) {
                                    //                 return <React.Fragment>
                                    //                     <Avatar style={{ marginRight: 8, marginLeft: -16 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` : avatar)} />
                                    //                     {`${value.FirstName} ${value.LastName}`}
                                    //                 </React.Fragment>
                                    //             } else {
                                    //                 return r.SoldBy
                                    //             }
                                    //         } else {
                                    //             return r.SoldBy
                                    //         }
                                    //     },
                                    //     className: "justify-center",
                                    //     minWidth: 192,
                                    // },
                                ]}
                                defaultPageSize={100}
                                noDataText="No Agreements found"
                            />
                        </div>
                    </FuseAnimate>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditAgreementDialog: Actions.openEditAgreementDialog,
        renewEditAgreementDialog: Actions.renewEditAgreementDialog,
        scheduleEditAgreementDialog: Actions.assignEditAgreementDialog,
        invoiceEditAgreementDialog: Actions.invoiceEditAgreementDialog,
        removeAgreement: Actions.removeAgreement,
        openNewAgreementDialog: Actions.openNewAgreementDialog,
        toggleInSelectedAgreements: Actions.toggleInSelectedAgreements,
        selectAllAgreements: Actions.selectAllAgreements,
        deSelectAllAgreements: Actions.deSelectAllAgreements,
        setSelectedBillings: Actions.setSelectedBillings,
        createSelectedBillings: Actions.createSelectedBillings,
        setSelectedAmortizations: Actions.setSelectedAmortizations,
        createSelectedAmortizations: Actions.createSelectedAmortizations,
        setSelectedTasks: Actions.setSelectedTasks,
        setWaivedTasks: Actions.setWaivedTasks,
        createSelectedTasks: Actions.createSelectedTasks,
        waiveSelectedTasks: Actions.waiveSelectedTasks,
        openEditInvoiceDialog,
        openEditWorkOrderDialog
    }, dispatch);
}

function mapStateToProps({ agreementsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        agreementData: agreementsApp.agreements.entities,
        searchText: agreementsApp.agreements.searchText,
        user: spReducers.userProfiles.User.UserName,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        agreementTypes: spReducers.agreementTypes,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
        filters: agreementsApp.agreements.filters,
        selectedAgreementIds: agreementsApp.agreements.selectedAgreementIds,
        loading: agreementsApp.agreements.loading,
        processing: agreementsApp.agreements.processing,
        amortizations: agreementsApp.agreements.amortizations,
        billings: agreementsApp.agreements.billings,
        tasks: agreementsApp.agreements.tasks,
        waivedTasks: agreementsApp.agreements.waivedTasks,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementsList)));
