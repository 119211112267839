import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class ArrivalWindowsList extends Component {

    state = {
        selectedArrivalWindowsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedArrivalWindowMenu = (event) => {
        this.setState({ selectedArrivalWindowsMenu: event.currentTarget });
    };

    closeSelectedArrivalWindowsMenu = () => {
        this.setState({ selectedArrivalWindowsMenu: null });
    };

    render() {
        const { classes, securables, arrivalWindows, user, searchText, openNewArrivalWindowDialog, selectedArrivalWindowIds, selectAllArrivalWindows, deSelectAllArrivalWindows, toggleInSelectedArrivalWindows, openEditArrivalWindowDialog, removeArrivalWindows, removeArrivalWindow, toggleStarredArrivalWindow, setArrivalWindowsUnstarred, setArrivalWindowsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.arrivalWindowData ? [] : this.props.arrivalWindowData), searchText);
        const { selectedArrivalWindowsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "arrival-windows" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewArrivalWindowDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">access_time</Icon>Arrival Windows</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditArrivalWindowDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center",
                                width: 200
                            },
                            {
                                Header: "Arrival Window",
                                accessor: "ArrivalWindow",
                                className: "font-bold justify-center",
                                width: 200
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 400
                            },
                            {
                                Header: "Start Time",
                                accessor: "StartTime",
                                className: "justify-center",
                            },
                            {
                                Header: "End Time",
                                accessor: "EndTime",
                                className: "justify-center",
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Arrival Windows found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getArrivalWindows: Actions.getArrivalWindows,
        getUserData: Actions.getUserData,
        toggleInSelectedArrivalWindows: Actions.toggleInSelectedArrivalWindows,
        selectAllArrivalWindows: Actions.selectAllArrivalWindows,
        deSelectAllArrivalWindows: Actions.deSelectAllArrivalWindows,
        openEditArrivalWindowDialog: Actions.openEditArrivalWindowDialog,
        removeArrivalWindows: Actions.removeArrivalWindows,
        removeArrivalWindow: Actions.removeArrivalWindow,
        toggleStarredArrivalWindow: Actions.toggleStarredArrivalWindow,
        toggleStarredArrivalWindows: Actions.toggleStarredArrivalWindows,
        setArrivalWindowsStarred: Actions.setArrivalWindowsStarred,
        setArrivalWindowsUnstarred: Actions.setArrivalWindowsUnstarred,
        openNewArrivalWindowDialog: Actions.openNewArrivalWindowDialog
    }, dispatch);
}

function mapStateToProps({ arrivalWindowsApp, spReducers }) {
    return {
        arrivalWindows: arrivalWindowsApp.arrivalWindows.entities,
        selectedArrivalWindowIds: arrivalWindowsApp.arrivalWindows.selectedArrivalWindowIds,
        searchText: arrivalWindowsApp.arrivalWindows.searchText,
        user: arrivalWindowsApp.user,
        arrivalWindowData: spReducers.arrivalWindows,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrivalWindowsList)));
