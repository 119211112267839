import { FuseAnimate, FuseSelectedTheme, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Divider, FormControlLabel, Icon, Input, Menu, MenuItem, Paper, Popper, Switch, TextField, Typography } from '@material-ui/core';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { openEditAgreementDialog, openNewAgreementDialog } from 'main/content/apps/agreements/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSortCatalog } from 'store/actions';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {},
    scrollableList: {
        maxHeight: 350,
        overflow: 'auto',
    },
    suggestionsContainerOpen: {
        borderRadius: 3,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: 8,
    },
    input: {
        paddingLeft: '8.8rem',
    },
    tooltip: {
        background: 'none',
        padding: 0,
        margin: 0,
        paddingTop: 4,
        paddingBottom: 4,
    },
    popper: {
        opacity: 1,
        transform: 'none !important',
        zIndex: 999999,
        marginTop: 4,
        marginBottom: 4,
        '&[x-placement*="bottom-start"]': {
            top: '56px !important',
        },
        '&[x-placement*="top-start"]': {
            top: 'unset !important',
            bottom: 56,
        },
    },
    portalPopper: {
        opacity: 1,
        zIndex: 999999,
        marginTop: 4,
        marginBottom: 4,
    },
    selectedOption: {
        background: 'rgba(50, 50, 50, .1)',
    },
    formControl: {
        marginBottom: 6,
        display: 'block'
    },
});

class CatalogDialogSearch extends Component {
    state = {
        open: false,
        searchText: '',
        suggestions: [],
    }

    clearSearch = () => {
        this.setState({ ...this.state, open: false, searchText: '', suggestions: [] }, () => this.props.setStateFxn({ ...this.props.parentState, preferredViewOpen: true }));
    }

    openMenu = (e) => {
        const { loading } = this.state;
        if (!loading) {
            this.setState({ ...this.state, menu: true, anchorEl: e.target });
        }
    }

    closeMenu = () => {
        this.setState({ ...this.state, menu: false, anchorEl: null, suggestions: [] });
    }

    downloadCatalog = () => {
        const { loading } = this.state;
        const { catalog } = this.props;
        if (!loading && catalog) {
            this.setState({ ...this.state, loading: true, menu: false, anchorEl: null }, () => {
                Promise.all([
                    this.props.downloadCatalog(catalog, true)
                ]).then(() => {
                    this.setState({ ...this.state, loading: false, menu: false, anchorEl: null });
                })
            });
        }
    }

    handleFocus = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, hasFocus: true, selectedIndex: 0 });
    }

    handleBlur = (event) => {
        const { onBlur } = this.props;
        this.setState({ ...this.state, searchText: '', hasFocus: false, selectedIndex: 0 }, () => { if (onBlur) { onBlur(); } });
    }

    handleSelect(data) {
        this.setState({ ...this.state, hasFocus: false, selectedIndex: 0 }, () => this.props.onSelect(data));
    }

    getSuggestions = _.debounce(() => {
        const { catalog, estimate } = this.props;
        const { searchText } = this.state;
        let suggestions = [];
        if (searchText && searchText.length > 2 && catalog && catalog.Data && catalog.Data.Assemblies) {
            suggestions = FuseUtils.filterArrayByString((estimate ? _.filter(catalog.Data.Assemblies, (o) => o.ActiveYN !== "N") : catalog.Data.Assemblies), searchText);
        }
        this.setState({ ...this.state, suggestions });
    }, 750);

    setSearchText = (e) => {
        this.setState({ searchText: e.target.value }, this.getSuggestions);
    }

    onActive = () => {
        if (this.props.onActive) {
            this.props.onActive(!this.props.activeYN);
        }
    }

    sortContents = (data) => {
        this.props.sortContents(data);
    }

    onTMItem = () => {
        const { onTMItem } = this.props;
        if (onTMItem) {
            this.setState({ anchorEl: null, menu: false, open: false }, onTMItem);
        }
    }

    newAgreement = () => {
        const { info } = this.props;
        if (info) {
            const est = _.cloneDeepWith(info);
            const { Scopes, Site, Customer, Assignments } = est.Data;
            const { Co, BusinessUnit, Division, EstimateNumber, IssuedBy } = est;
            const StartDate = new Date();
            const data = {
                Co,
                BusinessUnit,
                Division,
                Customer: Site.Customer,
                Site: Site.Site,
                SoldBy: IssuedBy,
                StartDate,
                Data: {
                    AgreementType: null,
                    Site,
                    Customer,
                    Tasks: [],
                    Schedule: [],
                    Variations: [],
                    Attachments: [],
                },
                EstimateNumber
            }
            this.setState({ anchorEl: null, menu: false, open: false }, () => {
                this.props.openNewAgreementDialog(data, (agreement) => this.props.onAddAgreement(agreement));
            });
        }
    }

    handleSortChange = (e) => {
        const { sortCatalog } = this.props;
        console.log(e.target)
        const type = e.target.name;
        const value = e.target.value;
        let newSortedObj = { ...sortCatalog }
        newSortedObj[type] = value;
        this.props.setSortCatalog(newSortedObj)
    }

    handleReorder = () => {
        this.setState({ anchorEl: null, menu: false, open: false }, () => {
            if (this.props.onReorder) {
                this.props.onReorder();
            }
        });
    }

    render() {
        const { classes, setSearchText, pageLayout, shrink, catalog, estimate, customer, site, onTMItem, sortBy, sortCatalog, category } = this.props;
        const { hasFocus, searchText, suggestions } = this.state;
        window["warn"]("estimate dialog yes/no", estimate)
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex flex-1">
                    {catalog &&
                        <Paper className="flex items-center w-full h-36 mt-4 p-16 rounded-8 " style={{ boxShadow: 'none' }} elevation={1}>
                            {!this.state.open ?
                                <Icon color="action" className="cursor-pointer" onClick={this.openMenu}>more_vert</Icon>
                                :
                                <Icon color="action" className="cursor-pointer" onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{searchText && searchText.length > 0 ? "filter_list" : "search"}</Icon>
                            }
                            <Menu id="price-book-menu" classes={{}} anchorEl={this.state.anchorEl} open={Boolean(this.state.menu)} onClose={this.closeMenu}>
                                {this.props.setStateFxn &&
                                    <MenuItem onClick={() => this.setState({ ...this.state, open: !this.state.open, menu: false, anchorEl: null }, () => this.props.setStateFxn({ ...this.props.parentState, preferredViewOpen: false }))}><Icon className="mr-8">search</Icon>Search</MenuItem>
                                }
                                {onTMItem && estimate && catalog && catalog.EnableTMEstimatesYN === "Y" &&
                                    <MenuItem onClick={this.onTMItem}><Icon className={classNames("mr-8")}>add</Icon>New T&M Item</MenuItem>
                                }
                                {estimate &&
                                    <MenuItem onClick={this.newAgreement}><Icon className={classNames("mr-8")}>business_center</Icon>New Agreement</MenuItem>
                                }
                                {!estimate && Boolean(category) &&
                                    <MenuItem onClick={this.handleReorder}><Icon className={classNames("mr-8")}>swap_vert</Icon>Reorder Items</MenuItem>
                                }
                                <MenuItem disabled={this.state.loading} onClick={this.downloadCatalog}><Icon className={classNames("mr-8", this.state.loading ? "spin" : "")}>refresh</Icon>Reload Price Book</MenuItem>
                                {this.props.onActive && !estimate &&
                                    <MenuItem onClick={this.onActive}>
                                        <div className="w-full">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={!this.props.activeYN}
                                                        name="showinActive"
                                                        color="primary"
                                                    />
                                                }
                                                label="Show Inactive"
                                            />
                                        </div>
                                    </MenuItem>
                                }
                            </Menu>
                            {this.state.open &&
                                <FuseAnimate animation="transition.slideRightBigIn">
                                    <div className="flex flex-1">
                                        {/* <Autocomplete
                                            className={classNames(classes.formControl, "w-256")}
                                            title="Search Price Book"
                                            options={catalog.Data.Assemblies}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.Assembly}>
                                                    {`${value.PRCode || value.Assembly} - ${value.Name}`}
                                                </MenuItem>
                                            }}
                                            disableUnderline={true}
                                            margin="dense"
                                            portal={true}
                                            minSearch={3}
                                            value={''}
                                            onSelect={(option) => this.setState({ ...this.state, UpgradedAssembly: option.Assembly })}
                                        /> */}
                                        <div className={classNames("relative")}>
                                            {hasFocus && suggestions && suggestions.length > 0 &&
                                                <Popper className={classes.portalPopper} anchorEl={this.anchorEl} open={true} placement="bottom-end">
                                                    {suggestions.length > 0 &&
                                                        <div className={classes.container} onMouseDown={(event) => { event.preventDefault(); }}>
                                                            <Paper className={classNames(classes.suggestionsContainerOpen, "w-full")} square>
                                                                <div className={classes.scrollableList}>
                                                                    {
                                                                        suggestions.map((suggestion, index) => {
                                                                            return (
                                                                                <div key={index} className={classNames("cursor-pointer h-40")} onClick={() => {
                                                                                    if (this.props.onCategory) {
                                                                                        const category = _.find(catalog.Data.Categories, (o) => _.findIndex(o.Data.Assemblies, { Assembly: suggestion.Assembly }) > -1);
                                                                                        if (category) {
                                                                                            this.props.onCategory(category);
                                                                                        }
                                                                                    }
                                                                                    if (this.props.onSelected) {
                                                                                        this.props.onSelected(suggestion);
                                                                                        this.handleBlur();
                                                                                    } else {
                                                                                        this.props.openEditCatalogItemDialog(suggestion, estimate);
                                                                                    }
                                                                                }}>
                                                                                    <MenuItem suggestion={suggestion.Assembly}>
                                                                                        {`${suggestion.PRCode || suggestion.Assembly} - ${suggestion.Name}`}
                                                                                    </MenuItem>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </Paper>
                                                        </div>
                                                    }
                                                </Popper>
                                            }
                                            <Input
                                                inputRef={node => {
                                                    this.anchorEl = node;
                                                }}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                placeholder="Search Price Book"
                                                className="pl-4"
                                                disableUnderline={suggestions.length < 1}
                                                fullWidth
                                                value={searchText || ''}
                                                inputProps={{
                                                    'aria-label': 'Search'
                                                }}
                                                onChange={this.setSearchText}
                                            // onBlur={() => this.setState({ ...this.state, open: false })}
                                            />
                                            <Icon color="action" className="cursor-pointer" onClick={this.clearSearch}>close</Icon>
                                        </div>
                                    </div>
                                </FuseAnimate>
                            }
                        </Paper>
                    }
                </div>
            </MuiThemeProvider>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText,
        downloadCatalog: Actions.downloadCatalog,
        openEditCatalogItemDialog: Actions.openEditCatalogItemDialog,
        setSortCatalog,
        openNewAgreementDialog,
        openEditAgreementDialog,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        // searchText: catalogApp.searchText
        sortCatalog: spReducers.catalog.sortCatalog,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogDialogSearch));
