import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedAgreementTypeTaskIds: [],
    routeParams: {},
    agreementTypeTaskDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            BusinessUnit: null,
            Division: null,
            AgreementType: null,
            TaskType: null,
            Name: null,
            Description: null,
            UnitCat: null,
            Notes: null,
            AgreementID: null,
            UnitType: null,
            AssociatedService: null,
            Department: null,
            Frequency: null,
            BME: null,
            DateMedian: null,
            LeadTime: null,
            RevenueType: null,
            Revenue: null,
            Additional: null,
            OptionalYN: 'N',
            Data: {
                Overrides: [],
            },
            taskOverride: {
                ID: null,
                Co: null,
                BusinessUnit: null,
                Division: null,
                AgreementType: null,
                TaskType: null,
                UnitType: null,
                Revenue: null,
                Additional: null,
                Data: {
                    ErrMsg: null,
                }
            },
        }
    }
};

const agreementTypeTasksReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_AGREEMENT_TYPE_TASKS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.OPEN_NEW_AGREEMENT_TYPE_TASK_DIALOG:
            {
                return {
                    ...state,
                    agreementTypeTaskDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_NEW_AGREEMENT_TYPE_TASK_DIALOG:
            {
                return {
                    ...state,
                    agreementTypeTaskDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_AGREEMENT_TYPE_TASK_DIALOG:
            {
                return {
                    ...state,
                    agreementTypeTaskDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_AGREEMENT_TYPE_TASK_DIALOG:
                {
                    return {
                        ...state,
                        agreementTypeTaskDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_AGREEMENT_TYPE_TASK_DIALOG:
            {
                return {
                    ...state,
                    agreementTypeTaskDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.ADD_AGREEMENT_TYPE_TASK_OVERRIDE:
            {
                return {
                    ...state,
                    agreementTypeTaskDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: {
                            ...state.agreementTypeTaskDialog.data,
                            taskOverride: {
                                ID: null,
                                Co: null,
                                BusinessUnit: null,
                                Division: null,
                                AgreementType: null,
                                TaskType: null,
                                UnitType: null,
                                Revenue: null,
                                Additional: null,
                                Data: {
                                    ErrMsg: null,
                                }
                            },
                        }
                    }
                }
            }
            case Actions.UPDATE_AGREEMENT_TYPE_TASK_OVERRIDE:
                {
                    return {
                        ...state,
                        agreementTypeTaskDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: {
                                ...state.agreementTypeTaskDialog.data,
                                taskOverride: {
                                    ID: null,
                                    Co: null,
                                    BusinessUnit: null,
                                    Division: null,
                                    AgreementType: null,
                                    TaskType: null,
                                    UnitType: null,
                                    Revenue: null,
                                    Additional: null,
                                    Data: {
                                        ErrMsg: null,
                                    }
                                },
                            }
                        }
                    }
                }
                case Actions.REMOVE_AGREEMENT_TYPE_TASK_OVERRIDE:
                    {
                        return {
                            ...state,
                            agreementTypeTaskDialog: {
                                type: 'update',
                                props: {
                                    open: true
                                },
                                data: {
                                    ...state.agreementTypeTaskDialog.data,
                                    taskOverride: {
                                        ID: null,
                                        Co: null,
                                        BusinessUnit: null,
                                        Division: null,
                                        AgreementType: null,
                                        TaskType: null,
                                        UnitType: null,
                                        Revenue: null,
                                        Additional: null,
                                        Data: {
                                            ErrMsg: null,
                                        }
                                    },
                                }
                            }
                        }
                    }
        default:
            {
                return state;
            }
    }
};

export default agreementTypeTasksReducer;
