import axios from 'axios';
import { getUserData } from 'main/content/apps/call-gather-keys/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_CALL_GATHER_KEYS = '[CALL GATHER KEYS APP] GET CALL GATHER KEYS';
export const SET_SEARCH_TEXT = '[CALL GATHER KEYS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CALL_GATHER_KEYS = '[CALL GATHER KEYS APP] TOGGLE IN SELECTED CALL GATHER KEYS';
export const SELECT_ALL_CALL_GATHER_KEYS = '[CALL GATHER KEYS APP] SELECT ALL CALL GATHER KEYS';
export const DESELECT_ALL_CALL_GATHER_KEYS = '[CALL GATHER KEYS APP] DESELECT ALL CALL GATHER KEYS';
export const OPEN_NEW_CALL_GATHER_KEY_DIALOG = '[CALL GATHER KEYS APP] OPEN NEW CALL GATHER KEY DIALOG';
export const CLOSE_NEW_CALL_GATHER_KEY_DIALOG = '[CALL GATHER KEYS APP] CLOSE NEW CALL GATHER KEY DIALOG';
export const OPEN_EDIT_CALL_GATHER_KEY_DIALOG = '[CALL GATHER KEYS APP] OPEN EDIT CALL GATHER KEY DIALOG';
export const UPDATE_EDIT_CALL_GATHER_KEY_DIALOG = '[CALL GATHER KEYS APP] UPDATE EDIT CALL GATHER KEY DIALOG';
export const CLOSE_EDIT_CALL_GATHER_KEY_DIALOG = '[CALL GATHER KEYS APP] CLOSE EDIT CALL GATHER KEY DIALOG';
export const ADD_CALL_GATHER_KEY = '[CALL GATHER KEYS APP] ADD CALL GATHER KEY';
export const UPDATE_CALL_GATHER_KEY = '[CALL GATHER KEYS APP] UPDATE CALL GATHER KEY';
export const REMOVE_CALL_GATHER_KEY = '[CALL GATHER KEYS APP] REMOVE CALL GATHER KEY';
export const REMOVE_CALL_GATHER_KEYS = '[CALL GATHER KEYS APP] REMOVE CALL GATHER KEYS';
export const TOGGLE_STARRED_CALL_GATHER_KEY = '[CALL GATHER KEYS APP] TOGGLE STARRED CALL GATHER KEY';
export const TOGGLE_STARRED_CALL_GATHER_KEYS = '[CALL GATHER KEYS APP] TOGGLE STARRED CALL GATHER KEYS';
export const SET_CALL_GATHER_KEYS_STARRED = '[CALL GATHER KEYS APP] SET CALL GATHER KEYS STARRED ';

export function getCallGatherKeys(routeParams) {
    const request = axios.get('/api/call-gather-keys-app/call-gather-keys', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_CALL_GATHER_KEYS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedCallGatherKeys(CallGatherKeyId) {
    return {
        type: TOGGLE_IN_SELECTED_CALL_GATHER_KEYS,
        CallGatherKeyId
    }
}


export function selectAllCallGatherKeys() {
    return {
        type: SELECT_ALL_CALL_GATHER_KEYS
    }
}

export function deSelectAllCallGatherKeys() {
    return {
        type: DESELECT_ALL_CALL_GATHER_KEYS
    }
}


export function openNewCallGatherKeysDialog(phone) {
    return {
        type: OPEN_NEW_CALL_GATHER_KEY_DIALOG,
        phone,
    }
}

export function closeNewCallGatherKeysDialog() {
    return {
        type: CLOSE_NEW_CALL_GATHER_KEY_DIALOG
    }
}

export function openEditCallGatherKeysDialog(data) {
    return {
        type: OPEN_EDIT_CALL_GATHER_KEY_DIALOG,
        data
    }
}

export function closeEditCallGatherKeysDialog() {
    return {
        type: CLOSE_EDIT_CALL_GATHER_KEY_DIALOG
    }
}

export function addCallGatherKey(newCallGatherKey) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/CallGatherKey`,
            newCallGatherKey
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_CALL_GATHER_KEY,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Key Press #${newCallGatherKey.Key} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateCallGatherKey(CallGatherKey) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/CallGatherKey?Co=${encodeURIComponent(CallGatherKey.Co)}&Gather=${encodeURIComponent(CallGatherKey.Gather)}&Key=${encodeURIComponent(CallGatherKey.Key)}`,
            CallGatherKey
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_CALL_GATHER_KEY,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Key Press #${CallGatherKey.Key} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeCallGatherKey(CallGatherKey) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/CallGatherKey?Co=${encodeURIComponent(CallGatherKey.Co)}&Gather=${encodeURIComponent(CallGatherKey.Gather)}&Key=${encodeURIComponent(CallGatherKey.Key)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CALL_GATHER_KEY,
                    data: CallGatherKey
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Key Press #${CallGatherKey.Key} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeCallGatherKeys(CallGatherKeyIds) {
    return (dispatch, getState) => {

        const request = axios.post('/api/call-gather-keys-app/remove-CallGatherKeys', {
            CallGatherKeyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CALL_GATHER_KEYS
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHER_KEYS
                })
            ])
        );
    };
}

export function toggleStarredCallGatherKey(CallGatherKeyId) {
    return (dispatch, getState) => {

        const request = axios.post('/api/call-gather-keys-app/toggle-starred-CallGatherKey', {
            CallGatherKeyId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CALL_GATHER_KEY
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function toggleStarredCallGatherKeys(CallGatherKeyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGatherKeysApp.CallGatherKeys;

        const request = axios.post('/api/call-gather-keys-app/toggle-starred-CallGatherKeys', {
            CallGatherKeyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CALL_GATHER_KEYS
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHER_KEYS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallGatherKeys(routeParams)))
        );
    };
}

export function setCallGatherKeysStarred(CallGatherKeyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGatherKeysApp.CallGatherKeys;

        const request = axios.post('/api/call-gather-keys-app/set-CallGatherKeys-starred', {
            CallGatherKeyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_GATHER_KEYS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHER_KEYS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallGatherKeys(routeParams)))
        );
    };
}

export function setCallGatherKeysUnstarred(CallGatherKeyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callGatherKeysApp.CallGatherKeys;

        const request = axios.post('/api/call-gather-keys-app/set-CallGatherKeys-unstarred', {
            CallGatherKeyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_GATHER_KEYS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_GATHER_KEYS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallGatherKeys(routeParams)))
        );
    };
}
