import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Fab, Icon, Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { openEditGpsInventoryTransferDialog, openNewGpsInventoryTransferDialog } from '../gps-inventory-transfers/store/actions/gps-inventory-transfers.actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: '1.2rem',
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    viewTabIcon: {
        color: theme.palette.primary.contrastText,
    },
    tab: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    viewTab: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 4px 0px #3f3f3f',
        color: theme.palette.primary.contrastText,
        paddingTop: '8px !important',
    },
});

class InventoryLocationsList extends Component {

    state = {
        selectedInventoryLocationsMenu: null,
        viewing: 'locations'
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedInventoryLocationMenu = (event) => {
        this.setState({ selectedInventoryLocationsMenu: event.currentTarget });
    };

    closeSelectedInventoryLocationsMenu = () => {
        this.setState({ selectedInventoryLocationsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, securables, inventoryLocationGroups, inventoryUserTransfers, user, searchText, costTypes, selectedInventoryLocationIds, fleetVehicles, selectAllInventoryLocations, deSelectAllInventoryLocations, technicians, toggleInSelectedInventoryLocations, openEditInventoryLocationDialog, removeInventoryLocations, removeInventoryLocation, toggleStarredInventoryLocation, setInventoryLocationsUnstarred, setInventoryLocationsStarred, openNewInventoryLocationDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedInventoryLocationData ? !this.props.inventoryLocationData ? [] : this.props.inventoryLocationData : this.props.selectedInventoryLocationData), searchText);
        const { selectedInventoryLocationsMenu, viewing } = this.state;
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';
        const accessLevel = _.find(securables, { Securable: "inventory-locations" });

        window["warn"]("inventoryUserTransfers", inventoryUserTransfers)

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" && viewing === 'locations' &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewInventoryLocationDialog}
                        >
                            <Tooltip title="New Inventory Location" placement="left">
                                <Icon>add</Icon>
                            </Tooltip>
                        </Fab>

                    }
                    {viewing === 'transfers' &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={(e) => {
                                    e.stopPropagation()
                                    const { Co } = this.props;
                                    this.props.openNewGpsInventoryTransferDialog({Co})
                                }
                            }
                        >
                            <Tooltip title="New Inventory Transfer" placement="left">
                                <Icon>add</Icon>
                            </Tooltip>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center', maxWidth: viewing === "locations" ? 'calc(100% - 40px)' : undefined, }} className="flex pl-0 pt-4 sm:max-w-none overflow-x-auto">
                        <div onClick={() => this.setState({ ...this.state, viewing: 'locations', })} className={classNames(viewing === 'locations' ? classes.viewTab : classes.tab, "w-auto p-6 pr-12 cursor-pointer")} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, whiteSpace: 'nowrap' }}>
                            <Icon color="primary" className={classNames(viewing === 'locations' ? classes.viewTabIcon : "", "text-32 mr-8 mb-4 align-middle")}>store</Icon>
                            <span>Inventory Locations</span>
                        </div>
                        <div onClick={() => this.setState({ ...this.state, viewing: 'transfers', })} className={classNames(viewing === 'transfers' ? classes.viewTab : classes.tab, "w-auto p-6 pr-12 cursor-pointer")} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, whiteSpace: 'nowrap' }}>
                            <Icon color="primary" className={classNames(viewing === 'transfers' ? classes.viewTabIcon : "", "text-32 mr-8 mb-4 align-middle")}>send</Icon>
                            <span>Inventory Transfers</span>
                        </div>
                    </Typography>
                    <div className={classNames(classes.root, "border-0 ")} style={{ maxHeight: 'unset', borderTop: '1px solid lightgrey' }}>
                        { viewing === 'locations' ? (
                            <>
                                {/* {accessLevel && accessLevel.AccessLevel !== "R" &&
                                    <Fab
                                        color="secondary"
                                        aria-label="add"
                                        className={classes.addButton}
                                        onClick={openNewInventoryLocationDialog}
                                    >
                                        <Icon>add</Icon>
                                    </Fab>

                                } */}
                                {/* <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12 mt-12"><Icon color="primary" className="text-32 mr-12">store</Icon>Inventory Locations</Typography> */}
                                <ReactTableFixedColumns
                                    className={classNames(classes.root, "-striped -highlight border-0 rounded mt-24")}
                                    getTrProps={(state, rowInfo, column) => {
                                        return {
                                            className: "cursor-pointer",
                                            onClick: (e, handleOriginal) => {
                                                if (rowInfo) {
                                                    openEditInventoryLocationDialog(rowInfo.original);
                                                }
                                            }
                                        }
                                    }}
                                    data={data}
                                    columns={[
                                        {
                                            Header: "Location",
                                            accessor: "Location",
                                            minWidth: 128,
                                            className: "justify-center font-bold",
                                        },
                                        {
                                            Header: "Location Group",
                                            id: "category",
                                            minWidth: 192,
                                            className: "justify-center",
                                            accessor: d => `${inventoryLocationGroups && `${d.LocationGroup} - ${_.find(inventoryLocationGroups, { LocationGroup: d.LocationGroup }).Description}`}`
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "Description",
                                            className: "justify-center",
                                            minWidth: 256,
                                        },
                                        {
                                            Header: "Mobile?",
                                            accessor: "MobileYN",
                                            className: "justify-center",
                                        },
                                        {
                                            Header: "Fleet Vehicle",
                                            accessor: "FleetVehicle",
                                            className: "justify-center",
                                            minWidth: 256,
                                            Cell: row => {
                                                if (fleetVehicles) {
                                                    return fleetVehicles.map((value, index) => (
                                                        value.FleetVehicle === row.original.FleetVehicle &&
                                                        <React.Fragment key={index}>
                                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.FleetVehicle} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                                            {`${value.FleetVehicle}`}
                                                        </React.Fragment>
                                                    ));
                                                }
                                            }
                                        },
                                        {
                                            Header: "Address",
                                            accessor: "Address1",
                                            //filterable: true,
                                            width: 300
                                        },
                                        {
                                            Header: "City",
                                            accessor: "City",
                                            //filterable: true,
                                            className: "justify-center",
                                            width: 150
                                        },
                                        {
                                            Header: "State",
                                            accessor: "State",
                                            //filterable: true,
                                            className: "justify-center",
                                            width: 100
                                        },
                                        {
                                            Header: "Zip",
                                            accessor: "Zip",
                                            //filterable: true,
                                            className: "justify-center",
                                            width: 100
                                        },
                                        {
                                            Header: "Cost GL Account",
                                            accessor: "CostGLAccount",
                                            minWidth: 192,
                                            className: "justify-center",
                                        },
                                        {
                                            Header: "Sales GL Account",
                                            accessor: "SalesGLAccount",
                                            minWidth: 192,
                                            className: "justify-center",
                                        },
                                        {
                                            Header: "Adjustment GL Account",
                                            accessor: "AdjustmentGLAccount",
                                            minWidth: 192,
                                            className: "justify-center",
                                        },
                                        {
                                            Header: "Created By",
                                            accessor: "CreatedBy",
                                            className: "justify-center",
                                            minWidth: 256,
                                        },
                                        {
                                            Header: "Created Date",
                                            accessor: "CreatedDate",
                                            className: "justify-center",
                                            minWidth: 128,
                                            Cell: row => (
                                                row.original.CreatedDate &&
                                                moment(moment(row.original.CreatedDate)).local().toDate().toLocaleDateString('en-US')
                                            )
                                        },
                                        {
                                            Header: "Active?",
                                            accessor: "ActiveYN",
                                            className: "justify-center",
                                        },
                                    ]}
                                    defaultPageSize={100}
                                    noDataText="No Inventory Locations found"
                                />
                            </> ) : ( 
                            <>
                                {/* <Fab
                                    color="secondary"
                                    aria-label="add"
                                    className={classes.addButton}
                                    onClick={(e) => {
                                            e.stopPropagation()
                                            const { Co } = this.props;
                                            this.props.openNewGpsInventoryTransferDialog({Co})
                                        }
                                    }
                                >
                                    <Tooltip title="New Inventory Transfer" placement="left">
                                        <Icon>add</Icon>
                                    </Tooltip>
                                </Fab> */}
                                {/* <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12 mt-12"><Icon color="primary" className="text-32 mr-12">send</Icon>Inventory Transfers</Typography> */}
                                <ReactTableFixedColumns
                                    className={classNames(classes.root, "-striped -highlight border-0 rounded mt-24")}
                                    getTrProps={(state, rowInfo, column) => {
                                        return {
                                            className: classNames("cursor-pointer"),
                                            onClick: (e, handleOriginal) => {
                                                e.stopPropagation();
                                                window["warn"]("yu7788", rowInfo, this.props.openEditGpsInventoryTransferDialog)
                                                if (rowInfo) {
                                                    this.props.openEditGpsInventoryTransferDialog({ ...rowInfo.original});
                                                }
                                            }
                                        }
                                    }}
                                    data={inventoryUserTransfers}
                                    columns={[
                                        {
                                            Header: "Inventory Transfer",
                                            accessor: "Transfer",
                                            className: "justify-center",
                                        },
                                        {
                                            Header: "Source Location",
                                            accessor: "SourceLocation",
                                            className: "justify-center"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "Status",
                                            minWidth: 100,
                                            className: "justify-center",
                                            Cell: row => {
                                                if(row.original.Status === 0){
                                                    return "Pending"
                                                } else if(row.original.Status === 1){
                                                    return "Submitted"
                                                } else if(row.original.Status === 2){
                                                    return "Approved"
                                                } else if(row.original.Status === 3){
                                                    return "Accepted"
                                                } else if(row.original.Status === 4){
                                                    return "Cancelled"
                                                }
                                            }
                                        },
                                        {
                                            Header: "Requested Date",
                                            accessor: "RequestedDate",
                                            minWidth: 100,
                                            className: "justify-center",
                                            Cell: row => {
                                                let date = moment(row.original.RequestedDate)
                                                return date.format("MM/DD/YYYY")
                                            }
                                        },
                                    ]}
                                    defaultPageSize={10}
                                    noDataText="No Inventory Transfers found"
                                />
                            </> )  
                        }           
                    </div>
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getInventoryLocations: Actions.getInventoryLocations,
        getUserData: Actions.getUserData,
        toggleInSelectedInventoryLocations: Actions.toggleInSelectedInventoryLocations,
        selectAllInventoryLocations: Actions.selectAllInventoryLocations,
        deSelectAllInventoryLocations: Actions.deSelectAllInventoryLocations,
        openEditInventoryLocationDialog: Actions.openEditInventoryLocationDialog,
        removeInventoryLocations: Actions.removeInventoryLocations,
        removeInventoryLocation: Actions.removeInventoryLocation,
        toggleStarredInventoryLocation: Actions.toggleStarredInventoryLocation,
        toggleStarredInventoryLocations: Actions.toggleStarredInventoryLocations,
        setInventoryLocationsStarred: Actions.setInventoryLocationsStarred,
        setInventoryLocationsUnstarred: Actions.setInventoryLocationsUnstarred,
        openNewInventoryLocationDialog: Actions.openNewInventoryLocationDialog,
        openEditGpsInventoryTransferDialog,
        openNewGpsInventoryTransferDialog,
    }, dispatch);
}

function mapStateToProps({ inventoryLocationsApp, spReducers }) {
    return {
        inventoryLocationData: spReducers.inventoryLocations,
        searchText: inventoryLocationsApp.inventoryLocations.searchText,
        user: spReducers.userProfiles.User.UserName,
        inventoryLocationGroups: spReducers.inventoryLocationGroups,
        employees: spReducers.employees,
        fleetVehicles: spReducers.fleetVehicles,
        securables: spReducers.userProfiles.User.Data.Securables,
        inventoryUserTransfers: spReducers.inventoryUserTransfers,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryLocationsList)));
