import { Plugins } from '@capacitor/core';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grow, Icon, IconButton, InputAdornment, Menu, MenuItem, Slide, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import _ from 'lodash';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import { openEditInvoiceDialog } from './store/actions';
// import 'capacitor-plugin-omni-sdk';
const { OmniPlugin } = Plugins;
const { initOmni, getReaders, connectReader, disconnectReader, takePayment } = OmniPlugin || {};

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const styles = theme => ({
  root: {
    margin: 24,
    backgroundSize: 'cover',
    backgroundImage: 'url(assets/images/backgrounds/SP_Header_Dark.png)',
    backgroundPosition: 'center'
  },
  checkbox: {
    paddingRight: 4,
    paddingTop: 10,
  },
  approved: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#9f9f9f',
      color: '#5f5f5f',
    }
  },
  voidButton: {
    backgroundColor: `${theme.palette.error[400]} !important`,
    color: '#fff',
    '&:hover': {
      backgroundColor: `${theme.palette.error[500]} !important`,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: `${theme.palette.error[500]} !important`,
      color: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#9f9f9f',
      color: '#5f5f5f',
    }
  }
});
class VoidPaymentForm extends Component {
  fattJs = null;
  state = {
    payment: null,
    transaction: null,
    voidTransaction: null,
    editAllocation: null,
    editAnchor: null,
    editAllocationData: null,
    allocationAmount: null,
    unpaidInvoices: [],
    newAllocation: null
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { payment } = this.props;
    if (payment.ID !== prevProps.payment.ID) {
      this.setState({ payment });
    }
  }

  componentDidMount() {
    const { data, payment } = this.props;
    this.setState({ payment }, () => {
      if (payment && payment.Data && payment.Data.Payment) {
        if (payment.Data.Payment.Data && payment.Data.Payment.Data.PaymentMethod) {
          this.getFullTransaction(payment.Co, payment.PaymentID);
        }
      }
      if (payment && payment.Data && payment.Data.PaymentMethod) {
        this.getFullTransaction(payment.Co, payment.PaymentID);
      }
    })
  }


  getFullTransaction = (Co, transaction) => {
    const request = axios.get(`${window["apiLocation"]}/api/Omni/Transaction?Co=${Co}&id=${encodeURIComponent(transaction)}`);
    request.then((response) => {
      this.setState({ transaction: response.data }, () => window["warn"]('Transaction Data: ', this.state.transaction));
    }).catch((err) => {
      // this.setState({ ...this.state, status: null, error: err.message });
    })
  }


  voidTransaction = () => {
    const { payment, user } = this.props;
    const { Co } = payment;
    const { transaction } = this.state;
    const { id } = transaction || {};
    if (Co && id) {
      const request = axios.get(`${window["apiLocation"]}/api/Omni/Void?Co=${Co}&id=${encodeURIComponent(id)}`);
      request.then((response) => {
        this.setState({ ...this.state, status: null, transaction: response.data, loading: false }, () => {
          const data = payment.Data.Payment || payment;
          if (response.data.is_voided) {
            payment.VoidDate = new Date();
            payment.VoidedBy = user.UserName;
            data.VoidDate = new Date();
            data.VoidedBy = user.UserName;
          }
          const req = axios.put(`${window["apiLocation"]}/api/InvoicePayment?Co=${Co}&PaymentID=${encodeURIComponent(data.PaymentID)}`, data);
          req.then((res) => {
            if (!res.data.Data.ErrMsg || res.data.Data.ErrMsg.length < 1)
              if (this.props.onVoid) {
                this.props.onVoid(data);
                // this.props.onClose();
              }
          })
        });
      }).catch((err) => {
        this.setState({ ...this.state, status: null, error: err.message });
      })
    } else {
      const data = payment.Data.Payment || payment;
      payment.VoidDate = new Date();
      payment.VoidedBy = user.UserName;
      data.VoidDate = new Date();
      data.VoidedBy = user.UserName;
      const req = axios.put(`${window["apiLocation"]}/api/InvoicePayment?Co=${Co}&PaymentID=${encodeURIComponent(data.PaymentID)}`, data);
      req.then((res) => {
        if (!res.data.Data.ErrMsg || res.data.Data.ErrMsg.length < 1) {
          if (this.props.onVoid) {
            this.props.onVoid(data);
            this.props.onClose();
          }
        }
      })
    }
  }

  updateAllocation = (alloc) => {

    const { payment } = this.state;
    const allocations = this.getEffectiveAllocationsFromState();

    let allocation = _.cloneDeepWith(alloc);

    if (!alloc.ID) {
      const exists = _.find(allocations, { Invoice: alloc.Invoice, PaymentID: alloc.PaymentID });
      if (exists) {
        allocation = {
          ...exists,
          Amount: alloc.Amount,
          VoidDate: alloc.VoidDate,
          VoidedBy: alloc.VoidedBy
        }
      }
    }

    const { Co, PaymentID, ID, Invoice } = allocation;
    const request = axios.put(`${window["apiLocation"]}/api/Payment?Co=${Co}&PaymentID=${encodeURIComponent(PaymentID)}&Invoice=${Invoice}&AllocationID=${ID}`, allocation);
    return request.then((response) => {
      if (response.data.Data && response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
        this.setState({ ...this.state, status: null, error: response.data.Data.ErrMsg });
      } else {
        const index = _.findIndex(allocations, (o) => o.ID === response.data.ID);
        if (index > -1) {
          allocations.splice(index, 1, response.data);
        } else {
          allocations.push(response.data);
        }
        this.setState({
          payment: {
            ...payment,
            Data: {
              ...payment.Data,
              allocations
            }
          },
          editAllocation: null,
          editAllocationData: null,
          editAnchor: null,
          allocationAmount: null,
          newAllocation: null,
          unpaidInvoices: [],
          error: null
        });
      }
    }).catch((err) => {
      this.setState({ ...this.state, status: null, error: err.message });
      console.error(err);
    })
  }

  formatDollars = (num) => {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  }

  getUnpaid = () => {
    const { Co, Customer } = this.state.payment;
    const request = axios.get(`${window["apiLocation"]}/api/Payment?Co=${Co}&Customer=${Customer}&Unpaid=true`);
    return request.then((response) => {
      this.setState({ unpaidInvoices: response.data });
    })
  }

  newAllocation = () => {
    const { Co, Customer, PaymentID } = this.state.payment;
    this.setState({
      newAllocation: {
        Co,
        Customer,
        PaymentID,
        Amount: null,
        Invoice: null,
        InvoiceNumber: null
      }
    }, this.getUnpaid);
  }

  renderMessage = () => {
    const { error } = this.state;
    if (error)
      return (
        <div className="error">
          {error}
        </div>
      );
  }

  getEffectiveAllocationsFromState = () => {
    const { payment } = this.state;

    if (payment && payment.Data && payment.Data.Payment &&
      payment.Data.Payment.Data &&
      payment.Data.Payment.Data.Allocations)
      return payment.Data.Payment.Data.Allocations;

    if (payment && payment.Data &&
      payment.Data.Allocations)
      return payment.Data.Allocations;

    return [];
  }

  render() {
    const { completedTransaction, isPayButtonDisabled, loading, formValues, total, editAmount, reader, readers, transaction, } = this.state;
    const { classes, open, user } = this.props;
    const { editAnchor, editAllocation, editAllocationData, payment, newAllocation, unpaidInvoices } = this.state;
    if (!payment) {
      return '';
    }
    window["warn"]('Payment info: ', payment);
    var pmt = (payment && payment.Data && payment.Data.Payment) ? payment.Data.Payment : payment;
    const card = pmt.Data && pmt.Data.PaymentMethod ? pmt.Data.PaymentMethod : null;
    const cardType = card ? card.PaymentMethodType : null;
    const allocations = this.getEffectiveAllocationsFromState();
    const allocated = allocations.length > 0 ? _.sumBy(_.filter(allocations, (o) => !o.VoidDate), 'Amount') : 0;

    return (
      <Media query="(min-width: 1000px)">
        {matches =>
          <Dialog
            classes={{ paper: classNames(classes.root, "rounded-lg", !matches ? "m-0 rounded-none full-screen-dialog" : "") }}
            // disableBackdropClick
            // disableEscapeKeyDown
            fullScreen={!matches}
            maxWidth="sm"
            fullWidth
            TransitionComponent={!matches ? SlideUp : Grow}
            onClose={() => this.setState({ payment: null, unpaidInvoices: [], newAllocation: null, editAllocation: null, editAllocationData: null, error: null, editAnchor: null }, () => this.props.onClose())}
            aria-labelledby="payment-dialog-title"
            open={open}
            onClick={() => {
              if (this.state.editAllocation || this.state.newAllocation) {
                this.setState({ editAllocation: null, editAllocationData: null, editAnchor: null, allocationAmount: null, newAllocation: null });
              }
            }}
          >
            <div className="dialog-header">
              <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-20" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">payment</Icon>Payment Details</DialogTitle>
              <IconButton style={{
                position: 'absolute',
                right: 10,
                top: 10,
                color: '#333'
              }}
                onClick={() => {
                  this.props.onClose();
                }}
                className="dialog-header-icon"
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
            <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
              <div className="w-full payment">
                <TextField
                  className="w-full bg-white mb-12"
                  label="Form of Payment"
                  id="type"
                  name="PaymentType"
                  variant="outlined"
                  value={pmt.PaymentType || ''}
                  margin="dense"
                  InputLabelProps={{
                    style: {
                      textShadow: '0px 0px 4px white'
                    }
                  }}
                  select
                  disabled={true}
                >
                  <MenuItem value="CREDIT">
                    Credit / Debit
                  </MenuItem>
                  <MenuItem value="CHECK">
                    Check
                  </MenuItem>
                  <MenuItem value="CASH">
                    Cash
                  </MenuItem>
                  <MenuItem value="OTHER">
                    Other
                  </MenuItem>
                </TextField>
                {card &&
                  <React.Fragment>
                    <div className="w-full flex">
                      <div className="w-full flex">
                        <TextField
                          className="w-full bg-white mr-4"
                          label="Cardholder"
                          id="paymentBy"
                          name="PaymentBy"
                          value={pmt.PaymentBy || ''}
                          variant="outlined"
                          InputProps={{
                            className: "h-48 p-8 pl-12",
                            startAdornment: <InputAdornment position="start"><Icon>account_box</Icon></InputAdornment>,
                          }}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className={`w-full`}>
                      <div id="card-element" className="w-full">
                        <div className="w-full flex">
                          <TextField
                            className="w-full mt-12 bg-white"
                            label="Card Number"
                            id="card-number"
                            variant="outlined"
                            InputLabelProps={{
                              style: {
                                textShadow: '0px 0px 4px white'
                              }
                            }}
                            value={card.CardLastFour ? `**** **** **** ${card.CardLastFour}` : '**** **** **** ****'}
                            disabled={true}
                            InputProps={{
                              inputProps: {
                                className: "h-32 p-8"
                              },
                              startAdornment: <InputAdornment position="start">{card && card.PaymentMethodType && ['visa', 'mastercard', 'american_express', 'discover'].indexOf(card.PaymentMethodType) > -1 ? <img className="w-32" src={`assets/images/cards/${cardType}.png`} /> : <Icon>payment</Icon>}</InputAdornment>,
                            }}
                          />
                        </div>
                        <div className="w-full flex mb-12">
                          <TextField
                            className="w-3/5 mt-12 mr-8 bg-white"
                            label="Card Expiration"
                            id="card-expire"
                            variant="outlined"
                            InputLabelProps={{
                              style: {
                                textShadow: '0px 0px 4px white'
                              }
                            }}
                            disabled={true}
                            InputProps={{
                              className: "h-48 p-8 pl-12",
                              inputProps: {
                                className: "h-32 p-8",
                              },
                              startAdornment: <InputAdornment position="start"><Icon>today</Icon></InputAdornment>,
                            }}
                            value={moment(card.CardExpirationDate).format("MM/YYYY")}
                          />
                          <TextField
                            className="w-2/5 mt-12 bg-white"
                            label="Security Code"
                            variant="outlined"
                            value="***"
                            InputLabelProps={{
                              style: {
                                textShadow: '0px 0px 4px white'
                              }
                            }}
                            disabled={true}
                            InputProps={{
                              className: "pl-12",
                              inputProps: {
                                className: "h-32 p-8"
                              },
                              startAdornment: <InputAdornment position="start" className="mr-0"><Icon>lock</Icon></InputAdornment>,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
                {pmt.PaymentType == "CHECK" &&
                  <React.Fragment>
                    <TextField
                      className={"w-full bg-white mb-12"}
                      label="Check #"
                      id="check"
                      name="Check"
                      value={pmt.Check || ''}
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth
                      required
                      disabled={true}
                    />
                  </React.Fragment>
                }
                {['CHECK', 'CASH', 'OTHER'].indexOf(pmt.PaymentType) > -1 &&
                  <React.Fragment>
                    <TextField
                      className={"w-full bg-white mb-12"}
                      label="Memo"
                      id="memo"
                      name="Memo"
                      value={pmt.Memo || ''}
                      onChange={this.handleChange}
                      variant="outlined"
                      multiline
                      rows={5}
                      required={['CASH', 'OTHER'].indexOf(pmt.PaymentType) > -1}
                      fullWidth
                      disabled={true}
                    />
                  </React.Fragment>
                }
                {/* {pmt.PaymentType === "CREDIT" && */}
                <div className="flex mb-16">
                  <Button
                    variant="contained"
                    className={classNames("w-full", ((transaction && transaction.is_voidable) || !payment.VoidDate) ? "rounded-r-none" : "", classes.approved)}
                    color="secondary"
                    disabled={((transaction && transaction.is_voided) || payment.VoidDate)}
                  >
                    {loading ? <CircularProgress color="secondary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8" color={((transaction && transaction.is_voided) || payment.VoidDate) ? undefined : "secondary"}>{((transaction && transaction.is_voided) || payment.VoidDate) ? "payment" : "check_circle_outline"}</Icon>}
                    {loading ? 'Voiding Payment' : `${((transaction && transaction.is_voided) || payment.VoidDate) ? "Transaction Voided" : pmt.PaymentType === "CREDIT" ? "Approved Payment" : `${pmt.PaymentType} Payment`} - ${this.formatDollars(pmt.PaymentTotal)}`}
                    {/* <Icon className="mr-8" color={((transaction && transaction.is_voided) || payment.VoidDate) ? undefined : "secondary"}>{((transaction && transaction.is_voided) || payment.VoidDate) ? "payment" : "check_circle_outline"}</Icon>
                                            {((transaction && transaction.is_voided) || payment.VoidDate) ? "Transaction Voided" : "Approved Payment"} - {this.formatDollars(pmt.PaymentTotal)} */}
                  </Button>
                  {((transaction && transaction.is_voidable) || (pmt.PaymentType !== "CREDIT" && !transaction && !payment.VoidDate)) ?
                    <React.Fragment>
                      <Button
                        variant="contained"
                        className={classNames("w-72 rounded-l-none", classes.voidButton)}
                        color={"primary"}
                        onClick={() => this.setState({ voidTransaction: true })}
                        disabled={pmt.PaymentType === "CREDIT" && !transaction || (this.state.transaction && !this.state.transaction.is_voidable) || payment.VoidDate}
                      >
                        Void
                      </Button>
                      <ConfirmationDialog
                        open={this.state.voidTransaction}
                        title={<div><Icon className="mr-6 align-middle mb-4">payment</Icon>Are You Sure?</div>}
                        content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to void this transaction? This action cannot be undone.`}</div>}
                        confirmText="Void Payment"
                        cancelText="Cancel"
                        onCancel={() => this.setState({ ...this.state, voidTransaction: false })}
                        onConfirm={() => this.setState({ ...this.state, voidTransaction: false, loading: true }, () => this.voidTransaction())}
                      />
                    </React.Fragment> : ''
                  }
                </div>

                {allocations &&
                  <div className="w-full px-4 pb-12">
                    <Menu onClick={(e) => e.stopPropagation()} id="unit-menu" anchorEl={this.state.editAnchor} open={Boolean(this.state.editAnchor)} onClose={() => this.setState({ editAnchor: null, editAllocation: null, editAllocationData: null, allocationAmount: null })}>
                      <MenuItem
                        onClick={() => {
                          this.props.openEditInvoiceDialog({ Co: this.state.editAllocation.Co, Invoice: this.state.editAllocation.Invoice })
                          this.setState({ editAllocationData: null, editAnchor: null, editAllocation: null, allocationAmount: null });
                        }}
                      >
                        <Icon className="mr-8">open_in_new</Icon>
                        View Invoice
                      </MenuItem>
                      {editAllocation && !editAllocation.VoidDate &&
                        <MenuItem
                          onClick={() => {
                            this.setState({ editAllocationData: this.state.editAllocation, editAnchor: null, allocationAmount: this.state.editAllocation.Amount });
                          }}
                        >
                          <Icon className="mr-8">edit</Icon>
                          Edit Invoice Allocation
                        </MenuItem>
                      }
                    </Menu>
                    <Typography variant="h6" className="font-bold text-14 mb-0">Invoices</Typography>
                    {
                      allocations.map((alloc) => {
                        return (
                          <div onClick={(e) => e.stopPropagation()} className={classNames("w-full mb-8 text-11 font-bold relative cursor-pointer p-4", (editAllocationData && editAllocationData.ID === alloc.ID) ? "p-8 border-1 border-dotted rounded-lg" : "")}>
                            <div onClick={(e) => { if ((!editAllocation || editAllocation.ID !== alloc.ID)) { this.setState({ editAllocation: alloc, editAnchor: e.target }) } }} className={classNames("w-full flex text-11 font-bold relative cursor-pointer justify-between")}>
                              <div className="flex align-middle w-full">
                                <Icon className="mr-4 text-16" color={alloc.VoidDate ? "error" : "primary"}>receipt</Icon>
                                <div className={classNames(alloc.VoidDate ? "mr-6 mt-2 line-through text-grey-dark" : "mt-2 mr-16")}>{alloc.InvoiceNumber}</div>
                                <div className={classNames(alloc.VoidDate ? "mr-6 mt-2 line-through text-grey-dark" : "mt-2 mr-16")}>{new Date(alloc.EnteredDate).toLocaleDateString('en-US')}</div>
                              </div>
                              <div className="flex align-middle">
                                <div className={classNames("mt-2", alloc.VoidDate ? "line-through text-grey-dark" : "")}>{`${this.formatDollars(alloc.Amount)}`}</div>
                              </div>
                            </div>

                            {(editAllocationData && editAllocationData.ID === alloc.ID) &&
                              <div className="w-full mt-8">
                                <TextField
                                  className="mb-8"
                                  fullWidth
                                  name="allocationAmount"
                                  label="Amount"
                                  type="number"
                                  variant="outlined"
                                  value={this.state.allocationAmount || ''}
                                  InputProps={{ startAdornment: this.state.allocationAmount && this.state.allocationAmount >= 0 ? <InputAdornment placement="start">$</InputAdornment> : undefined }}
                                  onChange={(e) => {
                                    const allocationAmount = e.target.value;
                                    if (allocationAmount >= 0 && allocationAmount <= (pmt.PaymentTotal - _.sumBy(_.filter(allocations, (o) => o.ID !== alloc.ID && !o.VoidDate), 'Amount'))) {
                                      this.setState({ allocationAmount });
                                    }
                                  }}
                                />

                                <div className="w-full flex">
                                  <DebounceButton
                                    buttonText={"Void"}
                                    processingText={"Voiding"}
                                    variant={"contained"}
                                    clickFxn={() => this.updateAllocation({ ...editAllocationData, VoidDate: new Date(), VoidedBy: user.UserName })}
                                    className={classNames("w-full mr-4", classes.voidButton)}
                                    debounceInterval={1000}
                                  />
                                  <DebounceButton
                                    buttonText={"Update"}
                                    processingText={"Updating"}
                                    variant={"contained"}
                                    color={"primary"}
                                    className="w-full ml-4"
                                    clickFxn={() => this.updateAllocation({ ...editAllocationData, Amount: this.state.allocationAmount })}
                                    debounceInterval={1000}
                                  />
                                </div>
                              </div>
                            }
                          </div>
                        );
                      })
                    }
                    {allocated < pmt.PaymentTotal && !pmt.VoidDate &&
                      <div onClick={this.newAllocation} className={classNames("w-full mb-8 text-11 font-bold relative cursor-pointer p-4")}>
                        <div className="flex align-middle w-full">
                          <Icon className="mr-4 text-16" color={"secondary"}>add_circle_outline</Icon>
                          <div className={classNames("mt-2 mr-16")}>New Allocation</div>
                        </div>
                      </div>
                    }

                    {newAllocation &&
                      <div className="w-full mt-8" onClick={(e) => e.stopPropagation()}>
                        <TextField
                          className="mb-8"
                          fullWidth
                          name="invoice"
                          label="Invoice"
                          variant="outlined"
                          value={newAllocation.Invoice || ''}
                          select
                          onChange={(e) => {
                            const inv = _.find(unpaidInvoices, { Invoice: e.target.value });
                            if (inv) {
                              const { Invoice, InvoiceNumber, BalanceDue } = inv;
                              const avail = (pmt.PaymentTotal - allocated);
                              const Amount = (BalanceDue > avail ? avail : BalanceDue).toFixed(2);
                              this.setState({
                                newAllocation: {
                                  ...newAllocation,
                                  Invoice,
                                  InvoiceNumber,
                                  Amount
                                }
                              });
                            }
                          }}
                        >
                          {
                            unpaidInvoices.map((inv) => {
                              return (
                                <MenuItem value={inv.Invoice}>
                                  <div className="w-full flex">
                                    {`${inv.InvoiceNumber} - ${moment(inv.InvoiceDate).format("M/D/YYYY")} - ${this.formatDollars(inv.BalanceDue)} Due`}
                                  </div>
                                </MenuItem>
                              );
                            })
                          }
                        </TextField>
                        <TextField
                          className="mb-8"
                          fullWidth
                          name="amount"
                          label="Amount"
                          type="number"
                          variant="outlined"
                          value={newAllocation.Amount || ''}
                          InputProps={{ startAdornment: newAllocation.Amount && newAllocation.Amount >= 0 ? <InputAdornment placement="start">$</InputAdornment> : undefined }}
                          onChange={(e) => {
                            const Amount = e.target.value;
                            if (Amount >= 0 && Amount <= (pmt.PaymentTotal - allocated)) {
                              this.setState({ newAllocation: { ...newAllocation, Amount } });
                            }
                          }}
                        />
                        <DebounceButton
                          buttonText={"Add"}
                          processingText={"Adding"}
                          variant={"contained"}
                          color={"primary"}
                          className="w-full ml-4"
                          clickFxn={() => this.updateAllocation({ ...newAllocation })}
                          debounceInterval={1000}
                        />
                      </div>
                    }
                  </div>
                }
              </div>
              <div className="outcome">
                {this.renderMessage()}
              </div>
            </DialogContent>
          </Dialog>
        }
      </Media>
    );
  }
}



function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openEditInvoiceDialog
  }, dispatch);
}



function mapStateToProps({ invoicesApp, spReducers }) {
  return {
    companies: spReducers.companies,
    user: spReducers.userProfiles.User,
  }
}



export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(VoidPaymentForm));


