import FleetVehicleCategoriesApp from 'main/content/apps/fleet-vehicle-categories/FleetVehicleCategoriesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const FleetVehicleCategoriesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/fleet-vehicle-categories/:id',
            component: FleetVehicleCategoriesApp
        },
        {
            path     : '/apps/fleet-vehicle-categories',
            component: () => <Redirect to="/apps/fleet-vehicle-categories/all"/>
        }
    ]
};
