import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { Badge, Divider, Icon, Menu, MenuItem, FormControlLabel, Switch, Typography, IconButton, Toolbar, Slide, CardHeader } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateUser } from '../users/store/actions';
import CallHandlerCallCard from '../call-handler/CallHandlerCallCard';
import CallManagerDialog from '../call-handler/CallManagerDialog';
import * as Actions from '../call-handler/store/actions';
import { getInitialCallHandlerData, showMessage } from 'store/actions';
import { SPRoundIcon } from 'main/icons';
import Media from 'react-media';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import { openPreloadIncomingCallDialog, closeEditIncomingCallDialog } from './store/actions';
import classNames from 'classnames';
import IncomingCallHandler from './IncomingCallHandler';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {

    },
    badge: {
        top: 6,
        right: 4,
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    }
});

class IncomingCallDialog extends Component {
    state = {
        calls: [],
        user: {
            Data: {
                Queues: [],
            }
        },
        callDialog: false,
        dialogOpen: false,
        screenOverlay: null,
        incHeight: 700
    };

    ref = React.createRef();

    componentDidMount() {
        const { calls, user } = this.props;
        this.setState({ ...this.state, calls, user });   
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calls, user } = this.props;
        if (!prevProps.incomingCallDialog.props.open && this.props.incomingCallDialog.props.open) {
            if (!_.isEqual(this.props.calls, prevProps.calls) || !_.isEqual(this.props.user, prevProps.user)) {
                this.setState({ ...this.state, calls, user, callDialog: true});
            } else {
                this.setState({ ...this.state, callDialog: true });
            }
        }

        if (!_.isEqual(this.props.calls, prevProps.calls) || !_.isEqual(this.props.user, prevProps.user)) {
            this.setState({ ...this.state, calls, user });
        }

        // window["warn"]("incoming call CDU ran", calls)
    };

    closeDialog = () => {
        this.setState({ ...this.state, dialogOpen: false, incHeight: 700 });
    }

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null });
    }

    toggleRingtone = () => {
        const { user } = this.props;
        this.setState({ ...this.state, user: { ...user, DisableRingtoneYN: user.DisableRingtoneYN === 'Y' ? 'N' : 'Y' } }, () => this.props.updateUser({ ...this.state.user, Data: { ErrMsg: null } }, true));
    }

    refreshCallList = () => {
        const { Co } = this.props;
        this.props.showMessage({
            message: `Refreshing Call List`,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'refresh'
        })
        Promise.all([
            this.props.getInitialCallHandlerData(Co)
        ]).then(() =>
            this.props.showMessage({
                message: `Call List Refreshed`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            })
        ).catch(() => this.props.showMessage({
            message: `Error Refreshing Call List`,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'error'
        }))
    }

    closeComposeDialog = () => {
        this.props.customerDialog.type === 'edit' ? this.props.closeEditIncomingCallDialog() : this.props.closeNewCustomerDialog();
        this.setState({ ...this.state, callDialog: false, removedFromSplitscreen: true, rightSplitOverlay: null, leftSplitOverlay: null, rightDropBox: null, leftDropBox: null, screenOverlay: null, posReset: true})
    };

    closeDialogForSplitscreen = () => {
        this.props.customerDialog.type === 'edit' ? this.props.closeEditIncomingCallDialog() : this.props.closeNewCustomerDialog();
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        return(
            <>
            <Menu id="call-handler-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.closeMenu}>
                <MenuItem onClick={() => this.setState({ ...this.state, dialogOpen: true, anchorEl: null })}><Icon className="mr-24">phone</Icon>View Call Manager</MenuItem>
                <MenuItem onClick={() => this.setState({ ...this.state, anchorEl: null }, this.refreshCallList)}><Icon className="mr-24">refresh</Icon>Refresh List</MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.user.DisableRingtoneYN !== 'Y'}
                                onChange={this.toggleRingtone}
                                color="primary"
                            />
                        }
                        label="Enable Ringtone"
                    />
                </MenuItem>
            </Menu>

            <IconButton style={{
                position: 'absolute',
                right: 112,
                //top: !matches ? 8 : 4,
                top: 8,
                color: 'white',
                //display: matches ? 'block' : 'none'
            }}
                className="dialog-header-icon"
                onClick={minimizeFxn}
            >
                <Icon>minimize</Icon>
            </IconButton>
            <IconButton style={{
                position: 'absolute',
                right: 64,
                top: matches ? 10 : 4,
                color: 'white'
            }}
                onClick={(e) => {
                    this.setState({ ...this.state, anchorEl: e.target });
                }}
                className="dialog-header-icon"
            >
                <Icon>more_vert</Icon>
            </IconButton>
            <IconButton style={{
                position: 'absolute',
                right: 10,
                top: matches ? 10 : 4,
                color: 'white'
            }}
                onClick={(e) => {
                    //this.closeComposeDialog();
                    closeFxn(e)
                }}
                className="dialog-header-icon"
            >
                <Icon>close</Icon>
            </IconButton>
            </>
        )
    }

    ToolbarContents = () => {
        return (
            <Typography variant="subtitle1" color="inherit">
                {/* <SPRoundIcon className="cursor-pointer text-32 align-middle mr-8" /> */}
                <Icon className="text-32 align-middle mr-8" style={{color: '#d1e751'}}>phone</Icon>
                Incoming Contacts
            </Typography>
        )
    }

    heightUpdateFxn = (position) => {
        if(position === 'upper-right' || position === 'upper-left' || position === 'lower-right' || position === 'lower-left'
            || position === 'upper-right-2' || position === 'upper-left-2' || position === 'lower-right-2' || position === 'lower-left-2'
        ){
            this.setState({ ...this.state, incHeight: 250})
        } else {
            this.setState({ ...this.state, incHeight: 700})
        }

    }

    render() {
        const { classes, openCallDraft, callID, username, setCall, call, pageLayout, Co, incomingCallDialog } = this.props;
        const { anchorEl, user } = this.state;

        const { Queues } = user.Data;

        const dialogProps = {
            ...incomingCallDialog.props,
            className: classes.root,
            onClose: this.props.closeEditIncomingCallDialog,
            fullWidth: true,
            maxWidth: "md",
            //hideBackdrop: true,
            disableEnforceFocus: true
        }

        //TODO: change this.props.calls to this.state.calls when done with dummy data

        // if(this.props.calls && this.props.calls.length > 0 ){
        //     this.props.calls[0].Status = 'answered'
        // }

        const calls = _.filter(this.state.calls, (o) => { return _.find(Queues, (q) => { return q.Queue == o.Queue && q.TextEnabledYN === (o.Type === "text" ? "Y" : q.TextEnabledYN) }) })

        //let activeCall = this.props.calls && this.props.calls.length > 0 ? _.filter(this.props.calls, (o) => { return o.Status === 'answered'}) : null

        let activeCall = this.state.calls && this.state.calls.length > 0 ? _.filter(this.state.calls, (o) => { return o.Status === 'answered'}) : null

        // window["warn"]("props calls", this.props.calls, activeCall, this.state.calls)

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={SlideUp}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.props.closeEditIncomingCallDialog}
                        closeDialogForSplitscreen={this.props.closeEditIncomingCallDialog}
                        header={'Incoming Calls'}
                        type="Incoming Calls"
                        picRef={this.ref.current}
                        icon="phone"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openPreloadIncomingCallDialog}
                        description={'Incoming Calls'}
                        storeDialog={incomingCallDialog}
                        TypeState={this.state.callDialog}
                        screenOverlay={this.state.screenOverlay}
                        heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                    >
                        <>
                            <CallManagerDialog open={this.state.dialogOpen} onClose={this.closeDialog} />
                            {/* <FuseAnimateGroup enter={{ animation: "transition.slideUpBigIn" }} ref={this.ref}> className="h-full" height: "calc(100% - 64px)" */}
                            <div className="flex h-full">

                                <div style={{flex: 2, position: 'relative'}}>
                                    { activeCall && activeCall.length > 0 ? 
                                            <div style={{display: 'flex', justifyContent: 'center', height: this.state.incHeight, flexWrap: 'wrap', flexDirection: "column"}}>
                                                <div style={{flex: 2}}></div>
                                                <div style={{flex: 2}}>
                                                    <IncomingCallHandler call={activeCall[0]} id={activeCall[0].Sid} handler={activeCall[0].HandledBy} />
                                                </div>
                                                <div style={{flex: 2}}></div>
                                            </div>
                                        : ""
                                    }
                                </div>
                                <div style={{flex: 1, borderLeft: '1px solid lightgrey', paddingLeft: '5px', position: 'relative', height: '100%' }} className="h-full"> 
                                {
                                    calls.map((value, index) => {
                                        // if (value.HandledBy === user.UserName && value.Sid !== call.CallSid) { height: 700
                                        //     setCall(value);
                                        // }; height: this.state.incHeight
                                        return (
                                            (!value.HandledBy || value.HandledBy === user.UserName) ?
                                                <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', flexDirection: "column"}}>
                                                    <div style={{flex: 1}}>
                                                        <CallHandlerCallCard key={value.Sid} pageLayout={pageLayout} call={value} id={value.Sid} handler={value.HandledBy} />
                                                    </div>
                                                </div>
                                                : ''
                                        )
                                    })
                                }
                                </div>
                                </div>
                            {/* </FuseAnimateGroup> */}
                        </>
                    </DraggableDialog>
                }
            </Media>
        );
    }
}

//

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCallDraft: Actions.openCallDraft,
        setCall: Actions.setCall,
        updateUser,
        getInitialCallHandlerData,
        showMessage,
        openPreloadIncomingCallDialog,
        closeEditIncomingCallDialog,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, contactsApp, spReducers, incomingCallApp }) {
    return {
        Co: spReducers.companies.Co,
        callHandler: callHandlerApp.callHandler,
        callID: callHandlerApp.callHandler.id,
        calls: spReducers.callHandler.Calls,
        user: spReducers.userProfiles.User,
        call: callHandlerApp.callHandler.call,
        incomingCallDialog: incomingCallApp.incomingCall,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(IncomingCallDialog)));