import axios from 'axios/index';

export const SET_SERVICE_UNIT_CATEGORY_DATA = '[SPCONNECTION] SET SERVICE UNIT CATEGORY DATA';
export const GET_SERVICE_UNIT_CATEGORY_DATA = '[SPCONNECTION] GET SERVICE UNIT CATEGORY DATA';
export const SUBSCRIBE_SERVICE_UNIT_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE SERVICE UNIT CATEGORY LIST';

export function getInitialServiceUnitCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ServiceUnitCategory`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getServiceUnitCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ServiceUnitCategory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_SERVICE_UNIT_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setServiceUnitCategoryData(data) {
    return {
        type: SET_SERVICE_UNIT_CATEGORY_DATA,
        payload: data
    };
}