import axios from 'axios/index';

export const SET_INVENTORY_MANAGER_LOCATION_DATA = '[SPCONNECTION] SET INVENTORY MANAGER LOCATION DATA';
export const GET_INVENTORY_MANAGER_LOCATION_DATA = '[SPCONNECTION] GET INVENTORY MANAGER LOCATION DATA';

export function getInitialInventorManagerLocationData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryLocationManager?Co=${encodeURIComponent(Co)}`);

    request.then((response) => {
        return response.data;
    });
}

export function getInventoryManagerLocationData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryLocationManager?Co=${encodeURIComponent(Co)}`);

    return (dispatch) => {
        request.then((response) => {
            window["warn"]("getInventoryManagerLocationData", response)
            dispatch({
                type: GET_INVENTORY_MANAGER_LOCATION_DATA,
                payload: response.data
            })
        });
    }
}

export function setInventoryManagerLocationData(data) {
    return {
        type: SET_INVENTORY_MANAGER_LOCATION_DATA,
        payload: data
    };
}