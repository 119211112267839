import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  TextField,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Divider,
  Icon,
  IconButton,
  Typography,
  Tooltip,
  Toolbar,
  AppBar,
  Avatar,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  InputAdornment,
} from '@material-ui/core'
import {
  AccountCircle,
  AccountBalance,
  Assignment,
  AttachFile,
  Business,
  BusinessCenter,
  ChevronRight,
  ChevronLeft,
  Dashboard,
  History,
  List as ListIcon,
  LocalShipping,
  LocationOn,
  Menu as MenuIcon,
  Payment,
  Receipt,
  ViewList,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles/index'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import { connect } from 'react-redux'
import Media from 'react-media'
import AgreementTypeTasksList from '../agreement-type-tasks/AgreementTypeTasksList'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import _ from '@lodash'

function Marker({ text, icon, style, badge, heading, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <div onClick={onClick}>
        <img
          onMouseDown={e => {
            e.stopPropagation()
          }}
          style={style}
          src={icon}
        />
      </div>
    </Tooltip>
  )
}

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: 73,
  },
  formControl: {
    marginBottom: 24,
    // overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  inputLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: '52px',
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
})
const newAgreementTypeState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  AgreementType: null,
  Name: null,
  Description: null,
  Prefix: null,
  Notes: null,
  Price: null,
  BillingFrequency: null,
  RevenueuType: 'T',
  AnnualVisits: null,
  AttachmentID: null,
  RateTemplate: null,
  LaborDiscount: null,
  ActiveYN: 'Y',
  IncentiveType: null,
  IncentiveRate: null,
  IncentiveLaborType: null,
  RenewalIncentiveType: null,
  RenewalIncentiveRate: null,
  PriceMethod: 'T',
  TermLength: null,
  PayTerms: null,
  Disclaimer: null,
  Data: {
    Tasks: [],
    Attachments: [],
  },
  value: 0,
  EnablePriceOverrideYN: null,
}

class AgreementTypesDialog extends Component {
  state = { ..._.cloneDeepWith(newAgreementTypeState) }

  componentDidMount() {}

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    newAgreementTypeState.Co = this.props.Co
    if (
      !prevProps.agreementTypeDialog.props.open &&
      this.props.agreementTypeDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.agreementTypeDialog.type === 'edit' &&
        this.props.agreementTypeDialog.data &&
        !_.isEqual({ ...this.props.agreementTypeDialog.data }, prevState)
      ) {
        const { data } = this.props.agreementTypeDialog
        this.setState({ ...newAgreementTypeState, ...data })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.agreementTypeDialog.type === 'new' &&
        !_.isEqual({ ...newAgreementTypeState }, prevState)
      ) {
        this.setState({ ...newAgreementTypeState })
      }
    } else {
      if (
        this.props.agreementTypeDialog.type === 'update' &&
        this.props.agreementTypeDialog.data &&
        !_.isEqual({ ...this.props.agreementTypeDialog.data }, prevState)
      ) {
        const { data } = this.props.agreementTypeDialog
        this.props.agreementTypeDialog.type = 'edit'
        this.setState({ ...data })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.agreementTypeDialog.type === 'edit'
      ? this.props.closeEditAgreementTypeDialog()
      : this.props.closeNewAgreementTypeDialog()
  }

  //handleAddressChange = (event) => {
  //    const { Address1, City, State, Zip, Latitude, Longitude } = this.state;
  //    //if (Latitude == null || Longitude == null || Latitude.length < 2 || Longitude.length < 2) {
  //    if (Address1.length > 5 && City.length > 2 && State.length >= 2) {
  //    this.setState({ Address: `${this.state.Address1.trim()} ${this.state.City.trim()}, ${this.state.State.trim()} ${this.state.Zip.trim()}` });
  //        Geocode.setApiKey(googleMapsApiKey);
  //        Geocode.fromAddress(`${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`.trim()).then(
  //            response => {
  //                const { lat, lng } = response.results[0].geometry.location;
  //                this.setState({ Latitude: lat, Longitude: lng });
  //                window["log"]('GPS: ', response, this.state);
  //            },
  //            error => {
  //                window["error"](error);
  //            }
  //        );
  //    }
  //    //}
  //};
  canBeSubmitted() {
    const { BusinessUnit, Division, AgreementType, Name, Description } =
      this.state
    return (
      BusinessUnit &&
      Division &&
      AgreementType &&
      Name &&
      Description &&
      BusinessUnit.length > 0 &&
      Division.length > 0 &&
      AgreementType.length > 0 &&
      Name.length > 0 &&
      Description.length > 0
    )
  }

  render() {
    const {
      classes,
      securables,
      agreementTypeDialog,
      addAgreementType,
      updateAgreementType,
      removeAgreementType,
      businessUnits,
      divisions,
      rateTemplates,
      laborTypes,
      payTerms,
    } = this.props
    const { Co, Data, value } = this.state
    const accessLevel = _.find(securables, { Securable: 'agreement-types' })
    return (
      <Media query='(min-width: 512px) and (min-height: 512px)'>
        {matches => (
          <Dialog
            classes={{
              root: classes.root,
              paper: matches
                ? classes.paper
                : 'full-screen-dialog' /*if fullScreen*/,
            }}
            fullScreen={!matches}
            className={classes.root}
            {...agreementTypeDialog.props}
            open={Boolean(accessLevel) && agreementTypeDialog.props.open}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth='md'
          >
            <AppBar
              position='static'
              elevation={1}
              className='dialog-header' /*if fullScreen*/
            >
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  {['new', 'customer'].indexOf(agreementTypeDialog.type) > -1
                    ? 'New Agreement Type'
                    : `Agreement Type ${this.state.AgreementType} Overview`}
                </Typography>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches ? 10 : 4 /*if fullScreen*/,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon' /*if fullScreen*/
              >
                <Icon>close</Icon>
              </IconButton>
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                variant='scrollable'
                scrollButtons='on'
              >
                <Tab
                  disableRipple
                  icon={<Icon>library_books</Icon>}
                  label='Info'
                />
                <Tab
                  disableRipple
                  icon={
                    <Badge
                      badgeContent={
                        this.state.Data && this.state.Data.Tasks
                          ? this.state.Data.Tasks.length
                          : 0
                      }
                      color='error'
                    >
                      <Icon>playlist_add</Icon>
                    </Badge>
                  }
                  label='Tasks'
                />
                <Tab
                  disableRipple
                  icon={
                    <Badge
                      badgeContent={
                        this.state.Data && this.state.Data.Attachments
                          ? this.state.Data.Attachments.length
                          : 0
                      }
                      color='error'
                    >
                      <Icon>attach_file</Icon>
                    </Badge>
                  }
                  label='Attachments'
                />
                <Tab disableRipple icon={<Dashboard />} label='Dashboard' />
              </Tabs>
            </AppBar>

            {value === 0 && (
              <TabContainer>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20'>
                    <Icon color='action'>library_books</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Business Unit'
                    id='businessUnit'
                    name='BusinessUnit'
                    value={this.state.BusinessUnit || ''}
                    onChange={e =>
                      this.setState({
                        ...this.state,
                        BusinessUnit: e.target.value,
                      })
                    }
                    // onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    disabled={agreementTypeDialog.type === 'new' ? false : true}
                    required
                  >
                    {businessUnits && businessUnits.length > 0
                      ? businessUnits.map(value => (
                          <MenuItem value={value.BusinessUnit}>
                            {`${value.BusinessUnit} - ${value.Description}`}
                          </MenuItem>
                        ))
                      : ''}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Division'
                    id='division'
                    name='Division'
                    value={this.state.Division || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    disabled={agreementTypeDialog.type === 'new' ? false : true}
                    required
                  >
                    {this.state.BusinessUnit &&
                    this.state.BusinessUnit.length > 0 ? (
                      divisions && divisions.length > 0 ? (
                        divisions.map(value =>
                          value.BusinessUnit == this.state.BusinessUnit ? (
                            <MenuItem value={value.Division}>
                              {`${value.Division} - ${value.Description}`}
                            </MenuItem>
                          ) : (
                            ''
                          ),
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      <MenuItem>Please Select a Business Unit</MenuItem>
                    )}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Agreement Type'
                    id='agreementType'
                    name='AgreementType'
                    value={this.state.AgreementType || ''}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={agreementTypeDialog.type === 'new' ? false : true}
                    required
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>chat_bubble_outline</Icon>
                  </div>
                  <div className='sm:flex w-full'>
                    <div className='sm:flex sm:w-1/3'>
                      <TextField
                        className={classes.formControl}
                        label='Name'
                        id='name'
                        name='Name'
                        value={this.state.Name || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        fullWidth
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                    </div>
                    <div className='sm:flex sm:w-2/3'>
                      <TextField
                        className={classes.formControl}
                        label='Description'
                        id='description'
                        name='Description'
                        value={this.state.Description || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        fullWidth
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classNames(classes.formControl, 'sm:w-1/4')}
                        label='Prefix'
                        id='prefix'
                        name='Prefix'
                        value={this.state.Prefix || ''}
                        onChange={this.handleChange}
                        variant='outlined'
                        fullWidth
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>today</Icon>
                  </div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Billing Frequency'
                    id='billingFrequency'
                    name='BillingFrequency'
                    value={this.state.BillingFrequency || ''}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                  >
                    <MenuItem value='O'>Once</MenuItem>
                    <MenuItem value='M'>Monthly</MenuItem>
                    <MenuItem value='B'>Bi-Monthly</MenuItem>
                    <MenuItem value='Q'>Quarterly</MenuItem>
                    <MenuItem value='S'>Semi-Annual</MenuItem>
                    <MenuItem value='A'>Annual</MenuItem>
                    <MenuItem value='*'>Per Agreement</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Revenue Type'
                    id='revenueType'
                    name='RevenueType'
                    value={this.state.RevenueType || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                  >
                    <MenuItem value='T'>Time of Service</MenuItem>
                    <MenuItem value='M'>Monthly Ammortization</MenuItem>
                    <MenuItem value='B'>Time of Billing</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, 'pr-10')}
                    label='Length of Agreement'
                    id='termLength'
                    name='TermLength'
                    value={this.state.TermLength || ''}
                    onChange={this.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          Year
                          {!this.state.TermLength || this.state.TermLength > 1
                            ? 's'
                            : ''}
                        </InputAdornment>
                      ),
                      type: 'number',
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    variant='outlined'
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>settings</Icon>
                  </div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Price Method'
                    id='priceMethod'
                    name='PriceMethod'
                    value={this.state.PriceMethod || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                  >
                    <MenuItem value='T'>Task-Based</MenuItem>
                    <MenuItem value='F'>Flat Price</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Pay Terms'
                    options={payTerms}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.PayTerms}>
                          {`${value.PayTerms} - ${value.Description}`}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.PayTerms || ''}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        PayTerms: option.PayTerms,
                      })
                    }
                    required
                  />
                  {this.state.PriceMethod == 'F' && (
                    <React.Fragment>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classNames(classes.formControl, '')}
                        label='Price'
                        id='price'
                        name='Price'
                        value={this.state.Price || ''}
                        onChange={this.handleChange}
                        InputProps={{
                          startAdornment: Boolean(this.state.Price) && (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                          type: 'number',
                        }}
                        variant='outlined'
                        fullWidth
                      />
                    </React.Fragment>
                  )}
                  {/* <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classNames(classes.formControl, "")}
                                        label="Labor Discount"
                                        id="laborDiscount"
                                        name="LaborDiscount"
                                        value={this.state.LaborDiscount}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: Boolean(this.state.LaborDiscount) }}
                                        InputProps={{
                                            endAdornment: Boolean(this.state.LaborDiscount) && <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    /> */}
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Member Rate Template'
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    id='RateTemplate'
                    name='RateTemplate'
                    value={this.state.RateTemplate || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                  >
                    {this.state.BusinessUnit &&
                    this.state.BusinessUnit.length > 0 ? (
                      this.state.Division && this.state.Division.length > 0 ? (
                        rateTemplates && rateTemplates.length > 0 ? (
                          rateTemplates.map((value, index) =>
                            this.state.BusinessUnit == value.BusinessUnit &&
                            this.state.Division == value.Division ? (
                              <MenuItem key={index} value={value.RateTemplate}>
                                {`${value.Description}`}
                              </MenuItem>
                            ) : (
                              ''
                            ),
                          )
                        ) : (
                          ''
                        )
                      ) : (
                        <MenuItem value=''>Please Select a Division</MenuItem>
                      )
                    ) : (
                      <MenuItem value=''>
                        Please Select a Business Unit
                      </MenuItem>
                    )}
                  </TextField>
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>monetization_on</Icon>
                  </div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Incentive Type'
                    id='incentiveType'
                    name='IncentiveType'
                    value={this.state.IncentiveType || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                  >
                    <MenuItem value={null}>None</MenuItem>
                    <MenuItem value='F'>Fixed Rate</MenuItem>
                    <MenuItem value='P'>Percentage</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Incentive Rate'
                    id='incentiveRate'
                    name='IncentiveRate'
                    value={this.state.IncentiveRate || ''}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: this.state.IncentiveType === 'F' &&
                        this.state.IncentiveRate !== null && (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                      endAdornment: this.state.IncentiveType === 'P' &&
                        this.state.IncentiveRate !== null && (
                          <InputAdornment position='end'>%</InputAdornment>
                        ),
                      type: 'number',
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={this.state.IncentiveType === null}
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4 p-0 sm:pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Incentive Labor Type'
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: Boolean(this.state.IncentiveLaborType),
                    }}
                    options={_.filter(laborTypes, {
                      BusinessUnit: this.state.BusinessUnit,
                      Division: this.state.Division,
                      Type: 'O',
                    })}
                    menuItemComponent={(value, index) => {
                      return (
                        <MenuItem key={index} value={value.LaborType}>
                          {value.Description}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.IncentiveLaborType || ''}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        IncentiveLaborType: option.LaborType,
                      })
                    }
                    required
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Renewal Incentive Type'
                    id='renewalIncentiveType'
                    name='RenewalIncentiveType'
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: Boolean(this.state.RenewalIncentiveType),
                    }}
                    value={this.state.RenewalIncentiveType || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                    required
                  >
                    <MenuItem value={null}>None</MenuItem>
                    <MenuItem value='F'>Fixed Rate</MenuItem>
                    <MenuItem value='P'>Percentage</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(classes.formControl, '')}
                    label='Renewal Incentive Rate'
                    id='renewalIncentiveRate'
                    name='RenewalIncentiveRate'
                    value={this.state.RenewalIncentiveRate || ''}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: this.state.RenewalIncentiveType === 'F' &&
                        this.state.RenewalIncentiveRate !== null && (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                      endAdornment: this.state.RenewalIncentiveType === 'P' &&
                        this.state.RenewalIncentiveRate !== null && (
                          <InputAdornment position='end'>%</InputAdornment>
                        ),
                      type: 'number',
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                      shrink: Boolean(this.state.RenewalIncentiveRate),
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={this.state.RenewalIncentiveType === null}
                  />
                </div>
                {/*  */}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>announcement</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Disclaimer'
                    id='disclaimer'
                    name='Disclaimer'
                    value={this.state.Disclaimer || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div className='sm:flex sm:justify-between'>
                  <div className=''>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                  </div>
                  <div className=''>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.EnablePriceOverrideYN === 'Y'}
                          name='EnablePriceOverrideYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Enable Price Override'
                    />
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 1 && agreementTypeDialog.type == 'edit' && (
              <TabContainer>
                <AgreementTypeTasksList
                  agreementType={{ ...this.state }}
                  agreementTypeTaskData={[...this.state.Data.Tasks]}
                />
              </TabContainer>
            )}
            {/* {value === 2 && agreementTypeDialog.type == 'edit' && <TabContainer><FileList /></TabContainer>}
                        {value === 3 && agreementTypeDialog.type == 'edit' && <TabContainer><AnalyticsDashboardApp /></TabContainer>} */}

            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {['new', 'customer'].indexOf(agreementTypeDialog.type) > -1 ? (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      addAgreementType(rec)
                      //this.closeComposeDialog();
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      updateAgreementType(rec)
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Save
                  </Button>
                )}
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}
AgreementTypesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditAgreementTypeDialog: Actions.closeEditAgreementTypeDialog,
      closeNewAgreementTypeDialog: Actions.closeNewAgreementTypeDialog,
      addAgreementType: Actions.addAgreementType,
      updateAgreementType: Actions.updateAgreementType,
      removeAgreementType: Actions.removeAgreementType,
    },
    dispatch,
  )
}

function mapStateToProps({ agreementTypesApp, spReducers }) {
  return {
    agreementTypeDialog: agreementTypesApp.agreementTypes.agreementTypeDialog,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    laborTypes: spReducers.laborTypes,
    departments: spReducers.departments,
    Co: spReducers.companies.Co,
    rateTemplates: spReducers.rateTemplates,
    securables: spReducers.userProfiles.User.Data.Securables,
    payTerms: spReducers.payTerms,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AgreementTypesDialog),
)
