import axios from 'axios/index';
import _ from '@lodash';

export const SET_CATALOG_DATA = '[SPCONNECTION] SET CATALOG DATA';
export const GET_CATALOG_DATA = '[SPCONNECTION] GET CATALOG DATA';
export const SET_SAVED_CATALOGS = '[SPCONNECTION] SET SAVED CATALOGS';
export const SET_ASSEMBLY_DATA = '[SPCONNECTION] SET ASSEMBLY DATA';
export const GET_ASSEMBLY_DATA = '[SPCONNECTION] GET ASSEMBLY DATA';
export const SUBSCRIBE_ASSEMBLY_LIST = '[SPCONNECTION] SUBSCRIBE ASSEMBLY LIST';
export const SET_ASSEMBLY_CATEGORY_DATA = '[SPCONNECTION] SET ASSEMBLY CATEGORY DATA';
export const GET_ASSEMBLY_CATEGORY_DATA = '[SPCONNECTION] GET ASSEMBLY CATEGORY DATA';
export const GET_ASSEMBLY_PROGRESS = '[SPCONNECTION] GET ASSEMBLY PROGRESS';
export const SET_ASSEMBLY_PROGRESS = '[SPCONNECTION] SET ASSEMBLY PROGRESS';
export const SUBSCRIBE_ASSEMBLY_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE ASSEMBLY CATEGORY LIST';
export const SET_PREFERRED_VIEW = '[SPCONNECTION] SET PREFERRED VIEW';
export const SET_SORT_CATALOG = '[SPCONNECTION] SORT CATALOG';

export function getInitialCatalogData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Catalog`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getInitialAssemblyData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Assembly`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getAssemblyCategoryAssignmentData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/AssemblyCategoryAssignment`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getCatalogData(Co) {

    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Catalog`, {
            params: {
                Co
            }
        });
        request.then((response) => {
            dispatch({
                type: GET_CATALOG_DATA,
                payload: response.data
            });
        });
    }
}

export function getAssemblyData(Co) {

    return (dispatch, getState) => {
        const state = getState();
        const { lastUpdate, assemblies } = state.spReducers.catalog;

        const request = axios.get(`${window["apiLocation"]}/api/Assembly`, {
            params: {
                Co,
                LastUpdate: lastUpdate,
            }
        });
        request.then((response) => {
            response.data.map((value) => {
                const { BusinessUnit, Division, Assembly } = value;
                const index = _.findIndex(assemblies, { Co, BusinessUnit, Division, Assembly });
                if (index < 0) {
                    assemblies.push(value);
                } else {
                    assemblies.splice(index, 1, value);
                }
            });
            dispatch({
                type: GET_ASSEMBLY_DATA,
                payload: assemblies
            });
        });
    }
}

export function setAssemblyData(data) {
    return {
        type: SET_ASSEMBLY_DATA,
        payload: data
    };
}

export function setPreferredView(data) {
    return {
        type: SET_PREFERRED_VIEW,
        payload: data
    };
}

export function setSortCatalog(data) {
    return {
        type: SET_SORT_CATALOG,
        payload: data
    };
}

export function getInitialAssemblyCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/AssemblyCategory`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getAssemblyCategoryData(Co) {

    return (dispatch, getState) => {
        const state = getState();
        const { lastUpdate, categories } = state.spReducers.catalog;

        const request = axios.get(`${window["apiLocation"]}/api/AssemblyCategory`, {
            params: {
                Co,
                LastUpdate: lastUpdate,
            }
        });
        request.then((response) => {
            response.data.map((value) => {
                const { BusinessUnit, Division, Category } = value;
                const index = _.findIndex(categories, { Co, BusinessUnit, Division, Category });
                if (index < 0) {
                    categories.push(value);
                } else {
                    categories.splice(index, 1, value);
                }
            });
            dispatch({
                type: GET_ASSEMBLY_CATEGORY_DATA,
                payload: categories
            })
        });
    }
}

export function setAssemblyCategoryData(data) {
    return {
        type: SET_ASSEMBLY_CATEGORY_DATA,
        payload: data
    };
}