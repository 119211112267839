import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import InvoiceDialog from 'main/content/apps/invoices/InvoiceDialog';
import InvoiceHeader from 'main/content/apps/invoices/InvoicesHeader';
import InvoicesLeftSidebarHeader from 'main/content/apps/invoices/InvoicesLeftSidebarHeader';
import InvoiceList from 'main/content/apps/invoices/InvoicesList';
import InvoiceSidebarContent from 'main/content/apps/invoices/InvoicesSidebarContent';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class InvoicesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getInvoices(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewInvoiceDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <InvoiceHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <InvoiceList />
                            }
                            leftSidebarHeader={
                                <InvoicesLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <InvoiceSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={classNames(classes.addButton, !matches ? "fixed" : "")}
                                onClick={openNewInvoiceDialog}
                            >
                                <Icon>person_add</Icon>
                            </Fab>
                        </FuseAnimate>
                        <InvoiceDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getInvoices: Actions.getInvoices,
        getUserData: Actions.getUserData,
        openNewInvoiceDialog: Actions.openNewInvoiceDialog
    }, dispatch);
}

function mapStateToProps({ invoicesApp }) {
    return {
        invoices: invoicesApp.invoices.entities,
        selectedInvoiceIds: invoicesApp.invoices.selectedInvoiceIds,
        searchText: invoicesApp.invoices.searchText,
        user: invoicesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesApp)));
