import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
    card: {
        width: 1200,
    },
    media: {
        width: 1200,
        height: 675,
    },
    iframe: {
        width: 1200,
        height: 675
    }
};

const VideoDetail = ({ video, classes }) => {
    if (!video) {
        return false;
    }
    const videoId = video.id.videoId;
    const url = `https://www.youtube.com/embed/${videoId}`;

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    title={video.snippet.title}
                >
                    <iframe className={classes.iframe} src={url}></iframe>
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom component="h2">
                        {video.snippet.channelTitle}
                    </Typography>
                    <Typography component="p">
                        {video.snippet.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

VideoDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoDetail);