import {combineReducers} from 'redux';
import textGathers from './text-gathers.reducer';
import user from './user.reducer';

const textGathersAppReducers = combineReducers({
    textGathers,
    user
});

export default textGathersAppReducers;
