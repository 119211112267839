import axios from 'axios';
import { getUserData } from 'main/content/apps/gl-accounts/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_GL_ACCOUNTS = '[GL ACCOUNTS APP] GET GL ACCOUNTS';
export const SET_SEARCH_TEXT = '[GL ACCOUNTS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_GL_ACCOUNTS = '[GL ACCOUNTS APP] TOGGLE IN SELECTED GL ACCOUNTS';
export const SELECT_ALL_GL_ACCOUNTS = '[GL ACCOUNTS APP] SELECT ALL GL ACCOUNTS';
export const DESELECT_ALL_GL_ACCOUNTS = '[GL ACCOUNTS APP] DESELECT ALL GL ACCOUNTS';
export const OPEN_NEW_GL_ACCOUNT_DIALOG = '[GL ACCOUNTS APP] OPEN NEW GL ACCOUNT DIALOG';
export const CLOSE_NEW_GL_ACCOUNT_DIALOG = '[GL ACCOUNTS APP] CLOSE NEW GL ACCOUNT DIALOG';
export const OPEN_EDIT_GL_ACCOUNT_DIALOG = '[GL ACCOUNTS APP] OPEN EDIT GL ACCOUNT DIALOG';
export const CLOSE_EDIT_GL_ACCOUNT_DIALOG = '[GL ACCOUNTS APP] CLOSE EDIT GL ACCOUNT DIALOG';
export const ADD_GL_ACCOUNT = '[GL ACCOUNTS APP] ADD GL ACCOUNT';
export const UPDATE_GL_ACCOUNT = '[GL ACCOUNTS APP] UPDATE GL ACCOUNT';
export const REMOVE_GL_ACCOUNT = '[GL ACCOUNTS APP] REMOVE GL ACCOUNT';
export const REMOVE_GL_ACCOUNTS = '[GL ACCOUNTS APP] REMOVE GL ACCOUNTS';
export const TOGGLE_STARRED_GL_ACCOUNT = '[GL ACCOUNTS APP] TOGGLE STARRED GL ACCOUNT';
export const TOGGLE_STARRED_GL_ACCOUNTS = '[GL ACCOUNTS APP] TOGGLE STARRED GL ACCOUNTS';
export const SET_GL_ACCOUNTS_STARRED = '[GL ACCOUNTS APP] SET GL ACCOUNTS STARRED ';

export function getGLAccounts(routeParams) {
    const request = axios.get('/api/glAccounts-app/glAccounts', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_GL_ACCOUNTS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedGLAccounts(glAccountId) {
    return {
        type: TOGGLE_IN_SELECTED_GL_ACCOUNTS,
        glAccountId
    }
}


export function selectAllGLAccounts() {
    return {
        type: SELECT_ALL_GL_ACCOUNTS
    }
}

export function deSelectAllGLAccounts() {
    return {
        type: DESELECT_ALL_GL_ACCOUNTS
    }
}


export function openNewGLAccountDialog() {
    return {
        type: OPEN_NEW_GL_ACCOUNT_DIALOG
    }
}

export function closeNewGLAccountDialog() {
    return {
        type: CLOSE_NEW_GL_ACCOUNT_DIALOG
    }
}

export function openEditGLAccountDialog(data) {
    return {
        type: OPEN_EDIT_GL_ACCOUNT_DIALOG,
        data
    }
}

export function closeEditGLAccountDialog() {
    return {
        type: CLOSE_EDIT_GL_ACCOUNT_DIALOG
    }
}

export function addGLAccount(newGLAccount) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post(`${window["apiLocation"]}/api/GLAccount`,
            newGLAccount
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_GL_ACCOUNT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `GL Account ${newGLAccount.GLAccount} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateGLAccount(glAccount) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.put(`${window["apiLocation"]}/api/GLAccount?Co=${encodeURIComponent(glAccount.Co)}&GLAccount=${encodeURIComponent(glAccount.GLAccount)}`,
            glAccount
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_GL_ACCOUNT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `GL Account ${glAccount.GLAccount} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeGLAccount(glAccountId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post('/api/glAccounts-app/remove-glAccount', {
            glAccountId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_GL_ACCOUNT
                })
            ]).then(() => dispatch(getGLAccounts(routeParams)))
        );
    };
}


export function removeGLAccounts(glAccountIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post('/api/glAccounts-app/remove-glAccounts', {
            glAccountIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_GL_ACCOUNTS
                }),
                dispatch({
                    type: DESELECT_ALL_GL_ACCOUNTS
                })
            ]).then(() => dispatch(getGLAccounts(routeParams)))
        );
    };
}

export function toggleStarredGLAccount(glAccountId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post('/api/glAccounts-app/toggle-starred-glAccount', {
            glAccountId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_GL_ACCOUNT
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getGLAccounts(routeParams)))
        );
    };
}

export function toggleStarredGLAccounts(glAccountIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post('/api/glAccounts-app/toggle-starred-glAccounts', {
            glAccountIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_GL_ACCOUNTS
                }),
                dispatch({
                    type: DESELECT_ALL_GL_ACCOUNTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getGLAccounts(routeParams)))
        );
    };
}

export function setGLAccountsStarred(glAccountIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post('/api/glAccounts-app/set-glAccounts-starred', {
            glAccountIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_GL_ACCOUNTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_GL_ACCOUNTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getGLAccounts(routeParams)))
        );
    };
}

export function setGLAccountsUnstarred(glAccountIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().glAccountsApp.glAccounts;

        const request = axios.post('/api/glAccounts-app/set-glAccounts-unstarred', {
            glAccountIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_GL_ACCOUNTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_GL_ACCOUNTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getGLAccounts(routeParams)))
        );
    };
}
