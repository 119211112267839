import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList';
import WorkOrdersHeader from 'main/content/apps/work-orders/WorkOrdersHeader';
import WorkOrdersLeftSidebarHeader from 'main/content/apps/work-orders/WorkOrdersLeftSidebarHeader';
import WorkOrdersRightSidebarHeader from 'main/content/apps/work-orders/WorkOrdersRightSidebarHeader';
import WorkOrdersSidebarContent from 'main/content/apps/work-orders/WorkOrdersSidebarContent';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class WorkOrdersApp extends Component {

    componentDidMount() {
        const { Co, WorkOrder, Trip } = this.props.match.params;
        window["warn"](this.props.match.params, Co, WorkOrder);
        if (Co && WorkOrder) {
            this.props.openEditWorkOrderDialog({ Co, WorkOrder }, Boolean(Trip) ? Number(Trip) : null);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co, WorkOrder, Trip } = this.props.match.params;
        window["warn"](this.props.match.params, Co, WorkOrder);
        if (Co && WorkOrder && (Co !== prevProps.match.params.Co || WorkOrder !== prevProps.match.params.WorkOrder || Trip !== prevProps.match.params.Trip)) {
            this.props.openEditWorkOrderDialog({ Co, WorkOrder }, Boolean(Trip) ? Number(Trip) : null);
        }
    }

    render() {
        const { classes, openNewWorkOrderDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <WorkOrdersHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <WorkOrdersLeftSidebarHeader />
                            }
                            content={
                                <WorkOrdersList />

                            }
                            leftSidebarContent={
                                <WorkOrdersSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWorkOrders: Actions.getWorkOrders,
        getUserData: Actions.getUserData,
        openNewWorkOrderDialog: Actions.openNewWorkOrderDialog,
        openEditWorkOrderDialog: Actions.openEditWorkOrderDialog,
    }, dispatch);
}

function mapStateToProps({ workOrdersApp }) {
    return {
        workOrders: workOrdersApp.workOrders.entities,
        selectedWorkOrderIds: workOrdersApp.workOrders.selectedWorkOrderIds,
        searchText: workOrdersApp.workOrders.searchText,
        user: workOrdersApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersApp)));
