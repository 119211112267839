import axios from 'axios/index';

export const SET_OPTIONS_TYPE_DATA = '[SPCONNECTION] SET OPTIONS TYPE DATA';
export const GET_OPTIONS_TYPE_DATA = '[SPCONNECTION] GET OPTIONS TYPE DATA';
export const SUBSCRIBE_OPTIONS_TYPE_LIST = '[SPCONNECTION] SUBSCRIBE OPTIONS TYPE LIST';

export function getInitialOptionsTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/OptionsType`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getOptionsTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/OptionsType`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_OPTIONS_TYPE_DATA,
                payload: response.data
            })
        });
    }
}

export function setOptionsTypeData(data) {
    return {
        type: SET_OPTIONS_TYPE_DATA,
        payload: data
    };
}