import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse';
import { Avatar, Button, Card, Checkbox, CircularProgress, Dialog, Fab, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, MobileStepper, TextField, Typography, Tooltip } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import * as WorkOrderActions from '../work-orders/store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import Swipeable from "react-swipeable";
import ReactQuill, { Quill } from 'react-quill';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import { impress } from 'main/content/compression/impress';
import parse, { domToReact } from 'html-react-parser';
import _ from '@lodash';
import axios from 'axios';

const ReactTableFixedColumns = withFixedColumns(ReactTable);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    editButton: {
        float: 'right',
        minHeight: 0,
        marginRight: 8,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
    },
    saveButton: {
        float: 'right',
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        float: 'right',
        minHeight: 0,
        marginRight: 8,
        height: 32,
        minWidth: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    formControl: {
        marginBottom: 24,
    },
    container: {
        // minHeight: 'calc(100vh - 164px)',
        // maxHeight: 'calc(100vh - 128px)',
        overflow: 'auto',
        position: 'relative',
        paddingBottom: 24,
        '::-webkit-scrollbar': {
            width: 0,
            height: 0
        },
    },
    buttonLabel: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    stepTitle: {
        color: theme.palette.primary.main,
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    }

});

const initialState = {
    ID: null,
    Co: null,
    Class: null,
    Step: null,
    Name: null,
    Description: null,
    Content: null,
    Data: {
        ErrMsg: null
    },
    DisplayIndex: null,
    lockEditor: true,
    Content: null,
    Showcase: null,
    Video: null,
    viewVideo: false,
    viewShowcase: false,
    uploading: false,
    prevStep: -1
}

class AcademyList extends Component {

    state = {
        ...initialState,
    };

    componentDidMount() {
        const { step } = this.props;
        this.setState({ ...initialState, ...step });
    }

    componentDidUpdate(prevProps, prevState) {
        const { step } = this.props;
        if (!_.isEqual(step, prevProps.step)) {
            this.setState({ ...initialState, ...step, prevStep: prevProps.step ? prevProps.step.DisplayIndex : -1 });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleEditorChange = (Content) => {
        this.setState({ ...this.state, Content })
    }

    handleShowcaseChange = (Showcase) => {
        this.setState({ ...this.state, Showcase })
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedAcademyMenu = (event) => {
        this.setState({ selectedAcademyMenu: event.currentTarget });
    };

    closeSelectedAcademyMenu = () => {
        this.setState({ selectedAcademyMenu: null });
    };

    toggleEditor = () => {
        const { lockEditor } = this.state;
        this.setState({ ...this.state, lockEditor: !lockEditor });
    }

    imgHandler = () => {
        const editor = this.quillRef.getEditor();
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = () => {
            const file = input.files[0];

            // Save current cursor state
            const range = editor.getSelection(true);

            //Compress image
            impress(file, 1024, 50, false, (img) => {
                // Insert uploaded image
                editor.insertEmbed(range.index, 'image', img.base64);
                editor.setSelection(range.index + 1);
            });

        }
    }


    selectShowcase = (e) => {
        const input = e.target;
        const file = input.files[0];
        const fileType = file['type'].split('/')[0];
        if (fileType === 'image') {
            impress(file, 1024, 50, false, (img) => {
                this.setState({ ...this.state, Video: null, Showcase: img.base64 });
            });
        }
        if (fileType === 'video') {
            const fd = new FormData();
            const { step } = this.props;
            const { Co, ID } = this.state;
            fd.append('video', file, file.name);
            this.setState({ ...this.state, uploading: true }, () => {
                axios.post(`${window["apiLocation"]}/api/Blob/PostBlob?filepath=${"assets/videos/classes/"}`, fd).then((res) => {
                    let Video = null;
                    Object.keys(res.data).map((value, index) => {
                        if (res.data[value].src) {
                            Video = res.data[value].src;
                        }
                    });
                    this.setState({ ...this.state, Video, Showcase: null, uploading: false, });
                });
            })
        }
    }

    handleSave = () => {
        const { step } = this.props;
        this.toggleEditor();
        if (!this.state.ID) {
            this.props.addStep(this.state);
        } else {
            this.props.updateStep({ ...step, ...this.state, Data: { ErrMsg: null } });
        }
    }

    handleDelete = () => {
        const { step } = this.props;
        this.toggleEditor();
        this.props.removeStep({ ...step, ...this.state, Data: { ErrMsg: null } });
    }

    handleNext = () => {
        const { selectedClass } = this.props;
        const { Steps } = selectedClass.Data;
        const { DisplayIndex } = this.state;
        if (DisplayIndex < (Steps.length - 1)) {
            this.props.setStep(Steps[DisplayIndex + 1]);
        }
    };

    handleBack = () => {
        const { selectedClass } = this.props;
        const { Data } = selectedClass;
        const { Steps } = Data;
        const { DisplayIndex } = this.state;
        if (DisplayIndex > 0) {
            this.props.setStep(Steps[DisplayIndex - 1]);
        }
    };

    render() {
        const { classes, Co, user, selectedClass, step, securables, pageLayout } = this.props;
        // const data = this.getFilteredArray((!this.props.selectedAcademyData ? (!this.props.academyData ? [] : this.props.academyData) : this.props.selectedAcademyData), searchText);
        const { lockEditor, DisplayIndex, prevStep, Content, Showcase, viewShowcase, Video, viewVideo, uploading } = this.state;
        const Step = DisplayIndex;
        const accessLevel = _.find(securables, { Securable: "learning" });
        const { Data } = selectedClass;
        const { Steps } = selectedClass.Data;
        const maxSteps = Steps.length;


        const toolbarOptions = [
            [{ size: ['small', false, 'large', 'huge'] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],

            ['link', 'image', 'video'],

            ['clean']                                       // remove formatting button
        ];

        const formats = [
            'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
            'list', 'bullet', 'indent', 'align',
            'link', 'image', 'video'
        ]

        const history = {
            delay: 2000,
            maxStack: 500,
            userOnly: true
        }

        if (this.quillRef && !this.state.imgHandler) {
            window["warn"](this.quillRef);
            this.quillRef.getEditor().getModule("toolbar").addHandler("image", this.imgHandler);
            this.setState({ ...this.state, imgHandler: true });
        }

        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        if (!selectedClass) {
            return (
                <div className={classNames(classes.container, "w-full")}>

                </div>
            );
        }
        return (
            <FuseAnimateGroup enter={{ animation: "transition.slideRightBigIn" }} leave={{ animation: "transition.slideRightBigOut" }} delay={300}>
                <div className={classes.container}>
                    {(((accessLevel && accessLevel.AccessLevel === "E" && user === selectedClass.AddedBy) || accessLevel && accessLevel.AccessLevel === "F") && step && lockEditor) &&
                        <IconButton className="float-right w-32 h-32" onClick={() => this.setState({ lockEditor: false })} color="inherit">
                            <Icon color="action">edit</Icon>
                        </IconButton>
                    }
                    {step && !lockEditor &&
                        <IconButton className="float-right w-32 h-32" onClick={() => this.setState({ lockEditor: true })} color="inherit">
                            <Icon color="action">close</Icon>
                        </IconButton>
                    }
                    {(((accessLevel && accessLevel.AccessLevel === "E" && user === selectedClass.AddedBy) || accessLevel && accessLevel.AccessLevel === "F") && !lockEditor) &&
                        <IconButton className="float-right mr-4 w-32 h-32" onClick={this.handleDelete} color="inherit">
                            <Icon color="error">delete</Icon>
                        </IconButton>
                    }
                    {(((accessLevel && accessLevel.AccessLevel === "E" && user === selectedClass.AddedBy) || accessLevel && accessLevel.AccessLevel === "F") && !lockEditor) &&
                        <IconButton className="float-right mr-4 w-32 h-32" onClick={this.handleSave} color="inherit">
                            <Icon color="primary">save</Icon>
                        </IconButton>
                    }
                    {lockEditor ?
                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-14 sm:text-20 mb-6 sm:mb-12"><Icon onClick={() => this.props.setClass(null)} color="primary" className="text-32 mr-4 cursor-pointer">arrow_back</Icon>{selectedClass.Name}</Typography>
                        :
                        <div className="w-full">
                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-14 sm:text-20 mb-6 sm:mb-12"><Icon onClick={(ev) => pageLayout().toggleLeftSidebar()} color="primary" className="text-32 mr-4 cursor-pointer">edit</Icon>Edit Step #{step.DisplayIndex + 1}</Typography>
                            <div className="lg:px-64 xl:px-256">
                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Name"

                                        id="name"
                                        name="Name"
                                        value={this.state.Name || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>

                    }
                    <div className={`w-full ${lockEditor ? "locked py:12 md:pt-16 px-4 md:px-48 lg:px-64 xl:px-256 xl:pt-96" : "xl:px-256"}`}>
                        <Swipeable onSwipedLeft={lockEditor ? this.handleNext : undefined} onSwipedRight={lockEditor ? this.handleBack : undefined}>
                            <FuseAnimateGroup enter={{ animation: Step > prevStep ? "transition.slideRightBigIn" : "transition.slideLeftBigIn" }} delay={300}>
                                <Card key={`${selectedClass.ID}_${Step}`}>
                                    <div className="w-full sm:flex">
                                        <div className="w-full p-12 md:p-24 min-h-256 sm:min-h-512 sm:max-h-512 sm:overflow-y-auto">
                                            <Typography className={classNames(classes.stepTitle, "font-bold text-16 w-full mb-16")}>{this.state.Name}</Typography>
                                            <Typography className="font-bold text-20 w-full mb-16">{this.state.Description}</Typography>
                                            <ReactQuill
                                                ref={(el) => { this.quillRef = el; }}
                                                theme="snow"
                                                className="w-full"
                                                value={Content}
                                                readOnly={lockEditor}
                                                modules={
                                                    {
                                                        toolbar: toolbarOptions,
                                                        history: history,
                                                        imageDrop: true,
                                                        imageResize: {
                                                            parchment: Quill.import('parchment'),
                                                            modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                        }
                                                    }
                                                }
                                                formats={formats}
                                                onChange={this.handleEditorChange}
                                            />
                                        </div>
                                        <div className={`w-full p-12 md:p-24 min-h-256 max-h-256 sm:min-h-512 sm:max-h-512 bg-grey-lightest border-t-light sm:border-t-none sm:border-l-light overflow-hidden relative ${lockEditor ? 'locked' : ''}`}>
                                            {Video &&
                                                <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute")} style={{ background: `#212121` }}>
                                                </div>
                                            }
                                            {Showcase &&
                                                <div className={classNames(classes.blurBG, "pin-l pin-r pin-t pin-b m-auto absolute")} style={{ backgroundImage: `url(${Showcase})` }}>
                                                    {viewShowcase &&
                                                        <Dialog
                                                            open={viewShowcase}
                                                            onClose={() => this.setState({ viewShowcase: false })}
                                                            maxWidth="lg"
                                                        >
                                                            <img className="w-full" src={Showcase} />
                                                        </Dialog>
                                                    }
                                                </div>
                                            }
                                            <div onClick={() => { if (!lockEditor && this.showcaseInput) { this.showcaseInput.click() } else { if (Showcase) { this.setState({ ...this.state, viewShowcase: !viewShowcase }) }; } }} className={classNames(Showcase ? classes.fitBG : "", "pin-l pin-r pin-t pin-b m-auto absolute cursor-pointer")} style={{ backgroundImage: Showcase ? `url(${Showcase})` : 'unset' }}>
                                                {!lockEditor && !Showcase && !Video &&
                                                    <div className="w-full flex text-center">
                                                        <Icon color="action" className="pin-t pin-b pin-r pin-l absolute m-auto text-48">cloud_upload</Icon>
                                                    </div>
                                                }
                                                {uploading &&
                                                    <div className="w-full flex text-center">
                                                        <CircularProgress color="primary" className="pin-t pin-b pin-r pin-l absolute m-auto" style={{ minWidth: 96, minHeight: 96 }} />
                                                    </div>
                                                }
                                                {Video &&
                                                    <video controls={true} disabled={!lockEditor} className="w-full h-full" preload="metadata">
                                                        <source src={Video} />
                                                    </video>
                                                }
                                                <input type="file" ref={el => { this.showcaseInput = el; }} style={{ display: 'none' }} onChange={this.selectShowcase} />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </FuseAnimateGroup>
                        </Swipeable>
                        <MobileStepper
                            steps={maxSteps}
                            classes={{ root: "mt-4 bg-transparent" }}
                            position="static"
                            activeStep={Step}
                            className={classes.mobileStepper}
                            nextButton={
                                <Button size="small" onClick={this.handleNext} disabled={Step === (maxSteps - 1)}>
                                    Next
                                    <Icon>keyboard_arrow_right</Icon>
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={this.handleBack} disabled={Step === 0}>
                                    <Icon>keyboard_arrow_left</Icon>
                                    Back
                                </Button>
                            }
                        />
                    </div>
                    {/* {lockEditor &&
                        <React.Fragment>
                            {(step && step.DisplayIndex > 0) &&
                                <Icon onClick={() => this.props.setStep(selectedClass.Data.Steps[step.DisplayIndex - 1])} color="primary" className="pin-l pin-t pin-b m-auto absolute md:ml-48 lg:ml-84 cursor-pointer">chevron_left</Icon>
                            }
                            {(selectedClass.Data && step && step.DisplayIndex < (selectedClass.Data.Steps.length - 1)) &&
                                <Icon onClick={() => this.props.setStep(selectedClass.Data.Steps[step.DisplayIndex + 1])} color="primary" className="pin-r pin-t pin-b m-auto absolute md:mr-48 lg:mr-84 cursor-pointer">chevron_right</Icon>

                            }
                        </React.Fragment>
                    } */}
                </div>
            </FuseAnimateGroup>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setClass: Actions.setClass,
        setStep: Actions.setStep,
        addStep: Actions.addStep,
        updateStep: Actions.updateStep,
        removeStep: Actions.removeStep,
    }, dispatch);
}

function mapStateToProps({ spReducers, academyApp }) {
    return {
        Co: spReducers.companies.Co,
        // academy: academyApp.academy.entities,
        // selectedAcademyIds: academyApp.academy.selectedAcademyIds,
        searchText: academyApp.academy.searchText,
        selectedClass: academyApp.academy.class,
        step: academyApp.academy.step,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademyList)));
