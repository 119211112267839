import * as Actions from '../actions/work-order-history.actions';
import _ from '@lodash';

const initialState = {
    data: null,
};

const workOrderHistoryReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_WORK_ORDER_HISTORY:
            {
                return {
                    data: action.payload
                };
            }
        default:
            {
                return state;
            }
    }
};

export default workOrderHistoryReducer;
