import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Avatar, AppBar, Badge, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { AttachFile, Dashboard } from '@material-ui/icons';
import axios from 'axios';
import classNames from 'classnames';
import * as GatherActions from 'main/content/apps/call-gather-keys/store/actions';
import AnalyticsDashboardApp from 'main/content/apps/dashboards/analytics/AnalyticsDashboardApp';
import FileList from 'main/content/apps/file-manager/FileList';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import VoicemailList from 'main/content/apps/voice-mailboxes/VoicemailList';
import VoiceMailboxHandlerList from './VoiceMailboxHandlerList';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import Recorder from 'recorder-js';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding !== null && props.padding !== undefined ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newVoiceMailboxState = {
    ID: '',
    Co: null,
    VoiceMailbox: null,
    Name: null,
    Description: null,
    VoicemailGreetingType: 'text',
    VoicemailGreeting: '',
    VoicemailFileID: null,
    Notes: '',
    ActiveYN: 'Y',
    value: 0,
    VocalPitch: 'medium',
    RateOfSpeech: 'medium',
    Volume: 'medium',
    hasRecordedAudio: false,
    isRecordingAudio: false,
    recordedAudioFileName: '',
    GreetingClip: '',
    Data: {
        ErrMsg: null,
        Messages: [],
        Handlers: [],
        AudioClips: [],
    },
    handler: {
        Co: null,
        VoiceMailbox: null,
        UserName: null,
        AccessLevel: null,
        AddedBy: null,
        AddedDate: null,
        Data: { ErrMsg: null }
    }

};



class VoiceMailboxesDialog extends Component {
    state = { ..._.cloneDeepWith(newVoiceMailboxState) };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newVoiceMailboxState.Co = this.props.Co;
        if (!prevProps.voiceMailboxDialog.props.open && this.props.voiceMailboxDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.voiceMailboxDialog.type === 'edit' &&
                this.props.voiceMailboxDialog.data &&
                !_.isEqual(this.props.voiceMailboxDialog.data, prevState)) {
                this.setState({ ..._.cloneDeepWith(newVoiceMailboxState), ...this.props.voiceMailboxDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.voiceMailboxDialog.type === 'new' &&
                !_.isEqual(newVoiceMailboxState, prevState)) {
                this.setState({ ..._.cloneDeepWith(newVoiceMailboxState) });
            }
        }
        if (prevProps.voiceMailboxDialog.props.open && this.props.voiceMailboxDialog.props.open) {
            if (this.props.voiceMailboxDialog.type === 'update' &&
                this.props.voiceMailboxDialog.data &&
                !_.isEqual(this.props.voiceMailboxDialog.data, prevState)) {
                this.props.showMessage({
                    message: `Voice Mailbox ${this.props.voiceMailboxDialog.data.VoiceMailbox} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.voiceMailboxDialog.type = 'edit';
                this.setState({ ...this.state, ...this.props.voiceMailboxDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.voiceMailboxDialog.type === 'edit' ? this.props.closeEditVoiceMailboxDialog() : this.props.closeNewVoiceMailboxDialog();
    };

    canBeSubmitted = () => {
        const { Name, Description } = this.state;
        return (
            Name && Name.length > 0 && Description && Description.length > 0
        );
    }

    parseSSML = () => {
        const Greeting = this.state.VoicemailGreeting;
        const ssml = Greeting;
        var tags = [];
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        for (var i = 0; i < nodes.length; i++) {
            var tag = {};
            var node = nodes[i];
            tag.Say = node.childNodes[0].nodeValue;
            tag.Attributes = '';
            for (var a = 0; a < node.attributes.length; a++) {
                tag[node.attributes[a].name] = node.attributes[a].value;
                tag.Attributes += ` ${node.attributes[a].name}=${node.attributes[a].value} `;
            }
            tags.push(tag);
        }
        window["warn"](tags, ssml, nodes, SSML);
        return tags;
    }

    buildSSML = (tags) => {
        let Greeting = '';
        for (var i = 0; i < tags.length; i++) {
            const tag = tags[i];
            Greeting += `<prosody pitch="${tag.pitch ? tag.pitch : 'medium'}" rate="${tag.rate ? tag.rate : 'medium'}" volume="${tag.volume ? tag.volume : 'medium'}">${tag.Say}</prosody>`;
        }
        return Greeting;
    }

    addClip = () => {
        let { GreetingClip, RateOfSpeech, VocalPitch, Volume } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: GreetingClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.push(tag);
        const newGreeting = this.buildSSML(tags);
        this.setState({ ...this.state, VoicemailGreeting: newGreeting, GreetingClip: '', });
    }

    saveClip = () => {
        let { GreetingClip, RateOfSpeech, VocalPitch, Volume, EditClip } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: GreetingClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.splice(EditClip, 1, tag);
        const newGreeting = this.buildSSML(tags);
        EditClip = null;
        this.setState({ ...this.state, VoicemailGreeting: newGreeting, GreetingClip: '', EditClip });
    }

    removeClip = (key) => {
        const tags = this.parseSSML();
        tags.splice(key, 1);
        const newGreeting = this.buildSSML(tags);
        this.setState({ ...this.state, VoicemailGreeting: newGreeting });
    }

    editClip = (key) => {
        const tags = this.parseSSML();
        const tag = tags[key];
        this.setState({ ...this.state, GreetingClip: tag.Say, RateOfSpeech: tag.rate ? tag.rate : 'medium', VocalPitch: tag.pitch ? tag.pitch : 'medium', Volume: tag.volume ? tag.volume : 'medium', EditClip: key })
    }

    clipFileSelectedHandler = (event) => {
        const { Data } = this.state;
        const fd = new FormData();
        const fn = event.target.files[0].name;
        const file = new Blob([event.target.files[0]], { type: event.target.files[0].type });
        fd.append('file', file, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipFileUploadProgress.innerHTML = percentCompleted === 100 ? 'Upload Clip' : `${percentCompleted}%`;
            }
        };
        axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                // this.addAudioClip(res.data.Attachment);
                this.setState({ ...this.state, VoicemailFileID: res.data.Attachment.Attachment });
            }
        });
    }

    addAudioClip = (att) => {
        const { Data } = this.state;
        let { AudioClips } = Data;
        AudioClips.push(att);
        this.setState({ ...this.state, Data: { ...Data, AudioClips } });
    }

    greetingAudioPaused = () => {
        this.setState({ ...this.state, greetingAudioPlaying: false });
    }

    greetingAudioPlaying = () => {
        this.setState({ ...this.state, greetingAudioPlaying: true });
    }

    startRecordingAudio = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            this.handleStream(stream);
        })
    }

    handleStream = (stream) => {

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const recorder = new Recorder(audioContext);
        recorder.init(stream);
        this.setState({ ...this.state, isRecordingAudio: recorder, audioStream: stream, hasRecordedAudio: false }, () => { recorder.start(); });
    }

    stopRecordingAudio = () => {
        const { isRecordingAudio, audioStream } = this.state;
        isRecordingAudio.stop().then(({ blob }) => {
            this.recordedAudioPlayback.src = URL.createObjectURL(blob);
            audioStream.getTracks()[0].stop();
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: blob, audioStream: null });
        });
    }

    deleteRecordedAudio = () => {
        this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false });
    }

    uploadRecordedAudio = () => {
        const { hasRecordedAudio, recordedAudioFileName } = this.state;
        const fd = new FormData();
        const fn = `${recordedAudioFileName}.wav`;
        fd.append('file', hasRecordedAudio, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipUploadProgress.innerHTML = percentCompleted === 100 ? '' : `${percentCompleted}%`;
            }
        };
        const request = axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            // if (res.data.Attachment) {
            //     this.addAudioClip(res.data.Attachment);
            // }
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false, MessageFileID: res.data.Attachment.Attachment });
        });
    }

    addHandler = () => {
        const { data, mailbox } = this.props;
        const { Co, VoiceMailbox } = this.state;
        const handler = {
            ...this.state.handler,
            Co,
            VoiceMailbox,
            Data: {
                ErrMsg: null
            }
        };
        const request = axios.post(`${window["apiLocation"]}/api/VoicemailHandler`, handler);
        request.then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg === "") {
                // data.push(response.data);
                const { Handlers } = _.cloneDeepWith(this.state.Data);
                Handlers.push(response.data);
                this.setState({ handler: _.cloneDeepWith(newVoiceMailboxState).handler, Data: { ...this.state.Data, Handlers } });
            }
        })
    }

    updateHandler = () => {
        const { data } = this.props;
        const { handler } = this.state;
        const { Co, VoiceMailbox, UserName } = handler;
        const request = axios.put(`${window["apiLocation"]}/api/VoicemailHandler?Co=${Co}&Mailbox=${VoiceMailbox}&User=${encodeURIComponent(UserName)}`, handler);
        request.then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg === "") {
                // data.push(response.data);
                const { Handlers } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Handlers, { UserName: handler.UserName });
                if (index > -1) {
                    Handlers.splice(index, 1, response.data);
                }
                this.setState({ handler: _.cloneDeepWith(newVoiceMailboxState).handler, Data: { ...this.state.Data, Handlers } });
            }
        })
    }

    removeHandler = () => {
        const { data } = this.props;
        const { handler } = this.state;
        const { Co, VoiceMailbox, UserName } = handler;
        const request = axios.delete(`${window["apiLocation"]}/api/VoicemailHandler?Co=${Co}&Mailbox=${VoiceMailbox}&User=${encodeURIComponent(UserName)}`);
        request.then((response) => {
            if (!response.data || response.data === "") {
                // data.push(response.data);
                const { Handlers } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Handlers, { UserName: handler.UserName });
                if (index > -1) {
                    Handlers.splice(index, 1);
                }
                this.setState({ handler: _.cloneDeepWith(newVoiceMailboxState).handler, Data: { ...this.state.Data, Handlers } });
            }
        })
    }

    render() {
        const { classes, Co, voiceMailboxDialog, addVoiceMailbox, updateVoiceMailbox, awaitingCallFrom, AudioClips, users, securables } = this.props
        const { Phone, value, EditClip } = this.state;
        const SSMLTags = this.parseSSML();
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "voicemail" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...voiceMailboxDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(voiceMailboxDialog.type) > -1 ? 'New Voice Mailbox' : `Voice Mailbox #${this.state.VoiceMailbox} Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>phone_callback</Icon>} label="Info" />
                                <Tab disableRipple disabled={this.props.voiceMailboxDialog.type === "new"} icon={<Badge badgeContent={this.state.Data && this.state.Data.Messages ? _.filter(this.state.Data.Messages, (o) => !o.HandledBy || o.Status === 0).length : 0} color="error"><Icon>voicemail</Icon></Badge>} label="Messages" />
                                <Tab disableRipple disabled={this.props.voiceMailboxDialog.type === "new"} icon={<Badge badgeContent={this.state.Data && this.state.Data.Handlers ? this.state.Data.Handlers.length : 0} color="error"><Icon>supervised_user_circle</Icon></Badge>} label="Handlers" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && <TabContainer padding={0}>
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <DialogContent classes={{ root: "p-24" }}>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">voicemail</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Voice Mailbox"
                                            id="voiceMailbox"
                                            name="VoiceMailbox"
                                            value={this.state.VoiceMailbox}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Name"
                                            id="name"
                                            name="Name"
                                            value={this.state.Name}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">chat_bubble_outline</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">record_voice_over</Icon>
                                        </div>

                                        {
                                            this.state.VoicemailGreetingType == 'voice' &&
                                            <div className="w-full sm:flex">
                                                <div className="w-full flex">
                                                    <div className="w-full">
                                                        <div className="sm:flex w-full">
                                                            <TextField
                                                                className={classNames("mb-12 w-full")}
                                                                label="Greeting Type"
                                                                id="voicemailGreetingType"
                                                                select
                                                                name="VoicemailGreetingType"
                                                                value={this.state.VoicemailGreetingType}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                // style={{ minWidth: 200 }}
                                                                placeholder="Greeting Type"
                                                            >
                                                                <MenuItem value="voice">
                                                                    Audio Recording
                                                                </MenuItem>
                                                                <MenuItem value="text">
                                                                    Text-to-Speech
                                                                </MenuItem>
                                                            </TextField>
                                                            <div className="hidden sm:block min-w-12 pt-20">
                                                            </div>
                                                            {(!this.state.isRecordingAudio && !this.state.hasRecordedAudio) ?
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classNames("mb-12 w-full text-12")}
                                                                    // style={{ minWidth: 100 }}
                                                                    onClick={this.startRecordingAudio}
                                                                >
                                                                    <Icon className="mr-4">mic_none</Icon> Record Clip
                                                                </Button>
                                                                :
                                                                !this.state.hasRecordedAudio ?
                                                                    <Button
                                                                        variant="contained"
                                                                        color="error"
                                                                        className={classNames("mb-12 w-full text-12")}
                                                                        // style={{ minWidth: 100 }}
                                                                        onClick={this.stopRecordingAudio}
                                                                    >
                                                                        <Icon className="mr-4">stop</Icon> Stop Recording
                                                                    </Button>
                                                                    :
                                                                    <div className="flex w-full">
                                                                        <Tooltip title="Upload Audio Clip">
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                className={classNames("mb-12 w-full text-12")}
                                                                                onClick={this.uploadRecordedAudio}
                                                                            >
                                                                                <Icon className="mr-4">cloud_upload</Icon> <span ref={clipUploadProgress => this.clipUploadProgress = clipUploadProgress}></span>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <div className="hidden sm:block min-w-12 pt-20">
                                                                        </div>
                                                                        <Tooltip title="Delete Audio Clip">
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className={classNames("mb-12 w-full text-12")}
                                                                                onClick={this.deleteRecordedAudio}
                                                                            >
                                                                                <Icon className="mr-4">delete</Icon>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            (this.state.isRecordingAudio || this.state.hasRecordedAudio) &&
                                                            <React.Fragment>
                                                                <TextField
                                                                    className={classNames("mb-12", this.state.isRecordingAudio && "hidden")}
                                                                    label="Clip Name"
                                                                    id="clipName"
                                                                    name="recordedAudioFileName"
                                                                    value={this.state.recordedAudioFileName}
                                                                    onChange={this.handleChange}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    disabled={Boolean(this.state.isRecordingAudio)}
                                                                />
                                                                <audio className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")} controls style={{ width: '100%' }} ref={(ref) => { this.recordedAudioPlayback = ref }} />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <div className="w-full sm:flex">
                                                    <div className="w-full">
                                                        <TextField
                                                            className={"mb-12"}
                                                            label="Greeting Audio"
                                                            id="greetingFileID"
                                                            select
                                                            name="VoicemailFileID"
                                                            value={this.state.VoicemailFileID}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            fullWidth
                                                            placeholder="Select Greeting Audio"
                                                            InputLabelProps={{ shrink: this.state.VoicemailFileID ? true : false }}
                                                            disabled={this.state.greetingAudioPlaying}
                                                        >
                                                            <MenuItem value={null}>
                                                                None
                                                            </MenuItem>
                                                            {(AudioClips && AudioClips.length > 0 ? AudioClips.map((value) => (
                                                                <MenuItem value={value.Attachment}>
                                                                    {value.FileName}
                                                                </MenuItem>
                                                            )) : '')}
                                                        </TextField>
                                                        {
                                                            this.state.VoicemailFileID &&
                                                            <audio className={classes.formControl} controls style={{ width: '100%' }} ref="greetingAudioPlay" src={`${window["apiLocation"]}/api/AudioFile/${this.state.VoicemailFileID}.wav`} onPause={this.greetingAudioPaused} onPlay={this.greetingAudioPlaying} />
                                                        }
                                                    </div>
                                                    <div className="hidden sm:block min-w-12 pt-20">
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classNames("mb-12 w-full text-12")}
                                                        onClick={() => this.clipFileInput.click()}
                                                    >
                                                        <Icon className="mr-4">cloud_upload</Icon> <span ref={clipFileUploadProgress => this.clipFileUploadProgress = clipFileUploadProgress}>Upload Clip</span>
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={this.clipFileSelectedHandler}
                                                        ref={clipFileInput => this.clipFileInput = clipFileInput}
                                                        accept=".mp3,.wav,.m4a"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.VoicemailGreetingType == 'text' &&
                                            <React.Fragment>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Greeting Type"
                                                    id="voicemailGreetingType"
                                                    select
                                                    name="VoicemailGreetingType"
                                                    value={this.state.VoicemailGreetingType}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Greeting Type"
                                                >
                                                    <MenuItem value="voice">
                                                        Audio Recording
                                                    </MenuItem>
                                                    <MenuItem value="text">
                                                        Text-to-Speech
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Rate"
                                                    id="rate"
                                                    select
                                                    name="RateOfSpeech"
                                                    value={this.state.RateOfSpeech}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Rate of Speech"
                                                >
                                                    <MenuItem value="x-slow">
                                                        X-Slow
                                                    </MenuItem>
                                                    <MenuItem value="slow">
                                                        Slow
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="fast">
                                                        Fast
                                                    </MenuItem>
                                                    <MenuItem value="x-fast">
                                                        X-Fast
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Pitch"
                                                    id="vocalPitch"
                                                    select
                                                    name="VocalPitch"
                                                    value={this.state.VocalPitch}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Vocal Pitch"
                                                >
                                                    <MenuItem value="x-low">
                                                        X-Low
                                                    </MenuItem>
                                                    <MenuItem value="low">
                                                        Low
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="high">
                                                        High
                                                    </MenuItem>
                                                    <MenuItem value="x-high">
                                                        X-High
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Volume"
                                                    id="volume"
                                                    select
                                                    name="Volume"
                                                    value={this.state.Volume}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Volume"
                                                >
                                                    <MenuItem value="x-soft">
                                                        X-Soft
                                                    </MenuItem>
                                                    <MenuItem value="soft">
                                                        Soft
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="loud">
                                                        Loud
                                                    </MenuItem>
                                                    <MenuItem value="x-loud">
                                                        X-Loud
                                                    </MenuItem>
                                                </TextField>
                                            </React.Fragment>
                                        }
                                    </div>
                                    {
                                        this.state.VoicemailGreetingType == 'text' &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Greeting"
                                                    id="Greeting"
                                                    name="GreetingClip"
                                                    value={this.state.GreetingClip}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    multiline
                                                    rows={3}
                                                    fullWidth
                                                />
                                                {(!EditClip && EditClip !== 0) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={"mb-12"}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.addClip}
                                                        disabled={this.state.GreetingClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">add_circle_outline</Icon> Add
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={"mb-12"}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.saveClip}
                                                        disabled={this.state.GreetingClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">save</Icon> Save
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.VoicemailGreetingType == 'text' && SSMLTags.length > 0 &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            </div>
                                            <div className="w-full max-w-full overflow-x-auto mb-12 mt-0">
                                                {
                                                    SSMLTags.map((value, key) =>
                                                        <Tooltip title={value.Attributes} placement="top">
                                                            <Chip key={key} className="mb-8 mr-4 truncate" color="primary" icon={<Icon>record_voice_over</Icon>} label={value.Say} onClick={() => { this.editClip(key); }} onDelete={() => { this.removeClip(key); }} />
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">note</Icon>
                                        </div>
                                        <TextField
                                            className={"mb-24 mt-12"}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>
                                </DialogContent>
                            </FuseAnimate>
                        </TabContainer>}
                        {value === 1 && voiceMailboxDialog.type == 'edit' &&
                            <TabContainer padding={8}>
                                <VoicemailList data={this.state.Data.Messages} />
                            </TabContainer>
                        }
                        {value === 2 && voiceMailboxDialog.type == 'edit' &&
                            <TabContainer>
                                {accessLevel && accessLevel.AccessLevel === "F" &&
                                    <React.Fragment>
                                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">person_add</Icon>{`${!this.state.handler.ID ? "Add" : "Update"} Handler`}</Typography>
                                        <div className="sm:flex">
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Handler"
                                                options={users}
                                                menuItemComponent={(value, index) => {
                                                    return value.ActiveYN === "Y" &&
                                                        <MenuItem key={index} value={value.UserName}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={value.UserName} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                                {`${value.FirstName} ${value.LastName}`}
                                                            </div>
                                                        </MenuItem>
                                                }}
                                                value={this.state.handler.UserName || ''}
                                                onSelect={(option) => this.setState({ ...this.state, handler: { ...this.state.handler, UserName: option.UserName } })}
                                                required
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={classNames(classes.formControl)}
                                                label="Access Level"
                                                id="accessLevel"
                                                select
                                                name="handler.AccessLevel"
                                                value={this.state.handler.AccessLevel || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                            >
                                                <MenuItem value="R">
                                                    Read-Only
                                                </MenuItem>
                                                <MenuItem value="E">
                                                    Read/Write
                                                </MenuItem>
                                                <MenuItem value="F">
                                                    Full Access
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            {!this.state.handler.ID ?
                                                <Button
                                                    className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.addHandler}
                                                    disabled={!this.state.handler.UserName || !this.state.handler.AccessLevel}
                                                >
                                                    Add
                                                </Button> :
                                                <div className="w-full flex">
                                                    <Button
                                                        className={classNames(classes.formControl, classes.updateMember, "w-full")}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.updateHandler}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        className={classNames(classes.formControl, classes.deleteMember)}
                                                        variant="contained"
                                                        onClick={this.removeHandler}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                <VoiceMailboxHandlerList data={this.state.Data.Handlers} mailbox={this.state} onSelectedHandler={(handler) => this.setState({ handler })} />
                            </TabContainer>
                        }
                        {value === 0 && accessLevel && accessLevel.AccessLevel === "F" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {['new', 'customer'].indexOf(voiceMailboxDialog.type) > -1 ? (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                var rec = this.state;
                                                addVoiceMailbox(rec);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Add
                                        </Button>
                                        <div className="text-right">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.ActiveYN === 'Y'}
                                                        name="ActiveYN"
                                                        onChange={this.handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Active?"
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                var rec = this.state;
                                                updateVoiceMailbox(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                                        </Button>
                                        <div className="text-center">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.ActiveYN === 'Y'}
                                                        name="ActiveYN"
                                                        onChange={this.handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Active?"
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
VoiceMailboxesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditVoiceMailboxDialog: Actions.closeEditVoiceMailboxDialog,
        closeNewVoiceMailboxDialog: Actions.closeNewVoiceMailboxDialog,
        addVoiceMailbox: Actions.addVoiceMailbox,
        updateVoiceMailbox: Actions.updateVoiceMailbox,
        // removeVoiceMailbox: Actions.removeVoiceMailbox,
        showMessage,
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp, spReducers }) {
    return {
        voiceMailboxDialog: voiceMailboxesApp.voiceMailboxes.voiceMailboxDialog,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        AudioClips: spReducers.audioFiles.audioClips,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(VoiceMailboxesDialog));
