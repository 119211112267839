import * as Actions from 'store/actions/spConnection';

const initialState = {
    data: [],
    status: {
        offline: true,
    },
    workOrders: [],
    schedules: [],
}

const offlineData = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_OFFLINE_DATA:
            {
                const { data } = action;
                return {
                    ...state,
                    data
                }
            }
        case Actions.SET_OFFLINE_STATUS:
            {
                const { offline } = action
                return {
                    ...state,
                    status: {
                        offline
                    },
                }
            }
        case Actions.SET_OFFLINE_WORK_ORDERS:
            {
                const { workOrders } = action;
                return {
                    ...state,
                    workOrders
                }
            }
        case Actions.SET_OFFLINE_SCHEDULES:
            {
                const { schedules } = action;
                return {
                    ...state,
                    schedules
                }
            }
    }

    return state;
};

export default offlineData;