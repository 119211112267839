import { FuseAnimate } from '@fuse'
import _ from '@lodash'
import {
  Button,
  Badge,
  Card,
  Chip,
  DialogActions,
  DialogContent,
  Drawer,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import {
  Assignment,
  Build,
  List as ListIcon,
  LocalShipping,
  Payment,
  Receipt,
} from '@material-ui/icons'
import axios from 'axios'
import classNames from 'classnames'
import EstimatesList from 'main/content/apps/estimates/EstimatesList'
import InvoicesList from 'main/content/apps/invoices/InvoicesList'
import PaymentsList from 'main/content/apps/invoices/PaymentsList'
import TripsList from 'main/content/apps/trips/TripsList'
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList'
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList'
import ContactList from './ContactList'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import { setMapSearch } from 'main/chatPanel/store/actions'
import * as Actions from './store/actions'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import PhoneInput from '../../components/inputs/PhoneInput'

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: 56,
    '& .ReactTable': {
      borderTop: '1px solid #f1f1f1',
    },
    '& h6': {
      fontSize: '1.8rem',
      padding: 8,
      marginBottom: '0px !important',
      '& .material-icons': {
        marginRight: '6px !important',
        fontSize: '2.4rem !important',
      },
    },
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 56,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.up('sm')]: {
      width: 56,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  greenText: {
    color: '#d1e751',
  },
})

const SiteMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class CustomerSitesTab extends Component {
  state = {
    ..._.cloneDeepWith(this.props.callHandlerData.data.Site),
    showHistory: false,
    histTab: 0,
    showEmailLogs: false,
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleBlur = event => {
    this.props.setCallHandlerData(this.state)
  }

  pacRef = React.createRef()

  componentDidMount() {
    var options = { types: ['address'] }

    const addressInputElement = this.pacRef.current
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete &&
      addressInputElement
    ) {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        addressInputElement,
        options,
      )

      this.autocomplete.setFields([
        'name',
        'formatted_address',
        'address_components',
        'geometry',
      ])

      this.autocomplete.addListener('place_changed', this.handleAddressSelect)
    }

    const Data = this.props.callHandlerData.data
    const CustomerData = Data.Customer
    let { Site } = Data

    const {
      Co,
      Customer,
      FirstName,
      LastName,
      Address1,
      Address2,
      City,
      State,
      Zip,
      Country,
      Latitude,
      Longitude,
      Phone,
      Email,
    } = CustomerData
    const siteExists =
      _.findIndex(CustomerData.Data.Sites, { Co, Address1, City, State, Zip }) >
      -1
    const Address = !siteExists
      ? Address1 && Zip && Address1.length > 3 && Zip.length >= 5
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
      : false

    if (Site.type == 'new') {
      Site.type = 'draft'
      Site = !siteExists
        ? {
            ...Site,
            Co,
            Customer,
            Address,
            Address1,
            Address2,
            City,
            State,
            Zip,
            Country,
            Latitude,
            Longitude,
          }
        : { ...Site, Co, Customer }
      this.setState({ ...Site }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }

    if (Site.type == 'saved') {
      Site.type = 'draft'
      this.setState({ ...Site }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const Data = this.props.callHandlerData.data
    const CustomerData = Data.Customer
    let { Site } = Data

    const {
      Co,
      Customer,
      Address1,
      Address2,
      City,
      State,
      Zip,
      Country,
      Latitude,
      Longitude,
    } = CustomerData
    const siteExists =
      _.findIndex(CustomerData.Data.Sites, { Co, Address1, City, State, Zip }) >
      -1
    const Address = !siteExists
      ? Address1 && Zip && Address1.length > 3 && Zip.length >= 5
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
      : false

    if (Site.type == 'new') {
      Site.type = 'draft'
      Site = !siteExists
        ? {
            ...Site,
            Co,
            Customer,
            Address,
            Address1,
            Address2,
            City,
            State,
            Zip,
            Country,
            Latitude,
            Longitude,
          }
        : { ...Site, Co, Customer }
      this.setState({ ...Site }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }

    if (Site.type == 'saved') {
      Site.type = 'draft'
      this.setState({ ...Site }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  canBeSubmitted() {
    const { Customer, Description, Address1, City, State, Zip, Data } =
      this.state
    return (
      Customer &&
      Address1 &&
      City &&
      State &&
      Zip &&
      Data &&
      Data.Contacts &&
      Data.Contacts.length > 0 &&
      Description &&
      Description.length > 0 &&
      Address1.length > 0 &&
      City.length > 0 &&
      State.length > 0 &&
      Zip.length > 0
    )
  }

  toggleStreetview(streetView) {
    this.setState({ streetView })
  }

  getAddress = () => {
    const oldAddress = this.state.Address
    const { Address1, Zip } = this.state
    const Address =
      Address1 && Zip && Address1.length > 3 && Zip.length >= 3
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
    if (!_.isEqual(oldAddress, Address)) {
      this.setState({ Address }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  handleScriptLoad = () => {}

  handleAddressSelect = () => {
    const componentForm = {
      street_number: 'short_name',
      route: 'short_name',
      locality: 'short_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
    }

    const place = this.autocomplete.getPlace()
    if (place && place.address_components) {
      const address = place.address_components
      const location = place.geometry ? place.geometry.location : ''
      let addressObject = {}
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]]
          addressObject[addressType] = val
        }
      }

      if (address) {
        const Data = this.props.callHandlerData.data
        const CustomerData = Data.Customer
        const { Description } = this.state
        const { Name, FirstName, LastName } = CustomerData
        this.setState(
          {
            Description: `${Name || `${FirstName} ${LastName}`} ${
              addressObject.street_number
            } ${addressObject.route}`.replace(/([^0-9a-zA-Z\s])/g, ''),
            Address1:
              `${addressObject.street_number} ${addressObject.route}`.replace(
                /([^0-9a-zA-Z\s])/g,
                '',
              ),
            City: addressObject.locality
              ? addressObject.locality
                  .normalize('NFD')
                  .replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            State: addressObject.administrative_area_level_1,
            Country: addressObject.country,
            Zip: addressObject.postal_code,
            Latitude: location && location.lat ? location.lat() : null,
            Longitude: location && location.lng ? location.lng() : null,
          },
          () => {
            this.props.setCallHandlerData({ ...this.state })
          },
        )
      }
    } else {
      this.pacRef.current.focus()
    }
  }

  checkLocationDetails = () => {
    const { Address, Address1, City, State, Zip } = this.state
    let { Tags } = this.state
    if (Address) {
      const req = axios.get(
        `${
          window['apiLocation']
        }/api/CustomerSiteInsights?Address=${encodeURIComponent(
          Address1,
        )}&City=${encodeURIComponent(City)}&State=${encodeURIComponent(
          State,
        )}&Zip=${encodeURIComponent(Zip)}`,
      )
      req.then(response => {
        window['log'](response.data)
        const insights = response.data
        if (insights && insights.properties) {
          var property = insights.properties[0]
          for (var s = 0; s < property.structures.length; s++) {
            const structure = property.structures[s]
            if (structure.is_primary) {
              if (structure.building_type) {
                Tags.push(`${structure.building_type}`)
              }
              if (structure.year_built) {
                Tags.push(`Built ${structure.year_built}`)
              }
              if (structure.total_size) {
                Tags.push(`${structure.total_size} Sq Ft`)
              }
              if (structure.stories_count) {
                Tags.push(
                  `${structure.stories_count} ${
                    structure.stories_count > 1 ? 'Stories' : 'Story'
                  }`,
                )
              }
              if (
                structure.air_conditioning_type &&
                structure.air_conditioning_type !== 'None'
              ) {
                Tags.push(`${structure.air_conditioning_type} Cooling`)
              }
              if (structure.heating_type && structure.heating_type !== 'None') {
                Tags.push(`${structure.heating_type} Heating`)
              }
              if (structure.roof_type) {
                Tags.push(`${structure.roof_type} Roof`)
              }
              if (structure.flooring_type) {
                Tags.push(`${structure.flooring_type} Flooring`)
              }
              if (structure.exterior_wall_type) {
                Tags.push(`${structure.exterior_wall_type} Exterior`)
              }
            }
          }
        }
        this.setState({ ...this.state, insights: response.data, Tags }, () => {
          this.props.setCallHandlerData({ ...this.state })
        })
      })
    }
  }

  deleteInsight(id) {
    let { insights } = this.state
    insights.properties[0].structures.forEach(structure => {
      if (structure.is_primary) {
        delete structure[id]
      }
    })
    this.setState({ ...this.state, insights })
  }

  submitSite = () => {
    return this.props.createCustomerSite(this.state)
  }

  handleSiteChange = event => {
    const { Site, Description } = this.state
    let { Sites } = _.cloneDeepWith(
      this.props.callHandlerData.data.Customer.Data,
    )
    if (Site !== event.target.value) {
      this.setState(
        {
          ...this.state,
          Site: event.target.value,
          Description: null,
          Address1: null,
          Address2: null,
          City: null,
          State: null,
          Zip: null,
          CrossStreets: null,
          Data: { ...this.state.Data, Tags: [], Contacts: [], Units: [] },
        },
        () => {
          if (Site && Description) {
            if (_.findIndex(Sites, { Site }) < 0) {
              Sites.push({ Site, Description })
            }
          }
          const site = _.find(Sites, { Site: this.state.Site })
          if (site) {
            this.props.getSite(site)
          }
        },
      )
    }
  }

  //handleTags = (Tags) => {
  //    window["log"](Tags);
  //    this.setState({ ...this.state, Tags: Tags }, () => {
  //        this.props.setCallHandlerData({ ...this.state });
  //    });
  //}

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  handleAddTag = tag => {
    let { Co } = this.state
    if (this.state.Data.Tags) {
      let { Tags } = this.state.Data
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState(
        { ...this.state, Data: { ...this.state.Data, Tags } },
        () => {
          this.props.setCallHandlerData({ ...this.state })
        },
      )
    } else {
      let Tags = []
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState(
        { ...this.state, Data: { ...this.state.Data, Tags } },
        () => {
          this.props.setCallHandlerData({ ...this.state })
        },
      )
    }
  }

  handleDeleteTag = tag => {
    let { Tags } = this.state.Data
    if (Tags == null) {
      Tags = []
    }
    for (var i = 0; i < Tags.length; i++) {
      if (Tags[i].ID == tag.ID) {
        if (isNaN(Tags[i].ID)) {
          Tags.splice(i, 1)
        } else {
          Tags[i].ActiveYN = 'N'
          Tags[i].Status = 'Update'
        }
      }
    }
    this.setState({ ...this.state, Data: { ...this.state.Data, Tags } }, () => {
      this.props.setCallHandlerData({ ...this.state })
    })
  }

  toggleHistory = () => {
    const { showHistory } = this.state
    this.setState({ ...this.state, showHistory: !showHistory })
  }

  render() {
    const {
      classes,
      securables,
      theme,
      openSite,
      googleMapsApiKey,
      createCustomerSite,
    } = this.props
    const {
      Co,
      Customer,
      Site,
      ID,
      Description,
      Contact,
      Phone,
      value,
      histTab,
      streetView,
      Address,
      insights,
      showHistory,
    } = this.state
    let { Sites } = this.props.callHandlerData.data.Customer.Data
    const histTabs = ['Work Order', 'Visit', 'Invoice', 'Payment', 'Estimate']
    const histIcons = [
      <Assignment className='text-24 ml-12 mr-12' />,
      <LocalShipping className='text-24 ml-12 mr-12' />,
      <Receipt className='text-24 ml-12 mr-12' />,
      <Payment className='text-24 ml-12 mr-12' />,
      <ListIcon className='text-24 ml-12 mr-12' />,
    ]
    const disabled = Boolean(this.state.Site)
    const accessLevel = _.find(securables, { Securable: 'call-handler' })

    window['warn'](
      'ch props data 222',
      this.props.callHandlerData.data,
      this.state,
    )

    return (
      <FuseAnimate animation='transition.slideUpIn' delay={300}>
        <DialogContent classes={{ root: 'p-12 sm:p-24' }}>
          <Typography
            variant='h6'
            style={{
              alignItems: 'center',
              marginBottom: 12,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: 'calc(100% - 136px)',
            }}
            className='w-full sm:flex'
          >
            <Icon color='primary' className='text-32 mr-8 align-middle'>
              location_on
            </Icon>
            {this.state.Site ? (
              <span>
                Service Site #{this.state.Site} Info
                <Tooltip placement='top' title='Edit Service Site'>
                  <Icon
                    className='text-20 mb-4 ml-8 align-middle'
                    onClick={() => {
                      openSite(this.state)
                    }}
                  >
                    open_in_new
                  </Icon>
                </Tooltip>
              </span>
            ) : (
              <span>Service Site Info</span>
            )}
            {this.state.Site && (
              <Button
                onClick={this.toggleHistory}
                color='primary'
                variant='contained'
                className={classNames(
                  classes.black,
                  'text-12 pin-r absolute mr-48 sm:mr-64 md:mr-24 capitalize mt-4 h-24',
                )}
              >
                <Icon className={classNames(classes.greenText, 'mr-4 text-16')}>
                  history
                </Icon>
                History
              </Button>
            )}
          </Typography>
          {showHistory && (
            <div className='w-full sm:flex'>
              <div className='hidden sm:block min-w-48 pt-20'>
                <Icon color='action'>history</Icon>
              </div>
              <Card classes={{ root: 'w-full mb-24' }}>
                <div className={classNames(classes.hist)}>
                  <Drawer
                    variant='permanent'
                    className={classNames(classes.drawer, {
                      [classes.drawerOpen]: this.state.open,
                      [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                      paper: classNames(classes.shelf, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                      }),
                    }}
                    open={this.state.open}
                  >
                    <List>
                      <ListItem
                        onClick={() => this.setState({ histTab: 0 })}
                        button
                        key='Work Orders'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Orders' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.WorkOrders || 0}
                              color='error'
                            >
                              <Assignment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 1 })}
                        button
                        key='Work Order Scopes'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Order Scopes' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Scopes || 0}
                              color='error'
                            >
                              <Build />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 2 })}
                        button
                        key='Visits'
                      >
                        <ListItemIcon>
                          <Tooltip title='Trips' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Trips || 0}
                              color='error'
                            >
                              <LocalShipping />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 3 })}
                        button
                        key='Invoices'
                      >
                        <ListItemIcon>
                          <Tooltip title='Invoices' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Invoices || 0}
                              color='error'
                            >
                              <Receipt />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 4 })}
                        button
                        key='Payments'
                      >
                        <ListItemIcon>
                          <Tooltip title='Payments' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Payments || 0}
                              color='error'
                            >
                              <Payment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 5 })}
                        button
                        key='Estimates'
                      >
                        <ListItemIcon>
                          <Tooltip title='Estimates' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Estimates || 0}
                              color='error'
                            >
                              <ListIcon />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ showEmailLogs: true })}
                        button
                        key='Emails'
                      >
                        <ListItemIcon>
                          <Tooltip title='Email Log' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={
                                this.state.Data && this.state.Data.EmailLogs
                                  ? this.state.Data.EmailLogs.length
                                  : 0
                              }
                              color='error'
                            >
                              <Icon>email</Icon>
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Drawer>
                  <EmailLogDialog
                    title={`Site #${this.state.Site} Email Log`}
                    type='SITE'
                    rec={this.state.ID}
                    open={this.state.showEmailLogs}
                    onClose={() => this.setState({ showEmailLogs: false })}
                  />
                  <div className={classes.content}>
                    <div className={classes.histTable}>
                      {histTab === 0 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrdersList
                            hist={{
                              PageCount: Math.ceil(this.state.WorkOrders / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.WorkOrders,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrders || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 1 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrderScopesList
                            hist={{
                              PageCount: Math.ceil(this.state.Scopes / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Scopes,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrderScopes || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 2 && (
                        <TabContainer padding={'0px'}>
                          <TripsList
                            hist={{
                              PageCount: Math.ceil(this.state.Trips / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Trips,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedTripData={[
                              ...(this.state.Data.Trips || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 3 && (
                        <TabContainer padding={'0px'}>
                          <InvoicesList
                            hist={{
                              PageCount: Math.ceil(this.state.Invoices / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Invoices,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            invoiceData={[...(this.state.Data.Invoices || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 4 && (
                        <TabContainer padding={'0px'}>
                          <PaymentsList
                            hist={{
                              PageCount: Math.ceil(this.state.Payments / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Payments,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            payments={[...(this.state.Data.Payments || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 5 && (
                        <TabContainer padding={'0px'}>
                          {/* <EstimateDialog /> */}
                          <EstimatesList
                            hist={{
                              PageCount: Math.ceil(this.state.Estimates / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Estimates,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            estimateData={[
                              ...(this.state.Data.Estimates || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                    </div>
                  </div>
                  <IconButton
                    className='pin-r absolute w-36 h-36 mt-4 mr-4'
                    onClick={this.toggleHistory}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </div>
              </Card>
            </div>
          )}
          {Customer && (Site || (Sites && Sites.length > 0)) ? (
            <div>
              <div className='w-full sm:flex'>
                <div className='hidden sm:block min-w-48 pt-20'>
                  <Icon color='action'>location_on</Icon>
                </div>
                {/* <Autocomplete
                                        className={classes.formControl}
                                        title="Service Site"
                                        options={Sites}
                                        menuItemComponent={(value) => {
                                            return <MenuItem value={value.Site}>
                                                {value.Site} - {value.Description}
                                            </MenuItem>
                                        }}
                                        portal={true}
                                        value={this.state.Site || ''}
                                        onSelect={(option) => this.setState({ ...this.state, Site: option.Site }, () => this.props.setCallHandlerData(this.state))}
                                        required
                                        activeOnly={true}
                                        error={this.state.trySubmit && !this.state.Site}
                                        // disabled={disabled}
                                    /> */}
                <TextField
                  className={classes.formControl}
                  label='Select Service Site'
                  id='site'
                  name='Site'
                  value={this.state.Site || ''}
                  onChange={this.handleSiteChange}
                  onBlur={this.handleBlur}
                  variant='outlined'
                  select
                  fullWidth
                >
                  <MenuItem value={null}>
                    <Icon className='mr-8'>add</Icon>New Service Site
                  </MenuItem>
                  {/* {(!ID && Customer && Site ?
                                            <MenuItem value={Site}>
                                                {Description}
                                            </MenuItem>
                                            : ''
                                        )} */}
                  {Sites && Sites.length > 0
                    ? Sites.map(value => (
                        <MenuItem value={value.Site}>
                          {`${value.Site} - ${value.Address1}${
                            value.Address2 && value.Address2.length > 0
                              ? ` ${value.Address2}`
                              : ''
                          }`}
                        </MenuItem>
                      ))
                    : ''}
                </TextField>
              </div>
              <div className='w-full sm:flex'>
                <div className='hidden sm:block min-w-48 pt-20'></div>
                <TextField
                  className={classes.formControl}
                  label='Description'
                  id='description'
                  name='Description'
                  value={this.state.Description || ''}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  variant='outlined'
                  disabled={disabled}
                  fullWidth
                />
              </div>
            </div>
          ) : (
            <div className='w-full sm:flex'>
              <div className='hidden sm:block min-w-48 pt-20'>
                <Icon color='action'>location_on</Icon>
              </div>
              <TextField
                className={classes.formControl}
                label='Description'
                id='description'
                name='Description'
                value={this.state.Description || ''}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                variant='outlined'
                disabled={disabled}
                fullWidth
              />
            </div>
          )}

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>home</Icon>
            </div>
            <TextField
              className={classes.formControl}
              label='Address'
              id='address1'
              name='Address1'
              value={this.state.Address1 || ''}
              inputRef={this.pacRef}
              onChange={this.handleChange}
              onBlur={this.getAddress}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'></div>
            <TextField
              className={classes.formControl}
              label="Address (cont'd)"
              id='address2'
              name='Address2'
              value={this.state.Address2 || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              variant='outlined'
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='City'
              id='city'
              name='City'
              value={this.state.City || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
            <div className='hidden sm:block min-w-12 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='State'
              id='state'
              name='State'
              value={this.state.State || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
            <div className='hidden sm:block min-w-12 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='Zip Code'
              id='zip'
              name='Zip'
              value={this.state.Zip || ''}
              onChange={this.handleChange}
              onBlur={this.getAddress}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>location_searching</Icon>
            </div>
            <TextField
              className={classes.formControl}
              label='Cross Streets'
              id='crossStreets'
              name='CrossStreets'
              value={this.state.CrossStreets || ''}
              onChange={this.handleChange}
              inputProps={{
                autoComplete: 'off',
              }}
              InputProps={{
                endAdornment:
                  this.state.Address1 && this.state.Zip ? (
                    <InputAdornment placement='end'>
                      <Tooltip placement='top' title='Find on Map'>
                        <Icon
                          onClick={() =>
                            this.props.setMapSearch({
                              Address: `${this.state.Address1} ${this.state.Zip}`,
                              Latitude: this.state.Latitude,
                              Longitude: this.state.Longitude,
                            })
                          }
                          className='cursor-pointer'
                          color='primary'
                        >
                          map
                        </Icon>
                      </Tooltip>
                    </InputAdornment>
                  ) : undefined,
              }}
              variant='outlined'
              disabled={disabled}
              fullWidth
            />
          </div>

          {/* <div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">phone</Icon>
                        </div>
                        {/* <TextField
                            className={classes.formControl}
                            label="Phone"
                            id="phone"
                            name="Phone"
                            value={this.state.Phone || ''}
                            onChange={this.handleChange}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            variant="outlined"
                            required
                            disabled={disabled}
                            fullWidth
                        /> */}
          {/* <PhoneInput
                            {...this.props}
                            className={classes.formControl}
                            label="Phone"
                            id="phone"
                            name="Phone"
                            value={this.state.Phone || ''}
                            onChange={(value) => {
                                this.setState({ ...this.state, Phone: value });
                            }}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            variant="outlined"
                            required={true}
                            disabled={disabled}
                            fullWidth={true}
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <TextField
                            className={classes.formControl}
                            label="Email"
                            id="email"
                            name="Email"
                            value={this.state.Email || ''}
                            onChange={this.handleChange}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            variant="outlined"
                            required
                            disabled={disabled}
                            fullWidth
                        /> */}
          {/* </div> */}

          <div className='w-full sm:flex mb-12' style={{ marginTop: -8 }}>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>contacts</Icon>
            </div>
            <ContactList
              label={'Contacts * '}
              onUpdate={Contacts =>
                this.setState(
                  { ...this.state, Data: { ...this.state.Data, Contacts } },
                  () => this.props.setCallHandlerData({ ...this.state }),
                )
              }
              editable={true}
              site={{ ...this.state }}
              details={this.state.Data.Contacts || []}
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>note</Icon>
            </div>
            <TextField
              className={classes.formControl}
              label='Notes'
              id='notes'
              name='Notes'
              value={this.state.Notes || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              variant='outlined'
              multiline
              rows={5}
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>assistant</Icon>
            </div>
            <ChipInput
              blurBehavior='add'
              className={classes.formControl}
              variant='outlined'
              label='Tags'
              id='tags'
              name='Tags'
              value={this.state.Data.Tags}
              onAdd={this.handleAddTag}
              onDelete={this.handleDeleteTag}
              chipRenderer={({ value, handleDelete }, key) =>
                value.ActiveYN == 'Y' &&
                (disabled ? (
                  <Tooltip
                    title={`Added By ${value.AddedBy} on ${new Date(
                      value.AddedDate,
                    ).toLocaleDateString('en-US')}`}
                    placement='top'
                  >
                    <Chip
                      className='mb-8 mr-4'
                      color='primary'
                      icon={<Icon>location_on</Icon>}
                      label={value.Title}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`Added By ${value.AddedBy} on ${new Date(
                      value.AddedDate,
                    ).toLocaleDateString('en-US')}`}
                    placement='top'
                  >
                    <Chip
                      className='mb-8 mr-4'
                      color='primary'
                      icon={<Icon>location_on</Icon>}
                      onDelete={handleDelete}
                      label={value.Title}
                    />
                  </Tooltip>
                ))
              }
              fullWidth
              fullWidthInput
              disabled={disabled}
            />
          </div>
          {/* {
                        Address && !insights && !disabled &&
                        <div className="w-full sm:flex">
                            <div className="hidden sm:block min-w-48 pt-20">
                            </div>
                            <Button color="secondary" className="mt-8 text-xs p-8 pr-24" variant="contained" onClick={this.checkLocationDetails}><Icon className="mr-8">search</Icon>Location Insights</Button>

                        </div>
                    } */}
          {accessLevel && accessLevel.AccessLevel !== 'R' && (
            <DialogActions className='dialog-actions justify-end m-0'>
              {
                Customer && !Site && (
                  <DebounceButton
                    buttonText={'Create Service Site'}
                    processingText={'Creating Service Site'}
                    isDisabled={!this.canBeSubmitted()}
                    variant={'contained'}
                    color={'primary'}
                    clickFxn={this.submitSite}
                    debounceInterval={3000}
                  />
                )
                // <Button
                //     variant="contained"
                //     color="primary"
                //     disabled={!this.canBeSubmitted()}
                //     onClick={this.submitSite}
                // >
                //     Create Service Site
                // </Button>
              }
            </DialogActions>
          )}
        </DialogContent>
      </FuseAnimate>
    )
  }
}
CustomerSitesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage: showMessage,
      setCallHandlerData: Actions.setSiteData,
      openSite: openEditCustomerSiteDialog,
      createCustomerSite: Actions.createCustomerSite,
      getSite: Actions.getSiteData,
      setMapSearch,
    },
    dispatch,
  )
}

function mapStateToProps({ callHandlerApp, customerSitesApp, spReducers }) {
  return {
    customerSiteDialog: customerSitesApp.customerSites.customerSiteDialog,
    googleMapsApiKey: googleMapsApiKey,
    callHandlerData: callHandlerApp.callHandler,
    user: callHandlerApp.user,
    Co: spReducers.companies.Co,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CustomerSitesTab),
)
