import _ from '@lodash'
import { batch } from 'react-redux'
import axios from 'axios/index'
import { getUserChats } from 'main/chatPanel/store/actions'
import {
  resetBoard,
  SET_WORK_ORDERS,
  TRIPS_LOADED,
} from 'main/content/apps/dispatch-board/store/actions/dispatch-board.actions'
import { CLEAR_CALL_HANDLER_DATA } from 'main/content/apps/call-handler/store/actions/call-handler.actions'
import {
  getAgentStatusData,
  getAbout,
  getNotificationData,
  getAgentStatusesData,
  getCalendarData,
  getAgentStatusListData,
  getAgreementTypeData,
  getToDoTagData,
  getSupportRequests,
  getArrivalWindowData,
  getBusinessUnitData,
  getCostTypeCategoryData,
  getCostTypeData,
  getDashboardSecurableData,
  getDepartmentData,
  getDivisionData,
  getEmployeeData,
  getFleetVehicleCategoryData,
  getFleetVehicleData,
  getFormData,
  getChecklistData,
  getFormOfPaymentData,
  getGLAccountData,
  getInitialDialogHistory,
  getInventoryLocationData,
  getInventoryManagerLocationData,
  getInventoryUserTransferData,
  getInventoryLocationGroupData,
  getLaborTypeData,
  getMaterialCategoryData,
  getMaterialData,
  getOptionsTypeData,
  getPayTermData,
  getQBDCompanyData,
  getQBOCompanyData,
  getRateTemplateData,
  getReportSecurableData,
  getServiceData,
  getServiceTypeData,
  getServiceUnitCategoryData,
  getServiceUnitTypeData,
  getTaxCodeData,
  getTechnicianData,
  getTechnicianSchedule,
  getTechnicianEstimates,
  getTextMessageData,
  getUOMData,
  getVendorData,
  getVPCompanyData,
  getWorkOrderUsageTypeData,
  setSettings,
  setUserPrivileges,
  showMessage,
  SUBSCRIBE_LIST,
  UNSUBSCRIBE_LIST,
} from 'store/actions'

export const SET_COMPANY = '[SPCONNECTION] SET COMPANY'
export const SET_COMPANY_DATA = '[SPCONNECTION] SET COMPANY DATA'
export const GET_COMPANY_DATA = '[SPCONNECTION] GET COMPANY DATA'
export const DO_NOTHING = '[SPCONNECTION] DO NOTHING'
export const SET_QBO_LINK = '[SPCONNECTION] SET QBO LINK'

export function setQBOLink(payload) {
  return dispatch => {
    dispatch({
      type: SET_QBO_LINK,
      payload,
    })
  }
}

export function getCompanyList(User) {
  const request = axios.get(`${window['apiLocation']}/api/CompanyUser`, {
    params: {
      User,
    },
  })
  return (dispatch, getState) => {
    request.then(response => {
      Promise.all([
        dispatch({
          type: GET_COMPANY_DATA,
          payload: response.data,
        }),
      ]).then(() => {
        if (response.data.length > 0) {
          const state = getState()
          const { Co, List } = state.spReducers.companies
          const selectedCo =
            Co && _.findIndex(response.data, { Co }) ? Co : response.data[0].Co
          batch(() => {
            dispatch(getAgentStatusListData())
            dispatch(getAgentStatusesData())
            dispatch(getCostTypeCategoryData())
            dispatch(getInitialDialogHistory())
            dispatch(getToDoTagData())
            dispatch(getAgentStatusData(User))
            dispatch(getUserChats(User))
            dispatch(getNotificationData())
            dispatch(getAbout())
            dispatch(getSupportRequests())
            dispatch({ type: SUBSCRIBE_LIST, List: `ToDoTags` })
            dispatch({ type: SUBSCRIBE_LIST, List: `Companies` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${User}_UserProfile` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${User}_CompanyUser` })
            dispatch({ type: SUBSCRIBE_LIST, List: `UserProfiles` })
            dispatch({ type: SUBSCRIBE_LIST, List: `UserRoles` })
            dispatch({ type: SUBSCRIBE_LIST, List: `AgentStatus` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${User}_AgentStatus` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${User}_ChatMessages` })
            dispatch({ type: SUBSCRIBE_LIST, List: `${User}_Notifications` })
            dispatch(setCompany(selectedCo))
          })
        }
      })
    })
  }
}

export function setCompany(Co, prevCo) {
  if (Co) {
    return (dispatch, getState) => {
      const state = getState()
      const companies = state.spReducers.companies.List
      const { User } = state.spReducers.userProfiles
      const { Roles } = User.Data
      const { current } = state.fuse.settings
      const co = _.find(companies, { Co })
      const userCo = _.find(User.Data.Companies, { Co })
      if (userCo && userCo.SelectedTheme) {
        dispatch(
          setSettings({
            ...current,
            theme: { ...current.theme, main: userCo.SelectedTheme },
          }),
        )
      }
      const date = new Date(state.dispatchBoardApp.dispatchBoard.date)
        .toLocaleDateString('en-US')
        .replace(/\//g, '-')
      if (userCo) {
        const { Technician } = userCo.Data.Technician || {}
        const { month } = state.spReducers.calendar
        dispatch(getCalendarData(Co, month, Technician))
      }
      if (userCo && userCo.Data.Technician) {
        const techDate = state.spReducers.technician.date
        // console.warn(userCo, techDate);
        dispatch(
          getTechnicianSchedule(userCo.Data.Technician, techDate, 'W', true),
        )
        dispatch(getTechnicianEstimates(userCo.Data.Technician))
        // dispatch(getTechnicianTimecard(userCo.Data.Technician, date));
      }
      if (co) {
        const integrationTypes = {
          'Viewpoint': 'V',
          'QuickbooksOnline': 'Q',
          'QuickbooksDesktop': 'D'
        };
        const { PaymentsToken } = co.Data;
        const { VPCompany, QBOCompany, IntegrationType } = co.Data.Co
        if (VPCompany && IntegrationType === integrationTypes.Viewpoint) {
          dispatch(getVPCompanyData(VPCompany));
        } else {
          if (QBOCompany) {
            if (IntegrationType === integrationTypes.QuickbooksOnline) {
              dispatch(getQBOCompanyData(QBOCompany));
            }
            if (IntegrationType === integrationTypes.QuickbooksDesktop) {
              dispatch(getQBDCompanyData(QBOCompany));
            }
          }
        }
        let fmTag = document.getElementById('fm-script')
        if (PaymentsToken) {
          if (!window['FattJs']) {
            fmTag = document.createElement('script')
            fmTag.id = 'fm-script'
            fmTag.src = 'static/scripts/fattmerchant.js'
            document.body.appendChild(fmTag)
          }
        } else {
          if (window['FattJs']) {
            delete window['FattJs']
          }
          if (fmTag) {
            document.body.removeChild(fmTag)
          }
        }
      }
      Promise.all([
        batch(() => {
          dispatch({
            type: SET_COMPANY,
            Co,
          })
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: CLEAR_CALL_HANDLER_DATA,
              }
              : { type: DO_NOTHING },
          )
          // dispatch({
          //     type: SAVE_CALL_HANDLER_DRAFT,
          //     data: {},
          // });
          dispatch(resetBoard())
          // dispatch({
          //     type: SET_TRIPS,
          //     payload: [],
          // });
          dispatch({
            type: SET_WORK_ORDERS,
            WorkOrders: [],
          })
          // dispatch({
          //     type: SET_TECHNICIANS,
          //     payload: [],
          // });
          dispatch({
            type: TRIPS_LOADED,
            loading: true,
          })
          // Unsubscribe
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Calls` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Classes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Timecards` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Forms` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Checklists` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_UOMs` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ClassCategories` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Technicians` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_TechnicianUATimes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Teams` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Employees` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Customers` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_CustomerSites` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_${date}_WorkOrders` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_${date}_Trips` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Services` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ServiceTypes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_OptionBoardOptionsTypes`,
              }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_BusinessUnits` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Agreements` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_AgreementTypes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Departments` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Divisions` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_RateTemplates` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_LaborTypes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_CostTypes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_FleetVehicleCategories`,
              }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_ServiceUnitCategories`,
              }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ServiceUnitTypes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_FleetVehicles` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_InventoryLocationGroups`,
              }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_InventoryLocations` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_MaterialCategories` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Materials` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Vendors` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_PurchaseOrders` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_TaxCodes` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_WorkOrderUsageTypes`,
              }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_FormOfPayments` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_PayTerms` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ArrivalWindows` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_PhoneNumbers` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_CallQueues` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_Gathers` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_TextGathers` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_${User.UserName}_TextMessages`,
              }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_GLAccounts` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_HuntGroups` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_AudioClips` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_HoldMusic` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ScrumBoardTags` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ScrumBoards` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? { type: UNSUBSCRIBE_LIST, List: `${prevCo}_ReportSecurables` }
              : { type: DO_NOTHING },
          )
          dispatch(
            prevCo && prevCo !== Co
              ? {
                type: UNSUBSCRIBE_LIST,
                List: `${prevCo}_DashboardSecurables`,
              }
              : { type: DO_NOTHING },
          )

          //Fetch essentials
          dispatch(getFormData(Co))
          dispatch(getChecklistData(Co))
          dispatch(getUOMData(Co))
          dispatch(getTechnicianData(Co))
          dispatch(getOptionsTypeData(Co))
          dispatch(getEmployeeData(Co))
          dispatch(getServiceData(Co))
          dispatch(getServiceTypeData(Co))
          dispatch(getBusinessUnitData(Co))
          dispatch(getAgreementTypeData(Co))
          dispatch(getDepartmentData(Co))
          dispatch(getDivisionData(Co))
          dispatch(getRateTemplateData(Co))
          dispatch(getLaborTypeData(Co))
          dispatch(getCostTypeData(Co))
          dispatch(getFleetVehicleCategoryData(Co))
          dispatch(getServiceUnitCategoryData(Co))
          dispatch(getServiceUnitTypeData(Co))
          dispatch(getFleetVehicleData(Co))
          dispatch(getInventoryLocationGroupData(Co))
          dispatch(getInventoryLocationData(Co))
          dispatch(getInventoryManagerLocationData(Co))
          dispatch(getInventoryUserTransferData(Co))
          dispatch(getMaterialCategoryData(Co))
          dispatch(getMaterialData(Co))
          dispatch(getVendorData(Co))
          dispatch(getTaxCodeData(Co))
          dispatch(getWorkOrderUsageTypeData(Co))
          dispatch(getFormOfPaymentData(Co))
          dispatch(getPayTermData(Co))
          dispatch(getArrivalWindowData(Co))
          dispatch(getTextMessageData(Co))
          dispatch(getGLAccountData(Co))
          dispatch(getReportSecurableData(Co))
          dispatch(getDashboardSecurableData(Co))

          //Subscribe essentials
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Forms` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Checklists` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_UOMs` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Technicians` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Employees` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Services` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ServiceTypes` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_BusinessUnits` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_AgreementTypes` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Departments` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Divisions` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_RateTemplates` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_LaborTypes` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_CostTypes` })
          dispatch({
            type: SUBSCRIBE_LIST,
            List: `${Co}_FleetVehicleCategories`,
          })
          dispatch({
            type: SUBSCRIBE_LIST,
            List: `${Co}_ServiceUnitCategories`,
          })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ServiceUnitTypes` })
          dispatch({
            type: SUBSCRIBE_LIST,
            List: `${Co}_OptionBoardOptionsTypes`,
          })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_FleetVehicles` })
          dispatch({
            type: SUBSCRIBE_LIST,
            List: `${Co}_InventoryLocationGroups`,
          })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_InventoryLocations` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_MaterialCategories` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Materials` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_Vendors` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_TaxCodes` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_WorkOrderUsageTypes` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_FormOfPayments` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_PayTerms` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ArrivalWindows` })
          dispatch({
            type: SUBSCRIBE_LIST,
            List: `${Co}_${User.UserName}_TextMessages`,
          })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_GLAccounts` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_ReportSecurables` })
          dispatch({ type: SUBSCRIBE_LIST, List: `${Co}_DashboardSecurables` })
        }),
      ])
        .then(() => {
          dispatch(setUserPrivileges(Co, User, Roles, true))
        })
        .catch(error => {
          dispatch(
            showMessage({
              message: `Error: ${error}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        })
    }
  }
}
export function getCompanyData(Co, User) {
  const request = axios.get(`${window['apiLocation']}/api/CompanyUser`, {
    params: {
      Co,
      User,
    },
  })

  return dispatch => {
    request.then(response => {
      dispatch({
        type: SET_COMPANY_DATA,
        payload: response.data,
      })
    })
  }
}

export function setCompanyData(Co) {
  return {
    type: SET_COMPANY_DATA,
    Co,
  }
}