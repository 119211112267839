import {combineReducers} from 'redux';
import supervisor from './supervisor.reducer';
import user from './user.reducer';

const supervisorAppReducers = combineReducers({
    supervisor,
    user
});

export default supervisorAppReducers;
