import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class FleetVehiclesList extends Component {

    state = {
        selectedFleetVehiclesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedFleetVehicleMenu = (event) => {
        this.setState({ selectedFleetVehiclesMenu: event.currentTarget });
    };

    closeSelectedFleetVehiclesMenu = () => {
        this.setState({ selectedFleetVehiclesMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, securables, fleetVehicleCategories, user, searchText, employees, costTypes, selectedFleetVehicleIds, selectAllFleetVehicles, deSelectAllFleetVehicles, technicians, toggleInSelectedFleetVehicles, openEditFleetVehicleDialog, removeFleetVehicles, removeFleetVehicle, toggleStarredFleetVehicle, setFleetVehiclesUnstarred, setFleetVehiclesStarred, openNewFleetVehicleDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedFleetVehicleData ? !this.props.fleetVehicleData ? [] : this.props.fleetVehicleData : this.props.selectedFleetVehicleData), searchText);
        const { selectedFleetVehiclesMenu } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "fleet-vehicles" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewFleetVehicleDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">local_shipping</Icon>Fleet Vehicles</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditFleetVehicleDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "FleetVehicle",
                                accessor: "FleetVehicle",
                                minWidth: 128,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Category",
                                id: "category",
                                minWidth: 256,
                                className: "justify-center",
                                accessor: d => `${fleetVehicleCategories && `${d.Category} - ${_.find(fleetVehicleCategories, { Category: d.Category }).Description}`}`
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 320,
                            },
                            {
                                Header: "License #",
                                accessor: "LicenseNum",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "VIN #",
                                accessor: "VIN",
                                className: "justify-center",
                                minWidth: 192,
                            },
                            {
                                Header: "Make",
                                accessor: "Manufacturer",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Model",
                                accessor: "Model",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Year",
                                accessor: "ModelYear",
                                className: "justify-center",
                                minWidth: 64,
                            },
                            {
                                Header: "Odometer",
                                accessor: "Odometer",
                                className: "justify-center",
                                minWidth: 128,
                            },
                            {
                                Header: "Odometer Date",
                                accessor: "OdometerDate",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    row.original.OdometerDate &&
                                    moment(moment(row.original.OdometerDate)).local().toDate().toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Operator",
                                accessor: "Operator",
                                minWidth: 256,
                                Cell: row => {
                                    return employees.map((value, index) => (
                                        value.Employee === row.original.Operator &&
                                        <React.Fragment key={index}>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Employee} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Fleet Vehicles found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFleetVehicles: Actions.getFleetVehicles,
        getUserData: Actions.getUserData,
        toggleInSelectedFleetVehicles: Actions.toggleInSelectedFleetVehicles,
        selectAllFleetVehicles: Actions.selectAllFleetVehicles,
        deSelectAllFleetVehicles: Actions.deSelectAllFleetVehicles,
        openEditFleetVehicleDialog: Actions.openEditFleetVehicleDialog,
        removeFleetVehicles: Actions.removeFleetVehicles,
        removeFleetVehicle: Actions.removeFleetVehicle,
        toggleStarredFleetVehicle: Actions.toggleStarredFleetVehicle,
        toggleStarredFleetVehicles: Actions.toggleStarredFleetVehicles,
        setFleetVehiclesStarred: Actions.setFleetVehiclesStarred,
        setFleetVehiclesUnstarred: Actions.setFleetVehiclesUnstarred,
        openNewFleetVehicleDialog: Actions.openNewFleetVehicleDialog
    }, dispatch);
}

function mapStateToProps({ fleetVehiclesApp, spReducers }) {
    return {
        fleetVehicleData: spReducers.fleetVehicles,
        searchText: fleetVehiclesApp.fleetVehicles.searchText,
        user: spReducers.userProfiles.User.UserName,
        fleetVehicleCategories: spReducers.fleetVehicleCategories,
        employees: spReducers.employees,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FleetVehiclesList)));
