import {combineReducers} from 'redux';
import purchaseOrders from './purchase-orders.reducer';
import user from './user.reducer';

const purchaseOrdersAppReducers = combineReducers({
    purchaseOrders,
    user
});

export default purchaseOrdersAppReducers;
