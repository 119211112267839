import * as Actions from 'store/actions/spConnection';

const glAccounts = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_GL_ACCOUNT_DATA:
            {
                return action.payload;
            }
        case Actions.SET_GL_ACCOUNT_DATA:
            {
                return action.payload;
            }
    }

    return state;
};

export default glAccounts;