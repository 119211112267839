import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedEmployeeIds: [],
    routeParams: {},
    employeeDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const employeesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_EMPLOYEES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        //case Actions.LOAD_WORK_ORDERS
        //    {
        //        return {
        //            ...state,
        //            workOrders: action.workOrders
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_EMPLOYEES:
            {

                const employeeId = action.employeeId;

                let selectedEmployeeIds = [...state.selectedEmployeeIds];

                if (selectedEmployeeIds.find(id => id === employeeId) !== undefined) {
                    selectedEmployeeIds = selectedEmployeeIds.filter(id => id !== employeeId);
                }
                else {
                    selectedEmployeeIds = [...selectedEmployeeIds, employeeId];
                }

                return {
                    ...state,
                    selectedEmployeeIds: selectedEmployeeIds
                };
            }
        case Actions.SELECT_ALL_EMPLOYEES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedEmployeeIds = arr.map(employee => employee.id);

                return {
                    ...state,
                    selectedEmployeeIds: selectedEmployeeIds
                };
            }
        case Actions.DESELECT_ALL_EMPLOYEES:
            {
                return {
                    ...state,
                    selectedEmployeeIds: []
                };
            }
        case Actions.OPEN_NEW_EMPLOYEE_DIALOG:
            {
                window["log"]('CUSTOMER DATA FOR NEW SITE: ', action.data, (action.data.Customer ? true : false));
                return {
                    ...state,
                    employeeDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_EMPLOYEE_DIALOG:
            {
                return {
                    ...state,
                    employeeDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_EMPLOYEE_DIALOG:
            {
                return {
                    ...state,
                    employeeDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.DATA_EDIT_EMPLOYEE_DIALOG:
            {
                return {
                    ...state,
                    employeeDialog: {
                        type: 'data',
                        props: {
                            ...state.employeeDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_EMPLOYEE_DIALOG:
            {
                return {
                    ...state,
                    employeeDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_EMPLOYEE_DIALOG:
            {
                return {
                    ...state,
                    employeeDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_EMPLOYEE_DIALOG:
            {
                return {
                    ...state,
                    employeeDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default employeesReducer;
