import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography, Fab, Tooltip, DialogContent } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { openEditGpsInventoryTransferDialog, openNewGpsInventoryTransferDialog } from '../gps-inventory-transfers/store/actions/gps-inventory-transfers.actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import moment from 'moment';
import _ from '@lodash';
import Media from 'react-media';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import { openEditGpsInventoryTransferListDialog, closeEditGpsInventoryTransferListDialog, openPreloadGpsInventoryTransferListDialog } from './store/actions/gps-transfer-list.actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    disabled: {
        color: 'lightgrey',
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },

});

class GpsInventoryTransfersListDialog extends Component {

    state = {
        Co: null,
        List: 1,
        Material: null,
        Description: '',
        Location: null,
        Page: 1,
        PageSize: 10,
        TotalCount: 0,
        PageCount: 1,
        Results: [],
        Type: null,
    };

    componentDidMount = () => {
        this.setState({...this.state, Co: this.props.Co})
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { gpsTransferListDialog } = this.props;
        if (!prevProps.gpsTransferListDialog.props.open && this.props.gpsTransferListDialog.props.open) {
            if(this.state.Co === null){
                this.setState({...this.state, Co: gpsTransferListDialog.data.Co})
            }

            if(!_.isEqual(prevProps.gpsTransferListDialog.data.Results, gpsTransferListDialog.data.Results)){
                this.setState({...this.state, ...gpsTransferListDialog.data})
            }
        }
        window["warn"]("gpsTransferListDialog", gpsTransferListDialog)
    }

    closeComposeDialog = () => {
        this.props.closeEditGpsInventoryTransferListDialog()
        //this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true, Materials: []})
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e);
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>close</Icon>
                </IconButton>
            </>
        )
    }

    ToolbarContents = () => {
        return (
            <Typography variant="subtitle1" color="inherit">
                GPS Transfer List
            </Typography>
        )
    }

    render() {
        const { classes, gpsTransferListDialog } = this.props;
        const data = gpsTransferListDialog.data.Results.length > 0 ? gpsTransferListDialog.data.Results : [];

        const dialogProps = {
            ...gpsTransferListDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "md",
            hideBackdrop: true,
            disableEnforceFocus: true
        }

        const draggableSelectImg = 'assets/images/draggable-selections/work-order-usage-select.png';

        let Material = gpsTransferListDialog.data ? gpsTransferListDialog.data.Material : null;
        let Description = gpsTransferListDialog.data ? gpsTransferListDialog.data.Description : '';
        let SourceLocation = gpsTransferListDialog.data ? gpsTransferListDialog.data.Location : '';

        let Type = gpsTransferListDialog.data ? gpsTransferListDialog.data.Type : null;
        let WorkOrder = gpsTransferListDialog.data ? gpsTransferListDialog.data.WorkOrder : null;
        let workOrder = gpsTransferListDialog.data ? gpsTransferListDialog.data.workOrder : null;
        let Scopes = gpsTransferListDialog.data ? gpsTransferListDialog.data.Scopes : null;

        window["warn"]("gpsTransferListDialog", gpsTransferListDialog, Material, Type)

        

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={null}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        header={"GPS Transfer List"}
                        type="GPS Transfer List"
                        picRef={draggableSelectImg}
                        icon="send"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openPreloadGpsInventoryTransferListDialog}
                        description={this.state.Description}
                        storeDialog={gpsTransferListDialog}
                        TypeState={this.state.Type}
                        //screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >
                <DialogContent>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={(e) => {
                                e.stopPropagation()
                                const { Co } = this.props;
                                let material = {
                                    ID: null,
                                    //Type: "I",
                                    Type: Type,
                                    Material: Material,
                                    Qty: 1,
                                    Description: Description,
                                };
                                this.props.openNewGpsInventoryTransferDialog({Co, Data:{Lines: []}, material, SourceLocation, Type, WorkOrder, workOrder, Scopes})
                            }
                        }
                    >
                        <Tooltip title="New Inventory Transfer" placement="left">
                            <Icon>add</Icon>
                        </Tooltip>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">send</Icon>GPS Inventory Transfer</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            //const assigned = assignments && rowInfo ? _.find(assignments, {Scope: rowInfo.original.Scope}) : null;
                            return {
                                //className: classNames("cursor-pointer", (assignments && !assigned) && classes.disabled),
                                className: classNames("cursor-pointer"),
                                onClick: (e, handleOriginal) => {
                                    e.stopPropagation();
                                    window["warn"]("yu7788", rowInfo, this.props.openEditGpsInventoryTransferDialog)
                                    if (rowInfo) {
                                        this.props.openEditGpsInventoryTransferDialog({ ...rowInfo.original});
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Inventory Transfer",
                                accessor: "Transfer",
                                className: "justify-center",
                            },
                            {
                                Header: "Source Location",
                                accessor: "SourceLocation",
                                className: "justify-center"
                            },
                            {
                                Header: "Status",
                                accessor: "Status",
                                minWidth: 100,
                                className: "justify-center",
                                Cell: row => {
                                    if(row.original.Status === 0){
                                        return "Pending"
                                    } else if(row.original.Status === 1){
                                        return "Submitted"
                                    } else if(row.original.Status === 2){
                                        return "Approved"
                                    } else if(row.original.Status === 3){
                                        return "Accepted"
                                    } else if(row.original.Status === 4){
                                        return "Cancelled"
                                    }
                                }
                            },
                            {
                                Header: "Requested Date",
                                accessor: "RequestedDate",
                                minWidth: 100,
                                className: "justify-center",
                                Cell: row => {
                                    let date = moment(row.original.RequestedDate)
                                    return date.format("MM/DD/YYYY")
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Inventory Transfers found"
                    />
                </DialogContent>
                </DraggableDialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditGpsInventoryTransferDialog, 
        openNewGpsInventoryTransferDialog,
        openEditGpsInventoryTransferListDialog, 
        closeEditGpsInventoryTransferListDialog,
        openPreloadGpsInventoryTransferListDialog,
    }, dispatch);
}

function mapStateToProps({ workOrderUsagesApp, spReducers, gpsInventoryTransferListApp }) {
    return {
        gpsTransferListDialog: gpsInventoryTransferListApp.gpsInventoryTransferList.gpsTransferListDialog,
        searchText: workOrderUsagesApp.workOrderUsages.searchText,
        user: spReducers.userProfiles.User.UserName,
        workOrderUsageTypes: spReducers.workOrderUsageTypes,
        technicians: spReducers.technicians,
        costTypes: spReducers.costTypes,
        Co: spReducers.companies.Co,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(GpsInventoryTransfersListDialog)));
