import * as Actions from 'store/actions/spConnection';

const initialState = {
    status: null,
    notifications: null,
    token: null,
    error: null
}
const pushNotifications = function (state = initialState, action) {
    switch (action.type) {
        case Actions.INIT_PUSH_NOTIFICATIONS:
            {
                return {
                    ...state,
                    status: action.payload
                }
            }
        case Actions.SET_PUSH_NOTIFICATION_TOKEN:
            {
                return {
                    ...state,
                    token: action.payload
                }
            }
        case Actions.SET_PUSH_NOTIFICATION_ERROR:
            {
                return {
                    ...state,
                    error: action.payload
                }
            }
        case Actions.CLEAR_PUSH_NOTIFICATIONS:
            {
                return {
                    ...state,
                    notifications: null
                }
            }
    }

    return state;
};

export default pushNotifications;