import {combineReducers} from 'redux';
import phoneNumbers from './phone-numbers.reducer';
import user from './user.reducer';

const phoneNumbersAppReducers = combineReducers({
    phoneNumbers,
    user
});

export default phoneNumbersAppReducers;
