import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },

});

class TextGatherPhrasesList extends Component {

    state = {
        selectedTextGatherPhrasesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    componentDidUpdate(prevProps, prevState) {
        window["warn"]('Call Queue Gathers List:', this.props);
    }

    render() {
        const { classes, securables, TextGatherPhrases, user, searchText, selectedTextGatherPhraseIds, selectAllTextGatherPhrases, deSelectAllTextGatherPhrases, toggleInSelectedTextGatherPhrases, openEditTextGatherPhrasesDialog, } = this.props;
        const data = this.getFilteredArray((!this.props.selectedTextGatherPhrasesData ? (!this.props.textGatherPhraseData ? [] : this.props.textGatherPhraseData) : this.props.selectedTextGatherPhrasesData), searchText);
        const accessLevel = _.find(securables, { Securable: "text-gathers" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={() => this.props.openNewTextGatherPhrasesDialog(this.props.gather)}
                        >
                            <Tooltip title="New Gather Phrase" placement="left">
                                <Icon>add</Icon>
                            </Tooltip>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">speaker_notes</Icon>Assigned Phrases</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTextGatherPhrasesDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Phrase",
                                accessor: "Phrase",
                                className: "font-bold justify-center",
                                width: 125
                            },
                            {
                                Header: "Action",
                                accessor: "Action",
                                className: "font-bold justify-center",
                                width: 175
                            },
                            {
                                Header: "Target",
                                className: "font-bold justify-center",
                                id: "actionTarget",
                                accessor: d => (d.Action === 'Enqueue' ? d.AssignedQueue : d.Action === 'Forward' ? d.AssignedForward : d.Action === 'Gather' ? d.AssignedGather : ''),
                                width: 175
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Text Gather Phrases found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTextGatherPhrases: Actions.getTextGatherPhrases,
        getUserData: Actions.getUserData,
        toggleInSelectedTextGatherPhrases: Actions.toggleInSelectedTextGatherPhrases,
        selectAllTextGatherPhrases: Actions.selectAllTextGatherPhrases,
        deSelectAllTextGatherPhrases: Actions.deSelectAllTextGatherPhrases,
        openNewTextGatherPhrasesDialog: Actions.openNewTextGatherPhrasesDialog,
        openEditTextGatherPhrasesDialog: Actions.openEditTextGatherPhrasesDialog,
        removeTextGatherPhrases: Actions.removeTextGatherPhrases,
        removeTextGatherPhrase: Actions.removeTextGatherPhrase,
        toggleStarredTextGatherPhrase: Actions.toggleStarredTextGatherPhrase,
        toggleStarredTextGatherPhrases: Actions.toggleStarredTextGatherPhrases,
        setTextGatherPhrasesStarred: Actions.setTextGatherPhrasesStarred,
        setTextGatherPhrasesUnstarred: Actions.setTextGatherPhrasesUnstarred
    }, dispatch);
}

function mapStateToProps({ textGatherPhrasesApp, spReducers }) {
    return {
        textGatherPhrases: textGatherPhrasesApp.TextGatherPhrases.entities,
        selectedTextGatherPhraseIds: textGatherPhrasesApp.TextGatherPhrases.selectedTextGatherPhraseIds,
        searchText: textGatherPhrasesApp.TextGatherPhrases.searchText,
        user: textGatherPhrasesApp.user,
        textGatherPhraseData: spReducers.textGatherPhrases,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TextGatherPhrasesList)));
