import {combineReducers} from 'redux';
import formOfPayments from './form-of-payments.reducer';
import user from './user.reducer';

const formOfPaymentsAppReducers = combineReducers({
    formOfPayments,
    user
});

export default formOfPaymentsAppReducers;
