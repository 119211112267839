import * as Actions from 'store/actions/spConnection';

const initialState = {
    date: new Date(),
    loading: false,
    data: {
        Teams: [],
        POLines: [],
        InvoiceLines: [],
        Labor: [],
        Estimates: [],
        InventoryTransactions: [],
        Trips: [],
        Timecards: []
    }
}

const supervisor = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SUPERVISOR_DATA:
            {
                return {
                    ...state,
                    data: action.payload,
                    start: action.start || state.start,
                    loading: false,
                };
            }
        case Actions.SET_SUPERVISOR_LOADING:
            {
                const { loading } = action;
                return {
                    ...state,
                    loading,
                };
            }
        case Actions.SET_SUPERVISOR_DATE:
            {
                return {
                    ...state,
                    date: action.date || state.date,
                };
            }
    }

    return state;
};

export default supervisor;