import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedServiceUnitIds: [],
    routeParams: {},
    serviceUnitPartDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            Co: null,
            Customer: null,
            Site: null,
            UnitID: null,
            Part: null,
            Qty: null,
            UOM: null,
            Description: null,
            PartType: null,
            Notes: null,
            AddedBy: null,
            AddedDate: null,
            Material: null,
            Data: {
                ErrMsg: null
            }
        }
    },
    serviceUnitDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            Customer: null,
            Site: null,
            UnitCat: null,
            UnitType: null,
            UnitID: null,
            ActiveYN: "Y",
            Name: null,
            Description: null,
            Manufacturer: null,
            Model: null,
            SerialNumber: null,
            MaterialWarranty: "N",
            MaterialWarrantyStart: null,
            MaterialWarrantyEnd: null,
            LaborWarranty: "N",
            LaborWarrantyStart: null,
            LaborWarrantyEnd: null,
            AttachmentID: null,
            EnteredBy: null,
            Notes: null,
            Link: null,
            ManufactureDate: null,
            Location: null,
            SubUnit: null,
            Variant: null,
            AddedBy: null,
            AddedDate: new Date(),
            UpdatedBy: null,
            UpdatedDate: new Date(),
            Data: {
                ErrMsg: null,
                Attachments: [],
                Avatar: null,
                Comments: [],
                Parts: [],
            }
        }
    }
};

const serviceUnitsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_SERVICE_UNITS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_SERVICE_UNITS:
            {

                const serviceUnitId = action.serviceUnitId;

                let selectedServiceUnitIds = [...state.selectedServiceUnitIds];

                if (selectedServiceUnitIds.find(id => id === serviceUnitId) !== undefined) {
                    selectedServiceUnitIds = selectedServiceUnitIds.filter(id => id !== serviceUnitId);
                }
                else {
                    selectedServiceUnitIds = [...selectedServiceUnitIds, serviceUnitId];
                }

                return {
                    ...state,
                    selectedServiceUnitIds: selectedServiceUnitIds
                };
            }
        case Actions.SELECT_ALL_SERVICE_UNITS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedServiceUnitIds = arr.map(serviceUnits => serviceUnits.id);

                return {
                    ...state,
                    selectedServiceUnitIds: selectedServiceUnitIds
                };
            }
        case Actions.DESELECT_ALL_SERVICE_UNITS:
            {
                return {
                    ...state,
                    selectedServiceUnitIds: []
                };
            }
        case Actions.OPEN_NEW_SERVICE_UNIT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    serviceUnitDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.serviceUnitDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_SERVICE_UNIT_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_SERVICE_UNIT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    serviceUnitDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.serviceUnitDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_SERVICE_UNIT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    serviceUnitDialog: {
                        ...state.serviceUnitDialog,
                        type: 'update',
                        data: {
                            ...initialState.serviceUnitDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_SERVICE_UNIT_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_NEW_SERVICE_UNIT_PART_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitPartDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null,
                    }
                };
            }
        case Actions.CLOSE_NEW_SERVICE_UNIT_PART_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitPartDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_SERVICE_UNIT_PART_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitPartDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_SERVICE_UNIT_PART_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitPartDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default serviceUnitsReducer;
