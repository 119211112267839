import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import moment from 'moment';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 21,
        borderRadius: 2,
        padding: '0 6px',
        fontSize: 11,
        backgroundColor: 'rgba(0,0,0,.08);',
        whiteSpace: 'nowrap'
    },
    color: {
        width: 8,
        height: 8,
        marginRight: 4,
        borderRadius: '50%'
    }
});

function MailChip({ classes, title, color, className, data }) {
    return (
        <Tooltip placement="top" title={<span className="capitalize">{data.Status || 'Status Unavailable'}{data.StatusDateTime ? ` as of ${moment.utc(data.StatusDateTime).local().format("h:mm A on M/D/YYYY")}` : ''}</span>}>
            <div className={classNames(classes.root, className)}>
                <div className={classes.color} style={{ backgroundColor: color }} />
                <div style={{ whiteSpace: 'nowrap' }}>{title}</div>
            </div>
        </Tooltip>
    );
}

export default withStyles(styles, { withTheme: true })(MailChip);
