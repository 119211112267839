import axios from 'axios';
import { getUserData } from 'main/content/apps/option-board-types/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const SET_SEARCH_TEXT = '[FLEET VEHICLE CATEGORIES APP] SET SEARCH TEXT';
export const OPEN_NEW_OPTION_BOARD_TYPE_DIALOG = '[FLEET VEHICLE CATEGORIES APP] OPEN NEW OPTION BOARD TYPE DIALOG';
export const CLOSE_NEW_OPTION_BOARD_TYPE_DIALOG = '[FLEET VEHICLE CATEGORIES APP] CLOSE NEW OPTION BOARD TYPE DIALOG';
export const OPEN_EDIT_OPTION_BOARD_TYPE_DIALOG = '[FLEET VEHICLE CATEGORIES APP] OPEN EDIT OPTION BOARD TYPE DIALOG';
export const CLOSE_EDIT_OPTION_BOARD_TYPE_DIALOG = '[FLEET VEHICLE CATEGORIES APP] CLOSE EDIT OPTION BOARD TYPE DIALOG';
export const ADD_OPTION_BOARD_TYPE = '[FLEET VEHICLE CATEGORIES APP] ADD OPTION BOARD TYPE';
export const UPDATE_OPTION_BOARD_TYPE = '[FLEET VEHICLE CATEGORIES APP] UPDATE OPTION BOARD TYPE';
export const REMOVE_OPTION_BOARD_TYPE = '[FLEET VEHICLE CATEGORIES APP] REMOVE OPTION BOARD TYPE';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}


export function openNewOptionBoardTypeDialog() {
    return {
        type: OPEN_NEW_OPTION_BOARD_TYPE_DIALOG
    }
}

export function closeNewOptionBoardTypeDialog() {
    return {
        type: CLOSE_NEW_OPTION_BOARD_TYPE_DIALOG
    }
}

export function openEditOptionBoardTypeDialog(data) {
    return {
        type: OPEN_EDIT_OPTION_BOARD_TYPE_DIALOG,
        data
    }
}

export function closeEditOptionBoardTypeDialog() {
    return {
        type: CLOSE_EDIT_OPTION_BOARD_TYPE_DIALOG
    }
}

export function addOptionBoardType(newOptionBoardType) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/OptionsType`,
            newOptionBoardType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_OPTION_BOARD_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Option Board Type #${response.data.OptionsType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateOptionBoardType(optionBoardType) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/OptionsType?Co=${encodeURIComponent(optionBoardType.Co)}&Type=${encodeURIComponent(optionBoardType.OptionsType)}`,
            optionBoardType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_OPTION_BOARD_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Option Board Type #${optionBoardType.OptionsType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeOptionBoardType(optionBoardTypeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().optionBoardTypesApp.optionBoardTypes;

        const request = axios.post('/api/optionBoardTypes-app/remove-optionBoardType', {
            optionBoardTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_OPTION_BOARD_TYPE
                })
            ])
        );
    };
}
