import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    Cards: [],
    Lists: [],
    Boards: []
};

const recycleBinReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_DELETED_BOARDS:
            {
                return {
                    ...state,
                    Boards: [...action.payload]
                };
            }
        case Actions.GET_DELETED_LISTS_CARDS:
            {
                return {
                    ...state,
                    ...action.payload
                };
            }
        default:
            return state;
    }
};

export default recycleBinReducer;
