
import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const SPRoundIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<path d="M12.01,1.14c6.01,0.01,10.89,4.89,10.89,10.89C22.89,18.04,18,22.91,12,22.9C5.99,22.89,1.12,18.03,1.12,12.02
			C1.13,6.01,6,1.13,12.01,1.14z M11.99,20.72c4.79,0.02,8.7-3.87,8.73-8.67c0.02-4.8-3.86-8.71-8.67-8.74
			c-4.81-0.03-8.72,3.87-8.74,8.7C3.28,16.8,7.17,20.7,11.99,20.72z"/>
		<path d="M16.72,15.43c-0.47,0.47-0.93,0.93-1.43,1.43c0.03,0.02,0.08,0.04,0.12,0.07c0.43,0.42,0.86,0.84,1.28,1.27
			c0.05,0.05,0.06,0.13,0.09,0.19c-0.06,0.02-0.13,0.07-0.19,0.07c-0.52,0-1.05-0.01-1.57-0.02c-0.06,0-0.13-0.04-0.17-0.08
			c-0.46-0.46-0.92-0.91-1.38-1.38c-0.05-0.05-0.08-0.13-0.08-0.2c-0.01-0.48-0.01-0.96-0.02-1.44c0-0.07-0.04-0.15-0.08-0.2
			c-1.46-1.47-2.92-2.93-4.39-4.39c-0.04-0.04-0.12-0.08-0.19-0.08c-0.47-0.01-0.95-0.01-1.43-0.01c-0.1,0-0.17-0.03-0.24-0.1
			c-0.44-0.44-0.87-0.88-1.32-1.32C5.65,9.17,5.6,9.07,5.6,8.95c0-0.49-0.01-0.98-0.01-1.47c0-0.07,0.05-0.13,0.08-0.2
			c0.07,0.03,0.14,0.05,0.19,0.1C6.29,7.8,6.71,8.22,7.13,8.65c0.04,0.04,0.06,0.09,0.08,0.12c0.49-0.49,0.95-0.95,1.42-1.42
			C8.61,7.32,8.58,7.29,8.54,7.25C8.11,6.82,7.68,6.4,7.25,5.97C7.21,5.92,7.17,5.84,7.18,5.78c0-0.03,0.11-0.08,0.16-0.08
			c0.53,0,1.07,0,1.6,0.01C9,5.72,9.06,5.75,9.1,5.79c0.47,0.46,0.93,0.92,1.39,1.39c0.04,0.04,0.07,0.13,0.07,0.19
			c0.01,0.48,0,0.97,0.01,1.45c0,0.06,0.03,0.14,0.08,0.19c1.46,1.47,2.93,2.94,4.4,4.4c0.04,0.04,0.12,0.07,0.19,0.08
			c0.48,0.01,0.96,0,1.44,0.01c0.08,0,0.17,0.04,0.23,0.09c0.46,0.45,0.91,0.9,1.36,1.36c0.05,0.05,0.1,0.14,0.1,0.21
			c0.01,0.53,0.01,1.06,0.01,1.58c0,0.05-0.04,0.14-0.08,0.15c-0.05,0.01-0.13-0.02-0.17-0.07c-0.45-0.44-0.89-0.89-1.33-1.34
			C16.76,15.47,16.74,15.45,16.72,15.43z"/>
		<path d="M11.15,8.82c0-0.07-0.01-0.12-0.01-0.17c0-0.47,0-0.93,0-1.4c0-0.07-0.04-0.16-0.09-0.21c-0.11-0.14-0.3-0.24-0.35-0.4
			c-0.04-0.15,0.06-0.33,0.11-0.5c0.08-0.34,0.17-0.68,0.25-1.01c0.02-0.1,0.06-0.15,0.18-0.16c0.53-0.06,1.05-0.06,1.58,0
			c0.11,0.01,0.14,0.05,0.17,0.15c0.13,0.54,0.27,1.08,0.4,1.62c0.02,0.09,0.06,0.13,0.15,0.17c0.37,0.14,0.72,0.3,1.09,0.44
			c0.05,0.02,0.13,0.02,0.18-0.01c0.49-0.29,0.98-0.58,1.46-0.87c0.08-0.05,0.14-0.05,0.21,0.01c0.41,0.33,0.78,0.7,1.11,1.11
			c0.07,0.09,0.06,0.14,0.01,0.23c-0.29,0.48-0.58,0.96-0.87,1.44c-0.04,0.07-0.04,0.12-0.01,0.2c0.15,0.35,0.3,0.7,0.44,1.06
			c0.04,0.11,0.09,0.15,0.19,0.18c0.54,0.13,1.07,0.27,1.61,0.4c0.1,0.02,0.15,0.07,0.15,0.17c0,0.53,0,1.05,0,1.58
			c0,0.1-0.04,0.14-0.14,0.17c-0.48,0.11-0.95,0.23-1.42,0.35c-0.1,0.02-0.17,0.02-0.23-0.06c-0.22-0.32-0.52-0.42-0.91-0.38
			c-0.33,0.03-0.66,0.01-0.99,0.01c-0.05,0-0.1,0-0.17,0c0.24-1.23-0.06-2.29-0.94-3.17C13.42,8.87,12.37,8.58,11.15,8.82z"/>
		<path d="M12.83,16.23c0,0.23-0.01,0.46,0,0.68c0,0.07,0.04,0.16,0.09,0.22c0.11,0.14,0.3,0.25,0.34,0.4
			c0.04,0.15-0.06,0.33-0.1,0.5c-0.08,0.34-0.17,0.68-0.24,1.02c-0.02,0.1-0.06,0.13-0.16,0.14c-0.53,0.06-1.06,0.05-1.59-0.01
			c-0.09-0.01-0.12-0.04-0.14-0.13c-0.13-0.53-0.27-1.06-0.4-1.59c-0.03-0.13-0.09-0.19-0.21-0.23c-0.34-0.12-0.67-0.27-1-0.42
			c-0.09-0.04-0.15-0.05-0.24,0c-0.46,0.28-0.93,0.55-1.4,0.83c-0.11,0.06-0.17,0.06-0.26-0.02c-0.4-0.33-0.76-0.69-1.08-1.09
			c-0.06-0.08-0.07-0.13-0.01-0.22c0.28-0.47,0.56-0.94,0.84-1.41c0.05-0.09,0.05-0.15,0.01-0.25c-0.16-0.34-0.3-0.69-0.43-1.04
			c-0.03-0.09-0.07-0.13-0.16-0.15c-0.54-0.13-1.07-0.27-1.61-0.41c-0.11-0.03-0.15-0.08-0.16-0.19c-0.06-0.52-0.06-1.04,0-1.56
			c0.01-0.09,0.04-0.13,0.12-0.15c0.48-0.11,0.96-0.23,1.44-0.35c0.08-0.02,0.15-0.02,0.2,0.06c0.22,0.33,0.53,0.41,0.91,0.38
			c0.33-0.03,0.67,0,1-0.01c0.12,0,0.15,0.04,0.13,0.15c-0.41,2.07,1.22,3.73,2.77,3.99c0.39,0.06,0.78,0.07,1.17,0
			c0.19-0.03,0.19-0.03,0.19,0.17C12.83,15.77,12.83,16,12.83,16.23z"/>
		</SvgIcon>
	);
};

export default SPRoundIcon;