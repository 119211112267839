import PhoneNumbersApp from 'main/content/apps/phone-numbers/PhoneNumbersApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const PhoneNumbersAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/phone-numbers/:id',
            component: PhoneNumbersApp
        },
        {
            path     : '/apps/phone-numbers',
            component: () => <Redirect to="/apps/phone-numbers/all"/>
        }
    ]
};
