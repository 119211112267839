import { FuseUtils } from '@fuse'
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Icon,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import RGL, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { connect } from 'react-redux'
import 'react-resizable/css/styles.css'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import ChecklistSection from './ChecklistSection'
import SignatureCanvas from 'react-signature-canvas'
import * as Actions from './store/actions'

const GridLayout = WidthProvider(RGL)

const styles = theme => ({
  mailList: {
    padding: 0,
  },
  checklistControl: {
    marginBottom: 12,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  labels: {},
  checklistSection: {
    border: '1px dotted lightgrey',
    // padding: 8,
  },
  scrollEnable: {
    touchAction: 'unset !important',
  },
  fullWidth: {
    width: '100% !important',
  },
  updateComponent: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteComponent: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
})

const initialComponentState = {
  ID: null,
  Co: null,
  Checklist: null,
  Section: null,
  Item: null,
  Type: null,
  Name: null,
  Description: null,
  EnableNotesYN: 'Y',
  DisplaySeq: null,
  Notes: null,
  Data: {
    ErrMsg: null,
    Items: [],
  },
  AttachmentEnabledYN: null,
  AttachmentRequiredYN: null,
  RequiredYN: null,
  inputOptions: {
    redText: null,
    yellowText: null,
    greenText: null,
  },
}

class ChecklistBuilder extends Component {
  state = {
    grabbing: false,
    needsUpdate: [],
    selectedComponent: _.cloneDeepWith(initialComponentState),
    selectedComponentType: 'S',
    isTechnicianSigning: false,
    TechnicianSignature: null,
    isCustomerSigning: false,
    CustomerSignature: null,
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedChecklistSectionMenu = event => {
    this.setState({ selectedChecklistSectionsMenu: event.currentTarget })
  }

  closeSelectedChecklistSectionsMenu = () => {
    this.setState({ selectedChecklistSectionsMenu: null })
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  setGrabbing = grabbing => {
    this.setState({ ...this.state, grabbing })
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  onDragEnd = result => {
    const { source, destination } = result
    console.warn(result)

    if (!destination) {
      return
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (result.type === 'section') {
      const { checklist, onNeedsUpdate } = this.props

      const data = this.reorder(
        checklist.Data.Sections,
        result.source.index,
        result.destination.index,
      )

      const headers = _.filter(data, { HeaderYN: 'Y' })
      const content = _.filter(data, o => {
        return o.HeaderYN !== 'Y' && o.FooterYN !== 'Y'
      })
      const footers = _.filter(data, { FooterYN: 'Y' })

      const sections = [...headers, ...content, ...footers]

      const needsUpdate = []

      window['warn'](sections)

      sections.map((section, index) => {
        var old = _.find(checklist.Data.Sections, { Section: section.Section })
        if (old && old.DisplaySeq !== index) {
          needsUpdate.push({ ...section, DisplaySeq: index })
        }
      })

      checklist.Data.Sections = [...sections]
      this.setState({ ...this.state, sections, needsUpdate }, () =>
        onNeedsUpdate(needsUpdate),
      )
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  setSelectedComponent = (type, component) => {
    this.setState({
      ...this.state,
      selectedComponentType: type,
      setSelectedComponent: component,
    })
  }

  sigTechnicianPad = {}

  clearTechnician = () => {
    this.sigTechnicianPad.clear()
  }

  trimTechnician = () => {
    this.setState({
      ...this.state,
      TechnicianSignature: this.sigTechnicianPad
        .getCanvas()
        .toDataURL('image/png'),
      isTechnicianSigning: false,
    })
  }

  sigCustomerPad = {}

  clearCustomer = () => {
    this.sigCustomerPad.clear()
  }

  trimCustomer = () => {
    this.setState({
      ...this.state,
      CustomerSignature: this.sigCustomerPad.getCanvas().toDataURL('image/png'),
      isCustomerSigning: false,
    })
  }

  render() {
    const {
      classes,
      checklist,
      scrollLock,
      securables,
      openNewChecklistSectionDialog,
      openEditChecklistSectionDialog,
    } = this.props
    const { selectedComponent, selectedComponentType } = this.state
    const { Co, Checklist } = checklist
    console.warn(Co, Checklist, checklist)
    const { grabbing, sections } = this.state
    const data = [...(sections || [...checklist.Data.Sections])]

    const avatar = 'assets/images/avatars/profile.jpg'
    const accessLevel = _.find(securables, { Securable: 'checklists' })
    const types = [
      {
        Type: 'CB',
        Description: 'Checkbox',
        Rows: 3,
        Enabled: true,
      },
      {
        Type: 'RYG',
        Description: 'Red | Yellow | Green',
        Rows: 4,
        Enabled: true,
      },
      {
        Type: 'RTG',
        Description: 'Rating',
        Rows: 4,
        Enabled: false,
      },
    ]

    return (
      <div className='w-full pb-64'>
        <Typography
          variant='h6'
          style={{ alignItems: 'center' }}
          className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
        >
          <Icon color='primary' className='text-24 mr-12'>
            {selectedComponent.ID ? 'edit' : 'add_circle_outline'}
          </Icon>
          {selectedComponent.ID ? 'Update ' : `Add `}
          {selectedComponentType === 'S' ? 'Section' : 'Item'}
        </Typography>
        <div className='w-full'>
          <div className='sm:flex'>
            <TextField
              className={classNames(classes.checklistControl)}
              label='Type'
              id='componentType'
              name='selectedComponentType'
              value={this.state.selectedComponentType || ''}
              onChange={this.handleChange}
              variant='outlined'
              fullWidth
              disabled={selectedComponent && selectedComponent.ID}
              select
            >
              <MenuItem value='S'>Section</MenuItem>
              <MenuItem value='I'>Item</MenuItem>
            </TextField>
            {selectedComponentType === 'I' && (
              <React.Fragment>
                <div className='hidden sm:block min-w-8 pt-20'></div>
                <TextField
                  className={classes.checklistControl}
                  label='Section'
                  id='section'
                  name='selectedComponent.Section'
                  value={this.state.selectedComponent.Section || ''}
                  onChange={this.handleChange}
                  variant='outlined'
                  fullWidth
                  select
                >
                  {data.map(value => {
                    return (
                      <MenuItem value={value.Section}>
                        {`${value.Section} - ${value.Name}`}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </React.Fragment>
            )}
            {selectedComponentType === 'I' && (
              <React.Fragment>
                <div className='hidden sm:block min-w-8 pt-20'></div>
                <TextField
                  className={classes.checklistControl}
                  label='Component'
                  id='type'
                  name='selectedComponent.Type'
                  value={this.state.selectedComponent.Type || ''}
                  onChange={this.handleChange}
                  variant='outlined'
                  fullWidth
                  select
                >
                  {_.filter(types, { Enabled: true }).map(value => {
                    return (
                      <MenuItem value={value.Type}>
                        {value.Description}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </React.Fragment>
            )}
            <div className='hidden sm:block min-w-8 pt-20'></div>
            <TextField
              className={classes.checklistControl}
              label='Title'
              id='name'
              name='selectedComponent.Name'
              value={this.state.selectedComponent.Name || ''}
              onChange={this.handleChange}
              variant='outlined'
              fullWidth
            />
            {/* <div className="hidden sm:block min-w-8 pt-20">
                        </div> */}
          </div>
          {selectedComponent && selectedComponentType === 'I' && (
            <div className='w-full sm:flex'>
              {selectedComponent.Type === 'RYG' && (
                <React.Fragment>
                  <TextField
                    className={classNames(classes.checklistControl)}
                    label='Red Option Text'
                    id='redText'
                    name='selectedComponent.inputOptions.redText'
                    value={
                      this.state.selectedComponent.inputOptions.redText || ''
                    }
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-8 pt-20'></div>
                  <TextField
                    className={classNames(classes.checklistControl)}
                    label='Yellow Option Text'
                    id='yellowText'
                    name='selectedComponent.inputOptions.yellowText'
                    value={
                      this.state.selectedComponent.inputOptions.yellowText || ''
                    }
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-8 pt-20'></div>
                  <TextField
                    className={classNames(classes.checklistControl)}
                    label='Green Option Text'
                    id='greenText'
                    name='selectedComponent.inputOptions.greenText'
                    value={
                      this.state.selectedComponent.inputOptions.greenText || ''
                    }
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-8 pt-20'></div>
                </React.Fragment>
              )}
              {/* <TextField
                                className={classes.checklistControl}
                                label="Required?"
                                id="required"
                                name="selectedComponent.RequiredYN"
                                value={this.state.selectedComponent.RequiredYN || ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                select
                            >
                                <MenuItem value="N">
                                    No
                                </MenuItem>
                                <MenuItem value="Y">
                                    Yes
                                </MenuItem>
                            </TextField> */}
            </div>
          )}
          {selectedComponent && selectedComponentType === 'I' && (
            <div
              className='w-full sm:flex justify-between'
              style={{ marginTop: -12 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedComponent.AttachmentEnabledYN === 'Y'}
                    onChange={() =>
                      this.setState({
                        ...this.state,
                        selectedComponent: {
                          ...selectedComponent,
                          AttachmentEnabledYN:
                            selectedComponent.AttachmentEnabledYN === 'Y'
                              ? 'N'
                              : 'Y',
                        },
                      })
                    }
                  />
                }
                label='Enable Attachment'
              />
              {selectedComponent.AttachmentEnabledYN === 'Y' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedComponent.AttachmentRequiredYN === 'Y'}
                      onChange={() =>
                        this.setState({
                          ...this.state,
                          selectedComponent: {
                            ...selectedComponent,
                            AttachmentRequiredYN:
                              selectedComponent.AttachmentRequiredYN === 'Y'
                                ? 'N'
                                : 'Y',
                          },
                        })
                      }
                    />
                  }
                  label='Attachment Required'
                />
              )}
            </div>
          )}
          <div className='w-full flex'>
            {!selectedComponent.ID ? (
              <Button
                className={classNames(classes.checklistControl, 'w-full')}
                variant='contained'
                color='primary'
                onClick={() => {
                  if (selectedComponentType === 'S') {
                    this.props.addChecklistSection({
                      ...selectedComponent,
                      Co,
                      Checklist,
                      Data: { ...selectedComponent.Data, ErrMsg: null },
                    })
                  }
                  if (selectedComponentType === 'I') {
                    this.props.addChecklistItem({
                      ...selectedComponent,
                      Co,
                      Checklist,
                      Data: { ...selectedComponent.Data, ErrMsg: null },
                      InputOptions: JSON.stringify(
                        selectedComponent.inputOptions,
                      ),
                      inputOptions: null,
                    })
                  }
                  this.setState({
                    ...this.state,
                    selectedComponent: {
                      ...selectedComponent,
                      Name: null,
                      Description: null,
                    },
                  })
                }}
                // disabled={!this.canComponentBeSubmitted()}
              >
                Add
              </Button>
            ) : (
              <div className='w-full flex'>
                <Button
                  className={classNames(
                    classes.checklistControl,
                    classes.updateComponent,
                    'w-full',
                  )}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    if (selectedComponentType === 'S') {
                      this.props.updateChecklistSection({
                        ...selectedComponent,
                        Co,
                        Checklist,
                        Data: { ...selectedComponent.Data, ErrMsg: null },
                      })
                    }
                    if (selectedComponentType === 'I') {
                      this.props.updateChecklistItem({
                        ...selectedComponent,
                        Co,
                        Checklist,
                        Data: { ...selectedComponent.Data, ErrMsg: null },
                        InputOptions: JSON.stringify(
                          selectedComponent.inputOptions,
                        ),
                        inputOptions: null,
                      })
                    }
                    this.setState({
                      ...this.state,
                      selectedComponent: _.cloneDeepWith(initialComponentState),
                    })
                  }}
                  // disabled={!this.canComponentBeSubmitted()}
                >
                  Update
                </Button>
                <Button
                  className={classNames(
                    classes.checklistControl,
                    classes.deleteComponent,
                  )}
                  variant='contained'
                  onClick={() => {
                    if (selectedComponentType === 'S') {
                      this.props.removeChecklistSection({
                        ...selectedComponent,
                        Co,
                        Checklist,
                        Data: { ...selectedComponent.Data, ErrMsg: null },
                      })
                    }
                    if (selectedComponentType === 'I') {
                      this.props.removeChecklistItem({
                        ...selectedComponent,
                        Co,
                        Checklist,
                        Data: { ...selectedComponent.Data, ErrMsg: null },
                      })
                    }
                    this.setState({
                      ...this.state,
                      selectedComponent: _.cloneDeepWith(initialComponentState),
                    })
                  }}
                  // disabled={!this.canComponentBeSubmitted()}
                >
                  <Icon>delete</Icon>
                </Button>
              </div>
            )}
          </div>
        </div>
        <Typography
          variant='h6'
          style={{ alignItems: 'center' }}
          className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12 mt-8'
        >
          <Icon color='primary' className='text-24 mr-12'>
            assignment_turned_in
          </Icon>
          Checklist Preview
        </Typography>

        <Typography
          variant='h6'
          style={{ alignItems: 'center' }}
          className='text-20 my-12 font-bold w-full text-center'
        >
          {checklist.Name}
        </Typography>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId='section' type='section' direction='vertical'>
            {provided => (
              <div ref={provided.innerRef} className='w-full'>
                {data &&
                  data.map((section, index) => (
                    <Draggable
                      isDragDisabled={scrollLock}
                      draggableId={section.Section}
                      key={index}
                      index={index}
                      type='section'
                    >
                      {(provided, snapshot) => (
                        <div
                          className={classNames(
                            'w-full',
                            !scrollLock && classes.checklistSection,
                            scrollLock && classes.scrollEnable,
                          )}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              onMouseDown={e => this.setGrabbing(true)}
                              onMouseUp={e => this.setGrabbing(false)}
                              className={`w-full min-h-24 px-12 relative${
                                grabbing ? ' cursor-grabbing' : ' cursor-grab'
                              }`}
                            >
                              <ChecklistSection
                                builder={true}
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    selectedComponent: section,
                                    selectedComponentType: 'S',
                                  })
                                }
                                onItemClick={el =>
                                  this.setState({
                                    ..._.cloneDeepWith(initialComponentState),
                                    ...this.state,
                                    selectedComponent: {
                                      ...el,
                                      inputOptions: el.InputOptions
                                        ? JSON.parse(el.InputOptions)
                                        : _.cloneDeepWith(initialComponentState)
                                            .inputOptions,
                                    },
                                    selectedComponentType: 'I',
                                  })
                                }
                                scrollLock={scrollLock}
                                section={section}
                                editable={false}
                                data={[...section.Data.Items]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className='w-full px-12 sm:flex'>
          <div className='w-full'>
            <div className='w-full'>
              <strong style={{ marginRight: 4 }}>Technician Signature:</strong>
            </div>
            <div
              className='w-full relative mb-12'
              onClick={() => {
                this.setState({ ...this.state, isTechnicianSigning: true })
              }}
              style={{
                border: '1px dotted #3f3f3f',
                width: '100%',
                height: 128,
                minHeight: 128,
                borderRadius: 5,
              }}
            >
              {this.state.TechnicianSignature && (
                <img
                  className='pin-t pin-b pin-l pin-r m-auto absolute'
                  style={{ width: 'auto', height: '100%' }}
                  src={this.state.TechnicianSignature}
                />
              )}
            </div>
            <Dialog
              open={this.state.isTechnicianSigning}
              classes={{ paper: classes.signaturePaper }}
              fullWidth
              maxWidth='sm'
              onClose={() => {
                if (this.sigTechnicianPad.isEmpty()) {
                  this.setState({
                    ...this.state,
                    isTechnicianSigning: false,
                  })
                }
              }}
            >
              <div className='w-full mb-12'>
                <strong style={{ marginRight: 4 }}>
                  Technician Signature:
                </strong>
              </div>
              <div
                className='w-full'
                style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
              >
                <SignatureCanvas
                  ref={ref => {
                    this.sigTechnicianPad = ref
                  }}
                  penColor='#66aef9'
                  canvasProps={{
                    className: 'sigCanvas',
                    style: { width: '100%', height: 150 },
                  }}
                />
              </div>
              <div className='flex w-full mt-16'>
                <Button
                  variant='contained'
                  className={classes.clearBtn}
                  fullWidth
                  onClick={this.clearTechnician}
                >
                  Clear
                </Button>
                <div className='min-w-12'></div>
                <Button
                  variant='contained'
                  className={classes.okBtn}
                  fullWidth
                  onClick={this.trimTechnician}
                >
                  OK
                </Button>
              </div>
            </Dialog>
          </div>
          <div className='sm:w-24 sm:h-12'></div>
          <div className='w-full'>
            <div className='w-full'>
              <strong style={{ marginRight: 4 }}>Customer Signature:</strong>
            </div>
            <div
              className='w-full relative mb-12'
              onClick={() => {
                this.setState({ ...this.state, isCustomerSigning: true })
              }}
              style={{
                border: '1px dotted #3f3f3f',
                width: '100%',
                height: 128,
                minHeight: 128,
                borderRadius: 5,
              }}
            >
              {this.state.CustomerSignature && (
                <img
                  className='pin-t pin-b pin-l pin-r m-auto absolute'
                  style={{ width: 'auto', height: '100%' }}
                  src={this.state.CustomerSignature}
                />
              )}
            </div>
            <Dialog
              open={this.state.isCustomerSigning}
              classes={{ paper: classes.signaturePaper }}
              fullWidth
              maxWidth='sm'
              onClose={() => {
                if (this.sigCustomerPad.isEmpty()) {
                  this.setState({
                    ...this.state,
                    isCustomerSigning: false,
                  })
                }
              }}
            >
              <div className='w-full mb-12'>
                <strong style={{ marginRight: 4 }}>Customer Signature:</strong>
              </div>
              <div
                className='w-full'
                style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
              >
                <SignatureCanvas
                  ref={ref => {
                    this.sigCustomerPad = ref
                  }}
                  penColor='#66aef9'
                  canvasProps={{
                    className: 'sigCanvas',
                    style: { width: '100%', height: 150 },
                  }}
                />
              </div>
              <div className='flex w-full mt-16'>
                <Button
                  variant='contained'
                  className={classes.clearBtn}
                  fullWidth
                  onClick={this.clearCustomer}
                >
                  Clear
                </Button>
                <div className='min-w-12'></div>
                <Button
                  variant='contained'
                  className={classes.okBtn}
                  fullWidth
                  onClick={this.trimCustomer}
                >
                  OK
                </Button>
              </div>
            </Dialog>
          </div>
        </div>
        {checklist.Disclaimer && (
          <div className='sm:flex px-12'>
            <div className='w-full mb-24'>
              <div
                className='mb-12 rounded-6 p-8'
                style={{ background: '#f1f1f1' }}
              >
                {checklist.Disclaimer && (
                  <div>
                    <div className='font-bold text-red text-10 text-center w-full'>
                      * {checklist.Disclaimer} *
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
      addChecklistSection: Actions.addChecklistSection,
      updateChecklistSection: Actions.updateChecklistSection,
      removeChecklistSection: Actions.removeChecklistSection,
      addChecklistItem: Actions.addChecklistItem,
      updateChecklistItem: Actions.updateChecklistItem,
      removeChecklistItem: Actions.removeChecklistItem,
    },
    dispatch,
  )
}

function mapStateToProps({ checklistsApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    checklistData: spReducers.checklists,
    searchText: checklistsApp.checklists.searchText,
    user: spReducers.userProfiles.User.UserName,
    employees: spReducers.employees,
    technicians: spReducers.technicians,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistBuilder)),
)
