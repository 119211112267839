import axios from 'axios/index';
import { setselectedContactId } from './contacts.actions';
import { setConversation } from './user.actions';
import { closeMobileChatsSidebar } from 'main/content/apps/chat/store/actions/sidebars.actions';
import _ from '@lodash';

export const GET_CHAT = '[CHAT PANEL] GET CHAT';
export const REMOVE_CHAT = '[CHAT PANEL] REMOVE CHAT';
export const SEND_MESSAGE = '[CHAT PANEL] SEND MESSAGE';

export function getChat(contactId) {
    return (dispatch, getState) => {

        const state = getState();

        const { User } = state.spReducers.userProfiles;

        let chat = state.chatPanel.user.conversations.find((_chat) => _chat.UserName === contactId);
        let last = 0;
        if (chat) {
            last = _.max(chat.Messages.map((o) => o.ID));
        }

        const request = axios.get(`${window["apiLocation"]}/api/Chat?Contact=${encodeURIComponent(contactId)}&Last=${last}`);
        request.then((response) => {
            if (response.data && response.data.Messages) {
                if (chat) {
                    chat.Messages = [...chat.Messages, ...response.data.Messages]
                } else {
                    chat = response.data;
                }
        
                dispatch(setselectedContactId(contactId));
        
                dispatch(closeMobileChatsSidebar());
        
                if (User && chat) {
                    return Promise.all([
                        dispatch(viewChat(chat, User)),
                        dispatch({
                            type: GET_CHAT,
                            chat,
                        })]);
                } else {
                    return dispatch({
                        type: GET_CHAT,
                        chat,
                    });
                }
            }
        }).catch((err) => {
    
            dispatch(setselectedContactId(contactId));
    
            dispatch(closeMobileChatsSidebar());
    
            if (User && chat) {
                return Promise.all([
                    dispatch(viewChat(chat, User)),
                    dispatch({
                        type: GET_CHAT,
                        chat,
                    })]);
            } else {
                return dispatch({
                    type: GET_CHAT,
                    chat,
                });
            }
        })
    }
}

export function viewChat(chat, user) {
    const members = {
        To: user.UserName,
        From: chat.UserName,
    }
    return (dispatch, getState) => {
        if (chat && user) {
            const request = axios.put(`${window["apiLocation"]}/api/Chat`, members);
            const state = getState();
            request.then((response) => {
                chat.messages.map((message) => {
                    message.Received = new Date();
                });
                Promise.all([,
                    dispatch(setConversation(chat)),
                    dispatch({
                        type: GET_CHAT,
                        chat,
                    }),
                ])
            }).catch((e) => {
                dispatch({
                    type: GET_CHAT,
                    chat,
                });
            });
        } else {
            dispatch({
                type: GET_CHAT,
                chat,
            });
        }
    }

}

export function removeChat() {
    return {
        type: REMOVE_CHAT
    };
}

export function sendMessage(message) {
    const request = axios.post(`${window["apiLocation"]}/api/Chat`, message);

    return (dispatch, getState) =>
        request.then((response) => {
            let chat = getState().chatPanel.user.conversations.find((_chat) => _chat.UserName === message.To);
            if (!chat) {
                chat = {
                    UserName: message.To,
                    Messages: []
                };
            };

            return dispatch(setConversation(chat));
        });
}