import _ from '@lodash';
import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Switch, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { DatePicker } from "@material-ui/pickers";
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import TaxCodeMemberList from './TaxCodeMemberList';
import * as Actions from './store/actions';
import axios from 'axios';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newTaxCodeState = {
    Co: null,
    TaxCode: null,
    Description: null,
    OldRate: 0,
    NewRate: 0,
    EffectiveDate: null,
    MultiLevelYN: 'N',
    GLAccount: null,
    Detail: null,
    ActiveYN: 'Y',
    Notes: null,
    Data: {
        ErrMsg: null,
        Members: [],
    },
    VPTaxGroup: null,
    VPTaxCode: null,
    QBOCompany: null,
    QBOTaxRate: null,
    JurisdictionType: null,
    RateID: null,
    isSearching: false,
    searchDescription: null,
    vpTaxCodes: [],
};

class TaxCodeDialog extends Component {
    state = { ...newTaxCodeState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { vpCompany } = this.props;
        /**
         * After Dialog Open
         */
        const initialState = _.cloneDeepWith(newTaxCodeState);
        initialState.Co = this.props.Co;
        if (!prevProps.taxCodeDialog.props.open && this.props.taxCodeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.taxCodeDialog.type === 'edit' &&
                this.props.taxCodeDialog.data &&
                !_.isEqual(this.props.taxCodeDialog.data, prevState)) {
                this.setState({ ...initialState, ...this.props.taxCodeDialog.data }, () => {
                    if (vpCompany && this.state.VPTaxCode) {
                        this.handleTaxCodeSearch();
                    }
                });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.taxCodeDialog.type === 'new' &&
                !_.isEqual(initialState, prevState)) {
                this.setState({ ...initialState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.taxCodeDialog.type === 'edit' ? this.props.closeEditTaxCodeDialog() : this.props.closeNewTaxCodeDialog();
    };

    canBeSubmitted() {
        const { TaxCode, Description, MultiLevelYN, OldRate, NewRate, EffectiveDate, Data } = this.state;
        if (MultiLevelYN !== "Y") {
            return (
                TaxCode && TaxCode.length > 0 && Description && Description.length > 0 && !isNaN(OldRate) && !isNaN(NewRate) && Boolean(EffectiveDate)
            );
        } else {
            return (
                TaxCode && TaxCode.length > 0 && Description && Description.length > 0 && _.filter(Data.Members, (o) => o.DeleteYN !== 'Y').length > 0
            );
        }
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    handleEffectiveDateChange = (event) => {
        let { EffectiveDate } = this.state;
        if (event) {
            EffectiveDate = event.toDate()
        } else {
            EffectiveDate = null;
        }
        this.setState({ ...this.state, EffectiveDate });
    }

    handleTaxCodeSearch = _.debounce(() => {
        const { vpCompany } = this.props;
        const { TaxGroup } = (vpCompany || {});
        const { searchDescription, VPTaxCode } = this.state;
        if (VPTaxCode || (searchDescription && searchDescription.length > 0)) {
            const inputValue = (searchDescription || VPTaxCode).trim().toLowerCase();
            this.setState({ isSearching: true }, () => {
                axios.get(`${window["apiLocation"]}/api/VPSettings/TaxCodes?Group=${TaxGroup}&search=${encodeURIComponent(inputValue)}`).then((response) => {
                    this.setState({ vpTaxCodes: response.data, isSearching: false });
                }).catch(() => this.setState({ isSearching: false, vpTaxCodes: [] }));
            })
        } else {
            this.setState({ vpTaxCodes: [] });
        }
    }, 750);

    render() {
        const { classes, taxCodeDialog, addTaxCode, updateTaxCode, removeTaxCode, glAccounts, vpCompany, qboCompany, qboTaxCodes } = this.props;
        const { searchDescription, isSearching, vpTaxCodes } = this.state;
        const members = _.sumBy(this.state.Data.Members, (m) => new Date(m.Data.TaxCode.EffectiveDate) <= new Date() ? m.Data.TaxCode.NewRate : m.Data.TaxCode.OldRate);
        window["warn"]('Members: ', this.state, members);

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...taxCodeDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {taxCodeDialog.type === 'new' ? 'New Tax Code' : this.state.RateID ? 'Managed Tax Code' : 'Edit Tax Code'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Tax Code"

                                    id="taxCode"
                                    name="TaxCode"
                                    value={this.state.TaxCode || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(taxCodeDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Type"
                                    id="multiLevelYN"
                                    name="MultiLevelYN"
                                    onChange={this.handleChange}
                                    value={this.state.MultiLevelYN || "N"}
                                    variant="outlined"
                                    fullWidth
                                    disabled={Boolean(this.state.RateID)}
                                    select
                                >
                                    <MenuItem value="N">
                                        Single Rate
                                    </MenuItem>
                                    <MenuItem value="Y">
                                        Combined Rate
                                    </MenuItem>
                                </TextField>
                            </div>
                            {this.state.MultiLevelYN === "Y" &&
                                <div className="flex" >
                                    <TextField
                                        className={classes.formControl}
                                        label="Combined Rate"
                                        id="combinedRate"
                                        name="CombinedRate"
                                        value={members}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        disabled={true}
                                        fullWidth
                                    />
                                </div>
                            }
                            {this.state.MultiLevelYN === "Y" &&
                                <TaxCodeMemberList members={this.state.Data.Members} co={this.state.Co} taxCode={this.state.TaxCode} onChange={(Members) => { this.setState({ ...this.state, Data: { ...this.state.Data, Members } }) }} edit={!this.state.RateID} />
                            }
                            {
                                this.state.MultiLevelYN !== "Y" &&
                                <React.Fragment>
                                    <div className="flex">
                                        <TextField
                                            className={classes.formControl}
                                            label="Old Rate"
                                            id="oldRate"
                                            name="OldRate"
                                            value={this.state.OldRate || ''}
                                            onChange={this.handleChange}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            disabled={Boolean(this.state.RateID)}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="flex">
                                        <TextField
                                            className={classes.formControl}
                                            label="New Rate"
                                            id="newRate"
                                            name="NewRate"
                                            value={this.state.NewRate || ''}
                                            onChange={this.handleChange}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            disabled={Boolean(this.state.RateID)}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="flex">
                                        <DatePicker
                                            className={classes.formControl}
                                            label="Effective Date"
                                            id="effectiveDate"
                                            format="MM/DD/YYYY"
                                            value={this.state.EffectiveDate}
                                            onChange={this.handleEffectiveDateChange}
                                            inputVariant="outlined"
                                            required
                                            fullWidth
                                            disabled={Boolean(this.state.RateID)}
                                            clearable={true}
                                        />
                                    </div>
                                    <div className="flex">
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="GL Account"
                                            options={glAccounts}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.GLAccount}>
                                                    {value.GLAccount} - {value.Description}
                                                </MenuItem>
                                            }}
                                            portal={matches}
                                            noflip={matches}
                                            value={this.state.GLAccount || ''}
                                            onSelect={(option) => this.setState({ ...this.state, GLAccount: option.GLAccount })}
                                            required
                                        />
                                    </div>
                                </React.Fragment>
                            }


                            {vpCompany &&
                                <div className="flex relative">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="VP Tax Code"
                                        options={vpTaxCodes}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.TaxCode}>
                                                {`${value.TaxCode} - ${value.Description}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        debounce={750}
                                        onChange={(text) => this.setState({ ...this.state, searchDescription: text }, this.handleTaxCodeSearch)}
                                        value={this.state.VPTaxCode || ''}
                                        onSelect={(option) => this.setState({ ...this.state, VPTaxGroup: option.TaxGroup, VPTaxCode: option.TaxCode })}
                                        required
                                        disabled={Boolean(this.state.RateID)}
                                    />
                                    {isSearching &&
                                        <CircularProgress color="primary" className="absolute pin-r pin-t m-auto" style={{ width: 20, height: 20, marginRight: 9, marginTop: 18 }} />
                                    }
                                </div>
                            }
                            {qboCompany && !Boolean(this.state.RateID) &&
                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="QB Tax Code"
                                        options={qboTaxCodes}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Id}>
                                                {`${value.Name} - ${value.TaxRate}%`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.QBOTaxRate || ''}
                                        onSelect={(option) => { if (taxCodeDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOTaxRate: option.Id }) } else { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOTaxRate: option.Id }) } }}
                                    // required
                                    />
                                </div>
                            }
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {taxCodeDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addTaxCode(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateTaxCode(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTaxCodeDialog: Actions.closeEditTaxCodeDialog,
        closeNewTaxCodeDialog: Actions.closeNewTaxCodeDialog,
        addTaxCode: Actions.addTaxCode,
        updateTaxCode: Actions.updateTaxCode,
        removeTaxCode: Actions.removeTaxCode
    }, dispatch);
}

function mapStateToProps({ taxCodesApp, spReducers }) {
    return {
        taxCodeDialog: taxCodesApp.taxCodes.taxCodeDialog,
        Co: spReducers.companies.Co,
        glAccounts: spReducers.glAccounts,
        vpCompany: spReducers.vpSettings.vpCompany,
        vpTaxCodes: spReducers.vpSettings.taxCodes,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboTaxRates: spReducers.qboSettings.taxRates,
        qboTaxCodes: spReducers.qboSettings.taxCodes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TaxCodeDialog));
