import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { Dialog, Fab, Hidden, Icon, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import classNames from 'classnames';
import AttachmentDialog from './AttachmentDialog';
import { FuseAnimate } from '@fuse';
import axios from 'axios';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import _ from '@lodash';

const styles = theme => ({
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    typeIcon: {
        '&.folder:before': {
            content: "'folder'",
            color: '#FFB300'
        },
        '&.document:before': {
            content: "'insert_drive_file'",
            color: '#1565C0'
        },
        '&.spreadsheet:before': {
            content: "'insert_chart'",
            color: '#4CAF50'
        }
    }
});

class FileList extends Component {
    state = {
        Attachment: null,
        confirmDelete: false,
        deleteAttachment: null
    }

    updateAttachment = (file, notes) => {
        const { Attachment } = this.state;
        if (Attachment) {
            const fd = new FormData();
            if (typeof file === "string") {
                fd.append('fileName', file);    
            }else {
                fd.append('image', file, file.fileName);    
            }
            
            if (notes) {
                fd.append('notes', notes);
            }
            // saveAs(img.file, img.fileName);

            return axios.post(`${window["apiLocation"]}/api/Attachment/UpdateAttachment?Co=${encodeURIComponent(this.props.Co)}&Attachment=${Attachment.Attachment}`, fd).then((res) => {
                if (res.data.Attachment) {
                    const { attachments } = this.props;
                    const index = _.findIndex(attachments, { Attachment: Attachment.Attachment });
                    if (index > -1) {
                        attachments.splice(index, 1, res.data.Attachment);
                    }
                    this.setState({ Attachment: res.data.Attachment });
                }
            });
        }
    }

    deleteAttachment = (attachment) => {
        if (attachment) {
            axios.delete(`${window["apiLocation"]}/api/Attachment/DeleteAttachment?Co=${encodeURIComponent(this.props.Co)}&Attachment=${attachment.Attachment}`).then((res) => {
                const { attachments } = this.props;
                const index = _.findIndex(attachments, { Attachment: attachment.Attachment });
                if (index > -1) {
                    attachments.splice(index, 1);
                }
                this.setState({ Attachment: null, deleteAttachment: null });
            });
        }
    }

    getFileSize = (contentLength) => {
        const postFix = [" B", " KB", " MB"]
        let index = 0;
        while (contentLength >= 1024) {
            contentLength /= 1024.0;
            index++;
        }
        return index < 3 && contentLength ? contentLength.toFixed(2) + postFix[index] : '-';
    }

    render() {
        const { classes, files, selectedItem, setSelectedItem, pageLayout, attachments, onAdded, accept, readOnly } = this.props;
        const { Attachment } = this.state;
        const ext = { "png": "image", "bmp": "image", "jpg": "image", "jpeg": "image", "pdf": "insert_drive_file", "doc": "insert_drive_file", "docx": "insert_drive_file", "txt": "insert_drive_file", "xls": "insert_chart", "xlsx": "insert_chart", "csv": "insert_chart" };

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {onAdded && !readOnly &&
                        <React.Fragment>
                            <Fab
                                variant="fab"
                                color="secondary"
                                aria-label="add"
                                className={classes.addButton}
                                onClick={() => this.fileInput.click()}
                            >
                                <Icon>add</Icon>
                            </Fab>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => Promise.all([
                                    onAdded(e)
                                ]).then(() => {
                                    this.fileInput.value = "";
                                })}
                                ref={fileInput => this.fileInput = fileInput}
                                multiple
                                accept={accept}
                            />
                        </React.Fragment>
                    }
                    {!readOnly &&
                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">attach_file</Icon>Attachments</Typography>
                    }
                    <div className="max-w-full overflow-auto">
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="max-w-64 w-64 p-0 text-center"> </TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Added By</TableCell>
                                    <TableCell className="text-center">Size</TableCell>
                                    <TableCell className="text-center">Added Date</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                !attachments ?
                                    <TableBody>
                                        {Object.entries(files).map(([key, n]) => {
                                            return (
                                                <TableRow
                                                    key={n.id}
                                                    hover
                                                    onClick={event => setSelectedItem(n.id)}
                                                    selected={n.id === selectedItem}
                                                    className="cursor-pointer"
                                                >
                                                    <TableCell className="max-w-64 w-64 p-0 text-center">
                                                        <Icon className={classNames(classes.typeIcon, n.type)}></Icon>
                                                    </TableCell>
                                                    <TableCell>{n.name}</TableCell>
                                                    <TableCell className="hidden sm:table-cell">{n.type}</TableCell>
                                                    <TableCell className="hidden sm:table-cell">{n.owner}</TableCell>
                                                    <TableCell className="text-center hidden sm:table-cell">{n.size === '' ? '-' : n.size}</TableCell>
                                                    <TableCell className="hidden sm:table-cell">{n.modified}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {attachments.map((value, index) => {
                                            return (
                                                <TableRow
                                                    key={value.Attachment}
                                                    hover
                                                    onClick={() => this.setState({ Attachment: value }, () => window["warn"](this.state.Attachment))}
                                                    selected={value.Attachment === selectedItem}
                                                    className="cursor-pointer"
                                                >
                                                    <TableCell className="max-w-64 w-64 p-0 text-center">
                                                        <Icon color="primary" className={classes.typeIcon}>{ext[value.Extension.replace('.', '')]}</Icon>
                                                    </TableCell>
                                                    <TableCell className="min-w-256">{value.FileName}</TableCell>
                                                    <TableCell className="min-w-256">{value.Description}</TableCell>
                                                    <TableCell className="min-w-256">{value.AddedBy}</TableCell>
                                                    <TableCell className="text-center min-w-96">{value.FileSize === '' ? '-' : this.getFileSize(value.ContentLength)}</TableCell>
                                                    <TableCell className="min-w-128 text-center">{new Date(value.AddedDate).toLocaleDateString('en-US')}</TableCell>
                                                    {!readOnly &&
                                                        <TableCell className="w-96 p-0 text-center">
                                                            <Icon onClick={(e) => { e.stopPropagation(); this.setState({ confirmDelete: true, deleteAttachment: value }) }} color="error" className={classes.typeIcon}>delete</Icon>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            );
                                        })}
                                        {(Boolean(Attachment) && !this.state.confirmDelete) &&
                                            <AttachmentDialog readOnly={readOnly} attachments={attachments} onChange={(attch) => this.setState({ Attachment: attch })} onDelete={this.deleteAttachment} onSave={this.updateAttachment} notes={Attachment.Notes} onClose={() => this.setState({ Attachment: null })} attachment={Attachment} open={true} />
                                        }
                                        {Boolean(this.state.deleteAttachment) && this.state.confirmDelete &&
                                            <ConfirmationDialog
                                                open={this.state.confirmDelete}
                                                title={<div><Icon className="mr-6 align-middle mb-4">check_circle</Icon>Are You Sure?</div>}
                                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this file? This action cannot be undone.`}</div>}
                                                confirmText="Delete Attachment"
                                                cancelText="Cancel"
                                                onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                                                onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => this.deleteAttachment(this.state.deleteAttachment))}
                                            />
                                        }
                                    </TableBody>
                            }
                        </Table>
                    </div>
                </div>
            </FuseAnimate>
        )
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFiles: Actions.getFiles,
        setSelectedItem: Actions.setSelectedItem
    }, dispatch);
}

function mapStateToProps({ fileManagerApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FileList)));