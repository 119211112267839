import { FuseAnimate } from '@fuse'
import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  DialogContent,
  FormControlLabel,
  Hidden,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Step,
  StepButton,
  Stepper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Popover,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import classNames from 'classnames'
import * as ICS from 'ics-js'
import CustomerSitesTab from 'main/content/apps/call-handler/CustomerSitesTab'
import WorkOrdersTab from 'main/content/apps/call-handler/WorkOrdersTab'
import MailCompose from 'main/content/apps/mail/MailCompose'
import { formatPhone } from 'main/functions'
import { SPRoundIcon } from 'main/icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import format from 'string-template'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import {
  openEditAgreementDialog,
  openNewAgreementDialog,
} from '../agreements/store/actions'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import { createTrip } from '../dispatch-board/store/actions'
import { openEditWorkOrderDialog } from '../work-orders/store/actions'
import CustomersTab from './CustomersTab'
import ContactList from './ContactList'
import * as Actions from './store/actions'
import getProfileImage from '../../../functions/getProfileImageUrl'

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

function TripTabContainer(props) {
  return <Typography component='div'>{props.children}</Typography>
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {
    width: '100%',
    paddingBottom: 56,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  navControl: {
    marginRight: 35,
    marginBottom: 35,
    float: 'right',
  },
  form: {
    borderRight: '1px solid lightgrey',
  },
  summary: {},
  workflow: {
    marginTop: 1,
    background: 'transparent',
  },
  card: {
    minHeight: 610,
    padding: 8,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  link: {
    cursor: 'pointer',
  },
  avatarTech: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  tabs: {
    width: '100%',
  },
  tab: {
    minWidth: '25%',
    maxWidth: '25%',
    paddingTop: 12,
    paddingBottom: 12,
  },
  action: {
    marginTop: 0,
    marginRight: -8,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  info: {
    padding: 8,
    paddingTop: 0,
    paddingBottom: 0 + '!important',
  },
  campaign: {
    width: 'auto',
    top: 0,
    right: -5,
    marginTop: 5,
    borderRadius: 4,
    padding: 10,
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 'bold',
    // fontSize: 12,
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: 11,
  },
  tripAdminTab: {
    minWidth: '20%',
    maxWidth: '20%',
    paddingTop: 12,
    paddingBottom: 12,
  },
  container: {
    padding: 0,
  },
  trip: {
    minWidth: 300,
  },
  tripTabContent: {
    minHeight: 175,
    maxHeight: 175,
    overflow: 'auto',
  },
  formControl: {
    marginBottom: 12,
  },
  footer: {
    top: 'auto',
    bottom: 0,
  },
  scopeBadge: {
    top: 5,
    right: 2,
  },
  tripBadge: {
    top: 35,
    right: 35,
    background: '#3f3f3f',
  },
  tripBadgeIcon: {
    fontSize: 14,
    marginTop: 1,
  },
  tripCountBadge: {
    top: 15,
    right: -7,
    height: 17,
    minWidth: 17,
    fontSize: '1rem',
    border: '2px solid #3f3f3f',
    paddingTop: 2,
  },
  tripRoot: {
    display: 'block',
    padding: 0,
    marginLeft: -12,
    marginRight: -12,
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  yellow: {
    backgroundColor: '#f4b636',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5f5f5f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
    '&:active': {
      backgroundColor: '#5f5f5f',
    },
  },
  grey: {
    backgroundColor: '#5f5f5f',
    color: '#fff',
  },
  redText: {
    color: '#f44336',
  },
  greenText: {
    color: '#d1e751',
  },
  blueText: {
    color: '#4dbce9',
  },
  yellowText: {
    color: '#f4b636',
  },
  blackText: {
    color: '#3f3f3f',
  },
  greyText: {
    color: '#5f5f5f',
  },
  liRoot: {
    padding: 0,
  },
  wrap: {
    whiteSpace: 'normal !important',
  },
  recessed: {
    marginBottom: -5,
  },
  scopeAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
    fontSize: 16,
  },
  liRoot: {
    padding: 0,
  },
  assigned: {
    backgroundColor: theme.palette.primary.main,
  },
  popper: {
    opacity: 1,
    marginBottom: 15,
    marginTop: 5,
    zIndex: 5,
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  tooltip: {
    opacity: 1,
    boxShadow: '4px 4px 8px #000',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  contactChip: {
    //fontSize: 10,
    marginRight: 4,
    height: 18,
    borderRadius: 4,
  },
})

function getSteps() {
  return [
    { Name: 'Customer', Icon: 'account_circle' },
    { Name: 'Service Site', Icon: 'location_on' },
    { Name: 'Work Order', Icon: 'assignment' },
  ]
}

const initialState = {
  activeStep: 0,
  anchorEl: null,
  smAnchorEl: null,
  trip: null,
  tripValue: 0,
  completed: {},
  anchorEl2: null,
}

const SiteMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class CallHandler extends Component {
  state = {
    ...initialState,
    ..._.cloneDeepWith(this.props.callHandlerData.data.Customer),
  }

  totalSteps = () => getSteps().length

  handleNext = () => {
    let activeStep
    window['warn'](this.state.completed)
    if (this.isLastStep() && !this.allStepsCompleted()) {
      const steps = getSteps()
      activeStep = steps.findIndex((step, i) => !(i in this.state.completed))
    } else {
      activeStep = this.props.activeStep + 1
    }
    this.props.setCallHandlerStep(activeStep)
  }

  handleBack = () => {
    const step = this.props.activeStep - 1
    this.props.setCallHandlerStep(step)
  }

  handleStep = nextStep => () => {
    const { data } = this.props
    if (nextStep === 0) {
      this.props.setCallHandlerStep(nextStep)
    } else if (nextStep === 1) {
      if (data.Customer.Customer) {
        this.props.setCallHandlerStep(nextStep)
      }
    } else if (nextStep === 2) {
      if (data.Customer.Customer && data.Site.Site) {
        this.props.setCallHandlerStep(nextStep)
      }
    }
  }

  handleComplete = () => {
    const { completed } = this.state
    completed[this.props.activeStep] = true
    this.setState({
      completed,
    })
    this.handleNext()
  }

  completedSteps() {
    return Object.keys(this.state.completed).length
  }

  isLastStep() {
    return this.props.activeStep === this.totalSteps() - 1
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps()
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, tab) => {
    this.setState({ tab })
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate().toString()
    const yyyy = dt.getFullYear().toString()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  formatDateInput(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }

  componentDidMount() {
    const { data, customerData, arrivalWindows, services } = this.props
    if (data && data.WorkOrder.WorkOrder) {
      let Duration = 0
      const {
        Co,
        WorkOrder,
        LeadTechnician,
        DueBy,
        ArrivalWindow,
        BusinessUnit,
        Division,
        Department,
        Service,
        ExactTime,
      } = data.WorkOrder
      const arrivalWindow = ExactTime
        ? null
        : _.find(arrivalWindows, { BusinessUnit, ArrivalWindow })
      const service = _.find(services, { Service })
      Duration +=
        service && service.DefaultDuration ? service.DefaultDuration : 1
      const trip = {
        Co,
        WorkOrder,
        Trip: null,
        Technician: null,
        Technicians: LeadTechnician ? [LeadTechnician] : [],
        Status: 0,
        ScheduledDate: DueBy,
        ScheduledTime:
          DueBy && (ExactTime || arrivalWindow.StartTime)
            ? `${moment(DueBy).local().toDate().toLocaleDateString('en-US')} ${
                ExactTime || arrivalWindow.StartTime
              }`
            : null,
        Duration,
        Data: {
          ErrMsg: null,
          Assignments: [
            {
              Co,
              WorkOrder,
              Trip: null,
              Scope: 1,
            },
          ],
        },
      }
      this.setState({ ...this.state, trip })
    } else {
      this.setState({ trip: null })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data, customerData, arrivalWindows, services } = this.props

    if (customerData !== null) {
      if (!_.isEqual(customerData, prevProps.customerData)) {
        this.setState({ Customer: customerData })
      }
    }
    if (!_.isEqual(data, prevProps.data)) {
      if (data.WorkOrder.WorkOrder) {
        if (!prevProps.data.WorkOrder.WorkOrder) {
          this.emailConfirmation()
        }

        let Duration = 0
        const {
          Co,
          WorkOrder,
          LeadTechnician,
          DueBy,
          ArrivalWindow,
          BusinessUnit,
          Division,
          Department,
          Service,
          ExactTime,
        } = data.WorkOrder
        const arrivalWindow = _.find(arrivalWindows, {
          BusinessUnit,
          ArrivalWindow,
        })
        const service = _.find(services, { Service })
        Duration +=
          service && service.DefaultDuration ? service.DefaultDuration : 1
        const trip = {
          Co,
          WorkOrder,
          Trip: null,
          Technician: null,
          Technicians: LeadTechnician ? [LeadTechnician] : [],
          Status: 0,
          ScheduledDate: DueBy,
          ScheduledTime:
            DueBy && (ExactTime || arrivalWindow.StartTime)
              ? `${moment(DueBy)
                  .local()
                  .toDate()
                  .toLocaleDateString('en-US')} ${
                  ExactTime || arrivalWindow.StartTime
                }`
              : null,
          Duration,
          Data: {
            ErrMsg: null,
            Assignments: [
              {
                Co,
                WorkOrder,
                Trip: null,
                Scope: 1,
              },
            ],
          },
        }
        this.setState({ ...this.state, trip })
      } else {
        this.setState({ ...this.state, trip: null })
      }
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, completed: {} })
  }

  handleSmClick = event => {
    window['warn'](event)
    this.setState({ smAnchorEl: event.currentTarget, completed: {} })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClearWindow = () => {
    this.setState({ ...initialState })
    this.props.clearCallHandlerData()
  }

  clearWorkOrder = () => {
    this.setState({ anchorEl: null, trip: null })
    this.props.clearWorkOrderData()
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  handleSaveDraft = () => {
    this.setState({ anchorEl: null })
    const { data, drafts, callID, activeStep, completed } = this.props
    if (callID && drafts[callID]) {
      drafts[callID] = {
        ...data,
        Customer: {
          ...data.Customer,
          type: 'saved',
        },
        Site: {
          ...data.Site,
          type: 'saved',
        },
        WorkOrder: {
          ...data.WorkOrder,
          type: 'saved',
          EnteredDate: new Date(),
        },
        activeStep,
      }
    } else {
      const id = this.guid()
      drafts[id] = {
        ...data,
        Customer: {
          ...data.Customer,
          type: 'saved',
        },
        Site: {
          ...data.Site,
          type: 'saved',
        },
        WorkOrder: {
          ...data.WorkOrder,
          type: 'saved',
          EnteredDate: new Date(),
        },
        activeStep,
      }
    }
    this.props.saveCallDraft(drafts)
  }

  handleDeleteDraft = () => {
    this.setState({ anchorEl: null })
    const { data, drafts, callID } = this.props
    if (callID && drafts[callID]) {
      delete drafts[callID]
    }
    this.props.saveCallDraft(drafts)
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return <CustomersTab />
      case 1:
        return <CustomerSitesTab />
      case 2:
        return <WorkOrdersTab />
      default:
        return null
    }
  }

  getFullAddress() {
    const { data } = this.props
    return data.Site.Address || data.Customer.Address
      ? data.Site.Address
        ? data.Site.Address
        : data.Customer.Address
      : false
  }

  formatDate(date) {
    const dt = new Date(date)
    const mm = dt.getMonth() + 1
    const dd = dt.getDate().toString()
    const yyyy = dt.getFullYear().toString()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return date ? formatted : ''
  }

  canBeSubmitted() {
    const { Customer, Site } = this.props.data
    return Customer.Customer && Site.Site
  }

  renderDateLabel = e => {
    window['log'](e)
    return (
      <TextField
        style={{ marginBottom: 12 }}
        label='Scheduled Date/Time'
        id='scheduledDate'
        name='ScheduledDate'
        onClick={e => {
          this.refs.datePicker.open(e)
        }}
        inputProps={{
          style: {
            padding: 12,
          },
        }}
        value={e.value}
        variant='outlined'
        fullWidth
      />
    )
  }

  handleAdminChange = (event, tripValue) => {
    this.setState({ tripValue })
  }

  createTrip = () => {
    const { trip } = this.state
    if (trip.Technicians.length > 0) {
      this.props.createTrip(trip, true)
      const newTrip = { ...trip, Technician: null, Technicians: [] }
      this.setState({ trip: newTrip, tripValue: 0 })
    }
  }

  handleAdminTripChange = event => {
    let { trip, tripValue } = this.state
    _.set(
      trip,
      event.target.name,
      event.target.type === 'checkbox'
        ? event.target.checked
          ? 'Y'
          : 'N'
        : event.target.value,
    )
    tripValue += tripValue < 3 ? 1 : 0
    this.setState({ ...this.state, trip, tripValue })
  }

  handleAdminTripDateChange = event => {
    let { trip, tripValue } = this.state
    window['warn'](event)
    trip.ScheduledDate = this.formatDate(event._d)
    trip.ScheduledTime = event._d.toLocaleString('en-US')
    tripValue += tripValue < 3 ? 1 : 0
    this.setState({ ...this.state, trip, tripValue })
  }

  handleAnytimeChange = event => {
    const { trip } = this.state
    if (event.target.checked) {
      trip.ScheduledTime = null
    } else {
      trip.ScheduledTime = trip.ScheduledDate
    }
    this.setState({ ...this.state, trip })
  }

  newAgreement = () => {
    const { Co, data } = this.props
    const { Site, Customer } = data
    const StartDate = new Date()
    const Data = {
      Co,
      Customer: Site.Customer,
      Site: Site.Site,
      StartDate,
      Data: {
        AgreementType: null,
        Site,
        Customer,
        Tasks: [],
        Schedule: [],
        Variations: Site.Data.Agreements,
        Attachments: [],
      },
    }
    // window["warn"](Data, Site, Customer);
    this.props.openNewAgreementDialog(_.cloneDeepWith(Data))
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  formatPhone(str) {
    return formatPhone(str).formatted
  }

  emailConfirmation = () => {
    const {
      data,
      businessUnits,
      divisions,
      services,
      arrivalWindows,
      templates,
      companies,
    } = this.props
    const { ID, Co, WorkOrder, Data, EnteredDate, UpdatedDate } = data.WorkOrder
    const co = _.find(companies, { Co })
    const Scopes = [data.WorkOrder]
    const { Customer, Site } = data
    const { Address1, City, State, Zip } = Site
    const Address = this.buildAddress(Address1, City, State, Zip)
    //
    const contacts = _.uniqBy([
      ...data.Site.Data.Contacts,
      ...data.Customer.Data.Contacts,
    ])
    const siteContact =
      data.WorkOrder.Data.Contact ||
      _.find(contacts, { Contact: data.WorkOrder.SiteContact })
        ? _.find(contacts, { Contact: data.WorkOrder.SiteContact }).Data.Contact
        : null
    //

    const custContacts = data.Customer.Data.Contacts
      ? data.Customer.Data.Contacts
      : []
    const primaryRecord = custContacts.find(item => item.PrimaryYN === 'Y')
    const primaryEmail = primaryRecord && primaryRecord.Data.Contact.Email
    const Attachments = []
    let Event
    let html = ''
    const reqBy = data.WorkOrder.Data.RequestedByContact
    if (Scopes) {
      const scope = Scopes[0]
      const { BusinessUnit, Division, Data } = Scopes[0]
      if (businessUnits && BusinessUnit) {
        const bu = _.find(businessUnits, { Co, BusinessUnit })
        const div = _.find(divisions, { Co, BusinessUnit, Division })
        const Service = _.find(services, { Co, Service: scope.Service })
        const ArrivalWindow = _.find(arrivalWindows, {
          Co,
          BusinessUnit,
          ArrivalWindow: scope.ArrivalWindow,
        })
        const entered = new Date(EnteredDate).toUTCString()
        const updated = new Date(UpdatedDate || EnteredDate).toUTCString()
        if (div && bu) {
          if (ArrivalWindow) {
            const start = new Date(
              `${new Date(scope.DueBy).toLocaleDateString('en-US')} ${
                ArrivalWindow.StartTime
              }`,
            ).toUTCString()
            const end = new Date(
              `${new Date(scope.DueBy).toLocaleDateString('en-US')} ${
                ArrivalWindow.EndTime
              }`,
            ).toUTCString()
            const cal = new ICS.VCALENDAR()
            cal.addProp('PRODID', 'servicepointpro.com')
            cal.addProp('VERSION', 2)
            cal.addProp('METHOD', 'REQUEST')
            const event = new ICS.VEVENT()
            event.addProp('UID', `${Co}-${WorkOrder}@my.servicepointpro.com`)
            event.addProp('DTSTAMP', new Date(), { VALUE: 'DATE-TIME' })
            event.addProp('CREATED', new Date(entered), { VALUE: 'DATE-TIME' })
            event.addProp('SEQUENCE', 0)
            event.addProp('ORGANIZER', null, {
              CN: `"${bu.Description}":MAILTO:scheduling@servicepointpro.com`,
            })
            if (Customer.Email && Customer.Email.length > 0) {
              event.addProp('ATTENDEE', null, {
                ROLE: 'REQ-PARTICIPANT',
                PARTSTAT: 'NEEDS-ACTION',
                RSVP: 'TRUE',
                CN: `"${
                  Customer.Name || `${Customer.FirstName} ${Customer.LastName}`
                }":MAILTO:${Customer.Email}`,
              })
            }
            event.addProp('DTSTART', new Date(start), { VALUE: 'DATE-TIME' })
            event.addProp('DTEND', new Date(end), { VALUE: 'DATE-TIME' })
            event.addProp('LOCATION', Address)
            event.addProp(
              'SUMMARY',
              `Your ${bu.Description} Service Appointment`,
            )
            event.addProp(
              'DESCRIPTION',
              `Service Appointment with ${bu.Description} for ${Service.Detail}`,
            )
            event.addProp('PRIORITY', '3')
            cal.addComponent(event)
            Event = cal.toString()
            window['warn'](start, end, Event)
            const blob = cal.toBlob()
          }
          const template =
            div.Data.ConfirmationEmailTemplate ||
            bu.Data.ConfirmationEmailTemplate
          // const template = _.find(templates, { ID: templateID });
          const templateData = {
            ...Customer,
            ...Site,
            ...scope,
            Name: Customer.Name || `${Customer.FirstName} ${Customer.LastName}`,
            DueBy: new Date(scope.DueBy).toLocaleDateString('en-US'),
            ArrivalWindow:
              !scope.ExactTime && ArrivalWindow
                ? ArrivalWindow.Description
                : scope.ExactTime
                  ? moment(
                      `${new Date(scope.DueBy).toLocaleDateString('en-US')} ${
                        scope.ExactTime
                      }`,
                    ).format('H:mm A')
                  : 'ANYTIME',
          }
          // window["warn"](template, bu, templateData);
          html = template ? format(template.Markup, templateData) : ''
          // Attachments.push(new File([blob], `service-appointment.ics`, { type: "text/calendar" }));
        }
      }
    }

    const emailData = {
      composeDialog: true,
      Title: 'Send Confirmation',
      Icon: 'email',
      // To: `${reqBy && reqBy.Email ? reqBy.Email : Customer.Email || Site.Email};`,
      To: `${
        reqBy && reqBy.Email
          ? reqBy.Email
          : primaryEmail || Customer.Email || siteContact.Email
      };`,
      Subject: `Your Work Order Confirmation from ${co.Data.Co.Name}`,
      Head: '',
      Message: '',
      Body: html,
      Attachments,
      Type: 'WO',
      RecordID: ID,
      role: 'confirmation',
    }
    this.setState(
      { ...this.state, confirmationData: { ...emailData } },
      this.closeMenu,
    )
    return emailData
  }

  render() {
    const { anchorEl, tripValue, trip, confirmationData } = this.state
    const {
      classes,
      data,
      activeStep,
      securables,
      map,
      completed,
      googleMapsAPIKey,
      openCustomer,
      openSite,
      openWorkOrder,
      callID,
      technicians,
      openEditAgreementDialog,
      openNewAgreementDialog,
      businessUnits,
      departments,
      divisions,
      serviceTypes,
      services,
      arrivalWindows,
      rateTemplates,
      formOfPayments,
    } = this.props
    const tabs = []
    const tabIcons = []
    const steps = getSteps()
    const open = Boolean(anchorEl)
    const Address = this.getFullAddress()
    const dueBy = this.formatDate(data.WorkOrder.DueBy)
    const avatar = 'assets/images/avatars/profile.jpg'
    let sortAgreements = []
    const agreementAccessLevel = _.find(securables, {
      Securable: 'work-order-agreements',
    })
    if (agreementAccessLevel) {
      window['warn']('Access Level: ', agreementAccessLevel.AccessLevel)
    }
    if (data.Site && data.Site.Data && data.Site.Data.Agreements) {
      const { Agreements } = data.Site.Data
      const active = _.filter(Agreements, { ActiveYN: 'Y' })
      const inactive = _.filter(Agreements, o => {
        return o.ActiveYN === 'N' && !_.find(active, { Agreement: o.Agreement })
      })
      sortAgreements = _.orderBy(
        [...active, ...inactive],
        ['Cycle', 'Revision'],
        ['asc', 'desc'],
      )
    }
    const statusProps = [
      {
        text: 'Scheduled',
        icon: 'access_time',
        color: 'rgb(100, 100, 200)',
        dateProp: 'ScheduledDate',
        timeProp: 'ScheduledTime',
      },
      {
        text: 'Notified',
        icon: 'offline_bolt',
        color: 'rgb(0, 150, 250)',
        dateProp: 'NotifiedDate',
        timeProp: 'NotifiedTime',
      },
      {
        text: 'Accepted',
        icon: 'offline_pin',
        color: 'rgb(0, 50, 250)',
        dateProp: 'AcceptedDate',
        timeProp: 'AcceptedTime',
      },
      {
        text: 'Rejected',
        icon: 'cancel',
        color: 'rgb(210, 0, 0)',
        dateProp: 'RejectedDate',
        timeProp: 'RejectedTime',
      },
      {
        text: 'En-Route',
        icon: 'explore',
        color: 'rgb(50, 170, 200)',
        dateProp: 'EnRouteDate',
        timeProp: 'EnRouteTime',
      },
      {
        text: 'Arrived',
        icon: 'my_location',
        color: 'rgb(50, 150, 100)',
        dateProp: 'ArrivedDate',
        timeProp: 'ArrivedTime',
      },
      {
        text: 'In Progress',
        icon: 'play_circle_outline',
        color: 'rgb(150, 200, 50)',
        dateProp: 'InProgressDate',
        timeProp: 'InProgressTime',
      },
      {
        text: 'On Hold',
        icon: 'pause_circle_outline',
        color: 'rgb(210, 100, 100)',
        dateProp: 'OnHoldDate',
        timeProp: 'OnHoldTime',
      },
      {
        text: 'Completed',
        icon: 'check_circle',
        color: 'rgb(100, 100, 100)',
        dateProp: 'CompletedDate',
        timeProp: 'CompletedTime',
      },
    ]
    const priorities = [
      { text: 'Critical', color: 'red' },
      { text: 'High', color: 'red' },
      { text: 'Medium', color: 'gold' },
      { text: 'Low', color: 'inherit' },
    ]
    let due = data.Customer.InvoiceTotal - data.Customer.PaymentsTotal
    if (data.Customer.Data && data.Customer.Data.VPARCM) {
      due =
        data.Customer.Data &&
        data.Customer.Data.VPARCM &&
        data.Customer.Data.VPARCM.Data &&
        data.Customer.Data.VPARCM.Data.VPARMTCustAmtDue
          ? data.Customer.Data.VPARCM.Data.VPARMTCustAmtDue.CustAmtDue
          : 0
    }
    const leadTech = _.find(technicians, {
      Technician: data.WorkOrder.LeadTechnician,
    })
    const businessUnit = _.find(businessUnits, {
      BusinessUnit: data.WorkOrder.BusinessUnit,
    })
    const division = _.find(divisions, {
      BusinessUnit: data.WorkOrder.BusinessUnit,
      Division: data.WorkOrder.Division,
    })
    const department = _.find(departments, {
      BusinessUnit: data.WorkOrder.BusinessUnit,
      Division: data.WorkOrder.Division,
      Department: data.WorkOrder.Department,
    })
    const serviceType = _.find(serviceTypes, {
      ServiceType: data.WorkOrder.ServiceType,
    })
    const priority = priorities[data.WorkOrder.Priority]
    const service = _.find(services, { Service: data.WorkOrder.Service })
    const arrivalWindow = _.find(arrivalWindows, {
      BusinessUnit: data.WorkOrder.BusinessUnit,
      ArrivalWindow: data.WorkOrder.ArrivalWindow,
    })
    const rateTemplate = _.find(rateTemplates, {
      BusinessUnit: data.WorkOrder.BusinessUnit,
      Division: data.WorkOrder.Division,
      RateTemplate: data.WorkOrder.RateTemplate,
    })
    const formOfPayment = _.find(formOfPayments, {
      BusinessUnit: data.WorkOrder.BusinessUnit,
      FormOfPayment: data.WorkOrder.FormOfPayment,
    })
    const reqBy =
      data.WorkOrder.Data.RequestedByContact ||
      _.find([...data.Site.Data.Contacts, ...data.Customer.Data.Contacts], {
        Contact: data.WorkOrder.RequestedByContact,
      })
        ? _.find([...data.Site.Data.Contacts, ...data.Customer.Data.Contacts], {
            Contact: data.WorkOrder.RequestedByContact,
          }).Data.Contact
        : null
    const contacts = _.uniqBy([
      ...data.Site.Data.Contacts,
      ...data.Customer.Data.Contacts,
    ])
    const siteContact =
      data.WorkOrder.Data.Contact ||
      _.find(contacts, { Contact: data.WorkOrder.SiteContact })
        ? _.find(contacts, { Contact: data.WorkOrder.SiteContact }).Data.Contact
        : null
    const custContacts = data.Customer.Data.Contacts
      ? data.Customer.Data.Contacts
      : []
    const primaryRecord = custContacts.find(item => item.PrimaryYN === 'Y')
    const primaryEmail = primaryRecord && primaryRecord.Data.Contact.Email

    const allContacts =
      this.state.Data &&
      this.state.Data.Customer &&
      this.state.Data.Site &&
      this.state.Data.Site.Data &&
      this.state.Data.Customer.Data &&
      this.state.Data.Site.Data.Contacts &&
      this.state.Data.Customer.Data.Contacts
        ? _.uniqBy(
            [
              ...this.state.Data.Site.Data.Contacts,
              ...this.state.Data.Customer.Data.Contacts,
            ],
            contact => contact.Data.Contact.Email,
          )
        : []

    let dueDateCompare
    if (trip) {
      var tripDate = moment(trip.ScheduledDate)
      var dueDate = moment(data.WorkOrder.DueBy)

      dueDateCompare = tripDate.startOf('day').isAfter(dueDate.startOf('day'))
    }

    window['warn']('ch panel data 444', data)

    return (
      <div className={classes.root}>
        {data.Customer.RefuseServiceYN === 'Y' && (
          <div className='w-full p-24 pb-0'>
            <div
              onClick={() => this.props.openCustomer(data.Customer)}
              className='p-8 rounded py-24 w-full cursor-pointer'
              style={{ backgroundColor: '#f9f9f9', border: '1px dashed red' }}
            >
              <Typography className='text-16 font-bold text-red w-full text-center'>
                <Icon color='error' className='mr-6 align-middle'>
                  explore_off
                </Icon>
                DO NOT SERVICE
              </Typography>
              <div className='w-full'>
                <div className='italic mt-4 text-center'>
                  "{data.Customer.RefusalReason || 'N/A'}"
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='md:flex relative'>
          <div
            className='pin-r absolute mt-16  mr-0 sm:mr-16'
            style={{ zIndex: 5 }}
          >
            <IconButton
              aria-haspopup='true'
              aria-owns={open ? 'draft-menu' : undefined}
              onClick={this.handleClick}
            >
              <Icon>more_vert</Icon>
            </IconButton>
            {confirmationData && (
              <MailCompose
                contacts={this.state.Data.Contacts}
                onClose={() =>
                  this.setState({ ...this.state, confirmationData: null })
                }
                hideButton={true}
                data={{ ...confirmationData }}
              />
            )}
            <Menu
              id='draft-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleClose}
            >
              {!data.WorkOrder.WorkOrder && (
                <MenuItem onClick={this.handleSaveDraft}>
                  <Icon className='mr-8'>assignment_ind</Icon>Save Draft
                </MenuItem>
              )}
              {Boolean(data.WorkOrder.WorkOrder) && (
                <MenuItem onClick={this.clearWorkOrder}>
                  <Icon className='mr-8'>assignment</Icon>New Work Order
                </MenuItem>
              )}
              <MenuItem onClick={this.handleClearWindow}>
                <Icon className='mr-8'>clear</Icon>Reset Form
              </MenuItem>
              {callID && (
                <MenuItem onClick={this.handleDeleteDraft}>
                  <Icon className='mr-8'>delete</Icon>Delete Draft
                </MenuItem>
              )}
            </Menu>
          </div>
          <div className='w-full md:w-3/5'>
            {this.getStepContent(activeStep)}
          </div>
          <Hidden mdUp>
            <Stepper
              nonLinear
              className={classes.workflow}
              activeStep={activeStep}
            >
              {steps.map((label, index) => (
                <Step key={label.Name}>
                  <StepButton
                    icon={
                      <Icon
                        color={
                          this.props.activeStep == index
                            ? 'primary'
                            : this.state.completed[index]
                              ? 'secondary'
                              : 'disabled'
                        }
                      >
                        {label.Icon}
                      </Icon>
                    }
                    onClick={this.handleStep(index)}
                    completed={this.state.completed[index]}
                  >
                    {/* {label.Name} */}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Hidden>
          <div className='w-full md:w-2/5'>
            <FuseAnimate animation='transition.slideUpIn' delay={300}>
              <DialogContent classes={{ root: 'p-12 sm:p-24' }}>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                >
                  <Icon color='secondary' className='text-32 mr-12'>
                    phone
                  </Icon>
                  Call Summary
                </Typography>
                {data.Customer && data.Customer.ApprovedAccountYN === 'Y' && (
                  <div
                    className='p-8 rounded w-full cursor-pointer rounded-t-lg rounded-b-none'
                    style={{ backgroundColor: '#3f3f3f' }}
                  >
                    <Typography className='text-16 font-bold text-white w-full text-center'>
                      <Icon color='secondary' className='mr-6 align-middle'>
                        security
                      </Icon>
                      Approved Account
                    </Typography>
                  </div>
                )}
                <Card className={classes.card}>
                  <CardHeader
                    className='p-12'
                    classes={{ title: classes.title, avatar: 'mr-6' }}
                    avatar={
                      <Icon color='primary' className='text-32'>
                        assignment_ind
                      </Icon>
                    }
                    title={
                      data.WorkOrder.WorkOrder ? (
                        <div>
                          {`Work Order #${data.WorkOrder.WorkOrder}`}
                          <Tooltip placement='top' title='Edit Work Order'>
                            <Icon
                              onClick={() => {
                                openWorkOrder(data.WorkOrder)
                              }}
                              className='text-16 ml-8 cursor-pointer align-middle'
                              style={{ marginBottom: 2, marginLeft: 4 }}
                            >
                              open_in_new
                            </Icon>
                          </Tooltip>
                        </div>
                      ) : (
                        'New Work Order Draft'
                      )
                    }
                    subheader={
                      data.WorkOrder.WorkOrder
                        ? `Scope #${data.WorkOrder.Scope}`
                        : `${new Date(
                            data.WorkOrder.EnteredDate,
                          ).toLocaleDateString('en-US')} at ${new Date(
                            data.WorkOrder.EnteredDate,
                          ).toLocaleTimeString('en-US')}`
                    }
                  />
                  {Address && (
                    <CardContent className='p-12 pt-0'>
                      <img
                        style={{ width: '100%', borderRadius: 5 }}
                        src={`https://maps.googleapis.com/maps/api/streetview?key=${googleMapsApiKey}&size=600x225&location=${encodeURIComponent(
                          Address,
                        )}`}
                      />
                      {due && due > 0 && !data.WorkOrder.WorkOrder ? (
                        <Chip
                          color='error'
                          onClick={() => {
                            openCustomer(data.Customer)
                          }}
                          className={classNames(
                            'mr-8 mt-4 h-36 cursor-pointer w-full font-bold',
                            classes.black,
                          )}
                          style={{ borderRadius: 5 }}
                          icon={<Icon color='error'>today</Icon>}
                          label={`Balance Due: ${this.formatDollars(due)}`}
                        />
                      ) : (
                        data.WorkOrder.WorkOrder && (
                          <Button
                            variant='contained'
                            color='primary'
                            aria-label='email'
                            className={classNames(
                              classes.black,
                              'h-36 mt-12 w-full text-12',
                            )}
                            onClick={() => {
                              this.emailConfirmation()
                              window['warn'](this.state)
                            }}
                          >
                            <Icon className='text-20 mr-4' color='secondary'>
                              send
                            </Icon>
                            Send Confirmation
                          </Button>
                        )
                      )}
                      {!data.WorkOrder.WorkOrder &&
                        data.Customer.Data &&
                        data.Customer.Data.Redirect && (
                          <div
                            className='p-4 rounded mt-12 w-full cursor-pointer'
                            style={{ backgroundColor: '#333' }}
                          >
                            <Typography className='text-12 font-bold text-white w-full text-center'>
                              <Icon
                                color='secondary'
                                className='mr-4 align-middle'
                              >
                                directions
                              </Icon>
                              {`Redirected from Customer #${data.Customer.Data.Redirect.Customer}`}
                            </Typography>
                          </div>
                        )}
                      <Popover
                        open={Boolean(this.state.anchorEl2)}
                        anchorEl={this.state.anchorEl2}
                        onClose={() =>
                          this.setState({ ...this.state, anchorEl2: null })
                        }
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <div
                          className='w-full sm:flex mb-12'
                          style={{ marginTop: 10 }}
                        >
                          {/* <div className="hidden sm:block min-w-48 pt-20">
                                                        <Icon color="action">contacts</Icon>
                                                    </div> */}
                          <div className='mr-12 ml-12'>
                            <ContactList
                              label={'Contacts'}
                              onUpdate={Contacts =>
                                this.setState(
                                  {
                                    ...this.state,
                                    Data: { ...this.state.Data, Contacts },
                                  },
                                  () =>
                                    this.props.setCallHandlerData({
                                      ...this.state,
                                    }),
                                )
                              }
                              editable={true}
                              customer={{ ...this.state }}
                              contact={
                                /*(!this.state.ID && (!this.state.Data.Contacts || this.state.Data.Contacts.length < 1) && ((this.state.FirstName && this.state.LastName) || this.state.Name)) ? { Name: (this.state.FirstName && this.state.LastName) ? `${this.state.FirstName} ${this.state.LastName}` : this.state.Name, Phone: this.state.Phone && this.state.Phone.length >= 7 ? this.state.Phone : null, Email: this.state.Email && this.state.Email.length > 0 ? this.state.Email : null, Data: { ErrMsg: null } } :*/ null
                              }
                              details={data.Customer.Data.Contacts || []}
                            />
                          </div>
                        </div>
                      </Popover>

                      <div className='pt-12 flex pb-6'>
                        <div
                          className={classNames(
                            classes.link,
                            'min-w-1/2 min-h-96',
                          )}
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          onClick={this.handleStep(0)}
                        >
                          <Typography
                            style={{ alignItems: 'center', marginBottom: 0 }}
                            className='flex font-bold'
                          >
                            <Icon
                              color={
                                !data.Customer.Customer
                                  ? 'secondary'
                                  : 'primary'
                              }
                              className='text-32 mr-4'
                            >
                              account_circle
                            </Icon>
                            {data.Customer.Customer ? (
                              <span>
                                Customer{' '}
                                <label>#{data.Customer.Customer}</label>
                                <Tooltip placement='top' title='Edit Customer'>
                                  <Icon
                                    onClick={() => {
                                      openCustomer(data.Customer)
                                    }}
                                    className='text-16 ml-8 cursor-pointer align-middle'
                                    style={{ marginBottom: 2, marginLeft: 4 }}
                                  >
                                    open_in_new
                                  </Icon>
                                </Tooltip>
                              </span>
                            ) : (
                              <span>New Customer</span>
                            )}
                          </Typography>
                          <span className='text-xs'>
                            {data.Customer.FirstName && data.Customer.LastName
                              ? `${data.Customer.FirstName} ${data.Customer.LastName}`
                              : data.Customer.Name}
                            <br />
                            {`${data.Customer.Address1}`}
                            <br />
                            {data.Customer.Address2 &&
                            data.Customer.Address2.length > 0
                              ? data.Customer.Address2
                              : ''}
                            {data.Customer.Address2 &&
                            data.Customer.Address2.length > 0 ? (
                              <br />
                            ) : (
                              ''
                            )}
                            {`${data.Customer.City}, ${data.Customer.State} ${data.Customer.Zip}`}
                            <br />
                            {reqBy && reqBy.Phone
                              ? this.formatPhone(reqBy.Phone)
                              : ''}
                            {reqBy && reqBy.Phone && <br />}
                            {primaryEmail ? primaryEmail : ''}
                          </span>
                          {data.Customer.Customer && (
                            <Tooltip
                              title={
                                <div className='mt-8'>
                                  <span className='text-12'>
                                    {data.Customer.Name && (
                                      <>
                                        {/* <span className={classNames(classes.blackText, "font-bold text-12")}>
                                                                                Company:
                                                                            </span> 
                                                                            <Chip classes={{ label: "px-4" }} className={classNames(classes.contactChip, 'text-white text-12 mb-4 mt-8')} style={{ backgroundColor: '#4dbce9' }} label={'Company'} />
                                                                            <br />
                                                                            <span>
                                                                                {data.Customer.Name}
                                                                            </span>
                                                                            <br /><br />
                                                                        </>
                                                                    }
                                                                    {data.Customer.Data.Contacts && 
                                                                        <>
                                                                            {/* <span className="font-bold text-12">Contacts:</span> */}
                                        <Chip
                                          classes={{ label: 'px-4' }}
                                          className={classNames(
                                            classes.contactChip,
                                            'text-white text-12 mb-4',
                                          )}
                                          style={{ backgroundColor: '#4dbce9' }}
                                          label={'Contacts'}
                                        />
                                        <br />
                                        {data.Customer.Data.Contacts.map(
                                          Contact => {
                                            return (
                                              <>
                                                <span
                                                  style={{
                                                    textDecoration: 'underline',
                                                  }}
                                                >
                                                  {Contact.Data.Contact.Name}:
                                                </span>
                                                <br />
                                                <span
                                                  style={{ fontWeight: 'bold' }}
                                                >
                                                  Phone:
                                                </span>{' '}
                                                {this.formatPhone(
                                                  Contact.Data.Contact.Phone,
                                                )}
                                                <br />
                                                <span
                                                  style={{ fontWeight: 'bold' }}
                                                >
                                                  Email:
                                                </span>{' '}
                                                {Contact.Data.Contact.Email}
                                                <br />
                                                <br />
                                              </>
                                            )
                                          },
                                        )}
                                      </>
                                    )}
                                    {data.Customer.Notes && (
                                      <>
                                        <br />
                                        <Chip
                                          classes={{ label: 'px-4' }}
                                          className={classNames(
                                            classes.contactChip,
                                            'text-white text-12 mb-4',
                                          )}
                                          style={{ backgroundColor: '#4dbce9' }}
                                          label={'Notes'}
                                        />
                                        <br />
                                        <span>{data.Customer.Notes}</span>
                                      </>
                                    )}
                                    {data.Customer.Data.Tags &&
                                      data.Customer.Data.Tags.length > 0 && (
                                        <>
                                          <br />
                                          <br />
                                          <Chip
                                            classes={{ label: 'px-4' }}
                                            className={classNames(
                                              classes.contactChip,
                                              'text-white text-12 mb-4',
                                            )}
                                            style={{
                                              backgroundColor: '#4dbce9',
                                            }}
                                            label={'Tags'}
                                          />
                                          <br />
                                          <span>
                                            {data.Customer.Data.Tags.map(
                                              tag => {
                                                return (
                                                  <Chip
                                                    classes={{ label: 'px-4' }}
                                                    className={classNames(
                                                      classes.contactChip,
                                                      'text-white text-xs',
                                                    )}
                                                    style={{
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0.54)',
                                                    }}
                                                    label={`${tag.Title}`}
                                                  />
                                                )
                                              },
                                            )}
                                          </span>
                                          <br />
                                          <br />
                                        </>
                                      )}
                                  </span>
                                </div>
                              }
                              placement='bottom-start'
                              classes={{
                                popper: classes.popper,
                                tooltip: classes.tooltip,
                                tooltipPlacementTop: 'with-after-l',
                                tooltipPlacementBottom: 'with-before-l',
                              }} //tooltipPlacementTop: "with-after", tooltipPlacementBottom: "with-before",
                            >
                              <div>
                                {/* <span className="text-xs mt-12" style={{ color: '#4dbce9'}}>More Info</span> */}
                                <Chip
                                  classes={{ label: 'px-4' }}
                                  className={classNames(
                                    classes.contactChip,
                                    'text-white text-xs',
                                  )}
                                  style={{ backgroundColor: '#4dbce9' }}
                                  label={'More Customer Info'}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className={classNames(
                            classes.link,
                            'min-w-1/2 min-h-96',
                          )}
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          onClick={this.handleStep(1)}
                        >
                          <Typography
                            style={{ alignItems: 'center', marginBottom: 0 }}
                            className='flex font-bold'
                          >
                            <Icon
                              color={!data.Site.Site ? 'secondary' : 'primary'}
                              className='text-32 mr-0'
                            >
                              location_on
                            </Icon>
                            {data.Site.Site ? (
                              <span>
                                Site <label>#{data.Site.Site}</label>
                                <Tooltip
                                  placement='top'
                                  title='Edit Service Site'
                                >
                                  <Icon
                                    onClick={() => {
                                      openSite(data.Site)
                                    }}
                                    className='text-16 ml-8 cursor-pointer align-middle'
                                    style={{ marginBottom: 2, marginLeft: 4 }}
                                  >
                                    open_in_new
                                  </Icon>
                                </Tooltip>
                              </span>
                            ) : (
                              <span>New Service Site</span>
                            )}
                          </Typography>
                          <span className='text-xs'>
                            {siteContact && siteContact.Name
                              ? siteContact.Name
                              : data.Site.Description &&
                                `${data.Site.Description}`}
                            <br />
                            {data.Site.Address1 && `${data.Site.Address1}`}
                            <br />
                            {data.Site.Address2 && data.Site.Address2.length > 0
                              ? data.Site.Address2
                              : ''}
                            {data.Site.Address2 &&
                            data.Site.Address2.length > 0 ? (
                              <br />
                            ) : (
                              ''
                            )}
                            {data.Site.City &&
                              data.Site.State &&
                              data.Site.Zip &&
                              `${data.Site.City}, ${data.Site.State} ${data.Site.Zip}`}
                            <br />
                            {siteContact && siteContact.Phone
                              ? this.formatPhone(siteContact.Phone)
                              : reqBy && reqBy.Phone
                                ? this.formatPhone(reqBy.Phone)
                                : ''}
                            {((siteContact && siteContact.Phone) ||
                              (reqBy && reqBy.Phone)) && <br />}
                            {siteContact && siteContact.Email
                              ? siteContact.Email
                              : reqBy && reqBy.Email
                                ? reqBy.Email
                                : ''}
                          </span>
                          {data.Site.Site && (
                            <Tooltip
                              title={
                                <div>
                                  <span className='text-12'>
                                    {/* <span className="font-bold text-12">Contacts:</span> */}
                                    {data.Site.Data.Contacts && (
                                      <>
                                        <Chip
                                          classes={{ label: 'px-4' }}
                                          className={classNames(
                                            classes.contactChip,
                                            'text-white text-12 mb-4 mt-8',
                                          )}
                                          style={{ backgroundColor: '#4dbce9' }}
                                          label={'Contacts'}
                                        />
                                        <br />
                                        {data.Site.Data.Contacts.map(
                                          Contact => {
                                            return (
                                              <>
                                                <span
                                                  style={{
                                                    textDecoration: 'underline',
                                                  }}
                                                >
                                                  {Contact.Data.Contact.Name}:
                                                </span>
                                                <br />
                                                <span
                                                  style={{ fontWeight: 'bold' }}
                                                >
                                                  Phone:
                                                </span>{' '}
                                                {this.formatPhone(
                                                  Contact.Data.Contact.Phone,
                                                )}
                                                <br />
                                                <span
                                                  style={{ fontWeight: 'bold' }}
                                                >
                                                  Email:
                                                </span>{' '}
                                                {Contact.Data.Contact.Email}
                                                <br />
                                                <br />
                                              </>
                                            )
                                          },
                                        )}
                                      </>
                                    )}
                                    {data.Site.CrossStreets && (
                                      <>
                                        <br />
                                        <Chip
                                          classes={{ label: 'px-4' }}
                                          className={classNames(
                                            classes.contactChip,
                                            'text-white text-12 mb-4',
                                          )}
                                          style={{ backgroundColor: '#4dbce9' }}
                                          label={'Cross Streets'}
                                        />
                                        <br />
                                        <span>{data.Site.CrossStreets}</span>
                                        <br />
                                      </>
                                    )}
                                    {data.Site.Notes && (
                                      <>
                                        <br />
                                        <Chip
                                          classes={{ label: 'px-4' }}
                                          className={classNames(
                                            classes.contactChip,
                                            'text-white text-12 mb-4',
                                          )}
                                          style={{ backgroundColor: '#4dbce9' }}
                                          label={'Notes'}
                                        />
                                        <br />
                                        <span>{data.Site.Notes}</span>
                                      </>
                                    )}
                                    {data.Site.Data.Tags &&
                                      data.Site.Data.Tags.length > 0 && (
                                        <>
                                          <br />
                                          <br />
                                          <Chip
                                            classes={{ label: 'px-4' }}
                                            className={classNames(
                                              classes.contactChip,
                                              'text-white text-12 mb-4',
                                            )}
                                            style={{
                                              backgroundColor: '#4dbce9',
                                            }}
                                            label={'Tags'}
                                          />
                                          <br />
                                          <span>
                                            {data.Site.Data.Tags.map(tag => {
                                              return (
                                                <Chip
                                                  classes={{ label: 'px-4' }}
                                                  className={classNames(
                                                    classes.contactChip,
                                                    'text-white text-xs',
                                                  )}
                                                  style={{
                                                    backgroundColor:
                                                      'rgba(0, 0, 0, 0.54)',
                                                  }}
                                                  label={`${tag.Title}`}
                                                />
                                              )
                                            })}
                                          </span>
                                          <br />
                                          <br />
                                        </>
                                      )}
                                  </span>
                                </div>
                              }
                              placement='bottom-start'
                              classes={{
                                popper: classes.popper,
                                tooltip: classes.tooltip,
                                tooltipPlacementTop: 'with-after-l',
                                tooltipPlacementBottom: 'with-before-l',
                              }} //tooltipPlacementTop: "with-after", tooltipPlacementBottom: "with-before",
                            >
                              <div>
                                {/* <span className="text-xs mt-12" style={{ color: '#4dbce9'}}>More Info</span> */}
                                <Chip
                                  classes={{ label: 'px-4' }}
                                  className={classNames(
                                    classes.contactChip,
                                    'text-white text-xs',
                                  )}
                                  style={{ backgroundColor: '#4dbce9' }}
                                  label={'More Site Info'}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div className='w-full mb-8'>
                        <div className='w-full'>
                          {data.Site.AddedBy &&
                            sortAgreements &&
                            sortAgreements.map((agr, index) => (
                              <Tooltip
                                title={`${moment(agr.StartDate).format(
                                  'MM/DD/YYYY',
                                )} - ${moment(agr.EndDate).format(
                                  'MM/DD/YYYY',
                                )}`}
                                placement='top'
                              >
                                <Chip
                                  color='primary'
                                  classes={{ label: 'text-11' }}
                                  onClick={() =>
                                    openEditAgreementDialog(
                                      {
                                        ...agr,
                                        renewalWorkOrder: this.state.WorkOrder,
                                        renewalScope: 1,
                                      },
                                      false,
                                      data.WorkOrder.WorkOrder,
                                    )
                                  }
                                  className={classNames(
                                    'mr-8 mt-4 h-28 cursor-pointer',
                                    agr.ActiveYN !== 'Y'
                                      ? classes.black
                                      : new Date() >=
                                          new Date(agr.EndDate).setDate(
                                            new Date(agr.EndDate).getDate() -
                                              45,
                                          )
                                        ? new Date() > new Date(agr.EndDate)
                                          ? classes.red
                                          : classes.yellow
                                        : classes.blue,
                                  )}
                                  style={{ borderRadius: 5 }}
                                  icon={
                                    <SPRoundIcon
                                      className={classNames(
                                        'text-20',
                                        agr.ActiveYN === 'Y'
                                          ? classes.greenText
                                          : '',
                                      )}
                                    />
                                  }
                                  label={`${agr.Agreement} Cycle #${agr.Cycle} Rev #${agr.Revision}`}
                                />
                              </Tooltip>
                            ))}
                          {data.Site.Site &&
                            agreementAccessLevel &&
                            agreementAccessLevel.AccessLevel !== 'R' && (
                              <Chip
                                color='primary'
                                classes={{ label: 'text-11' }}
                                onClick={this.newAgreement}
                                className={classNames(
                                  'mr-8 mt-4 h-28 cursor-pointer',
                                  classes.black,
                                )}
                                style={{ borderRadius: 5 }}
                                icon={
                                  <Icon className={classes.greenText}>add</Icon>
                                }
                                label='Add Agreement'
                              />
                            )}
                        </div>
                      </div>
                      <div className='pt-12 flex'>
                        <div
                          className={classNames(classes.link, 'w-full')}
                          onClick={this.handleStep(2)}
                        >
                          <ListItem
                            classes={{ root: classes.liRoot }}
                            className='relative cursor-pointer'
                          >
                            <Icon
                              color={
                                !data.WorkOrder.WorkOrder
                                  ? 'secondary'
                                  : 'primary'
                              }
                              className='text-32 mr-4'
                            >
                              assignment
                            </Icon>
                            <ListItemText
                              classes={{
                                root: classes.liRoot,
                                primary: classNames(
                                  classes.title,
                                  classes.recessed,
                                ),
                              }}
                              primary={
                                <span
                                  className={classNames('font-bold text-14')}
                                >
                                  {service ? (
                                    <span>{service.Description}</span>
                                  ) : (
                                    <span>New Work Order</span>
                                  )}
                                </span>
                              }
                              secondary={
                                <span className='font-bold text-12'>
                                  {
                                    <Typography
                                      variant='caption'
                                      className={classNames(
                                        classes.subheader,
                                        classes.wrap,
                                      )}
                                    >
                                      {data.WorkOrder.Description}
                                    </Typography>
                                  }
                                </span>
                              }
                            />
                            <Typography
                              variant='caption'
                              className='font-bold absolute pin-r pin-t mr-4'
                              style={{ marginTop: 2 }}
                            >
                              {data.WorkOrder.DueBy &&
                                new Date(
                                  data.WorkOrder.DueBy,
                                ).toLocaleDateString('en-US')}
                            </Typography>
                          </ListItem>
                          <div className='w-full pl-8 mb-16'>
                            {businessUnit && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      business_center
                                    </Icon>
                                    Business Unit:{' '}
                                  </b>
                                  {`${businessUnit.Description}`}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {division && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      location_city
                                    </Icon>
                                    Division:{' '}
                                  </b>
                                  {`${division.Description}`}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {department && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      business
                                    </Icon>
                                    Department:{' '}
                                  </b>
                                  {`${department.Description}`}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {leadTech && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon className='mr-6 text-14'>
                                      account_circle
                                    </Icon>
                                    Lead Technician:{' '}
                                  </b>
                                  {`${leadTech.FirstName} ${leadTech.LastName}`}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {serviceType && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      settings
                                    </Icon>
                                    Service Type:{' '}
                                  </b>
                                  {serviceType.Description}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {priority && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{
                                        color:
                                          priorities[data.WorkOrder.Priority]
                                            .color,
                                        paddingTop: 1,
                                      }}
                                    >
                                      flag
                                    </Icon>
                                    Priority:{' '}
                                  </b>
                                  {`${priority.text}`}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {!data.WorkOrder.ExactTime && arrivalWindow && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      access_time
                                    </Icon>
                                    Arrival Window:{' '}
                                  </b>
                                  {`${arrivalWindow.Description}`}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {data.WorkOrder.ExactTime && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      access_time
                                    </Icon>
                                    Arrival Time:{' '}
                                  </b>
                                  {moment(
                                    `1/1/1970 ${data.WorkOrder.ExactTime}`,
                                  ).format('h:mm A')}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {rateTemplate &&
                              data.WorkOrder.PriceMethod === 'T' && (
                                <React.Fragment>
                                  <span className='text-12'>
                                    <b className='text-12'>
                                      <Icon
                                        className='mr-6 text-14'
                                        style={{ paddingTop: 1 }}
                                      >
                                        monetization_on
                                      </Icon>
                                      Rate Template:{' '}
                                    </b>
                                    {rateTemplate.Description}
                                  </span>
                                  <br />
                                </React.Fragment>
                              )}
                            {data.WorkOrder.PriceMethod === 'F' && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      monetization_on
                                    </Icon>
                                    Flat Price:{' '}
                                  </b>
                                  ${data.WorkOrder.Price}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {formOfPayment && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      payment
                                    </Icon>
                                    Form of Payment:{' '}
                                  </b>
                                  {formOfPayment.Description}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                            {data.WorkOrder.Notes && (
                              <React.Fragment>
                                <span className='text-12'>
                                  <b className='text-12'>
                                    <Icon
                                      className='mr-6 text-14'
                                      style={{ paddingTop: 1 }}
                                    >
                                      notes
                                    </Icon>
                                    Notes:{' '}
                                  </b>
                                  {data.WorkOrder.Notes}
                                </span>
                                <br />
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  )}
                  {trip && (
                    <CardActions classes={{ root: classes.tripRoot }}>
                      {trip && (
                        <div className='w-full'>
                          <AppBar
                            color='primary'
                            className={classNames(
                              classes.actions,
                              classes.footer,
                              'relative',
                            )}
                          >
                            <Tabs
                              value={tripValue}
                              className={classNames(classes.tabs)}
                              onChange={this.handleAdminChange}
                              variant='fullWidth'
                            >
                              <Tooltip title='Technicians' placement='top'>
                                <Tab
                                  disableRipple
                                  className={classes.tripAdminTab}
                                  icon={
                                    <Icon className='text-32'>group_add</Icon>
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                title='Trip Scheduled Date'
                                placement='top'
                              >
                                <Tab
                                  disableRipple
                                  className={classes.tripAdminTab}
                                  icon={
                                    <Icon className='text-32'>
                                      calendar_today
                                    </Icon>
                                  }
                                />
                              </Tooltip>
                              <Tooltip title='Trip Status' placement='top'>
                                <Tab
                                  disableRipple
                                  className={classes.tripAdminTab}
                                  icon={
                                    <Icon className='text-32'>
                                      {statusProps[trip.Status].icon}
                                    </Icon>
                                  }
                                />
                              </Tooltip>
                              <Tooltip title='Trip Duration' placement='top'>
                                <Tab
                                  disableRipple
                                  className={classes.tripAdminTab}
                                  icon={<Icon className='text-32'>timer</Icon>}
                                />
                              </Tooltip>
                              <Tooltip title='Assigned Scopes' placement='top'>
                                <Tab
                                  disableRipple
                                  className={classes.tripAdminTab}
                                  icon={<Icon className='text-32'>build</Icon>}
                                />
                              </Tooltip>
                            </Tabs>
                          </AppBar>
                          {tripValue == 0 && (
                            <TripTabContainer>
                              <CardContent
                                className={classNames(classes.info, 'px-16')}
                              >
                                <div className='pt-16'>
                                  <div className='flex'>
                                    <div className='min-w-36 pt-12 mt-6'>
                                      <Icon className='text-24' color='action'>
                                        person_add
                                      </Icon>
                                    </div>
                                    <Autocomplete
                                      className={classes.formControl}
                                      title='Add Technician'
                                      options={technicians}
                                      menuItemComponent={value => {
                                        return (
                                          value.ActiveYN === 'Y' && (
                                            <MenuItem
                                              value={value.Technician}
                                              disabled={
                                                _.findIndex(
                                                  trip.Technicians,
                                                  o => o === value.Technician,
                                                ) > -1
                                              }
                                            >
                                              <div className='flex'>
                                                <Avatar
                                                  style={{ marginRight: 8 }}
                                                  classes={{
                                                    root: classes.avatarRoot,
                                                  }}
                                                  className={classes.avatarTech}
                                                  alt={value.Technician}
                                                  src={
                                                    value.Data &&
                                                    value.Data.Avatar
                                                      ? getProfileImage(
                                                          `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                                        )
                                                      : avatar
                                                  }
                                                />
                                                {`${value.FirstName} ${value.LastName}`}
                                              </div>
                                            </MenuItem>
                                          )
                                        )
                                      }}
                                      margin='dense'
                                      value={trip.Technician}
                                      onSelect={option => {
                                        if (
                                          _.findIndex(
                                            trip.Technicians,
                                            o => o === option.Technician,
                                          ) < 0
                                        ) {
                                          trip.Technicians.push(
                                            option.Technician,
                                          )
                                          this.setState({ trip })
                                        }
                                      }}
                                      required
                                    />
                                  </div>
                                  <div className='mt-6'>
                                    {trip.Technicians.map((tech, index) => {
                                      const value = _.find(technicians, {
                                        Technician: tech,
                                      })
                                      if (value) {
                                        return (
                                          <div className='w-full flex mb-16 justify-center'>
                                            <div className='w-full flex'>
                                              <Avatar
                                                style={{ marginRight: 8 }}
                                                classes={{
                                                  root: classes.avatarRoot,
                                                }}
                                                className={classes.avatarTech}
                                                alt={value.Technician}
                                                src={
                                                  value.Data &&
                                                  value.Data.Avatar
                                                    ? getProfileImage(
                                                        `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                                      )
                                                    : avatar
                                                }
                                              />
                                              {`${value.FirstName} ${value.LastName}`}
                                            </div>
                                            <Icon
                                              color='action'
                                              onClick={() => {
                                                trip.Technicians.splice(
                                                  index,
                                                  1,
                                                )
                                                this.setState({ trip })
                                              }}
                                              className='mt-4 cursor-pointer'
                                            >
                                              close
                                            </Icon>
                                          </div>
                                        )
                                      }
                                    })}
                                  </div>
                                </div>
                              </CardContent>
                            </TripTabContainer>
                          )}
                          {tripValue == 1 && (
                            <TripTabContainer>
                              <CardContent
                                className={classNames(classes.info, 'px-16')}
                              >
                                <div className='pt-16'>
                                  <div className='flex'>
                                    <div className='min-w-36 pt-12 mt-6'>
                                      <Icon className='text-24' color='action'>
                                        calendar_today
                                      </Icon>
                                    </div>
                                    {!trip.ScheduledTime ? (
                                      <DatePicker
                                        variant='inline'
                                        inputVariant='outlined'
                                        label='Scheduled Date'
                                        id='scheduledDate'
                                        format='MM/DD/YYYY'
                                        ref={e => (this.datePicker = e)}
                                        value={trip.ScheduledDate}
                                        onChange={
                                          this.handleAdminTripDateChange
                                        }
                                        fullWidth
                                      />
                                    ) : (
                                      <DateTimePicker
                                        variant='inline'
                                        inputVariant='outlined'
                                        label='Scheduled Date'
                                        id='scheduledDate'
                                        format='MM/DD/YYYY h:mm A'
                                        ref={e => (this.datePicker = e)}
                                        value={trip.ScheduledTime}
                                        onChange={
                                          this.handleAdminTripDateChange
                                        }
                                        fullWidth
                                      />
                                    )}
                                    {/* <DateTimePicker
                                                                            inputVariant="outlined"
                                                                            className={classes.formControl}
                                                                            label="Scheduled Date"
                                                                            id="scheduledDate"
                                                                            format="MM/DD/YYYY h:mm A"
                                                                            value={trip.ScheduledTime}
                                                                            onChange={this.handleAdminTripDateChange}
                                                                            margin="dense"
                                                                            fullWidth
                                                                        /> */}
                                  </div>

                                  <div className='flex w-full justify-end'>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={!trip.ScheduledTime}
                                          name='anytime'
                                          onChange={this.handleAnytimeChange}
                                          color='primary'
                                        />
                                      }
                                      label='Anytime'
                                    />
                                  </div>
                                </div>
                              </CardContent>
                            </TripTabContainer>
                          )}
                          {tripValue == 2 && (
                            <TripTabContainer>
                              <CardContent
                                className={classNames(classes.info, 'px-16')}
                              >
                                <div className='pt-16'>
                                  <div className='flex'>
                                    <div className='min-w-36 pt-12 mt-6'>
                                      <Icon className='text-24' color='action'>
                                        {statusProps[trip.Status].icon}
                                      </Icon>
                                    </div>
                                    <TextField
                                      className={classes.formControl}
                                      label='Status'
                                      id='Status'
                                      name='Status'
                                      margin='dense'
                                      value={trip.Status}
                                      onChange={this.handleAdminTripChange}
                                      variant='outlined'
                                      select
                                      fullWidth
                                    >
                                      {statusProps.map((value, index) => (
                                        <MenuItem key={index} value={index}>
                                          {`${value.text}`}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                </div>
                              </CardContent>
                            </TripTabContainer>
                          )}
                          {tripValue == 3 && (
                            <TripTabContainer>
                              <CardContent
                                className={classNames(classes.info, 'px-16')}
                              >
                                <div className='pt-16'>
                                  <div className='flex'>
                                    <div className='min-w-36 pt-12 mt-6'>
                                      <Icon className='text-24' color='action'>
                                        access_time
                                      </Icon>
                                    </div>
                                    <TextField
                                      className={classes.formControl}
                                      label='Duration'
                                      id='duration'
                                      name='Duration'
                                      margin='dense'
                                      value={trip.Duration}
                                      onChange={this.handleAdminTripChange}
                                      variant='outlined'
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </CardContent>
                            </TripTabContainer>
                          )}
                          {tripValue === 4 && (
                            <TripTabContainer>
                              <CardContent
                                className={classNames(classes.info, 'px-16')}
                              >
                                <div className='w-full my-12'>
                                  <div className='w-full cursor-pointer'>
                                    <ListItem
                                      classes={{ root: classes.liRoot }}
                                      className='relative mb-8 cursor-pointer'
                                    >
                                      <Avatar
                                        style={{ marginRight: 8 }}
                                        classes={{ root: classes.avatarRoot }}
                                        className={classNames(
                                          classes.scopeAvatar,
                                          classes.assigned,
                                        )}
                                      >
                                        1
                                      </Avatar>
                                      <ListItemText
                                        classes={{ root: classes.liRoot }}
                                        primary={
                                          <div className='w-3/4 overflow-hidden'>
                                            <div
                                              className='font-bold text-14'
                                              style={{
                                                overflow: 'hidden',
                                                width: '100%',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                              }}
                                            >
                                              {service.Description}
                                            </div>
                                          </div>
                                        }
                                        secondary={
                                          <span className='font-bold text-12'>
                                            {
                                              <Typography variant='caption'>
                                                {data.WorkOrder.Description}
                                              </Typography>
                                            }
                                          </span>
                                        }
                                      />
                                      <Typography
                                        variant='caption'
                                        className='font-bold absolute pin-r pin-t mr-4'
                                        style={{ marginTop: 2 }}
                                      >
                                        {data.WorkOrder.DueBy &&
                                          new Date(
                                            data.WorkOrder.DueBy,
                                          ).toLocaleDateString('en-US')}
                                      </Typography>
                                    </ListItem>
                                  </div>
                                </div>
                              </CardContent>
                            </TripTabContainer>
                          )}
                          {dueDateCompare && (
                            <div className='w-full p-16 py-0'>
                              <div
                                className='rounded mb-12 w-full'
                                style={{
                                  border: '2px dashed #F44336',
                                  padding: '8px 16px',
                                }}
                              >
                                <div style={{ padding: '4px 16px' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Icon className='text-16' color='error'>
                                      warning
                                    </Icon>
                                    <span
                                      style={{
                                        verticalAlign: 'top',
                                        color: '#F44336',
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                      }}
                                    >
                                      WARNING
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <span className='text-12'>
                                      <i>
                                        Trip Date is greater than Due By Date
                                      </i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {trip.Technicians.length > 0 &&
                            trip.ScheduledDate &&
                            trip.Duration && (
                              <div className='w-full px-8 pb-8'>
                                <Button
                                  className='w-full'
                                  variant='contained'
                                  color='primary'
                                  onClick={this.createTrip}
                                >
                                  Create Trip
                                  {trip.Technicians.length > 1 ? 's' : ''}
                                </Button>
                              </div>
                            )}
                        </div>
                      )}
                    </CardActions>
                  )}
                </Card>
              </DialogContent>
            </FuseAnimate>
          </div>
        </div>
        <Hidden smDown>
          <Stepper
            nonLinear
            className={classes.workflow}
            activeStep={activeStep}
          >
            {steps.map((label, index) => (
              <Step key={label.Name}>
                <StepButton
                  icon={
                    <Icon
                      color={
                        this.props.activeStep == index
                          ? 'primary'
                          : this.state.completed[index]
                            ? 'secondary'
                            : 'disabled'
                      }
                    >
                      {label.Icon}
                    </Icon>
                  }
                  onClick={this.handleStep(index)}
                  completed={this.state.completed[index]}
                >
                  {label.Name}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div className={classes.navControl}>
            {this.allStepsCompleted() ? (
              <div>
                <Button onClick={this.handleClearWindow}>Reset</Button>
              </div>
            ) : (
              <div>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.handleNext}
                    disabled={
                      (activeStep === 0 &&
                        !this.props.data.Customer.Customer) ||
                      (activeStep === 1 &&
                        (!this.props.data.Customer.Customer ||
                          !this.props.data.Site.Site))
                    }
                    className={classes.button}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Hidden>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage: showMessage,
      setCustomerData: Actions.setCustomerData,
      setSiteData: Actions.setSiteData,
      clearCallHandlerData: Actions.clearCallHandlerData,
      clearWorkOrderData: Actions.clearWorkOrderData,
      setCallHandlerStep: Actions.setCallHandlerStep,
      saveCallDraft: Actions.saveCallDraft,
      openCallDraft: Actions.openCallDraft,
      setCallHandlerData: Actions.setCustomerData,
      openCustomer: openEditCustomerDialog,
      openSite: openEditCustomerSiteDialog,
      openWorkOrder: openEditWorkOrderDialog,
      createTrip,
      openEditAgreementDialog,
      openNewAgreementDialog,
      //createMap: Actions.createMap,
    },
    dispatch,
  )
}

function mapStateToProps({ callHandlerApp, emailTemplateApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    googleMapsApiKey: googleMapsApiKey,
    data: callHandlerApp.callHandler.data,
    drafts: callHandlerApp.callHandler.drafts,
    activeStep: callHandlerApp.callHandler.activeStep,
    completed: callHandlerApp.callHandler.completed,
    callID: callHandlerApp.callHandler.id,
    arrivalWindows: spReducers.arrivalWindows,
    formOfPayments: spReducers.formOfPayments,
    businessUnits: spReducers.businessUnits,
    departments: spReducers.departments,
    divisions: spReducers.divisions,
    serviceTypes: spReducers.serviceTypes,
    rateTemplates: spReducers.rateTemplates,
    services: spReducers.services,
    technicians: spReducers.technicians,
    securables: spReducers.userProfiles.User.Data.Securables,
    templates: emailTemplateApp.templates.Templates,
    companies: spReducers.companies.List,
    callHandlerData: callHandlerApp.callHandler,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CallHandler),
)
