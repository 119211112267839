import axios from 'axios/index';
import FuseUtils from '@fuse/FuseUtils';
import _ from '@lodash';

import { showMessage, setCalendarData } from 'store/actions';

export const GET_EVENTS = '[CALENDAR APP] GET EVENTS';
export const OPEN_NEW_EVENT_DIALOG = '[CALENDAR APP] OPEN NEW EVENT DIALOG';
export const CLOSE_NEW_EVENT_DIALOG = '[CALENDAR APP] CLOSE NEW EVENT DIALOG';
export const OPEN_EDIT_EVENT_DIALOG = '[CALENDAR APP] OPEN EDIT EVENT DIALOG';
export const CLOSE_EDIT_EVENT_DIALOG = '[CALENDAR APP] CLOSE EDIT EVENT DIALOG';
export const ADD_EVENT = '[CALENDAR APP] ADD EVENT';
export const UPDATE_EVENT = '[CALENDAR APP] UPDATE EVENT';
export const REMOVE_EVENT = '[CALENDAR APP] REMOVE EVENT';

export function getEvents() {
    const request = axios.get('/api/calendar-app/events');

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_EVENTS,
                payload: response.data
            })
        );
}


export function openNewEventDialog(data) {
    return {
        type: OPEN_NEW_EVENT_DIALOG,
        data
    }
}

export function closeNewEventDialog() {
    return {
        type: CLOSE_NEW_EVENT_DIALOG
    }
}

export function openEditEventDialog(data) {
    return {
        type: OPEN_EDIT_EVENT_DIALOG,
        data
    }
}

export function closeEditEventDialog() {
    return {
        type: CLOSE_EDIT_EVENT_DIALOG
    }
}


export function addEvent(newEvent) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/CalendarEvent`, newEvent);

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Calendar Event successfully added.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const state = getState();
                const data = _.cloneDeepWith(state.spReducers.calendar.data);
                const { month } = state.spReducers.calendar;
                const index = _.findIndex(data.Events, { ID: response.data.ID });
                if (index > -1) {
                    data.Events.splice(index, 1, response.data);
                } else {
                    data.Events.push(response.data);
                }
                    dispatch(setCalendarData(data, month));
            }
        });
    };
}

export function updateEvent(event) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/CalendarEvent?Event=${event.ID}`, event);

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Calendar Event successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const state = getState();
                const data = _.cloneDeepWith(state.spReducers.calendar.data);
                const { month } = state.spReducers.calendar;
                const index = _.findIndex(data.Events, { ID: response.data.ID });
                window["warn"]('Updated Calendar Event: ', response.data, data, index);
                if (index > -1) {
                    data.Events.splice(index, 1, response.data);
                }
                dispatch(setCalendarData(data, month));
            }
        });
    };
}

export function removeEvent(eventId) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/CalendarEvent?Event=${eventId}`);

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Calendar Event successfully removed.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const state = getState();
                const data = _.cloneDeepWith(state.spReducers.calendar.data);
                const { month } = state.spReducers.calendar;
                const index = _.findIndex(data.Events, { ID: eventId });
                if (index > -1) {
                    data.Events.splice(index, 1);
                }
                dispatch(setCalendarData(data, month));
            }

        });
    }
}