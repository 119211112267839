export const slots = [
  "12A",
  "1230A",
  "1A",
  "130A",
  "2A",
  "230A",
  "3A",
  "330A",
  "4A",
  "430A",
  "5A",
  "530A",
  "6A",
  "630A",
  "7A",
  "730A",
  "8A",
  "830A",
  "9A",
  "930A",
  "10A",
  "1030A",
  "11A",
  "1130A",
  "12P",
  "1230P",
  "1P",
  "130P",
  "2P",
  "230P",
  "3P",
  "330P",
  "4P",
  "430P",
  "5P",
  "530P",
  "6P",
  "630P",
  "7P",
  "730P",
  "8P",
  "830P",
  "9P",
  "930P",
  "10P",
  "1030P",
  "11P",
  "1130P",
];
