import React from "react";
import { FormControlLabel, Icon, Checkbox, Typography } from "@material-ui/core";


const InvoiceAddPaymentComponent = ({ due,
    readOnly,
    isFinanced,
    isApprovedAccount,
    onIsFinancedChange,
    onIsApprovedAccountChange,
    onAddPaymentClick }) => {
    return <div className='w-full flex justify-between'>
        <span className='flex mr-4 font-bold items-center'>Payments:</span>
        {(isFinanced || (
            !readOnly &&
            Boolean(due) &&
            !isNaN(due) &&
            due.toFixed(2) > 0)) && (
                <FormControlLabel
                    className='ml-auto'
                    control={<Checkbox
                        checked={isFinanced}
                        onChange={onIsFinancedChange}
                        disabled={readOnly || !Boolean(due) || isNaN(due) || due.toFixed(2) === 0}
                    />}
                    label={<Typography className='text-12'>Is financed?</Typography>}
                />
            )}
        {(isApprovedAccount || (
            !readOnly &&
            Boolean(due) &&
            !isNaN(due) &&
            due.toFixed(2) > 0)) && (
                <FormControlLabel
                    control={<Checkbox
                        checked={isApprovedAccount}
                        onChange={onIsApprovedAccountChange}
                        disabled={readOnly || !Boolean(due) || isNaN(due) || due.toFixed(2) === 0}
                    />}
                    label={<Typography className='text-12'>Is an approved account?</Typography>}
                />)}
        {!readOnly &&
            Boolean(due) &&
            !isNaN(due) &&
            due.toFixed(2) > 0 && (
                <div
                    className={`flex no-print items-center ${isFinanced ||
                        isApprovedAccount ? ' opacity-50 cursor-not-allowed' : ''}`}
                    onClick={onAddPaymentClick}
                >
                    <Icon
                        className={`text-14 ml-4 align-middle float-right no-print ${isFinanced ||
                            isApprovedAccount ? ' opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                        color='primary'
                    >
                        add_circle_outline
                    </Icon>
                    <strong className={`ml-4 text-12 ${isFinanced ||
                        isApprovedAccount ? ' opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                        Add Payment
                    </strong>
                </div>
            )}
    </div>
}

export default InvoiceAddPaymentComponent;