import React from 'react';
import {Redirect} from 'react-router-dom';
import Boards from './boards/Boards';
import Board from './board/Board';

export const ScrumboardAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/project-tracking/boards/:Co/:boardId/:boardUri?',
            component: Board
        },
        {
            path     : '/apps/project-tracking/boards',
            component: Boards
        },
        {
            path     : '/apps/project-tracking',
            component: () => <Redirect to="/apps/project-tracking/boards"/>
        }
    ]
};
