// @ts-nocheck

import 'babel-polyfill'
import 'typeface-muli'

import './react-table-defaults'
import './styles/index.css'

import MomentUtils from '@date-io/moment'
import {
  FuseAuthorization,
  FuseLayout,
  FuseMessage,
  FuseNotification,
  FuseTheme,
} from '@fuse'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider } from '@material-ui/styles'
import 'babel-polyfill'
import { create } from 'jss'
import jssExtend from 'jss-extend'
import ChatPanel from 'main/chatPanel/ChatPanel'
import ErrorHandler from 'main/content/components/error-handler/ErrorHandler'
import LoginPage from 'main/content/pages/auth/login/LoginPage'
import QuickPanel from 'main/quickPanel/QuickPanel'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter, Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistor, store } from 'store.ts'
import 'typeface-muli'
import { routes } from './fuse-configs/fuseRoutesConfig'
import history from './history'
import MainFooter from './main/MainFooter'
import MainNavbarContent from './main/MainNavbarContent'
import MainNavbarHeader from './main/MainNavbarHeader'
import MainNavbarSubHeader from './main/MainNavbarSubHeader'
import MainToolbar from './main/MainToolbar'
import './react-table-defaults'
import { register, unregister } from './registerServiceWorker'
import './styles/index.css'
import { version } from './version'
import * as Sentry from '@sentry/react'
import { FuseUtils } from './@fuse'
import axios from 'axios'
import FeatureFlagsProvider from './providers/featureFlagsProvider'

// Entrypoint for the app, this is where the app starts
const isProd = process.env.NODE_ENV === 'production'
const isNext = process.env.NODE_ENV === 'next'
const sentryDSN = isProd
  ? 'https://a5852f78209e4f958e279b024973bf87@o365482.ingest.sentry.io/5779524'
  : isNext
    ? 'https://8ddba867d42b46aa8eba928c93df2a65@o365482.ingest.sentry.io/5042854'
    : null

// Only init Sentry in production or CI mode
if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: window.cordova
      ? []
      : [
          new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
          new Sentry.Replay(),
        ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /servicepointpro.com/,
      /ngrok.io/,
      /spp.qa/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  // force Axios non 2xx response to be logged
  // so they are tied to server side events
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      Sentry.captureException(error)
      return Promise.reject(error)
    },
  )
}

const AppRouter = window.cordova ? HashRouter : Router

const startApp = () => {
  if (module.hot) {
    module.hot.accept()
  }

  const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
  })

  jss.options.insertionPoint = document.getElementById('jss-insertion-point')
  const generateClassName = createGenerateClassName()

  ReactDOM.render(
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <Provider store={store}>
        <FeatureFlagsProvider>
          <PersistGate
            loading={
              <div id='sp-splash-screen'>
                <div className='back'></div>
                <div className='cog'></div>
                <div className='splash-logo'></div>
              </div>
            }
            persistor={persistor}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AppRouter
                hashType={window.cordova ? 'noslash' : undefined}
                history={history}
              >
                <FuseAuthorization routes={routes}>
                  <FuseTheme>
                    <FuseMessage />
                    <FuseNotification />
                    <LoginPage />
                    <ErrorHandler routes={routes} noReport={true}>
                      <FuseLayout
                        routes={routes}
                        toolbar={<MainToolbar />}
                        navbarHeader={<MainNavbarHeader />}
                        navbarSubHeader={<MainNavbarSubHeader />}
                        navbarContent={<MainNavbarContent />}
                        footer={<MainFooter />}
                        rightSidePanel={
                          <React.Fragment>
                            <ChatPanel />
                            <QuickPanel />
                          </React.Fragment>
                        }
                      ></FuseLayout>
                    </ErrorHandler>
                  </FuseTheme>
                </FuseAuthorization>
              </AppRouter>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </FeatureFlagsProvider>
      </Provider>
    </StylesProvider>,
    document.getElementById('root'),
  )
  if (!window.cordova) {
    register(version)
  } else {
    unregister()
  }
}

if (!window.cordova) {
  startApp()
} else {
  document.addEventListener('deviceready', startApp, false)
}
