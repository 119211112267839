import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Badge, Icon, Button, Fab, InputAdornment, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    logo: {},
    logoIcon: {
        fontSize: '32px!important'
    },
    logoText: {
        fontSize: 24
    },
    addButton: {
        width: 32,
        height: 32,
        marginTop: 32,
        marginLeft: 16,
        marginRight: 0,
        minHeight: 'unset'
    },
    badge: {
        marginTop: -2,
        marginRight: -2,
        minWidth: 16,
        height: 16,
        fontSize: 'x-small',
        fontWeight: 'bold',
    },
    accountSelect: {},
    accountSelectMenu: {}
});

class CallHandlerRightSidebarHeader extends Component {

    state = {
        statuses: [],
        stations: [],
        agent: {
            Status: 0,
            SessionID: null,
            Data: {
                Station: {
                    Phone: '',

                },
            }
        },
        dialing: false,
        status: 0,
        station: '',
    };

    componentDidMount = () => {
        const { statuses, stations, agent } = this.props;
        if (agent && stations.length > 0) {
            const status = agent.Status;
            const station = agent.Data.Station.Phone;
            this.setState({ statuses, stations, agent, status, station });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { statuses, stations, agent } = this.props;
        if (agent && stations.length > 0) {
            const status = agent.Status;
            const station = agent.Data.Station.Phone;
            if (!_.isEqual(this.props, prevProps)) {
                this.setState({ statuses, stations, agent, status, station, dialing: false, });
            }
        }
    }

    // setCompany = (ev) => {
    //     const { Co } = this.state;
    //     this.props.setCompany(ev.target.value, Co);
    // }
    setStatus = (ev) => {
        const { agent } = this.state;
        this.setState({ ...this.state, status: ev.target.value }, () => {
            agent.Status = this.state.status;
            this.props.setAgentStatus(this.props.Co, agent);
        });
    }

    setStation = (ev) => {
        const { agent, stations } = this.state;
        this.setState({ ...this.state, station: ev.target.value }, () => {
            let station = {};
            for (var i = 0; i < stations.length; i++) {
                if (stations[i].Phone == this.state.station) {
                    station = stations[i];
                    station.LastActiveDate = new Date();
                    this.props.setAgentStation(this.props.Co, station);
                }
            }
        });
    }

    startSession = (e) => {
        e.stopPropagation();
        const { agent } = this.state;
        const { Co } = this.props;
        this.setState({ ...this.state, dialing: true, }, () => { this.props.startAgentSession(Co, agent) });

    }

    endSession = (e) => {
        e.stopPropagation();
        const { agent } = this.state;
        this.props.endAgentSession(agent)
    }

    render() {
        const { classes, securables, user } = this.props;
        const { statuses, stations, agent, status, station } = this.state;
        const accessLevel = _.find(securables, { Securable: "call-handler" });

        const { Queues } = user.Data;

        const calls = _.filter(this.props.calls, (o) => { return _.find(Queues, (q) => { return q.Queue == o.Queue && q.TextEnabledYN === (o.Type === "text" ? "Y" : q.TextEnabledYN) }) })

        if ((accessLevel && accessLevel.AccessLevel !== "R") && stations && stations.length > 0) {
            return (
                <div className={classNames(classes.root, "flex flex-col justify-center h-full p-24")}>
                    <FuseAnimate animation="transition.slideUpIn" delay={300}>
                        <React.Fragment>
                            <div className="flex" style={{ marginRight: -8, }}>
                                <TextField
                                    id="agent-station"
                                    select
                                    label={"Station"}
                                    className={classNames(classes.accountSelect, "w-5/6")}
                                    value={station}
                                    onChange={this.setStation}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.accountSelectMenu
                                        }
                                    }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Select Station"
                                    margin="normal"
                                    fullWidth
                                >
                                    {stations.map((value, index) => (
                                        <MenuItem key={index} value={value.Phone}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {
                                    this.state.agent && !this.state.agent.SessionID && this.state.agent.Status < 1 && !this.state.dialing ?
                                        <Tooltip title="Connect to Queue">
                                            <Fab
                                                color="secondary"
                                                aria-label="queue"
                                                className={classes.addButton}
                                                onClick={(e) => { e.stopPropagation(); }}
                                            >
                                                <Badge color="error" classes={{ root: classes.badge }} badgeContent={calls ? calls.length : 0} classes={{ badge: classes.badge }}>
                                                    <Icon className="text-20">phone</Icon>
                                                </Badge>
                                            </Fab>
                                        </Tooltip> :
                                        this.state.agent && ((!this.state.agent.SessionID && this.state.dialing) || this.state.agent.Status <= 2) ?
                                            <Tooltip title="Connecting to Queue...">
                                                <Fab
                                                    color="secondary"
                                                    aria-label="queue"
                                                    className={classes.addButton}
                                                    onClick={(e) => { e.stopPropagation(); }}
                                                    style={{ backgroundColor: '#3f3f3f', color: 'white', paddingTop: 2, }}
                                                >
                                                    <Badge color="secondary" classes={{ root: classes.badge }} badgeContent={calls ? calls.length : 0} classes={{ badge: classes.badge }}>
                                                        <Icon className="text-20">phone_callback</Icon>
                                                    </Badge>
                                                </Fab>
                                            </Tooltip> :
                                            <Tooltip title="Disconnect from Queue">
                                                <Fab
                                                    aria-label="queue"
                                                    className={classes.addButton}
                                                    onClick={(e) => { e.stopPropagation(); }}
                                                    style={{ backgroundColor: 'red', color: 'white', paddingTop: 2, }}
                                                >
                                                    <Badge color="secondary" classes={{ root: classes.badge }} badgeContent={calls ? calls.length : 0} classes={{ badge: classes.badge }}>
                                                        <Icon style={{ transform: 'rotate(135deg)' }} className="text-20">phone</Icon>
                                                    </Badge>
                                                </Fab>
                                            </Tooltip>
                                }
                            </div>
                            {/*<Typography variant="caption">{agent.SessionID && `Session ID: ${agent.SessionID}`}</Typography>*/}
                            <TextField
                                id="agent-status"
                                select
                                label={"Status"}
                                className={classNames(classes.accountSelect, "w-5/6")}
                                value={status}
                                onChange={this.setStatus}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.accountSelectMenu
                                    },
                                }}
                                onClick={(e) => { e.stopPropagation(); }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>menu</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Select Status"
                                margin="normal"
                                fullWidth
                            >
                                {statuses.map((value, index) => (
                                    <MenuItem key={index} value={value.Status} disabled={value.Status > 2 && value.Status < 5}>
                                {value.Name}
                            </MenuItem>
                                ))}
                        </TextField>
                    </React.Fragment>
                </FuseAnimate>
                </div >
            );
        } else {
            return <div></div>
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAgentStatus: Actions.setAgentStatus,
        setAgentStation: Actions.setAgentStation,
        startAgentSession: Actions.startAgentSession,
        endAgentSession: Actions.endAgentSession,
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        statuses: spReducers.agentStatus.Statuses,
        stations: spReducers.userProfiles.User.Data.Stations,
        calls: spReducers.callHandler.Calls,
        agent: spReducers.agentStatus.Agent,
        securables: spReducers.userProfiles.User.Data.Securables,
        user: spReducers.userProfiles.User,
    }
}
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CallHandlerRightSidebarHeader));
