import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedPayTermIds: [],
    routeParams       : {},
    payTermDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const payTermsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_PAY_TERMS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_PAY_TERMS:
        {

            const payTermId = action.payTermId;

            let selectedPayTermIds = [...state.selectedPayTermIds];

            if ( selectedPayTermIds.find(id => id === payTermId) !== undefined )
            {
                selectedPayTermIds = selectedPayTermIds.filter(id => id !== payTermId);
            }
            else
            {
                selectedPayTermIds = [...selectedPayTermIds, payTermId];
            }

            return {
                ...state,
                selectedPayTermIds: selectedPayTermIds
            };
        }
        case Actions.SELECT_ALL_PAY_TERMS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedPayTermIds = arr.map(payTerms => payTerms.id);

            return {
                ...state,
                selectedPayTermIds: selectedPayTermIds
            };
        }
        case Actions.DESELECT_ALL_PAY_TERMS:
        {
            return {
                ...state,
                selectedPayTermIds: []
            };
        }
        case Actions.OPEN_NEW_PAY_TERM_DIALOG:
        {
            return {
                ...state,
                payTermDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_PAY_TERM_DIALOG:
        {
            return {
                ...state,
                payTermDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_PAY_TERM_DIALOG:
        {
            return {
                ...state,
                payTermDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_PAY_TERM_DIALOG:
        {
            return {
                ...state,
                payTermDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default payTermsReducer;
