import {combineReducers} from 'redux';
import vendors from './vendors.reducer';
import user from './user.reducer';

const vendorsAppReducers = combineReducers({
    vendors,
    user
});

export default vendorsAppReducers;
