import _ from '@lodash';
import { Button, Icon, Menu, MenuItem, Switch, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import CatalogOptionBoardOptionDialog from './CatalogOptionBoardOptionDialog';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';



const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    bgGrey: {
        backgroundColor: '#3f3f3f',
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    savings: {
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        width: 72,
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 'auto',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        lineHeight: '10px',
        textAlign: 'center'
    },
    selectedSavings: {
        top: 31,
        left: 0,
        right: 0,
        position: 'absolute',
        width: 72,
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: '#3f3f3f',
        color: '#fff',
        margin: 'auto',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        lineHeight: '10px',
        textAlign: 'center'
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
});

var newOptionState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Option: null,
    Name: null,
    Description: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    PriceTotal: 0.00,
    AttachmentID: null,
    Data: {
        Components: [],
        ErrMsg: null,
    },
    anchorEl: null,
    optionEl: null,

}

class OptionBoardDialog extends Component {

    state = { ...newOptionState };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidMount() {
        const { data, catalog, optionBoard } = this.props;

        this.setState({ ...this.state, ...data, catalog, optionBoard });
    }

    componentDidUpdate = (prevProps) => {
        const { data, catalog, optionBoard } = this.props;

        if (!_.isEqual(data, prevProps.data) || !_.isEqual(catalog, prevProps.catalog) || !_.isEqual(optionBoard, prevProps.optionBoard)) {
            this.setState({ ...this.state, ...data, catalog, optionBoard });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    selectOption = () => {
        this.props.onSelected(this.state);
    }

    handleSelect = () => {
        if (this.props.selected) {
            if (this.props.onDeselected) {
                this.props.onDeselected();
            }
        } else {
            if (this.props.onSelected) {
                this.props.onSelected(this.state);
            }
        }
    }

    editOption = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.props.onEdit(this.state);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    saveOption = () => {
        this.props.onChange(this.state);
    }

    viewOption = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({ ...this.state, viewOption: true });
    }

    closeViewOption = () => {
        this.setState({ ...this.state, viewOption: false });
    }

    copySelectedOption = (e) => {
        e.stopPropagation();
        const { options, data } = this.props;
        const { copyOption, Data } = this.state;
        const option = _.find(options, { Option: copyOption });
        if (option && option.Data && option.Data.Components) {
            option.Data.Components.map((value) => {
                Data.Components.push({
                    ..._.cloneDeepWith(value),
                    Option: data.Option
                })
            });
            this.setState({ ...this.state, anchorEl: null, optionEl: null, Data: { ...Data } });
        }
    }

    editSavingsType = (e) => {
        e.stopPropagation();
        if (!this.props.estimate) {
            this.setState({ editSavingsType: !this.state.editSavingsType });
        }
    }

    render() {
        const { anchorEl, optionEl } = this.state;
        const { classes, selected, catalog, estimate, options, optionBoard, hidePricing } = this.props;
        const components = _.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y');
        var savingsType = (this.state.SavingsType || optionBoard.DefaultSavingsType);
        const fullPrice = _.sumBy(components, (o) => o.Qty * (o.CustomPrice || o.Data.Assembly.Price));
        const highest = _.maxBy(components, (o) => (o.CustomPrice || o.Data.Assembly.Price));
        let addOnPrice = _.sumBy(components, (o) => { if (o.Component === highest.Component) { return (o.CustomPrice || o.Data.Assembly.Price) + ((o.Qty - 1) * (o.CustomPrice || o.Data.Assembly.AddOnPrice)) } else { return o.Qty * (o.CustomPrice || o.Data.Assembly.AddOnPrice) } });
        let memberPrice = _.sumBy(components, (o) => (o.Qty * (o.CustomMemberPrice || o.Data.Assembly.MemberPrice)));
        let price = (savingsType === 'M' ? memberPrice : savingsType === "A" ? addOnPrice : fullPrice);
        const savings = (fullPrice - price);
        const prc = this.formatDollars(price);
        const otherOptions = options ? _.filter(options, (o) => o.Data && o.Data.Components && o.Data.Components.length > 0) : [];
        window["warn"](`Option #${this.state.Option}: `, components, price, memberPrice, addOnPrice, fullPrice);
        return (
            <div onClick={this.handleSelect} className={`relative min-w-256 w-1/3 min-h-512 rounded-12 mx-12 py-8 cursor-pointer bg-white ${selected ? "shadow-lg" : "shadow"}`} style={{ border: '1px solid lightgrey', transform: selected ? 'scale(1.1)' : undefined }}>
                {selected &&
                    <div className={classNames("w-full text-center py-4 pin-t pin-l pin-r absolute rounded-t-12", classes.blackButton)}><Icon className="mr-4 text-20 align-middle mb-4" color="secondary" style={{ marginLeft: -16 }}>check_circle_outline</Icon><span className="font-bold">Recommended</span></div>
                }
                {(savingsType && savingsType !== "N") &&
                    <div onClick={this.editSavingsType} className={selected ? classes.selectedSavings : classes.savings} style={savingsType === "M" ? { width: 84 } : undefined}>
                        <span className="text-14 font-700">{this.formatDollars(savings).split('.')[0]}</span><br />
                        <span style={{ fontSize: 8 }}>{savingsType === "M" ? "Member " : ""}Savings</span>
                    </div>
                }
                {this.state.editSavingsType &&
                    <div className="px-24">
                        <TextField
                            className={classNames(selected ? "mt-72" : "mt-36")}
                            label="Savings Type"
                            id="savingsType"
                            name="SavingsType"
                            value={savingsType || ''}
                            onChange={(e) => { this.setState({ ...this.state, SavingsType: e.target.value, editSavingsType: false }) }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            select
                        >
                            <MenuItem value="N">
                                None
                            </MenuItem>
                            <MenuItem value="A">
                                Add-On
                            </MenuItem>
                            <MenuItem value="M">
                                Member
                            </MenuItem>
                        </TextField>
                    </div>
                }
                <Typography variant="h6" className={classNames("text-16 w-full text-center p-12 pb-0", this.state.editSavingsType ? "" : selected && (savingsType && savingsType !== "N") ? "pt-72" : "pt-36")} color="inherit">
                    {this.state.Name}
                </Typography>
                <div className="px-36 mb-16 text-10" onClick={(e) => e.stopPropagation()}>
                    <TextField
                        className="text-10"
                        label={!this.state.Description ? "Description" : undefined}
                        id="description"
                        name="Description"
                        value={this.state.Description}
                        onChange={this.handleChange}
                        InputProps={{
                            className: !this.state.Description ? "text-10" : "text-10 p-0",
                            style: {
                                lineHeight: '16px',
                            },
                            disableUnderline: Boolean(this.state.Description)
                        }}
                        inputProps={{
                            className: "text-10 text-center overflow-hidden",
                            style: { color: '#333' }
                        }}
                        onBlur={this.saveOption}
                        margin="dense"
                        // required={!this.state.Description}
                        multiline
                        fullWidth
                        disabled={Boolean(estimate)}
                    />
                </div>
                {/* <Typography variant="h6" className="text-10 w-full text-center mb-20 px-36 font-400" color="inherit">
                    {this.state.Description}
                </Typography> */}
                {!hidePricing &&
                    <Typography variant="h6" className={classNames("text-24 w-full text-center mt-8 text-white py-8", selected ? classes.bgGrey : classes.bgPrimary)} style={{ lineHeight: '4.2rem' }}>
                        <span>{prc.split('.')[0]}</span>
                        <span className="text-14 underline align-top ml-4">{prc.split('.')[1]}</span>
                    </Typography>
                }
                {components && components.length > 0 &&
                    <div className="w-full p-16 px-24" style={{ marginBottom: -28 }}>
                        {
                            components.map((component, index) => {
                                const { Assembly } = component.Data;
                                return (
                                    <div className="w-full text-center mb-8 font-light text-10">
                                        <b className="font-bold">{component.Qty}</b>{` - ${Assembly.Name}`}
                                    </div>
                                );
                            })
                        }
                    </div>
                }

                {Boolean(anchorEl) &&
                    <Menu
                        id="option-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(e) => { e.stopPropagation(); this.setState({ ...this.state, anchorEl: null, optionEl: null }) }}
                    >
                        <MenuItem onClick={(e) => { e.stopPropagation(); this.setState({ ...this.state, anchorEl: null, optionEl: anchorEl }) }}><Icon className="mr-8">file_copy</Icon>Copy from Option</MenuItem>
                        <MenuItem onClick={(e) => { e.stopPropagation(); this.setState({ ...this.state, anchorEl: null, optionEl: null }, this.editOption) }}><Icon className="mr-8">add_circle_outline</Icon>Add New</MenuItem>
                    </Menu>
                }

                {Boolean(optionEl) &&
                    <Menu
                        id="option-copy-menu"
                        anchorEl={optionEl}
                        keepMounted
                        open={Boolean(optionEl)}
                        onClose={(e) => { e.stopPropagation(); this.setState({ ...this.state, anchorEl: null, optionEl: null }) }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-12 min-w-320 max-w-320">
                            <Typography className="flex items-center flex-1 font-bold mb-12"><Icon className="mr-8" color="primary">file_copy</Icon>Copy from Option</Typography>
                            <TextField
                                className={classNames("mb-12")}
                                label="Source"
                                id="source"
                                name="Source"
                                value={this.state.copyOption || ''}
                                onChange={(e) => { e.stopPropagation(); this.setState({ ...this.state, copyOption: e.target.value }) }}
                                variant="outlined"
                                select
                                fullWidth
                            >
                                {otherOptions.map((value, index) => {
                                    return (
                                        <MenuItem key={index} value={value.Option}>
                                            {value.Name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            <Button
                                variant="contained"
                                className="w-full"
                                color="primary"
                                onClick={this.copySelectedOption}
                            >
                                Copy Tasks / Assemblies
                            </Button>
                        </div>
                    </Menu>
                }

                {!estimate &&
                    <React.Fragment>
                        {components && components.length < 1 ?
                            <Typography onClick={(e) => { e.stopPropagation(); if (otherOptions.length > 0) { this.setState({ ...this.state, anchorEl: e.target }) } else { this.editOption(); } }} variant="h6" className="text-10 w-full text-center px-24 mt-12" color="inherit">
                                <Icon className="mr-4 text-16 align-middle mb-4" color="primary">add_circle_outline</Icon>Add Tasks / Assemblies
                            </Typography> :
                            <Typography onClick={this.editOption} variant="h6" className="text-10 w-full text-center px-24 mt-12" color="inherit">
                                <Icon className="mr-4 text-16 align-middle mb-4" color="primary">edit</Icon>Edit Tasks / Assemblies
                            </Typography>
                        }
                    </React.Fragment>
                }
                <div className="w-full pin-b pin-l pin-r absolute p-12">
                    <Button
                        variant="contained"
                        className={classNames("h-32 w-full", selected ? classes.blackButton : "")}
                        color="primary"
                        onClick={this.viewOption}
                    // disabled={!this.canBeSubmitted()}
                    >
                        View Option
                    </Button>
                </div>
                <CatalogOptionBoardOptionDialog data={{ ...this.state }} optionBoard={{ ...optionBoard }} selected={selected} catalog={{ ...catalog }} open={this.state.viewOption} onClose={this.closeViewOption} />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        impress,
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(OptionBoardDialog));
