import axios from 'axios/index';

export const SET_SERVICE_TYPE_DATA = '[SPCONNECTION] SET SERVICE TYPE DATA';
export const GET_SERVICE_TYPE_DATA = '[SPCONNECTION] GET SERVICE TYPE DATA';
export const SUBSCRIBE_SERVICE_TYPE_LIST = '[SPCONNECTION] SUBSCRIBE SERVICE TYPE LIST';

export function getInitialServiceTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ServiceType`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getServiceTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ServiceType`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_SERVICE_TYPE_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_SERVICE_TYPE_LIST,
            Co
        }));
    }
}

export function setServiceTypeData(data) {
    return {
        type: SET_SERVICE_TYPE_DATA,
        payload: data
    };
}