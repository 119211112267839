import axios from 'axios';
import { getUserData } from 'main/content/apps/call-queues/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_CALL_QUEUES = '[CALL QUEUES APP] GET CALL QUEUES';
export const SET_SEARCH_TEXT = '[CALL QUEUES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CALL_QUEUES = '[CALL QUEUES APP] TOGGLE IN SELECTED CALL QUEUES';
export const SELECT_ALL_CALL_QUEUES = '[CALL QUEUES APP] SELECT ALL CALL QUEUES';
export const DESELECT_ALL_CALL_QUEUES = '[CALL QUEUES APP] DESELECT ALL CALL QUEUES';
export const OPEN_NEW_CALL_QUEUE_DIALOG = '[CALL QUEUES APP] OPEN NEW CALL QUEUE DIALOG';
export const CLOSE_NEW_CALL_QUEUE_DIALOG = '[CALL QUEUES APP] CLOSE NEW CALL QUEUE DIALOG';
export const OPEN_EDIT_CALL_QUEUE_DIALOG = '[CALL QUEUES APP] OPEN EDIT CALL QUEUE DIALOG';
export const CLOSE_EDIT_CALL_QUEUE_DIALOG = '[CALL QUEUES APP] CLOSE EDIT CALL QUEUE DIALOG';
export const ADD_CALL_QUEUE = '[CALL QUEUES APP] ADD CALL QUEUE';
export const UPDATE_CALL_QUEUE = '[CALL QUEUES APP] UPDATE CALL QUEUE';
export const REMOVE_CALL_QUEUE = '[CALL QUEUES APP] REMOVE CALL QUEUE';
export const REMOVE_CALL_QUEUES = '[CALL QUEUES APP] REMOVE CALL QUEUES';
export const TOGGLE_STARRED_CALL_QUEUE = '[CALL QUEUES APP] TOGGLE STARRED CALL QUEUE';
export const TOGGLE_STARRED_CALL_QUEUES = '[CALL QUEUES APP] TOGGLE STARRED CALL QUEUES';
export const SET_CALL_QUEUES_STARRED = '[CALL QUEUES APP] SET CALL QUEUES STARRED ';

export function getCallQueues(routeParams) {
    const request = axios.get('/api/call-queues-app/call-queues', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_CALL_QUEUES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedCallQueues(CallQueueId) {
    return {
        type: TOGGLE_IN_SELECTED_CALL_QUEUES,
        CallQueueId
    }
}


export function selectAllCallQueues() {
    return {
        type: SELECT_ALL_CALL_QUEUES
    }
}

export function deSelectAllCallQueues() {
    return {
        type: DESELECT_ALL_CALL_QUEUES
    }
}


export function openNewCallQueueDialog() {
    return {
        type: OPEN_NEW_CALL_QUEUE_DIALOG
    }
}

export function closeNewCallQueueDialog() {
    return {
        type: CLOSE_NEW_CALL_QUEUE_DIALOG
    }
}

export function openEditCallQueueDialog(data) {
    return {
        type: OPEN_EDIT_CALL_QUEUE_DIALOG,
        data
    }
}

export function closeEditCallQueueDialog() {
    return {
        type: CLOSE_EDIT_CALL_QUEUE_DIALOG
    }
}

export function addCallQueue(newCallQueue) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/CallQueues`,
            {
                ...newCallQueue,
                Data: {
                    ErrMsg: null,
                }
            }
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_CALL_QUEUE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `${newCallQueue.Queue} Queue has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateCallQueue(CallQueue) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/CallQueues?Co=${encodeURIComponent(CallQueue.Co)}&Queue=${encodeURIComponent(CallQueue.Queue)}`,
            {
                ...CallQueue,
                Data: {
                    ErrMsg: null,
                }
            }
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_CALL_QUEUE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `${CallQueue.Queue} Queue has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeCallQueue(CallQueueId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callQueuesApp.CallQueues;

        const request = axios.post('/api/call-queues-app/remove-CallQueue', {
            CallQueueId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CALL_QUEUE
                })
            ]).then(() => dispatch(getCallQueues(routeParams)))
        );
    };
}


export function removeCallQueues(CallQueueIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callQueuesApp.CallQueues;

        const request = axios.post('/api/call-queues-app/remove-CallQueues', {
            CallQueueIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_CALL_QUEUES
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_QUEUES
                })
            ]).then(() => dispatch(getCallQueues(routeParams)))
        );
    };
}

export function toggleStarredCallQueue(CallQueueId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().callQueuesApp.CallQueues;

        const request = axios.post('/api/call-queues-app/toggle-starred-CallQueue', {
            CallQueueId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CALL_QUEUE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallQueues(routeParams)))
        );
    };
}

export function toggleStarredCallQueues(CallQueueIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callQueuesApp.CallQueues;

        const request = axios.post('/api/call-queues-app/toggle-starred-CallQueues', {
            CallQueueIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CALL_QUEUES
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_QUEUES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallQueues(routeParams)))
        );
    };
}

export function setCallQueuesStarred(CallQueueIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callQueuesApp.CallQueues;

        const request = axios.post('/api/call-queues-app/set-CallQueues-starred', {
            CallQueueIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_QUEUES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_QUEUES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallQueues(routeParams)))
        );
    };
}

export function setCallQueuesUnstarred(CallQueueIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().callQueuesApp.CallQueues;

        const request = axios.post('/api/call-queues-app/set-CallQueues-unstarred', {
            CallQueueIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_QUEUES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CALL_QUEUES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCallQueues(routeParams)))
        );
    };
}
