import {combineReducers} from 'redux';
import fleetVehicles from './fleet-vehicles.reducer';
import user from './user.reducer';

const fleetVehiclesAppReducers = combineReducers({
    fleetVehicles,
    user
});

export default fleetVehiclesAppReducers;
