import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newFormOfPaymentState = {
    Co: null,
    BusinessUnit: '',
    FormOfPayment: '',
    Description: '',
    ActiveYN: 'Y',
    Notes: '',
    ApprovalRequiredYN: 'Y',
    Data: {

    }
};

class FormOfPaymentDialog extends Component {
    state = { ...newFormOfPaymentState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newFormOfPaymentState.Co = this.props.Co;
        if (!prevProps.formOfPaymentDialog.props.open && this.props.formOfPaymentDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.formOfPaymentDialog.type === 'edit' &&
                this.props.formOfPaymentDialog.data &&
                !_.isEqual({ ...newFormOfPaymentState, ...this.props.formOfPaymentDialog.data }, prevState)) {
                this.setState({ ...newFormOfPaymentState, ...this.props.formOfPaymentDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.formOfPaymentDialog.type === 'new' &&
                !_.isEqual(newFormOfPaymentState, prevState)) {
                this.setState({ ...newFormOfPaymentState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.formOfPaymentDialog.type === 'edit' ? this.props.closeEditFormOfPaymentDialog() : this.props.closeNewFormOfPaymentDialog();
    };

    canBeSubmitted() {
        const { FormOfPayment, Description } = this.state;
        return (
            FormOfPayment.length > 0 && Description.length > 0
        );
    }

    render() {
        const { classes, securables, formOfPaymentDialog, addFormOfPayment, updateFormOfPayment, removeFormOfPayment, businessUnits } = this.props;
        const accessLevel = _.find(securables, { Securable: "form-of-payments" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...formOfPaymentDialog.props}
                        open={Boolean(accessLevel) && formOfPaymentDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {formOfPaymentDialog.type === 'new' ? 'New Form of Payment' : 'Edit Form of Payment'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField

                                    className={classes.formControl}
                                    label="Business Unit"
                                    id="businessUnit"
                                    select
                                    name="BusinessUnit"
                                    value={this.state.BusinessUnit}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Select Business Unit"
                                    disabled={(formOfPaymentDialog.type === 'new' ? false : true)}
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                        <MenuItem value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Form of Payment"
                                    id="formOfPayment"
                                    name="FormOfPayment"
                                    value={this.state.FormOfPayment}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(formOfPaymentDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="flex">

                                <TextField

                                    className={classes.formControl}
                                    label="Auto Post Invoices"
                                    id="approvalRequiredYN"
                                    select
                                    name="ApprovalRequiredYN"
                                    value={this.state.ApprovalRequiredYN}
                                    InputLabelProps={{ shrink: this.state.ApprovalRequiredYN ? true : false }}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Auto Post Invoices"
                                >
                                    <MenuItem value="Y">
                                        No
                                    </MenuItem>
                                    <MenuItem value="N">
                                        Yes
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {formOfPaymentDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addFormOfPayment(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateFormOfPayment(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditFormOfPaymentDialog: Actions.closeEditFormOfPaymentDialog,
        closeNewFormOfPaymentDialog: Actions.closeNewFormOfPaymentDialog,
        addFormOfPayment: Actions.addFormOfPayment,
        updateFormOfPayment: Actions.updateFormOfPayment,
        removeFormOfPayment: Actions.removeFormOfPayment
    }, dispatch);
}

function mapStateToProps({ formOfPaymentsApp, spReducers }) {
    return {
        formOfPaymentDialog: formOfPaymentsApp.formOfPayments.formOfPaymentDialog,
        businessUnits: spReducers.businessUnits,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(FormOfPaymentDialog));
