import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedCallGatherIds: [],
    routeParams: {},
    callGatherDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    searchResults: [],
};

const callGathersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_RESULTS:
            {
                return {
                    ...state,
                    searchResults: action.payload,
                }
            }
        case Actions.GET_CALL_GATHERS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_CALL_GATHERS:
            {

                const callGatherId = action.callGatherId;

                let selectedCallGatherIds = [...state.selectedCallGatherIds];

                if (selectedCallGatherIds.find(id => id === callGatherId) !== undefined) {
                    selectedCallGatherIds = selectedCallGatherIds.filter(id => id !== callGatherId);
                }
                else {
                    selectedCallGatherIds = [...selectedCallGatherIds, callGatherId];
                }

                return {
                    ...state,
                    selectedCallGatherIds: selectedCallGatherIds
                };
            }
        case Actions.SELECT_ALL_CALL_GATHERS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedCallGatherIds = arr.map(callGathers => callGathers.id);

                return {
                    ...state,
                    selectedCallGatherIds: selectedCallGatherIds
                };
            }
        case Actions.DESELECT_ALL_CALL_GATHERS:
            {
                return {
                    ...state,
                    selectedCallGatherIds: []
                };
            }
        case Actions.OPEN_NEW_CALL_GATHER_DIALOG:
            {
                return {
                    ...state,
                    callGatherDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_CALL_GATHER_DIALOG:
            {
                return {
                    ...state,
                    callGatherDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CALL_GATHER_DIALOG:
            {
                return {
                    ...state,
                    callGatherDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_CALL_GATHER_DIALOG:
                {
                    return {
                        ...state,
                        callGatherDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_CALL_GATHER_DIALOG:
            {
                return {
                    ...state,
                    callGatherDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default callGathersReducer;
