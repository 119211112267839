import { combineReducers } from 'redux';
import localSporage from 'store/storage/CacheManager';

import services from './services.reducer';
import callHandler from './call-handler.reducer';
import technicians from './technicians.reducer';
import teams from './teams.reducer';
import supervisor from './supervisor.reducer';
import calendar from './calendar.reducer';
import employees from './employees.reducer';
import companies from './companies.reducer';
import vpSettings from './vpsettings.reducer';
import qboSettings from './qbosettings.reducer';
import costTypes from './cost-types.reducer';
import localNotifications from './local-notifications.reducer';
import pushNotifications from './push-notifications.reducer';
import fleetVehicleCategories from './fleet-vehicle-categories.reducer';
import notifications from './notifications.reducer';
import gps from './gps.reducer';
import checklists from './checklists.reducer';
import fleetVehicles from './fleet-vehicles.reducer';
import inventoryLocationGroups from './inventory-location-groups.reducer';
import inventoryLocations from './inventory-locations.reducer';
import inventoryManagerLocations from './inventory-manager-locations.reducer';
import inventoryUserTransfers from './inventory-user-transfers.reducer';
import purchaseOrders from './purchase-orders.reducer';
import materialCategories from './material-categories.reducer';
import materials from './materials.reducer';
import vendors from './vendors.reducer';
import forms from './forms.reducer';
import voiceMailboxes from './voice-mailboxes.reducer';
import voicemail from './voicemail.reducer';
import boards from './boards.reducer';
import folders from './folders.reducer';
import userRoles from './user-roles.reducer';
import glAccounts from './gl-accounts.reducer';
import uoms from './units-of-measure.reducer';
import catalog from './catalog.reducer';
import costTypeCategories from './cost-type-categories.reducer';
import serviceUnitCategories from './service-unit-categories.reducer';
import serviceUnitTypes from './service-unit-types.reducer';
import serviceTypes from './service-types.reducer';
import laborTypes from './labor-types.reducer';
import taxCodes from './tax-codes.reducer';
import workOrderUsageTypes from './work-order-usage-types.reducer';
import formOfPayments from './form-of-payments.reducer';
import payTerms from './pay-terms.reducer';
import rateTemplates from './rate-templates.reducer';
import arrivalWindows from './arrival-windows.reducer';
import departments from './departments.reducer';
import divisions from './divisions.reducer';
import timecards from './timecards.reducer';
import phoneNumbers from './phone-numbers.reducer';
import callGathers from './call-gathers.reducer';
import huntGroups from './hunt-groups.reducer';
import callQueues from './call-queues.reducer';
import businessUnits from './business-units.reducer';
import agreements from './agreements.reducer';
import agreementTypes from './agreement-types.reducer';
import workOrders from './work-orders.reducer';
import technician from './technician.reducer';
import trips from './trips.reducer';
import reporting from './reporting.reducer';
import textGathers from './text-gathers.reducer';
import textMessages from './text-messages.reducer';
import classes from './classes.reducer';
import audioFiles from './audio-files.reducer';
import customers from './customers.reducer';
import techUA from './technician-ua.reducer';
import customerSites from './customer-sites.reducer';
import userProfiles from './user-profile.reducer';
import optionTypes from './option-types.reducer';
import agentStatus from './agent-status.reducer';
import connectionStatus from './sp-signalr.reducer';
import offline from './offline.reducer';
import dialogHistory from './dialog-history.reducer';
import invoices from './invoices.reducer';

import { persistReducer } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
// import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const userFilter = createBlacklistFilter(
    'userProfiles',
    ['Users']
);


const catalogPersistConfig = {
    key: 'sp-catalogs-2',
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    serialize: false,
    deserialize: false,
};


const materialPersistConfig = {
    key: 'sp-materials-2',
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    serialize: false,
    deserialize: false,
};


const materialCategoriesPersistConfig = {
    key: 'sp-material-categories-2',
    storage: localSporage,
    stateReconciler: autoMergeLevel2,
    serialize: false,
    deserialize: false,
};


const usersPersistConfig = {
    key: 'sp-users-2',
    storage: localSporage,
    transforms: [userFilter],
    stateReconciler: autoMergeLevel2,
    serialize: false,
    deserialize: false,
};

const spReducers = combineReducers({
    connectionStatus,
    technicians,
    techUA,
    teams,
    gps,
    forms,
    calendar,
    invoices,
    userRoles,
    classes,
    checklists,
    employees,
    vendors,
    reporting,
    vpSettings,
    qboSettings,
    textMessages,
    technician,
    optionTypes,
    services,
    callHandler,
    textGathers,
    timecards,
    boards,
    folders,
    uoms,
    catalog: persistReducer(catalogPersistConfig, catalog),
    serviceTypes,
    costTypes,
    costTypeCategories,
    serviceUnitCategories,
    serviceUnitTypes,
    fleetVehicleCategories,
    fleetVehicles,
    inventoryLocationGroups,
    inventoryLocations,
    inventoryManagerLocations,
    inventoryUserTransfers,
    purchaseOrders,
    materialCategories,
    materials: persistReducer(materialPersistConfig, materials),
    glAccounts,
    laborTypes,
    taxCodes,
    workOrderUsageTypes,
    formOfPayments,
    payTerms,
    rateTemplates,
    arrivalWindows,
    agreements,
    agreementTypes,
    businessUnits,
    localNotifications,
    pushNotifications,
    departments,
    divisions,
    phoneNumbers,
    notifications,
    supervisor,
    callGathers,
    huntGroups,
    callQueues,
    workOrders,
    trips,
    audioFiles,
    customers,
    customerSites,
    userProfiles: persistReducer(usersPersistConfig, userProfiles),
    agentStatus,
    companies,
    offline,
    dialogHistory,
    voiceMailboxes,
    voicemail
});

export default spReducers;