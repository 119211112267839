import axios from 'axios/index';
import _ from '@lodash';

export const SET_MATERIAL_DATA = '[SPCONNECTION] SET MATERIAL DATA';
export const GET_MATERIAL_DATA = '[SPCONNECTION] GET MATERIAL DATA';
export const SUBSCRIBE_MATERIAL_LIST = '[SPCONNECTION] SUBSCRIBE MATERIAL LIST';

export function getInitialMaterialData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Material`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getMaterialData(Co, full) {

    return (dispatch, getState) => {
        const state = getState();
        const { lastUpdate, materials } = state.spReducers.materials;

        const request = axios.get(`${window["apiLocation"]}/api/Material`, {
            params: {
                Co,
                LastUpdate: lastUpdate
            }
        });
        request.then((response) => {
            if (Array.isArray(response.data)) {
            response.data.map((value) => {
                const { Material } = value;
                const index = _.findIndex(materials, { Co, Material });
                if (index < 0) {
                    materials.push(value);
                } else {
                    materials.splice(index, 1, value);
                }
            });
            dispatch({
                type: GET_MATERIAL_DATA,
                payload: materials
            })
        }});
    }
}

export function setMaterialData(data) {
    return {
        type: SET_MATERIAL_DATA,
        payload: data
    };
}