import {combineReducers} from 'redux';
import huntGroups from './hunt-groups.reducer';
import user from './user.reducer';

const huntGroupsAppReducers = combineReducers({
    huntGroups,
    user
});

export default huntGroupsAppReducers;
