import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class FleetVehicleCategoriesList extends Component {

    state = {
        selectedFleetVehicleCategoriesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedFleetVehicleCategoryMenu = (event) => {
        this.setState({ selectedFleetVehicleCategoriesMenu: event.currentTarget });
    };

    closeSelectedFleetVehicleCategoriesMenu = () => {
        this.setState({ selectedFleetVehicleCategoriesMenu: null });
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, securables, fleetVehicleCategories, user, searchText, openNewFleetVehicleCategoryDialog, selectedFleetVehicleCategoryIds, selectAllFleetVehicleCategories, deSelectAllFleetVehicleCategories, toggleInSelectedFleetVehicleCategories, openEditFleetVehicleCategoryDialog, removeFleetVehicleCategories, removeFleetVehicleCategory, toggleStarredFleetVehicleCategory, setFleetVehicleCategoriesUnstarred, setFleetVehicleCategoriesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.fleetVehicleCategoryData ? [] : this.props.fleetVehicleCategoryData), searchText);
        const { selectedFleetVehicleCategoriesMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "fleet-vehicle-categories" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewFleetVehicleCategoryDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Fleet Vehicle Categories</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditFleetVehicleCategoryDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Category",
                                accessor: "Category",
                                className: "justify-center font-bold",
                                width: 128
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                className: "justify-center",
                                width: 384
                            },
                            {
                                Header: "Vehicles",
                                id: "vehicles",
                                className: "justify-center",
                                accessor: r => (r.Data && r.Data.FleetVehicles) && r.Data.FleetVehicles.length,
                                width: 96
                            },
                            {
                                Header: "Active?",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64
                            },

                        ]}
                        defaultPageSize={10}
                        noDataText="No Fleet Vehicle Categories found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFleetVehicleCategories: Actions.getFleetVehicleCategories,
        getUserData: Actions.getUserData,
        toggleInSelectedFleetVehicleCategories: Actions.toggleInSelectedFleetVehicleCategories,
        selectAllFleetVehicleCategories: Actions.selectAllFleetVehicleCategories,
        deSelectAllFleetVehicleCategories: Actions.deSelectAllFleetVehicleCategories,
        openEditFleetVehicleCategoryDialog: Actions.openEditFleetVehicleCategoryDialog,
        removeFleetVehicleCategories: Actions.removeFleetVehicleCategories,
        removeFleetVehicleCategory: Actions.removeFleetVehicleCategory,
        toggleStarredFleetVehicleCategory: Actions.toggleStarredFleetVehicleCategory,
        toggleStarredFleetVehicleCategories: Actions.toggleStarredFleetVehicleCategories,
        setFleetVehicleCategoriesStarred: Actions.setFleetVehicleCategoriesStarred,
        setFleetVehicleCategoriesUnstarred: Actions.setFleetVehicleCategoriesUnstarred,
        openNewFleetVehicleCategoryDialog: Actions.openNewFleetVehicleCategoryDialog
    }, dispatch);
}

function mapStateToProps({ fleetVehicleCategoriesApp, spReducers }) {
    return {
        fleetVehicleCategories: fleetVehicleCategoriesApp.fleetVehicleCategories.entities,
        selectedFleetVehicleCategoryIds: fleetVehicleCategoriesApp.fleetVehicleCategories.selectedFleetVehicleCategoryIds,
        searchText: fleetVehicleCategoriesApp.fleetVehicleCategories.searchText,
        user: fleetVehicleCategoriesApp.user,
        fleetVehicleCategoryData: spReducers.fleetVehicleCategories,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FleetVehicleCategoriesList)));
