import _ from '@lodash';
import { AppBar, Badge, Button, Dialog, DialogActions, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import MaterialsList from '../materials/MaterialsList';
import MaterialCategoriesList from './MaterialCategoriesList';
import * as Actions from './store/actions';
import { showMessage } from 'store/actions';
import axios from 'axios';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newMaterialCategoryState = {
    ID: null,
    Co: null,
    Category: null,
    ParentCategory: null,
    Description: null,
    GLAccount: null,
    TaxableYN: 'Y',
    Notes: '',
    Data: {
        Materials: [],
        Categories: [],
    },
    costUpdate: {
        Percentage: 0,
        Notes: null,
    },
    value: 0,
};

class MaterialCategoryDialog extends Component {
    state = { ..._.cloneDeepWith(newMaterialCategoryState) };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        const initialState = _.cloneDeepWith(newMaterialCategoryState);
        initialState.Co = this.props.Co;
        const { data } = this.props.materialCategoryDialog;
        if (!prevProps.materialCategoryDialog.props.open && this.props.materialCategoryDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (!_.isEqual({ ...initialState, ...(data || {}) }, prevState)) {
                this.setState({ ...initialState, ...(data || {}) });
            }
        }
        if (prevProps.materialCategoryDialog.props.open && this.props.materialCategoryDialog.props.open && !_.isEqual(data, prevProps.materialCategoryDialog.data)) {
            // if (!_.isEqual({ ...initialState, ...(data || {}) }, prevState)) {
            this.setState({ ...initialState, ...(data || {}) });
            // }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.materialCategoryDialog.type === 'edit' ? this.props.closeEditMaterialCategoryDialog() : this.props.closeNewMaterialCategoryDialog();
    };

    canBeSubmitted() {
        const { Description, } = this.state;
        return (
            Description &&
            Description.length > 0
        );
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    updateCosts = () => {
        const { Co, Category, costUpdate } = this.state;
        const { Percentage, Notes } = costUpdate;

        const body = {
            Co,
            Categories: [{
                Co,
                Category
            }],
            Percentage,
            Notes
        };

        const request = axios.put(`${window["apiLocation"]}/api/MaterialCategory?Co=${Co}`,
            body
        );

        request.then((response) => {
            if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: response.data.ErrMsg,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.props.showMessage({
                    message: `Material Costs Successfully Updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                });
                costUpdate.results = response.data.Materials;
                costUpdate.Notes = null;
                costUpdate.Percentage = null;
                this.setState({ costUpdate });
            }
        }).catch((error) => {
            this.props.showMessage({
                message: error.message,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        })
    }

    render() {
        const { classes, materialCategoryDialog, addMaterialCategory, updateMaterialCategory, materialCategories, removeMaterialCategory, businessUnits, divisions, materialCategoryCategories, glAccounts } = this.props;
        const { value } = this.state;

        return (
            <Media query={value === 1 ? "(min-width: 768px) and (min-height: 769px)" : "(min-width: 512px) and (min-height: 512px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...materialCategoryDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={value > 1 && (value < 3 || this.state.costUpdate.results) ? "lg" : "sm"}
                    >
                        {/* <MaterialDialog /> */}
                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {materialCategoryDialog.type === 'new' ? 'New Material Category' : `Material Category #${this.state.Category}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>list</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Categories ? this.state.Data.Categories.length : 0} color="error"><Icon>list</Icon></Badge>} label="Categories" disabled={this.props.materialCategoryDialog.type === 'new'} />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Materials ? this.state.Data.Materials.length : 0} color="error"><Icon>crop_free</Icon></Badge>} label="Materials" disabled={this.props.materialCategoryDialog.type === 'new'} />
                                <Tab disableRipple icon={<Icon>monetization_on</Icon>} label="Cost Update" disabled={this.props.materialCategoryDialog.type === 'new'} />
                            </Tabs>
                        </AppBar>

                        {value === 0 &&
                            <TabContainer>
                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Category"

                                        id="category"
                                        name="Category"
                                        value={this.state.Category}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled={true}
                                    />
                                </div>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="Parent Category"
                                        options={[{ Category: null, Description: 'None' }, ...materialCategories]}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Category}>
                                                {`${value.Category ? `${value.Category} - ` : ''}${value.Description.substr(0, 30)}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.ParentCategory || ''}
                                        onSelect={(option) => this.setState({ ...this.state, ParentCategory: option.Category })}
                                    // required
                                    />
                                </div>

                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="GL Account"
                                        options={[{ GLAccount: null, Description: 'None' }, ...glAccounts]}
                                        menuItemComponent={(value) => {
                                            return <MenuItem value={value.GLAccount}>
                                                {`${value.GLAccount ? `${value.GLAccount} - ` : ''}${value.Description}`}
                                            </MenuItem>
                                        }}
                                        portal={matches}
                                        noflip={matches}
                                        value={this.state.GLAccount}
                                        onSelect={(option) => this.setState({ ...this.state, GLAccount: option.GLAccount })}
                                        required
                                    />
                                </div>

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <div className="w-1/2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                </div>
                            </TabContainer>
                        }

                        {value === 1 &&
                            <TabContainer>
                                <MaterialCategoriesList parentCategory={this.state.Category} selectedCategories={[...this.state.Data.Categories]} />
                            </TabContainer>
                        }

                        {value === 2 &&
                            <TabContainer>
                                <MaterialsList selectedMaterialData={[...this.state.Data.Materials]} category={this.state.Category} />
                            </TabContainer>
                        }

                        {value === 3 && this.state.costUpdate.results &&
                            <TabContainer>
                                <MaterialsList selectedMaterialData={[...this.state.costUpdate.results]} costUpdate={true} />
                            </TabContainer>
                        }

                        {value === 3 && !this.state.costUpdate.results &&
                            <TabContainer>
                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Adjust Costs By %"

                                        id="updatePct"
                                        name="costUpdate.Percentage"
                                        value={this.state.costUpdate.Percentage || ''}
                                        onChange={this.handleChange}
                                        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, type: 'number' }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="costNotes"
                                        name="costUpdate.Notes"
                                        value={this.state.costUpdate.Notes || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                            </TabContainer>
                        }
                        {value === 3 &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.setState({ confirmCostUpdate: true })
                                    }}
                                    disabled={!this.state.costUpdate.Percentage || isNaN(this.state.costUpdate.Percentage)}
                                >
                                    Update Costs
                                </Button>
                                <ConfirmationDialog
                                    open={this.state.confirmCostUpdate}
                                    title={<div><Icon className="mr-6 align-middle mb-4" color="action">error</Icon>Are You Sure?</div>}
                                    content={<div className="w-full pt-8 pb-8">{`Warning - this action will update material costs by ${this.state.costUpdate.Percentage}% for all materials contained within this category, as well as any sub-categories contained therein. Are you sure you wish to proceed?`}</div>}
                                    confirmText="Confirm"
                                    cancelText="Cancel"
                                    onCancel={() => this.setState({ ...this.state, confirmCostUpdate: false })}
                                    onConfirm={() => this.setState({ ...this.state, confirmCostUpdate: false }, () => { this.updateCosts(); })}
                                />
                            </DialogActions>
                        }
                        {value === 0 &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {!this.state.ID ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addMaterialCategory(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button> :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateMaterialCategory(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                }
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditMaterialCategoryDialog: Actions.closeEditMaterialCategoryDialog,
        closeNewMaterialCategoryDialog: Actions.closeNewMaterialCategoryDialog,
        addMaterialCategory: Actions.addMaterialCategory,
        updateMaterialCategory: Actions.updateMaterialCategory,
        removeMaterialCategory: Actions.removeMaterialCategory,
        showMessage
    }, dispatch);
}

function mapStateToProps({ materialCategoriesApp, spReducers }) {
    return {
        materialCategoryDialog: materialCategoriesApp.materialCategories.materialCategoryDialog,
        materialCategories: spReducers.materialCategories,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        Co: spReducers.companies.Co,
        materialCategoryCategories: spReducers.materialCategoryCategories,
        glAccounts: spReducers.glAccounts,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MaterialCategoryDialog));
