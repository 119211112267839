import _ from '@lodash';
import { AppBar, Badge, Button, Dialog, DialogActions, Fab, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RateTemplateLaborDialog from './RateTemplateLaborDialog';
import RateTemplateLaborsList from './RateTemplateLaborsList';
import RateTemplateMaterialDialog from './RateTemplateMaterialDialog';
import RateTemplateMaterialsList from './RateTemplateMaterialsList';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
});
const newRateTemplateState = {
    Co: null,
    BusinessUnit: '',
    Division: '',
    RateTemplate: '',
    Description: '',
    LaborRate: 0.00,
    MaterialMarkup: 0,
    EquipmentMarkup: 0,
    MiscMarkup: 0,
    MarkupByTotal: 'N',
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    },
    value: 0,
};

class RateTemplateDialog extends Component {
    state = { ...newRateTemplateState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newRateTemplateState.Co = this.props.Co;
        if (!prevProps.rateTemplateDialog.props.open && this.props.rateTemplateDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.rateTemplateDialog.type === 'edit' &&
                this.props.rateTemplateDialog.data &&
                !_.isEqual(this.props.rateTemplateDialog.data, prevState)) {
                this.setState({ ...this.props.rateTemplateDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.rateTemplateDialog.type === 'new' &&
                !_.isEqual(newRateTemplateState, prevState)) {
                this.setState({ ...newRateTemplateState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.rateTemplateDialog.type === 'edit' ? this.props.closeEditRateTemplateDialog() : this.props.closeNewRateTemplateDialog();
    };

    canBeSubmitted() {
        const { RateTemplate, Description } = this.state;
        return (
            RateTemplate.length > 0 && Description.length > 0
        );
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes, rateTemplateDialog, addRateTemplate, updateRateTemplate, removeRateTemplate, businessUnits, divisions, openNewRateTemplateLaborDialog, openNewRateTemplateMaterialDialog, } = this.props;
        const { value, Co, BusinessUnit, Division, RateTemplate } = this.state;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        {...rateTemplateDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={ !matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {rateTemplateDialog.type === 'new' ? 'New Rate Template' : `${this.state.RateTemplate} Rate Template`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>ballot</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Materials ? this.state.Data.Materials.length : 0} color="error"><Icon>crop_free</Icon></Badge>} label="Materials" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Labor ? this.state.Data.Labor.length : 0} color="error"><Icon>build</Icon></Badge>} label="Labor" />
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                            <TabContainer>
                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">business</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Business Unit"

                                        select
                                        id="businessUnit"
                                        name="BusinessUnit"
                                        value={this.state.BusinessUnit}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled={(rateTemplateDialog.type === 'new' ? false : true)}
                                    >
                                        {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                            <MenuItem value={value.BusinessUnit}>
                                                {value.Description}
                                            </MenuItem>
                                        )) : '')}
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Division"
                                        id="division"
                                        select
                                        name="Division"
                                        value={this.state.Division}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled={(rateTemplateDialog.type === 'new' ? false : true)}
                                    >
                                        {(this.state.BusinessUnit && this.state.BusinessUnit.length > 0 ?
                                            (divisions && divisions.length > 0 ? divisions.map((value) => (
                                                (this.state.BusinessUnit == value.BusinessUnit ?
                                                    <MenuItem value={value.Division}>
                                                        {value.Description}
                                                    </MenuItem> : '')
                                            )) : '')
                                            :
                                            <MenuItem value="">
                                                Please Select a Business Unit
                                            </MenuItem>
                                        )
                                        }
                                    </TextField>
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">ballot</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Rate Template"

                                        id="rateTemplate"
                                        name="RateTemplate"
                                        value={this.state.RateTemplate}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled={(rateTemplateDialog.type === 'new' ? false : true)}
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="LaborRate"
                                        id="laborRate"
                                        name="LaborRate"
                                        value={this.state.LaborRate}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="MaterialMarkup"
                                        id="materialMarkup"
                                        name="MaterialMarkup"
                                        value={this.state.MaterialMarkup}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="EquipmentMarkup"
                                        id="equipmentMarkup"
                                        name="EquipmentMarkup"
                                        value={this.state.EquipmentMarkup}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="MiscMarkup"
                                        id="miscMarkup"
                                        name="MiscMarkup"
                                        value={this.state.MiscMarkup}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                        <Icon color="action">note</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <div className="w-full">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.MarkupByTotal === 'Y'}
                                                    name="MarkupByTotal"
                                                    onChange={this.handleChange}
                                                    value="MarkupByTotal"
                                                    color="secondary"
                                                />
                                            }
                                            className="float-right"
                                            label="Markup By Total"
                                        />
                                    </div>
                                </div>
                            </TabContainer>
                        }
                        {value === 1 &&
                            <TabContainer>
                                <Fab
                                    color="secondary"
                                    aria-label="add"
                                    className={classes.addButton}
                                    onClick={(event) => {
                                        openNewRateTemplateMaterialDialog({
                                            Co,
                                            BusinessUnit,
                                            Division,
                                            RateTemplate
                                        });
                                    }
                                    }
                                >
                                    <Tooltip title="New Material Breakpoint" placement="left">
                                        <Icon>add</Icon>
                                    </Tooltip>
                                </Fab>
                                <RateTemplateMaterialsList rateTemplateMaterials={this.state.Data.Materials} />
                                <RateTemplateMaterialDialog />
                            </TabContainer>
                        }
                        {value === 2 &&
                            <TabContainer>
                                <Fab
                                    color="secondary"
                                    aria-label="add"
                                    className={classes.addButton}
                                    onClick={(event) => {
                                        openNewRateTemplateLaborDialog({
                                            Co,
                                            BusinessUnit,
                                            Division,
                                            RateTemplate
                                        });
                                    }
                                    }
                                >
                                    <Tooltip title="New Labor Rate" placement="left">
                                        <Icon>add</Icon>
                                    </Tooltip>
                                </Fab>
                                <RateTemplateLaborsList rateTemplateLabors={this.state.Data.Labor} />
                                <RateTemplateLaborDialog />
                            </TabContainer>
                        }
                        {rateTemplateDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addRateTemplate(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateRateTemplate(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openNewRateTemplateLaborDialog: Actions.openNewRateTemplateLaborDialog,
        openNewRateTemplateMaterialDialog: Actions.openNewRateTemplateMaterialDialog,
        closeEditRateTemplateDialog: Actions.closeEditRateTemplateDialog,
        closeNewRateTemplateDialog: Actions.closeNewRateTemplateDialog,
        addRateTemplate: Actions.addRateTemplate,
        updateRateTemplate: Actions.updateRateTemplate,
        removeRateTemplate: Actions.removeRateTemplate
    }, dispatch);
}

function mapStateToProps({ rateTemplatesApp, spReducers }) {
    return {
        rateTemplateDialog: rateTemplatesApp.rateTemplates.rateTemplateDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RateTemplateDialog));
