import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardMedia, CardContent, CardActions, Chip, Divider, Hidden, Icon, IconButton, LinearProgress, List, ListItem, ListItemText, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import { isMobile } from 'react-device-detect';
import * as Actions from './store/actions';
import _ from '@lodash';
import classNames from 'classnames';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import ImageDialog from './ImageDialog';
import { SPRoundIcon, SPMarkerIcon } from 'main/icons';
import parse, { domToReact } from 'html-react-parser';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/logos/SP_Cog_Gradient.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        overflow: 'hidden',
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    cardSubheader: {
        fontSize: 11,
    },
    avatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    completedAvatar: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 6
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    hoverable: {
        "& .showOnHover": {
            display: 'none',
        },
        "&:hover .showOnHover":
        {
            display: 'block'
        },
        // "&:active": {
        //     transform: 'scale(.95)'
        // }
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    scaleButton: {
        "&:active": {
            transform: 'scale(.95)'
        }
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    colorError: {
        color: theme.palette.error[500],
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
        cbackgroundClor: theme.palette.secondary.main,
    },
    bgError: {
        backgroundColor: theme.palette.error[500],
    },
    progress: {
        width: 48,
        height: 48,
        margin: 'auto',
        marginBottom: -36,
        top: -30,
    },
    roundProgress: {
        width: '72px !important',
        height: '72px !important',
    },
    avatar: {
        backgroundColor: '#fff !important',
        width: 48,
        height: 48,
    },
    labels: {},
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
});

const initialState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Category: null,
    Type: null,
    Name: null,
    Description: null,
    ActiveYN: null,
    ParentCategory: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    PRCode: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Data: {
        ErrMsg: null,
        Image: null,
        Images: [],
        Categories: [],
        Assignments: [],
        Upgrades: [],
        Recommendations: [],
        Calculation: null
    },
    open: false,
    editing: false,
    imgSearch: false,
    confirmDelete: false
};

class CatalogTMCard extends Component {

    state = {
        ...initialState
    }

    componentDidMount() {
        const { value } = this.props;
        // if (value.Data && value.Data.Image) {

        // }
        this.setState({ ...initialState, ...value });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value)) {
            this.setState({ ...this.state, ...value });
        }
    }

    hasAlpha(context, canvas) {
        var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
            hasAlphaPixels = false;
        for (var i = 3, n = data.length; i < n; i += 4) {
            if (data[i] < 255) {
                hasAlphaPixels = true;
                break;
            }
        }
        return hasAlphaPixels;
    }

    // checkAlpha(src) {
    //     var canvas = document.createElement('canvas');

    //     var img = new Image();
    //     img.onload = function () {
    //         canvas.width = 100;
    //         canvas.height = 100;
    //         ctx.drawImage(img, 0, 0, 100, 100);
    //         document.getElementById("image").src = canvas.toDataURL(src);
    //     }
    //     img.src = src;

    //     var ctx = canvas.getContext('2d');
    //     reader.readAsDataURL(e.target.files[0]);
    // }

    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ ...this.state, open: !open });
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        const { editing } = this.state;
        this.setState({ ...this.state, editing: !editing, open: true });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    canBeSubmitted = () => {
        const { Name, Description, Category } = this.state;
        return Name && Name.length > 0 && Description && Description.Length > 0 && Category && !isNaN(Category);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    addComponent = (e) => {
        e.stopPropagation();
        const { estimate, onAddComponent, isMember, onAdded } = this.props;
        if (estimate && estimate.Data && estimate.Data.Components) {
            var rec = _.cloneDeepWith(this.state);
            const { Components } = _.cloneDeepWith(estimate.Data);
            // delete rec.Data;
            const {
                Co,
                BusinessUnit,
                Division,
                Qty,
                Assembly,
                TaxableYN,
                AddonYN
            } = rec;
            const {
                Estimate,
                OptionBoard,
                Option
            } = estimate;
            const component = {
                Co,
                BusinessUnit,
                Division,
                Estimate,
                OptionBoard,
                Option,
                Component: Components.length + 1,
                Type: 0,
                Qty: Qty || 1,
                UOM: 'EA',
                Assembly,
                Price: Number(this.state.Qty || 1) * Number(isMember ? this.state.MemberPrice : this.state.AddonYN === 'Y' ? this.state.AddOnPrice : this.state.Price) || 0,
                PriceMethod: 'F',
                TaxableYN,
                AddonYN,
                Data: {
                    Assembly: rec,
                }
            };
            Components.push(component);
            window["warn"]('New Component List: ', Components);
            if (onAddComponent) {
                onAddComponent(Components);
            }
            // this.setState({estimate, component});
            window["warn"](estimate);
        }
    }

    deleteAssembly = () => {
        const { component, estimate, onRemoved } = this.props;
        const { Qty, Price } = _.cloneDeepWith(component);
        const { Components } = _.cloneDeepWith(estimate.Data);
        const index = _.findIndex(Components, { Component: component.Component });
        const comp = _.find(Components, { Component: component.Component });

        if (comp.ID) {
            comp.DeleteYN = 'Y';
            Components.splice(index, 1, comp);
        } else {
            Components.splice(index, 1);
        }
        if (onRemoved) {
            onRemoved(Components);
        }
    }

    render() {
        const { classes, value, category, user, securables, sidebar, topBar, component, estimate, option, dialog, catalogs, onClickBefore, isMember, companies, onSelected, materials } = this.props;
        // window["warn"]('Estimate Assembly: ', catalog, value);
        window["warn"]('Estimate Material: ', value, component);
        // window["warn"]('Catalog Categories', Categories);
        const { open, editing, imgSearch } = this.state;
        const defaultImg = 'assets/images/logos/SP_Cog_Gradient.png';
        const accessLevel = _.find(securables, { Securable: "catalog" });
        const { Co, BusinessUnit, Division, Material, ImageID, CustomImgURL } = value;
        const co = _.find(companies, { Co }).Data.Co;
        const catalog = _.find([...catalogs], { Co, BusinessUnit, Division });
        const { Data } = catalog || {};
        const { Categories } = Data || {};
        return (
            <div className={classNames(sidebar ? "w-full p-6" : topBar ? "w-160 min-w-160 p-6 m-0" : component ? "w-1/2 p-6 m-0" : "w-1/2 sm:w-1/3 md:w-1/4 p-4 pt-8 sm:p-12")}>
                <Card
                    onClick={(e) => {
                        if (onSelected) {
                            this.props.onSelected(component);
                        }
                    }}
                    className="mt-8 w-full relative cursor-pointer rounded-lg">
                    {component &&
                        <div className={classNames("pin-r pin-t m-8 absolute text-center text-24 p-8 pt-4 pb-4 font-bold z-10")} style={{ backgroundColor: `#3f3f3f`, lineHeight: '16px', borderRadius: 24, border: '1px solid #fff' }}>
                            <div class="w-full text-13 text-white">{`${component.Qty} ${component.UOM}`}</div>
                        </div>
                    }
                    {/* {component &&
                        <div className={classNames("w-full flex justify-between")}>
                            <div className={classNames(classes.bgError, "text-center p-4 font-bold")} style={{ lineHeight: '12px', borderRadius: 24, border: '1px solid #fff' }}>
                                <Icon className={classNames("text-16 text-white")} color="error">remove</Icon>
                            </div>
                            <div className="text-center p-6 w-56" style={{ backgroundColor: `#fff`, lineHeight: '12px', borderRadius: 6, border: '1px solid #3f3f3f' }}>
                                <div class="w-full text-14 font-bold">{component.Qty}</div>
                            </div>
                            <div className={classNames(classes.bgPrimary, "text-center p-4 font-bold")} style={{ lineHeight: '12px', borderRadius: 24, border: '1px solid #fff' }}>
                                <Icon className={classNames("text-16 text-white")} color="error">add</Icon>
                            </div>
                        </div>
                    } */}
                    <CardMedia
                        component="image"
                        className={!component ? "h-160 sm:h-192 w-full relative" : "h-128 w-full relative"}
                        image={(CustomImgURL) ? CustomImgURL : "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"}
                        style={(!CustomImgURL) ? {
                            background: catalog && catalog.EstimateHeaderImg ? 'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,0)), url(assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg)' : '#fff',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        } : undefined}
                        alt={value.Name}
                    >
                        {(!CustomImgURL) &&
                            <img src={catalog ? catalog.EstimateHeaderImg || co.ImgUrl : co.ImgUrl || "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"} className={catalog && catalog.EstimateHeaderImg ? "pin-t pin-b pin-l pin-r m-auto absolute w-5/6" : "pin-t pin-b pin-l pin-r m-auto absolute"} />
                        }
                    </CardMedia>
                    <CardContent className={editing ? "pb-6" : ""} classes={{ root: "px-6 py-12 pb-0 has-hover" }}>
                        <div className={classNames(classes.progress, "relative")}>
                            <Avatar className={classNames(classes.avatar, "absolute pin-t cursor-pointer")}>
                                {
                                    component ?
                                        <Icon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const { Qty, Price } = _.cloneDeepWith(component);
                                                const unitPrice = Price / Qty;
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                const index = _.findIndex(Components, { Component: component.Component });
                                                if (index > -1) {
                                                    const comp = _.find(Components, { Component: component.Component });
                                                    if (Qty > 1) {
                                                        comp.Qty -= 1;
                                                        comp.Price -= unitPrice;
                                                        if (this.props.onUpdated) {
                                                            this.props.onUpdated(Components);
                                                        }
                                                    } else {
                                                        if(this.props.multipleOfSameComponent){
                                                            if (comp.ID) {
                                                                comp.DeleteYN = 'Y';
                                                                Components.splice(index, 1, comp);
                                                            } else {
                                                                Components.splice(index, 1);
                                                            }
                                                            if (this.props.onRemoved) {
                                                                this.props.onRemoved(Components);
                                                            }
                                                        } else{
                                                            this.setState({ ...this.state, confirmDelete: true})
                                                        }        
                                                    }
                                                }
                                            }}
                                            className="text-36 font-500"
                                            style={{ color: '#5f5f5f' }}>remove_circle_outline</Icon> :
                                        value.Material ?
                                            <SPRoundIcon className="text-36" style={{ color: '#5f5f5f' }} /> :
                                            <Icon className="text-28" style={{ color: '#5f5f5f' }}>apps</Icon>
                                }
                            </Avatar>
                        </div>
                        <Typography onClick={(e) => { e.stopPropagation(); this.setState({ open: !open }) }} gutterBottom variant="h6" style={{ fontSize: 16 }} component="div" className="flex">
                            <div className={!open ? "truncate w-full text-center" : "w-full text-center"}>{(component && component.Name) ? component.Name : value.Name}</div>
                        </Typography>
                    </CardContent>
                    <CardActions className="w-full p-12 pt-0 px-6 flex-none">
                        {component &&
                            <div className="w-full">
                                {
                                    (component && component.Price) &&
                                    <Chip
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const { Qty, Price } = _.cloneDeepWith(component);
                                            const unitPrice = Price / Qty;
                                            const { Components } = _.cloneDeepWith(estimate.Data);
                                            const index = _.findIndex(Components, { Component: component.Component });
                                            if (index > -1) {
                                                const comp = _.find(Components, { Component: component.Component });
                                                comp.Qty += 1;
                                                comp.Price += unitPrice;
                                                if (this.props.onUpdated) {
                                                    this.props.onUpdated(Components);
                                                }
                                            }
                                        }}
                                        className={classNames(classes.black, classes.scaleButton, "w-full font-bold cursor-pointer")}
                                        icon={<Icon className={classNames(classes.colorSecondary, "text-24")}>add_circle_outline</Icon>}
                                        label={this.formatDollars(component.Price)}
                                    />
                                }
                            </div>
                        }
                    </CardActions>
                </Card>
                <ConfirmationDialog
                    open={this.state.confirmDelete}
                    title={<div><Icon className="mr-6 align-middle mb-4 text-orange">warning</Icon>Are You Sure?</div>}
                    content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to remove "${value.Name}" from this estimate?`}</div>}
                    confirmText="Remove item"
                    cancelText="Cancel"
                    onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                    onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => this.deleteAssembly())}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCategory: Actions.setCategory,
        getAssembly: Actions.getAssembly,
        addAssemblyCategory: Actions.addAssemblyCategory,
        updateAssemblyCategory: Actions.updateAssemblyCategory,
        deleteAssemblyCategory: Actions.removeAssemblyCategory,
        openNewCatalogItemDialog: Actions.openNewCatalogItemDialog,
        openEditCatalogItemDialog: Actions.openEditCatalogItemDialog,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, catalogApp }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        // categories: spReducers.catalog.categories,
        securables: spReducers.userProfiles.User.Data.Securables,
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        catalogs: spReducers.catalog.savedCatalogs,
        materials: spReducers.materials.materials
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogTMCard)));
