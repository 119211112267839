import { FuseUtils, FuseAnimate } from '@fuse'
import _ from '@lodash'
import {
  Avatar,
  Button,
  Checkbox,
  Fab,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Parser } from 'json2csv'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import { bindActionCreators } from 'redux'
import { updateTableSettings } from '../users/store/actions'
import InvoiceBulkDialog from './InvoiceBulkDialog'
import * as Actions from './store/actions'
import axios from 'axios'
import getProfileImage from '../../../functions/getProfileImageUrl'
import { IconButton } from '@material-ui/core'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

function LoadingDiv() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .65)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        className='loader stretchBar'
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
        }}
      >
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
        <br />
        <img
          style={{ width: 80, marginTop: 15 }}
          src='assets/images/splash/SPLoading.png'
        />
      </div>
    </div>
  )
}

function NoData() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .25)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
          paddingTop: 75,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        No Records Found
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 256px)',
    paddingBottom: 0,
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
  },
  labels: {},
  techAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
  dividerPlain: {
    color: '#fff',
  },
  pageBreak: {},
})

const initialState = {
  loading: false,
  invoiceData: [],
  selectedInvoiceIds: [],
  runFetchAllInvoices: false,
  hist: null,
  pageNum: 0,
  pageData: [],
  pageSize: 0,
}

class InvoicesList extends Component {
  state = {
    ...initialState,
  }

  componentDidMount() {
    const {
      loading,
      invoiceData,
      selectedInvoiceIds,
      selectedInvoiceData,
      hist,
    } = this.props
    this.setState({
      ...this.state,
      invoiceData,
      loading,
      selectedInvoiceIds,
      selectedInvoiceData,
      hist,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      loading,
      invoiceData,
      selectedInvoiceData,
      selectedInvoiceIds,
      hist,
    } = this.props
    // window['warn'](invoiceData, prevProps.invoiceData)
    if (loading !== prevProps.loading) {
      this.setState({ ...this.state, loading })
    }
    if (
      !_.isEqual(invoiceData, prevProps.invoiceData) ||
      !_.isEqual(selectedInvoiceData, prevProps.selectedInvoiceData) ||
      !_.isEqual(selectedInvoiceIds, prevProps.selectedInvoiceIds) ||
      !_.isEqual(hist, prevProps.hist)
    ) {
      window['warn']('New Invoice Data: ', invoiceData)
      this.setState({
        ...this.state,
        invoiceData,
        loading: false,
        selectedInvoiceIds,
        selectedInvoiceData,
        hist,
      })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  formatMonth(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const yyyy = dt.getFullYear()
    const formatted = mm + '/' + yyyy
    return formatted
  }

  formatPercentage(num) {
    const str = num.toString()
    const formatted = str + '%'
    return formatted
  }

  handleRowCount = count => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'invoices' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'invoices',
        Preferences: {
          RowCount: count,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.RowCount = count
    }
    this.props.updateTableSettings(prefs)
  }

  handleSortOrder = sort => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'invoices' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'invoices',
        Preferences: {
          SortOrder: sort,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.SortOrder = sort
    }
    this.props.updateTableSettings(prefs)
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  export = () => {
    const { invoiceData } = this.props
    const data = _.cloneDeepWith(!invoiceData ? [] : invoiceData)
    data.map(value => {
      delete value.Data
      delete value.Signature
    })
    const csvData = new Parser()
    const Data = csvData.parse(data)
    var encoded = encodeURIComponent(Data)
    var csv = `data:text/csv;charset=utf-8, ${encoded}`
    var link = document.createElement('a')
    link.setAttribute('href', csv)
    link.setAttribute('download', 'Invoices.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  selectAllInvoices = data => {
    const selected = []
    data.map(value => {
      selected.push(value.ID)
    })
    this.props.selectAllInvoices(selected)
  }

  openMenu = event => {
    event.stopPropagation()
    this.setState({ ...this.state, anchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ ...this.state, anchorEl: null })
  }

  rejectInvoices = () => {
    const { searchText } = this.props
    const { invoiceData, selectedInvoiceIds, loading } = this.state
    const data = this.props.selectedInvoiceData
      ? this.props.selectedInvoiceData
      : this.getFilteredArray(loading ? [] : invoiceData, searchText)
    let invoices = []
    selectedInvoiceIds.map(ID => {
      var invoice = _.find(data, { ID })
      if (invoice) {
        invoices.push({ ...invoice, ApprovedYN: 'N' })
      }
    })
    this.props.updateInvoices(invoices)
    this.props.deSelectAllInvoices()
    this.closeMenu()
  }

  approveInvoices = () => {
    const { searchText } = this.props
    const { invoiceData, selectedInvoiceIds, loading } = this.state
    const data = this.props.selectedInvoiceData
      ? this.props.selectedInvoiceData
      : this.getFilteredArray(loading ? [] : invoiceData, searchText)
    let invoices = []
    selectedInvoiceIds.forEach(ID => {
      var invoice = _.find(data, { ID, Status: 0 })
      if (invoice) {
        invoices.push({ ...invoice, Status: 2 })
      }
    })
    if (invoices.length > 0) {
      this.props.updateInvoices(invoices)
    }
    this.props.deSelectAllInvoices()
    this.closeMenu()
  }

  resetRunFetch = () =>
    this.setState({ ...this.state, runFetchAllInvoices: false })

  fetchData = (Co, Page) => {
    const { customer, site, hist } = this.props
    if (hist) {
      this.setState({ loading: true }, () => {
        const request = axios.get(
          `${window['apiLocation']}/api/Invoice?Co=${Co}&Customer=${customer}${
            site ? `&Site=${site}` : ''
          }&Page=${Page}`,
        )
        request
          .then(response => {
            this.setState({ hist: response.data, loading: false })
          })
          .catch(() => {
            this.setState({ loading: false })
          })
      })
    }
  }

  render() {
    const {
      classes,
      openEditInvoiceDialog,
      loading,
      users,
      Co,
      status,
      companies,
      user,
      glAccount,
      readOnly,
    } = this.props
    const { anchorEl, anchorEl2, selectedInvoiceIds, invoiceData, hist } =
      this.state
    const { TableSettings } = user.Data
    const prefs = _.find(TableSettings, { Table: 'invoices' })
    const data = invoiceData || []
    data.map((inv, index) => {
      if (inv.Data && inv.Data.Data) {
        const { Data } = inv.Data
        inv.batched = _.sumBy(Data.InvoiceDetails || {}, d => {
          return _.sumBy(d.Data.InvoiceLines || {}, o => {
            return o.Data && o.Data.VPARBL
              ? o.Data.VPARBL.Amount
              : o.QBOInvoiceLineTotal || 0
          })
        })
        inv.posted = _.sumBy(Data.InvoiceDetails || {}, d => {
          return _.sumBy(d.Data.InvoiceLines || {}, o => {
            return o.Data && o.Data.VPARTL
              ? o.Data.VPARTL.Amount
              : o.QBOInvoiceLineTotal || 0
          })
        })
        inv.subtotal = _.sumBy(Data.InvoiceDetails || {}, d => {
          return _.sumBy(d.Data.InvoiceLines || {}, o => {
            return o.Amount
          })
        })
        inv.taxTotal = _.sumBy(Data.InvoiceDetails || {}, d => {
          return _.sumBy(d.Data.InvoiceLines || {}, o => {
            return o.TaxAmount
          })
        })
        inv.total = _.sumBy(Data.InvoiceDetails || {}, d => {
          return _.sumBy(d.Data.InvoiceLines || {}, o => {
            return o.Amount + o.TaxAmount
          })
        })
        inv.paid = Data.VPARTH ? Data.VPARTH.Paid : 0
      }
    })
    const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
    const company = _.find(companies, { Co })
    const integrationType =
      company && company.Data && company.Data.Co
        ? company.Data.Co.IntegrationType
        : null
    const manualOptions = hist
      ? {
          manual: true,
          data: hist.Results,
          pages: hist.PageCount,
          onFetchData: e => {
            this.fetchData(Co, e.page + 1)
          },
          defaultPageSize: 10,
          // pageSizeOptions: [10],
          sortable: false,
        }
      : {
          data,
          defaultSorted: prefs ? prefs.Preferences.SortOrder || [] : [],
          defaultPageSize: prefs ? prefs.Preferences.RowCount || 100 : 10,
          onPageSizeChange: this.handleRowCount,
          onSortedChange: this.handleSortOrder,
        }

    //window["warn"]("llkkiiuu", _.difference(this.state.pageData, selectedInvoiceIds), selectedInvoiceIds, this.state.pageData)

    return (
      <FuseAnimate
        animation={this.props.animation || 'transition.slideUpIn'}
        delay={300}
      >
        <div>
          <InvoiceBulkDialog
            selectedInvoiceIds={selectedInvoiceIds}
            runFetch={this.state.runFetchAllInvoices}
            resetRunFetch={this.resetRunFetch.bind(this)}
          />
          <Menu
            id='invoices-menu'
            classes={{ paper: 'min-w-256' }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.closeMenu}
          >
            <MenuItem onClick={this.approveInvoices}>
              <Icon className='mr-8'>check_circle_outline</Icon>Post Selected
              Invoices
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState(
                  { ...this.state, runFetchAllInvoices: true, anchorEl: false },
                  window['warn'](selectedInvoiceIds),
                )
              }
            >
              <Icon className='mr-8'>picture_as_pdf</Icon>Generate Bulk PDF (
              {selectedInvoiceIds.length})
            </MenuItem>
          </Menu>
          {status && (
            <Fab
              color='primary'
              aria-label='add'
              className={classes.addButton}
              onClick={this.export}
              // onClick={openNewUserDialog}
            >
              <Icon className='text-16'>save_alt</Icon>
            </Fab>
          )}
          <Typography
            variant='h6'
            style={{
              alignItems: 'center',
              display: readOnly ? 'none' : 'flex',
            }}
            className='flex text-16 sm:text-20 mb-6 sm:mb-12'
          >
            <Icon color='primary' className='text-32 mr-12'>
              receipt
            </Icon>
            <span>Invoices</span>
            {selectedInvoiceIds.length > 0 && Boolean(status) && (
              <Button
                variant='contained'
                className='ml-12 pt-0 pb-0'
                color='primary'
                onClick={this.openMenu}
              >
                Actions
              </Button>
            )}
          </Typography>
          <ReactTableFixedColumns
            className={classNames(classes.root, '-striped -highlight border-0')}
            getTrProps={(state, rowInfo, column) => {
              // window['warn']('', state, this.state)

              if (state.page !== this.state.pageNum) {
                let pageData = []
                state.pageRows.map(row => {
                  pageData.push(row['_original'].ID)
                })
                this.setState({
                  ...this.state,
                  pageNum: state.page,
                  pageData: pageData,
                  pageSize: state.pageSize,
                })
              }

              if (data.length > 0 && this.state.pageData.length === 0) {
                let pageData = []
                state.pageRows.map(row => {
                  pageData.push(row['_original'].ID)
                })
                this.setState({
                  ...this.state,
                  pageNum: state.page,
                  pageData: pageData,
                  pageSize: state.pageSize,
                })
              }

              if (this.state.pageSize !== state.pageSize) {
                let pageData = []
                state.pageRows.map(row => {
                  pageData.push(row['_original'].ID)
                })
                if (selectedInvoiceIds.length > 0) {
                  this.setState(
                    {
                      ...this.state,
                      pageNum: state.page,
                      pageData: pageData,
                      pageSize: state.pageSize,
                    },
                    this.props.selectAllInvoices(pageData),
                  )
                } else {
                  this.setState({
                    ...this.state,
                    pageNum: state.page,
                    pageData: pageData,
                    pageSize: state.pageSize,
                  })
                }
              }

              const { original } = rowInfo || {}
              const late = original
                ? new Date(
                    new Date(original.DueDate).toLocaleDateString('en-US'),
                  ) < new Date(new Date().toLocaleDateString('en-US')) &&
                  original.BalanceDue > 0
                : null
              return {
                className: classNames(
                  'cursor-pointer',
                  late ? 'text-red-dark' : '',
                ),
                onClick: (e, handleOriginal) => {
                  if (rowInfo) {
                    openEditInvoiceDialog(rowInfo.original, readOnly)
                  }
                },
              }
            }}
            getTfootTrProps={() => {
              return {
                className: 'font-bold text-center',
              }
            }}
            {...manualOptions}
            pageSizeOptions={[5, 10, 50, 100]}
            loading={loading || this.state.loading} // Display the loading overlay when we need it
            NoDataComponent={
              loading || this.state.loading ? LoadingDiv : NoData
            }
            LoadingComponent={
              loading || this.state.loading ? LoadingDiv : undefined
            }
            columns={[
              {
                Header: () => (
                  <Checkbox
                    onClick={event => {
                      event.stopPropagation()
                    }}
                    className='p-0'
                    onChange={event => {
                      let newData = []
                      if (this.state.pageData.length > 0) {
                        if (event.target.checked) {
                          newData = [...this.props.selectedInvoiceIds]
                          this.state.pageData.map(row => {
                            newData.push(row)
                          })
                        } else {
                          newData = _.pullAll(
                            [...this.props.selectedInvoiceIds],
                            this.state.pageData,
                          )
                        }
                      }
                      this.props.selectAllInvoices(newData)
                    }}
                    checked={
                      selectedInvoiceIds.length > 0 &&
                      _.difference(this.state.pageData, selectedInvoiceIds)
                        .length < 1
                    }
                    //indeterminate={selectedInvoiceIds.length !== data.length && selectedInvoiceIds.length > 0}
                    //indeterminate={selectedInvoiceIds.length !== this.state.pageData.length && selectedInvoiceIds.length > 0}
                  />
                ),
                fixed: 'left',
                accessor: '',
                show: Boolean(status),
                Cell: row => {
                  return (
                    <Checkbox
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      className='p-0'
                      checked={selectedInvoiceIds.includes(row.value.ID)}
                      // disabled={row.value.Status === 2}
                      onChange={() =>
                        this.props.toggleInSelectedInvoices(row.value.ID)
                      }
                    />
                  )
                },
                className: 'justify-center p-0',
                sortable: false,
                width: 48,
                // show: !Boolean(this.props.selectedInvoiceData)
              },
              {
                Header: '',
                accessor: 'Data',
                width: 48,
                className: 'justify-center',
                show: Boolean(status),
                Cell: row => {
                  const inv = row.original
                  const { Status, Data } = row.original.Data
                  const variance =
                    (inv.QBOInvoice ? inv.subtotal : inv.total) -
                    (inv.batched || inv.posted)
                  // const batched = _.sumBy((Data.InvoiceDetails || {}), (d) => { return _.sumBy((d.Data.InvoiceLines || {}), (o) => { return o.Data && o.Data.VPARBL ? o.Data.VPARBL.Amount : 0 }) });
                  // const posted = _.sumBy((Data.InvoiceDetails || {}), (d) => { return _.sumBy((d.Data.InvoiceLines || {}), (o) => { return o.Data && o.Data.VPARTL ? o.Data.VPARTL.Amount : 0 }) });
                  // const total = _.sumBy((Data.InvoiceDetails || {}), (d) => { return _.sumBy((d.Data.InvoiceLines || {}), (o) => { return o.Amount + o.TaxAmount }) });
                  if (Status === 3) {
                    return (
                      <Tooltip
                        title={<div>Imported Invoice</div>}
                        placement='top'
                      >
                        <Icon>cloud_done</Icon>
                      </Tooltip>
                    )
                  } else {
                    if (
                      (Data.VPARBH || Data.VPARTH) &&
                      (variance <= 0.01 || variance >= -0.01)
                    ) {
                      return (
                        <Tooltip
                          title={
                            <div>
                              Batched: {this.formatDollars(inv.batched)}
                              <br />
                              Posted: {this.formatDollars(inv.posted)}
                            </div>
                          }
                          placement='top'
                        >
                          <Icon>
                            {Data.VPARBH ? 'cloud_upload' : 'cloud_done'}
                          </Icon>
                        </Tooltip>
                      )
                    } else {
                      if (
                        inv.QBOInvoice &&
                        variance <= 0.01 &&
                        variance >= -0.01
                      ) {
                        return (
                          <Tooltip
                            title={
                              <div>
                                Posted:{' '}
                                {this.formatDollars(inv.QBOInvoiceTotal)}
                              </div>
                            }
                            placement='top'
                          >
                            <Icon>cloud_done</Icon>
                          </Tooltip>
                        )
                      } else {
                        if (Status > 0) {
                          if (Data.VPARBH || Data.VPARTH) {
                            return (
                              <Tooltip
                                title={
                                  <div>
                                    Batched: {this.formatDollars(inv.batched)}
                                    <br />
                                    Posted: {this.formatDollars(inv.posted)}
                                    <br />
                                    Variance: {this.formatDollars(variance)}
                                  </div>
                                }
                                placement='top'
                              >
                                <Icon
                                  color='error'
                                  style={{
                                    color:
                                      inv.posted != 0 ? 'orange' : undefined,
                                  }}
                                >
                                  {inv.posted == 0
                                    ? 'error_outline'
                                    : 'warning'}
                                </Icon>
                              </Tooltip>
                            )
                          } else {
                            return (
                              <Tooltip
                                title={
                                  <div>
                                    Posted:{' '}
                                    {this.formatDollars(inv.QBOInvoiceTotal)}
                                    <br />
                                    Variance: {this.formatDollars(variance)}
                                  </div>
                                }
                                placement='top'
                              >
                                <Icon
                                  color='error'
                                  style={{
                                    color: variance ? 'orange' : undefined,
                                  }}
                                >
                                  {variance ? 'warning' : 'error_outline'}
                                </Icon>
                              </Tooltip>
                            )
                          }
                        }
                      }
                    }
                  }
                },
              },
              {
                Header: 'Invoice #',
                accessor: 'InvoiceNumber',
                className: 'font-bold justify-center',
                width: 150,
                Footer: !hist && 'Total',
              },
              {
                Header: 'Invoice Date',
                id: 'invoiceDate',
                accessor: 'InvoiceDate',
                className: 'justify-center',
                Cell: row => this.formatDate(row.value),
              },
              {
                Header: 'Due Date',
                id: 'dueDate',
                className: 'justify-center',
                Cell: row => this.formatDate(row.value),
                accessor: 'DueDate',
              },
              {
                Header: 'Subtotal',
                className: 'justify-center',
                accessor: 'subtotal',
                Cell: row => this.formatDollars(row.original.BilledTotal),
                Footer:
                  !hist && this.formatDollars(_.sumBy(data, 'BilledTotal')),
              },
              {
                Header: 'Tax',
                className: 'justify-center',
                accessor: 'taxTotal',
                Cell: row => this.formatDollars(row.original.TaxTotal),
                Footer: !hist && this.formatDollars(_.sumBy(data, 'TaxTotal')),
              },
              {
                Header: 'Total',
                className: 'justify-center',
                accessor: 'total',
                Cell: row => this.formatDollars(row.original.Total),
                Footer: !hist && this.formatDollars(_.sumBy(data, 'Total')),
              },
              {
                Header: 'Paid',
                className: 'justify-center',
                accessor: 'PaidTotal',
                Cell: row => this.formatDollars(row.original.PaidTotal),
                Footer: !hist && this.formatDollars(_.sumBy(data, 'PaidTotal')),
              },
              {
                Header: 'Due',
                className: 'justify-center',
                accessor: 'BalanceDue',
                Cell: row => this.formatDollars(row.original.BalanceDue),
                Footer:
                  !hist && this.formatDollars(_.sumBy(data, 'BalanceDue')),
              },
              {
                Header: 'Sent',
                accessor: 'EmailCount',
                className: 'justify-center',
                width: 64,
              },
              {
                Header: 'Work Order',
                accessor: 'WorkOrder',
                className: 'font-bold justify-center',
                width: 150,
              },
              {
                Header: 'Customer',
                accessor: 'Customer',
                // className: "justify-center",
                width: 256,
                Cell: row => {
                  return `${row.original.Customer} - ${row.original.BillName}`
                },
              },
              {
                Header: 'Payment Types',
                accessor: 'PaymentTypes',
                className: 'justify-center',
                width: 160,
              },
              {
                Header: 'Payment Status',
                accessor: 'PaymentStatus',
                className: 'justify-center',
                width: 128,
                Cell: row => {
                  if (row.original.PaymentStatus === 'P') return 'Paid In Full'
                  if (row.original.PaymentStatus === 'O') return 'Overpaid'
                  if (row.original.PaymentStatus === 'B') return 'Balance Due'

                  return row.original.PaymentStatus
                },
              },
              {
                Header: 'Entered By',
                accessor: 'EnteredBy',
                minWidth: 256,
                className: 'justify-center',
                Cell: row => {
                  return (
                    row.original.EnteredBy &&
                    users &&
                    users.map(
                      (value, index) =>
                        value.UserName === row.original.EnteredBy && (
                          <React.Fragment>
                            <Avatar
                              style={{ marginRight: 8, marginLeft: 8 }}
                              classes={{ root: classes.avatarRoot }}
                              className={classes.techAvatar}
                              alt={value.Technician}
                              src={
                                value.Data && value.Data.Avatar
                                  ? getProfileImage(
                                      `Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                    )
                                  : avatar
                              }
                            />
                            {`${value.FirstName} ${value.LastName}`}
                          </React.Fragment>
                        ),
                    )
                  )
                },
              },
              {
                Header: 'Description',
                accessor: 'Description',
                width: 256,
              },
              {
                Header: 'Batched Total',
                className: 'justify-center',
                accessor: 'batched',
                Cell: row => this.formatDollars(row.original.batched),
                Footer:
                  !hist && status
                    ? this.formatDollars(_.sumBy(data, 'batched'))
                    : null,
                show: Boolean(status) && integrationType === 'V',
              },
              {
                Header: 'Posted Total',
                className: 'justify-center',
                accessor: 'posted',
                Cell: row => this.formatDollars(row.original.posted),
                Footer:
                  !hist && status
                    ? this.formatDollars(_.sumBy(data, 'posted'))
                    : null,
                show: Boolean(status) && integrationType === 'V',
              },
              {
                Header: 'Posted Month',
                id: 'postMonth',
                className: 'justify-center',
                Cell: row => {
                  if (row.value) {
                    return this.formatMonth(row.value)
                  }
                },
                accessor: d => {
                  if (
                    d.Data &&
                    d.Data.Data &&
                    (d.Data.Data.VPARTH || d.Data.Data.VPARBH)
                  ) {
                    return (d.Data.Data.VPARBH || d.Data.Data.VPARTH).Mth
                  }
                },
                show: Boolean(integrationType) && integrationType === 'V',
              },
            ]}
            noDataText='No Invoices found'
          />
        </div>
      </FuseAnimate>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openEditInvoiceDialog: Actions.openEditInvoiceDialog,
      toggleInSelectedInvoices: Actions.toggleInSelectedInvoices,
      selectAllInvoices: Actions.selectAllInvoices,
      deSelectAllInvoices: Actions.deSelectAllInvoices,
      updateInvoices: Actions.updateInvoices,
      updateTableSettings,
    },
    dispatch,
  )
}

function mapStateToProps({ invoicesApp, spReducers, auditPostingApp }) {
  return {
    Co: spReducers.companies.Co,
    companies: spReducers.companies.List,
    invoices: invoicesApp.invoices.entities,
    searchText: invoicesApp.invoices.searchText,
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    glAccount: auditPostingApp.auditPosting.glAccount,
    selectedInvoiceIds: invoicesApp.invoices.selectedInvoiceIds,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesList)),
)
