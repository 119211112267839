import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import deburr from 'lodash/deburr'
import * as Actions from './store/actions'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Icon,
  Input,
  Paper,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { FuseAnimateGroup } from '@fuse'
import accents from 'remove-accents'
import axios from 'axios/index'
import _ from '@lodash'
import moment from 'moment'

const styles = theme => ({
  root: {
    flexGrow: 1,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    margin: 'auto',
    zIndex: 1,
    marginTop: 13,
    left: 24,
    right: 24,
    maxHeight: 500,
    overflow: 'auto',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: 8,
  },
  input: {
    paddingLeft: '8.8rem',
  },
})

class WorkOrderSearch extends React.Component {
  state = {
    suggestions: [],
  }

  handleSearchInput = event => {
    this.setState({ suggestions: [], open: false, searching: false })
    this.props.setSearchText(event)
    // this.getSuggestions();
  }

  handleFocus = event => {
    // this.getSuggestions();
  }

  handleBlur = event => {
    this.setState({ suggestions: [], open: false, searching: false })
  }

  search = () => {
    this.setState({ ...this.state, open: true, searching: true }, () =>
      this.getSuggestions(),
    )
  }

  getSuggestions = _.debounce(() => {
    const { searchText } = this.props
    if (searchText && searchText.length > 0) {
      const { Co } = this.props
      const inputValue = deburr(accents.remove(searchText.trim())).toLowerCase()
      axios
        .get(
          `${
            window['apiLocation']
          }/api/WorkOrderSearch?Co=${Co}&Term=${encodeURIComponent(
            inputValue,
          )}`,
        )
        .then(response => {
          const results = []
          const WorkOrders = response.data
          for (var i = 0; i < WorkOrders.length; i++) {
            const suggestion = WorkOrders[i]
            results.push({ type: 'WorkOrder', data: suggestion })
          }
          this.setState({ suggestions: results, searching: false }, () =>
            window['warn'](this.state.suggestions),
          )
        })
    } else {
      this.setState({ suggestions: [], searching: false })
    }
  }, 250)

  handleSelectWorkOrder(workOrder) {
    this.props.openEditWorkOrderDialog(workOrder)
    this.setState({ suggestions: [] })
  }

  render() {
    const { classes, searchText } = this.props
    const { suggestions, searching, open } = this.state

    return (
      <div
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        className={classes.root}
      >
        <form
          name='callHandlerSearch'
          onSubmit={e => {
            e.preventDefault()
            this.search()
            return false
          }}
          noValidate
        >
          <Input
            // placeholder={this.state.ffTest === false ? "Search Work Orders" : "FF worked!"}
            placeholder={'Search Work Orders'}
            className={classNames(classes.input, 'xl:pl-56')}
            disableUnderline
            fullWidth
            value={searchText}
            inputProps={{
              'aria-label': 'Search',
            }}
            onChange={this.handleSearchInput}
          />
        </form>
        {open && (
          <div
            className={classes.container}
            onMouseDown={event => {
              event.preventDefault()
            }}
          >
            <Paper
              className={classNames(classes.suggestionsContainerOpen)}
              square
            >
              {!suggestions ||
                (suggestions.length < 1 && (
                  <div className='w-full p-24 text-center mt-8 font-bold text-12'>
                    {searching ? (
                      <div className='loader stretchBar mb-8 w-full m-auto pin-t pin-b pin-l pin-r'>
                        <div className='rect1'></div>
                        <div className='rect2'></div>
                        <div className='rect3'></div>
                        <div className='rect4'></div>
                        <div className='rect5'></div>
                      </div>
                    ) : (
                      <Typography
                        fullWidth
                        style={{
                          alignItems: 'center',
                          margin: 0,
                          lineHeight: 1,
                          fontWeight: 'bold',
                        }}
                        className='w-full text-center'
                      >
                        No Results Found
                      </Typography>
                    )}
                  </div>
                ))}
              {suggestions.map(suggestion => {
                switch (suggestion.type) {
                  case 'Customer': {
                    return (
                      <MenuItem
                        onClick={() => {
                          this.handleSelectCustomer(suggestion.data)
                        }}
                        style={{
                          height: 96,
                          padding: 16,
                          border: '1px dotted lightgrey',
                          borderRadius: 4,
                          margin: 4,
                        }}
                        component='div'
                      >
                        <div className='flex flex-1'>
                          <div className='w-64'>
                            <Icon
                              color='primary'
                              className='text-36 mb-0 w-full text-center'
                            >
                              account_circle
                            </Icon>
                            <Typography
                              fullWidth
                              style={{
                                alignItems: 'center',
                                margin: 0,
                                lineHeight: 1,
                                fontWeight: 'bold',
                              }}
                              className='w-full text-center'
                            >{`#${suggestion.data.Customer}`}</Typography>
                          </div>
                          <div className='ml-16'>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full font-bold text-12 mb-6'
                            >{`${suggestion.data.FirstName} ${suggestion.data.LastName}`}</Typography>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full text-12 mb-6'
                            >{`${suggestion.data.Address1} ${suggestion.data.City}, ${suggestion.data.State} ${suggestion.data.Zip}`}</Typography>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full text-12 mb-6'
                            >{`${suggestion.data.Phone}`}</Typography>
                            {suggestion.data.UpdatedDate ? (
                              <Typography
                                fullWidth
                                style={{
                                  alignItems: 'center',
                                  lineHeight: 1,
                                  fontSize: 'xx-small',
                                  fontWeight: 'bold',
                                }}
                                className='w-full'
                              >{`Updated: ${moment(
                                moment(suggestion.data.UpdatedDate),
                              )
                                .local()
                                .toDate()
                                .toLocaleTimeString('en-US')} on ${moment(
                                moment(suggestion.data.UpdatedDate),
                              )
                                .local()
                                .toDate()
                                .toLocaleDateString('en-US')}`}</Typography>
                            ) : (
                              suggestion.data.AddedDate && (
                                <Typography
                                  fullWidth
                                  style={{
                                    alignItems: 'center',
                                    lineHeight: 1,
                                    fontSize: 'xx-small',
                                    fontWeight: 'bold',
                                  }}
                                  className='w-full'
                                >{`Added: ${moment(
                                  moment(suggestion.data.AddedDate),
                                )
                                  .local()
                                  .toDate()
                                  .toLocaleTimeString('en-US')} on ${moment(
                                  moment(suggestion.data.AddedDate),
                                )
                                  .local()
                                  .toDate()
                                  .toLocaleDateString('en-US')}`}</Typography>
                              )
                            )}
                          </div>
                        </div>
                      </MenuItem>
                    )
                  }
                  case 'Site': {
                    return (
                      <MenuItem
                        onClick={() => {
                          this.handleSelectSite(suggestion.data)
                        }}
                        style={{
                          height: 96,
                          padding: 16,
                          border: '1px dotted lightgrey',
                          borderRadius: 4,
                          margin: 4,
                        }}
                        component='div'
                      >
                        <div className='flex flex-1'>
                          <div className='w-64'>
                            <Icon
                              color='primary'
                              className='text-36 mb-0 w-full text-center'
                            >
                              location_on
                            </Icon>
                            <Typography
                              fullWidth
                              style={{
                                alignItems: 'center',
                                margin: 0,
                                lineHeight: 1,
                                fontWeight: 'bold',
                              }}
                              className='w-full text-center'
                            >{`#${suggestion.data.Site}`}</Typography>
                          </div>
                          <div className='ml-16'>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full font-bold text-12 mb-6'
                            >{`${suggestion.data.Description}`}</Typography>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full text-12 mb-6'
                            >{`${suggestion.data.Address1} ${suggestion.data.City}, ${suggestion.data.State} ${suggestion.data.Zip}`}</Typography>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full text-12 mb-6'
                            >{`${suggestion.data.Phone}`}</Typography>
                            {suggestion.data.UpdatedDate ? (
                              <Typography
                                fullWidth
                                style={{
                                  alignItems: 'center',
                                  lineHeight: 1,
                                  fontSize: 'xx-small',
                                  fontWeight: 'bold',
                                }}
                                className='w-full'
                              >{`Updated: ${moment(
                                moment(suggestion.data.UpdatedDate),
                              )
                                .local()
                                .toDate()
                                .toLocaleTimeString('en-US')} on ${moment(
                                moment(suggestion.data.UpdatedDate),
                              )
                                .local()
                                .toDate()
                                .toLocaleDateString('en-US')}`}</Typography>
                            ) : (
                              suggestion.data.AddedDate && (
                                <Typography
                                  fullWidth
                                  style={{
                                    alignItems: 'center',
                                    lineHeight: 1,
                                    fontSize: 'xx-small',
                                    fontWeight: 'bold',
                                  }}
                                  className='w-full'
                                >{`Added: ${moment(
                                  moment(suggestion.data.AddedDate),
                                )
                                  .local()
                                  .toDate()
                                  .toLocaleTimeString('en-US')} on ${moment(
                                  moment(suggestion.data.AddedDate),
                                )
                                  .local()
                                  .toDate()
                                  .toLocaleDateString('en-US')}`}</Typography>
                              )
                            )}
                          </div>
                        </div>
                      </MenuItem>
                    )
                  }
                  case 'WorkOrder': {
                    return (
                      <MenuItem
                        onClick={() => {
                          this.handleSelectWorkOrder(suggestion.data)
                        }}
                        style={{
                          height: 96,
                          padding: 16,
                          border: '1px dotted lightgrey',
                          borderRadius: 4,
                          margin: 4,
                        }}
                        component='div'
                      >
                        <div className='flex flex-1'>
                          <div className='w-64'>
                            <Icon
                              color='primary'
                              className='text-36 mb-0 w-full text-center'
                            >
                              assignment
                            </Icon>
                            <Typography
                              fullWidth
                              style={{
                                alignItems: 'center',
                                margin: 0,
                                lineHeight: 1,
                                fontWeight: 'bold',
                              }}
                              className='w-full text-center'
                            >{`#${suggestion.data.WorkOrder}`}</Typography>
                          </div>
                          <div className='ml-16'>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full font-bold text-12 mb-6'
                            >
                              {suggestion.data.Contact}
                            </Typography>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full text-12 mb-6'
                            >
                              {suggestion.data.ContactPhone}
                            </Typography>
                            <Typography
                              fullWidth
                              style={{ alignItems: 'center', lineHeight: 1 }}
                              className='w-full text-12 mb-6'
                            >
                              {suggestion.data.Description.substr(0, 50)}
                            </Typography>
                            {suggestion.data.EnteredDate ? (
                              <Typography
                                fullWidth
                                style={{
                                  alignItems: 'center',
                                  lineHeight: 1,
                                  fontSize: 'xx-small',
                                  fontWeight: 'bold',
                                }}
                                className='w-full'
                              >{`Entered: ${moment(
                                moment(suggestion.data.EnteredDate),
                              )
                                .local()
                                .toDate()
                                .toLocaleTimeString('en-US')} on ${moment(
                                moment(suggestion.data.EnteredDate),
                              )
                                .local()
                                .toDate()
                                .toLocaleDateString('en-US')}`}</Typography>
                            ) : (
                              suggestion.data.UpdatedDate && (
                                <Typography
                                  fullWidth
                                  style={{
                                    alignItems: 'center',
                                    lineHeight: 1,
                                    fontSize: 'xx-small',
                                    fontWeight: 'bold',
                                  }}
                                  className='w-full'
                                >{`Updated: ${moment(
                                  moment(suggestion.data.UpdatedDate),
                                )
                                  .local()
                                  .toDate()
                                  .toLocaleTimeString('en-US')} on ${moment(
                                  moment(suggestion.data.UpdatedDate),
                                )
                                  .local()
                                  .toDate()
                                  .toLocaleDateString('en-US')}`}</Typography>
                              )
                            )}
                          </div>
                        </div>
                      </MenuItem>
                    )
                  }
                }
              })}
            </Paper>
          </div>
        )}
      </div>
    )
  }
}

WorkOrderSearch.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSearchText: Actions.setSearchText,
      openEditWorkOrderDialog: Actions.openEditWorkOrderDialog,
    },
    dispatch,
  )
}

function mapStateToProps({ workOrdersApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    searchText: workOrdersApp.workOrders.searchText,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(WorkOrderSearch),
)
