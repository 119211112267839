import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedServiceTypeIds: [],
    routeParams       : {},
    serviceTypeDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const serviceTypesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_SERVICE_TYPES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_SERVICE_TYPES:
        {

            const serviceTypeId = action.serviceTypeId;

            let selectedServiceTypeIds = [...state.selectedServiceTypeIds];

            if ( selectedServiceTypeIds.find(id => id === serviceTypeId) !== undefined )
            {
                selectedServiceTypeIds = selectedServiceTypeIds.filter(id => id !== serviceTypeId);
            }
            else
            {
                selectedServiceTypeIds = [...selectedServiceTypeIds, serviceTypeId];
            }

            return {
                ...state,
                selectedServiceTypeIds: selectedServiceTypeIds
            };
        }
        case Actions.SELECT_ALL_SERVICE_TYPES:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedServiceTypeIds = arr.map(serviceTypes => serviceTypes.id);

            return {
                ...state,
                selectedServiceTypeIds: selectedServiceTypeIds
            };
        }
        case Actions.DESELECT_ALL_SERVICE_TYPES:
        {
            return {
                ...state,
                selectedServiceTypeIds: []
            };
        }
        case Actions.OPEN_NEW_SERVICE_TYPE_DIALOG:
        {
            return {
                ...state,
                serviceTypeDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_SERVICE_TYPE_DIALOG:
        {
            return {
                ...state,
                serviceTypeDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_SERVICE_TYPE_DIALOG:
        {
            return {
                ...state,
                serviceTypeDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_SERVICE_TYPE_DIALOG:
        {
            return {
                ...state,
                serviceTypeDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default serviceTypesReducer;
