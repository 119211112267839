import axios from 'axios'
import { getUserData } from 'main/content/apps/materials/store/actions/user.actions'
import { showMessage } from 'store/actions'
import _ from '@lodash'

export const GET_MATERIALS = '[MATERIALS APP] GET MATERIALS'
export const SET_SEARCH_TEXT = '[MATERIALS APP] SET SEARCH TEXT'
export const TOGGLE_IN_SELECTED_MATERIALS =
  '[MATERIALS APP] TOGGLE IN SELECTED MATERIALS'
export const SELECT_ALL_MATERIALS = '[MATERIALS APP] SELECT ALL MATERIALS'
export const DESELECT_ALL_MATERIALS = '[MATERIALS APP] DESELECT ALL MATERIALS'
export const OPEN_NEW_MATERIAL_DIALOG =
  '[MATERIALS APP] OPEN NEW MATERIAL DIALOG'
export const CLOSE_NEW_MATERIAL_DIALOG =
  '[MATERIALS APP] CLOSE NEW MATERIAL DIALOG'
export const OPEN_EDIT_MATERIAL_DIALOG =
  '[MATERIALS APP] OPEN EDIT MATERIAL DIALOG'
export const UPDATE_EDIT_MATERIAL_DIALOG =
  '[MATERIALS APP] UPDATE EDIT MATERIAL DIALOG'
export const CLOSE_EDIT_MATERIAL_DIALOG =
  '[MATERIALS APP] CLOSE EDIT MATERIAL DIALOG'
export const ADD_MATERIAL = '[MATERIALS APP] ADD MATERIAL'
export const UPDATE_MATERIAL = '[MATERIALS APP] UPDATE MATERIAL'
export const REMOVE_MATERIAL = '[MATERIALS APP] REMOVE MATERIAL'
export const REMOVE_MATERIALS = '[MATERIALS APP] REMOVE MATERIALS'
export const TOGGLE_STARRED_MATERIAL = '[MATERIALS APP] TOGGLE STARRED MATERIAL'
export const TOGGLE_STARRED_MATERIALS =
  '[MATERIALS APP] TOGGLE STARRED MATERIALS'
export const SET_MATERIALS_STARRED = '[MATERIALS APP] SET MATERIALS STARRED'
export const GET_ASSEMBLY_RATES = '[MATERIALS APP] GET ASSEMBLIES'
export const SET_SELECTED_ASSEMBLIES = '[MATERIALS APP] SET SELECTED ASSEMBLIES'
export const UPDATE_ASSEMBLY_PRICING = '[MATERIALS APP] UPDATE ASSEMBLY PRICING'
export const SET_ASSEMBLIES_UPDATING = '[MATERIALS APP] SET ASSEMBLIES UPDATING'

export function getMaterials(routeParams) {
  const request = axios.get('/api/materials-app/materials', {
    params: routeParams,
  })

  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_MATERIALS,
        payload: response.data,
        routeParams,
      }),
    )
}

export function getAssemblies(material) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.get(
      `${window['apiLocation']}/api/AssemblyRates?Co=${encodeURIComponent(
        material.Co,
      )}&Material=${encodeURIComponent(material.Material)}`,
    )

    return request.then(response => {
      let assemblies = { ...response }
      assemblies.selectedAssemblies = []
      assemblies.loading = false

      dispatch({
        type: GET_ASSEMBLY_RATES,
        assemblies: assemblies,
      })
    })
  }
}

export function toggleInSelectedAssemblies(row) {
  return (dispatch, getState) => {
    const state = getState().materialsApp.materials.assemblies
    const { selectedAssemblies } = state
    const index = _.findIndex(selectedAssemblies, { Assembly: row.Assembly }) //Co: row.Co, BusinessUnit: row.BusinessUnit, Division: row.Division,
    if (index > -1) {
      selectedAssemblies.splice(index, 1)
    } else {
      selectedAssemblies.push(row)
    }
    dispatch({
      type: SET_SELECTED_ASSEMBLIES,
      selectedAssemblies,
    })
  }
}

export function updateSelectedAssembliesPricing(assemblies) {
  return (dispatch, getState) => {
    const state = getState().materialsApp.materials.assemblies
    const data = _.cloneDeepWith(state.data)
    assemblies.map(assembly => {
      const index = _.findIndex(data, {
        Co: assembly.Co,
        BusinessUnit: assembly.BusinessUnit,
        Division: assembly.Division,
        Assembly: assembly.Assembly,
      })
      if (index > -1) {
        data[index].isUpdating = true
      }
      window['warn']('updateSelectedAssembliesPricing', data)
    })
    dispatch({
      type: SET_ASSEMBLIES_UPDATING,
      data: data,
      updating: true,
    })
    const request = axios.post(
      `${window['apiLocation']}/api/AssemblyRates`,
      assemblies,
    )
    return request
      .then(response => {
        const state = getState().materialsApp.materials.assemblies
        const data = _.cloneDeepWith(state.data)
        response.data.map(assembly => {
          const index = _.findIndex(data, {
            Co: assembly.Co,
            BusinessUnit: assembly.BusinessUnit,
            Division: assembly.Division,
            Assembly: assembly.Assembly,
          })
          if (index > -1) {
            data.splice(index, 1, {
              ...assembly,
              savedChanges: true,
              isUpdating: false,
              hasChanges: false,
              hasError: assembly.Data.ErrMsg && assembly.Data.ErrMsg.length > 0,
            })
          }
        })
        dispatch({
          type: UPDATE_ASSEMBLY_PRICING,
          assemblies: data,
        })
        dispatch(
          showMessage({
            message: `Assemblies successfully updated`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        )
        dispatch(deSelectAllAssemblies())
      })
      .catch(error => {
        dispatch({
          type: SET_ASSEMBLIES_UPDATING,
          updating: false,
        })
        dispatch(
          showMessage({
            message: `${error.message}`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export function updateAssemblyPricing(assembly) {
  const { Co, BusinessUnit, Division, Assembly } = assembly
  return (dispatch, getState) => {
    const request = axios.put(
      `${
        window['apiLocation']
      }/api/AssemblyRates?Co=${Co}&BusinessUnit=${encodeURIComponent(
        BusinessUnit,
      )}&Division=${encodeURIComponent(Division)}&Assembly=${Assembly}`,
      assembly,
    )
    return request
      .then(response => {
        const state = getState().materialsApp.materials.assemblies
        const data = _.cloneDeepWith(state.data)
        const index = _.findIndex(data, {
          Co: response.data.Co,
          BusinessUnit: response.data.BusinessUnit,
          Division: response.data.Division,
          Assembly: response.data.Assembly,
        })
        if (index > -1) {
          data.splice(index, 1, {
            ...response.data,
            savedChanges: true,
            isUpdating: false,
            hasChanges: false,
            hasError:
              response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0,
          })
        }
        dispatch({
          type: UPDATE_ASSEMBLY_PRICING,
          assemblies: data,
        })
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Pricing successfully updated for Assembly #${
                assembly.PRCode || assembly.Assembly
              }`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
      .catch(error => {
        dispatch(
          showMessage({
            message: `${error.message}`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export function selectAllAssemblies(data) {
  return (dispatch, getState) => {
    const state = getState().materialsApp.materials.assemblies
    const { data } = state
    dispatch({
      type: SET_SELECTED_ASSEMBLIES,
      selectedAssemblies: _.cloneDeepWith(data),
    })
  }
}

export function deSelectAllAssemblies() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_ASSEMBLIES,
      selectedAssemblies: [],
    })
  }
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function toggleInSelectedMaterials(materialId) {
  return {
    type: TOGGLE_IN_SELECTED_MATERIALS,
    materialId,
  }
}

export function selectAllMaterials() {
  return {
    type: SELECT_ALL_MATERIALS,
  }
}

export function deSelectAllMaterials() {
  return {
    type: DESELECT_ALL_MATERIALS,
  }
}

export function openNewMaterialDialog(data) {
  return {
    type: OPEN_NEW_MATERIAL_DIALOG,
    data,
  }
}

export function closeNewMaterialDialog() {
  return {
    type: CLOSE_NEW_MATERIAL_DIALOG,
  }
}

export function openPreloadMaterialDialog(data) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_EDIT_MATERIAL_DIALOG,
      data: data,
    })
  }
}

export function openEditMaterialDialog(data) {
  window['warn']('Material Requested: ', data)
  const { workOrder } = data
  return (dispatch, getState) => {
    const request = axios.get(
      `${window['apiLocation']}/api/Material?Co=${
        data.Co
      }&Material=${encodeURIComponent(data.Material)}&Assemblies=Y`,
    )

    return request
      .then(response => {
        if (data && data.value != null) {
          response.data.value = data.value
        }
        dispatch({
          type: OPEN_EDIT_MATERIAL_DIALOG,
          data: { ...response.data, workOrder },
        })
      })
      .catch(error => {
        dispatch(
          showMessage({
            message: `An error occured ${error}`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      })
  }
}

export function updateEditMaterialDialog(data) {
  return {
    type: UPDATE_EDIT_MATERIAL_DIALOG,
    data,
  }
}

export function closeEditMaterialDialog() {
  return {
    type: CLOSE_EDIT_MATERIAL_DIALOG,
  }
}

export function addMaterial(newMaterial) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.post(
      `${window['apiLocation']}/api/Material`,
      newMaterial,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: ADD_MATERIAL,
          data: response.data,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Material #${response.data.Material} has been successfully created.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
        dispatch(updateEditMaterialDialog(response.data))
      }),
    )
  }
}

export function updateMaterial(material) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.put(
      `${window['apiLocation']}/api/Material?Co=${encodeURIComponent(
        material.Co,
      )}&Material=${encodeURIComponent(material.Material)}`,
      material,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: UPDATE_MATERIAL,
          data: response.data,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Material #${material.Material} has been successfully updated.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
        dispatch(updateEditMaterialDialog(response.data))
      }),
    )
  }
}

export function removeMaterial(material) {
  return (dispatch, getState) => {
    const request = axios.delete(
      `${window['apiLocation']}/api/Material?Co=${encodeURIComponent(
        material.Co,
      )}&Material=${encodeURIComponent(material.Material)}`,
      material,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: REMOVE_MATERIAL,
          data: material,
        }),
      ]).then(() => {
        if (response.data && response.data.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Material #${material.Material} has been successfully deleted.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function removeMaterials(materialIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.post('/api/materials-app/remove-materials', {
      materialIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: REMOVE_MATERIALS,
        }),
        dispatch({
          type: DESELECT_ALL_MATERIALS,
        }),
      ]).then(() => dispatch(getMaterials(routeParams))),
    )
  }
}

export function toggleStarredMaterial(materialId) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.post('/api/materials-app/toggle-starred-material', {
      materialId,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: TOGGLE_STARRED_MATERIAL,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getMaterials(routeParams))),
    )
  }
}

export function toggleStarredMaterials(materialIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.post('/api/materials-app/toggle-starred-materials', {
      materialIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: TOGGLE_STARRED_MATERIALS,
        }),
        dispatch({
          type: DESELECT_ALL_MATERIALS,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getMaterials(routeParams))),
    )
  }
}

export function setMaterialsStarred(materialIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.post('/api/materials-app/set-materials-starred', {
      materialIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: SET_MATERIALS_STARRED,
        }),
        dispatch({
          type: DESELECT_ALL_MATERIALS,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getMaterials(routeParams))),
    )
  }
}

export function setMaterialsUnstarred(materialIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().materialsApp.materials

    const request = axios.post('/api/materials-app/set-materials-unstarred', {
      materialIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: SET_MATERIALS_STARRED,
        }),
        dispatch({
          type: DESELECT_ALL_MATERIALS,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getMaterials(routeParams))),
    )
  }
}
