import _ from '@lodash'
import {
  AppBar,
  Badge,
  Button,
  Chip,
  Dialog,
  DialogActions,
  Drawer,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  CardHeader,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import {
  Assignment,
  AttachFile,
  Build,
  BusinessCenter,
  Dashboard,
  History,
  List as ListIcon,
  LocalShipping,
  LocationOn,
  Payment,
  Receipt,
} from '@material-ui/icons'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'
import ContactList from 'main/content/apps/call-handler/ContactList'
import EstimatesList from 'main/content/apps/estimates/EstimatesList'
import FileList from 'main/content/apps/file-manager/FileList'
import InvoicesList from 'main/content/apps/invoices/InvoicesList'
import PaymentsList from 'main/content/apps/invoices/PaymentsList'
import TripsList from 'main/content/apps/trips/TripsList'
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList'
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList'
import Comments from 'main/content/components/comments/Comments'
import { SPRoundIcon } from 'main/icons'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Geocode from 'react-geocode'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import { impress } from 'main/content/compression/impress'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import AgreementsList from '../agreements/AgreementsList'
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector'
import AttachmentDialog from '../file-manager/AttachmentDialog'
import ServiceUnitDialog from '../service-units/ServiceUnitDialog'
import ServiceUnitsList from '../service-units/ServiceUnitsList'
import * as WorkOrderActions from '../work-orders/store/actions'
import CustomerRateTemplatesList from './CustomerRateTemplatesList'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import ChangeDialog from '../../components/change-log/ChangeDialog'
import * as Actions from './store/actions'
import * as WOActions from '../work-orders/store/actions'
import axios from 'axios'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import {
  openMinimizedDialog,
  updateAllMinimizedDialogs,
} from '../minimized-dialogs/store/actions/minimized-dialogs.actions'
import {
  addSplitscreenDialog,
  showSplitscreenDialog,
  setSplitscreenDialog,
  hideAllSplitscreenDialogs,
  captureSplitscreenDialogs,
} from '../splitscreen-dialogs/store/actions/splitscreen-dialogs.actions'
import { openPreloadCustomerSiteDialog } from './store/actions/customer-sites.actions'
import PhoneInput from '../../components/inputs/PhoneInput'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Marker({ text, icon, style, badge, heading, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <div onClick={onClick}>
        <img
          onMouseDown={e => {
            e.stopPropagation()
          }}
          style={style}
          src={icon}
        />
      </div>
    </Tooltip>
  )
}

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

Geocode.setApiKey(googleMapsApiKey)

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  updateRateTemplate: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteRateTemplate: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})

const newCustomerSiteState = {
  ID: '',
  Co: null,
  Customer: '',
  Site: '',
  Description: '',
  Contact: '',
  Phone: '',
  Email: '',
  Address1: '',
  Address2: '',
  City: 'Albuquerque',
  State: 'NM',
  Zip: '',
  Country: 'US',
  Latitude: null,
  Longitude: null,
  Notes: '',
  Status: 'A',
  ActiveYN: 'Y',
  TaxCode: null,
  CrossStreets: null,
  value: 0,
  histTab: 0,
  Data: {
    Customer: {},
    WorkOrders: [],
    Estimates: [],
    Agreements: [],
    Attachments: [],
    Trips: [],
    Invoices: [],
    Tags: [],
    Comments: [],
    Contacts: [],
  },
  streetView: false,
  Address: false,
  rateTemplate: {
    ID: null,
    Co: null,
    Customer: null,
    Site: null,
    BusinessUnit: null,
    Division: null,
    RateTemplate: null,
    Data: {
      ErrMsg: null,
    },
  },
  showEmailLogs: false,
  viewChanges: false,
  menuEl: null,
  showDeleteConfirmation: false,
}

const SiteMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class CustomerSitesDialog extends Component {
  state = { ...newCustomerSiteState }

  ref = React.createRef()

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidMount() {
    const { data, props } = this.props.customerSiteDialog
    const { open } = props

    if (data && open) {
      data.Address = this.buildAddress(
        data.Address1,
        data.City,
        data.State,
        data.Zip,
      )
      if ((!data.Latitude || !data.Longitude) && window.google) {
        Geocode.fromAddress(
          `${data.Address1} ${data.City}, ${data.State} ${data.Zip}`,
        ).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location
            this.setState({
              ...newCustomerSiteState,
              ...data,
              Latitude: lat,
              Longitude: lng,
            })
          },
          error => {
            this.setState({ ...newCustomerSiteState, ...data })
          },
        )
      } else {
        this.setState({ ...newCustomerSiteState, ...data })
      }
      this.props.updateEditCustomerSiteDialog(data)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */

    newCustomerSiteState.Co = this.props.Co
    if (
      !prevProps.customerSiteDialog.props.open &&
      this.props.customerSiteDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.customerSiteDialog.type === 'edit' &&
        this.props.customerSiteDialog.data &&
        !_.isEqual(
          this.props.customerSiteDialog.data,
          prevProps.customerSiteDialog.data,
        )
      ) {
        const data = this.props.customerSiteDialog.data
        data.Address = this.buildAddress(
          data.Address1,
          data.City,
          data.State,
          data.Zip,
        )
        if ((!data.Latitude || !data.Longitude) && window.google) {
          Geocode.fromAddress(
            `${data.Address1} ${data.City}, ${data.State} ${data.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                ...newCustomerSiteState,
                ...data,
                Latitude: lat,
                Longitude: lng,
              })
            },
            error => {
              this.setState({ ...newCustomerSiteState, ...data })
            },
          )
        } else {
          this.setState({ ...newCustomerSiteState, ...data })
        }
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.customerSiteDialog.type === 'new' &&
        !_.isEqual(newCustomerSiteState, prevState)
      ) {
        this.setState({ ...newCustomerSiteState })
      }

      if (
        this.props.customerSiteDialog.type === 'customer' &&
        this.props.customerSiteDialog.data &&
        !_.isEqual(
          this.props.customerSiteDialog.data,
          prevProps.customerSiteDialog.data,
        )
      ) {
        const data = this.props.customerSiteDialog.data
        this.props.customerSiteDialog.data.Address = this.buildAddress(
          data.Address1,
          data.City,
          data.State,
          data.Zip,
        )
        this.props.customerSiteDialog.type = 'new'
        this.setState({
          ...newCustomerSiteState,
          ...this.props.customerSiteDialog.data,
        })
      }
    }
    //window["log"]('STATE COMPARISON', this.props.customerSiteDialog, prevProps, prevState);
    if (
      prevProps.customerSiteDialog.props.open &&
      this.props.customerSiteDialog.props.open
    ) {
      if (
        this.props.customerSiteDialog.type === 'data' &&
        this.props.customerSiteDialog.data &&
        !_.isEqual(
          this.props.customerSiteDialog.data,
          prevProps.customerSiteDialog.data,
        )
      ) {
        const data = this.props.customerSiteDialog.data
        this.props.customerSiteDialog.data.Address = this.buildAddress(
          data.Address1,
          data.City,
          data.State,
          data.Zip,
        )
        this.props.customerSiteDialog.type = 'edit'
        this.setState({ ...this.props.customerSiteDialog.data })
      }
    }
    if (
      prevProps.customerSiteDialog.props.open &&
      this.props.customerSiteDialog.props.open
    ) {
      if (
        this.props.customerSiteDialog.type === 'add' &&
        this.props.customerSiteDialog.data &&
        !_.isEqual(
          this.props.customerSiteDialog.data,
          prevProps.customerSiteDialog.data,
        )
      ) {
        const data = this.props.customerSiteDialog.data
        this.props.customerSiteDialog.data.Address = this.buildAddress(
          data.Address1,
          data.City,
          data.State,
          data.Zip,
        )
        this.props.customerSiteDialog.type = 'edit'
        this.setState({ ...this.props.customerSiteDialog.data })
      }
    }
    if (
      prevProps.customerSiteDialog.props.open &&
      this.props.customerSiteDialog.props.open
    ) {
      if (this.props.customerSiteDialog.type === 'update') {
        if (
          this.props.customerSiteDialog.data &&
          !_.isEqual(
            this.props.customerSiteDialog.data,
            prevProps.customerSiteDialog.data,
          )
        ) {
          const data = this.props.customerSiteDialog.data
          this.props.customerSiteDialog.data.Address = this.buildAddress(
            data.Address1,
            data.City,
            data.State,
            data.Zip,
          )
          this.props.customerSiteDialog.type = 'edit'
          this.setState({ ...this.props.customerSiteDialog.data })
        } else {
          this.props.customerSiteDialog.type = 'edit'
          this.setState({ ...this.state })
        }
      }
    }
  }

  initGoogleAutocomplete = inputElement => {
    var options = { types: ['address'] }
    this.autocomplete = new window.google.maps.places.Autocomplete(
      inputElement,
      options,
    )
    this.autocomplete.setFields([
      'name',
      'formatted_address',
      'address_components',
      'geometry',
    ])
    this.autocomplete.addListener('place_changed', () =>
      this.handleAddressSelect(inputElement),
    )
  }

  handleAddressSelect = inputElement => {
    const componentForm = {
      street_number: 'short_name',
      route: 'short_name',
      locality: 'short_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
    }

    const place = this.autocomplete.getPlace()

    if (place && place.address_components) {
      const address = place.address_components
      const location = place.geometry ? place.geometry.location : ''
      let addressObject = {}
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]]
          addressObject[addressType] = val
        }
      }

      if (address) {
        const address1 = addressObject.street_number
          ? `${addressObject.street_number} ${addressObject.route}`
          : addressObject.route
        this.setState(
          {
            Address1: address1
              ? address1.normalize('NFD').replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            City: addressObject.locality
              ? addressObject.locality
                  .normalize('NFD')
                  .replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            State: addressObject.administrative_area_level_1,
            Country: addressObject.country,
            Zip: addressObject.postal_code,
            Latitude: location && location.lat ? location.lat() : null,
            Longitude: location && location.lng ? location.lng() : null,
          },
          this.getAddress,
          window['warn']('CUSTOMER TAB', place, addressObject),
        )
      }
    } else {
      window['warn']('error:', place)
      inputElement.focus()
    }
  }
  getAddress = () => {
    const oldAddress = this.state.Address
    const { Address1, Zip } = this.state
    const Address =
      Address1 && Zip && Address1.length > 3 && Zip.length >= 3
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
    if (!_.isEqual(oldAddress, Address)) {
      this.setState({ ...this.state, Address })
    }
  }
  //   getAddress = () => {
  //     const { Address1, City, State, Zip } = this.state
  //     if (window.google) {
  //       Geocode.fromAddress(`${Address1} ${City}, ${State} ${Zip}`).then(
  //         response => {
  //           const { lat, lng } = response.results[0].geometry.location
  //           const Address =
  //             Address1.length > 3 && City.length > 2 && State.length > 1
  //               ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
  //               : false
  //           this.setState({
  //             ...this.state,
  //             Address,
  //             Latitude: lat,
  //             Longitude: lng,
  //           })
  //         },
  //         error => {
  //           const Address =
  //             Address1.length > 3 && City.length > 2 && State.length > 1
  //               ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
  //               : false
  //           this.setState({ ...this.state, Address })
  //         },
  //       )
  //     } else {
  //       const Address =
  //         Address1.length > 3 && City.length > 2 && State.length > 1
  //           ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
  //           : false
  //       this.setState({ ...this.state, Address })
  //     }
  //   }

  closeComposeDialog = () => {
    this.props.customerSiteDialog.type === 'edit'
      ? this.props.closeEditCustomerSiteDialog()
      : this.props.closeNewCustomerSiteDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.customerSiteDialog.type === 'edit'
      ? this.props.closeEditCustomerSiteDialog()
      : this.props.closeNewCustomerSiteDialog()
  }

  canBeSubmitted() {
    const { Description } = this.state
    return Description.length > 0
  }

  toggleStreetview(streetView) {
    this.setState({ streetView })
  }

  hasAddress() {
    const { Address } = this.state
    return Address
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  handleAddTag = tag => {
    let { Co } = this.state
    if (this.state.Data.Tags) {
      let { Tags } = this.state.Data
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
    } else {
      let Tags = []
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
    }
  }

  handleDeleteTag = tag => {
    let { Tags } = this.state.Data
    if (Tags == null) {
      Tags = []
    }
    for (var i = 0; i < Tags.length; i++) {
      if (Tags[i].ID == tag.ID) {
        if (isNaN(Tags[i].ID)) {
          Tags.splice(i, 1)
        } else {
          Tags[i].ActiveYN = 'N'
          Tags[i].Status = 'Update'
        }
      }
    }
    this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  attachmentSelectedHandler = event => {
    let { ID, Co, Data } = this.state
    const file = event.target.files[0]
    if (file.type.split('/')[0] === 'image') {
      this.fileSelectedHandler(event)
      // impress(file, 1024, 50, false, (img) => {
      //     window["log"](img);
      //     const nameArr = img.fileName.split('.');
      //     const { Attachments } = this.state.Data;
      //     const newFileName = `Co-${Co}-Unit-${ID}-Img-${Attachments.length + 1}.${nameArr[nameArr.length - 1]}`;
      //     const fd = new FormData();
      //     fd.append('image', img.file, newFileName);
      //     axios.post(`${window["apiLocation"]}/api/Attachment/AddAttachment?Co=${Co}&ID=${ID}&type=WO`, fd).then((res) => {
      //         window["warn"](res);
      //         if (res.data.Attachment) {
      //             const { Attachments } = this.state.Data;
      //             Attachments.push(res.data.Attachment);
      //             this.setState({ ...this.state, Data: { ...Data, Attachments: [...Attachments] } });
      //         }
      //     });
      // });
    } else {
      const fd = new FormData()
      fd.append('file', file, file.name)
      axios
        .post(
          `${
            window['apiLocation']
          }/api/attachment/AddAttachment?Co=${Co}&ID=${ID}&type=${encodeURIComponent(
            'Site',
          )}`,
          fd,
        )
        .then(res => {
          // window["warn"](res);
          if (res.data.Attachment) {
            const { Attachments } = this.state.Data
            Attachments.push(res.data.Attachment)
            this.setState({
              ...this.state,
              Data: { ...Data, Attachments: [...Attachments] },
            })
          }
        })
    }
  }

  fileSelectedHandler = event => {
    let { ID, Data, Co, Customer } = this.state
    const file = event.target.files[0]
    var reader = new FileReader()
    reader.onload = e => {
      const nameArr = file.name.split('.')
      const fname = `Site${Co}-${Customer}Img${Data.Attachments.length + 1}`
      this.setState({
        newPhoto: {
          data: e.target.result,
          fileName: fname,
          type: 'image/jpeg',
        },
      })
      if (this.fileInput) {
        this.fileInput.value = ''
      }
    }
    reader.readAsDataURL(file)
  }

  uploadImg = (file, notes) => {
    impress(file, 1024, 50, false, img => {
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      if (notes) {
        fd.append('notes', notes)
      }
      // saveAs(img.file, img.fileName);

      axios
        .post(
          `${
            window['apiLocation']
          }/api/attachment/AddAttachment?Co=${encodeURIComponent(
            this.state.Co,
          )}&ID=${encodeURIComponent(this.state.ID)}&type=${encodeURIComponent(
            'Site',
          )}`,
          fd,
        )
        .then(res => {
          //axios.post(`${window["apiLocation"]}/api/Attachment/PostWorkOrderImage?Co=${encodeURIComponent(this.state.Co)}&WorkOrder=${encodeURIComponent(this.state.WorkOrder)}&User=${encodeURIComponent(this.props.user.UserName)}`, fd).then((res) => {
          // window["warn"](res);
          if (res.data.Attachment) {
            const { Attachments } = this.state.Data
            Attachments.push(res.data.Attachment)
            this.setState({
              ...this.state,
              Data: { ...this.state.Data, Attachments: [...Attachments] },
              newPhoto: null,
            })
          }
        })
    })
  }

  closeMenu = () => this.setState({ menuEl: null })

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    return (
      <>
        <IconButton
          style={{
            position: 'absolute',
            right: 104,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 56,
            top: 10,
            color: 'white',
          }}
          className='dialog-header-icon'
          onClick={e => this.setState({ menuEl: e.target })}
        >
          <Icon>more_vert</Icon>
        </IconButton>

        <Menu
          id='dialog-menu'
          classes={
            {
              /*paper: "min-w-256"*/
            }
          }
          anchorEl={this.state.menuEl}
          open={Boolean(this.state.menuEl)}
          onClose={this.closeMenu}
        >
          <MenuItem
            onClick={() => {
              this.setState({ menuEl: null, viewChanges: true })
            }}
          >
            <Icon className='mr-8'>history</Icon>View Changes
          </MenuItem>
        </Menu>
        <ChangeDialog
          type='SITE'
          rec={this.state.ID}
          restore={data => this.setState({ ...data, viewChanges: false })}
          accessLevel={this.state.accessLevel}
          data={this.state}
          open={this.state.viewChanges}
          onClose={() => this.setState({ viewChanges: false })}
        />
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 10 : 4,
            color: 'white',
          }}
          onClick={e => {
            //this.closeComposeDialog();
            closeFxn(e)
          }}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
        <Tabs
          value={this.state.value}
          onChange={this.handleTabChange}
          variant='scrollable'
          scrollButtons='on'
        >
          <Tab disableRipple icon={<LocationOn />} label='Info' />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Units
                    ? this.state.Data.Units.length
                    : 0
                }
                color='error'
              >
                <Build />
              </Badge>
            }
            label='Service Units'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.WorkOrders +
                  this.state.Scopes +
                  this.state.Invoices +
                  this.state.Trips +
                  this.state.Payments +
                  this.state.Estimates
                }
                max={999}
                color='error'
              >
                <History />
              </Badge>
            }
            label='History'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Agreements
                    ? this.state.Data.Agreements.length
                    : 0
                }
                color='error'
              >
                <BusinessCenter />
              </Badge>
            }
            label='Agreements'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.RateTemplates
                    ? this.state.Data.RateTemplates.length
                    : 0
                }
                color='error'
              >
                <Icon>ballot</Icon>
              </Badge>
            }
            label='Rate Templates'
          />
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Attachments
                    ? this.state.Data.Attachments.length
                    : 0
                }
                color='error'
              >
                <AttachFile />
              </Badge>
            }
            label='Attachments'
          />
          <Tab disableRipple icon={<Dashboard />} label='Dashboard' />
        </Tabs>
      </>
    )
  }

  ToolbarContents = () => {
    return (
      <Typography variant='subtitle1' color='inherit'>
        <SPRoundIcon className='cursor-pointer text-32 align-middle mr-8' />
        {['new', 'customer'].indexOf(this.props.customerSiteDialog.type) > -1
          ? 'New Service Site'
          : `Service Site #${this.state.Site} Overview`}
      </Typography>
    )
  }

  render() {
    const {
      classes,
      securables,
      theme,
      customerSiteDialog,
      addCustomerSite,
      taxCodes,
      updateCustomerSite,
      openNewWorkOrderDialog,
      googleMapsApiKey,
      businessUnits,
      rateTemplates,
      divisions,
      deleteCustomerSite,
      isAllowedToDelete,
    } = this.props
    const {
      Co,
      Customer,
      Site,
      Contact,
      Phone,
      value,
      newPhoto,
      histTab,
      streetView,
      Address,
      Latitude,
      Longitude,
      menuEl,
    } = this.state
    const histTabs = ['Work Order', 'Visit', 'Invoice', 'Payment', 'Estimate']
    const histIcons = [
      <Assignment className='text-24 ml-12 mr-12' />,
      <LocalShipping className='text-24 ml-12 mr-12' />,
      <Receipt className='text-24 ml-12 mr-12' />,
      <Payment className='text-24 ml-12 mr-12' />,
      <ListIcon className='text-24 ml-12 mr-12' />,
    ]
    const accessLevel = _.find(securables, { Securable: 'site-dialog' })

    let id = `site-dialog-${this.state.Site.replace('.', '-')}`

    const dialogProps = {
      ...customerSiteDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    // window["warn"]("Site dialog billing eee", this.state.Data.Contacts)

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <DraggableDialog
            SlideUp={SlideUp}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this,
            )}
            header={`Service Site #${this.state.Site}`}
            type='Service Site'
            picRef={this.ref.current}
            icon='location_on'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadCustomerSiteDialog}
            description={this.state.Description}
            storeDialog={customerSiteDialog}
            TypeState={this.state.Site}
          >
            <EmailLogDialog
              title={`Site #${this.state.Site} Email Log`}
              type='SITE'
              rec={this.state.ID}
              open={this.state.showEmailLogs}
              onClose={() => this.setState({ showEmailLogs: false })}
            />
            <ConfirmationDialog
              open={this.state.showDeleteConfirmation}
              title={
                <div>
                  <Icon
                    className='mr-6 align-middle mb-4 text-orange'
                    color='error'
                  >
                    warning
                  </Icon>
                  Delete Confirmation
                </div>
              }
              content={
                <div className='w-full'>
                  Are you sure you want to delete this site?
                </div>
              }
              confirmText={'Delete'}
              cancelText='Cancel'
              onCancel={() =>
                this.setState({ ...this.state, showDeleteConfirmation: false })
              }
              onConfirm={async () => {
                const response = await deleteCustomerSite({ ...this.state })

                if (response == '') {
                  this.setState({
                    ...this.state,
                    showDeleteConfirmation: false,
                  })
                }
              }}
            />
            {newPhoto && (
              <AttachmentDialog
                file={newPhoto}
                open={true}
                edit={true}
                onClose={() => this.setState({ newPhoto: null })}
                onSave={this.uploadImg}
              />
            )}
            {value === 0 && (
              <TabContainer refProp={this.ref}>
                {/* {(Latitude && Longitude) &&
                                <div className={classes.mapCanvas}>
                                    <GoogleMap
                                       options={this.getMapOptions}
gestureHandling="cooperative"
                                        bootstrapURLKeys={{
                                            key: googleMapsApiKey
                                        }}
                                        defaultZoom={12}
                                        defaultCenter={[Latitude, Longitude]}
                                        center={searchLatitude && searchLongitude ? [searchLatitude, searchLongitude] : [Latitude, Longitude]}
                                    >
                                            <Marker
                                                key={1}
                                                style={{ width: 32, height: 'auto', marginTop: -18, marginLeft: -16, cursor: 'pointer' }}
                                                icon="assets/images/logos/SPMarker_Gradient.png"
                                                text={this.state.Description}
                                                lat={Latitude}
                                                lng={Longitude}
                                            />
                                    </GoogleMap>
                                </div>
                            } */}
                {Latitude && Longitude && (
                  <div className='sm:flex'>
                    <div
                      className='min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'
                      style={{ position: 'relative' }}
                    >
                      <Tooltip title='Map View' placement='top'>
                        <Icon
                          color='action'
                          onClick={event => {
                            this.toggleStreetview(false)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          map
                        </Icon>
                      </Tooltip>
                      <br />
                      <Tooltip title='Street View' placement='top'>
                        <Icon
                          color='action'
                          className='pin-r pin-t mr-8 sm:pin-none absolute'
                          onClick={event => {
                            this.toggleStreetview(true)
                          }}
                          style={{ cursor: 'pointer', bottom: 40 }}
                        >
                          directions_car
                        </Icon>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '250px',
                        backgroundColor: '#eeeeee',
                        marginBottom: 24,
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <GoogleMap
                        options={this.getMapOptions}
                        gestureHandling='cooperative'
                        bootstrapURLKeys={{
                          key: googleMapsApiKey,
                        }}
                        defaultZoom={12}
                        defaultCenter={[Number(Latitude), Number(Longitude)]}
                        center={[Number(Latitude), Number(Longitude)]}
                      >
                        <Marker
                          key={1}
                          style={{
                            margin: 0,
                            padding: 0,
                            width: 32,
                            height: 42,
                            marginTop: -42,
                            marginLeft: -16,
                            cursor: 'pointer',
                          }}
                          icon='assets/images/logos/SPMarker_Gradient.png'
                          text={this.state.Description}
                          lat={Number(Latitude)}
                          lng={Number(Longitude)}
                        />
                      </GoogleMap>
                    </div>
                  </div>
                )}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>account_circle</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Customer #'
                    id='customer'
                    name='Customer'
                    value={this.state.Customer}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Description'
                    id='description'
                    name='Description'
                    value={this.state.Description}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>location_on</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Address'
                    id='address1'
                    inputRef={this.initGoogleAutocomplete}
                    name='Address1'
                    value={this.state.Address1 || ''}
                    onChange={this.handleChange}
                    // onBlur={this.getAddress}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label="Address (cont'd)"
                    id='address2'
                    name='Address2'
                    value={this.state.Address2}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='City'
                    id='city'
                    name='City'
                    value={this.state.City}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='State'
                    id='state'
                    name='State'
                    value={this.state.State}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Zip Code'
                    id='zip'
                    name='Zip'
                    value={this.state.Zip}
                    onChange={this.handleChange}
                    onBlur={this.getAddress}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                <div className='w-full sm:flex'>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>location_searching</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Cross Streets'
                    id='crossStreets'
                    name='CrossStreets'
                    value={this.state.CrossStreets || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    // required
                    fullWidth
                  />
                </div>
                <div className='w-full sm:flex' style={{ display: 'none' }}>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>phone</Icon>
                  </div>
                  <PhoneInput
                    {...this.props}
                    className={classes.formControl}
                    label='Phone'
                    id='phone'
                    name='Phone'
                    value={this.state.Phone || ''}
                    onChange={value => {
                      this.setState({ ...this.state, Phone: value })
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required={true}
                    fullWidth={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Email'
                    id='email'
                    name='Email'
                    value={this.state.Email || ''}
                    onChange={this.handleChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>

                <div className='w-full sm:flex mb-12' style={{ marginTop: -8 }}>
                  <div className='hidden sm:block min-w-48 pt-20'>
                    <Icon color='action'>contacts</Icon>
                  </div>
                  <ContactList
                    onUpdate={Contacts =>
                      this.setState({
                        ...this.state,
                        Data: { ...this.state.Data, Contacts },
                      })
                    }
                    editable={true}
                    site={{ ...this.state }}
                    details={this.state.Data.Contacts || []}
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>email</Icon>
                  </div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Default Tax Code'
                    options={[
                      { Description: 'None', TaxCode: null },
                      ...taxCodes,
                    ]}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.TaxCode}>
                          {value.TaxCode
                            ? `${value.Description} - ${value.TaxCode}`
                            : value.Description}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.TaxCode || ''}
                    onSelect={option =>
                      this.setState({ ...this.state, TaxCode: option.TaxCode })
                    }
                    // required
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>assistant</Icon>
                  </div>
                  <ChipInput
                    blurBehavior='add'
                    className={classes.formControl}
                    variant='outlined'
                    label='Tags'
                    id='tags'
                    name='Tags'
                    value={this.state.Data.Tags}
                    onAdd={this.handleAddTag}
                    onDelete={this.handleDeleteTag}
                    chipRenderer={({ value, handleDelete }, key) =>
                      value.ActiveYN == 'Y' && (
                        <Tooltip
                          key={key}
                          title={`Added By ${value.AddedBy} on ${new Date(
                            value.AddedDate,
                          ).toLocaleDateString('en-US')}`}
                          placement='top'
                        >
                          <Chip
                            className='mb-8 mr-4'
                            color='primary'
                            icon={<Icon>location_on</Icon>}
                            onDelete={handleDelete}
                            label={value.Title}
                          />
                        </Tooltip>
                      )
                    }
                    fullWidth
                    fullWidthInput
                  />
                </div>
                <div className='flex'>
                  <div className='w-1/2'>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                  </div>
                </div>
                {this.state.ID && (
                  <div
                    className='pt-12 mt-12'
                    style={{ borderTop: '1px solid lightgrey' }}
                  >
                    <Comments
                      comments={[...this.state.Data.Comments]}
                      recordID={this.state.ID}
                      type='CustomerSite'
                      commentAdd={comment => {
                        const { Data } = this.state
                        const { Comments } = Data
                        Comments.splice(0, 0, comment)
                        this.setState({
                          ...this.state,
                          Data: { ...Data, Comments },
                        })
                      }}
                    />
                  </div>
                )}
              </TabContainer>
            )}
            {value === 1 && customerSiteDialog.type == 'edit' && (
              <TabContainer>
                <ServiceUnitsList
                  customerSite={{ ...this.state }}
                  selectedServiceUnitData={[...this.state.Data.Units]}
                />
              </TabContainer>
            )}
            {value === 2 && customerSiteDialog.type == 'edit' && (
              <TabContainer>
                <div className={classes.hist}>
                  <Drawer
                    variant='permanent'
                    className={classNames(classes.drawer, {
                      [classes.drawerOpen]: this.state.open,
                      [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                      paper: classNames(classes.shelf, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                      }),
                    }}
                    open={this.state.open}
                  >
                    <List>
                      <ListItem
                        onClick={() => this.setState({ histTab: 0 })}
                        button
                        key='Work Orders'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Orders' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.WorkOrders || 0}
                              color='error'
                            >
                              <Assignment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 1 })}
                        button
                        key='Work Order Scopes'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Order Scopes' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Scopes || 0}
                              color='error'
                            >
                              <Build />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 2 })}
                        button
                        key='Visits'
                      >
                        <ListItemIcon>
                          <Tooltip title='Trips' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Trips || 0}
                              color='error'
                            >
                              <LocalShipping />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 3 })}
                        button
                        key='Invoices'
                      >
                        <ListItemIcon>
                          <Tooltip title='Invoices' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Invoices || 0}
                              color='error'
                            >
                              <Receipt />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 4 })}
                        button
                        key='Payments'
                      >
                        <ListItemIcon>
                          <Tooltip title='Payments' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Payments || 0}
                              color='error'
                            >
                              <Payment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 5 })}
                        button
                        key='Estimates'
                      >
                        <ListItemIcon>
                          <Tooltip title='Estimates' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Estimates || 0}
                              color='error'
                            >
                              <ListIcon />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ showEmailLogs: true })}
                        button
                        key='Emails'
                      >
                        <ListItemIcon>
                          <Tooltip title='Email Log' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={
                                this.state.Data && this.state.Data.EmailLogs
                                  ? this.state.Data.EmailLogs.length
                                  : 0
                              }
                              color='error'
                            >
                              <Icon>email</Icon>
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Drawer>
                  <div className={classes.content}>
                    <div className={classes.toolbar} />
                    <div className={classes.histTable}>
                      {histTab === 0 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrdersList
                            hist={{
                              PageCount: Math.ceil(this.state.WorkOrders / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.WorkOrders,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrders || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 1 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrderScopesList
                            hist={{
                              PageCount: Math.ceil(this.state.Scopes / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Scopes,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrderScopes || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 2 && (
                        <TabContainer padding={'0px'}>
                          <TripsList
                            hist={{
                              PageCount: Math.ceil(this.state.Trips / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Trips,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedTripData={[
                              ...(this.state.Data.Trips || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 3 && (
                        <TabContainer padding={'0px'}>
                          <InvoicesList
                            hist={{
                              PageCount: Math.ceil(this.state.Invoices / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Invoices,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            invoiceData={[...(this.state.Data.Invoices || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 4 && (
                        <TabContainer padding={'0px'}>
                          <PaymentsList
                            hist={{
                              PageCount: Math.ceil(this.state.Payments / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Payments,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            payments={[...(this.state.Data.Payments || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 5 && (
                        <TabContainer padding={'0px'}>
                          {/* <EstimateDialog /> */}
                          <EstimatesList
                            hist={{
                              PageCount: Math.ceil(this.state.Estimates / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Estimates,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            estimateData={[
                              ...(this.state.Data.Estimates || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                    </div>
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 3 && customerSiteDialog.type == 'edit' && (
              <TabContainer>
                <AgreementsList
                  customerSite={{ ...this.state }}
                  selectedAgreementData={[...this.state.Data.Agreements]}
                />
              </TabContainer>
            )}
            {value === 4 && (
              <TabContainer>
                {accessLevel && accessLevel.AccessLevel !== 'R' && (
                  <React.Fragment>
                    <Typography
                      variant='h6'
                      style={{ alignItems: 'center' }}
                      className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                    >
                      <Icon color='primary' className='text-32 mr-12'>
                        add
                      </Icon>
                      {`${
                        !this.state.rateTemplate.ID ? 'Add' : 'Update'
                      } Rate Template`}
                    </Typography>
                    <div className='sm:flex'>
                      <Autocomplete
                        className={classes.formControl}
                        title='Business Unit'
                        options={businessUnits}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.BusinessUnit}>
                              {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={true}
                        value={this.state.rateTemplate.BusinessUnit || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            rateTemplate: {
                              ...this.state.rateTemplate,
                              BusinessUnit: option.BusinessUnit,
                            },
                          })
                        }
                        disabled={this.state.rateTemplate.ID}
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Division'
                        options={
                          divisions
                            ? _.filter(divisions, {
                                BusinessUnit:
                                  this.state.rateTemplate.BusinessUnit,
                              })
                            : []
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Division}>
                              {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={true}
                        value={this.state.rateTemplate.Division || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            rateTemplate: {
                              ...this.state.rateTemplate,
                              Division: option.Division,
                            },
                          })
                        }
                        disabled={this.state.rateTemplate.ID}
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='Rate Template'
                        options={_.filter(rateTemplates, {
                          Co,
                          BusinessUnit: this.state.rateTemplate.BusinessUnit,
                          Division: this.state.rateTemplate.Division,
                        })}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.RateTemplate}>
                              {value.RateTemplate} - {value.Description}
                            </MenuItem>
                          )
                        }}
                        portal={matches}
                        noflip={matches}
                        value={this.state.rateTemplate.RateTemplate || ''}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            rateTemplate: {
                              ...this.state.rateTemplate,
                              RateTemplate: option.RateTemplate,
                            },
                          })
                        }
                        required
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {!this.state.rateTemplate.ID ? (
                        <Button
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            const { Co, Customer, Site } = this.state
                            this.props.addRateTemplate({
                              ...this.state.rateTemplate,
                              Co,
                              Customer,
                              Site,
                              Data: { ErrMsg: null },
                            })
                            this.setState({
                              ...this.state,
                              rateTemplate: newCustomerSiteState.rateTemplate,
                            })
                          }}
                          // disabled={true}
                          // disabled={!this.canMemberBeSubmitted()}
                        >
                          Add
                        </Button>
                      ) : (
                        <div className='w-full flex'>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.updateRateTemplate,
                              'w-full',
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              this.props.updateRateTemplate({
                                ...this.state.rateTemplate,
                                Data: { ErrMsg: null },
                              })
                              this.setState({
                                ...this.state,
                                rateTemplate: newCustomerSiteState.rateTemplate,
                              })
                            }}
                            // disabled={!this.canMemberBeSubmitted()}
                          >
                            Update
                          </Button>
                          <Button
                            className={classNames(
                              classes.formControl,
                              classes.deleteRateTemplate,
                            )}
                            variant='contained'
                            onClick={() => {
                              this.props.removeRateTemplate({
                                ...this.state.rateTemplate,
                                Data: { ErrMsg: null },
                              })
                              this.setState({
                                ...this.state,
                                rateTemplate: newCustomerSiteState.rateTemplate,
                              })
                            }}
                            // disabled={!this.canMemberBeSubmitted()}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
                <CustomerRateTemplatesList
                  onSelected={rateTemplate =>
                    this.setState({ ...this.state, rateTemplate })
                  }
                  data={[...(this.state.Data.RateTemplates || [])]}
                />
              </TabContainer>
            )}
            {value === 5 && customerSiteDialog.type !== 'new' && (
              <TabContainer>
                <FileList
                  attachments={this.state.Data.Attachments}
                  onAdded={this.attachmentSelectedHandler}
                  accept='.jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt'
                />
              </TabContainer>
            )}
            {value === 6 && customerSiteDialog.type !== 'new' && (
              <TabContainer>
                <DashboardSelector data={this.state} form='site' />
              </TabContainer>
            )}

            {accessLevel && accessLevel.AccessLevel !== 'R' && value === 0 && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {['new', 'customer'].indexOf(customerSiteDialog.type) > -1 ? (
                  <DebounceButton
                    buttonText={'Add'}
                    processingText={'Adding'}
                    isDisabled={!this.canBeSubmitted()}
                    variant={'contained'}
                    color={'primary'}
                    clickFxn={() => {
                      var rec = this.state
                      return addCustomerSite(rec)
                    }}
                    debounceInterval={1000}
                  />
                ) : (
                  <>
                    <DebounceButton
                      buttonText={'Save'}
                      processingText={'Saving'}
                      isDisabled={!this.canBeSubmitted()}
                      variant={'contained'}
                      color={'primary'}
                      clickFxn={() => {
                        // var rec = this.state;
                        // return updateCustomerSite(rec);
                        const { data, props } = this.props.workOrderDialog
                        if (props.open === true) {
                          return (
                            updateCustomerSite(this.state),
                            this.props.updateEditWorkOrderDialog(
                              data,
                              data.Trip,
                            )
                          )
                        } else {
                          return updateCustomerSite(this.state)
                        }
                      }}
                      debounceInterval={1000}
                    />
                    {isAllowedToDelete && (
                      <DebounceButton
                        buttonText={'Delete'}
                        processingText={'Deleting'}
                        variant={'contained'}
                        className={'backgroundErrorColor'}
                        clickFxn={async () => {
                          this.setState({
                            ...this.state,
                            showDeleteConfirmation: true,
                          })
                        }}
                        debounceInterval={1000}
                      />
                    )}
                  </>
                )}
              </DialogActions>
            )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}
CustomerSitesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEditWorkOrderDialog: WOActions.updateEditWorkOrderDialog,
      updateEditCustomerSiteDialog: Actions.updateEditCustomerSiteDialog,
      closeEditCustomerSiteDialog: Actions.closeEditCustomerSiteDialog,
      closeNewCustomerSiteDialog: Actions.closeNewCustomerSiteDialog,
      addCustomerSite: Actions.addCustomerSite,
      updateCustomerSite: Actions.updateCustomerSite,
      openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
      addRateTemplate: Actions.addRateTemplate,
      updateRateTemplate: Actions.updateRateTemplate,
      removeRateTemplate: Actions.removeRateTemplate,
      openEditCustomerSiteDialog: Actions.openEditCustomerSiteDialog,
      showMessage: showMessage,
      openMinimizedDialog,
      updateAllMinimizedDialogs,
      addSplitscreenDialog,
      showSplitscreenDialog,
      setSplitscreenDialog,
      hideAllSplitscreenDialogs,
      captureSplitscreenDialogs,
      openPreloadCustomerSiteDialog,
      deleteCustomerSite: Actions.deleteCustomerSite,
    },
    dispatch,
  )
}

function mapStateToProps({
  customerSitesApp,
  fileManagerApp,
  spReducers,
  minimizedDialogApp,
  splitscreenDialogApp,
  workOrdersApp,
}) {
  return {
    workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
    customerSiteDialog: customerSitesApp.customerSites.customerSiteDialog,
    files: fileManagerApp.files,
    googleMapsApiKey: googleMapsApiKey,
    taxCodes: spReducers.taxCodes,
    user: customerSitesApp.user,
    Co: spReducers.companies.Co,
    securables: spReducers.userProfiles.User.Data.Securables,
    rateTemplates: spReducers.rateTemplates,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    splitscreenDialogs: splitscreenDialogApp.splitscreenDialog.data,
    splitscreenDialog: splitscreenDialogApp.splitscreenDialog,
    isAllowedToDelete: _.some(spReducers.userProfiles.User.Data.Securables, {
      Securable: 'deletion-manager',
    }),
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CustomerSitesDialog),
)
