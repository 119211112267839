import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, MenuItem, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;
const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newTextGatherPhraseState = {
    ID: null,
    Co: null,
    Gather: null,
    Phrase: null,
    Action: 'Enqueue',
    AssignedQueue: null,
    AssignedGather: null,
    AssignedForward: null,
    Message: '',
    MessageVoice: '',
    MessageType: 'text',
    Description: '',
    Notes: '',
    ActiveYN: 'Y',
    value: 0,
    Data: {
        ErrMsg: '',
        AudioClips: [],
    },
};

const API_KEY = process.env.REACT_APP_MAP_KEY;

class TextGatherPhrasesDialog extends Component {
    state = {
        ...newTextGatherPhraseState,
        AddedBy: '',
        AddedDate: new Date(),
    };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {

        const { UserName } = this.props.user;
        if (!_.isEqual(UserName, prevProps.user.UserName)) {
            this.setState({ ...this.state, AddedBy: UserName });
        }
        /**
         * After Dialog Open
         */
        newTextGatherPhraseState.Co = this.props.Co;
        if (!prevProps.TextGatherPhrasesDialog.props.open && this.props.TextGatherPhrasesDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.TextGatherPhrasesDialog.type === 'edit' &&
                this.props.TextGatherPhrasesDialog.data &&
                !_.isEqual(this.props.TextGatherPhrasesDialog.data, prevState)) {
                const data = this.props.TextGatherPhrasesDialog.data;
                this.setState({ ...this.props.TextGatherPhrasesDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.TextGatherPhrasesDialog.type === 'new' &&
                !_.isEqual(newTextGatherPhraseState, prevState)) {
                const { data } = this.props.TextGatherPhrasesDialog;
                window["warn"](data);
                const { Co, Gather } = data;
                this.setState({ ...newTextGatherPhraseState, Co, Gather, }, () => window["warn"](this.state));
            }
        }
        //window["log"]('STATE COMPARISON', this.props.TextGatherPhrasesDialog, prevProps, prevState);
        if (prevProps.TextGatherPhrasesDialog.props.open && this.props.TextGatherPhrasesDialog.props.open) {
            if (this.props.TextGatherPhrasesDialog.type === 'data' &&
                this.props.TextGatherPhrasesDialog.data &&
                !_.isEqual(this.props.TextGatherPhrasesDialog.data, prevState)) {
                const data = this.props.TextGatherPhrasesDialog.data;
                this.props.TextGatherPhrasesDialog.type = 'edit';
                this.setState({ ...this.props.TextGatherPhrasesDialog.data });
            }
        }
        if (prevProps.TextGatherPhrasesDialog.props.open && this.props.TextGatherPhrasesDialog.props.open) {
            if (this.props.TextGatherPhrasesDialog.type === 'add' &&
                this.props.TextGatherPhrasesDialog.data &&
                !_.isEqual(this.props.TextGatherPhrasesDialog.data, prevState)) {
                const data = this.props.TextGatherPhrasesDialog.data;
                this.props.showMessage({
                    message: `TextGatherPhrase ${this.props.TextGatherPhrasesDialog.data.TextGatherPhrase} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.TextGatherPhrasesDialog.type = 'edit';
                this.setState({ ...this.props.TextGatherPhrasesDialog.data });
            }
        }
        if (prevProps.TextGatherPhrasesDialog.props.open && this.props.TextGatherPhrasesDialog.props.open) {
            if (this.props.TextGatherPhrasesDialog.type === 'update' &&
                this.props.TextGatherPhrasesDialog.data &&
                !_.isEqual(this.props.TextGatherPhrasesDialog.data, prevState)) {
                const data = this.props.TextGatherPhrasesDialog.data;
                this.props.showMessage({
                    message: `TextGatherPhrase ${this.props.TextGatherPhrasesDialog.data.TextGatherPhrase} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.TextGatherPhrasesDialog.type = 'edit';
                this.setState({ ...this.props.TextGatherPhrasesDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.TextGatherPhrasesDialog.type === 'edit' ? this.props.closeEditTextGatherPhrasesDialog() : this.props.closeNewTextGatherPhrasesDialog();
    };

    canBeSubmitted() {
        const { Gather, Phrase, Action, AssignedQueue, AssignedDial, AssignedGather, AssignedForward, Message } = this.state;
        return (
            Gather && Gather.toString().length > 0 &&
            Phrase && Phrase.toString().length > 0 &&
            Action && Action.length > 0 &&
            (
                (AssignedQueue && AssignedQueue.length > 0) ||
                (AssignedGather && AssignedGather.toString().length > 0) ||
                (AssignedDial && AssignedDial.length > 0) ||
                (AssignedForward && AssignedForward.length > 0)
            )
        );
    }

    render() {
        const { classes, securables, theme, TextGatherPhrasesDialog, addTextGatherPhrase, updateTextGatherPhrase, removeTextGatherPhrase, businessUnits, divisions, departments, queues, awaitingCallFrom, setAwaitingCallFrom, AudioClips, gathers, huntGroups } = this.props
        const { Co, Action, } = this.state;
        const accessLevel = _.find(securables, { Securable: "text-gathers" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...TextGatherPhrasesDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(TextGatherPhrasesDialog.type) > -1 ? `Gather #${this.state.Gather} New Phrase` : `Gather #${this.state.Gather} Phrase "${this.state.Phrase}"`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <FuseAnimate animation="transition.slideUpIn" delay={300}>
                            <DialogContent classes={{ root: "p-24" }}>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        <Icon color="action">speaker_notes</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Phrase"
                                        id="phrase"
                                        name="Phrase"
                                        value={this.state.Phrase}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Phrase"
                                        disabled={Boolean(this.state.ID)}
                                    />
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Action"
                                        id="action"
                                        name="Action"
                                        value={this.state.Action}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: this.state.Action ? true : false }}
                                        variant="outlined"
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value="Enqueue">
                                            Enqueue
                                    </MenuItem>
                                        <MenuItem value="Gather">
                                            Gather
                                    </MenuItem>
                                        <MenuItem value="Forward">
                                            Forward
                                    </MenuItem>
                                    </TextField>
                                    <div className="hidden sm:block min-w-12 pt-20">
                                    </div>
                                    {
                                        Action === "Enqueue" &&
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Queue"
                                            options={_.filter(queues, { TextEnabledYN: 'Y' })}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.Queue}>
                                                    {value.Queue}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.AssignedQueue}
                                            onSelect={(option) => this.setState({ ...this.state, AssignedQueue: option.Queue })}
                                            required
                                        />
                                    }
                                    {
                                        Action === "Gather" &&
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Gather"
                                            options={gathers}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.ID}>
                                                    {value.ID} - {value.Name}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.AssignedGather}
                                            onSelect={(option) => this.setState({ ...this.state, AssignedGather: option.ID })}
                                            required
                                        />
                                    }
                                    {
                                        Action === "Forward" &&
                                        <TextField
                                            className={classes.formControl}
                                            label="Forward To"
                                            id="defaultForward"
                                            name="AssignedForward"
                                            value={this.state.AssignedForward}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.AssignedForward ? true : false }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                </div>

                                <div className="sm:flex">
                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                        <Icon color="action">note</Icon>
                                    </div>
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                            </DialogContent>
                        </FuseAnimate>

                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {['new', 'customer'].indexOf(TextGatherPhrasesDialog.type) > -1 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addTextGatherPhrase(rec);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    var rec = this.state;
                                                    updateTextGatherPhrase(rec);
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                Save
                            </Button>
                                            <IconButton
                                                onClick={() => {
                                                    var rec = this.state;
                                                    removeTextGatherPhrase(rec);
                                                    this.closeComposeDialog();
                                                }}
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
TextGatherPhrasesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTextGatherPhrasesDialog: Actions.closeEditTextGatherPhrasesDialog,
        closeNewTextGatherPhrasesDialog: Actions.closeNewTextGatherPhrasesDialog,
        addTextGatherPhrase: Actions.addTextGatherPhrase,
        updateTextGatherPhrase: Actions.updateTextGatherPhrase,
        removeTextGatherPhrase: Actions.removeTextGatherPhrase,
        getFiles: FileActions.getFiles,
        showMessage: showMessage,
        setAwaitingCallFrom: setAwaitingCallFrom,
    }, dispatch);
}

function mapStateToProps({ textGatherPhrasesApp, fileManagerApp, spReducers, dialer }) {
    return {
        TextGatherPhrasesDialog: textGatherPhrasesApp.TextGatherPhrases.TextGatherPhrasesDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        queues: spReducers.callQueues,
        gathers: spReducers.textGathers,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        awaitingCallFrom: dialer.awaitingCallFrom,
        AudioClips: spReducers.audioFiles.audioClips,
        huntGroups: spReducers.huntGroups,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TextGatherPhrasesDialog));
