import {combineReducers} from 'redux';
import dialGroups from './dial-groups.reducer';
import user from './user.reducer';

const dialGroupsAppReducers = combineReducers({
    dialGroups,
    user
});

export default dialGroupsAppReducers;
