import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import AcademyClassStep from 'main/content/apps/academy/AcademyClassStep';
import AcademyHeader from 'main/content/apps/academy/AcademyHeader';
import AcademyLeftSidebarHeader from 'main/content/apps/academy/AcademyLeftSidebarHeader';
import AcademyClassList from 'main/content/apps/academy/AcademyClassList';
import AcademyClassCategoryList from 'main/content/apps/academy/AcademyClassCategoryList';
import AcademyClassStepList from 'main/content/apps/academy/AcademyClassStepList';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';


const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class CustomersApp extends Component {

    componentDidMount() {
        const { Co } = this.props;
        const { Category } = this.props.match.params;
        if (Category && !isNaN(Category)) {
            this.props.setCategory({ Co, Category: Number(Category) });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co } = this.props;
        const { Category } = this.props.match.params;
        if (Category && !isNaN(Category) && (Category !== prevProps.match.params.Category)) {
            this.props.setCategory({ Co, Category: Number(Category) });
        }
    }

    render() {
        const { classes, openNewAcademyDialog, selectedClass } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: selectedClass ? "items-center min-h-12 h-12 sm:h-32 sm:min-h-32" : "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <AcademyHeader shrink={selectedClass} pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <AcademyLeftSidebarHeader />
                            }
                            content={
                                selectedClass ? <AcademyClassStep pageLayout={() => this.pageLayout} /> : <AcademyClassList />
                            }
                            leftSidebarContent={
                                selectedClass ? <AcademyClassStepList /> : <AcademyClassCategoryList />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        setCategory: Actions.setCategory,
    }, dispatch);
}

function mapStateToProps({ academyApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        selectedClass: academyApp.academy.class,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
