import CustomersApp from 'main/content/apps/technicians/TechniciansApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const TechniciansAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/technicians/:id',
            component: CustomersApp
        },
        {
            path     : '/apps/technicians',
            component: () => <Redirect to="/apps/technicians/all"/>
        }
    ]
};
