import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class UsersList extends Component {

    state = {
        selectedUsersMenu: null
    };

    componentDidMount() {
        window["log"]('UsersList component initial userData prop: ', this.props.userData);
    }

    componentDidUpdate(prevProps, prevState) {
        window["log"]('UsersList component userData prop updated: ', this.props.userData);
        window["log"](this.state)
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedUserMenu = (event) => {
        this.setState({ selectedUsersMenu: event.currentTarget });
    };

    closeSelectedUsersMenu = () => {
        this.setState({ selectedUsersMenu: null });
    };

    formatDate(date) {

        const dt = new Date(date);
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return (date ? formatted : '');
    }

    formatPhone(phone) {
        let formatted = phone ? phone.replace('+1', '') : phone;
        if (formatted && !isNaN(formatted)) {
            switch (formatted.length) {
                case 7:
                    {
                        formatted = `${formatted.substr(0, 3)}-${formatted.substr(3, 4)}`;
                    }
                    break;
                case 10:
                    {
                        formatted = `(${formatted.substr(0, 3)}) ${formatted.substr(3, 3)}-${formatted.substr(6, 4)}`;
                    }
                    break;
                case 11:
                    {
                        formatted = `(${formatted.substr(1, 3)}) ${formatted.substr(4, 3)}-${formatted.substr(7, 4)}`;
                    }
                    break;
            }
        }
        return formatted;
    }

    render() {
        const { classes, users, user, searchText, openNewUserDialog, selectedUserIds, selectAllUsers, deSelectAllUsers, toggleInSelectedUsers, openEditUserDialog, removeUsers, removeUser, toggleStarredUser, setUsersUnstarred, setUsersStarred, openNewWorkOrderDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedUserData ? (!this.props.userData ? [] : this.props.userData) : this.props.selectedUserData), searchText);
        const { selectedUsersMenu } = this.state;
        window["log"]("USER DATA: ", data);
        const avatar = 'assets/images/avatars/profile.jpg';
        const favatar = 'assets/images/avatars/profile_f.jpg';

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewUserDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">people</Icon>User List</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        this.props.getUserData(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "",
                                    fixed: "left",
                                    accessor: "",
                                    Cell: row => (
                                        <Avatar className="mr-8" alt={row.original.name} src={(row.original.Data && row.original.Data.Avatar ? getProfileImage(`ID=${row.original.Data.Avatar}&Thumb=true`) : row.original.Gender === 'F' ? favatar : avatar)} />
                                    ),
                                    className: "justify-center",
                                    width: 64,
                                    sortable: false
                                },
                                {
                                    Header: "User",
                                    accessor: "UserName",
                                    //filterable: true,
                                    className: "font-bold",
                                    width: 256,
                                },
                                {
                                    Header: "First Name",
                                    accessor: "FirstName",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Last Name",
                                    accessor: "LastName",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Gender",
                                    accessor: "Gender",
                                    className: "justify-center",
                                    width: 64,
                                },
                                // {
                                //     Header: "DOB",
                                //     id: "dob",
                                //     accessor: d => this.formatDate(d.DOB),
                                //     className: "justify-center",
                                //     width: 192,
                                // },
                                {
                                    Header: "Phone",
                                    accessor: "Phone",
                                    Cell: row => this.formatPhone(row.original.Phone),
                                    className: "justify-center",
                                    width: 192,
                                },
                                {
                                    Header: "Email",
                                    accessor: "Email",
                                    width: 256,
                                },
                                {
                                    Header: "Last Login",
                                    accessor: "Data.LastLogin",
                                    Cell: ({ original }) => original.Data.LastLogin && `${moment(moment(original.Data.LastLogin)).local().toDate().toLocaleDateString('en-US')} at ${moment(moment(original.Data.LastLogin)).local().toDate().toLocaleTimeString('en-US')}`,
                                    width: 192,
                                },
                                {
                                    Header: "Last Version",
                                    accessor: "Data.LastVersion",
                                    className: "justify-center",
                                    width: 128
                                },
                                {
                                    Header: "Last OS",
                                    accessor: "Data.LastOS",
                                    className: "justify-center",
                                    width: 128
                                },
                                {
                                    Header: "Active",
                                    accessor: "ActiveYN",
                                    className: "justify-center",
                                    width: 64,
                                    //filterable: true,
                                },
                            ]
                        }
                        defaultPageSize={10}
                        noDataText="No Users found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUsers: Actions.getUsers,
        getUserData: Actions.getUserData,
        toggleInSelectedUsers: Actions.toggleInSelectedUsers,
        selectAllUsers: Actions.selectAllUsers,
        deSelectAllUsers: Actions.deSelectAllUsers,
        openEditUserDialog: Actions.openEditUserDialog,
        removeUsers: Actions.removeUsers,
        removeUser: Actions.removeUser,
        toggleStarredUser: Actions.toggleStarredUser,
        toggleStarredUsers: Actions.toggleStarredUsers,
        setUsersStarred: Actions.setUsersStarred,
        setUsersUnstarred: Actions.setUsersUnstarred,
        openNewUserDialog: Actions.openNewUserDialog,
        getUserData: Actions.getUserData
    }, dispatch);
}

function mapStateToProps({ spReducers, usersApp }) {
    window["log"](spReducers, usersApp);
    return {
        users: usersApp.users.entities,
        selectedUserIds: usersApp.users.selectedUserIds,
        searchText: usersApp.users.searchText,
        user: spReducers.userProfiles.User,
        userData: spReducers.userProfiles.Users
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersList)));
