import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class OptionBoardTypesList extends Component {

    state = {
        selectedOptionBoardTypesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedOptionBoardTypeMenu = (event) => {
        this.setState({ selectedOptionBoardTypesMenu: event.currentTarget });
    };

    closeSelectedOptionBoardTypesMenu = () => {
        this.setState({ selectedOptionBoardTypesMenu: null });
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, securables, optionBoardTypes, user, searchText, openNewOptionBoardTypeDialog, selectedOptionBoardTypeIds, selectAllOptionBoardTypes, deSelectAllOptionBoardTypes, toggleInSelectedOptionBoardTypes, openEditOptionBoardTypeDialog, removeOptionBoardTypes, removeOptionBoardType, toggleStarredOptionBoardType, setOptionBoardTypesUnstarred, setOptionBoardTypesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.optionBoardTypeData ? [] : this.props.optionBoardTypeData), searchText);
        const { selectedOptionBoardTypesMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "option-board-types" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewOptionBoardTypeDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Option Board Types</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditOptionBoardTypeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Options Type",
                                accessor: "OptionsType",
                                className: "justify-center font-bold",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 384
                            },
                            {
                                Header: "Options",
                                id: "options",
                                className: "justify-center",
                                accessor: r => r.Data && r.Data.Options ? r.Data.Options.length : 0,
                                width: 96,

                            },
                            {
                                Header: "Entered By",
                                accessor: "EnteredBy",
                                className: "justify-center",
                                width: 256
                            },
                            {
                                Header: "Entered Date",
                                id: "enteredDate",
                                className: "justify-center",
                                accessor: d => this.formatDate(d.EnteredDate),
                                width: 128
                            },

                        ]}
                        defaultPageSize={10}
                        noDataText="No Option Board Types found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditOptionBoardTypeDialog: Actions.openEditOptionBoardTypeDialog,
        removeOptionBoardType: Actions.removeOptionBoardType,
        openNewOptionBoardTypeDialog: Actions.openNewOptionBoardTypeDialog
    }, dispatch);
}

function mapStateToProps({ optionBoardTypesApp, spReducers }) {
    return {
        selectedOptionBoardTypeIds: optionBoardTypesApp.optionBoardTypes.selectedOptionBoardTypeIds,
        searchText: optionBoardTypesApp.optionBoardTypes.searchText,
        user: optionBoardTypesApp.user,
        optionBoardTypeData: spReducers.optionTypes,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(OptionBoardTypesList)));
