import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from 'lodash';
import moment from 'moment';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class TeamMemberList extends Component {

    state = {
        selectedTeamMembersMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTeamMemberMenu = (event) => {
        this.setState({ selectedTeamMembersMenu: event.currentTarget });
    };

    closeSelectedTeamMembersMenu = () => {
        this.setState({ selectedTeamMembersMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { Co, classes, team, technicians } = this.props;
        const data = [...team.Data.Members];
        const avatar = 'assets/images/avatars/profile.jpg';
        const types = { 'I': 'Inventory', 'W': 'Work Order' };

        return (
            <div>
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">supervisor_account</Icon>{`Team Members`}</Typography>
                <ReactTableFixedColumns
                    className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className: "cursor-pointer",
                            onClick: (e, handleOriginal) => {
                                if (rowInfo) {
                                    this.props.onSelectedMember({ ...rowInfo.original });
                                    // openEditTeamMemberDialog({ ...rowInfo.original });
                                }
                            }
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header: "Team",
                            accessor: "Team",
                            minWidth: 160,
                            className: "font-bold justify-center",
                            Cell: ({ original }) => team && team.Name,
                        },
                        {
                            Header: "Team Member",
                            accessor: "TeamMember",
                            minWidth: 192,
                            className: "font-bold justify-center",
                            Cell: row => {
                                return technicians.map((value, index) => (
                                    value.Technician === row.original.TeamMember &&
                                    <React.Fragment>
                                        <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                        {`${value.FirstName} ${value.LastName}`}
                                    </React.Fragment>
                                ));
                            }
                        },
                        {
                            Header: "Start Date",
                            accessor: "StartDate",
                            className: "justify-center",
                            Cell: ({ original }) => original.StartDate && `${moment(original.StartDate).toDate().toLocaleDateString('en-US')}`,
                            width: 160,
                        },
                        {
                            Header: "End Date",
                            accessor: "EndDate",
                            className: "justify-center",
                            Cell: ({ original }) => original.EndDate && `${moment(original.EndDate).toDate().toLocaleDateString('en-US')}`,
                            width: 160,
                        },
                        {
                            Header: "Active",
                            accessor: "ActiveYN",
                            className: "justify-center",
                            width: 64,
                        },
                    ]}
                    defaultPageSize={10}
                    noDataText="No Team Members found"
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ teamsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        teamData: spReducers.teams,
        searchText: teamsApp.teams.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamMemberList)));
