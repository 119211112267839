import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FuseUtils, FuseAnimate } from '@fuse'
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Icon,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@material-ui/core'
import { Attachment } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import ReactTable from 'react-table'
import classNames from 'classnames'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import RGL, { WidthProvider } from 'react-grid-layout'
import _ from 'lodash'
import ChecklistItemDialog from './ChecklistItemDialog'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
// import html2canvas from 'html2canvas';
import Media from 'react-media'
import { impress } from 'main/content/compression/impress'
import BNGElement from './BNGElement'
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment'

const GridLayout = WidthProvider(RGL)

const styles = theme => ({
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  labels: {},
  checklistSection: {
    border: '1px dotted lightgrey',
    // padding: 8,
  },
  scrollEnable: {
    touchAction: 'unset !important',
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
})

class ChecklistSectionBuilder extends Component {
  state = {
    grabbing: false,
    value: null,
    notes: null,
  }

  // componentDidMount() {
  //     const { el } = this.props;
  //     const { Value, Notes } = el;
  //     this.setState({ ...this.state, value: Value, notes: Notes })
  // }

  // componentDidUpdate = (prevProps, prevState) => {
  //     const { el } = this.props;
  //     const { Value, Notes } = el;
  //     if (!_.isEqual(Value, prevProps.el.Value) || !_.isEqual(Notes, prevProps.el.Notes)) {
  //         this.setState({ ...this.state, value: Value, notes: Notes });
  //     }
  // }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedChecklistSectionMenu = event => {
    this.setState({ selectedChecklistItemsMenu: event.currentTarget })
  }

  closeSelectedChecklistItemsMenu = () => {
    this.setState({ selectedChecklistItemsMenu: null })
  }

  checklistatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  checklistatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const checklistatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return checklistatted
  }

  setGrabbing = grabbing => {
    this.setState({ ...this.state, grabbing })
  }

  handleChange = event => {
    const { el, onChange, readOnly } = this.props
    if (!readOnly) {
      this.setState(
        _.set(
          { ...this.state },
          event.target.name,
          event.target.type === 'checkbox'
            ? event.target.checked
              ? 'Y'
              : 'N'
            : event.target.value,
        ),
        () => {
          if (onChange) {
            onChange(el, this.state.value, this.state.notes)
          }
        },
      )
    }
  }

  handleLayout = layout => {
    const { checklist, onNeedsUpdate } = this.props
    const data = [...checklist.Data.Items]
    const needsUpdate = []
    layout.map((item, i) => {
      let el = data[i]
      if (
        item.x !== el.PositionX ||
        item.y !== el.PositionY ||
        item.w !== el.Columns ||
        item.h !== el.Rows
      ) {
        needsUpdate.push({
          ...el,
          PositionX: item.x,
          PositionY: item.y,
          Columns: item.w,
          Rows: item.h,
        })
      }
    })
    this.setState({ ...this.state, needsUpdate }, () =>
      onNeedsUpdate(needsUpdate),
    )
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    const { el, onChange } = this.props
    this.setState(
      {
        ...this.state,
        value: this.sigPad.getCanvas().toDataURL('image/png'),
        isSigning: false,
      },
      () => {
        if (onChange) {
          onChange(el, this.state.value)
        }
      },
    )
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
    }
  }

  fileSelectedHandler = event => {
    const { el } = this.props
    impress(event.target.files[0], 1024, 50, false, img => {
      window['log'](img)
      el.Src = img.Base64
      this.props.updateChecklistItem({ ...el, Src: img.base64 })
      // this.setState({ ...this.state, Src: img.base64 });
    })
  }

  render() {
    const { classes, scrollLock, el, editable, onChange, photoNum } = this.props
    const { grabbing } = this.state
    window['warn']('Checklist Element: ', el, this.state)
    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <div
            className='relative'
            onClick={
              this.props.onClick ? () => this.props.onClick(el) : undefined
            }
            // onMouseDown={() => this.setGrabbing(true)}
            // onMouseUp={() => this.setGrabbing(false)}
            // className={`w-full h-full checklist-section relative ${grabbing ? 'cursor-grabbing' : 'cursor-grab'}`}
          >
            {el.AttachmentEnabledYN === 'Y' && photoNum && (
              <Button
                className={classNames(
                  'absolute pin-t pin-r h-24 text-12 font-bold mt-6',
                  el.Type === 'RYG' && 'mt-2',
                )} /*variant="contained" color="primary"*/
              >
                {' '}
                <Attachment
                  style={{ transform: 'rotate(-90deg)' }}
                  className='mr-4 text-20 mb-2'
                  color='secondary'
                />
                Photo #{photoNum}
              </Button>
            )}
            {el.Type === 'CB' && (
              <div className='w-full mb-4' style={{ marginTop: -6 }}>
                <FormControlLabel
                  control={
                    <React.Fragment>
                      <img
                        src={`assets/images/icons/${
                          this.state.value === 'Y' ? 'Checkbox' : 'Unchecked'
                        }.png`}
                        className='hidden checklist-print w-16 h-16 mr-6 ml-16'
                      />
                      <Checkbox
                        className='checklist-hidden'
                        style={{ padding: 4, marginLeft: 12 }}
                        checked={el.Value === 'Y'}
                        name='value'
                        value={el.Value || 'N'}
                        color='primary'
                        margin='dense'
                      />
                    </React.Fragment>
                  }
                  margin='dense'
                  label={<b>{el.Name}</b>}
                />
                {el.EnableNotesYN === 'Y' && (
                  <div
                    className={`w-full pl-32 checklist-notes`}
                    style={{ marginTop: -10, lineHeight: '14px' }}
                  >
                    <InputBase
                      className={classes.checklistControl}
                      id='notes'
                      name='notes'
                      value={el.Notes || ''}
                      inputProps={{
                        style: {
                          padding: 0,
                          paddingTop: 2,
                          fontStyle: 'italic',
                          fontSize: 10,
                          borderBottom: '1px dotted lightgrey',
                        },
                      }}
                      disabled={!scrollLock}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            )}
            {el.Type === 'RYG' && (
              <div className='w-full mb-4' style={{ marginTop: 0 }}>
                <Typography className='font-bold text-16 mb-6'>
                  {el.Name}
                </Typography>
                <BNGElement
                  name='value'
                  value={el.Value}
                  text={
                    el.InputOptions ? JSON.parse(el.InputOptions) : undefined
                  }
                />
                {el.EnableNotesYN === 'Y' && (
                  <div
                    className={`w-full pl-32 checklist-notes`}
                    style={{ lineHeight: '14px' }}
                  >
                    <InputBase
                      className={classes.checklistControl}
                      id='notes'
                      name='notes'
                      value={el.Notes || ''}
                      inputProps={{
                        style: {
                          padding: 0,
                          paddingTop: 2,
                          fontStyle: 'italic',
                          fontSize: 11,
                          borderBottom: '1px dotted lightgrey',
                        },
                      }}
                      disabled={!scrollLock}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            )}
            {el.Type === 'SIG' && (
              <React.Fragment>
                <div className='w-full'>
                  <strong style={{ marginRight: 4 }}>{el.Name}:</strong>
                </div>
                <div
                  className='w-full relative'
                  onClick={() => {
                    this.setState({ ...this.state, isSigning: true })
                  }}
                  style={{
                    border: '1px dotted #3f3f3f',
                    width: '100%',
                    height: 'calc(100% - 64px)',
                    borderRadius: 5,
                  }}
                >
                  {this.state.value && (
                    <img
                      className='pin-t pin-b pin-l pin-r m-auto absolute'
                      style={{ width: 'auto', height: '100%' }}
                      src={this.state.value}
                    />
                  )}
                </div>
                <div
                  className='w-full mb-6'
                  style={{ lineHeight: '12px', textAlign: 'center' }}
                >
                  <strong
                    style={{ marginRight: 4, fontSize: 10, color: 'red' }}
                  >
                    {el.Description}
                  </strong>
                </div>
                <Dialog
                  open={this.state.isSigning}
                  classes={{ paper: classes.signaturePaper }}
                  fullWidth
                  maxWidth='sm'
                  onClose={() => {
                    if (this.sigPad.isEmpty()) {
                      this.setState({ ...this.state, isSigning: false })
                    }
                  }}
                >
                  <div className='w-full mb-12'>
                    <strong style={{ marginRight: 4 }}>{el.Name}:</strong>
                  </div>
                  <div
                    className='w-full'
                    style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
                  >
                    <SignatureCanvas
                      ref={ref => {
                        this.sigPad = ref
                      }}
                      penColor='#66aef9'
                      canvasProps={{
                        className: 'sigCanvas',
                        style: { width: '100%', height: 150 },
                      }}
                    />
                  </div>
                  <div className='flex w-full mt-16'>
                    <Button
                      variant='contained'
                      className={classes.clearBtn}
                      fullWidth
                      onClick={this.clear}
                    >
                      Clear
                    </Button>
                    <div className='min-w-12'></div>
                    <Button
                      variant='contained'
                      className={classes.okBtn}
                      fullWidth
                      onClick={this.trim}
                    >
                      OK
                    </Button>
                  </div>
                </Dialog>
              </React.Fragment>
            )}
          </div>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
      openNewChecklistItemDialog: Actions.openNewChecklistItemDialog,
      openEditChecklistItemDialog: Actions.openEditChecklistItemDialog,
      updateChecklistItem: Actions.updateChecklistItem,
    },
    dispatch,
  )
}

function mapStateToProps({ checklistsApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    checklistData: spReducers.checklists,
    searchText: checklistsApp.checklists.searchText,
    user: spReducers.userProfiles.User.UserName,
    employees: spReducers.employees,
    technicians: spReducers.technicians,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChecklistSectionBuilder),
  ),
)
