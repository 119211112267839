// import TeamProfile from './TeamProfile';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Dialog, DialogActions, FormControlLabel, Icon, IconButton, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { Assignment, AttachFile, Dashboard, List as ListIcon, LocalShipping, Payment, Receipt } from '@material-ui/icons';
import axios from 'axios';
import classNames from 'classnames';
import FileList from 'main/content/apps/file-manager/FileList';
import { impress } from 'main/content/compression/impress';
import { DatePicker } from "@material-ui/pickers";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector';
import * as WorkOrderActions from '../work-orders/store/actions';
import * as Actions from './store/actions';
import TeamLeadList from './TeamLeadList';
import TeamMemberList from './TeamMemberList';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import { openPreloadTeamDialog } from './store/actions/teams.actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography ref={props.refProp} component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newTeamState = {
    ID: null,
    avatar: 'assets/images/avatars/profile.jpg',
    Co: null,
    Team: null,
    Name: null,
    Description: null,
    TeamLead: null,
    Coordinator: null,
    Notes: null,
    ActiveYN: 'Y',
    DefaultBusinessUnit: null,
    DefaultDivision: null,
    DefaultDepartment: null,
    value: 0,
    histTab: 0,
    Data: {
        Members: [],
        Attachments: [],
    },
    member: {
        ID: null,
        TeamMember: null,
        StartDate: null,
        EndDate: null,
        ActiveYN: 'Y',
        Data: {
            ErrMsg: null,
        }
    },
    lead: {
        ID: null,
        TeamLead: null,
        StartDate: null,
        EndDate: null,
        ActiveYN: 'Y',
        Data: {
            ErrMsg: null,
        },
    },
    showTabs: true,
    lockEditor: true,
    slide: null,
    dialogHeight: null,
    minHeight: null,
    location: null
};

const TeamMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

class TeamsDialog extends Component {
    state = { ...newTeamState };

    ref = React.createRef();

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleEditorChange = (slide) => {
        this.setState({ ...this.state, slide })
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newTeamState.Co = this.props.Co;
        if (!prevProps.teamDialog.props.open && this.props.teamDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.teamDialog.type === 'edit' &&
                this.props.teamDialog.data &&
                !_.isEqual(this.props.teamDialog.data, prevProps.teamDialog.Data)) {
                const data = this.props.teamDialog.data;
                this.setState({ ...newTeamState, ...this.props.teamDialog.data, value: 0 });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.teamDialog.type === 'new' &&
                !_.isEqual(newTeamState, prevState)) {
                this.setState({ ...newTeamState, value: 0 });
            }
        }
        //window["log"]('STATE COMPARISON', this.props.teamDialog, prevProps, prevState);
        if (prevProps.teamDialog.props.open && this.props.teamDialog.props.open) {
            if (this.props.teamDialog.type === 'data' &&
                this.props.teamDialog.data &&
                !_.isEqual(this.props.teamDialog.data, prevProps.teamDialog.Data)) {
                const data = this.props.teamDialog.data;
                this.props.teamDialog.type = 'edit';
                this.setState({ ...this.props.teamDialog.data });
            }
        }
        if (prevProps.teamDialog.props.open && this.props.teamDialog.props.open) {
            if (this.props.teamDialog.type === 'add' &&
                this.props.teamDialog.data &&
                !_.isEqual(this.props.teamDialog.data, prevProps.teamDialog.Data)) {
                const data = this.props.teamDialog.data;
                this.props.teamDialog.type = 'edit';
                this.setState({ ...this.props.teamDialog.data });
            }
        }
        if (prevProps.teamDialog.props.open && this.props.teamDialog.props.open) {
            if (this.props.teamDialog.type === 'update' &&
                this.props.teamDialog.data &&
                !_.isEqual(this.props.teamDialog.data, prevProps.teamDialog.Data)) {
                const data = this.props.teamDialog.data;
                this.props.teamDialog.type = 'edit';
                this.setState({ ...this.props.teamDialog.data });
            }
        }
    }


    handleEmployeeChange = (event) => {
        if ([9, 13].indexOf(event.keyCode) > -1 || !event.keyCode) {
            const { employees, teams } = this.props;
            const emp = event.target.value;
            let tech = {};
            for (var i = 0; i < teams.length; i++) {
                if (teams[i].Employee == emp) {
                    tech = teams[i];
                    tech.avatar = (!tech.avatar ? newTeamState.avatar : tech.avatar);
                    this.props.teamDialog.type = 'edit';
                    this.setState(tech);
                }
            };
            if (!tech.Team) {
                for (var i = 0; i < employees.length; i++) {
                    if (employees[i].Employee == emp) {
                        tech = employees[i];
                    }
                }
                if (tech.Employee) {
                    tech.Team = (`${tech.LastName}${tech.FirstName}`).replace(/ |\.|,|\(|\)|-/g, '').toUpperCase();
                    tech.histTab = 0;
                    tech.value = 0;
                    tech.avatar = 'assets/images/avatars/profile.jpg';
                    tech.Data = {
                        WorkOrders: []
                    };
                    tech.Bio = '';
                    this.props.teamDialog.type = 'new';
                    this.setState(tech);
                } else {
                    this.props.teamDialog.type = 'new';
                    this.setState(newTeamState);
                }
            }
            if (event.keyCode) {
                event.target.blur();
            }
        }
        //this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.teamDialog.type === 'edit' ? this.props.closeEditTeamDialog() : this.props.closeNewTeamDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true})
    };

    closeDialogForSplitscreen = () => {
        this.props.teamDialog.type === 'edit' ? this.props.closeEditTeamDialog() : this.props.closeNewTeamDialog();
    };

    canBeSubmitted() {
        const { Co, TeamLead, Name } = this.state;
        return (
            Co && Name && TeamLead && Co > 0 && Name.length > 0 && TeamLead.length > 0
        );
    }

    fileSelectedHandler = (event) => {
        let { ID, Data } = this.state;
        const { teamDialog } = this.props;
        impress(event.target.files[0], 1024, 50, true, (img) => {
            window["log"](img);
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(this.state.Co)}&Type=Team&ID=${encodeURIComponent(this.state.Team)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
                if (Data) {
                    Data.Avatar = img.base64;
                } else {
                    Data = { Avatar: img.base64 };
                }
                this.setState({ Data });
            });
        });
    }

    toggleTabs = () => {
        const { showTabs } = this.state;
        this.setState({ ...this.state, showTabs: !showTabs });
    }

    setMember = (member) => {
        this.setState({ ...this.state, member });
    }

    setLead = (lead) => {
        this.setState({ ...this.state, lead });
    }

    handleStartDateChange = (event) => {
        let { StartDate } = this.state.member;
        if (event) {
            StartDate = event._d.toLocaleDateString('en-US');
            window["warn"](event, StartDate);
        } else {
            StartDate = null;
        }
        this.setState({ ...this.state, member: { ...this.state.member, StartDate } });
    }

    handleEndDateChange = (event) => {
        let { EndDate } = this.state.member;
        if (event) {
            EndDate = event._d.toLocaleDateString('en-US');
            window["warn"](event, EndDate);
        } else {
            EndDate = null;
        }
        this.setState({ ...this.state, member: { ...this.state.member, EndDate, ActiveYN: !EndDate ? "Y" : 'N' } });
    }

    handleLeadStartDateChange = (event) => {
        let { StartDate } = this.state.lead;
        if (event) {
            StartDate = event._d.toLocaleDateString('en-US');
            window["warn"](event, StartDate);
        } else {
            StartDate = null;
        }
        this.setState({ ...this.state, lead: { ...this.state.lead, StartDate } });
    }

    handleLeadEndDateChange = (event) => {
        let { EndDate } = this.state.lead;
        if (event) {
            EndDate = event._d.toLocaleDateString('en-US');
            window["warn"](event, EndDate);
        } else {
            EndDate = null;
        }
        this.setState({ ...this.state, lead: { ...this.state.lead, EndDate, ActiveYN: 'N' } });
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        const { value, showTabs } = this.state;

        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e)
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>close</Icon>
                </IconButton>
                {showTabs &&
                    <Tabs
                        value={value}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        {/* <Tab disableRipple icon={<Icon>assignment_ind</Icon>} label="Profile" /> */}
                        <Tab disableRipple icon={<Icon>supervised_user_circle</Icon>} label="Info" />
                        <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Members ? this.state.Data.Members.length : 0} color="error"><Icon>supervisor_account</Icon></Badge>} disabled={!this.state.ID} label="Members" />
                        <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Leads ? this.state.Data.Leads.length : 0} color="error"><Icon>person</Icon></Badge>} disabled={!this.state.ID} label="Team Leads" />
                        <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Attachments ? this.state.Data.Attachments.length : 0} color="error"><AttachFile /></Badge>} disabled={!this.state.ID} label="Attachments" />
                        <Tab disableRipple icon={<Dashboard />} disabled={!this.state.ID} label="Dashboard" />
                    </Tabs>
                }
            </>
        )
    }

    ToolbarContents = () => {
        const { classes, teamDialog } = this.props
        const { Co, showTabs } = this.state;
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        return (
            <>
                <Typography variant="subtitle1" color="inherit">
                    {['new', 'customer'].indexOf(teamDialog.type) > -1 ? 'New Team' :
                        <div className="flex">
                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classNames(classes.avatar, "mt-0")} alt={this.state.Team} src={(this.state.Data && this.state.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${this.state.Data.Avatar}&Thumb=true`) : avatar)} />
                            {`${this.state.Name} Team`}
                        </div>
                    }
                </Typography>
                <IconButton style={{
                    color: 'white'
                }}
                    onClick={() => {
                        this.toggleTabs();
                    }}
                >
                    {showTabs ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                </IconButton>
            </>
        )
    }

    render() {
        const { classes, securables, theme, users, technicians, teamDialog, addTeam, updateTeam, workOrders, employees, trips, removeTeam, openNewWorkOrderDialog, googleMapsApiKey, businessUnits, divisions, departments } = this.props
        const { Co, Team, Phone, value, histTab, showTabs } = this.state;
        const histTabs = ['Work Order', 'Visit', 'Invoice', 'Payment', 'Estimate'];
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const histIcons = [<Assignment className="text-24 ml-12 mr-12" />, <LocalShipping className="text-24 ml-12 mr-12" />, <Receipt className="text-24 ml-12 mr-12" />, <Payment className="text-24 ml-12 mr-12" />, <ListIcon className="text-24 ml-12 mr-12" />];
        const accessLevel = _.find(securables, { Securable: "teams" });

        const dialogProps = {
            ...teamDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "md",
            //hideBackdrop: true,
            disableEnforceFocus: true
        }

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={null}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={`${this.state.Name} Team`}
                        type="Team"
                        picRef={this.ref.current}
                        icon="supervised_user_circle"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openPreloadTeamDialog}
                        description={this.state.Description}
                        storeDialog={teamDialog}
                        TypeState={this.state.Name}
                        screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >

                        {/* {value === 0 && teamDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}><TeamProfile trips={[...this.state.Data.Trips]} timecard={{ ...this.state.Data.Timecard }} team={{ ...this.state }} /></TabContainer>} */}
                        {value === 0 && <TabContainer refProp={this.ref} maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                            {(this.state.ID) &&
                                <div className={classNames(classes.profile, "flex flex-col items-center justify-center")}>
                                    <Avatar style={{ cursor: 'pointer', boxShadow: '2px 2px 10px 2px #3f3f3f' }} onClick={() => this.fileInput.click()} className="w-1/4 h-auto" alt="contact avatar" src={(this.state.Data && this.state.Data.Avatar ? (!isNaN(this.state.Data.Avatar) ? getProfileImage(`Co=${this.state.Co}&ID=${this.state.Data.Avatar}`) : this.state.Data.Avatar) : avatar)} />
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={this.fileSelectedHandler}
                                        ref={fileInput => this.fileInput = fileInput}
                                    />
                                    {(this.state.Name && this.state.Name.length > 0) && (
                                        <Typography variant="h6" color="inherit" className="p-16">
                                            {`${this.state.Name}`}
                                        </Typography>
                                    )}
                                </div>
                            }
                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">account_circle</Icon>
                                </div>
                                <TextField
                                    className={classNames(classes.formControl, "sm:w-1/2")}
                                    label="Team"
                                    id="team"
                                    name="Team"
                                    value={this.state.Team || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Name"
                                    id="name"
                                    name="Name"
                                    value={this.state.Name || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    onFocus={() => this.setState({ ...this.state, lockEditor: false })}
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Team Lead"
                                    options={technicians}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.Technician}>
                                                <div className="flex">
                                                    <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                    {`${value.FirstName} ${value.LastName}`}
                                                </div>
                                            </MenuItem>
                                    }}
                                    value={this.state.TeamLead}
                                    noflip={true}
                                    onSelect={(option) => this.setState({ ...this.state, TeamLead: option.Technician })}
                                    required
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Coordinator"
                                    options={users}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.UserName}>
                                                <div className="flex">
                                                    <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.UserName} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                    {`${value.FirstName} ${value.LastName}`}
                                                </div>
                                            </MenuItem>
                                    }}
                                    value={this.state.Coordinator}
                                    noflip={true}
                                    onSelect={(option) => this.setState({ ...this.state, Coordinator: option.UserName })}
                                    required
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">message</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="desctription"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">business</Icon>
                                </div>

                                <TextField
                                    className={classes.formControl}
                                    label="Default Business Unit"

                                    select
                                    id="defaultBusinessUnit"
                                    name="DefaultBusinessUnit"
                                    value={this.state.DefaultBusinessUnit || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value, index) => (
                                        <MenuItem key={index} value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>

                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Default Division"
                                    id="defaultDivision"
                                    select
                                    name="DefaultDivision"
                                    value={this.state.DefaultDivision || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    {(this.state.DefaultBusinessUnit && this.state.DefaultBusinessUnit.length > 0 ?
                                        (divisions && divisions.length > 0 ? divisions.map((value) => (
                                            (this.state.DefaultBusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Division}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Business Unit
                                        </MenuItem>
                                    )
                                    }
                                </TextField>

                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Default Department"
                                    id="defaultDepartment"
                                    select
                                    name="DefaultDepartment"
                                    value={this.state.DefaultDepartment || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    {(this.state.DefaultBusinessUnit && this.state.DefaultDivision && this.state.DefaultDivision.length > 0 && this.state.DefaultBusinessUnit.length > 0 ?
                                        (departments && departments.length > 0 ? departments.map((value) => (
                                            (this.state.DefaultDivision == value.Division && this.state.DefaultBusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Department}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Division
                                        </MenuItem>
                                    )
                                    }
                                </TextField>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">note</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </TabContainer>}

                        {value === 1 &&
                            <TabContainer>
                                {accessLevel && accessLevel.AccessLevel !== "R" &&
                                    <React.Fragment>
                                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">person_add</Icon>{`${!this.state.member.ID ? "Add" : "Update"} Team Member`}</Typography>
                                        <div className="sm:flex">
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Team Member"
                                                options={technicians}
                                                menuItemComponent={(value) => {
                                                    return value.ActiveYN === "Y" &&
                                                        <MenuItem value={value.Technician}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                                {`${value.FirstName} ${value.LastName}`}
                                                            </div>
                                                        </MenuItem>
                                                }}
                                                value={this.state.member.TeamMember}
                                                noflip={true}
                                                onSelect={(option) => this.setState({ ...this.state, member: { ...this.state.member, TeamMember: option.Technician } })}
                                                required
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <DatePicker
                                                className={classes.formControl}
                                                label="Start Date"
                                                id="receivedDate"
                                                format="MM/DD/YYYY"
                                                value={this.state.member.StartDate}
                                                onChange={this.handleStartDateChange}
                                                inputVariant="outlined"
                                                showTodayButton
                                                required
                                                fullWidth
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <DatePicker
                                                className={classes.formControl}
                                                label="End Date"
                                                id="receivedDate"
                                                format="MM/DD/YYYY"
                                                value={this.state.member.EndDate}
                                                onChange={this.handleEndDateChange}
                                                inputVariant="outlined"
                                                showTodayButton
                                                clearable
                                                required
                                                fullWidth
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={classNames(classes.formControl, "sm:w-1/2")}
                                                label="Active?"
                                                id="memberActiveYN"
                                                select
                                                name="member.ActiveYN"
                                                value={this.state.member.ActiveYN || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                            >
                                                <MenuItem value="Y">
                                                    Yes
                                                </MenuItem>
                                                <MenuItem value="N">
                                                    No
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            {!this.state.member.ID ?
                                                <Button
                                                    className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        const { Co, Team, member } = this.state;
                                                        this.props.addTeamMember({ ...member, Co, Team, Data: { ErrMsg: null } });
                                                        this.setState({ ...this.state, member: newTeamState.member })
                                                    }}
                                                // disabled={!this.canMemberBeSubmitted()}
                                                >
                                                    Add
                                                </Button> :
                                                <div className="w-full flex">
                                                    <Button
                                                        className={classNames(classes.formControl, classes.updateMember, "w-full")}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            this.props.updateTeamMember({ ...this.state.member, Data: { ErrMsg: null } });
                                                            this.setState({ ...this.state, member: newTeamState.member })
                                                        }}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        className={classNames(classes.formControl, classes.deleteMember)}
                                                        variant="contained"
                                                        onClick={() => {
                                                            this.props.removeTeamMember({ ...this.state.member, Data: { ErrMsg: null } });
                                                            this.setState({ ...this.state, member: newTeamState.member })
                                                        }}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                <TeamMemberList onSelectedMember={this.setMember} team={{ ...this.state }} />
                            </TabContainer>}

                        {value === 2 &&
                            <TabContainer>
                                {accessLevel && accessLevel.AccessLevel !== "R" && this.state.lead.ID &&
                                    <React.Fragment>
                                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">person</Icon>{`Update Team Lead`}</Typography>
                                        <div className="sm:flex">
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Team Lead"
                                                options={technicians}
                                                menuItemComponent={(value) => {
                                                    return value.ActiveYN === "Y" &&
                                                        <MenuItem value={value.Technician}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                                {`${value.FirstName} ${value.LastName}`}
                                                            </div>
                                                        </MenuItem>
                                                }}
                                                value={this.state.lead.TeamLead}
                                                noflip={true}
                                                onSelect={(option) => this.setState({ ...this.state, lead: { ...this.state.lead, TeamLead: option.Technician } })}
                                                required
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <DatePicker
                                                className={classes.formControl}
                                                label="Start Date"
                                                id="leadStartDate"
                                                format="MM/DD/YYYY"
                                                value={this.state.lead.StartDate}
                                                onChange={this.handleLeadStartDateChange}
                                                inputVariant="outlined"
                                                showTodayButton
                                                required
                                                fullWidth
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <DatePicker
                                                className={classes.formControl}
                                                label="End Date"
                                                id="leadEndDate"
                                                format="MM/DD/YYYY"
                                                value={this.state.lead.EndDate}
                                                onChange={this.handleLeadEndDateChange}
                                                inputVariant="outlined"
                                                showTodayButton
                                                clearable
                                                required
                                                fullWidth
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={classNames(classes.formControl, "sm:w-1/2")}
                                                label="Active?"
                                                id="leadActiveYN"
                                                select
                                                name="lead.ActiveYN"
                                                value={this.state.lead.ActiveYN || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                            >
                                                <MenuItem value="Y">
                                                    Yes
                                                </MenuItem>
                                                <MenuItem value="N">
                                                    No
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <div className="w-full flex">
                                                <Button
                                                    className={classNames(classes.formControl, classes.updateMember, "w-full")}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        this.props.updateTeamLead({ ...this.state.lead, Data: { ErrMsg: null } });
                                                        this.setState({ ...this.state, lead: newTeamState.lead })
                                                    }}
                                                // disabled={!this.canLeadBeSubmitted()}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    className={classNames(classes.formControl, classes.deleteMember)}
                                                    variant="contained"
                                                    onClick={() => {
                                                        this.props.removeTeamLead({ ...this.state.lead, Data: { ErrMsg: null } });
                                                        this.setState({ ...this.state, lead: newTeamState.lead })
                                                    }}
                                                // disabled={!this.canLeadBeSubmitted()}
                                                >
                                                    <Icon>delete</Icon>
                                                </Button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                <TeamLeadList onSelectedLead={this.setLead} team={{ ...this.state }} />
                            </TabContainer>}
                        {value === 3 && teamDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}><FileList attachments={this.state.Data.Attachments} /></TabContainer>}
                        {value === 4 && teamDialog.type == 'edit' &&
                            <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}>
                                <DashboardSelector data={this.state} form="team" />
                            </TabContainer>}

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {(value === 0 && ['new', 'customer'].indexOf(teamDialog.type) > -1) &&
                                    <DebounceButton
                                        buttonText={"Add"}
                                        processingText={"Adding"}
                                        isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            var rec = this.state;
                                            addTeam(rec);
                                        }}
                                        debounceInterval={1000}
                                    />
                                }
                                {(value === 0 && ['new', 'customer'].indexOf(teamDialog.type) < 0) &&
                                    <DebounceButton
                                        buttonText={"Save"}
                                        processingText={"Saving"}
                                        isDisabled={!this.canBeSubmitted()}
                                        variant={"contained"}
                                        color={"primary"}
                                        clickFxn={() => {
                                            var rec = this.state;
                                            updateTeam(rec);
                                        }}
                                        debounceInterval={1000}
                                    />
                                }
                            </DialogActions>
                        }
                    </DraggableDialog>
                }
            </Media>
        );
    }
}
TeamsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTeamDialog: Actions.closeEditTeamDialog,
        closeNewTeamDialog: Actions.closeNewTeamDialog,
        addTeam: Actions.addTeam,
        updateTeam: Actions.updateTeam,
        removeTeam: Actions.removeTeam,
        openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
        openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
        showMessage: showMessage,
        addTeamMember: Actions.addTeamMember,
        updateTeamMember: Actions.updateTeamMember,
        removeTeamMember: Actions.removeTeamMember,
        updateTeamLead: Actions.updateTeamLead,
        removeTeamLead: Actions.removeTeamLead,
        impress: impress,
        openPreloadTeamDialog,
    }, dispatch);
}

function mapStateToProps({ auth, teamsApp, spReducers, dispatchBoardApp }) {
    return {
        teamDialog: teamsApp.teams.teamDialog,
        googleMapsApiKey: googleMapsApiKey,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        employees: spReducers.employees,
        teams: spReducers.teams,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        workOrders: spReducers.workOrders,
        trips: dispatchBoardApp.dispatchBoard.trips,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TeamsDialog));
