import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedCallQueueIds: [],
    routeParams       : {},
    CallQueueDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const CallQueuesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CALL_QUEUES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_CALL_QUEUES:
        {

            const CallQueueId = action.CallQueueId;

            let selectedCallQueueIds = [...state.selectedCallQueueIds];

            if ( selectedCallQueueIds.find(id => id === CallQueueId) !== undefined )
            {
                selectedCallQueueIds = selectedCallQueueIds.filter(id => id !== CallQueueId);
            }
            else
            {
                selectedCallQueueIds = [...selectedCallQueueIds, CallQueueId];
            }

            return {
                ...state,
                selectedCallQueueIds: selectedCallQueueIds
            };
        }
        case Actions.SELECT_ALL_CALL_QUEUES:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedCallQueueIds = arr.map(CallQueues => CallQueues.id);

            return {
                ...state,
                selectedCallQueueIds: selectedCallQueueIds
            };
        }
        case Actions.DESELECT_ALL_CALL_QUEUES:
        {
            return {
                ...state,
                selectedCallQueueIds: []
            };
        }
        case Actions.OPEN_NEW_CALL_QUEUE_DIALOG:
        {
            return {
                ...state,
                CallQueueDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_CALL_QUEUE_DIALOG:
        {
            return {
                ...state,
                CallQueueDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_CALL_QUEUE_DIALOG:
        {
            return {
                ...state,
                CallQueueDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_CALL_QUEUE_DIALOG:
        {
            return {
                ...state,
                CallQueueDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default CallQueuesReducer;
