import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import TaskForm from '../forms/Form';
import moment from 'moment';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    invHeader: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        fontSize: 36,
        fontWeight: 'bold',
        color: '#333'
    },
    invSubHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right',
        marginTop: 4,
        color: '#333'
    },
    dividerPlain: {
        color: '#fff',
    },
    dividerHighlight: {
        background: theme.palette.primary.main,
        color: '#fff'
    }

});

class FormsList extends Component {

    state = {
        selectedFormsMenu: null
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {

            return arr;
        }

        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedFormMenu = (event) => {
        this.setState({ selectedFormsMenu: event.currentTarget });
    };

    closeSelectedFormsMenu = () => {
        this.setState({ selectedFormsMenu: null });
    };

    formatPhone(str) {
        const phone = str.replace(/\(|\)|,|\.|-/g, '');
        if (phone.length === 10) {
            return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
        }
        return phone.length === 10 ? `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}` : str;
    }

    viewForm = (selectedForm) => {
        this.setState({ selectedForm });
    }

    render() {
        const { classes, securables, technicians, users, searchText, customer, site, unit, workOrders, agreements, agreementTypes, divisions, serviceUnitTypes, data, companies } = this.props;
        const { selectedForm } = this.state;
        const { Co, Customer, Site, UnitID } = unit;
        const { WorkOrder, Scope, TaskType, Agreement, Cycle } = (selectedForm || {});
        const selectedAgreement = selectedForm ? _.find((agreements || []), { Co, Customer, Site, Agreement, Cycle }) : null;
        const { BusinessUnit, Division, AgreementType } = (selectedAgreement || {});
        const agreementType = selectedAgreement ? _.find((agreementTypes || []), { Co, BusinessUnit, Division, AgreementType }) : null;
        const selectedTask = agreementType && TaskType ? _.find(agreementType.Data.Tasks, { TaskType }) : null;
        const selectedUnit = unit;
        const selectedDivision = _.find(divisions, { Co, BusinessUnit, Division });
        const selectedWorkOrder = selectedForm ? _.find((workOrders || []), { Co, WorkOrder }) : null;
        const selectedScope = selectedWorkOrder ? _.find((selectedWorkOrder.Data.Scopes || []), { Co, WorkOrder, Scope }) : null;
        const user = selectedForm ? _.find(users, { UserName: selectedForm.Data.Result.EnteredBy }) : null;

        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "forms" });
        window["warn"]('Selected Form: ', selectedForm, selectedTask, selectedScope, selectedDivision, selectedUnit);
        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">assignment</Icon>Forms</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        this.viewForm(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "Date",
                                    accessor: "AddedDate",
                                    className: "justify-center",
                                    width: 128,
                                    Cell: row => moment(row.value).format("M/D/YYYY")
                                },
                                {
                                    Header: "Name",
                                    accessor: "Data.Result.Name",
                                    className: "justify-center",
                                    width: 256,
                                    //filterable: true,
                                },
                                {
                                    Header: "Entered By",
                                    accessor: "Data.Result.EnteredBy",
                                    width: 256,
                                    Cell: r => {
                                        if (users) {
                                            var value = _.find(users, { UserName: r.value });
                                            if (value) {
                                                return <React.Fragment>
                                                    <Avatar style={{ marginRight: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true` ): userAvatar)} />
                                                    {`${value.FirstName} ${value.LastName}`}
                                                </React.Fragment>
                                            } else {
                                                return r.EnteredBy
                                            }
                                        } else {
                                            return r.EnteredBy
                                        }
                                    }
                                },
                            ]
                        }
                        defaultPageSize={10}
                        noDataText="No Forms found"
                    />
                    {(selectedForm && selectedTask && selectedUnit && selectedScope) &&
                        <TaskForm
                            readOnly={true}
                            header={selectedDivision && selectedDivision.FormHeaderImg ? selectedDivision.FormHeaderImg : undefined}
                            footer={selectedDivision && selectedDivision.FormFooterImg ? selectedDivision.FormFooterImg : undefined}
                            assignment={{
                                Co: selectedTask.Co,
                                Customer: selectedTask.Customer,
                                Site: selectedTask.Site,
                                Agreement: selectedTask.Agreement,
                                Cycle: selectedTask.Cycle,
                                TaskType: selectedTask.TaskType,
                                UnitID: selectedUnit.UnitID,
                                WorkOrder: selectedScope.WorkOrder,
                                Scope: selectedScope.Scope
                            }}
                            scope={{ ...selectedScope }}
                            form={{ ...selectedForm.Data.Result }}
                            open={Boolean(selectedForm)}
                            onAdd={(formData) => {

                            }}
                            onUpdate={(formData) => {

                            }}
                            onDelete={(formData) => {

                            }}
                            onClose={() => this.setState({ selectedForm: null })}
                            info={selectedScope && [selectedScope].map((scope) => {
                                const { Co, BusinessUnit, Division } = scope || {};
                                const division = _.find(divisions, { Co, BusinessUnit, Division });
                                return (
                                    <div className="w-full">
                                        <div className="w-full flex justify-between my-24">
                                            {division && division.InvoiceHeaderImg &&
                                                <img className="h-48 sm:h-96" src={division.InvoiceHeaderImg} />
                                            }
                                            <span className="sm:mt-12">
                                                <label className={classNames(classes.invHeader, "sm:pr-8 text-20 sm:text-36")}>CHECKLIST</label>
                                                <div className={classNames(classes.invSubHeader, "sm:pr-8 text-10 sm:text-16")}>Work Order #{this.state.WorkOrder}-{selectedScope.Scope}</div>
                                            </span>
                                        </div>

                                        <div className="flex mb-24 justify-between p-16 pr-4">
                                            <div className="cursor-pointer">
                                                <div className="w-full text-16 font-bold text-left">
                                                    {customer.Name && customer.Name.length > 0 ? customer.Name : `${customer.FirstName} ${customer.LastName}`}
                                                </div>
                                                <div className="w-full text-12 text-left">
                                                    {customer.Address1}
                                                </div>
                                                {customer.Address2 &&
                                                    <div className="w-full text-12 text-left">
                                                        {customer.Address2}
                                                    </div>
                                                }
                                                <div className="w-full text-12 text-left">
                                                    {`${customer.City}, ${customer.State} ${customer.Zip}`}
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex cursor-pointer" onClick={this.openDatePicker}>
                                                    <img src="assets/icons/calendar_icon.png" className="w-32 h-32 mr-8" />
                                                    <div className="w-full">
                                                        <div className="text-14 font-bold">
                                                            {selectedForm.ResultID ? moment(selectedForm.EnteredDate).format('dddd') : moment(Date()).format('dddd')}
                                                        </div>
                                                        <div className="text-10">
                                                            {selectedForm.ResultID ? moment(selectedForm.EnteredDate).format('MMM DD YYYY') : moment(Date()).format('MMM DD YYYY')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex mt-24">
                                            <div className="w-full">
                                                <div className="w-full flex justify-between rounded-full mb-12" style={{ backgroundColor: '#3f3f3f' }}>
                                                    <div className={classNames("w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer", classes.dividerHighlight)}>
                                                        {selectedForm.Data.Result.Name}
                                                    </div>
                                                    <div className={classNames("w-2/3 py-8 px-24 text-12 font-bold text-right truncate", classes.dividerPlain)}>
                                                        {`${site.Address1} ${site.City}, ${site.State}, ${site.Zip}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-between">
                                            <div className="text-12 text-left pl-16">
                                                <div className="w-full">
                                                    <strong className="mr-8 font-bold">Task:</strong>
                                                    <label>{selectedTask.Name}</label>
                                                </div>
                                                {/* {selectedUnit.UnitCat && _.find(serviceUnitCategories, { UnitCat: selectedUnit.UnitCat }) &&
                                                    <div className="w-full">
                                                        <strong className="mr-8 font-bold">Category:</strong>
                                                        <label>{_.find(serviceUnitCategories, { UnitCat: selectedUnit.UnitCat }).Name}</label>
                                                    </div>
                                                } */}
                                                {selectedUnit.UnitType && _.find(serviceUnitTypes, { UnitType: selectedUnit.UnitType }) &&
                                                    <div className="w-full">
                                                        <strong className="mr-8 font-bold">Unit Type:</strong>
                                                        <label>{_.find(serviceUnitTypes, { UnitType: selectedUnit.UnitType }).Name}</label>
                                                    </div>
                                                }
                                                <div className="w-full">
                                                    <strong className="mr-8 font-bold">Unit ID:</strong>
                                                    <label>{`${selectedUnit.UnitID} - ${selectedUnit.Name}`}</label>
                                                </div>
                                                {selectedUnit.Manufacturer &&
                                                    <div className="w-full">
                                                        <strong className="mr-8 font-bold">Manufacturer:</strong>
                                                        <label>{selectedUnit.Manufacturer}</label>
                                                    </div>
                                                }
                                                {selectedUnit.Model &&
                                                    <div className="w-full">
                                                        <strong className="mr-8 font-bold">Model:</strong>
                                                        <label>{selectedUnit.Model}</label>
                                                    </div>
                                                }
                                                {selectedUnit.SerialNumber &&
                                                    <div className="w-full">
                                                        <strong className="mr-8 font-bold">Serial #:</strong>
                                                        <label>{selectedUnit.SerialNumber}</label>
                                                    </div>
                                                }
                                            </div>
                                            {user &&
                                                <div className="flex mr-24 mt-4" style={{ marginBottom: -28 }}>
                                                    <img src="assets/icons/person_icon.png" className="w-24 h-24 mr-4" />
                                                    <div className="w-full">
                                                        <div className="text-12 font-bold mt-4">
                                                            {`${user.FirstName} ${user.LastName}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>


                                    </div>
                                );
                            })
                            }
                            footerInfo={selectedScope && [selectedScope].map((scope) => {
                                const { Co } = scope || {};
                                const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co;
                                if (co) {
                                    return (
                                        <div className="mx-24">
                                            <div className="w-full sm:flex sm:justify-between">
                                                <div className="mt-24">
                                                    <div className="w-full flex">
                                                        <img src="assets/icons/location_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                {co.Name}
                                                            </div>
                                                            <div className="text-10">
                                                                {co.Address}
                                                            </div>
                                                            <div className="text-10">
                                                                {`${co.City}, ${co.State} ${co.Zip}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-24">
                                                    <div className="w-full flex">
                                                        <img src="assets/icons/email_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                Email
                                                            </div>
                                                            <div className="text-10">
                                                                {co.Email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-24">
                                                    <div className="w-full flex">
                                                        <img src="assets/icons/phone_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                Phone
                                                            </div>
                                                            <div className="text-10">
                                                                {this.formatPhone(co.Phone)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                            }
                        />
                    }
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        // openEditFormDialog: Actions.openEditFormDialog,
        // openNewFormDialog: Actions.openNewFormDialog
    }, dispatch);
}

function mapStateToProps({ spReducers, formsApp }) {
    return {
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        selectedFormIds: formsApp.forms.selectedFormIds,
        searchText: formsApp.forms.searchText,
        user: formsApp.user,
        formData: spReducers.forms,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        securables: spReducers.userProfiles.User.Data.Securables,
        divisions: spReducers.divisions,
        agreementTypes: spReducers.agreementTypes,
        serviceUnitTypes: spReducers.serviceUnitTypes
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FormsList)));
