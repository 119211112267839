import { FuseAnimate, FuseSelectedTheme } from '@fuse';
import { Chip, Icon, Paper } from '@material-ui/core';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import ChipInput from 'material-ui-chip-input';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchTrips, clearSearchTrips } from '../technicians/store/actions';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {},
    container: {
        position: 'relative',
    },
    chipContainer: {
        paddingTop: 3,
    },
});

class CatalogDialogSearch extends Component {
    state = {
        open: false,
        searchText: null
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.open && prevProps.searchResults && !this.props.searchResults) {
            this.setState({ ...this.state, open: false, searchText: null });
        }
    }

    clearSearch = () => {
        this.setState({ ...this.state, open: false, searchText: null }, this.clear);
    }

    setSearchText = (e) => {
        this.setState({ ...this.state, searchText: e.target.value });
    }

    handleAddTerm = (term) => {
        let { Co, searchText } = this.state;
        if (!searchText) {
            // this.setSearchText({ target: { value: term } });
            if (term && term.length > 0) {
                this.setState({ ...this.state, searchText: term }, this.search);
            } else {
                this.setState({ ...this.state, searchText: term });
            }
        } else {
            term = `${searchText}|${term}`;
            if (term && term.length > 0) {
                this.setState({ ...this.state, searchText: term }, this.search);
            } else {
                this.setState({ ...this.state, searchText: term });
            }
        }
    }

    handleDeleteTerm = (term) => {
        let { searchText } = this.state;
        let newText = searchText ? searchText.replace(`|${term}`, '').replace(`${term}|`, '').replace(term, '') : "";
        // this.setSearchText({ target: { value: newText } });
        if (newText && newText.length > 0) {
            this.setState({ ...this.state, searchText: newText }, this.search);
        } else {
            this.setState({ ...this.state, searchText: null });
        }
    }

    search = () => {
        const { Co, tech, searchTrips } = this.props;
        const { searchText } = this.state;
        this.setState({ ...this.state, open: true, searching: true }, () => searchTrips(Co, tech, searchText));
    }

    clear = () => {
        this.props.clearSearchTrips();
    }

    render() {
        const { Co, classes, setSearchText, pageLayout, shrink } = this.props;
        const { open, searchText } = this.state;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className={`flex flex-1`}>
                    <Paper className="flex items-center w-full h-32 mb-4 rounded-8 px-0 md:pl-20" style={{ boxShadow: 'none' }} elevation={1}>
                        {!open ?
                            <Icon color="action" className="cursor-pointer" onClick={() => this.setState({ ...this.state, open: !this.state.open })}>search</Icon>
                            :
                            <Icon color="action" className="cursor-pointer mr-4" onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{searchText && searchText.length > 0 ? "filter_list" : "search"}</Icon>
                        }
                        {open &&
                            <FuseAnimate animation="transition.slideRightBigIn">
                                <div className="flex flex-1">
                                    <ChipInput  blurBehavior="add"
                                        className={classNames(classes.input)}
                                        classes={{
                                            chipContainer: classes.chipContainer,
                                        }}
                                        id="terms"
                                        placeholder="Search Work Orders"
                                        alwaysShowPlaceholder={true}
                                        blurBehavior="add"
                                        value={searchText && searchText.length > 0 ? searchText.split('|') : []}
                                        disableUnderline={true}
                                        onAdd={this.handleAddTerm}
                                        onDelete={this.handleDeleteTerm}
                                        chipRenderer={({ value, handleDelete }, key) =>
                                            <Chip key={key} className="mr-4" color="primary" onDelete={handleDelete} label={value} />
                                        }
                                        fullWidth
                                    />
                                    <Icon color="action" className="cursor-pointer mt-8" onClick={this.clearSearch}>close</Icon>
                                </div>
                            </FuseAnimate>
                        }
                    </Paper>
                </div>
            </MuiThemeProvider>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        searchTrips,
        clearSearchTrips,
    }, dispatch);
}

function mapStateToProps({ spReducers, techniciansApp }) {
    return {
        Co: spReducers.companies.Co,
        searchResults: techniciansApp.technicians.technicianDialog.data && techniciansApp.technicians.technicianDialog.data.Data && techniciansApp.technicians.technicianDialog.data.Data.searchResults ? techniciansApp.technicians.technicianDialog.data.Data.searchResults : null
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogDialogSearch));
