import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import { Button, Hidden, Icon, IconButton, Input, InputAdornment, Menu, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { FuseAnimate, FuseSelectedTheme } from '@fuse';
import _ from '@lodash';

const styles = theme => ({
    root: {}
});

class RateUpdatesSearch extends Component {
    state = {
        open: false,
        category: null,
        variance: null,
    }

    componentDidMount() {
        const { category, variance } = this.props;
        this.setState({ ...this.state, category, variance });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { category, variance } = this.props;
        if (!_.isEqual(category, prevProps.category) || !_.isEqual(variance, prevProps.variance)) {
            this.setState({ ...this.state, category, variance });
        }
    }

    openMenu = (e) => {
        const { loading } = this.state;
        if (!loading) {
            this.setState({ ...this.state, menu: true, anchorEl: e.target });
        }
    }

    closeMenu = () => {
        this.setState({ ...this.state, menu: false, anchorEl: null });
    }

    getMainCategory = (cat) => {
        const { catalog } = this.props;
        const { Categories } = catalog.Data;
        if (cat.ParentCategory) {
            const parent = _.find(Categories, { Category: cat.ParentCategory });
            if (parent) {
                return this.getMainCategory(parent);
            } else {
                return cat;
            }
        } else {
            return cat;
        }
    }

    render() {
        const { classes, setSearchText, searchText, pageLayout, shrink, catalog } = this.props;
        const { open, category, variance } = this.state;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex flex-1">
                    {catalog &&
                        <Paper className="flex items-center w-full h-36 mt-4 p-16 rounded-8 " style={{ boxShadow: 'none' }} elevation={1}>
                            {!this.state.open ?
                                <Icon color="action" className="cursor-pointer" onClick={this.openMenu}>filter_list</Icon>
                                :
                                <Icon color="action" className="cursor-pointer" onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{searchText && searchText.length > 0 ? "filter_list" : "search"}</Icon>
                            }
                            <Menu id="price-book-menu" classes={{ paper: "min-w-256" }} anchorEl={this.state.anchorEl} open={Boolean(this.state.menu)} onClose={this.closeMenu}>
                                <div className="p-12 min-w-320 max-w-320">
                                    <Typography className="flex items-center flex-1 font-bold mb-12"><Icon className="mr-8" color="primary">filter_list</Icon>Filter Parameters</Typography>
                                    <Autocomplete
                                        className="w-full mb-12"
                                        title="Category"
                                        options={[{ Category: null, Name: "Any" }, ..._.filter(this.props.catalog.Data.Categories, { Type: 'T' })]}
                                        menuItemComponent={(value) => {
                                            const main = this.getMainCategory(value);
                                            return <MenuItem value={value.Category}>
                                                <div className="w-5/6 truncate" style={{ direction: 'rtl' }}>{`${main ? `${main.Name} > ... > ` : ''}${value.Name}`}</div>
                                            </MenuItem>
                                        }}
                                        portal={true}
                                        value={this.state.category || ''}
                                        onSelect={(option) => this.setState({ ...this.state, category: option.Category }, () => this.props.onChange(this.state.category, variance))}
                                        required
                                    />
                                    <TextField
                                        className="w-full mb-12"
                                        variant="outlined"
                                        label="Min Variance"
                                        id="minVariance"
                                        value={this.state.variance || ''}
                                        onChange={(e) => this.setState({ ...this.state, variance: e.target.value }, () => this.props.onChange(category, this.state.variance))}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="w-full"
                                        onClick={() => { this.closeMenu(); this.props.onSearch(); }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Menu>
                        </Paper>
                    }
                </div>
            </MuiThemeProvider>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText,
    }, dispatch);
}

function mapStateToProps({ catalogApp }) {
    return {
        searchText: catalogApp.searchText
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RateUpdatesSearch));
