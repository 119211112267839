import axios from 'axios';
import { getUserData } from 'main/content/apps/inventory-location-materials/store/actions/user.actions';
import { showMessage } from 'store/actions';
import { UPDATE_EDIT_INVENTORY_LOCATION_DIALOG } from 'main/content/apps/inventory-locations/store/actions';
import _ from '@lodash';

export const GET_INVENTORY_LOCATION_MATERIALS = '[INVENTORY LOCATION MATERIALS APP] GET INVENTORY LOCATION MATERIALS';
export const SET_SEARCH_TEXT = '[INVENTORY LOCATION MATERIALS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_INVENTORY_LOCATION_MATERIALS = '[INVENTORY LOCATION MATERIALS APP] TOGGLE IN SELECTED INVENTORY LOCATION MATERIALS';
export const SELECT_ALL_INVENTORY_LOCATION_MATERIALS = '[INVENTORY LOCATION MATERIALS APP] SELECT ALL INVENTORY LOCATION MATERIALS';
export const DESELECT_ALL_INVENTORY_LOCATION_MATERIALS = '[INVENTORY LOCATION MATERIALS APP] DESELECT ALL INVENTORY LOCATION MATERIALS';
export const OPEN_NEW_INVENTORY_LOCATION_MATERIAL_DIALOG = '[INVENTORY LOCATION MATERIALS APP] OPEN NEW INVENTORY LOCATION MATERIAL DIALOG';
export const CLOSE_NEW_INVENTORY_LOCATION_MATERIAL_DIALOG = '[INVENTORY LOCATION MATERIALS APP] CLOSE NEW INVENTORY LOCATION MATERIAL DIALOG';
export const OPEN_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG = '[INVENTORY LOCATION MATERIALS APP] OPEN EDIT INVENTORY LOCATION MATERIAL DIALOG';
export const UPDATE_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG = '[INVENTORY LOCATION MATERIALS APP] UPDATE EDIT INVENTORY LOCATION MATERIAL DIALOG';
export const CLOSE_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG = '[INVENTORY LOCATION MATERIALS APP] CLOSE EDIT INVENTORY LOCATION MATERIAL DIALOG';
export const ADD_INVENTORY_LOCATION_MATERIAL = '[INVENTORY LOCATION MATERIALS APP] ADD INVENTORY LOCATION MATERIAL';
export const UPDATE_INVENTORY_LOCATION_MATERIAL = '[INVENTORY LOCATION MATERIALS APP] UPDATE INVENTORY LOCATION MATERIAL';
export const REMOVE_INVENTORY_LOCATION_MATERIAL = '[INVENTORY LOCATION MATERIALS APP] REMOVE INVENTORY LOCATION MATERIAL';
export const REMOVE_INVENTORY_LOCATION_MATERIALS = '[INVENTORY LOCATION MATERIALS APP] REMOVE INVENTORY LOCATION MATERIALS';
export const TOGGLE_STARRED_INVENTORY_LOCATION_MATERIAL = '[INVENTORY LOCATION MATERIALS APP] TOGGLE STARRED INVENTORY LOCATION MATERIAL';
export const TOGGLE_STARRED_INVENTORY_LOCATION_MATERIALS = '[INVENTORY LOCATION MATERIALS APP] TOGGLE STARRED INVENTORY LOCATION MATERIALS';
export const SET_INVENTORY_LOCATION_MATERIALS_STARRED = '[INVENTORY LOCATION MATERIALS APP] SET INVENTORY LOCATION MATERIALS STARRED ';

export function getInventoryLocationMaterials(routeParams) {
    const request = axios.get('/api/inventory-location-materials-app/inventory-location-materials', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_INVENTORY_LOCATION_MATERIALS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedInventoryLocationMaterials(inventoryLocationMaterialId) {
    return {
        type: TOGGLE_IN_SELECTED_INVENTORY_LOCATION_MATERIALS,
        inventoryLocationMaterialId
    }
}


export function selectAllInventoryLocationMaterials() {
    return {
        type: SELECT_ALL_INVENTORY_LOCATION_MATERIALS
    }
}

export function deSelectAllInventoryLocationMaterials() {
    return {
        type: DESELECT_ALL_INVENTORY_LOCATION_MATERIALS
    }
}


export function openNewInventoryLocationMaterialDialog(data) {
    return {
        type: OPEN_NEW_INVENTORY_LOCATION_MATERIAL_DIALOG,
        data
    }
}

export function closeNewInventoryLocationMaterialDialog() {
    return {
        type: CLOSE_NEW_INVENTORY_LOCATION_MATERIAL_DIALOG
    }
}

export function openPreloadInventoryLocationMaterialDialog(data){
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG,
            data: data,
        })
    }
}

export function openEditInventoryLocationMaterialDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/InventoryLocationMaterial?Co=${data.Co}&Location=${encodeURIComponent(data.Location)}&Material=${encodeURIComponent(data.Material)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG,
                data: response.data
            })
        });
    }
}

export function updateEditInventoryLocationMaterialDialog(data) {
    return {
        type: UPDATE_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG,
        data
    }
}

export function closeEditInventoryLocationMaterialDialog() {
    return {
        type: CLOSE_EDIT_INVENTORY_LOCATION_MATERIAL_DIALOG
    }
}

export function addInventoryLocationMaterial(newInventoryLocationMaterial) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.inventoryLocationsApp.inventoryLocations.inventoryLocationDialog;

        const request = axios.post(`${window["apiLocation"]}/api/InventoryLocationMaterial`,
            newInventoryLocationMaterial
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Inventory Location Material #${response.data.Material} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                data.Data.Materials.push(response.data);
                dispatch({
                    type: UPDATE_EDIT_INVENTORY_LOCATION_DIALOG,
                    data
                });
                dispatch({
                    type: ADD_INVENTORY_LOCATION_MATERIAL,
                    data: response.data
                });
            }
        }
        );
    };
}

export function updateInventoryLocationMaterial(inventoryLocationMaterial) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.inventoryLocationsApp.inventoryLocations.inventoryLocationDialog;

        const request = axios.put(`${window["apiLocation"]}/api/InventoryLocationMaterial?Co=${encodeURIComponent(inventoryLocationMaterial.Co)}&Location=${encodeURIComponent(inventoryLocationMaterial.Location)}&Material=${encodeURIComponent(inventoryLocationMaterial.Material)}`,
            inventoryLocationMaterial
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Inventory Location Material #${inventoryLocationMaterial.Material} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const index = _.findIndex(data.Data.Materials, { Material: response.data.Material });
                if (index > -1) {
                    data.Data.Materials.splice(index, 1, response.data);
                }
                dispatch({
                    type: UPDATE_EDIT_INVENTORY_LOCATION_DIALOG,
                    data
                });
                dispatch({
                    type: UPDATE_INVENTORY_LOCATION_MATERIAL,
                    data: response.data
                });
            }
        }
        );
    };
}

export function removeInventoryLocationMaterial(inventoryLocationMaterial) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.inventoryLocationsApp.inventoryLocations.inventoryLocationDialog;

        const request = axios.delete(`${window["apiLocation"]}/api/InventoryLocationMaterial?Co=${encodeURIComponent(inventoryLocationMaterial.Co)}&Material=${encodeURIComponent(inventoryLocationMaterial.Material)}`,
            inventoryLocationMaterial
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Inventory Location Material #${inventoryLocationMaterial.Material} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const index = _.findIndex(data.Data.Materials, { Material: inventoryLocationMaterial.Material});
                if (index > -1) {
                    data.Data.Materials.splice(index, 1);
                }
                dispatch({
                    type: UPDATE_EDIT_INVENTORY_LOCATION_DIALOG,
                    data
                });
                dispatch({
                    type: REMOVE_INVENTORY_LOCATION_MATERIAL,
                    data: inventoryLocationMaterial
                })
            }
        }
        );
    };
}


export function removeInventoryLocationMaterials(inventoryLocationMaterialIds) {

}

export function toggleStarredInventoryLocationMaterial(inventoryLocationMaterialId) {

}

export function toggleStarredInventoryLocationMaterials(inventoryLocationMaterialIds) {

}

export function setInventoryLocationMaterialsStarred(inventoryLocationMaterialIds) {

}

export function setInventoryLocationMaterialsUnstarred(inventoryLocationMaterialIds) {

}
