import * as Actions from 'store/actions/spConnection';
import _ from '@lodash';

const initialState = {
    connected: false,
    subscriptions: []
}
const connectionStatus = function (state = initialState, action) {
    switch (action.type) {
        case Actions.ADD_SUBSCRIPTION:
            {
                const { List } = action;
                let { subscriptions } = state;
                const index = _.indexOf(subscriptions, List);
                if (index < 0) {
                    subscriptions.push(List);
                }
                return {
                    ...state,
                    subscriptions
                };
            }
        case Actions.REMOVE_SUBSCRIPTION:
            {
                const { List } = action;
                const subscriptions = _.without(state.subscriptions, List);
                return {
                    ...state,
                    subscriptions
                };
            }
        case Actions.CONNECTED_SIGNAL_R:
            {
                const { connected } = action;
                return {
                    ...state,
                    connected
                };
            }
    }

    return state;
};

export default connectionStatus;