import {
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  Input,
  OutlinedInput,
} from '@material-ui/core'
import React, { useState } from 'react'

const getPropertyByDotNotation = (obj, dotNotation) => {
  return dotNotation.split('.').reduce((acc, key) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return acc[key]
    }
    return undefined
  }, obj)
}

const SelectWrapper = props => {
  const [hasFocus, setHasFocus] = useState(false)

  const {
    options,
    values,
    onChange,
    required,
    multiple,
    label,
    nameProperty,
    valueProperty,
  } = props

  const effectiveLabel = `${label}${required ? ' *' : ''}`

  return (
    <div
      className={'w-full overflow-x-hidden'}
      onMouseEnter={e => e.stopPropagation()}
      onMouseMove={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}
      style={{ paddingTop: 14, marginTop: -14 }}
    >
      <FormControl className='w-full'>
        <InputLabel variant='outlined'>{effectiveLabel}</InputLabel>
        <Select
          className='mb-12'
          value={values}
          renderValue={selected =>
            selected
              .map(x => getPropertyByDotNotation(x, nameProperty))
              .join(', ')
          }
          onChange={onChange}
          required={required}
          input={<OutlinedInput label={effectiveLabel} />}
          inputProps={{
            onFocus: () => setHasFocus(true),
            onBlur: () => setHasFocus(false),
          }}
          multiple={multiple}
        >
          {options.map(option => {
            const optionName = getPropertyByDotNotation(option, nameProperty)
            const optionValue = getPropertyByDotNotation(option, valueProperty)

            const isOptionAlreadyAdded = values.find((currentValue)=>{
              return getPropertyByDotNotation(currentValue, valueProperty) === optionValue
            }) != undefined;

            return (
              <MenuItem key={optionValue} value={optionValue}>
                <Checkbox checked={isOptionAlreadyAdded} />
                <ListItemText primary={optionName} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectWrapper
