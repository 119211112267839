import {combineReducers} from 'redux';
import employees from './employees.reducer';
import user from './user.reducer';

const employeesAppReducers = combineReducers({
    employees,
    user
});

export default employeesAppReducers;
