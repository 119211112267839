import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedDialGroupIds: [],
    routeParams: {},
    dialGroupDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            GroupID: null,
            Name: '',
            Description: '',
            Timeout: 10,
            Seq: null,
            Notes: '',
            ActiveYN: 'Y',
            AddedBy: '',
            AddedDate: new Date(),
            value: 0,
            Data: {
                ErrMsg: '',
                Members: [],
            },
        }
    },
    searchResults: [],
};

const dialGroupsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_RESULTS:
            {
                return {
                    ...state,
                    searchResults: action.payload,
                }
            }
        case Actions.GET_DIAL_GROUPS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_DIAL_GROUPS:
            {

                const dialGroupId = action.dialGroupId;

                let selectedDialGroupIds = [...state.selectedDialGroupIds];

                if (selectedDialGroupIds.find(id => id === dialGroupId) !== undefined) {
                    selectedDialGroupIds = selectedDialGroupIds.filter(id => id !== dialGroupId);
                }
                else {
                    selectedDialGroupIds = [...selectedDialGroupIds, dialGroupId];
                }

                return {
                    ...state,
                    selectedDialGroupIds: selectedDialGroupIds
                };
            }
        case Actions.SELECT_ALL_DIAL_GROUPS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedDialGroupIds = arr.map(dialGroups => dialGroups.id);

                return {
                    ...state,
                    selectedDialGroupIds: selectedDialGroupIds
                };
            }
        case Actions.DESELECT_ALL_DIAL_GROUPS:
            {
                return {
                    ...state,
                    selectedDialGroupIds: []
                };
            }
        case Actions.OPEN_NEW_DIAL_GROUP_DIALOG:
            {
                const { Co, ID } = action.data;
                return {
                    ...state,
                    dialGroupDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.dialGroupDialog.data,
                            Co,
                            GroupID: ID,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_DIAL_GROUP_DIALOG:
            {
                return {
                    ...state,
                    dialGroupDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_DIAL_GROUP_DIALOG:
            {
                return {
                    ...state,
                    dialGroupDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_DIAL_GROUP_DIALOG:
                {
                    return {
                        ...state,
                        dialGroupDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_DIAL_GROUP_DIALOG:
            {
                return {
                    ...state,
                    dialGroupDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default dialGroupsReducer;
