import _ from '@lodash';
import { AppBar, Badge, Button, Chip, Dialog, DialogActions, FormControlLabel, Icon, IconButton, Switch, Tab, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
});
const newOptionBoardTypeState = {
    ID: null,
    Co: null,
    OptionsType: null,
    Name: null,
    Description: null,
    Notes: '',
    Data: {
        Options: [],
        ErrMsg: null,
    },
    ActiveYN: "Y",
    optionName: null,
    value: 0,
};

class OptionBoardTypeDialog extends Component {
    state = { ..._.cloneDeepWith(newOptionBoardTypeState) };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newOptionBoardTypeState.Co = this.props.Co;
        if (!prevProps.optionBoardTypeDialog.props.open && this.props.optionBoardTypeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.optionBoardTypeDialog.type === 'edit' &&
                this.props.optionBoardTypeDialog.data &&
                !_.isEqual(this.props.optionBoardTypeDialog.data, prevState)) {
                this.setState({ ...this.props.optionBoardTypeDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.optionBoardTypeDialog.type === 'new' &&
                !_.isEqual(_.cloneDeepWith(newOptionBoardTypeState), prevState)) {
                this.setState({ ..._.cloneDeepWith(newOptionBoardTypeState) });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.optionBoardTypeDialog.type === 'edit' ? this.props.closeEditOptionBoardTypeDialog() : this.props.closeNewOptionBoardTypeDialog();
    };

    canBeSubmitted() {
        const { Name, } = this.state;
        return (
            Name &&
            Name.length > 0
        );
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    addOption = () => {
        const { optionName, Data } = this.state;
        const { Options } = Data;
        const next = _.maxBy(Options, 'Option');
        const Option = next ? next.Option + 1 : 1
        Options.push({
            Option,
            Name: optionName,
            DisplaySeq: Option - 1,
        });
        this.setState({ ...this.state, Data: { ...this.state.Data, Options }, optionName: null });
    }

    removeOption = (option) => {
        const { Option } = option;
        const { Options } = this.state.Data;
        const index = _.findIndex(Options, { Option });
        if (index > -1) {
            if (!option.ID) {
                Options.splice(index, 1);
            } else {
                option.DeleteYN = 'Y';
                Options.splice(index, 1, option);
            }
            this.setState({ ...this.state, Data: { ...this.state.Data, Options } });
        }
    }

    reorderOptions = (result) => {
        const { source, destination } = result;
        if (source && destination) {
            if (source.index !== destination.index) {

                const { Options } = this.state.Data;

                const ordered = this.reorder(
                    Options,
                    result.source.index,
                    result.destination.index
                );

                for (var i = 0; i < ordered.length; i++) {
                    ordered[i].DisplaySeq = i;
                }

                this.setState({ ...this.state, Data: { ...this.state.Data, Options: ordered } });
            }
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    render() {
        const { classes, securables, optionBoardTypeDialog, addOptionBoardType, updateOptionBoardType } = this.props;
        const { value } = this.state;
        const accessLevel = _.find(securables, { Securable: "option-board-types" });

        return (
            <Media query={value === 1 ? "(min-width: 768px) and (min-height: 769px)" : "(min-width: 512px) and (min-height: 512px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...optionBoardTypeDialog.props}
                        open={Boolean(accessLevel) && optionBoardTypeDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={"xs"}
                    >
                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {optionBoardTypeDialog.type === 'new' ? 'New Option Board Type' : `Option Board Type #${this.state.OptionsType}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                            >
                                <Tab disableRipple icon={<Icon>table_chart</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Options ? this.state.Data.Options.length : 0} color="error"><Icon>list</Icon></Badge>} label="Options" />
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                            <TabContainer>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Name"
                                        id="name"
                                        name="Name"
                                        value={this.state.Name}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                {/* <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        // required
                                        fullWidth
                                    />
                                </div> */}

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <div className="w-1/2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                </div>
                            </TabContainer>
                        }
                        {value === 1 &&
                            <TabContainer>

                                <div className="flex">

                                    <TextField
                                        className={classNames(classes.formControl, "mb-8")}
                                        label="Option Name"
                                        id="optionName"
                                        name="optionName"
                                        value={this.state.optionName || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="w-full mb-12"
                                        onClick={this.addOption}
                                        disabled={!this.state.optionName}
                                    >
                                        Add Option
                                    </Button>
                                </div>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Options</Typography>

                                <DragDropContext onDragEnd={this.reorderOptions}>
                                    <Droppable
                                        droppableId="options"
                                        type="option"
                                        direction="vertical"
                                    // ignoreContainerClipping={800}
                                    >
                                        {(provided) => (
                                            <div ref={provided.innerRef}>
                                                {_.filter(this.state.Data.Options, (o) => o.DeleteYN !== 'Y').map((value) => {
                                                    return (
                                                        <Draggable key={`Option${value.Option}`} draggableId={`Option${value.Option}`} index={value.DisplaySeq} type="option">
                                                            {(provided2, snapshot) => (
                                                                <div
                                                                    ref={provided2.innerRef}
                                                                    {...provided2.draggableProps}
                                                                    {...provided2.dragHandleProps}
                                                                >
                                                                    <Chip key={value.Option} deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removeOption(value)} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classes.colorSecondary}>list</Icon>} label={<span>{value.Name}</span>} />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            </div>
                                        )
                                        }
                                    </Droppable>
                                </DragDropContext>
                            </TabContainer>}

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {this.props.optionBoardTypeDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addOptionBoardType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateOptionBoardType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditOptionBoardTypeDialog: Actions.closeEditOptionBoardTypeDialog,
        closeNewOptionBoardTypeDialog: Actions.closeNewOptionBoardTypeDialog,
        addOptionBoardType: Actions.addOptionBoardType,
        updateOptionBoardType: Actions.updateOptionBoardType,
        removeOptionBoardType: Actions.removeOptionBoardType
    }, dispatch);
}

function mapStateToProps({ optionBoardTypesApp, spReducers }) {
    return {
        optionBoardTypeDialog: optionBoardTypesApp.optionBoardTypes.optionBoardTypeDialog,
        Co: spReducers.companies.Co,
        optionBoardTypes: spReducers.optionBoardTypes,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(OptionBoardTypeDialog));
