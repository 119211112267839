import React, { Component } from 'react'
import {
  Grow,
  Slide,
  TextField,
  Tooltip,
  Badge,
  Tabs,
  Tab,
  ButtonGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  InputAdornment,
  Typography,
  Toolbar,
  AppBar,
  Avatar,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import { connect } from 'react-redux'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import classNames from 'classnames'
import _ from '@lodash'
import Media from 'react-media'
import BarcodeScanner from 'main/content/components/barcode-scanner/BarcodeScanner'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import axios from 'axios'
import PurchaseOrderReceivingList from './PurchaseOrderReceivingList'
import { impress } from 'main/content/compression/impress'
import { BrowserQRCodeSvgWriter, BarcodeFormat } from '@zxing/library'
import ChangeDialog from '../../components/change-log/ChangeDialog'
import WorkOrdersApp from '../work-orders/WorkOrdersApp'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import { openPreloadPurchaseOrderLineDialog } from './store/actions/purchase-order-lines.actions'
import getProfileImage from '../../../functions/getProfileImageUrl'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Marker({ text, icon, style, badge, heading }) {
  return (
    <Tooltip title={text} placement='top'>
      {
        <Badge
          badgeContent={badge ? badge : 0}
          classes={{ badge: 'inventory-map-badge' }}
          color='error'
        >
          <img style={style} src={icon} />
        </Badge>
      }
    </Tooltip>
  )
}

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

const styles = theme => ({
  root: {},
  formControl: {
    marginBottom: 24,
  },
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
  updateReceiving: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteReceiving: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})
const newPurchaseOrderLineState = {
  ID: null,
  Co: null,
  PO: null,
  POLine: null,
  POLineType: null,
  DateNeeded: new Date(),
  Description: null,
  UOM: null,
  VendorMaterial: null,
  Material: null,
  InventoryLocation: null,
  WorkOrder: null,
  Scope: null,
  Qty: null,
  CostType: null,
  UnitCost: null,
  CostTotal: null,
  TaxableYN: 'N',
  TaxCode: null,
  TaxRate: null,
  TaxTotal: null,
  ReceivingYN: 'N',
  ReceivedQty: null,
  ReceivedCostTotal: null,
  ReceivedTaxTotal: null,
  RemainingQty: null,
  RemainingCostTotal: null,
  RemainingTaxTotal: null,
  Notes: null,
  AttachmentID: null,
  Data: {
    ErrMsg: null,
    Receiving: [],
  },
  receiving: {
    ID: null,
    Co: null,
    PO: null,
    POLine: null,
    Seq: null,
    ReceivedQty: null,
    ReceivedCostTotal: null,
    ReceivedTaxTotal: null,
    ReceivedDate: new Date(),
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Data: {
      ErrMsg: '',
    },
  },
  scanner: null,
  isScanning: false,
  value: 0,
  searching: false,
  menuEl: null,
  viewChanges: false,
  dialogHeight: null,
  minHeight: null,
  location: null,
}

class PurchaseOrderLineDialog extends Component {
  state = { ..._.cloneDeepWith(newPurchaseOrderLineState) }

  ref = React.createRef()

  // componentDidMount() {
  //     const { Co, company } = this.props;
  //     newPurchaseOrderLineState.Co = Co;
  //     const options = (company && company.Data) ? company.Data.PurchaseOrderOptions : null;
  //     if (options) {
  //         newPurchaseOrderLineState.UOM = options.DefaultUOM;
  //         newPurchaseOrderLineState.CostType = options.DefaultCostType;
  //     }
  //     this.setState({ ..._.cloneDeepWith(newPurchaseOrderLineState) });
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { Co, company } = this.props
    newPurchaseOrderLineState.Co = Co
    const options =
      company && company.Data ? company.Data.PurchaseOrderOptions : null
    if (options) {
      newPurchaseOrderLineState.UOM = options.DefaultUOM
      newPurchaseOrderLineState.CostType = options.DefaultCostType
    }
    /**
     * After Dialog Open
     */
    if (
      !prevProps.purchaseOrderLineDialog.props.open &&
      this.props.purchaseOrderLineDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.purchaseOrderLineDialog.type === 'edit' &&
        this.props.purchaseOrderLineDialog.data &&
        !_.isEqual(this.props.purchaseOrderLineDialog.data, prevState)
      ) {
        this.setState({
          ..._.cloneDeepWith(newPurchaseOrderLineState),
          ...this.props.purchaseOrderLineDialog.data,
          receiving: {
            ID: null,
            Co: null,
            PO: null,
            POLine: null,
            ReceivedQty: null,
            ReceivedCostTotal: null,
            ReceivedTaxTotal: null,
            ReceivedDate: new Date(),
            Notes: null,
            AddedBy: null,
            AddedDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            Data: {
              ErrMsg: '',
            },
          },
        })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      const { data } = this.props.purchaseOrderLineDialog
      const { user, fleetVehicles, employees, inventoryLocations, taxCodes } =
        this.props
      const employee =
        employees && user
          ? _.find(employees, { UserName: user.UserName })
          : null
      const fleetVehicle =
        fleetVehicles && employee
          ? _.find(fleetVehicles, { Operator: employee.Employee })
          : null
      const InventoryLocation = data.InventoryLocation
        ? data.InventoryLocation
        : inventoryLocations && fleetVehicle
          ? (
              _.find(inventoryLocations, {
                MobileYN: 'Y',
                FleetVehicle: fleetVehicle.FleetVehicle,
              }) || {}
            ).Location
          : null
      if (
        this.props.purchaseOrderLineDialog.type === 'new' &&
        !_.isEqual(
          { ..._.cloneDeepWith(newPurchaseOrderLineState), ...data },
          prevState,
        )
      ) {
        let TaxRate = 0.0
        if (taxCodes && data.TaxCode) {
          for (var t = 0; t < taxCodes.length; t++) {
            if (taxCodes[t].TaxCode === data.TaxCode) {
              TaxRate = (
                new Date(taxCodes[t].EffectiveDate) <= new Date()
                  ? taxCodes[t].NewRate
                  : taxCodes[t].OldRate
              ).toFixed(6)
            }
          }
        }
        const { UOM, CostType } = newPurchaseOrderLineState
        this.setState({
          ..._.cloneDeepWith(newPurchaseOrderLineState),
          ...data,
          UOM: UOM || data.UOM,
          CostType: CostType || data.CostType,
          TaxRate,
          InventoryLocation,
        })
      }
    } else {
      if (
        this.props.purchaseOrderLineDialog.type === 'update' &&
        this.props.purchaseOrderLineDialog.data &&
        !_.isEqual(this.props.purchaseOrderLineDialog.data, prevState)
      ) {
        this.props.purchaseOrderLineDialog.type = 'edit'
        this.setState({
          ...this.state,
          ...this.props.purchaseOrderLineDialog.data,
        })
      }
    }
    if (this.state.Data && this.state.Data.POLine) {
      const { Description, UOM, Data } = this.state.Data.POLine
      if (this.state.Description !== this.state.Data.POLine.Description) {
        this.setState({
          ...this.state,
          Description,
          UOM,
          Data: {
            ...this.state.Data,
            Avatar: Data && Data.Avatar ? Data.Avatar : null,
          },
        })
      }
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
      this.handleTechLabor,
    )
  }

  handleDateChange = event => {
    let OdometerDate = this.formatDate(event._d)
    this.setState({ ...this.state, OdometerDate })
  }

  closeComposeDialog = () => {
    this.props.purchaseOrderLineDialog.type === 'edit'
      ? this.props.closeEditPurchaseOrderLineDialog()
      : this.props.closeNewPurchaseOrderLineDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.purchaseOrderLineDialog.type === 'edit'
      ? this.props.closeEditPurchaseOrderLineDialog()
      : this.props.closeNewPurchaseOrderLineDialog()
  }

  canBeSubmitted() {
    const { company } = this.props
    const options =
      company && company.Data ? company.Data.PurchaseOrderOptions : null
    const {
      PO,
      POLine,
      Description,
      POLineType,
      WorkOrder,
      Scope,
      InventoryLocation,
      Qty,
      UnitCost,
      CostTotal,
      UOM,
      CostType,
    } = this.state
    return (
      PO &&
      POLine &&
      Description &&
      !isNaN(POLine) &&
      PO.length > 0 &&
      Description.length > 0 &&
      ((CostType !== null && CostType !== undefined) ||
        !options ||
        options.RequireCostType !== 'Y') &&
      Qty &&
      UOM &&
      UnitCost &&
      CostTotal &&
      (POLineType === 'W' ? WorkOrder && Scope : InventoryLocation)
    )
  }

  canReceivingBeSubmitted() {
    const { receiving } = this.state
    const { ReceivedQty, ReceivedCostTotal, ReceivedTaxTotal, ReceivedDate } =
      receiving
    return (
      ReceivedQty &&
      ReceivedCostTotal &&
      ReceivedDate &&
      ReceivedQty > 0 &&
      ReceivedCostTotal > 0
    )
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }

  showQR = () => {
    const { Line } = this.state
    const el = document.getElementById('qr-code')
    if (el) {
      el.innerHTML = ''
      if (this.props.purchaseOrderLineDialog.type !== 'new' && Line) {
        const codeWriter = new BrowserQRCodeSvgWriter()
        // const hints = new Map();
        // const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_39, BarcodeFormat.EAN_13/*, ...*/];
        // hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        // codeWriter.setHints(hints);
        codeWriter.writeToDom('#qr-code', Line, 30, 30)
        codeWriter.writeToDom('#scannable', Line, 300, 300)
        window['warn']('QR Code generated')
      }
    }
  }

  handleDateNeededChange = event => {
    let { DateNeeded } = this.state
    DateNeeded = event._d.toLocaleDateString('en-US')
    window['warn'](event, DateNeeded)
    this.setState({ ...this.state, DateNeeded })
  }

  handleReceivedDateChange = event => {
    let { ReceivedDate } = this.state.receiving
    ReceivedDate = event._d.toLocaleDateString('en-US')
    window['warn'](event, ReceivedDate)
    this.setState({
      ...this.state,
      receiving: { ...this.state.receiving, ReceivedDate },
    })
  }

  handleQR = () => {
    this.setState({ ...this.state, viewingQR: true }, () =>
      setTimeout(this.showQR, 250),
    )
  }

  closeQR = () => {
    this.setState({ ...this.state, viewingQR: false })
  }

  handlePart = part => {
    const { Qty } = this.state
    const { Cost, Material, Data, Description, UOM } = part
    this.setState(
      {
        ...this.state,
        Material,
        Qty: Qty ? Qty : 1,
        Description,
        UOM,
        UnitCost: Cost,
        CostTotal: Qty ? (Qty * Cost).toFixed(2) : null,
        Data: {
          ...this.state.Data,
          Material: part,
          Avatar: Data && Data.Avatar ? Data.Avatar : null,
        },
        isScanning: false,
      },
      this.formatUnitCost,
    )
  }

  handleVendorPart = part => {
    const { Qty } = this.state
    const { Cost, Material, Data, VendorMaterial } = part
    const { Description, UOM } = Data.Material
    this.setState(
      {
        ...this.state,
        Material,
        Qty: Qty ? Qty : 1,
        Description,
        UOM,
        VendorMaterial,
        UnitCost: Cost,
        CostTotal: Qty ? (Qty * Cost).toFixed(2) : null,
        Data: {
          ...this.state.Data,
          Material: Data.Material,
          Avatar: Data && Data.Material.Avatar ? Data.Material.Avatar : null,
        },
        isScanning: false,
      },
      this.formatUnitCost,
    )
  }

  getPart = barcode => {
    const { Co, Material } = this.state
    const { purchaseOrder } = this.props
    const Vendor = purchaseOrder ? purchaseOrder.Vendor : null
    if (Vendor) {
      const request = axios
        .get(
          `${window['apiLocation']}/api/VendorMaterial?Co=${encodeURIComponent(
            Co,
          )}&Vendor=${encodeURIComponent(Vendor)}&Material=${encodeURIComponent(
            barcode ? barcode : Material,
          )}`,
        )
        .then(response => {
          if (response.data) {
            this.handleVendorPart(response.data)
          } else {
            const request2 = axios
              .get(
                `${window['apiLocation']}/api/Material?Co=${encodeURIComponent(
                  Co,
                )}&Material=${encodeURIComponent(
                  barcode ? barcode : Material,
                )}`,
              )
              .then(response2 => {
                if (response2.data) {
                  this.handlePart(response2.data)
                } else {
                }
              })
          }
        })
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ ...this.state, value })
  }

  formatUnitCost = () => {
    const {
      Qty,
      UnitCost,
      CostTotal,
      TaxRate,
      ReceivingYN,
      ReceivedQty,
      ReceivedCostTotal,
      ReceivedTaxTotal,
    } = this.state
    this.setState({
      ...this.state,
      UnitCost: UnitCost ? parseFloat(UnitCost).toFixed(2) : null,
      CostTotal: Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null,
      TaxTotal:
        Qty && UnitCost && TaxRate
          ? parseFloat(UnitCost * Qty * (TaxRate / 100)).toFixed(2)
          : null,
      // ReceivedQty: ReceivingYN !== 'Y' ? Qty : ReceivedQty,
      // ReceivedCostTotal: ReceivingYN !== 'Y' ? Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null : ReceivedCostTotal,
      // ReceivedTaxTotal: ReceivingYN !== 'Y' ? Qty && UnitCost && TaxRate ? parseFloat((UnitCost * Qty) * (TaxRate / 100)).toFixed(2) : null : ReceivedTaxTotal,
      // RemainingQty: ReceivingYN !== 'Y' ? '0' : Qty - ReceivedQty,
      // RemainingCostTotal: ReceivingYN !== 'Y' ? '0.00' : Qty && UnitCost && ReceivedCostTotal ? parseFloat((UnitCost * Qty) - ReceivedCostTotal).toFixed(2) : null,
      // RemainingTaxTotal: ReceivingYN !== 'Y' ? '0.00' : Qty && UnitCost && TaxRate && ReceivedTaxTotal ? parseFloat(((UnitCost * Qty) * (TaxRate / 100)) - ReceivedTaxTotal).toFixed(2) : null
    })
  }

  formatCostTotal = () => {
    const {
      Qty,
      UnitCost,
      CostTotal,
      TaxRate,
      ReceivingYN,
      ReceivedQty,
      ReceivedCostTotal,
      ReceivedTaxTotal,
    } = this.state
    this.setState({
      ...this.state,
      UnitCost:
        CostTotal && Qty ? parseFloat(CostTotal / Qty).toFixed(2) : null,
      CostTotal: CostTotal ? parseFloat(CostTotal).toFixed(2) : null,
      TaxTotal:
        CostTotal && TaxRate
          ? parseFloat(CostTotal * (TaxRate / 100)).toFixed(2)
          : null,
      // ReceivedQty: ReceivingYN !== 'Y' ? Qty : ReceivedQty,
      // ReceivedCostTotal: ReceivingYN !== 'Y' ? CostTotal ? parseFloat(CostTotal).toFixed(2) : null : ReceivedCostTotal,
      // ReceivedTaxTotal: ReceivingYN !== 'Y' ? CostTotal && TaxRate ? parseFloat(CostTotal * (TaxRate / 100)).toFixed(2) : null : ReceivedTaxTotal,
      // RemainingQty: ReceivingYN !== 'Y' ? '0' : Qty - ReceivedQty,
      // RemainingCostTotal: ReceivingYN !== 'Y' ? '0.00' : CostTotal && ReceivedCostTotal ? parseFloat(CostTotal - ReceivedCostTotal).toFixed(2) : null,
      // RemainingTaxTotal: ReceivingYN !== 'Y' ? '0.00' : CostTotal && TaxRate && ReceivedTaxTotal ? parseFloat((CostTotal * (TaxRate / 100)) - ReceivedTaxTotal).toFixed(2) : null
    })
  }

  setReceivedCost = () => {
    const {
      Qty,
      UnitCost,
      CostTotal,
      TaxableYN,
      TaxRate,
      TaxTotal,
      receiving,
    } = this.state
    const { ReceivedQty, ReceivedCostTotal, ReceivedTaxTotal } = receiving
    this.setState({
      ...this.state,
      receiving: {
        ...receiving,
        ReceivedQty,
        ReceivedCostTotal: ReceivedQty * UnitCost,
        ReceivedTaxTotal:
          TaxableYN === 'Y'
            ? (ReceivedQty * UnitCost * (TaxRate / 100)).toFixed(2)
            : '0.00',
      },
    })
  }

  setReceiving = receiving => {
    this.setState({ ...this.state, receiving })
  }

  addReceiving = data => {
    this.props.addPurchaseOrderReceiving(data)
  }

  updateReceiving = data => {
    this.props.updatePurchaseOrderReceiving(data)
  }

  removeReceiving = data => {
    this.props.removePurchaseOrderReceiving(data)
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { menuEl, value } = this.state
    const { securables } = this.props
    const accessLevel = _.find(securables, { Securable: 'purchase-orders' })

    return (
      <>
        <IconButton
          style={{
            position: 'absolute',
            right: 56,
            top: matches ? 10 : 4,
            color: 'white',
          }}
          className='dialog-header-icon'
          onClick={e => this.setState({ menuEl: e.target })}
        >
          <Icon>more_vert</Icon>
        </IconButton>

        <Menu
          id='dialog-menu'
          classes={
            {
              /*paper: "min-w-256"*/
            }
          }
          anchorEl={menuEl}
          open={Boolean(menuEl)}
          onClose={() => this.setState({ menuEl: null })}
        >
          <MenuItem
            onClick={() => {
              this.setState({ menuEl: null, viewChanges: true })
            }}
          >
            <Icon className='mr-8'>history</Icon>View Changes
          </MenuItem>
        </Menu>
        <ChangeDialog
          type='PO_LINE'
          rec={this.state.ID}
          restore={data => this.setState({ ...data, viewChanges: false })}
          accessLevel={accessLevel}
          data={this.state}
          open={this.state.viewChanges}
          onClose={() => this.setState({ viewChanges: false })}
        />
        <IconButton
          style={{
            position: 'absolute',
            right: 104,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 10 : 4,
            color: 'white',
          }}
          onClick={e => {
            closeFxn(e)
          }}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
        <Tabs value={value} onChange={this.handleTabChange}>
          <Tab disableRipple icon={<Icon>crop_free</Icon>} label='Info' />
          <Tab
            disableRipple
            icon={
              <Badge
                max={999}
                badgeContent={
                  this.state.Data && this.state.Data.Receiving
                    ? _.sumBy(this.state.Data.Receiving, o => {
                        return o.ReceivedQty
                      })
                    : 0
                }
                color='error'
              >
                <Icon>check_box</Icon>
              </Badge>
            }
            label='Receiving'
            disabled={this.props.purchaseOrderLineDialog.type === 'new'}
          />
        </Tabs>
      </>
    )
  }

  ToolbarContents = () => {
    const { purchaseOrderLineDialog, classes } = this.props
    const { Data, viewingQR, Line } = this.state

    return (
      <>
        {this.props.purchaseOrderLineDialog.type !== 'new' && Line && (
          <React.Fragment>
            <div
              id='qr-code'
              onClick={this.handleQR}
              className={classes.qrPreview}
            ></div>
            <Dialog open={viewingQR} onClose={this.closeQR}>
              <div id='scannable' className={classes.qr}></div>
            </Dialog>
          </React.Fragment>
        )}
        <Typography variant='subtitle1' color='inherit'>
          {Boolean(Data.VPPOIB || Data.VPPOIT) && (
            <Tooltip
              placement='top'
              title={
                Data.VPPOIB && Data.VPPOIB.OrigCost
                  ? this.formatDollars(Data.VPPOIB.OrigCost)
                  : Data.VPPOIT && Data.VPPOIT.OrigCost
                    ? this.formatDollars(Data.VPPOIT.OrigCost)
                    : ''
              }
            >
              <Icon className='mr-8 mb-4' style={{ verticalAlign: 'middle' }}>
                {Boolean(
                  Data.VPPOIB && Data.VPPOIB.OrigCost === this.state.CostTotal,
                )
                  ? 'cloud_upload'
                  : Boolean(
                        Data.VPPOIT &&
                          Data.VPPOIT.OrigCost === this.state.CostTotal,
                      )
                    ? 'cloud_done'
                    : 'error_outline'}
              </Icon>
            </Tooltip>
          )}
          {purchaseOrderLineDialog.type === 'new'
            ? `PO# ${this.state.PO} New PO Line`
            : `PO #${this.state.PO} Line #${this.state.POLine}`}
        </Typography>
      </>
    )
  }

  render() {
    const {
      classes,
      securables,
      uoms,
      purchaseOrderLineDialog,
      addPurchaseOrderLine,
      company,
      updatePurchaseOrderLine,
      removePurchaseOrderLine,
      workOrderData,
      addPurchaseOrderReceiving,
      updatePurchaseOrderReceiving,
      removePurchaseOrderReceiving,
      costTypes,
      inventoryLocations,
      employees,
      materials,
      taxCodes,
    } = this.props
    let {
      PurchaseOrderLine,
      ID,
      isScanning,
      viewingQR,
      Line,
      value,
      searching,
      Data,
      menuEl,
    } = this.state
    const { readOnly } = purchaseOrderLineDialog.props
    const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
    const accessLevel = _.find(securables, { Securable: 'purchase-orders' })

    let scopes = null
    if (
      this.state.Data.WorkOrder &&
      this.state.Data.WorkOrder.Data &&
      this.state.Data.WorkOrder.Data.Scopes
    ) {
      scopes = this.state.Data.WorkOrder.Data.Scopes
      if (
        workOrderData &&
        workOrderData.Trip &&
        workOrderData.WorkOrder === this.state.Data.WorkOrder.WorkOrder
      ) {
        const trip = _.find(workOrderData.Data.Trips, {
          Trip: workOrderData.Trip,
        })
        if (trip) {
          scopes = _.filter(
            this.state.Data.WorkOrder.Data.Scopes,
            o =>
              _.findIndex(trip.Data.Assignments, { Scope: o.Scope }) > -1 ||
              o.Scope === this.state.Scope,
          )
        }
      }
    }
    const poOptions =
      company && company.Data ? company.Data.PurchaseOrderOptions || {} : {}

    const dialogProps = {
      ...purchaseOrderLineDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <DraggableDialog
            SlideUp={SlideUp}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this,
            )}
            header={`PO #${this.state.PO} Line #${this.state.POLine}`}
            type='Purchase Order Line'
            picRef={this.ref.current}
            icon='format_list_numbered'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadPurchaseOrderLineDialog}
            description={this.state.Description}
            storeDialog={purchaseOrderLineDialog}
            TypeState={this.state.POLine}
            screenOverlay={this.state.screenOverlay}
            //posSet={this.state.posSet}
            //initialWidthSet={initialWidthSet}
            //minHeight={this.state.minHeight}
            //location={this.state.location}
            //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
            //dialogHeight={this.state.dialogHeight}
          >
            <BarcodeScanner
              open={isScanning}
              onScan={this.getPart}
              onClose={() => {
                this.setState({ ...this.state, isScanning: false })
              }}
            />

            {value === 0 && (
              <TabContainer refProp={this.ref}>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>format_list_numbered</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Line Type'
                    select
                    id='poLineType'
                    name='POLineType'
                    value={this.state.POLineType}
                    onChange={this.handleChange}
                    variant='outlined'
                    InputLabelProps={{
                      shrink: this.state.POLineType ? true : false,
                    }}
                    fullWidth
                    disabled={Boolean(this.state.ID)}
                    required
                  >
                    <MenuItem value='I'>Inventory</MenuItem>
                    <MenuItem value='W'>Work Order</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  {this.state.POLineType === 'I' && (
                    <TextField
                      className={classes.formControl}
                      label='Stock Location'
                      id='stockLocation'
                      name='InventoryLocation'
                      value={this.state.InventoryLocation}
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: this.state.InventoryLocation ? true : false,
                      }}
                      fullWidth
                      variant='outlined'
                      disabled={Boolean(this.state.ID)}
                      select
                      required
                    >
                      {inventoryLocations &&
                        inventoryLocations.map((value, index) => (
                          <MenuItem key={index} value={value.Location}>
                            <div className='flex'>{`${value.Description}`}</div>
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                  {this.state.POLineType === 'W' && (
                    <React.Fragment>
                      {this.state.Data.WorkOrder ? (
                        <Autocomplete
                          className={classes.formControl}
                          title='Work Order'
                          options={[this.state.Data.WorkOrder]}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.WorkOrder}>
                                {`${
                                  value.WorkOrder
                                } - ${value.Description.substr(0, 30)}`}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          noflip={true}
                          value={this.state.WorkOrder || ''}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              WorkOrder: option.WorkOrder,
                            })
                          }
                          disabled={true}
                          required
                        />
                      ) : (
                        <TextField
                          className={classes.formControl}
                          label='Work Order'
                          id='workOrder'
                          name='WorkOrder'
                          value={this.state.WorkOrder}
                          InputLabelProps={{
                            shrink: this.state.WorkOrder ? true : false,
                          }}
                          onChange={this.handleChange}
                          variant='outlined'
                          required
                          disabled={Boolean(this.state.ID)}
                          fullWidth
                        />
                      )}
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {scopes ? (
                        <Autocomplete
                          className={classes.formControl}
                          title='Scope'
                          options={scopes}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.Scope}>
                                {`${value.Scope} - ${value.Description.substr(
                                  0,
                                  30,
                                )}`}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          noflip={true}
                          value={this.state.Scope || ''}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              Scope: option.Scope,
                            })
                          }
                          required
                        />
                      ) : (
                        <TextField
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          label='Scope'
                          id='scope'
                          name='Scope'
                          value={this.state.Scope}
                          InputLabelProps={{
                            shrink: this.state.Scope ? true : false,
                          }}
                          onChange={this.handleChange}
                          variant='outlined'
                          required
                          disabled={Boolean(this.state.ID)}
                          fullWidth
                        />
                      )}
                    </React.Fragment>
                  )}
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <DatePicker
                    variant='inline'
                    inputVariant='outlined'
                    className={classes.formControl}
                    label='Date Needed'
                    id='dateNeeded'
                    format='MM/DD/YYYY'
                    value={this.state.DateNeeded}
                    onChange={this.handleDateNeededChange}
                    showTodayButton
                    autoOk
                    animateYearScrolling
                    required
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div
                    onClick={() => {
                      this.setState({ ...this.state, isScanning: true })
                    }}
                    className='min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20 cursor-pointer'
                  >
                    <Icon color='error'>crop_free</Icon>
                  </div>
                  <TextField
                    className={classNames(
                      classes.formControl,
                      'w-full sm:w-1/2',
                    )}
                    label='Part #'
                    id='Material'
                    name='Material'
                    value={this.state.Material}
                    onChange={this.handleChange}
                    onBlur={() => this.getPart()}
                    InputLabelProps={{
                      shrink: this.state.Material ? true : false,
                    }}
                    fullWidth
                    variant='outlined'
                    disabled={Boolean(this.state.ID)}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(
                      classes.formControl,
                      'w-full sm:w-1/3',
                    )}
                    label='Qty'
                    id='Qty'
                    name='Qty'
                    value={this.state.Qty}
                    onChange={this.handleChange}
                    onBlur={this.formatUnitCost}
                    InputLabelProps={{ shrink: this.state.Qty ? true : false }}
                    variant='outlined'
                    InputProps={{
                      type: 'number',
                    }}
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Unit of Measure'
                    options={uoms}
                    menuItemComponent={value => {
                      return (
                        value.ActiveYN === 'Y' && (
                          <MenuItem value={value.UOM}>
                            <div className='flex'>
                              {`${value.UOM} - ${value.Description}`}
                            </div>
                          </MenuItem>
                        )
                      )
                    }}
                    value={this.state.UOM}
                    noflip={true}
                    onSelect={option =>
                      this.setState({ ...this.state, UOM: option.UOM })
                    }
                    required
                    portal={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <div className='sm:flex w-full'>
                    {searching ? (
                      <Autocomplete
                        className={classes.formControl}
                        title='Description'
                        options={materials}
                        menuItemComponent={value => {
                          return (
                            value.ActiveYN === 'Y' && (
                              <MenuItem value={value.Material}>
                                <div className='flex'>
                                  <Avatar
                                    style={{ marginRight: 8 }}
                                    classes={{ root: classes.avatarRoot }}
                                    className={classes.avatar}
                                    alt={value.Material}
                                    src={
                                      value.Data && value.Data.Avatar
                                        ? getProfileImage(
                                            `Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                          )
                                        : avatar
                                    }
                                  />
                                  {`${value.Description}`}
                                </div>
                              </MenuItem>
                            )
                          )
                        }}
                        value={this.state.Material}
                        search={this.state.Description}
                        portal={true}
                        debounce={750}
                        onBlur={() =>
                          this.setState({ ...this.state, searching: false })
                        }
                        onChange={text =>
                          this.setState({ ...this.state, Description: text })
                        }
                        noflip={true}
                        onSelect={option =>
                          this.setState(
                            {
                              ...this.state,
                              searching: false,
                              hasMatch: true,
                              Material: option.Material,
                              Description: option.Description,
                              VendorMaterial: option.MfgBarcode,
                              Qty: this.state.Qty ? this.state.Qty : 1,
                              UOM: option.UOM,
                              UnitCost: option.Cost,
                            },
                            () => this.formatUnitCost(),
                          )
                        }
                        required
                      />
                    ) : (
                      <TextField
                        className={classes.formControl}
                        label='Description'
                        id='description'
                        name='Description'
                        value={this.state.Description}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: this.state.Description ? true : false,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Icon
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    searching: true,
                                  })
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                search
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        variant='outlined'
                        fullWidth
                        required
                      />
                    )}
                  </div>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classNames(
                      classes.formControl,
                      'w-full sm:w-1/2',
                    )}
                    label='Vendor Part #'
                    id='vendorMaterial'
                    name='VendorMaterial'
                    value={this.state.VendorMaterial || ''}
                    onChange={this.handleChange}
                    // onBlur={() => this.getPart() }
                    InputLabelProps={{
                      shrink: Boolean(this.state.VendorMaterial),
                    }}
                    fullWidth
                    variant='outlined'
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>attach_money</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Unit Cost'
                    id='unitCost'
                    name='UnitCost'
                    value={this.state.UnitCost || ''}
                    onChange={this.handleChange}
                    onBlur={this.formatUnitCost}
                    InputProps={{
                      startAdornment: this.state.UnitCost && (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                      type: 'number',
                    }}
                    InputLabelProps={{
                      shrink: this.state.UnitCost ? true : false,
                    }}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Total Cost'
                    id='costTotal'
                    name='CostTotal'
                    value={this.state.CostTotal || ''}
                    onChange={this.handleChange}
                    onBlur={this.formatCostTotal}
                    InputProps={{
                      startAdornment: this.state.CostTotal && (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                      type: 'number',
                    }}
                    InputLabelProps={{
                      shrink: this.state.CostTotal ? true : false,
                    }}
                    variant='outlined'
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className='mb-24'
                    title='Cost Type'
                    options={[{ CostType: null, Name: 'None' }, ...costTypes]}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.CostType}>
                          {`${value.CostType ? `${value.CostType} - ` : ''}${
                            value.Name
                          }`}
                        </MenuItem>
                      )
                    }}
                    portal={true}
                    value={this.state.CostType || ''}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        CostType: option.CostType,
                      })
                    }
                    required={poOptions.RequireCostType === 'Y'}
                  />
                  {this.state.Advanced === 'Y' && (
                    <React.Fragment>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        className={classNames(
                          classes.formControl,
                          'w-full sm:w-1/2',
                        )}
                        label='Status'
                        select
                        id='status'
                        name='Status'
                        value={this.state.Status}
                        onChange={this.handleChange}
                        variant='outlined'
                        required
                        fullWidth
                        disabled={Boolean(Data.VPPOIB || Data.VPPOIT)}
                      >
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={1}>Submitted</MenuItem>
                        <MenuItem value={2}>Issued</MenuItem>
                      </TextField>
                    </React.Fragment>
                  )}
                </div>

                {this.state.Advanced === 'Y' && (
                  <React.Fragment>
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>format_list_numbered_rtl</Icon>
                      </div>
                      <div className='sm:flex w-full'>
                        <TextField
                          className={classNames(
                            classes.formControl,
                            this.state.TaxableYN !== 'Y' && 'line-through',
                          )}
                          label='Tax Code'
                          select
                          id='taxCode'
                          name='TaxCode'
                          value={this.state.TaxCode}
                          onChange={this.handleChange}
                          variant='outlined'
                          InputLabelProps={{
                            shrink: this.state.TaxCode ? true : false,
                          }}
                          required
                          fullWidth
                          disabled={this.state.Status > 1}
                        >
                          {taxCodes && taxCodes.length > 0
                            ? taxCodes.map((value, index) => (
                                <MenuItem key={index} value={value.TaxCode}>
                                  {`${value.Description} - ${value.TaxCode}`}
                                </MenuItem>
                              ))
                            : ''}
                        </TextField>
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classNames(
                            classes.formControl,
                            this.state.TaxableYN !== 'Y' && 'line-through',
                          )}
                          label='Tax Rate'
                          id='taxRate'
                          name='TaxRate'
                          value={this.state.TaxRate}
                          onChange={this.handleChange}
                          InputProps={{
                            endAdornment: this.state.TaxRate && (
                              <InputAdornment position='end'>%</InputAdornment>
                            ),
                            type: 'number',
                          }}
                          InputLabelProps={{
                            shrink: this.state.TaxRate ? true : false,
                          }}
                          variant='outlined'
                          required
                          fullWidth
                          disabled={true}
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classNames(
                            classes.formControl,
                            this.state.TaxableYN !== 'Y' && 'line-through',
                          )}
                          label='Tax Total'
                          id='taxTotal'
                          name='TaxTotal'
                          value={this.state.TaxTotal}
                          InputProps={{
                            startAdornment: this.state.TaxTotal && (
                              <InputAdornment position='start'>
                                $
                              </InputAdornment>
                            ),
                            type: 'number',
                          }}
                          InputLabelProps={{
                            shrink: this.state.TaxTotal ? true : false,
                          }}
                          variant='outlined'
                          fullWidth
                          disabled={this.state.Status > 1}
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          label='Taxable?'
                          select
                          id='taxableYN'
                          name='TaxableYN'
                          value={this.state.TaxableYN}
                          onChange={this.handleChange}
                          variant='outlined'
                          required
                          fullWidth
                          disabled={this.state.Status > 1}
                        >
                          <MenuItem value='N'>No</MenuItem>
                          <MenuItem value='Y'>Yes</MenuItem>
                        </TextField>
                      </div>
                    </div>
                    {/* {
                                        this.state.ReceivingYN === "Y" &&
                                        <React.Fragment>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">check_box</Icon>
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                                    label="Received Qty"
                                                    id="receivedQty"
                                                    name="ReceivedQty"
                                                    value={this.state.ReceivedQty}
                                                    InputLabelProps={{ shrink: this.state.ReceivedQty ? true : false }}
                                                    variant="outlined"
                                                    InputProps={{
                                                        type: "number"
                                                    }}
                                                    disabled={true}
                                                    fullWidth
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Received Cost"
                                                    id="receivedCostTotal"
                                                    name="ReceivedCostTotal"
                                                    value={this.state.ReceivedCostTotal}
                                                    InputProps={{
                                                        startAdornment: this.state.ReceivedCostTotal && <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number"
                                                    }}
                                                    InputLabelProps={{ shrink: this.state.ReceivedCostTotal ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={true}
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl, this.state.TaxableYN !== 'Y' && "line-through")}
                                                    label="Received Tax"
                                                    id="receivedTaxTotal"
                                                    name="ReceivedTaxTotal"
                                                    value={this.state.ReceivedTaxTotal}
                                                    InputProps={{
                                                        startAdornment: this.state.ReceivedTaxTotal && <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number"
                                                    }}
                                                    InputLabelProps={{ shrink: this.state.ReceivedTaxTotal ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={true}
                                                />

                                            </div>

                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">check_box_outline_blank</Icon>
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                                    label="Remaining Qty"
                                                    id="remainingQty"
                                                    name="RemainingQty"
                                                    value={this.state.RemainingQty}
                                                    InputLabelProps={{ shrink: this.state.RemainingQty ? true : false }}
                                                    variant="outlined"
                                                    InputProps={{
                                                        type: "number"
                                                    }}
                                                    disabled={true}
                                                    fullWidth
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Remaining Cost"
                                                    id="remainingCostTotal"
                                                    name="RemainingCostTotal"
                                                    value={this.state.RemainingCostTotal}
                                                    InputProps={{
                                                        startAdornment: this.state.RemainingCostTotal && <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number"
                                                    }}
                                                    InputLabelProps={{ shrink: this.state.RemainingCostTotal ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={true}
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl, this.state.TaxableYN !== 'Y' && "line-through")}
                                                    label="Remaining Tax"
                                                    id="remainingTaxTotal"
                                                    name="RemainingTaxTotal"
                                                    value={this.state.RemainingTaxTotal}
                                                    InputProps={{
                                                        startAdornment: this.state.RemainingTaxTotal && <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number"
                                                    }}
                                                    InputLabelProps={{ shrink: this.state.RemainingTaxTotal ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={true}
                                                />

                                            </div>
                                        </React.Fragment>
                                    } */}

                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                        <Icon color='action'>note</Icon>
                      </div>
                      <TextField
                        className={classes.formControl}
                        label='Notes'
                        id='notes'
                        name='Notes'
                        value={this.state.Notes}
                        onChange={this.handleChange}
                        variant='outlined'
                        multiline
                        rows={5}
                        fullWidth
                      />
                    </div>
                  </React.Fragment>
                )}
                <FormControlLabel
                  style={{ float: 'left' }}
                  control={
                    <Switch
                      checked={this.state.ReceivingYN !== 'Y'}
                      name='ReceivingYN'
                      onChange={() => {
                        this.setState({
                          ...this.state,
                          ReceivingYN:
                            this.state.ReceivingYN === 'Y' ? 'N' : 'Y',
                        })
                      }}
                      color='secondary'
                      disabled={this.state.Status > 1}
                    />
                  }
                  label='Auto-Receive'
                />
              </TabContainer>
            )}

            {value === 1 && (
              <TabContainer>
                {!readOnly &&
                  accessLevel &&
                  accessLevel.AccessLevel !== 'R' &&
                  this.state.ReceivingYN === 'Y' && (
                    <React.Fragment>
                      <Typography
                        variant='h6'
                        style={{ alignItems: 'center' }}
                        className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                      >
                        <Icon color='primary' className='text-32 mr-12'>
                          check_box
                        </Icon>
                        {`${
                          !this.state.receiving.Seq ? 'Add' : 'Update'
                        } Receiving Line${
                          this.state.receiving.Seq
                            ? ` #${this.state.receiving.Seq}`
                            : ''
                        }`}
                      </Typography>
                      <div className='sm:flex'>
                        <TextField
                          className={classNames(
                            classes.formControl,
                            'w-full sm:w-1/2',
                          )}
                          label='Qty'
                          id='receivedQty'
                          name='receiving.ReceivedQty'
                          value={this.state.receiving.ReceivedQty || ''}
                          onChange={this.handleChange}
                          onBlur={this.setReceivedCost}
                          InputLabelProps={{
                            shrink: Boolean(this.state.receiving.ReceivedQty),
                          }}
                          variant='outlined'
                          InputProps={{
                            type: 'number',
                          }}
                          error={isNaN(this.state.receiving.ReceivedQty)}
                          required
                          fullWidth
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classes.formControl}
                          label='Received Cost'
                          id='receivedCostTotal'
                          name='receiving.ReceivedCostTotal'
                          value={this.state.receiving.ReceivedCostTotal || ''}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: Boolean(
                              this.state.receiving.ReceivedCostTotal,
                            ) && (
                              <InputAdornment position='start'>
                                $
                              </InputAdornment>
                            ),
                            type: 'number',
                          }}
                          InputLabelProps={{
                            shrink: Boolean(
                              this.state.receiving.ReceivedCostTotal,
                            ),
                          }}
                          variant='outlined'
                          fullWidth
                        />
                        {this.state.TaxableYN === 'Y' && (
                          <React.Fragment>
                            <div className='hidden sm:block min-w-12 pt-20'></div>
                            <TextField
                              className={classNames(
                                classes.formControl,
                                this.state.TaxableYN !== 'Y' && 'line-through',
                              )}
                              label='Received Tax'
                              id='receivedTaxTotal'
                              name='receiving.ReceivedTaxTotal'
                              value={this.state.receiving.ReceivedTaxTotal}
                              InputProps={{
                                startAdornment: this.state.receiving
                                  .ReceivedTaxTotal && (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                                type: 'number',
                              }}
                              InputLabelProps={{
                                shrink: this.state.receiving.ReceivedTaxTotal
                                  ? true
                                  : false,
                              }}
                              variant='outlined'
                              fullWidth
                            />
                          </React.Fragment>
                        )}
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <DatePicker
                          variant='inline'
                          inputVariant='outlined'
                          className={classes.formControl}
                          label='Received Date'
                          id='receivedDate'
                          format='MM/DD/YYYY'
                          value={this.state.receiving.ReceivedDate}
                          onChange={this.handleReceivedDateChange}
                          showTodayButton
                          autoOk
                          animateYearScrolling
                          required
                          fullWidth
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        {!this.state.receiving.ID ? (
                          <Button
                            className={classNames(
                              classes.formControl,
                              'w-full sm:w-1/2',
                            )}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              const { Co, PO, POLine, receiving } = this.state
                              addPurchaseOrderReceiving({
                                ...receiving,
                                Co,
                                PO,
                                POLine,
                              })
                            }}
                            disabled={!this.canReceivingBeSubmitted()}
                          >
                            Add
                          </Button>
                        ) : (
                          <div className='w-full flex'>
                            <Button
                              className={classNames(
                                classes.formControl,
                                classes.updateReceiving,
                                'w-full',
                              )}
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                updatePurchaseOrderReceiving({
                                  ...this.state.receiving,
                                })
                              }}
                              disabled={!this.canReceivingBeSubmitted()}
                            >
                              Update
                            </Button>
                            <Button
                              className={classNames(
                                classes.formControl,
                                classes.deleteReceiving,
                              )}
                              variant='contained'
                              onClick={() => {
                                removePurchaseOrderReceiving({
                                  ...this.state.receiving,
                                })
                              }}
                              disabled={!this.canReceivingBeSubmitted()}
                            >
                              <Icon>delete</Icon>
                            </Button>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                <PurchaseOrderReceivingList
                  onSelectedReceiving={this.setReceiving}
                  purchaseOrderLine={{ ...this.state }}
                />
              </TabContainer>
            )}

            {!readOnly && accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {purchaseOrderLineDialog.type === 'new' ? (
                  <React.Fragment>
                    <DebounceButton
                      buttonText={'Add'}
                      processingText={'Adding'}
                      isDisabled={!this.canBeSubmitted()}
                      variant={'contained'}
                      color={'primary'}
                      clickFxn={() => {
                        addPurchaseOrderLine(this.state)
                        this.closeComposeDialog()
                      }}
                      debounceInterval={1000}
                    />
                    <FormControlLabel
                      style={{ float: 'right' }}
                      control={
                        <Switch
                          checked={this.state.Advanced === 'Y'}
                          name='Advanced'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Advanced'
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <DebounceButton
                      buttonText={'Save'}
                      processingText={'Saving'}
                      isDisabled={!this.canBeSubmitted()}
                      variant={'contained'}
                      color={'primary'}
                      clickFxn={() => {
                        updatePurchaseOrderLine(this.state)
                      }}
                      debounceInterval={1000}
                    />
                    <FormControlLabel
                      style={{ float: 'right' }}
                      control={
                        <Switch
                          checked={this.state.Advanced === 'Y'}
                          name='Advanced'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Advanced'
                    />
                    <IconButton
                      onClick={() => {
                        removePurchaseOrderLine(this.state)
                        this.closeComposeDialog()
                      }}
                      // disabled={Boolean(Data.VPPOIB || Data.VPPOIT)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </React.Fragment>
                )}
              </DialogActions>
            )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditPurchaseOrderLineDialog:
        Actions.closeEditPurchaseOrderLineDialog,
      closeNewPurchaseOrderLineDialog: Actions.closeNewPurchaseOrderLineDialog,
      addPurchaseOrderLine: Actions.addPurchaseOrderLine,
      updatePurchaseOrderLine: Actions.updatePurchaseOrderLine,
      removePurchaseOrderLine: Actions.removePurchaseOrderLine,
      addPurchaseOrderReceiving: Actions.addPurchaseOrderReceiving,
      updatePurchaseOrderReceiving: Actions.updatePurchaseOrderReceiving,
      removePurchaseOrderReceiving: Actions.removePurchaseOrderReceiving,
      openPreloadPurchaseOrderLineDialog,
    },
    dispatch,
  )
}

function mapStateToProps({
  purchaseOrderLinesApp,
  workOrdersApp,
  workOrderScopesApp,
  spReducers,
}) {
  return {
    purchaseOrderLineDialog:
      purchaseOrderLinesApp.purchaseOrderLines.purchaseOrderLineDialog,
    Co: spReducers.companies.Co,
    user: spReducers.userProfiles.User,
    fleetVehicles: spReducers.fleetVehicles,
    costTypes: spReducers.costTypes,
    rateTemplates: spReducers.rateTemplates,
    employees: spReducers.employees,
    inventoryLocations: spReducers.inventoryLocations,
    taxCodes: spReducers.taxCodes,
    materials: spReducers.materials.materials,
    securables: spReducers.userProfiles.User.Data.Securables,
    workOrderData: workOrdersApp.workOrders.workOrderDialog.data,
    uoms: spReducers.uoms,
    company: _.find(spReducers.companies.List, { Co: spReducers.companies.Co })
      .Data.Co,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderLineDialog),
)
