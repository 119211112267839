import * as Actions from './index';

export const TOGGLE_CHAT_PANEL = '[CHAT PANEL] TOGGLE CHAT PANEL';
export const CLOSE_CHAT_PANEL = '[CHAT PANEL] CLOSE CHAT PANEL';
export const OPEN_CHAT_PANEL = '[CHAT PANEL] OPEN CHAT PANEL';
export const TOGGLE_MAP = '[CHAT PANEL] TOGGLE MAP';
export const OPEN_MAP = '[CHAT PANEL] OPEN MAP';
export const RESIZE_MAP = '[CHAT PANEL] RESIZE MAP';
export const SHRINK_MAP = '[CHAT PANEL] SHRINK MAP';
export const CLOSE_MAP = '[CHAT PANEL] CLOSE MAP';
export const SET_MAP_SEARCH = '[CHAT PANEL] SET MAP SEARCH';

export function toggleChatPanel()
{
    return {
        type: TOGGLE_CHAT_PANEL
    }
}

export function openChatPanel()
{
    return {
        type: OPEN_CHAT_PANEL
    }
}

export function setMapSearch(search) {
    return {
        type: SET_MAP_SEARCH,
        search
    }
}

export function closeChatPanel()
{
    return (dispatch) => {
        dispatch(Actions.removeSelectedContactId());
        dispatch(Actions.removeChat());
        return dispatch({
            type: CLOSE_CHAT_PANEL
        })
    }
}

export function toggleMap() {
    return {
        type: TOGGLE_MAP
    }
}

export function openMap() {
    return {
        type: OPEN_MAP
    }
}

export function resizeMap(size) {
    return {
        type: RESIZE_MAP,
        size,
    }
}

export function closeMap() {
    return (dispatch) => {
        return dispatch({
            type: CLOSE_MAP
        })
    }
}