import axios from 'axios/index';
import { showMessage, } from 'store/actions';

export const GET_USER_DATA = '[USERS APP] GET USER DATA';

export function getUserData(data) {
    return (dispatch, getState) => {
        if (data) {
            const { UserName } = data;
    
            const request = axios.get(`${window["apiLocation"]}/api/UserProfile?&UserName=${encodeURIComponent(UserName)}`);
    
            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: GET_USER_DATA,
                        data: response.data
                    })
                ])
            );
        } else {
            dispatch({
                type: GET_USER_DATA,
                data: null
            });
        }

    };
}

export function selectUser(data) {
    return (dispatch, getState) => {
        if (data) {
            const { UserName } = data;
    
            const request = axios.get(`${window["apiLocation"]}/api/UserProfile?&UserName=${encodeURIComponent(UserName)}`);
    
            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: GET_USER_DATA,
                        data: response.data
                    })
                ])
            );
        } else {
            dispatch({
                type: GET_USER_DATA,
                data: null
            });
        }

    };
}

export function updateTableSettings(data) {
    const obj = {
        ...data,
        Preferences: JSON.stringify(data.Preferences)
    }
    const request = axios.post(`${window["apiLocation"]}/api/UserSettings/UserTableSetting`, obj);

    return (dispatch, getState) => {
        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            }
        })
    }
}
