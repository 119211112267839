import * as Actions from '../actions'
import _ from '@lodash'

const initialState = {
  entities: [],
  searchText: '',
  selectedCustomerIds: [],
  routeParams: {},
  customerDialog: {
    type: 'new',
    props: {
      open: false,
    },
    data: null,
  },
  customerDNUDialog: {
    type: 'new',
    props: {
      open: false,
    },
    data: null,
  },
  callHandlerData: null,
  lineEnabled: true,
}

const customersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CUSTOMERS: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
        routeParams: action.routeParams,
      }
    }
    case Actions.ENABLE_LINE: {
      return {
        ...state,
        lineEnabled: action.lineEnabled,
      }
    }
    //case Actions.LOAD_WORK_ORDERS
    //    {
    //        return {
    //            ...state,
    //            workOrders: action.workOrders
    //        };
    //    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      }
    }
    case Actions.TOGGLE_IN_SELECTED_CUSTOMERS: {
      const customerId = action.customerId

      let selectedCustomerIds = [...state.selectedCustomerIds]

      if (selectedCustomerIds.find(id => id === customerId) !== undefined) {
        selectedCustomerIds = selectedCustomerIds.filter(
          id => id !== customerId,
        )
      } else {
        selectedCustomerIds = [...selectedCustomerIds, customerId]
      }

      return {
        ...state,
        selectedCustomerIds: selectedCustomerIds,
      }
    }
    case Actions.SELECT_ALL_CUSTOMERS: {
      const arr = Object.keys(state.entities).map(k => state.entities[k])

      const selectedCustomerIds = arr.map(customer => customer.id)

      return {
        ...state,
        selectedCustomerIds: selectedCustomerIds,
      }
    }
    case Actions.DESELECT_ALL_CUSTOMERS: {
      return {
        ...state,
        selectedCustomerIds: [],
      }
    }
    case Actions.OPEN_NEW_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'new',
          props: {
            open: true,
          },
          data: null,
        },
      }
    }
    case Actions.CLOSE_NEW_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'new',
          props: {
            open: false,
          },
          data: null,
        },
      }
    }
    case Actions.OPEN_EDIT_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'edit',
          props: {
            open: true,
          },
          data: action.data,
        },
      }
    }
    case Actions.UPDATE_EDIT_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'update',
          props: {
            open: true,
          },
          data: action.data,
        },
      }
    }
    case Actions.CLOSE_EDIT_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'edit',
          props: {
            open: false,
          },
          data: null,
        },
      }
    }
    case Actions.DATA_EDIT_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'data',
          props: {
            ...state.customerDialog.props,
          },
          data: action.data,
        },
      }
    }
    case Actions.ADD_EDIT_CUSTOMER_DIALOG: {
      return {
        ...state,
        customerDialog: {
          type: 'add',
          props: {
            open: true,
          },
          data: action.data,
        },
      }
    }
    case Actions.OPEN_NEW_CUSTOMER_DNU_DIALOG: {
      const { customer } = action
      const { Co, Customer } = customer
      return {
        ...state,
        customerDNUDialog: {
          type: 'new',
          props: {
            open: true,
          },
          data: {
            Co,
            Customer,
            Data: {
              Customer: customer,
              Redirect: null,
              ErrMsg: null,
            },
          },
        },
      }
    }
    case Actions.CLOSE_NEW_CUSTOMER_DNU_DIALOG: {
      return {
        ...state,
        customerDNUDialog: {
          type: 'new',
          props: {
            open: false,
          },
          data: null,
        },
      }
    }
    case Actions.OPEN_EDIT_CUSTOMER_DNU_DIALOG: {
      return {
        ...state,
        customerDNUDialog: {
          type: 'edit',
          props: {
            open: true,
          },
          data: action.data,
        },
      }
    }
    case Actions.UPDATE_EDIT_CUSTOMER_DNU_DIALOG: {
      return {
        ...state,
        customerDNUDialog: {
          type: 'update',
          props: {
            open: true,
          },
          data: action.data,
        },
      }
    }
    case Actions.CLOSE_EDIT_CUSTOMER_DNU_DIALOG: {
      return {
        ...state,
        customerDNUDialog: {
          type: 'edit',
          props: {
            open: false,
          },
          data: null,
        },
      }
    }
    case Actions.SET_CALL_HANDLER_DATA: {
      return {
        ...state,
        callHandlerData: action.data,
      }
    }
    case Actions.REMOVE_CUSTOMER: {
      return {
        ...state,
        customerDialog: {
          ...state.customerDialog,
          props: {
            open: false,
            showDeleteConfirmation: false,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

export default customersReducer
