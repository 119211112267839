import axios from 'axios';
import { getUserData } from 'main/content/apps/agreements/store/actions/user.actions';
import { setSiteData } from 'main/content/apps/call-handler/store/actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const SET_SEARCH_TEXT = '[AGREEMENTS APP] SET SEARCH TEXT';
export const SET_LOADING = '[AGREEMENTS APP] SET LOADING';
export const SET_PROCESSING = '[AGREEMENTS APP] SET PROCESSING';
export const SET_SELECTED_AMORTIZATIONS = '[AGREEMENTS APP] SET SELECTED AMORTIZATIONS';
export const SET_SELECTED_BILLINGS = '[AGREEMENTS APP] SET SELECTED BILLINGS';
export const SET_SELECTED_TASKS = '[AGREEMENTS APP] SET SELECTED TASKS';
export const SET_WAIVED_TASKS = '[AGREEMENTS APP] SET WAIVED TASKS';
export const SET_AGREEMENTS = '[AGREEMENTS APP] SET AGREEMENTS';
export const OPEN_NEW_AGREEMENT_DIALOG = '[AGREEMENTS APP] OPEN NEW AGREEMENT DIALOG';
export const CLOSE_NEW_AGREEMENT_DIALOG = '[AGREEMENTS APP] CLOSE NEW AGREEMENT DIALOG';
export const OPEN_EDIT_AGREEMENT_DIALOG = '[AGREEMENTS APP] OPEN EDIT AGREEMENT DIALOG';
export const RENEW_EDIT_AGREEMENT_DIALOG = '[AGREEMENTS APP] RENEW EDIT AGREEMENT DIALOG';
export const INVOICE_EDIT_AGREEMENT_DIALOG = '[AGREEMENTS APP] INVOICE EDIT AGREEMENT DIALOG';
export const SCHEDULE_EDIT_AGREEMENT_DIALOG = '[AGREEMENTS APP] SCHEDULE EDIT AGREEMENT DIALOG';
export const UPDATE_EDIT_AGREEMENT_DIALOG = '[AGREEMENTS APP] UPDATE EDIT AGREEMENT DIALOG';
export const CLOSE_EDIT_AGREEMENT_DIALOG = '[AGREEMENTS APP] CLOSE EDIT AGREEMENT DIALOG';
export const SELECT_ALL_AGREEMENTS = '[AGREEMENTS APP] SELECT ALL AGREEMENTS';
export const DESELECT_ALL_AGREEMENTS = '[AGREEMENTS APP] DESELECT ALL AGREEMENTS';
export const TOGGLE_IN_SELECTED_AGREEMENTS = '[AGREEMENTS APP] TOGGLE IN SELECTED AGREEMENTS';
export const ADD_AGREEMENT = '[AGREEMENTS APP] ADD AGREEMENT';
export const UPDATE_AGREEMENT = '[AGREEMENTS APP] UPDATE AGREEMENT';
export const REMOVE_AGREEMENT = '[AGREEMENTS APP] REMOVE AGREEMENT';
export const SET_AGREEMENT_FILTERS = '[AGREEMENTS APP] SET AGREEMENTS FILTERS';
export const ASSIGN_AGREEMENT_TASK = '[AGREEMENTS APP] ASSIGN AGREEMENT TASK';
export const ASSIGN_AGREEMENT_TASKS = '[AGREEMENTS APP] ASSIGN AGREEMENT TASKS';
export const UNASSIGN_AGREEMENT_TASK = '[AGREEMENTS APP] UNASSIGN AGREEMENT TASK';
export const REVISE_AGREEMENT_DIALOG = '[AGREEMENTS APP] REVISE AGREEMENT DIALOG';



export function getAgreements(Co, filters) {

    return (dispatch) => {
        // dispatch({
        //     type: SET_LOADING,
        //     loading: true,
        // });
        dispatch({
            type: SET_AGREEMENTS,
            agreements: [],
            filters,
            loading: true,
        });
        const { Agreement, EventType, Task, DueWithin } = filters;
        const { BusinessUnit, Division, AgreementType, Status } = Agreement;
        const request = axios.get(`${window["apiLocation"]}/api/Agreement`, {
            params: {
                Co,
                BusinessUnit,
                Division,
                AgreementType,
                Status,
                EventType,
                Task,
                DueWithin
            }
        });
        request.then((response) => {
            dispatch({
                type: SET_AGREEMENTS,
                agreements: response.data,
                filters,
                loading: false
            });
        });
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedAgreements(agreementId) {
    return {
        type: TOGGLE_IN_SELECTED_AGREEMENTS,
        agreementId
    }
}


export function selectAllAgreements(selectedAgreementIds) {
    return {
        type: SELECT_ALL_AGREEMENTS,
        selectedAgreementIds
    }
}

export function deSelectAllAgreements() {
    return {
        type: DESELECT_ALL_AGREEMENTS
    }
}

export function setAgreementFilters(filters) {
    return {
        type: SET_AGREEMENT_FILTERS,
        filters,
    }
}


export function openNewAgreementDialog(data, onAdded, onUpdated) {
    return {
        type: OPEN_NEW_AGREEMENT_DIALOG,
        data,
        onAdded,
        onUpdated
    }
}

export function closeNewAgreementDialog() {
    return {
        type: CLOSE_NEW_AGREEMENT_DIALOG
    }
}

export function openPreloadAgreementDialog(data){
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_AGREEMENT_DIALOG,
            data: data,
        })
    }
}

export function openEditAgreementDialog(data, isOpen, wo) {
    return (dispatch, getState) => {

        const { routeParams } = getState().agreementsApp.agreements;

        const request = axios.get(`${window["apiLocation"]}/api/Agreement?Co=${data.Co}&Customer=${encodeURIComponent(data.Customer)}${data.Site ? `&Site=${encodeURIComponent(data.Site)}` : ''}&Agreement=${encodeURIComponent(data.Agreement)}&Cycle=${encodeURIComponent(data.Cycle)}&Revision=${encodeURIComponent(data.Revision)}`);

        return request.then((response) => {
            const { renewalWorkOrder, renewalScope } = data;
            dispatch({
                type: !isOpen ? OPEN_EDIT_AGREEMENT_DIALOG : UPDATE_EDIT_AGREEMENT_DIALOG,
                data: { ...response.data, renewalWorkOrder, renewalScope },
                source: wo
            })
        });
    }
}

export function renewEditAgreementDialog(data, isOpen) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Agreement?Co=${data.Co}&Customer=${encodeURIComponent(data.Customer)}${data.Site ? `&Site=${encodeURIComponent(data.Site)}` : ''}&Agreement=${encodeURIComponent(data.Agreement)}&Cycle=${encodeURIComponent(data.Cycle)}&Revision=${encodeURIComponent(data.Revision)}`);

        return request.then((response) => {
            dispatch({
                type: RENEW_EDIT_AGREEMENT_DIALOG,
                data: response.data
            });
        });
    }
}

export function assignEditAgreementDialog(data, isOpen) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Agreement?Co=${data.Co}&Customer=${encodeURIComponent(data.Customer)}${data.Site ? `&Site=${encodeURIComponent(data.Site)}` : ''}&Agreement=${encodeURIComponent(data.Agreement)}&Cycle=${encodeURIComponent(data.Cycle)}&Revision=${encodeURIComponent(data.Revision)}`);

        return request.then((response) => {
            dispatch({
                type: SCHEDULE_EDIT_AGREEMENT_DIALOG,
                data: response.data,
                open: true
            });
        });
    }
}

export function invoiceEditAgreementDialog(data, isOpen) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/Agreement?Co=${data.Co}&Customer=${encodeURIComponent(data.Customer)}${data.Site ? `&Site=${encodeURIComponent(data.Site)}` : ''}&Agreement=${encodeURIComponent(data.Agreement)}&Cycle=${encodeURIComponent(data.Cycle)}&Revision=${encodeURIComponent(data.Revision)}`);

        return request.then((response) => {
            dispatch({
                type: INVOICE_EDIT_AGREEMENT_DIALOG,
                data: response.data,
                open: true
            });
        });
    }
}

export function updateEditAgreementDialog(data) {
    return {
        type: UPDATE_EDIT_AGREEMENT_DIALOG,
        data
    }
}

export function reviseAgreementDialog(data) {
    window["warn"]("reviseAgreementDialog data 111", data)
    return {
        type: REVISE_AGREEMENT_DIALOG,
        data
    }
}

export function scheduleEditAgreementDialog(data) {
    return {
        type: SCHEDULE_EDIT_AGREEMENT_DIALOG,
        data
    }
}

export function closeEditAgreementDialog() {
    return {
        type: CLOSE_EDIT_AGREEMENT_DIALOG
    }
}

export function addAgreement(newAgreement) {
    const { Co, Customer, Site, Agreement } = newAgreement;
    return (dispatch, getState) => {

        const state = getState();

        const { routeParams } = state.agreementsApp.agreements;
        const agr = _.cloneDeepWith(newAgreement);
        delete agr.Data.Customer;
        delete agr.Data.Site;

        window["warn"]("addAgreement data 111", agr.needsRevision)


        const request = axios.post(`${window["apiLocation"]}/api/Agreement`,
            agr
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Agreement #${response.data.Agreement} Cycle #${response.data.Cycle} Revision #${response.data.Revision} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const { entities, filters } = state.agreementsApp.agreements;
                _.filter(entities || [], { Co, Customer, Site, Agreement }).forEach((v) => {
                    if (v.Cycle < response.data.Cycle && !v.NextCycle) {
                        v.NextCycle = response.data.Cycle;
                    }
                });
                dispatch({
                    type: SET_AGREEMENTS,
                    agreements: entities,
                    filters,
                    loading: false
                });
                const { callHandler } = state.callHandlerApp;
                if (response.data.Customer === callHandler.data.Site.Customer && response.data.Site === callHandler.data.Site.Site && callHandler.data.Site.Data) {
                    if (!callHandler.data.Site.Data.Agreements) {
                        callHandler.data.Site.Data.Agreements = [];
                    }
                    callHandler.data.Site.Data.Agreements.push(response.data);
                    dispatch(setSiteData(callHandler.data.Site));
                }
                Promise.all([
                    dispatch({
                        type: ADD_AGREEMENT,
                        data: response.data
                    })
                ]).then(() => {
                    if(agr.needsRevision){
                        dispatch(reviseAgreementDialog(response.data));
                    } else{
                        dispatch(updateEditAgreementDialog(response.data));
                    }
                })
            }
        }
        );
    };
}

export function updateAgreement(agreement) {
    return (dispatch, getState) => {

        const state = getState();

        const { routeParams } = state.agreementsApp.agreements;

        const request = axios.put(`${window["apiLocation"]}/api/Agreement?Co=${encodeURIComponent(agreement.Co)}&Customer=${encodeURIComponent(agreement.Customer)}&Site=${encodeURIComponent(agreement.Site)}&Agreement=${encodeURIComponent(agreement.Agreement)}&Cycle=${encodeURIComponent(agreement.Cycle)}&Revision=${encodeURIComponent(agreement.Revision)}`,
            agreement
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_AGREEMENT,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement #${agreement.Agreement} Cycle #${agreement.Cycle} Revision #${agreement.Revision} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                    const { callHandler } = state.callHandlerApp;
                    if (response.data.Customer === callHandler.data.Site.Customer && response.data.Site === callHandler.data.Site.Site) {
                        const index = _.findIndex(callHandler.data.Site.Data.Agreements, { Co: agreement.Co, Customer: agreement.Customer, Site: agreement.Site, Agreement: agreement.Agreement, Cycle: agreement.Cycle, Revision: agreement.Revision });
                        if (index > -1) {
                            callHandler.data.Site.Data.Agreements.splice(index, 1, response.data);
                            dispatch(setSiteData(callHandler.data.Site));
                        }
                    }
                }
                dispatch(updateEditAgreementDialog(response.data));
            })
        );
    };
}

export function removeAgreement(agreement) {
    return (dispatch, getState) => {

        const state = getState();

        const request = axios.delete(`${window["apiLocation"]}/api/Agreement?Co=${encodeURIComponent(agreement.Co)}&Customer=${encodeURIComponent(agreement.Customer)}${agreement.Site ? `&Site=${encodeURIComponent(agreement.Site)}` : ''}&Agreement=${encodeURIComponent(agreement.Agreement)}&Cycle=${encodeURIComponent(agreement.Cycle)}&Revision=${encodeURIComponent(agreement.Revision)}`,
            agreement
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_AGREEMENT,
                    data: agreement
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Agreement #${agreement.Agreement} Cycle #${agreement.Cycle} Revision #${agreement.Revision} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                    const { callHandler } = state.callHandlerApp;
                    if (agreement.Customer === callHandler.data.Site.Customer && agreement.Site === callHandler.data.Site.Site) {
                        const index = _.findIndex(callHandler.data.Site.Data.Agreements, { Co: agreement.Co, Customer: agreement.Customer, Site: agreement.Site, Agreement: agreement.Agreement, Cycle: agreement.Cycle, Revision: agreement.Revision });
                        if (index > -1) {
                            callHandler.data.Site.Data.Agreements.splice(index, 1);
                        }
                        dispatch(setSiteData(callHandler.data.Site));
                    }
                }
            })
        );
    };
}

export function assignAgreementTask(task) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/AgreementTaskSchedule`,
            task
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Promise.all([
                    dispatch({
                        type: ASSIGN_AGREEMENT_TASK,
                        data: response.data
                    }),
                    dispatch(showMessage({
                        message: response.data.Status === 4 ? `Task ID ${task.TaskType} Seq #${task.Seq} successfully waived` : `Task ID ${task.TaskType} Seq #${task.Seq} successfully assigned to Work Order #${response.data.WorkOrder} Scope #${response.data.Scope}.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                ]).then(() => {
                    const state = getState();
                    const { data } = state.agreementsApp.agreements.agreementDialog;
                    const { Tasks } = data.Data;
                    const selTask = _.find(Tasks, { TaskType: task.TaskType });
                    if (selTask) {
                        selTask.Data.Schedule.push(response.data);
                    }
                    // data.Data.Schedule.push(response.data);
                    Promise.all([
                        dispatch(scheduleEditAgreementDialog(data)),
                    ])
                });
            }
        });
    }

}

export function assignAgreementTasks(tasks) {
    return (dispatch, getState) => {
        const { Co, Customer, Site, Agreement, Cycle } = tasks;
        const request = axios.put(`${window["apiLocation"]}/api/AgreementTaskSchedule?Co=${Co}&Customer=${Customer}&Site=${encodeURIComponent(Site)}&Agreement=${encodeURIComponent(Agreement)}&Cycle=${Cycle}`,
            tasks
        );

        return request.then((response) => {
            if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                Promise.all([
                    dispatch({
                        type: ASSIGN_AGREEMENT_TASKS,
                        data: response.data.Tasks
                    }),
                    dispatch(showMessage({
                        message: `${response.data.Tasks.length} Tasks successfully assigned to Work Order #${response.data.WorkOrder}.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                ]).then(() => {
                    const state = getState();
                    const { data } = state.agreementsApp.agreements.agreementDialog;
                    const { Tasks } = data.Data;
                    response.data.Tasks.forEach((task) => {
                        const selTask = _.find(Tasks, { TaskType: task.TaskType });
                        if (selTask) {
                            selTask.Data.Schedule.push(response.data);
                        }
                    });
                    // data.Data.Schedule.push(response.data);
                    Promise.all([
                        dispatch(scheduleEditAgreementDialog(data)),
                    ])
                });
            }
        });
    }

}

export function removeAgreementTask(task) {
    return (dispatch, getState) => {
        if(task && task.Co && task.Customer) {
            const request = axios.delete(`${window["apiLocation"]}/api/AgreementTaskSchedule?Co=${task.Co}&Customer=${task.Customer}${task.Site ? `&Site=${encodeURIComponent(task.Site)}` : ''}&Agreement=${encodeURIComponent(task.Agreement)}&Cycle=${task.Cycle}&TaskType=${encodeURIComponent(task.TaskType)}&Seq=${task.Seq}`,
                task
            );

            return request.then((response) => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    Promise.all([
                        dispatch({
                            type: UNASSIGN_AGREEMENT_TASK,
                            data: task
                        }),
                        dispatch(showMessage({
                            message: `Task ID ${task.TaskType} Seq #${task.Seq} successfully removed.`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }))
                    ]).then(() => {
                        const state = getState();
                        const { data } = state.agreementsApp.agreements.agreementDialog;
                        const { Tasks } = data.Data;
                        const selTask = _.find(Tasks, { TaskType: task.TaskType });
                        if (selTask) {
                            const index = _.findIndex(selTask.Data.Schedule, { TaskType: task.TaskType, Site: task.Site, Seq: task.Seq });
                            if (index > -1) {
                                selTask.Data.Schedule.splice(index, 1);
                            }
                        }
                        //Fix - only should open when Agr Dialog is already open
                        Promise.all([
                            dispatch(scheduleEditAgreementDialog(data)),
                        ])
                    });
                }
            });
        }
    }

}

export function setSelectedTasks(agreements) {
    return (dispatch, getState) => {
        let tasks = [];
        agreements.map((agreement) => {
            if (agreement.Data.SelectedTasks) {
                agreement.Data.SelectedTasks.map((value) => {
                    value.DueBy = value.Date;
                    tasks.push(value);
                })
                // tasks = [...tasks, ...agreement.Data.SelectedTasks];
            }
        })
        dispatch({
            type: SET_SELECTED_TASKS,
            tasks
        });
    }
}

export function setWaivedTasks(agreements) {
    return (dispatch, getState) => {
        let tasks = [];
        agreements.map((agreement) => {
            if (agreement.Data.SelectedTasks) {
                agreement.Data.SelectedTasks.map((value) => {
                    value.Action = "W";
                    tasks.push(value);
                })
                // tasks = [...tasks, ...agreement.Data.SelectedTasks];
            }
        })
        dispatch({
            type: SET_WAIVED_TASKS,
            tasks
        });
    }
}

export function waiveSelectedTasks(Co, tasks) {
    return (dispatch, getState) => {

        dispatch({
            type: SET_PROCESSING,
            processing: true
        });
        const request = axios.put(`${window["apiLocation"]}/api/AgreementTaskSchedule?Co=${Co}`,
            tasks
        );

        return request.then((response) => {

            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch({
                type: SET_WAIVED_TASKS,
                tasks: response.data
            });
        }).catch((error) => {
            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch(showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}

export function createSelectedTasks(Co, tasks) {
    return (dispatch, getState) => {

        dispatch({
            type: SET_PROCESSING,
            processing: true
        });
        const request = axios.put(`${window["apiLocation"]}/api/AgreementTaskSchedule?Co=${Co}`,
            tasks
        );

        return request.then((response) => {

            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch({
                type: SET_SELECTED_TASKS,
                tasks: response.data
            });
        }).catch((error) => {
            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch(showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}

export function setSelectedBillings(agreements) {
    return (dispatch, getState) => {
        let billings = [];
        agreements.map((agreement) => {
            if (agreement.Data.SelectedBillings) {
                billings = [...billings, ...agreement.Data.SelectedBillings];
            }
        })
        dispatch({
            type: SET_SELECTED_BILLINGS,
            billings
        });
    }
}

export function createSelectedBillings(Co, billings) {
    return (dispatch, getState) => {

        dispatch({
            type: SET_PROCESSING,
            processing: true
        });
        const request = axios.put(`${window["apiLocation"]}/api/AgreementBilling?Co=${Co}`,
            billings
        );

        return request.then((response) => {

            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch({
                type: SET_SELECTED_BILLINGS,
                billings: response.data
            });
        }).catch((error) => {
            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch(showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}



export function setSelectedAmortizations(agreements) {
    return (dispatch, getState) => {
        let amortizations = [];
        agreements.map((agreement) => {
            if (agreement.Data.SelectedAmortizations) {
                agreement.Data.SelectedAmortizations.map((value) => {
                    value.ScheduledDate = value.Date;
                    amortizations.push(value);
                })
            }
        })
        dispatch({
            type: SET_SELECTED_AMORTIZATIONS,
            amortizations
        });
    }
}

export function createAmortization(amortization) {
    return (dispatch, getState) => {

        dispatch({
            type: SET_PROCESSING,
            processing: true
        });
        const request = axios.post(`${window["apiLocation"]}/api/AgreementRevenueAmortization`,
            amortization
        );

        return request.then((response) => {

            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
        }).catch((error) => {
            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch(showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}

export function createSelectedAmortizations(Co, amortizations) {
    return (dispatch, getState) => {

        dispatch({
            type: SET_PROCESSING,
            processing: true
        });
        const request = axios.put(`${window["apiLocation"]}/api/AgreementRevenueAmortization?Co=${Co}`,
            amortizations
        );

        return request.then((response) => {

            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch({
                type: SET_SELECTED_AMORTIZATIONS,
                amortizations: response.data
            });
        }).catch((error) => {
            dispatch({
                type: SET_PROCESSING,
                processing: false
            });
            dispatch(showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
        })
    }
}