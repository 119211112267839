import axios from 'axios/index';
import { getUserData } from 'main/content/apps/divisions/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_DIVISIONS = '[DIVISIONS APP] GET DIVISIONS';
export const SET_SEARCH_TEXT = '[DIVISIONS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_DIVISIONS = '[DIVISIONS APP] TOGGLE IN SELECTED DIVISIONS';
export const SELECT_ALL_DIVISIONS = '[DIVISIONS APP] SELECT ALL DIVISIONS';
export const DESELECT_ALL_DIVISIONS = '[DIVISIONS APP] DESELECT ALL DIVISIONS';
export const OPEN_NEW_DIVISION_DIALOG = '[DIVISIONS APP] OPEN NEW DIVISION DIALOG';
export const CLOSE_NEW_DIVISION_DIALOG = '[DIVISIONS APP] CLOSE NEW DIVISION DIALOG';
export const OPEN_EDIT_DIVISION_DIALOG = '[DIVISIONS APP] OPEN EDIT DIVISION DIALOG';
export const CLOSE_EDIT_DIVISION_DIALOG = '[DIVISIONS APP] CLOSE EDIT DIVISION DIALOG';
export const ADD_DIVISION = '[DIVISIONS APP] ADD DIVISION';
export const UPDATE_DIVISION = '[DIVISIONS APP] UPDATE DIVISION';
export const REMOVE_DIVISION = '[DIVISIONS APP] REMOVE DIVISION';
export const REMOVE_DIVISIONS = '[DIVISIONS APP] REMOVE DIVISIONS';
export const TOGGLE_STARRED_DIVISION = '[DIVISIONS APP] TOGGLE STARRED DIVISION';
export const TOGGLE_STARRED_DIVISIONS = '[DIVISIONS APP] TOGGLE STARRED DIVISIONS';
export const SET_DIVISIONS_STARRED = '[DIVISIONS APP] SET DIVISIONS STARRED ';

export function getDivisions(routeParams) {
    const request = axios.get('/api/divisions-app/divisions', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_DIVISIONS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedDivisions(divisionId) {
    return {
        type: TOGGLE_IN_SELECTED_DIVISIONS,
        divisionId
    }
}


export function selectAllDivisions() {
    return {
        type: SELECT_ALL_DIVISIONS
    }
}

export function deSelectAllDivisions() {
    return {
        type: DESELECT_ALL_DIVISIONS
    }
}


export function openNewDivisionDialog() {
    return {
        type: OPEN_NEW_DIVISION_DIALOG
    }
}

export function closeNewDivisionDialog() {
    return {
        type: CLOSE_NEW_DIVISION_DIALOG
    }
}

export function openEditDivisionDialog(data) {
    return {
        type: OPEN_EDIT_DIVISION_DIALOG,
        data
    }
}

export function openPreloadDivisionDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_DIVISION_DIALOG,
            data: data,
        })
    }
}

export function closeEditDivisionDialog() {
    return {
        type: CLOSE_EDIT_DIVISION_DIALOG
    }
}

export function addDivision(newDivision) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post(`${window["apiLocation"]}/api/Division`,
            newDivision
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_DIVISION
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Division ${newDivision.Division} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateDivision(division) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.put(`${window["apiLocation"]}/api/Division?Co=${encodeURIComponent(division.Co)}&Division=${encodeURIComponent(division.Division)}`,
            division
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_DIVISION
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Division ${division.Division} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeDivision(divisionId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post('/api/divisions-app/remove-division', {
            divisionId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DIVISION
                })
            ]).then(() => dispatch(getDivisions(routeParams)))
        );
    };
}


export function removeDivisions(divisionIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post('/api/divisions-app/remove-divisions', {
            divisionIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DIVISIONS
                }),
                dispatch({
                    type: DESELECT_ALL_DIVISIONS
                })
            ]).then(() => dispatch(getDivisions(routeParams)))
        );
    };
}

export function toggleStarredDivision(divisionId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post('/api/divisions-app/toggle-starred-division', {
            divisionId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DIVISION
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDivisions(routeParams)))
        );
    };
}

export function toggleStarredDivisions(divisionIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post('/api/divisions-app/toggle-starred-divisions', {
            divisionIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DIVISIONS
                }),
                dispatch({
                    type: DESELECT_ALL_DIVISIONS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDivisions(routeParams)))
        );
    };
}

export function setDivisionsStarred(divisionIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post('/api/divisions-app/set-divisions-starred', {
            divisionIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DIVISIONS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DIVISIONS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDivisions(routeParams)))
        );
    };
}

export function setDivisionsUnstarred(divisionIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().divisionsApp.divisions;

        const request = axios.post('/api/divisions-app/set-divisions-unstarred', {
            divisionIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DIVISIONS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DIVISIONS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDivisions(routeParams)))
        );
    };
}
