import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Fab, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from '@lodash';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },

});

class CallGatherKeysList extends Component {

    state = {
        selectedCallGatherKeysMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCallGatherKeyMenu = (event) => {
        this.setState({ selectedCallGatherKeysMenu: event.currentTarget });
    };

    componentDidUpdate(prevProps, prevState) {
        window["warn"]('Call Queue Gathers List:', this.props);
    }

    closeSelectedCallGatherKeysMenu = () => {
        this.setState({ selectedCallGatherKeysMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        return content;
    }

    render() {
        const { classes, securables, searchText, openEditCallGatherKeysDialog, gather } = this.props;
        const data = this.getFilteredArray((!this.props.selectedCallGatherKeysData ? (!this.props.callGatherKeyData ? [] : this.props.callGatherKeyData) : this.props.selectedCallGatherKeysData), searchText);
        const accessLevel = _.find(securables, { Securable: "call-gathers" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={() => this.props.openNewCallGatherKeysDialog(gather)}
                        >
                            <Tooltip title="New Gather Key" placement="left">
                                <Icon>add</Icon>
                            </Tooltip>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Assigned Keys</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditCallGatherKeysDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Key",
                                accessor: "Key",
                                className: "font-bold justify-center",
                                width: 125
                            },
                            {
                                Header: "Action",
                                accessor: "Action",
                                className: "font-bold justify-center",
                                width: 175
                            },
                            {
                                Header: "Target",
                                className: "font-bold justify-center",
                                id: "actionTarget",
                                accessor: d => (d.Action === 'Enqueue' ? d.AssignedQueue : d.Action === 'Dial' ? d.AssignedDial : d.Action === 'Gather' ? d.AssignedGather : d.Action === 'Hunt' ? d.AssignedHuntGroup : d.Action === 'Voicemail' ? d.AssignedVoiceMailbox : ''),
                                width: 175
                            },
                            {
                                Header: "Message Type",
                                accessor: "MessageType",
                                className: "justify-center",
                                width: 175,
                            },
                            {
                                Header: "Audio Clip",
                                accessor: "MessageFileID",
                                className: "justify-center",
                                width: 175,
                            },
                            // {
                            //     Header: "Active",
                            //     accessor: "ActiveYN",
                            //     className: "justify-center",
                            //     width: 64,
                            // },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Call Gather Keys found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCallGatherKeys: Actions.getCallGatherKeys,
        getUserData: Actions.getUserData,
        openNewCallGatherKeysDialog: Actions.openNewCallGatherKeysDialog,
        openEditCallGatherKeysDialog: Actions.openEditCallGatherKeysDialog,
        removeCallGatherKeys: Actions.removeCallGatherKeys,
        removeCallGatherKey: Actions.removeCallGatherKey,
    }, dispatch);
}

function mapStateToProps({ callGatherKeysApp, spReducers }) {
    return {
        callGatherKeys: callGatherKeysApp.CallGatherKeys.entities,
        selectedCallGatherKeyIds: callGatherKeysApp.CallGatherKeys.selectedCallGatherKeyIds,
        searchText: callGatherKeysApp.CallGatherKeys.searchText,
        user: callGatherKeysApp.user,
        callGatherKeyData: spReducers.callGatherKeys,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallGatherKeysList)));
