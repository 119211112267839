export const SET_SCANNING = '[UTILITIES] SET ERROR';
export const RESET_REDUCERS = '[UTILITIES] RESET REDUCERS';

export function resetReducers() {
    return {
        type: RESET_REDUCERS
    }
}

export function setScanning(isScanning) {
    console.warn('Updating isScanning:', isScanning);
    if (isScanning) {
        document.getElementsByTagName("body")[0].className = "body-scan";
    } else {
        document.getElementsByTagName("body")[0].className = "";
    }
    return {
        type: SET_SCANNING,
        isScanning
    }
}