import {combineReducers} from 'redux';
import estimates from './estimates.reducer';
import user from './user.reducer';

const estimatesAppReducers = combineReducers({
    estimates,
    user
});

export default estimatesAppReducers;
