import InventoryLocationGroupsApp from 'main/content/apps/inventory-location-groups/InventoryLocationGroupsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const InventoryLocationGroupsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/inventory-location-groups/:id',
            component: InventoryLocationGroupsApp
        },
        {
            path     : '/apps/inventory-location-groups',
            component: () => <Redirect to="/apps/inventory-location-groups/all"/>
        }
    ]
};
