import {combineReducers} from 'redux';
import templates from './templates.reducer';
import template from './template.reducer';

const emailTemplateAppReducers = combineReducers({
    templates,
    template,
});

export default emailTemplateAppReducers;
