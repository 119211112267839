import MaterialCategoriesApp from 'main/content/apps/material-categories/MaterialCategoriesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const MaterialCategoriesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/material-categories/:id',
            component: MaterialCategoriesApp
        },
        {
            path     : '/apps/material-categories',
            component: () => <Redirect to="/apps/material-categories/all"/>
        }
    ]
};
