import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class TechniciansList extends Component {

    state = {
        selectedTechniciansMenu: null
    };

    componentDidMount() {
        window["log"]('TechniciansList component initial technicianData prop: ', this.props.technicianData);
    }

    componentDidUpdate(prevProps, prevState) {
        window["log"]('TechniciansList component technicianData prop updated: ', this.props.technicianData);
        window["log"](this.state)
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTechnicianMenu = (event) => {
        this.setState({ selectedTechniciansMenu: event.currentTarget });
    };

    closeSelectedTechniciansMenu = () => {
        this.setState({ selectedTechniciansMenu: null });
    };

    render() {
        const { classes, technicians, user, searchText, selectedTechnicianIds, openNewTechnicianDialog, selectAllTechnicians, deSelectAllTechnicians, toggleInSelectedTechnicians, openEditTechnicianDialog, removeTechnicians, removeTechnician, toggleStarredTechnician, setTechniciansUnstarred, setTechniciansStarred, openNewWorkOrderDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedTechnicianData ? (!this.props.technicianData ? [] : this.props.technicianData) : this.props.selectedTechnicianData), searchText);
        const { selectedTechniciansMenu } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewTechnicianDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">people</Icon>Technician List</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTechnicianDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "",
                                    fixed: "left",
                                    accessor: "",
                                    Cell: row => (
                                        <Avatar className="mr-8" alt={row.original.name} src={(row.original.Data && row.original.Data.Avatar ? getProfileImage(`Co=${row.original.Co}&ID=${row.original.Data.Avatar}&Thumb=true`) : avatar)} />
                                    ),
                                    className: "justify-center",
                                    width: 64,
                                    sortable: false
                                },
                                {
                                    Header: "Technician",
                                    accessor: "Technician",
                                    //filterable: true,
                                    className: "font-bold",
                                },
                                {
                                    Header: "First Name",
                                    accessor: "FirstName",
                                    //filterable: true,
                                },
                                {
                                    Header: "Last Name",
                                    accessor: "LastName",
                                    //filterable: true,
                                },
                                {
                                    Header: "MI",
                                    accessor: "MiddleInitial",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Employee #",
                                    accessor: "Employee",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Phone",
                                    accessor: "Phone",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Email",
                                    accessor: "Email",
                                    width: 256,
                                    //filterable: true,
                                },
                                {
                                    Header: "Business Unit",
                                    accessor: "DefaultBusinessUnit",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Division",
                                    accessor: "DefaultDivision",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Department",
                                    accessor: "DefaultDepartment",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Active",
                                    accessor: "ActiveYN",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                            ]
                        }
                        defaultPageSize={10}
                        noDataText="No Technicians found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTechnicians: Actions.getTechnicians,
        getUserData: Actions.getUserData,
        toggleInSelectedTechnicians: Actions.toggleInSelectedTechnicians,
        selectAllTechnicians: Actions.selectAllTechnicians,
        deSelectAllTechnicians: Actions.deSelectAllTechnicians,
        openEditTechnicianDialog: Actions.openEditTechnicianDialog,
        removeTechnicians: Actions.removeTechnicians,
        removeTechnician: Actions.removeTechnician,
        toggleStarredTechnician: Actions.toggleStarredTechnician,
        toggleStarredTechnicians: Actions.toggleStarredTechnicians,
        setTechniciansStarred: Actions.setTechniciansStarred,
        setTechniciansUnstarred: Actions.setTechniciansUnstarred,
        openNewTechnicianDialog: Actions.openNewTechnicianDialog
    }, dispatch);
}

function mapStateToProps({ spReducers, techniciansApp }) {
    return {
        technicians: techniciansApp.technicians.entities,
        selectedTechnicianIds: techniciansApp.technicians.selectedTechnicianIds,
        searchText: techniciansApp.technicians.searchText,
        user: techniciansApp.user,
        technicianData: spReducers.technicians
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechniciansList)));
