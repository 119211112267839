import ErrorPage from 'main/content/pages/errors/stack/StackErrorPage';

export const StackErrorPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/errors/stack',
            component: ErrorPage
        }
    ]
};
