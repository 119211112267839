import React, { Component } from 'react';
import * as Actions from 'main/content/apps/support/requests/store/actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Icon, IconButton, Button, TextField, withStyles } from '@material-ui/core';
import ToolbarMenu from './ToolbarMenu';

class CheckListMenu extends Component {
    state = {
        anchorEl: null,
        Name: '',
        canSubmit: false
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            Name: ''
        });
    };

    onSubmit = (ev) => {
        ev.preventDefault();
        console.info(this.canSubmit());
        if (!this.canSubmit()) {
            return;
        }
        const { Name } = this.state;
        const { card, User } = this.props;
        const { Co, BoardID, ListID, ID } = card;
        const AddedBy = User.UserName;
        const list = {
            Co,
            BoardID,
            ListID,
            SupportRequestID: ID,
            Name,
            AddedBy,
            AddedDate: new Date(),
            Data: {
                ErrMsg: null,
                Items: [],
            }
        }
        this.props.onAddChecklist(list);
        this.handleMenuClose();
    };

    handleTitleChange = (ev) => {
        this.setState({ Name: ev.target.value })
    };

    canSubmit = () => {
        return this.state.Name !== '';
    };

    render() {
        const { Name } = this.state;

        return (
            <div>
                <IconButton color="inherit" onClick={this.handleMenuClick}>
                    <Icon>check_box</Icon>
                </IconButton>
                <ToolbarMenu state={this.state.anchorEl} onClose={this.handleMenuClose}>
                    <form onSubmit={this.onSubmit} className="p-16 flex flex-col items-end">
                        <TextField
                            label="Checklist title"
                            name="Name"
                            value={Name}
                            onChange={this.handleTitleChange}
                            fullWidth
                            className="mb-12"
                            variant="outlined"
                            required
                            autoFocus={true}
                        />
                        <Button
                            color="secondary"
                            type="submit"
                            disabled={!this.canSubmit()}
                            variant="contained"
                        >
                            Add
                        </Button>
                    </form>
                </ToolbarMenu>
            </div>
        );
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addChecklist: Actions.addChecklist,
    }, dispatch);
}

function mapStateToProps({ supportApp, spReducers }) {
    return {
        card: supportApp.request,
        board: supportApp.board,
        users: spReducers.userProfiles.Users,
        User: spReducers.userProfiles.User,
    }
}


export default withStyles({}, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CheckListMenu));
