import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedWorkOrderUsageIds: [],
    routeParams: {},
    workOrderUsageDialog: {
        type: 'new',
        props: {
            open: false,
            readOnly: false
        },
        data: {
            ID: null,
            Co: null,
            WorkOrder: null,
            Scope: 1,
            Line: null,
            Type: 2,
            Status: 'NEW',
            Qty: null,
            UOM: '',
            Description: '',
            Technician: null,
            LaborType: null,
            Material: null,
            ItemID: null,
            CostType: null,
            UnitCost: null,
            CostTotal: null,
            CostDate: new Date(),
            UnitPrice: null,
            PriceTotal: null,
            TaxType: 'S',
            TaxCode: null,
            TaxRate: null,
            TaxTotal: null,
            TaxableYN: 'Y',
            NonBillableYN: "N",
            Invoice: null,
            InvoiceNumber: null,
            InvoiceDate: null,
            BatchMth: null,
            BatchID: null,
            BatchSeq: null,
            CreatedBy: null,
            CreatedDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            Notes: null,
            AttachmentID: null,
            UsageDate: new Date(),
            PO: null,
            POLine: null,
            Data: {
                ErrMsg: null,
            }
        },
        taxability: null,
    }
};

const workOrderUsagesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_WORK_ORDER_USAGES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_WORK_ORDER_USAGES:
            {

                const workOrderUsageId = action.workOrderUsageId;

                let selectedWorkOrderUsageIds = [...state.selectedWorkOrderUsageIds];

                if (selectedWorkOrderUsageIds.find(id => id === workOrderUsageId) !== undefined) {
                    selectedWorkOrderUsageIds = selectedWorkOrderUsageIds.filter(id => id !== workOrderUsageId);
                }
                else {
                    selectedWorkOrderUsageIds = [...selectedWorkOrderUsageIds, workOrderUsageId];
                }

                return {
                    ...state,
                    selectedWorkOrderUsageIds: selectedWorkOrderUsageIds
                };
            }
        case Actions.SELECT_ALL_WORK_ORDER_USAGES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedWorkOrderUsageIds = arr.map(workOrderUsages => workOrderUsages.id);

                return {
                    ...state,
                    selectedWorkOrderUsageIds: selectedWorkOrderUsageIds
                };
            }
        case Actions.DESELECT_ALL_WORK_ORDER_USAGES:
            {
                return {
                    ...state,
                    selectedWorkOrderUsageIds: []
                };
            }
        case Actions.OPEN_NEW_WORK_ORDER_USAGE_DIALOG:
            {
                const { data, taxability } = action;
                return {
                    ...state,
                    workOrderUsageDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            readOnly: false,
                        },
                        data: {
                            ...initialState.workOrderUsageDialog.data,
                            ...data,
                        },
                        taxability
                    }
                };
            }
        case Actions.CLOSE_NEW_WORK_ORDER_USAGE_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_WORK_ORDER_USAGE_DIALOG:
            {
                const { data, taxability, readOnly } = action;
                return {
                    ...state,
                    workOrderUsageDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.workOrderUsageDialog.data,
                            ...data,
                        },
                        taxability
                    }
                };
            }
        case Actions.UPDATE_EDIT_WORK_ORDER_USAGE_DIALOG:
            {
                const { data, taxability, readOnly } = action;
                return {
                    ...state,
                    workOrderUsageDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.workOrderUsageDialog.data,
                            ...data,
                        },
                        taxability
                    }
                };
            }
        case Actions.CLOSE_EDIT_WORK_ORDER_USAGE_DIALOG:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
};

export default workOrderUsagesReducer;
