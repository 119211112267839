import _ from '@lodash';
import { AppBar, Button, Dialog, Drawer, Grow, Icon, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clsx from 'clsx';
import { impress } from 'main/content/compression/impress';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import EstimateComponentsList from '../estimates/EstimateComponentsList';
import CatalogAssemblyList from './CatalogAssemblyList';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    bgGrey: {
        backgroundColor: '#3f3f3f',
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
});

const newAssemblyListState = {
    option: null,
    open: false,
    category: null,
};

class AssemblyListDialog extends Component {

    state = { ..._.cloneDeepWith(newAssemblyListState) };

    componentDidMount() {
        const { option, open } = this.props;
        this.setState({ ..._.cloneDeepWith(newAssemblyListState), option, open });
    }

    componentDidUpdate = (prevProps) => {
        const { option, open } = this.props;
        if (!_.isEqual(option, prevProps.option) || !_.isEqual(open, prevProps.open)) {
            this.setState({ ..._.cloneDeepWith(newAssemblyListState), option, open });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.catalogAssemblyListDialog.type === 'edit' ? this.props.closeEditAssemblyListDialog() : this.props.closeNewAssemblyListDialog();
    };

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    toggleAssemblyLists = (open) => {
        this.setState({
            ...this.state,
            optionBoardsList: open,
        });
    };

    closeAssemblyDialog = () => {
        this.setState({ ...this.state, open: false }, this.props.onClose);
    }

    toggleComponents = (open) => {
        this.setState({
            ...this.state,
            componentsList: open,
        });
    };

    render() {
        window["warn"](this.state);
        const { classes, estimate } = this.props;
        let { catalog } = this.props;
        const { option, componentsList, open } = this.state;
        window["warn"]('Catalog: ', catalog);
        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        open={open}
                        onClose={this.closeAssemblyDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                        onClick={componentsList ? () => this.toggleComponents(false) : undefined}
                    >
                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    <SPRoundIcon className="text-32 align-middle mr-8" />
                                    {`"${option && option.Name}" Tasks & Assemblies`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 56,
                                top: 8,
                                color: 'white'
                            }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.toggleComponents(!componentsList);
                                }}
                            >
                                <Icon>format_list_numbered</Icon>
                            </IconButton>
                            <IconButton style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeAssemblyDialog();
                                }}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        {option &&
                            <Drawer
                                anchor="right"
                                variant="permanent"
                                className={clsx(classNames(classes.drawer, "h-full pin-r pin-t absolute"), {
                                    [classes.drawerOpen]: componentsList,
                                    [classes.drawerClose]: !componentsList
                                })}
                                classes={{
                                    paper: clsx("relative border-none h-full", {
                                        [classes.drawerOpen]: componentsList,
                                        [classes.drawerClose]: !componentsList
                                    })
                                }}
                                open={componentsList}
                                onClose={() => this.toggleAssemblies(false)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div
                                    tabIndex={0}
                                    role="button"
                                >
                                    <div className="w-full p-12 pt-16 sm:pt-20 relative">
                                        <Typography className="font-bold w-full truncate">
                                            <SPRoundIcon className="mr-6 align-middle mb-4" />
                                            "{option.Name}"
                                        </Typography>
                                        <IconButton
                                            className="pin-r pin-t absolute mt-8"
                                            onClick={() => this.toggleComponents(false)}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                    {componentsList && option.Data && option.Data.Components &&
                                        <EstimateComponentsList estimate={{ ...option }} onAddNew={() => this.setState({ ...this.state, componentsList: false })} components={[...option.Data.Components]} optionBoard={true} />
                                    }
                                    <Button
                                        variant="contained"
                                        className={classNames(classes.black, "pin-b pin-r absolute m-12")}
                                        color="primary"
                                        onClick={this.closeAssemblyDialog}
                                    >
                                        Done
                                    </Button>
                                </div>
                            </Drawer>
                        }
                        <div className="p-12 overflow-auto" style={{ maxHeight: 'calc(100% - 76px)' }}>
                            <CatalogAssemblyList optionBoard={{ ...this.state }} option={option} estimate={estimate} onCategoryChange={(category) => this.setState({ ...this.state, category })} onAddComponent={() => this.toggleComponents(true)} onRemoveComponent={() => this.toggleComponents(true)} category={this.state.category} dialog={true} catalog={catalog} />
                        </div>
                    </Dialog>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        impress,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AssemblyListDialog));
