import TripsApp from 'main/content/apps/trips/TripsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const TripsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/trips/:id',
            component: TripsApp
        },
        {
            path     : '/apps/trips',
            component: () => <Redirect to="/apps/trips/all"/>
        }
    ]
};
