import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Badge, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Grow, Hidden, Icon, IconButton, MenuItem, MobileStepper, Slide, Switch, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import OptionBoardTypeDialog from 'main/content/apps/option-board-types/OptionBoardTypeDialog';
import { impress } from 'main/content/compression/impress';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import CatalogAssemblyList from '../catalog/CatalogAssemblyList';
import EstimateTMItem from '../estimates/EstimateTMItem';
// import OptionBoardOptionsList from '../estimates/OptionBoardOptionsList';
// import CatalogOptionBoardOptionsList from './CatalogOptionBoardOptionsList';
// import CatalogOptionBoardImageDialog from './CatalogOptionBoardImageDialog';
import { openNewOptionBoardTypeDialog } from '../option-board-types/store/actions';
import CatalogAssemblyListDialog from './CatalogAssemblyListDialog';
import CatalogItem from './CatalogItem';
import CatalogOptionBoardOption from './CatalogOptionBoardOption';
import CatalogOptionBoardOptionCard from './CatalogOptionBoardOptionCard';
import EstimateComponents from '../estimates/EstimateComponents';
import EstimateOptionCard from '../estimates/EstimateOptionCard';
import * as Actions from './store/actions';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    bgGrey: {
        backgroundColor: '#3f3f3f',
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    topShadow: {
        boxShadow: '0px 0px 12px 0px rgba(0,0,0,.15)',
    },
    estimateSummary: {
        boxShadow: '0px 0px 12px 4px rgba(0,0,0,.25)',
        zIndex: 999
    },
    estimateSummaryBottom: {
        zIndex: 999
    },
    countBadge: {
        top: 12,
        right: -18
    },
});

const newOptionBoardState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Name: null,
    Description: null,
    OptionsType: null,
    SharedYN: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    AttachmentID: null,
    Data: {
        ErrMsg: null,
        Options: [],
    },
    StandardYN: 'Y',
    featureDescription: null,
    simpleStep: 0,
    optionsType: null,
    category: null,
    RecommendedOption: null,
    Category: null,
    editOption: null,
    prevStep: null,
    hideSummary: true,
    addDescription: false
};

var newOption = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Option: null,
    Name: null,
    Description: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    PriceTotal: 0.00,
    AttachmentID: null,
    Data: {
        Components: [],
        ErrMsg: null,
    }

}
const newTMItemState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Name: null,
    Description: null,
    Assembly: null,
    Material: null,
    LaborType: null,
    Type: 2,
    PriceMethod: 'F',
    RateTemplate: null,
    Price: 0,
    TaxableYN: 'Y',
    DisplayYN: null,
    Notes: null,
    UnitCost: null,
    TotalCost: null,
    CustomPrice: null,
    CustomMemberPrice: null,
    CustomImgURL: null,
    EnteredBy: null,
    UpdatedBy: null,
    EnteredDate: null,
    UpdatedDate: null,
    Category: null,
    Qty: 1,
    UOM: 'EA',
    imageDialog: false,
    selectedImage: null,
    value: 0,
    saving: false,
    showRecommendations: false,
    estimate: null,
    option: null,
    partDescription: null,
    searching: false
}

class OptionBoardDialog extends Component {

    state = { ..._.cloneDeepWith(newOptionBoardState) };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidUpdate = (prevProps) => {
        const { data } = this.props.catalogOptionBoardDialog;

        if (!prevProps.catalogOptionBoardDialog.props.open && this.props.catalogOptionBoardDialog.props.open) {

            if (this.props.catalogOptionBoardDialog.type === 'edit' && data &&
                !_.isEqual(data, prevProps.catalogOptionBoardDialog.data)) {
                this.setState({ ..._.cloneDeepWith(newOptionBoardState), ...data, showTabs: !this.props.estimate });
            }

            if (this.props.catalogOptionBoardDialog.type === 'new' && data &&
                !_.isEqual({ ...newOptionBoardState, ...data }, prevProps.catalogOptionBoardDialog.data)) {
                this.setState({ ..._.cloneDeepWith(newOptionBoardState), ...data, showTabs: true });
            }
        } else {
            if (this.props.catalogOptionBoardDialog.type === 'update' &&
                this.props.catalogOptionBoardDialog.data &&
                !_.isEqual({ ...newOptionBoardState, ...data }, prevProps.catalogOptionBoardDialog.data)) {
                // window["warn"]('Data Updated: ', data);
                this.props.catalogOptionBoardDialog.type = 'edit';
                this.setState({ ...data, saving: false });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        const { selectedComponent, selectedTMItem } = this.state;
        if (selectedComponent || selectedTMItem) {
            this.setState({ selectedComponent: null, selectedTMItem: null });
        } else {
            this.props.catalogOptionBoardDialog.type === 'edit' ? this.props.closeEditOptionBoardDialog() : this.props.closeNewOptionBoardDialog();
        }
    };

    canBeSubmitted() {
        const { Co, Name, Description, OptionsType } = this.state;
        return (
            Co && Name && Co > 0 && Name.length > 0 && OptionsType
        );
    }

    toggleTabs = () => {
        const { showTabs } = this.state;
        this.setState({ ...this.state, showTabs: !showTabs });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    increaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate.Data && estimate.Data.Options) {
                const { Co, BusinessUnit, Division, OptionBoard } = this.state;
                component = _.find(estimate.Data.Options, { Co, BusinessUnit, Division, OptionBoard });
            }
        }
        Qty = Number(Qty || (component ? component.Qty : 1)) + 1;
        this.setState({ ...this.state, Qty });
    }

    decreaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate.Data && estimate.Data.Options) {
                const { Co, BusinessUnit, Division, OptionBoard } = this.state;
                component = _.find(estimate.Data.Options, { Co, BusinessUnit, Division, OptionBoard });
            }
        }
        Qty = Number(Qty || (component ? component.Qty : 1)) - 1;
        this.setState({ ...this.state, Qty });
    }

    toggleOptionBoards = (open) => {
        this.setState({
            ...this.state,
            optionBoardsList: open,
        });
    };

    toggleImageDialog = (open) => {
        this.setState({ ...this.state, imageDialog: open });
    }

    handleNext = () => {
        const steps = ["Basic Info", ...this.state.Data.Options.map((opt) => opt.Name)/*, "Options"*/, `Review and ${this.state.ID ? "Save" : "Add"}`];
        let { simpleStep } = this.state;
        if (simpleStep < steps.length - 1) {
            simpleStep += 1;
            this.setState({ ...this.state, simpleStep, prevStep: simpleStep - 1 })
        }
    }

    handleBack = () => {
        let { simpleStep } = this.state;
        if (simpleStep > 0) {
            simpleStep -= 1;
            this.setState({ ...this.state, simpleStep, prevStep: simpleStep + 1 });
        } else {
            this.setState({ ...this.state, simpleStep });
        }
    }

    closeAssemblyDialog = () => {
        this.setState({ ...this.state, editOption: null });
    }


    setOptionComponents = (option, Components) => {
        const OptionBoard = this.state;
        const opt = _.find(OptionBoard.Data.Options, { Option: option.Option });
        const { selectedComponent } = this.state;
        const shouldClose = !Boolean(selectedComponent && selectedComponent.Data && selectedComponent.Data.Recommendations && selectedComponent.Data.Recommendations.length > 0);
        if (opt) {
            opt.Data.Components = Components;
            this.setState({ ...OptionBoard, selectedComponent: shouldClose ? null : selectedComponent });
        }
    }

    setOptionsType = (optionType) => {
        const Options = [];
        const { Co, BusinessUnit, Division, OptionBoard, Data } = this.state;
        const oldOptions = Data.Options;
        optionType.Data.Options.map((value, index) => {
            const { Name, Option } = value;
            const oldOption = oldOptions ? oldOptions[index] : null;
            const option = {
                ...(oldOption || _.cloneDeepWith(newOption)),
                Co,
                BusinessUnit,
                Division,
                OptionBoard,
                Option,
                Name,
            };
            Options.push(option);
        })
        this.setState({ ...this.state, OptionsType: optionType.OptionsType, Data: { ...Data, Options } });
    }

    selectOption = (option) => {
        this.setState({ ...this.state, RecommendedOption: option.Option });
    }

    deSelectOption = () => {
        this.setState({ ...this.state, RecommendedOption: null });
    }

    editOption = (editOption) => {
        this.setState({ ...this.state, editOption });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    saveOption = (option) => {
        const { Data } = this.state;
        const { Co, BusinessUnit, Division, Option } = option;
        const { Options } = Data;
        const index = _.findIndex(Options, { Co, BusinessUnit, Division, Option });
        if (index > -1) {
            Options.splice(index, 1, _.cloneDeepWith(option));
            this.setState({ ...this.state, Data: { ...Data, Options } });
        }
    }

    toggleComponents = (open) => {
        this.setState({
            ...this.state,
            componentsList: open,
        });
    };

    render() {
        // window["warn"](this.state);
        const { classes, catalogOptionBoardDialog, Co, companies, businessUnits, divisions, optionTypes, } = this.props;
        let { catalog, estimate } = this.props;
        // window["warn"]('Catalog: ', catalog);
        const { optionBoardsList, simpleStep, editOption, componentsList, selectedComponent, selectedTMItem, prevStep, isMember, BusinessUnit, Division, hideSummary, addDescription } = this.state;
        const steps = ["Basic Info", ...this.state.Data.Options.map((opt) => opt.Name)/*, "Options"*/, `Review and ${this.state.ID ? "Save" : "Add"}`];
        const optionType = _.find(optionTypes, { Co, OptionsType: this.state.OptionsType });
        const currentOption = (simpleStep > 0 && simpleStep < steps.length - 1) ? this.state.Data.Options[simpleStep - 1] : null;
        const co = _.find(companies, { Co }).Data.Co;
        const sub = currentOption && currentOption.Data.Components ? _.sumBy(_.filter(currentOption.Data.Components, (o) => o.DeleteYN !== 'Y'), (o) => { return Number(o.Price || 0); }) : 0.00;
        const categories = (catalog ? catalog.Data.Categories : []).filter(c => !c.ParentCategory);
        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"
                        }}
                        className={classes.root}
                        {...catalogOptionBoardDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                        onClick={optionBoardsList ? () => this.toggleOptionBoards(false) : undefined}
                    >
                        <OptionBoardTypeDialog />
                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    <SPRoundIcon className="text-32 align-middle mr-8" />
                                    {`${simpleStep + 1}. ${steps[simpleStep]}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 8,
                                top: matches ? 8 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            {/*showTabs &&
                                <Tabs
                                    value={value}
                                    onChange={this.handleTabChange}
                                    variant="scrollable"
                                    scrollButtons="on"
                                >
                                    <Tab disableRipple icon={<SPRoundIcon />} label="Info" />
                                    <Tab disableRipple disabled={!this.state.ID} icon={<Badge color="error" badgeContent={(this.state.Data.Options || []).length}><Icon>table_chart</Icon></Badge>} label="Options" />
                                    {/* <Tab disableRipple icon={<Icon>chrome_reader_mode</Icon>} label="Price Book" />
                                </Tabs>
                            */}
                        </AppBar>
                        <DialogContent className={!estimate ? "p-24 pb-48" : "p-12 px-24 pb-48"}>
                            {simpleStep === 0 &&
                                <div className="w-full p-12 pb-48">
                                    <div className="w-full sm:flex">
                                        <Autocomplete
                                            className={classNames("mb-12")}
                                            title="Business Unit"
                                            options={businessUnits}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.BusinessUnit}>
                                                    {value.Description}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.BusinessUnit}
                                            onSelect={(option) => this.setState({ ...this.state, Co: option.Co, BusinessUnit: option.BusinessUnit, Division: null, category: null }, this.setCatalog)}
                                            required
                                            disabled={true}
                                        />

                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <Autocomplete
                                            className={classNames("mb-12")}
                                            title="Division"
                                            options={divisions ? _.filter(divisions, { BusinessUnit: this.state.BusinessUnit }) : []}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.Division}>
                                                    {value.Description}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.Division}
                                            onSelect={(option) => this.setState({ ...this.state, Co: option.Co, BusinessUnit: option.BusinessUnit, Division: option.Division, category: null }, this.setCatalog)}
                                            required
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="w-full sm:flex">
                                        <TextField
                                            className={classNames("mb-12")}
                                            label="Name"
                                            id="name"
                                            name="Name"
                                            value={this.state.Name}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <Autocomplete
                                            className="mb-12"
                                            title="Option Board Type"
                                            options={[...optionTypes, { OptionsType: "+", Name: "Add New", icon: "add" }]}
                                            menuItemComponent={(value) => {
                                                return (
                                                    <MenuItem value={value.OptionsType}>
                                                        {value.icon &&
                                                            <Icon className="mr-6">{value.icon}</Icon>
                                                        }
                                                        {value.Name}
                                                    </MenuItem>
                                                )
                                            }}
                                            portal={true}
                                            value={this.state.OptionsType || ''}
                                            onSelect={(option) => {
                                                if (option.OptionsType !== "+") {
                                                    this.setOptionsType(option);
                                                } else {
                                                    this.props.openNewOptionBoardTypeDialog();
                                                }
                                            }}
                                            required
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>

                                        <Autocomplete
                                            className={classNames(classes.formControl, "mb-4 sm:mb-12")}
                                            title="Category"
                                            options={[{ Category: null, Name: 'None' }, ...categories] || []}
                                            menuItemComponent={(value) => {
                                                return <MenuItem value={value.Category}>
                                                    {`${value.Name}`}
                                                </MenuItem>
                                            }}
                                            portal={true}
                                            value={this.state.Category || ''}
                                            onSelect={(option) => this.setState({ ...this.state, Category: option.Category })}
                                        />
                                    </div>

                                    <div className="w-full sm:flex">
                                        <TextField
                                            className={classNames("mb-12")}
                                            label="Summary"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        // required
                                        />
                                    </div>

                                    <div className="w-full sm:flex">
                                        <TextField
                                            className={classNames("mb-12")}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="w-full sm:flex justify-between">
                                        <FormControlLabel
                                            className="text-left"
                                            control={
                                                <Switch
                                                    checked={this.state.SharedYN === 'Y'}
                                                    name="SharedYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Shared?"
                                        />
                                        <FormControlLabel
                                            className="text-right mx-0"
                                            control={
                                                <Switch
                                                    checked={this.state.StandardYN === 'Y'}
                                                    name="StandardYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Standard?"
                                        />
                                    </div>
                                </div>
                            }

                            {currentOption &&
                                <div key={currentOption.Option} className="w-full h-full">
                                    <div className="w-full h-full" style={{ display: selectedComponent || selectedTMItem ? "none" : undefined }}>
                                        <CatalogAssemblyList enterFrom={prevStep < simpleStep ? "right" : "left"} isMember={isMember || _.findIndex(currentOption.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1} onCategoryChange={(category) => this.setState({ ...this.state, category })} category={this.state.category} dialog={true} catalog={catalog} estimate={currentOption} info={this.state} onSelected={(selectedComponent) => this.setState({ selectedComponent: { ...selectedComponent, isNew: true } })} onSelectedItem={() => this.setState({ selectedTMItem: { ..._.cloneDeepWith(newTMItemState), Co, BusinessUnit, Division } })} onAddComponent={(Components) => this.setOptionComponents(currentOption, Components)} onUpdated={(Components) => this.setOptionComponents(currentOption, Components)} />
                                    </div>
                                    {
                                        this.state.selectedComponent &&
                                        <CatalogItem onSelected={(selectedComponent) => this.setState({ selectedComponent: { ...selectedComponent, isNew: true } })} isMember={isMember || _.findIndex(currentOption.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1} data={selectedComponent} estimate={currentOption} catalog={catalog} onUpdated={(Components) => this.setOptionComponents(currentOption, Components)} />
                                    }
                                    {selectedTMItem &&
                                        <EstimateTMItem isMember={isMember || _.findIndex(currentOption.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1} estimate={currentOption} catalog={catalog} data={selectedTMItem} onUpdated={(Components) => this.setState({ selectedTMItem: null }, () => this.setOptionComponents(currentOption, Components))} />
                                    }
                                </div>
                            }
                            {/*simpleStep === (steps.length - 2) &&
                                <div className="w-full max-w-full overflow-x-auto flex pt-36 pb-72">
                                    <div className="min-w-24 min-h-12"></div>
                                    {this.state.Data.Options && this.state.Data.Options.map((value) => {
                                        const selected = Boolean(this.state.RecommendedOption === value.Option);
                                        return (
                                            <CatalogOptionBoardOptionCard data={{ ...value }} estimate={estimate} selected={selected} onChange={this.saveOption} optionBoard={{ ...this.state }} options={[...this.state.Data.Options]} catalog={catalog} onEdit={this.editOption} onView={(option) => this.viewOption(option)} />
                                        );
                                    })}
                                    <div className="min-w-24 min-h-12"></div>
                                    <CatalogAssemblyListDialog option={editOption} estimate={estimate} open={Boolean(editOption)} catalog={catalog} onClose={() => this.closeAssemblyDialog()} />
                                </div>
                                */}
                            {
                                simpleStep === (steps.length - 1) &&
                                <div className="w-full p-12">
                                    {this.state.Data.Options && this.state.Data.Options.map((value, index) => {
                                        return <CatalogOptionBoardOption key={index} optionBoard={{ ...this.state }} hideSavings={true} selected={value.Option === this.state.RecommendedOption} data={{ ...value, ComponentDetail: 'S' }} />
                                    })
                                    }
                                </div>
                            }
                        </DialogContent>

                        {currentOption &&
                            <DialogActions className={classNames("pin-b pin-l pin-r p-0 absolute bg-white", !hideSummary ? classes.estimateSummary : classes.estimateSummaryBottom, !selectedComponent && !selectedTMItem ? "mb-48" : "")}>
                                <div className={classNames("relative w-full")}>
                                    <div onClick={() => this.setState({ hideSummary: !hideSummary })} className={classNames("w-full flex justify-between p-12 py-8 cursor-pointer", hideSummary && "estimate-stepper")} style={{ backgroundColor: '#3f3f3f', borderTop: '2px solid #3f3f3f' }}>
                                        <Badge classes={{ badge: classes.countBadge }} color="error" badgeContent={currentOption ? _.filter(currentOption.Data.Components, (o) => o.DeleteYN !== "Y").length : (_.filter(this.state.Data.Components, (o) => o.DeleteYN !== "Y") || []).length}>
                                            <Typography onClick={(e) => { if (selectedComponent || selectedTMItem) { e.stopPropagation(); this.setState({ selectedComponent: null, selectedTMItem: null, hideSummary: true }); } }} variant="h6" className="text-16 text-white flex">{(selectedComponent || selectedTMItem) && <Icon className="mr-4">keyboard_arrow_left</Icon>}{currentOption && <React.Fragment>{currentOption.Name}<Hidden xsDown>{` - ${currentOption.Option} of ${this.state.Data.Options.length}`}</Hidden></React.Fragment>}</Typography>
                                        </Badge>
                                        {/* <Typography variant="h6" className="text-16 flex" color="primary">{this.state.Data.Components.length} Items</Typography> */}
                                        <Typography color="secondary" variant="h6" className={classNames("font-bold text-16 mr-4")}>Subtotal: <span className="text-white">{this.formatDollars(sub)}</span>
                                            {(currentOption.Data.Components && currentOption.Data.Components.length > 0) &&
                                                <Icon className="text-white ml-12 align-middle">{hideSummary ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                            }
                                        </Typography>
                                    </div>
                                    {!hideSummary &&
                                        <div className="w-full flex">
                                            <div className="w-full text-left">
                                                <FormControlLabel
                                                    className="ml-12 truncate"
                                                    // style={{ float: 'right', }}
                                                    control={
                                                        <Switch
                                                            checked={this.state.addDescription}
                                                            onChange={() => this.setState({ addDescription: !this.state.addDescription })}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Edit Description"
                                                />
                                            </div>
                                            <div className="w-full text-right">
                                                <FormControlLabel
                                                    className="mr-24 truncate"
                                                    // style={{ float: 'right', }}
                                                    control={
                                                        <Switch
                                                            checked={this.state.RecommendedOption === currentOption.Option}
                                                            onChange={() => this.setState({ RecommendedOption: this.state.RecommendedOption === currentOption.Option ? null : currentOption.Option })}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Recommended"
                                                />
                                            </div>
                                        </div>
                                    }
                                    {!hideSummary && !this.state.addDescription && currentOption.Description &&
                                        <div className="w-full px-24 mb-12">
                                            <Typography className={"text-14 font-bold"}>Description of Work:</Typography>
                                            <Typography className={"text-12"}>{currentOption.Description}</Typography>
                                        </div>
                                    }
                                    {!hideSummary && this.state.addDescription &&
                                        <div className="w-full sm:flex px-24 my-8">
                                            <TextField
                                                className={classNames("mb-12")}
                                                label="Description of Work"
                                                id="descriptionOfWork"
                                                value={currentOption.Description || ''}
                                                onChange={(e) => {
                                                    const { Options } = this.state.Data;
                                                    const index = _.findIndex(Options, { Option: currentOption.Option });
                                                    if (index > -1) {
                                                        const opt = Options[index];
                                                        opt.Description = e.target.value;
                                                        Options.splice(index, 1, opt);
                                                        this.setState({ ...this.state, Data: { ...this.state.Data, Options } });
                                                    }
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                fullWidth
                                            // required
                                            />
                                        </div>
                                    }
                                    {!hideSummary && currentOption && currentOption.Data.Components.length < 1 &&
                                        <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                            <div className="dialog-actions">
                                                <div className="w-full px-12" style={{ backgroundColor: '#f1f1f1' }}>
                                                    <div className="w-full flex max-w-full overflow-x-auto">
                                                        {this.state.Data.Options.map((opt, index) => {
                                                            if (opt.Option !== currentOption.Option && opt.Data.Components.length > 0) {
                                                                return (
                                                                    <EstimateOptionCard option={opt} key={opt.Option} optionBoard={this.state} co={co} catalog={catalog} onCopy={(Components) => this.setOptionComponents(currentOption, Components)} />
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </FuseAnimate>
                                    }
                                    {!hideSummary &&
                                        <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                            <div className="dialog-actions">
                                                <div className="w-full px-12" style={{ backgroundColor: '#f1f1f1' }}>
                                                    <EstimateComponents topBar={true} catalog={catalog} estimate={currentOption} optionBoard={{ ...this.state }} onSelected={(selectedComponent) => this.setState({ ...this.state, selectedComponent, selectedTMItem, hideSummary: true })} onSelectedItem={(selectedTMItem) => this.setState({ ...this.state, selectedTMItem, hideSummary: true })} components={currentOption ? currentOption.Data.Components : []} onUpdate={(Components) => { this.setOptionComponents(currentOption, Components); }} />
                                                </div>
                                            </div>
                                        </FuseAnimate>
                                    }
                                </div>
                            </DialogActions>
                        }

                        <DialogActions className="dialog-actions justify-between pl-16">
                            <MobileStepper
                                variant="dots"
                                steps={steps.length}
                                position="static"
                                activeStep={simpleStep}
                                className={"pin-b pin-l pin-r absolute m-0"}
                                nextButton={
                                    simpleStep < (steps.length - 1) ?
                                        <Button size="small" onClick={this.handleNext} disabled={simpleStep === steps.length || !this.state.Data.Options || !this.state.Name || !this.state.OptionsType}>
                                            Next
                                            <Icon>keyboard_arrow_right</Icon>
                                        </Button> :
                                        !this.state.ID ?
                                            <Button
                                                variant="contained"
                                                className="h-32 w-72"
                                                color="primary"
                                                onClick={() => {
                                                    Promise.all([
                                                        this.props.addOptionBoard({ ...this.state, viewOption: null, editOption: null })
                                                    ]).then(() => {
                                                        this.closeComposeDialog();
                                                    });
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                Add
                                            </Button> :

                                            <Button
                                                variant="contained"
                                                className="h-32 w-72"
                                                color="primary"
                                                onClick={() => {
                                                    Promise.all([
                                                        this.props.updateOptionBoard({ ...this.state, viewOption: null, editOption: null })
                                                    ]).then(() => {
                                                        this.closeComposeDialog();
                                                    });
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                Save
                                            </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={this.handleBack} disabled={simpleStep === 0}>
                                        <Icon>keyboard_arrow_left</Icon>
                                        Back
                                    </Button>
                                }
                            />
                        </DialogActions>
                    </Dialog>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditOptionBoardDialog: Actions.closeEditOptionBoardDialog,
        closeNewOptionBoardDialog: Actions.closeNewOptionBoardDialog,
        addOptionBoard: Actions.addOptionBoard,
        updateOptionBoard: Actions.updateOptionBoard,
        openNewOptionBoardTypeDialog,
        showMessage: showMessage,
        impress,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        catalogOptionBoardDialog: catalogApp.catalogOptionBoardDialog,
        rateTemplates: spReducers.rateTemplates,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        employees: spReducers.employees,
        optionTypes: spReducers.optionTypes,
        categories: spReducers.catalog.categories,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        catalog: catalogApp.catalogOptionBoardDialog.catalog
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(OptionBoardDialog));
