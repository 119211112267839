export const HIDE_NOTIFICATION = '[NOTIFICATION] CLOSE';
export const SHOW_NOTIFICATION = '[NOTIFICATION] SHOW';

export function hideNotification()
{
    return {
        type: HIDE_NOTIFICATION
    }
}

export function showNotification(options)
{
    return {
        type: SHOW_NOTIFICATION,
        options
    }
}

