import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Chip,
  Dialog,
  DialogActions,
  Fab,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import axios from 'axios'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'
import FileList from 'main/content/apps/file-manager/FileList'
import InvoicesList from 'main/content/apps/invoices/InvoicesList'
import TripsList from 'main/content/apps/trips/TripsList'
import WorkOrderUsagesList from 'main/content/apps/work-order-usages/WorkOrderUsagesList'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import { impress } from 'main/content/compression/impress'
import * as DialerActions from 'main/dialer/store/actions'
import ChipInput from 'material-ui-chip-input'
import React, { Component } from 'react'
import Geocode from 'react-geocode'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import {
  openEditAgreementDialog,
  openNewAgreementDialog,
} from '../agreements/store/actions'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import InvoiceCustomer from '../invoices/InvoiceCustomer'
import { openNewInvoiceDialog } from '../invoices/store/actions'
import PurchaseOrdersList from '../purchase-orders/PurchaseOrdersList'
import { openNewPurchaseOrderDialog } from '../purchase-orders/store/actions'
import ServiceUnitsList from '../call-handler/ServiceUnitsList'
import * as TripActions from '../trips/store/actions'
import { openNewWorkOrderUsageDialog } from '../work-order-usages/store/actions'
import RevenueSplitDialog from './RevenueSplitDialog'
import RevenueSplitsList from './RevenueSplitsList'
import ChangeDialog from '../../components/change-log/ChangeDialog'
import * as Actions from './store/actions'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import moment from 'moment'
import DraggableDialog from '../draggable-dialog/DraggableDialog'

function Marker({ text, icon, style, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <div onClick={onClick}>
        <img
          onMouseDown={e => {
            e.stopPropagation()
          }}
          style={style}
          src={icon}
        />
      </div>
    </Tooltip>
  )
}

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

const styles = theme => ({
  root: {
    //zIndex: '16 !important'
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  formControl: {
    marginBottom: 24,
  },
  status: {
    marginBottom: 24,
    maxWidth: 100,
  },
  avatar2: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  avatar: {
    // backgroundColor: '#555555',
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  customerSelect: {
    padding: 2,
    width: '100%',
    border: '1px solid lightgrey',
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  customerScroll: {},
  actionButton: {
    background: '#3f3f3f',
  },
  liRoot: {
    padding: 0,
  },
  actionBadge: {
    marginRight: 8,
    marginTop: 8,
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  yellow: {
    backgroundColor: '#f4b636',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
  },
  grey: {
    backgroundColor: '#5f5f5f',
    color: '#fff',
  },
  redText: {
    color: '#f44336',
  },
  greenText: {
    color: '#d1e751',
  },
  blueText: {
    color: '#4dbce9',
  },
  yellowText: {
    color: '#f4b636',
  },
  blackText: {
    color: '#3f3f3f',
  },
  greyText: {
    color: '#5f5f5f',
  },
  drawer: {
    width: 58,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 164,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerBadge: {
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    top: 4,
    right: -2,
  },
  taskBadge: {
    top: 20,
    right: 39,
    background: '#3f3f3f',
  },
  taskBadgeIcon: {
    fontSize: 12,
  },
  taskCountBadge: {
    top: 14,
    right: -6,
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    border: '2px solid #3f3f3f',
  },
  cancelButton: {
    background: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      background: theme.palette.error[600],
    },
    '&:active': {
      background: theme.palette.error[400],
    },
  },
  reOpenButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.light,
    },
    '&:active': {
      background: theme.palette.secondary.dark,
    },
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5f5f5f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
    '&:active': {
      backgroundColor: '#5f5f5f',
    },
  },
  colorPrimary: {
    color: theme.palette.primary.dark + ' !important',
    fontWeight: 'bold',
  },
})
let newWorkOrderScopeState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  Department: null,
  WorkOrder: null,
  BillToCustomer: null,
  Scope: 1,
  ServiceType: null,
  Service: null,
  Description: null,
  Originator: null,
  Requestor: null,
  DueBy: null,
  ArrivalWindow: null,
  ExactTime: null,
  Priority: 'Low',
  CustomerPO: null,
  NotToExceed: null,
  LeadTechnician: null,
  PriceMethod: 'T',
  Price: null,
  RateTemplate: null,
  FormOfPayment: null,
  NonBillableYN: 'N',
  TaxCode: null,
  TaxableYN: 'Y',
  QuoteID: null,
  EnteredBy: null,
  EnteredDate: new Date(),
  Status: 0,
  Notes: null,
  ReadyToBillYN: null,
  IsWarrantyYN: null,
  WarrantyTechnician: null,
  CallSid: null,
  Estimate: null,
  Summary: null,
  CancellationReason: null,
  value: 0,
  Data: {
    WorkOrder: {},
    Customer: {
      Address1: '',
      City: '',
      State: '',
      Zip: '',
    },
    Site: {
      Address1: '',
      City: '',
      State: '',
      Zip: '',
    },
    Tags: [],
    Splits: [],
    Trips: [],
    Usage: [],
    Comments: [],
    ServiceUnits: [],
  },
  streetView: false,
  BillingData: null,
  Latitude: null,
  Longitude: null,
  showTabs: true,
  activityType: 'tags',
  expanded: true,
  viewChanges: false,
  dialogHeight: null,
  minHeight: null,
  location: null,
}

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

class WorkOrderScopesDialog extends Component {
  state = { ..._.cloneDeepWith(newWorkOrderScopeState) }

  ref = React.createRef()

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    const initialState = _.cloneDeepWith(newWorkOrderScopeState)

    newWorkOrderScopeState.Co = this.props.Co
    if (
      !prevProps.workOrderScopeDialog.props.open &&
      this.props.workOrderScopeDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.workOrderScopeDialog.type === 'edit' &&
        this.props.workOrderScopeDialog.data &&
        !_.isEqual(this.props.workOrderScopeDialog.data, prevState)
      ) {
        const data = this.props.workOrderScopeDialog.data
        data.Address = this.buildAddress(
          data.Data.Site.Address1,
          data.Data.Site.City,
          data.Data.Site.State,
          data.Data.Site.Zip,
        )
        if (
          (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
          window.google
        ) {
          Geocode.fromAddress(
            `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                ...initialState,
                ...data,
                Latitude: lat,
                Longitude: lng,
              })
            },
            () => {
              this.setState({ ...initialState, ...data })
            },
          )
        } else {
          const { Latitude, Longitude } = data.Data.Site
          this.setState({ ...initialState, ...data, Latitude, Longitude })
        }
      }

      if (
        this.props.workOrderScopeDialog.type === 'site' &&
        this.props.workOrderScopeDialog.data &&
        !_.isEqual(this.props.workOrderScopeDialog.data, prevState)
      ) {
        const data = this.props.workOrderScopeDialog.data
        data.Address = this.buildAddress(
          data.Data.Site.Address1,
          data.Data.Site.City,
          data.Data.Site.State,
          data.Data.Site.Zip,
        )
        data.BusinessUnit = initialState.BusinessUnit
        data.Division = initialState.Division
        if (
          (!data.Data.Site.Latitude || !data.Data.Site.Longitude) &&
          window.google
        ) {
          Geocode.fromAddress(
            `${data.Data.Site.Address1} ${data.Data.Site.City}, ${data.Data.Site.State} ${data.Data.Site.Zip}`,
          ).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                ...initialState,
                ...data,
                Latitude: lat,
                Longitude: lng,
              })
            },
            () => {
              this.setState({ ...initialState, ...data })
            },
          )
        } else {
          const { Latitude, Longitude } = data.Data.Site
          this.setState({ ...initialState, ...data, Latitude, Longitude })
        }
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.workOrderScopeDialog.type === 'new' &&
        !_.isEqual(newWorkOrderScopeState, prevState)
      ) {
        this.setState({ ...initialState })
      }
    }
    if (
      this.props.workOrderScopeDialog.type === 'add' &&
      this.props.workOrderScopeDialog.data &&
      !_.isEqual(this.props.workOrderScopeDialog.data, prevState)
    ) {
      this.props.workOrderScopeDialog.type = 'edit'
      this.setState({ ...this.props.workOrderScopeDialog.data })
    }
    if (
      this.props.workOrderScopeDialog.type === 'update' &&
      this.props.workOrderScopeDialog.data &&
      !_.isEqual(this.props.workOrderScopeDialog.data, prevState)
    ) {
      this.props.workOrderScopeDialog.type = 'edit'
      this.setState({ ...this.props.workOrderScopeDialog.data })
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleDueByChange = event => {
    let { DueBy } = this.state
    if (event) {
      DueBy = event._d.toLocaleDateString('en-US')
    } else {
      DueBy = null
    }
    this.setState({ ...this.state, DueBy })
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  closeComposeDialog = () => {
    this.props.workOrderScopeDialog.type === 'edit'
      ? this.props.closeEditWorkOrderScopeDialog()
      : this.props.closeNewWorkOrderScopeDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.workOrderScopeDialog.type === 'edit'
      ? this.props.closeEditWorkOrderScopeDialog()
      : this.props.closeNewWorkOrderScopeDialog()
  }

  canBeSubmitted() {
    const {
      Co,
      Description,
      BusinessUnit,
      Division,
      Department,
      Service,
      ArrivalWindow,
      ExactTime,
      ServiceType,
      PriceMethod,
      Price,
      RateTemplate,
      WorkOrder,
      CustomerPO,
    } = this.state

    let requireCustomerPO =
      this.state.Data &&
      this.state.Data.Customer &&
      this.state.Data.Customer.PORequiredYN === 'Y'
    return (
      Co &&
      Description &&
      BusinessUnit &&
      Division &&
      Department &&
      ServiceType &&
      Service &&
      (ArrivalWindow || ExactTime) /* && LeadTechnician && FormOfPayment*/ &&
      PriceMethod &&
      (PriceMethod === 'F' ? Boolean(Price) || Price === 0 : RateTemplate) &&
      WorkOrder &&
      WorkOrder > 0 &&
      (requireCustomerPO ? CustomerPO : true)
    )
  }

  toggleStreetview(streetView) {
    this.setState({ streetView })
  }

  hasAddress() {
    const { Address1, City, State, Zip } = this.state.Data.Site
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1.length > 3 && City.length > 2 && State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  getAddress = () => {
    const { Address1, City, State, Zip } = this.state.Data.Site
    if (window.google) {
      Geocode.fromAddress(`${Address1} ${City}, ${State} ${Zip}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          const Address =
            Address1.length > 3 && City.length > 2 && State.length > 1
              ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
              : false
          this.setState({
            ...this.state,
            Address,
            Latitude: lat,
            Longitude: lng,
          })
        },
        () => {
          const Address =
            Address1.length > 3 && City.length > 2 && State.length > 1
              ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
              : false
          this.setState({ ...this.state, Address })
        },
      )
    } else {
      const Address =
        Address1.length > 3 && City.length > 2 && State.length > 1
          ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
          : false
      this.setState({ ...this.state, Address })
    }
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  setBillingData = Data => {
    let { BillingData } = this.state
    if (
      !BillingData ||
      BillingData.Customer.Customer !== Data.Customer.Customer
    ) {
      this.setState({ ...this.state, BillingData: Data })
    } else {
      this.setState({ ...this.state, BillingData: null })
    }
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }

  handleDefaultsChange = event => {
    newWorkOrderScopeState[event.target.name] = event.target.value

    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  handleAddTag = tag => {
    let { Co } = this.state
    if (this.state.Data.Tags) {
      let { Tags } = this.state.Data
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
    } else {
      let Tags = []
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
    }
  }

  toggleReadyToBill = () => {
    const { ReadyToBillYN } = this.state
    this.setState({
      ...this.state,
      ReadyToBillYN: ReadyToBillYN !== 'Y' ? 'Y' : 'N',
    })
  }

  handleDeleteTag = tag => {
    let { Tags } = this.state.Data
    if (Tags == null) {
      Tags = []
    }
    for (var i = 0; i < Tags.length; i++) {
      if (Tags[i].ID == tag.ID) {
        if (isNaN(Tags[i].ID)) {
          Tags.splice(i, 1)
        } else {
          Tags[i].ActiveYN = 'N'
          Tags[i].Status = 'Update'
        }
      }
    }
    this.setState({ ...this.state, Data: { ...this.state.Data, Tags } })
  }

  toggleActions = e => {
    e.stopPropagation()
    const { actionPanel } = this.state
    this.setState({ ...this.state, actionPanel: !actionPanel })
  }

  hideActions = e => {
    e.stopPropagation()
    const { actionPanel } = this.state
    if (actionPanel) {
      this.setState({ ...this.state, actionPanel: false })
    }
  }

  toggleTabs = () => {
    const { showTabs } = this.state
    this.setState({ ...this.state, showTabs: !showTabs })
  }

  openDirections = () => {
    const { Data } = this.state
    if (Data.Site) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          `${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State} ${Data.Site.Zip}`,
        )}`,
      )
    }
  }

  launchInvoice = () => {
    const { divisions, departments } = this.props
    let {
      WorkOrder,
      Scope,
      Co,
      BusinessUnit,
      Division,
      Department,
      Data,
    } = this.state
    const dept = _.find(departments, { BusinessUnit, Division, Department })
    const div = _.find(divisions, {
      Co,
      BusinessUnit,
      Division: dept.DivisionOverride || Division,
    })
    let prefix = 'AR'
    if (div) {
      prefix = div.InvoicePrefix || 'AR'
    }
    if (Data.Billing && Data.Billing.length > 0) {
      this.setState({ ...this.state, BillingData: Data.Billing[0] }, () => {
        this.props.openNewInvoiceDialog({
          ...this.state.BillingData,
          Scopes: [this.state],
          WorkOrder,
          Scope,
          InvoiceNumber: `${prefix}${WorkOrder}-${this.state.Data.Invoices
            .length + 1}`,
        })
        this.setState({
          ...this.state,
          actionEl: null,
          actionAdd: null,
          actionManage: null,
        })
      })
    }
  }

  fileSelectedHandler = event => {
    let { Data } = this.state
    impress(event.target.files[0], 1024, 50, false, img => {
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      axios
        .post(
          `${
            window['apiLocation']
          }/api/Attachment/PostWorkOrderScopeImage?Co=${encodeURIComponent(
            this.state.Co,
          )}&WorkOrder=${encodeURIComponent(
            this.state.WorkOrder,
          )}&Scope=${encodeURIComponent(
            this.state.Scope,
          )}&User=${encodeURIComponent(this.props.user)}`,
          fd,
        )
        .then(res => {
          if (res.data.Attachment) {
            this.setState({
              ...this.state,
              Data: {
                ...Data,
                Attachments: [...Data.Attachments, res.data.Attachment],
              },
            })
          }
        })
    })
  }

  setAction = (actionEl, actionAdd, actionManage) => {
    this.setState({ ...this.state, actionEl, actionAdd, actionManage })
  }

  handleCall = phone => {
    const { toggleDialer, initCall, dialer } = this.props
    if (
      (phone && phone.length === 7) ||
      phone.length === 10 ||
      phone.length === 12
    )
      toggleDialer(true)
    initCall(dialer, {
      destinationNumber: phone,
      audio: true,
      video: false,
    })
  }

  newAgreement = () => {
    const { Co, WorkOrder, Scope, Data } = this.state
    const { Site, Customer } = Data
    const { BusinessUnit, Division, LeadTechnician } = this.state
    const StartDate = new Date()
    const data = {
      Co,
      BusinessUnit,
      Division,
      Customer: Site.Customer,
      Site: Site.Site,
      SoldBy: LeadTechnician,
      StartDate,
      WorkOrder,
      Scope,
      Data: {
        AgreementType: null,
        Site,
        Customer,
        WorkOrder: this.state,
        Tasks: [],
        Schedule: [],
        Variations: Site.Data.Agreements,
        Attachments: [],
      },
    }
    this.props.openNewAgreementDialog(data)
  }

  toggleScope = () => {
    let { expanded } = this.state
    this.setState({ ...this.state, expanded: !expanded })
  }

  handleArrivalWindow = option => {
    window['warn']('handleExactTime option', option)
    if (option.ArrivalWindow === 'ExactTime') {
      this.setState({
        ...this.state,
        ExactTime: '08:00:00',
        ArrivalWindow: null,
      })
    } else {
      this.setState({ ...this.state, ArrivalWindow: option.ArrivalWindow })
    }
  }

  handleExactTime = time => {
    this.setState({
      ExactTime: time ? time.format('HH:mm:00') : null,
    })
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { workOrderScopeDialog, securables } = this.props
    const { value, PriceMethod, showTabs, menuEl } = this.state

    const accessLevel = _.find(securables, { Securable: 'work-orders' })

    return (
      <>
        <IconButton
          style={{
            position: 'absolute',
            right: 56,
            top: matches.medium ? 10 : 4,
            color: 'white',
          }}
          className='dialog-header-icon'
          onClick={e => this.setState({ menuEl: e.target })}
        >
          <Icon>more_vert</Icon>
        </IconButton>

        <Menu
          id='dialog-menu'
          classes={
            {
              /*paper: "min-w-256"*/
            }
          }
          anchorEl={menuEl}
          open={Boolean(menuEl)}
          onClose={() => this.setState({ menuEl: null })}
        >
          <MenuItem
            onClick={() => {
              this.setState({ menuEl: null, viewChanges: true })
            }}
          >
            <Icon className='mr-8'>history</Icon>View Changes
          </MenuItem>
        </Menu>
        <ChangeDialog
          type='SCOPE'
          rec={this.state.ID}
          restore={data => this.setState({ ...data, viewChanges: false })}
          accessLevel={accessLevel}
          data={this.state}
          open={this.state.viewChanges}
          onClose={() => this.setState({ viewChanges: false })}
        />
        <IconButton
          style={{
            position: 'absolute',
            right: 96,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches.medium ? 10 : 4,
            color: 'white',
          }}
          onClick={e => {
            closeFxn(e)
          }}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
        {showTabs && (
          <Tabs
            value={value}
            onChange={this.handleTabChange}
            variant='scrollable'
            scrollButtons='on'
          >
            <Tab disableRipple icon={<Icon>build</Icon>} label='Info' />
            {PriceMethod === 'F' && (
              <Tab
                disableRipple
                icon={
                  <Badge
                    badgeContent={
                      this.state.Data && this.state.Data.Splits
                        ? this.state.Data.Splits.length
                        : 0
                    }
                    color='error'
                  >
                    <Icon>format_list_numbered</Icon>
                  </Badge>
                }
                label='Split Revenue'
              />
            )}
            <Tab
              disableRipple
              disabled={['new', 'site'].indexOf(workOrderScopeDialog.type) > -1}
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Trips
                      ? this.state.Data.Trips.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>local_shipping</Icon>
                </Badge>
              }
              label='Trips'
            />
            <Tab
              disableRipple
              disabled={['new', 'site'].indexOf(workOrderScopeDialog.type) > -1}
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Usage
                      ? this.state.Data.Usage.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>format_list_bulleted</Icon>
                </Badge>
              }
              label='Usage'
            />
            <Tab
              disableRipple
              disabled={['new', 'site'].indexOf(workOrderScopeDialog.type) > -1}
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.POs
                      ? this.state.Data.POs.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>list_alt</Icon>
                </Badge>
              }
              label='Purchasing'
            />
            <Tab
              disableRipple
              disabled={['new', 'site'].indexOf(workOrderScopeDialog.type) > -1}
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Invoices
                      ? this.state.Data.Invoices.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>receipt</Icon>
                </Badge>
              }
              label='Billing'
            />
            <Tab
              disableRipple
              disabled={['new', 'site'].indexOf(workOrderScopeDialog.type) > -1}
              icon={
                <Badge
                  badgeContent={
                    this.state.Data && this.state.Data.Attachments
                      ? this.state.Data.Attachments.length
                      : 0
                  }
                  color='error'
                >
                  <Icon>attach_file</Icon>
                </Badge>
              }
              label='Attachments'
            />
            <Tab
              disableRipple
              disabled={['new', 'site'].indexOf(workOrderScopeDialog.type) > -1}
              icon={<Icon>dashboard</Icon>}
              label='Dashboard'
            />
          </Tabs>
        )}
      </>
    )
  }

  ToolbarContents = () => {
    const { workOrderScopeDialog } = this.props
    const { showTabs } = this.state

    return (
      <>
        {['new', 'site'].indexOf(workOrderScopeDialog.type) < 0 && (
          <IconButton
            style={{
              color: 'white',
              marginLeft: -16,
            }}
            onClick={() => {
              this.setState({ ...this.state, value: 0 })
            }}
          >
            <Icon>assignment_ind</Icon>
          </IconButton>
        )}
        <Typography variant='subtitle1' color='inherit'>
          {['new', 'site'].indexOf(workOrderScopeDialog.type) > -1
            ? `Work Order #${this.state.WorkOrder} New Scope`
            : `Work Order #${this.state.WorkOrder} Scope #${this.state.Scope}`}
        </Typography>
        <IconButton
          style={{
            color: 'white',
          }}
          onClick={() => {
            this.toggleTabs()
          }}
        >
          {showTabs ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
        </IconButton>
      </>
    )
  }

  render() {
    const {
      classes,
      securables,
      user,
      users,
      openNewRevenueSplitDialog,
      workOrderScopeDialog,
      addWorkOrderScope,
      updateWorkOrderScope,
      openNewTripDialog,
      businessUnits,
      divisions,
      departments,
      services,
      serviceTypes,
      rateTemplates,
      taxCodes,
      arrivalWindows,
      formOfPayments,
      technicians,
      googleMapsApiKey,
      openNewWorkOrderUsageDialog,
      openNewInvoiceDialog,
    } = this.props
    const {
      value,
      Co,
      BusinessUnit,
      Division,
      WorkOrder,
      Scope,
      PriceMethod,
      BillingData,
      Address,
      Latitude,
      Longitude,
      Data,
      showTabs,
      menuEl,
    } = this.state
    let sortAgreements = []
    const accessLevel = _.find(securables, { Securable: 'work-orders' })
    const tripAccessLevel = _.find(securables, {
      Securable: 'work-order-trips',
    })
    const billingAccessLevel = _.find(securables, {
      Securable: 'work-order-billing',
    })
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    if (Data.Site && Data.Site.Data && Data.Site.Data.Agreements) {
      const { Agreements } = Data.Site.Data
      const active = _.filter(Agreements, { ActiveYN: 'Y' })
      const inactive = _.filter(Agreements, o => {
        return o.ActiveYN === 'N' && !_.find(active, { Agreement: o.Agreement })
      })
      sortAgreements = [...active, ...inactive]
    }
    const avatar = 'assets/images/avatars/profile.jpg'
    const div = _.find(divisions, { Co, BusinessUnit, Division })
    let invPrefix = 'AR'
    if (div) {
      invPrefix = div.InvoicePrefix || 'AR'
    }

    const exactTime = this.state.ExactTime
      ? moment(`1/1/1970 ${this.state.ExactTime}`).toDate()
      : null

    const dialogProps = {
      ...workOrderScopeDialog.props,
      className: classes.root,
      id: `work-order-scope-${this.state.WorkOrder}-${this.state.Scope}`,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    return (
      <Media
        queries={{
          small: '(max-width: 600px)',
          medium: '(min-width: 1200px)' /**/,
        }}
      >
        {matches => (
          <DraggableDialog
            SlideUp={SlideUp}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this,
            )}
            //header={`Usage Line #${this.state.Line}`}
            header={`Work Order #${this.state.WorkOrder} Scope #${this.state.Scope}`}
            type='Work Order Scope'
            selector={document.querySelector(
              `#work-order-scope-${this.state.WorkOrder}-${this.state.Scope}`,
            )}
            picRef={this.ref.current}
            //picRef={draggableSelectImg}
            icon='build'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadWorkOrderScopeDialog}
            description={this.state.Description}
            storeDialog={workOrderScopeDialog}
            TypeState={this.state.WorkOrder}
            screenOverlay={this.state.screenOverlay}
            //posSet={this.state.posSet}
            //initialWidthSet={initialWidthSet}
            //minHeight={this.state.minHeight}
            //location={this.state.location}
            //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
            //dialogHeight={this.state.dialogHeight}
          >
            {value === 0 && (
              <TabContainer
                refProp={this.ref}
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                <React.Fragment>
                  {this.state.Status === 4 && (
                    <div
                      className='p-8 rounded mb-24 py-24 w-full cursor-pointer'
                      style={{
                        backgroundColor: '#f9f9f9',
                        border: '1px dashed red',
                      }}
                    >
                      <Typography className='text-16 font-bold text-red w-full text-center'>
                        <Icon color='error' className='mr-6 align-middle mb-2'>
                          cancel
                        </Icon>
                        CANCELLATION
                      </Typography>
                      <div className='w-full'>
                        <div className='italic mt-4 text-center'>
                          {this.state.CancellationReason
                            ? `"${this.state.CancellationReason}"`
                            : 'No Reason Given'}
                        </div>
                      </div>
                    </div>
                  )}
                  {Address ? (
                    <div className='sm:flex'>
                      <div
                        className='min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'
                        style={{ position: 'relative' }}
                      >
                        <Tooltip title='Map View' placement='top'>
                          <Icon
                            color='action'
                            onClick={() => {
                              this.toggleStreetview(false)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            map
                          </Icon>
                        </Tooltip>
                        <br />
                        <Tooltip title='Street View' placement='top'>
                          <Icon
                            color='action'
                            className='pin-r pin-t mr-8 sm:pin-none absolute'
                            onClick={() => {
                              this.toggleStreetview(true)
                            }}
                            style={{ cursor: 'pointer', bottom: 40 }}
                          >
                            directions_car
                          </Icon>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: '250px',
                          backgroundColor: '#eeeeee',
                          marginBottom: 24,
                          borderRadius: '5px',
                          overflow: 'hidden',
                        }}
                      >
                        <GoogleMap
                          options={this.getMapOptions}
                          gestureHandling='cooperative'
                          bootstrapURLKeys={{
                            key: googleMapsApiKey,
                          }}
                          defaultZoom={12}
                          defaultCenter={[Number(Latitude), Number(Longitude)]}
                          center={[Number(Latitude), Number(Longitude)]}
                        >
                          <Marker
                            key={1}
                            style={{
                              margin: 0,
                              padding: 0,
                              width: 32,
                              height: 42,
                              marginTop: -42,
                              marginLeft: -16,
                              cursor: 'pointer',
                            }}
                            icon='assets/images/logos/SPMarker_Gradient.png'
                            text={this.state.Description}
                            lat={Number(Latitude)}
                            lng={Number(Longitude)}
                          />
                        </GoogleMap>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>business</Icon>
                    </div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Business Unit'
                      options={businessUnits}
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.BusinessUnit}>
                            {value.Description}
                          </MenuItem>
                        )
                      }}
                      portal={true}
                      value={this.state.BusinessUnit}
                      onSelect={option => {
                        if (option.BusinessUnit !== this.state.BusinessUnit) {
                          this.setState({
                            ...this.state,
                            BusinessUnit: option.BusinessUnit,
                            Division: null,
                            RateTemplate: null,
                            ArrivalWindow: null,
                            FormOfPayment: null,
                          })
                        }
                      }}
                      required
                    />

                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Division'
                      options={
                        divisions
                          ? _.filter(divisions, {
                              BusinessUnit: this.state.BusinessUnit,
                            })
                          : []
                      }
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Division}>
                            {value.Description}
                          </MenuItem>
                        )
                      }}
                      portal={true}
                      value={this.state.Division}
                      onSelect={option => {
                        if (option.Division !== this.state.Division) {
                          this.setState({
                            ...this.state,
                            Division: option.Division,
                            RateTemplate: null,
                            ArrivalWindow: null,
                            FormOfPayment: null,
                          })
                        }
                      }}
                      required
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Department'
                      options={
                        departments
                          ? _.filter(departments, {
                              BusinessUnit: this.state.BusinessUnit,
                              Division: this.state.Division,
                            })
                          : []
                      }
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Department}>
                            {value.Description}
                          </MenuItem>
                        )
                      }}
                      portal={true}
                      value={this.state.Department}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          Department: option.Department,
                        })
                      }
                      required
                    />
                  </div>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>settings</Icon>
                    </div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Service Type'
                      options={serviceTypes}
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.ServiceType}>
                            {`${value.Description}`}
                          </MenuItem>
                        )
                      }}
                      noflip={true}
                      value={this.state.ServiceType}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          ServiceType: option.ServiceType,
                        })
                      }
                      required
                    />

                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Service'
                      options={services}
                      menuItemComponent={value => {
                        return (
                          value.ActiveYN === 'Y' && (
                            <MenuItem value={value.Service}>
                              {`${value.Service} - ${value.Description}`}
                            </MenuItem>
                          )
                        )
                      }}
                      noflip={true}
                      value={this.state.Service}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          Service: option.Service,
                        })
                      }
                      required
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <DatePicker
                      variant='inline'
                      inputVariant='outlined'
                      className={classes.formControl}
                      label='Due By'
                      id='dueBy'
                      format='MM/DD/YYYY'
                      value={this.state.DueBy || null}
                      onChange={this.handleDueByChange}
                      showTodayButton
                      autoOk
                      clearable={true}
                      required
                      fullWidth
                    />
                  </div>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>chat_bubble_outline</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Description'
                      id='description'
                      name='Description'
                      value={this.state.Description || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      required
                      rows={5}
                      fullWidth
                    />
                  </div>

                  <div className='sm:flex mb-12' style={{ marginTop: -12 }}>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>build</Icon>
                    </div>
                    <ServiceUnitsList
                      onAdd={unit => {
                        const { ServiceUnits } = _.cloneDeepWith(
                          this.state.Data,
                        )
                        const index = _.findIndex(ServiceUnits, {
                          UnitID: unit.UnitID,
                        })
                        if (index < 0) {
                          ServiceUnits.push(unit)
                          this.setState({
                            ...this.state,
                            Data: { ...this.state.Data, ServiceUnits },
                          })
                        }
                      }}
                      onRemove={unit => {
                        const { ServiceUnits } = _.cloneDeepWith(
                          this.state.Data,
                        )
                        const index = _.findIndex(ServiceUnits, {
                          UnitID: unit.UnitID,
                        })
                        if (index > -1) {
                          ServiceUnits.splice(index, 1)
                          this.setState({
                            ...this.state,
                            Data: { ...this.state.Data, ServiceUnits },
                          })
                        }
                      }}
                      site={this.state.Data.Site}
                      scope={this.state}
                      editable={true}
                      units={this.state.Data.ServiceUnits || []}
                    />
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>settings</Icon>
                    </div>

                    <TextField
                      className={classes.formControl}
                      label='Priority'
                      id='priority'
                      name='Priority'
                      value={this.state.Priority}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                      select
                    >
                      <MenuItem value={3}>Low</MenuItem>
                      <MenuItem value={2}>Medium</MenuItem>
                      <MenuItem value={1}>High</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    {this.state.ExactTime ? (
                      <TimePicker
                        className={classes.formControl}
                        id='exactTime'
                        label='Arrival Time'
                        format='h:mm A'
                        value={exactTime || ''}
                        onChange={this.handleExactTime}
                        required
                        clearable={true}
                        inputVariant='outlined'
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        className={classes.formControl}
                        title='Arrival Window'
                        options={
                          arrivalWindows
                            ? _.filter(arrivalWindows, {
                                BusinessUnit: this.state.BusinessUnit,
                              }).concat({
                                ArrivalWindow: 'ExactTime',
                                Description: 'Exact Time',
                                BusinessUnit: this.state.BusinessUnit,
                              })
                            : []
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.ArrivalWindow}>
                              {`${value.Description}`}
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        value={this.state.ArrivalWindow || ''}
                        onSelect={option => this.handleArrivalWindow(option)}
                        required
                      />
                    )}
                    <div className='hidden sm:block min-w-12 pt-20'></div>

                    <TextField
                      className={classes.formControl}
                      label='Status'
                      id='status'
                      name='Status'
                      value={this.state.Status}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                      select
                    >
                      <MenuItem value={0}>Open</MenuItem>
                      <MenuItem value={1}>In Progress</MenuItem>
                      <MenuItem value={2}>On Hold</MenuItem>
                      <MenuItem value={3}>Completed</MenuItem>
                      <MenuItem value={4}>Canceled</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Lead Technician'
                      options={[
                        { Technician: null, FirstName: 'None', LastName: '' },
                        ...technicians,
                      ]}
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Technician}>
                            <div className='flex'>
                              {value.Technician && (
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  classes={{ root: classes.avatarRoot }}
                                  className={classes.avatar}
                                  alt={value.Technician}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                      : avatar
                                  }
                                />
                              )}
                              {`${value.FirstName} ${value.LastName}`}
                            </div>
                          </MenuItem>
                        )
                      }}
                      value={this.state.LeadTechnician || ''}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          LeadTechnician: option.Technician,
                        })
                      }
                      required
                    />
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>payment</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Price Method'
                      id='priceMethod'
                      name='PriceMethod'
                      value={this.state.PriceMethod}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      select
                      fullWidth
                      disabled={this.state.NonBillableYN === 'Y'}
                    >
                      <MenuItem value='T'>Rate Template</MenuItem>
                      <MenuItem value='F'>Flat Price</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    {this.state.PriceMethod == 'F' ? (
                      <TextField
                        className={classes.formControl}
                        label='Price'
                        id='price'
                        name='Price'
                        value={this.state.Price}
                        onChange={this.handleChange}
                        variant='outlined'
                        type='number'
                        required
                        fullWidth
                        disabled={
                          this.state.PriceMethod == 'T' ||
                          this.state.NonBillableYN == 'Y'
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <Autocomplete
                        className={classes.formControl}
                        title='Rate Template'
                        options={
                          rateTemplates
                            ? _.filter(rateTemplates, {
                                BusinessUnit: this.state.BusinessUnit,
                                Division: this.state.Division,
                              })
                            : []
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.RateTemplate}>
                              {`${value.Description}`}
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        value={this.state.RateTemplate}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            RateTemplate: option.RateTemplate,
                          })
                        }
                        required
                      />
                    )}
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Not To Exceed'
                      id='notToExceed'
                      name='NotToExceed'
                      value={this.state.NotToExceed}
                      onChange={this.handleChange}
                      variant='outlined'
                      type='number'
                      fullWidth
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Non-Billable?'
                      id='nonTaxableYN'
                      name='NonBillableYN'
                      value={this.state.NonBillableYN}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                      select
                      disabled={this.state.PriceMethod == 'T' ? false : true}
                    >
                      <MenuItem value='N'>No</MenuItem>
                      <MenuItem value='Y'>Yes</MenuItem>
                    </TextField>
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>subtitles</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Customer PO'
                      id='customerPO'
                      name='CustomerPO'
                      value={this.state.CustomerPO}
                      onChange={this.handleChange}
                      required={
                        this.state.Data &&
                        this.state.Data.Customer &&
                        this.state.Data.Customer.PORequiredYN === 'Y'
                          ? true
                          : false
                      }
                      error={
                        this.state.Data.Customer.PORequiredYN === 'Y' &&
                        !this.state.CustomerPO ? true : false
                      }
                      variant='outlined'
                      fullWidth
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Quote ID'
                      id='quoteID'
                      name='QuoteID'
                      value={this.state.QuoteID}
                      onChange={this.handleChange}
                      variant='outlined'
                      fullWidth
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      title='Tax Code'
                      options={taxCodes}
                      menuItemComponent={value => {
                        return (
                          value.ActiveYN === 'Y' && (
                            <MenuItem value={value.TaxCode}>
                              {`${value.Description} - ${value.TaxCode}`}
                            </MenuItem>
                          )
                        )
                      }}
                      portal={true}
                      value={this.state.TaxCode}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          TaxCode: option.TaxCode,
                        })
                      }
                      required
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Taxable?'
                      id='taxableYN'
                      name='TaxableYN'
                      value={this.state.TaxableYN}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                      select
                    >
                      <MenuItem value={'N'}>No</MenuItem>
                      <MenuItem value={'Y'}>Yes</MenuItem>
                    </TextField>
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>person</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Entered By'
                      id='enteredBy'
                      name='EnteredBy'
                      value={this.state.EnteredBy || user.UserName}
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      select
                      disabled={true}
                    >
                      {users &&
                        users.map((value, index) => (
                          <MenuItem key={index} value={value.UserName}>
                            <div className='flex'>
                              <Avatar
                                style={{ marginRight: 8 }}
                                classes={{ root: classes.avatarRoot }}
                                className={classes.userAvatar}
                                alt={value.Technician}
                                src={
                                  value.Data && value.Data.Avatar
                                    ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`
                                    : userAvatar
                                }
                              />
                              {`${value.FirstName} ${value.LastName}`}
                            </div>
                          </MenuItem>
                        ))}
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <Autocomplete
                      className={classes.formControl}
                      IsWarrantyYN
                      title='Originator'
                      options={
                        technicians
                          ? [
                              {
                                Technician: null,
                                FirstName: 'None',
                                LastName: '',
                              },
                              ...technicians,
                            ]
                          : [
                              {
                                Technician: null,
                                FirstName: 'None',
                                LastName: '',
                              },
                            ]
                      }
                      menuItemComponent={value => {
                        return (
                          <MenuItem value={value.Technician}>
                            <div className='flex'>
                              <Avatar
                                style={{ marginRight: 8 }}
                                classes={{ root: classes.avatarRoot }}
                                className={classes.avatar}
                                alt={value.Technician}
                                src={
                                  value.Data && value.Data.Avatar
                                    ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                    : avatar
                                }
                              />
                              {`${value.FirstName} ${value.LastName}`}
                            </div>
                          </MenuItem>
                        )
                      }}
                      value={this.state.Originator}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          Originator: option.Technician,
                        })
                      }
                      required
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Warranty Work'
                      id='isWarrantyYN'
                      name='IsWarrantyYN'
                      value={this.state.IsWarrantyYN || 'N'}
                      onChange={this.handleChange}
                      variant='outlined'
                      fullWidth
                      select
                    >
                      <MenuItem value='N'>No</MenuItem>
                      <MenuItem value='Y'>Yes</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    {this.state.IsWarrantyYN !== 'Y' ? (
                      <Autocomplete
                        className={classes.formControl}
                        title='Form of Payment'
                        options={
                          formOfPayments
                            ? _.filter(formOfPayments, {
                                BusinessUnit: this.state.BusinessUnit,
                              })
                            : []
                        }
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.FormOfPayment}>
                              {`${value.Description}`}
                            </MenuItem>
                          )
                        }}
                        noflip={true}
                        value={this.state.FormOfPayment}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            FormOfPayment: option.FormOfPayment,
                          })
                        }
                        required
                      />
                    ) : (
                      <Autocomplete
                        className={classes.formControl}
                        title='Warranty Technician'
                        options={technicians}
                        menuItemComponent={value => {
                          return (
                            <MenuItem value={value.Technician}>
                              <div className='flex'>
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  classes={{ root: classes.avatarRoot }}
                                  className={classes.avatar}
                                  alt={value.Technician}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                      : avatar
                                  }
                                />
                                {`${value.FirstName} ${value.LastName}`}
                              </div>
                            </MenuItem>
                          )
                        }}
                        value={this.state.WarrantyTechnician}
                        onSelect={option =>
                          this.setState({
                            ...this.state,
                            WarrantyTechnician: option.Technician,
                          })
                        }
                      />
                    )}
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>person_pin</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Technician Summary'
                      id='summary'
                      name='Summary'
                      value={this.state.Summary || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>note</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Notes'
                      id='notes'
                      name='Notes'
                      value={this.state.Notes || ''}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </div>

                  <div className='flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>assistant</Icon>
                    </div>
                    <ChipInput
                      className={classes.formControl}
                      variant='outlined'
                      label='Tags'
                      id='tags'
                      name='Tags'
                      blurBehavior='add'
                      value={this.state.Data.Tags}
                      onAdd={this.handleAddTag}
                      onDelete={this.handleDeleteTag}
                      chipRenderer={({ value, handleDelete }, key) =>
                        value.ActiveYN == 'Y' && (
                          <Tooltip
                            key={key}
                            title={`Added By ${value.AddedBy} on ${new Date(
                              value.AddedDate,
                            ).toLocaleDateString('en-US')}`}
                            placement='top'
                          >
                            <Chip
                              className='mb-8 mr-4'
                              color='primary'
                              icon={<Icon>assignment</Icon>}
                              onDelete={handleDelete}
                              label={value.Title}
                            />
                          </Tooltip>
                        )
                      }
                      fullWidth
                      fullWidthInput
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.ReadyToBillYN === 'Y'}
                        name='ReadyToBillYN'
                        onChange={this.toggleReadyToBill}
                        color='secondary'
                      />
                    }
                    label='Ready to Bill'
                  />
                </React.Fragment>
              </TabContainer>
            )}
            {PriceMethod === 'F' && value === 1 && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                {accessLevel && accessLevel.AccessLevel !== 'R' && (
                  <Fab
                    color='secondary'
                    aria-label='add'
                    className={classes.addButton}
                    onClick={() => {
                      openNewRevenueSplitDialog({
                        Co,
                        WorkOrder,
                        Scope,
                      })
                    }}
                  >
                    <Tooltip title='New Revenue Split' placement='left'>
                      <Icon>add</Icon>
                    </Tooltip>
                  </Fab>
                )}
                <RevenueSplitDialog />
                <RevenueSplitsList data={[...this.state.Data.Splits]} />
              </TabContainer>
            )}
            {value === (PriceMethod === 'F' ? 2 : 1) && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                {tripAccessLevel && tripAccessLevel.AccessLevel !== 'R' && (
                  <Fab
                    color='secondary'
                    aria-label='add'
                    className={classes.addButton}
                    onClick={() => {
                      openNewTripDialog({
                        Co: Co,
                        WorkOrder: WorkOrder,
                        Trip: null,
                        Technician: null,
                        ScheduledDate: null,
                        ScheduledTime: null,
                        Status: 0,
                        Duration: 0.5,
                        CreatedBy: user.UserName,
                        CreatedDate: new Date(),
                        Data: {
                          ErrMsg: null,
                          Tags: [],
                          Scopes: [{ ...this.state }],
                          Assignments: [
                            { Co, WorkOrder, Scope: this.state.Scope },
                          ],
                        },
                      })
                    }}
                  >
                    <Tooltip title='New Trip' placement='left'>
                      <Icon>add</Icon>
                    </Tooltip>
                  </Fab>
                )}
                <TripsList selectedTripData={[...this.state.Data.Trips]} />
              </TabContainer>
            )}
            {value === (PriceMethod === 'F' ? 3 : 2) && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                {billingAccessLevel && billingAccessLevel.AccessLevel !== 'R' && (
                  <Fab
                    color='secondary'
                    aria-label='add'
                    className={classes.addButton}
                    onClick={() => {
                      openNewWorkOrderUsageDialog({
                        Co,
                        WorkOrder,
                        Scope,
                        Data: {
                          WorkOrder: this.state.Data.WorkOrder,
                          Scope: this.state,
                        },
                      })
                    }}
                  >
                    <Tooltip title='New Usage Line' placement='left'>
                      <Icon>add</Icon>
                    </Tooltip>
                  </Fab>
                )}
                <WorkOrderUsagesList
                  workOrder={{ ...this.state.Data.workOrder }}
                  scope={{ ...this.state }}
                  selectedWorkOrderUsageData={[...this.state.Data.Usage]}
                />
              </TabContainer>
            )}
            {value === (PriceMethod === 'F' ? 4 : 3) &&
              workOrderScopeDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                  }
                >
                  <PurchaseOrdersList
                    scope={{ ...this.state }}
                    selectedPurchaseOrderData={[...this.state.Data.POs]}
                  />
                </TabContainer>
              )}
            {value === (PriceMethod === 'F' ? 5 : 4) &&
              workOrderScopeDialog.type !== 'new' && (
                <TabContainer
                  maxHeight={
                    showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                  }
                >
                  <div>
                    <Typography
                      variant='h6'
                      style={{ alignItems: 'center' }}
                      className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                    >
                      <Badge
                        color='error'
                        className='mr-12'
                        badgeContent={this.state.Data.Billing.length}
                      >
                        <Icon color='primary' className='text-32'>
                          account_box
                        </Icon>
                      </Badge>
                      Billable Customers
                    </Typography>
                    <div className={classes.customerSelect}>
                      <div
                        className={classes.customerScroll}
                        style={{ width: this.state.Data.Billing.length * 380 }}
                      >
                        {this.state.Data.Billing.map((value, index) => (
                          <InvoiceCustomer
                            selected={_.isEqual(value, BillingData)}
                            onClick={() => {
                              this.setBillingData(value)
                            }}
                            key={index}
                            customerData={value.Customer}
                            invoiceData={value.Invoices}
                            scopeData={[this.state]}
                          />
                        ))}
                      </div>
                      <hr
                        style={{
                          clear: 'both',
                          border: 'none',
                          width: '100%',
                          height: 0,
                        }}
                      />
                    </div>
                    {billingAccessLevel &&
                      billingAccessLevel.AccessLevel !== 'R' &&
                      BillingData && (
                        <Fab
                          color='secondary'
                          aria-label='add'
                          className={classes.addButton}
                          onClick={() => {
                            window['warn'](BillingData)
                            openNewInvoiceDialog({
                              ...BillingData,
                              Scopes: [this.state],
                              WorkOrder,
                              Scope,
                              InvoiceNumber: `${invPrefix}${WorkOrder}-${this
                                .state.Data.Invoices.length + 1}`,
                            })
                          }}
                        >
                          <Tooltip title='New Invoice' placement='left'>
                            <Icon>add</Icon>
                          </Tooltip>
                        </Fab>
                      )}
                    <InvoicesList invoiceData={[...this.state.Data.Invoices]} />
                  </div>
                </TabContainer>
              )}
            {value === (PriceMethod === 'F' ? 6 : 5) && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                <FileList attachments={[...this.state.Data.Attachments]} />
              </TabContainer>
            )}
            {value === (PriceMethod === 'F' ? 7 : 6) && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              ></TabContainer>
            )}

            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between px-16'>
                {value === 0 &&
                  (['new', 'site'].indexOf(workOrderScopeDialog.type) > -1 ? (
                    <DebounceButton
                      buttonText={'Add'}
                      processingText={'Adding'}
                      isDisabled={!this.canBeSubmitted()}
                      variant={'contained'}
                      color={'primary'}
                      clickFxn={() => {
                        addWorkOrderScope({
                          ...this.state,
                          Data: {
                            ErrMsg: null,
                            Tags: this.state.Data.Tags,
                            ServiceUnits: this.state.Data.ServiceUnits,
                            Customer: this.state.Data.Customer,
                          },
                        })
                      }}
                      debounceInterval={1000}
                    />
                  ) : (
                    <React.Fragment>
                      <DebounceButton
                        buttonText={'Save'}
                        processingText={'Saving'}
                        isDisabled={!this.canBeSubmitted()}
                        variant={'contained'}
                        color={'primary'}
                        clickFxn={() => {
                          return Promise.all([
                            updateWorkOrderScope(this.state),
                          ]).then(() => {
                            this.closeComposeDialog()
                          })
                        }}
                        debounceInterval={1000}
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        className={classNames('text-12', classes.black)}
                        onClick={() => {
                          if (this.state.Status < 4) {
                            this.setState({ promptCancellation: true })
                          } else {
                            this.setState({ ...this.state, Status: 0 }, () =>
                              updateWorkOrderScope({ ...this.state }),
                            )
                          }
                        }}
                        disabled={!this.canBeSubmitted()}
                      >
                        <Icon
                          color={
                            this.state.Status !== 4 ? 'error' : 'secondary'
                          }
                          className='mr-4'
                        >
                          {this.state.Status !== 4 ? 'cancel' : 'check_circle'}
                        </Icon>
                        {this.state.Status < 4
                          ? 'Cancel Scope'
                          : 'Re-Open Scope'}
                      </Button>
                      {this.state.promptCancellation && (
                        <ConfirmationDialog
                          open={this.state.promptCancellation}
                          title={
                            <div>
                              <Icon
                                className='mr-6 align-middle mb-4'
                                color='error'
                              >
                                cancel
                              </Icon>
                              Cancel Scope
                            </div>
                          }
                          content={
                            <div className='w-full'>
                              Please enter a reason for canceling this scope:
                              <br />
                              <TextField
                                className={'mt-12'}
                                label='Reason for Cancellation'
                                id='cancellationReason'
                                name='CancellationReason'
                                value={this.state.CancellationReason}
                                onChange={this.handleChange}
                                variant='outlined'
                                multiline
                                rows={5}
                                fullWidth
                              />
                            </div>
                          }
                          disableConfirm={
                            !this.state.CancellationReason ||
                            this.state.CancellationReason.length < 6
                          }
                          confirmText={'Cancel Scope'}
                          cancelText='Never Mind'
                          onCancel={() =>
                            this.setState({
                              ...this.state,
                              promptCancellation: false,
                            })
                          }
                          onConfirm={() => {
                            this.setState(
                              {
                                ...this.state,
                                Status: 4,
                                promptCancellation: false,
                              },
                              () => updateWorkOrderScope({ ...this.state }),
                            )
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </DialogActions>
            )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditWorkOrderScopeDialog: Actions.closeEditWorkOrderScopeDialog,
      closeNewWorkOrderScopeDialog: Actions.closeNewWorkOrderScopeDialog,
      addWorkOrderScope: Actions.addWorkOrderScope,
      updateWorkOrderScope: Actions.updateWorkOrderScope,
      removeWorkOrderScope: Actions.removeWorkOrderScope,
      openNewRevenueSplitDialog: Actions.openNewRevenueSplitDialog,
      openPreloadWorkOrderScopeDialog: Actions.openPreloadWorkOrderScopeDialog,
      openCustomer: openEditCustomerDialog,
      openSite: openEditCustomerSiteDialog,
      openNewWorkOrderUsageDialog,
      openNewTripDialog: TripActions.openNewTripDialog,
      openNewInvoiceDialog,
      openNewPurchaseOrderDialog,
      openNewAgreementDialog,
      openEditAgreementDialog,
      toggleDialer: DialerActions.toggleDialer,
      initCall: DialerActions.initCall,
      showMessage: showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({
  workOrderScopesApp,
  spReducers,
  dialer,
  customersApp,
}) {
  return {
    Co: spReducers.companies.Co,
    workOrderScopeDialog:
      workOrderScopesApp.workOrderScopes.workOrderScopeDialog,
    serviceTypes: spReducers.serviceTypes,
    services: spReducers.services,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    rateTemplates: spReducers.rateTemplates,
    arrivalWindows: spReducers.arrivalWindows,
    taxCodes: spReducers.taxCodes,
    formOfPayments: spReducers.formOfPayments,
    departments: spReducers.departments,
    technicians: spReducers.technicians,
    googleMapsApiKey: googleMapsApiKey,
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    dialer: dialer.sipPhone,
    securables: spReducers.userProfiles.User.Data.Securables,
    workOrders: spReducers.workOrders,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(WorkOrderScopesDialog),
)
