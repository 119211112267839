import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Button, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { openEditInvoiceDialog, buildNewAgreementInvoice, addAgreementInvoice, waiveAgreementInvoice } from '../invoices/store/actions';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    redButton: {
        background: theme.palette.error[500],
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },

});

class AgreementBillingsList extends Component {

    state = {
        selectedAgreementBillingsMenu: null,
        data: []
    };

    componentDidMount() {
        const { data } = this.props;
        this.setState({ data });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;
        window["warn"]("AgreementBillingsList data", data)
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ data });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    launchInvoice = (billing) => {
        const { customer, site, agreement } = this.props;
        this.props.buildNewAgreementInvoice({ Customer: customer, Site: site, Billing: billing, Agreement: agreement });
    }

    render() {
        const { classes, searchText, agreement, agreementAlreadyExists } = this.props;
        const { data } = this.state;

        const columns = [
            {
                Header: "Cycle",
                accessor: "Cycle",
                width: 64,
                className: "justify-center font-bold",
            },
            {
                Header: "Sequence",
                accessor: "Seq",
                width: 96,
                className: "justify-center font-bold",
            },
            {
                Header: "Bill Date",
                id: "billDate",
                accessor: row => this.formatDate(row.DueDate),
                className: "justify-center",
                width: 192,
            },
            {
                Header: "Amount",
                accessor: "Amount",
                width: 96,
                className: "justify-center",
                Cell: row => {
                    return this.formatDollars(row.original.Amount);
                }
            }];

        if (agreementAlreadyExists === true) {
            columns.push(
                {
                    Header: "Invoice",
                    id: "processed",
                    accessor: row => {
                        window["warn"]('Billing Schedule: ', row);
                        return row.Data && row.Data.Billing && row.Data.Billing.Data && row.Data.Billing.Data.Invoice ? row.Data.Billing.Data.Invoice.InvoiceNumber : '';
                    },
                    width: 160,
                    className: "justify-center",
                    Cell: row => {
                        if (row.value.length > 0) {
                            return row.value;
                        } else {
                            if (row.original.WaivedYN === 'Y') {
                                return 'Waived';
                            } else {
                                const { Agreement, Cycle, Seq } = row.original;

                                return <div className="flex w-full">
                                    <Button onClick={(e) => { e.stopPropagation(); this.props.addAgreementInvoice({ ...row.original, InvoiceDate: new Date() }); row.original.InvoiceNumber = `${Agreement}-${Cycle}-${Seq}`; this.setState({ data }); }} variant="contained" color="primary" className="text-12 h-24">Add</Button>
                                    <div className="min-w-6 min-h-6"></div>
                                    <Button onClick={(e) => { e.stopPropagation(); this.props.waiveAgreementInvoice({ ...row.original, WaivedYN: 'Y' }); row.original.WaivedYN = 'Y'; this.setState({ data }); }} variant="contained" color="primary" className={classNames(classes.redButton, "text-12 h-24")}>Waive</Button>
                                </div>
                            }
                        }
                    }
                });
        };


        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">receipt</Icon>Agreement Billings</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        const value = rowInfo.original;
                                        if (value && value.Data && value.Data.Billing && value.Data.Billing.Data && value.Data.Billing.Data.Invoice) {
                                            this.props.openEditInvoiceDialog(value.Data.Billing.Data.Invoice);
                                        }
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={columns}
                        defaultPageSize={10}
                        noDataText="No Agreement Billings found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        buildNewAgreementInvoice,
        openEditInvoiceDialog,
        addAgreementInvoice,
        waiveAgreementInvoice,
    }, dispatch);
}

function mapStateToProps({ agreementBillingsApp, spReducers }) {
    return {
        user: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementBillingsList)));
