import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedFleetVehicleCategoryIds: [],
    routeParams       : {},
    fleetVehicleCategoryDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const fleetVehicleCategoriesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_FLEET_VEHICLE_CATEGORIES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_FLEET_VEHICLE_CATEGORIES:
        {

            const fleetVehicleCategoryId = action.fleetVehicleCategoryId;

            let selectedFleetVehicleCategoryIds = [...state.selectedFleetVehicleCategoryIds];

            if ( selectedFleetVehicleCategoryIds.find(id => id === fleetVehicleCategoryId) !== undefined )
            {
                selectedFleetVehicleCategoryIds = selectedFleetVehicleCategoryIds.filter(id => id !== fleetVehicleCategoryId);
            }
            else
            {
                selectedFleetVehicleCategoryIds = [...selectedFleetVehicleCategoryIds, fleetVehicleCategoryId];
            }

            return {
                ...state,
                selectedFleetVehicleCategoryIds: selectedFleetVehicleCategoryIds
            };
        }
        case Actions.SELECT_ALL_FLEET_VEHICLE_CATEGORIES:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedFleetVehicleCategoryIds = arr.map(fleetVehicleCategories => fleetVehicleCategories.id);

            return {
                ...state,
                selectedFleetVehicleCategoryIds: selectedFleetVehicleCategoryIds
            };
        }
        case Actions.DESELECT_ALL_FLEET_VEHICLE_CATEGORIES:
        {
            return {
                ...state,
                selectedFleetVehicleCategoryIds: []
            };
        }
        case Actions.OPEN_NEW_FLEET_VEHICLE_CATEGORY_DIALOG:
        {
            return {
                ...state,
                fleetVehicleCategoryDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_FLEET_VEHICLE_CATEGORY_DIALOG:
        {
            return {
                ...state,
                fleetVehicleCategoryDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_FLEET_VEHICLE_CATEGORY_DIALOG:
        {
            return {
                ...state,
                fleetVehicleCategoryDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_FLEET_VEHICLE_CATEGORY_DIALOG:
        {
            return {
                ...state,
                fleetVehicleCategoryDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default fleetVehicleCategoriesReducer;
