import _ from '@lodash';
import { AppBar, Avatar, Badge, Card, CardContent, Dialog, DialogActions, DialogContent, Grow, Icon, IconButton, InputAdornment, ListItem, ListItemText, Menu, MenuItem, Slide, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { AssignmentOutlined, BuildOutlined, LocalShippingOutlined, PersonPinOutlined, SpeakerNotesOutlined, HistoryOutlined, HeadsetMicOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import classNames from 'classnames';
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog';
import MailCompose from 'main/content/apps/mail/MailCompose';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import ChecklistGenerator from '../checklists/ChecklistGenerator';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditEstimateDialog } from '../estimates/store/actions';
import { updateTrip } from '../trips/store/actions';
// import InvoicePayment from './InvoicePayment';
// import InvoicePaymentRecord from './InvoicePaymentRecord';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import ScopeDetails from './WorkOrderScope';
import { formatPhone } from 'main/functions';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {

    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    formControl: {
        marginBottom: 24,
        marginTop: 24,
    },
    customerSelect: {
        padding: 2,
        minHeight: 512,
        minWidth: '25%',
        border: '1px solid lightgrey',
        marginRight: 12,
        marginBottom: 24,
        borderRadius: 5,
        background: 'rgba(0, 0, 0, .05)',
    },
    customerCard: {
        borderRadius: 5,
        boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
    },
    customerAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    chip: {
        color: '#fff',
        backgroundColor: '#3f3f3f',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        marginBottom: 12,
        marginLeft: '-4px !important',
        height: 28,
        maxWidth: '66%',
        fontSize: '2.0rem'
    },
    chipLabel: {
        paddingLeft: 8,
        fontWeight: 'bold',
        color: '#fff',
        paddingBottom: 2,
    },
    chipAvatar: {
        marginLeft: '0 !important',
        color: '#3f3f3f',
        backgroundColor: '#3f3f3f',
        border: '3px solid #3f3f3f',
        width: '40px !important',
        height: '40px !important',
    },
    chipAvatarIcon: {
        color: '#3f3f3f',
        backgroundColor: '#fff',
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    postedMatch: {
        color: theme.palette.secondary.dark
    },
    postedNoMatch: {
        color: theme.palette.error[500]
    },
    notPosted: {
        color: '#5f5f5f'
    },
    postedBatch: {
        color: theme.palette.primary.main
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    qty: {
        backgroundColor: '#3f3f3f',
        height: 18,
        minWidth: 24,
        fontSize: 13,
        paddingLeft: 6,
        paddingRight: 6,
        marginRight: 6,
        marginTop: 3,
        float: 'left',
        borderRadius: 12,
        color: '#fff',
        textAlign: 'center'
    },
    invHeader: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        fontSize: 36,
        fontWeight: 'bold',
        color: '#333'
    },
    invSubHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right',
        marginTop: 4,
        color: '#333'
    },
    dividerPlain: {
        color: '#fff',
    },
    dividerHighlight: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
    woHistoryIcon: {
        color: 'white',
        backgroundColor: '#4dbce9',
        borderRadius: '50%',
        height: '32px',
        width: '32px',
        lineHeight: '32px !important',
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    scopeCards: {
        borderBottom: "1px solid lightgrey"
    },
    primaryIcon: {
        color: `${theme.palette.primary.main} !important`,
        fill: `${theme.palette.primary.main} !important`,
        '& path': {
            fill: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.main} !important`,
        }
    },
    whiteIcon: {
        color: '#fff !important',
        fill: '#fff !important',
        '& path': {
            color: '#fff !important',
            fill: '#fff !important',
        }
    }
});


const newWOstate = {
    Address: null,
    AttachmentID: null,
    Co: null,
    Contact: null,
    Customer: null,
    Data: {
        Attachments: [],
        Billing: [],
        Comments: [],
        Customer: {
            ActiveYN: null,
            AddedBy: null,
            AddedDate: null,
            Address1: null,
            Address2: null,
            BillAddress1: null,
            BillAddress2: null,
            BillCity: null,
            BillContact: null,
            BillCountry: null,
            BillEmail: null,
            BillPhone: null,
            BillState: null,
            BillZip: null,
            City: null,
            Co: null,
            Contact: null,
            Country: null,
            Customer: null,
            Data: {
                Contacts: [],
                PaymentMethods: [],
                Sites: [],
                Tags: [],
                Taxability: [],
            },
            Email: null,
            FirstName: null,
            ID: null,
            LastName: null,
            Latitude: null,
            Longitude: null,
            Name: null,
            Notes: null,
            PaymentsCustomer: null,
            Phone: null,
            QBOCompany: null,
            QBOCustomer: null,
            State: null,
            Status: null,
            UpdatedBy: null,
            UpdatedDate: null,
            Zip: null
        },
        EmailLogs: [],
        Estimates: [],
        Inventory: [],
        Invoices: [],
        Labor: [],
        POs: [],
        Recordings: [],
        Scopes: [],
        Site: {},
        Tags: [],
        Trips: [],
        Usage: [],
    },
    Description: null,
    EnteredBy: null,
    EnteredDate: null,
    ID: null,
    Requestor: null,
    Site: null,
    Status: null,
    Trip: null,
    UpdatedBy: null,
    UpdatedDate: null,
    WorkOrder: null,
    newEstimate: null,
    updatedEstimate: null,
    openDialog: null,
    checklistsPDF: null,
}


class WorkOrdersPDF extends Component {
    state = { ...newWOstate };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        //newInvoiceState.Co = this.props.Co;

        if (prevProps.open !== this.props.open) {
            this.setState({ openDialog: this.props.open })
            this.setState({ ...newWOstate, ...this.props.workOrderDialog.data })
        }

    }

    formatPhone(str) {
        return formatPhone(str).formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    closeComposeDialog = () => {
        this.props.setStateFxn({ openWoPDF: false })
    };

    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    emailInvoice = (closeAfter, confirm) => {
        const { User } = this.props;
        window["warn"](`user data: ${JSON.stringify(User)}`)
        const { WorkOrder } = this.state;
        const { Co } = this.state;
        const { businessUnits, divisions, templates, companies } = this.props;
        const { Scopes } = this.state.Data;
        // const { InvoiceDetails, Contacts } = this.state.Data.Data;
        // const { BusinessUnit, Division } = Scopes && Scopes.length > 0 ? Scopes[0] : InvoiceDetails && InvoiceDetails.length > 0 && InvoiceDetails[0].Data && InvoiceDetails[0].Data.Agreement ? InvoiceDetails[0].Data.Agreement : null;
        let margin = { top: '.16 in', bottom: '.16 in', left: '.16 in', right: '.16 in' };
        // if (BusinessUnit && Division && businessUnits && templates) {
        //     const div = _.find(divisions, { Co, BusinessUnit, Division });
        //     const { InvoiceMarginTop, InvoiceMarginBottom, InvoiceMarginLeft, InvoiceMarginRight } = div;
        //     margin = {
        //         top: `${((InvoiceMarginTop / 330) * 11).toFixed(2)} in`,
        //         bottom: `${((InvoiceMarginBottom / 330) * 11).toFixed(2)} in`,
        //         left: `${((InvoiceMarginLeft / 255) * 8.5).toFixed(2)} in`,
        //         right: `${((InvoiceMarginRight / 255) * 8.5).toFixed(2)} in`
        //     }
        // }
        let gridElement = document.getElementById('wo-pdf-print');
        drawDOM(gridElement, { keepTogether: ".prevent-split", paperSize: "letter", margin, scale: .65 }).then((group) => {
            return exportPDF(group);
        }).then((dataUri) => {
            let html = '';
            const { Co, ID, checklistsPDF } = this.state;
            const file = this.dataURLtoFile(dataUri, `Work Order #${WorkOrder}.pdf`);
            const { businessUnits, divisions, templates, companies } = this.props;
            const co = _.find(companies, { Co });
            const { Scopes } = this.state.Data;
            const { InvoiceDetails, Contacts } = this.state.Data;
            const billingContact = Contacts ? _.find(Contacts, { BillingYN: 'Y' }) : null;
            const { BusinessUnit, Division } = Scopes && Scopes.length > 0 ? Scopes[0] : InvoiceDetails && InvoiceDetails.length > 0 && InvoiceDetails[0].Data && InvoiceDetails[0].Data.Agreement ? InvoiceDetails[0].Data.Agreement : null;
            if (BusinessUnit && Division && businessUnits && templates) {
                const bu = _.find(businessUnits, { Co, BusinessUnit });
                const div = _.find(divisions, { Co, BusinessUnit, Division });
                if (bu && div) {
                    const template = div.Data.InvoiceEmailTemplate || bu.Data.InvoiceEmailTemplate;
                    html = template ? template.Markup : '';
                }
            }
            const Attachments = [file];
            if (checklistsPDF) {
                Attachments.push(checklistsPDF);
            }
            const data = {
                composeDialog: true,
                Title: 'Email Work Order',
                Icon: "email",
                //To: `${billingContact && billingContact.Data.Contact && billingContact.Data.Contact.Email ? billingContact.Data.Contact.Email : this.state.BillEmail};`,
                To: User.Email,
                Subject: `Work Order #${WorkOrder}`,
                Head: '',
                Message: `Please see the attached PDF for Work Order #${WorkOrder}`,
                Attachments,
                Type: "INVOICE",
                RecordID: ID,
                role: 'invoice'
            }
            this.setState({ ...this.state, invoiceEmail: data, confirm, closeAfter });
        });
    }

    openEmail = (closeAfter, confirm) => {
        const checklists = [];
        const { classes, companies, users, divisions, serviceUnitTypes, workOrderDialog } = this.props;
        const woData = this.state; //workOrderDialog.data;
        if (woData && woData.WorkOrder === this.state.WorkOrder) {
            const { Data } = woData;
            const { Scopes, Site } = Data;
            Scopes.map((scope) => {
                if (scope.Data && scope.Data.ServiceUnits) {
                    scope.Data.ServiceUnits.map((su) => {
                        if (su.Data && su.Data.ChecklistResults) {
                            su.Data.ChecklistResults.map((checklist) => {
                                checklist.info = scope && [scope].map((scope) => {
                                    const { Co, BusinessUnit, Division } = scope || {};
                                    const division = _.find(divisions, { Co, BusinessUnit, Division });
                                    const technician = _.find(users, { UserName: checklist.EnteredBy });
                                    const unit = _.find(Site.Data.Units, { UnitID: su.UnitID });
                                    return (
                                        <div className="w-full" key={scope.ID}>
                                            <div className="w-full flex justify-between my-24">
                                                {division && division.InvoiceHeaderImg &&
                                                    <img className="h-48 sm:h-96" src={division.InvoiceHeaderImg} />
                                                }
                                                <span className="sm:mt-12">
                                                    <label className={classNames(classes.invHeader, "sm:pr-8 text-20 sm:text-36")}>CHECKLIST</label>
                                                    <div className={classNames(classes.invSubHeader, "sm:pr-8 text-10 sm:text-16")}>Work Order #{this.state.WorkOrder}-{scope.Scope}</div>
                                                </span>
                                            </div>

                                            <div className="flex mb-24 justify-between p-16 pr-4">
                                                <div className="cursor-pointer">
                                                    <div className="w-full text-16 font-bold text-left">
                                                        {Data.Customer.Name && Data.Customer.Name.length > 0 ? Data.Customer.Name : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                                    </div>
                                                    <div className="w-full text-12 text-left">
                                                        {Data.Customer.Address1}
                                                    </div>
                                                    {Data.Customer.Address2 &&
                                                        <div className="w-full text-12 text-left">
                                                            {Data.Customer.Address2}
                                                        </div>
                                                    }
                                                    <div className="w-full text-12 text-left">
                                                        {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="flex cursor-pointer" onClick={this.openDatePicker}>
                                                        <img src="assets/icons/calendar_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                {checklist.ResultID ? moment(checklist.EnteredDate).format('dddd') : moment(Date()).format('dddd')}
                                                            </div>
                                                            <div className="text-10">
                                                                {checklist.ResultID ? moment(checklist.EnteredDate).format('MMM DD YYYY') : moment(Date()).format('MMM DD YYYY')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex mt-24">
                                                <div className="w-full">
                                                    <div className="w-full flex justify-between rounded-full mb-12" style={{ backgroundColor: '#3f3f3f' }}>
                                                        <div className={classNames("w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer", classes.dividerHighlight)}>
                                                            {checklist.Name}
                                                        </div>
                                                        <div className={classNames("w-2/3 py-8 px-24 text-12 font-bold text-right truncate", classes.dividerPlain)}>
                                                            {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-between">
                                                <div className="text-12 text-left pl-16">
                                                    {/* <div className="w-full">
                                                        <strong className="mr-8 font-bold">Task:</strong>
                                                        <label>{task.Data.Task.Name}</label>
                                                    </div> */}
                                                    {unit.UnitType && _.find(serviceUnitTypes, { UnitType: unit.UnitType }) &&
                                                        <div className="w-full">
                                                            <strong className="mr-8 font-bold">Unit Type:</strong>
                                                            <label>{_.find(serviceUnitTypes, { UnitType: unit.UnitType }).Name}</label>
                                                        </div>
                                                    }
                                                    <div className="w-full">
                                                        <strong className="mr-8 font-bold">Unit ID:</strong>
                                                        <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                                                    </div>
                                                    {unit.Manufacturer &&
                                                        <div className="w-full">
                                                            <strong className="mr-8 font-bold">Manufacturer:</strong>
                                                            <label>{unit.Manufacturer}</label>
                                                        </div>
                                                    }
                                                    {unit.Model &&
                                                        <div className="w-full">
                                                            <strong className="mr-8 font-bold">Model:</strong>
                                                            <label>{unit.Model}</label>
                                                        </div>
                                                    }
                                                    {unit.SerialNumber &&
                                                        <div className="w-full">
                                                            <strong className="mr-8 font-bold">Serial #:</strong>
                                                            <label>{unit.SerialNumber}</label>
                                                        </div>
                                                    }
                                                </div>
                                                {technician &&
                                                    <div className="flex mr-24 mt-4" style={{ marginBottom: -28 }}>
                                                        <img src="assets/icons/person_icon.png" className="w-24 h-24 mr-4" />
                                                        <div className="w-full">
                                                            <div className="text-12 font-bold mt-4">
                                                                {`${technician.FirstName} ${technician.LastName}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>


                                        </div>
                                    );
                                });
                                checklist.footerInfo = scope && [scope].map((scope) => {
                                    const { Co } = scope || {};
                                    const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co;
                                    if (co) {
                                        return (
                                            <div className="mx-24" key={scope.ID}>
                                                <div className="w-full sm:flex sm:justify-between">
                                                    <div className="mt-24">
                                                        <div className="w-full flex">
                                                            <img src="assets/icons/location_icon.png" className="w-32 h-32 mr-8" />
                                                            <div className="w-full">
                                                                <div className="text-14 font-bold">
                                                                    {co.Name}
                                                                </div>
                                                                <div className="text-10">
                                                                    {co.Address}
                                                                </div>
                                                                <div className="text-10">
                                                                    {`${co.City}, ${co.State} ${co.Zip}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-24">
                                                        <div className="w-full flex">
                                                            <img src="assets/icons/email_icon.png" className="w-32 h-32 mr-8" />
                                                            <div className="w-full">
                                                                <div className="text-14 font-bold">
                                                                    Email
                                                                </div>
                                                                <div className="text-10">
                                                                    {co.Email}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-24">
                                                        <div className="w-full flex">
                                                            <img src="assets/icons/phone_icon.png" className="w-32 h-32 mr-8" />
                                                            <div className="w-full">
                                                                <div className="text-14 font-bold">
                                                                    Phone
                                                                </div>
                                                                <div className="text-10">
                                                                    {this.formatPhone(co.Phone)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                });
                                checklists.push(checklist);
                            })
                        }
                    })
                }
                if (scope.Data && scope.Data.ChecklistResults) {
                    scope.Data.ChecklistResults.map((checklist) => {
                        const technician = _.find(users, { UserName: checklist.EnteredBy });
                        checklist.info = scope && [scope].map((scope) => {
                            const { Co, BusinessUnit, Division } = scope || {};
                            const division = _.find(divisions, { Co, BusinessUnit, Division });
                            return (
                                <div className="w-full" key={scope.ID}>
                                    <div className="w-full flex justify-between my-24">
                                        {division && division.InvoiceHeaderImg &&
                                            <img className="h-48 sm:h-96" src={division.InvoiceHeaderImg} />
                                        }
                                        <span className="sm:mt-12">
                                            <label className={classNames(classes.invHeader, "sm:pr-8 text-20 sm:text-36")}>CHECKLIST</label>
                                            <div className={classNames(classes.invSubHeader, "sm:pr-8 text-10 sm:text-16")}>Work Order #{this.state.WorkOrder}-{scope.Scope}</div>
                                        </span>
                                    </div>

                                    <div className="flex mb-24 justify-between p-16 pr-4">
                                        <div className="cursor-pointer">
                                            <div className="w-full text-16 font-bold text-left">
                                                {Data.Customer.Name && Data.Customer.Name.length > 0 ? Data.Customer.Name : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                            </div>
                                            <div className="w-full text-12 text-left">
                                                {Data.Customer.Address1}
                                            </div>
                                            {Data.Customer.Address2 &&
                                                <div className="w-full text-12 text-left">
                                                    {Data.Customer.Address2}
                                                </div>
                                            }
                                            <div className="w-full text-12 text-left">
                                                {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex cursor-pointer" onClick={this.openDatePicker}>
                                                <img src="assets/icons/calendar_icon.png" className="w-32 h-32 mr-8" />
                                                <div className="w-full">
                                                    <div className="text-14 font-bold">
                                                        {checklist.ResultID ? moment(checklist.EnteredDate).format('dddd') : moment(Date()).format('dddd')}
                                                    </div>
                                                    <div className="text-10">
                                                        {checklist.ResultID ? moment(checklist.EnteredDate).format('MMM DD YYYY') : moment(Date()).format('MMM DD YYYY')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex mt-24">
                                        <div className="w-full">
                                            <div className="w-full flex justify-between rounded-full mb-12" style={{ backgroundColor: '#3f3f3f' }}>
                                                <div className={classNames("w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer", classes.dividerHighlight)}>
                                                    {checklist.Name}
                                                </div>
                                                <div className={classNames("w-2/3 py-8 px-24 text-12 font-bold text-right truncate", classes.dividerPlain)}>
                                                    {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {technician &&
                                        <div className="w-full flex justify-end">
                                            <div className="flex mr-24 mt-4" style={{ marginBottom: -28 }}>
                                                <img src="assets/icons/person_icon.png" className="w-24 h-24 mr-4" />
                                                <div className="w-full">
                                                    <div className="text-12 font-bold mt-4">
                                                        {`${technician.FirstName} ${technician.LastName}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                </div>
                            );
                        });
                        checklist.footerInfo = scope && [scope].map((scope) => {
                            const { Co } = scope || {};
                            const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co;
                            if (co) {
                                return (
                                    <div className="mx-24" key={scope.ID}>
                                        <div className="w-full sm:flex sm:justify-between">
                                            <div className="mt-24">
                                                <div className="w-full flex">
                                                    <img src="assets/icons/location_icon.png" className="w-32 h-32 mr-8" />
                                                    <div className="w-full">
                                                        <div className="text-14 font-bold">
                                                            {co.Name}
                                                        </div>
                                                        <div className="text-10">
                                                            {co.Address}
                                                        </div>
                                                        <div className="text-10">
                                                            {`${co.City}, ${co.State} ${co.Zip}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-24">
                                                <div className="w-full flex">
                                                    <img src="assets/icons/email_icon.png" className="w-32 h-32 mr-8" />
                                                    <div className="w-full">
                                                        <div className="text-14 font-bold">
                                                            Email
                                                        </div>
                                                        <div className="text-10">
                                                            {co.Email}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-24">
                                                <div className="w-full flex">
                                                    <img src="assets/icons/phone_icon.png" className="w-32 h-32 mr-8" />
                                                    <div className="w-full">
                                                        <div className="text-14 font-bold">
                                                            Phone
                                                        </div>
                                                        <div className="text-10">
                                                            {this.formatPhone(co.Phone)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        });
                        checklists.push(checklist);
                    });
                }
                if (scope.Data && scope.Data.AgreementTasks) {
                    scope.Data.AgreementTasks.map((task) => {
                        if (task.Data.Task.Data.Units) {
                            task.Data.Task.Data.Units.map((unit) => {
                                unit.Data.ChecklistResults.map((checklist) => {
                                    checklist.info = scope && [scope].map((scope) => {
                                        const { Co, BusinessUnit, Division } = scope || {};
                                        const division = _.find(divisions, { Co, BusinessUnit, Division });
                                        const technician = _.find(users, { UserName: checklist.EnteredBy });
                                        return (
                                            <div className="w-full" key={scope.ID}>
                                                <div className="w-full flex justify-between my-24">
                                                    {division && division.InvoiceHeaderImg &&
                                                        <img className="h-48 sm:h-96" src={division.InvoiceHeaderImg} />
                                                    }
                                                    <span className="sm:mt-12">
                                                        <label className={classNames(classes.invHeader, "sm:pr-8 text-20 sm:text-36")}>CHECKLIST</label>
                                                        <div className={classNames(classes.invSubHeader, "sm:pr-8 text-10 sm:text-16")}>Work Order #{this.state.WorkOrder}-{scope.Scope}</div>
                                                    </span>
                                                </div>

                                                <div className="flex mb-24 justify-between p-16 pr-4">
                                                    <div className="cursor-pointer">
                                                        <div className="w-full text-16 font-bold text-left">
                                                            {Data.Customer.Name && Data.Customer.Name.length > 0 ? Data.Customer.Name : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                                        </div>
                                                        <div className="w-full text-12 text-left">
                                                            {Data.Customer.Address1}
                                                        </div>
                                                        {Data.Customer.Address2 &&
                                                            <div className="w-full text-12 text-left">
                                                                {Data.Customer.Address2}
                                                            </div>
                                                        }
                                                        <div className="w-full text-12 text-left">
                                                            {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="flex cursor-pointer" onClick={this.openDatePicker}>
                                                            <img src="assets/icons/calendar_icon.png" className="w-32 h-32 mr-8" />
                                                            <div className="w-full">
                                                                <div className="text-14 font-bold">
                                                                    {checklist.ResultID ? moment(checklist.EnteredDate).format('dddd') : moment(Date()).format('dddd')}
                                                                </div>
                                                                <div className="text-10">
                                                                    {checklist.ResultID ? moment(checklist.EnteredDate).format('MMM DD YYYY') : moment(Date()).format('MMM DD YYYY')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex mt-24">
                                                    <div className="w-full">
                                                        <div className="w-full flex justify-between rounded-full mb-12" style={{ backgroundColor: '#3f3f3f' }}>
                                                            <div className={classNames("w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer", classes.dividerHighlight)}>
                                                                {checklist.Name}
                                                            </div>
                                                            <div className={classNames("w-2/3 py-8 px-24 text-12 font-bold text-right truncate", classes.dividerPlain)}>
                                                                {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-full flex justify-between">
                                                    <div className="text-12 text-left pl-16">
                                                        <div className="w-full">
                                                            <strong className="mr-8 font-bold">Task:</strong>
                                                            <label>{task.Data.Task.Name}</label>
                                                        </div>
                                                        {unit.UnitType && _.find(serviceUnitTypes, { UnitType: unit.UnitType }) &&
                                                            <div className="w-full">
                                                                <strong className="mr-8 font-bold">Unit Type:</strong>
                                                                <label>{_.find(serviceUnitTypes, { UnitType: unit.UnitType }).Name}</label>
                                                            </div>
                                                        }
                                                        <div className="w-full">
                                                            <strong className="mr-8 font-bold">Unit ID:</strong>
                                                            <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                                                        </div>
                                                        {unit.Manufacturer &&
                                                            <div className="w-full">
                                                                <strong className="mr-8 font-bold">Manufacturer:</strong>
                                                                <label>{unit.Manufacturer}</label>
                                                            </div>
                                                        }
                                                        {unit.Model &&
                                                            <div className="w-full">
                                                                <strong className="mr-8 font-bold">Model:</strong>
                                                                <label>{unit.Model}</label>
                                                            </div>
                                                        }
                                                        {unit.SerialNumber &&
                                                            <div className="w-full">
                                                                <strong className="mr-8 font-bold">Serial #:</strong>
                                                                <label>{unit.SerialNumber}</label>
                                                            </div>
                                                        }
                                                    </div>
                                                    {technician &&
                                                        <div className="flex mr-24 mt-4" style={{ marginBottom: -28 }}>
                                                            <img src="assets/icons/person_icon.png" className="w-24 h-24 mr-4" />
                                                            <div className="w-full">
                                                                <div className="text-12 font-bold mt-4">
                                                                    {`${technician.FirstName} ${technician.LastName}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>


                                            </div>
                                        );
                                    });
                                    checklist.footerInfo = scope && [scope].map((scope) => {
                                        const { Co } = scope || {};
                                        const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co;
                                        if (co) {
                                            return (
                                                <div className="mx-24" key={scope.ID}>
                                                    <div className="w-full sm:flex sm:justify-between">
                                                        <div className="mt-24">
                                                            <div className="w-full flex">
                                                                <img src="assets/icons/location_icon.png" className="w-32 h-32 mr-8" />
                                                                <div className="w-full">
                                                                    <div className="text-14 font-bold">
                                                                        {co.Name}
                                                                    </div>
                                                                    <div className="text-10">
                                                                        {co.Address}
                                                                    </div>
                                                                    <div className="text-10">
                                                                        {`${co.City}, ${co.State} ${co.Zip}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-24">
                                                            <div className="w-full flex">
                                                                <img src="assets/icons/email_icon.png" className="w-32 h-32 mr-8" />
                                                                <div className="w-full">
                                                                    <div className="text-14 font-bold">
                                                                        Email
                                                                    </div>
                                                                    <div className="text-10">
                                                                        {co.Email}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-24">
                                                            <div className="w-full flex">
                                                                <img src="assets/icons/phone_icon.png" className="w-32 h-32 mr-8" />
                                                                <div className="w-full">
                                                                    <div className="text-14 font-bold">
                                                                        Phone
                                                                    </div>
                                                                    <div className="text-10">
                                                                        {this.formatPhone(co.Phone)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    });
                                    checklists.push(checklist);
                                })
                            })
                        }
                    });
                }
            })
        }
        if (checklists.length > 0) {
            var checklistResults = <ChecklistGenerator readOnly={true} checklists={checklists} onGenerate={(checklistsPDF) => this.setState({ checklistResults: '', checklistsPDF }, () => this.emailInvoice(closeAfter, confirm))} fileName="Inspection Reports" />;
            window["warn"]('ChecklistData: ', checklists, checklistResults);
            this.setState({ checklistResults });

        } else {
            this.setState({ checklistResults: '', checklistsPDF: null }, () => this.emailInvoice(closeAfter, confirm));
        }
    }


    printPDF = () => {
        const { Co, InvoiceNumber } = this.state;
        const { businessUnits, divisions, templates, companies } = this.props;
        const { Scopes } = this.state.Data;
        //const { InvoiceDetails, Contacts } = this.state.Data.Data;
        //const { BusinessUnit, Division } = Scopes && Scopes.length > 0 ? Scopes[0] : InvoiceDetails && InvoiceDetails.length > 0 && InvoiceDetails[0].Data && InvoiceDetails[0].Data.Agreement ? InvoiceDetails[0].Data.Agreement : null;
        let margin = { top: '.16 in', bottom: '.16 in', left: '.16 in', right: '.16 in' };
        // if (BusinessUnit && Division && businessUnits && templates) {
        //     const div = _.find(divisions, { Co, BusinessUnit, Division });
        //     const { InvoiceMarginTop, InvoiceMarginBottom, InvoiceMarginLeft, InvoiceMarginRight } = div;
        //     margin = {
        //         top: `${((InvoiceMarginTop / 330) * 11).toFixed(2)} in`,
        //         bottom: `${((InvoiceMarginBottom / 330) * 11).toFixed(2)} in`,
        //         left: `${((InvoiceMarginLeft / 255) * 8.5).toFixed(2)} in`,
        //         right: `${((InvoiceMarginRight / 255) * 8.5).toFixed(2)} in`
        //     }
        // }
        let gridElement = document.getElementById('wo-pdf-print');
        drawDOM(gridElement, { keepTogether: '.prevent-split', paperSize: "letter", margin, scale: .65 }).then((group) => {
            return exportPDF(group);
        }).then((dataUri) => {
            this.setState({ printPDF: dataUri, anchorEl: null });
        });
    }


    render() {
        const { theme, classes, invoiceDialog, addInvoice, securables, users, updateInvoice, taxCodes, removeInvoice, formOfPayments, materials, laborTypes, openEditWorkOrderUsageDialog, openEditWorkOrderDialog, openEditCustomerSiteDialog, openEditCustomerDialog, Co, companies, technicians, divisions, addAgreementInvoice } = this.props;
        const { trip } = invoiceDialog.props;
        //const inv = invoiceDialog.data;
        const inv = this.state
        const woData = this.state;
        window["warn"]('invoiceDialog data', invoiceDialog)
        window["warn"]('WO PDF data', inv)
        console.log(inv)
        const { Contacts } = inv.Data;
        var contact = _.find(Contacts, (o) => { return o.Data.Contact.Name === inv.BillContact });
        const { invoiceEmail, showWarrantyDetail, openDialog, editLine } = this.state;
        const assignments = inv.Data.Assignments;
        const billableDetails = inv.ID ? inv.Data.InvoiceDetails : _.filter(inv.Data.InvoiceDetails, (l) => (l.Data.Usage && l.Data.Usage.NonBillableYN !== 'Y') || (l.Data.Scope && l.Data.Scope.NonBillableYN !== 'Y'));
        const lines = _.flatten(_.map(billableDetails, _.property('Data.InvoiceLines')));
        let sub = _.sumBy(lines, (o) => { return Number(o.Amount) });
        let tax = _.sumBy(lines, (o) => { return Number(o.TaxAmount) });
        const co = _.find(companies, { Co }).Data.Co;
        let total = sub + tax;
        let payments = ((inv.Data && inv.Data.Payments && inv.Data.Payments.length > 0 && inv.Data.Payments[0].Data.Payment) ? _.sumBy(inv.Data.Payments, (o) => (o.Data && o.Data.Payment && o.Data.Payment.VoidDate) ? 0 : (o.Total || o.Amount)) : 0);
        let due = total - payments;
        window["warn"]('Invoice Data: ', this.state) //, inv, lines, payments, total, due);
        const User = !this.state.EnteredBy ? this.props.User : _.findLast(this.props.Users, (n) => { return n.UserName === this.state.EnteredBy });
        const accessLevel = _.find(securables, { Securable: "invoices" });
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const batched = _.sumBy((billableDetails || {}), (d) => { return _.sumBy((d.Data.InvoiceLines || {}), (o) => { return o.Data && o.Data.VPARBL ? o.Data.VPARBL.Amount : 0 }) });
        const posted = _.sumBy((billableDetails || {}), (d) => { return _.sumBy((d.Data.InvoiceLines || {}), (o) => { return o.Data && o.Data.VPARTL ? o.Data.VPARTL.Amount : 0 }) });
        if (trip) {
            const start = moment(trip.InProgressTime || trip.EnRouteTime);
            const end = moment(trip.CompletedTime);
            const sec = end.diff(start, 'seconds');
            trip.ActualDuration = ((sec / 60) / 60);
        }
        const paymentTypes = { visa: "Visa", mastercard: "MasterCard", master: "MasterCard", discover: "Discover", american_express: "American Express", amex: "American Express", am_ex: "American Express" };
        const scope = inv.Data && inv.Data.Scopes && inv.Data.Scopes.length > 0 ? inv.Data.Scopes[0] : null;
        const billCust = inv.Data.Customer ? inv.Data.Customer : (scope ? scope.Data.Customer || {} : {});
        const agreement = inv.Data && billableDetails && billableDetails.length > 0 && billableDetails[0].Data && billableDetails[0].Data.Agreement ? billableDetails[0].Data.Agreement : null;
        const division = scope ? _.find(divisions, { Co, BusinessUnit: scope.BusinessUnit, Division: scope.Division }) : agreement ? _.find(divisions, { Co, BusinessUnit: agreement.BusinessUnit, Division: agreement.Division }) : null;
        let pmts = [...(inv.Data && inv.Data.Payments ? inv.Data.Payments : [])];

        const techAvatar = 'assets/images/avatars/profile.jpg';

        const priorities = [{ text: 'Critical', color: 'red' }, { text: 'High', color: 'red' }, { text: 'Medium', color: 'gold' }, { text: 'Low', color: 'inherit' }];
        const enteredBy = _.find(this.props.users, { UserName: this.state.EnteredBy });

        // window["warn"]('Trip Info: ', trip);
        window["warn"]('openDialog Info: ', openDialog);

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <React.Fragment>
                        {
                            invoiceEmail &&
                            <MailCompose onClose={() => this.setState({ invoiceEmail: null, checklistResults: '', checklistsPDF: null }, () => { if (this.state.closeAfter && (!trip || trip.Status === 8)) { this.closeComposeDialog(); } })} hideButton={true} data={invoiceEmail} />
                        }
                        <EmailLogDialog title={`Work Order #${inv.WorkOrder} Email Log`} type="INVOICE" rec={inv.ID} open={this.state.showEmailLogs} onClose={() => this.setState({ showEmailLogs: false })} />
                        <Dialog
                            //classes={{ paper: classNames("mt-0 mb-0 w-full") }}
                            TransitionComponent={!matches ? SlideUp : Grow}
                            classes={{
                                root: classes.root,
                                paper: matches ? classes.paper : "full-screen-dialog"
                            }}
                            className={classes.root}
                            open={Boolean(this.props.open)}
                            onClose={() => this.closeComposeDialog()}
                            fullWidth
                            maxWidth={"md"}
                            fullScreen={!matches}
                        >
                            <AppBar position="static" elevation={1} className="dialog-header">
                                <Toolbar className="flex w-full">
                                    <Typography variant="subtitle1" color="inherit">
                                        {Boolean(inv.Data.VPARBH || inv.Data.VPARTH) &&
                                            <Tooltip title={<div>Batched: {this.formatDollars(batched)}<br />Posted: {this.formatDollars(posted)}</div>} placement="top">
                                                <Icon className="mr-8 mb-4" style={{ verticalAlign: 'middle' }}>{Boolean(inv.Data.VPARBH) ? "cloud_upload" : "cloud_done"}</Icon>
                                            </Tooltip>
                                        }
                                        {`Work Order #${inv.WorkOrder}`}
                                    </Typography>
                                </Toolbar>


                                {
                                    // PDF Dialog
                                    this.state.printPDF &&
                                    <Dialog
                                        classes={{ paper: classNames("mt-0 mb-0 w-full h-full", !matches && "full-screen-dialog") }}
                                        open={this.state.printPDF}
                                        onClose={() => this.setState({ printPDF: null, anchorEl: null })}
                                        maxWidth="sm"
                                        fullScreen={!matches}
                                    >
                                        <AppBar position="static" className="dialog-header">
                                            <Toolbar className="flex w-full">
                                                <Typography variant="subtitle1" color="inherit">
                                                    <Icon className="mr-6 align-middle mb-2">print</Icon>{inv.InvoiceNumber}.pdf
                                                </Typography>
                                            </Toolbar>
                                            <IconButton
                                                style={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    top: matches.medium ? 8 : 4,
                                                    color: 'white'
                                                }}
                                                onClick={() => {
                                                    this.setState({ printPDF: null, anchorEl: null });
                                                }}
                                                className="dialog-header-icon"
                                            >
                                                <Icon>close</Icon>
                                            </IconButton>
                                        </AppBar>
                                        <DialogContent classes={{ root: "p-16 pb-0 sm:p-24 sm:pb-0" }}>
                                            <iframe src={this.state.printPDF} className="w-full overflow-auto" style={{ height: 'calc(100% - 24px)' }} />
                                        </DialogContent>
                                    </Dialog>
                                }
                                {inv.ID &&
                                    <Tooltip title="Email Work Order">
                                        <IconButton style={{
                                            position: 'absolute',
                                            right: 56,
                                            top: matches ? 8 : 4,
                                            color: 'white'
                                        }}
                                            onClick={() => {
                                                this.openEmail();
                                            }}
                                            className="dialog-header-icon"
                                        >
                                            <Icon>email</Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: matches ? 8 : 4,
                                    color: 'white'
                                }}
                                    onClick={() => this.closeComposeDialog()}
                                    className="dialog-header-icon"
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                                <ConfirmationDialog
                                    open={this.state.promptSubmit}
                                    title={<div><Icon className="mr-6 align-middle mb-4">receipt</Icon>Invoice Incomplete</div>}
                                    content={<div className="w-full pt-8 pb-8">{`This Invoice has ${this.formatDollars(payments)} in payments applied but has not yet been completed - Please select "Finish" to complete this Invoice.`}</div>}
                                    confirmText="Finish"
                                    cancelText="Cancel"
                                    onConfirm={
                                        () => this.setState({ ...this.state, promptSubmit: false }, () => {
                                            if (agreement) {
                                                addAgreementInvoice({ ...this.state, Data: { ...this.state.Data, PaymentDetail: inv.Data.PaymentDetail } });
                                                this.openEmail();
                                            } else {
                                                Promise.all([
                                                    addInvoice({ ...this.state, Data: { ...this.state.Data, PaymentDetail: inv.Data.PaymentDetail } }),
                                                    this.openEmail(true, (trip && trip.Status < 8)),
                                                ]);
                                            }
                                        })}
                                    onCancel={() => this.setState({ ...this.state, promptSubmit: false })}
                                />
                            </AppBar>

                            <DialogContent classes={{ root: "p-12" }}>
                                <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
                                    {this.state.checklistResults}
                                </div>
                                <div id="wo-pdf-print" className="inv-print sm:mx-24">
                                    <div className="w-full flex justify-between my-24">
                                        {division && division.InvoiceHeaderImg &&
                                            <img className="h-48 sm:h-96" src={division.InvoiceHeaderImg} />
                                        }
                                        <span className="sm:mt-12">
                                            {/* <label className={classNames(classes.invHeader, "text-20 sm:text-36")}>Work Order</label>
                                            <div className={classNames(classes.invSubHeader, "text-10 sm:text-16")}>#{this.state.WorkOrder}</div> */}

                                            <div className="cursor-pointer">
                                                <div className="w-full text-16 font-bold text-left">
                                                    Customer #{this.state.Customer}
                                                </div>
                                                <div className="w-full text-12 text-left">
                                                    {this.state.Data.Customer.FirstName} {this.state.Data.Customer.LastName}
                                                </div>
                                                <div className="w-full text-12 text-left">
                                                    {this.state.Data.Customer.BillAddress1}
                                                </div>
                                                {this.state.BillAddress2 &&
                                                    <div className="w-full text-12 text-left">
                                                        {this.state.Data.Customer.BillAddress2}
                                                    </div>
                                                }
                                                <div className="w-full text-12 text-left">
                                                    {`${this.state.Data.Customer.BillCity}, ${this.state.Data.Customer.BillState} ${this.state.Data.Customer.BillZip}`}
                                                </div>
                                                {this.state.Data.Customer.BillPhone &&
                                                    <div className="w-full text-12 text-left">
                                                        {this.formatPhone(this.state.Data.Customer.BillPhone)}
                                                    </div>
                                                }
                                                {this.state.Data.Customer.BillEmail &&
                                                    <div className="w-full text-12 text-left">
                                                        {this.state.Data.Customer.BillEmail}
                                                    </div>
                                                }
                                                {billCust.ApprovedAccountYN === 'Y' &&
                                                    <div className="capitalize font-bold"><Icon color="primary" className="mr-4 text-14 align-middle mb-2">security</Icon>Approved Account</div>
                                                }
                                            </div>
                                        </span>
                                    </div>

                                    <div className="flex mb-24 justify-between px-16">
                                        <div className="cursor-pointer">
                                            <div className="w-full text-16 font-bold text-left">
                                                Site #{this.state.Site}
                                            </div>
                                            <div className="w-full text-12 text-left">
                                                {this.state.Data.Site.Contact}
                                            </div>
                                            <div className="w-full text-12 text-left">
                                                {this.state.Data.Site.Address1}
                                            </div>
                                            <div className="w-full text-12 text-left">
                                                {`${this.state.Data.Site.City}, ${this.state.Data.Site.State} ${this.state.Data.Site.Zip}`}
                                            </div>
                                            {this.state.Data.Site.Phone &&
                                                <div className="w-full text-12 text-left">
                                                    {this.formatPhone(this.state.Data.Site.Phone)}
                                                </div>
                                            }
                                            {this.state.Data.Customer.BillEmail &&
                                                <div className="w-full text-12 text-left">
                                                    {this.state.Data.Customer.BillEmail}
                                                </div>
                                            }
                                            {billCust.ApprovedAccountYN === 'Y' &&
                                                <div className="capitalize font-bold"><Icon color="primary" className="mr-4 text-14 align-middle mb-2">security</Icon>Approved Account</div>
                                            }
                                        </div>

                                        <div>
                                            {/* <DatePicker
                                                inputVariant="outlined"
                                                onOpen={() => this.setDatepickerOpen(true)}
                                                onClose={() => this.setDatepickerOpen(false)}
                                                open={this.state.isDatepickerOpen}
                                                label="Date"
                                                inputProps={
                                                    {
                                                        style: {
                                                            padding: 12,
                                                        }
                                                    }
                                                }
                                                TextFieldComponent={() => ''}
                                                value={this.state.InvoiceDate}
                                                onChange={this.handleDateChange}
                                                showTodayButton
                                                autoOk
                                            /> */}
                                            <div className="flex cursor-pointer"> {/*onClick={this.openDatePicker}*/}
                                                <HeadsetMicOutlined className="font-bold text-32 mr-6 align-middle" color="primary" />
                                                <div className="w-full">
                                                    <div className="text-14 font-bold w-full text-center">
                                                        {enteredBy ? `${enteredBy.FirstName} ${enteredBy.LastName}` : moment(this.state.EnteredDate).format('dddd')}
                                                    </div>
                                                    <div className="text-10 w-full text-center">
                                                        {moment(this.state.EnteredDate).format('MMM DD, YYYY')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex mt-24">
                                        <div className="w-full">
                                            <div className="w-full flex justify-between rounded-full mb-12" style={{ backgroundColor: '#3f3f3f' }}>
                                                <div onClick={() => openEditWorkOrderDialog({ Co: this.state.Co, WorkOrder: this.state.WorkOrder })} className={classNames("w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer", classes.dividerHighlight)}>
                                                    {this.state.WorkOrder ? `Work Order #${this.state.WorkOrder}` : 'Agreement Billing'}
                                                </div>
                                                <div className={classNames("w-2/3 py-8 px-24 text-12 font-bold text-right truncate", classes.dividerPlain)}>
                                                    {this.state.Description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mx-24 mt-12">

                                        {/* ADD DATA HERE */}

                                        {(this.state.Data && this.state.Data.Scopes) && this.state.Data.Scopes.map((scope, index) => {
                                            // const assignedIndex = _.findIndex(trip && trip.Data && trip.Data.Assignments ? trip.Data.Assignments : [], { Scope: scope.Scope })
                                            // const assignedTrip = (!trip || trip && assignedIndex > -1) ? true : false;

                                            const assignedIndex = 1;
                                            const assignedTrip = false;

                                            const genFxn = () => {
                                                console.log("Nothing!")
                                            }

                                            //const scopeNum = scope.Scope;
                                            let expanded = {};
                                            expanded[scope.Scope] = true;

                                            return (
                                                <div key={index}>
                                                    <ScopeDetails
                                                        expanded={expanded}
                                                        scopeData={this.state.Data}
                                                        scopeNum={scope}
                                                        indexNum={index}
                                                        Trips={this.state.Data.Trips}
                                                        trip={trip}
                                                        assignedIndex={assignedIndex}
                                                        assignedTrip={assignedTrip}
                                                        editTechs={{}}
                                                        editScopes={{}}
                                                        editQuotes={{}}
                                                        editWarranties={{}}
                                                        editSummary={{}}
                                                        woOpt={null}
                                                        techAvatar={techAvatar}
                                                        tripAccessLevel={null}
                                                        WorkOrder={this.state.WorkOrder}
                                                        priorities={priorities}
                                                        classes={classes}
                                                        launchEstimate={genFxn}
                                                        launchInvoice={genFxn}
                                                        assignUnit={genFxn}
                                                        unassignUnit={genFxn}
                                                        thisState={this.state}
                                                        setStateFxn={this.setState.bind(this)}
                                                        pdf={true}
                                                    />
                                                </div>
                                            )
                                        })
                                        }


                                        {/* End of Scopes */}

                                        {this.state.Data.Site.Data &&
                                            <div className="mt-12 prevent-split" style={{ borderTop: "2px solid lightgrey" }}>
                                                <div className="sm:flex p-12 sm:p-0 mt-12">
                                                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><HistoryOutlined color="primary" className="text-32 mr-12" />
                                                        <span>Site History</span>
                                                    </Typography>
                                                </div>
                                                <Card style={{ backgroundColor: 'lightgrey' }}>
                                                    <CardContent style={{ padding: '16px 16px 6px 16px' }}>
                                                        {this.state.Data.Site.Data.WorkOrders.slice(0, 5).map((WorkOrder) => {
                                                            const Trips = _.filter(this.state.Data.Site.Data.Trips, { WorkOrder: WorkOrder.WorkOrder });
                                                            window["warn"]("card trips", Trips)

                                                            return (
                                                                <Card key={WorkOrder.WorkOrder} className="prevent-split" style={{ backgroundColor: 'white', marginBottom: '10px' }}>
                                                                    <CardContent>
                                                                        <div className="w-full sm:flex">
                                                                            <div className="w-full">
                                                                                <div className="w-full min-h-48 relative border-grey-light border-b-1">
                                                                                    <div className="flex cursor-pointer">
                                                                                        <AssignmentOutlined className={classNames(classes.primaryIcon, "text-36 mr-8")} />
                                                                                        <div className="w-full">
                                                                                            <div className="text-14 font-bold">
                                                                                                {`Work Order #${WorkOrder.WorkOrder}`}
                                                                                            </div>
                                                                                            <div className="text-11 capitalize">
                                                                                                {WorkOrder.Description}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                {/* Scopes loop here */}
                                                                                <Typography className="font-bold text-14 py-8 pl-6"><BuildOutlined className={classNames(classes.primaryIcon, "mr-6 align-middle text-20")} color="primary" />Scopes</Typography>
                                                                                <div className="w-full min-h-48 relative border-grey-light border-1 rounded-lg p-12" style={{ backgroundColor: 'rgb(235,235,235)' }}>
                                                                                    {WorkOrder.Data.Scopes.map((Scope, i) => {
                                                                                        const leadTech = _.find(this.props.technicians, { Technician: Scope.LeadTechnician });
                                                                                        const scopes = WorkOrder.Data.Scopes.length;
                                                                                        const svc = _.find(this.props.services, { Service: Scope.Service });

                                                                                        return (
                                                                                            <div className={classNames("rounded-lg bg-white px-12 prevent-split", i > 0 && "mt-8")} style={{ boxShadow: '1px 1px 2px 0px #5f5f5f' }}>
                                                                                                <ListItem className="relative cursor-pointer min-h-36 w-full" style={{ padding: '4px 0' }}>
                                                                                                    <Badge color="error" classes={{ badge: this.props.classes.estBadge, root: "mr-4" }}>
                                                                                                        <Badge color="error" classes={{ badge: this.props.classes.taskBadge }}>
                                                                                                            <Avatar style={{ marginRight: 8, backgroundColor: '#4dbce9' }} classes={{ root: this.props.classes.avatarRoot }} className={this.props.classes.unassignedScopeAvatar}>{Scope.Scope}</Avatar>
                                                                                                        </Badge>
                                                                                                    </Badge>
                                                                                                    <ListItemText
                                                                                                        classes={{ root: this.props.classes.liRoot, primary: this.props.classes.title }}
                                                                                                        primary={
                                                                                                            <span className={classNames("font-bold text-14")}>
                                                                                                                {svc ? svc.Description : Scope.Service}
                                                                                                            </span>
                                                                                                        }
                                                                                                        secondary={
                                                                                                            <span className="font-bold text-12 flex">
                                                                                                                {
                                                                                                                    <Typography variant="caption" className={classNames("capitalize", this.props.classes.subheader)}>
                                                                                                                        {Scope.Description}
                                                                                                                    </Typography>
                                                                                                                }
                                                                                                            </span>
                                                                                                        } />
                                                                                                    <Typography component="div" variant="caption" className="font-bold absolute pin-r pin-t mr-4" style={{ marginTop: '2.1rem' }}>
                                                                                                        {Scope.DueBy && new Date(Scope.DueBy).toLocaleDateString('en-US')}
                                                                                                    </Typography>

                                                                                                </ListItem>
                                                                                                {Scope.Summary &&
                                                                                                    <div className="w-full pb-8">
                                                                                                        <div className="mb-8 relative p-8" style={{ border: '1px dotted lightgrey', borderRadius: 5 }}>
                                                                                                            <div className="text-12 italic" style={{ whiteSpace: 'normal' }}>
                                                                                                                {`"${Scope.Summary}"`}
                                                                                                                {leadTech &&
                                                                                                                    <b>
                                                                                                                        {` - ${leadTech.FirstName} ${leadTech.LastName}`}
                                                                                                                    </b>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    )}
                                                                                </div>
                                                                                {Trips.length > 0 &&
                                                                                    <React.Fragment>
                                                                                        <Typography className="font-bold text-14 py-8 pl-6"><LocalShippingOutlined className={classNames(classes.primaryIcon, "mr-6 align-middle text-20")} color="primary" />Trips</Typography>
                                                                                        <div className="w-full min-h-48 relative border-grey-light border-1 rounded-lg p-12 prevent-split" style={{ backgroundColor: 'rgb(235,235,235)' }}>
                                                                                            {Trips.map((trip, i) => {
                                                                                                const leadTech = _.find(this.props.technicians, { Technician: trip.Technician });

                                                                                                return (
                                                                                                    <div className={classNames("rounded-lg bg-white px-12 prevent-split", i > 0 && "mt-8")} style={{ boxShadow: '1px 1px 2px 0px #5f5f5f' }}>
                                                                                                        <ListItem style={{ padding: '8px 0' }}>
                                                                                                            <div className="mr-12">
                                                                                                                <Avatar classes={{ root: this.props.classes.techAvatarRoot }} className={this.props.classes.techAvatar} alt={leadTech.name} src={(leadTech.Data && leadTech.Data.Avatar ? `${window["apiLocation"]}/api/Attachment/GetPDFImage?Co=${leadTech.Co}&ID=${leadTech.Data.Avatar}&Thumb=true` : this.props.techAvatar)} />
                                                                                                            </div>
                                                                                                            <ListItemText
                                                                                                                classes={{ root: this.props.classes.liRoot, primary: this.props.classes.title }}
                                                                                                                primary={
                                                                                                                    <span className={classNames("font-bold text-12")}>
                                                                                                                        {`${leadTech.FirstName} ${leadTech.LastName}`}
                                                                                                                    </span>
                                                                                                                }
                                                                                                                secondary={
                                                                                                                    <span className="font-bold flex">
                                                                                                                        {
                                                                                                                            <Typography variant="caption" className={classNames(this.props.classes.subheader)} style={{ fontSize: '10px' }}>
                                                                                                                                {trip.CreatedDate && new Date(trip.CreatedDate).toLocaleDateString('en-US')}
                                                                                                                            </Typography>
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                } />
                                                                                                            <Typography component="div" variant="caption" className="font-bold absolute pin-r pin-t" style={{ marginTop: '2.1rem' }}>
                                                                                                                {(trip.Data.Assignments || []).length > 0 &&
                                                                                                                    <div className="rounded-full px-4 text-white mx-4 align-middle min-w-20 text-center" style={{ backgroundColor: '#4dbce9' }}>{(trip.Data.Assignments || []).length}</div>
                                                                                                                }
                                                                                                            </Typography>

                                                                                                        </ListItem>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            )}
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }

                                                                            </div>

                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            )
                                                        })}
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        }

                                    </div>

                                    <div className="w-full h-auto">
                                        {/* {division && division.InvoiceDisclaimer &&
                                                <div className="mx-24 mb-12">
                                                    <strong style={{ marginRight: 4 }}>Terms and Conditions:</strong>
                                                    <div className="font-bold text-red w-full mt-6" style={{ fontSize: 9 }}>{division.InvoiceDisclaimer}</div>
                                                </div>
                                            } */}
                                        <div className="mx-24">
                                            <div className="w-full sm:flex sm:justify-between">
                                                <div className="mt-24">
                                                    <div className="w-full flex">
                                                        <img src="assets/icons/location_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                {co.Name}
                                                            </div>
                                                            <div className="text-10">
                                                                {co.Address}
                                                            </div>
                                                            <div className="text-10">
                                                                {`${co.City}, ${co.State} ${co.Zip}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-24">
                                                    <div className="w-full flex">
                                                        <img src="assets/icons/email_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                Email
                                                            </div>
                                                            <div className="text-10">
                                                                {co.Email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-24">
                                                    <div className="w-full flex">
                                                        <img src="assets/icons/phone_icon.png" className="w-32 h-32 mr-8" />
                                                        <div className="w-full">
                                                            <div className="text-14 font-bold">
                                                                Phone
                                                            </div>
                                                            <div className="text-10">
                                                                {this.formatPhone(co.Phone)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <DebounceButton
                                    buttonText={"Generate PDF"}
                                    processingText={"Generating PDF"}
                                    //isDisabled={this.state.editingLine}
                                    variant={"contained"}
                                    color={"primary"}
                                    clickFxn={() => {
                                        this.printPDF()
                                    }}
                                    debounceInterval={500}
                                />
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                }
            </Media>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // refreshEditInvoiceDialog: Actions.refreshEditInvoiceDialog,
        // closeEditInvoiceDialog: Actions.closeEditInvoiceDialog,
        // closeNewInvoiceDialog: Actions.closeNewInvoiceDialog,
        // addInvoice: Actions.addInvoice,
        // addAgreementInvoice: Actions.addAgreementInvoice,
        // updateInvoice: Actions.updateInvoice,
        // syncInvoice: Actions.syncInvoice,
        // removeInvoice: Actions.removeInvoice,
        // openEditCustomerSiteDialog,
        // openEditWorkOrderDialog,
        // openEditWorkOrderUsageDialog,
        // sendInvoice: Actions.sendInvoice,
        // updateNotes: updateInvoiceNotes,
        // updateInvoiceDetail: Actions.updateInvoiceDetail,
        // removeInvoiceDetail: Actions.removeInvoiceDetail,
        updateTrip,
        openEditCustomerDialog,
        openEditEstimateDialog
    }, dispatch);
}

function mapStateToProps({ invoicesApp, workOrdersApp, spReducers, emailTemplateApp }) {
    return {
        invoiceDialog: invoicesApp.invoices.invoiceDialog,
        workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
        companies: spReducers.companies.List,
        Co: spReducers.companies.Co,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        technicians: spReducers.technicians,
        templates: emailTemplateApp.templates.Templates,
        formOfPayments: spReducers.formOfPayments,
        User: spReducers.userProfiles.User,
        Users: spReducers.userProfiles.Users,
        services: spReducers.services,
        securables: spReducers.userProfiles.User.Data.Securables,
        users: spReducers.userProfiles.Users,
        notes: spReducers.invoices.notes,
        serviceUnitTypes: spReducers.serviceUnitTypes,
        materials: spReducers.materials.materials,
        laborTypes: spReducers.laborTypes,
        taxCodes: spReducers.taxCodes,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersPDF));