import {combineReducers} from 'redux';
import technicians from './technicians.reducer';
import user from './user.reducer';

const techniciansAppReducers = combineReducers({
    technicians,
    user
});

export default techniciansAppReducers;
