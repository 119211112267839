import React from 'react';
import {Redirect} from 'react-router-dom';
import Dashboards from './dashboards/Dashboards';
import Dashboard from './dashboard/Dashboard';

export const DashboardBuilderAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/dashboard-builder/dashboards/:Co/:dashboardId/:dashboardUri?',
            component: Dashboard
        },
        {
            path     : '/apps/dashboard-builder/dashboards',
            component: Dashboards
        },
        {
            path     : '/apps/dashboard-builder',
            component: () => <Redirect to="/apps/dashboard-builder/dashboards"/>
        }
    ]
};
