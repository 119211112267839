import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedPhoneNumberIds: [],
    routeParams: {},
    phoneNumberDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    searchResults: [],
};

const phoneNumbersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_RESULTS:
            {
                return {
                    ...state,
                    searchResults: action.payload,
                }
            }
        case Actions.GET_PHONE_NUMBERS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_PHONE_NUMBERS:
            {

                const phoneNumberId = action.phoneNumberId;

                let selectedPhoneNumberIds = [...state.selectedPhoneNumberIds];

                if (selectedPhoneNumberIds.find(id => id === phoneNumberId) !== undefined) {
                    selectedPhoneNumberIds = selectedPhoneNumberIds.filter(id => id !== phoneNumberId);
                }
                else {
                    selectedPhoneNumberIds = [...selectedPhoneNumberIds, phoneNumberId];
                }

                return {
                    ...state,
                    selectedPhoneNumberIds: selectedPhoneNumberIds
                };
            }
        case Actions.SELECT_ALL_PHONE_NUMBERS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedPhoneNumberIds = arr.map(phoneNumbers => phoneNumbers.id);

                return {
                    ...state,
                    selectedPhoneNumberIds: selectedPhoneNumberIds
                };
            }
        case Actions.DESELECT_ALL_PHONE_NUMBERS:
            {
                return {
                    ...state,
                    selectedPhoneNumberIds: []
                };
            }
        case Actions.OPEN_NEW_PHONE_NUMBER_DIALOG:
            {
                return {
                    ...state,
                    phoneNumberDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_PHONE_NUMBER_DIALOG:
            {
                return {
                    ...state,
                    phoneNumberDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_PHONE_NUMBER_DIALOG:
            {
                return {
                    ...state,
                    phoneNumberDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_PHONE_NUMBER_DIALOG:
            {
                return {
                    ...state,
                    phoneNumberDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default phoneNumbersReducer;
