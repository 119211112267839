import axios from 'axios/index';
import _ from '@lodash';

export const SET_QBO_COMPANY = '[SPCONNECTION] SET QBO COMPANY';
export const SET_QBO_COMPANY_DATA = '[SPCONNECTION] SET QBO COMPANY DATA';
export const GET_QBO_COMPANY_DATA = '[SPCONNECTION] GET QBO COMPANY DATA';
export const SET_QBO_VENDOR_DATA = '[SPCONNECTION] SET QBO VENDOR DATA';
export const GET_QBO_VENDOR_DATA = '[SPCONNECTION] GET QBO VENDOR DATA';
export const SET_QBO_DEPARTMENT_DATA = '[SPCONNECTION] SET QBO DEPARTMENT DATA';
export const GET_QBO_DEPARTMENT_DATA = '[SPCONNECTION] GET QBO DEPARTMENT DATA';
export const SET_QBO_EMPLOYEE_DATA = '[SPCONNECTION] SET QBO EMPLOYEE DATA';
export const GET_QBO_EMPLOYEE_DATA = '[SPCONNECTION] GET QBO EMPLOYEE DATA';
export const SET_QBO_NON_INVENTORY_DATA = '[SPCONNECTION] SET QBO NON INVENTORY DATA';
export const GET_QBO_NON_INVENTORY_DATA = '[SPCONNECTION] GET QBO NON INVENTORY DATA';
export const SET_QBO_MATERIAL_DATA = '[SPCONNECTION] SET QBO MATERIAL DATA';
export const GET_QBO_MATERIAL_DATA = '[SPCONNECTION] GET QBO MATERIAL DATA';
export const SET_QBO_EARN_CODE_DATA = '[SPCONNECTION] SET QBO EARN CODE DATA';
export const GET_QBO_EARN_CODE_DATA = '[SPCONNECTION] GET QBO EARN CODE DATA';
export const SET_QBO_GL_ACCOUNT_DATA = '[SPCONNECTION] SET QBO GL ACCOUNT DATA';
export const GET_QBO_GL_ACCOUNT_DATA = '[SPCONNECTION] GET QBO GL ACCOUNT DATA';
export const SET_QBO_PAY_TERM_DATA = '[SPCONNECTION] SET QBO PAY TERM DATA';
export const GET_QBO_PAY_TERM_DATA = '[SPCONNECTION] GET QBO PAY TERM DATA';
export const SET_QBO_TAX_CODE_DATA = '[SPCONNECTION] SET QBO TAX CODE DATA';
export const GET_QBO_TAX_CODE_DATA = '[SPCONNECTION] GET QBO TAX CODE DATA';
export const SET_QBO_TAX_RATE_DATA = '[SPCONNECTION] SET QBO TAX RATE DATA';
export const GET_QBO_TAX_RATE_DATA = '[SPCONNECTION] GET QBO TAX RATE DATA';
export const SET_QBO_CATEGORY_DATA = '[SPCONNECTION] SET QBO CATEGORY DATA';
export const GET_QBO_CATEGORY_DATA = '[SPCONNECTION] GET QBO CATEGORY DATA';
export const SET_QBO_CLASS_DATA = '[SPCONNECTION] SET QBO CLASS DATA';
export const GET_QBO_CLASS_DATA = '[SPCONNECTION] GET QBO CLASS DATA';
export const SET_QBO_SERVICE_DATA = '[SPCONNECTION] SET QBO SERVICE DATA';
export const GET_QBO_SERVICE_DATA = '[SPCONNECTION] GET QBO SERVICE DATA';
export const SET_QBO_PREFERENCES_DATA = '[SPCONNECTION] SET QBO PREFERENCES DATA';
export const GET_QBO_PREFERENCES_DATA = '[SPCONNECTION] GET QBO PREFERENCES DATA';
export const GET_QBO_PAYROLL_ITEM_WAGE_DATA = '[SPCONNECTION] GET QBO QBO PAYROLL ITEM WAGE DATA';

export function getQBOCompanyData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Company`, {
        params: {
            Co
        }
    });

    return (dispatch, getState) => {
        request.then((response) => {
            if (response.data) {
                Promise.all([
                    dispatch({
                        type: SET_QBO_COMPANY,
                        payload: {
                            ...response.data,
                            Co,
                        }
                    }),
                    dispatch(getQBOPreferenceData(Co)),
                    dispatch(getQBODepartmentData(Co)),
                    dispatch(getQBOClassData(Co)),
                    dispatch(getQBOMaterialData(Co)),
                    dispatch(getQBONonInventoryData(Co)),
                    dispatch(getQBOCategoryData(Co)),
                    dispatch(getQBOServiceData(Co)),
                    dispatch(getQBOTermData(Co)),
                    dispatch(getQBOEmployeeData(Co)),
                    dispatch(getQBOVendorData(Co)),
                    dispatch(getQBOAccountData(Co)),
                    dispatch(getQBOTaxCodeData(Co)),
                    dispatch(getQBOTaxRateData(Co)),
                ]);
            }
        });
    }
}

export function setQBOCompanyData(data) {
    return {
        type: SET_QBO_COMPANY_DATA,
        payload: data
    };
}

export function getQBOPreferenceData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/Preferences`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_PREFERENCES_DATA,
                payload: response.data[0]
            })
        });
    }
}

export function setQBOPreferenceData(data) {
    return {
        type: SET_QBO_PREFERENCES_DATA,
        payload: data
    };
}

export function getQBOVendorData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Vendors`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_VENDOR_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOVendorData(data) {
    return {
        type: SET_QBO_VENDOR_DATA,
        payload: data
    };
}

export function getQBONonInventoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/NonInventory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_NON_INVENTORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBONonInventoryData(data) {
    return {
        type: SET_QBO_NON_INVENTORY_DATA,
        payload: data
    };
}

export function getQBOMaterialData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/Materials`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_MATERIAL_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOMaterialData(data) {
    return {
        type: SET_QBO_MATERIAL_DATA,
        payload: data
    };
}

export function getQBOCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/Categories`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOCategoryData(data) {
    return {
        type: SET_QBO_CATEGORY_DATA,
        payload: data
    };
}

export function getQBOServiceData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/Services`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_SERVICE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOServiceData(data) {
    return {
        type: SET_QBO_SERVICE_DATA,
        payload: data
    };
}

export function getQBOEmployeeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Employees`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_EMPLOYEE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOEmployeeData(data) {
    return {
        type: SET_QBO_EMPLOYEE_DATA,
        payload: data
    };
}

export function getQBODepartmentData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Departments`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_DEPARTMENT_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBODepartmentData(data) {
    return {
        type: SET_QBO_DEPARTMENT_DATA,
        payload: data
    };
}

export function getQBOClassData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Classes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_CLASS_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOClassData(data) {
    return {
        type: SET_QBO_CLASS_DATA,
        payload: data
    };
}
export function getQBOEarnCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/EarnCodes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_EARN_CODE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOEarnCodeData(data) {
    return {
        type: SET_QBO_EARN_CODE_DATA,
        payload: data
    };
}

export function getQBOAccountData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Accounts`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_GL_ACCOUNT_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOAccountData(data) {
    return {
        type: SET_QBO_GL_ACCOUNT_DATA,
        payload: data
    };
}

export function getQBOTermData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettingsLite/Terms`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_PAY_TERM_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOTermData(data) {
    return {
        type: SET_QBO_PAY_TERM_DATA,
        payload: data
    };
}

export function getQBOTaxCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/TaxCodes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_TAX_CODE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOTaxCodeData(data) {
    return {
        type: SET_QBO_TAX_CODE_DATA,
        payload: data
    };
}

export function getQBOTaxRateData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBOSettings/TaxRates`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_TAX_RATE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBOTaxRateData(data) {
    return {
        type: SET_QBO_TAX_RATE_DATA,
        payload: data
    };
}


export function getQBDCompanyData(Co) {

    return (dispatch, getState) => {
        Promise.all([
            dispatch({
                type: SET_QBO_COMPANY,
                payload: {
                    Co,
                }
            }),
            dispatch(getQBDClassData(Co)),
            dispatch(getQBDMaterialData(Co)),
            dispatch(getQBDNonInventoryData(Co)),
            dispatch(getQBDServiceData(Co)),
            dispatch(getQBDTermData(Co)),
            dispatch(getQBDEmployeeData(Co)),
            dispatch(getQBDVendorData(Co)),
            dispatch(getQBDAccountData(Co)),
            dispatch(getQBDTaxCodeData(Co)),
            dispatch(getQBDPayrollItemWageData(Co)),
        ]);
    }
}

export function setQBDCompanyData(data) {
    return {
        type: SET_QBO_COMPANY_DATA,
        payload: data
    };
}

export function getQBDPreferenceData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/Preferences`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_PREFERENCES_DATA,
                payload: response.data[0]
            })
        });
    }
}

export function setQBDPreferenceData(data) {
    return {
        type: SET_QBO_PREFERENCES_DATA,
        payload: data
    };
}

export function getQBDVendorData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/Vendors`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_VENDOR_DATA,
                payload: response.data
            })
        });
    }
}

export function getQBDPayrollItemWageData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/PayrollItemWage`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_PAYROLL_ITEM_WAGE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDVendorData(data) {
    return {
        type: SET_QBO_VENDOR_DATA,
        payload: data
    };
}

export function getQBDNonInventoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/NonInventory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_NON_INVENTORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDNonInventoryData(data) {
    return {
        type: SET_QBO_NON_INVENTORY_DATA,
        payload: data
    };
}

export function getQBDMaterialData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/Materials`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_MATERIAL_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDMaterialData(data) {
    return {
        type: SET_QBO_MATERIAL_DATA,
        payload: data
    };
}

export function getQBDCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/Categories`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDCategoryData(data) {
    return {
        type: SET_QBO_CATEGORY_DATA,
        payload: data
    };
}

export function getQBDServiceData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/Services`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_SERVICE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDServiceData(data) {
    return {
        type: SET_QBO_SERVICE_DATA,
        payload: data
    };
}

export function getQBDEmployeeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/Employees`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_EMPLOYEE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDEmployeeData(data) {
    return {
        type: SET_QBO_EMPLOYEE_DATA,
        payload: data
    };
}

export function getQBDDepartmentData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/Departments`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_DEPARTMENT_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDDepartmentData(data) {
    return {
        type: SET_QBO_DEPARTMENT_DATA,
        payload: data
    };
}

export function getQBDClassData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/Classes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_CLASS_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDClassData(data) {
    return {
        type: SET_QBO_CLASS_DATA,
        payload: data
    };
}
export function getQBDEarnCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/EarnCodes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_EARN_CODE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDEarnCodeData(data) {
    return {
        type: SET_QBO_EARN_CODE_DATA,
        payload: data
    };
}

export function getQBDAccountData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/Accounts`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_GL_ACCOUNT_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDAccountData(data) {
    return {
        type: SET_QBO_GL_ACCOUNT_DATA,
        payload: data
    };
}

export function getQBDTermData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettingsLite/Terms`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_PAY_TERM_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDTermData(data) {
    return {
        type: SET_QBO_PAY_TERM_DATA,
        payload: data
    };
}

export function getQBDTaxCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/TaxCodes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_TAX_CODE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDTaxCodeData(data) {
    return {
        type: SET_QBO_TAX_CODE_DATA,
        payload: data
    };
}

export function getQBDTaxRateData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/QBDSettings/TaxRates`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_QBO_TAX_RATE_DATA,
                payload: response.data
            })
        });
    }
}

export function setQBDTaxRateData(data) {
    return {
        type: SET_QBO_TAX_RATE_DATA,
        payload: data
    };
}
