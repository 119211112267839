import {combineReducers} from 'redux';
import techUA from './tech-ua.reducer';
import user from './user.reducer';

const techUAAppReducers = combineReducers({
    techUA,
    user
});

export default techUAAppReducers;
