import React from 'react';
import { withStyles, Avatar, Icon, ListItem, Tooltip, Typography } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import classNames from 'classnames';
import moment from 'moment';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    commentBubble: {
        borderRadius: 8,
        border: '1px solid ' + theme.palette.divider,
        '&:hover': {
            backgroundColor: '#caeff6',
            cursor: 'pointer'
        }
    },
    selected: {
        backgroundColor: '#d5f6ca !important',
    },
    reverted: {
        backgroundColor: '#f1f1f1',
    }
});

const LogEntry = ({ theme, item, users, classes, onSelected, selected }) => {

    const avatar = 'assets/images/avatars/profile.jpg';
    const user = _.find(users, { UserName: item.ActionBy });
    const Data = JSON.parse(item.Data);
    const isSelected = Boolean(selected === item.ID);
    const isReverted = Boolean(selected && item.ID >= selected)

    return (
        <div className="p-12 w-full">
            <div className="flex justify-between items-center mb-6">
                <div className="flex">
                    <Avatar alt={user.FirstName} src={user && user.Data && user.Data.Avatar ? getProfileImage(`ID=${user.Data.Avatar}`) : avatar} className="w-32 h-32 mr-12" style={{ marginTop: -2 }} />
                    <Typography className="font-bold">{`${user.FirstName} ${user.LastName}`}</Typography>
                </div>
                <Typography className="ml-8 text-12" color="textSecondary">{moment(item.ActionDate).local().toDate().toLocaleString('en-US')}</Typography>
            </div>
            <div className={classNames(classes.commentBubble, isSelected ? classes.selected : isReverted ? classes.reverted : '', "flex flex-col pb-12 px-8")} style={{ opacity: isReverted ? isSelected ? 1 : .15 : 1 }} onClick={() => onSelected(item)}>
                <div className="w-full flex flex-wrap p-4">
                    {Data.map((value, index) => {
                        return (
                            <div className="py-4 mr-12">
                                <Typography className="font-bold text-12">{value.Prop}</Typography>
                                <Typography className="font-bold text-10 rounded-full bg-grey-darker text-white align-middle px-12 flex"><Tooltip placement="top" title={value.OldVal || 'NULL'}><div className={classNames('pt-4 max-w-128 truncate', isReverted && !isSelected ? 'line-through' : '')}>{value.OldVal || 'NULL'}</div></Tooltip><Icon className="mx-8 align-middle rounded-full" style={{ paddingLeft: isReverted ? undefined : 1, backgroundColor: isReverted ? theme.palette.error[500] : theme.palette.primary.main }}>{isReverted ? isSelected ? 'keyboard_arrow_left' : 'close' : 'keyboard_arrow_right'}</Icon><Tooltip placement="top" title={value.NewVal || 'NULL'}><div className={classNames('pt-4 max-w-128 truncate', isReverted ? 'line-through' : '')}>{value.NewVal || 'NULL'}</div></Tooltip></Typography>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(LogEntry));
