import axios from 'axios';
import { getUserData } from 'main/content/apps/dial-group-members/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_DIAL_GROUP_MEMBERS = '[DIAL GROUP MEMBERS APP] GET DIAL GROUP MEMBERS';
export const SET_SEARCH_TEXT = '[DIAL GROUP MEMBERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_DIAL_GROUP_MEMBERS = '[DIAL GROUP MEMBERS APP] TOGGLE IN SELECTED DIAL GROUP MEMBERS';
export const SELECT_ALL_DIAL_GROUP_MEMBERS = '[DIAL GROUP MEMBERS APP] SELECT ALL DIAL GROUP MEMBERS';
export const DESELECT_ALL_DIAL_GROUP_MEMBERS = '[DIAL GROUP MEMBERS APP] DESELECT ALL DIAL GROUP MEMBERS';
export const OPEN_NEW_DIAL_GROUP_MEMBER_DIALOG = '[DIAL GROUP MEMBERS APP] OPEN NEW DIAL GROUP MEMBER DIALOG';
export const CLOSE_NEW_DIAL_GROUP_MEMBER_DIALOG = '[DIAL GROUP MEMBERS APP] CLOSE NEW DIAL GROUP MEMBER DIALOG';
export const OPEN_EDIT_DIAL_GROUP_MEMBER_DIALOG = '[DIAL GROUP MEMBERS APP] OPEN EDIT DIAL GROUP MEMBER DIALOG';
export const UPDATE_EDIT_DIAL_GROUP_MEMBER_DIALOG = '[DIAL GROUP MEMBERS APP] UPDATE EDIT DIAL GROUP MEMBER DIALOG';
export const CLOSE_EDIT_DIAL_GROUP_MEMBER_DIALOG = '[DIAL GROUP MEMBERS APP] CLOSE EDIT DIAL GROUP MEMBER DIALOG';
export const ADD_DIAL_GROUP_MEMBER = '[DIAL GROUP MEMBERS APP] ADD DIAL GROUP MEMBER';
export const UPDATE_DIAL_GROUP_MEMBER = '[DIAL GROUP MEMBERS APP] UPDATE DIAL GROUP MEMBER';
export const REMOVE_DIAL_GROUP_MEMBER = '[DIAL GROUP MEMBERS APP] REMOVE DIAL GROUP MEMBER';
export const REMOVE_DIAL_GROUP_MEMBERS = '[DIAL GROUP MEMBERS APP] REMOVE DIAL GROUP MEMBERS';
export const TOGGLE_STARRED_DIAL_GROUP_MEMBER = '[DIAL GROUP MEMBERS APP] TOGGLE STARRED DIAL GROUP MEMBER';
export const TOGGLE_STARRED_DIAL_GROUP_MEMBERS = '[DIAL GROUP MEMBERS APP] TOGGLE STARRED DIAL GROUP MEMBERS';
export const SET_DIAL_GROUP_MEMBERS_STARRED = '[DIAL GROUP MEMBERS APP] SET DIAL GROUP MEMBERS STARRED ';
export const SET_SEARCH_RESULTS = '[DIAL GROUP MEMBERS APP] SET SEARCH RESULTS';

export function getDialGroupMembers(routeParams) {
    const request = axios.get('/api/dial-group-members-app/dial-group-members', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_DIAL_GROUP_MEMBERS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedDialGroupMembers(dialGroupMemberId) {
    return {
        type: TOGGLE_IN_SELECTED_DIAL_GROUP_MEMBERS,
        dialGroupMemberId
    }
}


export function selectAllDialGroupMembers() {
    return {
        type: SELECT_ALL_DIAL_GROUP_MEMBERS
    }
}

export function deSelectAllDialGroupMembers() {
    return {
        type: DESELECT_ALL_DIAL_GROUP_MEMBERS
    }
}


export function openNewDialGroupMemberDialog(data) {
    return {
        type: OPEN_NEW_DIAL_GROUP_MEMBER_DIALOG,
        data
    }
}

export function closeNewDialGroupMemberDialog() {
    return {
        type: CLOSE_NEW_DIAL_GROUP_MEMBER_DIALOG
    }
}

export function openEditDialGroupMemberDialog(data) {
    return {
        type: OPEN_EDIT_DIAL_GROUP_MEMBER_DIALOG,
        data
    }
}

export function closeEditDialGroupMemberDialog() {
    return {
        type: CLOSE_EDIT_DIAL_GROUP_MEMBER_DIALOG
    }
}

export function addDialGroupMember(newDialGroupMember) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/DialGroupMember`,
            newDialGroupMember
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_DIAL_GROUP_MEMBER,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dial Group Member has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateDialGroupMember(dialGroupMember) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/DialGroupMember?Co=${encodeURIComponent(dialGroupMember.Co)}&GroupID=${encodeURIComponent(dialGroupMember.GroupID)}&DialID=${encodeURIComponent(dialGroupMember.DialID)}&ID=${encodeURIComponent(dialGroupMember.ID)}`,
            dialGroupMember
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_DIAL_GROUP_MEMBER,
                    data: response.data,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dial Group Member has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeDialGroupMember(dialGroupMember) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/DialGroupMember?Co=${encodeURIComponent(dialGroupMember.Co)}&GroupID=${encodeURIComponent(dialGroupMember.GroupID)}&DialID=${encodeURIComponent(dialGroupMember.DialID)}&ID=${encodeURIComponent(dialGroupMember.ID)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DIAL_GROUP_MEMBER,
                    data: dialGroupMember
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.deSelectAllDialGroupMembers}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dial Group Member has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeDialGroupMembers(dialGroupMemberIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupMembersApp.dialGroupMembers;

        const request = axios.post('/api/dial-group-members-app/remove-dialGroupMembers', {
            dialGroupMemberIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DIAL_GROUP_MEMBERS
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUP_MEMBERS
                })
            ]).then(() => dispatch(getDialGroupMembers(routeParams)))
        );
    };
}

export function toggleStarredDialGroupMember(dialGroupMemberId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().dialGroupMembersApp.dialGroupMembers;

        const request = axios.post('/api/dial-group-members-app/toggle-starred-dialGroupMember', {
            dialGroupMemberId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DIAL_GROUP_MEMBER
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDialGroupMembers(routeParams)))
        );
    };
}

export function toggleStarredDialGroupMembers(dialGroupMemberIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupMembersApp.dialGroupMembers;

        const request = axios.post('/api/dial-group-members-app/toggle-starred-dialGroupMembers', {
            dialGroupMemberIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DIAL_GROUP_MEMBERS
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUP_MEMBERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDialGroupMembers(routeParams)))
        );
    };
}

export function setDialGroupMembersStarred(dialGroupMemberIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupMembersApp.dialGroupMembers;

        const request = axios.post('/api/dial-group-members-app/set-dialGroupMembers-starred', {
            dialGroupMemberIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DIAL_GROUP_MEMBERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUP_MEMBERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDialGroupMembers(routeParams)))
        );
    };
}

export function setDialGroupMembersUnstarred(dialGroupMemberIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupMembersApp.dialGroupMembers;

        const request = axios.post('/api/dial-group-members-app/set-dialGroupMembers-unstarred', {
            dialGroupMemberIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DIAL_GROUP_MEMBERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUP_MEMBERS
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function purchaseDialGroupMember(num) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/IncomingDialGroupMember`, num);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: ADD_DIAL_GROUP_MEMBER
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Gather ${num.Number} has been successfully purchased.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        });
    }
}

export function searchAvailableNumbers(Co, area, search) {
    return (dispatch, getState) => {
        var request = axios.get(`${window["apiLocation"]}/api/IncomingDialGroupMember?Co=${encodeURIComponent(Co)}&AreaCode=${encodeURIComponent(area)}&Pattern=${search}`);

        return request.then((response) => {
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: response.data,
            })
        });
    }
}
