import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedInventoryLocationGroupIds: [],
    routeParams       : {},
    inventoryLocationGroupDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const inventoryLocationGroupsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_INVENTORY_LOCATION_GROUPS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_INVENTORY_LOCATION_GROUPS:
        {

            const inventoryLocationGroupId = action.inventoryLocationGroupId;

            let selectedInventoryLocationGroupIds = [...state.selectedInventoryLocationGroupIds];

            if ( selectedInventoryLocationGroupIds.find(id => id === inventoryLocationGroupId) !== undefined )
            {
                selectedInventoryLocationGroupIds = selectedInventoryLocationGroupIds.filter(id => id !== inventoryLocationGroupId);
            }
            else
            {
                selectedInventoryLocationGroupIds = [...selectedInventoryLocationGroupIds, inventoryLocationGroupId];
            }

            return {
                ...state,
                selectedInventoryLocationGroupIds: selectedInventoryLocationGroupIds
            };
        }
        case Actions.SELECT_ALL_INVENTORY_LOCATION_GROUPS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedInventoryLocationGroupIds = arr.map(inventoryLocationGroups => inventoryLocationGroups.id);

            return {
                ...state,
                selectedInventoryLocationGroupIds: selectedInventoryLocationGroupIds
            };
        }
        case Actions.DESELECT_ALL_INVENTORY_LOCATION_GROUPS:
        {
            return {
                ...state,
                selectedInventoryLocationGroupIds: []
            };
        }
        case Actions.OPEN_NEW_INVENTORY_LOCATION_GROUP_DIALOG:
        {
            return {
                ...state,
                inventoryLocationGroupDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_INVENTORY_LOCATION_GROUP_DIALOG:
        {
            return {
                ...state,
                inventoryLocationGroupDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_INVENTORY_LOCATION_GROUP_DIALOG:
        {
            return {
                ...state,
                inventoryLocationGroupDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_INVENTORY_LOCATION_GROUP_DIALOG:
        {
            return {
                ...state,
                inventoryLocationGroupDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default inventoryLocationGroupsReducer;
