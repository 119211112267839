import {combineReducers} from 'redux';
import teams from './teams.reducer';
import user from './user.reducer';

const teamsAppReducers = combineReducers({
    teams,
    user
});

export default teamsAppReducers;
