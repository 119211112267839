import { Plugins } from '@capacitor/core';
import _ from '@lodash';
import { Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grow, Icon, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Slide, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { guid } from 'store/actions';
// import 'capacitor-plugin-omni-sdk';
import PaymentsList from '../payments/PaymentsList';
const { OmniPlugin } = Plugins;
const { initOmni, getReaders, connectReader, disconnectReader, takePayment } = OmniPlugin || {};

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        margin: 24,
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/images/backgrounds/SP_Header_Dark.png)',
        backgroundPosition: 'center'
    },
    checkbox: {
        paddingRight: 4,
        paddingTop: 10,
    },
    approved: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#333',
            color: '#fff',
        },
        '&:active': {
            backgroundColor: '#333',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#333',
            color: '#fff',
        }
    }
});

class PaymentForm extends Component {
    fattJs = null;
    state = {
        PaymentType: null,
        Memo: null,
        Check: null,
        cardMethod: /*window.cordova*/false ? "S" : "K",
        cardOnFile: null,
        tokenizedPaymentMethod: null,
        completedTransaction: null,
        isPayButtonDisabled: true,
        formValues: {},
        loading: false,
        connected: false,
        reader: false,
        readers: null,
        selectedReader: null,
        status: '',
        processing: false,
        complete: false,
        error: false,
        paymentStatus: null,
        readerStatus: null,
        paymentsToken: false,
        paymentsInvoice: null
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { PaymentsCustomer } = this.props.data;
        if (PaymentsCustomer && this.state.PaymentType === 'CREDIT' && prevState.PaymentType !== "CREDIT" && (/*!window.cordova || */this.state.cardMethod === "K")) {
            this.initPaymentForm();
        }
    }

    componentDidMount() {
        const { data, companies, paymentMethods } = this.props;
        let { Co, FirstName, LastName, BillPhone, BillEmail, BillAddress1, BillAddress2, BillCity, BillState, BillCountry, BillZip, PaymentsCustomer, PaymentsInvoice, total } = data;
        total = total.toFixed(2);
        const co = _.find(companies.List, { Co });
        window["log"]('Payment Methods: ', paymentMethods)
        const paymentsToken = co && co.Data ? co.Data.PaymentsToken : null;
        let PaymentType = (paymentsToken && paymentMethods && paymentMethods.length > 0) ? "ON-FILE" : this.state.PaymentType;
        if (Co) {
            if (/*window.cordova*/false) {
                const request = axios.get(`${window["apiLocation"]}/api/FMEK?Co=${Co}`);
                request.then((response) => {
                    if (response.data.token) {
                        initOmni({ apiKey: response.data.token }).then(r => {
                            this.setState({
                                ...this.state,
                                paymentsInvoice: PaymentsInvoice || this.state.paymentsInvoice,
                                PaymentType,
                                connected: true,
                                status: r.message,
                                total,
                                paymentsToken,
                                formValues: {
                                    customer_id: PaymentsCustomer || undefined,
                                    firstname: FirstName,
                                    lastname: LastName,
                                    phone: BillPhone,
                                    // email: BillEmail,
                                    address_1: BillAddress1,
                                    address_2: BillAddress2,
                                    address_city: BillCity,
                                    address_state: BillState,
                                    address_country: BillCountry,
                                    address_zip: BillZip,
                                    total,
                                }
                            }, () => {
                                const updater = OmniPlugin.addListener('onStatusUpdate', (info) => {
                                    this.setState({ ...this.state, readerStatus: info.message, paymentsToken });
                                });
                                this.getReaders();
                            });
                        }).catch(err => {
                            this.setState({
                                ...this.state,
                                paymentsInvoice: PaymentsInvoice || this.state.paymentsInvoice,
                                PaymentType,
                                connected: false,
                                status: err.message,
                                total,
                                paymentsToken,
                                formValues: {
                                    customer_id: PaymentsCustomer || undefined,
                                    firstname: FirstName,
                                    lastname: LastName,
                                    phone: BillPhone,
                                    // email: BillEmail,
                                    address_1: BillAddress1,
                                    address_2: BillAddress2,
                                    address_city: BillCity,
                                    address_state: BillState,
                                    address_country: BillCountry,
                                    address_zip: BillZip,
                                    total,
                                }
                            });
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            paymentsInvoice: PaymentsInvoice || this.state.paymentsInvoice,
                            PaymentType,
                            connected: false,
                            total,
                            paymentsToken,
                            formValues: {
                                customer_id: PaymentsCustomer || undefined,
                                firstname: FirstName,
                                lastname: LastName,
                                phone: BillPhone,
                                // email: BillEmail,
                                address_1: BillAddress1,
                                address_2: BillAddress2,
                                address_city: BillCity,
                                address_state: BillState,
                                address_country: BillCountry,
                                address_zip: BillZip,
                                total,
                            }
                        });
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    paymentsInvoice: PaymentsInvoice || this.state.paymentsInvoice,
                    PaymentType,
                    connected: false,
                    status: null,
                    total,
                    paymentsToken,
                    formValues: {
                        customer_id: PaymentsCustomer || undefined,
                        firstname: FirstName,
                        lastname: LastName,
                        phone: BillPhone,
                        // email: BillEmail,
                        address_1: BillAddress1,
                        address_2: BillAddress2,
                        address_city: BillCity,
                        address_state: BillState,
                        address_country: BillCountry,
                        address_zip: BillZip,
                        total,
                    }
                }/*, this.initPaymentForm*/);
            }
        }
    }



    initPaymentForm = () => {
        const { data, companies } = this.props;
        const { paymentsToken } = this.state;
        const { Co, FirstName, LastName, BillPhone, BillEmail, BillAddress1, BillAddress2, BillCity, BillState, BillCountry, BillZip, PaymentsCustomer, PaymentsInvoice, total } = data;
        const co = _.find(companies.List, { Co });
        if (paymentsToken) {
            const FattJs = window.FattJs;

            if (!FattJs) {
                this.setState({ PaymentType: null, error: { message: 'Manual Entry unavailable' } });
            } else {

                const fattJs = new FattJs(paymentsToken, {
                    number: {
                        id: "card-number",
                        placeholder: "xxxx xxxx xxxx xxxx",
                        style:
                            "height: 32px; width: 100%; font-size: 16px; color: #3f3f3f;",
                        format: "prettyFormat"
                    },
                    cvv: {
                        id: "card-cvv",
                        placeholder: "xxx",
                        style:
                            "height: 32px; width: 100%; font-size: 16px; color: #3f3f3f;"
                    }
                });



                // window["alert"](`PaymentsCustomer: ${PaymentsCustomer}`);
                fattJs.showCardForm().then(handler => {
                    this.setState({
                        total,
                        formValues: {
                            customer_id: PaymentsCustomer || undefined,
                            firstname: FirstName,
                            lastname: LastName,
                            phone: BillPhone,
                            // email: BillEmail,
                            address_1: BillAddress1,
                            address_2: BillAddress2,
                            address_city: BillCity,
                            address_state: BillState,
                            address_country: BillCountry,
                            address_zip: BillZip,
                            total,
                        }
                    });
                });



                fattJs.on("card_form_complete", message => {
                    console.log("card_form_complete", message);
                    this.setState({ isPayButtonDisabled: false, cardType: message.cardType });
                });



                fattJs.on("card_form_incomplete", message => {
                    console.log("card_form_incomplete", message);
                    this.setState({ isPayButtonDisabled: true, cardType: message.cardType });
                });



                this.fattJs = fattJs;
            }
        }
    }



    handleFieldChange = event => {
        const { formValues } = this.state;
        const { name, value } = event.target;
        this.setState({ formValues: { ...formValues, [name]: value } });
    };



    getReaders = () => {
        getReaders().then((r) => {
            let selectedReader = null;
            if (r.readers && r.readers.length === 1) {
                selectedReader = r.readers[0];
            }
            this.setState({ ...this.state, readers: r.readers, selectedReader, status: `${r.readers.length} Readers Found.` });
        }).catch(err => {
            this.setState({ ...this.state, selectedReader: null, status: err.message });
        })
    }



    connectReader = (reader) => {
        this.setState({ connecting: true }, () => {
            connectReader({ ...reader }).then(r => {
                this.setState({ ...this.state, connecting: false, reader: true, readerStatus: null, status: r.message });
            }).catch(err => {
                this.setState({ ...this.state, connecting: false, readerStatus: null, status: err.message });
            })
        })
    }



    disconnectReader = () => {
        disconnectReader().then(r => {
            this.setState({ ...this.state, reader: false, readerStatus: null, status: r.message });
        }).catch(err => {
            this.setState({ ...this.state, readerStatus: null, status: err.message });
        })
    }



    initiatePayment = () => {
        const { formValues } = this.state;
        const { data } = this.props;
        const { InvoiceNumber, EstimateNumber } = data;
        this.setState({
            ...this.state,
            processing: true,
            complete: false,
            error: false,
            paymentStatus: null
        }, () => {
            const updater = OmniPlugin.addListener('onTransactionUpdate', (info) => {
                this.setState({ ...this.state, paymentStatus: info.message });
            });



            takePayment({ amount: formValues.total, tokenize: true, invoice: InvoiceNumber || EstimateNumber }).then(r => {
                this.setState({ ...this.state, status: null, complete: true, error: false }, () => {
                    updater.remove();
                    this.getFullTransaction(r);
                });
            }).catch(err => {
                this.setState({ ...this.state, status: null, complete: false, error: err.message }, () => updater.remove());
            })
        })
    }


    getFullTransaction = (transaction) => {
        const { data } = this.props;
        const { Co, Customer, Site, Invoice, InvoiceNumber, EstimateNumber, WorkOrder } = data;
        const { detail } = transaction;
        const { id } = detail;
        const request = axios.get(`${window["apiLocation"]}/api/Omni/Transaction?Co=${Co}&id=${encodeURIComponent(id)}`);
        request.then((response) => {
            this.setState({ ...this.state, status: null, completedTransaction: response.data }, () => {
                const t = response.data;
                const c = t.payment_method;
                const payment = {
                    Co,
                    Customer,
                    PaymentID: t.id,
                    PaymentType: 'CREDIT',
                    PaymentDateTime: t.created_at.replace(/-/g, "/"),
                    PaymentTotal: t.total,
                    PaymentBy: c.person_name,
                    PaymentMethodID: t.payment_method_id,
                    Memo: 'CP Credit / Debit Payment',
                    // Status: t.status,
                    Data: {
                        ErrMsg: null,
                        PaymentMethod: {
                            Co,
                            Customer,
                            PaymentMethodID: c.id,
                            PaymentMethodType: c.card_type,
                            CardholderName: c.person_Name,
                            CardLastFour: c.card_last_four,
                            CardExpirationDate: c.card_exp_datetime,
                            Nickname: c.nickname,
                        },
                        Allocations: [
                            {
                                Co,
                                Customer,
                                PaymentID: t.id,
                                Invoice,
                                InvoiceNumber,
                                EstimateNumber,
                                Amount: t.total,
                            }
                        ]
                    }
                };
                console.log('Payment Info: ', payment, transaction, response.data);
                if (Invoice || EstimateNumber) {
                    const request = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, payment);
                    request.then((response) => {
                        this.props.onPayment(response.data);
                    })
                } else {
                    this.props.onPayment(payment);
                }
            });
        }).catch((err) => {
            this.setState({ ...this.state, status: null, error: err.message });
        })
    }


    getFullTransactionByID = (id) => {
        const { data } = this.props;
        const { Co } = data;
        const request = axios.get(`${window["apiLocation"]}/api/Omni/Transaction?Co=${Co}&id=${encodeURIComponent(id)}`);
        request.then((response) => {
            this.setState({ ...this.state, status: null, completedTransaction: response.data }, () => {
                // const t = response.data;
                // const c = t.payment_method;
                // const payment = {
                //     Co,
                //     Customer,
                //     PaymentID: t.id,
                //     PaymentType: 'CREDIT',
                //     PaymentDateTime: t.created_at.replace(/-/g, "/"),
                //     PaymentTotal: t.total,
                //     PaymentBy: c.person_name,
                //     PaymentMethodID: t.payment_method_id,
                //     Memo: 'CP Credit / Debit Payment',
                //     // Status: t.status,
                //     Data: {
                //         ErrMsg: null,
                //         PaymentMethod: {
                //             Co,
                //             Customer,
                //             PaymentMethodID: c.id,
                //             PaymentMethodType: c.card_type,
                //             CardholderName: c.person_Name,
                //             CardLastFour: c.card_last_four,
                //             CardExpirationDate: c.card_exp_datetime,
                //             Nickname: c.nickname,
                //         },
                //         Allocations: [
                //             {
                //                 Co,
                //                 Customer,
                //                 PaymentID: t.id,
                //                 Invoice,
                //                 InvoiceNumber,
                //                 Amount: t.total,
                //             }
                //         ]
                //     }
                // };
                // console.log('Payment Info: ', payment, transaction, response.data);
                // if (Invoice) {
                //     const request = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, payment);
                //     request.then((response) => {
                //         this.props.onPayment(response.data);
                //     })
                // } else {
                //     this.props.onPayment(payment);
                // }
            });
        }).catch((err) => {
            this.setState({ ...this.state, status: null, error: err.message });
        })
    }


    syncPayment = () => {
        const { data } = this.props;
        const { Co, Customer, Site, Invoice, InvoiceNumber, EstimateNumber, WorkOrder } = data;
        const { completedTransaction, formValues } = this.state;
        const t = completedTransaction;
        const trn = t;
        let allocated = 0;
        if (trn && trn.sp_data) {
            allocated = _.sumBy(_.filter(trn.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount');
        };
        let ttl = 0;
        if (trn && !trn.is_voided) {
            ttl = trn.total - trn.total_refunded;
        }
        const available = ttl - allocated;
        const c = t.payment_method;
        const payment = {
            Co,
            Customer,
            PaymentID: t.id,
            PaymentType: 'CREDIT',
            PaymentDateTime: t.created_at.replace(/-/g, "/"),
            PaymentTotal: t.total,
            PaymentBy: c.person_name,
            PaymentMethodID: t.payment_method_id,
            Memo: 'Re-Sync - CNP Credit / Debit Payment',
            // Status: t.status,
            Data: {
                ErrMsg: null,
                PaymentMethod: {
                    Co,
                    Customer,
                    PaymentMethodID: c.id,
                    PaymentMethodType: c.card_type,
                    CardholderName: c.person_Name,
                    CardLastFour: c.card_last_four,
                    CardExpirationDate: c.card_exp_datetime,
                    Nickname: c.nickname,
                },
                Allocations: [
                    {
                        Co,
                        Customer,
                        PaymentID: t.id,
                        Invoice,
                        InvoiceNumber,
                        EstimateNumber,
                        Amount: (Number(formValues.total) >= available ? available : Number(formValues.total)),
                    }
                ]
            }
        };
        console.log('Payment Info: ', payment, completedTransaction);
        if (Invoice || EstimateNumber) {
            const request = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, payment);
            request.then((response) => {
                this.props.onPayment(response.data);
                this.props.onClose();
            }).catch((err) => {
                this.setState({ error: err });
            })
        } else {
            this.props.onPayment(payment);
            this.props.onClose();
        }
    }

    addPayment = () => {
        const { data } = this.props;
        const { formValues, Memo, PaymentType, Check } = this.state;
        const { Co, Customer, Site, Invoice, InvoiceNumber, EstimateNumber, WorkOrder, } = data;
        const PaymentID = guid();
        const payment = {
            Co,
            Customer,
            PaymentID,
            PaymentType: PaymentType,
            PaymentDateTime: new Date(),
            PaymentTotal: Number(formValues.total),
            PaymentBy: `${formValues.firstname} ${formValues.lastname}`,
            // PaymentMethodID: t.payment_method_id,
            Check,
            Memo: Memo || `${PaymentType} Payment`,
            // Status: t.status,
            Data: {
                ErrMsg: null,
                /*PaymentMethod: {
                    Co,
                    Customer,
                    PaymentMethodID: c.id,
                    PaymentMethodType: c.card_type,
                    CardholderName: c.person_Name,
                    CardLastFour: c.card_last_four,
                    CardExpirationDate: c.card_exp_datetime,
                    Nickname: c.nickname,
                },*/
                Allocations: [
                    {
                        Co,
                        Customer,
                        PaymentID,
                        Invoice,
                        InvoiceNumber,
                        EstimateNumber,
                        Amount: Number(formValues.total),
                    }
                ]
            }
        };
        console.log('Payment Info: ', payment);
        if (Invoice || EstimateNumber) {
            const request = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, payment);
            request.then((response) => {
                this.props.onPayment(response.data);
                this.props.onClose();
            })
        } else {
            payment.Data.Payment = _.cloneDeepWith(payment)
            this.props.onPayment(payment);
            this.props.onClose();
        }
    }

    handlePay = () => {
        const { PaymentType, cardMethod, formValues } = this.state;
        const { data } = this.props;
        const { Co, Customer, Site, Invoice, InvoiceNumber, EstimateNumber, WorkOrder } = data;

        if (this.fattJs) {

            this.setState({
                tokenizedPaymentMethod: null,
                completedTransaction: null,
                isPayButtonDisabled: true,
                loading: true,
                error: null,
            });
            const extraDetails = {
                ...formValues,
                method: 'card',
                validate: false,
                send_receipt: false,
                meta: {
                    reference: InvoiceNumber || EstimateNumber,
                    otherField1: Co,
                    otherField2: WorkOrder,
                }
            };



            this.fattJs.pay(extraDetails).then(completedTransaction => {
                console.log(
                    "successfully paid. here's the transaction: ",
                    completedTransaction
                );
                this.setState({
                    completedTransaction,
                    isPayButtonDisabled: false,
                    loading: false
                }, () => {



                    const t = completedTransaction;
                    for (var i = 0; i < t.child_transactions.length; i++) {
                        const p = t.child_transactions[i];
                        const c = p.payment_method;
                        const payment = {
                            Co,
                            Customer,
                            PaymentID: p.id,
                            PaymentType: 'CREDIT',
                            PaymentDateTime: p.created_at.replace(/-/g, "/"),
                            PaymentTotal: p.total,
                            PaymentBy: c.person_name,
                            PaymentMethodID: p.payment_method_id,
                            Memo: 'CNP Credit / Debit Payment',
                            PaymentsCustomer: p.customer_id,
                            PaymentsInvoice: p.invoice_id,
                            // Status: t.status,
                            Data: {
                                ErrMsg: null,
                                PaymentMethod: {
                                    Co,
                                    Customer,
                                    PaymentMethodID: c.id,
                                    PaymentMethodType: c.card_type,
                                    PaymentsCustomer: p.customer_id,
                                    CardholderName: c.person_Name,
                                    CardLastFour: c.card_last_four,
                                    CardExpirationDate: c.card_exp_datetime,
                                    Nickname: c.nickname,
                                },
                                Allocations: [
                                    {
                                        Co,
                                        Customer,
                                        PaymentID: p.id,
                                        Invoice,
                                        InvoiceNumber,
                                        EstimateNumber,
                                        Amount: p.total,
                                        PaymentsInvoice: p.invoice_id,
                                    }
                                ]
                            }
                        };
                        if (Invoice || EstimateNumber) {
                            const request = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, payment);
                            request.then((response) => {
                                this.props.onPayment(response.data);
                            })
                        } else {
                            this.props.onPayment(payment);
                        }
                        // total -= p.total;
                        // this.setState({...this.state, total});
                    }
                });
            }).catch((error) => {
                window["warn"]('Payment Error: ', error);
                this.setState({
                    isPayButtonDisabled: false,
                    loading: false,
                    error: {
                        message: error.message || error.payment_attempt_message || 'Unable to process payment'
                    }
                });
            });
        } else {
            this.setState({ error: { message: 'Manual Entry unavailable.' } })
        }
    };

    handleTokenizedPay = () => {
        const { cardOnFile } = this.state;
        const { paymentMethods } = this.props;
        const card = (paymentMethods && cardOnFile) ? _.find(paymentMethods, { PaymentMethodID: cardOnFile }) : null;
        const { data } = this.props;
        const { Co, Customer, Site, Invoice, InvoiceNumber, EstimateNumber, WorkOrder, PaymentsInvoice, PaymentsCustomer, total } = data;
        const { paymentsInvoice } = this.state;
        this.setState({
            completedTransaction: null,
            isPayButtonDisabled: true,
            loading: true,
            error: null,
        });



        const { formValues } = this.state;
        var payment = {
            Co,
            Customer,
            PaymentMethod: card,
            Invoice: {
                Co,
                customer_id: card.PaymentsCustomer,
                id: paymentsInvoice || PaymentsInvoice,
                total,
                meta: {
                    reference: InvoiceNumber || EstimateNumber,
                    otherField1: Co,
                    otherField2: WorkOrder,
                }
            },
            Payment: {
                Co,
                payment_method_id: card.PaymentMethodID,
                apply_balance: parseFloat(formValues.total).toFixed(2)
            }
        };
        var request = axios.post(`${window["apiLocation"]}/api/Omni/ProcessPayment`, payment);

        function hasPaymentError(data) {
            const payment = data.Payment;

            if (!payment) {
                return true;
            }

            if (payment.error) {
                return true;
            }

            if (payment.payment_attempt_failed) {
                return true;
            }

            const childTransactions = payment.child_transactions

            if (!childTransactions || childTransactions.length === 0) {
                return true;
            }

            if (!childTransactions[0].success) {
                return true;
            }

            if (payment.status === 'ATTEMPTED') {
                return true;
            }

            return false;
        }

        request.then((response) => {
            if (hasPaymentError(response.data)) {
                this.setState({
                    completedTransaction: null,
                    isPayButtonDisabled: false,
                    loading: false,
                    paymentsInvoice: response.data.Invoice.id,
                    error: {
                        message:
                            response.data.Payment.error ? `Processor error: ${JSON.stringify(response.data.Payment.error)}` :
                                (response.data.Payment.payment_attempt_message && response.data.Payment.payment_attempt_message.length > 0) ? response.data.Payment.payment_attempt_message :
                                    (response.data.Payment.child_transactions[0] && response.data.Payment.child_transactions[0].message && response.data.Payment.child_transactions[0].message.length > 0) ? response.data.Payment.child_transactions[0].message :
                                        'Error Processing Payment - please contact customer support for assistance.'
                    }
                });
            } else {
                // if (response.data.Payment && response.data.Payment.payment_attempt_failed) {
                //     this.setState({
                //         completedTransaction: null,
                //         isPayButtonDisabled: false,
                //         loading: false,
                //         paymentsInvoice: response.data.Invoice.id,
                //         error: { message: response.data.Payment.payment_attempt_message }
                //     });
                // } else {
                this.setState({
                    completedTransaction: response.data.Payment,
                    isPayButtonDisabled: false,
                    loading: false
                }, () => {
                    const p = response.data.Payment.child_transactions[0];
                    const c = response.data.PaymentMethod;
                    const i = response.data.Invoice;
                    const confirmation = {
                        Co,
                        Customer,
                        PaymentID: p.id,
                        PaymentType: 'CREDIT',
                        PaymentDateTime: p.created_at.replace(/-/g, "/"),
                        PaymentTotal: p.total,
                        PaymentBy: c.CardholderName,
                        PaymentMethodID: p.payment_method_id,
                        Memo: 'CNP Credit / Debit Payment',
                        PaymentsCustomer: p.customer_id,
                        PaymentsInvoice: i.id,
                        // Status: t.status,
                        Data: {
                            ErrMsg: null,
                            Allocations: [
                                {
                                    Co,
                                    Customer,
                                    PaymentID: p.id,
                                    Invoice,
                                    InvoiceNumber,
                                    EstimateNumber,
                                    Amount: p.total,
                                    PaymentsInvoice: i.id,
                                }
                            ]
                        }
                    };
                    if (Invoice || EstimateNumber) {
                        const req = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, confirmation);
                        req.then((res) => {
                            this.props.onPayment({
                                ...res.data,
                                Data: {
                                    ...res.data.Data,
                                    PaymentMethod: c
                                }
                            });
                        })
                    } else {
                        this.props.onPayment({
                            ...confirmation,
                            Data: {
                                ...confirmation.Data,
                                PaymentMethod: c
                            }
                        });
                    }
                });
            }
        })
    }

    handleManualPay = () => {
        const { data } = this.props;
        const { Co, Customer, Site, Invoice, InvoiceNumber, EstimateNumber, WorkOrder, FirstName, LastName, BillPhone, BillEmail, BillAddress1, BillAddress2, BillCity, BillState, BillCountry, BillZip, PaymentsInvoice, PaymentsCustomer, total } = data;
        const { paymentsInvoice } = this.state;

        if (this.fattJs) {
            this.setState({
                tokenizedPaymentMethod: null,
                completedTransaction: null,
                isPayButtonDisabled: true,
                loading: true,
                error: null
            });



            const { formValues } = this.state;
            const extraDetails = {
                ...formValues,
                method: 'card',
                validate: false,
            };



            this.fattJs.tokenize(extraDetails).then(tokenizedPaymentMethod => {
                var payment = {
                    Co,
                    Customer,
                    PaymentMethod: {
                        Co,
                        Customer,
                        PaymentsCustomer: tokenizedPaymentMethod.customer_id,
                        PaymentMethodID: tokenizedPaymentMethod.id,
                        PaymentMethodType: tokenizedPaymentMethod.card_type,
                        CardholderName: tokenizedPaymentMethod.person_name,
                        CardLastFour: tokenizedPaymentMethod.card_last_four,
                        CardExpirationDate: tokenizedPaymentMethod.card_exp_datetime,
                        Nickname: tokenizedPaymentMethod.nickname,
                    },
                    Invoice: {
                        Co,
                        customer_id: tokenizedPaymentMethod.customer_id,
                        id: paymentsInvoice || PaymentsInvoice,
                        total,
                        meta: {
                            reference: InvoiceNumber || EstimateNumber,
                            otherField1: Co,
                            otherField2: WorkOrder,
                        }
                    },
                    Payment: {
                        Co,
                        payment_method_id: tokenizedPaymentMethod.id,
                        apply_balance: parseFloat(formValues.total).toFixed(2)
                    }
                };
                var request = axios.post(`${window["apiLocation"]}/api/Omni/ProcessPayment`, payment);
                request.then((response) => {
                    if (response.data.Payment && (response.data.Payment.error || response.data.Payment.payment_attempt_failed || !response.data.Payment.child_transactions[0] || !response.data.Payment.child_transactions[0].success || response.data.Payment.status === "ATTEMPTED")) {
                        this.setState({
                            completedTransaction: null,
                            isPayButtonDisabled: false,
                            loading: false,
                            paymentsInvoice: response.data.Invoice.id,
                            error: {
                                message:
                                    response.data.Payment.error ? JSON.stringify(response.data.Payment.error) :
                                        (response.data.Payment.payment_attempt_message && response.data.Payment.payment_attempt_message.length > 0) ? response.data.Payment.payment_attempt_message :
                                            (response.data.Payment.child_transactions[0] && response.data.Payment.child_transactions[0].message && response.data.Payment.child_transactions[0].message.length > 0) ? response.data.Payment.child_transactions[0].message :
                                                'Error Processing Payment - please contact customer support for assistance.'
                            }
                        });
                    } else {
                        this.setState({
                            completedTransaction: response.data.Payment,
                            isPayButtonDisabled: false,
                            loading: false
                        }, () => {
                            const p = response.data.Payment.child_transactions[0];
                            const c = response.data.PaymentMethod;
                            const i = response.data.Invoice;
                            const confirmation = {
                                Co,
                                Customer,
                                PaymentID: p.id,
                                PaymentType: 'CREDIT',
                                PaymentDateTime: p.created_at.replace(/-/g, "/"),
                                PaymentTotal: p.total,
                                PaymentBy: c.CardholderName,
                                PaymentMethodID: p.payment_method_id,
                                Memo: 'CNP Credit / Debit Payment',
                                PaymentsCustomer: p.customer_id,
                                PaymentsInvoice: i.id,
                                // Status: t.status,
                                Data: {
                                    ErrMsg: null,
                                    Allocations: [
                                        {
                                            Co,
                                            Customer,
                                            PaymentID: p.id,
                                            Invoice,
                                            InvoiceNumber,
                                            EstimateNumber,
                                            Amount: p.total,
                                            PaymentsInvoice: i.id,
                                        }
                                    ]
                                }
                            };
                            if (Invoice || EstimateNumber) {
                                const req = axios.post(`${window["apiLocation"]}/api/InvoicePayment`, confirmation);
                                req.then((res) => {
                                    this.props.onPayment({
                                        ...res.data,
                                        Data: {
                                            ...res.data.Data,
                                            PaymentMethod: c
                                        }
                                    });
                                })
                            } else {
                                this.props.onPayment({
                                    ...confirmation,
                                    Data: {
                                        ...confirmation.Data,
                                        PaymentMethod: c
                                    }
                                });
                            }
                        });
                    }
                })
            }).catch((error) => {
                this.setState({
                    isPayButtonDisabled: false,
                    loading: false,
                    error
                });
            });
        } else {
            this.setState({ error: { message: 'Manual Entry unavailable.' } })
        }

    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };
    renderCustomerDetails = () => {
        const { formValues } = this.state;



        return (
            <div className="w-full flex">
                <div className="w-full flex">
                    <TextField
                        className="w-full bg-white mr-4"
                        label="First Name"
                        id="first-name"
                        name="firstname"
                        placeholder="First Name"
                        onChange={this.handleFieldChange}
                        value={formValues.firstname || ""}
                        variant="outlined"
                        InputProps={{
                            className: "h-48 p-8 pl-12",
                            startAdornment: <InputAdornment position="start"><Icon>account_box</Icon></InputAdornment>,
                        }}
                    />
                    <TextField
                        className="w-full bg-white ml-4"
                        label="Last Name"
                        id="last-name"
                        name="lastname"
                        placeholder="Last Name"
                        onChange={this.handleFieldChange}
                        value={formValues.lastname || ""}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            className: "h-48 p-8",
                        }}
                    />
                </div>
            </div>
        );
    };



    renderExpField = () => {
        const { formValues, cardType } = this.state;
        return (
            <div className="flex">
                <input
                    className="h-32 w-56 text-center"
                    name="month"
                    maxLength="2"
                    min={1}
                    max={12}
                    placeholder="MM"
                    type="number"
                    onChange={this.handleFieldChange}
                    value={formValues.month || ""}
                />
                <div className="h-32 pt-6 text-center">/</div>
                <input
                    className="h-32 w-72 text-center"
                    name="year"
                    maxLength="4"
                    placeholder="YYYY"
                    type="number"
                    min={new Date().getFullYear()}
                    onChange={this.handleFieldChange}
                    value={formValues.year || ""}
                />
            </div>
        );
    }



    renderCardDetails = () => {
        const { cardType, editAmount, formValues } = this.state;



        return (
            <div className={`w-full ${editAmount ? 'mb-12' : ''}`}>
                <div id="card-element" className="w-full">
                    <div className="w-full flex">
                        <TextField
                            className="w-full mt-12 bg-white"
                            label="Card Number"
                            id="card-number"
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    textShadow: '0px 0px 4px white'
                                }
                            }}
                            InputProps={{
                                inputComponent: "div",
                                inputProps: {
                                    className: "h-32 p-8"
                                },
                                startAdornment: <InputAdornment position="start">{cardType && ['visa', 'master', 'american_express', 'discover'].indexOf(cardType) > -1 ? <img className="w-32" src={`assets/images/cards/${cardType}.png`} /> : <Icon>payment</Icon>}</InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="w-full sm:flex">
                        <TextField
                            className="w-full sm:w-3/5 mt-12 mr-8 bg-white"
                            label="Card Expiration"
                            id="card-expire"
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    textShadow: '0px 0px 4px white'
                                }
                            }}
                            InputProps={{
                                className: "h-48 p-8 pl-12",
                                inputComponent: this.renderExpField,
                                inputProps: {
                                    className: "h-32 p-8",
                                },
                                startAdornment: <InputAdornment position="start"><Icon>today</Icon></InputAdornment>,
                            }}
                        />
                        <div className="w-full flex">
                            <TextField
                                className="w-1/2 mt-12 mr-8 bg-white"
                                label="Security Code"
                                id="card-cvv"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        textShadow: '0px 0px 4px white'
                                    }
                                }}
                                InputProps={{
                                    className: "pl-12",
                                    inputComponent: "div",
                                    inputProps: {
                                        className: "h-32 p-8"
                                    },
                                    startAdornment: <InputAdornment position="start" className="mr-0"><Icon>lock</Icon></InputAdornment>,
                                }}
                            />
                            <TextField
                                className="w-1/2 mt-12 bg-white"
                                label="Zip Code"
                                id="zip-code"
                                name="address_zip"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        textShadow: '0px 0px 4px white'
                                    }
                                }}
                                value={formValues.address_zip || ''}
                                onChange={this.handleFieldChange}
                                InputProps={{
                                    className: "pl-12",
                                    inputProps: {
                                        className: "h-32 p-8"
                                    },
                                    startAdornment: <InputAdornment position="start" className="mr-0"><Icon>location_on</Icon></InputAdornment>,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };



    renderMessage = () => {
        const { completedTransaction, tokenizedPaymentMethod, error, status, readerStatus } = this.state;
        const success = completedTransaction || tokenizedPaymentMethod;



        if (success && !error) {
            return (
                <div className="success">
                    {completedTransaction
                        ? `${completedTransaction.id
                        }`
                        : ""}
                    {tokenizedPaymentMethod
                        ? `${tokenizedPaymentMethod.id
                        }`
                        : ""}
                </div>
            );
        } else {
            return (
                <div className="error">
                    {error ? `${error.message}` : readerStatus || status}
                </div>
            );
        }
    };



    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }



    toggleCardMethod(cardMethod) {
        if (this.state.cardMethod !== cardMethod) {
            switch (cardMethod) {
                case 'S': {
                    this.setState({ ...this.state, cardMethod });
                }
                    break;
                case 'K': {
                    this.setState({ ...this.state, cardMethod }, this.initPaymentForm);
                }
                    break;
            }
        }
    }



    render() {
        const { completedTransaction, isPayButtonDisabled, loading, formValues, total, editAmount, reader, readers, cardOnFile } = this.state;
        const { classes, open, paymentMethods, data } = this.props;
        const { PaymentsCustomer } = data;

        const card = (paymentMethods && cardOnFile) ? _.find(paymentMethods, { PaymentMethodID: cardOnFile }) : null;
        const cardType = card ? card.PaymentMethodType : null;
        const paymentTypes = { visa: "Visa", mastercard: "MasterCard", master: "MasterCard", discover: "Discover", american_express: "American Express", amex: "American Express", am_ex: "American Express" };

        const trn = this.state.completedTransaction;
        let allocated = 0;
        if (trn && trn.sp_data) {
            allocated = _.sumBy(_.filter(trn.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount');
        };
        let ttl = 0;
        if (trn && !trn.is_voided) {
            ttl = trn.total - trn.total_refunded;
        }
        let available = ttl - allocated;

        available = (Number(formValues.total) >= available ? available : Number(formValues.total));



        return (
            <Media query="(min-width: 1000px)">
                {matches =>
                    <Dialog
                        classes={{ paper: classNames(classes.root, "rounded-lg", !matches ? "m-0 rounded-none full-screen-dialog" : "") }}
                        // disableBackdropClick
                        // disableEscapeKeyDown
                        fullScreen={!matches}
                        maxWidth="sm"
                        fullWidth
                        TransitionComponent={!matches ? SlideUp : Grow}
                        onClose={() => this.props.onClose()}
                        aria-labelledby="payment-dialog-title"
                        open={open}
                    >
                        <div className="dialog-header">
                            <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-20" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">payment</Icon>Payment Details</DialogTitle>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                color: '#333'
                            }}
                                onClick={() => {
                                    this.props.onClose();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                        <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
                            <div className={classNames("w-full", this.state.PaymentType === "SEARCH" && !this.state.completedTransaction ? "" : "payment")}>
                                <TextField
                                    className="w-full bg-white mb-12"
                                    label="Form of Payment"
                                    id="type"
                                    name="PaymentType"
                                    variant="outlined"
                                    value={this.state.PaymentType || ''}
                                    onChange={(e) => this.setState({ ...this.state, PaymentType: e.target.value, Check: null, cardOnFile: null, error: null })}
                                    margin="dense"
                                    InputLabelProps={{
                                        style: {
                                            textShadow: '0px 0px 4px white'
                                        }
                                    }}
                                    select
                                >
                                    {PaymentsCustomer && this.state.paymentsToken && paymentMethods && paymentMethods.length > 0 &&
                                        <MenuItem value="ON-FILE">
                                            Card on File
                                        </MenuItem>
                                    }
                                    {PaymentsCustomer && this.state.paymentsToken &&
                                        <MenuItem value="CREDIT">
                                            Credit / Debit
                                        </MenuItem>
                                    }
                                    <MenuItem value="CHECK">
                                        Check
                                    </MenuItem>
                                    <MenuItem value="CASH">
                                        Cash
                                    </MenuItem>
                                    <MenuItem value="OTHER">
                                        Other
                                    </MenuItem>
                                    {PaymentsCustomer && this.state.paymentsToken &&
                                        <MenuItem value="SEARCH">
                                            Search
                                        </MenuItem>
                                    }
                                </TextField>
                                {this.state.PaymentType === 'ON-FILE' && paymentMethods && paymentMethods.length > 0 &&
                                    <FormControl classes={{ root: "mt--12 px-6 w-full" }} component="fieldset">
                                        {/* <FormLabel component="legend">Payment Method</FormLabel> */}
                                        <RadioGroup
                                            aria-label="payment-method"
                                            name="cardOnFile"
                                            value={this.state.cardOnFile || ''}
                                            onChange={(e) => this.setState({ cardOnFile: e.target.value, isPayButtonDisabled: false })}
                                        >
                                            {
                                                paymentMethods.map((value) => {
                                                    const exp = new Date(value.CardExpirationDate);
                                                    if (!card || this.state.cardOnFile === value.PaymentMethodID)
                                                        return (
                                                            <FormControlLabel onClick={(e) => { if (value.PaymentMethodID === cardOnFile) { e.stopPropagation(); this.setState({ cardOnFile: null, isPayButtonDisabled: true }) } }} classes={{ label: "text-12 font-bold w-full", root: "mr-0 mb-4" }} value={value.PaymentMethodID} control={<Radio />} label={
                                                                <div className="w-full flex justify-between pt-4">
                                                                    <div className="flex">
                                                                        <img src={`assets/images/cards/${value.PaymentMethodType}.png`} className="w-20 mr-4" style={{ height: 14 }} />
                                                                        <div className={classNames("mr-16")}>{`${paymentTypes[value.PaymentMethodType]} ending in ${value.CardLastFour}`}</div>
                                                                    </div>
                                                                    <div className="text-right text-12 font-bold">
                                                                        Exp. {`${exp.getMonth() + 1}/${exp.getFullYear()}`}
                                                                    </div>
                                                                </div>
                                                            } />
                                                        );
                                                })
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                    // <TextField
                                    //     className="w-full bg-white mb-12 mt-0"
                                    //     label="Payment Method"
                                    //     id="type"
                                    //     name="cardOnFile"
                                    //     variant="outlined"
                                    //     value={this.state.cardOnFile || ''}
                                    //     onChange={(e) => this.setState({ ...this.state, cardOnFile: e.target.value })}
                                    //     margin="dense"
                                    //     InputLabelProps={{
                                    //         style: {
                                    //             textShadow: '0px 0px 4px white'
                                    //         }
                                    //     }}
                                    //     select
                                    // >
                                    //     <MenuItem value={null}>
                                    //         None
                                    //     </MenuItem>
                                    //     {paymentMethods.map((value) => {
                                    //         return (
                                    //             <MenuItem value={value.PaymentMethodID}>
                                    //                 {value.Nickname}
                                    //             </MenuItem>
                                    //         );
                                    //     })}
                                    // </TextField>
                                }
                                {this.state.PaymentType === "SEARCH" &&
                                    <div className="w-full">
                                        <TextField
                                            className="w-full bg-white mr-4 mb-12 mt-0"
                                            label="Payment ID"
                                            id="paymentID"
                                            name="paymentID"
                                            value={this.state.paymentID || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            disabled={Boolean(this.state.completedTransaction)}
                                            InputProps={{
                                                endAdornment: (this.state.paymentID ? <InputAdornment placement="end"><Icon onClick={() => this.setState({ completedTransaction: null, paymentID: null })} className="cursor-pointer" color="action">cancel</Icon></InputAdornment> : undefined)
                                            }}
                                        />

                                        {this.state.completedTransaction && this.state.completedTransaction.payment_method &&
                                            <React.Fragment>
                                                <div className="w-full flex">
                                                    <div className="w-full flex">
                                                        <TextField
                                                            className="w-full bg-white mr-4"
                                                            label="Cardholder"
                                                            id="paymentBy"
                                                            name="PaymentBy"
                                                            value={this.state.completedTransaction.payment_method.person_name || ''}
                                                            variant="outlined"
                                                            InputProps={{
                                                                className: "h-48 p-8 pl-12",
                                                                startAdornment: <InputAdornment position="start"><Icon>account_box</Icon></InputAdornment>,
                                                            }}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`w-full`}>
                                                    <div id="card-element" className="w-full">
                                                        <div className="w-full flex">
                                                            <TextField
                                                                className="w-full mt-12 bg-white"
                                                                label="Card Number"
                                                                id="card-number"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    style: {
                                                                        textShadow: '0px 0px 4px white'
                                                                    }
                                                                }}
                                                                value={`**** **** **** ${this.state.completedTransaction.payment_method.card_last_four}`}
                                                                disabled={true}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        className: "h-32 p-8"
                                                                    },
                                                                    startAdornment: <InputAdornment position="start">{this.state.completedTransaction.payment_method.card_type && <img className="w-32" src={`assets/images/cards/${this.state.completedTransaction.payment_method.card_type}.png`} />}</InputAdornment>,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="w-full flex mb-12">
                                                            <TextField
                                                                className="w-3/5 mt-12 mr-8 bg-white"
                                                                label="Card Expiration"
                                                                id="card-expire"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    style: {
                                                                        textShadow: '0px 0px 4px white'
                                                                    }
                                                                }}
                                                                disabled={true}
                                                                InputProps={{
                                                                    className: "h-48 p-8 pl-12",
                                                                    inputProps: {
                                                                        className: "h-32 p-8",
                                                                    },
                                                                    startAdornment: <InputAdornment position="start"><Icon>today</Icon></InputAdornment>,
                                                                }}
                                                                value={moment(this.state.completedTransaction.payment_method.card_exp_datetime).format("MM/YYYY")}
                                                            />
                                                            <TextField
                                                                className="w-2/5 my-12 bg-white"
                                                                label="Security Code"
                                                                variant="outlined"
                                                                value="***"
                                                                InputLabelProps={{
                                                                    style: {
                                                                        textShadow: '0px 0px 4px white'
                                                                    }
                                                                }}
                                                                disabled={true}
                                                                InputProps={{
                                                                    className: "pl-12",
                                                                    inputProps: {
                                                                        className: "h-32 p-8"
                                                                    },
                                                                    startAdornment: <InputAdornment position="start" className="mr-0"><Icon>lock</Icon></InputAdornment>,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }

                                        {!this.state.completedTransaction &&
                                            <Button
                                                variant="contained"
                                                className={"w-full mb-12"}
                                                color={"primary"}
                                                onClick={() => this.getFullTransactionByID(this.state.paymentID)}
                                                disabled={!this.state.paymentID || this.state.paymentID.length < 16}
                                            >
                                                <Icon className="mr-8">search</Icon>
                                                Find Payment
                                            </Button>
                                        }
                                        {!this.state.completedTransaction &&
                                            <PaymentsList
                                                customer={PaymentsCustomer}
                                                onSelected={
                                                    (completedTransaction, available) => {
                                                        // window["alert"](`Transaction ID: ${completedTransaction.id}, Available: ${this.formatDollars(available)}`);
                                                        if (available > 0)
                                                            this.setState({ completedTransaction, paymentID: completedTransaction.id });
                                                    }
                                                }
                                            />
                                        }

                                        {this.state.completedTransaction &&
                                            <Button
                                                variant="contained"
                                                className={"w-full"}
                                                color={"primary"}
                                                onClick={this.syncPayment}
                                                disabled={!this.state.completedTransaction}
                                            >
                                                <Icon className="mr-8">cloud_upload</Icon>
                                                Sync Payment{` - ${this.formatDollars(available)}`}
                                            </Button>
                                        }
                                    </div>
                                }
                                {this.state.PaymentType === 'ON-FILE' && paymentMethods && paymentMethods.length > 0 && card &&
                                    <React.Fragment>
                                        <div className="w-full flex">
                                            <div className="w-full flex">
                                                <TextField
                                                    className="w-full bg-white mr-4"
                                                    label="Cardholder"
                                                    id="cardHolderName"
                                                    name="CardholderName"
                                                    value={card.CardholderName || card.Nickname.split(':')[1].split('(')[0].trim() || ''}
                                                    variant="outlined"
                                                    InputProps={{
                                                        className: "h-48 p-8 pl-12",
                                                        startAdornment: <InputAdornment position="start"><Icon>account_box</Icon></InputAdornment>,
                                                    }}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className={`w-full`}>
                                            <div id="card-element" className="w-full">
                                                <div className="w-full flex">
                                                    <TextField
                                                        className="w-full mt-12 bg-white"
                                                        label="Card Number"
                                                        id="card-number"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            }
                                                        }}
                                                        value={card.CardLastFour ? `**** **** **** ${card.CardLastFour}` : '**** **** **** ****'}
                                                        disabled={true}
                                                        InputProps={{
                                                            inputProps: {
                                                                className: "h-32 p-8"
                                                            },
                                                            startAdornment: <InputAdornment position="start">{card && card.PaymentMethodType && ['visa', 'mastercard', 'american_express', 'discover'].indexOf(card.PaymentMethodType) > -1 ? <img className="w-32" src={`assets/images/cards/${cardType}.png`} /> : <Icon>payment</Icon>}</InputAdornment>,
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-full flex mb-12">
                                                    <TextField
                                                        className="w-3/5 mt-12 mr-8 bg-white"
                                                        label="Card Expiration"
                                                        id="card-expire"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            }
                                                        }}
                                                        disabled={true}
                                                        InputProps={{
                                                            className: "h-48 p-8 pl-12",
                                                            inputProps: {
                                                                className: "h-32 p-8",
                                                            },
                                                            startAdornment: <InputAdornment position="start"><Icon>today</Icon></InputAdornment>,
                                                        }}
                                                        value={moment(card.CardExpirationDate).format("MM/YYYY")}
                                                    />
                                                    <TextField
                                                        className="w-2/5 mt-12 bg-white"
                                                        label="Security Code"
                                                        variant="outlined"
                                                        value="***"
                                                        InputLabelProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            }
                                                        }}
                                                        disabled={true}
                                                        InputProps={{
                                                            className: "pl-12",
                                                            inputProps: {
                                                                className: "h-32 p-8"
                                                            },
                                                            startAdornment: <InputAdornment position="start" className="mr-0"><Icon>lock</Icon></InputAdornment>,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {this.state.PaymentType === 'CREDIT' && this.state.paymentsToken && /*window.cordova*/false &&
                                    <React.Fragment>
                                        <div className={`w-full flex ${this.state.cardMethod !== "S" && 'mb-12'}`}>
                                            <Button
                                                variant="contained"
                                                className={classNames("w-full", this.state.cardMethod !== "S" && classes.approved)}
                                                color={this.state.cardMethod === "S" ? "secondary" : "primary"}
                                                onClick={() => this.toggleCardMethod("S")}
                                            >
                                                <Icon className="mr-8">payment</Icon>
                                                Swipe
                                            </Button>
                                            <div className="min-w-8 min-h-8"></div>
                                            <Button
                                                variant="contained"
                                                className={classNames("w-full", this.state.cardMethod !== "K" && classes.approved)}
                                                color={this.state.cardMethod === "K" ? "secondary" : "primary"}
                                                onClick={() => this.toggleCardMethod("K")}
                                            >
                                                <Icon className="mr-8">keyboard</Icon>
                                                Manual
                                            </Button>
                                        </div>
                                        {this.state.connected && !reader && this.state.cardMethod === "S" &&
                                            <TextField
                                                className="w-full bg-white mt-12"
                                                label="Select Reader"
                                                id="reader"
                                                variant="outlined"
                                                value={this.state.selectedReader ? this.state.selectedReader.name : ''}
                                                onChange={(e) => {
                                                    if (e.target.value === "+") {
                                                        this.getReaders();
                                                    } else {
                                                        this.setState({ ...this.state, selectedReader: _.find(readers, { name: e.target.value }) })
                                                    }
                                                }}
                                                margin="dense"
                                                InputLabelProps={{
                                                    style: {
                                                        textShadow: '0px 0px 4px white'
                                                    }
                                                }}
                                                select
                                            >
                                                <MenuItem value={null}>
                                                    None
                                                </MenuItem>
                                                {readers && readers.map((value) => {
                                                    return (
                                                        <MenuItem value={value.name}>
                                                            {value.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                                <MenuItem value={"+"}>
                                                    <Icon className="mr-4">refresh</Icon>
                                                    Refresh List
                                                </MenuItem>
                                            </TextField>



                                        }
                                    </React.Fragment>
                                }
                                {this.state.PaymentType == "CREDIT" && this.state.paymentsToken && this.state.cardMethod === "K" &&
                                    <React.Fragment>
                                        {this.renderCustomerDetails()}
                                        {this.renderCardDetails()}
                                    </React.Fragment>
                                }
                                {this.state.PaymentType == "CHECK" &&
                                    <React.Fragment>
                                        <TextField
                                            className={"w-full bg-white mb-12"}
                                            label="Check #"
                                            id="check"
                                            name="Check"
                                            value={this.state.Check || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    </React.Fragment>
                                }
                                {['CHECK', 'CASH', 'OTHER'].indexOf(this.state.PaymentType) > -1 &&
                                    <React.Fragment>
                                        <TextField
                                            className={"w-full bg-white mb-12"}
                                            label="Memo"
                                            id="memo"
                                            name="Memo"
                                            value={this.state.Memo || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            required={['OTHER'].indexOf(this.state.PaymentType) > -1}
                                            fullWidth
                                        />
                                    </React.Fragment>
                                }
                                <ConfirmationDialog
                                    open={this.state.processing}
                                    title={this.state.complete ? <div><Icon className="mr-6 align-middle mb-4">check_circle</Icon>Success!</div> : this.state.error ? <div><Icon className="mr-6 align-middle mb-4">error</Icon>Error</div> : <div><CircularProgress color="primary" className="mr-12 align-middle mb-6" style={{ width: 20, height: 20 }} />Processing</div>}
                                    content={this.state.complete ? "Payment was successfully processed." : this.state.error ? this.state.error : <div className="w-full pt-8 pb-8">{this.state.paymentStatus || `Processing card payment - Hang tight...`}</div>}
                                    confirmText={this.state.complete ? "Done" : "Cancel"}
                                    onConfirm={() => this.setState({ ...this.state, processing: false })}
                                />


                                {this.state.PaymentType && (card || this.state.PaymentType !== "ON-FILE") &&
                                    <React.Fragment>
                                        {editAmount &&
                                            <TextField
                                                className="w-full bg-white mt-12"
                                                label="Payment Amount"
                                                id="amount"
                                                name="total"
                                                variant="outlined"
                                                value={formValues.total}
                                                onChange={(e) => { if (Number(e.target.value) <= total) { this.handleFieldChange(e) } }}
                                                InputLabelProps={{
                                                    style: {
                                                        textShadow: '0px 0px 4px white'
                                                    }
                                                }}
                                                margin="dense"
                                                InputProps={{
                                                    max: total,
                                                    type: 'number',
                                                    startAdornment: formValues.total && <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                        }
                                        {this.state.PaymentType !== "SEARCH" &&
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.editAmount}
                                                        classes={{ root: classes.checkbox }}
                                                        onChange={() => this.setState({ ...this.state, editAmount: !this.state.editAmount, formValues: { ...formValues, total } })}
                                                        disabled={completedTransaction || loading}
                                                    />
                                                }
                                                label="Partial Payment"
                                            />
                                        }
                                    </React.Fragment>
                                }

                                {['CHECK', 'CASH', 'OTHER'].indexOf(this.state.PaymentType) > -1 &&
                                    <Button
                                        variant="contained"
                                        className={"w-full"}
                                        color={"primary"}
                                        onClick={this.addPayment}
                                        disabled={(this.state.PaymentType === "CHECK" && !this.state.Check) || (this.state.PaymentType !== "CHECK" && !this.state.Memo)}
                                    >
                                        <Icon className="mr-8">add_circle_outline</Icon>
                                        Add Payment ({this.formatDollars(formValues.total)})
                                    </Button>
                                }


                                {this.state.PaymentType === "ON-FILE" && this.state.paymentsToken && this.state.cardOnFile && card &&
                                    <div className="flex">
                                        <Button
                                            variant="contained"
                                            className={classNames("w-full", reader || completedTransaction ? "rounded-r-none" : "", (loading || completedTransaction) && classes.approved)}
                                            color={completedTransaction ? "secondary" : "primary"}
                                            onClick={() => { if (!this.state.processing && !completedTransaction && !loading) { this.handleTokenizedPay(); } }}
                                            disabled={(isPayButtonDisabled && !loading) || (completedTransaction && completedTransaction.is_voided)}
                                        >{!completedTransaction ?
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="secondary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8">payment</Icon>}
                                                {loading ? 'Processing Payment' : `Pay ${this.formatDollars(formValues.total)}`}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <Icon className="mr-8" color="secondary">check_circle_outline</Icon>
                                                Payment Approved
                                            </React.Fragment>
                                            }
                                        </Button>
                                    </div>
                                }


                                {this.state.PaymentType === "CREDIT" && this.state.paymentsToken &&
                                    <div className="flex">
                                        {(this.state.cardMethod === "K" || reader) &&
                                            <Button
                                                variant="contained"
                                                className={classNames("w-full", reader || completedTransaction ? "rounded-r-none" : "", (loading || completedTransaction) && classes.approved)}
                                                color={completedTransaction ? "secondary" : "primary"}
                                                onClick={() => { if (!this.state.processing && !completedTransaction && !loading) { if (this.state.cardMethod === "S" && this.state.reader) { this.initiatePayment(); } else { this.handleManualPay(); } } }}
                                                disabled={(this.state.cardMethod === "K" && isPayButtonDisabled && !loading) || (this.state.cardMethod === "S" && this.state.reader && this.state.processing) || (completedTransaction && completedTransaction.is_voided)}
                                            >{!completedTransaction ?
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="secondary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8">payment</Icon>}
                                                    {loading ? 'Processing Payment' : `Pay ${this.formatDollars(formValues.total)}`}
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <Icon className="mr-8" color="secondary">check_circle_outline</Icon>
                                                    Payment Approved
                                                </React.Fragment>
                                                }
                                            </Button>
                                        }



                                        {this.state.cardMethod === "S" && this.state.paymentsToken && this.state.selectedReader &&
                                            <Button
                                                variant="contained"
                                                className={classNames(!reader ? "w-full" : "w-48 rounded-l-none", reader && classes.approved)}
                                                color={completedTransaction ? "secondary" : "primary"}
                                                onClick={() => { if (!this.state.reader) { this.connectReader(this.state.selectedReader); } else { this.disconnectReader(); } }}
                                                disabled={!this.state.connected || this.state.connecting}
                                            >
                                                <React.Fragment>
                                                    {this.state.connecting ? <CircularProgress color="secondary" className="mr-8" style={{ width: 20, height: 20, color: '#fff' }} /> : <Icon className="mr-8" color={reader ? "secondary" : undefined}>bluetooth</Icon>}
                                                    {!this.state.reader ? `Connect${this.state.connecting ? 'ing' : ''} Reader` : ''}
                                                </React.Fragment>
                                            </Button>
                                        }
                                    </div>
                                }
                                <div className="outcome">
                                    {this.renderMessage()}
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({



    }, dispatch);
}



function mapStateToProps({ invoicesApp, spReducers }) {
    return {
        companies: spReducers.companies
    }
}



export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PaymentForm));


