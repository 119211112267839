import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Button, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Parser } from 'json2csv';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditInvoiceDialog } from '../invoices/store/actions';
import { updateTableSettings } from '../users/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import * as Actions from './store/actions';
import axios from 'axios';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Records Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 256px)',
        paddingBottom: 0,
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    techAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },

});

class EstimatesList extends Component {

    state = {
        selectedEstimatesMenu: null,
        hist: null,
        loading: false
    };

    componentDidMount() {
        const { Co, hist, loading } = this.props;
        this.setState({ loading, hist });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { Co, hist, loading } = this.props;
        if (!_.isEqual(loading, prevProps.loading) || !_.isEqual(hist, prevProps.hist)) {
            this.setState({ loading, hist });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatMonth(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const yyyy = dt.getFullYear();
        const formatted = mm + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    handleRowCount = (count) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'estimates' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'estimates',
                Preferences: {
                    RowCount: count
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.RowCount = count;
        }
        this.props.updateTableSettings(prefs);
    }

    handleSortOrder = (sort) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'estimates' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'estimates',
                Preferences: {
                    SortOrder: sort
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.SortOrder = sort;
        }
        this.props.updateTableSettings(prefs);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    export = () => {
        const { estimateData } = this.props;
        const data = _.cloneDeepWith(!estimateData ? [] : estimateData);
        data.map((value) => {
            delete value.Data;
            delete value.Signature;
        });
        const csvData = new Parser();
        const Data = csvData.parse(data);
        var encoded = encodeURIComponent(Data);
        var csv = `data:text/csv;charset=utf-8, ${encoded}`;
        var link = document.createElement("a");
        link.setAttribute("href", csv);
        link.setAttribute("download", "Estimates.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    fetchData = (Co, Page) => {
        const { customer, site, hist } = this.props;
        if (hist) {
            this.setState({ loading: true }, () => {
                const request = axios.get(`${window["apiLocation"]}/api/Estimate?Co=${Co}&Customer=${customer}${site ? `&Site=${site}` : ''}&Page=${Page}`);
                request.then((response) => {
                    this.setState({ hist: response.data, loading: false });
                }).catch(() => {
                    this.setState({ loading: false });
                })
            });
        }
    }

    render() {
        const { classes, openEditEstimateDialog, estimateData, Co, status, user, technicians, edit } = this.props;
        const { loading, hist } = this.state;
        const { TableSettings } = user.Data;
        const prefs = _.find(TableSettings, { Table: 'estimates' });
        const data = !estimateData ? [] : estimateData;
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';
        const statuses = [{ text: "Open", icon: "timelapse", background: 'transparent', color: '#333' }, { text: "Accepted", icon: "assignment_turned_in", background: 'transparent', color: '#333', iconColor: '#4dbce9', weight: 'bold' }, { text: "Scheduled", icon: "today", background: '#3f3f3f', color: '#fff', iconColor: '#4dbce9' }, { text: "Completed", icon: "check_circle_outline", background: '#333', color: '#fff', iconColor: '#d1e751' }, { text: "Expired", icon: "event_busy", background: '#f44336', color: '#fff' }]
        const manualOptions = (hist ? {
            manual: true,
            data: hist.Results,
            pages: hist.PageCount,
            onFetchData: (e) => { this.fetchData(Co, e.page + 1); },
            defaultPageSize: 10,
            pageSizeOptions: [10],
            sortable: false
        } : {
            data,
            defaultSorted: prefs ? (prefs.Preferences.SortOrder || []) : [],
            defaultPageSize: prefs ? (prefs.Preferences.RowCount || 100) : 10,
            onPageSizeChange: this.handleRowCount,
            onSortedChange: this.handleSortOrder,
        });

        return (
            <FuseAnimate animation={this.props.animation || "transition.slideUpIn"} delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center', display: edit === false ? 'none' : 'flex' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Estimates</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo && edit !== false) {
                                        openEditEstimateDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        getTfootTrProps={() => {
                            return {
                                className: "font-bold text-center",
                            }
                        }}
                        {...manualOptions}
                        loading={loading || this.state.loading} // Display the loading overlay when we need it
                        LoadingComponent={loading ? LoadingDiv : undefined}
                        NoDataComponent={loading ? LoadingDiv : NoData}
                        columns={[
                            {
                                Header: "Estimate #",
                                accessor: "EstimateNumber",
                                className: "font-bold justify-center",
                                width: 160,
                                Footer: status ? "Total" : null
                            },
                            {
                                Header: "Status",
                                id: "status",
                                className: "justify-center",
                                accessor: "Status",
                                minWidth: 128,
                                Cell: ({ original }) => {
                                    const stat = statuses[original.Status];
                                    return (
                                        <div className="rounded text-12 flex p-4 px-8" style={{ backgroundColor: stat.background, color: stat.color, fontWeight: stat.weight }}>
                                            <Icon className="text-16 mr-4" style={{ color: stat.iconColor || stat.color }}>{stat.icon}</Icon>
                                            {stat.text}
                                        </div>
                                    );
                                }

                            },
                            {
                                Header: "Issued Date",
                                id: "issuedDate",
                                className: "justify-center",
                                accessor: "IssuedDate",
                                Cell: row => this.formatDate(row.value)

                            },
                            {
                                Header: "Issued By",
                                accessor: "IssuedBy",
                                minWidth: 256,
                                className: "justify-center",
                                Cell: row => {
                                    return (row.original.IssuedBy && technicians) && technicians.map((value) => (
                                        value.Technician === row.original.IssuedBy &&
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.techAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                            {
                                Header: "Total",
                                className: "justify-center",
                                accessor: "total",
                                Cell: row => {
                                    let total = row.original.PriceTotal;
                                    if (!total) {
                                        if (row.original.Data) {
                                            total = _.sumBy(row.original.Data.Components, 'Price');
                                        } else {
                                            total = 0;
                                        }
                                    }
                                    return this.formatDollars(total);
                                },
                            },
                            {
                                Header: "Scheduled WO",
                                accessor: "AssignedToWorkOrder",
                                className: "font-bold justify-center",
                                width: 128,
                                maxWidth: 128,
                                Cell: ({ original }) => {
                                    if (original.AssignedToWorkOrder) {
                                        return <div onClick={(e) => { e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: original.Co, WorkOrder: original.AssignedToWorkOrder }) }} classsName="">{original.AssignedToWorkOrder}<Icon className="align-middle mb-2 ml-6 text-16">open_in_new</Icon></div>;
                                    } else {
                                        if (original.Status < 2 && original.PriceTotal) {
                                            const { Co, BusinessUnit, Division, Description } = original;
                                            return (
                                                <Button onClick={(e) => { e.stopPropagation(); openEditEstimateDialog(original, true) }} className="p-0 px-8 text-12" color="primary" variant="contained">Schedule</Button>
                                            );
                                        }
                                    }
                                }
                            },
                            {
                                Header: "Invoice #",
                                accessor: "InvoiceNumber",
                                className: "font-bold justify-center",
                                width: 128,
                                maxWidth: 128,
                                Cell: ({ original }) => {
                                    if (original.InvoiceNumber) {
                                        return <div onClick={(e) => { e.stopPropagation(); this.props.openEditInvoiceDialog({ Co: original.Co, InvoiceNumber: original.InvoiceNumber }) }} className="">{original.InvoiceNumber}<Icon className="align-middle mb-2 ml-6 text-16">open_in_new</Icon></div>
                                    }
                                }
                            },
                            {
                                Header: "From WO",
                                accessor: "IssuedFromWorkOrder",
                                className: "font-bold justify-center",
                                width: 128,
                                maxWidth: 128,
                                Cell: ({ original }) => {
                                    return <div onClick={(e) => { e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: original.Co, WorkOrder: original.IssuedFromWorkOrder }) }} classsName="">{original.IssuedFromWorkOrder}<Icon className="align-middle mb-2 ml-6 text-16">open_in_new</Icon></div>;
                                }
                            },
                            {
                                Header: "Customer",
                                accessor: "Customer",
                                className: "justify-center",
                                width: 96,
                                Cell: row => {
                                    return `${row.original.Customer}`
                                }
                            },
                            {
                                Header: "Site",
                                accessor: "Site",
                                className: "justify-center",
                                width: 128,
                                Cell: row => {
                                    return `${row.original.Site}`
                                }
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256,
                                maxWidth: 256,
                            },
                        ]}
                        // defaultSorted={status && prefs ? (prefs.Preferences.SortOrder || []) : []}
                        // defaultPageSize={status && prefs ? (prefs.Preferences.RowCount || 10) : 10}
                        // onPageSizeChange={this.handleRowCount}
                        // onSortedChange={this.handleSortOrder}
                        noDataText="No Estimates found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditEstimateDialog: Actions.openEditEstimateDialog,
        updateTableSettings,
        openEditCustomerDialog,
        openEditCustomerSiteDialog,
        openEditWorkOrderDialog,
        openEditInvoiceDialog,
    }, dispatch);
}

function mapStateToProps({ estimatesApp, spReducers, auditPostingApp }) {
    return {
        Co: spReducers.companies.Co,
        companies: spReducers.companies,
        estimates: estimatesApp.estimates.entities,
        searchText: estimatesApp.estimates.searchText,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        glAccount: auditPostingApp.auditPosting.glAccount,
        technicians: spReducers.technicians,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesList)));
