import _ from '@lodash';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default reorder;

export const reorderQuoteMap =
    (
        lists,
        source,
        destination
    ) => {
        const current = _.find(lists, { ID: source.droppableId });
        const next = _.find(lists, { ID: destination.droppableId });
        const target = current.Data.Cards[source.index];

        // moving to same list
        if (source.droppableId === destination.droppableId) {
            const reordered = reorder(
                current.Data.Cards,
                source.index,
                destination.index
            );
            return lists.map((list, index) => {
                if (list.ID === source.droppableId) {
                    list.Data.Cards = reordered;
                }
                return list;
            });
        }

        // moving to different list

        // remove from original
        current.Data.Cards.splice(source.index, 1);
        // insert into next
        next.Data.Cards.splice(destination.index, 0, target);

        return lists.map((list) => {
            if (list.ID === source.droppableId) {
                return current;
            }
            if (list.ID === destination.droppableId) {
                return next;
            }
            return list;
        });
    };
