import CompaniesApp from 'main/content/apps/companies/CompaniesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const CompaniesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/companies/:id',
            component: CompaniesApp
        },
        {
            path     : '/apps/companies',
            component: CompaniesApp
        }
    ]
};
