import { FuseUtils } from '@fuse/index';
import { appsConfigs } from 'main/content/apps/appsConfigs';
import { pagesConfigs } from 'main/content/pages/pagesConfigs';
import React from 'react';
import { Redirect } from 'react-router-dom';
import store from '../store';

const routeConfigs = [
    ...appsConfigs,
    ...pagesConfigs
];

// function getSecurable(url) {
//     const state = store.getState();
//     const { userRoles, userProfiles, companies } = state.spReducers;
//     const { User, Pages } = userProfiles;
//     const { Co } = companies;
//     let id = null;
//     let isSecurable = false;
//     Pages.map((page) => {
//         if (url.indexOf(page.url) > -1) {
//             id = page.id;
//             isSecurable = page.securables;
//         }
//     });
//     const roles = _.filter(userRoles, (o) => { return _.findIndex(User.Data.Roles, { Co, Role: o.Role }) > -1 });
//     const securables = [];
//     roles.map((value) => {
//         value.Data.Securables.map((sec) => {
//             securables.push(sec.Securable);
//         });
//     });
//     return !isSecurable ? true : (securables.indexOf(id) > -1);
// }

function getDefaultRoute() {
    const state = store.getState();
    const { userRoles, userProfiles, companies } = state.spReducers;
    const { User } = userProfiles;
    if (User) {
        const { Data } = User;
        if (Data && Data.DefaultHomepage) {
            window["warn"](Data.DefaultHomepage);
            return Data.DefaultHomepage;
        } else {
            return '/';
        }
    }
}

// function getDefaultRoute() {
//     let homepage = "/pages/errors/error-404";
//     const state = store.getState();
//     const { userRoles, userProfiles, companies } = state.spReducers;
//     const { User } = userProfiles;
//     const { Roles } = User.Data;
//     const { Co } = companies;
//     const roles = Roles ? _.filter(Roles, { Co }) : [];
//     if (roles && roles.length > 0) {
//         const role = Roles[0];
//         if (role) {
//             const Role = _.find(userRoles, { Role: role.Role });
//             if (Role) {
//                 const hasAccess = getSecurable(Role.DefaultHomepage);
//                 homepage = hasAccess ? Role.DefaultHomepage : homepage;
//             }
//         }
//     }
//     return homepage;
// }

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Redirect to={getDefaultRoute()} />
    },
    {
        component: () => <Redirect to="/pages/errors/error-404" />
    }
];
