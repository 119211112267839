import * as Actions from '../actions';

const initialState = [
    {
        'id': 1,
        'handle': 'frontend',
        'Title': 'Frontend',
        'color': '#388E3C',
        'Class': 'bg-green text white'
    },
    {
        'id': 2,
        'handle': 'backend',
        'Title': 'Backend',
        'color': '#F44336',
        'Class': 'bg-orange text white'
    },
    {
        'id': 3,
        'handle': 'api',
        'Title': 'API',
        'color': '#FF9800',
        'Class': 'bg-yellow text white'
    },
    {
        'id': 4,
        'handle': 'issue',
        'Title': 'Issue',
        'color': '#0091EA',
        'Class': 'bg-blue text white'
    },
    {
        'id': 5,
        'handle': 'mobile',
        'Title': 'Mobile',
        'color': '#9C27B0',
        'Class': 'bg-purple text white'
    }
]
const labelsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_LABELS:
            return [
                ...action.payload
            ];
        default:
            return state;
    }
};

export default labelsReducer;