import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../store/actions';
import { withStyles, Typography, Icon } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DashboardViewer from '../dashboard/DashboardViewer';
// import EmailDashboardBuilder from '../EmailDashboardBuilder';
import { FuseAnimateGroup, FuseAnimate } from '@fuse';
import axios from 'axios';
import { SPRoundIcon } from 'main/icons';
import _ from '@lodash';
import { isEqual } from 'lodash-es';

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        // backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        // backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        paddingBottom: 80,
        overflow: 'auto',
    },
    dashboard: {
        cursor: 'pointer',
        border: '1px dotted #9f9f9f',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    noDashboard: {
        cursor: 'pointer',
        border: '1px dotted #9f9f9f',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    colorGrey: {
        color: '#4f4f4f',
    },
    newDashboard: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    }
});


class Dashboards extends Component {
    state = {
        Co: null,
        User: null,
        dashboard: null,
        dashboards: [],
        fetching: null,
    }

    componentDidMount() {
        const { dashboards, form, user } = this.props;
        let boards = _.filter(dashboards, { Form: form });
        boards = _.filter(boards, (o) => _.findIndex(user.Data.Dashboards, { Dashboard: o.Dashboard }) > -1);
        const board = _.find(boards, { DefaultYN: 'Y' });
        this.setState({ ...this.state, dashboards: boards, dashboard: board ? board.DashboardInfo : null });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { dashboards, form, user } = this.props;
        if (!isEqual(dashboards, prevProps.dashboards) || !isEqual(user.Data.Dashboards, prevProps.user.Data.Dashboards)) {
            let boards = _.filter(dashboards, { Form: form });
            boards = _.filter(boards, (o) => _.findIndex(user.Data.Dashboards, { Dashboard: o.Dashboard }) > -1);
            this.setState({ ...this.state, dashboards: boards });
        }
    }

    getDashboard = (dashboard) => {
        const { fetching } = this.state;
        if (!fetching) {
            if (!dashboard.DashboardInfo) {
                this.setState({ ...this.state, fetching: dashboard }, () => {
                    const { Co, Dashboard } = dashboard;
                    const request = axios.get(`${window["apiLocation"]}/api/Dashboard?Co=${Co}&Dashboard=${Dashboard}`);
                    request.then(
                        (response) => {
                            this.setDashboard(response.data);
                        });
                })
            } else {
                this.setDashboard(dashboard.DashboardInfo);
            }
        }
    }

    setDashboard = (dashboard) => {
        window["warn"]('Dashboard: ', dashboard);
        this.setState({ ...this.state, dashboard, fetching: null });
    }

    render() {
        const { classes, Co, User, form, data } = this.props;
        const { dashboards, dashboard, fetching } = this.state;

        if (dashboard && data) {
            return <DashboardViewer data={{ ...data }} dashboard={{ ...dashboard }} onBack={() => this.setDashboard(null)} />
        }
        return (
            <div className={classNames(classes.root, "flex flex-grow flex-no-shrink flex-col")}>
                {dashboards.length > 0 ?
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mb-6 my-12 text-grey-darkest"><Icon color="primary" className="text-24 mr-6">insert_chart</Icon>Dashboards</Typography>
                    :
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mb-6 my-12 text-grey-darkest"><Icon color="error" className="text-24 mr-6">insert_chart</Icon>No Dashboards Found</Typography>
                }
                <div className="w-full">
                    <FuseAnimateGroup
                        className="flex flex-wrap w-full justify-start"
                        enter={{
                            animation: "transition.slideUpBigIn",
                            duration: 300
                        }}
                    >
                        {dashboards.map(board => (
                            <div onClick={() => this.getDashboard(board)} className="w-full sm:px-4 sm:w-1/3 md:w-1/4 lg:w-1/5 h-160 py-4 relative" key={board.ID}>
                                {board.DefaultYN === "Y" &&
                                    <div className="pin-l pin-t mt-12 ml-12 absolute text-12 font-bold bg-grey-darkest rounded pt-4 px-12 pb-0"><Icon color="secondary" className="mr-4 text-16 align-middle mb-4">check_circle_outline</Icon>Default</div>
                                }
                                <div className={classNames(classes.dashboard, "flex flex-col items-center justify-center w-full h-full rounded py-24")}>
                                    <Icon className={classNames("text-36 mt-16", fetching && fetching.Dashboard === board.Dashboard ? "spin" : "")}>{fetching && fetching.Dashboard === board.Dashboard ? "refresh" : "insert_chart"}</Icon>
                                    <Typography className="text-16 text-center px-32 font-600" color="inherit">{board.Name}</Typography>
                                </div>
                            </div>
                        ))}
                    </FuseAnimateGroup>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDashboards: Actions.getDashboards,
        resetDashboards: Actions.resetDashboards,
        newDashboard: Actions.newDashboard
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        dashboards: spReducers.reporting.dashboards,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboards)));
