import axios from 'axios/index';
import { getUserData, GET_USER_DATA } from 'main/content/apps/users/store/actions/user.actions';

export const GET_USERS = '[USERS APP] GET USERS';
export const SET_SEARCH_TEXT = '[USERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_USERS = '[USERS APP] TOGGLE IN SELECTED USERS';
export const SELECT_ALL_USERS = '[USERS APP] SELECT ALL USERS';
export const DESELECT_ALL_USERS = '[USERS APP] DESELECT ALL USERS';
export const OPEN_NEW_USER_DIALOG = '[USERS APP] OPEN NEW USER DIALOG';
export const CLOSE_NEW_USER_DIALOG = '[USERS APP] CLOSE NEW USER DIALOG';
export const OPEN_EDIT_USER_DIALOG = '[USERS APP] OPEN EDIT USER DIALOG';
export const ADD_EDIT_USER_DIALOG = '[USERS APP] ADD EDIT USER DIALOG';
export const UPDATE_EDIT_USER_DIALOG = '[USERS APP] UPDATE EDIT USER DIALOG';
export const DATA_EDIT_USER_DIALOG = '[USERS APP] DATA EDIT USER DIALOG';
export const CLOSE_EDIT_USER_DIALOG = '[USERS APP] CLOSE EDIT USER DIALOG';
export const ADD_USER = '[USERS APP] ADD USER';
export const UPDATE_USER = '[USERS APP] UPDATE USER';
export const REMOVE_USER = '[USERS APP] REMOVE USER';
export const REMOVE_USERS = '[USERS APP] REMOVE USERS';
export const TOGGLE_STARRED_USER = '[USERS APP] TOGGLE STARRED USER';
export const TOGGLE_STARRED_USERS = '[USERS APP] TOGGLE STARRED USERS';
export const SET_USERS_STARRED = '[USERS APP] SET USERS STARRED ';

export function getUsers(routeParams) {
    const request = axios.get('/api/users-app/users', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_USERS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedUsers(userId) {
    return {
        type: TOGGLE_IN_SELECTED_USERS,
        userId
    }
}


export function selectAllUsers() {
    return {
        type: SELECT_ALL_USERS
    }
}

export function deSelectAllUsers() {
    return {
        type: DESELECT_ALL_USERS
    }
}


export function openNewUserDialog(data) {
    return {
        type: OPEN_NEW_USER_DIALOG,
        data
    }
}

export function closeNewUserDialog() {
    return {
        type: CLOSE_NEW_USER_DIALOG
    }
}

export function openEditUserDialog(data, reload) {
    return (dispatch, getState) => {

        const { UserName } = data;
        if (reload) {

            const request = axios.get(`${window["apiLocation"]}/api/UserProfile?&UserName=${encodeURIComponent(UserName)}`);

            return request.then((response) =>
                Promise.all([
                    dispatch({
                        type: OPEN_EDIT_USER_DIALOG,
                        data: response.data
                    })
                ])
            );
        } else {
            dispatch({
                type: OPEN_EDIT_USER_DIALOG,
                data
            });
        }
    };
}

export function updateEditUserDialog(data) {
    return (dispatch, getState) => {

        const { UserName } = data;

        const request = axios.get(`${window["apiLocation"]}/api/UserProfile?UserName=${encodeURIComponent(UserName)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DATA_EDIT_USER_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditUserDialog() {
    return {
        type: CLOSE_EDIT_USER_DIALOG
    }
}

export function addUser(newUser) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/UserProfile`,
            {
                UserProfile: newUser,
                UserLogin: {
                    UserName: newUser.UserName,
                    Password: newUser.Password,
                },
                CompanyUser: newUser.Data.Companies[0],
                Data: {
                    ErrMsg: null,
                }
            }
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: GET_USER_DATA,
                    data: response.data
                })
            ])
        );
    };
}

export function updateUser(user, bool) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/UserProfile?UserName=${encodeURIComponent(user.UserName)}`,
            user
        );

        return request.then((response) => {
            if (!bool) {
                Promise.all([
                    dispatch({
                        type: GET_USER_DATA,
                        data: response.data
                    })
                ])
            }
        }
        );
    };
}

export function removeUser(userId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().usersApp.users;

        const request = axios.post('/api/users-app/remove-user', {
            userId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_USER
                }),
                dispatch({
                    type: GET_USER_DATA,
                    data: null
                })
            ]).then(() => dispatch(getUsers(routeParams)))
        );
    };
}


export function removeUsers(userIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().usersApp.users;

        const request = axios.post('/api/users-app/remove-users', {
            userIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_USERS
                }),
                dispatch({
                    type: DESELECT_ALL_USERS
                })
            ]).then(() => dispatch(getUsers(routeParams)))
        );
    };
}

export function toggleStarredUser(userId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().usersApp.users;

        const request = axios.post('/api/users-app/toggle-starred-user', {
            userId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_USER
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUsers(routeParams)))
        );
    };
}

export function toggleStarredUsers(userIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().usersApp.users;

        const request = axios.post('/api/users-app/toggle-starred-users', {
            userIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_USERS
                }),
                dispatch({
                    type: DESELECT_ALL_USERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUsers(routeParams)))
        );
    };
}

export function setUsersStarred(userIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().usersApp.users;

        const request = axios.post('/api/users-app/set-users-starred', {
            userIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_USERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_USERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUsers(routeParams)))
        );
    };
}

export function setUsersUnstarred(userIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().usersApp.users;

        const request = axios.post('/api/users-app/set-users-unstarred', {
            userIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_USERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_USERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getUsers(routeParams)))
        );
    };
}
