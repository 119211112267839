import axios from 'axios/index';

export const GET_AUDIO_CLIPS = '[SPCONNECTION] GET AUDIO CLIPS';
export const GET_HOLD_MUSIC = '[SPCONNECTION] GET HOLD MUSIC';
export const SET_AUDIO_CLIPS = '[SPCONNECTION] SET AUDIO CLIPS';
export const SET_HOLD_MUSIC = '[SPCONNECTION] SET HOLD MUSIC';

export function getAudioClips(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/AudioClips`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_AUDIO_CLIPS,
                payload: response.data
            })
        });
    }
}

export function setAudioClips(data) {
    return {
        type: SET_AUDIO_CLIPS,
        payload: data
    };
}

export function getHoldMusic(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/HoldMusic`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_HOLD_MUSIC,
                payload: response.data
            })
        });
    }
}

export function setHoldMusic(data) {
    return {
        type: SET_HOLD_MUSIC,
        payload: data
    };
}