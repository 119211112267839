import {combineReducers} from 'redux';
import taxCodes from './tax-codes.reducer';
import user from './user.reducer';

const taxCodesAppReducers = combineReducers({
    taxCodes,
    user
});

export default taxCodesAppReducers;
