import { Plugins } from '@capacitor/core';
import _ from '@lodash';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grow, Icon, IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import 'capacitor-plugin-omni-sdk';
const { OmniPlugin } = Plugins;
const { initOmni, getReaders, connectReader, disconnectReader, takePayment } = OmniPlugin || {};



const styles = theme => ({
    root: {
        margin: 24,
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/images/backgrounds/SP_Header_Dark.png)',
        backgroundPosition: 'center'
    },
    checkbox: {
        paddingRight: 4,
        paddingTop: 10,
    },
    approved: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#333',
            color: '#fff',
        },
        '&:active': {
            backgroundColor: '#333',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#333',
            color: '#fff',
        }
    }
});
class TokenizeForm extends Component {
    fattJs = null;
    state = {
        PaymentType: null,
        Memo: null,
        Check: null,
        cardOnFile: null,
        tokenizedPaymentMethod: null,
        completedTransaction: null,
        isTokenizeButtonDisabled: true,
        formValues: {},
        loading: false,
        connected: false,
        reader: false,
        readers: null,
        selectedReader: null,
        status: '',
        processing: false,
        complete: false,
        error: false,
        paymentStatus: null,
        readerStatus: null,
        paymentsToken: false,
        paymentsInvoice: null
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { PaymentsCustomer } = this.props.data;
        if (PaymentsCustomer && this.state.PaymentType === 'CREDIT' && prevState.PaymentType !== "CREDIT") {
            this.initTokenizeForm();
        }
    }

    componentWillMount() {
        const { data, companies, paymentMethods } = this.props;
        let { Co, FirstName, LastName, BillPhone, BillEmail, BillAddress1, BillAddress2, BillCity, BillState, BillCountry, BillZip, PaymentsCustomer, PaymentsInvoice, total } = data;
        const co = _.find(companies.List, { Co });
        const paymentsToken = co && co.Data ? co.Data.PaymentsToken : null;
        let PaymentType = null;
        if (Co) {
            this.setState({
                ...this.state,
                PaymentType,
                connected: false,
                status: null,
                paymentsToken,
                formValues: {
                    customer_id: PaymentsCustomer || undefined,
                    firstname: FirstName,
                    lastname: LastName,
                    phone: BillPhone,
                    // email: BillEmail,
                    // address_1: BillAddress1,
                    // address_2: BillAddress2,
                    // address_city: BillCity,
                    // address_state: BillState,
                    // address_country: BillCountry,
                    address_zip: BillZip,
                }
            });
        }
    }



    initTokenizeForm = () => {
        const { data, companies } = this.props;
        const { paymentsToken } = this.state;
        const { Co, FirstName, LastName, BillPhone, BillEmail, BillAddress1, BillAddress2, BillCity, BillState, BillCountry, BillZip, PaymentsCustomer, PaymentsInvoice, total } = data;
        const co = _.find(companies.List, { Co });
        if (paymentsToken) {
            const FattJs = window.FattJs;

            if (!FattJs) {
                this.setState({ error: { message: 'Manual Entry unavailable' } });
            } else {

                const fattJs = new FattJs(paymentsToken, {
                    number: {
                        id: "card-number",
                        placeholder: "xxxx xxxx xxxx xxxx",
                        style:
                            "height: 32px; width: 100%; font-size: 16px; color: #3f3f3f;",
                        format: "prettyFormat"
                    },
                    cvv: {
                        id: "card-cvv",
                        placeholder: "xxx",
                        style:
                            "height: 32px; width: 100%; font-size: 16px; color: #3f3f3f;"
                    }
                });



                // window["alert"](`PaymentsCustomer: ${PaymentsCustomer}`);
                fattJs.showCardForm().then(handler => {
                    this.setState({
                        formValues: {
                            customer_id: PaymentsCustomer || undefined,
                            firstname: FirstName,
                            lastname: LastName,
                            phone: BillPhone,
                            // email: BillEmail,
                            // address_1: BillAddress1,
                            // address_2: BillAddress2,
                            // address_city: BillCity,
                            // address_state: BillState,
                            // address_country: BillCountry,
                            address_zip: BillZip,
                        }
                    });
                });



                fattJs.on("card_form_complete", message => {
                    console.log("card_form_complete", message);
                    this.setState({ isTokenizeButtonDisabled: false, cardType: message.cardType });
                });



                fattJs.on("card_form_incomplete", message => {
                    console.log("card_form_incomplete", message);
                    this.setState({ isTokenizeButtonDisabled: true, cardType: message.cardType });
                });



                this.fattJs = fattJs;
            }
        }
    }



    handleFieldChange = event => {
        const { formValues } = this.state;
        const { name, value } = event.target;
        this.setState({ formValues: { ...formValues, [name]: value } });
    };

    handleTokenize = () => {
        const { data } = this.props;
        const { Co, Customer, Site, Invoice, InvoiceNumber, WorkOrder, FirstName, LastName, BillPhone, BillEmail, BillAddress1, BillAddress2, BillCity, BillState, BillCountry, BillZip, PaymentsInvoice, PaymentsCustomer, total } = data;
        const { paymentsInvoice } = this.state;

        if (!this.fattJs) {
            this.setState({ error: { message: 'Manual Entry unavailable' } });
        } else {
            this.setState({
                tokenizedPaymentMethod: null,
                completedTransaction: null,
                isTokenizeButtonDisabled: true,
                loading: true,
                error: null
            });



            const { formValues } = this.state;
            const extraDetails = {
                ...formValues,
                method: 'card',
                validate: false,
            };



            this.fattJs.tokenize(extraDetails).then(tokenizedPaymentMethod => {
                var paymentMethod = {
                    Co,
                    Customer,
                    PaymentsCustomer: tokenizedPaymentMethod.customer_id,
                    PaymentMethodID: tokenizedPaymentMethod.id,
                    PaymentMethodType: tokenizedPaymentMethod.card_type,
                    CardholderName: tokenizedPaymentMethod.person_name,
                    CardLastFour: tokenizedPaymentMethod.card_last_four,
                    CardExpirationDate: tokenizedPaymentMethod.card_exp_datetime,
                    Nickname: tokenizedPaymentMethod.nickname,
                };
                var request = axios.post(`${window["apiLocation"]}/api/Omni/SavePaymentMethod`, paymentMethod);
                request.then((response) => {
                    if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                        this.setState({
                            completedTransaction: null,
                            isTokenizeButtonDisabled: false,
                            loading: false,
                            error: { message: response.data.Data.ErrMsg }
                        });
                    } else {
                        this.setState({
                            completedTransaction: response.data,
                            tokenizedPaymentMethod,
                            isTokenizeButtonDisabled: false,
                            loading: false
                        }, () => {
                            if (this.props.onAdded) {
                                this.props.onAdded(response.data)
                            }
                        });
                    }
                })
            }).catch((error) => {
                this.setState({
                    isTokenizeButtonDisabled: false,
                    loading: false,
                    error
                });
            });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };
    renderCustomerDetails = () => {
        const { formValues } = this.state;



        return (
            <div className="w-full flex">
                <div className="w-full flex">
                    <TextField
                        className="w-full bg-white mr-4"
                        label="First Name"
                        id="first-name"
                        name="firstname"
                        placeholder="First Name"
                        onChange={this.handleFieldChange}
                        value={formValues.firstname || ""}
                        variant="outlined"
                        InputProps={{
                            className: "h-48 p-8 pl-12",
                            startAdornment: <InputAdornment position="start"><Icon>account_box</Icon></InputAdornment>,
                        }}
                    />
                    <TextField
                        className="w-full bg-white ml-4"
                        label="Last Name"
                        id="last-name"
                        name="lastname"
                        placeholder="Last Name"
                        onChange={this.handleFieldChange}
                        value={formValues.lastname || ""}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            className: "h-48 p-8",
                        }}
                    />
                </div>
            </div>
        );
    };



    renderExpField = () => {
        const { formValues, cardType } = this.state;
        return (
            <div className="flex">
                <input
                    className="h-32 w-40 text-center"
                    name="month"
                    maxLength="2"
                    min={1}
                    max={12}
                    placeholder="MM"
                    type="number"
                    onChange={this.handleFieldChange}
                    value={formValues.month || ""}
                />
                <div className="h-32 pt-6 text-center">/</div>
                <input
                    className="h-32 w-56 text-center"
                    name="year"
                    maxLength="4"
                    placeholder="YYYY"
                    type="number"
                    min={new Date().getFullYear()}
                    onChange={this.handleFieldChange}
                    value={formValues.year || ""}
                />
            </div>
        );
    }



    renderCardDetails = () => {
        const { cardType, editAmount, formValues } = this.state;



        return (
            <div className={`w-full ${editAmount ? 'mb-12' : ''}`}>
                <div id="card-element" className="w-full">
                    <div className="w-full flex">
                        <TextField
                            className="w-full mt-12 bg-white"
                            label="Card Number"
                            id="card-number"
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    textShadow: '0px 0px 4px white'
                                }
                            }}
                            InputProps={{
                                inputComponent: "div",
                                inputProps: {
                                    className: "h-32 p-8"
                                },
                                startAdornment: <InputAdornment position="start">{cardType && ['visa', 'master', 'american_express', 'discover'].indexOf(cardType) > -1 ? <img className="w-32" src={`assets/images/cards/${cardType}.png`} /> : <Icon>payment</Icon>}</InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="w-full sm:flex">
                        <TextField
                            className="w-full sm:w-3/5 mt-12 mr-8 bg-white"
                            label="Card Expiration"
                            id="card-expire"
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    textShadow: '0px 0px 4px white'
                                }
                            }}
                            InputProps={{
                                className: "h-48 p-8 pl-12",
                                inputComponent: this.renderExpField,
                                inputProps: {
                                    className: "h-32 p-8",
                                },
                                startAdornment: <InputAdornment position="start"><Icon>today</Icon></InputAdornment>,
                            }}
                        />
                        <div className="w-full flex">
                            <TextField
                                className="w-1/2 mt-12 mr-8 bg-white"
                                label="Security Code"
                                id="card-cvv"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        textShadow: '0px 0px 4px white'
                                    }
                                }}
                                InputProps={{
                                    className: "pl-12",
                                    inputComponent: "div",
                                    inputProps: {
                                        className: "h-32 p-8"
                                    },
                                    startAdornment: <InputAdornment position="start" className="mr-0"><Icon>lock</Icon></InputAdornment>,
                                }}
                            />
                            <TextField
                                className="w-1/2 mt-12 bg-white"
                                label="Zip Code"
                                id="zip-code"
                                name="address_zip"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        textShadow: '0px 0px 4px white'
                                    }
                                }}
                                value={formValues.address_zip || ''}
                                onChange={this.handleFieldChange}
                                InputProps={{
                                    className: "pl-12",
                                    inputProps: {
                                        className: "h-32 p-8"
                                    },
                                    startAdornment: <InputAdornment position="start" className="mr-0"><Icon>location_on</Icon></InputAdornment>,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };



    renderMessage = () => {
        const { completedTransaction, tokenizedPaymentMethod, error, status, readerStatus } = this.state;
        const success = completedTransaction || tokenizedPaymentMethod;



        if (success) {
            return (
                <div className="success">
                    {tokenizedPaymentMethod
                        ? `${tokenizedPaymentMethod.id
                        }`
                        : ""}
                </div>
            );
        } else {
            return (
                <div className="error">
                    {error ? `${error.message}` : readerStatus || status}
                </div>
            );
        }
    };



    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }



    render() {
        const { completedTransaction, isTokenizeButtonDisabled, loading, formValues, total, editAmount, reader, readers, cardOnFile } = this.state;
        const { classes, open, paymentMethods, data } = this.props;
        const { PaymentsCustomer } = data;

        const card = (paymentMethods && cardOnFile) ? _.find(paymentMethods, { PaymentMethodID: cardOnFile }) : null;
        const cardType = card ? card.PaymentMethodType : null;
        const paymentTypes = { visa: "Visa", mastercard: "MasterCard", master: "MasterCard", discover: "Discover", american_express: "American Express", amex: "American Express", am_ex: "American Express" };



        return (
            <Media queries={{ small: "(max-width: 600px)", medium: "(min-width: 768px) and (min-height: 768px)" }}>
                {matches =>
                    <Dialog
                        classes={{ paper: classes.root }}
                        // disableBackdropClick
                        // disableEscapeKeyDown
                        maxWidth="xs"
                        fullWidth
                        TransitionComponent={Grow}
                        onClose={() => this.props.onClose()}
                        aria-labelledby="payment-method-dialog-title"
                        open={open}
                    >
                        <DialogTitle classes={{ root: "p-16 pl-24 pr-24 text-20" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">payment</Icon>Payment Method</DialogTitle>
                        <IconButton style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            color: '#333'
                        }}
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                        <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
                            <div className="w-full payment">
                                <TextField
                                    className="w-full bg-white mb-12"
                                    label="Form of Payment"
                                    id="type"
                                    name="PaymentType"
                                    variant="outlined"
                                    value={this.state.PaymentType || ''}
                                    onChange={(e) => this.setState({ ...this.state, PaymentType: e.target.value, Check: null, cardOnFile: null })}
                                    margin="dense"
                                    InputLabelProps={{
                                        style: {
                                            textShadow: '0px 0px 4px white'
                                        }
                                    }}
                                    select
                                >
                                    {PaymentsCustomer && this.state.paymentsToken &&
                                        <MenuItem value="CREDIT">
                                            Credit / Debit
                                        </MenuItem>
                                    }
                                    {PaymentsCustomer && this.state.paymentsToken &&
                                        <MenuItem value="ACH" disabled={true}>
                                            Bank Account
                                        </MenuItem>
                                    }
                                </TextField>
                                {this.state.PaymentType == "CREDIT" &&
                                    <React.Fragment>
                                        {this.renderCustomerDetails()}
                                        {this.renderCardDetails()}
                                    </React.Fragment>
                                }


                                {this.state.PaymentType === "CREDIT" && this.state.paymentsToken &&
                                    <div className="flex mt-12">
                                        <Button
                                            variant="contained"
                                            className={classNames("w-full", reader || completedTransaction ? "rounded-r-none" : "", (loading || completedTransaction) && classes.approved)}
                                            color={completedTransaction ? "secondary" : "primary"}
                                            onClick={() => { if (!this.state.processing && !completedTransaction && !loading) { this.handleTokenize(); } }}
                                            disabled={isTokenizeButtonDisabled || loading}
                                        >{!completedTransaction ?
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="secondary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8">payment</Icon>}
                                                {loading ? 'Saving Payment Method' : `Save Payment Method`}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <Icon className="mr-8" color="secondary">cloud_done</Icon>
                                                Payment Method Saved
                                            </React.Fragment>
                                            }
                                        </Button>
                                    </div>
                                }
                                <div className="outcome">
                                    {this.renderMessage()}
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({



    }, dispatch);
}



function mapStateToProps({ invoicesApp, spReducers }) {
    return {
        companies: spReducers.companies
    }
}



export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TokenizeForm));


