import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { useSelector } from 'react-redux';

export const FeatureFlagsContext = createContext({});

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

const FeatureFlagsProvider = ({ children }) => {
    const ldKey = process.env.REACT_APP_LD_KEY;

    const [contextValue, setContextValue] = useState({});
    const client = useRef(null);
    const subdomain = useSelector(({ spAuth }) => spAuth.api.client);
    const clientId = useSelector(({ spAuth }) => spAuth.authentication.clientId);
    const userName = useSelector(({ spAuth }) => spAuth.authentication.username);
    const userEmail = useSelector(({ spReducers }) => spReducers.userProfiles.User.Email);
    const userFullName = useSelector(({ spReducers }) => spReducers.userProfiles.User.FirstName + ' ' + spReducers.userProfiles.User.LastName);

    const getIdentifyContext = () => {
        return {
            kind: 'user',
            key: userName ? userName : 'anonymous',
            email: userEmail,
            subdomain,
            clientId,
            fullName: userFullName
        };
    }

    useEffect(() => {
        if (ldKey) {
            client.current = LDClient.initialize(ldKey, getIdentifyContext());

            client.current.waitForInitialization().then(() => {
                const allFlags = client.current.allFlags();
                setContextValue(allFlags);

                client.current.on('change', (eventData) => {
                    setContextValue((prevState) => {

                        var newFlags = { ...prevState };

                        for (let key in eventData) {
                            newFlags[key] = eventData[key].current;
                        }

                        return newFlags;
                    });
                });
            })
        }
    }, [])

    useEffect(() => {
        if (client.current != null) {
            client.current.identify(getIdentifyContext());
        }
    }, [subdomain, clientId, userName, userEmail, userFullName])

    return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};

export default FeatureFlagsProvider;

