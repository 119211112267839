import { FuseAnimateGroup } from "@fuse";
import _ from "@lodash";
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Icon,
  IconButton,
  Link,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import axios from "axios";
import classNames from "classnames";
import history from "history.js";
import ConfirmationDialog from "main/content/components/dialogs/confirmation";
import * as DialerActions from "main/dialer/store/actions";
import { formatPhone } from "main/functions";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { showMessage } from "store/actions";
import Autocomplete from "../../components/autocomplete/Autocomplete";
import { getCustomerData, getSiteData } from "../call-handler/store/actions";
import { openEditCustomerSiteDialog } from "../customer-sites/store/actions";
import { openEditCustomerDialog } from "../customers/store/actions";
import { openEditTripDialog, openNewTripDialog } from "../trips/store/actions";
import {
  openEditWorkOrderScopeDialog,
  updateWorkOrderScope,
} from "../work-order-scopes/store/actions";
import { openEditWorkOrderDialog } from "../work-orders/store/actions";
import WorkOrdersContactList from "../work-orders/WorkOrdersContactList";
import * as Actions from "./store/actions";
import getProfileImage from "../../../functions/getProfileImageUrl";

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {},
  listItem: {
    color: "inherit!important",
    textDecoration: "none!important",
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingLeft: 24,
    paddingRight: 12,
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + "!important",
      pointerEvents: "none",
      "& .list-item-icon": {
        color: "inherit",
      },
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  selectedCallAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatarTech: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  card: {
    marginTop: 8,
    marginBottom: 4,
    cursor: "pointer",
    width: "100%",
  },
  actions: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
  },
  tabs: {
    width: "100%",
  },
  tab: {
    minWidth: "25%",
    maxWidth: "25%",
    paddingTop: 12,
    paddingBottom: 12,
  },
  action: {
    marginTop: 0,
    marginRight: -8,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  info: {
    padding: 8,
    paddingTop: 0,
    paddingBottom: 0 + "!important",
  },
  campaign: {
    width: "auto",
    top: 0,
    right: -5,
    marginTop: 5,
    borderRadius: 4,
    padding: 10,
  },
  content: {
    overflow: "hidden",
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontWeight: "bold",
    fontSize: 12,
  },
  subheader: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontSize: 11,
    marginLeft: 2,
  },
  tripAdminTab: {
    minWidth: "20%",
    maxWidth: "20%",
    paddingTop: 12,
    paddingBottom: 12,
  },
  container: {
    padding: 0,
  },
  trip: {
    minWidth: 300,
  },
  tripTabContent: {
    minHeight: 175,
    maxHeight: 175,
    overflow: "auto",
  },
  formControl: {
    marginBottom: 12,
  },
  footer: {
    top: "auto",
    bottom: 0,
  },
  scopeBadge: {
    top: 5,
    right: 2,
  },
  tripBadge: {
    top: 35,
    right: 35,
    background: "#3f3f3f",
  },
  tripBadgeIcon: {
    fontSize: "14px !important",
  },
  tripCountBadge: {
    top: 15,
    right: -7,
    height: 17,
    minWidth: 17,
    fontSize: "1rem",
    border: "2px solid #3f3f3f",
    padding: 4,
  },
  scopeAvatar: {
    width: 20,
    height: 20,
    fontSize: 11,
    marginRight: 4,
  },
  assignmentAvatar: {
    width: 20,
    height: 20,
    fontSize: 11,
    marginRight: 4,
    backgroundColor: theme.palette.primary.main,
  },
  tripAvatar: {
    backgroundColor: "#3f3f3f",
    width: 22,
    height: 22,
    marginTop: -3,
    marginRight: -3,
  },
  black: {
    backgroundColor: "#3f3f3f",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#5f5f5f",
    },
    "&:focus": {
      backgroundColor: "#5f5f5f",
    },
    "&:active": {
      backgroundColor: "#5f5f5f",
    },
  },
  colorPrimary: {
    color: theme.palette.primary.dark + " !important",
    fontWeight: "bold",
  },
  tripAssignmentAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
    fontSize: 16,
  },
  liRoot: {
    padding: 0,
  },
  assigned: {
    backgroundColor: theme.palette.primary.main,
  },
  highPriority: {
    backgroundColor: theme.palette.error[500],
  },
  medPriority: {
    backgroundColor: "gold",
  },
  moveToUnassignedDiv: {
    width: "100%",
    textAlign: "center",
    marginBottom: "10px",
  },
  moveToUnassigned: {
    width: "70%",
    fontSize: "12px",
  },
});

class DispatchBoardWorkOrder extends Component {
  state = {
    open: false,
    value: 2,
    tripValue: 2,
    isDatepickerOpen: false,
    promptCancellation: false,
    showDesc: false,
    siteMenuEl: null,
    customerMenuEl: null,
    openWoContactList: null,
    isSiteContact: null,
    editWoMenuEl: null,
    date: this.props.date,
  };
  //componentDidMount = () => {
  //    const { workOrder } = this.props;
  //    if (workOrder && !_.isEqual(workOrder, this.state)) {
  //        this.setState(workOrder);
  //    }
  //}

  componentDidMount() {
    let { tripValue } = this.state;
    let { workOrder, technician, trip, date, user } = this.props;
    const { ID, Co, WorkOrder, open } = workOrder;
    const ScheduledDate = this.formatDateInput(date);
    const scopes = this.props.scopes || workOrder.Data.Scopes;
    let ScheduledTime = null;
    if (!technician || trip.Trip < 1) {
      tripValue = 0;
    }
    let tech = null;
    if (!trip || trip.Trip < 1) {
      let Duration = 0;
      for (var i = 0; i < scopes.length; i++) {
        const service = _.find(this.props.services, {
          Service: scopes[i].Service,
        });
        const arrival = scopes[i].ExactTime
          ? null
          : _.find(this.props.arrivalWindows, {
              ArrivalWindow: scopes[i].ArrivalWindow,
            });
        tech = scopes[i].LeadTechnician;
        Duration +=
          scopes[i].EstDuration ||
          (service && service.DefaultDuration ? service.DefaultDuration : 1);
        if ((arrival && arrival.StartTime) || scopes[i].ExactTime) {
          if (i == 0) {
            ScheduledTime = `${ScheduledDate} ${scopes[i].ExactTime ||
              arrival.StartTime}`;
          } else {
            if (
              moment(`${ScheduledDate} ${arrival.StartTime}`).toDate() <
              moment(ScheduledTime).toDate()
            )
              ScheduledTime = `${ScheduledDate} ${arrival.StartTime}`;
          }
        }
      }
      trip = {
        ID,
        Co,
        WorkOrder,
        Trip: 0,
        Technician: null,
        Technicians: tech ? [tech] : [],
        Status: 0,
        ScheduledDate,
        ScheduledTime,
        Duration,
        CreatedBy: user.username,
        CreatedDate: new Date(),
        Data: { WorkOrder: workOrder, Tags: [], Assignments: [] },
        row: 0,
      };
      scopes.map((scope) => {
        const { Scope } = scope;
        trip.Data.Assignments.push({
          Co,
          WorkOrder,
          Scope,
        });
      });
    }
    if (workOrder && !_.isEqual(workOrder, this.state)) {
      //   window["log"]("Setting Work Order List Item");
      this.setState({ ...workOrder, technician, trip, date, open, tripValue });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { tripValue } = this.state;
    let { workOrder, technician, date, trip, user } = this.props;
    const { ID, Co, WorkOrder, open } = workOrder;
    const ScheduledDate = !trip
      ? this.formatDateInput(date)
      : this.formatDateInput(trip.ScheduledDate);
    const ScheduledTime = !trip
      ? `${ScheduledDate} 00:00:00`
      : trip.ScheduledTime;
    if (!technician || trip.Trip < 1) {
      tripValue = 0;
    }
    if (!trip || trip.Trip < 1) {
      trip = this.state.trip;
    }
    if (
      workOrder &&
      (!_.isEqual(workOrder, prevProps.workOrder) ||
        !_.isEqual(technician, prevProps.technician) ||
        !_.isEqual(trip, prevState.trip))
    ) {
      //   window["log"](
      //     "Updating Work Order List Item",
      //     workOrder,
      //     prevProps.workOrder
      //   );
      this.setState({
        ...workOrder,
        technician,
        trip,
        date,
        open,
        value:
          workOrder.value > 2 ? prevProps.workOrder.value : workOrder.value,
        tripValue,
      });
    }
  }

  handleChange = (event, value) => {
    const { workOrder } = this.props;
    if (this.state.value !== value) {
      this.setState({ value }, () => {
        this.props.setWorkOrderInfo(this.state);
      });
    }
  };

  handleInputChange = (event) => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === "checkbox"
          ? event.target.checked
            ? "Y"
            : "N"
          : event.target.value
      )
    );
  };

  handleOpen = () => {
    let { open, technician } = this.state;
    if (!technician) {
      open = !open;
      this.setState({ open }, () => {
        this.props.setWorkOrderInfo(this.state);
      });
    }
  };

  handleAdminChange = (event, tripValue) => {
    const { workOrder, technician, user } = this.props;
    const { trip, date } = this.state;
    const { ID, Co, WorkOrder } = workOrder;
    if (tripValue < 4) {
      this.setState({ tripValue }, () => {
        this.props.setWorkOrderInfo(this.state);
      });
    } else {
      if (!technician || trip.Trip < 1) {
        this.setState({ tripValue }, () => {
          this.props.setWorkOrderInfo(this.state);
        });
      } else {
        if (technician) this.props.onDelete(trip);
      }
    }
  };

  createTrip = () => {
    const { workOrder, technician, user } = this.props;
    const { trip, date } = this.state;
    const { ID, Co, WorkOrder } = workOrder;
    this.props.createTrip(trip, true);
    const newTrip = { ...trip, Technician: null, Technicians: [] };
    this.setState({ trip: newTrip }, () => {
      this.props.setWorkOrderInfo(this.state);
    });
  };

  handleAdminTripChange = (event) => {
    let { trip, tripValue } = this.state;
    _.set(
      trip,
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
          ? "Y"
          : "N"
        : event.target.value
    );
    tripValue += tripValue < 3 ? 1 : 0;
    this.setState({ ...this.state, trip, tripValue }, () => {
      if (this.props.onUpdate && this.state.trip.Trip > 0) {
        this.props.onUpdate(this.state.trip);
      }
    });
  };

  handleAdminTripDateChange = (event) => {
    let { trip, tripValue } = this.state;
    // window["warn"](event);
    trip.ScheduledDate = this.formatDate(event._d);
    trip.ScheduledTime = event._d.toLocaleString("en-US");
    tripValue += tripValue < 3 ? 1 : 0;
    this.setState({ ...this.state, trip, tripValue }, () => {
      if (this.props.onUpdate && this.state.trip.Trip > 0) {
        this.props.onUpdate(this.state.trip);
      }
    });
  };

  handleAnytimeChange = (event) => {
    const { trip } = this.state;
    if (event.target.checked) {
      trip.ScheduledTime = null;
    } else {
      trip.ScheduledTime = trip.ScheduledDate;
    }
    this.setState({ ...this.state, trip }, () => {
      if (this.props.onUpdate && this.state.trip.Trip > 0) {
        this.props.onUpdate(this.state.trip);
      }
    });
  };

  renderDateLabel = (e) => {
    // window["log"](e);
    return (
      <TextField
        style={{ marginBottom: 12 }}
        label="Scheduled Date/Time"
        id="scheduledDate"
        name="ScheduledDate"
        onClick={() => this.setDatepickerOpen(true)}
        value={e.value}
        variant="outlined"
        fullWidth
        margin="dense"
      />
    );
  };

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString("en-US"));
    const mm = dt.getMonth() + 1;
    const dd = dt.getDate().toString();
    const yyyy = dt.getFullYear().toString();
    const formatted =
      (mm <= 9 ? "0" + mm : mm) + "/" + (dd <= 9 ? "0" + dd : dd) + "/" + yyyy;
    return formatted;
  }

  formatDateInput(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString("en-US"));
    const mm = dt.getMonth() + 1;
    const dd = dt.getDate();
    const yyyy = dt.getFullYear();
    const formatted =
      yyyy + "-" + (mm <= 9 ? "0" + mm : mm) + "-" + (dd <= 9 ? "0" + dd : dd);
    return formatted;
  }

  dragStart = (event) => {
    const { scopes } = this.props;
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        ...this.state,
        Data: { ...this.state.Data, Scopes: scopes || this.state.Data.Scopes },
      })
    );
    this.props.setDragging(true);
  };

  dragEnd = (event) => {
    this.props.setDragging(false);
  };

  getDateTime(date, time) {
    const dt = new Date(date);
    const tm = new Date(time);
    return dt.setTime(tm);
  }

  stop = (event) => {
    event.stopPropagation();
  };

  handleCall = (phone) => {
    const { toggleDialer, initCall, dialer } = this.props;
    if (phone.length === 7 || phone.length === 10 || phone.length === 12)
      toggleDialer(true);
    initCall(dialer, {
      destinationNumber: phone,
      audio: true,
      video: false,
    });
  };

  formatPhoneLink(str) {
    const phone = str.replace(/\(|\)|,|\.|-/g, "");
    return phone.length === 10
      ? `+1-${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6, 4)}`
      : str;
  }

  formatPhone(str) {
    return formatPhone(str).formatted;
  }

  setDatepickerOpen = (bool) => {
    this.setState({ ...this.state, isDatepickerOpen: bool });
  };

  handleRefresh = () => {
    let { date } = this.state
    const old = this.formatDateInput(date);
    const oldDate = this.formatDateInput(this.props.date);
    this.props.setBoardDate(this.props.workOrder.Co, date, old);
    this.updateDate(this.props.workOrder.Co, oldDate, oldDate);
  }

  updateDate = _.debounce((Co, date, oldDate) => {
    this.props.getTripData(Co, date, oldDate)
    this.props.getWOData(Co, date, oldDate)
    if (
      moment(date).format('M/D/YYYY') !== moment(oldDate).format('M/D/YYYY')
    ) {
      if (
        moment().format('M/D/YYYY') === moment(date).format('M/D/YYYY') &&
        document.getElementsByClassName('rt-table')
      ) {
        document.getElementsByClassName('rt-table')[0].scrollLeft =
          new Date().getHours() * 192
      } else {
        document.getElementsByClassName('rt-table')[0].scrollLeft = 8 * 192
      }
    }
  }, 1000)

  moveTentativeToUnassigned = () => {
    const { WorkOrder, Co, Data } = this.state;
    const { date, showMessage, scopeNum } = this.props;
    let tentative = [];
    if (scopeNum) {
      tentative = _.filter(Data.Scopes, (s) => {
        return (
          Boolean(s.LeadTechnician) &&
          s.Scope === this.props.scopeNum &&
          s.Type !== "A" &&
          s.Status !== 4 &&
          new Date(s.DueBy).toLocaleDateString("en-US") ===
            new Date(date).toLocaleDateString("en-US") &&
          s.Data.Assignments.length < 1
        );
      });
    } else {
      tentative = _.filter(Data.Scopes, (s) => {
        return (
          Boolean(s.LeadTechnician) &&
          s.Type !== "A" &&
          s.Status !== 4 &&
          new Date(s.DueBy).toLocaleDateString("en-US") ===
            new Date(date).toLocaleDateString("en-US") &&
          s.Data.Assignments.length < 1
        );
      });
    }

    let Scope;
    if (tentative.length > 0) {
      Scope = tentative[0].Scope;
    }

    const request = axios.delete(
      `${window["apiLocation"]}/api/Tentative?Co=${Co}&WorkOrder=${WorkOrder}&Scope=${Scope}`
    );

    return request
      .then((response) => {
        // window["warn"]("Successful moveTentativeToUnassigned request", response, response.data)

        showMessage({
          message: `Scope #${Scope} from Work Order #${WorkOrder} has been moved to Unassigned.`,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "success",
        });
        this.setState(prevState => ({ ...prevState }));
        this.handleRefresh();
        return true;
      })
      .catch((err) => {
        // window["warn"]("Unsuccessful moveTentativeToUnassigned request", err)
        return err;
      });
  };

  render() {
    const {
      classes,
      securables,
      services,
      user,
      id,
      fullScreen,
      unassigned,
      openCustomer,
      canClose,
      placeholder,
      openSite,
      showHold,
      openWorkOrder,
      openScope,
      openEditTripDialog,
      openNewTripDialog,
      technicians,
      businessUnits,
      divisions,
      departments,
      serviceTypes,
      arrivalWindows,
      workOrders,
      date,
    } = this.props;
    const {
      promptCancellation,
      showDesc,
      siteMenuEl,
      customerMenuEl,
      editWoMenuEl,
    } = this.state;
    const workOrder = this.state;
    if (!workOrder.ID) {
      return <div></div>;
    }
    if (placeholder) {
      let height = 100.16;
      if (showHold) {
        height += 64;
      }
      if (this.state.open || this.props.open) {
        if (unassigned) {
          height += 351;
        } else {
          height += 445.5;
        }
      }
      return (
        <div
          className="w-full rounded mt-8 mb-4 cursor-pointer"
          style={{ height, backgroundColor: "#f1f1f1" }}
        ></div>
      );
    }
    const wo = _.find(workOrders, { WorkOrder: workOrder.WorkOrder });
    if (wo && wo.Data && !workOrder.Data) {
      workOrder.Data = wo.Data;
    }
    const techAvatar = "assets/images/avatars/profile.jpg";
    const hold =
      workOrder && workOrder.Data && workOrder.Data.Trips
        ? _.findLast(workOrder.Data.Trips, (t) => {
            return (
              t.Status === 7 &&
              _.filter(
                workOrder.Data.Trips,
                (h) =>
                  h.Trip !== t.Trip &&
                  new Date(
                    h.InProgressDate ||
                      h.ArrivedDate ||
                      h.EnRouteDate ||
                      h.ScheduledDate
                  ) > new Date(t.OnHoldDate || t.ScheduledDate)
              ).length < 1
            );
          })
        : null;
    const holdTech = hold
      ? _.find(technicians, { Technician: hold.Technician })
      : null;
    const { value, technician, trip, tripValue, open } = this.state;
    const priorities = [
      { text: "Critical", color: "red" },
      { text: "High", color: "red" },
      { text: "Medium", color: "gold" },
      { text: "Low", color: "inherit" },
    ];
    const avatar = "assets/images/avatars/profile.jpg";
    const chAccess = _.find(securables, { Securable: "call-handler" });
    const accessLevel = _.find(securables, { Securable: "dispatch-board" });
    const statusProps = [
      {
        text: "Scheduled",
        icon: "access_time",
        color: "rgb(100, 100, 200)",
        dateProp: "ScheduledDate",
        timeProp: "ScheduledTime",
      },
      {
        text: "Notified",
        icon: "offline_bolt",
        color: "rgb(0, 150, 250)",
        dateProp: "NotifiedDate",
        timeProp: "NotifiedTime",
      },
      {
        text: "Accepted",
        icon: "offline_pin",
        color: "rgb(0, 50, 250)",
        dateProp: "AcceptedDate",
        timeProp: "AcceptedTime",
      },
      {
        text: "Rejected",
        icon: "cancel",
        color: "rgb(210, 0, 0)",
        dateProp: "RejectedDate",
        timeProp: "RejectedTime",
      },
      {
        text: "En-Route",
        icon: "explore",
        color: "rgb(50, 170, 200)",
        dateProp: "EnRouteDate",
        timeProp: "EnRouteTime",
      },
      {
        text: "Arrived",
        icon: "my_location",
        color: "rgb(50, 150, 100)",
        dateProp: "ArrivedDate",
        timeProp: "ArrivedTime",
      },
      {
        text: "In Progress",
        icon: "play_circle_outline",
        color: "rgb(150, 200, 50)",
        dateProp: "InProgressDate",
        timeProp: "InProgressTime",
      },
      {
        text: "On Hold",
        icon: "pause_circle_outline",
        color: "rgb(210, 100, 100)",
        dateProp: "OnHoldDate",
        timeProp: "OnHoldTime",
      },
      {
        text: "Completed",
        icon: "check_circle",
        color: "rgb(100, 100, 100)",
        dateProp: "CompletedDate",
        timeProp: "CompletedTime",
      },
    ];

    const scopes = this.props.scopes || workOrder.Data.Scopes;
    let priorityClass = null;
    if (scopes && scopes.length === 1) {
      if (scopes[0].Priority === 1) {
        priorityClass = classes.highPriority;
      }
      if (scopes[0].Priority === 2) {
        priorityClass = classes.medPriority;
      }
    }
    const noDate = _.filter(scopes, (o) => !o.DueBy);

    const siteContacts =
      workOrder.Data.Site && workOrder.Data.Site.Data
        ? workOrder.Data.Site.Data.Contacts
        : [];
    const custContacts =
      workOrder.Data.Customer && workOrder.Data.Customer.Data
        ? workOrder.Data.Customer.Data.Contacts
        : [];

    const contactsList = workOrder.isSiteContact ? siteContacts : custContacts;

    const tentative = _.filter(scopes, (s) => {
      return (
        Boolean(s.LeadTechnician) &&
        s.Type !== "A" &&
        s.Status !== 4 &&
        new Date(s.DueBy).toLocaleDateString("en-US") ===
          new Date(date).toLocaleDateString("en-US") &&
        s.Data.Assignments.length < 1
      );
    });

    return (
      <Card
        onClick={this.stop}
        onMouseDown={this.stop}
        onMouseMove={this.stop}
        onMouseEnter={this.stop}
        className={classNames(classes.card, "dispatch-work-order")}
      >
        <Tooltip
          title={workOrder.Description}
          placement="top"
          open={technician ? false : undefined}
          enterDelay={500}
        >
          <CardHeader
            onClick={
              canClose
                ? () => {
                    openWorkOrder(workOrder, null, true);
                  }
                : !technician
                ? this.handleOpen
                : () => {
                    openWorkOrder(workOrder, trip.Trip, true);
                  }
            }
            draggable={
              technician
                ? false
                : accessLevel && accessLevel.AccessLevel !== "R"
            }
            onDragStart={this.dragStart}
            onDragEnd={this.dragEnd}
            className={classNames(
              "p-12",
              "highlight",
              technician ? classes.trip : ""
            )}
            classes={
              !technician
                ? {
                    content: classes.content,
                    action: classes.action,
                    title: classes.title,
                    subheader: classes.subheader,
                    avatar: "mr-8",
                  }
                : {
                    content: classes.content,
                    action: classes.action,
                    avatar: classNames(classes.icon, "mr-8"),
                    title: classes.title,
                    subheader: classes.subheader,
                  }
            }
            avatar={
              !technician ? (
                <Badge
                  color="error"
                  classes={{ badge: classes.tripBadge }}
                  invisible={workOrder.Data.Trips.length < 1 ? true : false}
                  badgeContent={
                    <Badge
                      color="secondary"
                      classes={{ badge: classes.tripCountBadge }}
                      badgeContent={workOrder.Data.Trips.length}
                    >
                      <Icon className={classes.tripBadgeIcon}>
                        local_shipping
                      </Icon>
                    </Badge>
                  }
                >
                  <Badge
                    color="error"
                    classes={{ badge: classes.scopeBadge }}
                    invisible={scopes.length < 2 ? true : false}
                    badgeContent={scopes.length}
                  >
                    <Avatar
                      className={classNames(classes.avatar, priorityClass)}
                    >
                      <Icon>assignment</Icon>
                    </Avatar>
                  </Badge>
                </Badge>
              ) : (
                <Avatar
                  classes={{ root: classes.avatarRoot }}
                  className={classes.avatar}
                  alt={technician.name}
                  src={
                    technician.Data && technician.Data.Avatar
                      ? getProfileImage(`Co=${technician.Co}&ID=${technician.Data.Avatar}&Thumb=true`
                      ): avatar
                  }
                />
              )
            }
            action={
              !technician && !canClose && tentative.length > 0 ? (
                <>
                  <Tooltip placement="top" title="View Options">
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                          ...this.state,
                          editWoMenuEl: e.target,
                        });
                      }}
                      className="text-20 ml-4 mr-8 cursor-pointer align-middle"
                    >
                      more_vert
                    </Icon>
                  </Tooltip>
                  {editWoMenuEl && (
                    <Menu
                      onClick={(e) => e.stopPropagation()}
                      id="edit-wo-menu"
                      anchorEl={editWoMenuEl}
                      open={Boolean(editWoMenuEl)}
                      onClose={() =>
                        this.setState({ ...this.state, editWoMenuEl: null })
                      }
                    >
                      <MenuItem
                        onClick={(e) => {
                          openWorkOrder(workOrder, null, true);
                        }}
                      >
                        <Icon>open_in_new</Icon>View Work Order
                      </MenuItem>
                      {tentative.length > 0 && (
                        <MenuItem
                          onClick={async (e) => {
                            this.setState({ editWoMenuEl: null });
                            await this.moveTentativeToUnassigned();
                          }}
                        >
                          <Icon className="mr-8">send</Icon>Move to Unassigned
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </>
              ) : !technician && !canClose ? (
                <Tooltip placement="top" title="View Work Order">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      openWorkOrder(workOrder, null, true);
                    }}
                  >
                    <Icon>open_in_new</Icon>
                  </IconButton>
                </Tooltip>
              ) : (
                (isMobile || canClose) && (
                  <Tooltip placement="top" title="Close Info Window">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.clickHandler(e);
                      }}
                    >
                      <Icon>cancel</Icon>
                    </IconButton>
                  </Tooltip>
                )
              )
            }
            title={
              technician ? (
                <Typography className="flex text-14 font-bold">
                  {`Work Order #${workOrder.WorkOrder}`}
                  <Tooltip placement="top" title="View Options">
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                          ...this.state,
                          editWoMenuEl: e.target,
                        });
                      }}
                      className="text-20 ml-4 cursor-pointer align-middle"
                      style={{ zIndex: "500" }}
                    >
                      more_vert
                    </Icon>
                  </Tooltip>
                  {editWoMenuEl && (
                    <Menu
                      onClick={(e) => e.stopPropagation()}
                      id="edit-wo-menu"
                      anchorEl={editWoMenuEl}
                      open={Boolean(editWoMenuEl)}
                      onClose={() =>
                        this.setState({ ...this.state, editWoMenuEl: null })
                      }
                    >
                      <MenuItem
                        onClick={(e) => {
                          openWorkOrder(workOrder, null, true);
                        }}
                      >
                        <Icon>open_in_new</Icon>View Work Order
                      </MenuItem>
                      {tentative.length > 0 && (
                        <MenuItem
                          onClick={async (e) => {
                            this.setState({ editWoMenuEl: null });
                            await this.moveTentativeToUnassigned();
                          }}
                        >
                          <Icon className="mr-8">send</Icon>Move to Unassigned
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </Typography>
              ) : (
                `Work Order #${workOrder.WorkOrder}`
              )
            }
            subheader={
              <React.Fragment>
                {scopes && scopes.length === 1 ? (
                  <Typography className="flex text-11 truncate font-bold">
                    {scopes[0].Service} -{" "}
                    {
                      _.find(services, { Service: scopes[0].Service })
                        .Description
                    }
                  </Typography>
                ) : (
                  ""
                )}
                {!technician ? (
                  <Typography className="flex text-11 truncate">
                    {noDate.length > 0 && (
                      <Icon
                        className="text-14 mr-2 cursor-pointer justify-start"
                        style={{ paddingTop: 1 }}
                        color="error"
                      >
                        event_busy
                      </Icon>
                    )}
                    {workOrder.Description}
                  </Typography>
                ) : trip.Trip > 0 ? (
                  `Trip #${trip.Trip}`
                ) : (
                  "Tentative"
                )}
              </React.Fragment>
            }
          />
        </Tooltip>
        <div
          className="w-full p-4 px-12 truncate text-white text-center text-11 capitalize cursor-pointer"
          onClick={() => this.props.setSelectedWO(workOrder)}
          style={{ backgroundColor: "#3f3f3f" }}
        >
          <Icon color="secondary" className="mr-4 text-16 align-middle mb-2">
            location_searching
          </Icon>
          {workOrder.Data.Site.CrossStreets || workOrder.Data.Site.Zip}
        </div>
        {trip && trip.Notes && (
          <div className="w-full" style={{ borderTop: "1px dotted lightgrey" }}>
            <div className="text-grey-darkest text-12 p-8">
              {trip.Notes && (
                <div className="w-full text-center italic">"{trip.Notes}"</div>
              )}
            </div>
          </div>
        )}
        {!technician && showHold && hold && (
          <div
            className="w-full"
            onClick={() => this.props.openWorkOrder(workOrder, hold.Trip, true)}
            style={{ borderTop: "1px dotted lightgrey" }}
          >
            <div className="text-grey-darkest text-12 p-8">
              {holdTech ? (
                <div className="w-full flex justify-between">
                  <div className="flex font-bold">
                    {/* <Avatar style={{ marginRight: 6, width: 20, height: 20 }} className="align-middle" alt={holdTech.Technician} src={(holdTech.Data && holdTech.Data.Avatar ? getProfileImage(`Co=${holdTech.Co}&ID=${holdTech.Data.Avatar}&Thumb=true` : techAvatar)} /> */}
                    <Icon
                      color="error"
                      className="mr-4 text-20 align-middle mb-4"
                    >
                      pause_circle_outline
                    </Icon>
                    <div className="mt-4">{`${holdTech.FirstName} ${holdTech.LastName}`}</div>
                  </div>
                  <div className="mt-4 font-bold">
                    {moment(hold.OnHoldDate).format("M/D/YYYY")}
                  </div>
                </div>
              ) : (
                <div className="w-full font-bold">
                  <Icon
                    color="error"
                    className="mr-4 text-16 align-middle mb-4"
                  >
                    pause_circle_outline
                  </Icon>
                  Trip #{hold.Trip}
                </div>
              )}
              {hold.Notes && (
                <div className="w-full flex justify-center italic mt-4">
                  <Icon className="mr-4 text-16 align-middle mb-4">
                    speaker_notes
                  </Icon>
                  <div className="truncate">{hold.Notes}</div>
                </div>
              )}
            </div>
          </div>
        )}
        {(open || this.props.open || technician) && (
          <FuseAnimateGroup
            enter={{ animation: "transition.slideDownIn" }}
            leave={{ animation: "transition.slideUpOut" }}
          >
            <div>
              <AppBar
                color="primary"
                className={classNames(
                  classes.actions,
                  technician ? classes.trip : ""
                )}
              >
                <Tabs
                  value={value}
                  className={classNames(
                    classes.tabs,
                    technician ? classes.trip : ""
                  )}
                  onChange={this.handleChange}
                  variant="fullWidth"
                >
                  <Tooltip title="View Customer Details" placement="top">
                    <Tab
                      disableRipple
                      className={classes.tab}
                      icon={<Icon className="text-32">account_circle</Icon>}
                    />
                  </Tooltip>
                  <Tooltip title="View Site Details" placement="top">
                    <Tab
                      disableRipple
                      className={classes.tab}
                      icon={<Icon className="text-32">location_on</Icon>}
                    />
                  </Tooltip>
                  <Tooltip title="View Scope Details" placement="top">
                    <Tab
                      disableRipple
                      className={classes.tab}
                      icon={
                        <Badge
                          color="error"
                          badgeContent={
                            !technician
                              ? scopes.length
                              : trip.Data.Assignments.length
                          }
                        >
                          <Icon className="text-32">build</Icon>
                        </Badge>
                      }
                    />
                  </Tooltip>
                  {!technician && workOrder.Data.Trips.length == 0 ? (
                    <Tab
                      disableRipple
                      className={classes.tab}
                      disabled={!technician && workOrder.Data.Trips.length < 1}
                      icon={<Icon className="text-32">local_shipping</Icon>}
                    />
                  ) : (
                    <Tooltip title="View Trip Details" placement="top">
                      <Tab
                        disableRipple
                        className={classes.tab}
                        icon={
                          <Badge
                            color="error"
                            badgeContent={workOrder.Data.Trips.length}
                          >
                            <Icon className="text-32">local_shipping</Icon>
                          </Badge>
                        }
                      />
                    </Tooltip>
                  )}
                </Tabs>
                <WorkOrdersContactList
                  label={
                    this.state.isSiteContact
                      ? "Site Contacts"
                      : "Customer Contacts"
                  }
                  contact={null}
                  open={this.state.openWoContactList}
                  onUpdate={(Contacts) => {
                    if (this.state.isSiteContact) {
                      this.setState({
                        addContact: false,
                        Data: {
                          ...this.state.Data,
                          Site: {
                            ...workOrder.Data.Site,
                            Data: { ...workOrder.Data.Site.Data, Contacts },
                          },
                        },
                      });
                    } else {
                      this.setState({
                        addContact: false,
                        Data: {
                          ...this.state.Data,
                          Customer: {
                            ...workOrder.Data.Customer,
                            Data: { ...workOrder.Data.Customer.Data, Contacts },
                          },
                        },
                      });
                    }
                  }}
                  onCancel={() => this.setState({ addContact: false })}
                  details={contactsList || []}
                  site={this.state.isSiteContact ? workOrder.Data.Site : null}
                  // customer={this.state.isSiteContact ? null : Data.Customer}
                  customer={workOrder.Data.Customer}
                  setStateFxn={this.setState.bind(this)}
                />
              </AppBar>
              {value == 0 && (
                <TabContainer>
                  <CardContent
                    className={classNames(classes.info, classes.tripTabContent)}
                  >
                    <div
                      onClick={(e) =>
                        this.setState({ customerMenuEl: e.target })
                      }
                      className="w-full p-8 highlight"
                    >
                      <Typography
                        fullWidth
                        style={{ alignItems: "center", marginBottom: 0 }}
                        className="flex"
                      >
                        <Icon color="primary" className="text-24 mr-4">
                          account_circle
                        </Icon>
                        <strong>Customer #{workOrder.Customer}</strong>
                        <Tooltip placement="top" title="Customer Actions">
                          <Icon className="text-20 ml-8 cursor-pointer justify-end">
                            more_vert
                          </Icon>
                        </Tooltip>
                        {customerMenuEl && (
                          <Menu
                            onClick={(e) => e.stopPropagation()}
                            id="customer-menu"
                            anchorEl={customerMenuEl}
                            open={Boolean(customerMenuEl)}
                            onClose={() =>
                              this.setState({ customerMenuEl: null })
                            }
                          >
                            <MenuItem
                              onClick={() => {
                                openCustomer(workOrder.Data.Customer);
                                this.setState({ customerMenuEl: null });
                              }}
                            >
                              <Icon className="mr-8">open_in_new</Icon>View
                              Customer Details
                            </MenuItem>
                            {chAccess && chAccess.AccessLevel && (
                              <MenuItem
                                onClick={() => {
                                  history.push(`/apps/call-handler`);
                                  this.props.getCustomerData(
                                    workOrder.Data.Customer
                                  );
                                  this.setState({ customerMenuEl: null });
                                }}
                              >
                                <Icon className="mr-8">phone</Icon>Open in Call
                                Handler
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                this.setState({
                                  openWoContactList: true,
                                  isSiteContact: false,
                                  customerMenuEl: null,
                                })
                              }
                            >
                              <Icon className="mr-8">assignment_ind</Icon>
                              Customer Contacts
                            </MenuItem>
                          </Menu>
                        )}
                      </Typography>
                      <span className="text-xs">
                        {`${workOrder.Data.Customer.FirstName} ${workOrder.Data.Customer.LastName}`}
                        <br />
                        {`${workOrder.Data.Customer.Address1}`}
                        <br />
                        {`${workOrder.Data.Customer.City}, ${workOrder.Data.Customer.State} ${workOrder.Data.Customer.Zip}`}
                        <br />
                        {`${workOrder.Data.Customer.Phone}`}
                        <br />
                        {`${workOrder.Data.Customer.Email}`}
                        {/* {workOrder.Data.Customer.Data.Tags.length > 0 &&
                                                    <ChipInput
                                                        className={classes.formControl}
                                                        style={{ marginTop: 5, }}
                                                        id="tags"
                                                        name="Tags"
                                                        value={workOrder.Data.Customer.Data.Tags}
                                                        disabled
                                                        chipRenderer={({ value }, key) =>
                                                            value.ActiveYN == 'Y' && (
                                                                <Tooltip title={`Added By ${value.AddedBy} on ${new Date(value.AddedDate).toLocaleDateString('en-US')}`} placement="top">
                                                                    <Chip className="mb-8 mr-4" color="primary" icon={<Icon>account_circle</Icon>} label={value.Title} />
                                                                </Tooltip>
                                                            )
                                                        }
                                                        fullWidth
                                                        fullWidthInput
                                                    />
                                                } */}
                      </span>
                    </div>
                  </CardContent>
                </TabContainer>
              )}
              {value == 1 && (
                <TabContainer>
                  <CardContent
                    className={classNames(classes.info, classes.tripTabContent)}
                  >
                    <div
                      onClick={(e) => this.setState({ siteMenuEl: e.target })}
                      className="w-full p-8 highlight"
                    >
                      <Typography
                        fullWidth
                        style={{ alignItems: "center", marginBottom: 0 }}
                        className="flex"
                      >
                        <Icon color="primary" className="text-24 mr-4">
                          location_on
                        </Icon>
                        <strong>Site #{workOrder.Site}</strong>
                        <Tooltip placement="top" title="Site Actions">
                          <Icon className="text-20 ml-8 cursor-pointer justify-end">
                            more_vert
                          </Icon>
                        </Tooltip>
                        {siteMenuEl && (
                          <Menu
                            onClick={(e) => e.stopPropagation()}
                            id="site-menu"
                            anchorEl={siteMenuEl}
                            open={Boolean(siteMenuEl)}
                            onClose={() => this.setState({ siteMenuEl: null })}
                          >
                            <MenuItem
                              onClick={() => {
                                openSite(workOrder.Data.Site);
                                this.setState({ siteMenuEl: null });
                              }}
                            >
                              <Icon className="mr-8">open_in_new</Icon>View Site
                              Details
                            </MenuItem>
                            {chAccess && chAccess.AccessLevel && (
                              <MenuItem
                                onClick={() => {
                                  history.push("/apps/call-handler");
                                  this.props.getSiteData(workOrder.Data.Site);
                                  this.setState({ siteMenuEl: null });
                                }}
                              >
                                <Icon className="mr-8">phone</Icon>Open in Call
                                Handler
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                this.setState({
                                  openWoContactList: true,
                                  isSiteContact: true,
                                  siteMenuEl: null,
                                })
                              }
                            >
                              <Icon className="mr-8">person_pin</Icon>Site
                              Contacts
                            </MenuItem>
                          </Menu>
                        )}
                      </Typography>
                      {!technician && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classNames(
                            classes.black,
                            "w-full my-4 text-12"
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.setSelectedWO(workOrder);
                          }}
                        >
                          <Icon className="mr-4 text-20" color="secondary">
                            my_location
                          </Icon>
                          View on Map
                        </Button>
                      )}
                      <span className="text-xs">
                        {`${workOrder.Data.Site.Description}`}
                        <br />
                        {`${workOrder.Data.Site.Address1}`}
                        <br />
                        {`${workOrder.Data.Site.City}, ${workOrder.Data.Site.State} ${workOrder.Data.Site.Zip}`}
                        <br />
                        {`${workOrder.Data.Site.Phone}`}
                        <br />
                        {`${workOrder.Data.Site.Email}`}
                        {/* {workOrder.Data.Site.Data.Tags.length > 0 &&
                                                    <ChipInput
                                                        className={classes.formControl}
                                                        style={{ marginTop: 5, }}
                                                        id="tags"
                                                        name="Tags"
                                                        value={workOrder.Data.Site.Data.Tags}
                                                        disabled
                                                        chipRenderer={({ value }, key) =>
                                                            value.ActiveYN == 'Y' && (
                                                                <Tooltip title={`Added By ${value.AddedBy} on ${new Date(value.AddedDate).toLocaleDateString('en-US')}`} placement="top">
                                                                    <Chip className="mb-8 mr-4" color="primary" icon={<Icon>location_on</Icon>} label={value.Title} />
                                                                </Tooltip>
                                                            )
                                                        }
                                                        fullWidth
                                                        fullWidthInput
                                                    />
                                                } */}
                      </span>
                    </div>
                  </CardContent>
                </TabContainer>
              )}
              {value == 2 && scopes && (
                <TabContainer>
                  <CardContent
                    className={classNames(classes.info, classes.tripTabContent)}
                  >
                    {scopes.map((scope, index) => {
                      const arrivalWindow = _.find(this.props.arrivalWindows, {
                        ArrivalWindow: scope.ArrivalWindow,
                      });
                      const service = _.find(this.props.services, {
                        Service: scope.Service,
                      });
                      const assigned = trip
                        ? _.find(trip.Data.Assignments, { Scope: scope.Scope })
                        : false;
                      if (!technician || assigned) {
                        return (
                          <div
                            onClick={() => {
                              openScope({
                                Co: workOrder.Co,
                                WorkOrder: workOrder.WorkOrder,
                                Scope: scope.Scope,
                              });
                            }}
                            key={scope.ID}
                            style={{
                              borderTop:
                                index > 0 ? "1px dashed lightgrey" : "none",
                            }}
                            className="w-full p-8 highlight"
                          >
                            <Typography
                              fullWidth
                              style={{ alignItems: "center", marginBottom: 8 }}
                              className="flex relative"
                            >
                              <Icon color="primary" className="text-24 mr-4">
                                build
                              </Icon>
                              <strong>Scope #{scope.Scope}</strong>
                              <Tooltip placement="top" title="Edit Scope">
                                <Icon className="text-16 ml-8 cursor-pointer justify-end">
                                  open_in_new
                                </Icon>
                              </Tooltip>
                              {scope.Data.Assignments.length > 0 && (
                                <Badge
                                  color="secondary"
                                  classes={{
                                    badge: classes.tripCountBadge,
                                    root: "pin-r absolute mr-16",
                                  }}
                                  badgeContent={scope.Data.Assignments.length}
                                >
                                  <Avatar className={classes.tripAvatar}>
                                    <Icon className={classes.tripBadgeIcon}>
                                      local_shipping
                                    </Icon>
                                  </Avatar>
                                </Badge>
                              )}
                            </Typography>
                            <span className="text-xs">
                              <span className="text-12 pl-4">
                                <b className="text-12">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{ paddingTop: 1 }}
                                  >
                                    today
                                  </Icon>
                                  Due By:{" "}
                                </b>
                                {`${this.formatDate(scope.DueBy)}`}
                              </span>
                              <br />
                              {!scope.ExactTime &&
                                arrivalWindows &&
                                scope.ArrivalWindow && (
                                  <span className="text-12 pl-4">
                                    <b className="text-12">
                                      <Icon
                                        className="mr-6 text-14"
                                        style={{ paddingTop: 1 }}
                                      >
                                        access_time
                                      </Icon>
                                      Arrival Window:{" "}
                                    </b>
                                    {
                                      _.find(arrivalWindows, {
                                        BusinessUnit: scope.BusinessUnit,
                                        ArrivalWindow: scope.ArrivalWindow,
                                      }).Description
                                    }
                                    <br />
                                  </span>
                                )}
                              {scope.ExactTime && (
                                <span className="text-12 pl-4">
                                  <b className="text-12">
                                    <Icon
                                      className="mr-6 text-14"
                                      style={{ paddingTop: 1 }}
                                    >
                                      access_time
                                    </Icon>
                                    Arrival Time:{" "}
                                  </b>
                                  {moment(`1/1/1970 ${scope.ExactTime}`).format(
                                    "h:mm A"
                                  )}
                                  <br />
                                </span>
                              )}
                              <span className="text-12">
                                <b className="text-12 pl-4">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{ paddingTop: 1 }}
                                  >
                                    build
                                  </Icon>
                                  Service:{" "}
                                </b>
                                {
                                  _.find(services, { Service: scope.Service })
                                    .Description
                                }
                              </span>
                              <br />
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({ showDesc: !showDesc });
                                }}
                                className={classNames(
                                  "text-12 cursor-pointer w-full",
                                  !showDesc && "flex mt-4"
                                )}
                              >
                                <b className="w-full text-12 pl-4">
                                  <Icon
                                    className="mr-6 text-14 align-middle mb-2"
                                    style={{ paddingTop: 1 }}
                                  >
                                    speaker_notes
                                  </Icon>
                                  Description:{" "}
                                </b>
                                <span
                                  className={
                                    !showDesc ? "w-full truncate" : "w-full"
                                  }
                                >
                                  {scope.Description}
                                </span>
                              </span>
                              <span className="text-12">
                                <b className="text-12 pl-4">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{ paddingTop: 1 }}
                                  >
                                    settings
                                  </Icon>
                                  Service Type:{" "}
                                </b>
                                {
                                  _.find(serviceTypes, {
                                    ServiceType: scope.ServiceType,
                                  }).Description
                                }
                              </span>
                              <br />
                              {workOrder.ContactPhone && (
                                <React.Fragment>
                                  <span
                                    onClick={(e) => e.stopPropagation()}
                                    className="text-12 pl-4"
                                  >
                                    <b className="text-12">
                                      <Icon
                                        className="mr-6 text-14"
                                        color="primary"
                                        style={{ paddingTop: 1 }}
                                      >
                                        phone
                                      </Icon>
                                      Contact:{" "}
                                    </b>
                                    <Link
                                      href={`tel:${this.formatPhoneLink(
                                        workOrder.ContactPhone
                                      )}`}
                                      className={classes.colorPrimary}
                                      color="primary"
                                    >{`${workOrder.Contact ||
                                      this.formatPhone(
                                        workOrder.ContactPhone
                                      )}`}</Link>
                                  </span>
                                  <br />
                                </React.Fragment>
                              )}
                              <span className="text-12 pl-4">
                                <b className="text-12">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{
                                      color: priorities[scope.Priority].color,
                                      paddingTop: 1,
                                    }}
                                  >
                                    flag
                                  </Icon>
                                  Priority:{" "}
                                </b>
                                {`${priorities[scope.Priority].text}`}
                              </span>
                              <br />
                              <span className="text-12 pl-4">
                                <b className="text-12">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{ paddingTop: 1 }}
                                  >
                                    business_center
                                  </Icon>
                                  Business Unit:{" "}
                                </b>
                                {`${
                                  _.find(businessUnits, {
                                    BusinessUnit: scope.BusinessUnit,
                                  }).Description
                                }`}
                              </span>
                              <br />
                              <span className="text-12 pl-4">
                                <b className="text-12">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{ paddingTop: 1 }}
                                  >
                                    location_city
                                  </Icon>
                                  Division:{" "}
                                </b>
                                {`${
                                  _.find(divisions, {
                                    BusinessUnit: scope.BusinessUnit,
                                    Division: scope.Division,
                                  }).Description
                                }`}
                              </span>
                              <br />
                              <span className="text-12 pl-4">
                                <b className="text-12">
                                  <Icon
                                    className="mr-6 text-14"
                                    style={{ paddingTop: 1 }}
                                  >
                                    business
                                  </Icon>
                                  Department:{" "}
                                </b>
                                {`${
                                  _.find(departments, {
                                    BusinessUnit: scope.BusinessUnit,
                                    Division: scope.Division,
                                    Department: scope.Department,
                                  }).Description
                                }`}
                              </span>
                              <br />
                              {scope.Status === 4 && (
                                <div
                                  className="p-4 py-12 rounded mb-4 w-full cursor-pointer"
                                  style={{
                                    backgroundColor: "#f9f9f9",
                                    border: "1px dashed red",
                                  }}
                                >
                                  <Typography className="text-12 font-bold text-red w-full text-center">
                                    <Icon
                                      color="error"
                                      className="text-16 mr-4 align-middle mb-4"
                                    >
                                      cancel
                                    </Icon>
                                    CANCELLATION
                                  </Typography>
                                  <div className="w-full">
                                    <div className="italic text-11 mt-4 text-center">
                                      {scope.CancellationReason
                                        ? `"${scope.CancellationReason}"`
                                        : "No Reason Given"}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <Button
                                variant="contained"
                                color="primary"
                                className={classNames(
                                  "mt-4 text-10 w-full",
                                  classes.black
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (scope.Status === 0) {
                                    this.setState({
                                      promptCancellation: { scope },
                                    });
                                  } else {
                                    scope.Status = 0;
                                    scope.CancellationReason = null;
                                    this.setState(
                                      {
                                        ...this.state,
                                        open: false,
                                        Data: {
                                          ...this.state.Data,
                                          Scopes: scopes,
                                        },
                                      },
                                      () =>
                                        this.props.updateWorkOrderScope({
                                          ...scope,
                                        })
                                    );
                                  }
                                }}
                              >
                                <Icon
                                  color={
                                    scope.Status !== 4 ? "error" : "secondary"
                                  }
                                  className="mr-4 text-16"
                                >
                                  {scope.Status !== 4
                                    ? "cancel"
                                    : "check_circle"}
                                </Icon>
                                {scope.Status < 4
                                  ? "Cancel Scope"
                                  : "Re-Open Scope"}
                              </Button>
                              {this.state.promptCancellation &&
                                this.state.promptCancellation.scope && (
                                  <ConfirmationDialog
                                    open={this.state.promptCancellation}
                                    title={
                                      <div>
                                        <Icon
                                          className="mr-6 align-middle mb-4"
                                          color="error"
                                        >
                                          cancel
                                        </Icon>
                                        Cancel Scope #
                                        {promptCancellation.scope.Scope}
                                      </div>
                                    }
                                    content={
                                      <div className="w-full">
                                        Please enter a reason for canceling
                                        Scope #{promptCancellation.scope.Scope}{" "}
                                        on Work Order #
                                        {promptCancellation.scope.WorkOrder}:
                                        <br />
                                        <TextField
                                          className={"mt-12"}
                                          label="Reason for Cancellation"
                                          id="cancellationReason"
                                          name="promptCancellation.CancellationReason"
                                          value={
                                            this.state.promptCancellation
                                              .CancellationReason || ""
                                          }
                                          onChange={this.handleInputChange}
                                          variant="outlined"
                                          multiline
                                          rows={5}
                                          fullWidth
                                        />
                                      </div>
                                    }
                                    disableConfirm={
                                      !this.state.promptCancellation
                                        .CancellationReason ||
                                      this.state.promptCancellation
                                        .CancellationReason.length < 2
                                    }
                                    confirmText={"Cancel Scope"}
                                    cancelText="Never Mind"
                                    onCancel={() =>
                                      this.setState({
                                        ...this.state,
                                        promptCancellation: false,
                                      })
                                    }
                                    onConfirm={() => {
                                      promptCancellation.scope.Status = 4;
                                      this.setState(
                                        {
                                          ...this.state,
                                          promptCancellation: false,
                                          Data: {
                                            ...this.state.Data,
                                            Scopes: scopes,
                                          },
                                        },
                                        () =>
                                          this.props.updateWorkOrderScope({
                                            ...promptCancellation.scope,
                                            Status: 4,
                                            CancellationReason:
                                              promptCancellation.CancellationReason,
                                          })
                                      );
                                    }}
                                  />
                                )}
                              {/* {scope.Data.Tags.length > 0 &&
                                                                <ChipInput
                                                                    className={classes.formControl}
                                                                    style={{ marginTop: 5, }}
                                                                    id="tags"
                                                                    name="Tags"
                                                                    value={scope.Data.Tags}
                                                                    disabled
                                                                    chipRenderer={({ value }, key) =>
                                                                        value.ActiveYN == 'Y' && (
                                                                            <Tooltip title={`Added By ${value.AddedBy} on ${new Date(value.AddedDate).toLocaleDateString('en-US')}`} placement="top">
                                                                                <Chip className="mb-8 mr-4" color="primary" icon={<Icon>assignment</Icon>} label={value.Title} />
                                                                            </Tooltip>
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    fullWidthInput
                                                                />
                                                            } */}
                            </span>
                          </div>
                        );
                      }
                    })}
                  </CardContent>
                </TabContainer>
              )}
              {value == 3 && (
                <TabContainer>
                  <CardContent
                    className={classNames(classes.info, classes.tripTabContent)}
                  >
                    {workOrder.Data.Trips.map((value, index) => {
                      const technician = _.find(this.props.technicians, {
                        Technician: value.Technician,
                      });
                      return (
                        <div
                          onClick={() => {
                            openEditTripDialog(value);
                          }}
                          key={value.ID}
                          style={
                            (!technician && index > 0) || technician
                              ? { borderTop: "1px dashed lightgrey" }
                              : {}
                          }
                          className="w-full p-8 highlight"
                        >
                          <Typography
                            fullWidth
                            style={{ alignItems: "center", marginBottom: 0 }}
                            className="flex relative"
                          >
                            <Icon color="primary" className="text-24 mr-4">
                              local_shipping
                            </Icon>
                            <strong>{`Trip #${value.Trip}`}</strong>
                            <Tooltip placement="top" title="Edit Trip">
                              <Icon className="text-16 ml-8 cursor-pointer justify-end">
                                open_in_new
                              </Icon>
                            </Tooltip>
                            {value.Trip === trip.Trip && (
                              <Icon
                                color="action"
                                className="text-24 pin-r absolute"
                              >
                                remove_red_eye
                              </Icon>
                            )}
                          </Typography>
                          <span className="text-xs">
                            {`${technician.FirstName} ${technician.LastName}`}
                            <br />
                            {statusProps.map((status, index) => (
                              <div>
                                {index < value.Status &&
                                value[status.timeProp] ? (
                                  <span key={index}>
                                    {status.text} {index > 0 ? "at" : "for"}{" "}
                                    {index > 0
                                      ? moment(moment(value[status.timeProp]))
                                          .local()
                                          .toDate()
                                          .toLocaleTimeString("en-US")
                                      : moment(value[status.timeProp])
                                          .toDate()
                                          .toLocaleTimeString("en-US")}{" "}
                                    on{" "}
                                    {index > 0
                                      ? moment(moment(value[status.timeProp]))
                                          .local()
                                          .toDate()
                                          .toLocaleDateString("en-US")
                                      : moment(value[status.timeProp])
                                          .toDate()
                                          .toLocaleDateString("en-US")}
                                    <br />
                                  </span>
                                ) : index == value.Status &&
                                  value[status.timeProp] ? (
                                  <span key={index}>
                                    <b>{status.text}</b>{" "}
                                    {index > 0 ? "at" : "for"}{" "}
                                    <b>
                                      {index > 0
                                        ? moment(moment(value[status.timeProp]))
                                            .local()
                                            .toDate()
                                            .toLocaleTimeString("en-US")
                                        : moment(value[status.timeProp])
                                            .toDate()
                                            .toLocaleTimeString("en-US")}
                                    </b>{" "}
                                    on{" "}
                                    <b>
                                      {index > 0
                                        ? moment(moment(value[status.timeProp]))
                                            .local()
                                            .toDate()
                                            .toLocaleDateString("en-US")
                                        : moment(value[status.timeProp])
                                            .toDate()
                                            .toLocaleDateString("en-US")}
                                    </b>
                                    <br />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                            <strong>{`${value.Duration.toFixed(2)} Hr${
                              value.Duration > 1 ? "s" : ""
                            }`}</strong>
                            <div className="w-full flex">
                              {workOrder.Data.Scopes.map((scope) => {
                                const assigned = _.find(
                                  value.Data.Assignments,
                                  { Scope: scope.Scope }
                                );
                                return (
                                  <Avatar
                                    className={
                                      assigned
                                        ? classes.assignmentAvatar
                                        : classes.scopeAvatar
                                    }
                                  >
                                    {scope.Scope}
                                  </Avatar>
                                );
                              })}
                            </div>
                          </span>
                        </div>
                      );
                    })}
                  </CardContent>
                </TabContainer>
              )}
              {accessLevel && accessLevel.AccessLevel !== "R" && trip && (
                <React.Fragment>
                  <AppBar
                    color="primary"
                    className={classNames(
                      classes.actions,
                      classes.footer,
                      technician ? classes.trip : ""
                    )}
                  >
                    <Tabs
                      value={tripValue}
                      className={classNames(
                        classes.tabs,
                        technician ? classes.trip : ""
                      )}
                      onChange={this.handleAdminChange}
                      variant="fullWidth"
                    >
                      <Tooltip
                        title={!technician ? "Technicians" : "Trip Technician"}
                        placement="top"
                      >
                        <Tab
                          disableRipple
                          className={classes.tripAdminTab}
                          icon={
                            <Icon className="text-32">
                              {technician ? "assignment_ind" : "group"}
                            </Icon>
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Trip Scheduled Date" placement="top">
                        <Tab
                          disableRipple
                          className={classes.tripAdminTab}
                          icon={<Icon className="text-32">calendar_today</Icon>}
                        />
                      </Tooltip>
                      <Tooltip title="Trip Status" placement="top">
                        <Tab
                          disableRipple
                          className={classes.tripAdminTab}
                          icon={
                            <Icon className="text-32">
                              {statusProps[trip.Status].icon}
                            </Icon>
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Trip Duration" placement="top">
                        <Tab
                          disableRipple
                          className={classes.tripAdminTab}
                          icon={<Icon className="text-32">timer</Icon>}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          technician && trip.Trip > 0
                            ? "Delete Trip"
                            : "Assigned Scopes"
                        }
                        placement="top"
                      >
                        <Tab
                          disableRipple
                          className={classes.tripAdminTab}
                          icon={
                            <Icon className="text-32">
                              {technician && trip.Trip > 0 ? "delete" : "build"}
                            </Icon>
                          }
                        />
                      </Tooltip>
                    </Tabs>
                  </AppBar>
                  {tripValue == 0 && (
                    <TabContainer>
                      <CardContent className={classNames(classes.info)}>
                        <div className="pt-4">
                          <div className="flex">
                            <div className="min-w-36 pt-12 mt-6">
                              <Icon className="text-24" color="action">
                                {technician ? "assignment_ind" : "person_add"}
                              </Icon>
                            </div>
                            <Autocomplete
                              className={classes.formControl}
                              title={
                                technician ? "Technician" : "Add Technician"
                              }
                              options={technicians}
                              menuItemComponent={(value) => {
                                return (
                                  value.ActiveYN === "Y" && (
                                    <MenuItem
                                      value={value.Technician}
                                      disabled={
                                        _.findIndex(
                                          trip.Technicians,
                                          (o) => o === value.Technician
                                        ) > -1
                                      }
                                    >
                                      <div className="flex">
                                        <Avatar
                                          style={{ marginRight: 8 }}
                                          classes={{ root: classes.avatarRoot }}
                                          className={classes.avatarTech}
                                          alt={value.Technician}
                                          src={
                                            value.Data && value.Data.Avatar
                                              ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                              ): avatar
                                          }
                                        />
                                        {`${value.FirstName} ${value.LastName}`}
                                      </div>
                                    </MenuItem>
                                  )
                                );
                              }}
                              margin="dense"
                              value={trip.Technician}
                              onSelect={(option) => {
                                if (technician && trip.Trip > 0) {
                                  this.handleAdminTripChange({
                                    target: {
                                      name: "Technician",
                                      value: option.Technician,
                                    },
                                  });
                                } else {
                                  if (
                                    _.findIndex(
                                      trip.Technicians,
                                      (o) => o === option.Technician
                                    ) < 0
                                  ) {
                                    trip.Technicians.push(option.Technician);
                                    this.setState({ trip });
                                  }
                                }
                              }}
                              required
                              portal={true}
                            />
                          </div>
                          {(!technician || trip.Trip < 1) &&
                            trip.Technicians &&
                            trip.Technicians.length > 0 && (
                              <div className="mt-6">
                                {trip.Technicians.map((tech, index) => {
                                  const tk = _.find(technicians, {
                                    Technician: tech,
                                  });
                                  if (tk) {
                                    return (
                                      <div className="w-full flex mb-16 justify-center">
                                        <div className="w-full flex">
                                          <Avatar
                                            style={{ marginRight: 8 }}
                                            classes={{
                                              root: classes.avatarRoot,
                                            }}
                                            className={classes.avatarTech}
                                            alt={tk.Technician}
                                            src={
                                              tk.Data && tk.Data.Avatar
                                                ? getProfileImage(`Co=${tk.Co}&ID=${tk.Data.Avatar}&Thumb=true`
                                                ): avatar
                                            }
                                          />
                                          {`${tk.FirstName} ${tk.LastName}`}
                                        </div>
                                        <Icon
                                          color="action"
                                          onClick={() => {
                                            trip.Technicians.splice(index, 1);
                                            this.setState({ trip });
                                          }}
                                          className="mt-4 cursor-pointer"
                                        >
                                          close
                                        </Icon>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            )}
                        </div>
                      </CardContent>
                    </TabContainer>
                  )}
                  {tripValue == 1 && (
                    <TabContainer>
                      <CardContent className={classNames(classes.info)}>
                        <div className="pt-4">
                          <div className="flex">
                            <div className="min-w-36 pt-20">
                              <Icon className="text-24" color="action">
                                calendar_today
                              </Icon>
                            </div>
                            {!trip.ScheduledTime ? (
                              <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="Scheduled Date"
                                id="scheduledDate"
                                format="MM/DD/YYYY"
                                ref={(e) => (this.datePicker = e)}
                                margin="dense"
                                value={trip.ScheduledDate}
                                onChange={this.handleAdminTripDateChange}
                                fullWidth
                              />
                            ) : (
                              <DateTimePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="Scheduled Date"
                                id="scheduledDate"
                                format="MM/DD/YYYY h:mm A"
                                ref={(e) => (this.datePicker = e)}
                                margin="dense"
                                value={trip.ScheduledTime}
                                onChange={this.handleAdminTripDateChange}
                                fullWidth
                              />
                            )}
                          </div>
                          <div className="flex w-full justify-end">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={!trip.ScheduledTime}
                                  name="anytime"
                                  onChange={this.handleAnytimeChange}
                                  color="primary"
                                />
                              }
                              label="Anytime"
                            />
                          </div>
                        </div>
                      </CardContent>
                    </TabContainer>
                  )}
                  {tripValue == 2 && (
                    <TabContainer>
                      <CardContent className={classNames(classes.info)}>
                        <div className="pt-4">
                          <div className="flex">
                            <div className="min-w-36 pt-20">
                              <Icon className="text-24" color="action">
                                {statusProps[trip.Status].icon}
                              </Icon>
                            </div>
                            <TextField
                              className={classes.formControl}
                              label="Status"
                              id="Status"
                              name="Status"
                              margin="dense"
                              value={trip.Status}
                              onChange={this.handleAdminTripChange}
                              variant="outlined"
                              select
                              fullWidth
                            >
                              {statusProps.map((value, index) => (
                                <MenuItem key={index} value={index}>
                                  {`${value.text}`}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </div>
                      </CardContent>
                    </TabContainer>
                  )}
                  {tripValue == 3 && (
                    <TabContainer>
                      <CardContent className={classNames(classes.info)}>
                        <div className="pt-4">
                          <div className="flex">
                            <div className="min-w-36 pt-20">
                              <Icon className="text-24" color="action">
                                access_time
                              </Icon>
                            </div>
                            <TextField
                              className={classes.formControl}
                              label="Duration"
                              id="duration"
                              name="Duration"
                              margin="dense"
                              value={trip.Duration}
                              onChange={this.handleAdminTripChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                      </CardContent>
                    </TabContainer>
                  )}
                  {(!technician || trip.Trip < 1) && tripValue === 4 && (
                    <TabContainer>
                      <CardContent
                        className={classNames(classes.info, "px-16")}
                      >
                        <div className="w-full my-12">
                          {scopes.map((scope) => {
                            const assigned = _.findIndex(
                              trip.Data.Assignments,
                              { Scope: scope.Scope }
                            );
                            const service = _.find(services, {
                              Service: scope.Service,
                            });
                            return (
                              <div
                                onClick={() => {
                                  if (assigned > -1) {
                                    trip.Data.Assignments.splice(assigned, 1);
                                  } else {
                                    const { Co, WorkOrder, Scope } = scope;
                                    trip.Data.Assignments.push({
                                      Co,
                                      WorkOrder,
                                      Scope,
                                    });
                                  }
                                  this.setState({ trip });
                                }}
                                className="w-full cursor-pointer"
                              >
                                <ListItem
                                  classes={{ root: classes.liRoot }}
                                  className="relative mb-8 cursor-pointer"
                                >
                                  <Avatar
                                    style={{ marginRight: 8 }}
                                    classes={{ root: classes.avatarRoot }}
                                    className={classNames(
                                      classes.tripAssignmentAvatar,
                                      assigned > -1 && classes.assigned
                                    )}
                                  >
                                    {scope.Scope}
                                  </Avatar>
                                  <ListItemText
                                    classes={{ root: classes.liRoot }}
                                    primary={
                                      <div className="w-3/4 overflow-hidden truncate">
                                        <div
                                          className="font-bold text-14"
                                          style={{
                                            overflow: "hidden",
                                            width: "100%",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {service.Description}
                                        </div>
                                      </div>
                                    }
                                    secondary={
                                      <span className="font-bold text-12 truncate">
                                        {
                                          <Typography variant="caption">
                                            {scope.Description}
                                          </Typography>
                                        }
                                      </span>
                                    }
                                  />
                                  <Typography
                                    variant="caption"
                                    className="font-bold absolute pin-r pin-t mr-4"
                                    style={{ marginTop: 2 }}
                                  >
                                    {scope.DueBy &&
                                      new Date(scope.DueBy).toLocaleDateString(
                                        "en-US"
                                      )}
                                  </Typography>
                                </ListItem>
                              </div>
                            );
                          })}
                        </div>
                      </CardContent>
                    </TabContainer>
                  )}
                  {(!technician || trip.Trip < 1) &&
                    trip.Technicians.length > 0 &&
                    trip.ScheduledDate &&
                    trip.Duration &&
                    trip.Data.Assignments &&
                    trip.Data.Assignments.length > 0 && (
                      <div className="w-full px-8 pb-8">
                        <Button
                          className="w-full"
                          variant="contained"
                          color="primary"
                          onClick={this.createTrip}
                        >
                          Create Trip{trip.Technicians.length > 1 ? "s" : ""}
                        </Button>
                      </div>
                    )}
                </React.Fragment>
              )}
            </div>
          </FuseAnimateGroup>
        )}
      </Card>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openCustomer: openEditCustomerDialog,
      openSite: openEditCustomerSiteDialog,
      openWorkOrder: openEditWorkOrderDialog,
      openScope: openEditWorkOrderScopeDialog,
      setWorkOrderInfo: Actions.setWorkOrderInfo,
      setSelectedWO: Actions.setSelectedWO,
      createTrip: Actions.createTrip,
      setDragging: Actions.setDragging,
      setBoardDate: Actions.setBoardDate,
      getWOData: Actions.getWOData,
      getTripData: Actions.getTripData,
      openEditTripDialog,
      openNewTripDialog,
      toggleDialer: DialerActions.toggleDialer,
      initCall: DialerActions.initCall,
      updateWorkOrderScope,
      getCustomerData,
      getSiteData,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ spReducers, dispatchBoardApp, dialer }) {
  return {
    user: dispatchBoardApp.user,
    workOrders: spReducers.workOrders,
    technicians: spReducers.technicians,
    arrivalWindows: spReducers.arrivalWindows,
    serviceTypes: spReducers.serviceTypes,
    services: spReducers.services,
    date: dispatchBoardApp.dispatchBoard.date,
    dragging: dispatchBoardApp.dispatchBoard.dragging,
    dialer: dialer.sipPhone,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    securables: spReducers.userProfiles.User.Data.Securables,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DispatchBoardWorkOrder)
  )
);
