import axios from 'axios/index';
import { getUserData } from 'main/content/apps/employees/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_EMPLOYEES = '[EMPLOYEES APP] GET EMPLOYEES';
export const SET_SEARCH_TEXT = '[EMPLOYEES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_EMPLOYEES = '[EMPLOYEES APP] TOGGLE IN SELECTED EMPLOYEES';
export const SELECT_ALL_EMPLOYEES = '[EMPLOYEES APP] SELECT ALL EMPLOYEES';
export const DESELECT_ALL_EMPLOYEES = '[EMPLOYEES APP] DESELECT ALL EMPLOYEES';
export const OPEN_NEW_EMPLOYEE_DIALOG = '[EMPLOYEES APP] OPEN NEW EMPLOYEE DIALOG';
export const CLOSE_NEW_EMPLOYEE_DIALOG = '[EMPLOYEES APP] CLOSE NEW EMPLOYEE DIALOG';
export const OPEN_EDIT_EMPLOYEE_DIALOG = '[EMPLOYEES APP] OPEN EDIT EMPLOYEE DIALOG';
export const ADD_EDIT_EMPLOYEE_DIALOG = '[EMPLOYEES APP] ADD EDIT EMPLOYEE DIALOG';
export const UPDATE_EDIT_EMPLOYEE_DIALOG = '[EMPLOYEES APP] UPDATE EDIT EMPLOYEE DIALOG';
export const DATA_EDIT_EMPLOYEE_DIALOG = '[EMPLOYEES APP] DATA EDIT EMPLOYEE DIALOG';
export const CLOSE_EDIT_EMPLOYEE_DIALOG = '[EMPLOYEES APP] CLOSE EDIT EMPLOYEE DIALOG';
export const ADD_EMPLOYEE = '[EMPLOYEES APP] ADD EMPLOYEE';
export const UPDATE_EMPLOYEE = '[EMPLOYEES APP] UPDATE EMPLOYEE';
export const REMOVE_EMPLOYEE = '[EMPLOYEES APP] REMOVE EMPLOYEE';
export const REMOVE_EMPLOYEES = '[EMPLOYEES APP] REMOVE EMPLOYEES';
export const TOGGLE_STARRED_EMPLOYEE = '[EMPLOYEES APP] TOGGLE STARRED EMPLOYEE';
export const TOGGLE_STARRED_EMPLOYEES = '[EMPLOYEES APP] TOGGLE STARRED EMPLOYEES';
export const SET_EMPLOYEES_STARRED = '[EMPLOYEES APP] SET EMPLOYEES STARRED ';

export function getEmployees(routeParams) {
    const request = axios.get('/api/employees-app/employees', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_EMPLOYEES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedEmployees(employeeId) {
    return {
        type: TOGGLE_IN_SELECTED_EMPLOYEES,
        employeeId
    }
}


export function selectAllEmployees() {
    return {
        type: SELECT_ALL_EMPLOYEES
    }
}

export function deSelectAllEmployees() {
    return {
        type: DESELECT_ALL_EMPLOYEES
    }
}


export function openNewEmployeeDialog(data) {
    return {
        type: OPEN_NEW_EMPLOYEE_DIALOG,
        data
    }
}

export function closeNewEmployeeDialog() {
    return {
        type: CLOSE_NEW_EMPLOYEE_DIALOG
    }
}

export function openPreloadEmployeeDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_EMPLOYEE_DIALOG,
            data: data,
        })
    }
}

export function openEditEmployeeDialog(data) {
    return (dispatch, getState) => {

        const { Co, Customer, Employee } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Employee?Co=${encodeURIComponent(Co)}&Employee=${encodeURIComponent(Employee)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_EMPLOYEE_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function updateEditEmployeeDialog(data) {
    return (dispatch, getState) => {

        const { Co, Customer, Employee } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Employee?Co=${encodeURIComponent(Co)}&Employee=${encodeURIComponent(Employee)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DATA_EDIT_EMPLOYEE_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditEmployeeDialog() {
    return {
        type: CLOSE_EDIT_EMPLOYEE_DIALOG
    }
}

export function addEmployee(newEmployee) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Employee`,
            newEmployee
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_EDIT_EMPLOYEE_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Employee #${response.data.Employee} has been successfully created`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateEmployee(employee) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/Employee?Co=${encodeURIComponent(employee.Co)}&Employee=${encodeURIComponent(employee.Employee)}`,
            employee
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_EMPLOYEE_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Employee #${response.data.Employee} has been successfully updated`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeEmployee(employeeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().employeesApp.employees;

        const request = axios.post('/api/employees-app/remove-employee', {
            employeeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_EMPLOYEE
                })
            ]).then(() => dispatch(getEmployees(routeParams)))
        );
    };
}


export function removeEmployees(employeeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().employeesApp.employees;

        const request = axios.post('/api/employees-app/remove-employees', {
            employeeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_EMPLOYEES
                }),
                dispatch({
                    type: DESELECT_ALL_EMPLOYEES
                })
            ]).then(() => dispatch(getEmployees(routeParams)))
        );
    };
}

export function toggleStarredEmployee(employeeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().employeesApp.employees;

        const request = axios.post('/api/employees-app/toggle-starred-employee', {
            employeeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_EMPLOYEE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getEmployees(routeParams)))
        );
    };
}

export function toggleStarredEmployees(employeeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().employeesApp.employees;

        const request = axios.post('/api/employees-app/toggle-starred-employees', {
            employeeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_EMPLOYEES
                }),
                dispatch({
                    type: DESELECT_ALL_EMPLOYEES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getEmployees(routeParams)))
        );
    };
}

export function setEmployeesStarred(employeeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().employeesApp.employees;

        const request = axios.post('/api/employees-app/set-employees-starred', {
            employeeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_EMPLOYEES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_EMPLOYEES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getEmployees(routeParams)))
        );
    };
}

export function setEmployeesUnstarred(employeeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().employeesApp.employees;

        const request = axios.post('/api/employees-app/set-employees-unstarred', {
            employeeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_EMPLOYEES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_EMPLOYEES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getEmployees(routeParams)))
        );
    };
}
