import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import AgreementTypeTaskList from 'main/content/apps/agreement-type-tasks/AgreementTypeTasksList';
import AgreementTypeTaskHeader from 'main/content/apps/agreement-type-tasks/AgreementTypeTasksHeader';
import AgreementTypeTaskLeftSidebarHeader from 'main/content/apps/agreement-type-tasks/AgreementTypeTaskLeftSidebarHeader';
import AgreementTypeTaskRightSidebarHeader from 'main/content/apps/agreement-type-tasks/AgreementTypeTaskRightSidebarHeader';
import AgreementTypeTaskSidebarContent from 'main/content/apps/agreement-type-tasks/AgreementTypeTasksSidebarContent';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import AgreementTypeTaskDialog from 'main/content/apps/agreement-type-tasks/AgreementTypeTaskDialog';
import DivisionDialog from 'main/content/apps/divisions/DivisionDialog';
import DepartmentDialog from 'main/content/apps/departments/DepartmentDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class AgreementTypeTasksApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    render() {
        const { classes, openNewAgreementTypeTaskDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <AgreementTypeTaskHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <AgreementTypeTaskLeftSidebarHeader />
                            }
                            content={
                                <AgreementTypeTaskList />
                            }
                            leftSidebarContent={
                                <AgreementTypeTaskSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <AgreementTypeTaskDialog />
                        {/* <DivisionDialog /> */}
                        <DepartmentDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewAgreementTypeTaskDialog: Actions.openNewAgreementTypeTaskDialog
    }, dispatch);
}

function mapStateToProps({ agreementTypeTasksApp }) {
    return {
        agreementTypeTasks: agreementTypeTasksApp.agreementTypeTasks.entities,
        selectedAgreementTypeTaskIds: agreementTypeTasksApp.agreementTypeTasks.selectedAgreementTypeTaskIds,
        searchText: agreementTypeTasksApp.agreementTypeTasks.searchText,
        user: agreementTypeTasksApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementTypeTasksApp)));
