import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Avatar, Badge, IconButton, Icon, Typography, Checkbox, ListItem, Tooltip } from '@material-ui/core';
import TodoChip from './TodoChip';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import classNames from 'classnames';
import _ from '@lodash';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import { openCardDialog } from '../scrumboard/store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    todoList: {
        padding: 0
    },
    todoItem: {
        borderBottom: '1px solid  ' + theme.palette.divider,
        '&.completed': {
            background: 'rgba(0,0,0,0.03)',
            '& $todoTitle, & $todoNotes': {
                textDecoration: 'line-through'
            }
        }
    },
    todoTitle: {},
    todoNotes: {},
    labels: {},
    assigneeBadge: {
        top: 12,
        right: 12,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 24,
        height: 24,
        cursor: 'pointer',
        borderRadius: '50%'
    },
    assignees: {

    }
});

const initialState = {
    ID: null,
    Type: null,
    Title: null,
    Notes: null,
    StartDate: null,
    DueDate: null,
    CompletedYN: null,
    StarredYN: null,
    ImportantYN: null,
    Data: {
        Assignees: [],
        Tags: [],
        ProjectMembers: [],
        ProjectTags: [],
        ErrMsg: null
    }
};

class TodoListItem extends Component {

    state = {
        ...initialState,
    }

    componentDidMount() {
        const { todo } = this.props;
        this.setState({ ...todo });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { todo } = this.props;
        if (!_.isEqual(todo, prevProps.todo)) {
            this.setState({ ...todo });
        }
    }

    handleToggleImportant = () => {
        this.setState({
            ...this.state,
            ImportantYN: this.state.ImportantYN !== "Y" ? "Y" : null
        }, () => this.props.updateTodo(this.state));
    };

    handleToggleStarred = () => {
        this.setState({
            ...this.state,
            StarredYN: this.state.StarredYN !== "Y" ? "Y" : null
        }, () => this.props.updateTodo(this.state));
    };

    handleToggleCompleted = () => {
        this.setState({
            ...this.state,
            CompletedYN: this.state.CompletedYN !== "Y" ? "Y" : null
        }, () => this.props.updateTodo(this.state));
    };

    render() {
        const { labels, classes, openEditTodoDialog, openCardDialog, toggleImportant, toggleStarred, toggleCompleted, users, User, Co } = this.props;
        const todo = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const colors = ['#4dbce9', '#f44336', 'orange', '#4dbce9'];

        return (
            <ListItem
                onClick={(ev) => {
                    ev.preventDefault();
                    if (todo.Type === "P") {
                        openCardDialog(todo, true);
                    } else {
                        openEditTodoDialog(todo);
                    }
                }}
                dense
                button
                className={classNames(classes.todoItem, { "completed": todo.CompletedYN === "Y" }, "py-16  px-0 sm:px-8")}
            >

                <Checkbox
                    tabIndex={-1}
                    disableRipple
                    checked={todo.CompletedYN === "Y"}
                    onChange={this.handleToggleCompleted}
                    onClick={(ev) => ev.stopPropagation()}
                    disabled={todo.Type === "P" && todo.CompletedYN === "N"}
                />

                <div className="flex flex-1 flex-col relative overflow-hidden pl-8">

                    <Typography
                        variant="subtitle1"
                        className={classNames(classes.todoTitle, "truncate")}
                        color={todo.CompletedYN === "Y" ? "textSecondary" : "default"}
                    >
                        {todo.Type === "P" && <Icon className="mr-4 text-20 align-middle mb-4" color="action" style={{ color: todo.Priority ? colors[todo.Priority] : undefined }}>table_chart</Icon>}{todo.Title}
                    </Typography>

                    <Typography
                        color="textSecondary"
                        className={classNames(classes.todoNotes, "truncate")}
                    >
                        {_.truncate(todo.Notes, { 'length': 180 })}
                    </Typography>

                    <div className={classNames(classes.labels, "flex mt-4")}>
                        {Boolean(todo.DueDate) &&
                            <TodoChip
                                className={classNames("mr-4", (new Date(todo.DueDate) < new Date() && todo.CompletedYN != "Y") ? "bg-red text-white" : "")}
                                title={new Date(todo.DueDate).toLocaleDateString('en-US')}
                            />
                        }
                        {todo.Data.Tags.map(label => {
                            var lbl = _.find(labels, { ID: label.TagID });
                            if (lbl) {
                                return (
                                    <TodoChip
                                        className="mr-4"
                                        title={lbl.Name}
                                        background={lbl.Background}
                                        color={lbl.Color}
                                        key={label.TagID}
                                    />
                                );
                            }
                        })}
                        {todo.Data.ProjectTags && todo.Data.ProjectTags.map(label => {
                            var lbl = _.find(labels, { ID: label.TagID });
                            if (lbl) {
                                return (
                                    <TodoChip
                                        className="mr-4"
                                        title={lbl.Name}
                                        background={lbl.Background}
                                        color={lbl.Color}
                                        key={label.TagID}
                                    />
                                );
                            }
                        })}
                    </div>
                    <div className={classNames(classes.assignees, "flex justify-start pl-4", (todo.DueDate || todo.Data.Tags.length > 0) ? "mt-8" : "mt-4")}>
                        {todo.Data.Assignees && todo.Data.Assignees.map((asn) => {
                            const assignee = _.find(users, { UserName: asn.Assignee });
                            if (assignee) {
                                return (
                                    <Tooltip title={`${assignee.FirstName} ${assignee.LastName}`}>
                                        <Badge classes={{ badge: classNames(classes.assigneeBadge), root: "mx-8" }} badgeContent={`${assignee.FirstName.substr(0, 1)}${assignee.LastName.substr(0, 1)}`}>
                                            <Avatar className="-ml-12 w-24 h-24" src={assignee.Data && assignee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${assignee.Data.Avatar}`) : avatar} />
                                        </Badge>
                                    </Tooltip>
                                );
                            }
                        })}
                        {todo.Data.ProjectMembers && todo.Data.ProjectMembers.map((asn) => {
                            const assignee = _.find(users, { UserName: asn.UserName });
                            if (assignee) {
                                return (
                                    <Tooltip title={`${assignee.FirstName} ${assignee.LastName}`}>
                                        <Badge classes={{ badge: classNames(classes.assigneeBadge), root: "mx-8" }} badgeContent={`${assignee.FirstName.substr(0, 1)}${assignee.LastName.substr(0, 1)}`}>
                                            <Avatar className="-ml-12 w-24 h-24" src={assignee.Data && assignee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${assignee.Data.Avatar}`) : avatar} />
                                        </Badge>
                                    </Tooltip>
                                );
                            }
                        })}
                    </div>
                </div>

                <div className="px-8">
                    <IconButton
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            this.handleToggleImportant();
                        }}>
                        {todo.ImportantYN === "Y" ? (
                            <Icon style={{ color: red[500] }}>error</Icon>
                        ) : (
                            <Icon>error_outline</Icon>
                        )}
                    </IconButton>
                    <IconButton
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            this.handleToggleStarred();
                        }}>
                        {todo.StarredYN === "Y" ? (
                            <Icon style={{ color: amber[500] }}>star</Icon>
                        ) : (
                            <Icon>star_outline</Icon>
                        )}
                    </IconButton>
                </div>
            </ListItem>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditTodoDialog: Actions.openEditTodoDialog,
        updateTodo: Actions.updateTodo,
        openCardDialog
    }, dispatch);
}

function mapStateToProps({ todoApp, spReducers }) {
    return {
        labels: spReducers.calendar.tags,
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
        User: spReducers.userProfiles.User
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TodoListItem)));
