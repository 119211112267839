import axios from 'axios/index';

export const SET_TEXT_MESSAGE_DATA = '[SPCONNECTION] SET TEXT MESSAGE DATA';
export const GET_TEXT_MESSAGE_DATA = '[SPCONNECTION] GET TEXT MESSAGE DATA';
export const SUBSCRIBE_TEXT_MESSAGE_LIST = '[SPCONNECTION] SUBSCRIBE TEXT MESSAGE LIST';

export function getInitialTextMessageData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Text`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getTextMessageData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Text`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_TEXT_MESSAGE_DATA,
                payload: response.data
            })
        });
    }
}

export function setTextMessageData(data) {
    return {
        type: SET_TEXT_MESSAGE_DATA,
        payload: data
    };
}