import { FusePageCarded } from '@fuse';
import _ from '@lodash';
import { withStyles } from '@material-ui/core/styles';
import PurchaseOrderDialog from 'main/content/apps/purchase-orders/PurchaseOrderDialog';
import ReportingHeader from './ReportingHeader';
import ReportCategories from './ReportCategories'
import ReportingLeftSidebarHeader from './ReportingLeftSidebarHeader';
import ReportingSidebarContent from './ReportingSidebarContent';
import TimecardDialog from 'main/content/apps/timecards/TimecardDialog';
import ReportViewer from './ReportViewer';
import ReportParameters from './ReportParameters';
import Reports from './Reports';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class ReportingApp extends Component {

    state = {
        drawerOpen: false
    }
    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.report && !prevProps.report) || (this.props.report && prevProps.report && this.props.report.Report !== prevProps.report.Report)) {
            this.setState({ ...this.state, drawerOpen: true });
        }
    };

    toggleDrawer = (open) => {
        const { drawerOpen } = this.state;
        this.setState({ ...this.state, drawerOpen: open || !drawerOpen });
    }

    render() {
        const { classes, openNewReportingDialog, reporting, searchText, report, reportCategory, categories } = this.props;
        const { drawerOpen } = this.state;
        const category = reportCategory ? _.find(categories, { Category: (reportCategory || {}).Category }) : null;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <ReportingHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                report ?
                                    <React.Fragment>
                                        <ReportViewer toggle={() => this.toggleDrawer(true)} />
                                        <ReportParameters open={drawerOpen} onClose={() => this.toggleDrawer()} />
                                    </React.Fragment> :
                                    reportCategory && category ?
                                        <Reports /> :
                                        <ReportCategories />
                            }
                            leftSidebarHeader={
                                <ReportingLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                report ? 
                                <Reports sidebar={true} /> :
                                reportCategory && category &&
                                <ReportCategories sidebar={true} />
                                // <ReportingSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {/* <PurchaseOrderDialog /> */}
                        <TimecardDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers }) {
    return {
        reporting: reportingApp.reporting.entities,
        categories: spReducers.reporting.categories,
        searchText: reportingApp.reporting.searchText,
        user: reportingApp.user,
        type: reportingApp.reporting.type,
        loading: reportingApp.reporting.loading,
        status: reportingApp.reporting.status,
        glAccount: reportingApp.reporting.glAccount,
        vendor: reportingApp.reporting.vendor,
        report: reportingApp.reporting.selectedReport,
        reportCategory: reportingApp.reporting.reportCategory
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingApp)));
