import { LoginPageConfig } from 'main/content/pages/auth/login/LoginPageConfig';
import { Error404PageConfig } from 'main/content/pages/errors/404/Error404PageConfig';
import { Error500PageConfig } from 'main/content/pages/errors/500/Error500PageConfig';
import { StackErrorPageConfig } from 'main/content/pages/errors/stack/StackErrorPageConfig';
import { ProfilePageConfig } from 'main/content/pages/profile/ProfilePageConfig';

export const pagesConfigs = [
    LoginPageConfig,
    Error404PageConfig,
    Error500PageConfig,
    StackErrorPageConfig,
    ProfilePageConfig,
];