export function impress(file, max, quality, crop, callback) {
  if (file && file !== null) {
    var imgObject = {}
    var filesize = (file.size / 1024).toFixed(4)
    console.log('Original Size: ' + filesize + ' KB')
    var buffer = new FileReader()
    buffer.onload = function (e) {
      var imgOrientation = 1
      var view = new DataView(e.target.result)
      if (view.getUint16(0, false) != 0xffd8) {
        imgOrientation = -2
      } else {
        var length = view.byteLength,
          offset = 2
        while (offset < length) {
          var marker = view.getUint16(offset, false)
          offset += 2
          if (marker == 0xffe1) {
            if (view.getUint32((offset += 2), false) != 0x45786966) {
              imgOrientation = -1
              break
            }
            var little = view.getUint16((offset += 6), false) == 0x4949
            offset += view.getUint32(offset + 4, little)
            var tags = view.getUint16(offset, little)
            offset += 2
            for (var i = 0; i < tags; i++)
              if (view.getUint16(offset + i * 12, little) == 0x0112) {
                imgOrientation = view.getUint16(offset + i * 12 + 8, little)
                break
              }
          } else if ((marker & 0xff00) != 0xff00) break
          else offset += view.getUint16(offset, false)
        }
      }
      var orCss = [
        { rotate: 0, flip: false },
        { rotate: 0, flip: true },
        { rotate: 180, flip: false },
        { rotate: 180, flip: true },
        { rotate: 90, flip: true },
        { rotate: 90, flip: false },
        { rotate: 270, flip: true },
        { rotate: 270, flip: false },
      ]
      console.log('Image processing: ', buffer, orCss)
      var reader = new FileReader()
      reader.onload = function (event) {
        console.log('Image Reader data: ', event)
        var output_format = file.type.replace('image/', '')
        var src = document.createElement('IMG')
        var i = src
        i.src = event.target.result
        console.log('Image element: ', i)
        i.onload = function (e) {
          console.log('Image element data: ', e)
          var output_format = file.type.replace('image/', '')
          var source_image = this
          var width = this.naturalWidth
          var height = this.naturalHeight
          var mime_type = file.type
          var newWidth = width
          var newHeight = height
          if (width > height && width > max) {
            var scale = max / width
            newWidth = newWidth * scale
            newHeight = newHeight * scale
          }
          if (height > width && height > max) {
            var scale = max / height
            newWidth = newWidth * scale
            newHeight = newHeight * scale
          }
          console.log(width, height)
          console.log(newWidth, newHeight)
          //begin re-orientation
          console.log(imgOrientation)
          var rotation = orCss[imgOrientation - 1]
          console.log(rotation)
          var degrees = rotation ? rotation.rotate : 0
          var canvas = document.createElement('canvas')
          var ctx = canvas.getContext('2d')

          if (degrees == 90 || degrees == 270) {
            canvas.width = newHeight
            canvas.height = newWidth
          } else {
            canvas.width = newWidth
            canvas.height = newHeight
          }

          if (crop) {
            var size = newWidth > newHeight ? newHeight : newWidth
            canvas.height = size
            canvas.width = size
          }
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          if (degrees == 90 || degrees == 270) {
            ctx.translate(newHeight / 2, newWidth / 2)
          } else {
            ctx.translate(newWidth / 2, newHeight / 2)
          }
          ctx.rotate((degrees * Math.PI) / 180)
          ctx.drawImage(
            source_image,
            crop
              ? newWidth > newHeight
                ? -newWidth - -newHeight / 2
                : -newWidth / 2
              : -newWidth / 2,
            -newHeight / 2,
            newWidth,
            newHeight,
          )
          // end re-orientation
          var base = canvas.toDataURL(mime_type, quality / 100)
          var fnm = file.name
          var type = file.type
          var data = base.replace('data:' + type + ';base64,', '')
          var size = (data.length / 1024).toFixed(2) + ' KB'
          // var blobBin = atob(base.split(',')[1]);
          var blob = Buffer.from(data, 'base64')
          // var array = [];
          // for (var i = 0; i < blobBin.length; i++) {
          //     array.push(blobBin.charCodeAt(i));
          // }
          var newFile = new Blob([blob], { type })
          // const b64 = await fetch(data);
          // const newFile = await b64.blob();
          imgObject = {
            data: atob(data),
            file: newFile,
            base64: base,
            fileName: fnm,
            type: type,
            size: size,
          }
          callback(imgObject)
          canvas = null
          src = null
        }
      }
      reader.readAsDataURL(file)
    }
    buffer.readAsArrayBuffer(file)
  }
}
/*
export function impress64(base64, max, quality, crop, callback) {
    if (file && file !== null) {

        var imgObject = {};
        var filesize = ((file.size / 1024)).toFixed(4);
        console.log('Original Size: ' + filesize + ' KB');
        var buffer = new FileReader();
        buffer.onload = function (e) {
            var imgOrientation = 1;
            var view = new DataView(e.target.result);
            if (view.getUint16(0, false) != 0xFFD8) {
                imgOrientation = -2;
            } else {
                var length = view.byteLength, offset = 2;
                while (offset < length) {
                    var marker = view.getUint16(offset, false);
                    offset += 2;
                    if (marker == 0xFFE1) {
                        if (view.getUint32(offset += 2, false) != 0x45786966) {
                            imgOrientation = -1;
                            break;
                        }
                        var little = view.getUint16(offset += 6, false) == 0x4949;
                        offset += view.getUint32(offset + 4, little);
                        var tags = view.getUint16(offset, little);
                        offset += 2;
                        for (var i = 0; i < tags; i++)
                            if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                                imgOrientation = view.getUint16(offset + (i * 12) + 8, little);
                                break;
                            }
                    }
                    else if ((marker & 0xFF00) != 0xFF00) break;
                    else offset += view.getUint16(offset, false);
                }
            }
            var orCss = [
                { rotate: 0, flip: false },
                { rotate: 0, flip: true },
                { rotate: 180, flip: false },
                { rotate: 180, flip: true },
                { rotate: 90, flip: true },
                { rotate: 90, flip: false },
                { rotate: 270, flip: true },
                { rotate: 270, flip: false }
            ];
            console.log('Image processing: ', buffer, orCss);
            var reader = new FileReader();
            reader.onload = function (event) {
                console.log('Image Reader data: ', event);
                var output_format = file.type.replace('image/', '');
                var src = document.createElement("IMG");
                var i = src;
                i.src = event.target.result;
                console.log('Image element: ', i);
                i.onload = function (e) {
                    console.log('Image element data: ', e);
                    var output_format = file.type.replace('image/', '');
                    var source_image = this;
                    var width = this.naturalWidth;
                    var height = this.naturalHeight;
                    var mime_type = file.type;
                    var newWidth = width;
                    var newHeight = height;
                    if (width > height && width > max) {
                        var scale = max / width;
                        newWidth = newWidth * scale;
                        newHeight = newHeight * scale;
                    };
                    if (height > width && height > max) {
                        var scale = max / height;
                        newWidth = newWidth * scale;
                        newHeight = newHeight * scale;
                    };
                    console.log(width, height);
                    console.log(newWidth, newHeight);
                    //begin re-orientation
                    console.log(imgOrientation);
                    var rotation = orCss[imgOrientation - 1];
                    console.log(rotation);
                    var degrees = rotation ? rotation.rotate : 0;
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");

                    if (degrees == 90 || degrees == 270) {
                        canvas.width = newHeight;
                        canvas.height = newWidth;
                    } else {
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                    }

                    if (crop) {
                        var size = (newWidth > newHeight ? newHeight : newWidth);
                        canvas.height = size;
                        canvas.width = size;
                    }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    if (degrees == 90 || degrees == 270) {
                        ctx.translate(newHeight / 2, newWidth / 2);
                    } else {
                        ctx.translate(newWidth / 2, newHeight / 2);
                    }
                    ctx.rotate(degrees * Math.PI / 180);
                    ctx.drawImage(source_image, (crop ? (newWidth > newHeight ? -newWidth - (-newHeight / 2) : -newWidth / 2) : -newWidth / 2), -newHeight / 2, newWidth, newHeight);
                    // end re-orientation
                    var base = canvas.toDataURL(mime_type, quality / 100);
                    var fnm = file.name;
                    var type = file.type;
                    var data = base.replace('data:' + type + ';base64,', '');
                    var size = (data.length / 1024).toFixed(2) + " KB";
                    var blobBin = atob(base.split(',')[1]);
                    var array = [];
                    for (var i = 0; i < blobBin.length; i++) {
                        array.push(blobBin.charCodeAt(i));
                    }
                    var newFile = new Blob([new Uint8Array(array)], { type: type });
                    imgObject = {
                        data: atob(data),
                        file: newFile,
                        base64: base,
                        fileName: fnm,
                        type: type,
                        size: size
                    };
                    callback(imgObject);
                    document.removeChild(canvas);
                    document.removeChild(src);
                }

            };
            reader.readAsDataURL(file);
        }
    };
    buffer.readAsArrayBuffer(file);
}*/
