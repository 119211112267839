import * as Actions from 'store/actions/spConnection';

const initialState = {
    location: null,
    timestamp: null,
    gps: [],
    enabled: false,
    logs: [],
    errors: [],
    watchers: [],
    lastPosted: null,
}

const gps = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_GPS_DATA:
            {
                const { gps } = action;
                return {
                    ...state,
                    gps,
                }
            }
        case Actions.SET_GPS_LAST_POSTED:
            {
                const { lastPosted } = action;
                return {
                    ...state,
                    lastPosted
                }
            }
        case Actions.SET_GPS_WATCHERS:
            {
                const { watchers } = action;
                return {
                    ...state,
                    watchers
                }
            }
        case Actions.SET_GPS_DATA:
            {
                const { gps } = action;
                return {
                    ...state,
                    gps,
                }
            }
        case Actions.SET_GPS_WATCHER_ID:
            {
                const { id } = action;
                return {
                    ...state,
                    id
                }
            }
        case Actions.SET_GPS_ENABLED:
            {
                const { enabled } = action;
                return {
                    ...state,
                    enabled,
                }
            }
        case Actions.GET_GPS_LOCATION:
            {
                const { location, timestamp } = action;
                return {
                    ...state,
                    location,
                    timestamp,
                }
            }
        case Actions.SET_GPS_LOCATION:
            {
                const { location, timestamp } = action;
                return {
                    ...state,
                    location,
                    timestamp,
                }
            }
        case Actions.LOG_GPS_LOCATION:
            {
                const { logs } = action;
                return {
                    ...state,
                    logs
                }
            }
        case Actions.LOG_GPS_ERROR:
            {
                const { errors } = action;
                return {
                    ...state,
                    errors
                }
            }
    }

    return state;
};

export default gps;