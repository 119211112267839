import axios from 'axios/index'
import { getUserData } from 'main/content/apps/customers/store/actions/user.actions'
import {
  setSiteData,
  setCustomerData,
} from 'main/content/apps/call-handler/store/actions'
import { showMessage, addDialogHistory } from 'store/actions'
import { batch } from 'react-redux'
import _ from '@lodash'

export const GET_CUSTOMERS = '[CUSTOMERS APP] GET CUSTOMERS'
export const SET_SEARCH_TEXT = '[CUSTOMERS APP] SET SEARCH TEXT'
export const TOGGLE_IN_SELECTED_CUSTOMERS =
  '[CUSTOMERS APP] TOGGLE IN SELECTED CUSTOMERS'
export const SELECT_ALL_CUSTOMERS = '[CUSTOMERS APP] SELECT ALL CUSTOMERS'
export const DESELECT_ALL_CUSTOMERS = '[CUSTOMERS APP] DESELECT ALL CUSTOMERS'
export const OPEN_NEW_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] OPEN NEW CUSTOMER DIALOG'
export const CLOSE_NEW_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] CLOSE NEW CUSTOMER DIALOG'
export const OPEN_EDIT_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] OPEN EDIT CUSTOMER DIALOG'
export const DATA_EDIT_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] DATA EDIT CUSTOMER DIALOG'
export const CLOSE_EDIT_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] CLOSE EDIT CUSTOMER DIALOG'
export const ADD_EDIT_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] ADD EDIT CUSTOMER DIALOG'
export const UPDATE_EDIT_CUSTOMER_DIALOG =
  '[CUSTOMERS APP] UPDATE EDIT CUSTOMER DIALOG'
export const REMOVE_CUSTOMER = '[CUSTOMERS APP] REMOVE CUSTOMER'
export const REMOVE_CUSTOMERS = '[CUSTOMERS APP] REMOVE CUSTOMERS'
export const TOGGLE_STARRED_CUSTOMER = '[CUSTOMERS APP] TOGGLE STARRED CUSTOMER'
export const TOGGLE_STARRED_CUSTOMERS =
  '[CUSTOMERS APP] TOGGLE STARRED CUSTOMERS'
export const SET_CUSTOMERS_STARRED = '[CUSTOMERS APP] SET CUSTOMERS STARRED '
export const SET_CALL_HANDLER_DATA = '[CUSTOMERS APP] SET CALL HANDLER DATA'
export const OPEN_NEW_CUSTOMER_DNU_DIALOG =
  '[CUSTOMERS APP] OPEN NEW CUSTOMER DNU DIALOG'
export const CLOSE_NEW_CUSTOMER_DNU_DIALOG =
  '[CUSTOMERS APP] CLOSE NEW CUSTOMER DNU DIALOG'
export const OPEN_EDIT_CUSTOMER_DNU_DIALOG =
  '[CUSTOMERS APP] OPEN EDIT CUSTOMER DNU DIALOG'
export const UPDATE_EDIT_CUSTOMER_DNU_DIALOG =
  '[CUSTOMERS APP] UPDATE EDIT CUSTOMER DNU DIALOG'
export const CLOSE_EDIT_CUSTOMER_DNU_DIALOG =
  '[CUSTOMERS APP] CLOSE EDIT CUSTOMER DNU DIALOG'
export const ENABLE_LINE = '[CUSTOMERS APP] ENABLE LINE'

export function getCustomers(routeParams) {
  const request = axios.get('/api/customers-app/customers', {
    params: routeParams,
  })
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_CUSTOMERS,
        payload: response.data,
        routeParams,
      }),
    )
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function toggleInSelectedCustomers(customerId) {
  return {
    type: TOGGLE_IN_SELECTED_CUSTOMERS,
    customerId,
  }
}

export function selectAllCustomers() {
  return {
    type: SELECT_ALL_CUSTOMERS,
  }
}

export function deSelectAllCustomers() {
  return {
    type: DESELECT_ALL_CUSTOMERS,
  }
}

export function openNewCustomerDialog() {
  return {
    type: OPEN_NEW_CUSTOMER_DIALOG,
  }
}

export function closeNewCustomerDialog() {
  return {
    type: CLOSE_NEW_CUSTOMER_DIALOG,
  }
}

export function openPreloadCustomerDialog(data) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_EDIT_CUSTOMER_DIALOG,
      data: data,
    })
  }
}

export function openEditCustomerDialog(data, bool) {
  // window['warn'](data)
  return (dispatch, getState) => {
    const { Co, Customer, Name, FirstName, LastName } = data
    if (!bool) {
      dispatch(
        addDialogHistory({
          timestamp: new Date(),
          dialog: 'Customer',
          params: {
            Co,
            Customer,
            Name,
            FirstName,
            LastName,
          },
        }),
      )
    }
    dispatch({
      type: ENABLE_LINE,
      lineEnabled: false,
    })

    const request = axios.get(
      `${window['apiLocation']}/api/Customer?Co=${encodeURIComponent(
        Co,
      )}&Customer=${encodeURIComponent(Customer)}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: OPEN_EDIT_CUSTOMER_DIALOG,
          data: response.data,
        }),
        setTimeout(() => {
          dispatch({
            type: ENABLE_LINE,
            lineEnabled: true,
          })
        }, 2000),
      ]),
    )
  }
}

export function updateEditCustomerDialog(data) {
  return (dispatch, getState) => {
    dispatch(
      showMessage({
        message: `Refreshing Customer Data`,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'refresh',
      }),
    )

    const { Co, Customer } = data

    const request = axios.get(
      `${window['apiLocation']}/api/Customer?Co=${encodeURIComponent(
        Co,
      )}&Customer=${encodeURIComponent(Customer)}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: DATA_EDIT_CUSTOMER_DIALOG,
          data: response.data,
        }),
        dispatch(
          showMessage({
            message: `Customer Data Refreshed`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        ),
      ]),
    )
  }
}

export function closeEditCustomerDialog() {
  return {
    type: CLOSE_EDIT_CUSTOMER_DIALOG,
  }
}

export function addCustomer(newCustomer) {
  // window['warn'](newCustomer)
  return (dispatch, getState) => {
    const { routeParams } = getState().customersApp.customers

    const request = axios.post(
      `${window['apiLocation']}/api/Customer`,
      newCustomer,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: OPEN_NEW_CUSTOMER_DIALOG,
          data: response.data,
        }),
      ]).then(() => {
        const { Co, Customer, Name, FirstName, LastName } = response.data
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          window['warn']('CUSTOMER HERE', Customer, response)
          batch(() => {
            dispatch(
              showMessage({
                message: `Error: ${response.data.Data.ErrMsg}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              }),
            )
            dispatch(closeNewCustomerDialog())
            dispatch(openEditCustomerDialog(response.data))
          })
        } else if (response.data.Data.DuplicatedEntities.length === 0) {
          window['warn']('CUSTOMER HERE', Customer)
          batch(() => {
            dispatch(
              showMessage({
                message: `Customer #${response.data.Customer} has been successfully created.`,
                autoHideDuration: 30000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
            dispatch(closeNewCustomerDialog())
            dispatch(openEditCustomerDialog(response.data))
            dispatch(
              addDialogHistory({
                timestamp: new Date(),
                dialog: 'Customer',
                params: {
                  Co,
                  Customer,
                  Name,
                  FirstName,
                  LastName,
                },
              }),
            )
          })
        }

        return response.data
      }),
    )
  }
}

export function deactivatePaymentMethod(pm) {
  return (dispatch, getState) => {
    const request = axios.delete(
      `${window['apiLocation']}/api/PaymentMethod?Co=${pm.Co}&Customer=${pm.Customer}&PaymentMethodID=${pm.PaymentMethodID}`,
    )
    return request.then(response => {
      if (!response.data || response.data.length < 1) {
        const state = getState()
        const { data } = _.cloneDeepWith(
          state.customersApp.customers.customerDialog,
        )
        const { PaymentMethods } = data.Data
        const rec = _.find(PaymentMethods, {
          PaymentMethodID: pm.PaymentMethodID,
        })
        if (rec) {
          rec.ReusableYN = 'N'
        }
        dispatch({
          type: DATA_EDIT_CUSTOMER_DIALOG,
          data,
        })
      } else {
        dispatch(
          showMessage({
            message: `Error: ${response.data}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      }
    })
  }
}

export function updateCustomer(customer) {
  return (dispatch, getState) => {
    const state = getState()
    const { routeParams } = state.customersApp.customers

    const request = axios.put(
      `${window['apiLocation']}/api/Customer?Co=${encodeURIComponent(
        customer.Co,
      )}&Customer=${encodeURIComponent(customer.Customer)}`,
      customer,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: UPDATE_EDIT_CUSTOMER_DIALOG,
          data: response.data,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else if (response.data.Data.DuplicatedEntities.length === 0) {
          dispatch(
            showMessage({
              message: `Customer #${response.data.Customer} has been successfully updated.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
          const { callHandler } = state.callHandlerApp
          if (response.data.Customer === callHandler.data.Customer.Customer) {
            dispatch(
              setCustomerData({
                ...callHandler.data.Customer,
                ...response.data,
              }),
            )
          }
          if (response.data.Customer === callHandler.data.Site.Customer) {
            dispatch(
              setSiteData({
                ...callHandler.data.Site,
                Data: {
                  ...callHandler.data.Site.Data,
                  Customer: {
                    ...callHandler.data.Site.Data.Customer,
                    ...response.data,
                  },
                },
              }),
            )
          }
        }

        return response.data
      }),
    )
  }
}

export function deleteCustomer(customer) {
  return async dispatch => {
    const request = axios.delete(
      `${window['apiLocation']}/api/Customer?company=${encodeURIComponent(
        customer.Co,
      )}&customer=${encodeURIComponent(customer.Customer)}`,
    )

    const response = await request

    if (response.data === '') {
      Promise.all([
        dispatch({
          type: REMOVE_CUSTOMER,
        }),
      ]).then(() => {
        if (response.data === '') {
          dispatch(
            showMessage({
              message: `Customer #${customer.Customer} has been successfully deleted.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
    } else {
      dispatch(
        showMessage({
          message: `Customer #${customer.Customer} could not be deleted. ${response.data}`,
          autoHideDuration: 30000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        }),
      )
    }

    return response.data
  }
}

export function toggleStarredCustomer(customerId) {
  return (dispatch, getState) => {
    const { routeParams } = getState().customersApp.customers

    const request = axios.post('/api/customers-app/toggle-starred-customer', {
      customerId,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: TOGGLE_STARRED_CUSTOMER,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getCustomers(routeParams))),
    )
  }
}

export function toggleStarredCustomers(customerIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().customersApp.customers

    const request = axios.post('/api/customers-app/toggle-starred-customers', {
      customerIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: TOGGLE_STARRED_CUSTOMERS,
        }),
        dispatch({
          type: DESELECT_ALL_CUSTOMERS,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getCustomers(routeParams))),
    )
  }
}

export function setCustomersStarred(customerIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().customersApp.customers

    const request = axios.post('/api/customers-app/set-customers-starred', {
      customerIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: SET_CUSTOMERS_STARRED,
        }),
        dispatch({
          type: DESELECT_ALL_CUSTOMERS,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getCustomers(routeParams))),
    )
  }
}

export function setCustomersUnstarred(customerIds) {
  return (dispatch, getState) => {
    const { routeParams } = getState().customersApp.customers

    const request = axios.post('/api/customers-app/set-customers-unstarred', {
      customerIds,
    })

    return request.then(response =>
      Promise.all([
        dispatch({
          type: SET_CUSTOMERS_STARRED,
        }),
        dispatch({
          type: DESELECT_ALL_CUSTOMERS,
        }),
        dispatch(getUserData()),
      ]).then(() => dispatch(getCustomers(routeParams))),
    )
  }
}

export function openNewCustomerDNUDialog(customer) {
  return {
    type: OPEN_NEW_CUSTOMER_DNU_DIALOG,
    customer,
  }
}

export function closeNewCustomerDNUDialog() {
  return {
    type: CLOSE_NEW_CUSTOMER_DNU_DIALOG,
  }
}

export function openEditCustomerDNUDialog(data) {
  return (dispatch, getState) => {
    const { Co, Customer, Name, FirstName, LastName } = data

    const request = axios.get(
      `${window['apiLocation']}/api/CustomerDNU?Co=${encodeURIComponent(
        Co,
      )}&Customer=${encodeURIComponent(Customer)}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: OPEN_EDIT_CUSTOMER_DNU_DIALOG,
          data: response.data,
        }),
      ]),
    )
  }
}

export function updateEditCustomerDNUDialog(data) {
  return (dispatch, getState) => {
    const { Co, Customer } = data

    const request = axios.get(
      `${window['apiLocation']}/api/CustomerDNU?Co=${encodeURIComponent(
        Co,
      )}&Customer=${encodeURIComponent(Customer)}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: UPDATE_EDIT_CUSTOMER_DNU_DIALOG,
          data: response.data,
        }),
      ]),
    )
  }
}

export function closeEditCustomerDNUDialog() {
  return {
    type: CLOSE_EDIT_CUSTOMER_DNU_DIALOG,
  }
}

export function addCustomerDNU(data) {
  return (dispatch, getState) => {
    const request = axios.post(`${window['apiLocation']}/api/CustomerDNU`, data)

    return request.then(response => {
      if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
        dispatch(
          showMessage({
            message: `Error: ${response.data.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      } else {
        Promise.all([
          dispatch({
            type: CLOSE_EDIT_CUSTOMER_DNU_DIALOG,
          }),
          dispatch(updateEditCustomerDialog(data, false)),
        ])
        dispatch(
          showMessage({
            message: `Customer #${response.data.Customer} has been successfully merged with Customer #${response.data.Redirect}.`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        )
      }
    })
  }
}

export function removeCustomerDNU(data) {
  return (dispatch, getState) => {
    const { Co, Customer, Redirect } = data

    const request = axios.delete(
      `${window['apiLocation']}/api/CustomerDNU?Co=${Co}&Customer=${Customer}`,
    )

    return request.then(response => {
      if (response.data && response.data.length > 0) {
        dispatch(
          showMessage({
            message: `Error: ${response.data}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          }),
        )
      } else {
        Promise.all([
          dispatch({
            type: CLOSE_EDIT_CUSTOMER_DNU_DIALOG,
          }),
          dispatch(updateEditCustomerDialog(data, false)),
        ])
        dispatch(
          showMessage({
            message: `Customer #${Customer} has been successfully un-merged from Customer #${Redirect}.`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        )
      }
    })
  }
}

export function addRateTemplate(rateTemplate) {
  return (dispatch, getState) => {
    const state = getState()
    const { data } = state.customersApp.customers.customerDialog

    const request = axios.post(
      `${window['apiLocation']}/api/CustomerRateTemplate`,
      rateTemplate,
    )

    return request.then(response => {
      window['warn'](response)
      if (data.Data && data.Data.RateTemplates) {
        data.Data.RateTemplates.push(response.data)
      }
      Promise.all([
        dispatch({
          type: UPDATE_EDIT_CUSTOMER_DIALOG,
          data,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Rate Template has been successfully added.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
    })
  }
}

export function updateRateTemplate(rateTemplate) {
  return (dispatch, getState) => {
    const state = getState()
    const { data } = state.customersApp.customers.customerDialog

    const request = axios.put(
      `${
        window['apiLocation']
      }/api/CustomerRateTemplate?Co=${encodeURIComponent(
        rateTemplate.Co,
      )}&Customer=${rateTemplate.Customer}&RateTemplate=${encodeURIComponent(
        rateTemplate.RateTemplate,
      )}&BusinessUnit=${encodeURIComponent(
        rateTemplate.BusinessUnit,
      )}&Division=${encodeURIComponent(rateTemplate.Division)}`,
      rateTemplate,
    )

    return request.then(response => {
      const index = _.findIndex(data.Data.RateTemplates, {
        ID: response.data.ID,
      })
      if (index > -1) {
        data.Data.RateTemplates.splice(index, 1, response.data)
      }
      Promise.all([
        dispatch({
          type: UPDATE_EDIT_CUSTOMER_DIALOG,
          data,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Rate Template has been successfully updated.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
    })
  }
}

export function removeRateTemplate(rateTemplate) {
  return (dispatch, getState) => {
    const state = getState()
    const { data } = state.customersApp.customers.customerDialog

    const request = axios.delete(
      `${
        window['apiLocation']
      }/api/CustomerRateTemplate?Co=${encodeURIComponent(
        rateTemplate.Co,
      )}&Customer=${rateTemplate.Customer}&RateTemplate=${encodeURIComponent(
        rateTemplate.RateTemplate,
      )}&BusinessUnit=${encodeURIComponent(
        rateTemplate.BusinessUnit,
      )}&Division=${encodeURIComponent(rateTemplate.Division)}`,
    )

    return request.then(response => {
      const index = _.findIndex(data.Data.RateTemplates, {
        ID: rateTemplate.ID,
      })
      if (index > -1) {
        data.Data.RateTemplates.splice(index, 1)
      }
      Promise.all([
        dispatch({
          type: UPDATE_EDIT_CUSTOMER_DIALOG,
          data,
        }),
      ]).then(() => {
        if (response.data && response.data.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Rate Template has been successfully removed.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
    })
  }
}

export function getCustomerData(co, customer) {
  return (dispatch, getState) => {
    const request = axios.get(
      `${window['apiLocation']}/api/Customer?Co=${encodeURIComponent(
        co,
      )}&Customer=${encodeURIComponent(customer)}`,
    )

    return request.then(response => response.data)
  }
}
