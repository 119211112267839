import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Badge, Card, Chip, Typography, Avatar, Icon, IconButton, Tooltip, LinearProgress, withStyles, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as Actions from 'main/content/apps/scrumboard/store/actions';
import moment from 'moment';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    card: {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider
    },
    cardContent: {},
    cardFooter: {
        height: 48,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider
    },
    memberBadge: {
        top: 16,
        right: 20,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 34,
        height: 34,
        cursor: 'pointer',
        borderRadius: '50%'
    },
    addedByBadge: {
        top: 12,
        right: 12,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 26,
        height: 26,
        cursor: 'pointer',
        borderRadius: '50%',
        padding: 0
    },
    taskIdBadge: {
        // float: 'right',
        // display:"block",
        backgroundColor: 'grey',
        color: '#fff',
        // fontSize: '1.0rem',
        // height: '20px',
        // padding: 'auto 6px',
        // borderRadius: '6px'
    }
});

class BoardCard extends Component {

    state = {
        anchorEl: null,
        statusAnchorEl: null,
    }

    handleMenuClick = (ev) => {
        ev.stopPropagation();
        this.setState({ anchorEl: ev.currentTarget })
    }
    handleStatusMenuClick = (ev) => {
        ev.stopPropagation();
        this.setState({ statusAnchorEl: ev.currentTarget })
    }
    handleStatusMenuClose = (ev) => {
        ev.stopPropagation()
        this.setState({ statusAnchorEl: null });
    };

    handleCardClick = (ev, card) => {
        window["warn"]('Card Click:', card);
        ev.preventDefault();
        const { board } = this.props;

        const list = card && board ? _.find(board.Data.Lists, { ID: card.ListID }) : null;

        if (board) card.BoardName = board.Name;
        if (list) card.ListName = list.Name;

        this.props.openCardDialog(card, true);
    };

    handleMenuClose = (ev) => {
        ev.stopPropagation()
        this.setState({ anchorEl: null });
    };
    updateBoard = () => {
        this.props.getBoard(this.props.board.Co, this.props.board.ID);
    }

    updateCardCompleted = (ev, card) => {
        ev.stopPropagation()
        this.props.updateCard(card)
        this.setState({ anchorEl: null });
    }
    updateCardStatus = (event, ev, card) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ statusAnchorEl: null });
        const updatedCard = { ...card, ListID: ev.ID }
        this.props.updateCard(updatedCard, true, true)
            .then(() => {
                this.updateBoard();
            });
    }

    checkItemsChecked = (card) => {
        return card.Data.Checklists ? _.sum(card.Data.Checklists.map(list => _.sum(list.Data.Items.map(x => (x.CheckedYN == 'Y' ? 1 : 0))))) : 0;
    };

    checkItems = (card) => {
        return card.Data.Checklists ? _.sum(card.Data.Checklists.map(x => x.Data.Items.length)) : 0;
    };

    getAllAssignees = (card, assignees = []) => {
        let list = [...assignees, ...(card.Data ? card.Data.Members || [] : [])];
        if (card.children) {
            card.children.map((task) => {
                list = this.getAllAssignees(task, list);
            })
        }
        return _.uniqBy(list, 'UserName');
    }

    render() {
        const { anchorEl, statusAnchorEl } = this.state;
        const { classes, card, index, board, users, tags, securables, view, list, boardData } = this.props;
        // const checkItemsChecked = this.checkItemsChecked(card);
        // const checkItems = this.checkItems(card);
        const avatar = 'assets/images/avatars/profile.jpg';
        // const addedBy = _.find(users, { UserName: card.AddedBy });
        const accessLevel = _.find(securables, { Securable: "project-tracking" });
        const colors = ['#4dbce9', '#f44336', 'orange', '#4dbce9'];
        const subtasks = card ? _.filter(board.Data.Cards, { ParentID: card.ID }) : [];
        // const allAssignees = this.getAllAssignees(card);

        return (
            <React.Fragment>

                {/* {card.CoverImage && (
                    <img className="block" src={getProfileImage(`Co=${card.Co}&ID=${card.CoverImage}`} alt="card cover" />
                )} */}

                <div className={classNames(classes.cardContent, "p-12 flex justify-between items-center tasklist-row text-center")} style={{backgroundColor: "rgba(0,0,0,0.1)"}}>
                    <div className="relative dropdown-btn ml-4">
                        <strong>List</strong>
                    </div><div className="task-title">
                        <strong>Title</strong>
                    </div>

                    {/* <Typography className={classNames("font-600 task-title ", card.CompletedYN === "Y" && "line-through text-grey-dark")}>{card.CompletedYN === "Y" ? <Icon color="secondary" className="mr-4 align-middle text-20 mb-4 line-through-none">check_circle_outline</Icon> : (card.Priority && card.Priority !== 3) ? <Icon style={{ color: colors[card.Priority] }} className="mr-4 align-middle text-20 mb-4">flag</Icon> : ''}{card.Name}</Typography> */}
                    <div className={classNames( "px-12 flex justify-between tasklist-footer")} style={{ width: "40%" }}>
                        <div className="flex items-center">
                            <strong>Summary</strong>
                        </div>
                    </div>

                    <div className='ct-btn'>
                        <strong>ID</strong>
                    </div>

                  
                        <div className="flex flex-wrap mb-8 task-point">
                            <strong>Tags</strong>
                        </div>
                   


                  
                        <div className="flex items-center  mb-12 due-date">

                            <strong>Due Date</strong>

                        </div>
                   


                    <div className="flex flex-wrap cta-btn">
                        <strong>Assignees</strong>
                        <div className="">
                        </div>
                    </div>



                    <div className='complete-tesk-btn'>
                        <strong>Action</strong>
                    </div>

                </div>


    
            </React.Fragment>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCardDialog: Actions.openCardDialog,
        updateCard: Actions.updateCard,
        getBoard: Actions.getBoard,
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        board: scrumboardApp.board,
        users: spReducers.userProfiles.Users,
        tags: spReducers.calendar.tags,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardCard)));
