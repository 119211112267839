import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    gpsTransferListDialog: {
        type: 'new',
        props: {
            open: false,
        },
        data: {
            Co: null,
            List: 1,
            Material: null,
            Description: '',
            Location: null,
            Page: 1,
            PageSize: 10,
            TotalCount: 0,
            PageCount: 1,
            Results: [],
            LocationResults: [],
            Type: null,
        },
    },
};

const gpsInventoryTransferListReducer = function (state = initialState, action) {
    window["warn"]("gpsInventoryTransfersReducer", action, Actions)
    switch (action.type) {
        case Actions.GET_GPS_INVENTORY_TRANSFER_LIST_LOCATION_DATA:  
            {
                const { data } = action;
                const { Results, Location } = data;
                let LocationResults = Results;
                const open = state.gpsTransferListDialog.props.open;
                let currentData = state.gpsTransferListDialog.data;
                return {
                    ...state,
                    gpsTransferListDialog: {
                        type: 'edit',
                        props: {
                            open: open,
                        },
                        data: {
                            ...currentData,
                            Location,
                            LocationResults,
                            //...data,
                        },
                    }
                };
            }
        case Actions.DELETE_GPS_INVENTORY_TRANSFER_LIST_LOCATION_DATA:  
            {
                //const { data } = action;
                //const { Results, Location } = data;
                let LocationResults = [];
                const open = state.gpsTransferListDialog.props.open;
                let currentData = state.gpsTransferListDialog.data;
                return {
                    ...state,
                    gpsTransferListDialog: {
                        type: 'edit',
                        props: {
                            open: open,
                        },
                        data: {
                            ...currentData,
                            LocationResults,
                            //...data,
                        },
                    }
                };
            }
        case Actions.OPEN_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG:  
            {
                const { data } = action;
                window["warn"]("OPEN_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG ran!", data)
                return {
                    ...state,
                    gpsTransferListDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data: {
                            ...initialState.gpsTransferListDialog.data,
                            ...data,
                        },
                    }
                };
            }
        case Actions.CLOSE_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG:
            {
                return {
                    ...state,
                    gpsTransferListDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: {
                            ...initialState.gpsTransferListDialog.data,
                        }
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default gpsInventoryTransferListReducer;
