import React, { Component } from 'react';
import { TextField, Button, Avatar, FormControlLabel, Switch } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import _ from '@lodash';
import getProfileImage from '../../../../../../../../../functions/getProfileImageUrl';

class SupportRequestComment extends Component {
    state = {
        message: '',
        InternalYN: this.props.isSupportProvider ? 'Y' : 'N'
    };

    componentDidMount() {
        this.setState({ InternalYN: this.props.isSupportProvider ? 'Y' : 'N' });
    }

    handleChange = (event) => {
        this.setState(_.setIn(this.state, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    submit = (ev) => {
        const { Co, user, card } = this.props;
        const { BoardID, ListID, ID } = card;
        const { UserName } = user;
        const { InternalYN } = this.state;
        ev.preventDefault();
        if (!this.canSubmit()) {
            return;
        }
        this.props.onCommentAdd({ Co, BoardID, ListID, SupportRequestID: ID, UserName, Comment: this.state.message, InternalYN, AddedBy: UserName, AddedDate: new Date(), Data: { ErrMsg: null } });
        this.setState({ message: '', InternalYN: this.props.isSupportProvider ? 'Y' : 'N' })
    };

    canSubmit = () => {
        return this.state.message !== '';
    };

    render() {
        const { members, Co, user, isSupportProvider } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';

        return (
            <form onSubmit={this.submit} className="flex">
                <Avatar alt={user.FirstName} src={user && user.Data && user.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${user.Data.Avatar}`) : avatar} className="w-32 h-32" />
                <div className="flex flex-col items-start flex-1 pr-0 pl-16">
                    <TextField
                        className="flex flex-1"
                        fullWidth
                        name="message"
                        row={3}
                        value={this.state.message}
                        onChange={this.handleChange}
                        variant="outlined"
                        label="Add comment"
                        placeholder="Write a comment..."
                    />
                    <div className="w-full flex justify-between">
                        <Button
                            className="mt-16"
                            aria-label="save"
                            variant="contained"
                            color="secondary"
                            type="submit"
                            size="small"
                            disabled={!this.canSubmit()}
                        >
                            Save
                        </Button>
                        {isSupportProvider &&
                            <FormControlLabel
                                className="text-right mx-0"
                                control={
                                    <Switch
                                        checked={this.state.InternalYN === 'Y'}
                                        name="InternalYN"
                                        onChange={this.handleChange}
                                        color="primary"
                                    />
                                }
                                label="Internal"
                            />
                        }
                    </div>
                </div>
            </form>
        );
    }
}

function mapStateToProps({ auth, supportApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        card: supportApp.request
    }
}

export default connect(mapStateToProps)(SupportRequestComment);
