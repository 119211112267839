import axios from 'axios/index';

export const SET_ARRIVAL_WINDOW_DATA = '[SPCONNECTION] SET ARRIVAL WINDOW DATA';
export const GET_ARRIVAL_WINDOW_DATA = '[SPCONNECTION] GET ARRIVAL WINDOW DATA';
export const SUBSCRIBE_ARRIVAL_WINDOW_LIST = '[SPCONNECTION] SUBSCRIBE ARRIVAL WINDOW LIST';

export function getInitialArrivalWindowData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ArrivalWindow`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getArrivalWindowData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ArrivalWindow`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_ARRIVAL_WINDOW_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_ARRIVAL_WINDOW_LIST,
            Co
        }));
    }
}

export function setArrivalWindowData(data) {
    return {
        type: SET_ARRIVAL_WINDOW_DATA,
        payload: data
    };
}