import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Dialog, Divider, Fab, FormControlLabel, GridListTileBar, Hidden, Icon, IconButton, InputAdornment, MenuItem, Slide, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { impress } from 'main/content/compression/impress';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import Media from 'react-media';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import CatalogAssemblyImageDialog from './CatalogAssemblyImageDialog';
import * as Actions from './store/actions';
import CatalogRecommendationPopup from './CatalogRecommendationPopup';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 108px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const drawerWidth = 240;

const styles = theme => ({
    root: {
        // overflow: 'hidden',
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    backdrop: {
        zIndex: 9998,
        backgroundColor: 'rgba(0,0,0,.5)'
    },
    recommendations: {
        zIndex: 9999
    }
});

const newCatalogItemState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Name: null,
    Description: null,
    Assembly: null,
    Code: null,
    Type: 'T',
    PriceMethod: 'T',
    RateTemplate: null,
    Department: null,
    ServiceType: null,
    Service: null,
    Detail: null,
    Price: 0,
    MemberPrice: 0,
    AddOnPrice: 0,
    AddOnMemberPrice: 0,
    PremiumPrice: 0,
    PremiumAddOnPrice: 0,
    PremiumYN: 'N',
    AddonYN: null,
    TaxableYN: 'Y',
    DisplayYN: null,
    Notes: null,
    ActiveYN: 'Y',
    PRCode: null,
    EditFromFieldYN: null,
    CustomPrice: '',
    CustomMemberPrice: '',
    EnteredBy: null,
    UpdatedBy: null,
    EnteredDate: null,
    UpdatedDate: null,
    Data: {
        ErrMsg: null,
        Components: [],
        Assignments: [],
        Images: [],
        Recommendations: [],
        Upgrades: [],
        OptionBoard: {
            ID: null,
            Co: null,
            BusinessUnit: null,
            Division: null,
            OptionBoard: null,
            Name: null,
            Description: null,
            OptionsType: null,
            SharedYN: null,
            Notes: null,
            EnteredBy: null,
            EnteredDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            AttachmentID: null,
            Data: {
                ErrMsg: null,
                Options: [],
            },
            featureDescription: null,
            simpleStep: 0,
            optionsType: null,
            category: null,
            RecommendedOption: null,
            editOption: null
        }
    },
    Category: null,
    Qty: 1,
    showTabs: false,
    assembliesList: false,
    imageDialog: false,
    selectedImage: null,
    value: 0,
    saving: false,
    RecommendedAssembly: null,
    UpgradedAssembly: null,
    showRecommendations: false,
    estimate: null,
    option: null
};

var newOption = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Option: null,
    Name: null,
    Description: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    PriceTotal: 0.00,
    AttachmentID: null,
    Data: {
        Components: [],
        ErrMsg: null,
    },
    confirmDelete: false

}

class CatalogItemDialog extends Component {

    state = { ..._.cloneDeepWith(newCatalogItemState) };

    ref = React.createRef();

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidMount() {
        let { data, component, estimate } = this.props;
        // document.addEventListener('click', this.closeRecommendations, true);
        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = data || this.state;
                component = _.findLast(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        this.setState({ ..._.cloneDeepWith(newCatalogItemState), ...data, estimate, Name: (component ? component.Name || data.Name : data.Name), Description: (component ? component.Description || data.Description : data.Description), AddonYN: (component ? component.AddonYN : data.AddonYN), Qty: (component ? component.Qty : this.state.Qty), CustomPrice: (component ? component.CustomPrice : data.CustomPrice), CustomMemberPrice: (component ? component.CustomMemberPrice : data.CustomMemberPrice) });
    }

    componentDidUpdate = (prevProps, prevState) => {
        let { data, component, estimate } = this.props;

        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = data || this.state;
                component = _.findLast(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        if (data && (!_.isEqual(data, prevProps.data) || !_.isEqual(estimate, prevProps.estimate))) {
            this.setState({ ...(data.Assembly === this.state.Assembly ? this.state : _.cloneDeepWith(newCatalogItemState)), ...data, estimate, Description: (component ? component.Description || data.Description : data.Description), AddonYN: (component ? component.AddonYN : data.AddonYN), Qty: (component ? component.Qty : this.state.Qty), CustomPrice: (component ? component.CustomPrice : data.CustomPrice), CustomMemberPrice: (component ? component.CustomMemberPrice : data.CustomMemberPrice) });
        }
    }

    componentWillUnmount() {
        // document.removeEventListener('click', this.closeRecommendations, true);
    }

    handleChange = (event) => {
        let { name, value } = event.target;

        if (name === 'Qty') {
            value = value.replace(/^1/, 0)
        }

        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };
    handleBlur = (event) => {
        let { name, value } = event.target; 

        if (name === 'Qty' && value.trim() === '' || !value) { 
            this.setState({...this.state, Qty: 1}) 

        }
    } 

    canBeSubmitted() {
        const { Co, Name, Description, EditFromFieldYN, CustomPrice, CustomMemberPrice, estimate } = this.state;
        return (
            Co && Name && Description && Co > 0 && Name.length > 0 && Description.length > 0 && (!estimate || EditFromFieldYN !== "Y" || (CustomPrice !== undefined && CustomPrice !== null && CustomMemberPrice !== null && CustomMemberPrice !== undefined))
        );
    }

    fileSelectedHandler = (event) => {
        impress(event.target.files[0], 1024, 50, true, () => {
            // window["log"](img);
            // const fd = new FormData();
            // fd.append('image', img.file, img.fileName);
            // axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(this.state.Co)}&Type=Catalog&ID=${encodeURIComponent(this.state.Catalog)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
            //     if (Data) {
            //         Data.Avatar = img.base64;
            //     } else {
            //         Data = { Avatar: img.base64 };
            //     }
            //     this.setState({ Data });
            // });
        });
    }

    toggleTabs = () => {
        const { showTabs } = this.state;
        this.setState({ ...this.state, showTabs: !showTabs });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    increaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate && estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        Qty = Number(Qty || (component ? component.Qty : 1)) + 1;
        this.setState({ ...this.state, Qty });
    }

    decreaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate && estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }

        Qty = Math.max(Number(Qty || (component ? component.Qty : 0)) - 1, 0); 

        Qty = Qty === 0 ? 1 : Qty;

        this.setState({ ...this.state, Qty });
    }

    toggleAssemblies = (open, showRecommendations) => {
        this.setState({
            ...this.state,
            assembliesList: open,
            showRecommendations
        });
    };

    toggleImageDialog = (open) => {
        this.setState({ ...this.state, imageDialog: open });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    handleEditorChange = (Description) => {
        this.setState({ ...this.state, Description })
    }

    handleCustomMemberPriceChange = (e) => {
        const { catalog } = this.props;
        const { estimate } = this.state;
        this.setState({ CustomMemberPrice: e.target.value });
    }

    handleCustomPriceChange = (e) => {
        const { catalog } = this.props;
        const { estimate } = this.state;
        const { Co, BusinessUnit, Division } = estimate || {};
        const cat = _.find(catalog.catalogs, { Co, BusinessUnit, Division }) || {};
        this.setState({ CustomPrice: e.target.value, CustomMemberPrice: cat.MemberLaborDiscount ? e.target.value * ((100 - cat.MemberLaborDiscount) / 100).toFixed(2) : this.state.CustomMemberPrice });
    }

    closeRecommendations = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({ ...this.state, showRecommendations: false })
        }
    }

    deleteCatalogItem = () => {
        let { estimate, component } = this.state;
        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }

        const { Components } = _.cloneDeepWith(estimate.Data);
        const index = _.findIndex(Components, { Component: component.Component });
        const comp = _.find(Components, { Component: component.Component });

        if (comp.ID) {
            comp.DeleteYN = 'Y';
            Components.splice(index, 1, comp);
        } else {
            Components.splice(index, 1);
        }
        if (this.props.onUpdated) {
            this.props.onUpdated(Components);
        }

        this.setState({ component: null, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
    }

    render() {
        window["warn"](this.state);
        const { classes, theme, addAssembly, rateTemplates, updateAssembly, departments, serviceTypes, services, catalog, SelectedPricing } = this.props;
        // let { component } = this.props;
        let { estimate, component } = this.state;
        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        const { Co, BusinessUnit, Division, value, showTabs, assembliesList, selectedImage, saving, RecommendedAssembly, UpgradedAssembly, showRecommendations, Data } = this.state;
        if (this.state.Data && this.state.Data.Images && (!this.state.Data.Image || this.state.Data.Image.DeleteYN === "Y")) {
            this.state.Data.Image = _.find(this.state.Data.Images, (o) => (o.PrimaryYN === 'Y' && o.DeleteYN !== "Y"));
            if (!this.state.Data.Image) {
                this.state.Data.Image = _.filter(this.state.Data.Images, (o) => o.DeleteYN !== "Y")[0];
            }
        } else {
            const Images = _.cloneDeepWith(this.state.Data.Images);
            const imgIndex = _.findIndex(Images, { PrimaryYN: 'Y' });
            if (imgIndex > 0) {
                Images.splice(0, 0, Images.splice(imgIndex, 1)[0]);
                this.setState({ ...this.state, Data: { ...this.state.Data, Images } });
            }


        }

        const selectedCatalog = _.find(catalog.savedCatalogs, { Co, BusinessUnit, Division });
        const tasks = selectedCatalog ? selectedCatalog.Data.Assemblies : [];
        const categories = selectedCatalog ? selectedCatalog.Data.Categories : [];

        const activeAgreements = estimate && estimate.Data && estimate.Data.Site && estimate.Data.Site.Data && estimate.Data.Site.Data.Agreements ? _.filter(estimate.Data.Site.Data.Agreements, (o) => o.ActiveYN !== 'N' && new Date(o.StartDate) <= new Date() && new Date(o.EndDate) >= new Date()) : null;
        const isMember = activeAgreements && activeAgreements.length > 0;

        const toolbarOptions = [
            [{ size: ['small', false, 'large', 'huge'] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],

            ['link', 'image', 'video'],

            ['clean']                                       // remove formatting button
        ];

        const formats = [
            'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
            'list', 'bullet', 'indent', 'align',
            'link'
        ]

        const history = {
            delay: 2000,
            maxStack: 500,
            userOnly: true
        }
        let { Recommendations, Upgrades } = Data;

        const fullItem = selectedCatalog ? _.find(selectedCatalog.Data.Assemblies, { Assembly: this.state.Assembly }) : null;
        if (fullItem) {
            if (!Recommendations) {
                Recommendations = fullItem.Data.Recommendations;
            }
            if (!Upgrades) {
                Upgrades = fullItem.Data.Upgrades;
            }
        }

        window["warn"]("3", estimate)

        let sub = 0;
        if (estimate) {
            if (!estimate.SelectedPricing) {
                estimate.SelectedPricing = SelectedPricing;
            }
            const assemblyNormalPrice = this.state.Qty * (this.state.CustomPrice || (this.state.AddonYN === 'Y' ? this.state.AddOnPrice : this.state.Price));
            let assemblyMemberPrice = this.state.Qty * (this.state.CustomMemberPrice || (this.state.AddonYN === 'Y' ? this.state.AddOnMemberPrice : this.state.MemberPrice));
            let assemblyPremiumPrice = this.state.Qty * (this.state.AddonYN === 'Y' ? (this.state.PremiumAddOnPrice || this.state.AddOnPrice || this.state.CustomPrice || this.state.Price) : (this.state.PremiumPrice || this.state.CustomPrice || this.state.Price));
            sub = (estimate.SelectedPricing === "S" ? assemblyNormalPrice : estimate.SelectedPricing === "M" ? assemblyMemberPrice : estimate.SelectedPricing === "P" ? assemblyPremiumPrice : assemblyNormalPrice);

        }


        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <>
                        <Card className="w-full">
                            {value === 0 &&
                                <CardContent className={!estimate ? "p-24 pb-96" : "p-12 px-24 pb-96"}>
                                    <div>
                                        {estimate && this.state.EditFromFieldYN !== "Y" ?
                                            <Typography variant="h5" className="text-20 font-bold">
                                                {this.state.Name}
                                            </Typography>
                                            :
                                            <div className="w-full sm:flex">
                                                {!estimate &&
                                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                        <Icon color="action">chrome_reader_mode</Icon>
                                                    </div>
                                                }
                                                <TextField
                                                    className="w-full m-0"
                                                    label="Title"
                                                    id="name"
                                                    name="Name"
                                                    value={this.state.Name || ''}
                                                    margin="dense"
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{
                                                        // disableUnderline: true,
                                                        // className: "p-0"
                                                    }}
                                                    inputProps={{
                                                        className: "text-20 font-bold"
                                                    }}
                                                />
                                            </div>
                                        }
                                        <div className="sm:flex my-24 mb-0">
                                            <CatalogAssemblyImageDialog onClose={() => this.toggleImageDialog(false)} onChange={(Images) => this.setState({ ...this.state, Data: { ...this.state.Data, Images } })} open={this.state.imageDialog} assembly={this.state} images={this.state.Data.Images || []} videos={this.state.Data.videos || []} />
                                            <div className="w-full sm:max-w-1/2-12">
                                                {this.state.Data && this.state.Data.Image ?
                                                    <div className="w-full overflow-hidden mb-0 relative min-h-320 rounded max-h-512" style={{ border: '1px solid #f1f1f1' }}>
                                                        <div className={classNames(classes.blurBG, "pin-l pin-r pin-t pin-b m-auto absolute")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }}></div>

                                                        <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b m-auto absolute cursor-pointer")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }} onClick={() => { const selectedImage = _.findIndex(this.state.Data.Images, { ImgUrl: this.state.Data.Image.ImgUrl }); if (selectedImage > -1) { this.setState({ ...this.state, selectedImage }) } }}></div>

                                                        {/* <img className="w-full h-auto rounded cursor-pointer" style={{ border: '1px solid #f1f1f1' }} src={{ ...this.state }.Data.Image.ImgUrl} /> */}
                                                        {this.state.Data.Images && this.state.Data.Images[selectedImage] &&
                                                            <Dialog
                                                                open={this.state.Data.Images && this.state.Data.Images[selectedImage]}
                                                                onClose={() => this.setState({ ...this.state, selectedImage: null })}
                                                                fullWidth
                                                                maxWidth="sm"
                                                            >
                                                                <IconButton style={{
                                                                    position: 'absolute',
                                                                    right: 10,
                                                                    top: 10,
                                                                    zIndex: 3,
                                                                    color: 'white'
                                                                }}
                                                                    className={classes.closeIcon}
                                                                    onClick={() => this.setState({ ...this.state, selectedImage: null })}
                                                                >
                                                                    <Icon>close</Icon>
                                                                </IconButton>
                                                                {selectedImage > 0 &&
                                                                    <IconButton style={{
                                                                        position: 'absolute',
                                                                        left: 10,
                                                                        top: 'calc(50% - 12px)',
                                                                        zIndex: 3,
                                                                        color: 'white'
                                                                    }}
                                                                        className={classes.closeIcon}
                                                                        onClick={() => this.setState({ ...this.state, selectedImage: selectedImage - 1 })}
                                                                    >
                                                                        <Icon className="text-32 text-grey-lighter">chevron_left</Icon>
                                                                    </IconButton>
                                                                }
                                                                {(selectedImage < (this.state.Data.Images.length - 1) && selectedImage < this.state.Data.Images.length - 1) &&
                                                                    <IconButton style={{
                                                                        position: 'absolute',
                                                                        right: 10,
                                                                        top: 'calc(50% - 12px)',
                                                                        zIndex: 3,
                                                                        color: 'white'
                                                                    }}
                                                                        className={classes.closeIcon}
                                                                        onClick={() => this.setState({ ...this.state, selectedImage: selectedImage + 1 })}
                                                                    >
                                                                        <Icon className="text-32 text-grey-lighter">chevron_right</Icon>
                                                                    </IconButton>
                                                                }
                                                                <img className="w-full" src={this.state.Data.Images[selectedImage].ImgUrl} />
                                                                <GridListTileBar
                                                                    // title={this.state.Data.Images[selectedImage].ID}
                                                                    titlePosition="top"
                                                                    actionIcon={!estimate &&
                                                                        <IconButton onClick={() => {
                                                                            const { Images } = this.state.Data;
                                                                            Images.map((Img) => {
                                                                                if (Img.ID == this.state.Data.Images[selectedImage].ID) {
                                                                                    Img.PrimaryYN = 'Y';
                                                                                } else {
                                                                                    Img.PrimaryYN = 'N';
                                                                                }
                                                                            });
                                                                            this.setState({ ...this.state, Data: { ...this.state.Data, Images }, selectedImage: 0 });
                                                                        }} className={classes.doneIcon}>
                                                                            <Icon className={this.state.Data.Images[selectedImage].PrimaryYN === 'Y' ? " text-yellow" : "text-white"}>{this.state.Data.Images[selectedImage].PrimaryYN === 'Y' ? 'star' : 'star_border-outline'}</Icon>
                                                                        </IconButton>
                                                                    }
                                                                    actionPosition="left"
                                                                    className={classNames(classes.titleBar, "pt-12")}
                                                                />
                                                            </Dialog>
                                                        }
                                                    </div> :
                                                    <div className="w-full sm:mr-16 mb-0 cursor-pointer max-h-512">
                                                        <div onClick={() => this.toggleImageDialog(true)} className="w-full h-full rounded min-h-256 relative" style={{ border: '1px solid lightgrey' }}>
                                                            <Icon color="action" className="text-64 absolute pin-l pin-r pin-t pin-b m-auto">add_photo_alternate</Icon>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.Data && this.state.Data.Images &&
                                                    <div className="mb-12 sm:mb-24">
                                                        <div className="w-full mt-8 overflow-auto">
                                                            <div className="flex pb-8">
                                                                {this.state.Data && this.state.Data.Images && _.filter(this.state.Data.Images, (o) => o.DeleteYN !== "Y").map((value) => {
                                                                    return (
                                                                        <div key={value.ID || value.UploadID} onClick={() => this.setState({ ...this.state, Data: { ...this.state.Data, Image: value } })} className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 cursor-pointer" style={{ backgroundImage: `url(${value.ImgUrl})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                                    );
                                                                })}
                                                                {!estimate &&
                                                                    <div className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 relative rounded" >
                                                                        <Fab onClick={() => this.toggleImageDialog(true)} className="w-36 h-36 pin-l pin-r pin-t pin-b m-auto absolute" color="primary"><Icon className="text-20">add_photo_alternate</Icon></Fab>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <div className="w-full sm:pt-0">
                                                {estimate &&
                                                    <React.Fragment>
                                                        <div className="">
                                                            <Typography className="text-16 font-bold mb-4">Quantity</Typography>
                                                            <div className="flex mb-16">
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={this.decreaseQty}
                                                                    className={classNames(classes.error, "p-0")}
                                                                    style={{ minWidth: 38, minHeight: 'unset', height: 38 }}
                                                                >
                                                                    <Icon>remove</Icon>
                                                                </Button>
                                                                <TextField
                                                                    className="w-96 m-0 ml-8 mr-8"
                                                                    id="qty"
                                                                    name="Qty"
                                                                    value={this.state.Qty || (component ? component.Qty : '')}
                                                                    margin="dense"
                                                                    onChange={this.handleChange}
                                                                    onBlur={this.handleBlur}
                                                                    // onChange={(e) => this.setState({Qty: e.target.value})}  
                                                                    variant="outlined"
                                                                    InputProps={{ type: 'number' }}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.increaseQty}
                                                                    className="p-0"
                                                                    style={{ minWidth: 38, minHeight: 'unset', height: 38 }}
                                                                >
                                                                    <Icon>add</Icon>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <Divider className="mb-8" />
                                                    </React.Fragment>
                                                }
                                                {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                    <div className="flex">
                                                        <div className="mr-32">
                                                            <div>
                                                                {estimate.SelectedPricing === "P" ?
                                                                    <Typography className="text-16 font-bold mb-4" style={{ color: theme.palette.primary.main }}>Premium Price</Typography>
                                                                    :
                                                                    <Typography className="text-16 font-bold mb-4" style={{ color: theme.palette.primary.main }}>Member Price</Typography>
                                                                }
                                                                {this.state.AddonYN !== "Y" ?
                                                                    <>
                                                                        {estimate.SelectedPricing === "P" ?
                                                                            <Typography className="text-20 mb-16">{this.formatDollars(this.state.PremiumPrice || 0.00)}</Typography>
                                                                            :
                                                                            <Typography className="text-20 mb-16">{this.formatDollars(this.state.MemberPrice || 0.00)}</Typography>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {estimate.SelectedPricing === "P" ?
                                                                            <Typography className="text-20 mb-16">{this.formatDollars(this.state.PremiumAddOnPrice || 0.00)}</Typography>
                                                                            :
                                                                            <Typography className="text-20 mb-16">{this.formatDollars(this.state.AddOnMemberPrice || 0.00)}</Typography>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            {estimate &&
                                                                <div>
                                                                    <Typography className="text-16 font-bold mb-4">Price</Typography>
                                                                    {this.state.AddonYN !== "Y" ?
                                                                        <Typography className="text-20 mb-16">{this.formatDollars(this.state.Price || 0.00)}</Typography>
                                                                        :
                                                                        <Typography className="text-20 mb-16">{this.formatDollars(this.state.AddOnPrice || 0.00)}</Typography>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                }
                                                {estimate && this.state.EditFromFieldYN === "Y" &&
                                                    <div className="flex">
                                                        <div className="mr-16">
                                                            <div>
                                                                <Typography className="text-16 font-bold mb-4" style={{ color: theme.palette.primary.main }}>Member Price</Typography>
                                                                <TextField className="text-20 mb-16" onBlur={() => this.setState({ CustomMemberPrice: Number(this.state.CustomMemberPrice).toFixed(2) })} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} margin="dense" variant="outlined" value={this.state.CustomMemberPrice || ''} name="CustomMemberPrice" onChange={this.handleCustomMemberPriceChange} />
                                                            </div>
                                                        </div>
                                                        <div className="ml-16">
                                                            <div>
                                                                <Typography className="text-16 font-bold mb-4">Price</Typography>
                                                                <TextField className="text-20 mb-16" onBlur={() => this.setState({ CustomPrice: Number(this.state.CustomPrice).toFixed(2) })} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} margin="dense" variant="outlined" value={this.state.CustomPrice || ''} name="CustomPrice" onChange={this.handleCustomPriceChange} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                                {estimate &&
                                                    <Divider className="mb-8" />
                                                }

                                                {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                    <div className="flex">
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.AddonYN === 'Y'}
                                                                    name="AddonYN"
                                                                    onClick={() => this.setState({ AddonYN: (this.state.AddonYN !== 'Y' ? 'Y' : 'N') })}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Add-On Item"
                                                        />
                                                    </div>
                                                }
                                                {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                    <Divider className="mt-8 mb-8" />
                                                }
                                                {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                    <div>
                                                        <Typography className={classNames("text-16 font-bold", estimate ? "mb-16" : "mb-0")}>Description</Typography>
                                                        <Typography className="text-14">{parse(this.state.Description || '')}</Typography>
                                                    </div>
                                                }
                                                {estimate && this.state.EditFromFieldYN === "Y" &&
                                                    <div>
                                                        <Typography className={classNames("text-16 font-bold", estimate ? "mb-16" : "mb-0")}>Description</Typography>

                                                        <ReactQuill
                                                            ref={(el) => { this.quillRef = el; }}
                                                            theme="snow"
                                                            className="w-full mb-24"
                                                            value={this.state.Description || ''}
                                                            name="Description"
                                                            // readOnly={lockEditor}
                                                            modules={
                                                                {
                                                                    toolbar: toolbarOptions,
                                                                    history: history,
                                                                    imageDrop: true,
                                                                    imageResize: {
                                                                        parchment: Quill.import('parchment'),
                                                                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                                    }
                                                                }
                                                            }
                                                            formats={formats}
                                                            onChange={this.handleEditorChange}
                                                        />
                                                        {/* <TextField className="text-14 w-full" placeholder="Description" multiline rows={5} name="Description" value={this.state.Description || ''} variant="outlined" margin="dense" onChange={this.handleChange} /> */}
                                                    </div>
                                                }
                                                {Recommendations && Recommendations.length > 0 && estimate &&
                                                    <Button
                                                        className={classNames("text-12 sm:text-14 font-bold w-full mt-24")}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => this.setState({ showRecommendations: true })}
                                                    >
                                                        <Icon className="mr-8">style</Icon>View Recommendations
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        {fullItem && fullItem.Detail &&
                                            <div className='my-16'>
                                                <Typography className={classNames("text-16 font-bold", estimate ? "mb-16" : "mb-0")}>Details</Typography>
                                                <Typography className="text-14">{fullItem.Detail}</Typography>
                                            </div>
                                        }
                                        {fullItem && fullItem.Detail && estimate && Upgrades && _.filter(Upgrades, (o) => { return o.DeleteYN !== 'Y' }).length > 0 &&
                                            <Divider className="my-8" />
                                        }
                                        {estimate && Upgrades && _.filter(Upgrades, (o) => { return o.DeleteYN !== 'Y' }).length > 0 &&
                                            <div className="w-full mt-24">
                                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-24 mr-8">loyalty</Icon>Alternative Tasks</Typography>
                                                <div className="w-full mb-24 rounded-4 p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                                    <div className="w-full flex overflow-x-auto p-12">
                                                        {Upgrades && _.filter(Upgrades, (o) => { return o.DeleteYN !== 'Y' }).map((value, index) => {
                                                            const { Co, BusinessUnit, Division, Assembly, UpgradedAssembly } = value;
                                                            const cat = _.find(tasks, { Co, BusinessUnit, Division, Assembly: UpgradedAssembly });
                                                            if (cat) {
                                                                if (cat && cat.Data && cat.Data.Images && cat.Data.Images.length > 0) {
                                                                    cat.Data.Image = _.find(cat.Data.Images, { PrimaryYN: 'Y' });
                                                                    if (!cat.Data.Image) {
                                                                        cat.Data.Image = cat.Data.Images[0];
                                                                    }
                                                                }
                                                                return (
                                                                    <Card onClick={() => { if (this.props.onSelected) { this.props.onSelected(cat) } else { this.props.openEditCatalogItemDialog({ ...cat }, estimate, this.state.option) } }} key={UpgradedAssembly} className={index < (Upgrades.length - 1) ? "mr-24 max-w-160 min-w-160 cursor-pointer relative rounded-lg" : "max-w-160 min-w-160 cursor-pointer relative rounded-lg"}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="auto"
                                                                            image={cat.Data.Image.ImgUrl}
                                                                            alt={cat.Name}
                                                                        />
                                                                        <CardContent className="mb-48">
                                                                            <Typography className="font-bold mb-8 text-12">
                                                                                {cat.Name}
                                                                            </Typography>
                                                                            {/* <Typography variant="caption" className="max-h-96 overflow-auto mb-8">
                                                                            {parse(cat.Description)}
                                                                        </Typography> */}
                                                                            {/* <label className="text-10">Task #{cat.PRCode || `${cat.Assembly}`}</label> */}
                                                                        </CardContent>
                                                                        <CardActions className="justify-between px-4 py-0 pin-b pin-l pin-r absolute" style={{ borderTop: '1px solid lightgrey' }}>
                                                                            <div className="font-bold text-14 ml-16">{this.formatDollars(isMember ? cat.MemberPrice : cat.Price)}</div>
                                                                            <IconButton
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    const { Components } = _.cloneDeepWith(estimate.Data);
                                                                                    var rec = cat;
                                                                                    const {
                                                                                        Co,
                                                                                        BusinessUnit,
                                                                                        Division,
                                                                                        Assembly,
                                                                                        TaxableYN,
                                                                                        AddonYN
                                                                                    } = rec;
                                                                                    const {
                                                                                        Estimate,
                                                                                        OptionBoard,
                                                                                        Option
                                                                                    } = estimate;
                                                                                    Components.push({
                                                                                        Co,
                                                                                        BusinessUnit,
                                                                                        Division,
                                                                                        Estimate,
                                                                                        OptionBoard,
                                                                                        Option,
                                                                                        Component: Components.length + 1,
                                                                                        Type: 0,
                                                                                        Qty: 1,
                                                                                        UOM: 'EA',
                                                                                        Assembly,
                                                                                        Price: Number(isMember ? cat.MemberPrice : cat.Price) || 0,
                                                                                        PriceMethod: 'F',
                                                                                        TaxableYN,
                                                                                        AddonYN,
                                                                                        Data: {
                                                                                            Assembly: rec,
                                                                                        }
                                                                                    });
                                                                                    window["warn"](estimate);
                                                                                    if (this.props.onUpdated) {
                                                                                        this.props.onUpdated(Components);
                                                                                    }
                                                                                    this.setState({ estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                                                    // this.toggleAssemblies(true, true);
                                                                                    // updateCatalogItem(rec);
                                                                                }} aria-label="add to estimate">
                                                                                <Icon color="primary">add_circle_outline</Icon>
                                                                            </IconButton>
                                                                        </CardActions>
                                                                    </Card>
                                                                );
                                                            }
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </CardContent>
                            }
                            {(value === 0 && estimate && estimate.Data && estimate.Data.Components && this.state.ActiveYN !== 'N') &&
                                <CardActions className="justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-48 m-0 bg-white z-10 py-12 catalog-item-footer" style={{ boxShadow: '2px 0px 12px 4px rgba(0,0,0,.5)' }}>
                                    <div className="flex">
                                        <Typography color="secondary" className="text-12 sm:text-16 font-bold mr-8">Item Subtotal:</Typography>
                                        <Typography className="text-12 sm:text-16 font-bold">{this.formatDollars(sub)}</Typography>
                                    </div>
                                    {!component && this.state.ActiveYN !== 'N' &&
                                        <Button
                                            className={classNames(classes.black, "text-12 sm:text-14 font-bold")}
                                            style={{ borderRadius: 24, paddingLeft: 8 }}
                                            variant="contained"
                                            // color="primary"
                                            onClick={() => {
                                                var rec = _.cloneDeepWith(this.state);
                                                delete rec.Data.Upgrades;
                                                delete rec.Data.Recommendations;
                                                delete rec.Data.Components;
                                                delete rec.Data.Calculation;
                                                delete rec.Data.Assignments;
                                                delete rec.estimate;
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                const last = _.maxBy(Components, 'Component');
                                                const {
                                                    Co,
                                                    BusinessUnit,
                                                    Division,
                                                    Qty,
                                                    Name,
                                                    Description,
                                                    Assembly,
                                                    TaxableYN,
                                                    AddonYN
                                                } = rec;
                                                const {
                                                    Estimate,
                                                    OptionBoard,
                                                    Option,
                                                    SelectedPricing
                                                } = estimate;

                                                let actualPrice = 0;
                                                if (!SelectedPricing || SelectedPricing === "S") {
                                                    actualPrice = this.state.AddonYN == 'Y' ? this.state.AddOnPrice : this.state.Price
                                                } else if (SelectedPricing === "M") {
                                                    actualPrice = this.state.AddonYN == 'Y' ? this.state.AddOnMemberPrice : this.state.MemberPrice
                                                } if (SelectedPricing === "P") {
                                                    actualPrice = this.state.PremiumPrice ? this.state.AddonYN == 'Y' ? this.state.PremiumAddOnPrice ? this.state.PremiumAddOnPrice : this.state.PremiumPrice : this.state.PremiumPrice : this.state.AddonYN == 'Y' ? this.state.AddOnPrice : this.state.Price
                                                }

                                                window["warn"]("CatalogItem actualPrice", actualPrice)

                                                const component = {
                                                    Co,
                                                    BusinessUnit,
                                                    Division,
                                                    Estimate,
                                                    OptionBoard,
                                                    Option,
                                                    Component: (last ? last.Component : 0) + 1,
                                                    Type: 0,
                                                    Qty: Qty || 1,
                                                    UOM: 'EA',
                                                    Assembly,
                                                    Name,
                                                    Description,
                                                    Price: Number(this.state.Qty || 1) * Number(actualPrice),
                                                    //Price: Number(this.state.Qty || 1) * Number(this.state.EditFromFieldYN === "Y" ? actualPrice : this.state.Price),
                                                    //Price: Number(this.state.Qty || 1) * Number(this.state.EditFromFieldYN === "Y" ? (isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) : this.state.AddonYN === "Y" ? (isMember ? this.state.AddOnMemberPrice : this.state.AddOnPrice) : this.state.Price) || 0,
                                                    CustomPrice: this.state.CustomPrice,
                                                    CustomMemberPrice: this.state.CustomMemberPrice,
                                                    PriceMethod: 'F',
                                                    TaxableYN,
                                                    AddonYN,
                                                    Data: {
                                                        Assembly: rec,
                                                    }
                                                };
                                                Components.push(component);
                                                if (this.props.onUpdated) {
                                                    this.props.onUpdated(Components);
                                                }
                                                this.setState({ component, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                window["warn"](estimate);
                                                this.toggleAssemblies(true, true);
                                                // updateCatalogItem(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            <SPRoundIcon className={classNames(classes.colorSecondary, "text-24 mr-8")} /> Add <Hidden xsDown>{estimate.Option ? 'Component' : 'to Estimate'}</Hidden>
                                        </Button>
                                    }
                                    {component && this.state.ActiveYN !== 'N' &&
                                        <div className="flex">
                                            <Button
                                                className={classNames(classes.black, "text-12 sm:text-14 font-bold m-0")}
                                                style={{ borderRadius: 24, paddingLeft: 8, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                variant="contained"
                                                // color="primary"
                                                onClick={() => {
                                                    var rec = _.cloneDeepWith(this.state);
                                                    delete rec.Data.Upgrades;
                                                    delete rec.Data.Recommendations;
                                                    delete rec.Data.Components;
                                                    delete rec.Data.Calculation;
                                                    delete rec.Data.Assignments;
                                                    delete rec.estimate;
                                                    const {
                                                        Qty,
                                                        TaxableYN,
                                                        Name,
                                                        Description,
                                                        AddonYN
                                                    } = rec;
                                                    component = {
                                                        ...component,
                                                        Qty,
                                                        Name,
                                                        Description,
                                                        Price: Number(this.state.Qty || (component ? component.Qty : 1)) * Number(this.state.EditFromFieldYN === "Y" ? (isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) : this.state.AddonYN === "Y" ? (isMember ? this.state.AddOnMemberPrice : this.state.AddOnPrice) : this.state.Price) || 0,
                                                        // Price: Number(this.state.Qty || (component ? component.Qty : 1)) * Number(this.state.AddonYN === 'Y' ? this.state.AddOnPrice : isMember ? this.state.EditFromFieldYN === "Y" ? this.state.CustomMemberPrice : this.state.MemberPrice : this.state.Price) || 0,
                                                        CustomPrice: this.state.CustomPrice,
                                                        CustomMemberPrice: this.state.CustomMemberPrice,
                                                        PriceMethod: 'F',
                                                        TaxableYN,
                                                        AddonYN,
                                                    }
                                                    const { Components } = _.cloneDeepWith(estimate.Data);
                                                    const index = _.findIndex(Components, { Component: component.Component });
                                                    Components.splice(index, 1, component);
                                                    if (this.props.onUpdated) {
                                                        this.props.onUpdated(Components, true);
                                                    }
                                                    this.setState({ component, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                    window["warn"](estimate);
                                                    this.toggleAssemblies(true);
                                                    // updateCatalogItem(rec);
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                <SPRoundIcon className={classNames(classes.colorSecondary, "text-24 mr-8")} /> Update <Hidden xsDown>{estimate.Option ? 'Component' : 'Estimate'}</Hidden>
                                            </Button>
                                            <Button
                                                className={classNames(classes.error, "text-12 sm:text-14 font-bold m-0")}
                                                style={{ minWidth: 36, width: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                variant="contained"
                                                // color="primary"
                                                onClick={() => {
                                                    const { Components } = _.cloneDeepWith(estimate.Data);
                                                    const index = _.findIndex(Components, { Component: component.Component });
                                                    if (index > -1) {
                                                        const comp = _.find(Components, { Component: component.Component });
                                                        if (this.props.multipleOfSameComponent) {
                                                            if (comp.ID) {
                                                                comp.DeleteYN = 'Y';
                                                                Components.splice(index, 1, comp);
                                                            } else {
                                                                Components.splice(index, 1);
                                                            }
                                                            if (this.props.onUpdated) {
                                                                this.props.onUpdated(Components);
                                                            }
                                                            this.setState({ component: null, estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                        } else {
                                                            this.setState({ ...this.state, confirmDelete: true })
                                                        }
                                                    }

                                                    // this.closeComposeDialog();
                                                }}
                                                disabled={!this.canBeSubmitted()}
                                            >
                                                <Icon className={classNames("text-24")}>delete</Icon>
                                            </Button>
                                        </div>
                                    }
                                </CardActions>
                            }
                            <div ref={this.ref}>
                                {showRecommendations && estimate && Recommendations && _.filter(Recommendations, (o) => { return o.DeleteYN !== 'Y' }).length > 0 &&
                                    <CatalogRecommendationPopup Recommendations={Recommendations} onSelected={this.props.onSelected} onUpdated={this.props.onUpdated} isMember={isMember} tasks={tasks} estimate={estimate} closeRecommendations={() => this.setState({ ...this.state, showRecommendations: false })} SelectedPricing={estimate.SelectedPricing} />
                                }
                            </div>
                        </Card>
                        <ConfirmationDialog
                            open={this.state.confirmDelete}
                            title={<div><Icon className="mr-6 align-middle mb-4 text-orange">warning</Icon>Are You Sure?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to remove "${this.state.Name}" from this estimate?`}</div>}
                            confirmText="Remove item"
                            cancelText="Cancel"
                            onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                            onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => this.deleteCatalogItem())}
                        />
                    </>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditCatalogItemDialog: Actions.openEditCatalogItemDialog,
        closeEditCatalogItemDialog: Actions.closeEditCatalogItemDialog,
        closeNewCatalogItemDialog: Actions.closeNewCatalogItemDialog,
        addAssembly: Actions.addAssembly,
        updateAssembly: Actions.updateAssembly,
        showMessage: showMessage,
        impress,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        rateTemplates: spReducers.rateTemplates,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        employees: spReducers.employees,
        catalog: spReducers.catalog,
        // categories: spReducers.catalog.categories,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogItemDialog));
