import {Component} from 'react';
import {Icon, IconButton, TextField, Checkbox, ListItem} from '@material-ui/core';
import React from 'react';
import _ from '@lodash';

class SupportRequestChecklistItem extends Component {
    state = this.props.item;

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( this.state && prevState && !_.isEqual(this.state.CheckedYN, prevState.CheckedYN) )
        {
            //this.props.onUpdateChecklistItem(this.state);
        }
    }

    handleChange = (event) => {
        this.setState(_.setIn(this.state, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : null : event.target.value));
    };
    
    handleCheck = (event) => {
        this.setState(_.setIn(this.state, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : null : event.target.value), () => {
            this.props.onUpdateChecklistItem(this.state);
        });
    }

    handleSaveChange = () => {
        this.props.onUpdateChecklistItem(this.state);
    }

    render()
    {
        const {onRemoveChecklistItem} = this.props;
        if ( !this.state )
        {
            return null;
        }

        return (
            <ListItem
                className="px-0"
                key={this.state.ID}
                dense
            >
                <Checkbox
                    checked={this.state.CheckedYN}
                    tabIndex={-1}
                    disableRipple
                    name="CheckedYN"
                    onChange={this.handleCheck}
                />
                <TextField
                    className="flex flex-1 mx-8"
                    name="Name"
                    value={this.state.Name}
                    onChange={this.handleChange}
                    onBlur={this.handleSaveChange}
                    variant="outlined"
                />
                <IconButton aria-label="Delete" onClick={() => onRemoveChecklistItem(this.state)}>
                    <Icon>delete</Icon>
                </IconButton>
            </ListItem>
        );
    }
}

export default SupportRequestChecklistItem;
