import _ from "@lodash";
import {
  Badge,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import * as Actions from "main/content/apps/dashboard-builder/store/actions";
import { SPRoundIcon } from "main/icons";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { Component } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import Media from "react-media";
import connect from "react-redux/es/connect/connect";
import "react-resizable/css/styles.css";
import { Link, withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import Autocomplete from "../../../components/autocomplete/Autocomplete";
import ConfirmationDialog from "main/content/components/dialogs/confirmation";
import Chart from "./Chart";
import DashboardComponentDialog from "./DashboardComponentDialog";
import PivotTable from "../../reporting/PivotTable";
import Tile from "./Tile";

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "auto",
  },
  component: {
    cursor: "pointer",
    boxShadow: theme.shadows[0],
    transitionProperty: "box-shadow border-color",
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    background: "#fff",
    color: "#333",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
  },
  newComponent: {
    borderWidth: 2,
    borderStyle: "dashed",
    background: "transparent",
    color: theme.palette.primary.contrastText,
    borderColor: fade(theme.palette.primary.contrastText, 0.6),
    "&:hover": {
      borderColor: fade(theme.palette.primary.contrastText, 0.8),
    },
  },
  dialogRoot: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  paper: {
    margin: 12,
    minHeight: "calc(100% - 64px)",
    overflowX: "hidden",
  },
  formControl: {
    marginBottom: 12,
  },
  dark: {
    backgroundColor: "#3f3f3f",
  },
  drawer: {
    width: 0,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "calc(100vh - 64px)",
    top: 0,
  },
  drawerOpen: {
    width: 512,
    maxWidth: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "-2px 0px 8px 0px rgba(0,0,0,.05)",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  black: {
    backgroundColor: "#3f3f3f",
    color: "#fff",
    "&:active": {
      backgroundColor: "#4f4f4f",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#444",
      color: "#fff",
    },
    "&:focus": {
      backgroundColor: "#444",
      color: "#fff",
    },
  },
  red: {
    backgroundColor: theme.palette.error[500],
    color: "#fff",
    "&:active": {
      backgroundColor: theme.palette.error[600],
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.error[400],
      color: "#fff",
    },
    "&:focus": {
      backgroundColor: theme.palette.error[600],
      color: "#fff",
    },
  },
  colorSecondary: {
    color: `${theme.palette.secondary.main} !important`,
  },
  colorError: {
    color: `${theme.palette.error[400]} !important`,
  },
  colorPrimary: {
    color: `${theme.palette.primary.main} !important`,
  },
  colorWhite: {
    color: "#fff !important",
  },
  cardRoot: {
    padding: "0 !important",
    color: "#333",
  },
  cardContentRoot: {
    padding: "0 !important",
  },
  actionBadge: {
    width: 16,
    height: 16,
    minWidth: 16,
    marginRight: 2,
    marginTop: 4,
    fontSize: 10,
    lineHeight: 10,
  },
  touchScroll: {
    touchAction: "auto !important",
  },
});

const newDashboardState = {
  ID: null,
  Co: null,
  Dashboard: null,
  Name: null,
  Notes: null,
  Options: null,
  EnteredBy: null,
  EnteredDate: null,
  UpdatedBy: null,
  UpdatedDate: null,
  DefaultYN: null,
  Form: null,
  Data: {
    ErrMsg: null,
    Components: [],
    Reports: [],
    DataSources: [],
  },
  dataSources: [],
  addReport: false,
  selectedReport: null,
  formParams: {},
  selectedParam: null,
  testDataSource: false,
  settingsDrawerOpen: false,
  editor: null,
  menuOpen: false,
  componentDialog: false,
  lockLayout: true,
  selectedComponent: null,
  timestamp: null,
  actionEl: null,
  actionEdit: undefined,
  actionUnassign: undefined,
  fetchingData: [],
  showFilters: false,
  selectedLayout: null,
  open: false,
  confirmDelete: false,
  resizingInfo: { isResizing: true, elementId: null },
};

class Dashboard extends Component {
  state = {
    ...newDashboardState,
  };

  componentDidMount() {
    this.setState({ ..._.cloneDeep(newDashboardState) }, () =>
      this.props.getDashboard(
        this.props.match.params.Co,
        this.props.match.params.dashboardId
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { dashboard } = this.props;
    const { open } = _.cloneDeepWith(this.state);
    if (open === false) {
      this.setState(
        { ..._.cloneDeep(newDashboardState), ...dashboard, open: true },
        () => window["warn"]("New Dashboard State: ", this.state)
      );
    } else {
      if (!_.isEqual(dashboard, prevProps.dashboard)) {
        this.setState({
          ...this.state,
          ...dashboard,
          open: true,
          menuOpen: false,
          componentDialog: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this.setState(
      { ..._.cloneDeep(newDashboardState), dataSources: [], open: false },
      () => this.props.resetDashboard()
    );
  }

  handleChange = (event) => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === "checkbox"
          ? event.target.checked
            ? "Y"
            : "N"
          : event.target.value
      )
    );
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped nowhere
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // // reordering list
    // if (result.type === 'list') {
    //     this.props.reorderList(result);
    // }

    // // reordering card
    // if (result.type === 'card') {
    //     this.props.reorderCard(result);
    // }
  };

  toggleSettingsDrawer = (state) => {
    state = state === undefined ? !this.state.settingsDrawerOpen : state;

    this.setState({
      settingsDrawerOpen: state,
    });
  };

  setEditor = (editor) => {
    if (editor) {
      this.setState({ ...this.state, editor });
    }
  };

  updateDashboard = () => {
    const { updateDashboard } = this.props;
    updateDashboard(this.state);
    this.toggleMenu();
  };

  toggleMenu = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({ ...this.state, menuOpen: !this.state.menuOpen });
  };

  selectDataSource = (dataSource) => {
    const selectedReport =
      _.find(this.state.Data.Reports, { Report: dataSource.Report }) ||
      dataSource.Data.Report;
    if (
      selectedReport &&
      selectedReport.Data &&
      selectedReport.Data.Parameters
    ) {
      selectedReport.Data.Parameters.map((value) => {
        const initial = _.find(dataSource.Data.Parameters, {
          Parameter: value.Parameter,
        });
        if (initial) {
          value.Value = initial.Value;
        }
      });
      window["warn"]("Report: ", selectedReport);
    }
    this.setState({ ...this.state, selectedReport, addReport: false });
  };

  testDataSource = () => {
    const { testDataSource } = _.cloneDeepWith(this.state);
    const { Report, Parameters } = testDataSource;
    const request = axios.put(
      `${window["apiLocation"]}/api/Report?r=${Report}`,
      Parameters
    );
    request.then((response) => {
      testDataSource.Results = response.data;
      testDataSource.pivotBy = [];
      testDataSource.selectedColumns = [];
      testDataSource.columns = this.getColumns(response.data);
      window["warn"](response.data);
      this.setState({ ...this.state, testDataSource });
    });
  };

  getReportList = () => {
    const { Form } = _.cloneDeepWith(this.state);
    if (Form) {
      const request = axios.get(
        `${window["apiLocation"]}/api/Report?Form=${encodeURIComponent(Form)}`
      );
      request.then((response) => {
        this.setState({
          ...this.state,
          Data: {
            ...this.state.Data,
            Reports: response.data,
          },
        });
      });
    }
  };

  closeDataSource = () => {
    this.setState({ ...this.state, testDataSource: false });
  };

  setTestParameter = (option, value, field) => {
    const { testDataSource } = _.cloneDeepWith(this.state);
    const { Parameters } = testDataSource;
    let param = _.find(Parameters, { Parameter: value.Parameter });
    if (param) {
      param.Value = option[field.listValue || field.value];
    } else {
      param = {
        Parameter: value.Parameter,
        Value: option[field.listValue || field.value],
      };
      Parameters.push(param);
    }
    this.setState({ ...this.state, testDataSource });
  };

  setTestParameterDate = (event, value) => {
    const { testDataSource } = _.cloneDeepWith(this.state);
    const { Parameters } = testDataSource;
    let param = _.find(Parameters, { Parameter: value.Parameter });
    if (param) {
      param.Value = event._d;
    } else {
      param = {
        Parameter: value.Parameter,
        Value: event._d,
      };
      Parameters.push(param);
    }
    this.setState({ ...this.state, testDataSource });
  };
  getTestParameter = (name) => {
    const { testDataSource } = _.cloneDeepWith(this.state);
    const { Parameters } = testDataSource;
    let param = _.find(Parameters, { Parameter: name });
    return param && param.Value ? param.Value : null;
  };

  getColumns(data) {
    return Object.keys(data[0]).map((key) => {
      return {
        Header: key,
        headerStyle: {
          fontWeight: "bold",
        },
        accessor: key,
        id: key,
        dataType: !isNaN(data[0][key]) ? "number" : "text",
      };
    });
  }

  toggleColumn = (column) => {
    const { testDataSource } = _.cloneDeepWith(this.state);
    const { columns, selectedColumns } = testDataSource;
    const selected = _.findIndex(selectedColumns, (o) => {
      return o.Header === column.Header;
    });
    if (selected > -1) {
      selectedColumns.splice(selected, 1);
    } else {
      column.aggregate =
        column.dataType === "number"
          ? (vals) => _.round(_.sum(vals), 2)
          : (vals) => "";
      column.Cell = ({ value }) => {
        return <div className="w-full text-center">{value}</div>;
      };
      selectedColumns.push(column);
    }
    this.setState({ ...this.state, testDataSource });
  };

  getTechnician = (Technician) => {
    const { classes } = this.props;
    const avatar = "assets/images/avatars/profile.jpg";
    const value = _.find(this.props.technicians, {
      Co: this.props.Co,
      Technician,
    });
    if (value) {
      return `${value.FirstName} ${value.LastName}`;
    } else {
      return Technician;
    }
  };

  fetchData = () => {
    const { Data, formParams } = _.cloneDeepWith(this.state);
    const { Reports, DataSources } = Data;
    const fetchingData = [];
    // const form = _.find(this.props.securablesList, { form: Form });
    DataSources.map((src) => {
      const rpt = _.find(Reports, { Report: src.Report });
      let canRun = true;
      if (rpt) {
        const { Parameters } = rpt.Data;
        if (Parameters && Parameters.length > 0) {
          Parameters.map((param) => {
            const val = _.find(src.Data.Parameters, {
              Parameter: param.Parameter,
            });
            if (val && formParams[val.Value]) {
              param.Value = formParams[val.Value];
            } else {
              const opt = formParams[param.Parameter];
              if (opt) {
                param.Value = opt;
              } else {
                if (param.RequiredYN === "Y") {
                  canRun = false;
                }
              }
            }
          });
          if (canRun) {
            const toFetch = {
              Report: rpt.Report,
              Parameters,
            };
            fetchingData.push(toFetch);
            this.setState({ ...this.state, fetchingData }, () =>
              this.fetchReportData(toFetch)
            );
          }
        }
      }
    });
  };

  fetchReportData = (report) => {
    const { Report, Parameters } = report;
    const request = axios.put(
      `${window["apiLocation"]}/api/Report?r=${Report}`,
      Parameters
    );
    request
      .then((response) => {
        const { dataSources } = _.cloneDeepWith(this.state);
        let result = _.find(dataSources, { Report });
        if (result) {
          result.Results = response.data;
          result.Fetched = new Date();
        } else {
          result = {
            Report,
            Results: response.data,
            Fetched: new Date(),
          };
          dataSources.push(result);
        }
        window["warn"](report, dataSources);
        const { fetchingData } = _.cloneDeepWith(this.state);
        const index = _.findIndex(fetchingData, { Report });
        if (index > -1) {
          fetchingData.splice(index, 1);
        }
        this.setState({ ...this.state, dataSources, fetchingData });
      })
      .catch((error) => {
        const { fetchingData } = _.cloneDeepWith(this.state);
        const index = _.findIndex(fetchingData, { Report });
        if (index > -1) {
          fetchingData.splice(index, 1);
        }
        this.setState({ ...this.state, fetchingData });
      });
  };

  addComponent = (data) => {
    const { Co, Dashboard, Data } = _.cloneDeepWith(this.state);
    const { Components } = Data;
    const index = _.findIndex(Components, { Component: data.Component });
    if (!data.ID || index < 0) {
      this.props.addDashboardComponent(this.state, data);
    }
    window["warn"](Components, data);
    this.setState({ ...this.state, Data: { ...Data, Components } });
  };

  updateComponent = (data) => {
    const { Data } = _.cloneDeepWith(this.state);
    const { Components } = Data;
    const index = _.findIndex(Components, { Component: data.Component });
    window["warn"](data, index);
    if (index > -1) {
      Components[index].Data.Component = data;
      this.props.updateDashboardComponent(data);
    }
    this.setState({ ...this.state, Data: { ...Data, Components } });
  };

  handleLayout = (layout) => {
    let { Options, selectedLayout } = _.cloneDeepWith(this.state);
    if (!Options) {
      Options = {
        Layouts: {},
      };
    } else {
      Options = JSON.parse(Options);
      if (!Options.Layouts) {
        Options.Layouts = {};
      }
    }
    Options.Layouts[selectedLayout] = layout;
    this.setState(
      {
        ...this.state,
        Options: JSON.stringify(Options),
        timestamp: new Date(),
        resizingInfo: { isResizing: false, elementId: null },
      },
      () => window.dispatchEvent(new Event("resize"))
    );
  };

  toggleLayoutLock = () => {
    const { lockLayout } = _.cloneDeepWith(this.state);
    if (!lockLayout) {
      this.saveLayout();
    }
    this.setState(
      { ...this.state, lockLayout: !lockLayout, selectedLayout: null },
      () => window.dispatchEvent(new Event("resize"))
    );
  };

  saveLayout = () => {
    const { Co, Dashboard, Name, Form, Notes, Options, Data } = _.cloneDeepWith(
      this.state
    );
    if (!_.isEqual(Options, this.props.dashboard.Options)) {
      this.props.updateDashboard({
        Co,
        Dashboard,
        Name,
        Form,
        Notes,
        Options,
        Data,
      });
    }
  };

  setAction = (actionEl, actionEdit, actionUnassign) => {
    this.setState({ ...this.state, actionEl, actionEdit, actionUnassign });
  };

  editComponent = (component) => {
    this.setState({
      ...this.state,
      selectedComponent: component,
      componentDialog: true,
      actionEl: null,
      actionEdit: null,
      actionUnassign: null,
    });
  };

  unassign = (component) => {
    const { Data } = _.cloneDeepWith(this.state);
    const { Co, Dashboard, Component } = component;
    const { Components } = Data;
    const index = _.findIndex(Components, { Dashboard, Component });
    if (index > -1) {
      Components.splice(index, 1);
      this.setState(
        {
          ...this.state,
          actionEl: null,
          actionEdit: null,
          actionUnassign: null,
          Data: { ...Data, Components },
        },
        () => this.props.removeDashboardComponent(component)
      );
    }
  };

  handleResizeStart = (layout, oldItem, newItem, placeholder, e, element) => {
    const elementId = e.target.parentNode.id;
    this.setState({ resizingInfo: { isResizing: true, elementId } });
  };

  render() {
    const {
      classes,
      dashboard,
      deleteDashboard,
      username,
      securables,
      securablesList,
      master,
      filters,
      setDashboardFilters,
      setDashboardDrilled,
      about,
    } = this.props;
    const {
      menuOpen,
      Co,
      Dashboard,
      Name,
      Form,
      showFilters,
      selectedReport,
      selectedParam,
      selectedLayout,
      addReport,
      Data,
      Notes,
      testDataSource,
      selectedFormParam,
      dataSources,
      componentDialog,
      lockLayout,
      timestamp,
      filterEl,
      actionEl,
      actionEdit,
      actionUnassign,
      fetchingData,
    } = _.cloneDeepWith(this.state);
    let { Options } = _.cloneDeepWith(this.state);
    let layouts;
    if (Options) {
      Options = JSON.parse(Options);
      layouts = Options.Layouts;
    }
    const { InstanceType } = about || {};
    const { Reports } = Data;
    const accessLevel = _.find(securables, { Securable: "dashboard-builder" });
    const form = _.find(securablesList, { form: Form });
    const otherParams = [];
    Data.DataSources.map((ds) => {
      const rpt = _.find(Data.Reports, { Report: ds.Report });
      if (rpt && rpt.Data.Parameters) {
        rpt.Data.Parameters.map((param) => {
          if (
            !_.find(ds.Data.Parameters, { Parameter: param.Parameter }) &&
            !_.find(otherParams, { Parameter: param.Parameter })
          ) {
            otherParams.push(param);
          }
        });
      }
      // ds.Data.Parameters.map((param) => {
      //     if (!_.find(form.fields,{value: param.value})) {
      //         allParams.push(param);
      //     };
      // });
    });
    window["warn"]("Other Parameters: ", layouts);
    let canRun;
    // if (!testDataSource || !testDataSource.Parameters || !testDataSource.Report || !selectedReport || !selectedReport.Data || !selectedReport.Data.Parameters || _.filter(testDataSource.Parameters, (o) => {})
    if (!dashboard) {
      return (
        <div
          className={classNames(
            classes.root,
            "flex flex-1 flex-col h-full relative"
          )}
        ></div>
      );
    }
    return (
      <Media
        queries={{
          xs: "(max-width: 479px)", //phone
          sm: "(min-width: 480px) and (max-width: 995px)", //tablet
          md: "(min-width: 996px) and (max-width: 1199px)", //tablet_landscape
          lg: "(min-width: 1200px) and (max-width: 1599px", //laptop
          xl: "(min-width: 1600px)", //desktop
        }}
      >
        {(matches) => (
          <div
            className={classNames(
              classes.root,
              "flex flex-1 flex-col h-full relative lg:pl-4 overflow-x-hidden"
            )}
            ref={(root) => {
              this.root = root;
            }}
          >
            <DashboardComponentDialog
              dataSources={dataSources}
              reports={[...Reports]}
              open={componentDialog}
              onClose={() =>
                this.setState({
                  ...this.state,
                  componentDialog: false,
                  selectedComponent: null,
                })
              }
              component={
                this.state.selectedComponent
                  ? { ...this.state.selectedComponent }
                  : null
              }
              onAdded={(comp) => this.addComponent(comp)}
              onUpdated={(comp) => this.updateComponent(comp)}
            />
            <div
              onClick={() => {
                if (menuOpen) {
                  this.setState({ ...this.state, menuOpen: false });
                }
              }}
              className="relative"
            >
              <Typography
                variant="h6"
                className="text-20 mt-16 text-white float-left"
              >
                <IconButton
                  to="/apps/dashboard-builder/dashboards/"
                  component={Link}
                  className="align-middle mb-4 ml-16 p-0"
                >
                  <Icon className="text-32" color="secondary">
                    keyboard_arrow_left
                  </Icon>
                </IconButton>
                {this.state.Name}
              </Typography>
              {dataSources && dataSources.length > 0 && lockLayout ? (
                <Icon
                  className="align-middle m-12 mt-20 cursor-pointer"
                  style={{ marginBottom: 5 }}
                  onClick={this.toggleLayoutLock}
                >
                  lock
                </Icon>
              ) : (
                dataSources &&
                dataSources.length > 0 && (
                  <React.Fragment>
                    <Icon
                      color="secondary"
                      className="float-left align-middle m-12 mt-20 cursor-pointer"
                      style={{ marginBottom: 5, transform: "rotate(15deg)" }}
                      onClick={this.toggleLayoutLock}
                    >
                      lock_open
                    </Icon>
                    <div class="float-left mt-8">
                      <Icon
                        color={
                          selectedLayout === "xs" ? "secondary" : undefined
                        }
                        className="align-middle m-12 ml-0 cursor-pointer"
                        style={{ marginBottom: 5 }}
                        onClick={() =>
                          this.setState(
                            { ...this.state, selectedLayout: "xs" },
                            () => window.dispatchEvent(new Event("resize"))
                          )
                        }
                      >
                        smartphone
                      </Icon>
                      {(matches.sm ||
                        matches.md ||
                        matches.lg ||
                        matches.xl) && (
                        <Icon
                          color={
                            selectedLayout === "sm" ? "secondary" : undefined
                          }
                          className="align-middle m-12 cursor-pointer"
                          style={{ marginBottom: 5 }}
                          onClick={() =>
                            this.setState(
                              { ...this.state, selectedLayout: "sm" },
                              () => window.dispatchEvent(new Event("resize"))
                            )
                          }
                        >
                          tablet_mac
                        </Icon>
                      )}
                      {(matches.md || matches.lg || matches.xl) && (
                        <Icon
                          color={
                            selectedLayout === "md" ? "secondary" : undefined
                          }
                          className="align-middle m-12 cursor-pointer"
                          style={{
                            marginBottom: 5,
                            transform: "rotate(-90deg)",
                          }}
                          onClick={() =>
                            this.setState(
                              { ...this.state, selectedLayout: "md" },
                              () => window.dispatchEvent(new Event("resize"))
                            )
                          }
                        >
                          tablet_mac
                        </Icon>
                      )}
                      {(matches.lg || matches.xl) && (
                        <Icon
                          color={
                            selectedLayout === "lg" ? "secondary" : undefined
                          }
                          className="align-middle m-12 cursor-pointer"
                          style={{ marginBottom: 5 }}
                          onClick={() =>
                            this.setState(
                              { ...this.state, selectedLayout: "lg" },
                              () => window.dispatchEvent(new Event("resize"))
                            )
                          }
                        >
                          laptop_mac
                        </Icon>
                      )}
                      {matches.xl && (
                        <Icon
                          color={
                            selectedLayout === "xl" ? "secondary" : undefined
                          }
                          className="align-middle m-12 cursor-pointer"
                          style={{ marginBottom: 5 }}
                          onClick={() =>
                            this.setState(
                              { ...this.state, selectedLayout: "xl" },
                              () => window.dispatchEvent(new Event("resize"))
                            )
                          }
                        >
                          desktop_windows
                        </Icon>
                      )}
                    </div>
                  </React.Fragment>
                )
              )}
              <div className="pin-t pin-r absolute mt-8 mr-8 flex">
                {dataSources.length > 0 && (
                  <Tooltip
                    placement="left"
                    title={`Last Refreshed: ${moment(
                      dataSources[0].Fetched
                    ).format("h:mm:ss A")}`}
                  >
                    <IconButton
                      onClick={this.fetchData}
                      className="mr-12"
                      color="inherit"
                    >
                      <Icon
                        style={{ textShadow: "1px 1px #3f3f3f" }}
                        className={fetchingData.length > 0 ? "spin" : ""}
                      >
                        refresh
                      </Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {Object.keys(filters).length > 0 && (
                  <IconButton
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        showFilters: !showFilters,
                      })
                    }
                    className="mr-12"
                    color="inherit"
                  >
                    <Badge
                      classes={{ root: "", badge: classes.actionBadge }}
                      badgeContent={Object.keys(filters).length}
                      color="error"
                    >
                      <Icon style={{ textShadow: "1px 1px #3f3f3f" }}>
                        filter_list
                      </Icon>
                    </Badge>
                  </IconButton>
                )}
                <IconButton onClick={this.toggleMenu} color="inherit">
                  <Icon style={{ textShadow: "1px 1px #3f3f3f" }}>menu</Icon>
                </IconButton>
              </div>
              {Object.keys(filters).length > 0 && showFilters && (
                <Card
                  className="rounded-6 p-6 relative clear"
                  style={{ margin: 30, marginBottom: 0, marginTop: 0 }}
                >
                  <Typography
                    className="w-full font-bold text-12 cursor-pointer truncate pr-36"
                    style={{ borderBottom: "1px solid #f1f1f1" }}
                  >
                    <Icon color="secondary" className="mr-4 align-middle mb-4">
                      filter_list
                    </Icon>
                    Active Filters
                  </Typography>
                  <Tooltip placement="left" title="Clear All Filters">
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 7,
                        top: 4,
                      }}
                      className="p-4"
                      onClick={() =>
                        this.setState(
                          { ...this.state, showFilters: false },
                          () => {
                            setDashboardDrilled({});
                            setDashboardFilters({});
                          }
                        )
                      }
                    >
                      <Icon className="text-20">close</Icon>
                    </IconButton>
                  </Tooltip>
                  <div className="w-full mt-12 mb-6">
                    <div className="flex w-full overflow-x-auto">
                      {Object.keys(filters).map((filter) => {
                        const fieldValue =
                          ["Technician", "TeamMember", "TeamLead"].indexOf(
                            filter
                          ) > -1
                            ? this.getTechnician(filters[filter])
                            : filter.indexOf("Date") > -1
                            ? moment(filters[filter]).format("M/D/YYYY")
                            : filters[filter];
                        return (
                          <div key={filter} className="float-left mr-8 flex">
                            <Chip
                              color="secondary"
                              className={classNames(
                                classes.black,
                                "rounded-r-none pr-4"
                              )}
                              icon={
                                <SPRoundIcon
                                  className={classes.colorSecondary}
                                />
                              }
                              label={filter}
                            />
                            <Chip
                              icon={
                                <Icon
                                  className={classes.colorWhite}
                                  style={{
                                    marginLeft: -12,
                                    textShadow: "0px 0px 2px #333",
                                  }}
                                >
                                  link
                                </Icon>
                              }
                              color={"secondary"}
                              className={classNames("rounded-l-none font-bold")}
                              label={fieldValue}
                              deleteIcon={<Icon>close</Icon>}
                              onDelete={() => {
                                delete filters[filter];
                                setDashboardFilters(filters);
                                if (Object.keys(filters).length < 1) {
                                  this.setState({
                                    ...this.state,
                                    filterEl: null,
                                  });
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Card>
              )}
              <Drawer
                anchor="right"
                variant="permanent"
                className={clsx(classNames(classes.drawer, "pin-r absolute"), {
                  [classes.drawerOpen]: menuOpen,
                  [classes.drawerClose]: !menuOpen,
                })}
                classes={{
                  paper: clsx("relative border-none h-full", {
                    [classes.drawerOpen]: menuOpen,
                    [classes.drawerClose]: !menuOpen,
                  }),
                }}
                open={menuOpen}
                onClick={(e) => e.stopPropagation()}
              >
                <div tabIndex={0}>
                  <div className="p-12 relative">
                    <Typography
                      variant="h6"
                      className="w-full font-bold text-16 mb-12 mt-4"
                    >
                      <SPRoundIcon className="align-middle mr-4 mb-4" />
                      Dashboard Settings
                    </Typography>
                    <IconButton
                      onClick={this.toggleMenu}
                      color="inherit"
                      className="pin-t pin-r absolute mt-4 mr-4 text-black"
                    >
                      <Icon>close</Icon>
                    </IconButton>
                    <React.Fragment>
                      <TextField
                        className={classes.formControl}
                        label="Name"
                        id="name"
                        name="Name"
                        value={Name || ""}
                        onChange={this.handleChange}
                        variant="outlined"
                        // disabled={!dashboard.Co && Boolean(dashboard.ID)}
                        required
                        fullWidth
                      />
                      <Autocomplete
                        className={classes.formControl}
                        title="Form"
                        options={_.filter(securablesList, (o) =>
                          Boolean(o.form)
                        )}
                        menuItemComponent={(value) => {
                          return (
                            <MenuItem value={value.form}>
                              <div className="flex">
                                {/* <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.title}>
                                                                    <Icon>{value.icon}</Icon>
                                                                </Avatar> */}
                                {`${value.title}`}
                              </div>
                            </MenuItem>
                          );
                        }}
                        value={Form}
                        portal={true}
                        disabled={Data.DataSources.length > 0}
                        onSelect={(option) =>
                          this.setState(
                            { ...this.state, Form: option.form },
                            () => this.getReportList()
                          )
                        }
                        required
                      />
                      <TextField
                        // className={classes.formControl}
                        label="Notes"
                        id="notes"
                        name="Notes"
                        value={Notes || ""}
                        onChange={this.handleChange}
                        variant="outlined"
                        multiline
                        rows={5}
                        fullWidth
                        // disabled={!dashboard.Co && Boolean(dashboard.ID)}
                      />
                    </React.Fragment>
                    <div className="w-full">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.DefaultYN === "Y"}
                            name="DefaultYN"
                            onChange={() =>
                              this.setState(
                                {
                                  ...this.state,
                                  DefaultYN:
                                    this.state.DefaultYN === "Y" ? "N" : "Y",
                                },
                                () =>
                                  this.props.setDefaultDashboard({
                                    ...this.state,
                                    Co: this.props.Co,
                                  })
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Default Dashboard"
                        disabled={!this.state.Form}
                      />
                    </div>
                    {form && form.fields && (
                      <div className="mb-8 w-full">
                        <Typography className="mb-4 w-full font-bold text-12">
                          Dashboard Parameters:
                          <Typography
                            onClick={this.fetchData}
                            className="mb-4 float-right font-bold text-12 cursor-pointer"
                          >
                            <Icon
                              className="align-middle mb-4 mr-4 text-16"
                              color="primary"
                            >
                              cloud_download
                            </Icon>
                            Fetch Data
                          </Typography>
                        </Typography>
                        <Divider className="mb-8" />
                        {form.fields.map((value) => {
                          const fieldValue =
                            ["Technician", "TeamMember", "TeamLead"].indexOf(
                              value.value
                            ) > -1
                              ? this.getTechnician(
                                  this.state.formParams[value.value]
                                )
                              : value.value.indexOf("Date") > -1
                              ? moment(
                                  this.state.formParams[value.value]
                                ).format("M/D/YYYY")
                              : this.state.formParams[value.value];
                          return (
                            // <Chip className="mr-8 mb-8" color="primary" icon={<SPRoundIcon />} label={value.value} />
                            <div
                              key={value.value}
                              className="float-left mr-8 mb-8"
                            >
                              <Chip
                                color="secondary"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    selectedFormParam: value,
                                  })
                                }
                                className={classNames(
                                  classes.black,
                                  fieldValue ? "rounded-r-none pr-4" : ""
                                )}
                                icon={
                                  <SPRoundIcon
                                    className={
                                      fieldValue
                                        ? classes.colorSecondary
                                        : classes.colorError
                                    }
                                  />
                                }
                                label={value.value}
                              />
                              {fieldValue && (
                                <Chip
                                  icon={
                                    <Icon
                                      className={classes.colorWhite}
                                      style={{
                                        marginLeft: -12,
                                        textShadow: "0px 0px 2px #333",
                                      }}
                                    >
                                      link
                                    </Icon>
                                  }
                                  color={"secondary"}
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      selectedFormParam: value,
                                    })
                                  }
                                  className={classNames(
                                    "rounded-l-none font-bold"
                                  )}
                                  label={fieldValue}
                                />
                              )}
                            </div>
                          );
                        })}
                        {otherParams.map((value) => {
                          const fieldValue =
                            ["@Technician", "@TeamMember", "@TeamLead"].indexOf(
                              value.Parameter
                            ) > -1
                              ? this.getTechnician(
                                  this.state.formParams[value.Parameter]
                                )
                              : value.Parameter.indexOf("Date") > -1 &&
                                this.state.formParams[value.Parameter]
                              ? moment(
                                  this.state.formParams[value.Parameter]
                                ).format("M/D/YYYY")
                              : this.state.formParams[value.Parameter];
                          return (
                            // <Chip className="mr-8 mb-8" color="primary" icon={<SPRoundIcon />} label={value.value} />
                            <div
                              key={value.Parameter}
                              className="float-left mr-8 mb-8"
                            >
                              <Chip
                                color="secondary"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    selectedFormParam: value,
                                  })
                                }
                                className={classNames(
                                  classes.black,
                                  fieldValue ? "rounded-r-none pr-4" : ""
                                )}
                                icon={
                                  <SPRoundIcon
                                    className={
                                      fieldValue
                                        ? classes.colorSecondary
                                        : classes.colorPrimary
                                    }
                                  />
                                }
                                label={value.Description}
                              />
                              {fieldValue && (
                                <Chip
                                  icon={
                                    <Icon
                                      className={classes.colorWhite}
                                      style={{
                                        marginLeft: -12,
                                        textShadow: "0px 0px 2px #333",
                                      }}
                                    >
                                      link
                                    </Icon>
                                  }
                                  color={"secondary"}
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      selectedFormParam: value,
                                    })
                                  }
                                  className={classNames(
                                    "rounded-l-none font-bold"
                                  )}
                                  label={fieldValue}
                                />
                              )}
                            </div>
                          );
                        })}
                        <Divider className="mb-8 clear" />
                        {selectedFormParam && (
                          <div className="w-full">
                            {selectedFormParam.list && (
                              <div className="w-full">
                                <Autocomplete
                                  className="w-full"
                                  title={
                                    selectedFormParam.value ||
                                    selectedFormParam.Description
                                  }
                                  options={
                                    _.get(master, selectedFormParam.list) || []
                                  }
                                  menuItemComponent={(option) => {
                                    let displayText;
                                    if (
                                      selectedFormParam.text &&
                                      selectedFormParam.text.length > 0
                                    ) {
                                      selectedFormParam.text.map((name) => {
                                        const text = _.get(option, name);
                                        if (text) {
                                          if (!displayText) {
                                            displayText = "";
                                          }
                                          displayText += `${text} `;
                                        }
                                      });
                                    }
                                    return (
                                      <MenuItem
                                        value={
                                          option
                                            ? option[
                                                selectedFormParam.listValue ||
                                                  selectedFormParam.value
                                              ]
                                            : null
                                        }
                                      >
                                        <div className="flex">
                                          {`${
                                            option
                                              ? displayText ||
                                                option[
                                                  selectedFormParam.listValue ||
                                                    selectedFormParam.value
                                                ]
                                              : "N/A"
                                          }`}
                                        </div>
                                      </MenuItem>
                                    );
                                  }}
                                  autoFocus={true}
                                  portal={true}
                                  value={
                                    this.state.formParams[
                                      selectedFormParam.listValue ||
                                        selectedFormParam.value ||
                                        selectedFormParam.Parameter
                                    ]
                                  }
                                  onSelect={(option) => {
                                    const { formParams } = _.cloneDeepWith(
                                      this.state
                                    );
                                    formParams[selectedFormParam.value] =
                                      option[
                                        selectedFormParam.listValue ||
                                          selectedFormParam.value
                                      ];
                                    this.setState({
                                      ...this.state,
                                      formParams,
                                      selectedFormParam: null,
                                    });
                                  }}
                                  required
                                />
                              </div>
                            )}
                            {selectedFormParam.Type === "date" && (
                              <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                className={classes.formControl}
                                label={
                                  selectedFormParam.value ||
                                  selectedFormParam.Description
                                }
                                format="MM/DD/YYYY"
                                value={
                                  this.state.formParams[
                                    selectedFormParam.value ||
                                      selectedFormParam.Parameter
                                  ]
                                }
                                onChange={(e) => {
                                  const { formParams } = _.cloneDeepWith(
                                    this.state
                                  );
                                  formParams[
                                    selectedFormParam.value ||
                                      selectedFormParam.Parameter
                                  ] = e._d.toLocaleDateString("en-US");
                                  this.setState({
                                    ...this.state,
                                    formParams,
                                    selectedFormParam: null,
                                  });
                                }}
                                autoFocus={true}
                                autoOk
                                required={selectedFormParam.RequiredYN === "Y"}
                                fullWidth
                              />
                            )}
                            {!selectedFormParam.list &&
                              selectedFormParam.Type !== "date" && (
                                <div className="w-full">
                                  <TextField
                                    className={classes.formControl}
                                    label={
                                      selectedFormParam.value ||
                                      selectedFormParam.Description
                                    }
                                    value={
                                      this.state.formParams[
                                        selectedFormParam.value ||
                                          selectedFormParam.Parameter
                                      ]
                                    }
                                    onChange={(e) => {
                                      const { formParams } = _.cloneDeepWith(
                                        this.state
                                      );
                                      formParams[
                                        selectedFormParam.value ||
                                          selectedFormParam.Parameter
                                      ] = e.target.value;
                                      this.setState({
                                        ...this.state,
                                        formParams,
                                      });
                                    }}
                                    onBlur={(e) => {
                                      this.setState({
                                        ...this.state,
                                        selectedFormParam: null,
                                      });
                                    }}
                                    autoFocus={true}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                    {(InstanceType === "D" || Boolean(dashboard.Co)) &&
                      Data &&
                      Data.Reports && (
                        <div
                          className={classNames(classes.formControl, "w-full")}
                        >
                          <Typography className="mb-4 w-full font-bold text-12">
                            Data Sources:
                            {!addReport && !selectedReport && (
                              <Typography
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    addReport: true,
                                    selectedReport: null,
                                  });
                                }}
                                className="px-4 font-bold text-12 cursor-pointer float-right"
                              >
                                <Icon
                                  color="secondary"
                                  className="mr-4 align-middle mb-4 text-16"
                                >
                                  add_circle_outline
                                </Icon>
                                Add Data Source
                              </Typography>
                            )}
                            {(selectedReport || addReport) && (
                              <Typography
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    addReport: false,
                                    selectedReport: null,
                                  });
                                }}
                                className="px-4 font-bold text-12 cursor-pointer float-right"
                              >
                                <Icon
                                  color="action"
                                  className="mr-4 align-middle mb-4 text-16"
                                >
                                  close
                                </Icon>
                              </Typography>
                            )}
                          </Typography>
                          <Divider className="mb-8" />
                          <div className="mb-8 w-full">
                            {Data &&
                              Data.DataSources &&
                              Data.DataSources.map((value) => {
                                const report = value.Data.Report;
                                if (
                                  !selectedReport ||
                                  selectedReport.Report === value.Report
                                ) {
                                  const fetched = _.find(dataSources, {
                                    Report: value.Report,
                                  });
                                  return (
                                    <Typography
                                      onClick={() =>
                                        this.selectDataSource(value)
                                      }
                                      key={value.ID || value.Report}
                                      className="w-full px-4 font-bold text-12 cursor-pointer"
                                    >
                                      <Icon
                                        color={
                                          fetched ? "secondary" : "primary"
                                        }
                                        className="mr-4 align-middle mb-4"
                                      >
                                        {!fetched
                                          ? "cloud_download"
                                          : "cloud_done"}
                                      </Icon>
                                      {report.Name}
                                      {fetched && (
                                        <Tooltip
                                          placement="left"
                                          title={`Last Run: ${moment(
                                            fetched.Fetched
                                          ).format("h:mm:ss A")}`}
                                        >
                                          <span className="float-right mt-4">
                                            {fetched.Results.length} Results
                                          </span>
                                        </Tooltip>
                                      )}
                                    </Typography>
                                  );
                                }
                              })}
                          </div>
                          {addReport && (
                            <Autocomplete
                              className={classes.formControl}
                              title="Report"
                              options={[
                                ..._.filter(Data.Reports, (o) => {
                                  return (
                                    _.findIndex(Data.DataSources, {
                                      Report: o.Report,
                                    }) < 0
                                  );
                                }),
                                {
                                  Name: "Refresh List",
                                  Report: "Refresh",
                                  Icon: "refresh",
                                },
                              ]}
                              menuItemComponent={(value) => {
                                if (
                                  value.ActiveYN === "Y" ||
                                  value.Report === "Refresh"
                                ) {
                                  return (
                                    <MenuItem value={value.Report}>
                                      <div className="flex">
                                        {/* <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.title}>
                                                                    <Icon>{value.icon}</Icon>
                                                                </Avatar> */}
                                        {value.Icon && (
                                          <Icon className="mr-4">
                                            {value.Icon}
                                          </Icon>
                                        )}
                                        {`${value.Name}`}
                                      </div>
                                    </MenuItem>
                                  );
                                }
                              }}
                              value={
                                selectedReport ? selectedReport.Report : ""
                              }
                              onSelect={(option) => {
                                if (option.Report === "Refresh") {
                                  this.getReportList();
                                } else {
                                  this.setState({
                                    ...this.state,
                                    selectedReport: option,
                                  });
                                }
                              }}
                              required
                            />
                          )}
                          {selectedReport &&
                            selectedReport.Data &&
                            selectedReport.Data.Parameters && (
                              <div
                                className={classNames(
                                  classes.formControl,
                                  "w-full"
                                )}
                              >
                                <Typography className="mb-4 w-full font-bold text-12">
                                  Report Parameters:
                                  <Typography
                                    onClick={() => {
                                      const params = [];
                                      const { formParams } = _.cloneDeepWith(
                                        this.state
                                      );
                                      const {
                                        Parameters,
                                      } = selectedReport.Data;
                                      Parameters.map((value) => {
                                        const param = formParams[value.Value];
                                        if (param) {
                                          params.push({
                                            Parameter: value.Parameter,
                                            Value: param,
                                          });
                                        }
                                      });
                                      this.setState({
                                        ...this.state,
                                        testDataSource: {
                                          Report: selectedReport.Report,
                                          Parameters: [...params],
                                        },
                                      });
                                    }}
                                    className="mb-4 float-right font-bold text-12 cursor-pointer"
                                  >
                                    <Icon
                                      className="align-middle mb-4 mr-4 text-16"
                                      color="primary"
                                    >
                                      cloud_download
                                    </Icon>
                                    Test Data Source
                                  </Typography>
                                </Typography>
                                <Divider className="mb-8" />
                                {selectedReport.Data.Parameters.map(
                                  (value, index) => {
                                    return (
                                      <div
                                        key={value.ID}
                                        className="float-left mr-8 mb-8"
                                      >
                                        <Chip
                                          color="primary"
                                          onClick={() =>
                                            this.setState({
                                              ...this.state,
                                              selectedParam: value,
                                            })
                                          }
                                          className={classNames(
                                            classes.black,
                                            value.Value
                                              ? "rounded-r-none pr-4"
                                              : ""
                                          )}
                                          icon={
                                            <SPRoundIcon
                                              className={
                                                value.RequiredYN === "Y"
                                                  ? value.Value
                                                    ? classes.colorSecondary
                                                    : classes.colorError
                                                  : classes.colorPrimary
                                              }
                                            />
                                          }
                                          label={value.Description}
                                        />
                                        {value.Value && (
                                          <Chip
                                            icon={
                                              <Icon
                                                className={classes.colorWhite}
                                                style={{
                                                  marginLeft: -12,
                                                  textShadow:
                                                    "0px 0px 2px #333",
                                                }}
                                              >
                                                link
                                              </Icon>
                                            }
                                            color={
                                              value.RequiredYN === "Y"
                                                ? "secondary"
                                                : "primary"
                                            }
                                            onClick={() =>
                                              this.setState({
                                                ...this.state,
                                                selectedParam: value,
                                              })
                                            }
                                            className={classNames(
                                              "rounded-l-none font-bold"
                                            )}
                                            label={value.Value}
                                          />
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                                <Divider className="mb-12 clear" />
                              </div>
                            )}
                          {this.state.testDataSource && (
                            <Media query="(min-width: 1200px)" /**/>
                              {(notMobile) => (
                                <Dialog
                                  TransitionComponent={
                                    !notMobile ? SlideUp : Grow
                                  }
                                  classes={{
                                    root: classes.dialogRoot,
                                    paper: notMobile ? classes.paper : null,
                                  }}
                                  className={classes.dialogRoot}
                                  open={this.state.testDataSource}
                                  onClose={this.closeDataSource}
                                  fullWidth
                                  maxWidth="md"
                                  fullScreen={!notMobile}
                                >
                                  <DialogContent>
                                    <Typography className="w-full px-4 font-bold text-14 mb-16 cursor-pointer">
                                      <Icon
                                        color="primary"
                                        className="mr-4 align-middle mb-4"
                                      >
                                        cloud_download
                                      </Icon>
                                      {selectedReport.Name}
                                    </Typography>
                                    <Divider className="mb-24" />
                                    <div
                                      className="w-full"
                                      style={{
                                        marginLeft: -6,
                                        marginRight: -6,
                                        width: "calc(100% + 12px)",
                                      }}
                                    >
                                      {selectedReport &&
                                        selectedReport.Data &&
                                        selectedReport.Data.Parameters &&
                                        selectedReport.Data.Parameters.map(
                                          (value) => {
                                            const { master } = this.props;
                                            const field = _.find(form.fields, {
                                              value: value.Value,
                                            });
                                            const paramVal = this.getTestParameter(
                                              value.Parameter
                                            );
                                            switch (value.Type) {
                                              case "text":
                                                {
                                                  if (field && field.list) {
                                                    const options =
                                                      _.get(
                                                        master,
                                                        field.list
                                                      ) || [];
                                                    return (
                                                      <div className="w-full sm:w-1/2 md:w-1/4 sm:float-left mb-12 px-6">
                                                        <Autocomplete
                                                          className="w-full"
                                                          title={
                                                            value.Description
                                                          }
                                                          options={options}
                                                          menuItemComponent={(
                                                            option
                                                          ) => {
                                                            let displayText;
                                                            if (
                                                              field.text &&
                                                              field.text
                                                                .length > 0
                                                            ) {
                                                              field.text.map(
                                                                (name) => {
                                                                  const text = _.get(
                                                                    option,
                                                                    name
                                                                  );
                                                                  if (text) {
                                                                    if (
                                                                      !displayText
                                                                    ) {
                                                                      displayText =
                                                                        "";
                                                                    }
                                                                    displayText += `${text} `;
                                                                  }
                                                                }
                                                              );
                                                            }
                                                            return (
                                                              <MenuItem
                                                                value={
                                                                  option
                                                                    ? option[
                                                                        field.listValue ||
                                                                          field.value
                                                                      ]
                                                                    : null
                                                                }
                                                              >
                                                                <div className="flex">
                                                                  {`${
                                                                    option
                                                                      ? displayText ||
                                                                        option[
                                                                          field.listValue ||
                                                                            field.value
                                                                        ]
                                                                      : "N/A"
                                                                  }`}
                                                                </div>
                                                              </MenuItem>
                                                            );
                                                          }}
                                                          portal={true}
                                                          value={paramVal}
                                                          onSelect={(option) =>
                                                            this.setTestParameter(
                                                              option,
                                                              value,
                                                              field
                                                            )
                                                          }
                                                          required={
                                                            value.RequiredYN ===
                                                            "Y"
                                                          }
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                }
                                                break;
                                              case "number":
                                                {
                                                  if (field && field.list) {
                                                    const options =
                                                      _.get(
                                                        master,
                                                        field.list
                                                      ) || [];
                                                    return (
                                                      <div className="w-full sm:w-1/2 md:w-1/4 sm:float-left mb-12 px-6">
                                                        <Autocomplete
                                                          className="w-full"
                                                          title={
                                                            value.Description
                                                          }
                                                          options={options}
                                                          menuItemComponent={(
                                                            option
                                                          ) => {
                                                            let displayText;
                                                            if (
                                                              field.text &&
                                                              field.text
                                                                .length > 0
                                                            ) {
                                                              field.text.map(
                                                                (name) => {
                                                                  const text = _.get(
                                                                    option,
                                                                    name
                                                                  );
                                                                  if (text) {
                                                                    if (
                                                                      !displayText
                                                                    ) {
                                                                      displayText =
                                                                        "";
                                                                    }
                                                                    displayText += `${text} `;
                                                                  }
                                                                }
                                                              );
                                                            }
                                                            return (
                                                              <MenuItem
                                                                value={
                                                                  option
                                                                    ? option[
                                                                        field.listValue ||
                                                                          field.value
                                                                      ]
                                                                    : null
                                                                }
                                                              >
                                                                <div className="flex">
                                                                  {`${
                                                                    option
                                                                      ? displayText ||
                                                                        option[
                                                                          field.listValue ||
                                                                            field.value
                                                                        ]
                                                                      : "N/A"
                                                                  }`}
                                                                </div>
                                                              </MenuItem>
                                                            );
                                                          }}
                                                          portal={true}
                                                          value={paramVal}
                                                          onSelect={(option) =>
                                                            this.setTestParameter(
                                                              option,
                                                              value,
                                                              field
                                                            )
                                                          }
                                                          required={
                                                            value.RequiredYN ===
                                                            "Y"
                                                          }
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                }
                                                break;
                                              case "date":
                                                {
                                                  return (
                                                    <div className="w-full sm:w-1/2 md:w-1/4 sm:float-left mb-12 px-6">
                                                      <DatePicker
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        className="w-full"
                                                        label={
                                                          value.Description
                                                        }
                                                        format="MM/DD/YYYY"
                                                        value={paramVal}
                                                        showTodayButton
                                                        onChange={(e) =>
                                                          this.setTestParameterDate(
                                                            e,
                                                            value
                                                          )
                                                        }
                                                        required={
                                                          value.RequiredYN ===
                                                          "Y"
                                                        }
                                                      />
                                                    </div>
                                                  );
                                                }
                                                break;
                                            }
                                          }
                                        )}
                                    </div>
                                    <Button
                                      variant="contained"
                                      className={classNames(
                                        classes.black,
                                        "w-full clear mb-12"
                                      )}
                                      color="secondary"
                                      onClick={this.testDataSource}
                                      // disabled={selectedReport && selectedReport.Data && selectedReport.Data.Parameters ? _.filter(selectedReport.Data.Parameters, (o) => { return o.RequiredYN === 'Y' && !o.Value }).length > 0 : true}
                                    >
                                      <Icon
                                        color="secondary"
                                        className="mr-4 text-20"
                                      >
                                        cloud_download
                                      </Icon>
                                      {`Test Data Source`}
                                    </Button>
                                    {testDataSource.Results &&
                                      testDataSource.columns && (
                                        <ReactTable
                                          data={[...testDataSource.Results]}
                                          columns={[...testDataSource.columns]}
                                          defaultPageSize={10}
                                          className="-highlight -striped rounded"
                                        />
                                      )}
                                  </DialogContent>
                                </Dialog>
                              )}
                            </Media>
                          )}
                          {selectedParam && (
                            <Autocomplete
                              className={classes.formControl}
                              title={selectedParam.Description}
                              options={[
                                null,
                                ..._.filter(form.fields, (o) => {
                                  return o.type === selectedParam.Type;
                                }),
                              ]}
                              menuItemComponent={(option) => {
                                return (
                                  <MenuItem
                                    value={option ? option.value : null}
                                  >
                                    <div className="flex">
                                      {`${option ? option.value : "N/A"}`}
                                    </div>
                                  </MenuItem>
                                );
                              }}
                              portal={true}
                              value={selectedParam.Value || ""}
                              onSelect={(option) => {
                                // window.alert(`SelectedParam: ${JSON.stringify(selectedParam)} - Option: ${JSON.stringify(option)}`);
                                const prm = _.find(
                                  selectedReport.Data.Parameters,
                                  { Parameter: selectedParam.Parameter }
                                );
                                if (prm) {
                                  prm.Value =
                                    option && option.value
                                      ? option.value
                                      : null;
                                }
                                this.setState({
                                  ...this.state,
                                  selectedReport,
                                  selectedParam: null,
                                });
                              }}
                              required={selectedParam.RequiredYN === "Y"}
                            />
                          )}
                          <div className="w-full flex clear">
                            {selectedReport && (
                              <Button
                                variant="contained"
                                className={classNames(
                                  classes.black,
                                  "w-full",
                                  !addReport && "rounded-r-none"
                                )}
                                color="secondary"
                                onClick={() => {
                                  const {
                                    selectedReport,
                                    Data,
                                    Dashboard,
                                    addReport,
                                  } = _.cloneDeepWith(this.state);
                                  const { DataSources } = Data;
                                  const index = _.findIndex(DataSources, {
                                    Report: selectedReport.Report,
                                  });
                                  if (index < 0) {
                                    selectedReport.Co = this.props.Co;
                                    selectedReport.Dashboard = Dashboard;
                                    // DataSources.push(selectedReport);
                                    this.setState(
                                      {
                                        ...this.state,
                                        selectedReport: null,
                                        addReport: false,
                                      },
                                      () =>
                                        this.props.addDataSource(selectedReport)
                                    );
                                  } else {
                                    selectedReport.Co = this.props.Co;
                                    selectedReport.Dashboard = Dashboard;
                                    this.setState(
                                      {
                                        ...this.state,
                                        selectedReport: null,
                                        addReport: false,
                                      },
                                      () =>
                                        this.props.updateDataSource(
                                          selectedReport
                                        )
                                    );
                                  }
                                }}
                                disabled={
                                  selectedReport &&
                                  selectedReport.Data &&
                                  selectedReport.Data.Parameters
                                    ? _.filter(
                                        selectedReport.Data.Parameters,
                                        (o) => {
                                          return (
                                            o.RequiredYN === "Y" && !o.Value
                                          );
                                        }
                                      ).length > 0
                                    : true
                                }
                              >
                                <Icon
                                  color={
                                    selectedReport &&
                                    selectedReport.Data &&
                                    selectedReport.Data.Parameters &&
                                    _.filter(
                                      selectedReport.Data.Parameters,
                                      (o) => {
                                        return o.RequiredYN === "Y" && !o.Value;
                                      }
                                    ).length > 0
                                      ? undefined
                                      : "secondary"
                                  }
                                  className="mr-4 text-20"
                                >
                                  {addReport
                                    ? "add_circle_outline"
                                    : "cloud_upload"}
                                </Icon>
                                {`${addReport ? "Add" : "Update"} Data Source`}
                              </Button>
                            )}
                            {selectedReport && !addReport && (
                              <Button
                                variant="contained"
                                className={classNames(
                                  classes.red,
                                  "w-64 rounded-l-none"
                                )}
                                onClick={() => {
                                  const {
                                    Co,
                                    selectedReport,
                                    Data,
                                    Dashboard,
                                    addReport,
                                  } = _.cloneDeepWith(this.state);
                                  const { DataSources, Components } = Data;
                                  const index = _.findIndex(DataSources, {
                                    Report: selectedReport.Report,
                                  });
                                  if (index > -1) {
                                    DataSources.splice(index, 1);
                                  }
                                  this.setState(
                                    {
                                      ...this.state,
                                      selectedReport: null,
                                      addReport: false,
                                      Data: { ...Data, DataSources },
                                    },
                                    () =>
                                      this.props.removeDataSource({
                                        Co,
                                        Dashboard,
                                        ...selectedReport,
                                      })
                                  );
                                }}
                                disabled={
                                  _.findIndex(
                                    this.state.Data.Components,
                                    (o) => {
                                      return (
                                        o.Data.Component.Report ===
                                        selectedReport.Report
                                      );
                                    }
                                  ) > -1
                                }
                                // disabled={selectedReport && selectedReport.Data && selectedReport.Data.Parameters ? _.filter(selectedReport.Data.Parameters, (o) => { return o.RequiredYN === 'Y' && !o.Value }).length > 0 : true}
                              >
                                <Icon className="mr-4 text-20">delete</Icon>
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  {(InstanceType === "D" || Boolean(dashboard.Co)) && (
                    <React.Fragment>
                      <div className="w-full sm:absolute sm:pin-b">
                        <Button
                          variant="contained"
                          className={classNames("m-12 float-left")}
                          color="primary"
                          onClick={() => this.updateDashboard(dashboard)}
                        >
                          Save
                        </Button>
                        <IconButton
                          className={classNames("m-12 float-right")}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              confirmDelete: true,
                            })
                          }
                        >
                          <Icon color="action">delete</Icon>
                        </IconButton>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </Drawer>
              <ConfirmationDialog
                open={this.state.confirmDelete}
                title={
                  <div>
                    <Icon className="mr-6 align-middle mb-4">delete</Icon>Delete
                    Dashboard?
                  </div>
                }
                content={
                  <div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this dashboard? This action cannot be undone.`}</div>
                }
                confirmText="Delete"
                cancelText="Never Mind"
                onCancel={() =>
                  this.setState({ ...this.state, confirmDelete: false })
                }
                onConfirm={() =>
                  this.setState({ ...this.state, confirmDelete: false }, () => {
                    this.props.deleteDashboard(dashboard);
                  })
                }
              />
              {dataSources &&
                dataSources.length > 0 &&
                fetchingData.length < 1 && (
                  <div
                    className={classNames(
                      "clear m-12 mt-0 pb-64",
                      selectedLayout ? `device-${selectedLayout}` : ""
                    )}
                  >
                    <Menu
                      id="component-menu"
                      anchorEl={actionEl}
                      open={Boolean(actionEl)}
                      onClose={() =>
                        this.setState({
                          ...this.state,
                          actionEl: null,
                          actionEdit: null,
                          actionUnassign: null,
                        })
                      }
                    >
                      <MenuItem onClick={actionEdit}>Edit</MenuItem>
                      <MenuItem onClick={actionUnassign}>Remove</MenuItem>
                    </Menu>
                    <ResponsiveGridLayout
                      className={classNames("layout w-full overflow-auto")}
                      breakpoints={{
                        xl: 1600,
                        lg: 1200,
                        md: 996,
                        sm: 480,
                        xs: 0,
                      }}
                      cols={{ xl: 24, lg: 18, md: 12, sm: 8, xs: 2 }}
                      rowHeight={64}
                      compactType={"vertical"}
                      margin={[16, 16]}
                      isDraggable={
                        !lockLayout &&
                        selectedLayout &&
                        (InstanceType === "D" || Boolean(dashboard.Co))
                      }
                      isResizable={
                        !lockLayout &&
                        selectedLayout &&
                        (InstanceType === "D" || Boolean(dashboard.Co))
                      }
                      measureBeforeMount={false}
                      width={
                        selectedLayout
                          ? selectedLayout === "xl"
                            ? 1800
                            : selectedLayout === "lg"
                            ? 1600
                            : selectedLayout === "md"
                            ? 1000
                            : selectedLayout === "sm"
                            ? 750
                            : 350
                          : matches.xl
                          ? 1800
                          : matches.lg
                          ? 1600
                          : matches.md
                          ? 1000
                          : matches.sm
                          ? 750
                          : 350
                      }
                      //width={matches.xl ? 1600 : matches.lg ? 1200 : matches.md ? 996 : matches.sm ? 480 : 0 }
                      onDragStop={(
                        layout,
                        oldItem,
                        newItem,
                        placeholder,
                        e,
                        element
                      ) => this.handleLayout(layout)}
                      onResizeStart={(
                        layout,
                        oldItem,
                        newItem,
                        placeholder,
                        e,
                        element
                      ) =>
                        this.handleResizeStart(
                          layout,
                          oldItem,
                          newItem,
                          placeholder,
                          e,
                          element
                        )
                      }
                      onResizeStop={(
                        layout,
                        oldItem,
                        newItem,
                        placeholder,
                        e,
                        element
                      ) =>
                        this.handleLayout(
                          layout,
                          oldItem,
                          newItem,
                          placeholder,
                          e,
                          element
                        )
                      }
                      onWidthChange={(w, m, c, p) =>
                        window["warn"]("RGL Resized: ", w, m, c, p)
                      }
                      layouts={layouts}
                    >
                      {Data.Components.map((component, index) => {
                        const comp = component.Data.Component;

                        const fetched = _.find(dataSources, {
                          Report: comp.Report,
                        });
                        const layout = matches.xl
                          ? "xl"
                          : matches.lg
                          ? "lg"
                          : matches.md
                          ? "md"
                          : matches.sm
                          ? "sm"
                          : "xs";
                        const size =
                          layouts && layouts[layout]
                            ? [...layouts[layout]][index]
                            : null;
                        if (fetched) {
                          switch (comp.Type) {
                            case "P":
                              {
                                return (
                                  <Card
                                    key={component.Component}
                                    className="rounded-6"
                                    classes={{
                                      root: classNames(
                                        classes.cardRoot,
                                        lockLayout ? classes.touchScroll : ""
                                      ),
                                    }} /*onClick={(e) => e.stopPropagation()}*/
                                  >
                                    <Typography className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36">
                                      <Icon
                                        color="secondary"
                                        className="mr-4 align-middle mb-4"
                                      >
                                        insert_chart
                                      </Icon>
                                      {comp.Name}
                                    </Typography>
                                    {(InstanceType === "D" ||
                                      Boolean(dashboard.Co)) && (
                                      <IconButton
                                        style={{
                                          position: "absolute",
                                          right: 7,
                                          top: 4,
                                        }}
                                        className="p-4"
                                        onClick={(event) => {
                                          this.setAction(
                                            event.currentTarget,
                                            () => this.editComponent(comp),
                                            () => this.unassign(component)
                                          );
                                        }}
                                      >
                                        <Icon className="text-20">
                                          more_vert
                                        </Icon>
                                      </IconButton>
                                    )}
                                    {fetched &&
                                    fetched.Results &&
                                    fetched.Results.length > 0 ? (
                                      <PivotTable
                                        dashboard={true}
                                        key={component.Component}
                                        options={{ ...comp }}
                                        results={[...fetched.Results]}
                                      />
                                    ) : (
                                      <React.Fragment>
                                        <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                          <Icon
                                            color="action"
                                            className="mr-4 align-middle mb-4 text-16"
                                          >
                                            warning
                                          </Icon>
                                          No Results
                                        </Typography>
                                      </React.Fragment>
                                    )}
                                  </Card>
                                );
                              }
                              break;
                            case "T": {
                              return (
                                <Card
                                  key={component.Component}
                                  className="rounded-6"
                                  classes={{
                                    root: classNames(
                                      classes.cardRoot,
                                      lockLayout ? classes.touchScroll : ""
                                    ),
                                  }} /*onClick={(e) => e.stopPropagation()}*/
                                >
                                  <Typography
                                    className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36"
                                    style={{
                                      borderBottom: "1px solid #f1f1f1",
                                    }}
                                  >
                                    <Icon
                                      color="secondary"
                                      className="mr-4 align-middle mb-4"
                                    >
                                      insert_chart
                                    </Icon>
                                    {comp.Name}
                                  </Typography>
                                  {(InstanceType === "D" ||
                                    Boolean(dashboard.Co)) && (
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        right: 7,
                                        top: 4,
                                      }}
                                      className="p-4"
                                      onClick={(event) => {
                                        this.setAction(
                                          event.currentTarget,
                                          () => this.editComponent(comp),
                                          () => this.unassign(component)
                                        );
                                      }}
                                    >
                                      <Icon className="text-20">more_vert</Icon>
                                    </IconButton>
                                  )}
                                  {fetched &&
                                  fetched.Results &&
                                  fetched.Results.length > 0 ? (
                                    <Tile
                                      key={component.Component}
                                      options={{ ...comp }}
                                      results={[...fetched.Results]}
                                    />
                                  ) : (
                                    <React.Fragment>
                                      <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                        <Icon
                                          color="action"
                                          className="mr-4 align-middle mb-4 text-16"
                                        >
                                          warning
                                        </Icon>
                                        No Results
                                      </Typography>
                                    </React.Fragment>
                                  )}
                                </Card>
                              );
                            }

                            case "C": {
                              const chartId = `component${comp.ID}`;
                              const showChart =
                                fetched &&
                                fetched.Results &&
                                fetched.Results.length > 0 &&
                                this.state.resizingInfo.elementId !== chartId;
                              const showResizing =
                                this.state.resizingInfo.isResizing &&
                                this.state.resizingInfo.elementId === chartId;

                              return (
                                <Card
                                  key={component.Component}
                                  id={`component${component.ID}`}
                                  className="rounded-6"
                                  classes={{
                                    root: classNames(
                                      classes.cardRoot,
                                      lockLayout ? classes.touchScroll : ""
                                    ),
                                  }} /*onClick={(e) => e.stopPropagation()}*/
                                >
                                  {(InstanceType === "D" ||
                                    Boolean(dashboard.Co)) && (
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        right: 7,
                                        top: 4,
                                      }}
                                      className="p-4"
                                      onClick={(event) => {
                                        this.setAction(
                                          event.currentTarget,
                                          () => this.editComponent(comp),
                                          () => this.unassign(component)
                                        );
                                      }}
                                    >
                                      <Icon className="text-20">more_vert</Icon>
                                    </IconButton>
                                  )}

                                  {showChart ? (
                                    <Chart
                                      key={component.Component}
                                      title={comp.Name}
                                      height={size ? size.h * 64 : undefined}
                                      width={size ? size.w : undefined}
                                      options={{ ...comp }}
                                      results={[...fetched.Results]}
                                    />
                                  ) : showResizing ? (
                                    <React.Fragment>
                                      <Typography
                                        className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36"
                                        style={{
                                          borderBottom: "1px solid #f1f1f1",
                                        }}
                                      >
                                        <Icon
                                          color="secondary"
                                          className="mr-4 align-middle mb-4"
                                        >
                                          insert_chart
                                        </Icon>
                                        {comp.Name}
                                      </Typography>
                                      <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                        <Icon
                                          color="action"
                                          className="mr-4 align-middle mb-4 text-16"
                                        >
                                          warning
                                        </Icon>
                                        Resizing...
                                      </Typography>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Typography
                                        className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36"
                                        style={{
                                          borderBottom: "1px solid #f1f1f1",
                                        }}
                                      >
                                        <Icon
                                          color="secondary"
                                          className="mr-4 align-middle mb-4"
                                        >
                                          insert_chart
                                        </Icon>
                                        {comp.Name}
                                      </Typography>
                                      <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                        <Icon
                                          color="action"
                                          className="mr-4 align-middle mb-4 text-16"
                                        >
                                          warning
                                        </Icon>
                                        No Results
                                      </Typography>
                                    </React.Fragment>
                                  )}
                                </Card>
                              );
                            }
                          }
                        }
                      })}
                    </ResponsiveGridLayout>
                    {(InstanceType === "D" || Boolean(dashboard.Co)) && (
                      <div
                        className={classNames("m-12 clear w-256")}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            ...this.state,
                            componentDialog: true,
                          });
                        }}
                      >
                        <Typography className="font-bold text-16 cursor-pointer text-white">
                          <Icon
                            color="secondary"
                            className="mr-4 align-middle mb-4 text-24"
                          >
                            add_circle_outline
                          </Icon>
                          New Component
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        )}
      </Media>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDashboard: Actions.getDashboard,
      resetDashboard: Actions.resetDashboard,
      deleteDashboard: Actions.deleteDashboard,
      updateDashboard: Actions.updateDashboard,
      addDataSource: Actions.addDataSource,
      updateDataSource: Actions.updateDataSource,
      removeDataSource: Actions.removeDataSource,
      addDashboardComponent: Actions.addDashboardComponent,
      updateDashboardComponent: Actions.updateDashboardComponent,
      removeDashboardComponent: Actions.removeDashboardComponent,
      setDashboardFilters: Actions.setDashboardFilters,
      setDashboardDrilled: Actions.setDashboardDrilled,
      setDefaultDashboard: Actions.setDefaultDashboard,
    },
    dispatch
  );
}

function mapStateToProps({ dashboardBuilderApp, spReducers, version }) {
  return {
    master: spReducers,
    dashboard: dashboardBuilderApp.dashboard,
    Co: spReducers.companies.Co,
    username: spReducers.userProfiles.User.UserName,
    securables: spReducers.userProfiles.User.Data.Securables,
    securablesList: spReducers.userProfiles.Securables,
    technicians: spReducers.technicians,
    users: spReducers.userProfiles.Users,
    filters: dashboardBuilderApp.dashboard.filters,
    about: version.about,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
);
