import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Chip, Icon, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MailCompose from 'main/content/apps/mail/MailCompose';
import { NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from '@lodash';
import { openSupportRequestDialog } from './requests/store/actions';
import { getSupportRequests } from 'store/actions';
import ReactTable from "react-table";
import { guid } from 'store/actions';
import moment from 'moment';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: '#fff !important',
        width: 72,
        height: 72,
        margin: 'auto',
        marginBottom: -48,
        top: -48,
    },
    labels: {},
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },

});

class SupportResources extends Component {

    state = {
        sendEmail: false,
        tab: 'resources',
        requests: [],
        filter: 'S',
        testError: false
    };

    componentDidMount() {
        this.setState({ requests: this.props.requests, filter: this.props.filter });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { requests, filter } = this.props;
        if (!_.isEqual(requests, prevProps.requests) || !_.isEqual(filter, prevProps.filter)) {
            this.setState({ requests, filter });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    launchEmail = () => {
        const { user, companies } = this.props;
        const { Co, List } = companies;
        const co = _.find(List, { Co });
        const info = {
            composeDialog: true,
            Title: 'Application Support Request',
            Icon: "email",
            To: `support@servicepointpro.com`,
            Subject: `Application Support Request from ${user.FirstName} ${user.LastName}${co && co.Data && co.Data.Co ? ` at ${co.Data.Co.Name}` : ''}`,
            Message: '',
            Body: null,
            Attachments: [],
            disableTo: true,
            disableSubject: true,
        }
        this.setState({ ...this.state, sendEmail: info, });
    }

    createRequest = () => {
        const ClientID = guid();
        this.props.openSupportRequestDialog({
            ID: 0,
            ClientID,
            UserName: this.props.user.UserName,
            Title: null,
            Description: null,
            AddedDate: new Date(),
            Data: { Attachments: [], Tags: [], Assignees: [], Comments: [], Checklists: [], ErrMsg: null }
        })
    }

    changeFilter = (e) => {
        this.setState({ filter: e.target.value }, () => this.props.getSupportRequests(this.state.filter));

    }

    render() {
        const { classes, searchText, isSupportProvider } = this.props;
        const { sendEmail, tab, filter } = this.state;
        const statuses = {
            'new': {
                id: 0,
                text: 'New'
            },
            'assigned': {
                id: 1,
                text: 'Assigned'
            },
            'in-progress': {
                id: 2,
                text: 'In Progress'
            },
            'on-hold': {
                id: 3,
                text: 'On Hold'
            },
            'qa': {
                id: 4,
                text: 'Q/A'
            },
            'completed': {
                id: 5,
                text: 'Completed'
            },
        };
        const tags = [
            {
                Tag: "inquiry",
                Name: "Inquiry",
                Color: "#4f4f4f",
                Background: "yellow"
            },
            {
                Tag: "bug",
                Name: "Bug",
                Color: "#fff",
                Background: "orangered"
            },
            {
                Tag: "improvement",
                Name: "Improvement",
                Color: "#fff",
                Background: "orange"
            },
            {
                Tag: "feature",
                Name: "Feature",
                Color: "#fff",
                Background: "blue"
            },
            {
                Tag: "reporting",
                Name: "Reporting",
                Color: "#fff",
                Background: "#5f5f5f"
            }
        ];
        const priorityColors = ['#4dbce9', '#f44336', 'yellow', '#fff']

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {tab === 'resources' &&
                        <div>
                            <div className="w-full flex justify-between">
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">headset_mic</Icon>Support Resources</Typography>
                                <Button color="primary" onClick={() => this.setState({ tab: 'requests' })} className="mb-6 sm:mb-12">Tickets<Icon>keyboard_arrow_right</Icon></Button>
                            </div>
                            <div className="w-full md:flex">
                                <div className="w-full sm:flex">
                                    <Card className="w-full mx-0 sm:mx-8 md:mx-16 relative mb-24">
                                        <CardMedia
                                            component="image"
                                            className="h-256 md:h-384 w-full"
                                            image="assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg"
                                            alt="SP Pro"
                                        />
                                        <CardContent className="pb-96">
                                            <Avatar className={classes.avatar}><Icon onClick={isSupportProvider ? () => this.setState({ testError: true }) : undefined} className={classNames("text-48", isSupportProvider && "cursor-pointer")} style={{ color: '#5f5f5f' }}>assignment_late</Icon></Avatar>
                                            {isSupportProvider && this.state.testError &&
                                                <Typography gutterBottom variant="h6" component="div">
                                                    {this.doesntExist.toString()}
                                                </Typography>
                                            }
                                            <Typography variant="body2" color="text.secondary">
                                                Need some help? Create a Support Ticket letting us know what's going on, and we'll do our best to get it taken care of in a timely fashion!
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="w-full pin-b pin-l pin-r absolute justify-between p-12">
                                            <Button role="button" onClick={this.createRequest} /*onClick={this.launchEmail}*/
                                                variant="contained" className={classNames("w-full p-8 text-14", classes.blackButton)} color="primary">
                                                <Icon color="secondary" className="mr-6">assignment_late</Icon>Create a Ticket
                                            </Button>
                                        </CardActions>
                                    </Card>
                                    <Card className="w-full mx-0 sm:mx-8 md:mx-16 relative mb-24">
                                        <CardMedia
                                            component="image"
                                            className="h-256 md:h-384 w-full"
                                            image="assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"
                                            alt="SP Pro"
                                        />
                                        <CardContent className="pb-96">
                                            <Avatar className={classes.avatar}><Icon className="text-48" color="primary">phone</Icon></Avatar>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Contact Us via Phone
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Our Customer Support Team is available 24/7 to provide you the help you need, and can be reached at (877) 351-2470. We're just a phone call away!
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="w-full pin-b pin-l pin-r absolute justify-between p-12">
                                            <Button role="button" href="tel:+18773512470" variant="contained" className={classNames("w-full p-8 text-14", classes.blackButton)} color="primary">
                                                <Icon color="secondary" className="mr-6">phone</Icon>Call Support
                                            </Button>
                                        </CardActions>
                                        {sendEmail &&
                                            <MailCompose onClose={() => this.setState({ ...this.state, sendEmail: null })} hideButton={true} data={sendEmail} />
                                        }
                                    </Card>
                                </div>
                                <div className="w-full sm:w-1/2 px-0 sm:px-8 md:px-16">
                                    <Card className="w-full mx-0 relative mb-24">
                                        <CardMedia
                                            component="image"
                                            className="h-256 md:h-384 w-full"
                                            image="assets/images/backgrounds/SP_Spinner_BG_Blue_XS.jpg"
                                            alt="SP Pro"
                                        />
                                        <CardContent className="pb-96">
                                            <Avatar className={classes.avatar}><Icon className="text-48" style={{ color: '#5f5f5f' }}>school</Icon></Avatar>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Check out the Learning Center
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Be sure to check out our articles on the Learning Center - chances are, if you need guidance with a specific issue or feature, the Learning Center has the answers you need!
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="w-full pin-b pin-l pin-r absolute justify-between p-12">
                                            <NavLink role="button" to="/apps/academy/0" className="w-full">
                                                <Button variant="contained" className={classNames("w-full p-8 text-14", classes.blackButton)} color="primary">
                                                    <Icon color="secondary" className="mr-6">school</Icon>Learning Center
                                                </Button>
                                            </NavLink>
                                        </CardActions>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    }
                    {tab === 'requests' &&
                        <div>
                            <div className="w-full flex justify-between">
                                {!isSupportProvider ?
                                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20">Support Tickets</Typography> :
                                    <div className="flex">
                                        <Icon color="primary" className="text-32 mr-12">assignment_late</Icon>
                                        <TextField
                                            className="m-0"
                                            value={filter || "O"}
                                            select
                                            margin="dense"
                                            InputProps={{
                                                disableUnderline: true,
                                                className: "text-20"
                                            }}
                                            onChange={this.changeFilter}
                                        >
                                            <MenuItem value="S">
                                                Submitted Tickets
                                            </MenuItem>
                                            <MenuItem value="A">
                                                Assigned Tickets
                                            </MenuItem>
                                            <MenuItem value="U">
                                                Unassigned Tickets
                                            </MenuItem>
                                            <MenuItem value="*">
                                                All Tickets
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                }
                                <Button color="primary" onClick={() => this.setState({ tab: 'resources' })}><Icon>keyboard_arrow_left</Icon>Resources</Button>
                            </div>
                            {/* <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={this.createRequest}
                        >
                            <Icon>add</Icon>
                        </Fab> */}
                            <ReactTable
                                className={classNames(classes.root, "-striped -highlight border-0 px-12")}
                                getTrProps={(state, rowInfo, column) => {
                                    return {
                                        className: classNames("cursor-pointer", (rowInfo && rowInfo.original.Status) === 'completed' ? 'text-grey' : ''),
                                        onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                                this.props.openSupportRequestDialog(rowInfo.original, true);
                                            }
                                        }
                                    }
                                }}
                                data={[...this.state.requests]}
                                columns={
                                    [
                                        {
                                            Header: '',
                                            accessor: "Priority",
                                            Cell: (row) => {
                                                return row.original.Status === 'completed' ? <Icon className="text-20" style={{ color: 'rgb(83, 203, 83)' }}>check_circle_outline</Icon> : row.value ? <Icon className="text-20" style={{ color: priorityColors[row.value] }}>flag</Icon> : ''
                                            },
                                            className: "justify-center",
                                            maxWidth: 48
                                        },
                                        {
                                            Header: "ID",
                                            accessor: "ID",
                                            className: "justify-center",
                                            maxWidth: 64
                                            //filterable: true,
                                        },
                                        {
                                            Header: "Status",
                                            accessor: row => {
                                                return statuses[row.Status].id
                                            },
                                            Cell: (row) => {
                                                return statuses[row.original.Status].text
                                            },
                                            id: 'Status',
                                            className: "justify-center",
                                            maxWidth: 96
                                            //filterable: true,
                                        },
                                        {
                                            Header: "Title",
                                            accessor: "Title",
                                            className: "justify-start",
                                            maxWidth: 192
                                            //filterable: true,
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "Description",
                                            maxWidth: 384,
                                            className: "truncate"
                                        },
                                        {
                                            Header: "Submitted",
                                            accessor: "AddedDate",
                                            maxWidth: 128,
                                            className: "justify-center",
                                            Cell: (row) => {
                                                return moment(row.value).format("MM/DD/YYYY")
                                            }
                                        },
                                        {
                                            Header: <Icon className="text-20">label</Icon>,
                                            accessor: "Tags",
                                            Cell: (row) => {
                                                const { value } = row;
                                                let vals = [];
                                                if (value) {
                                                    vals = value.split(',');
                                                }
                                                return <div className="w-full flex justify-center">
                                                    {
                                                        vals.map((val) => {
                                                            const tag = _.find(tags, { Tag: val.trim() });
                                                            if (tag) {
                                                                return <Chip label={tag.Name} className="mx-4" style={{ backgroundColor: tag.Background, color: tag.Color, fontSize: 11, height: 16 }} />
                                                            }
                                                        })
                                                    }
                                                </div>
                                            },
                                            maxWidth: 128,
                                            className: "justify-center",
                                        },
                                        {
                                            Header: <Icon className="text-20" style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>,
                                            accessor: "Attachments",
                                            maxWidth: 64,
                                            className: "justify-center",
                                        },
                                        {
                                            Header: <Icon className="text-20">comment</Icon>,
                                            accessor: "Comments",
                                            maxWidth: 64,
                                            className: "justify-center",
                                        },
                                    ]
                                }
                                defaultPageSize={10}
                                noDataText="No Support Tickets found"
                            />
                        </div>
                    }
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openSupportRequestDialog,
        getSupportRequests
    }, dispatch);
}

function mapStateToProps({ supportApp, spReducers, support, version }) {
    return {
        searchText: supportApp.support.searchText,
        user: spReducers.userProfiles.User,
        companies: spReducers.companies,
        requests: support.requests,
        isSupportProvider: (version.about || {}).SupportProvider,
        filter: support.filter
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportResources)));
