import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse';
import { Avatar, Badge, Button, Card, CardHeader, CardContent, Checkbox, Dialog, Icon, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField, Typography, Tooltip } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import * as TripActions from '../trips/store/actions';
import TechnicianTrip from './TechnicianTrip';
import classNames from 'classnames';
import moment from 'moment';
import _ from '@lodash';

const styles = theme => ({
    root: {
        maxHeight: '75vh',
        overflow: 'auto'
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    calendar: {
        width: '100%',
        background: theme.palette.primary.main,
        borderRadius: 5,
    },
    calendarHeader: {
        width: '100%',
    },
    calendarPicker: {
        height: 72,
        width: '100%',
        background: '#3f3f3f',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    calendarPickerDates: {
        height: 72,
        width: '100%',
    },
    mailItem: {},
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    labels: {},
    badge: {
        top: 5,
        right: 15,
    },
    liRoot: {
        padding: 0
    },
    clockInBtn: {
        background: '#333',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        }
    },
    clockOutBtn: {
        background: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        '&:active': {
            background: '#5f5f5f',
        }
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    withdraw: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    inlineBadge: {
        top: '25%',
        left: '110%',
        right: 'unset',
        fontSize: 11,
        padding: 4,
        height: 16,
        transform: 'none',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
});

const initialState = {
    loading: false,
    expanded: {},
    trips: [],
    schedule: null,
    selectedTrip: null,
    data: {
        Date: null,
        trips: [],
    }
}

class TechnicianTimecard extends Component {

    state = {
        ...initialState
    };

    componentDidMount() {
        let { data } = this.props;
        this.setState({ ...this.state, data })
    }

    componentDidUpdate(prevProps, prevState) {
        let { data } = this.props;
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ ...this.state, data })
        }
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    selectTrip = (trip) => {
        let { selectedTrip } = this.state;
        this.setState({ ...this.state, selectedTrip: (trip && selectedTrip && selectedTrip.ID === trip.ID) ? null : trip });
    }

    updateTrip = (trip) => {
        const { UserName } = this.props.user;
        trip.UpdatedBy = UserName;
        trip.UpdatedDate = new Date();
        this.props.updateTrip(trip);
    }

    render() {
        const { classes, open, technician, date, hold, animation, search, loading, supervisor, noBack } = this.props;
        const { data } = this.state;
        console.warn('Schedule Data: ', data);
        const { trips } = data;

        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }

        return (
            <Card className="mt-4 mb-8 rounded-lg" style={{ border: '1px solid lightgrey', touchAction: 'pan-y' }}>
                <CardHeader onClick={this.props.onClick} className={classNames("p-12 relative", "highlight")}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classes.subheader, }
                    }
                    avatar={
                        <Icon color={hold ? "error" : open || date === data.Date ? 'primary' : undefined} className={`${increaseFontSize ? "text-28" : "text-24"} mt-4`}>{open && !noBack ? 'keyboard_arrow_left' : hold ? 'pause_circle_outline' : 'today'}</Icon>
                    }
                    action={
                        <div className={classNames("absolute w-96 pin-r mr-16 pin-t text-right", search ? "mt-6 pt-12" : "mt-12")}>
                            <Typography variant="caption" className={`${increaseFontSize ? "text-16" : "text-12"} font-bold`}>{`${trips.length} Trip${trips.length === 1 ? '' : 's'}`}</Typography>
                            {!hold && !search &&
                                <Typography variant="caption" className={`${increaseFontSize ? "text-14" : "text-10"} text-grey-darker`}>{`${_.sumBy(data.trips, (t) => { return t.Duration }).toFixed(2)} Hours`}</Typography>
                            }
                        </div>
                    }
                    title={
                        <Typography className={`flex ${increaseFontSize ? "text-16" : "text-12"} font-bold`}>
                            <span>{hold ? 'On Hold' : search ? 'Search Results' : moment(data.Date).format('dddd')}</span>
                        </Typography>
                    }
                    subheader={<span className={`${increaseFontSize ? "text-14" : "text-11"}`}>{!hold ? data.Date : undefined}</span>}
                >
                </CardHeader>
                {open &&
                    <div className="w-full p-12 pt-4 pb-8" style={{ borderTop: '1px solid lightgrey', backgroundColor: '#f8f8f8' }}>
                        <FuseAnimateGroup
                            enter={{
                                animation: animation || "transition.slideRightIn"
                            }}
                        >
                            {loading &&
                                <div className="w-full p-24 text-center mt-8 font-bold text-12 h-64 relative">
                                    <div className="loader stretchBar" style={{
                                        margin: 'auto',
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        bottom: 30,
                                        width: '100%',
                                        position: 'absolute',
                                    }}>
                                        <div className="rect1"></div>
                                        <div className="rect2"></div>
                                        <div className="rect3"></div>
                                        <div className="rect4"></div>
                                        <div className="rect5"></div>
                                    </div>
                                </div>
                            }
                            {!loading && (!trips || trips.length < 1) &&
                                <div className="w-full p-24 text-center mt-8 font-bold text-12">
                                    No Assignments Found
                                </div>
                            }
                            {!loading && trips && trips.length > 0 && (search ? trips : _.sortBy([...trips], (trip) => { return (trip.Status > 3 ? moment(moment(trip.BoardTime)).local() : moment(trip.BoardTime).local()) })).map((trip, index) => {
                                window["warn"](trip);
                                const { Data } = trip;
                                if (Data) {
                                    const { WorkOrder } = Data;
                                    if (WorkOrder && WorkOrder.Data) {
                                        const { Site } = WorkOrder.Data;
                                        return (
                                            <div className="w-full">
                                                <TechnicianTrip hold={hold} search={search} supervisor={supervisor} onUpdate={(trip) => { this.updateTrip(trip); }} clickHandler={(t) => this.props.selectTrip(t)} key={trip.ID} workOrder={{ ...trip.Data.WorkOrder, value: 2 }} technician={{ ...technician }} trip={trip} />
                                            </div>
                                        )
                                    }
                                }
                            })
                            }
                        </FuseAnimateGroup>
                    </div>
                }
            </Card>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clockIn: Actions.clockIn,
        editPunch: Actions.editPunch,
        editTimecard: Actions.editTimecard,
        removePunch: Actions.removePunch,
        updateTrip: TripActions.updateTrip,
    }, dispatch);
}

function mapStateToProps({ spReducers, technicianApp, techniciansApp }) {
    return {
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        loading: techniciansApp.technicians.technicianDialog.loading,
        userPreferences: spReducers.userProfiles.User.Preferences,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianTimecard)));
