import axios from 'axios/index';

export const SET_CUSTOMER_DATA = '[SPCONNECTION] SET CUSTOMER DATA';
export const GET_CUSTOMER_DATA = '[SPCONNECTION] GET CUSTOMER DATA';
export const SUBSCRIBE_CUSTOMER_LIST = '[SPCONNECTION] SUBSCRIBE CUSTOMER LIST';

export function getCustomerData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Customer`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CUSTOMER_DATA,
                payload: response.data
            })
        });
    }
}

export function setCustomerData(data) {
    return {
        type: SET_CUSTOMER_DATA,
        payload: data
    };
}