import _ from '@lodash';
import { Avatar, Button, CardHeader, FormControlLabel, Icon, Link, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import { SPRoundIcon } from 'main/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import { openNewServiceUnitDialog, openEditServiceUnitDialog } from '../service-units/store/actions';
import MailCompose from 'main/content/apps/mail/MailCompose';


const styles = theme => ({
    green: {
        backgroundColor: '#d1e751',
        color: '#3f3f3f'
    },
    blue: {
        backgroundColor: '#4dbce9',
        color: '#fff'
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    borderTop: {
        borderTop: '1px solid lightgrey'
    },
    avatar: {
        width: 28,
        height: 28
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    ok: {
        background: '#333',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        },
        '&:focus': {
            backgroundColor: '#5f5f5f',
        },
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    greenText: {
        color: '#d1e751',
    },
});
const initialState = {
    units: [],
    exclusion: null,
    unit: null,
    customer: null,
    site: null,
    autoFocus: false
};

class ServiceUnitList extends Component {
    state = { ..._.cloneDeepWith(initialState) };

    componentDidMount() {
        const { units, customer, site, } = this.props;
        this.setState({ ...this.state, units, customer, site });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { units, customer, site } = this.props;
        if (!_.isEqual(prevProps.units, units) || !_.isEqual(prevProps.customer, customer) || !_.isEqual(prevProps.site, site)) {
            this.setState({ ...this.state, units, customer, site });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null, anchorData: null });
    }


    addServiceUnit = (newUnit) => {

        const { site, units } = this.state;
        const { scope } = this.props;
        const { Co, WorkOrder, Scope } = scope;

        const { Customer, Site } = site;
        const { UnitID } = newUnit;
        const unit = {
            Co,
            Customer,
            Site,
            WorkOrder,
            Scope,
            UnitID,
            Data: {
                ErrMsg: null,
            }
        }

        if (WorkOrder && Scope) {
            const request = axios.post(`${window["apiLocation"]}/api/ServiceUnitAssignment`,
                unit
            );

            request.then((response) => {
                if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                    this.props.showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    });
                    this.setState({ autoFocus: false });
                } else {
                    const Units = _.cloneDeepWith(units);
                    Units.push(response.data);
                    this.setState({ ...this.state, unit: null, units: Units, autoFocus: false }, () => {
                        this.props.onAdd(response.data);
                        this.props.showMessage({
                            message: `Successfully added Unit #${response.data.UnitID} to Scope #${response.data.Scope}`,
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        });
                    });
                }
            });
        } else {
            const Units = _.cloneDeepWith(units);
            Units.push(unit);
            this.setState({ ...this.state, unit: null, units: Units, autoFocus: false }, () => {
                this.props.onAdd(unit);
            });
        }
    }

    removeServiceUnit = (unit) => {

        const { units } = this.state;

        const { scope } = this.props;

        const { Co, WorkOrder, Scope } = scope;

        const { Customer, Site, UnitID } = unit;

        window["warn"]('Removed Unit: ', Co, WorkOrder, Scope, Customer, Site, UnitID);

        if (Co && Customer && Site && WorkOrder && Scope && UnitID) {

            const request = axios.delete(`${window["apiLocation"]}/api/ServiceUnitAssignment?Co=${Co}&Customer=${Customer}&Site=${Site}&WorkOrder=${WorkOrder}&Scope=${Scope}&UnitID=${encodeURIComponent(UnitID)}`);

            request.then((response) => {
                const Units = _.cloneDeepWith(units);
                const index = _.findIndex(Units, (o) => o.UnitID === unit.UnitID);
                if (index > -1) {
                    Units.splice(index, 1);
                }
                this.setState({ ...this.state, unit: null, units: Units }, () => {
                    this.props.onRemove(unit);
                    this.props.showMessage({
                        message: `Unit #${unit.UnitID} was successfully removed from Scope #${unit.Scope}`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            });
        } else {
            const Units = _.cloneDeepWith(units);
            const index = _.findIndex(Units, (o) => o.UnitID === unit.UnitID);
            if (index > -1) {
                Units.splice(index, 1);
            }
            this.setState({ ...this.state, unit: null, details: Units, autoFocus: false }, () => {
                this.props.onRemove(unit);
            });
        }
    }

    addUnit = (unit) => {
        if (this.props.onAdd) {
            const { Co, Customer, Site } = this.props.site;
            const { WorkOrder, Scope } = this.props.scope || {};
            const { UnitID } = unit;
            this.props.onAdd({
                Co,
                Customer,
                Site,
                WorkOrder,
                Scope,
                UnitID
            });
        }
    }

    removeUnit = (unit) => {
        if (this.props.onRemove) {
            this.props.onRemove(unit);
        }
    }

    render() {
        const { classes, estimate, parent, editable, billing, label, onCancel } = this.props;
        let { units, anchorEl, anchorData, unitEmailData, autoFocus, site, scope } = this.state;
        if (units) {
            units = _.filter([...units], (o) => { return o.DeleteYN !== 'Y' });
        }
        let siteUnits = site && site.Data && site.Data.Units ? site.Data.Units : [];
        siteUnits = _.filter(siteUnits, (o) => _.findIndex(units, { UnitID: o.UnitID }) < 0);
        const selectedUnit = anchorData && site && site.Data && site.Data.Units ? _.find(site.Data.Units, { UnitID: anchorData.UnitID }) : null;
        const { Co, Customer, Site } = site || {};
        return (
            <div className="w-full">
                <label className="text-12 p-4 px-6 ml-8" style={{ background: '#fff', color: 'grey' }}>Service Units
                </label>
                <div className="mb-24 sm:mb-12 rounded-4 px-12 mb-12 py-4" style={{ border: '1px solid rgba(0, 0, 0, .25)', marginTop: -11 }}>
                    <div className={units && units.length > 0 ? "" : "my-8"}>
                        <React.Fragment>
                            {anchorEl && anchorData && selectedUnit &&
                                <Menu id="unit-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                                    <MenuItem onClick={() => { this.props.openEditServiceUnitDialog(selectedUnit); this.closeMenu(); }}><Icon className="mr-24">edit</Icon>Edit Service Unit</MenuItem>
                                    <MenuItem onClick={() => { this.removeServiceUnit(anchorData); this.closeMenu(); }}><Icon className="mr-24">remove_circle_outline</Icon>Remove Service Unit</MenuItem>
                                </Menu>
                            }
                            {[...units].map((value, index) => {
                                const unit = site && site.Data && site.Data.Units ? _.find(site.Data.Units, { UnitID: value.UnitID }) : null;
                                if (unit) {
                                    return (
                                        <CardHeader
                                            key={index}
                                            classes={{ action: "", root: classNames("p-4 py-12", index > 0 ? classes.borderTop : ''), avatar: "mr-8", title: "leading-none", subheader: "leading-none" }}
                                            avatar={
                                                <SPRoundIcon className="text-32" color="primary" />
                                            }
                                            title={<label className="text-14 font-bold text-grey-darkest">{unit.Name}</label>}
                                            subheader={<label className="text-10 text-grey">{unit.UnitID}</label>}
                                            action={
                                                <div className="flex mt-12 pr-4">
                                                    <Tooltip title="More Options" placement="top">
                                                        <Icon color="action" onClick={(e) => this.setState({ ...this.state, anchorEl: e.target, anchorData: unit })} className="text-24 cursor-pointer">more_vert</Icon>
                                                    </Tooltip>
                                                </div>
                                            }
                                        />
                                    );
                                }
                            })
                            }
                        </React.Fragment>
                    </div>
                    {!this.state.unit &&
                        <Typography
                            variant="subtitle2"
                            style={{ alignItems: 'center', borderTop: units && units.length > 0 ? '1px solid lightgrey' : "none" }}
                            className={classNames("text-12 flex cursor-pointer justify-start", units && units.length > 0 ? "pt-12 pb-8" : "pt-4 pb-8")}
                            onClick={
                                () => {
                                    this.setState({ ...this.state, unit: true, autoFocus: true });
                                }
                            }
                        >
                            <Icon color="primary" className="text-18 mr-4" style={{ marginBottom: 2 }}>add</Icon>
                            Add Unit
                        </Typography>
                    }
                    {this.state.unit &&
                        <div className={classNames("w-full relative pt-36")} style={{ borderTop: units.length > 0 ? "1px solid lightgrey" : undefined }}>
                            <React.Fragment>
                                {[...siteUnits].map((value, index) => {
                                    if (value.ActiveYN != "N") {
                                        return (
                                            <CardHeader
                                                key={index}
                                                classes={{ action: "", root: classNames("p-4 py-12", index > 0 ? classes.borderTop : ''), avatar: "mr-8", title: "leading-none", subheader: "leading-none" }}
                                                avatar={
                                                    <SPRoundIcon className="text-32" color="action" />
                                                }
                                                title={<label className="text-14 font-bold text-grey-darkest">{value.Name}</label>}
                                                subheader={<label className="text-10 text-grey">{value.UnitID}</label>}
                                                action={
                                                    <div className="flex mt-12 pr-4">
                                                        <Tooltip title="Assign Service Unit" placement="top">
                                                            <Icon onClick={() => this.addServiceUnit(value)} color="secondary" className="text-24 mr-12 cursor-pointer">add_circle_outline</Icon>
                                                        </Tooltip>
                                                        <Tooltip title="Edit Service Unit" placement="top">
                                                            <Icon color="action" onClick={(e) => this.props.openEditServiceUnitDialog(value)} className="text-24 cursor-pointer">edit</Icon>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            />
                                        );
                                    }
                                })
                                }
                            </React.Fragment>
                            {this.state.unit &&
                                <Button className="h-20 px-6 absolute pin-r pin-t text-12" style={{ zIndex: 999, marginRight: 0, marginTop: units.length > 0 ? 8 : undefined }} color="primary" variant="contained" onClick={() => this.setState({ ...this.state, unit: null, autoFocus: false }, this.props.onCancel)}>Done</Button>
                                // <Icon style={{ zIndex: 999, marginRight: 0, marginTop: units.length > 0 ? 8 : undefined }} color="action" className="text-24 absolute pin-r pin-t cursor-pointer">close</Icon>
                            }
                            <div className="w-full flex mb-12 relative" style={{ borderTop: units && units.length > 0 ? '1px solid lightgrey' : "none" }}>
                                <div className="w-full">
                                    <div className="w-full">

                                    </div>
                                    {!this.state.unit.ID &&
                                        <Button
                                            variant="contained"
                                            className={classNames("w-full")}
                                            color="primary"
                                            onClick={() => this.props.openNewServiceUnitDialog({ Co, Customer, Site })}
                                            disabled={!site}
                                        >
                                            <Icon className="mr-6">add_circle_outline</Icon>New Service Unit
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage,
        openNewServiceUnitDialog,
        openEditServiceUnitDialog
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitList));
