import _ from '@lodash';
import { AppBar, Chip, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Switch, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import classNames from 'classnames';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import userProfiles from '../../../../store/reducers/spConnection/user-profile.reducer';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newDispatchFolderState = {
    ID: null,
    Co: null,
    Folder: null,
    Name: null,
    Description: null,
    Options: null,
    Notes: '',
    SharedYN: null,
    AddedBy: null,
    AddedDate: new Date(),
    UpdatedBy: null,
    UpdatedDate: null,
    criteriaType: null,
    criteria: {
        businessUnits: [],
        divisions: [],
        departments: [],
        serviceTypes: [],
        services: [],
        priorities: [],
        arrivalWindows: [],
    },
    Data: {
        ErrMsg: null
    }
};

class DispatchFolderDialog extends Component {
    state = { ..._.cloneDeepWith(newDispatchFolderState) };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newDispatchFolderState.Co = this.props.Co;
        if (!prevProps.dispatchFolderDialog.props.open && this.props.dispatchFolderDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.dispatchFolderDialog.type === 'edit' &&
                this.props.dispatchFolderDialog.data &&
                !_.isEqual(this.props.dispatchFolderDialog.data, prevState)) {
                const criteria = JSON.parse(this.props.dispatchFolderDialog.data.Options);
                this.setState({ ..._.cloneDeepWith(newDispatchFolderState), ...this.props.dispatchFolderDialog.data, criteria });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.dispatchFolderDialog.type === 'new' &&
                !_.isEqual(newDispatchFolderState, prevState)) {
                this.setState({ ..._.cloneDeepWith(newDispatchFolderState) });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.dispatchFolderDialog.type === 'edit' ? this.props.closeEditDispatchFolderDialog() : this.props.closeNewDispatchFolderDialog();
    };

    canBeSubmitted() {
        const { Name, Description } = this.state;
        return (
            Name && Name.length > 0 && Description && Description.length > 0
        );
    }

    render() {
        const { classes, dispatchFolderDialog, addDispatchFolder, updateDispatchFolder, removeDispatchFolder, businessUnits, master, user, securables } = this.props;
        const { criteria, criteriaType } = this.state;
        const priorities = [{ Priority: 3, Description: 'Low' }, { Priority: 2, Description: 'Medium' }, { Priority: 1, Description: 'High' }];
        const accessLevel = _.find(securables, { Securable: 'custom-dispatch-folders' });
        const criteriaLists = {
            businessUnits: {
                key: "BusinessUnit",
                format: ["Description"],
                label: "Business Unit",
                listName: "Business Units",
                icon: 'business_center'
            },
            divisions: {
                key: "Division",
                format: ["Description"],
                label: "Division",
                listName: "Divisions",
                icon: 'location_city'
            },
            departments: {
                key: "Department",
                format: ["Description"],
                label: "Department",
                listName: "Departments",
                icon: 'business'
            },
            serviceTypes: {
                key: "ServiceType",
                format: ["Description"],
                label: "Service Type",
                listName: "Service Types",
                icon: 'settings'
            },
            services: {
                key: "Service",
                format: ["Service", " - ", "Description"],
                label: "Service",
                listName: "Services",
                icon: 'build'
            },
            priorities: {
                key: "Priority",
                format: ["Description"],
                label: "Priority",
                listName: "Priorities",
                icon: 'flag'
            },
            arrivalWindows: {
                key: "ArrivalWindow",
                format: ["Description"],
                label: "Arrival Window",
                listName: "Arrival Windows",
                icon: 'access_time'
            },
        }
        const criteriaList = criteriaLists[criteriaType];
        const data = { ...master, priorities };

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...dispatchFolderDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {dispatchFolderDialog.type === 'new' ? 'New Dispatch Folder' : `Edit Dispatch Folder #${this.state.Folder}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Name"
                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    required
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Criteria Type"
                                    id="criteriaType"
                                    name="criteriaType"
                                    value={this.state.criteriaType || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    required
                                >
                                    <MenuItem value={null}>
                                        None
                                    </MenuItem>
                                    <MenuItem value="businessUnits">
                                        Business Units
                                    </MenuItem>
                                    <MenuItem value="divisions">
                                        Divisions
                                    </MenuItem>
                                    <MenuItem value="departments">
                                        Departments
                                    </MenuItem>
                                    <MenuItem value="serviceTypes">
                                        Service Types
                                    </MenuItem>
                                    <MenuItem value="services">
                                        Services
                                    </MenuItem>
                                    <MenuItem value="priorities">
                                        Priorities
                                    </MenuItem>
                                    <MenuItem value="arrivalWindows">
                                        Arrival Windows
                                    </MenuItem>
                                </TextField>
                            </div>
                            {criteriaList &&
                                <div className="w-full">
                                    <Autocomplete
                                        className={"mb-8"}
                                        title={criteriaList.label}
                                        options={_.filter(data[criteriaType], (o) => criteria[criteriaType].indexOf(o[criteriaList.key]) < 0)}
                                        menuItemComponent={(value, index) => {
                                            return <MenuItem key={index} value={value[criteriaList.key]}>
                                                {criteriaList.format.map(k => value[k] ? value[k] : k)}
                                            </MenuItem>
                                        }}
                                        portal={true}
                                        value={this.state[criteriaList.key]}
                                        onSelect={(option) => this.setState(_.set({ ...this.state }, criteriaList.key, option[criteriaList.key]))}
                                    />
                                    <Button
                                        className={classNames(classes.formControl, "w-full")}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            criteria[criteriaType].push(this.state[criteriaList.key]);
                                            this.setState(_.set({ ...this.state, criteria }, criteriaList.key, null));
                                        }}
                                        disabled={!this.state[criteriaList.key]}
                                    >
                                        Add {criteriaList.label}
                                    </Button>
                                </div>
                            }
                            {criteria && _.filter(criteria, (o) => o.length > 0).length > 0 &&
                                <div className="w-full">
                                    <div className="">
                                        <label className="text-12 p-4 px-6 ml-8" style={{ background: '#fff', color: '#9f9f9f' }}>Criteria</label>
                                        <div className="rounded-4 p-12 mb-12 min-h-256" style={{ border: '1px solid rgba(0, 0, 0, .25)', marginTop: -11 }}>
                                            {Object.keys(criteria).map((key, index) => {
                                                const value = criteria[key];
                                                if (value.length > 0) {
                                                    const list = criteriaLists[key];
                                                    return (
                                                        <div className="w-full">
                                                            <Typography variant="h6" className="mb-8 text-12 font-bold w-full" style={{ borderBottom: '1px solid lightgrey' }}><Icon className="mr-4 text-16 align-middle" style={{ marginBottom: 6 }}>{list.icon}</Icon>{list.listName}</Typography>
                                                            <div className="flex-wrap">
                                                                {value.map((entry, index) => {
                                                                    const obj = _.find(data[key], (o) => o[list.key] === entry);
                                                                    return <Chip classes={{ deleteIcon: "h-20" }} className="mb-8 mr-4 h-24" color="primary" onDelete={() => {
                                                                        criteria[key].splice(value.indexOf(entry), 1);
                                                                        this.setState({ ...this.state, criteria });
                                                                    }} label={list.format.map(k => obj[k] ? obj[k] : k)} />
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div> */}
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.SharedYN === 'Y'}
                                                name="SharedYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Shared?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== 'R' &&
                            <DialogActions className="dialog-actions justify-between">
                                {dispatchFolderDialog.type === 'new' ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const { Co } = this.props;
                                            addDispatchFolder({ ...this.state, Co, Options: JSON.stringify(criteria) });
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button> :
                                    (accessLevel.AccessLevel === 'F' || this.state.AddedBy === user.UserName) &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateDispatchFolder({ ...this.state, Options: JSON.stringify(criteria) });
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                }
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditDispatchFolderDialog: Actions.closeEditDispatchFolderDialog,
        closeNewDispatchFolderDialog: Actions.closeNewDispatchFolderDialog,
        addDispatchFolder: Actions.addDispatchFolder,
        updateDispatchFolder: Actions.updateDispatchFolder,
        // removeDispatchFolder: Actions.removeDispatchFolder
    }, dispatch);
}

function mapStateToProps({ dispatchBoardApp, spReducers }) {
    const {
        businessUnits,
        divisions,
        departments,
        serviceTypes,
        services,
        arrivalWindows,
        teams,
        technicians
    } = spReducers;
    return {
        dispatchFolderDialog: dispatchBoardApp.dispatchBoard.dispatchFolderDialog,
        user: spReducers.userProfiles.User,
        businessUnits,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        master: {
            businessUnits,
            divisions,
            departments,
            serviceTypes,
            services,
            arrivalWindows,
            teams,
            technicians
        },
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(DispatchFolderDialog));
