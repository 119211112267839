import { FusePageCarded } from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SupervisorHeader from 'main/content/apps/supervisor/SupervisorHeader';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import SupervisorProfile from './SupervisorProfile';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    },
    header: {
        minHeight: 48,
        height: 48
    },
});

class CustomersApp extends Component {
    state = {
        weekly: true
    }

    componentDidMount() {
        // this.props.getUserData();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (!_.isEqual(this.props.location, prevProps.location)) {
    //         this.props.getSupervisors(this.props.match.params);
    //     }
    // }

    render() {
        const { classes, openNewSupervisorDialog, trips, allTrips, timecard, supervisor } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-8 sm:p-12 pb-0",
                                header: classNames("items-center", classes.header)
                            }}
                            header={
                                <SupervisorHeader handleDaily={() => this.setState({ weekly: false })} weekly={this.state.weekly} handleWeekly={() => this.setState({ weekly: true })} />
                            }
                            content={
                                <SupervisorProfile weekly={this.state.weekly} trips={[...trips]} allTrips={[...allTrips]} timecard={{ ...timecard }} supervisor={{ ...supervisor }} />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ supervisorApp, spReducers }) {
    return {
        trips: spReducers.technician.trips,
        allTrips: spReducers.technician.allTrips,
        timecard: spReducers.technician.timecard,
        user: supervisorApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
