import axios from 'axios/index';
import * as Login from 'auth/store/actions';
import * as Actions from 'store/actions';
import { startSignalR, setOnlineStatus, initNotifications, setLockType } from 'store/actions';
import { initiateSip } from 'main/dialer/store/actions/dialer.actions';
import Constants from "../../../Constants";

axios.defaults.timeout = 60000;

export const AUTHENTICATE_SP = '[SP AUTHENTICATE] AUTHENTICATE SP';
export const REFRESH_SP_TOKEN = '[SP AUTHENTICATE] REFRESH SP TOKEN';
export const REMOVE_SP_TOKEN = '[SP AUTHENTICATE] REMOVE SP TOKEN';
export const SET_COMPANY = '[SP AUTHENTICATE] SET COMPANY';

export function logIn(unm, pass, version, os) {
    return (dispatch, getState) => {
        const state = getState();
        const { spAuth } = state;
        const { api } = spAuth;
        const { client, location } = api;
        if (location) {
            window["apiLocation"] = location;
            if (process.env.NODE_ENV === 'development') {
                window[Constants.SUBDOMAIN_HEADER_KEY] = client;
                axios.defaults.headers.common[Constants.SUBDOMAIN_HEADER_KEY] = client;
            }
            var data = `grant_type=password&username=${encodeURIComponent(unm)}&password=${encodeURIComponent(pass)}&version=${version}&os=${encodeURIComponent(os)}`;
            var obj = {
                url: `${location}/token`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            const request = axios(obj);
            dispatch({
                type: Actions.SHOW_MESSAGE,
                options: {
                    message: `Logging in to Service Point Pro...`,
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'info'
                }
            });
            request.then((result) => {
                if (result.data && result.data.access_token && result.data.expires_in && result.data.username) {
                    const { vault } = spAuth;
                    if (vault.vault && vault.lockType === 'Biometrics') {
                        vault.vault.setValue('api', client)
                        vault.vault.setValue('unm', unm);
                        vault.vault.setValue('c2', window.btoa(pass));
                        dispatch(Actions.lockVault());
                    }
                    setTimeout(() => { dispatch(refreshToken(true, version, os)); }, result.data.expires_in * 1000);
                    var dt = new Date();
                    dt.setSeconds(dt.getSeconds() + result.data.expires_in);
                    result.data.expires_at = dt.toLocaleString('en-US');
                    const token = result.data.access_token;
                    axios.defaults.withCredentials = true;
                    axios.defaults.headers.common['Authorization'] = token;
                    Promise.all([
                        dispatch({
                            type: Actions.SHOW_MESSAGE,
                            options: {
                                message: `Login Successful - Initializing Service Point Pro...`,
                                autoHideDuration: null,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                },
                                variant: 'success'
                            }
                        }),
                        dispatch({
                            type: AUTHENTICATE_SP,
                            data: result.data
                        }),
                        dispatch(initNotifications()),
                    ]).then(() => {
                        dispatch(Actions.getUserProfileData(result.data.username, true));
                        Promise.all([
                            dispatch({
                                type: Login.LOGIN_SUCCESS
                            }),
                        ]).then(() => {
                            dispatch(setOnlineStatus());
                        });
                    });
                } else {
                    dispatch(setLockType('NoLocking'));
                }
            }).catch((error) => {
                dispatch(setLockType('NoLocking'));
                dispatch({
                    type: Actions.SHOW_MESSAGE,
                    options: {
                        message: `Unable to Log in to Service Point Pro`,
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        variant: 'error',
                    }
                });
            });
        } else {
            dispatch(setLockType('NoLocking'));
        }
    }

}

export function refreshToken(bool, version, os) {
    return (dispatch, getState) => {
        const state = getState();
        const { spAuth, spReducers } = state;
        const { authentication, api } = spAuth;
        const { client, location } = api;
        if (location) {
            window["apiLocation"] = location;
            if (process.env.NODE_ENV === 'development') {
                window[Constants.SUBDOMAIN_HEADER_KEY] = client;
                axios.defaults.headers.common[Constants.SUBDOMAIN_HEADER_KEY] = client;
            }
            const { User } = spReducers.userProfiles;
            var unm = User.UserName;
            var data = `grant_type=refresh_token&username=${encodeURIComponent(unm)}&refresh_token=${encodeURIComponent(authentication.refresh_token)}&version=${version}&os=${encodeURIComponent(os)}`;
            var obj = {
                url: `${location}/token`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            const request = axios(obj);
            request.then((result) => {
                if (result && result.data && result.data.access_token) {
                    if (result.data.expires_in) {
                        setTimeout(() => { dispatch(refreshToken(true, version, os)); }, (result.data.expires_in * 1000) - 10000);
                    }
                    var dt = new Date();
                    dt.setSeconds(dt.getSeconds() + result.data.expires_in);
                    result.data.expires_at = dt.toLocaleString('en-US');
                    const token = result.data.access_token;
                    axios.defaults.withCredentials = true;
                    axios.defaults.headers.common['Authorization'] = token;
                    Promise.all([
                        dispatch({
                            type: REFRESH_SP_TOKEN,
                            data: result.data
                        })
                    ]).then(() => {
                        if (!bool) {
                            dispatch(Actions.getUserProfileData(unm, true));
                        } else {
                            dispatch(Actions.initGPS())
                        }
                        Promise.all([
                            dispatch({
                                type: Login.LOGIN_SUCCESS
                            }),
                            dispatch(initNotifications()),
                        ]).then(() => {
                            dispatch(setOnlineStatus());
                        });
                    })
                } else {
                    dispatch({
                        type: REMOVE_SP_TOKEN
                    });
                    dispatch({
                        type: Login.LOGOUT
                    });
                }
            }).catch(function (error) {
                window["warn"](error);
                if (error.request) {
                    window["warn"](error.request);
                    if (error.request.status === 400) {
                        dispatch({
                            type: REMOVE_SP_TOKEN
                        });
                        dispatch({
                            type: Login.LOGOUT
                        });
                    } else {
                        if (authentication) {
                            if (authentication.access_token) {
                                const token = authentication.access_token;
                                axios.defaults.withCredentials = true;
                                axios.defaults.headers.common['Authorization'] = token;
                            }
                        }
                    }
                }
            });
        }
    }
}