import axios from 'axios';
import { getUserData } from 'main/content/apps/pay-terms/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_PAY_TERMS = '[PAY TERMS APP] GET PAY TERMS';
export const SET_SEARCH_TEXT = '[PAY TERMS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_PAY_TERMS = '[PAY TERMS APP] TOGGLE IN SELECTED PAY TERMS';
export const SELECT_ALL_PAY_TERMS = '[PAY TERMS APP] SELECT ALL PAY TERMS';
export const DESELECT_ALL_PAY_TERMS = '[PAY TERMS APP] DESELECT ALL PAY TERMS';
export const OPEN_NEW_PAY_TERM_DIALOG = '[PAY TERMS APP] OPEN NEW PAY TERM DIALOG';
export const CLOSE_NEW_PAY_TERM_DIALOG = '[PAY TERMS APP] CLOSE NEW PAY TERM DIALOG';
export const OPEN_EDIT_PAY_TERM_DIALOG = '[PAY TERMS APP] OPEN EDIT PAY TERM DIALOG';
export const CLOSE_EDIT_PAY_TERM_DIALOG = '[PAY TERMS APP] CLOSE EDIT PAY TERM DIALOG';
export const ADD_PAY_TERM = '[PAY TERMS APP] ADD PAY TERM';
export const UPDATE_PAY_TERM = '[PAY TERMS APP] UPDATE PAY TERM';
export const REMOVE_PAY_TERM = '[PAY TERMS APP] REMOVE PAY TERM';
export const REMOVE_PAY_TERMS = '[PAY TERMS APP] REMOVE PAY TERMS';
export const TOGGLE_STARRED_PAY_TERM = '[PAY TERMS APP] TOGGLE STARRED PAY TERM';
export const TOGGLE_STARRED_PAY_TERMS = '[PAY TERMS APP] TOGGLE STARRED PAY TERMS';
export const SET_PAY_TERMS_STARRED = '[PAY TERMS APP] SET PAY TERMS STARRED ';

export function getPayTerms(routeParams) {
    const request = axios.get('/api/payTerms-app/payTerms', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_PAY_TERMS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedPayTerms(payTermId) {
    return {
        type: TOGGLE_IN_SELECTED_PAY_TERMS,
        payTermId
    }
}


export function selectAllPayTerms() {
    return {
        type: SELECT_ALL_PAY_TERMS
    }
}

export function deSelectAllPayTerms() {
    return {
        type: DESELECT_ALL_PAY_TERMS
    }
}


export function openNewPayTermDialog() {
    return {
        type: OPEN_NEW_PAY_TERM_DIALOG
    }
}

export function closeNewPayTermDialog() {
    return {
        type: CLOSE_NEW_PAY_TERM_DIALOG
    }
}

export function openEditPayTermDialog(data) {
    return {
        type: OPEN_EDIT_PAY_TERM_DIALOG,
        data
    }
}

export function closeEditPayTermDialog() {
    return {
        type: CLOSE_EDIT_PAY_TERM_DIALOG
    }
}

export function addPayTerm(newPayTerm) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/PayTerm`,
            newPayTerm
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_PAY_TERM
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Pay Terms ${newPayTerm.PayTerms} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updatePayTerm(payTerm) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/PayTerm?Co=${encodeURIComponent(payTerm.Co)}&PayTerm=${encodeURIComponent(payTerm.PayTerms)}`,
            payTerm
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_PAY_TERM
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Pay Terms ${payTerm.PayTerms} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removePayTerm(payTermId) {
    return (dispatch, getState) => {

        const request = axios.post('/api/payTerms-app/remove-payTerm', {
            payTermId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_PAY_TERM
                })
            ])
        );
    };
}

export function toggleStarredPayTerm(payTermId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().payTermsApp.payTerms;

        const request = axios.post('/api/payTerms-app/toggle-starred-payTerm', {
            payTermId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_PAY_TERM
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPayTerms(routeParams)))
        );
    };
}

export function toggleStarredPayTerms(payTermIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().payTermsApp.payTerms;

        const request = axios.post('/api/payTerms-app/toggle-starred-payTerms', {
            payTermIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_PAY_TERMS
                }),
                dispatch({
                    type: DESELECT_ALL_PAY_TERMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPayTerms(routeParams)))
        );
    };
}

export function setPayTermsStarred(payTermIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().payTermsApp.payTerms;

        const request = axios.post('/api/payTerms-app/set-payTerms-starred', {
            payTermIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_PAY_TERMS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_PAY_TERMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPayTerms(routeParams)))
        );
    };
}

export function setPayTermsUnstarred(payTermIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().payTermsApp.payTerms;

        const request = axios.post('/api/payTerms-app/set-payTerms-unstarred', {
            payTermIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_PAY_TERMS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_PAY_TERMS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPayTerms(routeParams)))
        );
    };
}
