import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import TeamsList from 'main/content/apps/teams/TeamsList';
import TeamsHeader from 'main/content/apps/teams/TeamsHeader';
import TeamsLeftSidebarHeader from 'main/content/apps/teams/TeamsLeftSidebarHeader';
import TeamsRightSidebarHeader from 'main/content/apps/teams/TeamsRightSidebarHeader';
import TeamsSidebarContent from 'main/content/apps/teams/TeamsSidebarContent';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class CustomersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getTeams(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewTeamDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <TeamsHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <TeamsLeftSidebarHeader />
                            }
                            content={
                                <TeamsList />

                            }
                            leftSidebarContent={
                                <TeamsSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTeams: Actions.getTeams,
        getUserData: Actions.getUserData,
        openNewTeamDialog: Actions.openNewTeamDialog
    }, dispatch);
}

function mapStateToProps({ teamsApp }) {
    return {
        teams: teamsApp.teams.entities,
        selectedTeamIds: teamsApp.teams.selectedTeamIds,
        searchText: teamsApp.teams.searchText,
        user: teamsApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
