import React, { Component } from 'react';
import { Icon, IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import ToolbarMenu from 'main/content/apps/scrumboard/board/dialogs/card/toolbar/ToolbarMenu';
import moment from 'moment';
import { DatePicker } from "@material-ui/pickers";

class DueMenu extends Component {
    state = {
        anchorEl: null,
        isDatepickerOpen: false,
    };

    renderDateLabel = (e) => {
        window["log"](e);
        return (
            <IconButton color="inherit" onClick={(e) => { this.setDatepickerOpen(true); }}>
                <Icon>today</Icon>
            </IconButton>
        )
    };

    setDatepickerOpen = (bool) => {
        this.setState({ isDatepickerOpen: bool });
    }

    render() {
        const { due, onRemoveDue, onDueChange } = this.props;

        return (
            <DatePicker
                inputVariant="outlined"
                onOpen={() => this.setDatepickerOpen(true)}
                onClose={() => this.setDatepickerOpen(false)}
                open={this.state.isDatepickerOpen}
                label="Due By"
                type="date"
                name="DueBy"
                value={due}
                onChange={(ev) => {
                    if (ev) {
                        ev.target = {
                            type: 'input',
                            name: "DueBy",
                            value: ev._d ? ev._d : null,
                        }
                        window["warn"](ev);
                        onDueChange(ev);
                    } else {
                        onRemoveDue();
                    }
                }}
                clearable={true}
                autoOk={true}
                placeholder=" Choose a due date"
                InputLabelProps={{
                    shrink: true
                }}
                format="MM/DD/YYYY"
                ref="datePicker"
                TextFieldComponent={this.renderDateLabel}
            />
        );
    };
}

export default DueMenu;
