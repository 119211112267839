import axios from 'axios/index';

export const SET_INVENTORY_LOCATION_GROUP_DATA = '[SPCONNECTION] SET INVENTORY LOCATION GROUP DATA';
export const GET_INVENTORY_LOCATION_GROUP_DATA = '[SPCONNECTION] GET INVENTORY LOCATION GROUP DATA';
export const SUBSCRIBE_INVENTORY_LOCATION_GROUP_LIST = '[SPCONNECTION] SUBSCRIBE INVENTORY LOCATION GROUP LIST';

export function getInitialInventoryLocationGroupData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryLocationGroup`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getInventoryLocationGroupData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryLocationGroup`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_INVENTORY_LOCATION_GROUP_DATA,
                payload: response.data
            })
        });
    }
}

export function setInventoryLocationGroupData(data) {
    return {
        type: SET_INVENTORY_LOCATION_GROUP_DATA,
        payload: data
    };
}