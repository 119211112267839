import AgreementTypeTasksApp from 'main/content/apps/agreement-type-tasks/AgreementTypeTasksApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const AgreementTypeTasksAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/agreement-type-tasks/:id',
            component: AgreementTypeTasksApp
        },
        {
            path     : '/apps/agreement-type-tasks',
            component: () => <Redirect to="/apps/agreement-type-tasks/all"/>
        }
    ]
};
