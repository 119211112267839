import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { Badge, Divider, Icon, Menu, MenuItem, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateUser } from '../users/store/actions';
import CallHandlerCallCard from './CallHandlerCallCard';
import CallManagerDialog from './CallManagerDialog';
import * as Actions from './store/actions';
import { getInitialCallHandlerData, showMessage } from 'store/actions';
import { openPreloadIncomingCallDialog } from '../incoming-call/store/actions';

const styles = theme => ({
    root: {

    },
    badge: {
        top: 6,
        right: 4,
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    }
});

class CallHandlerRightSidebarContent extends Component {
    state = {
        calls: [],
        user: {
            Data: {
                Queues: [],
            }
        },
        dialogOpen: false
    };

    componentDidMount() {
        const { calls, user } = this.props;
        this.setState({ ...this.state, calls, user });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calls, user } = this.props;
        if (!_.isEqual(this.props.calls, prevProps.calls) || !_.isEqual(this.props.user, prevProps.user)) {
            this.setState({ ...this.state, calls, user });
        }
    };

    closeDialog = () => {
        this.setState({ ...this.state, dialogOpen: false });
    }

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null });
    }

    toggleRingtone = () => {
        const { user } = this.props;
        this.setState({ ...this.state, user: { ...user, DisableRingtoneYN: user.DisableRingtoneYN === 'Y' ? 'N' : 'Y' } }, () => this.props.updateUser({ ...this.state.user, Data: { ErrMsg: null } }, true));
    }

    refreshCallList = () => {
        const { Co } = this.props;
        this.props.showMessage({
            message: `Refreshing Call List`,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'refresh'
        })
        Promise.all([
            this.props.getInitialCallHandlerData(Co)
        ]).then(() =>
            this.props.showMessage({
                message: `Call List Refreshed`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            })
        ).catch(() => this.props.showMessage({
            message: `Error Refreshing Call List`,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'error'
        }))
    }

    openIncomingCallDialog = () => {
        this.props.pageLayout().toggleRightSidebar()
        this.setState({...this.state, anchorEl: false}, this.props.openPreloadIncomingCallDialog())
    }

    render() {
        const { classes, openCallDraft, callID, username, setCall, call, pageLayout, Co } = this.props;
        const { anchorEl, user } = this.state;

        const { Queues } = user.Data;

        const calls = _.filter(this.state.calls, (o) => { return _.find(Queues, (q) => { return q.Queue == o.Queue && q.TextEnabledYN === (o.Type === "text" ? "Y" : q.TextEnabledYN) }) })
        return (
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <div className="p-12" onClick={(e) => e.stopPropagation()}>

                    <Menu id="call-handler-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                        <MenuItem onClick={() => this.setState({ ...this.state, dialogOpen: true, anchorEl: null })}><Icon className="mr-24">phone</Icon>View Call Manager</MenuItem>
                        <MenuItem onClick={() => this.setState({ ...this.state, anchorEl: null }, this.refreshCallList)}><Icon className="mr-24">refresh</Icon>Refresh List</MenuItem>
                        {/* <MenuItem onClick={this.openIncomingCallDialog}><Icon className="mr-24">ring_volume</Icon>Incoming Contacts</MenuItem> */}
                        <MenuItem>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={user.DisableRingtoneYN !== 'Y'}
                                        onChange={this.toggleRingtone}
                                        color="primary"
                                    />
                                }
                                label="Enable Ringtone"
                            />
                        </MenuItem>
                    </Menu>

                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex relative">{calls.length > 0 ? <Badge color="error" classes={{ badge: classes.badge, }} className="mr-12" badgeContent={calls.length}><Icon color="secondary" className="text-32">phone</Icon></Badge> : <Icon color="secondary" className="text-32 mr-12">phone</Icon>}Incoming Contacts<Icon className="pin-r absolute cursor-pointer" onClick={(e) => this.setState({ ...this.state, anchorEl: e.target })}>more_vert</Icon></Typography>
                    <CallManagerDialog open={this.state.dialogOpen} onClose={this.closeDialog} />
                    <Divider />
                    <FuseAnimateGroup enter={{ animation: "transition.slideUpBigIn" }}>
                        {
                            calls.map((value, index) => {
                                // if (value.HandledBy === user.UserName && value.Sid !== call.CallSid) {
                                //     setCall(value);
                                // };
                                return (
                                    (!value.HandledBy || value.HandledBy === user.UserName) ?
                                        <CallHandlerCallCard key={value.Sid} pageLayout={pageLayout} call={value} id={value.Sid} handler={value.HandledBy} />
                                        : ''
                                )
                            })
                        }
                    </FuseAnimateGroup>
                </div>
            </FuseAnimate>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCallDraft: Actions.openCallDraft,
        setCall: Actions.setCall,
        updateUser,
        getInitialCallHandlerData,
        showMessage,
        openPreloadIncomingCallDialog,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, contactsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        callHandler: callHandlerApp.callHandler,
        callID: callHandlerApp.callHandler.id,
        calls: spReducers.callHandler.Calls,
        user: spReducers.userProfiles.User,
        call: callHandlerApp.callHandler.call,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallHandlerRightSidebarContent)));
