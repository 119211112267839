import _ from '@lodash';
import { Avatar, Button, CardHeader, Dialog, DialogContent, Grow, Slide, AppBar, Toolbar, IconButton, FormControlLabel, Icon, Link, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Media from 'react-media';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import MailCompose from 'main/content/apps/mail/MailCompose';
import ContactList from '../call-handler/ContactList';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    green: {
        backgroundColor: '#d1e751',
        color: '#3f3f3f'
    },
    blue: {
        backgroundColor: '#4dbce9',
        color: '#fff'
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    borderTop: {
        borderTop: '1px solid lightgrey'
    },
    avatar: {
        width: 28,
        height: 28
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    ok: {
        background: '#333',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        },
        '&:focus': {
            backgroundColor: '#5f5f5f',
        },
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    greenText: {
        color: '#d1e751',
    },
});
const initialState = {
    details: [],
    exclusion: null,
    contact: null,
    customer: null,
    site: null,
    autoFocus: false,
    openDialog: null
};

class WorkOrdersContactList extends Component {
    state = { ..._.cloneDeepWith(initialState) };

    componentDidMount() {
        const { details, customer, site, contact, } = this.props;
        this.setState({ ...this.state, details, customer, site, contact });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { details, customer, site, contact } = this.props;
        if (!_.isEqual(prevProps.details, details) || !_.isEqual(prevProps.customer, customer) || !_.isEqual(prevProps.site, site) || !_.isEqual(prevProps.contact, contact)) {
            this.setState({ ...this.state, details, customer, site, contact });
        }

        if (prevProps.open !== this.props.open) {
            this.setState({ openDialog: this.props.open })
        }

    }

    closeComposeDialog = () => {
        this.props.setStateFxn({ openWoContactList: false })
    };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    formatPhone = (str) => {
        const phone = str.replace(/\(|\)|,|\.|-/g, '');
        return phone.length === 10 ? `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}` : str;
    }

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null, anchorData: null });
    }


    addContact = () => {

        const { contact, details, customer, site } = this.state;

        const request = axios.post(`${window["apiLocation"]}/api/Contact`,
            contact
        );

        request.then((response) => {
            if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
                this.setState({ autoFocus: false });
            } else {
                if (site) {
                    this.addSiteContact(response.data);
                } else if (customer) {
                    this.addCustomerContact(response.data);
                }
            }
        });
    }

    updateContact = () => {

        const { contact, details, customer, site } = this.state;

        const request = axios.put(`${window["apiLocation"]}/api/Contact?Contact=${encodeURIComponent(contact.Contact)}`,
            {
                ...contact,
                Data: {
                    ErrMsg: null
                }
            }
        );

        request.then((response) => {
            if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
                this.setState({ autoFocus: false });
            } else {
                const Contacts = _.cloneDeepWith(details);
                const current = _.find(Contacts, (o) => o.Data.Contact.ID === this.state.contact.ID);
                if (current) {
                    current.Data.Contact = this.state.contact;
                }
                this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                    this.props.onUpdate(Contacts);
                    this.props.showMessage({
                        message: `Contact #${response.data.Contact} was successfully updated`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        });
    }

    removeContact = () => {

        const { contact, details, customer, site } = this.state;
        const { Contact } = contact;
        const { Co, Customer, Site } = site || customer;

        if (Contact) {
            if (site) {
                this.removeSiteContact({ Co, Customer, Site, Contact });
            } else if (customer) {
                this.removeCustomerContact({ Co, Customer, Contact });
            }
        }

    }


    addCustomerContact = (newContact) => {

        const { details, customer, site } = this.state;

        const { Co, Customer, Site } = customer;
        const { Contact } = newContact;
        const contact = {
            Co,
            Customer,
            Contact,
            ActiveYN: 'Y',
            PrimaryYN: details.length > 0 ? 'N' : 'Y',
            BillingYN: details.length > 0 ? 'N' : 'Y',
            ApprovalRequiredYN: null,
            Data: {
                ErrMsg: null,
                Contact: newContact
            }
        }

        if (Customer) {
            const request = axios.post(`${window["apiLocation"]}/api/CustomerContact`,
                contact
            );

            request.then((response) => {
                if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                    this.props.showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    });
                    this.setState({ autoFocus: false });
                } else {
                    const Contacts = _.cloneDeepWith(details);
                    Contacts.push({
                        ...response.data,
                        Data: {
                            Contact: newContact
                        }
                    })
                    this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                        this.props.onUpdate(Contacts);
                        this.props.showMessage({
                            message: `Successfully added Contact #${response.data.Contact} to Customer #${response.data.Customer}`,
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        });
                    });
                }
            });
        } else {
            const Contacts = _.cloneDeepWith(details);
            Contacts.push({
                ...contact,
                Data: {
                    Contact: newContact
                }
            })
            this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                this.props.onUpdate(Contacts);
            });
        }
    }

    updateCustomerContact = (contact) => {

        const { details, customer, site } = this.state;

        const { Co, Customer, Contact } = contact;

        if (Co && Customer && Contact) {

            const request = axios.put(`${window["apiLocation"]}/api/CustomerContact?Co=${contact.Co}&Customer=${contact.Customer}&Contact=${encodeURIComponent(contact.Contact)}`,
                {
                    ...contact,
                    Data: {
                        ...contact.Data,
                        ErrMsg: null,
                    },
                }
            );

            request.then((response) => {
                const Contacts = _.cloneDeepWith(details);
                const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
                if (index > -1) {
                    Contacts.splice(index, 1, response.data);
                }
                this.setState({ ...this.state, contact: null, details: Contacts }, () => {
                    this.props.onUpdate(Contacts);
                    this.props.showMessage({
                        message: `Contact #${response.data.Contact} was successfully updated`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            });
        } else {
            const Contacts = _.cloneDeepWith(details);
            const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
            if (index > -1) {
                Contacts.splice(index, 1, contact);
            }
            this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                this.props.onUpdate(Contacts);
            });
        }
    }

    removeCustomerContact = (contact) => {

        const { details, customer, site } = this.state;

        const { Co, Customer, Contact } = contact;

        if (Co && Customer && Contact) {

            const request = axios.delete(`${window["apiLocation"]}/api/CustomerContact?Co=${contact.Co}&Customer=${contact.Customer}&Contact=${encodeURIComponent(contact.Contact)}`);

            request.then((response) => {
                const Contacts = _.cloneDeepWith(details);
                const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
                if (index > -1) {
                    Contacts.splice(index, 1);
                }
                this.setState({ ...this.state, contact: null, details: Contacts }, () => {
                    this.props.onUpdate(Contacts);
                    this.props.showMessage({
                        message: `Contact #${contact.Contact} was successfully removed from Customer #${contact.Customer}`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            });
        } else {
            const Contacts = _.cloneDeepWith(details);
            const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
            if (index > -1) {
                Contacts.splice(index, 1, contact);
            }
            this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                this.props.onUpdate(Contacts);
            });
        }
    }


    addSiteContact = (newContact) => {

        const { details, customer, site } = this.state;

        const { Co, Customer, Site } = site;
        const { Contact } = newContact;
        const contact = {
            Co,
            Customer,
            Site,
            Contact,
            ActiveYN: 'Y',
            PrimaryYN: details.length > 0 ? 'N' : 'Y',
            ApprovalRequiredYN: null,
            Data: {
                ErrMsg: null,
                Contact: newContact
            }
        }

        if (Customer && Site) {
            const request = axios.post(`${window["apiLocation"]}/api/CustomerSiteContact`,
                contact
            );

            request.then((response) => {
                if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
                    this.props.showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    });
                    this.setState({ autoFocus: false });
                } else {
                    const Contacts = _.cloneDeepWith(details);
                    Contacts.push({
                        ...response.data,
                        Data: {
                            Contact: newContact
                        }
                    })
                    this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                        this.props.onUpdate(Contacts);
                        this.props.showMessage({
                            message: `Successfully added Contact #${response.data.Contact} to Customer #${response.data.Customer} Site #${response.data.Site}`,
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        });
                    });
                }
            });
        } else {
            const Contacts = _.cloneDeepWith(details);
            Contacts.push({
                ...contact,
                Data: {
                    Contact: newContact
                }
            })
            this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                this.props.onUpdate(Contacts);
            });
        }
    }

    updateSiteContact = (contact) => {

        const { details, customer, site } = this.state;

        const { Co, Customer, Site, Contact } = contact;

        if (Co && Customer && Site && Contact) {

            const request = axios.put(`${window["apiLocation"]}/api/CustomerSiteContact?Co=${contact.Co}&Customer=${contact.Customer}&Site=${encodeURIComponent(contact.Site)}&Contact=${encodeURIComponent(contact.Contact)}`,
                {
                    ...contact,
                    Data: {
                        ...contact.Data,
                        ErrMsg: null,
                    },
                }
            );

            request.then((response) => {
                const Contacts = _.cloneDeepWith(details);
                const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
                if (index > -1) {
                    Contacts.splice(index, 1, response.data);
                }
                this.setState({ ...this.state, contact: null, details: Contacts }, () => {
                    this.props.onUpdate(Contacts);
                    this.props.showMessage({
                        message: `Successfully updated Contact #${response.data.Contact}`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            });
        } else {
            const Contacts = _.cloneDeepWith(details);
            const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
            if (index > -1) {
                Contacts.splice(index, 1, contact);
            }
            this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                this.props.onUpdate(Contacts);
            });
        }
    }

    removeSiteContact = (contact) => {

        const { details, customer, site } = this.state;

        const { Co, Customer, Site, Contact } = contact;

        if (Co && Customer && Site && Contact) {

            const request = axios.delete(`${window["apiLocation"]}/api/CustomerSiteContact?Co=${contact.Co}&Customer=${contact.Customer}&Site=${encodeURIComponent(contact.Site)}&Contact=${encodeURIComponent(contact.Contact)}`);

            request.then((response) => {
                const Contacts = _.cloneDeepWith(details);
                const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
                if (index > -1) {
                    Contacts.splice(index, 1);
                }
                this.setState({ ...this.state, contact: null, details: Contacts }, () => {
                    this.props.onUpdate(Contacts);
                    this.props.showMessage({
                        message: `Contact #${contact.Contact} was successfully removed from Customer #${contact.Customer} Site #${contact.Site}`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            });
        } else {
            const Contacts = _.cloneDeepWith(details);
            const index = _.findIndex(Contacts, (o) => o.Contact === contact.Contact);
            if (index > -1) {
                Contacts.splice(index, 1, contact);
            }
            this.setState({ ...this.state, contact: null, details: Contacts, autoFocus: false }, () => {
                this.props.onUpdate(Contacts);
            });
        }
    }

    formatPhoneLink(str) {
        const phone = str.replace(/\(|\)|,|\.|-/g, '');
        return phone.length === 10 ? `+1-${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6, 4)}` : str;
    }

    emailContact = (email) => {

        const { site, customer } = this.state;
        const Attachments = [];

        const data = {
            composeDialog: true,
            Title: `Email ${site ? "Site" : "Customer"} Contact`,
            Icon: "email",
            To: `${email};`,
            Subject: ``,
            Head: '',
            Message: '',
            Body: '',
            Attachments
        }
        this.setState({ ...this.state, contactEmailData: { ...data } }, this.closeMenu);
        return data;
    }

    render() {
        const { classes, estimate, parent, editable, billing, label, onCancel } = this.props;
        let { details, anchorEl, anchorData, contactEmailData, autoFocus } = this.state;
        // window["warn"]("WO Contacts: ", details)
        // window["warn"]("WO Contact Open: ", Boolean(this.props.open))

        if (details) {
            details = _.filter([...details], (o) => { return o.DeleteYN !== 'Y' });
        }
        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <React.Fragment>
                        {
                            contactEmailData &&
                            <MailCompose onClose={() => this.setState({ ...this.state, contactEmailData: null })} hideButton={true} data={{ ...contactEmailData }} />
                        }
                        <Dialog
                            TransitionComponent={!matches ? SlideUp : Grow}
                            classes={{
                                root: classes.root,
                                paper: matches ? classes.paper : "full-screen-dialog"
                            }}
                            className={classes.root}
                            open={Boolean(this.props.open)}
                            onClose={() => this.closeComposeDialog()}
                            fullWidth
                            maxWidth={"sm"}
                            fullScreen={!matches}
                        >
                            <AppBar position="static" elevation={1} className="dialog-header">
                                <Toolbar className="flex w-full">
                                    <Typography variant="subtitle1" color="inherit">
                                        {/* {Boolean(inv.Data.VPARBH || inv.Data.VPARTH) &&
                                                <Tooltip title={<div>Batched: {this.formatDollars(batched)}<br />Posted: {this.formatDollars(posted)}</div>} placement="top">
                                                    <Icon className="mr-8 mb-4" style={{ verticalAlign: 'middle' }}>{Boolean(inv.Data.VPARBH) ? "cloud_upload" : "cloud_done"}</Icon>
                                                </Tooltip>
                                            } */}
                                        Contacts
                                    </Typography>
                                </Toolbar>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: matches ? 8 : 4,
                                    color: 'white'
                                }}
                                    onClick={() => {
                                        this.closeComposeDialog();
                                    }}
                                    className="dialog-header-icon"
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </AppBar>
                            <DialogContent>
                                <ContactList
                                    label={this.props.label}
                                    onUpdate={this.props.onUpdate}
                                    onCancel={this.props.onCancel}
                                    editable={true}
                                    customer={this.props.customer}
                                    site={this.props.site}
                                    details={details}
                                    woContactActive={this.props.siteContact ? 'Y' : undefined}
                                    siteContact={this.props.siteContact}
                                    updateSiteContact={this.props.updateSiteContact}
                                />
                            </DialogContent>
                        </Dialog>
                    </React.Fragment>
                }

            </Media >

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersContactList));