import axios from 'axios/index';
import { getUserData } from 'main/content/apps/phone-numbers/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_PHONE_NUMBERS = '[PHONE NUMBERS APP] GET PHONE NUMBERS';
export const SET_SEARCH_TEXT = '[PHONE NUMBERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_PHONE_NUMBERS = '[PHONE NUMBERS APP] TOGGLE IN SELECTED PHONE NUMBERS';
export const SELECT_ALL_PHONE_NUMBERS = '[PHONE NUMBERS APP] SELECT ALL PHONE NUMBERS';
export const DESELECT_ALL_PHONE_NUMBERS = '[PHONE NUMBERS APP] DESELECT ALL PHONE NUMBERS';
export const OPEN_NEW_PHONE_NUMBER_DIALOG = '[PHONE NUMBERS APP] OPEN NEW PHONE NUMBER DIALOG';
export const CLOSE_NEW_PHONE_NUMBER_DIALOG = '[PHONE NUMBERS APP] CLOSE NEW PHONE NUMBER DIALOG';
export const OPEN_EDIT_PHONE_NUMBER_DIALOG = '[PHONE NUMBERS APP] OPEN EDIT PHONE NUMBER DIALOG';
export const CLOSE_EDIT_PHONE_NUMBER_DIALOG = '[PHONE NUMBERS APP] CLOSE EDIT PHONE NUMBER DIALOG';
export const ADD_PHONE_NUMBER = '[PHONE NUMBERS APP] ADD PHONE NUMBER';
export const UPDATE_PHONE_NUMBER = '[PHONE NUMBERS APP] UPDATE PHONE NUMBER';
export const REMOVE_PHONE_NUMBER = '[PHONE NUMBERS APP] REMOVE PHONE NUMBER';
export const REMOVE_PHONE_NUMBERS = '[PHONE NUMBERS APP] REMOVE PHONE NUMBERS';
export const TOGGLE_STARRED_PHONE_NUMBER = '[PHONE NUMBERS APP] TOGGLE STARRED PHONE NUMBER';
export const TOGGLE_STARRED_PHONE_NUMBERS = '[PHONE NUMBERS APP] TOGGLE STARRED PHONE NUMBERS';
export const SET_PHONE_NUMBERS_STARRED = '[PHONE NUMBERS APP] SET PHONE NUMBERS STARRED ';
export const SET_SEARCH_RESULTS = '[PHONE NUMBERS APP] SET SEARCH RESULTS';

export function getPhoneNumbers(routeParams) {
    const request = axios.get('/api/phone-numbers-app/phone-numbers', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_PHONE_NUMBERS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedPhoneNumbers(phoneNumberId) {
    return {
        type: TOGGLE_IN_SELECTED_PHONE_NUMBERS,
        phoneNumberId
    }
}


export function selectAllPhoneNumbers() {
    return {
        type: SELECT_ALL_PHONE_NUMBERS
    }
}

export function deSelectAllPhoneNumbers() {
    return {
        type: DESELECT_ALL_PHONE_NUMBERS
    }
}


export function openNewPhoneNumberDialog() {
    return {
        type: OPEN_NEW_PHONE_NUMBER_DIALOG
    }
}

export function closeNewPhoneNumberDialog() {
    return {
        type: CLOSE_NEW_PHONE_NUMBER_DIALOG
    }
}

export function openEditPhoneNumberDialog(data) {
    return {
        type: OPEN_EDIT_PHONE_NUMBER_DIALOG,
        data
    }
}

export function closeEditPhoneNumberDialog() {
    return {
        type: CLOSE_EDIT_PHONE_NUMBER_DIALOG
    }
}

export function addPhoneNumber(newPhoneNumber) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/PhoneNumber`,
            {
                ...newPhoneNumber,
                Data: {
                    ErrMsg: null
                }
            }
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_PHONE_NUMBER
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Phone Number ${newPhoneNumber.PhoneNumber} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updatePhoneNumber(phoneNumber) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/PhoneNumber?Co=${encodeURIComponent(phoneNumber.Co)}&PhoneNumber=${encodeURIComponent(phoneNumber.Phone)}`,
            {
                ...phoneNumber,
                Data: {
                    ErrMsg: null,
                }
            }
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_PHONE_NUMBER
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Phone Number ${phoneNumber.Phone} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function purchasePhoneNumber(num) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/IncomingPhoneNumber`, num);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: ADD_PHONE_NUMBER
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Phone Number ${num.Number} has been successfully purchased.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        });
    }
}

export function removePhoneNumber(num) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/IncomingPhoneNumber?Co=${num.Co}&Phone=${encodeURIComponent(num.Phone)}`);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: REMOVE_PHONE_NUMBER
                })
            ]).then(() => {
                if (response.data.length < 1) {
                    dispatch(showMessage({
                        message: `Phone Number ${num.Phone} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                }
            })
        });
    }
}

export function searchAvailableNumbers(Co, area, search, tollFree) {
    return (dispatch, getState) => {
        var request = axios.get(`${window["apiLocation"]}/api/IncomingPhoneNumber?Co=${encodeURIComponent(Co)}&AreaCode=${encodeURIComponent(area)}&Pattern=${search}&TollFree=${tollFree}`);

        return request.then((response) => {
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: response.data,
            })
        });
    }
}
