import * as Actions from 'store/actions/spConnection';
import { omitDates } from '../../../main/functions';
import { SET_SORT_CATALOG } from '../../actions/spConnection/catalog.actions';

const initialState = {
    catalogs: [],
    assemblies: [],
    categories: [],
    lastUpdate: '1/1/2020',
    savedCatalogs: [],
    preferredView: 'grid',
    sortCatalog: {
        sortBy: null,
        order: null 
    }
}

const catalogs = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CATALOG_DATA:
            {
                return {
                    ...state,
                    catalogs: omitDates(action.payload),
                };
            }
        case Actions.SET_CATALOG_DATA:
            {
                return {
                    ...state,
                    catalogs: omitDates(action.payload),
                };
            }
        case Actions.SET_SAVED_CATALOGS:
            {
                return {
                    ...state,
                    savedCatalogs: omitDates(action.payload),
                }
            }
        case Actions.GET_ASSEMBLY_DATA:
            {
                return {
                    ...state,
                    assemblies: omitDates(action.payload),
                    lastUpdate: new Date().toLocaleDateString('en-US'),
                };
            }
        case Actions.SET_ASSEMBLY_DATA:
            {
                return {
                    ...state,
                    assemblies: omitDates(action.payload),
                    lastUpdate: new Date().toLocaleDateString('en-US'),
                };
            }
        case Actions.GET_ASSEMBLY_CATEGORY_DATA:
            {
                return {
                    ...state,
                    categories: omitDates(action.payload),
                    lastUpdate: new Date().toLocaleDateString('en-US'),
                };
            }
        case Actions.SET_ASSEMBLY_CATEGORY_DATA:
            {
                return {
                    ...state,
                    categories: omitDates(action.payload),
                    lastUpdate: new Date().toLocaleDateString('en-US'),
                };
            }
        case Actions.SET_PREFERRED_VIEW:
            {
                return {
                    ...state,
                    preferredView: action.payload
                };
            }
        case SET_SORT_CATALOG:
            {
                return {
                    ...state,
                    sortCatalog: action.payload
                };
            }
    }

    return state;
};

export default catalogs;