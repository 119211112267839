import history from 'history.js';
import { FuseDefaultSettings } from '@fuse';
import _ from '@lodash';
import store from 'store';
import * as Actions from 'store/actions';
import { LOGOUT } from 'auth/store/actions/login.actions';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const PURGE_STATE = '[USER] PURGE STATE';

/**
 * Set User Data
 */
export function setUserData(user) {
    return (dispatch) => {

        /*
        Set User Settings
         */
        // dispatch(setDefaultSettings(user.data.settings));

        /*
        Set User Data
         */
        dispatch({
            type: SET_USER_DATA,
            payload: user
        })
    }
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, { data: { settings } });

        updateUserData(user);

        return dispatch(setUserData(user));
    }
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(Shortcuts) {
    return (dispatch, getState) => {
        const { User } = getState().spReducers.userProfiles;
        const newUser = {
            ...User,
            Data: {
                ...User.Data,
                Shortcuts
            }
        };

        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    }
}

/**
 * Remove User Data
 */
export function removeUserData() {
    return {
        type: REMOVE_USER_DATA
    }
}

/**
 * Logout
 */
export function logoutUser() {
    history.push({
        pathname: '/'
    });

    return (dispatch, getState) => {

        const user = getState().auth.user;
        Promise.all([
            dispatch(Actions.clearVault()),
            dispatch(Actions.setLockType("NoLocking"))
        ]).then(() => {
            Promise.all([
                dispatch(Actions.setDefaultSettings(FuseDefaultSettings)),
                dispatch({
                    type: USER_LOGGED_OUT
                }),
                dispatch({
                    type: LOGOUT
                }),
                dispatch({
                    type: Actions.STOP_SIGNAL_R
                }),
                dispatch({
                    type: Actions.REMOVE_SP_TOKEN
                })
            ]).then(() => {
                dispatch({
                    type: Actions.GET_API_LOCATION,
                    location: null,
                    error: 'User has been logged out',
                });
                window["apiLocation"] = null;
                dispatch({
                    type: PURGE_STATE
                });
            })
        })
    }
}

/**
 * Update User Data
 */
function updateUserData(user) {
    if (user.role === 'guest') {
        return;
    }

    switch (user.from) {
        default:
            {
            }
    }
}