import BusinessUnitsApp from 'main/content/apps/business-units/BusinessUnitsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const BusinessUnitsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/business-units/:id',
            component: BusinessUnitsApp
        },
        {
            path     : '/apps/business-units',
            component: () => <Redirect to="/apps/business-units/all"/>
        }
    ]
};
