import React, { Component } from 'react';
import _ from '@lodash';
import { withStyles } from '@material-ui/core/styles';
import { FusePageSimple, FuseAnimate } from '@fuse';
import YTSearch from 'youtube-api-v3-search';
import VideoSearch from './components/video_search';
import VideoList from './components/video_list';
import VideoDetail from './components/video_detail';

const YOUTUBE_API_KEY = 'AIzaSyCbIT8kaAy7BIuPC1sFWFyr1UCmbmnKlQE';


const styles = theme => ({
    content: {
    }
});

class YouTubeApp extends Component {
    constructor(props) {
        super(props);

        this.state = { videos: [], selectedVideo: null };
        //this.videoSearch('Repair Furnace');
    }

    videoSearch(term) {
        YTSearch(YOUTUBE_API_KEY, { q: term }, (error, videos) => {
            this.setState({
                videos: videos.items,
                selectedVideo: videos.items[0]
            });
            if (error) {
                window["error"](error);
            }
            window["log"](videos);
        });
    };

    render() {
        const { classes } = this.props;
        const videoSearch = _.debounce((term) => { this.videoSearch(term) }, 1000);

        return (
            <React.Fragment>
                <FusePageSimple
                    classes={{
                        leftSidebar: "w-256 pt-32 pl-8",
                        header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
                        content: "mt-20 p-20"
                    }}
                    header={
                        <VideoSearch pageLayout={() => this.pageLayout} onSearchTermChange={videoSearch} />
                    }
                    content={
                        <VideoDetail video={this.state.selectedVideo} />
                    }
                    leftSidebarContent={
                        <VideoList
                            onVideoSelect={selectedVideo => this.setState({ selectedVideo })}
                            videos={this.state.videos} />
                    }
                    sidebarInner
                    onRef={instance => {
                        this.pageLayout = instance;
                    }}
                    innerScroll
                />
            </React.Fragment >
        )
    };
}

export default withStyles(styles, { withTheme: true })(YouTubeApp);