import axios from 'axios/index';

export const SET_COST_TYPE_CATEGORY_DATA = '[SPCONNECTION] SET COST TYPE CATEGORY DATA';
export const GET_COST_TYPE_CATEGORY_DATA = '[SPCONNECTION] GET COST TYPE CATEGORY DATA';
export const SUBSCRIBE_COST_TYPE_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE COST TYPE CATEGORY LIST';

export function getInitialCostTypeCategoryData() {
    const request = axios.get(`${window["apiLocation"]}/api/CostTypeCategory`);
    request.then((response) => {
        return response.data;
    });
}

export function getCostTypeCategoryData() {
    const request = axios.get(`${window["apiLocation"]}/api/CostTypeCategory`);

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_COST_TYPE_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setCostTypeCategoryData(data) {
    return {
        type: SET_COST_TYPE_CATEGORY_DATA,
        payload: data
    };
}