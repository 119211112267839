import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newRevenueSplitState = {
    Co: null,
    WorkOrder: null,
    Scope: null,
    Seq: null,
    CostType: null,
    Split: null,
    TaxableYN: 'Y',
    OverrideGLAccount: null,
    Notes: null,
    Data: {

    }
};

class RevenueSplitDialog extends Component {
    state = { ...newRevenueSplitState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newRevenueSplitState.Co = this.props.Co;
        if (!prevProps.revenueSplitDialog.props.open && this.props.revenueSplitDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.revenueSplitDialog.type === 'edit' &&
                this.props.revenueSplitDialog.data &&
                !_.isEqual(this.props.revenueSplitDialog.data, prevState)) {
                this.setState({ ...this.props.revenueSplitDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.revenueSplitDialog.type === 'new' &&
                this.props.revenueSplitDialog.data &&
                !_.isEqual(this.props.revenueSplitDialog.data, prevState)) {
                this.setState({ ...newRevenueSplitState, ...this.props.revenueSplitDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.revenueSplitDialog.type === 'edit' ? this.props.closeEditRevenueSplitDialog() : this.props.closeNewRevenueSplitDialog();
    };

    canBeSubmitted() {
        const { Split, CostType } = this.state;
        return (
            Number(Split) <= 100 && Number(Split) > 0 && CostType !== null && !isNaN(CostType)
        );
    }

    render() {
        const { classes, securables, revenueSplitDialog, addRevenueSplit, updateRevenueSplit, removeRevenueSplit, glAccounts, costTypes } = this.props;
        const accessLevel = _.find(securables, { Securable: "work-orders" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? "m-24" : "full-screen-dialog"
                        }}
                        className={classes.root}
                        {...revenueSplitDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                        fullScreen={!matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {revenueSplitDialog.type === 'new' ? 'New  Revenue Split' : `Revenue Split #${this.state.Seq}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches.medium ? 8 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Cost Type"
                                    select
                                    id="costType"
                                    name="CostType"
                                    value={this.state.CostType}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.CostType ? true : false }}
                                    required
                                    fullWidth
                                >
                                    {(costTypes && costTypes.length > 0 ? costTypes.map((value, index) => (
                                        <MenuItem key={index} value={value.CostType}>
                                            {value.CostType} - {value.Name}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Split %"
                                    id="split"
                                    name="Split"
                                    value={this.state.Split}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="GL Override"
                                    id="overrideGLAccount"
                                    name="OverrideGLAccount"
                                    value={this.state.OverrideGLAccount}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.OverrideGLAccount ? true : false }}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value={null}>
                                        Default
                                    </MenuItem>
                                    {(glAccounts && glAccounts.length > 0 ? glAccounts.map((value, index) => (
                                        <MenuItem key={index} value={value.GLAccount}>
                                            {value.GLAccount} - {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Taxable?"
                                    id="taxableYN"
                                    name="TaxableYN"
                                    value={this.state.TaxableYN}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: this.state.TaxableYN ? true : false }}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="Y">
                                        Yes
                                    </MenuItem>
                                    <MenuItem value="N">
                                        No
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {revenueSplitDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addRevenueSplit(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateRevenueSplit(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                                        </Button>
                                        <IconButton
                                            onClick={() => {
                                                removeRevenueSplit(this.state);
                                                this.closeComposeDialog();
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditRevenueSplitDialog: Actions.closeEditRevenueSplitDialog,
        closeNewRevenueSplitDialog: Actions.closeNewRevenueSplitDialog,
        addRevenueSplit: Actions.addRevenueSplit,
        updateRevenueSplit: Actions.updateRevenueSplit,
        removeRevenueSplit: Actions.removeRevenueSplit
    }, dispatch);
}

function mapStateToProps({ workOrderScopesApp, spReducers }) {
    return {
        revenueSplitDialog: workOrderScopesApp.workOrderScopes.revenueSplitDialog,
        Co: spReducers.companies.Co,
        costTypes: spReducers.costTypes,
        glAccounts: spReducers.glAccounts,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RevenueSplitDialog));
