import {combineReducers} from 'redux';
import callGathers from './call-gathers.reducer';
import user from './user.reducer';

const callGathersAppReducers = combineReducers({
    callGathers,
    user
});

export default callGathersAppReducers;
