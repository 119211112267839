import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import VoiceMailboxList from 'main/content/apps/voice-mailboxes/VoiceMailboxesList';
import VoiceMailboxHeader from 'main/content/apps/voice-mailboxes/VoiceMailboxesHeader';
import VoicemailList from 'main/content/apps/voice-mailboxes/VoicemailList';
import VoiceMailboxesLeftSidebarHeader from 'main/content/apps/voice-mailboxes/VoiceMailboxesLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import VoiceMailboxDialog from 'main/content/apps/voice-mailboxes/VoiceMailboxDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right: 12,
        bottom: 12,
        zIndex: 99
    }
});

class VoiceMailboxesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        // if (!_.isEqual(this.props.location, prevProps.location)) {
        //     this.props.getVoiceMailboxes(this.props.match.params);
        // }
    }

    render() {
        const { classes, openNewVoiceMailboxDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <VoiceMailboxHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <VoiceMailboxesLeftSidebarHeader />
                            }
                            content={
                                <VoiceMailboxList />
                            }
                            leftSidebarContent={
                                <VoicemailList />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <VoiceMailboxDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // getVoiceMailboxes: Actions.getVoiceMailboxes,
        getUserData: Actions.getUserData,
        openNewVoiceMailboxDialog: Actions.openNewVoiceMailboxDialog
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp }) {
    return {
        voiceMailboxes: voiceMailboxesApp.voiceMailboxes.entities,
        selectedVoiceMailboxIds: voiceMailboxesApp.voiceMailboxes.selectedVoiceMailboxIds,
        searchText: voiceMailboxesApp.voiceMailboxes.searchText,
        user: voiceMailboxesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VoiceMailboxesApp)));
