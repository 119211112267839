import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedArrivalWindowIds: [],
    routeParams       : {},
    arrivalWindowDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const arrivalWindowsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_ARRIVAL_WINDOWS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_ARRIVAL_WINDOWS:
        {

            const arrivalWindowId = action.arrivalWindowId;

            let selectedArrivalWindowIds = [...state.selectedArrivalWindowIds];

            if ( selectedArrivalWindowIds.find(id => id === arrivalWindowId) !== undefined )
            {
                selectedArrivalWindowIds = selectedArrivalWindowIds.filter(id => id !== arrivalWindowId);
            }
            else
            {
                selectedArrivalWindowIds = [...selectedArrivalWindowIds, arrivalWindowId];
            }

            return {
                ...state,
                selectedArrivalWindowIds: selectedArrivalWindowIds
            };
        }
        case Actions.SELECT_ALL_ARRIVAL_WINDOWS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedArrivalWindowIds = arr.map(arrivalWindows => arrivalWindows.id);

            return {
                ...state,
                selectedArrivalWindowIds: selectedArrivalWindowIds
            };
        }
        case Actions.DESELECT_ALL_ARRIVAL_WINDOWS:
        {
            return {
                ...state,
                selectedArrivalWindowIds: []
            };
        }
        case Actions.OPEN_NEW_ARRIVAL_WINDOW_DIALOG:
        {
            return {
                ...state,
                arrivalWindowDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_ARRIVAL_WINDOW_DIALOG:
        {
            return {
                ...state,
                arrivalWindowDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_ARRIVAL_WINDOW_DIALOG:
        {
            return {
                ...state,
                arrivalWindowDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_ARRIVAL_WINDOW_DIALOG:
        {
            return {
                ...state,
                arrivalWindowDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default arrivalWindowsReducer;
