import _ from '@lodash';

export const ADD_SPLITSCREEN_DIALOG = '[WORK ORDER HISTORY APP] ADD SPLITSCREEN DIALOG';
export const SHOW_SPLITSCREEN_DIALOG = '[WORK ORDER HISTORY APP] SHOW SPLITSCREEN DIALOG';
export const SET_SPLITSCREEN_DIALOG = '[WORK ORDER HISTORY APP] SET SPLITSCREEN DIALOG';

export function addSplitscreenDialog(data) {
    return (dispatch, getState) => {
        if (data) {
            return dispatch({
                type: ADD_SPLITSCREEN_DIALOG,
                data: data,
            })
        }
    }
}

export function showSplitscreenDialog(data) {
    return (dispatch, getState) => {
        return dispatch({
            type: SHOW_SPLITSCREEN_DIALOG,
            payload: data
        })
    }
}

export function setSplitscreenDialog(data, show, splitPosition, height, cornered, layout) {

    return (dispatch, getState) => {
        let state = getState().splitscreenDialogApp.splitscreenDialog
        let existingLayout = state.layout
        window["warn"](window["warn"]("newDialogSplitList setSplitscreenDialog", data, layout, state))
        if (data || show) {
            return dispatch({
                type: SET_SPLITSCREEN_DIALOG,
                data: data,
                show: show,
                splitPosition: splitPosition,
                height: height,
                cornered: cornered ? cornered : 1,
                layout: layout ? layout : existingLayout,
            })
        }
    }
}

export function hideAllSplitscreenDialogs(data) {
    return (dispatch, getState) => {
        let currentState = getState()
        if(currentState){
            const currentData = currentState["splitscreenDialogApp"]["splitscreenDialog"]["data"];
            for(let i = 0; i < currentData.length; i++){
                if(currentData[i].header !== data && !currentData[i].docked){
                    window["warn"]("hideAllSplitscreenDialogs", currentData, currentData[i].header)
                    currentData[i].closeFxn2()
                }   
            }
        }

        return dispatch(addSplitscreenDialog(null));
    }
}

export function captureSplitscreenDialogs() {
    return (dispatch, getState) => {
        window["warn"]("captureSplitscreenDialogs ran!")
        return async () => {
            let currentState = getState()
            let currentData;
            let count = 0;
            window["warn"]("captureSplitscreenDialogs ran! 2", currentState)
            if(currentState){
                currentData = currentState["splitscreenDialogApp"]["splitscreenDialog"]["data"];
                for(let i = 0; i < currentData.length; i++){
                    //if(!currentData[i].image){
                    if(typeof currentData[i].image !== "string"){
                        let image = await currentData[i].captureImage();
                        if(typeof image === "string"){
                            currentData[i].image = image;
                            window["warn"]("image", image)
                            count++;
                        } 
                    }   
                }
            }

            if(count > 0){
                //return currentData;
                return dispatch(addSplitscreenDialog(currentData));
            } else{
                //return null;
                return dispatch(addSplitscreenDialog(null));
            }
        }
    }
}