import axios from 'axios/index';

export const SET_DIVISION_DATA = '[SPCONNECTION] SET DIVISION DATA';
export const GET_DIVISION_DATA = '[SPCONNECTION] GET DIVISION DATA';
export const SUBSCRIBE_DIVISION_LIST = '[SPCONNECTION] SUBSCRIBE DIVISION LIST';

export function getInitialDivisionData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Division`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getDivisionData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Division`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_DIVISION_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_DIVISION_LIST,
            Co
        }));
    }
}

export function setDivisionData(data) {
    return {
        type: SET_DIVISION_DATA,
        payload: data
    };
}