import React, { Component } from 'react';
import { TextField, Button, Avatar, Icon, Typography, List } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import CommentModel from 'main/content/apps/scrumboard/model/CommentModel';
import CardActivity from './activity/CardActivity';
import CardComment from './comment/CardComment';
import _ from '@lodash';

class Comments extends Component {
    state = {
        message: ''
    };

    render() {
        const { members, Co, user, commentAdd, board, card, users } = this.props;

        return (
            <div className="mb-24">
                <div className="flex items-center mt-16 mb-12">
                    <Icon className="text-20 mr-8" color="inherit">comment</Icon>
                    <Typography className="font-600 text-16">Comments</Typography>
                </div>
                <CardComment
                    onCommentAdd={commentAdd}
                />

                <div className="flex items-center mt-16 mb-12">
                    <Icon className="text-20 mr-8" color="inherit">list</Icon>
                    <Typography className="font-600 text-16">Activity</Typography>
                </div>
                {card.Data.Comments && card.Data.Comments.length > 0 && (
                    <div className="mb-24">
                        <List className="">
                            {card.Data.Comments.map(item => (
                                <CardActivity
                                    item={item}
                                    key={item.ID}
                                    members={users}
                                />
                            )
                            )}
                        </List>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        card: scrumboardApp.card
    }
}

export default connect(mapStateToProps)(Comments);
