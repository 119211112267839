import React, { Component } from 'react';
import { Badge, Divider, Icon, IconButton, Input, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography, withStyles, Card, CardHeader } from '@material-ui/core';
import * as UserActions from 'auth/store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FuseUtils, FuseAnimateGroup } from '@fuse';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { openEditCustomerDialog } from 'main/content/apps/customers/store/actions';
import { openEditCustomerSiteDialog } from 'main/content/apps/customer-sites/store/actions';
import { Link } from 'react-router-dom';
import amber from '@material-ui/core/colors/amber';
import classNames from 'classnames';
import axios from 'axios';
import Media from 'react-media';
import InspectorDialog from 'main/content/components/inspector/Inspector';
import _ from '@lodash';
import MinimizedDialogs from '../../../main/content/apps/minimized-dialogs/MinimizedDialogs';

const propTypes = {};

const defaultProps = {};

const styles = theme => ({
    root: {},
    bottom: {
        top: 'unset !important',
        bottom: '60px !important',
        left: 'unset !important',
        right: '16px !important'
    },
    bottomLeft: {
        top: 'unset !important',
        bottom: '60px !important',
        right: 'unset !important',
        left: '16px !important'
    },
    item: {
        textDecoration: 'none!important'
    },
    addIcon: {
        color: amber[600]
    },
    badge: {
        top: 4,
        right: 4,
        fontSize: 9,
        minWidth: 14,
        minHeight: 14,
        height: 14,
    }
});

class FuseShortcuts extends Component {
    state = {
        addMenu: null,
        searchText: '',
        searchResults: null,
        flatNavigation: null,
        shortcuts: [],
    };

    componentDidMount() {
        const { shortcuts } = this.props;
        this.setState({ ...this.state, shortcuts });
    }

    addMenuClick = event => {
        this.setState({
            ...this.state,
            addMenu: event.currentTarget
        });
    };

    histMenuClick = event => {
        this.setState({
            ...this.state,
            histMenu: event.currentTarget
        });
    };

    minimizeMenuClick = event => {
        window["warn"]("minimizeMenuClick", event)
        this.setState({
            ...this.state,
            minimizeMenu: event.currentTarget,
            minimizeMenuClicked: event.currentTarget,
        });
    };

    addMenuClose = () => {
        this.setState({
            ...this.state,
            addMenu: null
        });
    };

    histMenuClose = () => {
        this.setState({
            ...this.state,
            histMenu: null
        });
    };

    minimizeMenuClose = () => {
        this.setState({
            ...this.state,
            minimizeMenu: null,
            minimizeMenuClicked: null
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const { shortcuts } = this.props;
        if (!_.isEqual(shortcuts, prevProps.shortcuts)) {
            this.setState({ ...this.state, shortcuts });
        }
    }

    search = (ev) => {
        const { pages } = this.props;
        const searchText = ev.target.value;
        if (searchText && searchText.length > 0 && pages) {
            this.setState({
                ...this.state,
                searchResults: pages.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
                searchText
            });
            return;
        }
        this.setState({ ...this.state, searchText, searchResults: null });
    };

    toggleInShortcuts = (id) => {
        const { shortcuts } = this.state;
        const shortcut = _.find(shortcuts, { Shortcut: id });
        if (shortcut) {
            this.removeShortcut(id);
        } else {
            this.addShortcut(id);
        }
    };

    addShortcut = (id) => {
        const { user } = this.props;
        const { shortcuts } = this.state;
        const shortcut = {
            UserName: user.UserName,
            Shortcut: id
        }
        axios.post(`${window["apiLocation"]}/api/UserSettings/PostUserShortcut`,
            shortcut
        ).then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg.length < 1) {
                shortcuts.push(response.data);
                this.setState({ ...this.state, shortcuts }, this.props.updateUserShortcuts(shortcuts));
            }
        });
    }

    removeShortcut = (id) => {
        const { user } = this.props;
        const { shortcuts } = this.state;
        const shortcut = {
            UserName: user.UserName,
            Shortcut: id
        }
        axios.delete(`${window["apiLocation"]}/api/UserSettings/DeleteUserShortcut?Shortcut=${id}`, shortcut).then((response) => {
            if (!response.data || response.data.length < 1) {
                const index = _.findIndex(shortcuts, { Shortcut: id });
                shortcuts.splice(index, 1);
                this.setState({ ...this.state, shortcuts }, this.props.updateUserShortcuts(shortcuts));
            }
        });
    }

    handleHistClick = (item) => {
        const { dialog, timestamp, params } = item;
        this.setState({ ...this.state, histMenu: null });
        switch (item.dialog) {
            case 'WorkOrder':
                {
                    this.props.openEditWorkOrderDialog(params, params.Trip);
                }
                break;
            case 'Customer':
                {
                    this.props.openEditCustomerDialog(params);
                }
                break;
            case 'Site':
                {
                    this.props.openEditCustomerSiteDialog(params);
                }
                break;
        }

    }

    inspectData = () => {
        this.setState({ ...this.state, inspector: true, menuEl: null });
    }

    closeInspector = () => {
        this.setState({ ...this.state, inspector: false });
    }

    render() {
        const { classes, navigation, pages, placement, dialogHistory, openEditWorkOrderDialog, securables, minimizedDialogs } = this.props;
        const { addMenu, histMenu, searchText, searchResults, shortcuts, inspector, minimizeMenu } = this.state;
        const shortcutItems = shortcuts ? shortcuts.map(val => FuseUtils.findById(pages, val.Shortcut)) : [];
        const { history } = dialogHistory;
        const debugAccess = _.find(securables, { Securable: "inspector" });

        if(minimizedDialogs.length === 0 && this.state.minimizeMenuClicked){
            this.setState({
                ...this.state,
                minimizeMenu: null,
                minimizeMenuClicked: null
            });
        }

        const ShortcutMenuItem = ({ item, onToggle }) => {
            const shortcut = _.find(shortcuts, { Shortcut: item.id });
            return (
                <Link to={item.url} className={classes.item}>
                    <MenuItem onClick={this.addMenuClose} key={item.id}>
                        <ListItemIcon>
                            {item.icon ?
                                (
                                    <Icon>{item.icon}</Icon>
                                ) :
                                (
                                    <span className="text-20 font-bold uppercase text-center">{item.title[0]}</span>
                                )
                            }
                        </ListItemIcon>
                        <ListItemText className="pl-0 text-grey-darkest" primary={item.title} />
                        <IconButton
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                onToggle(item.id);
                            }}
                        >
                            <Icon color="action" className={shortcut ? classes.addIcon : ''}>{shortcut ? 'star' : 'star_border'}</Icon>
                        </IconButton>
                    </MenuItem>
                </Link>
            );
        }

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <div className={classNames(classes.root, "flex flex-1 px-16")}>

                        <FuseAnimateGroup
                            enter={{
                                animation: "transition.expandIn"
                            }}
                            className={`${placement !== "bottom" ? 'hidden md:flex md-flex-1' : 'w-full flex justify-between'}`}
                        >

                            <Tooltip title={"Dialog History"} placement="bottom" enterDelay={750}>
                                <IconButton
                                    className="w-40 h-40 p-0"
                                    aria-owns={histMenu ? 'hist-menu' : null}
                                    aria-haspopup="true"
                                    onClick={this.histMenuClick}
                                >
                                    <Icon>history</Icon>
                                </IconButton>
                            </Tooltip>

                            {shortcutItems && shortcutItems.map(item => item && (
                                <Link to={item.url} key={item.id} className={classes.item}>
                                    <Tooltip title={item.title} placement="bottom" enterDelay={750}>
                                        <IconButton className="w-40 h-40 p-0">
                                            {item.icon ?
                                                (
                                                    <Badge color="error" badgeContent={item.badge ? item.badge.title : null} classes={{ badge: classes.badge }}>
                                                        <Icon>{item.icon}</Icon>
                                                    </Badge>
                                                ) :
                                                (
                                                    <span className="text-20 font-bold uppercase">{item.title[0]}</span>
                                                )
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            ))}

                            {debugAccess &&
                                <Tooltip title="Inspect Data" placement="bottom" enterDelay={750}>
                                    <IconButton
                                        className="w-40 h-40 p-0"
                                        aria-haspopup="true"
                                        onClick={this.inspectData}
                                    >
                                        <Icon>code</Icon>
                                    </IconButton>
                                </Tooltip>
                            }

                            {placement && placement === 'bottom' && minimizedDialogs.length > 0 &&
                                <Tooltip title="Minimized Content" placement="bottom" enterDelay={750}>
                                    <IconButton
                                        className="w-40 h-40 p-0"
                                        aria-owns={minimizeMenu ? 'hist-menu' : null}
                                        aria-haspopup="true"
                                        onClick={this.minimizeMenuClick}
                                    >
                                        <Icon>minimize</Icon>
                                    </IconButton>
                                </Tooltip>
                            }

                            <Tooltip title="Click to add/remove shortcut" placement="bottom" enterDelay={750}>
                                <IconButton
                                    className="w-40 h-40 p-0"
                                    aria-owns={addMenu ? 'add-menu' : null}
                                    aria-haspopup="true"
                                    onClick={this.addMenuClick}
                                >
                                    <Icon>search</Icon>
                                </IconButton>
                            </Tooltip>
                        </FuseAnimateGroup>

                        <Menu
                            id="add-menu"
                            anchorEl={addMenu}
                            open={Boolean(addMenu)}
                            onClose={this.addMenuClose}
                            classes={{
                                paper: placement && placement === 'bottom' ? '' : 'mt-48'
                            }}
                            PopoverClasses={placement && placement === 'bottom' &&
                            {
                                paper: classes.bottom
                            }}
                            onEntered={() => {
                                this.searchInput.focus();
                            }}
                            onExited={() => {
                                this.setState({ searchText: '' });
                            }}>
                            {(!placement || (placement && placement !== 'bottom')) &&
                                <div className="p-16 pt-8">
                                    <Input
                                        inputRef={ref => this.searchInput = ref}
                                        value={searchText}
                                        onChange={this.search}
                                        placeholder="Search for an app or page"
                                        className=""
                                        fullWidth
                                        inputProps={{
                                            'aria-label': 'Search'
                                        }}
                                    />
                                </div>
                            }
                            {(!placement || (placement && placement !== 'bottom')) &&

                                <Divider />
                            }
                            <div className="max-h-256 overflow-auto">
                                {searchText && searchText.length !== 0 && searchResults && searchResults.map(item => (
                                    <ShortcutMenuItem
                                        key={item.id}
                                        item={item}
                                        onToggle={() => this.toggleInShortcuts(item.id)}
                                    />
                                ))}

                                {searchText && searchText.length !== 0 && searchResults.length === 0 && (
                                    <Typography color="textSecondary" className="p-16 pb-8">No results..</Typography>
                                )}

                                {(!searchText || searchText.length === 0) && shortcutItems.map(item => item && (
                                    <ShortcutMenuItem
                                        key={item.id}
                                        item={item}
                                        onToggle={() => this.toggleInShortcuts(item.id)}
                                    />
                                ))}
                            </div>

                            {placement && placement === 'bottom' &&
                                <Divider />
                            }
                            {placement && placement === 'bottom' &&
                                <div className="p-16 pb-8">
                                    <Input
                                        inputRef={ref => this.searchInput = ref}
                                        value={searchText}
                                        onChange={this.search}
                                        placeholder="Search for an app or page"
                                        className=""
                                        fullWidth
                                        inputProps={{
                                            'aria-label': 'Search'
                                        }}
                                    />
                                </div>
                            }
                        </Menu>

                        <Menu
                            id="hist-menu"
                            anchorEl={histMenu}
                            open={Boolean(histMenu)}
                            onClose={this.histMenuClose}
                            classes={{
                                paper: placement && placement === 'bottom' ? '' : 'mt-48'
                            }}
                            PopoverClasses={placement && placement === 'bottom' &&
                            {
                                paper: classes.bottomLeft
                            }}>
                            {(history && history.map) ? history.map((item) => {
                                const { dialog, timestamp, params } = item;
                                return (
                                    <MenuItem className="text-12 relative h-48" onClick={() => this.handleHistClick(item)}>
                                        <Icon className="text-20 mr-8 align-middle">{dialog === "WorkOrder" ? "assignment" : dialog === "Customer" ? "account_box" : dialog === "Site" ? "location_on" : "open_in_new"}</Icon>
                                        {dialog === "WorkOrder" &&
                                            `Work Order #${params.WorkOrder}${params.Trip ? ` Trip #${params.Trip}` : ''}`
                                        }
                                        {dialog === "Customer" &&
                                            `Customer #${params.Customer} - ${params.Name || (params.FirstName && params.LastName ? `${params.FirstName} ${params.LastName}` : '')}`
                                        }
                                        {dialog === "Site" &&
                                            `Site #${params.Site} - ${params.Description}`
                                        }
                                        <div className="pin-l pin-r pin-b absolute text-10 text-center text-grey">{`${new Date(timestamp).toLocaleTimeString('en-US')} on ${new Date(timestamp).toLocaleDateString('en-US')}`}</div>
                                    </MenuItem>
                                );
                            }) :
                                <MenuItem className="text-12 relative">
                                    <Icon className="text-20 mr-8 align-middle">history</Icon>
                                    {"No Recent History"}
                                </MenuItem>
                            }
                        </Menu>

                        <Menu
                            id="minimize-menu"
                            anchorEl={minimizeMenu}
                            open={Boolean(minimizeMenu)}
                            onClose={this.minimizeMenuClose}
                            classes={{
                                paper: placement && placement === 'bottom' ? '' : 'mt-48',
                                list: "p-0"
                            }}
                            PopoverClasses={placement && placement === 'bottom' &&
                            {
                                paper: classes.bottom
                            }}>
                            {minimizedDialogs.length > 0 ? minimizedDialogs.map((dialog, index) => {
                                return (
                                    <div onClick={this.minimizeMenuClose}>
                                        <MinimizedDialogs key={index} fullScreen={!matches} order={index} data={dialog} />
                                    </div>
                                );
                            }) : null
                            }
                        </Menu>

                        {inspector && debugAccess &&
                            <InspectorDialog theme="chromeDark" data={{ ...this.props.spReducers, ...this.props.version, dispatchData: this.props.dispatchBoard, callHandlerData: this.props.callHandler }} name="RootData" open={inspector} onClose={this.closeInspector} />
                        }
                    </div>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateUserShortcuts: UserActions.updateUserShortcuts,
        openEditWorkOrderDialog,
        openEditCustomerDialog,
        openEditCustomerSiteDialog,
    }, dispatch);
}

function mapStateToProps({ fuse, auth, spReducers, version, callHandlerApp, dispatchBoardApp, minimizedDialogApp }) {
    return {
        spReducers,
        navigation: fuse.navigation,
        user: spReducers.userProfiles.User,
        shortcuts: spReducers.userProfiles.User.Data.Shortcuts,
        pages: spReducers.userProfiles.Pages,
        dialogHistory: spReducers.dialogHistory,
        securables: spReducers.userProfiles.User.Data.Securables,
        dispatchBoard: dispatchBoardApp.dispatchBoard,
        callHandler: callHandlerApp.callHandler,
        minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
        version
    }
}

FuseShortcuts.propTypes = propTypes;
FuseShortcuts.defaultProps = defaultProps;

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FuseShortcuts));
