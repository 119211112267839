import * as Actions from 'store/actions/spConnection';

const callGathers = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_CALL_GATHER_DATA:
            {
                return action.payload;
            }
        case Actions.SET_CALL_GATHER_DATA:
            {
                return action.payload;
            }
    }

    return state;
};

export default callGathers;