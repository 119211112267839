import _ from '@lodash';
import { Avatar, Button, Chip, DialogActions, Divider, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Slide, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { openEditInvoiceDialog } from 'main/content/apps/invoices/store/actions';
import { openEditCustomerDialog } from 'main/content/apps/customers/store/actions';
import { openEditCustomerSiteDialog } from 'main/content/apps/customer-sites/store/actions';
import { openEditTechnicianDialog } from 'main/content/apps/technicians/store/actions';
import { openEditPurchaseOrderDialog } from 'main/content/apps/purchase-orders/store/actions';
import Autocomplete from 'main/content/components/autocomplete/Autocomplete';
import moment from 'moment';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import { guid, showMessage } from 'store/actions';
import format from 'string-template';
import getProfileImage from '../../../functions/getProfileImageUrl';

const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
    },
    dialogRoot: {

    },
    profileAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    columnButton: {
        '& .edit': {
            display: 'none',
        },
        '&:hover .edit': {
            display: 'block',
        }
    },
    avatar: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 11,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
});

const newPivotTableState = {
    results: [],
    columns: [],
    selectedColumns: [],
    pivotBy: [],
    calculations: [],
    calculation: {
        ID: null,
        Title: null,
        Expression: null,
        format: null
    },
    selectedPivot: null,
    selectedColumn: null,
    resized: [],
    newExpanded: null,
    viewPivots: false,
    viewColumns: false,
    viewCalculations: false,
    drilldowns: {}
}

class PivotTable extends Component {

    state = {
        ..._.cloneDeepWith(newPivotTableState)
    };

    componentDidMount() {
        const { results, options, expanded } = this.props;
        // window["warn"]('Pivot Table State From Mount: ', this.state);
        if (results && results.length > 0 && !options) {
            const columns = this.getColumns(results);
            const drilldowns = {};
            _.filter(columns, (o) => o.useDrilldown).forEach((dd) => drilldowns[dd.id] = { filter: null, column: dd.drilldown, value: null });
            this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, columns, expanded, drilldowns });
        } else {
            if (options && results && results.length > 0) {
                const { Options } = options;
                const pivot = this.buildPivot(results, Options);
                const drilldowns = {};
                _.filter(pivot.selectedColumns, (o) => o.useDrilldown).forEach((dd) => drilldowns[dd.id] = { filter: null, column: dd.drilldown, value: null });
                this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, ...pivot, expanded, drilldowns });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { results, options, expanded } = this.props;
        // window["warn"]('Pivot Table State From Update: ', this.state);
        if (!_.isEqual(results, prevProps.results) || !_.isEqual(options, prevProps.options) || !_.isEqual(expanded, prevProps.expanded)) {
            if (results && results.length > 0 && !options) {
                const columns = this.getColumns(results);
                const drilldowns = {};
                _.filter(columns, (o) => o.useDrilldown).forEach((dd) => drilldowns[dd.id] = { filter: null, column: dd.drilldown, value: null });
                this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, columns, expanded, newExpanded: null, drilldowns });
            } else {
                if (options && results && results.length > 0) {
                    const { Options } = options;
                    const pivot = this.buildPivot(results, Options);
                    const drilldowns = {};
                    _.filter(pivot.selectedColumns, (o) => o.useDrilldown).forEach((dd) => drilldowns[dd.id] = { filter: null, column: dd.drilldown, value: null });
                    this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, ...pivot, expanded, newExpanded: null, drilldowns });
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({ ..._.cloneDeepWith(newPivotTableState) });
    }

    buildPivot = (data, options) => {
        const Options = JSON.parse(options);
        const { pivotBy, calculations, columnOptions, resized } = Options;
        const columns = this.getColumns(data, columnOptions, pivotBy, calculations);
        const selectedColumns = _.orderBy(_.filter(columns, (o) => { return _.findIndex(columnOptions, { id: o.id }) > -1 }), ['displayIndex'], ['asc']);
        return {
            pivotBy,
            columns,
            selectedColumns,
            calculations,
            resized: resized || []
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    saveReport = () => {
        const { selectedColumns, pivotBy, calculations, resized } = this.state;
        const columnOptions = [];
        selectedColumns.map((col) => {
            const { id, Header, format, dataType, aggregation, show, width, isCalculation, displayIndex, useDrilldown } = col;
            columnOptions.push({
                id,
                Header,
                dataType,
                format,
                aggregation,
                show,
                width,
                isCalculation,
                displayIndex,
                useDrilldown
            });
        });
        const options = {
            columnOptions,
            pivotBy,
            calculations,
            resized
        };

        if (this.props.onAdded) {
            if (!this.props.options || !this.props.options.ID || !this.props.options.Report) {
                this.props.onAdded(JSON.stringify(options));
            }
        }

        if (this.props.onUpdated) {
            if (this.props.options && this.props.options.ID && this.props.options.Report) {
                this.props.onUpdated(JSON.stringify(options));
            }
        }
        // window["warn"](options);
        // return options;
    }

    removeReport = () => {
        const { options } = this.props;
        this.props.onDelete(options)
    }

    getColumns(data, columnOptions, pivotBy, calculations) {
        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }

        const columns = Object.keys(data[0]).map(key => {
            const existing = _.find(columnOptions, { id: key });
            const drilldown = _.find(Object.keys(data[0]), (o) => o.startsWith(`Drilldown_${key}`));
            const drilldownSource = _.find(Object.keys(data[0]), (o) => o.startsWith(`Drilldown_`) && o.endsWith(`_${key}`));
            const drilldownFrom = (drilldownSource ? drilldownSource.split('_')[1] : null);
            return {
                Header: existing ? existing.Header : key,
                headerClassName: increaseFontSize ? 'font-bold text-16' : 'font-bold text-12',
                accessor: key,
                id: key,
                dataType: existing ? existing.dataType : data[0][key] ? !isNaN(data[0][key]) ? 'number' : moment(data[0][key]).isValid() ? 'date' : 'text' : 'text',
                hidden: Boolean(key.startsWith('Drilldown_')),
                canDrilldown: Boolean(drilldown),
                drilldown,
                useDrilldown: existing ? existing.useDrilldown : false,
                drilldownSource,
                drilldownFrom
            };
        });
        if (calculations) {
            calculations.map((calculation) => {
                const agg = [];
                const col = {
                    Header: calculation.Title,
                    id: calculation.ID,
                    calculation: calculation.Expression,
                    calculationApplyTo: calculation.applyTo,
                    Cell: rowInfo => {
                        // window["warn"]('Calculation rowInfo: ', rowInfo)
                        if (rowInfo.original) {
                            if (!calculation.applyTo || calculation.applyTo === 'O') {
                                let val = '';
                                const { row, original } = rowInfo;
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = loose(exp)
                                    val = isNaN(val) ? val : _.round(val, 2);
                                } catch (ex) {
                                    val = ex.message;
                                }
                                row[calculation.id] = val;
                                return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        } else {
                            if (!calculation.applyTo || calculation.applyTo === 'A') {
                                let val = '';
                                const { row } = rowInfo;
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = _.round(loose(exp), 2);
                                    val = isNaN(val) ? '' : val
                                } catch (ex) {
                                    val = ex.message;
                                }
                                row[calculation.id] = val;
                                agg.push(val);
                                return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        }
                    },
                    accessor: row => {
                        let val = row[calculation.id];
                        if (!calculation.applyTo || calculation.applyTo === 'O') {
                            try {
                                const exp = `${format(calculation.Expression, row)}`;
                                val = loose(exp);
                            } catch (ex) {
                                val = ex.message;
                            }
                        }
                        return val;
                    },
                    aggregate: (vals, rows) => {
                        let val = '';
                        if (!calculation.applyTo || calculation.applyTo === 'A') {
                            if (rows) {
                                let row = {};
                                columnOptions.map((col) => {
                                    if (!col.isCalculation && (!pivotBy || pivotBy.indexOf(col.id) < 0) && col.aggregation) {
                                        row[col.id] = col.aggregation === 'count' ? rows && rows[0] && rows[0]._original ? _.filter(rows, (o) => { return o[col.id] }).length : _.round(_.sumBy(rows, col.id), 2) : _.round(_[`${col.aggregation}By`](rows, col.id), 2);
                                    }
                                });
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = loose(exp)
                                    val = isNaN(val) ? val === 'NaN' ? '' : val : _.round(val, 2);
                                } catch (ex) {
                                    val = ex.message;
                                }
                                // window["warn"]('Calc Agg RowInfo', row, rows, val)
                            }
                        }
                        return val;
                    },
                    headerClassName: increaseFontSize ? 'font-bold text-16' : 'font-bold text-12',
                    isCalculation: true,
                };
                columns.push(col);
            })
        }
        if (pivotBy) {
            pivotBy.map((piv, ind) => {
                const column = _.find(columns, { id: piv });
                if (column) {
                    const selectedColumn = _.find(columnOptions, { id: piv });
                    if (selectedColumn) {
                        column.format = selectedColumn.format;
                    }
                    column.Aggregated = cellInfo => { return <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}></div> };
                    if (['Technician', 'TeamLead', 'TeamMember'].indexOf(column.id) > -1) {
                        // column.disableExpander = true;
                        // column.accessor = row => this.getTechnicianName(row[column.id]);
                        column.Pivot = ({ value }) => <div className={`font-bold ${increaseFontSize ? 'text-16' : 'text-12'}`}>{this.getTechnician(value, column.drilldownFrom)}</div>;
                    } else if (['WorkOrder', 'WO'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: this.props.Co, WorkOrder: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['Invoice', 'InvoiceNumber'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditInvoiceDialog({ Co: this.props.Co, InvoiceNumber: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['Customer', 'Cust', 'CustNum'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditCustomerDialog({ Co: this.props.Co, Customer: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['PO', 'PONumber', 'PurchaseOrder'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditPurchaseOrderDialog({ Co: this.props.Co, PO: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else {
                        column.Pivot = ({ value }) => {
                            return <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} ${column.dataType !== 'text' ? 'text-center ' : ''}`}>{column.dataType === 'date' ? this.getFormattedDate(value, column.format) : value}</div>
                        };
                    }
                    if (column.dataType === 'date') {
                        column.accessor = row => {
                            return this.getDateAccessor(row[column.id], column.format)
                        };
                    } else {
                        if (column.useDrilldown) {
                            const { drilldowns } = this.state;
                            let drilldown = drilldowns[column.id];
                            if (!drilldown) {
                                drilldown = { filter: null, column: column.drilldown, value: null }
                            }
                            if (drilldown) {
                                const { filter } = drilldown;
                                const ddSrc = filter ? filter.split(' > ') : [];
                                const depth = ddSrc.length;
                                column.accessor = row => {
                                    const { drilldowns } = this.state;
                                    let drilldown = drilldowns[column.id];
                                    const { value } = drilldown || {};
                                    window["log"]('Drilldown Team Lead value: ', value);
                                    let val = row[column.id];
                                    const ddCol = row[column.drilldown];
                                    if (ddCol) {
                                        const ddArr = ddCol.split(' > ');
                                        if (ddArr.length >= depth) {
                                            val = ddArr[depth];
                                        }
                                    }
                                    return value || val;
                                }
                            }
                        }
                        if (column.drilldownFrom) {
                            column.accessor = row => {
                                let val = row[column.id];
                                const { drilldowns } = this.state;
                                const drilldown = drilldowns[column.drilldownFrom];
                                if (drilldown) {
                                    const filter = drilldown.filter || row[column.drilldownFrom];
                                    const ddSrc = filter ? filter.split(' > ') : [];
                                    const depth = ddSrc.length;
                                    const ddCol = row[column.drilldownSource];
                                    if (ddCol) {
                                        const ddArr = ddCol.split(' > ');
                                        if (ddArr.length >= depth) {
                                            val = '';
                                            ddArr.map((d, i) => { if (i < depth) { val = `${val}${d} > ` } if (i === depth) { val = `${val}${d}` } });
                                            // val = `${val}${row[column.id]}`
                                        }
                                        if (ddArr.length === depth) {
                                            val = ddCol;
                                        }
                                    }
                                }
                                return val;
                            }
                        }
                    }
                }
            });
        };
        if (columnOptions) {
            columnOptions.map((col) => {
                const { id, Header, format, dataType, aggregation, show, width, isCalculation, displayIndex } = col;
                const column = _.find(columns, { id });
                if (column) {
                    column.Header = Header;
                    if (!isCalculation && !column.Pivot) {
                        column.show = show;
                        column.aggregation = aggregation;
                        column.format = format;
                        column.aggregate = (vals, rows) => { if (aggregation === 'count') { return rows && rows[0] && rows[0]._original ? _.filter(rows, (o) => { return o[column.id] }).length : _.round(_.sum(vals), 2) } else { return (dataType === 'number') && aggregation ? _.round(_[aggregation](vals), 2) : '' } };
                        column.Aggregated = cellInfo => {
                            return <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} ${dataType !== 'text' || aggregation === 'count' ? 'text-center ' : ''}`}>{dataType === 'number' && aggregation ? column.format === 'D' ? this.formatDollars(cellInfo.value) : column.format === 'P' ? this.formatPercentage(cellInfo.value) : cellInfo.value : aggregation === 'count' ? cellInfo.value : ''}</div>
                        }
                        column.Cell = row => {
                            return (
                                <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} ${dataType !== 'text' ? 'text-center ' : ''}`}>{dataType === 'date' ? this.getFormattedDate(row.value, column.format) : column.format === 'D' ? this.formatDollars(row.value) : column.format === 'P' ? this.formatPercentage(row.value) : row.value}</div>
                            )
                        }
                    }
                    column.displayIndex = displayIndex;
                    if (column.dataType === 'date') {
                        column.accessor = row => {
                            return this.getDateAccessor(row[column.id], column.format)
                        };
                    }
                }
            });
        }
        return columns;
    }

    getFormattedDate = (date, format) => {
        let formatted = date;
        const dt = moment(date);
        switch (format) {
            case 'D': {
                formatted = moment(date).format("MM/DD/YYYY")
            }
                break;
            case 'Y': {
                formatted = date
            }
                break;
            case 'Q': {
                formatted = `Q${dt.quarter()} ${dt.format('YYYY')}`
            }
                break;
            case 'M': {
                formatted = `${dt.format("MMMM")} ${dt.format("YYYY")}`
            }
                break;
            case 'W': {
                formatted = `Week #${dt.isoWeek()} ${dt.format("YYYY")}`
            }
                break;
            case 'DW': {
                formatted = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date]
            }
                break;
            case 'T': {
                formatted = dt.format("h:mm A")
            }
                break;
            case 'H': {
                formatted = dt.startOf("hour").format("h:mm A")
            }
                break;
        }
        return formatted
    }

    getDateAccessor = (date, format) => {
        let formatted = date;
        const dt = moment(date);
        switch (format) {
            case 'D': {
                formatted = new Date(moment(date).format("MM/DD/YYYY")).toISOString()
            }
                break;
            case 'Y': {
                formatted = dt.format("YYYY")
            }
                break;
            case 'Q': {
                formatted = new Date(`${dt.startOf('quarter').format("MM/DD/YYYY")}`).toISOString()
            }
                break;
            case 'M': {
                formatted = new Date(`${dt.startOf('month').format("MM/DD/YYYY")}`).toISOString()
            }
                break;
            case 'W': {
                formatted = new Date(dt.startOf('week').format("MM/DD/YYYY")).toISOString()
            }
                break;
            case 'DW': {
                formatted = dt.day()
            }
                break;
            case 'T': {
                formatted = new Date(`1/1/1900 ${dt.local().format("h:mm A")}`).toISOString()
            }
                break;
            case 'H': {
                formatted = new Date(`1/1/1900 ${dt.local().startOf("hour").format("h:mm A")}`).toISOString()
            }
                break;
        }
        return formatted
    }

    addUpdatePivot = (column) => {
        const { columns, pivotBy, selectedColumns } = this.state;
        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }
        const pivot = _.findIndex(pivotBy, (o) => { return o === column.id });
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        column.accessor = column.id;
        if (column.useDrilldown) {
            const { drilldowns } = this.state;
            let drilldown = drilldowns[column.id];
            if (!drilldown) {
                drilldown = { filter: null, column: column.drilldown, value: null }
            }
            if (drilldown) {
                const { filter } = drilldown;
                const ddSrc = filter ? filter.split(' > ') : [];
                const depth = ddSrc.length;
                column.accessor = row => {
                    const { drilldowns } = this.state;
                    let drilldown = drilldowns[column.id];
                    const { value } = drilldown || {};
                    window["log"]('Drilldown Team Lead value: ', value);
                    let val = row[column.id];
                    const ddCol = row[column.drilldown];
                    if (ddCol) {
                        const ddArr = ddCol.split(' > ');
                        if (ddArr.length >= depth) {
                            val = ddArr[depth];
                        }
                    }
                    return value || val;
                }
            }
        }
        if (column.drilldownFrom) {
            column.accessor = row => {
                let val = row[column.id];
                const { drilldowns } = this.state;
                const drilldown = drilldowns[column.drilldownFrom];
                if (drilldown) {
                    const filter = drilldown.filter || row[column.drilldownFrom];
                    const ddSrc = filter ? filter.split(' > ') : [];
                    const depth = ddSrc.length;
                    const ddCol = row[column.drilldownSource];
                    if (ddCol) {
                        const ddArr = ddCol.split(' > ');
                        if (ddArr.length >= depth) {
                            val = '';
                            ddArr.map((d, i) => { if (i < depth) { val = `${val}${d} > ` } if (i === depth) { val = `${val}${d}` } });
                            // val = `${val}${row[column.id]}`
                        }
                        if (ddArr.length === depth) {
                            val = ddCol;
                        }
                    }
                }
                return val;
            }
        }
        if (selected < 0) {
            column.Aggregated = cellInfo => { return <div className="w-full font-bold text-12 text-center"></div> };
            if (['Technician', 'TeamLead', 'TeamMember'].indexOf(column.id) > -1) {
                // column.disableExpander = true;
                // column.accessor = row => this.getTechnicianName(row[column.id]);
                column.Pivot = ({ value }) => <div className={`font-bold ${increaseFontSize ? 'text-16' : 'text-12'}`}>{this.getTechnician(value, column.drilldownFrom)}</div>;
            } else if (['WorkOrder', 'WO'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: this.props.Co, WorkOrder: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else if (['Invoice', 'InvoiceNumber'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditInvoiceDialog({ Co: this.props.Co, InvoiceNumber: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else if (['Customer', 'Cust', 'CustNum', 'Cust #', 'Customer #'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditCustomerDialog({ Co: this.props.Co, Customer: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else if (['PO', 'PONumber', 'PurchaseOrder'].indexOf(column.id) > -1) {
                column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditPurchaseOrderDialog({ Co: this.props.Co, PO: value }) }} className="ml-4 text-12 font-bold align-middle" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
            } else {
                column.Pivot = ({ value }) => <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} ${column.dataType !== 'text' ? 'text-center ' : ''}`}>{column.dataType === 'date' ? this.getFormattedDate(value, column.format) : value}</div>;
            }
            column.displayIndex = selectedColumns.length;
            if (column.dataType === 'date') {
                column.accessor = row => this.getDateAccessor(row[column.id], column.format)
            }
            selectedColumns.push(column);
        } else {
            selectedColumns.splice(selected, 1, column);
        }
        if (pivot < 0) {
            pivotBy.push(column.id);
        }
        const drilldowns = {};
        _.filter(selectedColumns, (o) => o.useDrilldown).forEach((dd) => drilldowns[dd.id] = { filter: null, column: dd.drilldown, value: null });
        this.setState({ ...this.state, pivotBy, selectedColumns, selectedPivot: null, drilldowns });
    }

    removePivot = (column) => {
        const { columns, pivotBy, selectedColumns } = this.state;
        const pivot = _.findIndex(pivotBy, (o) => { return o === column.id });
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected > -1) {
            selectedColumns.splice(selected, 1);
        }
        if (pivot > -1) {
            pivotBy.splice(pivot, 1);
        }
        this.setState({ ...this.state, pivotBy, selectedColumns, selectedPivot: null });
    }

    addUpdateColumn = (column) => {
        const { columns, selectedColumns, pivotBy } = this.state;
        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected < 0) {
            if (!column.isCalculation) {
                column.show = true;
                if (!column.aggregation) {
                    column.aggregation = column.dataType === 'number' ? 'sum' : null;
                }
                column.aggregate = (vals, rows) => { if (column.aggregation === 'count') { return rows && rows[0] && rows[0]._original ? _.filter(rows, (o) => { return o[column.id] }).length : _.round(_.sum(vals), 2) } else { return (column.dataType === 'number') && column.aggregation ? _.round(_[column.aggregation](vals), 2) : '' } };
                column.Aggregated = cellInfo => {
                    return <div className={`w-full font-bold ${increaseFontSize ? 'text-16' : 'text-12'} ${column.dataType !== 'text' || column.aggregation === 'count' ? 'text-center ' : ''}`}>{column.dataType === 'number' && column.aggregation ? column.format === 'D' ? this.formatDollars(cellInfo.value) : column.format === 'P' ? this.formatPercentage(cellInfo.value) : cellInfo.value : column.aggregation === 'count' ? cellInfo.value : ''}</div>
                }
                column.Cell = row => {
                    return (
                        <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} ${column.dataType !== 'text' ? 'text-center ' : ''}`}>{column.dataType == 'date' ? this.getFormattedDate(row.value, column.format) : column.format === 'D' ? this.formatDollars(row.value) : column.format === 'P' ? this.formatPercentage(row.value) : row.value}</div>
                    )
                }
                if (column.dataType === 'date') {
                    column.accessor = row => this.getDateAccessor(row[column.id], column.format)
                }
            }
            column.displayIndex = selectedColumns.length;
            selectedColumns.push(column);
        } else {
            selectedColumns.splice(selected, 1, column);
        }
        this.setState({ ...this.state, selectedColumns, selectedColumn: null });
    }



    removeColumn = (column) => {
        const { columns, selectedColumns, calculations } = this.state;
        const selected = _.findIndex(selectedColumns, (o) => { return o.id === column.id });
        if (selected > -1) {
            let hasCalculation = false;
            calculations.map((calc) => {
                if (calc.Expression.indexOf(`{${column.id}}`) > -1) {
                    hasCalculation = true;
                }
            });
            if (!hasCalculation) {
                selectedColumns.splice(selected, 1);
            } else {
                this.props.showMessage({
                    message: `Unable to remove column - Column is being used by one or more calculations.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            }
        }
        this.setState({ ...this.state, selectedColumns, selectedColumn: null });
    }

    addCalculation = (calculation) => {
        const { calculations, columns, selectedColumns, pivotBy } = this.state;
        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }
        calculation.ID = guid();
        calculations.push(calculation);
        const col = {
            Header: calculation.Title,
            id: calculation.ID,
            calculation: calculation.Expression,
            calculationApplyTo: calculation.applyTo,
            Cell: rowInfo => {
                if (rowInfo.original) {
                    if (!calculation.applyTo || calculation.applyTo === 'O') {
                        let val = '';
                        const { row, original } = rowInfo;
                        try {
                            const exp = `${format(calculation.Expression, row)}`;
                            val = loose(exp)
                            val = isNaN(val) ? val : _.round(val, 2);
                        } catch (ex) {
                            val = ex.message;
                        }
                        row[calculation.id] = val;
                        return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                    } else {
                        return <div></div>
                    }
                } else {
                    if (!calculation.applyTo || calculation.applyTo === 'A') {
                        let val = '';
                        const { row } = rowInfo;
                        try {
                            const exp = `${format(calculation.Expression, row)}`;
                            val = _.round(loose(exp), 2);
                            val = isNaN(val) ? '' : val
                        } catch (ex) {
                            val = ex.message;
                        }
                        row[calculation.id] = val;
                        return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                    } else {
                        return <div></div>
                    }
                }
            },
            accessor: row => {
                let val = row[calculation.id];
                if (!calculation.applyTo || calculation.applyTo === 'O') {
                    try {
                        const exp = `${format(calculation.Expression, row)}`;
                        val = loose(exp);
                    } catch (ex) {
                        val = ex.message;
                    }
                }
                return val;
            },
            aggregate: (vals, rows) => {
                let val = '';
                if (!calculation.applyTo || calculation.applyTo === 'A') {
                    if (rows) {
                        let row = {};
                        columns.map((col) => {
                            if (!col.isCalculation && (!pivotBy || pivotBy.indexOf(col.id) < 0) && col.aggregation) {
                                row[col.id] = col.aggregation === 'count' ? rows && rows[0] && rows[0]._original ? rows.length : _.round(_.sumBy(rows, col.id), 2) : _.round(_[`${col.aggregation}By`](rows, col.id), 2);
                            }
                        });
                        try {
                            const exp = `${format(calculation.Expression, row)}`;
                            val = loose(exp)
                            val = isNaN(val) ? val === 'NaN' ? '' : val : _.round(val, 2);
                        } catch (ex) {
                            val = ex.message;
                        }
                        // window["warn"]('Calc Agg RowInfo', row, rows, val)
                    }
                }
                return val;
            },
            headerClassName: increaseFontSize ? 'font-bold text-16' : 'font-bold text-12',
            isCalculation: true,
        };
        columns.push(col);
        this.setState({ ...this.state, calculation: { ID: null, Title: null, Expression: null, format: null, applyTo: null }, calculations, selectedColumns });
    }

    updateCalculation = (calculation) => {
        const { calculations, columns, selectedColumns, pivotBy } = this.state;
        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }
        const index = _.findIndex(calculations, { ID: calculation.ID });
        if (index > -1) {
            calculations.splice(index, 1, calculation);
            const colIndex = _.findIndex(columns, { id: calculation.ID });
            if (colIndex > -1) {
                const col = columns[colIndex];
                col.Header = calculation.Title;
                col.calculation = calculation.Expression;
                col.calculationApplyTo = calculation.applyTo;
                col.Cell = rowInfo => {
                    if (rowInfo.original) {
                        if (!calculation.applyTo || calculation.applyTo === 'O') {
                            let val = '';
                            const { row, original } = rowInfo;
                            try {
                                const exp = `${format(calculation.Expression, row)}`;
                                val = _.round(loose(exp), 2);
                            } catch (ex) {
                                val = ex.message;
                            }
                            row[calculation.id] = val;
                            return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                        } else {
                            return <div></div>
                        }
                    } else {
                        if (!calculation.applyTo || calculation.applyTo === 'A') {
                            let val = '';
                            const { row } = rowInfo;
                            try {
                                const exp = `${format(calculation.Expression, row)}`;
                                val = _.round(loose(exp), 2);
                            } catch (ex) {
                                val = ex.message;
                            }
                            row[calculation.id] = val;
                            return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                        } else {
                            return <div></div>
                        }
                    }
                };
                col.accessor = row => {
                    let val = row[calculation.id];
                    if (!calculation.applyTo || calculation.applyTo === 'O') {
                        try {
                            const exp = `${format(calculation.Expression, row)}`;
                            val = loose(exp);
                        } catch (ex) {
                            val = ex.message;
                        }
                    }
                    return val;
                };
                col.aggregate = (vals, rows) => {
                    let val = '';
                    if (!calculation.applyTo || calculation.applyTo === 'A') {
                        if (rows) {
                            let row = {};
                            columns.map((col) => {
                                if (!col.isCalculation && (!pivotBy || pivotBy.indexOf(col.id) < 0) && col.aggregation) {
                                    row[col.id] = col.aggregation === 'count' ? rows && rows[0] && rows[0]._original ? rows.length : _.round(_.sumBy(rows, col.id), 2) : _.round(_[`${col.aggregation}By`](rows, col.id), 2);
                                }
                            });
                            try {
                                const exp = `${format(calculation.Expression, row)}`;
                                val = _.round(loose(exp), 2);
                            } catch (ex) {
                                val = ex.message;
                            }
                            // window["warn"]('Calc Agg RowInfo', row, rows, val)
                        }
                    }
                    return val;
                };
                const selIndex = _.findIndex(selectedColumns, { id: calculation.ID });
                if (selIndex > -1) {
                    const sel = selectedColumns[selIndex];
                    sel.Header = calculation.Title;
                    sel.Cell = rowInfo => {
                        if (rowInfo.original) {
                            if (!calculation.applyTo || calculation.applyTo === 'O') {
                                let val = '';
                                const { row, original } = rowInfo;
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = _.round(loose(exp), 2);
                                } catch (ex) {
                                    val = ex.message;
                                }
                                row[calculation.id] = val;
                                return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        } else {
                            if (!calculation.applyTo || calculation.applyTo === 'A') {
                                let val = '';
                                const { row } = rowInfo;
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = _.round(loose(exp), 2);
                                } catch (ex) {
                                    val = ex.message;
                                }
                                row[calculation.id] = val;
                                return <div className={`w-full ${increaseFontSize ? 'text-16' : 'text-12'} font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                            } else {
                                return <div></div>
                            }
                        }
                    };
                    sel.accessor = row => {
                        let val = row[calculation.id];
                        if (!calculation.applyTo || calculation.applyTo === 'O') {
                            try {
                                const exp = `${format(calculation.Expression, row)}`;
                                val = loose(exp);
                            } catch (ex) {
                                val = ex.message;
                            }
                        }
                        return val;
                    };
                    sel.aggregate = (vals, rowInfo) => {
                        let val = '';
                        if (!calculation.applyTo || calculation.applyTo === 'A') {
                            const { row } = rowInfo;
                            if (row) {
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = _.round(loose(exp), 2);
                                } catch (ex) {
                                    val = ex.message;
                                }
                            }
                        }
                        return val;
                    };
                }
            }
        }
        this.setState({ ...this.state, calculation: {}, calculations, columns, selectedColumns });
    }

    removeCalculation = (calculation) => {
        const { calculations, columns, selectedColumns } = this.state;
        const index = _.findIndex(calculations, { ID: calculation.ID });
        if (index > -1) {
            const colIndex = _.findIndex(columns, { id: calculation.ID });
            if (colIndex > -1) {
                const selIndex = _.findIndex(selectedColumns, { id: calculation.ID });
                if (selIndex > -1) {
                    selectedColumns.splice(selIndex, 1);
                }
                columns.splice(colIndex, 1);
            }
            calculations.splice(index, 1);
        }
        this.setState({ ...this.state, calculation: {}, calculations, columns, selectedColumns });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (!isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    getTechnician = (tech, parse) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const parseArr = tech.split(' > ');
        const Technician = !parse ? tech : parseArr[parseArr.length - 1];
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return (
                <span className="flex">
                    <Avatar style={{ marginRight: 8, marginLeft: 8, width: 24, height: 24, }} classes={{ root: classes.avatarRoot }} className={classes.profileAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                    <div className="mt-6">{`${value.FirstName} ${value.LastName}`}</div>
                    <Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditTechnicianDialog({ Co: this.props.Co, Technician }) }} className="ml-4 text-12 font-bold align-middle mt-8">open_in_new</Icon></Tooltip>
                </span>
            )
        } else {
            return Technician;
        }
    }

    getTechnicianName = (Technician) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return `${value.FirstName} ${value.LastName}`;
        } else {
            return Technician;
        }
    }

    reorderColumns = (result) => {
        const { source, destination } = result;
        if (source && destination) {
            if (source.index !== destination.index) {

                const { selectedColumns } = this.state;

                const ordered = this.reorder(
                    selectedColumns,
                    result.source.index,
                    result.destination.index
                );

                let pivotBy = [];
                for (var i = 0; i < ordered.length; i++) {
                    ordered[i].displayIndex = i;
                    if (_.findIndex(this.state.pivotBy, (p) => { return ordered[i].id === p }) > -1) {
                        pivotBy.push(ordered[i].id);
                    }
                }

                this.setState({ ...this.state, selectedColumns: ordered, pivotBy });
            }
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    expandAll = () => {
        let { selectedColumns } = this.state;
        const expanded = selectedColumns.map(x => true);
        this.setState({ ...this.state, expanded });
    }

    collapseAll = () => {
        let { selectedColumns } = this.state;
        const expanded = selectedColumns.map(x => false);
        this.setState({ ...this.state, expanded });
    }

    resizeColumn = _.debounce((newResized, event) => {
        this.setState({ ...this.state, resized: newResized });
    }, 1500);

    getExpanded = (recs, exp) => {
        if (recs && exp) {
            recs.map((val, index) => {
                if (val._subRows) {
                    exp[index] = this.getExpanded(val._subRows, {});
                } else {
                    exp[index] = {};
                }
            })
        }
        return exp;
    }

    expandRows = () => {
        let exp = {};
        if (this.pivotRef) {
            const tableState = this.pivotRef.getResolvedState();
            const recs = tableState.sortedData;
            exp = this.getExpanded(recs, exp);
        }
        return exp;
    }

    findExpanded = (obj, col, data) => {
        const { selectedColumns, drilldowns, results } = this.state;
        if (obj) {
            col += 1;
            Object.keys(obj).forEach((key) => {
                window["log"]('Drilldown info: ', data, obj, key, col);
                if (obj[key]) {
                    const drilldown = drilldowns[selectedColumns[col].drilldownFrom];
                    if (selectedColumns[col].drilldownFrom && selectedColumns[col].drilldownSource && drilldown) {
                        const filter = data[key]['_pivotVal'];
                        const ddArr = filter.split(' > ');
                        const value = ddArr[ddArr.length - 1];
                        window["log"]('Drilldown Filter found: ', [selectedColumns[col].drilldownFrom], data[key], filter, value);
                        if (drilldown.value !== value) {
                            if (_.filter(results, (o) => o[drilldown.column].startsWith(filter) && o[selectedColumns[col].drilldownFrom] === value).length > 0) {
                                this.setState({ drilldowns: { ...drilldowns, [selectedColumns[col].drilldownFrom]: { ...drilldown, filter, value } }, newExpanded: { 0: { 0: {} } } });
                            }
                        }
                    } else {
                        window["log"]('Drilldown Next info: ', obj[key], col, data[key]);
                        if (data[key] && data[key]['_subRows']) {
                            this.findExpanded(obj[key], col, data[key]['_subRows']);
                        }
                    }
                }
            })
        }
    }

    filterResults = () => {
        const { drilldowns, results } = this.state;
        let res = _.cloneDeepWith(results)
        Object.keys(drilldowns).forEach((key) => {
            const drilldown = drilldowns[key];
            if (drilldown) {
                const { column, filter } = drilldown;
                res = _.filter(res, (o) => !filter || o[column].startsWith(filter));
            }
        });
        return res;
    }

    drillUp = (nav) => {
        const { filter, column, value, name } = nav;
        const { drilldowns } = this.state;
        let newFilter = null;
        let newValue = null;
        let ddArr = [];
        if (filter) {
            ddArr = filter.split(' > ');
            if (ddArr.length > 1) {
                ddArr.splice(ddArr.length - 1, 1);
                newFilter = '';
                ddArr.forEach((v, index) => { newFilter += `${v}${(index < ddArr.length - 1 ? ' > ' : '')}` });
                newValue = ddArr[ddArr.length - 1];
            }
        }
        this.setState({ drilldowns: { ...drilldowns, [name]: { filter: newFilter, column, value: newValue } } });
    }

    formatDrilldownFilters = (filter, id) => {
        if (filter) {
            const { drilldowns } = this.state;
            const drilldown = drilldowns[id];
            const ddArr = filter.split(' > ');
            let newFilter = '';
            let newFilters = [];
            ddArr.forEach((val, index) => { newFilter += `${index > 0 ? ' > ' : ''}${val}`; newFilters.push(_.cloneDeepWith(newFilter)); });
            window["log"]('New Drilldown Filters: ', newFilters);
            if (['Technician', 'TeamLead', 'TeamMember'].indexOf(id) > -1) {
                return (
                    <div className="flex">
                        {ddArr.map((val, index) => <div onClick={(e) => { e.stopPropagation(); this.setState({ drilldowns: { ...drilldowns, [id]: { ...drilldown, value: val, filter: newFilters[index] } } }) }} className="flex">{this.getBreadcrumbTechnician(val)}{index < ddArr.length - 1 ? <Icon className="mx-4 align-middle" color="action">keyboard_arrow_right</Icon> : ''}</div>)}
                    </div>
                );
            } else {
                return filter;
            }
        } else {
            return filter;
        }
    }

    getBreadcrumbTechnician = (Technician) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return (
                <span className="flex mt-2">
                    <Avatar style={{ marginRight: 4, width: 18, height: 18, }} classes={{ root: classes.avatarRoot }} className={classes.profileAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                    <div>{`${value.FirstName} ${value.LastName}`}</div>
                </span>
            )
        } else {
            return Technician;
        }
    }

    render() {
        const { classes, edit, dialog, filters, reportCategory, viewer, getTable, hidden, height, dashboard } = this.props;
        const { columns, selectedColumns, pivotBy, calculations, calculation, selectedColumn, selectedPivot, resized, expanded, newExpanded, viewPivots, viewColumns, viewCalculations } = this.state;
        const exp = this.expandRows();
        window["warn"]('Rerendering Table: ', this.state, this.props)
        const results = this.filterResults();
        const { drilldowns } = this.state;
        let nav = null;
        Object.keys(drilldowns).forEach((dd) => { if (drilldowns[dd].filter) { nav = { ...drilldowns[dd], name: dd } } });

        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }

        return (
            <div className={hidden ? "hidden" : ""}>
                <div className="w-full">
                    {edit &&
                        <div className="w-full sm:flex">
                            <div className="w-full mb-12">
                                <Typography onClick={() => this.setState({ ...this.state, viewPivots: !viewPivots })} className="mb-4 w-full font-bold text-12 cursor-pointer">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">format_list_bulleted</Icon>Group By<Icon color="action" className="mr-4 align-middle text-20 float-right">keyboard_arrow_{viewPivots ? "up" : "down"}</Icon><Avatar className={classNames(classes.avatar, "mr-4 float-right")}>{pivotBy.length}</Avatar>
                                </Typography>
                                <Autocomplete
                                    className="w-full mb-12"
                                    title="Column"
                                    options={_.filter(columns, (o) => { return !o.isCalculation && !o.hidden })}
                                    menuItemComponent={(option) => {
                                        return (
                                            <MenuItem value={option.id}>
                                                <div className="flex">
                                                    {option.id}
                                                </div>
                                            </MenuItem>
                                        );
                                    }}
                                    portal={true}
                                    value={selectedPivot ? selectedPivot.id : null}
                                    onSelect={(option) => this.addUpdatePivot(option)}
                                />
                                {selectedPivot &&
                                    <React.Fragment>
                                        <TextField
                                            className={classes.formControl}
                                            label="Title"
                                            id="title"
                                            name="selectedPivot.Header"
                                            value={selectedPivot.Header || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            fullWidth
                                        />
                                        {selectedPivot.dataType === 'date' &&
                                            <TextField
                                                className={classes.formControl}
                                                label="Date Format"
                                                id="format"
                                                name="selectedPivot.format"
                                                value={selectedPivot.format || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                select
                                            >
                                                <MenuItem value={null}>
                                                    None
                                                </MenuItem>
                                                <MenuItem value="D">
                                                    Date
                                                </MenuItem>
                                                <MenuItem value="Y">
                                                    Year
                                                </MenuItem>
                                                <MenuItem value="Q">
                                                    Quarter
                                                </MenuItem>
                                                <MenuItem value="M">
                                                    Month
                                                </MenuItem>
                                                <MenuItem value="W">
                                                    Week #
                                                </MenuItem>
                                                <MenuItem value="DW">
                                                    Day of Week
                                                </MenuItem>
                                                <MenuItem value="T">
                                                    Time
                                                </MenuItem>
                                                <MenuItem value="H">
                                                    Hour
                                                </MenuItem>
                                            </TextField>
                                        }
                                        {selectedPivot.canDrilldown &&
                                            <FormControlLabel
                                                // style={{ float: 'right', }}
                                                control={
                                                    <Switch
                                                        checked={selectedPivot.useDrilldown}
                                                        name="selectedPivot.useDrilldown"
                                                        onChange={this.handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Use Drilldown"
                                            />
                                        }
                                        <Button
                                            variant="contained"
                                            className={classNames(classes.black, "mb-12")}
                                            color="primary"
                                            onClick={() => this.addUpdatePivot(selectedPivot)}
                                            fullWidth
                                        >
                                            Update
                                        </Button>
                                    </React.Fragment>
                                }
                                {viewPivots &&
                                    <DragDropContext onDragEnd={this.reorderColumns}>
                                        <Droppable
                                            droppableId="pivots"
                                            type="pivot"
                                            direction="vertical"
                                        // ignoreContainerClipping={800}
                                        >
                                            {(provided) => (
                                                <div ref={provided.innerRef}>
                                                    {selectedColumns.map((value) => {
                                                        if (_.findIndex(pivotBy, (p) => { return value.id === p }) > -1) {
                                                            return (
                                                                <Draggable key={value.id} draggableId={value.id} index={value.displayIndex} type="pivot">
                                                                    {(provided2, snapshot) => (
                                                                        <div
                                                                            ref={provided2.innerRef}
                                                                            {...provided2.draggableProps}
                                                                            {...provided2.dragHandleProps}
                                                                        >
                                                                            <Chip key={value} onClick={() => this.setState({ ...this.state, selectedPivot: value })} deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removePivot(value)} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classes.colorSecondary}>arrow_right</Icon>} label={<span>{value.Header}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>} />
                                                                            {provided2.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            )
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                }
                            </div>
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <div className="w-full mb-12">
                                <Typography onClick={() => this.setState({ ...this.state, viewColumns: !viewColumns })} className="mb-4 w-full font-bold text-12 cursor-pointer">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">format_list_numbered</Icon>Columns<Icon color="action" className="mr-4 align-middle text-20 float-right">keyboard_arrow_{viewColumns ? "up" : "down"}</Icon><Avatar className={classNames(classes.avatar, "mr-4 float-right")}>{selectedColumns.length}</Avatar>
                                </Typography>
                                <Autocomplete
                                    className="w-full mb-12"
                                    title="Column"
                                    options={_.filter(columns, (o) => { return _.findIndex(pivotBy, (p) => { return o.id === p }) < 0 && !o.hidden })}
                                    menuItemComponent={(option) => {
                                        return (
                                            <MenuItem value={option.id}>
                                                <div className="flex">
                                                    {option.Header || option.id}
                                                </div>
                                            </MenuItem>
                                        );
                                    }}
                                    portal={true}
                                    value={selectedColumn ? selectedColumn.id : null}
                                    onSelect={(option) => this.addUpdateColumn(option)}
                                />
                                {
                                    selectedColumn &&
                                    <React.Fragment>
                                        <TextField
                                            className={classes.formControl}
                                            label="Title"
                                            id="title"
                                            name="selectedColumn.Header"
                                            value={selectedColumn.Header || ''}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <TextField
                                            className={classes.formControl}
                                            label="Type"
                                            id="dataType"
                                            name="selectedColumn.dataType"
                                            value={selectedColumn.dataType || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="text">
                                                Text
                                            </MenuItem>
                                            <MenuItem value="number">
                                                Numeric
                                            </MenuItem>
                                            <MenuItem value="date">
                                                Date
                                            </MenuItem>
                                        </TextField>
                                        {selectedColumn.dataType === 'date' ?
                                            <TextField
                                                className={classes.formControl}
                                                label="Date Format"
                                                id="format"
                                                name="selectedColumn.format"
                                                value={selectedColumn.format || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                select
                                            >
                                                <MenuItem value={null}>
                                                    None
                                                </MenuItem>
                                                <MenuItem value="D">
                                                    Date
                                                </MenuItem>
                                                <MenuItem value="Y">
                                                    Year
                                                </MenuItem>
                                                <MenuItem value="Q">
                                                    Quarter
                                                </MenuItem>
                                                <MenuItem value="M">
                                                    Month
                                                </MenuItem>
                                                <MenuItem value="W">
                                                    Week #
                                                </MenuItem>
                                                <MenuItem value="DW">
                                                    Day of Week
                                                </MenuItem>
                                                <MenuItem value="T">
                                                    Time
                                                </MenuItem>
                                                <MenuItem value="H">
                                                    Hour
                                                </MenuItem>
                                            </TextField> :
                                            <TextField
                                                className={classes.formControl}
                                                label="Format"
                                                id="format"
                                                name="selectedColumn.format"
                                                value={selectedColumn.format || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                select
                                            >
                                                <MenuItem value={null}>
                                                    None
                                                </MenuItem>
                                                <MenuItem value="T">
                                                    Text
                                                </MenuItem>
                                                <MenuItem value="P">
                                                    Percentage
                                                </MenuItem>
                                                <MenuItem value="D">
                                                    Dollars
                                                </MenuItem>
                                            </TextField>
                                        }
                                        <TextField
                                            className={classes.formControl}
                                            label="Aggregation Type"
                                            id="aggregation"
                                            name="selectedColumn.aggregation"
                                            value={selectedColumn.aggregation || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value={null}>
                                                None
                                            </MenuItem>
                                            <MenuItem value="sum">
                                                Sum
                                            </MenuItem>
                                            <MenuItem value="count">
                                                Count
                                            </MenuItem>
                                            <MenuItem value="mean">
                                                Average
                                            </MenuItem>
                                            <MenuItem value="min">
                                                Minimum
                                            </MenuItem>
                                            <MenuItem value="max">
                                                Maximum
                                            </MenuItem>
                                        </TextField>
                                        <FormControlLabel
                                            // style={{ float: 'right', }}
                                            control={
                                                <Switch
                                                    checked={!selectedColumn.show}
                                                    name="selectedColumn.show"
                                                    onChange={() => { this.setState({ ...this.state, selectedColumn: { ...selectedColumn, show: !selectedColumn.show } }) }}
                                                    color="primary"
                                                />
                                            }
                                            label="Hidden"
                                        />
                                        <Button
                                            variant="contained"
                                            className={classNames(classes.black, "mb-12")}
                                            color="primary"
                                            onClick={() => this.addUpdateColumn(selectedColumn)}
                                            fullWidth
                                        >
                                            Update
                                        </Button>
                                    </React.Fragment>
                                }
                                {viewColumns &&
                                    <DragDropContext onDragEnd={this.reorderColumns}>
                                        <Droppable
                                            droppableId="columns"
                                            type="column"
                                            direction="vertical"
                                        // ignoreContainerClipping={800}
                                        >
                                            {(provided) => (
                                                <div ref={provided.innerRef}>
                                                    {selectedColumns.map((value, index) => {
                                                        if (_.findIndex(pivotBy, (p) => { return value.id === p }) < 0) {
                                                            return (
                                                                <Draggable key={value.id} draggableId={value.id} index={value.displayIndex} type="column">
                                                                    {(provided2, snapshot) => (
                                                                        <div
                                                                            ref={provided2.innerRef}
                                                                            {...provided2.draggableProps}
                                                                            {...provided2.dragHandleProps}
                                                                        >
                                                                            <Chip
                                                                                onClick={() => {
                                                                                    if (!value.isCalculation) {
                                                                                        if (calculation && calculation.Title && calculation.Title.length > 0) {
                                                                                            let { Expression } = calculation;
                                                                                            if (Expression) {
                                                                                                Expression += `{${value.id}}`;
                                                                                            } else {
                                                                                                Expression = `{${value.id}}`;
                                                                                            }
                                                                                            this.setState({ ...this.state, calculation: { ...calculation, Expression } });
                                                                                        } else {
                                                                                            this.setState({ ...this.state, selectedColumn: value })
                                                                                        }
                                                                                    } else {
                                                                                        this.setState({ ...this.state, calculation: _.find(calculations, { ID: value.id }) })
                                                                                    }
                                                                                }}
                                                                                deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>}
                                                                                onDelete={() => this.removeColumn(value)}
                                                                                className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")}
                                                                                icon={<Icon className={classNames(!value.show && !value.isCalculation ? classes.colorError : classes.colorSecondary, "text-16")}>{value.isCalculation ? 'functions' : !value.show ? 'visibility_off' : 'format_list_numbered'}</Icon>}
                                                                                label={<span>{value.Header}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            )
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                }
                            </div>
                            <div className="hidden sm:block min-w-12 pt-20">
                            </div>
                            <div className="w-full mb-12">
                                <Typography onClick={() => this.setState({ ...this.state, viewCalculations: !viewCalculations })} className="mb-4 w-full font-bold text-12 cursor-pointer">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">functions</Icon>Calculations<Icon color="action" className="mr-4 align-middle text-20 float-right">keyboard_arrow_{viewCalculations ? "up" : "down"}</Icon><Avatar className={classNames(classes.avatar, "mr-4 float-right")}>{calculations.length}</Avatar>
                                </Typography>
                                <TextField
                                    className={classNames("mt-10", classes.formControl)}
                                    label="Title"
                                    id="title"
                                    name="calculation.Title"
                                    value={calculation.Title || ''}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        autoComplete: "off"
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                                {calculation.Title && calculation.Title.length > 0 &&
                                    <React.Fragment>
                                        <TextField
                                            className={classes.formControl}
                                            label="Expression"
                                            id="expression"
                                            name="calculation.Expression"
                                            value={calculation.Expression || ''}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">=</InputAdornment>,
                                            }}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                        <TextField
                                            className={classes.formControl}
                                            label="Format"
                                            id="format"
                                            name="calculation.format"
                                            value={calculation.format || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value={null}>
                                                None
                                            </MenuItem>
                                            <MenuItem value="T">
                                                Text
                                            </MenuItem>
                                            <MenuItem value="P">
                                                Percentage
                                            </MenuItem>
                                            <MenuItem value="D">
                                                Dollars
                                            </MenuItem>
                                        </TextField>
                                        <TextField
                                            className={classes.formControl}
                                            label="Apply To"
                                            id="applyTo"
                                            name="calculation.applyTo"
                                            value={calculation.applyTo || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value={null}>
                                                All Rows
                                            </MenuItem>
                                            <MenuItem value="A">
                                                Aggregated Rows
                                            </MenuItem>
                                            <MenuItem value="O">
                                                Original Rows
                                            </MenuItem>
                                        </TextField>
                                        {!calculation.ID ?
                                            <Button
                                                variant="contained"
                                                className={classNames(classes.black, "mb-12")}
                                                color="primary"
                                                onClick={() => this.addCalculation(calculation)}
                                                fullWidth
                                                disabled={!calculation.Expression}
                                            >
                                                <Icon className={classNames("mr-4 text-20", calculation.Expression ? classes.colorSecondary : '')}>add_circle_outline</Icon>Add Calculation
                                            </Button> :
                                            <Button
                                                variant="contained"
                                                className={classNames(classes.black, "mb-12")}
                                                color="primary"
                                                onClick={() => this.updateCalculation(calculation)}
                                                fullWidth
                                                disabled={!calculation.Expression}
                                            >
                                                Update
                                            </Button>
                                        }

                                    </React.Fragment>
                                }
                                {viewCalculations && calculations.map((value) => {
                                    return (
                                        <Tooltip key={value.ID} placement="top" title={`=${value.Expression}`}>
                                            <Chip deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removeCalculation(value)} onClick={() => this.setState({ ...this.state, calculation: { ...value } })} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>functions</Icon>} label={<span>{value.Title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>} />
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    {nav && nav.filter && nav.filter.length > 0 &&
                        <React.Fragment>
                            <Divider className="mb-16" />
                            <Typography onClick={() => this.drillUp(nav)} className="w-full px-4 font-bold text-14 cursor-pointer mb-6 relative flex">
                                <Icon color="primary" className="mr-4 align-middle mb-4">keyboard_arrow_left</Icon>
                                {this.formatDrilldownFilters(nav.filter, nav.name)}
                            </Typography>
                        </React.Fragment>
                    }
                    <ReactTable
                        data={results}
                        columns={[...selectedColumns]}
                        ref={el => { this.pivotRef = el; if (getTable) { getTable(el) } }}
                        defaultPageSize={10}
                        // showPagination={false}
                        defaultResized={resized}
                        expanded={expanded ? exp : newExpanded ? newExpanded : undefined}
                        className={!dashboard ? `-highlight -striped rounded ${increaseFontSize ? "text-16" : "text-14"}  ${viewer ? 'mt-12' : ''}` : `-highlight -striped ${!edit && !dialog ? 'pin-t pin-b pin-r pin-l absolute mt-32' : ''}`}
                        style={this.props.maxHeight ? { maxHeight: this.props.maxHeight, overflowY: 'auto' } : undefined}
                        onResizedChange={this.resizeColumn}
                        onExpandedChange={(newExpanded, event) => {
                            const { selectedColumns, drilldowns } = this.state;
                            this.setState({ ...this.state, newExpanded });
                        }}
                        pivotBy={[...pivotBy]}
                        getTbodyProps={(state, rowInfo, column) => {
                            window["log"]('TBody Props: ', state, this.state);
                            const { expanded, sortedData } = state;
                            this.findExpanded(expanded, -1, sortedData);
                            return {

                            }
                        }}
                    />
                </div>
                {edit &&
                    <DialogActions className="dialog-actions justify-between p-0 m-0 mt-12">
                        {!this.props.options || !this.props.options.ID || !this.props.options.Report ?
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.saveReport}
                            >
                                Add
                            </Button> :
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.saveReport}
                            >
                                Update
                            </Button>
                        }
                        {this.props.onDelete &&
                            <IconButton
                                onClick={this.removeReport}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        }

                    </DialogActions>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditWorkOrderDialog,
        openEditInvoiceDialog,
        openEditCustomerDialog,
        openEditCustomerSiteDialog,
        openEditTechnicianDialog,
        openEditPurchaseOrderDialog,
        showMessage,
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        employees: spReducers.employees,
        userPreferences: spReducers.userProfiles.User.Preferences,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PivotTable)));
