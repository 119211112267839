import _ from '@lodash';
import { FuseAnimateGroup } from '@fuse';
import { AppBar, Button, Dialog, DialogActions, DialogContent, Grow, Icon, IconButton, InputAdornment, MobileStepper, Slide, Slider, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import "cropperjs/dist/cropper.css";
import React, { Component } from 'react';
import Cropper from "react-cropper";
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swipeable from "react-swipeable";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import getProfileImage from '../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    typeIcon: {
        '&.folder:before': {
            content: "'folder'",
            color: '#FFB300'
        },
        '&.document:before': {
            content: "'insert_drive_file'",
            color: '#1565C0'
        },
        '&.spreadsheet:before': {
            content: "'insert_chart'",
            color: '#4CAF50'
        }
    }
});

const newAttachmentState = {
    edit: false,
    attachment: null,
    open: false,
    cropper: null,
    notes: null,
    cropped: null,
    fileName: null,
    type: null,
    crop: false,
    drag: true,
    flipX: false,
    flipY: false,
    setRotation: false,
    animation: "transition.slideRightIn",
    confirmDelete: false,
    confirmCancel: false,
}

class AttachmentDialog extends Component {
    state = _.cloneDeepWith(newAttachmentState)

    componentDidMount() {
        const { edit, open, attachment, notes, file } = this.props;
        let { fileName, type } = (file || {});
        if (attachment) {
            fileName = attachment.FileName.replace(attachment.Extension, '');
        }
        this.setState({ attachment, edit, open, notes, fileName, type });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { edit, open, attachment, file, notes } = this.props;
        let { fileName, type } = (file || {});
        const initialState = _.cloneDeepWith(newAttachmentState);
        if (attachment) {
            fileName = attachment.FileName.replace(attachment.Extension, '');
        }
        if (!_.isEqual(attachment, prevProps.attachment) || !_.isEqual(open, prevProps.open) || !_.isEqual(notes, prevProps.notes)) {
            this.setState({ ...initialState, animation: this.state.animation, attachment, edit, open, fileName, type, notes });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    onClose = () => {
        const { edit, cropper, attachment } = this.state;
        if (edit) {
            if (!attachment) {
                if (!this.state.confirmCancel) {
                    this.setState({ confirmCancel: true });
                } else {
                    const { onClose } = this.props;
                    if (onClose) {
                        this.setState({ animation: "transition.slideRightIn" }, () => {
                            if (cropper) {
                                cropper.destroy();
                            }
                            onClose();
                        })
                    }
                }
            } else {
                this.setState({ edit: false });
            }
        } else {
            const { onClose } = this.props;
            if (onClose) {
                this.setState({ animation: "transition.slideRightIn" }, () => {
                    if (cropper) {
                        cropper.destroy();
                    }
                    onClose();
                })
            }
        }
    }

    handleNext = () => {
        const { onChange, attachments, attachment } = this.props;
        if (onChange) {
            if (attachment && attachments) {
                const imgs = _.filter(attachments, (o) => [".png", ".jpg", ".bmp", ".jpeg"].indexOf(o.Extension) > -1);
                const index = _.findIndex(attachments, { Attachment: attachment.Attachment });
                if (index < (imgs.length - 1)) {
                    this.setState({ animation: "transition.slideRightIn" }, () => {
                        onChange(imgs[index + 1]);
                    })
                }
            }
        }
    }

    handlePrev = () => {
        const { onChange, attachments, attachment } = this.props;
        if (onChange) {
            if (attachment && attachments) {
                const imgs = _.filter(attachments, (o) => [".png", ".jpg", ".bmp", ".jpeg"].indexOf(o.Extension) > -1);
                const index = _.findIndex(attachments, { Attachment: attachment.Attachment });
                if (index > 0) {
                    this.setState({ animation: "transition.slideLeftIn" }, () => {
                        onChange(imgs[index - 1]);
                    })
                }
            }
        }
    }

    handleSave = () => {
        const { cropper, fileName, notes, attachment } = this.state;
        if (cropper) {
            const canv = cropper.getCroppedCanvas();
            const cropped = (cropper.getCroppedCanvas().toDataURL('image/jpeg', .5));
            var size = (cropped.length / 1024).toFixed(2) + " KB";
            window["warn"]('New Image File Size: ', size);
            this.setState({ cropped }, async () => {
                const { onSave } = this.props;
                if (onSave) {
                    const b64 = await fetch(cropped);
                    const blob = await b64.blob();
                    const file = new File([blob], `${this.state.fileName}.jpg`, { type: `image/jpeg` });
                    onSave(file, notes);
                }
            });
        } else {
            const { onSave } = this.props;
            if (onSave) {
                onSave(`${fileName}${attachment.Extension}`, notes);
            }
        }
    };

    afterSave = () => {
        this.setState({ edit: false });
    }

    deleteAttachment = () => {
        const { attachment } = this.state;
        const { onDelete } = this.props;
        if (onDelete) {
            onDelete(attachment);
        }
    }

    render() {
        const { classes, files, selectedItem, setSelectedItem, pageLayout, attachments, onAdded, accept, file, readOnly } = this.props;
        const { edit, attachment, open, cropper, notes, cropped, crop, drag, flipX, flipY, zoom, setRotation, setZoom, rotate } = this.state;
        const ext = { "png": "image", "bmp": "image", "jpg": "image", "jpeg": "image", "pdf": "insert_drive_file", "doc": "insert_drive_file", "txt": "insert_drive_file", "xls": "insert_chart", "xlsm": "insert_chart", "csv": "insert_chart" };
        const imgs = _.filter(attachments || [], (o) => [".png", ".jpg", ".bmp", ".jpeg"].indexOf(o.Extension) > -1);
        const isImg = [".png", ".jpg", ".bmp", ".jpeg"].indexOf((attachment || {}).Extension) > -1 || (file && file.type.split("/")[0] === "image");
        const index = _.findIndex(attachments || [], (o) => attachment && attachment.Attachment === o.Attachment);

        if (!attachment && !file) {
            return '';
        }
        return (
            <Media queries={{ small: "(min-width: 600px)", medium: "(min-width: 1200px)"/**/ }}>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches.medium ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: classNames("relative", !matches.medium && (edit || !isImg) ? "full-screen-dialog" : classes.paper, !edit && "bg-transparent")
                        }}
                        className={classes.root}
                        open={open && Boolean(attachment || file)}
                        onClose={this.onClose}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches.medium && (edit || !isImg)}
                    >
                        {/* <FuseAnimateGroup enter={{ animation: this.state.animation }} delay={300}>
                            <div key={attachment ? attachment.Attachment : new Date()}> */}
                        <AppBar position="static" elevation={1} className={(edit || !isImg) && "dialog-header"}>
                            {(edit || !isImg) &&
                                <Toolbar className="flex w-full">
                                    <Typography variant="subtitle1" color="inherit">
                                        {
                                            edit ?
                                                <Icon onClick={() => this.setState({ edit: false })} className=" text-32 mb-2 mr-8 align-middle cursor-pointer">keyboard_arrow_left</Icon> :
                                                <Icon className=" text-28 mb-2 mr-8 align-middle cursor-pointer" style={{ transform: 'rotate(-90deg)' }}>attachment</Icon>
                                        }
                                        Attachment {edit ? "Editor" : "Viewer"}
                                    </Typography>
                                </Toolbar>
                            }
                            {!edit && !readOnly &&
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: 56,
                                        top: matches.medium ? 8 : 4,
                                        color: 'white',
                                        textShadow: !edit && isImg ? '2px 2px 6px rgb(0 0 0 /75%)' : undefined
                                    }}
                                    onClick={() => this.setState({ edit: true })}
                                    className={(edit || !isImg) && "dialog-header-icon"}
                                >
                                    <Icon>edit</Icon>
                                </IconButton>
                            }
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: matches.medium ? 8 : 4,
                                    color: 'white',
                                    textShadow: !edit && isImg ? '2px 2px 6px rgb(0 0 0 /75%)' : undefined
                                }}
                                onClick={this.onClose}
                                className={(edit || !isImg) && "dialog-header-icon"}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        {/* </div>
                        </FuseAnimateGroup> */}
                        {isImg && attachment && attachment.FileName && !edit &&
                            <div className="absolute pin-t pin-l w-full p-12 px-16 text-white h-auto text-16 leading-normal font-bold max-w-1/2 truncate z-10" style={{ textShadow: '2px 2px 6px rgb(0 0 0 / 75%)' }}>
                                {attachment.FileName}
                            </div>
                        }
                        {isImg && imgs.length > 1 && !edit &&
                            <div className=" px-16 w-full h-36 absolute pin-t pin-b m-auto z-10">
                                {index > 0 &&
                                    <Icon onClick={this.handlePrev} className="float-left text-36 cursor-pointer text-white" style={{ textShadow: '2px 2px 6px rgb(0 0 0 / 75%)' }}>keyboard_arrow_left</Icon>
                                }
                                {index < (imgs.length - 1) &&
                                    <Icon onClick={this.handleNext} className="float-right text-36 cursor-pointer text-white" style={{ textShadow: '2px 2px 6px rgb(0 0 0 / 75%)' }}>keyboard_arrow_right</Icon>
                                }
                            </div>
                        }
                        {isImg && this.state.notes && !edit &&
                            <div className="absolute pin-b w-full p-12 text-center text-white mb-36 h-auto text-16 leading-normal z-10 max-h-128 overflow-y-auto" style={{ textShadow: '2px 2px 6px rgb(0 0 0 / 75%)' }}>
                                {this.state.notes}
                            </div>
                        }
                        <DialogContent classes={{ root: !edit && "p-0 relative overflow-x-hidden" }} style={{ lineHeight: !edit ? 0 : undefined }}>
                            {attachment && !isImg &&
                                <iframe className="w-full bg-white mb-12 h-512 overflow-auto" style={{ height: !edit ? !matches.small ? 'calc(100vh - 56px)' : !matches.medium ? 'calc(100vh - 64px)' : 'calc(100vh - 128px)' : undefined, marginBottom: !edit ? 0 : undefined }} src={`${window["apiLocation"]}/api/Attachment/GetAttachment/${this.props.Co}/${encodeURIComponent(attachment.AttachmentID)}/${attachment.Attachment}/${attachment.FileName}`} />
                                // Boolean(attachment) && attachment.Extension === ".pdf" ?
                                //     <div className="w-full bg-white mb-12 h-512" style={{ height: !edit ? 'calc(100vh - 128px)' : undefined, marginBottom: !edit ? 0 : undefined }}>
                                //         <object className="w-full overflow-auto rounded h-auto" style={{ height: !edit ? 'calc(100vh - 128px)' : undefined }} data={`${window["apiLocation"]}/api/Attachment/GetAttachment/${this.props.Co}/${encodeURIComponent(attachment.AttachmentID)}/${attachment.Attachment}/${attachment.FileName}`} type="application/pdf">
                                //             <div>No online PDF viewer installed</div>
                                //         </object>
                                //     </div> :
                                //     <div className="w-full p-16">
                                //         <div className="w-full min-h-512 rounded-lg p-12 relative" style={{ border: '1px dotted lightgrey' }}>
                                //             <div className="w-full text-center h-16 font-bold pin-t pin-b absolute m-auto text-center">Preview Not Available</div>
                                //         </div>
                                //     </div> : ''
                            }
                            {!edit ?
                                <Swipeable onSwipedLeft={!edit ? this.handleNext : undefined} onSwipedRight={!edit ? this.handlePrev : undefined}>
                                    <FuseAnimateGroup enter={{ animation: this.state.animation }} delay={300}>
                                        <div key={attachment ? attachment.Attachment : new Date()} className="relative">
                                            {isImg &&
                                                <img className="w-full rounded-lg" src={cropped || (file ? file.data : null) || (attachment && !isNaN(attachment.Attachment) ? getProfileImage(`Co=${this.props.Co}&ID=${attachment.Attachment}&Timestamp=${new Date(attachment.UpdatedDate || attachment.AddedDate).getTime()}` ): attachment.Attachment)} />
                                            }
                                        </div>
                                    </FuseAnimateGroup>
                                </Swipeable> :
                                <div className="w-full">
                                    {isImg &&
                                        <Cropper
                                            onInitialized={(cropper) => this.setState({ cropper })}
                                            src={(file ? file.data : null) || (attachment && !isNaN(attachment.Attachment) ? getProfileImage(`Co=${this.props.Co}&ID=${attachment.Attachment}` ): attachment.Attachment)}
                                            style={{ height: 512, width: '100%' }}
                                            initialAspectRatio={16 / 9}
                                            autoCrop={false}
                                            guides={true}
                                            crop={() => { }}
                                            ref={el => this.cropper = el}
                                            viewMode={1}
                                            dragMode={crop ? 'crop' : 'move'}
                                            rotateTo={rotate}
                                            scaleX={flipX ? -1 : 1}
                                            scaleY={flipY ? -1 : 1}
                                        />
                                    }
                                    {isImg && cropper &&
                                        <div className="w-full mb-24">
                                            <div className="w-full flex justify-between overflow-x-auto">
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => this.setState({ crop: false, drag: true }, () => cropper.setDragMode('move'))}
                                                >
                                                    <Icon color={drag ? "primary" : undefined}>control_camera</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => this.setState({ crop: true, drag: false }, () => cropper.setDragMode('crop'))}
                                                >
                                                    <Icon color={crop ? "primary" : undefined}>crop</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => cropper.zoom(-0.1)}
                                                >
                                                    <Icon>zoom_out</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => cropper.zoom(0.1)}
                                                >
                                                    <Icon>zoom_in</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => this.setState({ flipX: !flipX }, () => cropper.scaleX(flipX ? 1 : -1))}
                                                >
                                                    <Icon>swap_horiz</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => this.setState({ flipY: !flipY }, () => cropper.scaleY(flipY ? 1 : -1))}
                                                >
                                                    <Icon>swap_vert</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => this.setState({ setRotation: !setRotation, setZoom: false })}
                                                >
                                                    <Icon color={setRotation ? "primary" : undefined}>crop_rotate</Icon>
                                                </IconButton>
                                                <IconButton
                                                    className="mt-6"
                                                    onClick={() => this.setState({ rotate: 0, flipX: false, flipY: false }, () => cropper.reset())}
                                                >
                                                    <Icon>cancel</Icon>
                                                </IconButton>
                                            </div>
                                            {isImg && setRotation &&
                                                <div className="w-full flex">
                                                    <IconButton
                                                        className="mt-6"
                                                        onClick={() => cropper.rotate(-90)}
                                                    >
                                                        <Icon>rotate_left</Icon>
                                                    </IconButton>
                                                    <Slider className="mt-16" defaultValue={0} min={-180} max={180} step={15} marks value={this.state.rotate} onChange={(e, rotate) => this.setState({ rotate }, () => cropper.rotateTo(rotate))} />
                                                    <IconButton
                                                        className="mt-6"
                                                        onClick={() => cropper.rotate(90)}
                                                    >
                                                        <Icon>rotate_right</Icon>
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <TextField
                                        className="mb-12"
                                        label="File Name"
                                        id="fileName"
                                        name="fileName"
                                        value={this.state.fileName || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{attachment ? attachment.Extension : ".jpg"}</InputAdornment>
                                        }}
                                        fullWidth
                                    />
                                    <TextField
                                        className="mb-12"
                                        label={isImg ? "Caption" : "Notes"}
                                        id="notes"
                                        name="notes"
                                        value={this.state.notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </div>
                            }
                        </DialogContent>
                        {!edit && isImg && imgs.length > 1 && index > -1 &&
                            <MobileStepper
                                steps={imgs.length}
                                classes={{ root: "bg-transparent pin-b absolute w-full justify-center" }}
                                style={{ backgroundColor: 'rgb(0 0 0 / 15%)' }}
                                position="static"
                                activeStep={index}
                                className={classes.mobileStepper}
                                nextButton={''
                                    // <Button size="small" className="text-white" style={{ textShadow: '2px 2px 6px rgb(0 0 0 / 75%)' }} onClick={this.handleNext}>
                                    //     Next
                                    //     <Icon>keyboard_arrow_right</Icon>
                                    // </Button>
                                }
                                backButton={''
                                    // <Button size="small" className="text-white" style={{ textShadow: '2px 2px 6px rgb(0 0 0 / 75%)' }} onClick={this.handlePrev}>
                                    //     <Icon>keyboard_arrow_left</Icon>
                                    //     Back
                                    // </Button>
                                }
                            />
                        }
                        {attachment && this.state.confirmDelete &&
                            <ConfirmationDialog
                                open={this.state.confirmDelete}
                                title={<div><Icon className="mr-6 align-middle mb-4" color="error">delete</Icon>Are You Sure?</div>}
                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this file? This action cannot be undone.`}</div>}
                                confirmText="Delete Attachment"
                                cancelText="Cancel"
                                onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                                onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, this.deleteAttachment)}
                            />
                        }
                        {this.state.confirmCancel &&
                            <ConfirmationDialog
                                open={this.state.confirmCancel}
                                title={<div><Icon className="mr-6 align-middle mb-4 text-orange">warning</Icon>Are You Sure?</div>}
                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to leave without saving? This attachment has not yet been uploaded.`}</div>}
                                confirmText="Discard"
                                cancelText="Cancel"
                                onCancel={() => this.setState({ ...this.state, confirmCancel: false })}
                                onConfirm={() => this.setState({ ...this.state }, this.onClose)}
                            />
                        }
                        {edit &&
                            <DialogActions className="w-full flex justify-between dialog-actions">
                                <DebounceButton
                                    buttonText={"Save"}
                                    processingText={"Saving"}
                                    variant={"contained"}
                                    color={"primary"}
                                    clickFxn={this.handleSave}
                                    afterFxn={this.afterSave}
                                    debounceInterval={1000}
                                />
                                {attachment && this.props.onDelete &&
                                    <IconButton
                                        onClick={() => {
                                            this.setState({ confirmDelete: true });
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                }
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFiles: Actions.getFiles,
        setSelectedItem: Actions.setSelectedItem
    }, dispatch);
}

function mapStateToProps({ fileManagerApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AttachmentDialog)));