import axios from 'axios/index';
import reorder, { reorderQuoteMap } from './reorder';
import { showMessage } from 'store/actions/fuse';
import { updateCard } from './card.actions';
import ListModel from '../../model/ListModel';
import CardModel from '../../model/CardModel';
import history from 'history.js';
import _ from '@lodash';
import * as Actions from './index';
import { FuseUtils } from '@fuse';

export const GET_BOARD = '[SCRUMBOARD APP] GET BOARD';
export const SET_BOARD = '[SCRUMBOARD APP] SET BOARD';
export const DELETE_BOARD = '[SCRUMBOARD APP] DELETE BOARD';
export const COPY_BOARD = '[SCRUMBOARD APP] COPY BOARD';
export const RENAME_BOARD = '[SCRUMBOARD APP] RENAME BOARD';
export const CHANGE_BOARD_SETTINGS = '[SCRUMBOARD APP] CHANGE BOARD SETTINGS';
export const RESET_BOARD = '[SCRUMBOARD APP] RESET BOARD';
export const ORDER_LIST = '[SCRUMBOARD APP] ORDER LIST';
export const ORDER_CARD = '[SCRUMBOARD APP] ORDER CARD';
export const ADD_CARD = '[SCRUMBOARD APP] ADD CARD';
export const ADD_LIST = '[SCRUMBOARD APP] ADD LIST';
export const ADD_LABEL = '[SCRUMBOARD APP] ADD LABEL';
export const RENAME_LIST = '[SCRUMBOARD APP] RENAME LIST';
export const REMOVE_LIST = '[SCRUMBOARD APP] REMOVE LIST';
export const SET_BOARD_VIEW = '[SCRUMBOARD APP] SET BOARD VIEW';

export function setBoardView(view) {
    return {
        type: SET_BOARD_VIEW,
        view
    }
}

export function getBoard(Co, boardId) {
    const request = axios.get(`${window["apiLocation"]}/api/ScrumBoard?Co=${Co}&Board=${boardId}&Flatten=true`);

    return (dispatch) =>
        request.then((response) => {
            dispatch(buildBoard(response.data));
        }).catch((error) => {
            dispatch(showMessage({
                message: error.message,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));
            history.push({
                pathname: '/apps/project-tracking/boards'
            });
        });
}

export function buildBoard(board) {
    return (dispatch, getState) => {
        const { Lists, Cards } = board.Data;
        Lists.map((list) => {
            list.Data.Cards = _.filter(Cards, (o) => o.ListID == list.ID && !o.ParentID);
        })
        const orphans = _.filter(Cards, (o) => o.DeletedYN !== "Y" && (Boolean(o.ListID) || Boolean(o.ParentID)) && !_.find(Lists, { ID: o.ListID }) && !_.find(Cards, { ID: o.ParentID }));
        if (orphans.length > 0) {
            Lists.push({
                ID: -1,
                Name: "Restored",
                BoardID: board.ID,
                Co: board.Co,
                Data: {
                    Cards: orphans
                },
                Status: 0
            })
        }
        dispatch({
            type: SET_BOARD,
            payload: board
        });
    }
}

export function setBoard(board) {
    return {
        type: SET_BOARD,
        payload: board
    };
}

export function addBoardMember(member) {
    const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardMember`, member);
    return (dispatch, getState) => {
        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error Adding Project Member: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            } else {
                const board = _.cloneDeepWith(getState().scrumboardApp.board);
                board.Data.Members.push(response.data);
                dispatch(setBoard(board));
                dispatch(showMessage({
                    message: 'Project Member Successfully Added',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch(() => {
            dispatch(showMessage({
                message: `Error Adding Project Member`,
                autoHideDuration: 30000,
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        })
    }
}

export function updateBoardMember(member) {
    const { Co, BoardID, UserName } = member;
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardMember?Co=${Co}&Board=${BoardID}&UserName=${encodeURIComponent(UserName)}`, member);
    return (dispatch, getState) => {
        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error Updating Project Member: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            } else {
                const board = _.cloneDeepWith(getState().scrumboardApp.board);
                const index = _.findIndex(board.Data.Members, { UserName });
                if (index > -1) {
                    board.Data.Members.splice(index, 1, response.data);
                    dispatch(setBoard(board));
                    dispatch(showMessage({
                        message: 'Project Member Successfully Updated',
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            }
        }).catch(() => {
            dispatch(showMessage({
                message: `Error Updating Project Member`,
                autoHideDuration: 30000,
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        })
    }
}

export function removeBoardMember(member) {
    const { Co, BoardID, UserName } = member;
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardMember?Co=${Co}&Board=${BoardID}&UserName=${encodeURIComponent(UserName)}`, member);
    return (dispatch, getState) => {
        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error Removing Project Member: ${response.data}`,
                    autoHideDuration: 30000,
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            } else {
                const board = _.cloneDeepWith(getState().scrumboardApp.board);
                const index = _.findIndex(board.Data.Members, { UserName });
                if (index > -1) {
                    board.Data.Members.splice(index, 1);
                    dispatch(setBoard(board));
                    dispatch(showMessage({
                        message: 'Project Member Successfully Removed',
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            }
        }).catch(() => {
            dispatch(showMessage({
                message: `Error Removing Project Member`,
                autoHideDuration: 30000,
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        })
    }
}

export function resetBoard() {
    return {
        type: RESET_BOARD
    };
}

export function reorderList(result) {
    return (dispatch, getState) => {

        const { board } = getState().scrumboardApp;
        const { Lists } = board.Data;

        const ordered = reorder(
            Lists,
            result.source.index,
            result.destination.index
        );

        for (var i = 0; i < ordered.length; i++) {
            ordered[i].DisplayIndex = i;
            dispatch(renameList(ordered[i], true));
        }

        dispatch(showMessage({
            message: 'List Order Saved',
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'success'
        }));

        return dispatch({
            type: ORDER_LIST,
            payload: ordered
        });
    }
}

export function reorderCard(result) {
    return (dispatch, getState) => {

        const { board } = getState().scrumboardApp;
        const { Lists } = board.Data;

        const ordered = reorderQuoteMap(
            Lists,
            result.source,
            result.destination
        );

        dispatch(showMessage({
            message: 'Card Order Saved',
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            variant: 'success'
        }));

        for (var i = 0; i < ordered.length; i++) {
            if (ordered[i].ID === result.destination.droppableId) {
                for (var c = 0; c < ordered[i].Data.Cards.length; c++) {
                    ordered[i].Data.Cards[c].ListID = ordered[i].ID;
                    ordered[i].Data.Cards[c].DisplayIndex = c;
                    dispatch(updateCard(ordered[i].Data.Cards[c], true, true));
                }
            }
        }

        return dispatch({
            type: ORDER_CARD,
            payload: ordered
        });
    }
}

export function newCard(card) {

    const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardCard`,
        card
    );
    return (dispatch, getState) => {
        return request.then((response) => {
            const { board } = _.cloneDeepWith(getState().scrumboardApp);
            const { Cards } = board.Data;
            Cards.push(response.data);
            dispatch(buildBoard(board));
        });
    }
}


export function newList(list) {

    const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardList`,
        list
    );

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: ADD_LIST,
                payload: response.data
            })
        );
}

export function renameList(list, bool) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardList?Co=${list.Co}&Board=${list.BoardID}&List=${list.ID}`,
        list
    );

    return (dispatch) =>
        request.then((response) => {
            if (!bool) {
                dispatch({
                    type: RENAME_LIST,
                    listId: list.ID,
                    listTitle: list.Name
                })
            }
        }
        );
}

export function removeList(list) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardList?Co=${list.Co}&Board=${list.BoardID}&List=${list.ID}`);

    return (dispatch, getState) =>
        request.then((response) => {
            const { board } = _.cloneDeepWith(getState().scrumboardApp);
            let { Lists, Cards } = board.Data;
            const index = _.findIndex(Lists, { ID: list.ID });
            if (index > -1) {
                Lists.splice(index, 1);
            }
            function removeCards(cards) {
                cards.map((card) => {
                    const ind = _.findIndex(Cards, { ID: card.ID });
                    if (ind > -1) {
                        Cards.splice(ind, 1);
                    }
                    const subTasks = _.filter(Cards, { ParentID: card.ID });
                    removeCards(subTasks);
                });
                return Cards;
            }
            removeCards(list.Data.Cards);
            dispatch(buildBoard(board));
            dispatch(showMessage({
                message: 'List Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
            // dispatch({
            //     type: REMOVE_LIST,
            //     payload: list
            // });
        }
        );
}

export function addLabel(label) {
    return (dispatch) => {
        return dispatch({
            type: ADD_LABEL,
            payload: label
        })
    }
}

export function changeBoardSettings(newSettings) {
    return (dispatch, getState) => {
        const { board } = getState().scrumboardApp;
        const settings = _.merge(board.settings, newSettings);
        const request = axios.post('/api/scrumboard-app/board/settings/update',
            {
                boardId: board.id,
                settings
            }
        );

        return request.then((response) =>
            dispatch({
                type: CHANGE_BOARD_SETTINGS,
                payload: response.data
            })
        );
    }
}

export function deleteBoard(board) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoard?Co=${board.Co}&Board=${board.ID}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch(showMessage({
                message: 'Board Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));

            history.push({
                pathname: '/apps/project-tracking/boards'
            });

            dispatch({
                type: DELETE_BOARD,
                payload: board
            });
        }
        );
}
// export function deleteBoard(boardId) {
//     const request = axios.post('/api/scrumboard-app/board/delete',
//         {
//             boardId
//         }
//     );

//     return (dispatch) =>
//         request.then((response) => {

//             history.push({
//                 pathname: '/apps/project-tracking/boards'
//             });

//             return dispatch({
//                 type: DELETE_BOARD
//             });
//         })
// }

export function copyBoard(board) {
    const newBoard = _.merge(board, {
        id: FuseUtils.generateGUID(),
        name: board.Name + ' (Copied)',
        uri: board.uri + '-copied'
    });
    return (dispatch) => {
        dispatch(Actions.newBoard(newBoard));
        return { type: COPY_BOARD };
    }
}

export function renameBoard(board) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoard?Co=${board.Co}&Board=${board.ID}`,
        board
    );

    return (dispatch) =>
        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error Updating Project: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            } else {
                dispatch({
                    type: RENAME_BOARD,
                    payload: board
                });
                dispatch(showMessage({
                    message: `Project Successfully Updated`,
                    autoHideDuration: 5000,
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            }
        }).catch(() => {
            dispatch(showMessage({
                message: `Error Updating Project`,
                autoHideDuration: 30000,
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        })
}
