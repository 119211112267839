import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedFleetVehicleIds: [],
    routeParams: {},
    fleetVehicleDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            Co: null,
            Category: null,
            FleetVehicle: null,
            LicenseNum: null,
            Description: null,
            Manufacturer: null,
            Model: null,
            ModelYear: null,
            VIN: null,
            Odometer: null,
            OdometerDate: null,
            Operator: null,
            Status: 0,
            ActiveYN: 'Y',
            Notes: null,
            AttachmentID: null,
            Data: {
                Avatar: null,
                ErrMsg: null,
            }
        }
    }
};

const fleetVehiclesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_FLEET_VEHICLES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_FLEET_VEHICLES:
            {

                const fleetVehicleId = action.fleetVehicleId;

                let selectedFleetVehicleIds = [...state.selectedFleetVehicleIds];

                if (selectedFleetVehicleIds.find(id => id === fleetVehicleId) !== undefined) {
                    selectedFleetVehicleIds = selectedFleetVehicleIds.filter(id => id !== fleetVehicleId);
                }
                else {
                    selectedFleetVehicleIds = [...selectedFleetVehicleIds, fleetVehicleId];
                }

                return {
                    ...state,
                    selectedFleetVehicleIds: selectedFleetVehicleIds
                };
            }
        case Actions.SELECT_ALL_FLEET_VEHICLES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedFleetVehicleIds = arr.map(fleetVehicles => fleetVehicles.id);

                return {
                    ...state,
                    selectedFleetVehicleIds: selectedFleetVehicleIds
                };
            }
        case Actions.DESELECT_ALL_FLEET_VEHICLES:
            {
                return {
                    ...state,
                    selectedFleetVehicleIds: []
                };
            }
        case Actions.OPEN_NEW_FLEET_VEHICLE_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    fleetVehicleDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.fleetVehicleDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_FLEET_VEHICLE_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_FLEET_VEHICLE_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    fleetVehicleDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.fleetVehicleDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_FLEET_VEHICLE_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    fleetVehicleDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: {
                            ...initialState.fleetVehicleDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_FLEET_VEHICLE_DIALOG:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
};

export default fleetVehiclesReducer;
