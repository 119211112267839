import {combineReducers} from 'redux';
import agreementTypes from './agreement-types.reducer';
import user from './user.reducer';

const agreementTypesAppReducers = combineReducers({
    agreementTypes,
    user
});

export default agreementTypesAppReducers;
