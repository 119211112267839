import AgreementsApp from 'main/content/apps/agreements/AgreementsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const AgreementsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/agreements/:id',
            component: AgreementsApp
        },
        {
            path     : '/apps/agreements',
            component: () => <Redirect to="/apps/agreements/all"/>
        }
    ]
};
