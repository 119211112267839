import { FuseAnimate } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { bindActionCreators } from 'redux';
import PartTypeDialog from './PartTypeDialog';
import * as Actions from './store/actions';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class PartTypesList extends Component {

    state = {
        selectedPartTypesMenu: null
    };

    render() {
        const { classes, searchText, openNewPartTypeDialog, openEditPartTypeDialog } = this.props;
        const data = this.props.partTypeData;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewPartTypeDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <PartTypeDialog unitType={this.props.unitType} />
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">settings</Icon>Part Types</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        openEditPartTypeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Part Type",
                                accessor: "Name",
                                className: "font-bold"
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Part Types found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditPartTypeDialog: Actions.openEditPartTypeDialog,
        openNewPartTypeDialog: Actions.openNewPartTypeDialog
    }, dispatch);
}

function mapStateToProps({ }) {
    return {

    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PartTypesList)));
