import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageSimple, FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import EmployeesList from 'main/content/apps/employees/EmployeesList';
import EmployeesHeader from 'main/content/apps/employees/EmployeesHeader';
import EmployeesLeftSidebarHeader from 'main/content/apps/employees/EmployeesLeftSidebarHeader';
import EmployeesRightSidebarHeader from 'main/content/apps/employees/EmployeesRightSidebarHeader';
import EmployeesSidebarContent from 'main/content/apps/employees/EmployeesSidebarContent';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import EmployeesDialog from 'main/content/apps/employees/EmployeesDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class CustomersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getEmployees(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewEmployeeDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <EmployeesHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <EmployeesLeftSidebarHeader />
                            }
                            content={
                                <EmployeesList />

                            }
                            leftSidebarContent={
                                <EmployeesSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {/* <EmployeesDialog /> */}
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEmployees: Actions.getEmployees,
        getUserData: Actions.getUserData,
        openNewEmployeeDialog: Actions.openNewEmployeeDialog
    }, dispatch);
}

function mapStateToProps({ employeesApp }) {
    return {
        employees: employeesApp.employees.entities,
        selectedEmployeeIds: employeesApp.employees.selectedEmployeeIds,
        searchText: employeesApp.employees.searchText,
        user: employeesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
