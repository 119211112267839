import {combineReducers} from 'redux';
import businessUnits from './business-units.reducer';
import user from './user.reducer';

const businessUnitsAppReducers = combineReducers({
    businessUnits,
    user
});

export default businessUnitsAppReducers;
