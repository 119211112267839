import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardContent, CardActions, Divider, Icon, IconButton, LinearProgress, List, ListItem, ListItemText, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import * as Actions from './store/actions';
import _ from '@lodash';
import classNames from 'classnames';
import { SET_CLASS_STEP } from './store/actions';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff'
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    cardSubheader: {
        fontSize: 11,
    },
    avatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: 24,
        height: 24
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 12
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
});

const initialState = {
    ID: null,
    Co: null,
    Name: null,
    Description: null,
    Category: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
    editing: false,
};

class ReportCard extends Component {

    state = {
        ...initialState,
    }

    componentDidMount() {
        const { value } = this.props;
        this.setState({ ...initialState, ...value });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value)) {
            this.setState({ ...this.state, ...value });
        }
    }

    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ ...this.state, open: !open });
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        const { editing } = this.state;
        this.setState({ ...this.state, editing: !editing, open: true });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    toggleReport = (value) => {
        const { report } = this.props;
        if (!_.isEqual(report, value)) {
            this.props.setReport(value);
        } else {
            this.props.setReport(null);
        }
    }

    canBeSubmitted = () => {
        const { Name, Description, Category } = this.state;
        return Name && Name.length > 0 && Description && Description.Length > 0 && Category && !isNaN(Category);
    }

    render() {
        const { classes, value, user, categories, report, securables, fullWidth } = this.props;
        const { open, editing } = this.state;
        // const count = _.filter(reportList, { Category: value.Category }).length;
        const accessLevel = _.find(securables, { Securable: "reporting" });
        return (
            <div className={classNames("mt-16 px-8 w-full", fullWidth ? "" : "sm:w-1/3 md:w-1/4 lg:w-1/5")}>
                <Card onClick={(e) => { e.stopPropagation(); if (this.props.onClick && !editing) { this.props.onClick() } }} className="cursor-pointer">
                    <CardHeader
                        classes={{ root: classes.card, title: classNames(classes.cardTitle, "truncate text-black"), subheader: classes.cardSubheader, avatar: "mr-8", action: "pt-8 pr-4" }}
                        avatar={<Avatar className={classes.avatar}><Icon className="text-16">assignment</Icon></Avatar>}
                        // action={this.state.ID ? <IconButton onClick={() => this.toggleReport(value)}><Icon color={catNum === value.Category ? "secondary" : "action"}>filter_list</Icon></IconButton> : false}
                        title={<React.Fragment>{value.Name}</React.Fragment>}
                    // subheader={`${count} ${count !== 1 ? "Categories" : "Category"}`}

                    />
                    <CardContent className="relative" style={{ minHeight: 128, padding: editing ? 0 : 16 }} classes={{ root: classes.cardContent }}>
                        <div className="absolute pin-r pin-l pin-t pin-b m-auto w-full text-center" style={{ height: 56 }}>
                            <strong className={classes.dark} className="text-12 p-8">
                                {value.Description}
                            </strong>
                            <br />
                            <label className={classes.dark} className="text-10 p-8">
                                {value.AddedBy}
                            </label>
                            <br />
                            <label className={classes.dark} className="text-10 p-8">
                                {new Date(value.AddedDate).toLocaleDateString('en-US')}
                            </label>
                        </div>
                    </CardContent>
                </Card >
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addReport: Actions.addReport,
        updateReport: Actions.updateReport,
        deleteReport: Actions.removeReport,
        // setReport: Actions.setReport,
        setCategories: Actions.setCategories,
    }, dispatch);
}

function mapStateToProps({ spReducers, reportingApp }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User.UserName,
        categories: spReducers.classes.categories,
        report: reportingApp.reporting.report,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportCard)));
