import React, { Component } from 'react';
import { withStyles, Badge, ClickAwayListener, Icon, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from 'main/content/apps/scrumboard/store/actions';
import { withRouter } from 'react-router-dom';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import connect from 'react-redux/es/connect/connect';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    listBadge: {
        top: 12,
        left: '100%',
        right: 'unset',
        backgroundColor: 'grey',
        color: '#fff',
        height: 24,
        minWidth: 24,
        borderRadius: '999px',
        padding: 8,
    }
});

class BoardListHeader extends Component {
    state = {
        anchorEl: null,
        renameForm: false,
        renameTitle: this.props.list.Name,
        confirm: false
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    renameFormToggle = (state) => {
        this.setState({
            renameForm: state,
            renameTitle: this.props.list.Name,
            anchorEl: null
        })
    };

    onRenameTitleChange = (ev) => {
        this.setState({ renameTitle: ev.target.value })
    };

    renameTitleSubmit = (ev) => {
        ev.preventDefault();
        if (!this.canSubmit()) {
            this.renameFormToggle(false);
            return;
        }
        this.setState({
            renameForm: false,
            renameTitle: this.state.renameTitle,
            anchorEl: null
        });
        this.props.renameList({ ...this.props.list, Name: this.state.renameTitle });
    };

    canSubmit = () => {
        return this.state.renameTitle !== '';
    };

    render() {
        const { classes, board, list, handleProps, removeList, username, securables } = this.props;
        const { anchorEl, renameTitle, renameForm } = this.state;
        const accessLevel = _.find(securables, { Securable: "project-tracking" });

        return (
            <div {...handleProps}>
                <div className="flex items-center justify-between h-64 pl-16 pr-8">
                    <div className="flex items-center min-w-0">
                        {renameForm ? (
                            <ClickAwayListener onClickAway={() => this.renameFormToggle(false)}>
                                <form className="flex w-full" onSubmit={this.renameTitleSubmit}>
                                    <TextField
                                        value={renameTitle}
                                        onChange={this.onRenameTitleChange}
                                        variant="outlined"
                                        margin="none"

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        type="submit"
                                                        disabled={!this.canSubmit()}
                                                    >
                                                        <Icon>check</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </form>
                            </ClickAwayListener>
                        ) : (
                            <Typography
                                className="text-16 font-600 cursor-pointer"
                                onClick={() => { if (username === list.AddedBy || accessLevel && accessLevel.AccessLevel === 'F') { this.renameFormToggle(true) } }}
                            >
                                <Badge classes={{ badge: classes.listBadge }} badgeContent={list.Data.Cards.length}>{list.Name}</Badge>
                            </Typography>
                        )}

                    </div>
                    {(list.ID >= 0 && (username === list.AddedBy || accessLevel && accessLevel.AccessLevel === 'F')) &&
                        <div className="">
                            <IconButton
                                aria-owns={anchorEl ? 'actions-menu' : null}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                variant="outlined"
                                size="small"
                            >
                                <Icon className="text-20">more_vert</Icon>
                            </IconButton>
                            <ConfirmationDialog
                                open={this.state.confirm}
                                title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this list?`}</div>}
                                confirmText="Confirm"
                                cancelText="Never Mind"
                                onCancel={() => this.setState({ ...this.state, confirm: false })}
                                onConfirm={() => this.setState({ ...this.state, confirm: false }, () => { removeList(list); })}
                            />
                            <Menu
                                id="actions-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={() => {
                                    this.setState({ ...this.state, anchorEl: null, confirm: true });
                                }}>
                                    <ListItemIcon>
                                        <Icon>delete</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary="Remove List" />
                                </MenuItem>
                                <MenuItem onClick={() => this.renameFormToggle(true)}>
                                    <ListItemIcon>
                                        <Icon>edit</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary="Rename List" />
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                </div>

            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        renameList: Actions.renameList,
        removeList: Actions.removeList
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        board: scrumboardApp.board,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardListHeader)));
