import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Badge, Button, Dialog, DialogActions, DialogContent, Fab, FormControlLabel, Icon, IconButton, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DialGroupsList from 'main/content/apps/dial-groups/DialGroupsList';
import * as DialGroupActions from 'main/content/apps/dial-groups/store/actions';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newHuntGroupState = {
    ID: null,
    Co: null,
    Name: '',
    Description: '',
    RecordYN: null,
    Notes: '',
    ActiveYN: 'Y',
    AddedBy: '',
    AddedDate: new Date(),
    MaxRetries: null,
    FallbackAction: null,
    FallbackDial: null,
    FallbackQueue: null,
    FallbackVoiceMailbox: null,
    value: 0,
    Data: {
        Tags: [],
    },
};

class HuntGroupsDialog extends Component {
    state = { ..._.cloneDeepWith(newHuntGroupState) };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newHuntGroupState.Co = this.props.Co;
        if (!prevProps.huntGroupDialog.props.open && this.props.huntGroupDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.huntGroupDialog.type === 'edit' &&
                this.props.huntGroupDialog.data &&
                !_.isEqual(this.props.huntGroupDialog.data, prevState)) {
                this.setState({ ..._.cloneDeepWith(newHuntGroupState), ...this.props.huntGroupDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.huntGroupDialog.type === 'new' &&
                !_.isEqual(newHuntGroupState, prevState)) {
                this.setState({ ..._.cloneDeepWith(newHuntGroupState) });
            }
        }
        if (prevProps.huntGroupDialog.props.open && this.props.huntGroupDialog.props.open) {
            if (this.props.huntGroupDialog.type === 'update' &&
                this.props.huntGroupDialog.data &&
                !_.isEqual(this.props.huntGroupDialog.data, prevState)) {
                this.props.huntGroupDialog.type = 'edit';
                this.setState({ ...this.props.huntGroupDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.huntGroupDialog.type === 'edit' ? this.props.closeEditHuntGroupDialog() : this.props.closeNewHuntGroupDialog();
    };

    canBeSubmitted() {
        const { Name } = this.state;
        return (
            Name.length > 0
        );
    }

    render() {
        const { classes, securables, huntGroupDialog, addHuntGroup, updateHuntGroup, queues, voiceMailboxes } = this.props
        const { value } = this.state;
        const accessLevel = _.find(securables, { Securable: "hunt-groups" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...huntGroupDialog.props}
                        open={Boolean(accessLevel) && huntGroupDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(huntGroupDialog.type) > -1 ? 'New Hunt Group' : `Hunt Group #${this.state.ID} Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>ring_volume</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Members ? this.state.Data.Members.length : 0} color="error"><Icon>dialpad</Icon></Badge>} label="Dial Groups" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && <TabContainer>
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <DialogContent classes={{ root: "p-0 sm:p-24" }}>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">ring_volume</Icon>
                                        </div>
                                        <TextField
                                            className={classNames("w-full sm:w-160", classes.formControl)}
                                            label="Hunt Group"
                                            id="id"
                                            name="ID"
                                            value={this.state.ID || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            // fullWidth
                                            disabled={true}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Name"
                                            id="name"
                                            name="Name"
                                            value={this.state.Name || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classNames("w-full sm:w-192", classes.formControl)}
                                            label="Max Retries"
                                            id="maxRetries"
                                            name="MaxRetries"
                                            value={this.state.MaxRetries || ''}
                                            type="number"
                                            onChange={this.handleChange}
                                            variant="outlined"
                                        />
                                    </div>

                                    {this.state.MaxRetries > 0 &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                                <Icon color="action">phone_missed</Icon>
                                            </div>
                                            <TextField
                                                className={classes.formControl}
                                                label="Fallback Action"
                                                id="fallbackAction"
                                                name="FallbackAction"
                                                value={this.state.FallbackAction || ''}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: this.state.FallbackAction ? true : false }}
                                                variant="outlined"
                                                fullWidth
                                                select
                                            >
                                                <MenuItem value="Dial">
                                                    Forward
                                                </MenuItem>
                                                <MenuItem value="Enqueue">
                                                    Enqueue
                                                </MenuItem>
                                                <MenuItem value="Voicemail">
                                                    Voicemail
                                                </MenuItem>
                                            </TextField>
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            {
                                                this.state.FallbackAction === "Dial" &&
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Transfer To"
                                                    id="fallbackDial"
                                                    name="FallbackDial"
                                                    value={this.state.FallbackDial || ''}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{ shrink: this.state.FallbackDial ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            }
                                            {
                                                this.state.FallbackAction === "Enqueue" &&
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Queue"
                                                    options={queues}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.Queue}>
                                                            {value.Queue}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.FallbackQueue || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, FallbackQueue: option.Queue })}
                                                    required
                                                />
                                            }
                                            {
                                                this.state.FallbackAction === "Voicemail" &&
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Voice Mailbox"
                                                    options={voiceMailboxes}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.VoiceMailbox}>
                                                            {`${value.VoiceMailbox} - ${value.Name}`}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.FallbackVoiceMailbox || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, FallbackVoiceMailbox: option.VoiceMailbox })}
                                                    required
                                                />
                                            }
                                        </div>
                                    }

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">chat_bubble_outline</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">note</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="sm:flex justify-between">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.RecordYN === 'Y'}
                                                    name="RecordYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Record Calls"
                                        />
                                    </div>
                                </DialogContent>
                            </FuseAnimate>
                        </TabContainer>}
                        {value === 1 && huntGroupDialog.type == 'edit' &&
                            <TabContainer>
                                {accessLevel && accessLevel.AccessLevel !== "R" &&
                                    <Fab
                                        color="secondary"
                                        aria-label="add"
                                        className={classNames(classes.addButton)}
                                        onClick={() => this.props.openNewDialGroupDialog(this.state)}
                                    >
                                        <Icon>add</Icon>
                                    </Fab>
                                }
                                <DialGroupsList selectedDialGroupsData={[...this.state.Data.Members]} />
                            </TabContainer>}

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {['new', 'customer'].indexOf(huntGroupDialog.type) > -1 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addHuntGroup(rec);
                                            //this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            updateHuntGroup(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
HuntGroupsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditHuntGroupDialog: Actions.closeEditHuntGroupDialog,
        closeNewHuntGroupDialog: Actions.closeNewHuntGroupDialog,
        addHuntGroup: Actions.addHuntGroup,
        updateHuntGroup: Actions.updateHuntGroup,
        removeHuntGroup: Actions.removeHuntGroup,
        getFiles: FileActions.getFiles,
        showMessage: showMessage,
        setAwaitingCallFrom: setAwaitingCallFrom,
        openNewDialGroupDialog: DialGroupActions.openNewDialGroupDialog
    }, dispatch);
}

function mapStateToProps({ huntGroupsApp, fileManagerApp, spReducers, dialer }) {
    return {
        huntGroupDialog: huntGroupsApp.huntGroups.huntGroupDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        queues: spReducers.callHandler.Queues,
        voiceMailboxes: spReducers.voiceMailboxes,
        gathers: spReducers.huntGroups,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        awaitingCallFrom: dialer.awaitingCallFrom,
        AudioClips: spReducers.audioFiles.audioClips,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(HuntGroupsDialog));
