import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class ServiceTypesList extends Component {

    state = {
        selectedServiceTypesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedServiceTypeMenu = (event) => {
        this.setState({ selectedServiceTypesMenu: event.currentTarget });
    };

    closeSelectedServiceTypesMenu = () => {
        this.setState({ selectedServiceTypesMenu: null });
    };

    render() {
        const { classes, serviceTypes, user, searchText, openNewServiceTypeDialog, selectedServiceTypeIds, selectAllServiceTypes, deSelectAllServiceTypes, toggleInSelectedServiceTypes, openEditServiceTypeDialog, removeServiceTypes, removeServiceType, toggleStarredServiceType, setServiceTypesUnstarred, setServiceTypesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.serviceTypeData ? [] : this.props.serviceTypeData), searchText);
        const { selectedServiceTypesMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewServiceTypeDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">settings</Icon>Service Types</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditServiceTypeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "ServiceType",
                                accessor: "ServiceType",
                                className: "font-bold"
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Service Types found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServiceTypes: Actions.getServiceTypes,
        getUserData: Actions.getUserData,
        toggleInSelectedServiceTypes: Actions.toggleInSelectedServiceTypes,
        selectAllServiceTypes: Actions.selectAllServiceTypes,
        deSelectAllServiceTypes: Actions.deSelectAllServiceTypes,
        openEditServiceTypeDialog: Actions.openEditServiceTypeDialog,
        removeServiceTypes: Actions.removeServiceTypes,
        removeServiceType: Actions.removeServiceType,
        toggleStarredServiceType: Actions.toggleStarredServiceType,
        toggleStarredServiceTypes: Actions.toggleStarredServiceTypes,
        setServiceTypesStarred: Actions.setServiceTypesStarred,
        setServiceTypesUnstarred: Actions.setServiceTypesUnstarred,
        openNewServiceTypeDialog: Actions.openNewServiceTypeDialog
    }, dispatch);
}

function mapStateToProps({ serviceTypesApp, spReducers }) {
    return {
        serviceTypes: serviceTypesApp.serviceTypes.entities,
        selectedServiceTypeIds: serviceTypesApp.serviceTypes.selectedServiceTypeIds,
        searchText: serviceTypesApp.serviceTypes.searchText,
        user: serviceTypesApp.user,
        serviceTypeData: spReducers.serviceTypes
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceTypesList)));
