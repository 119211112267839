import _ from '@lodash'
import {
  AppBar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { impress } from 'main/content/compression/impress'
import ChipInput from 'material-ui-chip-input'
import React, { Component } from 'react'
import Media from 'react-media'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AttachmentDialog from '../file-manager/AttachmentDialog'
import * as Actions from './store/actions'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const styles = theme => ({
  composeButton: {
    width: '100%',
  },
  formControl: {
    marginTop: 8,
    marginBottom: 16,
  },
  attachmentList: {
    paddingTop: 8,
  },
  attachment: {
    fontSize: 13,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    paddingLeft: 16,
    marginBottom: 8,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  attachmentFilename: {
    fontWeight: 600,
  },
  attachmentSize: {
    marginLeft: 8,
    fontWeight: 300,
  },
  quill: {
    '& .ql-editor': {
      minHeight: 256,
    },
  },
  chipContainer: {
    paddingTop: '8px !important',
  },
})
const initialState = {
  composeDialog: false,
  button: null,
  Title: 'New Email',
  To: '',
  Subject: '',
  Message: '',
  Head: null,
  Body: '',
  Attachments: [],
  DBAttachments: [],
  DBAttachment: null,
  Event: null,
  Type: null,
  RecordID: null,
  MessageID: null,
  Recipients: '',
  CreatedDate: null,
  Status: 0,
  selectedAttachment: null,
  selectedAttachmentName: null,
  disableTo: false,
  attachmentsMenuEl: null,
  //
  isFocused: false,
  inputValue: '',
  filteredData: [],
}
class MailCompose extends Component {
  state = {
    ...initialState,
  }

  // Filter functions
  handleInputChange = e => {
    window['warn'](this.props.contacts, this.state.To)
    const inputText = e.target.value.toLowerCase()
    this.setState({ inputValue: inputText })

    const filteredContacts = _.filter(this.props.contacts, customer => {
      const { Data } = customer
      if (Data && Data.Contact) {
        const { Name, Email } = Data.Contact
        if (Email === undefined) {
          return Name.toLowerCase().includes(inputText)
        } else {
          return (
            Name.toLowerCase().includes(inputText) ||
            Email.toLowerCase().includes(inputText)
          )
        }
      } else {
        return false
      }
    })
    this.setState({ filteredData: filteredContacts })
  }

  handleAddRecipient = (e, term) => {
    e.preventDefault()
    const { To, inputValue } = this.state
    const recipientEmail = term

    if (To.indexOf(recipientEmail) === -1) {
      let newTo = To
      if (newTo) {
        newTo += `; ${recipientEmail}`
      } else {
        newTo = recipientEmail
      }

      this.setState({
        To: newTo,
        inputValue: '',
        isFocused: false,
      })
    }
  }

  handleFocus = () => {
    this.setState({ isFocused: true })
  }
  handleBlur = () => {
    setTimeout(() => {
      this.setState({ isFocused: false })
    }, 100)
  }

  handleAddRecipientManually = term => {
    if (term && term.length > 0) {
      let { To } = this.state
      const to = this.validateEmail(term.replace(';', ''))
      // const to = term.replace(';', '');
      if (to.length > 0) {
        To += `;${to}`
        this.setState({ To, inputValue: '' })
      }
    }
  }

  validateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(mail.trim())) {
      return mail.trim()
    } else {
      return ''
    }
  }

  // handleDeleteRecipient = (term) => {
  //     let { To } = this.state;
  //     let newText = To.replace(`${term};`, '').replace(`;;`, ';').replace(term, '');
  //     this.setState({ To: newText });
  // }
  handleDeleteRecipient = term => {
    let { To } = this.state

    // Split the To string into an array, filter out the term, and join back into a string
    let newText = To.split(';')
      .filter(email => email.trim() !== term.trim())
      .join(';')

    this.setState({ To: newText })
  }

  // filterData = () => {
  //     const { To } = this.state;
  //     const customerData = this.props.contacts;

  //     const filteredData = customerData.filter((customer) => {
  //         const { Name, Email } = customer.Data.Contact;
  //         return (
  //             Name.toLowerCase().includes(To.toLowerCase()) ||
  //             Email.toLowerCase().includes(To.toLowerCase())
  //         );
  //     });
  //     this.setState({ filteredData })
  //     window["warn"](filteredData);
  // }

  componentDidMount() {
    if (this.props.data) {
      this.setState({ ...this.props.data })
      if (this.props.data.To === 'undefined;') {
        this.handleDeleteRecipient('undefined;')
      }
      window['warn'](this.props.data)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(this.props.data, prevProps.data)) {
      // window['warn']('Mail data updated', this.props.data)
      this.setState({ ...this.props.data })
    }
  }

  openComposeDialog = () => {
    this.setState({ composeDialog: true })
  }

  closeComposeDialog = () => {
    this.setState({ composeDialog: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  imgHandler = () => {
    const editor = this.quillRef.getEditor()
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = () => {
      const file = input.files[0]

      // Save current cursor state
      const range = editor.getSelection(true)

      //Compress image
      impress(file, 1024, 50, false, img => {
        // Insert uploaded image
        editor.insertEmbed(range.index, 'image', img.base64)
        editor.setSelection(range.index + 1)
      })
    }
  }

  handleEditorChange = Message => {
    this.setState({ ...this.state, Message })
  }

  sendEmail = () => {
    const { Event, Type, RecordID, role } = this.state
    let To = []
    this.state.To.split(';').map(value => {
      if (value.length > 4) {
        To.push(value.toLowerCase().trim())
      }
    })
    const data = {
      To,
      Subject: this.state.Subject,
      Body: `${this.state.Message}${this.state.Body ? this.state.Body : ''}`,
      Type,
      RecordID,
      DBAttachments: this.state.DBAttachments,
    }
    if (Event) {
      data.Event = Event
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(data))
    if (this.state.Attachments) {
      this.state.Attachments.map((attachment, index) => {
        fd.append(`attachment-${index}`, attachment, attachment.name)
      })
    }

    this.props.sendEmail(fd, role)
    this.setState({ ...initialState }, () => this.closeComposeDialog())
  }

  getFileSize = (attachment, contentLength) => {
    var _size = contentLength || attachment.size
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0
    while (_size > 900) {
      _size /= 1024
      i++
    }
    var exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i]
    return exactSize
  }

  removeAttachment = index => {
    const { Attachments } = this.state
    Attachments.splice(index, 1)
    this.setState({ ...this.state, Attachments })
  }

  removeDBAttachment = index => {
    const { DBAttachments } = this.state
    DBAttachments.splice(index, 1)
    this.setState({ ...this.state, DBAttachments })
  }

  fileSelectedHandler = event => {
    let { Attachments } = this.state
    Attachments.push(event.target.files[0])
    this.setState({ ...this.state, Attachments })
  }

  // showAttachment = (file) => new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => this.setState({ ...this.state, selectedAttachment: reader.result, selectedAttachmentName: file.name }, () => window["warn"](this.state));
  //     reader.onerror = error => reject(error);
  // });

  showAttachment = async file => {
    var url = URL.createObjectURL(file)
    this.setState(
      {
        ...this.state,
        selectedAttachment: url,
        selectedAttachmentName: file.name,
      },
      () => window['warn'](this.state),
    )
    // Browser.open({ url });
  }

  canBeSubmitted = () => {
    const { To, Subject, Body, Message } = this.state
    return To && To.length > 6 && Subject && (Body || Message)
  }

  // showAttachment = async (file) => {
  //     const attch = await this.toBase64(file);
  //     attch.then((b64) => {
  //         this.setState({ ...this.state, selectedAttachment: b64 }, () => window["warn"](this.state));
  //     })
  // }

  // showAttachment = (file) => {
  //     const b64FileData = this.getBase64(file);
  // }

  render() {
    const { classes, dbAttachments } = this.props
    const { disableTo, disableSubject } = this.state
    const html = `${this.state.Body}`
    const ext = {
      png: 'image',
      bmp: 'image',
      jpg: 'image',
      jpeg: 'image',
      pdf: 'insert_drive_file',
      doc: 'insert_drive_file',
      docx: 'insert_drive_file',
      txt: 'insert_drive_file',
      xls: 'insert_chart',
      xlsx: 'insert_chart',
      csv: 'insert_chart',
    }

    const toolbarOptions = [
      [
        { size: ['small', false, 'large', 'huge'] },
        'bold',
        'italic',
        'underline',
        'strike',
        { color: [] },
      ],

      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: [] },
      ],

      ['clean'], // remove formatting button
    ]

    const formats = [
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'list',
      'bullet',
      'indent',
      'align',
    ]

    const history = {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    }

    if (this.quillRef && !this.state.imgHandler) {
      window['warn'](this.quillRef)
      this.quillRef
        .getEditor()
        .getModule('toolbar')
        .addHandler('image', this.imgHandler)
      this.setState({ ...this.state, imgHandler: true })
    }

    const Attachment = ({ fileName, size, onRemove, file }) => {
      return (
        <div className='w-full'>
          <div
            onClick={() => this.showAttachment(file)}
            className={classes.attachment}
          >
            <div className='flex'>
              <Typography
                variant='caption'
                className={classes.attachmentFilename}
              >
                {fileName}
              </Typography>
              <Typography variant='caption' className={classes.attachmentSize}>
                ({size})
              </Typography>
            </div>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                onRemove()
              }}
            >
              <Icon className='text-16'>close</Icon>
            </IconButton>
          </div>
        </div>
      )
    }

    const DBAttachment = ({ fileName, size, onRemove, file }) => {
      return (
        <div className='w-full'>
          <div
            onClick={() => this.setState({ DBAttachment: file })}
            className={classes.attachment}
          >
            <div className='flex'>
              <Typography
                variant='caption'
                className={classes.attachmentFilename}
              >
                {fileName}
              </Typography>
              <Typography variant='caption' className={classes.attachmentSize}>
                ({size})
              </Typography>
            </div>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                onRemove()
              }}
            >
              <Icon className='text-16'>close</Icon>
            </IconButton>
          </div>
        </div>
      )
    }

    const dropDownElement = (customerEmail, customerName, to) => {
      const toEmails = to.split(';').map(email => email.trim())
      return (
        <div
          className='w-full flex justify-start items-center pl-4 hover:bg-grey'
          style={{
            cursor: toEmails.includes(customerEmail) ? 'default' : 'pointer',
          }}
          onClick={e => {
            if (
              customerEmail === undefined ||
              toEmails.includes(customerEmail)
            ) {
              this.setState({
                ...this.state,
                isFocused: false,
              })
            } else {
              this.handleAddRecipient(e, customerEmail)
            }
          }}
        >
          {customerEmail === '' ||
          customerEmail === undefined ? null : toEmails.includes(
              customerEmail,
            ) ? (
            <span style={{ textDecoration: 'line-through' }}>
              {customerEmail} - ADDED
            </span>
          ) : (
            `${customerName} - ${customerEmail}`
          )}
        </div>
      )
    }

    const emailUndefined = email => {
      const toEmails = email.split(';').map(toEmail => toEmail.trim())
      return toEmails === undefined
        ? 'Billing contact has NO email'
        : toEmails && toEmails.length > 0
          ? toEmails.split(';')
          : []
    }
    return (
      <Media
        queries={{
          small: '(max-width: 600px)',
          medium: '(min-width: 1200px)' /**/,
        }}
      >
        {matches => (
          <React.Fragment>
            {!this.props.hideButton && (
              <div style={{ ...this.props.style }}>
                {this.state.Icon ? (
                  <Tooltip title={this.state.Title}>
                    <IconButton
                      style={{
                        color: 'white',
                      }}
                      onClick={this.openComposeDialog}
                      className='dialog-header-icon'
                    >
                      <Icon>{this.state.Icon}</Icon>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.composeButton}
                    onClick={this.openComposeDialog}
                  >
                    {this.state.Title}
                  </Button>
                )}
              </div>
            )}
            <Dialog
              TransitionComponent={!matches.medium ? SlideUp : Grow}
              open={this.state.composeDialog}
              onClose={this.closeComposeDialog}
              aria-labelledby='form-dialog-title'
              maxWidth='md'
              fullWidth
              fullScreen={!matches.medium}
              classes={{ paper: !matches.medium ? 'full-screen-dialog' : '' }}
            >
              <AppBar position='static' className='dialog-header'>
                <Toolbar className='flex w-full'>
                  <Typography variant='subtitle1' color='inherit'>
                    {this.state.Title || 'New Email'}
                  </Typography>
                </Toolbar>
                <IconButton
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: matches.medium ? 8 : 4,
                    color: 'white',
                  }}
                  onClick={() => {
                    this.closeComposeDialog()
                  }}
                  className='dialog-header-icon'
                >
                  <Icon>close</Icon>
                </IconButton>
              </AppBar>

              <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                <ChipInput
                  blurBehavior='add'
                  className={classes.formControl}
                  variant='outlined'
                  label='To'
                  id='to'
                  autoComplete='off'
                  // value={
                  //   this.state.To && this.state.To.length > 0
                  //     ? this.state.To.split(';')
                  //     : []
                  // }
                  value={
                    this.state.To && this.state.To.length > 0
                      ? this.state.To.split(';')
                      : []
                  }
                  // value={() => emailUndefined(this.state.To)}
                  disableUnderline={true}
                  onAdd={this.handleAddRecipientManually}
                  onDelete={this.handleDeleteRecipient}
                  onUpdateInput={this.handleInputChange}
                  //   onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                  chipRenderer={({ value, handleDelete }, key) => {
                    if (value && value.length > 0)
                      return (
                        <Chip
                          icon={<Icon>person_icon</Icon>}
                          key={key}
                          className='mb-8 mr-4'
                          color='primary'
                          onDelete={handleDelete}
                          label={value}
                        />
                      )
                  }}
                  fullWidth
                  disabled={disableTo}
                  required
                />
                {this.state.isFocused &&
                  this.props.contacts &&
                  this.props.contacts.length > 0 && (
                    <div
                      className='relative top-0 py-8 w-full overflow-y-auto bg-white z-99 shadow-md'
                      style={{ height: 100 }}
                    >
                      {this.state.inputValue ? (
                        <div>
                          {this.state.filteredData.map((customer, index) => {
                            return dropDownElement(
                              customer.Data.Contact.Email,
                              customer.Data.Contact.Name,
                              this.state.To,
                            )
                          })}
                        </div>
                      ) : (
                        <div>
                          {this.props.contacts.map((customer, index) => {
                            return dropDownElement(
                              customer.Data.Contact.Email,
                              customer.Data.Contact.Name,
                              this.state.To,
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )}
                {/* <TextField
                className={classes.formControl}
                label="To"
                id="to"
                name="To"
                value={this.state.To}
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
                required
                disabled={disableTo}
            /> */}

                <TextField
                  className={classes.formControl}
                  label='Subject'
                  id='subject'
                  name='Subject'
                  value={this.state.Subject}
                  onChange={this.handleChange}
                  variant='outlined'
                  required
                  fullWidth
                  disabled={disableSubject}
                />
                {!this.state.Body ? (
                  <React.Fragment>
                    <div style={{ marginTop: 0, marginBottom: -11 }}>
                      <label class='text-grey-dark text-12 ml-12 px-4 bg-white'>
                        Message *
                      </label>
                    </div>
                    <ReactQuill
                      ref={el => {
                        this.quillRef = el
                      }}
                      theme='snow'
                      className={classNames('w-full mb-24', classes.quill)}
                      value={this.state.Message}
                      name='Message'
                      modules={{
                        toolbar: toolbarOptions,
                        history: history,
                      }}
                      formats={formats}
                      onChange={this.handleEditorChange}
                    />
                  </React.Fragment>
                ) : (
                  <TextField
                    className={classes.formControl}
                    id='Message'
                    name='Message'
                    onChange={this.handleChange}
                    label='Message'
                    type='text'
                    multiline
                    rows={5}
                    variant='outlined'
                    fullWidth
                  />
                )}
                {this.state.Body && this.state.Body.length > 0 && (
                  <div
                    className='w-full mb-12'
                    style={{
                      border: '1px solid lightgrey',
                      maxWidth: '100%',
                      overflowX: 'auto',
                    }}
                  >
                    <iframe
                      id='html'
                      name='html'
                      ref={e => {
                        this.iframe = e
                      }}
                      srcDoc={html}
                      style={{ width: '100%', height: 350 }}
                    />
                    {/* {parse(this.state.Body)} */}
                  </div>
                )}

                <div className={classes.attachmentList}>
                  {this.state.DBAttachments &&
                    this.state.DBAttachments.map((attachment, index) => {
                      const fileSize = this.getFileSize(
                        attachment,
                        attachment.ContentLength,
                      )
                      return (
                        <DBAttachment
                          key={index}
                          file={attachment}
                          onRemove={() => this.removeDBAttachment(index)}
                          fileName={attachment.FileName}
                          size={fileSize}
                        />
                      )
                    })}
                  {Boolean(this.state.DBAttachment) && (
                    <AttachmentDialog
                      readOnly={true}
                      attachments={[this.state.DBAttachment]}
                      notes={this.state.DBAttachment.Notes}
                      onClose={() => this.setState({ DBAttachment: null })}
                      attachment={this.state.DBAttachment}
                      open={true}
                    />
                  )}
                  {this.state.Attachments &&
                    this.state.Attachments.map((attachment, index) => {
                      const fileSize = this.getFileSize(attachment)
                      return (
                        <Attachment
                          key={index}
                          file={attachment}
                          onRemove={() => this.removeAttachment(index)}
                          fileName={attachment.name}
                          size={fileSize}
                        />
                      )
                    })}
                </div>

                {this.state.selectedAttachment && (
                  <Dialog
                    classes={{
                      paper: classNames(
                        'mt-0 mb-0 w-full h-full',
                        !matches.medium && 'full-screen-dialog',
                      ),
                    }}
                    open={this.state.selectedAttachment}
                    onClose={() =>
                      this.setState({ ...this.state, selectedAttachment: null })
                    }
                    maxWidth='sm'
                    fullScreen={!matches.medium}
                  >
                    <AppBar position='static' className='dialog-header'>
                      <Toolbar className='flex w-full'>
                        <Typography variant='subtitle1' color='inherit'>
                          {this.state.selectedAttachmentName
                            ? this.state.selectedAttachmentName
                            : 'Attachment'}
                        </Typography>
                      </Toolbar>
                      <IconButton
                        style={{
                          position: 'absolute',
                          right: 10,
                          top: matches.medium ? 8 : 4,
                          color: 'white',
                        }}
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            selectedAttachment: null,
                          })
                        }}
                        className='dialog-header-icon'
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </AppBar>
                    <DialogContent
                      classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}
                    >
                      <iframe
                        src={this.state.selectedAttachment}
                        className='w-full overflow-auto'
                        style={{ height: 'calc(100vh - 158px)' }}
                      />
                    </DialogContent>
                  </Dialog>
                )}
              </DialogContent>

              <DialogActions className='dialog-actions justify-between pl-8 sm:pl-16'>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!this.canBeSubmitted()}
                    onClick={this.sendEmail}
                  >
                    Send
                  </Button>
                  <Tooltip title='Add Attachment' placement='top'>
                    <IconButton
                      onClick={e =>
                        this.setState({ attachmentsMenuEl: e.target })
                      }
                    >
                      <Icon>attach_file</Icon>
                    </IconButton>
                  </Tooltip>
                  {Boolean(this.state.attachmentsMenuEl) && (
                    <Menu
                      id='attachments-menu'
                      classes={{ maxHeight: 256, overflowY: 'auto' }}
                      anchorEl={this.state.attachmentsMenuEl}
                      open={Boolean(this.state.attachmentsMenuEl)}
                      onClose={() =>
                        this.setState({ attachmentsMenuEl: false })
                      }
                    >
                      <MenuItem
                        onClick={() => {
                          this.setState({ attachmentsMenuEl: false }, () =>
                            this.fileInput.click(),
                          )
                        }}
                      >
                        <Icon className='mr-8' color='primary'>
                          add_circle_outline
                        </Icon>
                        New File
                      </MenuItem>
                      {dbAttachments &&
                        _.filter(
                          dbAttachments,
                          o =>
                            _.findIndex(this.state.DBAttachments, {
                              Attachment: o.Attachment,
                            }) < 0,
                        ).map(attch => {
                          return (
                            <MenuItem
                              onClick={() => {
                                this.setState({
                                  attachmentsMenuEl: false,
                                  DBAttachments: [
                                    ...this.state.DBAttachments,
                                    attch,
                                  ],
                                })
                              }}
                            >
                              <Icon className='mr-8'>
                                {ext[attch.Extension.replace('.', '')]}
                              </Icon>
                              {attch.FileName}
                            </MenuItem>
                          )
                        })}
                    </Menu>
                  )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    onChange={this.fileSelectedHandler}
                    ref={fileInput => (this.fileInput = fileInput)}
                  />
                </div>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendEmail: Actions.sendEmail,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, contactsApp, academyApp }) {
  window['warn'](spReducers, contactsApp)
  return {
    // contacts: spReducers,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(MailCompose),
)
