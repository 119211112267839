import axios from 'axios/index';

export const SET_SERVICE_UNIT_TYPE_DATA = '[SPCONNECTION] SET SERVICE UNIT TYPE DATA';
export const GET_SERVICE_UNIT_TYPE_DATA = '[SPCONNECTION] GET SERVICE UNIT TYPE DATA';
export const SUBSCRIBE_SERVICE_UNIT_TYPE_LIST = '[SPCONNECTION] SUBSCRIBE SERVICE UNIT TYPE LIST';

export function getInitialServiceUnitTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ServiceUnitType`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getServiceUnitTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ServiceUnitType`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_SERVICE_UNIT_TYPE_DATA,
                payload: response.data
            })
        });
    }
}

export function setServiceUnitTypeData(data) {
    return {
        type: SET_SERVICE_UNIT_TYPE_DATA,
        payload: data
    };
}