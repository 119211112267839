import React, { Component } from 'react';
import { Snackbar, SnackbarContent, IconButton, withStyles, Icon } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    offline: CloudOffIcon,
    uploading: CloudUploadIcon,
    uploaded: CloudDoneIcon,
    refresh: RefreshIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: theme.palette.secondary.dark,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    refresh: {
        backgroundColor: '#5f5f5f',
    },
    uploading: {
        backgroundColor: theme.palette.primary.main,
    },
    uploaded: {
        backgroundColor: theme.palette.secondary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    offline: {
        backgroundColor: '#5f5f5f',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    // bigRound: {
    //     borderRadius: 16
    // }
});

function ConnectionNotification(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className,)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant, variant === "refresh" ? 'spin' : '')} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

ConnectionNotification.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'refresh', 'offline', 'uploading', 'uploaded']).isRequired,
};

const ConnectionNotificationWrapper = withStyles(styles1)(ConnectionNotification);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
});

class FuseMessage extends Component {

    handleClose = () => {
        this.setState({ open: false, message: '' });
    };

    render() {
        const { classes, options, state, hideMessage } = this.props;
        return (
            <Snackbar
                anchorOrigin={{ vertical: options.anchorOrigin.vertical, horizontal: options.anchorOrigin.horizontal }}
                classes={{ root: options.anchorOrigin.vertical === "top" ? "toast-top" : options.anchorOrigin.vertical === "bottom" ? "toast-bottom" : "" }}
                open={state}
                onClose={hideMessage}
                autoHideDuration={options.autoHideDuration}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <ConnectionNotificationWrapper
                    onClose={hideMessage}
                    variant={options.variant}
                    message={options.message}
                />
            </Snackbar>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        hideMessage: Actions.hideMessage
    }, dispatch);
}

function mapStateToProps({ fuse }) {
    return {
        state: fuse.message.state,
        options: fuse.message.options
    }
}


export default withStyles(styles2)(connect(mapStateToProps, mapDispatchToProps)(FuseMessage));
