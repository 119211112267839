import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import ServiceUnitCategoryList from 'main/content/apps/service-unit-categories/ServiceUnitCategoriesList';
import ServiceUnitCategoryHeader from 'main/content/apps/service-unit-categories/ServiceUnitCategoriesHeader';
import ServiceUnitCategorySidebarContent from 'main/content/apps/service-unit-categories/ServiceUnitCategoriesSidebarContent';
import ServiceUnitCategoriesLeftSidebarHeader from 'main/content/apps/service-unit-categories/ServiceUnitCategoriesLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import ServiceUnitCategoryDialog from 'main/content/apps/service-unit-categories/ServiceUnitCategoryDialog';
import ServiceUnitTypeDialog from 'main/content/apps/service-unit-types/ServiceUnitTypeDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class ServiceUnitCategoriesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getServiceUnitCategories(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewServiceUnitCategoryDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <ServiceUnitCategoryHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <ServiceUnitCategoriesLeftSidebarHeader />
                            }
                            content={
                                <ServiceUnitCategoryList />
                            }
                            leftSidebarContent={
                                <ServiceUnitCategorySidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <ServiceUnitCategoryDialog />
                        <ServiceUnitTypeDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServiceUnitCategories: Actions.getServiceUnitCategories,
        getUserData: Actions.getUserData,
        openNewServiceUnitCategoryDialog: Actions.openNewServiceUnitCategoryDialog
    }, dispatch);
}

function mapStateToProps({ serviceUnitCategoriesApp }) {
    return {
        serviceUnitCategories: serviceUnitCategoriesApp.serviceUnitCategories.entities,
        selectedServiceUnitCategoryIds: serviceUnitCategoriesApp.serviceUnitCategories.selectedServiceUnitCategoryIds,
        searchText: serviceUnitCategoriesApp.serviceUnitCategories.searchText,
        user: serviceUnitCategoriesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitCategoriesApp)));
