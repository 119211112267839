import React from "react";
import ConfirmationDialog from "main/content/components/dialogs/confirmation";
import { Icon } from "@material-ui/core";

const DuplicateCustomerWarningDialog = ({ open,
    isCreatingCustomer,
    duplicatedEntitiesList,
    onConfirm,
    onCancel,
    onViewExistingCustomer,
    onSelectExistingCustomer }) => {
    return <ConfirmationDialog
        open={open}
        title={
            <div>
                <Icon className="mr-6 align-middle mb-4 text-orange" color="error">warning</Icon>
                <span className="text-orange">Duplicated Customer Warning</span>
            </div >
        }
        content={
            <div className="w-full">
                <span>{isCreatingCustomer ?
                    `The customer you are trying to create seems to be a duplicate of customer #${duplicatedEntitiesList[0].Customer}, do you want to create it anyway?`
                    : `The customer you are trying to update seems to be a duplicate of customer #${duplicatedEntitiesList[0].Customer}, do you want to update it anyway?`}
                </span>
                <br /><br />
                {
                    onViewExistingCustomer != undefined &&
                        onViewExistingCustomer != null ?
                        (<span>Click <a className="clickable italic mt-12 font-bold" role="button" onClick={onViewExistingCustomer}>here</a> to see the existing customer details</span>) : ''
                }

                {
                    onSelectExistingCustomer != undefined &&
                        onSelectExistingCustomer != null ?
                        (<span>Click <a className="clickable italic mt-12 font-bold" role="button"
                            onClick={() => {
                                onSelectExistingCustomer(duplicatedEntitiesList[0])
                            }}>here</a> to use the existing customer</span>) : ''
                }
            </div>
        }
        confirmText={`${isCreatingCustomer ? 'Create' : 'Save'} customer anyway`}
        cancelText="Cancel"
        onCancel={onCancel}
        onConfirm={onConfirm}
    />
}

export default DuplicateCustomerWarningDialog;