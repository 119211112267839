import { Capacitor } from "@capacitor/core";
import {
  BrowserVault,
  Device,
  DeviceSecurityType,
  Vault,
  VaultType,
} from "iidvault";
import { userLogIn } from "store/actions";

export const INITIALIZE_VAULT = "[IDENTITY VAULT] INITIALIZE VAULT";
export const CLEAR_VAULT = "[IDENTITY VAULT] CLEAR VAULT";
export const SET_VAULT_TYPE = "[IDENTITY VAULT] SET VAULT TYPE";
export const SET_VAULT_IS_LOCKED = "[IDENTITY VAULT] SET VAULT IS LOCKED";
export const SET_LOCK_TYPES = "[IDENTITY VAULT] SET LOCK TYPES";
export const SET_LOCK_TYPE = "[IDENTITY VAULT] SET LOCK TYPE";
export const LOCK_VAULT = "[IDENTITY VAULT] LOCK VAULT";
export const UNLOCK_VAULT = "[IDENTITY VAULT] UNLOCK VAULT";
export const SET_INSTANCE = "[IDENTITY VAULT] SET INSTANCE";

const config = {
  key: "com.servicepointpro.native",
  type: VaultType.SecureStorage,
  deviceSecurityType: DeviceSecurityType.None,
  lockAfterBackgrounded: 5000,
  shouldClearVaultAfterTooManyFailedAttempts: true,
  customPasscodeInvalidUnlockAttempts: 3,
  unlockVaultOnLoad: false,
};

export function initializeVault() {
  return (dispatch, getState) => {
    let { vault, lockType } = getState().spAuth.vault;
    const vaultType = getConfigUpdates(lockType);
    if (!vault) {
      vault =
        Capacitor.getPlatform() === "web"
          ? new BrowserVault({ ...config, ...vaultType })
          : new Vault({ ...config, ...vaultType });
      vault.isLocked().then(() => dispatch(setVaultIsLocked(false)));
    }

    // window["warn"]('Vault: ', vault);

    dispatch({
      type: INITIALIZE_VAULT,
      payload: vault,
    });
    dispatch(getLockTypes());
  };
}

function getConfigUpdates(lockType) {
  switch (lockType) {
    case "Biometrics":
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.Biometrics,
      };
    case "SystemPasscode":
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.SystemPasscode,
      };
    default:
      return {
        type: VaultType.SecureStorage,
        deviceSecurityType: DeviceSecurityType.None,
      };
  }
}

export function getLockTypes() {
  const lockTypes = {
    biometrics: false,
    passcode: false,
  };
  return (dispatch) => {
    if (window.cordova) {
      Device.isBiometricsEnabled().then((bio) => {
        lockTypes.biometrics = bio;
        Device.isSystemPasscodeSet().then((passcode) => {
          lockTypes.passcode = passcode;
          dispatch({
            type: SET_LOCK_TYPES,
            payload: lockTypes,
          });
        });
      });
    } else {
      dispatch({
        type: SET_LOCK_TYPES,
        payload: lockTypes,
      });
    }
  };
}

export function setLockType(lockType) {
  return (dispatch, getState) => {
    const { vault } = getState().spAuth.vault;
    if (lockType !== "Biometrics") {
      dispatch(clearVault());
    }
    const { type, deviceSecurityType } = getConfigUpdates(lockType);
    vault.updateConfig({ ...vault.config, type, deviceSecurityType });
    dispatch({
      type: SET_LOCK_TYPE,
      payload: lockType,
    });
  };
}

export function setVaultIsLocked(bool) {
  return {
    type: SET_VAULT_IS_LOCKED,
    payload: bool,
  };
}

export function clearVault() {
  return (dispatch, getState) => {
    let { vault } = getState().spAuth.vault;
    if (vault) {
      vault.clear().then(() => {
        dispatch({
          type: CLEAR_VAULT,
        });
      });
    }
  };
}

export function biometricLogin(version, os) {
  return (dispatch, getState) => {
    let { vault } = getState().spAuth.vault;
    vault.getValue("api").then((client) => {
      if (client) {
        vault.getValue("unm").then((user) => {
          if (user) {
            vault.getValue("c2").then((p) => {
              if (p) {
                dispatch(userLogIn(client, user, window.atob(p), version, os));
              } else {
                dispatch(setLockType("NoLocking"));
              }
            });
          } else {
            dispatch(setLockType("NoLocking"));
          }
        });
      } else {
        dispatch(setLockType("NoLocking"));
      }
    });
  };
}

export function lockVault() {
  return (dispatch, getState) => {
    const { vault } = getState().spAuth.vault;
    vault.lock().then(() => {
      dispatch({
        type: LOCK_VAULT,
      });
    });
  };
}

export function unlockVault() {
  return (dispatch, getState) => {
    const { vault } = getState().spAuth.vault;
    vault.unlock().then(() => {
      dispatch({
        type: UNLOCK_VAULT,
      });
    });
  };
}
