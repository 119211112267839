import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Dialog, DialogContent, DialogActions, AppBar, Slide, Grow, Toolbar, Divider, Icon, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import TripStatusTerminology from './settings/TripStatusTerminology';
import WorkOrderOptions from './settings/WorkOrderOptions';
import PurchaseOrderOptions from './settings/PurchaseOrderOptions';
import _ from '@lodash';
import classNames from 'classnames';
import Media from 'react-media';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? !isNaN(props.padding) ? props.padding : undefined : 8 * 3, minHeight: 'calc(100vh - 128px)', maxHeight: 'calc(100vh - 128px)', overflow: 'auto' }} {...props}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    dialogRoot: {

    },
    dialogContentRoot: {
        mineight: 'calc(100% - 76px)',
        maxHeight: 'calc(100% - 76px)',
    },
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    borderTop: {
        borderTop: '1px solid lightgrey',
        paddingTop: 4,
        paddingBottom: 4,
    },
    liRoot: {
        padding: 0
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '70%',
    },
    wrap: {
        whiteSpace: 'normal !important',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '70%',
    },
});

const initialState = {
    open: false,
}

class CompanyModule extends Component {

    state = {
        ..._.cloneDeepWith(initialState)
    }

    componentDidMount() {

    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    components = {
        company_module: CompanyModule,
        trip_status_terminology: TripStatusTerminology,
        work_order_options: WorkOrderOptions,
        purchase_order_options: PurchaseOrderOptions,
    }

    render() {
        const { classes, user, data } = this.props;
        const { open } = this.state;
        const ChildComponent = (data.settings && Boolean(this.components[data.settings])) ? this.components[data.settings] : null;
        return (
            <div className={classNames("w-full p-12", (data.children && data.children.length > 0 && open) ? "pb-0" : "", classes.borderTop)}>
                <ListItem onClick={(e) => this.setState({ open: !this.state.open })} classes={{ root: classes.liRoot }} className="relative cursor-pointer min-h-36">
                    {data.icon &&
                        <Icon className="mr-6" color="action">{data.icon}</Icon>
                    }
                    <ListItemText classes={{ root: classes.liRoot, primary: classes.title }} primary={<span className={classNames("font-bold text-14")}>{data.title}
                    </span>} secondary={data.description ? <span className="font-bold text-12">{<Typography variant="caption" className={classNames(classes.subheader, open ? classes.wrap : "")}>{data.description}</Typography>}</span> : undefined} />
                    <Icon className="pin-r pin-b pin-t absolute m-auto">{data.settings ? "keyboard_arrow_right" : open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                </ListItem>
                {
                    (open && Boolean(ChildComponent)) &&
                    <Media query="(min-width: 1200px)"/**/>
                        {matches =>
                            <Dialog
                            TransitionComponent={!matches ? SlideUp : Grow}
                                classes={{
                                    root: classes.dialogRoot,
                                    paper: matches ? "m-24" : null
                                }}
                                open={open}
                                fullScreen={!matches}
                                className={classes.dialogRoot}
                                onClose={() => this.setState({ open: !this.state.open })}
                                fullWidth
                                maxWidth="md"
                            >

                                <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                                    <Toolbar className="flex w-full">
                                        <Icon className="cursor-pointer text-32 mr-8">{data.icon}</Icon>
                                        <Typography variant="subtitle1" color="inherit">
                                            {data.title}
                                        </Typography>
                                    </Toolbar>
                                    <IconButton style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                        color: 'white'
                                    }}
                                        onClick={() => this.setState({ open: !this.state.open })}
                                    >
                                        <Icon>close</Icon>
                                    </IconButton>
                                </AppBar>
                                <TabContainer className="relative">
                                    <ChildComponent classes={classes} key={data.id} data={{ ...data, component: undefined }} />
                                </TabContainer>
                            </Dialog>
                        }
                    </Media>
                }
                {
                    (open && data.children && data.children.length > 0) ?
                        <div className="w-full pl-12 ml-12 mt-12">
                            <FuseAnimateGroup
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                                leave={{
                                    animation: "transition.slideUpBigOut"
                                }}
                            >
                                {data.children.map((child) => <CompanyModule classes={classes} key={child.id} data={child} />)}
                            </FuseAnimateGroup>
                        </div>
                        : ''
                }
            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ companiesApp, spReducers }) {
    return {
        user: spReducers.userProfiles.User
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyModule)));
