import axios from 'axios';
import { getUserData } from 'main/content/apps/fleet-vehicle-categories/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_FLEET_VEHICLE_CATEGORIES = '[FLEET VEHICLE CATEGORIES APP] GET FLEET VEHICLE CATEGORIES';
export const SET_SEARCH_TEXT = '[FLEET VEHICLE CATEGORIES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_FLEET_VEHICLE_CATEGORIES = '[FLEET VEHICLE CATEGORIES APP] TOGGLE IN SELECTED FLEET VEHICLE CATEGORIES';
export const SELECT_ALL_FLEET_VEHICLE_CATEGORIES = '[FLEET VEHICLE CATEGORIES APP] SELECT ALL FLEET VEHICLE CATEGORIES';
export const DESELECT_ALL_FLEET_VEHICLE_CATEGORIES = '[FLEET VEHICLE CATEGORIES APP] DESELECT ALL FLEET VEHICLE CATEGORIES';
export const OPEN_NEW_FLEET_VEHICLE_CATEGORY_DIALOG = '[FLEET VEHICLE CATEGORIES APP] OPEN NEW FLEET VEHICLE CATEGORY DIALOG';
export const CLOSE_NEW_FLEET_VEHICLE_CATEGORY_DIALOG = '[FLEET VEHICLE CATEGORIES APP] CLOSE NEW FLEET VEHICLE CATEGORY DIALOG';
export const OPEN_EDIT_FLEET_VEHICLE_CATEGORY_DIALOG = '[FLEET VEHICLE CATEGORIES APP] OPEN EDIT FLEET VEHICLE CATEGORY DIALOG';
export const CLOSE_EDIT_FLEET_VEHICLE_CATEGORY_DIALOG = '[FLEET VEHICLE CATEGORIES APP] CLOSE EDIT FLEET VEHICLE CATEGORY DIALOG';
export const ADD_FLEET_VEHICLE_CATEGORY = '[FLEET VEHICLE CATEGORIES APP] ADD FLEET VEHICLE CATEGORY';
export const UPDATE_FLEET_VEHICLE_CATEGORY = '[FLEET VEHICLE CATEGORIES APP] UPDATE FLEET VEHICLE CATEGORY';
export const REMOVE_FLEET_VEHICLE_CATEGORY = '[FLEET VEHICLE CATEGORIES APP] REMOVE FLEET VEHICLE CATEGORY';
export const REMOVE_FLEET_VEHICLE_CATEGORIES = '[FLEET VEHICLE CATEGORIES APP] REMOVE FLEET VEHICLE CATEGORIES';
export const TOGGLE_STARRED_FLEET_VEHICLE_CATEGORY = '[FLEET VEHICLE CATEGORIES APP] TOGGLE STARRED FLEET VEHICLE CATEGORY';
export const TOGGLE_STARRED_FLEET_VEHICLE_CATEGORIES = '[FLEET VEHICLE CATEGORIES APP] TOGGLE STARRED FLEET VEHICLE CATEGORIES';
export const SET_FLEET_VEHICLE_CATEGORIES_STARRED = '[FLEET VEHICLE CATEGORIES APP] SET FLEET VEHICLE CATEGORIES STARRED ';

export function getFleetVehicleCategories(routeParams) {
    const request = axios.get('/api/fleetVehicleCategories-app/fleetVehicleCategories', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_FLEET_VEHICLE_CATEGORIES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedFleetVehicleCategories(fleetVehicleCategoryId) {
    return {
        type: TOGGLE_IN_SELECTED_FLEET_VEHICLE_CATEGORIES,
        fleetVehicleCategoryId
    }
}


export function selectAllFleetVehicleCategories() {
    return {
        type: SELECT_ALL_FLEET_VEHICLE_CATEGORIES
    }
}

export function deSelectAllFleetVehicleCategories() {
    return {
        type: DESELECT_ALL_FLEET_VEHICLE_CATEGORIES
    }
}


export function openNewFleetVehicleCategoryDialog() {
    return {
        type: OPEN_NEW_FLEET_VEHICLE_CATEGORY_DIALOG
    }
}

export function closeNewFleetVehicleCategoryDialog() {
    return {
        type: CLOSE_NEW_FLEET_VEHICLE_CATEGORY_DIALOG
    }
}

export function openEditFleetVehicleCategoryDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/FleetVehicleCategory?Co=${data.Co}&Category=${encodeURIComponent(data.Category)}`);

        return request.then((response) =>
            dispatch({
                type: OPEN_EDIT_FLEET_VEHICLE_CATEGORY_DIALOG,
                data: response.data
            }));
    }
}

export function closeEditFleetVehicleCategoryDialog() {
    return {
        type: CLOSE_EDIT_FLEET_VEHICLE_CATEGORY_DIALOG
    }
}

export function addFleetVehicleCategory(newFleetVehicleCategory) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/FleetVehicleCategory`,
            newFleetVehicleCategory
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_FLEET_VEHICLE_CATEGORY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Fleet Vehicle Category #${newFleetVehicleCategory.Category} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateFleetVehicleCategory(fleetVehicleCategory) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/FleetVehicleCategory?Co=${encodeURIComponent(fleetVehicleCategory.Co)}&Category=${encodeURIComponent(fleetVehicleCategory.Category)}`,
            fleetVehicleCategory
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_FLEET_VEHICLE_CATEGORY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Fleet Vehicle Category #${fleetVehicleCategory.Category} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeFleetVehicleCategory(fleetVehicleCategoryId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehicleCategoriesApp.fleetVehicleCategories;

        const request = axios.post('/api/fleetVehicleCategories-app/remove-fleetVehicleCategory', {
            fleetVehicleCategoryId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FLEET_VEHICLE_CATEGORY
                })
            ]).then(() => dispatch(getFleetVehicleCategories(routeParams)))
        );
    };
}


export function removeFleetVehicleCategories(fleetVehicleCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehicleCategoriesApp.fleetVehicleCategories;

        const request = axios.post('/api/fleetVehicleCategories-app/remove-fleetVehicleCategories', {
            fleetVehicleCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FLEET_VEHICLE_CATEGORIES
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLE_CATEGORIES
                })
            ]).then(() => dispatch(getFleetVehicleCategories(routeParams)))
        );
    };
}

export function toggleStarredFleetVehicleCategory(fleetVehicleCategoryId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().fleetVehicleCategoriesApp.fleetVehicleCategories;

        const request = axios.post('/api/fleetVehicleCategories-app/toggle-starred-fleetVehicleCategory', {
            fleetVehicleCategoryId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_FLEET_VEHICLE_CATEGORY
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicleCategories(routeParams)))
        );
    };
}

export function toggleStarredFleetVehicleCategories(fleetVehicleCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehicleCategoriesApp.fleetVehicleCategories;

        const request = axios.post('/api/fleetVehicleCategories-app/toggle-starred-fleetVehicleCategories', {
            fleetVehicleCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_FLEET_VEHICLE_CATEGORIES
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLE_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicleCategories(routeParams)))
        );
    };
}

export function setFleetVehicleCategoriesStarred(fleetVehicleCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehicleCategoriesApp.fleetVehicleCategories;

        const request = axios.post('/api/fleetVehicleCategories-app/set-fleetVehicleCategories-starred', {
            fleetVehicleCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_FLEET_VEHICLE_CATEGORIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLE_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicleCategories(routeParams)))
        );
    };
}

export function setFleetVehicleCategoriesUnstarred(fleetVehicleCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().fleetVehicleCategoriesApp.fleetVehicleCategories;

        const request = axios.post('/api/fleetVehicleCategories-app/set-fleetVehicleCategories-unstarred', {
            fleetVehicleCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_FLEET_VEHICLE_CATEGORIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_FLEET_VEHICLE_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getFleetVehicleCategories(routeParams)))
        );
    };
}
