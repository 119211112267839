import {combineReducers} from 'redux';
import costTypes from './cost-types.reducer';
import user from './user.reducer';

const costTypesAppReducers = combineReducers({
    costTypes,
    user
});

export default costTypesAppReducers;
