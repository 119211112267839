import PurchaseOrderLinesApp from 'main/content/apps/purchase-order-lines/PurchaseOrderLinesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const PurchaseOrderLinesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/purchase-order-lines/:id',
            component: PurchaseOrderLinesApp
        },
        {
            path     : '/apps/purchase-order-lines',
            component: () => <Redirect to="/apps/purchase-order-lines/all"/>
        }
    ]
};
