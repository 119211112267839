import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedFormIds: [],
    routeParams: {},
    formDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    formSectionDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    formElementDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    formAssignmentDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
};

const formsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.OPEN_NEW_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'data',
                        props: {
                            ...state.formDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: action.data,
                    }
                };
            }
        case Actions.CLOSE_NEW_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: 'data',
                        props: {
                            ...state.formSectionDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_FORM_SECTION_DIALOG:
            {
                return {
                    ...state,
                    formSectionDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                    }
                };
            }
        case Actions.CLOSE_NEW_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    }
                };
            }
        case Actions.DATA_EDIT_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'data',
                        props: {
                            ...state.formElementDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_FORM_ELEMENT_DIALOG:
            {
                return {
                    ...state,
                    formElementDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
            case Actions.OPEN_NEW_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'new',
                            props: {
                                open: true
                            },
                            data: action.data,
                        }
                    };
                }
            case Actions.CLOSE_NEW_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'new',
                            props: {
                                open: false
                            },
                            data: null
                        }
                    };
                }
            case Actions.OPEN_EDIT_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'edit',
                            props: {
                                open: true
                            },
                            data: action.data,
                            loading: true,
                        }
                    };
                }
            case Actions.DATA_EDIT_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'data',
                            props: {
                                ...state.formAssignmentDialog.props
                            },
                            data: action.data
                        }
                    };
                }
            case Actions.ADD_EDIT_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'add',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
            case Actions.UPDATE_EDIT_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
            case Actions.CLOSE_EDIT_FORM_ASSIGNMENT_DIALOG:
                {
                    return {
                        ...state,
                        formAssignmentDialog: {
                            type: 'edit',
                            props: {
                                open: false
                            },
                            data: null
                        }
                    };
                }
        default:
            {
                return state;
            }
    }
};

export default formsReducer;
