import _ from '@lodash';
import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Chip, FormControlLabel, Icon, IconButton, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import ImageDialog from '../catalog/ImageDialog';
import * as Actions from './store/actions';
import CatalogRecommendationPopup from './CatalogRecommendationPopup';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/logos/SP_Cog_Gradient.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        overflow: 'hidden',
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    cardSubheader: {
        fontSize: 11,
    },
    avatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    completedAvatar: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 6,
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    hoverable: {
        "& .showOnHover": {
            display: 'none',
        },
        "&:hover .showOnHover":
        {
            display: 'block'
        },
        // "&:active": {
        //     transform: 'scale(.95)'
        // }
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    scaleButton: {
        "&:active": {
            transform: 'scale(.95)'
        }
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    progress: {
        width: 48,
        height: 48,
        margin: 'auto',
        marginBottom: -36,
        top: -30,
    },
    roundProgress: {
        width: '72px !important',
        height: '72px !important',
    },
    avatar: {
        backgroundColor: '#fff !important',
        width: 48,
        height: 48,
    },
    labels: {},
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
});

const initialState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Category: null,
    Type: null,
    Name: null,
    Description: null,
    ActiveYN: null,
    ParentCategory: null,
    DefaultRateTemplate: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    PRCode: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Data: {
        ErrMsg: null,
        Image: null,
        Images: [],
        Categories: [],
        Assignments: [],
        Upgrades: [],
        Recommendations: [],
        Calculation: null
    },
    open: false,
    editing: false,
    imgSearch: false,
    showRecommendations: false,
    confirmDelete: false
};

class CatalogAssemblyCard extends Component {

    state = {
        ...initialState
    }

    ref = React.createRef();

    componentDidMount() {
        const { value } = this.props;

        // document.addEventListener('click', this.closeRecommendations, true);
        this.setState({ ...initialState, ...value });
    }

    componentWillUnmount() {
        // document.removeEventListener('click', this.closeRecommendations, true);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value) || value.AddonYN !== this.state.AddonYN) {
            this.setState({ ...this.state, ...value, AddonYN: value.AddonYN });
        }
    }

    hasAlpha(context, canvas) {
        var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
            hasAlphaPixels = false;
        for (var i = 3, n = data.length; i < n; i += 4) {
            if (data[i] < 255) {
                hasAlphaPixels = true;
                break;
            }
        }
        return hasAlphaPixels;
    }

    // checkAlpha(src) {
    //     var canvas = document.createElement('canvas');

    //     var img = new Image();
    //     img.onload = function () {
    //         canvas.width = 100;
    //         canvas.height = 100;
    //         ctx.drawImage(img, 0, 0, 100, 100);
    //         document.getElementById("image").src = canvas.toDataURL(src);
    //     }
    //     img.src = src;

    //     var ctx = canvas.getContext('2d');
    //     reader.readAsDataURL(e.target.files[0]);
    // }

    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ ...this.state, open: !open });
    }

    toggleEdit = (e) => {
        e.stopPropagation();
        const { editing } = this.state;
        this.setState({ ...this.state, editing: !editing, open: true });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    canBeSubmitted = () => {
        const { Name, Description, Category } = this.state;
        return Name && Name.length > 0 && Description && Description.Length > 0 && Category && !isNaN(Category);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    addComponent = (e, addOn) => {
        e.stopPropagation();
        const { estimate, onAddComponent, isMember, onAdded, SelectedPricing } = this.props;
        if (estimate && estimate.Data && estimate.Data.Components) {
            var rec = _.cloneDeepWith(this.state);
            const { Components } = _.cloneDeepWith(estimate.Data);
            const last = _.maxBy(Components, 'Component');
            // delete rec.Data;
            const { Co, BusinessUnit, Division, Qty, Assembly, TaxableYN, AddonYN } = rec;
            const { Estimate, OptionBoard, Option } = estimate;
            const alreadyExists = _.find(Components, { Assembly });

            let actualPrice = 0;
            if (!SelectedPricing || SelectedPricing === "S") {
                actualPrice = this.state.AddonYN == 'Y' ? this.state.AddOnPrice : this.state.Price
            } else if (SelectedPricing === "M") {
                actualPrice = this.state.AddonYN == 'Y' ? this.state.AddOnMemberPrice : this.state.MemberPrice
            } if (SelectedPricing === "P") {
                actualPrice = this.state.PremiumPrice ? this.state.AddonYN == 'Y' ? this.state.PremiumAddOnPrice ? this.state.PremiumAddOnPrice : this.state.PremiumPrice : this.state.PremiumPrice : this.state.AddonYN == 'Y' ? this.state.AddOnPrice : this.state.Price
            }

            const component = {
                Co,
                BusinessUnit,
                Division,
                Estimate,
                OptionBoard,
                Option,
                Component: (last ? last.Component : 0) + 1,
                Type: 0,
                Qty: Qty || 1,
                UOM: 'EA',
                Assembly,
                Price: Number(this.state.Qty || 1) * Number(actualPrice),
                //Price: Number(this.state.Qty || 1) * Number(isMember ? this.state.MemberPrice : this.state.AddonYN === 'Y' ? this.state.AddOnPrice : this.state.Price) || 0,
                PriceMethod: 'F',
                TaxableYN,
                AddonYN: addOn ? 'Y' : AddonYN,
                Data: {
                    Assembly: rec,
                }
            };
            Components.push(component);
            window["warn"]('New Component List: ', Components);
            if (onAddComponent) {
                onAddComponent(Components);
            }
            // this.setState({estimate, component});
            window["warn"](estimate);
            if (!alreadyExists) {
                this.setState({ ...this.state, showRecommendations: true });
            }
        }
    }

    closeRecommendations = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({ ...this.state, showRecommendations: false })
        }
    }

    deleteAssembly = () => {
        const { component, estimate, onRemoved } = this.props;
        const { Qty, Price } = _.cloneDeepWith(component);
        const { Components } = _.cloneDeepWith(estimate.Data);
        const index = _.findIndex(Components, { Component: component.Component });
        const comp = _.find(Components, { Component: component.Component });

        if (comp.ID) {
            comp.DeleteYN = 'Y';
            Components.splice(index, 1, comp);
        } else {
            Components.splice(index, 1);
        }
        if (onRemoved) {
            onRemoved(Components);
        }
    }

    render() {
        const { classes, value, category, user, securables, sidebar, topBar, component, estimate, option, dialog, catalogs, onClickBefore, rateTemplates, isMember, companies, onSelected, fullScreen, optionBoards, SelectedPricing } = this.props;
        // window["warn"]('Estimate Assembly: ', catalog, value);
        if (component) {
            window["warn"]('Estimate Material: ', value, _.cloneDeepWith(component));
        }
        // window["warn"]('Catalog Categories', Categories);
        const { open, editing, imgSearch, showRecommendations } = this.state;
        const defaultImg = 'assets/images/logos/SP_Cog_Gradient.png';
        const accessLevel = _.find(securables, { Securable: "catalog" });
        if (value && value.Data && value.Data.Images && _.filter(this.state.Data.Images, (o) => o.DeleteYN !== "Y").length > 0) {
            value.Data.Image = _.find(value.Data.Images, (o) => (o.PrimaryYN === 'Y' && o.DeleteYN !== 'Y'));
            if (!value.Data.Image) {
                value.Data.Image = _.filter(this.state.Data.Images, (o) => o.DeleteYN !== "Y")[0];
            }
        } else {
            if (value.Assembly) {
                value.Data.Image = null;
            }
        }
        const { Co, BusinessUnit, Division, Assembly, Category } = value;
        const co = _.find(companies, { Co }).Data.Co;
        const catalog = _.find([...catalogs], { Co, BusinessUnit, Division });
        const { Data } = catalog || {};
        const { Categories, Assemblies: tasks } = Data || {};
        const optionsCount = (optionBoards ? _.filter(optionBoards, (o) => o.StandardYN === 'Y' && o.Category === value.Category).length : null)

        let actualPrice = "$0";
        if (value.Assembly && value.Price) {
            if (!SelectedPricing || SelectedPricing === "S") {
                actualPrice = this.formatDollars(value.AddonYN == 'Y' ? value.AddOnPrice : value.Price)
            } else if (SelectedPricing === "M") {
                actualPrice = this.formatDollars(value.AddonYN == 'Y' ? value.AddOnMemberPrice : value.MemberPrice)
            } if (SelectedPricing === "P") {
                actualPrice = value.PremiumPrice ? this.formatDollars(value.AddonYN == 'Y' ? value.PremiumAddOnPrice ? value.PremiumAddOnPrice : value.PremiumPrice : value.PremiumPrice) : this.formatDollars(value.AddonYN == 'Y' ? value.AddOnPrice : value.Price)
            }
        }

        // this.formatDollars(isMember ? (value.AddonYN == 'Y' ? value.AddOnMemberPrice : value.MemberPrice) : (value.AddonYN == 'Y' ? value.AddOnPrice : value.Price))

        return (
            <div className={classNames(sidebar ? "w-full p-6" : topBar ? "w-160 min-w-160 p-6 m-0" : component ? "w-1/2 p-6 m-0" : fullScreen ? "w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 p-4 pt-8 sm:p-12" : "w-1/2 sm:w-1/3 md:w-1/4 p-4 pt-8 sm:p-12")}>
                <Card
                    onClick={(e) => {
                        if (value.Assembly && onSelected) {
                            this.props.onSelected(value);
                        } else {
                            if (onClickBefore) {
                                Promise.all([
                                    onClickBefore(e)
                                ]).then(() => {
                                    if (value.Assembly) {
                                        this.props.openEditCatalogItemDialog(value, estimate, option);
                                    }
                                });
                            } else {
                                if (value.Assembly) {
                                    this.props.openEditCatalogItemDialog(value, estimate, option);
                                } else {
                                    if (this.props.onClick) {
                                        this.props.onClick(e);
                                    }
                                }
                            }
                        }
                    }}
                    className={classNames("mt-8 w-full relative cursor-pointer rounded-lg relative", value.Assembly && !component ? "pb-40" : "", !component ? "h-full" : "")}>
                    {component &&
                        <div className={classNames("pin-l pin-t absolute text-center p-8 py-4 font-bold z-10 rounded-tl-lg rounded-br-lg")} style={{ backgroundColor: `#3f3f3f`, border: '1px solid #fff' }}>
                            <div className="w-full text-12 text-white">{component.Qty}</div>
                        </div>
                    }
                    {optionsCount &&
                        <div className="absolute pin-t pin-r p-6 py-4 rounded-tr-lg rounded-bl-lg text-12 font-bold z-10" style={{ background: '#3f3f3f', color: '#fff' }}>{optionsCount} Board{optionsCount !== 1 ? 's' : ''}</div>
                    }
                    {(this.state.Code || (component && component.AddonYN === 'Y')) &&
                        <div className="absolute pin-t pin-r p-6 py-4 rounded-tr-lg rounded-bl-lg text-12 font-bold z-10" style={{ background: '#3f3f3f', color: '#fff' }}>{this.state.Code || "ADD-ON"}{this.state.Code && component && component.AddonYN === 'Y' && <React.Fragment><br /><div className="w-full text-center font-bold" style={{ fontSize: 8, marginTop: -2, marginBottom: -2, color: '#d1e751' }}>ADD-ON</div></React.Fragment>}</div>
                    }
                    <CardMedia
                        component="image"
                        className={!component ? "h-160 sm:h-192 w-full relative" : "h-128 w-full relative"}
                        image={(this.state.Data && this.state.Data.Image && this.state.Data.Image.ImgUrl) ? this.state.Data.Image.ImgUrl : "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"}
                        onClick={(e) => { if (editing && !value.Assembly) { e.stopPropagation(); this.setState({ ...this.state, imgSearch: !imgSearch }) } }}
                        style={(!this.state.Data || !this.state.Data.Image || !this.state.Data.Image.ImgUrl) ? {
                            background: catalog && catalog.EstimateHeaderImg ? 'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,0)), url(assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg)' : '#fff',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        } : undefined}
                        alt={value.Name}
                    >
                        {(!this.state.Data || !this.state.Data.Image || !this.state.Data.Image.ImgUrl) &&
                            <img src={catalog ? catalog.EstimateHeaderImg || co.ImgUrl : co.ImgUrl || "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"} className={catalog && catalog.EstimateHeaderImg ? "pin-t pin-b pin-l pin-r m-auto absolute w-5/6" : "pin-t pin-b pin-l pin-r m-auto absolute"} />
                        }
                    </CardMedia>
                    <CardContent className={editing ? "pb-6" : ""} classes={{ root: "px-6 py-12 pb-0 has-hover" }}>
                        <div className={classNames(classes.progress, "relative")}>
                            <Avatar className={classNames(classes.avatar, "absolute pin-t cursor-pointer")}>
                                {
                                    component ?
                                        <Icon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const { Qty, Price } = _.cloneDeepWith(component);
                                                const unitPrice = Price / Qty;
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                const index = _.findIndex(Components, { Component: component.Component });
                                                if (index > -1) {
                                                    const comp = _.find(Components, { Component: component.Component });
                                                    if (Qty > 1) {
                                                        comp.Qty -= 1;
                                                        comp.Price -= unitPrice;
                                                        if (this.props.onUpdated) {
                                                            this.props.onUpdated(Components);
                                                        }
                                                    } else {
                                                        if (this.props.multipleOfSameComponent) {
                                                            if (comp.ID) {
                                                                comp.DeleteYN = 'Y';
                                                                Components.splice(index, 1, comp);
                                                            } else {
                                                                Components.splice(index, 1);
                                                            }
                                                            if (this.props.onRemoved) {
                                                                this.props.onRemoved(Components);
                                                            }
                                                        } else {
                                                            this.setState({ ...this.state, confirmDelete: true })
                                                        }
                                                    }
                                                }
                                            }}
                                            className="text-36 font-500"
                                            style={{ color: '#5f5f5f' }}>remove_circle_outline</Icon> :
                                        value.Assembly ?
                                            <SPRoundIcon className="text-36" style={{ color: '#5f5f5f' }} /> :
                                            (!accessLevel || accessLevel.AccessLevel === "R" || (accessLevel.AccessLevel === "E" || user === value.AddedBy)) ?
                                                <Icon className="text-28" style={{ color: '#5f5f5f' }}>apps</Icon> :
                                                (isMobile && open && !this.props.estimate) ?
                                                    <Icon onClick={this.toggleEdit} className="text-28" style={{ color: '#5f5f5f' }}>edit</Icon> :
                                                    editing ?
                                                        <Icon onClick={this.toggleEdit} className="text-28" style={{ color: '#5f5f5f' }}>edit</Icon> :
                                                        !this.props.estimate ?
                                                            <React.Fragment>
                                                                <Icon onClick={this.toggleEdit} className="text-28 on-hover" style={{ color: '#5f5f5f' }}>edit</Icon>
                                                                <Icon className="text-28 hide-on-hover" style={{ color: '#5f5f5f' }}>apps</Icon>
                                                            </React.Fragment> :
                                                            <Icon className="text-28" style={{ color: '#5f5f5f' }}>apps</Icon>
                                }
                            </Avatar>
                            {/* <CircularProgress className={classNames("m-0", classes.roundProgress)} color={step > 0 && step >= value.Steps ? "secondary" : "primary"} variant="determinate" value={value.Steps && value.Steps > 0 && step > 0 ? (step / value.Steps) * 100 : 0} /> */}
                        </div>
                        {!editing ?
                            <React.Fragment>
                                {/* {!this.props.estimate && !value.Assembly && isMobile && open && ((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ?
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: -12,
                                            top: -24,
                                        }}
                                        onClick={this.toggleEdit}
                                    >
                                        <Icon color="action">edit</Icon>
                                    </IconButton>
                                    : ''}
                                {!this.props.estimate && !value.Assembly && !isMobile && ((accessLevel && accessLevel.AccessLevel === "E" && user === value.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ?
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: -12,
                                            top: -24,
                                        }}
                                        onClick={this.toggleEdit}
                                    >
                                        <Icon color="action">edit</Icon>
                                    </IconButton>
                                    : ''} */}
                                <Typography onClick={(e) => { e.stopPropagation(); this.setState({ open: !open }) }} gutterBottom variant="h6" style={{ fontSize: 16 }} component="div" className="flex">
                                    <div className={!open && component ? "truncate w-full text-center" : "w-full text-center"}>{(component && component.Name) ? component.Name : value.Name}</div>
                                </Typography>
                            </React.Fragment>
                            :
                            <div style={{ backgroundColor: '#fff' }} className="relative pt-16 pb-36" onClick={(e) => e.stopPropagation()}>
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: -12,
                                        top: -24,
                                    }}
                                    onClick={(e) => { e.stopPropagation(); this.setState({ ...this.state, editing: false, open: false }); }}
                                >
                                    <Icon color="action">close</Icon>
                                </IconButton>
                                {imgSearch &&
                                    <ImageDialog category="assets/images/assembly-categories/" onChange={(ImgUrl) => this.setState({ ...this.state, Data: { ...this.state.Data, Image: this.state.Data.Image ? { ...this.state.Data.Image, ImgUrl } : { Co, BusinessUnit, Division, Category, ImgUrl } } })} open={imgSearch} onClose={() => this.setState({ ...this.state, imgSearch: false })} />
                                    // <CatalogAssemblyCategoryImageDialog open={imgSearch} onClose={() => this.setState({ ...this.state, imgSearch: !imgSearch })} category={this.state} onChange={(Image) => this.setState({ ...this.state, Data: { ...this.state.Data, Image } })} />
                                }

                                <TextField
                                    className={classes.formControl}
                                    label="Name"

                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    margin="dense"
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                {/* 
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    margin="dense"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                /> */}

                                <TextField
                                    className={classes.formControl}
                                    label="Contains"
                                    id="type"
                                    name="Type"
                                    value={this.state.Type}
                                    margin="dense"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    select
                                >
                                    <MenuItem value="C" disabled={_.findIndex(catalog.Data.Assignments, { Category: this.state.Category }) > -1}>
                                        Categories
                                    </MenuItem>
                                    <MenuItem value="T" disabled={_.findIndex(catalog.Data.Categories, { ParentCategory: this.state.Category }) > -1}>
                                        Tasks / Assemblies
                                    </MenuItem>
                                </TextField>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Rate Template"
                                    options={[{ RateTemplate: null, Description: 'Default' }, ..._.filter(rateTemplates, { BusinessUnit, Division })]}
                                    menuItemComponent={(value) => {
                                        return (
                                            <MenuItem value={value.RateTemplate}>
                                                {`${value.Description}`}
                                            </MenuItem>
                                        );
                                    }}
                                    portal={true}
                                    moreProps={{ margin: 'dense' }}
                                    value={this.state.DefaultRateTemplate || ''}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultRateTemplate: option.RateTemplate, DefaultRateTemplateDivision: option.Division })}
                                />
                                {this.state.ID &&
                                    <div className="flex">
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Parent Category"
                                            options={[{ Category: null, Name: 'None' }, ...(_.filter(Categories || [], (o) => { return o.Category !== this.state.Category && o.Type === 'C' }))]}
                                            menuItemComponent={(value, index) => {
                                                return (
                                                    <MenuItem key={value.Category || index} value={value.Category}>
                                                        {value.Name}
                                                    </MenuItem>
                                                )
                                            }}
                                            portal={true}
                                            moreProps={{ margin: 'dense' }}
                                            debounce={750}
                                            value={this.state.ParentCategory || ''}
                                            onSelect={(option) => this.setState({ ...this.state, ParentCategory: option.Category })}
                                        />
                                    </div>
                                }
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.ActiveYN === 'Y'}
                                            name="ActiveYN"
                                            onChange={this.handleChange}
                                            color="primary"
                                        />
                                    }
                                    label="Active"
                                />
                            </div>
                        }
                    </CardContent>
                    <CardActions className={classNames("w-full p-12 pt-0 px-6", !component ? "pin-b absolute" : "")}>
                        {value.Assembly &&
                            <div className="w-full">
                                {this.state.ActiveYN !== 'Y' ?
                                    <Chip
                                        className={classNames(classes.blackButton, "w-full font-bold")}
                                        icon={<Icon color="error"
                                            className={classNames("text-24")}>visibility_off</Icon>}
                                        label="INACTIVE"
                                    /> :
                                    (component && component.Price) ?
                                        <Chip
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const { Qty, Price } = _.cloneDeepWith(component);
                                                const unitPrice = Price / Qty;
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                if (catalog && catalog.AutoAddOnYN === "Y" && this.state.AddonYN !== 'Y') {
                                                    const addOn = _.find(Components, { Assembly, AddonYN: 'Y' });
                                                    if (addOn) {
                                                        addOn.Qty += 1;
                                                        addOn.Price += unitPrice;
                                                        if (this.props.onUpdated) {
                                                            this.props.onUpdated(Components);
                                                        }
                                                    } else {
                                                        this.addComponent(e, true);
                                                    }
                                                } else {
                                                    const index = _.findIndex(Components, { Component: component.Component });
                                                    if (index > -1) {
                                                        const comp = _.find(Components, { Component: component.Component });
                                                        comp.Qty += 1;
                                                        comp.Price += unitPrice;
                                                        if (this.props.onUpdated) {
                                                            this.props.onUpdated(Components);
                                                        }
                                                    }
                                                }
                                            }}
                                            className={classNames(classes.blackButton, classes.scaleButton, "w-full font-bold cursor-pointer")}
                                            icon={<Icon className={classNames(classes.colorSecondary, "text-24")}>add_circle_outline</Icon>}
                                            label={this.formatDollars(component.Price)}
                                        />
                                        : value.EditFromFieldYN === "Y" ?
                                            <Chip
                                                className={classNames(classes.blackButton, estimate || option ? classes.scaleButton : "", "w-full font-bold cursor-pointer")}
                                                icon={!estimate && !option ? <SPRoundIcon className={classNames(classes.colorSecondary, "text-24")} /> : <Icon className={classNames(classes.colorSecondary, "text-24")}>edit</Icon>}
                                                label={"Custom"}
                                            />
                                            : (value.Assembly && value.Price) ?
                                                <Chip
                                                    onClick={this.addComponent}
                                                    className={classNames(classes.blackButton, estimate || option ? classes.scaleButton : "", "w-full font-bold cursor-pointer")}
                                                    icon={!estimate && !option ? <SPRoundIcon className={classNames(classes.colorSecondary, "text-24")} /> : <Icon className={classNames(classes.colorSecondary, "text-24")}>add_circle_outline</Icon>}
                                                    label={actualPrice}
                                                /> :
                                                <Chip
                                                    onClick={this.addComponent}
                                                    className={classNames(classes.blackButton, estimate || option ? classes.scaleButton : "", "w-full font-bold cursor-pointer")}
                                                    icon={!estimate && !option ? <SPRoundIcon className={classNames(classes.colorSecondary, "text-24")} /> : <Icon className={classNames(classes.colorSecondary, "text-24")}>add_circle_outline</Icon>}
                                                    label={this.formatDollars(0)}
                                                />
                                }
                            </div>
                        }
                        {editing &&
                            <div className="w-full flex">
                                <Button
                                    className={classNames(classes.saveButton, "w-full p-8 text-14")}
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.canBeSubmitted}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ ...this.state, editing: false, });
                                        if (!this.state.ID) {
                                            this.props.addAssemblyCategory(this.state);
                                            this.props.onDelete();
                                        } else {
                                            this.props.updateAssemblyCategory(this.state);
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={classNames(classes.deleteButton, "min-w-32 p-8 text-14")}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ ...this.state, editing: false, });
                                        if (!this.state.ID) {
                                            this.props.onDelete();
                                        } else {
                                            this.props.deleteAssemblyCategory(this.state);
                                        }
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </Button>
                            </div>
                        }
                    </CardActions>
                </Card>
                <div ref={this.ref}>
                    {showRecommendations && estimate && value.Data.Recommendations.length > 0 && _.filter(value.Data.Recommendations, (o) => { return o.DeleteYN !== 'Y' }).length > 0 &&
                        <CatalogRecommendationPopup source="list" Recommendations={value.Data.Recommendations} onSelected={this.props.onSelected} onUpdated={this.props.onUpdated} isMember={isMember} tasks={tasks} estimate={this.props.estimate} closeRecommendations={() => this.setState({ ...this.state, showRecommendations: false })} SelectedPricing={SelectedPricing} />
                    }
                </div>
                <ConfirmationDialog
                    open={this.state.confirmDelete}
                    title={<div><Icon className="mr-6 align-middle mb-4 text-orange">warning</Icon>Are You Sure?</div>}
                    content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to remove "${value.Name}" from this estimate?`}</div>}
                    confirmText="Remove item"
                    cancelText="Cancel"
                    onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                    onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => this.deleteAssembly())}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCategory: Actions.setCategory,
        getAssembly: Actions.getAssembly,
        addAssemblyCategory: Actions.addAssemblyCategory,
        updateAssemblyCategory: Actions.updateAssemblyCategory,
        deleteAssemblyCategory: Actions.removeAssemblyCategory,
        openNewCatalogItemDialog: Actions.openNewCatalogItemDialog,
        openEditCatalogItemDialog: Actions.openEditCatalogItemDialog,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, catalogApp }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        // categories: spReducers.catalog.categories,
        securables: spReducers.userProfiles.User.Data.Securables,
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        catalogs: spReducers.catalog.savedCatalogs,
        rateTemplates: spReducers.rateTemplates,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogAssemblyCard)));
