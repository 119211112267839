import axios from 'axios/index';

export const SET_UA_TIME_DATA = '[SPCONNECTION] SET UA TIME DATA';
export const GET_UA_TIME_DATA = '[SPCONNECTION] GET UA TIME DATA';

export function getTechnicianUATimeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/TechnicianUATime`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_UA_TIME_DATA,
                payload: response.data
            });
        });
    }
}

export function setTechnicianUATimeData(data) {
    return {
        type: SET_UA_TIME_DATA,
        payload: data
    };
}