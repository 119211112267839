import { getCompanyList } from 'store/actions';

export const START_SIGNAL_R = '[SP CONNECTION] START SIGNAL R';
export const STOP_SIGNAL_R = '[SP CONNECTION] STOP SIGNAL R';
export const CONNECTED_SIGNAL_R = '[SP CONNECTION] CONNECTED SIGNAL R';
export const ERROR_SIGNAL_R = '[SP CONNECTION] ERROR SIGNAL R';
export const SUBSCRIBE_LIST = '[SP AUTHENTICATE] SUBSCRIBE LIST';
export const UNSUBSCRIBE_LIST = '[SP AUTHENTICATE] UNSUBSCRIBE LIST';
export const ADD_SUBSCRIPTION = '[SP CONNECTION] ADD SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = '[SP CONNECTION] REMOVE SUBSCRIPTION';

export function startSignalR(User) {
    return (dispatch) => {
        Promise.all([
            dispatch({
                type: START_SIGNAL_R,
                User,
            })
        ]);
    };
}

export function stopSignalR(User) {
    return {
        type: STOP_SIGNAL_R,
        User
    };
}
