import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, MenuItem, TextField, Divider, Icon, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { DatePicker } from "@material-ui/pickers";
import * as Actions from './store/actions';
import { FuseAnimate } from '@fuse';
import _ from '@lodash';

const styles = theme => ({
    clear: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[400],
        },
        '&:active': {
            backgroundColor: theme.palette.error[700],
        }
    }
});

const initialState = {
    Co: null,
    type: null,
    startDate: null,
    endDate: null,
    status: null,
    paymentStatus: null,
    reviewStatus: null,
    paymentType: null,
    glAccount: null,
    vendor: null,
}
class AuditPostingSidebarContent extends Component {

    state = {
        ...initialState,
    }

    componentDidMount() {
        const { Co, type, startDate, endDate, status, paymentStatus, paymentType, glAccount, vendor, deliveryStatus, reviewStatus } = this.props;
        this.setState({ Co, type, startDate, endDate, status, paymentStatus, paymentType, glAccount, vendor, deliveryStatus, reviewStatus });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { Co, type, startDate, endDate, status, paymentStatus, paymentType, glAccount, vendor, deliveryStatus, reviewStatus } = this.props;
        if (!_.isEqual({ Co, type, startDate, endDate, status, paymentStatus, paymentType, glAccount, vendor, deliveryStatus, reviewStatus }, this.state)) {
            this.setState({ Co, type, startDate, endDate, status, paymentStatus, paymentType, glAccount, vendor, deliveryStatus, reviewStatus });
        }
    }

    setType = (event) => {
        this.props.setData([]);
        this.props.setType(event.target.value);
    }

    setVendor = (event) => {
        // this.props.setData([]);
        this.props.setVendor(event.target.value);
    }

    setGLAccount = (event) => {
        // this.props.setData([]);
        this.props.setGLAccount(event.target.value);
    }

    setDeliveryStatus = (event) => {
        this.props.setDeliveryStatus(event.target.value);
    }

    setReviewStatus = (event) => {
        this.props.setReviewStatus(event.target.value);
    }

    setStatus = (event) => {
        // this.props.setData([]);
        this.props.setStatus(event.target.value);
    }

    setPaymentStatus = (event) => {
        // this.props.setData([]);
        this.props.setPaymentStatus(event.target.value);
    }

    setPaymentType = (event) => {
        // this.props.setData([]);
        this.props.setPaymentType(event.target.value);
    }

    handleStartDateChange = (event) => {
        let { startDate } = this.state;
        startDate = event._d.toLocaleDateString('en-US');
        window["warn"](event, startDate);
        this.props.setStartDate(startDate);
        // this.setState({ ...this.state, startDate });
    };

    handleEndDateChange = (event) => {
        let { endDate } = this.state;
        endDate = event._d.toLocaleDateString('en-US');
        window["warn"](event, endDate);
        this.props.setEndDate(endDate);
        // this.setState({ ...this.state, endDate });
    };

    buildFilters = (data) => {

        const filters = {
        };
        Object.keys(data).map((key) => {
            const value = data[key];
            if (value) {
                filters[key] = value;
            }
        });
        return filters;
    }

    clearFilters = () => {
        this.props.setAgreementFilters(null);
    }

    getData = () => {
        const { Co, type, startDate, endDate, status, } = this.state;
        this.props.getData(Co, type, startDate, endDate);
    }

    render() {
        const { classes, user, entities, type } = this.props;
        let gls = [];
        if (type === "I") {
            entities.map((inv) => {
                const { Data } = inv.Data;
                if (Data && Data.InvoiceDetails) {
                    Data.InvoiceDetails.map((det) => {
                        if (det.Data && det.Data.InvoiceLines) {
                            det.Data.InvoiceLines.map((line) => {
                                if (line.Data && line.Data.VPARBL && line.Data.VPARBL.GLAcct && gls.indexOf(line.Data.VPARBL.GLAcct) < 0) {
                                    gls.push(line.Data.VPARBL.GLAcct);
                                } else {
                                    if (line.Data && line.Data.VPARTL && line.Data.VPARTL.GLAcct && gls.indexOf(line.Data.VPARTL.GLAcct) < 0) {
                                        gls.push(line.Data.VPARTL.GLAcct);
                                    }
                                }
                            })
                        }
                    })
                }
            });
        }
        const glAccounts = _.filter(this.props.glAccounts, (o) => { return gls.indexOf(o.GLAcct) > -1 });
        let vendors = [];
        if (type === "P") {
            const vend = _.map(entities, 'Vendor');
            vendors = _.filter(this.props.vendors, (o) => { return vend.indexOf(o.Vendor) > -1 });
        }
        return (
            <div onClick={(e) => e.stopPropagation()} className="p-12 lg:pl-20">
                <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                    <Paper elevation={1} className="rounded-8 p-12">
                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">find_replace</Icon>Audit Panel</Typography>
                        <TextField
                            className="mb-12"
                            label="Type"
                            id="type"
                            name="type"
                            value={this.state.type || ''}
                            onChange={this.setType}
                            variant="outlined"
                            select
                            fullWidth
                        >
                            <MenuItem value="C">
                                Calls
                            </MenuItem>
                            <MenuItem value="I">
                                Invoices
                            </MenuItem>
                            <MenuItem value="T">
                                Labor
                            </MenuItem>
                            <MenuItem value="P">
                                Purchase Orders
                            </MenuItem>
                            <MenuItem value="W">
                                Work Orders
                            </MenuItem>
                            <MenuItem value="S">
                                Work Order Scopes
                            </MenuItem>
                        </TextField>
                        <DatePicker
                            inputVariant="outlined"
                            className="mb-12"
                            label="Start Date"
                            id="orderedDate"
                            format="MM/DD/YYYY"
                            value={this.state.startDate}
                            onChange={this.handleStartDateChange}
                            showTodayButton
                            autoOk
                            required
                            fullWidth
                        />
                        <DatePicker
                            inputVariant="outlined"
                            className="mb-12"
                            label="End Date"
                            id="endDate"
                            format="MM/DD/YYYY"
                            value={this.state.endDate}
                            onChange={this.handleEndDateChange}
                            showTodayButton
                            autoOk
                            required
                            fullWidth
                        />
                        <Button
                            variant="contained"
                            className="mb-12"
                            fullWidth
                            color="primary"
                            onClick={this.getData}
                        >
                            Find Records
                        </Button>
                        {
                            ["T"].indexOf(this.state.type) > -1 && entities.length > 0 &&
                            <React.Fragment>
                                <TextField
                                    className="mb-12"
                                    label="Status"
                                    id="status"
                                    name="status"
                                    value={this.state.status}
                                    InputLabelProps={{ shrink: this.state.status !== null ? true : false }}
                                    onChange={this.setStatus}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value={null}>
                                        Any
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        Pending
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Submitted
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Approved
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Rejected
                                    </MenuItem>
                                </TextField>
                            </React.Fragment>
                        }
                        {
                            ["I", "P"].indexOf(this.state.type) > -1 && entities.length > 0 &&
                            <React.Fragment>
                                <TextField
                                    className="mb-12"
                                    label="Status"
                                    id="status"
                                    name="status"
                                    value={this.state.status}
                                    InputLabelProps={{ shrink: this.state.status !== null ? true : false }}
                                    onChange={this.setStatus}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value={null}>
                                        Any
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        Pending
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Batched
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Posted
                                    </MenuItem>
                                </TextField>
                                {
                                    ["I"].indexOf(this.state.type) > -1 && entities.length > 0 &&
                                    <React.Fragment>
                                        <TextField
                                            className="mb-12"
                                            label="Payment Status"
                                            id="paymentStatus"
                                            name="paymentStatus"
                                            value={this.state.paymentStatus || ''}
                                            onChange={this.setPaymentStatus}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            <MenuItem value={null}>
                                                Any
                                            </MenuItem>
                                            <MenuItem value={"P"}>
                                                Paid In Full
                                            </MenuItem>
                                            <MenuItem value={"B"}>
                                                Balance Due
                                            </MenuItem>
                                            <MenuItem value={"O"}>
                                                Overpaid
                                            </MenuItem>
                                            <MenuItem value={"Unpaid"}>
                                                Unpaid
                                            </MenuItem>
                                        </TextField>
                                        <TextField
                                            className="mb-12"
                                            label="Payment Type"
                                            id="paymentType"
                                            name="paymentType"
                                            value={this.state.paymentType || ''}
                                            onChange={this.setPaymentType}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            <MenuItem value={null}>
                                                Any
                                            </MenuItem>
                                            <MenuItem value={"CREDIT"}>
                                                Credit/Debit
                                            </MenuItem>
                                            <MenuItem value={"CHECK"}>
                                                Check
                                            </MenuItem>
                                            <MenuItem value={"CASH"}>
                                                Cash
                                            </MenuItem>
                                            <MenuItem value={"OTHER"}>
                                                Other
                                            </MenuItem>
                                            <MenuItem value={"MULTI"}>
                                                Multiple
                                            </MenuItem>
                                            <MenuItem value={"Financed"}>
                                                Financed
                                            </MenuItem>
                                            <MenuItem value={"ApprovedAccount"}>
                                                Approved Account
                                            </MenuItem>
                                        </TextField>
                                    </React.Fragment>
                                }
                                {
                                    this.state.type === "P" &&
                                    <Autocomplete
                                        className="mb-12"
                                        title="Vendor"
                                        options={[{ Vendor: null, Name: 'All' }, ...vendors]}
                                        menuItemComponent={(value) => {
                                            return (
                                                <MenuItem value={value.Vendor}>
                                                    {value.Vendor ? `${value.Vendor} - ${value.Name}` : value.Name}
                                                </MenuItem>
                                            );
                                        }}
                                        value={this.state.vendor}
                                        portal={true}
                                        onSelect={(option) => this.setVendor({ target: { value: option.Vendor } })}
                                    />
                                    // <TextField
                                    //     className="mb-12"
                                    //     label="Vendor"
                                    //     id="vendor"
                                    //     name="vendor"
                                    //     value={this.state.vendor || ''}
                                    //     onChange={this.setVendor}
                                    //     variant="outlined"
                                    //     select
                                    //     fullWidth
                                    // >
                                    //     <MenuItem value={null}>
                                    //         All
                                    // </MenuItem>
                                    //     {vendors && vendors.map((value, index) => {
                                    //         return (
                                    //             <MenuItem key={index} value={value.Vendor}>
                                    //                 {`${value.Vendor} - ${value.Name}`}
                                    //             </MenuItem>
                                    //         );
                                    //     })}
                                    // </TextField>
                                }
                                {
                                    this.state.type === "I" && this.state.status > 0 &&
                                    <TextField
                                        className="mb-12"
                                        label="GL Account"
                                        id="glAccount"
                                        name="glAccount"
                                        value={this.state.glAccount || ''}
                                        onChange={this.setGLAccount}
                                        variant="outlined"
                                        select
                                        fullWidth
                                    >
                                        <MenuItem value={null}>
                                            All
                                        </MenuItem>
                                        {glAccounts && glAccounts.map((value, index) => {
                                            return (
                                                <MenuItem key={index} value={value.GLAcct}>
                                                    {`${value.GLAcct.trim()} - ${value.Description}`}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                }
                                {
                                    this.state.type === "I" &&
                                    <TextField
                                        className="mb-12"
                                        label="Invoice Review"
                                        id="reviewStatus"
                                        name="reviewStatus"
                                        value={this.state.reviewStatus || ''}
                                        onChange={this.setReviewStatus}
                                        variant="outlined"
                                        select
                                        fullWidth
                                    >
                                        <MenuItem value={null}>
                                            All
                                        </MenuItem>
                                        <MenuItem value="Y">
                                            Requires Review
                                        </MenuItem>
                                        <MenuItem value="N">
                                            Review Not Required
                                        </MenuItem>
                                    </TextField>
                                }
                                {
                                    this.state.type === "I" &&
                                    <TextField
                                        className="mb-12"
                                        label="Invoice Delivery"
                                        id="deliveryStatus"
                                        name="deliveryStatus"
                                        value={this.state.deliveryStatus || ''}
                                        onChange={this.setDeliveryStatus}
                                        variant="outlined"
                                        select
                                        fullWidth
                                    >
                                        <MenuItem value={null}>
                                            All
                                        </MenuItem>
                                        <MenuItem value="sent">
                                            Sent
                                        </MenuItem>
                                        <MenuItem value="unsent">
                                            Not Sent
                                        </MenuItem>
                                    </TextField>
                                }
                            </React.Fragment>
                        }
                    </Paper>
                </FuseAnimate>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setType: Actions.setType,
        setGLAccount: Actions.setGLAccount,
        setStatus: Actions.setStatus,
        setPaymentStatus: Actions.setPaymentStatus,
        setPaymentType: Actions.setPaymentType,
        setVendor: Actions.setVendor,
        setDeliveryStatus: Actions.setDeliveryStatus,
        setReviewStatus: Actions.setReviewStatus,
        setStartDate: Actions.setStartDate,
        setEndDate: Actions.setEndDate,
        getData: Actions.getData,
        setData: Actions.setData,
    }, dispatch);
}

function mapStateToProps({ auditPostingApp, spReducers, }) {
    return {
        Co: spReducers.companies.Co,
        user: auditPostingApp.user,
        entities: auditPostingApp.auditPosting.entities,
        type: auditPostingApp.auditPosting.type,
        startDate: auditPostingApp.auditPosting.startDate,
        endDate: auditPostingApp.auditPosting.endDate,
        status: auditPostingApp.auditPosting.status,
        paymentStatus: auditPostingApp.auditPosting.paymentStatus,
        deliveryStatus: auditPostingApp.auditPosting.deliveryStatus,
        reviewStatus: auditPostingApp.auditPosting.reviewStatus,
        paymentType: auditPostingApp.auditPosting.paymentType,
        glAccount: auditPostingApp.auditPosting.glAccount,
        glAccounts: spReducers.vpSettings.glAccounts,
        vendors: spReducers.vendors,
        vendor: auditPostingApp.auditPosting.vendor,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditPostingSidebarContent)));
