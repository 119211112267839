import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    Co: null,
    BoardID: null,
    ListID: null,
    ID: null,
    Name: '',
    Description: '',
    DueBy: null,
    Data: {
        Attachments: [],
        Comments: [],
        Members: [],
        Tags: [],
        Checklists: [],
        Cards: []
    },
    BreadcrumbItems: [],
    Notes: '',
    AddedBy: null,
    AddedDate: null,
    AttachmentID: null,
    CoverImage: null,
    DisplayIndex: null,
}

const cardReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.OPEN_CARD_DIALOG:
            {
                return {
                    ...action.payload,
                    BreadcrumbItems: [
                        { clickable: false, text: action.payload.BoardName },
                        { clickable: false, text: action.payload.ListName },
                        { clickable: true, text: action.payload.ParentTaskName, card: { ID: action.payload.ParentID, Co: action.payload.Co, BoardID: action.payload.BoardID, Name: action.payload.ParentTaskName } },
                    ]
                };
            }
        case Actions.ADD_SUBTASK:
            {
                return _.setIn(state, 'Data.Cards', [...state.Data.Cards, action.payload]);

            }
        case Actions.REMOVE_SUBTASK:
            {

                const Cards = _.cloneDeep(state.Data.Cards);
                const index = _.findIndex(Cards, { ID: action.payload.ID })
                if (index > -1) Cards.splice(index, 1);
                return _.setIn(state, 'Data.Cards', Cards);
            }
        case Actions.OPEN_SUBTASK:
            {
                return {
                    ...initialState,
                    ...action.payload,
                    BreadcrumbItems: [
                        ...(state.BreadcrumbItems || []),
                        { clickable: true, text: state.Name, card: { ...state } }
                    ]
                };
            }
        case Actions.OPEN_BREADCRUMB_SUBTASK:
            {
                return {
                    ...initialState,
                    ...action.payload,
                    BreadcrumbItems: state.BreadcrumbItems.slice(0, action.index)
                };
            }
        case Actions.REMOVE_CARD:
            {
                return initialState;
            }
        case Actions.CLOSE_CARD_DIALOG:
            {
                return initialState;
            }
        case Actions.CHANGE_CARD:
            {
                window["warn"](action.target);
                return _.setIn(state, action.target.name, action.target.type === 'checkbox' ? action.target.checked : action.target.value);
            }
        case Actions.CHIP_CHANGE_CARD:
            {
                return _.setIn(state, action.name, action.value.map(item => item.value));
            }
        case Actions.NEW_CHIP_CARD:
            {
                return _.setIn(state, action.name, [...state[action.name], action.value]);
            }
        case Actions.MAKE_COVER_CARD:
            {
                return {
                    ...state,
                    CoverImage: action.attachmentId
                }
            }
        case Actions.REMOVE_COVER_CARD:
            {
                return _.setIn(state, 'idAttachmentCover', '');
            }
        case Actions.ADD_ATTACHMENT_CARD:
            {
                return _.setIn(state, 'Data.Attachments', [...state.Data.Attachments, action.attachment]);
            }
        case Actions.REMOVE_ATTACHMENT_CARD:
            {
                return {
                    ...state,
                    CoverImage: state.CoverImage === action.attachment.Attachment ? null : state.CoverImage,
                    Data: {
                        ...state.Data,
                        Attachments: _.reject(state.Data.Attachments, { Attachment: action.attachment.Attachment }),
                    }
                }
            }
        case Actions.REMOVE_DUE_CARD:
            {
                return _.setIn(state, 'DueBy', null);
            }
        case Actions.TOGGLE_LABEL_CARD:
            {
                const tag = _.find(state.Data.Tags, { TagID: action.label.TagID })
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Tags: tag ? _.reject(state.Data.Tags, { TagID: action.label.TagID }) : [...state.Data.Tags, action.label],
                    }
                }
                // return _.setIn(state, 'Data.Tags', tag ? _.reject(state.Data.Tags, {TagID: action.label.TagID}) : [...state.Data.Tags, action.label]);
            }
        case Actions.TOGGLE_MEMBER_CARD:
            {
                const { Members } = state.Data;
                switch (action.method) {
                    case 'ADD':
                        {
                            Members.push(action.member);
                        }
                        break;
                    case 'DELETE':
                        {
                            for (var i = 0; i < Members.length; i++) {
                                if (Members[i].UserName == action.member.UserName) {
                                    Members.splice(i, 1);
                                }
                            }
                        }
                        break;
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Members,
                    }
                }
                //return _.setIn(state, 'idMembers', _.xor(state.IDMembers, [action.memberId]));
            }
        case Actions.ADD_CHECKLIST_CARD:
            {
                const { Checklists } = state.Data;
                Checklists.push(action.list);
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
                //return _.setIn(state, 'Data.Checklists', [...state.Data.Checklists, action.newList]);
            }
        case Actions.UPDATE_CHECKLIST_CARD:
            {
                const { Checklists } = state.Data;
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.list.ID) {
                        Checklists[i] = action.list;
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.REMOVE_CHECKLIST_CARD:
            {
                const { Checklists } = state.Data;
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.list.ID) {
                        Checklists.splice(i, 1);
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.ADD_CHECKLIST_CARD_ITEM:
            {
                const { Checklists } = state.Data;
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.item.ChecklistID) {
                        Checklists[i].Data.Items.push(action.item);
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.UPDATE_CHECKLIST_CARD_ITEM:
            {
                const { Checklists } = state.Data;
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.item.ChecklistID) {
                        for (var t = 0; t < Checklists[i].Data.Items.length; t++) {
                            if (Checklists[i].Data.Items[t].ID == action.item.ID) {
                                Checklists[i].Data.Items[t] = action.item;
                            }
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.REMOVE_CHECKLIST_CARD_ITEM:
            {
                const { Checklists } = state.Data;
                for (var i = 0; i < Checklists.length; i++) {
                    if (Checklists[i].ID == action.item.ChecklistID) {
                        for (var t = 0; t < Checklists[i].Data.Items.length; t++) {
                            if (Checklists[i].Data.Items[t].ID == action.item.ID) {
                                Checklists[i].Data.Items.splice(t, 1);
                            }
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Checklists
                    }
                }
            }
        case Actions.CHECKLIST_CHANGE_CARD:
            {
                const index = state.Data.Checklists.findIndex((x) => x.ID === action.item.ID);
                return _.setIn(state, `Data.Checklists[${index}]`, action.item);
            }
        case Actions.CHECKLIST_REMOVE_CARD:
            {
                return _.setIn(state, 'Data.Checklists', _.reject(state.Data.Checklists, { id: action.id }));
            }
        case Actions.COMMENT_ADD_CARD:
            {
                return _.setIn(state, 'Data.Comments', [action.comment, ...state.Data.Comments]);
            }
        default:
            return state;
    }
};

export default cardReducer;
