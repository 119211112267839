import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, Badge, Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Step, StepButton, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { NavLink, withRouter } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import classNames from 'classnames';
import _ from '@lodash';
import { actions } from 'react-table';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingLeft: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    stepper: {
        backgroundColor: 'transparent',
        paddingTop: 16,
    },
    editButton: {
        float: 'right',
        minHeight: 0,
        marginRight: 8,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
    },
    saveButton: {
        float: 'right',
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    buttonLabel: {
        paddingLeft: 8,
        paddingRight: 8,
    },
});

const initialState = {
    class: {
        ID: null,
        Co: null,
        Category: null,
        Class: null,
        Name: null,
        Description: null,
        AddedBy: null,
        AddedDate: null,
        UpdatedBy: null,
        UpdatedDate: null,
        Data: {
            Steps: [],
            ErrMsg: null,
        },
    },
    step: 0,
}

class AcademyRightSidebarContent extends Component {

    state = {
        grabbing: false,
        steps: [],
        needsUpdate: [],
        editing: false
    }

    componentDidMount() {
        const { selectedClass } = this.props;
        this.setState({ steps: selectedClass && selectedClass.Data && _.cloneDeepWith(selectedClass.Data.Steps) });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { selectedClass } = this.props;
        if (!_.isEqual(selectedClass.Data.Steps, prevProps.selectedClass.Data.Steps)) {
            this.setState({ steps: selectedClass && selectedClass.Data && _.cloneDeepWith(selectedClass.Data.Steps) });
        }
    }

    setGrabbing = (grabbing) => {
        this.setState({ ...this.state, grabbing })
    }

    handleAddStep = () => {
        const { selectedClass } = this.props;
        const { Co, Class, Data } = selectedClass;
        const step = {
            Co,
            Class,
            Step: Data ? Data.Steps.length + 1 : 1,
            Name: `Step #${Data ? Data.Steps.length + 1 : 1}`,
            Description: null,
            Content: null,
            Data: {
                ErrMsg: null
            },
            DisplayIndex: Data ? Data.Steps.length : 0,
            lockEditor: false,

        };
        this.props.setClass({ ...selectedClass, Data: !Data ? { Steps: [step] } : { ...Data, Steps: [...Data.Steps, step] } });
        this.props.setStep(step);
    }

    onDragEnd = (result) => {
        const { source, destination } = result;
        console.warn(result);

        if (!destination) {
            return;
        }

        if (
            source.index === destination.index
        ) {
            return;
        }
        const { steps } = this.state;
        const needsUpdate = [];

        const data = this.reorder(
            steps,
            result.source.index,
            result.destination.index
        );

        //const needsUpdate = [];

        data.map((step, index) => {
            // if (step.DisplayIndex !== index) {
            step.DisplayIndex = index;
            const { Step, DisplayIndex } = step;
            // let stepIndex = _.findIndex(needsUpdate, { Step: step.Step });
            // if (stepIndex < 0){
            needsUpdate.push({ Step, DisplayIndex });
            // } else{
            //     needsUpdate.splice(stepIndex, 1);
            //     needsUpdate.push({ Step, DisplayIndex });
            // }
            //needsUpdate.push({ Step, DisplayIndex });
            // }
        });

        window["warn"]('Updated Step Order: ', steps, data, needsUpdate);

        this.setState({ ...this.state, steps: data, needsUpdate, grabbing: false });
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    saveNewOrder = async (selectedClass, needsUpdate) => {
        await this.props.setOrder(selectedClass, needsUpdate);

        this.setState({ editing: false, needsUpdate: [] });
    }

    render() {
        const { classes, user, selectedClass, step, securables, editable } = this.props;
        const accessLevel = _.find(securables, { Securable: "learning" });
        const { grabbing, editing, steps, needsUpdate } = this.state;
        if (!selectedClass) {
            return '';
        } else
            return (
                // <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <div className="p-12 pl-20">
                    <Typography variant="h6" component="div" style={{ alignItems: 'center', marginBottom: 12 }} className="flex justify-between"><div>{<Badge color="error" classes={{ badge: classes.badge, }} className="mr-8" badgeContent={0}><Icon color="primary" className="text-32">list</Icon></Badge>}Steps</div>
                        <div className="mt-6">
                            {(((accessLevel && accessLevel.AccessLevel === "E" && user === selectedClass.AddedBy) || accessLevel && accessLevel.AccessLevel === "F") && step && !editing) &&
                                <IconButton className="float-right" style={{ marginTop: -4, width: 32, height: 32 }} onClick={() => this.setState({ editing: true })} color="inherit">
                                    <Icon color="action">edit</Icon>
                                </IconButton>
                            }
                            {step && editing &&
                                <IconButton className="float-right" style={{ marginTop: -4, width: 32, height: 32 }} onClick={() => this.setState({ editing: false, steps: selectedClass.Data.Steps })} color="inherit">
                                    <Icon color="action">close</Icon>
                                </IconButton>
                            }
                            {(((accessLevel && accessLevel.AccessLevel === "E" && user === selectedClass.AddedBy) || accessLevel && accessLevel.AccessLevel === "F") && editing) &&
                                <IconButton className="float-right mr-4" style={{ marginTop: -4, width: 32, height: 32 }} onClick={() => this.saveNewOrder(selectedClass, needsUpdate)} color="inherit">
                                    <Icon color="primary">save</Icon>
                                </IconButton>
                            }
                        </div>
                    </Typography>
                    <Divider />
                    {!editing ?
                        <Stepper classes={{ root: classes.stepper }} nonLinear activeStep={step ? step.DisplayIndex : 0} orientation="vertical">
                            {steps.map((value, index) =>
                                <Step key={index}>
                                    <StepButton className="cursor-pointer truncate" completed={step && step.DisplayIndex > index} onClick={() => this.props.setStep(value)}>
                                        {value.Name}
                                    </StepButton>
                                </Step>
                            )}
                            {((accessLevel && accessLevel.AccessLevel === "E" && user === selectedClass.AddedBy) || accessLevel && accessLevel.AccessLevel === "F") &&
                                <Step>
                                    <StepButton onClick={this.handleAddStep} className="cursor-pointer" icon={<Icon color="secondary">add_circle_outline</Icon>}>
                                        Add Step
                                    </StepButton>
                                </Step>
                            }
                        </Stepper>
                        :
                        <List>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable
                                    droppableId="steps"
                                    type="step"
                                    direction="vertical"
                                // ignoreContainerClipping={800}
                                >
                                    {(provided) => (
                                        <div ref={provided.innerRef} className="w-full">
                                            {steps.map((value, index) =>
                                                <Draggable
                                                    isDragDisabled={!editing}
                                                    draggableId={`step${value.ID}`}
                                                    key={value.ID}
                                                    index={index}
                                                    type="step"
                                                >
                                                    {(provided, snapshot) => (
                                                        <div className={classNames("w-full")}>
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div
                                                                    onMouseDown={(e) => this.setGrabbing(true)}
                                                                    onMouseUp={(e) => this.setGrabbing(false)}
                                                                    className={`w-full${grabbing ? ' cursor-grabbing' : ' cursor-grab'}`}
                                                                >
                                                                    <ListItem key={index}>
                                                                        <ListItemAvatar classes={{ root: "min-w-36" }}><Avatar classes={{ root: "w-24 h-24 bg-grey text-white text-12" }}>{index + 1}</Avatar></ListItemAvatar>
                                                                        <ListItemText classes={{ primary: "text-14" }} className="truncate">
                                                                            {value.Name}
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </List>
                    }
                </div>
                // </FuseAnimate>
            );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setStep: Actions.setStep,
        setClass: Actions.setClass,
        setOrder: Actions.setOrder
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, academyApp }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        selectedClass: academyApp.academy.class,
        step: academyApp.academy.step,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademyRightSidebarContent)));
