import axios from 'axios/index';
import { getUserData } from 'main/content/apps/companies/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_COMPANIES = '[COMPANIES APP] GET COMPANIES';
export const SET_SEARCH_TEXT = '[COMPANIES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_COMPANIES = '[COMPANIES APP] TOGGLE IN SELECTED COMPANIES';
export const SELECT_ALL_COMPANIES = '[COMPANIES APP] SELECT ALL COMPANIES';
export const DESELECT_ALL_COMPANIES = '[COMPANIES APP] DESELECT ALL COMPANIES';
export const OPEN_NEW_COMPANY_DIALOG = '[COMPANIES APP] OPEN NEW COMPANY DIALOG';
export const CLOSE_NEW_COMPANY_DIALOG = '[COMPANIES APP] CLOSE NEW COMPANY DIALOG';
export const OPEN_EDIT_COMPANY_DIALOG = '[COMPANIES APP] OPEN EDIT COMPANY DIALOG';
export const CLOSE_EDIT_COMPANY_DIALOG = '[COMPANIES APP] CLOSE EDIT COMPANY DIALOG';
export const ADD_COMPANY = '[COMPANIES APP] ADD COMPANY';
export const UPDATE_COMPANY = '[COMPANIES APP] UPDATE COMPANY';
export const REMOVE_COMPANY = '[COMPANIES APP] REMOVE COMPANY';
export const REMOVE_COMPANIES = '[COMPANIES APP] REMOVE COMPANIES';
export const TOGGLE_STARRED_COMPANY = '[COMPANIES APP] TOGGLE STARRED COMPANY';
export const TOGGLE_STARRED_COMPANIES = '[COMPANIES APP] TOGGLE STARRED COMPANIES';
export const SET_COMPANIES_STARRED = '[COMPANIES APP] SET COMPANIES STARRED ';

export function getCompanies(routeParams) {
    const request = axios.get('/api/companies-app/companies', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_COMPANIES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedCompanies(companyId) {
    return {
        type: TOGGLE_IN_SELECTED_COMPANIES,
        companyId
    }
}


export function selectAllCompanies() {
    return {
        type: SELECT_ALL_COMPANIES
    }
}

export function deSelectAllCompanies() {
    return {
        type: DESELECT_ALL_COMPANIES
    }
}


export function openNewCompanyDialog() {
    return {
        type: OPEN_NEW_COMPANY_DIALOG
    }
}

export function closeNewCompanyDialog() {
    return {
        type: CLOSE_NEW_COMPANY_DIALOG
    }
}

export function openEditCompanyDialog(data) {
    return {
        type: OPEN_EDIT_COMPANY_DIALOG,
        data
    }
}

export function closeEditCompanyDialog() {
    return {
        type: CLOSE_EDIT_COMPANY_DIALOG
    }
}

export function addCompany(newCompany) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/Company`,
            newCompany
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_COMPANY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Company #${response.data.Co} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateCompany(company) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/Company?Co=${encodeURIComponent(company.Co)}`,
            company
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_COMPANY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Company ${company.Co} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeCompany(companyId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().companiesApp.companies;

        const request = axios.post('/api/companies-app/remove-company', {
            companyId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_COMPANY
                })
            ]).then(() => dispatch(getCompanies(routeParams)))
        );
    };
}


export function removeCompanies(companyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().companiesApp.companies;

        const request = axios.post('/api/companies-app/remove-companies', {
            companyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_COMPANIES
                }),
                dispatch({
                    type: DESELECT_ALL_COMPANIES
                })
            ]).then(() => dispatch(getCompanies(routeParams)))
        );
    };
}

export function toggleStarredCompany(companyId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().companiesApp.companies;

        const request = axios.post('/api/companies-app/toggle-starred-company', {
            companyId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_COMPANY
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCompanies(routeParams)))
        );
    };
}

export function toggleStarredCompanies(companyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().companiesApp.companies;

        const request = axios.post('/api/companies-app/toggle-starred-companies', {
            companyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_COMPANIES
                }),
                dispatch({
                    type: DESELECT_ALL_COMPANIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCompanies(routeParams)))
        );
    };
}

export function setCompaniesStarred(companyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().companiesApp.companies;

        const request = axios.post('/api/companies-app/set-companies-starred', {
            companyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_COMPANIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_COMPANIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCompanies(routeParams)))
        );
    };
}

export function setCompaniesUnstarred(companyIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().companiesApp.companies;

        const request = axios.post('/api/companies-app/set-companies-unstarred', {
            companyIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_COMPANIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_COMPANIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getCompanies(routeParams)))
        );
    };
}
