import axios from 'axios/index';

export const SET_PHONE_NUMBER_DATA = '[SPCONNECTION] SET PHONE NUMBER DATA';
export const GET_PHONE_NUMBER_DATA = '[SPCONNECTION] GET PHONE NUMBER DATA';
export const SUBSCRIBE_PHONE_NUMBER_LIST = '[SPCONNECTION] SUBSCRIBE PHONE NUMBER LIST';

export function getInitialPhoneNumberData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/PhoneNumber`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getPhoneNumberData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/PhoneNumber`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_PHONE_NUMBER_DATA,
                payload: response.data
            })
        });
    }
}

export function setPhoneNumberData(data) {
    return {
        type: SET_PHONE_NUMBER_DATA,
        payload: data
    };
}