import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    conversations: [],
}
const userReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_USER_DATA:
        {
            return {
                ...state,
                ...action.payload
            };
        }
        case Actions.GET_CONVERSATIONS:
            {
                return {
                    ...state,
                    conversations: action.payload,
                }
            }
        case Actions.SET_CONVERSATION:
            {
                const convo = action.payload;
                const { conversations } = state;
                let dialog = conversations.find((_chat) => _chat.UserName === convo.UserName);
                if (dialog) {
                    for (var i=0;i<conversations.length;i++){
                        if (conversations[i].UserName === convo.UserName){
                            conversations[i] = convo;
                        }
                    }
                } else {
                    conversations.push(convo);
                }
                return {
                    ...state,
                    conversations,
                }
            }
        default:
            return state;
    }
};

function getUpdatedUser(state, action)
{
    let newUserData = _.merge({}, state);
    let userChatData = newUserData.chatList.find(_chat => _chat.contactId === action.userChatData.contactId);
    if ( userChatData )
    {
        newUserData.chatList = newUserData.chatList.map(_chat => _chat.contactId === action.userChatData.contactId ? action.userChatData : _chat);
    }
    else
    {
        newUserData.chatList = [action.userChatData, ...newUserData.chatList];
    }
    return newUserData;
}

export default userReducer;
