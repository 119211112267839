import axios from 'axios/index';
import { getUserData } from 'main/content/apps/work-orders/store/actions/user.actions';
import { updateEditCustomerSiteDialog } from 'main/content/apps/customer-sites/store/actions/customer-sites.actions';
import { showMessage, addDialogHistory, setOfflineWorkOrders } from 'store/actions';
import CacheManager from 'store/middlewares/cache';
import _ from '@lodash';

export const GET_WORK_ORDERS = '[WORK ORDERS APP] GET WORK ORDERS';
export const SET_SEARCH_TEXT = '[WORK ORDERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_WORK_ORDERS = '[WORK ORDERS APP] TOGGLE IN SELECTED WORK ORDERS';
export const SELECT_ALL_WORK_ORDERS = '[WORK ORDERS APP] SELECT ALL WORK ORDERS';
export const DESELECT_ALL_WORK_ORDERS = '[WORK ORDERS APP] DESELECT ALL WORK ORDERS';
export const OPEN_NEW_WORK_ORDER_DIALOG = '[WORK ORDERS APP] OPEN NEW WORK ORDER DIALOG';
export const CLOSE_NEW_WORK_ORDER_DIALOG = '[WORK ORDERS APP] CLOSE NEW WORK ORDER DIALOG';
export const OPEN_EDIT_WORK_ORDER_DIALOG = '[WORK ORDERS APP] OPEN EDIT WORK ORDER DIALOG';
export const CLOSE_EDIT_WORK_ORDER_DIALOG = '[WORK ORDERS APP] CLOSE EDIT WORK ORDER DIALOG';
export const ADD_WORK_ORDER = '[WORK ORDERS APP] ADD WORK ORDER';
export const UPDATE_WORK_ORDER = '[WORK ORDERS APP] UPDATE WORK ORDER';
export const ADD_EDIT_WORK_ORDER_DIALOG = '[WORK ORDERS APP] ADD EDIT WORK ORDER DIALOG';
export const UPDATE_EDIT_WORK_ORDER_DIALOG = '[WORK ORDERS APP] UPDATE EDIT WORK ORDER DIALOG';
export const REMOVE_WORK_ORDER = '[WORK ORDERS APP] REMOVE WORK ORDER';
export const REMOVE_WORK_ORDERS = '[WORK ORDERS APP] REMOVE WORK ORDERS';
export const TOGGLE_STARRED_WORK_ORDER = '[WORK ORDERS APP] TOGGLE STARRED WORK ORDER';
export const TOGGLE_STARRED_WORK_ORDERS = '[WORK ORDERS APP] TOGGLE STARRED WORK ORDERS';
export const SET_WORK_ORDERS_STARRED = '[WORK ORDERS APP] SET WORK ORDERS STARRED ';

const offline = new CacheManager();

export function getWorkOrders(routeParams) {
    const request = axios.get('/api/workOrders-app/workOrders', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_WORK_ORDERS,
                payload: response.data,
                routeParams
            })
        );
}


export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedWorkOrders(workOrderId) {
    return {
        type: TOGGLE_IN_SELECTED_WORK_ORDERS,
        workOrderId
    }
}


export function selectAllWorkOrders() {
    return {
        type: SELECT_ALL_WORK_ORDERS
    }
}

export function deSelectAllWorkOrders() {
    return {
        type: DESELECT_ALL_WORK_ORDERS
    }
}


export function openNewWorkOrderDialog(data) {
    return {
        type: OPEN_NEW_WORK_ORDER_DIALOG,
        data
    }
}

export function closeNewWorkOrderDialog() {
    return {
        type: CLOSE_NEW_WORK_ORDER_DIALOG
    }
}

export function openPreloadWorkOrderDialog(data, Trip){
    return (dispatch, getState) => {
            const { Co, WorkOrder } = data;
            const state = getState();
            const wos = state.spReducers.offline.workOrders;
            const wo = wos ? _.find(wos, { WorkOrder }) : null;

            dispatch({
                type: OPEN_EDIT_WORK_ORDER_DIALOG,
                data: { ...wo, Trip },
                preload: false,
                splitOpen: true,
            });
    }
}

export function openPreloadWorkOrderDialogSplitScreen(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_WORK_ORDER_DIALOG,
            data: data,
            preload: false,
            splitOpen: false,
        })
    }
}

export function openEditWorkOrderDialog(data, Trip, preload) {
    return (dispatch, getState) => {

        const { Co, WorkOrder } = data;
        const state = getState();
        const wos = state.spReducers.offline.workOrders;
        const wo = wos ? _.find(wos, { WorkOrder }) : null;
        if (wo || preload) {
            if (wo) {
                Promise.all([
                    dispatch({
                        type: OPEN_EDIT_WORK_ORDER_DIALOG,
                        data: { ...wo, Trip },
                        preload: true,
                        splitOpen: true,
                    }),
                    dispatch(
                        addDialogHistory({
                            timestamp: new Date(),
                            dialog: 'WorkOrder',
                            params: {
                                Co,
                                WorkOrder,
                                Trip
                            }
                        })
                    )
                ]).then(() => {
                    dispatch(updateEditWorkOrderDialog({ ...data, preload: true }, Trip));
                });
            } else {
                if (preload) {
                    Promise.all([
                        dispatch({
                            type: OPEN_EDIT_WORK_ORDER_DIALOG,
                            data: { ...data, Trip },
                            preload: true,
                            splitOpen: true,
                        }),
                        dispatch(
                            addDialogHistory({
                                timestamp: new Date(),
                                dialog: 'WorkOrder',
                                params: {
                                    Co,
                                    WorkOrder,
                                    Trip
                                }
                            })
                        )
                    ]).then(() => {
                        dispatch(updateEditWorkOrderDialog({ ...data, preload: true }, Trip));
                    });
                }
            }
        } else {
            dispatch(
                addDialogHistory({
                    timestamp: new Date(),
                    dialog: 'WorkOrder',
                    params: {
                        Co,
                        WorkOrder,
                        Trip
                    }
                })
            );
            const request = axios.get(`${window["apiLocation"]}/api/WorkOrder?Co=${encodeURIComponent(Co)}&WorkOrder=${encodeURIComponent(WorkOrder)}`);

            return request.then((response) => {
                Promise.all([
                    dispatch({
                        type: wo || preload ? UPDATE_EDIT_WORK_ORDER_DIALOG : OPEN_EDIT_WORK_ORDER_DIALOG,
                        data: { ...response.data, Trip },
                        preload: false,
                        splitOpen: true,
                    })
                ])
            }).catch((err) => {
                if (wo) {
                    Promise.all([
                        dispatch({
                            type: UPDATE_EDIT_WORK_ORDER_DIALOG,
                            data: { ...wo, Trip },
                            splitOpen: true,
                        }),
                        dispatch(showMessage({
                            message: `Work Order loaded from offline storage`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'offline'
                        }))
                    ])
                } else {
                    dispatch(showMessage({
                        message: `Unable to load Work Order`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                }
            });
        }
    };
}

export function updateWorkOrderDialog(data) {
    return {
        type: UPDATE_EDIT_WORK_ORDER_DIALOG,
        data
    }
}

export function updateEditWorkOrderDialog(data, Trip) {
    return (dispatch, getState) => {
        if (!data.preload) {
            dispatch(showMessage({
                message: `Refreshing Work Order Data`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'refresh'
            }))
        }

        const { Co, WorkOrder } = data;

        const request = axios.get(`${window["apiLocation"]}/api/WorkOrder?Co=${encodeURIComponent(Co)}&WorkOrder=${encodeURIComponent(WorkOrder)}`);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_WORK_ORDER_DIALOG,
                    data: { ...response.data, Trip }
                })
            ]).then(() => {
                if (!data.preload) {
                    dispatch(showMessage({
                        message: `Work Order Data Refreshed`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                }
            })
        }).catch((err) => {
            offline.get('workOrders').then((wos) => {
                dispatch(showMessage({
                    message: data.preload ? `Work Order loaded from offline storage` : `Unable to refresh Work Order`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            })
        });
    };
}

export function closeEditWorkOrderDialog() {
    return {
        type: CLOSE_EDIT_WORK_ORDER_DIALOG
    }
}

export function addWorkOrder(newWorkOrder) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post(`${window["apiLocation"]}/api/WorkOrder`,
            newWorkOrder
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_EDIT_WORK_ORDER_DIALOG,
                    data: response.data
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Work Order #${response.data.WorkOrder} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                    const { Co, WorkOrder } = response.data;
                    dispatch(
                        addDialogHistory({
                            timestamp: new Date(),
                            dialog: 'WorkOrder',
                            params: {
                                Co,
                                WorkOrder,
                            }
                        })
                    );
                }
            })
        );
    };
}

export function updateWorkOrder(workOrder) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.put(`${window["apiLocation"]}/api/WorkOrder?Co=${encodeURIComponent(workOrder.Co)}&WorkOrder=${encodeURIComponent(workOrder.WorkOrder)}`,
            workOrder
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_WORK_ORDER_DIALOG,
                    data: response.data
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Work Order #${response.data.WorkOrder} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                }
            })
        );
    };
}

export function removeWorkOrder(workOrderId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post('/api/workOrders-app/remove-workOrder', {
            workOrderId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_WORK_ORDER
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Work Order #${workOrderId} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }))
                }
            })
        );
    };
}


export function removeWorkOrders(workOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post('/api/workOrders-app/remove-workOrders', {
            workOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_WORK_ORDERS
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDERS
                })
            ]).then(() => dispatch(getWorkOrders(routeParams)))
        );
    };
}

export function toggleStarredWorkOrder(workOrderId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post('/api/workOrders-app/toggle-starred-workOrder', {
            workOrderId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_WORK_ORDER
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrders(routeParams)))
        );
    };
}

export function toggleStarredWorkOrders(workOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post('/api/workOrders-app/toggle-starred-workOrders', {
            workOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_WORK_ORDERS
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrders(routeParams)))
        );
    };
}

export function setWorkOrdersStarred(workOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post('/api/workOrders-app/set-workOrders-starred', {
            workOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrders(routeParams)))
        );
    };
}

export function setWorkOrdersUnstarred(workOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrdersApp.workOrders;

        const request = axios.post('/api/workOrders-app/set-workOrders-unstarred', {
            workOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrders(routeParams)))
        );
    };
}