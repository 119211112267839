import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedPurchaseOrderIds: [],
    routeParams: {},
    purchaseOrderDialog: {
        type: 'new',
        props: {
            open: false,
            readOnly: false
        },
        data: {
            ID: null,
            Co: null,
            PO: null,
            POType: null,
            Vendor: null,
            Description: null,
            Status: 0,
            OrderedDate: new Date(),
            OrderedBy: null,
            DateNeeded: new Date(),
            ShippingMethod: 'W',
            ShippingLocation: null,
            ShippingAddress1: null,
            ShippingAddress2: null,
            ShippingCity: null,
            ShippingState: null,
            ShippingZip: null,
            ShippingCountry: 'US',
            ShippingContact: null,
            ShippingPhone: null,
            ShippingEmail: null,
            PayTerms: null,
            POAddress1: null,
            POAddress2: null,
            POCity: null,
            POState: null,
            POZip: null,
            POCountry: 'US',
            POCOntact: null,
            POPhone: null,
            POEmail: null,
            Notes: null,
            AttachmentID: null,
            AddedBy: null,
            AddedDate: new Date(),
            UpdatedBy: null,
            UpdatedDate: null,
            ApprovedYN: 'N',
            ApprovedBy: null,
            ApprovedDate: null,
            WorkOrder: null,
            Scope: null,
            InventoryLocation: null,
            Data: {
                Lines: [],
                ErrMsg: null,
            }
        }
    }
};

const purchaseOrdersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PURCHASE_ORDERS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
            case Actions.TOGGLE_IN_SELECTED_PURCHASE_ORDERS:
                {
    
                    const purchaseOrderId = action.purchaseOrderId;
    
                    let selectedPurchaseOrderIds = [...state.selectedPurchaseOrderIds];
    
                    if (selectedPurchaseOrderIds.find(id => id === purchaseOrderId) !== undefined) {
                        selectedPurchaseOrderIds = selectedPurchaseOrderIds.filter(id => id !== purchaseOrderId);
                    }
                    else {
                        selectedPurchaseOrderIds = [...selectedPurchaseOrderIds, purchaseOrderId];
                    }
    
                    return {
                        ...state,
                        selectedPurchaseOrderIds: selectedPurchaseOrderIds
                    };
                }
            case Actions.SELECT_ALL_PURCHASE_ORDERS:
                {
                    const { selectedPurchaseOrderIds } = action;
                    return {
                        ...state,
                        selectedPurchaseOrderIds,
                    };
                }
            case Actions.DESELECT_ALL_PURCHASE_ORDERS:
                {
                    return {
                        ...state,
                        selectedPurchaseOrderIds: []
                    };
                }
        case Actions.OPEN_NEW_PURCHASE_ORDER_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    purchaseOrderDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            readOnly: false
                        },
                        data: {
                            ...initialState.purchaseOrderDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_PURCHASE_ORDER_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_PURCHASE_ORDER_DIALOG:
            {
                const { data, readOnly } = action;
                return {
                    ...state,
                    purchaseOrderDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.purchaseOrderDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_PURCHASE_ORDER_DIALOG:
            {
                const { data, readOnly } = action;
                return {
                    ...state,
                    purchaseOrderDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.purchaseOrderDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_PURCHASE_ORDER_DIALOG:
            {
                return initialState;
            }
        default:
            {
                return state;
            }
    }
};

export default purchaseOrdersReducer;
