import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Card, CardActions, CardContent, CardMedia, Icon, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import * as Actions from './store/actions';
import Media from 'react-media';

const styles = theme => ({
    root: {

    },
    recommendations: {
        zIndex: 9999
    },
    backdrop: {
        zIndex: 9998,
        backgroundColor: 'rgba(0,0,0,.25)'
    },
    smallPopup: {
        marginBottom: 'calc(env(safe-area-inset-bottom) + 56px) !important'
    }
})


class CatalogRecommendationPopup extends Component {
    state = {
        showRecommendations: false,
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        //const { showRecommendations } = this.state;
        const { Recommendations, onSelected, onUpdated, isMember, tasks, estimate, classes, source, SelectedPricing } = this.props

        return (
            <Media queries={{ small: "(max-width: 600px)", medium: "(min-width: 1200px)" }}>
                {matches =>
                    <React.Fragment>
                        <FuseAnimate animation="transition.fadeIn" delay={300}>
                            <div onClick={(e) => { e.stopPropagation(); this.props.closeRecommendations(); }} className={classNames("pin-b pin-t pin-l pin-r fixed", classes.backdrop)}></div>
                        </FuseAnimate>
                        <FuseAnimate animation="transition.slideUpBigIn" delay={300}>
                            <div className={classNames("pin-b pin-l pin-r absolute m-8", source === "list" ? matches.small ? classes.smallPopup : "mb-56" : source === "drawer" ? "" : "mb-48", classes.recommendations, matches.small && !this.props.ogMb ? classes.smallPopup : null)}>
                                <div onClick={(e) => e.stopPropagation()} className="w-full mt-24 bg-white rounded-lg p-12 px-24" style={{ border: '1px solid lightgrey', boxShadow: '1px 2px 4px 2px rgb(0,0,0,.25)' }}>
                                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-24 mr-8">style</Icon>
                                        Also Recommended
                                        <IconButton
                                            className="pin-r absolute"
                                            style={{ marginRight: -8 }}
                                            aria-label="close"
                                            onClick={(e) => { e.stopPropagation(); this.props.closeRecommendations(); }}
                                        >
                                            <Icon color="action">close</Icon>
                                        </IconButton>
                                    </Typography>
                                    <div className="w-full rounded-lg p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                        <div className="w-full flex overflow-x-auto p-8">
                                            {Recommendations && _.filter(Recommendations, (o) => { return o.DeleteYN !== 'Y' }).map((value, index) => {
                                                const { Co, BusinessUnit, Division, Assembly, RecommendedAssembly } = value;
                                                const cat = _.find(tasks, { Co, BusinessUnit, Division, Assembly: RecommendedAssembly });
                                                const added = _.findIndex(estimate.Data.Components, { Assembly: cat.Assembly });
                                                if (cat) {
                                                    if (cat.Data && cat.Data.Images && cat.Data.Images.length > 0) {
                                                        cat.Data.Image = _.find(cat.Data.Images, { PrimaryYN: 'Y' });
                                                        if (!cat.Data.Image) {
                                                            cat.Data.Image = cat.Data.Images[0];
                                                        }
                                                    }

                                                    let actualPrice = 0;
                                                    if (!SelectedPricing || SelectedPricing === "S") {
                                                        actualPrice = cat.AddonYN == 'Y' ? cat.AddOnPrice : cat.Price
                                                    } else if (SelectedPricing === "M") {
                                                        actualPrice = cat.AddonYN == 'Y' ? cat.AddOnMemberPrice : cat.MemberPrice
                                                    } if (SelectedPricing === "P") {
                                                        actualPrice = cat.PremiumPrice ? cat.AddonYN == 'Y' ? cat.PremiumAddOnPrice ? cat.PremiumAddOnPrice : cat.PremiumPrice : cat.PremiumPrice : cat.AddonYN == 'Y' ? cat.AddOnPrice : cat.Price
                                                    }
                                                    return (
                                                        <Card key={RecommendedAssembly} className={index < (Recommendations.length - 1) ? "mr-24 max-w-160 min-w-160 cursor-pointer relative rounded-lg" : "max-w-160 min-w-160 cursor-pointer relative rounded-lg"}>
                                                            <CardMedia
                                                                component="img"
                                                                height="auto"
                                                                image={(cat.Data.Image ? cat.Data.Image.ImgUrl : "assets/images/logos/SP_Cog_Gradient.png")}
                                                                alt={cat.Name}
                                                                onClick={() => { if (this.props.onSelected) { this.props.onSelected(cat) } else { this.props.openEditCatalogItemDialog({ ...cat }, estimate, this.state.option) } }}
                                                            />
                                                            <CardContent className="mb-48 px-12 py-4">
                                                                <Typography className="font-bold mb-8 text-12">
                                                                    {cat.Name}
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions className="justify-between px-4 py-0 pin-b pin-l pin-r absolute" style={{ borderTop: '1px solid lightgrey' }}>
                                                                <div className="font-bold text-14 ml-16">{this.formatDollars(actualPrice)}</div>
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        const { Components } = _.cloneDeepWith(estimate.Data);
                                                                        if (added > -1) {
                                                                            const comp = Components[added];
                                                                            if (comp.ID) {
                                                                                comp.DeleteYN = 'Y';
                                                                                Components.splice(added, 1, comp);
                                                                            } else {
                                                                                Components.splice(added, 1);
                                                                            }
                                                                        } else {
                                                                            var rec = cat;
                                                                            const {
                                                                                Co,
                                                                                BusinessUnit,
                                                                                Division,
                                                                                Assembly,
                                                                                TaxableYN,
                                                                                AddonYN
                                                                            } = rec;
                                                                            const {
                                                                                Estimate,
                                                                                OptionBoard,
                                                                                Option
                                                                            } = estimate;
                                                                            Components.push({
                                                                                Co,
                                                                                BusinessUnit,
                                                                                Division,
                                                                                Estimate,
                                                                                OptionBoard,
                                                                                Option,
                                                                                Component: Components.length + 1,
                                                                                Type: 0,
                                                                                Qty: 1,
                                                                                UOM: 'EA',
                                                                                Assembly,
                                                                                Price: Number(actualPrice) || 0,
                                                                                PriceMethod: 'F',
                                                                                TaxableYN,
                                                                                AddonYN,
                                                                                Data: {
                                                                                    Assembly: rec,
                                                                                }
                                                                            });
                                                                            if (this.props.onUpdated) {
                                                                                this.props.onUpdated(Components);
                                                                            }
                                                                            this.setState({ estimate: { ...estimate, Data: { ...estimate.Data, Components } } });
                                                                            window["warn"](estimate);
                                                                        }
                                                                    }}
                                                                    aria-label="add to estimate">
                                                                    <Icon color={added > -1 ? "secondary" : "primary"}>{added > -1 ? "check_circle_outline" : "add_circle_outline"}</Icon>
                                                                </IconButton>
                                                            </CardActions>
                                                        </Card>
                                                    );
                                                }
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FuseAnimate>
                    </React.Fragment>
                }
            </Media>
        )
    }

}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditCatalogItemDialog: Actions.openEditCatalogItemDialog,
        closeEditCatalogItemDialog: Actions.closeEditCatalogItemDialog,
        closeNewCatalogItemDialog: Actions.closeNewCatalogItemDialog,
        addAssembly: Actions.addAssembly,
        updateAssembly: Actions.updateAssembly,
        showMessage: showMessage,
        impress,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        rateTemplates: spReducers.rateTemplates,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        employees: spReducers.employees,
        catalog: spReducers.catalog,
        // categories: spReducers.catalog.categories,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogRecommendationPopup));