import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Button, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { bindActionCreators } from 'redux';
import CatalogAssemblyComponentDialog from './CatalogAssemblyComponentDialog';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        width: 24,
        height: 24,
        minHeight: 0,
        right: 0,
        top: 0,
        marginTop: 4,
        position: 'absolute',
    },
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    disabled: {
        color: 'lightgrey',
    }

});

class CatalogAssemblyComponentsList extends Component {

    state = {
        selectedCatalogAssemblyComponentsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    render() {
        const { classes, assignments, catalogAssemblyComponentTypes, searchText, openEditCatalogAssemblyComponentDialog, openNewCatalogAssemblyComponentDialog, rateTemplates, securables } = this.props;
        const data = this.getFilteredArray(this.props.components, searchText);
        const { Co, BusinessUnit, Division, RateTemplate } = this.props.assembly;
        const rateTemplate = _.find(rateTemplates, { Co, BusinessUnit, Division, RateTemplate });
        const accessLevel = _.find(securables, { Securable: "catalog" });

        return (
            <div>
                <CatalogAssemblyComponentDialog assembly={this.props.assembly} rateTemplate={rateTemplate} />
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 sm:text-20 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Components
                    {this.props.assembly && accessLevel && accessLevel !== 'R' &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={() => {
                                const { Co, BusinessUnit, Division, Assembly, PriceMethod, Data } = this.props.assembly;
                                const lastComponent = _.maxBy(Data.Components || [], 'Component');
                                const last = lastComponent ? lastComponent.Component : 0;
                                openNewCatalogAssemblyComponentDialog({
                                    Co,
                                    BusinessUnit,
                                    Division,
                                    AssemblyID: Assembly,
                                    Component: last + 1,
                                    Type: 1,
                                    UOM: 'HR',
                                    PriceMethod
                                })
                            }}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                </Typography>
                <ReactTable
                    className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                    getTrProps={(state, rowInfo) => {
                        return {
                            className: classNames("cursor-pointer"),
                            onClick: () => {
                                if (rowInfo) {
                                    openEditCatalogAssemblyComponentDialog({ ...rowInfo.original });
                                }
                            }
                        }
                    }}
                    getTfootTdProps={() => {
                        return {
                            className: 'font-bold text-center',
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header: "Component",
                            accessor: "Component",
                            className: "font-bold justify-center",
                            footerClassName: "font-bold text-center",
                            Footer: "Total"
                        },
                        {
                            Header: "Type",
                            accessor: "Type",
                            minWidth: 192,
                            className: "justify-center",
                            Cell: row => {
                                return catalogAssemblyComponentTypes.map((value) => (
                                    value.UsageType === row.original.Type &&
                                    `${value.UsageType} - ${value.UsageType === 1 ? row.original.AddonYN === 'Y' ? 'Add-On ' : 'Task ' : ''}${value.Name}`
                                ));
                            }
                        },
                        {
                            Header: "Qty",
                            accessor: "Qty",
                            className: "justify-center",
                            minWidth: 64,
                        },
                        {
                            Header: "UOM",
                            accessor: "UOM",
                            className: "justify-center",
                            minWidth: 64,
                        },
                        {
                            Header: "Description",
                            accessor: "Description",
                            minWidth: 256,
                        },
                        {
                            Header: "Flat Price",
                            accessor: "Price",
                            className: "justify-center",
                            minWidth: 128,
                            Cell: row => {
                                return row.original.Price ? `$${row.original.Price ? parseFloat(row.original.Price).toFixed(2) : '0.00'}` : null;
                            },
                            footerClassName: "font-bold text-center",
                            Footer: this.formatDollars(_.sumBy(data, 'Price')),
                        },
                        {
                            Header: "Unit Cost",
                            accessor: "UnitCost",
                            className: "justify-center",
                            minWidth: 128,
                            Cell: row => {
                                return `$${row.original.UnitCost ? parseFloat(row.original.UnitCost).toFixed(2) : '0.00'}`;
                            }
                        },
                        {
                            Header: "Total Cost",
                            accessor: "CostTotal",
                            className: "justify-center",
                            minWidth: 128,
                            Cell: row => {
                                return `$${row.original.CostTotal ? parseFloat(row.original.CostTotal).toFixed(2) : '0.00'}`;
                            },
                            footerClassName: "font-bold text-center",
                            Footer: this.formatDollars(_.sumBy(data, 'CostTotal')),
                        },
                        {
                            Header: "Unit Price",
                            accessor: "UnitPrice",
                            className: "justify-center",
                            minWidth: 128,
                            Cell: row => {
                                return `$${row.original.UnitPrice ? parseFloat(row.original.UnitPrice).toFixed(2) : '0.00'}`;
                            }
                        },
                        {
                            Header: "Total Price",
                            accessor: "PriceTotal",
                            className: "justify-center",
                            minWidth: 128,
                            Cell: row => {
                                return `$${row.original.PriceTotal ? parseFloat(row.original.PriceTotal).toFixed(2) : '0.00'}`;
                            },
                            footerClassName: "font-bold text-center",
                            Footer: this.formatDollars(_.sumBy(data, 'PriceTotal')),
                        },
                    ]}
                    defaultPageSize={10}
                    noDataText="No Components found"
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // getCatalogAssemblyComponents: Actions.getCatalogAssemblyComponents,
        openEditCatalogAssemblyComponentDialog: Actions.openEditCatalogAssemblyComponentDialog,
        openNewCatalogAssemblyComponentDialog: Actions.openNewCatalogAssemblyComponentDialog,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        searchText: catalogApp.searchText,
        user: spReducers.userProfiles.User.UserName,
        catalogAssemblyComponentTypes: spReducers.workOrderUsageTypes,
        costTypes: spReducers.costTypes,
        rateTemplates: spReducers.rateTemplates,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogAssemblyComponentsList)));
