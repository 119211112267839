import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedUOMIds: [],
    routeParams       : {},
    uomDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const uomsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_UOMS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_UOMS:
        {

            const uomId = action.uomId;

            let selectedUOMIds = [...state.selectedUOMIds];

            if ( selectedUOMIds.find(id => id === uomId) !== undefined )
            {
                selectedUOMIds = selectedUOMIds.filter(id => id !== uomId);
            }
            else
            {
                selectedUOMIds = [...selectedUOMIds, uomId];
            }

            return {
                ...state,
                selectedUOMIds: selectedUOMIds
            };
        }
        case Actions.SELECT_ALL_UOMS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedUOMIds = arr.map(uoms => uoms.id);

            return {
                ...state,
                selectedUOMIds: selectedUOMIds
            };
        }
        case Actions.DESELECT_ALL_UOMS:
        {
            return {
                ...state,
                selectedUOMIds: []
            };
        }
        case Actions.OPEN_NEW_UOM_DIALOG:
        {
            return {
                ...state,
                uomDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_UOM_DIALOG:
        {
            return {
                ...state,
                uomDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_UOM_DIALOG:
        {
            return {
                ...state,
                uomDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_UOM_DIALOG:
        {
            return {
                ...state,
                uomDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default uomsReducer;
