import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios/index';
import { FusePageSimple, FuseAnimate } from '@fuse';
import { Avatar, Button, Icon, Tab, Tabs, Typography } from '@material-ui/core';
import TimelineTab from 'main/content/pages/profile/tabs/TimelineTab';
import PhotosVideosTab from 'main/content/pages/profile/tabs/PhotosVideosTab';
import AboutTab from 'main/content/pages/profile/tabs/AboutTab';
import { bindActionCreators } from 'redux';
import { impress } from 'main/content/compression/impress';
import { showMessage } from 'store/actions';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { openEditUserDialog } from 'main/content/apps/users/store/actions';
import { getChat, openChatPanel } from 'main/chatPanel/store/actions';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const styles = theme => ({
    layoutRoot: {},
    layoutToolbar: {
        padding: 0
    },
    layoutHeader: {
        height: 200,
        minHeight: 200,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.contrastBg,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            height: 200,
            minHeight: 200
        }
    },
    tabsRoot: {
        height: 64,
        width: '100%'
    },
    tabRoot: {
        height: 64
    }
});

class ProfilePage extends Component {
    state = {
        general: null,
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentDidMount() {
        const { general, user } = this.props;
        if (user.UserName) {
            this.setState({ ...this.state, general: general ? general : user })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { general, user } = this.props;
        if (user.UserName && !_.isEqual(general, prevProps.general)) {
            this.setState({ ...this.state, general })
        }
    }

    fileSelectedHandler = (event) => {
        const { Data, general } = this.state;
        impress(event.target.files[0], 1024, 50, true, (img) => {
            window["log"](img);
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(200)}&Type=User&ID=${encodeURIComponent(general.UserName)}&User=${encodeURIComponent(this.props.user.UserName)}`, fd).then((res) => {
                if (general.Data) {
                    general.Data.Avatar = img.base64;
                } else {
                    general.Data = { Avatar: img.base64 };
                }
                this.setState({ Data });
            });
        });
    }

    handleContactClick = () => {
        let { value, general } = this.state;
        let user = general || this.props.user;
        const { UserName } = user;
        this.props.openChatPanel();
        this.props.getChat(UserName);
    };

    render() {
        const { classes, securables } = this.props;
        let { value, general } = this.state;
        let user = general || this.props.user;
        if (!user) {
            return '';
        }
        const avatar = user.Gender !== 'F' ? '../../assets/images/avatars/profile.jpg' : '../../assets/images/avatars/profile_f.jpg';
        const userAccessLevel = _.find(securables, { Securable: "users" });


        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot,
                    header: classNames(classes.layoutHeader, "md:h-320"),
                    toolbar: classes.layoutToolbar
                }}
                header={
                    <div className="pl-24 pr-24 flex flex-1 flex-col items-center justify-center md:flex-row md:items-end">
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-row md:items-center md:justify-start">
                        </div>
                    </div>
                }
                // contentToolbar={

                // }
                content={
                    <div className="p-16 sm:p-24 relative" style={{ paddingTop: 0 }}>
                        <div className="max-w-2xl pb-48 pin-l pin-r m-auto absolute">
                            <div className="sm:flex items-center justify-between pb-12 px-12">
                                <div className="relative">
                                    <div className="sm:flex">
                                        <Avatar style={{ cursor: 'pointer', width: 144, }} classes={{ img: "rounded-full" }} onClick={() => this.fileInput.click()} className="h-auto -mt-72 sm:mr-8 p-4 bg-grey-lightest pin-l pin-r absolute m-auto absolute sm:pin-none sm:relative" alt="contact avatar" src={(user.Data && user.Data.Avatar ? (!isNaN(user.Data.Avatar) ? getProfileImage(`ID=${user.Data.Avatar}`) : user.Data.Avatar) : avatar)} />
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={this.fileSelectedHandler}
                                            ref={fileInput => this.fileInput = fileInput}
                                        />
                                        <Typography variant="h6" color="inherit" className="pin-l pin-r m-auto absolute text-center mt-72 sm:mt-24 sm:relative sm:pin-none">
                                            {user.FirstName && `${user.FirstName} ${user.LastName}`}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="flex py-12">
                                    {this.props.user.UserName !== user.UserName &&
                                        <Button onClick={this.handleContactClick} className="mr-8 normal-case w-full sm:w-auto rounded-lg mt-108 sm:mt-12" variant="contained" color="secondary" aria-label="Send Message"><Icon className="mr-6 align-middle text-16">chat</Icon>Send Message</Button>
                                    }
                                    {userAccessLevel && userAccessLevel.AccessLevel === "F" &&
                                        <Button onClick={() => this.props.openEditUserDialog(user)} className="normal-case w-full sm:w-auto rounded-lg mt-108 sm:mt-12" variant="contained" color="primary" aria-label="Follow"><Icon className="mr-6 align-middle text-16">edit</Icon>Edit User</Button>
                                    }
                                </div>
                            </div>
                            {value === 0 && (
                                <AboutTab general={user} />
                            )}
                            {/* {value === 1 &&
                        (
                            <TimelineTab/>
                        )}
                        {value === 2 && (
                            <PhotosVideosTab/>
                        )} */}

                        </div>
                    </div>
                }
            />
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        impress: impress,
        openEditUserDialog,
        openChatPanel,
        getChat,
    }, dispatch);
}

function mapStateToProps({ auth, spReducers, usersApp }) {
    return {
        user: spReducers.userProfiles.User,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
