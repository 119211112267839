import _ from '@lodash';
import { AppBar, Dialog, DialogContent, Grow, Slide} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSplitscreenDialog} from '../splitscreen-dialogs/store/actions/splitscreen-dialogs.actions';

const SlideDown = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        top: 0,
        //marginLeft: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: 80,
        width: 800,
    },
    paper: {
        top: 0,
        margin: 0,
        width: 800,
        maxHeight: 80,
        maxWidth: 700
    },
    centerOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: '25%',
        top: 0,
        position: 'fixed',
    },
    rightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        right: 0,
        top: 0,
        position: 'fixed',
    },
    leftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: 0,
        top: 0,
        position: 'fixed',
    },
    lowerRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        right: -5,
        //top: 0,
        bottom: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    lowerLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: -5,
        //top: 0,
        bottom: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    upperRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        right: -5,
        top: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    upperLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        zIndex: '-1',
        left: -5,
        top: -5,
        position: 'fixed',
        //border: '2px dotted black',
    },
    miniCenterOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
    },
    miniLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        marginRight: 5,
    },
    miniRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        marginLeft: 5,
    },
    miniLowerRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        marginLeft: 5,
        marginTop: 5,
    },
    miniLowerLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        marginRight: 5,
        marginTop: 5,
    },
    miniUpperRightSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        marginLeft: 5,
        marginBottom: 5,
    },
    miniUpperLeftSplitOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: '0.5',
        marginRight: 5,
        marginBottom: 5,
    },
});

const newDraggableOptionsState = {
    show: false,
    leftSplit: false,
    rightSplit: false,
    upperLeftSplit: false,
    lowerLeftSplit: false,
    upperRightSplit: false,
    lowerRightSplit: false,
    splitSelection: null,
    centerOverlay: null,
    rightSplitOverlay: null,
    leftSplitOverlay: null,
    lowerRightSplitOverlay: null,
    lowerLeftSplitOverlay: null,
    upperRightSplitOverlay: null,
    upperLeftSplitOverlay: null,
    snapPosition: null,
}

class DraggableOptionsDialog extends Component {
    state = { ...newDraggableOptionsState};

    //ref = React.createRef();

    ref = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { show } = this.props;
        let parent = document.querySelector('body');
        let parentRect = parent.getBoundingClientRect();

        if(prevProps.show !== show){
            this.setState({ ...this.state, show: show, snapPosition: null, centerOverlay: false, rightSplitOverlay: false, leftSplitOverlay: false, upperRightSplitOverlay: false, upperLeftSplitOverlay: false, lowerRightSplitOverlay: false, lowerLeftSplitOverlay: false,})
        }
    }


    handleMouseOver = (e) => {
        const { updateSnapPosition} = this.props;
        e.target.style.backgroundColor = "green";

        let position = e.target.dataset.position;
        let layout = e.target.dataset.layout;

        this.setState({ ...this.state, [e.target.id]: 'rgba(0, 0, 0, 0.3)', snapPosition: position}, () => updateSnapPosition(position, layout))
    }

    handleMouseLeave = (e) => {
        e.target.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
        this.setState({ ...this.state, [e.target.id]: null})
    }


    render(){
        const { classes} = this.props
        const { screenOverlay, rightSplitOverlay, leftSplitOverlay, upperRightSplitOverlay,upperLeftSplitOverlay, lowerRightSplitOverlay, lowerLeftSplitOverlay, centerOverlay } = this.state;

        return(
            <div>
            {this.state.show ?
                <>
                    <div className={classNames(classes.screenOverlay, "h-full w-full")} style={{display: Boolean(screenOverlay) ? 'flex' : 'none'}}></div>
                    <div className={classNames(classes.rightSplitOverlay, "h-full w-1/2")} style={{ display: Boolean(rightSplitOverlay) ? 'flex' : 'none', backgroundColor: rightSplitOverlay }}></div>
                    <div className={classNames(classes.leftSplitOverlay, "h-full w-1/2")} style={{ display: Boolean(leftSplitOverlay) ? 'flex' : 'none', backgroundColor: leftSplitOverlay }}></div>

                    <div className={classNames(classes.centerOverlay, "h-full w-1/2")} style={{display: Boolean(centerOverlay) ? 'flex' : 'none'}}></div>


                    <div className={classNames(classes.upperRightSplitOverlay, "h-1/2 w-1/2")} style={{ display: Boolean(upperRightSplitOverlay) ? 'flex' : 'none', backgroundColor: upperRightSplitOverlay }}></div>
                    <div className={classNames(classes.upperLeftSplitOverlay, "h-1/2 w-1/2")} style={{ display: Boolean(upperLeftSplitOverlay) ? 'flex' : 'none', backgroundColor: upperLeftSplitOverlay }}></div>
                    <div className={classNames(classes.lowerRightSplitOverlay, "h-1/2 w-1/2")} style={{ display: Boolean(lowerRightSplitOverlay) ? 'flex' : 'none', backgroundColor: lowerRightSplitOverlay }}></div>
                    <div className={classNames(classes.lowerLeftSplitOverlay, "h-1/2 w-1/2")} style={{ display: Boolean(lowerLeftSplitOverlay) ? 'flex' : 'none', backgroundColor: lowerLeftSplitOverlay }}></div>

                    <Dialog
                        TransitionComponent={SlideDown}
                        classes={{
                            root: classes.root,
                            paper: classes.paper,
                        }}
                        style={{
                            zIndex: 25
                        }}
                        disableBackdropClick={true}
                        open={this.state.show}
                        //open={true}
                        hideBackdrop={true}
                        disableEnforceFocus={true}
                        fullScreen={false}
                        //maxWidth={"sm"}
                        fullWidth={true}
                    >
                        <DialogContent style={{ height: 80, padding: 10}}>
                            <div className='flex h-full'>
                                <div className='flex w-1/2 h-full'>
                                <div className='flex w-1/2 mr-24' style={{height: 'calc(100% + 5px)'}}>
                                    <div className={classNames(classes.miniLeftSplitOverlay, 'w-1/2 h-full')} id='leftSplitOverlay' data-position='left' data-layout='1' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                    <div className={classNames(classes.miniRightSplitOverlay, 'w-1/2 h-full')} id='rightSplitOverlay' data-position='right' data-layout='1' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                </div>
                                <div className='flex w-1/2 flex-wrap mr-24'>
                                    <div className='flex h-1/2 w-full'>
                                        <div className={classNames(classes.miniUpperLeftSplitOverlay, "h-full w-1/2")} id='upperLeftSplitOverlay' data-position='upper-left' data-layout='2' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                        <div className={classNames(classes.miniUpperRightSplitOverlay, "h-full w-1/2")} id='upperRightSplitOverlay' data-position='upper-right' data-layout='2' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                    </div>
                                    <div className='flex h-1/2 w-full'>
                                        <div className={classNames(classes.miniLowerLeftSplitOverlay, "h-full w-1/2")} id='lowerLeftSplitOverlay' data-position='lower-left' data-layout='2' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                        <div className={classNames(classes.miniLowerRightSplitOverlay, "h-full w-1/2")} id='lowerRightSplitOverlay' data-position='lower-right' data-layout='2' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                    </div>
                                </div>
                                </div>

                                <div className='flex w-1/6 mr-24' style={{height: 'calc(100% + 5px)'}}>
                                    <div className={classNames(classes.miniCenterOverlay, 'w-full h-full')} id='centerOverlay' data-position='center' data-layout='5' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                </div>

                                <div className='flex w-1/2 h-full'>

                                    <div className='flex w-1/2 h-full mr-24'>
                                        <div style={{height: 'calc(100% + 5px)'}} className={classNames(classes.miniLeftSplitOverlay, 'w-1/2')} id='leftSplitOverlay' data-position='left-2' data-layout='3' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                        <div className='flex w-1/2 flex-wrap' style={{flexDirection: 'column'}}>
                                            <div className='flex h-1/2 w-full'>
                                                <div className={classNames(classes.miniUpperRightSplitOverlay, "h-full w-full")} id='upperRightSplitOverlay' data-position='upper-right-2' data-layout='3' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                            </div>
                                            <div className='flex h-1/2 w-full'>
                                                <div className={classNames(classes.miniLowerRightSplitOverlay, "h-full w-full")} id='lowerRightSplitOverlay' data-position='lower-right-2' data-layout='3' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex w-1/2 h-full'>
                                        <div className='flex w-1/2 flex-wrap' style={{flexDirection: 'column'}}>
                                            <div className='flex h-1/2 w-full'>
                                                <div className={classNames(classes.miniUpperLeftSplitOverlay, "h-full w-full")} id='upperLeftSplitOverlay' data-position='upper-left-2' data-layout='4' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                            </div>
                                            <div className='flex h-1/2 w-full'>
                                                <div className={classNames(classes.miniLowerLeftSplitOverlay, "h-full w-full")} id='lowerLeftSplitOverlay' data-position='lower-left-2' data-layout='4' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                            </div>
                                        </div>
                                        <div style={{height: 'calc(100% + 5px)'}} className={classNames(classes.miniRightSplitOverlay, 'w-1/2')} id='rightSplitOverlay' data-position='right-2' data-layout='4' onMouseOver={(e) => this.handleMouseOver(e)} onMouseLeave={(e) => this.handleMouseLeave(e)}></div>
                                    </div>

                                </div>

                            </div>
                        </DialogContent>
                    </Dialog>
                </> : null
                }
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSplitscreenDialog,
    }, dispatch);
}

function mapStateToProps({ spReducers, minimizedDialogApp, splitscreenDialogApp }) {
    return {
        Co: spReducers.companies.Co,
        minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
        splitscreenDialogs: splitscreenDialogApp.splitscreenDialog.data,
        splitscreenDialog: splitscreenDialogApp.splitscreenDialog,
        cornered: splitscreenDialogApp.splitscreenDialog.cornered,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(DraggableOptionsDialog));