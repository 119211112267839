import {combineReducers} from 'redux';
import agreements from './agreements.reducer';
import user from './user.reducer';

const agreementsAppReducers = combineReducers({
    agreements,
    user
});

export default agreementsAppReducers;
