import {combineReducers} from 'redux';
import gpsInventoryTransfers from './gps-inventory-transfers.reducer';
import user from './user.reducer';

const gpsInventoryTransfersAppReducers = combineReducers({
    gpsInventoryTransfers,
    user
});

export default gpsInventoryTransfersAppReducers;
