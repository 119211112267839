import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import { Hidden, Icon, IconButton, Input, Paper, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FuseAnimate, FuseSelectedTheme } from '@fuse';

const styles = theme => ({
    root: {},
    logo: {},
    logoIcon: {
        fontSize: '32px!important'
    },
    logoText: {
        fontSize: 24
    },
});

class TechniciansHeader extends Component {

    render() {
        const { classes, setSearchText, searchText, pageLayout } = this.props;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex">
                    <div className={classNames(classes.logo, "flex items-center flex-1")}>
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Icon className={classNames(classes.logoIcon, "mr-16")}>account_circle</Icon>
                        </FuseAnimate>
                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                            <span className={classes.logoText}>Technician Dashboard</span>
                        </FuseAnimate>
                    </div>
                </div>
            </MuiThemeProvider>
        )
            ;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({ techniciansApp }) {
    return {
        searchText: techniciansApp.technicians.searchText
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TechniciansHeader));
