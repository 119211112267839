import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, Drawer, FormControlLabel, FormGroup, Grid, Hidden, Icon, IconButton, InputAdornment, MenuItem, Switch, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TreeItem, TreeView } from '@material-ui/lab';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import classNames from 'classnames';
import MailCompose from 'main/content/apps/mail/MailCompose';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import ReportPDF from './ReportPDF';
import ReportTemplate from './ReportTemplate';
import * as Actions from './store/actions';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    drawer: {
        width: 0,
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    formControl: {
        '& .Mui-checked': {
            color: theme.palette.primary.main
        },
    }
});

const newComponentState = {
    optionsOpen: false,
    printPDF: null,
    expandAll: false,
    expandColumns: [],
    orientation: 'landscape',
    pageSize: 'auto',
    horizontalMargin: '0.25',
    hasChanges: false
}

const OptionsWrapper = ({ children, ...props }) => {

    const { optionsOpen, closeDrawer } = props;
    return (
        <Media query="(min-width: 600px)"/**/>
            {matches =>
                matches ?
                    <Grid item xs={12} sm={5} md={4} style={{ maxHeight: '100%', overflow: 'auto' }}>
                        {children}
                    </Grid>
                    :
                    <Drawer anchor="left" open={optionsOpen} onClose={closeDrawer}>
                        <div className="w-full p-12 pt-16 sm:pt-20 relative">
                            <Typography className="font-bold w-full truncate">
                                PDF Options
                            </Typography>
                            <IconButton
                                className="pin-r pin-t absolute mt-8"
                                onClick={closeDrawer}
                            >
                                <Icon>close</Icon>
                            </IconButton>

                            <br /><br />
                            {children}
                        </div>
                    </Drawer>
            }
        </Media>
    )
}

class ReportViewer extends Component {

    state = {
        ..._.cloneDeepWith(newComponentState)
    };

    componentDidMount() {
        const { component, results } = this.props;
        this.setState({ ..._.cloneDeepWith(newComponentState), ...(component || {}), results });

    }

    componentDidUpdate(prevProps, prevState) {
        const { results, printPDF, component } = this.props;
        if (!_.isEqual(results, prevProps.results) || !_.isEqual(printPDF, prevProps.printPDF) || !_.isEqual(component, prevProps.component)) {
            this.setState({ ..._.cloneDeepWith(newComponentState), results, printPDF, component });
        } else {
            if (!_.isEqual(results, prevProps.results) && (!results || results.length < 1)) {
                this.setState({ ...this.state, results, printPDF });
            }
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    getColumns(data) {
        if (data && data.length > 0) {
            return Object.keys(data[0]).map(key => {
                return {
                    Header: key,
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    accessor: key,
                    id: key,
                    dataType: !isNaN(data[0][key]) ? 'number' : 'text'
                };
            });
        }
    }

    getReportParameter = (name) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    printPDF = () => {

        const { master } = this.props;
        const { orientation, pageSize, horizontalMargin, component } = this.state;
        const Report = component.Data.DataSource;
        const options = JSON.parse(component.Options);
        const columns = options ? options.columnOptions : [];
        const pivotBy = options ? options.pivotBy : [];

        const margin = { top: '1.5in', bottom: '.5 in', left: `${horizontalMargin}in`, right: `${horizontalMargin}in` };
        let gridElement = document.getElementById('report-print');
        const style = { padding: `0.5in ${horizontalMargin}in` }
        drawDOM(gridElement, {
            margin,
            scale: .6,
            title: 'Report',
            paperSize: pageSize,
            multiPage: true,
            repeatHeaders: true,
            landscape: orientation === 'landscape',
            keepTogether: ".prevent-split",
            template: (props) => ReportTemplate({ ...props, style, master, Report, columns, pivotBy, horizontalMargin }),

        }).then((group) => {
            return exportPDF(group);

        }).then((dataUri) => {
            this.setState({ printPDF: dataUri, hasChanges: false });
        });
    }

    changeColumnVisibility = (event) => {
        const options = JSON.parse(this.state.component.Options);
        const columns = options ? options.columnOptions : [];
        const column = _.find(columns, { id: event.target.name });
        if (column) {
            column.show = event.target.checked;
            this.setState(prev => ({
                ...prev,
                component: {
                    ...prev.component,
                    Options: JSON.stringify(options)
                }, hasChanges: true
            }));
        }
    }

    renderTree = (node) => (
        <TreeItem className='my-6' key={node.id} nodeId={node.id} label={node.name}>
            {node.child.id ? this.renderTree(node.child) : <TreeItem className='my-6' label="- Fully expanded" />}
        </TreeItem>
    );

    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    emailPDF = () => {
        const { printPDF, component } = this.state;
        const { user } = this.props;
        const file = this.dataURLtoFile(printPDF, `${component.Report}-${component.Name.replace(/[^a-z0-9]/gi, '')}-${new Date().getTime()}.pdf`);
        const data = {
            composeDialog: true,
            Title: 'Email Report',
            Icon: "send",
            To: user.Email ? `${user.Email};` : null,
            Subject: `${user.FirstName} ${user.LastName} Shared Report #${component.Report} - ${component.Name}`,
            Head: '',
            Message: 'See Attachment(s)',
            Attachments: [file],
        }
        this.setState({ reportEmail: data });
    }

    render() {
        const { searchText, classes, onClose, results, master } = this.props;
        const { expandAll, expandColumns, orientation, pageSize, horizontalMargin, printPDF, component } = this.state;
        const data = this.getFilteredArray(results || [], searchText);

        if (!component) {
            return '';
        }
        const options = JSON.parse(component.Options);
        const columns = options ? options.columnOptions : [];
        const pivotBy = options ? options.pivotBy : [];

        const expandTree = {};
        let prevTree = expandTree;
        columns.filter(column => pivotBy.includes(column.id)).forEach(column => {
            prevTree.id = column.id;
            prevTree.name = column.Header
            prevTree.child = {};
            prevTree = prevTree.child;
        })

        return (
            <Media queries={{ small: "(max-width: 600px)", medium: "(min-width: 768px) and (min-height: 768px)", large: "(min-width: 1200px)" }}>
                {matches =>
                    <React.Fragment>
                        <FuseAnimate animation="transition.slideLeftBigIn" delay={300}>
                            <div>
                                {
                                    Boolean(printPDF) &&
                                    <Dialog
                                        classes={{ paper: classNames("mt-0 mb-0 w-full h-full", !matches.large && "full-screen-dialog") }}
                                        open={Boolean(printPDF)}
                                        onClose={onClose}
                                        maxWidth="md"
                                        fullScreen={!matches.large}
                                    >
                                        <AppBar position="static" className="dialog-header">
                                            <Toolbar className="flex w-full">
                                                <Typography variant="subtitle1" color="inherit">
                                                    <Icon className="mr-6 align-middle mb-2">share</Icon>Share Report
                                                </Typography>
                                            </Toolbar>

                                            <IconButton
                                                style={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    top: matches.large ? 8 : 4,
                                                    color: 'white'
                                                }}
                                                onClick={onClose}
                                                className="dialog-header-icon"
                                            >
                                                <Icon>close</Icon>
                                            </IconButton>
                                        </AppBar>

                                        <DialogContent classes={{ root: "p-16 pb-0 sm:p-24 sm:pb-0 h-full" }}>
                                            <Grid className="h-full" container spacing={2}>
                                                <OptionsWrapper optionsOpen={this.state.optionsOpen} closeDrawer={() => this.setState({ optionsOpen: false })}>

                                                    <div className="w-full mb-12">
                                                        <TextField
                                                            label="Page Size"
                                                            value={pageSize || ''}
                                                            onChange={(e) => this.setState({ ...this.state, pageSize: e.target.value, hasChanges: true })}
                                                            variant="outlined"
                                                            fullWidth
                                                            select
                                                        >
                                                            <MenuItem value="auto">Auto</MenuItem>
                                                            <MenuItem value="A4">A4</MenuItem>
                                                            <MenuItem value="Letter">Letter</MenuItem>
                                                            <MenuItem value="Legal">Legal</MenuItem>
                                                            <MenuItem value="Folio">Folio</MenuItem>
                                                            <MenuItem value="Executive">Executive</MenuItem>
                                                            <MenuItem value="Tabloid">Tabloid</MenuItem>
                                                        </TextField>
                                                    </div>

                                                    <div className="w-full mb-12">
                                                        <TextField
                                                            label="Orientation"
                                                            value={orientation || ''}
                                                            onChange={(e) => this.setState({ ...this.state, orientation: e.target.value, hasChanges: true })}
                                                            variant="outlined"
                                                            fullWidth
                                                            select
                                                        >
                                                            <MenuItem value="portrait">Portrait</MenuItem>
                                                            <MenuItem value="landscape">Landscape</MenuItem>
                                                        </TextField>
                                                    </div>

                                                    <div className='w-full mb-12'>
                                                        <TextField
                                                            label="Margin"
                                                            value={horizontalMargin}
                                                            onChange={(e) => this.setState({ ...this.state, horizontalMargin: e.target.value, hasChanges: true })}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">in</InputAdornment>,
                                                                type: "number"
                                                            }}
                                                            required
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                checked={expandColumns.length === pivotBy.length}
                                                                onChange={(e) => this.setState({ ...this.state, expandColumns: e.target.checked ? [...pivotBy] : [], hasChanges: true })}
                                                            />
                                                        }
                                                        label="Expand All Rows"
                                                    />

                                                    <div className="w-full mb-12">
                                                        <Typography className="font-bold text-14 mt-24">Expand by Columns</Typography>

                                                        <Box className='mt-8 ml-4'>
                                                            <TreeView
                                                                expanded={expandColumns}
                                                                onNodeToggle={(event, nodeIds) => this.setState({ ...this.state, expandColumns: nodeIds, hasChanges: true })}
                                                                defaultCollapseIcon={<Icon>expand_more</Icon>}
                                                                defaultExpandIcon={<Icon>chevron_right</Icon>}
                                                            >
                                                                {this.renderTree(expandTree)}
                                                            </TreeView>
                                                        </Box>
                                                    </div>

                                                    <div className="w-full mb-12">
                                                        <Typography className="font-bold text-14 mt-24">Columns Visibility</Typography>

                                                        <FormGroup className='mt-8 ml-4'>
                                                            {columns.filter(column => !pivotBy.includes(column.id)).map(column =>
                                                                <FormControlLabel
                                                                    className={classes.formControl}
                                                                    label={column.Header}
                                                                    control={
                                                                        <Checkbox
                                                                            defaultChecked
                                                                            name={column.id}
                                                                            checked={column.show}
                                                                            onChange={this.changeColumnVisibility}
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </div>

                                                    <Hidden smUp>
                                                        <Box className='flex w-full justify-end'>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => { this.printPDF(); this.setState({ optionsOpen: false }) }}
                                                            >
                                                                Apply Changes
                                                            </Button>
                                                        </Box>
                                                    </Hidden>

                                                </OptionsWrapper>

                                                <Grid item xs={12} sm={7} md={8} style={{ paddingBottom: 0 }}>

                                                    <Hidden smUp>
                                                        <Button className="mb-6 float-right" variant="outlined" color="inherit" onClick={(e) => this.setState({ optionsOpen: true })}>
                                                            <Icon className="mr-4">tune</Icon> PDF Options
                                                        </Button>
                                                    </Hidden>

                                                    {printPDF &&
                                                        <iframe
                                                            title="Report"
                                                            src={printPDF}
                                                            className="w-full overflow-auto rounded h-full"
                                                            style={{ backgroundColor: '#444', height: !matches.medium ? 'calc(100% - 64px)' : undefined }}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>

                                        </DialogContent>

                                        <Hidden xsDown>
                                            <DialogActions className='justify-between'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.printPDF}
                                                    disabled={!this.state.hasChanges}
                                                >
                                                    Apply Changes
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.emailPDF}
                                                    disabled={this.state.hasChanges}
                                                >
                                                    <Icon className="mr-6 align-middle">send</Icon>Email Report
                                                </Button>
                                            </DialogActions>
                                        </Hidden>
                                        <Hidden smUp>
                                            <DialogActions className='justify-end'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.emailPDF}
                                                    disabled={this.state.hasChanges}
                                                >
                                                    <Icon className="mr-6 align-middle">send</Icon>Email Report
                                                </Button>
                                            </DialogActions>
                                        </Hidden>
                                        {this.state.reportEmail &&
                                            <MailCompose onClose={() => this.setState({ reportEmail: null })} hideButton={true} data={this.state.reportEmail} />
                                        }
                                    </Dialog>
                                }

                                <ReportPDF
                                    master={master}
                                    component={component}
                                    hidden={true}
                                    reportHeader={pageSize === 'auto'}
                                    expanded={expandAll}
                                    expandColumns={expandColumns}
                                    results={[...data]}
                                    options={{ ...component }}
                                    horizontalMargin={horizontalMargin}
                                    getTable={(reportRef) => {
                                        if (!this.state.reportRef) {
                                            this.setState({ ...this.state, reportRef })
                                        }
                                    }}
                                />

                            </div>
                        </FuseAnimate>

                    </React.Fragment>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        getData: Actions.getData,
        setData: Actions.setData,
        setReport: Actions.setReport,
        removeReport: Actions.removeReport,
        showMessage
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        List: spReducers.companies.List,
        divisions: spReducers.divisions,
        results: reportingApp.reporting.entities,
        loading: reportingApp.reporting.loading,
        searchText: reportingApp.reporting.searchText,
        securables: spReducers.userProfiles.User.Data.Securables,
        user: spReducers.userProfiles.User,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportViewer)));
