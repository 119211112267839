import {combineReducers} from 'redux';
import laborTypes from './labor-types.reducer';
import user from './user.reducer';

const laborTypesAppReducers = combineReducers({
    laborTypes,
    user
});

export default laborTypesAppReducers;
