import GLAccountsApp from 'main/content/apps/gl-accounts/GLAccountsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const GLAccountsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/gl-accounts/:id',
            component: GLAccountsApp
        },
        {
            path     : '/apps/gl-accounts',
            component: () => <Redirect to="/apps/gl-accounts/all"/>
        }
    ]
};
