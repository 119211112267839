import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class ServiceUnitTypesList extends Component {

    state = {
        selectedServiceUnitTypesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedServiceUnitTypeMenu = (event) => {
        this.setState({ selectedServiceUnitTypesMenu: event.currentTarget });
    };

    closeSelectedServiceUnitTypesMenu = () => {
        this.setState({ selectedServiceUnitTypesMenu: null });
    };

    render() {
        const { classes, serviceUnitTypes, user, searchText, openNewServiceUnitTypeDialog, selectedServiceUnitTypeIds, selectAllServiceUnitTypes, deSelectAllServiceUnitTypes, toggleInSelectedServiceUnitTypes, openEditServiceUnitTypeDialog, removeServiceUnitTypes, removeServiceUnitType, toggleStarredServiceUnitType, setServiceUnitTypesUnstarred, setServiceUnitTypesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedServiceUnitTypesData ? (!this.props.serviceUnitTypeData ? [] : this.props.serviceUnitTypeData) : this.props.selectedServiceUnitTypesData), searchText);
        const { selectedServiceUnitTypesMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewServiceUnitTypeDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Service Unit Types</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditServiceUnitTypeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Unit Type",
                                accessor: "UnitType",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Unit Category",
                                accessor: "UnitCat",
                                className: "justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256,
                            },
                            {
                                Header: "Prefix",
                                accessor: "Prefix",
                                className: "justify-center",
                                width: 64
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Service Unit Types found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getServiceUnitTypes: Actions.getServiceUnitTypes,
        getUserData: Actions.getUserData,
        toggleInSelectedServiceUnitTypes: Actions.toggleInSelectedServiceUnitTypes,
        selectAllServiceUnitTypes: Actions.selectAllServiceUnitTypes,
        deSelectAllServiceUnitTypes: Actions.deSelectAllServiceUnitTypes,
        openEditServiceUnitTypeDialog: Actions.openEditServiceUnitTypeDialog,
        removeServiceUnitTypes: Actions.removeServiceUnitTypes,
        removeServiceUnitType: Actions.removeServiceUnitType,
        toggleStarredServiceUnitType: Actions.toggleStarredServiceUnitType,
        toggleStarredServiceUnitTypes: Actions.toggleStarredServiceUnitTypes,
        setServiceUnitTypesStarred: Actions.setServiceUnitTypesStarred,
        setServiceUnitTypesUnstarred: Actions.setServiceUnitTypesUnstarred,
        openNewServiceUnitTypeDialog: Actions.openNewServiceUnitTypeDialog
    }, dispatch);
}

function mapStateToProps({ serviceUnitTypesApp, spReducers }) {
    return {
        serviceUnitTypes: serviceUnitTypesApp.serviceUnitTypes.entities,
        selectedServiceUnitTypeIds: serviceUnitTypesApp.serviceUnitTypes.selectedServiceUnitTypeIds,
        searchText: serviceUnitTypesApp.serviceUnitTypes.searchText,
        user: serviceUnitTypesApp.user,
        serviceUnitTypeData: spReducers.serviceUnitTypes
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitTypesList)));
