import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    TextField, Badge, Button, Dialog, DialogActions, DialogContent, Drawer, List, ListItem, ListItemIcon, ListItemText, MenuItem, Divider, Icon, IconButton, Typography, Tooltip, Toolbar, AppBar, Avatar, Tabs, Tab, FormControlLabel, Switch
} from '@material-ui/core';
import { AccountCircle, AccountBalance, Assignment, AttachFile, Business, BusinessCenter, ChevronRight, ChevronLeft, Dashboard, History, LocationCity, List as ListIcon, LocalShipping, LocationOn, Menu as MenuIcon, Payment, Receipt, ViewList } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { showMessage } from 'store/actions';
import * as FileActions from '../file-manager/store/actions';
import { connect } from 'react-redux';
import { FuseUtils, FuseAnimate } from '@fuse';
import ServiceUnitTypesList from 'main/content/apps/service-unit-types/ServiceUnitTypesList';
import AnalyticsDashboardApp from 'main/content/apps/dashboards/analytics/AnalyticsDashboardApp';
import FileList from 'main/content/apps/file-manager/FileList';
import Media from 'react-media';
import _ from '@lodash';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newServiceUnitCategoryState = {
    ID: '',
    Co: null,
    BusinessUnit: '',
    ServiceUnitCategory: '',
    Description: '',
    LaborCostGL: '',
    LaborRevenueGL: '',
    MaterialCostGL: '',
    MaterialRevenueGL: '',
    EquipmentCostGL: '',
    EquipmentRevenueGL: '',
    MiscCostGL: '',
    MiscRevenueGL: '',
    DefaultRevenueGL: '',
    DeferredRevenueGL: '',
    Notes: '',
    value: 0,
    Data: {
        ServiceUnitTypes: [],
        Attachments: []
    },
};

const ServiceUnitCategoryMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

class ServiceUnitCategoriesDialog extends Component {
    state = { ...newServiceUnitCategoryState };


    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newServiceUnitCategoryState.Co = this.props.Co;
        if (!prevProps.serviceUnitCategoryDialog.props.open && this.props.serviceUnitCategoryDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.serviceUnitCategoryDialog.type === 'edit' &&
                this.props.serviceUnitCategoryDialog.data &&
                !_.isEqual(this.props.serviceUnitCategoryDialog.data, prevState)) {
                const data = this.props.serviceUnitCategoryDialog.data;
                this.setState({ ...this.props.serviceUnitCategoryDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.serviceUnitCategoryDialog.type === 'new' &&
                !_.isEqual(newServiceUnitCategoryState, prevState)) {
                this.setState({ ...newServiceUnitCategoryState });
            }

            if (this.props.serviceUnitCategoryDialog.type === 'customer' &&
                this.props.serviceUnitCategoryDialog.data &&
                !_.isEqual(this.props.serviceUnitCategoryDialog.data, prevState)) {
                const data = this.props.serviceUnitCategoryDialog.data;
                this.props.serviceUnitCategoryDialog.type = 'new';
                this.setState({ ...this.props.serviceUnitCategoryDialog.data });
            }
        }
        //window["log"]('STATE COMPARISON', this.props.serviceUnitCategoryDialog, prevProps, prevState);
        if (prevProps.serviceUnitCategoryDialog.props.open && this.props.serviceUnitCategoryDialog.props.open) {
            if (this.props.serviceUnitCategoryDialog.type === 'data' &&
                this.props.serviceUnitCategoryDialog.data &&
                !_.isEqual(this.props.serviceUnitCategoryDialog.data, prevState)) {
                const data = this.props.serviceUnitCategoryDialog.data;
                this.props.serviceUnitCategoryDialog.type = 'edit';
                this.setState({ ...this.props.serviceUnitCategoryDialog.data });
            }
        }
        if (prevProps.serviceUnitCategoryDialog.props.open && this.props.serviceUnitCategoryDialog.props.open) {
            if (this.props.serviceUnitCategoryDialog.type === 'add' &&
                this.props.serviceUnitCategoryDialog.data &&
                !_.isEqual(this.props.serviceUnitCategoryDialog.data, prevState)) {
                const data = this.props.serviceUnitCategoryDialog.data;
                this.props.showMessage({
                    message: `ServiceUnitCategory ${this.props.serviceUnitCategoryDialog.data.ServiceUnitCategory} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.serviceUnitCategoryDialog.type = 'edit';
                this.setState({ ...this.props.serviceUnitCategoryDialog.data });
            }
        }
        if (prevProps.serviceUnitCategoryDialog.props.open && this.props.serviceUnitCategoryDialog.props.open) {
            if (this.props.serviceUnitCategoryDialog.type === 'update' &&
                this.props.serviceUnitCategoryDialog.data &&
                !_.isEqual(this.props.serviceUnitCategoryDialog.data, prevState)) {
                const data = this.props.serviceUnitCategoryDialog.data;
                this.props.serviceUnitCategoryDialog.type = 'edit';
                this.setState({ ...this.props.serviceUnitCategoryDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.serviceUnitCategoryDialog.type === 'edit' ? this.props.closeEditServiceUnitCategoryDialog() : this.props.closeNewServiceUnitCategoryDialog();
    };

    canBeSubmitted() {
        const { Description } = this.state;
        return (
            Description.length > 0
        );
    }

    render() {
        const { classes, theme, serviceUnitCategoryDialog, addServiceUnitCategory, updateServiceUnitCategory, removeServiceUnitCategory, businessUnits, glAccounts } = this.props
        const { Co, BusinessUnit, ServiceUnitCategory, value } = this.state;
        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...serviceUnitCategoryDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={value === 0 ? "xs" : "md"}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(serviceUnitCategoryDialog.type) > -1 ? 'New Service Unit Category' : `Service Unit Category ${this.state.ServiceUnitCategory} Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                            >
                                <Tab disableRipple icon={<Icon>settings</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Types ? this.state.Data.Types.length : 0} color="error"><Icon>list</Icon></Badge>} label="Types" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && <TabContainer>

                            <div className="sm:flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Category"
                                    id="UnitCat"
                                    name="unitCat"
                                    value={this.state.UnitCat}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    disabled={(serviceUnitCategoryDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="sm:flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Name"
                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </TabContainer>}
                        {value === 1 && serviceUnitCategoryDialog.type == 'edit' && <TabContainer><ServiceUnitTypesList selectedServiceUnitTypesData={[...this.state.Data.Types]} /></TabContainer>}

                        {['new', 'customer'].indexOf(serviceUnitCategoryDialog.type) > -1 ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        var rec = this.state;
                                        addServiceUnitCategory(rec);
                                        //this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                        </Button>
                            </DialogActions>
                        ) : (
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            updateServiceUnitCategory(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                        </Button>
                                </DialogActions>
                            )}
                    </Dialog>
                }
            </Media>
        );
    }
}
ServiceUnitCategoriesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditServiceUnitCategoryDialog: Actions.closeEditServiceUnitCategoryDialog,
        closeNewServiceUnitCategoryDialog: Actions.closeNewServiceUnitCategoryDialog,
        addServiceUnitCategory: Actions.addServiceUnitCategory,
        updateServiceUnitCategory: Actions.updateServiceUnitCategory,
        removeServiceUnitCategory: Actions.removeServiceUnitCategory,
        getFiles: FileActions.getFiles,
        showMessage: showMessage
    }, dispatch);
}

function mapStateToProps({ serviceUnitCategoriesApp, fileManagerApp, spReducers }) {
    return {
        serviceUnitCategoryDialog: serviceUnitCategoriesApp.serviceUnitCategories.serviceUnitCategoryDialog,
        businessUnits: spReducers.businessUnits,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        glAccounts: spReducers.glAccounts,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ServiceUnitCategoriesDialog));
