import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class BusinessUnitsList extends Component {

    state = {
        selectedBusinessUnitsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedBusinessUnitMenu = (event) => {
        this.setState({ selectedBusinessUnitsMenu: event.currentTarget });
    };

    closeSelectedBusinessUnitsMenu = () => {
        this.setState({ selectedBusinessUnitsMenu: null });
    };

    render() {
        const { classes, securables, businessUnits, user, searchText, openNewBusinessUnitDialog, selectedBusinessUnitIds, selectAllBusinessUnits, deSelectAllBusinessUnits, toggleInSelectedBusinessUnits, openEditBusinessUnitDialog, removeBusinessUnits, removeBusinessUnit, toggleStarredBusinessUnit, setBusinessUnitsUnstarred, setBusinessUnitsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.businessUnitData ? [] : this.props.businessUnitData), searchText);
        const { selectedBusinessUnitsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "business-units" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewBusinessUnitDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">business_center</Icon>Business Units</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditBusinessUnitDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "BusinessUnit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center",
                                width: 140
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256
                            },
                            {
                                Header: "Address",
                                accessor: "Address1",
                                width: 256
                            },
                            {
                                Header: "Address2",
                                accessor: "Address2",
                                width: 256
                            },
                            {
                                Header: "City",
                                accessor: "City",
                                className: "justify-center",
                                width: 140
                            },
                            {
                                Header: "State",
                                accessor: "State",
                                className: "justify-center",
                            },
                            {
                                Header: "Zip",
                                accessor: "Zip",
                                className: "justify-center",
                            },
                            {
                                Header: "Phone",
                                accessor: "Phone",
                                className: "justify-center",
                                width: 140
                            },
                            {
                                Header: "Email",
                                accessor: "Email",
                                width: 256,
                            },
                            {
                                Header: "Status",
                                accessor: "Status",
                                className: "justify-center",
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Business Units found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBusinessUnits: Actions.getBusinessUnits,
        getUserData: Actions.getUserData,
        toggleInSelectedBusinessUnits: Actions.toggleInSelectedBusinessUnits,
        selectAllBusinessUnits: Actions.selectAllBusinessUnits,
        deSelectAllBusinessUnits: Actions.deSelectAllBusinessUnits,
        openEditBusinessUnitDialog: Actions.openEditBusinessUnitDialog,
        removeBusinessUnits: Actions.removeBusinessUnits,
        removeBusinessUnit: Actions.removeBusinessUnit,
        toggleStarredBusinessUnit: Actions.toggleStarredBusinessUnit,
        toggleStarredBusinessUnits: Actions.toggleStarredBusinessUnits,
        setBusinessUnitsStarred: Actions.setBusinessUnitsStarred,
        setBusinessUnitsUnstarred: Actions.setBusinessUnitsUnstarred,
        openNewBusinessUnitDialog: Actions.openNewBusinessUnitDialog
    }, dispatch);
}

function mapStateToProps({ businessUnitsApp, spReducers }) {
    return {
        businessUnits: businessUnitsApp.businessUnits.entities,
        selectedBusinessUnitIds: businessUnitsApp.businessUnits.selectedBusinessUnitIds,
        searchText: businessUnitsApp.businessUnits.searchText,
        user: businessUnitsApp.user,
        businessUnitData: spReducers.businessUnits,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessUnitsList)));
