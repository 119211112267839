import _ from '@lodash';
import { Avatar, Icon, Slide, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { openEditWorkOrderDialog } from 'main/content/apps/work-orders/store/actions';
import { openEditInvoiceDialog } from 'main/content/apps/invoices/store/actions';
import { openEditCustomerDialog } from 'main/content/apps/customers/store/actions';
import { openEditCustomerSiteDialog } from 'main/content/apps/customer-sites/store/actions';
import { openEditTechnicianDialog } from 'main/content/apps/technicians/store/actions';
import { openEditPurchaseOrderDialog } from 'main/content/apps/purchase-orders/store/actions';
import moment from 'moment';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import format from 'string-template';
import { ReportHeader } from './ReportTemplate';

const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        overflow: 'auto',
    },
    dialogRoot: {

    },
    profileAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    avatar: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 11,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
});

const newPivotTableState = {
    results: [],
    columns: [],
    selectedColumns: [],
    pivotBy: [],
    calculations: [],
    calculation: {
        ID: null,
        Title: null,
        Expression: null,
        format: null
    },
    selectedColumn: null,
    resized: [],
    expandAll: null,
    customExpanded: null
}

class ReportPDF extends Component {

    state = {
        ..._.cloneDeepWith(newPivotTableState)
    };

    componentDidMount() {
        const { results, options } = this.props;
        // window["warn"]('Pivot Table State From Mount: ', this.state);
        if (results && results.length > 0 && !options) {
            const columns = this.getColumns(results);
            this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, columns });
        } else {
            if (options && results && results.length > 0) {
                const { Options } = options;
                const pivot = this.buildPivot(results, Options);
                this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, ...pivot });
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { results, options, expandColumns } = this.props;
        if (!_.isEqual(results, prevProps.results) || !_.isEqual(options, prevProps.options)) {
            if (results && results.length > 0 && !options) {
                const columns = this.getColumns(results);
                this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, columns });

            } else {
                if (options && results && results.length > 0) {
                    const { Options } = options;
                    const pivot = this.buildPivot(results, Options);
                    this.setState({ ..._.cloneDeepWith(newPivotTableState), ...this.state, results, ...pivot });
                }
            }
        }

        if (!_.isEqual(prevProps.expandColumns, expandColumns)) {
            this.setState({ ...this.state, customExpanded: this.expandRows() });
        }
    }

    componentWillUnmount() {
        this.setState({ ..._.cloneDeepWith(newPivotTableState) });
    }

    expandRows = () => {
        let exp = {};
        if (this.pivotRef) {
            const tableState = this.pivotRef.getResolvedState();
            const recs = tableState.sortedData;

            exp = this.getExpanded(recs, exp);
        }
        return exp;
    }

    getExpanded = (recs, exp) => {
        const { expandColumns } = this.props;
        if (recs && exp) {
            recs.forEach((val, index) => {
                if (expandColumns.includes(val._pivotID)) {
                    exp[index] = val._subRows ? this.getExpanded(val._subRows, {}) : {};
                }
            })
        }
        return exp;
    }

    buildPivot = (data, options) => {
        const Options = JSON.parse(options);
        const { pivotBy, calculations, columnOptions, resized } = _.cloneDeepWith(Options);
        columnOptions.map((col) => {
            const resIndex = _.findIndex(resized, { id: col.id });
            if (resIndex > -1 && col.show !== undefined && !col.show) {
                resized.splice(resIndex, 1);
            }
        })
        const columns = this.getColumns(data, columnOptions, pivotBy, calculations);
        const selectedColumns = _.orderBy(_.filter(columns, (o) => { return _.findIndex(columnOptions, { id: o.id }) > -1 }), ['displayIndex'], ['asc']);

        return {
            pivotBy,
            columns,
            selectedColumns,
            calculations,
            resized: resized || []
        }
    }

    getColumns(data, columnOptions, pivotBy, calculations) {
        const columns = Object.keys(data[0]).map(key => {
            const existing = _.find(columnOptions, { id: key });
            return {
                Header: existing ? existing.Header : key,
                headerClassName: 'font-bold text-12 text-center',
                accessor: key,
                id: key,
                dataType: existing ? existing.dataType : data[0][key] ? !isNaN(data[0][key]) ? 'number' : moment(data[0][key]).isValid() ? 'date' : 'text' : 'text'
            };
        });
        if (calculations) {
            calculations.map((calculation) => {
                const agg = [];
                const column = _.find(columnOptions, { id: calculation.ID });
                const show = column && column.show !== undefined ? column.show : true;
                if (show) {
                    const col = {
                        Header: calculation.Title,
                        id: calculation.ID,
                        calculation: calculation.Expression,
                        calculationApplyTo: calculation.applyTo,
                        show: column && column.show !== undefined ? column.show : true,
                        Cell: rowInfo => {
                            // window["warn"]('Calculation rowInfo: ', rowInfo)
                            if (rowInfo.original) {
                                if (!calculation.applyTo || calculation.applyTo === 'O') {
                                    let val = '';
                                    const { row, original } = rowInfo;
                                    try {
                                        const exp = `${format(calculation.Expression, row)}`;
                                        val = loose(exp)
                                        val = isNaN(val) ? val : _.round(val, 2);
                                    } catch (ex) {
                                        val = ex.message;
                                    }
                                    row[calculation.id] = val;
                                    return <div className={`w-full text-12 ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                                } else {
                                    return <div></div>
                                }
                            } else {
                                if (!calculation.applyTo || calculation.applyTo === 'A') {
                                    let val = '';
                                    const { row } = rowInfo;
                                    try {
                                        const exp = `${format(calculation.Expression, row)}`;
                                        val = _.round(loose(exp), 2);
                                        val = isNaN(val) ? '' : val
                                    } catch (ex) {
                                        val = ex.message;
                                    }
                                    row[calculation.id] = val;
                                    agg.push(val);
                                    return <div className={`w-full text-12 font-bold ${!isNaN(val) ? 'text-center' : ''}`}>{calculation.format === 'D' ? this.formatDollars(val) : calculation.format === 'P' ? this.formatPercentage(val) : val}</div>
                                } else {
                                    return <div></div>
                                }
                            }
                        },
                        accessor: row => {
                            let val = row[calculation.id];
                            if (!calculation.applyTo || calculation.applyTo === 'O') {
                                try {
                                    const exp = `${format(calculation.Expression, row)}`;
                                    val = loose(exp);
                                } catch (ex) {
                                    val = ex.message;
                                }
                            }
                            return val;
                        },
                        aggregate: (vals, rows) => {
                            let val = '';
                            if (!calculation.applyTo || calculation.applyTo === 'A') {
                                if (rows) {
                                    let row = {};
                                    columnOptions.map((col) => {
                                        if (!col.isCalculation && (!pivotBy || pivotBy.indexOf(col.id) < 0) && col.aggregation) {
                                            row[col.id] = col.aggregation === 'count' ? rows && rows[0] && rows[0]._original ? _.filter(rows, (o) => { return o[col.id] }).length : _.round(_.sumBy(rows, col.id), 2) : _.round(_[`${col.aggregation}By`](rows, col.id), 2);
                                        }
                                    });
                                    try {
                                        const exp = `${format(calculation.Expression, row)}`;
                                        val = loose(exp)
                                        val = isNaN(val) ? val === 'NaN' ? '' : val : _.round(val, 2);
                                    } catch (ex) {
                                        val = ex.message;
                                    }
                                    // window["warn"]('Calc Agg RowInfo', row, rows, val)
                                }
                            }
                            return val;
                        },
                        headerClassName: 'font-bold text-12 text-center',
                        isCalculation: true,
                    };
                    columns.push(col);
                }
            })
        }
        if (pivotBy) {
            pivotBy.map((piv) => {
                const column = _.find(columns, { id: piv });
                if (column) {
                    const selectedColumn = _.find(columnOptions, { id: piv });
                    if (selectedColumn) {
                        column.format = selectedColumn.format;
                    }
                    column.Aggregated = cellInfo => { return <div className="w-full font-bold text-12 text-center"></div> };
                    if (['Technician', 'TeamLead', 'TeamMember'].indexOf(column.id) > -1) {
                        // column.disableExpander = true;
                        // column.accessor = row => this.getTechnicianName(row[column.id]);
                        column.Pivot = ({ value }) => <div className="font-bold text-12">{this.getTechnician(value)}</div>;
                    } else if (['WorkOrder', 'WO'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditWorkOrderDialog({ Co: this.props.Co, WorkOrder: value }) }} className="ml-4 text-12 font-bold align-middle no-print" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['Invoice', 'InvoiceNumber'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditInvoiceDialog({ Co: this.props.Co, InvoiceNumber: value }) }} className="ml-4 text-12 font-bold align-middle no-print" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['Customer', 'Cust', 'CustNum'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditCustomerDialog({ Co: this.props.Co, Customer: value }) }} className="ml-4 text-12 font-bold align-middle no-print" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else if (['PO', 'PONumber', 'PurchaseOrder'].indexOf(column.id) > -1) {
                        column.Pivot = ({ value }) => <div className={`w-full font-bold text-12 text-center`}>{value}<Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditPurchaseOrderDialog({ Co: this.props.Co, PO: value }) }} className="ml-4 text-12 font-bold align-middle no-print" style={{ marginBottom: 2 }}>open_in_new</Icon></Tooltip></div>;
                    } else {
                        column.Pivot = ({ value }) => {
                            return <div className={`w-full font-bold text-12 ${column.dataType !== 'text' ? 'text-center ' : ''}`}>{column.dataType === 'date' ? this.getFormattedDate(value, column.format) : value}</div>
                        };
                    }
                    if (column.dataType === 'date') {
                        column.accessor = row => {
                            return this.getDateAccessor(row[column.id], column.format)
                        };
                    }
                }
            });
        };
        if (columnOptions) {
            columnOptions.map((col) => {
                const { id, Header, format, dataType, aggregation, show, width, isCalculation, displayIndex } = col;
                const column = _.find(columns, { id });
                if (column) {
                    column.Header = Header;
                    if (!isCalculation && !column.Pivot) {
                        column.show = show;
                        column.aggregation = aggregation;
                        column.format = format;
                        column.aggregate = (vals, rows) => { if (aggregation === 'count') { return rows && rows[0] && rows[0]._original ? _.filter(rows, (o) => { return o[column.id] }).length : _.round(_.sum(vals), 2) } else { return (dataType === 'number') && aggregation ? _.round(_[aggregation](vals), 2) : '' } };
                        column.Aggregated = cellInfo => {
                            return <div className={`w-full font-bold text-12 ${dataType !== 'text' || aggregation === 'count' ? 'text-center ' : ''}`}>{dataType === 'number' && aggregation ? column.format === 'D' ? this.formatDollars(cellInfo.value) : column.format === 'P' ? this.formatPercentage(cellInfo.value) : cellInfo.value : aggregation === 'count' ? cellInfo.value : ''}</div>
                        }
                        column.Cell = row => {
                            return (
                                <div className={`w-full text-12 ${dataType !== 'text' ? 'text-center ' : ''}`}>{dataType === 'date' ? this.getFormattedDate(row.value, column.format) : column.format === 'D' ? this.formatDollars(row.value) : column.format === 'P' ? this.formatPercentage(row.value) : row.value}</div>
                            )
                        }
                    }
                    column.displayIndex = displayIndex;
                    if (column.dataType === 'date') {
                        column.accessor = row => {
                            return this.getDateAccessor(row[column.id], column.format)
                        };
                    }
                }
            });
        }
        return columns;
    }

    getFormattedDate = (date, format) => {
        let formatted = date;
        const dt = moment(date);
        switch (format) {
            case 'D': {
                formatted = moment(date).format("MM/DD/YYYY")
            }
                break;
            case 'Y': {
                formatted = date
            }
                break;
            case 'Q': {
                formatted = `Q${dt.quarter()} ${dt.format('YYYY')}`
            }
                break;
            case 'M': {
                formatted = `${dt.format("MMMM")} ${dt.format("YYYY")}`
            }
                break;
            case 'W': {
                formatted = `Week #${dt.isoWeek()} ${dt.format("YYYY")}`
            }
                break;
            case 'DW': {
                formatted = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date]
            }
                break;
            case 'T': {
                formatted = dt.format("h:mm A")
            }
                break;
            case 'H': {
                formatted = dt.startOf("hour").format("h:mm A")
            }
                break;
        }
        return formatted
    }

    getDateAccessor = (date, format) => {
        let formatted = date;
        const dt = moment(date);
        switch (format) {
            case 'D': {
                formatted = new Date(moment(date).format("MM/DD/YYYY")).toISOString()
            }
                break;
            case 'Y': {
                formatted = dt.format("YYYY")
            }
                break;
            case 'Q': {
                formatted = new Date(`${dt.startOf('quarter').format("MM/DD/YYYY")}`).toISOString()
            }
                break;
            case 'M': {
                formatted = new Date(`${dt.startOf('month').format("MM/DD/YYYY")}`).toISOString()
            }
                break;
            case 'W': {
                formatted = new Date(dt.startOf('week').format("MM/DD/YYYY")).toISOString()
            }
                break;
            case 'DW': {
                formatted = dt.day()
            }
                break;
            case 'T': {
                formatted = new Date(`1/1/1900 ${dt.local().format("h:mm A")}`).toISOString()
            }
                break;
            case 'H': {
                formatted = new Date(`1/1/1900 ${dt.local().startOf("hour").format("h:mm A")}`).toISOString()
            }
                break;
        }
        return formatted
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (!isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    getTechnician = (Technician) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return (
                <span className="flex">
                    <Avatar style={{ marginRight: 8, marginLeft: 8, width: 24, height: 24, }} classes={{ root: classes.avatarRoot }} className={classes.profileAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? `${window["apiLocation"]}/api/Attachment/GetPDFImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` : avatar)} />
                    <div className="mt-6">{`${value.FirstName} ${value.LastName}`}</div>
                    <Tooltip placement="top" title="Click to view full record"><Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.openEditTechnicianDialog({ Co: this.props.Co, Technician }) }} className="ml-4 text-12 font-bold align-middle mt-8 no-print">open_in_new</Icon></Tooltip>
                </span>
            )
        } else {
            return Technician;
        }
    }

    getTechnicianName = (Technician) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return `${value.FirstName} ${value.LastName}`;
        } else {
            return Technician;
        }
    }

    render() {
        const { master, component, hidden, getTable, reportHeader, horizontalMargin } = this.props;
        const { results, selectedColumns, pivotBy, resized, customExpanded, orientation } = this.state;
        const exp = {};

        window["warn"]('Rerendering Table: ', this.state, this.props, resized);
        return (
            <div style={hidden ? { position: 'absolute', left: '-100vw', top: '100vh', minWidth: orientation === 'portrait' ? '8in' : '10.5in' } : {}}>
                <div className="w-full" id="report-print" style={{ border: '1px solid rgba(0,0,0,0.1)', height: '100%' }}>

                    {reportHeader &&
                        <ReportHeader
                            master={master}
                            Report={component.Data.DataSource}
                            style={{ padding: `0.5in ${horizontalMargin}in` }}
                        />
                    }
                    <ReactTable
                        data={results}
                        columns={[...selectedColumns]}
                        ref={el => { this.pivotRef = el; if (getTable) { getTable(el) } }}
                        minRows={0}
                        showPagination={false}
                        defaultResized={resized}
                        expanded={customExpanded || undefined}
                        pivotBy={[...pivotBy]}
                        style={{ border: 'none' }}
                        TableComponent="table"
                        TheadComponent="thead"
                        getTheadTrProps={() => ({ className: 'w-full' })}
                        getTrProps={() => ({ className: 'prevent-split w-full' })}
                    />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditWorkOrderDialog,
        openEditInvoiceDialog,
        openEditCustomerDialog,
        openEditCustomerSiteDialog,
        openEditTechnicianDialog,
        openEditPurchaseOrderDialog,
        showMessage,
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        employees: spReducers.employees,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportPDF)));
