import React, { Component } from 'react';
import {
    withStyles,
    Button,
    Card,
    CardHeader,
    CardActions,
    Avatar,
    Divider,
    Tooltip,
    Badge,
    Icon
} from '@material-ui/core';
import classNames from 'classnames';
import { FuseScrollbars, FuseAnimateGroup } from '@fuse';
import { connect } from 'react-redux';
import { getConversation } from 'main/messaging/store/actions';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import _ from '@lodash';
import Chat from './Chat';

const styles = theme => ({
    root: {
        background: theme.palette.background.default,
        minWidth: 70,
        width: '100%',
    },
    contactButton: {
        width: 70,
        minWidth: 70,
        flex: '0 0 auto',
        '&.active:after': {
            position: 'absolute',
            top: 8,
            right: 0,
            bottom: 8,
            content: "''",
            width: 4,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    },
    unreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    inactiveUnreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#8f8f8f',
        color: '#fff',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    activeAvatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    avatar: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
    },
    videoBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: 0,
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    status: {
        position: 'absolute',
        width: 12,
        height: 12,
        bottom: 4,
        left: 44,
        border: '2px solid ' + theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 10,

        '&.online': {
            backgroundColor: '#4CAF50'
        },

        // '&.do-not-disturb': {
        //     backgroundColor: '#F44336'
        // },

        '&.idle': {
            backgroundColor: '#FFC107'
        },

        '&.offline': {
            backgroundColor: '#9f9f9f'
        }
    },
    card: {
        cursor: 'pointer',
        width: '100%',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
    },
    cardHeader: {
        paddingLeft: 16,
    },
    title: {
        fontWeight: 'bold',
    },
    subheader: {
        fontSize: 11,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 256,
        maxWidth: '100%',
    },
});

class ContactList extends Component {

    handleContactClick = (conversation) => {
        this.props.closeChatPanel();
        this.props.getConversation(this.props.Co, conversation.toLowerCase());

    };

    scrollToTop = () => {
        this.contactListScroll.scrollTop = 0;
    };

    formatPhone = (phone) => {
        const short = phone.substr(phone.length - 10, 10);
        return `(${short.substr(0, 3)}) ${short.substr(3, 3)}-${short.substr(6, 4)}`;
    }

    render() {
        const { Co, classes, texts, user, selectedContactId } = this.props;

        return (
            <FuseScrollbars
                className={classNames(classes.root, "flex flex-no-shrink flex-col overflow-y-auto py-8")}
                containerRef={(ref) => {
                    this.contactListScroll = ref
                }}
            >
                {texts && (
                    <React.Fragment>
                        <FuseAnimateGroup
                            enter={{
                                animation: "transition.expandIn"
                            }}
                            className="flex flex-col flex-no-shrink"
                        >
                            {
                                texts.map((chat, index) => {
                                    const last = chat.Messages[chat.Messages.length - 1];
                                    const contact = chat;
                                    const unread = last.To === chat.To;
                                    const active = last.EndConversationYN !== 'Y';
                                    return (
                                        <Tooltip title={`${this.formatPhone(chat.From)}`} placement="left">
                                            <Card className={classes.card}>
                                                <CardHeader onClick={() => this.handleContactClick(chat.Conversation)} classes={{ title: classes.title, subheader: classes.subheader, avatar: "mr-16" }} className={classNames(classes.cardHeader, "p-6 pl-16 pr-16 highlight")}
                                                    avatar={
                                                        <React.Fragment>
                                                            {unread && (
                                                                <div className={active ? classes.unreadBadge : classes.inactiveUnreadBadge}><Icon className="text-12">textsms</Icon></div>
                                                            )}
                                                            <Avatar
                                                                className={active ? classes.activeAvatar : classes.avatar}
                                                            >
                                                                <Icon>phone_iphone</Icon>
                                                            </Avatar>
                                                        </React.Fragment>
                                                    }
                                                    title={this.formatPhone(chat.From)}
                                                    subheader={chat.Messages[0].Body}
                                                />
                                            </Card>
                                        </Tooltip>
                                    )
                                })
                            }
                        </FuseAnimateGroup>
                    </React.Fragment>
                )}
            </FuseScrollbars>
        );
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChat: Actions.getChat,
        closeChatPanel: Actions.closeChatPanel,
        getConversation,
    }, dispatch);
}

function mapStateToProps({ chatPanel, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        chats: chatPanel.user.conversations,
        selectedContactId: chatPanel.contacts.selectedContactId,
        user: spReducers.userProfiles.User,
        texts: spReducers.textMessages,
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactList));

