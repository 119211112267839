import { Geolocation } from '@capacitor/geolocation'
import { FuseAnimate, FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'
import * as dialerActions from 'main/dialer/store/actions'
import moment from 'moment'
import React, { Component } from 'react'
import { showMessage } from 'store/actions'
import Geocode from 'react-geocode'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSelectedWO } from '../content/apps/dispatch-board/store/actions'
import { openEditTechnicianDialog } from '../content/apps/technicians/store/actions'
import { openEditUserDialog } from '../content/apps/users/store/actions'
import { openEditWorkOrderDialog } from '../content/apps/work-orders/store/actions'
import Autocomplete from '../content/components/autocomplete/Autocomplete'
import { DatePicker } from '@material-ui/pickers'
import Chat from './Chat'
import ContactList from './ContactList'
import * as Actions from './store/actions'
import { isMobile } from 'react-device-detect'
import TextList from './TextList'
import WOMarker from './WOMarker'
import history from 'history.js'
import axios from 'axios'
import { theme } from 'highcharts'
import getProfileImage from '../functions/getProfileImageUrl'

function Marker({
  text,
  icon,
  style,
  badge,
  heading,
  type,
  onClick,
  tooltipOpen,
  status,
  classes,
}) {
  const rotate =
    type === 'location'
      ? 'none'
      : heading && heading >= 0
        ? heading <= 180
          ? `rotate(${heading - 90}deg)`
          : `rotate(${heading - 270}deg)`
        : 'none'
  return (
    <Tooltip title={text} open={tooltipOpen} placement='top'>
      <div
        onClick={onClick}
        style={{
          transform: rotate,
          marginTop: type === 'location' ? -8 : undefined,
          marginLeft: type === 'location' ? -8 : undefined,
        }}
      >
        {badge && badge > 0 ? (
          <Badge
            badgeContent={badge}
            classes={{
              badge:
                type === 'truck'
                  ? heading > 180
                    ? 'truck-badge-west'
                    : 'truck-badge'
                  : type === 'site'
                    ? 'site-map-badge'
                    : '',
            }}
            color='error'
          >
            <img
              onMouseDown={e => {
                e.stopPropagation()
              }}
              style={style}
              src={icon}
            />
          </Badge>
        ) : type === 'person' ? (
          <div className={'person-marker'}>
            <img style={{ ...style }} src={icon} />
          </div>
        ) : type === 'location' ? (
          <div
            className='relative min-w-16 min-h-16'
            style={{
              cursor: 'pointer',
              transform:
                heading > -1
                  ? `rotate(${Math.round(heading) - 45}deg)`
                  : undefined,
            }}
          >
            {heading > -1 && (
              <div
                className='min-w-16 min-h-16 rounded-full absolute m-auto'
                style={{ backgroundColor: '#fff', borderTopRightRadius: 0 }}
              ></div>
            )}
            <div
              className='min-w-16 min-h-16 rounded-full absolute'
              style={{
                backgroundColor:
                  status === 'moving'
                    ? '#d1e751'
                    : status === 'idle'
                      ? '#4dbce9'
                      : '#c22d2d',
                border: heading > -1 ? '2px solid #5f5f5f' : '1px solid #fff',
              }}
            ></div>
          </div>
        ) : icon ? (
          <img
            onMouseDown={e => {
              e.stopPropagation()
            }}
            style={style}
            src={icon}
          />
        ) : (
          <div style={style}></div>
        )}
      </div>
    </Tooltip>
  )
}

const styles = theme => ({
  root: {
    width: 70,
    maxWidth: 70,
    minWidth: 70,
    [theme.breakpoints.down('md')]: {
      width: 0,
      maxWidth: 0,
      minWidth: 0,
    },
  },
  panel: {
    position: 'absolute',
    width: 365,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    top: 0,
    height: '100%',
    minHeight: '100%',
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    transform: 'translate3d(295px,0,0)',
    overflow: 'hidden',
    paddingBottom: 64,
    [theme.breakpoints.down('md')]: {
      transform: 'translate3d(365px,0,0)',
      boxShadow: 'none',
      '&.opened': {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    '&.opened': {
      transform: 'translateX(0)',
      zIndex: 1004,
      paddingBottom: 0,
    },
  },
  mapPanel: {
    position: 'absolute',
    width: 365,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1001,
    height: 64,
    minHeight: 64,
    transform: 'translate3d(295px,0,0)',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      transform: 'translate3d(365px,0,0)',
      boxShadow: 'none',
      '&.opened': {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    '&.opened': {
      transform: 'translateX(0)',
    },
  },
  map: {
    width: 365,
    top: '50%',
    height: '50%',
    minHeight: '50%',
  },
  mapLeft: {
    width: 365,
    top: '50%',
    height: '50%',
    minHeight: '50%',
    left: 0,
  },
  fullWidth: {
    width: '100%',
    top: '50%',
    height: '50%',
    minHeight: '50%',
  },
  fullScreen: {
    width: '100%',
    top: 0,
    height: '100%',
    minHeight: '100%',
  },
  fullHeight: {
    width: 365,
    top: 0,
    height: '100%',
    minHeight: '100%',
  },
  fullHeightLeft: {
    width: 365,
    top: 0,
    height: '100%',
    minHeight: '100%',
    left: 0,
  },
  mapWindow: {
    background: '#333',
    left: 0,
    right: 0,
    top: 64,
    bottom: 0,
    position: 'absolute',
    margin: 'auto',
  },
  mapCanvas: {
    left: 8,
    right: 8,
    top: 'calc(env(safe-area-inset-top) + 74px)',
    bottom: 8,
    position: 'absolute',
    margin: 'auto',
    borderRadius: 5,
    overflow: 'hidden',
  },
  addressQuery: {
    left: 8,
    right: 8,
    top: 'calc(env(safe-area-inset-top) + 10px)',
    position: 'absolute',
    margin: 'auto',
  },
  search: {
    background: '#4f4f4f',
    border: 'none',
    borderRadius: 4,
  },
  hideMap: {
    //display: 'none !important',
  },
  mapAddress: {
    marginLeft: -46,
    paddingLeft: 56,
  },
  gpsAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  woAvatar: {
    backgroundColor: theme.palette.error[500],
    height: 18,
    minWidth: 18,
    fontSize: 13,
    borderRadius: 9,
    padding: 4,
    marginRight: 8,
    width: 'auto',
  },
  woAvatarGrey: {
    backgroundColor: '#5f5f5f',
    height: 18,
    minWidth: 18,
    fontSize: 13,
    borderRadius: 9,
    padding: 4,
    marginRight: 8,
    width: 'auto',
  },
  popper: {
    opacity: 1,
    marginBottom: 15,
    marginTop: 5,
    zIndex: 5,
    // opacity: 0,
  },
  tooltip: {
    opacity: 1,
    padding: 0,
    margin: 0,
    boxShadow: '2px 2px 6px #3333',
  },
})

class ChatPanel extends Component {
  state = {
    Address: '',
    Type: 'team',
    workOrders: [],
    date: new Date(),
    technicians: [],
    trips: [],
    advancedSearch: false,
    selectedUser: null,
    filterType: 'D',
    woCriteriaType: null,
    woCriteria: {
      folders: [],
      businessUnits: [],
      divisions: [],
      departments: [],
      serviceTypes: [],
      services: [],
      priorities: [],
      arrivalWindows: [],
    },
    criteria: {
      businessUnits: [],
      divisions: [],
      departments: [],
      teams: [],
      technicians: [],
    },
    selectedBoard: null,
    selectedFolders: [],
    selectedTechs: [],
    expanded: [],
    anchorUser: null,
    anchorEl: null,
    gpsHistory: null,
    gpsDate: moment().format('MM/DD/YYYY'),
    setGPSDate: false,
  }

  componentDidMount() {
    // this.props.getUserData();
    this.props.getContacts()

    var options = { types: ['address'] }
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete &&
      document.getElementById('mapAddress')
    ) {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('mapAddress'),
        options,
      )

      this.autocomplete.setFields(['formatted_address', 'geometry'])

      this.autocomplete.addListener('place_changed', this.handleAddressSelect)
    }
    const { workOrders, technicians, dispatchBoard, selectedBoard } = this.props
    const { schedule, date } = dispatchBoard
    this.setState(
      { workOrders, technicians, schedule, date, selectedBoard },
      this.getUserPosition,
    )
  }

  componentDidUpdate(prevProps, prevState) {
    var options = { types: ['address'] }
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete &&
      document.getElementById('mapAddress') &&
      !this.autocomplete
    ) {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('mapAddress'),
        options,
      )

      this.autocomplete.setFields(['formatted_address', 'geometry'])

      this.autocomplete.addListener('place_changed', this.handleAddressSelect)
    }
    const { workOrders, dispatchBoard, state } = this.props
    if (state.search && !_.isEqual(state.search, prevProps.state.search)) {
      if (state.search.Latitude && state.search.Longitude) {
        this.setState({
          searchAddress: state.search.Address,
          searchLatitude: Number(state.search.Latitude),
          searchLongitude: Number(state.search.Longitude),
        })
      } else {
        if (window.google) {
          Geocode.fromAddress(state.search.Address).then(
            response => {
              const { lat, lng } = response.results[0].geometry.location
              this.setState({
                searchAddress: state.search.Address,
                searchLatitude: lat,
                searchLongitude: lng,
              })
            },
            error => {
              this.setState({
                searchAddress: null,
                searchLatitude: null,
                searchLongitude: null,
              })
            },
          )
        }
      }
    }
    const { schedule, technicians, date, trips, selectedWO, selectedBoard } =
      dispatchBoard
    if (
      !_.isEqual(prevProps.workOrders, workOrders) ||
      !_.isEqual(prevProps.dispatchBoard.technicians, technicians) ||
      !_.isEqual(prevProps.dispatchBoard.date, date) ||
      !_.isEqual(prevProps.dispatchBoard.selectedBoard, selectedBoard) ||
      !_.isEqual(prevProps.dispatchBoard.schedule, schedule) ||
      !_.isEqual(selectedWO, prevProps.dispatchBoard.selectedWO)
    ) {
      this.setState(
        {
          workOrders,
          technicians,
          trips,
          schedule,
          date,
          selectedBoard: !_.isEqual(prevProps.selectedBoard, selectedBoard)
            ? selectedBoard
            : this.state.selectedBoard,
        },
        () => {
          if (selectedWO) {
            if (!_.isEqual(selectedWO, prevProps.dispatchBoard.selectedWO)) {
              if (selectedWO.Data && selectedWO.Data.Site) {
                if (
                  selectedWO.Data.Site.Latitude &&
                  selectedWO.Data.Site.Longitude
                ) {
                  this.setState({
                    searchAddress: null,
                    searchLatitude: Number(selectedWO.Data.Site.Latitude),
                    searchLongitude: Number(selectedWO.Data.Site.Longitude),
                  })
                } else {
                  if (window.google) {
                    Geocode.fromAddress(
                      `${selectedWO.Data.Site.Address1} ${selectedWO.Data.Site.City}, ${selectedWO.Data.Site.State} ${selectedWO.Data.Site.Zip}`,
                    ).then(
                      response => {
                        const { lat, lng } =
                          response.results[0].geometry.location
                        this.setState({
                          searchAddress: null,
                          searchLatitude: lat,
                          searchLongitude: lng,
                        })
                      },
                      error => {
                        this.setState({
                          searchAddress: null,
                          searchLatitude: null,
                          searchLongitude: null,
                        })
                      },
                    )
                  }
                }
              }
            }
          }
        },
      )
    }
  }

  getUserPosition = () => {
    const { gps } = this.props

    if (
      !gps ||
      !gps.location ||
      !gps.location.coords ||
      !gps.location.coords.latitude ||
      !gps.location.coords.longitude
    ) {
      var geoOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
      // console.log('Fetching GPS Location - Chat Panel');
      Geolocation.getCurrentPosition(geoOptions)
        .then(pos => {
          var crd = pos.coords

          var Latitude = crd.latitude
          var Longitude = crd.longitude
          this.setState({ ...this.state, Latitude, Longitude })
        })
        .catch(err => {
          // window["warn"](`ERROR(${err.code}): ${err.message}`);
        })
    } else {
      var Latitude = gps.location.coords.latitude
      var Longitude = gps.location.coords.longitude
      this.setState({ ...this.state, Latitude, Longitude })
    }
  }

  handleVerticalExpandMap = () => {
    const { map } = this.props.state
    let { resizeMap } = this.props
    switch (map) {
      case 'fullWidth':
        {
          resizeMap('fullScreen')
        }
        break
      case false:
        {
          resizeMap('map')
        }
        break
      case 'mapLeft':
        {
          resizeMap('fullHeightLeft')
        }
        break
      case 'map':
        {
          resizeMap('fullHeight')
        }
        break
    }
  }

  handleVerticalShrinkMap = () => {
    const { map } = this.props.state
    let { resizeMap } = this.props
    switch (map) {
      case 'fullScreen':
        {
          resizeMap('fullWidth')
        }
        break
      case 'fullHeight':
        {
          resizeMap('map')
        }
        break
      case 'fullHeightLeft':
        {
          resizeMap('mapLeft')
        }
        break
      case 'fullWidth':
        {
          resizeMap(false)
        }
        break
      case 'map':
        {
          resizeMap(false)
        }
        break
    }
  }

  handleHorizontalExpandMap = () => {
    const { map } = this.props.state
    let { resizeMap } = this.props
    switch (map) {
      case 'fullHeight':
        {
          resizeMap('fullScreen')
        }
        break
      case 'fullWidth':
        {
          resizeMap('mapLeft')
        }
        break
      case 'fullScreen':
        {
          resizeMap('fullHeightLeft')
        }
        break
      case 'map':
        {
          resizeMap('fullWidth')
        }
        break
    }
  }

  handleHorizontalShrinkMap = () => {
    const { map } = this.props.state
    let { resizeMap } = this.props
    switch (map) {
      case 'fullScreen':
        {
          resizeMap('fullHeight')
        }
        break
      case 'fullHeightLeft':
        {
          resizeMap('fullScreen')
        }
        break
      case 'mapLeft':
        {
          resizeMap('fullWidth')
        }
        break
      case 'fullWidth':
        {
          resizeMap('map')
        }
        break
      case 'fullHeight':
        {
          resizeMap(false)
        }
        break
      case 'map':
        {
          resizeMap(false)
        }
        break
    }
  }

  handleScriptLoad = () => {}

  handleAddressChange = ev => {
    this.setState({ Address: ev.target.value })
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleAddressSelect = () => {
    const place = this.autocomplete.getPlace()
    const location = place.geometry.location

    if (place && location) {
      this.setState({
        searchAddress: place.formatted_address,
        searchLatitude: location && location.lat ? location.lat() : null,
        searchLongitude: location && location.lng ? location.lng() : null,
      })
    }
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      clickableIcons: false,
      // gestureHandling: "cooperative",
      // mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#d1e751',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 16,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#292929',
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#333333',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: '60',
            },
            {
              visibility: 'on',
            },
            {
              weight: 2,
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#333333',
            },
            {
              lightness: '17',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#4dbce9',
            },
            {
              saturation: -60,
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              lightness: '10',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [
            {
              lightness: '20',
            },
            {
              weight: 3,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#878787',
            },
          ],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [
            {
              lightness: '2',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [
            {
              lightness: '30',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#5a5c57',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#292929',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#212121',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#949494',
            },
            {
              weight: 1,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#b0b0b0',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a8a8a8',
            },
            {
              lightness: 15,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 30,
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#0f252e',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#4dbce9',
            },
            {
              saturation: -55,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#4a4a4a',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#333333',
            },
          ],
        },
      ],
    }
  }

  handleVideoChat = unm => {
    const { closeChatPanel, toggleDialer, initCall, dialer, user } = this.props
    closeChatPanel()
    toggleDialer(true)
    initCall(
      dialer,
      {
        destinationNumber: unm.split('@')[0],
        audio: true,
        video: true,
      },
      user.UserName,
      unm,
    )
  }

  getHostName = url => {
    var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === 'string' &&
      match[2].length > 0
    ) {
      return match[2]
    } else {
      return null
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    const data = FuseUtils.filterArrayByString(arr, searchText)
    return data
  }

  toggleList = name => {
    const { expanded } = this.state
    const index = expanded.indexOf(name)
    if (index > -1) {
      expanded.splice(index, 1)
    } else {
      expanded.push(name)
    }
    this.setState({ ...this.state, expanded })
  }

  getWOData = () => {
    const { searchText, loading } = this.props
    const { workOrders, expanded, date, trips } = this.state
    const woData = loading
      ? []
      : this.getFilteredArray(workOrders ? workOrders : {}, searchText)
    const agreements = _.filter(woData, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Type === 'A' &&
            s.Status !== 4 &&
            /*new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') &&*/ s
              .Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    const unassigned = _.filter(woData, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Type !== 'A' &&
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    const ongoing = _.filter(woData, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Type !== 'A' &&
            !s.DueBy &&
            s.Data.Assignments.length > 0 &&
            s.Status < 4
          )
        }).length > 0
      )
    })
    const onHold = _.filter(woData, o => {
      return (
        _.filter(o.Data.Trips, t => {
          return (
            t.Status === 7 &&
            _.filter(
              o.Data.Trips,
              h =>
                h.Trip !== t.Trip &&
                new Date(
                  h.InProgressDate ||
                    h.ArrivedDate ||
                    h.EnRouteDate ||
                    h.ScheduledDate,
                ) > new Date(t.OnHoldDate || t.ScheduledDate),
            ).length < 1
          )
        }).length > 0
      )
    })
    const assigned = _.filter(woData, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return s.Status !== 4 && s.Data.Assignments.length > 0
        }).length > 0 && _.filter(trips, { WorkOrder: o.WorkOrder }).length > 0
      )
    })
    const canceled = _.filter(woData, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return s.Status === 4
        }).length > 0
      )
    })
    const dateException = _.filter(woData, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            Boolean(s.DueBy) &&
            moment(s.DueBy).format('M/D/YYYY') ===
              moment(date).format('M/D/YYYY') &&
            s.Type !== 'A' &&
            s.Status !== 4 &&
            s.Data.Assignments.length > 0
          )
        }).length > 0 && _.filter(trips, { WorkOrder: o.WorkOrder }).length < 1
      )
    })

    const filteredWOs = []
    if (expanded.indexOf('Unassigned') > -1) {
      unassigned.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }
    if (expanded.indexOf('Agreements') > -1) {
      agreements.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }
    if (expanded.indexOf('OnHold') > -1) {
      onHold.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }
    if (expanded.indexOf('Ongoing') > -1) {
      ongoing.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }
    if (expanded.indexOf('Scheduled') > -1) {
      assigned.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }
    if (expanded.indexOf('Canceled') > -1) {
      canceled.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }
    if (expanded.indexOf('DateException') > -1) {
      dateException.map(wo => {
        if (_.findIndex(filteredWOs, { WorkOrder: wo.WorkOrder }) < 0)
          filteredWOs.push(wo)
      })
    }

    return {
      agreements,
      unassigned,
      ongoing,
      onHold,
      assigned,
      canceled,
      dateException,
      filteredWOs,
    }
  }

  getGPSHistory = user => {
    const { gpsDate } = this.state
    const dto = new Date().getTimezoneOffset() / 60
    const request = axios.get(
      `${window['apiLocation']}/api/UserGPS?User=${encodeURIComponent(
        user,
      )}&Date=${encodeURIComponent(gpsDate)}&Offset=${dto}`,
    )
    request
      .then(response => {
        window['log']('GPS History: ', response.data)
        this.setState({
          selectedUser: user,
          gpsHistory: {
            user,
            history: response.data,
          },
        })
        if (response.data.length <= 0) {
          window['warn']('Hello?')
          this.props.showMessage({
            message: `${user} has no GPS history for ${gpsDate}`,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            variant: 'warning',
          })
        }
      })
      .catch(() => {
        this.setState({ gpsHistory: null })
      })
  }

  render() {
    const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

    const {
      Co,
      classes,
      openChatPanel,
      textMessages,
      closeChatPanel,
      customFolders,
      securables,
      loading,
      searchText,
      user,
      openMap,
      sharedBoards,
      closeMap,
      resizeMap,
      employees,
      technicians,
      contacts,
      selectedContactId,
      state,
      master,
      toggleDialer,
      dialer,
      openEditUserDialog,
      openEditTechnicianDialog,
      openEditWorkOrderDialog,
      users,
    } = this.props
    const {
      Address,
      Latitude,
      Longitude,
      expanded,
      searchLatitude,
      trips,
      searchLongitude,
      searchAddress,
      Type,
      advancedSearch,
      selectedUser,
      gpsDate,
      setGPSDate,
      gpsHistory,
      woCriteria,
      woCriteriaType,
      criteria,
      criteriaType,
      selectedBoard,
      anchorUser,
      anchorEl,
    } = this.state

    const gps = []
    users.map(u => {
      if (u.Data && u.Data.Location) {
        gps.push(u.Data.Location)
      }
    })
    const { selectedWO } = this.props.dispatchBoard

    const selectedContact = contacts.find(
      _contact => _contact.UserName === selectedContactId,
    )
    const loc = window.location.href
    const isLocal = this.getHostName(loc) === 'localhost'
    const userAvatar = 'assets/images/avatars/profile.jpg'
    const selUser = selectedUser
      ? _.find(gps, { UserName: selectedUser })
      : null

    const accessLevel = false

    let canDispatch = false
    const { Pages } = user.Data
    if (_.find(Pages, { id: 'dispatch-board' })) {
      canDispatch = true
    }

    const priorities = [
      { Priority: 3, Description: 'Low' },
      { Priority: 2, Description: 'Medium' },
      { Priority: 1, Description: 'High' },
    ]
    const woCriteriaLists = {
      folders: {
        key: 'Folder',
        format: ['Name'],
        label: 'Dispatch Folder',
        listName: 'Dispatch Folders',
        icon: 'folder',
      },
      businessUnits: {
        key: 'BusinessUnit',
        format: ['Description'],
        label: 'Business Unit',
        listName: 'Business Units',
        icon: 'business_center',
      },
      divisions: {
        key: 'Division',
        format: ['Description'],
        label: 'Division',
        listName: 'Divisions',
        icon: 'location_city',
      },
      departments: {
        key: 'Department',
        format: ['Description'],
        label: 'Department',
        listName: 'Departments',
        icon: 'business',
      },
      serviceTypes: {
        key: 'ServiceType',
        format: ['Description'],
        label: 'Service Type',
        listName: 'Service Types',
        icon: 'settings',
      },
      services: {
        key: 'Service',
        format: ['Service', ' - ', 'Description'],
        label: 'Service',
        listName: 'Services',
        icon: 'build',
      },
      priorities: {
        key: 'Priority',
        format: ['Description'],
        label: 'Priority',
        listName: 'Priorities',
        icon: 'flag',
      },
      arrivalWindows: {
        key: 'ArrivalWindow',
        format: ['Description'],
        label: 'Arrival Window',
        listName: 'Arrival Windows',
        icon: 'access_time',
      },
    }
    const techCriteriaLists = {
      businessUnits: {
        key: 'BusinessUnit',
        format: ['Description'],
        label: 'Business Unit',
        listName: 'Business Units',
        icon: 'business_center',
      },
      divisions: {
        key: 'Division',
        format: ['Description'],
        label: 'Division',
        listName: 'Divisions',
        icon: 'location_city',
      },
      departments: {
        key: 'Department',
        format: ['Description'],
        label: 'Department',
        listName: 'Departments',
        icon: 'business',
      },
      teams: {
        key: 'Team',
        format: ['Name'],
        label: 'Team',
        listName: 'Teams',
        icon: 'supervised_user_circle',
      },
      technicians: {
        key: 'Technician',
        format: ['FirstName', 'LastName'],
        label: 'Technician',
        listName: 'Technicians',
        icon: 'people',
      },
    }
    const criteriaList = techCriteriaLists[criteriaType]
    const woCriteriaList = woCriteriaLists[woCriteriaType]
    const data = { ...master, priorities }

    const { teams } = master

    let boards =
      user && user.Data && user.Data.DispatchBoards
        ? _.filter(user.Data.DispatchBoards, o => o.SharedYN !== 'Y')
        : []
    boards = [...sharedBoards, ...boards]
    let folders =
      user && user.Data && user.Data.DispatchFolders
        ? _.filter(user.Data.DispatchFolders, o => o.SharedYN !== 'Y')
        : []
    folders = [...customFolders, ...folders]
    const board = _.find(boards, { Co, Board: selectedBoard })
    let techData = loading
      ? []
      : this.getFilteredArray(
          _.filter(technicians, o => o.ActiveYN !== 'N'),
          searchText,
        )
    let options
    if (board) {
      options = JSON.parse(board.Options)
      const { criteria } = options
      Object.keys(criteria).map(paramKey => {
        const params = criteria[paramKey]
        if (params.length > 0) {
          const list = techCriteriaLists[paramKey]
          if (paramKey !== 'teams') {
            techData = _.filter(techData, o => {
              return params.indexOf(o.Technician[list.key]) > -1
            })
          } else {
            techData = _.filter(techData, o => {
              let exists = false
              params.map(Team => {
                const team = _.find(teams, { Co, Team })
                if (
                  _.find(team.Data.Members, {
                    Co,
                    TeamMember: o.Technician.Technician,
                    ActiveYN: 'Y',
                  }) ||
                  _.find(team.Data.Leads, {
                    Co,
                    TeamLead: o.Technician.Technician,
                    ActiveYN: 'Y',
                  })
                ) {
                  exists = true
                }
              })
              return exists
            })
          }
        }
      })
    }

    const {
      agreements,
      unassigned,
      ongoing,
      onHold,
      assigned,
      canceled,
      dateException,
      filteredWOs,
    } = this.getWOData()
    const userAccessLevel = _.find(securables, { Securable: 'users' })
    const techAccessLevel = _.find(securables, { Securable: 'technicians' })

    return (
      <div className={classNames(classes.root, 'hide-scroll')}>
        <ClickAwayListener
          onClickAway={() => state.chat && closeChatPanel()}
          touchEvent='onTouchStart'
          mouseEvent='onMouseDown'
        >
          {/* <Portal> */}
          <div
            className={classNames(
              classes.panel,
              { opened: state.chat },
              'flex flex-col',
            )}
          >
            <AppBar className='chat-panel' position='static' elevation={1}>
              <Toolbar className='p-6'>
                <div className='flex flex-1 items-center'>
                  {(!state.chat || !selectedContactId) && (
                    <React.Fragment>
                      <IconButton color='inherit' onClick={openChatPanel}>
                        <Tooltip title='Open Chat Panel' placement='left'>
                          <Icon className='text-32'>chat</Icon>
                        </Tooltip>
                      </IconButton>
                      {!selectedContactId && (
                        <TextField
                          className='text-16 ml-8'
                          placeholder='Type'
                          select
                          value={Type}
                          onChange={e => {
                            e.stopPropagation()
                            this.setState({
                              ...this.state,
                              Type: e.target.value,
                            })
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: 'text-white',
                          }}
                        >
                          <MenuItem value='team'>Team Chat</MenuItem>
                          <MenuItem value='text'>Text Conversations</MenuItem>
                        </TextField>
                      )}
                    </React.Fragment>
                  )}
                  {state.chat && selectedContact && (
                    <React.Fragment>
                      <Avatar
                        className='ml-6'
                        src={
                          selectedContact.Data &&
                          selectedContact.Data.Avatar &&
                          getProfileImage(
                            `Co=${Co}&ID=${selectedContact.Data.Avatar}`,
                          )
                        }
                        alt={`${selectedContact.FirstName} ${selectedContact.LastName}`}
                      >
                        {!selectedContact.Data ||
                        !selectedContact.Data.Avatar ||
                        selectedContact.Data.Avatar === ''
                          ? selectedContact.FirstName[0]
                          : ''}
                      </Avatar>
                      <Typography
                        className='ml-16 text-16'
                        color='inherit'
                      >{`${selectedContact.FirstName} ${selectedContact.LastName}`}</Typography>
                      {dialer &&
                        selectedContact.Data &&
                        selectedContact.Data.Status > 0 &&
                        selectedContact.Data &&
                        selectedContact.Data.Video &&
                        selectedContact.Data.Video.Status === 1 && (
                          <Tooltip title='Video Chat' placement='top'>
                            <Icon
                              onClick={() =>
                                this.handleVideoChat(selectedContact.UserName)
                              }
                              style={{
                                marginLeft: 12,
                                marginTop: 4,
                                cursor: 'pointer',
                              }}
                            >
                              videocam
                            </Icon>
                          </Tooltip>
                        )}
                    </React.Fragment>
                  )}
                </div>
                <IconButton onClick={closeChatPanel} color='inherit'>
                  <Icon>close</Icon>
                </IconButton>
              </Toolbar>
            </AppBar>
            <Paper className='flex flex-1 flex-row min-h-px'>
              {Type === 'team' ? (
                <React.Fragment>
                  <ContactList className='flex flex-no-shrink' />
                  <Chat className='flex flex-1 z-10' />
                </React.Fragment>
              ) : (
                <TextList className='flex flex-1 z-10' />
              )}
            </Paper>
          </div>
          {/* </Portal> */}
        </ClickAwayListener>
        {/* START HERE */}
        <div
          className={classNames(
            classes.mapPanel,
            state.map && classes[state.map],
            { opened: state.map || state.chat },
            'flex flex-col pin-bottom',
          )}
        >
          <AppBar className='map-panel' position='static' elevation={1}>
            <Toolbar className='p-6'>
              <div className='flex flex-1 items-center'>
                <React.Fragment>
                  <IconButton color='inherit' onClick={openMap}>
                    <Tooltip
                      title={
                        this.state.Longitude && this.state.Latitude
                          ? 'View Map'
                          : 'Enable Location servies to view map'
                      }
                      placement='left'
                    >
                      <Icon
                        className='text-32'
                        style={{
                          color:
                            this.state.Longitude && this.state.Latitude
                              ? 'white'
                              : '#f44336',
                        }}
                      >
                        map
                      </Icon>
                    </Tooltip>
                  </IconButton>
                </React.Fragment>
              </div>
              {!this.state.Longitude && !this.state.Latitude ? (
                <div
                  className='absolute h-full flex justify-center items-center font-bold text-center'
                  style={{ left: 75 }}
                >
                  Please Enable Location services!
                </div>
              ) : null}
              {state.map &&
                this.state.Longitude &&
                this.state.Latitude &&
                ['fullHeightLeft', 'mapLeft'].indexOf(state.map) < 0 && (
                  <IconButton
                    color='inherit'
                    onClick={this.handleHorizontalExpandMap}
                  >
                    <Icon>keyboard_arrow_left</Icon>
                  </IconButton>
                )}
              {['fullHeight', 'fullScreen', 'fullHeightLeft'].indexOf(
                state.map,
              ) < 0 &&
                this.state.Longitude &&
                this.state.Latitude && (
                  <IconButton
                    color='inherit'
                    onClick={this.handleVerticalExpandMap}
                  >
                    <Icon>keyboard_arrow_up</Icon>
                  </IconButton>
                )}
              {['fullWidth', 'map', 'mapLeft'].indexOf(state.map) < 0 &&
                this.state.Longitude &&
                this.state.Latitude && (
                  <IconButton
                    color='inherit'
                    onClick={this.handleVerticalShrinkMap}
                  >
                    <Icon>keyboard_arrow_down</Icon>
                  </IconButton>
                )}
              {state.map &&
                this.state.Longitude &&
                this.state.Latitude &&
                [
                  'fullHeightLeft',
                  'mapLeft',
                  'fullScreen',
                  'fullWidth',
                ].indexOf(state.map) > -1 && (
                  <IconButton
                    color='inherit'
                    onClick={this.handleHorizontalShrinkMap}
                  >
                    <Icon>keyboard_arrow_right</Icon>
                  </IconButton>
                )}
              {state.map &&
                [
                  'fullHeightLeft',
                  'mapLeft',
                  'fullScreen',
                  'fullWidth',
                ].indexOf(state.map) < 0 && (
                  <IconButton
                    color='inherit'
                    onClick={this.handleHorizontalShrinkMap}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                )}
            </Toolbar>
          </AppBar>
          {Latitude && Longitude && (
            <div
              className={classes.mapWindow}
              style={{ maxHeight: 'calc(100vh + 64px)', overflowY: 'auto' }}
            >
              <div className={classNames('flex dark', classes.addressQuery)}>
                {!gpsHistory ? (
                  <TextField
                    id='mapAddress'
                    label='Search Address'
                    inputProps={{
                      style: {
                        color: '#fff',
                        marginLeft: -46,
                        paddingLeft: 56,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: '#fff',
                      },
                    }}
                    InputProps={{
                      className: classes.search,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icon style={{ color: '#fff' }}>search</Icon>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Icon
                            onClick={() => {
                              if (
                                searchAddress &&
                                searchLatitude &&
                                searchLongitude
                              ) {
                                this.setState(
                                  {
                                    Address: null,
                                    searchAddress: null,
                                    searchLatitude: null,
                                    searchLongitude: null,
                                  },
                                  () => this.props.setMapSearch(null),
                                )
                              } else {
                                this.setState({ advancedSearch: true })
                              }
                            }}
                            style={{ color: '#fff', cursor: 'pointer' }}
                          >
                            {searchAddress && searchLatitude && searchLongitude
                              ? 'close'
                              : 'filter_list'}
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    value={this.state.Address}
                    onChange={this.handleAddressChange}
                    variant='outlined'
                    fullWidth
                  />
                ) : (
                  <DatePicker
                    variant='inline'
                    inputVariant='outlined'
                    className='mb-12 w-full'
                    label='GPS History'
                    format='MM/DD/YYYY'
                    value={gpsDate}
                    showTodayButton
                    autoOk={true}
                    InputLabelProps={{
                      style: {
                        color: '#fff',
                      },
                    }}
                    InputProps={{
                      style: { color: '#fff' },
                      className: classes.search,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icon style={{ color: '#fff' }}>today</Icon>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Icon
                            onClick={() =>
                              this.setState({
                                gpsHistory: null,
                                selectedUser: null,
                              })
                            }
                            style={{ color: '#fff', cursor: 'pointer' }}
                          >
                            close
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={e =>
                      this.setState({ gpsDate: e.format('MM/DD/YYYY') }, () =>
                        this.getGPSHistory(gpsHistory.user),
                      )
                    }
                  />
                )}
              </div>
              {1 === 1 && (
                <div
                  className={classNames(
                    'min-h-128 dark absolute pin-l pin-r pin-t rounded z-10 mx-8 p-8',
                  )}
                  style={{
                    backgroundColor: '#3f3f3f',
                    top: 'calc(env(safe-area-inset-top) + 74px)',
                    boxShadow: '2px 2px 6px 2px #333',
                    border: '1px solid #5f5f5f',
                    display: !advancedSearch ? 'none' : undefined,
                  }}
                >
                  <div className='w-full justify-between flex mb-12'>
                    <Typography className='text-white font-bold text-12'>
                      <Icon className='text-white text-16 mr-4 align-middle mb-4'>
                        filter_list
                      </Icon>
                      Map Filters
                    </Typography>
                    <Icon
                      onClick={() => this.setState({ advancedSearch: false })}
                      className='text-white text-16 cursor-pointer mt-2'
                    >
                      close
                    </Icon>
                  </div>
                  <div className='w-full'>
                    {/* <TextField
                                            className={classNames("mb-12 text-white")}
                                            inputProps={
                                                {
                                                    style: {
                                                        color: '#fff',
                                                    }
                                                }
                                            }
                                            selectProps={
                                                {
                                                    style: {
                                                        color: '#fff',
                                                    }
                                                }
                                            }
                                            InputLabelProps={
                                                {
                                                    style: {
                                                        color: '#fff',
                                                    }
                                                }
                                            }
                                            InputProps={{
                                                className: classNames(classes.search, "text-white"),
                                            }}
                                            label="Filter Type"
                                            id="filterType"
                                            name="filterType"
                                            value={this.state.filterType || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="D">
                                                Dispatch Board
                                            </MenuItem>
                                            <MenuItem value="C">
                                                Custom Filters
                                            </MenuItem>
                                        </TextField> */}
                    {canDispatch && this.state.filterType === 'D' && (
                      <React.Fragment>
                        <TextField
                          className={classNames('mb-12 text-white')}
                          inputProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          selectProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          InputProps={{
                            className: classNames(classes.search, 'text-white'),
                          }}
                          label={
                            !this.state.selectedBoard
                              ? 'Select Dispatch Board'
                              : 'Dispatch Board'
                          }
                          id='selectedBoard'
                          name='selectedBoard'
                          value={this.state.selectedBoard || 'default'}
                          onChange={e =>
                            this.setState({
                              selectedBoard: e.target.value,
                              expanded: [],
                            })
                          }
                          variant='outlined'
                          fullWidth
                          select
                        >
                          <MenuItem value='default'>None (Default)</MenuItem>
                          {boards.map((board, i) => {
                            return (
                              <MenuItem key={i} value={board.Board}>
                                {board.Name}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                        <div className='w-full mb-12'>
                          <div className=''>
                            <label
                              className='text-12 p-4 px-6 ml-8'
                              style={{ background: '#3f3f3f', color: '#fff' }}
                            >
                              Work Orders
                            </label>
                            <div
                              className='rounded-4 p-12 mb-12 min-h-64'
                              style={{
                                border: '1px solid lightgrey',
                                marginTop: -11,
                              }}
                            >
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e => this.toggleList('Unassigned')}
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative text-white'
                                  >
                                    Unassigned
                                    {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                                    {(loading || unassigned.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-6'>
                                        refresh
                                      </Icon>
                                    ) : unassigned.length > 0 ? (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatar,
                                              'ml-6',
                                            )}
                                          >
                                            {unassigned.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    ) : (
                                      ''
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={
                                        expanded.indexOf('Unassigned') > -1
                                      }
                                      name='Unassigned'
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e => this.toggleList('Agreements')}
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                                  >
                                    <div className='truncate max-w-2/3 text-white'>
                                      Unassigned Agreements
                                    </div>
                                    {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                                    {(loading || agreements.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-4 text-white'>
                                        refresh
                                      </Icon>
                                    ) : agreements.length > 0 ? (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatar,
                                              'ml-6',
                                            )}
                                          >
                                            {agreements.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    ) : (
                                      ''
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={
                                        expanded.indexOf('Agreements') > -1
                                      }
                                      name='Agreements'
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                              {!board &&
                                folders.map((folder, index) => {
                                  let filtered = unassigned
                                  const criteria = JSON.parse(folder.Options)
                                  Object.keys(criteria).map(paramKey => {
                                    const params = criteria[paramKey]
                                    if (params.length > 0) {
                                      const list = woCriteriaLists[paramKey]
                                      filtered = _.filter(filtered, o => {
                                        return _.filter(
                                          o.Data.Scopes,
                                          s => params.indexOf(s[list.key]) > -1,
                                        ).length
                                      })
                                    }
                                  })
                                  if (
                                    expanded.indexOf(
                                      `folder-${folder.Folder}`,
                                    ) > -1
                                  ) {
                                    filtered.map(wo => {
                                      if (
                                        _.findIndex(filteredWOs, {
                                          WorkOrder: wo.WorkOrder,
                                        }) < 0
                                      )
                                        filteredWOs.push(wo)
                                    })
                                  }
                                  return (
                                    <div key={index}>
                                      <div className='w-full'>
                                        <Typography
                                          onClick={e =>
                                            this.toggleList(
                                              `folder-${folder.Folder}`,
                                            )
                                          }
                                          variant='h6'
                                          style={{ alignItems: 'center' }}
                                          className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                                        >
                                          <div className='truncate max-w-2/3 text-white'>
                                            {folder.Name}
                                          </div>
                                          {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                                          {(loading || filtered.length > 0) &&
                                          loading ? (
                                            <Icon className='text-20 spin ml-6'>
                                              refresh
                                            </Icon>
                                          ) : filtered.length > 0 ? (
                                            <FuseAnimate
                                              animation='transition.expandIn'
                                              delay={300}
                                            >
                                              {
                                                <Avatar
                                                  className={classNames(
                                                    classes.woAvatar,
                                                    'ml-6',
                                                  )}
                                                >
                                                  {filtered.length}
                                                </Avatar>
                                              }
                                            </FuseAnimate>
                                          ) : (
                                            ''
                                          )}
                                          <Switch
                                            className='pin-r absolute'
                                            style={{ marginRight: -8 }}
                                            checked={
                                              expanded.indexOf(
                                                `folder-${folder.Folder}`,
                                              ) > -1
                                            }
                                            name={`folder-${folder.Folder}`}
                                            color='secondary'
                                          />
                                        </Typography>
                                      </div>
                                      <Divider className='mt-12' />
                                    </div>
                                  )
                                })}
                              {options &&
                                options.folders.map((Folder, index) => {
                                  const folder = _.find(folders, { Co, Folder })
                                  if (folder) {
                                    let filtered = unassigned
                                    const criteria = JSON.parse(folder.Options)
                                    Object.keys(criteria).map(paramKey => {
                                      const params = criteria[paramKey]
                                      if (params.length > 0) {
                                        const list = woCriteriaLists[paramKey]
                                        filtered = _.filter(filtered, o => {
                                          return _.filter(
                                            o.Data.Scopes,
                                            s =>
                                              params.indexOf(s[list.key]) > -1,
                                          ).length
                                        })
                                      }
                                    })
                                    if (
                                      expanded.indexOf(
                                        `folder-${folder.Folder}`,
                                      ) > -1
                                    ) {
                                      filtered.map(wo => {
                                        if (
                                          _.findIndex(filteredWOs, {
                                            WorkOrder: wo.WorkOrder,
                                          }) < 0
                                        )
                                          filteredWOs.push(wo)
                                      })
                                    }
                                    return (
                                      <div key={index}>
                                        <div className='w-full'>
                                          <Typography
                                            onClick={e =>
                                              this.toggleList(
                                                `folder-${folder.Folder}`,
                                              )
                                            }
                                            variant='h6'
                                            style={{ alignItems: 'center' }}
                                            className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                                          >
                                            <div className='truncate max-w-2/3 text-white'>
                                              {folder.Name}
                                            </div>
                                            {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                                            {(loading || filtered.length > 0) &&
                                            loading ? (
                                              <Icon className='text-20 spin ml-6'>
                                                refresh
                                              </Icon>
                                            ) : filtered.length > 0 ? (
                                              <FuseAnimate
                                                animation='transition.expandIn'
                                                delay={300}
                                              >
                                                {
                                                  <Avatar
                                                    className={classNames(
                                                      classes.woAvatar,
                                                      ' ml-6',
                                                    )}
                                                  >
                                                    {filtered.length}
                                                  </Avatar>
                                                }
                                              </FuseAnimate>
                                            ) : (
                                              ''
                                            )}
                                            <Switch
                                              className='pin-r absolute'
                                              style={{ marginRight: -8 }}
                                              checked={
                                                expanded.indexOf(
                                                  `folder-${folder.Folder}`,
                                                ) > -1
                                              }
                                              name={`folder-${folder.Folder}`}
                                              color='secondary'
                                            />
                                          </Typography>
                                        </div>
                                        <Divider className='mt-12' />
                                      </div>
                                    )
                                  }
                                })}
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e => this.toggleList('OnHold')}
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative text-white'
                                  >
                                    On Hold
                                    {(loading || onHold.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-6'>
                                        refresh
                                      </Icon>
                                    ) : onHold.length > 0 ? (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatarGrey,
                                              ' ml-6',
                                            )}
                                          >
                                            {onHold.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    ) : (
                                      ''
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={expanded.indexOf('OnHold') > -1}
                                      name='OnHold'
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e => this.toggleList('Scheduled')}
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative text-white'
                                  >
                                    Scheduled
                                    {(loading || assigned.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-6'>
                                        refresh
                                      </Icon>
                                    ) : (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatarGrey,
                                              ' ml-6',
                                            )}
                                          >
                                            {assigned.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={
                                        expanded.indexOf('Scheduled') > -1
                                      }
                                      name='Scheduled'
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e => this.toggleList('Ongoing')}
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative text-white'
                                  >
                                    Ongoing
                                    {(loading || ongoing.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-6'>
                                        refresh
                                      </Icon>
                                    ) : ongoing.length > 0 ? (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatarGrey,
                                              ' ml-6',
                                            )}
                                          >
                                            {ongoing.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    ) : (
                                      ''
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={expanded.indexOf('Ongoing') > -1}
                                      name='Ongoing'
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e =>
                                      this.toggleList('DateException')
                                    }
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative text-white'
                                  >
                                    Date Exception
                                    {(loading || dateException.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-6'>
                                        refresh
                                      </Icon>
                                    ) : dateException.length > 0 ? (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatarGrey,
                                              ' ml-6',
                                            )}
                                          >
                                            {dateException.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    ) : (
                                      ''
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={
                                        expanded.indexOf('DateException') > -1
                                      }
                                      name='DateException'
                                      onChange={e =>
                                        this.toggleList('DateException')
                                      }
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                              <div>
                                <div className='w-full'>
                                  <Typography
                                    onClick={e => this.toggleList('Canceled')}
                                    variant='h6'
                                    style={{ alignItems: 'center' }}
                                    className='flex text-16 mt-12 ml-8 cursor-pointer relative text-white'
                                  >
                                    Canceled
                                    {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                                    {(loading || canceled.length > 0) &&
                                    loading ? (
                                      <Icon className='text-20 spin ml-6'>
                                        refresh
                                      </Icon>
                                    ) : canceled.length > 0 ? (
                                      <FuseAnimate
                                        animation='transition.expandIn'
                                        delay={300}
                                      >
                                        {
                                          <Avatar
                                            className={classNames(
                                              classes.woAvatarGrey,
                                              ' ml-6',
                                            )}
                                          >
                                            {canceled.length}
                                          </Avatar>
                                        }
                                      </FuseAnimate>
                                    ) : (
                                      ''
                                    )}
                                    <Switch
                                      className='pin-r absolute'
                                      style={{ marginRight: -8 }}
                                      checked={
                                        expanded.indexOf('Canceled') > -1
                                      }
                                      name='Canceled'
                                      color='secondary'
                                    />
                                  </Typography>
                                </div>
                                <Divider className='mt-12' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <Autocomplete
                      className={classNames('mb-12 text-white')}
                      inputProps={{
                        style: {
                          color: '#fff',
                        },
                      }}
                      selectProps={{
                        style: {
                          color: '#fff',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: '#fff',
                        },
                      }}
                      InputProps={{
                        className: classes.search,
                      }}
                      title='Locate User'
                      options={[
                        { UserName: null },
                        ..._.filter(users, u => u.ActiveYN === 'Y'),
                      ]}
                      menuItemComponent={value => {
                        if (value.UserName) {
                          return (
                            <MenuItem
                              disabled={!value.Data || !value.Data.Location}
                              value={value.UserName}
                            >
                              <div className='flex'>
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  classes={{ root: classes.gpsAvatarRoot }}
                                  className={classes.gpsAvatar}
                                  alt={value.UserName}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? getProfileImage(
                                          `Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                        )
                                      : userAvatar
                                  }
                                />
                                {`${value.FirstName} ${value.LastName}`}
                              </div>
                            </MenuItem>
                          )
                        } else {
                          return (
                            <MenuItem value={value.UserName}>
                              <div className='flex'>All</div>
                            </MenuItem>
                          )
                        }
                      }}
                      value={this.state.selectedUser || ''}
                      portal={true}
                      onSelect={option =>
                        this.setState({
                          ...this.state,
                          selectedUser: option.UserName,
                        })
                      }
                      // required
                    />
                    <Button
                      color='primary'
                      variant='contained'
                      className='w-full mb-12'
                      onClick={() => this.setState({ advancedSearch: false })}
                    >
                      <Icon className=' mr-4'>check_circle_outline</Icon>Done
                    </Button>
                    {this.state.filterType === 'C' && (
                      <React.Fragment>
                        <TextField
                          className={classNames('mb-12 text-white')}
                          inputProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          selectProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          InputProps={{
                            className: classNames(classes.search, 'text-white'),
                          }}
                          label='Technician Criteria'
                          id='criteriaType'
                          name='criteriaType'
                          value={this.state.criteriaType || ''}
                          onChange={this.handleChange}
                          variant='outlined'
                          fullWidth
                          select
                        >
                          <MenuItem value={null}>None</MenuItem>
                          <MenuItem value='businessUnits'>
                            Business Units
                          </MenuItem>
                          <MenuItem value='divisions'>Divisions</MenuItem>
                          <MenuItem value='departments'>Departments</MenuItem>
                          <MenuItem value='teams'>Teams</MenuItem>
                          <MenuItem value='technicians'>Technicians</MenuItem>
                        </TextField>
                        {criteriaList && (
                          <div className='w-full'>
                            <Autocomplete
                              className={classNames('mb-12 text-white')}
                              inputProps={{
                                style: {
                                  color: '#fff',
                                },
                              }}
                              selectProps={{
                                style: {
                                  color: '#fff',
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: '#fff',
                                },
                              }}
                              InputProps={{
                                className: classNames(
                                  classes.search,
                                  'text-white',
                                ),
                              }}
                              title={criteriaList.label}
                              options={_.filter(
                                data[criteriaType],
                                o =>
                                  criteria[criteriaType].indexOf(
                                    o[criteriaList.key],
                                  ) < 0,
                              )}
                              menuItemComponent={(value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={value[criteriaList.key]}
                                  >
                                    {criteriaList.format.map(prop => {
                                      return `${value[prop]} `
                                    })}
                                  </MenuItem>
                                )
                              }}
                              portal={true}
                              value={this.state[criteriaList.key] || ''}
                              onSelect={option =>
                                this.setState(
                                  _.set(
                                    { ...this.state },
                                    criteriaList.key,
                                    option[criteriaList.key],
                                  ),
                                )
                              }
                            />
                            <Button
                              className={classNames(
                                classes.formControl,
                                'w-full mb-12',
                              )}
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                criteria[criteriaType].push(
                                  this.state[criteriaList.key],
                                )
                                this.setState(
                                  _.set(
                                    { ...this.state, criteria },
                                    criteriaList.key,
                                    null,
                                  ),
                                )
                              }}
                              disabled={!this.state[criteriaList.key]}
                            >
                              Add {criteriaList.label}
                            </Button>
                          </div>
                        )}
                        {criteria &&
                          _.filter(criteria, o => o.length > 0).length > 0 && (
                            <div className='w-full mb-24'>
                              <div className=''>
                                <label
                                  className='text-12 p-4 px-6 ml-8'
                                  style={{
                                    background: '#3f3f3f',
                                    color: '#fff',
                                  }}
                                >
                                  Criteria
                                </label>
                                <div
                                  className='rounded-4 p-12 mb-12 min-h-256'
                                  style={{
                                    border: '1px solid lightgrey',
                                    marginTop: -11,
                                  }}
                                >
                                  {Object.keys(criteria).map((key, index) => {
                                    const value = criteria[key]
                                    if (value.length > 0) {
                                      const list = techCriteriaLists[key]
                                      return (
                                        <div className='w-full'>
                                          <Typography
                                            variant='h6'
                                            className='mb-8 text-12 font-bold w-full text-white'
                                            style={{
                                              borderBottom:
                                                '1px solid lightgrey',
                                            }}
                                          >
                                            <Icon
                                              className='mr-4 text-16 align-middle'
                                              style={{ marginBottom: 6 }}
                                            >
                                              {list.icon}
                                            </Icon>
                                            {list.listName}
                                          </Typography>
                                          <div className='flex-wrap'>
                                            {value.map((entry, index) => {
                                              const obj = _.find(
                                                data[key],
                                                o => o[list.key] === entry,
                                              )
                                              if (obj) {
                                                return (
                                                  <Chip
                                                    classes={{
                                                      deleteIcon: 'h-20',
                                                    }}
                                                    className='mb-8 mr-4 h-24'
                                                    color='primary'
                                                    onDelete={() => {
                                                      criteria[key].splice(
                                                        value.indexOf(entry),
                                                        1,
                                                      )
                                                      this.setState({
                                                        ...this.state,
                                                        criteria,
                                                      })
                                                    }}
                                                    label={list.format.map(
                                                      prop => {
                                                        return `${obj[prop]} `
                                                      },
                                                    )}
                                                  />
                                                )
                                              } else {
                                                return (
                                                  <Chip
                                                    classes={{
                                                      deleteIcon: 'h-20',
                                                    }}
                                                    className='mb-8 mr-4 h-24'
                                                    color='primary'
                                                    onDelete={() => {
                                                      criteria[key].splice(
                                                        value.indexOf(entry),
                                                        1,
                                                      )
                                                      this.setState({
                                                        ...this.state,
                                                        criteria,
                                                      })
                                                    }}
                                                    label={'Not Found'}
                                                  />
                                                )
                                              }
                                            })}
                                          </div>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        <TextField
                          className={classNames('mb-12 text-white')}
                          inputProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          selectProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: '#fff',
                            },
                          }}
                          InputProps={{
                            className: classNames(classes.search, 'text-white'),
                          }}
                          label='Work Order Criteria'
                          id='woCriteriaType'
                          name='woCriteriaType'
                          value={this.state.woCriteriaType || ''}
                          onChange={this.handleChange}
                          variant='outlined'
                          fullWidth
                          select
                        >
                          <MenuItem value={null}>None</MenuItem>
                          <MenuItem value='businessUnits'>
                            Business Units
                          </MenuItem>
                          <MenuItem value='divisions'>Divisions</MenuItem>
                          <MenuItem value='departments'>Departments</MenuItem>
                          <MenuItem value='serviceTypes'>
                            Service Types
                          </MenuItem>
                          <MenuItem value='services'>Services</MenuItem>
                          <MenuItem value='priorities'>Priorities</MenuItem>
                          <MenuItem value='arrivalWindows'>
                            Arrival Windows
                          </MenuItem>
                        </TextField>
                        {woCriteriaList && (
                          <div className='w-full'>
                            <Autocomplete
                              className={classNames('mb-12 text-white')}
                              inputProps={{
                                style: {
                                  color: '#fff',
                                },
                              }}
                              selectProps={{
                                style: {
                                  color: '#fff',
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: '#fff',
                                },
                              }}
                              InputProps={{
                                className: classes.search,
                              }}
                              title={woCriteriaList.label}
                              options={_.filter(
                                data[woCriteriaType],
                                o =>
                                  woCriteria[woCriteriaType].indexOf(
                                    o[woCriteriaList.key],
                                  ) < 0,
                              )}
                              menuItemComponent={(value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={value[woCriteriaList.key]}
                                  >
                                    {value.Description}
                                  </MenuItem>
                                )
                              }}
                              portal={true}
                              value={this.state[woCriteriaList.key] || ''}
                              onSelect={option =>
                                this.setState(
                                  _.set(
                                    { ...this.state },
                                    woCriteriaList.key,
                                    option[woCriteriaList.key],
                                  ),
                                )
                              }
                            />
                            <Button
                              className={classNames(
                                classes.formControl,
                                'w-full text-white',
                              )}
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                woCriteria[woCriteriaType].push(
                                  this.state[woCriteriaList.key],
                                )
                                this.setState(
                                  _.set(
                                    { ...this.state, woCriteria },
                                    woCriteriaList.key,
                                    null,
                                  ),
                                )
                              }}
                              disabled={!this.state[woCriteriaList.key]}
                            >
                              Add {woCriteriaList.label}
                            </Button>
                          </div>
                        )}
                        {woCriteria &&
                          _.filter(woCriteria, o => o.length > 0).length >
                            0 && (
                            <div className='w-full'>
                              <div className=''>
                                <label
                                  className='text-12 p-4 px-6 ml-8'
                                  style={{
                                    background: '#3f3f3f',
                                    color: '#fff',
                                  }}
                                >
                                  Criteria
                                </label>
                                <div
                                  className='rounded-4 p-12 mb-12 min-h-256'
                                  style={{
                                    border: '1px solid lightgrey',
                                    marginTop: -11,
                                  }}
                                >
                                  {Object.keys(woCriteria).map((key, index) => {
                                    const value = woCriteria[key]
                                    if (value.length > 0) {
                                      const list = woCriteriaLists[key]
                                      return (
                                        <div className='w-full'>
                                          <Typography
                                            variant='h6'
                                            className='mb-8 text-12 font-bold w-full text-white'
                                            style={{
                                              borderBottom:
                                                '1px solid lightgrey',
                                            }}
                                          >
                                            <Icon
                                              className='mr-4 text-16 align-middle'
                                              style={{ marginBottom: 6 }}
                                            >
                                              {list.icon}
                                            </Icon>
                                            {list.listName}
                                          </Typography>
                                          <div className='flex-wrap'>
                                            {value.map((entry, index) => {
                                              const obj = _.find(
                                                data[key],
                                                o => o[list.key] === entry,
                                              )
                                              return (
                                                <Chip
                                                  classes={{
                                                    deleteIcon: 'h-20',
                                                  }}
                                                  className='mb-8 mr-4 h-24'
                                                  color='primary'
                                                  onDelete={() => {
                                                    woCriteria[key].splice(
                                                      value.indexOf(entry),
                                                      1,
                                                    )
                                                    this.setState({
                                                      ...this.state,
                                                      woCriteria,
                                                    })
                                                  }}
                                                  label={obj.Description}
                                                />
                                              )
                                            })}
                                          </div>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
              {anchorUser && anchorEl && (
                <Menu
                  id='user-actions-menu'
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl && anchorUser)}
                  onClose={() =>
                    this.setState({ anchorEl: null, anchorUser: null })
                  }
                >
                  {[anchorUser].map(usr => {
                    const avatar = 'assets/images/avatars/profile.jpg'
                    const favatar = 'assets/images/avatars/profile_f.jpg'
                    return (
                      <MenuItem className='font-bold mb-6'>
                        <Avatar
                          src={
                            usr.Data && usr.Data.Avatar
                              ? getProfileImage(
                                  `ID=${usr.Data.Avatar}&Thumb=true`,
                                )
                              : usr.Gender === 'F'
                                ? favatar
                                : avatar
                          }
                          className={classNames('w-24 h-24 mr-6')}
                        />
                        {usr.FirstName} {usr.LastName}
                      </MenuItem>
                    )
                  })}
                  <Divider />
                  {userAccessLevel &&
                    ['E', 'F'].indexOf(userAccessLevel.AccessLevel) > -1 && (
                      <MenuItem
                        onClick={e => {
                          const usr = _.cloneDeepWith(anchorUser)
                          this.setState(
                            { anchorEl: null, anchorUser: null },
                            () => {
                              history.push(
                                `/apps/users/${encodeURIComponent(
                                  usr.UserName,
                                )}`,
                              )
                              // openEditUserDialog(usr, true)
                            },
                          )
                        }}
                      >
                        <Icon className='mr-4'>account_circle</Icon>
                        View User Profile
                      </MenuItem>
                    )}
                  {techAccessLevel &&
                    ['E', 'F'].indexOf(techAccessLevel.AccessLevel) > -1 &&
                    [anchorUser].map(value => {
                      const emp = _.find(employees, {
                        UserName: value.UserName,
                      })
                      const tech = emp
                        ? _.find(technicians, { Employee: emp.Employee })
                        : null
                      if (tech) {
                        return (
                          <MenuItem
                            onClick={e => {
                              this.setState(
                                { anchorEl: null, anchorUser: null },
                                () => openEditTechnicianDialog(tech),
                              )
                            }}
                          >
                            <Icon className='mr-4'>perm_contact_calendar</Icon>
                            View Technician Profile
                          </MenuItem>
                        )
                      }
                    })}
                  {userAccessLevel &&
                    ['E', 'F'].indexOf(userAccessLevel.AccessLevel) > -1 && (
                      <MenuItem
                        onClick={e => {
                          const usr = _.cloneDeepWith(anchorUser)
                          this.setState({ setGPSDate: !setGPSDate })
                        }}
                      >
                        <Icon className='mr-4'>person_pin</Icon>
                        GPS History
                      </MenuItem>
                    )}
                  {setGPSDate && (
                    <div className='w-full px-16 pt-8'>
                      <DatePicker
                        variant='inline'
                        inputVariant='outlined'
                        className='mb-12 w-full'
                        label='Date'
                        format='MM/DD/YYYY'
                        value={gpsDate}
                        showTodayButton
                        autoOk={true}
                        onChange={e =>
                          this.setState({ gpsDate: e.format('MM/DD/YYYY') })
                        }
                      />
                      <Button
                        className='w-full'
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          const usr = _.cloneDeepWith(anchorUser)
                          this.setState(
                            {
                              anchorEl: null,
                              anchorUser: null,
                              setGPSDate: false,
                            },
                            () => this.getGPSHistory(usr.UserName),
                          )
                        }}
                      >
                        View GPS History
                      </Button>
                    </div>
                  )}
                  {[anchorUser].map(usr => {
                    return (
                      <React.Fragment>
                        <Divider />
                        <div className='w-full text-center text-10 font-bold mt-8'>
                          {moment
                            .utc(usr.Data.Location.TimeStamp)
                            .local()
                            .format('h:mm:ss A on M/D/YYYY')}
                        </div>
                      </React.Fragment>
                    )
                  })}
                </Menu>
              )}
              <div className={classes.mapCanvas}>
                <GoogleMap
                  options={this.getMapOptions}
                  // gestureHandling="cooperative"
                  bootstrapURLKeys={{
                    key: googleMapsApiKey,
                  }}
                  // draggable={!Boolean(selectedWO)}
                  // draggable={!this.state.disableMapDrag}
                  onDrag={() => {
                    if (!this.state.mapDragging) {
                      this.setState({ mapDragging: true })
                    }
                  }}
                  onDragEnd={() => this.setState({ mapDragging: false })}
                  defaultZoom={12}
                  defaultCenter={[Latitude, Longitude]}
                  center={
                    searchLatitude && searchLongitude
                      ? [searchLatitude, searchLongitude]
                      : selUser && selUser.Latitude && selUser.Longitude
                        ? [Number(selUser.Latitude), Number(selUser.Longitude)]
                        : [Latitude, Longitude]
                  }
                >
                  <Marker
                    key={0}
                    style={{
                      margin: 0,
                      padding: 0,
                      width: 32,
                      height: 32,
                      marginTop: -16,
                      marginLeft: -16,
                      cursor: 'pointer',
                    }}
                    icon='assets/images/logos/SP_Call_Center.png'
                    text='Your Location'
                    lat={Latitude}
                    lng={Longitude}
                  />
                  {state.map &&
                    searchAddress &&
                    searchLatitude &&
                    searchLongitude && (
                      <Marker
                        key={1}
                        style={{
                          margin: 0,
                          padding: 0,
                          width: 32,
                          height: 42,
                          marginTop: -42,
                          marginLeft: -16,
                          cursor: 'pointer',
                        }}
                        icon='assets/images/logos/SPMarker_Gradient.png'
                        text={
                          searchAddress
                            ? `Search: ${searchAddress}`
                            : searchAddress
                        }
                        lat={searchLatitude}
                        lng={searchLongitude}
                        tooltipOpen={true}
                      />
                    )}
                  {state.map &&
                    gpsHistory &&
                    gpsHistory.history &&
                    gpsHistory.history.map(loc => {
                      return (
                        <Marker
                          key={loc.ID}
                          // style={{ minWidth: 12, minHeight: 12, backgroundColor: '#4dbce9', boxShadow: '1px 1px 8px 4px rgba(50,50,50,.5)', borderRadius: 999, borderBottomLeftRadius: loc.Status === 'moving' ? 0 : 999, transform: loc.Status === 'moving' ? `rotate(${Math.round(loc.Heading) - 45}deg)` : undefined, cursor: 'pointer' }}
                          // icon="assets/images/logos/SPMarker_Gradient.png"
                          text={
                            <div>
                              <div className='font-bold capitalize'>
                                {loc.Status}
                              </div>
                              {loc.MPH ? (
                                <div className='font-bold'>{loc.MPH} mph</div>
                              ) : loc.Duration ? (
                                <div className='font-bold'>
                                  {loc.Duration > 60
                                    ? (loc.Duration / 60).toFixed(2)
                                    : loc.Duration}{' '}
                                  {loc.Duration > 60 ? 'min' : 'sec'}
                                </div>
                              ) : (
                                ''
                              )}
                              <div className=''>
                                {moment(loc.TimeStamp).format(
                                  'h:mm:ss A on M/D/YYYY',
                                )}
                              </div>
                            </div>
                          }
                          type='location'
                          heading={loc.Heading}
                          status={loc.Status}
                          lat={Number(loc.Latitude)}
                          lng={Number(loc.Longitude)}
                        />
                      )
                    })}
                  {state.map &&
                    filteredWOs.map(value => {
                      const { Site } = value.Data
                      if (
                        Site &&
                        Site.Latitude &&
                        Site.Longitude &&
                        (!selectedWO ||
                          selectedWO.WorkOrder !== value.WorkOrder)
                      ) {
                        return (
                          <WOMarker
                            workOrder={value}
                            key={value.WorkOrder}
                            heading={0}
                            style={{
                              margin: 0,
                              padding: 0,
                              width: 32,
                              height: 42,
                              marginTop: -42,
                              marginLeft: -16,
                              cursor: 'pointer',
                            }}
                            icon='assets/images/logos/SPMarker_Gradient.png'
                            text={Site.Description}
                            lat={Number(Site.Latitude)}
                            lng={Number(Site.Longitude)}
                            type='site'
                            badge={_.sumBy(trips, {
                              WorkOrder: value.WorkOrder,
                            })}
                            // onClick={() => this.props.setSelectedWO(value)}
                            // onOpen={() => this.setState({ disableMapDrag: true })}
                            mapDragging={this.state.mapDragging}
                            onClose={() =>
                              this.setState({ disableMapDrag: false }, () =>
                                this.props.setSelectedWO(null),
                              )
                            }
                            // onMouseEnter={() => this.setState({ disableMapDrag: true })}
                            // onMouseLeave={() => this.setState({ disableMapDrag: false })}
                            // onMouseDown={() => this.setState({ disableMapDrag: true })}
                            // onMouseUp={() => this.setState({ disableMapDrag: false })}
                            // onTouchStart={() => this.setState({ disableMapDrag: true })}
                            // onTouchEnd={() => this.setState({ disableMapDrag: false })}
                            // onScrollStart={() => this.setState({ disableMapDrag: true })}
                            // onScrollEnd={() => this.setState({ disableMapDrag: false })}
                          />
                        )
                      }
                    })}
                  {selectedWO && (
                    <WOMarker
                      workOrder={selectedWO}
                      key={selectedWO.WorkOrder}
                      heading={0}
                      style={{
                        margin: 0,
                        padding: 0,
                        width: 32,
                        height: 42,
                        marginTop: -42,
                        marginLeft: -16,
                        cursor: 'pointer',
                      }}
                      icon='assets/images/logos/SPMarker_Gradient.png'
                      text={selectedWO.Data.Site.Description}
                      lat={Number(selectedWO.Data.Site.Latitude)}
                      lng={Number(selectedWO.Data.Site.Longitude)}
                      type='site'
                      badge={_.sumBy(trips, {
                        WorkOrder: selectedWO.WorkOrder,
                      })}
                      // onClick={() => this.props.setSelectedWO(selectedWO)}
                      tooltipOpen={true}
                      mapDragging={this.state.mapDragging}
                      onClose={() =>
                        this.setState({ disableMapDrag: false }, () =>
                          this.props.setSelectedWO(null),
                        )
                      }
                      // onMouseEnter={() => this.setState({ disableMapDrag: true })}
                      // onMouseLeave={() => this.setState({ disableMapDrag: false })}
                      // onMouseDown={() => this.setState({ disableMapDrag: true })}
                      // onMouseUp={() => this.setState({ disableMapDrag: false })}
                      // onTouchStart={() => this.setState({ disableMapDrag: true })}
                      // onTouchEnd={() => this.setState({ disableMapDrag: false })}
                      // onScrollStart={() => this.setState({ disableMapDrag: true })}
                      // onScrollEnd={() => this.setState({ disableMapDrag: false })}
                    />
                  )}
                  {state.map &&
                    gps &&
                    gps &&
                    gps.map((value, index) => {
                      const {
                        UserName,
                        Latitude,
                        Longitude,
                        Status,
                        TimeStamp,
                        Heading,
                      } = value
                      const usr = _.find(users, { UserName, ActiveYN: 'Y' })
                      const avatar = 'assets/images/avatars/profile.jpg'
                      const favatar = 'assets/images/avatars/profile_f.jpg'
                      if (
                        usr &&
                        (!this.state.selectedUser ||
                          UserName === this.state.selectedUser)
                      ) {
                        const emp = _.find(employees, {
                          UserName: value.UserName,
                        })
                        const tech = emp
                          ? _.find(technicians, { Employee: emp.Employee })
                          : null
                        return (
                          <Marker
                            key={index}
                            // heading={Heading}
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: '50%',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              margin: 'auto',
                              cursor: 'pointer',
                              position: 'absolute',
                              transform: 'rotate(-45deg)',
                            }}
                            icon={
                              usr.Data && usr.Data.Avatar
                                ? getProfileImage(
                                    `ID=${usr.Data.Avatar}&Thumb=true`,
                                  )
                                : usr.Gender === 'F'
                                  ? favatar
                                  : avatar
                            }
                            text={
                              <div className='text-center'>
                                <b>{`${usr.FirstName} ${usr.LastName}`}</b>
                                <br />
                                {moment
                                  .utc(TimeStamp)
                                  .local()
                                  .format('h:mm:ss a on M/D/YYYY')}
                              </div>
                            }
                            lat={Number(Latitude)}
                            lng={Number(Longitude)}
                            f
                            type='person'
                            // badge={_.sumBy(trips, { Technician: value.Technician })}
                            onClick={e =>
                              this.setState({
                                anchorUser: usr,
                                anchorEl: e.target,
                              })
                            }
                          />
                        )
                      }
                    })}
                </GoogleMap>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
      getContacts: Actions.getContacts,
      openChatPanel: Actions.openChatPanel,
      closeChatPanel: Actions.closeChatPanel,
      openEditUserDialog,
      openEditTechnicianDialog,
      openEditWorkOrderDialog,
      openMap: Actions.openMap,
      closeMap: Actions.closeMap,
      resizeMap: Actions.resizeMap,
      toggleDialer: dialerActions.toggleDialer,
      initCall: dialerActions.initCall,
      setMapSearch: Actions.setMapSearch,
      setSelectedWO,
      showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({ chatPanel, spReducers, dispatchBoardApp, dialer }) {
  const {
    businessUnits,
    divisions,
    departments,
    serviceTypes,
    services,
    arrivalWindows,
    teams,
    technicians,
    workOrders,
    employees,
    boards,
    folders,
  } = spReducers
  return {
    Co: spReducers.companies.Co,
    contacts: spReducers.userProfiles.Users,
    selectedContactId: chatPanel.contacts.selectedContactId,
    state: chatPanel.state,
    dispatchBoard: dispatchBoardApp.dispatchBoard,
    workOrders: workOrders || [],
    dialer: dialer.sipPhone,
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    textMessages: spReducers.textMessages,
    employees,
    technicians,
    master: {
      businessUnits,
      divisions,
      departments,
      serviceTypes,
      services,
      arrivalWindows,
      teams,
      technicians,
    },
    sharedBoards: boards || [],
    customFolders: folders || [],
    selectedBoard: dispatchBoardApp.dispatchBoard.selectedBoard,
    securables: spReducers.userProfiles.User.Data.Securables,
    loading: dispatchBoardApp.dispatchBoard.loading,
    searchText: dispatchBoardApp.dispatchBoard.searchText,
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChatPanel),
)
