import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles/index';
import { Badge, Hidden, Icon, IconButton, Input, Paper, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FuseAnimate, FuseSelectedTheme } from '@fuse';
import _ from '@lodash';

const styles = theme => ({
    root: {}
});

class DispatchBoardHeader extends Component {
    state = {
        searchText: '',
        tentative: [],
        unassigned: [],
        agreements: []
    };

    componentDidMount = () => {
        const { searchText } = this.props;
        this.setState({ searchText }, this.getWOData);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { searchText, selectedBoard, customFolders, customBoards, workOrders } = this.props;
        if (searchText !== prevProps.searchText) {
            this.setState({ searchText }, this.getWOData);
        } else {
            if (!_.isEqual(workOrders, prevProps.workOrders) || !_.isEqual(selectedBoard, prevProps.selectedBoard) || !_.isEqual(customFolders, prevProps.customFolders) || !_.isEqual(customBoards, prevProps.customBoards)) {
                this.getWOData();
            }
        }
    };

    handleChange = (event) => {
        this.setState({ searchText: event.target.value }, this.handleSearch);

    };

    getWOData = () => {
        const { Co, date, user, customBoards, customFolders, selectedBoard, workOrders } = this.props;

        const data = workOrders || {};
        let agreements = _.filter(data, (o) => { return _.filter(o.Data.Scopes, (s) => { return s.Type === 'A' && s.Status !== 4 && /*new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') &&*/ s.Data.Assignments.length < 1 }).length > 0 });
        let unassigned = _.filter(data, (o) => { return _.filter(o.Data.Scopes, (s) => { return !s.LeadTechnician && s.Type !== 'A' && s.Status !== 4 && new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') && s.Data.Assignments.length < 1 }).length > 0 });
        let tentative = _.filter(data, (o) => { return _.filter(o.Data.Scopes, (s) => { return Boolean(s.LeadTechnician) && s.Type !== 'A' && s.Status !== 4 && new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') && s.Data.Assignments.length < 1 }).length > 0 });

        const criteriaLists = {
            businessUnits: {
                key: "BusinessUnit",
                format: ["Description"],
                label: "Business Unit",
                listName: "Business Units",
                icon: 'business_center'
            },
            divisions: {
                key: "Division",
                format: ["Description"],
                label: "Division",
                listName: "Divisions",
                icon: 'location_city'
            },
            departments: {
                key: "Department",
                format: ["Description"],
                label: "Department",
                listName: "Departments",
                icon: 'business'
            },
            serviceTypes: {
                key: "ServiceType",
                format: ["Description"],
                label: "Service Type",
                listName: "Service Types",
                icon: 'settings'
            },
            services: {
                key: "Service",
                format: ["Service", " - ", "Description"],
                label: "Service",
                listName: "Services",
                icon: 'build'
            },
            priorities: {
                key: "Priority",
                format: ["Description"],
                label: "Priority",
                listName: "Priorities",
                icon: 'flag'
            },
            arrivalWindows: {
                key: "ArrivalWindow",
                format: ["Description"],
                label: "Arrival Window",
                listName: "Arrival Windows",
                icon: 'access_time'
            },
        };

        let boards = user && user.Data && user.Data.DispatchBoards ? _.filter(user.Data.DispatchBoards, (o) => o.SharedYN !== 'Y') : [];
        boards = [...customBoards, ...boards];

        let folders = user && user.Data && user.Data.DispatchFolders ? _.filter(user.Data.DispatchFolders, (o) => o.SharedYN !== 'Y') : [];
        folders = [...customFolders, ...folders];

        const board = _.find(boards, { Co, Board: selectedBoard });
        let options;

        if (board) {
            options = JSON.parse(board.Options);
            Object.keys(options.criteria).map((paramKey) => {
                const params = options.criteria[paramKey];
                if (params.length > 0) {
                    const list = criteriaLists[paramKey];
                    if (list) {
                        agreements = _.filter(agreements, (o) => { return _.filter(o.Data.Scopes, (s) => params.indexOf(s[list.key]) > -1 && s.Status !== 4 && s.Data.Assignments.length < 1).length });
                        unassigned = _.filter(unassigned, (o) => { return _.filter(o.Data.Scopes, (s) => params.indexOf(s[list.key]) > -1 && s.Status !== 4 && new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') && s.Data.Assignments.length < 1).length });
                        tentative = _.filter(tentative, (o) => { return _.filter(o.Data.Scopes, (s) => params.indexOf(s[list.key]) > -1 && s.Status !== 4 && new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') && s.Data.Assignments.length < 1).length });
                    }
                }
            });
        }

        this.setState({
            agreements,
            unassigned,
            tentative,
        });
    }

    handleSearch = _.debounce(() => {
        const { searchText } = this.state;
        this.props.setSearchText({ target: { value: searchText } });
    }, 750);

    render() {
        const { classes, setSearchText, pageLayout, workOrders, date, } = this.props;
        const { searchText, agreements, unassigned, tentative } = this.state;

        const woCount = tentative.length + unassigned.length + agreements.length;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex flex-1">
                    <Paper className="flex items-center w-full h-48 sm:h-56 p-16 pl-4 md:pl-16 rounded-8 " elevation={1}>
                        <Hidden xlUp>
                            <IconButton
                                onClick={(ev) => pageLayout().toggleLeftSidebar()}
                                aria-label="open left sidebar"
                            >
                                <Badge color="error" badgeContent={woCount}>
                                    <Icon>assignment</Icon>
                                </Badge>
                            </IconButton>
                        </Hidden>

                        <Icon color="action">search</Icon>

                        <Input
                            placeholder="Search Dispatch Board"
                            className="pl-16"
                            disableUnderline
                            fullWidth
                            value={searchText}
                            inputProps={{
                                'aria-label': 'Search'
                            }}
                            onChange={this.handleChange}
                        />
                        <div className="flex flex-shrink items-center w-1/5">

                        </div>
                    </Paper>
                </div>
            </MuiThemeProvider>
        )
            ;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({ dispatchBoardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        date: dispatchBoardApp.dispatchBoard.date,
        searchText: dispatchBoardApp.dispatchBoard.searchText,
        workOrders: dispatchBoardApp.dispatchBoard.workOrderList,
        selectedBoard: dispatchBoardApp.dispatchBoard.selectedBoard,
        customBoards: spReducers.boards,
        customFolders: spReducers.folders,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(DispatchBoardHeader));
