import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardContent, CardActions, Divider, Fab, Icon, IconButton, LinearProgress, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup, FuseUtils } from '@fuse';
import AcademyClassCard from './AcademyClassCard';
import _ from '@lodash';
import { actions } from 'react-table';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
});

class AcademyLeftSidebarContent extends Component {

    state = {
        classList: [],
    }

    componentDidMount() {
        const { classList } = this.props;
        this.setState({ ...this.state, classList });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { classList } = this.props;
        if (!_.isEqual(classList, this.state.classList)) {
            this.setState({ ...this.state, classList });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    addClass = () => {
        const { category, Co } = this.props;
        const { classList, } = this.state;
        const seq = _.maxBy(classList, 'Class');
        const newClass = {
            ID: null,
            Co,
            Class: seq ? seq.Class + 1 : 1,
            Name: 'New Class',
            Description: 'New Class',
            Category: category ? category.Category : 1,
            Data: {
                Steps: [],
                ErrMsg: null,
            },
            editing: true,
        };
        classList.push(newClass);
        this.setState({ ...this.state, classList });
        this.props.setClasses([...classList]);
    }

    removeClass = (value) => {
        const { classList, } = this.state;
        const index = _.findIndex(classList, { Class: value.Class });
        classList.splice(index, 1);
        this.setState({ ...this.state, classList });
        this.props.setClasses([...classList]);
    }

    render() {
        const { classes, user, categories, category, searchText, securables } = this.props;
        const { classList } = this.state;
        const catNum = category ? category.Category : false;
        const accessLevel = _.find(securables, { Securable: "learning" });
        const data = this.getFilteredArray(classList, searchText);
        return (
            <FuseAnimate animation="transition.slideRightBigIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><Icon color="primary" className="text-32 mr-8">school</Icon>Learning Center</Typography>
                    <Divider />
                    <div className="flex flex-wrap">
                        {
                            [...data].map((value, index) => {
                                const category = _.find(categories, { Category: value.Category });
                                return (
                                    (!catNum && catNum !== 0) || catNum === value.Category ? <AcademyClassCard editable={value.Category} onDelete={() => this.removeClass(value)} value={value} key={index} category={category} /> : ''
                                )
                            })
                        }
                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <Typography onClick={this.addClass} variant="subtitle2" style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>}New Class</Typography>
                        }
                    </div>
                </div>
            </FuseAnimate>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setClasses: Actions.setClasses,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, academyApp }) {
    return {
        Co: spReducers.companies.Co,
        user: contactsApp.user,
        classList: spReducers.classes.classes,
        categories: spReducers.classes.categories,
        category: academyApp.academy.category,
        searchText: academyApp.academy.searchText,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademyLeftSidebarContent)));
