import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { Avatar, Button, Badge, Icon, IconButton, Dialog, ListItemIcon, ListItemText, Popover, MenuItem, Tooltip, Typography, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import * as quickPanelActions from 'main/quickPanel/store/actions';
import * as dialerActions from 'main/dialer/store/actions';
import * as authActions from 'auth/store/actions';
import * as chatPanelActions from 'main/chatPanel/store/actions';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { FuseShortcuts, FuseAnimate } from '@fuse';

// Dialogs
import CustomerDNUDialog from 'main/content/apps/customers/CustomerDNUDialog';
import CustomersDialog from 'main/content/apps/customers/CustomersDialog';
import CustomerSitesDialog from 'main/content/apps/customer-sites/CustomerSitesDialog';
import SupportRequestDialog from './content/apps/support/requests/board/dialogs/card/SupportRequestDialog';
import TimecardDialog from './content/apps/timecards/TimecardDialog';
import WorkOrdersDialog from 'main/content/apps/work-orders/WorkOrdersDialog';
import WorkOrderScopesDialog from 'main/content/apps/work-order-scopes/WorkOrderScopesDialog';
import TripDialog from 'main/content/apps/trips/TripDialog';
import TodoDialog from 'main/content/apps/todo/TodoDialog';
import BoardCardDialog from 'main/content/apps/scrumboard/board/dialogs/card/BoardCardDialog';
import WorkOrderUsageDialog from 'main/content/apps/work-order-usages/WorkOrderUsageDialog';
import TechniciansDialog from 'main/content/apps/technicians/TechniciansDialog';
import TeamsDialog from 'main/content/apps/teams/TeamsDialog';
import TechUADialog from 'main/content/apps/tech-ua/TechUADialog';
import InvoiceDialog from 'main/content/apps/invoices/InvoiceDialog';
import AgreementDialog from 'main/content/apps/agreements/AgreementDialog';
import FormDialog from 'main/content/apps/forms/FormDialog';
import PurchaseOrderDialog from 'main/content/apps/purchase-orders/PurchaseOrderDialog';
import ChecklistDialog from 'main/content/apps/checklists/ChecklistDialog';
import EstimateDialog from 'main/content/apps/estimates/EstimateDialog';
import CatalogItemDialog from 'main/content/apps/catalog/CatalogItemDialog';
import CatalogDialog from 'main/content/apps/catalog/CatalogDialog';
import MaterialDialog from 'main/content/apps/materials/MaterialDialog';
import CatalogOptionBoardDialog from 'main/content/apps/catalog/CatalogOptionBoardDialog';
import ServiceUnitDialog from 'main/content/apps/service-units/ServiceUnitDialog';
import UsersDialog from 'main/content/apps/users/UsersDialog';
import EventDialog from 'main/content/apps/calendar/EventDialog';
import VoicemailDialog from 'main/content/apps/voice-mailboxes/VoicemailDialog';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import SplitscreenDialogs from 'main/content/apps/splitscreen-dialogs/SplitscreenDialogs';
import IncomingCallDialog from 'main/content/apps/incoming-call/IncomingCallDialog';
import BusinessUnitDialog from 'main/content/apps/business-units/BusinessUnitDialog';
import CompanyDialog from 'main/content/apps/companies/CompanyDialog';
import DivisionDialog from 'main/content/apps/divisions/DivisionDialog';
import PurchaseOrderLineDialog from 'main/content/apps/purchase-order-lines/PurchaseOrderLineDialog';
import EmployeesDialog from 'main/content/apps/employees/EmployeesDialog';
import GpsInventoryTransferDialog from 'main/content/apps/gps-inventory-transfers/GpsInventoryTransferDialog';
import GpsInventoryTransfersListDialog from 'main/content/apps/gps-transfer-list/GpsInventoryTransfersListDialog';
import InventoryLocationDialog from 'main/content/apps/inventory-locations/InventoryLocationDialog';
import InventoryLocationMaterialDialog from 'main/content/apps/inventory-location-materials/InventoryLocationMaterialDialog';

import moment from 'moment';
import About from 'main/About';
import { Link } from 'react-router-dom';
import _ from '@lodash';
import { theme } from 'highcharts';
import getProfileImage from './functions/getProfileImageUrl';
// import ConferenceDialog from './content/apps/calendar/ConferenceDialog';
// import { stopSignalR } from '../store/actions/spConnection/sp-signalr.actions';

const sounds = require('store/sounds/sounds.json');

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        // paddingTop: 'env(safe-area-inset-top)'
    },
    separator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider
    },
    callKey: {
        margin: 10,
        backgroundColor: theme.palette.secondary.main,
        cursor: 'pointer',
        width: 48,
        height: 48,
        fontSize: 'x-large',
    },
    endCallKey: {
        margin: 10,
        backgroundColor: theme.palette.error[500],
        cursor: 'pointer',
        width: 48,
        height: 48,
        fontSize: 'x-large',
    },
    incomingCall: {
        width: 300,
        background: 'transparent',
        maxWidth: 300,
        minHeight: 300,
        boxShadow: 'none',
        color: '#fff',
        margin: 0,
        padding: 0
    },
    incomingCallRoot: {
        backgroundColor: 'rgba(0, 0, 0, .5)',
    },
    incomingUserAvatar: {
        width: 128,
        height: 128,
        marginLeft: 86
    },
    badge: {
        minHeight: 14,
        height: 9,
        minWidth: 14,
        fontSize: 9,
        top: 4,
        right: 2
    },
    notificationBadge: {
        top: 4,
        right: 4,
        fontSize: 10,
        padding: 4,
        height: 16,
        minWidth: 16,
    },
    status: {
        position: 'absolute',
        width: 12,
        height: 12,
        bottom: 10,
        left: 36,
        border: '2px solid ' + theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 10,

        '&.online': {
            backgroundColor: '#4CAF50'
        },

        // '&.do-not-disturb': {
        //     backgroundColor: '#F44336'
        // },

        '&.idle': {
            backgroundColor: '#FFC107'
        },

        '&.offline': {
            backgroundColor: '#9f9f9f'
        }
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    }
});

class DialogAudio extends Component {
    constructor(props) {
        super(props)
        this.audioRef = React.createRef()
    }

    componentDidMount() {
        this.audioRef.current.srcObject = this.props.stream
    }

    componentDidUpdate(prevProps, prevState) {
        const { calls } = this.props;
        if (this.audioRef.current.srcObject !== this.props.stream) {
            this.audioRef.current.srcObject = this.props.stream
        }

    }

    render() {
        return <audio ref={this.audioRef} id="dialogAudio" autoPlay="autoplay"></audio>
    }
}

class MainToolbar extends Component {
    state = {
        userMenu: null,
        sipPhone: null,
        sipSession: null,
        sipSessionActive: false,
        remoteStream: null,
        alerts: [],
    };

    userMenuClick = event => {
        this.setState({ userMenu: event.currentTarget });
    };

    userMenuClose = () => {
        this.setState({ userMenu: null });
    };

    componentDidMount() {
        const { sipPhone, sipSession, sipSessionActive, remoteStream, alerts } = this.props;

        this.setState({
            ...this.state,
            sipPhone,
            sipSession,
            sipSessionActive,
            remoteStream,
            alerts
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { sipPhone, sipSession, sipSessionActive, remoteStream, calls, user, alerts } = this.props;
        const newCall = _.find(calls, (o) => { return o.Status === "queued" && _.findIndex(prevProps.calls, { Sid: o.Sid }) < 0 });
        if (this.pop && newCall && user.DisableRingtoneYN !== "Y") {
            this.pop.play();
        }
        if (!_.isEqual(alerts, prevProps.alerts)) {
            this.setState({ alerts });
        }
        if (!_.isEqual(this.state.sipPhone, sipPhone) || !_.isEqual(this.state.sipSession, sipSession) || !_.isEqual(this.state.remoteStream, remoteStream) || !_.isEqual(this.state.sipSessionActive, sipSessionActive)) {
            this.setState({
                ...this.state,
                sipPhone,
                sipSession,
                sipSessionActive,
                remoteStream
            });
        }
    }

    handleCall = () => {
        const { sipSession } = this.props;

        if (sipSession) {
            this.props.answerCall(sipSession);
        }

    };

    handleEndCall = () => {
        const { sipSession } = this.props;
        if (sipSession) {
            this.props.endCall(sipSession);
        }
    };

    formatPhone(phone) {
        let formatted = phone.replace('+1', '');
        if (!isNaN(formatted)) {
            switch (formatted.length) {
                case 7:
                    {
                        formatted = `${formatted.substr(0, 3)}-${formatted.substr(3, 4)}`;
                    }
                    break;
                case 10:
                    {
                        formatted = `(${formatted.substr(0, 3)}) ${formatted.substr(3, 3)}-${formatted.substr(6, 4)}`;
                    }
                    break;
                case 11:
                    {
                        formatted = `(${formatted.substr(1, 3)}) ${formatted.substr(4, 3)}-${formatted.substr(7, 4)}`;
                    }
                    break;
            }
        }
        return formatted;
    }

    getMinutes = (date) => {
        var now = new Date();
        var dt = new Date(date || null);
        var ms = (now - dt);
        var mins = ms / 60000;
        return mins;
    }

    render() {
        const { classes, toggleQuickPanel, user, logout, openChatPanel, openMap, toggleDialer, connected, offline, refreshToken, users, version, device, chats, notifications, warnLive, testAppErrors, isSupportProvider } = this.props;
        const { userMenu, sipPhone, sipSession, sipSessionActive, remoteStream, alerts } = this.state;
        const os = device && (device.osName || device.os) && device.osVersion ? `${device.osName || device.os} ${device.osVersion}` : 'N/A';
        const avatar = 'assets/images/avatars/profile.jpg';
        const incomingCaller = sipSession ? _.find(users, (u) => { return u.UserName.split('@')[0] === sipSession.options.remoteCallerNumber }) : null;
        const unreadChats = _.sumBy(chats, (chat) => { return _.filter(chat.Messages, { To: user.UserName, Received: null }).length });
        const me = _.find(users, { UserName: user.UserName });
        const minutes = me && me.Data ? this.getMinutes(me.Data.LastLogin) : null;
        const status = me && me.Data ? (!me.Data.Status || minutes > 60 ? 0 : me.Data.Status) : null;
        const statuses = ["offline", "idle", "online"];
        const new_version = localStorage.getItem('sp-update-available');
        const toDos = _.orderBy(_.filter(this.props.toDos, (o) => o.CompletedYN !== "Y" && moment(o.StartDate || o.StartBy).toDate() <= moment().toDate()), ["DueDate", "DueBy"], ["asc", "asc"]);
        const events = _.orderBy(_.filter(this.props.events, (o) => moment(o.StartDate).toDate() <= moment().toDate() && moment(o.EndDate).toDate() >= moment().toDate()), ["StartDate", "EndDate"], ["asc", "asc"]);


        return (
            <div className={classNames(classes.root, "flex flex-row justify-end")}>
                <audio style={{ display: 'none' }} ref={(ref) => { this.pop = ref; }} src={sounds["call"]} />
                <CustomerDNUDialog />
                <CustomersDialog />
                <CustomerSitesDialog />
                <TechniciansDialog />
                <WorkOrdersDialog />
                <TimecardDialog />
                <WorkOrderScopesDialog />
                <TripDialog />
                <WorkOrderUsageDialog />
                <AgreementDialog />
                <InvoiceDialog />
                <TeamsDialog />
                <TechUADialog />
                <FormDialog />
                <ChecklistDialog />
                <EstimateDialog />
                <CatalogDialog />
                <CatalogItemDialog />
                <PurchaseOrderDialog />
                <MaterialDialog />
                <CatalogOptionBoardDialog />
                <SupportRequestDialog isSupportProvider={this.props.isSupportProvider} />
                <ServiceUnitDialog />
                <TodoDialog />
                <BoardCardDialog />
                <UsersDialog />
                <EventDialog />
                {/* <ConferenceDialog /> */}
                <VoicemailDialog />
                <About />
                <SplitscreenDialogs />
                <IncomingCallDialog />
                <BusinessUnitDialog />
                <CompanyDialog />
                <DivisionDialog />
                <PurchaseOrderLineDialog />
                <EmployeesDialog />
                <GpsInventoryTransferDialog />
                <GpsInventoryTransfersListDialog />
                <InventoryLocationDialog />
                <InventoryLocationMaterialDialog />
                {isSupportProvider && testAppErrors && this.doesntExist.toString()}

                {alerts.map((alert) => {
                    return (
                        <ConfirmationDialog
                            open={alert.open}
                            title={<div><Icon className="mr-6 align-middle mb-4" color={alert.iconColor || "primary"}>{alert.icon}</Icon>{alert.title}</div>}
                            content={alert.content ?
                                <div className="w-full min-w-320 max-w-320 mb-8">
                                    {alert.content}
                                </div> : ''
                            }
                            type={alert.type}
                            data={alert.data}
                            hideCancel={Boolean(alert.hideCancel)}
                            hideConfirm={Boolean(alert.hideConfirm)}
                            confirmText={alert.confirmText}
                            cancelText={alert.cancelText}
                            onConfirm={() => { if (alert.onConfirm) { alert.onConfirm(); }; this.props.removeAlert(alert); }}
                            onAction={(data) => { if (alert.onAction) { alert.onAction(data); }; this.props.removeAlert(alert); }}
                            onCancel={() => this.props.removeAlert(alert)}
                        />
                    );
                })
                }

                <Hidden smDown>
                    <div className="flex flex-1">
                        <FuseShortcuts />
                    </div>
                </Hidden>

                {!window.cordova && warnLive &&
                    <ConfirmationDialog
                        open={warnLive}
                        title={<div><Icon className="mr-6 align-middle mb-4" color="error">warning</Icon>WARNING</div>}
                        content={<div className="w-full pt-8 pb-8">{`You are logged into a Production Instance of Service Point Pro from within a Test or Training environment. This can potentially degrade your user experience, and might cause issues with software functionality. Would you like to be redirected to the appropriate url for this instance?`}</div>}
                        confirmText="Get me out of here!"
                        cancelText="Continue Anyway"
                        onCancel={() => this.props.warnLiveVersion(false)}
                        onConfirm={() => { window.location = 'https://live.servicepointpro.com'; }}
                    />
                }

                <div className="flex">
                    <FuseAnimate delay={300}>
                        <Button className="h-64" onClick={this.userMenuClick}>
                            {status > 0 &&
                                <div className={classNames(statuses[status], classes.status)} />
                            }
                            {new_version ?
                                <Badge color="error" classes={{ badge: classNames(classes.blackButton, "mt-8 mr-4 p-0") }} badgeContent={<Icon className="text-14">cloud_download</Icon>}>
                                    {user.Data.Avatar ?
                                        (
                                            <Avatar className="" alt="user photo" src={(user.Data && user.Data.Avatar && user.Data.Avatar.length > 0 ? getProfileImage(`ID=${user.Data.Avatar}`) : avatar)} />
                                        )
                                        :
                                        (
                                            <Avatar className="" src={avatar} />
                                        )
                                    }
                                </Badge> :
                                <React.Fragment>
                                    {user.Data.Avatar ?
                                        (
                                            <Avatar className="" alt="user photo" src={(user.Data && user.Data.Avatar && user.Data.Avatar.length > 0 ? getProfileImage(`ID=${user.Data.Avatar}`) : avatar)} />
                                        )
                                        :
                                        (
                                            <Avatar className="" src={avatar} />
                                        )
                                    }
                                </React.Fragment>
                            }

                            <div className="hidden md:flex flex-col ml-12 items-start">
                                <Typography component="span" className="normal-case font-600 flex">
                                    {user.FirstName && `${user.FirstName} ${user.LastName}`}
                                </Typography>
                                <Typography className="text-11 capitalize" color="textSecondary">
                                    {user.Title && user.Title}
                                </Typography>
                            </div>

                            <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
                        </Button>
                    </FuseAnimate>

                    <Popover
                        open={Boolean(userMenu)}
                        anchorEl={userMenu}
                        onClose={this.userMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        classes={{
                            paper: "py-8 min-w-160"
                        }}
                    >

                        <React.Fragment>

                            <MenuItem component={Link} to="/profile" onClick={this.userMenuClose}>
                                <ListItemIcon className="min-w-24 mr-8">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="My Profile" />
                            </MenuItem>

                            <MenuItem onClick={() => window.location.reload()}>
                                <ListItemIcon className="min-w-24 mr-8">
                                    <Icon>refresh</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="Reload" />
                            </MenuItem>

                            <MenuItem component={Link} to="/apps/support" onClick={this.userMenuClose}>
                                <ListItemIcon className="min-w-24 mr-8">
                                    <Icon>headset_mic</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="Support" />
                            </MenuItem>

                            <MenuItem onClick={() => { this.props.getAbout(true,false); this.userMenuClose(); }}>
                                <ListItemIcon className="min-w-24 mr-8">
                                    <Icon>info</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="About" />
                            </MenuItem>
                            <MenuItem
                                onClick={() => { this.props.getAbout(true,true); this.userMenuClose(); }}
                            >
                                <ListItemIcon className="min-w-24 mr-8">
                                    <Icon>notes</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="Release Notes--" />
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    logout();
                                    this.userMenuClose();
                                }}
                            >
                                <ListItemIcon className="min-w-24 mr-8">
                                    <Icon>exit_to_app</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="Logout" />
                            </MenuItem>
                           
                            <Typography onClick={() => { this.props.getReleaseNotes(version, version); this.userMenuClose(); }} className="text-center font-bold text-10 cursor-pointer" style={{ fontSize: 8 }}>&copy; Service Point Pro {new Date().getFullYear()} - v {version}</Typography>

                            {new_version &&
                                <MenuItem onClick={() => Promise.all([localStorage.removeItem('sp-update-available')]).then(() => window.location.reload())}>
                                    <Button variant="contained" className={classNames(classes.blackButton, "px-12 py-4 capitalize text-12")}>
                                        <Icon className="mr-8" color="secondary">cloud_download</Icon>Update Version
                                    </Button>
                                </MenuItem>
                            }

                        </React.Fragment>

                    </Popover>

                    <div className={classes.separator} />

                    {
                        offline ?
                            <IconButton className="w-64 h-64">
                                <Icon>cloud_off</Icon>
                            </IconButton>
                            :
                            !connected ?
                                <IconButton className="w-64 h-64" onClick={() => refreshToken(false, version, os)}>
                                    <Icon color="error">sync_problem</Icon>
                                </IconButton>
                                :
                                <IconButton className="w-64 h-64" onClick={() => toggleQuickPanel(true)}>
                                    <Badge classes={{ badge: classes.notificationBadge }} color="error" badgeContent={/*events.length + toDos.length + */_.filter(notifications, (o) => !o.DateReceived).length}>
                                        <Icon>notifications</Icon>
                                    </Badge>
                                </IconButton>
                    }

                    {
                        sipPhone &&
                        <React.Fragment>
                            <div className={classes.separator} />

                            <IconButton className="w-64 h-64" onClick={() => toggleDialer(true)}>
                                {sipSession ? <Icon color={sipSessionActive ? "secondary" : "primary"}>phone_in_talk</Icon> : <Icon>dialpad</Icon>}
                            </IconButton>
                            {remoteStream && <DialogAudio stream={remoteStream} />}
                        </React.Fragment>
                    }

                    {(sipPhone && sipSession && !sipSessionActive && sipSession.direction !== "outbound") &&
                        <Dialog
                            open={true}
                            classes={{ root: classes.incomingCallRoot, paper: classes.incomingCall }}
                            fullWidth
                            maxWidth="sm">
                            <div style={{ width: 300, height: 300 }}>
                                <Typography variant="caption" className=" justify-center capitalize text-white text-center mt-20 text-20">{incomingCaller ? `${incomingCaller.FirstName} ${incomingCaller.LastName}` : this.formatPhone(sipSession.options.remoteCallerNumber)}</Typography>
                                <Avatar className={classNames(classes.incomingUserAvatar, "mt-24 mb-16")} alt="caller photo" src={(incomingCaller && incomingCaller.Data && incomingCaller.Data.Avatar && incomingCaller.Data.Avatar.length > 0 ? getProfileImage(`ID=${incomingCaller.Data.Avatar}`) : avatar)} />
                                <div className="flex justify-center">
                                    <div className="w-full">
                                        <Avatar className={classNames(classes.endCallKey, "float-left ml-84")} onClick={this.handleEndCall}><Icon>call_end</Icon></Avatar>
                                        <Avatar className={classNames(classes.callKey, "float-right mr-84")} onClick={this.handleCall}><Icon>phone</Icon></Avatar>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    }

                    <Hidden lgUp>
                        <div className={classes.separator} />
                    </Hidden>

                    <Hidden lgUp>
                        <IconButton className="w-64 h-64" onClick={openMap}>
                            <Tooltip title="View Map" placement="bottom">
                                <Icon>map</Icon>
                            </Tooltip>
                        </IconButton>
                    </Hidden>

                    <Hidden lgUp>
                        <div className={classes.separator} />

                        <IconButton className="w-64 h-64" onClick={openChatPanel}>
                            <Tooltip title="Open Chat Panel" placement="bottom">
                                <Badge badgeContent={unreadChats} classes={{ badge: classes.badge }} color="error">
                                    <Icon>chat</Icon>
                                </Badge>
                            </Tooltip>
                        </IconButton>
                    </Hidden>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleQuickPanel: quickPanelActions.toggleQuickPanel,
        logout: authActions.logoutUser,
        openChatPanel: chatPanelActions.openChatPanel,
        openMap: chatPanelActions.openMap,
        toggleDialer: dialerActions.toggleDialer,
        refreshToken: Actions.refreshToken,
        endCall: dialerActions.endCall,
        answerCall: dialerActions.answerCall,
        getAbout: Actions.getAbout,
        getReleaseNotes: Actions.getReleaseNotes,
        warnLiveVersion: Actions.warnLive,
        addAlert: Actions.addAlert,
        removeAlert: Actions.removeAlert,
    }, dispatch);
}


function mapStateToProps({ spReducers, dialer, version, chatPanel, alerts, support }) {
    return {
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        sipPhone: dialer.sipPhone,
        sipSession: dialer.sipSession,
        sipSessionActive: dialer.sipSessionActive,
        remoteStream: dialer.remoteStream,
        connected: spReducers.connectionStatus.connected,
        version: version.version,
        isSupportProvider: (version.about || {}).SupportProvider,
        device: version.device,
        chats: chatPanel.user.conversations,
        calls: spReducers.callHandler.Calls,
        offline: spReducers.offline.status.offline,
        events: spReducers.calendar.data.Events,
        trips: spReducers.calendar.data.Trips,
        toDos: spReducers.calendar.data.ToDos,
        notifications: spReducers.notifications,
        warnLive: version.warningDialog.props.open,
        alerts,
        testAppErrors: support.testAppErrors
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MainToolbar));
