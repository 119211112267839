import {combineReducers} from 'redux';
import optionBoardTypes from './option-board-types.reducer';
import user from './user.reducer';

const optionBoardTypesAppReducers = combineReducers({
    optionBoardTypes,
    user
});

export default optionBoardTypesAppReducers;
