import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    conversations         : [],
    searchText        : '',
};

const textMessagesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_TEXT_MESSAGES:
        {
            return {
                ...state,
                conversations: [...action.payload],
            };
        }
        case Actions.SET_TEXT_MESSAGES:
        {
            return {
                ...state,
                conversations: [...action.payload],
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        default:
        {
            return state;
        }
    }
};

export default textMessagesReducer;
