import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Button, CircularProgress, Dialog, Fab, Icon, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BrowserMultiFormatReader } from '@zxing/library';
import classNames from 'classnames';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setScanning } from '../../../../store/actions/utilities';

const styles = theme => ({
    root: {
        opacity: '1 !important',
        '& *': {
            opacity: '1 !important',
        }
    },
    container: {
        background: 'transparent',
        backgroundColor: 'transparent',
    },
    scanner: {
        width: '100%',
        background: 'transparent',
        maxWidth: '50vw',
        height: '50vw',
        boxShadow: '0 0 0 100vmax rgba(0, 0, 0, .5)'
    },
    scannerDone: {
        width: '100%',
        background: 'rgba(0, 0, 0, .5)',
        maxWidth: '50vw',
        height: '50vw',
        boxShadow: '0 0 0 100vmax rgba(0, 0, 0, .5)'
    },
    partData: {
        width: '100%',
        textAlign: 'center',
    },
    speedDial: {
        position: 'absolute',
        bottom: 12,
        right: 12,
    },
    torch: {
        background: '#3f3f3f !important',
        color: '#fff',
    },
    torchEnabled: {
        background: theme.palette.primary.main + ' !important',
        color: '#fff',
    },
    camera: {
        background: '#3f3f3f !important',
        color: '#fff',
    },
    exit: {
        position: 'fixed',
        top: 12,
        right: 12,
    },
    fab: {
        background: '#3f3f3f !important',
        color: '#fff',
    },
    settings: {
        position: 'fixed',
        bottom: 12,
        right: 12,
    },
    preview: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        background: '#fff',
        borderRadius: 5,
        padding: 12,
        width: 300,
    }
});

const actions = [
    { icon: <Icon>flash_on</Icon>, name: 'Torch' },
    { icon: <Icon>flash_off</Icon>, name: 'Torch Off' },
    { icon: <Icon>zoom_in</Icon>, name: 'Zoom In' },
    { icon: <Icon>zoom_out</Icon>, name: 'Zoom Out' },
];

class BarcodeScannerComponent extends Component {

    state = {
        active: false,
        result: null,
        scanner: null,
        torch: false,
        menuOpen: false,
        askPermission: null,
        device: null,
        devices: []

    };

    componentDidMount() {
        this.setState({ ...this.state });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { open, message } = this.props;
        if (open && !prevProps.open) {
            if (window.cordova) {
                this.startScan();
            } else {
                this.setDevice(undefined);
            }
        } else if (!open && prevProps.open) {
            this.setState({ ...this.state, result: null, message: null }, this.stopScan);
        } else if (message && (message !== prevProps.message || !this.state.message)) {
            this.setState({ ...this.state, message }, this.stopScan);
        }
    }

    componentWillUnmount = () => {
        this.stopScan();
    }

    toggleTorch = () => {
        const { torch } = this.state;
        document.getElementById('scanner').srcObject.getVideoTracks()[0].applyConstraints({ advanced: [{ torch: !torch }] });
        this.setState({ ...this.state, ...{ torch: !torch } });
    }

    closeDialog = () => {
        const { scanner } = this.state;
        if (scanner) {
            scanner.reset()
        };
        this.setState({ ...this.state, scanner: null, torch: false, menuOpen: false, result: null }, () => {
            Promise.all([
                this.stopScan()
            ]).then(() => {
                this.props.onClose();
            })
        });
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    setDevice = (device) => {
        this.setState({ ...this.state, anchorEl: null, device }, () => {
            const { Co, } = this.props;
            let { scanner, device } = this.state;
            const devices = [];
            window["warn"](`Selected device: `, device, this.state);
            if (scanner) {
                scanner.reset();
                scanner = null;
            };
            const args = {
                audio: false,
                video: {
                    width: 1280,
                    height: 1280
                }
            }
            scanner = new BrowserMultiFormatReader();

            scanner
                .listVideoInputDevices()
                .then(videoInputDevices => {
                    videoInputDevices.forEach(dev =>
                        devices.push(dev)
                    );
                    this.setState({ ...this.state, scanner, device: device ? device : devices[devices.length - 1].deviceId, devices }, () => {
                        let { scanner, device } = this.state;
                        scanner
                            .decodeFromInputVideoDevice(device, 'scanner')
                            .then((result) => {
                                if (result) {
                                    window["log"](result);
                                    const { text } = result;
                                    scanner.reset();
                                    this.setState({ ...this.state, scanner: null, result: text }, () => {
                                        this.props.onScan(text);
                                    });
                                }
                            })
                            .catch(err => window["error"](err));
                    });
                })
                .catch(err => window["error"](err));

        });
    }

    startScan = async () => {
        if (window.cordova) {
            const allowed = await this.checkPermission();
            if (allowed) {
                this.props.setScanning(true);
                BarcodeScanner.hideBackground(); // make background of WebView transparent

                const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
                // if the result has content
                if (result.hasContent) {
                    console.log(result.content); // log the raw scanned content
                    this.setState({ ...this.state, scanner: null, result: result.content, active: false }, () => {
                        this.props.onScan(result.content);
                        this.stopScan();
                        this.props.setScanning(false);
                    });
                } else {
                    this.stopScan();
                    this.props.setScanning(false);
                }
            }
        }
    };

    stopScan = () => {
        if (window.cordova) {
            this.setState({ ...this.active, active: false }, () => {
                BarcodeScanner.showBackground();
                BarcodeScanner.stopScan();
                this.props.setScanning(false);
            })
        }
    };

    checkPermission = async () => {
        return new Promise(async (resolve, reject) => {
            const status = await BarcodeScanner.checkPermission({ force: true });
            if (status.granted) {
                resolve(true);
            } else if (status.denied) {
                this.setState({ ...this.state, askPermission: 'Service Point Pro needs access to your camera in order to use the barcode scanner. Please open App Settings and allow camera access.' });
                resolve(false);
            } else {
                resolve(false);
            }
        })
    }

    render() {
        const { classes, type, open } = this.props;
        const { active, result, torch, menuOpen, devices, anchorEl, askPermission, message } = this.state;

        return (
            <Dialog
                open={open}
                className={classes.root}
                classes={{ root: classes.root, paper: !result ? classes.scanner : classes.scannerDone }}
                fullWidth
                onClose={this.closeDialog}
                BackdropProps={{
                    classes: { root: classes.container }
                }}
            >
                <div className={classNames("flex", classes.exit)}>
                    <Fab
                        color=""
                        aria-label="add"
                        className={classes.fab}
                        onClick={this.closeDialog}
                    >
                        <Icon>close</Icon>
                    </Fab>
                </div>
                {Boolean(window.cordova) && askPermission &&
                    <ConfirmationDialog
                        open={this.state.askPermission}
                        title={<div><Icon className="mr-6 align-middle mb-4">lock</Icon>Permission needed</div>}
                        content={<div className="w-full pt-8 pb-8">{askPermission}</div>}
                        confirmText="Open Settings"
                        cancelText="Cancel"
                        onCancel={() => this.setState({ ...this.state, askPermission: null })}
                        onConfirm={() => this.setState({ ...this.state, askPermission: null }, () => { BarcodeScanner.openAppSettings(); })}
                    />
                }
                {!result &&
                    <React.Fragment>
                        <video
                            id="scanner"
                            style={{ width: '50vw', height: '50vw' }}
                        ></video>
                        <div className="flex">
                            <div className="scanner-laser laser-leftTop" style={{ opacity: 0.5 }}></div>
                            <div className="scanner-laser laser-rightTop" style={{ opacity: 0.5 }}></div>
                        </div>
                        <div className="scanner-laser laser-middleBar" style={{ opacity: 0.25 }}></div>
                        <div className="flex">
                            <div className="scanner-laser laser-leftBottom" style={{ opacity: 0.5 }}></div>
                            <div className="scanner-laser laser-rightBottom" style={{ opacity: 0.5 }}></div>
                        </div>
                    </React.Fragment>
                }
                {result &&
                    <div className={classNames(!result ? "h-56" : "h-128", classes.preview)}>
                        <div className="flex">
                            {!message ?
                                <CircularProgress color="primary" className="mr-12" style={{ width: 24, height: 20, margin: 6 }} />
                                :
                                <Icon className="text-24 mt-4" color="error">error</Icon>
                            }
                            <Typography variant="subtitle1" color="inherit" className="w-full font-bold text-center text-16">
                                {result}
                            </Typography>
                        </div>
                        {message &&
                            <React.Fragment>
                                <Typography variant="subtitle1" color="inherit" className="w-full font-bold text-center text-12 mb-12 text-red">
                                    {message}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-label="add"
                                    className="w-full"
                                    onClick={() => {
                                        this.setState({ ...this.state, result: null, message: null }, () => {
                                            if (window.cordova) {
                                                this.startScan();
                                            } else {
                                                this.setDevice(this.state.device);
                                            }
                                        });
                                    }}
                                >
                                    Try Again
                                </Button>
                            </React.Fragment>
                        }
                    </div>
                }
                {!window.cordova &&
                    <div className={classNames("flex", classes.settings)}>
                        {devices.length > 0 &&
                            <React.Fragment>
                                <Tooltip title="Switch Camera" placement="left">
                                    <Fab
                                        color="secondary"
                                        aria-label="add"
                                        className={classes.camera}
                                        onClick={this.handleClick}
                                    >
                                        <Icon>switch_camera</Icon>
                                    </Fab>
                                </Tooltip>
                                <Menu
                                    id="cam-select"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                >
                                    {devices.map((device, index) =>
                                        <MenuItem selected={device.deviceId === this.state.device} key={index} onClick={() => this.setDevice(device.deviceId)}>{device.label}</MenuItem>
                                    )}
                                </Menu>
                                <div className="w-12 min-w-12 h-12"></div>
                            </React.Fragment>
                        }
                        <Tooltip title="Enable Torch" placement="left">
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={!torch ? classes.torch : classes.torchEnabled}
                                onClick={this.toggleTorch}
                            >
                                <Icon>{!torch ? 'flash_off' : 'flash_on'}</Icon>
                            </Fab>
                        </Tooltip>
                    </div>
                }
                {/* <SpeedDial
                    ariaLabel="Scanner Settings"
                    className={classes.speedDial}
                    icon={<Icon>settings</Icon>}
                    onClose={this.handleMenuClose}
                    onOpen={this.handleMenuOpen}
                    open={menuOpen}
                    direction="up"
                >
                    {actions.map(action => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={this.handleMenuClose}
                        />
                    ))}
                </SpeedDial> */}
            </Dialog >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setScanning,
    }, dispatch);
}

function mapStateToProps({ spReducers, utilities }) {
    return {
        Co: spReducers.companies.Co,
        isScanning: utilities.isScanning
    }
}
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(BarcodeScannerComponent));
