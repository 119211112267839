import { Http } from '@capacitor-community/http';
import { Capacitor, registerPlugin } from "@capacitor/core";
import { Geolocation } from '@capacitor/geolocation';
import { LocalNotifications } from '@capacitor/local-notifications';
import _ from '@lodash';
import axios from 'axios/index';
import { updateTrip } from 'main/content/apps/trips/store/actions';
import moment from 'moment';
import { createNotification, SET_LOCAL_NOTIFICATION } from './local-notifications.actions';
import { addAlert, updateUserLocation } from 'store/actions';
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");
// import BackgroundGeolocation from "@capacitor-community/background-geolocation";

export const SET_GPS_ENABLED = '[SPCONNECTION] SET GPS ENABLED';
export const GET_GPS_DATA = '[SPCONNECTION] GET GPS DATA';
export const SET_GPS_DATA = '[SPCONNECTION] SET GPS DATA';
export const GET_GPS_LOCATION = '[SPCONNECTION] GET GPS LOCATION';
export const SET_GPS_LOCATION = '[SPCONNECTION] SET GPS LOCATION';
export const LOG_GPS_LOCATION = '[SPCONNECTION] LOG GPS LOCATION';
export const LOG_GPS_ERROR = '[SPCONNECTION] LOG GPS ERROR';
export const SUBSCRIBE_GPS_LIST = '[SPCONNECTION] SUBSCRIBE GPS LIST';
export const SET_GPS_WATCHER_ID = '[SP CONNECTION] SET GPS WATCHER ID';
export const SET_GPS_WATCHERS = '[SP CONNECTION] SET GPS WATCHERS';
export const SET_GPS_LAST_POSTED = '[SP CONNECTION] SET GPS LAST POSTED';

export function setGPSEnabled(enabled) {
    return {
        type: SET_GPS_ENABLED,
        enabled,
    }
}

export function openAppSettings() {
    BackgroundGeolocation.openSettings();
}

function isArrived(techLat, techLng, woLat, woLng, threshhold) {
    var R = 6371; // km
    var dLat = toRad(Number(woLat) - Number(techLat));
    var dLon = toRad(Number(woLng) - Number(techLng));
    var techLat = toRad(Number(techLat));
    var woLat = toRad(Number(woLat));
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(techLat) * Math.cos(woLat);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    var arv = false;
    var dist = d * 0.621371;
    if (d * 0.621371 < (threshhold || .15)) {
        arv = true;
    }
    return {
        Arrived: arv,
        Distance: dist
    };
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}

export function checkGeofence(trip) {
    return (dispatch, getState) => {
        const state = getState();
        const { location, errors } = state.spReducers.gps;
        const today = moment().local().format('MM/DD/YYYY');
        const tripDate = moment(trip.BoardTime).format('MM/DD/YYYY');
        if (location && tripDate === today) {
            const { latitude, longitude } = location;
            if (latitude && longitude && trip.Status > 0 && trip.Status < 6) {
                try {
                    LocalNotifications.getDeliveredNotifications().then((res) => {
                        const { notifications } = res;
                        const isNotifiedArrival = _.find(notifications, (o) => o.id === trip.ID && o.actionTypeId === 'SiteArrival');
                        if (!isNotifiedArrival) {
                            const { Latitude, Longitude } = trip.Data.WorkOrder.Data.Site;
                            const arrv = isArrived(latitude, longitude, Latitude, Longitude);
                            if (arrv.Arrived) {
                                dispatch(createNotification(trip.ID, 'Site Arrival', `It looks like you've arrived at Work Order #${trip.WorkOrder}. Tap here to mark Trip #${trip.Trip} as "In Progress", or Long-Press for more options.`, { ...trip, Status: 6 }, 'SiteArrival', 'SPTripActions'));
                            }
                        }
                    });
                } catch (error) {
                    if (errors.length > 299) {
                        errors.length = 299;
                    }
                    errors.splice(0, 0, {
                        error: {
                            message: 'GPS Proximity Error'
                        },
                        event: 'proximity',
                        info: error
                    });
                    dispatch({
                        type: LOG_GPS_ERROR,
                        errors
                    })
                }
            }
        }
    }
}

export function updateLocation(location, mute) {
    return (dispatch, getState) => {
        const state = getState();
        const { spReducers, spAuth } = state;
        const { authentication } = spAuth;
        const { access_token } = authentication;
        const { gps, technician } = spReducers;
        const { allTrips } = technician;
        const { User } = spReducers.userProfiles;
        if (User && location) {
            const { UserName, GPSEnabledYN, FirstName, LastName } = User;
            const { logs, errors, id, watchers, lastPosted } = gps;
            let obj = {
                UserName,
                Name: `${FirstName} ${LastName}`,
                Data: {
                    ErrMsg: null
                },
            }
            const { latitude, longitude, speed, bearing } = location;
            if (speed && speed > 0 && !mute) {
                // const mph = speed * 2.237;
                const dist = speed * 30;
                dispatch(initGPS(User, dist));
            }
            if (logs.length > 299) {
                logs.length = 299;
            }
            logs.splice(0, 0, { ...location, event: mute ? 'locate' : 'location', timestamp: new Date() });
            dispatch({
                type: LOG_GPS_LOCATION,
                logs
            });
            const timestamp = new Date();
            dispatch({
                type: SET_GPS_LOCATION,
                location: {
                    ...location,
                    timestamp
                },
                timestamp
            });
            const maxTime = moment().subtract(15, 'seconds').toDate();
            if (!lastPosted || lastPosted <= maxTime) {
                obj = {
                    ...obj,
                    Latitude: latitude.toString(),
                    Longitude: longitude.toString(),
                    Heading: (bearing || "-1").toString(),
                    TimeStamp: timestamp,
                    Status: (bearing && bearing >= 0) ? (speed && speed > 0) ? "moving" : "idle" : "static"
                }
                const options = {
                    url: `${window["apiLocation"]}/api/UserGPS`,
                    headers: { "Authorization": access_token, "Content-Type": "application/json" },
                    data: obj,
                };

                const request = Http.post(options);
                request.then((response) => {
                    dispatch({
                        type: SET_GPS_LAST_POSTED,
                        lastPosted: new Date()
                    });
                    window["warn"]('GPS Location POST Response: ', response);
                    if (logs.length > 299) {
                        logs.length = 299;
                    }
                    logs.splice(0, 0, { ...response, event: 'http', timestamp: new Date() });
                    dispatch({
                        type: LOG_GPS_LOCATION,
                        logs
                    });

                    dispatch(updateUserLocation(response.data));
                }).catch((error) => {
                    if (errors.length > 299) {
                        errors.length = 299;
                    }
                    errors.splice(0, 0, {
                        error: {
                            message: 'GPS Location POST Error',
                            user: _.cloneDeepWith(User || spReducers.userProfiles.User)
                        },
                        event: 'http',
                        info: error
                    });
                    dispatch({
                        type: LOG_GPS_ERROR,
                        errors
                    })
                });
            }
            const today = moment().local().format('MM/DD/YYYY');
            const todayTrips = _.filter(allTrips, (o) => moment(o.BoardTime).format('MM/DD/YYYY') === today);
            const notified = _.filter(todayTrips, (o) => o.Status === 1);
            const enRoute = _.filter(todayTrips, (o) => o.Status === 4);
            const onHold = _.filter(todayTrips, (o) => o.Status === 7);
            const inProgress = _.filter(todayTrips, (o) => o.Status === 6);
            try {
                LocalNotifications.getDeliveredNotifications().then((res) => {
                    const { notifications } = res;
                    dispatch({
                        type: SET_LOCAL_NOTIFICATION,
                        payload: notifications
                    });
                    notified.map((trip) => {
                        const isNotifiedArrival = _.find(notifications, (o) => o.id === trip.ID && o.actionTypeId === 'SiteArrival');
                        if (!isNotifiedArrival) {
                            const { Latitude, Longitude } = trip.Data.WorkOrder.Data.Site;
                            const arrv = isArrived(latitude, longitude, Latitude, Longitude);
                            if (arrv.Arrived) {
                                dispatch(createNotification(trip.ID, 'Site Arrival', `It looks like you've arrived at Work Order #${trip.WorkOrder}. Tap here to mark Trip #${trip.Trip} as "In Progress", or Long-Press for more options.`, { ...trip, Status: 6 }, 'SiteArrival', 'SPTripActions'));
                            }
                        }
                    });
                    onHold.map((trip) => {
                        const isNotifiedArrival = _.find(notifications, (o) => o.id === trip.ID && o.actionTypeId === 'SiteArrival');
                        if (!isNotifiedArrival) {
                            const { Latitude, Longitude } = trip.Data.WorkOrder.Data.Site;
                            const arrv = isArrived(latitude, longitude, Latitude, Longitude);
                            if (arrv.Arrived) {
                                dispatch(createNotification(trip.ID, 'Site Arrival', `It looks like you've returned to Work Order #${trip.WorkOrder}. Tap here to mark Trip #${trip.Trip} as "In Progress", or Long-Press for more options.`, { ...trip, Status: 6, Notes: null }, 'SiteArrival', 'SPTripActions'));
                            }
                        }
                    });
                    enRoute.map((trip) => {
                        const isNotifiedArrival = _.find(notifications, (o) => o.id === trip.ID && o.actionTypeId === 'SiteArrival');
                        if (!isNotifiedArrival) {
                            const { Latitude, Longitude } = trip.Data.WorkOrder.Data.Site;
                            const arrv = isArrived(latitude, longitude, Latitude, Longitude);
                            if (arrv.Arrived) {
                                trip.BoardTime = new Date().toLocaleString('en-US');
                                dispatch(updateTrip({ ...trip, Status: 5, BoardTime: new Date().toLocaleDateString('en-US') }));
                                dispatch(createNotification(trip.ID, 'Site Arrival', `It looks like you've arrived at Work Order #${trip.WorkOrder}. Tap here to mark Trip #${trip.Trip} as "In Progress", or Long-Press for more options.`, { ...trip, Status: 6 }, 'SiteArrival', 'SPTripActions'));
                            }
                        }
                    });
                    inProgress.map((trip) => {
                        const isNotifiedDeparture = _.find(notifications, (o) => o.id === trip.ID && o.actionTypeId === 'SiteDeparture');
                        if (!isNotifiedDeparture) {
                            const { Latitude, Longitude } = trip.Data.WorkOrder.Data.Site;
                            const arrv = isArrived(latitude, longitude, Latitude, Longitude, .5);
                            if (!arrv.Arrived) {
                                dispatch(createNotification(trip.ID, 'Site Departure', `It looks like you've left Work Order #${trip.WorkOrder}. Tap here to mark Trip #${trip.Trip} as "Completed", or Long-Press for more options.`, { ...trip, Status: 8 }, 'SiteDeparture', 'SPTripActions'));
                            }
                        }
                    })
                });
            } catch (error) {
                if (errors.length > 299) {
                    errors.length = 299;
                }
                errors.splice(0, 0, {
                    error: {
                        message: 'GPS Proximity Error'
                    },
                    event: 'proximity',
                    info: error
                });
                dispatch({
                    type: LOG_GPS_ERROR,
                    errors
                })
            }
        }
    }
}

export function removeWatcher(id, locate) {
    return (dispatch, getState) => {
        const state = getState();
        const { spReducers } = state;
        const { gps } = spReducers;
        const { watchers } = gps;
        const { User } = spReducers.userProfiles;
        if (id) {
            const old = _.find(watchers, { id });
            if (old && !old.removedAt) {
                BackgroundGeolocation.removeWatcher({
                    id
                });
                old.removedAt = new Date();
                dispatch({
                    type: SET_GPS_WATCHERS,
                    watchers
                });
                dispatch(initGPS(User));
                if (locate) {
                    dispatch(getCurrentLocation());
                }
            }
        }
    }

}

export function getCurrentLocation() {
    return (dispatch, getState) => {
        var geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        Geolocation.getCurrentPosition(geoOptions).then((pos) => {
            var crd = pos.coords;
            const { latitude, longitude } = crd;
            dispatch(updateLocation({ latitude, longitude }, true));
        });
    }
}

export function initGPS(User, dist) {
    return (dispatch, getState) => {
        const platform = Capacitor.getPlatform();
        const state = getState();
        const { spReducers } = state;
        const { gps } = spReducers;
        const { UserName, GPSEnabledYN, FirstName, LastName } = User || spReducers.userProfiles.User || {};
        const { errors, id, watchers } = gps;
        if (id) {
            const old = _.find(watchers, { id });
            if (old && !old.removedAt) {
                BackgroundGeolocation.removeWatcher({
                    id
                });
                old.removedAt = new Date();
                dispatch({
                    type: SET_GPS_WATCHERS,
                    watchers
                });
            }
        }
        // if (UserName && FirstName && LastName && platform === 'web') {
        //     dispatch(getCurrentLocation());
        // };
        if (UserName && FirstName && LastName && GPSEnabledYN !== 'N' && platform !== 'web') {
            BackgroundGeolocation.addWatcher(
                {
                    backgroundMessage: "Cancel to prevent battery drain.",
                    backgroundTitle: "Location In Use",
                    requestPermissions: true,
                    stale: false,
                    distanceFilter: dist || 30
                },
                (location, error) => {
                    if (error) {
                        if (error.code === "NOT_AUTHORIZED") {
                            dispatch(addAlert({
                                open: true,
                                title: 'Permission Required',
                                content: 'Service Point Pro needs to track your location, but has not received permission to do so - Please open Application Settings and grant access to Location Services.',
                                icon: "explore",
                                cancelText: "Dismiss",
                                confirmText: "Settings",
                                onConfirm: () => BackgroundGeolocation.openSettings()
                                // hideConfirm: true
                            }));
                        } else {
                            if (errors.length > 299) {
                                errors.length = 299;
                            }
                            errors.splice(0, 0, {
                                error,
                                event: 'init'
                            });
                            dispatch({
                                type: LOG_GPS_ERROR,
                                errors
                            })
                        }
                        // return console.error(error);
                    } else {
                        if (location && (location.accuracy || 0) < 30) {
                            dispatch(updateLocation(location));
                        }
                        // return console.log(location);
                    }
                }
            ).then((watcher_id) => {
                if (watchers.length > 299) {
                    watchers.length = 299;
                }
                watchers.splice(0, 0, {
                    id: watcher_id,
                    addedAt: new Date(),
                    distanceFilter: dist || 30
                });
                dispatch({
                    type: SET_GPS_WATCHERS,
                    watchers
                })
                dispatch({
                    type: SET_GPS_WATCHER_ID,
                    id: watcher_id
                });
                setTimeout(() => {
                    dispatch(removeWatcher(watcher_id, true));
                }, ((dist && dist > 0) ? 45000 : 1800000));
            });

        }
    }
}

// export function initGPS_TS(User) {
//     return (dispatch, getState) => {
//         const state = getState();
//         const { spReducers, spAuth, version } = state;
//         const { device } = version;
//         const { authentication } = spAuth;
//         const { access_token, refresh_token, expires_at } = authentication;
//         const { gps } = spReducers;
//         const { UserName, GPSEnabledYN, FirstName, LastName } = User || spReducers.userProfiles.User || {};
//         const { logs, errors } = gps;
//         // var dt = new Date();
//         // var exp = new Date(expires_at);
//         // var expires = Math.floor((exp - dt) / 1000) - 60;
//         if (UserName && FirstName && LastName) {
//             var geoOptions = {
//                 reset: true,
//                 locationAuthorizationAlert: {
//                     titleWhenNotEnabled: "Location Services are not enabled",
//                     titleWhenOff: "Location Services are OFF",
//                     instructions: "In order to utilize GPS functionality, Location Services must be set to 'Always'.",
//                     cancelButton: "Cancel",
//                     settingsButton: "Settings"
//                 },
//                 desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
//                 distanceFilter: 50,
//                 stationaryRadius: 25,
//                 elasticityMultiplier: 3,
//                 activityRecognitionInterval: 5000,
//                 stopTimeout: 5,
//                 debug: false,
//                 stopOnTerminate: false,   // <-- Allow the background-service to continue tracking when user closes the app.
//                 startOnBoot: true,        // <-- Auto start tracking when device is powered-up.
//                 logLevel: BackgroundGeolocation.LOG_LEVEL_INFO,
//                 heartbeatInterval: 1800,
//                 preventSuspend: true,
//                 url: `${window["apiLocation"]}/api/UserGPS`,
//                 batchSync: false,       // <-- [Default: false] Set true to sync locations to server in a single HTTP request.
//                 autoSync: true,         // <-- [Default: true] Set true to sync each location to server as it arrives.
//                 method: 'POST',
//                 // authorization: {
//                 //     strategy: "JWT",
//                 //     accessToken: access_token,
//                 //     refreshToken: refresh_token,
//                 //     refreshUrl: `${window["apiLocation"]}/token`,
//                 //     expires: 3540,
//                 //     refreshPayload: {
//                 //         grant_type: "refresh_token",
//                 //         refresh_token: "{refreshToken}",
//                 //         username: UserName,
//                 //         version: version.version,
//                 //         os: device && (device.osName || device.os) && device.osVersion ? `${device.osName || device.os} ${device.osVersion}` : 'N/A',
//                 //         source: "GPS",
//                 //     },
//                 // },
//                 headers: {
//                     "Authorization": access_token
//                 },
//                 httpTimeout: 30000,
//                 httpRootProperty: '.',
//                 locationTemplate: '{"Latitude":<%= latitude %>,"Longitude":<%= longitude %>,"Heading":<%= heading %>, "TimeStamp": "<%= timestamp %>", "Status": "<%= is_moving %>"}',
//                 extras: {
//                     UserName,
//                     Name: `${FirstName} ${LastName}`,
//                     Data: {
//                         ErrMsg: null
//                     }
//                 },
//                 maxRecordsToPersist: 1
//             };
//             window["warn"]('Background Geolocation: ', BackgroundGeolocation, geoOptions);
//             if (window.cordova && BackgroundGeolocation) {
//                 // Listen to location events & errors.
//                 BackgroundGeolocation.onLocation((location) => {
//                     if (logs.length > 299) {
//                         logs.length = 299;
//                     }
//                     logs.splice(0, 0, { ...location, event: 'location', timestamp: new Date() });
//                     dispatch({
//                         type: LOG_GPS_LOCATION,
//                         logs
//                     });
//                     dispatch({
//                         type: SET_GPS_LOCATION,
//                         location,
//                         timestamp: new Date()
//                     });
//                     const newState = getState();
//                     if (newState && newState.spAuth.authentication && newState.spAuth.authentication.access_token) {
//                         geoOptions.headers = {
//                             "Authorization": newState.spAuth.authentication.access_token
//                         };
//                         BackgroundGeolocation.setConfig(geoOptions);
//                     }
//                 }, (error) => {
//                     if (errors.length > 299) {
//                         errors.length = 299;
//                     }
//                     errors.splice(0, 0, { error, event: 'location', timestamp: new Date() });
//                     dispatch({
//                         type: LOG_GPS_ERROR,
//                         errors
//                     })
//                 });
//                 BackgroundGeolocation.onHttp((response) => {
//                     if (response.success) {
//                         if (logs.length > 299) {
//                             logs.length = 299;
//                         }
//                         logs.splice(0, 0, { ...response, event: 'http', timestamp: new Date() });
//                         dispatch({
//                             type: LOG_GPS_LOCATION,
//                             logs
//                         });
//                     } else {
//                         if (errors.length > 299) {
//                             errors.length = 299;
//                         }
//                         errors.splice(0, 0, { error: response, event: 'http', timestamp: new Date() });
//                         dispatch({
//                             type: LOG_GPS_ERROR,
//                             errors
//                         });
//                     }
//                 });
//                 BackgroundGeolocation.onHeartbeat(heartbeatEvent => {
//                     BackgroundGeolocation.getCurrentPosition();
//                 });

//                 BackgroundGeolocation.getState().then((state) => {
//                     if (!state.enabled) {
//                         BackgroundGeolocation.ready(geoOptions).then((status) => {
//                             console.log("BackgroundGeolocation is configured and ready to use ", status.enabled);
//                             if (GPSEnabledYN !== 'N') {
//                                 BackgroundGeolocation.start(() => {
//                                     BackgroundGeolocation.getCurrentPosition();
//                                 }, (err) => {
//                                     if (errors.length > 299) {
//                                         errors.length = 299;
//                                     }
//                                     errors.splice(0, 0, {
//                                         error: {
//                                             message: `Unable to initialize GPS - ${err}`,
//                                             user: _.cloneDeepWith(User || spReducers.userProfiles.User),
//                                             timestamp: new Date()
//                                         },
//                                         event: 'init'
//                                     });
//                                     dispatch({
//                                         type: LOG_GPS_ERROR,
//                                         errors
//                                     })
//                                 });
//                             }
//                         });
//                     } else {
//                         BackgroundGeolocation.stop(() => {
//                             BackgroundGeolocation.setConfig(geoOptions);
//                             if (GPSEnabledYN !== 'N') {
//                                 BackgroundGeolocation.start();
//                             }
//                         }, (err) => {
//                             if (errors.length > 299) {
//                                 errors.length = 299;
//                             }
//                             errors.splice(0, 0, {
//                                 error: {
//                                     message: `Unable to stop GPS - ${err}`,
//                                     user: _.cloneDeepWith(User || spReducers.userProfiles.User),
//                                     timestamp: new Date()
//                                 },
//                                 event: 'stop'
//                             });
//                             dispatch({
//                                 type: LOG_GPS_ERROR,
//                                 errors
//                             })
//                         });
//                     }
//                 });
//             }
//         } else {
//             if (errors.length > 299) {
//                 errors.length = 299;
//             }
//             errors.splice(0, 0, {
//                 error: {
//                     message: 'Unable to initialize GPS - User Data not found',
//                     user: _.cloneDeepWith(User || spReducers.userProfiles.User)
//                 },
//                 event: 'init'
//             });
//             dispatch({
//                 type: LOG_GPS_ERROR,
//                 errors
//             })
//         }
//     }
// }

export function getInitialGPSData() {
    const request = axios.get(`${window["apiLocation"]}/api/UserGPS`);
    request.then((response) => {
        return response.data;
    });
}

export function getGPSData() {
    const request = axios.get(`${window["apiLocation"]}/api/UserGPS`);

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: SET_GPS_DATA,
                gps: response.data
            })
        });
    }
}

export function setGPSData(data) {
    return {
        type: SET_GPS_DATA,
        gps: data
    };
}

export function trackGPSLocation() {
    return (dispatch, getState) => {
        const state = getState();
        const { spReducers } = state;
        const { gps, userProfiles } = spReducers;
        const { User } = userProfiles;
        const { UserName } = User;
        const { location } = gps;
    }
}

export function getGPSLocation() {
    return (dispatch, getState) => {
        const state = getState();
        const { spReducers } = state;
        const { gps, userProfiles } = spReducers;
        const { User } = userProfiles;
        const { UserName } = User;
        const { location } = gps;
        dispatch({
            type: SET_GPS_LOCATION,
            location,
            timestamp: new Date()
        })
    }
}