import _ from '@lodash';
import { AppBar, Avatar, Dialog, Icon, IconButton, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import * as WorkOrderActions from '../work-orders/store/actions';
import * as Actions from './store/actions';
import moment from 'moment';
import axios from 'axios';
import Timecard from './Timecard';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import { openPreloadTimecardDialog } from './store/actions/timecards.actions';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import getProfileImage from '../../../functions/getProfileImageUrl';
//import TimecardPDFDialog from './TimecardPDFDialog';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    }
});
const newTimecardState = {
    ID: null,
    Co: null,
    Employee: null,
    Date: null,
    Data: {
        ErrMsg: null,
        Punch: [],
        Labor: [],
    },
    ApprovedYN: null,
    ApprovedBy: null,
    ApprovedDate: null,
    SubmittedYN: null,
    SubmittedDate: null,
    Signature: null,
    Notes: null,
    sigImg: null,
    trips: [],
    Hours: undefined,
    value: 0,
    dialogHeight: null,
    minHeight: null,
    location: null,
    //runFetchTimecards: false
};


class TimecardDialog extends Component {
    state = { ..._.cloneDeepWith(newTimecardState) };

    ref = React.createRef();

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        const { data } = this.props.timecardDialog;
        const newState = _.cloneDeepWith(newTimecardState);
        newTimecardState.Co = this.props.Co;
        let { trips } = this.state;
        if (!prevProps.timecardDialog.props.open && this.props.timecardDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.timecardDialog.type === 'edit' &&
                data &&
                !_.isEqual({ ...newState, ...data }, prevState)) {
                this.setState({ ...newState, ...data, value: 0 }, this.fetchSchedule);
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.timecardDialog.type === 'new' &&
                !_.isEqual(newState, prevState)) {
                this.setState({ ...newState, ...data, value: 1 });
            }
        }
        //window["log"]('STATE COMPARISON', this.props.timecardDialog, prevProps, prevState);
        if (prevProps.timecardDialog.props.open && this.props.timecardDialog.props.open) {
            if (this.props.timecardDialog.type === 'data' && data &&
                !_.isEqual(data, prevProps.timecardDialog.data)) {
                this.props.timecardDialog.type = 'edit';
            }

            if (this.props.timecardDialog.type === 'add' && data &&
                !_.isEqual(data, prevProps.timecardDialog.data)) {
                this.props.timecardDialog.type = 'edit';
            }

            if (this.props.timecardDialog.type === 'update' && data &&
                !_.isEqual(data, prevProps.timecardDialog.data)) {
                this.props.timecardDialog.type = 'edit';
            }

            if ((this.props.timecardDialog.type === 'edit') && data &&
                !_.isEqual(data, prevProps.timecardDialog.data)) {
                if (data.Co !== prevProps.timecardDialog.data.Co || data.Employee !== prevProps.timecardDialog.data.Employee || data.Date !== prevProps.timecardDialog.data.Date) {
                    trips = []
                }
                this.setState({ ...newState, ...data, trips });
            }

        }
    }


    closeComposeDialog = () => {
        this.props.timecardDialog.type === 'edit' ? this.props.closeEditTimecardDialog() : this.props.closeNewTimecardDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true})
    };

    closeDialogForSplitscreen = () => {
        this.props.timecardDialog.type === 'edit' ? this.props.closeEditTimecardDialog() : this.props.closeNewTimecardDialog();
    };

    fetchSchedule = () => {
        const { technicians } = this.props;
        const { Co, Employee, Date } = this.state;
        const tech = _.find(technicians, { Co, Employee });
        const dt = moment(Date).format('MM/DD/YYYY')
        if (tech) {
            const request = axios.get(`${window["apiLocation"]}/api/DispatchBoard?Co=${encodeURIComponent(Co)}&Date=${encodeURIComponent(dt)}&Tech=${encodeURIComponent(tech.Technician)}`);
            request.then((response) => {
                const trips = response.data;
                this.setState({ trips });
            })
        }

    }

    //resetRunFetch = () => this.setState({ ...this.state, runFetchTimecards: false });

    printPDF = () => {
        // const { Co, InvoiceNumber } = this.state;
        // const { businessUnits, divisions, templates, companies } = this.props;
        // const { Scopes } = this.state.Data.Data;
        // const { InvoiceDetails, Contacts } = this.state.Data.Data;
        // const { BusinessUnit, Division } = Scopes && Scopes.length > 0 ? Scopes[0] : InvoiceDetails && InvoiceDetails.length > 0 && InvoiceDetails[0].Data && InvoiceDetails[0].Data.Agreement ? InvoiceDetails[0].Data.Agreement : null;
        // let margin = { top: '.16 in', bottom: '.16 in', left: '.16 in', right: '.16 in' };
        // if (BusinessUnit && Division && businessUnits && templates) {
        //     const div = _.find(divisions, { Co, BusinessUnit, Division });
        //     const { InvoiceMarginTop, InvoiceMarginBottom, InvoiceMarginLeft, InvoiceMarginRight } = div;
        //     margin = {
        //         top: `${((InvoiceMarginTop / 330) * 11).toFixed(2)} in`,
        //         bottom: `${((InvoiceMarginBottom / 330) * 11).toFixed(2)} in`,
        //         left: `${((InvoiceMarginLeft / 255) * 8.5).toFixed(2)} in`,
        //         right: `${((InvoiceMarginRight / 255) * 8.5).toFixed(2)} in`
        //     }
        // }

        let margin = {
            top: `1 in`,
            bottom: `1 in`,
            left: `0.5 in`,
            right: `0.5 in`
        }
        //let gridElement = document.getElementById('timecard-print');
        let gridElement = this.ref.current;
        drawDOM(gridElement, { keepTogether: '.prevent-split', paperSize: "letter", margin, scale: .65 }).then((group) => {
            return exportPDF(group);
        }).then((dataUri) => {
            this.setState({ printPDF: dataUri, anchorEl: null, showSigForPrint: false });
        });
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        return (
            <>
                {/* <IconButton style={{
                    position: 'absolute',
                    right: 104,
                    top: matches ? 10 : 4
                    color: 'white'
                }}
                    onClick={() => {
                        this.setState({...this.state, runFetchTimecards: true})
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>print</Icon>
                </IconButton> */}
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4/*if fullScreen*/,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e);
                    }}
                    className="dialog-header-icon"/*if fullScreen*/
                >
                    <Icon>close</Icon>
                </IconButton>
            </>
        )
    }

    ToolbarContents = () => {
        const { classes, employees } = this.props
        const { Co, Employee } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const employee = _.find(employees, { Co, Employee });

        return (
            <>
                <Typography variant="subtitle1" color="inherit">
                    {employee &&
                        <div className="flex">
                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classNames(classes.avatar, "mt-0")} alt={employee.FirstName} src={(employee.Data && employee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${employee.Data.Avatar}&Thumb=true`) : avatar)} />
                            {`${employee.FirstName} ${employee.LastName}`}
                        </div>
                    }
                </Typography>
            </>
        )
    }

    render() {
        const { classes, theme, timecardDialog, employees, source } = this.props
        const { Co, Employee, trips } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const employee = _.find(employees, { Co, Employee });

        const dialogProps = {
            ...timecardDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "sm",
            //hideBackdrop: true,
            disableEnforceFocus: true
        }

        let header = employee ? `Timecard ${employee.FirstName} ${employee.LastName}` : null;

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={null}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={header}
                        type="Timecard"
                        picRef={this.ref.current}
                        icon="access_time"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openPreloadTimecardDialog}
                        description={"Timecard"}
                        storeDialog={timecardDialog}
                        TypeState={this.state.Employee}
                        screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >
                        {/* <TimecardPDFDialog runFetch={this.state.runFetchTimecards} resetRunFetch={this.resetRunFetch.bind(this)} /> */}
                        <TabContainer >
                            <Timecard refProp={this.ref} source={timecardDialog.supervisor ? "supervisor" : "technician"} supervisor={timecardDialog.supervisor} timecard={{ ...this.state }} trips={[...trips]} approve={!!timecardDialog.supervisor} />
                        </TabContainer>
                    </DraggableDialog>
                }
            </Media>
        );
    }
}
TimecardDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTimecardDialog: Actions.closeEditTimecardDialog,
        closeNewTimecardDialog: Actions.closeNewTimecardDialog,
        updateTimecard: Actions.updateTimecard,
        removeTimecard: Actions.removeTimecard,
        openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
        openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
        showMessage: showMessage,
        impress: impress,
        openPreloadTimecardDialog,
    }, dispatch);
}

function mapStateToProps({ auth, timecardsApp, spReducers, dispatchBoardApp }) {
    return {
        timecardDialog: timecardsApp.timecards.timecardDialog,
        googleMapsApiKey: googleMapsApiKey,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        employees: spReducers.employees,
        timecards: spReducers.timecards,
        user: spReducers.userProfiles.User.UserName,
        workOrders: spReducers.workOrders,
        trips: dispatchBoardApp.dispatchBoard.trips,
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TimecardDialog));
