import axios from 'axios/index';

export const SET_CLASS_DATA = '[SPCONNECTION] SET CLASS DATA';
export const GET_CLASS_DATA = '[SPCONNECTION] GET CLASS DATA';
export const SUBSCRIBE_CLASS_LIST = '[SPCONNECTION] SUBSCRIBE CLASS LIST';
export const SET_CLASS_CATEGORY_DATA = '[SPCONNECTION] SET CLASS CATEGORY DATA';
export const GET_CLASS_CATEGORY_DATA = '[SPCONNECTION] GET CLASS CATEGORY DATA';
export const GET_CLASS_PROGRESS = '[SPCONNECTION] GET CLASS PROGRESS';
export const SET_CLASS_PROGRESS = '[SPCONNECTION] SET CLASS PROGRESS';
export const SUBSCRIBE_CLASS_CATEGORY_LIST = '[SPCONNECTION] SUBSCRIBE CLASS CATEGORY LIST';

export function getInitialClassData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Class`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getClassData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Class`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CLASS_DATA,
                payload: response.data
            })
        });
    }
}

export function setClassData(data) {
    return {
        type: SET_CLASS_DATA,
        payload: data
    };
}

export function getInitialClassCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ClassCategory`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getClassCategoryData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ClassCategory`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CLASS_CATEGORY_DATA,
                payload: response.data
            })
        });
    }
}

export function setClassCategoryData(data) {
    return {
        type: SET_CLASS_CATEGORY_DATA,
        payload: data
    };
}

export function getClassProgress(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ClassProgress`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CLASS_PROGRESS,
                payload: response.data
            })
        });
    }
}

export function setClassProgress(data) {
    return {
        type: SET_CLASS_PROGRESS,
        payload: data
    };
}