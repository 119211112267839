import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { Icon, IconButton, Menu, MenuItem, Typography, Dialog, AppBar, Toolbar, DialogContent } from '@material-ui/core';
import { Parser } from 'json2csv';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReportDialog from './ReportDialog';
import ReportTemplate from './ReportTemplate';
import { bindActionCreators } from 'redux';
import PivotTable from './PivotTable';
import * as Actions from './store/actions';
import { showMessage } from 'store/actions';
import format from 'string-template';
import moment from 'moment';
import ReportPDF from './ReportPDF';
import ReportPDFDialog from './ReportPDFDialog';


const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    drawer: {
        width: 0,
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },

});

const newComponentState = {
    results: null,
    drawerOpen: true,
    reportOpen: false,
    reportRef: null,
    exportDetail: 'D',
    menuEl: null,
    loading: false,
}
class ReportViewer extends Component {

    state = {
        ..._.cloneDeepWith(newComponentState)
    };

    componentDidMount() {
        const { component, results } = this.props;
        this.setState({ ..._.cloneDeepWith(newComponentState), ...(component || {}), expandAll: false, results, component });
    }

    componentDidUpdate(prevProps, prevState) {
        const { component, results, loading } = this.props;
        if (!_.isEqual(component, prevProps.component) || !_.isEqual(results, prevProps.results) || !_.isEqual(loading, prevProps.loading)) {
            this.setState({ ..._.cloneDeepWith(newComponentState), ...(component || {}), expandAll: false, results, component, loading, });
        } else {
            if (!_.isEqual(results, prevProps.results) && (!results || results.length < 1)) {
                this.setState({ ...this.state, expandAll: false, results, component, loading });
            }
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    getColumns(data) {
        if (data && data.length > 0) {
            return Object.keys(data[0]).map(key => {
                return {
                    Header: key,
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    accessor: key,
                    id: key,
                    dataType: !isNaN(data[0][key]) ? 'number' : 'text'
                };
            });
        }
    }

    setReportParameter = (option, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = option[value.ListValue];
        } else {
            param = {
                Parameter: value.Parameter,
                Value: option[value.ListValue]
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }

    setReportParameterDate = (event, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = event._d;
        } else {
            param = {
                Parameter: value.Parameter,
                Value: event._d
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }
    getReportParameter = (name) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    getData = () => {
        const { dataSources, component } = this.props;
        const { Category, DataSource } = this.state;
        const Report = component.Data.DataSource;
        window["warn"]('Component Info: ', component, Report);
        this.setState({ ...this.state, drawerOpen: false, loading: true }, () => this.props.getData(Category, Report));
    }

    canGetData = () => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let canRun = true;
        Report.Data.Parameters.map((param) => {
            if (param.RequiredYN === 'Y') {
                const has = _.find(Parameters, { Parameter: param.Parameter });
                canRun = has;
            }
        })
        return canRun;
    }

    buildExport = (cols, recs, arr) => {
        if (recs && arr) {
            try {
                recs.map((rec) => {
                    let data = {};
                    cols.map((col, index) => {
                        // if (index > rec._nestingLevel - 1) {
                        if (col.calculation) {
                            if (rec._subRows && (!col.calculationApplyTo || col.calculationApplyTo === 'A')) {
                                const exp = `${format(col.calculation, rec)}`;
                                const val = loose(exp);
                                data[col.Header] = val;
                            }
                            if (!rec.subRows && (!col.calculationApplyTo || col.calculationApplyTo === 'O')) {
                                const exp = `${format(col.calculation, rec)}`;
                                const val = loose(exp);
                                data[col.Header] = val;
                            }
                        } else {
                            data[col.Header] = rec[col.id];
                        }
                        // }
                    })
                    if (rec._pivotID && rec._pivotVal) {
                        data[rec._pivotID] = rec._pivotVal;
                    }
                    if (!rec._subRows) {
                        arr.push(data);
                    }
                    if (rec._subRows) {
                        arr = this.buildExport(cols, rec._subRows, arr);
                    }
                })
            } catch (error) {
                this.props.showMessage({
                    message: `Error: ${error.message}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            }
        }
        return arr;
    }

    exportData = (data) => {
        const { component, results } = this.props;
        try {
            const csvData = new Parser();
            // const Data = csvData.parse(results);
            const Data = csvData.parse(data);
            var encoded = encodeURIComponent(Data);
            var csv = `data:text/csv;charset=utf-8, ${encoded}`;
            var link = document.createElement("a");
            link.setAttribute("href", csv);
            link.setAttribute("download", `${component.Name}-${moment().format('M-D-YYYY')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        }
    }

    openMenu = (e) => {
        this.setState({ ...this.state, menuEl: e.target });
    }

    closeMenu = () => {
        this.setState({ ...this.state, menuEl: null });
    }

    deleteReport = (rpt) => {
        this.props.removeReport(rpt)
    }

    getData = () => {
        const { dataSources, component } = this.props;
        const { Category, DataSource } = this.state;
        const Report = component.Data.DataSource;
        window["warn"]('Component Info: ', component, Report);
        this.setState({ ...this.state, loading: true }, () => { this.props.getData(Category, Report); });
    }

    canGetData = () => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let canRun = true;
        Report.Data.Parameters.map((param) => {
            if (param.RequiredYN === 'Y') {
                const has = _.find(Parameters, { Parameter: param.Parameter });
                canRun = has;
            }
        })
        return canRun;
    }

    printPDF = () => {

        const { master, component } = this.props;
        const Report = component.Data.DataSource;
        
        const options = JSON.parse(component.Options);
        const columns = options ? options.columnOptions : [];
        const pivotBy = options ? options.pivotBy : [];
        
        const margin = { top: '2.12in', bottom: '.5 in', left: '.16 in', right: '.16 in' };
        let gridElement = document.getElementById('report-print');
        drawDOM(gridElement, { 
            margin, 
            scale: .75,
            title: 'Report',
            paperSize: "auto",
            multiPage: true, 
            repeatHeaders: true,
            landscape: false,
            keepTogether: ".prevent-split",
            template: (props) => ReportTemplate({...props, master, Report, columns, pivotBy}), 
        }).then((group) => {
            return exportPDF(group);
            
        }).then((dataUri) => {
            this.setState({ printPDF: dataUri, menuEl: null });
        });
    }

    render() {
        const { master, searchText, securables, user } = this.props;
        const { reportOpen, expandAll, menuEl, results, component, loading } = this.state;
        const data = this.getFilteredArray(results || [], searchText);
        const accessLevel = _.find(securables, { Securable: "reporting" });
        if (!component) {
            return '';
        }
        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <React.Fragment>
                        <FuseAnimate animation="transition.slideLeftBigIn" delay={300}>
                            <div>
                                <ReportDialog edit={true} component={reportOpen ? { ...component } : null} open={reportOpen} onClose={() => this.setState({ ...this.state, reportOpen: false })} onDelete={this.props.removeReport} />
                                <Typography variant="h6" style={{ alignItems: 'center', width: 'calc(100% - 48px)' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon onClick={() => this.props.setReport(null)} color="primary" className="text-32 mr-12 cursor-pointer">keyboard_arrow_left</Icon>{component.Name}</Typography>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: -8,
                                }}
                                    onClick={this.openMenu}
                                >
                                    <Icon>more_vert</Icon>
                                </IconButton>

                                <Menu id="report_menu" anchorEl={menuEl} open={Boolean(menuEl)} onClose={this.closeMenu}>
                                    {this.canGetData() &&
                                        <MenuItem onClick={() => { this.getData(); this.closeMenu(); }}><Icon className="mr-8">refresh</Icon>Refresh</MenuItem>
                                    }
                                    <MenuItem onClick={() => { this.props.toggle(); this.closeMenu(); }}><Icon className="mr-8">tune</Icon>Set Parameters</MenuItem>
                                    {((accessLevel && accessLevel.AccessLevel === "E" && user === component.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) &&
                                        <MenuItem onClick={() => { this.setState({ ...this.state, reportOpen: true, menuEl: null }); }}><Icon className="mr-8">edit</Icon>Edit Report</MenuItem>
                                    }
                                    <MenuItem onClick={() => { this.setState({ ...this.state, expandAll: !expandAll, menuEl: null }); }}><Icon className="mr-8">keyboard_arrow_{expandAll ? 'up' : 'down'}</Icon>{expandAll ? 'Collapse' : 'Expand'} All Rows</MenuItem>
                                    <MenuItem onClick={this.printPDF}><Icon className="mr-8">share</Icon>Share Report</MenuItem>
                                    <MenuItem onClick={() => {
                                        const recs = this.state.reportRef.getResolvedState();
                                        const columns = recs.allVisibleColumns;
                                        const sorted = recs.sortedData;
                                        const built = this.buildExport(columns, sorted, []);
                                        window["warn"]('Table Data: ', recs, sorted, built);
                                        this.exportData(built);
                                        this.closeMenu();
                                    }}><Icon className="mr-8">save_alt</Icon>Export to CSV</MenuItem>
                                </Menu>
                                
                                <ReportPDFDialog 
                                    master={master}
                                    component={component}
                                    expandAll={this.state.expandAll}
                                    printPDF={this.state.printPDF} 
                                    onClose={() => this.setState({ printPDF: null, menuEl: null })}
                                />
                                
                                <PivotTable 
                                    maxHeight="calc(100vh - 264px)" 
                                    hidden={!component || !data || data.length < 1} 
                                    expanded={expandAll} 
                                    getTable={(reportRef) => { 
                                        if (!this.state.reportRef) { 
                                            this.setState({ ...this.state, reportRef }) 
                                        } 
                                    }} 
                                    results={[...data]} 
                                    options={{ ...component }} 
                                    viewer={true} 
                                    onDelete={((accessLevel && accessLevel.AccessLevel === "E" && user === component.AddedBy) || (accessLevel && accessLevel.AccessLevel === "F")) ? (rpt) => this.deleteReport(rpt) : undefined} 
                                />
                            </div>
                        </FuseAnimate>
                        {loading &&
                            <div className="loader stretchBar" style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                margin: 'auto',
                                width: '100%',
                            }}>
                                <div className="rect1"></div>
                                <div className="rect2"></div>
                                <div className="rect3"></div>
                                <div className="rect4"></div>
                                <div className="rect5"></div><br />
                                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
                            </div>
                        }
                        {!loading && data.length === 0 &&
                            <div className="loader stretchBar" style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                margin: 'auto',
                                width: '100%',
                            }}>
                                <Typography variant="h6" className="w-full text-center text-16 sm:text-20 text-grey-light">No Results Found</Typography>
                            </div>
                        }
                    </React.Fragment>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        getData: Actions.getData,
        setData: Actions.setData,
        setReport: Actions.setReport,
        removeReport: Actions.removeReport,
        showMessage
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        List: spReducers.companies.List,
        divisions: spReducers.divisions,
        results: reportingApp.reporting.entities,
        loading: reportingApp.reporting.loading,
        searchText: reportingApp.reporting.searchText,
        component: reportingApp.reporting.selectedReport,
        master: spReducers,
        securables: spReducers.userProfiles.User.Data.Securables,
        user: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportViewer)));
