import axios from 'axios/index'
import _ from 'lodash'
import moment from 'moment'
import { showMessage } from 'store/actions'

export const SET_SUPERVISOR_DATA = '[SPCONNECTION] SET SUPERVISOR DATA'
export const SET_SUPERVISOR_DATE = '[SPCONNECTION] SET SUPERVISOR DATE'
export const SET_SUPERVISOR_LOADING = '[SPCONNECTION] SET SUPERVISOR LOADING'

export function getSupervisorData(Co, newDate, bool) {
  return (dispatch, getState) => {
    const state = getState()
    const dt = newDate
      ? moment(newDate).format('M/D/YYYY')
      : moment().format('M/D/YYYY')
    const start = moment(new Date(dt))
      .locale('us')
      .startOf('isoweek')
      .format('M/D/YYYY')
    const { date } = state.spReducers.supervisor
    const oldStart = moment(state.spReducers.supervisor.start)
      .locale('us')
      .startOf('isoweek')
      .format('M/D/YYYY')
    if (start !== oldStart || bool) {
      const request = axios.get(
        `${window['apiLocation']}/api/Team?Co=${Co}&Date=${encodeURIComponent(
          dt,
        )}`,
      )
      dispatch({
        type: SET_SUPERVISOR_DATE,
        date: new Date(dt),
      })
      dispatch({
        type: SET_SUPERVISOR_LOADING,
        loading: true,
      })
      request
        .then(response => {
          const { Timecards, Punch, Labor } = response.data

          if (Timecards && _.isArray(Timecards)) {
            Timecards.map(timecard => {
              timecard.Data = {
                Punch: _.filter(
                  Punch,
                  o =>
                    o.Employee === timecard.Employee &&
                    o.Date === timecard.Date,
                ),
                Labor: _.filter(
                  Labor,
                  o =>
                    o.Employee === timecard.Employee &&
                    o.Date === timecard.Date,
                ),
              }
            })
            dispatch({
              type: SET_SUPERVISOR_DATA,
              payload: response.data,
              start,
            })
          }
        })
        .catch(error => {
          dispatch(
            showMessage({
              message: `Error: ${error}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        })
    } else {
      dispatch({
        type: SET_SUPERVISOR_DATE,
        date: new Date(dt),
      })
    }
  }
}

export function setSupervisorData(data) {
  return {
    type: SET_SUPERVISOR_DATA,
    payload: data,
  }
}
