import axios from 'axios/index';

export const SET_INVENTORY_LOCATION_DATA = '[SPCONNECTION] SET INVENTORY LOCATION DATA';
export const GET_INVENTORY_LOCATION_DATA = '[SPCONNECTION] GET INVENTORY LOCATION DATA';
export const SUBSCRIBE_INVENTORY_LOCATION_LIST = '[SPCONNECTION] SUBSCRIBE INVENTORY LOCATION LIST';

export function getInitialInventoryLocationData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryLocation`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getInventoryLocationData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryLocation`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_INVENTORY_LOCATION_DATA,
                payload: response.data
            })
        });
    }
}

export function setInventoryLocationData(data) {
    return {
        type: SET_INVENTORY_LOCATION_DATA,
        payload: data
    };
}