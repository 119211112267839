import {combineReducers} from 'redux';
import rateTemplates from './rate-templates.reducer';
import user from './user.reducer';

const rateTemplatesAppReducers = combineReducers({
    rateTemplates,
    user
});

export default rateTemplatesAppReducers;
