import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Chip, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import axios from 'axios';
import classNames from 'classnames';
import CallQueueMembersList from 'main/content/apps/call-queues/CallQueueMembersList';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import Recorder from 'recorder-js';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newCallQueueState = {
    ID: null,
    Co: null,
    Queue: '',
    Description: '',
    DefaultBusinessUnit: null,
    DefaultDivision: null,
    DefaultDepartment: null,
    Notes: '',
    value: 0,
    OnAcceptedMessage: '',
    OnAcceptedMessageClip: '',
    OnAcceptedMessageType: 'voice',
    OnQueuedMessage: '',
    OnQueuedMessageClip: '',
    OnQueuedMessageType: 'voice',
    VocalPitch: 'medium',
    RateOfSpeech: 'medium',
    Volume: 'medium',
    OnAcceptedVoice: '',
    WaitFileID: null,
    TransferFileID: null,
    AcceptedFileID: null,
    hasRecordedAudio: false,
    isRecordingAudio: false,
    recordedAudioFileName: '',
    FallbackAction: null,
    FallbackAudioFileID: null,
    FallbackDial: null,
    FallbackTextAction: null,
    FallbackTextMessage: null,
    FallbackTextGather: null,
    FallbackVoiceMailbox: null,
    RecordFallbackDialYN: null,
    RecordYN: 'Y',
    Phone: '',
    Data: {
        ErrMsg: null,
        // HoldMusic: [],
        // AudioClips: [],
    },
    TextEnabledYN: null,
    OnQueuedTextMessage: null,
    member: {
        ID: null,
        Queue: null,
        UserName: null,
        Rating: 0,
    }
};

const rates = { "x-slow": .35, "slow": .45, "medium": .55, "fast": .6, "x-fast": .65 };
const pitches = { "x-low": .43, "low": .47, "medium": .5, "high": 0.53, "x-high": 0.56 };
const volumes = { "x-soft": .25, "soft": .5, "medium": .75, "loud": .9, "'x-loud": 1 };

const API_KEY = process.env.REACT_APP_MAP_KEY;
const GOOGLE_TEXT_TO_SPEECH_URL = 'https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=' + API_KEY
const SIP_ENDPOINT = '';

class CallQueuesDialog extends Component {
    state = { ...newCallQueueState };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newCallQueueState.Co = this.props.Co;
        const initialState = _.cloneDeepWith(newCallQueueState);
        if (!prevProps.CallQueueDialog.props.open && this.props.CallQueueDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.CallQueueDialog.type === 'edit' &&
                this.props.CallQueueDialog.data &&
                !_.isEqual(this.props.CallQueueDialog.data, prevState)) {
                const data = this.props.CallQueueDialog.data;
                this.setState({ ...initialState, ...this.props.CallQueueDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.CallQueueDialog.type === 'new' &&
                !_.isEqual(newCallQueueState, prevState)) {
                this.setState({ ...initialState, });
            }
        }
        //window["log"]('STATE COMPARISON', this.props.CallQueueDialog, prevProps, prevState);
        if (prevProps.CallQueueDialog.props.open && this.props.CallQueueDialog.props.open) {
            if (this.props.CallQueueDialog.type === 'data' &&
                this.props.CallQueueDialog.data &&
                !_.isEqual(this.props.CallQueueDialog.data, prevState)) {
                const data = this.props.CallQueueDialog.data;
                this.props.CallQueueDialog.type = 'edit';
                this.setState({ ...this.state, ...this.props.CallQueueDialog.data });
            }
        }
        if (prevProps.CallQueueDialog.props.open && this.props.CallQueueDialog.props.open) {
            if (this.props.CallQueueDialog.type === 'add' &&
                this.props.CallQueueDialog.data &&
                !_.isEqual(this.props.CallQueueDialog.data, prevState)) {
                const data = this.props.CallQueueDialog.data;
                this.props.showMessage({
                    message: `CallQueue ${this.props.CallQueueDialog.data.CallQueue} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.CallQueueDialog.type = 'edit';
                this.setState({ ...this.state, ...this.props.CallQueueDialog.data });
            }
        }
        if (prevProps.CallQueueDialog.props.open && this.props.CallQueueDialog.props.open) {
            if (this.props.CallQueueDialog.type === 'update' &&
                this.props.CallQueueDialog.data &&
                !_.isEqual(this.props.CallQueueDialog.data, prevState)) {
                const data = this.props.CallQueueDialog.data;
                this.props.showMessage({
                    message: `CallQueue ${this.props.CallQueueDialog.data.CallQueue} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.CallQueueDialog.type = 'edit';
                this.setState({ ...this.state, ...this.props.CallQueueDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.CallQueueDialog.type === 'edit' ? this.props.closeEditCallQueueDialog() : this.props.closeNewCallQueueDialog();
    };

    canBeSubmitted() {
        const { Queue, Description } = this.state;
        return (
            Queue.length > 0 && Description.length > 0
        );
    }

    parseSSML = () => {
        const { OnAcceptedMessage } = this.state;
        const ssml = OnAcceptedMessage || '';
        var tags = [];
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        for (var i = 0; i < nodes.length; i++) {
            var tag = {};
            var node = nodes[i];
            if (node.childNodes && node.childNodes.length > 0) {
                tag.Say = node.childNodes[0].nodeValue;
                tag.Attributes = '';
                for (var a = 0; a < node.attributes.length; a++) {
                    tag[node.attributes[a].name] = node.attributes[a].value;
                    tag.Attributes += ` ${node.attributes[a].name}=${node.attributes[a].value} `;
                }
                tags.push(tag);
            }
        }
        window["warn"](tags, ssml, nodes, SSML);
        return tags;
    }

    buildSSML = (tags) => {
        let OnAcceptedMessage = '';
        for (var i = 0; i < tags.length; i++) {
            const tag = tags[i];
            OnAcceptedMessage += `<prosody pitch="${tag.pitch ? tag.pitch : 'medium'}" rate="${tag.rate ? tag.rate : 'medium'}" volume="${tag.volume ? tag.volume : 'medium'}">${tag.Say}</prosody>`;
        }
        return OnAcceptedMessage;
    }


    parseQueuedSSML = () => {
        const { OnQueuedMessage } = this.state;
        const ssml = OnQueuedMessage || '';
        var tags = [];
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        for (var i = 0; i < nodes.length; i++) {
            var tag = {};
            var node = nodes[i];
            if (node.childNodes && node.childNodes.length > 0) {
                tag.Say = node.childNodes[0].nodeValue;
                tag.Attributes = '';
                for (var a = 0; a < node.attributes.length; a++) {
                    tag[node.attributes[a].name] = node.attributes[a].value;
                    tag.Attributes += ` ${node.attributes[a].name}=${node.attributes[a].value} `;
                }
                tags.push(tag);
            }
        }
        window["warn"](tags, ssml, nodes, SSML);
        return tags;
    }

    buildQueuedSSML = (tags) => {
        let OnQueuedMessage = '';
        for (var i = 0; i < tags.length; i++) {
            const tag = tags[i];
            OnQueuedMessage += `<prosody pitch="${tag.pitch ? tag.pitch : 'medium'}" rate="${tag.rate ? tag.rate : 'medium'}" volume="${tag.volume ? tag.volume : 'medium'}">${tag.Say}</prosody>`;
        }
        return OnQueuedMessage;
    }

    speak = async () => {
        const { OnAcceptedMessage, Phone, } = this.state;
        const { user } = this.props;

        var value = {
            UserName: user.UserName,
            SSML: OnAcceptedMessage,
            Voice: 'man',
            Data: {
                ErrMsg: '',
            }
        };

        const request = axios.put(`${window["apiLocation"]}/api/Speech?Phone=${encodeURIComponent(Phone)}`, value);
        request.then((r) => { window["warn"](r); this.props.setAwaitingCallFrom(Phone); });
    }

    addClip = () => {
        let { OnAcceptedMessage, OnAcceptedMessageClip, RateOfSpeech, VocalPitch, Volume } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: OnAcceptedMessageClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.push(tag);
        const newOnAcceptedMessage = this.buildSSML(tags);
        this.setState({ ...this.state, OnAcceptedMessage: newOnAcceptedMessage, OnAcceptedMessageClip: '', });
    }

    saveClip = () => {
        let { OnAcceptedMessage, OnAcceptedMessageClip, RateOfSpeech, VocalPitch, Volume, EditClip } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: OnAcceptedMessageClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.splice(EditClip, 1, tag);
        const newOnAcceptedMessage = this.buildSSML(tags);
        EditClip = null;
        this.setState({ ...this.state, OnAcceptedMessage: newOnAcceptedMessage, OnAcceptedMessageClip: '', EditClip });
    }

    removeClip = (key) => {
        const tags = this.parseSSML();
        tags.splice(key, 1);
        const newOnAcceptedMessage = this.buildSSML(tags);
        this.setState({ ...this.state, OnAcceptedMessage: newOnAcceptedMessage });
    }

    editClip = (key) => {
        const tags = this.parseSSML();
        const tag = tags[key];
        this.setState({ ...this.state, OnAcceptedMessageClip: tag.Say, RateOfSpeech: tag.rate ? tag.rate : 'medium', VocalPitch: tag.pitch ? tag.pitch : 'medium', Volume: tag.volume ? tag.volume : 'medium', EditClip: key })
    }

    addQueuedClip = () => {
        let { OnQueuedMessage, OnQueuedMessageClip, RateOfSpeech, VocalPitch, Volume } = this.state;
        const tags = this.parseQueuedSSML();
        let tag = { Say: OnQueuedMessageClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.push(tag);
        const newOnQueuedMessage = this.buildQueuedSSML(tags);
        this.setState({ ...this.state, OnQueuedMessage: newOnQueuedMessage, OnQueuedMessageClip: '', });
    }

    saveQueuedClip = () => {
        let { OnQueuedMessage, OnQueuedMessageClip, RateOfSpeech, VocalPitch, Volume, EditQueuedClip } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: OnQueuedMessageClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.splice(EditQueuedClip, 1, tag);
        const newOnQueuedMessage = this.buildQueuedSSML(tags);
        EditQueuedClip = null;
        this.setState({ ...this.state, OnQueuedMessage: newOnQueuedMessage, OnQueuedMessageClip: '', EditQueuedClip });
    }

    removeQueuedClip = (key) => {
        const tags = this.parseQueuedSSML();
        tags.splice(key, 1);
        const newOnQueuedMessage = this.buildQueuedSSML(tags);
        this.setState({ ...this.state, OnQueuedMessage: newOnQueuedMessage });
    }

    editQueuedClip = (key) => {
        const tags = this.parseQueuedSSML();
        const tag = tags[key];
        this.setState({ ...this.state, OnQueuedMessageClip: tag.Say, RateOfSpeech: tag.rate ? tag.rate : 'medium', VocalPitch: tag.pitch ? tag.pitch : 'medium', Volume: tag.volume ? tag.volume : 'medium', EditQueuedClip: key })
    }

    fileSelectedHandler = (event) => {
        const { Data } = this.state;
        const fd = new FormData();
        const fn = event.target.files[0].name;
        const file = new Blob([event.target.files[0]], { type: event.target.files[0].type });
        fd.append('file', file, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.fileUploadProgress.innerHTML = percentCompleted === 100 ? 'Upload Music' : `${percentCompleted}%`;
            }
        };
        axios.post(`${window["apiLocation"]}/api/Attachment/PostHoldMusic?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                // this.addHoldMusic(res.data.Attachment);
            }
        });
    }

    clipFileSelectedHandler = (event) => {
        const { Data } = this.state;
        const fd = new FormData();
        const fn = event.target.files[0].name;
        const file = new Blob([event.target.files[0]], { type: event.target.files[0].type });
        fd.append('file', file, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipFileUploadProgress.innerHTML = percentCompleted === 100 ? 'Upload Message' : `${percentCompleted}%`;
            }
        };
        axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                // this.addAudioClip(res.data.Attachment);
                this.setState({ ...this.state, AcceptedFileID: res.data.Attachment.Attachment });
            }
        });
    }

    // addHoldMusic = (att) => {
    //     const { Data } = this.state;
    //     let { HoldMusic } = Data;
    //     HoldMusic.push(att);
    //     this.setState({ ...this.state, Data: { ...Data, HoldMusic } });
    // }

    // addAudioClip = (att) => {
    //     const { Data } = this.state;
    //     let { AudioClips } = Data;
    //     AudioClips.push(att);
    //     this.setState({ ...this.state, Data: { ...Data, AudioClips } });
    // }

    waitAudioPaused = () => {
        this.setState({ ...this.state, waitAudioPlaying: false });
    }

    waitAudioPlaying = () => {
        this.setState({ ...this.state, waitAudioPlaying: true });
    }

    connectAudioPaused = () => {
        this.setState({ ...this.state, connectAudioPlaying: false });
    }

    connectAudioPlaying = () => {
        this.setState({ ...this.state, connectAudioPlaying: true });
    }

    acceptedAudioPaused = () => {
        this.setState({ ...this.state, acceptedAudioPlaying: false });
    }

    acceptedAudioPlaying = () => {
        this.setState({ ...this.state, acceptedAudioPlaying: true });
    }

    startRecordingAudio = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            this.handleStream(stream);
        })
    }

    handleStream = (stream) => {

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const recorder = new Recorder(audioContext);
        recorder.init(stream);
        this.setState({ ...this.state, isRecordingAudio: recorder, audioStream: stream, hasRecordedAudio: false }, () => { recorder.start(); });
    }

    stopRecordingAudio = () => {
        const { isRecordingAudio, audioStream } = this.state;
        isRecordingAudio.stop().then(({ blob, buffer }) => {
            this.recordedAudioPlayback.src = URL.createObjectURL(blob);
            audioStream.getTracks()[0].stop();
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: blob, audioStream: null });
        });
    }

    deleteRecordedAudio = () => {
        this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false });
    }

    uploadRecordedAudio = () => {
        const { hasRecordedAudio, recordedAudioFileName } = this.state;
        const fd = new FormData();
        const fn = `${recordedAudioFileName}.wav`;
        fd.append('file', hasRecordedAudio, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipUploadProgress.innerHTML = percentCompleted === 100 ? '' : `${percentCompleted}%`;
            }
        };
        const request = axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                // this.addAudioClip(res.data.Attachment);
            }
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false });
        });
    }

    clipError = (e) => {
        window["warn"](e);
    }

    createAgent = () => {
        const { general, queues } = this.state
        const { Co } = this.props;
        let { member, Queue } = this.state;
        member = {
            ...member,
            Co,
            Queue
        }
        const initialState = _.cloneDeepWith(newCallQueueState);
        axios.post(`${window["apiLocation"]}/api/UserSettings/PostCallQueueAgent`, member).then((response) => {
            const { Agents } = this.state.Data;
            Agents.push(response.data);
            this.setState({ ...this.state, member: { ...initialState.member }, Data: { ...this.state.Data, Agents } });
        });
    }

    updateAgent = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { member } = this.state;
        const initialState = _.cloneDeepWith(newCallQueueState);
        axios.post(`${window["apiLocation"]}/api/UserSettings/UpdateCallQueueAgent?Co=${Co}&UserName=${encodeURIComponent(member.UserName)}&Queue=${member.Queue}`, member).then((response) => {
            const { Agents } = this.state.Data;
            let index = _.findIndex(Agents, { UserName: response.data.UserName });
            Agents.splice(index, 1, response.data);
            this.setState({ ...this.state, member: { ...initialState.member }, Data: { ...this.state.Data, Agents } });
        });
    }

    removeAgent = () => {
        let { general } = this.state;
        const { Co } = this.props;
        const { member } = this.state;
        const initialState = _.cloneDeepWith(newCallQueueState);
        axios.delete(`${window["apiLocation"]}/api/UserSettings/DeleteCallQueueAgent?Co=${Co}&UserName=${encodeURIComponent(member.UserName)}&Queue=${member.Queue}`, member).then((response) => {
            const { Agents } = this.state.Data;
            let index = _.findIndex(Agents, { UserName: response.data.UserName });
            Agents.splice(index, 1);
            this.setState({ ...this.state, member: { ...initialState.member }, Data: { ...this.state.Data, Agents } });
        });
    }

    render() {
        const { classes, securables, theme, CallQueueDialog, addCallQueue, updateCallQueue, voiceMailboxes, removeCallQueue, businessUnits, divisions, departments, queues, awaitingCallFrom, setAwaitingCallFrom, AudioClips, HoldMusic, users } = this.props
        const { Co, BusinessUnit, Phone, value, OnAcceptedMessage, EditClip, EditQueuedClip, Data, member } = this.state;
        const SSMLTags = this.parseSSML();
        const QueuedSSMLTags = this.parseQueuedSSML();
        const accessLevel = _.find(securables, { Securable: "call-queues" });
        const userAvatar = '../../assets/images/avatars/profile.jpg';

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...CallQueueDialog.props}
                        open={Boolean(accessLevel) && CallQueueDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(CallQueueDialog.type) > -1 ? 'New Call Queue' : `${this.state.Queue} Queue Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>queue_music</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Agents ? this.state.Data.Agents.length : 0} color="error"><Icon>contact_phone</Icon></Badge>} label="Agents" disabled={!this.state.ID} />
                                {/* <Tab disableRipple icon={<AttachFile />} label="Calls" /> */}
                            </Tabs>
                        </AppBar>
                        {value === 0 && <TabContainer>
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <DialogContent classes={{ root: "p-0 sm:p-24" }}>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">queue_music</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Queue"
                                            id="queue"
                                            name="Queue"
                                            value={this.state.Queue}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            disabled={Boolean(this.state.ID)}
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Default Business Unit"
                                            id="defaultBusinessUnit"
                                            select
                                            name="DefaultBusinessUnit"
                                            value={this.state.DefaultBusinessUnit || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: this.state.DefaultBusinessUnit ? true : false }}
                                            placeholder="Select Business Unit"
                                        >
                                            {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value, index) => (
                                                <MenuItem key={index} value={value.BusinessUnit}>
                                                    {value.Description}
                                                </MenuItem>
                                            )) : '')}
                                        </TextField>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Default Division"
                                            id="defaultDivision"
                                            select
                                            name="DefaultDivision"
                                            value={this.state.DefaultDivision || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: this.state.DefaultDivision ? true : false }}
                                            placeholder="Select Division"
                                        >
                                            {(this.state.DefaultBusinessUnit && this.state.DefaultBusinessUnit.length > 0 ?
                                                (divisions && divisions.length > 0 ? divisions.map((value) => (
                                                    (this.state.DefaultBusinessUnit == value.BusinessUnit ?
                                                        <MenuItem value={value.Division}>
                                                            {value.Description}
                                                        </MenuItem> : '')
                                                )) : '')
                                                :
                                                <MenuItem value={null}>
                                                    Please Select a Business Unit
                                                </MenuItem>
                                            )
                                            }
                                        </TextField>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Default Department"
                                            id="defaultDepartment"
                                            select
                                            name="DefaultDepartment"
                                            value={this.state.DefaultDepartment || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: this.state.DefaultDepartment ? true : false }}
                                            placeholder="Select Department"
                                        >
                                            {(this.state.DefaultDivision && this.state.DefaultDivision.length > 0 && this.state.DefaultBusinessUnit && this.state.DefaultBusinessUnit.length > 0 ?
                                                (departments && departments.length > 0 ? departments.map((value) => (
                                                    (this.state.DefaultDivision == value.Division && this.state.DefaultBusinessUnit == value.BusinessUnit ?
                                                        <MenuItem value={value.Department}>
                                                            {value.Description}
                                                        </MenuItem> : '')
                                                )) : '')
                                                :
                                                <MenuItem value={null}>
                                                    Please Select a Division
                                                </MenuItem>
                                            )
                                            }
                                        </TextField>
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">chat_bubble_outline</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>


                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">audiotrack</Icon>
                                        </div>
                                        <div className="w-full">
                                            <div className="sm:flex">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.formControl, "w-full text-12 pt-16 pb-16")}
                                                    onClick={() => this.clipFileInput.click()}
                                                >
                                                    <Icon className="mr-4">cloud_upload</Icon> <span ref={clipFileUploadProgress => this.clipFileUploadProgress = clipFileUploadProgress}>Upload Message</span>
                                                </Button>
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={this.clipFileSelectedHandler}
                                                    ref={clipFileInput => this.clipFileInput = clipFileInput}
                                                    accept=".mp3,.wav"
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.formControl, "w-full text-12 pt-16 pb-16")}
                                                    onClick={() => this.fileInput.click()}
                                                // disabled={!this.state.ID}
                                                >
                                                    <Icon className="mr-4">cloud_upload</Icon> <span ref={fileUploadProgress => this.fileUploadProgress = fileUploadProgress}>Upload Music</span>
                                                </Button>
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={this.fileSelectedHandler}
                                                    ref={fileInput => this.fileInput = fileInput}
                                                    accept=".mp3,.wav"
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {(!this.state.isRecordingAudio && !this.state.hasRecordedAudio) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classNames(classes.formControl, "w-full text-12 pt-16 pb-16")}
                                                        // style={{ minWidth: 100 }}
                                                        onClick={this.startRecordingAudio}
                                                    >
                                                        <Icon className="mr-4">mic_none</Icon> Record Audio
                                                    </Button>
                                                    :
                                                    !this.state.hasRecordedAudio ?
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            className={classNames(classes.formControl, "w-full text-12 pt-16 pb-16")}
                                                            // style={{ minWidth: 100 }}
                                                            onClick={this.stopRecordingAudio}
                                                        >
                                                            <Icon className="mr-4">stop</Icon> Stop Recording
                                                        </Button>
                                                        :
                                                        <div className="flex w-full">
                                                            <Tooltip title="Upload Message Clip">
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    className={classNames(classes.formControl, "w-full text-12 pt-16 pb-16")}
                                                                    // style={{ minWidth: 200 }}
                                                                    onClick={this.uploadRecordedAudio}
                                                                >
                                                                    <Icon className="mr-4">cloud_upload</Icon> <span ref={clipUploadProgress => this.clipUploadProgress = clipUploadProgress}></span>
                                                                </Button>
                                                            </Tooltip>
                                                            <div className="min-w-12 pt-20">
                                                            </div>
                                                            <Tooltip title="Delete Audio Clip">
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classNames(classes.formControl, "w-full text-12 pt-16 pb-16")}
                                                                    // style={{ minWidth: 200 }}
                                                                    onClick={this.deleteRecordedAudio}
                                                                >
                                                                    <Icon className="mr-4">delete</Icon>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                }
                                            </div>
                                            {
                                                (this.state.isRecordingAudio || this.state.hasRecordedAudio) &&
                                                <div className="sm:flex">
                                                    <TextField
                                                        className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")}
                                                        label="Clip Name"
                                                        id="clipName"
                                                        name="recordedAudioFileName"
                                                        value={this.state.recordedAudioFileName || ''}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(this.state.isRecordingAudio)}
                                                    />
                                                    <div className="hidden sm:block min-w-12 pt-20">
                                                    </div>
                                                    <audio className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")} onError={this.clipError} controls style={{ width: '100%' }} ref={(ref) => { this.recordedAudioPlayback = ref }} />
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">record_voice_over</Icon>
                                        </div>

                                        {
                                            this.state.OnQueuedMessageType !== 'text' &&
                                            <React.Fragment>
                                                <div className="w-full">
                                                    <div className="sm:flex w-full">
                                                        <TextField
                                                            className={classNames(classes.formControl, "w-full")}
                                                            label="Queued Message Type"
                                                            id="onQueuedMessageType"
                                                            select
                                                            name="OnQueuedMessageType"
                                                            value={this.state.OnQueuedMessageType || ''}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            // style={{ minWidth: 200 }}
                                                            placeholder="Queued Message Type"
                                                        >
                                                            <MenuItem value="voice">
                                                                Audio Recording
                                                            </MenuItem>
                                                            <MenuItem value="text">
                                                                Text-to-Speech
                                                            </MenuItem>
                                                        </TextField>
                                                    </div>
                                                </div>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <div className="w-full">
                                                    <div className="sm:flex w-full">
                                                        <div className="w-full">
                                                            <div className="sm:flex">
                                                                <Autocomplete
                                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                                    title="Queued Audio"
                                                                    options={AudioClips ? [{ Attachment: null, FileName: 'None' }, ...AudioClips] : [{ Attachment: null, FileName: 'Default' }]}
                                                                    menuItemComponent={(value) => {
                                                                        return <MenuItem value={value.Attachment}>
                                                                            {value.FileName}
                                                                        </MenuItem>
                                                                    }}
                                                                    portal={true}
                                                                    value={this.state.QueuedFileID || ''}
                                                                    onSelect={(option) => this.setState({ ...this.state, QueuedFileID: option.Attachment })}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                this.state.QueuedFileID &&
                                                                <audio className={classes.formControl} controls style={{ width: '100%' }} ref="queuedAudioPlay" onError={this.clipError} src={`${window["apiLocation"]}/api/AudioFile/${this.state.QueuedFileID}.wav`} onPause={this.queuedAudioPaused} onPlay={this.queuedAudioPlaying} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.OnQueuedMessageType == 'text' &&
                                            <React.Fragment>
                                                <TextField
                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                    label="Queued Message Type"
                                                    id="onQueuedMessageType"
                                                    select
                                                    name="OnQueuedMessageType"
                                                    value={this.state.OnQueuedMessageType || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ minWidth: 200 }}
                                                    placeholder="Queued Message Type"
                                                >
                                                    <MenuItem value="voice">
                                                        Audio Recording
                                                    </MenuItem>
                                                    <MenuItem value="text">
                                                        Text-to-Speech
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                    label="Queued Message"
                                                    id="OnQueuedMessage"
                                                    name="OnQueuedMessageClip"
                                                    value={this.state.OnQueuedMessageClip || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Rate"
                                                    id="rate"
                                                    select
                                                    name="RateOfSpeech"
                                                    value={this.state.RateOfSpeech || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ width: 200 }}
                                                    placeholder="Rate of Speech"
                                                >
                                                    <MenuItem value="x-slow">
                                                        x-slow
                                                    </MenuItem>
                                                    <MenuItem value="slow">
                                                        Slow
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="fast">
                                                        Fast
                                                    </MenuItem>
                                                    <MenuItem value="x-fast">
                                                        X-Fast
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Pitch"
                                                    id="vocalPitch"
                                                    select
                                                    name="VocalPitch"
                                                    value={this.state.VocalPitch || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ width: 200 }}
                                                    placeholder="Vocal Pitch"
                                                >
                                                    <MenuItem value="x-low">
                                                        X-Low
                                                    </MenuItem>
                                                    <MenuItem value="low">
                                                        Low
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="high">
                                                        High
                                                    </MenuItem>
                                                    <MenuItem value="x-high">
                                                        X-High
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Volume"
                                                    id="volume"
                                                    select
                                                    name="Volume"
                                                    value={this.state.Volume || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ width: 200 }}
                                                    placeholder="Volume"
                                                >
                                                    <MenuItem value="x-soft">
                                                        X-Soft
                                                    </MenuItem>
                                                    <MenuItem value="soft">
                                                        Soft
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="loud">
                                                        Loud
                                                    </MenuItem>
                                                    <MenuItem value="x-loud">
                                                        X-Loud
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {(!EditQueuedClip && EditQueuedClip !== 0) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.formControl}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.addQueuedClip}
                                                        disabled={!this.state.OnQueuedMessageClip || this.state.OnQueuedMessageClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">add_circle_outline</Icon> Add
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.formControl}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.saveQueuedClip}
                                                        disabled={!this.state.OnQueuedMessageClip || this.state.OnQueuedMessageClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">save</Icon> Save
                                                    </Button>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>

                                    {
                                        this.state.OnQueuedMessageType == 'text' &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            </div>
                                            <div style={{ width: '100%', marginBottom: 24, marginTop: -12 }}>
                                                {
                                                    QueuedSSMLTags.map((value, key) =>
                                                        <Tooltip title={value.Attributes} placement="top">
                                                            <Chip key={key} className="mb-8 mr-4" color="primary" icon={<Icon>record_voice_over</Icon>} label={value.Say} onClick={() => { this.editQueuedClip(key); }} onDelete={() => { this.removeQueuedClip(key); }} />
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                            {/* <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.formControl}
                                                style={{ minWidth: 100, marginTop: -12 }}
                                                onClick={this.speak}
                                                disabled={SSMLTags.length < 1 || awaitingCallFrom == Phone}
                                            >
                                                {awaitingCallFrom == Phone ? <CircularProgress color="inherit" /> : <React.Fragment><Icon className="mr-4">play_circle_outline</Icon> Play</React.Fragment>}
                                            </Button> */}
                                        </div>
                                    }
                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">playlist_play</Icon>
                                        </div>
                                        <div className="w-full">
                                            <Autocomplete
                                                className={classNames(classes.formControl, "w-full text-12")}
                                                title="Hold Music"
                                                options={HoldMusic ? [{ Attachment: null, FileName: 'Default' }, ...HoldMusic] : [{ Attachment: null, FileName: 'Default' }]}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.Attachment}>
                                                        {value.FileName}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.WaitFileID || ''}
                                                onSelect={(option) => this.setState({ ...this.state, WaitFileID: option.Attachment })}
                                                required
                                            />
                                            {
                                                this.state.WaitFileID &&
                                                <audio className={classes.formControl} controls style={{ width: '100%' }} ref="waitAudioPlay" src={`${window["apiLocation"]}/api/AudioFile/${this.state.WaitFileID}.wav`} onPause={this.waitAudioPaused} onPlay={this.waitAudioPlaying} />
                                            }
                                        </div>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <div className="w-full">
                                            <Autocomplete
                                                className={classNames(classes.formControl, "w-full text-12")}
                                                title="Connect Music"
                                                options={HoldMusic ? [{ Attachment: null, FileName: 'Default' }, ...HoldMusic] : [{ Attachment: null, FileName: 'Default' }]}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.Attachment}>
                                                        {value.FileName}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.TransferFileID || ''}
                                                onSelect={(option) => this.setState({ ...this.state, TransferFileID: option.Attachment })}
                                                required
                                            />
                                            {
                                                this.state.TransferFileID &&
                                                <audio className={classes.formControl} controls style={{ width: '100%' }} ref="connectAudioPlay" src={`${window["apiLocation"]}/api/AudioFile/${this.state.TransferFileID}.wav`} onPause={this.connectAudioPaused} onPlay={this.connectAudioPlaying} />
                                            }
                                        </div>
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">record_voice_over</Icon>
                                        </div>

                                        {
                                            this.state.OnAcceptedMessageType == 'voice' &&
                                            <React.Fragment>
                                                <div className="w-full">
                                                    <div className="sm:flex w-full">
                                                        <TextField
                                                            className={classNames(classes.formControl, "w-full")}
                                                            label="Connecting Message Type"
                                                            id="onAcceptedMessageType"
                                                            select
                                                            name="OnAcceptedMessageType"
                                                            value={this.state.OnAcceptedMessageType || ''}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            // style={{ minWidth: 200 }}
                                                            placeholder="Connecting Message Type"
                                                        >
                                                            <MenuItem value="voice">
                                                                Audio Recording
                                                            </MenuItem>
                                                            <MenuItem value="text">
                                                                Text-to-Speech
                                                            </MenuItem>
                                                        </TextField>
                                                    </div>
                                                </div>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <div className="w-full">
                                                    <div className="sm:flex w-full">
                                                        <div className="w-full">
                                                            <div className="sm:flex">
                                                                <Autocomplete
                                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                                    title="Connecting Message"
                                                                    options={AudioClips ? [{ Attachment: null, FileName: 'None' }, ...AudioClips] : [{ Attachment: null, FileName: 'Default' }]}
                                                                    menuItemComponent={(value) => {
                                                                        return <MenuItem value={value.Attachment}>
                                                                            {value.FileName}
                                                                        </MenuItem>
                                                                    }}
                                                                    portal={true}
                                                                    value={this.state.AcceptedFileID || ''}
                                                                    onSelect={(option) => this.setState({ ...this.state, AcceptedFileID: option.Attachment })}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                this.state.AcceptedFileID &&
                                                                <audio className={classes.formControl} controls style={{ width: '100%' }} ref="acceptedAudioPlay" onError={this.clipError} src={`${window["apiLocation"]}/api/AudioFile/${this.state.AcceptedFileID}.wav`} onPause={this.acceptedAudioPaused} onPlay={this.acceptedAudioPlaying} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.OnAcceptedMessageType == 'text' &&
                                            <React.Fragment>
                                                <TextField
                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                    label="Connecting Message Type"
                                                    id="onAcceptedMessageType"
                                                    select
                                                    name="OnAcceptedMessageType"
                                                    value={this.state.OnAcceptedMessageType || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ minWidth: 200 }}
                                                    placeholder="Connecting Message Type"
                                                >
                                                    <MenuItem value="voice">
                                                        Audio Recording
                                                    </MenuItem>
                                                    <MenuItem value="text">
                                                        Text-to-Speech
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                    label="Connecting Message"
                                                    id="OnAcceptedMessage"
                                                    name="OnAcceptedMessageClip"
                                                    value={this.state.OnAcceptedMessageClip || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Rate"
                                                    id="rate"
                                                    select
                                                    name="RateOfSpeech"
                                                    value={this.state.RateOfSpeech || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ width: 200 }}
                                                    placeholder="Rate of Speech"
                                                >
                                                    <MenuItem value="x-slow">
                                                        x-slow
                                                    </MenuItem>
                                                    <MenuItem value="slow">
                                                        Slow
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="fast">
                                                        Fast
                                                    </MenuItem>
                                                    <MenuItem value="x-fast">
                                                        X-Fast
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Pitch"
                                                    id="vocalPitch"
                                                    select
                                                    name="VocalPitch"
                                                    value={this.state.VocalPitch || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ width: 200 }}
                                                    placeholder="Vocal Pitch"
                                                >
                                                    <MenuItem value="x-low">
                                                        X-Low
                                                    </MenuItem>
                                                    <MenuItem value="low">
                                                        Low
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="high">
                                                        High
                                                    </MenuItem>
                                                    <MenuItem value="x-high">
                                                        X-High
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Volume"
                                                    id="volume"
                                                    select
                                                    name="Volume"
                                                    value={this.state.Volume || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{ width: 200 }}
                                                    placeholder="Volume"
                                                >
                                                    <MenuItem value="x-soft">
                                                        X-Soft
                                                    </MenuItem>
                                                    <MenuItem value="soft">
                                                        Soft
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="loud">
                                                        Loud
                                                    </MenuItem>
                                                    <MenuItem value="x-loud">
                                                        X-Loud
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {(!EditClip && EditClip !== 0) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.formControl || ''}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.addClip}
                                                        disabled={!this.state.OnAcceptedMessageClip || this.state.OnAcceptedMessageClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">add_circle_outline</Icon> Add
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.formControl || ''}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.saveClip}
                                                        disabled={!this.state.OnAcceptedMessageClip || this.state.OnAcceptedMessageClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">save</Icon> Save
                                                    </Button>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>

                                    {
                                        this.state.OnAcceptedMessageType == 'text' &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            </div>
                                            <div style={{ width: '100%', marginBottom: 24, marginTop: -12 }}>
                                                {
                                                    SSMLTags.map((value, key) =>
                                                        <Tooltip title={value.Attributes} placement="top">
                                                            <Chip key={key} className="mb-8 mr-4" color="primary" icon={<Icon>record_voice_over</Icon>} label={value.Say} onClick={() => { this.editClip(key); }} onDelete={() => { this.removeClip(key); }} />
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                            {/* <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.formControl}
                                                style={{ minWidth: 100, marginTop: -12 }}
                                                onClick={this.speak}
                                                disabled={SSMLTags.length < 1 || awaitingCallFrom == Phone}
                                            >
                                                {awaitingCallFrom == Phone ? <CircularProgress color="inherit" /> : <React.Fragment><Icon className="mr-4">play_circle_outline</Icon> Play</React.Fragment>}
                                            </Button> */}
                                        </div>
                                    }

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">phone_missed</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Fallback Action"
                                            id="fallbackAction"
                                            name="FallbackAction"
                                            value={this.state.FallbackAction || ''}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.FallbackAction ? true : false }}
                                            variant="outlined"
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="Hangup">
                                                Hang up
                                            </MenuItem>
                                            <MenuItem value="Dial">
                                                Forward
                                            </MenuItem>
                                            <MenuItem value="Voicemail">
                                                Voicemail
                                            </MenuItem>
                                        </TextField>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        {
                                            this.state.FallbackAction === "Dial" &&
                                            <TextField
                                                className={classes.formControl}
                                                label="Transfer To"
                                                id="fallbackDial"
                                                name="FallbackDial"
                                                value={this.state.FallbackDial || ''}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: this.state.FallbackDial ? true : false }}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        }
                                        {
                                            (this.state.FallbackAction === "Hangup" || this.state.FallbackAction === null) &&
                                            <Autocomplete
                                                className={classNames(classes.formControl, "w-full text-12")}
                                                title="Unavailable Message"
                                                options={AudioClips ? [{ Attachment: null, FileName: 'None' }, ...AudioClips] : [{ Attachment: null, FileName: 'Default' }]}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.Attachment}>
                                                        {value.FileName}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.FallbackAudioFileID || ''}
                                                onSelect={(option) => this.setState({ ...this.state, FallbackAudioFileID: option.Attachment })}
                                                required
                                            />
                                        }
                                        {
                                            this.state.FallbackAction === "Voicemail" &&
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Voice Mailbox"
                                                options={voiceMailboxes}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.VoiceMailbox}>
                                                        {`${value.VoiceMailbox} - ${value.Name}`}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.FallbackVoiceMailbox}
                                                onSelect={(option) => this.setState({ ...this.state, FallbackVoiceMailbox: option.VoiceMailbox })}
                                                required
                                            />
                                        }
                                    </div>
                                    <div className="sm:pl-48 sm:flex justify-between">
                                        <FormControlLabel
                                            classes={{ root: "mb-24" }}
                                            control={
                                                <Switch
                                                    checked={this.state.RecordYN !== 'N'}
                                                    name="RecordYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Record Queued Calls"
                                        />
                                        {
                                            this.state.FallbackAction === "Dial" &&
                                            <FormControlLabel
                                                classes={{ root: "mb-24" }}
                                                control={
                                                    <Switch
                                                        checked={this.state.RecordFallbackDialYN === 'Y'}
                                                        name="RecordFallbackDialYN"
                                                        onChange={this.handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Record Forwarded Calls"
                                            />
                                        }
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">note</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:pl-48 flex mb-24">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.TextEnabledYN === 'Y'}
                                                    name="TextEnabledYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Text Enabled?"
                                        />
                                    </div>
                                    {this.state.TextEnabledYN === 'Y' &&
                                        <React.Fragment>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                                    <Icon color="action">textsms</Icon>
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Queued Text Message"
                                                    id="onQueuedTextMessage"
                                                    name="OnQueuedTextMessage"
                                                    value={this.state.OnQueuedTextMessage || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                                    <Icon color="action">sms_failed</Icon>
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Fallback Text Action"
                                                    id="fallbackTextAction"
                                                    name="FallbackTextAction"
                                                    value={this.state.FallbackTextAction || ''}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{ shrink: this.state.FallbackTextAction ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    select
                                                >
                                                    <MenuItem value={null}>
                                                        Do Nothing
                                                    </MenuItem>
                                                    <MenuItem value="Hangup">
                                                        End Conversation
                                                    </MenuItem>
                                                    <MenuItem value="Gather">
                                                        Gather
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {
                                                    this.state.FallbackTextAction === "Gather" &&
                                                    <Autocomplete
                                                        className={classes.formControl}
                                                        title="Gather"
                                                        options={this.props.textGathers || []}
                                                        menuItemComponent={(value) => {
                                                            return <MenuItem value={value.ID}>
                                                                {value.ID} - {value.Name}
                                                            </MenuItem>
                                                        }}
                                                        portal={true}
                                                        value={this.state.FallbackTextGather || ''}
                                                        onSelect={(option) => this.setState({ ...this.state, FallbackTextGather: option.ID })}
                                                        required
                                                    />
                                                }
                                            </div>
                                            {this.state.FallbackTextAction === "Hangup" &&
                                                <div className="sm:flex">
                                                    <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                                        <Icon color="action">sms</Icon>
                                                    </div>
                                                    <TextField
                                                        className={classes.formControl}
                                                        label="Unavailable Text Message"
                                                        id="fallbackTextMessage"
                                                        name="FallbackTextMessage"
                                                        value={this.state.FallbackTextMessage || ''}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        multiline
                                                        rows={5}
                                                        fullWidth
                                                    />
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </DialogContent>
                            </FuseAnimate>
                        </TabContainer>}
                        {value === 1 &&
                            <TabContainer>
                                {accessLevel && accessLevel.AccessLevel !== "R" &&
                                    <React.Fragment>
                                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">person_add</Icon>{`${!this.state.member.ID ? "Add" : "Update"} Agent`}</Typography>
                                        <div className="sm:flex">
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Agent"
                                                options={users}
                                                menuItemComponent={(value) => {
                                                    return value.ActiveYN === "Y" &&
                                                        <MenuItem value={value.UserName}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.UserName} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${this.state.Co}&ID=${value.Data.Avatar}&Thumb=true` ): userAvatar)} />
                                                                {`${value.FirstName} ${value.LastName}`}
                                                            </div>
                                                        </MenuItem>
                                                }}
                                                value={this.state.member.UserName}
                                                noflip={true}
                                                onSelect={(option) => this.setState({ ...this.state, member: { ...this.state.member, UserName: option.UserName } })}
                                                required
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={classes.formControl}
                                                label="Rating"
                                                id="rating"
                                                name="member.Rating"
                                                value={this.state.member.Rating || 0}
                                                onChange={this.handleChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment placement="end">/10</InputAdornment>,
                                                    type: "number",
                                                    inputProps: { min: 0, max: 10 }
                                                }}
                                                variant="outlined"
                                                required
                                                fullWidth
                                            />
                                            <div className="hidden sm:block min-w-12 pt-20">
                                            </div>
                                            {(!member.ID && _.findIndex(this.state.Data.Agents, { UserName: member.UserName }) < 0) ?
                                                <Button
                                                    className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        const { Co, Queue, member } = this.state;
                                                        this.createAgent();
                                                    }}
                                                // disabled={!this.canMemberBeSubmitted()}
                                                >
                                                    Add
                                                </Button> :
                                                <div className="w-full flex">
                                                    <Button
                                                        className={classNames(classes.formControl, classes.updateMember, "w-full")}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            this.updateAgent();
                                                        }}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        className={classNames(classes.formControl, classes.deleteMember)}
                                                        variant="contained"
                                                        onClick={() => {
                                                            this.removeAgent();
                                                        }}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                <CallQueueMembersList data={this.state.Data.Agents} onSelected={(member) => this.setState({ member })} />
                            </TabContainer>
                        }
                        {value === 0 &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {accessLevel && accessLevel.AccessLevel !== "R" &&
                                    ['new', 'customer'].indexOf(CallQueueDialog.type) > -1 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addCallQueue(rec);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : accessLevel && accessLevel.AccessLevel !== "R" && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            updateCallQueue(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )
                                }
                                <div className="w-1/2 text-right">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}
CallQueuesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditCallQueueDialog: Actions.closeEditCallQueueDialog,
        closeNewCallQueueDialog: Actions.closeNewCallQueueDialog,
        addCallQueue: Actions.addCallQueue,
        updateCallQueue: Actions.updateCallQueue,
        removeCallQueue: Actions.removeCallQueue,
        getFiles: FileActions.getFiles,
        showMessage: showMessage,
        setAwaitingCallFrom: setAwaitingCallFrom,
    }, dispatch);
}

function mapStateToProps({ callQueuesApp, fileManagerApp, spReducers, dialer }) {
    return {
        CallQueueDialog: callQueuesApp.CallQueues.CallQueueDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        queues: spReducers.callHandler.Queues,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        awaitingCallFrom: dialer.awaitingCallFrom,
        AudioClips: spReducers.audioFiles.audioClips,
        HoldMusic: spReducers.audioFiles.holdMusic,
        securables: spReducers.userProfiles.User.Data.Securables,
        textGathers: spReducers.textGathers,
        voiceMailboxes: spReducers.voiceMailboxes,
        users: spReducers.userProfiles.Users
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CallQueuesDialog));
