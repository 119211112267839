import React from 'react';
import { withStyles, Avatar, Badge, Chip, Icon, ListItem, Tooltip, Typography } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import classNames from 'classnames';
import _ from '@lodash';
import { PlayButton, Progress, VolumeControl } from 'react-soundplayer/components';

const styles = theme => ({
    root: {},
    tag: {
        marginRight: 4,
        height: 28,
        borderRadius: 5,
        marginBottom: 4,
    },
    badge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 2,
        right: 8
    },
});

const TagCategory = ({ Co, category, tags, icon, users, classes }) => {
    window["warn"](category, icon, tags);
    return (
        <ListItem dense className="px-0">
            <div className={"w-full pl-12"}>
                <Typography variant="subheading" style={{ alignItems: 'center', }} className="flex"><Badge classes={{ badge: classes.badge }} badgeContent={tags.length} color="error"><Icon color="primary" className="text-24 mr-4">{icon}</Icon></Badge>{category}</Typography>
                <div className="w-full pl-24">
                    {tags.map((tag, index) => (
                        <Chip icon={<Icon>{icon}</Icon>} key={index} label={tag.Title} className={classes.tag} color="primary" />
                    ))}
                </div>

            </div>
        </ListItem>
    )
};

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TagCategory));
