import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class FormsList extends Component {

    state = {
        selectedFormsMenu: null
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {

            return arr;
        }

        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedFormMenu = (event) => {
        this.setState({ selectedFormsMenu: event.currentTarget });
    };

    closeSelectedFormsMenu = () => {
        this.setState({ selectedFormsMenu: null });
    };

    render() {
        const { classes, securables, technicians, users, searchText, openNewFormDialog, openEditFormDialog, Co } = this.props;
        const data = this.getFilteredArray((!this.props.selectedFormData ? (!this.props.formData ? [] : this.props.formData) : this.props.selectedFormData), searchText);

        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "forms" });
        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewFormDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">assignment_turned_in</Icon>Form List</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditFormDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "Form",
                                    accessor: "Form",
                                    width: 64,
                                    className: "font-bold justify-center",
                                },
                                {
                                    Header: "Name",
                                    accessor: "Name",
                                    className: "justify-center",
                                    width: 256,
                                    //filterable: true,
                                },
                                {
                                    Header: "Description",
                                    accessor: "Description",
                                    width: 256,
                                },
                            ]
                        }
                        defaultPageSize={10}
                        noDataText="No Forms found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditFormDialog: Actions.openEditFormDialog,
        openNewFormDialog: Actions.openNewFormDialog
    }, dispatch);
}

function mapStateToProps({ spReducers, formsApp }) {
    return {
        Co: spReducers.companies.Co,
        selectedFormIds: formsApp.forms.selectedFormIds,
        searchText: formsApp.forms.searchText,
        user: formsApp.user,
        formData: spReducers.forms,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FormsList)));
