import { FuseAnimate, FuseUtils } from '@fuse';
import { Avatar, Button, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { openEditInvoiceDialog, buildNewAgreementInvoice, addAgreementInvoice } from '../invoices/store/actions';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class AgreementAmortizationsList extends Component {

    state = {
        selectedAgreementAmortizationsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    launchInvoice = (billing) => {
        const { customer, site, agreement } = this.props;
        this.props.buildNewAgreementInvoice({ Customer: customer, Site: site, Amortization: billing, Agreement: agreement });
    }

    render() {
        const { classes, searchText, data, agreement } = this.props;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">today</Icon>Revenue Amortization Schedule</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        const value = rowInfo.original;
                                        if (value && value.Data && value.Data.Amortization && value.Data.Amortization.Data && value.Data.Amortization.Data.Invoice) {
                                            // this.props.openEditInvoiceDialog(value.Data.Amortization.Data.Invoice);
                                        }
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Cycle",
                                accessor: "Cycle",
                                width: 64,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Sequence",
                                accessor: "Seq",
                                width: 96,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Date",
                                id: "billDate",
                                accessor: row => this.formatDate(row.Data && row.Data.Amortization ? row.Data.Amortization.TransactionDate : row.Date),
                                className: "justify-center",
                                width: 192,
                            },
                            {
                                Header: "Amount",
                                accessor: "Amount",
                                width: 96,
                                className: "justify-center",
                                Cell: row => {
                                    return this.formatDollars(row.original.Amount);
                                }
                            },
                            {
                                Header: "Transaction",
                                id: "processed",
                                accessor: row => {
                                    window["warn"]('Amortization Schedule: ', row);
                                    return row.Data && row.Data.Amortization ? row.Data.Amortization.Transaction : '';
                                },
                                width: 128,
                                className: "justify-center",
                                Cell: row => {
                                    if (row.value > 0) {
                                        return `#${row.value}`;
                                    } else {
                                        return <Button onClick={(e) => { e.stopPropagation(); this.props.createAmortization({ ...row.original }); }} variant="contained" color="primary" className="text-12 h-24">Process</Button>
                                    }
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Revenue Amortizations found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        buildNewAgreementInvoice,
        openEditInvoiceDialog,
        addAgreementInvoice,
        createAmortization: Actions.createAmortization
    }, dispatch);
}

function mapStateToProps({ agreementAmortizationsApp, spReducers }) {
    return {
        user: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementAmortizationsList)));
