import _ from '@lodash';
import { Button, Dialog, DialogActions, DialogContent, Grow, Hidden, Icon, IconButton, Slide, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import ChecklistPDFSection from './ChecklistPDFSection';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, paddingTop: props.paddingTop ? props.paddingTop : props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    paper: {
        minHeight: 'calc(100% - 66px)',
        paddingTop: 16,
        paddingBottom: 64,
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
});
const newChecklistState = {
    scrollLock: true,
    isTechnicianSigning: false,
    TechnicianSignature: null,
    isCustomerSigning: false,
    CustomerSignature: null
};

const ChecklistMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

class Checklist extends Component {
    state = { ...newChecklistState };

    componentDidMount() {
        this.exportToPDF();
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const { checklist } = this.props;
    //     if (!_.isEqual(checklist, prevProps.checklist)) {
    //         this.setState({ ...this.state, ..._.omit(checklist, 'assignment') });
    //     }

    // }

    canBeSubmitted() {
        return true;
    }

    handleChange = (el, value, notes) => {

    };

    handleNotesChange = (el, notes) => {

    };

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    exportToPDF = () => {
        const { fileName, onGenerate } = this.props;
        let gridItem = document.getElementById('checklist-print');
        drawDOM(gridItem, {
            multiPage: true,
            forcePageBreak: ".page-break:not(:first-child)",
            paperSize: "Letter",
            keepTogether: ".checklist-section",
            margin: { top: 20, left: 40, right: 40, bottom: 20 },
            scale: .65,
            // pageTemplate: () => <PageTemplate checklist={{ ...checklist }} />
        }).then((group) => {
            return exportPDF(group);
        }).then((dataUri) => {
            const file = this.dataURLtoFile(dataUri, `${fileName}.pdf`);
            if (onGenerate) {
                onGenerate(file);
            }
            // var data = dataUri.split(';base64,')[1];
            // const blob = this.b64toBlob(data, 'application/pdf');
            // // console.warn(dataUri, data, blob);
            // saveAs(dataUri, "Inspection Reports");
        });
    }

    render() {
        const { classes, checklist, open, onClose, info, footerInfo, header, footer, businessUnits, divisions, readOnly, checklists } = this.props;


        return (
            <div id="checklist-print" className="p-16">
                {checklists.map((checklist, index) => {
                    const { info, footerInfo } = checklist;
                    const data = checklist.Data.Sections;
                    return (
                        <div className="w-full mb-24 page-break" key={checklist.ID}>
                            {/* <Typography variant="h6" style={{ alignItems: 'center' }} className="text-20 my-12 font-bold w-full text-center">{checklist.Name}</Typography> */}

                            {info &&
                                <div className="w-full min-h-24">
                                    {info}
                                </div>
                            }
                            {data && data.map((section, index) => {
                                const dt = new Date().toLocaleString('en-US');
                                return (
                                    <div key={`${dt}-${section.ID}`} className={`min-h-24 checklist-section relative px-16`}>
                                        <ChecklistPDFSection readOnly={readOnly} onNotesChange={this.handleNotesChange} scrollLock={true} section={section} editable={false} onChange={this.handleChange} data={[...section.Data.Items]} />
                                    </div>
                                );
                            })
                            }
                            <div className="sm:flex px-16">
                                <div className="w-full">
                                    <div className="w-full">
                                        <strong style={{ marginRight: 4 }}>Technician Signature:</strong>
                                    </div>
                                    <div className="w-full relative mb-12" style={{ border: '1px dotted #3f3f3f', width: '100%', height: 128, minHeight: 128, borderRadius: 5 }}>
                                        {
                                            checklist.TechnicianSignature &&
                                            <img className="pin-t pin-b pin-l pin-r m-auto absolute" style={{ width: 'auto', height: '100%' }} src={checklist.TechnicianSignature} />
                                        }
                                    </div>
                                </div>
                                <div className="sm:w-24 sm:h-12"></div>
                                <div className="w-full">
                                    <div className="w-full">
                                        <strong style={{ marginRight: 4 }}>Customer Signature:</strong>
                                    </div>
                                    <div className="w-full relative mb-12" style={{ border: '1px dotted #3f3f3f', width: '100%', height: 128, minHeight: 128, borderRadius: 5 }}>
                                        {
                                            checklist.CustomerSignature &&
                                            <img className="pin-t pin-b pin-l pin-r m-auto absolute" style={{ width: 'auto', height: '100%' }} src={checklist.CustomerSignature} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {checklist.Disclaimer &&
                                <div className="sm:flex">
                                    <div className="w-full px-16">
                                        <div className="mb-12 rounded-6 p-8" style={{ background: '#f1f1f1' }}>
                                            {checklist.Disclaimer &&
                                                <div>
                                                    <div className="font-bold text-red text-10 text-center w-full">* {checklist.Disclaimer} *</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {footerInfo &&
                                <div className="w-full min-h-24">
                                    {footerInfo}
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}
Checklist.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage,
    }, dispatch);
}

function mapStateToProps({ auth, checklistsApp, spReducers, dispatchBoardApp }) {
    return {
        user: spReducers.userProfiles.User,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Checklist));
