import React, { Component } from 'react';
import { Paper, ClickAwayListener, Icon, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from 'main/content/apps/email-templates/store/actions';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

class TemplateListHeader extends Component {
    state = {
        renameForm: false,
        renameTitle: this.props.template.Name
    };
    renameFormToggle = (state) => {
        this.setState({
            renameForm: state,
            renameTitle: this.props.template.Name
        })
    };

    onRenameTitleChange = (ev) => {
        this.setState({ renameTitle: ev.target.value })
    };

    renameTitleSubmit = (ev) => {
        ev.preventDefault();
        let { template } = this.props;
        if (!this.canSubmit()) {
            this.renameFormToggle(false);
            return;
        }
        this.setState({
            renameForm: false,
            renameTitle: this.state.renameTitle
        });
        this.props.updateTemplate({ ...template, Name: this.state.renameTitle });
    };

    canSubmit = () => {
        return this.state.renameTitle !== '';
    };

    render() {
        const { template } = this.props;
        const { renameTitle, renameForm } = this.state;

        return (
            <div className="flex items-center min-w-0">
                {renameForm ? (
                    <ClickAwayListener onClickAway={() => this.renameFormToggle(false)}>
                        <Paper className="p-4">
                            <form className="flex w-full" onSubmit={this.renameTitleSubmit}>
                                <TextField
                                    value={renameTitle}
                                    onChange={this.onRenameTitleChange}
                                    variant="outlined"
                                    margin="none"
                                    
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    type="submit"
                                                    disabled={!this.canSubmit()}
                                                >
                                                    <Icon>check</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </form>
                        </Paper>
                    </ClickAwayListener>
                ) : (
                        <div className="flex items-center justify-center">
                            <Typography
                                className="text-16 font-600 cursor-pointer"
                                onClick={() => this.renameFormToggle(true)}
                                color="inherit"
                            >
                                {template.Name}
                            </Typography>
                        </div>
                    )}
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateTemplate: Actions.updateTemplate
    }, dispatch);
}

function mapStateToProps({ emailTemplateApp }) {
    return {
        template: emailTemplateApp.template
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateListHeader));
