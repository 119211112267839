import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class UOMsList extends Component {

    state = {
        selectedUOMsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedUOMMenu = (event) => {
        this.setState({ selectedUOMsMenu: event.currentTarget });
    };

    closeSelectedUOMsMenu = () => {
        this.setState({ selectedUOMsMenu: null });
    };

    render() {
        const { classes, securables, uoms, user, searchText, openNewUOMDialog, selectedUOMIds, selectAllUOMs, deSelectAllUOMs, toggleInSelectedUOMs, openEditUOMDialog, removeUOMs, removeUOM, toggleStarredUOM, setUOMsUnstarred, setUOMsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.uomData ? [] : this.props.uomData), searchText);
        const { selectedUOMsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "units-of-measure" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewUOMDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">label</Icon>Units of Measure</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditUOMDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "UOM",
                                accessor: "UOM",
                                className: "font-bold justify-center",
                                width: 64
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Units of Measure found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUOMs: Actions.getUOMs,
        getUserData: Actions.getUserData,
        toggleInSelectedUOMs: Actions.toggleInSelectedUOMs,
        selectAllUOMs: Actions.selectAllUOMs,
        deSelectAllUOMs: Actions.deSelectAllUOMs,
        openEditUOMDialog: Actions.openEditUOMDialog,
        removeUOMs: Actions.removeUOMs,
        removeUOM: Actions.removeUOM,
        toggleStarredUOM: Actions.toggleStarredUOM,
        toggleStarredUOMs: Actions.toggleStarredUOMs,
        setUOMsStarred: Actions.setUOMsStarred,
        setUOMsUnstarred: Actions.setUOMsUnstarred,
        openNewUOMDialog: Actions.openNewUOMDialog
    }, dispatch);
}

function mapStateToProps({ uomsApp, spReducers }) {
    return {
        uoms: uomsApp.uoms.entities,
        selectedUOMIds: uomsApp.uoms.selectedUOMIds,
        searchText: uomsApp.uoms.searchText,
        user: uomsApp.user,
        uomData: spReducers.uoms,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UOMsList)));
