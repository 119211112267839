import axios from 'axios';
import { getUserData } from 'main/content/apps/service-unit-types/store/actions/user.actions';
import { showMessage } from 'store/actions';
import { UPDATE_EDIT_SERVICE_UNIT_DIALOG } from '../../../service-units/store/actions';
import _ from '@lodash';

export const GET_SERVICE_UNIT_TYPES = '[SERVICE UNIT TYPES APP] GET SERVICE UNIT TYPES';
export const SET_SEARCH_TEXT = '[SERVICE UNIT TYPES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SERVICE_UNIT_TYPES = '[SERVICE UNIT TYPES APP] TOGGLE IN SELECTED SERVICE UNIT TYPES';
export const SELECT_ALL_SERVICE_UNIT_TYPES = '[SERVICE UNIT TYPES APP] SELECT ALL SERVICE UNIT TYPES';
export const DESELECT_ALL_SERVICE_UNIT_TYPES = '[SERVICE UNIT TYPES APP] DESELECT ALL SERVICE UNIT TYPES';
export const OPEN_NEW_SERVICE_UNIT_TYPE_DIALOG = '[SERVICE UNIT TYPES APP] OPEN NEW SERVICE UNIT TYPE DIALOG';
export const CLOSE_NEW_SERVICE_UNIT_TYPE_DIALOG = '[SERVICE UNIT TYPES APP] CLOSE NEW SERVICE UNIT TYPE DIALOG';
export const OPEN_EDIT_SERVICE_UNIT_TYPE_DIALOG = '[SERVICE UNIT TYPES APP] OPEN EDIT SERVICE UNIT TYPE DIALOG';
export const UPDATE_EDIT_SERVICE_UNIT_TYPE_DIALOG = '[SERVICE UNIT TYPES APP] UPDATE EDIT SERVICE UNIT TYPE DIALOG';
export const CLOSE_EDIT_SERVICE_UNIT_TYPE_DIALOG = '[SERVICE UNIT TYPES APP] CLOSE EDIT SERVICE UNIT TYPE DIALOG';
export const ADD_SERVICE_UNIT_TYPE = '[SERVICE UNIT TYPES APP] ADD SERVICE UNIT TYPE';
export const UPDATE_SERVICE_UNIT_TYPE = '[SERVICE UNIT TYPES APP] UPDATE SERVICE UNIT TYPE';
export const REMOVE_SERVICE_UNIT_TYPE = '[SERVICE UNIT TYPES APP] REMOVE SERVICE UNIT TYPE';
export const REMOVE_SERVICE_UNIT_TYPES = '[SERVICE UNIT TYPES APP] REMOVE SERVICE UNIT TYPES';
export const TOGGLE_STARRED_SERVICE_UNIT_TYPE = '[SERVICE UNIT TYPES APP] TOGGLE STARRED SERVICE UNIT TYPE';
export const TOGGLE_STARRED_SERVICE_UNIT_TYPES = '[SERVICE UNIT TYPES APP] TOGGLE STARRED SERVICE UNIT TYPES';
export const SET_SERVICE_UNIT_TYPES_STARRED = '[SERVICE UNIT TYPES APP] SET SERVICE UNIT TYPES STARRED ';

export const OPEN_NEW_PART_TYPE_DIALOG = '[PART TYPES APP] OPEN NEW PART TYPE DIALOG';
export const CLOSE_NEW_PART_TYPE_DIALOG = '[PART TYPES APP] CLOSE NEW PART TYPE DIALOG';
export const OPEN_EDIT_PART_TYPE_DIALOG = '[PART TYPES APP] OPEN EDIT PART TYPE DIALOG';
export const CLOSE_EDIT_PART_TYPE_DIALOG = '[PART TYPES APP] CLOSE EDIT PART TYPE DIALOG';
export const ADD_PART_TYPE = '[PART TYPES APP] ADD PART TYPE';
export const UPDATE_PART_TYPE = '[PART TYPES APP] UPDATE PART TYPE';

export function getServiceUnitTypes(routeParams) {
    const request = axios.get('/api/service-unit-types-app/service-unit-types', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_SERVICE_UNIT_TYPES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedServiceUnitTypes(serviceUnitTypeId) {
    return {
        type: TOGGLE_IN_SELECTED_SERVICE_UNIT_TYPES,
        serviceUnitTypeId
    }
}


export function selectAllServiceUnitTypes() {
    return {
        type: SELECT_ALL_SERVICE_UNIT_TYPES
    }
}

export function deSelectAllServiceUnitTypes() {
    return {
        type: DESELECT_ALL_SERVICE_UNIT_TYPES
    }
}


export function openNewServiceUnitTypeDialog() {
    return {
        type: OPEN_NEW_SERVICE_UNIT_TYPE_DIALOG
    }
}

export function closeNewServiceUnitTypeDialog() {
    return {
        type: CLOSE_NEW_SERVICE_UNIT_TYPE_DIALOG
    }
}

export function openEditServiceUnitTypeDialog(data) {
    return (dispatch, getState) => {

        const request = axios.get(`${window["apiLocation"]}/api/ServiceUnitType?Co=${encodeURIComponent(data.Co)}&Type=${encodeURIComponent(data.UnitType)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_SERVICE_UNIT_TYPE_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditServiceUnitTypeDialog() {
    return {
        type: CLOSE_EDIT_SERVICE_UNIT_TYPE_DIALOG
    }
}

export function addServiceUnitType(newServiceUnitType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post(`${window["apiLocation"]}/api/ServiceUnitType`,
            newServiceUnitType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_SERVICE_UNIT_TYPE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service Unit Type ${newServiceUnitType.UnitType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateServiceUnitType(serviceUnitType) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.put(`${window["apiLocation"]}/api/ServiceUnitType?Co=${encodeURIComponent(serviceUnitType.Co)}&Type=${encodeURIComponent(serviceUnitType.UnitType)}`,
            serviceUnitType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_SERVICE_UNIT_TYPE,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service Unit Type ${serviceUnitType.UnitType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeServiceUnitType(serviceUnitTypeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post('/api/service-unit-types-app/remove-serviceUnitType', {
            serviceUnitTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_UNIT_TYPE,
                    data: response.data
                })
            ]).then(() => dispatch(getServiceUnitTypes(routeParams)))
        );
    };
}


export function removeServiceUnitTypes(serviceUnitTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post('/api/service-unit-types-app/remove-serviceUnitTypes', {
            serviceUnitTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_UNIT_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_TYPES
                })
            ]).then(() => dispatch(getServiceUnitTypes(routeParams)))
        );
    };
}

export function toggleStarredServiceUnitType(serviceUnitTypeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post('/api/service-unit-types-app/toggle-starred-serviceUnitType', {
            serviceUnitTypeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_UNIT_TYPE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitTypes(routeParams)))
        );
    };
}

export function toggleStarredServiceUnitTypes(serviceUnitTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post('/api/service-unit-types-app/toggle-starred-serviceUnitTypes', {
            serviceUnitTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_UNIT_TYPES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitTypes(routeParams)))
        );
    };
}

export function setServiceUnitTypesStarred(serviceUnitTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post('/api/service-unit-types-app/set-serviceUnitTypes-starred', {
            serviceUnitTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_UNIT_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitTypes(routeParams)))
        );
    };
}

export function setServiceUnitTypesUnstarred(serviceUnitTypeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitTypesApp.serviceUnitTypes;

        const request = axios.post('/api/service-unit-types-app/set-serviceUnitTypes-unstarred', {
            serviceUnitTypeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_UNIT_TYPES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_TYPES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitTypes(routeParams)))
        );
    };
}


export function openNewPartTypeDialog() {
    return {
        type: OPEN_NEW_PART_TYPE_DIALOG
    }
}

export function closeNewPartTypeDialog() {
    return {
        type: CLOSE_NEW_PART_TYPE_DIALOG
    }
}

export function openEditPartTypeDialog(data) {
    return {
        type: OPEN_EDIT_PART_TYPE_DIALOG,
        data
    }
}

export function closeEditPartTypeDialog() {
    return {
        type: CLOSE_EDIT_PART_TYPE_DIALOG
    }
}

export function addPartType(newPartType) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/PartType`,
            newPartType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_PART_TYPE,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const state = getState();
                    const { serviceUnitTypeDialog } = state.serviceUnitTypesApp.serviceUnitTypes;
                    const { Data } = serviceUnitTypeDialog.data;
                    const { PartTypes } = Data;
                    PartTypes.push(response.data);
                    dispatch({
                        type: UPDATE_EDIT_SERVICE_UNIT_TYPE_DIALOG,
                        data: serviceUnitTypeDialog.data
                    });
                    dispatch(showMessage({
                        message: `Part Type #${response.data.PartType} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updatePartType(partType) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/PartType?Co=${encodeURIComponent(partType.Co)}&UnitType=${encodeURIComponent(partType.UnitType)}&Type=${encodeURIComponent(partType.PartType)}`,
            partType
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_PART_TYPE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    const state = getState();
                    const { serviceUnitTypeDialog } = state.serviceUnitTypesApp.serviceUnitTypes;
                    const { Data } = serviceUnitTypeDialog.data;
                    const { PartTypes } = Data;
                    const index = _.findIndex(PartTypes, { PartType: partType.PartType });
                    if (index > -1) {
                        PartTypes.splice(index, 1, response.data);
                    }
                    dispatch({
                        type: UPDATE_EDIT_SERVICE_UNIT_TYPE_DIALOG,
                        data: serviceUnitTypeDialog.data
                    });
                    dispatch(showMessage({
                        message: `Part Type #${partType.PartType} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}