import axios from 'axios/index';

export const SET_FLEET_VEHICLE_DATA = '[SPCONNECTION] SET FLEET VEHICLE DATA';
export const GET_FLEET_VEHICLE_DATA = '[SPCONNECTION] GET FLEET VEHICLE DATA';
export const SUBSCRIBE_FLEET_VEHICLE_LIST = '[SPCONNECTION] SUBSCRIBE FLEET VEHICLE LIST';

export function getInitialFleetVehicleData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/FleetVehicle`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getFleetVehicleData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/FleetVehicle`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_FLEET_VEHICLE_DATA,
                payload: response.data
            })
        });
    }
}

export function setFleetVehicleData(data) {
    return {
        type: SET_FLEET_VEHICLE_DATA,
        payload: data
    };
}