import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },

});

class UserRolesList extends Component {

    state = {
        selectedUserRolesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedUserRoleMenu = (event) => {
        this.setState({ selectedUserRolesMenu: event.currentTarget });
    };

    closeSelectedUserRolesMenu = () => {
        this.setState({ selectedUserRolesMenu: null });
    };

    render() {
        const { classes, pages, openNewUserRoleDialog, userRoles, selectedRoles, userRoleData, user, searchText, selectedUserRoleIds, selectAllUserRoles, deSelectAllUserRoles, toggleInSelectedUserRoles, openEditUserRoleDialog, removeUserRoles, removeUserRole, toggleStarredUserRole, setUserRolesUnstarred, setUserRolesStarred } = this.props;
        const roles = selectedRoles ? _.filter(userRoleData, (o) => { return _.findIndex(selectedRoles, { Role: o.Role }) > -1 }) : userRoleData;
        const data = this.getFilteredArray((roles || []), searchText);

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {!selectedRoles &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewUserRoleDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">security</Icon>User Roles</Typography>
                    <ReactTable
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        if (this.props.onSelected) {
                                            this.props.onSelected(_.find(selectedRoles, { Role: rowInfo.original.Role }));
                                        } else {
                                            openEditUserRoleDialog(rowInfo.original);
                                        }
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Role #",
                                accessor: "Role",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Homepage",
                                id: "homepage",
                                accessor: r => {
                                    if (r) {
                                        const value = _.find(pages, { url: r.DefaultHomepage });
                                        if (!value) {
                                            return '';
                                        }
                                        return (
                                            <div className="flex">
                                                <Icon className="mr-8">{value.icon}</Icon>
                                                <label style={{ paddingTop: 2 }}>{`${value.title}`}</label>
                                            </div>
                                        );
                                    }
                                },
                                className: "justify-center",
                                width: 256,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No User Roles found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserRoles: Actions.getUserRoles,
        getUserData: Actions.getUserData,
        toggleInSelectedUserRoles: Actions.toggleInSelectedUserRoles,
        selectAllUserRoles: Actions.selectAllUserRoles,
        deSelectAllUserRoles: Actions.deSelectAllUserRoles,
        openNewUserRoleDialog: Actions.openNewUserRoleDialog,
        openEditUserRoleDialog: Actions.openEditUserRoleDialog,
        removeUserRoles: Actions.removeUserRoles,
        removeUserRole: Actions.removeUserRole,
        toggleStarredUserRole: Actions.toggleStarredUserRole,
        toggleStarredUserRoles: Actions.toggleStarredUserRoles,
        setUserRolesStarred: Actions.setUserRolesStarred,
        setUserRolesUnstarred: Actions.setUserRolesUnstarred
    }, dispatch);
}

function mapStateToProps({ userRolesApp, spReducers }) {
    return {
        userRoles: userRolesApp.userRoles.entities,
        selectedUserRoleIds: userRolesApp.userRoles.selectedUserRoleIds,
        searchText: userRolesApp.userRoles.searchText,
        user: userRolesApp.user,
        userRoleData: spReducers.userRoles,
        pages: spReducers.userProfiles.Pages,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRolesList)));
