import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedWorkOrderScopeIds: [],
    routeParams: {},
    workOrderScopeDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    revenueSplitDialog: {
        type: 'new',
        props: {
            open: false,
        },
        data: null,
    }
};

const workOrderScopesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_WORK_ORDER_SCOPES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        //case Actions.LOAD_WORK_ORDER_SCOPES
        //    {
        //        return {
        //            ...state,
        //            workOrderScopes: action.workOrderScopes
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_WORK_ORDER_SCOPES:
            {

                const workOrderScopeId = action.workOrderScopeId;

                let selectedWorkOrderScopeIds = [...state.selectedWorkOrderScopeIds];

                if (selectedWorkOrderScopeIds.find(id => id === workOrderScopeId) !== undefined) {
                    selectedWorkOrderScopeIds = selectedWorkOrderScopeIds.filter(id => id !== workOrderScopeId);
                }
                else {
                    selectedWorkOrderScopeIds = [...selectedWorkOrderScopeIds, workOrderScopeId];
                }

                return {
                    ...state,
                    selectedWorkOrderScopeIds: selectedWorkOrderScopeIds
                };
            }
        case Actions.SELECT_ALL_WORK_ORDER_SCOPES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedWorkOrderScopeIds = arr.map(workOrderScope => workOrderScope.id);

                return {
                    ...state,
                    selectedWorkOrderScopeIds: selectedWorkOrderScopeIds
                };
            }
        case Actions.DESELECT_ALL_WORK_ORDER_SCOPES:
            {
                return {
                    ...state,
                    selectedWorkOrderScopeIds: []
                };
            }
        case Actions.OPEN_NEW_WORK_ORDER_SCOPE_DIALOG:
            {
                return {
                    ...state,
                    workOrderScopeDialog: {
                        type: (action.data.Data.Customer ? 'site' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_WORK_ORDER_SCOPE_DIALOG:
            {
                return {
                    ...state,
                    workOrderScopeDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_WORK_ORDER_SCOPE_DIALOG:
            {
                return {
                    ...state,
                    workOrderScopeDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_WORK_ORDER_SCOPE_DIALOG:
            {
                return {
                    ...state,
                    workOrderScopeDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG:
            {
                return {
                    ...state,
                    workOrderScopeDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_WORK_ORDER_SCOPE_DIALOG:
            {
                return {
                    ...state,
                    workOrderScopeDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_REVENUE_SPLIT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    revenueSplitDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.OPEN_EDIT_REVENUE_SPLIT_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    revenueSplitDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data,
                    }
                }
            }
        case Actions.CLOSE_NEW_REVENUE_SPLIT_DIALOG:
            {
                return {
                    ...state,
                    revenueSplitDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.CLOSE_EDIT_REVENUE_SPLIT_DIALOG:
            {
                return {
                    ...state,
                    revenueSplitDialog: {
                        type: 'new',
                        props: {
                            open: false,
                        },
                        data: null,
                    }
                }
            }
        case Actions.ADD_REVENUE_SPLIT:
            {
                const { data } = action;
                const scope = state.workOrderScopeDialog.data;
                scope.Data.Splits.push(data);
                {
                    return {
                        ...state,
                        workOrderScopeDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: scope,
                        }
                    };
                }
            }
        case Actions.UPDATE_REVENUE_SPLIT:
            {
                const { data } = action;
                const scope = state.workOrderScopeDialog.data;
                scope.Data.Splits.splice(_.findIndex(scope.Data.Splits, { Seq: data.Seq }), 1, data);
                {
                    return {
                        ...state,
                        workOrderScopeDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: scope,
                        }
                    };
                }
            }
        case Actions.REMOVE_REVENUE_SPLIT:
            {
                const { data } = action;
                const scope = state.workOrderScopeDialog.data;
                scope.Data.Splits.splice(_.findIndex(scope.Data.Splits, { Seq: data.Seq }), 1);
                {
                    return {
                        ...state,
                        workOrderScopeDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: scope,
                        }
                    };
                }
            }
        default:
            {
                return state;
            }
    }
};

export default workOrderScopesReducer;
