import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class FormOfPaymentsList extends Component {

    state = {
        selectedFormOfPaymentsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedFormOfPaymentMenu = (event) => {
        this.setState({ selectedFormOfPaymentsMenu: event.currentTarget });
    };

    closeSelectedFormOfPaymentsMenu = () => {
        this.setState({ selectedFormOfPaymentsMenu: null });
    };

    render() {
        const { classes, securables, formOfPayments, user, searchText, openNewFormOfPaymentDialog, selectedFormOfPaymentIds, selectAllFormOfPayments, deSelectAllFormOfPayments, toggleInSelectedFormOfPayments, openEditFormOfPaymentDialog, removeFormOfPayments, removeFormOfPayment, toggleStarredFormOfPayment, setFormOfPaymentsUnstarred, setFormOfPaymentsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.formOfPaymentData ? [] : this.props.formOfPaymentData), searchText);
        const { selectedFormOfPaymentsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "form-of-payments" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewFormOfPaymentDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">payment</Icon>Forms Of Payment</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditFormOfPaymentDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center",
                                width: 200
                            },
                            {
                                Header: "Form of Payment",
                                accessor: "FormOfPayment",
                                className: "font-bold justify-center",
                                width: 200
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 400
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Forms of Payment found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFormOfPayments: Actions.getFormOfPayments,
        getUserData: Actions.getUserData,
        toggleInSelectedFormOfPayments: Actions.toggleInSelectedFormOfPayments,
        selectAllFormOfPayments: Actions.selectAllFormOfPayments,
        deSelectAllFormOfPayments: Actions.deSelectAllFormOfPayments,
        openEditFormOfPaymentDialog: Actions.openEditFormOfPaymentDialog,
        removeFormOfPayments: Actions.removeFormOfPayments,
        removeFormOfPayment: Actions.removeFormOfPayment,
        toggleStarredFormOfPayment: Actions.toggleStarredFormOfPayment,
        toggleStarredFormOfPayments: Actions.toggleStarredFormOfPayments,
        setFormOfPaymentsStarred: Actions.setFormOfPaymentsStarred,
        setFormOfPaymentsUnstarred: Actions.setFormOfPaymentsUnstarred,
        openNewFormOfPaymentDialog: Actions.openNewFormOfPaymentDialog
    }, dispatch);
}

function mapStateToProps({ formOfPaymentsApp, spReducers }) {
    return {
        formOfPayments: formOfPaymentsApp.formOfPayments.entities,
        selectedFormOfPaymentIds: formOfPaymentsApp.formOfPayments.selectedFormOfPaymentIds,
        searchText: formOfPaymentsApp.formOfPayments.searchText,
        user: formOfPaymentsApp.user,
        formOfPaymentData: spReducers.formOfPayments,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FormOfPaymentsList)));
