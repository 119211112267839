import _ from '@lodash';
import { Card, CardHeader, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Timecard from '../timecards/Timecard';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {
        maxHeight: '75vh',
        overflow: 'auto'
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    calendar: {
        width: '100%',
        background: theme.palette.primary.main,
        borderRadius: 5,
    },
    calendarHeader: {
        width: '100%',
    },
    calendarPicker: {
        height: 72,
        width: '100%',
        background: '#3f3f3f',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    calendarPickerDates: {
        height: 72,
        width: '100%',
    },
    mailItem: {},
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    labels: {},
    badge: {
        top: 5,
        right: 15,
    },
    liRoot: {
        padding: 0
    },
    clockInBtn: {
        background: '#333',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        }
    },
    clockOutBtn: {
        background: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        '&:active': {
            background: '#5f5f5f',
        }
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    withdraw: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    inlineBadge: {
        top: '25%',
        left: '110%',
        right: 'unset',
        fontSize: 11,
        padding: 4,
        height: 16,
        transform: 'none',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '65%',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '65%',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
});

const initialState = {
    loading: false,
    expanded: {},
    trips: [],
    timecard: null,
    selectedTrip: null,
    viewing: 'schedule',
    anchorEl: null,
    tcView: null,
    data: {
        Date: null,
        timecard: {},
    }
}

class TechnicianTimecard extends Component {

    state = {
        ...initialState
    };

    componentDidMount() {
        let { data } = this.props;
        this.setState({ ...this.state, data })

        window.addEventListener("resize", this.handleResize);
        window.addEventListener("orientationchange", this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
        let { data } = this.props;
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ ...this.state, data })
        }
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    sigPad = {};

    clear = () => {
        this.sigPad.clear()
    }

    trim = () => {
        this.setState({
            ...this.state,
            sigImg: this.sigPad.getCanvas()
                .toDataURL('image/png'),
            isSigning: false,
        })
    }

 
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        window.addEventListener("orientationchange", this.handleResize);
    }
    
        handleResize = () => { 
        // Get the current signature data before resizing
        if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
            const signatureData = this.sigPad.toDataURL(); 
            // Handle the resize without clearing the canvas
            this.sigPad.clear();
            this.sigPad.fromDataURL(signatureData);   
        }   
    }; 

    renderStartLabel = () => {
        const { timecard } = this.state;
        const tcData = timecard ? timecard.Data : null;
        const punches = tcData && tcData.Punch ? tcData.Punch : [];
        const timeIn = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[0].TimeIn : null;
        const timeOut = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[tcData.Punch.length - 1].TimeOut : null;
        return (
            <span className="text-12">{timeIn && moment(moment(timeIn)).local().toDate().toLocaleTimeString('en-US')}</span>
        );
    }

    renderEndLabel = () => {
        const { timecard } = this.state;
        const tcData = timecard ? timecard.Data : null;
        const punches = tcData && tcData.Punch ? tcData.Punch : [];
        const timeIn = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[0].TimeIn : null;
        const timeOut = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[tcData.Punch.length - 1].TimeOut : null;
        return (
            <span className="text-12">{timeOut && moment(moment(timeOut)).local().toDate().toLocaleTimeString('en-US')}</span>
        );
    }

    handleTimeOutChange = (event) => {
        window["warn"](event);
        const { technician, data } = this.props;
        const { timecard } = data;
        const tcData = timecard ? timecard.Data : null;
        if (event) {
            const time = event._d;
            const dt = new Date(data.Date);
            dt.setTime(time.getTime());
            window["warn"](data.Date, time, dt);
            if (tcData) {
                const punch = tcData.Punch[tcData.Punch.length - 1];
                punch.TimeOut = dt.toLocaleString('en-US');
                this.props.editPunch(punch);
            }
        } else {
            if (tcData) {
                const punch = tcData.Punch[tcData.Punch.length - 1];
                punch.TimeOut = null;
                this.props.editPunch(punch);
            }
        }
    };

    handleTimeInChange = (event) => {
        window["warn"](event);
        const { technician, data } = this.props;
        const { Co, Employee } = technician;
        const { timecard } = data;
        const tcData = timecard ? timecard.Data : null;
        if (event) {
            const time = event._d;
            const dt = new Date(data.Date);
            dt.setTime(time.getTime());
            window["warn"](data.Date, time, dt);
            const punch = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[0] : {
                Co,
                Employee,
                Date: data.Date,
                TimeIn: dt.toLocaleString('en-US'),
            }
            punch.TimeIn = dt.toLocaleString('en-US');
            if (tcData && tcData.Punch && tcData.Punch.length > 0) {
                this.props.editPunch(punch);
            } else {
                this.props.clockIn(punch);
            }
        } else {
            const punch = tcData && tcData.Punch && tcData.Punch.length === 1 ? tcData.Punch[0] : null;
            if (punch) {
                this.props.removePunch(punch);
            }
        }
    };

    openStartPicker = (e) => {
        this.startPicker.open(e);
    }

    openEndPicker = (e) => {
        this.endPicker.open(e);
    }

    render() {
        const { classes, open, technician, trips, animation, supervisor, noBack } = this.props;
        const { Co, Employee } = technician;
        const { data } = this.state;
        const { timecard } = data;
        window["warn"]('Timecard Data: ', timecard);
        const date = new Date(data.Date);
        const tcData = null;
        const punches = tcData && tcData.Punch ? tcData.Punch : [];
        const timeIn = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[0].TimeIn : null;
        const timeOut = tcData && tcData.Punch && tcData.Punch.length > 0 ? tcData.Punch[tcData.Punch.length - 1].TimeOut : null;
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const hours = timecard ? timecard.Data ? _.sumBy(timecard.Data.Labor || [], (o) => { return o.Qty }) : timecard.Hours ? timecard.Hours : 0 : 0;

        let increaseFontSize = false;
        if(this.props.userPreferences){
            let pref = JSON.parse(this.props.userPreferences);
            if(pref.IncreaseFontSize){
                increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
            }
        }

        return (
            <Card className="mt-4 mb-8 rounded-lg" style={{ border: '1px solid lightgrey', touchAction: 'pan-y' }}>
                <CardHeader onClick={this.props.onClick} className={classNames("p-12 relative", "highlight")}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-8", title: classes.title, subheader: classes.subheader, }
                    }
                    avatar={
                        supervisor ? 
                            <Icon color={open || this.props.date === data.Date ? 'primary' : undefined} className={`${increaseFontSize ? "text-28" : "text-24"} mt-4`}>{open && !noBack ? 'keyboard_arrow_left' : 'access_time'}</Icon>
                        :
                            <Icon color={this.props.date === data.Date ? 'primary' : undefined} className={`${increaseFontSize ? "text-28" : "text-24"} mt-4`}>access_time</Icon>
                    }
                    action={
                        <div className="absolute w-96 pin-r mr-16 pin-t text-right mt-12">
                            <Typography variant="caption" className={`${increaseFontSize ? "text-16" : "text-12"} font-bold`}>{`${Number(hours).toFixed(2)} Hours`}</Typography>
                            <Typography variant="caption" className={`${increaseFontSize ? "text-14" : "text-10"} text-grey-darker`}><Icon color={!timecard.ID ? "action" : timecard.ApprovedYN === 'Y' ? "secondary" : timecard.ApprovedYN === 'N' ? "error" : "primary"} className="text-14 mr-4" style={{ verticalAlign: 'middle' }}>{!timecard.ID ? "add" : timecard.ApprovedYN === 'Y' ? "cloud_done" : timecard.ApprovedYN === 'N' ? "cloud_offline" : timecard.SubmittedYN === 'Y' ? "cloud_upload" : "edit"}</Icon>{!timecard.ID ? 'New' : timecard.ApprovedYN === 'Y' ? 'Approved' : timecard.ApprovedYN === 'N' ? 'Rejected' : timecard.SubmittedYN === 'Y' ? 'Submitted' : 'Open'}</Typography>
                            {/* <Icon className="pin-r pin-t mt-20 absolute">{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon> */}
                        </div>
                    }
                    title={
                        <Typography className={`flex ${increaseFontSize ? "text-16" : "text-12"} font-bold`}>
                            <span>{moment(data.Date).format('dddd')}</span>
                        </Typography>
                    }
                    subheader={<span className={`${increaseFontSize ? "text-14" : "text-11"}`}>{data.Date}</span>}
                >
                </CardHeader>
                {supervisor && open &&
                    <div className="w-full p-12" style={{ borderTop: '1px solid lightgrey' }}>
                        <Timecard source="supervisor" trips={[...trips]} supervisor={supervisor} timecard={{ Co, Employee, Date: data.Date, Data: { Punch: [], Labor: [], Attachments: [] }, ...timecard, }} />
                    </div>
                }
            </Card>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clockIn: Actions.clockIn,
        editPunch: Actions.editPunch,
        editTimecard: Actions.editTimecard,
        removePunch: Actions.removePunch,
    }, dispatch);
}

function mapStateToProps({ spReducers, technicianApp }) {
    return {
        user: spReducers.userProfiles.User,
        Co: spReducers.companies.Co,
        userPreferences: spReducers.userProfiles.User.Preferences,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianTimecard)));
