import axios from 'axios';
import history from 'history.js';
// import DashboardModel from 'main/content/apps/dashboard-builder/model/DashboardModel';

export const GET_DASHBOARD_TAGS = '[DASHBOARD BUILDER APP] GET DASHBOARD TAGS';
export const ADD_DASHBOARD_TAG = '[DASHBOARD BUILDER APP] ADD DASHBOARD TAG';
export const UPDATE_DASHBOARD_TAG = '[DASHBOARD BUILDER APP] UPDATE DASHBOARD TAG';
export const REMOVE_DASHBOARD_TAG = '[DASHBOARD BUILDER APP] REMOVE DASHBOARD TAG';
export const GET_DASHBOARDS = '[DASHBOARD BUILDER APP] GET DASHBOARDS';
export const RESET_DASHBOARDS = '[DASHBOARD BUILDER APP] RESET DASHBOARDS';
export const NEW_DASHBOARD = '[DASHBOARD BUILDER APP] NEW DASHBOARD';

export function getDashboards(Co)
{
    const request = axios.get(`${window["apiLocation"]}/api/Dashboard?Co=${Co}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_DASHBOARDS,
                payload: response.data
            })
        );
}

export function getDashboardTags(Co)
{
    const request = axios.get(`${window["apiLocation"]}/api/DashboardTag?Co=${Co}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_DASHBOARD_TAGS,
                payload: response.data
            })
        );
}

export function newDashboardTag(newTag)
{
    const request = axios.post(`${window["apiLocation"]}/api/DashboardTag`,
        newTag
    );

    return (dispatch) =>
        request.then((response) => {
                const tag = response.data;
                return dispatch({
                    type: ADD_DASHBOARD_TAG,
                    tag
                })
            }
        );
}

export function updateDashboardTag(dashboardTag)
{
    const request = axios.put(`${window["apiLocation"]}/api/DashboardTag?Co=${dashboardTag.Co}&Tag=${dashboardTag.ID}`,
        dashboardTag
    );

    return (dispatch) =>
        request.then((response) => {
                const tag = response.data;
                return dispatch({
                    type: UPDATE_DASHBOARD_TAG,
                    tag
                })
            }
        );
}

export function removeDashboardTag(tag)
{
    const request = axios.put(`${window["apiLocation"]}/api/DashboardTag?Co=${tag.Co}&Tag=${tag.ID}`);

    return (dispatch) =>
        request.then((response) => {
                return dispatch({
                    type: REMOVE_DASHBOARD_TAG,
                    tag
                })
            }
        );
}

export function resetDashboards()
{
    return {
        type: RESET_DASHBOARDS
    }
}

export function newDashboard(dashboard)
{
    const request = axios.post(`${window["apiLocation"]}/api/Dashboard`,
        dashboard
    );

    return (dispatch) =>
        request.then((response) => {
                const dashboard = response.data;
                history.push({
                    pathname: `/apps/dashboard-builder/dashboards/${dashboard.Co}/${dashboard.Dashboard}`
                });
                return dispatch({
                    type: NEW_DASHBOARD,
                    dashboard
                })
            }
        );
}
