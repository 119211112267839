import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DialGroupMemberDialog from 'main/content/apps/dial-group-members/DialGroupMemberDialog';
import DialGroupMemberHeader from 'main/content/apps/dial-group-members/DialGroupMembersHeader';
import DialGroupMembersLeftSidebarHeader from 'main/content/apps/dial-group-members/DialGroupMembersLeftSidebarHeader';
import DialGroupMemberList from 'main/content/apps/dial-group-members/DialGroupMembersList';
import DialGroupMemberSidebarContent from 'main/content/apps/dial-group-members/DialGroupMembersSidebarContent';
import React, { Component } from 'react';
// import DialGroupMemberKeysDialog from 'main/content/apps/dial-group-member-keys/DialGroupMemberKeysDialog';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right: 12,
        bottom: 12,
        zIndex: 99
    }
});

class DialGroupMembersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getDialGroupMembers(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewDialGroupMemberDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <DialGroupMemberHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <DialGroupMembersLeftSidebarHeader />
                            }
                            content={
                                <DialGroupMemberList />
                            }
                            leftSidebarContent={
                                <DialGroupMemberSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={classNames(classes.addButton, !matches ? "fixed" : "")}
                                onClick={openNewDialGroupMemberDialog}
                            >
                                <Icon>add</Icon>
                            </Fab>
                        </FuseAnimate>
                        <DialGroupMemberDialog />
                        {/* <DialGroupMemberKeysDialog /> */}
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDialGroupMembers: Actions.getDialGroupMembers,
        getUserData: Actions.getUserData,
        openNewDialGroupMemberDialog: Actions.openNewDialGroupMemberDialog
    }, dispatch);
}

function mapStateToProps({ dialGroupMembersApp }) {
    return {
        dialGroupMembers: dialGroupMembersApp.dialGroupMembers.entities,
        selectedDialGroupMemberIds: dialGroupMembersApp.dialGroupMembers.selectedDialGroupMemberIds,
        searchText: dialGroupMembersApp.dialGroupMembers.searchText,
        user: dialGroupMembersApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DialGroupMembersApp)));
