import React, { useState, useEffect } from 'react'
import { FuseAnimate, FuseAnimateGroup } from '@fuse'
import _ from '@lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Hidden,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { SPRoundIcon } from 'main/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import { openEditEstimateDialog } from '../estimates/store/actions'
import { openEditInvoiceDialog } from '../invoices/store/actions'
import {
  openEditServiceUnitDialog,
  openNewServiceUnitDialog,
} from '../service-units/store/actions'
import * as TripActions from '../trips/store/actions'
import * as WorkOrderScopeActions from '../work-order-scopes/store/actions'

const styles = theme => ({
  root: {
    padding: 0,
  },
  avatar: {
    // backgroundColor: '#555555',
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  canceledAvatar: {
    backgroundColor: theme.palette.error[500],
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  unassignedScopeAvatar: {
    backgroundColor: '#9f9f9f',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  techSelAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  streetView: {
    borderRadius: '5px',
  },
  formControl: {
    marginBottom: 24,
  },
  status: {
    marginBottom: 24,
    maxWidth: 100,
  },
  customerSelect: {
    padding: 2,
    width: '100%',
    border: '1px solid lightgrey',
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  customerScroll: {},
  actionButton: {
    background: '#3f3f3f',
  },
  liRoot: {
    padding: 0,
  },
  actionBadge: {
    marginRight: 8,
    marginTop: 8,
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  yellow: {
    backgroundColor: '#f4b636',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5f5f5f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
    '&:active': {
      backgroundColor: '#5f5f5f',
    },
  },
  grey: {
    backgroundColor: '#5f5f5f',
    color: '#fff',
  },
  redText: {
    color: '#f44336',
  },
  greenText: {
    color: '#d1e751',
  },
  blueText: {
    color: '#4dbce9',
  },
  yellowText: {
    color: '#f4b636',
  },
  blackText: {
    color: '#3f3f3f',
  },
  greyText: {
    color: '#5f5f5f',
  },
  drawer: {
    width: 58,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 164,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerBadge: {
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    top: 4,
    right: -2,
    padding: 2,
  },
  switchBase: {
    height: 'unset',
  },
  estBadge: {
    top: 0,
    right: 12,
    background: '#3f3f3f',
    padding: 2,
  },
  estBadgeIcon: {
    fontSize: '14px !important',
  },
  taskBadge: {
    top: 20,
    right: 39,
    background: '#3f3f3f',
    padding: 2,
  },
  taskBadgeIcon: {
    fontSize: '14px !important',
  },
  taskCountBadge: {
    top: 14,
    right: -6,
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    border: '2px solid #3f3f3f',
    padding: 2,
  },
  taskCard: {
    marginBottom: 12,
    border: '1px solid lightgrey',
  },
  taskCardHeader: {
    // borderTop: '1px dotted lightgrey',
  },
  taskCardUnit: {
    // paddingLeft: 48,
  },
  unitAvatar: {
    width: 32,
    height: 32,
  },
  unitTaskBadge: {
    marginRight: 18,
    marginTop: 18,
  },
  formBadge: {
    minWidth: 14,
    height: 14,
    fontSize: 10,
    top: 'unset',
    bottom: -8,
    right: 4,
    padding: 2,
  },
  taskForms: {
    paddingBottom: '0 !important',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 12,
    maxWidth: '70%',
  },
  wrap: {
    whiteSpace: 'normal !important',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: 11,
    maxWidth: '70%',
  },
  tag: {
    marginRight: 4,
    height: 28,
    borderRadius: 5,
    marginBottom: 4,
  },
  borderTop: {
    borderTop: '1px solid lightgrey',
    paddingTop: 4,
    paddingBottom: 4,
  },
  techNameBadge: {
    top: '90%',
    right: '90%',
    backgroundColor: 'rgba(0,0,0,.5)',
    color: '#fff',
    fontSize: 'x-small',
  },
  techAvatarRoot: {
    marginRight: 0,
  },
  techAvatar: {
    borderRadius: '50%',
    boxShadow: '2px 2px 6px #333',
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  newTechButton: {
    width: 32,
    height: 32,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  histDrawer: {
    width: 64,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  histShelf: {
    position: 'relative',
  },
  histDrawerClose: {
    overflowX: 'hidden',
    width: 56,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.up('sm')]: {
      width: 56,
    },
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
    padding: 8,
  },
  histContent: {
    width: '100%',
    minHeight: '300px',
  },
  qty: {
    backgroundColor: '#3f3f3f',
    height: 18,
    minWidth: 18,
    fontSize: 13,
    paddingLeft: 4,
    paddingRight: 4,
    marginRight: 6,
    marginTop: 3,
    // width: 24,
    float: 'left',
    borderRadius: 12,
    color: '#fff',
    textAlign: 'center',
  },
  chip: {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 12,
    height: 24,
  },
  chipLabel: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: '#fff',
    paddingBottom: 2,
    fontSize: 16,
  },
  chipAvatar: {
    marginLeft: '-12px !important',
    color: '#3f3f3f',
    backgroundColor: '#3f3f3f',
    border: '3px solid #3f3f3f',
    width: '32px !important',
    height: '32px !important',
  },
  chipAvatarIcon: {
    color: '#3f3f3f',
    backgroundColor: '#fff',
  },
  invHeader: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
  },
  invSubHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 4,
    color: '#333',
  },
  dividerPlain: {
    color: '#fff',
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
})

function ScopeDetails(props) {
  const [Data, setData] = useState(props.scopeData)
  const [scope, setScope] = useState(props.scopeNum)
  const [index, setIndex] = useState(props.indexNum)

  //state from parent
  const [anchorQuote, setAnchorQuote] = useState(null)
  const [anchorQuoteEl, setAnchorQuoteEl] = useState(null)
  const [addUnitsScope, setAddUnitsScope] = useState(null)

  const [unitMenuEl, setUnitMenuEl] = useState(null)
  const [unitMenuData, setUnitMenuData] = useState(null)
  const [unitMenuUnit, setUnitMenuUnit] = useState(null)

  const [expandedComponents, setExpandedComponents] = useState({})

  const [summary, setSummary] = useState(
    (props.editSummary[scope.Scope] || scope).Summary,
  )

  useEffect(() => {
    setData(props.scopeData)
    setIndex(props.indexNum)
    setScope(props.scopeNum)
  }, [
    props.scopeData,
    props.scopeNum,
    props.thisState,
    props.expanded,
    props.editTechs,
    props.editScopes,
    props.editQuotes,
    props.editSummary,
  ])

  const toggleScope = scope => {
    if (props.expanded[scope]) {
      props.expanded[scope] = false
    } else {
      props.expanded[scope] = true
    }
    props.setStateFxn({ ...props.thisState, ...props.expanded })
  }

  const formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  const closeUnitMenu = () => {
    setUnitMenuEl(null)
    setUnitMenuData(null)
    setUnitMenuUnit(null)
  }

  if (!scope.Data) {
    scope.Data = {
      Usage: [],
      Service: _.find(props.services, { Co: scope.Co, Service: scope.Service }),
      ArrivalWindow: _.find(props.arrivalWindows, {
        Co: scope.Co,
        BusinessUnit: scope.BusinessUnit,
        Division: scope.Division,
        ArrivalWindow: scope.ArrivalWindow,
      }),
      Assignments: [],
      AgreementTasks: [],
      Tags: [],
    }
  } else {
    scope.Data = {
      ...scope.Data,
      Service: _.find(props.services, { Co: scope.Co, Service: scope.Service }),
      ArrivalWindow: _.find(props.arrivalWindows, {
        Co: scope.Co,
        BusinessUnit: scope.BusinessUnit,
        Division: scope.Division,
        ArrivalWindow: scope.ArrivalWindow,
      }),
    }
  }
  if (!Data.Site) {
    Data.Site = {
      Data: {
        Units: [],
        Estimates: [],
        Tags: [],
      },
    }
  }
  if (Data.Site && !Data.Site.Data) {
    Data.Site.Data = {
      Units: [],
      Estimates: [],
      Tags: [],
    }
  }
  const leadTech = _.find(props.technicians, {
    Technician: scope.LeadTechnician,
  })
  const assignedTrips = _.filter(props.Trips, o => {
    return (
      _.findIndex(scope.Data.Assignments, {
        Co: o.Co,
        WorkOrder: o.WorkOrder,
        Trip: o.Trip,
      }) > -1
    )
  })
  const assignedTechs = _.filter(props.technicians, o => {
    return (
      _.findIndex(assignedTrips, { Co: o.Co, Technician: o.Technician }) > -1
    )
  })

  const units = _.filter(Data.Site.Data.Units, unit => {
    return (
      _.filter(scope.Data.AgreementTasks, t => {
        return (
          _.findIndex(t.Data.Task.Data.Units || [], { UnitID: unit.UnitID }) >
          -1
        )
      }).length > 0 ||
      _.findIndex(scope.Data.ServiceUnits, { UnitID: unit.UnitID }) > -1
    )
  })
  const openQuotes =
    Data &&
    Data.Estimates &&
    _.filter(Data.Estimates, o => {
      return (
        (o.BusinessUnit === scope.BusinessUnit &&
          (o.Division === scope.Division || o.Division === '*') &&
          o.Status < 2 &&
          new Date(o.ExpirationDate) >= new Date()) ||
        o.Estimate === scope.Estimate ||
        (o.AssignedToWorkOrder === scope.WorkOrder &&
          o.AssignedToWorkOrderScope === scope.Scope)
      )
    })
  const est = scope.Data.Estimate
  const scopeDivision = _.find(props.divisions, {
    BusinessUnit: scope.BusinessUnit,
    Division: scope.Division,
  })
  var scopeForms = [
    ...(scope.Data.Forms || []),
    ...(scope.Data.FormResults || []),
  ]
  var scopeChecklists = [
    ...(scope.Data.Checklists || []),
    ...(scope.Data.ChecklistResults || []),
  ]
  var nte = scope.NotToExceed
  const isWarranty = Boolean(scope.IsWarrantyYN === 'Y')
  const warrantyTech = isWarranty
    ? _.find(props.technicians, { Technician: scope.WarrantyTechnician })
    : null
  const billableAmt =
    scope.PriceMethod === 'T'
      ? _.sumBy(
          _.filter(
            Data.Usage,
            o =>
              o.Scope === scope.Scope &&
              ['NEW', 'INVOICE PENDING', 'INVOICED'].indexOf(o.Status) > -1,
          ),
          'PriceTotal',
        )
      : scope.PriceMethod === 'F'
        ? scope.Price +
          _.sumBy(
            _.filter(
              Data.Usage,
              o =>
                o.Scope === scope.Scope &&
                (o.AddOnYN === 'Y' || o.Type > 4) &&
                ['NEW', 'INVOICE PENDING', 'INVOICED'].indexOf(o.Status) > -1,
            ),
            'PriceTotal',
          )
        : 0
  window['log']('WO Scope re-rendered')

  let increaseFontSize = false
  if (props.userPreferences) {
    let pref = JSON.parse(props.userPreferences)
    if (pref.IncreaseFontSize) {
      increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
    }
  }

  return (
    <div
      className={classNames(
        props.assignedIndex > 0 || (!props.trip && index > 0)
          ? props.classes.borderTop
          : ' py-4',
        'w-full',
      )}
    >
      <ListItem
        onClick={e => {
          e.stopPropagation()
          toggleScope(scope.Scope)
        }}
        classes={{ root: props.classes.liRoot }}
        className='relative cursor-pointer min-h-36'
      >
        <Badge
          color='error'
          classes={{ badge: props.classes.estBadge, root: est ? 'mr-4' : '' }}
          invisible={!est || props.pdf}
          badgeContent={
            <Icon
              className={classNames(
                'no-print no-print',
                props.classes.estBadgeIcon,
              )}
              color='secondary'
              style={{ transform: 'rotate(-90deg)' }}
            >
              attachment
            </Icon>
          }
        >
          <Badge
            color='error'
            classes={{ badge: props.classes.taskBadge }}
            invisible={units.length < 1 ? true : false}
            badgeContent={
              <Badge
                color='secondary'
                classes={{ badge: props.classes.taskCountBadge }}
                badgeContent={units.length}
              >
                <Icon className={props.classes.taskBadgeIcon}>build</Icon>
              </Badge>
            }
          >
            <Avatar
              style={{ marginRight: 8 }}
              classes={{ root: props.classes.avatarRoot }}
              className={
                !props.assignedTrip && !props.pdf
                  ? props.classes.unassignedScopeAvatar
                  : scope.Status !== 4
                    ? props.classes.avatar
                    : props.classes.canceledAvatar
              }
            >
              {scope.Scope}
            </Avatar>
          </Badge>
        </Badge>
        <ListItemText
          classes={{ root: props.classes.liRoot, primary: props.classes.title }}
          primary={
            <span
              className={classNames(
                increaseFontSize ? 'text-20' : 'text-14',
                'font-bold',
              )}
            >
              {scope.Status === 4
                ? 'Cancellation'
                : scope.Data.Service.Description}
              <Tooltip placement='top' title='Edit Scope'>
                <Icon
                  onClick={e => {
                    e.stopPropagation()
                    props.openEditWorkOrderScopeDialog(scope)
                  }}
                  className={classNames(
                    increaseFontSize ? 'text-20' : 'text-16',
                    'ml-4 cursor-pointer align-middle',
                  )}
                  style={{
                    marginBottom: 2,
                    display: props.assignedTrip ? 'inline' : 'none',
                  }}
                >
                  open_in_new
                </Icon>
              </Tooltip>
            </span>
          }
          secondary={
            <span className='font-bold text-12 flex'>
              {nte && (
                <Chip
                  className='h-20 rounded text-11 mr-6'
                  classes={{ label: 'px-6' }}
                  label={`NTE: ${formatDollars(nte)}`}
                  color='primary'
                />
              )}
              {
                <Typography
                  variant='caption'
                  className={classNames(
                    increaseFontSize ? 'text-16' : props.classes.subheader,
                    props.expanded[scope.Scope] ? props.classes.wrap : '',
                  )}
                >
                  {scope.Status === 4 && scope.CancellationReason
                    ? scope.CancellationReason
                    : est
                      ? est.Description || scope.Description
                      : scope.Description}
                </Typography>
              }
            </span>
          }
        />
        <Typography
          component='div'
          variant='caption'
          className={classNames(
            increaseFontSize ? 'text-16' : null,
            'font-bold absolute pin-r pin-t mr-4 mt-6',
          )}
        >
          {scope.DueBy && new Date(scope.DueBy).toLocaleDateString('en-US')}
        </Typography>
        <Icon
          className={classNames(
            increaseFontSize ? 'text-28' : null,
            'no-print pin-r pin-t mt-24 absolute',
          )}
        >
          {props.expanded[scope.Scope]
            ? 'keyboard_arrow_up'
            : 'keyboard_arrow_down'}
        </Icon>
      </ListItem>
      {props.expanded[scope.Scope] && (
        <FuseAnimate animation='transition.slideDownIn'>
          <div className='w-full pl-12 mt-18 mb-16'>
            {scope.IsWarrantyYN !== 'Y' &&
              scope.NotToExceed &&
              billableAmt > scope.NotToExceed && (
                <div className='w-full mb-8'>
                  <div
                    className='p-8 rounded py-12 w-full cursor-pointer'
                    style={{
                      backgroundColor: '#f9f9f9',
                      border: '1px dashed red',
                    }}
                  >
                    <Typography className='text-14 font-bold text-red w-full text-center'>
                      <Icon
                        color='error'
                        className='mr-6 text-20 mb-6 align-middle'
                      >
                        error
                      </Icon>
                      PRICE LIMIT EXCEEDED
                    </Typography>
                    <div className='w-full'>
                      <div className='italic mt-4 text-center text-12'>
                        This scope's billable amount currently exceeds the
                        specified limit of {formatDollars(scope.NotToExceed)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <span
              className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  business_center
                </Icon>
                Business Unit:{' '}
              </b>
              {`${
                _.find(props.businessUnits, {
                  BusinessUnit: scope.BusinessUnit,
                }).Description
              }`}
            </span>
            <br />
            <span
              className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  location_city
                </Icon>
                Division:{' '}
              </b>
              {`${scopeDivision.Description}`}
            </span>
            <br />
            <span
              className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  business
                </Icon>
                Department:{' '}
              </b>
              {`${
                _.find(props.departments, {
                  BusinessUnit: scope.BusinessUnit,
                  Division: scope.Division,
                  Department: scope.Department,
                }).Description
              }`}
            </span>
            <br />
            <span
              onClick={() => {
                /* const { props.editTechs } = props.editTechs; */ props.editTechs[
                  scope.Scope
                ] = !props.editTechs[scope.Scope] ? scope : null
                props.setStateFxn({ ...props.thisState, ...props.editTechs })
              }}
              style={{
                verticalAlign: 'middle',
                pointerEvents: props.assignedTrip ? 'auto' : 'none',
              }}
              className={classNames(
                increaseFontSize
                  ? 'text-16 cursor-pointer'
                  : 'text-12 cursor-pointer',
              )}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  account_circle
                </Icon>
                Lead Technician:{' '}
              </b>
              {leadTech ? `${leadTech.FirstName} ${leadTech.LastName}` : 'None'}
              <Icon
                className='no-print text-24 align-middle ml-4 cursor-pointer'
                style={{
                  visibility: props.assignedTrip ? 'visible' : 'hidden',
                }}
              >
                {props.editTechs[scope.Scope]
                  ? 'keyboard_arrow_up'
                  : 'keyboard_arrow_down'}
              </Icon>
              <br />
            </span>
            {props.editTechs[scope.Scope] && (
              <div className='w-full sm:w-1/2 md:w-1/3 xl:w-1/4 mt-12 mb-12'>
                <Autocomplete
                  className='mb-12'
                  title='Lead Technician'
                  options={[
                    { Technician: null, FirstName: 'None', LastName: '' },
                    ..._.filter(props.technicians, o => {
                      return props.Trips.length > 0
                        ? _.findIndex(props.Trips, {
                            Technician: o.Technician,
                          }) > -1
                        : o.Technician ===
                            props.editTechs[scope.Scope].LeadTechnician
                    }),
                  ]}
                  menuItemComponent={value => {
                    return (
                      <MenuItem value={value.Technician}>
                        <div className='flex'>
                          {value.Technician && (
                            <Avatar
                              style={{ marginRight: 8 }}
                              classes={{ root: props.classes.avatarRoot }}
                              className={props.classes.techSelAvatar}
                              alt={value.Technician}
                              src={
                                value.Data && value.Data.Avatar
                                  ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                  : props.avatar
                              }
                            />
                          )}
                          {`${value.FirstName} ${value.LastName}`}
                        </div>
                      </MenuItem>
                    )
                  }}
                  value={props.editTechs[scope.Scope].LeadTechnician || ''}
                  onSelect={option => {
                    props.editTechs[scope.Scope].LeadTechnician =
                      option.Technician
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editTechs,
                    })
                  }}
                  required
                />
                <Button
                  variant='contained'
                  className='mb-12'
                  color='primary'
                  onClick={() => {
                    props.updateWorkOrderScope({
                      ...scope,
                      LeadTechnician:
                        props.editTechs[scope.Scope].LeadTechnician,
                    })
                    props.editTechs[scope.Scope] = null
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editTechs,
                    })
                  }}
                  fullWidth
                >
                  Update
                </Button>
              </div>
            )}
            <span
              className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  settings
                </Icon>
                Service Type:{' '}
              </b>
              {
                _.find(props.serviceTypes, { ServiceType: scope.ServiceType })
                  .Description
              }
            </span>
            <br />
            <span
              className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon
                  className='no-print mr-6 text-14 align-middle mb-4'
                  style={{
                    color: props.priorities[scope.Priority].color,
                    paddingTop: 1,
                  }}
                >
                  flag
                </Icon>
                Priority:{' '}
              </b>
              {`${props.priorities[scope.Priority].text}`}
            </span>
            <br />
            {scope.Data.ArrivalWindow && (
              <span
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    access_time
                  </Icon>
                  Arrival Window:{' '}
                </b>
                {`${scope.Data.ArrivalWindow.Description}`}
                <br />
              </span>
            )}
            {props.rateTemplates &&
              scope.RateTemplate &&
              scope.PriceMethod === 'T' && (
                <span
                  onClick={() => {
                    props.editScopes[scope.Scope] = !props.editScopes[
                      scope.Scope
                    ]
                      ? scope
                      : null
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editScopes,
                    })
                  }}
                  className={classNames(
                    increaseFontSize
                      ? 'text-16 cursor-pointer'
                      : 'text-12 cursor-pointer',
                  )}
                  style={{
                    pointerEvents: props.assignedTrip ? 'auto' : 'none',
                  }}
                >
                  <b
                    className={classNames(
                      increaseFontSize ? 'text-16' : 'text-12',
                    )}
                  >
                    <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                      monetization_on
                    </Icon>
                    Rate Template:{' '}
                  </b>
                  {
                    (
                      _.find(props.rateTemplates, {
                        BusinessUnit: scope.BusinessUnit,
                        Division: scope.Division,
                        RateTemplate: scope.RateTemplate,
                      }) || {}
                    ).Description
                  }
                  <Icon
                    style={{
                      verticalAlign: 'middle',
                      visibility: props.assignedTrip ? 'visible' : 'hidden',
                    }}
                    className='text-24 align-middle ml-4 cursor-pointer'
                  >
                    {props.editScopes[scope.Scope]
                      ? 'keyboard_arrow_up'
                      : 'keyboard_arrow_down'}
                  </Icon>
                  <br />
                </span>
              )}
            {scope.PriceMethod === 'F' && (
              <span
                onClick={() => {
                  props.editScopes[scope.Scope] = !props.editScopes[scope.Scope]
                    ? scope
                    : null
                  props.setStateFxn({ ...props.thisState, ...props.editScopes })
                }}
                className={classNames(
                  increaseFontSize
                    ? 'text-16 cursor-pointer'
                    : 'text-12 cursor-pointer',
                )}
                style={{ pointerEvents: props.assignedTrip ? 'auto' : 'none' }}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    monetization_on
                  </Icon>
                  Flat Price:{' '}
                </b>
                {formatDollars(scope.Price)}
                <Icon
                  style={{
                    verticalAlign: 'middle',
                    visibility: props.assignedTrip ? 'visible' : 'hidden',
                  }}
                  className='text-24 align-middle ml-4 cursor-pointer'
                >
                  {props.editScopes[scope.Scope]
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'}
                </Icon>
                <br />
              </span>
            )}
            {props.editScopes[scope.Scope] && (
              <div className='w-full sm:w-1/2 md:w-1/3 xl:w-1/4 mt-12 mb-12'>
                <TextField
                  className='mb-12'
                  label='Price Method'
                  id='priceMethod'
                  name={`scope${scope.Scope}PriceMethod`}
                  value={props.editScopes[scope.Scope].PriceMethod}
                  onChange={e => {
                    props.editScopes[scope.Scope].PriceMethod = e.target.value
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editScopes,
                    })
                  }}
                  variant='outlined'
                  required
                  select
                  fullWidth
                  disabled={
                    scope.NonBillableYN === 'Y' || Boolean(scope.InvoiceNumber)
                  }
                >
                  <MenuItem
                    value='T'
                    disabled={
                      props.woOpt && props.woOpt.DisableTMBillingYN === 'Y'
                    }
                  >
                    Rate Template
                  </MenuItem>
                  <MenuItem value='F'>Flat Price</MenuItem>
                </TextField>
                {props.editScopes[scope.Scope].PriceMethod == 'F' ? (
                  <TextField
                    className='mb-12'
                    label='Price'
                    id='price'
                    name={`scope${scope.Scope}Price`}
                    value={props.editScopes[scope.Scope].Price}
                    onChange={e => {
                      props.editScopes[scope.Scope].Price = e.target.value
                      props.setStateFxn({
                        ...props.thisState,
                        ...props.editScopes,
                      })
                    }}
                    variant='outlined'
                    type='number'
                    required
                    fullWidth
                    disabled={
                      scope.NonBillableYN == 'Y'
                        ? true
                        : Boolean(scope.InvoiceNumber)
                    }
                  />
                ) : (
                  <Autocomplete
                    className='mb-12'
                    title='Rate Template'
                    options={
                      props.rateTemplates
                        ? _.filter(props.rateTemplates, {
                            BusinessUnit: scope.BusinessUnit,
                            Division: scope.Division,
                          })
                        : []
                    }
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.RateTemplate}>
                          {`${value.Description}`}
                        </MenuItem>
                      )
                    }}
                    noflip={true}
                    value={props.editScopes[scope.Scope].RateTemplate}
                    onSelect={option => {
                      props.editScopes[scope.Scope].RateTemplate =
                        option.RateTemplate
                      props.setStateFxn({
                        ...props.thisState,
                        ...props.editScopes,
                      })
                    }}
                    required
                    disabled={Boolean(scope.InvoiceNumber)}
                  />
                )}
                <TextField
                  className='mb-12'
                  label='Taxable?'
                  id='taxableYN'
                  name={`scope${scope.Scope}TaxableYN`}
                  value={props.editScopes[scope.Scope].TaxableYN}
                  onChange={e => {
                    props.editScopes[scope.Scope].TaxableYN = e.target.value
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editScopes,
                    })
                  }}
                  variant='outlined'
                  required
                  fullWidth
                  select
                  disabled={Boolean(scope.InvoiceNumber)}
                >
                  <MenuItem value={'N'}>No</MenuItem>
                  <MenuItem value={'Y'}>Yes</MenuItem>
                </TextField>
                <Button
                  variant='contained'
                  className='mb-12'
                  color='primary'
                  onClick={() => {
                    props.updateWorkOrderScope({
                      ...scope,
                      PriceMethod: props.editScopes[scope.Scope].PriceMethod,
                      Price: props.editScopes[scope.Scope].Price,
                      RateTemplate: props.editScopes[scope.Scope].RateTemplate,
                      TaxableYN: props.editScopes[scope.Scope].TaxableYN,
                    })
                    props.editScopes[scope.Scope] = null
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editScopes,
                    })
                  }}
                  fullWidth
                  disabled={Boolean(scope.InvoiceNumber)}
                >
                  Update
                </Button>
              </div>
            )}
            {/*Assign Estimate*/}
            <span
              onClick={() => {
                props.editQuotes[scope.Scope] = !props.editQuotes[scope.Scope]
                  ? scope
                  : null
                props.setStateFxn({ ...props.thisState, ...props.editQuotes })
                window['warn'](scope.Data.Estimate, openQuotes[0])
              }}
              style={{
                verticalAlign: 'middle',
                pointerEvents: props.assignedTrip ? 'auto' : 'none',
              }}
              className='text-12 cursor-pointer'
            >
              <b className='text-12'>
                <Icon
                  className='no-print mr-6 text-14 align-middle mb-4'
                  style={{ transform: 'rotate(-90deg)' }}
                >
                  attachment
                </Icon>
                Estimate:{' '}
              </b>
              {scope.Data.Estimate
                ? scope.Data.Estimate.EstimateNumber
                : openQuotes && openQuotes.length !== 0
                  ? openQuotes[0].EstimateNumber
                  : 'None'}
              <Icon
                className='no-print text-24 align-middle ml-4 cursor-pointer'
                style={{
                  visibility: props.assignedTrip ? 'visible' : 'hidden',
                }}
              >
                {props.editQuotes[scope.Scope]
                  ? 'keyboard_arrow_up'
                  : 'keyboard_arrow_down'}
              </Icon>
              <br />
            </span>
            {props.editQuotes[scope.Scope] && (
              <div className='w-full flex no-wrap overflow-x-auto'>
                {anchorQuote && anchorQuoteEl && (
                  <Menu
                    id='quote-menu'
                    anchorEl={anchorQuoteEl}
                    open={Boolean(anchorQuote)}
                    onClose={() => {
                      setAnchorQuote(null)
                      setAnchorQuoteEl(null)
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        props.openEditEstimateDialog(anchorQuote)
                        setAnchorQuote(null)
                        setAnchorQuoteEl(null)
                      }}
                    >
                      <Icon className='no-print mr-8'>visibility_on</Icon>View
                      Estimate
                    </MenuItem>
                    {!scope.InvoiceNumber &&
                      (!anchorQuote.OptionBoard ||
                        anchorQuote.SelectedOption) && (
                        <MenuItem
                          onClick={() => {
                            props.updateWorkOrderScope({
                              ...scope,
                              Estimate: anchorQuote.Estimate,
                              EstimateBusinessUnit: anchorQuote.BusinessUnit,
                              EstimateDivision: anchorQuote.Division,
                            })
                            setAnchorQuote(null)
                            setAnchorQuoteEl(null)
                          }}
                        >
                          <Icon
                            className='no-print mr-8'
                            style={{ transform: 'rotate(-90deg)' }}
                          >
                            attachment
                          </Icon>
                          Assign to Scope
                        </MenuItem>
                      )}
                  </Menu>
                )}
                {openQuotes.map(qt => {
                  return (
                    <div
                      onClick={e => {
                        setAnchorQuote(qt)
                        setAnchorQuoteEl(e.target)
                      }}
                      className='w-128 min-w-128 h-172 min-h-172 p-12 mr-12 mb-8 relative cursor-pointer'
                      style={{
                        backgroundImage:
                          'url(assets/images/icons/single_estimate.png)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        opacity:
                          qt.OptionBoard && !qt.SelectedOption ? 0.35 : 1,
                      }}
                    >
                      <Typography
                        className={classNames(
                          props.classes.blue,
                          increaseFontSize
                            ? 'w-full text-center text-16 font-bold text-white rounded mb-6'
                            : 'w-full text-center text-12 font-bold text-white rounded mb-6',
                        )}
                      >
                        {qt.EstimateNumber}
                      </Typography>
                      <Typography
                        className={classNames(
                          props.classes.grey,
                          increaseFontSize
                            ? 'w-full text-center text-16 font-bold text-white rounded mb-6'
                            : 'w-full text-center text-12 font-bold text-white rounded mb-6',
                        )}
                      >
                        {moment(qt.IssuedDate).format('M/D/YYYY')}
                      </Typography>
                      {qt.Estimate === scope.Estimate && (
                        <Icon
                          color='secondary'
                          className='text-72 absolute pin-l pin-r pin-b w-full text-center'
                          style={{
                            textShadow: '2px 2px 4px rgba(0, 0, 0, .5)',
                            marginBottom: 42,
                          }}
                        >
                          check_circle_outline
                        </Icon>
                      )}
                      <Typography
                        style={{ left: 12, right: 12, bottom: 12 }}
                        className={classNames(
                          props.classes.black,
                          'text-center text-12 font-bold text-white rounded pin-b absolute mb-8',
                        )}
                      >
                        {!qt.OptionBoard || qt.SelectedOption
                          ? formatDollars(qt.PriceTotal || 0)
                          : `${
                              qt.OptionCount ||
                              qt.Data.OptionBoard.Data.Options.length
                            } Options`}
                      </Typography>
                    </div>
                  )
                })}
                {!props.trip ||
                  (props.trip.Status >= 4 && (
                    <div
                      onClick={props.launchEstimate}
                      className={classNames(
                        'h-36 w-128 min-w-128 relative cursor-pointer',
                        openQuotes.length > 0
                          ? 'pl-0 h-172 min-h-172'
                          : 'pl-16',
                      )}
                    >
                      <div className='h-24 absolute pin-t pin-b m-auto text-12 font-bold'>
                        <Icon
                          color='primary'
                          className='mr-4 align-middle mb-4 text-16'
                        >
                          add_circle_outline
                        </Icon>
                        Add New
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {props.formOfPayments && scope.FormOfPayment && (
              <span
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    payment
                  </Icon>
                  Form of Payment:{' '}
                </b>
                {
                  _.find(props.formOfPayments, {
                    BusinessUnit: scope.BusinessUnit,
                    FormOfPayment: scope.FormOfPayment,
                  }).Description
                }
                <br />
              </span>
            )}
            <span
              className={classNames(
                increaseFontSize ? 'text-16' : 'text-12',
                'cursor-pointer',
              )}
              onClick={() => {
                props.editWarranties[scope.Scope] = !props.editWarranties[
                  scope.Scope
                ]
                  ? scope
                  : null
                props.setStateFxn({
                  ...props.thisState,
                  ...props.editWarranties,
                })
              }}
              style={{ pointerEvents: props.assignedTrip ? 'auto' : 'none' }}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  build
                </Icon>
                Warranty {!isWarranty ? 'Work' : 'Technician'}:{' '}
              </b>
              {scope.IsWarrantyYN === 'Y'
                ? warrantyTech
                  ? `${warrantyTech.FirstName} ${warrantyTech.LastName}`
                  : 'Yes'
                : 'No'}
              <Icon
                className='no-print text-24 align-middle ml-4 cursor-pointer'
                style={{
                  visibility: props.assignedTrip ? 'visible' : 'hidden',
                }}
              >
                {props.editWarranties[scope.Scope]
                  ? 'keyboard_arrow_up'
                  : 'keyboard_arrow_down'}
              </Icon>
              <br />
            </span>
            {props.editWarranties[scope.Scope] && (
              <div className='w-full sm:w-1/2 md:w-1/3 xl:w-1/4 mt-12 mb-12'>
                <TextField
                  className='mb-12'
                  label='Warranty Work?'
                  id='isWarrantyYN'
                  name={`scope${scope.Scope}IsWarrantyYN`}
                  value={props.editWarranties[scope.Scope].IsWarrantyYN || 'N'}
                  onChange={e => {
                    props.editWarranties[scope.Scope].IsWarrantyYN =
                      e.target.value
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editWarranties,
                    })
                  }}
                  variant='outlined'
                  required
                  select
                  fullWidth
                  disabled={Boolean(scope.InvoiceNumber)}
                >
                  <MenuItem value='N'>No</MenuItem>
                  <MenuItem value='Y'>Yes</MenuItem>
                </TextField>
                {props.editWarranties[scope.Scope].IsWarrantyYN === 'Y' && (
                  <Autocomplete
                    className='mb-12'
                    title='Warranty Technician'
                    options={props.technicians}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.Technician}>
                          <div className='flex'>
                            <Avatar
                              style={{ marginRight: 8 }}
                              classes={{ root: props.classes.avatarRoot }}
                              className={props.classes.techSelAvatar}
                              alt={value.Technician}
                              src={
                                value.Data && value.Data.Avatar
                                  ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`
                                  : props.avatar
                              }
                            />
                            {`${value.FirstName} ${value.LastName}`}
                          </div>
                        </MenuItem>
                      )
                    }}
                    value={
                      props.editWarranties[scope.Scope].WarrantyTechnician ||
                      null
                    }
                    onSelect={option => {
                      props.editWarranties[scope.Scope].WarrantyTechnician =
                        option.Technician
                      props.setStateFxn({
                        ...props.thisState,
                        ...props.editWarranties,
                      })
                    }}
                    required
                  />
                )}
                <Button
                  variant='contained'
                  className='mb-12'
                  color='primary'
                  onClick={() => {
                    props.updateWorkOrderScope({
                      ...scope,
                      IsWarrantyYN:
                        props.editWarranties[scope.Scope].IsWarrantyYN,
                      WarrantyTechnician:
                        props.editWarranties[scope.Scope].WarrantyTechnician,
                    })
                    props.editWarranties[scope.Scope] = null
                    props.setStateFxn({
                      ...props.thisState,
                      ...props.editWarranties,
                    })
                  }}
                  fullWidth
                >
                  Update
                </Button>
              </div>
            )}
            {scope.CustomerPO && (
              <span
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    receipt
                  </Icon>
                  Customer PO:{' '}
                </b>
                #{scope.CustomerPO}
                <br />
              </span>
            )}
            {scope.NotToExceed && (
              <span
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    vertical_align_top
                  </Icon>
                  Not To Exceed:{' '}
                </b>
                {formatDollars(scope.NotToExceed)}
                <br />
              </span>
            )}
            {scope.IsWarrantyYN !== 'Y' && (
              <span
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    monetization_on
                  </Icon>
                  Billable Amount:{' '}
                </b>
                {formatDollars(billableAmt)}
                <br />
              </span>
            )}
            {scope.NonBillableYN != 'Y' && (
              <span
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <b
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    playlist_add_check
                  </Icon>
                  Ready to Bill:{' '}
                </b>
                <Switch
                  classes={{ switchBase: props.classes.switchBase }}
                  checked={scope.ReadyToBillYN === 'Y'}
                  name='ReadyToBillYN'
                  onChange={() => {
                    const scp = _.find(Data.Scopes, { Scope: scope.Scope })
                    if (scp) {
                      const rtb = scp.ReadyToBillYN === 'Y' ? 'N' : 'Y'
                      scp.ReadyToBillYN = rtb
                      props.setStateFxn({ ...props.thisState, Data }, () => {
                        props.updateWorkOrderScope({
                          ...scope,
                          ReadyToBillYN: rtb,
                        })
                      })
                    }
                  }}
                  color='secondary'
                  size='small'
                  disabled={
                    scope.InvoiceNumber
                      ? true
                      : props.assignedTrip
                        ? false
                        : true
                  }
                />
                <br />
              </span>
            )}
            {scope.Notes && (
              <React.Fragment>
                <span
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <b
                    className={classNames(
                      increaseFontSize ? 'text-16' : 'text-12',
                    )}
                  >
                    <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                      notes
                    </Icon>
                    Notes:{' '}
                  </b>
                  {scope.Notes}
                </span>
                <br />
              </React.Fragment>
            )}
            {scope.Data.Tags && scope.Data.Tags.length > 0 && (
              <React.Fragment>
                <span
                  className={classNames(
                    increaseFontSize ? 'text-16' : 'text-12',
                  )}
                >
                  <b
                    className={classNames(
                      increaseFontSize ? 'text-16' : 'text-12',
                    )}
                  >
                    <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                      label
                    </Icon>
                    Tags:{' '}
                  </b>
                </span>
                <br />
                <div className='pl-16 pt-4'>
                  {scope.Data.Tags.map((tag, index) => {
                    return (
                      <Chip
                        icon={<Icon className='no-print text-20'>build</Icon>}
                        key={index}
                        label={tag.Title}
                        className={props.classes.tag}
                        color='primary'
                      />
                    )
                  })}
                </div>
              </React.Fragment>
            )}
            <span
              className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
            >
              <b
                className={classNames(increaseFontSize ? 'text-16' : 'text-12')}
              >
                <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                  person_pin
                </Icon>
                Assigned To:{' '}
              </b>
              <div className='w-full flex my-4 pl-4'>
                <FuseAnimateGroup
                  className='flex'
                  enter={{
                    animation: 'transition.slideUpBigIn',
                  }}
                  leave={{
                    animation: 'transition.slideUpBigOut',
                  }}
                >
                  {assignedTechs.map((tech, index) => {
                    return (
                      <div className='mr-12'>
                        <Tooltip
                          placement='top'
                          title={`${tech.FirstName} ${tech.LastName}`}
                          enterTouchDelay={100}
                        >
                          <Badge
                            classes={{
                              badge: classNames(props.classes.techNameBadge),
                            }}
                            badgeContent={`${tech.FirstName.substr(
                              0,
                              1,
                            )}${tech.LastName.substr(0, 1)}`}
                          >
                            <Avatar
                              classes={{ root: props.classes.techAvatarRoot }}
                              className={props.classes.techAvatar}
                              alt={tech.name}
                              src={
                                tech.Data && tech.Data.Avatar
                                  ? `${
                                      window['apiLocation']
                                    }/api/Attachment/Get${
                                      props.pdf ? 'PDF' : 'Profile'
                                    }Image?Co=${tech.Co}&ID=${
                                      tech.Data.Avatar
                                    }&Thumb=true`
                                  : props.techAvatar
                              }
                            />
                          </Badge>
                        </Tooltip>
                      </div>
                    )
                  })}
                </FuseAnimateGroup>
                <div className='mr-6 mt-6 cursor-pointer'>
                  {props.tripAccessLevel &&
                    props.tripAccessLevel.AccessLevel !== 'R' && (
                      <Tooltip title='New Trip' placement='right'>
                        <Icon
                          style={{
                            display: props.assignedTrip ? 'inline' : 'none',
                          }}
                          color='primary'
                          onClick={event => {
                            props.openNewTripDialog({
                              Co: props.Co,
                              WorkOrder: props.WorkOrder,
                              Trip: null,
                              Technician: null,
                              ScheduledDate: null,
                              ScheduledTime: null,
                              Status: 0,
                              Duration:
                                scope.Data.Service.DefaultDuration || 0.5,
                              CreatedBy: props.user.UserName,
                              CreatedDate: new Date(),
                              Data: {
                                ErrMsg: null,
                                Tags: [],
                                Scopes: [...Data.Scopes],
                                Assignments: [
                                  {
                                    Co: props.Co,
                                    WorkOrder: props.WorkOrder,
                                    Scope: scope.Scope,
                                  },
                                ],
                              },
                            })
                          }}
                        >
                          add
                        </Icon>
                      </Tooltip>
                    )}
                </div>
              </div>
            </span>
            {((scope.Data.Forms && scope.Data.Forms.length > 0) ||
              (scope.Data.FormResults && scope.Data.FormResults.length > 0) ||
              (scope.Data.Checklists && scope.Data.Checklists.length > 0) ||
              (scope.Data.ChecklistResults &&
                scope.Data.ChecklistResults.length > 0)) && (
              <div
                style={{ display: props.assignedTrip ? 'inline' : 'none' }}
                className='text-12 w-full'
              >
                <b className='text-12'>
                  <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                    assignment_turned_in
                  </Icon>
                  Forms & Checklists:{' '}
                </b>
                <div className='w-full flex mt-4 pl-4'>
                  <FuseAnimateGroup
                    className='flex w-full'
                    enter={{
                      animation: 'transition.slideUpBigIn',
                    }}
                    leave={{
                      animation: 'transition.slideUpBigOut',
                    }}
                  >
                    <CardContent
                      classes={{
                        root: classNames(
                          'w-full pt-0 pr-4',
                          props.classes.taskForms,
                        ),
                      }}
                    >
                      {scopeChecklists.map((checklist, index) => {
                        window['warn']('Checklist: ', checklist)
                        return (
                          <MenuItem
                            disabled={Boolean(!props.assignedTrip)}
                            className='font-bold mb-16 relative py-8 text-14'
                            style={{
                              border: '1px dotted lightgrey',
                              borderRadius: 5,
                            }}
                            key={index}
                            onClick={() =>
                              props.setStateFxn({
                                ...props.thisState,
                                selectedChecklist: checklist,
                                selectedScope: scope,
                              })
                            }
                          >
                            <Icon
                              className={classNames(
                                'no-print cursor-pointer mr-6',
                                !checklist.EnteredBy && props.classes.greyText,
                              )}
                              color={checklist.EnteredBy && 'primary'}
                            >
                              assignment_turned_in
                            </Icon>
                            {checklist.Name}
                            <Icon
                              className={classNames(
                                'no-print cursor-pointer absolute pin-r pin-t pin-b m-auto mr-12 mt-12',
                                props.classes.greyText,
                              )}
                            >
                              keyboard_arrow_right
                            </Icon>
                          </MenuItem>
                        )
                      })}
                      {scopeForms.map((form, index) => {
                        return (
                          <MenuItem
                            className='font-bold mb-16 relative py-8 text-14'
                            style={{
                              border: '1px dotted lightgrey',
                              borderRadius: 5,
                            }}
                            key={index}
                            onClick={() =>
                              props.setStateFxn({
                                ...props.thisState,
                                selectedForm: form,
                                selectedScope: scope,
                              })
                            }
                          >
                            <Icon
                              className={classNames(
                                'no-print cursor-pointer mr-6',
                                !form.EnteredBy && props.classes.greyText,
                              )}
                              color={form.EnteredBy && 'primary'}
                            >
                              assignment
                            </Icon>
                            {form.Name}
                            <Icon
                              className={classNames(
                                'no-print cursor-pointer absolute pin-r pin-t pin-b m-auto mr-12',
                                props.classes.greyText,
                              )}
                            >
                              keyboard_arrow_right
                            </Icon>
                          </MenuItem>
                        )
                      })}
                    </CardContent>
                  </FuseAnimateGroup>
                </div>
              </div>
            )}
            {est && est.Data && est.Data.Components && (
              <div
                className='w-full p-8 my-12 cursor-pointer'
                onClick={() =>
                  props.assignedTrip
                    ? props.openEditEstimateDialog(est)
                    : console.log()
                }
                style={{ borderTop: '1px solid lightgrey' }}
              >
                <div className='w-full flex justify-between'>
                  <Chip
                    classes={{
                      root: 'mt-12',
                      avatar: props.classes.chipAvatar,
                      label: props.classes.chipLabel,
                    }}
                    avatar={
                      <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                    }
                    label={`Estimate #${est.EstimateNumber}`}
                    // color="primary"
                    className={props.classes.chip}
                  />
                  {!scope.InvoiceNumber && (
                    <Button
                      variant='contained'
                      className={classNames(
                        props.classes.black,
                        'h-24 mt-12 font-bold text-12 px-8',
                      )}
                      onClick={e => {
                        e.stopPropagation()
                        props.setStateFxn(
                          { ...props.thisState, Estimate: null, Price: 0 },
                          () =>
                            props.updateWorkOrderScope({
                              ...scope,
                              Estimate: null,
                              Price: 0,
                            }),
                        )
                      }}
                    >
                      <Icon
                        className='no-print text-16 mr-4 align-middle'
                        color='error'
                      >
                        remove_circle_outline
                      </Icon>
                      Remove
                    </Button>
                  )}
                </div>
                <div className='w-full px-12'>
                  <strong className='mr-4 mb-4'>Description of Work:</strong>
                  <div className='text-12 w-full mb-16'>{est.Description}</div>
                  <div>
                    {_.filter(est.Data.Components, o => o.DeleteYN !== 'Y').map(
                      (value, index) => {
                        const {
                          Co,
                          BusinessUnit,
                          Division,
                          Assembly,
                          Material,
                          LaborType,
                          Qty,
                          UOM,
                        } = value
                        const assembly = value.Data.Assembly
                        const material = _.find(props.materials, {
                          Co,
                          Material,
                        })
                        const laborType = _.find(props.laborTypes, {
                          Co,
                          BusinessUnit,
                          Division,
                          LaborType,
                        })
                        let usage = null,
                          labor = [],
                          materials = [],
                          misc = []
                        // window["warn"](value, assembly, assemblies);
                        if (assembly) {
                          if (assembly.Data && assembly.Data.Images) {
                            assembly.Data.Image = _.find(assembly.Data.Images, {
                              PrimaryYN: 'Y',
                            })
                            if (
                              !assembly.Data.Image &&
                              assembly.Data.Images.length > 0
                            ) {
                              assembly.Data.Image = assembly.Data.Images[0]
                            }
                          }
                          if (
                            assembly &&
                            assembly.Data &&
                            assembly.Data.Usage
                          ) {
                            usage = _.filter(
                              assembly.Data.Usage,
                              o =>
                                o.Type !== 1 ||
                                (o.Type === 1 &&
                                  (o.AddOnYN || 'N') ===
                                    (value.AddOnYN || 'N')),
                            )
                            labor = _.filter(usage, { Type: 1 })
                            materials = _.filter(usage, { Type: 2 })
                            misc = _.filter(usage, { Type: 0 })
                          }

                          return (
                            <div className='w-full'>
                              <div
                                className='prevent-split flex mb-8 pt-6'
                                style={{ borderTop: '1px solid #f1f1f1' }}
                              >
                                <div className='w-1/6 pt-6 pr-6'>
                                  <img
                                    alt={assembly.Code}
                                    crossOrigin='Anonymous'
                                    className='w-full component-img'
                                    src={
                                      assembly.Data.Image
                                        ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                        : 'assets/images/logos/SP_Cog_Gradient.png'
                                    }
                                  />
                                </div>
                                <div className='w-full whitespace-normal'>
                                  <Typography
                                    className={classNames(
                                      increaseFontSize ? 'text-20' : 'text-16',
                                      'w-full font-bold',
                                    )}
                                  >
                                    <div
                                      className={classNames(props.classes.qty)}
                                    >
                                      {value.Qty} EA
                                    </div>
                                    {value.Name || assembly.Name}
                                    {assembly.Code && (
                                      <Typography
                                        color='secondary'
                                        className={classNames(
                                          increaseFontSize
                                            ? 'text-16'
                                            : 'text-12',
                                          'font-bold mt-4 float-right',
                                        )}
                                      >
                                        {assembly.Code}
                                      </Typography>
                                    )}
                                  </Typography>
                                  <Typography
                                    className={classNames(
                                      increaseFontSize ? 'text-16' : 'text-12',
                                      'clear',
                                    )}
                                    variant='caption'
                                  >
                                    {parse(
                                      value.Description || assembly.Description,
                                    )}
                                  </Typography>
                                  {(value.Detail || assembly.Detail) && (
                                    <Divider className='my-8' />
                                  )}
                                  {(value.Detail || assembly.Detail) && (
                                    <Typography
                                      className={classNames(
                                        increaseFontSize
                                          ? 'text-16'
                                          : 'text-12',
                                        'clear',
                                      )}
                                      variant='caption'
                                    >
                                      {value.Detail || assembly.Detail}
                                    </Typography>
                                  )}
                                  {/* <Typography className="font-bold text-12 my-12 relative">Qty: {value.Qty}
                                                                    <Typography className="font-bold text-12 float-right">{formatDollars(value.Price)}</Typography>
                                                                </Typography> */}
                                  {usage.length > 0 && (
                                    <div
                                      className='w-full min-w-288 max-w-288 sm:max-w-full mt-8'
                                      onClick={e => e.stopPropagation()}
                                    >
                                      <Divider className='m-0' />
                                      <div
                                        className='w-full pt-16 pb-6'
                                        onClick={() =>
                                          setExpandedComponents({
                                            ...expandedComponents,
                                            [`Task${value.Component}`]:
                                              !expandedComponents[
                                                `Task${value.Component}`
                                              ]
                                                ? { materials: true }
                                                : false,
                                          })
                                        }
                                      >
                                        <Typography
                                          className={classNames(
                                            increaseFontSize
                                              ? 'text-16'
                                              : 'text-12',
                                            'w-full font-bold mb-4 flex justify-between',
                                          )}
                                        >
                                          <div className='flex w-full'>
                                            <Icon
                                              color='primary'
                                              className={classNames(
                                                increaseFontSize
                                                  ? 'text-20'
                                                  : 'text-16',
                                                'mr-4 align-middle mb-4',
                                              )}
                                            >
                                              format_list_numbered
                                            </Icon>
                                            Task Components
                                          </div>
                                          <div className='flex'>
                                            <div
                                              className={classNames(
                                                increaseFontSize
                                                  ? 'text-14 min-w-20 h-20 px-6'
                                                  : 'text-11 min-w-16 h-16 px-4',
                                                'mr-4 rounded-full bg-red text-white font-bold',
                                              )}
                                            >
                                              {usage.length}
                                            </div>
                                            <Icon
                                              color='action'
                                              className={classNames(
                                                increaseFontSize
                                                  ? 'text-20'
                                                  : 'text-16',
                                                'mr-4 align-middle mb-2',
                                              )}
                                            >
                                              {!expandedComponents[
                                                `Task${value.Component}`
                                              ]
                                                ? 'keyboard_arrow_down'
                                                : 'keyboard_arrow_up'}
                                            </Icon>
                                          </div>
                                        </Typography>
                                      </div>
                                      {expandedComponents[
                                        `Task${value.Component}`
                                      ] && (
                                        <Hidden xsDown>
                                          <div
                                            className='w-full sm:flex border-1 rounded-lg border-grey justify-between'
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <Button
                                              variant={
                                                expandedComponents[
                                                  `Task${value.Component}`
                                                ]['labor']
                                                  ? 'contained'
                                                  : undefined
                                              }
                                              onClick={() =>
                                                setExpandedComponents({
                                                  ...expandedComponents,
                                                  [`Task${value.Component}`]: {
                                                    labor:
                                                      !expandedComponents[
                                                        `Task${value.Component}`
                                                      ]['labor'],
                                                  },
                                                })
                                              }
                                              className='w-full text-12 rounded-b-none sm:rounded-bl-lg sm:rounded-r-none sm:rounded-l-lg'
                                              color='primary'
                                              disabled={!labor.length}
                                            >
                                              Labor
                                              {labor.length > 0 && (
                                                <div
                                                  style={{ lineHeight: '16px' }}
                                                  className='ml-4 rounded-full bg-red px-4 text-white text-11 min-w-16 h-16 font-bold'
                                                >
                                                  {(
                                                    _.sumBy(labor, 'Qty') *
                                                    value.Qty
                                                  ).toFixed(2)}
                                                </div>
                                              )}
                                            </Button>
                                            <Button
                                              variant={
                                                expandedComponents[
                                                  `Task${value.Component}`
                                                ]['materials']
                                                  ? 'contained'
                                                  : undefined
                                              }
                                              onClick={() =>
                                                setExpandedComponents({
                                                  ...expandedComponents,
                                                  [`Task${value.Component}`]: {
                                                    materials:
                                                      !expandedComponents[
                                                        `Task${value.Component}`
                                                      ]['materials'],
                                                  },
                                                })
                                              }
                                              className='w-full text-12 rounded-none border-solid border-b border-t border-grey-light sm:border-b-0 sm:border-t-none sm:border-l sm:border-r'
                                              color='primary'
                                              disabled={!materials.length}
                                            >
                                              Materials
                                              {materials.length > 0 && (
                                                <div
                                                  style={{ lineHeight: '16px' }}
                                                  className='ml-4 rounded-full bg-red px-4 text-white text-11 min-w-16 h-16 font-bold'
                                                >
                                                  {formatDollars(
                                                    _.sumBy(
                                                      materials,
                                                      'TotalCost',
                                                    ) * value.Qty,
                                                  )}
                                                </div>
                                              )}
                                            </Button>
                                            <Button
                                              variant={
                                                expandedComponents[
                                                  `Task${value.Component}`
                                                ]['misc']
                                                  ? 'contained'
                                                  : undefined
                                              }
                                              onClick={() =>
                                                setExpandedComponents({
                                                  ...expandedComponents,
                                                  [`Task${value.Component}`]: {
                                                    misc: !expandedComponents[
                                                      `Task${value.Component}`
                                                    ]['misc'],
                                                  },
                                                })
                                              }
                                              className='w-full text-12 rounded-b-none sm:rounded-br-lg sm:rounded-l-none sm:rounded-r-lg'
                                              color='primary'
                                              disabled={!misc.length}
                                            >
                                              Misc
                                              {misc.length > 0 && (
                                                <div
                                                  style={{ lineHeight: '16px' }}
                                                  className='ml-4 rounded-full bg-red px-4 text-white text-11 min-w-16 h-16 font-bold'
                                                >
                                                  {formatDollars(
                                                    _.sumBy(misc, 'TotalCost') *
                                                      value.Qty,
                                                  )}
                                                </div>
                                              )}
                                            </Button>
                                          </div>
                                        </Hidden>
                                      )}
                                      {expandedComponents[
                                        `Task${value.Component}`
                                      ] && (
                                        <Hidden smUp>
                                          <div
                                            className=''
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <Accordion disabled={!labor.length}>
                                              <AccordionSummary
                                                classes={{
                                                  content: 'text-14 font-bold',
                                                }}
                                                expandIcon={
                                                  <Icon>expand_more</Icon>
                                                }
                                              >
                                                Labor
                                                {labor.length > 0 && (
                                                  <div
                                                    style={{
                                                      lineHeight: '16px',
                                                    }}
                                                    className='ml-4 rounded-full bg-red px-4 text-white text-11 min-w-16 h-16 font-bold mt-2'
                                                  >
                                                    {(
                                                      _.sumBy(labor, 'Qty') *
                                                      value.Qty
                                                    ).toFixed(2)}
                                                  </div>
                                                )}
                                              </AccordionSummary>
                                              <AccordionDetails
                                                classes={{
                                                  root: 'px-8 py-0 border-t border-grey bg-grey-lighter',
                                                }}
                                              >
                                                <div
                                                  className='w-full pt-6'
                                                  onClick={e =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  {labor.map(rec => {
                                                    return (
                                                      <div className='w-full flex justify-between px-6 mb-6 text-12 font-bold'>
                                                        <div className='w-full truncate flex'>
                                                          <SPRoundIcon
                                                            color='action'
                                                            className='text-16 mr-4'
                                                          />
                                                          <div className='truncate'>{`${(
                                                            value.Qty * rec.Qty
                                                          ).toFixed(2)} ${
                                                            rec.UOM
                                                          } - ${
                                                            rec.Description
                                                          } (${Math.round(
                                                            value.Qty *
                                                              rec.Qty *
                                                              60,
                                                          )} Minutes)`}</div>
                                                        </div>
                                                        <div>
                                                          {formatDollars(
                                                            value.Qty *
                                                              rec.TotalCost,
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                              </AccordionDetails>
                                            </Accordion>
                                            <Accordion
                                              disabled={!materials.length}
                                            >
                                              <AccordionSummary
                                                classes={{
                                                  content: 'text-14 font-bold',
                                                }}
                                                expandIcon={
                                                  <Icon>expand_more</Icon>
                                                }
                                              >
                                                Materials
                                                {materials.length > 0 && (
                                                  <div
                                                    style={{
                                                      lineHeight: '16px',
                                                    }}
                                                    className='ml-4 rounded-full bg-red px-4 text-white text-11 min-w-16 h-16 font-bold mt-2'
                                                  >
                                                    {formatDollars(
                                                      value.Qty *
                                                        _.sumBy(
                                                          materials,
                                                          'TotalCost',
                                                        ),
                                                    )}
                                                  </div>
                                                )}
                                              </AccordionSummary>
                                              <AccordionDetails
                                                classes={{
                                                  root: 'px-8 py-0 border-t border-grey bg-grey-lighter',
                                                }}
                                              >
                                                <div
                                                  className='w-full pt-6'
                                                  onClick={e =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  {materials.map(rec => {
                                                    return (
                                                      <div className='w-full flex justify-between px-6 mb-6 text-12 font-bold'>
                                                        <div className='w-full truncate flex'>
                                                          <SPRoundIcon
                                                            color='action'
                                                            className='text-16 mr-4'
                                                          />
                                                          <div className='truncate'>{`${(
                                                            value.Qty * rec.Qty
                                                          ).toFixed(2)} ${
                                                            rec.UOM
                                                          } - ${
                                                            rec.Description
                                                          }`}</div>
                                                        </div>
                                                        <div>
                                                          {formatDollars(
                                                            value.Qty *
                                                              rec.TotalCost,
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                              </AccordionDetails>
                                            </Accordion>
                                            <Accordion disabled={!misc.length}>
                                              <AccordionSummary
                                                classes={{
                                                  content: 'text-14 font-bold',
                                                }}
                                                expandIcon={
                                                  <Icon>expand_more</Icon>
                                                }
                                              >
                                                Misc
                                                {misc.length > 0 && (
                                                  <div
                                                    style={{
                                                      lineHeight: '16px',
                                                    }}
                                                    className='ml-4 rounded-full bg-red px-4 text-white text-11 min-w-16 h-16 font-bold mt-2'
                                                  >
                                                    {formatDollars(
                                                      _.sumBy(
                                                        misc,
                                                        'TotalCost',
                                                      ) * value.Qty,
                                                    )}
                                                  </div>
                                                )}
                                              </AccordionSummary>
                                              <AccordionDetails
                                                classes={{
                                                  root: 'px-8 py-0 border-t border-grey bg-grey-lighter',
                                                }}
                                              >
                                                <div
                                                  className='w-full pt-6'
                                                  onClick={e =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  {misc.map(rec => {
                                                    return (
                                                      <div className='w-full flex justify-between px-6 mb-6 text-12 font-bold'>
                                                        <div className='w-full truncate flex'>
                                                          <SPRoundIcon
                                                            color='action'
                                                            className='text-16 mr-4'
                                                          />
                                                          <div className='truncate'>{`${(
                                                            value.Qty * rec.Qty
                                                          ).toFixed(2)} ${
                                                            rec.UOM
                                                          } - ${
                                                            rec.Description
                                                          }`}</div>
                                                        </div>
                                                        <div>
                                                          {formatDollars(
                                                            value.Qty *
                                                              rec.TotalCost,
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                              </AccordionDetails>
                                            </Accordion>
                                          </div>
                                        </Hidden>
                                      )}
                                      <Hidden xsDown>
                                        {labor.length > 0 &&
                                          expandedComponents[
                                            `Task${value.Component}`
                                          ] &&
                                          expandedComponents[
                                            `Task${value.Component}`
                                          ]['labor'] && (
                                            <div
                                              className='w-full pt-6'
                                              onClick={e => e.stopPropagation()}
                                            >
                                              {labor.map(rec => {
                                                return (
                                                  <div className='w-full flex justify-between px-6 mb-6 text-12 font-bold'>
                                                    <div className='w-full truncate flex'>
                                                      <SPRoundIcon
                                                        color='action'
                                                        className='text-16 mr-4'
                                                      />
                                                      <div className='truncate'>{`${(
                                                        value.Qty * rec.Qty
                                                      ).toFixed(2)} ${
                                                        rec.UOM
                                                      } - ${
                                                        rec.Description
                                                      } (${Math.round(
                                                        value.Qty *
                                                          rec.Qty *
                                                          60,
                                                      )} Minutes)`}</div>
                                                    </div>
                                                    <div>
                                                      {formatDollars(
                                                        value.Qty *
                                                          rec.TotalCost,
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              })}
                                            </div>
                                          )}
                                        {materials.length > 0 &&
                                          expandedComponents[
                                            `Task${value.Component}`
                                          ] &&
                                          expandedComponents[
                                            `Task${value.Component}`
                                          ]['materials'] && (
                                            <div
                                              className='w-full pt-6'
                                              onClick={e => e.stopPropagation()}
                                            >
                                              {materials.map(rec => {
                                                return (
                                                  <div className='w-full flex justify-between px-6 mb-6 text-12 font-bold'>
                                                    <div className='w-full truncate flex'>
                                                      <SPRoundIcon
                                                        color='action'
                                                        className='text-16 mr-4'
                                                      />
                                                      <div className='truncate'>{`${(
                                                        value.Qty * rec.Qty
                                                      ).toFixed(2)} ${
                                                        rec.UOM
                                                      } - ${
                                                        rec.Description
                                                      }`}</div>
                                                    </div>
                                                    <div>
                                                      {formatDollars(
                                                        value.Qty *
                                                          rec.TotalCost,
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              })}
                                            </div>
                                          )}
                                        {misc.length > 0 &&
                                          expandedComponents[
                                            `Task${value.Component}`
                                          ] &&
                                          expandedComponents[
                                            `Task${value.Component}`
                                          ]['misc'] && (
                                            <div
                                              className='w-full pt-6'
                                              onClick={e => e.stopPropagation()}
                                            >
                                              {misc.map(rec => {
                                                return (
                                                  <div className='w-full flex justify-between px-6 mb-6 text-12 font-bold'>
                                                    <div className='w-full truncate flex'>
                                                      <SPRoundIcon
                                                        color='action'
                                                        className='text-16 mr-4'
                                                      />
                                                      <div className='truncate'>{`${(
                                                        value.Qty * rec.Qty
                                                      ).toFixed(2)} ${
                                                        rec.UOM
                                                      } - ${
                                                        rec.Description
                                                      }`}</div>
                                                    </div>
                                                    <div>
                                                      {formatDollars(
                                                        value.Qty *
                                                          rec.TotalCost,
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              })}
                                            </div>
                                          )}
                                      </Hidden>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        }
                        if (!assembly) {
                          var item = value
                          const { ImageID, CustomImgURL } = item
                          return (
                            <div
                              className='prevent-split flex mt-8 pt-6 cursor-pointer'
                              style={{ borderTop: '1px solid #f1f1f1' }}
                            >
                              <div className='w-1/6 pt-6 pr-6'>
                                <img
                                  alt={Material}
                                  crossOrigin='Anonymous'
                                  className='w-full component-img'
                                  src={
                                    CustomImgURL
                                      ? CustomImgURL
                                      : 'assets/images/logos/SP_Cog_Gradient.png'
                                  }
                                />
                              </div>
                              <div className='w-full whitespace-normal'>
                                <Typography className='w-full font-bold text-16'>
                                  <div
                                    className={classNames(props.classes.qty)}
                                  >{`${value.Qty} ${value.UOM || 'EA'}`}</div>
                                  {value.Name || item.Description}
                                  <Typography
                                    color='secondary'
                                    className='font-bold text-12 mt-4 float-right'
                                  >
                                    {Material}
                                  </Typography>
                                </Typography>
                                <Typography className='clear' variant='caption'>
                                  {parse(
                                    value.Description || item.Description || '',
                                  )}
                                </Typography>
                              </div>
                            </div>
                          )
                        }
                      },
                    )}
                  </div>
                </div>
              </div>
            )}
            {(!props.pdf || units.length > 0) && (
              <div
                className='w-full py-8 my-12'
                style={{
                  borderTop: '1px solid lightgrey',
                  borderBottom: '1px solid lightgrey',
                }}
              >
                {/* {units.length > 0 && */}
                <div className='w-full flex justify-between pr-8'>
                  <Chip
                    classes={{
                      root: 'm-12',
                      avatar: props.classes.chipAvatar,
                      label: props.classes.chipLabel,
                    }}
                    avatar={
                      <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                    }
                    label={`Service Units`}
                    // color="primary"
                    className={props.classes.chip}
                  />
                  {!scope.InvoiceNumber && !props.pdf && (
                    <React.Fragment>
                      {!addUnitsScope || addUnitsScope !== scope.Scope ? (
                        <Button
                          variant='contained'
                          className={classNames(
                            props.classes.black,
                            'h-24 mt-12 font-bold text-12 px-8',
                          )}
                          onClick={e => {
                            e.stopPropagation()
                            setAddUnitsScope(scope.Scope)
                          }}
                          disabled={Boolean(!props.assignedTrip)}
                        >
                          <Icon
                            className='no-print text-16 mr-4 align-middle'
                            color='secondary'
                          >
                            add_circle_outline
                          </Icon>
                          Add
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          className={classNames(
                            props.classes.black,
                            'h-24 mt-12 font-bold text-12 px-8',
                          )}
                          onClick={e => {
                            e.stopPropagation()
                            setAddUnitsScope(null)
                          }}
                        >
                          <Icon
                            className='no-print text-16 mr-4 align-middle'
                            color='secondary'
                          >
                            check_circle_outline
                          </Icon>
                          Done
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                </div>
                {addUnitsScope === scope.Scope && (
                  <div
                    className='w-full mt-8 rounded-lg bg-grey-lightest p-12'
                    style={{ border: '1px dotted lightgrey' }}
                  >
                    {_.filter(
                      Data.Site.Data.Units,
                      o =>
                        _.findIndex(units, { UnitID: o.UnitID }) < 0 &&
                        o.ActiveYN !== 'N',
                    ).map(unit => {
                      return (
                        <Card
                          className={props.classes.taskCard}
                          key={unit.UnitID}
                        >
                          <CardHeader
                            className={props.classes.taskCardUnit}
                            classes={{
                              action: 'mt-6',
                              avatar: 'h-36 w-36 mr-8',
                            }}
                            avatar={
                              <SPRoundIcon className='text-36' color='action' />
                              // <Avatar className={props.classes.unitAvatar} style={{ cursor: 'pointer', boxShadow: '2px 2px 4px 0px #3f3f3f' }} alt="Service Unit Image" src={(unit.Data && unit.Data.Avatar ? (!isNaN(unit.Data.Avatar) ? getProfileImage(`Co=${unit.Co}&ID=${unit.Data.Avatar}` : unit.Data.Avatar) : unit.avatar ? unit.avatar : avatar)} />
                            }
                            title={
                              <label
                                className={classNames(
                                  increaseFontSize ? 'text-20' : 'text-16',
                                  'font-bold',
                                )}
                              >
                                {unit.Name}
                              </label>
                            }
                            subheader={
                              <label
                                className={classNames(
                                  increaseFontSize ? 'text-16' : 'text-12',
                                )}
                              >
                                {unit.UnitID}
                              </label>
                            }
                            action={
                              <React.Fragment>
                                <IconButton
                                  onClick={() =>
                                    props.assignUnit(unit, scope.Scope)
                                  }
                                  aria-label='add'
                                  className='mr-6 w-36 h-36'
                                >
                                  <Icon color='secondary'>
                                    add_circle_outline
                                  </Icon>
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    props.openEditServiceUnitDialog(unit)
                                  }
                                  aria-label='edit'
                                  className='w-36 h-36'
                                >
                                  <Icon color='action'>edit</Icon>
                                </IconButton>
                              </React.Fragment>
                            }
                          />
                        </Card>
                      )
                    })}
                    <Button
                      className='w-full'
                      variant='contained'
                      color='primary'
                      onClick={() =>
                        props.openNewServiceUnitDialog({
                          Co: props.thisState.Co,
                          Customer: props.thisState.Customer,
                          Site: props.thisState.Site,
                        })
                      }
                    >
                      <Icon className='no-print mr-6'>add_circle_outline</Icon>
                      New Service Unit
                    </Button>
                  </div>
                )}
                {/* } */}
                {unitMenuEl && unitMenuUnit && (
                  <Menu
                    id='unit-menu'
                    anchorEl={unitMenuEl}
                    open={Boolean(unitMenuEl)}
                    onClose={closeUnitMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        props.openEditServiceUnitDialog(unitMenuUnit)
                        closeUnitMenu()
                      }}
                    >
                      <Icon className='no-print mr-24'>edit</Icon>Edit Service
                      Unit
                    </MenuItem>
                    {unitMenuData && (
                      <MenuItem
                        onClick={() => {
                          props.unassignUnit(unitMenuData)
                          closeUnitMenu()
                        }}
                      >
                        <Icon className='no-print mr-24'>
                          remove_circle_outline
                        </Icon>
                        Remove Service Unit
                      </MenuItem>
                    )}
                  </Menu>
                )}
                {units.length > 0 && (
                  <div className='px-8 mt-8'>
                    {units.map((unit, index) => {
                      var assn = _.find(scope.Data.ServiceUnits, {
                        UnitID: unit.UnitID,
                      })
                      var tasks = _.filter(scope.Data.AgreementTasks, t => {
                        return (
                          _.findIndex(t.Data.Task.Data.Units, {
                            UnitID: unit.UnitID,
                          }) > -1
                        )
                      })
                      var forms = assn
                        ? [
                            ...(assn.Data.Forms || []),
                            ...(assn.Data.FormResults || []),
                          ]
                        : []
                      var checklists = assn
                        ? [
                            ...(assn.Data.Checklists || []),
                            ...(assn.Data.ChecklistResults || []),
                          ]
                        : []
                      return (
                        <Card className={props.classes.taskCard} key={index}>
                          <CardHeader
                            className={props.classes.taskCardUnit}
                            classes={{
                              action: 'sm:mt-0',
                              avatar: 'h-36 w-36 mr-8',
                            }}
                            avatar={
                              <SPRoundIcon
                                className='text-36'
                                color='primary'
                              />
                              // <Avatar className={props.classes.unitAvatar} style={{ cursor: 'pointer', boxShadow: '2px 2px 4px 0px #3f3f3f' }} alt="Service Unit Image" src={(unit.Data && unit.Data.Avatar ? (!isNaN(unit.Data.Avatar) ? getProfileImage(`Co=${unit.Co}&ID=${unit.Data.Avatar}` : unit.Data.Avatar) : unit.avatar ? unit.avatar : avatar)} />
                            }
                            title={
                              <label
                                className={classNames(
                                  increaseFontSize ? 'text-20' : 'text-16',
                                  'font-bold',
                                )}
                              >
                                {unit.Name}
                              </label>
                            }
                            subheader={
                              <label
                                className={classNames(
                                  increaseFontSize ? 'text-16' : 'text-12',
                                )}
                              >
                                {unit.UnitID}
                              </label>
                            }
                            action={
                              <IconButton
                                style={{
                                  visibility: props.pdf ? 'hidden' : 'visible',
                                }}
                                onClick={e => {
                                  setUnitMenuEl(e.target)
                                  setUnitMenuData(assn)
                                  setUnitMenuUnit(unit)
                                }}
                                aria-label='edit'
                              >
                                <Icon>more_vert</Icon>
                              </IconButton>
                            }
                          />
                          {(checklists.length > 0 ||
                            forms.length > 0 ||
                            tasks.length > 0) && (
                            <div
                              className='p-16'
                              style={{ borderTop: '1px dotted lightgrey' }}
                            >
                              {(checklists.length > 0 || forms.length > 0) && (
                                <div className='px-12'>
                                  <div
                                    className='rounded-lg bg-grey-lightest'
                                    style={{ border: '1px dotted lightgrey' }}
                                  >
                                    <CardContent
                                      classes={{
                                        root: classNames(
                                          'p-12 pt-4',
                                          props.classes.taskForms,
                                        ),
                                      }}
                                    >
                                      <b className='text-12'>
                                        Forms & Checklists:{' '}
                                      </b>
                                      {checklists.map((checklist, index) => {
                                        window['warn']('Checklist: ', checklist)
                                        return (
                                          <MenuItem
                                            disabled={Boolean(
                                              !props.assignedTrip,
                                            )}
                                            className='font-bold mb-16 relative py-8 text-14 bg-white'
                                            style={{
                                              border: '1px solid lightgrey',
                                              borderRadius: 5,
                                            }}
                                            key={index}
                                            onClick={() =>
                                              props.setStateFxn({
                                                ...props.thisState,
                                                selectedChecklist: checklist,
                                                selectedUnit: unit,
                                                selectedDivision: scopeDivision,
                                                selectedScope: scope,
                                              })
                                            }
                                          >
                                            <Icon
                                              className={classNames(
                                                'no-print cursor-pointer mr-6',
                                                !checklist.EnteredBy &&
                                                  props.classes.greyText,
                                              )}
                                              color={
                                                checklist.EnteredBy && 'primary'
                                              }
                                            >
                                              assignment_turned_in
                                            </Icon>
                                            {checklist.Name}
                                            <Icon
                                              className={classNames(
                                                'no-print cursor-pointer absolute pin-r pin-t pin-b m-auto mr-12',
                                                props.classes.greyText,
                                              )}
                                            >
                                              keyboard_arrow_right
                                            </Icon>
                                          </MenuItem>
                                        )
                                      })}
                                      {forms.map((form, index) => {
                                        return (
                                          <MenuItem
                                            className='font-bold mb-16 relative py-8 text-14 bg-white'
                                            style={{
                                              border: '1px solid lightgrey',
                                              borderRadius: 5,
                                            }}
                                            key={index}
                                            onClick={() =>
                                              props.setStateFxn({
                                                ...props.thisState,
                                                selectedForm: form,
                                                selectedUnit: unit,
                                                selectedScope: scope,
                                              })
                                            }
                                          >
                                            <Icon
                                              className={classNames(
                                                'no-print cursor-pointer mr-6',
                                                !form.EnteredBy &&
                                                  props.classes.greyText,
                                              )}
                                              color={
                                                form.EnteredBy && 'primary'
                                              }
                                            >
                                              assignment
                                            </Icon>
                                            {form.Name}
                                            <Icon
                                              className={classNames(
                                                'no-print cursor-pointer absolute pin-r pin-t pin-b m-auto mr-12',
                                                props.classes.greyText,
                                              )}
                                            >
                                              keyboard_arrow_right
                                            </Icon>
                                          </MenuItem>
                                        )
                                      })}
                                    </CardContent>
                                  </div>
                                </div>
                              )}
                              {/* <Typography style={{ alignItems: 'center', }} className="flex font-bold pl-8"><Icon className="no-print text-24 mr-4">dns</Icon>Tasks</Typography> */}
                              {tasks.length > 0 &&
                                tasks.map((task, taskIndex) => {
                                  var taskUnit = _.find(
                                    task.Data.Task.Data.Units,
                                    { UnitID: unit.UnitID },
                                  )
                                  window['warn'](task, taskUnit)
                                  var forms = taskUnit
                                    ? [
                                        ...(taskUnit.Data.Forms || []),
                                        ...(taskUnit.Data.FormResults || []),
                                      ]
                                    : []
                                  var checklists = taskUnit
                                    ? [
                                        ...(taskUnit.Data.Checklists || []),
                                        ...(taskUnit.Data.ChecklistResults ||
                                          []),
                                      ]
                                    : []
                                  return (
                                    <React.Fragment>
                                      <CardHeader
                                        className={classNames(
                                          props.classes.taskCardHeader,
                                          'pt-0',
                                        )}
                                        classes={{ action: 'sm:mt-0' }}
                                        // avatar={
                                        //     <Icon className={classNames("no-print cursor-pointer", props.classes.greyText)}>build</Icon>
                                        // }
                                        title={
                                          <label
                                            className={classNames(
                                              increaseFontSize
                                                ? 'text-20'
                                                : 'text-16',
                                              'font-bold',
                                            )}
                                          >
                                            {task.Data.Task.Name}
                                          </label>
                                        }
                                        subheader={
                                          <label
                                            className={classNames(
                                              increaseFontSize
                                                ? 'text-16'
                                                : 'text-12',
                                            )}
                                          >
                                            {task.Data.Task.Description ||
                                              `${
                                                task.Data.Task.UnitCat
                                                  ? task.Data.Task.UnitCat
                                                  : ''
                                              }${
                                                task.Data.Task.UnitType
                                                  ? ` - ${task.Data.Task.UnitType}`
                                                  : ''
                                              }`}
                                          </label>
                                        }
                                        action={
                                          <React.Fragment>
                                            <div
                                              style={{
                                                visibility: props.pdf
                                                  ? 'hidden'
                                                  : 'visible',
                                              }}
                                              onClick={event =>
                                                props.setStateFxn({
                                                  ...props.thisState,
                                                  taskAnchorEl:
                                                    event.currentTarget,
                                                  selectedTask: task,
                                                  selectedUnit: taskUnit,
                                                })
                                              }
                                              className='cursor-pointer'
                                            >
                                              <Icon
                                                className={classNames(
                                                  'no-print cursor-pointer mt-12',
                                                  props.classes.greyText,
                                                )}
                                              >
                                                more_vert
                                              </Icon>
                                            </div>
                                          </React.Fragment>
                                        }
                                      />
                                      {(checklists.length > 0 ||
                                        forms.length > 0) && (
                                        <div className='px-12'>
                                          <div
                                            className='rounded-lg bg-grey-lightest'
                                            style={{
                                              border: '1px dotted lightgrey',
                                            }}
                                          >
                                            <CardContent
                                              classes={{
                                                root: classNames(
                                                  'p-12 pt-4',
                                                  props.classes.taskForms,
                                                ),
                                              }}
                                            >
                                              <b className='text-12'>
                                                Forms & Checklists:{' '}
                                              </b>
                                              {checklists.map(
                                                (checklist, index) => {
                                                  window['warn'](
                                                    'Checklist: ',
                                                    checklist,
                                                  )
                                                  return (
                                                    <MenuItem
                                                      disabled={Boolean(
                                                        !props.assignedTrip,
                                                      )}
                                                      className='font-bold mb-16 relative py-8 text-14 bg-white'
                                                      style={{
                                                        border:
                                                          '1px solid lightgrey',
                                                        borderRadius: 5,
                                                      }}
                                                      key={index}
                                                      onClick={() =>
                                                        props.setStateFxn({
                                                          ...props.thisState,
                                                          selectedChecklist:
                                                            checklist,
                                                          selectedTask: task,
                                                          selectedUnit:
                                                            taskUnit,
                                                          selectedDivision:
                                                            scopeDivision,
                                                          selectedScope: scope,
                                                        })
                                                      }
                                                    >
                                                      <Icon
                                                        className={classNames(
                                                          'no-print cursor-pointer mr-6',
                                                          !checklist.EnteredBy &&
                                                            props.classes
                                                              .greyText,
                                                        )}
                                                        color={
                                                          checklist.EnteredBy &&
                                                          'primary'
                                                        }
                                                      >
                                                        assignment_turned_in
                                                      </Icon>
                                                      {checklist.Name}
                                                      <Icon
                                                        className={classNames(
                                                          'no-print cursor-pointer absolute pin-r pin-t pin-b m-auto mr-12',
                                                          props.classes
                                                            .greyText,
                                                        )}
                                                      >
                                                        keyboard_arrow_right
                                                      </Icon>
                                                    </MenuItem>
                                                  )
                                                },
                                              )}
                                              {forms.map((form, index) => {
                                                return (
                                                  <MenuItem
                                                    className='font-bold mb-16 relative py-8 text-14 bg-white'
                                                    style={{
                                                      border:
                                                        '1px solid lightgrey',
                                                      borderRadius: 5,
                                                    }}
                                                    key={index}
                                                    onClick={() =>
                                                      props.setStateFxn({
                                                        ...props.thisState,
                                                        selectedForm: form,
                                                        selectedTask: task,
                                                        selectedUnit: taskUnit,
                                                        selectedScope: scope,
                                                      })
                                                    }
                                                  >
                                                    <Icon
                                                      className={classNames(
                                                        'no-print cursor-pointer mr-6',
                                                        !form.EnteredBy &&
                                                          props.classes
                                                            .greyText,
                                                      )}
                                                      color={
                                                        form.EnteredBy &&
                                                        'primary'
                                                      }
                                                    >
                                                      assignment
                                                    </Icon>
                                                    {form.Name}
                                                    <Icon
                                                      className={classNames(
                                                        'no-print cursor-pointer absolute pin-r pin-t pin-b m-auto mr-12',
                                                        props.classes.greyText,
                                                      )}
                                                    >
                                                      keyboard_arrow_right
                                                    </Icon>
                                                  </MenuItem>
                                                )
                                              })}
                                            </CardContent>
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                            </div>
                          )}
                        </Card>
                      )
                      // }
                    })}
                  </div>
                )}
              </div>
            )}
            {/*  */}
            {((scope.Summary && scope.Summary.length > 0) || !props.pdf) && (
              <React.Fragment>
                <div className='w-full justify-between'>
                  <span
                    className={classNames(
                      increaseFontSize ? 'text-16' : 'text-12',
                      'cursor-pointer',
                    )}
                  >
                    <b
                      className={classNames(
                        increaseFontSize ? 'text-16' : 'text-12',
                      )}
                    >
                      <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                        speaker_notes
                      </Icon>
                      Technician Summary:
                    </b>
                  </span>
                  {props.editSummary[scope.Scope] && (
                    <Icon
                      className='no-print text-20 mr-4 mt-4 float-right cursor-pointer'
                      color='action'
                      onClick={() => {
                        props.editSummary[scope.Scope] = null
                        props.setStateFxn({
                          ...props.thisState,
                          ...props.editSummary,
                        })
                      }}
                    >
                      close
                    </Icon>
                  )}
                </div>
                <div className='w-full mt-6 pr-4'>
                  {!props.editSummary[scope.Scope] &&
                    !scope.Summary &&
                    !props.pdf && (
                      <MenuItem
                        disabled={Boolean(!props.assignedTrip)}
                        className='font-bold mb-8 relative py-8 pb-6 text-14'
                        style={{
                          border: '1px dotted lightgrey',
                          borderRadius: 5,
                        }}
                        key={index}
                        onClick={() => {
                          if (!props.editSummary[scope.Scope]) {
                            props.editSummary[scope.Scope] =
                              _.cloneDeepWith(scope)
                            props.setStateFxn({
                              ...props.thisState,
                              ...props.editSummary,
                            })
                          }
                        }}
                      >
                        <div className='w-full text-center'>
                          <Icon
                            className={classNames(
                              'no-print cursor-pointer mr-6 text-20 align-middle mb-4',
                            )}
                            color='primary'
                          >
                            add
                          </Icon>
                          Add Summary
                        </div>
                      </MenuItem>
                    )}
                  {!props.editSummary[scope.Scope] && scope.Summary && (
                    <MenuItem
                      disabled={Boolean(!props.assignedTrip)}
                      className='mb-8 relative py-8'
                      style={{
                        border: '1px dotted lightgrey',
                        borderRadius: 5,
                      }}
                      key={index}
                      onClick={e => {
                        if (!props.editSummary[scope.Scope]) {
                          props.editSummary[scope.Scope] =
                            _.cloneDeepWith(scope)
                          props.setStateFxn({
                            ...props.thisState,
                            ...props.editSummary,
                          })
                          window['warn'](
                            props.editSummary[scope.Scope].Summary,
                            summary,
                          )
                          setSummary(props.editSummary[scope.Scope].Summary)
                        }
                      }}
                    >
                      <div
                        className={classNames(
                          increaseFontSize ? 'text-16' : 'text-12',
                          'italic',
                        )}
                        style={{ whiteSpace: 'normal' }}
                      >
                        "{scope.Summary}"
                      </div>
                    </MenuItem>
                  )}
                  {props.editSummary[scope.Scope] && (
                    <TextField
                      className='mb-8'
                      id='summary'
                      name={`scope${scope.Scope}Summary`}
                      value={summary || ''}
                      onChange={e => {
                        setSummary(e.target.value)
                        // window["log"]('Scope Summary update: ', e.target.value, props.editSummary);
                        // props.editSummary[scope.Scope].Summary = e.target.value;
                        // props.setStateFxn({ ...props.thisState, ...props.editSummary });
                      }}
                      variant='outlined'
                      multiline
                      rows={5}
                      fullWidth
                      disabled={!props.editSummary[scope.Scope]}
                    />
                  )}
                  {props.editSummary[scope.Scope] && (
                    <Button
                      variant='contained'
                      className='mb-12 mt-4'
                      color='primary'
                      onClick={() => {
                        props.updateWorkOrderScope({
                          ...scope,
                          Summary: summary,
                        })
                        props.editSummary[scope.Scope] = null
                      }}
                      fullWidth
                      disabled={Boolean(!props.assignedTrip)}
                    >
                      Update Summary
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
            {/*  */}
            {!scope.InvoiceNumber &&
              !props.editSummary[scope.Scope] &&
              scope.ReadyToBillYN === 'Y' &&
              !props.pdf && (
                <Button
                  variant='contained'
                  className='w-full mt-12 mb-12'
                  style={{ marginLeft: -4 }}
                  color='primary'
                  onClick={() => props.launchInvoice()}
                  fullWidth
                  disabled={Boolean(!props.assignedTrip)}
                >
                  <Icon className='no-print text-24 mr-6'>
                    add_circle_outline
                  </Icon>
                  New Invoice
                </Button>
              )}
            {scope.InvoiceNumber &&
              !props.editSummary[scope.Scope] &&
              !props.pdf && (
                <Button
                  variant='contained'
                  className={classNames(
                    'w-full mt-12 mb-12',
                    props.classes.black,
                  )}
                  style={{ marginLeft: -4 }}
                  color='primary'
                  onClick={() =>
                    props.openEditInvoiceDialog({
                      Co: scope.Co,
                      Invoice: scope.Invoice,
                    })
                  }
                  fullWidth
                >
                  <Icon className='no-print text-24 mr-6' color='secondary'>
                    receipt
                  </Icon>
                  View Invoice
                </Button>
              )}
          </div>
        </FuseAnimate>
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWorkOrderScope: WorkOrderScopeActions.updateWorkOrderScope,
      openEditWorkOrderScopeDialog:
        WorkOrderScopeActions.openEditWorkOrderScopeDialog,
      openNewTripDialog: TripActions.openNewTripDialog,
      openEditInvoiceDialog,
      openEditEstimateDialog,
      openEditServiceUnitDialog,
      openNewServiceUnitDialog,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers }) {
  return {
    Co: spReducers.companies.Co,
    user: spReducers.userProfiles.User,
    rateTemplates: spReducers.rateTemplates,
    formOfPayments: spReducers.formOfPayments,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    services: spReducers.services,
    arrivalWindows: spReducers.arrivalWindows,
    serviceTypes: spReducers.serviceTypes,
    technicians: spReducers.technicians,
    technician: spReducers.technician.technician,
    companies: spReducers.companies.List,
    materials: spReducers.materials.materials,
    laborTypes: spReducers.laborTypes,
    userPreferences: spReducers.userProfiles.User.Preferences,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(ScopeDetails),
)
