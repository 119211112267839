import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from '@lodash';
import axios from 'axios';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },

});

const initialState = {
    handler: {
        Co: null,
        VoiceMailbox: null,
        UserName: null,
        AccessLevel: null,
        AddedBy: null,
        AddedDate: null,
        Data: { ErrMsg: null }
    }
}

class VoiceMailboxesList extends Component {

    state = {
        ..._.cloneDeepWith(initialState)
    };

    setHandler = (handler) => {
        this.setState({ handler });
    }

    render() {
        const { classes, searchText, data, mailbox } = this.props;
        const levels = { 'R': 'Read-Only', 'E': 'Read/Write', 'F': 'Full Access' };
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const { Co, VoiceMailbox } = mailbox;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="">
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">supervised_user_circle</Icon>Handlers</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        if (this.props.onSelectedHandler) {
                                            this.props.onSelectedHandler(_.cloneDeepWith(rowInfo.original));
                                        }
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "User",
                                accessor: "UserName",
                                className: "justify-center",
                                id: "handler",
                                Cell: row => {
                                    const value = _.find(this.props.users, { UserName: row.original.UserName });
                                    return (
                                        <div className="flex">
                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </div>
                                    )
                                },
                                width: 256,
                            },
                            {
                                Header: "Access Level",
                                accessor: "AccessLevel",
                                width: 128,
                                id: "accessLevel",
                                Cell: row => levels[row.original.AccessLevel]
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Handlers found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // addVoiceMailboxHandler: Actions.addVoiceMailboxHandler,
        // updateVoiceMailboxHandler: Actions.updateVoiceMailboxHandler,
        // removeVoiceMailboxHandler: Actions.removeVoiceMailboxHandler,
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp, spReducers }) {
    return {
        voiceMailboxes: spReducers.voiceMailboxes,
        searchText: voiceMailboxesApp.voiceMailboxes.searchText,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VoiceMailboxesList)));
