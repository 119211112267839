import axios from 'axios/index';

export const SET_CHECKLIST_DATA = '[SPCONNECTION] SET CHECKLIST DATA';
export const GET_CHECKLIST_DATA = '[SPCONNECTION] GET CHECKLIST DATA';
export const SUBSCRIBE_CHECKLIST_LIST = '[SPCONNECTION] SUBSCRIBE CHECKLIST LIST';

export function getInitialChecklistData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Checklist`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getChecklistData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Checklist`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CHECKLIST_DATA,
                payload: response.data
            });
        });
    }
}

export function setChecklistData(data) {
    return {
        type: SET_CHECKLIST_DATA,
        payload: data
    };
}