import axios from 'axios/index';
import { showMessage } from 'store/actions/fuse';
import { NEW_BOARD, ADD_CARD, buildBoard } from '../actions';
import _ from '@lodash';
import { DesktopWindowsRounded } from '@material-ui/icons';

export const GET_DELETED_BOARDS = '[SCRUMBOARD APP] GET DELETED BOARDS';
export const GET_DELETED_LISTS_CARDS = '[SCRUMBOARD APP] GET DELETED LISTS CARDS';

export function getDeletedBoards(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/ScrumBoardRecycleBin?Co=${Co}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch({
                type: GET_DELETED_BOARDS,
                payload: response.data
            })
        }).catch((error) => {
            dispatch(showMessage({
                message: error.message,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });
}

export function getDeletedListsCards(Co, boardId) {
    const request = axios.get(`${window["apiLocation"]}/api/ScrumBoardRecycleBin?Co=${Co}&Board=${boardId}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch({
                type: GET_DELETED_LISTS_CARDS,
                payload: response.data
            })
        }).catch((error) => {
            dispatch(showMessage({
                message: error.message,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });
}

export function permanentlyRemoveBoard(board) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoard?Co=${board.Co}&Board=${board.ID}&Purge=true`);

    return (dispatch) =>

        request.then(() => {
            dispatch(getDeletedBoards(board.Co));
            dispatch(showMessage({
                message: 'Board Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });

}

export function permanentlyRemoveCard(card) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}&Purge=true`);

    return (dispatch) =>

        request.then(() => {
            dispatch(getDeletedListsCards(card.Co, card.BoardID));
            dispatch(showMessage({
                message: 'Card Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });

}

export function permanentlyRemoveList(list) {
    const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardList?Co=${list.Co}&Board=${list.BoardID}&List=${list.ID}&Purge=true`);

    return (dispatch) =>
        request.then(() => {
            dispatch(getDeletedListsCards(list.Co, list.BoardID));
            dispatch(showMessage({
                message: 'List Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });

}

export function restoreBoard(board) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardRecycleBin?Co=${board.Co}&Board=${board.ID}`, board);

    return (dispatch) =>
        request.then((response) => {
            dispatch(getDeletedBoards(board.Co));
            dispatch({
                type: NEW_BOARD,
                board: response.data
            })
            dispatch(showMessage({
                message: 'Board Restored',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });
}

export function restoreCard(card) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardRecycleBin?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}`, card);

    return (dispatch, getState) =>
        request.then((response) => {
            const { board } = _.cloneDeepWith(getState().scrumboardApp);
            const { Cards } = board.Data;
            Cards.push(response.data);
            dispatch(buildBoard(board));
            dispatch(getDeletedListsCards(card.Co, card.BoardID));
            dispatch(showMessage({
                message: 'Card Restored',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });
}

export function restoreList(list) {
    const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardRecycleBin?Co=${list.Co}&Board=${list.BoardID}&List=${list.ID}`, list);

    return (dispatch, getState) =>
        request.then((response) => {
            const { board } = getState().scrumboardApp;
            const { Cards, Lists } = board.Data;
            Lists.push({
                ...response.data,
                Data: {
                    ...response.data.Data,
                    Cards: []
                }
            });
            function restoreCards(cards) {
                cards.map((card) => {
                    const index = _.findIndex(Cards, { ID: card.ID });
                    if (index < 0) {
                        Cards.push({ ...card, Data: { ...card.Data, Cards: [] } });
                    }
                    if (card.Data && card.Data.Cards) {
                        restoreCards(card.Data.Cards);
                    }
                });
            }
            restoreCards(response.data.Data.Cards);
            dispatch(buildBoard(board));
            dispatch(getDeletedListsCards(list.Co, list.BoardID));
            dispatch(showMessage({
                message: 'List Restored',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        });
}