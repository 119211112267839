import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, MenuItem, TextField, Divider, Icon, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { DatePicker } from "@material-ui/pickers";
import * as Actions from './store/actions';
import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import axios from 'axios';

const styles = theme => ({
    clear: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[400],
        },
        '&:active': {
            backgroundColor: theme.palette.error[700],
        }
    }
});

const initialState = {
    Co: null,
    Category: null,
    Report: null,
    dataSources: [],
    loading: false,
}
class ReportingSidebarContent extends Component {

    state = {
        ...initialState,
    }

    componentDidMount() {
        const { Co, Category, Report, loading, dataSources } = this.props;
        this.setState({ Co, Category, Report, dataSources, loading });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { Co, Category, Report, dataSources, loading } = this.props;
        if (!_.isEqual(Co, prevProps.Co) || !_.isEqual(Category, prevProps.Category) || !_.isEqual(Report, prevProps.Report) || !_.isEqual(dataSources, prevProps.dataSources)) {
            this.setState({ Co, Category, Report, dataSources, loading });
        }
    }

    handleStartDateChange = (event) => {
        let { startDate } = this.state;
        startDate = event._d.toLocaleDateString('en-US');
        window["warn"](event, startDate);
        this.props.setStartDate(startDate);
        // this.setState({ ...this.state, startDate });
    };

    handleEndDateChange = (event) => {
        let { endDate } = this.state;
        endDate = event._d.toLocaleDateString('en-US');
        window["warn"](event, endDate);
        this.props.setEndDate(endDate);
        // this.setState({ ...this.state, endDate });
    };

    getData = () => {
        const { Category, Report } = this.state;
        this.props.getData(Category, Report);
    }

    getReportList = () => {
        const { Category } = this.state;
        if (Category) {
            const request = axios.get(`${window["apiLocation"]}/api/CustomReport?Category=${encodeURIComponent(Category)}`);
            request.then((response) => {
                this.props.setDataSources(Category, response.data);
            });
        }
    }

    setReportParameter = (option, value) => {
        const { Report } = this.state;
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = option[value.ListValue];
        } else {
            param = {
                Parameter: value.Parameter,
                Value: option[value.ListValue]
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, Report });
    }

    setReportParameterDate = (event, value) => {
        const { Report } = this.state;
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = event._d;
        } else {
            param = {
                Parameter: value.Parameter,
                Value: event._d
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, Report });
    }
    getReportParameter = (name) => {
        const { Report } = this.state;
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    setCategory = (e) => {
        const { value } = e.target;
        this.setState({ ...this.state, Category: value }, () => this.getReportList())
    }

    render() {
        const { classes, user, entities, type, securablesList, dataSources, master } = this.props;
        const { Category, Data, Report } = this.state;
        return (
            <div className="p-12 lg:pl-20">
                <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                    <Paper elevation={1} className="rounded-8 p-12">
                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">tune</Icon>Parameters</Typography>
                        <TextField
                            className="mb-12"
                            label="Data Category"
                            id="category"
                            name="Category"
                            value={Category || ''}
                            select
                            onChange={this.setCategory}
                            variant="outlined"
                            fullWidth
                            required
                        >
                            <MenuItem value="P">
                                Production
                            </MenuItem>
                            <MenuItem value="C">
                                Call Center
                            </MenuItem>
                            <MenuItem value="E">
                                Employee
                            </MenuItem>
                            <MenuItem value="M">
                                Marketing
                            </MenuItem>
                            <MenuItem value="A">
                                Agreements
                            </MenuItem>
                        </TextField>
                        <Autocomplete
                            className="mb-12"
                            title="Data Source"
                            options={[...dataSources, { Name: 'Refresh List', Report: 'Refresh', Icon: 'refresh' }]}
                            menuItemComponent={(value) => {
                                return (
                                    <MenuItem value={value.Report}>
                                        <div className="flex">
                                            {value.Icon &&
                                                <Icon className="mr-4">{value.Icon}</Icon>
                                            }
                                            {`${value.Name}`}
                                        </div>
                                    </MenuItem>
                                );
                            }}
                            value={Report ? Report.Report : ''}
                            portal={true}
                            onSelect={(option) => { if (option.Report === 'Refresh') { this.getReportList(); } else { this.setState({ ...this.state, Report: { ...option, Parameters: [] } }) } }}
                            required
                        />
                        {Report && Report.Data && Report.Data.Parameters && Report.Data.Parameters.map((value) => {

                            const paramVal = this.getReportParameter(value.Parameter);
                            value.text = value.ListValueText ? JSON.parse(value.ListValueText) : []
                            switch (value.Type) {
                                case 'text':
                                    {
                                        if (value.List) {
                                            const options = _.get(master, value.List) || [];
                                            return (
                                                <Autocomplete
                                                    className="mb-12"
                                                    title={value.Description}
                                                    options={options}
                                                    menuItemComponent={(option) => {
                                                        let displayText;
                                                        if (value.text && value.text.length > 0) {
                                                            value.text.map((name) => {
                                                                const text = _.get(option, name);
                                                                if (text) {
                                                                    if (!displayText) {
                                                                        displayText = '';
                                                                    }
                                                                    displayText += `${text} `;
                                                                }
                                                            })
                                                        }
                                                        return (
                                                            <MenuItem value={option ? option[value.ListValue] : null}>
                                                                <div className="flex">
                                                                    {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    }}
                                                    portal={true}
                                                    value={paramVal}
                                                    onSelect={(option) => this.setReportParameter(option, value)}
                                                    required={value.RequiredYN === 'Y'}
                                                />
                                            );
                                        }
                                    }
                                    break;
                                case 'number':
                                    {
                                        if (value.List) {
                                            const options = _.get(master, value.List) || [];
                                            return (
                                                <Autocomplete
                                                    className="mb-12"
                                                    title={value.Description}
                                                    options={options}
                                                    menuItemComponent={(option) => {
                                                        let displayText;
                                                        if (value.text && value.text.length > 0) {
                                                            value.text.map((name) => {
                                                                const text = _.get(option, name);
                                                                if (text) {
                                                                    if (!displayText) {
                                                                        displayText = '';
                                                                    }
                                                                    displayText += `${text} `;
                                                                }
                                                            })
                                                        }
                                                        return (
                                                            <MenuItem value={option ? option[value.ListValue] : null}>
                                                                <div className="flex">
                                                                    {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    }}
                                                    portal={true}
                                                    value={paramVal}
                                                    onSelect={(option) => this.setReportParameter(option, value)}
                                                    required={value.RequiredYN === 'Y'}
                                                />
                                            );
                                        }
                                    }
                                    break;
                                case 'date':
                                    {
                                        return (
                                            <DatePicker variant="inline" inputVariant="outlined"
                                                className="mb-12"
                                                label={value.Description}
                                                format="MM/DD/YYYY"
                                                value={paramVal}
                                                onChange={() => { }}
                                                variant="outlined"
                                                showTodayButton
                                                onChange={(e) => this.setReportParameterDate(e, value)}
                                                required={value.RequiredYN === 'Y'}
                                            />
                                        );
                                    }
                                    break;
                            }
                        })}
                        {/*
                        <DatePicker variant="inline" inputVariant="outlined"
                            className="mb-12"
                            label="Start Date"
                            id="orderedDate"
                            format="MM/DD/YYYY"
                            value={this.state.startDate}
                            onChange={this.handleStartDateChange}
                            variant="outlined"
                            showTodayButton
                            autoOk
                            required
                            fullWidth
                        />
                        <DatePicker variant="inline" inputVariant="outlined"
                            className="mb-12"
                            label="End Date"
                            id="endDate"
                            format="MM/DD/YYYY"
                            value={this.state.endDate}
                            onChange={this.handleEndDateChange}
                            variant="outlined"
                            showTodayButton
                            autoOk
                            required
                            fullWidth
                        />*/}
                        <Button
                            variant="contained"
                            className="mb-12"
                            fullWidth
                            color="primary"
                            onClick={this.getData}
                            disabled={!Report}
                        >
                            Run Report
                            </Button>
                    </Paper>
                </FuseAnimate>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setDataSources: Actions.setDataSources,
        getData: Actions.getData,
        setData: Actions.setData,
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers, }) {
    return {
        Co: spReducers.companies.Co,
        Category: reportingApp.reporting.category,
        Report: reportingApp.reporting.report,
        loading: reportingApp.reporting.loading,
        dataSources: reportingApp.reporting.dataSources,
        master: spReducers,
        user: reportingApp.user,
        entities: reportingApp.reporting.entities,
        securablesList: spReducers.userProfiles.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingSidebarContent)));
