import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FuseUtils, FuseAnimate } from '@fuse'
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Dialog,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import * as WorkOrderActions from '../work-orders/store/actions'
import * as DispatchActions from '../dispatch-board/store/actions'
import TechnicianTrip from '../technician/TechnicianTrip'
import TimecardLabor from './TimecardLabor'
import TimecardBreak from './TimecardBreak'
import ReactTable from 'react-table'
import classNames from 'classnames'
import moment from 'moment'
import { TimePicker } from '@material-ui/pickers'
import SignatureCanvas from 'react-signature-canvas'
import GoogleMap from 'google-map-react'
import _ from '@lodash'
import { Geolocation } from '@capacitor/geolocation'

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

function Marker({ text, icon, style, badge, heading, type, onClick }) {
  return (
    <Tooltip title={text} placement='top'>
      <Badge
        badgeContent={badge ? badge : 0}
        classes={{
          badge: 'inventory-map-badge',
          root: type === 'person' ? 'person-marker' : '',
        }}
        color='error'
      >
        <div onClick={onClick}>
          <img style={style} src={icon} />
        </div>
      </Badge>
    </Tooltip>
  )
}

const styles = theme => ({
  root: {
    maxHeight: '70vh',
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  calendar: {
    width: '100%',
    background: theme.palette.primary.main,
    borderRadius: 5,
  },
  calendarHeader: {
    width: '100%',
  },
  calendarPicker: {
    height: 72,
    width: '100%',
    background: '#3f3f3f',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  calendarPickerDates: {
    height: 72,
    width: '100%',
  },
  mailItem: {},
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 30,
    height: 30,
    marginTop: -6,
    marginBottom: -6,
  },
  labels: {},
  badge: {
    top: 5,
    right: 15,
  },
  liRoot: {
    padding: 0,
  },
  clockInBtn: {
    background: '#333',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
  },
  clockOutBtn: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:active': {
      background: '#5f5f5f',
    },
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  withdraw: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  inlineBadge: {
    top: '25%',
    left: '110%',
    right: 'unset',
    fontSize: 11,
    padding: 4,
    height: 16,
    transform: 'none',
  },
  step: {
    width: 24,
    height: 24,
    fontSize: 12,
    marginTop: 12,
    marginRight: 8,
  },
})

const initialState = {
  loading: false,
  expanded: {},
  trips: [],
  timecard: null,
  selectedTrip: null,
  viewing: 'schedule',
  anchorEl: null,
  isStartpickerOpen: false,
  isEndpickerOpen: false,
}

class TechnicianProfile extends Component {
  state = {
    ...initialState,
  }

  componentDidMount() {
    let { date, trips, timecard } = this.props
    const pending = _.filter(trips, t => {
      return t.Status < 4
    })
    // if (pending.length > 0) {
    //     document.title = `(${pending.length}) Technician Dashboard`;
    // } else {
    //     document.title = `Technician Dashboard`;
    // }
    const dt = date ? new Date(date.toLocaleDateString('en-US')) : new Date()
    if (date) {
      this.setState({ ...this.state, date: dt, trips, timecard }, () =>
        this.getGPS(),
      )
    }

    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    let { loading, trips, timecard } = this.props
    const pending = _.filter(trips, t => {
      return t.Status < 4
    })
    // if (pending.length > 0) {
    //     document.title = `(${pending.length}) Technician Dashboard`;
    // } else {
    //     document.title = `Technician Dashboard`;
    // }
    if (
      !_.isEqual(trips, prevProps.trips) ||
      !_.isEqual(loading, prevProps.loading) ||
      !_.isEqual(timecard, prevProps.timecard)
    ) {
      window['warn']('ComponentDidUpdate', {
        ...this.state,
        loading,
        trips,
        timecard,
      })
      this.setState({ ...this.state, loading, trips, timecard })
    }
  }

  getGPS = () => {
    const { gps } = this.props
    if (
      !gps ||
      !gps.location ||
      !gps.location.coords ||
      !gps.location.coords.latitude ||
      !gps.location.coords.longitude
    ) {
      var geoOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      console.log('Fetching GPS Location - Technicians')
      Geolocation.getCurrentPosition(geoOptions)
        .then(pos => {
          var crd = pos.coords

          var Latitude = crd.latitude
          var Longitude = crd.longitude
          this.setState({ ...this.state, userGPS: { Latitude, Longitude } })
        })
        .catch(err => {
          window['warn'](`ERROR(${err.code}): ${err.message}`)
        })
    } else {
      var Latitude = gps.location.coords.latitude
      var Longitude = gps.location.coords.longitude
      this.setState({ ...this.state, userGPS: { Latitude, Longitude } })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      window['log']('Filtered Array: ', arr)
      return arr
    }
    window['log']('Filtered Array: ', arr, searchText)
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedTechnicianMenu = event => {
    this.setState({ selectedTechniciansMenu: event.currentTarget })
  }

  closeSelectedTechniciansMenu = () => {
    this.setState({ selectedTechniciansMenu: null })
  }

  handleDateChange = event => {
    window['warn'](event)
    const date = event._d
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    const newDate = this.formatDateInput(date)
    if (oldDate !== newDate) {
      this.setState(
        { ...this.state, date, sigImg: null, loading: true, trips: [] },
        () => {
          this.updateDate(date)
        },
      )
    }
  }

  handleDateClick = date => {
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    const newDate = this.formatDateInput(date)
    if (oldDate !== newDate) {
      this.setState(
        { ...this.state, date, sigImg: null, loading: true, trips: [] },
        () => {
          this.updateDate(date)
        },
      )
    }
  }

  handleToday = event => {
    const date = new Date()
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    const newDate = this.formatDateInput(date)
    this.setState(
      { ...this.state, date, sigImg: null, loading: true, trips: [] },
      () => {
        this.updateDate(date)
      },
    )
  }

  handleNextDate = () => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    date.setDate(date.getDate() + 7)
    const newDate = this.formatDateInput(date)
    this.setState(
      { ...this.state, date, sigImg: null, loading: true, trips: [] },
      () => {
        this.updateDate(date)
      },
    )
  }

  handlePrevDate = () => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    date.setDate(date.getDate() - 7)
    const newDate = this.formatDateInput(date)
    this.setState(
      { ...this.state, date, sigImg: null, loading: true, trips: [] },
      () => {
        this.updateDate(date)
      },
    )
  }

  updateDate = _.debounce(date => {
    this.props.getTechnicianSchedule(this.props.technician, date)
  }, 500)

  formatDateInput(dt) {
    return dt.toLocaleDateString('en-US').replace(/\//g, '-')
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  openDatePicker = e => {
    this.refs.datePicker.open(e)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer mr-8'>
      {this.formatDateText(this.state.date)}
    </span>
  )

  handleRefresh = () => {
    let { date } = this.state
    this.setState(
      { ...this.state, date, sigImg: null, loading: true, trips: [] },
      () => {
        this.updateDate(date)
      },
    )
  }

  toggleTrip = trip => {
    const { expanded } = this.state
    if (expanded[trip]) {
      expanded[trip] = false
    } else {
      expanded[trip] = true
    }
    this.setState({ ...this.state, expanded })
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: maps.MapTypeId.TERRAIN,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 60 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 10 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#333333' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ color: '#333333' }, { lightness: '17' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '0' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: '60' },
            { weight: 2 },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }, { weight: 3 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ lightness: '10' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [{ visibility: 'on' }, { lightness: '2' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [{ lightness: '20' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ lightness: '30' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#0f252e' }, { lightness: 17 }],
        },
      ],
    }
  }

  selectTrip = trip => {
    let { selectedTrip } = this.state
    this.setState({
      ...this.state,
      selectedTrip:
        trip && selectedTrip && selectedTrip.ID === trip.ID ? null : trip,
    })
  }

  updateTrip = trip => {
    const { UserName } = this.props.user
    trip.UpdatedBy = UserName
    trip.UpdatedDate = new Date()
    if (!isNaN(trip.Duration) && trip.Duration >= 0.5) {
      this.props.updateTrip(trip)
    }
  }

  openMenu = e => {
    this.setState({ ...this.state, anchorEl: e.currentTarget })
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({
      ...this.state,
      sigImg: this.sigPad.getCanvas().toDataURL('image/png'),
      isSigning: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
    }
  }

  renderStartLabel = () => {
    const { timecard } = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    return (
      <span className='text-12'>
        {timeIn && moment(timeIn).local().toDate().toLocaleTimeString('en-US')}
      </span>
    )
  }

  renderEndLabel = () => {
    const { timecard } = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    return (
      <span className='text-12'>
        {timeOut &&
          moment(timeOut).local().toDate().toLocaleTimeString('en-US')}
      </span>
    )
  }

  handleTimeOutChange = event => {
    window['warn'](event)
    const { timecard, date } = this.state
    const tcData = timecard ? timecard.Data : null
    if (event) {
      const time = event._d
      const dt = new Date(date.toLocaleDateString('en-US'))
      dt.setTime(time.getTime())
      window['warn'](date, time, dt)
      if (tcData) {
        const punch = tcData.Punch[tcData.Punch.length - 1]
        punch.TimeOut = dt.toLocaleString('en-US')
        this.props.editPunch(punch)
      }
    } else {
      if (tcData) {
        const punch = tcData.Punch[tcData.Punch.length - 1]
        punch.TimeOut = null
        this.props.editPunch(punch)
      }
    }
  }

  handleTimeInChange = event => {
    window['warn'](event)
    const { technician } = this.props
    const { Co, Employee } = technician
    const { timecard, date } = this.state
    const tcData = timecard ? timecard.Data : null
    if (event) {
      const time = event._d
      const dt = new Date(date.toLocaleDateString('en-US'))
      dt.setTime(time.getTime())
      window['warn'](date, time, dt)
      const punch =
        tcData && tcData.Punch && tcData.Punch.length > 0
          ? tcData.Punch[0]
          : {
              Co,
              Employee,
              Date: date.toLocaleDateString('en-US'),
              TimeIn: dt.toLocaleString('en-US'),
            }
      punch.TimeIn = dt.toLocaleString('en-US')
      if (tcData && tcData.Punch && tcData.Punch.length > 0) {
        this.props.editPunch(punch)
      } else {
        this.props.clockIn(punch)
      }
    } else {
      const punch =
        tcData && tcData.Punch && tcData.Punch.length === 1
          ? tcData.Punch[0]
          : null
      if (punch) {
        this.props.removePunch(punch)
      }
    }
  }

  openStartPicker = e => {
    const { submitted } = this.props
    if (submitted !== 'Y') {
      this.setStartpickerOpen(true)
    }
  }

  openEndPicker = e => {
    const { submitted } = this.props
    if (submitted !== 'Y') {
      this.setEndpickerOpen(true)
    }
  }

  setStartpickerOpen = bool => {
    this.setState({ isStartpickerOpen: bool })
  }

  setEndpickerOpen = bool => {
    this.setState({ isEndpickerOpen: bool })
  }

  render() {
    const {
      classes,
      technicians,
      user,
      searchText,
      openEditWorkOrderDialog,
      technician,
    } = this.props
    const {
      date,
      loading,
      expanded,
      trips,
      timecard,
      userGPS,
      selectedTrip,
      viewing,
      anchorEl,
    } = this.state
    window['warn'](selectedTrip)
    const data = this.getFilteredArray(
      !this.props.selectedTechnicianData
        ? !this.props.technicianData
          ? []
          : this.props.technicianData
        : this.props.selectedTechnicianData,
      searchText,
    )
    const { selectedTechniciansMenu } = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const statusProps = [
      {
        text: 'Scheduled',
        icon: 'access_time',
        color: 'rgb(100, 100, 200)',
        dateProp: 'ScheduledDate',
        timeProp: 'ScheduledTime',
      },
      {
        text: 'Notified',
        icon: 'offline_bolt',
        color: 'rgb(0, 150, 250)',
        dateProp: 'NotifiedDate',
        timeProp: 'NotifiedTime',
      },
      {
        text: 'Accepted',
        icon: 'offline_pin',
        color: 'rgb(0, 50, 250)',
        dateProp: 'AcceptedDate',
        timeProp: 'AcceptedTime',
      },
      {
        text: 'Rejected',
        icon: 'cancel',
        color: 'rgb(210, 0, 0)',
        dateProp: 'RejectedDate',
        timeProp: 'RejectedTime',
      },
      {
        text: 'En-Route',
        icon: 'explore',
        color: 'rgb(50, 170, 200)',
        dateProp: 'EnRouteDate',
        timeProp: 'EnRouteTime',
      },
      {
        text: 'Arrived',
        icon: 'my_location',
        color: 'rgb(50, 150, 100)',
        dateProp: 'ArrivedDate',
        timeProp: 'ArrivedTime',
      },
      {
        text: 'In Progress',
        icon: 'play_circle_outline',
        color: 'rgb(150, 200, 50)',
        dateProp: 'InProgressDate',
        timeProp: 'InProgressTime',
      },
      {
        text: 'On Hold',
        icon: 'pause_circle_outline',
        color: 'rgb(210, 100, 100)',
        dateProp: 'OnHoldDate',
        timeProp: 'OnHoldTime',
      },
      {
        text: 'Completed',
        icon: 'check_circle',
        color: 'rgb(100, 100, 100)',
        dateProp: 'CompletedDate',
        timeProp: 'CompletedTime',
      },
    ]
    const calWindow = []
    for (var i = -3; i < 4; i++) {
      let dt = new Date(date)
      dt.setDate(dt.getDate() + i)
      calWindow.push(dt)
    }
    const avatar = 'assets/images/avatars/profile.jpg'
    return (
      <FuseAnimate animation='transition.slideUpIn' delay={300}>
        <div className='w-full relative'>
          {viewing === 'schedule' && (
            <Typography
              variant='h6'
              style={{ alignItems: 'center' }}
              className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
            >
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={trips.length}
                color='error'
              >
                <Icon color='primary' className='text-32 mr-12'>
                  perm_contact_calendar
                </Icon>
              </Badge>
              <span>Schedule</span>
              <Icon
                color='action'
                onClick={this.handleRefresh}
                className={
                  !loading
                    ? 'text-24 ml-8 mt-4 cursor-pointer'
                    : 'text-24 ml-8 mt-4 cursor-pointer spin'
                }
              >
                refresh
              </Icon>
            </Typography>
          )}
          {viewing === 'timecard' && (
            <Typography
              variant='h6'
              style={{ alignItems: 'center' }}
              className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
            >
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={
                  <Icon className='text-14'>
                    {!timecard.ID
                      ? 'add'
                      : timecard.ApprovedYN === 'Y'
                        ? 'cloud_done'
                        : timecard.SubmittedYN === 'Y'
                          ? 'cloud_upload'
                          : 'edit'}
                  </Icon>
                }
                color={
                  !timecard.ID
                    ? 'error'
                    : timecard.ApprovedYN === 'Y'
                      ? 'secondary'
                      : timecard.SubmittedYN === 'Y'
                        ? 'secondary'
                        : 'primary'
                }
              >
                <Icon color='primary' className='text-32 mr-12'>
                  access_time
                </Icon>
              </Badge>
              <span>Timecard</span>
              <Icon
                color='action'
                onClick={this.handleRefresh}
                className={
                  !loading
                    ? 'text-24 ml-8 mt-4 cursor-pointer'
                    : 'text-24 ml-8 mt-4 cursor-pointer spin'
                }
              >
                refresh
              </Icon>
            </Typography>
          )}
          <IconButton
            onClick={this.openMenu}
            className='pin-r pin-t absolute'
            style={{ marginTop: -4 }}
          >
            <Icon color='action' className='text-24'>
              more_vert
            </Icon>
          </IconButton>
          <Menu
            id='profileMenu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => this.setState({ ...this.state, anchorEl: null })}
          >
            <MenuItem
              selected={viewing === 'schedule'}
              onClick={() =>
                this.setState({
                  ...this.state,
                  viewing: 'schedule',
                  anchorEl: null,
                })
              }
            >
              <Icon className='mr-6'>perm_contact_calendar</Icon>Schedule
            </MenuItem>
            <MenuItem
              selected={viewing === 'timecard'}
              onClick={() =>
                this.setState({
                  ...this.state,
                  viewing: 'timecard',
                  anchorEl: null,
                })
              }
            >
              <Icon className='mr-6'>access_time</Icon>Timecard
            </MenuItem>
          </Menu>
          <div className={classNames(classes.root, 'border-0')}>
            <div className={classes.calendar}>
              <div className={classNames(classes.calendarHeader, 'flex')}>
                <Typography
                  onClick={this.handleToday}
                  variant='subheading'
                  style={{ alignItems: 'center', color: '#fff' }}
                  className='flex w-full justify-center font-bold m-4'
                >
                  <Icon className='text-white text-16 mr-4'>today</Icon>
                  {months[new Date(date).getMonth()]}{' '}
                  {new Date(date).getFullYear()}
                </Typography>
              </div>
              <div className={classNames(classes.calendarPicker, 'flex')}>
                <Icon
                  onClick={this.handlePrevDate}
                  className='text-white w-32 h-72 pt-24 text-center cursor-pointer'
                  style={{ background: 'rgba(0,0,0,.25)' }}
                >
                  chevron_left
                </Icon>
                <div className={classes.calendarPickerDates}>
                  {date && (
                    <div className='flex'>
                      {calWindow.map((dt, index) => {
                        const day = days[dt.getDay()]
                        return (
                          <div
                            onClick={() => this.handleDateClick(dt)}
                            className='w-full pt-8 h-72 cursor-pointer'
                            style={{
                              background:
                                index === 3
                                  ? 'rgba(255,255,255,.1)'
                                  : 'transparent',
                            }}
                          >
                            <Typography
                              fullWidth
                              style={{
                                alignItems: 'center',
                                margin: 0,
                                lineHeight: 1,
                                fontWeight: 'bold',
                                color: '#fff',
                              }}
                              className='w-full text-center'
                            >
                              {day ? day.substr(0, 3) : ''}
                            </Typography>
                            <div className='w-full text-center text-white relative'>
                              <div
                                className='absolute mt-8 pin-l pin-r m-auto h-28 w-28 p-4 font-bold'
                                style={{
                                  borderRadius: '50%',
                                  background:
                                    index === 3 ? '#d1e751' : 'transparent',
                                  color: index === 3 ? '#333' : '#fff',
                                }}
                              >
                                {dt.getDate()}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
                <Icon
                  onClick={this.handleNextDate}
                  className='text-white w-32 h-72 pt-24 text-center cursor-pointer'
                  style={{ background: 'rgba(0,0,0,.25)' }}
                >
                  chevron_right
                </Icon>
              </div>
            </div>
          </div>
          {viewing === 'schedule' && (
            <div
              className={classNames(classes.root, 'border-0')}
              style={{ maxHeight: 'unset' }}
            >
              {trips &&
                [...trips].map((trip, index) => {
                  window['warn'](trip)
                  const { Data } = trip
                  if (Data) {
                    const { WorkOrder } = Data
                    if (WorkOrder && WorkOrder.Data) {
                      const { Site } = WorkOrder.Data
                      return (
                        <div key={index} className='flex'>
                          <TechnicianTrip
                            detail={
                              index ===
                              _.findIndex(trips, o => {
                                return o.Status < 7
                              })
                            }
                            onUpdate={trip => {
                              this.updateTrip(trip)
                            }}
                            clickHandler={t => this.selectTrip(t)}
                            key={trip.ID}
                            workOrder={{ ...trip.Data.WorkOrder, value: 2 }}
                            technician={trip.Technician}
                            trip={trip}
                          />
                        </div>
                      )
                    }
                  }
                })}
            </div>
          )}
          {userGPS && (
            <div className={viewing === 'schedule' ? 'sm:flex mt-8' : 'hidden'}>
              <div
                style={{
                  width: '100%',
                  height: '250px',
                  backgroundColor: '#eeeeee',
                  marginBottom: 8,
                  borderRadius: '5px',
                  overflow: 'hidden',
                }}
              >
                <GoogleMap
                  options={this.getMapOptions}
                  gestureHandling='cooperative'
                  bootstrapURLKeys={{
                    key: googleMapsApiKey,
                  }}
                  defaultZoom={12}
                  defaultCenter={[userGPS.Latitude, userGPS.Longitude]}
                  center={
                    selectedTrip &&
                    selectedTrip.Data.WorkOrder.Data.Site.Latitude &&
                    selectedTrip.Data.WorkOrder.Data.Site.Longitude
                      ? [
                          Number(
                            selectedTrip.Data.WorkOrder.Data.Site.Latitude,
                          ),
                          Number(
                            selectedTrip.Data.WorkOrder.Data.Site.Longitude,
                          ),
                        ]
                      : [userGPS.Latitude, userGPS.Longitude]
                  }
                >
                  <Marker
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: '50%',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      margin: 'auto',
                      cursor: 'pointer',
                      position: 'absolute',
                      transform: 'rotate(-45deg)',
                    }}
                    icon={
                      user.Data && user.Data.Avatar
                        ? `${window['apiLocation']}/api/Attachment/GetProfileImage?ID=${user.Data.Avatar}&Thumb=true`
                        : avatar
                    }
                    text={'Your Location'}
                    lat={userGPS.Latitude}
                    lng={userGPS.Longitude}
                    type='person'
                  />
                  {trips &&
                    [...trips].map((trip, index) => {
                      const { Data } = trip
                      if (Data) {
                        const { WorkOrder } = Data
                        if (WorkOrder && WorkOrder.Data) {
                          const { Site } = WorkOrder.Data
                          if (Site.Latitude && Site.Longitude) {
                            return (
                              <Marker
                                key={trip.ID}
                                heading={0}
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  width: 32,
                                  height: 42,
                                  marginTop: -42,
                                  marginLeft: -16,
                                  cursor: 'pointer',
                                }}
                                icon='assets/images/logos/SPMarker_Gradient.png'
                                text={Site.Description}
                                lat={Number(Site.Latitude)}
                                lng={Number(Site.Longitude)}
                                type='site'
                                onClick={() =>
                                  openEditWorkOrderDialog(trip, trip.Trip)
                                }
                              />
                            )
                          }
                        }
                      }
                    })}
                </GoogleMap>
              </div>
            </div>
          )}
          {viewing === 'timecard' && (
            <div className={classNames(classes.root, 'border-0')}>
              <div className='w-full mb-12'>
                <div className='float-left text-left'>
                  <div>
                    <span className='font-bold mr-6'>Time In:</span>
                    <TimePicker
                      clearable
                      className={classes.formControl}
                      label='Time In'
                      id='timeIn'
                      format='h:mm A'
                      openTo='hours'
                      value={
                        timeIn
                          ? moment(moment(timeIn))
                              .local()
                              .toDate()
                              .toLocaleTimeString('en-US')
                          : date
                      }
                      onChange={this.handleTimeInChange}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      TextFieldComponent={this.renderStartLabel}
                      onOpen={() => this.setStartpickerOpen(true)}
                      onClose={() => this.setStartpickerOpen(false)}
                      open={this.state.isStartpickerOpen}
                      auto
                      variant='outlined'
                      fullWidth
                    />
                    {timecard.SubmittedYN !== 'Y' && (
                      <Icon
                        onClick={this.openStartPicker}
                        className='text-16 ml-4 cursor-pointer'
                        style={{ paddingTop: 2 }}
                      >
                        edit
                      </Icon>
                    )}
                  </div>
                  <div>
                    <span className='font-bold mr-6'>Time Out:</span>
                    {/* <span className="text-12">{timeOut && moment(moment(timeOut)).local().toDate().toLocaleTimeString('en-US')}</span> */}
                    {tcData && timeIn && (
                      <React.Fragment>
                        <TimePicker
                          clearable
                          className={classes.formControl}
                          label='Time Out'
                          id='timeOut'
                          format='h:mm A'
                          openTo='hours'
                          value={
                            timeOut
                              ? moment(moment(timeOut))
                                  .local()
                                  .toDate()
                                  .toLocaleTimeString('en-US')
                              : date
                          }
                          onChange={this.handleTimeOutChange}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel,
                          }}
                          TextFieldComponent={this.renderEndLabel}
                          onOpen={() => this.setEndpickerOpen(true)}
                          onClose={() => this.setEndpickerOpen(false)}
                          open={this.state.isEndpickerOpen}
                          auto
                          variant='outlined'
                          fullWidth
                        />
                        {timecard.SubmittedYN !== 'Y' && (
                          <Icon
                            onClick={this.openEndPicker}
                            className='text-16 ml-4 cursor-pointer'
                            style={{ paddingTop: 2 }}
                          >
                            edit
                          </Icon>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className='float-right text-right'>
                  <div>
                    <span className='font-bold mr-6'>{`${technician.Employee} - ${technician.FirstName} ${technician.LastName}`}</span>
                  </div>
                  <div>
                    <span className='font-bold mr-6'>
                      {date.toLocaleDateString('en-US')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full mb-12 clearfix'>
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center', padding: 4, clear: 'both' }}
                  className='flex w-full justify-center'
                >
                  <span className='text-16 font-bold'>Daily Timecard</span>
                </Typography>
                {tcData && timeIn && (
                  <React.Fragment>
                    <Typography
                      variant='h6'
                      style={{
                        alignItems: 'center',
                        marginTop: 4,
                        clear: 'both',
                      }}
                      className='flex'
                    >
                      <Badge
                        badgeContent={
                          tcData.Labor && _.sumBy(tcData.Labor, 'Qty')
                        }
                        color='primary'
                        classes={{ badge: classes.inlineBadge }}
                      >
                        <span className='text-16'>Hours</span>
                      </Badge>
                    </Typography>
                    {tcData &&
                      tcData.Labor &&
                      tcData.Labor.map((value, index) => (
                        <TimecardLabor
                          submitted={timecard.SubmittedYN}
                          key={value.ID}
                          trips={[...trips]}
                          data={{ ...value }}
                        />
                      ))}
                    {timecard.SubmittedYN !== 'Y' && (
                      <TimecardLabor trips={[...trips]} data={null} />
                    )}

                    {tcData && tcData.Punch && tcData.Punch.length > 1 && (
                      <Typography
                        variant='h6'
                        style={{
                          alignItems: 'center',
                          marginTop: -8,
                          clear: 'both',
                        }}
                        className='flex'
                      >
                        <span className='text-16'>Breaks</span>
                      </Typography>
                    )}

                    {tcData &&
                      tcData.Punch &&
                      tcData.Punch.map((value, index) => {
                        if (index > 0) {
                          return (
                            <TimecardBreak
                              key={value.ID}
                              submitted={timecard.SubmittedYN}
                              data={{
                                StartRec: tcData.Punch[index - 1],
                                EndRec: value,
                              }}
                            />
                          )
                        }
                      })}
                  </React.Fragment>
                )}
              </div>
              {timeIn && timeOut && (
                <React.Fragment>
                  <span>
                    <strong style={{ marginRight: 4 }}>Signature:</strong>
                  </span>
                  <br />
                  <div
                    className='w-full mb-12'
                    onClick={() => {
                      if (timecard.SubmittedYN !== 'Y') {
                        this.setState({
                          ...this.state,
                          isSigning: true,
                          sigImg: null,
                        })
                      }
                    }}
                    style={{
                      border: '1px dotted #3f3f3f',
                      width: '100%',
                      height: 150,
                      borderRadius: 5,
                    }}
                  >
                    {(this.state.sigImg || timecard.Signature) && (
                      <img
                        style={{ width: '100%', height: 150 }}
                        src={this.state.sigImg || timecard.Signature}
                      />
                    )}
                  </div>
                  {this.state.sigImg && timecard.SubmittedYN !== 'Y' && (
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        timecard.SubmittedYN = 'Y'
                        timecard.ApprovedYN = null
                        timecard.SubmittedDate = new Date().toLocaleString(
                          'en-US',
                        )
                        timecard.Signature = this.state.sigImg
                        this.props.editTimecard(timecard)
                      }}
                      className={classNames('w-full mb-24')}
                    >
                      <Icon className='mr-8'>cloud_upload</Icon>
                      Submit Timecard
                    </Button>
                  )}
                  {timecard.SubmittedYN === 'Y' &&
                    timecard.ApprovedYN !== 'Y' && (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          timecard.SubmittedYN = null
                          timecard.SubmittedDate = null
                          timecard.Signature = null
                          this.props.editTimecard(timecard)
                        }}
                        className={classNames(classes.withdraw, 'w-full mb-24')}
                      >
                        <Icon className='mr-8'>history</Icon>
                        Withdraw Timecard
                      </Button>
                    )}
                </React.Fragment>
              )}
              {date.toLocaleDateString('en-US') ===
                new Date().toLocaleDateString('en-US') &&
                (!timeIn || timeOut) &&
                timecard.SubmittedYN !== 'Y' && (
                  <React.Fragment>
                    {!this.state.sigImg && (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          const { Co, Employee } = technician

                          const punch = {
                            Co,
                            Employee,
                            Date: date.toLocaleDateString('en-US'),
                            TimeIn: new Date().toLocaleString('en-US'),
                          }
                          this.props.clockIn(punch)
                        }}
                        className={classNames(
                          classes.clockInBtn,
                          'w-full mb-24',
                        )}
                      >
                        <Icon className='mr-8'>timer</Icon>
                        Clock In
                      </Button>
                    )}
                  </React.Fragment>
                )}
              <Dialog
                open={this.state.isSigning}
                classes={{ paper: classes.signaturePaper }}
                fullWidth
                maxWidth='sm'
                onClose={() => {
                  if (this.sigPad.isEmpty()) {
                    this.setState({ ...this.state, isSigning: false })
                  }
                }}
              >
                <div className='w-full mb-12'>
                  <span>
                    <strong style={{ marginRight: 4 }}>Signature:</strong>
                  </span>
                  <br />
                </div>
                <div
                  className='w-full'
                  style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
                >
                  <SignatureCanvas
                    ref={ref => {
                      this.sigPad = ref
                    }}
                    penColor='#66aef9'
                    canvasProps={{
                      className: 'sigCanvas',
                      style: { width: '100%', height: 150 },
                    }}
                  />
                </div>
                <div className='flex w-full mt-16'>
                  <Button
                    variant='contained'
                    className={classes.clearBtn}
                    fullWidth
                    onClick={this.clear}
                  >
                    Clear
                  </Button>
                  <div className='min-w-12'></div>
                  <Button
                    variant='contained'
                    className={classes.okBtn}
                    fullWidth
                    onClick={this.trim}
                  >
                    OK
                  </Button>
                </div>
              </Dialog>
              {date.toLocaleDateString('en-US') ===
                new Date().toLocaleDateString('en-US') &&
                timeIn &&
                !timeOut && (
                  <React.Fragment>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        var rec = this.state
                        window['log'](rec)
                        const punch = {
                          ...tcData.Punch[tcData.Punch.length - 1],
                          TimeOut: new Date().toLocaleString('en-US'),
                        }
                        this.props.editPunch(punch)
                      }}
                      className={classNames(
                        classes.clockOutBtn,
                        'w-full mb-24',
                      )}
                    >
                      <Icon className='mr-8'>timer</Icon>
                      Clock Out
                    </Button>
                  </React.Fragment>
                )}
            </div>
          )}
        </div>
      </FuseAnimate>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTechnicians: Actions.getTechnicians,
      getUserData: Actions.getUserData,
      toggleInSelectedTechnicians: Actions.toggleInSelectedTechnicians,
      selectAllTechnicians: Actions.selectAllTechnicians,
      deSelectAllTechnicians: Actions.deSelectAllTechnicians,
      openEditTechnicianDialog: Actions.openEditTechnicianDialog,
      removeTechnicians: Actions.removeTechnicians,
      removeTechnician: Actions.removeTechnician,
      toggleStarredTechnician: Actions.toggleStarredTechnician,
      toggleStarredTechnicians: Actions.toggleStarredTechnicians,
      setTechniciansStarred: Actions.setTechniciansStarred,
      setTechniciansUnstarred: Actions.setTechniciansUnstarred,
      openNewTechnicianDialog: Actions.openNewTechnicianDialog,
      getTechnicianSchedule: Actions.getTechnicianSchedule,
      getWOData: DispatchActions.getWOData,
      getTripData: DispatchActions.getTripData,
      setTrips: DispatchActions.setTrips,
      setBoardDate: DispatchActions.setBoardDate,
      loadingTrips: DispatchActions.loadingTrips,
      updateTrips: DispatchActions.updateTrips,
      setSchedule: DispatchActions.setSchedule,
      openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
      updateTrip: DispatchActions.updateTrip,
      clockIn: Actions.clockIn,
      editPunch: Actions.editPunch,
      editTimecard: Actions.editTimecard,
      removePunch: Actions.removePunch,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, techniciansApp, dispatchBoardApp }) {
  window['log'](spReducers, techniciansApp)
  return {
    loading: techniciansApp.technicians.technicianDialog.loading,
    selectedTechnicianIds: techniciansApp.technicians.selectedTechnicianIds,
    searchText: techniciansApp.technicians.searchText,
    user: spReducers.userProfiles.User,
    workOrderData: spReducers.workOrders,
    date:
      techniciansApp.technicians.technicianDialog.date ||
      dispatchBoardApp.dispatchBoard.date,
    technicians: spReducers.technicians,
    schedule: dispatchBoardApp.dispatchBoard.schedule,
    Co: spReducers.companies.Co,
    gps: spReducers.gps,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianProfile)),
)
