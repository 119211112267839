import localforage from 'localforage';
import JSONL from 'json-literal';

// localforage.removeItem('persist:root');
// localforage.removeItem('persist:sp-dispatch');
// localforage.removeItem('persist:sp-reducers');
// localforage.removeItem('persist:sp-catalog');
// localforage.removeItem('persist:sp-material');
// localforage.removeItem('persist:sp-users');

const localSporage = {
    ...localforage,
    getItem: async (key, data) => {
        const value = await localforage.getItem(key, data);
        return value;
        // if (value === null) {
        //     return null;
        // }
        // return JSONL.parse(value);
    },
    setItem: async (key, data) => {
        await localforage.setItem(key, data);
    }
}

export default localSporage;