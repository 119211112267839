import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { AppBar, Avatar, Badge, Button, Card, CardHeader, CardContent, CardActions, Chip, Collapse, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import { getConversation } from 'main/messaging/store/actions';
import { NavLink, withRouter } from 'react-router-dom';
import Timer from 'react-compound-timer';
import ReactTable from "react-table";
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import green from '@material-ui/core/colors/green';
import _ from '@lodash';
import axios from 'axios/index';
import moment from 'moment';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    completed: {
        backgroundColor: theme.palette.error,
    },
    ringing: {
        backgroundColor: '#3f3f3f',
    },
    card: {
        marginTop: 12,
        cursor: 'pointer',
        width: '100%',
    },
    actions: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    campaign: {
        width: 'auto',
        top: 5,
        right: 5,
        padding: 10,
        paddingLeft: 0,
        paddingRight: 0,
        transform: 'none',
        background: 'transparent'
    },
    callInfo: {
        color: '#fff',
        height: 'auto',
        borderRadius: 3,
        marginLeft: 5,
        fontSize: 11,
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    tabs: {
        width: '100%',
    },
    tab: {
        minWidth: '33.3%',
        maxWidth: '33.3%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    recStatBadge: {
        top: -12,
        right: 2,
        marginBottom: -12,
        width: 20,
        height: 20,
        backgroundColor: '#3f3f3f'
    },
});

class CallHandlerCallCard extends Component {
    state = {
        call: {
            To: '',
            From: '',
            Queue: '',
            CallSid: '',
            ConversationID: '',
            Data: {
                Customers: [],
                Sites: [],
                WorkOrders: [],
                Phone: {},
                QueueID: '',
                Queue: {},
                QueueName: '',
            }
        },
        status: 'queued',
        handler: null,
        value: 1,
        id: null,
        callID: null,
        hoverRecStatus: false
    };

    componentDidMount() {
        window["warn"](this.props);
        const { call, handler } = this.props;
        this.setState({ ...this.state, call, handler, tab: 0 });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { call, handler } = this.props;
        window["warn"](this.props, this.state);
        if (!_.isEqual(this.props.call, this.state.call) || !_.isEqual(this.props.handler, this.state.handler)) {
            this.setState({ ...this.state, call, handler, tab: 0 });
        }
    };

    handleTabChange = (event, value) => {
        if (this.state.value == value) {
            this.setState({ value: -1 });
        } else {
            this.setState({ value });
        }
    }
    handleExpandClick(id) {
        //const { expanded } = this.state;
        //const call = (id !== expanded ? id : null);
        //this.setState({ ...this.state, expanded: call }, () => { window["warn"](this.state) });
    };

    stop = (event) => {
        event.stopPropagation();
    }

    formatPhone(phone) {
        return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    handleCall = () => {
        const { call, handler } = this.state;
        const { username, pageLayout } = this.props;
        if (call.Type === 'text') {
            this.props.getConversation(call.Co, call.ConversationID.toLowerCase());
            pageLayout().toggleRightSidebar();
        }
        if (!handler) {
            this.setState({ ...this.state, status: 'answered', handler: username }, () => { this.props.acceptCall(call, username); });

        } else {
            if (handler === username) {
                this.setState({ ...this.state, status: 'completed' }, () => { this.props.endCall(call, username); });
            }
        }
    }

    endCall = () => {
        const { call, handler } = this.state;
        const { username } = this.props;
        if (!handler || handler === username) {
            this.setState({ ...this.state, status: 'completed' }, () => { this.props.endCall(call, username); });
        }
    }

    divertCall = () => {
        const { call, handler } = this.state;
        const { username, callQueues } = this.props;
        const queue = _.find(callQueues, { Co: call.Co, Queue: call.Queue });
        if (!handler && queue && queue.FallbackAction === "Dial" && queue.FallbackDial) {
            this.setState({ ...this.state, status: 'forwarded' }, () => { this.props.divertCall(call, queue.FallbackDial); });
        }
    }

    enterRecStatus = () => {
        this.setState({ ...this.state, hoverRecStatus: true });
    }

    leaveRecStatus = () => {
        this.setState({ ...this.state, hoverRecStatus: false });
    }

    updateRecStatus = (e) => {
        e.stopPropagation();
        const { call } = this.state;
        let status = call.RecordingStatus === 'in-progress' ? 'paused' : call.RecordingStatus === 'paused' ? 'in-progress' : call.RecordingStatus;
        this.props.updateRecordingStatus(call, status);
    }

    render() {
        const { classes, securables, user, openCustomer, openSite, openWorkOrder, newCustomerCall, id, callID, getCustomerData, getSiteData, handler, agent, callQueues, phoneNumbers, username } = this.props;
        const { call, value, status, hoverRecStatus, anchorEl } = this.state;
        const queue = _.find(callQueues, { Co: call.Co, Queue: call.Queue });
        const phone = _.find(phoneNumbers, { Co: call.Co, Phone: call.To });
        const { DefaultBusinessUnit, DefaultDivision, DefaultDepartment } = queue ? queue : {};
        const accessLevel = _.find(securables, { Securable: "call-handler" });
        const dbu = DefaultBusinessUnit;
        const ddv = DefaultDivision;
        const ddp = DefaultDepartment;
        const startDate = moment(moment.utc(call.Status === "queued" ? call.CreatedDate : call.HandledDate)).local().toDate();
        const wait = (new Date() - startDate);
        return (
            <Badge classes={{ root: "w-full", badge: classNames(classes.campaign, "w-full justify-end") }} badgeContent={
                <React.Fragment>
                    {phone && <Tooltip placement="top" title={phone.Name}><Chip className={classNames("max-w-2/5 truncate", classes.callInfo)} classes={{ label: "py-4" }} color="primary" label={phone.Name} /></Tooltip>}
                    {queue && <Tooltip placement="top" title={queue.Queue}><Chip className={classNames("max-w-2/5 truncate", classes.callInfo)} classes={{ label: "py-4" }} color="secondary" label={queue.Queue} /></Tooltip>}
                </React.Fragment>
            }>
                {anchorEl &&
                    <Menu id={`${call.CallSid}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.setState({ anchorEl: null })}>
                        <MenuItem onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.endCall(); this.setState({ anchorEl: null }) } }}><Icon color="error" className="mr-12">call_end</Icon>End Call</MenuItem>
                        <MenuItem disabled={true} onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { /*this.transferCall();*/ this.setState({ anchorEl: null }) } }}><Icon color="primary" className="mr-12">phone_forwarded</Icon>Transfer Call</MenuItem>
                        <MenuItem disabled={true} onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { /*this.finishCall();*/ this.setState({ anchorEl: null }) } }}><Icon color="secondary" className="mr-12">check_circle_outline</Icon>Mark as Handled</MenuItem>
                    </Menu>
                }
                <Card onClick={this.stop} className={classes.card}>
                    <CardHeader onClick={() => newCustomerCall(call)} className="p-12 pt-20 highlight"
                        classes={{ subheader: "text-11", title: "font-bold" }}
                        avatar={
                            <div>
                                {!handler ?
                                    <Tooltip title={"Accept Conversation"} placement="top">
                                        <Avatar onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.handleCall() } }} className={(agent && !agent.SessionID && this.state.status === "answered") || status === 'forwarded' ? classes.ringing : status === 'completed' ? classes.completed : handler || status === 'answered' ? classes.selectedCallAvatar : classes.avatar}>
                                            <Icon>{call.Type === 'text' ? 'phone_iphone' : agent && !agent.SessionID && this.state.status === "answered" ? 'phone_callback' : (handler || status == 'answered') && status !== 'completed' ? 'phone_in_talk' : status === 'forwarded' ? 'phone_forwarded' : 'ring_volume'}</Icon>
                                        </Avatar>
                                    </Tooltip> :
                                    <Tooltip title={"End Conversation"} placement="top">
                                        <Avatar onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.handleCall() } }} className={(agent && !agent.SessionID && this.state.status === "answered") || status === 'forwarded' ? classes.ringing : status === 'completed' ? classes.completed : handler || status === 'answered' ? classes.selectedCallAvatar : classes.avatar}>
                                            <Icon>{call.Type === 'text' ? 'phone_iphone' : agent && !agent.SessionID && this.state.status === "answered" ? 'phone_callback' : (handler || status == 'answered') && status !== 'completed' ? 'phone_in_talk' : status === 'forwarded' ? 'phone_forwarded' : 'ring_volume'}</Icon>
                                        </Avatar>
                                    </Tooltip>
                                }
                                {call.RecordingStatus && call.RecordingStatus !== 'completed' &&
                                    <Tooltip title={call.RecordingStatus === 'in-progress' ? "Pause Recording" : "Resume Recording"} placement="top">
                                        <Avatar onClick={this.updateRecStatus} onMouseEnter={this.enterRecStatus} onMouseLeave={this.leaveRecStatus} className={classes.recStatBadge}>
                                            <Icon color={call.RecordingStatus === 'in-progress' && !hoverRecStatus ? undefined : hoverRecStatus ? undefined : "error"} className="text-14">{call.RecordingStatus === 'in-progress' && !hoverRecStatus ? 'mic' : hoverRecStatus ? 'mic' : 'mic_off'}</Icon>
                                        </Avatar>
                                    </Tooltip>
                                }
                            </div>
                        }
                        title={`${this.formatPhone(call.From.replace('+1', ''))}`}
                        subheader={
                            call.Status === 'queued' ? startDate.toLocaleTimeString('en-US') :
                                call.CreatedDate && wait && wait > 0 ?
                                    <Timer initialTime={wait} lastUnit="m">
                                        {(timer) => {
                                            return (
                                                <div>
                                                    <label className="text-10">
                                                        <Timer.Minutes />
                                                    </label>
                                                    <label className="text-10 ml-4">
                                                        Minutes
                                                    </label>
                                                    <label className="text-10 ml-4">
                                                        <Timer.Seconds />
                                                    </label>
                                                    <label className="text-10 ml-4">
                                                        Seconds
                                                    </label>
                                                </div>
                                            )
                                        }}
                                    </Timer> : ''
                            // startDate.toLocaleTimeString('en-US')
                            // <Timer initialTime={startTime}>
                            //     {() => (
                            //         <React.Fragment>
                            //             <Timer.Minutes /> Minutes <Timer.Seconds /> Seconds
                            //         </React.Fragment>
                            //     )}
                            // </Timer>
                        }
                        action={
                            call.Type !== 'text' &&
                            <React.Fragment>
                                {
                                    !handler && queue && queue.FallbackAction === 'Dial' && queue.FallbackDial && status !== 'forwarded' &&
                                    <Tooltip title={`Divert to ${queue.FallbackDial}`} placement="top">
                                        <IconButton style={{ marginTop: 8, }} onClick={(e) => { e.stopPropagation(); if (accessLevel && accessLevel.AccessLevel !== "R") { this.divertCall() } }} aria-owns={undefined}>
                                            <Icon>phone_forwarded</Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {call.Status === 'answered' && handler && handler === username &&
                                    <Tooltip title={`Call Actions`} placement="top" onClick={(e) => this.setState({ anchorEl: e.target })}>
                                        <IconButton style={{ marginTop: 8, }} aria-owns={undefined}>
                                            <Icon color="action">more_vert</Icon>
                                            {/* <Icon color="error">call_end</Icon> */}
                                        </IconButton>
                                    </Tooltip>
                                }
                            </React.Fragment>
                        }
                    />
                    {call.Data.Customers.length > 0 || call.Data.Sites.length > 0 || call.Data.WorkOrders.length > 0 ?
                        <div>
                            <AppBar color="primary" className={classNames(classes.actions)}>
                                <Tabs value={value} className={classNames(classes.tabs)} onChange={this.handleTabChange} variant="fullWidth">
                                    <Tooltip title="Customers" placement="top">
                                        <Tab disableRipple className={classes.tab} icon={(
                                            <Badge color="error" badgeContent={call.Data.Customers.length}>
                                                <Icon className="text-32">account_circle</Icon>
                                            </Badge>)} />
                                    </Tooltip>
                                    <Tooltip title="Service Sites" placement="top">
                                        <Tab disableRipple className={classes.tab} icon={(
                                            <Badge color="error" badgeContent={call.Data.Sites.length}>
                                                <Icon className="text-32">location_on</Icon>
                                            </Badge>)} />
                                    </Tooltip>
                                    <Tooltip title="Upcoming Work Orders" placement="top">
                                        <Tab disableRipple className={classes.tab} icon={(
                                            <Badge color="error" badgeContent={call.Data.WorkOrders.length}>
                                                <Icon className="text-32">assignment</Icon>
                                            </Badge>)} />
                                    </Tooltip>
                                </Tabs>
                            </AppBar>
                            {value == 0 &&
                                <TabContainer>
                                    <CardContent className={classes.info}>
                                        {call.Data.Customers.map((value, index) => (
                                            <div key={index} style={{ borderTop: index > 0 ? '1px dashed lightgrey' : 'none' }} onClick={() => { getCustomerData(value, true, { BusinessUnit: dbu ? dbu : '', Division: ddv ? ddv : '', Department: ddp ? ddp : '', CallSid: call.Sid }) }} className="w-full p-8 highlight">
                                                <Typography fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                                    <Icon color="primary" className="text-24 mr-4">account_circle</Icon>
                                                    <strong>Customer #{value.Customer}</strong>
                                                    <Tooltip placement="top" title="Edit Customer">
                                                        <Icon onClick={() => { openCustomer(value); }} className="text-16 ml-8 cursor-pointer justify-end" style={{ paddingTop: 2, marginLeft: 4 }}>open_in_new</Icon>
                                                    </Tooltip>
                                                </Typography>
                                                <span className="text-xs">
                                                    {value.Name && value.Name.length > 0 ? value.Name : `${value.FirstName} ${value.LastName}`}<br />
                                                    {`${value.Address1}`}<br />
                                                    {`${value.City}, ${value.State} ${value.Zip}`}
                                                </span>
                                            </div>
                                        ))}
                                    </CardContent>
                                </TabContainer>
                            }
                            {value == 1 &&
                                <TabContainer>
                                    <CardContent className={classes.info}>
                                        {call.Data.Sites.map((value, index) => (
                                            <div key={index} style={{ borderTop: index > 0 ? '1px dashed lightgrey' : 'none' }} onClick={() => { getSiteData(value, true, { BusinessUnit: dbu ? dbu : '', Division: ddv ? ddv : '', Department: ddp ? ddp : '', CallSid: call.Sid }) }} className="w-full p-8 highlight">
                                                <Typography fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                                    <Icon color="primary" className="text-24 mr-4">location_on</Icon>
                                                    <strong>Site #{value.Site}</strong>
                                                    <Tooltip placement="top" title="Edit Site">
                                                        <Icon onClick={() => { openSite(value); }} className="text-16 ml-8 cursor-pointer justify-end" style={{ paddingTop: 2, marginLeft: 4 }}>open_in_new</Icon>
                                                    </Tooltip>
                                                </Typography>
                                                <span className="text-xs">
                                                    {`${value.Description}`}<br />
                                                    {`${value.Address1}`}<br />
                                                    {`${value.City}, ${value.State} ${value.Zip}`}
                                                </span>
                                            </div>
                                        ))}
                                    </CardContent>
                                </TabContainer>
                            }
                            {value == 2 &&
                                <TabContainer>
                                    <CardContent className={classes.info}>
                                        {call.Data.WorkOrders.map((value, index) => (
                                            <div key={index} style={{ borderTop: index > 0 ? '1px dashed lightgrey' : 'none' }} onClick={() => { openWorkOrder(value); }} className="w-full p-8 highlight">
                                                <Typography fullWidth style={{ alignItems: 'center', marginBottom: 0 }} className="flex">
                                                    <Icon color="primary" className="text-24 mr-4">assignment</Icon>
                                                    <strong>Work Order #{value.WorkOrder}</strong>
                                                    <Tooltip placement="top" title="Edit Site">
                                                        <Icon onClick={() => { openWorkOrder(value); }} className="text-16 ml-8 cursor-pointer justify-end" style={{ paddingTop: 2, marginLeft: 4 }}>open_in_new</Icon>
                                                    </Tooltip>
                                                </Typography>
                                                <span className="text-xs">
                                                    {`${value.Description}`}
                                                </span>
                                            </div>
                                        ))}
                                    </CardContent>
                                </TabContainer>
                            }
                        </div>
                        : ''
                    }
                </Card>
            </Badge>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //openCall: Actions.openCall,
        openCustomer: openEditCustomerDialog,
        openSite: openEditCustomerSiteDialog,
        openWorkOrder: openEditWorkOrderDialog,
        getCustomerData: Actions.getCustomerData,
        getSiteData: Actions.getSiteData,
        acceptCall: Actions.acceptCall,
        divertCall: Actions.divertCall,
        endCall: Actions.endCall,
        newCustomerCall: Actions.newCustomerCall,
        updateRecordingStatus: Actions.updateRecordingStatus,
        getConversation,
    }, dispatch);
}

function mapStateToProps({ callHandlerApp, contactsApp, spReducers }) {
    return {
        user: contactsApp.user,
        callHandler: callHandlerApp.callHandler,
        callID: callHandlerApp.callHandler.id,
        username: spReducers.userProfiles.User.UserName,
        agent: spReducers.agentStatus.Agent,
        securables: spReducers.userProfiles.User.Data.Securables,
        callQueues: spReducers.callQueues,
        phoneNumbers: spReducers.phoneNumbers,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallHandlerCallCard)));
