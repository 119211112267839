import React, { Component } from 'react';
import {
    withStyles,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Chip,
    Icon,
    IconButton,
    Typography,
    Toolbar,
    AppBar,
    Avatar,
    Checkbox,
    FormControlLabel,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Badge,
    Slide,
    Grow,
    Divider
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import Media from 'react-media';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import moment from 'moment/moment';
import classNames from 'classnames';
import { FuseUtils, FuseChipSelect } from '@fuse';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {},
    formControl: {
        marginTop: 8,
        marginBottom: 16
    },
    assigneeBadge: {
        top: 16,
        right: 16,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: '50%'
    },
});

const newTodoState = {
    ID: null,
    Title: null,
    Notes: null,
    StartDate: null,
    DueDate: null,
    CompletedYN: null,
    StarredYN: null,
    ImportantYN: null,
    Data: {
        Assignees: [],
        Tags: [],
        ErrMsg: null
    },
    AddedBy: null,
    AddedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    confirmDelete: false,
};

class TodoDialog extends Component {
    state = {
        form: { ...newTodoState },
        labelMenuEl: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        if (!prevProps.todoDialog.props.open && this.props.todoDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.todoDialog.type === 'edit' &&
                this.props.todoDialog.data &&
                !_.isEqual(this.props.todoDialog.data, prevProps.todoDialog.data)) {
                this.setState({ form: { ...this.props.todoDialog.data } });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.todoDialog.type === 'new' &&
                !_.isEqual(newTodoState, prevState)) {
                const { User } = this.props;
                const form = {
                    ...newTodoState,
                    id: FuseUtils.generateGUID(),
                }
                const assignee = {
                    ItemID: form.ID,
                    Assignee: User.UserName,
                    AddedBy: User.UserName,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                };
                form.Data.Assignees.push(assignee);
                this.setState({
                    form
                });
            }
        }
    }

    handleChange = (event) => {
        const form = _.set({ ...this.state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value);
        this.setState({ form });
    };

    closeTodoDialog = () => {
        this.props.todoDialog.type === 'edit' ? this.props.closeEditTodoDialog() : this.props.closeNewTodoDialog();
    };

    handleLabelMenuOpen = (event) => {
        this.setState({ labelMenuEl: event.currentTarget });
    };

    handleLabelMenuClose = (event) => {
        this.setState({ labelMenuEl: null });
    };

    handleToggleImportant = () => {
        this.setState({
            form: {
                ...this.state.form,
                ImportantYN: this.state.form.ImportantYN !== "Y" ? "Y" : null
            }
        });
    };

    handleToggleStarred = () => {
        this.setState({
            form: {
                ...this.state.form,
                StarredYN: this.state.form.StarredYN !== "Y" ? "Y" : null
            }
        });
    };

    handleToggleLabel = (event, id) => {
        event.stopPropagation();
        const { form } = this.state;
        const { Tags } = form.Data;
        const index = _.findIndex(Tags, { TagID: id });
        if (index > -1) {
            Tags.splice(index, 1);
        } else {
            Tags.push({
                ItemID: form.ID,
                TagID: id
            });
        }
        this.setState({
            form
        });
    };

    toggleCompleted = () => {
        this.setState({
            form: {
                ...this.state.form,
                CompletedYN: this.state.form.CompletedYN !== "Y" ? "Y" : null
            }
        })
    };

    canBeSubmitted() {
        const { Title } = this.state.form;
        return (
            Title && Title.length > 0
        );
    }

    handleStartDateChange = (event) => {
        const { DueDate, form } = this.state;
        if (event && event._d) {
            this.setState({ ...this.state, form: { ...form, StartDate: event.toDate() } });
        } else {
            this.setState({ ...this.state, form: { ...form, StartDate: null } });
        }
    };

    handleEndDateChange = (event) => {
        const { StartDate, form } = this.state;
        if (event && event._d) {
            this.setState({ ...this.state, form: { ...form, DueDate: event.toDate() } });
        } else {
            this.setState({ ...this.state, form: { ...form, DueDate: null } });
        }
    };

    handleAssigneeAction = (assignees) => {
        const { users, User } = this.props;
        const { form } = this.state;
        const { ID, Data } = form;
        const { Assignees } = Data;
        const AddedBy = User.UserName;
        window["warn"]('Old Assignees: ', _.cloneDeepWith(Assignees), assignees);
        for (var i = 0; i < assignees.length; i++) {
            const Assignee = assignees[i].value;
            const isAssignee = _.findIndex(Assignees, { Assignee });
            if (isAssignee < 0) {
                const assignee = {
                    ItemID: ID,
                    Assignee,
                    AddedDate: new Date(),
                    Data: {
                        ErrMsg: null,
                    }
                };
                Assignees.push(assignee);
                window["warn"]('Added Assignee: ', assignee, _.cloneDeepWith(Assignees));
            } else {
                Assignees[isAssignee].DeleteYN = null;
            }
        };

        for (var i = 0; i < Assignees.length; i++) {
            const assignee = Assignees[i];
            const { Assignee } = assignee;
            const isAssignee = _.find(assignees, { value: Assignee });
            window["warn"]('Removed Assignee: ', assignee, isAssignee, Assignees);
            if (!isAssignee) {
                if (!assignee.ID) {
                    Assignees.splice(i, 1);
                } else {
                    assignee.DeleteYN = 'Y';
                }
            }
        }
        window["warn"]('New Assignees: ', _.cloneDeepWith(Assignees), assignees);
        this.setState({ ...this.state, form: { ...form, Data: { ...Data, Assignees } } });

    }

    render() {
        const { classes, todoDialog, addTodo, updateTodo, removeTodo, labels, Co, users, securables, User } = this.props;
        const { form, labelMenuEl } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        let StartDate, DueDate;
        const accessLevel = _.find(securables, { Securable: "to-do" });

        if (form) {
            StartDate = moment(form.StartDate).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
            DueDate = moment(form.DueDate).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
        }

        return (
            <Media query="(min-width: 1200px)">
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        className={classes.root}
                        {...todoDialog.props}
                        classes={{
                            paper: matches ? "" : "full-screen-dialog"
                        }}
                        onClose={this.closeTodoDialog}
                        fullWidth
                        maxWidth="sm"
                        fullScreen={!matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {todoDialog.type === 'new' ? 'New To-Do' : 'Edit To-Do'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 8 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeTodoDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-0 pt-12" }}>

                            <div className="px-16 sm:px-24">
                                <FormControl className={classes.formControl} required fullWidth>
                                    <TextField
                                        label="Title"

                                        name="Title"
                                        value={form.Title}
                                        onChange={this.handleChange}
                                        required
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FuseChipSelect
                                    className={classes.formControl}
                                    value={
                                        form.Data.Assignees.map(memb => {
                                            const assignee = _.find(users, { UserName: memb.Assignee });
                                            if (assignee && memb.DeleteYN !== "Y") {
                                                return {
                                                    value: assignee.UserName,
                                                    name: `${assignee.FirstName} ${assignee.LastName}`,
                                                    label: (
                                                        <Tooltip title={`${assignee.FirstName} ${assignee.LastName}`}>
                                                            <Badge classes={{ badge: classNames(classes.assigneeBadge) }} badgeContent={`${assignee.FirstName.substr(0, 1)}${assignee.LastName.substr(0, 1)}`}>
                                                                <Avatar className="-ml-12 w-32 h-32" src={assignee.Data && assignee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${assignee.Data.Avatar}`) : avatar} />
                                                            </Badge>
                                                        </Tooltip>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    onChange={(value) => this.handleAssigneeAction(value)}
                                    label="Assignees"
                                    placeholder="Select Assignees"
                                    isMulti
                                    textFieldProps={{
                                        variant: "outlined",
                                    }}
                                    options={_.filter(users, { ActiveYN: 'Y' }).map((assignee) => (
                                        {
                                            value: assignee.UserName,
                                            name: `${assignee.FirstName} ${assignee.LastName}`,
                                            label: (<span className="flex items-center"><Avatar className="w-32 h-32 mr-8" src={assignee.Data && assignee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${assignee.Data.Avatar}`) : avatar} />{`${assignee.FirstName} ${assignee.LastName}`}</span>)
                                        }
                                    ))}
                                    variant="fixed"
                                />

                                <FormControl className={classes.formControl} required fullWidth>
                                    <TextField
                                        label="Notes"
                                        name="Notes"
                                        multiline
                                        rows="6"
                                        value={form.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <div className="sm:flex">
                                    <DateTimePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        className={classNames(classes.formControl, "mb-12 sm:mb-4")}
                                        label="Start"
                                        id="start"
                                        format="MM/DD/YYYY h:mm A"
                                        value={form.StartDate || null}
                                        onChange={this.handleStartDateChange}
                                        clearable={true}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <div className="min-h-12 w-24 hidden sm:block"></div>
                                    <DateTimePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        className={classNames(classes.formControl, "mb-12 sm:mb-4")}
                                        label="End"
                                        id="end"
                                        format="MM/DD/YYYY h:mm A"
                                        value={form.DueDate || null}
                                        onChange={this.handleEndDateChange}
                                        clearable={true}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-between px-16 sm:px-24">

                                <div className="flex">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                checked={form.CompletedYN}
                                                onChange={this.toggleCompleted}
                                                onClick={(ev) => ev.stopPropagation()}
                                            />
                                        }
                                        margin="dense"
                                        label="Completed"
                                    />
                                </div>

                                <div className="flex items-center justify-start" aria-label="Toggle star">
                                    <IconButton onClick={this.handleToggleImportant}>
                                        {form.ImportantYN ? (
                                            <Icon style={{ color: red[500] }}>error</Icon>
                                        ) : (
                                            <Icon>error_outline</Icon>
                                        )}
                                    </IconButton>

                                    <IconButton onClick={this.handleToggleStarred}>
                                        {form.StarredYN ? (
                                            <Icon style={{ color: amber[500] }}>star</Icon>
                                        ) : (
                                            <Icon>star_outline</Icon>
                                        )}
                                    </IconButton>
                                    <div>
                                        <IconButton
                                            aria-owns={labelMenuEl ? 'label-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleLabelMenuOpen}
                                        >
                                            <Icon>label</Icon>
                                        </IconButton>
                                        <Menu
                                            id="label-menu"
                                            anchorEl={labelMenuEl}
                                            open={Boolean(labelMenuEl)}
                                            onClose={this.handleLabelMenuClose}
                                        >
                                            {labels.length > 0 && labels.map((label) => (
                                                <MenuItem onClick={(ev) => this.handleToggleLabel(ev, label.ID)} key={label.ID}>
                                                    <ListItemIcon>
                                                        <Icon className="mr-0" color="action">
                                                            {_.find(form.Data.Tags, { TagID: label.ID }) ? 'check_box' : 'check_box_outline_blank'}
                                                        </Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={label.Name} disableTypography={true} />
                                                    <ListItemIcon>
                                                        <Icon className="mr-0" style={{ color: label.Background }} color="action">
                                                            label
                                                        </Icon>
                                                    </ListItemIcon>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                            </div>

                            {form.Data.Tags.length > 0 && (
                                <div className="flex flex-wrap  px-16 sm:px-24 mb-16">
                                    {form.Data.Tags.map(label => (
                                        <Chip
                                            avatar={(
                                                <Avatar
                                                    classes={{ colorDefault: "bg-transparent" }}>
                                                    <Icon
                                                        className="text-20 mr-6"
                                                        style={{ color: _.find(labels, { ID: label.TagID }).Background }}
                                                    >
                                                        label
                                                    </Icon>
                                                </Avatar>
                                            )}
                                            label={_.find(labels, { ID: label.TagID }).Name}
                                            onDelete={(ev) => this.handleToggleLabel(ev, label.TagID)}
                                            className="mr-8 my-8"
                                            classes={{ label: "pl-4" }}
                                            key={label}
                                        />
                                    ))}
                                </div>
                            )}

                        </DialogContent>

                        {todoDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-8 sm:pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addTodo(this.state.form);
                                        this.closeTodoDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        ) : ((accessLevel && accessLevel.AccessLevel !== "R") || this.state.AddedBy === User.UserName || form.AddedBy === User.UserName) &&
                        (
                            <DialogActions className="dialog-actions justify-between pl-8 sm:pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateTodo(this.state.form);
                                        this.closeTodoDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                                </Button>
                                <IconButton
                                    className="min-w-auto"
                                    onClick={() => this.setState({ confirmDelete: true })}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </DialogActions>
                        )}
                        <ConfirmationDialog
                            open={this.state.confirmDelete}
                            title={<div><Icon className="mr-6 align-middle mb-4" color="error">delete</Icon>Are You Sure?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this To-Do Item? This action cannot be undone.`}</div>}
                            confirmText="Delete"
                            cancelText="Cancel"
                            onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                            onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => {
                                removeTodo(this.state.form.ID);
                                this.closeTodoDialog();
                            })}
                        />
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditTodoDialog: Actions.closeEditTodoDialog,
        closeNewTodoDialog: Actions.closeNewTodoDialog,
        addTodo: Actions.addTodo,
        updateTodo: Actions.updateTodo,
        removeTodo: Actions.removeTodo
    }, dispatch);
}

function mapStateToProps({ todoApp, spReducers }) {
    return {
        todoDialog: todoApp.todos.todoDialog,
        labels: spReducers.calendar.tags,
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
        securables: spReducers.userProfiles.User.Data.Securables,
        User: spReducers.userProfiles.User
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TodoDialog));
