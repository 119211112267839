import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTextGatherIds: [],
    routeParams: {},
    textGatherDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    searchResults: [],
};

const textGathersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_RESULTS:
            {
                return {
                    ...state,
                    searchResults: action.payload,
                }
            }
        case Actions.GET_TEXT_GATHERS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_TEXT_GATHERS:
            {

                const textGatherId = action.textGatherId;

                let selectedTextGatherIds = [...state.selectedTextGatherIds];

                if (selectedTextGatherIds.find(id => id === textGatherId) !== undefined) {
                    selectedTextGatherIds = selectedTextGatherIds.filter(id => id !== textGatherId);
                }
                else {
                    selectedTextGatherIds = [...selectedTextGatherIds, textGatherId];
                }

                return {
                    ...state,
                    selectedTextGatherIds: selectedTextGatherIds
                };
            }
        case Actions.SELECT_ALL_TEXT_GATHERS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedTextGatherIds = arr.map(textGathers => textGathers.id);

                return {
                    ...state,
                    selectedTextGatherIds: selectedTextGatherIds
                };
            }
        case Actions.DESELECT_ALL_TEXT_GATHERS:
            {
                return {
                    ...state,
                    selectedTextGatherIds: []
                };
            }
        case Actions.OPEN_NEW_TEXT_GATHER_DIALOG:
            {
                return {
                    ...state,
                    textGatherDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_TEXT_GATHER_DIALOG:
            {
                return {
                    ...state,
                    textGatherDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_TEXT_GATHER_DIALOG:
            {
                return {
                    ...state,
                    textGatherDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_TEXT_GATHER_DIALOG:
                {
                    return {
                        ...state,
                        textGatherDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_TEXT_GATHER_DIALOG:
            {
                return {
                    ...state,
                    textGatherDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default textGathersReducer;
