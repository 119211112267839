import { FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  FormControlLabel,
  Paper,
  Popper,
  Switch,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const styles = theme => ({
  root: {},
  container: {
    width: '100%',
  },
  select: {
    // "& label": {
    //     color: 'rgba(0,0,0,.54) !important',
    // },
    // "& fieldset": {
    //     borderColor: "rgba(0,0,0,.23) !important",
    //     borderWidth: "1px !important",
    // }
  },
  scrollableList: {
    maxHeight: 350,
    overflow: 'auto',
  },
  suggestionsContainerOpen: {
    borderRadius: 3,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: 8,
  },
  input: {
    paddingLeft: '8.8rem',
  },
  tooltip: {
    background: 'none',
    padding: 0,
    margin: 0,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popper: {
    opacity: 1,
    transform: 'none !important',
    zIndex: 999999,
    marginTop: 4,
    marginBottom: 4,
    '&[x-placement*="bottom-start"]': {
      top: '56px !important',
    },
    '&[x-placement*="top-start"]': {
      top: 'unset !important',
      bottom: 56,
    },
  },
  portalPopper: {
    opacity: 1,
    zIndex: 999999,
    marginTop: 4,
    marginBottom: 4,
  },
  selectedOption: {
    background: 'rgba(50, 50, 50, .1)',
  },
})

class Autocomplete extends React.Component {
  state = {
    suggestions: [],
    search: null,
    searchText: null,
    hasFocus: this.props.search ? true : false,
    selectedIndex: 0,
    activeYN: true,
    filter: null,
  }

  componentDidMount() {
    const { search, filter } = this.props
    this.setState({ ...this.state, search, searchText: search, filter })
  }

  componentDidUpdate(prevProps, prevState) {
    const { search, filter } = this.props
    if (
      !_.isEqual(search, prevProps.search) ||
      !_.isEqual(filter, prevProps.filter)
    ) {
      this.setState({ ...this.state, search, searchText: search, filter })
    }
  }

  handleSearchInput = event => {
    const searchText = event.target.value
    const { onChange } = this.props
    if (this.props.debounce) {
      this.setState({ ...this.state, search: searchText })
      this.search(searchText)
    } else {
      this.setState(
        { ...this.state, search: searchText, searchText, selectedIndex: 0 },
        () => {
          if (onChange) {
            onChange(this.state.searchText)
          }
        },
      )
    }
  }

  search = _.debounce(searchText => {
    const { onChange } = this.props
    this.setState({ ...this.state, searchText, selectedIndex: 0 }, () => {
      if (onChange) {
        onChange(this.state.searchText)
      }
    })
  }, this.props.debounce)

  handleSelectedIndex = event => {
    const key = event.keyCode
    window['warn'](key)
    const { options, activeOnly } = this.props
    let { searchText, selectedIndex, activeYN } = this.state
    const hasActive =
      options &&
      _.findIndex(options, o => Boolean(o) && Boolean(o.ActiveYN)) > -1
    const opt = _.filter(options, o => {
      return (
        (activeOnly && o.ActiveYN === 'Y') ||
        !hasActive ||
        !activeYN ||
        !o.ActiveYN ||
        o.ActiveYN === 'Y'
      )
    })
    const suggestions = this.getSuggestions(opt, searchText)
    if (key === 40 || key === 38) {
      if (key === 40 && suggestions.length > selectedIndex + 1) {
        selectedIndex += 1
        this.setState({ ...this.state, selectedIndex })
      }
      if (key === 38 && selectedIndex > 0) {
        selectedIndex -= 1
        this.setState({ ...this.state, selectedIndex })
      }
    }
    if (key === 13) {
      const suggestion = suggestions[selectedIndex]
      if (suggestion) {
        this.handleSelect(suggestion)
      }
    }
  }

  getSuggestions = (options, searchText) => {
    if (searchText && searchText.length > 0) {
      return FuseUtils.filterArrayByString(options, searchText)
    } else {
      return options
    }
  }

  handleFocus = event => {
    event.stopPropagation()
    this.setState(
      {
        hasFocus: true,
        selectedIndex: 0,
      },
      () => {
        if (this.props.setParentContainerHeight) {
          this.props.setParentContainerHeight()
        }
      },
    )
  }

  handleBlur = event => {
    const { onBlur, noBlur } = this.props
    if (!noBlur) {
      this.setState(
        { ...this.state, searchText: '', hasFocus: false, selectedIndex: 0 },
        () => {
          if (onBlur) {
            onBlur()
          }
        },
      )
    }
  }

  handleSelect(data) {
    this.setState(
      {
        ...this.state,
        hasFocus: false,
        selectedIndex: 0,
        searchText: this.props.search,
        search: this.props.search,
      },
      () => this.props.onSelect(data),
    )
  }

  render() {
    const {
      classes,
      className,
      portal,
      noflip,
      InputProps,
      margin,
      disableUnderline,
      focusStyle,
      InputLabelProps,
      inputProps,
      minSearch,
      noBlur,
      selectProps,
      title,
      options,
      value,
      menuItemComponent,
      selectedItemComponent,
      required,
      hasMatch,
      onBlur,
      onChange,
      error,
      moreProps,
      selectOnly,
      activeOnly,
      helperText,
    } = this.props
    const { searchText, hasFocus, selectedIndex, search, activeYN, filter } =
      this.state
    const hasActive =
      options &&
      _.findIndex(options, o => Boolean(o) && Boolean(o.ActiveYN)) > -1
    const opt = _.filter(
      _.filter(options, o => {
        return (
          (activeOnly && o.ActiveYN === 'Y') ||
          !hasActive ||
          !activeYN ||
          !o.ActiveYN ||
          o.ActiveYN === 'Y'
        )
      }),
      filter ? { ...filter } : () => true,
    )
    const suggestions = this.getSuggestions(opt, searchText)
    // window["warn"]('Autocomplete has focus: ', hasFocus);

    return (
      <div
        className={!hasFocus ? 'w-full overflow-x-hidden' : 'w-full'}
        onMouseEnter={e => e.stopPropagation()}
        onMouseMove={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        style={{
          paddingTop: this.props.paddingStyleProp
            ? this.props.paddingStyleProp
            : 14,
          marginTop: -14,
        }}
      >
        {hasFocus && !selectOnly ? (
          <div className={classNames(className, 'relative')}>
            {(!minSearch || (search && minSearch <= search.length)) && (
              <Popper
                disablePortal={!portal}
                className={portal ? classes.portalPopper : classes.popper}
                anchorEl={this.anchorEl}
                open={true}
                placement='bottom-start'
                modifiers={{ flip: { enabled: !noflip } }}
              >
                {suggestions.length > 0 ? (
                  <div
                    className={classes.container}
                    onMouseDown={event => {
                      event.preventDefault()
                    }}
                  >
                    <Paper
                      className={classNames(
                        classes.suggestionsContainerOpen,
                        'w-full autocomplete-focus',
                      )}
                      square
                    >
                      <div
                        className={
                          this.props.listClass
                            ? this.props.listClass
                            : classes.scrollableList
                        }
                      >
                        {_.take(suggestions, 250).map((suggestion, index) => {
                          const content = menuItemComponent(suggestion)
                          if (content && content.toString().length > 0) {
                            return (
                              <div
                                key={index}
                                className={classNames(
                                  'cursor-pointer h-40',
                                  selectedIndex === index &&
                                    classes.selectedOption,
                                )}
                                onClick={() => this.handleSelect(suggestion)}
                              >
                                {content}
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div
                        className='w-full flex justify-between px-12 py-4'
                        style={{ borderTop: '1px solid lightgrey' }}
                      >
                        {activeOnly && this.props.filter && (
                          <FormControlLabel
                            // style={{ float: 'right', }}
                            control={
                              <Switch
                                checked={!Boolean(filter)}
                                name='showAll'
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    filter: filter ? null : this.props.filter,
                                  })
                                }}
                                color='primary'
                              />
                            }
                            label='Show All'
                          />
                        )}
                        {!activeOnly && hasActive && (
                          <FormControlLabel
                            // style={{ float: 'right', }}
                            control={
                              <Switch
                                checked={activeYN}
                                name='showActive'
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    activeYN: !activeYN,
                                  })
                                }}
                                color='primary'
                              />
                            }
                            label='Active?'
                          />
                        )}
                        <div className='font-bold text-11 mx-4 my-12 w-full text-right'>
                          {suggestions.length > 250 ? '250 of ' : ''}
                          {suggestions.length} Results
                        </div>
                      </div>
                    </Paper>
                  </div>
                ) : (
                  <div
                    className={classes.container}
                    onMouseDown={event => {
                      event.preventDefault()
                    }}
                  >
                    <Paper
                      className={classNames(
                        classes.suggestionsContainerOpen,
                        'w-full autocomplete-focus',
                      )}
                      square
                    >
                      {activeOnly && this.props.filter && (
                        <div className='w-full flex px-12 py-4'>
                          <FormControlLabel
                            // style={{ float: 'right', }}
                            control={
                              <Switch
                                checked={!Boolean(filter)}
                                name='showAll'
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    filter: !filter ? this.props.filter : null,
                                  })
                                }}
                                color='primary'
                              />
                            }
                            label='Show All'
                          />
                        </div>
                      )}
                      {!activeOnly && hasActive && (
                        <div className='w-full flex px-12 py-4'>
                          <FormControlLabel
                            // style={{ float: 'right', }}
                            control={
                              <Switch
                                checked={activeYN}
                                name='showActive'
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    activeYN: !activeYN,
                                  })
                                }}
                                color='primary'
                              />
                            }
                            label='Active?'
                          />
                        </div>
                      )}
                    </Paper>
                  </div>
                )}
              </Popper>
            )}
            <TextField
              inputRef={node => {
                this.anchorEl = node
              }}
              onFocus={this.handleFocus}
              // className={className}
              onBlur={this.handleBlur}
              label={title}
              value={search || ''}
              onChange={this.handleSearchInput}
              onKeyUp={this.handleSelectedIndex}
              InputProps={InputProps}
              inputProps={inputProps}
              InputLabelProps={{
                disableUnderline,
              }}
              fullWidth
              autoFocus
              margin={this.props.margin}
              variant={disableUnderline ? undefined : 'outlined'}
              required={required}
              error={error}
              disabled={this.props.disabled}
              helperText={helperText}
              style={hasFocus ? focusStyle || {} : {}}
              {...(moreProps || {})}
            />
          </div>
        ) : (
          <TextField
            onFocus={this.handleFocus}
            className={className}
            SelectProps={selectProps}
            classes={{ root: classes.select }}
            label={title}
            value={value}
            InputProps={InputProps}
            inputProps={inputProps}
            InputLabelProps={
              InputLabelProps || {
                shrink: value ? true : false,
                disableUnderline,
              }
            }
            fullWidth
            variant={disableUnderline ? undefined : 'outlined'}
            margin={this.props.margin}
            select
            required={required}
            error={error}
            helperText={helperText}
            disabled={this.props.disabled}
            {...(moreProps || {})}
          >
            {options &&
              options.map((option, index) => {
                if (selectedItemComponent) {
                  return selectedItemComponent(option)
                } else {
                  return menuItemComponent(option)
                }
              })}
          </TextField>
        )}
      </div>
    )
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps({ customersApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Autocomplete),
)
