import { FusePageCarded } from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import PaymentsHeader from 'main/content/apps/payments/PaymentsHeader';
import PaymentsLeftSidebarHeader from 'main/content/apps/payments/PaymentsLeftSidebarHeader';
import PaymentList from 'main/content/apps/payments/PaymentsList';
import PaymentSidebarContent from 'main/content/apps/payments/PaymentsSidebarContent';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DepositsList from './DepositsList';
import DepositTransactions from './DepositTransactions';
import PaymentDetailSidebar from './PaymentDetailSidebar';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class PaymentsApp extends Component {

    componentDidMount() {
        const { Co } = this.props;
        if (Co) {
            this.props.getPayments(Co);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co } = this.props;
        if (Co && Co !== prevProps.Co) {
            this.props.getPayments(Co);
        }
    }

    render() {
        const { classes, openNewPaymentDialog, filters, deposit, depositTransactions } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full relative",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            onClick={() => this.props.setTransaction(null)}
                            header={
                                <PaymentsHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                deposit || depositTransactions ?
                                    <DepositTransactions /> :
                                    filters.Type === 'deposit' ?
                                        <DepositsList /> :
                                        <PaymentList />
                            }
                            leftSidebarHeader={
                                <PaymentsLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <PaymentSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <PaymentDetailSidebar transaction={this.props.transaction} />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPayments: Actions.getPayments,
        setTransaction: Actions.setTransaction
    }, dispatch);
}

function mapStateToProps({ spReducers, paymentsApp }) {
    return {
        Co: spReducers.companies.Co,
        transaction: paymentsApp.payments.transaction,
        filters: paymentsApp.payments.filters,
        deposit: paymentsApp.payments.deposit,
        depositTransactions: paymentsApp.payments.depositTransactions
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsApp)));
