import axios from 'axios/index';

export const SET_INVENTORY_USER_TRANSFER_DATA = '[SPCONNECTION] SET INVENTORY USER TRANSFER DATA';
export const GET_INVENTORY_USER_TRANSFER_DATA = '[SPCONNECTION] GET INVENTORY USER TRANSFER DATA';

export function getInitialInventorUserTransferData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryTransfer?Co=${encodeURIComponent(Co)}&Page=1&PageSize=100`);

    request.then((response) => {
        return response.data.Results;
    });
}

export function getInventoryUserTransferData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/InventoryTransfer?Co=${encodeURIComponent(Co)}&Page=1&PageSize=100`);

    return (dispatch) => {
        request.then((response) => {
            window["warn"]("getInventoryManagerLocationData", response)
            dispatch({
                type: GET_INVENTORY_USER_TRANSFER_DATA,
                payload: response.data.Results
            })
        });
    }
}

export function setInventoryUserTransferData(data) {
    return {
        type: SET_INVENTORY_USER_TRANSFER_DATA,
        payload: data
    };
}