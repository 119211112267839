import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Badge, Button, Checkbox, Fab, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Parser } from 'json2csv';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { getTimecardData } from '../../../../store/actions/spConnection/timecards.actions';
import { updateTableSettings } from '../users/store/actions';
import * as Actions from './store/actions';
import TimecardPDFDialog from '../timecards/TimecardPDFDialog';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Records Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 256px)',
        paddingBottom: 0,
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    printButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 50,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    badge: {
        top: 4,
        minWidth: 16,
        fontSize: 10,
        width: 'auto',
        padding: 4,
        height: 16
    }

});

const initialState = {
    loading: false,
    timecardData: [],
    selectedTimecardIds: [],
    runFetchTimecards: false
}

class TimecardsList extends Component {

    state = {
        ...initialState
    };

    componentDidMount() {
        const { loading, timecardData, selectedTimecardIds, selectedTimecardData } = this.props;
        this.setState({ ...this.state, timecardData, loading, selectedTimecardIds, selectedTimecardData });
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, timecardData, selectedTimecardData, selectedTimecardIds } = this.props;
        window["warn"](timecardData, prevProps.timecardData);
        if (loading !== prevProps.loading) {
            this.setState({ ...this.state, loading });
        };
        if (!_.isEqual(timecardData, prevProps.timecardData) || !_.isEqual(selectedTimecardData, prevProps.selectedTimecardData) || !_.isEqual(selectedTimecardIds, prevProps.selectedTimecardIds)) {
            window["warn"]('New Timecard Data: ', timecardData);
            this.setState({ ...this.state, timecardData, loading: false, selectedTimecardIds, selectedTimecardData });
        }

    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTimecardMenu = (event) => {
        this.setState({ selectedTimecardsMenu: event.currentTarget });
    };

    closeSelectedTimecardsMenu = () => {
        this.setState({ selectedTimecardsMenu: null });
    };

    handleRefresh = () => {
        const { Co } = this.props;
        this.setState({ ...this.state, loading: true }, this.props.getTimecardData(Co));
    }

    handleRowCount = (count) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'timecards' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'timecards',
                Preferences: {
                    RowCount: count
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.RowCount = count;
        }
        this.props.updateTableSettings(prefs);
    }

    handleSortOrder = (sort) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'timecards' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'timecards',
                Preferences: {
                    SortOrder: sort
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.SortOrder = sort;
        }
        this.props.updateTableSettings(prefs);
    }

    export = () => {
        const { selectedTimecardData } = this.props;
        const data = _.cloneDeepWith(!selectedTimecardData ? [] : selectedTimecardData);
        data.map((value) => {
            value.Hours = value.Hours || ((value.Data && value.Data.Labor) ? _.sumBy(value.Data.Labor, 'Qty') : 0);
            delete value.Signature;
            delete value.Data;
        });
        const csvData = new Parser();
        const Data = csvData.parse(data);
        var encoded = encodeURIComponent(Data);
        var csv = `data:text/csv;charset=utf-8, ${encoded}`;
        var link = document.createElement("a");
        link.setAttribute("href", csv);
        link.setAttribute("download", "Timecards.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    selectAllTimecards = (data) => {
        const selected = [];
        data.map((value) => {
            if (!Boolean(value.ApprovedYN) && Boolean(value.SubmittedYN))
                selected.push(value.ID);
        });
        this.props.selectAllTimecards(selected);
    }

    openMenu = event => {
        event.stopPropagation();
        this.setState({ ...this.state, anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null });
    };

    rejectTimecards = () => {
        const { searchText } = this.props;
        const { timecardData, selectedTimecardIds, loading } = this.state;
        const data = this.props.selectedTimecardData ? this.props.selectedTimecardData : this.getFilteredArray(loading ? [] : timecardData, searchText);
        let timecards = [];
        selectedTimecardIds.map(ID => {
            var timecard = _.find(data, { ID });
            if (timecard) {
                timecards.push({ ...timecard, ApprovedYN: 'N' });
            }
        })
        this.props.updateTimecards(timecards);
        this.props.deSelectAllTimecards()
        this.closeMenu();
    }

    approveTimecards = () => {
        const { searchText } = this.props;
        const { timecardData, selectedTimecardIds, loading } = this.state;
        const data = this.props.selectedTimecardData ? this.props.selectedTimecardData : this.getFilteredArray(loading ? [] : timecardData, searchText);
        let timecards = [];
        selectedTimecardIds.map(ID => {
            var timecard = _.find(data, { ID });
            if (timecard) {
                timecards.push({ ...timecard, ApprovedYN: 'Y' });
            }
        })

        this.props.updateTimecards(timecards);
        this.props.deSelectAllTimecards()
        this.closeMenu();
    }

    resetRunFetch = () => this.setState({ ...this.state, runFetchTimecards: false });

    render() {
        const { classes, timecards, lines, user, status, searchText, openNewTimecardDialog, openEditTimecardDialog, employees, loading2, companies, Co } = this.props;
        const { TableSettings } = user.Data;
        const prefs = _.find(TableSettings, { Table: 'timecards' });
        const { loading, timecardData, anchorEl, selectedTimecardIds, selectedTimecardData } = this.state;
        let recs = selectedTimecardData ? selectedTimecardData : timecardData;
        switch (status) {
            case 0:
                {
                    recs = _.filter(recs, (o) => !o.SubmittedYN && !o.ApprovedYN);
                }
                break;
            case 1:
                {
                    recs = _.filter(recs, (o) => o.SubmittedYN === 'Y' && !o.ApprovedYN);
                }
                break;
            case 2:
                {
                    recs = _.filter(recs, (o) => o.ApprovedYN === 'Y');
                }
                break;
            case 3:
                {
                    recs = _.filter(recs, (o) => o.ApprovedYN === 'N');
                }
                break;
        }
        const data = this.getFilteredArray(loading ? [] : recs, searchText);
        const co = _.find(companies, { Co });
        const avatar = 'assets/images/avatars/profile.jpg';
        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Menu id="timecards-menu" classes={{ paper: "min-w-256" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                        <MenuItem onClick={this.approveTimecards}><Icon className="mr-8">check_circle_outline</Icon>Approve Selected</MenuItem>
                        <MenuItem onClick={this.rejectTimecards}><Icon className="mr-8">cancel</Icon>Reject Selected</MenuItem>
                    </Menu>
                    {status !== undefined &&
                        <Fab
                            color="primary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={this.export}
                        // onClick={openNewUserDialog}
                        >
                            <Icon className="text-16">save_alt</Icon>
                        </Fab>
                    }
                    {timecards !== undefined &&
                        <Fab
                        color="primary"
                        aria-label="add"
                        className={classNames(classes.printButton, "ml-12 pt-0 pb-0 pin-r absolute text-12")}
                        onClick={() => {
                            this.setState({...this.state, runFetchTimecards: true})
                        }}
                    >
                        <Icon className="text-16">print</Icon>
                    </Fab>
                    }
                    <TimecardPDFDialog runFetch={this.state.runFetchTimecards} resetRunFetch={this.resetRunFetch.bind(this)} />
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">access_time</Icon>
                        <span>Timecard List</span>
                        {
                            selectedTimecardIds.length > 0 &&
                            <Button
                                variant="contained"
                                className="ml-12 pt-0 pb-0"
                                color="primary"
                                onClick={this.openMenu}
                            >
                                Actions
                            </Button>
                        }
                        {!this.props.selectedTimecardData && <Icon color="action" onClick={this.handleRefresh} className={!loading ? "text-24 ml-8 mt-4 cursor-pointer" : "text-24 ml-8 mt-4 cursor-pointer spin"}>refresh</Icon>}
                    </Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        NoDataComponent={(loading || loading2) ? LoadingDiv : NoData}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTimecardDialog({ ...rowInfo.original }, true);
                                    }
                                }
                            }
                        }}
                        getTfootTrProps={() => {
                            return {
                                className: "font-bold text-center",
                            }
                        }}
                        data={[...data]}
                        columns={[
                            {
                                Header: () => (
                                    <Checkbox
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                        className="p-0"
                                        onChange={(event) => {
                                            event.target.checked ? this.selectAllTimecards(data) : this.props.deSelectAllTimecards();
                                        }}
                                        checked={selectedTimecardIds.length === _.filter(data, (o) => !Boolean(o.ApprovedYN) && Boolean(o.SubmittedYN)).length && selectedTimecardIds.length > 0}
                                        indeterminate={selectedTimecardIds.length !== _.filter(data, (o) => !Boolean(o.ApprovedYN) && Boolean(o.SubmittedYN)).length && selectedTimecardIds.length > 0}
                                    />
                                ),
                                fixed: "left",
                                accessor: "",
                                Cell: row => {
                                    return (<Checkbox
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                        className="p-0"
                                        checked={selectedTimecardIds.includes(row.value.ID)}
                                        disabled={Boolean(row.value.ApprovedYN) || !Boolean(row.value.SubmittedYN)}
                                        onChange={() => this.props.toggleInSelectedTimecards(row.value.ID)}
                                    />
                                    )
                                },
                                className: "justify-center p-0",
                                sortable: false,
                                width: 48,
                                // show: !Boolean(this.props.selectedTimecardData)
                            },
                            {
                                Header: "",
                                accessor: "Data",
                                width: 48,
                                className: "justify-center",
                                show: co && co.Data && co.Data.Co && co.Data.Co.IntegrationType === 'V',
                                Cell: row => {
                                    const { Status, Data, ApprovedYN } = row.original;
                                    if (ApprovedYN === "Y") {
                                        if (Data && Data.Labor) {
                                            const hours = _.sumBy(Data.Labor, 'Qty');
                                            const posted = _.sumBy(Data.Labor, (o) => { return (o.Data && (o.Data.VPPRTB || o.Data.VPPRTH)) ? (o.Data.VPPRTB || o.Data.VPPRTH).Hours : 0 });
                                            return (
                                                <Badge classes={{ badge: classes.badge }} badgeContent={posted} color={hours === posted ? "primary" : "error"}>
                                                    <Icon color={hours === posted ? "primary" : "error"}>{hours === posted ? "cloud_upload" : "error_outline"}</Icon>
                                                </Badge>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment>
                                                    <Icon color="error">error_outline</Icon>
                                                </React.Fragment>
                                            )
                                        }
                                    }
                                    // if (Data && (Data.VPPRTB || Data.VPPRTH)) {
                                    //     return (
                                    //         <React.Fragment>
                                    //             <Icon>{Data.ApprovedYN === "Y" ? "cloud_upload" : "cloud_done"}</Icon>
                                    //         </React.Fragment>
                                    //     )
                                    // } else {
                                    //     if (Status > 0) {
                                    //         return (
                                    //             <React.Fragment>
                                    //                 <Icon color="error">error_outline</Icon>
                                    //             </React.Fragment>
                                    //         )
                                    //     }
                                    // }
                                }
                            },
                            {
                                Header: "Employee",
                                accessor: "Employee",
                                //filterable: true,
                                className: "font-bold justify-center",
                                width: 128,
                                Footer: "Total",
                            },
                            {
                                Header: "Date",
                                accessor: "Date",
                                className: "justify-center",
                                width: 128,
                                Cell: row => (
                                    row.original.Date &&
                                    new Date(row.original.Date).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Name",
                                id: "name",
                                className: "justify-center",
                                width: 256,
                                accessor: row => {
                                    const { Employee } = row;
                                    const emp = _.find(employees, { Employee });
                                    if (emp) {
                                        return `${emp.FirstName} ${emp.LastName}`;
                                    }
                                },
                                Cell: row => {
                                    const { Employee } = row.original;
                                    const emp = _.find(employees, { Employee });
                                    return emp && (
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={emp.Employee} src={(emp.Data && emp.Data.Avatar ? getProfileImage(`Co=${emp.Co}&ID=${emp.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${emp.FirstName} ${emp.LastName}`}
                                        </React.Fragment>
                                    );
                                }
                            },
                            {
                                Header: "Hours",
                                id: "hours",
                                accessor: row => {
                                    return parseFloat(row.Hours || ((row.Data && row.Data.Labor) ? _.sumBy(row.Data.Labor, 'Qty') : 0).toFixed(2))
                                },
                                className: "justify-center",
                                width: 96,
                                Cell: row => parseFloat(row.original.Hours || ((row.original.Data && row.original.Data.Labor) ? _.sumBy(row.original.Data.Labor, 'Qty') : 0).toFixed(2)),
                                Footer: _.sumBy(data, (d) => { return d.Hours || ((d.Data && d.Data.Labor) ? _.sumBy(d.Data.Labor, 'Qty') : 0) })
                            },
                            {
                                Header: "Submitted",
                                accessor: "SubmittedDate",
                                className: "justify-center",
                                width: 128,
                                Cell: row => (
                                    row.original.SubmittedDate &&
                                    new Date(row.original.SubmittedDate).toLocaleDateString('en-US')
                                ),
                            },
                            {
                                Header: "Approved",
                                accessor: "ApprovedYN",
                                className: "justify-center",
                                width: 96,
                                Cell: row => {
                                    if (row.original.ApprovedYN) {
                                        return <Icon color={row.original.ApprovedYN === "Y" ? "secondary" : "error"}>{row.original.ApprovedYN === "Y" ? "check_circle_outline" : "cancel"}</Icon>
                                    }
                                }
                                //filterable: true,
                            },
                        ]}
                        defaultSorted={prefs ? (prefs.Preferences.SortOrder || []) : []}
                        defaultPageSize={prefs ? (prefs.Preferences.RowCount || 100) : 100}
                        onPageSizeChange={this.handleRowCount}
                        onSortedChange={this.handleSortOrder}
                        noDataText="No Timecards found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTimecards: Actions.getTimecards,
        getUserData: Actions.getUserData,
        openEditTimecardDialog: Actions.openEditTimecardDialog,
        removeTimecard: Actions.removeTimecard,
        toggleInSelectedTimecards: Actions.toggleInSelectedTimecards,
        selectAllTimecards: Actions.selectAllTimecards,
        deSelectAllTimecards: Actions.deSelectAllTimecards,
        updateTimecards: Actions.updateTimecards,
        getTimecardData,
        updateTableSettings,
    }, dispatch);
}

function mapStateToProps({ spReducers, timecardsApp }) {
    return {
        Co: spReducers.companies.Co,
        companies: spReducers.companies.List,
        timecards: timecardsApp.timecards.entities,
        selectedTimecardIds: timecardsApp.timecards.selectedTimecardIds,
        searchText: timecardsApp.timecards.searchText,
        user: spReducers.userProfiles.User,
        timecardData: spReducers.timecards.timecards,
        loading: spReducers.timecards.loading,
        employees: spReducers.employees,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TimecardsList)));
