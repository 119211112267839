import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import PurchaseOrderList from 'main/content/apps/purchase-orders/PurchaseOrdersList';
import PurchaseOrderHeader from 'main/content/apps/purchase-orders/PurchaseOrdersHeader';
import PurchaseOrderSidebarContent from 'main/content/apps/purchase-orders/PurchaseOrdersSidebarContent';
import PurchaseOrdersLeftSidebarHeader from 'main/content/apps/purchase-orders/PurchaseOrdersLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import PurchaseOrderDialog from 'main/content/apps/purchase-orders/PurchaseOrderDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class PurchaseOrdersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getPurchaseOrders(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewPurchaseOrderDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <PurchaseOrderHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <PurchaseOrderList />
                            }
                            leftSidebarHeader={
                                <PurchaseOrdersLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <PurchaseOrderSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        {/* <PurchaseOrderDialog /> */}
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPurchaseOrders: Actions.getPurchaseOrders,
        getUserData: Actions.getUserData,
        openNewPurchaseOrderDialog: Actions.openNewPurchaseOrderDialog
    }, dispatch);
}

function mapStateToProps({ purchaseOrdersApp }) {
    return {
        purchaseOrders: purchaseOrdersApp.purchaseOrders.entities,
        selectedPurchaseOrderIds: purchaseOrdersApp.purchaseOrders.selectedPurchaseOrderIds,
        searchText: purchaseOrdersApp.purchaseOrders.searchText,
        user: purchaseOrdersApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersApp)));
