import {combineReducers} from 'redux';
import userRoles from './user-roles.reducer';
import user from './user.reducer';

const userRolesAppReducers = combineReducers({
    userRoles,
    user
});

export default userRolesAppReducers;
