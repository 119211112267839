import React, { Component } from 'react';
import { Paper, ClickAwayListener, Icon, IconButton, Input, InputAdornment, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from 'main/content/apps/scrumboard/store/actions';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import _ from '@lodash';

class BoardSearchBar extends Component {
    state = {
        renameForm: false,
        renameTitle: this.props.board.Name,
        searchInput: null
    };
    renameFormToggle = (state) => {
        this.setState({
            renameForm: state,
            renameTitle: this.props.board.Name
        })
    };

    onRenameTitleChange = (ev) => {
        this.setState({ renameTitle: ev.target.value })
    };

    renameTitleSubmit = (ev) => {
        ev.preventDefault();
        let { board } = this.props;
        if (!this.canSubmit()) {
            this.renameFormToggle(false);
            return;
        }
        this.setState({
            renameForm: false,
            renameTitle: this.state.renameTitle
        });
        this.props.renameBoard({ ...board, Name: this.state.renameTitle });
    };

    canSubmit = () => {
        return this.state.renameTitle !== '';
    };

    search = (e) => {
        this.setState({ searchInput: e.target.value }, () => this.setSearch({ target: { value: this.state.searchInput } }));
    }

    setSearch = _.debounce((e) => this.props.setSearchText(e), 350);

    render() {
        const { board, securables, username, setSearchText, searchText } = this.props;
        const { renameTitle, renameForm, searchInput } = this.state;
        const accessLevel = _.find(securables, { Securable: "project-tracking" });

        return (
            <div className="flex items-center min-w-0">
                <div className="flex flex-1">
                    <Paper className="flex items-center w-full h-36 sm:h-36 p-16 pl-4 md:pl-16 rounded-8 " elevation={1}>
                        <Icon color="action">search</Icon>

                        <Input
                            placeholder={`Search ${board.Name}`}
                            className="pl-16 mt-4"
                            disableUnderline
                            fullWidth
                            value={searchInput || ''}
                            margin="dense"
                            inputProps={{
                                'aria-label': 'Search'
                            }}
                            onChange={this.search}
                        />
                        {searchInput && searchInput.length > 0 && <Icon color="action" className="cursor-pointer" onClick={() => this.setState({ searchInput: '' }, () => this.setSearch({ target: { value: this.state.searchInput } }))}>cancel</Icon>}
                    </Paper>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        board: scrumboardApp.board,
        username: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
        searchText: scrumboardApp.boards.searchText
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardSearchBar));
