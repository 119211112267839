import React, { Component } from 'react';
import {
    Grow, Slide, TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, FormGroup, FormControlLabel, Switch, Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import classNames from 'classnames';
import _ from '@lodash';
import Media from 'react-media';
import BarcodeScanner from 'main/content/components/barcode-scanner/BarcodeScanner';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import MaterialDialog from '../materials/MaterialDialog';
import { openEditMaterialDialog } from '../materials/store/actions';
import axios from 'axios';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import DraggableDialog from '../draggable-dialog/DraggableDialog';
import getProfileImage from '../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
});
const newWorkOrderUsageState = {
    ID: null,
    Co: null,
    WorkOrder: null,
    Scope: 1,
    Line: null,
    Type: 2,
    Status: 'NEW',
    Qty: null,
    UOM: '',
    Description: '',
    Technician: null,
    LaborType: null,
    Material: null,
    InventoryLocation: null,
    ItemID: null,
    CostType: null,
    UnitCost: 0.00,
    CostTotal: 0.00,
    CostDate: new Date(),
    UnitPrice: 0.00,
    PriceTotal: 0.00,
    TaxType: 'S',
    TaxCode: null,
    TaxRate: 0.00,
    TaxTotal: 0.00,
    TaxableYN: 'Y',
    NonBillableYN: "N",
    Invoice: null,
    InvoiceNumber: null,
    InvoiceDate: null,
    BatchMth: null,
    BatchID: null,
    BatchSeq: null,
    CreatedBy: null,
    CreatedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Notes: null,
    AttachmentID: null,
    UsageDate: new Date(),
    PO: null,
    POLine: null,
    AddOnYN: 'N',
    Data: {
        ErrMsg: null,
    },
    scanner: null,
    isScanning: false,
    Advanced: 'N',
    miscMarkupType: 2,
    searching: false,
    scanMessage: null,
    dialogHeight: null,
    minHeight: null,
    location: null
};

class WorkOrderUsageDialog extends Component {
    state = { ...newWorkOrderUsageState };

    ref = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newWorkOrderUsageState.Co = this.props.Co;
        if (!prevProps.workOrderUsageDialog.props.open && this.props.workOrderUsageDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.workOrderUsageDialog.type === 'edit' &&
                this.props.workOrderUsageDialog.data &&
                !_.isEqual(this.props.workOrderUsageDialog.data, prevState)) {
                this.setState({ ...this.props.workOrderUsageDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.workOrderUsageDialog.type === 'new') {
                let { businessUnits, taxCodes, user, Co } = this.props;
                const { Data } = this.props.workOrderUsageDialog.data;
                let workOrder = Data.WorkOrder, scope = Data.Scope;
                let usage = { ...newWorkOrderUsageState, ...this.props.workOrderUsageDialog.data };
                const { Companies } = user.Data;
                const co = _.find(Companies, { Co });
                const userTech = co.Data.Technician || {};
                if (workOrder && workOrder.Data && workOrder.Data.Scopes) {
                    const { Scopes } = workOrder.Data;
                    scope = Scopes[usage.Scope - 1];
                    if (scope) {
                        let { TaxCode, LeadTechnician, } = scope;
                        if (!TaxCode) {
                            const { BusinessUnit } = scope;
                            if (businessUnits) {
                                for (var i = 0; i < businessUnits.length; i++) {
                                    if (businessUnits[i].BusinessUnit === BusinessUnit) {
                                        let { TaxCode } = businessUnits[i];
                                        if (taxCodes) {
                                            for (var t = 0; t < taxCodes.length; t++) {
                                                if (taxCodes[t].TaxCode === TaxCode) {
                                                    let TaxRate = ((new Date(taxCodes[t].EffectiveDate) <= new Date() ? taxCodes[t].NewRate : taxCodes[t].OldRate)).toFixed(6);
                                                    usage = {
                                                        ...usage,
                                                        TaxCode,
                                                        TaxRate,
                                                        Technician: LeadTechnician || userTech.Technician,
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if (taxCodes) {
                                for (var t = 0; t < taxCodes.length; t++) {
                                    if (taxCodes[t].TaxCode === TaxCode) {
                                        let TaxRate = ((new Date(taxCodes[t].EffectiveDate) <= new Date() ? taxCodes[t].NewRate : taxCodes[t].OldRate)).toFixed(6);
                                        usage = {
                                            ...usage,
                                            TaxCode,
                                            TaxRate,
                                            Technician: LeadTechnician || userTech.Technician,
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (scope) {
                        let { TaxCode, LeadTechnician } = scope;
                        if (!TaxCode) {
                            const { BusinessUnit } = scope;
                            if (businessUnits) {
                                for (var i = 0; i < businessUnits.length; i++) {
                                    if (businessUnits[i].BusinessUnit === BusinessUnit) {
                                        let { TaxCode } = businessUnits[i];
                                        if (taxCodes) {
                                            for (var t = 0; t < taxCodes.length; t++) {
                                                if (taxCodes[t].TaxCode === TaxCode) {
                                                    let TaxRate = ((new Date(taxCodes[t].EffectiveDate) <= new Date() ? taxCodes[t].NewRate : taxCodes[t].OldRate)).toFixed(6);
                                                    usage = {
                                                        ...usage,
                                                        TaxCode,
                                                        TaxRate,
                                                        Technician: LeadTechnician || userTech.Technician,
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if (taxCodes) {
                                for (var t = 0; t < taxCodes.length; t++) {
                                    if (taxCodes[t].TaxCode === TaxCode) {
                                        let TaxRate = ((new Date(taxCodes[t].EffectiveDate) <= new Date() ? taxCodes[t].NewRate : taxCodes[t].OldRate)).toFixed(6);
                                        usage = {
                                            ...usage,
                                            TaxCode,
                                            TaxRate,
                                            Technician: LeadTechnician || userTech.Technician,
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (!_.isEqual({ ...usage }, prevState)) {
                    this.setState({ ...usage });
                }
            }
        } else {
            if (this.props.workOrderUsageDialog.type === 'update' &&
                this.props.workOrderUsageDialog.data &&
                !_.isEqual(this.props.workOrderUsageDialog.data, prevState)) {
                this.props.workOrderUsageDialog.type = 'edit';
                this.setState({ ...this.props.workOrderUsageDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), this.handleTechLabor);
    };

    handleTypeChange = (event) => {
        let Type = event.target.value;
        this.setState({ ...this.state, Type, UOM: Type === 1 ? 'HR' : '' }, this.handleTechLabor);
    }

    closeComposeDialog = () => {
        this.props.workOrderUsageDialog.type === 'edit' ? this.props.closeEditWorkOrderUsageDialog() : this.props.closeNewWorkOrderUsageDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true})
    };

    closeDialogForSplitscreen = () => {
        this.props.workOrderUsageDialog.type === 'edit' ? this.props.closeEditWorkOrderUsageDialog() : this.props.closeNewWorkOrderUsageDialog();
    };

    canBeSubmitted = () => {
        const { Co, WorkOrder, Scope, Type, Qty, Description, UOM, Technician, PriceTotal } = this.state;
        return Co && WorkOrder && Scope && !isNaN(Type) && Qty && UOM && Description && Technician && PriceTotal;
    }

    handleDateChange = (event) => {
        let UsageDate = this.formatDate(event._d);
        let CostDate = this.formatDate(event._d);
        this.setState({ ...this.state, CostDate, UsageDate });
    };

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    formatUnitCost = () => {
        const { Qty, UnitPrice, PriceTotal, UnitCost, CostTotal } = this.state;
        this.setState({
            ...this.state,
            UnitCost: UnitCost ? parseFloat(UnitCost).toFixed(2) : null,
            CostTotal: Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null,
        }, this.handleMarkup);
    }

    formatCostTotal = () => {
        const { Qty, UnitPrice, PriceTotal, UnitCost, CostTotal } = this.state;
        this.setState({
            ...this.state,
            UnitCost: CostTotal && Qty ? parseFloat(CostTotal / Qty).toFixed(2) : null,
            CostTotal: CostTotal ? parseFloat(CostTotal).toFixed(2) : null,
        }, this.handleMarkup);
    }

    formatUnitPrice = () => {
        const { Qty, UnitPrice, TaxRate } = this.state;
        this.setState({
            ...this.state,
            UnitPrice: UnitPrice ? parseFloat(UnitPrice).toFixed(2) : null,
            PriceTotal: Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null,
            TaxTotal: TaxRate && UnitPrice ? (Number(TaxRate / 100) * (UnitPrice * Qty)).toFixed(2) : null
        });
    }

    formatPriceTotal = () => {
        const { Qty, UnitPrice, PriceTotal, UnitCost, CostTotal, TaxRate } = this.state;
        this.setState({
            ...this.state,
            UnitPrice: PriceTotal && Qty ? parseFloat(PriceTotal / Qty).toFixed(2) : null,
            PriceTotal: PriceTotal ? parseFloat(PriceTotal).toFixed(2) : null,
            TaxTotal: TaxRate && PriceTotal ? (Number(TaxRate / 100) * PriceTotal).toFixed(2) : null
        });
    }

    handleLaborChange = (event) => {
        const { laborTypes } = this.props;
        const { Qty, Scope, Data } = this.state;
        let workOrder = Data.WorkOrder, scope = Data.Scope;
        const scopes = workOrder && workOrder.Data ? workOrder.Data.Scopes : scope ? [scope] : [];
        let LaborType = event.target.value;
        if (scope || workOrder) {
            let { BusinessUnit, Division } = scope ? scope : scopes[Scope - 1];
            const laborType = _.find(laborTypes, { BusinessUnit, Division, LaborType });
            this.setState({ ...this.state, LaborType, Description: `${laborType.Description} - ${new Date(this.state.UsageDate).toLocaleDateString('en-US')}`, UnitCost: laborType.StandardUnitCost, CostTotal: Qty * laborType.StandardUnitCost }, this.handleTechLabor);
        }
    }

    formatCostAndPrice = () => {
        let { Qty, UnitPrice, UnitCost, TaxRate } = this.state;
        this.setState({
            ...this.state,
            UnitCost,
            CostTotal: Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null,
            UnitPrice,
            PriceTotal: Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null,
            TaxTotal: TaxRate && Qty && UnitPrice ? (Number(TaxRate / 100) * parseFloat(UnitPrice * Qty).toFixed(2)) : null
        }, this.handleMarkup);
    }

    handleMarkup = () => {
        const { workOrderUsageDialog, costTypes } = this.props;
        const { taxability } = workOrderUsageDialog;
        const usageTypes = ['O', 'L', 'M', 'M', 'E', 'O', 'O', 'O', 'O'];
        const ct = _.find(costTypes, { CostType: this.state.CostType });
        const type = ct ? ct.CostTypeCategory : usageTypes[this.state.Type] || 'O';
        const nonTax = _.find((taxability || []), { CostTypeCategory: type, TaxableYN: 'N' });
        let { Type, LaborType, Qty, UnitPrice, PriceTotal, UnitCost, CostTotal, TaxRate, Data, miscMarkupType } = this.state;
        let workOrder = Data.WorkOrder, scope = Data.Scope;
        const { Assignments } = Data;
        const scopes = workOrder && workOrder.Data ? workOrder.Data.Scopes : scope ? [scope] : [];
        const { Scope } = this.state;
        if (scope || workOrder) {
            let { RateTemplate } = scope ? scope.Data : scopes[Scope - 1].Data;
            if (RateTemplate) {
                switch (Type) {
                    case 0: {
                        if (!miscMarkupType || miscMarkupType === 0) {
                            if (UnitCost) {
                                UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MiscMarkup / 100))).toFixed(2);
                                CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            }
                        }
                        if (miscMarkupType === 2) {
                            if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                                // UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                                CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                    var bp = RateTemplate.Data.Materials[i];
                                    if (UnitCost >= bp.Breakpoint) {
                                        UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                                        // CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                        PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                    }
                                }
                            } else {
                                if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                                    // CostTotal = Qty && UnitCost ? (UnitCost * Qty).toFixed(2) : 0;
                                    PriceTotal = CostTotal * (1 + (RateTemplate.MaterialMarkup / 100));
                                    UnitPrice = (PriceTotal / Qty).toFixed(2);
                                    PriceTotal = PriceTotal.toFixed(2);
                                    for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                        var bp = RateTemplate.Data.Materials[i];
                                        if (CostTotal >= bp.Breakpoint) {
                                            // CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                            PriceTotal = (CostTotal * (1 + (bp.Markup / 100)));
                                            UnitPrice = (PriceTotal / Qty).toFixed(2);
                                            PriceTotal = PriceTotal.toFixed(2);
                                        }
                                    }
                                }
                            }
                        }
                    }
                        break;
                    case 1: {
                        UnitPrice = RateTemplate.LaborRate;
                        if (LaborType) {
                            for (var i = 0; i < RateTemplate.Data.Labor.length; i++) {
                                if (LaborType == RateTemplate.Data.Labor[i].LaborType) {
                                    UnitPrice = parseFloat(RateTemplate.Data.Labor[i].Rate).toFixed(2);
                                    PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                }
                            }
                        }
                    }
                        break;
                    case 2: {
                        if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                            // UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                            CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                            PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                var bp = RateTemplate.Data.Materials[i];
                                if (UnitCost >= bp.Breakpoint) {
                                    UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                                    // CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                    PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                }
                            }
                        } else {
                            if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                                // CostTotal = Qty && UnitCost ? (UnitCost * Qty).toFixed(2) : 0;
                                PriceTotal = CostTotal * (1 + (RateTemplate.MaterialMarkup / 100));
                                UnitPrice = (PriceTotal / Qty).toFixed(2);
                                PriceTotal = PriceTotal.toFixed(2);
                                for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                    var bp = RateTemplate.Data.Materials[i];
                                    if (CostTotal >= bp.Breakpoint) {
                                        // CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                        PriceTotal = (CostTotal * (1 + (bp.Markup / 100)));
                                        UnitPrice = (PriceTotal / Qty).toFixed(2);
                                        PriceTotal = PriceTotal.toFixed(2);
                                    }
                                }
                            }
                        }
                    }
                        break;
                    case 3: {

                    }
                        break;
                    case 4: {

                    }
                    case 5: {

                    }
                        break;
                }
            }
        }
        this.setState({
            ...this.state,
            UnitCost,
            CostTotal,
            UnitPrice,
            PriceTotal,
            TaxTotal: nonTax ? 0 : TaxRate && Qty && UnitPrice ? (Number(TaxRate / 100) * parseFloat(UnitPrice * Qty)).toFixed(2) : null,
            // TaxableYN: nonTax ? 'N' : this.state.TaxableYN
        });
    }

    handleDateChange = (event) => {
        let { UsageDate } = this.state;
        UsageDate = event._d.toLocaleDateString('en-US');
        this.setState({ ...this.state, UsageDate });
    };

    handleTechLabor = () => {
        // let { Technician, Type, UnitCost, CostTotal, Qty } = this.state;
        // const { technicians, employees } = this.props;
        // UnitCost = 0.00;
        // if (Type === 1 && Technician) {
        //     for (var t = 0; t < technicians.length; t++) {
        //         const tech = technicians[t];
        //         if (tech.Technician === Technician) {
        //             for (var e = 0; e < employees.length; e++) {
        //                 const emp = employees[e];
        //                 if (tech.Employee === emp.Employee) {
        //                     UnitCost = (emp.SalaryYN === 'Y' ?
        //                         emp.SalaryAmt / (emp.WeeklyHours ? emp.WeeklyHours : 40) :
        //                         emp.HourlyAmt).toFixed(2);
        //                     CostTotal = Qty ? Qty * UnitCost : 0.00;
        //                     this.setState({ ...this.state, UnitCost, CostTotal });
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    handleUpdateTaxCode = (event) => {
        const { taxCodes } = this.props;
        const { PriceTotal } = this.state;
        const TaxCode = event.target.value;
        for (var i = 0; i < taxCodes.length; i++) {
            if (taxCodes[i].TaxCode === TaxCode) {
                let TaxRate = ((new Date(taxCodes[i].EffectiveDate) <= new Date() ? taxCodes[i].NewRate : taxCodes[i].OldRate)).toFixed(6);
                let TaxTotal = (Number(TaxRate / 100) * PriceTotal).toFixed(2);
                this.setState({ ...this.state, TaxCode, TaxRate, TaxTotal });
            }
        }
    }

    handlePart = (part, qty) => {
        const { Material, UOM, Description, Cost, CostType } = part;
        this.setState({ ...this.state, Qty: qty ? qty : 1, Material, UOM, Description, UnitCost: Cost, CostType, TotalCost: Cost, isScanning: false, scanMessage: null, }, () => {
            this.formatUnitCost();
        })
    }

    getPart = (barcode) => {
        const { Co, Material, Qty } = this.state;
        const request = axios.get(`${window["apiLocation"]}/api/Material?Co=${encodeURIComponent(Co)}&Material=${encodeURIComponent(barcode ? barcode : Material)}`).then((response) => {
            if (response.data) {
                this.handlePart(response.data, Qty);
            } else {
                this.setState({ ...this.state, scanMessage: 'Part not found' });
            }
        });
    }

    handleMaterialSearch = () => {
        const { Co, Material, Data } = this.state;
        let workOrder = Data.WorkOrder;

        let value = 1;
        this.props.openEditMaterialDialog({ Co, Material, value, workOrder});
    }

    handleLocationChange = (e) => {
        const InventoryLocation = e.target.value;
        // const { inventoryLocations } = this.props;
        // const location = _.find(inventoryLocations, { Location: InventoryLocation });
        // if (location) {
        // const { Address1, Address2, City, State, Zip, Country } = location;
        this.setState({ ...this.state, InventoryLocation });
        // }
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e);
                    }}
                    className="dialog-header-icon"
                >
                    <Icon>close</Icon>
                </IconButton>
            </>
        )
    }

    ToolbarContents = () => {
        const { workOrderUsageDialog } = this.props;

        return (
            <Typography variant="subtitle1" color="inherit">
                {workOrderUsageDialog.type === 'new' ? 'New Usage Line' : `Usage Line #${this.state.Line}`}
            </Typography>
        )
    }

    render() {
        const { classes, securables, workOrderUsageDialog, uoms, addWorkOrderUsage, updateWorkOrderUsage, removeWorkOrderUsage, date, technicians, materials, inventoryLocations, types, costTypes, taxCodes, laborTypes, rateTemplates, inventoryManagerLocations } = this.props;
        const { taxability } = workOrderUsageDialog;
        const { readOnly } = workOrderUsageDialog.props;
        const usageTypes = ['O', 'L', 'M', 'M', 'E', 'O', 'O', 'O', 'O'];
        const ct = _.find(costTypes, { CostType: this.state.CostType });
        const type = ct ? ct.CostTypeCategory : usageTypes[this.state.Type] || 'O';
        const nonTax = _.find((taxability || []), { CostTypeCategory: type, TaxableYN: 'N' });
        let { Status, WorkOrderUsage, Scope, AddOnYN, NonBillableYN, Advanced, ID, Invoice, isScanning, Data, searching } = this.state;
        const { Assignments } = Data;
        let workOrder = Data.WorkOrder, scope = Data.Scope;
        const scopes = workOrder && workOrder.Data ? workOrder.Data.Scopes : scope ? [scope] : [];
        const scp = scope ? scope : workOrder && workOrder.Data.Scopes && workOrder.Data.Scopes.length > 0 ? workOrder.Data.Scopes[Scope - 1] : {};
        const accessLevel = _.find(securables, { Securable: "work-order-billing" });
        Status = scp && scp.PriceMthod === 'F' && AddOnYN !== 'Y' ? 'FLAT PRICE' : NonBillableYN === 'Y' ? 'NON-BILLABLE' : !Invoice ? 'NEW' : Status;

        if (this.state.Status !== Status) {
            this.setState({ ...this.state, Status });
        }

        const avatar = 'assets/images/avatars/profile.jpg';
        const matAvatar = 'assets/images/logos/SP_Cog_Gradient.png';

        const draggableSelectImg = 'assets/images/draggable-selections/work-order-usage-select.png';

        const dialogProps = {
            ...workOrderUsageDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "md",
            //hideBackdrop: true,
            disableEnforceFocus: true
        }

        let header = workOrderUsageDialog.type === 'new' ? 'New Usage Line' : `Usage Line #${this.state.Line}`;

        window["warn"]("wo usage dialog type", workOrder, scope, scopes)

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={SlideUp}
                        matches={matches}
                        dialogProps = {{...dialogProps}}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={header}
                        type="Work Order Usage"
                        picRef={draggableSelectImg}
                        icon="format_list_bulleted"
                        dialogState={this.state}
                        reopenDialogFxn={workOrderUsageDialog.type === 'new' ? this.props.openNewWorkOrderUsageDialog : this.props.openEditWorkOrderUsageDialog}
                        description={this.state.Description}
                        storeDialog={workOrderUsageDialog}
                        TypeState={workOrderUsageDialog.type === 'new' && workOrderUsageDialog.props.open === true ? true : this.state.Line}
                        screenOverlay={this.state.screenOverlay}
                        //posSet={this.state.posSet}
                        //initialWidthSet={initialWidthSet}
                        //minHeight={this.state.minHeight}
                        //location={this.state.location}
                        //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                        //dialogHeight={this.state.dialogHeight}
                    >
                        <BarcodeScanner open={isScanning} onScan={this.getPart} onClose={() => { this.setState({ ...this.state, isScanning: false, }) }} message={this.state.scanMessage} />

                        <DialogContent ref={this.ref} classes={{ root: "p-24" }}>
                            <div className="md:flex">
                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">format_list_bulleted</Icon>
                                </div>
                                <div className="md:flex w-full">
                                    <div className="md:flex w-full">
                                        <TextField
                                            className={classes.formControl}
                                            label="Scope"
                                            select
                                            id="scope"
                                            name="Scope"
                                            value={this.state.Scope}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: this.state.Scope ? true : false }}
                                            required
                                            fullWidth
                                            disabled={Boolean(scope) || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        >
                                            {(scopes && scopes.length > 0 ? scopes.map((value, index) => {
                                                if (!Assignments || Assignments && _.findIndex(Assignments, { Scope: value.Scope }) > -1)
                                                    return (
                                                        <MenuItem key={index} value={value.Scope}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar}>{value.Scope}</Avatar>
                                                                {`${value.Data.Service.Description || ''.substr(0, 30)}`}
                                                            </div>
                                                        </MenuItem>
                                                    )
                                            }) : scope ?
                                                <MenuItem value={scope.Scope}>
                                                    <div className="flex">
                                                        <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar}>{scope.Scope}</Avatar>
                                                        {`${scope.Data.Service.Description || ''.substr(0, 30)}`}
                                                    </div>
                                                </MenuItem> : '')}
                                        </TextField>
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Type"
                                            select
                                            id="type"
                                            name="Type"
                                            value={this.state.Type}
                                            onChange={this.handleTypeChange}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: this.state.Type || this.state.Type === 0 ? true : false }}
                                            required
                                            fullWidth
                                            disabled={Boolean(this.state.ID) || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        >
                                            {(types && types.length > 0 ? types.map((value, index) => (
                                                <MenuItem key={index} disabled={Boolean(value.UsageType > 4)} value={value.UsageType}>
                                                    {`${value.UsageType} - ${value.Name}`}
                                                </MenuItem>
                                            )) : '')}
                                        </TextField>
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="md:flex w-full">

                                        <Autocomplete
                                            className={classes.formControl}
                                            title="Technician"
                                            options={technicians}
                                            menuItemComponent={(value) => {
                                                return value.ActiveYN === "Y" &&
                                                    <MenuItem value={value.Technician}>
                                                        <div className="flex">
                                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                                            {`${value.FirstName} ${value.LastName}`}
                                                        </div>
                                                    </MenuItem>
                                            }}
                                            value={this.state.Technician}
                                            noflip={true}
                                            onSelect={(option) => this.setState({ ...this.state, Technician: option.Technician })}
                                            portal={true}
                                            required
                                        />
                                        {this.state.Type === 1 &&
                                            <React.Fragment>
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                                <DatePicker variant="inline" inputVariant="outlined"
                                                    className={classes.formControl}
                                                    label="Date"
                                                    id="usageDate"
                                                    format="MM/DD/YYYY"
                                                    value={this.state.UsageDate}
                                                    onChange={this.handleDateChange}
                                                    showTodayButton
                                                    autoOk
                                                    animateYearScrolling
                                                    required
                                                    fullWidth
                                                />
                                            </React.Fragment>
                                        }
                                        {this.state.Type === 2 &&
                                            <React.Fragment>
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Material Location"
                                                    id="inventoryLocation"
                                                    name="InventoryLocation"
                                                    value={this.state.InventoryLocation}
                                                    onChange={this.handleLocationChange}
                                                    InputLabelProps={{ shrink: this.state.InventoryLocation ? true : false }}
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={this.state.Status > 0}
                                                    select
                                                >
                                                    {inventoryManagerLocations && inventoryManagerLocations.map((value, index) => (
                                                        <MenuItem key={index} value={value.Location}>
                                                            <div className="flex">
                                                                {`${value.Description}`}
                                                            </div>
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </TextField>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>


                            {
                                Advanced === 'Y' &&
                                <div className="md:flex">
                                    <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                        <Icon color="action">today</Icon>
                                    </div>
                                    <div className="md:flex w-full">
                                        <DatePicker
                                            inputVariant="outlined"
                                            className={classes.formControl}
                                            label="Usage Date"
                                            id="usageDate"
                                            format="MM/DD/YYYY"
                                            ref="datePicker"
                                            value={this.state.UsageDate}
                                            onChange={this.handleDateChange}
                                            fullWidth
                                            disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        />
                                        {this.state.Type === 0 &&
                                            <React.Fragment>
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={classNames(classes.formControl)}
                                                    label="Markup Type"
                                                    select
                                                    id="miscMarkupType"
                                                    name="miscMarkupType"
                                                    value={this.state.miscMarkupType}
                                                    onChange={this.handleChange}
                                                    onBlur={this.formatCostAndPrice}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <MenuItem value={0}>
                                                        Misc Rate
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        Material Rates
                                                    </MenuItem>
                                                </TextField>
                                            </React.Fragment>
                                        }
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Cost Type"
                                            select
                                            id="costType"
                                            name="CostType"
                                            value={this.state.CostType}
                                            onChange={(e) => {
                                                this.setState({ ...this.state, CostType: e.target.value }, this.handleMarkup)
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: this.state.CostType ? true : false }}
                                            required
                                            fullWidth
                                            disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        >
                                            {(costTypes && costTypes.length > 0 ? costTypes.map((value, index) => (
                                                <MenuItem key={index} value={value.CostType}>
                                                    {value.CostType} - {value.Name}
                                                </MenuItem>
                                            )) : '')}
                                        </TextField>
                                    </div>
                                </div>
                            }

                            {/* <MaterialDialog /> */}
                            <div className="md:flex">
                                {(this.state.Type !== 2 || (this.state.Type === 2 && !this.state.Material || this.state.Material === '')) &&
                                    <div onClick={() => { if (this.state.Type === 2) { this.setState({ ...this.state, isScanning: true }); } }} className="min-w-48 pb-12 md:pb-4  p-0 md:pt-20 cursor-pointer">
                                        <Icon color={this.state.Type === 2 ? "error" : "action"}>crop_free</Icon>
                                    </div>
                                }
                                {
                                    (this.state.Type === 2 && this.state.Material && this.state.Material !== '') &&
                                    <div onClick={this.handleMaterialSearch} className="min-w-48 pb-12 md:pb-4  p-0 md:pt-20 cursor-pointer">
                                        <Icon color={this.state.Type === 2 ? "error" : "action"}>search</Icon>
                                    </div>
                                }
                                <div className="md:flex w-full">
                                    <div className="md:flex md:w-1/2">
                                        {
                                            this.state.Type == 2 &&
                                            <React.Fragment>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Part #"
                                                    id="material"
                                                    name="Material"
                                                    value={this.state.Material || ''}
                                                    onChange={this.handleChange}
                                                    onBlur={() => this.getPart()}
                                                    InputLabelProps={{ shrink: this.state.Material ? true : false }}
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                                />
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.Type == 1 &&
                                            <React.Fragment>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Labor Type"
                                                    id="laborType"
                                                    name="LaborType"
                                                    value={this.state.LaborType}
                                                    onChange={this.handleLaborChange}
                                                    onBlur={this.formatCostAndPrice}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: this.state.LaborType ? true : false }}
                                                    select
                                                    disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                                >
                                                    {(scp.BusinessUnit && scp.Division && laborTypes && laborTypes.length > 0 ? _.filter(laborTypes, { Type: 'W', BusinessUnit: scp.BusinessUnit, Division: scp.Division }).map((value, index) => (
                                                        (value.BusinessUnit === scp.BusinessUnit && value.Division === scp.Division) &&
                                                        <MenuItem key={index} value={value.LaborType}>
                                                            {`${value.Name}`}
                                                        </MenuItem>
                                                    )) : '')}
                                                </TextField>
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                            </React.Fragment>
                                        }
                                        <TextField
                                            className={classes.formControl}
                                            label="Qty"
                                            id="qty"
                                            name="Qty"
                                            value={this.state.Qty}
                                            onChange={this.handleChange}
                                            onBlur={this.formatCostAndPrice}
                                            InputLabelProps={{ shrink: this.state.Qty ? true : false }}
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                            required
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="UOM"
                                            options={uoms}
                                            menuItemComponent={(value) => {
                                                return value.ActiveYN === "Y" &&
                                                    <MenuItem value={value.UOM}>
                                                        <div className="flex">
                                                            {`${value.UOM} - ${value.Description}`}
                                                        </div>
                                                    </MenuItem>
                                            }}
                                            value={this.state.UOM}
                                            noflip={true}
                                            onSelect={(option) => this.setState({ ...this.state, UOM: option.UOM })}
                                            required
                                            portal={true}
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="md:flex md:w-1/2">
                                        {this.state.Type === 2 && searching && this.state.Description && this.state.Description.length > 1 ?
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Description"
                                                options={materials}
                                                searchButton={true}
                                                menuItemComponent={(value) => {
                                                    return value.ActiveYN === "Y" &&
                                                        <MenuItem value={value.Material}>
                                                            <div className="flex">
                                                                <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Material} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : matAvatar)} />
                                                                {`${(value.Description || '').substr(0, 50)}`}
                                                            </div>
                                                        </MenuItem>
                                                }}
                                                debounce={750}
                                                onChange={(text) => this.setState({ ...this.state, Description: text })}
                                                onBlur={() => { if (!this.state.Material) { this.setState({ ...this.state, searching: false }) } }}
                                                search={this.state.Description}
                                                value={this.state.Material}
                                                onClick={(e) => e.stopPropagation()}
                                                portal={true}
                                                noflip={true}
                                                onSelect={(option) => this.setState({ ...this.state, Material: option.Material, Description: option.Description, Qty: this.state.Qty ? this.state.Qty : 1, UOM: option.UOM, UnitCost: option.Cost, searching: false }, () => this.formatCostAndPrice())}
                                                required
                                            /> :
                                            <form className="w-full" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); setTimeout(() => this.setState({ searching: true }), 300) }}>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Description"
                                                    id="description"
                                                    name="Description"
                                                    value={this.state.Description || ''}
                                                    onChange={this.handleChange}
                                                    onClick={(e) => e.stopPropagation()}
                                                    InputProps={this.state.Type === 2 ? {
                                                        endAdornment: !this.state.Material ?
                                                            <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: true, })} style={{ cursor: 'pointer' }}>search</Icon></InputAdornment>
                                                            :
                                                            <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: false, Description: null, Material: null, UnitCost: 0, CostTotal: 0, UnitPrice: 0, PriceTotal: 0 })} style={{ cursor: 'pointer' }}>close</Icon></InputAdornment>,
                                                    } : undefined}
                                                    InputLabelProps={{ shrink: this.state.Description ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    disabled={['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                                />
                                            </form>
                                        }
                                    </div>
                                    {
                                        (this.state.Type === 2 && this.state.Material && this.state.Material !== '') &&
                                        <>
                                            <div className="hidden md:block min-w-12 pt-20">
                                            </div>
                                            <div onClick={(e) => {e.stopPropagation(); this.handleMaterialSearch();}} className="min-w-48 pb-12 md:pb-4  p-0 md:pt-16 md:pl-12 cursor-pointer">
                                                <Tooltip title="Transfer Inventory" placement="top">
                                                    <Icon color={this.state.Type === 2 ? "error" : "action"}>gps_fixed</Icon>
                                                </Tooltip>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="md:flex">
                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">attach_money</Icon>
                                </div>
                                <div className="md:flex w-full">
                                    <div className="md:flex md:w-1/2">

                                        {
                                            Advanced === 'Y' &&
                                            <React.Fragment>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Unit Cost"
                                                    id="unitCost"
                                                    name="UnitCost"
                                                    value={this.state.UnitCost}
                                                    onChange={this.handleChange}
                                                    onBlur={this.formatUnitCost}
                                                    InputProps={{
                                                        startAdornment: this.state.UnitCost && <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number"
                                                    }}
                                                    InputLabelProps={{ shrink: this.state.UnitCost ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={['1'].indexOf(this.state.Type.toString()) > -1 || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                                />
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                            </React.Fragment>
                                        }
                                        <TextField
                                            className={classes.formControl}
                                            label="Total Cost"
                                            id="costTotal"
                                            name="CostTotal"
                                            value={this.state.CostTotal}
                                            onChange={this.handleChange}
                                            onBlur={this.formatCostTotal}
                                            InputProps={{
                                                startAdornment: this.state.CostTotal && <InputAdornment position="start">$</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            disabled={['1'].indexOf(this.state.Type.toString()) > -1 || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                            required
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                    </div>
                                    <div className="md:flex md:w-1/2">

                                        {
                                            Advanced === 'Y' &&
                                            <React.Fragment>
                                                <TextField
                                                    className={classNames(classes.formControl, (this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y')) && "line-through")}
                                                    label="Unit Price"
                                                    id="unitPrice"
                                                    name="UnitPrice"
                                                    value={this.state.UnitPrice}
                                                    onChange={this.handleChange}
                                                    onBlur={this.formatUnitPrice}
                                                    InputProps={{
                                                        startAdornment: this.state.UnitPrice && <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number"
                                                    }}
                                                    variant="outlined"
                                                    disabled={this.state.NonBillableYN === "Y" || (Status === 'FLAT PRICE' && AddOnYN !== 'Y') || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                                    fullWidth
                                                    required
                                                />
                                                <div className="hidden md:block min-w-12 pt-20">
                                                </div>
                                            </React.Fragment>
                                        }
                                        <TextField
                                            className={classNames(classes.formControl, (this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y')) && "line-through")}
                                            label="Total Price"
                                            id="priceTotal"
                                            name="PriceTotal"
                                            value={this.state.PriceTotal}
                                            onChange={this.handleChange}
                                            onBlur={this.formatPriceTotal}
                                            InputProps={{
                                                startAdornment: this.state.PriceTotal && <InputAdornment position="start">$</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            disabled={this.state.NonBillableYN === "Y" || (Status === 'FLAT PRICE' && AddOnYN !== 'Y') || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                            fullWidth
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                Advanced === 'Y' &&
                                <React.Fragment>
                                    <div className="w-full md:flex">
                                        <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                            <Icon color="action">format_list_numbered_rtl</Icon>
                                        </div>
                                        <TextField
                                            className={classNames(classes.formControl, (this.state.TaxableYN !== 'Y' || this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y')) && "line-through")}
                                            label="Tax Code"
                                            select
                                            id="taxCode"
                                            name="TaxCode"
                                            value={this.state.TaxCode}
                                            onChange={this.handleUpdateTaxCode}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: this.state.TaxCode ? true : false }}
                                            required
                                            fullWidth
                                            disabled={this.state.TaxableYN !== 'Y' || this.state.NonBillableYN === "Y" || (Status === 'FLAT PRICE' && AddOnYN !== 'Y') || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        >
                                            {(taxCodes && taxCodes.length > 0 ? taxCodes.map((value, index) => (
                                                <MenuItem key={index} value={value.TaxCode}>
                                                    {`${value.Description} - ${value.TaxCode}`}
                                                </MenuItem>
                                            )) : '')}
                                        </TextField>
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classNames(classes.formControl, (this.state.TaxableYN !== 'Y' || this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y')) && "line-through")}
                                            label="Tax Rate"
                                            id="taxRate"
                                            name="TaxRate"
                                            value={this.state.TaxRate}
                                            onChange={this.handleChange}
                                            InputProps={{
                                                endAdornment: this.state.TaxRate && <InputAdornment position="end">%</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={true}
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classNames(classes.formControl, (this.state.TaxableYN !== 'Y' || this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y')) && "line-through")}
                                            label="Tax Total"
                                            id="taxTotal"
                                            name="TaxTotal"
                                            value={nonTax ? 0 : this.state.TaxTotal}
                                            InputProps={{
                                                startAdornment: this.state.TaxTotal && <InputAdornment position="start">$</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                            required
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classNames(classes.formControl, (this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y')) && "line-through")}
                                            label="Taxable?"
                                            select
                                            id="taxableYN"
                                            name="TaxableYN"
                                            value={nonTax ? "N" : this.state.TaxableYN}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={nonTax || this.state.NonBillableYN === "Y" || Status === 'NON-BILLABLE' || (Status === 'FLAT PRICE' && AddOnYN !== 'Y') || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        >
                                            <MenuItem value="N">
                                                No
                                            </MenuItem>
                                            <MenuItem value="Y">
                                                Yes
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                    <div className="md:flex">
                                        <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                            <Icon color="action">note</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>
                                </React.Fragment>
                            }
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        style={{ float: 'left', }}
                                        control={
                                            <Switch
                                                checked={this.state.AddOnYN === 'Y'}
                                                name="AddOnYN"
                                                onChange={this.handleChange}
                                                color="secondary"
                                                disabled={!scp || (scp && scp.IsWarrantyYN !== "Y" && scp.PriceMethod && scp.PriceMethod !== 'F') || this.state.NonBillableYN === 'Y' || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                            />
                                        }
                                        label="Add-On Item?"
                                    />
                                </div>
                                <div className="w-1/2">
                                    <FormControlLabel
                                        style={{ float: 'right', }}
                                        control={
                                            <Switch
                                                checked={this.state.NonBillableYN === 'Y'}
                                                name="NonBillableYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                                disabled={this.state.AddOnYN === 'Y' || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                            />
                                        }
                                        label="Non-Billable?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {!readOnly && accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {workOrderUsageDialog.type === 'new' ? (
                                    <React.Fragment>
                                        <DebounceButton
                                            buttonText={"Add"}
                                            processingText={"Adding"}
                                            isDisabled={!this.canBeSubmitted()}
                                            variant={"contained"}
                                            color={"primary"}
                                            clickFxn={() => {
                                                addWorkOrderUsage(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            debounceInterval={1000}
                                        />
                                        <FormControlLabel
                                            style={{ float: 'right', }}
                                            control={
                                                <Switch
                                                    checked={this.state.Advanced === 'Y'}
                                                    name="Advanced"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Advanced"
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <DebounceButton
                                            buttonText={"Save"}
                                            processingText={"Saving"}
                                            isDisabled={!this.canBeSubmitted()}
                                            variant={"contained"}
                                            color={"primary"}
                                            clickFxn={() => {
                                                updateWorkOrderUsage(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            debounceInterval={1000}
                                        />
                                        <FormControlLabel
                                            style={{ float: 'right', }}
                                            control={
                                                <Switch
                                                    checked={this.state.Advanced === 'Y'}
                                                    name="Advanced"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Advanced"
                                        />
                                        <IconButton
                                            onClick={() => {
                                                removeWorkOrderUsage(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.state.ID || ['INVOICED', 'INVOICE PENDING'].indexOf(Status) > -1}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </DialogActions>
                        }
                    </DraggableDialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditWorkOrderUsageDialog: Actions.closeEditWorkOrderUsageDialog,
        closeNewWorkOrderUsageDialog: Actions.closeNewWorkOrderUsageDialog,
        addWorkOrderUsage: Actions.addWorkOrderUsage,
        updateWorkOrderUsage: Actions.updateWorkOrderUsage,
        removeWorkOrderUsage: Actions.removeWorkOrderUsage,
        openEditWorkOrderUsageDialog: Actions.openEditWorkOrderUsageDialog,
        openEditMaterialDialog,
        openNewWorkOrderUsageDialog: Actions.openNewWorkOrderUsageDialog,
    }, dispatch);
}

function mapStateToProps({ workOrderUsagesApp, workOrdersApp, workOrderScopesApp, spReducers }) {
    return {
        workOrderUsageDialog: workOrderUsagesApp.workOrderUsages.workOrderUsageDialog,
        // workOrder: workOrdersApp.workOrders.workOrderDialog.data,
        // scope: workOrderScopesApp.workOrderScopes.workOrderScopeDialog.data,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        types: spReducers.workOrderUsageTypes,
        technicians: spReducers.technicians,
        businessUnits: spReducers.businessUnits,
        laborTypes: spReducers.laborTypes,
        taxCodes: spReducers.taxCodes,
        costTypes: spReducers.costTypes,
        rateTemplates: spReducers.rateTemplates,
        inventoryLocations: spReducers.inventoryLocations,
        employees: spReducers.employees,
        materials: spReducers.materials.materials,
        securables: spReducers.userProfiles.User.Data.Securables,
        uoms: spReducers.uoms,
        inventoryManagerLocations: spReducers.inventoryManagerLocations,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WorkOrderUsageDialog));
