import {combineReducers} from 'redux';
import companies from './companies.reducer';
import user from './user.reducer';

const companiesAppReducers = combineReducers({
    companies,
    user
});

export default companiesAppReducers;
