import _ from '@lodash';
import { Button, Chip, Avatar, Icon, Table, TableCell, TableFooter, TableRow, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import parse from 'html-react-parser';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';



const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    bgGrey: {
        backgroundColor: '#3f3f3f',
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    savings: {
        top: 0,
        right: 0,
        position: 'absolute',
        width: 72,
        padding: 4,
        paddingTop: 6,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 'auto',
        borderBottomLeftRadius: 6,
        borderTopRightRadius: 6,
        lineHeight: '9px',
        textAlign: 'center',
        // marginTop: 44,
    },
    selectedSavings: {
        top: 24,
        right: 0,
        position: 'absolute',
        width: 72,
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: '#3f3f3f',
        color: '#fff',
        margin: 'auto',
        borderBottomLeftRadius: 6,
        lineHeight: '9px',
        textAlign: 'center',
        // marginTop: 44,
    },
    chip: {
        color: '#fff',
        backgroundColor: '#3f3f3f',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 0,
        marginBottom: 12,
        height: 24,
    },
    chipLabel: {
        paddingLeft: 8,
        fontWeight: 'bold',
        color: '#fff',
        paddingBottom: 2,
    },
    chipAvatar: {
        marginLeft: '0px !important',
        color: '#3f3f3f',
        backgroundColor: '#3f3f3f',
        border: '3px solid #3f3f3f',
        width: '32px !important',
        height: '32px !important',
    },
    chipAvatarIcon: {
        color: '#3f3f3f',
        backgroundColor: '#fff',
    },
    qty: {
        backgroundColor: '#3f3f3f',
        height: 18,
        minWidth: 18,
        fontSize: 13,
        paddingLeft: 4,
        paddingRight: 4,
        marginRight: 6,
        marginTop: 3,
        width: 24,
        float: 'left',
        borderRadius: 12,
        color: '#fff',
        textAlign: 'center'
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
    },
});

var newOptionState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Option: null,
    Name: null,
    Description: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    PriceTotal: 0.00,
    AttachmentID: null,
    SavingsType: "M",
    Data: {
        Components: [],
        ErrMsg: null,
    }

}

class OptionBoardDialog extends Component {

    state = { ...newOptionState };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidMount() {
        const { data } = this.props;

        this.setState({ ...this.state, ...data });
    }

    componentDidUpdate = (prevProps) => {
        const { data } = this.props;

        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ ...this.state, ...data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    selectOption = () => {
        this.props.onSelected(this.state);
    }

    editOption = (e) => {
        e.stopPropagation();
        this.props.onEdit(this.state);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    saveOption = () => {
        this.props.onChange(this.state);
    }

    render() {
        const { classes, selected, optionBoard, isMember, hideSavings } = this.props;
        const components = _.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y');
        var savingsType = (this.state.SavingsType || optionBoard.DefaultSavingsType || "M");
        const fullPrice = _.sumBy(components, (o) => o.Qty * (o.CustomPrice || o.Data.Assembly.Price));
        const highest = _.maxBy(components, (o) => (o.CustomPrice || o.Data.Assembly.Price));
        let addOnPrice = _.sumBy(components, (o) => { if (o.Component === highest.Component) { return (o.CustomPrice || o.Data.Assembly.Price) + ((o.Qty - 1) * (o.CustomPrice || o.Data.Assembly.AddOnPrice)) } else { return o.Qty * (o.CustomPrice || o.Data.Assembly.AddOnPrice) } });
        let memberPrice = _.sumBy(components, (o) => (o.Qty * (o.CustomMemberPrice || o.Data.Assembly.MemberPrice)));
        let price = (savingsType === 'M' && isMember ? memberPrice : savingsType === "A" ? addOnPrice : fullPrice);
        const savings = (fullPrice - price);
        const prc = this.formatDollars(price);
        window["warn"](`Option #${this.state.Option}`, this.state, isMember, price, fullPrice, memberPrice);
        return (
            <div className="mb-24">
                <div className="rounded-6 p-12 relative" style={{ border: '1px solid #f1f1f1' }}>
                    {selected &&
                        <div className={classNames("w-full text-center py-4 pin-t pin-l pin-r absolute rounded-t", classes.blackButton)}><span className={classNames("font-bold")}>Recommended</span></div>
                    }
                    <Chip
                        classes={{ root: selected ? "mt-40" : "mt-8", avatar: classes.chipAvatar, label: classes.chipLabel, }}
                        avatar={<Avatar src="assets/images/logos/SP_Cog_Gradient.png" />}
                        label={this.state.Name}
                        className={classNames(classes.chip, "w-auto min-w-128 justify-start")}
                    />
                    {!hideSavings &&
                        <div className={selected ? classes.selectedSavings : classes.savings} style={savingsType === "M" ? { width: 84 } : undefined}>
                            <span className="text-14 font-700">{this.formatDollars(savings).split('.')[0]}</span><br />
                            <span style={{ fontSize: 8 }}>{savingsType === "M" ? "Member " : ""}Savings</span>
                        </div>
                    }

                    <div><strong className="mr-4 mb-4">Description of Work:</strong><br />{this.state.Description}
                        {this.state.Data && this.state.Data.Components &&
                            <div className="w-full mt-8">
                                {
                                    //Basic Detail
                                    this.state.ComponentDetail === "B" &&
                                    <ul>
                                        {_.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y').map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, Qty, UOM } = value;
                                            const assembly = value.Data.Assembly;
                                            if (assembly) {
                                                return (
                                                    <li>{`${Qty} - ${assembly.Name}`}</li>
                                                );
                                            }
                                        })
                                        }
                                    </ul>
                                }
                                {
                                    //Basic Detail
                                    this.state.ComponentDetail === "S" &&
                                    <div>
                                        {_.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y').map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, Qty, UOM } = value;
                                            const assembly = value.Data.Assembly;
                                            if (assembly) {
                                                if (assembly.Data && assembly.Data.Images) {
                                                    assembly.Data.Image = _.find(assembly.Data.Images, { PrimaryYN: 'Y' });
                                                    if (!assembly.Data.Image && assembly.Data.Images.length > 0) {
                                                        assembly.Data.Image = assembly.Data.Images[0];
                                                    }
                                                }
                                                return (
                                                    <div className="prevent-split flex mt-8 pt-6" style={{ borderTop: '1px solid #f1f1f1' }}>
                                                        <div className="w-1/6 pt-6 pr-6">
                                                            <img alt={assembly.Code} crossOrigin="Anonymous" className="w-full component-img" src={assembly.Data.Image ? `${window["apiLocation"]}/api/AssemblyImage?ID=${assembly.Data.Image.ID}` : 'assets/images/logos/SP_Cog_Gradient.png'} />
                                                        </div>
                                                        <div className="w-full">
                                                            <Typography className="w-full font-bold text-16">
                                                                <div className={classNames(classes.qty)}>{value.Qty}</div>
                                                                {assembly.Name}
                                                            </Typography>
                                                            <Typography className="clear" variant="caption">{parse(assembly.Description)}</Typography>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                }
                                {
                                    //Basic Detail
                                    this.state.ComponentDetail === "F" &&
                                    <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                                        {_.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y').map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, Qty, UOM } = value;
                                            const assembly = value.Data.Assembly;
                                            if (assembly) {
                                                if (assembly.Data && assembly.Data.Images) {
                                                    assembly.Data.Image = _.find(assembly.Data.Images, { PrimaryYN: 'Y' });
                                                    if (!assembly.Data.Image && assembly.Data.Images.length > 0) {
                                                        assembly.Data.Image = assembly.Data.Images[0];
                                                    }
                                                }
                                                return (
                                                    <div className="prevent-split flex mt-8 pt-6" style={{ borderTop: '1px solid #f1f1f1' }}>
                                                        <div className="w-1/6 pt-6 pr-6">
                                                            <img alt={assembly.Code} crossOrigin="Anonymous" className="w-full component-img" src={assembly.Data.Image ? `${window["apiLocation"]}/api/AssemblyImage?ID=${assembly.Data.Image.ID}` : 'assets/images/logos/SP_Cog_Gradient.png'} />
                                                        </div>
                                                        <div className="w-full">
                                                            <Typography className="w-full font-bold text-16">
                                                                <div className={classNames(classes.qty)}>{value.Qty}</div>
                                                                {assembly.Name}
                                                                {assembly.Code &&
                                                                    <Typography color="secondary" className="font-bold text-12 mt-4 float-right">{assembly.Code}</Typography>
                                                                }
                                                            </Typography>
                                                            <Typography className="clear" variant="caption">{parse(assembly.Description)}</Typography>
                                                            <Typography className="font-bold text-12 my-12 relative">Qty: {value.Qty}
                                                                <Typography className="font-bold text-12 float-right">{this.formatDollars(value.Qty * assembly.Price)}</Typography>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                }
                                <div className="mt-12">
                                    <Table>
                                        <TableFooter className="mt-24">
                                            <TableRow className="h-24">
                                                <TableCell className="w-1/6 p-0 table-cell font-bold text-red text-16" style={{ borderBottom: 'none' }}>Subtotal</TableCell>
                                                <TableCell className="w-1/6 p-0 table-cell font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                <TableCell className="w-1/2 table-cell p-0 font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                <TableCell className="w-1/6 text-right p-0 table-cell font-bold text-red text-16" style={{ paddingRight: 0, borderBottom: 'none' }}>{prc}</TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>
                                {this.props.onSelected &&
                                    <Button
                                        variant="contained"
                                        className="w-full no-print"
                                        color="primary"
                                        onClick={() => this.props.onSelected(this.state)}
                                    >
                                        Select Option
                                    </Button>
                                }
                            </div>
                        }
                        {/* {
                            //Basic Detail
                            this.state.ComponentDetail === "C" &&
                            <ul className="prevent-split">
                                <EstimateFeatures estimate={{ ...this.state }} details={this.state.Data.Features} />
                            </ul>
                        } */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        impress,
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(OptionBoardDialog));
