import React, { Component } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardContent, CardActions, withStyles } from '@material-ui/core';
import BoardCard from './BoardCard';
import classNames from 'classnames';
import { darken } from '@material-ui/core/styles/colorManipulator';
import BoardAddCard from './BoardAddCard';
import BoardListHeader from './BoardListHeader';
import BoardCardRowHeader from './BoardCardRowHeader';
import _ from 'lodash';
import { FuseUtils } from '@fuse';
import moment from 'moment';

const styles = theme => ({
    root: {},
    list: {
        maxHeight: 'calc(100vh - 216px)',
        backgroundColor: darken(theme.palette.background.default, theme.palette.type === 'light' ? 0.02 : .4),
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut
    },
    listHeader: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.divider
    },
});

class BoardList extends Component {
    handleCardAdded = () => {
        this.contentScrollEl.scrollTop = this.contentScrollEl.scrollHeight;
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (!searchText || searchText.length === 0) {
            return arr;
        }
        var matches = FuseUtils.filterArrayByString(arr, searchText, ['Name', 'Description', 'TaskID']);
        function addParent(ID) {
            var parent = _.find(entities, { ID });
            window["warn"]('Match Parent Object: ', parent);
            if (parent) {
                matches.push(parent);
                if (parent.ParentID) {
                    addParent(parent.ParentID);
                }
            }
        }
        matches.map((match) => {
            if (match.ParentID) {
                window["warn"]('Match Parent: ', match.ParentID);
                addParent(match.ParentID);
            }
        })
        return matches
    };

    findChildren = (tasks, allTasks) => {
        tasks.map((task) => {
            if (task.ID) {
                task.children = this.findChildren(_.filter(allTasks, { ParentID: task.ID }), allTasks);
            }
        })
        return tasks;
    }

    findAssignedChildren = (task, matches) => {
        const { filters } = this.props;
        if (_.filter(task.Data.Members, (m) => filters.assignedTo.indexOf(m.UserName) > -1).length > 0) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findAssignedChildren(t, matches);
            });
        }
        return matches;
    }

    findAddedByChildren = (task, matches) => {
        const { filters } = this.props;
        if (filters.addedBy.indexOf(task.AddedBy) > -1) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findAddedByChildren(t, matches);
            });
        }
        return matches;
    }

    findDueByChildren = (task, matches) => {
        const { filters } = this.props;
        if (task.DueBy && moment(task.DueBy).toDate() <= moment(filters.dueBy).toDate()) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findDueByChildren(t, matches);
            });
        }
        return matches;
    }

    findTaggedChildren = (task, matches) => {
        const { filters } = this.props;
        if (_.filter(task.Data.Tags, (t) => filters.tags.indexOf(t.TagID) > -1).length > 0) {
            matches.push(task);
        }
        if (task.children) {
            task.children.map((t) => {
                matches = this.findTaggedChildren(t, matches);
            });
        }
        return matches;
    }

    filterCards = () => {
        const { filters, list, searchText, sortBy } = this.props;
        window["warn"]('All Tasks: ', this.props.cards);
        let filtered = this.getFilteredArray(this.findChildren(list.Data.Cards, this.props.cards) || [], searchText);
        window["warn"]('Filtered Cards: ', filtered)
        if (filters.assignedTo && filters.assignedTo.length > 0) {
            filtered = _.filter(filtered, (o) => this.findAssignedChildren(o, []).length > 0);
        }
        if (filters.addedBy && filters.addedBy.length > 0) {
            filtered = _.filter(filtered, (o) => this.findAddedByChildren(o, []).length > 0);
        }
        if (filters.tags && filters.tags.length > 0) {
            filtered = _.filter(filtered, (o) => this.findTaggedChildren(o, []).length > 0);
        }
        if (filters.dueBy) {
            filtered = _.filter(filtered, (o) => this.findDueByChildren(o, []).length > 0);
        }
        return _.orderBy(filtered, [sortBy.prop], [sortBy.order]);
    }

    render() {
        const { classes, list, index, username,view, boardData } = this.props;
        const filtered = this.filterCards();
        return (
            <Draggable draggableId={String(list.ID)} index={index} type="list">
                {(provided, snapshot) => (
                    <div
                        className={`${classes.listWrapper} ${view==="col" && "w-full mb-8"}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <Card
                            className={classNames(classes.list, "flex flex-col rounded-lg",view==="row" && "w-256 sm:w-320 mr-16 sm:mr-24")}
                            square={true}
                            elevation={snapshot.isDragging ? 3 : 1}
                        >

                            <BoardListHeader
                                username={username}
                                list={{ ...list, Data: { ...list.Data, Cards: filtered } }}
                                className={classNames(classes.listHeader)}
                                handleProps={provided.dragHandleProps}
                            />

                            <CardContent
                                ref={ref => this.contentScrollEl = ref}
                                className="flex flex-col flex-1 flex-auto h-full min-h-0 w-full p-0 overflow-auto"
                            >
                                <Droppable
                                    droppableId={String(list.ID)}
                                    type="card"
                                    direction="vertical"
                                    isCombineEnabled
                                >
                                    {(provided) => (
                                        <div ref={provided.innerRef} className="flex flex-col h-full p-16 sc-wrapper">
                                            {view === "col" && <BoardCardRowHeader />}
                                            {filtered.map((card, index) => (
                                                <BoardCard
                                                    key={card.ID}
                                                    card={card}
                                                    index={index}
                                                    list={list}
                                                    view={view}
                                                    boardData={boardData}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </CardContent>
                            {list.ID >= 0 &&
                                <CardActions className="p-0 flex-no-shrink">
                                    <BoardAddCard listId={list.ID} onCardAdded={this.handleCardAdded} />
                                </CardActions>
                            }


                        </Card>
                    </div>
                )}
            </Draggable>
        );
    }
}

export default withStyles(styles, { withTheme: true })(BoardList);
