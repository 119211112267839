import {combineReducers} from 'redux';
import divisions from './divisions.reducer';
import user from './user.reducer';

const divisionsAppReducers = combineReducers({
    divisions,
    user
});

export default divisionsAppReducers;
