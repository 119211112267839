import textGatherPhrasesApp from 'main/content/apps/text-gather-phrases/TextGatherPhrasesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const TextGatherPhrasesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/text-gather-phrases/:id',
            component: textGatherPhrasesApp
        },
        {
            path     : '/apps/text-gather-phrases',
            component: () => <Redirect to="/apps/text-gather-phrases/all"/>
        }
    ]
};
