import { FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { AppBar, Button, Card, Dialog, DialogActions, DialogContent, Icon, IconButton, MobileStepper, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { SPRoundIcon } from 'main/icons';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import Swipeable from "react-swipeable";
import { bindActionCreators } from 'redux';
import { getReleaseNotes, setAbout, getAbout, resetRelease, resetAllowVersionList } from 'store/actions';
import OfflineItemsDialog from 'main/content/components/error-handler/OfflineItemsDialog';

const styles = theme => ({
    root: {
        // maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    editButton: {
        float: 'right',
        minHeight: 0,
        marginRight: 8,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
    },
    saveButton: {
        float: 'right',
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        float: 'right',
        minHeight: 0,
        marginRight: 8,
        height: 32,
        minWidth: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    divs: {},
    formControl: {
        marginBottom: 24,
    },
    container: {
        // minHeight: 'calc(100vh - 164px)',
        // maxHeight: 'calc(100vh - 128px)',
        overflow: 'auto',
        position: 'relative',
        paddingBottom: 24,
        '::-webkit-scrollbar': {
            width: 0,
            height: 0
        },
    },
    buttonLabel: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    stepTitle: {
        color: theme.palette.primary.main,
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    max840: {
        maxWidth: 840
    },
    fontSm: {
        '& *': {
            fontSize: '10px',
        }
    },
    fontMd: {
        '& *': {
            fontSize: '13px',
        }
    },
    fontLg: {
        '& *': {
            fontSize: '16px',
        }
    },
    fontXl: {
        '& *': {
            fontSize: '20px',
        }
    },

});
const newAboutState = {
    step: 0,
    prevStep: null,
    viewShowcase: false,
    release: null,
    releaseNotes: null,
    viewOffline: false,
    fontSize: 1,
};

class AboutDialog extends Component {
    state = { ..._.cloneDeepWith(newAboutState) };

    componentDidUpdate = (prevProps, prevState) => {
        const { version } = this.props;
        const { aboutDialog } = version;
        const { release } = aboutDialog;
        const { open } = aboutDialog.props;
        if ((open && !prevProps.version.aboutDialog.props.open)) {
            this.setState({ step: 0, release });
        }
    }

    closeDialog = () => {
        this.setState({ step: 0, release: null }, () => this.props.setAbout(false));
    }
    closeDialogR = () => {
        this.setState({ step: 0, release: null });
        this.props.setAbout(false);
        this.props.resetRelease(null);
    }

    backToReleaseNotes = () => {
        this.setState({ step: 0, release: null });
        this.props.setAbout(false);
        this.props.resetRelease(null);
        this.props.resetAllowVersionList({allowVersionList: true})
    }

    handleNext = () => {
        const { releaseNotes } = this.props.version;
        const { steps } = JSON.parse(releaseNotes.Data);
        let { step } = this.state;
        if (step < steps.length - 1) {
            this.setState({ prevStep: step, step: step + 1 });
        }
    }

    handleBack = () => {
        let { step } = this.state;
        if (step > 0) {
            this.setState({ prevStep: step, step: step - 1 });
        }
    }

    render() {
        const { classes, securables, tagBuilderDialog, version, offline } = this.props;
        const { step, prevStep, viewShowcase, release, viewOffline, fontSize } = this.state;
        const { releaseNotes, about, aboutDialog, device } = version;
        const { open, allowVersionList } = aboutDialog.props;
        if (!about || !open) {
            return '';
        }
        const os = device && (device.osName || device.os) && device.osVersion ? `${device.osName || device.os} ${device.osVersion}` : 'N/A';
        const { steps } = releaseNotes && releaseNotes.Data ? JSON.parse(releaseNotes.Data) : {};
        const Step = steps ? steps[step] : null;
        const lastFiveRecords = version.about.Releases.slice(-5);
        const versionsList = lastFiveRecords.reverse();
        console.log("ABOUTTTT--",version);
        const toolbarOptions = [
            [{ size: ['small', false, 'large', 'huge'] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],

            ['link', 'image', 'video'],

            ['clean']                                       // remove formatting button
        ];

        const formats = [
            'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
            'list', 'bullet', 'indent', 'align',
            'link', 'image', 'video'
        ]

        const history = {
            delay: 2000,
            maxStack: 500,
            userOnly: true
        }
        const { DisplayIndex, Content, Showcase, Video } = Step || {};

        const instanceTypes = { "T": "Test / Training", "D": "Development", "P": "Production" };

        return (
            <Media query={"(min-width: 512px) and (min-height: 512px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: classNames(release && Step ? classes.max840 : '', matches ? classes.paper : "full-screen-dialog")
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        open={open}
                        onClose={this.closeDialog}
                        fullWidth
                        maxWidth={(!release || !Step) ? "sm" : undefined}
                    >
                        <OfflineItemsDialog title="Offline Data Sync" items={offline.data} open={viewOffline} onClose={() => this.setState({ viewOffline: false })} />
                        {release && releaseNotes &&
                            <AppBar position="static" elevation={1} className="dialog-header">
                                <Toolbar className="flex w-full">
                                    <Typography variant="subtitle1" color="inherit">
                                        <Icon className="align-middle mr-6" style={{ marginBottom: 2 }}>info</Icon>
                                        About
                                    </Typography>
                                </Toolbar>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 104,
                                    top: matches ? 8 : 4,
                                    color: fontSize < 1 ? undefined : 'white',
                                    transform: 'scaleX(-1)'
                                }}
                                    onClick={() => this.setState({ fontSize: fontSize - 1 })}
                                    className="dialog-header-icon"
                                    disabled={fontSize < 1}
                                >
                                    <Icon>zoom_out</Icon>
                                </IconButton>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 56,
                                    top: matches ? 8 : 4,
                                    color: fontSize > 2 ? undefined : 'white'
                                }}
                                    onClick={() => this.setState({ fontSize: fontSize + 1 })}
                                    className="dialog-header-icon"
                                    disabled={fontSize > 2}
                                >
                                    <Icon>zoom_in</Icon>
                                </IconButton>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: matches ? 8 : 4,
                                    color: 'white'
                                }}
                                    onClick={this.closeDialogR}
                                    className="dialog-header-icon"
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </AppBar>
                        }
                        {(!release && !releaseNotes) && !allowVersionList &&
                            <React.Fragment>
                                <DialogContent className="pb-0 dialog-header">
                                    <Typography className="w-full text-center font-bold">
                                        {/* <SPRoundIcon className="align-middle mr-4 text-20" style={{ marginBottom: 3 }} />
                                        Service Point Pro */}
                                        <img src="assets/images/logos/SP_Logo_SM_GRN.png" className="w-256 pt-16 sm:pt-0" />
                                    </Typography>
                                    {/* <Typography className="text-10 w-full text-center mb-12 cursor-pointer">Version {version.version}</Typography> */}
                                    <div className="bg-grey-lightest rounded-lg w-full min-h-256 max-h-256 overflow-auto p-8 py-4 relative" style={{ border: '1px solid lightgrey' }}>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Company:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full">{about.Company}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Instance Type:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full">{instanceTypes[about.InstanceType]}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Total Users:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full">{about.TotalUsers}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Field Users:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full">{about.FieldUsers}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Server Version:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full">{about.Version}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Client Version:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full flex justify-between">{version.version}{about.Releases.indexOf(version.version) > -1 && <span onClick={() => this.setState({ release: version.version }, () => this.props.getReleaseNotes(this.state.release))} className="text-10 text-grey cursor-pointer hover:underline">Release Notes<Icon className="text-16 align-middle" color="action">keyboard_arrow_right</Icon></span>}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex py-4" style={{ borderBottom: '1px dotted lightgrey' }}>
                                            <div className="w-1/2 text-left font-bold text-12 pr-6">
                                                <div className="w-full truncate">Client OS:</div>
                                            </div>
                                            <div className="w-full pl-6 text-12">
                                                <div className="w-full">{os}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-end absolute pin-b pin-l pin-r m-auto mr-6">{offline.data.length > 0 && <span onClick={() => this.setState({ viewOffline: true })} className="text-10 text-grey cursor-pointer hover:underline">Offline Data<Icon className="text-16 align-middle" color="action">keyboard_arrow_right</Icon></span>}</div>
                                    </div>
                                    <Typography className="w-full text-center text-10 mt-12">&copy; Service Point Pro {moment().format('YYYY')}</Typography>
                                </DialogContent>
                                <DialogActions className="dialog-actions pt-0 px-16">
                                    <Button
                                        color="primary"
                                        onClick={this.closeDialog}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </React.Fragment>
                        }
                        {releaseNotes && releaseNotes.Version === release && Step &&
                            <React.Fragment>
                                <DialogContent>
                                    <Typography onClick={() => this.setState({ release: null })} className="text-16 font-bold mb-8 cursor-pointer"><Icon color="primary" className="mr-4 align-middle">keyboard_arrow_left</Icon>Version {version.version} Release Notes</Typography>
                                    <Swipeable onSwipedLeft={this.handleNext} onSwipedRight={this.handleBack}>
                                        <FuseAnimateGroup enter={{ animation: step > prevStep ? "transition.slideRightIn" : "transition.slideLeftIn" }} delay={300}>
                                            <Card key={step}>
                                                <div className="w-full sm:flex">
                                                    <div className={classNames("w-full locked p-12 md:p-24 min-h-256 sm:min-h-512 sm:max-h-512 overflow-y-auto", fontSize === 0 ? classes.fontSm : fontSize === 1 ? classes.fontMd : fontSize === 2 ? classes.fontLg : fontSize === 3 ? classes.fontXl : '')}>
                                                        <Typography className={classNames(classes.stepTitle, "font-bold text-16 w-full mb-16")}>{Step.Name}</Typography>
                                                        <Typography className="font-bold text-20 w-full mb-16">{Step.Description}</Typography>
                                                        <ReactQuill
                                                            ref={(el) => { this.quillRef = el; }}
                                                            theme="snow"
                                                            className="w-full"
                                                            value={Content}
                                                            readOnly={true}
                                                            modules={
                                                                {
                                                                    toolbar: toolbarOptions,
                                                                    history: history,
                                                                    imageDrop: true,
                                                                    imageResize: {
                                                                        parchment: Quill.import('parchment'),
                                                                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                                    }
                                                                }
                                                            }
                                                            formats={formats}
                                                            onChange={this.handleEditorChange}
                                                        />
                                                    </div>
                                                    <div className={`w-full p-12 md:p-24 min-h-256 max-h-256 sm:min-h-512 sm:max-h-512 bg-grey-lightest border-t-light sm:border-t-none sm:border-l-light overflow-hidden relative locked`}>
                                                        {Video &&
                                                            <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute")} style={{ background: `#212121` }}>
                                                            </div>
                                                        }
                                                        {Showcase &&
                                                            <div className={classNames(classes.blurBG, "pin-l pin-r pin-t pin-b m-auto absolute")} style={{ backgroundImage: `url(${Showcase})` }}>
                                                                {viewShowcase &&
                                                                    <Dialog
                                                                        open={viewShowcase}
                                                                        onClose={() => this.setState({ viewShowcase: false })}
                                                                        maxWidth="lg"
                                                                    >
                                                                        <img className="w-full" src={Showcase} />
                                                                    </Dialog>
                                                                }
                                                            </div>
                                                        }
                                                        <div onClick={() => { if (Showcase) { this.setState({ ...this.state, viewShowcase: !viewShowcase }) }; }} className={classNames(Showcase ? classes.fitBG : "", "pin-l pin-r pin-t pin-b m-auto absolute cursor-pointer")} style={{ backgroundImage: Showcase ? `url(${Showcase})` : 'unset' }}>
                                                            {Video &&
                                                                <video controls={true} className="w-full h-full" autoplay={false} src={Video} />
                                                            }
                                                            {/* <input type="file" ref={el => { this.showcaseInput = el; }} style={{ display: 'none' }} onChange={this.selectShowcase} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </FuseAnimateGroup>
                                    </Swipeable>
                                    <Typography className="w-full text-center text-10 mt-12">&copy; Service Point Pro {moment().format('YYYY')}</Typography>
                                </DialogContent>
                                <MobileStepper
                                    steps={steps.length}
                                    classes={{ root: "w-full bg-transparent dialog-stepper" }}
                                    position="static"
                                    activeStep={step}
                                    className={classes.mobileStepper}
                                    nextButton={
                                        step === (steps.length - 1) ?
                                            <Button size="small" color="primary" onClick={this.closeDialogR}>
                                                Done
                                            </Button> :
                                            <Button size="small" onClick={this.handleNext} disabled={step === (steps.length - 1)}>
                                                Next
                                                <Icon>keyboard_arrow_right</Icon>
                                            </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={this.handleBack} disabled={step === 0}>
                                            {step > 0 &&
                                                <React.Fragment>
                                                    <Icon>keyboard_arrow_left</Icon>
                                                    Back
                                                </React.Fragment>
                                            }
                                        </Button>
                                    }
                                />
                            </React.Fragment>
                        }
                        {releaseNotes && releaseNotes.Version !== release && Step && !allowVersionList &&
                            <>
                                <AppBar position="static" elevation={1} className="dialog-header">
                                    <Toolbar className="flex w-full">
                                        <Typography variant="subtitle1" color="inherit">
                                            <Icon className="align-middle mr-6" style={{ marginBottom: 2 }}>info</Icon>
                                            About
                                        </Typography>
                                    </Toolbar>
                                    <IconButton style={{
                                        position: 'absolute',
                                        right: 104,
                                        top: matches ? 8 : 4,
                                        color: fontSize < 1 ? undefined : 'white',
                                        transform: 'scaleX(-1)'
                                    }}
                                        onClick={() => this.setState({ fontSize: fontSize - 1 })}
                                        className="dialog-header-icon"
                                        disabled={fontSize < 1}
                                    >
                                        <Icon>zoom_out</Icon>
                                    </IconButton>
                                    <IconButton style={{
                                        position: 'absolute',
                                        right: 56,
                                        top: matches ? 8 : 4,
                                        color: fontSize > 2 ? undefined : 'white'
                                    }}
                                        onClick={() => this.setState({ fontSize: fontSize + 1 })}
                                        className="dialog-header-icon"
                                        disabled={fontSize > 2}
                                    >
                                        <Icon>zoom_in</Icon>
                                    </IconButton>
                                    <IconButton style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: matches ? 8 : 4,
                                        color: 'white'
                                    }}
                                        onClick={this.closeDialogR}
                                        className="dialog-header-icon"
                                    >
                                        <Icon>close</Icon>
                                    </IconButton>
                                </AppBar>
                                <React.Fragment>
                                    <DialogContent>
                                        <Typography className="text-16 font-bold mb-8 cursor-pointer">Version {releaseNotes.Version} Release Notes</Typography>
                                        <Swipeable onSwipedLeft={this.handleNext} onSwipedRight={this.handleBack}>
                                            <FuseAnimateGroup enter={{ animation: step > prevStep ? "transition.slideRightIn" : "transition.slideLeftIn" }} delay={300}>
                                                <Card key={step}>
                                                    <div className="w-full sm:flex">
                                                        <div className={classNames("w-full locked p-12 md:p-24 min-h-256 sm:min-h-512 sm:max-h-512 overflow-y-auto", fontSize === 0 ? classes.fontSm : fontSize === 1 ? classes.fontMd : fontSize === 2 ? classes.fontLg : fontSize === 3 ? classes.fontXl : '')}>
                                                            <Typography className={classNames(classes.stepTitle, "font-bold text-16 w-full mb-16")}>{Step.Name}</Typography>
                                                            <Typography className="font-bold text-20 w-full mb-16">{Step.Description}</Typography>
                                                            <ReactQuill
                                                                ref={(el) => { this.quillRef = el; }}
                                                                theme="snow"
                                                                className="w-full"
                                                                value={Content}
                                                                readOnly={true}
                                                                modules={
                                                                    {
                                                                        toolbar: toolbarOptions,
                                                                        history: history,
                                                                        imageDrop: true,
                                                                        imageResize: {
                                                                            parchment: Quill.import('parchment'),
                                                                            modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                                        }
                                                                    }
                                                                }
                                                                formats={formats}
                                                                onChange={this.handleEditorChange}
                                                            />
                                                        </div>
                                                        <div className={`w-full p-12 md:p-24 min-h-256 max-h-256 sm:min-h-512 sm:max-h-512 bg-grey-lightest border-t-light sm:border-t-none sm:border-l-light overflow-hidden relative locked`}>
                                                            {Video &&
                                                                <div className={classNames("pin-l pin-r pin-t pin-b m-auto absolute")} style={{ background: `#212121` }}>
                                                                </div>
                                                            }
                                                            {Showcase &&
                                                                <div className={classNames(classes.blurBG, "pin-l pin-r pin-t pin-b m-auto absolute")} style={{ backgroundImage: `url(${Showcase})` }}>
                                                                    {viewShowcase &&
                                                                        <Dialog
                                                                            open={viewShowcase}
                                                                            onClose={() => this.setState({ viewShowcase: false })}
                                                                            maxWidth="lg"
                                                                        >
                                                                            <img className="w-full" src={Showcase} />
                                                                        </Dialog>
                                                                    }
                                                                </div>
                                                            }
                                                            <div onClick={() => { if (Showcase) { this.setState({ ...this.state, viewShowcase: !viewShowcase }) }; }} className={classNames(Showcase ? classes.fitBG : "", "pin-l pin-r pin-t pin-b m-auto absolute cursor-pointer")} style={{ backgroundImage: Showcase ? `url(${Showcase})` : 'unset' }}>
                                                                {Video &&
                                                                    <video controls={true} className="w-full h-full" autoplay={false} src={Video} />
                                                                }
                                                                {/* <input type="file" ref={el => { this.showcaseInput = el; }} style={{ display: 'none' }} onChange={this.selectShowcase} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </FuseAnimateGroup>
                                        </Swipeable>
                                        <Typography className="w-full text-center text-10 mt-12">&copy; Service Point Pro {moment().format('YYYY')}</Typography>
                                    </DialogContent>
                                    <MobileStepper
                                        steps={steps.length}
                                        classes={{ root: "w-full bg-transparent dialog-stepper" }}
                                        position="static"
                                        activeStep={step}
                                        className={classes.mobileStepper}
                                        nextButton={
                                            step === (steps.length - 1) ?
                                                <Button size="small" color="primary" onClick={this.closeDialogR}>
                                                    Done
                                                </Button> :
                                                <Button size="small" onClick={this.handleNext} disabled={step === (steps.length - 1)}>
                                                    Next
                                                    <Icon>keyboard_arrow_right</Icon>
                                                </Button>
                                        }
                                        backButton={ step > 0 ? 
                                            <Button size="small" onClick={this.handleBack} disabled={step === 0}>
                                                    <React.Fragment>
                                                        <Icon>keyboard_arrow_left</Icon>
                                                        Back
                                                    </React.Fragment>
                                            </Button> :
                                            <Button size="small" onClick={this.backToReleaseNotes}>
                                                    <React.Fragment>
                                                        <Icon>keyboard_arrow_left</Icon>
                                                        Previous Release Notes
                                                    </React.Fragment>
                                            </Button>
                                        }
                                    />
                                </React.Fragment>
                            </>
                        }
                        {versionsList && allowVersionList &&
                            <div style={{height: "415px", display:"flex", justifyContent:"center", flexDirection:"column"}}>
                                <DialogContent className="pb-0 dialog-header" style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                                    {/* <Typography className="text-10 w-full text-center mb-12 cursor-pointer">Version {version.version}</Typography> */}
                                        <div className="bg-grey-lightest rounded-lg w-full p-8 py-4 relative" style={{ border: '1px solid lightgrey', height: "85%" }}>
                                            <div className="w-full flex" style={{ display: "flex", height: "100%" }}>
                                                <div className="w-full text-left text-12 pr-6">
                                                    <div className="w-full truncate text-24 font-bold mb-10">Version List:</div>
                                                    <div className="w-full truncate text-14 mb-10">Here are the latest 5 Versions. <br /> Select to see the release notes</div>
                                                    {versionsList ? versionsList.map((item) =>
                                                        <div className="w-full p-4 cursor-pointer hover:underline" onClick={() => { this.props.getReleaseNotes(item, item); }} style={{ borderBottom: '1px dotted lightgrey', color: "#004359" }}> - {item} </div>
                                                    ) : ""}
                                                </div>
                                                <div className="w-full pl-6 text-12" style={{display: 'flex', alignItems: 'center'}}>
                                                    <img src="assets/images/logos/SP_Logo_SM_GRN.png" className="w-256 pt-16 sm:pt-0" />
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-end absolute pin-b pin-l pin-r m-auto mr-6">{offline.data.length > 0 && <span onClick={() => this.setState({ viewOffline: true })} className="text-10 text-grey cursor-pointer hover:underline">Offline Data<Icon className="text-16 align-middle" color="action">keyboard_arrow_right</Icon></span>}</div>
                                        </div>
                                    <Typography className="w-full text-center text-10 mt-12">&copy; Service Point Pro {moment().format('YYYY')}</Typography>
                                </DialogContent>
                                <DialogActions className="dialog-actions pt-0 px-16">
                                    <Button
                                        color="primary"
                                        onClick={this.closeDialog}
                                        >
                                        Close
                                    </Button>
                                </DialogActions>
                            </div>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getReleaseNotes,
        setAbout,
        getAbout,
        resetRelease,
        resetAllowVersionList
    }, dispatch);
}

function mapStateToProps({ spReducers, version }) {
    return {
        version,
        offline: spReducers.offline
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AboutDialog));
