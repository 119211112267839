import { AppBar, Button, Dialog, DialogActions, DialogContent, Grow, Icon, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import DataLogs from './DataLogs';
import moment from 'moment';
import _ from '@lodash';
import axios from 'axios';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ minHeight: 384, overflow: 'auto', position: 'relative' }}>
            {props.children}
        </Typography>
    );
}

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function ErrorDiv({ error }) {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                height: 24,
                textAlign: 'center'
            }}>
                <Typography className="text-16 font-bold"><Icon className="mr-6 text-24 align-middle" color="error">warning</Icon>{error}</Typography>
            </div>
        </div>
    );
};

const styles = theme => ({
    root: {},
});
const initialState = {
    open: false,
    restoreData: null,
    logs: [],
    error: null
};

class ChangeDialog extends Component {
    state = { ...initialState };

    componentDidMount() {
        const initial = _.cloneDeepWith(initialState);
        const { open } = this.props;
        this.setState({ ...initial, open });
    }

    // componentDidUpdate = (prevProps, prevState) => {
    //     const { open } = this.props;
    //     if (open !== prevProps.open) {
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const initial = _.cloneDeepWith(initialState);
        const { open, type, rec } = this.props;
        if (!_.isEqual(open, prevProps.open)) {
            if (open && type && rec) {
                this.fetchLogs(type, rec);
            } else {
                this.setState({ ...initial, open });
            }
        }
    }

    fetchLogs = (type, rec) => {
        const initial = _.cloneDeepWith(initialState);
        this.setState({ loading: true, logs: [], error: null }, () => {
            const request = axios.get(`${window["apiLocation"]}/api/ChangeLog?Type=${type}&ID=${rec}`);

            request.then((response) => {
                this.setState({ ...initial, loading: false, logs: response.data, error: null, open: true });
            }).catch((error) => {
                this.setState({ ...initial, loading: false, logs: [], error: error.message || 'Error Loading Logs', open: true });
            })
        })
    }

    closeComposeDialog = () => {
        const { onClose } = this.props;
        this.setState({
            ...initialState
        }, () => {
            if (onClose) {
                onClose();
            }
        })
    }

    formatDate(date) {
        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
        return formatted;
    }

    setRestoreData = (restoredData) => {
        const { data } = this.props;
        window["warn"]('Data to restore: ', { ...data, ...restoredData });
        this.setState({ restoreData: { ...data, ...restoredData } });
    }

    render() {
        const { classes, restore, data, accessLevel } = this.props;
        const { restoreData, open, logs, loading, error } = this.state;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? "m-24" : "full-screen-dialog"
                        }}
                        className={classes.root}
                        open={open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="sm"
                        fullScreen={!matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    <Icon className="text-24 mr-8 align-middle">history</Icon>
                                    Change Tracking
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            {loading || error || logs.length < 1 &&
                                <TabContainer>
                                    {loading && !error && <LoadingDiv />}
                                    {!loading && error && <ErrorDiv error={error} />}

                                    {!loading && !error && logs.length < 1 && <ErrorDiv error={"No Changes Found"} />}
                                </TabContainer>
                            }
                            {!loading && !error && logs.length > 0 &&
                                <DataLogs onSelected={(restoredData) => this.setRestoreData(restoredData)} selected={(restoreData || {}).logID} logs={logs} />
                            }
                        </DialogContent>

                        {(restore && restoreData && accessLevel && accessLevel.AccessLevel === "F") &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        if (restore) {
                                            restore(restoreData);
                                        }
                                    }}
                                >
                                    Restore
                                </Button>
                                <Typography className="mr-8 text-12" color="textSecondary">{moment(restoreData.timestamp).local().format("h:mm:ss A on M/DD/YYYY")}</Typography>
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ tripsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ChangeDialog));
