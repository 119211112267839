import {combineReducers} from 'redux';
import boards from './boards.reducer';
import board from './board.reducer';
import card from './card.reducer';
import recycleBin from './recycle-bin.reducer';
import boardView from './board-view.reducer';

const scrumboardAppReducers = combineReducers({
    boards,
    board,
    card,
    recycleBin,
    boardView
});

export default scrumboardAppReducers;
