import { FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Dialog, Icon, IconButton, List, ListItem, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import parse, { domToReact } from 'html-react-parser';
import MailChip from './MailChip';
import axios from 'axios';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ minHeight: 384, overflow: 'auto', position: 'relative' }}>
            {props.children}
        </Typography>
    );
}

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function ErrorDiv({ error }) {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                height: 24,
                textAlign: 'center'
            }}>
                <Typography className="text-16 font-bold"><Icon className="mr-6 text-24 align-middle" color="error">warning</Icon>{error}</Typography>
            </div>
        </div>
    );
};

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    mailList: {
        padding: 0
    },
    mailItem: {
        borderBottom: '1px solid  ' + theme.palette.divider,

        '&.unread': {
            background: 'rgba(0,0,0,0.03)'
        },
        '&.selected': {
            '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                display: 'block',
                height: '100%',
                width: 3,
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    attachment: {
        fontSize: 13,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.16)',
        paddingLeft: 16,
        marginBottom: 8,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    attachmentFilename: {
        fontWeight: 600
    },
    attachmentSize: {
        marginLeft: 8,
        fontWeight: 300
    },
});
const newEmailLogState = {
    messages: null,
    message: null,
    error: null,
    loading: false,
};

class EmailLogDialog extends Component {
    state = { ..._.cloneDeepWith(newEmailLogState) };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { messages, open, type, rec } = this.props;
        if (open && type && rec && !_.isEqual(open, prevProps.open)) {
            // this.setState({ messages });
            this.fetchMessages(type, rec);
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    fetchMessages = (type, rec) => {
        this.setState({ loading: true, message: null, messages: null, error: null }, () => {
            const request = axios.get(`${window["apiLocation"]}/api/EmailLog?Type=${type}&ID=${rec}`);

            request.then((response) => {
                this.setState({ loading: false, messages: response.data, error: null });
            }).catch((error) => {
                this.setState({ loading: false, messages: null, error: error.message || 'Error Loading Messages' });
            })
        })
    }

    closeComposeDialog = () => {
        const { message } = this.state;
        if (message) {
            this.setState({ message: null });
        } else {
            this.props.onClose();
        }
    };

    getMessage = (messageID) => {
        const request = axios.get(`${window["apiLocation"]}/api/Email?MessageID=${messageID}`);

        request.then((response) => {
            this.setState({ message: response.data });
        })
    }

    render() {
        const { theme, classes, securables, open, title, users, Co } = this.props;
        const { messages, message, error, loading } = this.state;
        const statuses = { processed: theme.palette.primary.main, dropped: theme.palette.error[500], deferred: 'orange', bounce: theme.palette.error[700], delivered: 'limegreen', open: theme.palette.primary.main, click: theme.palette.secondary.main };
        const events = { processed: "mail", dropped: "mail", deferred: "mail", bounce: "mail", delivered: "mail", open: "visibility", click: "mouse" };

        return (
            <Media query={"(min-width: 1200px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: classNames(classes.paper, matches ? "m-24 max-w-lg" : "full-screen-dialog")
                        }}
                        fullScreen={!matches}
                        open={open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={"md"}
                    >
                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit" className="w-5/6 truncate">
                                    <Icon onClick={() => this.setState({ message: null })} className="mr-6 align-middle mb-2 cursor-pointer">{message ? "keyboard_arrow_left" : "send"}</Icon>{message ? `ID: ${message.MessageID}` : title}
                                </Typography>
                            </Toolbar>
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: 56,
                                    top: matches ? 8 : 6,
                                    color: 'white'
                                }}
                                onClick={() => {
                                    if (!loading) {
                                        this.fetchMessages(this.props.type, this.props.rec);
                                    }
                                }}
                                className={classNames("dialog-header-icon", loading && "spin")}
                            >
                                <Icon>refresh</Icon>
                            </IconButton>
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: matches ? 8 : 6,
                                    color: 'white'
                                }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <TabContainer>
                            {loading && !error && <LoadingDiv />}
                            {!loading && error && <ErrorDiv error={error} />}
                            {/* <Typography variant="h6" className="text-12 font-bold mb-8">Sent Messages</Typography> */}
                            {!loading && !error && Boolean(messages) &&
                                <List className={classes.mailList}>
                                    <FuseAnimateGroup
                                        enter={{
                                            animation: "transition.slideUpBigIn"
                                        }}
                                    >
                                        {
                                            messages && messages.map((mail) => {
                                                const { UserName } = mail;
                                                const sender = _.find(users, (o) => o.UserName.toLowerCase() === UserName.toLowerCase());
                                                const avatar = 'assets/images/avatars/profile.jpg';
                                                const clicks = _.filter(mail.Data.Events, { EventType: 'click' });
                                                const opens = _.filter(mail.Data.Events, { EventType: 'open' });
                                                if (!message || message.MessageID === mail.MessageID) {
                                                    return (
                                                        <div className="w-full">
                                                            <ListItem
                                                                dense
                                                                button
                                                                onClick={() => this.getMessage(mail.MessageID)}
                                                                className={classNames(classes.mailItem, "px-8 sm:px-16")}>
                                                                <div className="w-full flex">

                                                                    {sender && sender.Data && sender.Data.Avatar ? (
                                                                        <Avatar className="my-24 mr-6" alt={`${sender.FirstName} ${sender.LastName}`} src={getProfileImage(`Co=${Co}&ID=${sender.Data.Avatar}`)} />
                                                                    ) : (
                                                                        <Avatar className={classNames(classes.avatar, "my-24 mr-6")}>
                                                                            {sender.FirstName[0]}
                                                                        </Avatar>
                                                                    )}
                                                                    <div className="flex flex-1 flex-col relative overflow-hidden">
                                                                        <div className="flex items-center justify-between px-12">
                                                                            <div className="flex items-center">
                                                                                <Typography variant="subtitle1" className="font-bold">{`${sender.FirstName} ${sender.LastName}`}</Typography>
                                                                            </div>
                                                                            <Typography variant="subtitle1" className="text-12 mt-2 font-bold" style={{ whiteSpace: 'nowrap' }}>{moment(mail.CreatedDate).format("M/D/YYYY")}</Typography>
                                                                        </div>

                                                                        <div className="flex px-12 py-0 justify-between">
                                                                            <Typography className="truncate">{mail.Subject}</Typography>
                                                                            <Typography variant="subtitle1" className="text-10 mt-6" style={{ whiteSpace: 'nowrap' }}>{moment(mail.CreatedDate).format("h:mm A")}</Typography>
                                                                        </div>

                                                                        <div className="flex px-12 py-0 mt-8 justify-between">
                                                                            <div className={classNames(classes.labels, "flex justify-start overflow-x-auto")}>
                                                                                {mail.Data.Recipients.map(rcp => {
                                                                                    return (
                                                                                        <MailChip data={rcp} className="mr-4" title={rcp.To} color={!rcp.Status ? '#9f9f9f' : statuses[rcp.Status]} key={rcp.ID} />
                                                                                    );
                                                                                }
                                                                                )}
                                                                            </div>
                                                                            <div className="flex">
                                                                                {opens.length > 0 &&
                                                                                    <Badge color="error" badgeContent={opens.length} classes={{ root: "ml-16", badge: "min-w-16 w-auto h-16 text-10 px-4 mr-4 bg-blue" }}>
                                                                                        <Icon className="text-20" color="action">visibility</Icon>
                                                                                    </Badge>
                                                                                }
                                                                                {clicks.length > 0 &&
                                                                                    <Badge color="error" badgeContent={clicks.length} classes={{ root: "ml-16", badge: "min-w-16 w-auto h-16 text-10 px-4 mr-4 bg-green" }}>
                                                                                        <Icon className="text-20" color="action">mouse</Icon>
                                                                                    </Badge>
                                                                                }
                                                                                {mail.HasAttachmentsYN === 'Y' &&
                                                                                    <Badge color="error" badgeContent={mail.AttachmentCount} classes={{ root: "ml-16", badge: "min-w-16 w-auto h-16 text-10 px-4 mr-4" }}>
                                                                                        <Icon className="text-20" style={{ transform: 'rotate(-90deg)' }} color="action">attachment</Icon>
                                                                                    </Badge>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </ListItem>
                                                            {message && message.MessageID === mail.MessageID && message.Body && message.Body.length > 0 &&
                                                                <div className="w-full email-preview" style={{ maxWidth: '100%', minHeight: 512, overflow: 'auto', borderBottom: '1px solid lightgrey' }}>
                                                                    {parse(message.Body)}
                                                                    {mail.Data.Events && mail.Data.Events.length > 0 &&
                                                                        <div className="full my-24 p-24 pt-12 bt-dotted">
                                                                            <Typography className="text-12 font-bold mb-8">Recipient Events</Typography>
                                                                            {_.orderBy(mail.Data.Events, ['ReceivedDateTime'], ['desc']).map((evt, index) => {
                                                                                return (
                                                                                    <div className={classNames("w-full py-6", index > 0 && "bt-dotted")}>
                                                                                        <div className="w-full flex justify-between">
                                                                                            <div className="w-full flex">
                                                                                                <Tooltip placement="right" open={evt.Link ? undefined : false} title={`${evt.Link} - [${evt.LinkIndex}]`}>
                                                                                                    <Icon className="text-32 mr-12" style={{ color: statuses[evt.EventType] || "#5f5f5f" }}>{events[evt.EventType] || "mail"}</Icon>
                                                                                                </Tooltip>
                                                                                                <div className="w-full">
                                                                                                    <Typography className="text-11 font-bold">{evt.To}</Typography>
                                                                                                    <Typography className="text-10 capitalize">{evt.EventType}</Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-full text-right">
                                                                                                <Typography className="text-11 font-bold text-right">{moment.utc(evt.ReceivedDateTime).local().format("M/D/YYYY")}</Typography>
                                                                                                <Typography className="text-10 capitalize text-right">{moment.utc(evt.ReceivedDateTime).local().format("h:mm A")}</Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        {evt.Notes &&
                                                                                            <div className="w-full border-dotted border-1 border-grey rounded p-12 text-10 my-6 italic bg-grey-lightest">
                                                                                                <div className="w-full flex"><Icon color="error" className="mr-8 align-middle text-20">warning</Icon><div className="w-full mt-2">{evt.Notes}</div></div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </FuseAnimateGroup>
                                </List>
                            }
                        </TabContainer>
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        users: spReducers.userProfiles.Users
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(EmailLogDialog));
