import axios from 'axios/index';

export const SET_CALL_LOG = '[SPCONNECTION] SET CALL LOG';
export const SET_CALL_HANDLER_DATA = '[SPCONNECTION] SET CALL HANDLER DATA';
export const GET_CALL_HANDLER_DATA = '[SPCONNECTION] GET CALL HANDLER DATA';
export const SUBSCRIBE_CALL_HANDLER_LIST = '[SPCONNECTION] SUBSCRIBE CALL HANDLER LIST';

const callData = {
   "Queues": [
      {
        "Data": {
          "Queue": {
            "Sid": "1b730dcf-4838-4628-9218-473c1b094ab0",
            "FriendlyName": "1_Reception",
            "AverageWaitTime": 0,
            "CurrentSize": 0,
            "Members": []
          }
        },
        "Queue": "Reception",
        "Description": "All other calls",
        "ID": 18,
        "Co": 1,
        "DefaultBusinessUnit": "Service",
        "Notes": "",
        "OnAcceptedMessage": "",
        "OnAcceptedVoice": "man",
        "WaitFileID": 14,
        "OnAcceptedMessageType": "voice",
        "AcceptedFileID": 45,
        "RecordYN": "Y",
        "ActiveYN": "Y",
        "OnQueuedMessageType": "voice",
        "OnQueuedMessage": "",
        "OnQueuedTextMessage": "Thanks for choosing Guardian Home Services! A Team Member will be with you shortly.",
        "TextEnabledYN": "Y",
        "FallbackAction": "Voicemail",
        "FallbackTextAction": "Hangup",
        "FallbackTextMessage": "We're sorry, no agents are currently available. Please try back during our normal business hours of 8AM - 5PM Monday through Friday.",
        "FallbackDial": "5053827518",
        "FallbackAudioFileID": 17,
        "FallbackVoiceMailbox": 1,
        "RecordFallbackDialYN": "Y"
      },
      {
        "Data": {
          "Queue": {
            "Sid": "aa726470-d38c-4ceb-a064-279ff48db24a",
            "FriendlyName": "1_Sales",
            "AverageWaitTime": 0,
            "CurrentSize": 0,
            "Members": []
          }
        },
        "Queue": "Sales",
        "Description": "New Queue for sales",
        "ID": 19,
        "Co": 1,
        "DefaultBusinessUnit": "Service",
        "Notes": "",
        "OnAcceptedMessage": "",
        "OnAcceptedVoice": "man",
        "WaitFileID": 14,
        "OnAcceptedMessageType": "voice",
        "AcceptedFileID": 45,
        "RecordYN": "Y",
        "ActiveYN": "Y",
        "OnQueuedMessageType": "voice",
        "OnQueuedMessage": "",
        "FallbackAction": "Voicemail",
        "FallbackDial": "5053827518",
        "FallbackVoiceMailbox": 1,
        "RecordFallbackDialYN": "Y"
      },
      {
        "Data": {
          "Queue": {
            "Sid": "c914d4b0-9d4a-44b2-81bd-1b8ce90820eb",
            "FriendlyName": "1_Service",
            "AverageWaitTime": 0,
            "CurrentSize": 0,
            "Members": []
          }
        },
        "Queue": "Service",
        "Description": "Main Service Queue",
        "ID": 17,
        "Co": 1,
        "DefaultBusinessUnit": "Service",
        "Notes": "",
        "OnAcceptedMessage": "",
        "OnAcceptedVoice": "man",
        "WaitFileID": 14,
        "OnAcceptedMessageType": "voice",
        "RecordYN": "Y",
        "ActiveYN": "Y",
        "OnQueuedMessageType": "voice",
        "OnQueuedMessage": "",
        "OnQueuedTextMessage": "Thanks for choosing the GHS Service department! A Team member will be with you shortly.",
        "TextEnabledYN": "Y",
        "FallbackAction": "Voicemail",
        "FallbackTextAction": "Hangup",
        "FallbackTextMessage": "We're sorry, no agents are currently available. Please try back during our normal business hours of 8AM - 5PM Monday through Friday.",
        "FallbackDial": "5053827518",
        "FallbackAudioFileID": 17,
        "FallbackVoiceMailbox": 1,
        "RecordFallbackDialYN": "N"
      },
      {
        "Data": {},
        "Queue": "Support",
        "Description": "Support Queue",
        "ID": 21,
        "Co": 1,
        "DefaultBusinessUnit": "Service",
        "Notes": "",
        "OnAcceptedMessage": "",
        "OnAcceptedVoice": "",
        "OnAcceptedMessageType": "voice",
        "RecordYN": "Y",
        "ActiveYN": "N",
        "OnQueuedMessageType": "voice",
        "OnQueuedMessage": "",
        "FallbackAction": "Voicemail",
        "FallbackVoiceMailbox": 1
      },
      {
        "Data": {},
        "Queue": "Test",
        "Description": "Test Queue",
        "ID": 20,
        "Co": 1,
        "Notes": "",
        "OnAcceptedMessage": "",
        "OnAcceptedVoice": "",
        "OnAcceptedMessageType": "voice",
        "RecordYN": "Y",
        "ActiveYN": "N",
        "OnQueuedMessageType": "voice",
        "OnQueuedMessage": "",
        "FallbackAction": "Voicemail",
        "FallbackVoiceMailbox": 1
      }
    ],
    "Calls": [
      {
        "Data": {
          "Customers": [
            {
              "ID": 22,
              "Co": 1,
              "Customer": 22,
              "Name": "",
              "FirstName": "George",
              "LastName": "O'Malley",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Email": "gomalley@greys.com",
              "Contact": "",
              "BillAddress1": "8951 Eagle Rock Ave NE",
              "BillAddress2": "",
              "BillCity": "Albuquerque",
              "BillState": "NM",
              "BillZip": "87122",
              "BillCountry": "US",
              "BillPhone": "5054236875",
              "BillEmail": "gomalley@greys.com",
              "BillContact": "George O'Malley",
              "Status": "A",
              "Notes": "",
              "AddedBy": "bdominguez@servicepointpro.com",
              "AddedDate": "2020-12-15T15:11:44.51",
              "UpdatedBy": "jfish@servicepointpro.com",
              "UpdatedDate": "2021-07-28T07:48:08.907",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "ActiveYN": "Y",
              "PaymentsCustomer": "f87ef662-6002-462d-b163-81be7ffbedd0"
            }
          ],
          "Sites": [
            {
              "ID": 84,
              "Co": 1,
              "Customer": 22,
              "Site": "22.03",
              "Description": "George O'Malley 8951 Eagle Rock Ave NE",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Contact": "George O'Malley",
              "Email": "gomalley@greys.com",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "Status": "A",
              "TaxCode": "SPP-1",
              "AddedBy": "gaguilar@servicepointpro.com",
              "AddedDate": "2021-03-24T21:29:23.52"
            },
            {
              "ID": 84,
              "Co": 1,
              "Customer": 22,
              "Site": "22.03",
              "Description": "George O'Malley 8951 Eagle Rock Ave NE",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Contact": "George O'Malley",
              "Email": "gomalley@greys.com",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "Status": "A",
              "TaxCode": "SPP-1",
              "AddedBy": "gaguilar@servicepointpro.com",
              "AddedDate": "2021-03-24T21:29:23.52"
            },
            {
              "ID": 84,
              "Co": 1,
              "Customer": 22,
              "Site": "22.03",
              "Description": "George O'Malley 8951 Eagle Rock Ave NE",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Contact": "George O'Malley",
              "Email": "gomalley@greys.com",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "Status": "A",
              "TaxCode": "SPP-1",
              "AddedBy": "gaguilar@servicepointpro.com",
              "AddedDate": "2021-03-24T21:29:23.52"
            },
            {
              "ID": 84,
              "Co": 1,
              "Customer": 22,
              "Site": "22.03",
              "Description": "George O'Malley 8951 Eagle Rock Ave NE",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Contact": "George O'Malley",
              "Email": "gomalley@greys.com",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "Status": "A",
              "TaxCode": "SPP-1",
              "AddedBy": "gaguilar@servicepointpro.com",
              "AddedDate": "2021-03-24T21:29:23.52"
            },
            {
              "ID": 84,
              "Co": 1,
              "Customer": 22,
              "Site": "22.03",
              "Description": "George O'Malley 8951 Eagle Rock Ave NE",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Contact": "George O'Malley",
              "Email": "gomalley@greys.com",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "Status": "A",
              "TaxCode": "SPP-1",
              "AddedBy": "gaguilar@servicepointpro.com",
              "AddedDate": "2021-03-24T21:29:23.52"
            },
            {
              "ID": 84,
              "Co": 1,
              "Customer": 22,
              "Site": "22.03",
              "Description": "George O'Malley 8951 Eagle Rock Ave NE",
              "Address1": "8951 Eagle Rock Ave NE",
              "Address2": "",
              "City": "Albuquerque",
              "State": "NM",
              "Zip": "87122",
              "Country": "US",
              "Phone": "5052886907",
              "Contact": "George O'Malley",
              "Email": "gomalley@greys.com",
              "Latitude": "35.1875935",
              "Longitude": "-106.543607",
              "Status": "A",
              "TaxCode": "SPP-1",
              "AddedBy": "gaguilar@servicepointpro.com",
              "AddedDate": "2021-03-24T21:29:23.52"
            },
          ],
          "WorkOrders": [
            {
              "ID": 896088,
              "Co": 1,
              "WorkOrder": 4717,
              "Customer": 22,
              "Site": "22.01",
              "Description": "Test Upcoming",
              "Status": 0,
              "Requestor": "George O'Malley",
              "Contact": "George O'Malley",
              "ContactPhone": "5052886907",
              "EnteredBy": "jfish@servicepointpro.com",
              "EnteredDate": "2023-06-13T13:41:12.617",
              "CallSid": "a8a91292-2dff-4b70-a756-7a3ac0a1775d",
              "SiteContact": 44,
              "RequestedByContact": 44
            }
          ],
          "Phone": {
            "Phone": "+15054205505",
            "Co": 1,
            "Name": "Google",
            "Description": "Main number on Google",
            "Notes": "",
            "CreatedBy": "jfish@servicepointpro.com",
            "CreatedDate": "2020-12-08T08:20:27.41",
            "ActiveYN": "Y",
            "DefaultQueue": "Reception",
            "Greeting": "",
            "GreetingVoice": "man",
            "GreetingType": "text",
            "Action": "Gather",
            "DefaultGather": 12,
            "TextAction": "Gather",
            "TextGather": 1,
            "TextEnabledYN": "Y",
            "TollFreeYN": "N"
          },
          "Queue": {
            "Data": {
              "Queue": {
                "Sid": "c914d4b0-9d4a-44b2-81bd-1b8ce90820eb",
                "FriendlyName": "1_Service",
                "AverageWaitTime": 0,
                "CurrentSize": 0,
                "Members": []
              }
            },
            "Queue": "Service",
            "Description": "Main Service Queue",
            "ID": 17,
            "Co": 1,
            "DefaultBusinessUnit": "Service",
            "Notes": "",
            "OnAcceptedMessage": "",
            "OnAcceptedVoice": "man",
            "WaitFileID": 14,
            "OnAcceptedMessageType": "voice",
            "RecordYN": "Y",
            "ActiveYN": "Y",
            "OnQueuedMessageType": "voice",
            "OnQueuedMessage": "",
            "OnQueuedTextMessage": "Thanks for choosing the GHS Service department! A Team member will be with you shortly.",
            "TextEnabledYN": "Y",
            "FallbackAction": "Voicemail",
            "FallbackTextAction": "Hangup",
            "FallbackTextMessage": "We're sorry, no agents are currently available. Please try back during our normal business hours of 8AM - 5PM Monday through Friday.",
            "FallbackDial": "5053827518",
            "FallbackAudioFileID": 17,
            "FallbackVoiceMailbox": 1,
            "RecordFallbackDialYN": "N"
          },
          "QueueID": "c914d4b0-9d4a-44b2-81bd-1b8ce90820eb",
          "QueueName": "Service"
        },
        "Co": 1,
        "CreatedDate": "2023-06-13T13:41:28.617",
        "Sid": "9782611f-2b12-4121-bc69-0e90cf445473",
        "Status": "queued",
        "From": "+15053827518",
        "To": "+15054205505",
        "Queue": "Service",
        "JSON": "{\"CallSid\":\"9782611f-2b12-4121-bc69-0e90cf445473\",\"CallStatus\":\"in-progress\",\"ApiVersion\":\"2010-04-01\",\"Direction\":\"inbound\",\"ForwardedFrom\":null,\"CallerName\":null,\"Digits\":\"1\",\"SpeechResult\":null,\"Confidence\":null,\"RecordingUrl\":null,\"RecordingStatus\":null,\"RecordingDuration\":null,\"RecordingChannels\":null,\"RecordingSource\":null,\"TranscriptionSid\":null,\"TranscriptionText\":null,\"TranscriptionStatus\":null,\"TranscriptionUrl\":null,\"RecordingSid\":null,\"DialCallStatus\":null,\"DialCallSid\":null,\"DialCallDuration\":null,\"SipDomain\":null,\"SipUsername\":null,\"SipCallId\":null,\"SipSourceIp\":null,\"AccountSid\":\"5fe84b26-ac84-4e3d-83bc-84b041f7812a\",\"From\":\"+15053827518\",\"To\":\"+15054205505\",\"FromCity\":null,\"FromState\":null,\"FromZip\":null,\"FromCountry\":null,\"ToCity\":null,\"ToState\":null,\"ToZip\":null,\"ToCountry\":null}",
        "Type": "voice"
      }
    ]
  }

export function getInitialCallHandlerData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CallHandler`, {
        params: {
            Co
        }
    });
    

    let newData = [callData]

    //window["warn"]("getCallHandlerData initial", newData)

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: SET_CALL_HANDLER_DATA,
                payload: response.data,
                //payload: newData,
            });
        });
    }
}

export function getCallHandlerData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/CallHandler`, {
        params: {
            Co
        }
    });

   // window["warn"]("getCallHandlerData", callData)

    let newData = [callData]

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: SET_CALL_HANDLER_DATA,
                payload: response.data
                //payload: newData,
            })
        }).then(dispatch({
            type: SUBSCRIBE_CALL_HANDLER_LIST,
            Co
        }));
    }
}

export function setCallHandlerData(data) {
    let newData = [callData]
    return {
        type: SET_CALL_HANDLER_DATA,
        payload: data
        //payload: newData,
    };
}

export function getCallLog(Co, start, end) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_CALL_LOG,
            data: [],
            loading: true,
        });
        let url = `${window["apiLocation"]}/api/Call?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;

        const request = axios.get(url);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CALL_LOG,
                    start,
                    end,
                    data: response.data,
                    loading: false,
                })
            ])
        );
    }
}

// export function setInCallLog(Data) {
//     return (dispatch, getState) => {
//         const st ate = getState();
//         const {log, date} = state.spReducers.callHandler;
//         for(var i = 0; i < data.length; i++) {
            
//         }
//     }
// }