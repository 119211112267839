import React, { Component } from "react";
import DispatchBoardWorkOrder from "./DispatchBoardWorkOrder";

class DispatchBoardWOWrapper extends Component {
  state = {
    isObserving: false,
    placeholder: true,
  };

  componentDidMount() {
    const { placeholder, observer } = this.props;
    this.setState({ hasRef: false, placeholder }, this.observeRef);
  }

  componentDidUpdate(prevProps, prevState) {
    const { placeholder, observer } = this.props;
    if (
      prevProps.placeholder !== placeholder ||
      (!prevProps.observer && observer)
    ) {
      this.setState({ placeholder, observer }, this.observeRef);
    }
  }

  handleRef = (ref) => {
    // window["log"]('WO Ref: ', this.ref);
    if (ref) {
      this.ref = ref;
      this.setState({ hasRef: true }, () => {
        const { observer } = this.props;
        if (this.ref && observer) {
          observer.observe(this.ref);
          this.setState({ isObserving: true });
        }
      });
    }
  };

  observeRef = () => {
    const { observer, isObserving } = this.state;
    if (this.ref && observer && !isObserving) {
      observer.observe(this.ref);
      this.setState({ isObserving: true });
    }
  };

  render() {
    const { id } = this.props;
    const { placeholder } = this.state;
    return (
      <div id={id} ref={this.handleRef} className="w-full">
        <DispatchBoardWorkOrder {...this.props} placeholder={placeholder} />
      </div>
    );
  }
}

export default DispatchBoardWOWrapper;
