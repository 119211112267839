import axios from 'axios';
import { getUserData } from 'main/content/apps/purchase-orders/store/actions/user.actions';
import { addPurchaseOrderLine, ADD_PURCHASE_ORDER_LINE } from 'main/content/apps/purchase-order-lines/store/actions';
import { showMessage, setOfflineData, guid } from 'store/actions';
import _ from '@lodash';

export const GET_PURCHASE_ORDERS = '[PURCHASE ORDERS APP] GET PURCHASE ORDERS';
export const SET_SEARCH_TEXT = '[PURCHASE ORDERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_PURCHASE_ORDERS = '[PURCHASE ORDERS APP] TOGGLE IN SELECTED PURCHASE ORDERS';
export const SELECT_ALL_PURCHASE_ORDERS = '[PURCHASE ORDERS APP] SELECT ALL PURCHASE ORDERS';
export const DESELECT_ALL_PURCHASE_ORDERS = '[PURCHASE ORDERS APP] DESELECT ALL PURCHASE ORDERS';
export const OPEN_NEW_PURCHASE_ORDER_DIALOG = '[PURCHASE ORDERS APP] OPEN NEW PURCHASE ORDER DIALOG';
export const CLOSE_NEW_PURCHASE_ORDER_DIALOG = '[PURCHASE ORDERS APP] CLOSE NEW PURCHASE ORDER DIALOG';
export const OPEN_EDIT_PURCHASE_ORDER_DIALOG = '[PURCHASE ORDERS APP] OPEN EDIT PURCHASE ORDER DIALOG';
export const UPDATE_EDIT_PURCHASE_ORDER_DIALOG = '[PURCHASE ORDERS APP] UPDATE EDIT PURCHASE ORDER DIALOG';
export const CLOSE_EDIT_PURCHASE_ORDER_DIALOG = '[PURCHASE ORDERS APP] CLOSE EDIT PURCHASE ORDER DIALOG';
export const ADD_PURCHASE_ORDER = '[PURCHASE ORDERS APP] ADD PURCHASE ORDER';
export const UPDATE_PURCHASE_ORDER = '[PURCHASE ORDERS APP] UPDATE PURCHASE ORDER';
export const REMOVE_PURCHASE_ORDER = '[PURCHASE ORDERS APP] REMOVE PURCHASE ORDER';
export const REMOVE_PURCHASE_ORDERS = '[PURCHASE ORDERS APP] REMOVE PURCHASE ORDERS';
export const TOGGLE_STARRED_PURCHASE_ORDER = '[PURCHASE ORDERS APP] TOGGLE STARRED PURCHASE ORDER';
export const TOGGLE_STARRED_PURCHASE_ORDERS = '[PURCHASE ORDERS APP] TOGGLE STARRED PURCHASE ORDERS';
export const SET_PURCHASE_ORDERS_STARRED = '[PURCHASE ORDERS APP] SET PURCHASE ORDERS STARRED ';

export function getPurchaseOrders(routeParams) {
    const request = axios.get('/api/purchase-orders-app/purchase-orders', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_PURCHASE_ORDERS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedPurchaseOrders(purchaseOrderId) {
    return {
        type: TOGGLE_IN_SELECTED_PURCHASE_ORDERS,
        purchaseOrderId
    }
}


export function selectAllPurchaseOrders(purchaseOrders) {
    return {
        type: SELECT_ALL_PURCHASE_ORDERS,
        selectedPurchaseOrderIds: purchaseOrders
    }
}

export function deSelectAllPurchaseOrders() {
    return {
        type: DESELECT_ALL_PURCHASE_ORDERS,
        selectedPurchaseOrderIds: []
    }
}

export function updatePurchaseOrders(purchaseOrders) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/PurchaseOrder/Batch`,
            purchaseOrders
        );

        return request.then((response) => {
            var ErrMsg = '';
            response.data.map(value => {
                if (value.Data.ErrMsg && value.Data.ErrMsg.length > 0) {
                    ErrMsg += `${value.Data.ErrMsg} | `;
                }
            });
            if (ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Selected purchase orders have been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        });
    };
}


export function openNewPurchaseOrderDialog(data) {
    return {
        type: OPEN_NEW_PURCHASE_ORDER_DIALOG,
        data
    }
}

export function closeNewPurchaseOrderDialog() {
    return {
        type: CLOSE_NEW_PURCHASE_ORDER_DIALOG
    }
}

export function openPreloadPurchaseOrderDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_PURCHASE_ORDER_DIALOG,
            data: data,
        })
    }
}

export function openEditPurchaseOrderDialog(data, readOnly) {
    return (dispatch, getState) => {

        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.get(`${window["apiLocation"]}/api/PurchaseOrder?Co=${data.Co}&PO=${encodeURIComponent(data.PO)}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_EDIT_PURCHASE_ORDER_DIALOG,
                data: response.data,
                readOnly
            })
        }).catch((e) => {
            dispatch({
                type: OPEN_EDIT_PURCHASE_ORDER_DIALOG,
                data: data,
                readOnly
            });
            dispatch(showMessage({
                message: `PO loaded from offline storage`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'offline'
            }));
        });
    }
}

export function updateEditPurchaseOrderDialog(data) {
    return {
        type: UPDATE_EDIT_PURCHASE_ORDER_DIALOG,
        data
    }
}

export function closeEditPurchaseOrderDialog() {
    return {
        type: CLOSE_EDIT_PURCHASE_ORDER_DIALOG
    }
}

export function addPurchaseOrder(newPurchaseOrder) {
    return (dispatch, getState) => {

        const po = _.cloneDeepWith(newPurchaseOrder);

        delete po.Data.Scopes;
        delete po.Data.Assignments;

        const { Co, line, WorkOrder, Scope, PO, POType, InventoryLocation } = po;
        if (line && line.Description && line.UOM && line.UnitCost && line.CostTotal) {
            po.Line = { ...line, Co, PO, POLineType: POType, WorkOrder, Scope, InventoryLocation, Data: { ErrMsg: null } };
            // dispatch(addPurchaseOrderLine());
        }

        const url = `${window["apiLocation"]}/api/PurchaseOrder`;
        const request = axios.post(url,
            po
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch({
                    type: ADD_PURCHASE_ORDER,
                    data: response.data
                })
                dispatch(showMessage({
                    message: `Purchase Order #${response.data.PO} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                dispatch(updateEditPurchaseOrderDialog({ ...response.data, value: 2, showTabs: true }));
                if (response.data.Data.Lines && response.data.Data.Lines[0]) {
                    dispatch({
                        type: ADD_PURCHASE_ORDER_LINE,
                        data: response.data.Data.Lines[0]
                    })
                }
            }
        }).catch((error) => {
            console.warn('Error adding PO: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...po, OfflineID: guid(), Data: { Lines: [], ErrMsg: null } };
                // const { Co, line, WorkOrder, Scope, POType, InventoryLocation } = offlineData;
                // if (line && line.Description && line.UOM && line.UnitCost && line.CostTotal) {
                //     dispatch(addPurchaseOrderLine({ ...line, Co, PO: offlineData.PO, POLineType: POType, WorkOrder, Scope, InventoryLocation, Data: { ErrMsg: null } }));
                // }
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'purchase-orders')),
                ]).then(() => {
                    dispatch({
                        type: ADD_PURCHASE_ORDER,
                        data: offlineData
                    });
                    dispatch(updateEditPurchaseOrderDialog({ ...offlineData, value: 2 }));
                })
            }
        });
    };
}

export function updatePurchaseOrder(purchaseOrder) {
    return (dispatch, getState) => {

        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.put(`${window["apiLocation"]}/api/PurchaseOrder?Co=${encodeURIComponent(purchaseOrder.Co)}&PO=${encodeURIComponent(purchaseOrder.PO)}`,
            purchaseOrder
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_PURCHASE_ORDER,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Purchase Order #${purchaseOrder.PO} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditPurchaseOrderDialog(response.data));
            })
        );
    };
}

export function removePurchaseOrder(purchaseOrder) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/PurchaseOrder?Co=${encodeURIComponent(purchaseOrder.Co)}&PO=${encodeURIComponent(purchaseOrder.PO)}`,
            purchaseOrder
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_PURCHASE_ORDER,
                    data: purchaseOrder
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Purchase Order #${purchaseOrder.PO} has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removePurchaseOrders(purchaseOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.post('/api/purchase-orders-app/remove-purchaseOrders', {
            purchaseOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_PURCHASE_ORDERS
                }),
                dispatch({
                    type: DESELECT_ALL_PURCHASE_ORDERS
                })
            ]).then(() => dispatch(getPurchaseOrders(routeParams)))
        );
    };
}

export function toggleStarredPurchaseOrder(purchaseOrderId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.post('/api/purchase-orders-app/toggle-starred-purchaseOrder', {
            purchaseOrderId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_PURCHASE_ORDER
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPurchaseOrders(routeParams)))
        );
    };
}

export function toggleStarredPurchaseOrders(purchaseOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.post('/api/purchase-orders-app/toggle-starred-purchaseOrders', {
            purchaseOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_PURCHASE_ORDERS
                }),
                dispatch({
                    type: DESELECT_ALL_PURCHASE_ORDERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPurchaseOrders(routeParams)))
        );
    };
}

export function setPurchaseOrdersStarred(purchaseOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.post('/api/purchase-orders-app/set-purchaseOrders-starred', {
            purchaseOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_PURCHASE_ORDERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_PURCHASE_ORDERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPurchaseOrders(routeParams)))
        );
    };
}

export function setPurchaseOrdersUnstarred(purchaseOrderIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().purchaseOrdersApp.purchaseOrders;

        const request = axios.post('/api/purchase-orders-app/set-purchaseOrders-unstarred', {
            purchaseOrderIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_PURCHASE_ORDERS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_PURCHASE_ORDERS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getPurchaseOrders(routeParams)))
        );
    };
}
