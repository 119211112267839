import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { Card, CardHeader, Icon, IconButton, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import _ from '@lodash';

const styles = theme => ({
    chat: {
        background: 'transparent',
        border: '1px solid lightgrey',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: 300,
        height: 384,
        marginRight: 16,
        position: 'absolute',
        bottom: 0,
    },
    fullScreen: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
    },
    closed: {
        background: 'transparent',
        border: '1px solid lightgrey',
        borderBottom: 'none',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: 300,
        marginRight: 12,
        position: 'absolute',
        bottom: 0,
    },
    smallScreen: {
        background: 'transparent',
        border: 0,
        boxShadow: '0px 0px 0px 0px',
        width: '100%',
        marginRight: 16,
    },
    smallScreenCard: {
        boxShadow: '0px 0px 0px 0px !important',
        borderBottom: '0 !important',
    },
    seperator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider
    },
    card: {
        cursor: 'pointer',
        width: '100%',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
    },
    cardHeader: {
        borderBottom: '1px solid lightgrey',
        boxShadow: '0px 0px 4px 0px #8f8f8f',
    },
    title: {
        fontWeight: 'bold',
    },
    subheader: {
        fontSize: 11,
    },
    actions: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    inactiveAvatar: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
    },
    unreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 2,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    inactiveUnreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 2,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#8f8f8f',
        color: '#fff',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    closedUnreadBadge: {
        minWidth: 20,
        height: 20,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    closedInactiveUnreadBadge: {
        minWidth: 20,
        height: 20,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#8f8f8f',
        color: '#fff',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    formControl: {
        marginBottom: 12
    },

});

class MinimizedDialogs extends Component {
    state = {
        open: null,
    };

    componentDidMount() {
        const { data } = this.props;
        this.setState({ data, open: Boolean(data.open) });
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        //window["warn"]("minimizedDialogs cdu", data)
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ data, open: Boolean(data.open) });
        }
    }

    formatPhone = (phone) => {
        const short = phone.substr(phone.length - 10, 10);
        return `(${short.substr(0, 3)}) ${short.substr(3, 3)}-${short.substr(6, 4)}`;
    }

    toggleOpen = () => {
        const { data } = this.props;
        this.setState({ ...this.state, open: null });

        let dialogList = [...this.props.minimizedDialogs];
        const dialogIndex = _.findIndex(dialogList, (o) => o.header === data.header)
        dialogList[dialogIndex].show = false;

        this.props.openMinimizedDialog(dialogList)

        window["warn"]("mini toggleOpen aaa", data)

        data.reopenFxn(this.props.data.dialogState);
    }

    close = (e) => {
        e.stopPropagation();
        const { data } = this.props;

        data.closeFxn(e);
    }

    render() {
        const { Co, classes, order, fullScreen, dialog, data } = this.props; //dialog
        const { open } = this.state;

        window["warn"]("minimizedDialogs render", data)

        return (
            <div style={{ right: fullScreen ? 0 : (312 * order)}} className={classNames(fullScreen ? classNames(classes.smallScreen, "full-screen-dialog") : open ? classes.smallScreen : classes.closed, "")}>
                <Card className={classNames(classes.card, fullScreen ? classes.smallScreenCard : "")}>
                    <CardHeader onClick={this.toggleOpen} classes={{ title: classes.title, subheader: classes.subheader, content: "truncate", avatar: "mr-6", action: "mt-0" }} className={classNames(classes.cardHeader, "conversation-header", "p-0 px-6", "highlight", fullScreen ? classes.smallScreenCard : "")}
                        avatar={
                            <Icon className="mt-8" color="primary">{data.icon}</Icon>
                        }
                        action={
                            <React.Fragment>
                                <IconButton onClick={this.close} classes={{ root: !open ? "px-6 py-0 mt-0" : "p-8" }} className={open ? "mt-8 mr-4" : "mt-8"}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </React.Fragment>
                        }
                        title={<Typography className="font-bold flex" style={{ alignItems: 'center' }}>{data.header}</Typography>}
                    />
                </Card>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openMinimizedDialog: Actions.openMinimizedDialog,
    }, dispatch);
}


function mapStateToProps({ spReducers, minimizedDialogApp }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MinimizedDialogs));