import axios from 'axios/index';

export const SET_TAX_CODE_DATA = '[SPCONNECTION] SET TAX CODE DATA';
export const GET_TAX_CODE_DATA = '[SPCONNECTION] GET TAX CODE DATA';
export const SUBSCRIBE_TAX_CODE_LIST = '[SPCONNECTION] SUBSCRIBE TAX CODE LIST';

export function getInitialTaxCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/TaxCode`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getTaxCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/TaxCode`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_TAX_CODE_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_TAX_CODE_LIST,
            Co
        }));
    }
}

export function setTaxCodeData(data) {
    return {
        type: SET_TAX_CODE_DATA,
        payload: data
    };
}