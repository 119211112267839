import _ from '@lodash';
import { Badge, Button, Card, Chip, Dialog, DialogContent, Divider, Drawer, Grow, Icon, IconButton, Menu, MenuItem, Slide, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import axios from 'axios';
import classNames from 'classnames';
import clsx from 'clsx';
import * as Actions from 'main/content/apps/dashboard-builder/store/actions';
import { SPRoundIcon } from 'main/icons';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { Component } from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import Media from 'react-media';
import connect from 'react-redux/es/connect/connect';
import 'react-resizable/css/styles.css';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../../components/autocomplete/Autocomplete';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import Chart from './Chart';
import DashboardComponentDialog from './DashboardComponentDialog';
import PivotTable from './PivotTable';
import Tile from './Tile';

const GridLayout = WidthProvider(Responsive);

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        color: '#333',
        backgroundColor: '#f9f9f9',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
        borderRadius: 6,
        minHeight: 'calc(100vh - 280px)',
    },
    component: {
        cursor: 'pointer',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        background: '#fff',
        color: '#333',
        boxShadow: theme.shadows[10]
    },
    newComponent: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    },
    dialogRoot: {

    },
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100vh - 64px)',
        top: 0,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    red: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    cardRoot: {
        padding: '0 !important',
        color: '#333',
    },
    cardContentRoot: {
        padding: '0 !important',
    },
    actionBadge: {
        width: 16,
        height: 16,
        minWidth: 16,
        marginRight: 2,
        marginTop: 4,
        fontSize: 10,
        lineHeight: 10,
    },
    touchScroll: {
        touchAction: 'auto !important'
    }
});

const newDashboardState = {
    ID: null,
    Co: null,
    Dashboard: null,
    Name: null,
    Notes: null,
    Options: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Form: null,
    Data: {
        ErrMsg: null,
        Components: [],
        Reports: [],
        DataSources: [],
    },
    dataSources: [],
    addReport: false,
    selectedReport: null,
    formParams: {},
    selectedParam: null,
    testDataSource: false,
    settingsDrawerOpen: false,
    editor: null,
    menuOpen: false,
    componentDialog: false,
    lockLayout: true,
    selectedComponent: null,
    timestamp: null,
    actionEl: null,
    actionEdit: undefined,
    actionUnassign: undefined,
    fetchingData: [],
    showFilters: false,
    selectedLayout: null,
    confirmDelete: false,
}

class Dashboard extends Component {

    state = {
        ..._.cloneDeepWith(newDashboardState)
    };

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.dashboard, this.props.dashboard) || !_.isEqual(nextState, this.state);
    }

    componentDidMount() {
        const { dashboard, data } = this.props;
        if (dashboard) {
            this.setState({ ..._.cloneDeepWith(newDashboardState), ...dashboard, formParams: { ...data } }, () => {
                if (data) {
                    window["warn"]('Dashboard Param data: ', data);
                    this.fetchData()
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { dashboard } = this.props;
        const { open } = _.cloneDeepWith(this.state);
        if (open === false) {
            this.setState({ ...dashboard }, () => window["warn"]('New Dashboard State: ', this.state));
        } else {
            if (!_.isEqual(dashboard, prevProps.dashboard)) {
                this.setState({ ...dashboard, menuOpen: false, componentDialog: false });
            }
        }
    }

    componentWillUnmount() {
        this.setState({ ..._.cloneDeepWith(newDashboardState), dataSources: [], open: false }, () => this.props.resetDashboard());
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped nowhere
        if (!destination) {
            return;
        }

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // // reordering list
        // if (result.type === 'list') {
        //     this.props.reorderList(result);
        // }

        // // reordering card
        // if (result.type === 'card') {
        //     this.props.reorderCard(result);
        // }
    };

    toggleSettingsDrawer = (state) => {
        state = (state === undefined) ? !this.state.settingsDrawerOpen : state;

        this.setState({
            settingsDrawerOpen: state
        });
    };

    setEditor = (editor) => {
        if (editor) {
            this.setState({ ...this.state, editor });
        }
    }

    updateDashboard = () => {
        const { updateDashboard } = this.props;
        updateDashboard(this.state);
        this.toggleMenu();
    }

    toggleMenu = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({ ...this.state, menuOpen: !this.state.menuOpen });
    }

    selectDataSource = (dataSource) => {
        const selectedReport = _.find(this.state.Data.Reports, { Report: dataSource.Report }) || dataSource.Data.Report;
        if (selectedReport && selectedReport.Data && selectedReport.Data.Parameters) {
            selectedReport.Data.Parameters.map((value) => {
                const initial = _.find(dataSource.Data.Parameters, { Parameter: value.Parameter });
                if (initial) {
                    value.Value = initial.Value;
                }
            });
            window["warn"]('Report: ', selectedReport);
        }
        this.setState({ ...this.state, selectedReport, addReport: false });
    }

    testDataSource = () => {
        const { testDataSource } = _.cloneDeepWith(this.state);
        const { Report, Parameters } = testDataSource;
        const request = axios.put(`${window["apiLocation"]}/api/Report?r=${Report}`, Parameters);
        request.then((response) => {
            testDataSource.Results = response.data;
            testDataSource.pivotBy = [];
            testDataSource.selectedColumns = [];
            testDataSource.columns = this.getColumns(response.data);
            window["warn"](response.data);
            this.setState({ ...this.state, testDataSource });
        });
    }

    getReportList = () => {
        const { Form } = _.cloneDeepWith(this.state);
        if (Form) {
            const request = axios.get(`${window["apiLocation"]}/api/Report?Form=${encodeURIComponent(Form)}`);
            request.then((response) => {
                this.setState({
                    ...this.state,
                    Data: {
                        ...this.state.Data,
                        Reports: response.data
                    }
                });
            });
        }
    }

    closeDataSource = () => {
        this.setState({ ...this.state, testDataSource: false });
    }

    setTestParameter = (option, value, field) => {
        const { testDataSource } = _.cloneDeepWith(this.state);
        const { Parameters } = testDataSource;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = option[field.value];
        } else {
            param = {
                Parameter: value.Parameter,
                Value: option[field.value]
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, testDataSource });
    }

    setTestParameterDate = (event, value) => {
        const { testDataSource } = _.cloneDeepWith(this.state);
        const { Parameters } = testDataSource;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = event._d;
        } else {
            param = {
                Parameter: value.Parameter,
                Value: event._d
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, testDataSource });
    }
    getTestParameter = (name) => {
        const { testDataSource } = _.cloneDeepWith(this.state);
        const { Parameters } = testDataSource;
        let param = _.find(Parameters, { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    getColumns(data) {
        return Object.keys(data[0]).map(key => {
            return {
                Header: key,
                headerStyle: {
                    fontWeight: 'bold'
                },
                accessor: key,
                id: key,
                dataType: !isNaN(data[0][key]) ? 'number' : 'text'
            };
        });
    }

    toggleColumn = (column) => {
        const { testDataSource } = _.cloneDeepWith(this.state);
        const { columns, selectedColumns } = testDataSource;
        const selected = _.findIndex(selectedColumns, (o) => { return o.Header === column.Header });
        if (selected > -1) {
            selectedColumns.splice(selected, 1);
        } else {
            column.aggregate = column.dataType === 'number' ? (vals) => _.round(_.sum(vals), 2) : (vals) => '';
            column.Cell = ({ value }) => {
                return (
                    <div className="w-full text-center">{value}</div>
                )
            }
            selectedColumns.push(column);
        }
        this.setState({ ...this.state, testDataSource });
    }

    getTechnician = (Technician) => {
        const { classes } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return `${value.FirstName} ${value.LastName}`;
        } else {
            return Technician;
        }
    }

    fetchData = () => {
        const { Data, formParams } = _.cloneDeepWith(this.state);
        const { Reports, DataSources } = Data;
        const fetchingData = [];
        // const form = _.find(this.props.securablesList, { form: Form });
        window["warn"]('Fetch Data: ', Data, formParams, Reports, DataSources);
        DataSources.map((src) => {
            const rpt = _.find(Reports, { Report: src.Report });
            let canRun = true;
            if (rpt) {
                const { Parameters } = rpt.Data;
                if (Parameters && Parameters.length > 0) {
                    Parameters.map((param) => {
                        const val = _.find(src.Data.Parameters, { Parameter: param.Parameter });
                        if (val && formParams[val.Value]) {
                            param.Value = formParams[val.Value];
                        } else {
                            const opt = formParams[param.Parameter];
                            if (opt) {
                                param.Value = opt;
                            } else {
                                if (param.RequiredYN === 'Y') {
                                    canRun = false;
                                }
                            }
                        }
                    });
                    if (canRun) {
                        const toFetch = {
                            Report: rpt.Report,
                            Parameters
                        };
                        fetchingData.push(toFetch);
                        this.setState({ ...this.state, fetchingData, menuOpen: false }, () => this.fetchReportData(toFetch));
                    }
                }
            }
        })
    }

    fetchReportData = (report) => {
        const { Report, Parameters } = report;
        const request = axios.put(`${window["apiLocation"]}/api/Report?r=${Report}`, Parameters);
        request.then((response) => {
            const { dataSources } = _.cloneDeepWith(this.state);
            let result = _.find(dataSources, { Report });
            if (result) {
                result.Results = response.data;
                result.Fetched = new Date();
            } else {
                result = {
                    Report,
                    Results: response.data,
                    Fetched: new Date()
                };
                dataSources.push(result);
            }
            window["warn"](report, dataSources);
            const { fetchingData } = _.cloneDeepWith(this.state);
            const index = _.findIndex(fetchingData, { Report });
            if (index > -1) {
                fetchingData.splice(index, 1);
            }
            this.setState({ ...this.state, dataSources, fetchingData });
        }).catch((error) => {
            const { fetchingData } = _.cloneDeepWith(this.state);
            const index = _.findIndex(fetchingData, { Report });
            if (index > -1) {
                fetchingData.splice(index, 1);
            }
            this.setState({ ...this.state, fetchingData });
        });
    }

    addComponent = (data) => {
        const { Co, Dashboard, Data } = _.cloneDeepWith(this.state);
        const { Components } = Data;
        const index = _.findIndex(Components, { Component: data.Component });
        if (!data.ID || index < 0) {
            this.props.addDashboardComponent(this.state, data);
        }
        window["warn"](Components, data);
        this.setState({ ...this.state, Data: { ...Data, Components } });
    }

    updateComponent = (data) => {
        const { Data } = _.cloneDeepWith(this.state);
        const { Components } = Data;
        const index = _.findIndex(Components, { Component: data.Component });
        window["warn"](data, index);
        if (index > -1) {
            Components[index].Data.Component = data;
            this.props.updateDashboardComponent(data);
        }
        this.setState({ ...this.state, Data: { ...Data, Components } });
    }

    handleLayout = (layout) => {
        let { Options, selectedLayout } = _.cloneDeepWith(this.state);
        if (!Options) {
            Options = {
                Layouts: {}
            };
        } else {
            Options = JSON.parse(Options);
            if (!Options.Layouts) {
                Options.Layouts = {};
            }
        }
        Options.Layouts[selectedLayout] = layout;
        this.setState({ ...this.state, Options: JSON.stringify(Options), timestamp: new Date() }, () => window.dispatchEvent(new Event('resize')));
    }

    toggleLayoutLock = () => {
        const { lockLayout } = _.cloneDeepWith(this.state);
        if (!lockLayout) {
            this.saveLayout();
        }
        this.setState({ ...this.state, lockLayout: !lockLayout, selectedLayout: null }, () => window.dispatchEvent(new Event('resize')));
    }

    saveLayout = () => {
        const { Co, Dashboard, Name, Form, Notes, Options, Data } = _.cloneDeepWith(this.state);
        if (!_.isEqual(Options, this.props.dashboard.Options)) {
            this.props.updateDashboard({
                Co,
                Dashboard,
                Name,
                Form,
                Notes,
                Options,
                Data
            })
        }
    }

    setAction = (actionEl, actionEdit, actionUnassign) => {
        this.setState({ ...this.state, actionEl, actionEdit, actionUnassign, });
    }

    editComponent = (component) => {
        this.setState({ ...this.state, selectedComponent: component, componentDialog: true, actionEl: null, actionEdit: null, actionUnassign: null, });
    }

    unassign = (component) => {
        const { Data } = _.cloneDeepWith(this.state);
        const { Co, Dashboard, Component } = component;
        const { Components } = Data;
        const index = _.findIndex(Components, { Co, Dashboard, Component });
        if (index > -1) {
            Components.splice(index, 1);
            this.setState({ ...this.state, actionEl: null, actionEdit: null, actionUnassign: null, Data: { ...Data, Components } }, () => this.props.removeDashboardComponent(component));
        }
    }
    getReportParameter = (name) => {
        const { formParams } = _.cloneDeepWith(this.state);
        return formParams[name];
    }

    setReportParameterDate = (event, value) => {
        const { formParams } = _.cloneDeepWith(this.state);
        if (event._d) {
            formParams[value.Parameter] = new Date(event._d).toLocaleDateString('en-US');
            this.setState({ ...this.state, formParams });
        }
    }

    setReportParameter = (event, value) => {
        const { formParams } = _.cloneDeepWith(this.state);
        formParams[value.Parameter] = event.target.value;
        this.setState({ ...this.state, formParams });
    }

    render() {
        const { classes, dashboard, deleteDashboard, username, securables, securablesList, master, filters, setDashboardFilters, setDashboardDrilled } = this.props;
        const { menuOpen, Co, Dashboard, Name, Form, showFilters, selectedReport, selectedParam, selectedLayout, addReport, Data, Notes, testDataSource, selectedFormParam, dataSources, componentDialog, lockLayout, timestamp, filterEl, actionEl, actionEdit, actionUnassign, fetchingData } = _.cloneDeepWith(this.state);
        let { Options } = _.cloneDeepWith(this.state);
        let layouts;
        if (Options) {
            Options = JSON.parse(Options);
            layouts = Options.Layouts;
        }
        const { Reports } = Data;
        const accessLevel = _.find(securables, { Securable: 'dashboard-builder' });
        const form = _.find(securablesList, { form: Form });
        const otherParams = [];
        Data.DataSources.map((ds) => {
            const rpt = _.find(Data.Reports, { Report: ds.Report });
            if (rpt && rpt.Data.Parameters) {
                rpt.Data.Parameters.map((param) => {
                    if (!_.find(ds.Data.Parameters, { Parameter: param.Parameter }) && !_.find(otherParams, { Parameter: param.Parameter })) {
                        otherParams.push(param);
                    }
                });
            }
            // ds.Data.Parameters.map((param) => {
            //     if (!_.find(form.fields,{value: param.value})) {
            //         allParams.push(param);
            //     };
            // });
        });
        window["warn"]('Other Parameters: ', otherParams)
        let canRun;
        // if (!testDataSource || !testDataSource.Parameters || !testDataSource.Report || !selectedReport || !selectedReport.Data || !selectedReport.Data.Parameters || _.filter(testDataSource.Parameters, (o) => {})
        if (!dashboard) {
            return (<div className={classNames(classes.root, "flex flex-1 flex-col h-full relative")}></div>);
        }
        return (
            <Media queries={{
                xs: "(max-width: 479px)", //phone
                sm: "(min-width: 480px) and (max-width: 995px)", //tablet
                md: "(min-width: 996px) and (max-width: 1199px)", //tablet_landscape
                lg: "(min-width: 1200px) and (max-width: 1599px", //laptop
                xl: "(min-width: 1600px)" //desktop
            }}>
                {matches =>
                    <div
                        className={classNames(classes.root, "flex flex-1 flex-col h-full relative lg:pl-4 overflow-x-hidden")}
                        ref={(root) => {
                            this.root = root;
                        }}
                    >
                        {/* <DashboardComponentDialog dataSources={dataSources} reports={[...Reports]} open={componentDialog} onClose={() => this.setState({ ...this.state, componentDialog: false, selectedComponent: null })} component={this.state.selectedComponent ? { ...this.state.selectedComponent } : null} onAdded={(comp) => this.addComponent(comp)} onUpdated={(comp) => this.updateComponent(comp)} /> */}
                        <div onClick={() => { if (menuOpen) { this.setState({ ...this.state, menuOpen: false }) } }} className="relative">
                            <Typography variant="h6" className="text-20 mt-8 float-left"><IconButton onClick={() => { if (this.props.onBack) { this.props.onBack(); } }} className="align-middle mb-4 ml-16 p-0"><Icon className="text-32" color="secondary">keyboard_arrow_left</Icon></IconButton>{this.state.Name}

                            </Typography>
                            <div className="pin-t pin-r absolute mr-8 flex">
                                {/* {dataSources.length > 0 &&
                                    <Tooltip placement="left" title={`Last Refreshed: ${moment(dataSources[0].Fetched).format('h:mm:ss A')}`}>
                                        <IconButton onClick={this.fetchData} className="mr-12" color="action">
                                            <Icon className={fetchingData.length > 0 ? "spin" : ""}>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                } */}
                                {Object.keys(filters).length > 0 &&
                                    <IconButton onClick={() => this.setState({ ...this.state, showFilters: !showFilters })} className="mr-12" color="action">
                                        <Badge classes={{ root: "", badge: classes.actionBadge }} badgeContent={Object.keys(filters).length} color="error">
                                            <Icon>filter_list</Icon>
                                        </Badge>
                                    </IconButton>
                                }
                                <IconButton onClick={this.toggleMenu} color="action">
                                    <Icon>menu</Icon>
                                </IconButton>
                            </div>
                            {Object.keys(filters).length > 0 && showFilters &&
                                <Card className="rounded-6 p-6 relative clear" style={{ margin: 30, marginBottom: 0, marginTop: 0 }}>
                                    <Typography className="w-full font-bold text-12 cursor-pointer truncate pr-36" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                        <Icon color="secondary" className="mr-4 align-middle mb-4">filter_list</Icon>
                                Active Filters
                            </Typography>
                                    <Tooltip placement="left" title="Clear All Filters">
                                        <IconButton
                                            style={{
                                                position: 'absolute',
                                                right: 7,
                                                top: 4,
                                            }}
                                            className="p-4"
                                            onClick={() => this.setState({ ...this.state, showFilters: false }, () => { setDashboardDrilled({}); setDashboardFilters({}); })}
                                        >
                                            <Icon className="text-20">close</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <div className="w-full mt-12 mb-6">
                                        <div className="flex w-full overflow-x-auto">
                                            {Object.keys(filters).map((filter) => {
                                                const fieldValue = ['Technician', 'TeamMember', 'TeamLead'].indexOf(filter) > -1 ? this.getTechnician(filters[filter]) : filter.indexOf('Date') > -1 ? moment(filters[filter]).format("M/D/YYYY") : filters[filter];
                                                return (
                                                    <div key={filter} className="float-left mr-8 flex">
                                                        <Chip color="secondary" className={classNames(classes.black, "rounded-r-none pr-4")} icon={<SPRoundIcon className={classes.colorSecondary} />} label={filter} />
                                                        <Chip icon={<Icon className={classes.colorWhite} style={{ marginLeft: -12, textShadow: '0px 0px 2px #333' }}>link</Icon>} color={"secondary"} className={classNames("rounded-l-none font-bold")} label={fieldValue} deleteIcon={<Icon>close</Icon>} onDelete={() => { delete filters[filter]; setDashboardFilters(filters); if (Object.keys(filters).length < 1) { this.setState({ ...this.state, filterEl: null }) } }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Card>
                            }
                            <Drawer
                                anchor="right"
                                variant="permanent"
                                className={clsx(classNames(classes.drawer, "pin-r absolute"), {
                                    [classes.drawerOpen]: menuOpen,
                                    [classes.drawerClose]: !menuOpen
                                })}
                                classes={{
                                    paper: clsx("relative border-none h-full", {
                                        [classes.drawerOpen]: menuOpen,
                                        [classes.drawerClose]: !menuOpen
                                    })
                                }}
                                open={menuOpen}
                                onClick={(e) => e.stopPropagation()}
                            >

                                <div
                                    tabIndex={0}
                                >
                                    <div className="p-12 relative">
                                        <Typography variant="h6" className="w-full font-bold text-16 mb-12 mt-4"><SPRoundIcon className="align-middle mr-4 mb-4" />Dashboard Parameters</Typography>
                                        <IconButton onClick={this.toggleMenu} color="inherit" className="pin-t pin-r absolute mt-4 mr-4 text-black">
                                            <Icon>close</Icon>
                                        </IconButton>
                                        {form && form.fields &&
                                            <div className="mb-8 w-full flex flex-wrap">
                                                {/* <Typography className="mb-4 w-full font-bold text-12">Dashboard Parameters:
                                                <Typography onClick={this.fetchData}
                                                        className="mb-4 float-right font-bold text-12 cursor-pointer"
                                                    >
                                                        <Icon className="align-middle mb-4 mr-4 text-16" color="primary">cloud_download</Icon>
                                                    Fetch Data
                                                </Typography>
                                                </Typography>
                                                <Divider className="mb-8" /> */}
                                                {form.fields.map((value) => {
                                                    window["warn"]('Parameter: ', value);
                                                    const paramVal = this.getReportParameter(value.value);
                                                    switch (value.type) {
                                                        case 'text':
                                                            {
                                                                if (value.list) {
                                                                    const options = _.get(master, value.list) || [];
                                                                    return (
                                                                        <Autocomplete
                                                                            className="mb-12 w-full"
                                                                            title={value.value}
                                                                            options={options}
                                                                            menuItemComponent={(option) => {
                                                                                let displayText;
                                                                                if (value.text && value.text.length > 0) {
                                                                                    value.text.map((name) => {
                                                                                        const text = _.get(option, name);
                                                                                        if (text) {
                                                                                            if (!displayText) {
                                                                                                displayText = '';
                                                                                            }
                                                                                            displayText += `${text} `;
                                                                                        }
                                                                                    })
                                                                                }
                                                                                return (
                                                                                    <MenuItem value={option ? option[value.listValue || value.value] : null}>
                                                                                        <div className="flex">
                                                                                            {`${option ? displayText || option[value.listValue || value.value] : 'N/A'}`}
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                );
                                                                            }}
                                                                            portal={true}
                                                                            value={paramVal}
                                                                            onSelect={(option) => this.setReportParameter(option, value)}
                                                                            disabled={Boolean(paramVal)}
                                                                            required={value.RequiredYN === 'Y'}
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TextField
                                                                            className="mb-12 w-full"
                                                                            label={value.value}
                                                                            id={`param${value.ID}`}
                                                                            value={paramVal}
                                                                            onChange={(e) => this.setReportParameterValue(e, value)}
                                                                            variant="outlined"
                                                                            disabled={Boolean(paramVal)}
                                                                            inputProps={{
                                                                                autoComplete: "off"
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                            break;
                                                        case 'number':
                                                            {
                                                                if (value.list) {
                                                                    const options = _.get(master, value.list) || [];
                                                                    return (
                                                                        <Autocomplete
                                                                            className="mb-12 w-full"
                                                                            title={value.value}
                                                                            options={options}
                                                                            menuItemComponent={(option) => {
                                                                                let displayText;
                                                                                if (value.text && value.text.length > 0) {
                                                                                    value.text.map((name) => {
                                                                                        const text = _.get(option, name);
                                                                                        if (text) {
                                                                                            if (!displayText) {
                                                                                                displayText = '';
                                                                                            }
                                                                                            displayText += `${text} `;
                                                                                        }
                                                                                    })
                                                                                }
                                                                                return (
                                                                                    <MenuItem value={option ? option[value.listValue || value.value] : null}>
                                                                                        <div className="flex">
                                                                                            {`${option ? displayText || option[value.listValue || value.value] : 'N/A'}`}
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                );
                                                                            }}
                                                                            portal={true}
                                                                            disabled={Boolean(paramVal)}
                                                                            value={paramVal}
                                                                            onSelect={(option) => this.setReportParameter(option, value)}
                                                                            required={value.RequiredYN === 'Y'}
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TextField
                                                                            className="mb-12 w-full"
                                                                            label={value.value}
                                                                            id={`param${value.ID}`}
                                                                            defaultValue={null}
                                                                            value={paramVal}
                                                                            onChange={(e) => this.setReportParameterValue(e, value)}
                                                                            variant="outlined"
                                                                            inputProps={{
                                                                                autoComplete: "off"
                                                                            }}
                                                                            InputProps={{
                                                                                type: 'number'
                                                                            }}
                                                                            disabled={Boolean(paramVal)}
                                                                            fullWidth
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                            break;
                                                        case 'date':
                                                            {
                                                                return (
                                                                    <DatePicker variant="inline" inputVariant="outlined"
                                                                        className="mb-12 w-full"
                                                                        label={value.value}
                                                                        format="MM/DD/YYYY"
                                                                        value={paramVal || null}
                                                                        showTodayButton
                                                                        onChange={(e) => this.setReportParameterDate(e, value)}
                                                                        required={value.RequiredYN === 'Y'}
                                                                    />
                                                                );
                                                            }
                                                            break;
                                                    }
                                                })}
                                                {otherParams.map((value) => {
                                                    const paramVal = this.getReportParameter(value.Parameter);
                                                    value.text = value.ListValueText ? JSON.parse(value.ListValueText) : []
                                                    switch (value.Type) {
                                                        case 'text':
                                                            {
                                                                if (value.List) {
                                                                    const options = _.get(master, value.List) || [];
                                                                    return (
                                                                        <Autocomplete
                                                                            className="mb-12 w-full"
                                                                            title={value.Description}
                                                                            options={options}
                                                                            menuItemComponent={(option) => {
                                                                                let displayText;
                                                                                if (value.text && value.text.length > 0) {
                                                                                    value.text.map((name) => {
                                                                                        const text = _.get(option, name);
                                                                                        if (text) {
                                                                                            if (!displayText) {
                                                                                                displayText = '';
                                                                                            }
                                                                                            displayText += `${text} `;
                                                                                        }
                                                                                    })
                                                                                }
                                                                                return (
                                                                                    <MenuItem value={option ? option[value.ListValue] : null}>
                                                                                        <div className="flex">
                                                                                            {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                );
                                                                            }}
                                                                            portal={true}
                                                                            value={paramVal}
                                                                            onSelect={(option) => this.setReportParameter(option, value)}
                                                                            required={value.RequiredYN === 'Y'}
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TextField
                                                                            className="mb-12 w-full"
                                                                            label={value.Description}
                                                                            id={`param${value.ID}`}
                                                                            value={paramVal}
                                                                            onChange={(e) => this.setReportParameterValue(e, value)}
                                                                            variant="outlined"
                                                                            inputProps={{
                                                                                autoComplete: "off"
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                            break;
                                                        case 'number':
                                                            {
                                                                if (value.List) {
                                                                    const options = _.get(master, value.List) || [];
                                                                    return (
                                                                        <Autocomplete
                                                                            className="mb-12 w-full"
                                                                            title={value.Description}
                                                                            options={options}
                                                                            menuItemComponent={(option) => {
                                                                                let displayText;
                                                                                if (value.text && value.text.length > 0) {
                                                                                    value.text.map((name) => {
                                                                                        const text = _.get(option, name);
                                                                                        if (text) {
                                                                                            if (!displayText) {
                                                                                                displayText = '';
                                                                                            }
                                                                                            displayText += `${text} `;
                                                                                        }
                                                                                    })
                                                                                }
                                                                                return (
                                                                                    <MenuItem value={option ? option[value.ListValue] : null}>
                                                                                        <div className="flex">
                                                                                            {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                );
                                                                            }}
                                                                            portal={true}
                                                                            value={paramVal}
                                                                            onSelect={(option) => this.setReportParameter(option, value)}
                                                                            required={value.RequiredYN === 'Y'}
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TextField
                                                                            className="mb-12 w-full"
                                                                            label={value.Description}
                                                                            id={`param${value.ID}`}
                                                                            value={paramVal}
                                                                            onChange={(e) => this.setReportParameterValue(e, value)}
                                                                            variant="outlined"
                                                                            inputProps={{
                                                                                autoComplete: "off"
                                                                            }}
                                                                            InputProps={{
                                                                                type: 'number'
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                            break;
                                                        case 'date':
                                                            {
                                                                return (
                                                                    <DatePicker variant="inline" inputVariant="outlined"
                                                                        className="mb-12 w-full"
                                                                        label={value.Description}
                                                                        format="MM/DD/YYYY"
                                                                        value={paramVal || null}
                                                                        showTodayButton
                                                                        onChange={(e) => this.setReportParameterDate(e, value)}
                                                                        required={value.RequiredYN === 'Y'}
                                                                    />
                                                                );
                                                            }
                                                            break;
                                                    }
                                                })}
                                            </div>
                                        }
                                        <Button
                                            variant="contained"
                                            className="mb-12"
                                            fullWidth
                                            color="primary"
                                            onClick={this.fetchData}
                                        >
                                            <Icon className="mr-6">refresh</Icon>Refresh Data
                                        </Button>
                                    </div>
                                </div>
                            </Drawer>
                            {dataSources && dataSources.length > 0 && fetchingData.length < 1 &&
                                <div className={classNames("clear m-12 mt-0 pb-24", selectedLayout ? `device-${selectedLayout}` : "")}>
                                    <Menu
                                        id="component-menu"
                                        anchorEl={actionEl}
                                        open={Boolean(actionEl)}
                                        onClose={() => this.setState({ ...this.state, actionEl: null, actionEdit: null, actionUnassign: null })}
                                    >
                                        <MenuItem onClick={actionEdit}>Edit</MenuItem>
                                        <MenuItem onClick={actionUnassign}>Remove</MenuItem>
                                    </Menu>
                                    <ResponsiveGridLayout
                                        className={classNames("layout w-full")}
                                        breakpoints={{ xl: 1600, lg: 1200, md: 996, sm: 480, xs: 0 }}
                                        cols={{ xl: 24, lg: 18, md: 12, sm: 8, xs: 2 }}
                                        rowHeight={64}
                                        compactType={"vertical"}
                                        margin={[16, 16]}
                                        measureBeforeMount={false}
                                        width={matches.xl ? 1800 : matches.lg ? 1600 : matches.md ? 1000 : matches.sm ? 750 : 350 }
                                        isDraggable={!lockLayout && selectedLayout}
                                        isResizable={!lockLayout && selectedLayout}
                                        onDragStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                                        onResizeStop={(layout, oldItem, newItem, placeholder, e, element) => this.handleLayout(layout)}
                                        onWidthChange={(w, m, c, p) => window["warn"]('RGL Resized: ', w, m, c, p)}
                                        layouts={layouts}
                                    >
                                        {Data.Components.map((component, index) => {
                                            const comp = component.Data.Component;
                                            const fetched = _.find(dataSources, { Report: comp.Report });
                                            const layout = matches.xl ? "xl" : matches.lg ? "lg" : matches.md ? "md" : matches.sm ? "sm" : "xs";
                                            const size = layouts && layouts[layout] ? [...layouts[layout]][index] : null;
                                            if (fetched) {
                                                switch (comp.Type) {
                                                    case 'P': {
                                                        return (
                                                            <Card key={component.Component} className="rounded-6" classes={{ root: classNames(classes.cardRoot, lockLayout ? classes.touchScroll : '') }} /*onClick={(e) => e.stopPropagation()}*/>
                                                                <Typography className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36">
                                                                    <Icon color="secondary" className="mr-4 align-middle mb-4">insert_chart</Icon>
                                                                    {comp.Name}
                                                                </Typography>
                                                                {/* <IconButton
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: 7,
                                                                        top: 4,
                                                                    }}
                                                                    className="p-4"
                                                                    onClick={(event) => {
                                                                        this.setAction(event.currentTarget, () => this.editComponent(comp), () => this.unassign(component));
                                                                    }}
                                                                >
                                                                    <Icon className="text-20">more_vert</Icon>
                                                                </IconButton> */}
                                                                {fetched && fetched.Results && fetched.Results.length > 0 ?
                                                                    <PivotTable key={component.Component} options={{ ...comp }} results={[...fetched.Results]} />
                                                                    :
                                                                    <React.Fragment>
                                                                        <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                                                            <Icon color="action" className="mr-4 align-middle mb-4 text-16">warning</Icon>No Results
                                                                    </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            </Card>
                                                        );
                                                    }
                                                        break;
                                                    case 'T': {
                                                        return (
                                                            <Card key={component.Component} className="rounded-6" classes={{ root: classNames(classes.cardRoot, lockLayout ? classes.touchScroll : '') }} /*onClick={(e) => e.stopPropagation()}*/>
                                                                <Typography className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                                    <Icon color="secondary" className="mr-4 align-middle mb-4">insert_chart</Icon>
                                                                    {comp.Name}
                                                                </Typography>
                                                                {/* <IconButton
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: 7,
                                                                        top: 4,
                                                                    }}
                                                                    className="p-4"
                                                                    onClick={(event) => {
                                                                        this.setAction(event.currentTarget, () => this.editComponent(comp), () => this.unassign(component));
                                                                    }}
                                                                >
                                                                    <Icon className="text-20">more_vert</Icon>
                                                                </IconButton> */}
                                                                {fetched && fetched.Results && fetched.Results.length > 0 ?
                                                                    <Tile key={component.Component} options={{ ...comp }} results={[...fetched.Results]} />
                                                                    :
                                                                    <React.Fragment>
                                                                        <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                                                            <Icon color="action" className="mr-4 align-middle mb-4 text-16">warning</Icon>No Results
                                                                </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            </Card>
                                                        );
                                                    }

                                                    case 'C': {
                                                        return (
                                                            <Card key={component.Component} id={`component${component.ID}`} className="rounded-6" classes={{ root: classNames(classes.cardRoot, lockLayout ? classes.touchScroll : '') }} /*onClick={(e) => e.stopPropagation()}*/>
                                                                {/* <IconButton
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: 7,
                                                                        top: 4,
                                                                    }}
                                                                    className="p-4"
                                                                    onClick={(event) => {
                                                                        this.setAction(event.currentTarget, () => this.editComponent(comp), () => this.unassign(component));
                                                                    }}
                                                                >
                                                                    <Icon className="text-20">more_vert</Icon>
                                                                </IconButton> */}
                                                                {fetched && fetched.Results && fetched.Results.length > 0 ?
                                                                    <Chart key={component.Component} title={comp.Name} height={size ? (size.h * 64) : undefined} width={size ? size.w : undefined} options={{ ...comp }} results={[...fetched.Results]} />
                                                                    :
                                                                    <React.Fragment>
                                                                        <Typography className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                                            <Icon color="secondary" className="mr-4 align-middle mb-4">insert_chart</Icon>
                                                                            {comp.Name}
                                                                        </Typography>
                                                                        <Typography className="pin-l pin-r pin-t pin-b absolute m-auto text-center text-12 h-24 w-128">
                                                                            <Icon color="action" className="mr-4 align-middle mb-4 text-16">warning</Icon>No Results
                                                                    </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            </Card>
                                                        );
                                                    }
                                                }
                                            }
                                        })}
                                    </ResponsiveGridLayout>
                                </div>
                            }
                        </div>
                    </div >
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDashboard: Actions.getDashboard,
        resetDashboard: Actions.resetDashboard,
        deleteDashboard: Actions.deleteDashboard,
        updateDashboard: Actions.updateDashboard,
        addDataSource: Actions.addDataSource,
        updateDataSource: Actions.updateDataSource,
        removeDataSource: Actions.removeDataSource,
        addDashboardComponent: Actions.addDashboardComponent,
        updateDashboardComponent: Actions.updateDashboardComponent,
        removeDashboardComponent: Actions.removeDashboardComponent,
        setDashboardFilters: Actions.setDashboardFilters,
        setDashboardDrilled: Actions.setDashboardDrilled,
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        master: spReducers,
        // dashboard: dashboardBuilderApp.dashboard,
        Co: spReducers.companies.Co,
        username: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
        securablesList: spReducers.userProfiles.Securables,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        filters: dashboardBuilderApp.dashboard.filters
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
