import ProfilePage from 'main/content/pages/profile/ProfilePage';

export const ProfilePageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/profile',
            component: ProfilePage
        }
    ]
};
