import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';
import history from 'history.js';

const styles = theme => ({
    root: {
        paddingBottom: 12,
        display                       : 'flex',
        alignItems                    : 'center',
        '& .logo-text, & .react-badge': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut
            })
        },
    },
    logo      : {},
    logoIcon: {
        width: '36px !important',
        height: 'auto',
        opacity: 1,
        marginTop: 8,
        transition: theme.transitions.create(['opacity', 'width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut
        }),
        marginRight: 6,
        marginLeft: 6
    },
    logoFolded: {
        width: 0,
        height: 0,
        opacity: 0,
        marginTop: 36,
        transition: theme.transitions.create(['opacity', 'width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut
        })
    },
    logoText: {
        width: 180,
        height: 'auto',
        marginTop: 16,
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut
        }),
        marginLeft: 8
    },
    reactBadge: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color          : '#61dafb'
    }
});

function MainNavbarHeader({classes})
{
    return (
        <div className={classes.root}>
            <div className={classNames(classes.logo, "flex items-center")}>
                <img onClick={() => history.push('/')} className={classNames(classes.logoFolded, "logo-folded cursor-pointer")} src="assets/images/logos/SPMarker_Gradient_Short.png" alt="logo"/>
                {/* <img onClick={() => history.push('/')} className={classNames(classes.logoIcon, "logo-icon cursor-pointer")} src="assets/images/logos/SP_Cog_Gradient.png" alt="logo"/> */}
                <img onClick={() => history.push('/')} className={classNames(classes.logoText, "logo-text cursor-pointer")} src="assets/images/logos/SPPro_green_new.png" alt="logo"/>
            </div>
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(withRouter(MainNavbarHeader));
