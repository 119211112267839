import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as WorkOrderActions from '../work-orders/store/actions';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CustomerSitesList extends Component {

    state = {
        selectedCustomerSitesMenu: null
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {

            return arr;
        }

        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCustomerSiteMenu = (event) => {
        this.setState({ selectedCustomerSitesMenu: event.currentTarget });
    };

    closeSelectedCustomerSitesMenu = () => {
        this.setState({ selectedCustomerSitesMenu: null });
    };

    render() {
        const { classes, securables, customerSites, user, searchText, selectedCustomerSiteIds, openNewCustomerSiteDialog, selectAllCustomerSites, deSelectAllCustomerSites, toggleInSelectedCustomerSites, openEditCustomerSiteDialog, toggleStarredCustomerSite, setCustomerSitesUnstarred, setCustomerSitesStarred, openNewWorkOrderDialog } = this.props;
        const data = !this.props.selectedCustomerSiteData ? this.getFilteredArray((!this.props.selectedCustomerSiteData ? (!this.props.customerSiteData ? [] : this.props.customerSiteData) : this.props.selectedCustomerSiteData), searchText) : this.props.selectedCustomerSiteData;
        const { selectedCustomerSitesMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "sites" });


        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" && !this.props.selectedCustomerSiteData && this.props.selectedCustomerData &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewCustomerSiteDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">location_on</Icon>Service Site List</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditCustomerSiteDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "Customer",
                                    accessor: "Customer",
                                    //filterable: true,
                                    className: "font-bold justify-center",
                                    width: 100
                                },
                                {
                                    Header: "Site",
                                    accessor: "Site",
                                    //filterable: true,
                                    className: "font-bold justify-center",
                                    width: 100
                                },
                                {
                                    Header: "Description",
                                    accessor: "Description",
                                    //filterable: true,
                                    width: 300
                                },
                                {
                                    Header: "Address",
                                    accessor: "Address1",
                                    //filterable: true,
                                    width: 300
                                },
                                {
                                    Header: "City",
                                    accessor: "City",
                                    //filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header: "State",
                                    accessor: "State",
                                    //filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header: "Zip",
                                    accessor: "Zip",
                                    //filterable: true,
                                    className: "justify-center",
                                },
                            ]
                        }
                        defaultPageSize={100}
                        noDataText="No Service Sites found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCustomerSites: Actions.getCustomerSites,
        getUserData: Actions.getUserData,
        toggleInSelectedCustomerSites: Actions.toggleInSelectedCustomerSites,
        selectAllCustomerSites: Actions.selectAllCustomerSites,
        deSelectAllCustomerSites: Actions.deSelectAllCustomerSites,
        openEditCustomerSiteDialog: Actions.openEditCustomerSiteDialog,
        toggleStarredCustomerSite: Actions.toggleStarredCustomerSite,
        toggleStarredCustomerSites: Actions.toggleStarredCustomerSites,
        setCustomerSitesStarred: Actions.setCustomerSitesStarred,
        setCustomerSitesUnstarred: Actions.setCustomerSitesUnstarred,
        openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
        openNewCustomerSiteDialog: Actions.openNewCustomerSiteDialog,
    }, dispatch);
}

function mapStateToProps({ spReducers, customerSitesApp }) {
    window["log"](spReducers, customerSitesApp);
    return {
        customerSites: customerSitesApp.customerSites.entities,
        selectedCustomerSiteIds: customerSitesApp.customerSites.selectedCustomerSiteIds,
        searchText: customerSitesApp.customerSites.searchText,
        user: customerSitesApp.user,
        customerSiteData: spReducers.customerSites,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSitesList)));
