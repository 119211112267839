import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography, Tooltip, Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import * as WorkOrderActions from '../work-orders/store/actions';
import * as DispatchActions from '../dispatch-board/store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import moment from 'moment';
import { DatePicker } from "@material-ui/pickers";
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class TechUAList extends Component {

    state = {
        selectedTechUAMenu: null,
        date: new Date(),
        loading: false,
    };

    componentDidMount() {
        let { date, loading, } = this.props;
        if (date) {
            this.setState({ ...this.state, date, loading, });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { date, loading, } = this.props;
        if (!_.isEqual(date, prevProps.date) || !_.isEqual(loading, prevProps.loading)) {
            this.setState({ ...this.state, date, loading });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDateInput(dt) {
        return dt.toLocaleDateString('en-US').replace(/\//g, '-');
    }

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    openDatePicker = (e) => {
        this.refs.datePicker.open(e);
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    render() {
        const { classes, securables, user, searchText, technicians, openEditTechUADialog, removeTechUA, } = this.props;
        const data = this.getFilteredArray((!this.props.selectedTechUAData ? !this.props.techUAData ? [] : this.props.techUAData : this.props.selectedTechUAData), searchText);
        const { selectedTechUAMenu, loading, date } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "dispatch-board" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <div className="sm:flex p-12 sm:p-0">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><Icon color="primary" className="text-32 mr-12">event_busy</Icon>
                            <span>Unavailable Time</span>
                        </Typography>
                    </div>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTechUADialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Technician",
                                accessor: "Technician",
                                minWidth: 256,
                                className: "justify-center",
                                Cell: row => {
                                    return technicians.map((value, index) => (
                                        value.Technician === row.original.Technician &&
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>
                                    ));
                                }
                            },
                            {
                                Header: "Start Date",
                                accessor: "StartDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.StartDate &&
                                    new Date(row.original.StartDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Start Time",
                                accessor: "StartTime",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.StartTime &&
                                    new Date(row.original.StartTime).toLocaleTimeString('en-US')
                                )
                            },
                            {
                                Header: "End Date",
                                accessor: "EndDate",
                                className: "justify-center",
                                minWidth: 192,
                                Cell: row => (
                                    row.original.EndDate &&
                                    new Date(row.original.EndDate).toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Duration",
                                accessor: "Duration",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    row.original.Duration && `${row.original.Duration.toFixed(2)} Hrs`
                                )
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                minWidth: 256,
                            },
                            {
                                Header: "All Day?",
                                accessor: "AllDayYN",
                                className: "justify-center",
                                minWidth: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Records found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewTechUADialog: Actions.openNewTechUADialog,
        openEditTechUADialog: Actions.openEditTechUADialog,
        removeTechUA: Actions.removeTechUATime,
    }, dispatch);
}

function mapStateToProps({ techUAApp, spReducers, dispatchBoardApp }) {
    return {
        techUA: techUAApp.techUA.entities,
        selectedTechUAIds: techUAApp.techUA.selectedTechUAIds,
        searchText: techUAApp.techUA.searchText,
        user: techUAApp.user,
        workOrderData: spReducers.workOrders,
        date: dispatchBoardApp.dispatchBoard.date,
        loading: dispatchBoardApp.dispatchBoard.loading,
        technicians: spReducers.technicians,
        techUA: dispatchBoardApp.dispatchBoard.techUA,
        schedule: dispatchBoardApp.dispatchBoard.schedule,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechUAList)));
