import * as Actions from '../actions';
import _ from '@lodash';
import { ADD_MATERIAL_TO_TRANSFER, UPDATE_MATERIAL_TO_TRANSFER, REMOVE_MATERIAL_TO_TRANSFER } from '../actions/gps-inventory-transfers.actions'

const initialState = {
    entities: [],
    searchText: '',
    selectedWorkOrderUsageIds: [],
    routeParams: {},
    gpsInventoryTransferDialog: {
        type: 'new',
        props: {
            open: false,
            readOnly: false
        },
        data: {
            ID: null,
            Co: null,
            Transfer: null,
            WorkOrder: null,
            workOrder: null,
            Scope: 1,
            Status: 0,
            Qty: null,
            Description: '',
            Material: null,
            Type: "I",
            material: {
                ID: null,
                Co: null,
                Type: "I",
                SourceLocation: null,
                NewLocation: null,
                WorkOrder: null,
                Scope: null,
                ApprovedQty: null,
                AcceptedQty: null,
                Employee: null,
                Qty: null,
                Material: null,
                Status: null,
                Description: '',
            },
            SourceLocation: null,
            NewLocation: null,
            Employee: null,
            Data: {
                Lines: [],
            },
            CreatedBy: null,
            CreatedDate: null,
            UpdatedBy: null,
            UpdatedDate: null,
            Notes: null,
            AttachmentID: null,
            UsageDate: new Date(),
        },
    }
};

const gpsInventoryTransfersReducer = function (state = initialState, action) {
    window["warn"]("gpsInventoryTransfersReducer", action, Actions)
    switch (action.type) {
        case Actions.OPEN_NEW_GPS_INVENTORY_TRANSFER_DIALOG:
            window["warn"]("OPEN_NEW_GPS_INVENTORY_TRANSFER_DIALOG ran!")
            {
                const { data } = action;
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: 'new',
                        props: {
                            open: true,
                        },
                        data: {
                            ...initialState.gpsInventoryTransferDialog.data,
                            ...data,
                        },
                    }
                };
            }
        case Actions.CLOSE_NEW_GPS_INVENTORY_TRANSFER_DIALOG:
            {
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_GPS_INVENTORY_TRANSFER_DIALOG:
            window["warn"]("OPEN_EDIT_GPS_INVENTORY_TRANSFER_DIALOG ran!")
            {
                const { data } = action;
                //const { data, taxability } = action;
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                        },
                        data: {
                            ...initialState.gpsInventoryTransferDialog.data,
                            ...data,
                        },
                    }
                };
            }
        case Actions.UPDATE_EDIT_GPS_INVENTORY_TRANSFER_DIALOG:
            {
                const { data, taxability, readOnly } = action;
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.gpsInventoryTransferDialog.data,
                            ...data,
                        },
                        taxability
                    }
                };
            }
        case Actions.CLOSE_EDIT_GPS_INVENTORY_TRANSFER_DIALOG:
            {
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case ADD_MATERIAL_TO_TRANSFER:
            {
                const { data } = action;
                let Lines = state.gpsInventoryTransferDialog.data.Data.Lines;
                Lines.push(data)
                let Data = {Lines: Lines}
                window["warn"]("aaffgg", action, data, Lines)
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            readOnly: false,
                        },
                        data: {
                            ...state.gpsInventoryTransferDialog.data,
                            //Lines
                            Data
                        },
                    }
                };
            }
        case UPDATE_MATERIAL_TO_TRANSFER:
            {
                const { data } = action;
                let Lines = state.gpsInventoryTransferDialog.data.Data.Lines;
                let index;
                if(data.tempID){
                    index = _.findIndex(Lines, (o) => o.tempID === data.tempID)
                } else {
                    index = _.findIndex(Lines, (o) => o.ID === data.ID)
                }
                
                Lines[index] = data
                let Data = {Lines: Lines}
                window["warn"]("aaffgg", action, data, Lines)
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: state.gpsInventoryTransferDialog.type,
                        props: {
                            open: true,
                            readOnly: false,
                        },
                        data: {
                            ...state.gpsInventoryTransferDialog.data,
                            //Lines
                            Data
                        },
                    }
                };
            }
        case REMOVE_MATERIAL_TO_TRANSFER:
            {
                const { data } = action;
                let Lines = state.gpsInventoryTransferDialog.data.Data.Lines;
                let index = _.findIndex(Lines, (o) => o.ID === data.ID)
                let pull = _.pull(Lines, Lines[index])
                let Data = {Lines: Lines}
                window["warn"]("aaffgg", action, data, Lines)
                return {
                    ...state,
                    gpsInventoryTransferDialog: {
                        type: state.gpsInventoryTransferDialog.type,
                        props: {
                            open: true,
                            readOnly: false,
                        },
                        data: {
                            ...state.gpsInventoryTransferDialog.data,
                            //Lines
                            Data
                        },
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default gpsInventoryTransfersReducer;
