import {combineReducers} from 'redux';
import technician from './technician.reducer';
import user from './user.reducer';

const technicianAppReducers = combineReducers({
    technician,
    user
});

export default technicianAppReducers;
