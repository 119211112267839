import axios from 'axios';
import { showMessage } from 'store/actions';
import _ from '@lodash';
import moment from 'moment';

export const SET_SEARCH_TEXT = '[PAYMENTS APP] SET SEARCH TEXT';
export const SET_LOADING = '[PAYMENTS APP] SET LOADING';
export const SET_PAYMENTS = '[PAYMENTS APP] SET PAYMENTS';
export const SET_PAYMENT_FILTERS = '[PAYMENTS APP] SET PAYMENT FILTERS';
export const SET_TRANSACTION = '[PAYMENTS APP] SET TRANSACTION';
export const SET_DEPOSITS = '[PAYMENTS APP] SET DEPOSITS';
export const SET_DEPOSIT = '[PAYMENTS APP] SET DEPOSIT';
export const SET_DEPOSIT_TRANSACTIONS = '[PAYMENTS APP] SET DEPOSIT TRANSACTIONS';

export function getPayments(Co, Page, customer) {

    return (dispatch, getState) => {
        const state = getState();
        const { filters, searchText } = state.paymentsApp.payments;
        // dispatch({
        //     type: SET_LOADING,
        //     loading: true,
        // });
        dispatch({
            type: SET_LOADING,
            loading: true,
        });
        let queryString = `?Co=${Co}&Page=${Page || 1}&${customer ? `CustomerID=${encodeURIComponent(customer)}&PerPage=10&Success=1` : ''}`;
        if (!customer && searchText && searchText.length > 0) {
            let searchArr = searchText.replace(/ /g, '|').split('|');
            searchArr.map((val) => {
                queryString = `${queryString}Keywords[]=${val.trim()}&`;
            })
        }
        if (!customer) {
            Object.keys(filters).map((value) => {
                if (filters[value]) {
                    queryString = `${queryString}${value}=${encodeURIComponent(filters[value])}&`
                }
            })
        }
        const request = axios.get(customer || filters.Type !== "deposit" ? `${window["apiLocation"]}/api/Omni/Transactions${queryString}${!customer && filters.Type ? 'TopLevel=1' : ''}` : `${window["apiLocation"]}/api/Omni/Deposits?Co=${Co}&StartDate=${encodeURIComponent(filters.StartDate)}&EndDate=${encodeURIComponent(filters.EndDate)}`);
        return request.then((response) => {
            if (filters.Type !== "deposit") {
                dispatch({
                    type: SET_PAYMENTS,
                    data: response.data,
                    filters,
                    loading: false
                });
            } else {
                dispatch({
                    type: SET_DEPOSITS,
                    data: response.data,
                    filters,
                    loading: false
                });
            }
        }).catch(() => {
            dispatch({
                type: SET_PAYMENTS,
                data: {},
                filters,
                loading: false
            });
            dispatch({
                type: SET_DEPOSITS,
                data: {},
                filters,
                loading: false
            });
        })
    }
}

export function getDeposit(Co, deposit) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_LOADING,
            loading: true,
        });
        const state = getState();
        const { filters } = state.paymentsApp.payments;
        const request = axios.get(`${window["apiLocation"]}/api/Omni/Deposit?Co=${Co}&id=${encodeURIComponent(deposit.batch_id)}&StartDate=${encodeURIComponent(moment.utc(deposit.batched_at).format("YYYY-MM-DD"))}&EndDate=${encodeURIComponent(moment.utc(deposit.batched_at).format("YYYY-MM-DD"))}`);
        return request.then((response) => {
            dispatch(setDeposit(response.data));
        }).catch((err) => {
            dispatch(setDeposit(null));
            window["warn"](err);
        })
    }
}

export function getDepositTransactions(Co, deposit) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_LOADING,
            loading: true,
        });
        const state = getState();
        const { filters } = state.paymentsApp.payments;
        const request = axios.get(`${window["apiLocation"]}/api/Omni/DepositTransactions?Co=${Co}&id=${encodeURIComponent(deposit.batch_id)}&StartDate=${encodeURIComponent(moment.utc(deposit.batched_at).format("YYYY-MM-DD"))}&EndDate=${encodeURIComponent(moment.utc(deposit.batched_at).format("YYYY-MM-DD"))}`);
        return request.then((response) => {
            dispatch(setDepositTransactions(response.data));
        }).catch((err) => {
            dispatch(setDepositTransactions(null));
            window["warn"](err);
        })
    }
}

export function setDepositTransactions(depositTransactions){
    return {
        type: SET_DEPOSIT_TRANSACTIONS,
        depositTransactions
    }
}

export function setDeposit(deposit) {
    return {
        type: SET_DEPOSIT,
        deposit
    }
}

export function setPayments(payments) {
    return (dispatch, getState) => {
        const state = getState();
        const { filters } = state.paymentsApp.payments;
        dispatch({
            type: SET_PAYMENTS,
            data: payments,
            filters,
            loading: false
        });
    }
}

export function setPaymentFilters(filters) {
    return {
        type: SET_PAYMENT_FILTERS,
        filters
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function setTransaction(transaction) {
    return {
        type: SET_TRANSACTION,
        transaction
    }
}