import _ from '@lodash'
import {
  Avatar,
  Button,
  Dialog,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { saveAs } from '@progress/kendo-file-saver'
import MailCompose from 'main/content/apps/mail/MailCompose'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { bindActionCreators } from 'redux'
import CacheManager from 'store/middlewares/cache'
import classNames from 'classnames'
import { formatPhone } from 'main/functions'

const offline = new CacheManager()

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  formControl: {
    marginBottom: 24,
    marginTop: 24,
  },
  customerSelect: {
    padding: 2,
    minHeight: 512,
    minWidth: '25%',
    border: '1px solid lightgrey',
    marginRight: 12,
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
  },
  customerCard: {
    borderRadius: 5,
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
  },
  customerAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 12,
    marginLeft: 8,
    height: 24,
  },
  chipLabel: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: '#fff',
    paddingBottom: 2,
  },
  chipAvatar: {
    marginLeft: -12,
    color: '#3f3f3f',
    backgroundColor: '#3f3f3f',
    border: '3px solid #3f3f3f',
  },
  chipAvatarIcon: {
    color: '#3f3f3f',
    backgroundColor: '#fff',
  },
  signature: {
    border: '1px solid #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  postedMatch: {
    color: theme.palette.secondary.dark,
  },
  postedNoMatch: {
    color: theme.palette.error[500],
  },
  notPosted: {
    color: '#4f4f4f',
  },
  postedBatch: {
    color: theme.palette.primary.main,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  unitAvatar: {
    width: 16,
    height: 16,
    marginLeft: 6,
    marginRight: 6,
  },
  checked: {
    backgroundColor: '#5f5f5f !important',
  },
  windowActual: {
    zIndex: 999,
  },
  beforeChecked: {
    backgroundColor: '#9f9f9f !important',
    zIndex: 0,
    width: '200% !important',
  },
  afterChecked: {
    // borderLeft: 'none !important',
    backgroundColor: '#9f9f9f !important',
    zIndex: 0,
    width: '200% !important',
    marginLeft: '-100%',
  },
})
const newAgreementFormState = {
  Signature: null,
  SignedBy: null,
  isSigning: false,
}

class AgreementFormDialog extends Component {
  state = { ...newAgreementFormState }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  closeComposeDialog = () => {
    let { open } = this.state
    open = false
    this.setState({ ...this.state, open })
  }

  canBeSubmitted() {
    const { AgreementFormNumber, Description } = this.state
    return (
      AgreementFormNumber &&
      Description &&
      AgreementFormNumber.length > 0 &&
      Description.length > 0
    )
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }
  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    const Signature = this.sigPad.getCanvas().toDataURL('image/png')
    this.setState(
      {
        ...this.state,
        isSigning: false,
      },
      () => this.props.onSignature(Signature),
    )
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
    }
  }

  // getPDF = () => {
  //     const input = document.getElementById('agreementForm-print');
  //     html2canvas(input)
  //         .then((canvas) => {
  //             const imgData = canvas.toDataURL('image/png');
  //             const pdf = new jsPDF();
  //             pdf.addImage(imgData, 'PNG', 0, 0);
  //             pdf.save(`${this.state.AgreementFormNumber}.pdf`);
  //         });
  // }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  exportToPDF = () => {
    const { Agreement, Cycle, Revision } = this.props.data
    let gridElement = document.getElementById('agreementForm-print')
    if (!window.cordova) {
      drawDOM(gridElement, { paperSize: 'letter', margin: 20, scale: 0.6 })
        .then(group => {
          return exportPDF(group)
        })
        .then(dataUri => {
          var data = dataUri.split(';base64,')[1]
          const blob = this.b64toBlob(data, 'application/pdf')
          saveAs(dataUri, `${Agreement}_${Cycle}_${Revision}.pdf`)
        })
    } else {
      const { cordova, pdf } = window
      var formCss = ''
      var styles = document.getElementsByTagName('style')
      for (var i = 0; i < styles.length; i++) {
        formCss += `<style>${styles[i].innerHTML}</style>`
      }
      formCss += '<style> .no-print { display:none !important; } </style>'
      let options = {
        documentSize: 'A4',
        type: 'share',
        fileName: `${Agreement}_${Cycle}_${Revision}.pdf`,
      }
      const html = document.getElementById('agreementForm-print').innerHTML
      pdf
        .fromData(
          `<html><head>${formCss}</head><body>${html}</body></html>`,
          options,
        )
        .then(stats => window['warn']('status', stats)) // it will
        .catch(err => console.err(err))
    }
  }

  emailAgreementForm = () => {
    const { data } = this.props;
    const { Agreement, Cycle, Revision } = data;
    let gridElement = document.getElementById('agreementForm-print');
    drawDOM(gridElement, { paperSize: "letter", margin: 20, scale: .6 }).then((group) => {
        return exportPDF(group);
    }).then((dataUri) => {
        const file = this.dataURLtoFile(dataUri, `${Agreement}_${Cycle}_${Revision}.pdf`);
        const agreementForm = this.buildAgreementFormEmail();
        const info = {
            composeDialog: true,
            Title: 'Email Agreement',
            Icon: "email",
            To: `${data.Data && data.Data.Customer ? data.Data.Customer.Email || data.Data.Customer.BillEmail : ''};`,
            Subject: `Your Maintenance Agreement #${Agreement} Cycle #${Cycle}`,
            Head: agreementForm.Head,
            Message: '',
            Body: agreementForm.Body,
            Attachments: [file]
        }
        this.setState({ ...this.state, agreementFormEmail: info, });
    });
}

  buildAgreementFormEmail = () => {
    const { Co } = this.props
    let Body = ``
    let Head = ``
    const data = {
      Head,
      Body,
    }
    return data
  }

  handleDateChange = event => {
    window['warn'](event)
    const AgreementFormDate = event._d
    this.setState({
      ...this.state,
      AgreementFormDate,
      DueDate: AgreementFormDate,
    })
  }

  openDatePicker = e => {
    this.datePicker.open(e)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer'>
      {new Date(this.state.AgreementFormDate).toLocaleDateString('en-US')}
    </span>
  )

  formatPhone(str) {
    return formatPhone(str).formatted
  }

  isOdd(num) {
    return Boolean(num % 2)
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  getTaskDue(task, assigned) {
    const { DateMedian, LeadTime, Frequency } = task
    // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    const freq = {
      A: 'Annual',
      S: 'Semi-Annual',
      Q: 'Quarterly',
      B: 'Bi-Monthly',
      M: 'Monthly',
      W: 'Weekly',
      O: 'One-Time',
    }
    var start = new Date(task.DateMedian)
    start.setDate(start.getDate() - LeadTime)
    var end = new Date(task.DateMedian)
    end.setDate(end.getDate() + LeadTime)
    var startMonth = start.getMonth()
    var endMonth = end.getMonth()
    var window =
      startMonth !== endMonth
        ? `${months[startMonth]}${endMonth - startMonth > 1 ? ' - ' : ' / '}${months[endMonth]
        }`
        : months[startMonth]
    return /*Frequency === 'A' ? window : */ freq[
      assigned ? assigned.Frequency || Frequency : Frequency
    ]
  }

  formatMfgDate = date => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    var dt = new Date(date)
    return date ? `${months[dt.getMonth()]} ${dt.getFullYear()}` : ''
  }

  render() {
    const {
      theme,
      classes,
      companies,
      businessUnits,
      divisions,
      agreementTypes,
      formOfPayments,
      data,
      open,
    } = this.props
    const {
      Co,
      BusinessUnit,
      Division,
      Department,
      AgreementType,
      MultiSiteYN,
      Data,
    } = data
    const { agreementFormEmail } = this.state
    const bu = _.find(businessUnits, { Co, BusinessUnit })
    const dv = _.find(divisions, { Co, BusinessUnit, Division })
    const at =
      data.Data && data.Data.AgreementType ? data.Data.AgreementType : null
    const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const allContacts = this.state.Data && this.state.Data.Customer && this.state.Data.Site && this.state.Data.Site.Data && this.state.Data.Customer.Data && this.state.Data.Site.Data.Contacts && Data.Customer.Data.Contacts ? _.uniqBy([...this.state.Data.Site.Data.Contacts, ...this.state.Data.Customer.Data.Contacts], contact => contact.Data.Contact.Email) : [];
    // const catSort = data.Data && data.Data.Tasks ? _.sortBy(data.Data.Tasks, (o) => { return o.Data && o.Data.Units ? o.Data.Units.length : 0 }) : [];
    // const total = sub + tax;
    // const User = !this.state.EnteredBy ? this.props.User : _.findLast(this.props.Users, (n) => { return n.UserName === this.state.EnteredBy });
    // const accessLevel = _.find(securables, { Securable: "agreementForms" });
    // const userAvatar = '../../assets/images/avatars/profile.jpg';
    if (!bu || !dv || !at || !data.Data || !data.Data.Customer) {
      return ''
    } else {
      const units = {}
      const { Site, Tasks, Sites } = data.Data
      const Units =
        MultiSiteYN !== 'Y'
          ? Site && Site.Data && Site.Data.Units
            ? Site.Data.Units
            : []
          : Data.Customer && Data.Customer.Data && Data.Customer.Data.Sites
            ? _.flatten(_.map(Data.Customer.Data.Sites, o => o.Data.Units))
            : []
      if (Tasks) {
        Tasks.map(task => {
          if (task.Data.Units) {
            task.Data.Units.map(Unit => {
              if (MultiSiteYN !== 'Y' && Site) {
                const unit = _.find(Units, {
                  Site: Site.Site,
                  UnitID: Unit.UnitID,
                })
                if (unit) {
                  if (!units[unit.UnitCat]) {
                    units[unit.UnitCat] = []
                  }
                  if (
                    !_.find(units[unit.UnitCat], {
                      Site: Site.Site,
                      UnitID: unit.UnitID,
                    })
                  ) {
                    units[unit.UnitCat].push(unit)
                  }
                }
              }
              if (MultiSiteYN === 'Y' && Sites) {
                Sites.map(st => {
                  const site = _.find(Data.Customer.Data.Sites, {
                    Site: st.Site,
                  })
                  if (site) {
                    const unit = _.find(Units, {
                      Site: site.Site,
                      UnitID: Unit.UnitID,
                    })
                    if (unit) {
                      if (!units[unit.UnitCat]) {
                        units[unit.UnitCat] = []
                      }
                      if (
                        !_.find(units[unit.UnitCat], {
                          Site: site.Site,
                          UnitID: unit.UnitID,
                        })
                      ) {
                        units[unit.UnitCat].push(unit)
                      }
                    }
                  }
                })
              }
            })
          }
        })
      }
      window['warn'](Units, units, Tasks)
      const cat =
        at && at.Data && at.Data.Tasks
          ? _.groupBy(
            _.sortBy(
              _.sortBy(
                _.filter(at.Data.Tasks, o => {
                  return (
                    _.findIndex(data.Data.Tasks, { TaskType: o.TaskType }) >
                    -1
                  )
                }),
                [
                  o => {
                    return new Date(o.DateMedian).getMonth()
                  },
                ],
              ),
              o => {
                return o.UnitCat || !o.OptionalYN || o.OptionalYN === 'N'
                  ? 1
                  : 2
              },
            ),
            o => {
              return o.UnitCat || 'Add-Ons'
            },
          )
          : []
      return (
        <React.Fragment>
          {agreementFormEmail && (
            <MailCompose
              contacts={allContacts}
              onClose={() =>
                this.setState({ ...this.state, agreementFormEmail: null })
              }
              hideButton={true}
              data={agreementFormEmail}
            />
          )}
          <IconButton
            style={{
              position: 'absolute',
              right: 160,
              top: 8,
              color: 'white',
              zIndex: 9999,
            }}
            onClick={() => {
              this.exportToPDF()
            }}
          >
            <Icon>save_alt</Icon>
          </IconButton>
          <Tooltip title='Email Agreement'>
            <IconButton
              style={{
                position: 'absolute',
                right: 104,
                top: 8,
                color: 'white',
                zIndex: 9999,
              }}
              onClick={() => {
                this.emailAgreementForm()
              }}
            >
              <Icon>email</Icon>
            </IconButton>
          </Tooltip>
          <div
            id='agreementForm-print'
            style={{ display: this.props.hidden ? 'none' : 'block' }}
            className='inv-print sm:ml-18 sm:mr-18 p-6 hiddenPrint'
          >
            <div className='w-full'>
              {/* <img className="w-full" src="assets/images/invoices/220SMInvoiceHeader.png" /> */}
              <strong className='uppercase mr-12 font-extrabold'>
                {at.Name}
              </strong>
              <strong className='uppercase font-extrabold'>#</strong>
              <strong className='uppercase mr-6'>{data.Agreement}</strong>
              <strong className='uppercase font-extrabold'>C</strong>
              <strong className='uppercase mr-6'>{data.Cycle}</strong>
              <strong className='uppercase font-extrabold'>R</strong>
              <strong className='uppercase mr-6'>{data.Revision}</strong>
              {dv.InvoiceHeaderImg && (
                <img
                  style={{ width: 'auto', height: 48, float: 'right' }}
                  src={dv.InvoiceHeaderImg}
                />
              )}
            </div>
            <div className='w-full mt-4'>
              <strong className='text-10 uppercase mr-4 font-extrabold'>
                {bu.Address1}
              </strong>
              <strong className='text-10 uppercase mr-4 font-extrabold'>
                |
              </strong>
              {bu.Address2 && (
                <React.Fragment>
                  <strong className='text-10 uppercase mr-4 font-extrabold'>
                    {bu.Address2}
                  </strong>
                  <strong className='text-10 uppercase mr-4 font-extrabold'>
                    |
                  </strong>
                </React.Fragment>
              )}
              <strong className='text-10 uppercase mr-4 font-extrabold'>{`${bu.City}, ${bu.State} ${bu.Zip}`}</strong>
              <strong className='text-10 uppercase mr-4 font-extrabold'>
                |
              </strong>
              <strong className='text-10 uppercase mr-4 font-extrabold'>
                {this.formatPhone(bu.Phone)}
              </strong>
            </div>
            <div className='w-full flex mt-8'>
              <div className='w-1/2'>
                <strong className='text-12 uppercase mr-4 font-extrabold'>
                  Customer:
                </strong>
                <strong className='text-12 mr-4'>{data.Customer}</strong>
              </div>
              <div className='w-1/2 text-right'>
                <strong className='text-12 uppercase mr-4 font-extrabold'>
                  Start Date:
                </strong>
                <strong className='text-12'>
                  {new Date(data.StartDate).toLocaleDateString('en-US')}
                </strong>
              </div>
            </div>
            <div
              className='w-full flex rounded'
              style={{ background: '#f1f1f1', border: '1px solid #333' }}
            >
              <div
                className='w-2/5 p-4'
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>
                  {data.Data.Customer.Name ||
                    `${data.Data.Customer.FirstName} ${data.Data.Customer.LastName}`}
                </strong>
              </div>
              <div
                className='w-1/5 p-4'
                style={{
                  borderLeft: '1px solid #333',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>
                  {this.formatPhone(data.Data.Customer.Phone)}
                </strong>
              </div>
              <div
                className='w-2/5 p-4'
                style={{
                  borderLeft: '1px solid #333',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>{data.Data.Customer.Email}</strong>
              </div>
            </div>
            <div className='w-full flex mt-4'>
              {data.Site ? (
                <div className='w-1/2'>
                  <strong className='text-12 uppercase mr-4 font-extrabold'>
                    Site:
                  </strong>
                  <strong className='text-12 mr-4'>{`${data.Site}`}</strong>
                </div>
              ) : (
                <div className='w-1/2'>
                  <strong className='text-12 uppercase mr-4 font-extrabold'>
                    Bill To:
                  </strong>
                  <strong className='text-12 mr-4'>
                    {data.Data.Customer.Name ||
                      `${data.Data.Customer.FirstName} ${data.Data.Customer.LastName}`}
                  </strong>
                </div>
              )}
              {data.EndDate && (
                <div className='w-1/2 text-right'>
                  <strong className='text-12 uppercase mr-4 font-extrabold'>
                    End Date:
                  </strong>
                  <strong className='text-12'>
                    {new Date(data.EndDate).toLocaleDateString('en-US')}
                  </strong>
                </div>
              )}
            </div>
            {data.Site && (
              <div
                className='w-full flex rounded'
                style={{ background: '#f1f1f1', border: '1px solid #333' }}
              >
                <div
                  className='w-2/5 p-4'
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <strong className='text-12'>
                    {data.Data.Site.Address1}
                    {data.Data.Site.Address2 && ` ${data.Data.Site.Address2}`}
                  </strong>
                </div>
                <div
                  className='w-1/5 p-4'
                  style={{
                    borderLeft: '1px solid #333',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <strong className='text-12'>{data.Data.Site.City}</strong>
                </div>
                <div
                  className='w-1/5 p-4'
                  style={{
                    borderLeft: '1px solid #333',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <strong className='text-12'>{data.Data.Site.State}</strong>
                </div>
                <div
                  className='w-1/5 p-4'
                  style={{
                    borderLeft: '1px solid #333',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <strong className='text-12'>{data.Data.Site.Zip}</strong>
                </div>
              </div>
            )}
            {data.Site && (
              <div className='w-full flex mt-4'>
                <div className='w-1/2'>
                  <strong className='text-12 uppercase mr-4 font-extrabold'>
                    Bill To:
                  </strong>
                  <strong className='text-12 mr-4'>
                    {data.Data.Customer.Name ||
                      `${data.Data.Customer.FirstName} ${data.Data.Customer.LastName}`}
                  </strong>
                </div>
              </div>
            )}
            <div
              className='w-full flex rounded'
              style={{ background: '#f1f1f1', border: '1px solid #333' }}
            >
              <div
                className='w-2/5 p-4'
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>
                  {data.Data.Customer.Address1}
                  {data.Data.Customer.Address2 &&
                    ` ${data.Data.Customer.Address2}`}
                </strong>
              </div>
              <div
                className='w-1/5 p-4'
                style={{
                  borderLeft: '1px solid #333',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>{data.Data.Customer.City}</strong>
              </div>
              <div
                className='w-1/5 p-4'
                style={{
                  borderLeft: '1px solid #333',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>{data.Data.Customer.State}</strong>
              </div>
              <div
                className='w-1/5 p-4'
                style={{
                  borderLeft: '1px solid #333',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <strong className='text-12'>{data.Data.Customer.Zip}</strong>
              </div>
            </div>
            <div className='w-full sm:flex'>
              <div className='w-full'>
                {Object.keys(cat).map((t, index) => {
                  // var task = _.find(at.Data.Tasks, { TaskType: t.TaskType });
                  var even = !this.isOdd(index)
                  if (even) {
                    var tasks = cat[t]
                    var next = Object.keys(cat)[index + 1]
                    var nextTasks = cat[next]
                    // var nextTask = _.find(at.Data.Tasks, { TaskType: next.TaskType });
                    return (
                      <div key={index} className={`pt-12 w-full`}>
                        <strong
                          className='text-12 uppercase mr-4'
                          style={{ fontWeight: 800 }}
                        >
                          {t}
                        </strong>
                        <div
                          className='w-full flex min-h-96'
                          style={{ border: '1px solid #333', borderRadius: 3 }}
                        >
                          <div
                            className='w-64'
                            style={{
                              background: '#4f4f4f',
                              color: '#fff',
                              borderRight: '1px solid #fff',
                            }}
                          >
                            <img
                              src='/assets/images/logos/SPMarker_Gradient.png'
                              style={{
                                width: 36,
                                margin: 10,
                                background: 'transparent',
                              }}
                            />
                            {/* <SPRoundIcon className="w-full mt-12 text-36" /> */}
                          </div>
                          <div className='w-full max-w-full overflow-hidden'>
                            {tasks &&
                              tasks.map((task, i) => {
                                var assigned = _.find(data.Data.Tasks, {
                                  TaskType: task.TaskType,
                                })
                                var included = assigned.Data.Units
                                return (
                                  <React.Fragment>
                                    <div
                                      key={i}
                                      className='w-full'
                                      style={{
                                        background: '#4f4f4f',
                                        color: '#fff',
                                      }}
                                    >
                                      <div className='w-full relative'>
                                        <strong className='text-10 m-4 uppercase'>
                                          {task.Name}
                                        </strong>
                                        <strong
                                          className={`text-10 m-4 uppercase absolute pin-r`}
                                        >
                                          {this.getTaskDue(task, assigned)}
                                        </strong>
                                      </div>
                                      <div
                                        className='w-full relative'
                                        style={{ marginTop: -8 }}
                                      >
                                        <label className='text-10 m-4 capitalize'>
                                          {task.Description}
                                        </label>
                                        {task.OptionalYN === 'Y' &&
                                          t === 'Add-Ons' && (
                                            <label
                                              className={`text-10 m-4 uppercase absolute pin-r`}
                                            >
                                              {this.formatDollars(task.Revenue)}
                                            </label>
                                          )}
                                      </div>
                                    </div>
                                    <div className='w-full max-w-full overflow-hidden'>
                                      <div className='w-full flex' style={{}}>
                                        {months.map((month, mindex) => {
                                          const checked = _.find(
                                            assigned.Data.Recurrences,
                                            o => {
                                              return (
                                                moment(o.Date).month() ===
                                                mindex
                                              )
                                            },
                                          )
                                          const before = _.find(
                                            assigned.Data.Recurrences,
                                            o => {
                                              const due = moment(o.Date)
                                              const start = moment(
                                                due.startOf('month'),
                                              ).subtract(task.LeadTime, 'days')
                                              return (
                                                due.month() > mindex &&
                                                (start.month() <= mindex ||
                                                  start.year() < due.year())
                                              )
                                            },
                                          )
                                          const after = _.find(
                                            assigned.Data.Recurrences,
                                            o => {
                                              const due = moment(o.Date)
                                              const end = moment(
                                                due.endOf('month'),
                                              ).add(task.LeadTime, 'days')
                                              return (
                                                due.month() < mindex &&
                                                (end.month() >= mindex ||
                                                  end.year() > due.year())
                                              )
                                            },
                                          )
                                          window['warn'](
                                            'Checked: ',
                                            task.LeadTime,
                                            checked,
                                            before,
                                            after,
                                          )
                                          return (
                                            <div
                                              key={mindex}
                                              className={classNames(
                                                'w-full p-4 text-center text-10 font-bold',
                                                checked && classes.windowActual,
                                              )}
                                              style={{
                                                borderBottom: '1px solid #333',
                                                borderRight:
                                                  mindex < 11
                                                    ? '1px solid #333'
                                                    : 'none',
                                              }}
                                            >
                                              {month}
                                              <div
                                                className={classNames(
                                                  'w-full rounded bg-grey-light',
                                                  checked
                                                    ? classes.checked
                                                    : before
                                                      ? classes.beforeChecked
                                                      : after
                                                        ? classes.afterChecked
                                                        : '',
                                                )}
                                                style={{ minHeight: 6 }}
                                              ></div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    <div className='w-full'>
                                      {MultiSiteYN === 'Y' &&
                                        Sites &&
                                        Sites.map(st => {
                                          const site = _.find(
                                            Data.Customer.Data.Sites,
                                            { Site: st.Site },
                                          )
                                          if (site) {
                                            return (
                                              <div
                                                style={{
                                                  borderBottom:
                                                    '1px solid lightgrey',
                                                }}
                                              >
                                                <div className='flex pt-6'>
                                                  <img
                                                    className={
                                                      classes.unitAvatar
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                    alt='Site Icon'
                                                    src={
                                                      '/assets/images/logos/SPMarker_Gradient.png'
                                                    }
                                                  />
                                                  <strong className='text-10 m-4 uppercase truncate'>{`${site.Address1} ${site.City}, ${site.State} ${site.Zip}`}</strong>
                                                </div>
                                                {included &&
                                                  _.filter(included, {
                                                    Site: site.Site,
                                                  }).map((uid, index) => {
                                                    const unit = _.find(
                                                      site.Data.Units,
                                                      {
                                                        Site: site.Site,
                                                        UnitID: uid.UnitID,
                                                      },
                                                    )
                                                    return (
                                                      <div
                                                        key={index}
                                                        className='w-full flex p-4 px-0 ml-24'
                                                        style={{
                                                          borderTop:
                                                            '1px solid lightgrey',
                                                          width:
                                                            'calc(100% - 25px)',
                                                          borderLeft:
                                                            '1px solid lightgrey',
                                                          backgroundColor:
                                                            '#f9f9f9',
                                                          borderTopLeftRadius:
                                                            index > 0 ? 0 : 4,
                                                          marginTop:
                                                            index > 0 ? 0 : 6,
                                                        }}
                                                      >
                                                        <Avatar
                                                          className={
                                                            classes.unitAvatar
                                                          }
                                                          style={{
                                                            cursor: 'pointer',
                                                            boxShadow:
                                                              '2px 2px 4px 0px #3f3f3f',
                                                            marginTop: 5,
                                                          }}
                                                          alt='Service Unit Image'
                                                          src={
                                                            unit.Data &&
                                                              unit.Data.Avatar
                                                              ? !isNaN(
                                                                unit.Data
                                                                  .Avatar,
                                                              )
                                                                ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${unit.Co}&ID=${unit.Data.Avatar}`
                                                                : unit.Data
                                                                  .Avatar
                                                              : unit.avatar
                                                                ? unit.avatar
                                                                : avatar
                                                          }
                                                        />
                                                        <div className='w-full text-left'>
                                                          <div className='w-full'>
                                                            <strong className='text-10 m-4 uppercase'>{`${unit.UnitID} - ${unit.Name}`}</strong>
                                                          </div>
                                                          {unit.Manufacturer && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Manufacturer:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${unit.Manufacturer}`}</label>
                                                            </div>
                                                          )}
                                                          {unit.Model && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Model:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${unit.Model}`}</label>
                                                            </div>
                                                          )}
                                                          {unit.SerialNumber && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Serial #:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${unit.SerialNumber}`}</label>
                                                            </div>
                                                          )}
                                                          {unit.ManufactureDate && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Mfg Date:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${this.formatMfgDate(
                                                                unit.ManufactureDate,
                                                              )}`}</label>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                              </div>
                                            )
                                          }
                                        })}
                                      {MultiSiteYN !== 'Y' &&
                                        included &&
                                        included.map((uid, index) => {
                                          const unit = _.find(
                                            Data.Site.Data.Units,
                                            { UnitID: uid.UnitID },
                                          )
                                          return (
                                            <div
                                              key={index}
                                              className='w-full flex mb-6 mt-6'
                                            >
                                              <Avatar
                                                className={classes.unitAvatar}
                                                style={{
                                                  cursor: 'pointer',
                                                  boxShadow:
                                                    '2px 2px 4px 0px #3f3f3f',
                                                  marginTop: 5,
                                                }}
                                                alt='Service Unit Image'
                                                src={
                                                  unit.Data && unit.Data.Avatar
                                                    ? !isNaN(unit.Data.Avatar)
                                                      ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${unit.Co}&ID=${unit.Data.Avatar}`
                                                      : unit.Data.Avatar
                                                    : unit.avatar
                                                      ? unit.avatar
                                                      : avatar
                                                }
                                              />
                                              <div className='w-full text-left'>
                                                <div className='w-full'>
                                                  <strong className='text-10 m-4 uppercase'>{`${unit.UnitID} - ${unit.Name}`}</strong>
                                                </div>
                                                {unit.Manufacturer && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Manufacturer:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${unit.Manufacturer}`}</label>
                                                  </div>
                                                )}
                                                {unit.Model && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Model:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${unit.Model}`}</label>
                                                  </div>
                                                )}
                                                {unit.SerialNumber && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Serial #:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${unit.SerialNumber}`}</label>
                                                  </div>
                                                )}
                                                {unit.ManufactureDate && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Mfg Date:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${this.formatMfgDate(
                                                      unit.ManufactureDate,
                                                    )}`}</label>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </React.Fragment>
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              <div className='sm:min-w-24 sm:min-h-96'></div>
              <div className='w-full'>
                {Object.keys(cat).map((t, index) => {
                  // var task = _.find(at.Data.Tasks, { TaskType: t.TaskType });
                  var odd = this.isOdd(index)
                  if (odd) {
                    var tasks = cat[t]
                    var next = Object.keys(cat)[index + 1]
                    var nextTasks = cat[next]
                    // var nextTask = _.find(at.Data.Tasks, { TaskType: next.TaskType });
                    return (
                      <div key={index} className={`pt-12 w-full`}>
                        <strong
                          className='text-12 uppercase mr-4'
                          style={{ fontWeight: 800 }}
                        >
                          {t}
                        </strong>
                        <div
                          className='w-full flex min-h-96'
                          style={{ border: '1px solid #333', borderRadius: 3 }}
                        >
                          <div
                            className='w-64'
                            style={{
                              background: '#4f4f4f',
                              color: '#fff',
                              borderRight: '1px solid #fff',
                            }}
                          >
                            <img
                              src='/assets/images/logos/SPMarker_Gradient.png'
                              style={{
                                width: 36,
                                margin: 10,
                                background: 'transparent',
                              }}
                            />
                            {/* <SPRoundIcon className="w-full mt-12 text-36" /> */}
                          </div>
                          <div className='w-full max-w-full overflow-hidden'>
                            {tasks &&
                              tasks.map((task, i) => {
                                var assigned = _.find(data.Data.Tasks, {
                                  TaskType: task.TaskType,
                                })
                                var included = assigned.Data.Units
                                return (
                                  <React.Fragment>
                                    <div
                                      key={i}
                                      className='w-full'
                                      style={{
                                        background: '#4f4f4f',
                                        color: '#fff',
                                      }}
                                    >
                                      <div className='w-full relative'>
                                        <strong className='text-10 m-4 uppercase'>
                                          {task.Name}
                                        </strong>
                                        <strong
                                          className={`text-10 m-4 uppercase absolute pin-r`}
                                        >
                                          {this.getTaskDue(task)}
                                        </strong>
                                      </div>
                                      <div
                                        className='w-full relative'
                                        style={{ marginTop: -8 }}
                                      >
                                        <label className='text-10 m-4 capitalize'>
                                          {task.Description}
                                        </label>
                                        {task.OptionalYN === 'Y' &&
                                          t === 'Add-Ons' && (
                                            <label
                                              className={`text-10 m-4 uppercase absolute pin-r`}
                                            >
                                              {this.formatDollars(task.Revenue)}
                                            </label>
                                          )}
                                      </div>
                                    </div>
                                    <div className='w-full max-w-full overflow-hidden'>
                                      <div className='w-full flex' style={{}}>
                                        {months.map((month, mindex) => {
                                          const checked = _.find(
                                            assigned.Data.Recurrences,
                                            o => {
                                              return (
                                                moment(o.Date).month() ===
                                                mindex
                                              )
                                            },
                                          )
                                          const before = _.find(
                                            assigned.Data.Recurrences,
                                            o => {
                                              const due = moment(o.Date)
                                              const start = moment(
                                                due.startOf('month'),
                                              ).subtract(task.LeadTime, 'days')
                                              return (
                                                due.month() > mindex &&
                                                (start.month() <= mindex ||
                                                  start.year() < due.year())
                                              )
                                            },
                                          )
                                          const after = _.find(
                                            assigned.Data.Recurrences,
                                            o => {
                                              const due = moment(o.Date)
                                              const end = moment(
                                                due.endOf('month'),
                                              ).add(task.LeadTime, 'days')
                                              return (
                                                due.month() < mindex &&
                                                (end.month() >= mindex ||
                                                  end.year() > due.year())
                                              )
                                            },
                                          )
                                          window['warn'](
                                            'Checked: ',
                                            task.LeadTime,
                                            checked,
                                            before,
                                            after,
                                          )
                                          return (
                                            <div
                                              key={mindex}
                                              className={classNames(
                                                'w-full p-4 text-center text-10 font-bold',
                                                checked && classes.windowActual,
                                              )}
                                              style={{
                                                borderBottom: '1px solid #333',
                                                borderRight:
                                                  mindex < 11
                                                    ? '1px solid #333'
                                                    : 'none',
                                              }}
                                            >
                                              {month}
                                              <div
                                                className={classNames(
                                                  'w-full rounded bg-grey-light',
                                                  checked
                                                    ? classes.checked
                                                    : before
                                                      ? classes.beforeChecked
                                                      : after
                                                        ? classes.afterChecked
                                                        : '',
                                                )}
                                                style={{ minHeight: 6 }}
                                              ></div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    <div className='w-full'>
                                      {MultiSiteYN === 'Y' &&
                                        Sites &&
                                        Sites.map(st => {
                                          const site = _.find(
                                            Data.Customer.Data.Sites,
                                            { Site: st.Site },
                                          )
                                          if (site) {
                                            return (
                                              <div
                                                style={{
                                                  borderBottom:
                                                    '1px solid lightgrey',
                                                }}
                                              >
                                                <div className='flex pt-6'>
                                                  <img
                                                    className={
                                                      classes.unitAvatar
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                      height: 'auto',
                                                    }}
                                                    alt='Site Icon'
                                                    src={
                                                      '/assets/images/logos/SPMarker_Gradient.png'
                                                    }
                                                  />
                                                  <strong className='text-10 m-4 uppercase truncate'>{`${site.Address1} ${site.City}, ${site.State} ${site.Zip}`}</strong>
                                                </div>
                                                {included &&
                                                  _.filter(included, {
                                                    Site: site.Site,
                                                  }).map((uid, index) => {
                                                    const unit = _.find(
                                                      site.Data.Units,
                                                      {
                                                        Site: site.Site,
                                                        UnitID: uid.UnitID,
                                                      },
                                                    )
                                                    return (
                                                      <div
                                                        key={index}
                                                        className='w-full flex p-4 px-0 ml-24'
                                                        style={{
                                                          borderTop:
                                                            '1px solid lightgrey',
                                                          width:
                                                            'calc(100% - 25px)',
                                                          borderLeft:
                                                            '1px solid lightgrey',
                                                          backgroundColor:
                                                            '#f9f9f9',
                                                          borderTopLeftRadius:
                                                            index > 0 ? 0 : 4,
                                                          marginTop:
                                                            index > 0 ? 0 : 6,
                                                        }}
                                                      >
                                                        <Avatar
                                                          className={
                                                            classes.unitAvatar
                                                          }
                                                          style={{
                                                            cursor: 'pointer',
                                                            boxShadow:
                                                              '2px 2px 4px 0px #3f3f3f',
                                                            marginTop: 5,
                                                          }}
                                                          alt='Service Unit Image'
                                                          src={
                                                            unit.Data &&
                                                              unit.Data.Avatar
                                                              ? !isNaN(
                                                                unit.Data
                                                                  .Avatar,
                                                              )
                                                                ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${unit.Co}&ID=${unit.Data.Avatar}`
                                                                : unit.Data
                                                                  .Avatar
                                                              : unit.avatar
                                                                ? unit.avatar
                                                                : avatar
                                                          }
                                                        />
                                                        <div className='w-full text-left'>
                                                          <div className='w-full'>
                                                            <strong className='text-10 m-4 uppercase'>{`${unit.UnitID} - ${unit.Name}`}</strong>
                                                          </div>
                                                          {unit.Manufacturer && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Manufacturer:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${unit.Manufacturer}`}</label>
                                                            </div>
                                                          )}
                                                          {unit.Model && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Model:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${unit.Model}`}</label>
                                                            </div>
                                                          )}
                                                          {unit.SerialNumber && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Serial #:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${unit.SerialNumber}`}</label>
                                                            </div>
                                                          )}
                                                          {unit.ManufactureDate && (
                                                            <div
                                                              className='w-full'
                                                              style={{
                                                                marginTop: -8,
                                                              }}
                                                            >
                                                              <strong className='text-10 m-4'>
                                                                Mfg Date:
                                                              </strong>
                                                              <label className='text-10 m-4'>{`${this.formatMfgDate(
                                                                unit.ManufactureDate,
                                                              )}`}</label>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                              </div>
                                            )
                                          }
                                        })}
                                      {MultiSiteYN !== 'Y' &&
                                        included &&
                                        included.map((uid, index) => {
                                          const unit = _.find(
                                            Data.Site.Data.Units,
                                            { UnitID: uid.UnitID },
                                          )
                                          return (
                                            <div
                                              key={index}
                                              className='w-full flex mb-6 mt-6'
                                            >
                                              <Avatar
                                                className={classes.unitAvatar}
                                                style={{
                                                  cursor: 'pointer',
                                                  boxShadow:
                                                    '2px 2px 4px 0px #3f3f3f',
                                                  marginTop: 5,
                                                }}
                                                alt='Service Unit Image'
                                                src={
                                                  unit.Data && unit.Data.Avatar
                                                    ? !isNaN(unit.Data.Avatar)
                                                      ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${unit.Co}&ID=${unit.Data.Avatar}`
                                                      : unit.Data.Avatar
                                                    : unit.avatar
                                                      ? unit.avatar
                                                      : avatar
                                                }
                                              />
                                              <div className='w-full text-left'>
                                                <div className='w-full'>
                                                  <strong className='text-10 m-4 uppercase'>{`${unit.UnitID} - ${unit.Name}`}</strong>
                                                </div>
                                                {unit.Manufacturer && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Manufacturer:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${unit.Manufacturer}`}</label>
                                                  </div>
                                                )}
                                                {unit.Model && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Model:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${unit.Model}`}</label>
                                                  </div>
                                                )}
                                                {unit.SerialNumber && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Serial #:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${unit.SerialNumber}`}</label>
                                                  </div>
                                                )}
                                                {unit.ManufactureDate && (
                                                  <div
                                                    className='w-full'
                                                    style={{ marginTop: -8 }}
                                                  >
                                                    <strong className='text-10 m-4'>
                                                      Mfg Date:
                                                    </strong>
                                                    <label className='text-10 m-4'>{`${this.formatMfgDate(
                                                      unit.ManufactureDate,
                                                    )}`}</label>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </React.Fragment>
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}

                <div className={`pt-12 w-full`}>
                  <strong
                    className='text-12 uppercase mr-4'
                    style={{ fontWeight: 800 }}
                  >
                    Maintenance Plan
                  </strong>
                  <div
                    className='w-full text-10 mt-0 rounded p-6 px-0'
                    style={{ border: '1px solid #333' }}
                  >
                    <div
                      className='w-full mx-6'
                      style={{ width: 'calc(100% - 12px)' }}
                    >
                      <div
                        className='w-full'
                        style={{ borderBottom: '1px solid #333' }}
                      >
                        <div className='w-full flex text-11'>
                          <div className='w-1/5 text-left font-bold uppercase'>
                            Qty
                          </div>
                          <div className='w-4/5 text-left font-bold uppercase'>
                            Description
                          </div>
                          <div className='w-1/5 text-right font-bold uppercase'>
                            Price
                          </div>
                        </div>
                      </div>
                      <div className='w-full mt-4 mb-4'>
                        <div className='w-full flex'>
                          <div className='w-1/5 text-left'>1</div>
                          <div className='w-4/5 text-left'>
                            {at.Description}
                          </div>
                          <div className='w-1/5 text-right'>
                            {this.formatDollars(data.TotalPrice)}
                          </div>
                        </div>
                      </div>
                      <div
                        className='w-full'
                        style={{ borderTop: '1px solid #333' }}
                      >
                        <div className='w-full flex text-11'>
                          <div className='w-1/5 text-left font-bold uppercase'>
                            Subtotal
                          </div>
                          <div className='w-4/5 text-left font-bold uppercase'></div>
                          <div className='w-1/5 text-right font-bold uppercase'>
                            {this.formatDollars(data.TotalPrice)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {data.Data
                  .BillingSchedule /*&& data.Data.BillingSchedule.length > 0*/ && (
                    <div className={`pt-12 w-full`}>
                      <strong
                        className='text-12 uppercase mr-4'
                        style={{ fontWeight: 800 }}
                      >
                        Billing Schedule
                      </strong>
                      <div
                        className='w-full text-10 mt-0 rounded p-6 px-0'
                        style={{ border: '1px solid #333' }}
                      >
                        <div
                          className='w-full mx-6'
                          style={{ width: 'calc(100% - 12px)' }}
                        >
                          <div
                            className='w-full'
                            style={{ borderBottom: '1px solid #333' }}
                          >
                            <div className='w-full flex text-11'>
                              <div className='w-1/5 text-left font-bold uppercase'>
                                Date
                              </div>
                              <div className='w-4/5 text-left font-bold uppercase'>
                                Description
                              </div>
                              <div className='w-1/5 text-right font-bold uppercase'>
                                Amount
                              </div>
                            </div>
                          </div>
                          {data.Data.BillingSchedule.map((billing, index) => {
                            return (
                              <div className='w-full mt-4 mb-4'>
                                <div className='w-full flex'>
                                  <div className='w-1/5 text-left'>
                                    {moment(billing.DueDate).format('M/D/YYYY')}
                                  </div>
                                  <div className='w-4/5 text-left'>{`Billing Sequence #${billing.Seq} of ${data.Data.BillingSchedule.length}`}</div>
                                  <div className='w-1/5 text-right'>
                                    {this.formatDollars(billing.Amount)}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          <div
                            className='w-full'
                            style={{ borderTop: '1px solid #333' }}
                          >
                            <div className='w-full flex text-11'>
                              <div className='w-1/5 text-left font-bold uppercase'>
                                Subtotal
                              </div>
                              <div className='w-4/5 text-left font-bold uppercase'></div>
                              <div className='w-1/5 text-right font-bold uppercase'>
                                {this.formatDollars(
                                  _.sumBy(data.Data.BillingSchedule, 'Amount'),
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <Dialog
                  open={this.state.isSigning}
                  classes={{ paper: classes.signaturePaper }}
                  fullWidth
                  maxWidth='sm'
                  onClose={() => {
                    if (this.sigPad.isEmpty()) {
                      this.setState({ ...this.state, isSigning: false })
                    }
                  }}
                >
                  <div className='w-full mb-4'>
                    <span>
                      <strong style={{ marginRight: 4 }}>
                        Customer Signature:
                      </strong>
                      {data.SignedBy}
                      <Icon
                        onClick={() => {
                          this.setState({ ...this.state, EditSignedBy: true })
                        }}
                        className='cursor-pointer text-16 ml-4 no-print'
                      >
                        edit
                      </Icon>
                    </span>
                    <br />
                    {this.state.isSigning && this.state.EditSignedBy && (
                      <TextField
                        className={classes.formControl}
                        label='Signed By'
                        id='signedBy'
                        name='SignedBy'
                        value={this.state.SignedBy}
                        onChange={this.handleChange}
                        onBlur={() => {
                          this.setState(
                            { ...this.state, EditSignedBy: false },
                            () => this.props.onSignedBy(this.state.SignedBy),
                          )
                        }}
                        variant='outlined'
                        autoFocus
                        fullWidth
                      />
                    )}
                  </div>
                  <div
                    className='w-full'
                    style={{ border: '1px solid #3f3f3f', borderRadius: 5 }}
                  >
                    <SignatureCanvas
                      ref={ref => {
                        this.sigPad = ref
                      }}
                      penColor='#66aef9'
                      canvasProps={{
                        className: 'sigCanvas',
                        style: { width: '100%', height: 128 },
                      }}
                    />
                  </div>
                  <div className='flex w-full mt-16'>
                    <Button
                      variant='contained'
                      className={classes.clearBtn}
                      fullWidth
                      onClick={this.clear}
                    >
                      Clear
                    </Button>
                    <div className='min-w-12'></div>
                    <Button
                      variant='contained'
                      className={classes.okBtn}
                      fullWidth
                      onClick={this.trim}
                    >
                      OK
                    </Button>
                  </div>
                </Dialog>
                <div className='m-12 ml-0 mr-0 w-full'>
                  <div className='w-full mb-4'>
                    <span>
                      <strong
                        className='mr-8 text-12'
                        style={{ fontWeight: 800 }}
                      >
                        CUSTOMER SIGNATURE:
                      </strong>
                      {data.SignedBy}
                      <Icon
                        onClick={() => {
                          this.setState({ ...this.state, EditSignedBy: true })
                        }}
                        className='cursor-pointer text-16 align-middle mb-4 no-print'
                      >
                        edit
                      </Icon>
                    </span>
                    <br />
                    {!this.state.isSigning && this.state.EditSignedBy && (
                      <TextField
                        className={classes.formControl}
                        label='Signed By'
                        id='signedBy'
                        name='SignedBy'
                        value={this.state.SignedBy}
                        onChange={this.handleChange}
                        onBlur={() => {
                          this.setState(
                            { ...this.state, EditSignedBy: false },
                            () => this.props.onSignedBy(this.state.SignedBy),
                          )
                        }}
                        variant='outlined'
                        autoFocus
                        fullWidth
                      />
                    )}
                  </div>
                  <div
                    className='w-full mb-12'
                    onClick={() => {
                      this.setState({ ...this.state, isSigning: true })
                    }}
                    style={{
                      border: '1px solid #3f3f3f',
                      width: '100%',
                      height: 112,
                      borderRadius: 5,
                    }}
                  >
                    {data.Signature && (
                      <img
                        style={{ width: '100%', height: 112 }}
                        src={data.Signature}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr
              style={{
                clear: 'both',
                borderTop: '1px solid black',
                marginTop: 0,
                marginBottom: 5,
              }}
            />
            <div className='flex text-10 text-left w-full'>
              {at.Disclaimer && (
                <div className='w-full m-0'>* {at.Disclaimer} *</div>
              )}
            </div>
            {/* <img className="w-full" src="assets/images/invoices/220SMInvoiceFooter.png" /> */}
          </div>
        </React.Fragment>
      )
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // sendAgreementForm: Actions.sendAgreementForm,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers }) {
  return {
    Co: spReducers.companies.Co,
    divisions: spReducers.divisions,
    businessUnits: spReducers.businessUnits,
    agreementTypes: spReducers.agreementTypes,
    companies: spReducers.companies.List,
    User: spReducers.userProfiles.User,
    Users: spReducers.userProfiles.Users,
    users: spReducers.userProfiles.Users,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AgreementFormDialog),
)
