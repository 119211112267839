import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CallGatherKeysDialog from 'main/content/apps/call-gather-keys/CallGatherKeysDialog';
import CallGatherKeyHeader from 'main/content/apps/call-gather-keys/CallGatherKeysHeader';
import CallGatherKeysLeftSidebarHeader from 'main/content/apps/call-gather-keys/CallGatherKeysLeftSidebarHeader';
import CallGatherKeyList from 'main/content/apps/call-gather-keys/CallGatherKeysList';
import CallGatherKeySidebarContent from 'main/content/apps/call-gather-keys/CallGatherKeysSidebarContent';
import DepartmentDialog from 'main/content/apps/departments/DepartmentDialog';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right: 12,
        bottom: 12,
        zIndex: 99
    }
});

class callGatherKeysApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getCallGatherKeys(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewCallGatherKeysDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <CallGatherKeyHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <CallGatherKeysLeftSidebarHeader />
                            }
                            content={
                                <CallGatherKeyList />
                            }
                            leftSidebarContent={
                                <CallGatherKeySidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={classNames(classes.addButton, !matches ? "fixed" : "")}
                                onClick={openNewCallGatherKeysDialog}
                            >
                                <Icon>add</Icon>
                            </Fab>
                        </FuseAnimate>
                        <CallGatherKeysDialog />
                        <DepartmentDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCallGatherKeys: Actions.getCallGatherKeys,
        getUserData: Actions.getUserData,
        openNewCallGatherKeysDialog: Actions.openNewCallGatherKeysDialog
    }, dispatch);
}

function mapStateToProps({ callGatherKeysApp }) {
    return {
        CallGatherKeys: callGatherKeysApp.CallGatherKeys.entities,
        selectedCallGatherKeyIds: callGatherKeysApp.CallGatherKeys.selectedCallGatherKeyIds,
        searchText: callGatherKeysApp.CallGatherKeys.searchText,
        user: callGatherKeysApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(callGatherKeysApp)));
