import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    drilled: {},
    filters: {},
};

const dashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_DASHBOARD:
            {
                return {
                    ...initialState,
                    ...action.payload
                };
            }
        case Actions.RESET_DASHBOARD:
            {
                return {
                    ...initialState
                };
            }
        case Actions.DELETE_DASHBOARD:
            {
                return {
                    ...initialState
                };
            }
        case Actions.UPDATE_DASHBOARD:
            {
                return {
                    ...state,
                    ...action.payload
                };
            }
        case Actions.SET_DASHBOARD_FILTERS:
            {
                const { filters } = action;
                return {
                    ...state,
                    filters
                }
            }
            break;
        case Actions.SET_DASHBOARD_DRILLED:
            {
                const { drilled } = action;
                return {
                    ...state,
                    drilled
                }
            }
            break;
        default:
            return state;
    }
};

export default dashboardReducer;
