import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    Dashboards: [],
    Tags: [],
}

const dashboardsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_DASHBOARDS:
            {

                return {
                    ...state,
                    Dashboards: action.payload,
                };
            }
        case Actions.NEW_DASHBOARD:
            {
                return {
                    ...state,
                    Dashboards: [
                        ...state.Dashboards,
                        action.dashboard
                    ],
                }
            }
        case Actions.UPDATE_DASHBOARD:
            {
                const index = state.Dashboards.findIndex((x) => x.ID === action.payload.ID);
                state.Dashboards[index] = action.payload;
                return {
                    ...state,
                }
            }
        case Actions.RESET_DASHBOARDS:
            {
                return {
                    ...state,
                    Dashboards: [],
                }
            }
        case Actions.DELETE_DASHBOARD:
            {
                return {
                    ...state,
                    Dashboards: _.reject(state.Dashboards, { ID: action.payload.ID }),
                }
            }
        default:
            return state;
    }
};

export default dashboardsReducer;
