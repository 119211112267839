import React, { Component } from 'react';
import {
    TextField, Badge, Button, Card, CardHeader, CardContent, CardActions, Dialog, DialogActions, DialogContent, Divider, Icon, IconButton, Input, InputAdornment, Menu, Tabs, Tab, Tooltip, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { getCallLog } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { DatePicker } from "@material-ui/pickers";
import CallList from 'main/content/apps/calls/CallsList';
import { connect } from 'react-redux';
import { SPRoundIcon } from 'main/icons';
import Media from 'react-media';
import Timer from 'react-compound-timer';
import moment from 'moment';
import classNames from 'classnames';
import _ from '@lodash';
import getProfileImage from '../../../functions/getProfileImageUrl';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)', overflow: 'auto', fontSize: 'unset' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        width: 32,
        height: 32,
    },
    clearedAvatar: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        width: 32,
        height: 32,
    },
    avatarBadge: {
        top: 4,
        right: 2,
        lineHeight: 16,
        minWidth: 16,
        height: 16,
        fontSize: 10,
        padding: 0,
    },
    content: {
        padding: '14px 16px 0 16px !important',
        borderTop: '1px solid lightgrey',

    },
    agentAvatar: {
        borderRadius: '50%',
        boxShadow: '2px 2px 6px #333',
        width: 24,
        height: 24,
        cursor: 'pointer'
    },
    agentNameBadge: {
        top: 12,
        right: 12,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 24,
        height: 24,
        cursor: 'pointer'
    },
    offline: {
        borderRadius: '50%',
        minHeight: 8,
        minWidth: 8,
        backgroundColor: 'transparent',
        width: 8,
        height: 8,
        marginTop: 6
    },
    idle: {
        borderRadius: '50%',
        minHeight: 8,
        minWidth: 8,
        backgroundColor: '#FFC107',
        width: 8,
        height: 8,
        marginTop: 6
    },
    online: {
        borderRadius: '50%',
        minHeight: 8,
        minWidth: 8,
        backgroundColor: '#4CAF50',
        width: 8,
        height: 8,
        marginTop: 6
    },
});

const newCallManagerState = {
    value: 0,
    calls: [],
    queues: [],
    log: [],
    agents: [],
    start: new Date(),
    end: new Date(),
    Queue: null,
    Status: null,
    isStartpickerOpen: false,
    isEndpickerOpen: false
};

class CallManagerDialog extends Component {
    state = { ...newCallManagerState };

    componentDidMount() {
        const { start, end } = this.props;
        this.setState({ ...this.state, start, end });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { start, end } = this.props;
        if (!_.isEqual(start, prevProps.start) || !_.isEqual(end, prevProps.end)) {
            this.setState({ ...this.state, start, end });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    formatPhone(phone) {
        return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    handleCall = (call, action, agent) => {
        const { username, acceptCall, divertCall, endCall, queues } = this.props;
        switch (action) {
            case 'accept':
                {
                    if (call && !call.HandledBy && call.Status === "queued") {
                        acceptCall(call, agent);
                    }
                }
                break;
            case 'divert':
                {
                    const queue = _.find(queues, { Co: call.Co, Queue: call.Queue });
                    if (call && !call.HandledBy && call.Status === "queued" && queue && queue.FallbackAction === "Dial" && queue.FallbackDial) {
                        divertCall(call, queue.FallbackDial);
                    }
                }
                break;
            case 'end':
                {
                    if (call && call.Status !== "completed") {
                        endCall(call, username);
                    }
                }
                break;
        }
    }

    handleAgentChange = (e, agent) => {
        const { Co } = this.props;
        const { value } = e.target;
        this.props.setAgentStatus(Co, {
            ...agent,
            Status: value
        })
    }

    getMinutes = (date) => {
        var now = new Date();
        var dt = new Date(date || null);
        var ms = (now - dt);
        var mins = ms / 60000;
        return mins;
    }

    handleStartChange = (event) => {
        let { end } = this.state;
        const start = event._d;
        end = (end < start ? start : end);
        this.setStartpickerOpen(false, start, end);
        // this.setState({ ...this.state, start, end }, () => window["warn"]('New State: ', this.state.start, this.state.end, start, end))
    }

    handleEndChange = (event) => {
        let { start } = this.state;
        const end = event._d;
        start = (start > end ? end : start);
        this.setEndpickerOpen(false, start, end);
        // this.setState({ ...this.state, start, end }, () => window["warn"]('New State: ', this.state.start, this.state.end, start, end))
    }

    formatDateInput(dt) {
        return new Date(dt).toLocaleDateString('en-US').replace(/\//g, '-');
    }

    formatDateText(dt) {
        // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        // return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
        return moment(dt).format("MMM D YYYY");
    }

    openStartPicker = (e) => {
        this.setStartpickerOpen(true);
    }

    renderStartLabel = (e) => (
        <span onClick={this.openStartPicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.start)}</span>
    );

    openEndPicker = (e) => {
        this.setEndpickerOpen(true);
    }

    renderEndLabel = (e) => (
        <span onClick={this.openEndPicker} className="cursor-pointer ml-8">{this.formatDateText(this.state.end)}</span>
    );

    handlerMenuClick = (event, call) => {
        if (call && call.Status === "queued") {
            this.setState({
                ...this.state,
                handlerMenu: event.currentTarget,
                call
            });
        }
    };

    handlerMenuClose = () => {
        this.setState({
            ...this.state,
            handlerMenu: null,
            call: null,
        });
    };

    searchAgents = (ev) => {
        const { agents, users } = this.props;
        const searchText = ev.target.value;
        if (searchText && searchText.length > 0 && agents && users) {
            const searchResults = _.filter(_.filter(users, (o) => { return _.find(agents, { UserName: o.UserName }) }), (o) => { if (`${o.FirstName} ${o.LastName}`.toLowerCase().includes(searchText.toLowerCase())) { { return o } } })
            this.setState({
                ...this.state,
                searchResults,
                searchText
            });
            return;
        }
        this.setState({ ...this.state, searchText, searchResults: null });
    }

    setStartpickerOpen = (bool, start, end) => {
        if (!start || !end) {
            this.setState({ ...this.state, isStartpickerOpen: bool });
        } else {
            this.setState({ ...this.state, isStartpickerOpen: bool, start, end });
        }
    }

    setEndpickerOpen = (bool, start, end) => {
        if (!start || !end) {
            this.setState({ ...this.state, isEndpickerOpen: bool });
        } else {
            this.setState({ ...this.state, isEndpickerOpen: bool, start, end });
        }
    }

    render() {
        const { classes, Co, securables, open, statuses, agent, users, calls, agents, queues, log, loading, getCallLog } = this.props;
        const { value, start, end, Queue, Status, handlerMenu, searchText, searchResults } = this.state;
        const accessLevel = _.find(securables, { Securable: "call-manager" });
        const icons = { queued: { icon: "ring_volume", color: "action" }, answered: { icon: "phone_in_talk", color: "secondary" }, completed: { icon: "call_end", color: "error" } };
        const agentAvatar = 'assets/images/avatars/profile.jpg';
        const statusIcons = ['phone_callback', 'phone_callback', 'phone_callback', 'phone_in_talk', 'settings_phone', 'phone_forwarded', 'phone_forwarded'];
        const statusClass = ['offline', 'idle', 'online']

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        open={Boolean(accessLevel) && open}
                        onClose={this.props.onClose}
                        onClick={(e) => e.stopPropagation()}
                        fullWidth
                        maxWidth={value === 2 ? "md" : "sm"}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full pl-16 pr-16">
                                <Typography variant="subtitle1" color="inherit">
                                    <SPRoundIcon className="align-middle mr-6" style={{ marginBottom: 2 }} />
                                    Call Manager
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                color: 'white'
                            }}
                                className="dialog-header-icon"
                                onClick={this.props.onClose}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                            >
                                <Tab disableRipple className={(value !== 2 || !matches) && "w-1/3"} icon={<Badge badgeContent={queues ? queues.length : 0} color="error"><Icon>phone_callback</Icon></Badge>} label="Queues" />
                                <Tab disableRipple className={(value !== 2 || !matches) && "w-1/3"} icon={<Badge badgeContent={agents ? agents.length : 0} color="error"><Icon>headset_mic</Icon></Badge>} label="Agents" />
                                <Tab disableRipple className={(value !== 2 || !matches) && "w-1/3"} icon={<Icon>phone</Icon>} label="Log" />
                            </Tabs>
                        </AppBar>

                        <DialogContent classes={{ root: "p-12" }}>
                            {value === 0 &&
                                <TabContainer padding="0px 4px 0px 4px" maxHeight='100%'>
                                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12, }} className="flex relative"><Icon color="primary" className="text-24 mr-8">phone_callback</Icon>Call Queues
                                        <TextField
                                            className="pin-r absolute w-160"
                                            // label="Queue"
                                            id="queue"
                                            name="Queue"
                                            value={this.state.Queue || ""}
                                            onChange={this.handleChange}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><Icon color="action">filter_list</Icon></InputAdornment>
                                            }}
                                            select
                                        >
                                            {queues &&
                                                <MenuItem value={null}>
                                                    All Queues
                                                </MenuItem>
                                            }
                                            {queues ?
                                                queues.map((queue, index) => {
                                                    return (
                                                        <MenuItem key={index} value={queue.Queue}>
                                                            {queue.Queue}
                                                        </MenuItem>
                                                    )
                                                }) :
                                                <MenuItem value={null}>
                                                    No Queues Found
                                                </MenuItem>
                                            }
                                        </TextField>
                                    </Typography>

                                    <Menu
                                        id="handler-menu"
                                        anchorEl={handlerMenu}
                                        open={Boolean(handlerMenu)}
                                        onClose={this.handlerMenuClose}
                                        classes={{
                                            paper: 'mt-48'
                                        }}
                                        onEntered={() => {
                                            this.searchAgent.focus();
                                        }}
                                        onExited={() => {
                                            this.handlerMenuClose();
                                        }}>
                                        <div className="p-8">
                                            <Input
                                                inputRef={ref => this.searchAgent = ref}
                                                value={searchText}
                                                onChange={this.searchAgents}
                                                placeholder="Search for an agent"
                                                className=""
                                                fullWidth
                                                inputProps={{
                                                    'aria-label': 'Search'
                                                }}
                                            />
                                        </div>

                                        <Divider />
                                        <div className="max-h-256 overflow-auto">
                                            {(searchResults || agents) &&
                                                (searchResults || agents).map(user => {
                                                    const agent = _.find(users, { UserName: user.UserName });
                                                    if (agent) {
                                                        var minutes = this.getMinutes(agent.Data.LastLogin);
                                                        var status = (!agent.Data.Status || minutes > 60 ? 0 : agent.Data.Status)
                                                        return (
                                                            <MenuItem disabled={status < 1} onClick={() => { this.handleCall(this.state.call, "accept", agent.UserName); this.handlerMenuClose(); }}>
                                                                <Avatar classes={{ root: classes.agentAvatarRoot }} className={classes.agentAvatar} alt={agent.name} src={(agent.Data && agent.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${agent.Data.Avatar}&Thumb=true` ): agentAvatar)} />
                                                                <Typography className="ml-8 mt-4 font-bold flex" variant="caption">{`${agent.FirstName} ${agent.LastName}`}<div className={classNames("ml-6", classes[statusClass[status]])}></div></Typography>
                                                            </MenuItem>
                                                        );
                                                    }
                                                })
                                            }

                                            {searchText && searchText.length !== 0 && searchResults.length === 0 && (
                                                <Typography color="textSecondary" className="p-16 pb-8">No results...</Typography>
                                            )}
                                        </div>
                                    </Menu>
                                    <Divider className="mb-12" />
                                    {queues &&
                                        <div className="">
                                            {[...queues].map((queue, index) => {
                                                const { Queue } = queue;
                                                const queueCalls = _.filter([...calls], { Queue });
                                                const waiting = _.filter([...queueCalls], { Status: 'queued' });
                                                const oldest = _.minBy([...waiting], (o) => { return new Date(o.CreatedDate) });
                                                const wait = oldest ? (new Date() - moment(moment.utc(oldest.CreatedDate)).local().toDate()) : 0;
                                                if (!this.state.Queue || this.state.Queue === Queue) {
                                                    return (
                                                        <Card key={queue.ID} className="mb-12">
                                                            <CardHeader
                                                                key={index}
                                                                classes={{ action: "sm:mt-0", root: "p-8 px-12", avatar: "mr-8", title: "leading-none", subheader: "leading-none" }}
                                                                avatar={
                                                                    <Badge classes={{ badge: classes.avatarBadge }} color="error" badgeContent={queueCalls ? _.filter(queueCalls, { Status: 'queued' }).length : 0}>
                                                                        <Avatar className={queueCalls.length > 0 ? classes.avatar : classes.clearedAvatar}>
                                                                            <Icon className="text-20">{queueCalls.length > 0 ? "phone_callback" : "call_end"}</Icon>
                                                                        </Avatar>
                                                                    </Badge>
                                                                }
                                                                title={<label className="text-12 font-bold">{queue.Description}</label>}
                                                                subheader={waiting.length < 1 ? <label className="text-10">No Calls Waiting</label> :
                                                                    <Timer initialTime={wait} lastUnit="m">
                                                                        {(timer) => {
                                                                            return (
                                                                                <div>
                                                                                    <label className="text-10">
                                                                                        <Timer.Minutes />
                                                                                    </label>
                                                                                    <label className="text-10" style={{ marginLeft: 3 }}>
                                                                                        Minutes
                                                                                    </label>
                                                                                    <label className="text-10 ml-4">
                                                                                        <Timer.Seconds />
                                                                                    </label>
                                                                                    <label className="text-10" style={{ marginLeft: 3 }}>
                                                                                        Seconds
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        }}
                                                                    </Timer>
                                                                }
                                                                action={
                                                                    <IconButton aria-label="edit">
                                                                        <Icon className="text-16">keyboard_arrow_down</Icon>
                                                                    </IconButton>
                                                                }
                                                            />
                                                            {[...queueCalls].length > 0 &&
                                                                <CardContent classes={{ root: classes.content }}>
                                                                    {[...queueCalls].map((call, index) => {
                                                                        const callWait = (new Date() - moment(moment.utc(call.Status === "queued" ? call.CreatedDate : call.HandledDate)).local().toDate());
                                                                        const agent = call.HandledBy ? _.find(users, { UserName: call.HandledBy }) : null;
                                                                        return (
                                                                            <div key={call.Sid || call.CallSid} className={`h-36 w-full flex text-11${index < (queueCalls.length - 1) ? ' mb-12' : ''}`} style={index < (queueCalls.length - 1) ? { borderBottom: '1px dotted lightgrey' } : {}}>
                                                                                <div className={call.HandledBy && agent ? "" : "mr-12"} style={call.HandledBy && agent ? { marginRight: 10, marginLeft: -2 } : {}}>
                                                                                    {call.HandledBy && agent ?
                                                                                        <Tooltip placement="top" title={`${agent.FirstName} ${agent.LastName}`} enterTouchDelay={100}>
                                                                                            <Badge classes={{ badge: classNames(classes.agentNameBadge) }} badgeContent={`${agent.FirstName.substr(0, 1)}${agent.LastName.substr(0, 1)}`}>
                                                                                                <Avatar classes={{ root: classes.agentAvatarRoot }} className={classes.agentAvatar} alt={agent.name} src={(agent.Data && agent.Data.Avatar ? getProfileImage(`Co=${call.Co}&ID=${agent.Data.Avatar}&Thumb=true` ): agentAvatar)} />
                                                                                            </Badge>
                                                                                        </Tooltip> :
                                                                                        <Icon className="text-20" color={icons[call.Status].color}>{icons[call.Status].icon}</Icon>
                                                                                    }
                                                                                </div>
                                                                                <div className="whitespace-no-wrap mr-8 mt-4 font-bold">{this.formatPhone(call.From.replace('+1', ''))}</div>
                                                                                <div className="w-full text-10 mt-4 text-center pl-16">
                                                                                    <Timer initialTime={callWait} lastUnit="m">
                                                                                        {(timer) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    {call.HandledBy &&
                                                                                                        <Icon className="text-16 mr-4 align-middle" style={{ marginLeft: -20 }} color={icons[call.Status].color}>{icons[call.Status].icon}</Icon>
                                                                                                    }
                                                                                                    <label>
                                                                                                        <Timer.Minutes />
                                                                                                    </label>
                                                                                                    <label style={{ marginLeft: 3 }}>
                                                                                                        Minutes
                                                                                                    </label>
                                                                                                    <label className="ml-4">
                                                                                                        <Timer.Seconds />
                                                                                                    </label>
                                                                                                    <label style={{ marginLeft: 3 }}>
                                                                                                        Seconds
                                                                                                    </label>
                                                                                                </div>
                                                                                            )
                                                                                        }}
                                                                                    </Timer>
                                                                                </div>
                                                                                <div className="whitespace-no-wrap text-right">
                                                                                    <Tooltip placement="top" title={call.HandledBy ? "" : "Route Call"}>
                                                                                        <Icon onClick={(e) => this.handlerMenuClick(e, call)} color={call.HandledBy ? "disabled" : "secondary"} className="text-20 ml-8 cursor-pointer" style={{ marginTop: 2 }}>phone_in_talk</Icon>
                                                                                    </Tooltip>
                                                                                    <Tooltip placement="top" title={queue.FallbackDial && !call.HandledBy ? `Divert to ${this.formatPhone(queue.FallbackDial.replace('+1', ''))}` : ''}>
                                                                                        <Icon onClick={() => this.handleCall(call, "divert")} color={queue.FallbackDial && !call.HandledBy ? "action" : "disabled"} className="text-20 ml-16 cursor-pointer" style={{ marginTop: 2 }}>phone_forwarded</Icon>
                                                                                    </Tooltip>
                                                                                    <Tooltip placement="top" title="End Call">
                                                                                        <Icon onClick={() => this.handleCall(call, "end")} color="error" className="text-20 ml-16 cursor-pointer" style={{ marginTop: 2 }}>call_end</Icon>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </CardContent>
                                                            }
                                                        </Card>
                                                    );
                                                }
                                            })
                                            }
                                        </div>
                                    }
                                </TabContainer>
                            }
                            {value === 1 &&
                                <TabContainer padding="0px" maxHeight='100%'>
                                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12, }} className="flex relative"><Icon color="primary" className="text-24 mr-8">headset_mic</Icon>Agents
                                        <TextField
                                            className="pin-r absolute w-160"
                                            // label="Queue"
                                            id="status"
                                            name="Status"
                                            value={this.state.Status || ""}
                                            onChange={this.handleChange}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><Icon color="action">filter_list</Icon></InputAdornment>
                                            }}
                                            select
                                        >
                                            <MenuItem value={null}>
                                                All Agents
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Active
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                Idle
                                            </MenuItem>
                                            <MenuItem value={0}>
                                                Offline
                                            </MenuItem>
                                        </TextField>
                                    </Typography>
                                    <Divider className="mb-12" />
                                    {agents && [...agents].map((user, index) => {
                                        const agent = _.find(users, { UserName: user.UserName, ActiveYN: "Y" });
                                        if (agent) {
                                            var minutes = this.getMinutes(agent.Data.LastLogin);
                                            var status = (!agent.Data.Status || minutes > 60 ? 0 : agent.Data.Status)
                                            if (Status === null || Status === status) {
                                                return (
                                                    <div key={user.UserName} className={`h-36 w-full flex relative text-11${index < (agents.length - 1) ? ' mb-12' : ''}`} style={index < (agents.length - 1) ? { borderBottom: '1px dotted lightgrey' } : {}}>
                                                        <Avatar classes={{ root: classes.agentAvatarRoot }} className={classes.agentAvatar} alt={agent.name} src={(agent.Data && agent.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${agent.Data.Avatar}&Thumb=true` ): agentAvatar)} />
                                                        <Typography className="ml-8 mt-4 font-bold flex" variant="caption">{`${agent.FirstName} ${agent.LastName}`}<div className={classNames("ml-6", classes[statusClass[status]])}></div></Typography>
                                                        <TextField
                                                            className="pin-r absolute pin-r absolute"
                                                            id={`agent${index}`}
                                                            value={user.Status !== null && user.Status !== undefined ? user.Status : 5}
                                                            onChange={(e) => this.handleAgentChange(e, agent)}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                className: "text-12",
                                                                startAdornment: <InputAdornment position="start"><Icon color="action" className="text-16">{statusIcons[user.Status !== null && user.Status !== undefined ? user.Status : 5]}</Icon></InputAdornment>
                                                            }}
                                                            select
                                                        >
                                                            {statuses.map((value, index) => (
                                                                <MenuItem key={index} value={value.Status}>
                                                                    {value.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>
                                                );
                                            }
                                        }
                                    })}
                                </TabContainer>
                            }
                            {value === 2 &&
                                <TabContainer padding="0px" maxHeight='100%'>
                                    <CallList audit={true} fixed={true} header={
                                        <React.Fragment>
                                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-24 mr-8">phone</Icon>Call Log
                                                <Tooltip title="Start Date" placement="top">
                                                    <div className="picker mt-6 text-11 ml-8">
                                                        <DatePicker
                                                            // onOpen={() => this.setStartpickerOpen(true)}
                                                            // onClose={() => this.setStartpickerOpen(false)}
                                                            // open={this.state.isStartpickerOpen}
                                                            className="text-11 w-64 cursor-pointer"
                                                            // label="Start"
                                                            InputProps={
                                                                {
                                                                    className: "text-11 cursor-pointer",
                                                                    disableUnderline: true,
                                                                }
                                                            }
                                                            inputProps={
                                                                {
                                                                    className: "text-11 text-center cursor-pointer",
                                                                }
                                                            }
                                                            format="MMM DD YYYY"
                                                            // TextFieldComponent={this.renderStartLabel}
                                                            value={start}
                                                            onChange={this.handleStartChange}
                                                            showTodayButton
                                                            autoOk
                                                        />
                                                    </div>
                                                </Tooltip>
                                                <div className="mt-6 text-11 ml-6 mr-6">-</div>
                                                <Tooltip title="End Date" placement="top">
                                                    <div className="picker mt-6 text-11">
                                                        <DatePicker
                                                            // onOpen={() => this.setEndpickerOpen(true)}
                                                            // onClose={() => this.setEndpickerOpen(false)}
                                                            // open={this.state.isEndpickerOpen}
                                                            className="text-11 w-64 cursor-pointer"
                                                            // label="End"
                                                            InputProps={
                                                                {
                                                                    className: "text-11 cursor-pointer",
                                                                    disableUnderline: true,
                                                                }
                                                            }
                                                            inputProps={
                                                                {
                                                                    className: "text-11 text-center cursor-pointer",
                                                                }
                                                            }
                                                            format="MMM DD YYYY"
                                                            // TextFieldComponent={this.renderEndLabel}
                                                            value={end}
                                                            onChange={this.handleEndChange}
                                                            showTodayButton
                                                            autoOk
                                                        />
                                                    </div>
                                                </Tooltip>
                                                <Icon color="action" onClick={() => getCallLog(Co, start, end)} className={classNames("text-20 ml-4 mt-4 align-middle cursor-pointer", loading ? "spin" : "")}>refresh</Icon>
                                            </Typography>
                                            <Divider />
                                        </React.Fragment>
                                    } loading2={loading} selectedCallData={[...log]} />
                                </TabContainer>
                            }
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        acceptCall: Actions.acceptCall,
        divertCall: Actions.divertCall,
        endCall: Actions.endCall,
        setAgentStatus: Actions.setAgentStatus,
        getCallLog,
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        statuses: spReducers.agentStatus.Statuses,
        calls: spReducers.callHandler.Calls,
        start: spReducers.callHandler.start,
        end: spReducers.callHandler.end,
        loading: spReducers.callHandler.loading,
        log: spReducers.callHandler.Log,
        queues: spReducers.callHandler.Queues,
        agents: spReducers.agentStatus.Agents,
        agent: spReducers.agentStatus.Agent,
        statuses: spReducers.agentStatus.Statuses,
        securables: spReducers.userProfiles.User.Data.Securables,
        username: spReducers.userProfiles.User.UserName,
        users: spReducers.userProfiles.Users
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CallManagerDialog));
