
export const fuseNavigationConfig = [
    {
        'id': 'technician',
        'title': 'Technician Dashboard',
        'type': 'item',
        'icon': 'perm_contact_calendar',
        'url': '/apps/technician',
        'securables': [{
            'id': 'technician',
            'title': 'Technician Dashboard',
        }],
        'children': [
            {
                'id': 'trip-status',
                'title': 'Trip Status Terminology',
                'icon': 'local_shipping',
                'settings': 'trip_status_terminology'
            }
        ]
    },
    {
        'id': 'supervisor',
        'title': 'Supervisor Dashboard',
        'type': 'item',
        'icon': 'supervised_user_circle',
        'url': '/apps/supervisor',
        'securables': [{
            'id': 'supervisor',
            'title': 'Supervisor Dashboard',
        }],
    },
    {
        'id': 'call-handler',
        'title': 'Call Handler',
        'type': 'item',
        'icon': 'phone',
        'url': '/apps/call-handler',
        'securables': [{
            'id': 'call-handler',
            'title': 'Call Handler',
        }, {
            'id': 'call-manager',
            'title': 'Call Manager',
        }]
    },
    {
        'id': 'voicemail',
        'title': 'Voicemail',
        'type': 'item',
        'icon': 'voicemail',
        'url': '/apps/voice-mailboxes',
        'securables': [{
            'id': 'voicemail',
            'title': 'Voicemail',
        }]
    },
    {
        'id': 'dispatch-board',
        'title': 'Dispatch Board',
        'type': 'item',
        'icon': 'dvr',
        'url': '/apps/dispatch-board',
        'securables': [{
            'id': 'dispatch-board',
            'title': 'Dispatch Board',
        }, {
            'id': 'custom-dispatch-folders',
            'title': 'Custom Dispatch Folders',
        }, {
            'id': 'custom-dispatch-boards',
            'title': 'Custom Dispatch Boards',
        }]
    },
    {
        'id': 'customers',
        'title': 'Customers',
        'type': 'item',
        'icon': 'account_box',
        'url': '/apps/customers',
        'securables': [{
            'id': 'customers',
            'title': 'Customers',
        }, {
            'id': 'customer-dialog',
            'title': 'Customer Dialog',
            'form': 'customer',
            'reportCategory': 'Customers',
            'fields': [
                { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                { 'value': 'Customer', 'type': 'number' },
            ]
        },
        {
            'id': 'customer-refusal',
            'title': 'Refusal of Service',
        }]
    },
    {
        'id': 'sites',
        'title': 'Service Sites',
        'type': 'item',
        'icon': 'location_on',
        'url': '/apps/customer-sites',
        'securables': [{
            'id': 'sites',
            'title': 'Service Sites',
            'form': 'site',
            'reportCategory': 'Sites',
            'fields': [
                { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                { 'value': 'Customer', 'type': 'number' },
                { 'value': 'Site', 'type': 'text' },
            ]
        }, {
            'id': 'site-dialog',
            'title': 'Site Dialog',
        }]
    },
    {
        'id': 'work-orders',
        'title': 'Work Orders',
        'type': 'item',
        'icon': 'assignment',
        'url': '/apps/work-orders',
        'children': [
            {
                'id': 'work-order-options',
                'title': 'Work Order Options',
                'icon': 'settings',
                'settings': 'work_order_options'
            }
        ],
        'securables': [
            {
                'id': 'work-orders',
                'title': 'Work Orders',
                'form': 'work-order',
                'reportCategory': 'Work Orders',
                'fields': [
                    { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                    { 'value': 'WorkOrder', 'type': 'number' },
                ]
            },
            {
                'id': 'work-order-dialog',
                'title': 'Work Order Dialog',
            },
            {
                'id': 'work-order-billing',
                'title': 'Work Order Billing',
            },
            {
                'id': 'work-order-trips',
                'title': 'Work Order Trips',
            },
            {
                'id': 'work-order-attachments',
                'title': 'Work Order Attachments',
            },
            {
                'id': 'work-order-agreements',
                'title': 'Work Order Agreements',
            },
            {
                'id': 'work-order-purchases',
                'title': 'Work Order POs',
            },
            {
                'id': 'work-order-scopes',
                'title': 'Work Order Scopes',
            },
        ]
    },
    {
        'id': 'estimates',
        'title': 'Estimates',
        'type': 'item',
        'icon': 'format_list_numbered',
        'url': '/apps/estimates',
        'securables': [
            {
                'id': 'estimates',
                'title': 'Estimates',
            },
            {
                'id': 'estimate-work-orders',
                'title': 'Estimate Work Orders',
            },
        ]
    },
    {
        'id': 'agreements',
        'title': 'Agreements',
        'type': 'item',
        'icon': 'business_center',
        'url': '/apps/agreements',
        'securables': [
            {
                'id': 'agreements',
                'title': 'Agreements',
                'form': 'agreement',
                'reportCategory': 'Agreements',
                'fields': [
                    { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                    { 'value': 'BusinessUnit', 'type': 'text', 'list': 'businessUnits', 'text': ['Name'] },
                    { 'value': 'Division', 'type': 'text', 'list': 'divisions', 'text': ['Name'] },
                    { 'value': 'Customer', 'type': 'number' },
                    { 'value': 'Site', 'type': 'text' },
                    { 'value': 'Agreement', 'type': 'number' },
                    { 'value': 'Cycle', 'type': 'number' },
                    { 'value': 'Revision', 'type': 'number' },
                ]
            },
            {
                'id': 'agreement-dialog',
                'title': 'Agreement Dialog',
            },
            {
                'id': 'agreement-work-orders',
                'title': 'Agreement Work Orders',
            },
        ]
    },
    {
        'id': 'payments',
        'title': 'Payments',
        'type': 'item',
        'icon': 'payment',
        'url': '/apps/payments',
        'securables': [{
            'id': 'payments',
            'title': 'Payments',
        }, {
            'id': 'deposits',
            'title': 'Deposits',
        }, {
            'id': 'export-payments',
            'title': 'Export Payments',
        }],
    },
    {
        'id': 'teams',
        'title': 'Teams',
        'type': 'item',
        'icon': 'supervised_user_circle',
        'url': '/apps/teams',
        'securables': [{
            'id': 'teams',
            'title': 'Teams',
            'form': 'team',
            'reportCategory': 'Teams',
            'fields': [
                { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                { 'value': 'Team', 'type': 'number', 'list': 'teams' },
                { 'value': 'TeamLead', 'listValue': 'Technician', 'type': 'text', 'list': 'technicians', 'text': ['FirstName', 'LastName'] }
            ]
        }]
    },
    {
        'id': 'calendar',
        'title': 'Calendar',
        'type': 'item',
        'icon': 'today',
        'url': '/apps/calendar',
        'securables': [{
            'id': 'calendar',
            'title': 'Calendar',
        }]
    },
    {
        'id': 'to-do',
        'title': 'To-Do',
        'type': 'item',
        'icon': 'check_box',
        'url': '/apps/to-do',
        'securables': [{
            'id': 'to-do',
            'title': 'To-Do',
        }, {
            'id': 'to-do-labels',
            'title': 'To-Do Labels',
        }]
    },
    {
        'id': 'approvals',
        'title': 'Approvals',
        'type': 'collapse',
        'icon': 'check_circle',
        'children': [
            {
                'id': 'timecards',
                'title': 'Timecards',
                'type': 'item',
                'icon': 'access_time',
                'url': '/apps/timecards',
                'securables': [{
                    'id': 'timecards',
                    'title': 'Timecards',
                }]
            },/*
            {
                'id': 'purchase-orders',
                'title': 'Purchase Orders',
                'type': 'item',
                'icon': 'list_alt',
                'url': '/apps/purchase-orders',
                'securables': [{
                    'id': 'purchase-orders',
                    'title': 'Purchase Orders',
                }]
            },*/
        ],
        'securables': [{
            'id': 'approvals',
            'title': 'Approvals',
        }]
    },
    {
        'id': 'inventory-locations',
        'title': 'Inventory',
        'type': 'item',
        'icon': 'store',
        'url': '/apps/inventory-locations',
        'securables': [{
            'id': 'inventory-locations',
            'title': 'Inventory',
        }, {
            'id': 'inventory-control',
            'title': 'Inventory Control',
        }]
    },
    {
        'id': 'learning',
        'title': 'Learning Center',
        'type': 'item',
        'icon': 'school',
        'url': '/apps/academy',
        'securables': [{
            'id': 'learning',
            'title': 'Learning Center',
        }]
    },
    {
        'id': 'project-tracking',
        'title': 'Project Tracking',
        'type': 'item',
        'icon': 'table_chart',
        'url': '/apps/project-tracking',
        'securables': [{
            'id': 'project-tracking',
            'title': 'Project Tracking',
        }]
    },
    {
        'id': 'email-templates',
        'title': 'Email Studio',
        'type': 'item',
        'icon': 'view_quilt',
        'url': '/apps/email-templates',
        'securables': [{
            'id': 'email-templates',
            'title': 'Email Studio',
        }]
    },
    {
        'id': 'reporting',
        'title': 'Reporting',
        'type': 'collapse',
        'icon': 'format_list_numbered',
        'children': [
            {
                'id': 'audit-posting',
                'title': 'Audit Records',
                'type': 'item',
                'icon': 'find_replace',
                'url': '/apps/audit-posting',
                'securables': [{
                    'id': 'audit-posting',
                    'title': 'Audit Records',
                },
                {
                    'id': 'invoices',
                    'title': 'Invoice Posting',
                }]
            },
            {
                'id': 'reporting',
                'title': 'Reports',
                'type': 'item',
                'icon': 'format_list_numbered',
                'url': '/apps/reporting',
                'securables': [{
                    'id': 'reporting',
                    'title': 'Reports',
                }]
            },
        ]
    },
    {
        'id': 'dashboards',
        'title': 'Dashboards',
        'type': 'collapse',
        'icon': 'insert_chart',
        'children': [
            {
                'id': 'dashboard-builder',
                'title': 'Dashboard Studio',
                'type': 'item',
                'icon': 'insert_chart',
                'url': '/apps/dashboard-builder',
                'securables': [{
                    'id': 'dashboard-builder',
                    'title': 'Dashboard Studio',
                }]
            },
        ]
    },
    {
        'id': 'settings',
        'title': 'Settings',
        'type': 'collapse',
        'icon': 'tune',
        'children': [
            {
                'id': 'global-settings',
                'title': 'Global Settings',
                'type': 'collapse',
                'icon': 'public',
                'securables': [{
                    'id': 'global-settings',
                    'title': 'Global Settings',
                }, /*{
                    'id': 'inspector',
                    'title': 'Data Inspector',
                }*/],
                'children': [
                    {
                        'id': 'companies',
                        'title': 'Companies',
                        'type': 'item',
                        'icon': 'business',
                        'url': '/apps/companies',
                        'securables': [{
                            'id': 'companies',
                            'title': 'Companies',
                            'form': 'company',
                            'reportCategory': 'Companies',
                            'fields': [
                                { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                            ]
                        }]
                    },
                    {
                        'id': 'users',
                        'title': 'Users',
                        'type': 'item',
                        'icon': 'people',
                        'url': '/apps/users',
                        'securables': [{
                            'id': 'users',
                            'title': 'Users',
                        }]
                    },
                    {
                        'id': 'user-roles',
                        'title': 'User Roles',
                        'type': 'item',
                        'icon': 'security',
                        'url': '/apps/user-roles',
                        'securables': [{
                            'id': 'user-roles',
                            'title': 'User Roles',
                        }]
                    },
                ]
            },
            {
                'id': 'company-settings',
                'title': 'Company Settings',
                'type': 'collapse',
                'icon': 'business',
                'securables': [{
                    'id': 'company-settings',
                    'title': 'Company Settings',
                }],
                'children': [
                    {
                        'id': 'employees',
                        'title': 'Employees',
                        'type': 'item',
                        'icon': 'people',
                        'url': '/apps/employees',
                        'securables': [{
                            'id': 'employees',
                            'title': 'Employees',
                        }]
                    },
                    {
                        'id': 'accounting',
                        'title': 'Accounting',
                        'type': 'collapse',
                        'icon': 'account_balance',
                        'children': [
                            {
                                'id': 'gl-accounts',
                                'title': 'GL Accounts',
                                'type': 'item',
                                'icon': 'format_list_bulleted',
                                'url': '/apps/gl-accounts',
                                'securables': [{
                                    'id': 'gl-accounts',
                                    'title': 'GL Accounts',
                                }]
                            },
                            {
                                'id': 'cost-types',
                                'title': 'Cost Types',
                                'type': 'item',
                                'icon': 'list',
                                'url': '/apps/cost-types',
                                'securables': [{
                                    'id': 'cost-types',
                                    'title': 'Cost Types',
                                }]
                            },
                            {
                                'id': 'tax-codes',
                                'title': 'Tax Codes',
                                'type': 'item',
                                'icon': 'format_list_numbered_rtl',
                                'url': '/apps/tax-codes',
                                'securables': [{
                                    'id': 'tax-codes',
                                    'title': 'Tax Codes',
                                }]
                            },
                            {
                                'id': 'pay-terms',
                                'title': 'Pay Terms',
                                'type': 'item',
                                'icon': 'update',
                                'url': '/apps/pay-terms',
                                'securables': [{
                                    'id': 'pay-terms',
                                    'title': 'Pay Terms',
                                }]
                            },

                        ]
                    },
                    {
                        'id': 'fleet',
                        'title': 'Fleet Vehicles',
                        'type': 'collapse',
                        'icon': 'local_shipping',
                        'children': [
                            {
                                'id': 'fleet-vehicle-categories',
                                'title': 'Fleet Vehicle Categories',
                                'type': 'item',
                                'icon': 'list',
                                'url': '/apps/fleet-vehicle-categories',
                                'securables': [{
                                    'id': 'fleet-vehicle-categories',
                                    'title': 'Fleet Vehicle Categories',
                                }]
                            },
                            {
                                'id': 'fleet-vehicles',
                                'title': 'Fleet Vehicles',
                                'type': 'item',
                                'icon': 'local_shipping',
                                'url': '/apps/fleet-vehicles',
                                'securables': [{
                                    'id': 'fleet-vehicles',
                                    'title': 'Fleet Vehicles',
                                }]
                            },

                        ]
                    },
                    {
                        'id': 'materials',
                        'title': 'Materials',
                        'type': 'collapse',
                        'icon': 'art_track',
                        'children': [
                            {
                                'id': 'material-categories',
                                'title': 'Material Categories',
                                'type': 'item',
                                'icon': 'list',
                                'url': '/apps/material-categories',
                                'securables': [{
                                    'id': 'material-categories',
                                    'title': 'Material Categories',
                                }]
                            },
                            {
                                'id': 'materials',
                                'title': 'Materials',
                                'type': 'item',
                                'icon': 'crop_free',
                                'url': '/apps/materials',
                                'securables': [{
                                    'id': 'materials',
                                    'title': 'Materials',
                                }]
                            },
                            {
                                'id': 'units-of-measure',
                                'title': 'Units of Measure',
                                'type': 'item',
                                'icon': 'label',
                                'url': '/apps/units-of-measure',
                                'securables': [{
                                    'id': 'units-of-measure',
                                    'title': 'Units of Measure',
                                }]
                            },

                        ]
                    },
                    {
                        'id': 'inventory',
                        'title': 'Inventory',
                        'type': 'collapse',
                        'icon': 'store',
                        'children': [
                            {
                                'id': 'inventory-location-groups',
                                'title': 'Location Groups',
                                'type': 'item',
                                'icon': 'list',
                                'url': '/apps/inventory-location-groups',
                                'securables': [{
                                    'id': 'inventory-location-groups',
                                    'title': 'Inventory Location Groups',
                                }]
                            },

                        ]
                    },
                    {
                        'id': 'purchasing',
                        'title': 'Purchasing',
                        'type': 'collapse',
                        'icon': 'list_alt',
                        'children': [
                            {
                                'id': 'purchase-order-options',
                                'title': 'Purchase Order Options',
                                'icon': 'settings',
                                'settings': 'purchase_order_options',
                                'children': []
                            },
                            {
                                'id': 'vendors',
                                'title': 'Vendors',
                                'type': 'item',
                                'icon': 'store',
                                'url': '/apps/vendors',
                                'securables': [{
                                    'id': 'vendors',
                                    'title': 'Vendors',
                                }, {
                                    'id': 'purchase-orders',
                                    'title': 'Purchase Orders',
                                }]
                            }
                        ]
                    },
                    {
                        'id': 'flat-rate',
                        'title': 'Flat Rate',
                        'type': 'collapse',
                        'icon': 'assignment',
                        'children': [
                            {
                                'id': 'catalog',
                                'title': 'Price Book',
                                'type': 'item',
                                'icon': 'chrome_reader_mode',
                                'url': '/apps/catalog',
                                'securables': [{
                                    'id': 'catalog',
                                    'title': 'Price Book',
                                }]
                            },
                            {
                                'id': 'option-board-types',
                                'title': 'Option Board Types',
                                'type': 'item',
                                'icon': 'table_chart',
                                'url': '/apps/option-board-types',
                                'securables': [{
                                    'id': 'option-board-types',
                                    'title': 'Option Board Types',
                                }]
                            }
                        ],
                        'securables': [
                            {
                                'id': 'flat-rate',
                                'title': 'Flat Rate',
                            },
                            {
                                'id': 'sync-price-book',
                                'title': 'Sync Price Book'
                            }
                        ]
                    },
                    {
                        'id': 'phones',
                        'title': 'Phones',
                        'type': 'collapse',
                        'icon': 'phone',
                        'children': [
                            {
                                'id': 'phone-numbers',
                                'title': 'Phone Numbers',
                                'type': 'item',
                                'icon': 'phone_callback',
                                'url': '/apps/phone-numbers',
                                'securables': [{
                                    'id': 'phone-numbers',
                                    'title': 'Phone Numbers',
                                }]
                            },
                            {
                                'id': 'call-gathers',
                                'title': 'Call Gathers',
                                'type': 'item',
                                'icon': 'dialpad',
                                'url': '/apps/call-gathers',
                                'securables': [{
                                    'id': 'call-gathers',
                                    'title': 'Call Gathers',
                                }]
                            },
                            {
                                'id': 'text-gathers',
                                'title': 'Text Gathers',
                                'type': 'item',
                                'icon': 'textsms',
                                'url': '/apps/text-gathers',
                                'securables': [{
                                    'id': 'text-gathers',
                                    'title': 'Text Gathers',
                                }]
                            },
                            {
                                'id': 'call-queues',
                                'title': 'Call Queues',
                                'type': 'item',
                                'icon': 'queue_music',
                                'url': '/apps/call-queues',
                                'securables': [{
                                    'id': 'call-queues',
                                    'title': 'Call Queues',
                                }]
                            },
                            {
                                'id': 'hunt-groups',
                                'title': 'Hunt Groups',
                                'type': 'item',
                                'icon': 'ring_volume',
                                'url': '/apps/hunt-groups',
                                'securables': [{
                                    'id': 'hunt-groups',
                                    'title': 'Hunt Groups',
                                }]
                            },
                        ]
                    },
                    {
                        'id': 'service',
                        'title': 'Service',
                        'type': 'collapse',
                        'icon': 'settings',
                        'children': [
                            {
                                'id': 'business-units',
                                'title': 'Business Units',
                                'type': 'item',
                                'icon': 'business_center',
                                'url': '/apps/business-units',
                                'securables': [{
                                    'id': 'business-units',
                                    'title': 'Business Units',
                                    'form': 'business-unit',
                                    'reportCategory': 'Business Units',
                                    'fields': [
                                        { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                                        { 'value': 'BusinessUnit', 'type': 'text', 'list': 'businessUnits', 'text': ['Name'] },
                                    ]
                                }]
                            },
                            {
                                'id': 'divisions',
                                'title': 'Divisions',
                                'type': 'item',
                                'icon': 'location_city',
                                'url': '/apps/divisions',
                                'securables': [{
                                    'id': 'divisions',
                                    'title': 'Divisions',
                                    'form': 'division',
                                    'reportCategory': 'Divisions',
                                    'fields': [
                                        { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                                        { 'value': 'BusinessUnit', 'type': 'text', 'list': 'businessUnits', 'text': ['Name'] },
                                        { 'value': 'Division', 'type': 'text', 'list': 'divisions', 'text': ['Name'] },
                                    ]
                                }]
                            },
                            {
                                'id': 'departments',
                                'title': 'Departments',
                                'type': 'item',
                                'icon': 'business',
                                'url': '/apps/departments',
                                'securables': [{
                                    'id': 'departments',
                                    'title': 'Departments',
                                    'form': 'department',
                                    'reportCategory': 'Departments',
                                    'fields': [
                                        { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                                        { 'value': 'BusinessUnit', 'type': 'text', 'list': 'businessUnits', 'text': ['Name'] },
                                        { 'value': 'Division', 'type': 'text', 'list': 'divisions', 'text': ['Name'] },
                                        { 'value': 'Department', 'type': 'text', 'list': 'departments', 'text': ['Name'] },
                                    ]
                                }]
                            },
                            {
                                'id': 'service-types',
                                'title': 'Service Types',
                                'type': 'item',
                                'icon': 'settings',
                                'url': '/apps/service-types',
                                'securables': [{
                                    'id': 'service-types',
                                    'title': 'Service Types',
                                }]
                            },
                            {
                                'id': 'services',
                                'title': 'Services',
                                'type': 'item',
                                'icon': 'build',
                                'url': '/apps/services',
                                'securables': [{
                                    'id': 'services',
                                    'title': 'Services',
                                }]
                            },
                            {
                                'id': 'rate-templates',
                                'title': 'Rate Templates',
                                'type': 'item',
                                'icon': 'ballot',
                                'url': '/apps/rate-templates',
                                'securables': [{
                                    'id': 'rate-templates',
                                    'title': 'Rate Templates',
                                }]
                            },
                            {
                                'id': 'labor-types',
                                'title': 'Labor Types',
                                'type': 'item',
                                'icon': 'format_list_numbered',
                                'url': '/apps/labor-types',
                                'securables': [{
                                    'id': 'labor-types',
                                    'title': 'Labor Types',
                                }]
                            },
                            {
                                'id': 'forms',
                                'title': 'Forms',
                                'type': 'collapse',
                                'icon': 'assignment_turned_in',
                                'children': [
                                    {
                                        'id': 'forms',
                                        'title': 'Forms',
                                        'type': 'item',
                                        'icon': 'assignment',
                                        'url': '/apps/forms',
                                        'securables': [{
                                            'id': 'forms',
                                            'title': 'Forms',
                                        }]
                                    },
                                    {
                                        'id': 'checklists',
                                        'title': 'Checklists',
                                        'type': 'item',
                                        'icon': 'check_box',
                                        'url': '/apps/checklists',
                                        'securables': [{
                                            'id': 'checklists',
                                            'title': 'Checklists',
                                        }]
                                    },
                                ]
                            },
                            {
                                'id': 'form-of-payments',
                                'title': 'Forms of Payment',
                                'type': 'item',
                                'icon': 'payment',
                                'url': '/apps/form-of-payments',
                                'securables': [{
                                    'id': 'form-of-payments',
                                    'title': 'Forms of Payment',
                                }]
                            },
                            {
                                'id': 'arrival-windows',
                                'title': 'Arrival Windows',
                                'type': 'item',
                                'icon': 'access_time',
                                'url': '/apps/arrival-windows',
                                'securables': [{
                                    'id': 'arrival-windows',
                                    'title': 'Arrival Windows',
                                }]
                            },
                            {
                                'id': 'technicians',
                                'title': 'Technicians',
                                'type': 'item',
                                'icon': 'people',
                                'url': '/apps/technicians',
                                'securables': [{
                                    'id': 'technicians',
                                    'title': 'Technicians',
                                    'form': 'technician',
                                    'reportCategory': 'Technicians',
                                    'fields': [
                                        { 'value': 'Co', 'type': 'number', 'list': 'companies.List', 'text': ['Data.Co.Name'] },
                                        { 'value': 'Technician', 'type': 'text', 'list': 'technicians', 'text': ['FirstName', 'LastName'] },
                                    ]
                                }]
                            },
                            {
                                'id': 'agreement-types',
                                'title': 'Agreement Types',
                                'type': 'item',
                                'icon': 'library_books',
                                'url': '/apps/agreement-types',
                                'securables': [{
                                    'id': 'agreement-types',
                                    'title': 'Agreement Types',
                                }]
                            },
                        ]
                    },
                    {
                        'id': 'service-units',
                        'title': 'Service Units',
                        'type': 'collapse',
                        'icon': 'list',
                        'children': [
                            {
                                'id': 'service-unit-categories',
                                'title': 'Service Unit Categories',
                                'type': 'item',
                                'icon': 'settings',
                                'url': '/apps/service-unit-categories',
                                'securables': [{
                                    'id': 'service-unit-categories',
                                    'title': 'Service Unit Categories',
                                }]
                            },
                            {
                                'id': 'service-unit-types',
                                'title': 'Service Unit Types',
                                'type': 'item',
                                'icon': 'list',
                                'url': '/apps/service-unit-types',
                                'securables': [{
                                    'id': 'service-unit-types',
                                    'title': 'Service Unit Types',
                                }]
                            },

                        ]
                    },
                ]
            },
            // {
            //     'id': 'user-settings',
            //     'title': 'User Profile',
            //     'type': 'item',
            //     'icon': 'person',
            //     'url': '/pages/profile'
            // },
        ],
    },
];
