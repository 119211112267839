import React, {Component} from 'react';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles/index';
import {Hidden, Icon, IconButton, Input, Paper, Typography} from '@material-ui/core';
import * as Actions from './store/actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {FuseAnimate, FuseSelectedTheme} from '@fuse';

const styles = theme => ({
    root: {}
});

class WorkOrderUsagesHeader extends Component {

    render()
    {
        const { classes, setSearchText, searchText, pageLayout } = this.props;
        return (
            <MuiThemeProvider theme={FuseSelectedTheme}>
                <div className="flex flex-1">
                    <Paper className="flex items-center w-full h-48 sm:h-56 p-16 pl-4 md:pl-16 rounded-8 " elevation={1}>
                        <Hidden xlUp>
                            <IconButton
                                onClick={(ev) => pageLayout().toggleLeftSidebar()}
                                aria-label="open left sidebar"
                            >
                                <Icon>menu</Icon>
                            </IconButton>
                        </Hidden>

                        <Icon color="action">search</Icon>

                        <Input
                            placeholder="Search WorkOrderUsages"
                            className="pl-16"
                            disableUnderline
                            fullWidth
                            value={searchText}
                            inputProps={{
                                'aria-label': 'Search'
                            }}
                            onChange={setSearchText}
                        />
                        <div className="flex flex-shrink items-center w-1/5">

                        </div>
                    </Paper>
                </div>
            </MuiThemeProvider>
        )
            ;
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        setSearchText: Actions.setSearchText
    }, dispatch);
}

function mapStateToProps({workOrderUsagesApp})
{
    return {
        searchText: workOrderUsagesApp.workOrderUsages.searchText
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(WorkOrderUsagesHeader));
