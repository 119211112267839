import UserRolesApp from 'main/content/apps/user-roles/UserRolesApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const UserRolesAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/user-roles/:id',
            component: UserRolesApp
        },
        {
            path     : '/apps/user-roles',
            component: () => <Redirect to="/apps/user-roles/all"/>
        }
    ]
};
