import axios from 'axios/index';
import moment from 'moment';
import _ from 'lodash';
import { SHOW_MESSAGE } from 'store/actions';
import CacheManager from 'store/middlewares/cache';

const offline = new CacheManager();

export const SET_OFFLINE_DATA = '[SPCONNECTION] SET OFFLINE DATA';
export const SET_OFFLINE_SCHEDULES = '[SPCONNECTION] SET OFFLINE SCHEDULES';
export const SET_OFFLINE_WORK_ORDERS = '[SPCONNECTION] SET OFFLINE WORK_ORDERS';
export const UPDATE_OFFLINE_DATA = '[SPCONNECTION] UPDATE OFFLINE DATA';
export const REMOVE_OFFLINE_DATA = '[SPCONNECTION] REMOVE OFFLINE DATA';
export const SET_OFFLINE_STATUS = '[SPCONNECTION] SET OFFLINE STATUS';
export const GET_LAST_STATE = '[SPCONNECTION] GET LAST STATE';
export const GET_LAST_AUTH = '[SPCONNECTION] GET LAST AUTH';

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function setOfflineWorkOrders(workOrders) {
    return {
        type: SET_OFFLINE_WORK_ORDERS,
        workOrders
    }
}

export function setOfflineSchedules(schedules) {
    return {
        type: SET_OFFLINE_SCHEDULES,
        schedules
    }
}

export function setOfflineStatus() {

    // console.log(`Network Connection is Offline`);
    return (dispatch, getState) => {
        const state = getState();
        const { auth } = state;
        const loggedIn = auth.login.success;
        if (loggedIn) {
            dispatch({
                type: SET_OFFLINE_STATUS,
                offline: true
            });
        }
    }
}

export function setOnlineStatus() {

    // console.log(`Network Connection is Online`);
    return (dispatch, getState) => {
        const state = getState();
        const { auth } = state;
        const loggedIn = auth.login.success;
        if (loggedIn) {
            dispatch({
                type: SET_OFFLINE_STATUS,
                offline: false
            });
        }
    }
}

export function getOfflineData() {
    return (dispatch) => {
        offline.get('offline').then((data) => {
            dispatch({
                type: SET_OFFLINE_DATA,
                data: data || []
            });
            offline.remove('offline');
        });
    }
}

export function setOfflineData(action, method, url, obj, module) {
    return (dispatch, getState) => {
        const state = getState();
        const { data } = state.spReducers.offline;
        switch (action) {
            case 'ADD':
                {
                    obj.OfflineID = obj.OfflineID || guid();
                    data.push({ method, url, obj, module, timestamp: new Date() });
                }
                break;
            case 'UPDATE':
                {
                    const id = obj ? obj.ID || obj.OfflineID : null;
                    if (id) {
                        const index = _.findIndex(data, (o) => { return (o.obj.ID || o.obj.OfflineID) === id });
                        if (index > -1) {
                            data[index].obj = { ...obj };
                        } else {
                            data.push({ method, url, obj, module, timestamp: new Date() });
                        }
                    }
                }
                break;
            case 'REMOVE':
                {
                    const id = obj ? obj.ID || obj.OfflineID : null;
                    if (id) {
                        const index = _.findIndex(data, (o) => { return (o.obj.ID || o.obj.OfflineID) === id });
                        if (index > -1) {
                            if (data[index].obj.ID) {
                                data[index] = { method, url, obj: { ...obj } };
                            } else {
                                data.splice(index, 1);
                            }
                        } else {
                            data.push({ method, url, obj, module, timestamp: new Date() });
                        }
                    }
                }
                break;
        };
        dispatch(storeOfflineData(data));
    }
}

export function storeOfflineData(data) {
    // offline.set('offline', data);
    return {
        type: SET_OFFLINE_DATA,
        data
    }
}

export function syncOfflineData(index) {
    return (dispatch, getState) => {
        const state = getState();
        const { data } = state.spReducers.offline;
        const next = index || 0;
        if (data.length > next) {
            dispatch({
                type: SHOW_MESSAGE,
                options: {
                    message: `Uploading Offline Data...`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'uploading'
                }
            });
            dispatch(processOfflineData(data[next], next));
        } else {
            dispatch({
                type: SHOW_MESSAGE,
                options: {
                    message: `Offline Data Sync Complete.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'uploaded'
                }
            })
        }
    }
}

export function processOfflineData(item, index, single) {
    const { method, url, obj, module } = item;
    const request = axios[method](url, obj);
    return (dispatch, getState) => {
        // console.warn(item, index);
        return request.then((response) => {
            const state = getState();
            const { data } = state.spReducers.offline;
            if (data.length > index) {
                data.splice(index, 1);
            }
            Promise.all([
                dispatch(storeOfflineData(data)),
            ]).then(() => {
                if (!single) {
                    dispatch(syncOfflineData(index));
                }
            });
        }).catch((error) => {
            // console.warn('Error uploading data: ', error);
            if (error.message) {
                item.syncError = error.message;
                dispatch({
                    type: SHOW_MESSAGE,
                    options: {
                        message: `${error.message}`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }
                });
            }
            item.syncAttempts = (item.syncAttempts || 0) + 1;
            if (!single) {
                dispatch(syncOfflineData(index + 1));
            }
        });
    }
}