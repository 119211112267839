import { FuseUtils } from '@fuse';
import _ from '@lodash';
import { Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CatalogAssemblyCard from '../catalog/CatalogAssemblyCard';
import { updateTableSettings } from '../users/store/actions';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 256px)',
        paddingBottom: 0,
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    techAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },

});

class EstimatesList extends Component {

    state = {
        selectedEstimatesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    removeComponent = (value) => {
        if (this.props.onRemoveComponent) {
            this.props.onRemoveComponent();
        }
    }

    render() {
        const { classes, components, estimate, onAddNew, optionBoard } = this.props;

        return (
            <div className="w-full flex flex-wrap">
                {components && _.filter(components, (o) => o.DeleteYN !== 'Y').map((value) => {
                    const assembly = value.Data.Assembly;
                    if (assembly) {
                        return (
                            <CatalogAssemblyCard onClickBefore={onAddNew} estimate={estimate} onDelete={() => this.removeComponent(assembly)} value={assembly} component={value} key={value.Component} />
                        );
                    }
                })}
                {onAddNew && !optionBoard &&
                    <Typography variant="subtitle2" onClick={onAddNew} style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer"><Icon color="primary" className="text-24 m-6">add</Icon>Add Assembly</Typography>
                }
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditEstimateDialog: Actions.openEditEstimateDialog,
        updateTableSettings,
    }, dispatch);
}

function mapStateToProps({ estimatesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        companies: spReducers.companies,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        technicians: spReducers.technicians,
        // assemblies: spReducers.catalog.assemblies
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesList)));
