import _ from '@lodash';
import { Button, MenuItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../../components/autocomplete/Autocomplete';


const styles = theme => ({
    root: {

    },
    updateOverride: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteOverride: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    borderTop: {
        borderTop: '1px solid lightgrey',
        paddingTop: 4,
        paddingBottom: 4,
    },
    liRoot: {
        padding: 0
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        maxWidth: '70%',
    },
    wrap: {
        whiteSpace: 'normal !important',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
        maxWidth: '70%',
    },
});

const initialState = {
    ID: null,
    Co: null,
    DefaultUOM: 'LSU',
    DefaultTaxCode: null,
    DefaultShippingMethod: 'W',
    DefaultPayTerms: null,
    DefaultCostType: null,
    CostTypeRequired: null,
    Data: {
        ErrMsg: null
    }
}

class TripsStatusTerminology extends Component {

    state = {
        ..._.cloneDeepWith(initialState)
    }

    componentDidMount() {
        const { PurchaseOrderOptions } = this.props.company.Data;
        this.setState({ ..._.cloneDeepWith(initialState), ...PurchaseOrderOptions });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { PurchaseOrderOptions } = this.props.company.Data;
        if (!_.isEqual(PurchaseOrderOptions, prevProps.company.Data.PurchaseOrderOptions)) {
            this.setState({ ..._.cloneDeepWith(initialState), ...PurchaseOrderOptions });
        }
    }

    // handleChange = (event) => {
    //     this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    // };

    saveOptions = () => {
        const { Co } = this.props;
        const request = axios.put(`${window["apiLocation"]}/api/PurchaseOrderOptions?Co=${Co}`, { ...this.state, Co });

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                this.props.showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                });
            } else {
                this.setState({ override: _.cloneDeepWith(initialState.override) }, () => {
                    this.props.showMessage({
                        message: `Purchase Order Options successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
            }
        }).catch((error) => {
            this.props.showMessage({
                message: `Error: ${error.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), this.handleTechLabor);
    };

    render() {
        const { classes, user, data, securables, company, uoms, taxCodes, payTerms, costTypes } = this.props;
        const accessLevel = _.find(securables, { Securable: "companies" });

        window["warn"]('Purchase Order Options: ', this.state, this.props.company);

        return (
            <React.Fragment>
                <div className={classNames("w-full")}>
                    {accessLevel && accessLevel.AccessLevel === "F" &&
                        <React.Fragment>
                            {/* <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">settings</Icon>Purchase Order Options</Typography> */}
                            <div className="sm:flex w-full">
                                <Autocomplete
                                    className="mb-24"
                                    title="Default Unit of Measure"
                                    options={uoms}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.UOM}>
                                                <div className="flex">
                                                    {`${value.UOM} - ${value.Description}`}
                                                </div>
                                            </MenuItem>
                                    }}
                                    value={this.state.DefaultUOM || ''}
                                    noflip={true}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultUOM: option.UOM })}
                                    portal={true}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className="mb-24"
                                    label="Default Shipping Method"
                                    select
                                    id="defaultShippingMethod"
                                    name="DefaultShippingMethod"
                                    value={this.state.DefaultShippingMethod || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value="W">
                                        Will Call
                                    </MenuItem>
                                    <MenuItem value="D">
                                        Deliver
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="sm:flex w-full">
                                <Autocomplete
                                    className="mb-24"
                                    title="Pay Terms"
                                    options={[{ PayTerms: null, Description: 'None' }, ...payTerms]}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.PayTerms}>
                                            {`${value.PayTerms ? `${value.PayTerms} - ` : ''}${value.Description}`}
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.DefaultPayTerms || ''}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultPayTerms: option.PayTerms })}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <Autocomplete
                                    className="mb-24"
                                    title="Tax Code"
                                    options={[{ TaxCode: null, Description: 'None' }, ...taxCodes]}
                                    menuItemComponent={(value) => (
                                        <MenuItem value={value.TaxCode}>
                                            {`${value.Description}${value.TaxCode ? ` - ${value.TaxCode}` : ''}`}
                                        </MenuItem>
                                    )}
                                    portal={true}
                                    value={this.state.DefaultTaxCode || ''}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultTaxCode: option.TaxCode })}
                                />
                            </div>

                            <div className="sm:flex w-full">
                                <Autocomplete
                                    className="mb-24"
                                    title="Default Cost Type"
                                    options={[{ CostType: null, Name: 'None' }, ...costTypes]}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.CostType}>
                                            {`${value.CostType ? `${value.CostType} - ` : ''}${value.Name}`}
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.DefaultCostType || ''}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultCostType: option.CostType })}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className="mb-24"
                                    label="Require Cost Type?"
                                    select
                                    id="requireCostType"
                                    name="RequireCostType"
                                    value={this.state.RequireCostType || 'N'}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value="N">
                                        No
                                    </MenuItem>
                                    <MenuItem value="Y">
                                        Yes
                                    </MenuItem>
                                </TextField>
                            </div>
                        </React.Fragment>
                    }
                    {/* {statusProps.map((value, index) => {
                    let override = _.find(company.Data.TripStatusTerminologies, { Status: index });
                    if (!override) {
                        override = {
                            ..._.cloneDeepWith(initialState.override),
                            Status: index,
                            TechnicianTerminology: value.text,
                            DispatchTerminology: value.text,
                            ButtonText: value.text,
                            ButtonStyle: 'B',
                        };
                    }
                    return (
                        <div key={index} onClick={() => this.setState({ override })} className={classNames("w-full p-12", index > 0 ? classes.borderTop : '')}>
                            <ListItem classes={{ root: classes.liRoot }} className="relative cursor-pointer min-h-36">
                                {value.icon &&
                                    <Icon className="mr-6" style={{ color: value.color }}>{value.icon}</Icon>
                                }
                                <ListItemText classes={{ root: classes.liRoot, primary: classes.title }} primary={<span className={classNames("font-bold text-14")}>{override.TechnicianTerminology || value.text}
                                </span>} />
                                <Icon className="pin-r pin-b pin-t absolute m-auto text-16">{override && override.ID ? "edit" : "add_circle_outline"}</Icon>
                            </ListItem>
                        </div>
                    );
                })} */}
                </div>
                <div className="w-full justify-between pin-l pin-r pin-b absolute p-12">
                    <Button variant="contained" color="primary" onClick={this.saveOptions}>Save</Button>
                </div>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage
    }, dispatch);
}

function mapStateToProps({ companiesApp, spReducers }) {
    return {
        Co: companiesApp.companies.companyDialog.data.Co,
        user: spReducers.userProfiles.User,
        company: _.find(spReducers.companies.List, { Co: companiesApp.companies.companyDialog.data.Co }).Data.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        taxCodes: spReducers.taxCodes,
        payTerms: spReducers.payTerms,
        costTypes: spReducers.costTypes,
        uoms: spReducers.uoms,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TripsStatusTerminology)));
