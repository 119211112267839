import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class LaborTypesList extends Component {

    state = {
        selectedLaborTypesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedLaborTypeMenu = (event) => {
        this.setState({ selectedLaborTypesMenu: event.currentTarget });
    };

    closeSelectedLaborTypesMenu = () => {
        this.setState({ selectedLaborTypesMenu: null });
    };

    render() {
        const { classes, securables, laborTypes, user, searchText, openNewLaborTypeDialog, selectedLaborTypeIds, selectAllLaborTypes, deSelectAllLaborTypes, toggleInSelectedLaborTypes, openEditLaborTypeDialog, removeLaborTypes, removeLaborType, toggleStarredLaborType, setLaborTypesUnstarred, setLaborTypesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.laborTypeData ? [] : this.props.laborTypeData), searchText);
        const { selectedLaborTypesMenu } = this.state;
        const types = { 'W': 'Work Order', 'I': 'Indirect', 'O': 'Other' };
        const accessLevel = _.find(securables, { Securable: "labor-types" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewLaborTypeDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Labor Types</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditLaborTypeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Labor Type",
                                accessor: "LaborType",
                                className: "font-bold justify-center"
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                            },
                            {
                                Header: "Category",
                                accessor: "type",
                                className: "justify-center",
                                Cell: row => row.original.Type && types[row.original.Type]
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center"
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Labor Types found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getLaborTypes: Actions.getLaborTypes,
        getUserData: Actions.getUserData,
        toggleInSelectedLaborTypes: Actions.toggleInSelectedLaborTypes,
        selectAllLaborTypes: Actions.selectAllLaborTypes,
        deSelectAllLaborTypes: Actions.deSelectAllLaborTypes,
        openEditLaborTypeDialog: Actions.openEditLaborTypeDialog,
        removeLaborTypes: Actions.removeLaborTypes,
        removeLaborType: Actions.removeLaborType,
        toggleStarredLaborType: Actions.toggleStarredLaborType,
        toggleStarredLaborTypes: Actions.toggleStarredLaborTypes,
        setLaborTypesStarred: Actions.setLaborTypesStarred,
        setLaborTypesUnstarred: Actions.setLaborTypesUnstarred,
        openNewLaborTypeDialog: Actions.openNewLaborTypeDialog
    }, dispatch);
}

function mapStateToProps({ laborTypesApp, spReducers }) {
    return {
        laborTypes: laborTypesApp.laborTypes.entities,
        selectedLaborTypeIds: laborTypesApp.laborTypes.selectedLaborTypeIds,
        searchText: laborTypesApp.laborTypes.searchText,
        user: laborTypesApp.user,
        laborTypeData: spReducers.laborTypes,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(LaborTypesList)));
