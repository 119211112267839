import React, { useState } from "react";
import { CheckCircleOutline, ErrorOutline, AddCircleOutline } from '@material-ui/icons';

function BNGElement(props) {

    const selectedValue = props.value;

    window["warn"]('BNG Values: ', props.value, props.name, selectedValue);

    const colors = {
        initialGreen: 'linear-gradient(to top, rgb(53, 120, 0), rgb(96, 255, 0))',
        selectedGreen: 'linear-gradient(to top, rgb(53, 120, 0), rgb(96, 255, 0))',
        green: 'rgb(187, 249, 187)',
        initialYellow: 'linear-gradient(to top, rgb(120 108 0), rgb(245, 245, 4))',
        selectedYellow: 'linear-gradient(to top, rgb(120 108 0), rgb(245, 245, 4))',
        yellow: 'rgb(235, 235, 171)',
        initialRed: 'linear-gradient(to top, rgb(123 19 19), rgb(244, 67, 54))',
        selectedRed: 'linear-gradient(to top, rgb(123 19 19), rgb(244, 67, 54))',
        red: 'rgb(233, 173, 173)'
    }
    return (
        <div className="relative p-4">
            <div
                className="relative flex flex-row"
                style={{
                    borderRadius: 999,
                    boxShadow: "2px 2px 4px 0px rgb(0 0 0 /75%)",
                }}
            >
                <div
                    style={{
                        minWidth: 64,
                        width: "100%",
                        height: 32,
                        background: !selectedValue ? colors.initialRed : selectedValue === "B" ? colors.selectedRed : colors.red,
                        color: !selectedValue ? "#333" : selectedValue === "B" ? "#333" : "#8f8f8f",
                        fontWeight: 700,
                        lineHeight: '3.2rem',
                        textAlign: "center",
                        borderTopLeftRadius: 999,
                        borderBottomLeftRadius: 999,
                        cursor: "pointer",
                        textTransform: 'uppercase',
                        opacity: selectedValue && selectedValue !== "B" ? .25 : 1,
                        color: '#f5f5f5',
                        textShadow: '2px 1px 2px #333'
                    }}
                    className="px-8 truncate"
                    onClick={() => { props.onChange({ target: { name: props.name, value: "B" } }); }}
                >
                    {selectedValue === "B" && <AddCircleOutline style={{ transform: 'rotate(-45deg)', filter: 'drop-shadow(2px 1px 2px #333)', fill: '#f5f5f5' }} className="mr-4 align-middle mb-4" />}{props.text ? props.text.redText || '' : ''}
                </div>

                <div
                    style={{
                        height: 32,
                        minHeight: 32,
                        borderLeft: "1px solid lightgrey",
                        cursor: "pointer",
                    }}
                >
                </div>

                <div
                    style={{
                        minWidth: 64,
                        width: "100%",
                        height: 32,
                        background: !selectedValue ? colors.initialYellow : selectedValue === "N" ? colors.selectedYellow : colors.yellow,
                        color: !selectedValue ? "#333" : selectedValue === "N" ? "#333" : "#8f8f8f",
                        fontWeight: 700,
                        lineHeight: '3.2rem',
                        textAlign: "center",
                        cursor: "pointer",
                        textTransform: 'uppercase',
                        opacity: selectedValue && selectedValue !== "N" ? .25 : 1,
                        color: '#f5f5f5',
                        textShadow: '2px 1px 2px #333'
                    }}
                    className="px-8 truncate"
                    onClick={() => { props.onChange({ target: { name: props.name, value: "N" } }); }}
                >
                    {selectedValue === "N" && <ErrorOutline className="mr-4 align-middle mb-4" style={{ filter: 'drop-shadow(2px 1px 2px #333)', fill: '#f5f5f5' }} />}{props.text ? props.text.yellowText || '' : ''}
                </div>

                <div
                    style={{
                        height: 32,
                        minHeight: 32,
                        borderRight: "1px solid lightgrey",
                        cursor: "pointer",
                    }}
                >
                </div>

                <div
                    style={{
                        minWidth: 64,
                        width: "100%",
                        height: 32,
                        background: !selectedValue ? colors.initialGreen : selectedValue === "G" ? colors.selectedGreen : colors.green,
                        color: !selectedValue ? "#333" : selectedValue === "G" ? "#333" : "#8f8f8f",
                        fontWeight: 700,
                        lineHeight: '3.2rem',
                        textAlign: "center",
                        borderTopRightRadius: 999,
                        borderBottomRightRadius: 999,
                        cursor: "pointer",
                        textTransform: 'uppercase',
                        opacity: selectedValue && selectedValue !== "G" ? .25 : 1,
                        color: '#f5f5f5',
                        textShadow: '2px 1px 2px #333'
                    }}
                    className="px-8 truncate"
                    onClick={() => { props.onChange({ target: { name: props.name, value: "G" } }); }}
                >
                    {selectedValue === "G" && <CheckCircleOutline className="mr-4 align-middle mb-4" style={{ filter: 'drop-shadow(2px 1px 2px #333)', fill: '#f5f5f5' }} />}{props.text ? props.text.greenText || '' : ''}
                </div>
            </div>
        </div>
    );
}

export default BNGElement;