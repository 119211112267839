import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, FormControlLabel, Checkbox, Card, CardHeader, CardContent, Divider, Fab, Icon, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import axios from 'axios';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    card: {
        marginBottom: 12,
        cursor: 'pointer',
        width: '100%',
    },
    cardHeader: {
        padding: 8,
    },
    cardContent: {
        borderTop: '1px solid lightgrey',
        padding: 8,
        paddingBottom: '8px !important',
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    action: {
        marginTop: 0,
        alignSelf: 'auto',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: '1rem'
    },
});

class PhoneNumbersSidebarContent extends Component {

    state = {
        Co: null,
        search: '',
        results: [],
        user: null,
        tollFree: "N",
    }

    componentDidMount() {
        const { Co, results, user } = this.props;
        this.setState({ ...this.state, Co, results, user });
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co, results, user } = this.props;
        if (!_.isEqual(Co, prevProps.Co) || !_.isEqual(results, prevProps.results) || !_.isEqual(user, prevProps.user)) {
            this.setState({ ...this.state, Co, results, user });
        }
    }
    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleSearch = () => {
        const { Co, search, tollFree } = this.state;
        var area = search.substr(0, 3);
        if (area.length > 2) {
            this.props.searchAvailableNumbers(Co, area, tollFree === 'Y' ? search : search.substr(2, search.length - 4), tollFree);
        }
    }

    stop = (event) => {
        event.stopPropagation();
    }

    handlePurchase = (num) => {
        const { user } = this.props;
        window["warn"](this.props, this.state);
        this.props.purchasePhoneNumber({ ...num, User: user });
    }

    render() {
        const { classes, purchasePhoneNumber, phoneNumbers } = this.props;
        const { search, user, results, Co } = this.state;
        const local = _.filter(phoneNumbers, (o) => o.TollFreeYN !== 'Y');
        const tollFree = _.filter(phoneNumbers, { TollFreeYN: 'Y' });
        const tollFreeText = (tollFree.length >= 3 ? 'Purchase - $2/Month' : 'Claim Number');
        const localText = (local.length >= 20 ? 'Purchase - $1/Month' : 'Claim Number');
        return (
            <div className="p-16 lg:pl-24">
                <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                    <Paper elevation={1} className="rounded-8">

                        <div className="flex p-8" onClick={this.stop}>
                            <TextField
                                className={classes.formControl}
                                label={this.state.tollFree !== "Y" ? "Starts With" : "Contains"}
                                id="search"
                                name="search"
                                value={search}
                                onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon color="primary">search</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Button variant="contained" size="small" style={{ fontSize: '1rem', padding: 0, }} className="capitalize" color="primary" onClick={this.handleSearch}>Search</Button>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className="ml-20 w-16 h-16"
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={this.state.tollFree === "Y"}
                                    onChange={this.handleChange}
                                    name="tollFree"
                                    color="primary"
                                />
                            }
                            label={<label className="text-11">Toll Free</label>}
                        />
                        <Divider className="mt-8" />
                        <List className="p-8 pt-16">
                            <FuseAnimateGroup enter={{ animation: "transition.slideUpIn" }}>
                                {
                                    (results && results.length > 0) ?
                                        results.map((value, index) =>
                                            <Card
                                                className={classes.card}
                                                key={index}
                                                onClick={this.stop}
                                            >
                                                <CardHeader
                                                    classes={
                                                        { root: classes.cardHeader, content: classes.content, action: classes.action, avatar: classes.icon, title: classes.title, subheader: classes.subheader, }
                                                    }
                                                    avatar={
                                                        <Avatar className={classes.avatar}>
                                                            <Icon className="text-20">phone_callback</Icon>
                                                        </Avatar>
                                                    }
                                                    title={
                                                        value.Number
                                                    }
                                                    subheader={
                                                        value.TollFreeYN !== "Y" && `${value.RateCenter}, ${value.Region}`
                                                    }
                                                />
                                                <CardContent classes={{ root: classes.cardContent }}>
                                                    <Typography variant="caption">Capabilities:</Typography>
                                                    <div className="flex justify-center">
                                                        {
                                                            value.Capabilities.Voice &&
                                                            <Icon className="m-8" color="primary">phone</Icon>
                                                        }
                                                        {
                                                            value.Capabilities.SMS &&
                                                            <Icon className="m-8" color="primary">sms</Icon>
                                                        }
                                                        {
                                                            value.Capabilities.MMS &&
                                                            <Icon className="m-8" color="primary">mms</Icon>
                                                        }
                                                    </div>
                                                    <Button variant="contained" onClick={() => { this.handlePurchase(value); }} color="secondary" className="capitalize text-12 font-bold" fullWidth><Icon className="text-20 mr-8">shopping_cart</Icon>{value.TollFreeYN === 'Y' ? tollFreeText : localText}</Button>
                                                </CardContent>
                                            </Card>
                                        ) :
                                        <Card
                                            className={classes.card}
                                            onClick={this.stop}
                                        >
                                            <CardHeader
                                                classes={
                                                    { root: classes.cardHeader, content: classes.content, action: classes.action, avatar: classes.icon, title: classes.title, subheader: classes.subheader, }
                                                }
                                                title={
                                                    <Typography variant="caption">No Phone Numbers Found</Typography>
                                                }
                                            />
                                        </Card>
                                }
                            </FuseAnimateGroup>
                        </List>
                    </Paper>
                </FuseAnimate>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        purchasePhoneNumber: Actions.purchasePhoneNumber,
        searchAvailableNumbers: Actions.searchAvailableNumbers,
    }, dispatch);
}

function mapStateToProps({ phoneNumbersApp, usersApp, spReducers }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        Co: spReducers.companies.Co,
        results: phoneNumbersApp.phoneNumbers.searchResults,
        phoneNumbers: spReducers.phoneNumbers
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PhoneNumbersSidebarContent)));
