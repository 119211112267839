import React from 'react';
import { withStyles, Avatar, ListItem, Tooltip, Typography } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import classNames from 'classnames';
import Player from './Player';
import _ from '@lodash';
import { PlayButton, Progress, VolumeControl } from 'react-soundplayer/components';
import getProfileImage from '../../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {},
    commentBubble: {

    }
});

const CardActivity = ({ Co, item, users, classes }) => {
    const user = item.Call && item.Call.HandledBy ? _.find(users, { UserName: item.Call.HandledBy }) : null;
    const avatar = 'assets/images/avatars/profile.jpg';

    return (
        <ListItem dense className="px-0">
            <Tooltip title={user ? `${user.FirstName} ${user.LastName}` : item.Sid} placement="top">
                <Avatar alt={user ? user.FirstName : item.Sid} src={user && user.Data && user.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${user.Data.Avatar}`) : avatar} className="w-32 h-32" />
            </Tooltip>
            <div className={"w-full flex flex-col ml-16"}>
                <div className="">
                    <Typography className="font-bold" color="textSecondary">{`${(Number(item.Duration) / 60).toFixed(4).split('.')[0]} Minutes ${Number(item.Duration) - (Number((Number(item.Duration) / 60).toFixed(4).split('.')[0]) * 60)} Seconds`}</Typography>
                    <Typography className="text-12" color="textSecondary">{new Date(item.StartTime).toLocaleString('en-US')}</Typography>
                </div>
                <audio controls style={{ width: '100%' }}>
                    <source src={`${window["apiLocation"]}/api/CallRecordings/File?Recording=${encodeURIComponent(item.Sid)}`} type="audio/x-wav" />
                </audio>

            </div>
        </ListItem>
    )
};

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(CardActivity));
