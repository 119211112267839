import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedPurchaseOrderLineIds: [],
    routeParams: {},
    purchaseOrderLineDialog: {
        type: 'new',
        props: {
            open: false,
            readOnly: false
        },
        data: {
            ID: null,
            Co: null,
            PO: null,
            POLine: null,
            POLineType: null,
            DateNeeded: new Date(),
            Description: null,
            UOM: null,
            VendorMaterial: null,
            Material: null,
            InventoryLocation: null,
            WorkOrder: null,
            Scope: null,
            Qty: null,
            CostType: null,
            UnitCost: null,
            CostTotal: null,
            TaxableYN: 'N',
            TaxCode: null,
            TaxRate: null,
            TaxTotal: null,
            ReceivingYN: 'N',
            ReceivedQty: null,
            ReceivedCostTotal: null,
            ReceivedTaxTotal: null,
            RemainingQty: null,
            RemainingCostTotal: null,
            RemainingTaxTotal: null,
            Notes: null,
            AttachmentID: null,
            Data: {
                ErrMsg: null,
                Receiving: [],
            },
            receiving: {
                ID: null,
                Co: null,
                PO: null,
                POLine: null,
                Seq: null,
                ReceivedQty: null,
                ReceivedCostTotal: null,
                ReceivedTaxTotal: null,
                ReceivedDate: new Date(),
                Notes: null,
                AddedBy: null,
                AddedDate: null,
                UpdatedBy: null,
                UpdatedDate: null,
                Data: {
                    ErrMsg: "",
                },
            },
        }
    }
};

const purchaseOrderLinesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PURCHASE_ORDER_LINES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_PURCHASE_ORDER_LINES:
            {

                const purchaseOrderLineId = action.purchaseOrderLineId;

                let selectedPurchaseOrderLineIds = [...state.selectedPurchaseOrderLineIds];

                if (selectedPurchaseOrderLineIds.find(id => id === purchaseOrderLineId) !== undefined) {
                    selectedPurchaseOrderLineIds = selectedPurchaseOrderLineIds.filter(id => id !== purchaseOrderLineId);
                }
                else {
                    selectedPurchaseOrderLineIds = [...selectedPurchaseOrderLineIds, purchaseOrderLineId];
                }

                return {
                    ...state,
                    selectedPurchaseOrderLineIds: selectedPurchaseOrderLineIds
                };
            }
        case Actions.SELECT_ALL_PURCHASE_ORDER_LINES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedPurchaseOrderLineIds = arr.map(purchaseOrderLines => purchaseOrderLines.id);

                return {
                    ...state,
                    selectedPurchaseOrderLineIds: selectedPurchaseOrderLineIds
                };
            }
        case Actions.DESELECT_ALL_PURCHASE_ORDER_LINES:
            {
                return {
                    ...state,
                    selectedPurchaseOrderLineIds: []
                };
            }
        case Actions.OPEN_NEW_PURCHASE_ORDER_LINE_DIALOG:
            {
                const { data } = action;
                return {
                    ...state,
                    purchaseOrderLineDialog: {
                        type: 'new',
                        props: {
                            open: true,
                            readOnly: false,
                        },
                        data: {
                            ...initialState.purchaseOrderLineDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_NEW_PURCHASE_ORDER_LINE_DIALOG:
            {
                return initialState;
            }
        case Actions.OPEN_EDIT_PURCHASE_ORDER_LINE_DIALOG:
            {
                const { data, readOnly } = action;
                return {
                    ...state,
                    purchaseOrderLineDialog: {
                        type: 'edit',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.purchaseOrderLineDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.UPDATE_EDIT_PURCHASE_ORDER_LINE_DIALOG:
            {
                const { data, readOnly } = action;
                return {
                    ...state,
                    purchaseOrderLineDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly
                        },
                        data: {
                            ...initialState.purchaseOrderLineDialog.data,
                            ...data,
                        }
                    }
                };
            }
        case Actions.CLOSE_EDIT_PURCHASE_ORDER_LINE_DIALOG:
            {
                return initialState;
            }
        case Actions.ADD_PURCHASE_ORDER_RECEIVING:
            {
                return {
                    ...state,
                    purchaseOrderLineDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly: false
                        },
                        data: {
                            ...state.purchaseOrderLineDialog.data,
                            receiving: {
                                ID: null,
                                Co: null,
                                PO: null,
                                POLine: null,
                                Seq: null,
                                ReceivedQty: null,
                                ReceivedCostTotal: null,
                                ReceivedTaxTotal: null,
                                ReceivedDate: new Date(),
                                Notes: null,
                                AddedBy: null,
                                AddedDate: null,
                                UpdatedBy: null,
                                UpdatedDate: null,
                                Data: {
                                    ErrMsg: "",
                                },
                            }
                        }
                    }
                }
            }
        case Actions.UPDATE_PURCHASE_ORDER_RECEIVING:
            {
                return {
                    ...state,
                    purchaseOrderLineDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly: false
                        },
                        data: {
                            ...state.purchaseOrderLineDialog.data,
                            receiving: {
                                ID: null,
                                Co: null,
                                PO: null,
                                POLine: null,
                                Seq: null,
                                ReceivedQty: null,
                                ReceivedCostTotal: null,
                                ReceivedTaxTotal: null,
                                ReceivedDate: new Date(),
                                Notes: null,
                                AddedBy: null,
                                AddedDate: null,
                                UpdatedBy: null,
                                UpdatedDate: null,
                                Data: {
                                    ErrMsg: "",
                                },
                            }
                        }
                    }
                }
            }
        case Actions.REMOVE_PURCHASE_ORDER_RECEIVING:
            {
                return {
                    ...state,
                    purchaseOrderLineDialog: {
                        type: 'update',
                        props: {
                            open: true,
                            readOnly: false
                        },
                        data: {
                            ...state.purchaseOrderLineDialog.data,
                            receiving: {
                                ID: null,
                                Co: null,
                                PO: null,
                                POLine: null,
                                Seq: null,
                                ReceivedQty: null,
                                ReceivedCostTotal: null,
                                ReceivedTaxTotal: null,
                                ReceivedDate: new Date(),
                                Notes: null,
                                AddedBy: null,
                                AddedDate: null,
                                UpdatedBy: null,
                                UpdatedDate: null,
                                Data: {
                                    ErrMsg: "",
                                },
                            }
                        }
                    }
                }
            }
        default:
            {
                return state;
            }
    }
};

export default purchaseOrderLinesReducer;
