import _ from '@lodash';
import { Chip, Icon, InputAdornment, MenuItem, Paper, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Assignment, Block, Build, FormatListNumbered, Payment, Receipt } from '@material-ui/icons';
import axios from 'axios/index';
import classNames from 'classnames';
import deburr from 'lodash/deburr';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import accents from 'remove-accents';
import { formatPhone } from '../../../functions';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {
        flexGrow: 1,
        left: 0,
        right: 0,
        position: 'absolute',
    },
    container: {
        position: 'relative',
    },
    chipContainer: {
        paddingTop: 3,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        margin: 'auto',
        zIndex: 1,
        left: 12,
        right: 12,
        maxHeight: 500,
        overflow: 'auto',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 10
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: 8,
    },
    input: {
        paddingLeft: '8.8rem',
    },
    histIcon: {
        color: '#fff'
    },
    histRoot: {
        height: 20,
        backgroundColor: '#4f4f4f',
        marginRight: 4,
        cursor: 'pointer',
        borderRadius: 5
    },
    histLabel: {
        paddingLeft: 8,
        paddingRight: 6,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 2
    },
    noHistIcon: {
        color: theme.palette.error[500]
    },
    noHistLabel: {
        paddingLeft: 8,
        paddingRight: 6,
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 10
    },
    inactive: {
        width: '100%',
        textAlign: 'center'
    }
});

class CustomerSearch extends React.Component {
    state = {
        suggestions: [],
    };


    handleSearchInput = (event) => {
        this.setState({ suggestions: [], open: false, searching: false });
        this.props.setSearchText(event);
        // this.getSuggestions();

    }

    handleFocus = (event) => {
        const { suggestions } = this.state;
        // this.getSuggestions();
        this.setState({ open: suggestions.length > 0, searching: false });
    }

    handleBlur = (event) => {
        this.setState({ open: false, searching: false });
    }

    search = () => {
        this.setState({ ...this.state, open: true, searching: true }, () => this.getSuggestions());
    }

    getSuggestions = _.debounce(() => {
        const { searchText } = this.props;
        if (searchText && searchText.length > 0) {
            const { Co, customers, sites, drafts } = this.props;
            const inputValue = deburr(accents.remove(searchText.trim())).toLowerCase();
            axios.get(`${window["apiLocation"]}/api/CustomerSearch?Co=${Co}&Term=${encodeURIComponent(inputValue)}`).then((response) => {
                const results = [];
                const Customers = response.data;
                for (var i = 0; i < Customers.length; i++) {
                    const suggestion = Customers[i];
                    results.push({ type: 'Customer', data: suggestion });
                }
                this.setState({ suggestions: results, searching: false, }, () => window["warn"](this.state.suggestions));
            });
        } else {
            this.setState({ suggestions: [], searching: false });
        }
    }, 250);

    handleSelectCustomer(customer) {
        this.props.openEditCustomerDialog(customer);
        this.setState({ open: false });
    }
 
    handleAddTerm = (term) => {
        let { Co, } = this.state;
        if (!this.props.searchText) {
            this.props.setSearchText({ target: { value: term } });
            if (term && term.length > 0) {
                this.setState({ ...this.state, open: false, suggestions: [], searching: true }, this.search);
            } else {
                this.setState({ ...this.state, open: false, suggestions: [], searching: false });
            }
        } else {
            this.props.setSearchText({ target: { value: `${this.props.searchText}|${term}` } });
            if (term && term.length > 0) {
                this.setState({ ...this.state, open: false, suggestions: [], searching: true }, this.search);
            } else {
                this.setState({ ...this.state, open: false, suggestions: [], searching: false });
            }
        }
    }

    handleDeleteTerm = (term) => {
        let { searchText } = this.props;
        let newText = searchText.replace(`|${term}`, '').replace(`${term}|`, '').replace(term, '');
        this.props.setSearchText({ target: { value: newText } });
        if (newText && newText.length > 0) {
            this.setState({ ...this.state, open: false, suggestions: [], searching: true }, this.search);
        } else {
            this.setState({ ...this.state, open: false, suggestions: [], searching: false });
        }
    }

    render() {
        const { classes, searchText } = this.props;
        const { suggestions, searching, open } = this.state;

        return (
            <div onFocus={this.handleFocus} onBlur={this.handleBlur} className={classes.root}>
                <ChipInput  blurBehavior="add"
                    className={classNames(classes.input, "xl:pl-56")}
                    classes={{
                        chipContainer: classes.chipContainer,
                    }}
                    // variant="outlined"
                    // label="Terms"
                    blurBehavior="add"
                    id="terms"
                    placeholder="Search Customers"
                    value={searchText && searchText.length > 0 ? searchText.split('|') : []}
                    disableUnderline={true}
                    onAdd={this.handleAddTerm}
                    onDelete={this.handleDeleteTerm}
                    chipRenderer={({ value, handleDelete }, key) =>
                        <Chip key={key} className="mb-8 mr-4" color="primary" onDelete={handleDelete} label={value} />
                    }
                    fullWidth
                    InputProps={{
                        endAdornment: searchText && searchText.length > 0 ? <InputAdornment className="mr-8 xl:mr-16 mb-2 cursor-pointer" placement="end"><Icon onClick={(e) => { e.stopPropagation(); this.props.setSearchText({ target: { value: '' } }); this.setState({ open: false, suggestions: [], searching: false }); }} color="action" className="">cancel_rounded</Icon></InputAdornment> : undefined
                    }}
                />
                {/* <Input
                        placeholder="Search Customers"
                        className={classNames(classes.input, "xl:pl-56")}
                        disableUnderline
                        fullWidth
                        value={searchText}
                        inputProps={{
                            'aria-label': 'Search'
                        }}
                        onChange={this.handleSearchInput}
                    /> */}
                {
                    open &&
                    <div className={classes.container} onMouseDown={(event) => { event.preventDefault(); }}>
                        <Paper className={classNames(classes.suggestionsContainerOpen, "mt-6 sm:mt-8")} square>
                            {!suggestions || suggestions.length < 1 &&
                                <div className="w-full p-24 text-center mt-8 font-bold text-12">
                                    {searching ?
                                        <div className="loader stretchBar mb-8 w-full m-auto pin-t pin-b pin-l pin-r">
                                            <div className="rect1"></div>
                                            <div className="rect2"></div>
                                            <div className="rect3"></div>
                                            <div className="rect4"></div>
                                            <div className="rect5"></div>
                                        </div> :
                                        <Typography fullWidth style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold' }} className="w-full text-center">No Results Found</Typography>
                                    }
                                </div>
                            }
                            {suggestions.map((suggestion) => {
                                switch (suggestion.type) {
                                    case 'Customer':
                                        {
                                            const { WorkOrders, Scopes, Invoices, Estimates, Payments } = suggestion.data;
                                            const hist = (WorkOrders + Scopes + Invoices + Payments + Estimates);
                                            return (
                                                <MenuItem onClick={() => { this.handleSelectCustomer(suggestion.data); }} style={{ height: 96, padding: 16, border: '1px dotted lightgrey', borderRadius: 4, margin: 4 }} component="div">
                                                    <div className="flex flex-1">
                                                        <div className="w-76">
                                                            {suggestion.data.RefuseServiceYN === "Y" ?
                                                                <Icon color="error" className="text-36 mb-0 w-full text-center">explore_off</Icon> :
                                                                suggestion.data.Redirect ?
                                                                    <Icon color="primary" className="text-36 mb-0 w-full text-center text-orange">directions</Icon> :
                                                                    <Icon color={suggestion.data.ActiveYN !== 'N' ? "primary" : 'action'} className="text-36 mb-0 w-full text-center">account_circle</Icon>
                                                            }
                                                            <Typography fullWidth style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold', fontSize: 12 }} className="w-full text-center truncate">{`#${suggestion.data.Redirect || suggestion.data.Customer}`}</Typography>
                                                            {suggestion.data.ActiveYN === 'N' &&
                                                                <div className={classes.inactive}>
                                                                    <Chip classes={{ icon: classes.histIcon, root: classes.histRoot, label: classes.noHistLabel }} label="Inactive" />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="ml-16">
                                                            {suggestion.data.Name && suggestion.data.Name.length > 0 ?
                                                                <Typography fullWidth style={{ alignItems: 'center', lineHeight: 1 }} className="w-full font-bold text-12 mb-6">{`${suggestion.data.Name}`}</Typography>
                                                                :
                                                                <Typography fullWidth style={{ alignItems: 'center', lineHeight: 1 }} className="w-full font-bold text-12 mb-6">{`${suggestion.data.FirstName} ${suggestion.data.LastName}`}</Typography>
                                                            }
                                                            <Typography fullWidth style={{ alignItems: 'center', lineHeight: 1 }} className="w-full text-12 mb-6">{`${suggestion.data.Address1} ${suggestion.data.City}, ${suggestion.data.State} ${suggestion.data.Zip}`}</Typography>
                                                            <Typography fullWidth style={{ alignItems: 'center', lineHeight: 1 }} className="w-full text-12 mb-4">{`${formatPhone(suggestion.data.Phone).formatted}`}</Typography>
                                                            {hist > 0 ?
                                                                <div className="flex">
                                                                    {WorkOrders > 0 &&
                                                                        <Tooltip title="Work Orders" placement="top"><Chip classes={{ icon: classes.histIcon, root: classes.histRoot, label: classes.histLabel }} icon={<Assignment className="text-16" />} label={WorkOrders} /></Tooltip>
                                                                    }
                                                                    {Scopes > 0 &&
                                                                        <Tooltip title="Scopes" placement="top"><Chip classes={{ icon: classes.histIcon, root: classes.histRoot, label: classes.histLabel }} icon={<Build className="text-16" />} label={Scopes} /></Tooltip>
                                                                    }
                                                                    {Invoices > 0 &&
                                                                        <Tooltip title="Invoices" placement="top"><Chip classes={{ icon: classes.histIcon, root: classes.histRoot, label: classes.histLabel }} icon={<Receipt className="text-16" />} label={Invoices} /></Tooltip>
                                                                    }
                                                                    {Payments > 0 &&
                                                                        <Tooltip title="Payments" placement="top"><Chip classes={{ icon: classes.histIcon, root: classes.histRoot, label: classes.histLabel }} icon={<Payment className="text-16" />} label={Payments} /></Tooltip>
                                                                    }
                                                                    {Estimates > 0 &&
                                                                        <Tooltip title="Estimates" placement="top"><Chip classes={{ icon: classes.histIcon, root: classes.histRoot, label: classes.histLabel }} icon={<FormatListNumbered className="text-16" />} label={Estimates} /></Tooltip>
                                                                    }
                                                                </div> :
                                                                <Chip classes={{ icon: classes.noHistIcon, root: classes.histRoot, label: classes.noHistLabel }} icon={<Block className="text-16" />} label="No History" />
                                                            }
                                                        </div>
                                                    </div>
                                                </MenuItem >
                                            );
                                        }
                                }
                            })
                            }
                        </Paper>
                    </div>
                }
            </div>
        );
    }
}

CustomerSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSearchText: Actions.setSearchText,
        openEditCustomerDialog: Actions.openEditCustomerDialog,
    }, dispatch);
}

function mapStateToProps({ customersApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        searchText: customersApp.customers.searchText,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CustomerSearch));