import localforage from 'localforage';

export default class CacheManager {

    set = (key, data) => localforage.setItem(key, data)

    get = (key, data) => localforage.getItem(key, data)

    remove = (key, data) => localforage.removeItem(key, data)

    clear = () => localforage.clear()
}