import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon } from '@material-ui/core';
import classNames from 'classnames';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        padding: 12,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    fullScreen: {
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)'
    }
});

class ConfirmationDialog extends React.Component {

    handleCancel = () => {
        this.props.onCancel();
    };

    handleOk = () => {
        this.props.onConfirm();
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    getComponent = (type, data) => {
        const { onAction } = this.props;
        let component = '';
        switch (type) {
            case 'TripDirections':
                {
                    const { Address1, City, State, Zip, WorkOrder, platform } = data;
                    component = (
                        <div className="w-full min-w-320 max-w-320">
                            <div className="w-full">
                                <div className="w-full">{`Need directions to Work Order #${WorkOrder}? If so, just click "Navigate" below.`}</div>
                                <div className="w-full mt-12 mb-6"><Button onClick={() => window.open(platform === 'ios' ? `https://maps.apple.com?t=m&address=${encodeURIComponent(`${Address1} ${City}, ${State} ${Zip}`)}` : `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${Address1} ${City}, ${State} ${Zip}`)}`)} className="w-full" variant="contained" color="primary"><Icon className="mr-6">map</Icon>Navigate</Button></div>
                                {platform === 'ios' &&
                                    <div className="w-full mb-12 text-center text-12 flex justify-center">You can also use<div onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${Address1} ${City}, ${State} ${Zip}`)}`)} className="underline ml-6" style={{ color: '#4dbce9' }}>Google Maps</div></div>
                                }
                            </div>
                        </div>
                    );
                }
                break;
            case 'TripExtension': {
                component = (
                    <div className="w-full min-w-320 max-w-320">
                        <div className="w-full">
                            <div className="w-full">{`Need to extend the Duration of this Trip? Just select the amount of additional time needed below!`}</div>
                            <div className="w-full my-12">
                                <Button onClick={() => { if (onAction) { onAction({ ...data, Duration: (data.Duration + .5) }); } }} className="w-full mb-8" variant="contained" color="primary">1/2 Hour</Button>
                                <Button onClick={() => { if (onAction) { onAction({ ...data, Duration: (data.Duration + 1) }); } }} className="w-full mb-8" variant="contained" color="primary">1 Hour</Button>
                                <Button onClick={() => { if (onAction) { onAction({ ...data, Duration: (data.Duration + 1.5) }); } }} className="w-full mb-8" variant="contained" color="primary">1 1/2 Hours</Button>
                                <Button onClick={() => { if (onAction) { onAction({ ...data, Duration: (data.Duration + 2) }); } }} className="w-full mb-8" variant="contained" color="primary">2 Hours</Button>
                            </div>
                        </div>
                    </div >
                )
            }
        }
        return component;
    }

    render() {
        const { classes, value, content, title, confirmText, cancelText, onConfirm, onCancel, open, disableConfirm, hideConfirm, hideCancel, type, data, allowEsc, fullScreen } = this.props;

        return (
            <Dialog
                disableBackdropClick={!allowEsc}
                disableEscapeKeyDown={!allowEsc}
                maxWidth="sm"
                onEntering={this.handleEntering}
                fullScreen={fullScreen}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                onClick={(e) => e.stopPropagation()}
                onClose={allowEsc ? this.handleCancel : undefined}
                classes={{ paper: fullScreen ? classes.fullScreen : undefined }}
            >
                <DialogTitle classes={{ root: "p-16 pl-24 pr-24" }}>{title}</DialogTitle>
                <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
                    {type ? this.getComponent(type, data) : content}
                </DialogContent>
                <DialogActions>
                    {!hideCancel &&
                        <Button onClick={this.handleCancel} color="error">
                            {cancelText || "Cancel"}
                        </Button>
                    }
                    {!hideConfirm && Boolean(onConfirm) &&
                        <Button onClick={this.handleOk} disabled={disableConfirm} color="primary">
                            {confirmText || "OK"}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func,
    value: PropTypes.string,
};

export default withStyles(styles)(ConfirmationDialog);