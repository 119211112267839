import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Badge, Button, Card, CardHeader, CardContent, CardActions, Divider, Fab, Icon, IconButton, LinearProgress, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import AcademyClassCategoryCard from './AcademyClassCategoryCard';
import _ from '@lodash';
import { actions } from 'react-table';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
});

class AcademyCategoryList extends Component {

    state = {
        categories: [],
    }

    componentDidMount() {
        const { categories } = this.props;
        this.setState({ ...this.state, categories });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { categories } = this.props;
        if (!_.isEqual(categories, prevProps.categories)) {
            this.setState({ ...this.state, categories });
        }
    }

    addCategory = (e) => {
        e.stopPropagation();
        const { Co } = this.props;
        const { categories, } = this.state;
        const seq = _.maxBy(categories, 'Category');
        const newClass = {
            ID: null,
            Co,
            Category: seq ? seq.Category + 1 : 1,
            Name: 'New Category',
            Description: 'New Category',
            Data: {
                ErrMsg: null,
            },
            editing: true,
        };
        categories.push(newClass);
        this.setState({ ...this.state, categories });
        this.props.setClassCategories([...categories]);
    }

    removeCategory = (value) => {
        const { categories, } = this.state;
        const index = _.findIndex(categories, { Category: value.Category });
        categories.splice(index, 1);
        this.setState({ ...this.state, categories });
        this.props.setClassCategories([...categories]);
    }

    render() {
        const { classes, user, category, securables } = this.props;
        const { categories } = this.state;
        const data = category ? _.filter(categories, { Category: category.Category }) : categories;
        const accessLevel = _.find(securables, { Securable: "learning" });
        return (
            <div className="p-12 pl-20">
                <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex">{<Badge color="error" classes={{ badge: classes.badge, }} className="mr-8" badgeContent={[...categories].length}><Icon color="primary" className="text-32">list</Icon></Badge>}Categories</Typography>
                <Divider />
                <FuseAnimateGroup enter={{ animation: "transition.slideUpBigIn" }} leave={{ animation: "transition.slideUpBigOut" }} delay={300}>
                    {
                        [...data].map((value, index) => {
                            const category = _.find(categories, { Category: value.Category });
                            return (
                                <AcademyClassCategoryCard editable={value.Category} onDelete={() => this.removeCategory(value)} value={value} key={index} category={category} />
                            )
                        })
                    }
                </FuseAnimateGroup>
                {(accessLevel && accessLevel.AccessLevel !== "R") &&
                    <Typography onClick={this.addCategory} variant="subtitle2" style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-12">add</Icon>}New Category</Typography>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setClassCategories: Actions.setCategories,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, academyApp }) {
    return {
        Co: spReducers.companies.Co,
        user: contactsApp.user,
        categories: spReducers.classes.categories,
        category: academyApp.academy.category,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AcademyCategoryList)));
