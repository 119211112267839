import axios from 'axios';
import audioPlayer from '../sounds/audioPlayer';
import { Relay } from '@signalwire/js'

export const TOGGLE_DIALER = '[DIALER] TOGGLE DIALER';
export const GET_DIALER_DATA = '[DIALER] GET DATA';
export const INITIATE_SIP_CONNECTION = '[DIALER] INITIATE SIP CONNECTION';
export const INITIATE_SIP_SESSION = '[DIALER] INITIATE SIP SESSION';
export const UPDATE_SIP_CONNECTION = '[DIALER] UPDATE SIP CONNECTION';
export const UPDATE_SIP_SESSION = '[DIALER] UPDATE SIP SESSION';
export const END_SIP_CONNECTION = '[DIALER] END SIP CONNECTION';
export const END_SIP_SESSION = '[DIALER] END SIP SESSION';
export const AWAITING_CALL_FROM = '[DIALER] AWAITING CALL FROM';
export const SET_FAVORITES = '[DIALER] SET FAVORITES';
export const SET_CONTACTS = '[DIALER] SET CONTACTS';
export const SET_RECENTS = '[DIALER] SET RECENTS';
export const GET_MEDIA_DEVICES = '[DIALER] GET MEDIA DEVICES';
export const SET_AUDIO_IN = '[DIALER] SET AUDIO IN';
export const SET_AUDIO_OUT = '[DIALER] SET AUDIO OUT';
export const SET_VIDEO_IN = '[DIALER] SET VIDEO IN';
export const SET_VIDEO_ORIENTATION = '[DIALER] SET VIDEO ORIENTATION';
export const SET_DIALER_TOKENS = '[DIALER] SET DIALER TOKENS';
export const UPDATE_LOCAL_STREAM = '[DIALER] UPDATE LOCAL STREAM';


export function getDialerData() {
    const request = axios.get('/api/quick-panel/data');
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_DIALER_DATA,
                payload: response.data
            })
        );
}

export function toggleDialer(value) {
    return {
        type: TOGGLE_DIALER,
        payload: value
    }
}

// export function getMediaDevices() {
//     var dev = navigator.mediaDevices.enumerateDevices();
//     return (dispatch) => {
//         dev.then((devices) => {
//             const audioIn = [];
//             const audioOut = [];
//             const videoIn = [];
//             for (var i = 0; i < devices.length; i++) {
//                 if (devices[i].kind == "audioinput" && devices[i].deviceId.length > 0 && devices[i].label.length > 0) {
//                     audioIn.push(devices[i]);
//                 };
//                 if (devices[i].kind == "audiooutput" && devices[i].deviceId.length > 0 && devices[i].label.length > 0) {
//                     audioOut.push(devices[i]);
//                 };
//                 if (devices[i].kind == "videoinput" && devices[i].deviceId.length > 0 && devices[i].label.length > 0) {
//                     videoIn.push(devices[i]);
//                 };
//             }
//             dispatch({
//                 type: GET_MEDIA_DEVICES,
//                 payload: {
//                     audioIn,
//                     audioOut,
//                     videoIn,
//                 },
//             });
//         })
//     }
// }

// export function setAudioIn(device, useVideo, videoIn, session) {
//     if (session) {
//         session.renegotiate({
//             mediaConstraints: {
//                 audio: device ? {
//                     mandatory: {
//                         sourceId: device,
//                     }
//                 } : true,
//                 video: useVideo ?
//                     videoIn ? {
//                         mandatory: {
//                             sourceId: videoIn,
//                         }
//                     } : true
//                     : false
//             },
//             pcConfig: {
//                 iceServers: [{
//                     urls: ['stun:stun.signalwire.com:3478']
//                 }]
//             }
//         });
//     }
//     return {
//         type: SET_AUDIO_IN,
//         payload: device,
//     }
// }

// export function setAudioOut(device) {
//     if (document.getElementById('remote-video') !== null) {
//         const remoteVideo = document.getElementById('remote-video');
//         remoteVideo.setSinkId(device);
//     }
//     const remoteAudio = document.getElementById('remote-audio');
//     remoteAudio.setSinkId(device);
//     return {
//         type: SET_AUDIO_OUT,
//         payload: device,
//     }
// }

// export function setVideoIn(device, audio, session) {
//     if (session) {
//         session.renegotiate({
//             mediaConstraints: {
//                 audio: audio ? {
//                     mandatory: {
//                         sourceId: audio,
//                     }
//                 } : true,
//                 video: device ? {
//                     mandatory: {
//                         sourceId: device,
//                     }
//                 } : false
//             },
//             pcConfig: {
//                 iceServers: [{
//                     urls: ['stun:stun.signalwire.com:3478']
//                 }]
//             }
//         });
//     }
//     return {
//         type: SET_VIDEO_IN,
//         payload: device,
//     }
// }

export function getMediaDevices(phone) {
    if (phone) {
        return (dispatch) => {
            var dev = phone.refreshDevices();
            dev.then((devices) => {
                window["warn"](devices);
                const audioIn = [];
                const audioOut = [];
                const videoIn = [];
                var cameras = devices.videoinput;
                var mics = devices.audioinput;
                var speakers = devices.audiooutput;
                for (var i = 0; i < Object.keys(cameras).length; i++) {
                    videoIn.push(cameras[Object.keys(cameras)[i]]);
                }
                for (var i = 0; i < Object.keys(mics).length; i++) {
                    audioIn.push(mics[Object.keys(mics)[i]]);
                }
                for (var i = 0; i < Object.keys(speakers).length; i++) {
                    audioOut.push(speakers[Object.keys(speakers)[i]]);
                }
                dispatch({
                    type: GET_MEDIA_DEVICES,
                    payload: devices,
                });
            })
        }
    }
}

export function setAudioIn(device, label, session, phone) {
    return ((dispatch, getState) => {
        if (session) {
            const success = session.setAudioInDevice(device);
            success.then(e => {
                dispatch({ type: UPDATE_LOCAL_STREAM, local: session.localStream });
            })
        }
        phone.setAudioSettings({
            deviceId: { exact: device },
        });
        dispatch({
            type: SET_AUDIO_IN,
            payload: device,
        });
    })
}

export function setAudioOut(device) {
    if (document.getElementById('remote-video') !== null) {
        const remoteVideo = document.getElementById('remote-video');
        remoteVideo.setSinkId(device);
    }
    const remoteAudio = document.getElementById('remote-audio');
    remoteAudio.setSinkId(device);
    return {
        type: SET_AUDIO_OUT,
        payload: device,
    }
}

export function setVideoIn(device, label, session, phone) {
    return ((dispatch, getState) => {
        if (session) {
            const success = session.setVideoDevice(device);
            success.then(e => {
                dispatch({ type: UPDATE_LOCAL_STREAM, local: session.localStream });
            })
        }
        dispatch({
            type: SET_VIDEO_IN,
            payload: device,
        });
        phone.setVideoSettings({
            frameRate: 30,
            deviceId: { exact: device },
        });
    })
}

export function setVideoOrientation(orientation, client, call) {
    return ((dispatch, getState) => {
        const constraints = client.setVideoSettings({
            "facingMode": orientation,
        });
        return constraints.then((response) => {
            dispatch({
                type: SET_VIDEO_ORIENTATION,
                payload: orientation,
            });
            // if (call) {
            //     call.setVideoDevice();
            // }
        });
    });
}

export function setContacts(contacts) {
    return {
        type: SET_CONTACTS,
        payload: contacts,
    }
}

export function setFavorites(favorites) {
    return {
        type: SET_FAVORITES,
        payload: favorites,
    }
}

export function setRecents(recents) {
    return {
        type: SET_RECENTS,
        payload: recents,
    }
}

export function updateVideoUser(UserName, Status) {
    return ((dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/VideoChat/PostVideoUser`, {
            UserName,
            Status
        });
        return request.then((response) => {
            window["warn"](response.data);
        });
    });
}

export function updateVideoChat(CallSid, Status, From, To) {
    return ((dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/VideoChat/PostVideoChat`, {
            CallSid,
            From,
            To,
            Status
        });
        return request.then((response) => {
            window["warn"](response.data);
        });
    });
}

export function initCall(client, options, from, to) {
    window["warn"](from, to);
    return ((dispatch, getState) => {
        const call = client.newCall(options);
        return call.then(session => {
            window["warn"]('New Session Initiated:', session.remoteStream);
            dispatch({ type: INITIATE_SIP_SESSION, payload: session, remote: session.remoteStream, local: session.localStream });
            if (from && to) {
                dispatch(updateVideoChat(session.id, 0, from, to));
            }
            window["call"] = session;
        }).catch(error => {
            window["warn"](error);
        })
    });
}

export function answerCall(session) {

    return ((dispatch, getState) => {
        session.answer();
        window["call"] = session;
        dispatch({ type: UPDATE_SIP_SESSION, payload: true, remote: session.remoteStream, local: session.localStream });
    });
}

export function endCall(session) {
    return ((dispatch, getState) => {
        session.hangup();
        dispatch({ type: AWAITING_CALL_FROM, payload: null });
        dispatch({ type: END_SIP_SESSION, payload: null });
    })
}

export function initiateSip(unm) {
    return ((dispatch, getState) => {
        const request = axios.get(`${window["apiLocation"]}/api/VoiceToken?UserName=${encodeURIComponent(unm.split('@')[0])}`);
        return request.then((response) => {
            dispatch({
                type: SET_DIALER_TOKENS,
                payload: response.data,
            });
            const client = new Relay({
                project: response.data.Project,
                token: response.data.Token,
            });

            client.on('signalwire.ready', session => {
                window["warn"]('New Verto Client:', client);
                dispatch({ type: INITIATE_SIP_CONNECTION, payload: client });
                dispatch(updateVideoUser(unm, 1));
                window["phone"] = client;
            });
            client.on('signalwire.error', error => {
                window["error"]('Connection Error', error);
                dispatch(updateVideoUser(unm, 0));
            })

            client.on('signalwire.socket.error', error => {
                window["error"]('Socket Error', error);
                dispatch(updateVideoUser(unm, 0));
                // dispatch({ type: END_SIP_CONNECTION, payload: null });
                // dispatch(refreshVoiceToken());
                // client.disconnect()
            })

            client.on('signalwire.socket.close', error => {
                window["log"]('close', error)
                dispatch({ type: END_SIP_CONNECTION, payload: null });
                dispatch(updateVideoUser(unm, 0));
                // client.disconnect()
            })

            client.on('signalwire.notification', notification => {
                window["warn"](notification);
                switch (notification.type) {
                    case 'callUpdate':
                        const { call } = notification;
                        switch (call.state) {
                            case 'destroy':
                                {
                                    if (call.direction === 'outbound') {
                                        dispatch(updateVideoChat(call.id, call.gotAnswer ? 2 : 3));
                                    }
                                    dispatch({ type: AWAITING_CALL_FROM, payload: null });
                                    dispatch({ type: END_SIP_SESSION, payload: null });
                                }
                                break;
                            case 'active':
                                {
                                    const video = call.localStream.getVideoTracks();
                                    const audio = call.localStream.getAudioTracks();
                                    if (video && video.length > 0) {
                                        dispatch({
                                            type: SET_VIDEO_IN,
                                            payload: video[0].label,
                                        });
                                    }
                                    if (audio && audio.length > 0) {
                                        dispatch({
                                            type: SET_AUDIO_IN,
                                            payload: audio[0].label,
                                        });
                                    }
                                    dispatch({ type: UPDATE_SIP_SESSION, payload: true, remote: call.options.remoteStream, local: call.localStream });
                                    if (call.prevState !== 'active') {
                                        if (call.direction === 'outbound') {
                                            dispatch(updateVideoChat(call.id, 1));
                                            audioPlayer.play('answered');
                                        }
                                    }
                                }
                                break;
                            case 'early':
                                {
                                    dispatch({ type: INITIATE_SIP_SESSION, payload: call, remote: call.options.remoteStream, local: call.localStream });
                                }
                                break;
                            case 'ringing':
                                {
                                    dispatch({ type: INITIATE_SIP_SESSION, payload: call, remote: call.options.remoteStream, local: call.localStream });
                                }
                                break;
                        }
                        break;
                    case 'participantData':
                        window["warn"](notification);
                        break
                    case 'userMediaError':
                        window["error"](notification);
                        break
                    case 'refreshToken':
                        window["warn"](notification);
                        dispatch(refreshVoiceToken());
                        break;
                    default:
                }
            })

            client.connect()
        });
    })
}

export function refreshVoiceToken() {
    return ((dispatch, getState) => {
        const state = getState();
        const rtoken = state.dialer.jwt.refresh;
        const client = state.dialer.sipPhone;
        if (client) {
            const request = axios.get(`${window["apiLocation"]}/api/VoiceToken?RToken=${encodeURIComponent(rtoken)}`);
            return request.then((response) => {
                client.refreshToken(response.data.Token);
                dispatch({
                    type: SET_DIALER_TOKENS,
                    payload: response.data,
                });
            });
        }
    });
}

export function setAwaitingCallFrom(phone) {
    return {
        type: AWAITING_CALL_FROM,
        payload: phone
    }
}
