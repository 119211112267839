import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import FleetVehicleList from 'main/content/apps/fleet-vehicles/FleetVehiclesList';
import FleetVehicleHeader from 'main/content/apps/fleet-vehicles/FleetVehiclesHeader';
import FleetVehicleSidebarContent from 'main/content/apps/fleet-vehicles/FleetVehiclesSidebarContent';
import FleetVehiclesLeftSidebarHeader from 'main/content/apps/fleet-vehicles/FleetVehiclesLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import FleetVehicleDialog from 'main/content/apps/fleet-vehicles/FleetVehicleDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class FleetVehiclesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getFleetVehicles(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewFleetVehicleDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <FleetVehicleHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <FleetVehicleList />
                            }
                            leftSidebarHeader={
                                <FleetVehiclesLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <FleetVehicleSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <FleetVehicleDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFleetVehicles: Actions.getFleetVehicles,
        getUserData: Actions.getUserData,
        openNewFleetVehicleDialog: Actions.openNewFleetVehicleDialog
    }, dispatch);
}

function mapStateToProps({ fleetVehiclesApp }) {
    return {
        fleetVehicles: fleetVehiclesApp.fleetVehicles.entities,
        selectedFleetVehicleIds: fleetVehiclesApp.fleetVehicles.selectedFleetVehicleIds,
        searchText: fleetVehiclesApp.fleetVehicles.searchText,
        user: fleetVehiclesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FleetVehiclesApp)));
