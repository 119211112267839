import axios from 'axios';
import { getUserData } from 'main/content/apps/work-order-usages/store/actions/user.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';

export const OPEN_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG = '[GPS TRANSFER LIST APP] OPEN EDIT GPS TRANSFER LIST DIALOG';
export const CLOSE_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG = '[GPS TRANSFER LIST APP] CLOSE EDIT GPS TRANSFER LIST DIALOG';
export const GET_GPS_INVENTORY_TRANSFER_LIST_LOCATION_DATA = '[GPS TRANSFER LIST APP]  GET GPS INVENTORY TRANSFER LIST LOCATION DATA';
export const DELETE_GPS_INVENTORY_TRANSFER_LIST_LOCATION_DATA = '[GPS TRANSFER LIST APP] DELETE GPS INVENTORY TRANSFER LIST LOCATION DATA'


export function openPreloadGpsInventoryTransferListDialog(data) {
    return {
        type: OPEN_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG,
        data,
    }
}

export function openEditGpsInventoryTransferListDialog(data) {
    return (dispatch, getState) => {
        const { Co, Page, PageSize, Location } = data;
        // const request = axios.get(`${window["apiLocation"]}/api/InventoryTransfer?Co=${encodeURIComponent(Co)}&Page=${Page}&PageSize=${PageSize}&From=${encodeURIComponent(Location)}`);
        //const request1 = axios.get(`${window["apiLocation"]}/api/InventoryTransfer?Co=${encodeURIComponent(Co)}&Page=${Page}&PageSize=${PageSize}`);
        const request = axios.get(`${window["apiLocation"]}/api/InventoryTransfer`, {
            params: {
              Co: encodeURIComponent(Co),
              Page: Page,
              PageSize: PageSize,
              Location: encodeURIComponent(Location)
            }
          });
          

        return request.then((response) =>

            Promise.all([
                dispatch({
                    type: OPEN_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG,
                    data: {...response.data, ...data}
                })
            ])
        );
    };
}

export function closeEditGpsInventoryTransferListDialog() {
    return {
        type: CLOSE_EDIT_GPS_INVENTORY_TRANSFER_LIST_DIALOG
    }
}

export function getGpsInventoryTransferListLocationData(data) {
    return (dispatch, getState) => {
        const { Co, Location, Page, PageSize } = data;
        // const request = axios.get(`${window["apiLocation"]}/api/InventoryTransfer?Co=${encodeURIComponent(Co)}&Page=${Page}&PageSize=${PageSize}&Location=${encodeURIComponent(Location)}`);
        const request = axios.get(`${window["apiLocation"]}/api/InventoryTransfer`, {
            params: {
              Co: encodeURIComponent(Co),
              Page: Page,
              PageSize: PageSize,
              Location: encodeURIComponent(Location)
            }
          });
          
        return request.then((response) =>

            Promise.all([
                dispatch({
                    type: GET_GPS_INVENTORY_TRANSFER_LIST_LOCATION_DATA,
                    data: {...response.data, ...data}
                })
            ])
        );
    };
}

export function deleteGpsInventoryTransferListLocationData() {
    return {
        type: DELETE_GPS_INVENTORY_TRANSFER_LIST_LOCATION_DATA
    }
}


