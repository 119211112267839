export const version = {
  version: '1.11.0',
}

export const notes = {}

console.log('Endpoint', process.env.REACT_APP_OVERRIDE_ENDPOINT, process.env.REACT_APP_OVERRIDE_DOMAIN)

export function endpoint(client) {
  if (process.env.REACT_APP_OVERRIDE_ENDPOINT) {
    return process.env.REACT_APP_OVERRIDE_ENDPOINT
  }

  if (process.env.REACT_APP_OVERRIDE_DOMAIN) {
    return `https://${client}.${process.env.REACT_APP_OVERRIDE_DOMAIN}`
  }

  return `https://${client}.servicepointpro.com`
}