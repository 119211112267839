import React, { Component } from 'react';
import { TextField, Button, Avatar, Icon, Typography, List } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import RecordingActivity from './activity/RecordingActivity';
import _ from '@lodash';

class Comments extends Component {
    state = {
        message: ''
    };

    render() {
        const { recordings } = this.props;

        return (
            <div className="mb-24">
                <div className="flex items-center">
                    <Icon className="text-24 mr-8" color="primary">voicemail</Icon>
                    <Typography className="font-600 text-16">Call Recordings</Typography>
                </div>
                {recordings && recordings.length > 0 && (
                    <div className="mb-24">
                        <List className="">
                            {recordings.map(item => (
                                <RecordingActivity
                                    item={item}
                                    key={item.Sid}
                                />
                            )
                            )}
                        </List>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}

export default connect(mapStateToProps)(Comments);
