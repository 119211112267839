import axios from 'axios';
import { showMessage } from 'store/actions/fuse';
import history from 'history.js';
import _ from '@lodash';
import * as Actions from './index';
import { FuseUtils } from '@fuse';
import { GET_DASHBOARDS } from './dashboards.actions';

export const GET_DASHBOARD = '[DASHBOARD BUILDER APP] GET DASHBOARD';
export const DELETE_DASHBOARD = '[DASHBOARD BUILDER APP] DELETE DASHBOARD';
export const COPY_DASHBOARD = '[DASHBOARD BUILDER APP] COPY DASHBOARD';
export const UPDATE_DASHBOARD = '[DASHBOARD BUILDER APP] UPDATE DASHBOARD';
export const CHANGE_DASHBOARD_SETTINGS = '[DASHBOARD BUILDER APP] CHANGE DASHBOARD SETTINGS';
export const RESET_DASHBOARD = '[DASHBOARD BUILDER APP] RESET DASHBOARD';
export const SET_DASHBOARD_FILTERS = '[DASHBOARD BUILDER APP] SET DASHBOARD FILTERS';
export const SET_DASHBOARD_DRILLED = '[DASHBOARD BUILDER APP] SET DASHBOARD DRILLED';

export function getDashboard(Co, Dashboard) {
    const request = axios.get(`${window["apiLocation"]}/api/Dashboard?Co=${Co}&Dashboard=${Dashboard}`);

    return (dispatch) =>
        request.then(
            (response) =>
                dispatch({
                    type: GET_DASHBOARD,
                    payload: response.data
                }),
            (error) => {
                dispatch(showMessage({
                    message: error.message,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
                history.push({
                    pathname: '/apps/dashboard-builder/dashboards'
                });
            });
}

export function resetDashboard() {
    return {
        type: RESET_DASHBOARD
    };
}

export function setDefaultDashboard(dashboard) {
    const request = axios.put(`${window["apiLocation"]}/api/Dashboard?Co=${dashboard.Co}&Form=${encodeURIComponent(dashboard.Form)}&Dashboard=${dashboard.Dashboard}&Default=${dashboard.DefaultYN}`,
        dashboard
    );

    return (dispatch) =>
        request.then((response) => {
            dispatch(showMessage({
                message: 'Dashboard updated successfully',
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));
        });
}

export function changeDashboardSettings(newSettings) {
    return (dispatch, getState) => {
        const { dashboard } = getState().dashboardBuilderApp;
        const settings = _.merge(dashboard.settings, newSettings);
        const request = axios.post('/api/dashboard-builder-app/dashboard/settings/update',
            {
                dashboardId: dashboard.id,
                settings
            }
        );

        return request.then((response) =>
            dispatch({
                type: CHANGE_DASHBOARD_SETTINGS,
                payload: response.data
            })
        );
    }
}

export function deleteDashboard(dashboard) {
    window['warn']('Deleting Dashboard: ', dashboard);
    const request = axios.delete(`${window["apiLocation"]}/api/Dashboard?Co=${dashboard.Co}&Dashboard=${dashboard.Dashboard}`);

    return (dispatch, getState) =>
        request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const state = getState();
                const { Dashboards } = state.dashboardBuilderApp.dashboards;
                const index = _.findIndex(Dashboards, { Dashboard: dashboard.Dashboard });
                if (index > -1) {
                    Dashboards.splice(index, 1);
                    dispatch({
                        type: GET_DASHBOARDS,
                        payload: Dashboards
                    });
                }
                dispatch({
                    type: DELETE_DASHBOARD,
                    payload: response.data
                });
                dispatch(showMessage({
                    message: 'Dashboard removed successfully',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                history.push({
                    pathname: '/apps/dashboard-builder/dashboards'
                });
            }
        });
}
// export function deleteDashboard(dashboardId) {
//     const request = axios.post('/api/dashboard-builder-app/dashboard/delete',
//         {
//             dashboardId
//         }
//     );

//     return (dispatch) =>
//         request.then((response) => {

//             history.push({
//                 pathname: '/apps/dashboard-builder/dashboards'
//             });

//             return dispatch({
//                 type: DELETE_DASHBOARD
//             });
//         })
// }

export function copyDashboard(dashboard) {
    const newDashboard = _.merge(dashboard, {
        id: FuseUtils.generateGUID(),
        name: dashboard.Name + ' (Copied)',
        uri: dashboard.uri + '-copied'
    });
    return (dispatch) => {
        dispatch(Actions.newDashboard(newDashboard));
        return { type: COPY_DASHBOARD };
    }
}



export function updateDashboard(dashboard) {

    return (dispatch, getState) => {
        const state = getState();
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!dashboard.Co && dashboard.Dashboard < 1000 && about.InstanceType === 'D') {
            dashboard.StandardYN = 'Y'
        }

        const request = axios.put(`${window["apiLocation"]}/api/Dashboard?Co=${dashboard.Co || Co}&Dashboard=${dashboard.Dashboard}`,
            dashboard
        );
        return request.then((response) => {

            dispatch({
                type: UPDATE_DASHBOARD,
                payload: response.data
            });
            dispatch(showMessage({
                message: 'Dashboard updated successfully',
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'success'
            }));
        });
    }
}

export function addDataSource(dataSource) {
    const request = axios.post(`${window["apiLocation"]}/api/DashboardDataSource`,
        dataSource
    );

    return (dispatch, getState) => {

        const state = getState();
        const { dashboard } = state.dashboardBuilderApp;
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!dashboard.Co && dashboard.Dashboard < 1000 && about.InstanceType === 'D') {
            dataSource.StandardYN = 'Y'
        }

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dashboard.Data.DataSources.push(response.data);
                dispatch({
                    type: UPDATE_DASHBOARD,
                    payload: dashboard
                });
                dispatch(showMessage({
                    message: 'Data Source added successfully',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        });
    }
}


export function updateDataSource(dataSource) {
    const { Dashboard, Report } = dataSource

    return (dispatch, getState) => {

        const state = getState();
        const { dashboard } = state.dashboardBuilderApp;
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!dashboard.Co && dashboard.Dashboard < 1000 && about.InstanceType === 'D') {
            dataSource.StandardYN = 'Y'
        }

        const request = axios.put(`${window["apiLocation"]}/api/DashboardDataSource?Co=${dataSource.Co || Co}&Dashboard=${Dashboard}&Report=${Report}`,
            dataSource
        );
        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const index = _.findIndex(dashboard.Data.DataSources, { Dashboard, Report });
                if (index > -1) {
                    dashboard.Data.DataSources.splice(index, 1, response.data);
                }
                dispatch({
                    type: UPDATE_DASHBOARD,
                    payload: dashboard
                });
                dispatch(showMessage({
                    message: 'Data Source updated successfully',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        });
    }
}

export function removeDataSource(dataSource) {
    const { Dashboard, Report } = dataSource

    return (dispatch, getState) => {

        const state = getState();
        const { dashboard } = state.dashboardBuilderApp;
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!dashboard.Co && dashboard.Dashboard < 1000 && about.InstanceType === 'D') {
            dataSource.StandardYN = 'Y'
        }

        const request = axios.delete(`${window["apiLocation"]}/api/DashboardDataSource?Co=${dataSource.Co || Co}&Dashboard=${Dashboard}&Report=${Report}`);

        request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const index = _.findIndex(dashboard.Data.DataSources, { Dashboard, Report });
                if (index > -1) {
                    dashboard.Data.DataSources.splice(index, 1);
                }
                dispatch({
                    type: UPDATE_DASHBOARD,
                    payload: dashboard
                });
                dispatch(showMessage({
                    message: 'Data Source removed successfully',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        });
    }
}

export function addDashboardComponent(dashboard, component) {
    const { Dashboard, Data } = dashboard;
    const { Components } = Data;
    return (dispatch, getState) => {

        const state = getState();
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!dashboard.Co && dashboard.Dashboard < 1000 && about.InstanceType === 'D') {
            component.StandardYN = 'Y'
        }

        const request = axios.post(`${window["apiLocation"]}/api/DashboardComponent`, component);

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                const { Component } = response.data;
                const req2 = axios.post(`${window["apiLocation"]}/api/DashboardComponentAssignment`, {
                    Co,
                    Dashboard,
                    Component,
                    DisplaySeq: Components.length,
                    StandardYN: (!dashboard.Co && dashboard.Dashboard < 1000 && about.InstanceType === 'D') ? 'Y' : null,
                    Data: {
                        ErrMsg: null
                    }
                });

                req2.then((resp) => {
                    if (resp.data.Data.ErrMsg && resp.data.Data.ErrMsg.length > 0) {
                        dispatch(showMessage({
                            message: `Error: ${resp.data.Data.ErrMsg}`,
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                    } else {
                        dashboard.Data.Components.push({
                            ...resp.data,
                            Data: {
                                ...resp.data.Data,
                                Component: response.data
                            }
                        });
                        dispatch({
                            type: UPDATE_DASHBOARD,
                            payload: dashboard
                        })
                        dispatch(showMessage({
                            message: 'Component added successfully',
                            autoHideDuration: 5000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                    }
                })
            }
        })

    }
}

export function updateDashboardComponent(component) {
    const { Component } = component;
    return (dispatch, getState) => {

        const state = getState();
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!component.Co && about.InstanceType === 'D') {
            component.StandardYN = 'Y'
        }

        const request = axios.put(`${window["apiLocation"]}/api/DashboardComponent?Co=${component.Co || Co}&Component=${Component}`, component);

        request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: 'Component updated successfully',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        })

    }
}

export function removeDashboardComponent(component) {
    const { Dashboard, Component } = component;
    return (dispatch, getState) => {

        const state = getState();
        const { Co } = state.spReducers.companies;
        const { about } = state.version;

        if (!component.Co && about.InstanceType === 'D') {
            component.StandardYN = 'Y'
        }

        const request = axios.delete(`${window["apiLocation"]}/api/DashboardComponentAssignment?Co=${component.Co || Co}&Dashboard=${Dashboard}&Component=${Component}`);

        request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: 'Component removed successfully',
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        })

    }
}

export function setDashboardFilters(filters) {
    return {
        type: SET_DASHBOARD_FILTERS,
        filters
    }
}

export function setDashboardDrilled(drilled) {
    return {
        type: SET_DASHBOARD_DRILLED,
        drilled
    }
}