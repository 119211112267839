import axios from 'axios/index';
import { getUserData } from 'main/content/apps/voice-mailboxes/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_VOICE_MAILBOXES = '[VOICE MAILBOXES APP] GET VOICE MAILBOXES';
export const SET_SEARCH_TEXT = '[VOICE MAILBOXES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_VOICE_MAILBOXES = '[VOICE MAILBOXES APP] TOGGLE IN SELECTED VOICE MAILBOXES';
export const SELECT_ALL_VOICE_MAILBOXES = '[VOICE MAILBOXES APP] SELECT ALL VOICE MAILBOXES';
export const DESELECT_ALL_VOICE_MAILBOXES = '[VOICE MAILBOXES APP] DESELECT ALL VOICE MAILBOXES';
export const OPEN_NEW_VOICE_MAILBOX_DIALOG = '[VOICE MAILBOXES APP] OPEN NEW VOICE MAILBOX DIALOG';
export const CLOSE_NEW_VOICE_MAILBOX_DIALOG = '[VOICE MAILBOXES APP] CLOSE NEW VOICE MAILBOX DIALOG';
export const OPEN_EDIT_VOICE_MAILBOX_DIALOG = '[VOICE MAILBOXES APP] OPEN EDIT VOICE MAILBOX DIALOG';
export const CLOSE_EDIT_VOICE_MAILBOX_DIALOG = '[VOICE MAILBOXES APP] CLOSE EDIT VOICE MAILBOX DIALOG';
export const ADD_VOICE_MAILBOX = '[VOICE MAILBOXES APP] ADD VOICE MAILBOX';
export const UPDATE_VOICE_MAILBOX = '[VOICE MAILBOXES APP] UPDATE VOICE MAILBOX';
export const REMOVE_VOICE_MAILBOX = '[VOICE MAILBOXES APP] REMOVE VOICE MAILBOX';
export const REMOVE_VOICE_MAILBOXES = '[VOICE MAILBOXES APP] REMOVE VOICE MAILBOXES';
export const TOGGLE_STARRED_VOICE_MAILBOX = '[VOICE MAILBOXES APP] TOGGLE STARRED VOICE MAILBOX';
export const TOGGLE_STARRED_VOICE_MAILBOXES = '[VOICE MAILBOXES APP] TOGGLE STARRED VOICE MAILBOXES';
export const SET_VOICE_MAILBOXES_STARRED = '[VOICE MAILBOXES APP] SET VOICE MAILBOXES STARRED ';
export const SET_SEARCH_RESULTS = '[VOICE MAILBOXES APP] SET SEARCH RESULTS';
export const SET_RECORDING = '[VOICE MAILBOXES APP] SET RECORDING';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}


export function openNewVoiceMailboxDialog() {
    return {
        type: OPEN_NEW_VOICE_MAILBOX_DIALOG
    }
}

export function setRecording(recording, fetch) {
    return (dispatch, getState) => {
        const state = getState();
        const { Co } = state.spReducers.companies;
        if (!fetch) {
            dispatch({
                type: SET_RECORDING,
                recording
            });
        } else {
            if (recording && !recording.ID) {
                const request = axios.get(`${window["apiLocation"]}/api/Voicemail?Co=${Co}&Message=${encodeURIComponent(recording)}`);

                request.then((response) => {
                    dispatch({
                        type: SET_RECORDING,
                        recording: response.data
                    });
                })
            }
        }
    }
}

export function openEditVoiceMailboxDialog(voiceMailbox) {
    const request = axios.get(`${window["apiLocation"]}/api/VoiceMailbox?Co=${encodeURIComponent(voiceMailbox.Co)}&Mailbox=${encodeURIComponent(voiceMailbox.VoiceMailbox)}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: OPEN_EDIT_VOICE_MAILBOX_DIALOG,
                data: response.data
            })
        );
}

export function closeNewVoiceMailboxDialog() {
    return {
        type: CLOSE_NEW_VOICE_MAILBOX_DIALOG
    }
}

export function closeEditVoiceMailboxDialog() {
    return {
        type: CLOSE_EDIT_VOICE_MAILBOX_DIALOG
    }
}

export function addVoiceMailbox(newVoiceMailbox) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/VoiceMailbox`,
            newVoiceMailbox
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_VOICE_MAILBOX
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Voice Mailbox #${response.data.VoiceMailbox} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateVoiceMailbox(voiceMailbox) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/VoiceMailbox?Co=${encodeURIComponent(voiceMailbox.Co)}&Mailbox=${encodeURIComponent(voiceMailbox.VoiceMailbox)}`,
            voiceMailbox
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_VOICE_MAILBOX
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Voice Mailbox #${response.data.VoiceMailbox} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}
