import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FuseUtils, FuseAnimate, FuseAnimateGroup } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import classNames from 'classnames';
import VideoListItem from './video_list_item';

const VideoList = (props) => {
    const videoItems = props.videos.map((video) => {
        return (
            <VideoListItem
                onVideoSelect={props.onVideoSelect}
                key={video.etag}
                    video={video} />
            )
    });

    return (

        <List className="col-md-4 list-group">
            <FuseAnimateGroup
                enter={{
                    animation: "transition.slideUpBigIn"
                }}
                leave={{
                    animation: "transition.slideUpBigOut"
                }}
            >
                {videoItems}
            </FuseAnimateGroup>
        </List>
    );
}
export default VideoList;