import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CompaniesList extends Component {

    state = {
        selectedCompaniesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCompanyMenu = (event) => {
        this.setState({ selectedCompaniesMenu: event.currentTarget });
    };

    closeSelectedCompaniesMenu = () => {
        this.setState({ selectedCompaniesMenu: null });
    };

    render() {
        const { classes, securables, companies, user, searchText, openNewCompanyDialog, selectedCompanyIds, selectAllCompanies, deSelectAllCompanies, toggleInSelectedCompanies, openEditCompanyDialog, removeCompanies, removeCompany, toggleStarredCompany, setCompaniesUnstarred, setCompaniesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.companyData ? [] : this.props.companyData), searchText);
        const { selectedCompaniesMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "companies" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {(accessLevel && accessLevel.AccessLevel !== "R") &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewCompanyDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">business</Icon>Companies</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditCompanyDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Company",
                                accessor: "Co",
                                className: "font-bold justify-center",
                                width: 96
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                width: 192
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256
                            },
                            {
                                Header: "Address",
                                accessor: "Address",
                                width: 256
                            },
                            {
                                Header: "Address2",
                                accessor: "Address2",
                                width: 256
                            },
                            {
                                Header: "City",
                                accessor: "City",
                                className: "justify-center",
                                width: 140
                            },
                            {
                                Header: "State",
                                accessor: "State",
                                className: "justify-center",
                                width: 96,
                            },
                            {
                                Header: "Zip",
                                accessor: "Zip",
                                className: "justify-center",
                                width: 96,
                            },
                            {
                                Header: "Phone",
                                accessor: "Phone",
                                className: "justify-center",
                                width: 140
                            },
                            {
                                Header: "Email",
                                accessor: "Email",
                                width: 256,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Companies found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCompanies: Actions.getCompanies,
        getUserData: Actions.getUserData,
        toggleInSelectedCompanies: Actions.toggleInSelectedCompanies,
        selectAllCompanies: Actions.selectAllCompanies,
        deSelectAllCompanies: Actions.deSelectAllCompanies,
        openEditCompanyDialog: Actions.openEditCompanyDialog,
        removeCompanies: Actions.removeCompanies,
        removeCompany: Actions.removeCompany,
        toggleStarredCompany: Actions.toggleStarredCompany,
        toggleStarredCompanies: Actions.toggleStarredCompanies,
        setCompaniesStarred: Actions.setCompaniesStarred,
        setCompaniesUnstarred: Actions.setCompaniesUnstarred,
        openNewCompanyDialog: Actions.openNewCompanyDialog
    }, dispatch);
}

function mapStateToProps({ companiesApp, spReducers }) {
    return {
        companies: companiesApp.companies.entities,
        selectedCompanyIds: companiesApp.companies.selectedCompanyIds,
        searchText: companiesApp.companies.searchText,
        user: companiesApp.user,
        companyData: spReducers.companies.List.map(value => value.Data.Co),
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CompaniesList)));
