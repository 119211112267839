import _ from '@lodash';
import { Avatar, Button, CardHeader, Icon, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';


const styles = theme => ({
    green: {
        backgroundColor: '#d1e751',
        color: '#3f3f3f'
    },
    blue: {
        backgroundColor: '#4dbce9',
        color: '#fff'
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    borderTop: {
        borderTop: '1px solid lightgrey'
    },
    avatar: {
        width: 28,
        height: 28
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[600]
        },
        '&:active': {
            backgroundColor: theme.palette.error[700]
        },
        '&:focus': {
            backgroundColor: theme.palette.error[700]
        },
    },
    ok: {
        background: '#333',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#3f3f3f',
        },
        '&:focus': {
            backgroundColor: '#5f5f5f',
        },
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    greenText: {
        color: '#d1e751',
    },
});
const initialState = {
    co: null,
    taxCode: null,
    members: [],
    selectedMember: null,
};

class TaxCodeMemberList extends Component {
    state = { ..._.cloneDeepWith(initialState) };

    componentDidMount() {
        const { co, taxCode, members } = this.props;
        this.setState({ ...this.state, co, taxCode, members });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { co, taxCode, members } = this.props;
        if (!_.isEqual(prevProps.members, members) || !_.isEqual(prevProps.co, co) || !_.isEqual(prevProps.taxCode, taxCode)) {
            this.setState({ ...this.state, co, taxCode, members });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    render() {
        const { classes, taxCodes, edit } = this.props;
        let { co, taxCode, members } = this.state;

        return (
            <div className="w-full mb-24" style={{ marginTop: -8 }}>
                <label className="text-12 p-4 px-6 ml-8" style={{ background: '#fff', color: 'grey' }}>Component Rates:</label>
                <div className="mb-24 sm:mb-12 rounded-4 px-12 mb-12 py-4" style={{ border: '1px solid rgba(0, 0, 0, .25)', marginTop: -11 }}>
                    <div className={members && members.length > 0 ? "" : "my-8"}>
                        <React.Fragment>
                            {[...members].map((value, index) => {
                                const { DeleteYN, Data } = value;
                                if (DeleteYN === "Y") {
                                    return '';
                                }
                                return (
                                    <CardHeader
                                        key={value.Member}
                                        classes={{ action: "", root: classNames("p-4 py-12", index > 0 ? classes.borderTop : ''), content: "overflow-hidden", avatar: "mr-8", title: "leading-none text-14 font-bold text-grey-darkest truncate w-5/6", subheader: "leading-none text-10 text-grey" }}
                                        avatar={
                                            <Avatar color="primary" className={classNames(classes.blue, classes.avatar)}>
                                                <Icon className="text-20">format_list_numbered_rtl</Icon>
                                            </Avatar>
                                        }
                                        title={`${Data.TaxCode.Description} - ${Data.TaxCode.TaxCode}`}
                                        subheader={`${new Date(Data.TaxCode.EffectiveDate) <= new Date() ? Data.TaxCode.NewRate : Data.TaxCode.OldRate}%`}
                                        action={
                                            edit &&
                                            <div className="flex mt-12 pr-4">
                                                <Tooltip title="Remove Component Rate" placement="top">
                                                    <Icon
                                                        onClick={() => {
                                                            const { members } = _.cloneDeepWith(this.state);
                                                            const index = _.findIndex(members, { Member: value.Member });
                                                            if (index > -1) {
                                                                const rem = members[index];
                                                                if (rem.AddYN !== "Y") {
                                                                    rem.DeleteYN = 'Y';
                                                                } else {
                                                                    members.splice(index, 1);
                                                                }
                                                                this.setState({ ...this.state, members }, () => this.props.onChange(members));
                                                            }
                                                        }}
                                                        color="action"
                                                        className="text-24 cursor-pointer">
                                                        delete
                                                    </Icon>
                                                </Tooltip>
                                            </div>
                                        }
                                    />
                                );
                            })
                            }
                        </React.Fragment>
                    </div>
                    {!this.state.selectedMember && edit &&
                        <Typography
                            variant="subtitle2"
                            style={{ alignItems: 'center', borderTop: members && members.length > 0 ? '1px solid lightgrey' : 'none' }}
                            className={classNames("text-12 flex cursor-pointer justify-start", members && members.length > 0 ? "pt-12 pb-8" : "pt-4 pb-8")}
                            onClick={
                                () => {
                                    const selectedMember = {
                                        AddYN: "Y",
                                        Co: co,
                                        TaxCode: taxCode,
                                        Member: null,
                                        Data: {
                                            ErrMsg: null
                                        }
                                    };
                                    this.setState({ ...this.state, selectedMember });
                                }
                            }
                        >
                            <Icon color="primary" className="text-18 mr-4" style={{ marginBottom: 2 }}>add</Icon>
                            New Component Rate
                        </Typography>
                    }
                    {this.state.selectedMember &&
                        <div className="w-full relative">
                            <label style={{ zIndex: 999 }} color="action" className="text-12 font-bold absolute pin-l pin-t mt-8 ml-4 cursor-pointer">New Component Rate:</label>
                            <Icon style={{ zIndex: 999 }} onClick={() => this.setState({ ...this.state, selectedMember: null })} color="action" className="text-24 absolute pin-r pin-t mt-4 cursor-pointer">close</Icon>
                            <div className="w-full flex mb-12 relative pt-36" style={{ borderTop: members && members.length > 0 ? '1px solid lightgrey' : "none" }}>
                                <div className="w-full">
                                    <div className="sm:flex clear px-4">
                                        <div className="w-full">
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Component Rate"
                                                options={_.filter(taxCodes, (o) => o.MultiLevelYN !== "Y" && _.findIndex(members, { Member: o.TaxCode }) < 0)}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.TaxCode}>
                                                        {value.TaxCode ? `${value.Description} - ${value.TaxCode}` : value.Description}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.selectedMember.Member || ''}
                                                onSelect={(option) => this.setState({ ...this.state, selectedMember: { ...this.state.selectedMember, Member: option.TaxCode, Data: { TaxCode: option } } })}
                                            // required
                                            />
                                            <Button
                                                variant="contained"
                                                className="w-full mt-12"
                                                color="primary"
                                                // onClick={this.addContact}
                                                onClick={() => {
                                                    const { selectedMember, members } = _.cloneDeepWith(this.state);
                                                    members.push(selectedMember);
                                                    this.setState({ ...this.state, members, selectedMember: null }, () => this.props.onChange(members));
                                                }}
                                                disabled={!this.state.selectedMember.Member}
                                            >
                                                Add Component Rate
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        taxCodes: spReducers.taxCodes,
        User: spReducers.userProfiles.User,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TaxCodeMemberList));
