import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from 'lodash';
import moment from 'moment';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class VendorMaterialsList extends Component {

    state = {
        selectedVendorMaterialsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, vendorGroups, user, searchText, costTypes, selectedVendorMaterialIds, fleetVehicles, selectAllVendorMaterials, deSelectAllVendorMaterials, technicians, toggleInSelectedVendorMaterials, openEditVendorMaterialDialog, removeVendorMaterials, removeVendorMaterial, toggleStarredVendorMaterial, setVendorMaterialsUnstarred, setVendorMaterialsStarred, openNewVendorMaterialDialog } = this.props;
        const data = this.props.selectedVendorMaterialData;
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_bulleted</Icon>Vendor Materials</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        this.props.onSelectedMaterial({ ...rowInfo.original });
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Material",
                                accessor: "Material",
                                minWidth: 128,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Vendor Part #",
                                accessor: "VendorMaterial",
                                minWidth: 128,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Description",
                                id: "Description",
                                className: "justify-center",
                                minWidth: 256,
                                accessor: r => (r.Data && r.Data.Material) && r.Data.Material.Description

                            },
                            {
                                Header: "Cost",
                                id: "Cost",
                                className: "justify-center",
                                accessor: r => `$${r.Cost.toFixed(2)}`
                            },
                            {
                                Header: "UOM",
                                id: "UOM",
                                className: "justify-center",
                                accessor: r => (r.Data && r.Data.Material) && r.Data.Material.UOM
                            },
                            {
                                Header: "Added By",
                                accessor: "AddedBy",
                                className: "justify-center",
                                minWidth: 192,
                            },
                            {
                                Header: "Added Date",
                                accessor: "AddedDate",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    row.original.AddedDate &&
                                    moment(moment(row.original.AddedDate)).local().toDate().toLocaleDateString('en-US')
                                )
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Vendor Materials found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ vendorsApp, spReducers }) {
    return {
        vendorData: spReducers.vendors,
        user: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorMaterialsList)));
