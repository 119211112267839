import React, { Component } from 'react';
import { TextField, Button, Avatar } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import axios from 'axios/index';
import _ from '@lodash';
import getProfileImage from '../../../../functions/getProfileImageUrl';

class CardComment extends Component {
    state = {
        message: ''
    };

    handleChange = (event) => {
        this.setState(_.setIn(this.state, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    submit = (ev) => {
        const { Co, type, recordID, user } = this.props;
        const { UserName } = user;
        ev.preventDefault();
        if (!this.canSubmit()) {
            return;
        }
        const comment = {
            Co,
            Type: type,
            ID: recordID,
            UserName,
            Comment: this.state.message,
            AddedBy: UserName,
            AddedDate: new Date(),
            Data: {
                ErrMsg: null,
            }
        }
        axios.put(`${window["apiLocation"]}/api/Comment?Co=${Co}&Type=${encodeURIComponent(type)}&ID=${recordID}`, comment).then((response) => {
            this.setState({ message: '' });
            this.props.onCommentAdd(response.data);
        })
    };

    canSubmit = () => {
        return this.state.message !== '';
    };

    render() {
        const { Co, user } = this.props;
        const avatar = 'assets/images/avatars/profile.jpg';
        return (
            <form onSubmit={this.submit} className="flex mt-12">
                <Avatar className="w-32 h-32 mt-12" alt={user && user.UserName} src={(user && user.Data && user.Data.Avatar) ? getProfileImage(`Co=${Co}&ID=${user.Data.Avatar}`) : avatar} />
                <div className="flex flex-col items-start flex-1 pr-0 pl-16">
                    <TextField
                        className="flex flex-1"
                        fullWidth
                        name="message"
                        row={3}new 
                        value={this.state.message}
                        onChange={this.handleChange}
                        variant="outlined"
                        label="Add comment"
                        placeholder="Write a comment..."
                    />
                    <Button
                        className="mt-16"
                        aria-label="save"
                        variant="contained"
                        color="secondary"
                        type="submit"
                        size="small"
                        disabled={!this.canSubmit()}
                    >
                        Save
                    </Button>
                </div>
            </form>
        );
    }
}

function mapStateToProps({ auth, scrumboardApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
    }
}

export default connect(mapStateToProps)(CardComment);
