import axios from 'axios/index';

export const SET_EMPLOYEE_DATA = '[SPCONNECTION] SET EMPLOYEE DATA';
export const GET_EMPLOYEE_DATA = '[SPCONNECTION] GET EMPLOYEE DATA';
export const SUBSCRIBE_EMPLOYEE_LIST = '[SPCONNECTION] SUBSCRIBE EMPLOYEE LIST';

export function getInitialEmployeeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Employee`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getEmployeeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Employee`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_EMPLOYEE_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_EMPLOYEE_LIST,
            Co
        }));
    }
}

export function setEmployeeData(data) {
    return {
        type: SET_EMPLOYEE_DATA,
        payload: data
    };
}