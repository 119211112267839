import React, { Component } from 'react';
import {
    Grow, Slide, TextField, Button, CircularProgress, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, Tooltip, AppBar, Avatar, MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import _ from '@lodash';
import Media from 'react-media';
import BarcodeScanner from 'main/content/components/barcode-scanner/BarcodeScanner';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { openEditMaterialDialog, closeEditMaterialDialog } from '../materials/store/actions/materials.actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
});
const newCatalogAssemblyComponentState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    AssemblyID: null,
    Component: null,
    Type: 2,
    Qty: null,
    UOM: 'EA',
    Description: '',
    LaborType: null,
    Material: null,
    Assembly: null,
    CostType: null,
    UnitCost: 0.00,
    CostTotal: 0.00,
    CostDate: new Date(),
    UnitPrice: 0.00,
    Price: 0.00,
    PriceTotal: 0.00,
    PriceMethod: 'F',
    OptionalYN: 'N',
    TaxableYN: 'Y',
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    Notes: null,
    AttachmentID: null,
    AddonYN: 'N',
    Data: {
        ErrMsg: null,
    },
    scanner: null,
    isScanning: false,
    Advanced: 'N',
    miscMarkupType: 2,
    searching: false,
    saving: false,
};

class CatalogAssemblyComponentDialog extends Component {
    state = { ...newCatalogAssemblyComponentState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newCatalogAssemblyComponentState.Co = this.props.Co;
        if (!prevProps.catalogAssemblyComponentDialog.props.open && this.props.catalogAssemblyComponentDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.catalogAssemblyComponentDialog.type === 'edit' &&
                this.props.catalogAssemblyComponentDialog.data &&
                !_.isEqual(this.props.catalogAssemblyComponentDialog.data, prevState)) {
                this.setState({ ...newCatalogAssemblyComponentState, ...this.props.catalogAssemblyComponentDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.catalogAssemblyComponentDialog.type === 'new') {
                let usage = { ...newCatalogAssemblyComponentState, ...this.props.catalogAssemblyComponentDialog.data };
                if (!_.isEqual({ ...usage }, prevState)) {
                    this.setState({ ...usage });
                }
            }
        } else {
            if (this.props.catalogAssemblyComponentDialog.type === 'update' &&
                this.props.catalogAssemblyComponentDialog.data &&
                !_.isEqual(this.props.catalogAssemblyComponentDialog.data, prevState)) {
                this.props.catalogAssemblyComponentDialog.type = 'edit';
                this.setState({ ...newCatalogAssemblyComponentState, ...this.props.catalogAssemblyComponentDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value), this.handleTechLabor);
    };

    handleTypeChange = (event) => {
        let Type = event.target.value;
        this.setState({ ...this.state, Type, UOM: Type === 1 ? 'HR' : '' }, this.handleTechLabor);
    }

    closeComposeDialog = () => {
        this.props.catalogAssemblyComponentDialog.type === 'edit' ? this.props.closeEditCatalogAssemblyComponentDialog() : this.props.closeNewCatalogAssemblyComponentDialog();
    };

    canBeSubmitted = () => {
        const { Co, BusinessUnit, Division, Type, Qty, Description, UOM, Price, PriceTotal } = this.state;
        return Co && BusinessUnit && Division && !isNaN(Type) && Qty && UOM && Description && (!isNaN(Price) || !isNaN(PriceTotal));
    }

    formatUnitCost = () => {
        const { Qty, UnitCost } = this.state;
        this.setState({
            ...this.state,
            UnitCost: UnitCost ? parseFloat(UnitCost).toFixed(2) : null,
            CostTotal: Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null,
        }, this.handleMarkup);
    }

    formatCostTotal = () => {
        const { Qty, CostTotal } = this.state;
        this.setState({
            ...this.state,
            UnitCost: CostTotal && Qty ? parseFloat(CostTotal / Qty).toFixed(2) : null,
            CostTotal: CostTotal ? parseFloat(CostTotal).toFixed(2) : null,
        }, this.handleMarkup);
    }

    formatUnitPrice = () => {
        const { Qty, UnitPrice } = this.state;
        this.setState({
            ...this.state,
            UnitPrice: UnitPrice ? parseFloat(UnitPrice).toFixed(2) : null,
            PriceTotal: Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null,
        });
    }

    formatPriceTotal = () => {
        const { Qty, PriceTotal } = this.state;
        this.setState({
            ...this.state,
            UnitPrice: PriceTotal && Qty ? parseFloat(PriceTotal / Qty).toFixed(2) : null,
            PriceTotal: PriceTotal ? parseFloat(PriceTotal).toFixed(2) : null,
        });
    }

    handleLaborChange = (event) => {
        const { laborTypes } = this.props;
        const { Co, Qty, BusinessUnit, Division } = this.state;
        let LaborType = event.target.value;
        const laborType = _.find(laborTypes, { Co, BusinessUnit, Division, LaborType });
        if (laborType) {
            this.setState({ ...this.state, LaborType, Description: `${laborType.Description}`, UnitCost: laborType.StandardUnitCost, CostTotal: Qty * laborType.StandardUnitCost }, this.handleTechLabor);
        }
    }

    formatCostAndPrice = () => {
        let { Qty, UnitPrice, UnitCost } = this.state;
        this.setState({
            ...this.state,
            UnitCost,
            CostTotal: Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null,
            UnitPrice,
            PriceTotal: Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null,
        }, this.handleMarkup);
    }

    handleMarkup = () => {
        let { Type, LaborType, Qty, UnitPrice, PriceTotal, UnitCost, CostTotal, miscMarkupType } = this.state;
        const RateTemplate = this.props.rateTemplate;
        if (RateTemplate) {
            switch (Type) {
                case 0: {
                    if (!miscMarkupType || miscMarkupType === 0) {
                        if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                            UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MiscMarkup / 100))).toFixed(2);
                            CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                            PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                        } else {
                            if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                                UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MiscMarkup / 100))).toFixed(2);
                                CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            }
                        }
                    }
                    if (miscMarkupType === 2) {
                        if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                            UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                            CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                            PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                var bp = RateTemplate.Data.Materials[i];
                                if (UnitCost >= bp.Breakpoint) {
                                    UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                                    CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                    PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                }
                            }
                        } else {
                            if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                                UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                                CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                    var bp = RateTemplate.Data.Materials[i];
                                    if (UnitCost >= bp.Breakpoint) {
                                        UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                                        CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                        PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                    }
                                }
                            }
                        }
                    }
                }
                    break;
                case 1: {
                    UnitPrice = RateTemplate.LaborRate;
                    if (LaborType) {
                        for (var i = 0; i < RateTemplate.Data.Labor.length; i++) {
                            if (LaborType == RateTemplate.Data.Labor[i].LaborType) {
                                UnitPrice = parseFloat(RateTemplate.Data.Labor[i].Rate).toFixed(2);
                                PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            }
                        }
                    }
                }
                    break;
                case 2: {
                    if (UnitCost && RateTemplate.MarkupByTotal !== 'Y') {
                        UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                        CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                        PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                        for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                            var bp = RateTemplate.Data.Materials[i];
                            if (UnitCost >= bp.Breakpoint) {
                                UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                                CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            }
                        }
                    } else {
                        if (CostTotal && RateTemplate.MarkupByTotal === 'Y') {
                            UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (RateTemplate.MaterialMarkup / 100))).toFixed(2);
                            CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                            PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                            for (var i = 0; i < RateTemplate.Data.Materials.length; i++) {
                                var bp = RateTemplate.Data.Materials[i];
                                if (UnitCost >= bp.Breakpoint) {
                                    UnitPrice = (Number(UnitCost) + (Number(UnitCost) * (bp.Markup / 100))).toFixed(2);
                                    CostTotal = Qty && UnitCost ? parseFloat(UnitCost * Qty).toFixed(2) : null;
                                    PriceTotal = Qty && UnitPrice ? parseFloat(UnitPrice * Qty).toFixed(2) : null;
                                }
                            }
                        }
                    }
                }
                    break;
                case 3: {

                }
                    break;
                case 4: {

                }
                case 5: {

                }
                    break;
            }
        }
        this.setState({
            ...this.state,
            UnitCost,
            CostTotal,
            UnitPrice,
            PriceTotal,
        });
    }

    handlePart = (part, qty) => {
        const { Material, UOM, Description, Cost, CostType } = part;
        this.setState({ ...this.state, Qty: qty ? qty : 1, Material, UOM, Description, UnitCost: Cost, CostType, TotalCost: Cost, isScanning: false }, () => {
            this.formatUnitCost();
        })
    }

    getPart = () => {
    }

    handleMaterialSearch = () => {
        const { Co, Material } = this.state;
        this.props.openEditMaterialDialog({ Co, Material });
    }

    render() {
        const { classes, securables, catalogAssemblyComponentDialog, uoms, materials, types, laborTypes, addAssemblyComponent, updateAssemblyComponent, removeAssemblyComponent } = this.props;
        let { isScanning, searching, saving } = this.state;
        const accessLevel = _.find(securables, { Securable: "catalog" });

        const matAvatar = 'assets/images/logos/SP_Cog_Gradient.png';

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        {...catalogAssemblyComponentDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                    >
                        <BarcodeScanner open={isScanning} onScan={this.getPart} onClose={() => { this.setState({ ...this.state, isScanning: false, }) }} />

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="md:flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {catalogAssemblyComponentDialog.type === 'new' ? 'New Assembly Component' : `Assembly Component #${this.state.Component}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="md:flex">
                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">format_list_bulleted</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Type"
                                    select
                                    id="type"
                                    name="Type"
                                    value={this.state.Type || 0}
                                    onChange={this.handleTypeChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.Type || this.state.Type === 0 ? true : false }}
                                    required
                                    fullWidth
                                    disabled={Boolean(this.state.ID)}
                                >
                                    {(types && types.length > 0 ? _.filter(types, (o) => { return o.UsageType < 3 }).map((value, index) => (
                                        <MenuItem key={index} value={value.UsageType}>
                                            {`${value.UsageType} - ${value.Name}`}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                                {this.state.Type === 1 &&
                                    <React.Fragment>
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Task / Add-On"
                                            id="addOnYN"
                                            name="AddonYN"
                                            value={this.state.AddonYN || 'N'}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            <MenuItem value="N">
                                                Task
                                            </MenuItem>
                                            <MenuItem value="Y">
                                                Add-On
                                            </MenuItem>
                                        </TextField>
                                    </React.Fragment>
                                }
                                <div className="hidden md:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Price Method"
                                    id="priceMethod"
                                    name="PriceMethod"
                                    value={this.state.PriceMethod || 'T'}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="T">
                                        Rate Template
                                    </MenuItem>
                                    <MenuItem value="F">
                                        Flat Price
                                    </MenuItem>
                                </TextField>
                                {this.state.PriceMethod === 'F' &&
                                    <React.Fragment>
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Price"
                                            id="price"
                                            name="Price"
                                            value={this.state.Price}
                                            onChange={this.handleChange}
                                            onBlur={this.formatPriceTotal}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                type: "number"
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    </React.Fragment>
                                }
                            </div>

                            <div className="md:flex">
                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">build</Icon>
                                </div>
                                <div className="md:flex w-full">
                                    <div className="md:flex md:w-1/3">
                                        <TextField
                                            className={classes.formControl}
                                            label="Qty"
                                            id="qty"
                                            name="Qty"
                                            value={this.state.Qty || ''}
                                            onChange={this.handleChange}
                                            onBlur={this.formatCostAndPrice}
                                            InputLabelProps={{ shrink: this.state.Qty ? true : false }}
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            required
                                        />
                                        <div className="hidden md:block min-w-12 pt-20">
                                        </div>
                                        <Autocomplete
                                            className={classes.formControl}
                                            title="UOM"
                                            options={uoms}
                                            menuItemComponent={(value) => {
                                                return value.ActiveYN === "Y" &&
                                                    <MenuItem value={value.UOM}>
                                                        <div className="flex">
                                                            {`${value.UOM} - ${value.Description}`}
                                                        </div>
                                                    </MenuItem>
                                            }}
                                            value={this.state.UOM || ''}
                                            noflip={true}
                                            onSelect={(option) => this.setState({ ...this.state, UOM: option.UOM })}
                                            required
                                            portal={true}
                                            disabled={this.state.Type === 1}
                                        />
                                    </div>
                                    <div className="hidden md:block min-w-12 pt-20">
                                    </div>
                                    <div className="flex md:w-2/3">
                                        {this.state.Type === 1 ?
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Labor Type"
                                                options={_.filter(laborTypes, { Type: 'W', BusinessUnit: this.state.BusinessUnit, Division: this.state.Division })}
                                                menuItemComponent={(value) => {
                                                    return (
                                                        <MenuItem value={value.LaborType}>
                                                            {`${value.LaborType} - ${value.Name}`}
                                                        </MenuItem>
                                                    );
                                                }}
                                                value={this.state.LaborType || ''}
                                                portal={true}
                                                noflip={true}
                                                onSelect={(option) => this.setState({ ...this.state, LaborType: option.LaborType, Description: `${option.Description}`, UnitCost: option.StandardUnitCost, CostTotal: this.state.Qty * option.StandardUnitCost }, this.handleTechLabor)}
                                                required
                                            /> :
                                            this.state.Type === 2 ?
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Material"
                                                    options={materials}
                                                    menuItemComponent={(value) => {
                                                        return value.ActiveYN === "Y" &&
                                                            <MenuItem value={value.Material}>
                                                                <div className="flex">
                                                                    <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Material} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true` ): matAvatar)} />
                                                                    <div className="truncate">{`${value.Material} - ${(value.Description || '').substr(0, 50)}`}</div>
                                                                </div>
                                                            </MenuItem>
                                                    }}
                                                    debounce={750}
                                                    value={this.state.Material || ''}
                                                    portal={true}
                                                    noflip={true}
                                                    onSelect={(option) => this.setState({ ...this.state, Material: option.Material, Description: option.Description, Qty: this.state.Qty ? this.state.Qty : 1, UOM: option.UOM, UnitCost: option.Cost, searching: false }, () => this.formatCostAndPrice())}
                                                    required
                                                /> :
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Description"
                                                    id="description"
                                                    name="Description"
                                                    value={this.state.Description || ''}
                                                    onChange={this.handleChange}
                                                    InputProps={this.state.Type === 2 ? {
                                                        endAdornment: !this.state.Material ?
                                                            <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: true, })} style={{ cursor: 'pointer' }}>search</Icon></InputAdornment>
                                                            :
                                                            <InputAdornment position="end"><Icon onClick={() => this.setState({ ...this.state, searching: false, Description: null, Material: null, UnitCost: 0, CostTotal: 0, UnitPrice: 0, PriceTotal: 0 })} style={{ cursor: 'pointer' }}>close</Icon></InputAdornment>,
                                                    } : undefined}
                                                    InputLabelProps={{ shrink: this.state.Description ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                />
                                        }
                                        {this.state.Type === 2 && this.state.Material &&
                                            <React.Fragment>
                                                <div className="min-w-12 pt-20">
                                                </div>
                                                <Tooltip placement="top" title="View Material Record">
                                                    <Button
                                                        className={classes.formControl}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            Promise.all([
                                                                this.props.closeEditMaterialDialog()
                                                            ]).then(() => {
                                                                this.props.openEditMaterialDialog({ Co: this.state.Co, Material: this.state.Material })
                                                            });
                                                        }}
                                                    >
                                                        <Icon>open_in_new</Icon>
                                                    </Button>
                                                </Tooltip>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>

                            {this.state.PriceMethod === 'T' &&
                                <div className="md:flex">
                                    <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                        <Icon color="action">attach_money</Icon>
                                    </div>
                                    <div className="md:flex w-full">
                                        <div className="md:flex md:w-1/2">

                                            <TextField
                                                className={classes.formControl}
                                                label="Unit Cost"
                                                id="unitCost"
                                                name="UnitCost"
                                                value={this.state.UnitCost || ''}
                                                onChange={this.handleChange}
                                                onBlur={this.formatUnitCost}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    type: "number"
                                                }}
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <div className="hidden md:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={classes.formControl}
                                                label="Total Cost"
                                                id="costTotal"
                                                name="CostTotal"
                                                value={this.state.CostTotal || ''}
                                                onChange={this.handleChange}
                                                onBlur={this.formatCostTotal}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    type: "number"
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                            />
                                            <div className="hidden md:block min-w-12 pt-20">
                                            </div>
                                        </div>
                                        <div className="md:flex md:w-1/2">
                                            <TextField
                                                className={classes.formControl}
                                                label="Unit Price"
                                                id="unitPrice"
                                                name="UnitPrice"
                                                value={this.state.UnitPrice || ''}
                                                onChange={this.handleChange}
                                                onBlur={this.formatUnitPrice}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    type: "number"
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                            />
                                            <div className="hidden md:block min-w-12 pt-20">
                                            </div>
                                            <TextField
                                                className={classes.formControl}
                                                label="Total Price"
                                                id="priceTotal"
                                                name="PriceTotal"
                                                value={this.state.PriceTotal || ''}
                                                onChange={this.handleChange}
                                                onBlur={this.formatPriceTotal}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    type: "number"
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="md:flex">
                                <div className="hidden md:block min-w-48 pb-12 md:pb-4  p-0 md:pt-20">
                                    <Icon color="action">note</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {catalogAssemblyComponentDialog.type === 'new' ? (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                addAssemblyComponent(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Add
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ ...this.state, saving: true }, () => {
                                                    updateAssemblyComponent(this.state);
                                                    this.setState({ ...this.state, saving: false }, () => this.closeComposeDialog())
                                                })
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            <React.Fragment>
                                                {saving ? <CircularProgress color="secondary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8">save</Icon>}
                                                {saving ? 'Saving' : `Save`}
                                            </React.Fragment>
                                        </Button>
                                        <IconButton
                                            onClick={() => {
                                                removeAssemblyComponent(this.state);
                                                this.closeComposeDialog();
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditCatalogAssemblyComponentDialog: Actions.closeEditCatalogAssemblyComponentDialog,
        closeNewCatalogAssemblyComponentDialog: Actions.closeNewCatalogAssemblyComponentDialog,
        addAssemblyComponent: Actions.addAssemblyComponent,
        updateAssemblyComponent: Actions.updateAssemblyComponent,
        removeAssemblyComponent: Actions.removeAssemblyComponent,
        openEditMaterialDialog,
        closeEditMaterialDialog,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        catalogAssemblyComponentDialog: catalogApp.catalogAssemblyComponentDialog,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User.UserName,
        types: spReducers.workOrderUsageTypes,
        businessUnits: spReducers.businessUnits,
        laborTypes: spReducers.laborTypes,
        costTypes: spReducers.costTypes,
        rateTemplates: spReducers.rateTemplates,
        materials: spReducers.materials.materials,
        securables: spReducers.userProfiles.User.Data.Securables,
        uoms: spReducers.uoms,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogAssemblyComponentDialog));
