import {combineReducers} from 'redux';
import workOrderHistory from './work-order-history.reducer';
import user from './user.reducer';

const workOrderHistoryAppReducers = combineReducers({
    workOrderHistory,
    user
});

export default workOrderHistoryAppReducers;
