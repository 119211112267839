import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import ArrivalWindowList from 'main/content/apps/arrival-windows/ArrivalWindowsList';
import ArrivalWindowHeader from 'main/content/apps/arrival-windows/ArrivalWindowsHeader';
import ArrivalWindowSidebarContent from 'main/content/apps/arrival-windows/ArrivalWindowsSidebarContent';
import ArrivalWindowsLeftSidebarHeader from 'main/content/apps/arrival-windows/ArrivalWindowsLeftSidebarHeader';
import _ from '@lodash';
import { Button, Icon } from '@material-ui/core';
import ArrivalWindowDialog from 'main/content/apps/arrival-windows/ArrivalWindowDialog';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class ArrivalWindowsApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getArrivalWindows(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewArrivalWindowDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <ArrivalWindowHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <ArrivalWindowList />
                            }
                            leftSidebarHeader={
                                <ArrivalWindowsLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <ArrivalWindowSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                        <ArrivalWindowDialog />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getArrivalWindows: Actions.getArrivalWindows,
        getUserData: Actions.getUserData,
        openNewArrivalWindowDialog: Actions.openNewArrivalWindowDialog
    }, dispatch);
}

function mapStateToProps({ arrivalWindowsApp }) {
    return {
        arrivalWindows: arrivalWindowsApp.arrivalWindows.entities,
        selectedArrivalWindowIds: arrivalWindowsApp.arrivalWindows.selectedArrivalWindowIds,
        searchText: arrivalWindowsApp.arrivalWindows.searchText,
        user: arrivalWindowsApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrivalWindowsApp)));
