import _ from '@lodash';
import { Avatar, Card, CardActions, CardContent, CardMedia, Chip, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    card: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid lightgrey',
        boxShadow: '0 -2px 4px 0 #9f9f9f',
        cursor: 'pointer',
    },
    cardActions: {
        padding: 8,
        color: theme.palette.primary.contrastText,
        borderTop: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 #9f9f9f',
        display: 'block',
        padding: 0,
    },
    cardContent: {
        backgroundImage: 'url("assets/images/logos/SP_Cog_Gradient.png")',
        backgroundColor: '#3f3f3f',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        overflow: 'hidden',
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    cardSubheader: {
        fontSize: 11,
    },
    avatar: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    completedAvatar: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    dark: {
        color: '#fff'
    },
    light: {
        color: '#3f3f3f'
    },
    formControl: {
        marginBottom: 6
    },
    saveButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    deleteButton: {
        minHeight: 0,
        height: 32,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        zIndex: 3,
        padding: 2,
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    hoverable: {
        "& .showOnHover": {
            display: 'none',
        },
        "&:hover .showOnHover":
        {
            display: 'block'
        },
        // "&:active": {
        //     transform: 'scale(.95)'
        // }
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    scaleButton: {
        "&:active": {
            transform: 'scale(.95)'
        }
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    progress: {
        width: 48,
        height: 48,
        margin: 'auto',
        marginBottom: -36,
        top: -30,
    },
    roundProgress: {
        width: '72px !important',
        height: '72px !important',
    },
    avatar: {
        backgroundColor: '#fff !important',
        width: 48,
        height: 48,
    },
    labels: {},
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
});

const initialState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Assembly: null,
    Category: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
    editing: false,
    imgSearch: false,
};

class EstimateOptionCard extends Component {

    state = {
        ID: null,
        Co: null,
        Assembly: null,
        Category: null,
        Data: {
            Steps: [],
            ErrMsg: null,
        },
        Steps: 0,
        open: false,
        editing: false,
    }

    componentDidMount() {
        const { value } = this.props;
        // if (value.Data && value.Data.Image) {

        // }
        this.setState({ ...initialState, ...value });
    }

    componentDidUpdate = (prevProps) => {
        const { value } = this.props;
        if (!_.isEqual(value, prevProps.value)) {
            this.setState({ ...this.state, ...value });
        }
    }

    render() {
        const { classes, value, user, option, optionBoard, estimate, catalog, co } = this.props;
        const { open } = this.state;
        return (
            <div className="w-160 min-w-160 p-6 m-0">
                <Card className="mt-8 w-full relative cursor-pointer rounded-lg">
                    {option &&
                        <div className={classNames("pin-r pin-t m-8 absolute text-center text-24 p-8 pt-4 pb-4 font-bold z-10")} style={{ backgroundColor: `#3f3f3f`, lineHeight: '16px', borderRadius: 24 }}>
                            <div class="w-full text-14 text-white">{option.Data.Components.length}</div>
                        </div>
                    }
                    <CardMedia
                        option="image"
                        className={!option ? "h-160 sm:h-192 w-full relative" : "h-128 w-full relative"}
                        image={"assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"}
                        alt="SP Pro"
                        style={{
                            background: catalog.EstimateHeaderImg ? 'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,0)), url(assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg)' : '#fff',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                        alt={option.Name}
                    >
                        <img src={catalog.EstimateHeaderImg || co.ImgUrl || "assets/images/backgrounds/SP_Spinner_BG_Black_XS.jpg"} className={catalog.EstimateHeaderImg ? "pin-t pin-b pin-l pin-r m-auto absolute w-5/6" : "pin-t pin-b pin-l pin-r m-auto absolute"} />
                    </CardMedia>
                    <CardContent classes={{ root: "px-6 py-12 pb-0 has-hover" }}>
                        <div className={classNames(classes.progress, "relative")}>
                            <Avatar className={classNames(classes.avatar, "absolute pin-t cursor-pointer")}>
                                <SPRoundIcon className="text-36" style={{ color: '#5f5f5f' }} />
                            </Avatar>
                        </div>
                        <Typography onClick={(e) => { e.stopPropagation(); this.setState({ open: !open }) }} gutterBottom variant="h6" style={{ fontSize: 16 }} option="div" className="flex">
                            <div className={!open ? "truncate w-full text-center" : "w-full text-center"}>{`${option.Name} - ${option.Option} of ${optionBoard.Data.Options.length}`}</div>
                        </Typography>
                    </CardContent>
                    <CardActions className="w-full p-12 pt-0 px-6">
                        <div className="w-full">
                            <Chip
                                onClick={() => this.props.onCopy(option.Data.Components)}
                                className={classNames(classes.black, classes.scaleButton, "w-full font-bold")}
                                icon={<Icon className={classNames(classes.colorSecondary, "text-24")}>file_copy</Icon>}
                                label="Copy Items"
                            />
                        </div>
                    </CardActions>
                </Card>
            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, catalogApp }) {
    return {

    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimateOptionCard)));
