import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class VendorsList extends Component {

    state = {
        selectedVendorsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedVendorMenu = (event) => {
        this.setState({ selectedVendorsMenu: event.currentTarget });
    };

    closeSelectedVendorsMenu = () => {
        this.setState({ selectedVendorsMenu: null });
    };

    formatDateText(dt) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, vendorGroups, user, searchText, costTypes, selectedVendorIds, fleetVehicles, selectAllVendors, deSelectAllVendors, technicians, toggleInSelectedVendors, openEditVendorDialog, removeVendors, removeVendor, toggleStarredVendor, setVendorsUnstarred, setVendorsStarred, openNewVendorDialog } = this.props;
        const data = this.getFilteredArray((!this.props.selectedVendorData ? !this.props.vendorData ? [] : this.props.vendorData : this.props.selectedVendorData), searchText);
        const { selectedVendorsMenu } = this.state;
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewVendorDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">store</Icon>Vendors</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditVendorDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Vendor",
                                accessor: "Vendor",
                                minWidth: 128,
                                className: "justify-center font-bold",
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                minWidth: 256,
                            },
                            {
                                Header: "Address",
                                accessor: "Address1",
                                //filterable: true,
                                width: 300
                            },
                            {
                                Header: "City",
                                accessor: "City",
                                //filterable: true,
                                className: "justify-center",
                                width: 150
                            },
                            {
                                Header: "State",
                                accessor: "State",
                                //filterable: true,
                                className: "justify-center",
                                width: 100
                            },
                            {
                                Header: "Zip",
                                accessor: "Zip",
                                //filterable: true,
                                className: "justify-center",
                                width: 100
                            },
                            {
                                Header: "Created By",
                                accessor: "CreatedBy",
                                className: "justify-center",
                                minWidth: 256,
                            },
                            {
                                Header: "Created Date",
                                accessor: "CreatedDate",
                                className: "justify-center",
                                minWidth: 128,
                                Cell: row => (
                                    row.original.CreatedDate &&
                                    moment(moment(row.original.CreatedDate)).local().toDate().toLocaleDateString('en-US')
                                )
                            },
                            {
                                Header: "Active?",
                                accessor: "ActiveYN",
                                className: "justify-center",
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Vendors found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getVendors: Actions.getVendors,
        getUserData: Actions.getUserData,
        toggleInSelectedVendors: Actions.toggleInSelectedVendors,
        selectAllVendors: Actions.selectAllVendors,
        deSelectAllVendors: Actions.deSelectAllVendors,
        openEditVendorDialog: Actions.openEditVendorDialog,
        removeVendors: Actions.removeVendors,
        removeVendor: Actions.removeVendor,
        toggleStarredVendor: Actions.toggleStarredVendor,
        toggleStarredVendors: Actions.toggleStarredVendors,
        setVendorsStarred: Actions.setVendorsStarred,
        setVendorsUnstarred: Actions.setVendorsUnstarred,
        openNewVendorDialog: Actions.openNewVendorDialog
    }, dispatch);
}

function mapStateToProps({ vendorsApp, spReducers }) {
    return {
        vendorData: spReducers.vendors,
        searchText: vendorsApp.vendors.searchText,
        user: spReducers.userProfiles.User.UserName,
        vendorGroups: spReducers.vendorGroups,
        employees: spReducers.employees,
        fleetVehicles: spReducers.fleetVehicles,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorsList)));
