import axios from 'axios'
import { getUserData } from 'main/content/apps/tech-ua/store/actions/user.actions'
import { showMessage } from 'store/actions'
import { getTechnicianUATimeData } from '../../../../../../store/actions/spConnection'
import _ from '@lodash'

export const SET_SEARCH_TEXT = '[TECH_UA APP] SET SEARCH TEXT'
export const OPEN_NEW_TECH_UA_DIALOG = '[TECH_UA APP] OPEN NEW TECH UA DIALOG'
export const CLOSE_NEW_TECH_UA_DIALOG = '[TECH_UA APP] CLOSE NEW TECH UA DIALOG'
export const OPEN_EDIT_TECH_UA_DIALOG = '[TECH_UA APP] OPEN EDIT TECH UA DIALOG'
export const UPDATE_EDIT_TECH_UA_DIALOG =
  '[TECH_UA APP] UPDATE EDIT TECH UA DIALOG'
export const CLOSE_EDIT_TECH_UA_DIALOG =
  '[TECH_UA APP] CLOSE EDIT TECH UA DIALOG'
export const ADD_TECH_UA = '[TECH_UA APP] ADD TECH UA'
export const UPDATE_TECH_UA = '[TECH_UA APP] UPDATE TECH UA'
export const REMOVE_TECH_UA = '[TECH_UA APP] REMOVE TECH UA'

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function openNewTechUADialog(data) {
  return {
    type: OPEN_NEW_TECH_UA_DIALOG,
    data,
  }
}

export function closeNewTechUADialog() {
  return {
    type: CLOSE_NEW_TECH_UA_DIALOG,
  }
}

export function openEditTechUADialog(data) {
  return {
    type: OPEN_EDIT_TECH_UA_DIALOG,
    data,
  }
}

export function updateEditTechUADialog(data) {
  return {
    type: UPDATE_EDIT_TECH_UA_DIALOG,
    data,
  }
}

export function closeEditTechUADialog() {
  return {
    type: CLOSE_EDIT_TECH_UA_DIALOG,
  }
}

export function addTechUATime(newTechUA) {
  return (dispatch, getState) => {
    const { routeParams } = getState().techUAApp.techUA

    const request = axios.post(
      `${window['apiLocation']}/api/TechnicianUATime`,
      newTechUA,
    )

    return request.then(response =>
      Promise.all([dispatch(getTechnicianUATimeData(newTechUA.Co))]).then(
        () => {
          if (
            response.data.Data.ErrMsg &&
            response.data.Data.ErrMsg.length > 0
          ) {
            dispatch(
              showMessage({
                message: `Error: ${response.data.Data.ErrMsg}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              }),
            )
          } else {
            dispatch(
              showMessage({
                message: `Unavailable Time has been successfully created.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
          }
        },
      ),
    )
  }
}

export function updateTechUATime(techUA) {
  return (dispatch, getState) => {
    const { routeParams } = getState().techUAApp.techUA

    const request = axios.put(
      `${window['apiLocation']}/api/TechnicianUATime?Co=${encodeURIComponent(
        techUA.Co,
      )}&ID=${techUA.ID}`,
      techUA,
    )

    return request.then(response =>
      Promise.all([dispatch(getTechnicianUATimeData(techUA.Co))]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Unavailable Time has been successfully updated.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function removeTechUATime(techUA) {
  return (dispatch, getState) => {
    const request = axios.delete(
      `${window['apiLocation']}/api/TechnicianUATime?Co=${encodeURIComponent(
        techUA.Co,
      )}&ID=${techUA.ID}`,
    )

    return request.then(response =>
      Promise.all([dispatch(getTechnicianUATimeData(techUA.Co))]).then(() => {
        if (response.data && response.data.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Unavailable Time has been successfully deleted.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
        dispatch(closeEditTechUADialog())
      }),
    )
  }
}
